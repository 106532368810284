var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c("div", { staticClass: "md-toolbar-row" }, [
          _c("div", { staticClass: "md-toolbar-section-start" }, [
            _c("h3", [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("menus.dashboard")) +
                  "\n                "
              ),
            ]),
          ]),
          _c("div", { staticClass: "md-toolbar-section-end" }, [
            _c("div", { staticClass: "md-collapse" }, [
              _c(
                "div",
                [
                  _c("label", { staticClass: "field-label" }, [
                    _vm._v(_vm._s(_vm.$t("inputs.selectDate"))),
                  ]),
                  _c("md-datepicker", {
                    staticClass: "dashboard-date",
                    attrs: { "md-immediately": "" },
                    model: {
                      value: _vm.dashboardDate,
                      callback: function ($$v) {
                        _vm.dashboardDate = $$v
                      },
                      expression: "dashboardDate",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25",
      },
      [
        _c(
          "stats-card",
          { attrs: { "header-color": "blue" } },
          [
            _c("template", { slot: "header" }, [
              _c(
                "div",
                { staticClass: "card-icon" },
                [_c("md-icon", [_vm._v("equalizer")])],
                1
              ),
              _c("p", { staticClass: "category" }, [
                _vm._v(
                  "\n                    My " +
                    _vm._s(_vm.$t("pages.dashboard.nrStopsCompleted")) +
                    "\n                    "
                ),
                _c("br"),
                _vm._v(
                  "\n                    for " +
                    _vm._s(
                      _vm._f("dateFormat")(
                        _vm.dashboardDate,
                        _vm.DATE_TYPES.internationalDate
                      )
                    ) +
                    "\n                "
                ),
              ]),
              _c("p", { staticClass: "category" }, [_vm._v(" ")]),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                staticClass: "stat-loader",
              }),
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.results.completedStop) +
                    "\n                "
                ),
              ]),
            ]),
            _c("template", { slot: "footer" }, [
              _c(
                "div",
                { staticClass: "stats" },
                [
                  _c("md-icon", [_vm._v("update")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("pages.dashboard.justUpdated")) +
                      "\n                "
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _vm.hasTeamAccess
      ? _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25",
          },
          [
            _c(
              "stats-card",
              { attrs: { "header-color": "green" } },
              [
                _c("template", { slot: "header" }, [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("store")])],
                    1
                  ),
                  _c("p", { staticClass: "category" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("pages.dashboard.nrStopsCompleted")) +
                        "\n                    "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                    for " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.dashboardDate,
                            _vm.DATE_TYPES.internationalDate
                          )
                        ) +
                        "\n                "
                    ),
                  ]),
                  _c("p", { staticClass: "category category-label" }, [
                    _vm._v(_vm._s(_vm.$t("pages.dashboard.team"))),
                  ]),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLoading,
                        expression: "isLoading",
                      },
                    ],
                    staticClass: "stat-loader",
                  }),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.results.completedStopIncludeTeam) +
                        "\n                "
                    ),
                  ]),
                ]),
                _c("template", { slot: "footer" }, [
                  _c(
                    "div",
                    { staticClass: "stats" },
                    [
                      _c("md-icon", [_vm._v("update")]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("pages.dashboard.justUpdated")) +
                          "\n                "
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25",
      },
      [
        _c(
          "stats-card",
          { attrs: { "header-color": "rose" } },
          [
            _c("template", { slot: "header" }, [
              _c(
                "div",
                { staticClass: "card-icon" },
                [_c("md-icon", [_vm._v("equalizer")])],
                1
              ),
              _c("p", { staticClass: "category" }, [
                _vm._v(
                  "\n                    My " +
                    _vm._s(_vm.$t("pages.dashboard.nrStopsRemaining")) +
                    "\n                    "
                ),
                _c("br"),
                _vm._v(
                  "\n                    for " +
                    _vm._s(
                      _vm._f("dateFormat")(
                        _vm.dashboardDate,
                        _vm.DATE_TYPES.internationalDate
                      )
                    ) +
                    "\n                "
                ),
              ]),
              _c("p", { staticClass: "category" }, [_vm._v(" ")]),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                staticClass: "stat-loader",
              }),
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.results.remainingStop) +
                    "\n                "
                ),
              ]),
            ]),
            _c("template", { slot: "footer" }, [
              _c(
                "div",
                { staticClass: "stats" },
                [
                  _c("md-icon", [_vm._v("update")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("pages.dashboard.justUpdated")) +
                      "\n                "
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _vm.hasTeamAccess
      ? _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25",
          },
          [
            _c(
              "stats-card",
              { attrs: { "header-color": "warning" } },
              [
                _c("template", { slot: "header" }, [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("weekend")])],
                    1
                  ),
                  _c("p", { staticClass: "category" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("pages.dashboard.nrStopsRemaining")) +
                        "\n                    "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                    for " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.dashboardDate,
                            _vm.DATE_TYPES.internationalDate
                          )
                        ) +
                        "\n                "
                    ),
                  ]),
                  _c("p", { staticClass: "category category-label" }, [
                    _vm._v(_vm._s(_vm.$t("pages.dashboard.team"))),
                  ]),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLoading,
                        expression: "isLoading",
                      },
                    ],
                    staticClass: "stat-loader",
                  }),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.results.remainingStopIncludeTeam) +
                        "\n                "
                    ),
                  ]),
                ]),
                _c("template", { slot: "footer" }, [
                  _c(
                    "div",
                    { staticClass: "stats" },
                    [
                      _c("md-icon", [_vm._v("update")]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("pages.dashboard.justUpdated")) +
                          "\n                "
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25",
      },
      [
        _c(
          "stats-card",
          { attrs: { "header-color": "primary" } },
          [
            _c("template", { slot: "header" }, [
              _c(
                "div",
                { staticClass: "card-icon" },
                [_c("md-icon", [_vm._v("build")])],
                1
              ),
              _c("p", { staticClass: "category" }, [
                _vm._v(
                  "My " + _vm._s(_vm.$t("pages.dashboard.nrOverDueSchedules"))
                ),
              ]),
              _c("p", { staticClass: "category" }, [_vm._v(" ")]),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                staticClass: "stat-loader",
              }),
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.results.overdueScheduledMaintenance) +
                    "\n                "
                ),
              ]),
            ]),
            _c("template", { slot: "footer" }, [
              _c(
                "div",
                { staticClass: "stats" },
                [
                  _c("md-icon", [_vm._v("update")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("pages.dashboard.justUpdated")) +
                      "\n                "
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25",
      },
      [
        _c(
          "stats-card",
          { attrs: { "header-color": "warning" } },
          [
            _c("template", { slot: "header" }, [
              _c(
                "div",
                { staticClass: "card-icon" },
                [_c("md-icon", [_vm._v("build")])],
                1
              ),
              _c("p", { staticClass: "category" }, [
                _vm._v(_vm._s(_vm.$t("pages.dashboard.nrOverDueSchedules"))),
              ]),
              _c("p", { staticClass: "category category-label" }, [
                _vm._v(_vm._s(_vm.$t("pages.dashboard.team"))),
              ]),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                staticClass: "stat-loader",
              }),
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.results.overdueScheduledMaintenanceIncludeTeam) +
                    "\n                "
                ),
              ]),
            ]),
            _c("template", { slot: "footer" }, [
              _c(
                "div",
                { staticClass: "stats" },
                [
                  _c("md-icon", [_vm._v("update")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("pages.dashboard.justUpdated")) +
                      "\n                "
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }