<template>
    <div class="profile--container map-panel" v-if="!isAssetGroup && pinnedDriverDetails.publicUserId">
        <router-link
            class="profile--link"
            :to="{
                name: 'Team Member Profile',
                params: { publicUserId: pinnedDriverDetails.publicUserId }
            }"
            target="_blank"
        >
            <img :src="photoUrl" class="profile--photo" @error="setDefaultBrokenImage" />
        </router-link>
        <span class="profile--fullname">{{ pinnedDriverDetails.fullName }}</span>
        <div v-if="pinnedDriverDetails.phone">
            <a :href="`tel:${pinnedDriverDetails.phone}`" class="profile--phone">
                <span>{{ pinnedDriverDetails.phone }}</span>
            </a>
            <send-sms-button :public-user-id="pinnedDriverDetails.publicUserId" :name="pinnedDriverDetails.fullName" />
        </div>
    </div>
</template>

<script>
import { inject, computed } from '@vue/composition-api';
import useGeneralUtils from '@/compostables/useGeneralUtils';
import { SendSmsButton } from '@/components';

export default {
    name: 'DriverProfile',
    props: {
        pinnedDriverDetails: {
            type: Object,
            default: () => {}
        },
        photoUrl: {
            type: String,
            default: '/img/profile-pic.png'
        }
    },
    components: { SendSmsButton },
    setup(props, { root }) {
        const { getters } = inject('vuex-store');
        const isAssetGroup = computed(() => getters['map/isAssetGroup']);
        const { setDefaultBrokenImage } = useGeneralUtils(root);

        return {
            setDefaultBrokenImage,
            isAssetGroup
        };
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';

.profile--container {
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    right: 10px;
    top: 95px;
    min-width: 170px;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 5px;
    text-align: center;

    .profile--link {
        margin-bottom: 5px;

        .profile--photo {
            min-width: 100px;
            height: 100px;
            box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.1), 0 0px 5px 0 rgba(0, 0, 0, 0.15) !important;
            border-radius: 3px;
        }
    }

    span {
        padding: 5px;
    }

    a {
        color: $locate2u-blue-base !important;
    }

    .profile--fullname {
        font-weight: 500;
        white-space: nowrap;
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .profile--phone {
        margin-top: -10px;
        font-size: 13px;
    }
}
</style>
