var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cf-container" }, [
    _vm.teamRegionSchemeSupported
      ? _c(
          "div",
          { staticClass: "cf-action-container" },
          [
            _c(
              "md-button",
              {
                staticClass:
                  "md-primary md-just-icon md-round add-geofence-event",
                attrs: { title: "Add New Setting" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleAdd($event)
                  },
                },
              },
              [_c("md-icon", [_vm._v("add")])],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.offerSettings && _vm.offerSettings.length
      ? _c(
          "div",
          [
            _c(
              "md-table",
              { staticClass: "context-menu-support custom-paginated-table" },
              [
                _c(
                  "md-table-row",
                  [
                    _c("md-table-head", [_vm._v("Team Region")]),
                    _vm.showAutoAllocateMethodFields
                      ? _c("md-table-head", [_vm._v("Max Retry")])
                      : _vm._e(),
                    _vm.showAutoAllocateMethodFields
                      ? _c("md-table-head", [_vm._v("Retry Delay")])
                      : _vm._e(),
                    _c("md-table-head", [_vm._v("Match Skills")]),
                    _c("md-table-head", [_vm._v("Check Load Capacity")]),
                    _c("md-table-head", [_vm._v("Prioritize Empty Vehicles")]),
                    _vm.showAutoAllocateMethodFields
                      ? _c("md-table-head", [_vm._v("Bands Count")])
                      : _vm._e(),
                    _c("md-table-head", { staticClass: "end" }, [
                      _vm._v("Actions"),
                    ]),
                  ],
                  1
                ),
                _vm._l(_vm.offerSettings, function (item) {
                  return _c(
                    "md-table-row",
                    { key: item.teamRegionId },
                    [
                      _c("md-table-cell", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              item.teamRegionName !== null
                                ? item.teamRegionName
                                : "Team Level"
                            ) +
                            "\n                "
                        ),
                      ]),
                      _vm.showAutoAllocateMethodFields
                        ? _c("md-table-cell", [_vm._v(_vm._s(item.maxRetry))])
                        : _vm._e(),
                      _vm.showAutoAllocateMethodFields
                        ? _c("md-table-cell", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("timeSpanFormat")(item.retryDelay)
                                ) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                      _c("md-table-cell", [
                        _vm._v(_vm._s(_vm._f("yesNoDesc")(item.matchSkills))),
                      ]),
                      _c("md-table-cell", [
                        _vm._v(
                          _vm._s(_vm._f("yesNoDesc")(item.checkLoadCapacity))
                        ),
                      ]),
                      _c("md-table-cell", [
                        _vm._v(
                          _vm._s(
                            _vm._f("yesNoDesc")(item.prioritizeEmptyVehicles)
                          )
                        ),
                      ]),
                      _vm.showAutoAllocateMethodFields
                        ? _c("md-table-cell", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  item.bands && item.bands.length
                                    ? item.bands.length
                                    : "-"
                                ) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "md-table-cell",
                        {
                          staticClass: "action-buttons",
                          attrs: { align: "end" },
                        },
                        [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-info md-just-icon md-round",
                              attrs: {
                                title: "View details",
                                "data-test-id":
                                  "btn-view-details-" + item.teamRegionId,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleViewDetails(item)
                                },
                              },
                            },
                            [_c("md-icon", [_vm._v("subject")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ],
          1
        )
      : _c("div", [
          _c("p", { staticClass: "no-result-message" }, [
            _vm._v("No Job Offer settings found."),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }