var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inline-button" },
    [
      !_vm.isReadOnlyUser
        ? _c(
            "md-button",
            {
              class: _vm.useIcon
                ? "md-warning md-just-icon md-round"
                : "" + _vm.className,
              attrs: { title: "Update maintenance schedule" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleUpdate($event)
                },
              },
            },
            [
              _vm.useIcon
                ? _c("md-icon", [_vm._v("edit")])
                : _c("span", [_vm._v("Update")]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }