var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _vm._m(0),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-blue" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("settings")])],
                  1
                ),
              ]
            ),
            _c("md-card-content", [
              _c(
                "div",
                [
                  _c(
                    "tabs",
                    {
                      staticClass: "custom-tab-list",
                      attrs: {
                        "tab-name": [
                          "SMS Settings",
                          "Proof of Delivery Settings",
                        ],
                        "color-button": "success",
                      },
                    },
                    [
                      _c("template", { slot: "tab-pane-1" }, [
                        _c(
                          "div",
                          { staticClass: "member-container" },
                          [_c("sms-settings", { attrs: { level: "users" } })],
                          1
                        ),
                      ]),
                      _c("template", { slot: "tab-pane-2" }, [
                        _c("div", [_c("proof-of-delivery-configuration")], 1),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c("div", { staticClass: "custom-toolbar" }, [
          _c("div", { staticClass: "custom-toolbar-start" }, [
            _c("h3", { staticClass: "title" }, [_vm._v("User Settings")]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }