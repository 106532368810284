<template>
    <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100" v-if="hasTeam">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <h3 class="title">Team Settings</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>settings</md-icon>
                    </div>
                </md-card-header>

                <md-card-content v-if="hasTeam">
                    <div class="md-layout">
                        <div
                            class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 md-size-25 text-center"
                            v-for="(option, index) in teamSettingOptions"
                            :key="index"
                        >
                            <div>
                                <router-link
                                    :to="{
                                        name: option.name
                                    }"
                                    class="setting-menu"
                                >
                                    <md-icon :class="option.class" v-if="option.isCustomIcon">
                                        <img :src="`/img/icons/${option.icon}`" />
                                    </md-icon>
                                    <md-icon :class="option.class" v-else>{{ option.icon }}</md-icon>
                                    <p>
                                        {{ $t('menus.setting.' + option.class) }}
                                    </p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TEAM_SETTING_OPTIONS_CONSTANTS } from '@/utils/constants';

export default {
    name: 'TeamSettings',
    data() {
        return {
            teamSettingOptions: []
        };
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam',
            teamType: 'user/teamType',
            oldJobOffersEnabled: 'user/oldJobOffersEnabled',
            hasOfferToTeamMembersAction: 'custom-actions/hasOfferToTeamMembersAction'
        })
    },
    methods: {
        ...mapActions('custom-actions', ['FETCH_EVENT_TRIGGERS'])
    },
    async mounted() {
        await this.FETCH_EVENT_TRIGGERS({ actionType: 'OfferToTeamMembers' });

        const allOptions = [...TEAM_SETTING_OPTIONS_CONSTANTS].sort((x, y) => (x.name > y.name ? 1 : -1));
        if (this.oldJobOffersEnabled || !this.hasOfferToTeamMembersAction) {
            const jobOfferIndex = allOptions.findIndex((x) => x.name === 'Job Offer Settings');
            if (jobOfferIndex > -1) 
                allOptions.splice(jobOfferIndex, 1);
        }
        this.teamSettingOptions = [...allOptions].sort((x, y) => (x.name > y.name ? 1 : -1));
    }
};
</script>

<style lang="scss" scoped>
.title {
    display: inline-block;
}

.setting-menu {
    i {
        font-size: 42px !important;
        color: #575c5c;
    }
    p {
        font-weight: 400;
        color: #575c5c;
        font-size: 14px;
    }
}

.generalSettings {
    color: #4ed2ff !important;
}
.customFields {
    color: #c2907f !important;
}

.skills {
    color: #3b5998 !important;
}

.smsSettings {
    color: #2b93ff !important;
}
.proofOfDeliveryConfiguration {
    color: #0bda8e !important;
}
.apps {
    color: #384553 !important;
}
.geofenceEventsSettings {
    color: #ff5245 !important;
}
.capacitySettings {
    color: #ffb42b !important;
}
.questions {
    color: #dd4b39 !important;
}
.importMappings {
    color: #0976b4 !important;
}
.accounts {
    color: #377e47 !important;
}
.customActions {
    color: #b618dd !important;
}
.rateZones {
    color: #05b0c5 !important;
}
.services {
    color: #fb8c00 !important;
}
.teamRegions {
    color: #43a047 !important;
}
.jobOffers {
    color: #cce055 !important;
}
.offerMethods {
    color: #0bf600 !important;
}
.serviceAreas {
    color: #2b93ff !important;
}
.failedDeliveryConfiguration {
    color: #dd4b39 !important;
    width: 40px;
}
.driverInstructions {
    color: #43a047 !important;
}
.jobOffers {
    color: #cce055 !important;
}
.serviceAreas {
    color: #2b93ff !important;
}

.driverInstructions {
    color: #43a047 !important;
}
.appPermissionSettings {
    color: #008000 !important;
}

.shipmentContents {
    color: #f53c3cc7 !important;
}

.text-center {
    min-height: 120px;
    margin-top: 20px;
}
</style>
