var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam
    ? _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Settings"),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "\n                       >    " +
                        _vm._s(
                          _vm.$t("menus.setting.proofOfDeliveryConfiguration")
                        ) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "custom-toolbar-end" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-primary md-just-icon md-round pull-right header-button",
                      attrs: { title: "Refresh List" },
                      on: { click: _vm.refreshList },
                    },
                    [_c("md-icon", [_vm._v("refresh")])],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "Create Proof Of Delivery Template" },
                      },
                    },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "md-primary md-just-icon md-round pull-right header-button",
                          attrs: { title: "Add proof of delivery template" },
                        },
                        [_c("md-icon", [_vm._v("add")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c("proof-of-delivery-template-list", {
              ref: "proofOfDeliveryTemplateList",
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }