<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Time Window</h4>
        </div>
        <div class="modal-body">
            <md-field class="hide-timer-clear-button">
                <label for="">Start time</label>
                <time-picker time="00:00" @selectedTime="getStartTime" :all-time-options="false" :is-required="true" />
            </md-field>
            <md-field class="hide-timer-clear-button">
                <label for="">End time</label>
                <time-picker time="23:59" @selectedTime="getEndTime" :all-time-options="false" :is-required="true" />
            </md-field>
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="addTimeWindowOption">Save</md-button>
            <md-button class="md-default dialog-button" @click.stop="close">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import TimePicker from './TimePicker';

export default {
    name: 'TimePickerCustomTimeWindow',
    components: {
        TimePicker
    },
    props: {
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            timeFilterValue: {}
        };
    },
    methods: {
        addTimeWindowOption() {
            if (this.timeFilterValue.timeWindowStart == null || this.timeFilterValue.timeWindowEnd == null) {
                this.$notify({
                    message: 'Start/End time cannot be empty.',
                    type: 'danger'
                });
                return;
            }

            if (
                moment(this.timeFilterValue.timeWindowStart, 'HH:mm') >
                moment(this.timeFilterValue.timeWindowEnd, 'HH:mm')
            ) {
                this.$notify({
                    message: 'End time is earlier than the start time',
                    type: 'danger'
                });
            } else {
                this.resolve({
                    name: `${moment(this.timeFilterValue.timeWindowStart, 'HH:mm').format('h:mm A')} - ${moment(
                        this.timeFilterValue.timeWindowEnd,
                        'HH:mm'
                    ).format('h:mm A')}`,
                    timeWindowStart: this.timeFilterValue.timeWindowStart,
                    timeWindowEnd: this.timeFilterValue.timeWindowEnd
                });
            }
        },
        close() {
            this.resolve(null);
        },
        getStartTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;

                this.timeFilterValue.timeWindowStart = appointmentTime;
            } else {
                this.timeFilterValue.timeWindowStart = null;
            }
        },
        getEndTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;

                this.timeFilterValue.timeWindowEnd = appointmentTime;
            } else {
                this.timeFilterValue.timeWindowEnd = null;
            }
        }
    }
};
</script>
