var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _vm._m(0),
    _vm.list.length > 0
      ? _c(
          "div",
          { staticClass: "app-parent-container" },
          _vm._l(_vm.list, function (item) {
            return _c(
              "div",
              { key: item.carrierTeamId, staticClass: "app-container" },
              [
                _c("div", [
                  _c("div", [
                    _c("div", { staticClass: "app-logo" }, [
                      item.logoUrl != null
                        ? _c("img", { attrs: { src: item.logoUrl } })
                        : _c("img", {
                            attrs: { src: _vm.$root.defaultLogoUrl },
                          }),
                    ]),
                  ]),
                  _c("div", { staticClass: "app-content" }, [
                    _c("span", [_vm._v(_vm._s(item.company))]),
                    _c("p", [_vm._v(_vm._s(item.shortDescription))]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "btn-container" },
                  [
                    item.loadCapacitySettingsRequirement != null &&
                    item.loadCapacitySettingsRequirement.length > 0 &&
                    _vm.getCarrierStatus(item.installedCarrierStatus) !=
                      "Connected"
                      ? _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "/settings/capacity-settings/",
                            },
                          },
                          [
                            _c(
                              "md-icon",
                              [
                                _vm._v(
                                  "\n                        production_quantity_limits\n                        "
                                ),
                                _c("md-tooltip", [
                                  _vm._v(
                                    "This carrier requires capacity settings setup."
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.getCarrierStatus(item.installedCarrierStatus) ==
                      "Connect" ||
                    _vm.getCarrierStatus(item.installedCarrierStatus) ==
                      "Connected" ||
                    _vm.getCarrierStatus(item.installedCarrierStatus) ==
                      "Inactive"
                      ? _c(
                          "md-button",
                          {
                            class:
                              _vm.getCarrierStatus(
                                item.installedCarrierStatus
                              ) != "Connect"
                                ? "md-default"
                                : "md-primary",
                            attrs: {
                              disabled:
                                _vm.getCarrierStatus(
                                  item.installedCarrierStatus
                                ) == "Connected" ||
                                _vm.getCarrierStatus(
                                  item.installedCarrierStatus
                                ) == "Inactive",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.openApp(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.getCarrierStatus(
                                    item.installedCarrierStatus
                                  )
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : _c(
                          "md-button",
                          {
                            staticClass: "md-warning",
                            attrs: { href: "/team/carriers/" },
                          },
                          [
                            _c("md-tooltip", [
                              _vm._v(
                                "\n                        By clicking this button this will redirect you to the carrier page to complete the setup for\n                        this carrier.\n                    "
                              ),
                            ]),
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.getCarrierStatus(
                                    item.installedCarrierStatus
                                  )
                                ) +
                                "\n                "
                            ),
                          ],
                          1
                        ),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        )
      : _c("div", [_vm._v("No carriers available")]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "custom-toolbar" }, [
      _c("div", { staticClass: "custom-toolbar-start" }, [
        _c("h3", { staticClass: "title" }, [_vm._v("Carrier Marketplace")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }