<template>
    <div class="geo-fence" v-if="hasGeofenceAccess">
        <div
            class="geofence-wrapper map-panel"
            :class="[{ 'drawing-geo-fence': drawGeofence }, { 'showing-geo-list': showGeofenceList }]"
        >
            <div class="geo-main-wrapper">
                <a class="geo-panel">
                    <span class="title">Geofences</span>
                    <span v-if="!this.drawGeofence" class="geo-icons toggle-list" @click="toggleGeofenceList">
                        <md-icon>list</md-icon>
                    </span>

                    <!-- section : drawing tools -->
                    <transition name="fade">
                        <div v-if="drawGeofence" class="draw-geo-fence drawing-tool map-panel">
                            <div class="gmnoprint ">
                                <div class="gmnoprint-child" :class="[{ active: isDrawingCircle }]">
                                    <button
                                        @click="selectDrawing('circle')"
                                        draggable="false"
                                        title="Draw a circle"
                                        aria-label="Draw a circle"
                                        type="button"
                                        aria-pressed="false"
                                        :class="['circle-button', drawingType === 'circle' ? 'button-pressed' : '']"
                                    >
                                        <span class="button-span">
                                            <div class="button-div">
                                                <img alt="" :src="$root.drawingToolIcon" draggable="false" />
                                            </div>
                                        </span>
                                    </button>
                                </div>
                                <div class="gmnoprint-child" :class="[{ active: !isDrawingCircle }]">
                                    <button
                                        @click="selectDrawing('polygon')"
                                        draggable="false"
                                        title="Draw a shape"
                                        aria-label="Draw a shape"
                                        type="button"
                                        aria-pressed="false"
                                        :class="['polygon-button', drawingType === 'polygon' ? 'button-pressed' : '']"
                                    >
                                        <span class="button-span">
                                            <div class="button-div">
                                                <img alt="" :src="$root.drawingToolIcon" draggable="false" />
                                            </div>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <span class="geo-icons toggle-add" @click="toggleGeofenceDraw">
                        <md-icon v-if="drawGeofence" title="Cancel">close</md-icon>
                        <md-icon v-else title="Create">draw</md-icon>
                    </span>
                </a>
            </div>

            <!-- section : geo wrapper lists -->
            <transition name="fade">
                <div v-if="showGeofenceList" class="geo-list-wrapper">
                    <md-card-content class="geofence-table-body" v-if="geofenceArr.length">
                        <ul>
                            <li
                                v-for="fence in geofenceArr"
                                :key="fence.geofenceId"
                                style="cursor:pointer;"
                                :class="showInfoWindow && fence.geofenceId === geofenceId ? 'highlight-item' : ''"
                            >
                                <span class="name" @click="zoomToFence(fence.geofenceId)">
                                    {{ fence.name }}
                                </span>
                                <span class="pushed-right cta-list">
                                    <span @click="editGeofence(fence.geofenceId)" title="Edit">
                                        <md-icon>edit</md-icon>
                                    </span>
                                    <span @click="deleteGeofence(fence.geofenceId)" title="Delete">
                                        <md-icon>delete</md-icon>
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </md-card-content>
                    <div class="small" v-else>
                        No geofences found
                    </div>
                </div>
            </transition>
        </div>

        <div class="geo-fence-desc map-panel" v-if="addGeofenceDesc">
            <div class="modal-main">
                <div class="modal-header">
                    <b class="title" v-if="geofenceId">Edit Geofence</b>
                    <b class="title" v-else>Add Geofence</b>
                </div>
                <div class="modal-body">
                    <form-wrapper :validator="$v" class="form-wrapper">
                        <form-group
                            label="Team Region"
                            v-if="canViewTeamRegion && teamRegions && teamRegions.length > 0"
                        >
                            <team-region-options
                                :selected="currentShape.teamRegionId"
                                @selectedOption="handleTeamRegionChanged"
                                :show-auto="false"
                            />
                        </form-group>
                        <span v-if="isTeamRegionId" class="error">
                            Team Region is required
                        </span>
                        <form-group name="newGeofenceTitle" label="Name">
                            <md-input v-model="newGeofenceTitle" aria-placeholder="Name" v-focus />
                        </form-group>
                        <!--<form-group name="type" label="Type">
                <md-select v-model="newGeofenceType">
                    <md-option
                        v-for="geofenceType in geofenceTypes"
                        :key="geofenceType.geofenceTypeId"
                        :value="geofenceType.geofenceTypeId"
                    >
                        {{ geofenceType.type }}
                    </md-option>
                </md-select>
            </form-group>-->
                        <form-group name="notes" label="Notes">
                            <md-textarea v-model="newGeofenceNotes" aria-placeholder="Notes" />
                        </form-group>
                    </form-wrapper>
                </div>
                <div class="modal-footer">
                    <md-button class="md-primary dialog-button md-theme-default" @click="addNewGeofence(false)">
                        Save
                    </md-button>
                    <md-button class="md-default dialog-button md-theme-default" @click="cancelNewGeofence(false)">
                        Cancel
                    </md-button>
                </div>
            </div>
        </div>

        <info-window-component
            :content="infoWindowContent"
            :styles="infoWindowStyles"
            :show-info-window="showInfoWindow"
            @editGeofence="editGeofence"
            @deleteGeofence="deleteGeofence"
            @closeInfoWindow="
                () => {
                    this.showInfoWindow = false;
                    this.geofenceId = null;
                }
            "
        />
    </div>
</template>

<script>
import { showErrorMessage } from '@/helpers';
import { MapOverviewMixin, GeneralMixin } from '@/mixins';
import { GEOFENCE_TYPES } from '@/utils/constants';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import InfoWindowComponent from '@/pages/Map/InfoWindow';
import { TeamRegionOptions } from '@/components';

export default {
    components: { InfoWindowComponent, TeamRegionOptions },
    props: {
        map: {
            type: Object,
            default: () => {}
        },
        user: {
            type: Object,
            default: () => {}
        }
    },
    mixins: [MapOverviewMixin, GeneralMixin],
    data() {
        return {
            geofenceArr: [],
            geofenceDrawings: [],
            showAllGeofence: true,
            drawings: [],
            isDrawingCircle: true,
            geofenceAction: [],
            drawingPolygons: [],
            drawingType: null,
            addFence: false,
            showGeofence: true,
            drawGeofence: false,
            addGeofenceDesc: false,
            newGeofenceTitle: null,
            newGeofenceNotes: null,
            newGeofenceType: null,
            drawingManager: null,
            currentShape: {
                teamRegionId: null
            },
            isTeamRegionId: false,
            infowindow: new google.maps.InfoWindow(),
            geofenceId: null,
            infoWindowContent: {},
            infoWindowStyles: {},
            showInfoWindow: false,
            showGeofenceList: false,
            selectedTeamRegionId: null,
            geofenceTypes: GEOFENCE_TYPES
        };
    },
    computed: {
        ...mapGetters({
            canViewTeamRegion: 'user/canViewTeamRegion',
            teamRegions: 'team/teamRegions',
            hasGeofenceAccess: 'user/hasGeofenceAccess',
            teamRegionId: 'map/teamRegionId'
        })
    },
    validations: {
        newGeofenceTitle: {
            required
        }
    },
    async mounted() {
        // Get types
        // const geofenceTypes = await this.$store.dispatch('map/FETCH_GEO_TYPES');
        // this.geofenceTypes = geofenceTypes.data;
        // const geofenceArr = await this.$store.dispatch('map/FETCH_GEO_FENCE');
        // this.geofenceArr = geofenceArr.data;
        // const geofenceTypesArr = await this.$store.dispatch('map/FETCH_GEO_TYPES');
        // this.geofenceTypesArr = geofenceTypesArr.data;
        await this.getGeofenceData();
        this.initDrawing();
    },
    watch: {
        teamRegionId(value) {
            this.selectedTeamRegionId = value;
            this.getGeofenceData();
        }
    },
    methods: {
        zoomToFence(geofenceId) {
            let bounds;
            const clickedFence = this.geofenceArr.find((fence) => geofenceId === fence.geofenceId);
            if (clickedFence.shape === 'Polygon') {
                bounds = new google.maps.LatLngBounds();
                clickedFence.paintedShape.getPath().forEach((element) => {
                    bounds.extend(element);
                });
            } else {
                bounds = clickedFence.paintedShape.getBounds();
            }
            this.map.fitBounds(bounds);
        },
        initDrawing() {
            this.drawingManager = this.$_map_drawingManager();
            this.drawingManager.setMap(this.map);
            this.drawingManager.setOptions({
                drawingControl: false
            });
            const onCircleComplete = (circle) => {
                this.currentShape = {
                    type: 'Monitor',
                    shape: 'Circle',
                    circleGeofence: {
                        center: {
                            lat: circle.center.lat(),
                            lng: circle.center.lng()
                        },
                        radiusUnit: 'meters',
                        radiusValue: circle.radius
                    }
                };

                this.drawings.push(circle);
                this.drawingManager.setDrawingMode(null);
                this.addGeofenceDesc = true;
                this.setTeamRegion(this.teamRegionId);
            };
            const onPolygonComplete = (polygon) => {
                // For polygon, loop through vertices and push the lat and lng as object
                this.currentShape = {
                    type: 'Monitor',
                    shape: 'Polygon',
                    polygonGeofence: {
                        vertices: []
                    }
                };
                polygon.getPath().forEach((vertex) => {
                    this.currentShape.polygonGeofence.vertices.push({
                        lat: vertex.lat(),
                        lng: vertex.lng()
                    });
                });

                this.drawings.push(polygon);
                this.drawingManager.setDrawingMode(null);
                this.addGeofenceDesc = true;
                this.setTeamRegion(this.teamRegionId);
            };

            this.$_map_handleListener(this.drawingManager, 'circlecomplete', onCircleComplete);
            this.$_map_handleListener(this.drawingManager, 'polygoncomplete', onPolygonComplete);
            this.$_map_handleListener(this.drawingManager, 'drawingmode_changed', () => {
                this.drawingManager.clearDrawing = false;
                if (this.drawingManager.getDrawingMode() !== null) {
                    this.clearDrawing();
                }
            });
        },
        selectDrawing(mode) {
            this.drawingManager.setDrawingMode(mode);
            this.isDrawingCircle = mode === 'circle';
            this.clearFields();
        },
        clearDrawing() {
            this.drawings.forEach((draw) => {
                draw.setMap(null);
            });
            this.drawings = [];
            this.drawingPolygons.forEach((draw) => {
                draw.setMap(null);
            });
            this.drawingPolygons = [];
        },
        toggleGeofenceDraw() {
            if (this.showGeofenceList) 
                this.showGeofenceList = false;
            if (this.drawGeofence) {
                this.selectDrawing(null);
                this.addGeofenceDesc = false;
            } else {
                this.selectDrawing('circle');
            }
            this.drawGeofence = !this.drawGeofence;
        },
        toggleAddGeofenceDesc() {
            this.addGeofenceDesc = !this.addGeofenceDesc;
        },
        async addNewGeofence(isGeofence) {
            this.$_handleLoaderState(true, 'SAVING...');

            if (!isGeofence) {
                this.$v.$touch();
                if (!this.$v.$invalid && !this.isTeamRegionId) {
                    await this.saveGeofence(isGeofence);
                    this.$v.$reset();
                }
            } else {
                await this.saveGeofence(isGeofence);
            }
            this.$_handleLoaderState(false);
        },
        async saveGeofence(isGeofence) {
            const geofence = this.geofenceArr.find((fence) => this.geofenceId === fence.geofenceId);
            if (this.geofenceId) {
                const { paintedShape, shape } = geofence;
                if (shape === 'Circle') {
                    Object.assign(this.currentShape, {
                        type: 'Monitor',
                        shape,
                        circleGeofence: {
                            center: {
                                lat: paintedShape.center.lat(),
                                lng: paintedShape.center.lng()
                            },
                            radiusUnit: 'meters',
                            radiusValue: paintedShape.radius
                        }
                    });
                } else if (shape === 'Polygon') {
                    Object.assign(this.currentShape, {
                        type: 'Monitor',
                        shape,
                        polygonGeofence: {
                            vertices: []
                        }
                    });
                    paintedShape.getPath().forEach((vertex) => {
                        this.currentShape.polygonGeofence.vertices.push({
                            lat: vertex.lat(),
                            lng: vertex.lng()
                        });
                    });
                }
                this.currentShape.geofenceId = this.geofenceId;
                this.currentShape.name = geofence.name;
                this.currentShape.notes = geofence.notes;
                this.currentShape.geofenceTypeId = geofence.geofenceTypeId;
                this.addGeofenceDesc = false;
            }
            if (isGeofence) {
                this.currentShape.teamRegionId = geofence.teamRegionId;
            } else {
                this.currentShape.name = this.newGeofenceTitle;
                this.currentShape.notes = this.newGeofenceNotes;
                this.currentShape.geofenceTypeId = 4; // this.newGeofenceType;
                this.addGeofenceDesc = false;
            }
            if (this.currentShape.teamRegionId === -1) {
                this.currentShape.teamRegionId = null;
            }

            await this.$store.dispatch('map/CREATE_GEO_FENCE', Object.assign({}, this.currentShape));

            this.$notify({
                message: this.geofenceId ? 'Geofence updated' : 'Geofence created',
                type: 'success'
            });

            this.drawGeofence = false;
            this.showGeofenceList = true;
            this.clearDrawing();
            this.clearFields();
            await this.getGeofenceData();
        },
        clearFields(byGeofence, action) {
            if (this.geofenceId) {
                const geofenceData = this.geofenceArr.find((fence) => fence.geofenceId === this.geofenceId);
                const { geofenceId, shape, circleGeofence, polygonGeofence, paintedShape } = geofenceData;
                paintedShape.setEditable(false);
                paintedShape.setDraggable(false);
                if (byGeofence) {
                    if (shape === 'Circle') {
                        const latlng = this.$_map_createLatLngPoint(
                            circleGeofence.center.lat,
                            circleGeofence.center.lng
                        );
                        paintedShape.setCenter(latlng);
                        paintedShape.setRadius(circleGeofence.radiusValue);
                    } else {
                        action.remove();
                        action.isDisplay = false;
                        const polygon = new google.maps.Polygon({
                            paths: polygonGeofence.vertices
                        });
                        paintedShape.setPath(polygon.getPath());
                        const bounds = new google.maps.LatLngBounds();
                        paintedShape.getPath().forEach((element) => {
                            bounds.extend(element);
                        });
                        const geoAction = this.geofenceActions(
                            paintedShape,
                            geofenceData,
                            bounds.getCenter().lat(),
                            bounds.getCenter().lng()
                        );
                        const geofenceAction = this.geofenceAction.find((x) => x.geofenceId === geofenceId);
                        geofenceAction.action = geoAction;
                        paintedShape.setMap(this.map);
                    }
                }
            }
            this.geofenceId = null;
            this.newGeofenceNotes = '';
            this.newGeofenceTitle = null;
            this.currentShape = {
                teamRegionId: null
            };
            this.showInfoWindow = false;
        },
        cancelNewGeofence(byGeofence, action) {
            this.clearDrawing();
            this.currentShape = {
                teamRegionId: null
            };
            this.drawings = [];
            this.drawGeofence = false;
            this.addGeofenceDesc = false;
            this.drawingManager.setDrawingMode(null);
            this.clearDrawing();
            this.clearFields(byGeofence, action);
            this.$v.$reset();
        },
        editGeofence(geofenceId) {
            this.zoomToFence(geofenceId);
            this.clearFields();
            const fence = this.geofenceArr.find((fence) => fence.geofenceId === geofenceId);
            this.geofenceId = geofenceId;
            this.newGeofenceTitle = fence.name;
            this.newGeofenceNotes = fence.notes;
            this.newGeofenceType = fence.geofenceTypeId;
            this.addGeofenceDesc = true;
            this.showInfoWindow = false;

            fence.paintedShape.setEditable(true);
            fence.paintedShape.setDraggable(true);
            this.setTeamRegion(fence.teamRegionId);
        },
        deleteGeofence(geofenceId) {
            this.zoomToFence(geofenceId);
            this.$messageBox
                .show({
                    title: 'Delete Geofence',
                    body: 'Are you sure you want to delete this geofence?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmDelete(geofenceId);
                });
        },
        async handleConfirmDelete(geofenceId) {
            this.$_handleLoaderState(true, 'DELETING...');
            try {
                this.showInfoWindow = false;
                await this.$store.dispatch('map/DELETE_GEO_FENCE', geofenceId);
                await this.getGeofenceData();

                this.$notify({
                    message: 'Geofence removed',
                    type: 'success'
                });
                this.$_handleLoaderState(false);
            } catch (e) {
                const message = 'Could not delete geofence.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        geofenceActions(paintedShape, shape, lat, lng) {
            const action = this.$_map_createGeofenceActions({
                latlng: this.$_map_createLatLngPoint(lat, lng),
                map: this.map,
                html: `<div style="background-color: #fff;padding: 5px;border-radius: 8px; text-align: center;">
                            <div style="color: #000; font-size: 14px; font-weight: 500;">${shape.name}
                            </div>
                            <div class="geofence-id" style="display: none; margin-top: 8px;">
                                <span class="save-geofence" title="Save" style="visibility:hidden;">
                                    <i style="width: 14px; height: 14px; font-size: 14px !important;" class="md-icon md-icon-font md-theme-default">save</i>
                                </span>
                                <span class="edit-geofence" title="Edit">
                                    <i style="width: 14px; height: 14px; font-size: 14px !important;" class="md-icon md-icon-font md-theme-default">edit</i>
                                </span>
                                <span class="delete-geofence" title="Delete">
                                    <i style="width: 14px; height: 14px; font-size: 14px !important;" class="md-icon md-icon-font md-theme-default">delete</i>
                                </span>
                                <span class="cancel-geofence" title="Cancel" style="visibility:hidden;">
                                    <i style="width: 14px; height: 14px; font-size: 14px !important;" class="md-icon md-icon-font md-theme-default">close</i>
                                </span>
                            </div>
                      </div>`
            });

            const onEdit = () => {
                action.div.getElementsByClassName('edit-geofence')[0].style.visibility = 'hidden';
                action.div.getElementsByClassName('cancel-geofence')[0].style.visibility = 'visible';
                this.geofenceId = shape.geofenceId;
                const fence = this.geofenceArr.find((fence) => fence.geofenceId === shape.geofenceId);
                fence.paintedShape.setEditable(true);
                fence.paintedShape.setDraggable(true);
            };
            const onDelete = () => {
                this.deleteGeofence(shape.geofenceId);
            };

            const onSave = () => {
                this.geofenceId = shape.geofenceId;
                this.addNewGeofence(true);
            };
            const onCancel = () => {
                this.cancelNewGeofence(true, action);
                if (action.div) {
                    action.div.getElementsByClassName('save-geofence')[0].style.visibility = 'hidden';
                    action.div.getElementsByClassName('cancel-geofence')[0].style.visibility = 'hidden';
                    action.div.getElementsByClassName('edit-geofence')[0].style.visibility = 'visible';
                }
            };

            google.maps.event.addListener(paintedShape, 'mouseover', (evt) => {
                if (action.div) {
                    const dom = action.div.getElementsByClassName('geofence-id')[0].style;
                    dom.display = 'block';

                    const editDom = action.div.getElementsByClassName('edit-geofence')[0];
                    if (editDom.getAttribute('editlistener') !== 'true') {
                        editDom.setAttribute('editlistener', 'true');
                        editDom.addEventListener('click', () => {
                            onEdit();
                        });
                    }

                    const deleteDom = action.div.getElementsByClassName('delete-geofence')[0];
                    if (deleteDom.getAttribute('deletelistener') !== 'true') {
                        deleteDom.setAttribute('deletelistener', 'true');
                        deleteDom.addEventListener('click', () => {
                            onDelete();
                        });
                    }
                    const saveDom = action.div.getElementsByClassName('save-geofence')[0];
                    if (saveDom.getAttribute('savelistener') !== 'true') {
                        saveDom.setAttribute('savelistener', 'true');
                        saveDom.addEventListener('click', () => {
                            onSave();
                        });
                    }
                    const cancelDom = action.div.getElementsByClassName('cancel-geofence')[0];
                    if (cancelDom.getAttribute('cancellistener') !== 'true') {
                        cancelDom.setAttribute('cancellistener', 'true');
                        cancelDom.addEventListener('click', () => {
                            onCancel();
                        });
                    }
                }
            });
            google.maps.event.addListener(paintedShape, 'mouseout', (evt) => {
                if (action.div) {
                    action.div.getElementsByClassName('geofence-id')[0].style.display = 'none';
                }
            });
            const onRadiusChanged = () => {
                if (shape.shape === 'Circle') {
                    action.setPosition(shape.paintedShape.getCenter());
                } else if (shape.shape === 'Polygon') {
                    const bounds = new google.maps.LatLngBounds();
                    shape.paintedShape.getPath().forEach((element) => {
                        bounds.extend(element);
                    });
                    action.setPosition(bounds.getCenter());
                }
                action.div.getElementsByClassName('save-geofence')[0].style.visibility = 'visible';
                action.div.getElementsByClassName('edit-geofence')[0].style.visibility = 'hidden';
                action.div.getElementsByClassName('cancel-geofence')[0].style.visibility = 'visible';
            };

            if (shape.shape === 'Circle') {
                this.$_map_handleListener(paintedShape, 'radius_changed', onRadiusChanged);
                this.$_map_handleListener(paintedShape, 'center_changed', onRadiusChanged);
            } else {
                const polygonPath = paintedShape.getPath();
                this.$_map_handleListener(polygonPath, 'insert_at', onRadiusChanged);
                this.$_map_handleListener(polygonPath, 'set_at', onRadiusChanged);
            }

            action.isDisplay = true;

            return action;
        },
        paintFence(shape, geofenceId) {
            let paintedShape;
            let action;
            if (shape.shape === 'Circle') {
                paintedShape = new google.maps.Circle({
                    center: shape.circleGeofence.center,
                    radius: shape.circleGeofence.radiusValue,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.35,
                    draggable: false,
                    editable: false,
                    map: this.map
                });
                action = this.geofenceActions(
                    paintedShape,
                    shape,
                    shape.circleGeofence.center.lat,
                    shape.circleGeofence.center.lng
                );
            } else {
                paintedShape = new google.maps.Polygon({
                    paths: shape.polygonGeofence.vertices,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    draggable: false,
                    editable: false,
                    fillOpacity: 0.35
                });
                const bounds = new google.maps.LatLngBounds();
                paintedShape.getPath().forEach((element) => {
                    bounds.extend(element);
                });
                action = this.geofenceActions(paintedShape, shape, bounds.getCenter().lat(), bounds.getCenter().lng());
                paintedShape.setMap(this.map);
            }
            this.geofenceDrawings.push(paintedShape);

            const index = this.geofenceArr.findIndex((x) => x.geofenceId === geofenceId);
            if (index === -1) {
                shape.geofenceId = geofenceId;
                this.geofenceArr.push(Object.assign({ paintedShape }, { ...shape }, { geofenceId }));
            } else {
                this.geofenceArr[index].paintedShape = paintedShape;
                Object.assign(this.geofenceArr[index], shape);
            }

            // this.clearDrawing();
            this.geofenceAction.push({ geofenceId, action });
        },
        toggleGeofenceList() {
            if (this.drawGeofence) 
                this.toggleGeofenceDraw();
            this.showGeofenceList = !this.showGeofenceList;
            this.map.isVisible = this.showGeofenceList && this.geofenceArr.length > 0;
            this.displayGEOFence(this.showGeofenceList);
        },
        displayGEOFence(value) {
            this.geofenceAction.forEach((geofence) => {
                geofence.action.remove();
                geofence.action.isDisplay = false;
            });
            this.geofenceArr.forEach((fence) => {
                if (!fence.paintedShape) {
                    if (fence.shape === 'Circle') {
                        this.paintFence(fence, fence.geofenceId);
                    } else {
                        this.paintFence(fence, fence.geofenceId);
                    }
                } else {
                    fence.paintedShape.setVisible(value);
                }
                const geofenceAction = this.geofenceAction.find((x) => x.geofenceId === fence.geofenceId);
                if (value) {
                    geofenceAction.action.isDisplay = true;
                    geofenceAction.action.draw();
                }
            });
        },
        clearGeofence() {
            this.clearDrawing();
            this.geofenceDrawings.forEach((draw) => {
                draw.setMap(null);
            });
            this.geofenceDrawings = [];

            this.geofenceAction.forEach((geofence) => {
                geofence.action.remove();
                geofence.action.isDisplay = false;
            });
            this.geofenceAction = [];
        },
        handleTeamRegionChanged(value) {
            this.setTeamRegion(value);
            this.isTeamRegionId = !this.currentShape.teamRegionId && !!this.user.teamRegionId;
        },
        async getGeofenceData() {
            this.clearGeofence();
            const geofenceArr = await this.$store.dispatch('map/FETCH_GEO_FENCE', this.selectedTeamRegionId);
            this.geofenceArr = geofenceArr.data;
            this.map.isVisible = this.showGeofenceList && this.geofenceArr.length > 0;
            if (this.showGeofenceList) {
                this.displayGEOFence();
            }
        },
        setTeamRegion(value) {
            this.currentShape.teamRegionId = value || -1; // not set
        }
    }
};
</script>

<style lang="scss" scoped>
span.geo-icons {
    transform: scale(0.7);
}
.collapsed {
    transition: opacity 1s;
}
.geo-fence-desc {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: table;
    -webkit-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease;
    .modal-main {
        position: absolute;
        width: 250px;
        z-index: 999;
        background: #fff;
        padding: 10px;
        border-radius: 10px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .modal-header {
        padding: 5px;
    }
    .modal-body {
        padding: 5px;
    }
    .modal-footer {
        justify-content: center;
        padding: 5px;
    }
}
.geo-panel {
    display: flex;
    .title {
        flex-grow: 1;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
    }
    .geo-icons {
        padding-left: 4px;
    }
    .md-icon {
        transition: 0.3s linear;
    }
}
.geofence-wrapper {
    position: absolute;
    top: 50px;
    z-index: 2;
    background: #fff;
    padding: 3px 5px;
    left: 15px;
    border-bottom: 1px solid #e8e8e8;
    margin-top: 10px;
    width: 155px;
    transition: 0.2s linear;

    &.showing-geo-list {
        width: 240px;
    }
    &.drawing-geo-fence {
        width: 175px;
    }
    .geo-main-wrapper {
        padding: 5px;
    }

    a,
    a:hover {
        color: #222;
        font-size: 0.9rem;
        cursor: pointer;
        display: flex;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0px;
        font-size: 14px;

        .cta-list {
            display: none;
        }

        li {
            cursor: pointer;
            font-size: 14px;
            display: flex;
            padding: 3px 0px;
            &:hover {
                font-weight: bold;
                .cta-list {
                    display: unset;
                }
            }
            a.name {
                color: #2b93ff;
                max-width: 80%;
            }

            .name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .md-icon {
                width: 14px;
                height: 14px;
                font-size: 14px !important;
                margin-right: 0px;
                margin-left: 0px;
                &.user-pin {
                    transform: rotate(90deg);
                }
            }
        }
        .pushed-right {
            margin-left: auto;
        }
        .highlight-item {
            font-weight: bold;
            ::v-deep .cta-list {
                display: unset;
            }
        }
    }

    .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid\9;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }
    .add-new {
        cursor: pointer;
        background: #2b93ff;
        padding: 5px;
        border-radius: 3px;
        color: #fff;
        font-weight: bold;
    }
    .md-checkbox {
        margin: 0;
    }
    .md-checkbox-container {
        width: 15px;
        height: 15px;
        min-width: 15px;
    }
}

.drawing-tool {
    margin-left: 20px;

    .small {
        line-height: 1.2;
        margin-bottom: 5px;
    }
    .gmnoprint {
        display: flex;
        justify-content: center;
    }
    .gmnoprint-child {
        float: left;
        line-height: 0;
        &.active button {
            border: 1px solid #2b93ff;
        }
    }
    button {
        background: none padding-box transparent;
        display: block;
        border: 0px;
        margin: 0px;
        padding: 4px;
        text-transform: none;
        appearance: none;
        position: relative;
        cursor: pointer;
        user-select: none;
        direction: ltr;
        overflow: hidden;
        text-align: left;
        font-family: Roboto, Arial, sans-serif;
        font-size: 11px;
        border: 1px solid #e8e8e8;
        span {
            display: inline-block;
        }
        div {
            width: 16px;
            height: 14px;
            overflow: hidden;
            position: relative;
        }
        img {
            position: absolute;
            left: 0px;
            user-select: none;
            border: 0px;
            padding: 0px;
            margin: 0px;
            max-width: none;
            width: 16px;
            height: 192px;
        }
    }
    .button-pressed {
        background: #e5e5e5;
        -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
        -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
        box-shadow: inset 0px 0px 5px #c1c1c1;
        outline: none;
    }
    .drawing-button {
        color: rgb(0, 0, 0);
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        font-weight: 500;
        img {
            top: -144px;
        }
    }
    .circle-button {
        color: rgb(86, 86, 86);
        img {
            top: -161px;
        }
    }
    .polygon-button {
        color: rgb(86, 86, 86);
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
        img {
            top: -65px;
        }
    }
}

.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
    padding: 15px 0;
}
.md-field + .md-has-textarea:not(.md-autogrow) {
    margin-top: 0;
}
.showing-geo-list .toggle-list .md-icon {
    color: #2b93ff;
}
.toggle-add {
    transition: 0.3s linear;
}
.fade-enter-active {
    transition: all 0.3s ease;
}
.fade-enter, .fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    display: none;
    overflow: hidden;
}
.geo-list-wrapper {
    background: #fff;
    padding: 3px 5px;
    font-size: 0.8rem;
    max-height: 300px;
    overflow: auto;
    border-top: 1px solid #2b93ff;
}
::v-deep .md-card-content {
    padding: 0;
}
::v-deep .md-table-head {
    padding: 0;
}
::v-deep .md-table-head-label {
    font-size: 0.83rem;
    font-weight: 800;
}
map-overlay {
    ::v-deep .geo-fence-name {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
    }
}
</style>
