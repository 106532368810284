/* eslint-disable import/no-cycle */
import {
    FETCH_GENERAL_SETTINGS,
    SET_TEAM_GENERAL_SETTINGS,
    FETCH_TEAM_ACCOUNTING_PACKAGE,
    SET_TEAM_ACCOUNTING_PACKAGE
} from '@/store/types';
import { handleRequests } from '@/helpers/index';

const INIT_STATE = () => ({
    defaultSpeedUnit: null,
    defaultCurrency: null,
    accountingPackage: null,
    companyLogo: null,
    isTwoFAEnabled: false
});

const getters = {
    getGeneralSettings: (state) => state,
    hasAccountingPackage: (state) => {
        return state.accountingPackage != null && state.accountingPackage !== '';
    }
};

const mutations = {
    [SET_TEAM_GENERAL_SETTINGS]: (state, payload) => {
        state.defaultSpeedUnit = payload.defaultSpeedUnits;
        state.defaultCurrency = payload.currency;
        state.companyLogo = payload.companyLogo;
        state.isTwoFAEnabled = payload.isTwoFAEnabled;
    },
    [SET_TEAM_ACCOUNTING_PACKAGE]: (state, payload) => {
        state.accountingPackage = payload;
    }
};

const actions = {
    async [FETCH_GENERAL_SETTINGS]({ commit }) {
        const url = '/api/teams/settings/general';
        const { data } = await handleRequests(url);
        commit(SET_TEAM_GENERAL_SETTINGS, data);
        return data;
    },
    async [FETCH_TEAM_ACCOUNTING_PACKAGE]({ commit }) {
        const url = '/api/teams/accounting-package';
        const { data } = await handleRequests(url);
        commit(SET_TEAM_ACCOUNTING_PACKAGE, data);
        return data;
    }
};

const state = () => ({ ...INIT_STATE() });

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
