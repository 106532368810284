<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.apps') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>apps</md-icon>
                    </div>
                </md-card-header>

                <md-card-content>
                    <div class="cf-container">
                        <div class="cf-action-container">
                            <md-button title="Add App" class="md-primary md-just-icon md-round" @click="create()">
                                <md-icon>add</md-icon>
                            </md-button>
                        </div>
                        <md-table class="custom-paginated-table">
                            <md-table-row>
                                <md-table-head>Name</md-table-head>
                                <md-table-head>Client Id</md-table-head>
                                <md-table-head>Client Secret</md-table-head>
                                <md-table-head>Actions</md-table-head>
                            </md-table-row>
                            <md-table-row v-for="app in appList" :key="app.appId" :value="app.appName">
                                <md-table-cell>
                                    {{ app.appName }}
                                </md-table-cell>
                                <md-table-cell>
                                    {{ app.clientId }}
                                </md-table-cell>
                                <md-table-cell>
                                    {{ app.clientSecret }}
                                </md-table-cell>
                                <md-table-cell class="action-buttons">
                                    <md-button
                                        title="Edit Name"
                                        class="md-info md-just-icon md-round"
                                        @click="beginEdit(app)"
                                    >
                                        <md-icon>edit</md-icon>
                                    </md-button>
                                    <md-button
                                        title="Regenerate Secret"
                                        class="md-success md-just-icon md-round"
                                        @click.stop="regenerateKey(app)"
                                    >
                                        <md-icon>refresh</md-icon>
                                    </md-button>
                                    <md-button
                                        title="Delete App"
                                        class="md-danger md-just-icon md-round"
                                        @click.stop="deleteApp(app)"
                                    >
                                        <md-icon>delete</md-icon>
                                    </md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>
<script>
import { handleRequests } from '@/helpers';
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import AppNameModal from './AppNameModal';
// eslint-disable-next-line import/no-dynamic-require
const { config } = require(`../../config/config.${process.env.NODE_ENV}`);

export default {
    name: 'Apps',
    mixins: [GeneralMixin],
    data() {
        return {
            appList: [],
            edit: false
        };
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam'
        })
    },
    async mounted() {
        this.list();
    },
    methods: {
        list() {
            const me = this;
            me.$_handleLoaderState(true);
            handleRequests(`${config.authority}api/apps`)
                .then((response) => {
                    const { data } = response;
                    data.forEach((app) => {
                        app.edit = false;
                    });
                    me.appList = data;
                    me.$_handleLoaderState(false);
                })
                .catch(() => {
                    me.$_handleLoaderState(false);
                });
        },
        create() {
            const me = this;
            me.$_handleLoaderState(true, 'CREATING...');
            handleRequests(`${config.authority}api/apps/create`, {
                method: 'post'
            })
                .then((response) => {
                    me.newApp = response.data;
                    me.$_handleLoaderState(false);
                    me.$messageBox
                        .show({
                            title: 'App Created',
                            body: `A new app has been created. The secret will not be displayed again, so please copy and save it :\n ${
                                me.newApp.clientSecret
                            }`,
                            buttons: ['Confirm']
                        })
                        .then(() => {
                            me.newApp.clientSecret = me.hideSecret(me.newApp.clientSecret);
                            me.newApp.edit = false;
                            me.appList.push(me.newApp);
                        });
                })
                .catch(() => {
                    me.$_handleLoaderState(false);
                });
        },
        regenerateKey(app) {
            const me = this;
            me.$messageBox
                .show({
                    title: 'Regenerate Secret',
                    body:
                        'All integrations using the existing secret will stop working! Are you sure you want to regenerate the secret?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        me.$_handleLoaderState(true, 'GENERATING...');
                        handleRequests(`${config.authority}api/apps/${app.appId}/regenerate-secret`, {
                            method: 'post'
                        })
                            .then((response) => {
                                me.$_handleLoaderState(false);
                                me.$messageBox
                                    .show({
                                        title: 'Secret Regenerated',
                                        body: `A new secret has been generated. This will not be displayed again, so please copy the secret and save it :\n ${
                                            response.data.clientSecret
                                        }`,
                                        buttons: ['Confirm']
                                    })
                                    .then(() => {
                                        app.clientSecret = me.hideSecret(response.data.clientSecret);
                                    });
                            })
                            .catch(() => {
                                me.$_handleLoaderState(false);
                            });
                    }
                });
        },
        deleteApp(app) {
            this.$messageBox
                .show({
                    title: 'Delete App',
                    body: 'Are you sure you want to delete this app?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmDelete(app);
                });
        },
        beginEdit(app) {
            this.$modal
                .show(AppNameModal, {
                    data: {
                        appId: app.appId,
                        appName: app.appName
                    }
                })
                .then((result) => {
                    app.appName = result.appName;
                });
        },
        async handleConfirmDelete(app) {
            const me = this;
            me.$_handleLoaderState(true, 'DELETING...');
            await handleRequests(`${config.authority}api/apps/${app.appId}`, {
                method: 'delete'
            })
                .then(() => {
                    me.$_handleLoaderState(false);
                    me.appList.splice(me.appList.indexOf(app), 1);
                    me.$notify({
                        message: `App ${app.appName} was deleted!`,
                        type: 'success'
                    });
                })
                .catch(() => {
                    me.$_handleLoaderState(false);
                    me.$notify({
                        message: 'Could not delete the app',
                        type: 'danger'
                    });
                });
        },
        hideSecret(secret) {
            return `${secret.substr(0, 3)}${''.padStart(secret.length - 7, '*')}${secret.substr(secret.length - 4, 4)}`;
        }
    }
};
</script>
<style lang="scss" scoped>
md-card {
    width: 320px;
    margin: 4px;
    display: inline-block;
    vertical-align: top;
}
label {
    font-weight: 500;
}
.title {
    outline: none;
    border: none;
}
.card-icon {
    background: #384553 !important;
}
</style>
