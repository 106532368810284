<template>
    <span :class="`status status-${status.toLowerCase().replace(/\s+/g, '')}`">{{ status }}</span>
</template>

<script>
export default {
    name: 'StopLinkRenderer',
    data() {
        return {
            status: ''
        };
    },
    beforeMount() {
        this.status = this.params.value.status;
    }
};
</script>
<style lang="scss" scoped>
.status {
    color: #fff !important;
    min-width: 90px;
    text-align: center;
    display: inline-block;
    line-height: 31px;
    font-weight: 400;
    max-width: 90px;
    cursor: pointer;
}
</style>
