<template>
    <div>
        <div v-if="!isListLoading">
            <div v-if="!hasInvitations" class="text-center">
                <p>
                    It seems that you haven't invited anyone to your team. Click the button below to start inviting
                    people.
                </p>
                <button class="custom-btn" @click="onInviteMember">
                    Invite Member
                </button>
            </div>
            <div v-else>
                <md-table class="custom-paginated-table">
                    <md-table-row>
                        <md-table-head>Email</md-table-head>
                        <md-table-head>Role</md-table-head>
                        <md-table-head>Status</md-table-head>
                        <md-table-head>Invited Date</md-table-head>
                        <md-table-head>Accepted Date</md-table-head>
                        <md-table-head>Invited By</md-table-head>
                        <md-table-head>Actions</md-table-head>
                    </md-table-row>

                    <md-table-row v-for="(item, index) in invitationList" :key="index">
                        <md-table-cell>
                            {{ item.email }}
                        </md-table-cell>
                        <md-table-cell>
                            {{ item.role }}
                        </md-table-cell>
                        <md-table-cell class="status-container">
                            <span :class="`status-${item.status.toLowerCase()} invite-status`">
                                {{ item.status.toLowerCase() }}
                            </span>
                        </md-table-cell>
                        <md-table-cell>
                            {{ item.createdDate | dateFormat(DATE_TYPES.standardDate) }}
                        </md-table-cell>
                        <md-table-cell>
                            {{ item.acceptedDate | dateFormat(DATE_TYPES.standardDate) }}
                        </md-table-cell>
                        <md-table-cell>
                            {{ item.inviterName }}
                        </md-table-cell>
                        <md-table-cell class="action-buttons">
                            <md-button
                                v-if="item.status == 'PENDING'"
                                title="Resend Invite"
                                class="md-primary md-just-icon md-round btn-size-27"
                                @click="resend(item.teamInviteId)"
                            >
                                <md-icon>email</md-icon>
                            </md-button>
                            <md-button
                                v-if="item.status == 'PENDING'"
                                title="Revoke Invite"
                                class="md-danger md-just-icon md-round btn-size-27"
                                @click="revoke(item.teamInviteId, index)"
                            >
                                <md-icon>cancel</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
                <div v-if="invitationList.length == 0 && !isLoading">
                    <p class="no-result-message">No results matching your search/filter could be found.</p>
                </div>

                <md-card-actions class="page-footer" md-alignment="space-between">
                    <div>
                        <p v-if="total === pagination.perPage" class="card-category">
                            Page {{ pagination.currentPage }} of many
                        </p>
                        <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                    </div>
                    <pagination
                        v-model="pagination.currentPage"
                        class="pagination-no-border pagination-success"
                        :per-page="pagination.perPage"
                        :total="total"
                        @change-page="handleChangePage($event, pagination.perPage)"
                    />
                </md-card-actions>
            </div>
        </div>
        <div v-else>
            <div class="body-list">
                <div class="list-loader">
                    <fade-loader :loading="isListLoading" color="#333333" />
                    <span>LOADING</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Pagination } from '@/components';
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { handleRequests, showErrorMessage } from '@/helpers';

export default {
    name: 'InvitesTable',
    components: {
        Pagination,
        FadeLoader
    },
    mixins: [GeneralMixin],
    props: {
        newInviteId: {
            type: Number,
            default: null
        },
        searchText: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            pagination: PAGINATION_DEFAULTS,
            isListLoading: false,
            invitationList: [],
            hasInvitations: false,
            filters: {},
            maxPage: 1
        };
    },
    watch: {
        newInviteId(newValue) {
            this.handleChangePage();
        },
        searchText(newValue) {
            this.handleFilterOrSearch({ searchText: newValue });
        }
    },
    async mounted() {
        this.isListLoading = true;
        let currentPage = 1;
        const { type } = this.$route.params;
        if (type === 'list-of-invites') {
            currentPage = Number(this.$route.query.currentPage) || 1;
        }
        this.pagination.currentPage = currentPage;

        await this.fetchInvitationList(currentPage);
        if (this.invitationList.length > 0) {
            this.hasInvitations = true;
        }
        this.isListLoading = false;
    },
    methods: {
        async fetchInvitationList(pageNumber = 1, itemsPerPage = 50) {
            const endpoint = `/api/team-members/list/invites`;
            const response = await handleRequests(endpoint, {
                params: {
                    pageNumber,
                    itemsPerPage,
                    ...this.cleanFilters()
                }
            });
            const {
                data: { invitations, totalInvitations }
            } = response;
            this.$emit('fetchInvitationList', invitations);
            this.assignToList(invitations, totalInvitations || invitations.length);
        },
        assignToList(invitations, totalInvitations) {
            this.invitationList = invitations;
            this.pagination.total = totalInvitations;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },
        getMaxPage(page) {
            this.maxPage = page;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.$_handleLoaderState(true);
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            await this.fetchInvitationList(currentPage, perPage);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            if (this.invitationList.length > 0) {
                this.hasInvitations = true;
            }
            this.$_handleLoaderState(false);
        },
        async handleFilterOrSearch(val) {
            this.isListLoading = true;
            this.filters = Object.assign(this.filters, val);
            this.pagination.currentPage = 1;
            await this.fetchInvitationList(1, this.pagination.perPage);
            this.$router.replace({ path: this.$route.path, query: { currentPage: 1 } });
            this.isListLoading = false;
        },
        resend(inviteId) {
            this.$_handleLoaderState(true, 'RESENDING...');
            const payload = {
                method: 'post'
            };
            const api = `/api/team-members/invite/${inviteId}/resend`;
            handleRequests(api, payload).then(
                (response) => {
                    const result = response.data.data;
                    this.$_handleLoaderState(false);
                    this.$notify({
                        message: 'Invite successfully sent!',
                        type: 'success'
                    });
                    this.invitationList.find((res) => res.teamInviteId === inviteId).status = result.status;
                    this.invitationList.find((res) => res.teamInviteId === inviteId).createdDate = result.createdDate;
                    this.invitationList.find((res) => res.teamInviteId === inviteId).acceptedDate = result.acceptedDate;
                },
                (error) => {
                    this.$_handleLoaderState(false);
                    const message = 'Error in resending invite';
                    showErrorMessage(this, message, error);
                }
            );
        },
        revoke(inviteId, index) {
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'post'
            };
            const api = `/api/team-members/invite/${inviteId}/revoke`;
            handleRequests(api, payload).then(
                (response) => {
                    this.$_handleLoaderState(false);
                    this.$notify({
                        message: 'Invite revoked!',
                        type: 'success'
                    });
                    this.invitationList.find((res) => res.teamInviteId === inviteId).status = response.data;
                },
                (error) => {
                    this.$_handleLoaderState(false);
                    const message = 'Error in revoking the invite';
                    showErrorMessage(this, message, error);
                }
            );
        },
        onInviteMember() {
            this.$emit('inviteTeamMember');
        }
    },
    computed: {
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        ...mapGetters({
            isLoading: 'isLoading'
        })
    }
};
</script>
<style lang="scss" scoped>
::v-deep table > tbody > tr > td:first-child {
    max-width: 300px;
}
::v-deep .custom-btn {
    margin-top: 0;
    margin-bottom: 0;
}

::v-deep table > tbody > tr > td:last-child {
    width: 120px !important;
}

.custom-paginated-table {
    .status-container {
        padding: 5px;
    }
    .invite-status {
        text-transform: capitalize;
        width: 100px;
        display: block;
        text-align: center;
        padding: 5px 10px;
    }
}
</style>
