<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 id="createJobOfferSettingModalTitle" class="modal-title">
                {{ isUpdate ? 'Update' : 'Create' }} Job Offer Band
            </h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.offerBandModel" class="form-wrapper">
                <form-group name="band_name" label="Name">
                    <md-input v-model="offerBandModel.band_name" />
                </form-group>
                <form-group name="band_minProximity" label="Mininum Proximity">
                    <DistanceInput
                        :distance="offerBandModel.band_minProximity"
                        :unit="offerBandModel.band_minProximityUnit"
                        @changed="handleMinProximityChanged"
                    />
                </form-group>
                <form-group name="band_maxProximity" label="Max Proximity">
                    <DistanceInput
                        :distance="offerBandModel.band_maxProximity"
                        :unit="offerBandModel.band_maxProximityUnit"
                        @changed="handleMaxProximityChanged"
                    />
                </form-group>
                <form-group name="band_sendingMode" label="Sending Mode">
                    <md-select v-model="offerBandModel.band_sendingMode">
                        <md-option v-for="item in sendingModeOptions" :key="item.value" :value="item.value">
                            {{ item.text }}
                        </md-option>
                    </md-select>
                </form-group>
                <form-group
                    v-if="offerBandModel.band_sendingMode == 'DripFeed'"
                    name="band_dripFeedDelay"
                    label="Drip Feed Delay"
                >
                    <TimeSpanInput
                        v-model="offerBandModel.band_dripFeedDelay"
                        :value="offerBandModel.band_dripFeedDelay"
                        @changed="handleDripFeedDelayChanged"
                    />
                </form-group>
                <form-group name="band_nextBandDelay" label="Next Band Delay">
                    <TimeSpanInput
                        v-model="offerBandModel.band_nextBandDelay"
                        :value="offerBandModel.band_nextBandDelay"
                        @changed="handleNextBandDelayChanged"
                    />
                </form-group>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button id="btnUpdate" class="dialog-button md-primary" @click.prevent="validate">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import DistanceInput from '@/components/DistanceInput';
import TimeSpanInput from '@/components/TimeSpanInput';
import { JOB_OFFER_SENDING_MODE_OPTIONS } from '@/utils/constants';
import { handleRequests, showErrorMessage } from '@/helpers';
import { required, requiredIf, minValue, maxValue } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'CreateJobOfferBandModal',
    components: { DistanceInput, TimeSpanInput },
    mixins: [GeneralMixin],
    props: {
        offerSetting: { type: Object, default: () => null },
        offerBand: { type: Object, default: () => null },
        isUpdate: { type: Boolean, default: false },
        resolve: { type: Function, default: () => {} }
    },
    data() {
        return {
            offerBandModel: {
                band_id: null,
                band_order: 0,
                band_name: null,
                band_minProximity: 0,
                band_minProximityUnit: 'km',
                band_minProximityMeters: 0,
                band_maxProximity: 0,
                band_maxProximityUnit: 'km',
                band_maxProximityMeters: 0,
                band_sendingMode: 'Broadcast',
                band_dripFeedDelay: null,
                band_nextBandDelay: '0.00:01:00' // 1 minute
            },
            nextBandDelayValue: null,
            sendingModeOptions: JOB_OFFER_SENDING_MODE_OPTIONS
        };
    },
    validations() {
        return {
            offerBandModel: {
                band_name: { required },
                band_minProximity: { required, minValue: minValue(0), maxValue: maxValue(999) },
                band_minProximityUnit: { required },
                band_maxProximity: { required, minValue: minValue(0), maxValue: maxValue(999) },
                band_maxProximityUnit: { required },
                band_sendingMode: { required },
                band_dripFeedDelay: {
                    required: requiredIf((val) => {
                        return this.offerBandModel && this.offerBandModel.band_sendingMode === 'DripFeed';
                    })
                },
                band_nextBandDelay: { required }
            }
        };
    },
    watch: {
        'offerBandModel.sendingMode': function(newVal, oldVal) {
            if (!newVal || newVal !== 'DripFeed') {
                this.offerBandModel.dripFeedDelay = null;
            }
        }
    },
    methods: {
        async validate() {
            if (this.isUpdate) {
                await this.update();
            } else {
                await this.create();
            }
        },
        async create() {
            this.$v.offerBandModel.$touch();
            if (this.$v.offerBandModel.$invalid) 
                return;

            const dataModel = this.parseModelToRequestModel(this.offerBandModel);

            this.$_handleLoaderState(true, 'CREATING...');

            try {
                const api = `/api/teams/settings/job-offers/bands/?teamRegionId=${this.offerSetting.teamRegionId}`;
                const payload = {
                    method: 'post',
                    data: dataModel
                };

                const { data } = await handleRequests(api, payload);

                this.$notify({
                    message: 'Successfully created a new band.',
                    type: 'success'
                });

                this.resolve({ result: 'ok', data });
            } catch (e) {
                const message = 'Could not create a new band.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async update() {
            this.$v.offerBandModel.$touch();
            if (this.$v.offerBandModel.$invalid) 
                return;

            const dataModel = this.parseModelToRequestModel(this.offerBandModel);

            this.$_handleLoaderState(true, 'UPDATING...');

            try {
                const api = `/api/teams/settings/job-offers/bands/${dataModel.id}/?teamRegionId=${
                    this.offerSetting.teamRegionId
                }`;
                const payload = {
                    method: 'put',
                    data: dataModel
                };

                const { data } = await handleRequests(api, payload);

                this.$notify({
                    message: 'Successfully updated a band.',
                    type: 'success'
                });

                this.resolve({ result: 'ok', data });
            } catch (e) {
                const message = 'Could not update band.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        handleMinProximityChanged(args) {
            this.offerBandModel = {
                ...this.offerBandModel,
                band_minProximity: args?.distance || 0,
                band_minProximityUnit: args?.unit || 'km',
                band_minProximityMeters: args?.distanceInMeters || 0
            };
        },
        handleMaxProximityChanged(args) {
            this.offerBandModel = {
                ...this.offerBandModel,
                band_maxProximity: args?.distance || 0,
                band_maxProximityUnit: args?.unit || 'km',
                band_maxProximityMeters: args?.distanceInMeters || 0
            };
        },
        handleDripFeedDelayChanged(args) {
            this.offerBandModel = {
                ...this.offerBandModel,
                band_dripFeedDelay: args.formatted
            };
        },
        handleNextBandDelayChanged(args) {
            this.offerBandModel = {
                ...this.offerBandModel,
                band_nextBandDelay: args.formatted
            };
            this.nextBandDelayValue = args;
        },
        parseModelToRequestModel(model) {
            const requestModel = {
                id: model.band_id,
                order: model.band_order,
                name: model.band_name,
                minProximity: model.band_minProximity,
                minProximityUnit: model.band_minProximityUnit,
                minProximityMeters: model.band_minProximityMeters,
                maxProximity: model.band_maxProximity,
                maxProximityUnit: model.band_maxProximityUnit,
                maxProximityMeters: model.band_maxProximityMeters,
                sendingMode: model.band_sendingMode,
                dripFeedDelay: model.band_dripFeedDelay,
                nextBandDelay: model.band_nextBandDelay
            };

            return requestModel;
        }
    },
    mounted() {
        if (this.offerBand) {
            this.offerBandModel = {
                band_id: this.offerBand.id,
                band_order: this.offerBand.order,
                band_name: this.offerBand.name,
                band_minProximity: this.offerBand.minProximity,
                band_minProximityUnit: this.offerBand.minProximityUnit,
                band_minProximityMeters: this.offerBand.minProximityMeters,
                band_maxProximity: this.offerBand.maxProximity,
                band_maxProximityUnit: this.offerBand.maxProximityUnit,
                band_maxProximityMeters: this.offerBand.maxProximityMeters,
                band_sendingMode: this.offerBand.sendingMode,
                band_dripFeedDelay: this.offerBand.dripFeedDelay,
                band_nextBandDelay: this.offerBand.nextBandDelay
            };
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-checkbox-container {
    top: 10px;
}

::v-deep .md-checkbox-label {
    opacity: 1 !important;
    color: black !important;
}

::v-deep div.md-field.no-underline:after {
    height: 0px;
}

::v-deep .md-checkbox {
    margin-top: 0px;
}
</style>
