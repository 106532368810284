<script>
import { ref, toRefs, inject, computed, onMounted, watch, onBeforeUnmount } from '@vue/composition-api';
import useMapUtils from '@/compostables/useMapUtils';

export default {
    name: 'LocationHistoryRoutePolyline',
    props: {
        locationHistoryDetails: {
            type: Array,
            default: () => []
        },
        pinnedDriverDetails: {
            type: Object,
            default: () => {}
        }
    },
    setup(props, context) {
        const { getters } = inject('vuex-store');
        const google = inject('google');
        const map = inject('map');
        const { createRouteLine } = useMapUtils(context);
        const { locationHistoryDetails } = toRefs(props);
        const { pinnedDriverDetails } = props;

        const timeWindow = computed(() => getters['map/timeWindow']);
        const isLocationHistoryToggled = computed(() => getters['map/isLocationHistoryToggled']);
        const isPlaying = computed(() => getters['map/isPlaying']);

        const routePolyline = ref(null);

        const routeOptions = {
            strokeOpacity: 0,
            fillOpacity: 0,
            icons: [
                {
                    icon: {
                        path: google.value.maps.SymbolPath.CIRCLE,
                        strokeOpacity: 1,
                        fillOpacity: 1,
                        scale: 2
                    },
                    offset: '0',
                    repeat: '8px'
                }
            ],
            zIndex: 3
        };

        const getPolylinePath = (data, timeWindow) => {
            const [start, end] = timeWindow;

            return data
                .filter((d) => {
                    const { minutesFromMidnight, isHB } = d;
                    if (isHB) 
                        return false;
                    return minutesFromMidnight >= start && minutesFromMidnight <= end;
                })
                .map((m) => {
                    const { latitude: lat, longitude: lng } = m.coordinates;
                    return { lat, lng };
                });
        };

        const clearData = () => {
            routePolyline.value.setMap(null);
            routePolyline.value = null;
        };

        onMounted(() => {
            const locationDetails = locationHistoryDetails.value.slice(0);
            const paths = getPolylinePath(locationDetails, timeWindow.value);
            const {
                markerPins: { color }
            } = pinnedDriverDetails;
            routePolyline.value = createRouteLine(google.value, paths, color, routeOptions, map.value);
            if (!isLocationHistoryToggled.value) 
                routePolyline.value.setMap(null);
        });

        watch(timeWindow, (newVal) => {
            const paths = getPolylinePath(locationHistoryDetails.value, newVal);
            routePolyline.value.setPath(paths);
        });

        watch(locationHistoryDetails, (newVal) => {
            if (newVal.length) {
                const paths = getPolylinePath(locationHistoryDetails.value, newVal);
                routePolyline.value.setPath(paths);
            } else 
                clearData();
        });

        watch(isLocationHistoryToggled, (newVal) => {
            routePolyline.value.setMap(newVal ? map.value : null);
        });

        watch(isPlaying, (newVal) => {
            routePolyline.value.setMap(newVal ? null : map.value);
        });

        onBeforeUnmount(() => {
            clearData();
        });

        return {
            routePolyline
        };
    },
    render: () => null
};
</script>
