<template>
    <div class="modal-container assign-trip-model">
        <div class="modal-header">
            <h4 class="modal-title">Change Team Member</h4>
        </div>
        <div class="modal-body">
            <form-group name="TripTeamMember">
                <md-select v-model="assignedUser">
                    <md-option
                        v-for="member in orderedTeamMembers"
                        :key="member.publicUserId"
                        :value="member.publicUserId"
                    >
                        {{ member.fullName || member.publicUserId }}
                    </md-option>
                </md-select>
            </form-group>
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="onChangeTeamMember">
                Change
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChangeTripTeamMemberModal',
    props: {
        teamMembers: {
            type: Array,
            default: () => []
        },
        assignedTo: {
            type: String,
            default: ''
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            assignedUser: null
        };
    },
    mounted() {
        if (this.assignedTo) {
            this.assignedUser = this.teamMembers.find((member) => member.publicUserId === this.assignedTo).publicUserId;
        }
    },
    methods: {
        onChangeTeamMember() {
            const { assignedTo, assignedUser } = this;
            this.resolve({ assignedTo, assignedUser });
        }
    },
    computed: {
        orderedTeamMembers() {
            return this.teamMembers.slice().sort((x, y) => (x.fullName > y.fullName ? 1 : -1));
        }
    }
};
</script>
<style lang="scss" scoped>
.assign-trip-model {
    max-width: 350px;
}
</style>
