<template>
    <div v-if="showPreview">
        <div class="reminder-indicators" v-for="(item, index) in list" :key="index">
            <div>
                <div v-if="item.time != null">
                    <span class="material-icons">today</span>
                    {{ item.time.due | dateFormat(DATE_TYPES.standardDate) }}
                    <span :class="item.time.status != null ? `status-${item.time.status}` : ''" class="due-remaining">
                        ({{ item.time.remaining }})
                    </span>
                </div>
                <div v-if="item.engineHours != null">
                    <span class="material-icons">hourglass_full</span>
                    {{ item.engineHours.due }} hours
                    <span
                        :class="item.engineHours.status != null ? `status-${item.engineHours.status}` : ''"
                        class="due-remaining"
                    >
                        ({{ item.engineHours.remaining }})
                    </span>
                </div>
                <div v-if="item.distance != null">
                    <span class="material-icons">edit_road</span>
                    {{ item.distance.due }} {{ distanceUnit }}
                    <span
                        :class="item.distance.status != null ? `status-${item.distance.status}` : ''"
                        class="due-remaining"
                    >
                        ({{ item.distance.remaining }})
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import moment from 'moment';
import later from '@breejs/later';

export default {
    name: 'ReminderIndicator',
    mixins: [GeneralMixin],
    props: {
        maintenanceData: {
            type: Object,
            default: () => {}
        },
        currentEngineHours: {
            type: [Number, String],
            default: null
        },
        nextEngineHourDue: {
            type: [Number, String],
            default: null
        },
        currentDistanceReading: {
            type: [Number, String],
            default: null
        },
        nextDueDistance: {
            type: [Number, String],
            default: null
        },
        repeatInterval: {
            type: Number,
            default: 4
        },
        nextDueDate: {
            type: [Date, String],
            default: null
        }
    },
    computed: {
        ...mapGetters({
            generalSettings: 'generalSetting/getGeneralSettings'
        })
    },
    data() {
        return {
            engineHourInterval: null,
            distanceInterval: null,
            timeBasedInterval: null,
            showPreview: false,
            distanceUnit: null,
            today: null,
            list: []
        };
    },
    mounted() {
        this.today = moment();
        this.distanceUnit = this.maintenanceData.odometerUnitType ?? this.maintenanceData.odometerReminderUnitType;

        if (
            (this.currentEngineHours && this.nextEngineHourDue) ||
            this.nextDueDate ||
            (this.currentDistanceReading && this.nextDueDistance)
        ) {
            this.showPreview = true;
            this.calculateNextRecurrence();
        }
    },
    methods: {
        calculateNextRecurrence() {
            this.list = [];
            let schedule = null;
            let occurences = null;

            if (this.maintenanceData.timeSchedule != null) {
                schedule = later.parse.cron(this.maintenanceData.timeSchedule);
                occurences = later.schedule(schedule).next(5, this.nextDueDate);

                if (moment(this.nextDueDate).format('YYYY-MM-DD') !== moment(occurences[0]).format('YYYY-MM-DD')) {
                    occurences[0] = moment(this.nextDueDate).format('YYYY-MM-DD');
                }
            }

            if (
                this.maintenanceData.timeScheduleInterval != null &&
                this.maintenanceData.timeScheduleUnitType != null
            ) {
                occurences = [];

                for (let i = 0; i < this.repeatInterval; i++) {
                    occurences.push(
                        moment(this.nextDueDate).add(
                            this.maintenanceData.timeScheduleInterval * (i + 1),
                            this.maintenanceData.timeScheduleUnitType
                        )
                    );
                }
            }

            for (let i = 0; i < this.repeatInterval; i++) {
                this.list.push({
                    // eslint-disable-next-line radix
                    time: this.generateTimeSchedule(occurences, i),
                    // eslint-disable-next-line radix
                    engineHours: this.generateEngineHourPreview(i),
                    // eslint-disable-next-line radix
                    distance: this.generateDistancePreview(i)
                });
            }
        },
        generateTimeSchedule(occurences, index) {
            if (!this.nextDueDate) 
                return null;

            if (index === 0 && this.nextDueDate == null) 
                return null;

            const obj = {};

            if (occurences == null) {
                obj.due = this.nextDueDate;
            } else {
                obj.due = occurences[index];
            }

            if (this.maintenanceData.timeScheduleStatus) {
                obj.status = this.maintenanceData.timeScheduleStatus.toLowerCase().replace(/\s+/g, '');
            }

            let remainingDays = moment().diff(moment(obj.due), 'days', true);
            // // moment substracts the date including time so it always show less 1 day to the display if it crosses the 12pm mark
            // // to prevent this from happening we manually handle the display based on the remaining days returned by moment.

            if (remainingDays >= 0 && remainingDays < 1) {
                obj.remaining = 'Today';
            } else if (remainingDays >= 1 && remainingDays < 2) {
                obj.remaining = '1 day ago';
                if (!obj.status) {
                    obj.status = 'overdue';
                }
            } else if (remainingDays < 0 && remainingDays > -1) {
                obj.remaining = `in 1 day`;
            } else if (remainingDays < 0) {
                remainingDays *= -1;
                obj.remaining = `in ${Math.ceil(remainingDays)} days`;
            } else {
                // we use Math.floor here as we dont want to include the current date in counting how many days has passed from the due date.
                obj.remaining = `${Math.floor(remainingDays)} days ago`;
                if (!obj.status) {
                    obj.status = 'overdue';
                }
            }

            return obj;
        },
        generateEngineHourPreview(index) {
            if (!this.nextEngineHourDue) 
                return null;

            if (this.maintenanceData.engineHoursReminderInterval == null && this.nextEngineHourDue == null) 
                return null;

            const obj = {};

            if (this.maintenanceData.engineHourStatus) {
                obj.status = this.maintenanceData.engineHourStatus.toLowerCase().replace(/\s+/g, '');
            }

            const remainingHours = this.maintenanceData.engineHoursReminderInterval * index;

            if (index === 0) {
                obj.due = this.nextEngineHourDue;
                obj.remaining = this.nextEngineHourDue - this.currentEngineHours;
            } else {
                obj.due = this.nextEngineHourDue + remainingHours;
                obj.remaining = obj.due - this.currentEngineHours;
            }

            if (this.currentEngineHours > this.nextEngineHourDue) {
                let remainingValue = Math.abs(obj.remaining);
                remainingValue = Math.round(remainingValue);
                obj.remaining = `${remainingValue} hours ago`;
                if (!obj.status) {
                    obj.status = 'overdue';
                }
            } else {
                obj.remaining = `in ${Math.round(obj.remaining)} hours`;
            }

            return obj;
        },
        generateDistancePreview(index) {
            if (!this.nextDueDistance) 
                return null;

            if (this.maintenanceData.odometerReminderInterval == null && this.nextDueDistance == null) 
                return null;

            const obj = {};

            if (this.maintenanceData.odometerStatus) {
                obj.status = this.maintenanceData.odometerStatus.toLowerCase().replace(/\s+/g, '');
            }

            const distance = this.maintenanceData.odometerReminderInterval * index;

            if (index === 0) {
                obj.due = this.nextDueDistance;
                obj.remaining = this.nextDueDistance - this.currentDistanceReading;
            } else {
                obj.due = this.nextDueDistance + distance;
                obj.remaining = obj.due - this.currentDistanceReading;
            }

            if (this.currentDistanceReading > this.nextDueDistance) {
                let remainingValue = Math.abs(obj.remaining);
                remainingValue = Math.round(remainingValue);

                obj.remaining = `${remainingValue} ${this.distanceUnit} ago`;
                if (!obj.status) {
                    obj.status = 'overdue';
                }
            } else {
                obj.remaining = `in ${Math.round(obj.remaining)} ${this.distanceUnit}`;
            }

            return obj;
        }
    }
};
</script>

<style lang="scss" scoped>
.reminder-indicators {
    .material-icons {
        vertical-align: middle;
    }
    > div {
        > div {
            margin-bottom: 8px;
        }
        > div:last-child {
            margin-bottom: 0;
        }
    }
}

.due-remaining {
    font-style: italic;
    color: #999;
}

.status-overdue {
    color: #f44336;
    font-weight: 500;
}

.status-duesoon {
    color: orange;
    font-weight: 500;
}
</style>
