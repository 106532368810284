<template>
    <div class="content">
        <div class="custom-toolbar">
            <div class="custom-toolbar-start">
                <h3 class="title">Carrier Marketplace</h3>
            </div>
        </div>
        <div class="app-parent-container" v-if="list.length > 0">
            <div v-for="item in list" :key="item.carrierTeamId" class="app-container">
                <div>
                    <div>
                        <div class="app-logo">
                            <img :src="item.logoUrl" v-if="item.logoUrl != null" />
                            <img v-else :src="$root.defaultLogoUrl" />
                        </div>
                    </div>
                    <div class="app-content">
                        <span>{{ item.company }}</span>
                        <p>{{ item.shortDescription }}</p>
                    </div>
                </div>
                <div class="btn-container">
                    <a
                        target="_blank"
                        :href="'/settings/capacity-settings/'"
                        v-if="
                            item.loadCapacitySettingsRequirement != null &&
                                item.loadCapacitySettingsRequirement.length > 0 &&
                                getCarrierStatus(item.installedCarrierStatus) != 'Connected'
                        "
                    >
                        <md-icon>
                            production_quantity_limits
                            <md-tooltip>This carrier requires capacity settings setup.</md-tooltip>
                        </md-icon>
                    </a>

                    <md-button
                        v-if="
                            getCarrierStatus(item.installedCarrierStatus) == 'Connect' ||
                                getCarrierStatus(item.installedCarrierStatus) == 'Connected' ||
                                getCarrierStatus(item.installedCarrierStatus) == 'Inactive'
                        "
                        @click.stop="openApp(item)"
                        :class="
                            getCarrierStatus(item.installedCarrierStatus) != 'Connect' ? 'md-default' : 'md-primary'
                        "
                        :disabled="
                            getCarrierStatus(item.installedCarrierStatus) == 'Connected' ||
                                getCarrierStatus(item.installedCarrierStatus) == 'Inactive'
                        "
                    >
                        {{ getCarrierStatus(item.installedCarrierStatus) }}
                    </md-button>
                    <md-button v-else :href="'/team/carriers/'" class="md-warning">
                        <md-tooltip>
                            By clicking this button this will redirect you to the carrier page to complete the setup for
                            this carrier.
                        </md-tooltip>
                        {{ getCarrierStatus(item.installedCarrierStatus) }}
                    </md-button>
                </div>
            </div>
        </div>
        <div v-else>No carriers available</div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';
import OnboardingWizard from './OnboardingWizard';

export default {
    name: 'Marketplace',
    mixins: [GeneralMixin],
    data() {
        return {
            list: [],
            smallAlertModalHide: false
        };
    },
    computed: {
        ...mapGetters({
            hasMarketplaceAccess: 'user/hasMarketplaceAccess'
        })
    },
    async mounted() {
        if (this.hasMarketplaceAccess) {
            await this.fetchCarrierList();
        }
    },
    methods: {
        openApp(app) {
            // check if offer method is setup if not ask them to setup first
            this.$modal.show(OnboardingWizard, { appItem: app }).then((response) => {
                if (response && response.toLowerCase() !== 'failed') {
                    app.installedCarrierStatus = response;
                    this.$modal.hide();
                }
            });
        },
        async fetchCarrierList() {
            this.$_handleLoaderState(true);
            try {
                const endpoint = `/api/carriers/marketplace`;
                const response = await handleRequests(endpoint);
                this.list = response.data.carriers;
            } catch (error) {
                const message = 'Error in getting the list of carriers. Please try again later';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        getCarrierStatus(status) {
            if (status == null) 
                return 'Connect';
            let displayStatus = null;

            switch (status) {
                case 'Active':
                    displayStatus = 'Connected';
                    break;
                case 'Inactive':
                    displayStatus = 'Connected';
                    break;
                case 'Onboarding':
                    displayStatus = 'Onboarding';
                    break;
                case 'PendingCarrierApproval':
                    displayStatus = 'Pending Carrier Approval';
                    break;
                case 'CarrierApproved':
                    displayStatus = 'Carrier Approved';
                    break;
                default:
                    displayStatus = 'Connect';
                    break;
            }

            return displayStatus;
        }
    }
};
</script>

<style lang="scss" scoped>
.app-parent-container {
    display: flex;
    flex-wrap: wrap;
    .app-container {
        background-color: #fff;
        display: table;
        width: calc((100% - 60px) / 3);
        padding: 10px;
        margin: 10px;
        margin-bottom: 10px;
        border-radius: 6px;

        > div > div {
            display: table-cell;
            vertical-align: top;
        }

        .btn-container {
            text-align: right;
            > div,
            > a,
            > button {
                display: inline-block;
                vertical-align: middle;
            }
        }

        .app-logo {
            min-width: 80px;
            border: 1px solid #eee;
            height: 80px;
            width: 80px;
            // padding: 10px;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: #eee;
            img {
                width: 100%;
            }
        }

        .app-content {
            span {
                font-weight: 600;
                margin-bottom: 5px;
            }
            p {
                font-size: 14px;
                line-height: 20px;
                display: -webkit-box;
                // max-width: 200px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-top: 0;
            }
            div {
                text-align: right;
            }
        }

        @media (min-width: 1600px) {
            width: calc((100% - 80px) / 4);
        }
    }
}

.alert-z {
    z-index: 0 !important;
}
</style>
