<template>
    <md-button
        v-if="type === 'icon'"
        title="Show quotes"
        :class="`md-just-icon md-round md-primary ${className}`"
        @click.stop="handleShowQuotes"
    >
        <md-icon>drive_eta</md-icon>
    </md-button>
</template>

<script>
// eslint-disable-next-line import/no-cycle, import/extensions
import { QuotesModal } from '@/components';

export default {
    name: 'QuotesButton',
    props: {
        shipment: {
            type: Object,
            default: () => null
        },
        className: {
            type: String,
            default: () => ''
        },
        type: {
            type: String,
            default: () => 'icon'
        }
    },
    methods: {
        handleShowQuotes() {
            this.$modal
                .show(QuotesModal, {
                    shipment: this.shipment
                })
                .then((response) => {
                    if (response.type && response.type === 'error') {
                        this.$modal.hide();
                        this.$emit('handleError', response.data);
                    } else {
                        this.$emit('assignedUser', response);
                        this.$modal.hide();
                    }
                });
        }
    }
};
</script>
