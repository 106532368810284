<template>
    <div class="user">
        <div class="photo">
            <img v-if="user.photoUrl" :src="user.photoUrl" alt="avatar" @error="$_setDefaultBrokenImage" />
            <img v-else :src="$root.defaultPhotoUrl" alt="" />
        </div>
        <div class="user-info">
            <a data-toggle="collapse" :aria-expanded="!isClosed" @click.stop="toggleMenu" @click.capture="clicked">
                <span class="info-fullname">
                    {{ user.fullName }}
                </span>
                <span>
                    <b class="caret" />
                </span>
            </a>

            <collapse-transition>
                <div v-show="!isClosed">
                    <ul class="nav">
                        <slot>
                            <li v-if="!isReadOnlyUser">
                                <router-link to="/profile">
                                    <span class="sidebar-mini">{{ $t('menus.user.myProfile-mini') }}</span>
                                    <span class="sidebar-normal">{{ $t('menus.user.myProfile') }}</span>
                                </router-link>
                            </li>
                            <li v-if="isIndividualUser && !isReadOnlyUser">
                                <router-link to="/profile/settings">
                                    <span class="sidebar-mini">{{ $t('menus.user.settings-mini') }}</span>
                                    <span class="sidebar-normal">{{ $t('menus.user.settings') }}</span>
                                </router-link>
                            </li>
                            <li>
                                <a href="#" @click="logout">
                                    <span class="sidebar-mini">{{ $t('menus.user.logOut-mini') }}</span>
                                    <span class="sidebar-normal">{{ $t('menus.user.logOut') }}</span>
                                </a>
                            </li>
                        </slot>
                    </ul>
                </div>
            </collapse-transition>
        </div>
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { CollapseTransition } from 'vue2-transitions';
import { mapGetters } from 'vuex';

export default {
    components: {
        CollapseTransition
    },
    data() {
        return {
            isClosed: true
        };
    },
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            user: 'user/user',
            isIndividualUser: 'user/isIndividualUser',
            hasTeamAccess: 'user/hasTeamAccess',
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    methods: {
        clicked(e) {
            e.preventDefault();
        },
        async logout() {
            this.$store.commit('user/RESET_STATE');
            const response = await auth.logout();
            // clear local storage
            window.localStorage.clear();
            return response;
        },
        toggleMenu() {
            this.isClosed = !this.isClosed;
        }
    }
};
</script>
<style scoped lang="scss">
.collapsed {
    transition: opacity 1s;
}

.info-fullname {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.user-info .caret {
    top: -12px !important;
}
</style>
