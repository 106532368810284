var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("div", { staticClass: "modal-container" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("h4", { staticClass: "modal-title" }, [_vm._v("Update Trip")]),
            _c(
              "md-button",
              {
                staticClass:
                  "md-simple md-just-icon md-round modal-default-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$modal.hide($event)
                  },
                },
              },
              [_c("md-icon", [_vm._v("clear")])],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c(
              "form-wrapper",
              {
                staticClass: "form-wrapper",
                attrs: { validator: _vm.$v.tripDetails },
              },
              [
                _c(
                  "div",
                  [
                    _c("google-autocomplete", {
                      ref: "address",
                      attrs: {
                        label: "Start Address",
                        id: "startAddress",
                        classname: "form-control autocomplete-input",
                        placeholder: "",
                        "should-focus": false,
                      },
                      on: { placechanged: _vm.handleStartLocationChange },
                      model: {
                        value: _vm.startAddress,
                        callback: function ($$v) {
                          _vm.startAddress = $$v
                        },
                        expression: "startAddress",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("google-autocomplete", {
                      attrs: {
                        label: "End Address",
                        id: "endAddress",
                        classname: "form-control autocomplete-input",
                        placeholder: "",
                        "should-focus": false,
                      },
                      on: { placechanged: _vm.handleEndLocationChange },
                      model: {
                        value: _vm.endAddress,
                        callback: function ($$v) {
                          _vm.endAddress = $$v
                        },
                        expression: "endAddress",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "date-picker-div" },
                  [
                    _c(
                      "md-datepicker",
                      {
                        ref: "mdDatePicker",
                        attrs: {
                          "md-immediately": "",
                          "md-debounce": 10,
                          clearable: false,
                        },
                        model: {
                          value: _vm.tripDate,
                          callback: function ($$v) {
                            _vm.tripDate = $$v
                          },
                          expression: "tripDate",
                        },
                      },
                      [_c("label", [_vm._v("Trip Date")])]
                    ),
                    _vm.tripDate == null || !_vm.isTripDateValid
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v("Trip date is invalid."),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "time-picker-div" },
                  [
                    _c(
                      "form-group",
                      {
                        staticClass: "time-picker hide-clear-btn",
                        attrs: { name: "startTime", label: "Start Time" },
                      },
                      [
                        _c("time-picker", {
                          attrs: {
                            time: _vm.startTime,
                            "all-time-options": false,
                            "is-required": true,
                          },
                          on: { selectedTime: _vm.getStartTime },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "time-picker-error" }, [
                      _vm.isFormTouched && !_vm.isStartTimeValid
                        ? _c("span", { staticClass: "error" }, [
                            _vm._v("Start time is required."),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "time-picker-div last-tpd" },
                  [
                    _c(
                      "form-group",
                      {
                        staticClass: "time-picker",
                        attrs: { name: "endTime", label: "End Time" },
                      },
                      [
                        _c("time-picker", {
                          attrs: {
                            time: _vm.endTime,
                            "all-time-options": false,
                          },
                          on: { selectedTime: _vm.getEndTime },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "md-layout" }, [
                  _c(
                    "div",
                    { staticClass: "md-layout-item" },
                    [
                      _c("CustomerOptions", {
                        attrs: {
                          selected: _vm.customerId,
                          "team-region-id": _vm.teamRegionId,
                        },
                        on: { selectedOption: _vm.handleCustomerChanged },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "md-layout-item" },
                    [
                      _c("charging-type-options", {
                        attrs: { "selected-charge-type-id": _vm.rateGroupId },
                        on: { selectedOption: _vm.handleRateTypeChanged },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.updateTrip($event)
                  },
                },
              },
              [_vm._v("Update")]
            ),
            _c(
              "md-button",
              {
                staticClass: "dialog-button md-default",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$modal.hide($event)
                  },
                },
              },
              [_vm._v("Cancel")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }