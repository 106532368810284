<template>
    <div class="md-layout">
        <div class="md-layout-item">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <md-field class="status-filter" v-if="rateGroups.length > 0">
                        <vue-select
                            :options="rateGroups"
                            :reduce="(rg) => rg.rateGroupId"
                            label="rateGroupName"
                            placeholder="Rate Groups"
                            v-model="rateGroupFilterValue"
                            :searchable="$root.isDesktop"
                        ></vue-select>
                    </md-field>
                    <div class="team-region-filter">
                        <team-region-member-filter-options
                            v-if="canViewTeamRegion && rateGroupFilterValue"
                            :team-region-id="teamRegionId"
                            :show-team-member="false"
                            @changed="handleTeamRegionMemberChanged"
                            :strict-team-region-filter="false"
                            :clearable="false"
                        />
                    </div>
                </div>
                <div class="custom-toolbar-end">
                    <search-component ref="search" @onSearch="handleSearch" placeholder="Search" />
                    <!-- filters and action buttons -->
                    <div class="header-button-container">
                        <md-button
                            title="Create Service"
                            class="md-primary md-just-icon md-round"
                            @click="onCreateService"
                        >
                            <md-icon>add</md-icon>
                        </md-button>

                        <input ref="fileInput" style="display: none" type="file" @change="onFileSelected" />
                        <md-button
                            title="Import Services"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click="importClick"
                        >
                            <md-icon>publish</md-icon>
                        </md-button>
                    </div>
                    <div>
                        <a class="download-btn" href="/sample-batchServicesUpload.xlsx" download>
                            Download Template
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { SearchComponent, TeamRegionMemberFilterOptions } from '@/components';
import { getTeamRegionName } from '@/helpers';

export default {
    name: 'ServicesToolbar',
    components: { SearchComponent, TeamRegionMemberFilterOptions },
    props: {
        teamMembers: {
            type: Array,
            default: null
        },
        warehouses: {
            type: Array,
            default: null
        },
        rateGroups: {
            type: Array,
            default: () => []
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {
            rateGroupFilterValue: null,
            teamRegionId: -1,
            searchText: ''
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            canViewTeamRegion: 'user/canViewTeamRegion',
            teamRegions: 'team/teamRegions'
        }),
        teamRegionName() {
            return getTeamRegionName(this.teamRegionId, this.teamRegions) || 'All';
        },
        rateGroupName() {
            const item = this.rateGroups.find((r) => r.rateGroupId === this.rateGroupFilterValue);
            if (item === undefined) 
                return 'Default';
            return item.rateGroupName;
        }
    },
    watch: {
        rateGroupFilterValue(newValue, oldValue) {
            if (newValue) {
                this.teamRegionId = this.user.teamRegionId ? this.user.teamRegionId : null; // not set
            } else 
                this.teamRegionId = null;
            this.handleFilteredValue();
        }
    },
    methods: {
        // eslint-disable-next-line func-names
        emitDebouncedEvent: _.debounce(function(evt) {
            // When we have a change in the Rate groups combo, we also change the teamRegionId to the users team region.
            // Doing such changes forces the application to emit the events two times resulting in duplicate calls to the GET Api endpoint almost instantly.
            // Debouncing the calls allows the event to emited only once.

            this.$emit(evt, {
                searchText: this.searchText,
                teamRegionId: this.teamRegionId,
                rateGroupId: this.rateGroupFilterValue
            });
        }, 200),
        handleFilteredValue() {
            this.emitDebouncedEvent('onFilterServices');
        },
        handleSearch(data) {
            this.searchText = data.searchText;
            this.emitDebouncedEvent('onFilterServices');
        },
        async exportItems() {
            this.emitDebouncedEvent('onExportClicked');
        },
        handleTeamRegionMemberChanged(value) {
            this.teamRegionId = value.teamRegionId;
            this.emitDebouncedEvent('onFilterServices');
        },
        onCreateService() {
            this.emitDebouncedEvent('onCreateService');
        },
        importClick() {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Import Services',
                    body: `Are you sure you want to import service prices for the '${
                        this.rateGroupName
                    }' rate group & the '${
                        this.teamRegionName
                    }' region?<br/><br/>Note that this function allows you to update the pricing of existing services.
 If the service name already exists, we'll update the pricing with the data from the import file. Note that when updating pricing for existing services,
the quantity type field from the import file will be ignored, and won't be updated.`,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$refs.fileInput.click();
                    }
                });
        },
        onFileSelected(event) {
            const file = event.target.files[0];
            event.target.value = '';
            if (file) 
                this.$emit('onFileSelected', file);
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}
::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}
::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}

::v-deep .vs__search {
    cursor: pointer;
}

.status-filter {
    width: 180px !important;
}

.team-region-filter {
    width: 180px !important;
    margin-left: 12px;
}

.download-btn {
    color: #2b93ff !important;
    margin-top: 5px;
    display: block;
    font-size: 12px;
}
</style>
