var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v(_vm._s(_vm.maintenanceScheduleData.maintenanceName)),
      ]),
    ]),
    _c("div", { staticClass: "modal-body" }, [
      _c("div", { staticClass: "details" }, [
        _vm.maintenanceScheduleData.timeSchedule != null ||
        _vm.maintenanceScheduleData.timeScheduleInterval
          ? _c("div", [
              _c("div", { staticClass: "reminder-title" }, [
                _vm._v(
                  "\n                    Time Based Reminder\n                    "
                ),
                _c(
                  "span",
                  { staticClass: "custom-ellipsis" },
                  [
                    _vm._v(
                      "\n                        (" +
                        _vm._s(_vm.cronExpressionText) +
                        ")\n                        "
                    ),
                    _vm.cronExpressionText != null &&
                    _vm.cronExpressionText.length > 26
                      ? _c("md-tooltip", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.cronExpressionText) +
                              "\n                        "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "reminder-details" }, [
                _c("div", [
                  _c("span", [_vm._v("Your next due date:")]),
                  _c(
                    "div",
                    { staticClass: "custom-md-field" },
                    [
                      _c("md-datepicker", {
                        ref: "mdDatePicker",
                        attrs: {
                          "md-immediately": "",
                          "md-disabled-dates": _vm.disabledDates,
                          "md-debounce": 10,
                        },
                        model: {
                          value: _vm.dueTime,
                          callback: function ($$v) {
                            _vm.dueTime = $$v
                          },
                          expression: "dueTime",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.maintenanceScheduleData.engineHoursReminderInterval != null ||
        _vm.maintenanceScheduleData.engineHoursReminderValue != null
          ? _c("div", [
              _c("div", { staticClass: "reminder-title" }, [
                _vm._v(
                  "\n                    Engine Based Reminder\n                    "
                ),
                _vm.maintenanceScheduleData.engineHoursReminderInterval
                  ? _c("span", [
                      _vm._v(
                        "\n                        (every " +
                          _vm._s(
                            _vm.maintenanceScheduleData
                              .engineHoursReminderInterval
                          ) +
                          " hours)\n                    "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "reminder-details" },
                [
                  _c(
                    "form-wrapper",
                    {
                      staticClass: "form-wrapper",
                      attrs: { validator: _vm.$v.formData },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("Current engine hours:")]),
                          _c(
                            "form-group",
                            {
                              staticClass: "custom-md-field",
                              attrs: { name: "currentEngineHoursValue" },
                            },
                            [
                              _c("md-input", {
                                model: {
                                  value: _vm.formData.currentEngineHoursValue,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "currentEngineHoursValue",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formData.currentEngineHoursValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("Your next due (in hours):")]),
                          _c(
                            "form-group",
                            {
                              staticClass: "custom-md-field",
                              attrs: { name: "dueEngineHours" },
                            },
                            [
                              _c("md-input", {
                                attrs: {
                                  disabled:
                                    _vm.maintenanceScheduleData
                                      .engineHoursReminderValue != null,
                                },
                                model: {
                                  value: _vm.formData.dueEngineHours,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "dueEngineHours",
                                      $$v
                                    )
                                  },
                                  expression: "formData.dueEngineHours",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.maintenanceScheduleData.odometerReminderInterval != null ||
        _vm.maintenanceScheduleData.odometerReminderValue != null
          ? _c("div", [
              _c("div", { staticClass: "reminder-title" }, [
                _vm._v(
                  "\n                    Distance Based Reminder\n                    "
                ),
                _vm.maintenanceScheduleData.odometerReminderInterval
                  ? _c("span", [
                      _vm._v(
                        "\n                        (every " +
                          _vm._s(
                            _vm.maintenanceScheduleData.odometerReminderInterval
                          ) +
                          "\n                        " +
                          _vm._s(
                            _vm.maintenanceScheduleData.odometerReminderUnitType
                          ) +
                          ")\n                    "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "reminder-details" },
                [
                  _c(
                    "form-wrapper",
                    {
                      staticClass: "form-wrapper",
                      attrs: { validator: _vm.$v.formData },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("span", [
                            _vm._v(
                              "\n                                Current Odometer Reading (" +
                                _vm._s(
                                  _vm.maintenanceScheduleData
                                    .odometerReminderUnitType
                                ) +
                                "):\n                            "
                            ),
                          ]),
                          _c(
                            "form-group",
                            {
                              staticClass: "custom-md-field",
                              attrs: { name: "currentOdometerValue" },
                            },
                            [
                              _c("md-input", {
                                model: {
                                  value: _vm.formData.currentOdometerValue,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "currentOdometerValue",
                                      $$v
                                    )
                                  },
                                  expression: "formData.currentOdometerValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("span", [
                            _vm._v(
                              "Your next due (in " +
                                _vm._s(
                                  _vm.maintenanceScheduleData
                                    .odometerReminderUnitType
                                ) +
                                "):"
                            ),
                          ]),
                          _c(
                            "form-group",
                            {
                              staticClass: "custom-md-field",
                              attrs: { name: "dueDistanceReading" },
                            },
                            [
                              _c("md-input", {
                                attrs: {
                                  disabled:
                                    _vm.maintenanceScheduleData
                                      .odometerReminderValue != null,
                                },
                                model: {
                                  value: _vm.formData.dueDistanceReading,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "dueDistanceReading",
                                      $$v
                                    )
                                  },
                                  expression: "formData.dueDistanceReading",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "recurring-list" },
        [
          _c("div", { staticClass: "preview-title" }, [
            _vm._v("Schedule Preview"),
          ]),
          _c("reminder-indicator", {
            key: _vm.counter,
            attrs: {
              "maintenance-data": _vm.maintenanceScheduleData,
              "current-engine-hours":
                _vm.formData.currentEngineHoursValue != null
                  ? parseFloat(_vm.formData.currentEngineHoursValue)
                  : null,
              "next-engine-hour-due":
                _vm.formData.dueEngineHours != null
                  ? parseFloat(_vm.formData.dueEngineHours)
                  : null,
              "next-due-distance":
                _vm.formData.dueDistanceReading != null
                  ? parseFloat(_vm.formData.dueDistanceReading)
                  : null,
              "current-distance-reading":
                _vm.formData.currentOdometerValue != null
                  ? parseFloat(_vm.formData.currentOdometerValue)
                  : null,
              "next-due-date": _vm.dueTime,
              "repeat-interval": _vm.repeatInterval,
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.attachScheduleToAsset($event)
              },
            },
          },
          [_vm._v("Apply")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }