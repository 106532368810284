var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-xsmall-size-100 md-medium-size-100" },
      [
        _c("router-link", { attrs: { to: "/map/" } }, [
          _c("div", { staticClass: "box-container first-box" }, [
            _c("div", [
              _c("div", [
                _c("img", {
                  attrs: {
                    src: "/img/mobile/dashboard-map.png",
                    alt: "Track your team",
                  },
                }),
              ]),
              _c("div", [_c("span", [_vm._v("Track your team")])]),
              _c(
                "div",
                { staticClass: "navigate-arrow" },
                [_c("md-icon", [_vm._v("navigate_next")])],
                1
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-xsmall-size-100 md-medium-size-100" },
      [
        _c("router-link", { attrs: { to: "/stops/list" } }, [
          _c("div", { staticClass: "box-container" }, [
            _c("div", [
              _c("div", [
                _c("img", {
                  attrs: {
                    src: "/img/mobile/dashboard-stops.png",
                    alt: "Manage your stops",
                  },
                }),
              ]),
              _c("div", [_c("span", [_vm._v("Manage your stops")])]),
              _c(
                "div",
                { staticClass: "navigate-arrow" },
                [_c("md-icon", [_vm._v("navigate_next")])],
                1
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-xsmall-size-100 md-medium-size-100" },
      [
        _c("router-link", { attrs: { to: "/shipments/list" } }, [
          _c("div", { staticClass: "box-container" }, [
            _c("div", [
              _c("div", [
                _c("img", {
                  attrs: {
                    src: "/img/mobile/dashboard-shipments.png",
                    alt: "Shipments",
                  },
                }),
              ]),
              _c("div", [_c("span", [_vm._v("Shipments")])]),
              _c(
                "div",
                { staticClass: "navigate-arrow" },
                [_c("md-icon", [_vm._v("navigate_next")])],
                1
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-xsmall-size-100 md-medium-size-100" },
      [
        _c("router-link", { attrs: { to: "/trips/list" } }, [
          _c("div", { staticClass: "box-container" }, [
            _c("div", [
              _c("div", [
                _c("img", {
                  attrs: {
                    src: "/img/mobile/dashboard-trips.png",
                    alt: "Trips",
                  },
                }),
              ]),
              _c("div", [_c("span", [_vm._v("Trips")])]),
              _c(
                "div",
                { staticClass: "navigate-arrow" },
                [_c("md-icon", [_vm._v("navigate_next")])],
                1
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }