<template>
    <div v-if="hasTeam && offerSettingModel" class="cf-container">
        <div>
            <md-card v-if="offerSettingModel">
                <md-card-content>
                    <UpdateJobOfferSetting v-if="showManualAllocationSettings" :offer-setting="offerSettingModel" />
                    <JobOfferSettingsWorded v-if="!showManualAllocationSettings" :offer-setting="offerSetting" />
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import JobOfferSettingsWorded from './JobOfferSettingsWorded';
import UpdateJobOfferSetting from './UpdateJobOfferSetting';

export default {
    name: 'JobOfferSettings',
    components: { JobOfferSettingsWorded, UpdateJobOfferSetting },
    props: {
        offerSetting: {
            type: Object,
            default: () => null
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {
            offerSettingModel: null
        };
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam'
        }),
        showManualAllocationSettings() {
            return !this.offerSetting || this.offerSetting.offerAllocationMethod === 'Manual';
        }
    },
    watch: {
        offerSetting(newVal) {
            if (newVal) {
                this.offerSettingModel = {
                    ...newVal
                };
            } else {
                this.offerSettingModel = null;
            }
        }
    },
    mounted() {
        this.offerSettingModel = this.offerSetting;
    }
};
</script>

<style lang="scss" scoped>
.md-table-head.end {
    text-align: right;
}
::v-deep .md-table-cell.action-buttons {
    padding-right: 30px;
    text-align: right;
}

.details {
    > div {
        display: inline-block;
        // width: 400px;
    }
    a {
        cursor: pointer;
    }
}

.details-box {
    width: 33%;
}

.header-button {
    margin-right: 10px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
}
</style>
