<template>
    <div class="load-vals">
        <form-group :name="capacityDefinition.type" :label="capacityLabel">
            <md-input v-model="capacityValue" />
            {{ capacityDefinition.symbol }}
        </form-group>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'CapacityInputs',
    mixins: [GeneralMixin],
    props: {
        capacityDefinition: {
            type: Object,
            default: () => {}
        },
        // eslint-disable-next-line vue/require-default-prop
        initialValue: null
    },
    watch: {
        capacityValue(newValue, oldValue) {
            this.$emit('changed', { name: this.capacityDefinition.type, value: parseFloat(newValue) });
        }
    },
    mounted() {
        this.capacityValue = this.initialValue ? this.initialValue : this.capacityDefinition.defaultMaxCapacity;
        this.capacityLabel = `Max ${this.capacityDefinition.label}`;
    },
    data() {
        return {
            capacityValue: null,
            capacityLabel: null
        };
    }
};
</script>

<style lang="scss" scoped>
.load-vals {
    display: inline-block;
    width: calc(33% - 10px);
    margin-right: 10px;
    &::last-child {
        margin-right: 0;
    }

    span {
        font-size: smaller;
        margin-left: -12px;
    }
}
</style>
