var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSingleDate
    ? _c("div", [
        _c(
          "div",
          { staticClass: "date" },
          [
            _c(
              "md-datepicker",
              {
                staticClass: "picker",
                attrs: { "md-immediately": "", "md-debounce": 100 },
                model: {
                  value: _vm.endDate,
                  callback: function ($$v) {
                    _vm.endDate = $$v
                  },
                  expression: "endDate",
                },
              },
              [_c("label", [_vm._v("Date")])]
            ),
            _vm.endDate == null
              ? _c("span", { staticClass: "error" }, [
                  _vm._v("Date is required."),
                ])
              : _vm._e(),
          ],
          1
        ),
      ])
    : _c("div", [
        _c(
          "div",
          { staticClass: "date" },
          [
            _c(
              "md-datepicker",
              {
                staticClass: "picker",
                attrs: { "md-immediately": "", "md-debounce": 100 },
                model: {
                  value: _vm.startDate,
                  callback: function ($$v) {
                    _vm.startDate = $$v
                  },
                  expression: "startDate",
                },
              },
              [_c("label", [_vm._v("Start date")])]
            ),
            _vm.startDate == null
              ? _c("span", { staticClass: "error" }, [
                  _vm._v("Start date is required."),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "date" },
          [
            _c(
              "md-datepicker",
              {
                staticClass: "picker",
                attrs: { "md-immediately": "", "md-debounce": 100 },
                model: {
                  value: _vm.endDate,
                  callback: function ($$v) {
                    _vm.endDate = $$v
                  },
                  expression: "endDate",
                },
              },
              [_c("label", [_vm._v("End date")])]
            ),
            _vm.endDate == null
              ? _c("span", { staticClass: "error" }, [
                  _vm._v("End date is required."),
                ])
              : _vm._e(),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }