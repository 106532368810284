var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c("div", { staticClass: "custom-toolbar" }, [
          _c(
            "div",
            { staticClass: "custom-toolbar-start" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "Team Settings",
                    },
                  },
                },
                [_c("h3", { staticClass: "title" }, [_vm._v("Team Settings")])]
              ),
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "   >    " + _vm._s(_vm.$t("menus.setting.shipmentContents"))
                ),
              ]),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-blue" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("medication_liquid")])],
                  1
                ),
              ]
            ),
            _c("md-card-content", [
              _c("div", { staticClass: "md-layout" }, [
                _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                  _c("h5", { staticClass: "app-title" }, [
                    _vm._v(
                      "\n                            Food\n                        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100 layout-item" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isFood,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isFood", $$v)
                                },
                                expression: "model.isFood",
                              },
                            },
                            [_vm._v("Food")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isBaked,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isBaked", $$v)
                                },
                                expression: "model.isBaked",
                              },
                            },
                            [_vm._v("Baked")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isCake,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isCake", $$v)
                                },
                                expression: "model.isCake",
                              },
                            },
                            [_vm._v("Cake")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                  _c("h5", { staticClass: "app-title" }, [
                    _vm._v(
                      "\n                            Meat\n                        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100 layout-item" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isMeat,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isMeat", $$v)
                                },
                                expression: "model.isMeat",
                              },
                            },
                            [_vm._v("Meat")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isBeef,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isBeef", $$v)
                                },
                                expression: "model.isBeef",
                              },
                            },
                            [_vm._v("Beef")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isPork,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isPork", $$v)
                                },
                                expression: "model.isPork",
                              },
                            },
                            [_vm._v("Pork")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                  _c("h5", { staticClass: "app-title" }, [
                    _vm._v(
                      "\n                            Liquid\n                        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100 layout-item" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isAlcohol,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isAlcohol", $$v)
                                },
                                expression: "model.isAlcohol",
                              },
                            },
                            [_vm._v("Alcohol")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isLiquid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isLiquid", $$v)
                                },
                                expression: "model.isLiquid",
                              },
                            },
                            [_vm._v("Liquid")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                  _c("h5", { staticClass: "app-title" }, [
                    _vm._v(
                      "\n                            Medications\n                        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100 layout-item" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isPrescriptionMedication,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "isPrescriptionMedication",
                                    $$v
                                  )
                                },
                                expression: "model.isPrescriptionMedication",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                    Prescription medications\n                                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                  _c("h5", { staticClass: "app-title" }, [
                    _vm._v(
                      "\n                            Drugs\n                        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100 layout-item" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isTobacco,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isTobacco", $$v)
                                },
                                expression: "model.isTobacco",
                              },
                            },
                            [_vm._v("Tobacco")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isDangerousGoods,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isDangerousGoods", $$v)
                                },
                                expression: "model.isDangerousGoods",
                              },
                            },
                            [_vm._v("Dangerous goods")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                  _c("h5", { staticClass: "app-title" }, [
                    _vm._v(
                      "\n                            Fragile Things\n                        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100 layout-item" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isFragile,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isFragile", $$v)
                                },
                                expression: "model.isFragile",
                              },
                            },
                            [_vm._v("Fragile")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isGlass,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isGlass", $$v)
                                },
                                expression: "model.isGlass",
                              },
                            },
                            [_vm._v("Glass")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                  _c("h5", { staticClass: "app-title" }, [
                    _vm._v(
                      "\n                            Floral\n                        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100 layout-item" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isFlowers,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isFlowers", $$v)
                                },
                                expression: "model.isFlowers",
                              },
                            },
                            [_vm._v("Flowers")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                  _c("h5", { staticClass: "app-title" }, [
                    _vm._v(
                      "\n                            Others\n                        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100 layout-item" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.isNotRotatable,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "isNotRotatable", $$v)
                                },
                                expression: "model.isNotRotatable",
                              },
                            },
                            [_vm._v("Not rotatable")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.model.mustBeUpright,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "mustBeUpright", $$v)
                                },
                                expression: "model.mustBeUpright",
                              },
                            },
                            [_vm._v("Must be upright")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c("md-card-actions", { attrs: { "md-alignment": "left" } }, [
              _c(
                "button",
                {
                  staticClass: "custom-btn",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.saveSettings($event)
                    },
                  },
                },
                [_vm._v("Save Settings")]
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }