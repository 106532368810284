var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "drop-down",
    {
      attrs: { "should-close": _vm.shouldCloseDropdown },
      on: {
        closeDropdown: function (val) {
          return (this$1.shouldCloseDropdown = val)
        },
        closeOtherDropDown: _vm.closeOtherDropDown,
      },
    },
    [
      _vm.assignee
        ? _c(
            "div",
            {
              staticClass: "status custom-ellipsis",
              attrs: { slot: "title", "data-toggle": "dropdown" },
              on: { click: _vm.handleClick },
              slot: "title",
            },
            [
              _c("span", { staticClass: "profile-image" }, [
                _c("img", {
                  attrs: {
                    src:
                      _vm.assignee.photoUrl !== null
                        ? _vm.assignee.photoUrl
                        : _vm.$root.defaultPhotoUrl,
                    alt: "avatar",
                  },
                }),
              ]),
              _vm._v("\n\n        " + _vm._s(_vm.assignee.name) + "\n        "),
              _c("md-tooltip", { attrs: { "md-direction": "right" } }, [
                _vm._v(_vm._s(_vm.assignee.name)),
              ]),
            ],
            1
          )
        : _c(
            "span",
            {
              staticClass: "unassigned",
              attrs: { slot: "title", "data-toggle": "dropdown" },
              on: { click: _vm.handleClick },
              slot: "title",
            },
            [_vm._v("\n        " + _vm._s("Assign") + "\n    ")]
          ),
      !_vm.isReadOnlyUser
        ? _c(
            "ul",
            {
              staticClass: "dropdown-menu",
              class: _vm.validQuotes.length > 0 ? "carriers-dropdown-menu" : "",
            },
            [
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleAssignShipment("")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s("Team Members ...") +
                        "\n            "
                    ),
                  ]
                ),
              ]),
              _vm.validQuotes.length && _vm.shipment.carrierTeamId == null
                ? _c("li", { staticClass: "carrier-title" }, [
                    _vm._v("Carriers"),
                  ])
                : _vm._e(),
              _vm.shipment.carrierTeamId == null &&
              _vm.validQuotes.length == 0 &&
              _vm.quotesLoader == false &&
              _vm.hasActiveCarriers &&
              _vm.allTeamCarriers.length > 0
                ? [
                    _c("div", { staticClass: "zero-quote-container" }, [
                      _vm._v(
                        "\n                No available quotes or quotes have expired\n            "
                      ),
                    ]),
                  ]
                : _vm._e(),
              _vm.validQuotes.length
                ? [
                    _vm._l(_vm.validQuotes, function (quote, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleAssignShipment(quote)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "righthand-side" }, [
                            _c("div", { staticClass: "carrier-image" }, [
                              quote.carrierTeam.logoUrl != null
                                ? _c("img", {
                                    staticClass: "profile-image",
                                    attrs: {
                                      src: quote.carrierTeam.logoUrl,
                                      alt: "avatar",
                                    },
                                    on: { error: _vm.$_setDefaultBrokenImage },
                                  })
                                : _c("img", {
                                    staticClass: "profile-image",
                                    attrs: {
                                      src: _vm.$root.defaultLogoUrl,
                                      alt: "avatar",
                                    },
                                    on: { error: _vm.$_setDefaultBrokenImage },
                                  }),
                            ]),
                            _c(
                              "div",
                              { staticClass: "carrier-delivery-details" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "carrier-name custom-ellipsis",
                                  },
                                  [_vm._v(_vm._s(quote.carrierTeam.company))]
                                ),
                                _c("div", { staticClass: "carrier-speed" }, [
                                  _vm._v(_vm._s(_vm.$t(quote.serviceLevel))),
                                ]),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "carrier-pricing" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("currency")(
                                    quote.amountAfterTax,
                                    quote.currency
                                  )
                                ) +
                                "\n                "
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm.activeValidQuotesCount && _vm.activeValidQuotesCount > 3
                      ? _c(
                          "li",
                          {
                            staticClass: "see-more",
                            on: { click: _vm.showQuotesModal },
                          },
                          [
                            _vm._v(
                              "\n                See all " +
                                _vm._s(_vm.activeValidQuotesCount) +
                                " quotes...\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                : [
                    _vm.shipment.carrierTeamId == null &&
                    _vm.quotesLoader &&
                    _vm.hasActiveCarriers &&
                    _vm.allTeamCarriers.length > 0
                      ? _c(
                          "div",
                          { staticClass: "quotes-loader" },
                          [
                            _vm._v(
                              "\n                Fetching quotes\n                "
                            ),
                            _c("straight-line-loader"),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }