<template>
    <div v-if="maintenanceScheduleDetails != null">
        <div class="md-layout-item md-size-100">
            <form-group name="maintenanceName" label="Name">
                <div>
                    <md-input v-focus v-model="maintenanceScheduleDetails.maintenanceName" />
                </div>
            </form-group>
        </div>
        <div class="md-layout-item md-size-100">
            <form-group label="Type" name="maintenanceType">
                <md-select
                    v-model="maintenanceScheduleDetails.maintenanceType"
                    @md-selected="handleLogType(maintenanceScheduleDetails.maintenanceType)"
                >
                    <md-option v-for="x in assetLogTypes" :key="x.key" :value="x.key">
                        {{ $t('assetLogTypes.' + x.key) }}
                    </md-option>
                </md-select>
            </form-group>
            <form-group label="Subtype" name="maintenanceSubType">
                <md-select
                    v-model="maintenanceScheduleDetails.maintenanceSubType"
                    :disabled="maintenanceScheduleDetails.maintenanceType == null"
                >
                    <md-option v-for="x in subLogTypes" :key="x.key" :value="x.key">
                        {{ $t('assetLogSubTypes.' + x.key) }}
                    </md-option>
                </md-select>
            </form-group>
        </div>
        <div class="md-layout-item md-size-100">
            <form-group name="notes" label="Description">
                <md-textarea v-model="maintenanceScheduleDetails.notes" />
            </form-group>
        </div>
    </div>
</template>

<script>
import { ASSET_LOG_TYPES } from '@/utils/AssetLogs';

export default {
    name: 'ScheduleDetailsStep',
    props: {
        maintenanceScheduleDetails: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            assetLogTypes: [],
            subLogTypes: []
        };
    },
    mounted() {
        this.assetLogTypes = ASSET_LOG_TYPES.filter((x) => !x.isVehicleLogType);
        this.subLogTypes = this.assetLogTypes[0].sub_categories;

        if (this.maintenanceScheduleDetails.maintenanceType != null) {
            this.handleLogType(this.maintenanceScheduleDetails.maintenanceType);
        }
    },
    watch: {
        'maintenanceScheduleDetails.maintenanceType': function() {
            this.maintenanceScheduleDetails.maintenanceSubType = null;
        }
    },
    methods: {
        handleLogType(type) {
            const subtypes = this.assetLogTypes.filter((x) => x.key === type);
            this.subLogTypes = null;
            this.subLogTypes = subtypes[0].sub_categories;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-field > div,
::v-deep .md-input {
    width: 100%;
}

::v-deep .md-textarea {
    resize: none !important;
    padding: 0 !important;
    padding-top: 20px !important;
}

.form-group-no-underline:after {
    height: 0;
}

.form-group-no-underline ::v-deep label {
    top: 1px;
}
</style>
