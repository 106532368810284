<template>
    <div
        class="sidebar"
        data-color="blue"
        :data-background-color="backgroundColor"
        @mouseenter="toggleLogo"
        @mouseleave="toggleLogo"
    >
        <div class="logo">
            <a v-if="!showLogo" href="https://www.locate2u.com/" class="simple-text logo-mini" target="_blank">
                <div class="logo-img" v-if="logo">
                    <img :src="logo" />
                </div>
                <div class="logo-img" v-else>
                    <img src="/img/logos/locate2u-logo-thumbnail.png" />
                </div>
            </a>
            <a v-else href="https://www.locate2u.com/" class="simple-text logo-normal" target="_blank">
                <template v-if="isCustomerType">
                    <img v-if="logo" :src="logo" class="logo-complete" />
                    <img v-else src="/img/logos/locate2u-logo-dark.png" class="logo-complete" />
                </template>
                <template v-else>
                    <img src="/img/logos/locate2u-logo.png" class="logo-complete" />
                </template>
            </a>
            <div class="navbar-minimize">
                <md-button id="minimizeSidebar" class="md-round md-just-icon md-transparent" @click="minimizeSidebar">
                    <i class="material-icons text_align-center visible-on-sidebar-regular">
                        more_vert
                    </i>
                    <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">
                        view_list
                    </i>
                </md-button>
            </div>
        </div>
        <div ref="sidebarScrollArea" class="sidebar-wrapper" :class="!showLogo ? 'mini-sidebar' : 'max-sidebar'">
            <slot />
            <md-list class="nav">
                <slot name="links">
                    <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
                        <sidebar-item v-for="(subLink, i) in link.children" :key="subLink.name + i" :link="subLink" />
                    </sidebar-item>
                </slot>
            </md-list>
            <div v-if="appVersion" :class="showLogo ? 'version-text' : 'version-text-min'">
                <a :href="appVersionLink">
                    <span v-if="showLogo">Locate2u</span>
                    v{{ appVersion }}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Sidebar',
    props: {
        title: {
            type: String,
            default: 'Locate2u'
        },
        activeColor: {
            type: String,
            default: 'blue'
        },
        backgroundColor: {
            type: String,
            default: 'darkblue',
            validator: (value) => {
                const acceptedValues = ['', 'darkblue', 'white', 'red', 'customwhite'];
                return acceptedValues.indexOf(value) !== -1;
            }
        },
        logo: {
            type: String,
            default: '/img/logos/locate2u-logo-thumbnail.png'
        },
        sidebarLinks: {
            type: Array,
            default: () => []
        },
        autoClose: {
            type: Boolean,
            default: true
        },
        isCustomerType: {
            type: Boolean,
            default: false
        },
        appVersion: {
            type: String,
            default: null
        },
        appVersionLink: {
            type: String,
            default: 'https://locate2u.com'
        }
    },
    data() {
        return {
            showLogo: false
        };
    },
    provide() {
        return {
            autoClose: this.autoClose
        };
    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    },
    mounted() {
        this.sidebarStore.isMinimized = false;
        if (
            localStorage.getItem(`l2u-sidebar-state`) !== null &&
            localStorage.getItem(`l2u-sidebar-state`) === 'open'
        ) {
            this.$sidebar.toggleMinimize();
            this.toggleLogo();
        }
    },
    methods: {
        toggleLogo() {
            this.showLogo = !this.showLogo;
            if (this.sidebarStore.isMinimized) 
                this.showLogo = true;
        },
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        }
    }
};
</script>
<style scoped lang="scss">
.logo-complete {
    max-width: 150px;
    margin-left: 23px;
}

.logo {
    min-height: 65px;
}

.logo-img {
    border-radius: 0 !important;
}

.version-text,
.version-text-min {
    a {
        color: #aaa !important;
        text-decoration: none;
        font-weight: 500;
        font-size: 12px;
    }
    a:hover {
        text-decoration: underline !important;
    }
}
.version-text-min {
    padding-top: 5px;
    text-align: center;
}
.version-text {
    padding-top: 5px;
    margin-right: 23px;
    text-align: right;
}
.mini-sidebar {
    height: calc(100vh - 69px);
    .md-list {
        height: calc(100vh - 170px);
        overflow: hidden;
    }
}
.max-sidebar {
    height: calc(100vh - 96px);
    .md-list {
        height: calc(100vh - 200px);
        overflow: hidden;
    }
}
.md-list:hover {
    overflow: scroll;
    overflow-x: hidden;
}
.md-list::-webkit-scrollbar {
    width: 7px;
}
/* Track */
.md-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */
.md-list::-webkit-scrollbar-thumb {
    background: #aaa;
}
/* Handle on hover */
.md-list::-webkit-scrollbar-thumb:hover {
    background: #ddd;
}
</style>
