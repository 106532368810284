<template>
    <drop-down
        :should-close="shouldCloseDropdown"
        @closeDropdown="(val) => (this.shouldCloseDropdown = val)"
        @closeOtherDropDown="closeOtherDropDown"
    >
        <div v-if="assignee" class="status custom-ellipsis" slot="title" data-toggle="dropdown" @click="handleClick">
            <span class="profile-image">
                <img :src="assignee.photoUrl !== null ? assignee.photoUrl : $root.defaultPhotoUrl" alt="avatar" />
            </span>

            {{ assignee.name }}
            <md-tooltip md-direction="right">{{ assignee.name }}</md-tooltip>
        </div>
        <span v-else class="unassigned" slot="title" data-toggle="dropdown" @click="handleClick">
            {{ 'Assign' }}
        </span>
        <ul
            class="dropdown-menu"
            :class="validQuotes.length > 0 ? 'carriers-dropdown-menu' : ''"
            v-if="!isReadOnlyUser"
        >
            <li>
                <a @click.stop="handleAssignShipment('')">
                    {{ `Team Members ...` }}
                </a>
            </li>
            <li class="carrier-title" v-if="validQuotes.length && shipment.carrierTeamId == null">Carriers</li>
            <template
                v-if="
                    shipment.carrierTeamId == null &&
                        validQuotes.length == 0 &&
                        quotesLoader == false &&
                        hasActiveCarriers &&
                        allTeamCarriers.length > 0
                "
            >
                <div class="zero-quote-container">
                    No available quotes or quotes have expired
                </div>
            </template>
            <template v-if="validQuotes.length">
                <li v-for="(quote, index) in validQuotes" :key="index" @click.stop="handleAssignShipment(quote)">
                    <div class="righthand-side">
                        <div class="carrier-image">
                            <img
                                v-if="quote.carrierTeam.logoUrl != null"
                                class="profile-image"
                                :src="quote.carrierTeam.logoUrl"
                                @error="$_setDefaultBrokenImage"
                                alt="avatar"
                            />
                            <img
                                v-else
                                class="profile-image"
                                :src="$root.defaultLogoUrl"
                                @error="$_setDefaultBrokenImage"
                                alt="avatar"
                            />
                        </div>
                        <div class="carrier-delivery-details">
                            <div class="carrier-name custom-ellipsis">{{ quote.carrierTeam.company }}</div>
                            <div class="carrier-speed">{{ $t(quote.serviceLevel) }}</div>
                        </div>
                    </div>
                    <div class="carrier-pricing">
                        {{ quote.amountAfterTax | currency(quote.currency) }}
                    </div>
                </li>
                <li
                    v-if="activeValidQuotesCount && activeValidQuotesCount > 3"
                    class="see-more"
                    @click="showQuotesModal"
                >
                    See all {{ activeValidQuotesCount }} quotes...
                </li>
            </template>
            <template v-else>
                <div
                    v-if="
                        shipment.carrierTeamId == null &&
                            quotesLoader &&
                            hasActiveCarriers &&
                            allTeamCarriers.length > 0
                    "
                    class="quotes-loader"
                >
                    Fetching quotes
                    <straight-line-loader />
                </div>
            </template>
        </ul>
    </drop-down>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage, CarrierPreBookingQuestionsMapper } from '@/helpers';
import { AssignShipmentModal } from '@/pages/Shipments/components';
import CarrierPreBookingQuestionsModal from '@/components/JobOffers/CarrierPreBookingQuestionsModal';
import { QuotesModal, StraightLineLoader } from '@/components';

export default {
    name: 'AssignShipmentDropdown',
    mixins: [GeneralMixin],
    props: {
        shipment: {
            type: Object,
            default: () => {}
        },
        teamMembers: {
            type: Array,
            default: () => []
        },
        className: {
            type: String,
            default: () => ''
        }
    },
    data() {
        return {
            shouldCloseDropdown: false,
            quoteOptions: [],
            quotesLoader: true,
            activeValidQuotesCount: 0,
            latestDate: null,
            topQuotes: []
        };
    },
    components: {
        StraightLineLoader
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            user: 'user/user',
            allTeamCarriers: 'team/teamCarriers',
            hasActiveCarriers: 'team/hasActiveCarriers',
            hasMarketplaceAccess: 'user/hasMarketplaceAccess'
        }),
        assignee() {
            if (this.shipment.assignedTo && this.shipment.assignedTo.publicUserId) {
                return {
                    name: this.shipment.assignedTo.fullName,
                    photoUrl: this.shipment.assignedTo.photoUrl
                };
            }
            if (this.shipment.carrierTeam) {
                return {
                    name: this.shipment.carrierTeam.company,
                    photoUrl: this.shipment.carrierTeam.logoUrl
                };
            }
            return null;
        },
        validQuotes() {
            if (!this.shipment || this.shipment.carrierTeamId || this.topQuotes.length === 0) 
                return [];

            let quotes = this.topQuotes.filter(
                (x) =>
                    this.latestDate === x.createdDate &&
                    x.error == null &&
                    (x.expiryDate == null || moment().isBefore(moment.utc(x.expiryDate).local()))
            );

            quotes = quotes.sort((x, y) => x.amountAfterTax - y.amountAfterTax);
            return quotes.slice(0, 3);
        }
    },
    mounted() {
        this.quoteOptions = this.validQuotes.sort((x, y) => x.amountAfterTax - y.amountAfterTax);
        this.quoteOptions = this.quoteOptions.slice(0, 3);
    },
    methods: {
        quoteItemText(quote) {
            const text = `${quote.carrierTeam?.company}  ${quote.currency} ${quote.amountAfterTax}`;

            if (quote.serviceLevel) 
                return `${text} (${quote.serviceLevel})`;
            return text;
        },
        showQuotesModal() {
            this.$modal
                .show(QuotesModal, {
                    // members: this.teamMembers,
                    shipment: this.shipment
                })
                .then((response) => {
                    this.$emit('assignedUser', response);
                    this.$modal.hide();
                });
            this.$emit('showQuotesModal');
        },
        async handleClick() {
            if (this.shipment.carrierTeamId != null) 
                return;
            this.quotesLoader = true;
            await this.getQuotes();
            this.quotesLoader = false;
        },
        async getQuotes() {
            const api = `/api/carriers/${this.shipment.shipmentId}/quotes`;
            const payload = {
                method: 'get',
                params: {
                    shipmentId: this.shipment.shipmentId
                }
            };

            const response = await handleRequests(api, payload);

            this.shipment.quotes = [];
            this.shipment.activeQuotesCount = 0;

            const allQuotes = response.data ? response.data : [];

            if (allQuotes.length <= 0) {
                this.topQuotes = [];
                return;
            }

            const sortedQuotes = this.groupByAndSortByDate(allQuotes);
            const keys = Object.keys(sortedQuotes);
            // eslint-disable-next-line prefer-destructuring
            this.latestDate = keys[0];
            this.topQuotes = sortedQuotes[keys[0]];
            this.activeValidQuotesCount = this.topQuotes.length;
            this.shipment.activeQuotesCount = this.topQuotes.length;
        },
        groupBy(arr, key) {
            const obj = arr.reduce((rv, x) => {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});

            if (Object.keys(obj).length === 0) 
                return [];

            return obj;
        },
        groupByAndSortByDate(data) {
            const sortedObj = {};
            const groupedData = this.groupBy(data, 'createdDate');

            Object.keys(groupedData)
                .sort((a, b) => {
                    return new Date(b) - new Date(a);
                })
                // eslint-disable-next-line func-names
                .forEach(function(key) {
                    sortedObj[key] = groupedData[key];
                });

            return sortedObj;
        },
        async handleAssignShipment(quote) {
            this.shouldCloseDropdown = true;

            // eslint-disable-next-line nonblock-statement-body-position
            if (this.isReadOnlyUser) return;

            if (!quote) {
                // team member
                this.$modal
                    .show(AssignShipmentModal, {
                        shipmentId: this.shipment.shipmentId,
                        teamMembers: this.teamMembers,
                        assignedTo: this.shipment.assignedTo.publicUserId,
                        assignedCarrierTeamId: this.shipment.carrierTeamId,
                        shipmentRef: this.shipment.shipmentRef,
                        teamRegionId: this.shipment.teamRegionId
                    })
                    .then((response) => {
                        this.$emit('assignedUser', response);
                    });
            } else {
                // show questionnaires, if they have
                const questionMapper = new CarrierPreBookingQuestionsMapper();
                const mappingResults = await questionMapper.mapAsync(quote.carrierTeamId);

                if (mappingResults.success) {
                    // get required fields that were not filled-up
                    // todo: call api
                    // get required fields that were not filled-up
                    const mandatories = await this.getPreBookingRequiredFields({
                        shipmentId: this.shipment.shipmentId,
                        carrierTeamId: quote.carrierTeamId
                    });

                    if (!mandatories) {
                        // there was an error
                        return;
                    }

                    const mandatoryFields = mandatories.map((fld) => {
                        return {
                            ...fld,
                            label: this.$t(`carrierFieldLabels.${fld.name}`)
                        };
                    });

                    if (!mappingResults.newAnswersList.length && !mandatoryFields.length) {
                        // no questions and required fields to answer, continue
                        this.continueAssignment(quote, mappingResults.combinedList, []);
                    } else {
                        // show pre-booking questions
                        this.$modal
                            .show(CarrierPreBookingQuestionsModal, {
                                carrierTeamId: quote.carrierTeamId,
                                carrierName: quote.carrierTeam.company,
                                fixedMappingList: [...mappingResults.fixedMappingList],
                                newAnswersList: [...mappingResults.newAnswersList],
                                mandatoryFields: [...mandatoryFields]
                            })
                            .then((response) => {
                                if (response && response.result) {
                                    const combinedList = questionMapper.combine(
                                        mappingResults.fixedMappingList,
                                        response.data.newAnswersList
                                    );
                                    return this.continueAssignment(quote, combinedList, response.data.mandatoryFields);
                                }
                                return Promise.resolve();
                            });
                    }
                } else {
                    const message = 'Could not retrieve question mappings.';
                    showErrorMessage(this, message, mappingResults.error);
                }
            }
        },
        async continueAssignment(quote, carrierQuestionAnswers, mandatoryFields) {
            this.$_handleLoaderState(true, 'ASSIGNING SHIPMENT...');
            const api = `/api/shipments/${this.shipment.shipmentId}/assign-user`;
            const data = {
                assignToCarrierTeamId: quote.carrierTeamId,
                carrierQuoteId: quote.carrierQuoteId,
                carrierQuestionAnswers,
                carrierRequiredFields: mandatoryFields
            };

            const payload = {
                method: 'post',
                data
            };

            try {
                const { data: returnedData } = await handleRequests(api, payload);

                this.$notify({
                    message: `Shipment was assigned to ${returnedData?.carrierTeam?.company}`,
                    type: 'success'
                });

                this.$emit('assignedUser', returnedData);
            } catch (e) {
                let message = 'Cannot assign shipment to the carrier.';
                // eslint-disable-next-line nonblock-statement-body-position, prefer-destructuring
                if (e && e.message) message = e.message;
                showErrorMessage(this, message, e);
                if (e.data && e.data[0].details) {
                    this.$emit('handleError', { errors: e.data[0].details, carrierName: quote.carrierTeam.company });
                } else {
                    showErrorMessage(this, message, e);
                }
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async getPreBookingRequiredFields({ shipmentId, carrierTeamId }) {
            try {
                const api = `/api/carriers/${shipmentId}/pre-booking-check`;
                const payload = {
                    method: 'post',
                    data: { shipmentId, carrierTeamId }
                };
                const { data: requirdFields } = await handleRequests(api, payload);
                return requirdFields;
            } catch (e) {
                let message = 'Unable to perform pre-booking check.';
                // eslint-disable-next-line nonblock-statement-body-position, prefer-destructuring
                if (e && e.message) message = e.message;
                showErrorMessage(this, message, e);
                return null;
            }
        },
        closeOtherDropDown() {
            const dropdowns = document.querySelectorAll('.open');
            dropdowns.forEach((dropdown) => dropdown.classList.remove('open'));
        }
    }
};
</script>

<style lang="scss" scoped>
.status {
    // color: #fff !important;
    // background-color: #2b93ff !important;
    min-width: 90px;
    // text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 160px;
    cursor: pointer;
}

.unassigned {
    color: #fff !important;
    background-color: #2b93ff !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 160px;
    cursor: pointer;
}

::v-deep .dropdown-menu {
    li {
        cursor: pointer !important;
        a {
            padding: 10px 15px !important;
        }
    }
}

.carriers-dropdown-menu {
    width: 300px;

    .carrier-title,
    .see-more {
        font-weight: 500;
        padding: 5px 20px;
        display: block;
    }

    .carrier-title {
        cursor: default !important;
    }

    .see-more {
        text-align: center;
        font-weight: 400;
        color: #aaa;
    }

    li {
        border-bottom: 1px solid #eee;
        display: table;
        width: 100%;
        > div {
            display: table-cell;
            vertical-align: middle;
            margin: 5px;
        }
    }

    li:hover {
        background-color: #eee;
    }

    li:first-child:hover {
        background-color: initial;
        cursor: default !important;
    }

    .righthand-side {
        display: table;
        width: 180px;
        width: 100%;
        > div {
            display: table-cell;
            vertical-align: middle;
        }

        > div:first-child {
            width: 40px;
        }
        .carrier-delivery-details {
            padding-left: 10px;
            max-width: 100px;
        }

        .carrier-name {
            font-weight: 700;
            font-size: 14px;
        }
        .carrier-speed {
            font-weight: 500px;
            font-size: 10px;
        }
        .carrier-image {
            width: 100%;
        }
    }

    .carrier-pricing {
        padding: 0 10px;
        text-align: right;
        font-size: 13px;
    }
}

.expired-quote {
    color: #d3d3d3;

    .carrier-name {
        text-decoration: line-through;
    }
}

.expired-quote:hover {
    cursor: no-drop !important;
}

.profile-image {
    margin-right: 5px;
    display: inline-block;
    img {
        object-fit: contain;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #eee;
    }
}

.quotes-loader {
    ::v-deep svg {
        circle {
            fill: #4e79ff;
        }
    }

    font-size: 12px;
    font-weight: 500;
    color: #aaa;
    text-align: center;
    padding: 10px;
    display: block;
    border-top: 1px solid #eee;

    span {
        font-size: 10px;
    }
}

.zero-quote-container {
    font-size: 12px;
    font-weight: 500;
    color: #aaa;
    text-align: center;
    padding: 10px;
    display: block;
    border-top: 1px solid #eee;
}
</style>
