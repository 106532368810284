var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-ag-dropdown" },
    [
      _c(
        "md-field",
        [
          _c(
            "md-select",
            {
              on: { "md-selected": _vm.optionSelected },
              model: {
                value: _vm.currentValue,
                callback: function ($$v) {
                  _vm.currentValue = $$v
                },
                expression: "currentValue",
              },
            },
            [
              _c("md-option", { key: "blank", attrs: { value: "" } }),
              _vm._l(_vm.params.dropdownValues, function (item) {
                return _c("md-option", { key: item, attrs: { value: item } }, [
                  _vm._v(
                    "\n                " + _vm._s(item) + "\n            "
                  ),
                ])
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }