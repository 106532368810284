var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "modal-body" }, [
      _c("div", [
        _c("span", [_vm._v("Date:")]),
        _c("div", [
          _vm._v(
            _vm._s(
              _vm._f("dateFormat")(
                _vm.logData.logDate,
                _vm.DATE_TYPES.standardDate
              )
            )
          ),
        ]),
      ]),
      _c("div", [
        _c("span", [_vm._v("Type:")]),
        _c("div", [
          _c(
            "span",
            {
              staticClass: "generic-badge",
              class:
                "status-" +
                _vm.$_getAssetLogTypeBadgeColor(_vm.logData.logType),
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("assetLogTypes." + _vm.logData.logType)) +
                  "\n                "
              ),
            ]
          ),
        ]),
      ]),
      _c("div", [
        _c("span", [_vm._v("Sub Type:")]),
        _c("div", [
          _c(
            "span",
            {
              staticClass: "generic-badge",
              class:
                "status-" +
                _vm.$_getAssetLogSubTypeBadgeColor(
                  _vm.logData.logType,
                  _vm.logData.logSubType
                ),
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("assetLogSubTypes." + _vm.logData.logSubType)) +
                  "\n                "
              ),
            ]
          ),
        ]),
      ]),
      _vm.logData.logType != "insurance" &&
      _vm.logData.logType != "vehicle-registration"
        ? _c("div", [
            _c("span", [_vm._v("Engine hours:")]),
            _c("div", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.logData.startingEngineHours) +
                  "\n                "
              ),
              _vm.logData.startingEngineHours
                ? _c("span", [_vm._v("hours")])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.logData.logType != "insurance" &&
      _vm.logData.logType != "vehicle-registration" &&
      _vm.logData.startingOdometer != null
        ? _c("div", [
            _c("span", [_vm._v("Odometer Reading:")]),
            _c("div", [
              _vm._v(
                _vm._s(_vm.logData.startingOdometer) +
                  " " +
                  _vm._s(_vm.logData.odometerUnitType)
              ),
            ]),
          ])
        : _vm._e(),
      _c("div", [
        _c("span", [_vm._v("Total cost:")]),
        _c("div", [
          _vm._v(
            "\n                " +
              _vm._s(
                _vm.logData.cost != null
                  ? _vm.logData.costCurrency == null
                    ? this.defaultCurrency
                    : _vm.logData.costCurrency
                  : null
              ) +
              "\n                " +
              _vm._s(_vm.logData.cost) +
              "\n            "
          ),
        ]),
      ]),
      _c("div", [
        _c("span", [_vm._v("Location:")]),
        _c("div", [_vm._v(_vm._s(_vm.logData.startAddressName))]),
      ]),
      _c("div", [
        _c("span", [_vm._v("Notes:")]),
        _c("div", [_vm._v(_vm._s(_vm.logData.notes))]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Close")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Log Details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }