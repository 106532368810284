import Notifications from './Notifications';

const NotificationStore = {
    state: [], // here the notifications will be added

    removeNotification(timestamp) {
        const indexToDelete = this.state.findIndex((n) => n.timestamp === timestamp);
        if (indexToDelete !== -1) {
            this.state.splice(indexToDelete, 1);
        }
    },
    addNotification(notification) {
        notification.timestamp = new Date();
        notification.timestamp.setMilliseconds(notification.timestamp.getMilliseconds() + this.state.length);

        this.state.push(notification);
    },
    notify(notification) {
        if (Array.isArray(notification)) {
            notification.forEach((notificationInstance) => {
                this.addNotification(notificationInstance);
            });
        } else {
            this.addNotification(notification);
        }
    },
    notifySuccess(message, duration) {
        this.addNotification({
            message,
            duration,
            type: 'success'
        });
    },
    notifyWarning(message, duration) {
        this.addNotification({
            message,
            duration,
            type: 'warning'
        });
    },
    notifyError(message, duration) {
        this.addNotification({
            message,
            duration,
            type: 'danger'
        });
    },
    notifyErrors(errors, duration) {
        errors.forEach((e) => {
            this.addNotification({
                message: e.message,
                duration,
                type: 'danger'
            });
        });
    }
};

const NotificationsPlugin = {
    install(Vue) {
        Vue.mixin({
            data() {
                return {
                    notificationStore: NotificationStore
                };
            },
            methods: {
                notify(notification) {
                    this.notificationStore.notify(notification);
                },
                notifySuccess(message, duration) {
                    this.notificationStore.notifySuccess(message, duration);
                },
                notifyWarning(message, duration) {
                    this.notificationStore.notifyWarning(message, duration);
                },
                notifyError(message, duration) {
                    this.notificationStore.notifyError(message, duration);
                },
                notifyErrors(errors, duration) {
                    this.notificationStore.notifyErrors(errors, duration);
                }
            }
        });
        Object.defineProperty(Vue.prototype, '$notify', {
            get() {
                return this.$root.notify;
            }
        });
        Object.defineProperty(Vue.prototype, '$notifySuccess', {
            get() {
                return this.$root.notifySuccess;
            }
        });
        Object.defineProperty(Vue.prototype, '$notifyWarning', {
            get() {
                return this.$root.notifyWarning;
            }
        });
        Object.defineProperty(Vue.prototype, '$notifyError', {
            get() {
                return this.$root.notifyError;
            }
        });
        Object.defineProperty(Vue.prototype, '$notifyErrors', {
            get() {
                return this.$root.notifyErrors;
            }
        });
        Object.defineProperty(Vue.prototype, '$notifications', {
            get() {
                return this.$root.notificationStore;
            }
        });
        Vue.component('Notifications', Notifications);
    }
};

export default NotificationsPlugin;
