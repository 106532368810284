<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Create Distance Rule</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>

        <form-wrapper class="form-wrapper" :validator="$v.rateDetail">
            <div class="md-layout-item md-size-100">
                <rule-valid-days
                    :current-days="rateDetail.validForWeekDays"
                    :from-date="rateDetail.validFromDate"
                    :to-date="rateDetail.validToDate"
                    @daysSelected="handleDaysSeleceted"
                    ref="ruleValidDays"
                />
                <form-group name="distanceFirstFlagfall" label="Flag Fall">
                    <md-input v-model.number="rateDetail.distanceFirstFlagfall" />
                </form-group>
                <form-group name="distanceRatePerKilometre" :label="metricValue">
                    <md-input v-model.number="rateDetail.distanceRatePerKilometre" />
                </form-group>
                <form-group name="includedDistance" label="Included Distance">
                    <md-input v-model.number="rateDetail.includedDistance" />
                </form-group>
                <form-group name="distanceMinimumCharge" label="Minimum Charge">
                    <md-input v-model.number="rateDetail.distanceMinimumCharge" />
                </form-group>
            </div>
        </form-wrapper>

        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="create()">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin, RatesEngineMixin } from '@/mixins';
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';
import { RATE_CHARGING_TYPES } from '@/utils/constants';
import { decimal } from 'vuelidate/lib/validators';
import { RuleValidDays } from '@/components';

export default {
    name: 'CreateDistanceRuleContent',
    components: {
        RuleValidDays
    },
    mixins: [GeneralMixin, RatesEngineMixin],
    props: {
        rateDetail: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        listLength: {
            type: Number,
            default: 0
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        customerId: {
            type: Number,
            default: null
        },
        rateGroupId: {
            type: Number,
            default: null
        },
        driverUserId: {
            type: Number,
            default: null
        },
        driverRuleCreation: {
            type: Boolean,
            default: false
        },
        metricValue: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            ruleData: {
                type: null,
                rule: '',
                customerId: null
            },
            rateChargingTypes: RATE_CHARGING_TYPES
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    validations: {
        rateDetail: {
            distanceFirstFlagfall: {
                decimal
            },
            distanceRatePerKilometre: {
                decimal
            },
            distanceMinimumCharge: {
                decimal
            },
            includedDistance: {
                decimal
            }
        }
    },
    methods: {
        validateData() {
            if (this.$v.$invalid) {
                return false;
                // eslint-disable-next-line no-else-return
            } else if (
                this.$v.rateDetail.distanceFirstFlagfall.$model === null &&
                this.$v.rateDetail.distanceRatePerKilometre.$model === null &&
                this.$v.rateDetail.distanceMinimumCharge.$model === null &&
                this.$v.rateDetail.includedDistance.$model === null
            ) {
                return false;
            }

            return true;
        },
        async create() {
            const ruleDaysValid = this.$refs.ruleValidDays.validate();
            if (!ruleDaysValid) {
                return;
            }

            if (this.isUpdate) {
                this.updateRuleDetails();
                return;
            }

            if (!this.validateData()) {
                showErrorMessage(this, 'Rule must have valid data', null);
                return;
            }

            this.$_handleLoaderState(true, 'PROCESSING...');
            const chargeType = this.rateChargingTypes.find((x) => x.key.toLowerCase() === this.$route.params.type);
            const obj = this.$_getRateRuleData(
                chargeType,
                this.user.publicUserId,
                this.rateDetail,
                this.customerId,
                this.listLength,
                this.rateGroupId,
                this.driverRuleCreation,
                this.driverUserId
            );

            const endpoint = '/api/rates/create';
            const payload = {
                method: 'post',
                data: obj
            };

            try {
                const response = await handleRequests(endpoint, payload);

                if (response.status === 200) {
                    this.$notify({
                        message: 'Successfully created rule.',
                        type: 'success'
                    });

                    this.resolve({
                        record: response.data
                    });
                } else {
                    showErrorMessage(this, 'Failed creating rule.', null);
                }
            } catch (error) {
                showErrorMessage(this, error.data, null);
            }

            this.$_handleLoaderState(false);
        },
        async updateRuleDetails() {
            if (!this.validateData()) {
                showErrorMessage(this, 'Rule must have valid data', null);
                return;
            }

            this.$_handleLoaderState(true, 'UPDATING...');

            const rateId = this.rateDetail.rateRulesId;
            this.rateDetail.condition = null;
            this.rateDetail.currency = null;
            this.rateDetail.priority = null;
            this.rateDetail.rateRulesId = null;

            const obj = {
                rateRulesId: rateId,
                rule: JSON.stringify(this.rateDetail)
            };

            const endpoint = '/api/rates/updateRate';
            const payload = {
                method: 'post',
                data: obj
            };

            try {
                const response = await handleRequests(endpoint, payload);

                if (response.status === 200) {
                    this.$notify({
                        message: 'Successfully updated rule.',
                        type: 'success'
                    });

                    this.resolve({
                        record: response.status
                    });
                } else {
                    showErrorMessage(this, 'Failed updating rule.', null);
                }
            } catch (error) {
                showErrorMessage(this, 'Failed updating rule.', null);
            }

            this.$_handleLoaderState(false);
        },
        handleDaysSeleceted(value) {
            this.rateDetail = Object.assign(this.rateDetail, value);
        }
    }
};
</script>
