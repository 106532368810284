<template>
    <div class="white-bg">
        <div class="mobile-filter-header">
            <div class="md-layout">
                <div class="md-layout-item md-xsmall-size-40">
                    <div class="datepicker-container">
                        <md-datepicker
                            v-model="selectedDate"
                            md-immediately
                            :md-debounce="100"
                            ref="mdDatePicker"
                            :md-model-type="String"
                        ></md-datepicker>
                        <span class="heavy-font-600">
                            {{ this.selectedDate | dateFormat(DATE_TYPES.standardDate) }}
                        </span>
                    </div>
                </div>
                <div class="md-layout-item md-xsmall-size-50" v-if="!isSingleUser && !isSingleTeamMember">
                    <div class="heavy-font-600 filter-btn">
                        <span @click="filterByTeamMember" class="filter-teammember">{{ selectedTeamMember.name }}</span>
                    </div>
                </div>
                <div class="md-layout-item md-xsmall-size-10">
                    <div @click="showFilter = !showFilter">
                        <md-icon v-if="showFilter">search_off</md-icon>
                        <md-icon v-else>search</md-icon>
                    </div>
                </div>
            </div>
            <div class="md-layout" v-if="showFilter">
                <div class="search-bar">
                    <input type="text" v-focus v-model="searchText" placeholder="Search by name or stop reference" />
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item stoplist-container">
                <div v-if="!loading">
                    <div v-if="searchStopList.length">
                        <div v-for="item in searchStopList" :key="item.stopId" class="stop-item">
                            <div class="item-details" :class="item.status == 'Complete' ? 'completed-stop' : ''">
                                <div class="item-status-icon">
                                    <md-button
                                        class="md-just-icon md-round"
                                        :class="`status-${item.status.toLowerCase()}`"
                                    >
                                        <md-icon>place</md-icon>
                                    </md-button>
                                </div>
                                <div>
                                    <router-link
                                        :to="{
                                            name: 'Stop Details',
                                            params: { stopId: item.stopId }
                                        }"
                                    >
                                        <div v-if="item.contact != null">{{ item.contact.name }}</div>
                                        <div class="heavy-font-600">{{ item.address }}</div>
                                    </router-link>
                                </div>
                            </div>
                            <div class="item-actions" v-if="item.status != 'Complete'">
                                <reassign-stop-button
                                    v-if="!isSingleTeamMember && !isSingleUser"
                                    :item="item"
                                    :team-members="teamMembers"
                                    @assignedUser="handleFilteredValue"
                                />
                                <div
                                    class="heavy-font-600"
                                    v-if="
                                        item.originalEta == null || (item.originalEta != null && today != selectedDate)
                                    "
                                >
                                    <span v-if="item.timeWindowStart != null && item.timeWindowEnd != null">
                                        Due: {{ item.timeWindowStart | timeFormat }} -
                                        {{ item.timeWindowEnd | timeFormat }}
                                    </span>
                                    <span v-else-if="item.appointmentTime != null">
                                        Due: {{ item.appointmentTime | timeFormat }}
                                    </span>
                                </div>
                                <div class="heavy-font-600" v-else>
                                    <span
                                        v-if="
                                            today == selectedDate &&
                                                item.originalEta != null &&
                                                item.status != 'Arrived' &&
                                                item.status != 'Departed' &&
                                                item.status != 'Complete'
                                        "
                                    >
                                        ETA: {{ item.originalEta | timeFormat }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="md-layout-item">
                        <span class="text-center no-result-message">No available stops to display.</span>
                    </div>
                </div>
                <div v-else>
                    <div class="stopbar-loader" v-if="!isLoading">
                        <fade-loader :loading="true" color="#333333" />
                        <span>LOADING</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { ReassignStopButton } from '@/pages/Stops/buttons';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { AssignStopModal } from '@/pages/Stops/components';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { handleRequests } from '@/helpers';

export default {
    name: 'MobileStopOverview',
    mixins: [GeneralMixin],
    components: { ReassignStopButton, FadeLoader },
    data() {
        return {
            showFilter: false,
            selectedDate: null,
            selectedTeamMember: {
                name: '',
                publicUserId: null
            },
            selectedStatus: 'All',
            searchStopList: [],
            searchText: '',
            loading: false,
            list: [],
            teamMembers: [],
            today: null
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isLoading: 'isLoading',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        })
    },
    watch: {
        selectedDate(newValue) {
            this.handleFilteredValue();
        },
        'selectedTeamMember.publicUserId': async function(newValue) {
            if (newValue) {
                await this.getStops();
            } else {
                await this.getUnassignedStops();
            }
        },
        // eslint-disable-next-line func-names
        searchText: _.debounce(function() {
            this.getFilteredSearchList();
        }, 500),
        isListLoading(newValue) {
            this.loading = newValue;
        },
        showFilter(newValue) {
            if (!newValue) {
                this.searchText = '';
            }
        },
        '$route.query': function({ userId }) {
            this.getMemberDetails(userId);
        }
    },
    async mounted() {
        const { userId } = this.$route.query;
        await this.getTeamMembers();

        this.selectedDate = moment().format(this.DATE_TYPES.internationalDate);
        this.today = moment().format(this.DATE_TYPES.internationalDate);

        if (userId) {
            this.getMemberDetails(userId);
        } else {
            this.$router.replace({ path: `/stops/list`, query: { userId: this.user.publicUserId } });
            this.selectedTeamMember.name = this.user.fullName;
            this.selectedTeamMember.publicUserId = this.user.publicUserId;
        }

        if (this.$refs.mdDatePicker) 
            this.$refs.mdDatePicker.$refs.input.$el.setAttribute('readonly', true);
    },
    methods: {
        async getUnassignedStops() {
            this.$_handleLoaderState(true);
            this.loading = true;
            const endpoint = `/api/stops/unassigned?tripDate=${this.selectedDate}`;
            const { data } = await handleRequests(endpoint);
            this.list = data;
            this.searchStopList = data;
            this.loading = false;
            this.$_handleLoaderState(false);
        },
        async getStops() {
            this.$_handleLoaderState(true);
            this.loading = true;
            const endpoint = `/api/trips/${this.selectedDate}?userId=${this.selectedTeamMember.publicUserId}`;
            const {
                data: { data }
            } = await handleRequests(endpoint);
            this.list = data.stopList;
            this.searchStopList = data.stopList;
            this.loading = false;
            this.$_handleLoaderState(false);
        },
        async getTeamMembers() {
            this.$_handleLoaderState(true);
            this.loading = true;
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS');
            this.loading = false;
            this.$_handleLoaderState(false);
        },
        getMemberDetails(userId) {
            if (this.teamMembers.length > 0 && userId) {
                const member = this.teamMembers.find((x) => x.publicUserId === userId);
                this.selectedTeamMember.name = member.fullName;
                this.selectedTeamMember.publicUserId = userId;
            }
        },
        filterByTeamMember() {
            this.$modal
                .show(AssignStopModal, {
                    isFilterSelection: true,
                    teamMembers: this.teamMembers,
                    assignedTo: this.selectedTeamMember.publicUserId
                })
                .then((response) => {
                    const member = this.teamMembers.find((x) => x.publicUserId === response);
                    if (member) {
                        this.$router.push({ path: `/stops/list`, query: { userId: response } });
                        this.selectedTeamMember.publicUserId = response;
                        this.selectedTeamMember.name = member.fullName;
                    } else {
                        this.$router.push({ path: `/stops/list`, query: {} });
                        this.selectedTeamMember.publicUserId = null;
                        this.selectedTeamMember.name = 'Unassigned';
                    }
                });
        },
        async handleFilteredValue() {
            this.$nextTick(async () => {
                if (this.selectedTeamMember.publicUserId) {
                    await this.getStops();
                } else {
                    await this.getUnassignedStops();
                }
            });
        },
        getFilteredSearchList() {
            this.loading = true;
            let data = this.list;
            if (this.searchText) {
                data = this.list.filter((stop) => {
                    if (
                        (stop.contact != null &&
                            (stop.contact.name || '').toLowerCase().includes(this.searchText.toLowerCase())) ||
                        stop.address.toLowerCase().includes(this.searchText.toLowerCase())
                    ) {
                        return true;
                    }

                    return false;
                });
            }

            this.searchStopList = data;
            this.loading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
$status-pending: #c2907f;
$status-enroute: #4ed2ff;
$status-arrived: #2b93ff;
$status-departed: #ffb42b;
$status-cancelled: #ff5245;
$status-delayed: #384553;
$status-complete: #0bda8e;

.white-bg {
    background-color: #fff;
}
.no-result-message {
    font-size: 1.4rem;
    display: block;
}

.stopbar-loader {
    position: absolute;
    top: 55%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

.stoplist-container {
    padding-top: 20px;
}
.stop-item {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;

    > div {
        display: inline-block;
        vertical-align: middle;
        font-weight: 400;
        color: #3c4858;
    }
    .item-details {
        width: calc(100% - 100px);
        > div {
            display: inline-block;
            vertical-align: middle;
        }
        .item-status-icon {
            width: 45px;
            > button {
                margin: 0;
                height: 35px;
                min-width: 35px;
                width: 35px;
                line-height: 35px;
            }
        }
        > div:last-child {
            width: calc(100% - 50px);
            a > div {
                color: #3c4858;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .completed-stop {
        width: 100%;
        > div:last-child {
            text-decoration: line-through;
            a {
                color: #3c4858;
            }
        }
    }
    .item-actions {
        width: 100px;

        ::v-deep .reassign-btn-mobile > span {
            background-color: #eee;
            width: 100px;
            height: 30px;
            display: block;
            text-align: center;
            line-height: 30px;
            font-size: 15px;
            border-radius: 20px;
            margin: 0 auto;
        }
        > div:last-child {
            text-align: center;
            > span {
                font-size: 13px;
            }
        }
    }
    .status-pending {
        background-color: $status-pending !important;
        color: #fff !important;
    }
    .status-pending:hover,
    .status-pending:focus {
        color: #fff !important;
    }
    .status-enroute {
        background-color: $status-enroute !important;
    }
    .status-arrived {
        background-color: $status-arrived !important;
    }
    .status-departed {
        background-color: $status-departed !important;
    }
    .status-complete {
        background-color: $status-complete !important;
    }
    .status-delayed {
        background-color: $status-delayed !important;
    }
    .status-cancelled {
        background-color: $status-cancelled !important;
    }
}
</style>
