<template>
    <md-button
        v-if="!isReadOnlyUser"
        title="Delete item"
        :class="['md-danger md-just-icon md-round', isDisabled ? 'button-disabled' : '']"
        @click.stop="handleDeleteItem(itemId)"
    >
        <md-icon>delete</md-icon>
    </md-button>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';

export default {
    name: 'DeleteItemButton',
    props: {
        itemId: {
            type: [Number, String],
            default: () => null
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        isShipmentAttached: {
            type: Boolean,
            required: true
        },
        isStopAttached: {
            type: Boolean,
            required: true
        },
        isOrderAttached: {
            type: Boolean,
            required: true
        }
    },
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    methods: {
        handleDeleteItem(itemId) {
            //
            let message = 'Are you sure you want to delete this item?';
            if (this.isOrderAttached) {
                message = `This item is attached to an Order.<br/>${message}`;
            } else if (this.isShipmentAttached) {
                message = `This item is attached to a Shipment.<br/>${message}`;
            } else if (this.isStopAttached) {
                message = `This item is attached to a Stop.<br/>${message}`;
            }

            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Item',
                    body: message,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmDelete(itemId);
                });
        },
        async handleConfirmDelete(itemId) {
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'delete'
            };
            const api = `/api/items/${itemId}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Item was deleted!',
                    type: 'success'
                });
                this.$emit('itemDeleted', true);
            } catch (e) {
                const message = 'Error in deleting the item.';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
        }
    }
};
</script>
<style lang="scss" scoped>
.button-disabled {
    opacity: 0.2 !important;
    cursor: not-allowed;
    pointer-events: all !important;
}
</style>
