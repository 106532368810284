var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.active,
          expression: "active",
        },
      ],
      staticClass: "tab-pane fade",
      class: { "active show": _vm.active },
      attrs: {
        id: _vm.tabId,
        role: "tabpanel",
        "aria-hidden": !_vm.active,
        "aria-labelledby": "step-" + _vm.tabId,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }