<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Remove Support User</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.user" class="form-wrapper">
                <support-users-autocomplete
                    ref="usersRef"
                    label="User"
                    placeholder=""
                    v-model="user"
                    :should-focus="true"
                    :is-required="true"
                />
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="removeUser">Remove</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { required, maxLength } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { SupportUsersAutocomplete } from '@/components';

export default {
    name: 'RemoveSupportUserModal',
    components: { SupportUsersAutocomplete },
    mixins: [GeneralMixin],
    props: {
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            user: {
                publicUserId: null
            }
        };
    },
    mounted() {},
    methods: {
        async removeUser() {
            this.$v.$touch();
            // Not the best... aren't we using any kind of event bus?
            this.$refs.usersRef.callTouch();

            if (!this.$v.$invalid) {
                try {
                    this.$_handleLoaderState(true, 'UPDATING...');

                    const api = `/api/support/users/${this.user.publicUserId}/remove`;
                    const response = await handleRequests(api, { method: 'post' });

                    if (response.status === 200) {
                        this.$notify({
                            message: `User removed!`,
                            type: 'success'
                        });
                        this.$v.$reset();

                        await this.$store.dispatch('user/FETCH_USER');

                        this.resolve(true);
                    }
                } catch (e) {
                    const message = 'Cannot remove user.';
                    showErrorMessage(this, message);
                    this.resolve(false);
                } finally {
                    this.$_handleLoaderState(false);
                }
            }
        }
    },
    validations: {
        user: {
            email: { required, maxLength: maxLength(100) }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-container {
    max-width: 600px;
}
</style>
