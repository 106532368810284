var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "md-layout" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-green" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("schedule")])],
                      1
                    ),
                  ]
                ),
                _c("md-card-content", { staticClass: "body-list" }, [
                  !_vm.isListLoading
                    ? _c(
                        "div",
                        [
                          _vm.maintenanceScheduleList.length > 0
                            ? _c(
                                "md-table",
                                { staticClass: "context-menu-support" },
                                [
                                  _c(
                                    "md-table-row",
                                    [
                                      _c("md-table-head"),
                                      _c("md-table-head", [
                                        _vm._v("Upcoming Maintenance"),
                                      ]),
                                      _c("md-table-head", [
                                        _vm._v("Asset Name"),
                                      ]),
                                      _c("md-table-head", [
                                        _vm._v("Reminder Type"),
                                      ]),
                                      _c("md-table-head", [_vm._v("Status")]),
                                      _c("md-table-head", [_vm._v("Actions")]),
                                    ],
                                    1
                                  ),
                                  _vm._l(
                                    _vm.maintenanceScheduleList,
                                    function (item, index) {
                                      return _c(
                                        "md-table-row",
                                        {
                                          key: index,
                                          class:
                                            "status-row-" +
                                            item.status
                                              .replace(/\s/g, "")
                                              .toLowerCase(),
                                        },
                                        [
                                          _c(
                                            "md-table-cell",
                                            {
                                              staticClass: "status-icon-column",
                                            },
                                            [
                                              item.status
                                                .replace(/\s/g, "")
                                                .toLowerCase() == "duesoon"
                                                ? _c(
                                                    "md-icon",
                                                    {
                                                      class:
                                                        "status-" +
                                                        item.status
                                                          .replace(/\s/g, "")
                                                          .toLowerCase(),
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        report_problem\n                                        "
                                                      ),
                                                      _c(
                                                        "md-tooltip",
                                                        {
                                                          attrs: {
                                                            "md-direction":
                                                              "left",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.status)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.status
                                                .replace(/\s/g, "")
                                                .toLowerCase() == "overdue"
                                                ? _c(
                                                    "md-icon",
                                                    {
                                                      class:
                                                        "status-" +
                                                        item.status
                                                          .replace(/\s/g, "")
                                                          .toLowerCase(),
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        report\n                                        "
                                                      ),
                                                      _c(
                                                        "md-tooltip",
                                                        {
                                                          attrs: {
                                                            "md-direction":
                                                              "left",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.status)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c("md-table-cell", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(item.maintenanceName) +
                                                "\n                                "
                                            ),
                                          ]),
                                          _c(
                                            "md-table-cell",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "a-link",
                                                  attrs: {
                                                    to: {
                                                      name: "Asset Details",
                                                      params: {
                                                        assetId: item.assetId,
                                                      },
                                                    },
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(item.assetName) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "md-table-cell",
                                            [
                                              _c("reminder-indicator", {
                                                key: _vm.counter,
                                                attrs: {
                                                  "maintenance-data": item,
                                                  "current-engine-hours":
                                                    item.estimatedEngineHourValue,
                                                  "next-engine-hour-due":
                                                    item.nextDueEngineHours !=
                                                    null
                                                      ? item.nextDueEngineHours
                                                      : null,
                                                  "next-due-distance":
                                                    item.nextDueOdometer != null
                                                      ? item.nextDueOdometer
                                                      : null,
                                                  "current-distance-reading":
                                                    item.estimatedOdometerValue,
                                                  "repeat-interval": 1,
                                                  "next-due-date":
                                                    item.nextDueServiceDate,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              staticClass:
                                                "maintenance-status-container",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "custom-badge generic-badge",
                                                  class:
                                                    item.status
                                                      .replace(/\s/g, "")
                                                      .toLowerCase() + "-badge",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(item.status) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            { staticClass: "action-buttons" },
                                            [
                                              _c(
                                                "complete-maintenance-schedule-button",
                                                {
                                                  attrs: {
                                                    "maintenance-data": item,
                                                    "asset-data": item.asset,
                                                  },
                                                  on: {
                                                    maintenanceCompleted:
                                                      _vm.handleCompleteSchedule,
                                                  },
                                                }
                                              ),
                                              _c(
                                                "update-maintenance-schedule-button",
                                                {
                                                  attrs: {
                                                    "maintenance-data": item,
                                                    "asset-data": item.asset,
                                                  },
                                                  on: {
                                                    maintenanceCompleted:
                                                      _vm.handleCompleteSchedule,
                                                  },
                                                }
                                              ),
                                              _c(
                                                "remove-maintenance-schedule-button",
                                                {
                                                  attrs: {
                                                    "maintenance-data": item,
                                                    "asset-data": item.asset,
                                                  },
                                                  on: {
                                                    maintenanceCompleted:
                                                      _vm.handleCompleteSchedule,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.maintenanceScheduleList.length == 0 &&
                          !_vm.isListLoading
                            ? _c("div", [
                                _c("p", { staticClass: "no-result-message" }, [
                                  _vm._v(
                                    "No results matching your search/filter could be found."
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("div", [
                        _c(
                          "div",
                          { staticClass: "custom-loader" },
                          [
                            _c("fade-loader", {
                              attrs: {
                                loading: _vm.isListLoading,
                                color: "#333333",
                              },
                            }),
                            _c("span", [_vm._v("LOADING")]),
                          ],
                          1
                        ),
                      ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "md-card-actions",
        {
          staticClass: "page-footer",
          attrs: { "md-alignment": "space-between" },
        },
        [
          _c("div", [
            _vm.total === _vm.pagination.perPage
              ? _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "\n                Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of many\n            "
                  ),
                ])
              : _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of " +
                      _vm._s(_vm.totalPages)
                  ),
                ]),
          ]),
          _c("pagination", {
            staticClass: "pagination-no-border pagination-success",
            attrs: { "per-page": _vm.pagination.perPage, total: _vm.total },
            on: {
              "change-page": function ($event) {
                return _vm.handleChangePage($event, _vm.pagination.perPage)
              },
            },
            model: {
              value: _vm.pagination.currentPage,
              callback: function ($$v) {
                _vm.$set(_vm.pagination, "currentPage", $$v)
              },
              expression: "pagination.currentPage",
            },
          }),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.showSidebar
            ? _c("maintenance-schedule-sidebar", {
                attrs: { "sidebar-data": _vm.sidebarData },
                on: { closeModal: _vm.toggleSidebar },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "md-layout-item" }, [
      _c("div", { staticClass: "custom-toolbar" }, [
        _c("div", { staticClass: "custom-toolbar-start" }),
        _c("div", { staticClass: "custom-toolbar-end" }, [_c("div")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }