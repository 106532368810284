<template>
    <router-view />
</template>

<script>
/* eslint-disable vue-scoped-css/require-scoped */
export default {};
</script>

<style lang="scss">
@import '@/assets/scss/custom-elements.scss';
</style>
