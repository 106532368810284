<template>
    <div class="modal-container">
        <div class="modal-header">
            <h5 class="modal-title">Change Email</h5>
        </div>
        <div class="modal-body">
            <md-label>
                This will change both your email address AND username, and you will have to re-verify your email
                address. You will need to re-enter your password to confirm.
            </md-label>
            <md-field>
                <md-input type="password" v-model="password" />
            </md-field>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validatePassword">
                Confirm
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ValidatePasswordModal',
    props: {
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            password: ''
        };
    },
    methods: {
        validatePassword() {
            this.resolve(this.password);
        }
    }
};
</script>
<style lang="scss" scoped>
.modal-container {
    max-width: 250px;
}
</style>
