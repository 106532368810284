var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.isUpdate ? "Update" : "Create") + " Run Schedule"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body stop-form-modal" },
      [
        _c(
          "form-wrapper",
          {
            staticClass: "form-wrapper",
            attrs: { validator: _vm.$v.runDetails },
          },
          [
            _c(
              "div",
              { staticClass: "stop-form-container" },
              [
                _c("div", { staticClass: "md-layout run-item-layout" }, [
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-70" },
                    [
                      _c(
                        "form-group",
                        { attrs: { name: "runName", label: "Name" } },
                        [
                          _c("md-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.runDetails.runName,
                              callback: function ($$v) {
                                _vm.$set(_vm.runDetails, "runName", $$v)
                              },
                              expression: "runDetails.runName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-30" },
                    [
                      _c(
                        "form-group",
                        { attrs: { name: "runnumber", label: "Run Number" } },
                        [
                          _c("md-input", {
                            attrs: { type: "number", min: "1" },
                            model: {
                              value: _vm.runDetails.runNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.runDetails,
                                  "runNumber",
                                  _vm._n($$v)
                                )
                              },
                              expression: "runDetails.runNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("charging-type-options", {
                  attrs: {
                    "update-stop": true,
                    "selected-charge-type-id": _vm.runDetails.rateGroupId,
                  },
                  on: { selectedOption: _vm.handleRateTypeChanged },
                }),
                _c("team-region-member-controls", {
                  attrs: {
                    "team-region-id": _vm.runDetails.teamRegionId,
                    "public-user-id": _vm.runDetails.assignToPublicUserId,
                    mode: _vm.isUpdate ? "update" : "create",
                  },
                  on: { onChanged: _vm.handleTeamRegionMemberChanged },
                }),
                _c(
                  "div",
                  { staticClass: "contact-details" },
                  [
                    _c("customer-autocomplete", {
                      attrs: {
                        label: "Customer",
                        id: "autocustomer",
                        placeholder: "",
                        "should-focus": false,
                        "auto-fill-address": true,
                      },
                      on: {
                        input: _vm.onCustomerChanged,
                        changeCustomerRateGroup:
                          _vm.onCustomerRateGroupIdChange,
                      },
                      model: {
                        value: _vm.runDetails,
                        callback: function ($$v) {
                          _vm.runDetails = $$v
                        },
                        expression: "runDetails",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("google-autocomplete", {
                      ref: "startlocation",
                      attrs: {
                        label: "Start Address",
                        id: "startlocationId",
                        classname: "form-control autocomplete-input",
                        placeholder: "",
                        "should-focus": false,
                        name: "runStartLocation.address",
                        "disable-gps-coordinates": false,
                        "gps-coordinate-placeholder":
                          "Enter a name for the start address",
                      },
                      on: {
                        handleCoordinates: _vm.handleStartAddressCoordinates,
                        placechanged: _vm.onStartLocationChanged,
                      },
                      model: {
                        value: _vm.runDetails.runStartLocation,
                        callback: function ($$v) {
                          _vm.$set(_vm.runDetails, "runStartLocation", $$v)
                        },
                        expression: "runDetails.runStartLocation",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("google-autocomplete", {
                      ref: "endlocation",
                      attrs: {
                        name: "runEndLocation.address",
                        id: "endlocationId",
                        classname: "form-control autocomplete-input",
                        label: "End Address",
                        placeholder: "",
                        "disable-gps-coordinates": false,
                        "gps-coordinate-placeholder":
                          "Enter a name for the end address",
                      },
                      on: {
                        handleCoordinates: _vm.handleEndAddressCoordinates,
                        placechanged: _vm.onEndLocationChanged,
                      },
                      model: {
                        value: _vm.runDetails.runEndLocation,
                        callback: function ($$v) {
                          _vm.$set(_vm.runDetails, "runEndLocation", $$v)
                        },
                        expression: "runDetails.runEndLocation",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "md-layout run-item-layout" }, [
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-50" },
                    [
                      _c(
                        "form-group",
                        { attrs: { name: "starttime", label: "Start Time" } },
                        [
                          _c("time-picker", {
                            attrs: {
                              time: _vm.runDetails.startTime,
                              "all-time-options": false,
                            },
                            on: { selectedTime: _vm.setStartTime },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-50" },
                    [
                      _c(
                        "form-group",
                        { attrs: { name: "endtime", label: "End Time" } },
                        [
                          _c("time-picker", {
                            attrs: {
                              time: _vm.runDetails.endTime,
                              "all-time-options": false,
                            },
                            on: { selectedTime: _vm.setEndTime },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "md-layout run-item-layout" }, [
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-50" },
                    [
                      _c(
                        "form-group",
                        {
                          attrs: {
                            name: "startlocationstopdurationminutes",
                            label: "Start Location Stop Duration (mins)",
                          },
                        },
                        [
                          _c("md-input", {
                            attrs: { type: "number", min: "1" },
                            model: {
                              value:
                                _vm.runDetails.startLocationStopDurationMinutes,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.runDetails,
                                  "startLocationStopDurationMinutes",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "runDetails.startLocationStopDurationMinutes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-50" },
                    [
                      _c(
                        "form-group",
                        {
                          attrs: {
                            name: "endlocationstopdurationminutes",
                            label: "End Location Stop Duration (mins)",
                          },
                        },
                        [
                          _c("md-input", {
                            attrs: { type: "number", min: "1" },
                            model: {
                              value:
                                _vm.runDetails.endLocationStopDurationMinutes,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.runDetails,
                                  "endLocationStopDurationMinutes",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "runDetails.endLocationStopDurationMinutes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "form-group",
                  { attrs: { label: "Schedule", name: "schedule" } },
                  [
                    _c(
                      "md-select",
                      {
                        model: {
                          value: _vm.runDetails.scheduleType,
                          callback: function ($$v) {
                            _vm.$set(_vm.runDetails, "scheduleType", $$v)
                          },
                          expression: "runDetails.scheduleType",
                        },
                      },
                      _vm._l(_vm.scheduleTypes, function (scheduleType) {
                        return _c(
                          "md-option",
                          { key: scheduleType, attrs: { value: scheduleType } },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(scheduleType) +
                                "\n                        "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm.runDetails.scheduleType == "Fortnightly"
                  ? _c(
                      "div",
                      { staticClass: "date-picker-div hide-clear-button" },
                      [
                        _c(
                          "md-datepicker",
                          {
                            ref: "mdDatePicker",
                            attrs: {
                              name: "activefromdate",
                              "md-immediately": "",
                            },
                            model: {
                              value: _vm.runDetails.activeFromDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.runDetails, "activeFromDate", $$v)
                              },
                              expression: "runDetails.activeFromDate",
                            },
                          },
                          [_c("label", [_vm._v("Start Date")])]
                        ),
                        _vm.runDetails.activeFromDate === null
                          ? _c("span", [
                              _vm._v(
                                "\n                        Start date is required.\n                    "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                ["Weekly", "Fortnightly"].includes(_vm.runDetails.scheduleType)
                  ? _c(
                      "div",
                      { staticClass: "md-layout weekly-box" },
                      [
                        _c("label", [_vm._v("Every:")]),
                        _vm._l(_vm.weekDays, function (day, index) {
                          return _c(
                            "md-checkbox",
                            {
                              key: day,
                              attrs: { value: index },
                              model: {
                                value: _vm.scheduleWeeklyValue,
                                callback: function ($$v) {
                                  _vm.scheduleWeeklyValue = $$v
                                },
                                expression: "scheduleWeeklyValue",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(day) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "form-group",
                  {
                    attrs: {
                      name: "autocreatetripdaysinadvance",
                      label: "Auto Create Trip Days In Advance",
                    },
                  },
                  [
                    _c("md-input", {
                      attrs: { type: "number", min: "1" },
                      model: {
                        value: _vm.runDetails.autoCreateTripDaysInAdvance,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.runDetails,
                            "autoCreateTripDaysInAdvance",
                            _vm._n($$v)
                          )
                        },
                        expression: "runDetails.autoCreateTripDaysInAdvance",
                      },
                    }),
                  ],
                  1
                ),
                _vm.showCreateEmptyTrip(_vm.runDetails)
                  ? _c("div", { staticClass: "md-layout run-item-layout" }, [
                      _c(
                        "div",
                        { staticClass: "md-layout-item" },
                        [
                          _c(
                            "md-checkbox",
                            {
                              model: {
                                value: _vm.runDetails.createEmptyTrip,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.runDetails,
                                    "createEmptyTrip",
                                    $$v
                                  )
                                },
                                expression: "runDetails.createEmptyTrip",
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Create empty trip\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.validateRunSchedule },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isUpdate ? "Update" : "Create") +
                "\n        "
            ),
          ]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }