<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
                <md-icon>miscellaneous_services</md-icon>
            </div>
        </md-card-header>
        <md-card-content>
            <p v-if="!lines || lines.length === 0" class="grayedout-text text-center">
                No services found
            </p>
            <div v-else>
                <md-table class="context-menu-support custom-paginated-table">
                    <md-table-row>
                        <md-table-head>Service</md-table-head>
                        <md-table-head>Quantity</md-table-head>
                        <md-table-head v-if="$root.isDesktop && !isReadOnlyUser">Actions</md-table-head>
                    </md-table-row>
                    <md-table-row v-for="line in lines" :key="line.StopLines">
                        <md-table-cell>{{ line.description }}</md-table-cell>
                        <md-table-cell>
                            {{ line.quantity }}
                            <span class="quantity-type">{{ line.quantityType }}</span>
                        </md-table-cell>

                        <md-table-cell v-if="$root.isDesktop && !isReadOnlyUser" class="action-buttons">
                            <!--<update-line-button
                                v-if="!isReadOnlyUser"
                                :service="line"
                                @serviceUpdated="handleUpdateService"
                            />-->

                            <div class="action-button">
                                <md-button
                                    v-if="!isReadOnlyUser"
                                    title="Delete line"
                                    class="md-danger md-just-icon md-round"
                                    :disabled="isInvoiced"
                                    @click.stop="handleDeleteService(line.stopLineId)"
                                >
                                    <md-icon>delete</md-icon>
                                </md-button>
                                <!--<md-tooltip v-if="belongsToShipment" class="line-tooltip" md-direction="left">
                                    This stop belongs to a shipment.
                                    <br />
                                    Open the shipment details from the top of the page to delete the line.
                                </md-tooltip>-->
                                <md-tooltip v-if="isInvoiced" class="line-tooltip" md-direction="top">
                                    You cannot delete a line when the stop is invoiced.
                                </md-tooltip>
                            </div>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
            </div>
        </md-card-content>

        <md-card-actions md-alignment="right">
            <div>
                <md-button :disabled="isInvoiced" class="md-success" @click="handleAddLine()" v-if="!isSupportStaff">
                    Add Line
                </md-button>
                <!--<md-tooltip v-if="belongsToShipment" class="line-tooltip" md-direction="left">
                    This stop belongs to a shipment.
                    <br />
                    Open the shipment details from the top of the page to add new line.
                </md-tooltip>-->
                <md-tooltip v-if="isInvoiced" class="line-tooltip" md-direction="left">
                    Not allowed to add a line when the stop is already invoiced.
                </md-tooltip>
            </div>
        </md-card-actions>
    </md-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import ServiceLineModal from './ServiceLineModal';

export default {
    name: 'ServicesLines',
    mixins: [GeneralMixin],
    components: {},
    props: {
        lines: {
            type: Array,
            default: () => []
        },
        stopId: {
            type: Number,
            default: null
        },
        belongsToShipment: {
            type: Boolean,
            default: false
        },
        stopStatus: {
            type: String,
            required: true
        },
        isInvoiced: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser',
            isSupportStaff: 'user/isSupportStaff'
        })
    },
    methods: {
        handleAddLine() {
            this.$modal
                .show(ServiceLineModal, {
                    stopId: this.stopId,
                    lines: this.lines
                })
                .then((response) => {
                    this.$emit('createdLine', response);
                    this.$modal.hide();
                });
        },
        handleUpdateService() {
            this.$emit('createdLine');
        },
        async handleConfirmDelete(stopLineId) {
            this.$_handleLoaderState(true, 'UPDATING...');

            try {
                const payload = {
                    method: 'delete'
                };
                const api = `/api/stops/${this.stopId}/lines/${stopLineId}`;

                await handleRequests(api, payload);

                this.$emit('createdLine');

                this.$notify({
                    message: `Line deleted!`,
                    type: 'success'
                });
            } catch (e) {
                const message = 'Cannot delete line.';
                showErrorMessage(this, message);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async handleDeleteService(serviceId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Line',
                    body: 'Are you sure you want to delete this line?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmDelete(serviceId);
                });
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped>
.custom-btn {
    margin-right: 16px;
}
.action-button {
    display: inline-block;
}
/*.quantity-type {
    display: inline-block;
    text-transform: lowercase;
}
.quantity-type:first-letter {
    text-transform: capitalize;
}*/
</style>
