<template>
    <div>
        <md-card-content>
            <md-table :value="distanceData" class="table-hover">
                <md-table-row slot="md-table-row" slot-scope="{ item }" :style="bgc(item)">
                    <md-table-cell v-if="!publicUserId" md-label="Asset Group / User">
                        {{ item.name }}
                    </md-table-cell>

                    <md-table-cell :md-label="getPeriodLabel">
                        {{ item.periodText }}
                        <small v-if="item.periodSubtext">
                            <br />
                            {{ item.periodSubtext }}
                        </small>
                    </md-table-cell>
                    <md-table-cell md-label="Total Distance" md-sort>
                        {{ toDistanceStr(item.travelDistanceKm) }}
                    </md-table-cell>
                    <md-table-cell md-label="Travel Time">
                        {{ toTime(item.travelTimeMinutes) }}
                    </md-table-cell>

                    <md-table-cell
                        v-if="isTrackerData !== true && getPeriod === 'day'"
                        md-label="Estimated Distance"
                        md-sort
                    >
                        {{ toDistanceStr(item.estimatedTravelDistanceKm) }}
                    </md-table-cell>
                    <md-table-cell v-if="isTrackerData !== true && getPeriod === 'day'" md-label="Estimated Time">
                        {{ toTime(item.estimatedTravelTimeMinutes) }}
                    </md-table-cell>

                    <!--Ignition On / Off date/times-->
                    <md-table-cell v-if="getPeriod === 'day' || publicUserId === null" md-label="First Ignition On">
                        {{ displayDateTime(item.firstIgnitionOn, item.utcOffset) }}
                    </md-table-cell>
                    <md-table-cell v-if="getPeriod === 'day' || publicUserId === null" md-label="Last Ignition Off">
                        {{ displayDateTime(item.lastIgnitionOff, item.utcOffset) }}
                    </md-table-cell>
                    <md-table-cell md-label="Working Time">
                        {{ toTime(item.workingTimeMinutes) }}
                    </md-table-cell>

                    <md-table-cell v-if="$root.isDesktop && !isTrackerData && getPeriod === 'day'" md-label="Actions">
                        <template v-if="item.tripID">
                            <span class="view-map">
                                <router-link
                                    :to="{
                                        name: 'Trip Details',
                                        params: {
                                            tripId: item.tripID
                                        }
                                    }"
                                    target="_blank"
                                >
                                    <md-icon>description</md-icon>
                                </router-link>
                                <md-tooltip md-direction="left">View trip details</md-tooltip>
                            </span>

                            <span class="view-map">
                                <router-link
                                    :to="{
                                        name: 'Maps Overview Active User Panel',
                                        params: {
                                            date: getItemMoment(item).format(DATE_TYPES.internationalDate),
                                            userId: publicUserId
                                        }
                                    }"
                                    target="_blank"
                                >
                                    <md-icon>map</md-icon>
                                </router-link>
                                <md-tooltip md-direction="left">View stops in a map</md-tooltip>
                            </span>
                        </template>
                    </md-table-cell>
                </md-table-row>
            </md-table>
            <div v-if="publicUserId" class="label-total">
                <span>Total Distance: {{ getTotalDistance }}</span>
                <span>Total Duration: {{ getTotalDuration }}</span>
                <span>Total Working Time: {{ getTotalWorkingTime }}</span>
            </div>
        </md-card-content>
    </div>
</template>
<style lang="scss" scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}
.label-total {
    float: right;
    font-size: 14px;
    font-weight: 500;
    span {
        display: block;
    }
}
.view-map {
    margin-right: 5px;
    cursor: pointer;
    ::v-deep .md-icon {
        color: #2b93ff;
    }
}
</style>
<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins';
import { DATE_TYPES } from '@/utils/constants';

export default {
    name: 'DistanceReportDetails',
    components: {},
    mixins: [GeneralMixin],
    props: {
        distancesList: {
            type: Array,
            default: () => []
        },
        publicUserId: {
            type: String,
            default: () => null
        },
        isTrackerData: {
            type: Boolean
        },
        period: {
            type: String,
            default: () => 'day'
        },
        discrepancy: {
            type: Object,
            default: () => null
        }
    },
    computed: {
        distanceData() {
            return this.distancesList;
        },
        getTotalDistance() {
            return this.toDistanceStr(this.distanceData.reduce((a, b) => a + b.travelDistanceKm, 0));
        },
        getTotalDuration() {
            return this.toTime(this.distanceData.reduce((a, b) => a + b.travelTimeMinutes, 0));
        },
        getTotalWorkingTime() {
            return this.toTime(this.distanceData.reduce((a, b) => a + b.workingTimeMinutes, 0));
        },
        user() {
            return this.$store.getters['user/user'];
        },
        speedUnits() {
            return this.user.speedUnits;
        },
        getPeriodLabel() {
            switch (this.getPeriod) {
                case 'day':
                    return 'Date';
                case 'week':
                    return 'Week';
                case 'month':
                    return 'Month';
                default:
                    return 'Date';
            }
        },
        getPeriod() {
            return this.period || 'day';
        }
    },
    methods: {
        bgc(item) {
            if (!this.discrepancy || !item.estimatedTravelDistanceKm || !item.estimatedTravelTimeMinutes)
                return { color: 'black' };
            if (
                (Math.abs(item.travelDistanceKm - item.estimatedTravelDistanceKm) / item.estimatedTravelDistanceKm) *
                    100 >=
                this.discrepancy.value
            )
                return { color: this.discrepancy.color };
            if (
                (Math.abs(item.travelTimeMinutes - item.estimatedTravelTimeMinutes) / item.estimatedTravelTimeMinutes) *
                    100 >=
                this.discrepancy.value
            )
                return { color: this.discrepancy.color };
            return { color: 'black' };
        },
        toDistanceStr(dist) {
            if (!dist) 
                return '---';
            if (this.speedUnits === 'kph') 
                return `${Math.round(dist * 10) / 10} km`;
            return `${Math.round(dist * 0.621371 * 10) / 10} mi`;
        },
        toTime(minutes) {
            if (!minutes) 
                return '---';

            // It would be better to have installed this package for durations: require("moment-duration-format");
            //   return moment.duration({ minutes: Math.round(minutes) }).format("hh:mm:ss", { trim: false });

            const duration = moment.duration({ minutes: Math.round(minutes) });

            const res = `${Math.floor(duration.asHours())}:${`0${duration.minutes()}`.slice(-2)}`;

            return res;
        },
        getItemMoment(item) {
            return moment(new Date(item.year, item.month - 1, item.day));
        },
        displayDateTime(time, utcOffset) {
            if (time) {
                return this.$options.filters.timeFormat(
                    moment(time).utcOffset(utcOffset),
                    DATE_TYPES.standardTime,
                    false
                );
                // return moment(time).utcOffset(utcOffset);
            }

            return '---';
        }
    }
};
</script>
