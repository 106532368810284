<template>
    <div>
        <md-card-content>
            <md-table :value="ratingReportData" class="table-hover">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Arrival Date" md-sort>
                        {{ item.arrivalDate | timeFormat(DATE_TYPES.standardDateTime) }}
                    </md-table-cell>

                    <md-table-cell md-label="Driver Name" md-sort>
                        {{ item.driverName }}
                    </md-table-cell>

                    <md-table-cell md-label="Stop Ref" md-sort>
                        <router-link
                            :to="{
                                name: 'Stop Details',
                                params: { stopId: item.stopId }
                            }"
                            target="_blank"
                        >
                            {{ item.stopReference }}
                        </router-link>
                    </md-table-cell>

                    <md-table-cell md-label="Contact Name" md-sort>
                        {{ item.stopContactName }}
                    </md-table-cell>
                    <md-table-cell md-label="Contact Phone" md-sort>
                        {{ item.stopContactPhone }}
                    </md-table-cell>

                    <md-table-cell md-label="Rating" md-sort class="rating">
                        <md-icon
                            v-for="index in 5"
                            :key="index"
                            :class="index <= item.rating ? 'orange-star' : 'default-star-rating'"
                        >
                            star_rate
                        </md-icon>
                    </md-table-cell>

                    <md-table-cell md-label="Feedback" md-sort class="feedback">
                        {{ item.feedback }}
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </md-card-content>
    </div>
</template>
<style lang="scss" scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

::v-deep .md-table-head {
    cursor: default;
}

.table-hover {
    cursor: pointer;
}

.label-total {
    float: right;
    font-size: 14px;
    font-weight: 500;

    span {
        display: block;
    }
}

.rating {
    width: 140px;
    min-width: 140px;
}

.default-star-rating {
    color: rgba(0, 0, 0, 0.54);
}

.orange-star {
    color: orange !important;
}
</style>
<script>
import { GeneralMixin } from '@/mixins';

export default {
    name: 'StopRatingReportDetails',
    mixins: [GeneralMixin],
    props: {
        ratingsList: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ratingReportData() {
            return this.ratingsList;
        }
    }
};
</script>
