var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "md-layout" }, [
      _c("div", { staticClass: "md-layout-item md-size-100" }, [
        _c("h5", [_vm._v(_vm._s(_vm.type) + " Information:")]),
      ]),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-100" },
        [
          _c(
            "form-group",
            { attrs: { name: "name", label: "Asset Name*" } },
            [
              _c("md-input", {
                model: {
                  value: _vm.data.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "name", $$v)
                  },
                  expression: "data.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.assetCustomFieldList !== null
        ? _c(
            "div",
            { staticClass: "md-layout-item md-size-100" },
            _vm._l(_vm.assetCustomFieldList, function (customField, i) {
              return _c("custom-field-inputs", {
                key: i,
                attrs: {
                  "custom-field-definition": customField,
                  "initial-value": _vm.data[customField.label],
                },
                on: { changed: _vm.handleCustomFieldChanged },
              })
            }),
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }