<template>
    <div class="detail-container">
        <div class="close-button">
            <md-button class="md-default md-just-icon md-round pull-right" @click="closeModal">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <div v-if="!loading && invoiceDetails.customerInvoiceId !== undefined">
            <div class="detail-section text-center">
                <span class="title">{{ invoiceDetails.barcode }}</span>
                <div class="detail-group text-center action-detail-group">
                    <router-link
                        title="View invoice details"
                        class="md-button md-success md-just-icon md-round"
                        :to="{
                            name: 'Customer Invoice Details',
                            params: { invoiceId: customerInvoiceId }
                        }"
                        target="_blank"
                    >
                        <div class="md-ripple">
                            <div class="md-button-content">
                                <md-icon>visibility</md-icon>
                            </div>
                        </div>
                    </router-link>
                    <delete-invoice-button
                        v-if="!isReadOnlyUser"
                        :customer-invoice-id="invoiceDetails.customerInvoiceId"
                        @invoiceDeleted="handleDeletedInvoice"
                        :status="invoiceDetails.status"
                    />
                </div>
            </div>
            <div class="line-separator"></div>
            <div class="detail-section">
                <span class="title">Invoice Details</span>
                <div class="detail-group">
                    <label class="label">Invoice Number:</label>
                    <span class="value">
                        <router-link
                            :to="{
                                name: 'Customer Invoice Details',
                                params: { invoiceId: customerInvoiceId }
                            }"
                            target="_blank"
                        >
                            {{ invoiceDetails.invoiceNumber }}
                        </router-link>
                    </span>
                </div>
                <div class="detail-group">
                    <label class="label">PO Number:</label>
                    <span class="value">
                        {{ invoiceDetails.purchaseOrderNumber }}
                    </span>
                </div>

                <div class="detail-group">
                    <label class="label">Customer:</label>
                    <span class="value">{{ invoiceDetails.customer }}</span>
                </div>

                <div class="detail-group">
                    <label class="label">Invoice Date:</label>
                    <span class="value">{{ invoiceDetails.invoiceDate | dateFormat(DATE_TYPES.standardDate) }}</span>
                </div>
                <div class="detail-group">
                    <label class="label">Due Date:</label>
                    <span class="value">{{ invoiceDetails.dueDate | dateFormat(DATE_TYPES.standardDate) }}</span>
                </div>

                <div class="detail-group">
                    <label class="label">Status:</label>
                    <span class="value">
                        <payment-status-button
                            :payment-status="invoiceDetails.status"
                            :customer-invoice-id="invoiceDetails.customerInvoiceId"
                            :invoice-details="invoiceDetails"
                            @statusUpdated="statusUpdated"
                        />
                    </span>
                </div>
            </div>

            <div class="line-separator" v-if="invoiceDetails.invoiceLines.length > 0"></div>
            <div class="detail-section" v-if="invoiceDetails.invoiceLines.length > 0">
                <span class="title">Lines</span>
                <div v-for="(line, index) in invoiceDetails.invoiceLines" :key="index">
                    <div class="detail-group item-reference">
                        <span class="label info-line">
                            {{ line.description }} x {{ line.quantity }}
                            <span class="quantity-type" v-if="line.quantityType">{{ line.quantityType }}</span>
                            <span class="price">
                                {{ (line.unitPriceExTax * line.quantity) | currency(line.priceCurrency) }}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="sidebar-loader">
                <fade-loader :loading="loading" color="#333333" />
                <span>LOADING</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { GeneralMixin, MapOverviewMixin } from '@/mixins';
import { PaymentStatusButton, DeleteInvoiceButton } from './buttons';

export default {
    name: 'CustomerInvoicesSidebar',
    mixins: [GeneralMixin, MapOverviewMixin],
    components: {
        FadeLoader,
        PaymentStatusButton,
        DeleteInvoiceButton
    },
    props: {
        customerInvoiceId: {
            type: Number,
            default: null
        },
        warehouses: {
            type: Array,
            default: null
        },
        teamMembers: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            stopLinesHistory: null,
            invoiceDetails: {
                status: null
            },
            loading: false
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    watch: {
        async customerInvoiceId(newValue, oldValue) {
            await this.getCustomerInvoiceDetails(newValue);
        }
    },
    async mounted() {
        await this.getCustomerInvoiceDetails(this.customerInvoiceId);
    },
    methods: {
        statusUpdated(updateData) {
            this.$set(this.invoiceDetails, 'status', updateData.status);

            this.$emit('invoiceUpdated', updateData);
        },
        handleDeletedInvoice({ result, invoiceId }) {
            this.$emit('invoiceDeleted', { result, invoiceId });
        },
        async getCustomerInvoiceDetails(customerInvoiceId) {
            try {
                this.loading = true;
                const api = `/api/customer-invoices/${customerInvoiceId}`;
                const { data } = await handleRequests(api);
                Object.assign(this.invoiceDetails, data);
            } catch (error) {
                const message = 'Error in getting the customer invoice details.';
                showErrorMessage(this, message, error);
            } finally {
                this.loading = false;
            }
        },
        closeModal() {
            this.$emit('closeModal');
        }
    }
};
</script>

<style lang="scss" scoped>
$label-width: 125px;

.detail-container {
    position: fixed;
    top: 2vh;
    right: 10px;
    height: 96vh;
    width: 25%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    background: white;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 16px;

    .close-button {
        top: 0;
        right: 0;
        position: absolute;
        /* add z-index for old shipments that have no shipment reference numbers */
        z-index: 999;
    }

    .md-button.md-just-icon,
    ::v-deep .md-button.md-just-icon {
        font-size: 15px;
        height: 30px;
        min-width: 3px;
        width: 30px;
        line-height: 30px;
        margin: 2.5px;
    }

    .detail-section {
        padding-top: 10px;

        .blue-ref a {
            color: #2b93ff !important;
        }

        .title {
            text-transform: uppercase;
            font-weight: 600;
            padding: 2px 16px;
            display: block;
            font-size: 14px;
        }

        ::v-deep .custom-a-blue a {
            color: #2b93ff !important;
        }
    }

    .line-separator {
        height: 1px;
        background-color: #ddd;
        width: calc(100% - 32px);
        margin: 10px auto;
        margin-bottom: 0px;
    }

    .detail-group {
        padding: 2px 16px;
        margin: 5px 0;
        font-size: 0.9em;

        .label {
            display: inline-block;
            width: $label-width;
            margin-right: 4px;
            vertical-align: top;
            font-weight: 400;
        }

        .custom-badge {
            margin: auto;
            max-width: unset;
        }

        .custom-badge-div {
            width: $label-width;
            margin: 0;
            text-align: left;
            float: left;
        }

        .value {
            display: inline-block;
            width: calc(100% - #{$label-width} - 10px);
            vertical-align: top;

            a {
                color: #2b93ff;
            }
        }

        .info-line {
            font-weight: 400;
            width: 100%;
        }

        .price {
            float: right;
        }

        .quantity-type {
            display: inline-block;
            text-transform: lowercase;
        }

        .quantity-type:first-letter {
            text-transform: capitalize;
        }
    }
}

.sidebar-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

.detail-section .address {
    cursor: pointer;
}

.stop-ref-badge {
    background-color: gray !important;
}
</style>
