<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100" v-if="hasTeam">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.importMappings') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
                <md-card-content>
                    <div>
                        <tabs
                            :tab-name="tabNames"
                            color-button="success"
                            class="custom-tab-list"
                            @switch-panel="beforeTabChanged"
                        >
                            <template v-for="(tabName, index) in tabNames" :slot="`tab-pane-${index + 1}`">
                                <div :key="index">
                                    <import-mapping-view
                                        :mapping-data="mappingData"
                                        @onUpload="onUpload"
                                        @handleSave="saveMapping"
                                        @handleReset="handleReset"
                                        @handleTemplateChanged="handleTemplateChanged"
                                        @handleAddNewTemplate="handleAddNewTemplate"
                                        @handleDeleteTemplate="handleDeleteTemplate"
                                        @updateViewType="updateViewType"
                                    ></import-mapping-view>
                                </div>
                            </template>
                        </tabs>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { Tabs } from '@/components';
import { mapGetters } from 'vuex';
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import ImportMappingView from './ImportMappings/ImportMappingView';
import TemplateNameModal from './ImportMappings/TemplateNameModal';

export default {
    name: 'ImportMappingTabs',
    mixins: [GeneralMixin],
    components: {
        Tabs,
        ImportMappingView
    },
    data() {
        return {
            tabNames: ['stops', 'shipments', 'customers', 'run Schedules'],
            mappingData: {
                isDefaultMappings: true,
                selectedTemplateName: 'Default',
                importMappingType: 'stops',
                viewType: 'designer',
                isJSONEditorView: false,
                isDesignerView: true,
                importMappingJson: null,
                templateNameList: [],
                importMappingFields: [],
                worksheetColumnFields: [],
                importMappingsColumns: {}
            },
            oldImportMappingJson: null,
            fileName: null,
            fields: {
                stops: [
                    'address',
                    'appointmentTime',
                    'brandName',
                    'rateGroupName',
                    'contact.name',
                    'contact.phone',
                    'contact.email',
                    'customerId',
                    'customerName',
                    'item1Barcode',
                    'item1Description',
                    'item2Barcode',
                    'item2Description',
                    'item3Barcode',
                    'item3Description',
                    'item4Barcode',
                    'item4Description',
                    'item5Barcode',
                    'item5Description',
                    'location.latitude',
                    'location.longitude',
                    'name',
                    'notes',
                    'order',
                    'rawTeamRegion',
                    'runNumber',
                    'runName',
                    'skills',
                    'source',
                    'sourceReference',
                    'durationMinutes',
                    'assignedTeamMemberId',
                    'assignedTeamMemberName',
                    'timeWindowStart',
                    'timeWindowEnd',
                    'tripDate',
                    'oneTimePin',
                    'type'
                ],
                shipments: [
                    'brandName',
                    'rateGroupName',
                    'contact.name',
                    'contact.phone',
                    'contact.email',
                    'customerId',
                    'customerName',
                    'item1Barcode',
                    'item1Description',
                    'item2Barcode',
                    'item2Description',
                    'item3Barcode',
                    'item3Description',
                    'item4Barcode',
                    'item4Description',
                    'item5Barcode',
                    'item5Description',
                    'notes',
                    'order',
                    'rawTeamRegion',
                    'runNumber',
                    'runName',
                    'skills',
                    'source',
                    'sourceReference',
                    'assignedTeamMemberId',
                    'assignedTeamMemberName',
                    'tripDate',

                    '_pickupStop_',
                    'pickupStop.address',
                    'pickupStop.appointmentTime',
                    'pickupStop.brandName',
                    'pickupStop.rateGroupName',
                    'pickupStop.contact.name',
                    'pickupStop.contact.phone',
                    'pickupStop.contact.email',
                    'pickupStop.customerId',
                    'pickupStop.customerName',
                    'pickupStop.location.latitude',
                    'pickupStop.location.longitude',
                    'pickupStop.name',
                    'pickupStop.notes',
                    'pickupStop.runNumber',
                    // "pickupStop.runName",
                    'pickupStop.durationMinutes',
                    'pickupStop.timeWindowStart',
                    'pickupStop.timeWindowEnd',
                    'pickupStop.tripDate',
                    'pickupStop.oneTimePin',

                    '_dropStop_',
                    'dropStop.address',
                    'dropStop.appointmentTime',
                    'dropStop.brandName',
                    'dropStop.rateGroupName',
                    'dropStop.contact.name',
                    'dropStop.contact.phone',
                    'dropStop.contact.email',
                    'dropStop.customerId',
                    'dropStop.customerName',
                    'dropStop.location.latitude',
                    'dropStop.location.longitude',
                    'dropStop.name',
                    'dropStop.notes',
                    'dropStop.runNumber',
                    // "dropStop.runName",
                    'dropStop.durationMinutes',
                    'dropStop.timeWindowStart',
                    'dropStop.timeWindowEnd',
                    'dropStop.tripDate',
                    'dropStop.oneTimePin'
                ],
                customers: [
                    'address',
                    'company',
                    'defaultStopDurationMinutes',
                    'email',
                    'firstName',
                    'lastName',
                    'location.latitude',
                    'location.longitude',
                    'notes',
                    'phone'
                ],
                runSchedules: [
                    'name',
                    'runNumber',
                    'scheduleType',
                    'schedule',
                    'assignedTeamMemberName',
                    'customerName',
                    'startAddress',
                    'endAddress',
                    'startTime',
                    'endTime',
                    'startLocationStopDurationMinutes',
                    'endLocationStopDurationMinutes',
                    'warehouseName',
                    'teamRegionName'
                ]
            }
        };
    },

    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam',
            user: 'user/user'
        })
    },

    async mounted() {
        if (sessionStorage.getItem('viewType') != null) {
            this.mappingData.viewType = sessionStorage.getItem('viewType');
            this.mappingData.isDesignerView = this.mappingData.viewType === 'designer';
            this.mappingData.isJSONEditorView = !this.mappingData.isDesignerView;
        }

        this.$_handleLoaderState(true);

        await this.loadTemplateList(this.mappingData.importMappingType);
        await this.loadMapping(this.mappingData.importMappingType, this.mappingData.selectedTemplateName);
    },

    beforeRouteLeave(to, from, next) {
        this.beforeTabChanged(to, from, next);
    },

    methods: {
        checkImportConfiguration(isDefaultTemplate) {
            if (this.mappingData.selectedTemplateName === 'Default') {
                // For the default template, default (sample) column names are returned from the server, but it will also label it as such if this is the case
                this.mappingData.isDefaultMappings = isDefaultTemplate;
            } else {
                // For all other templates, we just need to know if they have any columns defined yet
                this.mappingData.isDefaultMappings =
                    this.mappingData.importMappingsColumns && this.mappingData.importMappingsColumns.length > 0;
            }
        },

        openAddTemplateNameModal() {
            this.$modal
                .show(TemplateNameModal, {
                    mappingData: this.mappingData
                })
                .then((name) => {
                    this.setMappingsColumns({});
                    this.populateDesignerFieldList({}, this.mappingData.importMappingType);
                    this.mappingData.selectedTemplateName = name;
                    this.mappingData.templateNameList.push(name);
                    this.mappingData.isDefaultMappings = true;
                });
        },

        async handleAddNewTemplate() {
            const oldTemplateName = this.mappingData.selectedTemplateName;
            this.checkForUnsavedChanges(oldTemplateName).then(this.openAddTemplateNameModal);
        },

        async handleConfirmDeleteTemplate(templateName) {
            this.$_handleLoaderState(true, 'DELETING...');

            const api = `/api/teams/delete-import-mappings/${this.mappingData.importMappingType}`;

            await handleRequests(api, {
                method: 'post',
                params: {
                    templateName
                }
            });

            const templateNameIndex = this.mappingData.templateNameList.findIndex((x) => x === templateName);
            this.mappingData.templateNameList.splice(templateNameIndex, 1);

            this.$_handleLoaderState(false);
            this.$notify({
                message: `Import mapping was deleted`,
                type: 'success'
            });

            if (templateName === this.mappingData.selectedTemplateName) {
                this.mappingData.selectedTemplateName = 'Default';
                await this.loadMapping(this.mappingData.importMappingType, this.mappingData.selectedTemplateName);
            }
        },

        handleDeleteTemplate(templateName) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Template',
                    body: `Are you sure you want to delete the ${templateName} import template?`,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        this.handleConfirmDeleteTemplate(templateName);
                });
        },

        async handleTemplateChanged(templateName) {
            const oldTemplateName = this.mappingData.selectedTemplateName;

            return this.checkForUnsavedChanges(oldTemplateName).then(async () => {
                await this.loadMapping(this.mappingData.importMappingType, templateName);
            });
        },

        async handleTabChanged(tab) {
            const tabName = tab.replaceAll(' ', '');
            this.mappingData.selectedTemplateName = 'Default';
            this.mappingData.importMappingType = tabName;

            this.$_handleLoaderState(true);

            await this.loadTemplateList(this.mappingData.importMappingType);
            await this.loadMapping(this.mappingData.importMappingType, this.mappingData.selectedTemplateName);
        },

        beforeTabChanged(tab, from, next) {
            try {
                this.checkForUnsavedChanges().then(async () => {
                    if (next) {
                        next();
                    } else {
                        this.handleTabChanged(tab);
                    }
                });
            } catch (ex) {
                this.$notify({
                    message: ex.message,
                    type: 'danger'
                });
            }
        },

        checkForUnsavedChanges(templateName) {
            return new Promise((resolve, reject) => {
                if (!this.mappingData.isJSONEditorView) {
                    this.mappingData.importMappingJson = this.getFieldMappingsFromDesignerAsJson();
                }

                if (!_.isEqual(JSON.parse(this.oldImportMappingJson), JSON.parse(this.mappingData.importMappingJson))) {
                    this.$messageBox
                        .show({
                            class: 'sm-modal-container',
                            title: 'Save Mappings',
                            body: `You have unsaved changes. Would you like to save the field mappings for the ${templateName} import template?`,
                            buttons: ['Yes', 'No']
                        })
                        .then(async (response) => {
                            if (response.toLowerCase() === 'yes') {
                                await this.saveMapping(templateName);
                            }

                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },

        async loadTemplateList(importMappingType) {
            const api = `/api/teams/import-template-names/${importMappingType}`;

            try {
                const response = await handleRequests(api);

                if (response.data.length > 0) {
                    // Make sure Default sticks to the top when sorting
                    this.mappingData.templateNameList = ['Default'].concat(
                        response.data
                            .filter((value) => value !== 'Default')
                            .sort((value1, value2) => value1.localeCompare(value2))
                    );
                }
            } catch (error) {
                // TODO
                // eslint-disable-next-line no-console
                console.log(error);
            }
        },

        async loadMapping(importMappingType, selectedTemplateName) {
            this.$_handleLoaderState(true);

            this.mappingData.importMappingFields = []; // For some reason, the mapping dropdowns don't display properly without this piece of code

            const api = `/api/teams/import-mappings/${importMappingType}`;

            try {
                const payload = {
                    params: {
                        templateName: selectedTemplateName
                    }
                };

                const response = await handleRequests(api, payload);

                // We have existing data that is pascal-cased in the database. Convert it to camel-case so that this tool will work.
                const data = JSON.parse(JSON.stringify(response.data), this.toCamelCase);

                const { mappings, columns, isDefaultTemplate } = data;
                this.setMappingsColumns(columns);
                this.populateDesignerFieldList(mappings, importMappingType);

                // eslint-disable-next-line no-multi-assign
                this.oldImportMappingJson = this.mappingData.importMappingJson = this.getFieldMappingsFromDesignerAsJson();
                this.checkImportConfiguration(isDefaultTemplate);
            } catch (error) {
                this.mappingData.importMappingFields = [];
                this.mappingData.importMappingJson = null;
                this.oldImportMappingJson = null;
            }

            this.$_handleLoaderState(false);
        },

        setMappingsColumns(columns) {
            const column = {};
            column.notMapped = 'Not Mapped';
            column.customMapping = 'Custom Mapping';

            function getColumnDisplayName(name, columns) {
                // Header may be stored in format group|title. If so, display this in a nicer format.
                let header = columns[name] || '';
                const headerParts = header.split('|');

                if (headerParts.length > 1) 
                    header = `${headerParts[1]} (${headerParts[0]})`;

                return `${name} - ${header}`;
            }

            this.mappingData.importMappingsColumns = Object.assign(column, columns);
            this.mappingData.worksheetColumnFields = Object.keys(this.mappingData.importMappingsColumns).map(
                (name) => ({
                    name,
                    value:
                        name === 'notMapped' || name === 'customMapping'
                            ? this.mappingData.importMappingsColumns[name]
                            : getColumnDisplayName(name, columns)
                })
            );
        },

        populateDesignerFieldList(mappings, importMappingType) {
            // Get the full list of fields that can be mapped, and then look for corresponding mappings for each. Where there
            // is a mapped field, create an entry with the mapping details to be displayed in the designer.
            // Finally, sort the list by the display name
            const fieldList = this.fields[importMappingType];

            this.mappingData.importMappingFields = fieldList.map((keyName) => {
                return this.getDesignerFieldMapping(keyName, mappings, importMappingType);
            }); // .sort((x, y) => (x.displayName > y.displayName ? 1 : -1)); <- Problem with nested structures blending - to fix

            // Add custom fields
            const customFieldType = importMappingType.substring(0, importMappingType.length - 1);
            const customFields = this.user[`${customFieldType}CustomFieldDefinitions`];

            if (customFields && customFields.length) {
                this.mappingData.importMappingFields.push({
                    isSectionHeader: true,
                    displayName: 'Custom Fields',
                    name: 'customFields'
                });

                customFields
                    .sort((x, y) => (x.label > y.label ? 1 : -1))
                    .forEach((field) => {
                        let value = null;

                        if (mappings?.customFields) 
                            value = mappings?.customFields[field.name];

                        this.mappingData.importMappingFields.push({
                            name: `customFields.${field.name}`,
                            displayName: field.label,
                            value,
                            worksheetFields: this.mappingData.worksheetColumnFields
                        });
                    });
            }

            // Add load types
            if (importMappingType === 'stops' || importMappingType === 'shipments') {
                const loadFields = this.user.vehicleCapacityUnitsConfiguration;

                if (loadFields && loadFields.length) {
                    this.mappingData.importMappingFields.push({
                        isSectionHeader: true,
                        displayName: 'Load',
                        name: 'load'
                    });

                    loadFields
                        .sort((x, y) => (x.label > y.label ? 1 : -1))
                        .forEach((field) => {
                            let value = null;

                            if (mappings?.load) 
                                value = mappings?.load[field.type];

                            this.mappingData.importMappingFields.push({
                                name: `load.${field.type}`,
                                displayName: field.label,
                                value,
                                worksheetFields: this.mappingData.worksheetColumnFields
                            });
                        });
                }
            }
        },

        getDesignerFieldMapping(key, mappings, importMappingType) {
            if (!key.startsWith('_')) {
                return {
                    name: key,
                    displayName: this.$t(`imports.${importMappingType}.${key}`),
                    value: key.split('.').reduce((parent, partName) => (parent ? parent[partName] : null), mappings), // Gets nested value from parent mappings object
                    worksheetFields: this.mappingData.worksheetColumnFields
                };
            }

            // This is a section header
            return {
                name: key,
                displayName: this.$t(`imports.${importMappingType}.${key}`),
                isSectionHeader: true
            };
        },

        getFieldMappingsFromDesignerAsJson() {
            const mappingObject = this.getFieldMappingsFromDesigner();
            return JSON.stringify(mappingObject, undefined, 4);
        },

        getFieldMappingsFromDesigner() {
            const mappingObject = {};

            this.mappingData.importMappingFields.forEach((field) => {
                if (field.value) {
                    const fieldNameParts = field.name.split('.');
                    const lastPart = fieldNameParts.pop();
                    // eslint-disable-next-line no-return-assign
                    fieldNameParts.reduce((parent, fieldName) => (parent[fieldName] ??= {}), mappingObject)[lastPart] =
                        field.value; // Set the value with support for nested properties
                }
            });

            return mappingObject;
        },

        toCamelCase(key, value) {
            if (value && typeof value === 'object' && !/^columns$/i.test(key)) {
                // Ignore the columns property, since these are expected as uppercase property names
                Object.keys(value).forEach((k) => {
                    if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
                        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
                        delete value[k];
                    }
                });
            }
            return value;
        },

        async saveMapping(value) {
            const templateName = value || this.mappingData.selectedTemplateName;

            try {
                if (!this.mappingData.isDesignerView) {
                    const mappings = JSON.parse(this.mappingData.importMappingJson);
                    this.populateDesignerFieldList(mappings, this.mappingData.importMappingType);
                }

                // These two "columns"" should not be included in the column list in the database
                delete this.mappingData.importMappingsColumns.notMapped;
                delete this.mappingData.importMappingsColumns.customMapping;

                this.$_handleLoaderState(true, 'SAVING...');

                const api = `/api/teams/import-mappings/${this.mappingData.importMappingType}`;
                const saveMappingData = {
                    name: templateName,
                    mappings: this.getFieldMappingsFromDesigner(),
                    columns: this.mappingData.importMappingsColumns
                };

                const payload = {
                    method: 'post',
                    data: saveMappingData
                };

                await handleRequests(api, payload);
                this.$notify({
                    message: `Import mapping "${this.mappingData.importMappingType}" successfully updated.`,
                    type: 'success'
                });

                // Update so we don't get an unsaved changes message
                // eslint-disable-next-line no-multi-assign
                this.oldImportMappingJson = this.mappingData.importMappingJson = this.getFieldMappingsFromDesignerAsJson();

                this.$_handleLoaderState(false);
            } catch (ex) {
                this.$_handleLoaderState(false);
                this.$notify({
                    message: ex.message,
                    type: 'danger'
                });
            }
        },

        updateViewType() {
            if (this.mappingData.isJSONEditorView) {
                this.mappingData.importMappingJson = this.getFieldMappingsFromDesignerAsJson();
            } else {
                const mappings = JSON.parse(this.mappingData.importMappingJson);
                this.populateDesignerFieldList(mappings, this.mappingData.importMappingType);
            }

            this.mappingData.viewType = this.mappingData.isJSONEditorView ? 'editor' : 'designer';
            sessionStorage.setItem('viewType', this.mappingData.viewType);
            this.mappingData.isDesignerView = this.mappingData.viewType === 'designer';
        },

        async onUpload(selectedFile) {
            this.$_handleLoaderState(true, 'IMPORTING...');
            const fd = new FormData();
            fd.append('file', selectedFile);
            this.mappingData.importMappingFields = [];
            const api = `/api/teams/import-mappings/parse-workbook/${this.mappingData.importMappingType}`;
            const payload = {
                method: 'post',
                data: fd
            };

            try {
                const response = await handleRequests(api, payload);

                if (response.status === 200) {
                    const { data } = response;
                    this.setMappingsColumns(data);
                    this.populateDesignerFieldList({}, this.mappingData.importMappingType);
                    this.mappingData.importMappingJson = this.getFieldMappingsFromDesignerAsJson();
                    this.mappingData.isDefaultMappings = false; // Hide the alert
                }

                this.$_handleLoaderState(false);
            } catch (error) {
                this.$_handleLoaderState(false);
                this.$notify({
                    message: `Error in getting "${this.mappingData.importMappingType}" mapping fields.`,
                    type: 'danger'
                });
            }
        },

        handleReset() {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: `Reset Import Mappings`,
                    body: `Are you sure you want to reset the import mappings for the default ${this.mappingData.importMappingType.toLowerCase()} template?`,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmReset();
                });
        },

        async handleConfirmReset() {
            try {
                this.$_handleLoaderState(true);
                const api = `/api/teams/delete-import-mappings/${this.mappingData.importMappingType}`;

                await handleRequests(api, {
                    method: 'post',
                    params: {
                        templateName: 'Default'
                    }
                });

                this.$notify({
                    message: `Import mapping "${this.mappingData.importMappingType}" successfully reset.`,
                    type: 'success'
                });

                this.mappingData.selectedTemplateName = 'Default';
                await this.loadMapping(this.mappingData.importMappingType, this.mappingData.selectedTemplateName);

                this.$_handleLoaderState(false);
            } catch (ex) {
                this.$_handleLoaderState(false);
                this.$notify({
                    message: ex.message,
                    type: 'danger'
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.title {
    display: inline-block;
}

::v-deep .custom-tab-list {
    .nav-tabs {
        width: calc(100% - 5px);
        flex-wrap: wrap;
        li {
            border-bottom: 0px;
        }
    }
}
</style>
