<template>
    <div class="inline-button">
        <md-button
            v-if="!isReadOnlyUser"
            title="Delete this maintenance schedule"
            :class="useIcon ? 'md-danger md-just-icon md-round' : `${className}`"
            @click.stop="handleDeleteSchedule"
        >
            <md-icon v-if="useIcon">delete</md-icon>
            <span v-else>Dlete</span>
        </md-button>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';

export default {
    name: 'DeleteMaintenanceScheduleButton',
    props: {
        className: {
            type: String,
            default: () => ''
        },
        useIcon: {
            type: Boolean,
            default: () => true
        },
        id: {
            type: [Number, String],
            default: null
        }
    },
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    methods: {
        handleDeleteSchedule() {
            const message = 'Are you sure you want to delete this maintenance schedule?';

            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete maintenance schedule',
                    body: message,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmDelete(this.id);
                });
        },
        async handleConfirmDelete(id) {
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'delete'
            };
            const api = `/api/maintenance-schedules/${id}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Maintenance schedule was deleted!',
                    type: 'success'
                });
                this.$_handleLoaderState(false);
                this.$emit('deleted', true);
            } catch (e) {
                this.$_handleLoaderState(false);
                const message = 'Error in deleting maintenance schedule.';
                showErrorMessage(this, message, e);
                this.$emit('deleted', false);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.inline-button {
    display: inline-block;
}
</style>
