<template>
    <md-field class="member-role-filter-container">
        <vue-select
            v-if="memberRoles"
            v-model="selectedOption"
            :options="memberRoles"
            :clearable="false"
            placeholder="Team Role"
            label="name"
            track-by="id"
        ></vue-select>
    </md-field>
</template>
<script>
export default {
    name: 'MemberRoleFilterComponent',
    props: {
        selectedMemberRole: {
            type: Object,
            default: () => {}
        },
        memberRoles: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            selectedOption: null
        };
    },
    watch: {
        selectedOption(newValue, oldValue) {
            this.handleRoleChange(newValue, oldValue, () => {
                this.$emit('changed', newValue);
            });
        },
        selectedMemberRole(newValue, oldValue) {
            this.handleRoleChange(newValue, oldValue, () => {
                this.selectedOption = newValue;
            });
        }
    },
    methods: {
        handleRoleChange(newValue, oldValue, onRoleChange) {
            const newSelectedRole = newValue ? newValue.id : null;
            const oldSelectedRole = oldValue ? oldValue.id : null;
            if (newSelectedRole !== oldSelectedRole || newValue !== oldValue) {
                onRoleChange();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.member-role-filter-container {
    margin-top: 0;
    margin-bottom: 0;
    width: 205px !important;
}
</style>
