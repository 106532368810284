var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: [
        { "timeline-inverted": _vm.inverted },
        { "timeline-simple": !_vm.inverted },
        { clickable: _vm.isClickable },
      ],
      on: {
        click: function () {
          return _vm.$emit("clicked")
        },
      },
    },
    [
      _vm._t("badge", [
        _c(
          "div",
          {
            staticClass: "timeline-badge",
            class: [_vm.badgeType, _vm.isImage ? "timeline-image-badge" : ""],
          },
          [
            !_vm.isImage
              ? _c("md-icon", [_vm._v(_vm._s(_vm.badgeIcon))])
              : _vm._e(),
            !_vm.imageSrc && _vm.isImage
              ? _c("img", {
                  staticClass: "timeline-image",
                  attrs: { src: _vm.$root.defaultPhotoUrl, alt: "" },
                })
              : _c("img", {
                  staticClass: "timeline-image",
                  attrs: { src: _vm.imageSrc, alt: "" },
                  on: { error: _vm.$_setDefaultBrokenImage },
                }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "timeline-panel" }, [
        _c(
          "div",
          { staticClass: "timeline-heading" },
          [_vm._t("header"), _vm._t("headerTime")],
          2
        ),
        _vm.$slots.content
          ? _c("div", { staticClass: "timeline-body" }, [_vm._t("content")], 2)
          : _vm._e(),
        _vm.$slots.footer ? _c("h6", [_vm._t("footer")], 2) : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }