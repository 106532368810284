<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 clas="modal-title">Edit {{ assetGroupData.groupName }}</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.assetGroup" class="form-wrapper">
                <div class="md-layout-item md-size-100">
                    <form-group label="Asset Group Name*" name="groupName">
                        <md-input v-model="assetGroup.groupName" />
                    </form-group>
                </div>
                <div class="md-layout-item md-size-100">
                    <form-group v-if="showTeamRegion" name="teamRegion" label="Team Region">
                        <team-region-options
                            :selected="assetGroup.teamRegionId"
                            @selectedOption="handleTeamRegionChanged"
                            mode="update"
                            :show-auto="false"
                        />
                    </form-group>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="update()">
                Update
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import { TeamRegionOptions } from '@/components';
import { required } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showTeamRegionControl } from '@/helpers';
import { mapGetters } from 'vuex';

export default {
    name: 'UpdateAssetGroup',
    mixins: [GeneralMixin],
    props: {
        assetGroupData: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    components: {
        TeamRegionOptions
    },
    mounted() {
        this.assetGroup.groupName = this.assetGroupData.groupName;
        this.assetGroup.teamRegionId = this.assetGroupData.teamRegionId;
    },
    data() {
        return {
            assetGroup: {
                groupName: null,
                teamRegionId: null
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasAssetAccess: 'user/hasAssetAccess',
            teamRegions: 'team/teamRegions',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        }),
        showTeamRegion() {
            return showTeamRegionControl(
                {
                    user: this.user,
                    hasAssetAccess: this.hasAssetAccess,
                    teamRegions: this.teamRegions
                },
                this.assetGroup.teamRegionId
            );
        }
    },
    validations: {
        assetGroup: {
            groupName: {
                required
            }
        }
    },
    methods: {
        async update() {
            this.$v.assetGroup.$touch();
            const isValid = !this.$v.assetGroup.$invalid;

            if (isValid) {
                this.$_handleLoaderState(true, 'SAVING...');
                const api = `/api/asset-groups/${this.assetGroupData.assetGroupId}`;

                const data = Object.assign({}, this.assetGroupData, this.assetGroup);

                // const api = '/api/assets';
                const payload = {
                    method: 'put',
                    data
                };

                try {
                    await handleRequests(api, payload);

                    this.$notify({
                        message: 'Successfully updated the asset group.',
                        type: 'success'
                    });

                    this.resolve(data);

                    // TODO: We need to add a way to clear the customfields after successfully creating the asset.
                    this.$v.$reset();
                } catch (error) {
                    const err = error.data;
                    if (err && err.length) {
                        err.forEach((e) => {
                            this.$notify({
                                message: e.message,
                                type: 'danger'
                            });
                        });
                    }
                }
            }

            this.$_handleLoaderState(false);
        },
        handleTeamRegionChanged(value) {
            this.assetGroup.teamRegionId = value;
        }
    }
};
</script>
