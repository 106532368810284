<template>
    <div class="asset-management-page">
        <div class="md-layout" v-if="isValidType">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100" v-if="hasTeam">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <asset-tracking-filter
                            @onFilterChanged="handleFilterOrSearch"
                            :team-region-id="filters.teamRegionId"
                        />
                    </div>
                    <div class="custom-toolbar-end">
                        <search-component ref="search" @onSearch="handleSearch" />
                        <div class="header-button-container">
                            <md-button
                                title="Create Asset"
                                class="md-primary md-just-icon md-round"
                                @click="createAsset"
                            >
                                <md-icon>add</md-icon>
                            </md-button>
                            <md-button
                                title="Add asset log"
                                class="md-primary md-just-icon md-round"
                                @click="createAssetlog"
                            >
                                <md-icon>note_add</md-icon>
                            </md-button>
                            <!-- disable creating asset group for the meantime, until we create a new way of creating of asset groups that includes custom asset types -->
                            <!-- <md-button
                                title="Create Asset Group"
                                class="md-primary md-just-icon md-round"
                                @click="createAssetGroup"
                            >
                                <md-icon>medical_services</md-icon>
                            </md-button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card class="custom-card">
                    <md-card-header class="md-card-header-icon md-card-header-warning">
                        <div class="card-icon">
                            <md-icon>work</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content>
                        <div v-if="!hasTeam && !isLoading">
                            <h3>Asset Group</h3>
                            <p>
                                You are currently set up as an individual user of Locate2u. Asset Management is only
                                available if you are part of a team.
                            </p>
                            <p>
                                If you want to create your own team, click here to set one up. This will allow you to
                                invite people to your team and manage them via this portal.
                            </p>

                            <router-link to="/team/profile" class="custom-a-blue">Create Team</router-link>
                        </div>
                        <div v-if="hasTeam">
                            <tabs
                                :tab-name="tabNames"
                                color-button="success"
                                class="custom-tab-list"
                                @switch-panel="switchTab"
                                :selected-panel="replaceDashToSpacesAndCapitalizeFirstLetter(activeTab)"
                            >
                                <template v-for="(tabName, index) in tabNames" :slot="`tab-pane-${index + 1}`">
                                    <div :key="index">
                                        <asset-group-list-table
                                            @removeAssetFromGroup="removeAssetFromGroup"
                                            :new-asset-group-id="newAssetGroupId"
                                            v-if="index == 0"
                                            :search-text="searchText"
                                            :filters="filters"
                                        />

                                        <asset-list-table
                                            v-else
                                            :search-text="searchText"
                                            :type="activeTab"
                                            :new-asset-id="newAssetId"
                                            :custom-fields="assetCustomFieldList"
                                            :filters="filters"
                                        />
                                    </div>
                                </template>
                            </tabs>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div v-else>
            <p class="text-center">Type Not Valid</p>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { Tabs, SearchComponent } from '@/components';
import { handleRequests } from '@/helpers';
import { mapGetters, mapActions } from 'vuex';
import CreateAssetContent from '@/pages/AssetTracking/ModalComponents/CreateAssetContent';
import CreateAssetGroupContent from '@/pages/AssetTracking/ModalComponents/CreateAssetGroupContent';
import CreateAssetLogModal from '@/pages/AssetTracking/ModalComponents/CreateAssetLogModal';
import { ASSET_TYPE_CONSTANTS } from '@/utils/constants';
import AssetGroupListTable from './AssetGroupListTable';
import AssetListTable from './AssetListTable';
import AssetTrackingFilter from './components/AssetTrackingFilter';

export default {
    name: 'AssetDashboard',
    components: {
        Tabs,
        AssetGroupListTable,
        AssetListTable,
        SearchComponent,
        AssetTrackingFilter
    },
    mixins: [GeneralMixin],
    data() {
        return {
            showModal: false,
            assetCustomFieldList: [],
            personnelList: [],
            vehicleList: [],
            trackerList: [],
            personnelDropdownList: [],
            vehicleDropdownList: [],
            trackerDropdownList: [],
            assetList: [],
            tabNames: ['Asset Groups', ...ASSET_TYPE_CONSTANTS],
            isAssetLoading: false,
            searchText: null,
            newAssetGroupId: null,
            newAssetId: null,
            activeTab: this.$route.params.type || 'asset-groups',
            isValidType: true,
            filters: {
                status: 'active',
                teamRegionId: undefined,
                memberPublicUserId: undefined,
                searchText: undefined
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasTeam: 'user/hasTeam',
            isLoading: 'isLoading'
        })
    },
    async mounted() {
        // this.filters.teamRegionId = this.user.teamRegionId ? this.user.teamRegionId : null; // null = 'All'
        this.filters.teamRegionId = null; // null = 'All'
        this.FETCH_TEAM_MEMBERS();

        if (!this.tabNames.includes(this.replaceDashToSpacesAndCapitalizeFirstLetter(this.activeTab))) {
            this.isValidType = false;
            return;
        }

        const api = '/api/teams/custom-fields/assets';
        const response = await handleRequests(api);
        if (response.data !== '') {
            this.assetCustomFieldList = response.data;
        }

        const currentPage = Number(this.$route.query.currentPage);
        if (currentPage) {
            this.$router.replace({ path: `/assets/overview/${this.activeTab}`, query: { currentPage } });
        } else {
            this.$router.replace({ path: `/assets/overview/${this.activeTab}`, query: {} });
        }
    },
    methods: {
        ...mapActions('team', ['FETCH_TEAM_MEMBERS']),
        replaceDashToSpacesAndCapitalizeFirstLetter(str) {
            return str
                .toLowerCase()
                .split('-')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        },
        async switchTab(panel) {
            const tab = panel
                .toLowerCase()
                .split(' ')
                .join('-');
            this.activeTab = tab;
            this.$router.replace({ path: `/assets/overview/${tab}`, query: {} });
            this.$refs.search.handleClear();
            this.searchText = null;
            this.currentTab = tab;
            // since creating of asset group is disabled we dont need to call this yet as
            // this is only used on the dropdowns when creating an asset group.
            // await this.getAssetByType(panel);
        },
        async getAssetByType(type) {
            this.isAssetLoading = true;
            switch (type.toLowerCase()) {
                case 'personnel':
                    this.assetList = await this.getPersonnelList();
                    break;
                case 'vehicle':
                    this.assetList = await this.getVehicleList();
                    break;
                case 'tracker':
                    this.assetList = await this.getTrackerList();
                    break;
                default:
                    this.assetList = await this.getAssets(type);
                    break;
            }
            this.isAssetLoading = false;
        },
        async getTrackerList() {
            // to lessen the load on the server the endpoint will only be called if list is empty.
            if (!this.trackerList.length) {
                const trackerApi = `/api/assets/tracker`;
                const trackers = await handleRequests(trackerApi);
                this.trackerList = trackers.data.data;
                this.trackerDropdownList = this.getUnlinkedAssets(this.trackerList);
            }

            return this.trackerList;
        },
        async getVehicleList() {
            // to lessen the load on the server the endpoint will only be called if list is empty.
            if (!this.vehicleList.length) {
                const type = 'vehicle';
                // since we only load this list once to display in the dropdown modal of when adding a log
                // we only need to display vehicles that are team region specific to users.
                const api = `/api/assets/?type=${type}&teamRegionId=${this.user.teamRegionId}`;
                const { data } = await handleRequests(api);

                this.vehicleList = data.data;
                this.vehicleDropdownList = this.getUnlinkedAssets(this.vehicleList);
            }

            return this.vehicleList;
        },
        async getPersonnelList() {
            // to lessen the load on the server the endpoint will only be called if list is empty.
            if (!this.personnelList.length) {
                this.personnelList = await this.getAssets('personnel');
                this.personnelDropdownList = this.getUnlinkedAssets(this.personnelList);
            }

            return this.personnelList;
        },
        async getAssets(type) {
            const api = `/api/assets/?type=${type}&teamRegionId=${this.filters.teamRegionId}`;
            const { data } = await handleRequests(api);

            return data.data;
        },
        getUnlinkedAssets(list) {
            return list.filter((asset) => !asset.isLinked);
        },
        showModalContent(type) {
            this.showModal = true;
            if (type === 'asset') {
                this.createAsset = true;
            } else {
                this.createAssetGroup = true;
            }
        },
        createAsset() {
            const { personnelDropdownList, vehicleDropdownList, trackerDropdownList, assetCustomFieldList } = this;
            this.$modal
                .show(CreateAssetContent, {
                    personnelDropdownList,
                    vehicleDropdownList,
                    trackerDropdownList,
                    assetCustomFieldList,
                    activeTab: this.activeTab
                })
                .then(({ type, assetId }) => {
                    this.newAssetId = assetId;
                    this.getAssetByType(type);
                });
        },
        async createAssetlog() {
            // TODO: this causes the modal to open really slow especially if there are a lot of vehicle assets
            // We need to implement a dropdown list that would only load the first 25 and upon reaching the
            // end of the list should load more vehicles <-- probably can implement or improved for phase 2
            if (this.vehicleList.length === 0) {
                this.$_handleLoaderState(true);
                await this.getVehicleList();
                this.$_handleLoaderState(false);
            }

            this.$modal
                .show(CreateAssetLogModal, {
                    vehicleDropdownList: this.vehicleList,
                    activeTab: this.activeTab
                })
                .then(({ isSuccess }) => {
                    // do nothing
                    this.$modal.hide();
                });
        },
        async createAssetGroup() {
            // since create asset group requires unlinked assets of vehicle, personnel and tracker
            // these functions below will be called to make sure that if there are existing unlinked assets it will be included when the modal is rendered.
            this.$_handleLoaderState(true);
            await this.getVehicleList();
            await this.getTrackerList();
            await this.getPersonnelList();
            this.$_handleLoaderState(false);
            const { personnelDropdownList, vehicleDropdownList, trackerDropdownList } = this;
            this.$modal
                .show(CreateAssetGroupContent, {
                    personnelDropdownList,
                    vehicleDropdownList,
                    trackerDropdownList
                })
                .then((response) => {
                    this.addCreateGroupResponse(response);
                });
        },
        classicModalHide() {
            this.showModal = false;
            this.createAsset = false;
            this.createAssetGroup = false;
        },
        addCreateGroupResponse(res) {
            const { assets, tracker } = res.data;
            this.newAssetGroupId = Number(res.data.assetGroup.assetGroupId);
            this.trackerList.push(tracker);
            assets.forEach((asset) => {
                switch (asset.type) {
                    case 'Personnel':
                        this.personnelList.push(asset);
                        break;
                    case 'Vehicle':
                        this.vehicleList.push(asset);
                        break;
                    default:
                    // no return
                }
            });
        },
        removeAssetFromGroup(assetGroupId) {
            this.personnelList = this.removeDetails(this.personnelList, assetGroupId);
            this.personnelDropdownList = this.getUnlinkedAssets(this.personnelList);

            this.vehicleList = this.removeDetails(this.vehicleList, assetGroupId);
            this.vehicleDropdownList = this.getUnlinkedAssets(this.vehicleList);

            this.trackerList = this.removeDetails(this.trackerList, assetGroupId);
            this.trackerDropdownList = this.getUnlinkedAssets(this.trackerList);
        },
        removeDetails(list, assetGroupId) {
            // it is possible that the list passed has no elements, since the tracker, personnel and vehicle will only be loaded when their tab is clicked.
            if (list.length > 0) {
                list.find((res) => res.assetGroupId === assetGroupId).assetGroupName = null;
                list.find((res) => res.assetGroupId === assetGroupId).isLinked = false;
                list.find((res) => res.assetGroupId === assetGroupId).assetGroupId = null;
            }

            return list;
        },
        handleSearch(text) {
            this.filters = { ...this.filters, searchText: text.searchText };
        },
        async handleFilterOrSearch(val) {
            this.filters = { ...this.filters, ...val };
        }
    }
};
</script>
<style lang="scss" scoped>
.main-btn ::v-deep .custom-btn {
    margin-right: 10px;
}

.asset-management-page ::v-deep {
    .title {
        display: inline-block;
    }
    .md-datepicker-overlay {
        z-index: 9999;
    }
    .md-datepicker-dialog {
        z-index: 9999;
    }
}
.custom-toolbar-end {
    ::v-deep .search-button--container .md-button {
        z-index: initial !important;
    }
}

// since the sidebar for asset group and asset is the child of the tab and not the parent container
// we need to remove the position relativity of the tab so that the sidebar doesn't limit
// itself to the tab rather it will depend its stacking based on the parent container

.custom-card {
    z-index: initial;
}

.custom-tab-list {
    position: initial;
}

//End of comment
</style>
