<template>
    <div class="drag-drop-container">
        <div class="bg-gray">
            <input
                type="file"
                multiple
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                class="dragdrop-input"
                @change="onChange"
                ref="file"
                accept=".pdf,.xlsx,.xls,.txt,.ppt,.pptx,.docx,.doc,.mp4"
            />

            <label for="assetsFieldHandle" class="block cursor-pointer">
                <div>
                    <span class="underline">Click here</span>
                    to upload files
                </div>
            </label>
        </div>
        <div class="file-names">
            <ul class="filename-list" v-if="this.filelist.length" v-cloak>
                <li class="text-sm" v-for="(file, index) in filelist" :key="index">
                    {{ file.name }}
                    <button
                        class="file-remove-btn"
                        type="button"
                        @click="remove(filelist.indexOf(file))"
                        title="Remove file"
                    >
                        remove
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'DocumentFileUpload',
    mixins: [GeneralMixin],
    data() {
        return {
            filelist: []
        };
    },
    props: {
        documents: {
            type: Array,
            default: () => []
        }
    },
    mounted() {
        if (this.documents.length > 0) {
            this.filelist = this.documents;
        }
    },
    methods: {
        onChange() {
            this.checkFileSize([...this.$refs.file.files]);
            this.$emit('upload-success', this.filelist);
        },
        remove(i) {
            this.filelist.splice(i, 1);
            this.$refs.file.value = '';
            this.$emit('upload-remove', i, this.filelist);
        },
        checkFileSize(files) {
            files.forEach((file) => {
                if (file.size / 1024 > 15000) {
                    this.$notify({
                        message: `${file.name} exceeded the maximum size.`,
                        type: 'danger'
                    });
                } else {
                    const name = this.handleDuplicateFileNames(file);
                    const blob = file.slice(0, file.size, file.type);
                    this.filelist.push(new File([blob], name, { type: file.type }));
                }
            });
        },
        handleDuplicateFileNames(item) {
            const documentNames = [];
            this.filelist.forEach((element) => {
                documentNames.push(element.name.toLowerCase());
            });
            const file = item.name.split('.');
            const fileName = file[0];
            const fileExt = file[1];
            if (documentNames.includes(item.name.toLowerCase())) {
                let index = 1;
                let name = `${fileName} (${index}).${fileExt}`;
                while (documentNames.includes(name.toLowerCase())) {
                    index += 1;
                    name = `${fileName} (${index}).${fileExt}`;
                }
                return name;
            }
            return item.name;
        }
    }
};
</script>

<style lang="scss" scoped>
.drag-drop-container {
    margin-top: 10px;
    text-align: center;
    > .bg-gray {
        height: 100%;
        width: 100%;

        .underline {
            text-decoration: underline;
        }

        .dragdrop-input {
            width: 1px;
            height: 1px;
            position: absolute;
            overflow: hidden;
        }
    }

    .file-names {
        max-height: 190px;
        overflow: auto;
        .filename-list {
            list-style: none;
            text-align: left;
            padding-left: 5px;
        }
        .file-remove-btn {
            color: #fff;
            background-color: #aaa;
            border: none;
        }
    }

    label {
        > div {
            height: 100%;
        }
    }
}
</style>
