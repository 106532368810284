var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        _vm.shipment && _vm.shipment.selectedCarrierQuote
          ? "quote-dropdown-container"
          : "",
    },
    [
      !_vm.isBestQuote &&
      _vm.shipment &&
      _vm.shipment.selectedCarrierQuote &&
      _vm.shipment.quotingStatus != "JobAssigned"
        ? _c(
            "span",
            {
              staticClass: "close-button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleRemove($event)
                },
              },
            },
            [
              _c("md-icon", [_vm._v("cancel")]),
              _c("md-tooltip", { attrs: { "md-direction": "bottom" } }, [
                _vm._v(
                  "Clicking this button will revert back to the best quote"
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "drop-down",
        {
          attrs: { "should-close": _vm.shouldCloseDropdown },
          on: {
            closeDropdown: function (val) {
              return (this$1.shouldCloseDropdown = val)
            },
            closeOtherDropDown: _vm.closeOtherDropDown,
          },
        },
        [
          _vm.shipment && _vm.shipment.selectedCarrierQuote
            ? _c(
                "div",
                {
                  staticClass: "status carrier-text",
                  attrs: { slot: "title", "data-toggle": "dropdown" },
                  slot: "title",
                },
                [
                  _c(
                    "div",
                    {
                      class:
                        _vm.shipment.selectedCarrierQuote != null &&
                        _vm.shipment.selectedCarrierQuote.isExpired
                          ? "expired"
                          : "",
                      on: { click: _vm.handleClick },
                    },
                    [
                      _c("span", { staticClass: "profile-image" }, [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.shipment.selectedCarrierQuote.carrierTeam
                                .logoUrl !== null
                                ? _vm.shipment.selectedCarrierQuote.carrierTeam
                                    .logoUrl
                                : _vm.$root.defaultLogoUrl,
                            alt: "avatar",
                          },
                        }),
                      ]),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.selectedQuoteText) +
                          "\n                "
                      ),
                      _c(
                        "md-tooltip",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.shipment.selectedCarrierQuote.isExpired,
                              expression:
                                "shipment.selectedCarrierQuote.isExpired",
                            },
                          ],
                          attrs: { "md-direction": "left" },
                        },
                        [
                          _vm._v(
                            "\n                    This quote has already expired. To get a new quote please press the refresh button.\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _c(
                "span",
                {
                  staticClass: "unassigned",
                  class: _vm.totalQuotes.length > 0 ? "" : "no-quotes",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.totalQuotes.length > 0
                          ? _vm.totalQuotes.length + " quotes available"
                          : "No quotes available"
                      ) +
                      "\n        "
                  ),
                ]
              ),
          _vm.shipment.bestQuote != null
            ? _c(
                "ul",
                {
                  staticClass: "dropdown-menu",
                  class:
                    _vm.shipment.bestQuote != null
                      ? "carriers-dropdown-menu"
                      : "",
                },
                [
                  _vm.dropdownLoading
                    ? _c("li", { staticClass: "carrier-title loading-text" }, [
                        _vm._v("Loading..."),
                      ])
                    : _vm._e(),
                  _vm.totalQuotes.length > 0 && !_vm.dropdownLoading
                    ? _c("li", { staticClass: "carrier-title" }, [
                        _vm._v("Carriers"),
                      ])
                    : _vm._e(),
                  _vm.totalQuotes.length > 0 && !_vm.dropdownLoading
                    ? [
                        _vm._l(_vm.validQuotes, function (quote, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              class: quote.isExpired ? "expired-quote" : "",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleSelectQuote(quote)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "righthand-side" }, [
                                _c("div", { staticClass: "carrier-image" }, [
                                  quote.carrierTeam.logoUrl != null
                                    ? _c("img", {
                                        staticClass: "profile-image",
                                        attrs: {
                                          src: quote.carrierTeam.logoUrl,
                                          alt: "avatar",
                                        },
                                        on: {
                                          error: _vm.$_setDefaultBrokenImage,
                                        },
                                      })
                                    : _c("img", {
                                        staticClass: "profile-image",
                                        attrs: {
                                          src: _vm.$root.defaultLogoUrl,
                                          alt: "avatar",
                                        },
                                        on: {
                                          error: _vm.$_setDefaultBrokenImage,
                                        },
                                      }),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "carrier-delivery-details" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "carrier-name custom-ellipsis",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(quote.carrierTeam.company)
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "carrier-speed" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t(quote.serviceLevel))
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "carrier-pricing" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        quote.amountAfterTax,
                                        quote.currency
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]),
                            ]
                          )
                        }),
                        _vm.totalQuotes.length && _vm.totalQuotes.length > 3
                          ? _c(
                              "li",
                              {
                                staticClass: "see-more",
                                on: { click: _vm.showQuotesModal },
                              },
                              [
                                _vm._v(
                                  "\n                    See all " +
                                    _vm._s(_vm.totalQuotes.length) +
                                    " quotes...\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.totalQuotes.length && _vm.totalQuotes.length <= 3
                          ? _c(
                              "li",
                              {
                                staticClass: "see-more",
                                on: { click: _vm.showQuotesModal },
                              },
                              [
                                _vm._v(
                                  "\n                    See previous quotes...\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }