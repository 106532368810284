var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-green" },
        [
          _c(
            "div",
            { staticClass: "card-icon" },
            [_c("md-icon", [_vm._v("receipt_long")])],
            1
          ),
        ]
      ),
      _c("md-card-content", [
        !_vm.lines || _vm.lines.length === 0
          ? _c("p", { staticClass: "grayedout-text" }, [
              _vm._v("\n            No item provided.\n        "),
            ])
          : _c(
              "div",
              [
                _c(
                  "md-table",
                  {
                    staticClass: "context-menu-support custom-paginated-table",
                  },
                  [
                    _c(
                      "md-table-row",
                      [
                        _c("md-table-head", [_vm._v("Description")]),
                        _c("md-table-head", [_vm._v("Quantity")]),
                        _c("md-table-head", [_vm._v("Unit Cost (Ex-Tax)")]),
                        _c("md-table-head", [_vm._v("Total Amount (Ex-Tax)")]),
                        _c("md-table-head", [_vm._v("Stop Ref")]),
                        _c("md-table-head", [_vm._v("Trip Date")]),
                        _c("md-table-head", [_vm._v("Stop Status")]),
                      ],
                      1
                    ),
                    _vm._l(_vm.lines, function (line) {
                      return _c(
                        "md-table-row",
                        { key: line.customerInvoiceLineId },
                        [
                          _c("md-table-cell", [
                            _vm._v(_vm._s(line.description)),
                          ]),
                          _c("md-table-cell", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.displayLineValue(line)) +
                                "\n                        "
                            ),
                            _c("span", { staticClass: "quantity-type" }, [
                              _vm._v(_vm._s(line.quantityType)),
                            ]),
                          ]),
                          _c("md-table-cell", [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  line.unitPriceExTax,
                                  line.priceCurrency
                                )
                              )
                            ),
                          ]),
                          _c("md-table-cell", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("currency")(
                                    line.unitPriceExTax * line.quantity,
                                    line.priceCurrency
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _c(
                            "md-table-cell",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Stop Details",
                                      params: { stopId: line.stopId },
                                    },
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(line.stopRef) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("md-table-cell", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    line.tripDate,
                                    _vm.DATE_TYPES.standardDate
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _c("md-table-cell", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  line.stopStatus
                                    ? _vm.$t(
                                        "inputs.stopStatuses." + line.stopStatus
                                      )
                                    : line.stopStatus
                                ) +
                                "\n                    "
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }