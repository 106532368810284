<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Create Levy Rule</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>

        <form-wrapper class="form-wrapper" :validator="$v.rateDetail">
            <div class="md-layout-item md-size-100">
                <rule-valid-days
                    :current-days="rateDetail.validForWeekDays"
                    :from-date="rateDetail.validFromDate"
                    :to-date="rateDetail.validToDate"
                    @daysSelected="handleDaysSeleceted"
                    ref="ruleValidDays"
                />
                <form-group name="fuelLevyPercentage" label="Fuel Levy Percentage">
                    <md-input v-model.number="rateDetail.fuelLevyPercentage" />
                </form-group>
                <form-group name="futileCharge" label="Futile Charge">
                    <md-input v-model.number="rateDetail.futileCharge" />
                </form-group>
                <form-group v-if="driverRuleCreation" name="driverCommission" label="Commission Percentage">
                    <md-input v-model.number="rateDetail.driverCommission" />
                </form-group>
                <div class="md-switch-container">
                    <md-switch v-model="rateDetail.fuelLevyIncludesAllCharges">
                        Include all surcharges
                    </md-switch>
                </div>
            </div>
        </form-wrapper>

        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="create()">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin, RatesEngineMixin } from '@/mixins';
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';
import { RATE_CHARGING_TYPES } from '@/utils/constants';
import { decimal } from 'vuelidate/lib/validators';
import { RuleValidDays } from '@/components';

export default {
    name: 'CreateLevyRuleContent',
    components: {
        RuleValidDays
    },
    mixins: [GeneralMixin, RatesEngineMixin],
    props: {
        rateDetail: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        listLength: {
            type: Number,
            default: 0
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        customerId: {
            type: Number,
            default: null
        },
        rateGroupId: {
            type: Number,
            default: null
        },
        driverUserId: {
            type: Number,
            default: null
        },
        driverRuleCreation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            ruleData: {
                type: null,
                rule: 'test'
            },
            rateChargingTypes: RATE_CHARGING_TYPES,
            localMessages: {
                required: '{attribute} is required.'
            }
        };
    },
    validations: {
        rateDetail: {
            fuelLevyPercentage: {
                decimal
            },
            futileCharge: {
                decimal
            },
            driverCommission: {
                decimal
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    methods: {
        validateData() {
            if (this.$v.$invalid) {
                return false;
            }

            if (
                this.$v.rateDetail.fuelLevyPercentage.$model === null &&
                this.$v.rateDetail.futileCharge.$model === null &&
                this.$v.rateDetail.driverCommission.$model === null
            ) {
                return false;
            }

            return true;
        },
        async create() {
            const ruleDaysValid = this.$refs.ruleValidDays.validate();
            if (!ruleDaysValid) {
                return;
            }

            if (this.isUpdate) {
                this.updateRuleDetails();
                return;
            }

            if (!this.validateData()) {
                showErrorMessage(this, 'Rule must have valid data', null);
                return;
            }

            this.rateDetail.fuelLevyEnabled = this.$v.rateDetail.fuelLevyPercentage.$model !== null;

            this.$_handleLoaderState(true, 'PROCESSING...');

            const chargeType = this.rateChargingTypes.find(
                (x) => x.key.toLowerCase() === this.$route.params.type.toLowerCase()
            );

            const obj = this.$_getRateRuleData(
                chargeType,
                this.user.publicUserId,
                this.rateDetail,
                this.customerId,
                this.listLength,
                this.rateGroupId,
                this.driverRuleCreation,
                this.driverUserId
            );

            const endpoint = '/api/rates/create';
            const payload = {
                method: 'post',
                data: obj
            };

            try {
                const response = await handleRequests(endpoint, payload);

                if (response.status === 200) {
                    this.$notify({
                        message: 'Successfully created rule.',
                        type: 'success'
                    });

                    this.resolve({
                        record: response.data
                    });
                } else {
                    showErrorMessage(this, 'Failed creating rule.', null);
                }
            } catch (error) {
                showErrorMessage(this, error.data, null);
            }

            this.$_handleLoaderState(false);
        },
        async updateRuleDetails() {
            if (!this.validateData()) {
                showErrorMessage(this, 'Rule must have valid data', null);
                return;
            }

            this.$_handleLoaderState(true, 'UPDATING...');

            const rateId = this.rateDetail.rateRulesId;
            this.rateDetail.condition = null;
            this.rateDetail.currency = null;
            this.rateDetail.priority = null;
            this.rateDetail.rateRulesId = null;

            const obj = {
                rateRulesId: rateId,
                rule: JSON.stringify(this.rateDetail)
            };

            const endpoint = '/api/rates/updateRate';
            const payload = {
                method: 'post',
                data: obj
            };

            try {
                const response = await handleRequests(endpoint, payload);

                if (response.status === 200) {
                    this.$notify({
                        message: 'Successfully updated rule.',
                        type: 'success'
                    });

                    this.resolve({
                        record: response.status
                    });
                } else {
                    showErrorMessage(this, 'Failed updating rule.', null);
                }
            } catch (error) {
                showErrorMessage(this, 'Failed updating rule.', null);
            }

            this.$_handleLoaderState(false);
        },
        handleDaysSeleceted(value) {
            this.rateDetail = Object.assign(this.rateDetail, value);
        }
    }
};
</script>

<style lang="scss" scoped>
.rate-form-container {
    padding-top: 20px;
    max-height: 80vh;
    padding-right: 10px;
}

::v-deep .form-wrapper {
    width: 100%;
}

.md-switch-container {
    .md-checked {
        ::v-deep .md-switch-container {
            background-color: #2b93ff !important;
        }

        ::v-deep .md-switch-thumb {
            border: 1px solid #2b93ff !important;
        }
    }
}
</style>
