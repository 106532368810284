var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.driveData || _vm.driveData.length == 0
        ? _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-blue" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("summarize")])],
                    1
                  ),
                ]
              ),
              _c("md-card-content", [
                _c("div", [
                  _c("h3", [_vm._v("Ignition On/Off Report")]),
                  _c("p", [
                    _vm._v(
                      "\n                    No results to display.\n                "
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._l(_vm.filteredData, function (dataList, i) {
            return _c(
              "div",
              { key: i },
              [
                _c(
                  "md-card",
                  [
                    _c(
                      "md-card-header",
                      {
                        staticClass: "md-card-header-icon md-card-header-blue",
                      },
                      [
                        _c("CardHeaderDropdown", {
                          attrs: {
                            "default-icon": "summarize",
                            "default-text": _vm.displayDate(i),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("md-card-content", [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "report-container" },
                          [
                            _c(
                              "md-card-content",
                              [
                                _c("md-table", {
                                  staticClass: "table-hover",
                                  attrs: { value: dataList },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "md-table-row",
                                        fn: function (ref) {
                                          var item = ref.item
                                          var index = ref.index
                                          return _c(
                                            "md-table-row",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewAddress(
                                                    item.arrival
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "md-table-cell",
                                                { attrs: { "md-label": "ID" } },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(index + 1) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  attrs: {
                                                    "md-label":
                                                      "Departure time",
                                                    "md-sort": "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm._f("timeFormat")(
                                                          _vm.displayDateTime(
                                                            item.departure,
                                                            item.utcOffset
                                                          ),
                                                          _vm.DATE_TYPES
                                                            .standardTime
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  attrs: {
                                                    "md-label":
                                                      "Departure location",
                                                    "md-sort": "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "address",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.viewAddress(
                                                            item.departure
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.displayAddressOrCoord(
                                                              item.departure
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  attrs: {
                                                    "md-label": "Arrival time",
                                                    "md-sort": "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm._f("timeFormat")(
                                                          _vm.displayDateTime(
                                                            item.arrival,
                                                            item.utcOffset
                                                          ),
                                                          _vm.DATE_TYPES
                                                            .standardTime
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  attrs: {
                                                    "md-label":
                                                      "Arrival location",
                                                    "md-sort": "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "address",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.viewAddress(
                                                            item.arrival
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.displayAddressOrCoord(
                                                              item.arrival
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  attrs: {
                                                    "md-label":
                                                      "Distance covered",
                                                    "md-sort": "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.toDistanceStr(
                                                          item.distanceCoveredMeters /
                                                            1000
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  attrs: {
                                                    "md-label": "Travel time",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.toTime(
                                                          item.travelTimeMinutes
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  attrs: {
                                                    "md-label": "Device IMEI",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(item.deviceIMIE) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("div", { staticClass: "label-total" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Total Ignitions: " +
                                        _vm._s(dataList.length)
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }