<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ isUpdate ? 'Update' : 'Create' }} Warehouse</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.warehouseDetails" class="form-wrapper">
                <div class="form-container warehouse-form-container">
                    <div class="warehouse-details">
                        <form-group name="teamRegion" label="Team Region">
                            <team-region-options
                                :selected="warehouseDetails.teamRegionId"
                                @selectedOption="handleTeamRegionChanged"
                                :mode="isUpdate ? 'update' : 'create'"
                                :show-auto="false"
                            />
                        </form-group>

                        <form-group name="name" label="Name">
                            <md-input v-focus v-model="warehouseDetails.warehouseName" />
                        </form-group>
                        <google-autocomplete
                            label="Address"
                            ref="address"
                            id="autoaddress"
                            classname="form-control autocomplete-input"
                            placeholder=""
                            :should-focus="false"
                            v-model="warehouseDetails"
                            :disable-gps-coordinates="true"
                        />
                        <form-group name="firstName" label="First Name">
                            <md-input v-focus v-model.trim="warehouseDetails.firstName" />
                        </form-group>
                        <form-group name="lastName" label="Last Name">
                            <md-input v-model.trim="warehouseDetails.lastName" />
                        </form-group>
                        <form-group name="email" label="Email">
                            <md-input v-model.trim="warehouseDetails.email" />
                        </form-group>
                        <form-group name="phone" label="Phone">
                            <md-input v-model.trim="warehouseDetails.phone" />
                        </form-group>
                        <form-group name="notes" label="Default Stop Notes">
                            <md-textarea v-model.trim="warehouseDetails.defaultStopNotes" />
                        </form-group>
                        <div>
                            <md-switch v-model="warehouseDetails.isDefaultShipmentPickupStop">
                                Use as Default Pickup Address for Shipments
                            </md-switch>
                        </div>
                        <div>
                            <md-switch v-model="warehouseDetails.isDefaultShipmentDropStop">
                                Use as Default Drop Address for Shipments
                            </md-switch>
                        </div>
                    </div>
                    <div></div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validateWarehouse">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import {
    handleRequests,
    showErrorMessage,
    isEmptyOrSpaces,
    showTeamRegionControl
    // minStopDuration,
    // maxStopDuration,
    // isStopDurationNull,
    /* isAValidCoordinate */
} from '@/helpers';
import { required, maxLength } from 'vuelidate/lib/validators';
import { GoogleAutocomplete, TeamRegionOptions } from '@/components';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'CreateWarehouseModal',
    components: { GoogleAutocomplete, TeamRegionOptions },
    mixins: [GeneralMixin],
    props: {
        warehouseDetails: {
            type: Object,
            default: () => {}
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasAssetAccess: 'user/hasAssetAccess',
            teamRegions: 'team/teamRegions',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        }),
        showTeamRegion() {
            return showTeamRegionControl(
                {
                    user: this.user,
                    hasAssetAccess: this.hasAssetAccess,
                    teamRegions: this.teamRegions
                },
                this.warehouseDetails.teamRegionId
            );
        }
    },
    data() {
        return {
            // useCoordinates: false
        };
    },
    watch: {
        'warehouseDetails.isDefaultShipmentPickupStop': function(newVal) {
            if (newVal && this.warehouseDetails.isDefaultShipmentDropStop)
                this.warehouseDetails.isDefaultShipmentDropStop = false;
        },
        'warehouseDetails.isDefaultShipmentDropStop': function(newVal) {
            if (newVal && this.warehouseDetails.isDefaultShipmentPickupStop)
                this.warehouseDetails.isDefaultShipmentPickupStop = false;
        }
    },
    mounted() {
        this.warehouseDetails.name = this.warehouseDetails.address;
    },
    methods: {
        ...mapActions('team', ['FETCH_WAREHOUSES']),
        async create() {
            this.$_handleLoaderState(true, 'PROCESSING...');
            const api = `/api/warehouses`;
            const payload = {
                method: 'post',
                data: this.warehouseDetails
            };

            try {
                const response = await handleRequests(api, payload);
                // const warehouseId = response.data;
                if (response.status === 200) {
                    this.refreshState();
                    this.$notify({
                        message: 'Successfully created warehouse.',
                        type: 'success'
                    });

                    // todo: check the bellow.
                    this.$v.$reset();
                    this.resolve('ok');
                }
            } catch (error) {
                const message = 'Error in creating the warehouse';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        async updateWarehouseDetails() {
            this.$_handleLoaderState(true, 'UPDATING...');
            const api = `/api/warehouses/${this.warehouseDetails.warehouseId}`;
            const payload = {
                method: 'put',
                data: this.warehouseDetails
            };

            try {
                await handleRequests(api, payload);
                this.refreshState();
                this.$notify({
                    message: 'Successfully updated warehouse.',
                    type: 'success'
                });
                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Error in updating the warehouse.';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        validateWarehouse() {
            if (isEmptyOrSpaces(this.warehouseDetails.warehouseName)) {
                this.$notify({
                    message: 'Please enter a warehouse name to submit the form.',
                    type: 'danger'
                });
                return;
            }

            this.$v.warehouseDetails.$touch();

            if (!this.$v.warehouseDetails.$invalid) {
                if (!isEmptyOrSpaces(this.warehouseDetails.warehouseName)) {
                    this.warehouseDetails.warehouseName = this.warehouseDetails.warehouseName.trim();
                }
                // when using the google autocomplete we cannot have a name property in our object as the component will override this
                // upon entering an address. Having a temporary name will solve this issue and just reassign back the name to the name property upon form submission.
                this.warehouseDetails.name = this.warehouseDetails.warehouseName;

                if (!this.warehouseDetails.address) {
                    this.warehouseDetails.location = null;
                }

                if (this.isUpdate) {
                    this.updateWarehouseDetails();
                } else {
                    this.create();
                }
            }
        },
        handleTeamRegionChanged(value) {
            if (value && value > 0) 
                this.warehouseDetails.teamRegionId = value;
            else 
                this.warehouseDetails.teamRegionId = null;
        },
        async refreshState() {
            this.FETCH_WAREHOUSES();
        }
        //    handleCoordinates(value) {
        //        this.useCoordinates = value;
        //        this.$v.$reset();
        //    }
    },
    validations: {
        warehouseDetails: {
            warehouseName: { required, maxLength: maxLength(100) }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-container {
    max-width: 600px;
}

::v-deep .md-switch {
    .md-switch-label {
        color: #3c4858 !important;
    }
}
</style>
