<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ isUpdate ? 'Update' : 'Create' }} Customer Profile</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.customerDetails" class="form-wrapper">
                <div class="form-container customer-form-container">
                    <div class="contact-details">
                        <div v-if="!isSingleUser && !isSingleTeamMember">
                            <form-group v-if="showTeamRegion" name="teamRegion" label="Team Region">
                                <team-region-options
                                    :selected="customerDetails.teamRegionId"
                                    @selectedOption="handleTeamRegionChanged"
                                    :mode="isUpdate ? 'update' : 'create'"
                                    :show-auto="false"
                                />
                            </form-group>
                        </div>
                        <form-group name="firstName" label="First Name">
                            <md-input
                                v-focus
                                v-model.trim="customerDetails.firstName"
                                @input="setCustomerName(customerDetails)"
                            />
                        </form-group>
                        <form-group name="lastName" label="Last Name">
                            <md-input
                                v-model.trim="customerDetails.lastName"
                                @input="setCustomerName(customerDetails)"
                            />
                        </form-group>
                        <form-group name="company" label="Company">
                            <md-input
                                v-model.trim="customerDetails.company"
                                @input="setCustomerName(customerDetails)"
                            />
                        </form-group>
                        <form-group name="businessRegistrationNumber" label="ABN">
                            <md-input v-model.trim="customerDetails.businessRegistrationNumber" />
                        </form-group>
                        <div>
                            <brand-filter v-model="customerDetails" :list-of-brands="brands" />
                        </div>
                        <div>
                            <business-entities-dropdown v-model="customerDetails.invoicingBusinessEntityId" />
                        </div>
                        <div>
                            <charging-type-options
                                :selected-charge-type-id="customerDetails.defaultRateGroupId"
                                label-name="Default Service"
                                @selectedOption="rateGroupChanged"
                            />
                        </div>
                        <div v-if="customerDetails.defaultRateGroupId">
                            <div>
                                <span style="color: #aaa; font-weight: 400; font-size: 14px">
                                    Apply the Service Package to the following:
                                </span>
                            </div>
                            <md-checkbox
                                v-model="applyRatesToValue"
                                v-for="(type, index) in applyRatesTo"
                                :key="index"
                                :value="type.key"
                            >
                                {{ type.label }}
                            </md-checkbox>
                        </div>
                        <form-group name="invoiceTypes" label="Invoice Types" class="invoice-line-container">
                            <md-input v-model="customerDetails.invoiceLineTypes" type="hidden" />
                            <vue-select
                                :reduce="(r) => r"
                                label="name"
                                :options="invoiceLineOptions"
                                v-model="customerDetails.invoiceLineTypes"
                                :searchable="$root.isDesktop"
                                multiple
                                class="invoice-line-background"
                            ></vue-select>
                            <div
                                v-if="customerDetails.invoiceLineTypes && customerDetails.invoiceLineTypes.length > 0"
                                class="invoice-line-icon"
                            >
                                <md-button
                                    v-if="shouldShowDownloadIcon"
                                    class="md-primary md-just-icon md-round"
                                    @click="downloadInvoiceLineExamples"
                                >
                                    <md-icon>download</md-icon>
                                    <md-tooltip md-direction="right">Download Examples</md-tooltip>
                                </md-button>
                            </div>
                        </form-group>

                        <google-autocomplete
                            label="Default Stop Address"
                            ref="address"
                            id="autoaddress"
                            classname="form-control autocomplete-input"
                            placeholder=""
                            :should-focus="false"
                            v-model="customerDetails"
                            :disable-gps-coordinates="false"
                            gps-coordinate-placeholder="Enter a name for this stop address"
                            @handleCoordinates="handleCoordinates"
                        ></google-autocomplete>
                        <form-group name="email" label="Email">
                            <md-input v-model.trim="customerDetails.email" />
                        </form-group>
                        <form-group name="contactEmail" label="Contact Email">
                            <md-input v-model.trim="customerDetails.contactEmail" />
                        </form-group>
                        <span>
                            <md-checkbox v-model="customerDetails.enableBookingConfirmationEmail">
                                Enable booking confirmation emails
                            </md-checkbox>

                            <md-tooltip>
                                <span>
                                    When you add a stop/shipment on behalf of this customer they will receive a
                                    confirmation email on their 'Contact Email' if this box is checked.
                                </span>
                            </md-tooltip>
                        </span>
                        <div class="skillfield-container">
                            <label for="skills" v-if="accountEmailList.length">Accounts Email</label>
                            <multiselect
                                v-model="accountEmailList"
                                :options="accountEmailList"
                                :multiple="true"
                                :taggable="true"
                                :close-on-select="true"
                                placeholder="Search or add accounts email"
                                @tag="addAccountEmail"
                            ></multiselect>
                        </div>
                        <form-group name="phone" label="Phone">
                            <md-input v-model.trim="customerDetails.phone" />
                        </form-group>
                        <form-group name="defaultStopNotes" label="Default Stop/Shipment Notes">
                            <md-textarea v-model.trim="customerDetails.defaultStopNotes" />
                        </form-group>
                        <form-group name="notes" label="Notes">
                            <md-textarea v-model.trim="customerDetails.notes" />
                        </form-group>
                        <form-group name="defaultStopDurationMinutes" label="Default stop duration (mins)">
                            <md-input type="number" v-model.number="customerDetails.defaultStopDurationMinutes" />
                        </form-group>
                        <form-group name="customerName" label="Customer Name" class="last-input-field">
                            <md-input v-model.trim="customerDetails.customerName" />
                        </form-group>
                        <form-group v-if="allTeamMembers.length" name="teamMembers" label="Associated Team members">
                            <multiselect
                                v-model="selectedTeamMembers"
                                :options="allTeamMembers"
                                :multiple="true"
                                :close-on-select="true"
                                placeholder="Associate Team member"
                                label="fullName"
                                track-by="publicUserId"
                            ></multiselect>
                        </form-group>
                        <md-checkbox
                            v-model="customerDetails.createUserAccount"
                            v-if="customerDetails.linkedUserEmail == null && !isSingleUser"
                        >
                            Create a user login for this customer?
                        </md-checkbox>
                        <form-group
                            name="customerrole"
                            label="Customer Role"
                            v-if="customerDetails.createUserAccount || customerDetails.linkedUserEmail != null"
                        >
                            <md-select v-model="customerDetails.role">
                                <md-option
                                    v-for="(customerRole, index) in customerRoles"
                                    :key="index"
                                    :value="customerRole"
                                >
                                    {{ customerRole }}
                                </md-option>
                            </md-select>
                        </form-group>
                        <div>
                            <md-checkbox v-model="setPassword" v-if="customerDetails.createUserAccount">
                                Set a password.
                            </md-checkbox>
                        </div>
                        <form-group v-if="setPassword" name="password" label="Password">
                            <md-input v-model="password" />
                            <md-icon class="icon-info">info</md-icon>
                            <md-tooltip class="tooltip-width" md-direction="right">
                                Passwords must contains at least 8 characters with at least one non alphanumeric
                                character, at least one digit ('0'-'9'), at least one lowercase ('a'-'z') and at least
                                one uppercase ('A'-'Z').
                            </md-tooltip>
                        </form-group>

                        <span v-else-if="customerDetails.linkedUserEmail != null" class="customer-note">
                            ** This customer is linked to this user: {{ customerDetails.linkedUserEmail }}
                        </span>
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validateCustomer">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import {
    handleRequests,
    showErrorMessage,
    isEmptyOrSpaces,
    minStopDuration,
    maxStopDuration,
    isStopDurationNull,
    isAValidCoordinate,
    showTeamRegionControl,
    isValidEmail,
    openFile
} from '@/helpers';
import { required, email, maxLength, requiredIf } from 'vuelidate/lib/validators';
import {
    GoogleAutocomplete,
    TeamRegionOptions,
    BrandFilter,
    ChargingTypeOptions,
    BusinessEntitiesDropdown
} from '@/components';
import { CUSTOMER_ROLES_CONSTANTS, INVOICE_LINE_TYPES, APPLY_RATES_TO } from '@/utils/constants';
import { ReverseApplyRatesToBitwise } from '@/utils/ApplyRatesToUtils';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters, mapActions } from 'vuex';

// eslint-disable-next-line import/no-dynamic-require
const { config } = require(`../../../config/config.${process.env.NODE_ENV}`);

export default {
    name: 'CreateCustomerModal',
    components: { GoogleAutocomplete, TeamRegionOptions, BrandFilter, ChargingTypeOptions, BusinessEntitiesDropdown },
    mixins: [GeneralMixin],
    props: {
        customerDetails: {
            type: Object,
            default: () => {}
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        brands: {
            type: Array,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasAssetAccess: 'user/hasAssetAccess',
            teamRegions: 'team/teamRegions',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            allTeamMembers: 'team/teamMembers'
        }),
        showTeamRegion() {
            return showTeamRegionControl(
                {
                    user: this.user,
                    hasAssetAccess: this.hasAssetAccess,
                    teamRegions: this.teamRegions
                },
                this.customerDetails.teamRegionId
            );
        },
        shouldShowDownloadIcon() {
            if (this.customerDetails.invoiceLineTypes.length <= 0) 
                return false;

            const serviceLineType = this.customerDetails.invoiceLineTypes.find(
                (x) => x.name === 'Include service lines'
            );

            if (this.customerDetails.invoiceLineTypes.length === 1 && serviceLineType != null) 
                return false;

            return true;
        }
    },
    data() {
        return {
            setPassword: false,
            password: null,
            oldCustomerRole: null,
            customerRoles: CUSTOMER_ROLES_CONSTANTS,
            useCoordinates: false,
            selectedTeamMembers: [],
            accountEmailList: [],
            invoiceLineOptions: INVOICE_LINE_TYPES,
            applyRatesTo: APPLY_RATES_TO,
            applyRatesToValue: [1, 2, 4]
        };
    },
    mounted() {
        this.FETCH_TEAM_MEMBERS();
        this.customerDetails.name = this.customerDetails.locationName;
        this.oldCustomerRole = this.customerDetails.role;
        if (
            this.customerDetails.assignedTeamMembers.length != null ||
            this.customerDetails.assignedTeamMembers != null
        ) {
            this.selectedTeamMembers = this.customerDetails.assignedTeamMembers;
        }

        if (this.isUpdate) {
            this.applyRatesToValue = ReverseApplyRatesToBitwise(this.customerDetails.applyRatesTo, APPLY_RATES_TO);
            if (this.customerDetails.accountsEmailList.length > 0)
                this.accountEmailList = this.customerDetails.accountsEmailList;
        }
    },
    watch: {
        'customerDetails.invoiceLineTypes': function(newValue) {
            const lineOptions = [...INVOICE_LINE_TYPES];

            if (newValue.length <= 0) {
                this.invoiceLineOptions = lineOptions;
            } else {
                const selectedKeys = newValue.map((item) => item.key);

                this.invoiceLineOptions = lineOptions.filter(
                    (line) => !newValue.some((value) => value.inCompatible.includes(line.key))
                );
                this.invoiceLineOptions = this.invoiceLineOptions.filter((item) => !selectedKeys.includes(item.key));
            }
        },
        'customerDetails.invoicingBusinessEntityId': function(newValue) {
            this.customerDetails.invoicingBusinessEntityId = newValue;
        }
    },
    methods: {
        ...mapActions('team', ['FETCH_TEAM_MEMBERS']),
        async create() {
            this.$_handleLoaderState(true, 'PROCESSING...');
            this.customerDetails.assignedTeamMembers = [];
            if (this.selectedTeamMembers.length > 0) {
                this.selectedTeamMembers.forEach((member) => {
                    this.customerDetails.assignedTeamMembers.push(member.publicUserId);
                });
            }

            if (this.accountEmailList != null && this.accountEmailList.length > 0) {
                this.customerDetails.accountsEmail = this.accountEmailList.join(';');
            } else {
                this.customerDetails.accountsEmail = null;
            }

            this.customerDetails.applyRatesTo = this.applyRatesToValue.reduce((a, b) => a + b, 0);
            const api = `/api/customers`;
            const payload = {
                method: 'post',
                data: this.customerDetails
            };

            try {
                const response = await handleRequests(api, payload);
                const customerId = response.data;
                if (this.customerDetails.createUserAccount) {
                    await this.addUser(customerId);
                }

                this.$notifySuccess('Successfully created customer.');

                if (!this.customerDetails.createUserAccount) {
                    this.$v.$reset();
                    this.resolve('ok');
                }
            } catch (error) {
                const message = 'Error in creating the customer profile';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        async addUser(customerId) {
            this.$_handleLoaderState(true, 'SAVING...');

            const userDetails = {
                email: this.customerDetails.email,
                firstName: this.customerDetails.firstName,
                lastName: this.customerDetails.lastName,
                role: this.customerDetails.role,
                password: this.password || null,
                customerId,
                teamRegionId: this.customerDetails.teamRegionId
            };
            const payload = {
                method: 'post',
                data: userDetails
            };
            const api = `${config.authority}api/team/users/create`;
            try {
                await handleRequests(api, payload).then((response) => {
                    const result = response.data;
                    this.$_handleLoaderState(false);
                    this.$messageBox
                        .show({
                            title: 'New Customer Account Created Successfully!',
                            body: `Email: ${
                                this.customerDetails.email
                            } </br> Temporary Password: ${result} </br></br> The temporary password will be displayed once. Please copy and save it before logging in.\n`,
                            buttons: ['Confirm']
                        })
                        .then(() => {
                            this.$v.$reset();
                            this.resolve('ok');
                        });
                });
            } catch (error) {
                this.$_handleLoaderState(false);
                const message = error.data[0].description;
                showErrorMessage(this, message);
            }
        },
        async updateUserRole(customer) {
            const api = `/api/team-members/${customer.publicUserId}/roles`;
            const payload = {
                method: 'post',
                data: [this.oldCustomerRole, customer.role]
            };
            await handleRequests(api, payload);
        },
        setCustomerName(data) {
            data.customerName = null;
            if (!isEmptyOrSpaces(data.company)) {
                data.customerName = `${data.company}`;
                if (!isEmptyOrSpaces(data.firstName) || !isEmptyOrSpaces(data.lastName)) {
                    const fullName = `${!isEmptyOrSpaces(data.firstName) ? data.firstName : ''} ${
                        !isEmptyOrSpaces(data.lastName) ? data.lastName : ''
                    }`.trim();
                    data.customerName = `${data.customerName} (${fullName})`.trim();
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (!isEmptyOrSpaces(data.firstName) || !isEmptyOrSpaces(data.lastName)) {
                    data.customerName = `${!isEmptyOrSpaces(data.firstName) ? data.firstName : ''} ${
                        !isEmptyOrSpaces(data.lastName) ? data.lastName : ''
                    }`.trim();
                }
            }
        },
        async updateCustomerDetails() {
            if (this.customerDetails.location) {
                if (!this.customerDetails.location.latitude || !this.customerDetails.location.longitude) {
                    this.customerDetails.location = null;
                }
            }

            if (!this.customerDetails.defaultStopDurationMinutes)
                this.customerDetails.defaultStopDurationMinutes = null;

            this.customerDetails.assignedTeamMembers = [];
            if (this.selectedTeamMembers.length > 0) {
                this.selectedTeamMembers.forEach((member) => {
                    this.customerDetails.assignedTeamMembers.push(member.publicUserId);
                });
            }

            if (this.accountEmailList != null && this.accountEmailList.length > 0) {
                this.customerDetails.accountsEmail = this.accountEmailList.join(';');
            } else {
                this.customerDetails.accountsEmail = null;
            }

            if (this.customerDetails.company === null || this.customerDetails.company.length <= 0) {
                this.customerDetails.company = null;
            }

            this.customerDetails.applyRatesTo = this.applyRatesToValue.reduce((a, b) => a + b, 0);
            this.$_handleLoaderState(true, 'UPDATING...');
            const api = `/api/customers/${this.customerDetails.customerId}`;
            const payload = {
                method: 'put',
                data: this.customerDetails
            };

            try {
                await handleRequests(api, payload);

                if (this.customerDetails.createUserAccount) {
                    await this.addUser(this.customerDetails.customerId);
                } else if (
                    this.oldCustomerRole !== this.customerDetails.role &&
                    this.customerDetails.publicUserId !== null
                ) {
                    await this.updateUserRole(this.customerDetails);
                }

                this.$notifySuccess('Successfully updated customer.');

                if (!this.customerDetails.createUserAccount) {
                    this.$v.$reset();
                    this.resolve('ok');
                }
            } catch (error) {
                const message = 'Error in updating the customer profile';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        validateCustomer() {
            if (isEmptyOrSpaces(this.customerDetails.firstName) && isEmptyOrSpaces(this.customerDetails.company)) {
                this.$notifyError('Please enter either a first name or a company name to submit the form.');
                return;
            }

            this.$v.customerDetails.$touch();
            if (!this.$v.customerDetails.$invalid) {
                if (!isEmptyOrSpaces(this.customerDetails.customerName)) {
                    this.customerDetails.customerName = this.customerDetails.customerName.trim();
                }

                if (this.useCoordinates) {
                    // before the name will totally be overriden we need to set the name of the address to the locationName first
                    // when using coordinates
                    this.customerDetails.locationName = this.customerDetails.name;
                } else {
                    this.customerDetails.locationName = null;
                }

                // when using the google autocomplete we cannot have a name property in our object as the component will override this
                // upon entering an address. Having a temporary name will solve this issue and just reassign back the name to the name property upon form submission.
                this.customerDetails.name = this.customerDetails.customerName;

                if (!this.customerDetails.address) {
                    this.customerDetails.location = null;
                }

                if (this.isUpdate) {
                    this.updateCustomerDetails();
                } else {
                    this.create();
                }
            }
        },
        handleCoordinates(value) {
            this.useCoordinates = value;
            this.$v.$reset();
        },
        handleTeamRegionChanged(value) {
            this.customerDetails.teamRegionId = value;
        },
        rateGroupChanged(rateGroup) {
            this.customerDetails.defaultRateGroupId = rateGroup?.rateGroupId;
        },
        addAccountEmail(newEmail) {
            const isValid = isValidEmail(newEmail);
            if (!isValid) {
                this.$notifyError(`${newEmail} is not a valid email`);
                return;
            }
            this.accountEmailList.push(newEmail);
        },
        async downloadInvoiceLineExamples() {
            this.customerDetails.invoiceLineTypes.forEach((invoiceType) => {
                if (invoiceType.name !== 'Include service lines') 
                    this.downloadInvoiceExample(invoiceType.name);
            });
        },
        async downloadInvoiceExample(invoiceType) {
            this.$_handleLoaderState(true, 'DOWNLOADING...');

            const api = `/api/customer-invoices/download/invoice-examples?invoiceExamples=${invoiceType}`;
            const payload = {
                method: 'get',
                responseType: 'blob'
            };

            try {
                const response = await handleRequests(api, payload);
                await openFile(response.data, `${invoiceType}.pdf`);
            } catch (error) {
                this.$_handleLoaderState(false);
                const message = error.data;
                showErrorMessage(this, message, null);
            }
            this.$_handleLoaderState(false);
        }
    },
    validations: {
        customerDetails: {
            customerName: { required, maxLength: maxLength(100) },
            firstName: { maxLength: maxLength(100) },
            lastName: { maxLength: maxLength(100) },
            company: { maxLength: maxLength(100) },
            email: { email },
            contactEmail: {
                email,
                required:
                    // eslint-disable-next-line func-names
                    requiredIf(function() {
                        return this.customerDetails.enableBookingConfirmationEmail;
                    })
            },
            defaultStopDurationMinutes: {
                minValue_customerStopDuration: minStopDuration,
                maxValue_customerStopDuration: maxStopDuration, // max duration value of 24 hours,
                numeric_duration: isStopDurationNull
            },
            address: {
                required:
                    // eslint-disable-next-line func-names
                    requiredIf(function() {
                        return this.useCoordinates && this.customerDetails.name != null;
                    }),
                coordinatesValid(data) {
                    if ((this.customerDetails.name != null || data != null) && this.useCoordinates) {
                        return isAValidCoordinate(data);
                    }
                    return true;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-container {
    max-width: 600px;
}

.customer-note {
    font-size: 13px;
    margin-top: 10px;
    display: inline-block;
}
.icon-info {
    color: #2b93ff !important;
    cursor: pointer;
}
.tooltip-width {
    max-width: 350px;
    text-align: justify;
}

::v-deep .invoice-line-background .vs__selected {
    background-color: #42b983 !important;
    color: white;
}

.invoice-line-icon {
    display: inline-block;
    vertical-align: middle;

    .md-button {
        height: 30px;
        width: 30px;
        min-width: 30px;
        margin-right: 0;

        ::v-deep i {
            color: #2b93ff !important;
        }
        background-color: transparent !important;
    }

    .md-button:active,
    .md-button:focus {
        background-color: transparent !important;

        ::v-deep i {
            color: #2b93ff !important;
        }
    }

    .md-button:hover {
        background: none !important;
        background-color: #2b93ff !important;

        ::v-deep i {
            color: #fff !important;
        }
    }
}

::v-deep .vs--searchable .vs__dropdown-toggle,
.vs--unsearchable .vs__dropdown-toggle {
    border: none !important;
}
</style>
