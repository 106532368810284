var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "profile-container" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25",
        },
        [
          _c(
            "md-card",
            { staticClass: "md-card-profile" },
            [
              _c(
                "div",
                {
                  staticClass: "md-card-avatar upload-container",
                  on: {
                    mouseover: function ($event) {
                      _vm.showUploadButton = true
                    },
                    mouseleave: function ($event) {
                      _vm.showUploadButton = false
                    },
                  },
                },
                [
                  !_vm.photoPreview
                    ? _c("img", {
                        staticClass: "profile-image",
                        attrs: {
                          src:
                            _vm.member.photoUrl !== null
                              ? _vm.member.photoUrl
                              : _vm.$root.defaultPhotoUrl,
                          alt: "",
                        },
                        on: { error: _vm.$_setDefaultBrokenImage },
                      })
                    : _vm._e(),
                  _vm.photoPreview
                    ? _c("img", {
                        staticClass: "profile-image",
                        attrs: { src: _vm.photoPreview, alt: "" },
                        on: { error: _vm.$_setDefaultBrokenImage },
                      })
                    : _vm._e(),
                  _vm.showUploadButton && _vm.canUpdateMember
                    ? _c("div", [
                        _c("input", {
                          ref: "photoInput",
                          attrs: { hidden: "", type: "file" },
                          on: { change: _vm.onPhotoSelected },
                        }),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "custom-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.photoInput.click()
                                },
                              },
                            },
                            [_vm._v("Update Photo")]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
              _c("md-card-content", [
                _c("h6", { staticClass: "category text-gray" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.displayRole) +
                      "\n                    "
                  ),
                ]),
                _c("span", { staticClass: "card-description" }, [
                  _vm._v(
                    "\n                        Date Joined: " +
                      _vm._s(
                        _vm._f("dateFormat")(
                          _vm.member.dateJoined,
                          _vm.DATE_TYPES.standardDate
                        )
                      ) +
                      "\n                    "
                  ),
                ]),
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.displayName) +
                      "\n                    "
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-75 md-xsmall-size-100 md-size-75",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-warning" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("person")])],
                    1
                  ),
                ]
              ),
              _c("md-card-content", [
                _c(
                  "div",
                  { staticClass: "member-information field-spacing-container" },
                  [
                    _c(
                      "form-wrapper",
                      {
                        staticClass: "form-wrapper",
                        attrs: { validator: _vm.$v.member },
                      },
                      [
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "firstName",
                                      label: "First Name",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      attrs: { disabled: !_vm.canUpdateMember },
                                      model: {
                                        value: _vm.member.firstName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.member, "firstName", $$v)
                                        },
                                        expression: "member.firstName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "lastName",
                                      label: "Last Name",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      attrs: { disabled: !_vm.canUpdateMember },
                                      model: {
                                        value: _vm.member.lastName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.member, "lastName", $$v)
                                        },
                                        expression: "member.lastName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "email",
                                      label: "Email Address",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      model: {
                                        value: _vm.member.email,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.member, "email", $$v)
                                        },
                                        expression: "member.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  { attrs: { name: "phone", label: "Phone" } },
                                  [
                                    _c("md-input", {
                                      attrs: { disabled: !_vm.canUpdateMember },
                                      model: {
                                        value: _vm.member.phone,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.member, "phone", $$v)
                                        },
                                        expression: "member.phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "company",
                                      label: "Company",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      model: {
                                        value: _vm.member.company,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.member, "company", $$v)
                                        },
                                        expression: "member.company",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "businessRegistrationNumber",
                                      label:
                                        _vm.businessRegistrationNumberLabel,
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      model: {
                                        value:
                                          _vm.member.businessRegistrationNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.member,
                                            "businessRegistrationNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "member.businessRegistrationNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "md-layout half-width" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "accountemail",
                                      label: "Accounts Email Address",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      model: {
                                        value: _vm.member.accountsEmail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.member,
                                            "accountsEmail",
                                            $$v
                                          )
                                        },
                                        expression: "member.accountsEmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm.skillList.length
                          ? _c("div", { staticClass: "information-box" }, [
                              _c("div", { staticClass: "md-layout" }, [
                                _c("div", { staticClass: "md-layout-item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "skillfield-container" },
                                    [
                                      _vm.member.skills.length
                                        ? _c(
                                            "label",
                                            { attrs: { for: "skills" } },
                                            [_vm._v("Skills")]
                                          )
                                        : _vm._e(),
                                      _c("multiselect", {
                                        attrs: {
                                          options: _vm.skillList,
                                          multiple: true,
                                          "close-on-select": true,
                                          disabled: !_vm.canUpdateMember,
                                          placeholder: "Pick skills",
                                        },
                                        model: {
                                          value: _vm.member.skills,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.member, "skills", $$v)
                                          },
                                          expression: "member.skills",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.serviceAreasList && _vm.serviceAreasList.length
                          ? _c("div", { staticClass: "information-box" }, [
                              _c("div", { staticClass: "md-layout" }, [
                                _c("div", { staticClass: "md-layout-item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "skillfield-container" },
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "serviceAreas" } },
                                        [
                                          _vm._v(
                                            "\n                                                Service Areas\n                                            "
                                          ),
                                        ]
                                      ),
                                      _c("multiselect", {
                                        attrs: {
                                          options: _vm.serviceAreasList,
                                          multiple: true,
                                          "close-on-select": true,
                                          placeholder: "Pick service areas",
                                        },
                                        model: {
                                          value: _vm.member.serviceAreas,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.member,
                                              "serviceAreas",
                                              $$v
                                            )
                                          },
                                          expression: "member.serviceAreas",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.capacityDefinitions.length
                          ? _c("div", { staticClass: "information-box" }, [
                              _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  _vm._l(
                                    _vm.capacityDefinitions,
                                    function (capacity, index) {
                                      return _c("capacity-inputs", {
                                        key: index,
                                        attrs: {
                                          "capacity-definition": capacity,
                                          "initial-value":
                                            _vm.capacityValues[capacity.type],
                                          disabled: !_vm.canUpdateMember,
                                        },
                                        on: {
                                          changed: _vm.handleCapacityChanged,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _vm.showTeamRegion
                              ? _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c(
                                      "form-group",
                                      {
                                        attrs: {
                                          name: "team-region",
                                          label: "Team Region",
                                        },
                                      },
                                      [
                                        _c("team-region-options", {
                                          attrs: {
                                            mode: "update",
                                            selected: _vm.member.teamRegionId,
                                            "show-auto": false,
                                            enabled:
                                              _vm.canEditOwnTeamRegion &&
                                              _vm.canUpdateMember,
                                          },
                                          on: {
                                            selectedOption:
                                              _vm.handleTeamRegionChanged,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    staticClass: "md-field-split",
                                    attrs: {
                                      name: "rateGroup",
                                      label: "Default Service Package",
                                    },
                                  },
                                  [
                                    _c(
                                      "md-select",
                                      {
                                        attrs: {
                                          disabled: !_vm.canUpdateMember,
                                        },
                                        model: {
                                          value: _vm.rateGroupId,
                                          callback: function ($$v) {
                                            _vm.rateGroupId = $$v
                                          },
                                          expression: "rateGroupId",
                                        },
                                      },
                                      _vm._l(_vm.rateGroups, function (item) {
                                        return _c(
                                          "md-option",
                                          {
                                            key: item.rateGroupId,
                                            attrs: { value: item.rateGroupId },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(item.rateGroupName) +
                                                "\n                                                "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.rateGroupId != -1
                              ? _c("div", { staticClass: "md-layout-item" }, [
                                  _c("div", [
                                    _c("label", [
                                      _vm._v(
                                        "Apply the Service Package to the following:"
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    _vm._l(
                                      _vm.applyRatesTo,
                                      function (type, index) {
                                        return _c(
                                          "md-checkbox",
                                          {
                                            key: index,
                                            attrs: { value: type.key },
                                            model: {
                                              value: _vm.applyRatesToValue,
                                              callback: function ($$v) {
                                                _vm.applyRatesToValue = $$v
                                              },
                                              expression: "applyRatesToValue",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(type.label) +
                                                "\n                                            "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          !_vm.hasTeam
                            ? _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c(
                                      "form-group",
                                      {
                                        attrs: {
                                          name: "company",
                                          label: "Company",
                                        },
                                      },
                                      [
                                        _c("md-input", {
                                          attrs: {
                                            disabled: !_vm.canUpdateMember,
                                          },
                                          model: {
                                            value: _vm.member.company,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.member,
                                                "company",
                                                $$v
                                              )
                                            },
                                            expression: "member.company",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c(
                                      "form-group",
                                      {
                                        attrs: {
                                          name: "industry",
                                          label: "Industry",
                                        },
                                      },
                                      [
                                        _c("md-input", {
                                          attrs: {
                                            disabled: !_vm.canUpdateMember,
                                          },
                                          model: {
                                            value: _vm.member.industry,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.member,
                                                "industry",
                                                $$v
                                              )
                                            },
                                            expression: "member.industry",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: { name: "role", label: "Team Role" },
                                  },
                                  [
                                    _c(
                                      "md-select",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.user.publicUserId ===
                                              _vm.member.publicUserId ||
                                            !_vm.canUpdateMember,
                                        },
                                        on: {
                                          "md-selected": _vm.getSelectedRole,
                                        },
                                        model: {
                                          value: _vm.member.role,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.member, "role", $$v)
                                          },
                                          expression: "member.role",
                                        },
                                      },
                                      _vm._l(
                                        _vm.memberRoles,
                                        function (memberRole) {
                                          return _c(
                                            "md-option",
                                            {
                                              key: memberRole.id,
                                              attrs: { value: memberRole.name },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(memberRole.name) +
                                                  "\n                                                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "md-layout-item vehicle-box" },
                              [
                                _c("label", [_vm._v("Vehicle:")]),
                                _c(
                                  "md-radio",
                                  {
                                    attrs: {
                                      value: "Bike",
                                      disabled: !_vm.canUpdateMember,
                                    },
                                    model: {
                                      value: _vm.member.vehicleType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.member, "vehicleType", $$v)
                                      },
                                      expression: "member.vehicleType",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            Bike\n                                        "
                                    ),
                                  ]
                                ),
                                _c(
                                  "md-radio",
                                  {
                                    attrs: {
                                      value: "Car",
                                      disabled: !_vm.canUpdateMember,
                                    },
                                    model: {
                                      value: _vm.member.vehicleType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.member, "vehicleType", $$v)
                                      },
                                      expression: "member.vehicleType",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            Car\n                                        "
                                    ),
                                  ]
                                ),
                                _c(
                                  "md-radio",
                                  {
                                    attrs: {
                                      value: "Walk",
                                      disabled: !_vm.canUpdateMember,
                                    },
                                    model: {
                                      value: _vm.member.vehicleType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.member, "vehicleType", $$v)
                                      },
                                      expression: "member.vehicleType",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            Walk\n                                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    staticClass: "md-field-split",
                                    attrs: {
                                      name: "role",
                                      label:
                                        "Mobile Location Tracking Behaviour",
                                    },
                                  },
                                  [
                                    _c("gps-collection-dropdown", {
                                      attrs: {
                                        selected:
                                          _vm.member.gpsDataCollectionRule,
                                        disabled: !_vm.canUpdateMember,
                                      },
                                      on: {
                                        selectedOption:
                                          _vm.getSelectedTrackingBehaviour,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-size-25",
                              },
                              [
                                _vm.isLoaded
                                  ? _c(
                                      "form-group",
                                      {
                                        staticClass:
                                          "timer-field hide-timer-clear-button",
                                        attrs: {
                                          name: "defaultTripStartTime",
                                          label: "Start Time",
                                        },
                                      },
                                      [
                                        _c("time-picker", {
                                          attrs: {
                                            time: _vm.member
                                              .defaultTripStartTime,
                                            "all-time-options": false,
                                            "clear-none": true,
                                            disabled: !_vm.canUpdateMember,
                                          },
                                          on: {
                                            selectedTime: _vm.getStartTime,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-size-25",
                              },
                              [
                                _vm.isLoaded
                                  ? _c(
                                      "form-group",
                                      {
                                        staticClass: "timer-field",
                                        attrs: {
                                          name: "defaultTripEndTime",
                                          label: "End Time",
                                        },
                                      },
                                      [
                                        _c("time-picker", {
                                          attrs: {
                                            time: _vm.member.defaultTripEndTime,
                                            "all-time-options": false,
                                            disabled: !_vm.canUpdateMember,
                                          },
                                          on: { selectedTime: _vm.getEndTime },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-size-25",
                              },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "defaultStopDurationMinutes",
                                      label:
                                        "Default Stop Duration (in Minutes)",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      attrs: { type: "number" },
                                      model: {
                                        value:
                                          _vm.member.defaultStopDurationMinutes,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.member,
                                            "defaultStopDurationMinutes",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "member.defaultStopDurationMinutes",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-size-25",
                              },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "stopDurationMultiplier",
                                      label: "Stop Duration Multiplier",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      attrs: { type: "number" },
                                      model: {
                                        value:
                                          _vm.member.stopDurationMultiplier,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.member,
                                            "stopDurationMultiplier",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "member.stopDurationMultiplier",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c("google-autocomplete", {
                                  ref: "startaddress",
                                  attrs: {
                                    label: "Start Address",
                                    id: "startaddress",
                                    classname:
                                      "form-control autocomplete-input",
                                    placeholder: "",
                                    disabled: !_vm.canUpdateMember,
                                  },
                                  on: {
                                    placechanged: _vm.handleStartLocationChange,
                                  },
                                  model: {
                                    value: _vm.startLocation,
                                    callback: function ($$v) {
                                      _vm.startLocation = $$v
                                    },
                                    expression: "startLocation",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c("google-autocomplete", {
                                  ref: "endaddress",
                                  attrs: {
                                    label: "End Address",
                                    id: "endaddress",
                                    classname:
                                      "form-control autocomplete-input",
                                    placeholder: "",
                                    disabled: !_vm.canUpdateMember,
                                  },
                                  on: {
                                    placechanged: _vm.handleEndLocationChange,
                                  },
                                  model: {
                                    value: _vm.endLocation,
                                    callback: function ($$v) {
                                      _vm.endLocation = $$v
                                    },
                                    expression: "endLocation",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("payment-method-inputs", {
                          attrs: { detail: _vm.member },
                        }),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [_vm._v("Bio")]),
                                    _c("md-textarea", {
                                      attrs: { disabled: !_vm.canUpdateMember },
                                      model: {
                                        value: _vm.member.bio,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.member, "bio", $$v)
                                        },
                                        expression: "member.bio",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item custom-switch" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label-text" },
                                  [
                                    _vm._v(
                                      "\n                                            Is this user a driver?\n                                            "
                                    ),
                                    _c(
                                      "md-icon",
                                      { staticClass: "icon-info" },
                                      [_vm._v("info")]
                                    ),
                                    _c(
                                      "md-tooltip",
                                      {
                                        staticClass: "tooltip-width",
                                        attrs: { "md-direction": "right" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                                This setting is used to determine whether the user can be assigned\n                                                stops or shipments.\n                                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("md-switch", {
                                  model: {
                                    value: _vm.member.isDriver,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.member, "isDriver", $$v)
                                    },
                                    expression: "member.isDriver",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item custom-switch" },
                              [
                                _c("label", { staticClass: "label-text" }, [
                                  _vm._v(
                                    "Enable Stop/Shipment Offer Notifications"
                                  ),
                                ]),
                                _c("md-switch", {
                                  model: {
                                    value: _vm.member.canReceiveOffers,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.member,
                                        "canReceiveOffers",
                                        $$v
                                      )
                                    },
                                    expression: "member.canReceiveOffers",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item custom-switch" },
                              [
                                _c("label", { staticClass: "label-text" }, [
                                  _vm._v(
                                    "\n                                            Apply tax for invoicing?\n                                        "
                                  ),
                                ]),
                                _c("md-switch", {
                                  model: {
                                    value: _vm.member.shouldApplyTax,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.member,
                                        "shouldApplyTax",
                                        $$v
                                      )
                                    },
                                    expression: "member.shouldApplyTax",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-actions" },
                          [
                            _vm.canRequestDeviceLog(_vm.member.deviceType)
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-primary dialog-button pull-right",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleRequestDeviceLog(
                                          _vm.member.publicUserId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Request Device Logs\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.member.disabledDate === null &&
                            _vm.user.publicUserId !== _vm.member.publicUserId &&
                            _vm.canUpdateMember
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-default pull-right dialog-button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateTeamMemberStatus(
                                          _vm.member.publicUserId,
                                          "disable"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Disable Member\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.member.disabledDate != null &&
                            _vm.user.publicUserId != _vm.member.publicUserId &&
                            _vm.canUpdateMember
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-default pull-right dialog-button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateTeamMemberStatus(
                                          _vm.member.publicUserId,
                                          "enable"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Enable Member\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.member.isLockedOut &&
                            _vm.user.publicUserId != _vm.member.publicUserId &&
                            _vm.canUpdateMember
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-default pull-right dialog-button unlock-button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateTeamMemberStatus(
                                          _vm.member.publicUserId,
                                          "unlock"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Unlock Member\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.canUpdateMember
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-primary dialog-button pull-right",
                                    on: { click: _vm.validateForm },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Update Profile\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }