var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.invoiceDetails != null
    ? _c("div", { staticClass: "details-page" }, [
        _c("div", { staticClass: "md-layout" }, [
          _c("div", { staticClass: "md-layout-item" }, [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c("div", { staticClass: "custom-toolbar-start" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.invoiceDetails.invoiceNumber) +
                      "\n                    "
                  ),
                ]),
              ]),
              !_vm.$root.isMobileOnly
                ? _c("div", { staticClass: "custom-toolbar-end" })
                : _vm._e(),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "md-layout md-alignment-top-left content-layout-panel",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-100 md-xlarge-size-60",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-blue",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-icon" },
                              [_c("md-icon", [_vm._v("description")])],
                              1
                            ),
                          ]
                        ),
                        _c("md-card-content", [
                          _c("div", [
                            _vm.invoiceDetails.accountingSystemRef
                              ? _c("div", { staticClass: "details-box" }, [
                                  _c("label", [
                                    _vm._v("Accounting System Reference:"),
                                  ]),
                                  _c("span", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          target: "_blank",
                                          href:
                                            "https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=" +
                                            _vm.invoiceDetails
                                              .accountingSystemRef,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        Open invoice in " +
                                            _vm._s(
                                              _vm.invoiceDetails
                                                .accountingSystemName
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "details-box" }, [
                              _c("label", [_vm._v("PO Number")]),
                              _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.invoiceDetails.purchaseOrderNumber
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "details-box" }, [
                              _c("label", [_vm._v("Team Member")]),
                              _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.invoiceDetails.teamMember.fullName
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "details-box" }, [
                              _c("label", [_vm._v("Invoice Date")]),
                              _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.invoiceDetails.invoiceDate,
                                        _vm.DATE_TYPES.standardDate
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "details-box" }, [
                              _c("label", [_vm._v("Due Date")]),
                              _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.invoiceDetails.dueDate,
                                        _vm.DATE_TYPES.standardDate
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "details-box" }, [
                              _c("label", [_vm._v("Status")]),
                              _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.invoiceDetails.status) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100",
                  },
                  [
                    _c("invoice-lines", {
                      attrs: { lines: _vm.invoiceDetails.invoiceLines },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }