var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "details-page" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c("div", { staticClass: "md-layout-item" }, [
        _c("div", { staticClass: "custom-toolbar" }, [
          !_vm.$root.isMobileOnly
            ? _c("div", { staticClass: "custom-toolbar-end" }, [
                _c(
                  "div",
                  { staticClass: "header-button-container" },
                  [
                    _c("create-run-shipment-button", {
                      attrs: {
                        "run-schedule-id": _vm.$route.params.runScheduleId,
                        "run-schedule-customer-id": _vm.runDetails.customerId,
                      },
                      on: { runShipmentCreated: _vm.handleRunStop },
                    }),
                    _c("create-run-stop", {
                      attrs: {
                        "run-schedule-id": _vm.$route.params.runScheduleId,
                        "verify-pending-trip-exists-on-run-schedule":
                          _vm.verifyPendingTripExistsOnRunSchedule,
                        "run-schedule-customer-id": _vm.runDetails.customerId,
                      },
                      on: { runStopCreated: _vm.handleRunStop },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "header-button-container" },
                  [
                    _c(
                      "md-button",
                      {
                        attrs: { title: "Edit run schedule details" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleRunScheduleUpdate($event)
                          },
                        },
                      },
                      [_c("span", [_vm._v("Edit")])]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "md-layout md-alignment-top-left content-layout-panel" },
      [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-100 md-xlarge-size-100",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100",
              },
              [
                _c(
                  "md-card",
                  [
                    _c(
                      "md-card-header",
                      {
                        staticClass: "md-card-header-icon md-card-header-blue",
                      },
                      [
                        _vm.runDetails.assignedTo.photoUrl === null
                          ? _c(
                              "div",
                              { staticClass: "card-icon" },
                              [_c("md-icon", [_vm._v("description")])],
                              1
                            )
                          : _vm._e(),
                        _vm.runDetails.assignedTo.photoUrl !== null
                          ? _c(
                              "div",
                              { staticClass: "card-icon profile-image" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.runDetails.assignedTo.photoUrl,
                                    alt: "avatar",
                                  },
                                  on: { error: _vm.$_setDefaultBrokenImage },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c("md-card-content", [
                      _c(
                        "div",
                        { staticClass: "single-row three-column-box" },
                        [
                          _c(
                            "div",
                            { staticClass: "custom-md-field details-box" },
                            [
                              _c("div", { staticClass: "custom-label" }, [
                                _vm._v("Name"),
                              ]),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "#",
                                    title: "Edit run schedule details",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleRunScheduleUpdate($event)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.runDetails.name) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "details-box" }, [
                            _c("label", [_vm._v("Run Number")]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.runDetails.runNumber) +
                                  "\n                                "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "details-box" }, [
                            _c("label", [_vm._v("Schedule")]),
                            _c("span", { staticClass: "value" }, [
                              _vm.runDetails.schedule
                                ? _c(
                                    "span",
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.cronExpressionExplainer(
                                              _vm.runDetails
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                      _c(
                                        "md-tooltip",
                                        {
                                          staticClass: "tooltip-width",
                                          attrs: { "md-direction": "top" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm.runDetails
                                                  .humanReadableSchedule
                                              ) +
                                              "\n                                        "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.runDetails.scheduleType === "Fortnightly"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.runDetails.scheduleType) +
                                        " (" +
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            _vm.runDetails.activeFromDate,
                                            _vm.DATE_TYPES.standardDate
                                          )
                                        ) +
                                        ")\n                                    "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.runDetails.scheduleType) +
                                        "\n                                    "
                                    ),
                                  ]),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "single-row three-column-box" },
                        [
                          _c(
                            "div",
                            { staticClass: "custom-md-field details-box" },
                            [
                              _c("div", { staticClass: "custom-label" }, [
                                _vm._v("Driver"),
                              ]),
                              _vm.runDetails.assignedTo.publicUserId
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Team Member Profile",
                                          params: {
                                            publicUserId:
                                              _vm.runDetails.assignedTo
                                                .publicUserId,
                                          },
                                        },
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.runDetails.assignedTo.fullName
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  )
                                : _c("a", [_vm._v("Unassigned")]),
                            ],
                            1
                          ),
                          _vm.runDetails.customerName
                            ? _c("div", { staticClass: "details-box" }, [
                                _c("label", [_vm._v("Customer")]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.runDetails.customerName) +
                                      "\n                                "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c("div", { staticClass: "single-row-three-column" }, [
                        _c("div", { staticClass: "details-box" }, [
                          _c("label", [_vm._v("Start Time")]),
                          _c("span", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.convertTimeFormat(
                                    _vm.runDetails.startTime
                                  )
                                ) +
                                "\n                                "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "details-box" }, [
                          _c("label", [_vm._v("Start Location")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.runDetails.startAddress)),
                          ]),
                        ]),
                        _c("div", { staticClass: "details-box" }, [
                          _vm.runDetails.startLocationStopDurationMinutes !=
                          null
                            ? _c("label", [
                                _vm._v(
                                  "\n                                    Start Location Stop Duration (mins)\n                                "
                                ),
                              ])
                            : _vm._e(),
                          _vm.runDetails.startLocationStopDurationMinutes !=
                          null
                            ? _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.runDetails
                                        .startLocationStopDurationMinutes
                                    ) +
                                    "\n                                "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "single-row-three-column" }, [
                        _c("div", { staticClass: "details-box" }, [
                          _vm.runDetails.endTime != null
                            ? _c("label", [_vm._v("End Time")])
                            : _vm._e(),
                          _vm.runDetails.endTime != null
                            ? _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.convertTimeFormat(
                                        _vm.runDetails.endTime
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "details-box" }, [
                          _vm.runDetails.endAddress != null
                            ? _c("label", [_vm._v("End Location")])
                            : _vm._e(),
                          _vm.runDetails.endAddress != null
                            ? _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.runDetails.endAddress) +
                                    "\n                                "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "details-box" }, [
                          _vm.runDetails.endLocationStopDurationMinutes != null
                            ? _c("label", [
                                _vm._v(
                                  "\n                                    End Location Stop Duration (mins)\n                                "
                                ),
                              ])
                            : _vm._e(),
                          _vm.runDetails.endLocationStopDurationMinutes != null
                            ? _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.runDetails
                                        .endLocationStopDurationMinutes
                                    ) +
                                    "\n                                "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100",
              },
              [
                _c("run-schedule-stop-list-table", {
                  attrs: {
                    "run-details": _vm.runDetails,
                    "verify-pending-trip-exists-on-run-schedule":
                      _vm.verifyPendingTripExistsOnRunSchedule,
                  },
                  on: {
                    deleteRunStop: _vm.handleRunStop,
                    runStopUpdated: _vm.handleRunStop,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }