<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.customActions') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>offline_bolt</md-icon>
                    </div>
                </md-card-header>

                <md-card-content>
                    <div v-if="hasTeam">
                        <tabs
                            :tab-name="['Email', 'Others']"
                            color-button="success"
                            class="custom-tab-list"
                            :class="{
                                'asset-custom-fields-active': isAssetCustomFieldsActive
                            }"
                        >
                            <template slot="tab-pane-1">
                                <EmailCustomAction :event-trigger="selectedEventTrigger" />
                            </template>
                            <template slot="tab-pane-2">
                                <OtherCustomActions :event-trigger="selectedEventTrigger" />
                            </template>
                            <!-- <template slot="tab-pane-2">
                                <WebhookCustomAction :event-trigger="selectedEventTrigger" />
                            </template>
                            <template slot="tab-pane-3">
                                <SmsCustomAction />
                            </template> -->
                        </tabs>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TEAM_CUSTOM_ACTION_DEFAULTS } from '@/utils/defaults';
import { Tabs } from '@/components';
import CreateEmailAction from './CreateEmailAction';

export default {
    components: {
        // we'll put it back when webhook/sms event trigger type is added
        Tabs,
        EmailCustomAction: () => import('./EmailCustomAction'),
        OtherCustomActions: () => import('./OtherCustomActions')
        // SmsCustomAction: () => import('./SmsCustomAction'),
        // WebhookCustomAction: () => import('./WebhookCustomAction')
    },
    data() {
        return {
            showSms: false,
            showWebhook: false,
            activeTab: 'Email',
            selectedEventTrigger: null,
            isAssetCustomFieldsActive: false
        };
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam'
        })
    },
    methods: {
        handleTabChanged(tab) {
            this.isAssetCustomFieldsActive = tab === 'Others';
        },
        handleAddCustomAction() {
            const customAction = { ...TEAM_CUSTOM_ACTION_DEFAULTS() };
            customAction.action = this.activeTab;

            this.$modal
                .show(CreateEmailAction, {
                    customActionModel: customAction
                })
                .then(({ result, model }) => {
                    if (result === 'ok') {
                        this.$modal.hide();
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.event-trigger {
    margin-top: 20px;
    margin-right: 10px;
    width: 25%;
    display: inline-block;

    input {
        width: 100%;
    }
}
@media all and (max-width: 450px) {
    .event-trigger {
        width: 95%;
    }
}
.card-icon {
    background: #b618dd !important;
}

::v-deep .custom-tab-list {
    .nav-tabs {
        width: calc(100% - 55px);
        flex-wrap: wrap;
        li {
            border-bottom: 0px;
        }
    }
    &.asset-custom-fields-active {
        .nav-tabs {
            width: calc(100% - 225px);
        }
    }
}
</style>
