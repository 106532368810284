<template>
    <div class="">
        <div class="mobile-filter-header">
            <div class="md-layout">
                <div class="md-layout-item">
                    <div class="datepicker-container">
                        <md-datepicker
                            v-model="selectedDate"
                            md-immediately
                            :md-debounce="100"
                            ref="mdDatePicker"
                            :md-model-type="String"
                        ></md-datepicker>
                        <span class="heavy-font-600">{{ this.selectedDate | dateFormat('ddd D MMM') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item">
                <div v-if="!loading">
                    <div v-if="list.length > 0">
                        <md-list
                            :md-expand-single="expandSingle"
                            class="trip-list-body"
                            v-for="(tripItem, tripIndex) in list"
                            :key="tripIndex"
                        >
                            <md-list-item md-expand :md-ripple="false" :md-expanded.sync="tripItem.isOpen">
                                <div
                                    class="trip-accordion-header"
                                    :class="tripItem.isOpen ? 'trip-open' : ''"
                                    @click.stop="toggleTripItem(tripItem.user.publicUserId, tripItem.tripId)"
                                >
                                    <div>
                                        <div class="member-name custom-ellipsis">
                                            {{ tripItem.user.fullName }}
                                        </div>

                                        <div>
                                            <span>
                                                {{
                                                    `${tripItem.completedStops} of ${
                                                        tripItem.totalStops
                                                    } stops completed`
                                                }}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="view-map">
                                        <span class="view-map">
                                            <router-link
                                                :to="{
                                                    name: 'Maps Overview Active User Panel',
                                                    params: {
                                                        date: selectedDate,
                                                        userId: tripItem.user.publicUserId
                                                    }
                                                }"
                                                target="_blank"
                                            >
                                                <md-icon>map</md-icon>
                                            </router-link>
                                            <md-tooltip md-direction="left">View stops in a map</md-tooltip>
                                        </span>
                                    </div>
                                </div>

                                <md-list slot="md-expand" class="stop-table-body">
                                    <div
                                        v-if="tripItem.stopList.length > 0"
                                        v-for="(stopItem, stopIndex) in tripItem.stopList"
                                        :key="stopIndex"
                                        class="stop-expand-container"
                                    >
                                        <div class="stop-contact-address">
                                            <div>
                                                <div class="marker-reference-container">
                                                    <div class="location-marker">
                                                        <span><location-marker :icon-name="stopIndex + 1" /></span>
                                                    </div>
                                                    <div>
                                                        <span
                                                            class="name custom-ellipsis weight-600"
                                                            v-if="
                                                                stopItem.contact != null &&
                                                                    stopItem.contact.name != null
                                                            "
                                                        >
                                                            {{ stopItem.contact != null ? stopItem.contact.name : '' }}
                                                        </span>
                                                        <span class="stop-ref">{{ stopItem.stopRef }}</span>
                                                    </div>
                                                </div>
                                                <div class="stop-address weight-600">
                                                    <span class="custom-ellipsis">{{ stopItem.address }}</span>
                                                </div>
                                                <div class="stop-status">
                                                    <div :class="`status-${stopItem.status.toLowerCase()}`">
                                                        {{ stopItem.status }}
                                                    </div>
                                                    <div>
                                                        <span>ETA:</span>
                                                        {{ stopItem.originalEta | timeFormat(DATE_TYPES.standardTime) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        :class="tripItem.isStopListLoading ? 'loader-container' : ''"
                                        class="empty-table"
                                    >
                                        <div class="table-loader" v-if="tripItem.isStopListLoading">
                                            <fade-loader :loading="true" color="#333333" />
                                            <span>LOADING</span>
                                        </div>
                                        <div v-else-if="tripItem.stopList.length == 0">
                                            <span>No stops available for this trip</span>
                                        </div>
                                    </div>
                                </md-list>
                            </md-list-item>
                        </md-list>
                    </div>
                    <div v-else>
                        <span class="empty-list">No trips available for this day</span>
                    </div>
                </div>
                <div v-else>
                    <div class="stopbar-loader" v-if="!isLoading">
                        <fade-loader :loading="true" color="#333333" />
                        <span>LOADING</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import moment from 'moment';
import { DATE_TYPES } from '@/utils/constants';
import { mapGetters } from 'vuex';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { handleRequests, showErrorMessage } from '@/helpers';
import { LocationMarker } from '@/components';

export default {
    name: 'MobileTripOverview',
    mixins: [GeneralMixin],
    components: { FadeLoader, LocationMarker },
    data() {
        return {
            showFilter: false,
            selectedDate: this.$route.params.date
                ? this.$route.params.date
                : moment().format(DATE_TYPES.internationalDate),
            selectedTeamMember: {
                name: '',
                publicUserId: null
            },
            selectedStatus: 'All',
            searchStopList: [],
            searchText: '',
            loading: false,
            list: [],
            teamMembers: [],
            today: moment().format(DATE_TYPES.internationalDate),
            expandSingle: false
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isLoading: 'isLoading',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        })
    },
    watch: {
        selectedDate(newValue) {
            this.$router.replace({ path: `/trips/list/${newValue}` });
            this.$nextTick(async () => {
                this.list = [];
                this.$_handleLoaderState(true);
                await this.getTripList();
                this.$_handleLoaderState(false);
            });
        },
        isListLoading(newValue) {
            this.loading = newValue;
        },
        showFilter(newValue) {
            if (!newValue) {
                this.searchText = '';
            }
        },
        '$route.query': function({ userId }) {
            this.getMemberDetails(userId);
        }
    },
    mounted() {
        this.getTripList();
    },
    methods: {
        async getTripList() {
            const api = `/api/trips/${this.selectedDate}/list`;

            try {
                const { data } = await handleRequests(api);
                data.forEach((trip) => {
                    const existingTrip = this.getTripDetails(trip.tripId);
                    if (existingTrip != null) {
                        Object.assign(existingTrip, trip);
                    } else {
                        trip.stopList = [];
                        trip.isOpen = false;
                        trip.isShowAlert = false;
                        trip.isReorderedAlert = true;
                        trip.isSortable = true;
                        trip.isStopListLoading = false;
                        this.list.push(trip);
                    }
                });
            } catch (error) {
                const message = 'Error in loading the trip list';
                showErrorMessage(message, error);
            }
        },
        getTripDetails(tripId) {
            let trip = null;
            if (this.list.length > 0) {
                trip = this.list.find((x) => x.tripId === tripId);
            }
            return trip;
        },
        toggleTripItem(userId, tripId) {
            const trip = this.getTripDetails(tripId);

            trip.isOpen = !trip.isOpen;

            if (trip.isOpen) {
                this.getStopList(trip.user.publicUserId, trip.tripId, false);
            }
        },
        async getStopList(userId, tripId, retryUntilRoutingComplete = false, showLoader = true) {
            const trip = this.getTripDetails(tripId);

            trip.isSortable = true;
            trip.isShowAlert = false;

            if (showLoader) {
                trip.isStopListLoading = true;
                trip.stopList = [];
            }

            const api = `/api/trips/${
                this.selectedDate
            }?userId=${userId}&retryUntilRoutingComplete=${retryUntilRoutingComplete}`;

            try {
                const {
                    data: { data }
                } = await handleRequests(api);
                trip.stopList = data.stopList;

                trip.totalStops = trip.stopList.filter((x) => x.status !== 'Cancelled').length;
                trip.completedStops = trip.stopList.filter((x) => x.status === 'Complete').length;
            } catch (error) {
                const message = 'Error in loading the trip list';
                showErrorMessage(message, error);
            }
            trip.isStopListLoading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.trip-accordion-header {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    position: relative;
    z-index: 10;
    > div:first-child {
        width: 70%;
    }
    > div:last-child {
        width: 30%;
    }
    .member-name {
        margin-bottom: 5px;
        font-weight: 600;
    }
    .view-map {
        text-align: center;
        a {
            display: inline-block;
        }
    }
}

.trip-list-body {
    ::v-deep .md-list-item-expand {
        border: none;
    }
    ::v-deep .md-list-item-content {
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
        border-radius: 0;
    }
    ::v-deep .md-list-item-content:hover {
        background-color: #fff !important;
    }
    ::v-deep .md-list-item {
        margin-left: 0;
        z-index: 0;
    }

    ::v-deep .md-list-item .md-list-item-container .md-ripple .md-list-expand-icon {
        position: absolute;
        z-index: 1;
        right: 0;
    }
}

.stop-expand-container {
    border-bottom: 1px solid #eee;
    padding: 20px 10px;
    .stop-order {
        margin-right: 10px;
        margin-bottom: 5px;
        span {
            font-weight: 600;
            max-width: 84%;
        }
    }
    .stop-status {
        > div {
            display: inline-block;
            padding: 5px;
            text-transform: uppercase;
        }
        > div:first-child {
            font-size: 12px;
            width: 90px;
            text-align: center;
            margin-right: 10px;
            color: #fff;
        }
    }
    .stop-contact-address {
        .marker-reference-container {
            > div {
                width: calc(100% - 30px);
                display: inline-block;
                vertical-align: middle;
                margin-top: -8px;
            }
            .location-marker {
                width: 30px;
                margin-top: 0px;
            }
        }

        .stop-address {
            margin-bottom: 5px;
            margin-top: -5px;
        }

        .name {
            max-width: 150px;
            width: initial;
            margin-right: 5px;
        }

        @media (min-width: 425px) {
            .stop-ref {
                display: inline-block;
            }
            .name {
                vertical-align: bottom;
                display: inline-block;
            }
            .planned-order {
                display: initial;
            }
        }
    }
}

.loader-container {
    min-height: 200px;
}
.empty-list {
    margin-top: 20px;
    display: block;
}
</style>
