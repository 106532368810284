<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <!-- filter by type -->
                    </div>
                    <div class="custom-toolbar-end">
                        <div>
                            <!-- search -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>schedule</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div v-if="!isListLoading">
                            <md-table class="context-menu-support" v-if="maintenanceScheduleList.length > 0">
                                <md-table-row>
                                    <md-table-head></md-table-head>
                                    <md-table-head>Upcoming Maintenance</md-table-head>
                                    <md-table-head>Asset Name</md-table-head>
                                    <md-table-head>Reminder Type</md-table-head>
                                    <md-table-head>Status</md-table-head>
                                    <md-table-head>Actions</md-table-head>
                                </md-table-row>

                                <md-table-row
                                    v-for="(item, index) in maintenanceScheduleList"
                                    :key="index"
                                    :class="`status-row-${item.status.replace(/\s/g, '').toLowerCase()}`"
                                >
                                    <md-table-cell class="status-icon-column">
                                        <md-icon
                                            :class="`status-${item.status.replace(/\s/g, '').toLowerCase()}`"
                                            v-if="item.status.replace(/\s/g, '').toLowerCase() == 'duesoon'"
                                        >
                                            report_problem
                                            <md-tooltip md-direction="left">{{ item.status }}</md-tooltip>
                                        </md-icon>

                                        <md-icon
                                            :class="`status-${item.status.replace(/\s/g, '').toLowerCase()}`"
                                            v-if="item.status.replace(/\s/g, '').toLowerCase() == 'overdue'"
                                        >
                                            report
                                            <md-tooltip md-direction="left">{{ item.status }}</md-tooltip>
                                        </md-icon>
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ item.maintenanceName }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        <router-link
                                            class="a-link"
                                            :to="{
                                                name: 'Asset Details',
                                                params: { assetId: item.assetId }
                                            }"
                                            target="_blank"
                                        >
                                            {{ item.assetName }}
                                        </router-link>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <reminder-indicator
                                            :maintenance-data="item"
                                            :current-engine-hours="item.estimatedEngineHourValue"
                                            :next-engine-hour-due="
                                                item.nextDueEngineHours != null ? item.nextDueEngineHours : null
                                            "
                                            :next-due-distance="
                                                item.nextDueOdometer != null ? item.nextDueOdometer : null
                                            "
                                            :current-distance-reading="item.estimatedOdometerValue"
                                            :repeat-interval="1"
                                            :next-due-date="item.nextDueServiceDate"
                                            :key="counter"
                                        />
                                    </md-table-cell>
                                    <md-table-cell class="maintenance-status-container">
                                        <span
                                            class="custom-badge generic-badge"
                                            :class="`${item.status.replace(/\s/g, '').toLowerCase()}-badge`"
                                        >
                                            {{ item.status }}
                                        </span>
                                    </md-table-cell>
                                    <md-table-cell class="action-buttons">
                                        <complete-maintenance-schedule-button
                                            :maintenance-data="item"
                                            :asset-data="item.asset"
                                            @maintenanceCompleted="handleCompleteSchedule"
                                        />
                                        <update-maintenance-schedule-button
                                            :maintenance-data="item"
                                            :asset-data="item.asset"
                                            @maintenanceCompleted="handleCompleteSchedule"
                                        />
                                        <remove-maintenance-schedule-button
                                            :maintenance-data="item"
                                            :asset-data="item.asset"
                                            @maintenanceCompleted="handleCompleteSchedule"
                                        />
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <div v-if="maintenanceScheduleList.length == 0 && !isListLoading">
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                        <div v-else>
                            <div class="custom-loader">
                                <fade-loader :loading="isListLoading" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <md-card-actions class="page-footer" md-alignment="space-between">
            <div>
                <p v-if="total === pagination.perPage" class="card-category">
                    Page {{ pagination.currentPage }} of many
                </p>
                <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
            </div>
            <pagination
                v-model="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                @change-page="handleChangePage($event, pagination.perPage)"
            />
        </md-card-actions>
        <transition name="slide">
            <maintenance-schedule-sidebar v-if="showSidebar" @closeModal="toggleSidebar" :sidebar-data="sidebarData" />
        </transition>
    </div>
</template>

<script>
import { Pagination } from '@/components';
import { handleRequests } from '@/helpers';
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import MaintenanceScheduleSidebar from './MaintenanceScheduleSidebar';
// import CreateMaintenanceScheduleWizard from './modals/CreateMaintenanceScheduleWizard';
import ReminderIndicator from './components/ReminderIndicator';
import {
    RemoveMaintenanceScheduleButton,
    CompleteMaintenanceScheduleButton,
    UpdateMaintenanceScheduleButton
} from './buttons';

export default {
    name: 'UpcomingMaintenanceSchedule',
    mixins: [GeneralMixin],
    data() {
        return {
            isListLoading: false,
            showSidebar: false,
            maintenanceScheduleList: [],
            pagination: PAGINATION_DEFAULTS,
            sidebarData: null,
            counter: 0
        };
    },
    components: {
        MaintenanceScheduleSidebar,
        ReminderIndicator,
        CompleteMaintenanceScheduleButton,
        RemoveMaintenanceScheduleButton,
        UpdateMaintenanceScheduleButton,
        FadeLoader,
        Pagination
    },
    async mounted() {
        const {
            upcomingMaintenanceSchedules,
            totalUpcomingMaintenanceSchedules
        } = await this.fetchMaintenanceScheduleList();
        this.setupDataList(
            upcomingMaintenanceSchedules,
            totalUpcomingMaintenanceSchedules || upcomingMaintenanceSchedules.length
        );
    },
    computed: {
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        }
    },
    methods: {
        async handleCompleteSchedule() {
            const {
                upcomingMaintenanceSchedules,
                totalUpcomingMaintenanceSchedules
            } = await this.fetchMaintenanceScheduleList();
            this.setupDataList(upcomingMaintenanceSchedules, totalUpcomingMaintenanceSchedules);
            this.counter = this.counter + 1;
        },
        async fetchMaintenanceScheduleList(pageNumber = 1, itemsPerPage = 50) {
            this.isListLoading = true;
            const endpoint = `/api/upcoming-maintenance-schedules`;
            const response = await handleRequests(endpoint, {
                params: {
                    pageNumber,
                    itemsPerPage
                }
            });
            this.isListLoading = false;
            return response.data;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.$_handleLoaderState(true);
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            const {
                upcomingMaintenanceSchedules,
                totalUpcomingMaintenanceSchedules
            } = await this.fetchMaintenanceScheduleList(currentPage, perPage);
            const maintenanceToSetup = totalUpcomingMaintenanceSchedules || upcomingMaintenanceSchedules.length;
            this.setupDataList(upcomingMaintenanceSchedules, maintenanceToSetup);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            this.$_handleLoaderState(false);
        },
        setupDataList(data, total) {
            this.maintenanceScheduleList = data;
            this.pagination.total = total;
        },
        showSidebarDetails(item) {
            this.showSidebar = true;
            this.sidebarData = Object.assign({}, {});
            this.sidebarData = Object.assign({}, item);
            if (item.notificationConfiguration != null) {
                this.sidebarData.notificationConfiguration = JSON.parse(item.notificationConfiguration);
            }
        },
        toggleSidebar() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown) 
                this.showSidebar = !this.showSidebar;
        }
        // createMaintenanceSchedule() {
        //     this.$modal.show(CreateMaintenanceScheduleWizard).then((response) => {
        //         if (response) {
        //             this.$modal.hide();
        //         }
        //     });
        // }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

.status {
    color: #fff !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 90px;
}

.custom-type-icon {
    margin-right: 5px;
    border-radius: 50%;
    padding: 2px;
    vertical-align: middle;
}

::v-deep .header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.status-overdue {
    color: red !important;
}

.status-duesoon {
    color: orange !important;
}

.status-bar {
    width: 5px;
    color: red;
    display: block;
    height: 16px;
    background-color: red;
    text-align: right;
    margin-right: 0;
    margin: 0 auto;
    margin-left: 100%;
}

.status-icon-column {
    width: 45px;
}

.overdue-badge {
    background-color: red !important;
    color: #fff;
}

.duesoon-badge {
    background-color: orange !important;
    color: #fff;
}

.upcoming-badge {
    background-color: grey !important;
    color: #fff;
}

.action-buttons {
    ::v-deep .inline-button {
        margin-right: 5px;
    }

    ::v-deep .inline-button:last-child {
        margin-right: 0;
    }
}

.a-link {
    color: rgba(0, 0, 0, 0.87) !important;
}
.a-link:hover {
    color: #1286ff !important;
    cursor: pointer;
}
</style>
