<template>
    <md-card
        class="md-card-tabs"
        :class="[{ 'flex-column': flexColumn }, { 'nav-pills-icons': navPillsIcons }, { 'md-card-plain': plain }]"
    >
        <md-card-header>
            <slot name="header-title" />
        </md-card-header>
        <md-card-content>
            <md-list class="nav-tabs" v-if="!hideTabHeader">
                <md-list-item
                    v-for="(item, index) in tabName"
                    :key="item"
                    :class="[
                        { active: isActiveTab(item) ? isActivePanel(tabName[index]) : 'false' },
                        {
                            [getColorButton(colorButton)]: isActivePanel(tabName[index])
                        },
                        { hideTab: badgeLength.length > 0 && hideTab(badgeLength[index]) }
                    ]"
                    @click.stop="isActiveTab(item) ? switchPanel(tabName[index]) : ''"
                >
                    {{ tabName[index] }}
                    <md-badge
                        :md-content="badgeLength[index]"
                        v-if="badgeLength.length > 0 && badgeLength[index] > 0"
                        class="tab-custom-badge"
                    ></md-badge>
                    <md-icon v-if="navPillsIcons">{{ tabIcon[index] }}</md-icon>
                </md-list-item>
            </md-list>

            <transition name="fade" mode="out-in">
                <div class="tab-content">
                    <template v-for="(item, tabindex) in tabName">
                        <template v-if="isActivePanel(tabName[tabindex])">
                            <div :key="tabindex" :class="getTabContent(tabindex + 1)">
                                <slot :name="getTabContent(tabindex + 1)">This is the default text!</slot>
                            </div>
                        </template>
                    </template>
                </div>
            </transition>
        </md-card-content>
    </md-card>
</template>

<script>
export default {
    props: {
        flexColumn: Boolean,
        navPillsIcons: Boolean,
        plain: Boolean,
        isLoading: {
            type: Boolean,
            default: false
        },
        tabName: {
            type: Array,
            default: () => []
        },
        tabIcon: {
            type: Array,
            default: () => []
        },
        colorButton: {
            type: String,
            default: ''
        },
        selectedPanel: {
            type: String,
            default: ''
        },
        hideTabHeader: {
            type: Boolean,
            default: false
        },
        badgeLength: {
            type: Array,
            default: () => []
        },
        hideEmptyTabs: {
            type: Boolean,
            default: false
        },
        activeTabs: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            activePanel: this.selectedPanel || this.tabName[0],
            currentActiveTabs: []
        };
    },
    watch: {
        selectedPanel(value) {
            this.activePanel = value;
        }
    },
    methods: {
        switchPanel(panel) {
            if (this.isLoading) 
                return;
            this.activePanel = panel;
            this.$emit('switch-panel', panel);
        },
        isActivePanel(panel) {
            return this.activePanel === panel;
        },
        getColorButton(colorButton) {
            return `md-${colorButton}`;
        },
        getTabContent(index) {
            return `tab-pane-${index}`;
        },
        hideTab(dataLength) {
            if (!this.hideEmptyTabs || dataLength == null) 
                return false;

            if (dataLength === 0) 
                return true;

            return false;
        },
        isActiveTab(tabName) {
            if (this.activeTabs === null || this.activeTabs.length === 0) {
                this.currentActiveTabs = this.tabName;
            } else {
                this.currentActiveTabs = this.activeTabs;
            }

            if (this.currentActiveTabs.includes(tabName)) {
                return true;
            }

            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.tab-custom-badge {
    margin-left: 5px;
}

.hideTab {
    display: none;
}
</style>
