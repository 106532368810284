<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
            <div class="card-icon">
                <md-icon>description</md-icon>
            </div>
        </md-card-header>

        <md-card-content>
            <md-table class="custom-paginated-table">
                <!-- TODO: Make header sticky -->
                <md-table-row slot="header">
                    <md-table-head class="stop-order"></md-table-head>
                    <md-table-head class="stop-reference">Stop Reference</md-table-head>
                    <md-table-head class="stop-contact">Contact</md-table-head>
                    <md-table-head class="stop-address">Address</md-table-head>
                    <md-table-head class="stop-status">Status</md-table-head>
                    <md-table-head class="stop-time" v-if="tripDetails.assignedTo.publicUserId != null">
                        ETA/Arrival
                    </md-table-head>
                </md-table-row>

                <md-table-row
                    v-for="(stopItem, stopIndex) in tripDetails.stopList"
                    :key="stopIndex"
                    :class="stopItem.isStartStop || stopItem.isEndStop ? 'startend-table-row' : ''"
                >
                    <md-table-cell class="stop-order actual-stop-order">
                        <md-icon v-if="stopItem.isStartStop">
                            pin_drop
                        </md-icon>
                        <md-icon v-else-if="stopItem.isEndStop">
                            flag
                        </md-icon>
                        <span v-else-if="tripDetails.hasRuns">{{ stopItem.index }}</span>
                        <span v-else>{{ stopItem.order != null ? stopItem.order : '' }}</span>
                    </md-table-cell>
                    <md-table-cell class="stop-reference">
                        <router-link
                            :to="{
                                name: 'Stop Details',
                                params: { stopId: stopItem.stopId }
                            }"
                            target="_blank"
                        >
                            {{ stopItem.stopRef }}
                        </router-link>
                    </md-table-cell>
                    <md-table-cell class="stop-contact">
                        <span class="custom-ellipsis">
                            {{ stopItem.contact != null ? stopItem.contact.name : '' }}
                        </span>
                    </md-table-cell>
                    <md-table-cell class="stop-address">
                        <span class="custom-ellipsis">{{ $_displayAddressName(stopItem) }}</span>
                    </md-table-cell>
                    <md-table-cell class="stop-status">
                        <span
                            v-if="!stopItem.isStartStop && !stopItem.isEndStop"
                            class="status"
                            :class="`status-${stopItem.status.toLowerCase().replace(/\s+/g, '')}`"
                        >
                            {{ stopItem.status }}
                        </span>
                        <!-- FIXME: Support status update inside the trip details page, temporarily disabling this as having issues with
                                   the table not overflowing even with the context-menu-support class added  -->
                        <!-- <stop-status-button
                            v-if="!stopItem.isStartStop && !stopItem.isEndStop"
                            :stop="stopItem"
                            @statusUpdated="onChangedStopStatus"
                        /> -->
                    </md-table-cell>
                    <md-table-cell class="stop-time" v-if="tripDetails.assignedTo.publicUserId != null">
                        <div v-if="stopItem.status != 'Cancelled' && stopItem.status != 'On Hold'">
                            <span v-if="stopItem.actualDate" class="bold-time">
                                {{ stopItem.actualDate | timeFormat(DATE_TYPES.standardTime) }}
                            </span>
                            <span
                                v-else-if="
                                    stopItem.status == 'Arrived' ||
                                        stopItem.status == 'Complete' ||
                                        stopItem.status == 'Failed'
                                "
                                class="bold-time"
                            >
                                {{ stopItem.arrivalDate | timeFormat(DATE_TYPES.standardTime) }}
                            </span>
                            <span v-else>
                                <span v-if="stopItem.originalEta">
                                    {{ stopItem.originalEta | timeFormat(DATE_TYPES.standardTime) }}
                                </span>
                            </span>
                        </div>
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </md-card-content>
    </md-card>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
// import { StopStatusButton } from '@/pages/Stops/buttons';

export default {
    name: 'StopListTable',
    mixins: [GeneralMixin],
    // components: {
    //     StopStatusButton
    // },
    props: {
        tripDetails: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        // onChangedStopStatus(response){
        //     this.$emit('statusUpdate');
        // }
    }
};
</script>

<style lang="scss" scoped>
.stop-address,
.stop-contact {
    max-width: 200px;
}

.status {
    color: #fff !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 90px;
}

.custom-paginated-table {
    > ::v-deep div {
        overflow: auto;
        max-height: 400px;
    }
    ::v-deep th {
        position: sticky;
        top: 0;
    }
}

.startend-table-row {
    background-color: #fafafa;
}

@media (min-width: 1904px) {
    .custom-paginated-table {
        > ::v-deep div {
            overflow: auto;
            max-height: 600px;
        }
    }
}

.bold-time {
    font-weight: 500;
}
</style>
