var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "md-card-content",
        [
          _c(
            "md-table",
            { staticClass: "table-hover", attrs: { value: _vm.list } },
            [
              _c(
                "md-table-row",
                [
                  _c("md-table-head", [_vm._v("Team Region")]),
                  _c("md-table-head", [_vm._v("User")]),
                  _c("md-table-head", [_vm._v("Company")]),
                  _c("md-table-head", [_vm._v("# Stops")]),
                  _c("md-table-head", [_vm._v("Sub Total")]),
                  _c("md-table-head", [_vm._v("Total Tax")]),
                  _c("md-table-head", [_vm._v("Total")]),
                  _c("md-table-head", [_vm._v("Deductions")]),
                ],
                1
              ),
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "md-table-row",
                  { key: index },
                  [
                    _c("md-table-cell", [_vm._v(_vm._s(item.region))]),
                    _c("md-table-cell", [_vm._v(_vm._s(item.userName))]),
                    _c("md-table-cell", [_vm._v(_vm._s(item.company))]),
                    _c("md-table-cell", [_vm._v(_vm._s(item.numberOfStops))]),
                    _c("md-table-cell", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(item.subTotal, _vm.invoiceCurrency)
                        )
                      ),
                    ]),
                    _c("md-table-cell", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(item.totalTax, _vm.invoiceCurrency)
                        )
                      ),
                    ]),
                    _c("md-table-cell", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(item.total, _vm.invoiceCurrency)
                        )
                      ),
                    ]),
                    _c("md-table-cell", { staticClass: "deductions-font" }, [
                      _vm._v(
                        "\n                    (" +
                          _vm._s(
                            _vm._f("currency")(
                              item.deduction,
                              _vm.invoiceCurrency
                            )
                          ) +
                          ")\n                "
                      ),
                    ]),
                  ],
                  1
                )
              }),
              _c(
                "md-table-row",
                [
                  _c("md-table-cell", { attrs: { colspan: "3" } }),
                  _c("md-table-cell", { staticClass: "total-style" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.totalnumberofStops) +
                        "\n                "
                    ),
                  ]),
                  _c("md-table-cell", { staticClass: "total-style" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("currency")(
                            _vm.totalSubTotal,
                            _vm.invoiceCurrency
                          )
                        ) +
                        "\n                "
                    ),
                  ]),
                  _c("md-table-cell", { staticClass: "total-style" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("currency")(
                            _vm.totalTotalTax,
                            _vm.invoiceCurrency
                          )
                        ) +
                        "\n                "
                    ),
                  ]),
                  _c("md-table-cell", { staticClass: "total-style" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("currency")(
                            _vm.totalTotal,
                            _vm.invoiceCurrency
                          )
                        ) +
                        "\n                "
                    ),
                  ]),
                  _c(
                    "md-table-cell",
                    { staticClass: "total-style deductions-font" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.totalDeduction,
                              _vm.invoiceCurrency
                            )
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }