var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPreview
    ? _c(
        "div",
        _vm._l(_vm.list, function (item, index) {
          return _c("div", { key: index, staticClass: "reminder-indicators" }, [
            _c("div", [
              item.time != null
                ? _c("div", [
                    _c("span", { staticClass: "material-icons" }, [
                      _vm._v("today"),
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            item.time.due,
                            _vm.DATE_TYPES.standardDate
                          )
                        ) +
                        "\n                "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "due-remaining",
                        class:
                          item.time.status != null
                            ? "status-" + item.time.status
                            : "",
                      },
                      [
                        _vm._v(
                          "\n                    (" +
                            _vm._s(item.time.remaining) +
                            ")\n                "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              item.engineHours != null
                ? _c("div", [
                    _c("span", { staticClass: "material-icons" }, [
                      _vm._v("hourglass_full"),
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(item.engineHours.due) +
                        " hours\n                "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "due-remaining",
                        class:
                          item.engineHours.status != null
                            ? "status-" + item.engineHours.status
                            : "",
                      },
                      [
                        _vm._v(
                          "\n                    (" +
                            _vm._s(item.engineHours.remaining) +
                            ")\n                "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              item.distance != null
                ? _c("div", [
                    _c("span", { staticClass: "material-icons" }, [
                      _vm._v("edit_road"),
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(item.distance.due) +
                        " " +
                        _vm._s(_vm.distanceUnit) +
                        "\n                "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "due-remaining",
                        class:
                          item.distance.status != null
                            ? "status-" + item.distance.status
                            : "",
                      },
                      [
                        _vm._v(
                          "\n                    (" +
                            _vm._s(item.distance.remaining) +
                            ")\n                "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }