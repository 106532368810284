<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <div class="custom-toolbar md-layout">
                    <div class="md-layout-item custom-toolbar-start md-size-75">
                        <team-members-filter @onSelection="onTeamMemberSelection" :emit-on-clear="true" />
                        <date-range-filter
                            :is-single-date="false"
                            :max-pediod-in-months="selectedPeriod === 'day' ? 2 : 24"
                            @onChanged="onDateChanged"
                        />
                    </div>
                    <div class="md-layout-item custom-toolbar-end">
                        <md-field v-if="selectedTeamMember.isTracker !== true && getPeriod === 'day'">
                            <vue-select
                                placeholder="Highlight Discrepancies"
                                :options="discrepancyComboValues"
                                :searchable="$root.isDesktop"
                                v-model="selectedDiscrepancy"
                            ></vue-select>
                        </md-field>

                        <md-button
                            title="Export Travel Statistics"
                            class="md-primary md-just-icon md-round header-button"
                            @click="onExportToExcel"
                        >
                            <md-icon>get_app</md-icon>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>summarize</md-icon>
                        </div>
                    </md-card-header>

                    <div class="aggregate-options">
                        <ButtonGroup
                            v-if="this.selectedTeamMember.userId"
                            selected-class-name="md-info"
                            :allow-no-selection="false"
                            v-model="selectedPeriod"
                        >
                            <option value="day">day</option>
                            <option value="week">week</option>
                            <option value="month">month</option>
                            <option value="quarter">quarter</option>
                        </ButtonGroup>
                    </div>

                    <md-card-content>
                        <div v-if="!distancesList || distancesList.length == 0">
                            <h3>Travel Statistics</h3>
                            <p>
                                No results to display.
                            </p>
                        </div>
                        <div v-else>
                            <div class="report-container">
                                <distance-report-details
                                    :distances-list="distancesList"
                                    :public-user-id="selectedTeamMember.userId"
                                    :is-tracker-data="selectedTeamMember.isTracker"
                                    :discrepancy="getDescepancy"
                                    :period="getPeriod"
                                />
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin: 18px 0px 0px 24px;
}

.aggregate-options {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.alert-z {
    z-index: 0 !important;
}

.period-filter {
    min-width: 115px;
    width: 120px !important;
}

::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}
</style>
<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import { ButtonGroup } from '@/components';
import DistanceReportDetails from './DistanceReportDetails';
import { handleRequests, downloadFile } from '../../helpers';
import { TeamMembersFilter, DateRangeFilter } from './components';

export default {
    name: 'Reports',
    components: { DistanceReportDetails, TeamMembersFilter, DateRangeFilter, ButtonGroup },
    mixins: [GeneralMixin],
    data() {
        return {
            isLoading: true,
            startDate: null,
            endDate: null,
            // periodTypes: ['day', 'week', 'month'],
            selectedPeriod: 'day',
            distancesList: [],
            userFullName: '',
            selectedTeamMember: {},
            smallAlertModalHide: false,
            selectedDiscrepancy: null
        };
    },
    watch: {
        startDate(newVal, oldVal) {
            if (moment(newVal).valueOf() !== moment(oldVal).valueOf()) {
                if (moment(newVal) > moment(this.endDate)) {
                    this.$notify({
                        message: 'Start date must not be after the end date.',
                        type: 'danger'
                    });

                    this.startDate = this.endDate;
                    return;
                }
                this.getDistanceTraveledList();
            }
        },
        endDate(newVal, oldVal) {
            if (moment(newVal).valueOf() !== moment(oldVal).valueOf()) {
                if (moment(newVal) < moment(this.startDate)) {
                    this.$notify({
                        message: 'End date cannot be earlier than the start date.',
                        type: 'danger'
                    });

                    this.endDate = this.startDate;
                    return;
                }
                this.getDistanceTraveledList();
            }
        },
        selectedPeriod(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getDistanceTraveledList();
            }
        }
    },
    computed: {
        getDescepancy() {
            if (this.selectedTeamMember.isTracker !== true && this.getPeriod === 'day') 
                return this.selectedDiscrepancy;
            return null;
        },
        discrepancyComboValues() {
            return [
                {
                    label: 'more than 5%',
                    value: 5,
                    color: '#FF0000'
                },
                {
                    label: 'more than 10%',
                    value: 10,
                    color: '#FF0000'
                },
                {
                    label: 'more than 20%',
                    value: 20,
                    color: '#FF0000'
                },
                {
                    label: 'more than 30%',
                    value: 30,
                    color: '#FF0000'
                },
                {
                    label: 'more than 40%',
                    value: 40,
                    color: '#FF0000'
                },
                {
                    label: 'more than 50%',
                    value: 50,
                    color: '#FF0000'
                }
            ];
        },
        getPeriod() {
            if (!this.selectedTeamMember.userId) 
                return 'day';
            return this.selectedPeriod;
        }
    },
    created() {
        this.getDistanceTraveledList = _.debounce(this.getDistanceTraveledList, 500);
    },
    methods: {
        ...mapGetters({
            user: 'user/user'
        }),

        onExportToExcel() {
            if (this.startDate && this.endDate) {
                const reportDate = `${moment(this.startDate).format('YYYY-MM-DD')}-${moment(this.endDate).format(
                    'YYYY-MM-DD'
                )}`;

                this.$_handleLoaderState(true);
                const api = `/api/reports/distance-report/export-to-excel?teamRegionId=${
                    this.selectedTeamMember.teamRegionId
                }&userId=${this.selectedTeamMember.userId || ''}&isTracker=${
                    this.selectedTeamMember.isTracker
                }&startDate=${moment(this.startDate).format('YYYY-MM-DD')}&endDate=${moment(this.endDate).format(
                    'YYYY-MM-DD'
                )}&interval=${this.getPeriod ?? 'day'}`;

                const payload = {
                    method: 'get',
                    responseType: 'blob'
                };
                const reportName = `${this.selectedTeamMember.name ||
                    this.userFullName}-DistanceReport-${reportDate}.xlsx`;

                handleRequests(api, payload)
                    .then((response) => {
                        downloadFile(response.data, reportName);
                        this.$_handleLoaderState(false);
                    })
                    .catch((e) => {
                        this.$_handleLoaderState(false);
                        this.$notify({ message: e.data, type: 'danger' });
                    });
            }
        },

        getDistanceTraveledList() {
            if (this.startDate && this.endDate) {
                this.isLoading = true;

                this.$_handleLoaderState(true);
                const api = `/api/reports/distance-report/?teamRegionId=${
                    this.selectedTeamMember.teamRegionId
                }&userId=${this.selectedTeamMember.userId || ''}&isTracker=${
                    this.selectedTeamMember.isTracker
                }&startDate=${moment(this.startDate).format('YYYY-MM-DD')}&endDate=${moment(this.endDate).format(
                    'YYYY-MM-DD'
                )}&interval=${this.getPeriod ?? 'day'}`;
                handleRequests(api)
                    .then((response) => {
                        this.isLoading = false;
                        const { data } = response.data;

                        this.distancesList = data;

                        this.$_handleLoaderState(false);
                    })
                    .catch((e) => {
                        this.distancesList = [];
                        this.$_handleLoaderState(false);
                        this.$notify({ message: e.data, type: 'danger' });
                    });
            }
        },

        async onDateChanged(val) {
            this.startDate = val.startDate;
            this.endDate = val.endDate;
            this.getDistanceTraveledList();
        },

        async onTeamMemberSelection(val) {
            this.selectedTeamMember = val || {};
            this.getDistanceTraveledList();
        }
    },
    async mounted() {
        this.userFullName = this.user().fullName;
    }
};
</script>
