var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-container",
      attrs: { tabindex: "0" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.$modal.hide($event)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-header" },
        [
          _c("h4", { staticClass: "modal-title" }, [
            _vm._v(
              _vm._s(_vm.isUpdate ? "Update Tax Setting" : "Create Tax Setting")
            ),
          ]),
          _c(
            "md-button",
            {
              staticClass:
                "md-simple md-just-icon md-round modal-default-button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$modal.hide($event)
                },
              },
            },
            [_c("md-icon", [_vm._v("clear")])],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _c(
            "form-wrapper",
            {
              staticClass: "form-wrapper",
              attrs: { validator: _vm.$v.taxSettingDetails },
            },
            [
              _c(
                "form",
                {
                  attrs: { novalidate: "" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.validateService($event)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-container rate-group-form-container" },
                    [
                      _c(
                        "div",
                        { staticClass: "rate-group-details" },
                        [
                          _c(
                            "form-group",
                            {
                              attrs: {
                                name: "type",
                                label: "Type",
                                attribute: "Type",
                                messages: _vm.localMessages,
                              },
                            },
                            [
                              _c("md-input", {
                                directives: [
                                  { name: "focus", rawName: "v-focus" },
                                ],
                                attrs: { required: "" },
                                model: {
                                  value: _vm.taxSettingDetails.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.taxSettingDetails, "type", $$v)
                                  },
                                  expression: "taxSettingDetails.type",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "form-group",
                            {
                              attrs: {
                                name: "description",
                                label: "Description",
                                attribute: "Description",
                                messages: _vm.localMessages,
                              },
                            },
                            [
                              _c("md-input", {
                                directives: [
                                  { name: "focus", rawName: "v-focus" },
                                ],
                                model: {
                                  value: _vm.taxSettingDetails.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.taxSettingDetails,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "taxSettingDetails.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "form-group",
                            {
                              attrs: {
                                name: "rate",
                                label: "Rate (%)",
                                attribute: "Rate",
                                messages: _vm.localMessages,
                              },
                            },
                            [
                              _c("md-input", {
                                directives: [
                                  { name: "focus", rawName: "v-focus" },
                                ],
                                attrs: { required: "" },
                                model: {
                                  value: _vm.taxSettingDetails.rate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.taxSettingDetails, "rate", $$v)
                                  },
                                  expression: "taxSettingDetails.rate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "md-button",
            {
              staticClass: "dialog-button md-primary",
              on: { click: _vm.validateService },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.isUpdate ? "Update" : "Create") +
                  "\n        "
              ),
            ]
          ),
          _c(
            "md-button",
            {
              staticClass: "dialog-button md-default",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$modal.hide($event)
                },
              },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }