<template>
    <div class="map-container" :class="isTrialExpired ? 'not-paid-map-overlay' : ''">
        <div class="google-map" ref="googleMap"></div>
        <template v-if="Boolean(this.map)">
            <slot />
        </template>
    </div>
</template>

<script>
import useMapUtils from '@/compostables/useMapUtils';
import { ref, onMounted, inject } from '@vue/composition-api';

export default {
    name: 'GoogleMapLoader',
    setup(_, context) {
        const { loadGoogleMap, createMap, createBounds /* createPanorama, setPanoramaOptions */ } = useMapUtils(
            context.root
        );
        const map = ref(null);
        // const panorama = ref(null);

        const { getters } = inject('vuex-store');
        const googleMap = ref(null);
        const isTrialExpired = getters['team/isTrialExpired'];

        const setupMap = async () => {
            const google = await loadGoogleMap();
            context.emit('google-defined', google);
            const mapContainer = googleMap.value;
            const { latitude, longitude } = getters['user/user'].startLocation;

            map.value = createMap(google, mapContainer, latitude, longitude);
            // panorama.value = createPanorama(google.value, map.value);
            // const panoramaOptions = {
            //    scrollwheel: false,
            //    disableDefaultUI: true,
            //    clickToGo: false,
            //    enableCloseButton: false
            // };

            // const pov = { heading: 265, pitch: 0 };
            // setPanoramaOptions(panorama.value, panoramaOptions, pov);

            // context.emit('panorama-defined', panorama.value);
            context.emit('map-defined', map.value);
            const bounds = createBounds(google);
            context.emit('bounds-defined', bounds);
        };
        onMounted(setupMap);

        return {
            map,
            googleMap,
            isTrialExpired
        };
    }
};
</script>

<style lang="scss" scoped>
.map-container {
    height: 100vh;
    width: 100%;
    overflow: hidden !important;

    &.not-paid-map-overlay {
        width: 100%;
        height: calc(100vh - 60px);
        > div {
            bottom: 0;
        }
    }
}

.google-map {
    width: 100%;
    height: 100%;
    overflow: hidden !important;
}

.show-footer {
    .map-container {
        height: 100vh;
        width: 100%;
        overflow: hidden !important;
        height: calc(100vh - 62px);

        &.not-paid-map-overlay {
            width: 100%;
            height: calc(100vh - 122px);
            > div {
                bottom: 0;
            }
        }
    }
}
</style>
