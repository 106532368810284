<template>
    <LoginRedirect />
</template>
<script>
import LoginRedirect from '../../../components/LoginRedirect';

export default {
    components: { LoginRedirect },
    async beforeCreate() {
        const isLoggedIn = await window.auth.isLoggedIn();
        if (isLoggedIn) {
            this.login();
        } else {
            this.$router.push('/');
        }
    },
    methods: {
        async login() {
            window.auth.login();
        }
    }
};
</script>
