<template>
    <div class="detail-container">
        <div class="close-button">
            <md-button class="md-default md-just-icon md-round" @click="closeModal">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <div class="button-arrows">
            <div>
                <md-button class="left-btn md-primary md-round" @click.stop="handleLeftClick">
                    <span class="md-label">
                        <md-icon class="material-icons">
                            keyboard_arrow_left
                        </md-icon>
                    </span>
                </md-button>
                <md-button class="right-btn md-primary md-round" @click.stop="handleRightClick">
                    <span class="md-label md-label-right">
                        <md-icon class="material-icons">
                            keyboard_arrow_right
                        </md-icon>
                    </span>
                </md-button>
            </div>
        </div>
        <div class="main-container">
            <div class="detail-section text-center">
                <span class="title blue-ref" v-if="stopDetails.stopRef != null">
                    <router-link
                        :to="{
                            name: 'Stop Details',
                            params: { stopId: stopDetails.stopId }
                        }"
                        target="_blank"
                    >
                        {{ stopDetails.stopRef }}
                    </router-link>
                </span>
                <span class="title" v-else>
                    <router-link
                        :to="{
                            name: 'Stop Details',
                            params: { stopId: stopDetails.stopId }
                        }"
                    >
                        {{ stopDetails.id }}
                    </router-link>
                </span>
            </div>
            <div class="line-separator"></div>
            <div v-show="!hasNoMapLocation">
                <div ref="map" class="map"></div>
            </div>
            <div v-show="hasNoMapLocation" class="no-map">
                <p>No map available</p>
            </div>
            <div class="detail-section" v-if="stopDetails.addressComponents != null">
                <span class="title">Address Components</span>
                <div class="detail-group">
                    <google-autocomplete
                        label="Address"
                        ref="address"
                        id="autoaddress"
                        classname="form-control autocomplete-input"
                        placeholder=""
                        :should-focus="true"
                        v-model="stopDetails"
                        :disable-gps-coordinates="true"
                        @handleCoordinates="handleCoordinates"
                        :should-keep-original-address="true"
                        @placechanged="handleAddressChange"
                    ></google-autocomplete>
                </div>
                <div class="detail-group">
                    <label class="label">Type:</label>
                    <div class="value">
                        <div
                            v-if="stopDetails.addressComponents != null && stopDetails.addressComponents.type != null"
                            :class="
                                `custom-badge address-badge status-${$_getGeocodeAddressTypeBadgeColor(
                                    stopDetails.addressComponents.type
                                )}`
                            "
                        >
                            {{
                                $te(`geocodeTypes.${stopDetails.addressComponents.type}`)
                                    ? $t(`geocodeTypes.${stopDetails.addressComponents.type}`)
                                    : stopDetails.addressComponents.type
                            }}
                        </div>
                    </div>
                </div>
                <div class="detail-group">
                    <label class="label">Confidence score:</label>
                    <div class="value">
                        {{
                            stopDetails.addressComponents.confidenceScore == 0 ||
                            stopDetails.addressComponents.confidenceScore == null
                                ? '-'
                                : Math.round(stopDetails.addressComponents.confidenceScore * 100) + '%'
                        }}
                    </div>
                </div>
                <div class="detail-group" v-if="stopDetails.addressComponents.line1">
                    <label class="label">Line 1:</label>
                    <div class="value">
                        {{ stopDetails.addressComponents.line1 }}
                    </div>
                </div>
                <div class="detail-group" v-if="stopDetails.addressComponents.line2">
                    <label class="label">Line 2:</label>
                    <div class="value">
                        {{ stopDetails.addressComponents.line2 }}
                    </div>
                </div>
                <div class="detail-group" v-if="stopDetails.addressComponents.city">
                    <label class="label">City:</label>
                    <div class="value">
                        {{ stopDetails.addressComponents.city }}
                    </div>
                </div>
                <div class="detail-group" v-if="stopDetails.addressComponents.postcode">
                    <label class="label">Postcode:</label>
                    <div class="value">
                        {{ stopDetails.addressComponents.postcode }}
                    </div>
                </div>
                <div class="detail-group" v-if="stopDetails.addressComponents.province">
                    <label class="label">Province:</label>
                    <div class="value">
                        {{ stopDetails.addressComponents.province }}
                    </div>
                </div>
                <div class="detail-group" v-if="stopDetails.addressComponents.country">
                    <label class="label">Country:</label>
                    <div class="value">
                        {{ stopDetails.addressComponents.country }}
                    </div>
                </div>
            </div>
            <div class="line-separator"></div>
            <div class="detail-section">
                <span class="title">Stop Details</span>
                <div class="detail-group">
                    <label class="label">When:</label>
                    <div class="value">
                        <span
                            v-if="
                                stopDetails.tripDate == null &&
                                    stopDetails.appointmentTime == null &&
                                    stopDetails.timeWindowStart == null &&
                                    stopDetails.timeWindowEnd == null
                            "
                        >
                            <i>Unscheduled</i>
                        </span>
                        <span v-if="stopDetails.tripDate != null">
                            <router-link
                                v-if="stopDetails.tripId"
                                :to="{
                                    name: 'Trip Details',
                                    params: {
                                        tripId: stopDetails.tripId
                                    }
                                }"
                                target="_blank"
                            >
                                {{ stopDetails.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                            </router-link>
                            <span v-else>
                                {{ stopDetails.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                            </span>
                        </span>
                        <span v-if="stopDetails.timeWindowStart != null && stopDetails.timeWindowEnd != null">
                            ({{ stopDetails.timeWindowStart | timeFormat }} -
                            {{ stopDetails.timeWindowEnd | timeFormat }})
                        </span>
                        <span v-else-if="stopDetails.appointmentTime != null">
                            ({{ stopDetails.appointmentTime | timeFormat }})
                        </span>
                    </div>
                </div>
                <div class="detail-group">
                    <label class="label">Contact Name:</label>
                    <span class="value">
                        {{ stopDetails.contact.name }}
                    </span>
                </div>
                <div class="detail-group">
                    <label class="label">Contact Email:</label>
                    <span class="value">
                        {{ stopDetails.contact.email }}
                    </span>
                </div>
                <div class="detail-group">
                    <label class="label">Contact Phone:</label>
                    <span class="value">
                        {{ stopDetails.contact.phone }}
                    </span>
                </div>
                <div class="detail-group">
                    <label class="label">Notes:</label>
                    <span class="value">
                        {{ stopDetails.notes }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GeneralMixin, MapOverviewMixin, GeocodeAddressMixin } from '@/mixins';
import { MarkerPins } from '@/utils/MarkerPins';
import { GoogleAutocomplete } from '@/components';
import { handleRequests } from '@/helpers';

export default {
    name: 'FixAddressSidebar',
    props: {
        stopDetails: {
            type: Object,
            default: null
        },
        currentIndex: {
            type: Number,
            default: null
        }
    },
    components: {
        GoogleAutocomplete
    },
    data() {
        return {
            map: null
        };
    },
    mixins: [GeneralMixin, MapOverviewMixin, GeocodeAddressMixin],
    watch: {
        'stopDetails.stopId': function() {
            this.initializeMap();
        },
        'stopDetails.addressComponents.location.latitude': function() {
            this.initializeMap();
        }
    },
    computed: {
        hasNoMapLocation() {
            if (this.stopDetails.addressComponents == null) {
                return true;
            }

            return false;
        }
    },
    beforeMount() {
        window.addEventListener('keydown', this.handleKeydown, null);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeydown);
    },
    methods: {
        handleCoordinates(value) {
            this.initializeMap();
        },
        closeModal() {
            this.$emit('closeModal');
        },
        async initializeMap() {
            await this.$_map_loadGoogleMap();
            if (this.stopDetails.addressComponents == null) 
                return;

            const lat = this.stopDetails.addressComponents.location.latitude;
            const lng = this.stopDetails.addressComponents.location.longitude;
            const element = this.$refs.map;

            const options = {
                mapTypeControl: false,
                gestureHandling: 'cooperative'
            };

            this.map = this.$_map_createMap(element, lat, lng, options);
            const pins = MarkerPins[0];

            this.showStopMarker(pins);
            this.$_map_callMapFunction(this.map, 'setZoom', [19]);
        },
        showStopMarker(pins) {
            const {
                location: { latitude, longitude }
            } = this.stopDetails.addressComponents;

            this.$_map_setMarkers(latitude, longitude, 'stop', pins);
        },
        handleLeftClick() {
            this.$emit('leftClick');
        },
        handleRightClick() {
            this.$emit('rightClick');
        },
        handleKeydown(e) {
            if (e.shiftKey && e.keyCode === 33) {
                this.handleLeftClick();
            } else if (e.shiftKey && e.keyCode === 34) {
                this.handleRightClick();
            }
        },
        handleAddressChange(stopAddressData) {
            const { address, location } = stopAddressData;

            if (address == null || location == null) 
                return;

            if (location) {
                const api = `/api/stops/${this.stopDetails.stopId}/address`;
                const payload = {
                    method: 'post',
                    data: stopAddressData.addressComponents
                };
                handleRequests(api, payload).catch(() => {
                    this.$notify({
                        message: `Could not update stop with reference number ${this.stopDetails.stopRef}.`,
                        type: 'danger'
                    });
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.detail-container {
    position: fixed;
    top: 2vh;
    right: 10px;
    height: 96vh;
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    background: white;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 16px;

    .close-button {
        top: 3px;
        right: 0;
        position: absolute;
        /* add z-index for old stops that have no stop reference numbers */
        z-index: 999;

        ::v-deep .md-button {
            height: 36px !important;
            width: 36px !important;
        }
    }

    .md-button.md-just-icon,
    ::v-deep .md-button.md-just-icon {
        font-size: 15px;
        height: 30px;
        min-width: 3px;
        width: 30px;
        line-height: 30px;
        margin: 2.5px;
    }

    .detail-section {
        padding-top: 10px;
        .blue-ref a {
            color: #2b93ff !important;
        }
        .title {
            text-transform: uppercase;
            font-weight: 600;
            padding: 2px 16px;
            display: block;
            font-size: 14px;
        }
        ::v-deep .custom-a-blue a {
            color: #2b93ff !important;
        }
    }

    .line-separator {
        height: 1px;
        background-color: #ddd;
        width: calc(100% - 32px);
        margin: 10px auto;
        margin-bottom: 0px;
    }

    .detail-group {
        padding: 2px 16px;
        margin: 5px 0;
        font-size: 0.9em;
        .label {
            display: inline-block;
            width: 150px;
            margin-right: 4px;
            vertical-align: top;
            font-weight: 400;
        }

        .value {
            display: inline-block;
            width: calc(100% - 160px);
            vertical-align: top;
            a {
                color: #2b93ff;
            }
        }
        .custom-btn,
        ::v-deep .custom-btn {
            padding: 4px 8px;
            margin: 0;
            width: initial;
            max-width: initial;
            padding: 4px 0;
            .md-ripple {
                padding: 0 !important;
            }
        }
    }
}

::v-deep {
    .status {
        color: #fff !important;
        min-width: 90px;
        max-width: 90px;
        display: block;
        min-height: 31px;
        text-align: center;
    }
}

a {
    &.link {
        color: #2b93ff;
    }
}

@media (min-width: 992px) {
    .detail-container {
        width: 40%;
    }
}

@media (min-width: 1200px) {
    .detail-container {
        width: 500px;
    }
}

.button-arrows {
    position: absolute;
    top: 0;
    left: 0;

    ::v-deep button {
        min-width: 36px;
    }

    ::v-deep .md-ripple {
        padding: 3px !important;
    }
}

.main-container {
    height: 100%;
}
.no-map {
    height: 300px;
    background-color: #eee;
    text-align: center;
    p {
        margin: 0;
        padding-top: 135px;
    }
}
</style>
