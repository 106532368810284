var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-button",
    {
      staticClass: "md-info md-just-icon md-round",
      attrs: { title: "Download Excel" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleGenerateExcel(_vm.invoiceId)
        },
      },
    },
    [_c("md-icon", [_vm._v("get_app")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }