<template>
    <div>
        <div class="md-layout conditions-container">
            <div class="md-layout-item conditions-title-container">
                Conditions
            </div>
            <div class="md-layout-item conditions-button-container">
                <md-button title="Add condition" class="md-simple md-just-icon md-round" @click="handleAddCondition">
                    <md-icon>add</md-icon>
                </md-button>
            </div>
        </div>
        <div class="conditions-list">
            <div v-for="(condition, conditionIndex) in conditions" :key="conditionIndex" class="condition-item">
                <div class="condition-content-container">
                    <div class="condition-header">
                        <form-group label="Condition" class="group-filter">
                            <md-select v-model="condition.type" @md-selected="handleConditionTypeSelected(condition)">
                                <md-option
                                    v-for="(option, index) in conditionsOptions"
                                    :key="index"
                                    :value="option.value"
                                >
                                    {{ option.name }}
                                </md-option>
                            </md-select>
                        </form-group>
                    </div>
                    <div class="condition-content">
                        <div v-if="condition.type === 'teamRegions'" class="multiple-field-container">
                            <multiselect
                                v-model="condition.data"
                                :options="condition.teamRegionsOptions"
                                :multiple="true"
                                :close-on-select="true"
                                placeholder="Pick team regions"
                                label="name"
                                track-by="teamRegionId"
                            >
                                <template v-slot:option="{ option }">
                                    <span :style="{ marginLeft: getIndentation(option) }">
                                        {{ !option.$isDisabled ? option.name : option.selectedLabel }}
                                    </span>
                                </template>
                            </multiselect>
                            <div>
                                <md-tooltip
                                    class="team-region-popup"
                                    md-direction="right"
                                    :md-active.sync="condition.teamRegionPopup.teamRegionPopUpActive"
                                >
                                    {{ condition.teamRegionPopup.teamRegionPopUpMessage }}
                                </md-tooltip>
                            </div>
                        </div>
                        <div v-else-if="condition.type === 'shipmentContents'" class="multiple-field-container">
                            <multiselect
                                v-model="condition.data"
                                :options="shipmentContentsOptions"
                                :multiple="true"
                                :close-on-select="true"
                                placeholder="Pick shipment contents"
                                group-values="values"
                                group-label="label"
                                :group-select="true"
                                track-by="name"
                                label="name"
                            ></multiselect>
                        </div>
                        <div v-else-if="condition.type === 'customFields'" class="custom-fields">
                            <div class="custom-field-item">
                                <form-group label="Name" class="group-filter">
                                    <md-select v-model="condition.data.name">
                                        <md-optgroup label="Stop Custom Fields" class="custom-field-option">
                                            <md-option
                                                v-for="(stopCustomField, index) in stopCustomFieldsOptions"
                                                :key="index"
                                                :value="stopCustomField.name"
                                            >
                                                {{ stopCustomField.label }}
                                            </md-option>
                                        </md-optgroup>
                                        <md-optgroup label="Shipment Custom Fields" class="custom-field-option">
                                            <md-option
                                                v-for="(shipmentCustomField, index) in shipmentCustomFieldsOptions"
                                                :key="index"
                                                :value="shipmentCustomField.name"
                                            >
                                                {{ shipmentCustomField.label }}
                                            </md-option>
                                        </md-optgroup>
                                    </md-select>
                                </form-group>
                            </div>
                            <div class="custom-field-item">
                                <form-group label="Operator" class="group-filter">
                                    <md-select v-model="condition.data.operator">
                                        <md-option
                                            v-for="(operator, index) in operatorOptions"
                                            :key="index"
                                            :value="operator.value"
                                        >
                                            {{ operator.name }}
                                        </md-option>
                                    </md-select>
                                </form-group>
                            </div>
                            <div class="custom-field-item">
                                <form-group label="Value" name="value">
                                    <md-input v-model="condition.data.value" />
                                </form-group>
                            </div>
                        </div>
                        <div v-else-if="condition.type === 'customers'">
                            <customer-chips-autocomplete
                                class="customer-condition-auto-complete"
                                :customers="condition.data"
                                @select="handleCustomerSelect"
                                @delete="handleCustomerDeselect"
                            />
                        </div>
                    </div>
                </div>
                <div class="condition-actions">
                    <md-button
                        class="md-just-icon md-danger md-simple delete-button"
                        title="delete condition"
                        @click="handleDeleteCondition(conditionIndex)"
                    >
                        <md-icon>close</md-icon>
                    </md-button>
                </div>
            </div>
        </div>
        <div class="text-center" v-if="conditions.length == 0">
            <p class="no-result-message">No available conditions.</p>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { CustomerChipsAutocomplete } from '@/components';

export default {
    name: 'QuestionConditions',
    components: { CustomerChipsAutocomplete },
    mixins: [GeneralMixin],
    props: {
        form: {
            type: Object,
            default: () => {}
        },
        conditions: {
            type: Array,
            default: () => []
        },
        conditionsOptions: {
            type: Array,
            default: () => []
        },
        teamRegionsOptions: {
            type: Array,
            default: () => []
        },
        shipmentContentsOptions: {
            type: Array,
            default: () => []
        },
        stopCustomFieldsOptions: {
            type: Array,
            default: () => []
        },
        shipmentCustomFieldsOptions: {
            type: Array,
            default: () => []
        },
        operatorOptions: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isDeleting: false
        };
    },
    methods: {
        getIndentation(option) {
            if (!option) 
                return '0px';

            const optionLevel = option.level;
            let indentation = 0;

            if (optionLevel > 1) 
                indentation = (+optionLevel - 1) * 20;

            return `${indentation}px`;
        },
        handleAddCondition() {
            this.conditions.push({
                type: 'none',
                data: null,
                teamRegionsOptions: [],
                teamRegionPopup: {
                    teamRegionPopUpActive: false,
                    teamRegionPopUpMessage: '',
                    teamRegionsInitialized: false
                }
            });
        },
        handleDeleteCondition(conditionIndex) {
            this.isDeleting = true;
            this.conditions.splice(conditionIndex, 1);
            this.$nextTick(() => {
                this.isDeleting = false;
            });
        },
        handleConditionTypeSelected(condition) {
            if (this.isDeleting) {
                return;
            }

            condition.teamRegionsOptions = [];

            if (condition.type === 'teamRegions') {
                condition.teamRegionsOptions = JSON.parse(JSON.stringify(this.teamRegionsOptions));
            }

            if (condition.type === 'teamRegions' || condition.type === 'shipmentContents') {
                condition.data = null;
            }

            if (condition.type === 'customers') {
                condition.data = [];
            }

            if (condition.type === 'customFields') {
                condition.data = {
                    name: null,
                    operator: null,
                    value: null
                };
            }
        },
        handleCustomerSelect(customer, conditions) {
            conditions.push({ customerId: customer.customerId, name: customer.name });
        },
        handleCustomerDeselect(customer, index, conditions) {
            conditions.splice(index, 1);
        }
    }
};
</script>
<style lang="scss" scoped>
.conditions-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.condition-item {
    display: flex;
    align-items: flex-start;
}
.condition-content-container {
    flex-grow: 1;
}
.condition-actions {
    flex: 0 0 auto;
    width: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 15px;
}
.delete-button {
    width: 24px;
    height: 24px;
}

.multiple-field-container {
    margin: 10px 0;
}
.custom-fields {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.custom-field-item {
    flex: 1 1 calc(33.333% - 10px);
}
.custom-field-option {
    ::v-deep .md-subheader {
        text-transform: capitalize;
        font-weight: bold;
    }

    ::v-deep .md-list-item {
        margin-left: 15px !important;
    }
}
.team-region-popup {
    position: absolute;
    top: 0;
    left: 100%;
}
.conditions-container {
    margin-bottom: 5px;
}

.conditions-title-container {
    font-weight: 600;
    padding-left: 0;
    padding-right: 0;
}
.conditions-button-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
    padding-left: 0;

    button {
        height: 20px;
        padding: 0;
        margin: 0 3px 0 0;
        min-width: 0;
        width: 20px;
    }
}

.customer-condition-auto-complete {
    margin-bottom: 20px;

    ::v-deep .custom-md-chips {
        border-radius: 5px;
        border: 1px solid #dfdfdf;
        padding-left: 5px;
        padding-right: 5px;
        margin-top: 0;
        padding-top: 2px;

        .md-chip {
            background-color: #41b883 !important;
            border-radius: 5px;

            button {
                padding: 0;
            }
        }
    }
}
</style>
