<template>
    <div
        v-if="isSupportUser || isSupportAdminUser"
        class="support-user-container md-layout md-alignment-top-left content-layout-panel"
    >
        <div class="support-user-details md-layout-item md-size-45">
            <div>
                <img
                    v-if="supportingTeam && supportingTeam.logoUrl"
                    class="profile-image"
                    :src="supportingTeam.logoUrl"
                    @error="$_setDefaultBrokenImage"
                />
            </div>
            <div>
                Joined team:
                <br />
                <span v-if="supportingTeam && supportingTeam.name">{{ supportingTeam.name }}</span>
                <span v-else>&lt; None &gt;</span>
            </div>
        </div>

        <div class="md-layout-item support-buttons">
            <md-button class="join-btn md-primary support-btn" @click="displayJoinTeamModal">
                Search Teams
            </md-button>

            <drop-down v-if="isSupportAdminUser" direction="down" class="join-btn">
                <md-button slot="title" data-toggle="dropdown" class="drop-down-button md-primary md-just-icon">
                    <md-icon>expand_more</md-icon>
                </md-button>

                <ul class="dropdown-menu dropdown-menu-right">
                    <li>
                        <a @click="displayCreateSupportUserModal">
                            Create Support User
                        </a>
                    </li>
                    <li>
                        <a @click="displayRemoveSupportUserModal">
                            Remove Support User
                        </a>
                    </li>
                </ul>
            </drop-down>

            <md-button v-if="supportingTeam" class="join-btn md-danger support-btn" @click="leaveTeam">
                Leave Team
            </md-button>
        </div>
    </div>
</template>
<script>
/* eslint-disable no-new */
import { handleRequests } from '@/helpers';
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import SupportJoinTeamModal from './Modals/SupportJoinTeamModal';
import AddSupportUserModal from './Modals/AddSupportUserModal';
import RemoveSupportUserModal from './Modals/RemoveSupportUserModal';

export default {
    name: 'SupportUserNavBar',
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            isIndividualUser: 'user/isIndividualUser',
            isSupportUser: 'user/isSupportUser',
            isSupportAdminUser: 'user/isSupportAdminUser',
            supportingTeam: 'user/supportingTeam',
            user: 'user/user'
        })
    },
    methods: {
        async displayJoinTeamModal() {
            this.$modal.show(SupportJoinTeamModal, {}).then((response) => {
                if (response) {
                    this.$modal.hide();
                    this.$router.go();
                }
            });
        },
        async leaveTeam() {
            this.$_handleLoaderState(true, 'Leaving Team...');

            const payload = {
                method: 'post'
            };
            const api = `/api/support/teams/0/leave`;
            const response = await handleRequests(api, payload);

            this.$_handleLoaderState(false);

            if (response.status === 200) {
                this.$notify({
                    message: `Left Team!`,
                    type: 'success'
                });
                this.$router.go();
            }
        },
        async displayCreateSupportUserModal() {
            this.$modal.show(AddSupportUserModal).then((response) => {
                if (response) {
                    this.$modal.hide();
                    this.$router.go();
                }
            });
        },
        async displayRemoveSupportUserModal() {
            this.$modal.show(RemoveSupportUserModal).then((response) => {
                if (response) {
                    this.$modal.hide();
                    this.$router.go();
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.support-user-container {
    background-color: #1c3e61;
    color: white;
    position: sticky;
    top: 0;
    z-index: 999;
    padding: 0px 12px;

    .support-user-details {
        margin: auto !important;
        display: inline-flex;

        .profile-image {
            max-height: 41px;
            margin-right: 12px;
        }
    }

    .support-buttons {
        text-align: right;
    }

    .join-btn {
        display: inline-block;
        margin: 8px 1px 8px -30px;
        height: 41px;
    }

    .dropdown-menu {
        margin: 0px !important;

        a {
            cursor: pointer;
        }
    }

    .drop-down-button {
        margin: 0px 0px 0px 1px !important;
    }
}

.support-btn {
    margin-left: 10px !important;
}
</style>
