var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cf-container" }, [
    _c(
      "div",
      { staticClass: "cf-action-container" },
      [
        _vm.mappingData.viewType == "designer"
          ? _c(
              "div",
              { staticClass: "mappings-template" },
              [
                _c(
                  "drop-down",
                  { attrs: { direction: "down" } },
                  [
                    _c(
                      "span",
                      { staticClass: "mappings-template-text" },
                      [
                        _c(
                          "md-tooltip",
                          {
                            staticClass: "toggle-tooltip",
                            attrs: { "md-direction": "top" },
                          },
                          [
                            _vm._v(
                              "\n                        Mapping Template - " +
                                _vm._s(_vm.mappingData.selectedTemplateName) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.mappingData.selectedTemplateName) +
                            "\n                "
                        ),
                      ],
                      1
                    ),
                    _c("md-icon", { staticClass: "icon-arrow" }, [
                      _vm._v("expand_more"),
                    ]),
                    _c(
                      "ul",
                      { staticClass: "dropdown-menu" },
                      [
                        _vm._l(
                          _vm.mappingData.templateNameList,
                          function (templateName, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                on: {
                                  click: function ($event) {
                                    return _vm.getSelectedTemplate(templateName)
                                  },
                                },
                              },
                              [
                                _c(
                                  "md-icon",
                                  {
                                    class: [
                                      "icon-check",
                                      _vm.mappingData.selectedTemplateName ===
                                      templateName
                                        ? "icon-check-color"
                                        : "",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n                            check\n                        "
                                    ),
                                  ]
                                ),
                                _c("span", { staticClass: "name" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(templateName) +
                                      "\n                        "
                                  ),
                                ]),
                                templateName !== "Default"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "delete",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.deleteTemplate(
                                              templateName
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "md-tooltip",
                                          {
                                            staticClass: "toggle-tooltip",
                                            attrs: { "md-direction": "right" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Delete Template\n                            "
                                            ),
                                          ]
                                        ),
                                        _c("md-icon", [_vm._v("close")]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                        _c(
                          "li",
                          { staticClass: "li-border" },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "dialog-button md-primary add",
                                on: { click: _vm.addNewTemplate },
                              },
                              [
                                _vm._v(
                                  "\n                            Add Template\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "switch-options" }, [
          _c(
            "div",
            { staticClass: "custom-switch" },
            [
              _c(
                "md-switch",
                {
                  on: {
                    change: function ($event) {
                      return _vm.updateViewType()
                    },
                  },
                  model: {
                    value: _vm.mappingData.isJSONEditorView,
                    callback: function ($$v) {
                      _vm.$set(_vm.mappingData, "isJSONEditorView", $$v)
                    },
                    expression: "mappingData.isJSONEditorView",
                  },
                },
                [_vm._v("JSON Editor")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-just-icon md-round reset-btn",
            attrs: {
              title:
                "Reset mappings - mappings will be reverted to the original mappings.",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleReset()
              },
            },
          },
          [_c("md-icon", [_vm._v("settings_backup_restore")])],
          1
        ),
        _c("input", {
          ref: "fileInput",
          staticStyle: { display: "none" },
          attrs: { type: "file" },
          on: { change: _vm.onFileSelected },
        }),
        _vm.mappingData.viewType == "designer"
          ? _c(
              "md-button",
              {
                staticClass: "md-primary md-just-icon md-round",
                attrs: { title: "Please upload a sample spreadsheet." },
                on: {
                  click: function ($event) {
                    return _vm.$refs.fileInput.click()
                  },
                },
              },
              [_c("md-icon", [_vm._v("publish")])],
              1
            )
          : _vm._e(),
        _c(
          "md-button",
          {
            staticClass: "md-primary md-just-icon md-round",
            attrs: { title: "Save mappings" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleSave()
              },
            },
          },
          [_c("md-icon", [_vm._v("save")])],
          1
        ),
      ],
      1
    ),
    _vm.mappingData.viewType == "designer"
      ? _c("div", [
          _vm.mappingData.isDefaultMappings
            ? _c("div", { staticClass: "alert alert-warning" }, [
                _c("span", [
                  _vm._v(
                    "\n                You do not have any import field mappings configured. Please upload a sample spreadsheet using the\n                Upload button in the top right to start mapping your spreadsheet for import.\n            "
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "import-designer" },
            [
              _vm._m(0),
              _vm._l(
                _vm.mappingData.importMappingFields,
                function (data, index) {
                  return _c("div", { key: index }, [
                    data.isSectionHeader
                      ? _c("div", { staticClass: "section-header" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(data.displayName) +
                              "\n                "
                          ),
                        ])
                      : _c("div", [
                          _c(
                            "div",
                            { staticClass: "field-label-col field-label" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(data.displayName) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "mapped-column" },
                            [
                              _c("designer-field-inputs", {
                                attrs: { "field-definition": data },
                                on: {
                                  editCustomMapping: _vm.editCustomMapping,
                                },
                              }),
                            ],
                            1
                          ),
                          data.value && typeof data.value !== "string"
                            ? _c(
                                "div",
                                {
                                  staticClass: "custom-mapping-info",
                                  attrs: { title: "Edit Mapping" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editCustomMapping(data)
                                    },
                                  },
                                },
                                [
                                  data.value.formatString
                                    ? _c("span", [
                                        _vm._v(
                                          "Format: " +
                                            _vm._s(data.value.formatString)
                                        ),
                                      ])
                                    : _vm._e(),
                                  data.value.formatString &&
                                  data.value.defaultValue
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                            ,  \n                        "
                                        ),
                                      ])
                                    : _vm._e(),
                                  data.value.defaultValue
                                    ? _c("span", [
                                        _vm._v(
                                          "Default Value: " +
                                            _vm._s(data.value.defaultValue)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]),
                  ])
                }
              ),
            ],
            2
          ),
        ])
      : _vm.mappingData.viewType == "editor"
      ? _c("div", [
          _c(
            "div",
            { staticClass: "import-editor" },
            [
              _c(
                "form-group",
                [
                  _c("md-textarea", {
                    staticClass: "import-textarea",
                    model: {
                      value: _vm.mappingData.importMappingJson,
                      callback: function ($$v) {
                        _vm.$set(_vm.mappingData, "importMappingJson", $$v)
                      },
                      expression: "mappingData.importMappingJson",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mappings-header" }, [
      _c("div", { staticClass: "field-label-col" }, [_vm._v("Field")]),
      _c("div", { staticClass: "mapped-column" }, [
        _vm._v("\n                    Mapped Column\n                "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }