var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c("div", { staticClass: "custom-toolbar md-layout" }, [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item custom-toolbar-start md-layout md-small-size-80 md-size-90",
              },
              [
                _c("team-members-filter", {
                  on: { onSelection: _vm.handleTeamRegionMemberChanged },
                }),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-field",
                      [
                        _c("label", { staticClass: "field-label" }, [
                          _vm._v("Geofence:"),
                        ]),
                        _c(
                          "md-select",
                          {
                            on: { "md-selected": _vm.getReports },
                            model: {
                              value: _vm.filters.geofenceId,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "geofenceId", $$v)
                              },
                              expression: "filters.geofenceId",
                            },
                          },
                          [
                            _c(
                              "md-option",
                              { key: "fence", attrs: { value: "" } },
                              [_vm._v("All")]
                            ),
                            _vm._l(_vm.geofenceList, function (fence, index) {
                              return _c(
                                "md-option",
                                {
                                  key: "fence-" + index,
                                  attrs: { value: fence.geofenceId },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.toGeofenceNameDesc(fence)) +
                                      "\n                                "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c("DateRangeFilter", {
                      attrs: { "is-single-date": false },
                      on: { onChanged: _vm.onDateChanged },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "md-layout-item custom-toolbar-end" },
              [
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-primary md-just-icon md-round pull-right header-button",
                    attrs: { title: "Export Geofence report" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.exportToExcel($event)
                      },
                    },
                  },
                  [_c("md-icon", [_vm._v("get_app")])],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-blue" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("summarize")])],
                    1
                  ),
                ]
              ),
              _c("md-card-content", [
                !_vm.reportDataList || _vm.reportDataList.length == 0
                  ? _c("div", [
                      _c("h3", [_vm._v("Geofence Timesheet Report")]),
                      _c("p", [_vm._v("No results to display.")]),
                    ])
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "report-container" },
                        [
                          _c("GeofenceTimesheetReportDetails", {
                            attrs: { list: _vm.reportDataList },
                          }),
                        ],
                        1
                      ),
                    ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }