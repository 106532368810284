<template>
    <div class="profile-container">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>

                    <router-link
                        :to="{
                            name: 'Business Entities'
                        }"
                    >
                        <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; Business Entities</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ businessEntity.businessRegistrationName }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>account_balance</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div class="cf-container">
                            <div class="cf-action-container">
                                <md-button
                                    title="Edit Business Entity"
                                    class="md-primary md-just-icon md-round"
                                    @click.stop="toggleEdit()"
                                >
                                    <md-icon>edit</md-icon>
                                </md-button>
                            </div>
                            <div class="businessEntity-information field-spacing-container">
                                <form-wrapper :validator="$v.businessEntity" class="form-wrapper">
                                    <div class="information-box">
                                        <div class="md-layout">
                                            <div class="md-layout-item">
                                                <form-group name="businessName" label="Business Name">
                                                    <md-input
                                                        v-model="businessEntity.businessRegistrationName"
                                                        :disabled="!editMode"
                                                    />
                                                </form-group>
                                            </div>
                                            <div class="md-layout-item">
                                                <form-group name="businessNumber" label="Business Number">
                                                    <md-input
                                                        v-model="businessEntity.businessRegistrationNumber"
                                                        :disabled="!editMode"
                                                    />
                                                </form-group>
                                            </div>
                                        </div>

                                        <div class="md-layout">
                                            <div class="md-layout-item">
                                                <form-group name="businessAddress" label="Address">
                                                    <md-input
                                                        v-model="businessEntity.businessAddress"
                                                        :disabled="!editMode"
                                                    />
                                                </form-group>
                                            </div>
                                            <div class="md-layout-item">
                                                <form-group name="companyNumber" label="Company Number">
                                                    <md-input
                                                        v-model="businessEntity.companyNumber"
                                                        :disabled="!editMode"
                                                    />
                                                </form-group>
                                            </div>
                                        </div>

                                        <div class="md-layout">
                                            <div class="md-layout-item">
                                                <brand-filter v-model="businessEntity" v-if="editMode" />
                                                <form-group v-if="!editMode" name="brand" label="Brand">
                                                    <span
                                                        class="default-tracking-info"
                                                        :class="{ empty: businessEntity.brandName == null }"
                                                    >
                                                        <md-icon>info</md-icon>
                                                        <md-tooltip md-direction="top">
                                                            If no brand is selected, the team profile logo will be
                                                            displayed.
                                                        </md-tooltip>
                                                    </span>
                                                    <md-input
                                                        v-model="businessEntity.brandName"
                                                        :disabled="!editMode"
                                                    />
                                                </form-group>
                                            </div>
                                            <div class="md-layout-item">
                                                <form-group name="tags" label="Tags">
                                                    <md-input v-model="businessEntity.tags" :disabled="!editMode" />
                                                </form-group>
                                            </div>
                                        </div>

                                        <div class="md-layout">
                                            <div class="md-layout-item">
                                                <div class="md-layout-item">
                                                    <md-checkbox
                                                        type="checkbox"
                                                        v-model="businessEntity.isRegisteredForTax"
                                                        :disabled="!editMode"
                                                    >
                                                        Is Registered for Tax
                                                    </md-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-actions">
                                        <md-button
                                            v-if="editMode"
                                            class="md-primary dialog-button pull-right"
                                            @click="updateBusinessEntity"
                                        >
                                            Update Business Entity
                                        </md-button>
                                    </div>
                                </form-wrapper>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';
import { BrandFilter } from '@/components';

export default {
    name: 'BusinessEntityDetails',
    mixins: [GeneralMixin],
    components: { BrandFilter },
    data() {
        return {
            businessEntity: {
                businessRegistrationName: null,
                businessRegistrationNumber: null,
                businessAddress: null,
                brandId: null,
                isRegisteredForTax: false
            },
            showUploadButton: false,
            editMode: false
        };
    },
    computed: {},
    async mounted() {
        this.$_handleLoaderState(true);
        this.retrieveBusinessEntityDetails();
        this.$_handleLoaderState(false);
    },
    methods: {
        toggleEdit() {
            this.editMode = !this.editMode;
        },
        onPhotoSelected(event) {
            const [file] = event.target.files;
            this.selectedPhoto = file;
            this.photoPreview = URL.createObjectURL(this.selectedPhoto);
            this.onUploadImage();
        },
        async onUploadImage() {
            this.$_handleLoaderState(true, 'UPLOADING...');
            const file = this.selectedPhoto;
            const fileType = file.type.split('/');

            let ext = fileType[1];

            if (ext === 'jpeg') {
                ext = 'jpg';
            }

            const api = `/api/team-members/${this.$route.params.publicUserId}/photo/?fileType=${ext}`;
            const response = await handleRequests(api, { method: 'post' });

            const blockBlobClient = new BlockBlobClient(response.data.uri);

            try {
                await blockBlobClient.upload(file, file.size);

                this.$notify({
                    message: `Team member's photo has been successfully uploaded. Please wait a moment for it to be processed - it will reflect on your team member profile shortly.`,
                    type: 'success'
                });

                this.selectedPhoto = null;
                this.$_handleLoaderState(false);
            } catch (e) {
                const message = 'Error in uploading photo';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        async updateBusinessEntity() {
            this.$_handleLoaderState(true, 'UPDATING...');
            const api = `/api/business-entities/${this.$route.params.businessEntityId}`;
            const payload = {
                method: 'put',
                data: {
                    businessEntity: this.businessEntity
                }
            };
            handleRequests(api, payload).then(
                (response) => {
                    this.retrieveBusinessEntityDetails();
                    const message = 'Successfully updated';

                    this.$notify({
                        message,
                        type: 'success'
                    });
                    this.$_handleLoaderState(false);
                    this.editMode = false;
                },
                (error) => {
                    const message = 'Error in updating business entity';
                    showErrorMessage(this, message, error);
                    this.$_handleLoaderState(false);
                }
            );
        },
        retrieveBusinessEntityDetails() {
            const api = `/api/business-entities/details/${this.$route.params.businessEntityId}`;
            const payload = {
                method: 'get'
            };
            handleRequests(api, payload).then(
                (response) => {
                    this.businessEntity = response.data;
                    this.$_handleLoaderState(false);
                },
                (error) => {
                    const message = 'Error in updating business entity';
                    showErrorMessage(this, message, error);
                    this.$_handleLoaderState(false);
                }
            );
        }
    },
    validations: {
        businessEntity: {
            businessRegistrationName: { required },
            businessRegistrationNumber: { required },
            businessAddress: { required }
        }
    }
};
</script>

<style lang="scss" scoped>
.md-field.md-disabled .md-input {
    cursor: auto;
}

.md-disabled::after {
    border-bottom: none !important;
}

.default-tracking-info {
    i {
        font-size: 17px !important;
        margin-top: 5px;
        color: #2b93ff !important;
    }
}
.empty {
    i {
        margin-left: 35px;
        margin-top: 8px;
    }
}
</style>
