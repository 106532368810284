var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ratesList.length
    ? _c(
        "div",
        {
          staticClass: "charge-wrapper side-panel",
          attrs: { id: "chargeSidePanel" },
        },
        [
          _c(
            "div",
            [
              _vm._m(0),
              !_vm.ratesIsLoading
                ? _c(
                    "md-table",
                    { staticClass: "custom-paginated-table" },
                    [
                      _c(
                        "md-table-row",
                        [
                          _c("md-table-head"),
                          _c("md-table-head", [_vm._v("Description")]),
                          _c("md-table-head", [_vm._v("Quantity")]),
                          _c("md-table-head", [_vm._v("Amount")]),
                        ],
                        1
                      ),
                      _vm._l(_vm.ratesList, function (rate, index) {
                        return _c(
                          "md-table-row",
                          { key: index },
                          [
                            _c(
                              "md-table-cell",
                              [
                                rate.isManualCharge || rate.manuallyChanged
                                  ? _c("md-icon", [
                                      _c("span", {}, [
                                        _vm._v(
                                          "\n                            drive_file_rename_outline\n                        "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("md-table-cell", [
                              _vm._v(_vm._s(rate.description)),
                            ]),
                            rate.isManualCharge || rate.manuallyChanged
                              ? _c("md-table-cell")
                              : _c("md-table-cell", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(rate.chargeUnit) +
                                      "\n                "
                                  ),
                                ]),
                            _c("md-table-cell", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.$_getRateAmount(rate),
                                      _vm.currency
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        )
                      }),
                      _c(
                        "md-table-row",
                        [
                          _c("md-table-cell"),
                          _c("md-table-cell"),
                          _c("md-table-cell"),
                          _c("md-table-cell", { staticClass: "total-style" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.$_getTotalAmount(_vm.ratesList),
                                    _vm.currency
                                  )
                                ) +
                                "\n                "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.ratesIsLoading
                ? _c("span", { staticClass: "loading-text" }, [
                    _vm._v("Loading Charges..."),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [_c("b", [_vm._v("Charges")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }