var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isReadOnlyUser
    ? _c(
        "md-button",
        {
          staticClass: "md-danger md-just-icon md-round",
          class: [
            "md-danger md-just-icon md-round",
            _vm.isDisabled ? "button-disabled" : "",
          ],
          attrs: { title: "Delete invoice", disabled: _vm.isDisabled },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleDeleteInvoice(_vm.customerInvoiceId)
            },
          },
        },
        [
          _c("md-icon", [_vm._v("delete")]),
          _vm.status === "Paid"
            ? _c(
                "md-tooltip",
                {
                  staticClass: "line-tooltip",
                  attrs: { "md-direction": "left" },
                },
                [
                  _vm._v(
                    "\n        You cannot delete an invoice when it is paid.\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }