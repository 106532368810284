var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showWizardModal
    ? _c("div", { staticClass: "modal-container" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c(
              "md-button",
              {
                staticClass:
                  "md-simple md-just-icon md-round modal-default-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$modal.hide($event)
                  },
                },
              },
              [_c("md-icon", [_vm._v("clear")])],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c(
              "simple-wizard",
              {
                attrs: {
                  plain: "",
                  "show-header": false,
                  title: _vm.title,
                  "sub-title": "",
                  "finish-button-text": !_vm.isUpdate ? "Create" : "Update",
                },
                on: { finish: _vm.validateDetails, stepChange: _vm.stepChange },
              },
              [
                _c(
                  "wizard-tab",
                  {
                    attrs: {
                      "before-change": _vm.validateDetails,
                      id: "maintenanceScheduleDetails",
                    },
                  },
                  [
                    _c("template", { slot: "label" }, [
                      _vm._v(
                        "\n                    Schedule Details\n                "
                      ),
                    ]),
                    _c("div", { staticClass: "md-layout" }, [
                      _c(
                        "div",
                        { staticClass: "md-layout-item md-size-50" },
                        [
                          _vm.step === "maintenanceScheduleDetails"
                            ? _c(
                                "form-wrapper",
                                {
                                  staticClass: "form-wrapper",
                                  attrs: {
                                    validator:
                                      _vm.$v.maintenanceScheduleDetails,
                                  },
                                },
                                [
                                  _c("schedule-details-step", {
                                    attrs: {
                                      "maintenance-schedule-details":
                                        _vm.maintenanceScheduleDetails,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      !_vm.isUpdate
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-size-50 card-expansion",
                            },
                            [
                              _c(
                                "form-wrapper",
                                {
                                  staticClass: "form-wrapper",
                                  attrs: {
                                    validator:
                                      _vm.$v.maintenanceScheduleDetails,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "recurrence-container" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("span", [
                                            _vm._v("Recurrence type:"),
                                          ]),
                                          _c(
                                            "md-radio",
                                            {
                                              attrs: { value: "one-off" },
                                              model: {
                                                value:
                                                  _vm.maintenanceScheduleDetails
                                                    .frequency,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.maintenanceScheduleDetails,
                                                    "frequency",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "maintenanceScheduleDetails.frequency",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        One-off\n                                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-radio",
                                            {
                                              attrs: { value: "recurring" },
                                              model: {
                                                value:
                                                  _vm.maintenanceScheduleDetails
                                                    .frequency,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.maintenanceScheduleDetails,
                                                    "frequency",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "maintenanceScheduleDetails.frequency",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Recurring\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm.maintenanceScheduleDetails.frequency ==
                                    "recurring" || _vm.asset != null
                                    ? _c(
                                        "md-list",
                                        {
                                          staticClass: "custom-accordion-list",
                                          attrs: { "md-expand-single": false },
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "container-checkbox",
                                              },
                                              [
                                                _c("md-checkbox", {
                                                  staticClass:
                                                    "accordion-checkbox",
                                                  model: {
                                                    value: _vm.setTimeReminder,
                                                    callback: function ($$v) {
                                                      _vm.setTimeReminder = $$v
                                                    },
                                                    expression:
                                                      "setTimeReminder",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "container-dropdown context-menu-support-accordion",
                                                class:
                                                  _vm.asset != null &&
                                                  _vm.asset.trackerId != null
                                                    ? "one-time-field"
                                                    : "",
                                              },
                                              [
                                                _c(
                                                  "md-list-item",
                                                  {
                                                    attrs: {
                                                      "md-expand": "",
                                                      "md-expanded":
                                                        _vm.expandTimeReminder,
                                                    },
                                                    on: {
                                                      "update:mdExpanded":
                                                        function ($event) {
                                                          _vm.expandTimeReminder =
                                                            $event
                                                        },
                                                      "update:md-expanded":
                                                        function ($event) {
                                                          _vm.expandTimeReminder =
                                                            $event
                                                        },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "md-list-item-text",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.setTimeReminderTitle(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                Time based reminder\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "md-list",
                                                      {
                                                        attrs: {
                                                          slot: "md-expand",
                                                        },
                                                        slot: "md-expand",
                                                      },
                                                      [
                                                        _vm.asset != null
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "field-box-value",
                                                              },
                                                              [
                                                                _vm
                                                                  .maintenanceScheduleDetails
                                                                  .frequency ==
                                                                "recurring"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "reminder-p interval-title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                        Next due date:\n                                                    "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "reminder-p interval-title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Due on"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "custom-md-field custom-date-picker-field",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "md-datepicker",
                                                                      {
                                                                        ref: "mdDatePicker",
                                                                        attrs: {
                                                                          "md-immediately":
                                                                            "",
                                                                          "md-disabled-dates":
                                                                            _vm.disabledDates,
                                                                          "md-debounce": 10,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.nextServiceDate,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.nextServiceDate =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "nextServiceDate",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm
                                                          .maintenanceScheduleDetails
                                                          .frequency ==
                                                        "recurring"
                                                          ? _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "reminder-p",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "interval-title",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                        Interval\n                                                    "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "frequency-interval-dropdown",
                                                                  {
                                                                    attrs: {
                                                                      date: _vm.nextServiceDate,
                                                                    },
                                                                    on: {
                                                                      recurrenceUpdated:
                                                                        _vm.handleRecurrenceUpdate,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm
                                                          .maintenanceScheduleDetails
                                                          .frequency ==
                                                          "recurring" &&
                                                        _vm.selectedRecurrence ==
                                                          "custom"
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _vm
                                                                  .maintenanceScheduleDetails
                                                                  .timeScheduleUnitType !=
                                                                "weeks"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "field-box-value time-sched-input",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "reminder-p",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "Repeat every"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "form-group",
                                                                          {
                                                                            staticClass:
                                                                              "custom-field-input",
                                                                            attrs:
                                                                              {
                                                                                name: "timeScheduleInterval",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "md-input",
                                                                              {
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .maintenanceScheduleDetails
                                                                                        .timeScheduleInterval,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.maintenanceScheduleDetails,
                                                                                          "timeScheduleInterval",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "\n                                                                    maintenanceScheduleDetails.timeScheduleInterval\n                                                                ",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm
                                                                  .maintenanceScheduleDetails
                                                                  .timeScheduleUnitType ==
                                                                "weeks"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "field-box-value",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "md-layout weekly-box",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              {
                                                                                staticClass:
                                                                                  "reminder-p",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Repeat Every:"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "checkbox-container",
                                                                              },
                                                                              _vm._l(
                                                                                _vm.weekDays,
                                                                                function (
                                                                                  day,
                                                                                  index
                                                                                ) {
                                                                                  return _c(
                                                                                    "md-checkbox",
                                                                                    {
                                                                                      key: day,
                                                                                      attrs:
                                                                                        {
                                                                                          value:
                                                                                            index,
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm.scheduleWeeklyValue,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.scheduleWeeklyValue =
                                                                                                $$v
                                                                                            },
                                                                                          expression:
                                                                                            "scheduleWeeklyValue",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                                    " +
                                                                                          _vm._s(
                                                                                            day
                                                                                          ) +
                                                                                          "\n                                                                "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "md-field",
                                                                  {
                                                                    staticClass:
                                                                      "field-select",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "md-select",
                                                                      {
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .maintenanceScheduleDetails
                                                                              .timeScheduleUnitType,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.maintenanceScheduleDetails,
                                                                                "timeScheduleUnitType",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "\n                                                                maintenanceScheduleDetails.timeScheduleUnitType\n                                                            ",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "md-option",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  "days",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                days\n                                                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "md-option",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  "weeks",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                weeks\n                                                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "md-option",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  "months",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                months\n                                                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "md-option",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  "years",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                years\n                                                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.selectedRecurrence !=
                                                        "daily"
                                                          ? _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "reminder-p",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Remind me"
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "form-group",
                                                                  {
                                                                    staticClass:
                                                                      "short-field",
                                                                    attrs: {
                                                                      name: "timeScheduleBefore",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "md-input",
                                                                      {
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .maintenanceScheduleDetails
                                                                              .timeScheduleBefore,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.maintenanceScheduleDetails,
                                                                                "timeScheduleBefore",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "maintenanceScheduleDetails.timeScheduleBefore",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(
                                                                  "\n                                                    days before.\n                                                "
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.selectedRecurrence !=
                                                        "daily"
                                                          ? _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "reminder-p",
                                                              },
                                                              [
                                                                _vm
                                                                  .maintenanceScheduleDetails
                                                                  .frequency ==
                                                                "recurring"
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "\n                                                        Remind me every\n                                                    "
                                                                      ),
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        "Remind me"
                                                                      ),
                                                                    ]),
                                                                _c(
                                                                  "form-group",
                                                                  {
                                                                    staticClass:
                                                                      "short-field",
                                                                    attrs: {
                                                                      name: "timeScheduleAfter",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "md-input",
                                                                      {
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .maintenanceScheduleDetails
                                                                              .timeScheduleAfter,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.maintenanceScheduleDetails,
                                                                                "timeScheduleAfter",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "maintenanceScheduleDetails.timeScheduleAfter",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(
                                                                  "\n                                                    days after.\n                                                "
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "md-list",
                                    {
                                      staticClass: "custom-accordion-list",
                                      attrs: { "md-expand-single": true },
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "container-checkbox" },
                                          [
                                            _c("md-checkbox", {
                                              staticClass: "accordion-checkbox",
                                              attrs: {
                                                disabled:
                                                  _vm.asset != null &&
                                                  _vm.asset.trackerId == null,
                                              },
                                              model: {
                                                value:
                                                  _vm.setEngineHoursReminder,
                                                callback: function ($$v) {
                                                  _vm.setEngineHoursReminder =
                                                    $$v
                                                },
                                                expression:
                                                  "setEngineHoursReminder",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.asset != null &&
                                        _vm.asset.trackerId == null
                                          ? _c("div", {
                                              class:
                                                _vm.asset != null &&
                                                _vm.asset.trackerId == null
                                                  ? "disabled-overlay"
                                                  : "",
                                            })
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "container-dropdown",
                                            class:
                                              _vm.asset != null &&
                                              _vm.asset.trackerId != null
                                                ? "eh-one-time-field"
                                                : "",
                                          },
                                          [
                                            _c(
                                              "md-list-item",
                                              {
                                                attrs: {
                                                  "md-expand": "",
                                                  "md-expanded":
                                                    _vm.expandEngineHours,
                                                },
                                                on: {
                                                  "update:mdExpanded":
                                                    function ($event) {
                                                      _vm.expandEngineHours =
                                                        $event
                                                    },
                                                  "update:md-expanded":
                                                    function ($event) {
                                                      _vm.expandEngineHours =
                                                        $event
                                                    },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "md-list-item-text",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.setEngineHoursReminderTitle(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Engine Hours Reminder\n                                                "
                                                    ),
                                                    _vm.asset != null &&
                                                    _vm.asset.trackerId == null
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "unavailable-note",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    Tracker Unavailable\n                                                "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "md-list",
                                                  {
                                                    attrs: {
                                                      slot: "md-expand",
                                                    },
                                                    slot: "md-expand",
                                                  },
                                                  [
                                                    _vm.asset != null &&
                                                    _vm.asset.trackerId != null
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "reminder-p",
                                                          },
                                                          [
                                                            _c("span", {}, [
                                                              _vm._v(
                                                                "Current engine hours"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "form-group",
                                                              {
                                                                staticClass:
                                                                  "short-field  distance-field",
                                                                attrs: {
                                                                  name: "currentEngineHoursValue",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "md-input",
                                                                      {
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .maintenanceScheduleDetails
                                                                              .currentEngineHoursValue,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.maintenanceScheduleDetails,
                                                                                "currentEngineHoursValue",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "\n                                                                    maintenanceScheduleDetails.currentEngineHoursValue\n                                                                ",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.asset != null &&
                                                    _vm
                                                      .maintenanceScheduleDetails
                                                      .frequency == "recurring"
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "reminder-p",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "interval-title",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Next due"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "form-group",
                                                              {
                                                                staticClass:
                                                                  "short-field  distance-field",
                                                                attrs: {
                                                                  name: "nextDueEngineHours",
                                                                },
                                                              },
                                                              [
                                                                _c("md-input", {
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .maintenanceScheduleDetails
                                                                        .nextDueEngineHours,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.maintenanceScheduleDetails,
                                                                          "nextDueEngineHours",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "maintenanceScheduleDetails.nextDueEngineHours",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(
                                                              "\n                                                    hours\n                                                "
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "reminder-p",
                                                      },
                                                      [
                                                        _vm
                                                          .maintenanceScheduleDetails
                                                          .frequency ==
                                                        "recurring"
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "\n                                                        Repeat every\n                                                    "
                                                              ),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v("Due"),
                                                            ]),
                                                        _c(
                                                          "form-group",
                                                          {
                                                            staticClass:
                                                              "short-field  distance-field",
                                                            attrs: {
                                                              name: "engineHoursDue",
                                                            },
                                                          },
                                                          [
                                                            _c("md-input", {
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .maintenanceScheduleDetails
                                                                    .engineHoursDue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.maintenanceScheduleDetails,
                                                                      "engineHoursDue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "maintenanceScheduleDetails.engineHoursDue",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(
                                                          "\n\n                                                    hours\n                                                "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "reminder-p",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Remind me"),
                                                        ]),
                                                        _c(
                                                          "form-group",
                                                          {
                                                            staticClass:
                                                              "short-field  distance-field",
                                                            attrs: {
                                                              name: "engineHoursBefore",
                                                            },
                                                          },
                                                          [
                                                            _c("md-input", {
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .maintenanceScheduleDetails
                                                                    .engineHoursBefore,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.maintenanceScheduleDetails,
                                                                      "engineHoursBefore",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "maintenanceScheduleDetails.engineHoursBefore",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(
                                                          "\n                                                    hours before.\n                                                "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "reminder-p",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Remind me every"
                                                          ),
                                                        ]),
                                                        _c(
                                                          "form-group",
                                                          {
                                                            staticClass:
                                                              "short-field  distance-field",
                                                            attrs: {
                                                              name: "engineHoursAfter",
                                                            },
                                                          },
                                                          [
                                                            _c("md-input", {
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .maintenanceScheduleDetails
                                                                    .engineHoursAfter,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.maintenanceScheduleDetails,
                                                                      "engineHoursAfter",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "maintenanceScheduleDetails.engineHoursAfter",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(
                                                          "\n                                                    hours after.\n                                                "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "md-list",
                                    {
                                      staticClass: "custom-accordion-list",
                                      attrs: { "md-expand-single": true },
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "container-checkbox" },
                                          [
                                            _c("md-checkbox", {
                                              staticClass: "accordion-checkbox",
                                              attrs: {
                                                disabled:
                                                  _vm.asset != null &&
                                                  _vm.asset.trackerId == null,
                                              },
                                              model: {
                                                value: _vm.setOdometerReminder,
                                                callback: function ($$v) {
                                                  _vm.setOdometerReminder = $$v
                                                },
                                                expression:
                                                  "setOdometerReminder",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.asset != null &&
                                        _vm.asset.trackerId == null
                                          ? _c("div", {
                                              class:
                                                _vm.asset != null &&
                                                _vm.asset.trackerId == null
                                                  ? "disabled-overlay"
                                                  : "",
                                            })
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "container-dropdown",
                                            class:
                                              _vm.asset != null &&
                                              _vm.asset.trackerId != null
                                                ? "one-time-field odometer-custom-field"
                                                : "",
                                          },
                                          [
                                            _c(
                                              "md-list-item",
                                              {
                                                attrs: {
                                                  "md-expand": "",
                                                  "md-expanded":
                                                    _vm.expandOdometer,
                                                },
                                                on: {
                                                  "update:mdExpanded":
                                                    function ($event) {
                                                      _vm.expandOdometer =
                                                        $event
                                                    },
                                                  "update:md-expanded":
                                                    function ($event) {
                                                      _vm.expandOdometer =
                                                        $event
                                                    },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "md-list-item-text",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.setOdometerReminderTitle(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Distance Based Reminder\n                                                "
                                                    ),
                                                    _vm.asset != null &&
                                                    _vm.asset.trackerId == null
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "unavailable-note",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    Tracker Unavailable\n                                                "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "md-list",
                                                  {
                                                    attrs: {
                                                      slot: "md-expand",
                                                    },
                                                    slot: "md-expand",
                                                  },
                                                  [
                                                    _vm.asset != null &&
                                                    _vm.asset.trackerId != null
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "reminder-p",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "interval-title",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Current odometer reading"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "form-group",
                                                              {
                                                                staticClass:
                                                                  "short-field distance-field",
                                                                attrs: {
                                                                  name: "currentOdometerValue",
                                                                },
                                                              },
                                                              [
                                                                _c("md-input", {
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .maintenanceScheduleDetails
                                                                        .currentOdometerValue,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.maintenanceScheduleDetails,
                                                                          "currentOdometerValue",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "\n                                                                maintenanceScheduleDetails.currentOdometerValue\n                                                            ",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.defaultDistanceUnit
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.asset != null &&
                                                    _vm
                                                      .maintenanceScheduleDetails
                                                      .frequency == "recurring"
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "reminder-p",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "interval-title",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Next due"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "form-group",
                                                              {
                                                                staticClass:
                                                                  "short-field distance-field",
                                                                attrs: {
                                                                  name: "nextDueOdometer",
                                                                },
                                                              },
                                                              [
                                                                _c("md-input", {
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .maintenanceScheduleDetails
                                                                        .nextDueOdometer,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.maintenanceScheduleDetails,
                                                                          "nextDueOdometer",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "maintenanceScheduleDetails.nextDueOdometer",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.defaultDistanceUnit
                                                                ) +
                                                                ".\n                                                "
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "reminder-p",
                                                      },
                                                      [
                                                        _vm
                                                          .maintenanceScheduleDetails
                                                          .frequency ==
                                                        "recurring"
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "\n                                                        Repeat every\n                                                    "
                                                              ),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v("Due"),
                                                            ]),
                                                        _c(
                                                          "form-group",
                                                          {
                                                            staticClass:
                                                              "short-field distance-field",
                                                            attrs: {
                                                              name: "odometerDue",
                                                            },
                                                          },
                                                          [
                                                            _c("md-input", {
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .maintenanceScheduleDetails
                                                                    .odometerDue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.maintenanceScheduleDetails,
                                                                      "odometerDue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "maintenanceScheduleDetails.odometerDue",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              _vm.defaultDistanceUnit
                                                            ) +
                                                            ".\n                                                "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "reminder-p",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Remind me"),
                                                        ]),
                                                        _c(
                                                          "form-group",
                                                          {
                                                            staticClass:
                                                              "short-field distance-field",
                                                            attrs: {
                                                              name: "odometerBefore",
                                                            },
                                                          },
                                                          [
                                                            _c("md-input", {
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .maintenanceScheduleDetails
                                                                    .odometerBefore,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.maintenanceScheduleDetails,
                                                                      "odometerBefore",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "maintenanceScheduleDetails.odometerBefore",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              _vm.defaultDistanceUnit
                                                            ) +
                                                            " before.\n                                                "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "reminder-p",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Remind me every"
                                                          ),
                                                        ]),
                                                        _c(
                                                          "form-group",
                                                          {
                                                            staticClass:
                                                              "short-field distance-field",
                                                            attrs: {
                                                              name: "odometerAfter",
                                                            },
                                                          },
                                                          [
                                                            _c("md-input", {
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .maintenanceScheduleDetails
                                                                    .odometerAfter,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.maintenanceScheduleDetails,
                                                                      "odometerAfter",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "maintenanceScheduleDetails.odometerAfter",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              _vm.defaultDistanceUnit
                                                            ) +
                                                            " after.\n                                                "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  2
                ),
                _c(
                  "wizard-tab",
                  {
                    attrs: {
                      "before-change": _vm.validateDetails,
                      id: "notificationConfiguration",
                    },
                  },
                  [
                    _c("template", { slot: "label" }, [
                      _vm._v(
                        "\n                    Notification Configuration\n                "
                      ),
                    ]),
                    _vm.step === "notificationConfiguration"
                      ? _c(
                          "form-wrapper",
                          {
                            staticClass: "form-wrapper notification-tab-pane",
                            attrs: {
                              validator: _vm.$v.notificationConfiguration,
                            },
                          },
                          [
                            _c("div", { staticClass: "notification-table" }, [
                              _c("div"),
                              _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "notification-title" },
                                  [_vm._v("Email")]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "notification-table" }, [
                              _c("div", [_vm._v("Team Owner")]),
                              _c(
                                "div",
                                [
                                  _c("md-checkbox", {
                                    attrs: { value: "Team Owner" },
                                    model: {
                                      value:
                                        _vm.maintenanceScheduleDetails
                                          .notificationConfiguration.email,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.maintenanceScheduleDetails
                                            .notificationConfiguration,
                                          "email",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "maintenanceScheduleDetails.notificationConfiguration.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "notification-table" }, [
                              _c("div", [_vm._v("Team Manager")]),
                              _c(
                                "div",
                                [
                                  _c("md-checkbox", {
                                    attrs: { value: "Team Manager" },
                                    model: {
                                      value:
                                        _vm.maintenanceScheduleDetails
                                          .notificationConfiguration.email,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.maintenanceScheduleDetails
                                            .notificationConfiguration,
                                          "email",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "maintenanceScheduleDetails.notificationConfiguration.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "notification-table" }, [
                              _c("div", [_vm._v("Assigned Driver")]),
                              _c(
                                "div",
                                [
                                  _c("md-checkbox", {
                                    attrs: { value: "Assigned Driver" },
                                    model: {
                                      value:
                                        _vm.maintenanceScheduleDetails
                                          .notificationConfiguration.email,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.maintenanceScheduleDetails
                                            .notificationConfiguration,
                                          "email",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "maintenanceScheduleDetails.notificationConfiguration.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "notification-table" }, [
                              _c("div", [_vm._v("Additional Emails")]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "md-field",
                                    {},
                                    [
                                      _c("md-input", {
                                        attrs: {
                                          placeholder:
                                            "test@locate2u.com; maintenance@locate2u.com",
                                        },
                                        model: {
                                          value:
                                            _vm.maintenanceScheduleDetails
                                              .notificationConfiguration
                                              .additionalEmails,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.maintenanceScheduleDetails
                                                .notificationConfiguration,
                                              "additionalEmails",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "maintenanceScheduleDetails.notificationConfiguration.additionalEmails",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }