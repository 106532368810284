<template>
    <div class="services-dashboard">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.services') }}</h3>
                </div>
            </div>
        </div>
        <services-toolbar
            :class="$root.isTablet ? 'tablet-filter-container' : ''"
            :rate-groups="rateGroups"
            @onCreateService="onCreateService"
            @onFileSelected="onFileSelected"
            @onFilterServices="handleFilterOrSearch"
        />
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="error-message-container">
                    <batch-upload-error-message :content="errorMessages" :has-error-message="hasError" />
                </div>
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-warning">
                        <div class="card-icon">
                            <md-icon>store</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div v-if="isListLoading" class="empty-table">
                            <div class="table-loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="servicesList.length">
                                <md-table class="context-menu-support custom-paginated-table">
                                    <md-table-row>
                                        <md-table-head>Description</md-table-head>
                                        <md-table-head>Unit Price (Ex. Tax)</md-table-head>
                                        <md-table-head>Unit Cost (Ex. Tax)</md-table-head>
                                        <md-table-head>Quantity Type</md-table-head>
                                        <md-table-head>Actions</md-table-head>
                                    </md-table-row>
                                    <!--@click.stop="showSidebar(item.serviceId)"-->
                                    <md-table-row v-for="service in servicesList" :key="service.serviceId">
                                        <md-table-cell>
                                            <a class="cell-hover" @click.stop="updateService(service.serviceId)">
                                                {{ service.name }}
                                            </a>
                                        </md-table-cell>

                                        <md-table-cell>
                                            <span v-if="service.unitPriceExTax">
                                                {{ service.unitPriceExTax | currency(service.costCurrency) }}
                                            </span>
                                            <span v-else-if="service.defaultUnitPriceExTax" class="default-price">
                                                {{ service.defaultUnitPriceExTax | currency(service.costCurrency) }}
                                            </span>
                                            <span v-else>
                                                -
                                            </span>
                                        </md-table-cell>
                                        <md-table-cell>
                                            <span v-if="service.unitCostExTax">
                                                {{ service.unitCostExTax | currency(service.costCurrency) }}
                                            </span>
                                            <span v-else-if="service.defaultUnitCostExTax" class="default-price">
                                                {{ service.defaultUnitCostExTax | currency(service.costCurrency) }}
                                            </span>
                                            <span v-else>
                                                -
                                            </span>
                                        </md-table-cell>

                                        <md-table-cell>{{ service.quantityType }}</md-table-cell>

                                        <md-table-cell class="action-buttons">
                                            <md-button
                                                title="Update service details"
                                                class="md-warning md-just-icon md-round"
                                                @click.stop="updateService(service.serviceId)"
                                            >
                                                <md-icon>edit</md-icon>
                                            </md-button>
                                            <md-button
                                                title="Delete service"
                                                class="md-danger md-just-icon md-round"
                                                @click.stop="handleDeleteService(service.serviceId)"
                                            >
                                                <md-icon>delete</md-icon>
                                            </md-button>
                                        </md-table-cell>
                                    </md-table-row>
                                </md-table>
                            </div>
                            <div v-else>
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
                <md-card-actions class="page-footer" md-alignment="space-between">
                    <div>
                        <p v-if="total === pagination.perPage" class="card-category">
                            Page {{ pagination.currentPage }} of many
                        </p>
                        <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ maxPage }}</p>
                    </div>

                    <pagination
                        v-model="pagination.currentPage"
                        class="pagination-no-border pagination-success"
                        :per-page="pagination.perPage"
                        :total="total"
                        @change-page="handleChangePage($event, pagination.perPage)"
                    />
                </md-card-actions>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { SERVICE_DETAILS_DEFAULTS, PAGINATION_DEFAULTS, SERVICE_RATE_DETAILS_DEFAULTS } from '@/utils/defaults';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { Pagination, BatchUploadErrorMessage } from '@/components';
import { mapGetters } from 'vuex';
import { ServicesToolbar } from './components';
import CreateServiceModal from './Modals/CreateServiceModal';
// NOTE: Below component is used in Stops. We use the same in warehouses. Shipments have their own which is a clone.
//       Maybe we consider extracting it to the "@/components/BatchUploadErrorMessage' and start using this?

export default {
    name: 'ServicesOverview',
    mixins: [GeneralMixin],
    components: { ServicesToolbar, FadeLoader, Pagination, BatchUploadErrorMessage },
    data() {
        return {
            servicesList: [],
            isListLoading: false,
            pagination: PAGINATION_DEFAULTS,
            filters: {},
            maxPage: 1,
            /* isShowSidebar: false, */
            serviceId: null,
            hasError: false,
            errorMessages: [],
            rateGroups: []
        };
    },
    computed: {
        total() {
            return this.pagination.total;
        },
        ...mapGetters({
            user: 'user/user',
            canViewTeamRegion: 'user/canViewTeamRegion'
            // teamRegions: 'team/teamRegions',
            // isSingleUser: 'user/isIndividualUser',
            // isSingleTeamMember: 'team/isSingleTeamMember'
        })
    },
    async mounted() {
        // For testing
        // this.pagination.perPage = 4;

        this.rateGroups = [...(await this.getRateGroupsList())];

        const currentPage = Number(this.$route.query.currentPage) || 1;
        if (currentPage) {
            this.pagination.currentPage = currentPage;
        }

        const {
            data: { services, totalNumberOfServices }
        } = await this.getServicesList(currentPage);

        this.setServicesAndTotal(services, totalNumberOfServices);
    },
    methods: {
        async getRateGroupsList() {
            let response = {
                data: []
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/rate-groups`;

                response = await handleRequests(endpoint);
            } catch (error) {
                const message = 'Error in getting the rate groups list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;

            // this.isListLoading = false;
            return response.data.map((rg) => {
                return {
                    rateGroupId: rg.rateGroupId,
                    rateGroupName: rg.rateGroupName
                };
            });
        },
        async handleFilterOrSearch(data) {
            this.filters = Object.assign(this.filters, data);
            const {
                data: { services, totalNumberOfServices }
            } = await this.getServicesList(1);

            this.setServicesAndTotal(services, totalNumberOfServices);
        },
        async getServicesList(pageNumber = 1) {
            let response = {
                data: {
                    services: [],
                    totalNumberOfServices: null
                }
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/services/rates`;

                response = await handleRequests(endpoint, {
                    params: {
                        pageNumber,
                        itemsPerPage: this.pagination.perPage,
                        ...this.cleanFilters()
                    }
                });
            } catch (error) {
                const message = 'Error in getting the services list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;
            return response;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },
        setServicesAndTotal(data, total) {
            this.servicesList = data;
            this.pagination.total = total || data.length;
            this.maxPage = Math.ceil(this.pagination.total / this.pagination.perPage);
        },
        createServiceDetailsObject(det) {
            const serviceDetails = Object.assign({}, det);

            if (!serviceDetails.serviceRates) 
                serviceDetails.serviceRates = [];

            // rateGroups: this.rateGroups
            this.rateGroups
                .filter((rg) => rg.rateGroupId !== null)
                .forEach((rg) => {
                    const found = serviceDetails.serviceRates.find((r) => r.rateGroupId === rg.rateGroupId);
                    if (found) 
                        return;

                    const rate = Object.assign({}, ...SERVICE_RATE_DETAILS_DEFAULTS, rg);

                    serviceDetails.serviceRates.push(rate);
                });

            return serviceDetails;
        },
        async onCreateService(data) {
            this.$modal
                .show(CreateServiceModal, {
                    teamRegionId: this.filters.teamRegionId,
                    serviceDetails: this.createServiceDetailsObject(SERVICE_DETAILS_DEFAULTS())
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
                        this.$modal.hide();
                    }
                });
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            const {
                data: { services, totalNumberOfServices }
            } = await this.getServicesList(currentPage);
            const total = totalNumberOfServices;
            this.setServicesAndTotal(services, total);
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
        },
        // showSidebar(id) {
        //    this.isShowSidebar = true;
        //    this.serviceId = id;
        // },
        // toggleSidebarWindow() {
        //    if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown)
        //        this.isShowSidebar = !this.isShowSidebar;
        // },
        async onFileSelected(file) {
            if (file) {
                this.$_handleLoaderState(true, 'IMPORTING...');

                const fd = new FormData();
                fd.append('file', file);

                const api = `/api/services/import?teamRegionId=${this.filters.teamRegionId}&rateGroupId=${
                    this.filters.rateGroupId
                }`;
                const payload = {
                    method: 'post',
                    data: fd
                };

                try {
                    const response = await handleRequests(api, payload);

                    if (response.status === 200) {
                        const message =
                            `${response.data.created +
                                response.data.updated} services have been successfully uploaded:<BR/>` +
                            `&emsp;${response.data.created} created.<BR />` +
                            `&emsp;${response.data.updated} updated.`;

                        this.hasError = false;
                        this.errorMessages = [];
                        this.$notify({
                            message,
                            type: 'success',
                            duration: 8000
                        });
                    }
                    this.$_handleLoaderState(false);
                    this.handleChangePage(1, this.pagination.perPage);
                } catch (error) {
                    this.$_handleLoaderState(false);
                    this.hasError = true;
                    this.errorMessages = error.data;
                    if (!error.data) {
                        const message = 'Error in importing services';
                        showErrorMessage(this, message, error);
                    }
                }
            }
        },
        async getServiceDetails(serviceId, teamRegionId) {
            try {
                const api = `/api/services/${serviceId}?teamRegionId=${teamRegionId}`;
                const { data } = await handleRequests(api);
                return data;
            } catch (error) {
                const message = 'Error in getting the service details';
                showErrorMessage(this, message, error);
            }
            return {};
        },
        async updateService(serviceId) {
            const serviceDetails = await this.getServiceDetails(serviceId, this.filters.teamRegionId);

            this.$modal
                .show(CreateServiceModal, {
                    teamRegionId: this.filters.teamRegionId,
                    serviceDetails: this.createServiceDetailsObject(serviceDetails),
                    isUpdate: true
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);

                        this.$modal.hide();
                    }
                });
        },
        async deleteService(serviceId) {
            const payload = {
                method: 'delete'
            };
            const api = `/api/services/${serviceId}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Service was deleted!',
                    type: 'success'
                });

                // If the item deleted was the last one, then move to the previous page.
                if ((this.pagination.currentPage - 1) * this.pagination.perPage === this.pagination.total - 1) {
                    this.pagination.currentPage -= 1;
                    if (this.pagination.currentPage < 1) 
                        this.pagination.currentPage = 1;
                }

                this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
            } catch (e) {
                const message = 'Error in deleting a service.';
                showErrorMessage(this, message, e);
                this.isListLoading = false;
            }
        },
        handleDeleteService(serviceId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Service',
                    body: 'Are you sure you want to delete this service?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isListLoading = true;
                        await this.deleteService(serviceId);
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .error-body div {
    margin: 0;
    margin-top: 20px;
    padding: 10px;
}

.services-dashboard ::v-deep .md-card {
    margin-bottom: 0;
}

.default-price {
    color: darkgray;
}

.cell-hover {
    cursor: pointer;
}
</style>
