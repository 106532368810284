<template>
    <div class="content">
        <team-member-invoices-overview-toolbar
            :class="$root.isTablet ? 'tablet-filter-container' : ''"
            @onFilterInvoices="handleFilterOrSearch"
            @onExportClicked="handleExportClick"
            @onSendToAccountingPackageClicked="processInvoicesBasedOnFilter"
            @onCreateInvoice="handleGenerateInvoice"
            :team-members="() => this.teamMembers"
            :team-region-id="filters.teamRegionId"
            :batch-filter-list="batchFilterList"
            :disable-export="
                !((this.selectedMessage && this.selectedMessage > 0) || (invoicesList && invoicesList.length > 0))
            "
        />
        <div class="md-layout">
            <div class="md-layout-item">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-warning">
                        <div class="card-icon">
                            <md-icon>receipt</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div
                            v-if="
                                showProgressBox &&
                                    selectedTeamMemberInvoiceIds != null &&
                                    selectedTeamMemberInvoiceIds.length > 0
                            "
                            class="bulk-section"
                        >
                            <div>Cannot perform bulk operations while generating invoice.</div>
                        </div>
                        <div class="bulk-section" v-if="selectedMessage && (!showProgressBox || isProgressComplete)">
                            <div>{{ selectedMessage }} invoice{{ selectedMessage > 1 ? 's' : '' }} selected.</div>
                            <div>
                                <payment-status-button
                                    :is-bulk="true"
                                    @invoiceBulkStatusUpdate="handleBulkChangeStatus"
                                    :bulk-team-member-invoice-ids="selectedTeamMemberInvoiceIds"
                                />
                                <md-button
                                    v-if="hasAccountingPackage"
                                    class="md-round md-just-icon md-warning"
                                    title="Send to Accounting Package"
                                    @click="handleSendToAccountingPackageClick"
                                >
                                    <md-icon>send</md-icon>
                                </md-button>
                                <send-email-button
                                    :is-bulk="true"
                                    :bulk-team-member-invoice-ids="selectedTeamMemberInvoiceIds"
                                    @handleBulkSent="handleBulkSentEmail"
                                />
                                <delete-invoice-button
                                    :is-bulk="true"
                                    :bulk-team-member-invoice-ids="selectedTeamMemberInvoiceIds"
                                    @invoiceBulkDelete="handleBulkDelete"
                                />
                            </div>
                        </div>
                        <div :key="updateList" v-if="!isListLoading">
                            <md-table class="context-menu-support custom-paginated-table">
                                <md-table-row>
                                    <md-table-head>
                                        <md-checkbox
                                            class="shipment-checkbox checkbox-head"
                                            v-model="isSelectAll"
                                            @change="onSelectAll(isSelectAll)"
                                        ></md-checkbox>
                                    </md-table-head>
                                    <md-table-head>Invoice Number</md-table-head>
                                    <md-table-head>Team Member</md-table-head>
                                    <md-table-head>Invoice Date</md-table-head>
                                    <md-table-head>Due Date</md-table-head>
                                    <md-table-head md-numeric>Subtotal</md-table-head>
                                    <md-table-head md-numeric>Tax</md-table-head>
                                    <md-table-head md-numeric>Total Amount</md-table-head>

                                    <md-table-head>Status</md-table-head>
                                    <md-table-head v-if="$root.isDesktop && !isReadOnlyUser">Actions</md-table-head>
                                </md-table-row>
                                <md-table-row
                                    v-for="invoice in invoicesList"
                                    :key="invoice.teamMemberInvoiceId"
                                    @click.stop="showSidebar(invoice.teamMemberInvoiceId)"
                                >
                                    <md-table-cell>
                                        <md-checkbox
                                            class="shipment-checkbox"
                                            :value="invoice.teamMemberInvoiceId"
                                            v-model="selectedTeamMemberInvoiceIds"
                                        ></md-checkbox>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <router-link
                                            class="invoice-ref"
                                            :to="{
                                                name: 'Team Member Invoice Details',
                                                params: { invoiceId: invoice.teamMemberInvoiceId }
                                            }"
                                            target="_blank"
                                        >
                                            {{ invoice.invoiceNumber }}
                                        </router-link>
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ invoice.teamMember.fullName }}
                                    </md-table-cell>

                                    <md-table-cell>
                                        {{ invoice.invoiceDate | dateFormat(DATE_TYPES.standardDate) }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ invoice.dueDate | dateFormat(DATE_TYPES.standardDate) }}
                                    </md-table-cell>
                                    <md-table-cell md-numeric>
                                        {{ invoice.subTotal | currency(invoice.currency) }}
                                    </md-table-cell>
                                    <md-table-cell md-numeric>
                                        {{ invoice.totalTax | currency(invoice.currency) }}
                                    </md-table-cell>
                                    <md-table-cell md-numeric>
                                        {{ invoice.total | currency(invoice.currency) }}
                                    </md-table-cell>

                                    <md-table-cell>
                                        <payment-status-button
                                            :invoice-details="invoice"
                                            @statusUpdated="onChangedInvoiceStatus"
                                        />
                                    </md-table-cell>

                                    <md-table-cell v-if="$root.isDesktop && !isReadOnlyUser" class="action-buttons">
                                        <sync-invoice-button :invoice="invoice" v-if="hasAccountingPackage" />
                                        <generate-pdf-button
                                            v-if="!isReadOnlyUser"
                                            :invoice-id="invoice.teamMemberInvoiceId"
                                        />
                                        <generate-excel-button
                                            v-if="!isReadOnlyUser"
                                            :invoice-id="invoice.teamMemberInvoiceId"
                                        />
                                        <send-email-button
                                            v-if="!isReadOnlyUser"
                                            :invoice-id="invoice.teamMemberInvoiceId"
                                            :email-sent-date="invoice.lastEmailDateSent"
                                            @invoiceSent="handleSentEmail"
                                        />
                                        <delete-invoice-button
                                            :invoice-id="invoice.teamMemberInvoiceId"
                                            @invoiceDeleted="handleDeleteInvoice"
                                        />
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <div v-if="invoicesList.length == 0">
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
                <md-card-actions class="page-footer" md-alignment="space-between" v-if="total >= pagination.perPage">
                    <div class>
                        <p class="card-category">Showing page {{ pagination.currentPage }} of {{ maxPage }}</p>
                    </div>
                    <pagination
                        v-model="pagination.currentPage"
                        class="pagination-no-border pagination-success"
                        :per-page="pagination.perPage"
                        :total="total"
                        @change-page="handleChangePage($event, pagination.perPage)"
                    />
                </md-card-actions>
            </div>

            <transition name="slide">
                <team-member-invoices-sidebar
                    v-if="isShowSidebar"
                    :team-member-invoice-id="teamMemberInvoiceId"
                    v-click-outside="toggleSidebarWindow"
                    @closeModal="toggleSidebarWindow"
                />
            </transition>
        </div>

        <div class="progress-container" v-if="showProgressBox">
            <div class="button-close">
                <md-button class="md-default md-just-icon md-round" @click="handleCloseProgressBox">
                    <md-icon>close</md-icon>
                </md-button>
            </div>
            <div class="progress-text-section" v-if="!isProgressComplete">
                <div>
                    <div class="btn-loader route-loader"></div>
                </div>
                <div v-if="totalInvoice == 0">Preparing invoices...</div>
                <div v-else>{{ currentProcessedInvoice }} of {{ totalInvoice }} invoices have been generated.</div>
            </div>
            <div v-else class="progress-text-section">
                <div>
                    <md-icon class="green-check">check_circle</md-icon>
                </div>
                <div>
                    Process has been completed.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { handleRequests, showErrorMessage, downloadFile } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { mapGetters } from 'vuex';
import { Pagination } from '@/components';
import { DATE_TYPES, SEND_TO_ACCOUNTING_CONSTANTS } from '@/utils/constants';
import moment from 'moment';
import { TeamMemberInvoicesOverviewToolbar } from './components';
import TeamMemberInvoicesSidebar from './TeamMemberInvoicesSidebar';
import {
    SyncInvoiceButton,
    GeneratePdfButton,
    GenerateExcelButton,
    SendEmailButton,
    PaymentStatusButton,
    DeleteInvoiceButton
} from './buttons';

const signalR = require('@aspnet/signalr');

export default {
    name: 'TeamMemberInvoicesOverview',
    mixins: [GeneralMixin],
    components: {
        TeamMemberInvoicesOverviewToolbar,
        FadeLoader,
        TeamMemberInvoicesSidebar,
        Pagination,
        SyncInvoiceButton,
        GeneratePdfButton,
        GenerateExcelButton,
        SendEmailButton,
        PaymentStatusButton,
        DeleteInvoiceButton
    },
    data() {
        return {
            invoicesList: [],
            isListLoading: false,
            pagination: PAGINATION_DEFAULTS,
            filters: {
                teamMemberPublicUserId: '',
                teamRegionId: null
            },
            maxPage: 1,
            isShowSidebar: false,
            teamMemberInvoiceId: null,
            selectedFile: null,
            hasError: false,
            errorMessages: [],
            updateList: 0,
            teamMembers: [],
            selectedTeamMemberId: '',
            selectedTeamMemberInvoiceIds: [],
            isSelectAll: false,
            totalInvoiceToProcessValue: 0,
            totalInvoiceCompletedValue: 0,
            totalInvoiceFailedValue: 0,
            isProcessAll: false,
            connection: '',
            batchFilterList: [],
            showProgressBox: false,
            totalInvoice: 0,
            currentProcessedInvoice: 0,
            isProgressComplete: false,
            generatedInvoiceIds: []
        };
    },
    computed: {
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser',
            hasAccountingPackage: 'generalSetting/hasAccountingPackage'
        }),
        selectedMessage() {
            return this.selectedTeamMemberInvoiceIds.length;
        }
    },
    async mounted() {
        await this.$store.dispatch('generalSetting/FETCH_TEAM_ACCOUNTING_PACKAGE');
        this.filters.teamRegionId = null; // All
        this.getTeamMembers();
        const currentPage = Number(this.$route.query.currentPage) || 1;
        if (currentPage) {
            this.pagination.currentPage = currentPage;
        }

        await this.handleChangePage(currentPage, this.pagination.perPage);

        this.setupSignalR();
    },
    methods: {
        handleCloseProgressBox() {
            this.showProgressBox = false;
        },
        onChangedInvoiceStatus(response) {
            const { teamMemberInvoiceId, status } = response;
            const invoice = this.invoicesList.find((item) => item.teamMemberInvoiceId === teamMemberInvoiceId);
            this.$set(invoice, 'status', status);
        },
        sendToAccountingPackage() {},
        async handleSyncInvoice(item) {
            if (item.isSyncing === undefined) {
                this.$set(item, 'isSyncing', true);
            } else {
                item.isSyncing = true;
            }

            try {
                const endpoint = `/api/team-member-invoices/send-to-accounting`;
                const data = {
                    invoiceIdList: [item.teamMemberInvoiceId]
                };
                const payload = {
                    method: 'post',
                    data
                };
                await handleRequests(endpoint, payload);
            } catch (error) {
                item.isSyncing = false;
            }
        },
        onSelectAll(value) {
            if (value) {
                this.invoicesList.forEach((invoice) => {
                    this.selectedTeamMemberInvoiceIds.push(invoice.teamMemberInvoiceId);
                });
            } else {
                this.selectedTeamMemberInvoiceIds = [];
            }
        },
        async getTeamMembers(tripDate = null) {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS', {
                date: tripDate,
                teamRegionId: this.selectedTeamRegionId
            });
        },
        async getInvoicesList(pageNumber = 1, invoicesPerPage = 50) {
            try {
                this.isListLoading = true;
                const endpoint = `/api/team-member-invoices/search`;
                const response = await handleRequests(endpoint, {
                    params: {
                        pageNumber,
                        invoicesPerPage,
                        ...this.cleanFilters()
                    }
                });

                const { invoices, totalInvoices, batchFilters } = response.data;

                this.batchFilterList = batchFilters.map((item) => {
                    return { id: item.invoiceBatchId, label: this.formatDate(item.startDate, item.endDate) };
                });

                this.invoicesList = invoices;

                // Prevent sending of individual invoice to accounting package if there was a recent unfinished last sync attempt
                for (let i = 0; i < this.invoicesList.length; i++) {
                    const invoice = this.invoicesList[i];

                    if (
                        invoice.accountingSystemLastSyncAttemptDate &&
                        !invoice.accountingSystemRef &&
                        !invoice.accountingSystemNote
                    ) {
                        const now = moment.utc();
                        const accountingSystemLastSyncAttemptDate = moment.utc(
                            invoice.accountingSystemLastSyncAttemptDate
                        );

                        if (
                            now.diff(accountingSystemLastSyncAttemptDate, 'minutes') <
                            SEND_TO_ACCOUNTING_CONSTANTS.enableRetryAfterMinutes
                        ) {
                            invoice.isSyncing = true;
                            invoice.retryTooltip = 'Sending is still in progress. Please check again later.';
                        }
                    }
                }

                this.pagination.total = totalInvoices || this.invoicesList.length;
                this.maxPage = Math.ceil(this.pagination.total / this.pagination.perPage);
            } catch (error) {
                const message = 'Error in getting the team member invoice list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;
        },
        formatDate(startDate, endDate) {
            const formattedStartDate = this.$options.filters.dateFormat(startDate, DATE_TYPES.standardDate);
            const formattedEndDate = this.$options.filters.dateFormat(endDate, DATE_TYPES.standardDate);

            return `${formattedStartDate} - ${formattedEndDate}`;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });

            return this.filters;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            await this.getInvoicesList(currentPage, perPage);
        },
        showSidebar(id) {
            this.isShowSidebar = true;
            this.teamMemberInvoiceId = id;
        },
        toggleSidebarWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown)
                this.isShowSidebar = !this.isShowSidebar;
        },
        async handleFilterOrSearch(data) {
            this.filters = Object.assign(this.filters, data);
            this.handleChangePage(1, this.pagination.perPage);
        },
        async handleExportClick(data) {
            this.filters = Object.assign(this.filters, data);
            this.$_handleLoaderState(true, 'EXPORTING...');
            try {
                const isBulk = this.selectedMessage && this.selectedMessage > 0;
                const endpoint = `/api/team-member-invoices/${isBulk ? 'bulk/export' : 'export'}`;
                const payload = {
                    method: isBulk ? 'post' : 'get',
                    responseType: 'blob',
                    ...(isBulk
                        ? { data: { invoiceIds: this.selectedTeamMemberInvoiceIds } }
                        : { params: { ...this.cleanFilters() } })
                };

                const response = await handleRequests(endpoint, payload);
                downloadFile(response.data, 'invoicesList.xlsx');
            } catch (error) {
                const message = 'Error in exporting invoices.';
                showErrorMessage(this, message, error);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async setupSignalR() {
            const userCrendentials = await window.auth.getUser();

            this.connection = new signalR.HubConnectionBuilder()
                .withUrl('/api/InvoicingHub', {
                    accessTokenFactory: () => {
                        return userCrendentials.access_token;
                    }
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            try {
                await this.connection.start().then((result) => {
                    this.connection.invoke('JoinToTeamChannel');
                });

                this.connection.onclose(async () => {
                    await this.setupSignalR();
                });

                this.connection.on(
                    'TeamMemberInvoiceBatchCompleted',
                    async (batchId, status, completedInvoiceCount) => {
                        this.isProgressComplete = true;

                        if (status === 'CompletedNoRecords') {
                            this.handleCloseProgressBox();

                            this.$notifySuccess(
                                'Invoice batch completed but no uninvoiced stops found in range.',
                                60000
                            );
                        } else {
                            this.handleCloseProgressBox();

                            let successMessage = `${completedInvoiceCount} Invoices have been generated.`;
                            if (completedInvoiceCount === 1) {
                                successMessage = `${completedInvoiceCount} Invoice has been generated.`;
                            }
                            this.$notifySuccess(successMessage, 60000);
                        }
                    }
                );

                this.connection.on(
                    'TeamMemberInvoiceBatchFailed',
                    async (status, completedInvoiceCount, failedInvoiceCount) => {
                        this.isProgressComplete = true;
                        this.handleCloseProgressBox();

                        this.$notify({
                            message: `Completed Invoices: ${completedInvoiceCount} Failed Invoices: ${failedInvoiceCount}.<br /><br /> Please try again. If error persists please contact support.`,
                            type: 'warning',
                            duration: 10000
                        });
                    }
                );

                this.connection.on('InvoiceSentResult', this.handleInvoiceSentResult);
                this.connection.on('BulkInvoiceResult', this.handleBulkInvoiceResult);
                this.connection.on('TotalInvoiceToBeGenerated', this.handleTotalInvoiceToBeGenerated);
                this.connection.on('InvoiceGenerated', this.handleInvoiceGenerated);
            } catch (err) {
                setTimeout(this.setupSignalR, 5000);
            }
        },
        async handleSendToAccountingPackageClick() {
            this.isProcessAll = true;
            this.$_handleLoaderState(true, 'SENDING...');
            this.selectedTeamMemberInvoiceIds.forEach((invoiceId) => {
                const invoice = this.invoicesList.find((x) => x.teamMemberInvoiceId === invoiceId);

                if (invoice.isSyncing === undefined) {
                    this.$set(invoice, 'isSyncing', true);
                } else {
                    invoice.isSyncing = true;
                }
            });

            try {
                const endpoint = `/api/team-member-invoices/send-to-accounting`;
                const data = {
                    invoiceIdList: this.selectedTeamMemberInvoiceIds
                };
                const payload = {
                    method: 'post',
                    data
                };
                await handleRequests(endpoint, payload);
            } catch (error) {
                this.isProcessAll = false;
                this.$_handleLoaderState(false);
                const message = 'Error in sending the invoices to the accounting package';
                showErrorMessage(this, message, error);
                this.selectedTeamMemberInvoiceIds.forEach((invoiceId) => {
                    const invoice = this.invoicesList.find((x) => x.teamMemberInvoiceId === invoiceId);
                    invoice.isSyncing = false;
                });
            }
        },
        handleInvoiceSentResult(invoiceId, reference, note) {
            const invoice = this.invoicesList.find((x) => x.teamMemberInvoiceId === Number(invoiceId));

            if (invoice == null) 
                return;

            if (reference != null) {
                invoice.accountingSystemRef = reference;
            } else {
                invoice.accountingSystemNote = note;
            }

            if (invoice.isSyncing === undefined) {
                this.$set(invoice, 'isSyncing', false);
            } else {
                invoice.isSyncing = false;
            }
        },
        removeSelected(invoiceId) {
            const ndx = this.selectedTeamMemberInvoiceIds.findIndex((x) => x === invoiceId);
            if (ndx > -1) {
                this.selectedTeamMemberInvoiceIds.splice(ndx, 1);
                this.isSelectAll = false;
            }
        },
        async handleBulkInvoiceResult(result, invoices) {
            this.$_handleLoaderState(false);

            let successMessage = 'Successfully processed the invoices.';
            let errorMessage = 'Error in sending the invoices to the accounting package.';

            if (invoices?.length) {
                if (invoices.length === 1) {
                    successMessage = 'Successfully processed the invoice.';
                    errorMessage = 'Error in sending the invoice to the accounting package.';
                }

                for (let i = 0; i < invoices.length; i++) {
                    const currentInvoice = invoices[i];
                    const matchingInvoice = this.invoicesList.find(
                        (x) => x.teamMemberInvoiceId === Number(currentInvoice.InvoiceId)
                    );

                    if (matchingInvoice) {
                        if (matchingInvoice.isSyncing === undefined) {
                            this.$set(matchingInvoice, 'isSyncing', false);
                        } else {
                            matchingInvoice.isSyncing = false;
                        }

                        matchingInvoice.accountingSystemRef = currentInvoice.Reference;
                        matchingInvoice.accountingSystemNote = currentInvoice.Note;
                    }
                }
            }

            if (result === 'Success') {
                this.$notifySuccess(successMessage, 10000);
            } else {
                this.$notifyError(errorMessage);

                if (this.isProcessAll) {
                    this.selectedTeamMemberInvoiceIds.forEach((id) => {
                        const invoice = this.invoicesList.find((x) => x.teamMemberInvoiceId === Number(id));

                        if (invoice.isSyncing === undefined) {
                            this.$set(invoice, 'isSyncing', false);
                        } else {
                            invoice.isSyncing = false;
                        }
                    });
                }
            }
        },
        async processInvoicesBasedOnFilter() {
            this.isProcessAll = true;
            this.$notify({
                message: 'Invoices will be processed in the background.',
                type: 'warning',
                duration: 6000
            });
            try {
                const payload = {
                    method: 'post',
                    params: {
                        ...this.cleanFilters()
                    }
                };
                const endpoint = `/api/team-member-invoices/send-to-accounting/all`;
                await handleRequests(endpoint, payload);
                this.isProcessAll = false;
            } catch (error) {
                this.isProcessAll = false;
                const message = 'Error in sending the invoices to the accounting package';
                showErrorMessage(this, message, error);
            }
        },
        handleBulkDelete({ result, ids }) {
            const invoices = [...ids];
            if (result) {
                invoices.forEach((invoiceId) => {
                    this.removeSelected(invoiceId);
                });

                this.handleChangePage(true);
            } else {
                const message = 'There was an error deleting the invoices';
                showErrorMessage(this, message);
            }
        },
        handleBulkChangeStatus(response) {
            if (response) {
                this.handleChangePage(1, this.pagination.perPage);
            }
        },
        handleDeleteInvoice({ result, invoiceId }) {
            this.removeSelected(invoiceId);
            if (result) {
                this.handleChangePage(1, this.pagination.perPage);
            }
        },
        handleGenerateInvoice() {
            this.isProgressComplete = false;
            this.showProgressBox = true;
            this.currentProcessedInvoice = 0;
            this.totalInvoice = 0;
        },
        handleTotalInvoiceToBeGenerated(total) {
            this.totalInvoice = Number(total);
        },
        async handleInvoiceGenerated(teamMemberInvoiceId) {
            if (!teamMemberInvoiceId) {
                return;
            }

            this.generatedInvoiceIds.push(teamMemberInvoiceId);

            this.currentProcessedInvoice = this.currentProcessedInvoice + 1;

            // Debounced method: will only be actually executed once all the generated invoice ids have been sent from the server
            await this.tryPushGeneratedInvoices();
        },
        // eslint-disable-next-line func-names
        tryPushGeneratedInvoices: _.debounce(async function() {
            // If the number of the existing invoices plus the number of the generated invoices exceed the page capacity,
            // reload the page instead of just appending to the list
            const getSingleInvoicePromises = [];
            let shouldPushToInvoiceList = true;

            for (let i = 0; i < this.generatedInvoiceIds.length; i++) {
                if (i + 1 + this.invoicesList.length > this.pagination.perPage) {
                    shouldPushToInvoiceList = false;
                    break;
                }

                getSingleInvoicePromises.push(this.getSingleInvoice(Number(this.generatedInvoiceIds[i])));
            }

            this.generatedInvoiceIds = [];

            if (shouldPushToInvoiceList) {
                const invoices = await Promise.all(getSingleInvoicePromises);

                for (let i = 0; i < invoices.length; i++) {
                    const invoice = invoices[i];
                    if (invoice && invoice.invoiceNumber) {
                        this.invoicesList.push(invoice);
                    }
                }
            } else {
                this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
            }
        }, 1000),
        handleSentEmail(invoiceId) {
            const invoice = this.invoicesList.find((x) => x.teamMemberInvoiceId === Number(invoiceId));
            if (invoice) {
                invoice.lastEmailDateSent = moment().format();
            }
        },
        handleBulkSentEmail(invoiceIdList) {
            invoiceIdList.forEach((invoiceId) => {
                this.handleSentEmail(invoiceId);
            });
        },
        async getSingleInvoice(teamMemberInvoiceId) {
            const endpoint = `/api/team-member-invoices/${teamMemberInvoiceId}`;
            const { data } = await handleRequests(endpoint);
            return data;
        }
    }
};
</script>

<style lang="scss" scoped>
.no-result-message {
    text-align: center;
    margin-top: 45px;
    font-size: 16px;
}

::v-deep .md-numeric {
    .md-table-cell-container {
        padding-right: 10px;
    }
}

::v-deep .status-container {
    .md-table-cell-container {
        overflow: visible;
    }
}

::v-deep .rating-container {
    .ratings {
        display: flex;
        align-items: flex-end;

        > {
            display: inline-block;
        }

        .md-icon {
            margin: 0;
        }
    }
}

.slide-leave-active,
.slide-enter-active {
    transition: 0.3s;
}

.slide-enter {
    transform: translateX(100%);
}

.slide-leave-to {
    transform: translateX(100%);
}

.content {
    margin-top: -15px;

    ::v-deep .md-card.md-theme-default {
        margin-bottom: 0;
    }
}

::v-deep .filter-steps--container {
    margin-top: 6px;

    .filter-steps--choices {
        margin-top: 0;
        padding-bottom: 0;

        > .md-input {
            display: initial;
        }
    }
}

::v-deep .filter-steps--container.tablet-filter-container {
    width: initial;
    float: left;
    margin-left: 20px;
}

::v-deep .search--container {
    width: 250px;
    float: right;
    margin-top: 6px;
}

::v-deep .md-table-cell-container {
    .md-button.md-round.md-just-icon {
        box-shadow: none;
    }
}

.custom-hidden-tablet {
    display: none;
}

.address-container {
    display: none;
}

@media (min-width: 1400px) {
    .address-container {
        display: table-cell;

        ::v-deep .md-table-cell-container {
            max-width: 300px;
        }
    }
}

::v-deep .md-table-cell {
    padding: 0px 8px;

    &:last-child {
        .md-table-cell-container {
            text-align: left !important;
            justify-content: flex-start;
        }
    }
}

::v-deep .md-table-row {
    &.zero-results {
        padding: 16px;
        text-align: center;
    }

    .custom-button-container .custom-a-blue a {
        color: rgba(0, 0, 0, 0.87);
    }
}

::v-deep .md-table-row:hover {
    background-color: #fafafa;
    cursor: pointer;
}

::v-deep .md-toolbar {
    .md-field {
        &:first-child,
        &:last-child {
            margin: 8px;
        }
    }

    .stops-search {
        padding-bottom: 10px;

        &::before,
        &::after {
            bottom: 10px;
        }
    }
}

::v-deep .md-card-actions {
    padding: 16px;

    &.page-footer {
        padding-top: 0px;
    }
}

.stop-reference ::v-deep a {
    color: rgba(0, 0, 0, 0.87);
}

::v-deep .header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.gray-text {
    color: rgba(0, 0, 0, 0.5);
}

.profile-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

.loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

.action-buttons {
    button,
    ::v-deep button {
        margin: 0 2px;
    }

    button:last-child {
        margin-right: 0;
    }
}

.assigned-column {
    display: inline-block;
    vertical-align: middle;

    ::v-deep .custom-ellipsis {
        max-width: 150px;
    }
}

.stop-checkbox {
    margin: 0;
}

.checkbox-head {
    margin-top: 4px;
}

.body-list {
    position: relative;

    .sticky {
        position: fixed;
        top: 0;
        z-index: 9;
        left: 75px;
    }
}

.icon-warning {
    color: #ff9800 !important;
    cursor: pointer;
    left: 14px;
}

.bulk-section {
    position: absolute;
    top: -35px;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    background-color: #cfeefa;
    color: #2b93ff;
    font-weight: 400;
    padding: 5px 10px 5px 20px;
    > div {
        width: 40%;
        display: inline-block;
        vertical-align: middle;
        text-align: left;
    }
    > div:last-child {
        width: 60%;
        text-align: right;
        button {
            height: 30px;
            width: 30px;
            min-width: 30px;
        }
        .dropdown {
            width: 180px;
            background-color: #ff9800 !important;
            display: inline-block;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
            height: 30px;
            line-height: 30px;
            margin-top: 6px;
            cursor: pointer;
        }
    }
}

@media (max-width: 992px) {
    .tablet-button-margin {
        margin-right: 10px;
    }
}

.green-flag {
    ::v-deep i {
        color: green !important;
    }
}

.custom-badge {
    max-width: initial;
}

.invoice-ref {
    color: rgba(0, 0, 0, 0.87) !important;
}
.invoice-ref:hover {
    color: #2b93ff !important;
}

.info-icons {
    display: inline-block;
}

.error-icon i {
    color: #f44336 !important;
}

.progress-container {
    min-width: 200px;
    max-width: 250px;
    background-color: #fff;
    position: fixed;
    right: 12px;
    bottom: 8px;
    z-index: 99;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    padding: 0 10px 10px 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    .progress-text-section {
        display: table;
        padding-top: 12px;
        > div {
            display: table-cell;
            vertical-align: middle;
        }

        > div:last-child {
            padding-left: 10px;
        }
    }

    ::v-deep .md-button {
        height: 20px;
        min-width: 20px;
        width: 20px;
        position: absolute;
        top: -12px;
        right: -12px;

        i {
            font-size: 15px !important;
        }
    }
}

.content {
    position: relative !important;
    height: 100% !important;
    min-height: 95vh !important;
}

.route-loader {
    top: 20% !important;
    left: 0 !important;
}
.route-loader-text {
    left: 37px;
    position: absolute;
    top: 13px;
}
.btn-loader {
    border: 4px solid #eeeeee;
    border-radius: 50%;
    border-top: 4px solid #2b93ff;
    width: 24px;
    height: 24px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    top: 85%;
    left: 48%;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.hide-progressbox {
    display: none;
}
.green-check {
    color: #4caf50 !important;
}
</style>
