var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-header" }, [
      _vm.teamMemberName !== "Unallocated"
        ? _c("h4", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.teamMemberName) + " - Assigned Stops"),
          ])
        : _vm._e(),
      _vm.teamMemberName === "Unallocated"
        ? _c("h4", { staticClass: "modal-title" }, [_vm._v("Unassigned Stops")])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "md-table",
          { staticClass: "context-menu-support unassignstop-table" },
          [
            _c(
              "md-table-row",
              [
                _c("md-table-head", [_vm._v("Reference")]),
                _c("md-table-head", [_vm._v("Address")]),
                _vm.teamMemberName !== "Unallocated"
                  ? _c("md-table-head", [_vm._v("ETA")])
                  : _vm._e(),
                _vm.hasCapacitySettings("quantity")
                  ? _c(
                      "md-table-head",
                      [
                        _c("md-icon", [
                          _c("img", {
                            staticClass: "capacity-images",
                            attrs: {
                              src: "/img/icons/capacity-items-gray.png",
                            },
                          }),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.hasCapacitySettings("volume")
                  ? _c(
                      "md-table-head",
                      [
                        _c("md-icon", [
                          _c("img", {
                            staticClass: "capacity-images",
                            attrs: {
                              src: "/img/icons/capacity-volume-gray.png",
                            },
                          }),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.hasCapacitySettings("weight")
                  ? _c(
                      "md-table-head",
                      [
                        _c("md-icon", [
                          _c("img", {
                            staticClass: "capacity-images",
                            attrs: {
                              src: "/img/icons/capacity-weight-gray.png",
                            },
                          }),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._l(_vm.stopList, function (item) {
              return _c(
                "md-table-row",
                { key: item.shipId || item.shipmentId },
                [
                  _c(
                    "md-table-cell",
                    { staticClass: "stop-reference" },
                    [
                      item.routingErrors.length > 0
                        ? _c("md-icon", [_vm._v("info")])
                        : _vm._e(),
                      item.routingErrors.length > 0
                        ? _c(
                            "md-tooltip",
                            { attrs: { "md-direction": "top" } },
                            [
                              _c(
                                "ul",
                                _vm._l(
                                  item.routingErrors,
                                  function (reason, index) {
                                    return _c("li", { key: index }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(reason.message) +
                                          "\n                            "
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                      item.stopRef
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Stop Details",
                                  params: { stopId: item.stopId },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.stopRef) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : item.shipmentId
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Shipment Details",
                                  params: { shipmentId: item.shipmentId },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.shipmentRef) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _c("span", [_vm._v(_vm._s(item.locationType))]),
                    ],
                    1
                  ),
                  _c("md-table-cell", { staticClass: "address-container" }, [
                    item.stopId
                      ? _c(
                          "div",
                          { staticClass: "stop-address" },
                          [
                            _c("google-autocomplete", {
                              ref: item.stopId,
                              refInFor: true,
                              attrs: {
                                id: item.stopRef,
                                "stop-id": item.stopId,
                                classname: "form-control autocomplete-input",
                                label: "",
                                icon: "",
                                placeholder: "",
                              },
                              on: { placechanged: _vm.onStopLocationChanged },
                              model: {
                                value: item.locationData,
                                callback: function ($$v) {
                                  _vm.$set(item, "locationData", $$v)
                                },
                                expression: "item.locationData",
                              },
                            }),
                          ],
                          1
                        )
                      : item.shipmentId
                      ? _c(
                          "div",
                          [
                            _c("md-icon", [
                              _vm._v(
                                "\n                            local_shipping\n                        "
                              ),
                            ]),
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.pickupStop.address) +
                                "\n                        "
                            ),
                            _c("md-icon", [
                              _vm._v(
                                "\n                            arrow_right_alt\n                        "
                              ),
                            ]),
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.dropStop.address) +
                                "\n                    "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm.teamMemberName !== "Unallocated"
                    ? _c("md-table-cell", { staticClass: "address-eta" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.arrivalTime) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                  _vm.hasCapacitySettings("quantity")
                    ? _c(
                        "md-table-cell",
                        { staticClass: "capacity-table-cell" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.getCapacityInfo(item, "quantity", "items")
                              ) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.hasCapacitySettings("volume")
                    ? _c(
                        "md-table-cell",
                        { staticClass: "capacity-table-cell" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.getCapacityInfo(item, "volume", "m3")
                              ) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.hasCapacitySettings("weight")
                    ? _c(
                        "md-table-cell",
                        { staticClass: "capacity-table-cell" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.getCapacityInfo(item, "weight", "kg")
                              ) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "btn-close",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Close")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }