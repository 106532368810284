<template>
    <div class="information-box">
        <div class="md-layout">
            <div class="md-layout-item">
                <md-field>
                    <label>Payment Method</label>
                    <md-select
                        v-model="detail.paymentDetails.paymentMethod"
                        @md-selected="getSelectedPaymentMethod"
                    >
                        <md-option v-for="(item, index) in paymentMethodOptions" :key="index" :value="item">
                            {{ item }}
                        </md-option>
                    </md-select>
                </md-field>
            </div>
        </div>
        <div class="md-layout" v-show="displayPaymentMethod">
            <div class="md-layout-item">
                <form-group name="paymentDetails.bank.name" label="Account Name (optional)">
                    <md-input v-model="detail.paymentDetails.bank.name" />
                </form-group>
            </div>
            <div class="md-layout-item">
                <form-group name="paymentDetails.bank.accountNumber" label="Account Number">
                    <md-input v-model="detail.paymentDetails.bank.accountNumber" />
                </form-group>
            </div>
            <div class="md-layout-item">
                <form-group name="paymentDetails.bank.bsb" label="BSB">
                    <md-input v-model="detail.paymentDetails.bank.bsb" />
                </form-group>
            </div>
            <div v-if="showRemittance" class="md-layout-item">
                <form-group name="paymentDetails.bank.remittance" label="Remittance">
                    <md-input v-model="detail.paymentDetails.bank.remittance" />
                </form-group>
            </div>
        </div>
    </div>
</template>

<script>
import { PAYMENT_METHOD_CONSTANTS } from '@/utils/constants';

export default {
    name: 'PaymentMethodInputs',
    props: {
        detail: {
            type: Object,
            default: () => {}
        },
        showRemittance: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            paymentMethodOptions: PAYMENT_METHOD_CONSTANTS,
            displayPaymentMethod: null
        };
    },
    methods: {
        getSelectedPaymentMethod(value) {
            this.displayPaymentMethod = value === 'Bank Transfer';
        }
    }
};
</script>
