<template>
    <div class="sidebar-spacing">
        <sidebar-item :link="{ name: $t('menus.team.settings'), icon: 'settings' }">
            <sidebar-item
                v-if="hasTeamSettingsAccess"
                :link="{
                    name: $t('menus.team.profile'),
                    path: '/team/profile'
                }"
            />

            <sidebar-item
                :link="{
                    name: $t('menus.team.members'),
                    path: '/team/team-members'
                }"
            />

            <sidebar-item
                v-if="hasTeamSettingsAccess"
                :link="{
                    name: $t('menus.team.configuration'),
                    path: '/team/settings'
                }"
            />

            <sidebar-item
                v-if="hasMarketplaceAccess && (!teamType || teamType !== 'Carrier')"
                :link="{
                    name: 'Carriers',
                    path: '/team/carriers'
                }"
            />

            <sidebar-item
                v-if="hasMarketplaceAccess && (!teamType || teamType !== 'Carrier')"
                :link="{
                    name: 'Carrier Marketplace',
                    path: '/marketplace'
                }"
            />
            <!--
            <sidebar-item
                v-if="hasTeamSettingsAccess"
                :link="{
                    name: 'Services',
                    path: '/team/services'
                }"
            />

    <sidebar-item
        v-if="hasTeamSettingsAccess"
        :link="{
            name: 'Team Regions',
            path: '/team/team-regions'
        }"
    />-->

            <sidebar-item
                :link="{
                    name: $t('menus.team.runSchedules'),
                    path: '/team/run-schedules'
                }"
            />

            <sidebar-item
                :link="{ name: 'Warehouses', icon: 'store', path: '/warehouses/list' }"
                v-if="!isIndividualUser && hasInventoryListAccess && $root.isDesktop && !isReadOnlyUser"
            />

            <li>
                <a @click="openCustomerBillingPortal()" class="nav-link first-support-link">
                    <span class="sidebar-mini">{{ $t('menus.team.billing-mini') }}</span>
                    <span class="sidebar-normal">{{ $t('menus.team.billing') }}</span>
                </a>
            </li>
        </sidebar-item>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { redirectToBillingPortal } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
// eslint-disable-next-line import/no-dynamic-require
const { config } = require(`../../../../config/config.${process.env.NODE_ENV}`);

export default {
    mixins: [GeneralMixin],
    data() {
        return {
            isClosed: true
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasTeamSettingsAccess: 'user/hasTeamSettingsAccess',
            hasMarketplaceAccess: 'user/hasMarketplaceAccess',
            teamType: 'user/teamType',
            hasInventoryListAccess: 'user/hasInventoryListAccess',
            isReadOnlyUser: 'user/isReadOnlyUser',
            isIndividualUser: 'user/isIndividualUser'
        })
    },
    methods: {
        clicked(e) {
            e.preventDefault();
        },
        toggleMenu() {
            this.isClosed = !this.isClosed;
        },
        async openCustomerBillingPortal() {
            await redirectToBillingPortal(config.billing_redirect_url);
        }
    }
};
</script>
<style scoped>
.collapsed {
    transition: opacity 1s;
}

.caret-report {
    position: absolute;
    top: 8px;
    right: 15px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.sidebar-mini {
    min-width: 30px;
    height: 5px;
}
</style>
