<template>
    <div>
        <vue-select
            label="name"
            :options="teamRegionOptions"
            placeholder="Team Region"
            v-model="teamRegionFilterValue"
            :disabled="!enabled"
            :clearable="clearable"
        >
            <template #selected-option="option">
                <span style="margin-left: 2px">
                    {{ option.name }}
                </span>
            </template>
            <template v-slot:option="option">
                <span style="margin-left: 2px" :class="isSubRegion ? `option-level-${option.level}` : ''">
                    {{ option.name }}
                </span>
            </template>
        </vue-select>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TeamRegionFilterOptions',
    props: {
        teamRegionId: {
            type: Number,
            default: null
        },
        enabled: { type: Boolean, default: true },
        clearable: { type: Boolean, default: true }
    },
    data() {
        return {
            teamRegionFilterValue: null,
            isSubRegion: false,
            defaults: [
                {
                    name: 'All',
                    teamRegionId: null
                },
                {
                    name: 'None',
                    teamRegionId: -1
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            teamRegions: 'team/teamRegions',
            user: 'user/user'
        }),
        teamRegionOptions() {
            if (this.teamRegions) {
                this.subRegionLevel();
                return [...this.defaults, ...this.teamRegions];
                /* if (!this.user.teamRegionId) {
                    return [...this.defaults, ...this.teamRegions];
                }
                const userTR = this.teamRegions.find((x) => x.teamRegionId === this.user.teamRegionId);
                if (userTR) {
                    return [...this.defaults, userTR];
                } */
            }
            return [];
        }
    },
    watch: {
        teamRegionId: {
            immediate: true,
            handler(newValue, oldValue) {
                if (oldValue !== null || oldValue !== newValue) {
                    this.updateSelectedRegion();
                }
            }
        },
        teamRegions: {
            immediate: true,
            deep: true,
            handler() {
                this.updateSelectedRegion();
            }
        },
        teamRegionFilterValue(newValue, oldValue) {
            const newTeamRegionId = newValue ? newValue.teamRegionId : null;
            const oldTeamRegionId = oldValue ? oldValue.teamRegionId : null;
            if (newTeamRegionId !== oldTeamRegionId) {
                this.$emit('changed', newValue ? newValue.teamRegionId : null);
            }
        }
    },
    methods: {
        subRegionLevel() {
            const subRegion = this.teamRegions.find((x) => x.level >= 2);
            this.isSubRegion = !!subRegion;
        },
        updateSelectedRegion() {
            let userTeamRegion = null;
            if (this.teamRegionId && this.teamRegionId > 0) {
                userTeamRegion = this.teamRegions.find((x) => x.teamRegionId === this.teamRegionId);
            }

            if (userTeamRegion) {
                this.teamRegionFilterValue = userTeamRegion;
            } else if (this.teamRegionId === -1) {
                this.teamRegionFilterValue = { ...this.defaults[1] }; // None
            } else {
                this.teamRegionFilterValue = { ...this.defaults[0] }; // All
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.option-level-1 {
    font-weight: 500;
}
.option-level-2 {
    margin-left: 20px !important;
}

.option-level-3 {
    margin-left: 40px !important;
}
</style>
