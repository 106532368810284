var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam
    ? _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Settings"),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "   >    " + _vm._s(_vm.$t("menus.setting.questions"))
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-blue" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("question_answer")])],
                      1
                    ),
                  ]
                ),
                _c("md-card-content", [
                  _c(
                    "div",
                    { staticClass: "cf-container" },
                    [
                      _c(
                        "div",
                        { staticClass: "cf-action-container" },
                        [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-primary md-just-icon md-round",
                              attrs: { title: "Add question " },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleAddQuestion()
                                },
                              },
                            },
                            [_c("md-icon", [_vm._v("add")])],
                            1
                          ),
                          _vm.questionsList.length > 0
                            ? _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-primary md-just-icon md-round",
                                  attrs: {
                                    title: "Save Questions configurations",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.saveQuestionsList()
                                    },
                                  },
                                },
                                [_c("md-icon", [_vm._v("save")])],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "form-group",
                        {
                          staticClass: "group-filter",
                          attrs: { label: "Group" },
                        },
                        [
                          _c(
                            "md-select",
                            {
                              model: {
                                value: _vm.selectedGroup,
                                callback: function ($$v) {
                                  _vm.selectedGroup = $$v
                                },
                                expression: "selectedGroup",
                              },
                            },
                            _vm._l(_vm.groupOptions, function (group, index) {
                              return _c(
                                "md-option",
                                { key: index, attrs: { value: group } },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(group) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "md-table",
                        { staticClass: "custom-paginated-table" },
                        [
                          _c(
                            "draggable",
                            {
                              staticClass: "custom-draggable",
                              attrs: { draggable: ".drag-item" },
                              model: {
                                value: _vm.questionsList,
                                callback: function ($$v) {
                                  _vm.questionsList = $$v
                                },
                                expression: "questionsList",
                              },
                            },
                            [
                              _c(
                                "md-table-row",
                                { attrs: { slot: "header" }, slot: "header" },
                                [
                                  _c("md-table-head"),
                                  _vm.isBookingGroup
                                    ? _c("md-table-head", [_vm._v("Kind")])
                                    : _vm._e(),
                                  _vm.isBookingGroup
                                    ? _c("md-table-head", [_vm._v("Name")])
                                    : _vm._e(),
                                  _c("md-table-head", [_vm._v("Label")]),
                                  _c("md-table-head", [_vm._v("Type")]),
                                  _c(
                                    "md-table-head",
                                    { staticClass: "actions" },
                                    [_vm._v("Required")]
                                  ),
                                  ["proofofdelivery", "arrivedatstop"].includes(
                                    _vm.selectedGroupNormalized
                                  )
                                    ? _c("md-table-head", [
                                        _vm._v(
                                          "\n                                    Include in Proof Of Delivery PDF\n                                "
                                        ),
                                      ])
                                    : _vm.selectedGroupNormalized ===
                                      "faileddelivery"
                                    ? _c("md-table-head", [
                                        _vm._v(
                                          "\n                                    Include in Failed Delivery PDF\n                                "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("md-table-head", [_vm._v("Conditions")]),
                                  _c(
                                    "md-table-head",
                                    { staticClass: "actions" },
                                    [_vm._v("Actions")]
                                  ),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.questionsList,
                                function (question, index) {
                                  return _c(
                                    "md-table-row",
                                    { key: index, staticClass: "drag-item" },
                                    [
                                      _c(
                                        "md-table-cell",
                                        [
                                          _c("md-icon", [
                                            _vm._v("drag_indicator"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm.isBookingGroup
                                        ? _c("md-table-cell", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(question.kind) +
                                                "\n                                "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm.isBookingGroup
                                        ? _c("md-table-cell", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(question.name) +
                                                "\n                                "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(question.label) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(question.type) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _c("md-table-cell", [
                                        question.isRequired
                                          ? _c("span", [_vm._v("Yes")])
                                          : _c("span", [_vm._v("No")]),
                                      ]),
                                      [
                                        "proofofdelivery",
                                        "faileddelivery",
                                        "arrivedatstop",
                                      ].includes(_vm.selectedGroupNormalized)
                                        ? _c("md-table-cell", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  question.includeInPdf
                                                    ? "Yes"
                                                    : "No"
                                                ) +
                                                "\n                                "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "md-table-cell",
                                        { staticClass: "conditions-group" },
                                        _vm._l(
                                          _vm.getFormattedGroupedConditions(
                                            question
                                          ),
                                          function (group, groupIndex) {
                                            return _c(
                                              "div",
                                              { key: groupIndex },
                                              [
                                                _c("div", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(group.type) + ": "
                                                    ),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(_vm._s(group.data)),
                                                  ]),
                                                ]),
                                                _c("br"),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _c(
                                        "md-table-cell",
                                        [
                                          _c(
                                            "md-button",
                                            {
                                              staticClass:
                                                "md-warning md-just-icon md-round",
                                              attrs: {
                                                title: "edit question ",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handleEditQuestion(
                                                    question
                                                  )
                                                },
                                              },
                                            },
                                            [_c("md-icon", [_vm._v("edit")])],
                                            1
                                          ),
                                          _c(
                                            "md-button",
                                            {
                                              staticClass:
                                                "md-danger md-just-icon md-round delete-action",
                                              attrs: {
                                                title: "delete question ",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.removeQuestion(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_c("md-icon", [_vm._v("delete")])],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm.questionsList.length == 0
                        ? _c("div", { staticClass: "text-center" }, [
                            _c("p", { staticClass: "no-result-message" }, [
                              _vm._v(
                                "No results matching this filter could be found."
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }