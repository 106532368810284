var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSupportUser || _vm.isSupportAdminUser
    ? _c(
        "div",
        {
          staticClass:
            "support-user-container md-layout md-alignment-top-left content-layout-panel",
        },
        [
          _c(
            "div",
            { staticClass: "support-user-details md-layout-item md-size-45" },
            [
              _c("div", [
                _vm.supportingTeam && _vm.supportingTeam.logoUrl
                  ? _c("img", {
                      staticClass: "profile-image",
                      attrs: { src: _vm.supportingTeam.logoUrl },
                      on: { error: _vm.$_setDefaultBrokenImage },
                    })
                  : _vm._e(),
              ]),
              _c("div", [
                _vm._v("\n            Joined team:\n            "),
                _c("br"),
                _vm.supportingTeam && _vm.supportingTeam.name
                  ? _c("span", [_vm._v(_vm._s(_vm.supportingTeam.name))])
                  : _c("span", [_vm._v("< None >")]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "md-layout-item support-buttons" },
            [
              _c(
                "md-button",
                {
                  staticClass: "join-btn md-primary support-btn",
                  on: { click: _vm.displayJoinTeamModal },
                },
                [_vm._v("\n            Search Teams\n        ")]
              ),
              _vm.isSupportAdminUser
                ? _c(
                    "drop-down",
                    { staticClass: "join-btn", attrs: { direction: "down" } },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "drop-down-button md-primary md-just-icon",
                          attrs: { slot: "title", "data-toggle": "dropdown" },
                          slot: "title",
                        },
                        [_c("md-icon", [_vm._v("expand_more")])],
                        1
                      ),
                      _c(
                        "ul",
                        { staticClass: "dropdown-menu dropdown-menu-right" },
                        [
                          _c("li", [
                            _c(
                              "a",
                              {
                                on: {
                                  click: _vm.displayCreateSupportUserModal,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Create Support User\n                    "
                                ),
                              ]
                            ),
                          ]),
                          _c("li", [
                            _c(
                              "a",
                              {
                                on: {
                                  click: _vm.displayRemoveSupportUserModal,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Remove Support User\n                    "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.supportingTeam
                ? _c(
                    "md-button",
                    {
                      staticClass: "join-btn md-danger support-btn",
                      on: { click: _vm.leaveTeam },
                    },
                    [_vm._v("\n            Leave Team\n        ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }