<template>
    <div class="filter-steps--container">
        <team-region-member-filter-options
            :team-region-id="teamRegionId"
            :show-team-member="false"
            @changed="handleTeamRegionChanged"
            :clearable="false"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { TeamRegionMemberFilterOptions } from '@/components';

export default {
    name: 'TeamMemberFilterComponent',
    components: { TeamRegionMemberFilterOptions },
    props: {
        teamRegionId: { type: Number, default: null }
    },
    computed: {
        ...mapGetters({
            teamRegions: 'team/teamRegions'
        })
    },
    watch: {
        teamRegionFilterValue() {
            this.handleFilter();
        }
    },
    methods: {
        handleTeamRegionChanged(args) {
            this.$emit('onFilterMembers', { teamRegionId: args?.teamRegionId });
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-steps--container {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }
    .filter-step-date {
        min-width: 250px;
        margin-right: 0;
        min-height: initial;
        position: absolute;
        z-index: 1;
    }

    .filter-button--container {
        display: inline-block;
    }
    ::v-deep .md-datepicker:after,
    ::v-deep .md-datepicker:before {
        bottom: 0;
        height: 0;
    }

    ::v-deep .md-datepicker:before,
    ::v-deep .md-datepicker:after {
        bottom: 30px;
        content: 'Trip date';
        color: #aaaaaa;
        font-weight: 400;
        font-size: 14px;
        transform: none;
        z-index: 1;
    }

    ::v-deep .md-has-value:before,
    ::v-deep .md-has-value:after {
        bottom: 0;
        height: 0;
        content: '';
    }

    ::v-deep .md-datepicker .md-input {
        position: absolute;
        z-index: 9;
        width: 150px;
    }

    ::v-deep .md-datepicker .md-input-action {
        z-index: 99;
        right: 52px;
    }
}
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}
::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}
::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}

::v-deep .vs__search {
    cursor: pointer;
}

.datepicker-noevent {
    ::v-deep .md-datepicker {
        input {
            pointer-events: none;
        }
    }
}

::v-deep .filter-member-name .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    display: block;
}

.time-picker .main-time-picker-container:after {
    content: 'Select time';
    color: #aaaaaa;
    font-weight: 400;
    font-size: 14px;
    transform: none;
    bottom: 17px;
    position: absolute;
}

::v-deep .md-has-value .main-time-picker-container:after {
    content: '';
}
.time-picker {
    width: 210px !important;
}

.time-picker.md-field:after {
    bottom: 10px !important;
    height: 1px !important;
}
.time-picker:last-child {
    margin-right: 0 !important;
}

.status-filter {
    width: 150px !important;
}

.time-picker ::v-deep .time-picker-container:nth-child(2) {
    border-bottom: 1px solid #d2d2d2;
}
</style>
