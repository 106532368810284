import {
    DATE_TYPES,
    SPEED_MULTIPLIER,
    GPS_COORDINATE_CONSTANT,
    NOTE_TYPE_CONSTANTS,
    STOP_NOTE_TYPE_CONSTANTS,
    SPEED_TO_DISTANCE_UNITS
} from '@/utils/constants';
import { isAValidCoordinate, isEmptyOrSpaces, camelCaseToSentenceCase } from '@/helpers';
import marked from 'marked';
import i18n from '@/i18n';
// eslint-disable-next-line import/prefer-default-export
export const GeneralMixin = {
    methods: {
        $_handleLoaderState(state, text = i18n.t('common.loadingText')) {
            const loader = {
                isLoading: state,
                loadingText: text
            };
            this.$store.commit('CHANGE_LOADING_STATE', loader, { root: true });
        },
        $_setDefaultBrokenImage(event) {
            event.target.src = this.$root.defaultBrokenImage;
        },
        $_validateCoordinate(coordinates) {
            return isAValidCoordinate(coordinates);
        },
        $_displayAddressName(stop) {
            if (isAValidCoordinate(stop.address)) {
                if (stop.name === undefined || isEmptyOrSpaces(stop.name)) {
                    return GPS_COORDINATE_CONSTANT;
                }

                return stop.name;
            }

            return stop.address;
        },
        $_displayContactName(stop) {
            if (stop.stopRef && stop.stopId) {
                return stop.contact != null ? stop.contact.name : ' - ';
            }
            return stop?.locationType;
        },
        $_isEmptyOrSpaces(text) {
            return isEmptyOrSpaces(text);
        },
        $_getFileIcon(file) {
            const fileExt = file.split('.')[1];
            switch (fileExt) {
                case 'xlsx':
                    return 'excel-icon';
                case 'xls':
                    return 'excel-icon';
                case 'docx':
                    return 'word-icon';
                case 'doc':
                    return 'word-icon';
                case 'pdf':
                    return 'pdf-icon';
                case 'mp4':
                    return 'youtube-icon';

                default:
                    return null;
            }
        },
        $_camelCaseToSentenceCase(text) {
            return camelCaseToSentenceCase(text);
        },
        $_compiledMarkdown(note) {
            return marked(note, { sanitize: true });
        },
        $_getBadgeColor(type) {
            const noteTypes = [...NOTE_TYPE_CONSTANTS, ...STOP_NOTE_TYPE_CONSTANTS];

            const result = noteTypes.find((noteType) => noteType.name === type);

            if (result) 
                return result.color;

            return 'pending';
        },
        $_notePhotoLoading(event, source) {
            // If the photo thumbnail couldn't be loaded, it's probably because the photo hasn't been processed by the Image Processor function.
            // It should be processed soon, so show a wait indicator instead, and try and load it again after 10 seconds.
            event.target.src = '/img/loading-ico-60px.gif';

            setTimeout(() => {
                event.target.src = source;
            }, 10000);
        },
        $_getAssetIcon(type) {
            switch (type) {
                case 'Personnel':
                    return 'person';
                case 'Vehicle':
                    return 'local_shipping';
                case 'Tracker':
                    return 'share_location';
                case 'Trailer':
                    return 'rv_hookup';
                default:
                    return 'work';
            }
        },
        $_getMaskingText(text) {
            return text.replace(text.substring(2, text.length - 2), '********');
        },
        $_handleColumnSwitch(data, key, storeKey) {
            this.tableColumnOptions.columns[key] = data;

            localStorage.setItem(storeKey, JSON.stringify(this.tableColumnOptions));
        },
        $_applyColumnSwitchDefaults(storeKey, applyDefaultKeys) {
            Object.keys(applyDefaultKeys).forEach((propName) => {
                if (
                    this.tableColumnKeyOptions[propName] !== undefined ||
                    this.tableColumnKeyOptions[propName] !== null
                ) {
                    this.tableColumnKeyOptions[propName] = applyDefaultKeys[propName];
                    [this.tableColumnOptions.columns[propName]] = this.tableColumnKeyOptions[propName];
                }
            });

            const savedTableColumnOptions = JSON.parse(localStorage.getItem(storeKey));
            if (
                savedTableColumnOptions === undefined ||
                savedTableColumnOptions === null ||
                savedTableColumnOptions.columns === undefined ||
                savedTableColumnOptions.columns === null
            ) {
                return;
            }

            Object.keys(this.tableColumnOptions.columns).forEach((propName) => {
                if (
                    savedTableColumnOptions.columns[propName] !== undefined ||
                    savedTableColumnOptions.columns[propName] !== null
                ) {
                    const item = this.tableColumnKeyOptions[propName].find(
                        (option) => option.key === savedTableColumnOptions.columns[propName].key
                    );
                    if (item !== undefined) {
                        this.tableColumnOptions.columns[propName] = item;
                    }
                }
            });
        },
        $_getUsedByLabel(usedBy) {
            if (usedBy) {
                return usedBy.length > 3 ? `${usedBy.splice(0, 3).join(',')}...(${usedBy.length})` : usedBy.join(',');
            }

            return '';
        },
        $_isLoadCapacityTypeEnabled(loadCapacityList, label) {
            return loadCapacityList.findIndex((l) => l.label === label) > -1;
        },
        $_displayLoadValue(loadCapacityList, load, label, showSymbol = true) {
            if (load && load.length > 0) {
                if (!showSymbol) 
                    return load[label.toLowerCase()];

                return `${load[label.toLowerCase()]} ${loadCapacityList.find((l) => l.label === label).symbol} `;
            }
            return '';
        }
    },
    data() {
        return {
            DATE_TYPES,
            SPEED_MULTIPLIER,
            GPS_COORDINATE_CONSTANT,
            SPEED_TO_DISTANCE_UNITS
        };
    }
};
