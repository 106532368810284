<template>
    <div>
        <md-button class="md-primary pull-right header-button" @click="updateShipment" :disabled="!teamMembers.length">
            Edit
        </md-button>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import UpdateShipmentModal from './UpdateShipmentModal';

export default {
    name: 'UpdateShipment',
    mixins: [GeneralMixin],
    props: {
        teamMembers: {
            type: Array,
            default: () => []
        },
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            shipmentDetails: {}
        };
    },
    methods: {
        updateShipment() {
            this.shipmentDetails = Object.assign({}, this.data);

            this.$modal
                .show(UpdateShipmentModal, {
                    members: this.teamMembers,
                    shipment: this.shipmentDetails
                })
                .then((response) => {
                    if (response.toLowerCase() === 'ok') 
                        this.emitShipmentUpdated();
                    this.$modal.hide();
                });
        },
        emitShipmentUpdated() {
            this.$emit('shipmentUpdated');
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    margin-right: 16px;
}
</style>
