var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-blue" },
        [
          _c(
            "div",
            { staticClass: "card-icon" },
            [
              _c("md-icon", { attrs: { title: "Assignment History" } }, [
                _vm._v("supervised_user_circle"),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "md-card-content",
        {
          staticClass: "custom-history-timeline",
          class: _vm.list.length >= 4 ? "timeline-active-scrollbar" : "",
        },
        [
          _vm.list.length > 0
            ? _c(
                "time-line",
                { attrs: { plain: "", type: "simple" } },
                _vm._l(_vm.list, function (history, index) {
                  return _c(
                    "time-line-item",
                    {
                      key: index,
                      attrs: {
                        inverted: "",
                        "is-image": true,
                        "image-src":
                          history.assignedToPhotoUrl != null
                            ? history.assignedToPhotoUrl
                            : history.assignedToCarrierLogoUrl,
                      },
                    },
                    [
                      history.assignedToPublicUserId
                        ? _c(
                            "span",
                            {
                              staticClass: "custom-badge status-complete",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    history.assignedToFullName ||
                                      history.assignedToPublicUserId
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : history.assignedToCarrierTeamId
                        ? _c(
                            "span",
                            {
                              staticClass: "badge status-complete",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(history.assignedToCarrierCompany) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      !history.assignedToPublicUserId &&
                      !history.assignedToCarrierTeamId
                        ? _c(
                            "span",
                            {
                              staticClass: "custom-badge status-pending",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _vm._v(
                                "\n                    Unassigned\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticClass: "time",
                          attrs: { slot: "headerTime" },
                          slot: "headerTime",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("dateTimeFormat")(
                                  history.actionDate,
                                  _vm.DATE_TYPES.standardDate
                                )
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                      _c("h6", { attrs: { slot: "footer" }, slot: "footer" }, [
                        _c("i", { staticClass: "ti-time" }),
                        _vm._v(
                          "\n\n                    By " +
                            _vm._s(
                              history.assignedByFullName ||
                                history.assignedByPublicUserId
                            ) +
                            "\n                "
                        ),
                      ]),
                    ]
                  )
                }),
                1
              )
            : _c("p", { staticClass: "grayedout-text" }, [
                _vm._v("No Assignment History Available"),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }