<template>
    <div>
        <div class="alert trial-alert-expiry alert-dismissible fade show" :class="trialAlertColor" role="alert">
            {{ alertMessage }}
            <a
                v-if="user.role === 'Team Owner' || user.role === null"
                @click.prevent="redirectUser"
                target="_blank"
                class="trial-upgrade-btn"
            >
                {{ $t('pages.dashboardLayout.upgradeNow') }}
            </a>
            <span v-else>{{ $t('pages.dashboardLayout.contactTeamOwner') }}</span>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { redirectToBillingPortal } from '@/helpers';
import NoBillingOptions from '@/pages/Dashboard/Layout/Modals/NoBillingOptionsModal';

const { config } = require(`../../config/config.${process.env.NODE_ENV}`); // eslint-disable-line import/no-dynamic-require

export default {
    name: 'TrialBanner',
    mixins: [GeneralMixin],
    data() {
        return {
            trialDaysLeft: 0,
            trialAlertColor: 'alert-primary',
            hasStripeCustomerId: true
        };
    },
    computed: {
        ...mapGetters({
            isTrialExpired: 'user/isTrialExpired',
            user: 'user/user',
            teamStatus: 'team/teamStatus'
        }),
        userSubscription() {
            if (this.user) 
                return this.user.subscriptionDetails;
            return {};
        },
        alertMessage() {
            if (this.teamStatus === 'Disabling') {
                return this.$t('pages.dashboardLayout.accountStatusDisabling');
            }

            if (this.trialDaysLeft <= 0) {
                return this.$t('pages.dashboardLayout.trialExpired');
            }

            return this.$tc('pages.dashboardLayout.remainingDays', this.trialDaysLeft);
        }
    },
    mounted() {
        const today = moment().startOf('day');
        const expiryDate = moment(this.userSubscription.trialExpiryDate);
        this.trialDaysLeft = expiryDate.diff(today, 'days');

        if (this.trialDaysLeft <= 3) {
            this.trialAlertColor = 'alert-warning';
        }

        if (this.trialDaysLeft <= 0) {
            this.trialAlertColor = 'alert-danger';
        }
    },
    methods: {
        async redirectUser() {
            if (this.hasStripeCustomerId) {
                await this.openBillingDashboard();
            } else {
                this.$modal.show(NoBillingOptions).then((response) => {
                    if (response) {
                        this.$modal.hide();
                    }
                });
            }
        },
        async openBillingDashboard() {
            this.$_handleLoaderState(true, 'Redirect...');
            await redirectToBillingPortal(config.billing_redirect_url);
            this.$_handleLoaderState(false);
        }
    }
};
</script>

<style lang="scss" scoped>
.main-panel-whitebg {
    background-color: #fff;
    > .content {
        padding-top: 0;
    }

    .trial-upgrade-btn {
        margin-left: 20px;
        cursor: pointer;
    }
}

.custom-lp {
    position: relative;
    .trial-alert-container {
        position: sticky;
        top: 0;
        z-index: 999;
        .trial-alert-expiry {
            width: 100%;
            text-align: center;
            margin-bottom: 0;
            span {
                max-width: 100%;
                display: inline-block;
            }
        }
        .trial-upgrade-btn {
            color: #fff;
            border: 2px solid #fff;
            padding: 5px 10px;
            margin-left: 20px;
            cursor: pointer;
        }

        .trial-upgrade-btn:hover,
        .trial-upgrade-btn:focus,
        .trial-upgrade-btn:active {
            color: #fff !important;
        }
    }
}
</style>
