<template>
    <div class="calendar-sidebar">
        <div>
            <h4>Unassigned Stops ({{ totalUnassignedStops }})</h4>
            <div>
                <div class="outer">
                    <md-field class="full">
                        <md-input v-model="searchStopFilter" placeholder="Search for a stop"></md-input>
                    </md-field>
                    <!-- <md-button class="md-icon-button md-dense md-raised md-primary" @click="getUnassignedStops">
                        <md-icon>search</md-icon>
                    </md-button> -->
                </div>
            </div>
        </div>
        <div ref="unassignedStops" class="calendar-sidebar-list">
            <div v-if="!loading">
                <div v-if="searchResultList.length > 0">
                    <div
                        class="stop-box"
                        v-for="item in searchResultList"
                        :key="item.stopId"
                        :data-stop="`${JSON.stringify(item)}`"
                    >
                        <p class="contactName">
                            <span>
                                <router-link
                                    :to="{
                                        name: 'Stop Details',
                                        params: { stopId: item.stopId }
                                    }"
                                    target="_blank"
                                >
                                    {{ item.stopRef }}
                                </router-link>
                            </span>
                            {{ item.contact.name }}
                        </p>
                        <p class="contactAddress">{{ item.address }}</p>
                        <p class="date">
                            {{ item.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                            <span v-if="item.timeWindowStart != null && item.timeWindowEnd != null">
                                ({{ item.timeWindowStart | timeFormat }} - {{ item.timeWindowEnd | timeFormat }})
                            </span>
                            <span v-else-if="item.appointmentTime != null">
                                ({{ item.appointmentTime | timeFormat }})
                            </span>
                        </p>
                        <!-- 
                        code for required skills commented out in case we need it in the future.
                        
                        <div
                            class="details-box md-chips md-primary md-theme-default md-has-placeholder md-theme-default"
                            v-if="item.skills.length > 0"
                        >
                            <label>Required skills:</label>
                            <div
                                class="md-chip md-theme-default custom-chip"
                                v-for="(skill, index) in item.skills"
                                :key="index"
                            >
                                {{ skill.name }}
                            </div>
                        </div> -->
                    </div>
                </div>
                <div v-else>
                    <span class="stop-box-empty">No Results Found</span>
                </div>
            </div>
            <div v-else>
                <div class="stopbar-loader">
                    <fade-loader :loading="loading" color="#333333" />
                    <span>LOADING</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Draggable } from '@fullcalendar/interaction';
import { GeneralMixin } from '@/mixins';
import _ from 'lodash';
import FadeLoader from 'vue-spinner/src/FadeLoader';

export default {
    name: 'StopCalendarSidebar',
    components: {
        FadeLoader
    },
    props: {
        unassignedStops: {
            type: Array,
            default: () => []
        },
        removeStopId: {
            type: Number,
            default: () => null
        },
        isLoading: {
            type: Boolean,
            default: () => false
        }
    },
    mixins: [GeneralMixin],
    watch: {
        isLoading(newVal) {
            this.loading = newVal;
        },
        unassignedStops(newVal) {
            this.handleFilteredValue();
        },
        searchStopFilter(newVal) {
            this.loading = true;
            this.getUnassignedStops();
        },
        removeStopId(newVal) {
            if (newVal) {
                const index = this.searchResultList.findIndex((stop) => {
                    return stop.stopId === newVal;
                });
                if (index >= 0) {
                    this.searchResultList.splice(index, 1);
                }

                const indexOriginal = this.unassignedStopsData.findIndex((stop) => {
                    return stop.stopId === newVal;
                });

                if (indexOriginal >= 0) {
                    this.unassignedStopsData.splice(indexOriginal, 1);
                }

                this.totalUnassignedStops = this.searchResultList.length;
            }
        }
    },
    mounted() {
        this.loading = true;
        this.handleFilteredValue();

        // eslint-disable-next-line no-new
        new Draggable(this.$refs.unassignedStops, {
            itemSelector: '.stop-box',
            eventData: (e) => {
                const { contact, address, status } = JSON.parse(e.dataset.stop);
                return {
                    customHtml: `<div class="heavy-font">${
                        contact.name == null ? '' : contact.name
                    }</div><div>${address}</div>`,
                    className: status.toLowerCase(),
                    create: false
                };
            }
        });
    },
    data() {
        return {
            searchStopFilter: '',
            totalUnassignedStops: 0,
            unassignedStopsData: [],
            loading: true,
            searchResultList: []
        };
    },
    methods: {
        handleFilteredValue() {
            this.unassignedStopsData = this.unassignedStops;
            this.searchResultList = this.unassignedStops;
            this.totalUnassignedStops = this.unassignedStopsData.length;
            this.loading = false;
        },
        // eslint-disable-next-line func-names
        getUnassignedStops: _.debounce(function() {
            let list = this.unassignedStopsData;
            if (this.searchStopFilter) {
                list = this.unassignedStopsData.filter((stop) => {
                    if (stop.contact.name != null) {
                        if (
                            stop.contact.name.toLowerCase().includes(this.searchStopFilter.toLowerCase()) ||
                            stop.address.toLowerCase().includes(this.searchStopFilter.toLowerCase())
                        ) {
                            return true;
                        }
                    }

                    return false;
                });
            }

            this.totalUnassignedStops = list.length;
            this.searchResultList = list;
            this.loading = false;
        }, 500)
    }
};
</script>

<style lang="scss" scoped>
.calendar-sidebar {
    background-color: #fff;
    padding: 10px;
    z-index: 999;

    h4 {
        font-weight: 400;
        margin: 0;
    }
    .calendar-sidebar-list {
        height: 66vh;
        overflow-y: auto;
        position: relative;
    }
    .stop-box {
        background-color: #fafafa;
        border-bottom: 1px solid #ddd;
        font-size: 0.8rem;
        padding: 10px;
        margin-bottom: 5px;

        label {
            display: block;
            font-size: 0.65rem;
            color: #aaaaaa;
            font-weight: 400;
            margin-bottom: 0;
        }
        div {
            font-size: 0.8rem;
        }
        span {
            font-weight: 400;
        }
        p {
            margin: 0;
        }
        .contactName {
            font-weight: 600;
        }
        .date {
            font-weight: 400;
            font-style: italic;
        }
    }
    .stop-box:hover {
        cursor: grab;
        background-color: #eee;
    }

    .outer {
        display: flex;
        align-items: baseline;
    }
    .stop-box-empty {
        color: #aaa;
        margin-top: 70%;
        display: block;
        text-align: center;
        font-weight: 400;
    }
}

.stopbar-loader {
    position: absolute;
    top: 35%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}
</style>
