var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "md-layout" }, [
        _c("div", { staticClass: "md-layout-item" }, [
          _c("div", { staticClass: "custom-toolbar" }, [
            _c(
              "div",
              { staticClass: "custom-toolbar-start" },
              [
                _c("ShipmentFilterComponent", {
                  attrs: {
                    "team-members": function () {
                      return this$1.teamMembers
                    },
                    "team-region-id": _vm.filters.teamRegionId,
                    "show-quoting-status": false,
                    "show-team-members": false,
                    "show-status": false,
                  },
                  on: { onFilterShipments: _vm.handleFilterOrSearch },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "custom-toolbar-end" },
              [
                _c("SearchComponent", {
                  on: { onSearch: _vm.handleFilterOrSearch },
                }),
                _c(
                  "div",
                  [
                    _c(
                      "md-button",
                      {
                        staticClass:
                          "md-primary md-just-icon md-round pull-right header-button",
                        attrs: { title: "Refresh List" },
                        on: { click: _vm.handleRefresh },
                      },
                      [_c("md-icon", [_vm._v("refresh")])],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c(
              "md-card",
              [
                _vm.hasMarketplaceAccess
                  ? _c(
                      "md-card-header",
                      {
                        staticClass: "md-card-header-icon md-card-header-green",
                      },
                      [
                        _c("CardHeaderDropdown", {
                          attrs: {
                            "dropdown-options": _vm.headerDropdown,
                            "selected-option": _vm.headerDropdown[1],
                          },
                          on: { selectedOption: _vm.handleSelectedView },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "md-card-header",
                      {
                        staticClass: "md-card-header-icon md-card-header-green",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-icon running-load-menu" },
                          [_c("md-icon", [_vm._v("assignment")])],
                          1
                        ),
                      ]
                    ),
                _c("md-card-content", { staticClass: "body-list" }, [
                  _vm.selectedShipmentIds.length
                    ? _c(
                        "div",
                        {
                          class: [
                            "bulk-section",
                            _vm.topScrollPosition > 150 ? "sticky" : "",
                          ],
                        },
                        [
                          _c("div", [
                            _vm._v(_vm._s(_vm.selectedShipmentsDisplayText)),
                          ]),
                          _c(
                            "div",
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-warning",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.requestQuotes($event)
                                    },
                                  },
                                },
                                [_vm._v("Refresh quotes")]
                              ),
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-info",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.bulkAssign($event)
                                    },
                                  },
                                },
                                [_vm._v("Assign to Carrier")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isListLoading
                    ? _c(
                        "div",
                        [
                          _c(
                            "md-table",
                            {
                              staticClass: "context-menu-support",
                              attrs: { id: "table-container" },
                            },
                            [
                              _c(
                                "md-table-row",
                                [
                                  _c(
                                    "md-table-head",
                                    [
                                      _c("md-checkbox", {
                                        staticClass:
                                          "shipment-checkbox checkbox-head",
                                        on: {
                                          change: function ($event) {
                                            return _vm.onSelectAll(
                                              _vm.isSelectAll
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.isSelectAll,
                                          callback: function ($$v) {
                                            _vm.isSelectAll = $$v
                                          },
                                          expression: "isSelectAll",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "md-table-head",
                                    { staticClass: "hide-column" },
                                    [_vm._v("Reference")]
                                  ),
                                  _c("md-table-head", [_vm._v("Quote Status")]),
                                  _c(
                                    "md-table-head",
                                    { staticClass: "hide-column" },
                                    [_vm._v("Trip Date")]
                                  ),
                                  _c("md-table-head", [_vm._v("Address")]),
                                  _c("md-table-head", [_vm._v("Contact")]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "md-table-head running-load-head",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-table-head-container running-load-menu",
                                        },
                                        [
                                          _c(
                                            "drop-down",
                                            { attrs: { direction: "down" } },
                                            [
                                              _vm.selectedCarrierHeader !=
                                                "Default Only" &&
                                              _vm.selectedCarrierHeader != null
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "load-header-text custom-ellipsis",
                                                      attrs: { href: "#" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.selectedCarrierHeader
                                                          ) +
                                                          "\n                                                "
                                                      ),
                                                      _c(
                                                        "md-tooltip",
                                                        {
                                                          attrs: {
                                                            "md-direction":
                                                              "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    This will filter the cheapest quote based on the selected\n                                                    carrier\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "load-header-text",
                                                      attrs: { href: "#" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Carrier\n                                                "
                                                      ),
                                                      _c(
                                                        "md-tooltip",
                                                        {
                                                          attrs: {
                                                            "md-direction":
                                                              "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    This will filter the cheapest quote based on the selected\n                                                    carrier\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                              _c(
                                                "md-icon",
                                                { staticClass: "icon-arrow" },
                                                [_vm._v("expand_more")]
                                              ),
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "dropdown-menu table-dropdown",
                                                },
                                                _vm._l(
                                                  _vm.filteredCarrierTeamOptions,
                                                  function (item) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: item.key,
                                                        class:
                                                          item.key == -1
                                                            ? "line-menu"
                                                            : "",
                                                      },
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            class: [
                                                              "icon-check",
                                                              _vm.selectedCarrierKey ===
                                                              item.key
                                                                ? "icon-check-color"
                                                                : "",
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        check\n                                                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "a",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleSelectedCarrierOption(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        " +
                                                                _vm._s(
                                                                  item.label
                                                                ) +
                                                                "\n                                                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("md-table-head", [_vm._v("Service Type")]),
                                  _c("md-table-head", [_vm._v("Price")]),
                                  _c("md-table-head"),
                                ],
                                1
                              ),
                              _vm._l(_vm.shipmentsList, function (item) {
                                return _c(
                                  "md-table-row",
                                  {
                                    key: item.shipmentId,
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.showShipmentDetailsSidebar(
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "small-column" },
                                      [
                                        _c("md-checkbox", {
                                          staticClass: "shipment-checkbox",
                                          attrs: {
                                            value: item.shipmentId,
                                            disabled:
                                              item.quotingStatus ==
                                              "JobAssigned",
                                          },
                                          on: { change: _vm.onSelectOne },
                                          model: {
                                            value: _vm.selectedShipmentIds,
                                            callback: function ($$v) {
                                              _vm.selectedShipmentIds = $$v
                                            },
                                            expression: "selectedShipmentIds",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-table-cell",
                                      {
                                        staticClass:
                                          "shipment-reference hide-column",
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Shipment Details",
                                                params: {
                                                  shipmentId: item.shipmentId,
                                                },
                                              },
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(item.shipmentRef) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-table-cell",
                                      {
                                        staticClass:
                                          "status-container quote-status-container",
                                        class:
                                          !!item.quotingStatus &&
                                          item.quotingStatus ===
                                            "QuotesGathered"
                                            ? "quote-status"
                                            : "",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: !!item.quotingStatus
                                              ? "status-" +
                                                _vm.$_getQuotingStatusBadgeColor(
                                                  item.quotingStatus
                                                )
                                              : "",
                                          },
                                          [
                                            item.quotingStatus ==
                                              "GatheringQuotes" ||
                                            item.showLoader
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "quotes-loader",
                                                    class: item.showLoader
                                                      ? "started-loader"
                                                      : "",
                                                  },
                                                  [_c("straight-line-loader")],
                                                  1
                                                )
                                              : _vm._e(),
                                            !!item.quotingStatus
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "custom-badge quoting-badge",
                                                    class:
                                                      "status-" +
                                                      _vm.$_getQuotingStatusBadgeColor(
                                                        item.quotingStatus
                                                      ),
                                                  },
                                                  [
                                                    item.quotingStatus ==
                                                    "QuotesGathered"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.handleShowQuotes(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  _vm.toQuotingStatusDesc(
                                                                    item
                                                                  )
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        )
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.toQuotingStatusDesc(
                                                                item
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "hide-column" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                item.tripDate,
                                                _vm.DATE_TYPES.standardDate
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "address-container" },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "md-tooltip",
                                              {
                                                staticClass: "tooltip-width",
                                                attrs: {
                                                  "md-direction": "top",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "address-text-tooltip",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v("Pickup:"),
                                                    ]),
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm.$_displayAddressName(
                                                            {
                                                              address:
                                                                item.pickupAddress,
                                                              name: item.pickupName,
                                                            }
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "address-text-tooltip",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v("Drop off:"),
                                                    ]),
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm.$_displayAddressName(
                                                            {
                                                              address:
                                                                item.dropAddress,
                                                              name: item.dropName,
                                                            }
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "address-text" },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.$_displayAddressName({
                                                        address:
                                                          item.pickupAddress,
                                                        name: item.pickupName,
                                                      })
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "address-separator",
                                              },
                                              [_vm._v("->")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "address-text" },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.$_displayAddressName({
                                                        address:
                                                          item.dropAddress,
                                                        name: item.dropName,
                                                      })
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c("md-table-cell", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.contact.name) +
                                          "\n                                "
                                      ),
                                    ]),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "carrier-dropdown" },
                                      [
                                        _c("SelectShipmentDropdown", {
                                          attrs: {
                                            shipment: item,
                                            "team-members": [],
                                            "is-quotes-view": true,
                                          },
                                          on: {
                                            quoteSelected:
                                              _vm.handleQuoteSelected,
                                            getQuotes: _vm.loadQuotes,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("md-table-cell", [
                                      item.selectedCarrierQuote != null &&
                                      item.selectedCarrierQuote.serviceLevel !=
                                        null
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "custom-badge service-badge",
                                              class:
                                                "status-" +
                                                _vm.$_getServiceLevelBadgeColor(
                                                  item.selectedCarrierQuote
                                                    .timeWindowEnd
                                                ),
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      item.selectedCarrierQuote
                                                        .serviceLevel
                                                    )
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.selectedCarrierQuote != null &&
                                      item.selectedCarrierQuote.timeWindowEnd !=
                                        null
                                        ? _c(
                                            "div",
                                            { staticClass: "quote-time" },
                                            [
                                              _vm._v(
                                                "\n                                        ETA: " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "quoteDateTimeFormat"
                                                    )(
                                                      item.selectedCarrierQuote
                                                        .timeWindowEnd
                                                    )
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _c("md-table-cell", [
                                      item.selectedCarrierQuote != null
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    item.selectedCarrierQuote
                                                      .amountAfterTax,
                                                    item.selectedCarrierQuote
                                                      .currency
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "action-buttons" },
                                      [
                                        item.selectedCarrierQuote != null &&
                                        item.selectedCarrierQuote.isExpired &&
                                        item.quotingStatus != "GatheringQuotes"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "action-button-container",
                                              },
                                              [
                                                _c(
                                                  "md-button",
                                                  {
                                                    staticClass:
                                                      "md-success md-just-icon md-round",
                                                    attrs: {
                                                      title: "Refresh quotes",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleRefreshQuotes(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("md-icon", [
                                                      _vm._v("refresh"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        item.quotingStatus !== "JobAssigned"
                                          ? _c("PreBookingIndicatorButton", {
                                              staticClass: "pre-booking-icon",
                                              attrs: { shipment: item },
                                              on: {
                                                onCheckPreBookingRequiredFieldsCompleted:
                                                  _vm.handleCheckPreBookingRequiredFieldsCompleted,
                                                onUpdateShipmentCompleted:
                                                  _vm.handleUpdateShipmentCompleted,
                                              },
                                            })
                                          : _vm._e(),
                                        item.selectedCarrierQuote != null &&
                                        item.quotingStatus != "JobAssigned"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "action-button-container",
                                              },
                                              [
                                                _c(
                                                  "md-button",
                                                  {
                                                    staticClass:
                                                      "md-primary md-just-icon md-round",
                                                    attrs: {
                                                      title:
                                                        "Assign quote to shipment",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleAssignQuoteToShipment(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("md-icon", [
                                                      _vm._v("person_add"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                          false
                            ? _c("div", [
                                _c("p", { staticClass: "no-result-message" }, [
                                  _vm._v(
                                    "No results matching your search/filter could be found."
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("div", [
                        _c(
                          "div",
                          { staticClass: "shipmentbar-loader" },
                          [
                            _c("fade-loader", {
                              attrs: { loading: _vm.loading, color: "#333333" },
                            }),
                            _c("span", [_vm._v("LOADING")]),
                          ],
                          1
                        ),
                      ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "md-card-actions",
        {
          staticClass: "page-footer",
          attrs: { "md-alignment": "space-between" },
        },
        [
          _c("div", [
            _vm.total === _vm.pagination.perPage
              ? _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "\n                Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of many\n            "
                  ),
                ])
              : _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of " +
                      _vm._s(_vm.totalPages)
                  ),
                ]),
          ]),
          _c("pagination", {
            staticClass: "pagination-no-border pagination-success",
            attrs: {
              "per-page": _vm.pagination.perPage,
              total: _vm.total,
              "enable-first-page-link": true,
              "enable-last-page-link": true,
            },
            on: {
              "change-page": function ($event) {
                return _vm.handleChangePage($event, _vm.pagination.perPage)
              },
            },
            model: {
              value: _vm.pagination.currentPage,
              callback: function ($$v) {
                _vm.$set(_vm.pagination, "currentPage", $$v)
              },
              expression: "pagination.currentPage",
            },
          }),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.showShipmentDetails
            ? _c("shipment-sidebar", {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.toggleShipmentDetailsWindow,
                    expression: "toggleShipmentDetailsWindow",
                  },
                ],
                attrs: {
                  "shipment-id": _vm.shipmentId,
                  data: _vm.shipmentDetails,
                  "team-members": _vm.teamMembers,
                },
                on: { closeModal: _vm.toggleShipmentDetailsWindow },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }