<template>
    <div class="offers-panel">
        <div>
            <div class="search--container" v-if="offerList.length > 5">
                <md-field md-clearable @md-clear="handleClear">
                    <md-input type="text" placeholder="Search" v-model="searchText" />
                    <md-icon>search</md-icon>
                </md-field>
            </div>
            <ul>
                <li
                    v-for="(driver, driverIndex) in filteredOffers"
                    :key="driver.publicUserId"
                    @mouseover="highlightDriver(driver)"
                    @mouseout="highlightDriver(null)"
                    :class="{
                        isPinned: driver.isPinned
                    }"
                >
                    <DriverOnlineStatus :driver-name="driver.driverName" :is-online="driver.isOnline" />
                    <span class="name" @click="pinOffer(driver)">{{ driverIndex + 1 }}. {{ driver.driverName }}</span>
                    <span class="distance" @click="pinOffer(driver)">
                        &nbsp;(+{{ Math.round(driver.additionalDistance * 10) / 10 }} {{ driver.distanceUnits
                        }}{{ formatDrivingTime(driver.additionalTimeSeconds) }})
                    </span>
                    <span class="zoom" @click.stop="focusOnDriver(driver)">
                        <md-icon class="footer-icon">my_location</md-icon>
                        <md-tooltip class="toggle-tooltip" md-direction="top">
                            Centre map on
                            <br />
                            {{ driver.driverName }}
                        </md-tooltip>
                    </span>
                    <span @click="pinOffer(driver)">
                        <md-icon class="footer-icon user-pin">push_pin</md-icon>
                        <md-tooltip class="toggle-tooltip" md-direction="top">Pin {{ driver.driverName }}</md-tooltip>
                    </span>
                </li>
            </ul>
            <span class="error-text" v-if="offerList.length === 0">No drivers found.</span>
            <span class="error-text" v-else-if="filteredOffers.length === 0">
                There is no driver named '{{ searchText }}'.
            </span>
        </div>
    </div>
</template>

<script>
import { onMounted, watch, ref, toRefs } from '@vue/composition-api';
import DriverOnlineStatus from './DriverOnlineStatus';

export default {
    name: 'OfferList',
    components: { DriverOnlineStatus },
    props: {
        offerList: {
            type: Array,
            default: () => []
        },
        pinnedOffer: {
            type: Object,
            default: null
        },
        focusedDriver: {
            type: Object,
            default: null
        },
        highlightedDriverId: {
            type: String,
            default: null
        }
    },

    setup(props, context) {
        const { offerList, pinnedOffer } = toRefs(props);
        const filteredOffers = ref([]);
        const searchText = ref('');

        onMounted(() => {
            filteredOffers.value = offerList.value;
        });

        const handleClear = () => {
            searchText.value = '';
        };

        watch(searchText, (newVal) => {
            if (!newVal) 
                filteredOffers.value = offerList.value;
            else {
                filteredOffers.value = offerList.value.filter((d) => {
                    return new RegExp(newVal, 'i').test(d.driverName);
                });
            }
        });

        const pinOffer = (offer) => {
            // If the current offer is already pinned, unpin it
            // NOTE: This just updates the prop. The actual pinning logic happens in the watcher
            // as the pinnedOffer can be set externally as well.
            if (offer.isPinned === true) {
                context.emit('update:pinnedOffer', null);
                return;
            }

            context.emit('update:pinnedOffer', offer);
        };

        const highlightDriver = (offer) => {
            context.emit('update:highlightedDriverId', offer?.publicUserId);
        };

        const focusOnDriver = (offer) => {
            context.emit('update:focusedDriver', offer);
        };

        const filterList = async () => {
            filteredOffers.value = offerList.value;
        };

        watch(offerList, () => {
            filterList();
        });

        const formatDrivingTime = (seconds) => {
            if (!seconds) 
                return '';
            const str = ', +';

            if (seconds < 60) {
                return `${str}${seconds}s`; // e.g. 23s
            }
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);

            const formattedTime = [];

            if (hours > 0) {
                formattedTime.push(`${hours}h`);
            }

            if (minutes > 0 || (hours === 0 && minutes === 0)) {
                formattedTime.push(`${minutes}min`);
            }

            return str + formattedTime.join(' ');
        };

        watch(pinnedOffer, (newOffer, oldOffer) => {
            // If an offer is already pinned, unpin it
            if (oldOffer) 
                oldOffer.isPinned = false; // TODO: Broadcast?

            if (newOffer) 
                newOffer.isPinned = true;
        });

        return {
            searchText,
            filteredOffers,
            handleClear,
            pinOffer,
            focusOnDriver,
            highlightDriver,
            formatDrivingTime
        };
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';

.offers-panel {
    position: absolute;
    bottom: 25px;
    left: 20px;
    width: 380px;
    height: auto;
    max-height: 340px;
    background: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    border-radius: 3px;

    ul {
        padding: 0 10px;
        padding-top: 0;
        margin: 0;
        list-style: none;
        font-size: 14px;

        li {
            cursor: pointer;
            font-size: 14px;
            display: flex;

            &:hover {
                font-weight: bold;
            }

            .name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .distance {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: silver;
                font-weight: normal !important;
            }

            .zoom {
                margin-left: auto;
            }

            .md-icon {
                width: 14px;
                height: 14px;
                font-size: 14px !important;
                margin-right: 0px;
                margin-left: 0px;

                &.user-pin {
                    transform: rotate(90deg);
                }
            }
        }
    }

    ::v-deep .isPinned {
        color: #066cc8 !important;
        font-weight: bold;

        .md-icon {
            color: #066cc8 !important;
        }

        .user-pin {
            transform: rotate(0deg) !important;
        }
    }
}

.search--container {
    padding-left: 12px;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    ::v-deep .md-field {
        box-sizing: border-box;
        margin-top: 0;
        &::before,
        &::after {
            bottom: 10px;
        }
        &.md-focused {
            .md-icon {
                color: #4ba3ff;
                fill: #4ba3ff;
            }
        }
        .md-icon {
            color: #d2d2d2;
            fill: #d2d2d2;
            margin-bottom: 0 !important;
            margin-top: 6px !important;
            font-size: 18px !important;
        }
    }

    .md-input {
        padding-top: 0;
        padding-bottom: 0;

        &::placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder,
        &::-webkit-input-placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #d2d2d2 !important;
            opacity: 1 !important; /* Firefox */
        }
    }
}

.error-text {
    padding-left: 10px;
    color: #ff5245;
    font-size: 14px;
}
</style>
