var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isTeamOwner
    ? _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "md-layout" }, [
          _c("div", { staticClass: "md-layout-item" }, [
            _c("div", { staticClass: "custom-toolbar md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-layout md-small-size-80 md-size-90",
                },
                [
                  _c(
                    "div",
                    { staticClass: "md-layout-item" },
                    [
                      _c("DateRangeFilter", {
                        attrs: { "is-single-date": false },
                        on: { onChanged: _vm.onDateChanged },
                      }),
                      _c("team-member-invoice-batch-list", {
                        staticClass: "md-layout-item",
                        attrs: {
                          "date-from": _vm.filters.dateFrom,
                          "date-to": _vm.filters.dateTo,
                        },
                        on: {
                          selectedBatchChanged: _vm.onBatchChanged,
                          batchListUpdated: _vm.onBatchListUpdate,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "md-layout-item" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-primary md-just-icon md-round pull-right header-button",
                      attrs: { title: "Export Report" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.exportToExcel($event)
                        },
                      },
                    },
                    [_c("md-icon", [_vm._v("get_app")])],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
            },
            [
              !_vm.reportDataList || _vm.reportDataList.length == 0
                ? _c(
                    "md-card",
                    [
                      _c(
                        "md-card-header",
                        {
                          staticClass:
                            "md-card-header-icon md-card-header-blue",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "card-icon" },
                            [_c("md-icon", [_vm._v("paid")])],
                            1
                          ),
                        ]
                      ),
                      _c("md-card-content", [
                        !_vm.reportDataList || _vm.reportDataList.length == 0
                          ? _c("div", [
                              _c("h3", [_vm._v("Driver Payment Summary")]),
                              _c("p", [_vm._v("No results to display.")]),
                              !_vm.batchList || _vm.batchList.length <= 0
                                ? _c("p", [
                                    _vm._v(
                                      "\n                            *Please Select a date range to get a list of team member invoice batches.\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                : _vm._l(
                    _vm.reportDataList.groupDataByTeamRegion,
                    function (group, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "md-card",
                            [
                              _c(
                                "md-card-header",
                                {
                                  staticClass:
                                    "md-card-header-icon md-card-header-blue",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "card-icon" },
                                    [_c("md-icon", [_vm._v("paid")])],
                                    1
                                  ),
                                ]
                              ),
                              _c("md-card-content", [
                                group[0].region !== null
                                  ? _c("h3", [
                                      _vm._v(
                                        "\n                            Driver Payment Summary for\n                            "
                                      ),
                                      _c("i", [
                                        _vm._v(_vm._s(group[0].region)),
                                      ]),
                                    ])
                                  : _c("h3", [
                                      _vm._v("Driver Payment Summary"),
                                    ]),
                                _c(
                                  "div",
                                  { staticClass: "report-container" },
                                  [
                                    _c("driver-payment-summary-details", {
                                      attrs: {
                                        list: group,
                                        "invoice-currency":
                                          _vm.reportDataList.currency,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }
                  ),
            ],
            2
          ),
        ]),
      ])
    : _c("div", [_vm._v("\n    No available report.\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }