import { render, staticRenderFns } from "./ValidatePasswordModal.vue?vue&type=template&id=e10d0cc8&scoped=true&"
import script from "./ValidatePasswordModal.vue?vue&type=script&lang=js&"
export * from "./ValidatePasswordModal.vue?vue&type=script&lang=js&"
import style0 from "./ValidatePasswordModal.vue?vue&type=style&index=0&id=e10d0cc8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e10d0cc8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\a\\1\\s\\Locate2u.AppPortal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e10d0cc8')) {
      api.createRecord('e10d0cc8', component.options)
    } else {
      api.reload('e10d0cc8', component.options)
    }
    module.hot.accept("./ValidatePasswordModal.vue?vue&type=template&id=e10d0cc8&scoped=true&", function () {
      api.rerender('e10d0cc8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/User/ValidatePasswordModal.vue"
export default component.exports