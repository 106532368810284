import { OFFER_QUOTING_STATUSES } from '@/utils/constants';
import moment from 'moment';

export const CarrierMixin = {
    methods: {
        $_getQuotingStatusBadgeColor(type) {
            const statuses = [...OFFER_QUOTING_STATUSES];
            const result = statuses.find((status) => status.value === type);

            if (result) 
                return result.color;

            return 'pending';
        },
        $_getQuotingStatusText(quotingStatus) {
            const statuses = [...OFFER_QUOTING_STATUSES];
            if (quotingStatus) {
                const status = statuses.find((x) => x.value === quotingStatus);
                if (status) 
                    return status.text;
            }
            return quotingStatus;
        },
        $_getServiceLevelBadgeColor(date) {
            if (date == null) 
                return 'pending';

            const today = moment();
            const expiryDate = moment(date);
            const hours = expiryDate.diff(today, 'hours');

            if (hours < 3) {
                return 'complete';
            }

            if (hours < 15) {
                return 'departed';
            }

            return 'cancelled';
        }
    }
};
