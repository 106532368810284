<template>
    <md-button
        v-if="!isReadOnlyUser"
        title="Delete invoice"
        :class="['md-danger md-just-icon md-round', isDisabled ? 'button-disabled' : '']"
        :disabled="isDisabled"
        class="md-danger md-just-icon md-round"
        @click.stop="handleDeleteInvoice(customerInvoiceId)"
    >
        <md-icon>delete</md-icon>
        <md-tooltip v-if="status === 'Paid'" class="line-tooltip" md-direction="left">
            You cannot delete an invoice when it is paid.
        </md-tooltip>
    </md-button>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';

export default {
    name: 'DeleteInvoiceButton',
    props: {
        customerInvoiceId: {
            type: [Number, String],
            default: () => null
        },
        status: {
            type: String,
            default: ''
        },
        isBulk: {
            type: Boolean,
            default: false
        },
        bulkCustomerInvoiceIds: {
            type: Array,
            default: () => []
        }
    },
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        }),
        isDisabled() {
            return this.status === 'Paid';
        }
    },
    methods: {
        handleDeleteInvoice() {
            let message = 'Are you sure you want to delete this invoice?';

            if (this.isBulk) {
                message = 'Are you sure you want to delete these invoices?';
            }

            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Invoice',
                    body: message,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        if (this.isBulk) {
                            await this.handleBulkDelete();
                        } else {
                            await this.handleConfirmDelete();
                        }
                    }
                });
        },
        async handleConfirmDelete() {
            if (this.customerInvoiceId == null) {
                showErrorMessage(this, 'No invoice selected');
                return;
            }
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'delete'
            };
            const api = `/api/customer-invoices/${this.customerInvoiceId}`;

            try {
                await handleRequests(api, payload);
                this.$notifySuccess('Invoice was deleted!');
                this.$emit('invoiceDeleted', { result: true, invoiceId: this.customerInvoiceId });
            } catch (e) {
                const message = 'Error in deleting the invoice.';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
        },
        async handleBulkDelete() {
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'delete',
                data: this.bulkCustomerInvoiceIds
            };
            const api = `/api/customer-invoices/bulk/delete`;

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Invoices were successfully deleted!',
                    type: 'success'
                });
                this.$emit('invoiceBulkDelete', { result: true, ids: this.bulkCustomerInvoiceIds });
            } catch (e) {
                const message = 'Error in deleting the invoices';
                showErrorMessage(this, message, e);
                this.$emit('invoiceBulkDelete', { result: false, ids: this.bulkCustomerInvoiceIds });
            }
            this.$_handleLoaderState(false);
        }
    }
};
</script>

<style lang="scss" scoped>
.button-disabled {
    opacity: 0.2 !important;
    cursor: not-allowed;
    pointer-events: all !important;
}
</style>
