<script>
import { inject, onBeforeUnmount, onMounted, watch } from '@vue/composition-api';

export default {
    name: 'MapPath',
    props: {
        identifier: {
            type: String,
            required: true,
            default: 'route'
        },
        pathString: {
            type: String,
            default: ''
        },
        options: {
            type: Object,
            default: () => {}
        }
    },
    setup(props) {
        const { identifier } = props;
        const mapEngine = inject('mapEngine');

        function setPath() {
            const payload = {
                identifier,
                coordinates: mapEngine.decodePath(props.pathString),
                ...props.options
            };

            mapEngine.setPath(payload);
        }

        onMounted(() => {
            setPath();
        });

        onBeforeUnmount(() => {
            mapEngine.removePath({ identifier });
        });

        watch(
            () => props.pathString,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    setPath();
                }
            }
        );
    }
};
</script>
<template>
    <!-- Render an element to satisfy CI build rule vue/valid-template-root -->
    <span />
</template>
