<template>
    <div class="modal-container">
        <div class="modal-header">
            Add Skill
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.form" class="form-wrapper">
                <form-group label="Name" name="name">
                    <md-input v-model="form.name" />
                </form-group>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="addSkill">Save</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'SkillModal',
    mixins: [GeneralMixin],
    props: {
        teamId: {
            type: Number,
            default: 0
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            form: {
                name: ''
            }
        };
    },
    methods: {
        async addSkill() {
            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                this.$_handleLoaderState(true, 'SAVING...');
                const payload = {
                    method: 'post',
                    data: this.form
                };
                const api = `/api/teams/skills`;
                try {
                    await handleRequests(api, payload);
                    this.$notify({
                        message: 'Skill saved!',
                        type: 'success'
                    });
                } catch (e) {
                    const message = 'Cannot save skill.';
                    showErrorMessage(this, message, e);
                }
                this.$_handleLoaderState(false);
                this.resolve(this.form);
            }
        }
    },
    validations: {
        form: {
            name: {
                required_skill: required
            }
        }
    }
};
</script>
