var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "md-layout" }, [
        _c("div", { staticClass: "md-layout-item" }, [
          _c("div", { staticClass: "custom-toolbar" }, [
            _c(
              "div",
              { staticClass: "custom-toolbar-start" },
              [
                _c("filter-component", {
                  class: _vm.$root.isTablet ? "tablet-filter-container" : "",
                  attrs: {
                    "team-members": function () {
                      return this$1.teamMembers
                    },
                    "time-window-filter-options": _vm.timeWindowFilterOptions,
                    "team-region-id": _vm.filters.teamRegionId,
                    "is-geocoded-view": true,
                    "geocode-types": _vm.filters.geocodeTypes,
                    "confidence-score": _vm.filters.confidenceScore,
                  },
                  on: { onFilterStops: _vm.handleFilterOrSearch },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "custom-toolbar-end" },
              [
                _vm.$root.isDesktop
                  ? _c("search-component", {
                      on: { onSearch: _vm.handleFilterOrSearch },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _c(
                      "md-button",
                      {
                        staticClass:
                          "md-primary md-just-icon md-round pull-right header-button",
                        attrs: { title: "Refresh List" },
                        on: { click: _vm.handleRefresh },
                      },
                      [_c("md-icon", [_vm._v("refresh")])],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
            class: _vm.total < _vm.pagination.perPage ? "margin-space-bot" : "",
          },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-green" },
                  [
                    _c("CardHeaderDropdown", {
                      attrs: {
                        "dropdown-options": _vm.headerDropdown,
                        "selected-option": _vm.headerDropdown[1],
                      },
                      on: { selectedOption: _vm.handleSelectedView },
                    }),
                  ],
                  1
                ),
                _c("md-card-content", { staticClass: "body-list" }, [
                  !_vm.isListLoading
                    ? _c(
                        "div",
                        [
                          _c(
                            "md-table",
                            { staticClass: "context-menu-support" },
                            [
                              _c(
                                "md-table-row",
                                [
                                  _c("md-table-head", [_vm._v("Address")]),
                                  _c("md-table-head", [
                                    _vm._v("Geocoded Address"),
                                  ]),
                                  _c("TableHeaderDropdownMultiple", {
                                    attrs: {
                                      "default-text": "Type",
                                      "dropdown-options": _vm.geocodingOptions,
                                      "selected-options":
                                        _vm.selectedGeocodeTypes,
                                    },
                                    on: {
                                      selectedOptions:
                                        _vm.handleGeocodingTypeFilter,
                                    },
                                  }),
                                  _c("TableHeaderDropdown", {
                                    attrs: {
                                      "default-text": "Score",
                                      "dropdown-options":
                                        _vm.confidenceScoreList,
                                      "selected-option":
                                        _vm.selectedConfidenceScore,
                                    },
                                    on: {
                                      selectedOption:
                                        _vm.handleSelectConfidenceFilter,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._l(_vm.stopsList, function (item, index) {
                                return _c(
                                  "md-table-row",
                                  {
                                    key: item.stopId,
                                    class:
                                      _vm.selectedStop != null &&
                                      _vm.selectedStop.stopId == item.stopId
                                        ? "highlight-address"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.showStopDetailsSidebar(
                                          item,
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "address" },
                                      [
                                        _c("GeocodingIndicator", {
                                          attrs: {
                                            "address-components":
                                              item.addressComponents,
                                          },
                                        }),
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(item.address) +
                                            "\n                                "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "address" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              item.addressComponents != null
                                                ? item.addressComponents.address
                                                : ""
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _c("md-table-cell", [
                                      item.addressComponents != null &&
                                      item.addressComponents.type != null
                                        ? _c(
                                            "div",
                                            {
                                              class:
                                                "custom-badge address-badge status-" +
                                                _vm.$_getGeocodeAddressTypeBadgeColor(
                                                  item.addressComponents.type
                                                ),
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.$te(
                                                      "geocodeTypes." +
                                                        item.addressComponents
                                                          .type
                                                    )
                                                      ? _vm.$t(
                                                          "geocodeTypes." +
                                                            item
                                                              .addressComponents
                                                              .type
                                                        )
                                                      : item.addressComponents
                                                          .type
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _c("md-table-cell", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            item.addressComponents != null
                                              ? Math.round(
                                                  item.addressComponents
                                                    .confidenceScore * 100
                                                ) + "%"
                                              : ""
                                          ) +
                                          "\n                                "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                          _vm.stopsList.length == 0 && !_vm.isLoading
                            ? _c("div", [
                                _c("p", { staticClass: "no-result-message" }, [
                                  _vm._v(
                                    "No results matching your search/filter could be found."
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("div", [
                        _c(
                          "div",
                          { staticClass: "stopbar-loader" },
                          [
                            _c("fade-loader", {
                              attrs: { loading: _vm.loading, color: "#333333" },
                            }),
                            _c("span", [_vm._v("LOADING")]),
                          ],
                          1
                        ),
                      ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "md-card-actions",
        {
          staticClass: "page-footer",
          attrs: { "md-alignment": "space-between" },
        },
        [
          _c("div", [
            _vm.total === _vm.pagination.perPage
              ? _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "\n                Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of many\n            "
                  ),
                ])
              : _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of " +
                      _vm._s(_vm.totalPages)
                  ),
                ]),
          ]),
          _c("pagination", {
            staticClass: "pagination-no-border pagination-success",
            attrs: { "per-page": _vm.pagination.perPage, total: _vm.total },
            on: {
              "change-page": function ($event) {
                return _vm.handleChangePage($event, _vm.pagination.perPage)
              },
            },
            model: {
              value: _vm.pagination.currentPage,
              callback: function ($$v) {
                _vm.$set(_vm.pagination, "currentPage", $$v)
              },
              expression: "pagination.currentPage",
            },
          }),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.showStopDetails
            ? _c("fix-address-sidebar", {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.toggleStopDetailsWindow,
                    expression: "toggleStopDetailsWindow",
                  },
                ],
                attrs: {
                  "stop-details": _vm.selectedStop,
                  "current-index": _vm.currentListIndex,
                },
                on: {
                  closeModal: _vm.toggleStopDetailsWindow,
                  leftClick: _vm.handleLeftClick,
                  rightClick: _vm.handleRightClick,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }