var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.businessEntitiesList.length > 0
    ? _c(
        "div",
        { staticClass: "filter-inline" },
        [
          _c(
            "form-group",
            { attrs: { name: "businessEntity", label: _vm.dropdownLabel } },
            [
              _c("md-input", {
                attrs: { type: "hidden" },
                model: {
                  value: _vm.businessEntityValue,
                  callback: function ($$v) {
                    _vm.businessEntityValue = $$v
                  },
                  expression: "businessEntityValue",
                },
              }),
              _c("vue-select", {
                attrs: {
                  reduce: function (x) {
                    return x.businessEntityId
                  },
                  label: "businessRegistrationName",
                  options: _vm.businessEntitiesList,
                },
                model: {
                  value: _vm.businessEntityValue,
                  callback: function ($$v) {
                    _vm.businessEntityValue = $$v
                  },
                  expression: "businessEntityValue",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }