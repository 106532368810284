var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c("div", { staticClass: "custom-toolbar md-layout" }, [
            _c(
              "div",
              { staticClass: "md-layout-item custom-toolbar-start md-size-75" },
              [
                _c("team-members-filter", {
                  attrs: { "emit-on-clear": true },
                  on: { onSelection: _vm.onTeamMemberSelection },
                }),
                _c("date-range-filter", {
                  attrs: {
                    "is-single-date": false,
                    "max-pediod-in-months":
                      _vm.selectedPeriod === "day" ? 2 : 24,
                  },
                  on: { onChanged: _vm.onDateChanged },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "md-layout-item custom-toolbar-end" },
              [
                _vm.selectedTeamMember.isTracker !== true &&
                _vm.getPeriod === "day"
                  ? _c(
                      "md-field",
                      [
                        _c("vue-select", {
                          attrs: {
                            placeholder: "Highlight Discrepancies",
                            options: _vm.discrepancyComboValues,
                            searchable: _vm.$root.isDesktop,
                          },
                          model: {
                            value: _vm.selectedDiscrepancy,
                            callback: function ($$v) {
                              _vm.selectedDiscrepancy = $$v
                            },
                            expression: "selectedDiscrepancy",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-primary md-just-icon md-round header-button",
                    attrs: { title: "Export Travel Statistics" },
                    on: { click: _vm.onExportToExcel },
                  },
                  [_c("md-icon", [_vm._v("get_app")])],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-blue" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("summarize")])],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "aggregate-options" },
                [
                  this.selectedTeamMember.userId
                    ? _c(
                        "ButtonGroup",
                        {
                          attrs: {
                            "selected-class-name": "md-info",
                            "allow-no-selection": false,
                          },
                          model: {
                            value: _vm.selectedPeriod,
                            callback: function ($$v) {
                              _vm.selectedPeriod = $$v
                            },
                            expression: "selectedPeriod",
                          },
                        },
                        [
                          _c("option", { attrs: { value: "day" } }, [
                            _vm._v("day"),
                          ]),
                          _c("option", { attrs: { value: "week" } }, [
                            _vm._v("week"),
                          ]),
                          _c("option", { attrs: { value: "month" } }, [
                            _vm._v("month"),
                          ]),
                          _c("option", { attrs: { value: "quarter" } }, [
                            _vm._v("quarter"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("md-card-content", [
                !_vm.distancesList || _vm.distancesList.length == 0
                  ? _c("div", [
                      _c("h3", [_vm._v("Travel Statistics")]),
                      _c("p", [
                        _vm._v(
                          "\n                            No results to display.\n                        "
                        ),
                      ]),
                    ])
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "report-container" },
                        [
                          _c("distance-report-details", {
                            attrs: {
                              "distances-list": _vm.distancesList,
                              "public-user-id": _vm.selectedTeamMember.userId,
                              "is-tracker-data":
                                _vm.selectedTeamMember.isTracker,
                              discrepancy: _vm.getDescepancy,
                              period: _vm.getPeriod,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }