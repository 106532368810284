var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isListLoading
        ? _c(
            "div",
            [
              _c(
                "md-table",
                { staticClass: "custom-paginated-table" },
                [
                  _c(
                    "md-table-row",
                    [
                      _c("md-table-head", [_vm._v("Name")]),
                      _c("md-table-head", [_vm._v("Tracker Reference")]),
                      _c("md-table-head", [_vm._v("Team Member")]),
                      _c("md-table-head", [_vm._v("Actions")]),
                    ],
                    1
                  ),
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "md-table-row",
                      {
                        key: index,
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.showAssetGroupDetails(item)
                          },
                        },
                      },
                      [
                        _c(
                          "md-table-cell",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Asset Group Details",
                                    params: { assetGroupId: item.assetGroupId },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.groupName) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("md-table-cell", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.trackerReference) +
                              "\n                "
                          ),
                        ]),
                        _c("md-table-cell", [
                          item.ownerId != null
                            ? _c(
                                "div",
                                [
                                  item.ownerPhotoUrl
                                    ? _c("img", {
                                        staticClass: "circle-profile-image",
                                        attrs: {
                                          src: item.ownerPhotoUrl,
                                          alt: "",
                                        },
                                        on: {
                                          error: _vm.$_setDefaultBrokenImage,
                                        },
                                      })
                                    : _c("img", {
                                        staticClass: "circle-profile-image",
                                        attrs: {
                                          src: _vm.$root.defaultPhotoUrl,
                                          alt: "",
                                        },
                                      }),
                                  _vm.user.publicUserId != item.ownerId
                                    ? _c(
                                        "router-link",
                                        {
                                          staticClass: "custom-a-blue",
                                          attrs: {
                                            to: {
                                              name: "Team Member Profile",
                                              params: {
                                                publicUserId: item.ownerId,
                                              },
                                            },
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(item.ownerName) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "router-link",
                                        {
                                          staticClass: "custom-a-blue",
                                          attrs: {
                                            to: {
                                              name: "User Profile",
                                            },
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(item.ownerName) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "md-table-cell",
                          { staticClass: "action-buttons" },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-warning md-just-icon md-round",
                                attrs: { title: "Update asset group name" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleUpdateAssetGroup(item)
                                  },
                                },
                              },
                              [_c("md-icon", [_vm._v("edit")])],
                              1
                            ),
                            !item.disabledDate
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-info md-just-icon md-round",
                                    attrs: { title: "Assign a member" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleAssignAssetGroup({
                                          assetGroupId: item.assetGroupId,
                                          ownedBy: item.ownerId,
                                          groupName: item.groupName,
                                          teamRegionId: item.teamRegionId,
                                        })
                                      },
                                    },
                                  },
                                  [_c("md-icon", [_vm._v("person_pin")])],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _vm.list.length == 0 && !_vm.isListLoading
                ? _c("div", { staticClass: "text-center" }, [
                    _c("p", { staticClass: "no-result-message" }, [
                      _vm._v(
                        "No results matching your search/filter could be found."
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.list.length > 0
                ? _c(
                    "md-card-actions",
                    {
                      staticClass: "page-footer",
                      attrs: { "md-alignment": "space-between" },
                    },
                    [
                      _c("div", [
                        _vm.total === _vm.pagination.perPage
                          ? _c("p", { staticClass: "card-category" }, [
                              _vm._v(
                                "\n                    Page " +
                                  _vm._s(_vm.pagination.currentPage) +
                                  " of many\n                "
                              ),
                            ])
                          : _c("p", { staticClass: "card-category" }, [
                              _vm._v(
                                "Page " +
                                  _vm._s(_vm.pagination.currentPage) +
                                  " of " +
                                  _vm._s(_vm.totalPages)
                              ),
                            ]),
                      ]),
                      _c("pagination", {
                        staticClass: "pagination-no-border pagination-success",
                        attrs: {
                          "per-page": _vm.pagination.perPage,
                          total: _vm.total,
                        },
                        on: {
                          "change-page": function ($event) {
                            return _vm.handleChangePage(
                              $event,
                              _vm.pagination.perPage
                            )
                          },
                        },
                        model: {
                          value: _vm.pagination.currentPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.pagination, "currentPage", $$v)
                          },
                          expression: "pagination.currentPage",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("div", [
            _c("div", { staticClass: "body-list" }, [
              _c(
                "div",
                { staticClass: "list-loader" },
                [
                  _c("fade-loader", {
                    attrs: { loading: _vm.isListLoading, color: "#333333" },
                  }),
                  _c("span", [_vm._v("LOADING")]),
                ],
                1
              ),
            ]),
          ]),
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.showSideBar
            ? _c("asset-group-sidebar", {
                attrs: { "asset-group-id": _vm.assetGroupId },
                on: { closeModal: _vm.toggleAssetGroupDetailsWindow },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }