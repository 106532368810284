<template>
    <div class="details-page" v-if="assetGroupDetails != null">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <h3 class="title" v-if="assetGroupDetails.groupName != null">
                            {{ assetGroupDetails.groupName }}
                            <badge type="warning" class="custom-badge">Asset Group</badge>
                            <badge v-if="assetGroupDetails.disabledDate != null" type="danger" class="custom-badge">
                                Disabled
                            </badge>
                        </h3>
                        <h3 class="title" v-else>
                            {{ assetGroupDetails.assetGroupId }}
                            <badge type="warning" class="custom-badge">Asset Group</badge>
                            <badge v-if="assetGroupDetails.disabledDate != null" type="danger" class="custom-badge">
                                Disabled
                            </badge>
                        </h3>
                    </div>
                    <div class="custom-toolbar-end" v-if="!$root.isMobileOnly">
                        <div class="header-button-container">
                            <!-- Asset group edit button -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout md-alignment-top-left content-layout-panel">
            <div
                class="md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-66 md-xlarge-size-50"
            >
                <div class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-blue">
                            <div class="card-icon custom-header-icon-text">
                                <md-icon>description</md-icon>
                                Asset Group Details
                            </div>
                        </md-card-header>

                        <md-card-content>
                            <div class="asset-details-box details-box">
                                <div>
                                    <label>Asset Group Name</label>
                                    <span class="value">
                                        {{ assetGroupDetails.groupName }}
                                    </span>
                                </div>
                                <div v-if="assetGroupDetails.disabledDate != null">
                                    <label>Disabled Date</label>
                                    <span class="value">
                                        {{ assetGroupDetails.disabledDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div class="line-separator"></div>
                                <h4 class="small-details-title">Connected Assets</h4>
                                <div v-if="assetGroupDetails.connectedAssets.length > 0">
                                    <div v-for="(item, index) in assetGroupDetails.connectedAssets" :key="index">
                                        <!-- <div class="line-separator"></div> -->
                                        <!-- <h4 class="small-details-title">                                                 -->
                                        <div class="value icon-asset-name">
                                            <div>
                                                <md-icon>
                                                    {{ $_getAssetIcon(item.type) }}
                                                    <md-tooltip md-direction="right">
                                                        {{ item.type }}
                                                    </md-tooltip>
                                                </md-icon>
                                            </div>
                                            <div>
                                                <router-link
                                                    :to="{
                                                        name: 'Asset Details',
                                                        params: { assetId: item.assetId }
                                                    }"
                                                    target="_blank"
                                                >
                                                    {{ item.name }}
                                                </router-link>
                                            </div>
                                        </div>
                                        <!-- </h4> -->
                                        <!-- <div class="asset-details-box details-box">
                                <div>
                                    <label>Asset Name</label>

                                </div>
                            </div> -->
                                    </div>
                                </div>
                                <div v-else>No connected assets</div>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>

                <asset-group-history :asset-logs="assetLogs" :asset-group-id="assetGroupDetails.assetGroupId" />
            </div>
            <div
                class="md-layout-item md-small-size-100 md-layout md-medium-size-100 md-large-size-33 md-xlarge-size-50"
                v-if="$root.isDesktop"
            >
                <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-warning">
                            <div class="card-icon custom-header-icon-text">
                                <md-icon>fact_check</md-icon>
                                Asset Logs
                            </div>
                        </md-card-header>

                        <md-card-content>
                            <div>No logs available</div>
                        </md-card-content>
                    </md-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { Badge } from '@/components';
import { handleRequests } from '@/helpers';
import { mapGetters } from 'vuex';
import { trackerDefaults } from '@/utils/customAssetFieldDefaults';
import AssetGroupHistory from './AssetGroupOwnerHistoryLog';

export default {
    name: 'AssetGroupDetails',
    mixins: [GeneralMixin],
    components: {
        Badge,
        AssetGroupHistory
    },
    computed: {
        ...mapGetters({
            getUser: 'user/user'
        })
    },
    async mounted() {
        this.$_handleLoaderState(true);
        const user = this.getUser;
        if (user.assetCustomFieldDefinitions) {
            this.assetCustomFieldList = user.assetCustomFieldDefinitions;
        }

        await this.getAssetGroupDetails();
        // this.$_handleLoaderState(false);
    },

    data() {
        return {
            assetGroupDetails: null,
            assetCustomFieldList: [],
            trackerExtraFields: [],
            customFieldValues: {},
            assetLogs: []
        };
    },
    methods: {
        async getAssetGroupDetails() {
            this.$_handleLoaderState(true);
            const api = `/api/asset-groups/${this.$route.params.assetGroupId}`;
            const {
                data: { data }
            } = await handleRequests(api);

            this.assetGroupDetails = data;
            this.customFieldValues = data.customFields;

            this.trackerExtraFields = trackerDefaults;
            this.assetLogs = data.ownerHistory;
            this.$_handleLoaderState(false);
        }
    }
};
</script>

<style scoped lang="scss">
.content-layout-panel {
    > div {
        padding: 0;
    }
}

.custom-badge {
    height: initial;
    width: initial;
    position: initial;
    border-radius: initial;
    margin: initial;
    border-radius: 12px;
    padding: 5px 12px;
    text-transform: uppercase;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    margin-left: 10px;
    vertical-align: sub;
    max-width: 200px;
}

.asset-details-box {
    > div {
        display: inline-block;
        min-width: 200px;
    }
    > div:first-child {
        width: 350px;
    }
}

.tracker-details-box {
    > div {
        display: inline-block;
        min-width: 200px;
        margin: 10px 0;
    }
}

.line-separator {
    height: 1px;
    background-color: #eee;
}

.small-details-title {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 800;
}

.header-button-container {
    > button {
        margin-right: 0;
    }
    .status-btn {
        display: inline-block;
        text-transform: uppercase;
        font-size: 12px;
        > ::v-deep span {
            margin: 6px 8px;
            height: 36px;
            line-height: 36px;
            border-radius: 2px;
            margin-right: 0px;
        }

        > ::v-deep .dropdown-menu {
            margin-left: 9px;
            margin-top: 0;
        }
    }
}

.icon-asset-name {
    display: table;
    margin-bottom: 5px;

    > div {
        display: table-cell;
        vertical-align: middle;
    }

    > div:first-child {
        padding-right: 10px;
    }

    ::v-deep a {
        display: block;
        margin-top: 3px;
    }

    ::v-deep i {
        font-size: 20px !important;
        width: 20px;
        height: 20px;
        min-width: 20px;
    }
}
</style>
