<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ getModalTitle() }} Quotes</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <div
                class="top-section"
                v-if="!isLoading && (activeQuotes.length > 0 || expiredQuotes.length > 0 || errorQuotes.length > 0)"
            >
                <div class="date-list">
                    <drop-down
                        :should-close="shouldCloseDropdown"
                        @closeDropdown="(val) => (this.shouldCloseDropdown = val)"
                        @closeOtherDropDown="closeOtherDropDown"
                    >
                        <span class="quote-history-dropdown" slot="title" data-toggle="dropdown">Quote History</span>
                        <ul class="dropdown-menu">
                            <li
                                v-for="(date, index) in quoteDateOptions"
                                :key="index"
                                :class="selectedDateKey == date ? 'selected-date' : ''"
                            >
                                <a @click.stop="handleQuoteHistory(date)">
                                    {{ toLocalTime(date) }}
                                </a>
                            </li>
                        </ul>
                    </drop-down>
                </div>
            </div>
            <div class="content" v-if="!isLoading">
                <tabs
                    :tab-name="tabNames"
                    color-button="success"
                    class="custom-tab-list"
                    @switch-panel="switchTab"
                    :badge-length="badgeLength"
                    :hide-empty-tabs="true"
                    :selected-panel="activeTab"
                >
                    <template slot="tab-pane-1">
                        <div class="old-quote-warning" v-if="activeQuotes.length > 0 && latestDate != selectedDateKey">
                            These are old quotes generated at
                            {{ toLocalTime(selectedDateKey) }}
                        </div>
                        <div v-if="showIncomingQuoteLoader" class="text-center quotes-loader-container">
                            <div class="quotes-loader">
                                <StraightLineLoader />
                            </div>
                            Retrieving quotes
                        </div>
                        <template v-if="activeQuotes.length > 0">
                            <div>
                                <md-table
                                    @md-selected="onSelect"
                                    :value="activeQuotes"
                                    :md-sort.sync="sortBy"
                                    :md-sort-order.sync="sortOrder"
                                >
                                    <md-table-row
                                        slot="md-table-row"
                                        md-selectable="single"
                                        slot-scope="{ item, index }"
                                        :key="index"
                                    >
                                        <md-table-cell md-label="" class="carrier-logo">
                                            <img
                                                v-if="item.carrierTeam.logoUrl != null"
                                                :src="item.carrierTeam.logoUrl"
                                                alt=""
                                            />
                                            <img v-else :src="$root.defaultLogoUrl" alt="" />
                                        </md-table-cell>
                                        <md-table-cell md-label="Carrier" md-sort-by="carrierTeam.company">
                                            {{ item.carrierTeam.company }}
                                        </md-table-cell>
                                        <md-table-cell md-label="Amount" md-sort-by="amountAfterTax">
                                            {{ item.amountAfterTax | currency(item.currency) }}
                                        </md-table-cell>
                                        <md-table-cell md-label="Service Type" md-sort-by="serviceLevel">
                                            <span
                                                v-if="item.serviceLevel"
                                                class="custom-badge"
                                                :class="`status-${$_getServiceLevelBadgeColor(item.timeWindowEnd)}`"
                                            >
                                                {{ $t(item.serviceLevel) }}
                                            </span>
                                        </md-table-cell>
                                        <md-table-cell md-label="Pickup ETA" md-sort-by="timeWindowEnd">
                                            {{ item.timeWindowStart | quoteDateTimeFormat }}
                                        </md-table-cell>
                                        <md-table-cell md-label="Delivery ETA" md-sort-by="timeWindowEnd">
                                            {{ item.timeWindowEnd | quoteDateTimeFormat }}
                                        </md-table-cell>
                                    </md-table-row>
                                </md-table>
                            </div>
                        </template>
                        <template v-else>
                            <div v-if="!showIncomingQuoteLoader">
                                <span class="empty-quotes" v-if="hasError">
                                    Error in fetching quotes. Please try again later or
                                    <a class="quote-link" @click.stop="requestQuotes()">
                                        click here to refresh qoutes.
                                    </a>
                                </span>
                                <span class="empty-quotes" v-else-if="shipment.quotingStatus === 'GatheringQuotes'">
                                    No available quotes at the moment.
                                    <br />
                                    <br />
                                    <a class="quote-link" @click.stop="requestQuotes()">
                                        Click here to refresh qoutes.
                                    </a>
                                </span>
                                <span class="empty-quotes" v-else>No quotes gathered.</span>
                            </div>
                        </template>
                    </template>
                    <template slot="tab-pane-2">
                        <div class="old-quote-warning" v-if="expiredQuotes.length > 0 && latestDate != selectedDateKey">
                            These are old quotes generated at
                            {{ toLocalTime(selectedDateKey) }}
                        </div>
                        <div class="text-center quotes-loader-container" v-if="showIncomingQuoteLoader">
                            <div class="quotes-loader">
                                <StraightLineLoader />
                            </div>
                            Retrieving quotes
                        </div>
                        <template v-if="expiredQuotes.length > 0">
                            <md-table :value="expiredQuotes" :md-sort.sync="sortBy" :md-sort-order.sync="sortOrder">
                                <md-table-row :class="getClass(item)" slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="" class="carrier-logo">
                                        <img
                                            v-if="item.carrierTeam.logoUrl && item.carrierTeam.logoUrl != null"
                                            :src="item.carrierTeam.logoUrl"
                                            alt=""
                                        />
                                        <img v-else :src="$root.defaultLogoUrl" alt="" />
                                    </md-table-cell>
                                    <md-table-cell md-label="Carrier" class="carrier-name">
                                        {{ item.carrierTeam ? item.carrierTeam.company : '' }}
                                    </md-table-cell>
                                    <md-table-cell md-label="Amount" md-sort-by="amountAfterTax">
                                        <span>
                                            {{ item.amountAfterTax | currency(item.currency) }}
                                        </span>
                                    </md-table-cell>
                                    <md-table-cell md-label="Service Type">
                                        <span
                                            v-if="item.serviceLevel"
                                            class="custom-badge"
                                            :class="`status-${$_getServiceLevelBadgeColor(item.timeWindowEnd)}`"
                                        >
                                            {{ $t(item.serviceLevel) }}
                                        </span>
                                    </md-table-cell>
                                    <md-table-cell md-label="Expired">
                                        <span class="md-list-item-text">
                                            {{ toLocalTime(item.expiryDate) }}
                                        </span>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <md-icon v-if="item.notes" class="help-icon">
                                            help
                                            <md-tooltip>{{ item.notes }}</md-tooltip>
                                        </md-icon>
                                    </md-table-cell>
                                    <md-table-cell md-label="">
                                        <div class="action-button-container">
                                            <md-button
                                                title="Refresh quotes"
                                                class="md-success md-just-icon md-round"
                                                @click.stop="requestQuotes(item.carrierTeam.carrierTeamId)"
                                            >
                                                <md-icon>refresh</md-icon>
                                            </md-button>
                                        </div>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </template>
                        <template v-else>
                            <span class="empty-quotes">No expired quotes.</span>
                        </template>
                    </template>
                    <template slot="tab-pane-3">
                        <div class="text-center quotes-loader-container" v-if="showIncomingQuoteLoader">
                            <div class="quotes-loader">
                                <StraightLineLoader />
                            </div>
                            Retrieving quotes
                        </div>
                        <div class="old-quote-warning" v-if="errorQuotes.length > 0 && latestDate != selectedDateKey">
                            These are old quotes generated at
                            {{ selectedDateKey | dateTimeFormat(DATE_TYPES.standardDate) }}
                        </div>
                        <template v-if="errorQuotes.length > 0">
                            <md-table :value="errorQuotes" class="table-hover">
                                <md-table-row
                                    :class="getClass(item)"
                                    slot="md-table-row"
                                    slot-scope="{ item, index }"
                                    :key="index"
                                >
                                    <md-table-cell md-label="" class="carrier-logo">
                                        <img
                                            v-if="
                                                item.carrierTeam &&
                                                    item.carrierTeam.logoUrl &&
                                                    item.carrierTeam.logoUrl != null
                                            "
                                            :src="item.carrierTeam.logoUrl"
                                            alt=""
                                        />
                                        <img v-else :src="$root.defaultLogoUrl" alt="" />
                                    </md-table-cell>
                                    <md-table-cell md-label="Carrier" class="carrier-name">
                                        {{ item.carrierTeam ? item.carrierTeam.company : '' }}
                                    </md-table-cell>
                                    <md-table-cell md-label="Message">
                                        {{ item.error ? item.error.message : '' }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        <md-icon v-if="shouldDisplayErrors(item)" class="help-icon">
                                            help
                                            <md-tooltip>{{ item.error.errors }}</md-tooltip>
                                        </md-icon>
                                        <template v-else>
                                            &nbsp;
                                        </template>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </template>
                        <template v-else>
                            <span class="empty-quotes">No quotes gathered</span>
                        </template>
                    </template>
                </tabs>
            </div>
            <div
                v-else-if="
                    showIncomingQuoteLoader &&
                        activeQuotes.length == 0 &&
                        expiredQuotes.length == 0 &&
                        errorQuotes.length == 0
                "
            >
                <template>
                    <div class="list-loader">
                        <fade-loader :loading="isLoading" color="#333333" />
                        <span>Retrieving Quotes</span>
                    </div>
                    <!-- <span class="empty-quotes">Retrieving Quotes</span> -->
                </template>
            </div>
            <div v-else>
                <template>
                    <div class="list-loader">
                        <fade-loader :loading="isLoading" color="#333333" />
                        <span>Retrieving Quotes</span>
                    </div>
                    <!-- <span class="empty-quotes">Retrieving Quotes</span> -->
                </template>
            </div>
        </div>
        <div class="modal-footer">
            <md-button
                class="dialog-button md-primary reload-icon"
                @click.stop="requestQuotes()"
                v-if="
                    shipment.quotingStatus != 'GatheringQuotes' &&
                        (shipment.status == 'Pending' || shipment.status == 'On Hold') &&
                        !isPastDate &&
                        !this.isLoading
                "
            >
                Refresh Quotes
            </md-button>
            <md-button
                v-if="
                    !isBulk &&
                        shipment.quotingStatus != 'GatheringQuotes' &&
                        (shipment.status == 'Pending' || shipment.status == 'On Hold') &&
                        !isPastDate
                "
                :disabled="!selectedQuote || hasAssignedCarrier"
                class="dialog-button md-primary"
                @click.stop="assignShipment"
            >
                Assign Shipment
            </md-button>
            <md-button
                v-if="
                    isBulk &&
                        shipment.quotingStatus != 'GatheringQuotes' &&
                        (shipment.status == 'Pending' || shipment.status == 'On Hold') &&
                        !isPastDate
                "
                :disabled="!selectedQuote || hasAssignedCarrier"
                class="dialog-button md-primary"
                @click.stop="quoteSelected"
            >
                Select Quote
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Close</md-button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { GeneralMixin, CarrierMixin } from '@/mixins';
import { mapGetters } from 'vuex';
import { DATE_TYPES } from '@/utils/constants';
import { handleRequests, showErrorMessage } from '@/helpers';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { Tabs } from '@/components';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import CarrierPreBookingQuestionsModal from '@/components/JobOffers/CarrierPreBookingQuestionsModal';
import StraightLineLoader from '../StraightLineLoader';

export default {
    name: 'QuotesModal',
    props: {
        shipment: {
            type: Object,
            default: () => null
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        newQuoteGenerated: {
            type: Object,
            default: () => null
        },
        quoteModalStatus: {
            type: String,
            default: null
        },
        isBulk: {
            type: Boolean,
            default: false
        }
    },
    components: { Tabs, FadeLoader, StraightLineLoader },
    mixins: [GeneralMixin, CarrierMixin],
    data() {
        return {
            quotes: [],
            selectedQuote: null,
            selectedDateKey: null,
            latestDate: null,
            tabNames: ['Quotes', 'Expired', 'Failed Quotes'],
            activeTab: 'Quotes',
            shouldCloseDropdown: false,
            pollInterval: null,
            isLoading: true,
            pollingIndex: 0,
            hasError: false,
            showIncomingQuoteLoader: false,
            isPastDate: false,
            gatherQuotesTimeout: null,
            sortBy: 'amountAfterTax',
            sortOrder: 'asc'
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            allTeamCarriers: 'team/teamCarriers',
            generalSettings: 'generalSetting/getGeneralSettings'
        }),
        quoteDateOptions() {
            if (this.quotes.length == 0) return null;

            var dates = this.quotes.map((obj) => obj['createdDate']);
            // First sort the array
            dates.sort();
            // Then reverse it:
            dates.reverse();
            this.selectedDateKey = dates[0];
            this.latestDate = dates[0];

            return _.uniq(dates);
        },
        activeQuotes() {
            if (!this.selectedDateKey) return [];

            var quotes = this.quotes.filter(
                (x) =>
                    this.selectedDateKey == x.createdDate &&
                    x.error == null &&
                    (x.expiryDate == null || moment().isBefore(moment.utc(x.expiryDate).local()))
            );
            return quotes;
        },
        expiredQuotes() {
            if (!this.selectedDateKey) return [];

            var quotes = this.quotes.filter(
                (x) =>
                    this.selectedDateKey == x.createdDate &&
                    x.expiryDate != null &&
                    moment().isAfter(moment.utc(x.expiryDate).local())
            );
            return quotes;
        },
        errorQuotes() {
            if (!this.selectedDateKey) return [];

            var quotes = this.quotes.filter((x) => this.selectedDateKey == x.createdDate && x.error != null);

            return quotes;
        },
        badgeLength() {
            var badgeCount = [];

            badgeCount.push(this.activeQuotes.length);
            badgeCount.push(this.expiredQuotes.length);
            badgeCount.push(this.errorQuotes.length);

            return badgeCount;
        },
        hasAssignedCarrier() {
            return this.shipment.carrierTeamId != null;
        },
        isAssigned() {
            return !!this.shipment.assignedTo.publicUserId || !!this.shipment.carrierTeamId;
        },
        showRequestQuote() {
            return !this.shipment.assignedTo.publicUserId && !this.shipment.carrierTeamId;
        }
    },
    watch: {
        selectedDateKey(value) {
            if (this.activeQuotes.length > 0) {
                this.activeTab = 'Quotes';
            } else if (this.expiredQuotes.length > 0) {
                this.activeTab = 'Expired';
            } else if (this.errorQuotes.length > 0) {
                this.activeTab = 'Failed Quotes';
            } else {
                this.activeTab = null;
            }
        },
        'shipment.newQuotes': function(quote) {
            if (this.gatherQuotesTimeout != null) {
                clearTimeout(this.gatherQuotesTimeout);
            }
            this.quotes.push(quote);
            this.quoteDateOptions;
        },
        'shipment.errorQuotes': function(quote) {
            if (this.gatherQuotesTimeout != null) {
                clearTimeout(this.gatherQuotesTimeout);
            }
            this.quotes.push(quote);
            this.quoteDateOptions;
        },
        'shipment.showLoader': async function(value) {
            if (value) {
                this.showIncomingQuoteLoader = true;
                this.isLoading = false;
            } else {
                if (this.activeQuotes.length > 0) this.activeTab = 'Quotes';
                else if (this.expiredQuotes.length > 0) this.activeTab = 'Expired Quotes';
                else if (this.errorQuotes.length > 0) this.activeTab = 'Error Quotes';

                this.showIncomingQuoteLoader = false;
            }
        },
        'shipment.finalQuotes': function() {
            this.loadQuotes();
        }
    },
    beforeDestroy() {
        clearInterval(this.polling);
    },
    methods: {
        getModalTitle() {
            if (this.shipment == null) return null;

            var pickupAddress = this.shipment.pickupAddressComponent;
            var dropAddress = this.shipment.dropAddressComponent;

            if (pickupAddress != null && dropAddress != null) {
                return `${pickupAddress.city ?? '-'} to ${dropAddress.city ?? '-'}`;
            }

            return this.shipment.shipmentRef;
        },
        switchTab(currentTab) {
            this.activeTab = currentTab;
        },
        toLocalTime(utcDate) {
            if (utcDate) {
                return this.$options.filters.dateTimeFormat(moment.utc(utcDate).local(), DATE_TYPES.standardDate);
            }
            return utcDate;
        },
        onSelect(item) {
            if (!item.carrierQuoteId) return;

            // show error message

            this.selectedQuote = item;
        },
        getClass(item) {
            if (this.selectedQuote) {
                if (item.carrierQuoteId === this.selectedQuote.carrierQuoteId) {
                    return {
                        'quote-selected': true
                    };
                }
            }
        },
        async loadQuotes() {
            if (!this.shipment) return;

            const api = `/api/carriers/${this.shipment.shipmentId}/quotes`;
            const payload = {
                method: 'get',
                params: {
                    shipmentId: this.shipment.shipmentId
                }
            };

            await this.execRequest(api, payload);
        },
        async requestQuotes(carrierTeamId = null) {
            this.isLoading = true;
            if (!this.shipment) return;

            const carrierTeamIds = [];

            if (carrierTeamId != null) {
                carrierTeamIds.push(carrierTeamId);
            }

            const api = `/api/carriers/${this.shipment.shipmentId}/request-quotes`;
            const payload = {
                method: 'post',
                data: {
                    shipmentId: this.shipment.shipmentId,
                    teamCarrierIdList: carrierTeamIds // pass specific here
                }
            };

            await this.execRequest(api, payload);

            if (this.shipment.quotingStatus == null || this.shipment.quotingStatus == 'QuotesGathered') {
                this.shipment.quotingStatus = 'QuotesGathered';
            }
        },
        async execRequest(api, payload) {
            await handleRequests(api, payload)
                .then((resp) => {
                    this.quotes = [];
                    this.quotes = resp.data && resp.data ? resp.data : [];
                })
                .catch(() => {
                    this.$notify({
                        message: 'Error retrieving quotes.',
                        type: 'danger'
                    });

                    this.isLoading = false;
                })
                .finally(() => {
                    this.quoteDateOptions;
                    this.isLoading = false;
                    this.$_handleLoaderState(false);
                });
        },
        async assignShipment() {
            this.$_handleLoaderState(true);
            if (!this.selectedQuote) {
                this.$notify({
                    message: 'Please select quote to assign shipment to its carrier.',
                    type: 'danger'
                });
                this.$_handleLoaderState(false);
                return;
            }

            // get questionnaires and required fields that were not filled-up
            const preBookingQueryResults = await this.getPreBookingRequiredFields({
                shipmentId: this.shipment.shipmentId,
                carrierTeamId: this.selectedQuote.carrierTeamId
            });

            if (!preBookingQueryResults) {
                // there was an error
                const message = 'Could not retrieve question mappings.';
                showErrorMessage(this, message);
                this.$_handleLoaderState(false);
                return;
            }

            const { requiredFields, questionnaires, fixedAnswers } = preBookingQueryResults;
            const mandatoryFields = requiredFields.map((fld) => {
                return {
                    ...fld,
                    label: this.$t(`carrierFieldLabels.${fld.name}`)
                };
            });

            if (!questionnaires.length && !mandatoryFields.length) {
                // no questions and required fields to answer, continue
                const combinedList = [...questionnaires, ...fixedAnswers];
                this.continueAssignment(combinedList, []);
            } else {
                this.$_handleLoaderState(false);
                questionnaires.sort(this.sortByIsRequiredAndAlphabetical);
                // show pre-booking questions
                this.$modal
                    .show(CarrierPreBookingQuestionsModal, {
                        carrierTeamId: this.selectedQuote.carrierTeamId,
                        carrierName: this.selectedQuote.carrierTeam.company,
                        fixedMappingList: [...fixedAnswers],
                        newAnswersList: [...questionnaires],
                        mandatoryFields: [...mandatoryFields]
                    })
                    .then((response) => {
                        if (response && response.result) {
                            const combinedList = [...fixedAnswers, ...response.data.newAnswersList];
                            return this.continueAssignment(combinedList, response.data.mandatoryFields);
                        }
                        return Promise.resolve();
                    });
            }
        },
        async quoteSelected() {
            this.resolve(this.selectedQuote);
        },
        sortByIsRequiredAndAlphabetical(a, b) {
            if (a.isRequired && !b.isRequired) {
                return -1;
            }

            if (!a.isRequired && b.isRequired) {
                return 1;
            }

            return a.label.localeCompare(b.label);
        },
        async continueAssignment(carrierQuestionAnswers, mandatoryFields) {
            this.$_handleLoaderState(true, 'ASSIGNING SHIPMENT...');
            const api = `/api/shipments/${this.shipment.shipmentId}/assign-user`;
            const data = {
                assignToCarrierTeamId: this.selectedQuote.carrierTeamId,
                carrierQuoteId: this.selectedQuote.carrierQuoteId,
                carrierQuestionAnswers: carrierQuestionAnswers,
                carrierRequiredFields: mandatoryFields
            };

            const payload = {
                method: 'post',
                data
            };

            try {
                const { data: returnedData } = await handleRequests(api, payload);

                this.$notify({
                    message: `Shipment was assigned to ${returnedData?.carrierTeam?.company}`,
                    type: 'success'
                });

                this.resolve(returnedData);
            } catch (e) {
                let message = 'Cannot assign shipment to the carrier.';
                if (e && e.message) message = e.message;
                showErrorMessage(this, message, e);
                if (e.data && e.data[0].details) {
                    this.resolve({
                        type: 'error',
                        data: { errors: e.data[0].details, carrierName: this.selectedQuote.carrierTeam?.company }
                    });
                } else {
                    this.resolve(false);
                }
            }
        },
        closeOtherDropDown() {
            const dropdowns = document.querySelectorAll('.open');
            dropdowns.forEach((dropdown) => dropdown.classList.remove('open'));
        },
        handleQuoteHistory(date) {
            this.selectedDateKey = date;
            this.shouldCloseDropdown = true;
        },
        pollData() {
            this.pollInterval = setInterval(() => {
                this.loadQuotes(false);
            }, 10000);
        },
        async getPreBookingRequiredFields({ shipmentId, carrierTeamId }) {
            try {
                const api = `/api/carriers/${shipmentId}/pre-booking-check`;
                const payload = {
                    method: 'post',
                    data: { shipmentId, carrierTeamId }
                };
                const { data: requirdFields } = await handleRequests(api, payload);
                return requirdFields;
            } catch (e) {
                let message = 'Unable to perform pre-booking check.';
                if (e && e.message) message = e.message;
                showErrorMessage(this, message, e);
                return null;
            }
        },
        shouldDisplayErrors(item) {
            return item.error && item.error.errors && Object.keys(item.error.errors).length > 0;
        }
    },
    async mounted() {
        if (this.shipment.tripDate != null) {
            const tripDate = moment(this.shipment.tripDate).format(DATE_TYPES.internationalDate);
            const today = moment().format(DATE_TYPES.internationalDate);

            if (moment(tripDate).isBefore(today)) {
                this.isPastDate = true;
            }
        }

        if (
            this.shipment.quotingStatus != 'GatheringQuotes' ||
            (this.shipment.quotes != null && this.shipment.quotes.length != null)
        ) {
            await this.loadQuotes();
            this.isLoading = false;
        }

        this.gatherQuotesTimeout = setTimeout(async () => {
            if (this.shipment.quotingStatus == 'GatheringQuotes') {
                await this.loadQuotes();
                this.isLoading = false;
            }
        }, 120000);

        this.quoteDateOptions;
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    width: 1000px;
    max-width: 90% !important;
}

.modal-body {
    position: relative;
    min-height: 45vh;

    ::v-deep .nav-tabs {
        margin-right: 0;
    }
}

.lowest-price-icon {
    color: green !important;
}

.top-section {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 9;
    right: 80px;
    top: -26px;
}
.reload-icon {
    display: inline-block;
    margin: auto auto auto 0 !important;
}

.content {
    display: block;
}

.carrier-name {
    width: 180px;
    ::v-deep .md-table-cell-container {
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        width: 100%;
    }
}

::v-deep .md-table-head-label {
    font-size: 15px;
    color: #4caf50 !important;
    padding-right: 22px;
    i {
        left: initial;
        right: 0;
    }
    svg {
        fill: #4caf50 !important;
    }
}

.date-list {
    ::v-deep input,
    ::v-deep li {
        cursor: pointer !important;
    }

    ::v-deep ul {
        right: 0;
        left: initial;
    }

    .selected-date {
        background-color: #2b93ff;
        a {
            color: #fff !important;
        }
    }
}

::v-deep .vs--searchable .vs__dropdown-toggle {
    cursor: pointer !important;
}

.quote-selected {
    background-color: #4caf50 !important;
    color: #fff;
    font-weight: inherit !important;
    .lowest-price-icon {
        color: #fff !important;
    }
}

.error-tab ::v-deep .active.md-list-item.md-success .md-list-item-button {
    color: #fff !important;
    background-color: #f44336 !important;
}

.icon-column {
    width: 40px;
}

.carrier-logo {
    width: 50px;
    img {
        width: 100%;
    }
}

::v-deep .md-table-row.md-selected-single {
    font-weight: inherit !important;
}
::v-deep .tab-content {
    min-height: 365px;

    .md-table-content {
        max-height: 48vh;
    }
}

.empty-quotes {
    font-size: 20px;
    color: #aaa;
    display: block;
    text-align: center;
    padding-top: 50px;
}

.badge {
    width: 19px;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 2px;
    background: #eee;
    border-radius: 100%;
    color: #000;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.05em;
    font-family: 'Roboto Mono', monospace;
}

.old-quote-warning {
    background-color: #ffa726;
    color: #fff;
    text-align: center;
    font-size: 12px;
}

.quote-history-dropdown {
    font-size: 12px;
    font-weight: 400;
    padding: 5px 0;
    text-transform: uppercase;
    color: #2b93ff;
}

.quote-history-dropdown:hover {
    cursor: pointer;
}

.help-icon {
    ::v-deep {
        color: #ff9800 !important;
    }
}

.custom-tab-list ::v-deep .nav-tabs .active.md-list-item:first-child {
    .md-badge {
        color: #000 !important;
        background-color: #eee !important;
    }
}

.custom-tab-list ::v-deep .nav-tabs .md-list-item:first-child {
    .md-badge {
        color: #fff !important;
        background-color: #4caf50 !important;
    }
}

.list-loader span {
    width: 200px;
    left: calc((100% - 190px) / 2);
}

.quote-link {
    color: #2b93ff !important;
}

.quote-link:hover {
    cursor: pointer;
}

.quotes-loader {
    display: inline-block;
    margin-right: 3px;
}

svg {
    width: 20px;
    height: 15px;
    display: inline-block;
}

.quotes-loader-container {
    background-color: #2b93ff;
    color: #fff;
}

.custom-badge {
    max-width: 130px !important;
}

.action-button-container {
    .md-button {
        background: #4caf50 !important;
        font-weight: initial;
    }
    .md-button:hover {
        background: #4caf50 !important;
        font-weight: initial;
    }
}
</style>
