var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "offers-panel" }, [
    _c("div", [
      _vm.offerList.length > 5
        ? _c(
            "div",
            { staticClass: "search--container" },
            [
              _c(
                "md-field",
                {
                  attrs: { "md-clearable": "" },
                  on: { "md-clear": _vm.handleClear },
                },
                [
                  _c("md-input", {
                    attrs: { type: "text", placeholder: "Search" },
                    model: {
                      value: _vm.searchText,
                      callback: function ($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText",
                    },
                  }),
                  _c("md-icon", [_vm._v("search")]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "ul",
        _vm._l(_vm.filteredOffers, function (driver, driverIndex) {
          return _c(
            "li",
            {
              key: driver.publicUserId,
              class: {
                isPinned: driver.isPinned,
              },
              on: {
                mouseover: function ($event) {
                  return _vm.highlightDriver(driver)
                },
                mouseout: function ($event) {
                  return _vm.highlightDriver(null)
                },
              },
            },
            [
              _c("DriverOnlineStatus", {
                attrs: {
                  "driver-name": driver.driverName,
                  "is-online": driver.isOnline,
                },
              }),
              _c(
                "span",
                {
                  staticClass: "name",
                  on: {
                    click: function ($event) {
                      return _vm.pinOffer(driver)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(driverIndex + 1) + ". " + _vm._s(driver.driverName)
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "distance",
                  on: {
                    click: function ($event) {
                      return _vm.pinOffer(driver)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                     (+" +
                      _vm._s(Math.round(driver.additionalDistance * 10) / 10) +
                      " " +
                      _vm._s(driver.distanceUnits) +
                      _vm._s(
                        _vm.formatDrivingTime(driver.additionalTimeSeconds)
                      ) +
                      ")\n                "
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "zoom",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.focusOnDriver(driver)
                    },
                  },
                },
                [
                  _c("md-icon", { staticClass: "footer-icon" }, [
                    _vm._v("my_location"),
                  ]),
                  _c(
                    "md-tooltip",
                    {
                      staticClass: "toggle-tooltip",
                      attrs: { "md-direction": "top" },
                    },
                    [
                      _vm._v(
                        "\n                        Centre map on\n                        "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                        " +
                          _vm._s(driver.driverName) +
                          "\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.pinOffer(driver)
                    },
                  },
                },
                [
                  _c("md-icon", { staticClass: "footer-icon user-pin" }, [
                    _vm._v("push_pin"),
                  ]),
                  _c(
                    "md-tooltip",
                    {
                      staticClass: "toggle-tooltip",
                      attrs: { "md-direction": "top" },
                    },
                    [_vm._v("Pin " + _vm._s(driver.driverName))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm.offerList.length === 0
        ? _c("span", { staticClass: "error-text" }, [
            _vm._v("No drivers found."),
          ])
        : _vm.filteredOffers.length === 0
        ? _c("span", { staticClass: "error-text" }, [
            _vm._v(
              "\n            There is no driver named '" +
                _vm._s(_vm.searchText) +
                "'.\n        "
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }