<template>
    <div class="md-layout">
        <div class="md-layout-item">
            <div class="custom-toolbar md-layout">
                <div class="custom-toolbar-start">
                    <div class="filter-steps--container">
                        <md-field class="customer-filter" v-if="!isReadOnlyUser" md-clearable @md-clear="handleClear">
                            <customer-autocomplete
                                label="Customer"
                                id="autocustomer"
                                ref="customerAutocomplete"
                                placeholder=""
                                :should-focus="false"
                                v-model="customer"
                                :auto-fill-address="true"
                                @input="onCustomerChanged"
                                :is-clearable="true"
                            />
                        </md-field>

                        <md-field class="status-filter">
                            <vue-select
                                v-if="statuses"
                                :options="statuses"
                                label="statusValue"
                                :reduce="(stat) => stat.statusId"
                                placeholder="Status"
                                v-model="statusFilterValue"
                                :searchable="$root.isDesktop"
                            ></vue-select>
                        </md-field>

                        <md-field>
                            <md-datepicker
                                class="filter-steps--choices filter-step-date start-date"
                                v-model="invoiceDateFrom"
                                md-immediately
                                :md-debounce="100"
                                ref="mdDatePicker"
                            ></md-datepicker>
                        </md-field>

                        <md-field>
                            <md-datepicker
                                class="filter-steps--choices filter-step-date end-date"
                                v-model="invoiceDateTo"
                                md-immediately
                                :md-debounce="100"
                                ref="mdDatePicker"
                            ></md-datepicker>
                        </md-field>

                        <md-field
                            class="status-filter batch-filter"
                            v-if="!isReadOnlyUser && batchFilterList != null && batchFilterList.length > 0"
                        >
                            <vue-select
                                :options="batchFilterList"
                                label="label"
                                :reduce="(x) => x.id"
                                placeholder="Batch Filter"
                                v-model="batchFilterValue"
                                :searchable="$root.isDesktop"
                                :clearable="true"
                            ></vue-select>
                        </md-field>
                    </div>
                </div>
                <div class="custom-toolbar-end">
                    <search-component ref="keyword" @onSearch="handleKeyword" placeholder="Search" />

                    <md-button
                        v-if="!isReadOnlyUser"
                        title="Generate Invoices"
                        class="md-primary md-just-icon md-round pull-right header-button"
                        @click="createInvoice"
                    >
                        <md-icon>library_add</md-icon>
                    </md-button>

                    <div>
                        <md-button
                            title="Export Invoices"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click="exportInvoices"
                        >
                            <md-icon>get_app</md-icon>
                        </md-button>
                    </div>

                    <!-- <div>
                        <md-button
                            title="Send data that matches the filter to Accounting Package"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click="sendInvoicesToAccountingPackage"
                        >
                            <md-icon>sync</md-icon>
                        </md-button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins';
import { mapGetters } from 'vuex';
import { PAYMENT_STATUS_CONSTANTS } from '@/utils/constants';
import _ from 'lodash';
import { SearchComponent, CustomerAutocomplete } from '@/components';
import CreateInvoicesModal from './CreateInvoicesModal';

export default {
    name: 'CustomerInvoicesOverviewToolbar',
    components: { SearchComponent, CustomerAutocomplete },
    mixins: [GeneralMixin],
    props: {
        batchFilterList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            statuses: this.getStatuses([...PAYMENT_STATUS_CONSTANTS, 'All']),
            statusFilterValue: '',
            keywordFilterValue: '',
            invoiceDateFrom: null,
            invoiceDateTo: null,
            customer: {
                customerId: undefined,
                contact: {
                    name: ''
                }
            },
            batchFilterValue: ''
        };
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    mounted() {
        if (!this.$root.isDesktop) {
            if (this.$refs.mdDatePicker) 
                this.$refs.mdDatePicker.$refs.input.$el.setAttribute('readonly', true);
        }
    },
    watch: {
        statusFilterValue(newValue, oldValue) {
            this.handleFilter();
        },
        keywordFilterValue(newValue, oldValue) {
            this.handleFilter();
        },
        invoiceDateFrom(newValue, oldValue) {
            this.handleFilter();
        },
        invoiceDateTo(newValue, oldValue) {
            this.handleFilter();
        },
        batchFilterValue() {
            this.handleFilter();
        }
    },
    methods: {
        async sendInvoicesToAccountingPackage() {
            this.emitEvent('onSendToAccountingPackageClicked');
        },
        getStatuses(arr) {
            const stats = arr.map((x) => ({
                statusId: x,
                statusValue: this.$t(`inputs.paymentStatuses.${x}`)
            }));

            return stats;
        },
        handleClear() {
            this.customer = {
                customerId: undefined,
                contact: {
                    name: ''
                }
            };
            this.handleFilter();
        },
        // eslint-disable-next-line func-names
        handleFilter: _.debounce(function() {
            this.handleFilteredValue();
        }, 700),
        emitEvent(evt) {
            // use nextTick to ensure that invoiceDate value has already been changed.
            // this is because the @selected method of md-datepicker is asynchronous.
            this.$nextTick(() => {
                const { keywordFilterValue, invoiceDateFrom, invoiceDateTo } = this;

                let { statusFilterValue } = this;

                let dateFrom = '';
                if (invoiceDateFrom) {
                    dateFrom = this.$options.filters.dateFormat(invoiceDateFrom, this.DATE_TYPES.internationalDate);
                }

                let dateTo = '';
                if (invoiceDateTo) {
                    dateTo = this.$options.filters.dateFormat(invoiceDateTo, this.DATE_TYPES.internationalDate);
                }

                if (statusFilterValue === 'All') 
                    statusFilterValue = null;

                this.$emit(evt, {
                    paymentStatus: statusFilterValue,
                    invoiceNumber: keywordFilterValue,
                    customerId: this.customer.customerId,
                    invoiceDateFrom: dateFrom,
                    invoiceDateTo: dateTo
                });
            });
        },
        onCustomerChanged(val) {
            this.handleFilter();
        },
        handleFilteredValue() {
            this.emitEvent('onFilterInvoices');
        },
        handleKeyword(data) {
            this.keywordFilterValue = data.searchText;
        },
        async exportInvoices() {
            this.emitEvent('onExportClicked');
        },
        async createInvoice() {
            this.$modal.show(CreateInvoicesModal, {}).then((response) => {
                if (response) {
                    this.$emit('onCreateInvoice');
                    this.$modal.hide();
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-steps--container {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }

    .filter-step-date {
        min-width: 250px;
        margin-right: 0;
        min-height: initial;
        position: absolute;
        z-index: 1;
    }

    .filter-button--container {
        display: inline-block;
    }

    ::v-deep .md-datepicker:after,
    ::v-deep .md-datepicker:before {
        bottom: 0;
        height: 0;
    }

    ::v-deep .md-datepicker:before,
    ::v-deep .md-datepicker:after {
        bottom: 30px;
        content: 'Invoice date';
        color: #aaaaaa;
        font-weight: 400;
        font-size: 14px;
        transform: none;
        z-index: 1;
    }

    .start-date:before,
    .start-date:after {
        content: 'Start Date';
    }

    .end-date:before,
    .end-date:after {
        content: 'End Date';
    }

    .start-date.md-has-value:before,
    .start-date.md-has-value:after,
    .end-date.md-has-value:before,
    .end-date.md-has-value:after {
        bottom: 0;
        height: 0;
        content: '';
    }

    ::v-deep .md-datepicker .md-input {
        position: absolute;
        z-index: 9;
        width: 150px;
    }

    ::v-deep .md-datepicker .md-input-action {
        z-index: 99;
        right: 52px;
    }

    .customer-filter {
        width: 300px;
        padding-bottom: 0px;
    }
}
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}
::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}
::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}

::v-deep .vs__search {
    cursor: pointer;
}

.datepicker-noevent {
    ::v-deep .md-datepicker {
        input {
            pointer-events: none;
        }
    }
}

::v-deep .filter-member-name .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    display: block;
}

.status-filter {
    width: 150px !important;
}

.custom-toolbar {
    align-items: start;
    margin-top: 1px;

    .custom-toolbar-start {
        flex: 3;
    }
}

::v-deep .customer-autocomplete {
    .md-field {
        width: 100%;
        .md-input {
            width: 100%;
        }
    }
}
.batch-filter {
    font-size: 12.5px;
    width: 250px !important;
}
</style>
