<template>
    <span v-if="type === 'Trip' && useIcon" @click.stop="handleGenerateLabel(id)">
        <md-icon>barcode_reader</md-icon>
        <md-tooltip md-direction="left">
            Download Labels
        </md-tooltip>
    </span>
    <div v-else class="generate-label">
        <md-button
            title="Download Labels"
            :class="['generate-label-btn', useIcon ? 'md-just-icon md-round' : '']"
            @click.stop="handleGenerateLabel(id)"
        >
            <md-icon v-if="useIcon">barcode_reader</md-icon>
            <span v-else>Download Labels</span>
        </md-button>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';

export default {
    name: 'GenerateLabelButton',
    props: {
        id: {
            type: [Number, String],
            default: () => null
        },
        useIcon: {
            type: Boolean,
            default: () => true
        },
        type: {
            type: String,
            default: 'Stop'
        }
    },
    mixins: [GeneralMixin],
    methods: {
        async handleGenerateLabel(id) {
            this.$_handleLoaderState(true);
            const api = `/api/${this.type}s/generate-label/${id}`;
            const payload = {
                method: 'post',
                responseType: 'arraybuffer'
            };

            try {
                const response = await handleRequests(api, payload);

                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL, '_blank');

                this.$_handleLoaderState(false);
                this.$emit('download-success');
            } catch (e) {
                let message = 'Error in download labels.';
                if (this.type === 'Trip') {
                    message = 'Item was not found.';
                }
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
                this.$emit('download-failed');
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.generate-label {
    display: inline;
}
.generate-label-btn,
.generate-label-btn:hover,
.generate-label-btn:focus {
    background-color: #2b93ff !important;
    margin-left: 0px;
}
</style>
