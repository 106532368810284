var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          { staticClass: "form-wrapper", attrs: { validator: _vm.$v } },
          [
            _c("services-autocomplete", {
              ref: "servicesRef",
              attrs: {
                label: "Service",
                id: "autoservice",
                placeholder: "",
                "should-focus": true,
                "is-required": true,
              },
              model: {
                value: _vm.serviceValue,
                callback: function ($$v) {
                  _vm.serviceValue = $$v
                },
                expression: "serviceValue",
              },
            }),
            _c(
              "form-group",
              {
                attrs: {
                  name: "description",
                  label: "Alternative Description / Notes (Optional)",
                  attribute: "Description",
                },
              },
              [
                _c("md-input", {
                  on: {
                    input: function ($event) {
                      return _vm.$v.description.$touch()
                    },
                  },
                  model: {
                    value: _vm.description,
                    callback: function ($$v) {
                      _vm.description = $$v
                    },
                    expression: "description",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "md-layout md-alignment-center-left" },
              [
                _c(
                  "form-group",
                  {
                    staticClass: "md-layout-item md-size-33 quantity",
                    attrs: {
                      name: "quantity",
                      label: "Quantity",
                      attribute: "Quantity",
                      messages: _vm.localMessages,
                    },
                  },
                  [
                    _c("md-input", {
                      attrs: { type: "number", min: "0", required: "" },
                      on: {
                        input: function ($event) {
                          return _vm.$v.quantity.$touch()
                        },
                      },
                      model: {
                        value: _vm.quantity,
                        callback: function ($$v) {
                          _vm.quantity = $$v
                        },
                        expression: "quantity",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "md-layout-item quantity-type" }, [
                  _vm._v(_vm._s(_vm.serviceValue.quantityType)),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            on: { click: _vm.addServiceLine },
          },
          [_vm._v("\n            Save\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Add a Line")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }