var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "information-box" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-25 md-small-size-100" },
        [
          _c(
            "form-group",
            {
              attrs: {
                label: "Team Member Invoice Prefix",
                name: "settings.invoiceAffixSettings.teamMemberInvoiceNumberPrefix",
              },
            },
            [
              _c("md-input", {
                model: {
                  value:
                    _vm.settings.invoiceAffixSettings
                      .teamMemberInvoiceNumberPrefix,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.settings.invoiceAffixSettings,
                      "teamMemberInvoiceNumberPrefix",
                      $$v
                    )
                  },
                  expression:
                    "settings.invoiceAffixSettings.teamMemberInvoiceNumberPrefix",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-25 md-small-size-100" },
        [
          _c(
            "form-group",
            {
              attrs: {
                label: "Team Member Invoice Suffix",
                name: "settings.invoiceAffixSettings.teamMemberInvoiceNumberSuffix",
              },
            },
            [
              _c("md-input", {
                model: {
                  value:
                    _vm.settings.invoiceAffixSettings
                      .teamMemberInvoiceNumberSuffix,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.settings.invoiceAffixSettings,
                      "teamMemberInvoiceNumberSuffix",
                      $$v
                    )
                  },
                  expression:
                    "settings.invoiceAffixSettings.teamMemberInvoiceNumberSuffix",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-25 md-small-size-100" },
        [
          _c(
            "form-group",
            {
              staticClass: "sample-invoice",
              attrs: { label: "Team Member Invoice Example" },
            },
            [
              _c("md-input", {
                attrs: {
                  disabled: "",
                  value: _vm.exampleTeamMemberInvoiceNumber,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-25 md-small-size-100" },
        [
          _c(
            "md-checkbox",
            {
              model: {
                value: _vm.settings.sendAsPaymentSummary,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "sendAsPaymentSummary", $$v)
                },
                expression: "settings.sendAsPaymentSummary",
              },
            },
            [
              _vm._v(
                "\n                Send as payment Summary\n                "
              ),
              _c("md-tooltip", [
                _vm._v(
                  "\n                    This will send a payment summary to team members rather than an RCTI tax invoice.\n                "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-25 md-small-size-100" },
        [
          _c(
            "form-group",
            {
              attrs: {
                label: "Customer Invoice Prefix",
                name: "settings.invoiceAffixSettings.customerInvoiceNumberPrefix",
              },
            },
            [
              _c("md-input", {
                model: {
                  value:
                    _vm.settings.invoiceAffixSettings
                      .customerInvoiceNumberPrefix,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.settings.invoiceAffixSettings,
                      "customerInvoiceNumberPrefix",
                      $$v
                    )
                  },
                  expression:
                    "settings.invoiceAffixSettings.customerInvoiceNumberPrefix",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-25 md-small-size-100" },
        [
          _c(
            "form-group",
            {
              attrs: {
                label: "Customer Invoice Suffix",
                name: "settings.invoiceAffixSettings.customerInvoiceNumberSuffix",
              },
            },
            [
              _c("md-input", {
                model: {
                  value:
                    _vm.settings.invoiceAffixSettings
                      .customerInvoiceNumberSuffix,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.settings.invoiceAffixSettings,
                      "customerInvoiceNumberSuffix",
                      $$v
                    )
                  },
                  expression:
                    "settings.invoiceAffixSettings.customerInvoiceNumberSuffix",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-25 md-small-size-100" },
        [
          _c(
            "form-group",
            {
              staticClass: "sample-invoice",
              attrs: { label: "Customer Invoice Example" },
            },
            [
              _c("md-input", {
                attrs: {
                  disabled: "",
                  value: _vm.exampleCustomerInvoiceNumber,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }