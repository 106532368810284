var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-autocomplete" },
    [
      _c(
        "form-group",
        { attrs: { name: "name", label: _vm.label } },
        [
          _c("md-input", {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.handleClickOutside,
                expression: "handleClickOutside",
              },
            ],
            ref: "customerAutocomplete",
            attrs: {
              type: "text",
              disabled: _vm.isCustomerAdmin || _vm.disabled,
            },
            on: {
              focus: function ($event) {
                return _vm.onFocus($event)
              },
              blur: _vm.blur,
              change: _vm.onChange,
              keyup: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  ) {
                    return null
                  }
                  return _vm.onArrowDown($event)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  ) {
                    return null
                  }
                  return _vm.onArrowUp($event)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onEnter($event)
                },
              ],
            },
            model: {
              value: _vm.autocompleteText,
              callback: function ($$v) {
                _vm.autocompleteText = $$v
              },
              expression: "autocompleteText",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "autocomplete" }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen && _vm.autocompleteList.length,
                expression: "isOpen && autocompleteList.length",
              },
            ],
            ref: "autocompleteContainer",
            staticClass: "autocomplete-results",
            attrs: { id: "autocomplete-results" },
          },
          [
            _vm.isAutoCompleteLoading
              ? _c("li", { staticClass: "loading" }, [
                  _vm._v("\n                Loading results...\n            "),
                ])
              : _vm._l(_vm.autocompleteList, function (result, i) {
                  return _c(
                    "li",
                    {
                      key: result.customerId,
                      ref: "autocompleteOptions",
                      refInFor: true,
                      staticClass: "autocomplete-result",
                      class: { "is-active": i === _vm.arrowCounter },
                      on: {
                        click: function ($event) {
                          return _vm.setAutocompleteResult(result)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(result.name) +
                          "\n            "
                      ),
                    ]
                  )
                }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }