var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "users-autocomplete" },
    [
      _c(
        "form-wrapper",
        { staticClass: "form-wrapper", attrs: { validator: _vm.$v } },
        [
          _c(
            "form-group",
            {
              attrs: {
                name: "selectedAutocomplete",
                label: _vm.label,
                attribute: "User Name",
              },
            },
            [
              _c("md-input", {
                directives: [
                  {
                    name: "focus",
                    rawName: "v-focus",
                    value: _vm.shouldFocus,
                    expression: "shouldFocus",
                  },
                ],
                ref: "usersAutocomplete",
                attrs: { type: "text", required: _vm.isRequired },
                on: {
                  focus: function ($event) {
                    return _vm.onFocus($event)
                  },
                  focusout: function ($event) {
                    return _vm.onFocusout($event)
                  },
                  blur: _vm.blur,
                  change: _vm.onChange,
                  keyup: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      ) {
                        return null
                      }
                      return _vm.onArrowDown($event)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ])
                      ) {
                        return null
                      }
                      return _vm.onArrowUp($event)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onEnter($event)
                    },
                  ],
                },
                model: {
                  value: _vm.autocompleteText,
                  callback: function ($$v) {
                    _vm.autocompleteText = $$v
                  },
                  expression: "autocompleteText",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "autocomplete" }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen && _vm.autocompleteList.length,
                expression: "isOpen && autocompleteList.length",
              },
            ],
            ref: "autocompleteContainer",
            staticClass: "autocomplete-results",
            attrs: { id: "autocomplete-results" },
          },
          [
            _vm.isAutoCompleteLoading
              ? _c("li", { staticClass: "loading" }, [
                  _vm._v("\n                Loading results...\n            "),
                ])
              : _vm._l(_vm.autocompleteList, function (result, i) {
                  return _c(
                    "li",
                    {
                      key: result.publicUserId,
                      ref: "autocompleteOptions",
                      refInFor: true,
                      staticClass: "autocomplete-result",
                      class: { "is-active": i === _vm.arrowCounter },
                      on: {
                        click: function ($event) {
                          return _vm.setAutocompleteResult(result)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(result.fullName) +
                          " ( " +
                          _vm._s(result.email) +
                          " )\n            "
                      ),
                    ]
                  )
                }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }