<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
            <div class="card-icon">
                <md-icon>description</md-icon>
            </div>
            <!-- <div class="lock-rs" @click="lockRunSchedule(runDetails.isLockedTrip)">
                <md-button class="md-primary">
                    <md-icon v-if="!runDetails.isLockedTrip">lock</md-icon>
                    <md-icon v-else>lock_open</md-icon>
                    <span v-if="!runDetails.isLockedTrip">Lock run schedule</span>
                    <span v-else>Unlock run schedule</span>
                </md-button>
            </div> -->
        </md-card-header>

        <md-card-content>
            <md-table class="custom-paginated-table">
                <div class="alert-section" v-if="showAlert">
                    <div class="alert alert-warning alert-dismissible fade show" role="alert">
                        <div>
                            <div>
                                <strong>ATTENTION!</strong>
                                Please click the save button in order to save the changes made.
                            </div>
                        </div>
                        <div>
                            <md-button
                                title="Save new order of stops"
                                class="md-primary md-just-icon md-round"
                                @click.stop="saveRunStopOrder()"
                            >
                                <md-icon>save</md-icon>
                            </md-button>
                        </div>
                    </div>
                </div>
                <draggable
                    v-model="runDetails.stops"
                    draggable=".drag-item"
                    class="custom-draggable show-draggable"
                    @change="stopOrderChanged"
                >
                    <md-table-row slot="header">
                        <md-table-head class="drag-icon">
                            <span
                                class="gold-lock"
                                v-if="runDetails.isLockedTrip"
                                @click="lockRunSchedule(runDetails.isLockedTrip)"
                            >
                                <md-icon>lock</md-icon>
                                <md-tooltip md-direction="right">
                                    Locked run schedules will generate as a locked trip and the order of the
                                    stops/shipments will be retained.
                                </md-tooltip>
                            </span>
                            <span class="grey-lock" v-else @click="lockRunSchedule(runDetails.isLockedTrip)">
                                <md-icon>lock_open</md-icon>
                                <md-tooltip md-direction="right">
                                    Unlocked run schedules will generate as unlocked trips and may optimise to a
                                    different order then what is visible below.
                                </md-tooltip>
                            </span>
                        </md-table-head>
                        <md-table-head class="drag-icon"></md-table-head>
                        <md-table-head class="stop-order" v-if="runDetails.isLockedTrip">Planned</md-table-head>
                        <md-table-head class="stop-address">Address</md-table-head>
                        <md-table-head>Type</md-table-head>
                        <md-table-head class="stop-contact">Contact</md-table-head>
                        <md-table-head class="stop-actions">Actions</md-table-head>
                    </md-table-row>

                    <md-table-row
                        v-for="(stopItem, stopIndex) in runDetails.stops"
                        :key="stopIndex"
                        class="drag-item"
                        :class="stopItem.isStartStop || stopItem.isEndStop ? 'startend-table-row' : ''"
                    >
                        <md-table-cell class="drag-icon">
                            <md-icon>drag_indicator</md-icon>
                        </md-table-cell>
                        <md-table-cell class="drag-icon">
                            <md-icon v-if="stopItem.shipmentId != null && stopItem.runScheduleShipmentId == null">
                                drive_eta
                            </md-icon>
                            <md-icon v-if="stopItem.runScheduleShipmentId != null">drive_eta</md-icon>
                        </md-table-cell>
                        <md-table-cell v-if="runDetails.isLockedTrip" class="stop-order">
                            {{ stopItem.order }}
                        </md-table-cell>
                        <md-table-cell class="stop-address">
                            <span class="custom-ellipsis">
                                <span class="shipment-ref" v-if="stopItem.shipmentCount">
                                    ({{ stopItem.shipmentCount }})
                                </span>
                                {{ $_displayAddressName(stopItem) }}
                            </span>
                        </md-table-cell>
                        <md-table-cell>
                            {{ stopItem.type }}
                        </md-table-cell>
                        <md-table-cell class="stop-contact">
                            <span class="custom-ellipsis">
                                {{ stopItem.contact != null ? stopItem.contact.name : '' }}
                            </span>
                        </md-table-cell>
                        <md-table-cell class="action-buttons">
                            <md-button
                                title="Edit stop details"
                                class="md-button md-primary md-just-icon md-round btn-size-27"
                                @click.stop="updateRunStop(stopItem)"
                            >
                                <md-icon>edit</md-icon>
                            </md-button>
                            <md-button
                                title="Delete stop"
                                class="md-danger md-just-icon md-round btn-size-27"
                                @click.stop="handleDeleteRunStop(stopItem)"
                            >
                                <md-icon>delete</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </draggable>
            </md-table>
        </md-card-content>
    </md-card>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { RUN_SCHEDULE_RUN_STOP_UPDATE_OPERATION } from '@/utils/constants';
import CreateRunStopModal from './CreateRunStopModal';
import CreateRunShipmentModal from './CreateRunShipmentModal';

export default {
    name: 'StopListTable',
    mixins: [GeneralMixin],
    props: {
        runDetails: {
            type: Object,
            default: () => {}
        },
        verifyPendingTripExistsOnRunSchedule: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            showAlert: false,
            runOperation: RUN_SCHEDULE_RUN_STOP_UPDATE_OPERATION
        };
    },
    updated() {
        const elements = document.getElementsByClassName('drag-item');
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            const shipmentId = element.getAttribute('data-shipmentId');
            if (shipmentId) {
                // eslint-disable-next-line func-names
                element.addEventListener('mouseenter', function() {
                    const pairs = document.querySelectorAll(`[data-shipmentid="${shipmentId}"]`);
                    pairs.forEach((shipmentPair) => {
                        shipmentPair.style.backgroundColor = '#eee';
                    });
                });
                element.addEventListener('mouseleave', () => {
                    for (let j = 0; j < elements.length; j++) {
                        elements[j].style.backgroundColor = 'transparent';
                    }
                });
            }
        }
    },
    methods: {
        async getRunShipmentDetails(runStopDetail) {
            try {
                this.$_handleLoaderState(true, 'LOADING...');
                const api = `/api/run-schedules/${runStopDetail.runScheduleId}/run-shipments/${
                    runStopDetail.runScheduleShipmentId
                }`;
                const { data } = await handleRequests(api);
                this.$_handleLoaderState(false);
                return data;
            } catch (error) {
                this.$_handleLoaderState(false);
                const message = 'Error in loading the run shipment details';
                showErrorMessage(this, message, error);
            }
            return null;
        },
        async updateRunStop(runStopDetail) {
            if (runStopDetail.runScheduleShipmentId == null) {
                this.$modal
                    .show(CreateRunStopModal, {
                        runStopDetails: runStopDetail,
                        verifyPendingTripExistsOnRunSchedule: this.verifyPendingTripExistsOnRunSchedule,
                        runScheduleCustomerId: this.runDetails.customerId,
                        isUpdate: true
                    })
                    .then((response) => {
                        this.$emit('runStopUpdated', response);
                        this.$modal.hide();
                    });
            } else {
                const runShipmentData = await this.getRunShipmentDetails(runStopDetail);
                this.$modal
                    .show(CreateRunShipmentModal, {
                        runShipmentDetails: runShipmentData,
                        isUpdate: true
                    })
                    .then((response) => {
                        this.$emit('runStopUpdated', response);
                        this.$modal.hide();
                    });
            }
        },
        async handleDeleteRunStop(runStopDetail) {
            let message = 'Are you sure you want to delete this stop?';
            if (runStopDetail.shipmentId != null || runStopDetail.runScheduleShipmentId != null) {
                message =
                    'This stop is a shipment stop. Deleting this stop will also delete its partner stop. Are you sure you want to delete this stop?';
            }
            const { runOperation } = this;
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Stop',
                    body: message,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        const onSuccess = this.handleConfirmDelete.bind(
                            this,
                            runStopDetail.runScheduleId,
                            runStopDetail.runScheduleStopId
                        );
                        await this.verifyPendingTripExistsOnRunSchedule(
                            runOperation.Delete,
                            runStopDetail.runScheduleId,
                            onSuccess
                        );
                    }
                });
        },
        async handleConfirmDelete(runScheduleId, runScheduleStopId) {
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'delete'
            };
            const api = `/api/run-schedules/${runScheduleId}/run-stops/${runScheduleStopId}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Stop was deleted!',
                    type: 'success'
                });
                this.$emit('deleteRunStop', true);
            } catch (e) {
                const message = 'Error in deleting a stop.';
                showErrorMessage(this, message, e);
                this.$emit('deleteRunStop', false);
            }
        },
        stopOrderChanged() {
            this.showAlert = true;
            this.$forceUpdate();
        },
        async saveRunStopOrder(isLockTrip = false) {
            const obj = [];

            this.runDetails.stops.forEach((x, i) => {
                x.order = i + 1;

                obj.push({
                    runScheduleStopId: x.runScheduleStopId,
                    order: i + 1
                });
            });

            this.$_handleLoaderState(true, 'SAVING...');

            try {
                const endpoint = `/api/run-schedules/${this.runDetails.runScheduleId}/stop/reorder`;
                const payload = {
                    method: 'post',
                    data: {
                        RunStops: obj
                    }
                };
                await handleRequests(endpoint, payload);

                let message = 'Successfully reordered stops. Changes will be applied to future generated trips.';
                if (isLockTrip) {
                    message = 'Successfully locked the run. Changes will be applied to future generated trips.';
                }

                this.showAlert = false;
                this.$notify({
                    message,
                    type: 'success'
                });
            } catch (error) {
                let message = 'Error in reordering of stops.';
                if (isLockTrip) {
                    message = 'Error in locking the run schedule';
                }
                showErrorMessage(this, message, error);
            }

            if (!isLockTrip) {
                this.$emit('deleteRunStop', true);
            }
            this.$_handleLoaderState(false);
        },
        async lockRunSchedule(isLockedTrip) {
            if (isLockedTrip) {
                this.$_handleLoaderState(true, 'SAVING...');

                try {
                    const endpoint = `/api/run-schedules/${this.runDetails.runScheduleId}/unlock`;
                    const payload = {
                        method: 'post'
                    };
                    await handleRequests(endpoint, payload);

                    this.showAlert = false;
                    this.$notify({
                        message: 'Successfully unlocked the run. Changes will be applied to future generated trips.',
                        type: 'success'
                    });
                } catch (error) {
                    const message = 'Error in unlocking the run schedule';
                    showErrorMessage(this, message, error);
                }

                this.$_handleLoaderState(false);
            } else {
                await this.saveRunStopOrder(true);
            }

            this.$emit('deleteRunStop', true);
        }
    }
};
</script>

<style lang="scss" scoped>
.stop-address,
.stop-contact,
.stop-notes {
    max-width: 200px;
}

.status {
    color: #fff !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 90px;
}

.custom-paginated-table {
    > ::v-deep div {
        overflow: auto;
        max-height: 400px;
    }
    ::v-deep th {
        position: sticky;
        top: 0;
    }
}

.startend-table-row {
    background-color: #fafafa;
}

@media (min-width: 1904px) {
    .custom-paginated-table {
        > ::v-deep div {
            overflow: auto;
            max-height: 600px;
        }
    }
}

.custom-draggable {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.drag-item {
    cursor: grab;
}

.show-draggable {
    tr > .drag-icon {
        width: 40px;
    }
}

.stop-actions {
    width: 200px;
}

.alert-section {
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 1;
    > div {
        padding: 8px 20px;
        > div {
            display: inline-block;
            vertical-align: middle;
            button {
                height: 30px;
                min-width: 30px;
                width: 30px;
            }
        }
    }
}

.lock-rs {
    display: inline-block;
    float: right;

    i:hover {
        cursor: pointer;
    }

    .md-button {
        margin-top: 5px;
    }
}

.stop-order {
    width: 100px;

    ::v-deep .md-table-cell-container {
        padding-left: 10px;
    }
}

.gold-lock {
    margin-right: 10px;
    .md-icon {
        color: #daa520;
    }
}

.grey-lock {
    margin-right: 10px;
    .md-icon {
        color: #a9a9a9;
    }
}

.gold-lock:hover,
.grey-lock:hover {
    cursor: pointer;
}

.shipment-ref {
    font-weight: 500;
}
</style>
