var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c(
          "h4",
          {
            staticClass: "modal-title",
            attrs: { id: "carrierQuestionsMappingModalTitle" },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.carrierName + " Questions Mapping") +
                "\n        "
            ),
          ]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "modal-body stop-form-modal" }, [
      _c(
        "div",
        [
          _c("CarrierQuestions", {
            ref: "questionMapping",
            attrs: {
              "questions-mapping": _vm.questionsMapping,
              "booking-questions": _vm.bookingQuestions,
              "carrier-teamid": _vm.carrierTeamid,
            },
            on: { isInvalid: _vm.validationInvalid },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _vm.hasQuestionnaires
          ? _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary",
                on: { click: _vm.updateQuestionsMapping },
              },
              [_vm._v("\n            Update\n        ")]
            )
          : _vm._e(),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }