var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.contentsList, function (content, ndx) {
      return _c("div", { key: "content-" + ndx, staticClass: "detail-group" }, [
        _c("label", { staticClass: "label" }, [
          _vm._v(_vm._s(content.text) + ":"),
        ]),
        _c("span", { staticClass: "value" }, [
          _vm._v("\n            " + _vm._s(content.value) + "\n        "),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }