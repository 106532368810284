<template>
    <div class="cf-container">
        <div class="alert-section">
            <div class="alert alert-warning alert-dismissible fade show" role="alert" v-if="showAlert">
                <strong>ATTENTION!</strong>
                Please click the save button on the upper right to save the changes made.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="closeAlert">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="cf-container" v-if="!proofOfDeliveryTemplate.isDefault">
            <form-group label="Assigned Customers" class="assigned-customers">
                <customer-chips-autocomplete
                    :customers="proofOfDeliveryTemplate.assignedCustomers"
                    @select="onAssignCustomer"
                    @delete="onUnassignCustomer"
                />
            </form-group>
        </div>
        <md-table class="custom-paginated-table">
            <draggable v-model="proofOfDeliveryTemplate.configuration" draggable=".drag-item" class="custom-draggable">
                <md-table-row slot="header">
                    <md-table-head></md-table-head>
                    <md-table-head>Type</md-table-head>
                    <md-table-head>Stops</md-table-head>
                    <md-table-head>Shipment Pickup</md-table-head>
                    <md-table-head>Shipment Drop Off</md-table-head>
                    <md-table-head v-if="!isReadOnlyUser && !isSingleUser">Include in PDF</md-table-head>
                </md-table-row>

                <md-table-row
                    v-for="(setting, index) in proofOfDeliveryTemplate.configuration"
                    :key="index"
                    class="drag-item"
                >
                    <md-table-cell>
                        <md-icon>drag_indicator</md-icon>
                    </md-table-cell>
                    <md-table-cell>
                        {{ $t('podTypes.' + setting.type.toLowerCase()) }}
                    </md-table-cell>
                    <md-table-cell>
                        <form-group class="pod-dropdown">
                            <md-select v-model="setting.standardStops" @md-selected="updateConfiguration">
                                <md-option v-for="option in stopAndShipmentsOptions" :key="option" :value="option">
                                    {{ option }}
                                </md-option>
                            </md-select>
                        </form-group>
                    </md-table-cell>

                    <md-table-cell>
                        <form-group class="pod-dropdown">
                            <md-select v-model="setting.shipmentPickupStops" @md-selected="updateConfiguration">
                                <md-option v-for="option in stopAndShipmentsOptions" :key="option" :value="option">
                                    {{ option }}
                                </md-option>
                            </md-select>
                        </form-group>
                    </md-table-cell>

                    <md-table-cell>
                        <form-group class="pod-dropdown">
                            <md-select v-model="setting.shipmentDropStops" @md-selected="updateConfiguration">
                                <md-option v-for="option in stopAndShipmentsOptions" :key="option" :value="option">
                                    {{ option }}
                                </md-option>
                            </md-select>
                        </form-group>
                    </md-table-cell>

                    <md-table-cell v-if="!isReadOnlyUser && !isSingleUser">
                        <form-group class="pod-checkbox">
                            <md-checkbox v-model="setting.includeInPodPdf"></md-checkbox>
                        </form-group>
                    </md-table-cell>
                </md-table-row>
            </draggable>
        </md-table>
        <div class="text-center" v-if="proofOfDeliveryTemplate.configuration.length == 0">
            <p class="no-result-message">This setting is currently not yet available.</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CustomerChipsAutocomplete } from '@/components';

export default {
    name: 'ProofOfDeliveryTemplate',
    components: { CustomerChipsAutocomplete },
    props: {
        proofOfDeliveryTemplate: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            chipData: {
                selectedCustomerName: ''
            },
            showAlert: false,
            stopAndShipmentsOptions: ['Always', 'Optional', 'Never']
        };
    },
    computed: {
        includeInPodPdfValues() {
            if (this.proofOfDeliveryTemplate && this.proofOfDeliveryTemplate.configuration) {
                return this.proofOfDeliveryTemplate.configuration.map((configuration) => configuration.includeInPodPdf);
            }

            return [];
        },
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            isSingleUser: 'user/isIndividualUser'
        })
    },
    methods: {
        async setDefaultConfiguration(podConfiguration) {
            let types = ['Signature', 'Photos', 'Barcodes', 'Notes', 'OneTimePin'];

            if (podConfiguration) {
                types = [];
                podConfiguration.forEach((type) => {
                    types.push(type.type);
                });
            }

            this.proofOfDeliveryTemplate.configuration = [];

            types.forEach((type) => {
                const existingConfiguration =
                    podConfiguration !== null ? podConfiguration.find((x) => x.type === type) : null;
                if (existingConfiguration) {
                    this.proofOfDeliveryTemplate.configuration.push(existingConfiguration);
                } else {
                    this.proofOfDeliveryTemplate.configuration.push({
                        type,
                        standardStops: 'Never',
                        shipmentPickupStops: 'Never',
                        shipmentDropStops: 'Never',
                        includeInPodPdf: false
                    });
                }
            });

            this.$watch('includeInPodPdfValues', (newVal, oldVal) => {
                this.updateConfiguration(true);
            });
        },
        updateConfiguration(checkboxClicked = false) {
            let showAlert = true;

            this.proofOfDeliveryTemplate.configuration.forEach((s) => {
                if (s.standardStops === 'Never' && s.includeInPodPdf) {
                    // The below will re-trigger the `updateConfiguration()` method due to the watcher but it will be fast...
                    s.includeInPodPdf = false;

                    if (checkboxClicked) {
                        showAlert = false;
                    }
                }
            });

            this.showAlert = showAlert;
        },
        closeAlert() {
            this.showAlert = false;
        },
        onAssignCustomer(customer) {
            this.proofOfDeliveryTemplate.assignedCustomers.push(customer);
        },
        onUnassignCustomer(customer, index) {
            this.proofOfDeliveryTemplate.assignedCustomers.splice(index, 1);
        }
    }
};
</script>
<style lang="scss" scoped>
.actions {
    width: 6%;
}
.pod-dropdown {
    width: 100px;
    margin-top: 0;
    border: 1px solid #ddd;
    padding: 0;
    padding-left: 10px;
}

.pod-checkbox {
    width: 100px;
    margin-top: 0;
    padding: 0;
    padding-left: 10px;
}
.pod-checkbox:after,
.pod-checkbox:before {
    height: 0;
}

.pod-dropdown:after,
.pod-dropdown:before {
    height: 0;
}

.alert-section {
    padding-top: 10px;
}

.custom-draggable {
    display: table;
    width: 100%;
    tr > td:first-child {
        width: 45px;
    }
    tr {
        cursor: move;
    }
    tr:first-child:hover {
        cursor: context-menu;
        background-color: #fff;
    }
}
.assigned-customers {
    margin-top: 0 !important;
}
</style>
