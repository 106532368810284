var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100",
    },
    [
      _c(
        "md-card",
        [
          _c(
            "md-card-header",
            { staticClass: "md-card-header-icon md-card-header-rose" },
            [
              _c(
                "div",
                { staticClass: "card-icon custom-header-icon-text" },
                [
                  _c("md-icon", [_vm._v("notes")]),
                  _vm._v("\n                Possession History\n            "),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filter-steps--container" },
                [
                  _c(
                    "md-field",
                    { staticClass: "datepicker-container" },
                    [
                      _c("md-datepicker", {
                        staticClass: "filter-step-date",
                        attrs: { "md-immediately": "", "md-debounce": 100 },
                        model: {
                          value: _vm.dateFilter,
                          callback: function ($$v) {
                            _vm.dateFilter = $$v
                          },
                          expression: "dateFilter",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("md-card-content", [
            !_vm.isLoading
              ? _c(
                  "div",
                  [
                    _vm.logs && _vm.logs.ownerHistory.length
                      ? _c("md-table", {
                          attrs: { "table-header-color": "green" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "md-table-row",
                                fn: function (ref) {
                                  var item = ref.item
                                  return _c(
                                    "md-table-row",
                                    {},
                                    [
                                      _c(
                                        "md-table-cell",
                                        {
                                          attrs: { "md-label": "Team Member" },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "custom-ellipsis",
                                              attrs: {
                                                href:
                                                  "/team/team-members/" +
                                                  item.publicUserId,
                                                title: "reassign stop",
                                              },
                                            },
                                            [
                                              item.ownerName
                                                ? _c("img", {
                                                    staticClass:
                                                      "profile-image",
                                                    attrs: {
                                                      src:
                                                        item.photoUrl !== null
                                                          ? item.photoUrl
                                                          : _vm.$root
                                                              .defaultPhotoUrl,
                                                      alt: "avatar",
                                                    },
                                                    on: {
                                                      error:
                                                        _vm.$_setDefaultBrokenImage,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(item.ownerName) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "md-table-cell",
                                        {
                                          class: {
                                            "manual-actioned":
                                              item.checkedOutByUserFullName !==
                                              null,
                                          },
                                          attrs: { "md-label": "Time Taken" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm._f("dateTimeFormat")(
                                                  item.checkOutDate,
                                                  _vm.DATE_TYPES.standardDate
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                          item.checkInNotes !== null
                                            ? _c(
                                                "md-tooltip",
                                                {
                                                  attrs: {
                                                    "md-direction": "left",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    '\n                                "' +
                                                      _vm._s(
                                                        item.checkOutNotes
                                                      ) +
                                                      '"\n                                '
                                                  ),
                                                  item.checkedOutByUserFullName !==
                                                  null
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                    Actioned by " +
                                                            _vm._s(
                                                              item.checkedOutByUserFullName
                                                            ) +
                                                            "\n                                "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "md-table-cell",
                                        {
                                          class: {
                                            "manual-actioned":
                                              item.checkedInByUserFullName !==
                                              null,
                                          },
                                          attrs: {
                                            "md-label": "Time Returned",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm._f("dateTimeFormat")(
                                                  item.checkInDate,
                                                  _vm.DATE_TYPES.standardDate
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                          item.checkInNotes !== null
                                            ? _c(
                                                "md-tooltip",
                                                {
                                                  attrs: {
                                                    "md-direction": "left",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    '\n                                "' +
                                                      _vm._s(
                                                        item.checkInNotes
                                                      ) +
                                                      '"\n                                '
                                                  ),
                                                  item.checkedInByUserFullName !==
                                                  null
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                    Actioned by " +
                                                            _vm._s(
                                                              item.checkedInByUserFullName
                                                            ) +
                                                            "\n                                "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            1893572701
                          ),
                          model: {
                            value: _vm.logs.ownerHistory,
                            callback: function ($$v) {
                              _vm.$set(_vm.logs, "ownerHistory", $$v)
                            },
                            expression: "logs.ownerHistory",
                          },
                        })
                      : _vm._e(),
                    !_vm.isLoading &&
                    _vm.logs &&
                    _vm.logs.ownerHistory.length === 0
                      ? _c("p", { staticClass: "grayedout-text" }, [
                          _vm._v(
                            "\n                    No possession history available\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm.logs && _vm.logs.ownerHistory.length
                      ? _c(
                          "md-card-actions",
                          {
                            staticClass: "page-footer",
                            attrs: { "md-alignment": "space-between" },
                          },
                          [
                            _c("div", [
                              _vm.total === _vm.pagination.perPage
                                ? _c("p", { staticClass: "card-category" }, [
                                    _vm._v(
                                      "\n                            Page " +
                                        _vm._s(_vm.pagination.currentPage) +
                                        " of many\n                        "
                                    ),
                                  ])
                                : _c("p", { staticClass: "card-category" }, [
                                    _vm._v(
                                      "Page " +
                                        _vm._s(_vm.pagination.currentPage) +
                                        " of " +
                                        _vm._s(_vm.totalPages)
                                    ),
                                  ]),
                            ]),
                            _c("pagination", {
                              staticClass:
                                "pagination-no-border pagination-success",
                              attrs: {
                                "per-page": _vm.pagination.perPage,
                                total: _vm.total,
                              },
                              on: {
                                "change-page": function ($event) {
                                  return _vm.handleChangePage(
                                    $event,
                                    _vm.pagination.perPage
                                  )
                                },
                              },
                              model: {
                                value: _vm.pagination.currentPage,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pagination, "currentPage", $$v)
                                },
                                expression: "pagination.currentPage",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _c("div", [
                  _c("div", { staticClass: "body-list" }, [
                    _c(
                      "div",
                      { staticClass: "list-loader" },
                      [
                        _c("fade-loader", {
                          attrs: { loading: _vm.isLoading, color: "#333333" },
                        }),
                        _c("span", [_vm._v("LOADING")]),
                      ],
                      1
                    ),
                  ]),
                ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }