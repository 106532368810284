var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showTripStopWindow
    ? _c(
        "div",
        { staticClass: "modal" },
        [
          _c(
            "md-content",
            { staticClass: "info-tooltip" },
            [
              _c("md-card", [
                _c("div", { staticClass: "modal-header" }, [
                  _vm.content.teamMember !== null
                    ? _c("h4", { staticClass: "modal-title" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.content.teamMember.fullName) +
                            " - Assigned Stops " +
                            _vm._s(_vm.getVehicleMaxLoad()) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                  _vm.content.teamMember === null
                    ? _c("h4", { staticClass: "modal-title" }, [
                        _vm._v("Unassigned Stops"),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "modal-body",
                    class:
                      _vm.content.stopList.length > 7
                        ? "modal-body-scroll"
                        : "",
                  },
                  [
                    _c(
                      "md-table",
                      {
                        staticClass: "context-menu-support unassignstop-table",
                      },
                      [
                        _c(
                          "md-table-row",
                          { staticClass: "stop-list-row" },
                          [
                            _c("md-table-head"),
                            _c(
                              "md-table-head",
                              { staticClass: "contact-header" },
                              [_vm._v("Contact")]
                            ),
                            _c(
                              "md-table-head",
                              { staticClass: "address-header" },
                              [_vm._v("Address")]
                            ),
                            _c("md-table-head", { staticClass: "eta-header" }, [
                              _vm._v("ETA"),
                            ]),
                            _vm.user.vehicleCapacityUnitsConfiguration !=
                              null &&
                            _vm.user.vehicleCapacityUnitsConfiguration.length >
                              0
                              ? _c(
                                  "md-table-head",
                                  { staticClass: "stop-load-header" },
                                  [
                                    _vm._v(
                                      "\n                            Load " +
                                        _vm._s(
                                          _vm.selectedCapacitySymbol
                                            ? "(" +
                                                _vm.selectedCapacitySymbol +
                                                ")"
                                            : ""
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.user.vehicleCapacityUnitsConfiguration !=
                              null &&
                            _vm.user.vehicleCapacityUnitsConfiguration.length >
                              0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-table-head running-load-head",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-table-head-container running-load-menu",
                                      },
                                      [
                                        _c(
                                          "drop-down",
                                          { attrs: { direction: "down" } },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "load-header-text",
                                                attrs: { href: "#" },
                                              },
                                              [
                                                _c(
                                                  "md-tooltip",
                                                  {
                                                    staticClass:
                                                      "toggle-tooltip",
                                                    attrs: {
                                                      "md-direction": "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.selectedViewType
                                                        ) +
                                                        " (" +
                                                        _vm._s(
                                                          _vm.selectedCapacityLabel
                                                        ) +
                                                        ")\n                                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(_vm.loadHeaderText) +
                                                    "\n                                        " +
                                                    _vm._s(
                                                      _vm.selectedCapacitySymbol
                                                        ? "(" +
                                                            _vm.selectedCapacitySymbol +
                                                            ")"
                                                        : ""
                                                    ) +
                                                    "\n                                        "
                                                ),
                                                _c(
                                                  "md-icon",
                                                  { staticClass: "icon-arrow" },
                                                  [_vm._v("expand_more")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "ul",
                                              { staticClass: "dropdown-menu" },
                                              [
                                                _vm._l(
                                                  _vm.user
                                                    .vehicleCapacityUnitsConfiguration,
                                                  function (
                                                    vehicleCapacity,
                                                    index
                                                  ) {
                                                    return _c(
                                                      "li",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            class: [
                                                              "icon-check",
                                                              _vm.content
                                                                .selectedCapacityType ===
                                                              vehicleCapacity.type
                                                                ? "icon-check-color"
                                                                : "",
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                check\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeCapacityType(
                                                                  vehicleCapacity.type
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  vehicleCapacity.label
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                _c("hr", {
                                                  staticClass: "border",
                                                }),
                                                _vm._l(
                                                  _vm.viewTypes,
                                                  function (
                                                    viewType,
                                                    viewindex
                                                  ) {
                                                    return _c(
                                                      "li",
                                                      { key: "v" + viewindex },
                                                      [
                                                        _c(
                                                          "md-icon",
                                                          {
                                                            class: [
                                                              "icon-check",
                                                              _vm.selectedViewType ===
                                                              viewType
                                                                ? "icon-check-color"
                                                                : "",
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                check\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeSelectViewType(
                                                                  viewType
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  viewType
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c("md-table-head"),
                          ],
                          1
                        ),
                        _vm._l(
                          _vm.content.stopList,
                          function (item, stopIndex) {
                            return _c(
                              "md-table-row",
                              {
                                key: item.stopId,
                                staticClass: "stop-list-row",
                                staticStyle: { cursor: "pointer" },
                              },
                              [
                                _c("md-table-cell", [
                                  item.stopRef
                                    ? _c("span", [_vm._v(_vm._s(stopIndex))])
                                    : _vm._e(),
                                ]),
                                _c(
                                  "md-table-cell",
                                  { staticClass: "contact-name-container" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "overflow-text-tooltip",
                                            rawName: "v-overflow-text-tooltip",
                                          },
                                        ],
                                        staticClass: "custom-ellipsis",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$_displayContactName(item)
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "md-table-cell",
                                  { staticClass: "address-container" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "overflow-text-tooltip",
                                            rawName: "v-overflow-text-tooltip",
                                          },
                                        ],
                                        staticClass: "custom-ellipsis",
                                      },
                                      [
                                        item.stopRef && item.stopId
                                          ? _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "Stop Details",
                                                    params: {
                                                      stopId: item.stopId,
                                                    },
                                                  },
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.$_displayAddressName(
                                                        item
                                                      )
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$_displayAddressName(item)
                                                )
                                              ),
                                            ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm.content.teamMember !== null
                                  ? _c(
                                      "md-table-cell",
                                      { staticClass: "stop-eta" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm._f("timeFormat")(
                                                item.arrivalTime,
                                                _vm.DATE_TYPES.standardTime
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.user.vehicleCapacityUnitsConfiguration !=
                                  null &&
                                _vm.user.vehicleCapacityUnitsConfiguration
                                  .length > 0
                                  ? _c(
                                      "md-table-cell",
                                      { staticClass: "stop-load" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.getStopLoad(item)) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.user.vehicleCapacityUnitsConfiguration !=
                                  null &&
                                _vm.user.vehicleCapacityUnitsConfiguration
                                  .length > 0
                                  ? _c(
                                      "md-table-cell",
                                      { staticClass: "vehicle-load" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.getVehicleCurrentLoadOrAvailableCapacity(
                                                item
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.content.teamMember !== null &&
                                !_vm.loadedSaveModel
                                  ? _c(
                                      "md-table-cell",
                                      [
                                        item.stopRef &&
                                        _vm.showWarningAboutCustomerId(item)
                                          ? _c(
                                              "md-avatar",
                                              [
                                                _c(
                                                  "md-icon",
                                                  {
                                                    staticClass: "warning-icon",
                                                  },
                                                  [_vm._v("warning")]
                                                ),
                                                _vm.stopWithDifferentCustomer(
                                                  item
                                                )
                                                  ? _c(
                                                      "md-tooltip",
                                                      {
                                                        staticClass:
                                                          "warning-tooltip",
                                                        attrs: {
                                                          "md-direction":
                                                            "right",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    This " +
                                                            _vm._s(
                                                              item.shipmentId
                                                                ? "stop's shipment"
                                                                : "stop"
                                                            ) +
                                                            " has a different customer\n                                    from the trip. This will be updated to match with the trip customer.\n                                "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm.stopWithoutCustomer(
                                                      item
                                                    )
                                                  ? _c(
                                                      "md-tooltip",
                                                      {
                                                        staticClass:
                                                          "warning-tooltip",
                                                        attrs: {
                                                          "md-direction":
                                                            "right",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    This " +
                                                            _vm._s(
                                                              item.shipmentId
                                                                ? "stop's shipment"
                                                                : "stop"
                                                            ) +
                                                            " has no customer. This\n                                    will be updated to match with the trip customer.\n                                "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.content.teamMember !== null &&
                                !_vm.loadedSaveModel
                                  ? _c(
                                      "md-table-cell",
                                      { staticClass: "action-buttons" },
                                      [
                                        item.stopRef
                                          ? _c("reassign-stop-button", {
                                              class:
                                                item.display === "hide"
                                                  ? "btn-display"
                                                  : "",
                                              attrs: {
                                                "is-assigned-stops": true,
                                                "allocate-stops":
                                                  _vm.content.allTrips,
                                                "stop-id": item.stopId,
                                                "vehicle-id":
                                                  _vm.content.vehicleId,
                                              },
                                              on: {
                                                reassignedStop:
                                                  _vm.handleReassignedStop,
                                              },
                                            })
                                          : _vm._e(),
                                        item.stopRef
                                          ? _c("delete-stop-button", {
                                              class: [
                                                "custom-btn",
                                                item.display === "hide"
                                                  ? "btn-display"
                                                  : "",
                                              ],
                                              attrs: {
                                                "stop-id": item.stopId,
                                                "vehicle-id":
                                                  _vm.content.vehicleId,
                                                "allocate-stops":
                                                  _vm.content.allTrips,
                                                "stop-ref": item.stopRef,
                                                address: item.address,
                                                "is-dialog": true,
                                              },
                                              on: {
                                                stopDeleted:
                                                  _vm.handleDeleteStop,
                                              },
                                            })
                                          : _vm._e(),
                                        item.stopRef
                                          ? _c(
                                              "md-button",
                                              {
                                                class: [
                                                  "md-danger undo-btn md-just-icon md-round",
                                                  item.display === "hide"
                                                    ? ""
                                                    : "btn-display",
                                                ],
                                                attrs: {
                                                  title: "Undo changes",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.undoTripStop(
                                                      item.stopId
                                                    )
                                                  },
                                                },
                                              },
                                              [_c("md-icon", [_vm._v("undo")])],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "modal-footer" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "dialog-button md-default btn-close",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.closeTripStopWindow($event)
                          },
                        },
                      },
                      [_vm._v("\n                    Close\n                ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }