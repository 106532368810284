var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-spacing" },
    [
      _c(
        "sidebar-item",
        { attrs: { link: { name: "Accounting", icon: "paid" } } },
        [
          _c("sidebar-item", {
            attrs: {
              link: {
                name: "Team Member Invoices",
                icon: "store",
                path: "/team-member-invoices/list",
              },
            },
          }),
          _c("sidebar-item", {
            attrs: {
              link: {
                name: "Customer Invoices",
                icon: "people",
                path: "/customer-invoices/list",
              },
            },
          }),
          _vm.hasTeamAccess
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: "Invoice Adjustments",
                    icon: "people",
                    path: "/invoice-adjustments/list",
                  },
                },
              })
            : _vm._e(),
          (!_vm.isIndividualUser &&
            _vm.$root.isDesktop &&
            _vm.isTeamOwner &&
            _vm.hasRateRules) ||
          _vm.isAdmin
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: _vm.$t("menus.rates"),
                    icon: "price_change",
                    path: "/rates/list",
                  },
                },
              })
            : _vm._e(),
          (!_vm.isIndividualUser && _vm.$root.isDesktop && _vm.isTeamOwner) ||
          _vm.isAdmin
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: _vm.$t("menus.processCharges"),
                    icon: "price_check",
                    path: "/processcharges/list",
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }