<template>
    <div class="cf-container">
        <div class="cf-action-container">
            <md-button
                title="Add shipment custom fields"
                class="md-primary md-just-icon md-round"
                @click.stop="handleAddCustomField()"
            >
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <md-table class="custom-paginated-table">
            <md-table-row>
                <md-table-head>Id</md-table-head>
                <md-table-head>Label</md-table-head>
                <md-table-head>Type</md-table-head>
                <md-table-head>Include in Proof Of Delivery PDF</md-table-head>
                <md-table-head class="actions">Actions</md-table-head>
            </md-table-row>

            <md-table-row v-for="(customField, index) in shipmentCustomFieldList" :key="index">
                <md-table-cell>
                    {{ customField.name }}
                </md-table-cell>
                <md-table-cell>
                    {{ customField.label }}
                </md-table-cell>
                <md-table-cell>
                    {{ customField.type }}
                </md-table-cell>
                <md-table-cell>
                    {{ customField.includeInPodPdf ? 'Yes' : 'No' }}
                </md-table-cell>
                <md-table-cell class="action-buttons">
                    <md-button
                        title="edit custom field"
                        class="md-warning md-just-icon md-round"
                        @click.stop="handleEditShipmentCustomField(customField)"
                    >
                        <md-icon>edit</md-icon>
                    </md-button>
                    <md-button
                        title="delete custom field"
                        class="md-danger md-just-icon md-round"
                        @click.stop="removeCustomField(index)"
                    >
                        <md-icon>delete</md-icon>
                    </md-button>
                </md-table-cell>
            </md-table-row>
        </md-table>
        <div class="text-center" v-if="shipmentCustomFieldList.length == 0">
            <p class="no-result-message">No results matching your search/filter could be found.</p>
        </div>
    </div>
</template>

<script>
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import ShipmentCustomFieldModal from './ShipmentCustomFieldModal';

export default {
    name: 'ShipmentCustomFieldsList',
    mixins: [GeneralMixin],
    data() {
        return {
            shipmentCustomFieldList: [],
            shouldShowAddShipmentCustomFieldModal: false
        };
    },
    async mounted() {
        this.$_handleLoaderState(true);
        const api = '/api/teams/custom-fields/shipments';
        const response = await handleRequests(api);
        if (response.data !== '') {
            this.shipmentCustomFieldList = response.data;
        }
        this.$_handleLoaderState(false);
    },
    methods: {
        handleAddCustomField() {
            this.$modal.show(ShipmentCustomFieldModal).then((response) => {
                const shipmentCustomField = this.shipmentCustomFieldList.find((item) => item.name === response.name);

                // to prevent duplicate error from happening, we add a randomString at the end if field name already exists.
                if (shipmentCustomField) {
                    const randomString = Math.random()
                        .toString(20)
                        .substr(2, 4);
                    response.name = `${response.name}-${randomString}`;
                }

                if (!this.isDuplicateField(response) && this.isDuplicateField(response) !== 0) {
                    this.shipmentCustomFieldList.push(response);
                    this.saveCustomFields('SAVING...');
                } else {
                    this.showWarningDuplicate(response.label);
                }
            });
        },
        handleEditShipmentCustomField(selectedCustomField) {
            this.$modal.show(ShipmentCustomFieldModal, { selectedCustomField }).then((response) => {
                let hasDuplicate = false;
                const shipmentCustomField = this.shipmentCustomFieldList.find((item) => item.name === response.name);
                const hasChanges = !Object.keys(shipmentCustomField).every(
                    (key) => shipmentCustomField[key] === response[key]
                );
                const shipmentCustomFieldIndex = this.shipmentCustomFieldList.findIndex(
                    (item) => item.name === response.name
                );

                if (hasChanges) {
                    if (this.isDuplicateField(response) >= 0 && this.isDuplicateField(response) !== false) {
                        hasDuplicate = !(this.isDuplicateField(response) === shipmentCustomFieldIndex);
                        if (hasDuplicate) {
                            this.showWarningDuplicate(response.label);
                        }
                    }

                    if (!hasDuplicate) {
                        this.$set(shipmentCustomField, 'label', response.label);
                        this.$set(shipmentCustomField, 'type', response.type);
                        this.$set(shipmentCustomField, 'includeInPodPdf', response.includeInPodPdf);
                        this.saveCustomFields('UPDATING...');
                    }
                }
            });
        },
        toggleModal(shouldToggle, modalToToggle) {
            this[modalToToggle] = shouldToggle;
        },
        removeCustomField(index) {
            this.$messageBox
                .show({
                    title: 'Delete shipment custom field',
                    body: 'Are you sure you want to delete this custom field?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.shipmentCustomFieldList.splice(index, 1);
                        this.saveCustomFields('DELETING...');
                    }
                });
        },
        async saveCustomFields(text) {
            this.$_handleLoaderState(true, text);
            const payload = {
                method: 'post',
                data: this.shipmentCustomFieldList
            };
            const api = `/api/teams/custom-fields/shipments`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Custom fields saved!',
                    type: 'success'
                });
                await this.$store.dispatch('user/FETCH_USER');
            } catch (e) {
                let message = 'Cannot save custom fields.';
                if (e) 
                    [{ message }] = e.data;
                this.$notify({
                    message,
                    type: 'danger'
                });
            }
            this.$_handleLoaderState(false);
        },
        isDuplicateField(customField) {
            const index = this.shipmentCustomFieldList.findIndex(
                (x) => x.label.toLowerCase() === customField.label.toLowerCase()
            );

            if (index === -1) {
                return false;
            }

            return index;
        },
        showWarningDuplicate(label) {
            const message = `Custom field name ${label} already exists.`;
            this.$notify({
                message,
                type: 'warning'
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.actions {
    width: 6%;
}
</style>
