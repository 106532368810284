<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Search Teams</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v" class="form-wrapper">
                <teams-autocomplete
                    ref="teamsRef"
                    label="Team"
                    placeholder=""
                    v-model="team"
                    :should-focus="true"
                    :is-required="true"
                />
                <form-group v-if="team && team.status" name="action" label="Action">
                    <md-select v-model="action">
                        <md-option v-for="item in actionOptions" :key="item" :value="item">
                            {{ item }}
                        </md-option>
                    </md-select>
                </form-group>
                <form-group v-if="action === 'Change Status'" name="status" label="Status">
                    <md-select v-model="status">
                        <md-option v-for="item in filteredStatusOptions" :key="item" :value="item">
                            {{ item }}
                        </md-option>
                    </md-select>
                </form-group>
                <form-group
                    v-if="
                        action === 'Complete Carrier Approval' && team && team.teamCarriers && team.teamCarriers.length
                    "
                    name="carrier"
                    label="Carriers with Pending Approval"
                >
                    <md-select v-model="selectedCarrier">
                        <md-option v-for="item in team.teamCarriers" :key="item" :value="item">
                            {{ item.carrierName }}
                        </md-option>
                    </md-select>
                </form-group>
                <div v-if="carrierCreatedCustomerId" class="md-layout-item detail-group">
                    <label class="label">Created Customer Id:</label>
                    <span class="value">
                        {{ carrierCreatedCustomerId }}
                    </span>
                </div>
                <form-group v-if="action === 'Set as Free'" name="reason" label="Reason">
                    <md-textarea v-model="reason" />
                </form-group>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click.stop="executeAction">Go</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { TeamsAutocomplete } from '@/components';
import Swal from 'sweetalert2';

export default {
    name: 'SupportJoinTeamModal',
    components: { TeamsAutocomplete },
    mixins: [GeneralMixin],
    props: {
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            team: {
                teamId: null,
                status: null,
                company: null,
                teamCarriers: []
            },
            action: null,
            status: null,
            reason: null,
            completeCarrierApprovalText: 'Complete Carrier Approval',
            actionOptions: ['Change Status', 'Join Team', 'Set as Free'],
            statusOptions: [
                {
                    from: 'Disabled?',
                    options: ['Active', 'Disabling', 'Disabled']
                },
                {
                    from: 'Disabling',
                    options: ['Active', 'Disabled']
                },
                {
                    from: 'Disabled',
                    options: ['Active', 'Disabling']
                },
                {
                    from: 'Unknown',
                    options: ['Active', 'Disabling', 'Disabled']
                },
                {
                    from: 'Active',
                    options: ['Disabling', 'Disabled']
                }
            ],
            selectedCarrier: null,
            carrierCreatedCustomerId: null
        };
    },
    computed: {
        filteredStatusOptions() {
            if (!this.team || !this.team.status) 
                return [];

            const targetStatuses = this.statusOptions.find((x) => x.from === this.team.status);

            if (targetStatuses) 
                return targetStatuses.options;

            this.$notify({
                message: `Changing '${this.team.status}' status is not allowed.`,
                type: 'warning'
            });

            return [];
        }
    },
    watch: {
        team(newVal) {
            const completeCarrierApprovalIndex = this.actionOptions.findIndex(
                (x) => x === this.completeCarrierApprovalText
            );

            if (newVal && newVal.teamCarriers && newVal.teamCarriers.length) {
                if (completeCarrierApprovalIndex === -1) 
                    this.actionOptions.push(this.completeCarrierApprovalText);
            } else if (completeCarrierApprovalIndex > -1) 
                this.actionOptions.splice(completeCarrierApprovalIndex, 1);
        }
    },
    methods: {
        async FinishJoinTeam() {
            this.$notify({
                message: `Joined Team!`,
                type: 'success'
            });
            this.$v.$reset();

            await this.$store.dispatch('user/FETCH_USER');

            this.resolve(true);
        },
        async joinTeam() {
            try {
                this.$_handleLoaderState(true, 'UPDATING...');

                const payload = {
                    method: 'post'
                };
                const api = `/api/support/teams/${this.team.teamId}/join`;
                const response = await handleRequests(api, payload);

                if (response.status === 200) {
                    if (response.data === true) {
                        this.FinishJoinTeam();
                        return;
                    }

                    Swal.fire({
                        title: 'PIN Code',
                        input: 'text',
                        text: 'Enter the PIN sent to your Locate2u email address',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Continue',
                        showLoaderOnConfirm: true,
                        preConfirm: (pinNumber) => {
                            handleRequests(`/api/support/teams/${this.team.teamId}/verify-pin?pin=${pinNumber}`, {
                                method: 'post'
                            })
                                .then((response) => {
                                    if (response.data === true) {
                                        this.FinishJoinTeam();
                                        return;
                                    }

                                    showErrorMessage(this, 'Wrong PIN!');
                                })
                                .catch((error) => {
                                    let {
                                        data: [{ message }]
                                    } = error;

                                    if (!message) 
                                        message = 'Cannot join team.';
                                    showErrorMessage(this, message);
                                });
                        },
                        allowOutsideClick: () => false
                    });
                }
            } catch (error) {
                let {
                    data: [{ message }]
                } = error;

                if (!message) 
                    message = 'Cannot join team.';
                showErrorMessage(this, message, error);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async changeTeamStatus(toStatus) {
            this.$_handleLoaderState(true, `Updating ${this.team.company}'s status...`);

            try {
                const payload = {
                    method: 'post',
                    data: {
                        status: toStatus
                    }
                };
                const api = `/api/support/teams/${this.team.teamId}/team-status`;
                await handleRequests(api, payload);

                this.$notify({
                    message: "Team's status was updated successfully.",
                    type: 'success'
                });

                this.resolve(false);
            } catch (e) {
                const message = "Could not update team's status.";
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async changeBillingStatus(toStatus) {
            this.$_handleLoaderState(true, `Updating ${this.team.company}'s billing status...`);

            try {
                const payload = {
                    method: 'post',
                    data: {
                        status: toStatus,
                        reason: this.reason
                    }
                };
                const api = `/api/support/teams/${this.team.teamId}/billing-status`;
                await handleRequests(api, payload);

                this.$notify({
                    message: "Team's billing account was updated successfully.",
                    type: 'success'
                });

                this.resolve(false);
            } catch (e) {
                const message = "Could not update team's billing status.";
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async changeTeamCarrierStatus(toStatus) {
            this.$_handleLoaderState(true, `Updating ${this.team.company}'s carrier status...`);

            try {
                const payload = {
                    method: 'post',
                    data: {
                        teamCarrierId: this.selectedCarrier.teamCarrierId,
                        status: toStatus
                    }
                };
                const api = `/api/support/teams/${this.team.teamId}/team-carrier-status`;
                const { data } = await handleRequests(api, payload);
                this.carrierCreatedCustomerId = data.customerId;

                this.$notify({
                    message: "Team's carrier status was updated successfully.",
                    type: 'success'
                });

                // keep the modal afloat in order for the user to copy the created customer id
            } catch (e) {
                const message = "Could not update team's carrier status.";
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async executeAction() {
            this.$v.$touch();
            this.$refs.teamsRef.callTouch();
            if (this.$v.$invalid) 
                return;

            switch (this.action) {
                case 'Change Status':
                    await this.changeTeamStatus(this.status);
                    break;
                case 'Join Team':
                    await this.joinTeam();
                    break;
                case 'Set as Free':
                    await this.changeBillingStatus('Free');
                    break;
                case 'Complete Carrier Approval':
                    await this.changeTeamCarrierStatus('CarrierApproved');
                    break;
                default:
                    break;
            }
        }
    },
    validations() {
        return {
            team: {
                teamId: { required }
            },
            action: { required },
            status: {
                required: requiredIf(() => {
                    return this.action === 'Change Status';
                })
            },
            reason: {
                required: requiredIf(() => {
                    return this.action === 'Set as Free';
                })
            }
        };
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-container {
    max-width: 600px;
}
::v-deep .md-error {
    padding-top: 30px;
}

.detail-group {
    margin-bottom: 20px;
    .label {
        display: inline-block;
        width: 180px;
        margin-right: 4px;
        vertical-align: top;
        font-weight: 400;
        text-align: left;
    }

    .value {
        display: inline-block;
        width: calc(100% - 200px);
        vertical-align: top;
        a {
            color: #2b93ff;
        }
        text-align: left;
    }
}
</style>
