<template>
    <ul>
        <li style="color:white" v-for="(message, index) in messages" :key="index">
            {{ message.text }}
        </li>
    </ul>
</template>

<script>
const signalR = require('@aspnet/signalr');

export default {
    name: 'Demo',
    data() {
        return {
            // signalR
            connection: null,
            messages: [],
            expandNews: false,
            expandSingle: false,
            lastSeenPerson: ''
        };
    },
    async mounted() {
        await this.setupSignalR();
    },
    methods: {
        async setupSignalR() {
            const user = await window.auth.getUser();

            this.connection = new signalR.HubConnectionBuilder()
                .withUrl('/LocationHub', {
                    accessTokenFactory: () => {
                        return user.access_token;
                    }
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            this.connection.start();
            this.connection.on('Detected', this.detected);
        },
        parseName(val) {
            const parts = val.replace('.jpg', '').split('_');
            parts.forEach((part, index, object) => {
                if (!Number.isNaN(part)) 
                    object.splice(index, 1);
            }); // Remove _1, _2 etc
            return parts;
        },
        detected(val) {
            if (val.FaceSearchResponse) {
                val.FaceSearchResponse.forEach((res) => {
                    res.MatchedFaces.forEach((face) => {
                        const parts = this.parseName(face.Face.ExternalImageId);
                        const firstName = parts[0];
                        const fullName = parts.join(' ');
                        if (fullName !== this.lastSeenPerson) {
                            this.lastSeenPerson = fullName;
                            this.messages.push({
                                text: `Saw ${fullName}. Saying greeting`
                            });
                            window.speechSynthesis.speak(
                                new SpeechSynthesisUtterance(`Hello ${firstName}. How are you doing?`)
                            );
                        }
                    });
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$list-width: 320px;

.full-control {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
}

.list {
    width: $list-width;
}

.full-control > .md-list {
    width: $list-width;
    max-width: 100%;
    height: 400px;
    display: inline-block;
    overflow: auto;
    border: 1px solid rgba(#000, 0.12);
    vertical-align: top;
}

.control {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    padding: 16px;
}
</style>
