var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c(
          "h4",
          {
            staticClass: "modal-title",
            attrs: { id: "createJobOfferSettingModalTitle" },
          },
          [_vm._v("Create Job Offer Setting")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _vm.jobOfferSettingModel
      ? _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c(
              "form-wrapper",
              {
                staticClass: "form-wrapper",
                attrs: { validator: _vm.$v.jobOfferSettingModel },
              },
              [
                _c(
                  "form-group",
                  { attrs: { name: "teamRegion", label: "Team Region" } },
                  [
                    _c("team-region-options", {
                      attrs: {
                        selected: _vm.jobOfferSettingModel.teamRegionId,
                        "show-auto": false,
                        mode: "create",
                        "include-sub-team-regions": false,
                      },
                      on: {
                        selectedOption: _vm.handleTeamRegionOptionsChanged,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "form-group",
                  {
                    attrs: {
                      name: "jobOfferSetting.offerAllocationMethod",
                      label: "Offer Allocation Method",
                    },
                  },
                  [
                    _c(
                      "md-select",
                      {
                        model: {
                          value: _vm.jobOfferSettingModel.offerAllocationMethod,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.jobOfferSettingModel,
                              "offerAllocationMethod",
                              $$v
                            )
                          },
                          expression:
                            "jobOfferSettingModel.offerAllocationMethod",
                        },
                      },
                      _vm._l(
                        _vm.jobOfferAllocationMethodOptions,
                        function (item) {
                          return _c(
                            "md-option",
                            { key: item.value, attrs: { value: item.value } },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.text) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "form-group",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAutoAllocation,
                        expression: "isAutoAllocation",
                      },
                    ],
                    attrs: { name: "maxRetry", label: "Max Retry" },
                  },
                  [
                    _c("md-input", {
                      attrs: { type: "number" },
                      model: {
                        value: _vm.jobOfferSettingModel.maxRetry,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.jobOfferSettingModel,
                            "maxRetry",
                            _vm._n($$v)
                          )
                        },
                        expression: "jobOfferSettingModel.maxRetry",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "form-group",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAutoAllocation,
                        expression: "isAutoAllocation",
                      },
                    ],
                    attrs: { name: "retryDelay", label: "Retry Delay" },
                  },
                  [
                    _c("TimeSpanInput", {
                      attrs: { value: _vm.jobOfferSettingModel.retryDelay },
                      on: { changed: _vm.handleRetryDelayChanged },
                      model: {
                        value: _vm.jobOfferSettingModel.retryDelay,
                        callback: function ($$v) {
                          _vm.$set(_vm.jobOfferSettingModel, "retryDelay", $$v)
                        },
                        expression: "jobOfferSettingModel.retryDelay",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "form-group",
                  {
                    staticClass: "no-underline",
                    attrs: { name: "jobOfferSetting.matchSkills" },
                  },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.jobOfferSettingModel.matchSkills,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.jobOfferSettingModel,
                              "matchSkills",
                              $$v
                            )
                          },
                          expression: "jobOfferSettingModel.matchSkills",
                        },
                      },
                      [_vm._v("Match Skills")]
                    ),
                  ],
                  1
                ),
                _c(
                  "form-group",
                  {
                    staticClass: "no-underline",
                    attrs: { name: "jobOfferSetting.checkLoadCapacity" },
                  },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.jobOfferSettingModel.checkLoadCapacity,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.jobOfferSettingModel,
                              "checkLoadCapacity",
                              $$v
                            )
                          },
                          expression: "jobOfferSettingModel.checkLoadCapacity",
                        },
                      },
                      [_vm._v("Check Load Capacity")]
                    ),
                  ],
                  1
                ),
                _c(
                  "form-group",
                  {
                    staticClass: "no-underline",
                    attrs: { name: "jobOfferSetting.prioritizeEmptyVehicles" },
                  },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value:
                            _vm.jobOfferSettingModel.prioritizeEmptyVehicles,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.jobOfferSettingModel,
                              "prioritizeEmptyVehicles",
                              $$v
                            )
                          },
                          expression:
                            "jobOfferSettingModel.prioritizeEmptyVehicles",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Prioritize Empty Vehicles\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "form-group",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "no-underline",
                    attrs: { name: "jobOfferSetting.autoAllocate" },
                  },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.jobOfferSettingModel.autoAllocate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.jobOfferSettingModel,
                              "autoAllocate",
                              $$v
                            )
                          },
                          expression: "jobOfferSettingModel.autoAllocate",
                        },
                      },
                      [_vm._v("Auto Allocate")]
                    ),
                  ],
                  1
                ),
                _c(
                  "form-group",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAutoAllocation,
                        expression: "isAutoAllocation",
                      },
                    ],
                    staticClass: "no-underline",
                    attrs: { name: "jobOfferSetting.failFast" },
                  },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.jobOfferSettingModel.failFast,
                          callback: function ($$v) {
                            _vm.$set(_vm.jobOfferSettingModel, "failFast", $$v)
                          },
                          expression: "jobOfferSettingModel.failFast",
                        },
                      },
                      [_vm._v("Fail Fast")]
                    ),
                  ],
                  1
                ),
                _c(
                  "form-group",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAutoAllocation,
                        expression: "isAutoAllocation",
                      },
                    ],
                    attrs: {
                      name: "jobOfferSetting.alertLeadTime",
                      label:
                        "Notification Lead Time before the scheduled timeframe",
                    },
                  },
                  [
                    _c("TimeSpanInput", {
                      attrs: { value: _vm.jobOfferSettingModel.alertLeadTime },
                      on: { changed: _vm.handleAlertLeadTimeChanged },
                      model: {
                        value: _vm.jobOfferSettingModel.alertLeadTime,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.jobOfferSettingModel,
                            "alertLeadTime",
                            $$v
                          )
                        },
                        expression: "jobOfferSettingModel.alertLeadTime",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            attrs: { id: "btnUpdate" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.create($event)
              },
            },
          },
          [_vm._v("Create")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }