<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.capacitySettings') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>production_quantity_limits</md-icon>
                    </div>
                </md-card-header>

                <md-card-content>
                    <div class="cf-container">
                        <h5>
                            This will set a default capacity for each user in your team when using the optimise stops.
                        </h5>
                        <div>
                            <md-table class="custom-paginated-table">
                                <md-table-row>
                                    <md-table-head></md-table-head>
                                    <md-table-head>Type</md-table-head>
                                    <md-table-head>Unit</md-table-head>
                                    <md-table-head>Symbol</md-table-head>
                                    <md-table-head>Default Stop/Shipment Load</md-table-head>
                                    <md-table-head>Default Max Vehicle Capacity</md-table-head>
                                    <md-table-head>Include in POD PDF</md-table-head>
                                </md-table-row>

                                <md-table-row v-for="(capacity, index) in capacityDefaults" :key="index">
                                    <md-table-cell>
                                        <md-checkbox class="checkbox-head" v-model="capacity.selected"></md-checkbox>
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ capacity.label }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        <form-group>
                                            <md-select
                                                v-model="capacity.selectedUnit"
                                                @md-selected="handleSelectedUnit(capacity.type, capacity.selectedUnit)"
                                            >
                                                <md-option
                                                    v-for="(item, unitNdx) in capacity.units"
                                                    :key="`${unitNdx}-${item.unit}`"
                                                    :value="item.unit"
                                                >
                                                    {{ item.unit }}
                                                </md-option>
                                            </md-select>
                                            <md-input
                                                v-show="capacity.selectedUnit === 'Others'"
                                                v-model="capacity.otherUnit"
                                            />
                                        </form-group>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <form-group>
                                            <md-input
                                                v-show="capacity.selectedUnit !== 'Others'"
                                                v-model="capacity.selectedSymbol"
                                            />
                                            <md-input
                                                v-show="capacity.selectedUnit === 'Others'"
                                                v-model="capacity.otherSymbol"
                                            />
                                        </form-group>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <form-group>
                                            <md-input v-model.number="capacity.defaultLoad" type="number" />
                                        </form-group>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <form-group>
                                            <md-input v-model.number="capacity.defaultMaxCapacity" type="number" />
                                        </form-group>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <span>
                                            <md-checkbox
                                                class="checkbox-head"
                                                v-model="capacity.includeInPodPdf"
                                                :disabled="!capacityTypeAllowedInPodPdf(capacity.type)"
                                            ></md-checkbox>
                                            <md-tooltip v-if="!capacityTypeAllowedInPodPdf(capacity.type)">
                                                Not allowed in POD PDF
                                            </md-tooltip>
                                        </span>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                        <button class="custom-btn" @click="saveTeamCapacity">Save</button>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters, mapMutations } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import {
    CAPACITY_TYPES_CONSTANTS,
    CAPACITY_TYPE_DEFAULTS_CONSTANTS,
    CAPACITY_TYPES_INCLUDE_IN_POD_PDF_CONSTANTS
} from '@/utils/constants';

export default {
    name: 'CapacitySettings',
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            getUser: 'user/user',
            hasTeam: 'user/hasTeam'
        })
    },

    data() {
        return {
            capacityTypes: [...CAPACITY_TYPES_CONSTANTS],
            capacityDefaults: [...CAPACITY_TYPE_DEFAULTS_CONSTANTS],
            user: null
        };
    },
    async mounted() {
        this.user = Object.assign({}, this.getUser);
        await this.getTeamData();

        const teamCapacities = this.vehicleCapacityUnitsConfiguration || [];

        this.capacityDefaults.forEach((def) => {
            // add others to each type
            if (def.units.findIndex((d) => d.unit === 'Others') === -1) {
                def.units.push({
                    unit: 'Others',
                    symbol: 'oth',
                    default: false
                });
                def.otherUnit = null;
                def.otherSymbol = null;
            }

            const teamCap = teamCapacities.find((x) => x.type === def.type);
            if (teamCap) {
                def.selected = true;
                def.defaultLoad = teamCap.defaultLoad;
                def.defaultMaxCapacity = teamCap.defaultMaxCapacity;
                def.includeInPodPdf = teamCap.includeInPodPdf;

                // find if unit exists in list of standard units
                const userUnit = def.units.find((u) => u.unit === teamCap.unit);
                if (userUnit) {
                    def.unit = teamCap.unit;
                    def.symbol = teamCap.symbol;
                    def.selectedUnit = teamCap.unit;
                    def.selectedSymbol = teamCap.symbol;
                } else {
                    // it's other unit
                    def.unit = 'Others';
                    def.symbol = 'oth';
                    def.selectedUnit = 'Others';
                    def.selectedSymbol = 'oth';
                    def.otherUnit = teamCap.unit;
                    def.otherSymbol = teamCap.symbol;
                }
            }
        });
    },
    methods: {
        ...mapMutations({
            updateProfile: 'user/UPDATE_USER'
        }),
        capacityTypeAllowedInPodPdf(capacityType) {
            return CAPACITY_TYPES_INCLUDE_IN_POD_PDF_CONSTANTS.includes(capacityType);
        },
        async getTeamData() {
            const api = '/api/teams';
            const { data } = await handleRequests(api);
            this.team = data.data;
            this.vehicleCapacityUnitsConfiguration = this.team.vehicleCapacityUnitsConfiguration;
        },
        async saveTeamCapacity() {
            const emptyUnitOrSymbol = [];
            const filteredData = this.capacityDefaults.filter((c) => c.selected === true);
            const mapped = filteredData.map((capacityUnit) => {
                const selUnit = capacityUnit.units.find((x) => x.unit === capacityUnit.selectedUnit);
                capacityUnit.unit = capacityUnit.selectedUnit;
                capacityUnit.symbol = capacityUnit.selectedSymbol;

                if (capacityUnit.selectedUnit === 'Others' && (!capacityUnit.otherUnit || !capacityUnit.otherSymbol)) {
                    emptyUnitOrSymbol.push(capacityUnit.label);
                }

                return {
                    defaultLoad: capacityUnit.defaultLoad === '' ? null : capacityUnit.defaultLoad,
                    defaultMaxCapacity: capacityUnit.defaultMaxCapacity === '' ? null : capacityUnit.defaultMaxCapacity,
                    type: capacityUnit.type,
                    label: capacityUnit.label,
                    unit: selUnit.unit !== 'Others' ? capacityUnit.unit : capacityUnit.otherUnit,
                    symbol: selUnit.unit !== 'Others' ? capacityUnit.symbol : capacityUnit.otherSymbol,
                    includeInPodPdf: capacityUnit.includeInPodPdf
                };
            });

            if (emptyUnitOrSymbol.length) {
                showErrorMessage(this, `Unit or Symbol cannot be empty. ( ${emptyUnitOrSymbol.join(', ')})`);
                return;
            }

            this.$_handleLoaderState(true, 'SAVING...');

            const payload = {
                method: 'put',
                data: mapped
            };
            const api = `/api/teams/capacity/settings`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Team capacity setting have been saved!',
                    type: 'success'
                });
                this.user.vehicleCapacityUnitsConfiguration = mapped;
                this.updateProfile(this.user);
                this.$_handleLoaderState(false);
            } catch (e) {
                const message = 'Cannot save Team capacity setting.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        handleSelectedUnit(type, selectedUnit) {
            const sourceType = this.capacityDefaults.find((x) => x.type === type);
            const sourceTypeIndex = this.capacityDefaults.findIndex((x) => x.type === type);
            const unit = sourceType.units.find((x) => x.unit === selectedUnit);
            sourceType.selectedUnit = selectedUnit;
            sourceType.selectedSymbol = unit.symbol;
            this.$set(this.capacityDefaults, sourceTypeIndex, sourceType);
        }
    }
};
</script>
<style lang="scss" scoped>
.capacity-container {
    width: 100%;
    float: none;
}
.capacity-header {
    font-weight: bold;
    margin-top: 10px;
}
.custom-paginated-table {
    ::v-deep .md-field input {
        border: 1px solid #ddd;
        padding: 10px;
    }
}
.card-icon {
    background: #ffb42b !important;
}
</style>
