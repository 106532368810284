<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Add Business Entity</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.entityDetails" class="form-wrapper">
                <div class="form-container">
                    <div class="contact-details">
                        <form-group
                            name="name"
                            label="Business Name"
                            :messages="{ required: 'Business Name is required.' }"
                        >
                            <md-input v-focus v-model="entityDetails.businessRegistrationName" id="businessName" />
                        </form-group>
                        <form-group
                            name="number"
                            label="Business Number (ABN)"
                            :messages="{ required: 'Business Number is required.' }"
                        >
                            <md-input v-model="entityDetails.businessRegistrationNumber" id="businessNumber" />
                        </form-group>
                        <form-group
                            name="address"
                            label="Business Address"
                            :messages="{ required: 'Business Address is required.' }"
                        >
                            <md-input v-model="entityDetails.businessAddress" id="businessAddress" />
                        </form-group>
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click.prevent="addBusinessEntity">
                Add
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { required } from 'vuelidate/lib/validators';
import router from '@/router/router';

export default {
    name: 'AddBusinessEntityModal',
    mixins: [GeneralMixin],
    props: {
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            entityDetails: {
                businessRegistrationName: null,
                businessRegistrationNumber: null,
                businessAddress: null
            }
        };
    },
    methods: {
        async addBusinessEntity() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            await this.saveBusinessEntity();
        },
        async saveBusinessEntity() {
            this.$_handleLoaderState(true, 'Saving...');
            try {
                const api = '/api/business-entities';

                const payload = {
                    method: 'post',
                    data: this.entityDetails
                };

                const response = await handleRequests(api, payload);

                if (response !== null) {
                    router.push(`/settings/business-entities/details/${response.data}`);
                }
                const message = 'Successfully added';
                this.$notify({
                    message,
                    type: 'success'
                });
                this.$_handleLoaderState(false);
                this.resolve(true);
            } catch (e) {
                const message = 'Error in saving business entity';
                this.$_handleLoaderState(false);
                showErrorMessage(this, message, e);
            }
        }
    },
    validations: {
        entityDetails: {
            businessRegistrationName: { required },
            businessRegistrationNumber: { required },
            businessAddress: { required }
        }
    }
};
</script>
