<template>
    <div class="detail-section">
        <div class="section-header">
            <span class="title">{{ sectionTitle }}</span>
            <div class="section-header--actions">
                <md-button class="md-info md-just-icon md-round" @click="createLocation">
                    <md-icon>add</md-icon>
                </md-button>
            </div>
        </div>
        <div class="search-area">
            <vue-select
                label="displayText"
                v-model="locationTypeQuery"
                :options="locationChoicesPluralized"
                placeholder="Select location type"
            ></vue-select>
            <search-component @onSearch="handleSearchByName" placeholder="Search barcode / name" />
        </div>
        <div v-if="loading" class="loading-section">
            <FadeLoader :loading="loading" color="#333333" />
        </div>
        <div v-else>
            <!-- show empty list if empty locattions -->
            <div v-if="locations.length === 0" class="detail-group">
                <label class="label no-location">No locations found</label>
            </div>
            <div class="location-groups">
                <div v-for="type in locationGroupsSorted" :key="type" class="detail-group location-group">
                    <md-tooltip>
                        Click me to view items inside this location
                    </md-tooltip>
                    <div class="location-items">
                        <span class="location-group--title">{{ locationChoicesObjPluralized[type] || type }}</span>
                        <div
                            v-for="(location, index) in locationsGroupedByType[type]"
                            :key="index"
                            class="location-item"
                            @click="viewItemLocationItems(location.locationId)"
                        >
                            <ColorViewer :color="location.color" class="location-item--color" />
                            <div class="location-item--body">
                                <span class="location-item--body-title">{{ location.name }}</span>

                                <span v-if="!!location.notes" class="location-item--body-subtitle">
                                    {{ location.notes }}
                                </span>
                            </div>
                            <div class="location-item--actions">
                                <md-button
                                    class="md-info md-just-icon md-round"
                                    @click.stop="generateLabel(location.locationId)"
                                >
                                    <md-icon>download_for_offline</md-icon>
                                </md-button>
                                <md-button
                                    class="md-warning md-just-icon md-round"
                                    @click.stop="updateLocation(location.locationId)"
                                >
                                    <md-icon>edit</md-icon>
                                </md-button>
                                <md-button
                                    class="md-danger md-just-icon md-round"
                                    @click.stop="deleteLocation(location.locationId)"
                                >
                                    <md-icon>delete</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { GeneralMixin } from '@/mixins';
import { handleRequests, showErrorMessage } from '@/helpers';
import { SearchComponent } from '@/components';
import ColorViewer from './ColorViewer';
import ManageLocationModal from './ManageLocationModal';

import {
    warehouseLocationChoices,
    warehouseLocationChoicesList,
    assetLocationChoices,
    assetLocationChoicesList
} from './locationChoices';
import ManageItemLocationItemsModal from './ManageItemLocationItemsModal';

export default {
    name: 'ItemLocationList',
    components: {
        ColorViewer,
        FadeLoader,
        SearchComponent
    },
    mixins: [GeneralMixin],
    data() {
        return {
            /**
             * @type {Array<import('@/jsDocTypes/ItemLocation').ItemLocation>}
             */
            locations: [],
            loading: false,
            locationQuery: '',
            locationTypeQuery: null
        };
    },
    props: {
        // Warehouse ID is required for warehouse locations
        warehouseId: {
            type: Number,
            default: null
        },
        // Asset ID is required for asset locations
        assetId: {
            type: Number,
            default: null
        },
        // Team member ID is required for team member locations
        // This is unsupported for now
        teamMemberPublicId: {
            type: String,
            default: null
        }
    },
    computed: {
        /**
         *
         * @returns {Record<string, string>} Key value pair of location type and its plural form.
         */
        locationChoicesObjPluralized() {
            if (this.warehouseId) {
                return warehouseLocationChoices;
            }
            if (this.assetId) {
                return assetLocationChoices;
            }
            if (this.teamMemberPublicId) {
                return {};
            }
            return {};
        },
        /**
         *
         * @returns {{ type: string, valueId: string, displayText: string}[]}
         */
        locationChoicesPluralized() {
            if (this.warehouseId) {
                return Object.keys(warehouseLocationChoices).map((key) => {
                    return {
                        type: key,
                        valueId: key,
                        displayText: warehouseLocationChoices[key]
                    };
                });
            }
            if (this.assetId) {
                return Object.keys(assetLocationChoices).map((key) => {
                    return {
                        type: key,
                        valueId: key,
                        displayText: assetLocationChoices[key]
                    };
                });
            }
            if (this.teamMemberPublicId) {
                return [];
            }
            return [];
        },
        /**
         *
         * @returns {string[]} Location choices based on the type of location
         */
        locationChoices() {
            if (this.warehouseId) {
                return warehouseLocationChoicesList;
            }
            if (this.assetId) {
                return assetLocationChoicesList;
            }
            if (this.teamMemberPublicId) {
                return [];
            }
            return [];
        },
        sectionTitle() {
            if (this.warehouseId) {
                return 'Warehouse Locations';
            }
            if (this.assetId) {
                return 'Asset Locations';
            }
            if (this.teamMemberPublicId) {
                return 'Team Member Locations';
            }
            return 'Locations';
        },
        /**
         * @returns {Record<string, import('./types/ItemLocation').ItemLocation>}
         */
        locationsGroupedByType() {
            const grouped = {};
            this.locations.forEach(
                /**
                 * @param {import('./types/ItemLocation').ItemLocation} location
                 */
                (location) => {
                    if (!grouped[location.type]) {
                        grouped[location.type] = [];
                    }
                    grouped[location.type].push(location);
                }
            );
            return grouped;
        },
        locationGroupsSorted() {
            return Object.keys(this.locationsGroupedByType).sort();
        }
    },
    mounted() {
        this.getLocations();
    },
    watch: {
        locationTypeQuery() {
            this.getLocations();
        }
    },
    methods: {
        /**
         * @param {{ searchText: string }} obj
         * @returns {Promise<void>}
         */
        async handleSearchByName(obj) {
            this.locationQuery = obj.searchText;
            await this.getLocations();
        },
        async getLocations() {
            this.loading = true;
            const query = new URLSearchParams();
            if (this.locationQuery) {
                query.append('locationQuery', this.locationQuery);
            }
            if (this.locationTypeQuery) {
                query.append('locationType', this.locationTypeQuery.valueId);
            }
            if (this.warehouseId) {
                query.append('warehouseId', this.warehouseId);
            } else if (this.assetId) {
                query.append('assetId', this.assetId);
            } else if (this.teamMemberPublicId) {
                query.append('teamMemberPublicId', this.teamMemberPublicId);
            }
            try {
                const api = `/api/item-locations?${query.toString()}`;
                const { data } = await handleRequests(api);
                this.locations = data;
            } catch (error) {
                const message = 'Error in getting the warehouse locations';
                showErrorMessage(this, message, error);
            }
            this.loading = false;
        },
        /**
         * @param {number} locationId
         */
        async generateLabel(locationId) {
            this.$_handleLoaderState(true, 'PROCESSING...');
            try {
                const api = `/api/item-locations/${locationId}/generate-label`;
                const payload = {
                    method: 'post',
                    responseType: 'arraybuffer'
                };
                const response = await handleRequests(api, payload);
                if (response.status !== 200) {
                    showErrorMessage(this, 'Error in generating the label', response);

                    this.$_handleLoaderState(false);
                    return;
                }
                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL, '_blank');

                this.$notify({
                    message: 'Label generated successfully',
                    type: 'success'
                });
            } catch (error) {
                const message = 'Error generating the label';
                showErrorMessage(this, message, error);
            }

            this.$_handleLoaderState(false);
        },
        createLocation() {
            const options = {
                locationChoices: this.locationChoices
            };
            if (this.warehouseId) {
                options.warehouseId = this.warehouseId;
            } else if (this.assetId) {
                options.assetId = this.assetId;
            } else if (this.teamMemberPublicId) {
                options.teamMemberPublicId = this.teamMemberId;
            }
            this.$modal.show(ManageLocationModal, options).then((response) => {
                if (response && response.toLowerCase() === 'ok') {
                    this.$modal.hide();
                    this.onCreateLocationSuccess();
                }
            });
        },

        viewItemLocationItems(id) {
            this.$modal.show(ManageItemLocationItemsModal, { locationId: id });
        },

        /**
         * @param {number} id
         */
        updateLocation(id) {
            const locationDetails = this.locations.find((location) => location.locationId === id);

            const options = {
                locationId: id,
                locationChoices: this.locationChoices,
                initialLocationDetails: locationDetails,
                isUpdateFlag: true
            };

            if (this.warehouseId) {
                options.warehouseId = this.warehouseId;
            } else if (this.assetId) {
                options.assetId = this.assetId;
            } else if (this.teamMemberPublicId) {
                options.teamMemberPublicId = this.teamMemberId;
            }

            this.$modal.show(ManageLocationModal, options).then((response) => {
                if (response && response.toLowerCase() === 'ok') {
                    this.$modal.hide();
                    this.onUpdateLocationSuccess();
                }
            });
        },
        /**
         * @param {number} id
         */
        async deleteLocation(id) {
            const locationToDelete = this.locations.find((location) => location.locationId === id);
            if (!locationToDelete) 
                return;
            const response = await this.$messageBox.show({
                title: 'Delete Location',
                body: `Are you sure you want to delete the location ${locationToDelete.name}?`,
                buttons: ['Yes', 'No']
            });
            if (response !== 'Yes') {
                return;
            }

            this.$_handleLoaderState(true, 'DELETING...');
            try {
                const api = `/api/item-locations/${id}`;
                const payload = {
                    method: 'delete'
                };
                const response = await handleRequests(api, payload);
                if (response.status !== 200) {
                    showErrorMessage(this, 'Error in deleting the location', response);
                    this.$_handleLoaderState(false);
                    return;
                }
                this.$notify({
                    message: 'Location deleted successfully',
                    type: 'success'
                });
                this.onDeleteLocationSuccess(id);
            } catch (error) {
                const message = 'Error in deleting the location';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },

        // emit events
        onCreateLocationSuccess() {
            this.getLocations();
        },
        onUpdateLocationSuccess() {
            this.getLocations();
        },
        /**
         *
         * @param {number} id
         */
        onDeleteLocationSuccess(id) {
            this.locations = this.locations.filter((location) => location.locationId !== id);
        }
    }
};
</script>
<style lang="scss" scoped>
.detail-section {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 15px;

    .blue-ref a {
        color: #2b93ff !important;
    }

    .title {
        text-transform: uppercase;
        font-weight: 600;
        display: block;
        font-size: 14px;
    }

    ::v-deep .title {
        text-transform: uppercase;
        font-weight: 600;
        display: block;
        font-size: 14px;
    }

    ::v-deep .custom-a-blue a {
        color: #2b93ff !important;
    }
}

.location-groups {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 15px;
}

.loading-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.location-group {
    &--title {
        font-size: 1rem;
    }
}

.location-items {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 5px;
}

.location-item {
    display: grid;
    margin-left: 5px;
    padding: 5px 5px 5px 0; // don't put margin at left side because we already have a margin
    align-items: center;
    grid-template-columns: 30px 3fr auto;
    column-gap: 5px;
    cursor: pointer;

    transition: all 0.3s ease-in-out;

    &--actions {
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    &--color {
        width: 20px;
    }

    &--body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        &-title {
            font-weight: normal !important;
            font-size: 14px !important;
        }

        &-subtitle {
            font-size: 12px !important;
            line-height: 15px !important;
            color: #3c4858 !important;
        }
    }

    &:hover {
        background-color: #f5f5f5;
    }

    &:hover &--actions {
        opacity: 1;
    }
}

.label.no-location {
    text-align: center;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--actions {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.search-area {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 7.5px;
    // override the filter component style
    ::v-deep .vs__search {
        font-size: 14px;
        // add padding bottom to keep the filter component aligned with the search component
        padding-bottom: 3px;
        // modify placeholder color to match the search component
        // this is not the exact color - I just eyeballed it
        -webkit-text-fill-color: #a1a1a1;
    }
}

// override search component and dropdown component styles
// to keep both consistent

::v-deep .search--container .search-button--container {
    margin: 0;

    // remove margin from the button
    // to make sure that there isn't any white space between the button and the right side
    & > button {
        margin: 0;
    }
}

::v-deep .vs__dropdown-toggle {
    margin: 0;
    padding-bottom: 7px;
}
</style>
