var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container assign-trip-model" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-group",
          { attrs: { name: "TripTeamMember" } },
          [
            _c(
              "md-select",
              {
                model: {
                  value: _vm.assignedUser,
                  callback: function ($$v) {
                    _vm.assignedUser = $$v
                  },
                  expression: "assignedUser",
                },
              },
              _vm._l(_vm.orderedTeamMembers, function (member) {
                return _c(
                  "md-option",
                  {
                    key: member.publicUserId,
                    attrs: { value: member.publicUserId },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(member.fullName || member.publicUserId) +
                        "\n                "
                    ),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            on: { click: _vm.onChangeTeamMember },
          },
          [_vm._v("\n            Change\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Change Team Member")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }