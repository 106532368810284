var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.driverName && _vm.driverName.length
    ? _c(
        "span",
        [
          _c(
            "svg",
            {
              staticClass: "online-status",
              class: [
                _vm.isOnline ? "online" : "offline",
                _vm.iconSize == "small" ? "small-icon" : "big-icon",
              ],
              attrs: {
                viewBox: "0 0 12 12",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("circle", {
                attrs: { cx: "5", cy: "7", r: "3", "stroke-width": "1" },
              }),
            ]
          ),
          _c(
            "md-tooltip",
            { staticClass: "toggle-tooltip", attrs: { "md-direction": "top" } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.driverName) +
                  " is " +
                  _vm._s(_vm.isOnline ? "online" : "offline") +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }