<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">
                        &nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.proofOfDeliveryConfiguration') }}
                    </h3>
                </div>
                <div class="custom-toolbar-end">
                    <md-button
                        title="Refresh List"
                        class="md-primary md-just-icon md-round pull-right header-button"
                        @click="refreshList"
                    >
                        <md-icon>refresh</md-icon>
                    </md-button>
                    <router-link :to="{ name: 'Create Proof Of Delivery Template' }">
                        <md-button
                            title="Add proof of delivery template"
                            class="md-primary md-just-icon md-round pull-right header-button"
                        >
                            <md-icon>add</md-icon>
                        </md-button>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <proof-of-delivery-template-list ref="proofOfDeliveryTemplateList" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProofOfDeliveryTemplateList from './ProofOfDeliveryTemplateList';

export default {
    name: 'ProofOfDeliveryTemplateListPage',
    components: {
        ProofOfDeliveryTemplateList
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam'
        })
    },
    methods: {
        async refreshList() {
            await this.$refs.proofOfDeliveryTemplateList.handleRefresh();
        }
    }
};
</script>
<style lang="scss" scoped>
.header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}
</style>
