<template>
    <div>
        <div class="detail-container">
            <div class="close-button">
                <md-button class="md-default md-just-icon md-round pull-right" @click="closeModal">
                    <md-icon>close</md-icon>
                </md-button>
            </div>
            <div v-if="!loading">
                <div class="detail-section text-center">
                    <span class="title blue-ref" v-if="stopDetails.stopRef != null">
                        <router-link
                            :to="{
                                name: 'Stop Details',
                                params: { stopId: stopDetails.stopId }
                            }"
                            target="_blank"
                        >
                            {{ stopDetails.stopRef }}
                        </router-link>
                    </span>
                    <span class="title" v-else>
                        <router-link
                            :to="{
                                name: 'Stop Details',
                                params: { stopId: stopDetails.stopId }
                            }"
                        >
                            {{ stopDetails.id }}
                        </router-link>
                    </span>
                    <div class="detail-group text-center action-detail-group">
                        <img
                            v-if="stopDetails.assignedTo.publicUserId"
                            class="profile-image"
                            :src="
                                stopDetails.assignedTo.photoUrl !== null
                                    ? stopDetails.assignedTo.photoUrl
                                    : $root.defaultPhotoUrl
                            "
                            alt="avatar"
                            @error="$_setDefaultBrokenImage"
                        />
                        <img
                            v-if="stopDetails.carrierTeam != null"
                            class="profile-image"
                            :src="
                                stopDetails.carrierTeam.logoUrl !== null
                                    ? stopDetails.carrierTeam.logoUrl
                                    : $root.defaultLogoUrl
                            "
                            alt="avatar"
                        />
                        <router-link
                            title="View stop details"
                            class="md-button md-success md-just-icon md-round"
                            :to="{
                                name: 'Stop Details',
                                params: { stopId: stopDetails.stopId }
                            }"
                            target="_blank"
                        >
                            <div class="md-ripple">
                                <div class="md-button-content">
                                    <md-icon>visibility</md-icon>
                                </div>
                            </div>
                        </router-link>
                        <template v-if="isCarrierAssigned">
                            <a
                                v-if="carrierTrackingUrl"
                                class="md-button md-primary md-just-icon md-round"
                                :href="carrierTrackingUrl"
                                target="_blank"
                            >
                                <div class="md-ripple">
                                    <div class="md-button-content">
                                        <md-icon>place</md-icon>
                                    </div>
                                </div>
                            </a>
                        </template>
                        <template v-else>
                            <a
                                v-if="stopDetails.trackingLinkUrl"
                                title="Tracking Link"
                                class="md-button md-primary md-just-icon md-round"
                                :href="setTrackingLinkUrl(stopDetails.trackingLinkUrl)"
                                target="_blank"
                            >
                                <div class="md-ripple">
                                    <div class="md-button-content">
                                        <md-icon>place</md-icon>
                                    </div>
                                </div>
                            </a>
                            <a
                                v-else-if="stopDetails.trackingLinkUrl == null && !isReadOnlyUser"
                                title="Create tracking link"
                                class="md-button md-default md-just-icon md-round"
                                @click="createTrackingLink(stopDetails)"
                                target="_blank"
                            >
                                <div class="md-ripple">
                                    <div class="md-button-content">
                                        <md-icon>add_location_alt</md-icon>
                                    </div>
                                </div>
                            </a>
                        </template>
                        <update-stop-button
                            :stop="stopDetails.stopId"
                            :team-members="teamMembers"
                            @stopUpdated="updateStop"
                            :is-failed="stopDetails.status.toLowerCase() === 'failed'"
                            :carrier-team-id="stopDetails.carrierTeamId"
                        />
                        <reassign-stop-button
                            v-if="!isSingleUser && !isSingleTeamMember && !isReadOnlyUser"
                            type="icon"
                            class-name="small-icon"
                            :item="stopDetails"
                            :team-members="teamMembers"
                            @assignedUser="assignStop"
                        />
                        <delete-stop-button
                            :stop-id="stopDetails.stopId"
                            :team-members="teamMembers"
                            @stopDeleted="deleteStop"
                            :is-disabled="stopDetails.type != null"
                            :has-attached-items="stopDetails.items.length > 0"
                        />
                        <generate-label-button v-if="stopDetails.items.length > 0" :id="stopDetails.stopId" />
                        <stop-pdf-download-button
                            v-if="!stopDetails.carrierTeamId"
                            :status="stopDetails.status.toLowerCase()"
                            :stop-type="stopDetails.type"
                            :stop-id="stopDetails.stopId"
                            :stop-ref="stopDetails.stopRef"
                        />
                    </div>
                </div>
                <div class="line-separator"></div>
                <div class="detail-section">
                    <span class="title">Stop Details</span>
                    <div class="detail-group" v-if="stopDetails.shipmentId > 0">
                        <label class="label">Shipment Ref:</label>
                        <span class="value">
                            <router-link
                                :to="{
                                    name: 'Shipment Details',
                                    params: { shipmentId: stopDetails.shipmentId }
                                }"
                                target="_blank"
                            >
                                {{ stopDetails.stopRef.slice(0, -2) }}
                            </router-link>
                        </span>
                    </div>
                    <div class="detail-group" v-if="stopDetails.carrierTeamId == null">
                        <label class="label">Assigned To:</label>

                        <reassign-stop-button
                            v-if="isAssignedTo()"
                            type="text"
                            :item="stopDetails"
                            :team-members="teamMembers"
                            @assignedUser="assignStop"
                        />

                        <reassign-stop-button
                            v-else
                            type="button"
                            class-name="custom-btn"
                            :item="stopDetails"
                            :team-members="teamMembers"
                            @assignedUser="assignStop"
                        />
                    </div>
                    <div class="detail-group" v-if="stopDetails.rating">
                        <label class="label">Rating:</label>
                        <div class="value">
                            <div class="rating">
                                <md-icon
                                    v-for="index in 5"
                                    :key="index"
                                    :style="{ color: index <= stopDetails.rating ? 'orange' : 'rgba(0,0,0,0.54' }"
                                >
                                    star_rate
                                </md-icon>
                            </div>
                        </div>
                    </div>
                    <div class="detail-group" v-if="stopDetails.type != null">
                        <label class="label">Type:</label>
                        <span class="value">
                            {{ stopDetails.type }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Status:</label>
                        <span class="value">
                            <stop-status-button :stop="stopDetails" @statusUpdated="changeStopStatus" />
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">When:</label>
                        <div class="value">
                            <span
                                v-if="
                                    stopDetails.tripDate == null &&
                                        stopDetails.appointmentTime == null &&
                                        stopDetails.timeWindowStart == null &&
                                        stopDetails.timeWindowEnd == null
                                "
                            >
                                <i>Unscheduled</i>
                            </span>
                            <span v-if="stopDetails.tripDate != null">
                                <router-link
                                    v-if="stopDetails.tripId && !isCarrierAssigned"
                                    :to="{
                                        name: 'Trip Details',
                                        params: {
                                            tripId: stopDetails.tripId
                                        }
                                    }"
                                    target="_blank"
                                >
                                    {{ stopDetails.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                </router-link>
                                <span v-else>
                                    {{ stopDetails.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                </span>
                            </span>
                            <span v-if="stopDetails.timeWindowStart != null && stopDetails.timeWindowEnd != null">
                                ({{ stopDetails.timeWindowStart | timeFormat }} -
                                {{ stopDetails.timeWindowEnd | timeFormat }})
                            </span>
                            <span v-else-if="stopDetails.appointmentTime != null">
                                ({{ stopDetails.appointmentTime | timeFormat }})
                            </span>
                        </div>
                    </div>
                    <div
                        class="detail-group"
                        v-if="
                            stopDetails.originalEta != null &&
                                stopDetails.status != 'Arrived' &&
                                stopDetails.status != 'Departed' &&
                                stopDetails.status != 'Complete'
                        "
                    >
                        <label class="label">ETA:</label>
                        <span class="value">
                            {{ stopDetails.originalEta | dateTimeFormat(DATE_TYPES.standardDate) }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Address:</label>
                        <div class="value">
                            <div
                                v-if="stopDetails.name && !$_validateCoordinate(stopDetails.address)"
                                class="address-name"
                            >
                                {{ stopDetails.name }}
                            </div>
                            <a class="address" @click.prevent="viewAddress(stopDetails)">
                                {{ $_displayAddressName(stopDetails) }}
                            </a>
                        </div>
                    </div>
                    <div class="detail-group">
                        <label class="label">Duration:</label>
                        <span class="value">{{ stopDetails.durationMinutes }} minutes</span>
                    </div>
                    <div class="detail-group" v-if="stopDetails.arrivalDate">
                        <label class="label">Arrival:</label>
                        <span class="value">
                            {{ stopDetails.arrivalDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                        </span>
                    </div>
                    <div class="detail-group" v-if="stopDetails.departureDate">
                        <label class="label">Departure:</label>
                        <span class="value">
                            {{ stopDetails.departureDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                        </span>
                    </div>
                    <div v-if="teamRegionName" class="detail-group">
                        <label class="label">Team Region:</label>
                        <span class="value">
                            {{ teamRegionName }}
                        </span>
                    </div>

                    <div class="detail-group" v-if="stopDetails.runNumber || stopDetails.runName">
                        <label class="label">Run:</label>
                        <span class="value" v-if="stopDetails.runName">
                            {{ stopDetails.runName }}
                        </span>
                        <span class="value" v-else>
                            {{ stopDetails.runNumber }}
                        </span>
                    </div>

                    <div class="detail-group" v-if="stopDetails.teamMemberInvoiceId">
                        <label class="label">Team Member Invoice:</label>
                        <span class="value">
                            <router-link
                                :to="{
                                    name: 'Team Member Invoice Details',
                                    params: { invoiceId: stopDetails.teamMemberInvoiceId }
                                }"
                                target="_blank"
                            >
                                {{ stopDetails.teamMemberInvoiceNumber }}
                            </router-link>

                            <div
                                :class="
                                    `custom-badge invoice-badge payment-status-${stopDetails.teamMemberInvoiceStatus
                                        .toLowerCase()
                                        .replace(/\s+/g, '')}`
                                "
                            >
                                {{ $t('inputs.paymentStatuses.' + stopDetails.teamMemberInvoiceStatus) }}
                            </div>
                        </span>
                    </div>

                    <div class="detail-group" v-if="stopDetails.customerInvoiceId">
                        <label class="label">Customer Invoice:</label>
                        <span class="value">
                            <router-link
                                :to="{
                                    name: 'Customer Invoice Details',
                                    params: { invoiceId: stopDetails.customerInvoiceId }
                                }"
                                target="_blank"
                            >
                                {{ stopDetails.customerInvoiceNumber }}
                            </router-link>

                            <div
                                :class="
                                    `custom-badge invoice-badge payment-status-${stopDetails.customerInvoiceStatus
                                        .toLowerCase()
                                        .replace(/\s+/g, '')}`
                                "
                            >
                                {{ $t('inputs.paymentStatuses.' + stopDetails.customerInvoiceStatus) }}
                            </div>
                        </span>
                    </div>
                </div>
                <div class="line-separator" v-if="stopDetails.carrierTeamId != null"></div>
                <div class="detail-section contact-detail-section" v-if="stopDetails.carrierTeamId != null">
                    <span class="title">Carrier Details</span>
                    <div class="detail-group">
                        <label class="label">Carrier:</label>
                        <span class="value">
                            {{ stopDetails.carrierTeam.company }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Reference #:</label>
                        <span class="value">
                            {{ stopDetails.shipment.assignedCarrierBooking.supportReference }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Service Type:</label>
                        <span class="value">
                            <span
                                class="custom-badge"
                                :class="
                                    `status-${$_getServiceLevelBadgeColor(
                                        stopDetails.shipment.assignedCarrierBooking.timeWindowEnd
                                    )}`
                                "
                            >
                                {{ $t(stopDetails.shipment.assignedCarrierBooking.serviceLevel) }}
                            </span>
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Price:</label>
                        <span class="value">
                            {{
                                stopDetails.shipment.assignedCarrierBooking.feeAfterTax
                                    | currency(stopDetails.shipment.assignedCarrierBooking.currency)
                            }}
                        </span>
                    </div>
                    <div class="detail-group" v-if="stopDetails.type == 'Pick Up'">
                        <label class="label">Pickup ETA:</label>
                        <span class="value" v-if="stopDetails.shipment.assignedCarrierBooking.pickupEta">
                            {{
                                stopDetails.shipment.assignedCarrierBooking.pickupEta
                                    | dateTimeFormat(DATE_TYPES.standardDate)
                            }}
                        </span>
                        <span v-else class="not-available">
                            Not available
                        </span>
                    </div>
                    <div class="detail-group" v-if="stopDetails.type == 'Drop'">
                        <label class="label">Drop ETA:</label>
                        <span class="value" v-if="stopDetails.shipment.assignedCarrierBooking.dropOffEta">
                            {{
                                stopDetails.shipment.assignedCarrierBooking.dropOffEta
                                    | dateTimeFormat(DATE_TYPES.standardDate)
                            }}
                        </span>
                        <span v-else class="not-available">
                            Not available
                        </span>
                    </div>
                </div>
                <div class="line-separator"></div>
                <div class="detail-section contact-detail-section">
                    <span class="title">Contact Details</span>
                    <div class="detail-group">
                        <label class="label">Name:</label>
                        <span class="value">
                            {{ stopDetails.contact.name }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Phone:</label>
                        <a class="value link" :href="`tel:${stopDetails.contact.name}`">
                            {{ stopDetails.contact.phone }}
                        </a>
                    </div>
                    <div class="detail-group">
                        <label class="label">Email:</label>
                        <span class="value">
                            {{ stopDetails.contact.email }}
                        </span>
                    </div>
                </div>

                <div class="line-separator" v-if="stopDetails.brandId != null"></div>
                <div class="detail-section" v-if="stopDetails.brandId != null">
                    <span class="title">Brand</span>
                    <img
                        v-if="stopDetails.brandLogoUrl"
                        class="brand-logo"
                        :src="stopDetails.brandLogoUrl !== null ? stopDetails.brandLogoUrl : $root.defaultPhotoUrl"
                        @error="$_setDefaultBrokenImage"
                        :title="stopDetails.brandName"
                        :alt="stopDetails.brandName"
                    />
                    <span v-if="!stopDetails.brandLogoUrl">{{ stopDetails.brandName }}</span>
                </div>

                <div class="line-separator" v-if="stopDetails.skills.length > 0"></div>
                <div class="detail-section contact-detail-section" v-if="stopDetails.skills.length > 0">
                    <span class="title">Required Skills</span>
                    <div class="detail-group md-chips md-primary md-theme-default md-has-placeholder md-theme-default">
                        <div class="md-chip md-theme-default" v-for="(skill, index) in stopDetails.skills" :key="index">
                            {{ skill }}
                        </div>
                    </div>
                </div>
                <div class="line-separator" v-if="stopCustomFieldList.length"></div>
                <div class="detail-section contact-detail-section" v-if="stopCustomFieldList.length">
                    <span class="title">Custom fields</span>
                    <div class="detail-group" v-for="(customField, index) in stopCustomFieldList" :key="index">
                        <span class="label">{{ customField.label }}:</span>
                        <div
                            class="value"
                            v-if="customFieldValues !== null && customFieldValues[customField.name] !== null"
                        >
                            <span v-if="customField.type.toLowerCase() === 'date'">
                                {{ customFieldValues[customField.name] | dateTimeFormat(DATE_TYPES.standardDate) }}
                            </span>
                            <span v-else>{{ customFieldValues[customField.name] }}</span>
                        </div>
                    </div>
                </div>
                <div class="line-separator" v-if="stopDetails.shipmentId && shipmentCustomFieldList.length"></div>
                <div
                    class="detail-section contact-detail-section"
                    v-if="stopDetails.shipmentId && shipmentCustomFieldList.length"
                >
                    <span class="title">Shipment Custom fields</span>
                    <div class="detail-group" v-for="(customField, index) in shipmentCustomFieldList" :key="index">
                        <span class="label">{{ customField.label }}:</span>
                        <div
                            class="value"
                            v-if="
                                shipmentCustomFieldValues !== null &&
                                    shipmentCustomFieldValues[customField.name] !== null
                            "
                        >
                            <span v-if="customField.type.toLowerCase() === 'date'">
                                {{
                                    shipmentCustomFieldValues[customField.name]
                                        | dateTimeFormat(DATE_TYPES.standardDate)
                                }}
                            </span>
                            <span v-else>{{ shipmentCustomFieldValues[customField.name] }}</span>
                        </div>
                    </div>
                </div>
                <div class="line-separator" v-if="loadCapacityList.length"></div>
                <div class="detail-section contact-detail-section" v-if="loadCapacityList.length">
                    <span class="title">Load</span>
                    <div class="detail-group" v-for="(loadCapacity, index) in loadCapacityList" :key="index">
                        <div v-if="loadCapacity.type !== 'dimensions'">
                            <span class="label">{{ loadCapacity.label }}:</span>
                            <div class="value" v-if="loadValues !== null && loadValues[loadCapacity.type] !== null">
                                {{ loadValues[loadCapacity.type] }} {{ loadCapacity.symbol }}
                            </div>
                        </div>
                        <div v-if="loadCapacity.type === 'dimensions'">
                            <span class="label">{{ loadCapacity.label }}</span>
                            <div class="value" v-if="loadValues !== null && loadValues[loadCapacity.type] !== null">
                                L: {{ loadValues['length'] }} {{ loadCapacity.symbol }} W: {{ loadValues['width'] }}
                                {{ loadCapacity.symbol }} H: {{ loadValues['height'] }} {{ loadCapacity.symbol }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="line-separator" v-if="stopDetails.driverInstructions"></div>
                <div class="detail-section" v-if="stopDetails.driverInstructions">
                    <span class="title">Driver's Instructions</span>
                    <DriverInstructions :driver-instructions="stopDetails.driverInstructions" />
                </div>

                <div class="line-separator" v-if="stopDetails.items.length > 0"></div>
                <div class="detail-section contact-detail-section" v-if="stopDetails.items.length > 0">
                    <span class="title">Items</span>
                    <div v-for="(line, index) in stopDetails.items" :key="index">
                        <div class="detail-group item-reference">
                            <router-link
                                title="View item details"
                                :to="{ name: 'Inventory Details', params: { itemId: line.itemId } }"
                                target="_blank"
                            >
                                <div
                                    :class="
                                        `custom-badge inventory-status-${line.status.toLowerCase().replace(/\s+/g, '')}`
                                    "
                                >
                                    {{ $t('inputs.itemStatuses.' + line.status) }}
                                </div>
                                <span class="label status-label">{{ line.description }}</span>
                            </router-link>

                            <md-tooltip class="line-tooltip" md-direction="left">
                                <div class="label" v-if="line.currentLocationName">
                                    {{ line.currentLocationName }}
                                </div>
                                <div v-if="line.barcode">
                                    {{ line.barcode }}
                                </div>
                            </md-tooltip>
                        </div>
                    </div>
                </div>

                <div class="line-separator" v-if="stopDetails.lines.length > 0"></div>
                <div class="detail-section contact-detail-section" v-if="stopDetails.lines.length > 0">
                    <span class="title">Services</span>
                    <div v-for="(line, index) in stopDetails.lines" :key="index">
                        <div class="detail-group item-reference">
                            <span class="label info-line">
                                {{ line.description }}
                                <span class="quantity">
                                    x {{ line.quantity }}
                                    <span class="quantity-type" v-if="line.quantityType">{{ line.quantityType }}</span>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="line-separator" v-if="stopDetails.notes"></div>
                <div class="detail-section" v-if="stopDetails.notes">
                    <span class="title">Notes</span>
                    <div class="detail-group notes-content">
                        {{ stopDetails.notes }}
                    </div>
                </div>
                <div
                    class="line-separator"
                    v-if="
                        stopDetails.batchImportId || (stopDetails.importErrors && stopDetails.importErrors.length > 0)
                    "
                ></div>
                <div
                    class="detail-section"
                    v-if="
                        stopDetails.batchImportId || (stopDetails.importErrors && stopDetails.importErrors.length > 0)
                    "
                >
                    <span class="title">Import Details</span>
                    <div class="detail-group" v-if="stopDetails.batchImportId">
                        <label class="label">Import Id:</label>
                        <span class="value">
                            {{ stopDetails.batchImportId }}
                        </span>
                    </div>
                    <div class="detail-group" v-if="stopDetails.importErrors && stopDetails.importErrors.length > 0">
                        <label class="label">Import Errors:</label>
                        <span class="value">
                            <ul>
                                <li
                                    v-for="(importError, importErrorIndex) in stopDetails.importErrors"
                                    :key="importErrorIndex"
                                >
                                    {{ importError }}
                                </li>
                            </ul>
                        </span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="stopbar-loader">
                    <fade-loader :loading="loading" color="#333333" />
                    <span>LOADING</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import { GeneralMixin, MapOverviewMixin, CarrierMixin } from '@/mixins';
import { GenerateLabelButton } from '@/components';
import { handleRequests, getTeamRegionName } from '@/helpers';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { mapGetters } from 'vuex';
import { ViewAddressModal } from '..';
import {
    UpdateStopButton,
    DeleteStopButton,
    ReassignStopButton,
    StopStatusButton,
    StopPdfDownloadButton
} from './buttons';
import { DriverInstructions } from './components';

export default {
    name: 'StopSidebar',
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        stopId: {
            type: [Number, String],
            default: () => null
        },
        teamMembers: {
            type: Array,
            default: () => []
        }
    },
    components: {
        FadeLoader,
        UpdateStopButton,
        DeleteStopButton,
        ReassignStopButton,
        StopStatusButton,
        GenerateLabelButton,
        StopPdfDownloadButton,
        DriverInstructions
    },
    data() {
        return {
            loading: true,
            stopDetails: null,
            customFieldValues: {},
            stopCustomFieldList: [],
            shipmentCustomFieldValues: [],
            shipmentCustomFieldList: [],
            loadCapacityList: [],
            loadValues: {}
        };
    },
    mixins: [GeneralMixin, MapOverviewMixin, CarrierMixin],
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            getUser: 'user/user',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            teamRegions: 'team/teamRegions'
        }),
        teamRegionName() {
            return getTeamRegionName(this.stopDetails?.teamRegionId, this.teamRegions);
        },
        isCarrierAssigned() {
            return this.stopDetails && this.stopDetails.carrierTeamId;
        },
        carrierTrackingUrl() {
            if (!this.isCarrierAssigned) 
                return null;
            if (this.stopDetails.assignedCarrierBooking?.trackingUrl)
                return this.stopDetails.assignedCarrierBooking?.trackingUrl;
            if (this.stopDetails.shipment?.assignedCarrierBooking?.trackingUrl)
                return this.stopDetails.shipment?.assignedCarrierBooking?.trackingUrl;
            return null;
        }
    },
    watch: {
        async stopId(newValue, oldValue) {
            await this.getStopDetails(newValue);
        },
        'data.assignedTo': function(newValue, oldValue) {
            this.stopDetails.assignedTo = newValue;
        },
        'data.tripDate': function(newValue, oldValue) {
            this.stopDetails.tripDate = newValue;
        },
        'data.status': function(newValue, oldValue) {
            this.stopDetails.status = newValue;
        }
    },
    async mounted() {
        const user = this.getUser;
        if (user.stopCustomFieldDefinitions) {
            this.stopCustomFieldList = user.stopCustomFieldDefinitions;
        }
        if (user.shipmentCustomFieldDefinitions) {
            this.shipmentCustomFieldList = user.shipmentCustomFieldDefinitions;
        }
        this.loadCapacityList = user.vehicleCapacityUnitsConfiguration || [];

        await this.getStopDetails(this.stopId);
    },
    methods: {
        isAssignedTo() {
            return Boolean(this.stopDetails.assignedTo.publicUserId);
        },
        async getStopDetails(id) {
            this.loading = true;
            const stopDetailsEndpoint = `/api/stops/${id}`;
            const {
                data: { data }
            } = await handleRequests(stopDetailsEndpoint, {
                params: {
                    includeStopLines: true
                }
            });

            const items = data.lines.filter((item) => item.itemId !== null);
            const lines = data.lines.filter((item) => item.serviceId !== null);

            // Services
            data.lines = lines;
            // Items
            data.items = items;

            this.stopDetails = data;
            this.loading = false;
            this.customFieldValues = this.stopDetails.customFields;
            if (this.stopDetails.shipmentId) {
                this.shipmentCustomFieldValues = this.stopDetails.shipment.customFields;
            }
            if (this.loadCapacityList != null && this.loadCapacityList.length) {
                this.loadValues = this.stopDetails.load;
            }
        },
        assignStop(response) {
            this.$emit('assignStop', response);
        },
        changeStopStatus(response) {
            this.$set(stop, 'status', response.status);
            this.$emit('changeStopStatus', response);
        },
        updateStop(response) {
            this.$emit('updateStop', response);
        },
        deleteStop(response) {
            this.$emit('deleteStop', response);
        },
        closeModal() {
            this.$emit('closeModal');
        },
        viewAddress() {
            this.$modal.show(ViewAddressModal, { data: this.stopDetails });
        },
        async createTrackingLink() {
            const data = {
                stopId: this.stopDetails.stopId,
                type: 'LR',
                recipient: {
                    name: this.stopDetails.contact.name,
                    phone: this.stopDetails.contact.phone,
                    email: this.stopDetails.contact.email
                },
                teamMemberId: this.stopDetails.assignedTo ? this.stopDetails.assignedTo.publicUserId : null
            };

            const api = '/api/links';
            const payload = {
                method: 'post',
                data
            };

            try {
                const response = await handleRequests(api, payload);
                this.stopDetails.trackingLinkUrl = response.data.url;

                this.$notify({
                    message: 'Successfully created a tracking link.',
                    type: 'success'
                });
            } catch (e) {
                let message = 'Error in creating the tracking link.';
                if (e && e.data) 
                    [{ message }] = e.data;
                this.$notify({
                    message,
                    type: 'danger'
                });
            }
        },
        setTrackingLinkUrl(trackingLink) {
            // this is to make url shorteners behave like a link
            if (trackingLink.includes('https://')) 
                return `${trackingLink}?isAdmin=true&showPOD=true`;

            return `https://${trackingLink}?isAdmin=true&showPOD=true`;
        }
    }
};
</script>

<style lang="scss" scoped>
.detail-container {
    position: fixed;
    top: 2vh;
    right: 10px;
    height: 96vh;
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    background: white;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 16px;

    .close-button {
        top: 0;
        right: 0;
        position: absolute;
        /* add z-index for old stops that have no stop reference numbers */
        z-index: 999;
    }

    .rating {
        display: flex;
        align-items: flex-end;
        > {
            display: inline-block;
        }
        .md-icon {
            margin: 0;
            padding-bottom: 5px;
        }
    }

    .md-button.md-just-icon,
    ::v-deep .md-button.md-just-icon {
        font-size: 15px;
        height: 30px;
        min-width: 3px;
        width: 30px;
        line-height: 30px;
        margin: 2.5px;
    }

    .detail-section {
        padding-top: 10px;
        .blue-ref a {
            color: #2b93ff !important;
        }
        .title {
            text-transform: uppercase;
            font-weight: 600;
            padding: 2px 16px;
            display: block;
            font-size: 14px;
        }
        ::v-deep .custom-a-blue a {
            color: #2b93ff !important;
        }
    }

    .line-separator {
        height: 1px;
        background-color: #ddd;
        width: calc(100% - 32px);
        margin: 10px auto;
        margin-bottom: 0px;
    }

    .detail-group {
        padding: 2px 16px;
        margin: 5px 0;
        font-size: 0.9em;
        .label {
            display: inline-block;
            width: 150px;
            margin-right: 4px;
            vertical-align: top;
            font-weight: 400;
        }

        .value {
            display: inline-block;
            width: calc(100% - 160px);
            vertical-align: top;
            a {
                color: #2b93ff;
            }
        }
        .custom-btn,
        ::v-deep .custom-btn {
            padding: 4px 8px;
            margin: 0;
            width: initial;
            max-width: initial;
            padding: 4px 0;
            .md-ripple {
                padding: 0 !important;
            }
        }
        .address {
            color: #2b93ff;
            &:hover {
                text-decoration: underline !important;
                cursor: pointer;
            }
        }
        .status-label {
            margin-left: 10px;
            font-weight: 400;
            font-size: 0.75rem;
            width: calc(100% - 100px);
        }
        .info-line {
            font-weight: 400;
            //font-size: 0.75rem;
            width: 100%;
        }
        .quantity {
            float: right;
        }
        .notes {
            padding-left: 24px;
            width: 100%;
        }
    }

    .item-reference ::v-deep a {
        color: rgba(0, 0, 0, 0.87);
    }

    .action-detail-group {
        margin: 0;
        position: relative;
    }

    .profile-image {
        height: 50px;
        width: 50px;
        margin-top: -10px;
        position: absolute;
        left: 16px;
        margin-top: -16px;
        border-radius: 50%;
        box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.1), 0 0px 15px 0 rgba(0, 0, 0, 0.15) !important;
        object-fit: contain;
    }
}

.line-tooltip {
    width: 600px;

    .label {
        font-weight: bold;
    }
}

::v-deep {
    .status {
        color: #fff !important;
        min-width: 90px;
        max-width: 90px;
        display: block;
        min-height: 31px;
        text-align: center;
    }
}

.note-image {
    height: 100px;
    width: initial;
    margin-right: 10px;
}

.stopbar-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

a {
    &.link {
        color: #2b93ff;
    }
}

@media (min-width: 992px) {
    .detail-container {
        width: 40%;
    }
}

@media (min-width: 1200px) {
    .detail-container {
        width: 500px;
    }
}

.quantity-type {
    display: inline-block;
    text-transform: lowercase;
}

.quantity-type:first-letter {
    text-transform: capitalize;
}

.invoice-badge {
    float: right;
}
.brand-logo {
    max-height: 50px;
    padding: 2px 16px;
    display: block;
}
.not-available {
    color: #aaa !important;
    font-style: italic;
}
</style>
