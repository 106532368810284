var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    { staticClass: "md-card-testimonial" },
    [
      _c(
        "md-card-header",
        { staticClass: "icon" },
        [_c("md-icon", [_vm._t("header-icon")], 2)],
        1
      ),
      _c("md-card-content", [_vm._t("content")], 2),
      _c(
        "md-card-actions",
        { attrs: { "md-alignment": "left" } },
        [_vm._t("footer")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }