var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _c(
        "md-card",
        [
          _c(
            "md-card-header",
            {
              staticClass: "md-card-header-icon",
              class: _vm.getClass(_vm.headerColor),
            },
            [
              _c(
                "div",
                { staticClass: "card-icon" },
                [_c("md-icon", [_vm._v("perm_identity")])],
                1
              ),
              _c("h4", { staticClass: "title" }, [
                _vm._v("\n                Edit Profile -\n                "),
                _c("small", [_vm._v("Complete your profile")]),
              ]),
            ]
          ),
          _c("md-card-content", [
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-33" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Company (disabled)")]),
                      _c("md-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.disabled,
                          callback: function ($$v) {
                            _vm.disabled = $$v
                          },
                          expression: "disabled",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-33" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("User Name")]),
                      _c("md-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.username,
                          callback: function ($$v) {
                            _vm.username = $$v
                          },
                          expression: "username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-33" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Email Address")]),
                      _c("md-input", {
                        attrs: { type: "email" },
                        model: {
                          value: _vm.emailadress,
                          callback: function ($$v) {
                            _vm.emailadress = $$v
                          },
                          expression: "emailadress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-50" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("First Name")]),
                      _c("md-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.firstname,
                          callback: function ($$v) {
                            _vm.firstname = $$v
                          },
                          expression: "firstname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-50" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Last Name")]),
                      _c("md-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.lastname,
                          callback: function ($$v) {
                            _vm.lastname = $$v
                          },
                          expression: "lastname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-100" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Adress")]),
                      _c("md-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.address,
                          callback: function ($$v) {
                            _vm.address = $$v
                          },
                          expression: "address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-33" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("City")]),
                      _c("md-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.city,
                          callback: function ($$v) {
                            _vm.city = $$v
                          },
                          expression: "city",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-33" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Country")]),
                      _c("md-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.country,
                          callback: function ($$v) {
                            _vm.country = $$v
                          },
                          expression: "country",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-small-size-100 md-size-33" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Postal Code")]),
                      _c("md-input", {
                        attrs: { type: "number" },
                        model: {
                          value: _vm.code,
                          callback: function ($$v) {
                            _vm.code = $$v
                          },
                          expression: "code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-size-100" },
                [
                  _c(
                    "md-field",
                    { attrs: { maxlength: "5" } },
                    [
                      _c("label", [_vm._v("About Me")]),
                      _c("md-textarea", {
                        model: {
                          value: _vm.aboutme,
                          callback: function ($$v) {
                            _vm.aboutme = $$v
                          },
                          expression: "aboutme",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-size-100 text-right" },
                [
                  _c(
                    "md-button",
                    { staticClass: "md-raised md-success mt-4" },
                    [
                      _vm._v(
                        "\n                        Update Profile\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }