var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-field",
    { staticClass: "field-select" },
    [
      _c(
        "md-select",
        {
          model: {
            value: _vm.selectedOption,
            callback: function ($$v) {
              _vm.selectedOption = $$v
            },
            expression: "selectedOption",
          },
        },
        _vm._l(_vm.options, function (option, index) {
          return _c(
            "md-option",
            { key: index, attrs: { value: option.type } },
            [_vm._v("\n            " + _vm._s(option.name) + "\n        ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }