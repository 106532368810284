function useCurrencyUtils() {
    const userLocale = window.navigator.userLanguage || window.navigator.language;
    /**
     *
     * @param {number | string} value
     * @param {string} currency
     */
    function formatValue(value, currency = 'AUD') {
        if (!value && value !== '0')
            return Intl.NumberFormat(userLocale, {
                style: 'currency',
                currency
            }).format(value);
        if (!currency) 
            return value;
        try {
            return Intl.NumberFormat(userLocale, {
                style: 'currency',
                currency
            }).format(value);
        } catch {
            // Wrong currency (probably from the DB).
            return value;
        }
    }

    return {
        formatValue
    };
}

export default useCurrencyUtils;
