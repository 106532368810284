<template>
    <transition name="modal" v-if="visible">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <component :is="modalComponent" v-bind="modalAttrs" />
            </div>
        </div>
    </transition>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'Modal',
    mixins: [GeneralMixin],
    beforeMount() {
        this.$modal.subscription.$on('toggle', this.onToggle);
    },
    created() {
        // eslint-disable-next-line no-underscore-dangle
        this.$root._dynamicContainer = this;
    },
    data() {
        return {
            modalComponent: null,
            title: '',
            text: '',
            modalAttrs: {},
            visible: false
        };
    },
    methods: {
        set(component, modalAttrs = {}, modalListeners = {}, resolve) {
            this.modalComponent = component;

            this.modalAttrs = Object.assign({}, modalAttrs, { resolve });

            this.$nextTick(() => {
                this.open();
            });
        },
        onToggle(shouldShow) {
            if (shouldShow) {
                this.open();
            } else {
                this.close();
            }
        },
        open() {
            this.visible = true;
        },
        close() {
            this.reset();
            this.$_handleLoaderState(false);
            this.visible = false;
        },
        reset() {
            this.modalComponent = null;
            this.title = '';
            this.modalAttrs = null;
        }
    },
    beforeDestroy() {
        this.$modal.subscription.$off('toggle');
    }
};
</script>

<style lang="scss" scoped>
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
