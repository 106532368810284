var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-container",
      class: _vm.$root.isMobileOnly ? "modal-dialog-full-width" : "",
    },
    [
      _c(
        "div",
        { staticClass: "modal-header" },
        [
          _c("h4", { staticClass: "modal-title" }, [_vm._v("Reassign Trip")]),
          _c(
            "md-button",
            {
              staticClass:
                "md-simple md-just-icon md-round modal-default-button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$modal.hide($event)
                },
              },
            },
            [_c("md-icon", [_vm._v("clear")])],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _c("team-region-member-controls", {
            attrs: {
              "team-region-id": _vm.teamRegionId,
              "public-user-id": _vm.form.assignedUser,
              "carrier-team-id": _vm.form.assignedCarrierTeamId,
              mode: "update",
              "show-team-region": false,
              "strict-team-region-filter": false,
              "show-carriers": true,
            },
            on: { onChanged: _vm.handleTeamRegionMemberChanged },
          }),
          _c(
            "div",
            { staticClass: "date-picker-div hide-clear-button" },
            [
              _c(
                "md-datepicker",
                {
                  ref: "mdDatePicker",
                  attrs: {
                    "md-immediately": "",
                    "md-disabled-dates": _vm.disabledDates,
                    "md-debounce": 10,
                    clearable: false,
                  },
                  model: {
                    value: _vm.selectedDate,
                    callback: function ($$v) {
                      _vm.selectedDate = $$v
                    },
                    expression: "selectedDate",
                  },
                },
                [_c("label", [_vm._v("Trip Date")])]
              ),
              _vm.tripDate == null || !_vm.isTripDateValid
                ? _c("span", { staticClass: "error" }, [
                    _vm._v("Trip date is invalid."),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._l(_vm.runs, function (run, index) {
            return _c(
              "div",
              { key: index, staticClass: "run-inputs" },
              [
                _c(
                  "form-group",
                  { attrs: { name: "runNumber", label: "Run" } },
                  [
                    _c("md-input", {
                      attrs: {
                        readonly: true,
                        placeholder: "No Run",
                        type: "number",
                        min: "1",
                      },
                      model: {
                        value: run.runNumber,
                        callback: function ($$v) {
                          _vm.$set(run, "runNumber", _vm._n($$v))
                        },
                        expression: "run.runNumber",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "form-group",
                  { attrs: { name: "runNumber", label: "New Run" } },
                  [
                    _c("md-input", {
                      attrs: {
                        placeholder: "Optional",
                        type: "number",
                        min: "1",
                      },
                      on: {
                        input: function ($value) {
                          return (run.newRunNumber = parseFloat($value) || null)
                        },
                      },
                      model: {
                        value: run.newRunNumber,
                        callback: function ($$v) {
                          _vm.$set(run, "newRunNumber", $$v)
                        },
                        expression: "run.newRunNumber",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      !_vm.$root.isMobileOnly
        ? _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-primary dialog-button",
                  on: { click: _vm.assignTrip },
                },
                [_vm._v("Assign")]
              ),
              _c(
                "md-button",
                {
                  staticClass: "md-default dialog-button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$modal.hide($event)
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }