<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Edit Item</h4>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v" class="form-wrapper">
                <div class="item-barcode">
                    <form-group name="barcode" label="Barcode">
                        <md-input v-model="barcode" v-focus />
                    </form-group>
                </div>
                <div class="item-description">
                    <form-group name="description" label="Description">
                        <md-input v-model="description" />
                    </form-group>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="addStopline">
                Save
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';
import { handleRequests, showErrorMessage } from '@/helpers';

export default {
    name: 'StopLineModal',
    mixins: [GeneralMixin],
    props: {
        item: {
            type: Object,
            default: () => null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    validations: {
        barcode: {
            required
        }
    },
    data() {
        return {
            barcode: this.item.barcode,
            description: this.item.description
        };
    },
    mounted() {},
    methods: {
        async addStopline() {
            if (this.$v.$invalid) {
                showErrorMessage(this, 'Barcode is required');
                return;
            }

            this.$_handleLoaderState(true, 'UPDATING...');
            const payload = {
                method: 'put',
                data: {
                    barcode: this.barcode,
                    description: this.description
                }
            };
            try {
                const api = `/api/items/${this.item.itemId}`;
                await handleRequests(api, payload);

                this.$notify({
                    message: `Item updated!`,
                    type: 'success'
                });
                this.$v.$reset();

                this.resolve(payload.data);
            } catch (e) {
                const message = 'Cannot update item.';
                showErrorMessage(this, message);
                this.resolve(null);
            }
            this.$_handleLoaderState(false);
        }
    }
};
</script>
