var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "md-card",
        [
          _c(
            "md-card-header",
            { staticClass: "md-card-header-icon md-card-header-blue" },
            [
              _c(
                "div",
                { staticClass: "card-icon" },
                [_c("md-icon", [_vm._v("fact_check")])],
                1
              ),
            ]
          ),
          _c(
            "md-card-content",
            [
              _c(
                "md-table",
                { staticClass: "custom-paginated-table" },
                [
                  _c(
                    "md-table-row",
                    { attrs: { slot: "header" }, slot: "header" },
                    [
                      _c("md-table-head"),
                      _c("md-table-head", [_vm._v("Name")]),
                      _c("md-table-head", [_vm._v("Used By")]),
                      _c("md-table-head", [_vm._v("Actions")]),
                    ],
                    1
                  ),
                  _vm._l(_vm.templates, function (template, index) {
                    return _c(
                      "md-table-row",
                      {
                        key: index,
                        class: {
                          "deleted-row": template.actionStatus === "deleted",
                          "deleting-row":
                            template.actionStatus === "deleting" ||
                            template.actionStatus === "deleted",
                        },
                      },
                      [
                        _c(
                          "md-table-cell",
                          { staticClass: "default-icon-column" },
                          [
                            _c("md-icon", { staticClass: "pod-default" }, [
                              _vm._v(_vm._s(template.isDefault ? "star" : "")),
                            ]),
                          ],
                          1
                        ),
                        _c("md-table-cell", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(template.name) +
                              "\n                    "
                          ),
                        ]),
                        _c("md-table-cell", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$_getUsedByLabel(template.usedBy)) +
                              "\n                    "
                          ),
                        ]),
                        _c(
                          "md-table-cell",
                          [
                            !template.isDefault
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-just-icon md-round action-button",
                                    attrs: {
                                      title: "Set as default",
                                      disabled:
                                        _vm.isTemplateDisabled(template),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setDefaultTemplate(template)
                                      },
                                    },
                                  },
                                  [_c("md-icon", [_vm._v("star")])],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Edit Proof Of Delivery Template",
                                    params: {
                                      podTemplateId: template.podTemplateId,
                                    },
                                  },
                                },
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-warning md-just-icon md-round action-button",
                                    attrs: {
                                      title: "Edit Template",
                                      disabled:
                                        _vm.isTemplateDisabled(template),
                                    },
                                  },
                                  [_c("md-icon", [_vm._v("edit")])],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "md-button",
                              {
                                staticClass: "md-danger md-just-icon md-round",
                                attrs: {
                                  title: "Delete Template",
                                  disabled:
                                    template.isDefault ||
                                    _vm.isTemplateDisabled(template),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteTemplate(template)
                                  },
                                },
                              },
                              [
                                template.actionStatus === "deleting" ||
                                template.actionStatus === "deleted"
                                  ? _c("fade-loader", {
                                      staticClass: "delete-spinner",
                                      attrs: {
                                        width: "5px",
                                        height: "5px",
                                        radius: "8px",
                                        color: "white",
                                      },
                                    })
                                  : _c("md-icon", [_vm._v("delete")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "md-card-actions",
        {
          staticClass: "page-footer",
          attrs: { "md-alignment": "space-between" },
        },
        [
          _c("div", [
            _vm.total === _vm.pagination.perPage
              ? _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "\n                Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of many\n            "
                  ),
                ])
              : _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of " +
                      _vm._s(_vm.totalPages)
                  ),
                ]),
          ]),
          _c("pagination", {
            staticClass: "pagination-no-border pagination-success",
            attrs: {
              "per-page": _vm.pagination.perPage,
              total: _vm.total,
              "enable-first-page-link": true,
              "enable-last-page-link": true,
            },
            on: {
              "change-page": function ($event) {
                return _vm.handleChangePage($event, _vm.pagination.perPage)
              },
            },
            model: {
              value: _vm.pagination.currentPage,
              callback: function ($$v) {
                _vm.$set(_vm.pagination, "currentPage", $$v)
              },
              expression: "pagination.currentPage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }