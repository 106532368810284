var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "md-field",
          { staticClass: "hide-timer-clear-button" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Start time")]),
            _c("time-picker", {
              attrs: {
                time: "00:00",
                "all-time-options": false,
                "is-required": true,
              },
              on: { selectedTime: _vm.getStartTime },
            }),
          ],
          1
        ),
        _c(
          "md-field",
          { staticClass: "hide-timer-clear-button" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("End time")]),
            _c("time-picker", {
              attrs: {
                time: "23:59",
                "all-time-options": false,
                "is-required": true,
              },
              on: { selectedTime: _vm.getEndTime },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            on: { click: _vm.addTimeWindowOption },
          },
          [_vm._v("Save")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.close($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Time Window")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }