<template>
    <div class="d-flex">
        <div>
            <div v-if="rule.validForWeekDays && rule.validForWeekDays.length > 0" class="d-flex">
                <template v-if="isWeekends(rule.validForWeekDays)">
                    Weekends
                </template>
                <template v-else-if="isWeekdays(rule.validForWeekDays)">
                    Weekdays
                </template>
                <template v-else>
                    <div v-for="(day, index) in rule.validForWeekDays" :key="index">
                        {{ selectedDay(day.label, rule.validForWeekDays.length, index) }}&nbsp;
                    </div>
                </template>
            </div>
            <div v-else>
                All days of week
            </div>
            <div v-if="validFromDateLocal || validToDateLocal">
                <div>{{ getDatesText(validFromDateLocal, validToDateLocal) }}</div>
            </div>
        </div>
        <div
            class="days-text-icon md-warning"
            v-if="
                (validFromDateLocal || validToDateLocal) &&
                    !getDatesMatchDays(validFromDateLocal, validToDateLocal, rule.validForWeekDays)
            "
        >
            <md-icon title="Rule may not apply">warning</md-icon>
        </div>
    </div>
</template>

<script>
import { DATE_TYPES } from '@/utils/constants';
import moment from 'moment';

export default {
    name: 'SelectedDay',
    props: {
        rule: {
            type: Object,
            default: () => []
        }
    },
    data() {
        return {
            validFromDateLocal: null,
            validToDateLocal: null
        };
    },
    methods: {
        selectedDay(label, length, dayIndex) {
            const index = dayIndex + 1;
            let text = label;
            if (Number(length) !== Number(index)) {
                text = `${text},`;
            }
            return text;
        },
        isWeekdays(days) {
            return days && days.length === 5 && days.every(({ label }) => label !== 'Sun' && label !== 'Sat');
        },
        isWeekends(days) {
            return days && days.length === 2 && days.every(({ label }) => label === 'Sun' || label === 'Sat');
        },
        // Gets the user-friendly text to display on the screen given a from-to date range.
        getDatesText(fromDate, toDate) {
            const fromDateFormatted = fromDate ? fromDate.format(DATE_TYPES.day) : null;
            const toDateFormatted = toDate ? toDate.format(DATE_TYPES.day) : null;

            let datesText = null;
            if (fromDateFormatted && toDateFormatted) {
                if (fromDateFormatted === toDateFormatted) {
                    datesText = `On ${fromDateFormatted}`;
                } else {
                    datesText = `From ${fromDateFormatted} until ${toDateFormatted}`;
                }
            } else if (fromDateFormatted) {
                datesText = `From ${fromDateFormatted}`;
            } else if (toDateFormatted) {
                datesText = `Until ${toDateFormatted}`;
            }

            return datesText;
        },
        // Determines if the dates within the from-to range match the days for at least one instance.
        getDatesMatchDays(fromDate, toDate, days) {
            let datesMatchDays = true;

            if (fromDate && toDate && days && days.length) {
                datesMatchDays = false;
                const datesDays = this.getDateRange(fromDate, toDate).map((d) => d.format('ddd'));
                const datesDaysDistinct = [...new Set(datesDays)];
                const daysDays = days.map((d) => d.label);

                for (let i = 0; i < datesDaysDistinct.length; i++) {
                    if (daysDays.indexOf(datesDaysDistinct[i]) !== -1) {
                        datesMatchDays = true;
                        break;
                    }
                }
            }

            return datesMatchDays;
        },
        // Gets an array of moment dates between two moment dates, inclusive.
        getDateRange(startDate, endDate) {
            const diff = endDate.diff(startDate, 'days');

            return Array.from({ length: diff + 1 }, (x) => moment(startDate).add(x, 'days'));
        }
    },
    mounted() {
        this.validFromDateLocal = this.rule.validFromDate ? moment.parseZone(this.rule.validFromDate).local() : null;
        this.validToDateLocal = this.rule.validToDate ? moment.parseZone(this.rule.validToDate).local() : null;
    }
};
</script>

<style lang="scss" scoped>
.d-flex {
    display: flex;
}

.days-text-icon {
    align-self: center;
    margin: 0px 8px;

    &.md-warning > i {
        color: #ff9800 !important; // this is the same "warning" color used elsewhere in the site
    }
}
</style>
