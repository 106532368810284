var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.isUpdate ? "Update" : "Create") + " Run Shipment"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body shipment-form-modal" },
      [
        _c(
          "form-wrapper",
          {
            staticClass: "form-wrapper",
            attrs: { validator: _vm.$v.runShipmentDetails },
          },
          [
            _c(
              "tabs",
              {
                staticClass: "custom-tab-list",
                attrs: {
                  "tab-name": [
                    "Shipment",
                    "Pickup Options",
                    "Drop off Options",
                  ],
                  "color-button": "warning",
                },
              },
              [
                _c("template", { slot: "tab-pane-1" }, [
                  _c(
                    "div",
                    { staticClass: "shipment-form-container" },
                    [
                      _c("charging-type-options", {
                        attrs: {
                          "update-stop": true,
                          "selected-charge-type-id":
                            _vm.runShipmentDetails.rateGroupId,
                        },
                        on: { selectedOption: _vm.handleRateTypeChanged },
                      }),
                      _c("div", { staticClass: "stop-container" }, [
                        _c(
                          "div",
                          [
                            _c("google-autocomplete", {
                              attrs: {
                                label: "Pickup",
                                id: "pautoaddress",
                                classname: "form-control autocomplete-input",
                                placeholder: "",
                                "should-focus": false,
                                "disable-gps-coordinates": false,
                                "gps-coordinate-placeholder":
                                  "Enter a name for the pickup stop",
                                name: "pickupRunScheduleStop.address",
                              },
                              on: {
                                handleCoordinates: _vm.handlePickupCoordinates,
                                change: _vm.handleShipmentDetailsChanged,
                              },
                              model: {
                                value:
                                  _vm.runShipmentDetails.pickupRunScheduleStop,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.runShipmentDetails,
                                    "pickupRunScheduleStop",
                                    $$v
                                  )
                                },
                                expression:
                                  "runShipmentDetails.pickupRunScheduleStop",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "stop-container" }, [
                        _c(
                          "div",
                          [
                            _c("google-autocomplete", {
                              attrs: {
                                label: "Drop off",
                                id: "dautoaddress",
                                classname: "form-control autocomplete-input",
                                placeholder: "",
                                "should-focus": false,
                                "disable-gps-coordinates": false,
                                "gps-coordinate-placeholder":
                                  "Enter a name for the drop stop",
                                name: "dropRunScheduleStop.address",
                              },
                              on: {
                                handleCoordinates: _vm.handleDropCoordinates,
                                change: _vm.handleShipmentDetailsChanged,
                              },
                              model: {
                                value:
                                  _vm.runShipmentDetails.dropRunScheduleStop,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.runShipmentDetails,
                                    "dropRunScheduleStop",
                                    $$v
                                  )
                                },
                                expression:
                                  "runShipmentDetails.dropRunScheduleStop",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "contact-details" }, [
                        _c("span", [_vm._v("Contact Details")]),
                        _c(
                          "div",
                          { staticClass: "contact-details" },
                          [
                            _c("customer-autocomplete", {
                              attrs: {
                                label:
                                  _vm.runShipmentDetails.customerId == null
                                    ? "Name"
                                    : "Customer",
                                id: "autocustomerShipment",
                                placeholder: "",
                                "should-focus": false,
                                "auto-fill-address": true,
                                "update-customer": !_vm.isUpdate,
                                disabled: _vm.customerNameIsDisabled(),
                              },
                              on: {
                                changeCustomerRateGroup:
                                  _vm.handleCustomerChangeRateGroup,
                              },
                              model: {
                                value: _vm.runShipmentDetails,
                                callback: function ($$v) {
                                  _vm.runShipmentDetails = $$v
                                },
                                expression: "runShipmentDetails",
                              },
                            }),
                            _vm.runShipmentDetails.customerId != null
                              ? _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "name",
                                      label: "Contact Name",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      model: {
                                        value:
                                          _vm.runShipmentDetails.contact.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.runShipmentDetails.contact,
                                            "name",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "runShipmentDetails.contact.name",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "form-group",
                              { attrs: { name: "phone", label: "Phone" } },
                              [
                                _c("md-input", {
                                  staticClass: "shipment-contact",
                                  model: {
                                    value: _vm.runShipmentDetails.contact.phone,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.runShipmentDetails.contact,
                                        "phone",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "runShipmentDetails.contact.phone",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "form-group",
                              { attrs: { name: "email", label: "Email" } },
                              [
                                _c("md-input", {
                                  staticClass: "shipment-contact",
                                  model: {
                                    value: _vm.runShipmentDetails.contact.email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.runShipmentDetails.contact,
                                        "email",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "runShipmentDetails.contact.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "form-group",
                        { attrs: { name: "notes", label: "Notes" } },
                        [
                          _c("md-textarea", {
                            model: {
                              value: _vm.runShipmentDetails.notes,
                              callback: function ($$v) {
                                _vm.$set(_vm.runShipmentDetails, "notes", $$v)
                              },
                              expression: "runShipmentDetails.notes",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.shipmentCustomFieldList !== null
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.shipmentCustomFieldList,
                              function (customField, index) {
                                return _c("custom-field-inputs", {
                                  key: index,
                                  attrs: {
                                    "custom-field-definition": customField,
                                    "initial-value":
                                      _vm.customFieldValues[customField.name],
                                  },
                                  on: { changed: _vm.handleCustomFieldChanged },
                                })
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _vm.loadFieldList !== null
                        ? _c(
                            "div",
                            _vm._l(_vm.loadFieldList, function (load, index) {
                              return _c("load-inputs", {
                                key: index,
                                attrs: {
                                  "load-definition": load,
                                  "initial-value": _vm.loadValues,
                                },
                                on: { changed: _vm.handleLoadChanged },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm.skillOptions.length
                        ? _c(
                            "form-group",
                            { attrs: { name: "skills", label: "Skills" } },
                            [
                              _c("multiselect", {
                                attrs: {
                                  options: _vm.skillOptions,
                                  multiple: true,
                                  "close-on-select": true,
                                  placeholder: "Pick required skills",
                                },
                                model: {
                                  value: _vm.runShipmentDetails.skills,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.runShipmentDetails,
                                      "skills",
                                      $$v
                                    )
                                  },
                                  expression: "runShipmentDetails.skills",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("template", { slot: "tab-pane-2" }, [
                  _c(
                    "div",
                    { staticClass: "contact-details" },
                    [
                      _c("span", [_vm._v("Pickup Details")]),
                      _c("customer-autocomplete", {
                        attrs: {
                          label:
                            _vm.runShipmentDetails.pickupRunScheduleStop
                              .customerId == null
                              ? "Name"
                              : "Customer",
                          id: "autocustomerShipmentPickup",
                          placeholder: "",
                          "should-focus": false,
                          "auto-fill-address": true,
                        },
                        model: {
                          value: _vm.runShipmentDetails.pickupRunScheduleStop,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.runShipmentDetails,
                              "pickupRunScheduleStop",
                              $$v
                            )
                          },
                          expression:
                            "runShipmentDetails.pickupRunScheduleStop",
                        },
                      }),
                      _vm.runShipmentDetails.pickupRunScheduleStop.customerId !=
                      null
                        ? _c(
                            "form-group",
                            { attrs: { name: "name", label: "Contact Name" } },
                            [
                              _c("md-input", {
                                model: {
                                  value:
                                    _vm.runShipmentDetails.pickupRunScheduleStop
                                      .contact.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.runShipmentDetails
                                        .pickupRunScheduleStop.contact,
                                      "name",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "runShipmentDetails.pickupRunScheduleStop.contact.name",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "form-group",
                        { attrs: { name: "phone", label: "Phone" } },
                        [
                          _c("md-input", {
                            model: {
                              value:
                                _vm.runShipmentDetails.pickupRunScheduleStop
                                  .contact.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.runShipmentDetails.pickupRunScheduleStop
                                    .contact,
                                  "phone",
                                  $$v
                                )
                              },
                              expression:
                                "runShipmentDetails.pickupRunScheduleStop.contact.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "form-group",
                        { attrs: { name: "email", label: "Email" } },
                        [
                          _c("md-input", {
                            model: {
                              value:
                                _vm.runShipmentDetails.pickupRunScheduleStop
                                  .contact.email,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.runShipmentDetails.pickupRunScheduleStop
                                    .contact,
                                  "email",
                                  $$v
                                )
                              },
                              expression:
                                "runShipmentDetails.pickupRunScheduleStop.contact.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "form-group",
                        { attrs: { name: "notes", label: "Notes" } },
                        [
                          _c("md-textarea", {
                            model: {
                              value:
                                _vm.runShipmentDetails.pickupRunScheduleStop
                                  .notes,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.runShipmentDetails.pickupRunScheduleStop,
                                  "notes",
                                  $$v
                                )
                              },
                              expression:
                                "runShipmentDetails.pickupRunScheduleStop.notes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "shipment-datepicker" }, [
                    _c("div", { staticClass: "stop-container" }, [
                      _c(
                        "div",
                        { staticClass: "trip-settings" },
                        [
                          _c(
                            "form-group",
                            {
                              staticClass: "time-picker",
                              attrs: { name: "time", label: "Time" },
                            },
                            [
                              _c("time-picker", {
                                attrs: {
                                  time: _vm.pickupTime,
                                  "additional-time-options":
                                    _vm.additionalPickupTimeOption,
                                },
                                on: { selectedTime: _vm.getPickupTime },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "trip-settings duration-setting" },
                        [
                          _c(
                            "form-group",
                            {
                              attrs: {
                                name: "pickupRunScheduleStop.durationMinutes",
                                label: "Duration (mins)",
                              },
                            },
                            [
                              _c("md-input", {
                                attrs: { type: "number" },
                                model: {
                                  value:
                                    _vm.runShipmentDetails.pickupRunScheduleStop
                                      .durationMinutes,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.runShipmentDetails
                                        .pickupRunScheduleStop,
                                      "durationMinutes",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "runShipmentDetails.pickupRunScheduleStop.durationMinutes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("template", { slot: "tab-pane-3" }, [
                  _c(
                    "div",
                    { staticClass: "contact-details" },
                    [
                      _c("span", [_vm._v("Drop off Details")]),
                      _c("customer-autocomplete", {
                        attrs: {
                          label:
                            _vm.runShipmentDetails.dropRunScheduleStop
                              .customerId == null
                              ? "Name"
                              : "Customer",
                          id: "autocustomerShipmentDrop",
                          placeholder: "",
                          "should-focus": false,
                          "auto-fill-address": true,
                        },
                        model: {
                          value: _vm.runShipmentDetails.dropRunScheduleStop,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.runShipmentDetails,
                              "dropRunScheduleStop",
                              $$v
                            )
                          },
                          expression: "runShipmentDetails.dropRunScheduleStop",
                        },
                      }),
                      _vm.runShipmentDetails.dropRunScheduleStop.customerId !=
                      null
                        ? _c(
                            "form-group",
                            { attrs: { name: "name", label: "Contact Name" } },
                            [
                              _c("md-input", {
                                model: {
                                  value:
                                    _vm.runShipmentDetails.dropRunScheduleStop
                                      .contact.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.runShipmentDetails.dropRunScheduleStop
                                        .contact,
                                      "name",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "runShipmentDetails.dropRunScheduleStop.contact.name",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "form-group",
                        { attrs: { name: "phone", label: "Phone" } },
                        [
                          _c("md-input", {
                            model: {
                              value:
                                _vm.runShipmentDetails.dropRunScheduleStop
                                  .contact.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.runShipmentDetails.dropRunScheduleStop
                                    .contact,
                                  "phone",
                                  $$v
                                )
                              },
                              expression:
                                "runShipmentDetails.dropRunScheduleStop.contact.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "form-group",
                        { attrs: { name: "email", label: "Email" } },
                        [
                          _c("md-input", {
                            model: {
                              value:
                                _vm.runShipmentDetails.dropRunScheduleStop
                                  .contact.email,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.runShipmentDetails.dropRunScheduleStop
                                    .contact,
                                  "email",
                                  $$v
                                )
                              },
                              expression:
                                "runShipmentDetails.dropRunScheduleStop.contact.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "form-group",
                        { attrs: { name: "notes", label: "Notes" } },
                        [
                          _c("md-textarea", {
                            model: {
                              value:
                                _vm.runShipmentDetails.dropRunScheduleStop
                                  .notes,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.runShipmentDetails.dropRunScheduleStop,
                                  "notes",
                                  $$v
                                )
                              },
                              expression:
                                "runShipmentDetails.dropRunScheduleStop.notes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "shipment-drop-datepicker" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "form-group",
                          {
                            staticClass: "time-picker",
                            attrs: { name: "time", label: "Time" },
                          },
                          [
                            _c("time-picker", {
                              attrs: {
                                time: _vm.dropTime,
                                "additional-time-options":
                                  _vm.additionalDropTimeOption,
                              },
                              on: { selectedTime: _vm.getDropTime },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "form-group",
                          {
                            attrs: {
                              name: "dropRunScheduleStop.durationMinutes",
                              label: "Duration (mins)",
                            },
                          },
                          [
                            _c("md-input", {
                              attrs: { type: "number" },
                              model: {
                                value:
                                  _vm.runShipmentDetails.dropRunScheduleStop
                                    .durationMinutes,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.runShipmentDetails.dropRunScheduleStop,
                                    "durationMinutes",
                                    $$v
                                  )
                                },
                                expression:
                                  "runShipmentDetails.dropRunScheduleStop.durationMinutes",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.validateRunShipment },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isUpdate ? "Update" : "Create") +
                "\n        "
            ),
          ]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }