import { render, staticRenderFns } from "./ActionsPanel.vue?vue&type=template&id=1738ea37&scoped=true&"
import script from "./ActionsPanel.vue?vue&type=script&lang=js&"
export * from "./ActionsPanel.vue?vue&type=script&lang=js&"
import style0 from "./ActionsPanel.vue?vue&type=style&index=0&id=1738ea37&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1738ea37",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\a\\1\\s\\Locate2u.AppPortal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1738ea37')) {
      api.createRecord('1738ea37', component.options)
    } else {
      api.reload('1738ea37', component.options)
    }
    module.hot.accept("./ActionsPanel.vue?vue&type=template&id=1738ea37&scoped=true&", function () {
      api.rerender('1738ea37', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/DispatchDashboard/components/ActionsPanel.vue"
export default component.exports