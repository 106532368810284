<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
            <ul class="filter-option-ui">
                <li v-for="f in data" :key="f.id">
                    <md-checkbox type="checkbox" :value="f.name" v-model="selectedSkillsValue" multiple>
                        {{ f.name }}
                    </md-checkbox>
                </li>
            </ul>
        </div>
        <div class="modal-footer">
            <md-button
                class="md-primary dialog-button"
                @click="applyFilter"
                :disabled="selectedSkillsValue.length === 0"
            >
                Apply
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterStopsBySkills',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        selectedSkillsValue: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        applyFilter() {
            this.resolve({ value: this.selectedSkillsValue });
        }
    }
};
</script>
<style lang="scss" scoped>
.modal-container {
    max-width: 250px;
}
.filter-option-ui {
    padding: 0;
    margin: 0;
    list-style-type: none;
    .md-checkbox.md-theme-default {
        margin: 8px 0px;
    }
}
</style>
