<template>
    <div class="filter-steps--container">
        <team-region-member-filter-options
            :team-region-id="teamRegionId"
            @changed="handleTeamRegionMemberChanged"
            :strict-team-region-filter="false"
            :clearable="false"
        ></team-region-member-filter-options>
    </div>
</template>

<script>
import { TeamRegionMemberFilterOptions } from '@/components';

export default {
    name: 'RunScheduleFilterComponent',
    props: {
        teamRegionId: { type: Number, default: null }
    },
    components: { TeamRegionMemberFilterOptions },
    methods: {
        handleTeamRegionMemberChanged(val) {
            this.$emit('onFilterChanged', {
                teamMemberPublicUserId: val.publicUserId,
                teamRegionId: val.teamRegionId
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-steps--container {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }
    .datepicker-container {
        width: 140px;
    }
    .filter-step-date {
        margin-right: 0;
        min-height: initial;
        position: absolute;
        z-index: 1;
        min-width: 250px;
        ::v-deep .md-clear {
            display: none;
        }
        ::v-deep .md-input {
            width: 100%;
        }
    }
    ::v-deep .md-datepicker:after,
    ::v-deep .md-datepicker:before {
        bottom: 0;
        height: 0;
    }
    ::v-deep .md-datepicker:before,
    ::v-deep .md-datepicker:after {
        bottom: 30px;
        content: 'Trip date';
        color: #aaaaaa;
        font-weight: 400;
        font-size: 14px;
        transform: none;
        z-index: 1;
    }
    ::v-deep .md-has-value:before,
    ::v-deep .md-has-value:after {
        bottom: 0;
        height: 0;
        content: '';
    }
    ::v-deep .md-datepicker .md-input {
        position: absolute;
        z-index: 9;
        width: 150px;
    }
    ::v-deep .md-datepicker .md-input-action {
        z-index: 99;
        right: 52px;
    }
}
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}
::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}
::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}
::v-deep .vs__search {
    cursor: pointer;
}
.datepicker-noevent {
    ::v-deep .md-datepicker {
        input {
            pointer-events: none;
        }
    }
}
</style>
