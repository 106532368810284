<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item md-size-100">
                <h5>{{ type }} Information:</h5>
            </div>
            <div class="md-layout-item md-size-100">
                <form-group name="name" label="Asset Name*">
                    <md-input v-model="data.name" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-100" v-if="assetCustomFieldList !== null">
                <custom-field-inputs
                    v-for="(customField, i) in assetCustomFieldList"
                    :key="i"
                    :custom-field-definition="customField"
                    @changed="handleCustomFieldChanged"
                    :initial-value="data[customField.label]"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CustomFieldInputs } from '@/components';

export default {
    name: 'GeneralStep',
    components: {
        CustomFieldInputs
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        type: {
            type: String,
            default: () => ''
        }
    },
    watch: {
        type(newValue, oldValue) {
            this.getUserCustomFields();
        }
    },
    data() {
        return {
            prevId: 0,
            assetCustomFieldList: []
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    mounted() {
        this.getUserCustomFields();
    },
    methods: {
        handleCustomFieldChanged({ name, value }) {
            this.data[name] = value;
        },
        getUserCustomFields() {
            if (this.user.assetCustomFieldDefinitions !== '' && this.user.assetCustomFieldDefinitions != null) {
                this.assetCustomFieldList = this.user.assetCustomFieldDefinitions.filter((item) => {
                    return item.assetType === this.type || item.assetType === 'Any';
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-disabled .md-list-item-text {
    color: #eee;
}
</style>
