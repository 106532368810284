<template>
    <div class="md-layout">
        <md-card class="no-border-card">
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-20">
                        <form-group label="Business Registration Name" name="businessRegistrationName">
                            <md-input v-model="entity.businessRegistrationName" />
                        </form-group>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <form-group label="Business Registration Number" name="businessRegistrationNumber">
                            <md-input v-model="entity.businessRegistrationNumber" />
                        </form-group>
                    </div>
                </div>
            </md-card-content>

            <md-card-actions md-alignment="left">
                <button class="custom-btn" @click="validateForm">
                    Save
                </button>
            </md-card-actions>
        </md-card>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'EntityList',
    mixins: [GeneralMixin],
    data() {
        return {
            entity: {
                businessRegistrationName: null,
                businessRegistrationNumber: null
            }
        };
    },
    async mounted() {
        await this.getBusinessData();
    },
    methods: {
        async getBusinessData() {
            const api = '/api/teams/settings/entity';
            const { data } = await handleRequests(api);
            this.entity = data;
        },
        async validateForm() {
            this.$_handleLoaderState(true, 'SAVING...');

            const payload = {
                method: 'put',
                data: this.entity
            };

            const api = '/api/teams/settings/entity';
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Business entity details saved!',
                    type: 'success'
                });
                this.$_handleLoaderState(false);
            } catch (e) {
                this.$_handleLoaderState(false);
                const message = 'Cannot save business entity settings.';
                showErrorMessage(this, message, e);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.no-border-card {
    border: 0px !important;
    box-shadow: none;
    margin: 0;
}

::v-deep .md-card .md-card-actions {
    border-top: none;
}
</style>
