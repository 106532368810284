<template>
    <div class="table-data-tooltip-container">
        <div>
            <b>{{ headerText }}</b>
        </div>
        <div>
            <table>
                <tr v-for="(row, i) in tableRows" :key="i + 'row-tooltip'">
                    <td>{{ row[0] }}</td>
                    <td>{{ row[1] }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GridKeyValueDataTooltip',
    data() {
        return {
            tableRows: [],
            headerText: ''
        };
    },
    beforeMount() {
        this.headerText = this.params.headerText ?? '';
        this.tableJsonField = this.params.tableJsonField ?? '';

        if (this.tableJsonField !== '') {
            const data = this.params.data[this.tableJsonField];

            if (data) {
                const dataObj = JSON.parse(data);

                this.tableRows = [];

                Object.keys(dataObj).forEach((key) => {
                    this.tableRows.push([key, dataObj[key]]);
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.table-data-tooltip-container {
    border-radius: 5px;
    border: #333 1px solid;
    background-color: #eee;
    padding: 5px;
}
</style>
