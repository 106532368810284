<template>
    <div class="teams-autocomplete">
        <form-wrapper :validator="$v" class="form-wrapper">
            <form-group name="selectedAutocomplete" :label="label" attribute="Team Name">
                <md-input
                    ref="teamsAutocomplete"
                    type="text"
                    v-model="autocompleteText"
                    @focus="onFocus($event)"
                    @focusout="onFocusout($event)"
                    @blur="blur"
                    @change="onChange"
                    @keyup.down="onArrowDown"
                    @keyup.up="onArrowUp"
                    @keyup.enter="onEnter"
                    :required="isRequired"
                    v-focus="shouldFocus"
                />
            </form-group>
        </form-wrapper>
        <div class="autocomplete">
            <ul
                id="autocomplete-results"
                v-show="isOpen && autocompleteList.length"
                class="autocomplete-results"
                ref="autocompleteContainer"
            >
                <li class="loading" v-if="isAutoCompleteLoading">Loading results...</li>
                <li
                    ref="autocompleteOptions"
                    v-else
                    v-for="(result, i) in autocompleteList"
                    :key="result.TeamId"
                    @click="setAutocompleteResult(result)"
                    class="autocomplete-result"
                    :class="{ 'is-active': i === arrowCounter }"
                >
                    <span>
                        {{ getDisplayText(result) }}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';

export default {
    name: 'TeamsAutocomplete',
    props: {
        classname: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: 'Name'
        },
        icon: {
            type: String,
            default: ''
        },
        shouldFocus: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default() {
                return { TeamId: null };
            }
        },
        isRequired: {
            type: Boolean,
            default: true
        }
    },
    validations: {
        selectedAutocomplete: {
            required: (value, vm) => {
                if (!vm.isRequired) 
                    return true;
                if (vm.selectedAutocomplete === null) 
                    return false;
                return true;
            }
        }
    },
    data() {
        return {
            autocompleteText: null,
            autocompleteList: [],
            isOpen: false,
            isAutoCompleteLoading: false,
            arrowCounter: 0,
            selectedAutocomplete: null,
            autocompleteSetFromApp: false
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    watch: {
        // eslint-disable-next-line func-names
        autocompleteText: _.debounce(function() {
            if (this.selectedAutocomplete !== null && !this.autocompleteSetFromApp) 
                this.selectedAutocomplete = null;
            if (this.selectedAutocomplete === null) 
                this.getTeamsList();

            this.autocompleteSetFromApp = false;
        }, 500)
    },
    async mounted() {
        // This is never called the way we use the Autocomplete box but we leave it for consistency with the other autocompletes.
        // In any case, someone can set in the teamId of the model a value and let that triggered.
        if (this.value.teamId != null) {
            await this.getTeamDetails(this.value.teamId);
            this.update(this.getDisplayText(this.selectedAutocomplete));
        } else {
            this.update('');
        }
    },
    methods: {
        callTouch() {
            this.$v.$touch();
        },
        onFocus(e) {
            // for selecting the whole input field
            setTimeout(() => e.target.select(), 0);
            this.$emit('focus');
        },
        onFocusout(e) {
            setTimeout(() => {
                if (this.selectedAutocomplete === null) {
                    this.setAutocompleteResult(null);
                } else {
                    this.isOpen = false;
                }
                this.arrowCounter = -1;
            }, 300);
        },
        onChange() {
            this.$emit('change', this.autocompleteText);
        },
        focus() {
            this.$refs.teamsAutocomplete.$el.focus();
        },
        blur() {
            this.$refs.teamsAutocomplete.$el.blur();
            // a timeout is needed since when an autocomplete option is selected it is synchronous with the setAutoCompleteResult function
            // we need to make sure that setAutocompleteResult runs first before we close the autocomplete selection.
            // This is also used when users use the tab key to go to the next input. We need to close the autocomplete selection.
            setTimeout(() => {
                this.isOpen = false;
            }, 300);
        },
        update(value) {
            this.autocompleteSetFromApp = true;
            this.autocompleteText = value;
        },
        setAutocompleteResult(data) {
            if (data != null) {
                this.selectedAutocomplete = data;
                this.update(this.getDisplayText(data));
            } else {
                this.selectedAutocomplete = null;
                this.update('');
            }
            const val = Object.assign({}, data);

            this.$emit('input', val);
            this.isOpen = false;
        },
        onArrowDown(evt) {
            if (this.arrowCounter < this.autocompleteList.length - 1) {
                this.arrowCounter = this.arrowCounter + 1;
                this.fixScrolling();
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
                this.fixScrolling();
            }
        },
        onEnter() {
            this.setAutocompleteResult(this.autocompleteList[this.arrowCounter]);
            this.arrowCounter = -1;
        },
        fixScrolling() {
            const optionHeight = this.$refs.autocompleteOptions[this.arrowCounter].clientHeight;
            this.$refs.autocompleteContainer.scrollTop = optionHeight * this.arrowCounter;
        },
        // eslint-disable-next-line func-names
        async getTeamsList() {
            this.isAutoCompleteLoading = true;
            const endpoint = `/api/support/teams`;

            const {
                data: { teams }
            } = await handleRequests(endpoint, {
                params: {
                    pageNumber: 1,
                    itemsPerPage: 50,
                    searchText: this.autocompleteText
                }
            });

            this.isOpen = true;
            this.autocompleteList = teams;
            this.isAutoCompleteLoading = false;
        },
        async getTeamDetails(teamId) {
            try {
                const api = `/api/support/teams/${teamId}`;
                const { data } = await handleRequests(api);
                this.selectedAutocomplete = data;
            } catch (error) {
                const message = 'Error in getting the team details';
                showErrorMessage(this, message, error);
            }
        },
        getDisplayText(data) {
            if (!data) 
                return '';
            if (data.company) 
                return data.status === 'Disabled' ? `${data.company} (${data.status})` : data.company;
            return `${data.matchedUserFullName} ( ${data.matchedUserEmail} )`;
        }
    }
};
</script>

<style lang="scss" scoped>
.teams-autocomplete {
    position: relative;
    width: 100%;
}

.autocomplete {
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    top: 40px;
}

.autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    overflow: auto;
    width: 100%;
}

.autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
    background-color: #4aae9b;
    color: white;
}
</style>
