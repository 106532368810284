<template>
    <div class="modal-container" :class="$root.isMobileOnly ? 'modal-dialog-full-width' : ''">
        <div class="modal-header">
            <h4 class="modal-title">Reassign Trip</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <team-region-member-controls
                :team-region-id="teamRegionId"
                :public-user-id="form.assignedUser"
                :carrier-team-id="form.assignedCarrierTeamId"
                @onChanged="handleTeamRegionMemberChanged"
                mode="update"
                :show-team-region="false"
                :strict-team-region-filter="false"
                :show-carriers="true"
            />
            <div class="date-picker-div hide-clear-button">
                <md-datepicker
                    ref="mdDatePicker"
                    v-model="selectedDate"
                    md-immediately
                    :md-disabled-dates="disabledDates"
                    :md-debounce="10"
                    :clearable="false"
                >
                    <label>Trip Date</label>
                </md-datepicker>
                <span v-if="tripDate == null || !isTripDateValid" class="error">Trip date is invalid.</span>
            </div>
            <div class="run-inputs" v-for="(run, index) in runs" :key="index">
                <form-group name="runNumber" label="Run">
                    <md-input
                        :readonly="true"
                        v-model.number="run.runNumber"
                        placeholder="No Run"
                        type="number"
                        min="1"
                    />
                </form-group>
                <form-group name="runNumber" label="New Run">
                    <md-input
                        v-model="run.newRunNumber"
                        @input="($value) => (run.newRunNumber = parseFloat($value) || null)"
                        placeholder="Optional"
                        type="number"
                        min="1"
                    />
                </form-group>
            </div>
        </div>
        <div class="modal-footer" v-if="!$root.isMobileOnly">
            <md-button class="md-primary dialog-button" @click="assignTrip">Assign</md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import moment from 'moment';
import { DATE_TYPES } from '@/utils/constants';
import { TeamRegionMemberControls } from '@/components';
import { mapGetters } from 'vuex';

export default {
    name: 'ReassignTripModal',
    mixins: [GeneralMixin],
    components: { TeamRegionMemberControls },
    props: {
        teamMembers: {
            type: Array,
            default: () => []
        },
        tripId: {
            type: Number,
            default: null
        },
        assignedTo: {
            type: String,
            default: ''
        },
        assignedCarrierTeamId: {
            type: [String, Number],
            default: ''
        },
        tripDate: {
            type: String,
            default: null
        },
        teamRegionId: {
            type: Number,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            form: {
                assignedUser: null,
                assignedCarrierTeamId: null
            },
            disabledDates: (date) => {
                const x = moment(date).format(DATE_TYPES.internationalDate);
                const now = moment().format(DATE_TYPES.internationalDate);
                return !moment(x).isSameOrAfter(now, 'day');
            },
            isTripDateValid: true,
            isLoading: true,
            dateToday: moment().format(DATE_TYPES.internationalDate),
            selectedDate: null,

            runs: []
        };
    },
    computed: {
        ...mapGetters({
            teamRegions: 'team/teamRegions',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            allTeamMembers: 'team/teamMembers',
            allTeamCarriers: 'team/teamCarriers'
        })
    },
    watch: {
        selectedDate(newValue) {
            if (moment(newValue).isBefore(this.dateToday)) {
                this.isTripDateValid = false;
            } else {
                this.isTripDateValid = true;
            }
        }
    },

    async mounted() {
        this.updateSelectedMember();

        if (this.tripDate != null) {
            this.selectedDate = this.tripDate;
        }
        await this.getRuns();
    },
    methods: {
        async assignTrip() {
            try {
                if (this.selectedDate == null || !this.isTripDateValid) {
                    this.isTripDateValid = false;
                    return;
                }

                this.$_handleLoaderState(true, 'PROCESSING...');
                const api = `/api/trips/${this.tripId}/assign-trip`;
                const data = {
                    publicUserId: this.form.assignedUser,
                    carrierTeamId: this.form.assignedCarrierTeamId,
                    tripDate: moment(this.selectedDate).format(DATE_TYPES.internationalDate),
                    reassignRuns: this.runs
                };
                const payload = {
                    method: 'put',
                    data
                };

                const response = await handleRequests(api, payload);

                if (response != null && response.data.length) {
                    const { data } = response;
                    data.forEach((item) => {
                        item.errors.forEach((e) => {
                            this.$notify({
                                message: e.message,
                                type: 'danger'
                            });
                        });
                    });
                    this.resolve({ code: 'error', value: '' });
                    return;
                }

                let msg = 'Trip was set to unassigned';
                if (this.form.assignedUser != null) {
                    const name = this.allTeamMembers.find((member) => member.publicUserId === this.form.assignedUser)
                        .fullName;
                    msg = `Trip was assigned to ${name}`;
                } else if (this.form.assignedCarrierTeamId != null) {
                    const name = this.allTeamCarriers.find(
                        (member) => member.carrierTeamId === this.form.assignedCarrierTeamId
                    ).carrierTeam.company;
                    msg = `Trip was assigned to ${name}`;
                }

                this.$notify({
                    message: msg,
                    type: 'success'
                });

                this.resolve({ code: 'ok', value: this.form.assignedUser });
            } catch (error) {
                const message = 'Cannot assign stop to user.';
                showErrorMessage(this, message, error);
                this.resolve({ code: 'error', value: '' });
            }
            this.$_handleLoaderState(false);
        },
        updateSelectedMember() {
            let assignedUser = null;
            if (this.assignedTo) {
                assignedUser = this.allTeamMembers.find((member) => {
                    return member.publicUserId === this.assignedTo;
                });
            }

            this.form = {
                assignedUser: assignedUser ? assignedUser.publicUserId : null,
                assignedCarrierTeamId: this.assignedCarrierTeamId || null
            };
        },
        async getRuns() {
            const api = `/api/trips/${this.tripId}/runs`;
            const payload = {
                method: 'get'
            };
            const { data } = await handleRequests(api, payload);
            this.runs.push(...data);
        },
        handleTeamRegionMemberChanged(val) {
            this.form.assignedUser = val && val.publicUserId ? val.publicUserId : null;
            this.form.assignedCarrierTeamId = val && val.carrierTeamId ? val.carrierTeamId : null;
        }
    }
};
</script>
<style lang="scss" scoped>
.run-inputs {
    display: flex;
    > div {
        width: 45%;
        margin-right: 10px;
    }
}
</style>
