import { render, staticRenderFns } from "./UserMenu.vue?vue&type=template&id=545e28df&scoped=true&"
import script from "./UserMenu.vue?vue&type=script&lang=js&"
export * from "./UserMenu.vue?vue&type=script&lang=js&"
import style0 from "./UserMenu.vue?vue&type=style&index=0&id=545e28df&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545e28df",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\a\\1\\s\\Locate2u.AppPortal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('545e28df')) {
      api.createRecord('545e28df', component.options)
    } else {
      api.reload('545e28df', component.options)
    }
    module.hot.accept("./UserMenu.vue?vue&type=template&id=545e28df&scoped=true&", function () {
      api.rerender('545e28df', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Dashboard/Layout/Extra/UserMenu.vue"
export default component.exports