<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Log Details</h4>
        </div>
        <div class="modal-body">
            <div>
                <span>Date:</span>
                <div>{{ logData.logDate | dateFormat(DATE_TYPES.standardDate) }}</div>
            </div>
            <div>
                <span>Type:</span>
                <div>
                    <span class="generic-badge" :class="`status-${$_getAssetLogTypeBadgeColor(logData.logType)}`">
                        {{ $t('assetLogTypes.' + logData.logType) }}
                    </span>
                </div>
            </div>
            <div>
                <span>Sub Type:</span>
                <div>
                    <span
                        class="generic-badge"
                        :class="`status-${$_getAssetLogSubTypeBadgeColor(logData.logType, logData.logSubType)}`"
                    >
                        {{ $t('assetLogSubTypes.' + logData.logSubType) }}
                    </span>
                </div>
            </div>
            <div v-if="logData.logType != 'insurance' && logData.logType != 'vehicle-registration'">
                <span>Engine hours:</span>
                <div>
                    {{ logData.startingEngineHours }}
                    <span v-if="logData.startingEngineHours">hours</span>
                </div>
            </div>
            <div
                v-if="
                    logData.logType != 'insurance' &&
                        logData.logType != 'vehicle-registration' &&
                        logData.startingOdometer != null
                "
            >
                <span>Odometer Reading:</span>
                <div>{{ logData.startingOdometer }} {{ logData.odometerUnitType }}</div>
            </div>

            <div>
                <span>Total cost:</span>
                <div>
                    {{
                        logData.cost != null
                            ? logData.costCurrency == null
                                ? this.defaultCurrency
                                : logData.costCurrency
                            : null
                    }}
                    {{ logData.cost }}
                </div>
            </div>
            <div>
                <span>Location:</span>
                <div>{{ logData.startAddressName }}</div>
            </div>
            <div>
                <span>Notes:</span>
                <div>{{ logData.notes }}</div>
            </div>
        </div>
        <div class="modal-footer">
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Close</md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin, AssetManagementMixin } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
    name: 'AssetLogDetailsModal',
    mixins: [GeneralMixin, AssetManagementMixin],
    props: {
        logData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            defaultCurrency: null
        };
    },
    computed: {
        ...mapGetters({
            getGeneralSettings: 'generalSetting/getGeneralSettings'
        })
    },
    mounted() {
        if (this.getGeneralSettings.defaultCurrency != null) {
            this.defaultCurrency = this.getGeneralSettings.defaultCurrency;
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 500px;
}

.modal-body {
    max-height: 70vh;
    overflow: auto;
    span {
        font-weight: 500;
        min-width: 145px;
        display: inline-block;
    }
    > div {
        margin-bottom: 10px;
        > div {
            display: inline-block;
        }
    }
}
</style>
