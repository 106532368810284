var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.isUpdate ? "Update" : "Add") + " Brand"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          {
            staticClass: "form-wrapper",
            attrs: { validator: _vm.$v.brandDetails },
          },
          [
            _c("div", { staticClass: "form-container" }, [
              _c(
                "div",
                { staticClass: "contact-details" },
                [
                  _c(
                    "form-group",
                    {
                      attrs: {
                        name: "name",
                        label: "Brand name",
                        messages: { required: "Brand name is required." },
                      },
                    },
                    [
                      _c("md-input", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        attrs: { id: "brandName" },
                        model: {
                          value: _vm.brandDetails.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.brandDetails, "name", $$v)
                          },
                          expression: "brandDetails.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", [
                _c("div", { staticClass: "upload-container" }, [
                  !_vm.logoPreview
                    ? _c("img", {
                        staticClass: "profile-image",
                        attrs: {
                          src:
                            _vm.logoPreview !== null
                              ? _vm.logoPreview
                              : "/img/logos/logo2-placeholder.jpg",
                          alt: "",
                        },
                        on: { error: _vm.$_setDefaultBrokenImage },
                      })
                    : _vm._e(),
                  _vm.logoPreview
                    ? _c("img", {
                        staticClass: "profile-image",
                        attrs: { src: _vm.logoPreview, alt: "" },
                        on: { error: _vm.$_setDefaultBrokenImage },
                      })
                    : _vm._e(),
                  _c("div", [
                    _c("input", {
                      ref: "logoInput",
                      attrs: {
                        hidden: "",
                        type: "file",
                        accept: "image/x-png,image/jpeg,image/jpg,image/png",
                      },
                      on: { change: _vm.onLogoSelected },
                    }),
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "custom-btn",
                          on: {
                            click: function ($event) {
                              return _vm.$refs.logoInput.click()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.isUpdate ? "Update" : "Upload") +
                              " brand logo\n                                "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.addUpdateBrand($event)
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isUpdate ? "Update" : "Add") +
                "\n        "
            ),
          ]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }