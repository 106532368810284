<template>
    <md-tooltip class="custom-tooltip" md-direction="top">
        <slot></slot>
    </md-tooltip>
</template>
<script>
export default {
    name: 'CustomTooltip'
};
</script>
<style scoped lang="scss">
.custom-tooltip {
    max-width: 600px;
}
</style>
