<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Distance Setting</h4>
        </div>
        <div class="modal-body md-layout">
            <div class="md-layout-item md-size-75">
                <form-group name="radiusValue" label="Maximum Valid Distance From Pickup Location">
                    <md-input type="number" v-model="radiusValue" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-25">
                <form-group>
                    <md-select v-model="radiusUnits">
                        <md-option value="KM">km</md-option>
                        <md-option value="MILES">miles</md-option>
                    </md-select>
                </form-group>
            </div>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="saveDistanceSetting">
                Save
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'DistanceSettingsModal',
    props: {
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            radiusValue: 300,
            radiusUnits: 'KM'
        };
    },
    computed: {
        ...mapGetters({
            maxRadiusValue: 'routingSetting/maxRadiusValue',
            maxRadiusUnits: 'routingSetting/maxRadiusUnits'
        })
    },
    methods: {
        ...mapMutations({
            saveMaxRadiusValue: 'routingSetting/UPDATE_MAX_RADIUS_VALUE',
            saveMaxRadiusUnits: 'routingSetting/UPDATE_MAX_RADIUS_UNITS'
        }),
        saveDistanceSetting() {
            const radiusValue = parseFloat(this.radiusValue);
            const { radiusUnits } = this;
            const isChange = radiusValue !== this.maxRadiusValue || radiusUnits !== this.maxRadiusUnits;

            this.saveMaxRadiusValue(radiusValue);
            this.saveMaxRadiusUnits(radiusUnits);

            this.resolve({ isChange });
        }
    },
    mounted() {
        this.radiusValue = this.maxRadiusValue || this.radiusValue;
        this.radiusUnits = this.maxRadiusUnits || this.radiusUnits;
    }
};
</script>
<style lang="scss" scoped>
.modal-body {
    padding: 24px 10px 10px;
}
.modal-container {
    max-width: 370px;
}
</style>
