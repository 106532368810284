var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _vm.obdAlertModalHide !== true
      ? _c("div", { staticClass: "alert alert-info alert-z" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button", "aria-hidden": "true" },
              on: {
                click: function ($event) {
                  _vm.obdAlertModalHide = true
                },
              },
            },
            [_vm._v("\n            ×\n        ")]
          ),
          _c("span", [
            _vm._v(
              "\n            The safety reports are only available for OBD device data.\n        "
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c("div", { staticClass: "custom-toolbar md-layout" }, [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item custom-toolbar-start md-layout md-small-size-80 md-size-90",
              },
              [
                _vm.canViewTeamRegion
                  ? _c("team-region-member-filter-options", {
                      staticClass: "filter-inline",
                      attrs: {
                        "team-region-id": _vm.teamRegionId,
                        "show-team-member": false,
                        clearable: false,
                      },
                      on: { changed: _vm.handleTeamRegionMemberChanged },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c("date-range-filter", {
                      attrs: { "is-single-date": false },
                      on: { onChanged: _vm.onDateChanged },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "md-layout-item custom-toolbar-end" },
              [
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-primary md-just-icon md-round pull-right header-button",
                    attrs: { title: "Export Safety Summary Report" },
                    on: { click: _vm.onExportToExcel },
                  },
                  [_c("md-icon", [_vm._v("get_app")])],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-blue" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("summarize")])],
                    1
                  ),
                ]
              ),
              _c("md-card-content", [
                !_vm.reportDataList || _vm.reportDataList.length == 0
                  ? _c("div", [
                      _c("h3", [_vm._v("Safety Summary Report")]),
                      _c("p", [_vm._v("No results to display.")]),
                    ])
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "report-container" },
                        [
                          _c("safety-summary-report-details", {
                            attrs: { "member-list": _vm.reportDataList },
                          }),
                        ],
                        1
                      ),
                    ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }