var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam
    ? _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Settings"),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "   >    " +
                        _vm._s(_vm.$t("menus.setting.generalSettings"))
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100 member-container" },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-content",
                  [
                    _vm.hasTeam
                      ? _c(
                          "tabs",
                          {
                            staticClass: "custom-tab-list",
                            attrs: {
                              "tab-name": _vm.tabNames,
                              "color-button": "success",
                              "hide-empty-tabs": true,
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "tab-pane-1" },
                              [
                                _c(
                                  "form-wrapper",
                                  { attrs: { validator: _vm.$v.settings } },
                                  [
                                    _c("div", { staticClass: "default-tab" }, [
                                      _c("div", [
                                        _vm._v(
                                          "Default Stop Duration (in Minutes):"
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "form-group",
                                            {
                                              attrs: {
                                                name: "defaultStopDurationMinutes",
                                              },
                                            },
                                            [
                                              _c("md-input", {
                                                attrs: { type: "number" },
                                                model: {
                                                  value:
                                                    _vm.settings
                                                      .defaultStopDurationMinutes,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.settings,
                                                      "defaultStopDurationMinutes",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "settings.defaultStopDurationMinutes",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "default-tab" }, [
                                      _c("div", [
                                        _vm._v("Stop Duration Behaviour"),
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "form-group",
                                            {
                                              staticClass: "speed-unit-field",
                                              attrs: {
                                                name: "defaultSpeedUnit",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "md-inline-boxes",
                                                },
                                                [
                                                  _c(
                                                    "md-radio",
                                                    {
                                                      attrs: {
                                                        value: "Additive",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.settings
                                                            .stopDurationBehaviour,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.settings,
                                                            "stopDurationBehaviour",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "settings.stopDurationBehaviour",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Additive Stop Durations\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "md-radio",
                                                    {
                                                      attrs: {
                                                        value: "Combined",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.settings
                                                            .stopDurationBehaviour,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.settings,
                                                            "stopDurationBehaviour",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "settings.stopDurationBehaviour",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Combined Stop Durations\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "md-avatar",
                                                    [
                                                      _c(
                                                        "md-icon",
                                                        {
                                                          staticClass:
                                                            "icon-info",
                                                        },
                                                        [_vm._v("info")]
                                                      ),
                                                      _c(
                                                        "md-tooltip",
                                                        {
                                                          staticClass:
                                                            "tooltip-width",
                                                          attrs: {
                                                            "md-direction":
                                                              "right",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    When you have multiple stops at the same address, this setting\n                                                    will determine the combined stop duration. You may want the stop\n                                                    durations to be added together, or combined into a single stop\n                                                    duration. For example, with 3 stops with a 5 minute duration\n                                                    each, you can choose to add these together (total: 15 minutes),\n                                                    or they can be combined (a total of 5 minutes).\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "default-tab" }, [
                                      _c("div", [
                                        _vm._v("Default speed unit:"),
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "form-group",
                                            {
                                              staticClass: "speed-unit-field",
                                              attrs: {
                                                name: "defaultSpeedUnit",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "md-inline-boxes",
                                                },
                                                [
                                                  _c(
                                                    "md-radio",
                                                    {
                                                      attrs: { value: "kph" },
                                                      model: {
                                                        value:
                                                          _vm.settings
                                                            .defaultSpeedUnits,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.settings,
                                                            "defaultSpeedUnits",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "settings.defaultSpeedUnits",
                                                      },
                                                    },
                                                    [_vm._v("km")]
                                                  ),
                                                  _c(
                                                    "md-radio",
                                                    {
                                                      attrs: { value: "mph" },
                                                      model: {
                                                        value:
                                                          _vm.settings
                                                            .defaultSpeedUnits,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.settings,
                                                            "defaultSpeedUnits",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "settings.defaultSpeedUnits",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                miles\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "default-tab" }, [
                                      _c("div", [
                                        _vm._v(
                                          "Include Proof Of Delivery In Email:"
                                        ),
                                      ]),
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "md-inline-boxes" },
                                          [
                                            _c("md-switch", {
                                              model: {
                                                value:
                                                  _vm.settings
                                                    .includeProofOfDeliveryInEmail,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "includeProofOfDeliveryInEmail",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "settings.includeProofOfDeliveryInEmail",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                    _vm.isAdmin
                                      ? _c(
                                          "div",
                                          { staticClass: "default-tab" },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                "Enable Two Factor Authentication:"
                                              ),
                                            ]),
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "md-inline-boxes",
                                                },
                                                [
                                                  _c("md-switch", {
                                                    model: {
                                                      value:
                                                        _vm.settings
                                                          .isTwoFAEnabled,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.settings,
                                                          "isTwoFAEnabled",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "settings.isTwoFAEnabled",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("template", { slot: "tab-pane-2" }, [
                              _vm.oldJobOffersEnabled
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "md-layout custom-switch",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "md-layout-item md-size-15 md-form-label",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    Enable Offer Notifications:\n                                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "md-layout-item md-inline-boxes",
                                          },
                                          [
                                            _c("md-switch", {
                                              model: {
                                                value:
                                                  _vm.settings
                                                    .offerNotification,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "offerNotification",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "settings.offerNotification",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "md-layout" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "md-layout-item md-size-15 md-form-label",
                                        },
                                        [_vm._v("Alert Type:")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-inline-boxes",
                                        },
                                        [
                                          _c(
                                            "md-radio",
                                            {
                                              attrs: { value: "Default" },
                                              model: {
                                                value:
                                                  _vm.settings.alertTypeScheme,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "alertTypeScheme",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "settings.alertTypeScheme",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Default Communication\n                                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-radio",
                                            {
                                              attrs: {
                                                value: "PushNotification",
                                              },
                                              model: {
                                                value:
                                                  _vm.settings.alertTypeScheme,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "alertTypeScheme",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "settings.alertTypeScheme",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Push Notification\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "md-layout" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "md-layout-item md-size-15 md-form-label",
                                        },
                                        [_vm._v("Offer Order:")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-inline-boxes",
                                        },
                                        [
                                          _c(
                                            "md-radio",
                                            {
                                              attrs: { value: "Nearest" },
                                              model: {
                                                value:
                                                  _vm.settings
                                                    .prioritizationScheme,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "prioritizationScheme",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "settings.prioritizationScheme",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Nearest\n                                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-radio",
                                            {
                                              attrs: { value: "Random" },
                                              model: {
                                                value:
                                                  _vm.settings
                                                    .prioritizationScheme,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.settings,
                                                    "prioritizationScheme",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "settings.prioritizationScheme",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Random\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "md-layout" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "md-layout-item md-size-15 md-form-label",
                                        },
                                        [_vm._v("Offer Within Radius:")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-size-10 units-input",
                                        },
                                        [
                                          _c(
                                            "md-field",
                                            [
                                              _c("md-input", {
                                                staticClass: "input-width",
                                                attrs: { type: "number" },
                                                model: {
                                                  value:
                                                    _vm.settings
                                                      .offerWithinDistance,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.settings,
                                                      "offerWithinDistance",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "settings.offerWithinDistance",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-size-10",
                                        },
                                        [
                                          _c(
                                            "md-field",
                                            [
                                              _c(
                                                "md-select",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.settings
                                                        .preferredDistanceUnits,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.settings,
                                                        "preferredDistanceUnits",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "settings.preferredDistanceUnits",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "md-option",
                                                    { attrs: { value: "kph" } },
                                                    [_vm._v("km")]
                                                  ),
                                                  _c(
                                                    "md-option",
                                                    { attrs: { value: "mph" } },
                                                    [_vm._v("miles")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("md-card-actions", { attrs: { "md-alignment": "left" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "custom-btn",
                      on: { click: _vm.validateForm },
                    },
                    [_vm._v("Save")]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }