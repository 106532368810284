<template>
    <div>
        <md-card-content>
            <md-table :value="list" class="table-hover">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Team Member/ Asset Group" md-sort>
                        {{ item.trackerId ? item.assetGroupName : item.teamMemberFullName }}
                    </md-table-cell>
                    <md-table-cell md-label="Geofence" md-sort>
                        {{ toGeofenceNameDesc(item) }}
                    </md-table-cell>
                    <md-table-cell md-label="Action">
                        {{ item.action }}
                    </md-table-cell>
                    <md-table-cell md-label="Date/Time">
                        {{ item.actionDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </md-card-content>
    </div>
</template>

<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins';

export default {
    name: 'GeofenceReportDetails',
    components: {},
    mixins: [GeneralMixin],
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {};
    },
    methods: {
        displayDateTime(date, utcOffset) {
            if (date) 
                return moment(date).utcOffset(utcOffset);
            return '';
        },
        toGeofenceNameDesc(item) {
            if (item.geofenceStatus) {
                return `${item.geofenceName} (${item.geofenceStatus})`;
            }
            return item.geofenceName;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

::v-deep .md-table-head {
    cursor: default;
}

.table-hover {
    cursor: pointer;
}
</style>
