<template>
    <div class="modal-container">
        <div class="modal-header">
            Send SMS
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <span>To: {{ name }}</span>
            <form-wrapper :validator="$v.form" class="form-wrapper">
                <form-group class="custom-textarea" name="message">
                    <md-textarea v-model="form.message" ref="message" />
                </form-group>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="sendSms">
                Send
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'SendSmsModal',
    mixins: [GeneralMixin],
    props: {
        name: {
            type: String,
            default: null
        },
        phone: {
            type: String,
            default: null
        },
        countryCode: {
            type: String,
            default: null
        },
        publicUserId: {
            type: String,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            form: {
                message: ''
            }
        };
    },
    methods: {
        async sendSms() {
            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                this.$_handleLoaderState(true, 'SENDING...');
                const payload = {
                    method: 'post',
                    data: {
                        message: this.form.message,
                        phone: this.phone,
                        countryCode: this.countryCode,
                        publicUserId: this.publicUserId
                    }
                };
                const apiUrl = `/api/sms/send`;
                try {
                    await handleRequests(apiUrl, payload);
                    this.$notify({
                        message: 'SMS sent!',
                        type: 'success'
                    });
                    this.$v.$reset();
                    this.resolve('ok');
                } catch (e) {
                    const message = 'Unable to send SMS.';
                    showErrorMessage(this, message, e);
                    this.resolve('ok');
                }
                this.$_handleLoaderState(false);
            }
        }
    },
    validations: {
        form: {
            message: {
                required_message: required
            }
        }
    }
};
</script>
