<template>
    <div class="currencies-autocomplete">
        <form-group name="currency" :label="label">
            <md-input
                ref="currenciesAutocomplete"
                type="text"
                v-model="autocompleteText"
                @focus="onFocus($event)"
                @focusout="onFocusout($event)"
                @blur="blur"
                @change="onChange"
                @keyup.down="onArrowDown"
                @keyup.up="onArrowUp"
                @keyup.enter="onEnter"
            />
        </form-group>
        <div class="autocomplete">
            <ul
                id="autocomplete-results"
                v-show="isOpen && autocompleteList"
                class="autocomplete-results"
                ref="autocompleteContainer"
            >
                <li
                    ref="autocompleteOptions"
                    v-for="(entry, index) of autocompleteList"
                    :key="entry.isoCode"
                    @click="setAutocompleteResult(entry)"
                    class="autocomplete-result"
                    :class="{ 'is-active': index === arrowCounter }"
                >
                    {{ entry.symbol }} - {{ entry.description }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Iso4217Currencies } from '@/utils/Iso4217Currencies';
import moment from 'moment';
import _ from 'lodash';

export default {
    name: 'CurrenciesAutocomplete',
    props: {
        classname: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: 'Name'
        },
        icon: {
            type: String,
            default: ''
        },
        shouldFocus: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: ''
        }
    },
    validations: {
        currency: {}
    },
    data() {
        return {
            autocompleteText: null,
            autocompleteList: {},
            isOpen: false,
            arrowCounter: 0,
            selectedAutocomplete: null,
            isoCurrencies: {}
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    watch: {
        // eslint-disable-next-line func-names
        autocompleteText: _.debounce(function() {
            if (this.autocompleteText === null) 
                return;
            if (
                this.selectedAutocomplete === null ||
                this.autocompleteText !== this.getTextFromCurrency(this.selectedAutocomplete)
            ) {
                this.selectedAutocomplete = null;
                this.$emit('input', null);

                const upcaseText = this.autocompleteText.toUpperCase();
                const list = this.isoCurrencies.filter(
                    (entry) =>
                        entry.isoCode.toUpperCase().includes(upcaseText) ||
                        entry.description.toUpperCase().includes(upcaseText) ||
                        entry.symbol.toUpperCase().includes(upcaseText)
                );

                this.isOpen = true;
                this.autocompleteList = list;
            }
        }, 500),
        value(newVal) {
            if (newVal != null) {
                const currency = this.isoCurrencies.find((cur) => cur.isoCode === newVal);
                this.setAutocompleteResult(currency);
            }
        }
    },
    async mounted() {
        const userLocale = moment.locale();

        this.isoCurrencies = Object.entries(Iso4217Currencies).map(([key, value]) => ({
            isoCode: key,
            description: value,
            symbol: this.getCurrencySymbol(userLocale, key)
        }));

        if (this.value != null) {
            const currency = this.isoCurrencies.find((cur) => cur.isoCode === this.value);
            this.setAutocompleteResult(currency);
        } else {
            this.setAutocompleteResult(null);
            // this.autocompleteText = null;
        }
    },
    methods: {
        getCurrencySymbol(userLocale, isoCode) {
            const parts = Intl.NumberFormat(userLocale, {
                style: 'currency',
                currency: isoCode
            }).formatToParts();
            const symbol = parts.find((element) => element.type === 'currency');

            return symbol.value;
        },
        getTextFromCurrency(currency) {
            return `${currency.symbol} - ${currency.description}`;
        },
        onFocus(e) {
            // for selecting the whole input field
            setTimeout(() => e.target.select(), 0);
            this.$emit('focus');
        },
        onFocusout(e) {
            // if (!this.$el.contains(evt.target)) {
            setTimeout(() => {
                if (this.selectedAutocomplete === null) {
                    this.setAutocompleteResult(null);
                } else {
                    this.isOpen = false;
                }
                this.arrowCounter = -1;
            }, 300);
            // }
        },
        onChange() {
            if (this.selectedAutocomplete) 
                this.$emit('change', this.selectedAutocomplete.symbol);
            else 
                this.$emit('change', null);
        },
        focus() {
            this.$refs.currenciesAutocomplete.$el.focus();
        },
        blur() {
            this.$refs.currenciesAutocomplete.$el.blur();
            // a timeout is needed since when an autocomplete option is selected it is synchronous with the setAutoCompleteResult function
            // we need to make sure that setAutocompleteResult runs first before we close the autocomplete selection.
            // This is also used when users use the tab key to go to the next input. We need to close the autocomplete selection.
            setTimeout(() => {
                this.isOpen = false;
            }, 300);
        },
        setAutocompleteResult(data) {
            if (data != null) {
                this.selectedAutocomplete = data;
                this.autocompleteText = this.getTextFromCurrency(data);

                this.$emit('input', data.isoCode);
            } else {
                this.selectedAutocomplete = null;
                this.autocompleteText = null;

                this.$emit('input', null);
            }

            this.isOpen = false;
        },
        onArrowDown(evt) {
            if (this.arrowCounter < this.autocompleteList.length - 1) {
                this.arrowCounter = this.arrowCounter + 1;
                this.fixScrolling();
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
                this.fixScrolling();
            }
        },
        onEnter() {
            this.setAutocompleteResult(this.autocompleteList[this.arrowCounter]);
            this.arrowCounter = -1;
        },
        fixScrolling() {
            const optionHeight = this.$refs.autocompleteOptions[this.arrowCounter].clientHeight;
            this.$refs.autocompleteContainer.scrollTop = optionHeight * this.arrowCounter;
        }
    }
};
</script>

<style lang="scss" scoped>
.currencies-autocomplete {
    position: relative;
    width: 100%;
}

.autocomplete {
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    top: 40px;
}

.autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    overflow: auto;
    width: 100%;
}

.autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
    background-color: #4aae9b;
    color: white;
}
</style>
