<template>
    <div class="modal-container">
        <div class="modal-header">
            <h5 class="modal-title">New Template</h5>
        </div>
        <div class="modal-body">
            <form-group name="name" label="Template Name">
                <md-input v-model="templateName" v-focus />
            </form-group>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="saveTemplateName">
                Save
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TemplateNameModal',
    props: {
        mappingData: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            templateName: ''
        };
    },
    methods: {
        saveTemplateName() {
            if (
                this.mappingData.templateNameList.findIndex(
                    (x) => x.toLowerCase() === this.templateName.toLowerCase()
                ) === -1
            ) {
                this.mappingData.importMappingFields = [];
                this.resolve(this.templateName);
            } else {
                this.$notify({
                    message: `Template name ${this.templateName} already exists.`,
                    type: 'danger'
                });
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.modal-container {
    max-width: 300px;
}
</style>
