var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.assetDetails != null
    ? _c("div", { staticClass: "details-page" }, [
        _c("div", { staticClass: "md-layout" }, [
          _c("div", { staticClass: "md-layout-item" }, [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c("div", { staticClass: "custom-toolbar-start" }, [
                _vm.assetDetails.name != null
                  ? _c(
                      "h3",
                      { staticClass: "title" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.assetDetails.name) +
                            "\n                        "
                        ),
                        _c(
                          "badge",
                          {
                            staticClass: "custom-badge generic-badge",
                            attrs: { type: "warning" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.assetDetails.type || "Asset") +
                                "\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "h3",
                      { staticClass: "title" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.assetDetails.assetId) +
                            "\n                        "
                        ),
                        _c(
                          "badge",
                          {
                            staticClass: "custom-badge generic-badge",
                            attrs: { type: "warning" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.assetDetails.type || "Asset") +
                                "\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
              ]),
              !_vm.$root.isMobileOnly
                ? _c("div", { staticClass: "custom-toolbar-end" }, [
                    _c(
                      "div",
                      { staticClass: "header-button-container" },
                      [
                        _c("update-asset-button", {
                          attrs: {
                            asset: _vm.assetDetails,
                            "use-icon": false,
                            "custom-fields": _vm.assetCustomFieldList,
                            "class-name": "header-button",
                          },
                          on: { onAssetUpdate: _vm.getAssetDetails },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "md-layout md-alignment-top-left content-layout-panel",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-40 md-xlarge-size-40",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-blue",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card-icon custom-header-icon-text",
                              },
                              [
                                _c("md-icon", [_vm._v("description")]),
                                _vm._v(
                                  "\n                            Asset Details\n                        "
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("md-card-content", [
                          _c(
                            "div",
                            { staticClass: "asset-details-box details-box" },
                            [
                              _c("div", [
                                _c("label", [_vm._v("Asset Name")]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.assetDetails.name) +
                                      "\n                                "
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _c("label", [_vm._v("Type")]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.assetDetails.type) +
                                      "\n                                "
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _c("label", [_vm._v("Created By")]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.assetDetails.createdByUser) +
                                      "\n                                "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "asset-details-box details-box" },
                            [
                              _c("div", [
                                _c("label", [_vm._v("Asset Group Name")]),
                                _c(
                                  "span",
                                  { staticClass: "value" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Asset Group Details",
                                            params: {
                                              assetGroupId:
                                                _vm.assetDetails.assetGroupId,
                                            },
                                          },
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.assetDetails.assetGroupName
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", [
                                _c("label", [_vm._v("Time Zone")]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.assetDetails.timeZone) +
                                      "\n                                "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "asset-details-box details-box" },
                            [
                              _c("div", [
                                _c("label", [
                                  _vm._v("Current Odometer Reading"),
                                ]),
                                _vm.assetDetails.estimatedOdometerValue
                                  ? _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.assetDetails
                                              .estimatedOdometerValue
                                          ) +
                                          "\n                                    " +
                                          _vm._s(
                                            _vm.assetDetails
                                              .estimatedOdometerUnitType
                                          ) +
                                          "\n                                "
                                      ),
                                    ])
                                  : _c("span", { staticClass: "value" }, [
                                      _c("i", [_vm._v("(Not set)")]),
                                    ]),
                              ]),
                              _c("div", [
                                _c("label", [
                                  _vm._v("Current Engine Hour Reading"),
                                ]),
                                _vm.assetDetails.estimatedEngineHourValue
                                  ? _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.assetDetails
                                              .estimatedEngineHourValue
                                          ) +
                                          " hours\n                                "
                                      ),
                                    ])
                                  : _c("span", { staticClass: "value" }, [
                                      _c("i", [_vm._v("(Not set)")]),
                                    ]),
                              ]),
                              _vm.assetDetails.type === "Vehicle" ||
                              _vm.assetDetails.type === "Trailer"
                                ? _c("div", [
                                    _c("label", [
                                      _vm._v("Vehicle License Plate"),
                                    ]),
                                    _vm.assetDetails.vehicleLicensePlate
                                      ? _c("span", { staticClass: "value" }, [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.assetDetails
                                                  .vehicleLicensePlate
                                              ) +
                                              "\n                                "
                                          ),
                                        ])
                                      : _c("span", { staticClass: "value" }, [
                                          _c("i", [_vm._v("(Not set)")]),
                                        ]),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            [
                              _vm.trackerExtraFields.length > 0
                                ? _c("div", { staticClass: "line-separator" })
                                : _vm._e(),
                              _vm.trackerExtraFields.length > 0
                                ? _c(
                                    "h4",
                                    { staticClass: "small-details-title" },
                                    [
                                      _vm._v(
                                        "\n                                Tracker Details\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.trackerExtraFields.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "tracker-details-box" },
                                    _vm._l(
                                      _vm.trackerExtraFields,
                                      function (customField, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "details-box",
                                          },
                                          [
                                            _c("label", [
                                              _vm._v(_vm._s(customField.label)),
                                            ]),
                                            _vm.customFieldValues !== null &&
                                            _vm.customFieldValues[
                                              customField.name
                                            ] !== null &&
                                            customField.type.toLowerCase() ===
                                              "date"
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "dateTimeFormat"
                                                        )(
                                                          _vm.customFieldValues[
                                                            customField.name
                                                          ],
                                                          _vm.DATE_TYPES
                                                            .standardDate
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ])
                                              : customField.name ===
                                                "trackerReference"
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.assetDetails
                                                          .trackerReference
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.customFieldValues[
                                                        customField.name
                                                      ]
                                                    )
                                                  ),
                                                ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "line-separator" }),
                              _c("h4", { staticClass: "small-details-title" }, [
                                _vm._v("Custom fields"),
                              ]),
                              _vm._l(
                                _vm.assetCustomFieldList,
                                function (customField, index) {
                                  return customField.assetType === "Any" ||
                                    customField.assetType ===
                                      _vm.assetDetails.type
                                    ? _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "details-box",
                                        },
                                        [
                                          _c("label", [
                                            _vm._v(_vm._s(customField.label)),
                                          ]),
                                          _vm.customFieldValues !== null &&
                                          _vm.customFieldValues[
                                            customField.name
                                          ] !== null
                                            ? _c(
                                                "span",
                                                { staticClass: "value" },
                                                [
                                                  customField.type.toLowerCase() ===
                                                  "date"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "dateTimeFormat"
                                                              )(
                                                                _vm
                                                                  .customFieldValues[
                                                                  customField
                                                                    .name
                                                                ],
                                                                _vm.DATE_TYPES
                                                                  .standardDate
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .customFieldValues[
                                                              customField.name
                                                            ]
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e()
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-rose",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card-icon custom-header-icon-text",
                              },
                              [
                                _c("md-icon", [_vm._v("description")]),
                                _vm._v(
                                  "\n                            Maintenance Schedules\n                        "
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "md-card-content",
                          [
                            _vm.sortedbyNameAssetMaintenanceSchedule.length > 0
                              ? _c("md-table", {
                                  attrs: { "table-header-color": "green" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "md-table-row",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return _c(
                                            "md-table-row",
                                            {},
                                            [
                                              _c(
                                                "md-table-cell",
                                                {
                                                  attrs: { "md-label": "Name" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.maintenanceName)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  staticClass: "action-buttons",
                                                  attrs: {
                                                    "md-label": "Action",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "update-maintenance-schedule-button",
                                                    {
                                                      attrs: {
                                                        "maintenance-data":
                                                          item,
                                                        "asset-data":
                                                          _vm.assetDetails,
                                                      },
                                                      on: {
                                                        maintenanceCompleted:
                                                          _vm.handleCompleteSchedule,
                                                      },
                                                    }
                                                  ),
                                                  _c(
                                                    "remove-maintenance-schedule-button",
                                                    {
                                                      attrs: {
                                                        "maintenance-data":
                                                          item,
                                                      },
                                                      on: {
                                                        maintenanceCompleted:
                                                          _vm.handleCompleteSchedule,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3964408420
                                  ),
                                  model: {
                                    value:
                                      _vm.sortedbyNameAssetMaintenanceSchedule,
                                    callback: function ($$v) {
                                      _vm.sortedbyNameAssetMaintenanceSchedule =
                                        $$v
                                    },
                                    expression:
                                      "sortedbyNameAssetMaintenanceSchedule",
                                  },
                                })
                              : _c("p", { staticClass: "grayedout-text" }, [
                                  _vm._v("No maintenance schedule available"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm.$root.isDesktop
              ? _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-layout md-medium-size-100 md-large-size-60 md-xlarge-size-60",
                  },
                  [
                    _vm.$root.isDesktop
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                          },
                          [
                            _c(
                              "md-card",
                              [
                                _c(
                                  "md-card-header",
                                  {
                                    staticClass:
                                      "md-card-header-icon md-card-header-green",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "card-icon custom-header-icon-text",
                                      },
                                      [
                                        _c("md-icon", [_vm._v("schedule")]),
                                        _vm._v(
                                          "\n                            Upcoming Maintenance\n                        "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "md-card-content",
                                  [
                                    _vm.assetDetails
                                      .upcomingMaintenanceSchedules.length > 0
                                      ? _c(
                                          "time-line",
                                          {
                                            attrs: {
                                              plain: "",
                                              type: "simple",
                                            },
                                          },
                                          _vm._l(
                                            _vm.assetDetails
                                              .upcomingMaintenanceSchedules,
                                            function (item, index) {
                                              return _c(
                                                "time-line-item",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    inverted: "",
                                                    "is-clickable": true,
                                                    "badge-type":
                                                      "status-" +
                                                      _vm.$_getAssetLogTypeBadgeColor(
                                                        item.logType
                                                      ),
                                                    "badge-icon":
                                                      "" +
                                                      _vm.$_getAssetLogTypeIcon(
                                                        item.logType
                                                      ),
                                                  },
                                                  on: {
                                                    clicked: function ($event) {
                                                      return _vm.completeSchedule(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "title-badge",
                                                      attrs: { slot: "header" },
                                                      slot: "header",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.maintenanceName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "generic-badge status-pending",
                                                      class:
                                                        "status-" +
                                                        _vm.$_getAssetLogSubTypeBadgeColor(
                                                          item.logType,
                                                          item.logSubType
                                                        ),
                                                      attrs: {
                                                        slot: "headerTime",
                                                      },
                                                      slot: "headerTime",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "assetLogSubTypes." +
                                                                item.logSubType
                                                            )
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      attrs: {
                                                        slot: "content",
                                                      },
                                                      slot: "content",
                                                    },
                                                    [
                                                      _c("reminder-indicator", {
                                                        attrs: {
                                                          "maintenance-data":
                                                            item,
                                                          "current-engine-hours":
                                                            item.estimatedEngineHourValue,
                                                          "next-engine-hour-due":
                                                            item.nextDueEngineHours !=
                                                            null
                                                              ? parseFloat(
                                                                  item.nextDueEngineHours
                                                                )
                                                              : null,
                                                          "next-due-distance":
                                                            item.nextDueOdometer !=
                                                            null
                                                              ? parseFloat(
                                                                  item.nextDueOdometer
                                                                )
                                                              : null,
                                                          "current-distance-reading":
                                                            item.estimatedOdometerValue,
                                                          "repeat-interval":
                                                            parseInt(1),
                                                          "next-due-date":
                                                            item.nextDueServiceDate,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "heading-time",
                                                      attrs: { slot: "footer" },
                                                      slot: "footer",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass: "ti-time",
                                                      }),
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              item.createdDate,
                                                              _vm.DATE_TYPES
                                                                .standardDate
                                                            )
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _c(
                                          "p",
                                          { staticClass: "grayedout-text" },
                                          [
                                            _vm._v(
                                              "No upcoming maintenance schedule available"
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$root.isDesktop
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                          },
                          [
                            _c(
                              "md-card",
                              [
                                _c(
                                  "md-card-header",
                                  {
                                    staticClass:
                                      "md-card-header-icon md-card-header-warning",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "card-icon custom-header-icon-text",
                                      },
                                      [
                                        _c("md-icon", [_vm._v("history")]),
                                        _vm._v(
                                          "\n                            Asset Logs\n                        "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "md-card-content",
                                  [
                                    _vm.assetDetails.logs.length > 0
                                      ? _c(
                                          "time-line",
                                          {
                                            attrs: {
                                              plain: "",
                                              type: "simple",
                                            },
                                          },
                                          _vm._l(
                                            _vm.assetDetails.logs,
                                            function (logItem, index) {
                                              return _c(
                                                "time-line-item",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    inverted: "",
                                                    "badge-type":
                                                      "status-" +
                                                      _vm.$_getAssetLogTypeBadgeColor(
                                                        logItem.logType
                                                      ),
                                                    "badge-icon":
                                                      "" +
                                                      _vm.$_getAssetLogTypeIcon(
                                                        logItem.logType
                                                      ),
                                                    "is-clickable": true,
                                                  },
                                                  on: {
                                                    clicked: function ($event) {
                                                      return _vm.showDetails(
                                                        logItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "generic-badge status-pending",
                                                      class:
                                                        "status-" +
                                                        _vm.$_getAssetLogSubTypeBadgeColor(
                                                          logItem.logType,
                                                          logItem.logSubType
                                                        ),
                                                      attrs: { slot: "header" },
                                                      slot: "header",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "assetLogSubTypes." +
                                                                logItem.logSubType
                                                            )
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "custom-ellipsis",
                                                      attrs: {
                                                        slot: "content",
                                                      },
                                                      slot: "content",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            logItem.notes
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "heading-time",
                                                      attrs: { slot: "footer" },
                                                      slot: "footer",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass: "ti-time",
                                                      }),
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              logItem.createdDate,
                                                              _vm.DATE_TYPES
                                                                .standardDate
                                                            )
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _c(
                                          "p",
                                          { staticClass: "grayedout-text" },
                                          [_vm._v("No log history available")]
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }