<template>
    <div class="hide-border">
        <label class="valid-days-label">Valid on days</label>
        <md-field class="hide-border">
            <md-tooltip md-direction="right">Leave blank to apply all days</md-tooltip>
            <multiselect
                class="hide-border"
                v-model="selectedDays"
                :options="dayList"
                :multiple="true"
                :close-on-select="false"
                placeholder="Valid Days"
                label="name"
                track-by="value"
            />
        </md-field>
        <div class="md-layout">
            <div class="md-layout-item md-size-50">
                <label class="datepicker-label">Valid from</label>
                <md-tooltip md-direction="right">Leave blank to apply all dates</md-tooltip>
                <md-field class="datepicker">
                    <md-datepicker
                        class="start-date"
                        v-model="validFromDate"
                        md-immediately
                        :md-debounce="100"
                    ></md-datepicker>
                </md-field>
            </div>
            <div class="md-layout-item md-size-50">
                <label class="datepicker-label">Valid to</label>
                <md-tooltip md-direction="right">Leave blank to apply all dates</md-tooltip>
                <md-field class="datepicker">
                    <md-datepicker
                        class="end-date"
                        v-model="validToDate"
                        md-immediately
                        :md-debounce="100"
                    ></md-datepicker>
                </md-field>
            </div>
        </div>
    </div>
</template>

<script>
import { RATES_DAYS_OF_THE_WEEK } from '@/utils/constants';

export default {
    name: 'RuleValidDays',
    props: {
        currentDays: {
            type: Array,
            default: () => []
        },
        fromDate: {
            type: [String, Date],
            default: null
        },
        toDate: {
            type: [String, Date],
            default: null
        }
    },
    async mounted() {
        this.selectedDays = this.currentDays;

        if (this.fromDate) {
            this.validFromDate = new Date(this.fromDate);
        }

        if (this.toDate) {
            this.validToDate = new Date(this.toDate);
        }
    },
    data() {
        return {
            dayList: [...RATES_DAYS_OF_THE_WEEK],
            selectedDays: null,
            validFromDate: null,
            validToDate: null
        };
    },
    watch: {
        selectedDays(newValue, oldValue) {
            this.$emit('daysSelected', {
                validForWeekDays: this.selectedDays,
                validFromDate: this.validFromDate,
                validToDate: this.validToDate
            });
        },
        validFromDate(newValue, oldValue) {
            this.$emit('daysSelected', {
                validForWeekDays: this.selectedDays,
                validFromDate: this.validFromDate,
                validToDate: this.validToDate
            });
        },
        validToDate(newValue, oldValue) {
            this.$emit('daysSelected', {
                validForWeekDays: this.selectedDays,
                validFromDate: this.validFromDate,
                validToDate: this.validToDate
            });
        }
    },
    methods: {
        validate() {
            if (this.validFromDate && this.validToDate && this.validFromDate > this.validToDate) {
                this.$notifyError('From date must not come after To date.');
                return false;
            }

            return true;
        }
    }
};
</script>

<style lang="scss" scoped>
.md-layout {
    & > .md-layout-item:first-child {
        padding-left: 0px;
    }

    & > .md-layout-item:last-child {
        padding-right: 0px;

        .md-field.datepicker {
            margin-right: 0px;
        }
    }
}

label {
    color: #aaaaaa;
    font-weight: 400;
    font-size: 14px;
    display: block;
    margin-top: 10px;
}

.valid-days-label {
    + .md-field {
        margin-top: 0px;
    }
}

.hide-border {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.datepicker-label {
    + .md-field.datepicker {
        & > .md-field {
            margin-top: 0px;
        }
    }
}

.md-field.datepicker {
    width: 220px;
    margin-top: 0;

    & > .md-field {
        width: 220px;
        padding-bottom: 0px;

        &:before,
        &:after {
            background-color: transparent !important;
            bottom: 28px;
            color: #aaaaaa;
            font-weight: 400;
            font-size: 14px;
            -webkit-transform: none;
            transform: none;
            z-index: 1;
        }

        &.start-date:before,
        &.start-date:after {
            content: 'Start Date';
        }

        &.end-date:before,
        &.end-date:after {
            content: 'End Date';
        }

        &:hover {
            cursor: text;
        }
    }

    & > .md-field.md-has-value {
        &.start-date:before,
        &.start-date:after {
            content: '';
        }

        &.end-date:before,
        &.end-date:after {
            content: '';
        }
    }
}
</style>
