import { ref } from '@vue/composition-api';

const STATUS_ENUM = {
    PENDING: 'pending',
    IDLE: 'idle',
    SUCCESS: 'success',
    ERROR: 'error'
};

export default function useGenericStatus() {
    const status = ref(STATUS_ENUM.IDLE);

    function setIdle() {
        status.value = STATUS_ENUM.IDLE;
    }
    function setPending() {
        status.value = STATUS_ENUM.PENDING;
    }

    function setSuccess() {
        status.value = STATUS_ENUM.SUCCESS;
    }

    function setError() {
        status.value = STATUS_ENUM.ERROR;
    }
    return {
        status,
        STATUS_ENUM,
        setIdle,
        setPending,
        setSuccess,
        setError
    };
}
