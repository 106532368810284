<template>
    <md-select v-model="selectedOption" @md-selected="getSelectedOption" :disabled="disabled">
        <md-option v-for="item in optionList" :key="item.name" :value="item.name">
            {{ item.name }}
            <md-tooltip md-direction="right">
                {{ item.description }}
            </md-tooltip>
        </md-option>
    </md-select>
</template>
<script>
import { GPS_DATA_COLLECTION_RULE_CONSTANTS } from '@/utils/constants';

export default {
    name: 'GpsCollectionDropdown',
    props: {
        selected: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            optionList: [...GPS_DATA_COLLECTION_RULE_CONSTANTS],
            selectedOption: ''
        };
    },
    watch: {
        selected(newValue) {
            this.selectedOption = newValue;
        }
    },
    methods: {
        getSelectedOption(value) {
            this.$emit('selectedOption', value);
        }
    }
};
</script>

<style lang="scss" scoped>
.md-tooltip {
    z-index: 9999;
}
</style>
