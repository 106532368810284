var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-button",
    {
      staticClass: "md-danger md-just-icon md-round",
      attrs: { title: "Remove from route" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleDeleteStop(_vm.stopId, _vm.isDialog)
        },
      },
    },
    [
      _c("md-icon", [
        _c("img", {
          staticClass: "delete-icon",
          attrs: { src: "/img/icons/remove-booking-icon.svg" },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }