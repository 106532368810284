var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam
    ? _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Settings"),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "   >    " +
                        _vm._s(_vm.$t("menus.setting.geofenceEventsSettings"))
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-blue" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("settings_input_antenna")])],
                      1
                    ),
                  ]
                ),
                _c("md-card-content", [
                  _c(
                    "div",
                    { staticClass: "cf-container" },
                    [
                      _c(
                        "div",
                        { staticClass: "cf-action-container" },
                        [
                          _vm.geofenceConfiguration !== "OFF"
                            ? _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-primary md-just-icon md-round add-geofence-event",
                                  attrs: { title: "Add Geofence Event" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.addNewGeofenceEvent()
                                    },
                                  },
                                },
                                [_c("md-icon", [_vm._v("add")])],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "form-group",
                            {
                              staticClass: "geofence-settings-key",
                              attrs: {
                                label: "Geofence Setting",
                                name: "setting",
                              },
                            },
                            [
                              _c(
                                "md-select",
                                {
                                  model: {
                                    value: _vm.geofenceConfiguration,
                                    callback: function ($$v) {
                                      _vm.geofenceConfiguration = $$v
                                    },
                                    expression: "geofenceConfiguration",
                                  },
                                },
                                [
                                  _c("md-option", { attrs: { value: "OFF" } }, [
                                    _vm._v("Off"),
                                  ]),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "TEST" } },
                                    [_vm._v("Test mode")]
                                  ),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "LIVE" } },
                                    [_vm._v("Live")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "geofence-settings-key-btn custom-btn",
                              on: { click: _vm.SaveGeofenceConfiguration },
                            },
                            [
                              _vm._v(
                                "\n                            Save\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.geofenceConfiguration !== "OFF" &&
                      _vm.dataLoaded === true
                        ? _c(
                            "md-table",
                            { staticClass: "custom-paginated-table" },
                            [
                              _c(
                                "md-table-row",
                                [
                                  _c("md-table-head", [
                                    _vm._v("Travel Distance"),
                                  ]),
                                  _c("md-table-head", [_vm._v("Action")]),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.geofenceEvents,
                                function (geofence, index) {
                                  return _c(
                                    "md-table-row",
                                    { key: index },
                                    [
                                      _c("md-table-cell", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDistanceFromText(geofence)
                                          )
                                        ),
                                      ]),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.getTriggerGroupEvent(geofence)
                                            ) +
                                            "\n                            "
                                        ),
                                      ]),
                                      _c(
                                        "md-table-cell",
                                        { staticClass: "action-buttons" },
                                        [
                                          _c(
                                            "md-button",
                                            {
                                              staticClass:
                                                "md-warning md-just-icon md-round",
                                              attrs: {
                                                title: "Edit Geofence Event",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editGeofenceEvent(
                                                    geofence
                                                  )
                                                },
                                              },
                                            },
                                            [_c("md-icon", [_vm._v("edit")])],
                                            1
                                          ),
                                          _c(
                                            "md-button",
                                            {
                                              staticClass:
                                                "md-danger md-just-icon md-round",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteGeofenceEvent(
                                                    geofence.geofenceEventId
                                                  )
                                                },
                                              },
                                            },
                                            [_c("md-icon", [_vm._v("delete")])],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }