<template>
    <md-card class="md-card-signup">
        <md-card-header>
            <slot name="title" />
            <div class="social-line">
                <slot name="buttons" />
            </div>
        </md-card-header>

        <md-card-content>
            <slot name="description" />
            <slot name="inputs" />
            <slot name="form-link" />
        </md-card-content>

        <md-card-actions>
            <slot name="footer" />
        </md-card-actions>
    </md-card>
</template>

<script>
export default {
    name: 'SignupCard'
};
</script>
