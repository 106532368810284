<template>
    <div class="content">
        <div v-if="obdAlertModalHide !== true" class="alert alert-info alert-z">
            <button type="button" aria-hidden="true" class="close" @click="obdAlertModalHide = true">
                ×
            </button>
            <span>
                The safety reports are only available for OBD device data.
            </span>
        </div>

        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <div class="custom-toolbar md-layout">
                    <div class="md-layout-item custom-toolbar-start md-layout md-small-size-80 md-size-90">
                        <team-members-filter @onSelection="onTeamMemberSelection" />

                        <div class="md-layout-item">
                            <md-field>
                                <label class="field-label">Type:</label>
                                <md-select v-model="selectedType" @md-selected="getReportDataList" multiple>
                                    <md-option
                                        v-for="member in types"
                                        :key="member.key"
                                        :value="member.key"
                                        class="multiple-selection-checkbox"
                                    >
                                        {{ member.value }}
                                    </md-option>
                                </md-select>
                            </md-field>
                        </div>

                        <div class="md-layout-item">
                            <date-range-filter :is-single-date="false" @onChanged="onDateChanged" />
                        </div>
                    </div>
                    <div class="md-layout-item custom-toolbar-end">
                        <md-button
                            title="Export Safety report"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click="onExportToExcel"
                        >
                            <md-icon>get_app</md-icon>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>summarize</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div v-if="!reportDataList || reportDataList.length == 0">
                            <h3>Safety Report</h3>
                            <p>
                                No results to display.
                            </p>
                        </div>
                        <div v-else>
                            <div class="report-container">
                                <safety-report-details :member-list="reportDataList" />
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>
<style scoped>
.header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.multiple-selection-checkbox .md-checkbox.md-theme-default .md-checkbox-container {
    border-color: rgba(0, 0, 0, 0.54) !important;
}

.multiple-selection-checkbox .md-checkbox {
    margin-left: unset !important;
}

.multiple-selection-checkbox .md-list-item-text {
    left: 35px !important;
}

.alert-z {
    z-index: 0 !important;
}
</style>
<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapMutations, mapGetters } from 'vuex';
import SafetyReportDetails from './SafetyReportDetails';
import { handleRequests, downloadFile } from '../../helpers';
import { TeamMembersFilter, DateRangeFilter } from './components';

export default {
    name: 'SafetyReport',
    components: { SafetyReportDetails, TeamMembersFilter, DateRangeFilter },
    mixins: [GeneralMixin],
    data() {
        return {
            isLoading: true,
            reportDateFrom: null,
            reportDateTo: null,
            selectedType: [],
            types: [
                { value: 'Over speed', key: 'OVERSPEED' },
                { value: 'Fatigue', key: 'FATIGUE' },
                { value: 'Harsh deceleration', key: 'HARSH_DECELERATION' },
                { value: 'Harsh acceleration', key: 'HARSH_ACCELERATION' },
                { value: 'Harsh turning', key: 'HARSH_TURNING' },
                { value: 'Impact', key: 'IMPACT' },
                { value: 'Roll over', key: 'ROLL_OVER' },
                { value: 'High rpm', key: 'HIGH_RPM' },
                { value: 'Rpm speed mismatch', key: 'RPM_SPEED_MISMATCH' }
            ],
            reportDataList: [],
            userFullName: '',
            selectedTeamMember: {},
            smallAlertModalHide: false,
            obdAlertModalHide: false
        };
    },
    methods: {
        ...mapGetters({
            user: 'user/user'
        }),
        ...mapMutations({
            changeDate: 'map/CHANGE_DATE'
        }),

        onExportToExcel() {
            if (this.reportDateFrom && this.reportDateTo) {
                const types = this.selectedType.length === 0 ? this.types.map((v) => v.key) : this.selectedType;

                this.$_handleLoaderState(true);
                const api = `/api/reports/safety-report/export-to-excel?userId=${this.selectedTeamMember.userId ||
                    ''}&isTracker=${this.selectedTeamMember.isTracker}&types=${types}&startDate=${moment(
                    this.reportDateFrom
                ).format('YYYY-MM-DD')}&endDate=${moment(this.reportDateTo).format('YYYY-MM-DD')}`;
                const payload = {
                    method: 'get',
                    responseType: 'blob'
                };
                const reportName = `${this.selectedTeamMember.name || this.userFullName}-SafetyReport.xlsx`;
                handleRequests(api, payload)
                    .then((response) => {
                        downloadFile(response.data, reportName);
                        this.$_handleLoaderState(false);
                    })
                    .catch((e) => {
                        this.$_handleLoaderState(false);
                        this.$notify({ message: e.data, type: 'danger' });
                    });
            }
        },
        getReportDataList() {
            if (this.reportDateFrom && this.reportDateTo) {
                this.isLoading = true;

                const types = this.selectedType.length === 0 ? this.types.map((v) => v.key) : this.selectedType;

                this.$_handleLoaderState(true);
                const api = `/api/reports/safety-report/?userId=${this.selectedTeamMember.userId || ''}&isTracker=${
                    this.selectedTeamMember.isTracker
                }&types=${types}&startDate=${moment(this.reportDateFrom).format('YYYY-MM-DD')}&endDate=${moment(
                    this.reportDateTo
                ).format('YYYY-MM-DD')}`;
                handleRequests(api)
                    .then((response) => {
                        this.isLoading = false;
                        this.reportDataList = response.data.data;
                        this.$_handleLoaderState(false);
                    })
                    .catch((e) => {
                        this.reportDataList = [];
                        this.$_handleLoaderState(false);
                        this.$notify({ message: e.data, type: 'danger' });
                    });
            }
        },

        async onDateChanged(val) {
            this.reportDateFrom = val.startDate;
            this.reportDateTo = val.endDate;
            this.getReportDataList();
        },

        async onTeamMemberSelection(val) {
            this.selectedTeamMember = val || {};
            this.getReportDataList();
        }
    },
    async mounted() {
        this.userFullName = this.user().fullName;
    }
};
</script>
