<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.appPermissionSettings') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100 main-container">
            <md-card>
                <md-card-content>
                    <h5 class="card-title">
                        Select from the list of below features which you want to provide access for mobile app users.
                    </h5>
                    <div class="md-layout">
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Stops
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="appPermissions.addStops">Add Stops</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.addStopsForCustomers">
                                        Add Stops for Customers
                                    </md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.updateStops">Update Stops</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.deleteStops">Delete Stops</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.changeStopStatuses">
                                        Change Stop Statuses
                                    </md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.reorderStops">Reorder Stops</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.viewStopNotes">View Stop Notes</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.addStopNotes">Add Stop Notes</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.discardStops">Discard Stops</md-checkbox>
                                </div>

                                <div>
                                    <md-checkbox v-model="appPermissions.selectCustomer">Select Customer</md-checkbox>
                                </div>
                                <div class="md-layout">
                                    <label class="md-layout-item md-size-35 md-form-label">
                                        Stop Line Mode
                                    </label>
                                    <div class="md-layout-item md-size-65">
                                        <md-field>
                                            <md-select v-model="appPermissions.stopLineMode" name="stopLineMode">
                                                <md-option value="noPricing">
                                                    No Pricing
                                                </md-option>
                                                <md-option value="teamMemberCosts">
                                                    Team Member Costs
                                                </md-option>
                                                <md-option value="customerPricing">
                                                    Customer Pricing
                                                </md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Shipments
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="appPermissions.addShipments">Add Shipments</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.updateShipments">Update Shipments</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.deleteShipments">Delete Shipments</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.changeShipmentStatuses">
                                        Change Shipment Statuses
                                    </md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.discardShipments">
                                        Discard Shipments
                                    </md-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item md-size-25">
                            <h5 class="app-title">
                                Users
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="appPermissions.updateUserProfile">
                                        Update User Profile
                                    </md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.updateUserStatus">
                                        Update User Status
                                    </md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.changeUserPhoto">
                                        Change User Photo
                                    </md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.changeLogo">Change Logo</md-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Links
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="appPermissions.createLinks">Create Links</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.deleteLinks">Delete Links</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.addStopLines">Add Stop Lines</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.updateStopLines">
                                        Update Stop Lines
                                    </md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.deleteStopLines">
                                        Delete Stop Lines
                                    </md-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Trips
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="appPermissions.updateTrips">Update Trips</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.askTripQuestionsForEachRun">
                                        Ask Trip Questions for Each Run
                                    </md-checkbox>
                                </div>
                                <div class="md-layout">
                                    <label class="md-layout-item md-size-35 md-form-label">
                                        Trip List Mode
                                    </label>
                                    <div class="md-layout-item md-size-65">
                                        <md-field>
                                            <md-select v-model="appPermissions.tripListMode" name="tripListMode">
                                                <md-option value="all">
                                                    Stops + Shipments
                                                </md-option>
                                                <md-option value="stops">
                                                    Stops
                                                </md-option>
                                                <md-option value="shipments">
                                                    Shipments
                                                </md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item md-size-25">
                            <h5 class="app-title">
                                Assets
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="appPermissions.registerGpsTrackingDevice">
                                        Register GPS Tracking Device
                                    </md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.checkAssetsInAndOut">
                                        Take Possession of Assets...
                                    </md-checkbox>
                                </div>
                                <div style="padding-left: 30px">
                                    <div>
                                        <md-checkbox v-model="appPermissions.assetCheckInOut.nfc">
                                            via NFC Tap
                                        </md-checkbox>
                                    </div>
                                    <div>
                                        <md-checkbox v-model="appPermissions.assetCheckInOut.barcode">
                                            via Scan Barcode
                                        </md-checkbox>
                                    </div>
                                    <div>
                                        <md-checkbox v-model="appPermissions.assetCheckInOut.selectFromList">
                                            via Select From List
                                        </md-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Invoices
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="appPermissions.createCustomerInvoices">
                                        Create Customer Invoices
                                    </md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.acceptPayments">Accept Payments</md-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Items
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="appPermissions.pickupItems">Pickup Items</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.returnItems">Return Items</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.warehouseItemsScan">
                                        Warehouse Items Scan
                                    </md-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Views
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="appPermissions.views.map">
                                        Map
                                    </md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.views.stops">
                                        Stops
                                    </md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.views.items" @change="itemScanningChanged">
                                        Item scanning
                                    </md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox
                                        v-model="appPermissions.views.itemChecklist"
                                        @change="itemChecklistChanged"
                                    >
                                        Item checklist
                                    </md-checkbox>
                                </div>
                                <div class="md-layout">
                                    <label class="md-layout-item md-size-35 md-form-label">
                                        Default view
                                    </label>
                                    <div class="md-layout-item md-size-65">
                                        <md-field>
                                            <md-select v-model="appPermissions.views.default" name="defaultview">
                                                <md-option value="">
                                                    Not Selected
                                                </md-option>
                                                <md-option value="map">
                                                    Map
                                                </md-option>
                                                <md-option value="stops">
                                                    Stops
                                                </md-option>
                                                <md-option value="items">
                                                    Item scanning
                                                </md-option>
                                                <md-option value="itemChecklist">
                                                    Item checklist
                                                </md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Photo/Camera options
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="appPermissions.quickCapturePhotoNotes">
                                        Quick capture photo notes
                                    </md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="appPermissions.uploadFromGallery">
                                        Upload from gallery
                                    </md-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </md-card-content>
                <md-card-actions md-alignment="left">
                    <button class="custom-btn" @click="saveAppPermissions">
                        Save
                    </button>
                </md-card-actions>
            </md-card>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { APP_DETAILS_PERMISSIONS } from '@/utils/defaults';
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'AppPermissionSettings',
    mixins: [GeneralMixin],
    data() {
        return {
            appPermissions: APP_DETAILS_PERMISSIONS()
        };
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam'
        })
    },
    async beforeMount() {
        await this.getAppPermissions();
    },
    methods: {
        async getAppPermissions() {
            const api = '/api/teams/settings/app-permissions';
            const { data } = await handleRequests(api);

            if (data) {
                if (!data.assetCheckInOut) {
                    data.assetCheckInOut = {
                        nfc: true,
                        barcode: true,
                        selectFromList: true
                    };
                }

                if (!data.views) {
                    data.views = {
                        map: true,
                        stops: true,
                        items: false,
                        itemChecklist: false,
                        default: ''
                    };
                }
            }

            this.appPermissions = Object.assign({}, data === '' ? APP_DETAILS_PERMISSIONS() : data);
        },
        async saveAppPermissions() {
            this.$_handleLoaderState(true, 'SAVING...');
            const payload = {
                method: 'post',
                data: this.appPermissions
            };

            const api = '/api/teams/settings/app-permissions';
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'App permissions saved!',
                    type: 'success'
                });
                this.$_handleLoaderState(false);
            } catch (e) {
                this.$_handleLoaderState(false);
                const message = 'Cannot save app permissions.';
                showErrorMessage(this, message, e);
            }
        },
        itemScanningChanged(value) {
            if (value) {
                this.appPermissions.views.itemChecklist = false;
            }
        },
        itemChecklistChanged(value) {
            if (value) {
                this.appPermissions.views.items = false;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.main-container {
    margin-top: 15px;
    ::v-deep .md-field {
        margin: 0;
    }
    .layout-item {
        padding: 0px;
    }
    .app-title {
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 0;
        color: #999;
    }
    .md-form-label {
        padding: 10px 5px 0 0;
        text-align: left;
    }
}
</style>
