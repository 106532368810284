<template>
    <div class="asset-group-control md-layout-item md-size-100">
        <form-group v-if="assetGroupOptions && assetGroupOptions.length > 0" label="Asset Group">
            <md-select v-model="selectedAssetGroupId" @md-selected="emitChange">
                <md-option
                    v-for="(group, index) in assetGroupOptions"
                    :key="`asset-group-${index}`"
                    :value="group.assetGroupId"
                >
                    {{ group.groupName }}
                </md-option>
            </md-select>
        </form-group>
    </div>
</template>

<script>
import { handleRequests } from '@/helpers';
import { mapGetters } from 'vuex';

export default {
    name: 'AssetGroup',
    props: {
        teamRegionId: {
            type: Number,
            default: undefined
        },
        assetGroupId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            selectedAssetGroupId: 0,
            assetGroupOptions: [],
            assetGroupDefaultOption: {
                assetGroupId: 0,
                groupName: 'Unassigned'
            },
            filter: undefined
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    watch: {
        teamRegionId(newValue, oldValue) {
            this.fetchAssetGroupList(newValue);
        },
        assetGroupId(newValue) {
            this.selectedAssetGroupId = !newValue ? 0 : newValue;
        }
    },
    methods: {
        async fetchAssetGroupList(teamRegionId) {
            if (teamRegionId === this.filter) 
                return; // avoid multiple calls with same query params

            this.filter = teamRegionId;

            const endpoint = `/api/asset-groups/list`;
            const response = await handleRequests(endpoint, {
                params: {
                    pageNumber: 1,
                    itemsPerPage: 100,
                    teamRegionId
                }
            });
            const { assetGroups } = response.data;
            if (assetGroups && assetGroups.length > 0) {
                this.assetGroupOptions = [this.assetGroupDefaultOption, ...assetGroups];
            } else {
                this.assetGroupOptions = [];
                this.selectedAssetGroupId = null;
            }
        },
        emitChange() {
            const assetGroupId =
                this.selectedAssetGroupId && this.selectedAssetGroupId !== 0 ? this.selectedAssetGroupId : null;

            let assetGroup = null;
            if (assetGroupId) {
                assetGroup = this.assetGroupOptions.find((x) => x.assetGroupId === assetGroupId);
            }
            this.$emit('changed', assetGroup);
        }
    },
    mounted() {
        const teamRegionId = this.teamRegionId || this.user.teamRegionId;
        this.fetchAssetGroupList(teamRegionId);
    }
};
</script>

<style lang="scss" scoped>
.asset-group-control {
    overflow: auto;
    width: 100%;
}
</style>
