<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ isUpdate ? 'Update' : 'Create' }} Run Shipment</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body shipment-form-modal">
            <form-wrapper :validator="$v.runShipmentDetails" class="form-wrapper">
                <tabs
                    :tab-name="['Shipment', 'Pickup Options', 'Drop off Options']"
                    color-button="warning"
                    class="custom-tab-list"
                >
                    <template slot="tab-pane-1">
                        <div class="shipment-form-container">
                            <charging-type-options
                                :update-stop="true"
                                @selectedOption="handleRateTypeChanged"
                                :selected-charge-type-id="runShipmentDetails.rateGroupId"
                            />
                            <div class="stop-container">
                                <div>
                                    <google-autocomplete
                                        label="Pickup"
                                        id="pautoaddress"
                                        classname="form-control autocomplete-input"
                                        placeholder=""
                                        :should-focus="false"
                                        :disable-gps-coordinates="false"
                                        gps-coordinate-placeholder="Enter a name for the pickup stop"
                                        v-model="runShipmentDetails.pickupRunScheduleStop"
                                        name="pickupRunScheduleStop.address"
                                        @handleCoordinates="handlePickupCoordinates"
                                        @change="handleShipmentDetailsChanged"
                                    ></google-autocomplete>
                                </div>
                            </div>
                            <div class="stop-container">
                                <div>
                                    <google-autocomplete
                                        label="Drop off"
                                        id="dautoaddress"
                                        classname="form-control autocomplete-input"
                                        placeholder=""
                                        :should-focus="false"
                                        :disable-gps-coordinates="false"
                                        gps-coordinate-placeholder="Enter a name for the drop stop"
                                        @handleCoordinates="handleDropCoordinates"
                                        name="dropRunScheduleStop.address"
                                        v-model="runShipmentDetails.dropRunScheduleStop"
                                        @change="handleShipmentDetailsChanged"
                                    ></google-autocomplete>
                                </div>
                            </div>
                            <div class="contact-details">
                                <span>Contact Details</span>
                                <div class="contact-details">
                                    <customer-autocomplete
                                        :label="runShipmentDetails.customerId == null ? 'Name' : 'Customer'"
                                        id="autocustomerShipment"
                                        placeholder=""
                                        :should-focus="false"
                                        v-model="runShipmentDetails"
                                        :auto-fill-address="true"
                                        :update-customer="!isUpdate"
                                        :disabled="customerNameIsDisabled()"
                                        @changeCustomerRateGroup="handleCustomerChangeRateGroup"
                                    />
                                    <form-group
                                        name="name"
                                        label="Contact Name"
                                        v-if="runShipmentDetails.customerId != null"
                                    >
                                        <md-input v-model="runShipmentDetails.contact.name" />
                                    </form-group>
                                    <form-group name="phone" label="Phone">
                                        <md-input class="shipment-contact" v-model="runShipmentDetails.contact.phone" />
                                    </form-group>
                                    <form-group name="email" label="Email">
                                        <md-input class="shipment-contact" v-model="runShipmentDetails.contact.email" />
                                    </form-group>
                                </div>
                            </div>
                            <form-group name="notes" label="Notes">
                                <md-textarea v-model="runShipmentDetails.notes" />
                            </form-group>
                            <div v-if="shipmentCustomFieldList !== null">
                                <custom-field-inputs
                                    v-for="(customField, index) in shipmentCustomFieldList"
                                    :key="index"
                                    :custom-field-definition="customField"
                                    :initial-value="customFieldValues[customField.name]"
                                    @changed="handleCustomFieldChanged"
                                />
                            </div>
                            <div v-if="loadFieldList !== null">
                                <load-inputs
                                    v-for="(load, index) in loadFieldList"
                                    :key="index"
                                    :load-definition="load"
                                    :initial-value="loadValues"
                                    @changed="handleLoadChanged"
                                />
                            </div>
                            <form-group v-if="skillOptions.length" name="skills" label="Skills">
                                <multiselect
                                    v-model="runShipmentDetails.skills"
                                    :options="skillOptions"
                                    :multiple="true"
                                    :close-on-select="true"
                                    placeholder="Pick required skills"
                                ></multiselect>
                            </form-group>
                        </div>
                    </template>

                    <template slot="tab-pane-2">
                        <div class="contact-details">
                            <span>Pickup Details</span>
                            <customer-autocomplete
                                :label="
                                    runShipmentDetails.pickupRunScheduleStop.customerId == null ? 'Name' : 'Customer'
                                "
                                id="autocustomerShipmentPickup"
                                placeholder=""
                                :should-focus="false"
                                v-model="runShipmentDetails.pickupRunScheduleStop"
                                :auto-fill-address="true"
                            />
                            <form-group
                                name="name"
                                label="Contact Name"
                                v-if="runShipmentDetails.pickupRunScheduleStop.customerId != null"
                            >
                                <md-input v-model="runShipmentDetails.pickupRunScheduleStop.contact.name" />
                            </form-group>
                            <form-group name="phone" label="Phone">
                                <md-input v-model="runShipmentDetails.pickupRunScheduleStop.contact.phone" />
                            </form-group>
                            <form-group name="email" label="Email">
                                <md-input v-model="runShipmentDetails.pickupRunScheduleStop.contact.email" />
                            </form-group>
                            <form-group name="notes" label="Notes">
                                <md-textarea v-model="runShipmentDetails.pickupRunScheduleStop.notes" />
                            </form-group>
                        </div>
                        <div class="shipment-datepicker">
                            <div class="stop-container">
                                <div class="trip-settings">
                                    <form-group name="time" label="Time" class="time-picker">
                                        <time-picker
                                            :time="pickupTime"
                                            :additional-time-options="additionalPickupTimeOption"
                                            @selectedTime="getPickupTime"
                                        />
                                    </form-group>
                                </div>
                                <div class="trip-settings duration-setting">
                                    <form-group name="pickupRunScheduleStop.durationMinutes" label="Duration (mins)">
                                        <md-input
                                            type="number"
                                            v-model="runShipmentDetails.pickupRunScheduleStop.durationMinutes"
                                        />
                                    </form-group>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template slot="tab-pane-3">
                        <div class="contact-details">
                            <span>Drop off Details</span>
                            <customer-autocomplete
                                :label="runShipmentDetails.dropRunScheduleStop.customerId == null ? 'Name' : 'Customer'"
                                id="autocustomerShipmentDrop"
                                placeholder=""
                                :should-focus="false"
                                v-model="runShipmentDetails.dropRunScheduleStop"
                                :auto-fill-address="true"
                            />
                            <form-group
                                name="name"
                                label="Contact Name"
                                v-if="runShipmentDetails.dropRunScheduleStop.customerId != null"
                            >
                                <md-input v-model="runShipmentDetails.dropRunScheduleStop.contact.name" />
                            </form-group>
                            <form-group name="phone" label="Phone">
                                <md-input v-model="runShipmentDetails.dropRunScheduleStop.contact.phone" />
                            </form-group>
                            <form-group name="email" label="Email">
                                <md-input v-model="runShipmentDetails.dropRunScheduleStop.contact.email" />
                            </form-group>
                            <form-group name="notes" label="Notes">
                                <md-textarea v-model="runShipmentDetails.dropRunScheduleStop.notes" />
                            </form-group>
                        </div>
                        <div class="shipment-drop-datepicker">
                            <div>
                                <form-group name="time" label="Time" class="time-picker">
                                    <time-picker
                                        :time="dropTime"
                                        :additional-time-options="additionalDropTimeOption"
                                        @selectedTime="getDropTime"
                                    />
                                </form-group>
                            </div>
                            <div>
                                <form-group name="dropRunScheduleStop.durationMinutes" label="Duration (mins)">
                                    <md-input
                                        type="number"
                                        v-model="runShipmentDetails.dropRunScheduleStop.durationMinutes"
                                    />
                                </form-group>
                            </div>
                        </div>
                    </template>
                </tabs>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validateRunShipment">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, isAValidCoordinate, showErrorMessage } from '@/helpers';
import { requiredIf, numeric } from 'vuelidate/lib/validators';
import { RUN_SHIPMENT_DETAILS_DEFAULTS } from '@/utils/defaults';
import { MapOverviewMixin } from '@/mixins/MapOverviewMixin';
import _ from 'lodash';
import {
    TimePicker,
    GoogleAutocomplete,
    Tabs,
    CustomerAutocomplete,
    ChargingTypeOptions,
    LoadInputs,
    CustomFieldInputs
} from '@/components';
import moment from 'moment';
import { GeneralMixin, RatesEngineMixin } from '@/mixins';
import { DATE_TYPES, TIME_WINDOW_CONSTANTS } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
    name: 'CreateRunShipmentModal',
    components: {
        TimePicker,
        GoogleAutocomplete,
        Tabs,
        CustomerAutocomplete,
        ChargingTypeOptions,
        LoadInputs,
        CustomFieldInputs
    },
    mixins: [GeneralMixin, MapOverviewMixin, RatesEngineMixin],
    props: {
        runShipmentDetails: {
            type: Object,
            default: () => {}
        },
        members: {
            type: Array,
            default: () => []
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        runScheduleCustomerId: {
            type: [String, Number],
            default: () => 0
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isCustomerAdmin: 'user/isCustomerAdmin',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isSingleTeamMemberUser: 'user/isSingleTeamMember',
            allWarehouses: 'team/warehouses',
            hasRateRules: 'user/hasRateRules'
        })
    },
    async mounted() {
        this.shipmentCustomFieldList = this.user.shipmentCustomFieldDefinitions;
        this.loadFieldList = this.user.vehicleCapacityUnitsConfiguration || [];
        if (this.user.skillOptions) {
            this.skillOptions = this.user.skillOptions.map((x) => x.name);
        }

        if (!this.isUpdate) {
            this.initializeCustomFields();
        } else {
            this.customFieldValues = this.runShipmentDetails.customFields;
        }

        this.initializeLoads();
        this.initializeTime();
        await this.preLoadCustomerDetails();
    },
    methods: {
        initializeTime() {
            if (!this.isUpdate) 
                return;

            this.pickupTime = this.getStopTime(
                this.runShipmentDetails.pickupRunScheduleStop.timeWindowStart,
                this.runShipmentDetails.pickupRunScheduleStop.timeWindowEnd,
                this.runShipmentDetails.pickupRunScheduleStop.appointmentTime,
                this.additionalPickupTimeOption
            );
            this.runShipmentDetails.pickupRunScheduleStop.timeWindowStart = this.formatStopTime(
                this.runShipmentDetails.pickupRunScheduleStop.timeWindowStart,
                DATE_TYPES.militaryTime
            );
            this.runShipmentDetails.pickupRunScheduleStop.timeWindowEnd = this.formatStopTime(
                this.runShipmentDetails.pickupRunScheduleStop.timeWindowEnd,
                DATE_TYPES.militaryTime
            );
            this.runShipmentDetails.pickupRunScheduleStop.appointmentTime = this.formatStopTime(
                this.runShipmentDetails.pickupRunScheduleStop.appointmentTime,
                DATE_TYPES.militaryTime
            );

            this.dropTime = this.getStopTime(
                this.runShipmentDetails.dropRunScheduleStop.timeWindowStart,
                this.runShipmentDetails.dropRunScheduleStop.timeWindowEnd,
                this.runShipmentDetails.dropRunScheduleStop.appointmentTime,
                this.additionalDropTimeOption
            );
            this.runShipmentDetails.dropRunScheduleStop.timeWindowStart = this.formatStopTime(
                this.runShipmentDetails.dropRunScheduleStop.timeWindowStart,
                DATE_TYPES.militaryTime
            );
            this.runShipmentDetails.dropRunScheduleStop.timeWindowEnd = this.formatStopTime(
                this.runShipmentDetails.dropRunScheduleStop.timeWindowEnd,
                DATE_TYPES.militaryTime
            );
            this.runShipmentDetails.dropRunScheduleStop.appointmentTime = this.formatStopTime(
                this.runShipmentDetails.dropRunScheduleStop.appointmentTime,
                DATE_TYPES.militaryTime
            );
        },
        formatStopTime(stopTime, dateType) {
            if (stopTime != null) {
                return stopTime.length > 5 ? moment(stopTime).format(dateType) : stopTime;
            }
            return stopTime;
        },
        validateRunShipment() {
            this.$v.runShipmentDetails.$touch();
            if (!this.$v.runShipmentDetails.$invalid) {
                if (this.isUpdate) {
                    this.updateRunShipment();
                } else {
                    this.createRunShipment();
                }
            }
        },
        initializeCustomFields() {
            if (this.shipmentCustomFieldList && this.shipmentCustomFieldList.length > 0) {
                const custFieldValueList = {};
                this.shipmentCustomFieldList.forEach((item, index) => {
                    if (item.type.toLowerCase() !== 'checkbox') {
                        custFieldValueList[item.name] = null;
                    } else {
                        custFieldValueList[item.name] = false;
                    }
                });
                this.customFieldValues = custFieldValueList;
            }
        },
        initializeLoads() {
            if (this.loadFieldList && this.loadFieldList.length > 0) {
                const loadList = {};
                this.loadFieldList.forEach((item, index) => {
                    loadList[item.type] = null;
                });
                this.loadValues = loadList;

                if (this.isUpdate) {
                    this.loadValues = this.runShipmentDetails.load;
                }
            }
        },
        async createRunShipment() {
            this.$v.runShipmentDetails.$touch();
            if (!this.$v.runShipmentDetails.$invalid) {
                this.runShipmentDetails.customFields = JSON.stringify(this.customFieldValues);
                this.runShipmentDetails.load = this.loadValues;
                this.$_handleLoaderState(true, 'SAVING...');
                this.runShipmentDetails.skills =
                    this.runShipmentDetails.skills === 'null' || this.runShipmentDetails.skills === []
                        ? null
                        : this.runShipmentDetails.skills;
                const api = `/api/run-schedules/${this.runShipmentDetails.runScheduleId}/run-shipments`;
                const payload = {
                    method: 'post',
                    data: this.runShipmentDetails
                };
                try {
                    await handleRequests(api, payload);
                    this.$notify({
                        message: 'Successfully created a shipment.',
                        type: 'success'
                    });
                    this.$v.$reset();
                    this.resolve('ok');
                } catch (e) {
                    this.$_handleLoaderState(false);
                    const message = 'Could not create a new shipment.';
                    showErrorMessage(this, message, e);
                }
            }
        },
        async updateRunShipment() {
            this.$v.runShipmentDetails.$touch();
            if (!this.$v.runShipmentDetails.$invalid) {
                this.runShipmentDetails.customFields = JSON.stringify(this.customFieldValues);
                this.runShipmentDetails.load = this.loadValues;
                this.$_handleLoaderState(true, 'SAVING...');
                this.runShipmentDetails.skills =
                    this.runShipmentDetails.skills === 'null' || this.runShipmentDetails.skills === []
                        ? null
                        : this.runShipmentDetails.skills;

                const api = `/api/run-schedules/${this.runShipmentDetails.runScheduleId}/run-shipments/${
                    this.runShipmentDetails.runScheduleShipmentId
                }`;

                const payload = {
                    method: 'put',
                    data: this.runShipmentDetails
                };
                try {
                    await handleRequests(api, payload);
                    this.$notify({
                        message: 'Successfully updated the shipment.',
                        type: 'success'
                    });
                    this.$v.$reset();
                    this.resolve('ok');
                } catch (e) {
                    this.$_handleLoaderState(false);
                    const message = 'Could not update shipment.';
                    showErrorMessage(this, message, e);
                }
            }
        },
        getPickupTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime, timeWindowStart, timeWindowEnd } = selectedTime;
                this.runShipmentDetails.pickupRunScheduleStop.appointmentTime = appointmentTime;
                this.runShipmentDetails.pickupRunScheduleStop.timeWindowStart = timeWindowStart;
                this.runShipmentDetails.pickupRunScheduleStop.timeWindowEnd = timeWindowEnd;
                this.pickupTime = selectedTime;
                this.pickupTime = this.getStopTime(
                    this.runShipmentDetails.pickupRunScheduleStop.timeWindowStart,
                    this.runShipmentDetails.pickupRunScheduleStop.timeWindowEnd,
                    this.runShipmentDetails.pickupRunScheduleStop.appointmentTime,
                    this.additionalPickupTimeOption
                );
            } else {
                this.runShipmentDetails.pickupRunScheduleStop.appointmentTime = null;
                this.runShipmentDetails.pickupRunScheduleStop.timeWindowStart = null;
                this.runShipmentDetails.pickupRunScheduleStop.timeWindowEnd = null;
                this.pickupTime = null;
            }
            this.isPickupTimeValid = isTimeValid;
        },
        getDropTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime, timeWindowStart, timeWindowEnd } = selectedTime;
                this.runShipmentDetails.dropRunScheduleStop.appointmentTime = appointmentTime;
                this.runShipmentDetails.dropRunScheduleStop.timeWindowStart = timeWindowStart;
                this.runShipmentDetails.dropRunScheduleStop.timeWindowEnd = timeWindowEnd;
                this.dropTime = this.getStopTime(
                    this.runShipmentDetails.dropRunScheduleStop.timeWindowStart,
                    this.runShipmentDetails.dropRunScheduleStop.timeWindowEnd,
                    this.runShipmentDetails.dropRunScheduleStop.appointmentTime,
                    this.additionalDropTimeOption
                );
            } else {
                this.runShipmentDetails.dropRunScheduleStop.appointmentTime = null;
                this.runShipmentDetails.dropRunScheduleStop.timeWindowStart = null;
                this.runShipmentDetails.dropRunScheduleStop.timeWindowEnd = null;
                this.dropTime = null;
            }
            this.isDropTimeValid = isTimeValid;
        },
        resetShipmentState() {
            Object.assign(this.runShipmentDetails, RUN_SHIPMENT_DETAILS_DEFAULTS());
            this.$v.$reset();
        },
        handleCustomFieldChanged({ name, value }) {
            this.customFieldValues[name] = value;
        },
        handleLoadChanged({ name, value }) {
            this.loadValues[name] = parseFloat(value);
        },
        getStopTime(stopTimeWindowStart, stopTimeWindowEnd, stopAppointmentTime, additionalTimeOption) {
            let stopTime = null;
            if (stopAppointmentTime != null) {
                stopTime = this.formatStopTime(stopAppointmentTime, DATE_TYPES.militaryTime);
            } else if (stopTimeWindowStart != null && stopTimeWindowEnd != null) {
                const timeWindowStart = this.formatStopTime(stopTimeWindowStart, DATE_TYPES.militaryTime);
                const timeWindowEnd = this.formatStopTime(stopTimeWindowEnd, DATE_TYPES.militaryTime);

                const timeWindowStartStandard =
                    stopTimeWindowStart.length > 5
                        ? moment(stopTimeWindowStart).format(DATE_TYPES.standardTime)
                        : moment(stopTimeWindowStart, DATE_TYPES.militaryTime).format(DATE_TYPES.standardTime);
                const timeWindowEndStandard =
                    stopTimeWindowEnd.length > 5
                        ? moment(stopTimeWindowEnd).format(DATE_TYPES.standardTime)
                        : moment(stopTimeWindowEnd, DATE_TYPES.militaryTime).format(DATE_TYPES.standardTime);

                let hasMatch = false;

                TIME_WINDOW_CONSTANTS.some((x) => {
                    if (timeWindowStart === x.timeWindowStart && timeWindowEnd === x.timeWindowEnd) {
                        stopTime = x.name;
                        hasMatch = true;
                    }
                    return timeWindowStart === x.timeWindowStart && timeWindowEnd === x.timeWindowEnd;
                });

                if (!hasMatch) {
                    stopTime = `${timeWindowStartStandard} - ${timeWindowEndStandard}`;
                    additionalTimeOption.push({
                        name: stopTime,
                        timeWindowStart,
                        timeWindowEnd
                    });
                }
            } else {
                stopTime = 'None';
            }

            return stopTime;
        },
        handlePickupCoordinates(value) {
            this.usePickupCoordinates = value;
            this.$v.$reset();
        },
        handleDropCoordinates(value) {
            this.useDropCoordinates = value;
            this.$v.$reset();
        },
        // eslint-disable-next-line func-names
        handleShipmentDetailsChanged: _.debounce(function() {
            if (
                this.runShipmentDetails.pickupRunScheduleStop.address !== this.pickupAddress ||
                this.runShipmentDetails.dropRunScheduleStop.address !== this.dropStop
            ) {
                this.pickupAddress = this.runShipmentDetails.pickupRunScheduleStop.address;
                this.dropStop = this.runShipmentDetails.dropRunScheduleStop.address;
            }
        }, 500),
        openCharges() {
            document.querySelector('.charge-wrapper').classList.toggle('side-panel-open');
            document.querySelector('.charge-wrapper').classList.toggle('side-panel');
        },
        handleRateTypeChanged(rateRule) {
            this.runShipmentDetails.rateGroupId = rateRule?.rateGroupId ?? null;
        },
        handleCustomerChangeRateGroup(customerRateGroupId) {
            if (this.runShipmentDetails.rateGroupId === null || this.runShipmentDetails.rateGroupId === undefined) {
                this.runShipmentDetails.rateGroupId = customerRateGroupId;
            }
        },
        async getCustomerDetails(customerId) {
            let customerData;
            try {
                const api = `/api/customers/${customerId}`;
                const { data } = await handleRequests(api);
                customerData = data;
            } catch (error) {
                const message = 'Error in getting the customer details';
                showErrorMessage(this, message, error);
            }
            return customerData;
        },
        async preLoadCustomerDetails() {
            if (this.runScheduleCustomerId && this.runScheduleCustomerId > 0 && !this.isUpdate) {
                const customerDetails = await this.getCustomerDetails(this.runScheduleCustomerId);

                if (customerDetails) {
                    this.runShipmentDetails.rateGroupId = customerDetails.defaultRateGroupId;
                    this.runShipmentDetails.customerId = customerDetails.customerId;
                }
            }
        },
        customerNameIsDisabled() {
            if (
                !this.isUpdate ||
                !this.runShipmentDetails ||
                !this.runScheduleCustomerId ||
                this.runScheduleCustomerId === 0
            )
                return true;

            // We will let user to update shipment's customer ONLY IF this is different from run schedule customer
            return this.runShipmentDetails.customerId === this.runScheduleCustomerId;
        }
    },
    data() {
        return {
            isPickupTimeValid: true,
            isDropTimeValid: true,
            shipmentCustomFieldList: [],
            customFieldValues: {},
            loadFieldList: [],
            loadValues: {},
            skillOptions: [],
            pickupTime: 'None',
            dropTime: 'None',
            additionalPickupTimeOption: [],
            additionalDropTimeOption: [],
            usePickupCoordinates: false,
            useDropCoordinates: false,
            rateListData: null,
            currency: '',
            chargeList: [],
            ratesIsLoading: false,
            pickupAddress: null,
            dropAddress: null,
            costList: []
        };
    },
    validations: {
        runShipmentDetails: {
            pickupRunScheduleStop: {
                address: {
                    required:
                        // eslint-disable-next-line func-names
                        requiredIf(function() {
                            return !this.usePickupCoordinates;
                        }),
                    coordinatesValid(data) {
                        if (this.usePickupCoordinates) {
                            return isAValidCoordinate(data);
                        }
                        return true;
                    }
                },
                durationMinutes: {
                    numeric
                }
            },
            dropRunScheduleStop: {
                address: {
                    required:
                        // eslint-disable-next-line func-names
                        requiredIf(function() {
                            return !this.useDropCoordinates;
                        }),
                    coordinatesValid(data) {
                        if (this.useDropCoordinates) {
                            return isAValidCoordinate(data);
                        }
                        return true;
                    }
                },
                durationMinutes: {
                    numeric
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.modal-container {
    max-width: 600px;
    .modal-body {
        min-height: 400px;
        padding-bottom: 0;
        ::v-deep .md-card-tabs .tab-content {
            max-height: 65vh;
            overflow: auto;
        }
    }
    .stats {
        flex-grow: 8;
        .md-icon {
            color: #ffd900;
        }
    }
    .tip {
        font-size: 12px;
    }
}
.custom-tab-list {
    margin-top: -20px;
    ::v-deep .md-card-header {
        padding: 0;
    }
    ::v-deep .md-card-content {
        padding: 0;
        .md-list {
            margin: 0;
            .md-list-item:first-child {
                margin-left: 0;
            }
        }
    }
}
.shipment-form-container {
    padding-top: 20px;
    max-height: 80vh;
    padding-right: 10px;
}
.trip-settings {
    float: left;
    margin-right: 20px;
    margin-top: 20px;
    min-width: 125px;
    max-width: 30%;
}
.duration-setting {
    min-width: 90px;
    max-width: 100px;
}
.contact-details {
    margin-top: 20px;
}
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.shipment-drop-datepicker {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    ::v-deep .md-clear {
        display: none;
    }
    > div {
        margin-right: 20px;
        min-width: 125px;
    }
    > div:first-child {
        width: 35%;
    }
    > div:last-child {
        margin-right: 0;
        min-width: 100px;
        max-width: 100px;
    }
    .error {
        font-size: 12px;
        color: red;
        margin-top: -10px;
        display: block;
    }
    .md-input {
        width: 100%;
    }
    .time-picker:after {
        height: 0;
    }
    .time-window {
        width: 100%;
        > div {
            width: 100%;
        }
        > div:first-child {
            text-transform: uppercase;
            display: block;
            font-size: 12px;
            font-weight: 600;
            text-align: left;
        }
        > div:nth-child(2) {
            ::v-deep .md-radio {
                margin: 0;
                margin-right: 15px;
            }
            text-transform: uppercase;
            text-align: left;
            font-size: 12px;
        }
    }
}
.charge-wrapper {
    padding-left: 25px;
}
.side-panel {
    display: none;
    width: 500px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 600px;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    border-radius: 15px;
    border: 2px solid #000000;
    max-width: 500px;
    overflow: auto;
    background-color: #ffffff;
}
.side-panel-open {
    display: initial;
    width: 500px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 601px;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    border-radius: 15px;
    border: 2px solid #ffffff;
    max-width: 500px;
    overflow: auto;
    background-color: #ffffff;
    box-shadow: 0 0 10px (0, 0, 0, 0.35);
}
.chargeButton-toggle {
    float: right;
    border: none;
    background-color: #4caf50;
    position: relative;
    place-items: center;
    display: grid;
    color: white !important;
}
.chargeButton-toggle:hover {
    background: #409443;
}
.sp-icon-close {
    display: none !important;
}
.sp-icon-open {
}
.material-symbols-outlined {
    place-items: center;
    display: grid;
    color: #ffff;
}
</style>
