var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.invoice.invoiceNumber) + " Error"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "modal-body" }, [
      _vm._v(
        "\n        " + _vm._s(_vm.invoice.accountingSystemNote) + "\n    "
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          { staticClass: "md-primary dialog-button", on: { click: _vm.retry } },
          [_vm._v("\n            Retry sync\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }