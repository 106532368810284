var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-header" }, [
      !_vm.isLocationDetailsLoading
        ? _c("h4", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.modalTitle)),
          ])
        : _c(
            "div",
            { staticClass: "loader" },
            [_c("fade-loader", { attrs: { loading: true, color: "#333333" } })],
            1
          ),
      _c(
        "div",
        { staticClass: "modal-header--actions" },
        [
          !_vm.isLocationDetailsLoading
            ? _c("search-component", {
                attrs: { placeholder: "Search" },
                on: { onSearch: _vm.handleSearch },
              })
            : _vm._e(),
          _c(
            "md-button",
            {
              staticClass:
                "md-simple md-just-icon md-round modal-default-button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$modal.hide($event)
                },
              },
            },
            [_c("md-icon", [_vm._v("clear")])],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "modal-body" }, [
      !_vm.isListLoading
        ? _c(
            "div",
            [
              _c(
                "md-table",
                [
                  _c(
                    "md-table-row",
                    [
                      _c("md-table-head", [_vm._v("Barcode")]),
                      _c("md-table-head", [_vm._v("Description")]),
                    ],
                    1
                  ),
                  _vm._l(_vm.inventoryList, function (item) {
                    return _c(
                      "md-table-row",
                      { key: item.itemId },
                      [
                        _c(
                          "md-table-cell",
                          { staticClass: "item-barcode" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  title: "View item details",
                                  to: {
                                    name: "Inventory Details",
                                    params: { itemId: item.itemId },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.barcode) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("md-table-cell", [_vm._v(_vm._s(item.description))]),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _vm.inventoryList.length == 0
                ? _c("div", [
                    _c("p", { staticClass: "no-result-message" }, [
                      _vm._v(
                        "No results matching your search/filter could be found."
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "loader" },
            [_c("fade-loader", { attrs: { loading: true, color: "#333333" } })],
            1
          ),
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c("div", [
          _vm.total === _vm.pagination.perPage
            ? _c("p", { staticClass: "card-category" }, [
                _vm._v(
                  "\n                Page " +
                    _vm._s(_vm.pagination.currentPage) +
                    " of many\n            "
                ),
              ])
            : _c("p", { staticClass: "card-category" }, [
                _vm._v(
                  "Page " +
                    _vm._s(_vm.pagination.currentPage) +
                    " of " +
                    _vm._s(_vm.totalPages)
                ),
              ]),
        ]),
        _c("pagination", {
          staticClass: "pagination-no-border pagination-success",
          attrs: { "per-page": _vm.pagination.perPage, total: _vm.total },
          on: {
            "change-page": function ($event) {
              return _vm.handleChangePage($event, _vm.pagination.perPage)
            },
          },
          model: {
            value: _vm.pagination.currentPage,
            callback: function ($$v) {
              _vm.$set(_vm.pagination, "currentPage", $$v)
            },
            expression: "pagination.currentPage",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }