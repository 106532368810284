<template>
    <div class="details-page" v-if="invoiceDetails != null">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <h3 class="title">
                            {{ invoiceDetails.invoiceNumber }}
                        </h3>
                    </div>
                    <div class="custom-toolbar-end" v-if="!$root.isMobileOnly"></div>
                </div>
            </div>
        </div>
        <div class="md-layout md-alignment-top-left content-layout-panel">
            <div
                class="md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-100 md-xlarge-size-60"
            >
                <div class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-blue">
                            <div class="card-icon">
                                <md-icon>description</md-icon>
                            </div>
                        </md-card-header>

                        <md-card-content>
                            <div>
                                <div class="details-box" v-if="invoiceDetails.accountingSystemRef">
                                    <label>Accounting System Reference:</label>
                                    <span>
                                        <a
                                            target="_blank"
                                            :href="
                                                `https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=${
                                                    invoiceDetails.accountingSystemRef
                                                }`
                                            "
                                        >
                                            Open invoice in {{ invoiceDetails.accountingSystemName }}
                                        </a>
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label>PO Number</label>
                                    <span>
                                        {{ invoiceDetails.purchaseOrderNumber }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label>Team Member</label>
                                    <span>
                                        {{ invoiceDetails.teamMember.fullName }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label>Invoice Date</label>
                                    <span>
                                        {{ invoiceDetails.invoiceDate | dateFormat(DATE_TYPES.standardDate) }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label>Due Date</label>
                                    <span>
                                        {{ invoiceDetails.dueDate | dateFormat(DATE_TYPES.standardDate) }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label>Status</label>
                                    <span>
                                        {{ invoiceDetails.status }}
                                    </span>
                                </div>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>

                <div class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100">
                    <invoice-lines :lines="invoiceDetails.invoiceLines" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { InvoiceLines } from './components';

export default {
    name: 'TeamMemberInvoicesDetails',
    components: {
        InvoiceLines
    },
    mixins: [GeneralMixin],
    async mounted() {
        this.$_handleLoaderState(true);
        await this.getTeamMemberInvoiceDetails();
        this.$_handleLoaderState(false);
    },
    data() {
        return {
            invoiceDetails: null
        };
    },
    methods: {
        async getTeamMemberInvoiceDetails() {
            const api = `/api/team-member-invoices/${this.$route.params.invoiceId}`;
            const { data } = await handleRequests(api);
            this.invoiceDetails = data;
        }
    }
};
</script>

<style lang="scss" scoped>
.details-page {
    padding-top: 40px;

    ::v-deep .md-table-row:hover {
        background-color: #fafafa;
        cursor: pointer;
    }

    ::v-deep .md-field.md-disabled:after {
        border-bottom: none;
    }

    ::v-deep .md-field:after {
        height: 0;
    }

    ::v-deep .md-field:before {
        background-color: initial !important;
    }
}

::v-deep .md-card-header-icon {
    z-index: 9;
}

.custom-toolbar {
    .title {
        margin-bottom: 0;
    }
}

.content-layout-panel {
    > div {
        padding: 0;
    }
}

@media (min-width: 768px) {
    .details-page {
        padding-top: 0;
    }
}
</style>
