<template>
    <div class="information-box">
        <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-100">
                <form-group
                    label="Team Member Invoice Prefix"
                    name="settings.invoiceAffixSettings.teamMemberInvoiceNumberPrefix"
                >
                    <md-input v-model="settings.invoiceAffixSettings.teamMemberInvoiceNumberPrefix" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
                <form-group
                    label="Team Member Invoice Suffix"
                    name="settings.invoiceAffixSettings.teamMemberInvoiceNumberSuffix"
                >
                    <md-input v-model="settings.invoiceAffixSettings.teamMemberInvoiceNumberSuffix" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
                <form-group class="sample-invoice" label="Team Member Invoice Example">
                    <md-input disabled :value="exampleTeamMemberInvoiceNumber" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
                <md-checkbox
                    v-model="settings.sendAsPaymentSummary"
                >
                     Send as payment Summary
                     <md-tooltip>
                         This will send a payment summary to team members rather than an RCTI tax invoice.
                     </md-tooltip>
                 </md-checkbox>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-100">
                <form-group label="Customer Invoice Prefix" name="settings.invoiceAffixSettings.customerInvoiceNumberPrefix">
                    <md-input v-model="settings.invoiceAffixSettings.customerInvoiceNumberPrefix" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
                <form-group label="Customer Invoice Suffix" name="settings.invoiceAffixSettings.customerInvoiceNumberSuffix">
                    <md-input v-model="settings.invoiceAffixSettings.customerInvoiceNumberSuffix" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
                <form-group class="sample-invoice" label="Customer Invoice Example">
                    <md-input disabled :value="exampleCustomerInvoiceNumber" />
                </form-group>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InvoiceAffixSettings',
    props: {
        settings: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        exampleTeamMemberInvoiceNumber() {
            const prefix = this.settings.invoiceAffixSettings.teamMemberInvoiceNumberPrefix ?? '';
            const suffix = this.settings.invoiceAffixSettings.teamMemberInvoiceNumberSuffix ?? '';

            return `(Ex: ${prefix}0001${suffix})`;
        },
        exampleCustomerInvoiceNumber() {
            const prefix = this.settings.invoiceAffixSettings.customerInvoiceNumberPrefix ?? '';
            const suffix = this.settings.invoiceAffixSettings.customerInvoiceNumberSuffix ?? '';

            return `(Ex: ${prefix}0001${suffix})`;
        }
    }
};
</script>
<style lang="scss" scoped>
.sample-invoice.md-disabled::after {
    border-bottom: 0 !important;
}
</style>
