<template>
    <div class="main-container">
        <div class="dayTime-container">
            <span class="custom-label">Distance</span>
            <md-input id="distance-input" v-model.number="model.distance" type="number" class="distance-element" />
        </div>
        <div class="dayTime-container">
            <span class="custom-label">Unit</span>
            <vue-select
                :reduce="(o) => o.value"
                label="text"
                :options="unitOptions"
                v-model="model.unit"
                :clearable="false"
                class="distance-element"
            ></vue-select>
        </div>
    </div>
</template>

<script>
import { DISTANCE_UNIT_OPTIONS } from '@/utils/constants';

export default {
    name: 'DistanceInput',
    props: {
        distance: { type: Number, default: 0 },
        unit: { type: String, default: 'km' }
    },
    data() {
        return {
            model: {
                distance: 0,
                unit: 'km'
            },
            unitOptions: DISTANCE_UNIT_OPTIONS
        };
    },
    watch: {
        distance(newVal, oldVal) {
            if (newVal !== oldVal) 
                this.parseValue();
        },
        unit(newVal, oldVal) {
            if (newVal !== oldVal) 
                this.parseValue();
        },
        'model.distance': function(newVal, oldVal) {
            if (newVal !== oldVal) 
                this.handleChange();
        },
        'model.unit': function(newVal, oldVal) {
            if (newVal !== oldVal) 
                this.handleChange();
        }
    },
    methods: {
        // eslint-disable-next-line func-names
        handleChange: _.debounce(function() {
            this.raiseEvent();
        }, 100),
        raiseEvent() {
            const args = {
                distance: this.model.distance,
                unit: this.model.unit,
                distanceInMeters: this.computeInMeters()
            };

            this.$emit('changed', args);
        },
        computeInMeters() {
            switch (this.model.unit) {
                case 'km':
                    return (this.model.distance || 0) * 1000;
                case 'mi':
                    return (this.model.distance || 0) * 1609.34;
                case 'm':
                default:
                    return this.model.distance || 0;
            }
        },
        parseValue() {
            this.model = {
                distance: this.distance,
                unit: this.unit
            };
        }
    },
    mounted() {
        this.parseValue();
    }
};
</script>

<style scoped lang="scss">
.main-container {
    width: 100%;
}

.dayTime-container {
    display: inline-block;
    border-bottom: 1px solid #ddd;
    margin-right: 10px;
    width: calc((100% - 30px) / 2);

    .distance-element {
        width: 100%;
    }
}

.dayTime-container:last-child {
    margin-right: 0;
}
</style>
