<template>
    <div class="md-layout team-member-list">
        <div class="md-layout team-member-tools">
            <div class="md-layout-item md-size-20">
                <ul class="team-member-list-ui">
                    <li class="team-member-list-li-select-all">
                        <md-checkbox v-model="isSelectAll" @change="onSelectAll(isSelectAll)">Select All</md-checkbox>
                    </li>
                </ul>
            </div>
            <div class="md-layout-item md-size-40 search-team-member">
                <div class="search-icon">
                    <md-icon>search</md-icon>
                </div>
                <search-component
                    :handle-search-on-type="true"
                    :search-icon="false"
                    :placeholder="placeholderText"
                    @onSearch="handleSearch"
                />
            </div>
            <div class="md-layout md-layout-item md-size-40 filter-link">
                <div class="md-layout-item filter-menu-dropdown md-size-65">
                    <drop-down direction="down">
                        <a href="#">
                            <md-icon class="icon-filter">filter_alt</md-icon>
                            Filter
                        </a>
                        <ul class="dropdown-menu">
                            <li v-if="showTeamRegion" class="dropdown-child-menu">
                                <a href="#">
                                    By Team Region
                                    <md-icon class="icon-arrow">navigate_next</md-icon>
                                </a>
                                <ul>
                                    <li v-for="(teamRegionMenu, index) in teamRegionMenuOptions" :key="index">
                                        <md-icon
                                            :class="['icon-check', teamRegionMenu.checked ? 'icon-check-color' : '']"
                                        >
                                            check
                                        </md-icon>
                                        <a href="#" @click="handleFilterTeamRegionClick(teamRegionMenu)">
                                            {{ teamRegionMenu.name }}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </drop-down>
                </div>
                <div class="md-layout-item filter-menu md-size-35">
                    <drop-down direction="down">
                        <a href="#">
                            <md-icon class="icon-filter">settings</md-icon>
                            Options
                        </a>
                        <ul class="dropdown-menu">
                            <li class="separator">
                                <md-checkbox v-model="optimiseConfiguration.includeAssignedStops">
                                    Include assigned stops in routing
                                </md-checkbox>
                            </li>
                            <li>
                                <md-radio v-model="optimiseConfiguration.isDistributeWorkload" :value="false">
                                    Minimise team members required
                                </md-radio>
                                <md-radio v-model="optimiseConfiguration.isDistributeWorkload" :value="true">
                                    Spread workload evenly
                                </md-radio>
                            </li>
                        </ul>
                    </drop-down>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100" :class="isLoading ? 'loader-margin' : ''">
            <div class="loader" v-if="isLoading">
                <fade-loader :loading="isLoading" color="#333333" />
            </div>
            <md-table :value="orderedTeamMembers" class="team-member-table">
                <md-table-row
                    :class="
                        item.routeDetails == null && startLocationMode === 'teammembers' ? 'team-member-disabled' : ''
                    "
                    slot="md-table-row"
                    slot-scope="{ item }"
                >
                    <md-table-cell class="team-member-checkbox">
                        <md-avatar v-if="item.routeDetails == null && startLocationMode === 'teammembers'">
                            <md-icon class="icon-warning">warning</md-icon>
                            <md-tooltip class="tooltip-width" md-direction="right">
                                This team member cannot be included because they have no start location set
                            </md-tooltip>
                        </md-avatar>
                        <md-checkbox
                            v-else
                            type="checkbox"
                            :value="item.publicUserId"
                            v-model="selectedTeamMembers"
                        ></md-checkbox>
                    </md-table-cell>
                    <md-table-cell class="team-member-image">
                        <img
                            v-if="item.photoUrl"
                            class="profile-image"
                            :src="item.photoUrl"
                            @error="$_setDefaultBrokenImage"
                            alt
                        />
                        <img v-else class="member-image" :src="$root.defaultPhotoUrl" alt />
                    </md-table-cell>
                    <md-table-cell class="team-member-text">
                        <router-link
                            :to="{
                                name: 'Team Member Profile',
                                params: { publicUserId: item.publicUserId }
                            }"
                            target="_blank"
                        >
                            {{ item.fullName }}
                        </router-link>
                    </md-table-cell>
                    <md-table-cell class="team-member-customer" v-if="item.tripDetails">
                        <md-avatar v-if="item.tripDetails.customerName">
                            <span class="tooltip-place">
                                {{
                                    item.tripDetails.customerName.length > 13
                                        ? `${item.tripDetails.customerName.slice(0, 13)} ...`
                                        : item.tripDetails.customerName
                                }}
                            </span>
                            <md-tooltip class="warning-tooltip" md-direction="right">
                                <p>
                                    All stops that get routed to
                                    <b>{{ item.fullName }}</b>
                                    will have the customer updated to match the trip customer:
                                    <b>{{ item.tripDetails.customerName }}</b>
                                    .
                                </p>
                            </md-tooltip>
                        </md-avatar>
                    </md-table-cell>
                    <md-table-cell class="team-member-place" v-if="item.tripDetails">
                        <md-avatar>
                            <md-icon class="icon-place">place</md-icon>
                            <span class="tooltip-place">{{ item.tripDetails.stopsCount }} Stops</span>
                            <md-tooltip class="tooltip-width" md-direction="right">
                                {{ item.firstName }} already has {{ item.tripDetails.stopsCount }} stops assigned for
                                their trip on this date
                            </md-tooltip>
                        </md-avatar>
                    </md-table-cell>
                    <md-table-cell v-if="item.tripDetails" class="team-member-warning">
                        <md-avatar>
                            <md-icon class="warning-icon">warning</md-icon>
                            <md-tooltip class="warning-tooltip" md-direction="right">
                                <p
                                    v-if="
                                        item.tripDetails.startTime != optimiseConfiguration.startTime ||
                                            item.tripDetails.endTime != optimiseConfiguration.endTime
                                    "
                                >
                                    <b>{{ item.firstName }}</b>
                                    has their availability set to
                                    <b>{{ item.tripDetails.startTime | timeFormat(DATE_TYPES.standardTime, true) }}</b>
                                    to
                                    <b>{{ item.tripDetails.endTime | timeFormat(DATE_TYPES.standardTime, true) }}</b>
                                    for this date, which differs from the
                                    <b>
                                        {{
                                            optimiseConfiguration.startTime | timeFormat(DATE_TYPES.standardTime, true)
                                        }}
                                    </b>
                                    to
                                    <b>
                                        {{ optimiseConfiguration.endTime | timeFormat(DATE_TYPES.standardTime, true) }}
                                    </b>
                                    timeframe set in the optimise settings.
                                    <br />
                                    <b>{{ item.firstName }}'s</b>
                                    availability timeframe will be used when creating their trip. Alternatively, check
                                    the box below the trip list to override the team members existing trip settings with
                                    the optimise settings.
                                </p>
                                <p
                                    v-if="
                                        optimiseConfiguration.startLocationMode === 'manual' &&
                                            item.tripDetails.startAddress != optimiseConfiguration.startLocation.address
                                    "
                                >
                                    <b>{{ item.firstName }}</b>
                                    already has a trip for this date, with their start location set to
                                    <b>{{ item.tripDetails.startAddress }}</b>
                                    , which differs from the start location set in the optimise settings (
                                    <b>{{ optimiseConfiguration.startLocation.address }}</b>
                                    ).
                                    <br />
                                    <b>{{ item.firstName }}'s</b>
                                    start location will be used when creating their trip. Alternatively, check the box
                                    below the trip list to override the team members existing trip settings with the
                                    optimise settings.
                                </p>
                                <p
                                    v-else-if="
                                        item.tripDetails.endAddress != optimiseConfiguration.endLocation.address &&
                                            optimiseConfiguration.endLocationMode != 'none'
                                    "
                                >
                                    <b>{{ item.firstName }}</b>
                                    already has a trip for this date, with their end location set to
                                    <b>{{ item.tripDetails.endAddress }}</b>
                                    , which differs from the end location set in the optimise settings (
                                    <b>{{ optimiseConfiguration.endLocation.address }}</b>
                                    ).
                                    <br />
                                    <b>{{ item.firstName }}'s</b>
                                    end location will be used when creating their trip. Alternatively, check the box
                                    below the trip list to override the team members existing trip settings with the
                                    optimise settings.
                                </p>
                            </md-tooltip>
                        </md-avatar>
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { SearchComponent } from '@/components';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { showTeamRegionControl } from '@/helpers';
import { mapGetters } from 'vuex';

export default {
    mixins: [GeneralMixin],
    name: 'TeamMemberStep',
    components: {
        FadeLoader,
        SearchComponent
    },
    props: {
        teamMembers: {
            type: Array,
            default: () => {}
        },
        startLocationMode: {
            type: String,
            default: null
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        optimiseConfiguration: {
            type: Object,
            default: () => {}
        },
        teamRegions: { type: Array, default: () => [] }
    },
    data() {
        return {
            isSelectAll: true,
            orderedTeamMembers: [],
            placeholderText: 'Find team member...',
            selectedTeamMembers: [],
            teamRegionMenuOptions: null,
            startTeamRegionId: null
        };
    },
    mounted() {
        this.startTeamRegionId = this.optimiseConfiguration.teamRegionId || this.user.teamRegionId;
        this.initTeamRegionFilter();
    },
    methods: {
        onSelectAll(value) {
            if (this.isSelectAll) {
                this.selectedTeamMembers = this.teamMembers.map((x) => x.publicUserId);
            } else if (value === false) {
                this.selectedTeamMembers = [];
            }
        },
        updateSelectAll() {
            this.isSelectAll = this.selectedTeamMembers.length === this.teamMembers.length;
        },
        setWorkloadBalance(enable) {
            this.optimiseConfiguration.isDistributeWorkload = enable;
        },
        getSortedTeamMembers() {
            return this.teamMembers.slice().sort((x, y) => (x.fullName > y.fullName ? 1 : -1));
        },
        handleSearch(value) {
            const { searchText } = value;
            if (searchText) {
                const filterTeamMembers = this.teamMembers.filter((teamMember) => {
                    return teamMember.fullName.toLowerCase().match(searchText.toLowerCase());
                });
                this.orderedTeamMembers = filterTeamMembers;
            } else {
                this.orderedTeamMembers = this.getSortedTeamMembers();
            }
            this.selectedTeamMembers = this.orderedTeamMembers.map((x) => x.publicUserId);
        },
        initTeamRegionFilter() {
            this.updateTeamRegionFilters(this.startTeamRegionId);
            this.setSelectionFilter(this.startTeamRegionId);
            this.handleFilterByTeamRegion(this.startTeamRegionId);
        },
        updateTeamRegionFilters(currentUserTeamRegionId) {
            if (this.teamRegions && this.teamRegions.length > 0) {
                const filtered = this.teamRegions.flatMap((obj) => {
                    if (
                        this.user.teamRegionId &&
                        this.user.teamRegionId !== -1 &&
                        this.user.teamRegionId !== obj.teamRegionId
                    )
                        return [];
                    return [
                        {
                            ...obj,
                            checked: currentUserTeamRegionId === obj.teamRegionId,
                            unassignedStops: obj.unassignedStops
                        }
                    ];
                });
                const teamRegionDefaultOptions = [
                    {
                        name: 'All',
                        teamRegionId: null,
                        checked: currentUserTeamRegionId === null
                    },
                    {
                        name: 'None',
                        teamRegionId: -1,
                        checked: currentUserTeamRegionId === -1,
                        unassignedStops: this.unassignedStopsWithoutTeamRegion
                    }
                ];
                this.teamRegionMenuOptions = [...teamRegionDefaultOptions, ...filtered];
            }
        },
        handleFilterTeamRegionClick(option) {
            this.setSelectionFilter('Team Region', option.teamRegionId);
            const teamRegionId = option.teamRegionId && option.teamRegionId === 0 ? null : option.teamRegionId;
            this.handleFilterByTeamRegion(teamRegionId);
        },
        handleFilterByTeamRegion(teamRegionId) {
            if (teamRegionId) {
                const trId = teamRegionId === -1 ? null : teamRegionId;
                const filterTeamMembers = this.teamMembers.filter((teamMember) => {
                    return teamMember.teamRegionId === trId;
                });

                this.orderedTeamMembers = filterTeamMembers;
            } else {
                this.orderedTeamMembers = this.getSortedTeamMembers();
            }
            this.selectedTeamMembers = this.orderedTeamMembers.map((x) => x.publicUserId);
        },
        setSelectionFilter(type, optionId) {
            if (type === 'Team Region') {
                this.teamRegionMenuOptions.find((x) => x.checked === true).checked = false;
                this.teamRegionMenuOptions.find((x) => x.teamRegionId === optionId).checked = true;
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasAssetAccess: 'user/hasAssetAccess'
        }),
        showTeamRegion() {
            return showTeamRegionControl(
                {
                    user: this.user,
                    hasAssetAccess: this.hasAssetAccess,
                    teamRegions: this.teamRegions
                },
                this.user.teamRegionId
            );
        }
    },
    watch: {
        selectedTeamMembers(teamMembers) {
            this.updateSelectAll();
            const teamMembersList = [];
            teamMembers.forEach((publicUserId) => {
                const teamMember = this.teamMembers.find((teamMember) => teamMember.publicUserId === publicUserId);
                if (teamMember) {
                    teamMembersList.push(teamMember);
                }
            });
            this.$emit('selectedTeamMembers', teamMembersList);
        },
        startLocationMode() {
            this.isSelectAll = true;
            this.onSelectAll(true);
        },
        teamMembers() {
            // selectedTeamMembers
            if (!this.teamMembers || this.teamMembers.length === 0) {
                this.orderedTeamMembers = [];
                this.selectedTeamMembers = [];
                this.isSelectAll = false;
            } else {
                this.onSelectAll();
                this.orderedTeamMembers = this.getSortedTeamMembers();
            }
        },
        teamRegions: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.updateTeamRegionFilters(this.optimiseConfiguration.teamRegionId);
            }
        },
        'optimiseConfiguration.teamRegionId': function(newValue, oldValue) {
            if (oldValue !== undefined) {
                this.startTeamRegionId = newValue;
                this.initTeamRegionFilter();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.team-member-list {
    margin-top: -25px;
    .md-layout-item {
        padding-right: 0px;
        padding-left: 0px;
    }
}
.team-member-list-ui {
    height: 19px;
    margin-top: 10px;
}
::v-deep .loader {
    position: relative;
    top: 20%;
    left: 50%;
}
.team-member-table {
    padding-left: 0;
    line-height: 18px;
    list-style-type: none;
    max-height: 350px;
    background-color: transparent;
    ::v-deep > div {
        background-color: transparent !important;
    }
    ::v-deep .md-table-head {
        display: none;
    }

    .md-table-cell {
        padding: 0px;
        border: 0px;
        height: 32px;
    }
    .team-member-checkbox {
        width: 40px;
    }
    .team-member-image {
        width: 40px;
        img {
            width: 30px;
            border-radius: 50%;
        }
    }

    .team-member-disabled {
        .profile-image,
        .member-image {
            opacity: 0.3;
        }
        .icon-warning {
            color: #ffa21a !important;
            cursor: pointer;
            left: 14px;
        }
    }
    .team-member-text a {
        color: #2b93ff;
    }
}
.team-member-table,
.team-member-list-ui {
    padding-left: 0;
    line-height: 18px;
    list-style-type: none;
    li img {
        width: 30px;
    }
    .md-checkbox .md-checkbox-label {
        color: #5f5e5c;
        line-height: 17px;
    }
    .md-checkbox .md-checkbox-label label {
        padding-left: 5px;
    }
    .md-checkbox {
        margin: 5px;
    }
    .md-checkbox .md-checkbox-container {
        width: 16px;
        min-width: 16px;
        height: 16px;
    }
    .md-checkbox .md-checkbox-container:after {
        width: 5px;
        height: 11px;
    }
    .md-checkbox.md-checked .md-checkbox-container:after {
        left: 5px;
    }
}

.team-member-list-li-select-all {
    .md-checkbox .md-checkbox-label {
        color: #5f5e5c;
        font-weight: 500;
    }
}
.tooltip-width {
    max-width: 500px;
    text-align: justify;
}
.icon-place {
    color: #ffa21a !important;
    cursor: pointer;
    left: 14px;
}
.warning-icon {
    color: #ffa21a !important;
    cursor: pointer;
}
.team-member-place {
    .tooltip-place {
        width: 100px;
        font-size: 14px;
        padding-left: 25px;
        cursor: pointer;
    }
    .md-avatar {
        width: 100px !important;
    }
}
.team-member-customer {
    .tooltip-place {
        width: 100px;
        font-size: 14px;
        cursor: pointer;
    }
    .md-avatar {
        width: 150px !important;
    }
}
.team-member-warning {
    width: 40px;
    left: -7px;
}
.warning-tooltip {
    max-width: 500px;
    text-align: justify;
}

.warning-tooltip[x-placement='left']:after {
    top: 48%;
}

.warning-tooltip[x-placement='right']:after {
    top: 48%;
}

.selected-option {
    background-color: #2b93ff;
    color: white;
}
.team-member-tools {
    border-bottom: 1px solid #e8e8e8;
    padding: 5px 0px;
    .search--container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .md-button {
            display: none !important;
        }

        ::v-deep .md-field {
            box-sizing: border-box;
            margin-top: 6px;
            padding: 0px 0px 0px 5px;
            &::before,
            &::after {
                bottom: 0px;
                display: none;
            }
        }
        ::v-deep input {
            font-size: 12px !important;
        }
        ::placeholder {
            font-size: 12px !important;
        }
        ::v-deep .md-clear {
            display: none;
        }
    }

    .search-team-member {
        .search-icon {
            float: left;
            width: 15px;
            padding: 11px 0px 0px;
            cursor: pointer;
        }
        i {
            font-size: 12px !important;
            color: #aaa;
        }
    }

    .filter-link {
        text-align: right;
        .icon-filter {
            color: #2b93ff !important;
            font-size: 16px !important;
            margin-top: -2px;
            padding-left: 10px;
        }
        .icon-arrow {
            color: #333333 !important;
            font-size: 16px !important;
            padding-left: 25%;
        }
        .icon-check {
            color: #fff !important;
            font-size: 16px !important;
            margin-top: 5px;
            float: left;
            padding-left: 10px;
        }
        .icon-check-color {
            color: #333333 !important;
        }
        .filter-menu {
            .dropdown-menu {
                margin-left: 50px;
                min-width: 260px;
                li {
                    border-bottom: 1px solid #e9e9e9;
                }
                li:last-child {
                    border-bottom: 0px !important;
                }
                .md-checkbox .md-checkbox-label {
                    color: #5f5e5c;
                    line-height: 17px;
                }
                .md-checkbox .md-checkbox-label label {
                    padding-left: 5px;
                }
                .md-checkbox {
                    margin: 10px;
                }
                .md-checkbox .md-checkbox-container {
                    width: 16px;
                    min-width: 16px;
                    height: 16px;
                }
                .md-checkbox .md-checkbox-container:after {
                    width: 5px;
                    height: 11px;
                }
                .md-checkbox.md-checked .md-checkbox-container:after {
                    left: 5px;
                }
                .md-radio {
                    margin: 12px;
                }
                .separator {
                    border-bottom: 3px solid #e9e9e9;
                }
            }
            .dropdown-child-menu {
                position: relative;
                border-bottom: 0;
                ul {
                    background-color: white;
                    list-style-type: none;
                    cursor: pointer;
                    padding: 0px;
                    position: absolute;
                    top: 0;
                    left: 100% !important;
                    z-index: 10;
                    display: none;
                    min-width: 160px;
                    border-radius: 2px;
                    li > a {
                        padding-left: 10px;
                    }
                }
                &:hover > ul {
                    display: block;
                }
            }
            .dropdown-menu > li:hover > ul {
                left: auto;
                padding-top: 5px;
                min-width: 100%;
            }
        }
        .filter-menu-dropdown {
            .dropdown-menu {
                margin-left: 50px;
                li {
                    border-bottom: 0px;
                }
                li:last-child {
                    border-bottom: 0px !important;
                }
                a {
                    color: #333333 !important;
                }
                a:hover {
                    background-color: transparent !important;
                    box-shadow: none;
                    color: #2b93ff !important;
                }
                a:hover > i {
                    color: #2b93ff !important;
                }
            }
            .dropdown-child-menu {
                position: relative;
                border-bottom: 0;
                ul {
                    background-color: white;
                    list-style-type: none;
                    cursor: pointer;
                    padding: 0px;
                    position: absolute;
                    top: 0;
                    left: 100% !important;
                    z-index: 10;
                    display: none;
                    min-width: 160px;
                    border-radius: 2px;
                    li > a {
                        padding-left: 10px;
                    }
                }
                &:hover > ul {
                    display: block;
                }
            }
            .dropdown-menu > li:hover > ul {
                left: auto;
                padding-top: 5px;
                min-width: 100%;
            }
        }
        a {
            color: #2b93ff !important;
            font-size: 12px;
            font-weight: 400;
        }
        .filter-clear {
            position: relative !important;
            top: 12px;
            margin-top: 4px;
            font-weight: 400;
            color: #afafaf;
            cursor: pointer;
        }
    }
}

.loader-margin {
    margin-bottom: 100px;
    .loader {
        position: absolute;
        bottom: 75px;
        left: 50%;
        top: initial;
    }
}
</style>
