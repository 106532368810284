<template>
    <div>
        <md-card-content>
            <md-table :value="locationHistoryData" class="table-hover">
                <md-table-row slot="md-table-row" slot-scope="{ item }" @click="viewAddress(item)">
                    <md-table-cell md-label="Suburb/Town" md-sort>
                        <a class="address" @click.stop="viewAddress(item)">
                            {{ displayAddressOrCoord(item) }}
                        </a>
                    </md-table-cell>

                    <md-table-cell md-label="Entered Suburb">
                        {{ displayDateTime(item.entered, item.utcOffset) | timeFormat(DATE_TYPES.standardTime) }}
                    </md-table-cell>
                    <md-table-cell md-label="Left Suburb">
                        {{ displayDateTime(item.exited, item.utcOffset) | timeFormat(DATE_TYPES.standardTime) }}
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </md-card-content>
    </div>
</template>
<style lang="scss" scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

::v-deep .md-table-head {
    cursor: default;
}

.table-hover {
    cursor: pointer;
}
</style>
<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins';
import { handleRequests } from '@/helpers';
import { ViewAddressModal } from '..';

export default {
    name: 'LocationHistoryReportDetails',
    components: {},
    mixins: [GeneralMixin],
    props: {
        memberList: {
            type: Array,
            default: () => []
        },
        distanceCount: {
            type: Number,
            default: 0
        }
    },
    computed: {
        locationHistoryData() {
            return this.memberList;
        },
        getTotalDistance() {
            return this.distanceCount;
        }
    },
    methods: {
        displayDateTime(date, utcOffset) {
            if (date) 
                return moment(date).utcOffset(utcOffset);
            return '';
        },
        displayAddressOrCoord(item) {
            const addr = [item.addressSuburb, item.addressTown, item.addressState].filter((part) => part).join(', ');

            if (addr) 
                return addr;
            return `${item.latitude} - ${item.longitude}`;
        },
        viewAddress(item) {
            this.$_handleLoaderState(true);

            const api = `/api/location/boundaries?latitude=${item.latitude}&longitude=${item.longitude}&level=14`;
            handleRequests(api)
                .then((response) => {
                    const { data } = response.data;

                    if (data !== undefined && data !== null) {
                        // The above polygon data are returned in the natural JSON format by the Nominatim service (we only return the geojson field).
                        // Calling the following link on the web browser for a coordinate around Sydney(https://nominatim.openstreetmap.org/reverse.php?format=json&lat=-33.879143800971704&lon=151.16402717937993&zoom=14&extratags=1&polygon_geojson=1 )
                        // we get JSON (polygon) data in double nested array (check it out).
                        // It returns a polygon but the polygon is nested inside a double nested array. Each coordinate of that polygon is also in format longitude, latitude & not latitude, longitude.
                        // The problem is that Nominatim might return an area of type Polygon or MultiPolygon, etc. More details on:
                        // https://gis.stackexchange.com/questions/270778/nominatim-geojson
                        // https://gis.stackexchange.com/questions/221448/query-country-border-polygon-from-openstreetmap
                        //
                        // Currently we don't handle MultiPolygons as there are very limited places worldwide (and mostly represent country areas) that are using them.
                        // But if we need to, we can implement handling of them on a later stage (even though they are tricky).
                        // The following link has details about them of how OpenStreetMaps handle them with XML although I am not quite sure of how Nominatim does it (haven't found any details).
                        // https://wiki.openstreetmap.org/wiki/Relation:multipolygon

                        // Format returned is an array of polygons that have arrays of coords. The coords are [lat, long] in array & not object.
                        // eg: [ [[x1,y1],[x2,y2],[x3,y3]], [[x4,y4],[x5,y5],...] ]
                        // We need to convert it to: [ [{lat: y1, lng: x1}, {lat: y2, lng: x2}, {lat: y3, lng: x3}], [{lat: y4, lng: x4}, ...] ]
                        const polygons = JSON.parse(data.coordinates).map((d) =>
                            d.map((d2) => ({ lat: d2[1], lng: d2[0] }))
                        );

                        this.$modal.show(ViewAddressModal, {
                            data: {
                                location: {
                                    latitude: item.latitude,
                                    longitude: item.longitude
                                },
                                address: this.displayAddressOrCoord(item),
                                polygons: {
                                    data: polygons,
                                    options: {
                                        strokeColor: '#FF0000',
                                        strokeOpacity: 0.8,
                                        strokeWeight: 2,
                                        fillColor: '#FF0000',
                                        fillOpacity: 0.15
                                    }
                                }
                            }
                        });
                    } else {
                        this.$modal.show(ViewAddressModal, {
                            data: {
                                location: {
                                    latitude: item.latitude,
                                    longitude: item.longitude
                                },
                                address: this.displayAddressOrCoord(item)
                            }
                        });
                    }

                    this.$_handleLoaderState(false);
                })
                .catch((e) => {
                    this.$_handleLoaderState(false);
                });
        }
    }
};
</script>
