<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <div class="custom-toolbar md-layout">
                    <div class="md-layout-item custom-toolbar-start md-small-size-70 md-size-70">
                        <SmsHistoryFilter @onChanged="smsHistoryFilterChangedHandler" />
                    </div>
                    <div class="md-layout-item custom-toolbar-end">
                        <SearchComponent
                            v-if="$root.isDesktop"
                            :search-filter="searchTextValue"
                            @onSearch="searchComponentSearchHandler"
                        />
                        <md-button
                            title="Export Sms History"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click="onExportToExcel"
                        >
                            <md-icon>get_app</md-icon>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>sms</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div v-if="!smsLogs || smsLogs.length == 0">
                            <h3>Sms History</h3>
                            <p>
                                No results to display.
                            </p>
                        </div>
                        <div v-else>
                            <div class="report-container">
                                <md-card-content>
                                    <md-table :value="smsLogs" class="table-hover">
                                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                                            <md-table-cell md-label="Date/Time">
                                                {{ item.statusUpdatedDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                                            </md-table-cell>
                                            <md-table-cell md-label="Recipient" md-sort>
                                                {{ item.toNumber }}
                                            </md-table-cell>
                                            <md-table-cell md-label="Address">
                                                {{ item.stopAddress }}
                                            </md-table-cell>
                                            <md-table-cell md-label="Trigger">
                                                {{ toTriggerString(item.eventTrigger) }}
                                                <template v-if="item.statusFilter">
                                                    <br />
                                                    <span>{{ `(${item.statusFilter})` }}</span>
                                                </template>
                                            </md-table-cell>
                                            <md-table-cell md-label="Segments">
                                                {{ item.segments }}
                                            </md-table-cell>
                                            <md-table-cell md-label="Content">
                                                <md-icon>visibility</md-icon>
                                                <md-tooltip md-direction="top">{{ item.message }}</md-tooltip>
                                            </md-table-cell>
                                            <md-table-cell md-label="Status">
                                                {{ item.l2uStatus }}
                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                </md-card-content>
                            </div>
                        </div>
                    </md-card-content>

                    <md-card-actions v-if="total" class="page-footer" md-alignment="space-between">
                        <div>
                            <p v-if="total === pagination.perPage" class="card-category">
                                Page {{ pagination.currentPage }} of many
                            </p>
                            <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                        </div>
                        <Pagination
                            v-model="pagination.currentPage"
                            class="pagination-no-border pagination-success"
                            :per-page="pagination.perPage"
                            :total="total"
                            :enable-first-page-link="true"
                            :enable-last-page-link="true"
                            @change-page="handleChangePage($event, pagination.perPage)"
                        />
                    </md-card-actions>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import { TEAM_CUSTOM_ACTION_EVENT_TRIGGERS } from '@/utils/CustomActionConstants';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { Pagination, SearchComponent } from '@/components';
import { handleRequests } from '../../helpers';
import { SmsHistoryFilter } from './components';

export default {
    name: 'SmsReport',
    components: { SmsHistoryFilter, SearchComponent, Pagination },
    mixins: [GeneralMixin],
    data() {
        return {
            eventTriggers: TEAM_CUSTOM_ACTION_EVENT_TRIGGERS,
            pagination: {
                perPage: 25,
                currentPage: 1,
                total: 0
            },
            filters: {},
            searchTextValue: null,
            smsLogs: []
        };
    },
    computed: {
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        }
    },
    methods: {
        async smsHistoryFilterChangedHandler(args) {
            this.filters = { ...args };

            await this.getSmsHistory();
        },
        searchComponentSearchHandler(val) {
            this.searchTextValue = val;
        },
        async handleChangePage(currentPage = 1, perPage = 25) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;

            await this.getSmsHistory();
        },
        onExportToExcel() {},
        async getSmsHistory() {
            this.$_handleLoaderState(true);

            const params = new URLSearchParams({
                eventTrigger: this.filters.eventTrigger || '',
                l2uStatus: this.filters.status || '',
                dateFrom: this.filters.dateFrom,
                dateTo: this.filters.dateTo,
                pageNumber: this.pagination.currentPage,
                itemsPerPage: this.pagination.perPage
            });

            const endpoint = `/api/sms/history?${params.toString()}`;

            await handleRequests(endpoint)
                .then((response) => {
                    const { smsLogs, total } = response.data;

                    this.smsLogs = smsLogs;
                    this.pagination.total = total;
                })
                .catch((err) => {
                    this.$notify({
                        message: 'Error retrieving reports.',
                        type: 'danger'
                    });
                })
                .finally(() => {
                    this.$_handleLoaderState(false);
                });
        },
        toTriggerString(eventTrigger) {
            return this.eventTriggers.find((x) => x.eventTrigger === eventTrigger)?.displayText;
        }
    }
};
</script>

<style lang="scss" scoped>
.header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin: 0;
}

.alert-z {
    z-index: 0 !important;
}

.custom-toolbar-end {
    padding-top: 10px;
}
</style>
