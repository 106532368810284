var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _vm.hasTeam
      ? _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Settings"),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "   >    " + _vm._s(_vm.$t("menus.setting.accounts"))
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-blue" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("manage_accounts")])],
                  1
                ),
              ]
            ),
            _c("md-card-content", [
              _vm.hasTeam
                ? _c(
                    "div",
                    [
                      _vm.hasTeam
                        ? _c(
                            "tabs",
                            {
                              staticClass: "custom-tab-list",
                              attrs: {
                                "tab-name": [
                                  "Service packages",
                                  "Invoicing",
                                  "Account Codes",
                                  "Rate Types",
                                  "Entity",
                                  "Tax Settings",
                                ],
                                "selected-panel": _vm.selectedTab,
                                "color-button": "success",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "tab-pane-1" },
                                [_c("rate-groups-list")],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "tab-pane-2" },
                                [_c("invoice-settings")],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "tab-pane-3" },
                                [_c("account-code-settings")],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "tab-pane-4" },
                                [_c("rate-type-settings")],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "tab-pane-5" },
                                [_c("entity-list")],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "tab-pane-6" },
                                [_c("tax-settings-list")],
                                1
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }