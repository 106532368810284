<template>
    <md-table class="custom-paginated-table">
        <md-table-row>
            <md-table-head>Business Name</md-table-head>
            <md-table-head>Business Number</md-table-head>
            <md-table-head>Address</md-table-head>
            <md-table-head>Company Number</md-table-head>
            <md-table-head>Brand</md-table-head>
        </md-table-row>
        <md-table-row v-for="entity in businessEntitiesList" :key="entity.businessEntityId">
            <md-table-cell>
                <router-link
                    :to="{
                        name: 'Business Entity Details',
                        params: { businessEntityId: entity.businessEntityId }
                    }"
                >
                    {{ entity.businessRegistrationName }}
                </router-link>
            </md-table-cell>
            <md-table-cell class="business-number-cell">
                {{ entity.businessRegistrationNumber }}
            </md-table-cell>
            <md-table-cell>
                {{ entity.businessAddress }}
            </md-table-cell>
            <md-table-cell>
                {{ entity.companyNumber }}
            </md-table-cell>
            <md-table-cell>
                {{ entity.brandName }}
            </md-table-cell>
        </md-table-row>
    </md-table>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests } from '@/helpers';

export default {
    name: 'BusinessEntitiesList',
    props: {
        listOfBusinessEntities: {
            type: Array,
            default: null
        }
    },
    mixins: [GeneralMixin],
    async mounted() {
        if (this.listOfBusinessEntities == null) {
            await this.getBusinessEntities();
        } else {
            this.businessEntitiesList = this.listOfBusinessEntities;
        }
    },
    methods: {
        async getBusinessEntities() {
            this.$_handleLoaderState(true);
            const api = '/api/business-entities/list';
            const response = await handleRequests(api);
            if (response.data !== null) {
                this.businessEntitiesList = response.data.businessEntities;
            }
            this.$_handleLoaderState(false);
        }
    },
    data() {
        return {
            businessEntitiesList: []
        };
    }
};
</script>
<style lang="scss" scoped>
.business-number-cell {
    width: 200px;
}

.card-icon-text {
    color: white;
}

.md-theme-default a:not(.md-button) {
    color: black;
}
</style>
