/* eslint-disable no-param-reassign */

let elementPos;

const dragElement = (element, parent, vNode) => {
    let oldPosX = 0;
    let newPosX = 0;

    elementPos = Number(element.style.left.replace(/[^0-9.]/g, ''));

    const { width } = parent.getBoundingClientRect();
    const onMouseMove = (e) => {
        const element = document.querySelector('.playback-indicator');

        // if the mouse is not over the playback circle, don't move the circle
        if (!element.matches(':hover')) 
            return;
        e = e || window.event;

        // calculate the new cursor position:
        newPosX = oldPosX - e.clientX;

        oldPosX = e.clientX;
        // set the element's new position:

        elementPos -= newPosX;

        // pertaining to the leftmost and rightmost x value of the slider.
        // 7 because the width of the circle is 14, so we're getting half of that.
        if (elementPos < 0) 
            elementPos = 0;
        else if (elementPos > width) 
            elementPos = width;

        element.style.left = `${elementPos - 7}px`;

        // -7 offset
        vNode.context.onMouseMove(e, elementPos / width);
    };

    const onMouseUp = (e) => {
        /* stop moving when mouse button is released: */
        document.onmouseup = null;
        document.onmousemove = null;

        vNode.context.onMouseUp(e);
    };
    const onMouseDown = (e) => {
        // eslint-disable-next-line no-param-reassign
        e = e || window.event;
        oldPosX = e.clientX;

        vNode.context.onMouseDown(e);

        document.onmouseup = onMouseUp;
        document.onmousemove = onMouseMove;
    };

    element.addEventListener('mousedown', (e) => {
        onMouseDown(e);
    });
};

export const PlaybackCircle = {
    bind(el, binding, vNode) {
        const $circle = document.createElement('div');
        $circle.setAttribute('class', 'playback-indicator');
        const { value } = binding;
        const { lastUpdateTimeInMs } = vNode.context;

        const width = lastUpdateTimeInMs / (48 * 1000);

        const $slider = document.querySelector(value.parentNode);

        $slider.insertBefore($circle, $slider.firstChild);

        // get total width of slider
        const sliderWidth = $slider.getBoundingClientRect().width;

        $circle.style.left = `${sliderWidth * width}px`;

        dragElement($circle, $slider, vNode);
    },
    componentUpdated(el, binding, vNode) {
        const { value } = binding;
        const {
            circleOptions: { width }
        } = vNode.context;

        if (!width) 
            return;
        const $slider = document.querySelector(value.parentNode);

        if ($slider) {
            // get total width of slider
            const sliderWidth = $slider.getBoundingClientRect().width;
            // get left value of slider
            const sliderLeft = $slider.getBoundingClientRect().left;

            // prevent going less or beyond the two 12mn points
            if (width > 1 && (width - sliderLeft > sliderWidth || width < sliderLeft)) 
                return;

            const $circle = document.querySelector('.playback-indicator');

            $circle.style.left = `${(width > 1 ? width - sliderLeft : sliderWidth * width) - 7}px`;

            if (Math.floor(width) > 1) {
                const { getCurrentTimeBasedOnMinutes } = vNode.context;
                const widthPercent = (width - sliderLeft) / sliderWidth;
                const timeInMinutes = (48 * widthPercent * 1800) / 60;

                elementPos = width - sliderLeft;

                getCurrentTimeBasedOnMinutes(timeInMinutes);
            }
        }
    },
    unbind(el, binding) {
        const { value } = binding;
        const $slider = document.querySelector(value.parentNode);
        const $circle = document.querySelector('.playback-indicator');
        if ($slider) {
            $slider.removeChild($circle);
        }
    }
};
