var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "welcome-container",
    domProps: {
      innerHTML: _vm._s(
        _vm.$_compiledMarkdown(_vm.carrierDetails.longDescription)
      ),
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }