<template>
    <div class="md-layout">
        <div class="md-layout-item">
            <div class="custom-toolbar md-layout">
                <div class="custom-toolbar-start">
                    <div class="filter-steps--container">
                        <md-field class="status-filter">
                            <vue-select
                                v-if="statuses"
                                :options="statuses"
                                label="statusValue"
                                :reduce="(stat) => stat.statusId"
                                placeholder="Status"
                                v-model="statusFilterValue"
                                :searchable="$root.isDesktop"
                            ></vue-select>
                        </md-field>

                        <md-field v-if="warehousesOptions.length > 1" class="filter-member-name">
                            <vue-select
                                :reduce="(wh) => wh.warehouseId"
                                label="name"
                                :options="warehousesOptions"
                                placeholder="Warehouse"
                                v-model="warehouseFilterValue"
                                :searchable="$root.isDesktop"
                            ></vue-select>
                        </md-field>

                        <md-field class="status-filter location-status-filter">
                            <vue-select
                                v-if="movementLocationStatus"
                                :options="movementLocationStatus"
                                label="name"
                                :reduce="(stat) => stat.key"
                                placeholder="Location Status"
                                v-model="locationStatusFilterValue"
                                :clearable="false"
                            ></vue-select>
                        </md-field>

                        <md-field>
                            <md-datepicker
                                class="filter-steps--choices filter-step-date"
                                v-model="scannedDateFilterValue"
                                md-immediately
                                :md-debounce="100"
                                ref="mdDatePicker"
                            ></md-datepicker>
                        </md-field>
                        <md-field>
                            <vue-select
                                v-if="itemLocations"
                                :reduce="(itemLocation) => itemLocation.locationId"
                                :options="itemLocations"
                                v-model="itemLocationFilterValue"
                                label="name"
                                placeholder="Item Location"
                                :clearable="true"
                            ></vue-select>
                        </md-field>
                    </div>
                </div>
                <div class="custom-toolbar-end">
                    <search-component ref="keyword" @onSearch="handleKeyword" placeholder="Search" />
                    <div>
                        <md-button
                            title="Export Items"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click="exportItems"
                        >
                            <md-icon>get_app</md-icon>
                        </md-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins';
import { ITEM_STATUS_CONSTANTS, ITEM_MOVEMENT_LOCATION_CONSTANTS } from '@/utils/constants';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { SearchComponent } from '@/components';

export default {
    name: 'InventoryFilterComponent',
    components: { SearchComponent },
    props: {
        warehouses: {
            type: Array,
            default: null
        },

        /**
         * @type {import('@/components/ItemLocations/types/ItemLocation').ItemLocation[]}
         */
        itemLocations: {
            type: Array,
            default: null
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {
            statuses: this.getStatuses(['Active', 'Completed', ...ITEM_STATUS_CONSTANTS, 'All']),
            movementLocationStatus: [{ key: 'All', name: 'All Locations' }, ...ITEM_MOVEMENT_LOCATION_CONSTANTS],
            statusFilterValue: '',
            keywordFilterValue: '',
            warehouseFilterValue: '',
            itemLocationFilterValue: '',
            tripDateFilterValue: null,
            warehousesOptions: [],
            scannedDateFilterValue: null,
            locationStatusFilterValue: 'All'
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        })
    },
    mounted() {
        if (!this.$root.isDesktop) {
            if (this.$refs.mdDatePicker) 
                this.$refs.mdDatePicker.$refs.input.$el.setAttribute('readonly', true);
        }
    },
    watch: {
        statusFilterValue(newValue, oldValue) {
            this.handleFilter();
        },
        warehouseFilterValue(newValue, oldValue) {
            this.handleFilter();
        },
        keywordFilterValue(newValue, oldValue) {
            this.handleFilter();
        },
        itemLocationFilterValue(newValue, oldValue) {
            this.handleFilter();
        },
        warehouses(newValue, oldValue) {
            this.warehousesOptions = newValue;
        },
        scannedDateFilterValue() {
            this.handleFilter();
        },
        locationStatusFilterValue() {
            this.handleFilter();
        }
    },
    methods: {
        getStatuses(arr) {
            const stats = arr.map((x) => ({
                statusId: x,
                statusValue: this.$t(`inputs.itemStatuses.${x}`)
            }));

            return stats;
        },
        // eslint-disable-next-line func-names
        handleFilter: _.debounce(function() {
            this.handleFilteredValue();
        }, 700),
        emitEvent(evt) {
            // use nextTick to ensure that tripDate value has already been changed.
            // this is because the @selected method of md-datepicker is asynchronous.
            this.$nextTick(() => {
                const { keywordFilterValue, warehouseFilterValue, scannedDateFilterValue } = this;

                let { statusFilterValue, locationStatusFilterValue } = this;

                if (statusFilterValue === 'All') 
                    statusFilterValue = null;

                let tripDate = '';
                if (scannedDateFilterValue) {
                    tripDate = this.$options.filters.dateFormat(
                        scannedDateFilterValue,
                        this.DATE_TYPES.internationalDate
                    );
                }

                if (locationStatusFilterValue === 'All') {
                    locationStatusFilterValue = null;
                }

                this.$emit(evt, {
                    itemStatus: statusFilterValue,
                    keyword: keywordFilterValue,
                    warehouseId: warehouseFilterValue,
                    itemLocationId: this.itemLocationFilterValue,
                    lastScannedDate: tripDate,
                    locationStatus: locationStatusFilterValue
                });
            });
        },
        handleFilteredValue() {
            this.emitEvent('onFilterStops');
        },
        handleKeyword(data) {
            this.keywordFilterValue = data.searchText;
        },
        async exportItems() {
            this.emitEvent('onExportClicked');
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-steps--container {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }
    .filter-step-date {
        min-width: 250px;
        margin-right: 0;
        min-height: initial;
        position: absolute;
        z-index: 1;
    }

    .filter-button--container {
        display: inline-block;
    }
    ::v-deep .md-datepicker:after,
    ::v-deep .md-datepicker:before {
        bottom: 0;
        height: 0;
    }

    ::v-deep .md-datepicker:before,
    ::v-deep .md-datepicker:after {
        bottom: 30px;
        content: 'Scanned Date';
        color: #aaaaaa;
        font-weight: 400;
        font-size: 14px;
        transform: none;
        z-index: 1;
    }

    ::v-deep .md-has-value:before,
    ::v-deep .md-has-value:after {
        bottom: 0;
        height: 0;
        content: '';
    }

    ::v-deep .md-datepicker .md-input {
        position: absolute;
        z-index: 9;
        width: 150px;
    }

    ::v-deep .md-datepicker .md-input-action {
        z-index: 99;
        right: 52px;
    }
}
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}
::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}
::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}

::v-deep .vs__search {
    cursor: pointer;
}

.datepicker-noevent {
    ::v-deep .md-datepicker {
        input {
            pointer-events: none;
        }
    }
}

::v-deep .filter-member-name .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    display: block;
}

.time-picker .main-time-picker-container:after {
    content: 'Select time';
    color: #aaaaaa;
    font-weight: 400;
    font-size: 14px;
    transform: none;
    bottom: 17px;
    position: absolute;
}

::v-deep .md-has-value .main-time-picker-container:after {
    content: '';
}

.time-picker {
    width: 210px !important;
}

.time-picker.md-field:after {
    bottom: 10px !important;
    height: 1px !important;
}

.time-picker:last-child {
    margin-right: 0 !important;
}

.status-filter {
    width: 150px !important;
}

.time-picker ::v-deep .time-picker-container:nth-child(2) {
    border-bottom: 1px solid #d2d2d2;
}

.custom-toolbar {
    align-items: start;
    margin-top: 1px;

    .custom-toolbar-start {
        flex: 3;
    }
}

.location-status-filter {
    width: 200px !important;
}
</style>
