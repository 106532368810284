var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-container" }, [
    _c(
      "div",
      { staticClass: "close-button" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-default md-just-icon md-round pull-right",
            on: { click: _vm.closeModal },
          },
          [_c("md-icon", [_vm._v("close")])],
          1
        ),
      ],
      1
    ),
    !_vm.loading && _vm.invoiceDetails.customerInvoiceId !== undefined
      ? _c("div", [
          _c("div", { staticClass: "detail-section text-center" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.invoiceDetails.barcode)),
            ]),
            _c(
              "div",
              { staticClass: "detail-group text-center action-detail-group" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "md-button md-success md-just-icon md-round",
                    attrs: {
                      title: "View invoice details",
                      to: {
                        name: "Customer Invoice Details",
                        params: { invoiceId: _vm.customerInvoiceId },
                      },
                      target: "_blank",
                    },
                  },
                  [
                    _c("div", { staticClass: "md-ripple" }, [
                      _c(
                        "div",
                        { staticClass: "md-button-content" },
                        [_c("md-icon", [_vm._v("visibility")])],
                        1
                      ),
                    ]),
                  ]
                ),
                !_vm.isReadOnlyUser
                  ? _c("delete-invoice-button", {
                      attrs: {
                        "customer-invoice-id":
                          _vm.invoiceDetails.customerInvoiceId,
                        status: _vm.invoiceDetails.status,
                      },
                      on: { invoiceDeleted: _vm.handleDeletedInvoice },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "line-separator" }),
          _c("div", { staticClass: "detail-section" }, [
            _c("span", { staticClass: "title" }, [_vm._v("Invoice Details")]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [
                _vm._v("Invoice Number:"),
              ]),
              _c(
                "span",
                { staticClass: "value" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Customer Invoice Details",
                          params: { invoiceId: _vm.customerInvoiceId },
                        },
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.invoiceDetails.invoiceNumber) +
                          "\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("PO Number:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.invoiceDetails.purchaseOrderNumber) +
                    "\n                "
                ),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Customer:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.invoiceDetails.customer)),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Invoice Date:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("dateFormat")(
                      _vm.invoiceDetails.invoiceDate,
                      _vm.DATE_TYPES.standardDate
                    )
                  )
                ),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Due Date:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("dateFormat")(
                      _vm.invoiceDetails.dueDate,
                      _vm.DATE_TYPES.standardDate
                    )
                  )
                ),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Status:")]),
              _c(
                "span",
                { staticClass: "value" },
                [
                  _c("payment-status-button", {
                    attrs: {
                      "payment-status": _vm.invoiceDetails.status,
                      "customer-invoice-id":
                        _vm.invoiceDetails.customerInvoiceId,
                      "invoice-details": _vm.invoiceDetails,
                    },
                    on: { statusUpdated: _vm.statusUpdated },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm.invoiceDetails.invoiceLines.length > 0
            ? _c("div", { staticClass: "line-separator" })
            : _vm._e(),
          _vm.invoiceDetails.invoiceLines.length > 0
            ? _c(
                "div",
                { staticClass: "detail-section" },
                [
                  _c("span", { staticClass: "title" }, [_vm._v("Lines")]),
                  _vm._l(
                    _vm.invoiceDetails.invoiceLines,
                    function (line, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          { staticClass: "detail-group item-reference" },
                          [
                            _c("span", { staticClass: "label info-line" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(line.description) +
                                  " x " +
                                  _vm._s(line.quantity) +
                                  "\n                        "
                              ),
                              line.quantityType
                                ? _c("span", { staticClass: "quantity-type" }, [
                                    _vm._v(_vm._s(line.quantityType)),
                                  ])
                                : _vm._e(),
                              _c("span", { staticClass: "price" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        line.unitPriceExTax * line.quantity,
                                        line.priceCurrency
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "sidebar-loader" },
            [
              _c("fade-loader", {
                attrs: { loading: _vm.loading, color: "#333333" },
              }),
              _c("span", [_vm._v("LOADING")]),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }