var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "details-page" }, [
    _vm.shipmentDetails != null
      ? _c("div", { staticClass: "detail-container" }, [
          _c("div", { staticClass: "booking-details" }, [
            _c(
              "div",
              { staticClass: "title" },
              [
                _vm.shipmentDetails.shipmentRef != null
                  ? _c("span", [
                      _vm._v(
                        "Shipment " + _vm._s(_vm.shipmentDetails.shipmentRef)
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "Shipment " + _vm._s(_vm.shipmentDetails.shipmentId)
                      ),
                    ]),
                _vm.shipmentDetails.sourceReference
                  ? [
                      _vm._v(
                        "\n                    / " +
                          _vm._s(_vm.shipmentDetails.sourceReference) +
                          "\n                "
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "action-buttons pull-right" },
              [
                _c(
                  "div",
                  [
                    _c("shipment-status-button", {
                      attrs: { shipment: _vm.shipmentDetails },
                      on: { statusUpdated: _vm.handleChangedStatus },
                    }),
                  ],
                  1
                ),
                !_vm.isCustomer && !_vm.isSupportStaff
                  ? _c(
                      "div",
                      [
                        _c("update-shipment-button", {
                          attrs: {
                            shipment: _vm.shipmentDetails.shipmentId,
                            "team-members": _vm.teamMembers,
                            "class-name": "header-button",
                            "use-icon": false,
                            "shipment-status": _vm.shipmentDetails.status,
                            "carrier-team-id":
                              _vm.shipmentDetails.carrierTeamId,
                          },
                          on: { shipmentUpdated: _vm.reloadDetails },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isCarrierAssigned && !_vm.isSupportStaff
                  ? _c("generate-label-button", {
                      attrs: {
                        id: _vm.shipmentDetails.shipmentId,
                        "use-icon": false,
                        type: "Shipment",
                      },
                    })
                  : _vm._e(),
                !_vm.shipmentDetails.carrierTeamId
                  ? _c("stop-pdf-download-button", {
                      attrs: {
                        status: _vm.shipmentDetails.status.toLowerCase(),
                        "stop-id": _vm.shipmentDetails.dropStop.stopId,
                        "stop-ref": _vm.shipmentDetails.dropStop.stopRef,
                        "use-icon": false,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "md-layout md-alignment-top-left" }, [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-layout md-large-size-66 md-xlarge-size-50",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-large-size-100 md-small-size-100 md-xlarge-size-100",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-blue",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-icon" },
                              [_c("md-icon", [_vm._v("description")])],
                              1
                            ),
                          ]
                        ),
                        _c("md-card-content", [
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "details-box tracking-link-box" },
                              [
                                _vm.shipmentDetails.tripDate
                                  ? _c("div", [
                                      _c("label", [_vm._v("Trip date")]),
                                      _vm.shipmentDetails.tripDate != null &&
                                      (_vm.shipmentDetails.dropStop.tripDate ==
                                        null ||
                                        _vm.shipmentDetails.pickupStop
                                          .tripDate ===
                                          _vm.shipmentDetails.dropStop.tripDate)
                                        ? _c(
                                            "span",
                                            [
                                              _vm.shipmentDetails.tripId &&
                                              !_vm.isCarrierAssigned
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Trip Details",
                                                          params: {
                                                            tripId:
                                                              _vm
                                                                .shipmentDetails
                                                                .tripId,
                                                          },
                                                        },
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              _vm
                                                                .shipmentDetails
                                                                .tripDate,
                                                              _vm.DATE_TYPES
                                                                .standardDate
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm._f("dateFormat")(
                                                            _vm.shipmentDetails
                                                              .tripDate,
                                                            _vm.DATE_TYPES
                                                              .standardDate
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "span",
                                            [
                                              _vm.shipmentDetails.tripId &&
                                              !_vm.isCarrierAssigned
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Trip Details",
                                                          params: {
                                                            tripId:
                                                              _vm
                                                                .shipmentDetails
                                                                .tripId,
                                                          },
                                                        },
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              _vm
                                                                .shipmentDetails
                                                                .pickupStop
                                                                .tripDate,
                                                              _vm.DATE_TYPES
                                                                .standardDate
                                                            )
                                                          ) +
                                                          "\n                                                "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v("->"),
                                                      ]),
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateFormat"
                                                            )(
                                                              _vm
                                                                .shipmentDetails
                                                                .dropStop
                                                                .tripDate,
                                                              _vm.DATE_TYPES
                                                                .standardDate
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm._f("dateFormat")(
                                                            _vm.shipmentDetails
                                                              .pickupStop
                                                              .tripDate,
                                                            _vm.DATE_TYPES
                                                              .standardDate
                                                          )
                                                        ) +
                                                        "\n                                                "
                                                    ),
                                                    _c("strong", [
                                                      _vm._v("->"),
                                                    ]),
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm._f("dateFormat")(
                                                            _vm.shipmentDetails
                                                              .dropStop
                                                              .tripDate,
                                                            _vm.DATE_TYPES
                                                              .standardDate
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]),
                                            ],
                                            1
                                          ),
                                    ])
                                  : _vm._e(),
                                _vm.shipmentDetails.runNumber
                                  ? _c("div", { staticClass: "run-number" }, [
                                      _c(
                                        "div",
                                        { staticClass: "details-box" },
                                        [
                                          _c("label", [_vm._v("Run")]),
                                          _c("span", [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm.shipmentDetails.runNumber
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  [
                                    _vm.isCarrierAssigned
                                      ? [
                                          _vm.carrierTrackingUrl
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: _vm.carrierTrackingUrl,
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _c("md-icon", [
                                                    _vm._v("place"),
                                                  ]),
                                                  _vm._v(
                                                    "\n                                                Tracking Link\n                                            "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      : [
                                          _vm.shipmentDetails.trackingLinkUrl
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: _vm.setTrackingLinkUrl(
                                                      _vm.shipmentDetails
                                                        .trackingLinkUrl
                                                    ),
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _c("md-icon", [
                                                    _vm._v("place"),
                                                  ]),
                                                  _vm._v(
                                                    "\n                                                Tracking Link\n                                            "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm.shipmentDetails
                                                .trackingLinkUrl == null &&
                                              !_vm.isReadOnlyUser
                                            ? _c(
                                                "a",
                                                {
                                                  on: {
                                                    click:
                                                      _vm.createTrackingLink,
                                                  },
                                                },
                                                [
                                                  _c("md-icon", [
                                                    _vm._v("add_location_alt"),
                                                  ]),
                                                  _vm._v(
                                                    "\n                                                Create tracking link\n                                            "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                  ],
                                  2
                                ),
                                _vm.shipmentDetails.rateGroup
                                  ? _c("div", [
                                      _c("label", [_vm._v("Service Package")]),
                                      _c("span", [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.shipmentDetails.rateGroup
                                                .rateGroupName
                                            ) +
                                            "\n                                        "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _vm.shipmentDetails.brandId != null
                            ? _c(
                                "div",
                                { staticClass: "shipment-time-details" },
                                [
                                  _c("div", [
                                    _c("div", { staticClass: "details-box" }, [
                                      _c("label", [_vm._v("Brand")]),
                                      _vm.shipmentDetails.brandLogoUrl
                                        ? _c("img", {
                                            staticClass: "brand-logo",
                                            attrs: {
                                              src:
                                                _vm.shipmentDetails
                                                  .brandLogoUrl !== null
                                                  ? _vm.shipmentDetails
                                                      .brandLogoUrl
                                                  : _vm.$root.defaultPhotoUrl,
                                              title:
                                                _vm.shipmentDetails.brandName,
                                              alt: _vm.shipmentDetails
                                                .brandName,
                                            },
                                            on: {
                                              error:
                                                _vm.$_setDefaultBrokenImage,
                                            },
                                          })
                                        : _vm._e(),
                                      !_vm.shipmentDetails.brandLogoUrl
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.shipmentDetails.brandName
                                                ) +
                                                "\n                                        "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "details-box" }, [
                            _c("div", { staticClass: "address-details" }, [
                              _c("label", [_vm._v("Pickup Address")]),
                              _vm.shipmentDetails.pickupStop.name
                                ? _c("div", { staticClass: "address-name" }, [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.shipmentDetails.pickupStop.name
                                        ) +
                                        "\n                                    "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/stops/details/" +
                                      _vm.shipmentDetails.pickupStop.stopId,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.shipmentDetails.pickupStop.address
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "shipment-time-details" },
                              [
                                _vm.shipmentDetails.pickupStop.durationMinutes >
                                0
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "details-box" },
                                        [
                                          _c("label", [_vm._v("Duration")]),
                                          _c("span", [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm.shipmentDetails.pickupStop
                                                    .durationMinutes +
                                                    " minutes"
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.shipmentDetails.pickupStop.appointmentTime
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "details-box" },
                                        [
                                          _c("label", [
                                            _vm._v("Appointment time"),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm._f("timeFormat")(
                                                    _vm.shipmentDetails
                                                      .pickupStop
                                                      .appointmentTime,
                                                    _vm.DATE_TYPES.standardTime
                                                  )
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm.shipmentDetails.pickupStop
                                      .timeWindowStart &&
                                    _vm.shipmentDetails.pickupStop.timeWindowEnd
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "details-box" },
                                        [
                                          _c("label", [_vm._v("Time Window")]),
                                          _c("span", [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm._f("timeFormat")(
                                                    _vm.shipmentDetails
                                                      .pickupStop
                                                      .timeWindowStart,
                                                    _vm.DATE_TYPES.standardTime
                                                  )
                                                ) +
                                                "\n                                                -\n                                                " +
                                                _vm._s(
                                                  _vm._f("timeFormat")(
                                                    _vm.shipmentDetails
                                                      .pickupStop.timeWindowEnd,
                                                    _vm.DATE_TYPES.standardTime
                                                  )
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.shipmentDetails.pickupStop.originalEta
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "details-box" },
                                        [
                                          _c("label", [
                                            _vm._v("Estimated Time of Arrival"),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm._f("timeFormat")(
                                                    _vm.shipmentDetails
                                                      .pickupStop.originalEta,
                                                    _vm.DATE_TYPES
                                                      .standardDateTime
                                                  )
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "details-box" }, [
                            _c("div", { staticClass: "address-details" }, [
                              _c("label", [_vm._v("Drop Address")]),
                              _vm.shipmentDetails.dropStop.name
                                ? _c("div", { staticClass: "address-name" }, [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.shipmentDetails.dropStop.name
                                        ) +
                                        "\n                                    "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/stops/details/" +
                                      _vm.shipmentDetails.dropStop.stopId,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.shipmentDetails.dropStop.address
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "shipment-time-details" },
                              [
                                _vm.shipmentDetails.dropStop.durationMinutes > 0
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "details-box" },
                                        [
                                          _c("label", [_vm._v("Duration")]),
                                          _c("span", [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm.shipmentDetails.dropStop
                                                    .durationMinutes +
                                                    " minutes"
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.shipmentDetails.dropStop.appointmentTime
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "details-box" },
                                        [
                                          _c("label", [
                                            _vm._v("Appointment time"),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm._f("timeFormat")(
                                                    _vm.shipmentDetails.dropStop
                                                      .appointmentTime,
                                                    _vm.DATE_TYPES.standardTime
                                                  )
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm.shipmentDetails.dropStop
                                      .timeWindowStart &&
                                    _vm.shipmentDetails.dropStop.timeWindowEnd
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "details-box" },
                                        [
                                          _c("label", [_vm._v("Time Window")]),
                                          _c("span", [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm._f("timeFormat")(
                                                    _vm.shipmentDetails.dropStop
                                                      .timeWindowStart,
                                                    _vm.DATE_TYPES.standardTime
                                                  )
                                                ) +
                                                "\n                                                -\n                                                " +
                                                _vm._s(
                                                  _vm._f("timeFormat")(
                                                    _vm.shipmentDetails.dropStop
                                                      .timeWindowEnd,
                                                    _vm.DATE_TYPES.standardTime
                                                  )
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.shipmentDetails.dropStop.originalEta
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "details-box" },
                                        [
                                          _c("label", [
                                            _vm._v("Estimated Time of Arrival"),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm._f("timeFormat")(
                                                    _vm.shipmentDetails.dropStop
                                                      .originalEta,
                                                    _vm.DATE_TYPES
                                                      .standardDateTime
                                                  )
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _vm.shipmentDetails.skills.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "details-box md-chips md-primary",
                                },
                                [
                                  _c("label", [_vm._v("Required skills:")]),
                                  _vm._l(
                                    _vm.shipmentDetails.skills,
                                    function (skill, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "md-chip custom-chip",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(skill) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.shipmentCustomFieldList !== null
                            ? _c(
                                "div",
                                { staticClass: "custom-field-container" },
                                _vm._l(
                                  _vm.shipmentCustomFieldList,
                                  function (customField, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "details-box",
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(_vm._s(customField.label)),
                                        ]),
                                        _vm.customFieldValues !== null &&
                                        _vm.customFieldValues[
                                          customField.name
                                        ] !== null
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.customFieldValues[
                                                      customField.name
                                                    ]
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.loadCapacityList !== null
                            ? _c(
                                "div",
                                { staticClass: "shipment-time-details" },
                                _vm._l(
                                  _vm.loadCapacityList,
                                  function (loadCapacity, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "details-box",
                                      },
                                      [
                                        loadCapacity.type !== "dimensions"
                                          ? _c("div", [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(loadCapacity.label)
                                                ),
                                              ]),
                                              loadCapacity !== null &&
                                              _vm.loadValues[
                                                loadCapacity.type
                                              ] !== null
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.loadValues[
                                                            loadCapacity.type
                                                          ]
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          loadCapacity.symbol
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        loadCapacity.type === "dimensions"
                                          ? _c("div", [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(loadCapacity.label)
                                                ),
                                              ]),
                                              loadCapacity !== null &&
                                              _vm.loadValues[
                                                loadCapacity.type
                                              ] !== null
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                                            L: " +
                                                        _vm._s(
                                                          _vm.loadValues[
                                                            "length"
                                                          ]
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          loadCapacity.symbol
                                                        ) +
                                                        " W:\n                                            " +
                                                        _vm._s(
                                                          _vm.loadValues[
                                                            "width"
                                                          ]
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          loadCapacity.symbol
                                                        ) +
                                                        " H:\n                                            " +
                                                        _vm._s(
                                                          _vm.loadValues[
                                                            "height"
                                                          ]
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          loadCapacity.symbol
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.teamRegionName
                            ? _c("div", { staticClass: "details-box" }, [
                                _c("label", [_vm._v("Team Region")]),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.teamRegionName)),
                                ]),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "details-box" }, [
                            _c("label", [_vm._v("Notes")]),
                            _vm.shipmentDetails.notes
                              ? _c("span", {
                                  staticClass: "shipment-notes",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.shipmentDetails.notes
                                    ),
                                  },
                                })
                              : _c("span", { staticClass: "grayedout-text" }, [
                                  _vm._v("No notes found"),
                                ]),
                          ]),
                          _vm.shipmentDetails.teamMemberInvoiceId
                            ? _c("div", { staticClass: "details-box" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Team Member Invoice:"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "value" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          "custom-badge payment-status-" +
                                          _vm.shipmentDetails.teamMemberInvoiceStatus
                                            .toLowerCase()
                                            .replace(/\s+/g, ""),
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "inputs.paymentStatuses." +
                                                  _vm.shipmentDetails
                                                    .teamMemberInvoiceStatus
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Team Member Invoice Details",
                                            params: {
                                              invoiceId:
                                                _vm.shipmentDetails
                                                  .teamMemberInvoiceId,
                                            },
                                          },
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.shipmentDetails
                                                .teamMemberInvoiceNumber
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.shipmentDetails.customerInvoiceId
                            ? _c("div", { staticClass: "details-box" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Customer Invoice:"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "value" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          "custom-badge payment-status-" +
                                          _vm.shipmentDetails.customerInvoiceStatus
                                            .toLowerCase()
                                            .replace(/\s+/g, ""),
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "inputs.paymentStatuses." +
                                                  _vm.shipmentDetails
                                                    .customerInvoiceStatus
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Customer Invoice Details",
                                            params: {
                                              invoiceId:
                                                _vm.shipmentDetails
                                                  .customerInvoiceId,
                                            },
                                          },
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.shipmentDetails
                                                .customerInvoiceNumber
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100 map-box",
                  },
                  [
                    _c("map-overlay", {
                      attrs: {
                        "shipment-details": _vm.shipmentDetails,
                        "shipment-status-history": _vm.shipmentStatusHistory,
                        "clicked-history": _vm.clickedHistory,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100",
                  },
                  [
                    _c("notes", {
                      attrs: {
                        list: _vm.shipmentDetails.shipmentNotes,
                        "shipment-id": _vm.shipmentDetails.shipmentId,
                      },
                      on: { notesAdded: _vm.handleAddedShipmentNote },
                    }),
                  ],
                  1
                ),
                !_vm.isIndividualUser
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100",
                      },
                      [
                        _vm.isTeamOwner || _vm.isAdmin || _vm.isReadOnlyUser
                          ? _c("charge-rates-list", {
                              attrs: {
                                "charge-lines": _vm.shipmentDetails.chargeLines,
                                "shipment-details": _vm.shipmentDetails,
                                title: "Charges",
                                "has-invoice":
                                  _vm.shipmentDetails.customerInvoiceId !==
                                  null,
                                "rates-have-changed":
                                  _vm.updateChargesInBackground,
                              },
                              on: {
                                updateShipmentDetails: _vm.getShipmentDetails,
                              },
                            })
                          : _vm._e(),
                        _c(
                          "md-card",
                          [
                            _c(
                              "md-card-header",
                              {
                                staticClass:
                                  "md-card-header-icon md-card-header-green",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "card-icon" },
                                  [_c("md-icon", [_vm._v("receipt_long")])],
                                  1
                                ),
                              ]
                            ),
                            _c("md-card-content", [
                              !_vm.shipmentDetails.pickupStop.stopItems ||
                              _vm.shipmentDetails.pickupStop.stopItems
                                .length === 0
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "grayedout-text text-center",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                No items found\n                            "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "md-table",
                                        {
                                          staticClass:
                                            "context-menu-support custom-paginated-table",
                                        },
                                        [
                                          _c(
                                            "md-table-row",
                                            [
                                              _c("md-table-head", [
                                                _vm._v("Status"),
                                              ]),
                                              _c("md-table-head", [
                                                _vm._v("Barcode"),
                                              ]),
                                              _c("md-table-head", [
                                                _vm._v("Description"),
                                              ]),
                                              _vm.$_isLoadCapacityTypeEnabled(
                                                _vm.loadCapacityList,
                                                "Length"
                                              )
                                                ? _c("md-table-head", [
                                                    _vm._v(
                                                      "\n                                            L\n                                        "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.$_isLoadCapacityTypeEnabled(
                                                _vm.loadCapacityList,
                                                "Width"
                                              )
                                                ? _c("md-table-head", [
                                                    _vm._v(
                                                      "\n                                            W\n                                        "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.$_isLoadCapacityTypeEnabled(
                                                _vm.loadCapacityList,
                                                "Height"
                                              )
                                                ? _c("md-table-head", [
                                                    _vm._v(
                                                      "\n                                            H\n                                        "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.$_isLoadCapacityTypeEnabled(
                                                _vm.loadCapacityList,
                                                "Volume"
                                              )
                                                ? _c("md-table-head", [
                                                    _vm._v(
                                                      "\n                                            V\n                                        "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.$_isLoadCapacityTypeEnabled(
                                                _vm.loadCapacityList,
                                                "Weight"
                                              )
                                                ? _c("md-table-head", [
                                                    _vm._v(
                                                      "\n                                            Wt\n                                        "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.$_isLoadCapacityTypeEnabled(
                                                _vm.loadCapacityList,
                                                "Quantity"
                                              )
                                                ? _c("md-table-head", [
                                                    _vm._v(
                                                      "\n                                            Qty\n                                        "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.$root.isDesktop &&
                                              !_vm.isReadOnlyUser
                                                ? _c("md-table-head", [
                                                    _vm._v(
                                                      "\n                                            Actions\n                                        "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            _vm.shipmentDetails.pickupStop
                                              .stopItems,
                                            function (line) {
                                              return _c(
                                                "md-table-row",
                                                {
                                                  key: line.itemId,
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.openInventoryItem(
                                                        line.itemId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("md-table-cell", [
                                                    _c(
                                                      "div",
                                                      {
                                                        class:
                                                          "custom-badge inventory-status-" +
                                                          line.status
                                                            .toLowerCase()
                                                            .replace(
                                                              /\s+/g,
                                                              ""
                                                            ),
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              line.status
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c("md-table-cell", [
                                                    _vm._v(
                                                      _vm._s(line.barcode)
                                                    ),
                                                  ]),
                                                  _c("md-table-cell", [
                                                    _vm._v(
                                                      _vm._s(line.description)
                                                    ),
                                                  ]),
                                                  _vm.$_isLoadCapacityTypeEnabled(
                                                    _vm.loadCapacityList,
                                                    "Length"
                                                  )
                                                    ? _c("md-table-cell", [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm.$_displayLoadValue(
                                                                _vm.loadCapacityList,
                                                                line.load,
                                                                "Length"
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.$_isLoadCapacityTypeEnabled(
                                                    _vm.loadCapacityList,
                                                    "Width"
                                                  )
                                                    ? _c("md-table-cell", [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm.$_displayLoadValue(
                                                                _vm.loadCapacityList,
                                                                line.load,
                                                                "Width"
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.$_isLoadCapacityTypeEnabled(
                                                    _vm.loadCapacityList,
                                                    "Height"
                                                  )
                                                    ? _c("md-table-cell", [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm.$_displayLoadValue(
                                                                _vm.loadCapacityList,
                                                                line.load,
                                                                "Height"
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.$_isLoadCapacityTypeEnabled(
                                                    _vm.loadCapacityList,
                                                    "Volume"
                                                  )
                                                    ? _c("md-table-cell", [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm.$_displayLoadValue(
                                                                _vm.loadCapacityList,
                                                                line.load,
                                                                "Volume"
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.$_isLoadCapacityTypeEnabled(
                                                    _vm.loadCapacityList,
                                                    "Weight"
                                                  )
                                                    ? _c("md-table-cell", [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm.$_displayLoadValue(
                                                                _vm.loadCapacityList,
                                                                line.load,
                                                                "Weight"
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.$_isLoadCapacityTypeEnabled(
                                                    _vm.loadCapacityList,
                                                    "Quantity"
                                                  )
                                                    ? _c("md-table-cell", [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm.$_displayLoadValue(
                                                                _vm.loadCapacityList,
                                                                line.load,
                                                                "Quantity"
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.$root.isDesktop &&
                                                  !_vm.isReadOnlyUser
                                                    ? _c(
                                                        "md-table-cell",
                                                        {
                                                          staticClass:
                                                            "action-buttons",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "action-button",
                                                            },
                                                            [
                                                              !_vm.isReadOnlyUser
                                                                ? _c(
                                                                    "update-item-button",
                                                                    {
                                                                      attrs: {
                                                                        item: line,
                                                                        "is-delivered":
                                                                          line.status ===
                                                                          "Delivered",
                                                                        "shipment-status":
                                                                          _vm
                                                                            .shipmentDetails
                                                                            .status,
                                                                      },
                                                                      on: {
                                                                        itemUpdated:
                                                                          _vm.handleUpdateItem,
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              line.status ===
                                                              "Delivered"
                                                                ? _c(
                                                                    "md-tooltip",
                                                                    {
                                                                      staticClass:
                                                                        "line-tooltip",
                                                                      attrs: {
                                                                        "md-direction":
                                                                          "top",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                    You cannot edit a delivered item.\n                                                "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "action-button",
                                                            },
                                                            [
                                                              !_vm.isReadOnlyUser
                                                                ? _c(
                                                                    "md-button",
                                                                    {
                                                                      staticClass:
                                                                        "md-danger md-just-icon md-round",
                                                                      attrs: {
                                                                        title:
                                                                          "Unlink item",
                                                                        disabled:
                                                                          line.status ===
                                                                            "Delivered" ||
                                                                          _vm.shipmentDetails.status.toLowerCase() ===
                                                                            "complete" ||
                                                                          _vm.shipmentDetails.status.toLowerCase() ===
                                                                            "cancelled",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            return _vm.handleUnlinkItem(
                                                                              line.itemId
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "md-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "link_off"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              line.status ===
                                                              "Delivered"
                                                                ? _c(
                                                                    "md-tooltip",
                                                                    {
                                                                      staticClass:
                                                                        "line-tooltip",
                                                                      attrs: {
                                                                        "md-direction":
                                                                          "top",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                    You cannot unlink a delivered item.\n                                                "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm.shipmentDetails.status.toLowerCase() ===
                                                                  "complete"
                                                                ? _c(
                                                                    "md-tooltip",
                                                                    {
                                                                      staticClass:
                                                                        "line-tooltip",
                                                                      attrs: {
                                                                        "md-direction":
                                                                          "top",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                    You cannot edit an item when the shipment is marked as Complete\n                                                "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm.shipmentDetails.status.toLowerCase() ===
                                                                  "cancelled"
                                                                ? _c(
                                                                    "md-tooltip",
                                                                    {
                                                                      staticClass:
                                                                        "line-tooltip",
                                                                      attrs: {
                                                                        "md-direction":
                                                                          "top",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                    You cannot edit an item when the shipment is marked as Cancelled\n                                                "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                            ]),
                            !_vm.isSupportStaff
                              ? _c(
                                  "md-card-actions",
                                  { attrs: { "md-alignment": "right" } },
                                  [
                                    _vm.isCarrierAssigned &&
                                    _vm.carrierLabels.length
                                      ? _c("CarrierDownloadLabelButton", {
                                          attrs: {
                                            "use-icon": false,
                                            labels: _vm.carrierLabels,
                                          },
                                        })
                                      : _vm._e(),
                                    !_vm.isCarrierAssigned &&
                                    _vm.shipmentDetails.pickupStop.stopItems
                                      .length > 0
                                      ? _c("generate-label-button", {
                                          attrs: {
                                            id: _vm.shipmentDetails.shipmentId,
                                            "use-icon": false,
                                            type: "Shipment",
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "md-button",
                                      {
                                        staticClass: "md-success",
                                        attrs: {
                                          disabled:
                                            _vm.shipmentDetails.status.toLowerCase() ===
                                              "complete" ||
                                            _vm.shipmentDetails.status.toLowerCase() ===
                                              "cancelled",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAddItem()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Add Item\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm.shipmentDetails.status.toLowerCase() ===
                                    "complete"
                                      ? _c(
                                          "md-tooltip",
                                          {
                                            staticClass: "line-tooltip",
                                            attrs: { "md-direction": "left" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Not allowed to add an item when the shipment is already marked as Complete.\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm.shipmentDetails.status.toLowerCase() ===
                                        "cancelled"
                                      ? _c(
                                          "md-tooltip",
                                          {
                                            staticClass: "line-tooltip",
                                            attrs: { "md-direction": "left" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Not allowed to add an item when the shipment is already marked as Cancelled.\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-layout md-large-size-33 md-xlarge-size-50",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-green",
                          },
                          [
                            _vm.shipmentDetails.assignedTo.photoUrl === null
                              ? _c(
                                  "div",
                                  { staticClass: "card-icon" },
                                  [_c("md-icon", [_vm._v("account_box")])],
                                  1
                                )
                              : _vm._e(),
                            _vm.shipmentDetails.assignedTo.photoUrl !== null
                              ? _c(
                                  "div",
                                  { staticClass: "card-icon profile-image" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.shipmentDetails.assignedTo
                                          .photoUrl,
                                        alt: "avatar",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c("md-card-content", [
                          _c(
                            "div",
                            { staticClass: "custom-md-field" },
                            [
                              _c("div", { staticClass: "custom-label" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.shipmentDetails.carrierTeamId != null
                                        ? "Carrier"
                                        : "Driver"
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                              _vm.isReadOnlyUser
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.shipmentDetails.assignedTo.fullName
                                      )
                                    ),
                                  ])
                                : _vm.shipmentDetails.assignedTo.publicUserId
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Team Member Profile",
                                          params: {
                                            publicUserId:
                                              _vm.shipmentDetails.assignedTo
                                                .publicUserId,
                                          },
                                        },
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.shipmentDetails.assignedTo
                                              .fullName
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  )
                                : _vm.shipmentDetails.carrierTeamId != null
                                ? _c("span", [
                                    _vm.shipmentDetails.carrierTeam &&
                                    _vm.shipmentDetails.carrierTeam.logoUrl
                                      ? _c("img", {
                                          staticClass:
                                            "profile-image carrier-image-logo",
                                          attrs: {
                                            src:
                                              _vm.shipmentDetails.carrierTeam
                                                .logoUrl !== null
                                                ? _vm.shipmentDetails
                                                    .carrierTeam.logoUrl
                                                : _vm.$root.defaultPhotoUrl,
                                            alt: "carrier logo",
                                          },
                                          on: {
                                            error: _vm.$_setDefaultBrokenImage,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.shipmentDetails.carrierTeam
                                            .company
                                        ) +
                                        "\n                                "
                                    ),
                                  ])
                                : _c("reassign-shipment-button", {
                                    attrs: {
                                      type: "text",
                                      item: _vm.shipmentDetails,
                                      "team-members": _vm.teamMembers,
                                    },
                                    on: {
                                      assignedUser: _vm.handleAssignedUser,
                                    },
                                  }),
                              !_vm.shipmentDetails.rateGroupId &&
                              _vm.shipmentDetails.assignedTo.rateGroupId &&
                              _vm.showServicePackageDriver()
                                ? _c("div", [
                                    _c("div", { staticClass: "custom-label" }, [
                                      _vm._v("Service Package"),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.shipmentDetails.assignedTo
                                            .rateGroupName
                                        )
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.shipmentDetails.assignedTo.phone
                            ? _c("div", { staticClass: "details-box" }, [
                                _c("label", [_vm._v("Phone")]),
                                _c(
                                  "div",
                                  { staticClass: "phone-sms" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "tel:" +
                                            _vm.shipmentDetails.assignedTo
                                              .phone,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.shipmentDetails.assignedTo
                                                .phone
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm.shipmentDetails.assignedTo.phone
                                      ? _c("send-sms-button", {
                                          attrs: {
                                            "public-user-id":
                                              _vm.shipmentDetails.assignedTo
                                                .publicUserId,
                                            name: _vm.shipmentDetails.assignedTo
                                              .fullName,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.shipmentDetails.assignedCarrierBooking != null
                            ? _c("div", [
                                _c("div", { staticClass: "details-box" }, [
                                  _c("label", [_vm._v("Reference #:")]),
                                  _c("span", [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.shipmentDetails
                                            .assignedCarrierBooking
                                            .supportReference
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "details-box" }, [
                                  _c("label", [_vm._v("Status")]),
                                  _c("span", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "custom-badge",
                                        class:
                                          "status-" +
                                          _vm.$_getQuotingStatusBadgeColor(
                                            _vm.shipmentDetails.quotingStatus
                                          ),
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.$_getQuotingStatusText(
                                                _vm.shipmentDetails
                                                  .quotingStatus
                                              )
                                            ) +
                                            "\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm.shipmentDetails.assignedCarrierBooking
                                  .serviceLevel
                                  ? _c("div", { staticClass: "details-box" }, [
                                      _c("label", [_vm._v("Service Type:")]),
                                      _c("span", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "custom-badge",
                                            class:
                                              "status-" +
                                              _vm.$_getServiceLevelBadgeColor(
                                                _vm.shipmentDetails
                                                  .assignedCarrierBooking
                                                  .timeWindowEnd
                                              ),
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.shipmentDetails
                                                      .assignedCarrierBooking
                                                      .serviceLevel
                                                  )
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "details-box" }, [
                                  _c("label", [_vm._v("Price:")]),
                                  _c("span", [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.shipmentDetails
                                              .assignedCarrierBooking
                                              .feeAfterTax,
                                            _vm.shipmentDetails
                                              .assignedCarrierBooking.currency
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]),
                                ]),
                                _vm.shipmentDetails.assignedCarrierBooking
                                  .pickupEta
                                  ? _c("div", { staticClass: "details-box" }, [
                                      _c("label", [_vm._v("Pickup ETA:")]),
                                      _c("span", [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm._f("dateTimeFormat")(
                                                _vm.shipmentDetails
                                                  .assignedCarrierBooking
                                                  .pickupEta,
                                                _vm.DATE_TYPES.standardDate
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.shipmentDetails.assignedCarrierBooking
                                  .dropOffEta
                                  ? _c("div", { staticClass: "details-box" }, [
                                      _c("label", [_vm._v("Drop ETA:")]),
                                      _c("span", [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm._f("dateTimeFormat")(
                                                _vm.shipmentDetails
                                                  .assignedCarrierBooking
                                                  .dropOffEta,
                                                _vm.DATE_TYPES.standardDate
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-green",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-icon" },
                              [_c("md-icon", [_vm._v("account_box")])],
                              1
                            ),
                          ]
                        ),
                        _c("md-card-content", [
                          !_vm.shipmentDetails.contact.name &&
                          !_vm.shipmentDetails.contact.phone &&
                          !_vm.shipmentDetails.contact.email
                            ? _c("p", { staticClass: "grayedout-text" }, [
                                _vm._v(
                                  "\n                                No contact details provided.\n                            "
                                ),
                              ])
                            : _c("div", [
                                _vm.shipmentDetails.contact.name
                                  ? _c("div", { staticClass: "details-box" }, [
                                      _c("label", [_vm._v("Contact Name")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.shipmentDetails.contact.name
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.shipmentDetails.contact.phone
                                  ? _c("div", { staticClass: "details-box" }, [
                                      _c("label", [_vm._v("Phone")]),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "tel:" +
                                              _vm.shipmentDetails.contact.phone,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.shipmentDetails.contact
                                                  .phone
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.shipmentDetails.contact.email
                                  ? _c("div", { staticClass: "details-box" }, [
                                      _c("label", [_vm._v("Email")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.shipmentDetails.contact.email
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.shipmentDetails.customerRateGroupId &&
                                !_vm.shipmentDetails.rateGroupId &&
                                _vm.showServicePackageCustomer()
                                  ? _c("div", { staticClass: "details-box" }, [
                                      _c("label", [_vm._v("Service Package")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.shipmentDetails
                                              .customerRateGroupName
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-warning",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-icon" },
                              [_c("md-icon", [_vm._v("history")])],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "md-card-content",
                          [
                            _vm.shipmentStatusHistory.length > 0
                              ? _c(
                                  "time-line",
                                  { attrs: { plain: "", type: "simple" } },
                                  _vm._l(
                                    _vm.shipmentStatusHistory,
                                    function (history, index) {
                                      return _c(
                                        "time-line-item",
                                        {
                                          key: index,
                                          attrs: {
                                            inverted: "",
                                            "badge-type":
                                              "status-" +
                                              history.newStatus
                                                .replace(/\s+/g, "")
                                                .toLowerCase(),
                                            "badge-icon": "place",
                                            "is-clickable": true,
                                          },
                                          on: {
                                            clicked: function ($event) {
                                              return _vm.showHistoryInMap(
                                                history
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "badge",
                                              class:
                                                "status-" +
                                                history.newStatus
                                                  .replace(/\s+/g, "")
                                                  .toLowerCase(),
                                              attrs: { slot: "header" },
                                              slot: "header",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(history.newStatus) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "time",
                                              attrs: { slot: "headerTime" },
                                              slot: "headerTime",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm._f("dateTimeFormat")(
                                                      history.actionDate,
                                                      _vm.DATE_TYPES
                                                        .standardDate
                                                    )
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "h6",
                                            {
                                              attrs: { slot: "footer" },
                                              slot: "footer",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "ti-time",
                                              }),
                                              _vm._v(
                                                "\n\n                                        By " +
                                                  _vm._s(
                                                    history.changedByUserFullName
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _c("p", { staticClass: "grayedout-text" }, [
                                  _vm._v("No Status History Available"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.hasMarketplaceAccess
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50",
                      },
                      [
                        _c("CarrierBookingHistory", {
                          attrs: {
                            "carrier-booking-history": _vm.shipmentDetails
                              ? _vm.shipmentDetails.carrierBookingHistory
                              : [],
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-blue",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-icon" },
                              [
                                _c(
                                  "md-icon",
                                  { attrs: { title: "Assignment History" } },
                                  [_vm._v("supervised_user_circle")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "md-card-content",
                          [
                            _vm.shipmentDetails.shipmentUserHistory.length > 0
                              ? _c(
                                  "time-line",
                                  { attrs: { plain: "", type: "simple" } },
                                  _vm._l(
                                    _vm.shipmentDetails.shipmentUserHistory,
                                    function (shipmentUser) {
                                      return _c(
                                        "time-line-item",
                                        {
                                          key: shipmentUser.actionDate,
                                          attrs: {
                                            inverted: "",
                                            "is-image": true,
                                            "image-src":
                                              shipmentUser.assignedToPhotoUrl
                                                ? shipmentUser.assignedToPhotoUrl
                                                : shipmentUser.assignedToCarrierLogoUrl,
                                          },
                                        },
                                        [
                                          shipmentUser.assignedToPublicUserId
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge status-complete",
                                                  attrs: { slot: "header" },
                                                  slot: "header",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        shipmentUser.assignedToFullName
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            : shipmentUser.assignedToCarrierTeamId
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge status-complete",
                                                  attrs: { slot: "header" },
                                                  slot: "header",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        shipmentUser.assignedToCarrierCompany
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          !shipmentUser.assignedToPublicUserId &&
                                          !shipmentUser.assignedToCarrierTeamId
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge status-pending",
                                                  attrs: { slot: "header" },
                                                  slot: "header",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        Unassigned\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "time",
                                              attrs: { slot: "headerTime" },
                                              slot: "headerTime",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm._f("dateTimeFormat")(
                                                      shipmentUser.actionDate,
                                                      _vm.DATE_TYPES
                                                        .standardDate
                                                    )
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "h6",
                                            {
                                              attrs: { slot: "footer" },
                                              slot: "footer",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "ti-time",
                                              }),
                                              _vm._v(
                                                "\n\n                                        By " +
                                                  _vm._s(
                                                    shipmentUser.assignedByFullName
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _c("p", { staticClass: "grayedout-text" }, [
                                  _vm._v("No Assignment History Available"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-blue",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-icon icon-style" },
                              [
                                _c(
                                  "md-icon",
                                  { attrs: { title: "Shipment Contents" } },
                                  [_vm._v("medication_liquid")]
                                ),
                                _vm._v(
                                  "\n                                Contents\n                            "
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "md-card-content",
                          [
                            _c("ShipmentContentsDisplay", {
                              attrs: { contents: _vm.shipmentDetails.contents },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                !_vm.isIndividualUser
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100",
                      },
                      [
                        _vm.isTeamOwner || _vm.isAdmin
                          ? _c("cost-rates-list", {
                              attrs: {
                                "cost-lines": _vm.shipmentDetails.costLines,
                                "shipment-details": _vm.shipmentDetails,
                                title: "Costs",
                                "has-invoice":
                                  _vm.shipmentDetails.teamMemberInvoiceId !==
                                  null,
                                "rates-have-changed":
                                  _vm.updateCostsInBackground,
                              },
                              on: {
                                updateShipmentDetails: _vm.getShipmentDetails,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }