<template>
    <md-content class="stop-container">
        <div
            class="md-layout"
            v-if="
                !(
                    (content.sourceReference === undefined || content.sourceReference === null) &&
                    (content.stopRef === undefined || content.stopRef === null)
                )
            "
        >
            <div class="md-layout-item stop-ref-block">
                <span @click="showDetailsPage">
                    {{ content.sourceReference || content.stopRef }}
                </span>
                <md-button
                    title="stop details"
                    class="info--buttons md-white md-button md-just-icon md-round"
                    :md-ripple="false"
                    @click="showDetailsPage"
                >
                    <md-icon id="stop-ref-visibility-icon">
                        visibility
                    </md-icon>
                </md-button>
            </div>
        </div>
        <div class="md-layout stop-address-block">
            <div class="md-layout-item">
                <span v-if="content.isStartLocation" class="info-label">Start location</span>
                <span v-else-if="content.isEndLocation" class="info-label">End location</span>
                <span class="info-heading">{{ $_displayAddressName(content) }}</span>
            </div>
        </div>
        <div class="md-layout stop-status-block" v-if="['Cancelled', 'On Hold'].indexOf(content.status) === -1">
            <div class="md-layout-item" v-if="content.originalEta || content.arrivalDate">
                <span class="info-label">
                    {{ ['Arrived', 'Complete'].indexOf(content.status) === -1 ? 'ETA' : 'Time of arrival:' }}
                </span>
                <span v-if="['Arrived', 'Complete'].indexOf(content.status) !== -1">
                    {{ content.arrivalDate | timeFormat(DATE_TYPES.standardTime) }}
                </span>
                <span v-else>{{ content.originalEta | timeFormat(DATE_TYPES.standardTime) }}</span>
            </div>
        </div>
        <info-window-more-info-content
            :content="content"
            :show-details-page="showDetailsPage"
            :get-capacity-info="getCapacityInfo"
            :has-capacity-settings="hasCapacitySettings"
            :get-skills-info="getSkillsInfo"
            :has-skills-settings="hasSkillsSettings"
            :get-service-info="getServiceInfo"
            :has-service-settings="hasServiceSettings"
        />
        <div class="md-layout md-alignment-center-space-between">
            <div class="md-layout-item md-size-60 stop-team-member-block">
                <span class="team-member-image">
                    <img
                        v-if="content.photoUrl"
                        class="profile-image"
                        :src="content.photoUrl"
                        @error="$_setDefaultBrokenImage"
                        alt
                    />
                    <img v-else class="member-image" :src="$root.defaultPhotoUrl" alt />
                </span>
                <span class="left-margin">{{ content.teamMemberName }}</span>
            </div>
            <div class="md-layout-item md-size-40 stop-button-block">
                <delete-stop-button
                    class="info--buttons md-primary md-button md-just-icon md-round"
                    :stop-id="content.stopId"
                    :vehicle-id="content.vehicleId"
                    :allocate-stops="content.allocateStops"
                    :stop-ref="content.stopRef"
                    :address="content.address"
                    @stopDeleted="handleDeleteStop"
                />
                <reassign-stop-button
                    :team-members="content.teamMembers"
                    :allocate-stops="content.allocateStops"
                    :stop-id="content.stopId"
                    :vehicle-id="content.vehicleId"
                    @reassignedStop="handleReassignedStop"
                />
                <md-button
                    v-if="content.trackingLinkUrl"
                    title="tracking link"
                    class="info--buttons md-primary md-button md-just-icon md-round"
                    :md-ripple="false"
                    @click="showTrackingLink"
                >
                    <md-icon>directions_car</md-icon>
                </md-button>
            </div>
        </div>
    </md-content>
</template>

<script>
import { GeneralMixin } from '@/mixins';
import { DeleteStopButton, ReassignStopButton } from '@/pages/BatchStop/buttons';
import InfoWindowMoreInfoContent from './InfoWindowMoreInfoContent';

export default {
    name: 'InfoWindowStopContent',
    mixins: [GeneralMixin],
    components: {
        DeleteStopButton,
        ReassignStopButton,
        InfoWindowMoreInfoContent
    },
    props: {
        content: {
            type: Object,
            default: () => {}
        },
        showDetailsPage: {
            type: Function,
            default: () => {}
        },
        getCapacityInfo: {
            type: Function,
            default: () => {}
        },
        hasCapacitySettings: {
            type: Function,
            default: () => {}
        },
        getSkillsInfo: {
            type: Function,
            default: () => {}
        },
        hasSkillsSettings: {
            type: Function,
            default: () => {}
        },
        getServiceInfo: {
            type: Function,
            default: () => {}
        },
        hasServiceSettings: {
            type: Function,
            default: () => {}
        }
    },
    methods: {
        showTrackingLink() {
            window.open(`${this.content.trackingLinkUrl}?isAdmin=true&showPOD=true`, '_blank');
        },
        handleReassignedStop(record, stopId, vehicleId) {
            const reassignedTripStop = {
                records: [record],
                stops: [stopId],
                vehicleId
            };
            this.$emit('handleReassignedStop', reassignedTripStop);
        },
        handleDeleteStop(stopId, vehicleId) {
            const deleteTripStops = {
                stops: [stopId],
                vehicleId
            };
            this.$emit('handleDeleteStop', deleteTripStops);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';
.md-content {
    font-weight: bold;
}
.left-margin {
    margin-left: 4px !important;
}
.team-member-image {
    width: 40px;
    margin-top: -5px;
    img {
        width: 24px;
        border-radius: 50%;
    }
}

.stop-ref-block {
    span {
        color: #2b93ff;
        cursor: pointer;
    }
    button {
        height: 16px;
        margin: 0 !important;
        top: -1px;
    }
}

.stop-address-block {
    span {
        font-weight: 700;
    }
}

.stop-status-block {
    span {
        font-weight: 400;
    }
}

.custom-button-container {
    margin-top: -9px;
    button,
    ::v-deep button {
        margin: 0px 0px;
        min-width: 29px !important;
        width: 29px !important;
        height: 29px !important;
    }
    button:last-child {
        margin-right: 0;
    }
}
.stop-button-block {
    padding-left: 0 !important;
    button {
        margin: 0 0 0 2px !important;
        width: 29px !important;
        height: 29px !important;
        min-width: 29px !important;
        margin-left: 2px;
    }

    .custom-button-container {
        margin-top: -14px;
        margin-left: 2px;
        button {
            top: -14px;
        }
    }

    > * {
        float: right;
    }
}

.stop-team-member-block {
    padding-right: 0 !important;
    img {
        margin: 0 !important;
        width: 29px !important;
        height: 29px !important;
    }
}

.stop-container {
    .md-layout {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

#stop-ref-visibility-icon {
    color: #2b93ff !important;
}
</style>
