<template>
    <div>
        <p v-if="ready">
            Thank you! Your payment has been processed. You can now manage your subscription
            <a :href="`https://locate2u.recurly.com/account/${token}`" target="_blank">
                here
            </a>
        </p>
        <p v-if="error">{{ error }}</p>
    </div>
</template>
<script>
import { handleRequests } from '@/helpers';
import { mapGetters, mapMutations } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'PaymentSuccess',
    mixins: [GeneralMixin],
    data() {
        return {
            token: '',
            user: null,
            ready: false,
            error: null
        };
    },
    async mounted() {
        this.$_handleLoaderState(true);
        // Call Server to say payment success and save account code
        this.user = Object.assign({}, this.getUser);
        const {
            data: { loginToken }
        } = await this.fetchSubscription(this.$route.query.account, this.$route.query.plan);
        this.token = loginToken;
        this.ready = true;
        this.$_handleLoaderState(false);
    },
    computed: {
        ...mapGetters({
            getUser: 'user/user'
        })
    },
    methods: {
        ...mapMutations({
            updateProfile: 'user/UPDATE_USER'
        }),
        async fetchSubscription(account, plan) {
            let token;
            const endpoint = `api/subscription/payment-success`;
            const response = await handleRequests(endpoint, {
                params: {
                    account,
                    plan
                },
                method: 'post'
            }).catch((err) => {
                this.error = err.data.errors
                    .map((error) => {
                        return error.message;
                    })
                    .join(',');
                token = err.data.loginToken;
                this.$_handleLoaderState(false);
                this.$notify({
                    message: this.error,
                    type: 'danger'
                });
            });
            this.user.loginToken = this.error ? token : response.data.loginToken;
            this.updateProfile(this.user);
            return response;
        }
    }
};
</script>

<style lang="scss" scoped></style>
