var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "modal-body" }, [
      _c(
        "div",
        { staticClass: "field-with-icon" },
        [
          _c(
            "form-group",
            { attrs: { name: "distanceCost", label: "Distance Cost" } },
            [
              _c("md-input", {
                attrs: { type: "number", disabled: !_vm.hasTeamAccess },
                model: {
                  value: _vm.distanceCost,
                  callback: function ($$v) {
                    _vm.distanceCost = $$v
                  },
                  expression: "distanceCost",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("md-icon", [_vm._v("info")]),
              _c(
                "md-tooltip",
                {
                  staticClass: "tooltip-width",
                  attrs: { "md-direction": "right" },
                },
                [
                  _vm._v(
                    "\n                    Cost that is incurred for each km the vehicle travels. The higher the value the stops will be in\n                    shorter distance to one another. Recommended value is\n                    "
                  ),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.defaultRouteSettings.distanceCost)),
                  ]),
                  _vm._v("\n                    .\n                "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "field-with-icon" },
        [
          _c(
            "form-group",
            { attrs: { name: "timeCost", label: "Time Cost" } },
            [
              _c("md-input", {
                attrs: { type: "number", disabled: !_vm.hasTeamAccess },
                model: {
                  value: _vm.timeCost,
                  callback: function ($$v) {
                    _vm.timeCost = $$v
                  },
                  expression: "timeCost",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("md-icon", [_vm._v("info")]),
              _c(
                "md-tooltip",
                {
                  staticClass: "tooltip-width",
                  attrs: { "md-direction": "right" },
                },
                [
                  _vm._v(
                    "\n                    Cost that is incurred for each hour the vehicle travels. The higher the value the stops will\n                    have less travel time between one another. Recommended value is\n                    "
                  ),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.defaultRouteSettings.timeCost)),
                  ]),
                  _vm._v("\n                    .\n                "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isDistributeWorkload === false
        ? _c(
            "div",
            { staticClass: "field-with-icon" },
            [
              _c(
                "form-group",
                { attrs: { name: "fixedCost", label: "Fixed Costs" } },
                [
                  _c("md-input", {
                    attrs: { type: "number", disabled: !_vm.hasTeamAccess },
                    model: {
                      value: _vm.fixedCost,
                      callback: function ($$v) {
                        _vm.fixedCost = $$v
                      },
                      expression: "fixedCost",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("md-icon", [_vm._v("info")]),
                  _c(
                    "md-tooltip",
                    {
                      staticClass: "tooltip-width",
                      attrs: { "md-direction": "right" },
                    },
                    [
                      _vm._v(
                        "\n                    A fixed cost which is applied when a vehicle is used, irrespective of the number of jobs\n                    assigned to it. Recommended value is\n                    "
                      ),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.defaultRouteSettings.fixedCost)),
                      ]),
                      _vm._v("\n                    .\n                "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isDistributeWorkload === true
        ? _c(
            "div",
            { staticClass: "field-with-icon" },
            [
              _c(
                "form-group",
                {
                  attrs: {
                    name: "distributeWorkloadCost",
                    label: "Distribute Workload Cost",
                  },
                },
                [
                  _c("md-input", {
                    attrs: { type: "number", disabled: !_vm.hasTeamAccess },
                    model: {
                      value: _vm.distributeWorkloadCost,
                      callback: function ($$v) {
                        _vm.distributeWorkloadCost = $$v
                      },
                      expression: "distributeWorkloadCost",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("md-icon", [_vm._v("info")]),
                  _c(
                    "md-tooltip",
                    {
                      staticClass: "tooltip-width",
                      attrs: { "md-direction": "right" },
                    },
                    [
                      _vm._v(
                        "\n                    Workload balancing enables work to be spread more evenly across your planned routes, which helps\n                    to (a) keep your staff content and (b) retain capacity across your fleet for future jobs. You\n                    are advised to try\n                    "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.defaultRouteSettings.distributeWorkloadCost
                          )
                        ),
                      ]),
                      _vm._v(
                        "\n                    as a starting point and then reduce it (for example to 1) if the travel time is impacted too\n                    greatly, or increase it if the balance doesn’t seem enough.\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "field-with-icon" },
        [
          _c(
            "form-group",
            {
              attrs: {
                name: "routeCenteringPenalty",
                label: "Route Centering Penalty",
              },
            },
            [
              _c("md-input", {
                attrs: { type: "number", disabled: !_vm.hasTeamAccess },
                model: {
                  value: _vm.routeCenteringPenalty,
                  callback: function ($$v) {
                    _vm.routeCenteringPenalty = $$v
                  },
                  expression: "routeCenteringPenalty",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("md-icon", [_vm._v("info")]),
              _c(
                "md-tooltip",
                {
                  staticClass: "tooltip-width",
                  attrs: { "md-direction": "right" },
                },
                [
                  _vm._v(
                    "\n                    Cost which is applied to minimise the travel distance or time between the most central stop on a\n                    route and all other stops on the route. You can use this cost to force routes to form tighter\n                    clusters, instead of the typical ‘petal’ shaped routes that arise naturally from a central\n                    depot. Recommended value range is\n                    "
                  ),
                  _c("strong", [
                    _vm._v(
                      "0.1 to " +
                        _vm._s(_vm.defaultRouteSettings.routeCenteringPenalty)
                    ),
                  ]),
                  _vm._v("\n                    .\n                "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "reset" },
        [
          _vm.hasTeamAccess
            ? _c(
                "md-button",
                {
                  staticClass: "dialog-button md-default",
                  on: { click: _vm.resetSettingsToDefault },
                },
                [
                  _vm._v(
                    "\n                Reset to default values\n            "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _vm.hasTeamAccess
          ? _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary",
                on: { click: _vm.saveRoutingSetting },
              },
              [_vm._v("\n            Save\n        ")]
            )
          : _vm._e(),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Routing Setting")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }