<template>
    <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="md-toolbar-row">
                <div class="md-toolbar-section-start">
                    <h3>
                        {{ $t('menus.dashboard') }}
                    </h3>
                </div>
                <div class="md-toolbar-section-end">
                    <div class="md-collapse">
                        <div>
                            <label class="field-label">{{ $t('inputs.selectDate') }}</label>
                            <md-datepicker class="dashboard-date" v-model="dashboardDate" md-immediately />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
            <stats-card header-color="blue">
                <template slot="header">
                    <div class="card-icon">
                        <md-icon>equalizer</md-icon>
                    </div>
                    <p class="category">
                        My {{ $t('pages.dashboard.nrStopsCompleted') }}
                        <br />
                        for {{ dashboardDate | dateFormat(DATE_TYPES.internationalDate) }}
                    </p>
                    <p class="category">&nbsp;</p>
                    <div class="stat-loader" v-show="isLoading"></div>
                    <h3 class="title">
                        {{ results.completedStop }}
                    </h3>
                </template>

                <template slot="footer">
                    <div class="stats">
                        <md-icon>update</md-icon>
                        {{ $t('pages.dashboard.justUpdated') }}
                    </div>
                </template>
            </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25" v-if="hasTeamAccess">
            <stats-card header-color="green">
                <template slot="header">
                    <div class="card-icon">
                        <md-icon>store</md-icon>
                    </div>
                    <p class="category">
                        {{ $t('pages.dashboard.nrStopsCompleted') }}
                        <br />
                        for {{ dashboardDate | dateFormat(DATE_TYPES.internationalDate) }}
                    </p>
                    <p class="category category-label">{{ $t('pages.dashboard.team') }}</p>
                    <div class="stat-loader" v-show="isLoading"></div>
                    <h3 class="title">
                        {{ results.completedStopIncludeTeam }}
                    </h3>
                </template>

                <template slot="footer">
                    <div class="stats">
                        <md-icon>update</md-icon>
                        {{ $t('pages.dashboard.justUpdated') }}
                    </div>
                </template>
            </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
            <stats-card header-color="rose">
                <template slot="header">
                    <div class="card-icon">
                        <md-icon>equalizer</md-icon>
                    </div>
                    <p class="category">
                        My {{ $t('pages.dashboard.nrStopsRemaining') }}
                        <br />
                        for {{ dashboardDate | dateFormat(DATE_TYPES.internationalDate) }}
                    </p>
                    <p class="category">&nbsp;</p>
                    <div class="stat-loader" v-show="isLoading"></div>
                    <h3 class="title">
                        {{ results.remainingStop }}
                    </h3>
                </template>

                <template slot="footer">
                    <div class="stats">
                        <md-icon>update</md-icon>
                        {{ $t('pages.dashboard.justUpdated') }}
                    </div>
                </template>
            </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25" v-if="hasTeamAccess">
            <stats-card header-color="warning">
                <template slot="header">
                    <div class="card-icon">
                        <md-icon>weekend</md-icon>
                    </div>
                    <p class="category">
                        {{ $t('pages.dashboard.nrStopsRemaining') }}
                        <br />
                        for {{ dashboardDate | dateFormat(DATE_TYPES.internationalDate) }}
                    </p>
                    <p class="category category-label">{{ $t('pages.dashboard.team') }}</p>
                    <div class="stat-loader" v-show="isLoading"></div>
                    <h3 class="title">
                        {{ results.remainingStopIncludeTeam }}
                    </h3>
                </template>

                <template slot="footer">
                    <div class="stats">
                        <md-icon>update</md-icon>
                        {{ $t('pages.dashboard.justUpdated') }}
                    </div>
                </template>
            </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
            <stats-card header-color="primary">
                <template slot="header">
                    <div class="card-icon">
                        <md-icon>build</md-icon>
                    </div>
                    <p class="category">My {{ $t('pages.dashboard.nrOverDueSchedules') }}</p>
                    <p class="category">&nbsp;</p>
                    <div class="stat-loader" v-show="isLoading"></div>
                    <h3 class="title">
                        {{ results.overdueScheduledMaintenance }}
                    </h3>
                </template>

                <template slot="footer">
                    <div class="stats">
                        <md-icon>update</md-icon>
                        {{ $t('pages.dashboard.justUpdated') }}
                    </div>
                </template>
            </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
            <stats-card header-color="warning">
                <template slot="header">
                    <div class="card-icon">
                        <md-icon>build</md-icon>
                    </div>
                    <p class="category">{{ $t('pages.dashboard.nrOverDueSchedules') }}</p>
                    <p class="category category-label">{{ $t('pages.dashboard.team') }}</p>
                    <div class="stat-loader" v-show="isLoading"></div>
                    <h3 class="title">
                        {{ results.overdueScheduledMaintenanceIncludeTeam }}
                    </h3>
                </template>

                <template slot="footer">
                    <div class="stats">
                        <md-icon>update</md-icon>
                        {{ $t('pages.dashboard.justUpdated') }}
                    </div>
                </template>
            </stats-card>
        </div>
        <!--For now Hide the Distance Travelled and Travel Time-->
        <!--<div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
            <stats-card header-color="green">
                <template slot="header">
                    <div class="card-icon">
                        <md-icon>store</md-icon>
                    </div>
                    <p class="category">Distance Travelled</p>
                    <div class="stat-loader" v-show="isLoading"></div>
                    <h3 class="title">{{ results.distance }} km</h3>
                </template>
                <template slot="footer">
                    <div class="stats">
                        <md-icon class="text-danger">warning</md-icon>
                        <a href="#pablo">Get More...</a>
                    </div>
                </template>
            </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
            <stats-card header-color="warning">
                <template slot="header">
                    <div class="card-icon">
                        <md-icon>weekend</md-icon>
                    </div>
                    <p class="category">Travel Time</p>
                    <div class="stat-loader" v-show="isLoading"></div>
                    <h3 class="title">
                        {{ results.travelTime }}
                    </h3>
                </template>

                <template slot="footer">
                    <div class="stats">
                        <md-icon class="text-danger">warning</md-icon>
                        <a href="#pablo">Get More...</a>
                    </div>
                </template>
            </stats-card>
        </div>-->
    </div>
</template>

<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapMutations, mapGetters } from 'vuex';
import { StatsCard } from '@/components';
import { handleRequests } from '../../helpers';

export default {
    components: {
        StatsCard
    },
    mixins: [GeneralMixin],
    data() {
        return {
            dashboardDate: new Date(),
            isLoading: false,
            results: []
        };
    },
    computed: {
        ...mapGetters({
            hasTeamAccess: 'user/hasTeamAccess'
        })
    },
    watch: {
        dashboardDate(newVal, oldVal) {
            const me = this;
            if (
                moment(newVal)
                    .format('YYYY-MM-DD')
                    .valueOf() !==
                moment(oldVal)
                    .format('YYYY-MM-DD')
                    .valueOf()
            ) {
                me.changeDate({ date: newVal });
                me.getRecords();
            }
        }
    },
    methods: {
        ...mapMutations({
            changeDate: 'map/CHANGE_DATE'
        }),
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
        getRecords() {
            const me = this;
            if (!me.dashboardDate) {
                return;
            }

            me.isLoading = true;
            const api = `/api/dashboard/dashboarddata/${moment(me.dashboardDate).format('YYYY-MM-DD')}`;
            handleRequests(api).then((response) => {
                me.isLoading = false;
                me.results = response.data;
            });
        }
    },
    async mounted() {
        const me = this;
        me.$_handleLoaderState(false);
        me.getRecords();
    }
};
</script>
<style lang="scss" scoped>
.stat-loader {
    border: 4px solid #eeeeee;
    border-radius: 50%;
    border-top: 4px solid #2b93ff;
    width: 24px;
    height: 24px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    float: right;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.category-label {
    font-size: 12px !important;
    color: #999999 !important;
}
</style>
