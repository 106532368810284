var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content.stopRef === undefined || _vm.content.stopRef === null
    ? _c(
        "md-content",
        { staticClass: "info-detail--container" },
        [
          _c("md-content", { staticClass: "info-detail route-optimization" }, [
            _c("span", { staticClass: "info-label" }, [_vm._v("Address")]),
            _c("span", [_vm._v(_vm._s(_vm.content.address))]),
          ]),
        ],
        1
      )
    : _c(
        "md-content",
        { staticClass: "optimised-stop-container" },
        [
          _c("div", { staticClass: "md-layout optimised-stop-ref-block" }, [
            !(
              (_vm.content.sourceReference === undefined ||
                _vm.content.sourceReference === null) &&
              (_vm.content.stopRef === undefined ||
                _vm.content.stopRef === null)
            )
              ? _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c("span", { on: { click: _vm.showDetailsPage } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.content.sourceReference || _vm.content.stopRef
                          ) +
                          "\n            "
                      ),
                    ]),
                    _c(
                      "md-button",
                      {
                        staticClass:
                          "info--buttons md-white md-button md-just-icon md-round",
                        attrs: { title: "stop details", "md-ripple": false },
                        on: { click: _vm.showDetailsPage },
                      },
                      [
                        _c(
                          "md-icon",
                          {
                            attrs: { id: "optimised-stop-ref-visibility-icon" },
                          },
                          [
                            _vm._v(
                              "\n                    visibility\n                "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "md-layout optimised-stop-address-block" }, [
            _c("div", { staticClass: "md-layout-item" }, [
              _c("span", [_vm._v(_vm._s(_vm.content.address))]),
            ]),
          ]),
          _c("info-window-more-info-content", {
            attrs: {
              content: _vm.content,
              "show-details-page": _vm.showDetailsPage,
              "get-capacity-info": _vm.getCapacityInfo,
              "has-capacity-settings": _vm.hasCapacitySettings,
              "get-skills-info": _vm.getSkillsInfo,
              "has-skills-settings": _vm.hasSkillsSettings,
              "get-service-info": _vm.getServiceInfo,
              "has-service-settings": _vm.hasServiceSettings,
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }