<template>
    <div class="details-page" v-if="assetDetails != null">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <h3 class="title" v-if="assetDetails.name != null">
                            {{ assetDetails.name }}
                            <badge type="warning" class="custom-badge generic-badge">
                                {{ assetDetails.type || 'Asset' }}
                            </badge>
                        </h3>
                        <h3 class="title" v-else>
                            {{ assetDetails.assetId }}
                            <badge type="warning" class="custom-badge generic-badge">
                                {{ assetDetails.type || 'Asset' }}
                            </badge>
                        </h3>
                    </div>
                    <div class="custom-toolbar-end" v-if="!$root.isMobileOnly">
                        <div class="header-button-container">
                            <update-asset-button
                                :asset="assetDetails"
                                :use-icon="false"
                                :custom-fields="assetCustomFieldList"
                                @onAssetUpdate="getAssetDetails"
                                class-name="header-button"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout md-alignment-top-left content-layout-panel">
            <div
                class="md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-40 md-xlarge-size-40"
            >
                <div class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-blue">
                            <div class="card-icon custom-header-icon-text">
                                <md-icon>description</md-icon>
                                Asset Details
                            </div>
                        </md-card-header>

                        <md-card-content>
                            <div class="asset-details-box details-box">
                                <div>
                                    <label>Asset Name</label>
                                    <span class="value">
                                        {{ assetDetails.name }}
                                    </span>
                                </div>
                                <div>
                                    <label>Type</label>
                                    <span class="value">
                                        {{ assetDetails.type }}
                                    </span>
                                </div>
                                <div>
                                    <label>Created By</label>
                                    <span class="value">
                                        {{ assetDetails.createdByUser }}
                                    </span>
                                </div>
                            </div>
                            <div class="asset-details-box details-box">
                                <div>
                                    <label>Asset Group Name</label>
                                    <span class="value">
                                        <router-link
                                            :to="{
                                                name: 'Asset Group Details',
                                                params: { assetGroupId: assetDetails.assetGroupId }
                                            }"
                                            target="_blank"
                                        >
                                            {{ assetDetails.assetGroupName }}
                                        </router-link>
                                    </span>
                                </div>
                                <div>
                                    <label>Time Zone</label>
                                    <span class="value">
                                        {{ assetDetails.timeZone }}
                                    </span>
                                </div>
                            </div>
                            <div class="asset-details-box details-box">
                                <div>
                                    <label>Current Odometer Reading</label>
                                    <span class="value" v-if="assetDetails.estimatedOdometerValue">
                                        {{ assetDetails.estimatedOdometerValue }}
                                        {{ assetDetails.estimatedOdometerUnitType }}
                                    </span>
                                    <span class="value" v-else>
                                        <i>(Not set)</i>
                                    </span>
                                </div>
                                <div>
                                    <label>Current Engine Hour Reading</label>
                                    <span class="value" v-if="assetDetails.estimatedEngineHourValue">
                                        {{ assetDetails.estimatedEngineHourValue }} hours
                                    </span>
                                    <span class="value" v-else>
                                        <i>(Not set)</i>
                                    </span>
                                </div>
                                <div v-if="assetDetails.type === 'Vehicle' || assetDetails.type === 'Trailer'">
                                    <label>Vehicle License Plate</label>
                                    <span class="value" v-if="assetDetails.vehicleLicensePlate">
                                        {{ assetDetails.vehicleLicensePlate }}
                                    </span>
                                    <span class="value" v-else>
                                        <i>(Not set)</i>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div class="line-separator" v-if="trackerExtraFields.length > 0"></div>
                                <h4 class="small-details-title" v-if="trackerExtraFields.length > 0">
                                    Tracker Details
                                </h4>
                                <div class="tracker-details-box" v-if="trackerExtraFields.length > 0">
                                    <div
                                        class="details-box"
                                        v-for="(customField, index) in trackerExtraFields"
                                        :key="index"
                                    >
                                        <label>{{ customField.label }}</label>

                                        <span
                                            v-if="
                                                customFieldValues !== null &&
                                                    customFieldValues[customField.name] !== null &&
                                                    customField.type.toLowerCase() === 'date'
                                            "
                                        >
                                            {{
                                                customFieldValues[customField.name]
                                                    | dateTimeFormat(DATE_TYPES.standardDate)
                                            }}
                                        </span>
                                        <span v-else-if="customField.name === 'trackerReference'">
                                            {{ assetDetails.trackerReference }}
                                        </span>
                                        <span v-else>{{ customFieldValues[customField.name] }}</span>
                                    </div>
                                </div>
                                <div class="line-separator"></div>
                                <h4 class="small-details-title">Custom fields</h4>
                                <div
                                    class="details-box"
                                    v-for="(customField, index) in assetCustomFieldList"
                                    :key="index"
                                    v-if="
                                        customField.assetType === 'Any' || customField.assetType === assetDetails.type
                                    "
                                >
                                    <label>{{ customField.label }}</label>
                                    <span
                                        class="value"
                                        v-if="
                                            customFieldValues !== null && customFieldValues[customField.name] !== null
                                        "
                                    >
                                        <span v-if="customField.type.toLowerCase() === 'date'">
                                            {{
                                                customFieldValues[customField.name]
                                                    | dateTimeFormat(DATE_TYPES.standardDate)
                                            }}
                                        </span>
                                        <span v-else>{{ customFieldValues[customField.name] }}</span>
                                    </span>
                                </div>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>

                <div class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-rose">
                            <div class="card-icon custom-header-icon-text">
                                <md-icon>description</md-icon>
                                Maintenance Schedules
                            </div>
                        </md-card-header>

                        <md-card-content>
                            <!-- <div>
                                <div v-for="(item, index) in assetDetails.upcomingMaintenanceSchedules" :key="index">
                                    {{item.maintenanceName}}
                                </div>
                            </div> -->

                            <md-table
                                v-model="sortedbyNameAssetMaintenanceSchedule"
                                table-header-color="green"
                                v-if="sortedbyNameAssetMaintenanceSchedule.length > 0"
                            >
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Name">{{ item.maintenanceName }}</md-table-cell>
                                    <md-table-cell md-label="Action" class="action-buttons">
                                        <update-maintenance-schedule-button
                                            :maintenance-data="item"
                                            :asset-data="assetDetails"
                                            @maintenanceCompleted="handleCompleteSchedule"
                                        />
                                        <remove-maintenance-schedule-button
                                            :maintenance-data="item"
                                            @maintenanceCompleted="handleCompleteSchedule"
                                        />
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <p v-else class="grayedout-text">No maintenance schedule available</p>
                        </md-card-content>
                    </md-card>
                </div>
            </div>
            <div
                class="md-layout-item md-small-size-100 md-layout md-medium-size-100 md-large-size-60 md-xlarge-size-60"
                v-if="$root.isDesktop"
            >
                <div
                    class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50"
                    v-if="$root.isDesktop"
                >
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-green">
                            <div class="card-icon custom-header-icon-text">
                                <md-icon>schedule</md-icon>
                                Upcoming Maintenance
                            </div>
                        </md-card-header>

                        <md-card-content>
                            <time-line plain type="simple" v-if="assetDetails.upcomingMaintenanceSchedules.length > 0">
                                <time-line-item
                                    inverted
                                    :is-clickable="true"
                                    :badge-type="`status-${$_getAssetLogTypeBadgeColor(item.logType)}`"
                                    :badge-icon="`${$_getAssetLogTypeIcon(item.logType)}`"
                                    v-for="(item, index) in assetDetails.upcomingMaintenanceSchedules"
                                    @clicked="completeSchedule(item)"
                                    :key="index"
                                >
                                    <div slot="header" class="title-badge">{{ item.maintenanceName }}</div>
                                    <span
                                        slot="headerTime"
                                        class="generic-badge status-pending"
                                        :class="
                                            `status-${$_getAssetLogSubTypeBadgeColor(item.logType, item.logSubType)}`
                                        "
                                    >
                                        {{ $t('assetLogSubTypes.' + item.logSubType) }}
                                    </span>

                                    <div slot="content">
                                        <!-- <span>{{ item.maintenanceName }}</span> -->
                                        <reminder-indicator
                                            :maintenance-data="item"
                                            :current-engine-hours="item.estimatedEngineHourValue"
                                            :next-engine-hour-due="
                                                item.nextDueEngineHours != null
                                                    ? parseFloat(item.nextDueEngineHours)
                                                    : null
                                            "
                                            :next-due-distance="
                                                item.nextDueOdometer != null ? parseFloat(item.nextDueOdometer) : null
                                            "
                                            :current-distance-reading="item.estimatedOdometerValue"
                                            :repeat-interval="parseInt(1)"
                                            :next-due-date="item.nextDueServiceDate"
                                        />
                                    </div>

                                    <h6 slot="footer" class="heading-time">
                                        <i class="ti-time" />
                                        {{ item.createdDate | dateFormat(DATE_TYPES.standardDate) }}
                                    </h6>
                                </time-line-item>
                            </time-line>
                            <p v-else class="grayedout-text">No upcoming maintenance schedule available</p>
                        </md-card-content>
                    </md-card>
                </div>

                <div
                    class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50"
                    v-if="$root.isDesktop"
                >
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-warning">
                            <div class="card-icon custom-header-icon-text">
                                <md-icon>history</md-icon>
                                Asset Logs
                            </div>
                        </md-card-header>

                        <md-card-content>
                            <time-line plain type="simple" v-if="assetDetails.logs.length > 0">
                                <time-line-item
                                    inverted
                                    :badge-type="`status-${$_getAssetLogTypeBadgeColor(logItem.logType)}`"
                                    :badge-icon="`${$_getAssetLogTypeIcon(logItem.logType)}`"
                                    v-for="(logItem, index) in assetDetails.logs"
                                    :key="index"
                                    :is-clickable="true"
                                    @clicked="showDetails(logItem)"
                                >
                                    <span
                                        slot="header"
                                        class="generic-badge status-pending"
                                        :class="
                                            `status-${$_getAssetLogSubTypeBadgeColor(
                                                logItem.logType,
                                                logItem.logSubType
                                            )}`
                                        "
                                    >
                                        {{ $t('assetLogSubTypes.' + logItem.logSubType) }}
                                    </span>

                                    <div slot="content" class="custom-ellipsis">
                                        {{ logItem.notes }}
                                    </div>

                                    <h6 slot="footer" class="heading-time">
                                        <i class="ti-time" />
                                        {{ logItem.createdDate | dateFormat(DATE_TYPES.standardDate) }}
                                    </h6>
                                </time-line-item>
                            </time-line>
                            <p v-else class="grayedout-text">No log history available</p>
                        </md-card-content>
                    </md-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GeneralMixin, AssetManagementMixin } from '@/mixins';
import { Badge, TimeLine, TimeLineItem } from '@/components';
import { handleRequests } from '@/helpers';
import { mapGetters } from 'vuex';
import { trackerDefaults } from '@/utils/customAssetFieldDefaults';
import ReminderIndicator from '@/pages/Maintenance/components/ReminderIndicator';
import { RemoveMaintenanceScheduleButton, UpdateMaintenanceScheduleButton } from '@/pages/Maintenance/buttons';
import CreateAssetLogModal from '@/pages/AssetTracking/ModalComponents/CreateAssetLogModal';
import UpdateAssetButton from './Buttons/UpdateAssetButton';
import AssetLogDetailsModal from './ModalComponents/AssetLogDetailsModal';

export default {
    name: 'AssetDetails',
    mixins: [GeneralMixin, AssetManagementMixin],
    components: {
        Badge,
        UpdateAssetButton,
        TimeLine,
        TimeLineItem,
        ReminderIndicator,
        RemoveMaintenanceScheduleButton,
        UpdateMaintenanceScheduleButton
    },
    computed: {
        ...mapGetters({
            getUser: 'user/user'
        })
    },
    async mounted() {
        this.$_handleLoaderState(true);
        const user = this.getUser;

        if (user.assetCustomFieldDefinitions) {
            this.assetCustomFieldList = user.assetCustomFieldDefinitions;
        }

        await this.getAssetDetails();
        // this.$_handleLoaderState(false);
    },

    data() {
        return {
            assetDetails: null,
            assetCustomFieldList: [],
            trackerExtraFields: [],
            customFieldValues: {},
            sortedbyNameAssetMaintenanceSchedule: []
        };
    },
    methods: {
        async getAssetDetails() {
            this.$_handleLoaderState(true);
            const api = `/api/assets/${this.$route.params.assetId}`;
            const {
                data: { data }
            } = await handleRequests(api);

            this.assetDetails = data;
            this.customFieldValues = data.customFields;

            if (data.type === 'Tracker') {
                this.trackerExtraFields = trackerDefaults;
            }

            if (this.assetDetails.upcomingMaintenanceSchedules != null) {
                this.sortedbyNameAssetMaintenanceSchedule = [...this.assetDetails.upcomingMaintenanceSchedules];
                // since maintenance schedule tab will display the same list using the upcoming maintenance schedule data but ordered by name
                // currently sorting this one for now via client side but for phase 2 should be using server to return 2 different list
                // eslint-disable-next-line func-names
                this.sortedbyNameAssetMaintenanceSchedule.sort(function(a, b) {
                    if (a.maintenanceName < b.maintenanceName) {
                        return -1;
                    }
                    if (a.maintenanceName > b.maintenanceName) {
                        return 1;
                    }
                    return 0;
                });
            }
            this.$_handleLoaderState(false);
        },
        showDetails(data) {
            this.$modal.show(AssetLogDetailsModal, { logData: data }).then((response) => {
                this.$modal.hide();
            });
        },
        handleCompleteSchedule() {
            this.getAssetDetails();
        },
        completeSchedule(data) {
            this.$modal
                .show(CreateAssetLogModal, {
                    maintenanceData: data
                })
                .then((response) => {
                    this.getAssetDetails();
                    this.$modal.hide();
                });
        }
    }
};
</script>

<style scoped lang="scss">
.content-layout-panel {
    > div {
        padding: 0;
    }
}

.custom-badge {
    height: initial;
    width: initial;
    position: initial;
    border-radius: initial;
    margin: initial;
    border-radius: 12px;
    padding: 5px 12px;
    text-transform: uppercase;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    margin-left: 10px;
    vertical-align: sub;
}

.asset-details-box {
    > div {
        display: inline-block;
        min-width: 200px;
    }
    > div:first-child {
        width: 350px;
    }
}

.tracker-details-box {
    > div {
        display: inline-block;
        min-width: 200px;
        margin: 10px 0;
    }
}

.line-separator {
    height: 1px;
    background-color: #eee;
}

.small-details-title {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 800;
}

.header-button-container {
    > button {
        margin-right: 0;
    }
    .status-btn {
        display: inline-block;
        text-transform: uppercase;
        font-size: 12px;
        > ::v-deep span {
            margin: 6px 8px;
            height: 36px;
            line-height: 36px;
            border-radius: 2px;
            margin-right: 0px;
        }

        > ::v-deep .dropdown-menu {
            margin-left: 9px;
            margin-top: 0;
        }
    }
}

.log-box {
    border-bottom: 1px solid #eee;
    padding: 10px 0;

    > div {
        display: inline-block;
        width: 25%;
        vertical-align: top;
    }
    .custom-badge {
        vertical-align: middle;
        margin-left: 0;
    }

    .logtype-box {
        display: block;
        width: 100%;
        margin-bottom: 5px;
    }

    .date-badge {
        font-weight: 500;
        margin-right: 10px;
    }
    .view-detail-btn {
        width: 100%;
        text-align: right;
        color: #0076f0 !important;
        span:hover {
            cursor: pointer;
        }
    }

    .custom-label {
        font-weight: 500;
        font-size: 14px;
        color: initial !important;
    }
}

.log-box:last-child {
    border-bottom: none;
}

@media (min-width: 992px) {
    .log-box {
        border-bottom: 1px solid #eee;
        padding: 10px 0;

        > div {
            display: inline-block;
            width: 50%;
            vertical-align: top;
        }
    }
}

::v-deep .timeline.timeline-simple {
    margin-top: 0;
}

::v-deep .md-card-timeline {
    margin-top: 0;
    margin-bottom: 0;
}

::v-deep .timeline > li > .timeline-panel {
    padding: 5px 20px;
    background-color: #fafafa;
}

::v-deep .timeline-panel > h6 > h6 {
    text-transform: none;
    margin-top: 0;
}

::v-deep .timeline-panel > h6 {
    margin-top: 0px;
}

::v-deep .timeline-heading {
    margin-bottom: 5px;
}

::v-deep .timeline > li {
    margin-bottom: 5px;
}

::v-deep .timeline > li > .timeline-panel:after,
::v-deep .timeline > li > .timeline-panel:before {
    border-left-color: #fafafa;
    border-right-color: #fafafa;
}

::v-deep .timeline.timeline-simple > li > .timeline-panel {
    width: 83%;
}

::v-deep .timeline-heading .time {
    margin-left: 10px;
    color: #333333;
    font-weight: 400;
    font-size: 0.75rem;
}

.heading-time {
    text-align: right;
    color: #aaa;
}
.title-badge {
    font-weight: 500;
}

.action-buttons {
    ::v-deep .inline-button {
        margin-right: 5px;
    }

    ::v-deep .inline-button:last-child {
        margin-right: 0;
    }
}

// @media (min-width: 1440px) {
//     .log-box {
//         border-bottom: 1px solid #eee;
//         padding: 10px 0;

//         > div {
//             display: inline-block;
//             // margin-left: 20px;
//             width: 50%;
//             vertical-align: top;
//         }
//     }
// }

// @media (min-width: 1600px) {
//     .log-box {
//         border-bottom: 1px solid #eee;
//         padding: 10px 0;

//         > div {
//             display: inline-block;
//             // margin-left: 20px;
//             width: 25%;
//             vertical-align: top;
//         }
//     }
// }
</style>
