var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white-bg" }, [
    _c("div", { staticClass: "mobile-filter-header" }, [
      _c("div", { staticClass: "md-layout" }, [
        !_vm.isSingleUser && !_vm.isSingleTeamMember
          ? _c(
              "div",
              {
                class: _vm.selectedTeamMember.publicUserId
                  ? "md-layout-item md-xsmall-size-50"
                  : "md-layout-item md-xsmall-size-100",
              },
              [
                _c("div", { staticClass: "heavy-font-600 filter-btn" }, [
                  _c(
                    "span",
                    {
                      staticClass: "filter-teammember",
                      on: { click: _vm.filterByTeamMember },
                    },
                    [_vm._v(_vm._s(_vm.selectedTeamMember.name))]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm.selectedTeamMember.publicUserId != null
          ? _c("div", { staticClass: "md-layout-item md-xsmall-size-50" }, [
              _c(
                "div",
                { staticClass: "datepicker-container" },
                [
                  _c("md-datepicker", {
                    ref: "mdDatePicker",
                    attrs: {
                      "md-immediately": "",
                      "md-debounce": 100,
                      "md-model-type": String,
                    },
                    model: {
                      value: _vm.selectedDate,
                      callback: function ($$v) {
                        _vm.selectedDate = $$v
                      },
                      expression: "selectedDate",
                    },
                  }),
                  _c("span", { staticClass: "heavy-font-600" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("dateFormat")(
                          _vm.selectedDate,
                          _vm.DATE_TYPES.standardDate
                        )
                      )
                    ),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "md-layout" }, [
      _c("div", { staticClass: "md-layout-item stoplist-container" }, [
        !_vm.loading
          ? _c("div", [
              _vm.searchList.length
                ? _c(
                    "div",
                    _vm._l(_vm.searchList, function (item) {
                      return _c(
                        "div",
                        { key: item.shipmentId, staticClass: "shipment-item" },
                        [
                          _c("div", { staticClass: "pickup-drop-container" }, [
                            _c("div", { staticClass: "reference-container" }, [
                              _c("div", [
                                _c("h5", { staticClass: "type-title" }, [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        item.contact.name
                                          ? item.contact.name
                                          : item.shipmentRef
                                      ) +
                                      "\n                                    "
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                [
                                  !_vm.isSingleUser && !_vm.isSingleTeamMember
                                    ? _c("reassign-shipment-button", {
                                        attrs: {
                                          type: "button",
                                          item: item,
                                          "team-members": _vm.teamMembers,
                                        },
                                        on: {
                                          assignedUser: _vm.handleFilteredValue,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "pickup-container custom-ellipsis",
                              },
                              [
                                _c("h5", { staticClass: "type-title" }, [
                                  _vm._v(
                                    "\n                                    Pickup\n                                    "
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.showDate(item, item.pickupStop)
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]),
                                ]),
                                _vm._v(
                                  "\n\n                                " +
                                    _vm._s(item.pickupStop.address) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "drop-container custom-ellipsis" },
                              [
                                _c("h5", { staticClass: "type-title" }, [
                                  _vm._v(
                                    "\n                                    Drop\n                                    "
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.showDate(item, item.dropStop)
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.dropStop.address) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            (_vm.selectedTeamMember.publicUserId === null ||
                              _vm.selectedTeamMember.publicUserId === "All") &&
                            !_vm.$_isEmptyOrSpaces(item.assignedTo.fullName)
                              ? _c("div", [
                                  _c("h5", { staticClass: "type-title" }, [
                                    _vm._v("Driver"),
                                  ]),
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(_vm._s(item.assignedTo.fullName)),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "md-layout-item" }, [
                    _c(
                      "span",
                      { staticClass: "text-center no-result-message" },
                      [_vm._v("No available shipments to display.")]
                    ),
                  ]),
            ])
          : _c("div", [
              !_vm.isLoading
                ? _c(
                    "div",
                    { staticClass: "stopbar-loader" },
                    [
                      _c("fade-loader", {
                        attrs: { loading: true, color: "#333333" },
                      }),
                      _c("span", [_vm._v("LOADING")]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }