var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isFetchingUserDetails
    ? _c(
        "div",
        { staticClass: "loader-content-container" },
        [_c("LoginRedirect")],
        1
      )
    : _c(
        "div",
        {
          staticClass: "wrapper",
          class: [{ "nav-open": _vm.$sidebar.showSidebar }],
        },
        [
          _c("notifications"),
          _c(
            "side-bar",
            {
              attrs: {
                "active-color": _vm.sidebarBackground,
                "background-image": _vm.sidebarBackgroundImage,
                "background-color": _vm.sidebarBackgroundColor,
                logo:
                  _vm.isCustomer && _vm.getTeamGeneralSettings.companyLogo
                    ? _vm.getTeamGeneralSettings.companyLogo
                    : null,
                "is-customer-type": _vm.isCustomer,
              },
            },
            [
              _c("user-menu"),
              _c(
                "template",
                { slot: "links" },
                [
                  _vm._l(_vm.menuData, function (menuItem, menuIndex) {
                    return _c("div", { key: menuIndex }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "feature",
                              rawName: "v-feature",
                              value: _vm.getRouteFeatureRestrictions(menuItem),
                              expression:
                                "getRouteFeatureRestrictions(menuItem)",
                            },
                          ],
                        },
                        [
                          _vm.canShowItem(menuItem)
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "sidebar-item",
                                    {
                                      staticClass: "sidebar-spacing",
                                      attrs: { link: menuItem },
                                    },
                                    [
                                      _vm._l(
                                        menuItem.children,
                                        function (childItem, index) {
                                          return [
                                            _vm.canShowItem(childItem)
                                              ? _c("sidebar-item", {
                                                  directives: [
                                                    {
                                                      name: "feature",
                                                      rawName: "v-feature",
                                                      value:
                                                        _vm.getRouteFeatureRestrictions(
                                                          childItem
                                                        ),
                                                      expression:
                                                        "getRouteFeatureRestrictions(childItem)",
                                                    },
                                                  ],
                                                  key: index,
                                                  staticClass:
                                                    "sidebar-spacing-reset",
                                                  attrs: {
                                                    badge: childItem.badge,
                                                    link: childItem,
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ])
                  }),
                  !_vm.isReadOnlyUser ? _c("support-menu") : _vm._e(),
                ],
                2
              ),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "main-panel custom-lp",
              class: [
                _vm.$root.isMobileOnly ? "main-panel-whitebg" : "",
                _vm.isSupportUser ? "with-header" : "no-header",
                _vm.isReadOnlyUser || !_vm.$route.meta.hideFooter
                  ? "show-footer"
                  : "no-footer",
              ],
            },
            [
              _c("support-user-nav-bar"),
              !_vm.isFetchingUserDetails && _vm.showTrialBanner
                ? _c(
                    "div",
                    { staticClass: "trial-alert-container" },
                    [_c("TrialBanner")],
                    1
                  )
                : _vm._e(),
              _c("top-navbar", {
                staticClass: "mobile-navigation",
                class: _vm.$root.isMobileOnly ? "mobileonly-navigation" : "",
              }),
              _c(
                "div",
                {
                  class: {
                    content: !_vm.$route.meta.hideContent,
                    "map-overview": _vm.$route.meta.isMap,
                    "optimise-stops-overview": _vm.$route.meta.isOptimise,
                  },
                  on: { click: _vm.toggleSidebar },
                },
                [
                  _c(
                    "zoom-center-transition",
                    { attrs: { duration: 200, mode: "out-in" } },
                    [
                      _vm.getTeamGeneralSettings.isTwoFAEnabled &&
                      !_vm.user.hasTwoFAEnabled
                        ? _c("div", { staticClass: "layout-parent" }, [
                            _c("div", { staticClass: "two-fa-container" }, [
                              _c("h5", { staticClass: "two-fa-title" }, [
                                _vm._v("Two Factor Authentication Setup"),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "\n                            Your team has enabled two factor authentication. In order to use the app properly you\n                            must logout and sign in again to setup two factor authentication on your account.\n                        "
                                ),
                              ]),
                              _c("div", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "logout-link",
                                    attrs: { href: "#" },
                                    on: { click: _vm.logout },
                                  },
                                  [_vm._v("Click here to logout")]
                                ),
                              ]),
                            ]),
                          ])
                        : _c(
                            "div",
                            { staticClass: "layout-parent" },
                            [
                              _c("router-view", { key: _vm.$router.path }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isLoading,
                                      expression: "isLoading",
                                    },
                                  ],
                                  staticClass: "loading",
                                },
                                [
                                  _c("fade-loader", {
                                    attrs: {
                                      loading: _vm.isLoading,
                                      color: "#333333",
                                    },
                                  }),
                                  _c("span", [_vm._v(_vm._s(_vm.loadingText))]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                ],
                1
              ),
              _vm.isReadOnlyUser || !_vm.$route.meta.hideFooter
                ? _c("content-footer")
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }