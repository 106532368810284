import { render, staticRenderFns } from "./FixAddressView.vue?vue&type=template&id=15b83f74&scoped=true&"
import script from "./FixAddressView.vue?vue&type=script&lang=js&"
export * from "./FixAddressView.vue?vue&type=script&lang=js&"
import style0 from "./FixAddressView.vue?vue&type=style&index=0&id=15b83f74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b83f74",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\a\\1\\s\\Locate2u.AppPortal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15b83f74')) {
      api.createRecord('15b83f74', component.options)
    } else {
      api.reload('15b83f74', component.options)
    }
    module.hot.accept("./FixAddressView.vue?vue&type=template&id=15b83f74&scoped=true&", function () {
      api.rerender('15b83f74', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Stops/FixAddressView.vue"
export default component.exports