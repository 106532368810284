<template>
    <div class="detail-container">
        <div class="close-button">
            <md-button class="md-default md-just-icon md-round pull-right" @click="closeModal">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <div v-if="!loading">
            <div class="detail-section text-center">
                <span class="title">{{ warehouseDetails.name }}</span>
            </div>
            <div class="detail-container-list">
                <div class="line-separator"></div>
                <WarehouseSidebarDetails :warehouse-details="warehouseDetails" />
                <div class="line-separator"></div>
                <WarehouseSidebarLocations
                    :warehouse-id="warehouseId"
                    @location-created="fetchAllInfo"
                    @location-updated="fetchAllInfo"
                    @location-deleted="fetchAllInfo"
                />
            </div>
        </div>
        <div v-else>
            <div class="sidebar-loader">
                <fade-loader :loading="loading" color="#333333" />
                <span>LOADING</span>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { GeneralMixin } from '@/mixins';
import ItemLocationList from '@/components/ItemLocations/ItemLocationList';
import WarehouseSidebarDetails from './components/WarehouseSidebarDetails';

export default {
    name: 'WarehouseSidebar',
    mixins: [GeneralMixin],
    components: {
        FadeLoader,
        WarehouseSidebarDetails,
        WarehouseSidebarLocations: ItemLocationList
    },
    props: {
        warehouseId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            warehouseDetails: {},
            loading: false
        };
    },

    watch: {
        async warehouseId(newValue, oldValue) {
            await this.getWarehouseDetails(newValue);
        }
    },
    async mounted() {
        await this.fetchAllInfo();
    },
    methods: {
        async fetchAllInfo() {
            this.loading = true;
            await this.getWarehouseDetails(this.warehouseId);
            this.loading = false;
        },
        async getWarehouseDetails(warehouseId) {
            try {
                const api = `/api/warehouses/${warehouseId}`;
                const { data } = await handleRequests(api);
                Object.assign(this.warehouseDetails, data);
            } catch (error) {
                const message = 'Error in getting the warehouse details';
                showErrorMessage(this, message, error);
            }
        },
        closeModal() {
            this.$emit('closeModal');
        }
    }
};
</script>

<style lang="scss" scoped>
.detail-container {
    position: fixed;
    /* to achieve consistency, set padding to container and not to each individual elements */
    padding: 12px 16px;
    top: 2vh;
    right: 10px;
    height: 96vh;
    width: 27.5%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    background: white;
    z-index: 999;
    border-radius: 16px;
    overflow-y: scroll;

    /* all items should flow to column */

    .close-button {
        top: 0;
        right: 0;
        position: absolute;
        /* add z-index for old shipments that have no shipment reference numbers */
        z-index: 999;
    }
    .md-button.md-just-icon,
    ::v-deep .md-button.md-just-icon {
        font-size: 15px;
        height: 30px;
        min-width: 3px;
        width: 30px;
        line-height: 30px;
        margin: 2.5px;
    }
}

.detail-container-list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 10px;
    row-gap: 10px;
}

.line-separator {
    height: 1px;
    background-color: #ddd;
}
.detail-section {
    .blue-ref a {
        color: #2b93ff !important;
    }

    .title {
        text-transform: uppercase;
        font-weight: 600;
        display: block;
        font-size: 14px;
    }
    ::v-deep .title {
        text-transform: uppercase;
        font-weight: 600;
        display: block;
        font-size: 14px;
    }

    ::v-deep .custom-a-blue a {
        color: #2b93ff !important;
    }
}

.sidebar-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

.detail-section .address {
    cursor: pointer;
}
</style>
