<template>
    <div class="content">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.businessEntities') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon card-icon-text">
                            <md-icon>account_balance</md-icon>
                            Business Entities
                        </div>
                    </md-card-header>
                    <md-card-content>
                        <div class="cf-container">
                            <div class="cf-action-container">
                                <md-button
                                    title="Add Business Entity"
                                    class="md-primary md-just-icon md-round"
                                    @click.stop="handleAdd()"
                                >
                                    <md-icon>add</md-icon>
                                </md-button>
                            </div>
                            <div v-if="businessEntitiesList.length != 0">
                                <business-entities-list
                                    :list-of-business-entities="businessEntitiesList"
                                ></business-entities-list>
                            </div>
                            <div v-if="businessEntitiesList.length == 0">
                                <p class="no-result-message">No business entities configured</p>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import AddBusinessEntityModal from './AddBusinessEntityModal';
import BusinessEntitiesList from './components/BusinessEntitiesList';

export default {
    name: 'BusinessEntitiesOverview',
    components: { BusinessEntitiesList },
    mixins: [GeneralMixin],
    data() {
        return {
            businessEntitiesList: []
        };
    },
    async mounted() {
        this.$_handleLoaderState(true);
        await this.retrieveBusinessEntitiesList();
        this.$_handleLoaderState(false);
    },
    methods: {
        async fetchBusinessEntitiesList() {
            const endpoint = `/api/business-entities/list`;
            const response = await handleRequests(endpoint);
            return response;
        },
        async retrieveBusinessEntitiesList() {
            const {
                data: { businessEntities }
            } = await this.fetchBusinessEntitiesList();
            this.assignBusinessEntities(businessEntities);
        },
        assignBusinessEntities(businessEntities) {
            this.businessEntitiesList = businessEntities.sort((a, b) =>
                a.businessRegistrationName.localeCompare(b.businessRegistrationName)
            );
        },
        handleAdd() {
            this.$modal.show(AddBusinessEntityModal).then(async (response) => {
                if (response) {
                    this.retrieveBusinessEntitiesList();
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.break-btn-icon {
    margin-left: 3px;
    margin-right: 3px;
}

.body-list {
    padding-top: 0px;
    position: relative;
}

.custom-toolbar {
    margin-top: 0px;
}

.content {
    margin-top: -15px;

    ::v-deep .md-card.md-theme-default {
        margin-bottom: 0;
    }
}

.card-icon-text {
    color: white;
}
</style>
