import { ref } from '@vue/composition-api';
import useGenericStatus from '@/compostables/useGenericStatus';
import { handleRequests } from '@/helpers';

export default function useRateList() {
    /**
     *
     * @type {Ref<UnwrapRef<{ description: string, amount: number | null, amountText: string | null }[]>>}
     */
    const rateListItems = ref([]);
    const currency = ref(null);

    const fetchTripRateRulesStatus = useGenericStatus();

    /**
     * @typedef {Object} TripDetails
     * @property {string} publicUserId
     * @property {number} customerId
     * @property {number} rateGroupId
     * @property {string} tripDate
     * @property {string} startTime
     * @property {string} endTime
     * @property {Object} startLocation
     * @property {string} startAddress
     * @property {Object} endLocation
     * @property {string} endAddress
     * @property {string} chargingTag
     */

    /**
     *
     * @param {TripDetails} tripDetails
     * @param {() => void} onSuccess
     * @param {(e: Error) => void} onError
     * @returns {Promise<void>}
     */
    async function fetchRateRules(tripDetails, onSuccess, onError) {
        fetchTripRateRulesStatus.setPending();
        const data = {
            publicUserId: tripDetails.publicUserId || '',
            customerId: tripDetails.customerId,
            rateGroupId: tripDetails.rateGroupId,
            tripDate: tripDetails.tripDate,
            startTime: tripDetails.startTime || '',
            endTime: tripDetails.endTime || '',
            startLocation: tripDetails.startLocation,
            startAddress: tripDetails.startAddress || '',
            endLocation: tripDetails.endLocation,
            endAddress: tripDetails.endAddress || '',
            chargingTag: tripDetails.chargingTag
        };

        const api = '/api/rates/generate-trip-rates';
        const payload = {
            method: 'post',
            data
        };

        try {
            const res = await handleRequests(api, payload);
            if (res.data) {
                // rateList.value = res.data;
                const { rateList } = res.data;
                rateListItems.value = rateList;
                currency.value = res.data.currency;
            }
            fetchTripRateRulesStatus.setSuccess();
            onSuccess();
        } catch (e) {
            console.error(e);
            fetchTripRateRulesStatus.setError();
            onError(e);
        }
    }

    return {
        rateListItems,
        currency,
        fetchRateRules,
        fetchTripRateRulesStatus
    };
}
