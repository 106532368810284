<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.skills') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>psychology</md-icon>
                    </div>
                </md-card-header>

                <md-card-content>
                    <div class="cf-container">
                        <div class="cf-action-container">
                            <md-button
                                title="Add skill"
                                class="md-primary md-just-icon md-round"
                                @click.stop="handleAddSkill()"
                            >
                                <md-icon>add</md-icon>
                            </md-button>
                            <md-button
                                title="Import skill"
                                class="md-primary md-just-icon md-round"
                                @click.stop="handleImportSkills()"
                            >
                                <md-icon>import_export</md-icon>
                            </md-button>
                        </div>
                        <md-table class="custom-paginated-table">
                            <md-table-row>
                                <md-table-head>Name</md-table-head>
                                <md-table-head>Actions</md-table-head>
                            </md-table-row>

                            <md-table-row v-for="(skill, index) in skillList" :key="index">
                                <md-table-cell>
                                    {{ skill.name }}
                                </md-table-cell>
                                <md-table-cell class="action-buttons">
                                    <md-button
                                        title="delete skill"
                                        class="md-danger md-just-icon md-round"
                                        @click.stop="deleteSkill(skill.id)"
                                    >
                                        <md-icon>delete</md-icon>
                                    </md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <div class="text-center" v-if="skillList.length == 0">
                            <p class="no-result-message">No results matching your search/filter could be found.</p>
                        </div>
                        <skill-modal v-if="shouldShowAddSkillModal" :team-id="teamId" @emitToggleModal="toggleModal" />
                        <skill-import-modal
                            v-if="shouldShowImportSkillsModal"
                            :team-id="teamId"
                            @emitToggleModal="toggleModal"
                        />
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import SkillModal from './SkillModal';
import SkillImportModal from './SkillImportModal';

export default {
    name: 'SkillList',
    mixins: [GeneralMixin],
    components: {
        SkillModal,
        SkillImportModal
    },
    props: {
        teamId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            skillList: [],
            shouldShowAddSkillModal: false,
            shouldShowImportSkillsModal: false
        };
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam'
        })
    },
    async mounted() {
        await this.getSkillList();
    },
    methods: {
        handleAddSkill() {
            this.$modal.show(SkillModal, this.teamId).then((response) => {
                this.getSkillList();
            });
        },
        handleImportSkills() {
            this.$modal.show(SkillImportModal, this.teamId).then((response) => {
                this.getSkillList();
            });
        },
        toggleModal(shouldToggle, modalToToggle) {
            this[modalToToggle] = shouldToggle;
        },
        deleteSkill(skillId) {
            this.$messageBox
                .show({
                    title: 'Delete Skill',
                    body: 'Are you sure you want to delete this skill?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmDelete(skillId);
                });
        },
        async getSkillList() {
            this.$_handleLoaderState(true);
            const api = '/api/teams/skills';
            const response = await handleRequests(api);
            if (response.data !== null) {
                this.skillList = response.data.data;
            }
            await this.$store.dispatch('user/FETCH_USER');
            this.$_handleLoaderState(false);
        },
        async handleConfirmDelete(skillId) {
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'delete'
            };
            const api = `/api/teams/skills/${skillId}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Skill was deleted!',
                    type: 'success'
                });
                this.getSkillList();
            } catch (e) {
                const message = 'Cannot delete skill.';
                showErrorMessage(this, message, e);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.actions {
    width: 6%;
}

.asset-type-filter {
    width: 10%;
    float: right;
}

.custom-paginated-table .action-buttons {
    width: 100px;
}
.card-icon {
    background: #3b5998 !important;
}
</style>
