<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ isUpdate ? 'Update' : 'Create' }} Team Region</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.teamRegionModel" class="form-wrapper">
                <div class="form-container customer-form-container">
                    <div class="contact-details">
                        <form-group name="name" label="Name">
                            <md-input v-focus v-model="teamRegionModel.name" />
                        </form-group>
                        <form-group name="parentTeamRegionId" label="Parent Team Region">
                            <md-select v-model="teamRegionModel.parentTeamRegionId">
                                <md-option
                                    v-for="(option, index) in parentTeamRegionOptions"
                                    :key="`option-${index}`"
                                    :value="option.teamRegionId"
                                    :class="`option-level-${option.level}`"
                                >
                                    {{ option.name }}
                                </md-option>
                            </md-select>
                        </form-group>
                        <form-group name="notes" label="Notes">
                            <md-textarea v-model="teamRegionModel.notes" />
                        </form-group>
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click.prevent="validate">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage, isEmptyOrSpaces } from '@/helpers';
import { required, maxLength } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'CreateTeamRegionModal',
    mixins: [GeneralMixin],
    props: {
        teamRegionModel: {
            type: Object,
            default: () => {}
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            defaults: [
                {
                    name: 'No Parent',
                    teamRegionId: 0,
                    parentTeamRegionId: 0
                }
            ]
        };
    },
    methods: {
        ...mapActions('team', ['FETCH_TEAM_REGIONS']),
        async create() {
            this.$_handleLoaderState(true, 'PROCESSING...');
            const api = `/api/teams/team-regions`;
            const payload = {
                method: 'post',
                data: this.teamRegionModel
            };

            try {
                await handleRequests(api, payload);
                this.refreshState();
                this.$notify({
                    message: 'Successfully created team region.',
                    type: 'success'
                });

                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Error in creating the team region.';
                showErrorMessage(this, message, error);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async update() {
            this.$_handleLoaderState(true, 'UPDATING...');
            const api = `/api/teams/team-regions/${this.teamRegionModel.teamRegionId}`;
            const payload = {
                method: 'put',
                data: this.teamRegionModel
            };

            try {
                await handleRequests(api, payload);
                this.refreshState();
                this.$notify({
                    message: 'Successfully updated team region.',
                    type: 'success'
                });

                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Error in updating the customer profile';
                showErrorMessage(this, message, error);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        validate() {
            if (isEmptyOrSpaces(this.teamRegionModel.name)) {
                this.$notify({
                    message: 'Please enter name to submit the form.',
                    type: 'danger'
                });
                return;
            }

            if (this.isParentTeamRegion()) {
                const teamRegion = this.teamRegions.find(
                    (x) => x.teamRegionId === this.teamRegionModel.parentTeamRegionId
                );
                this.$notify({
                    message: `You cannot add team region's parent as its child. Because ${
                        teamRegion.name
                    } is already a child of ${this.teamRegionModel.name}`,
                    type: 'danger'
                });
                return;
            }
            this.$v.teamRegionModel.$touch();
            if (!this.$v.teamRegionModel.$invalid) {
                if (!isEmptyOrSpaces(this.teamRegionModel.name)) {
                    this.teamRegionModel.name = this.teamRegionModel.name.trim();

                    if (this.isUpdate) {
                        this.update();
                    } else {
                        this.create();
                    }
                }
            }
        },
        async refreshState() {
            this.FETCH_TEAM_REGIONS();
        },
        isParentTeamRegion() {
            const parentTeamRegionId = this.teamRegions.find(
                (x) => x.teamRegionId === this.teamRegionModel.parentTeamRegionId
            )?.parentTeamRegionId;
            const teamRegion = this.teamRegions.find(
                (x) => x.teamRegionId === parentTeamRegionId && x.name === this.teamRegionModel.name
            );

            return teamRegion ?? null;
        },
        removeTeamRegionParentAndDecendants(teamRegions, parentTeamRegionId) {
            const filtered = teamRegions.filter(
                (x) =>
                    x.teamRegionId !== this.teamRegionModel.teamRegionId && x.parentTeamRegionId !== parentTeamRegionId
            );

            const children = teamRegions.filter((x) => x.parentTeamRegionId === parentTeamRegionId);

            let result = filtered;

            children.forEach((x) => {
                result = this.removeTeamRegionParentAndDecendants(result, x.teamRegionId);
            });

            return result;
        }
    },
    validations: {
        teamRegionModel: {
            name: { required, maxLength: maxLength(100) }
        }
    },
    computed: {
        ...mapGetters({
            teamRegions: 'team/teamRegions'
        }),
        parentTeamRegionOptions() {
            if (this.teamRegions) {
                const teamRegions = this.removeTeamRegionParentAndDecendants(
                    this.teamRegions.filter((x) => x.level !== 3),
                    this.teamRegionModel.teamRegionId
                );
                return [...this.defaults, ...teamRegions];
            }

            return [];
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-container {
    max-width: 600px;
}

.note {
    font-size: 13px;
    margin-top: 10px;
    display: inline-block;
}
.option-level-1 {
    ::v-deep .md-list-item-text {
        font-weight: 500;
    }
}
.option-level-2 {
    margin-left: 30px !important;
}
</style>
