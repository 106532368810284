var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "md-layout text-center" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-30 md-small-size-50 md-xsmall-size-100 md-size-25",
        },
        [
          _c(
            "div",
            { staticClass: "pricing-plan-container" },
            [
              _c(
                "form-group",
                [
                  _c(
                    "md-select",
                    {
                      on: { "md-selected": _vm.selectStarterPlan },
                      model: {
                        value: _vm.starterPlan,
                        callback: function ($$v) {
                          _vm.starterPlan = $$v
                        },
                        expression: "starterPlan",
                      },
                    },
                    [
                      _c("md-option", { attrs: { value: "core-gps" } }, [
                        _vm._v("Core - GPS"),
                      ]),
                      _c(
                        "md-option",
                        { attrs: { value: "professional-gps" } },
                        [_vm._v("Professional - GPS")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "content-price" }, [
                _c("div", { staticClass: "price" }, [
                  _vm._v(_vm._s(_vm.currency) + _vm._s(_vm.starterPrice)),
                ]),
                _c("p", [_vm._v("monthly per user")]),
                _c("p", [
                  _vm._v(
                    "\n                        Ready to know where your team and assets live throughout the day? Start using Locate2u\n                        today.\n                    "
                  ),
                ]),
              ]),
              _vm.enableLiveMode
                ? _c(
                    "a",
                    { attrs: { href: _vm.starterUrl, target: "_blank" } },
                    [_vm._v("Choose plan")]
                  )
                : _vm._e(),
              _vm._m(1),
              _vm._m(2),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-30 md-small-size-50 md-xsmall-size-100 md-size-25",
        },
        [
          _c(
            "div",
            { staticClass: "pricing-plan-container" },
            [
              _c(
                "form-group",
                [
                  _c(
                    "md-select",
                    {
                      on: { "md-selected": _vm.selectPremiumPlan },
                      model: {
                        value: _vm.premiumPlan,
                        callback: function ($$v) {
                          _vm.premiumPlan = $$v
                        },
                        expression: "premiumPlan",
                      },
                    },
                    [
                      _c("md-option", { attrs: { value: "premium3" } }, [
                        _vm._v("Premium 3"),
                      ]),
                      _c("md-option", { attrs: { value: "premium10" } }, [
                        _vm._v("Premium 10"),
                      ]),
                      _c("md-option", { attrs: { value: "premium30" } }, [
                        _vm._v("Premium 30"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "content-price" }, [
                _c("div", { staticClass: "price" }, [
                  _vm._v(_vm._s(_vm.currency) + _vm._s(_vm.premiumPrice)),
                ]),
                _c("p", [_vm._v("monthly per user")]),
                _c("p", [
                  _vm._v(
                    "\n                        Looking for a complete solution for your service or delivery business? Provide your\n                        customers with the ultimate customer experience and help your business grow at speed.\n                    "
                  ),
                ]),
              ]),
              _vm.enableLiveMode
                ? _c(
                    "a",
                    { attrs: { href: _vm.premiumUrl, target: "_blank" } },
                    [_vm._v("Choose plan")]
                  )
                : _vm._e(),
              _vm._m(3),
              _vm._m(4),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-30 md-small-size-50 md-xsmall-size-100 md-size-25",
        },
        [
          _c("div", { staticClass: "pricing-plan-container" }, [
            _c("div", { staticClass: "enterprise-title" }, [
              _vm._v("Enterprise"),
            ]),
            _vm._m(5),
            _vm.enableLiveMode
              ? _c(
                  "a",
                  { attrs: { href: _vm.enterpriseUrl, target: "_blank" } },
                  [_vm._v("Inquire now")]
                )
              : _vm._e(),
            _vm._m(6),
            _vm._m(7),
          ]),
        ]
      ),
    ]),
    _vm._m(8),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "md-layout" }, [
      _c("div", { staticClass: "md-layout-item mx-auto text-center" }, [
        _c("h2", { staticClass: "title" }, [
          _vm._v("\n                Locate2u Pricing Benefits\n            "),
        ]),
        _c("h5", { staticClass: "description" }, [
          _vm._v(
            "\n                Our pricing plans are designed for small or large delivery or service businesses.\n            "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ideal-container" }, [
      _c("h5", [_vm._v("Ideal for customers who:")]),
      _c("p", [_vm._v("Have up to 5 vehicles or assets to track")]),
      _c("p", [
        _vm._v(
          "Need to see live location and don't require a long history of data"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "key-benefits" }, [
      _c("h5", [_vm._v("Key benefits")]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "Use your mobile or our GPS device to track the live location of your team"
          ),
        ]),
        _c("li", [_vm._v("View a route replay up to 3 days")]),
        _c("li", [_vm._v("Track your live location via mobile and web app")]),
        _c("li", [
          _vm._v(
            "\n                            Suitable for most types of vehicles and can be used for tracking trailers, equipment and\n                            bins\n                        "
          ),
        ]),
        _c("li", [_vm._v("Email and chat based support. 24 hrs 5 day week")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ideal-container" }, [
      _c("h5", [_vm._v("Ideal for customers who:")]),
      _c("p", [
        _vm._v(
          "\n                        You are either a delivery, transport or service business looking to provide a better\n                        experience to your customers.\n                    "
        ),
      ]),
      _c("p", [
        _vm._v(
          "Require a system to track your team and share that information with your customers"
        ),
      ]),
      _c("p", [_vm._v("Require a system to manage your bookings.")]),
      _c("p", [_vm._v("Wanting to optimize your route")]),
      _c("p", [_vm._v("Need up to 3 users")]),
      _c("p", [_vm._v("Manage up to 100 bookings in a day")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "key-benefits" }, [
      _c("h5", [_vm._v("Key benefits")]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "Booking Management - Includes proof of delivery, custom fields"
          ),
        ]),
        _c("li", [_vm._v("Driver App")]),
        _c("li", [_vm._v("Access to portal via Mobile or Desktop")]),
        _c("li", [_vm._v("Schedule view of your bookings")]),
        _c("li", [
          _vm._v(
            "Optimize your route in real time, save your drivers time and money"
          ),
        ]),
        _c("li", [_vm._v("Share your live location with customers")]),
        _c("li", [_vm._v("GPS tracking via mobile or hardware device")]),
        _c("li", [_vm._v("View route replay up to 120 days in history")]),
        _c("li", [_vm._v("Automatic SMS")]),
        _c("li", [_vm._v("Up to 3 users")]),
        _c("li", [
          _vm._v("Phone, Email and Chat based support, 24 hrs 5 day a week"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-price" }, [
      _c("p", [
        _vm._v(
          "\n                        For enterprise clients who have over 50 users and are looking to use our API, speak to us so\n                        we can assist in developing a solution specifically for your business.\n                    "
        ),
      ]),
      _c("p", { staticStyle: {} }, [
        _vm._v(
          "\n                        We work with many businesses across the world who are looking to improve their profitability\n                        and increase their customer experience\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ideal-container" }, [
      _c("h5", [_vm._v("Ideal for customers who:")]),
      _c("p", [
        _vm._v("Enterprise customers who belong to the following industries;"),
      ]),
      _c("h5", [_vm._v("Logistics Businesses")]),
      _c("p", [
        _vm._v(
          "Same Day Couriers, Line Haul Trucking, Tow Trucks, Retail & Food delivery."
        ),
      ]),
      _c("h5", [_vm._v("Service Businesses:")]),
      _c("p", [
        _vm._v(
          "\n                        Cleaning, Security, Plumber, Electrical, Installation, Repair, IT Service, Garden\n                        Maintenance Rubbish Removal, Removalists and the list goes on and on. Feel free to reach out\n                        to us to see if we can assist you..\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "key-benefits" }, [
      _c("h5", [_vm._v("Key benefits")]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "Use your mobile or our GPS device to track the live location of your team"
          ),
        ]),
        _c("li", [_vm._v("View a route replay up to 3 days")]),
        _c("li", [_vm._v("Track your live location via mobile and web app")]),
        _c("li", [
          _vm._v(
            "\n                            Suitable for most types of vehicles and can be used for tracking trailers, equipment and\n                            bins\n                        "
          ),
        ]),
        _c("li", [
          _vm._v("Phone, Email and Chat based support. 24 hrs 5 day week"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "md-layout" }, [
      _c("div", { staticClass: "md-layout-item mx-auto text-center" }, [
        _c("h5", { staticClass: "description" }, [
          _vm._v(
            "\n                If you have any questions regarding your subscription please contact us at\n                "
          ),
          _c("a", { attrs: { href: "mailto:hello@locate2u.com" } }, [
            _vm._v("hello@locate2u.com"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }