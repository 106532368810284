var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-data-tooltip-container" }, [
    _c("div", [_c("b", [_vm._v(_vm._s(_vm.headerText))])]),
    _c("div", [
      _c(
        "table",
        _vm._l(_vm.tableRows, function (row, i) {
          return _c("tr", { key: i + "row-tooltip" }, [
            _c(
              "td",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "custom-a-blue",
                    attrs: { to: "/inventory/details/" + row.itemId },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(row.barcode) +
                        "\n                    "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("td", [_vm._v(_vm._s(row.description))]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }