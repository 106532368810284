var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-group",
    {
      staticClass: "filter-customer",
      attrs: { name: "customer", label: _vm.labelName },
    },
    [
      _c("md-input", {
        attrs: { type: "hidden" },
        model: {
          value: _vm.selectedCustomerId,
          callback: function ($$v) {
            _vm.selectedCustomerId = $$v
          },
          expression: "selectedCustomerId",
        },
      }),
      _c("vue-select", {
        attrs: {
          reduce: function (customer) {
            return customer.customerId
          },
          label: "labelText",
          options: _vm.customerList,
          searchable: _vm.$root.isDesktop,
          selectable: function (option) {
            return option.selectable
          },
        },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function (option) {
              return [
                option.selectable
                  ? _c("span", [_vm._v(_vm._s(option.labelText))])
                  : _c("hr"),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedCustomerId,
          callback: function ($$v) {
            _vm.selectedCustomerId = $$v
          },
          expression: "selectedCustomerId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }