<script>
import { Timer } from '@/helpers';
import { toRefs, inject, computed, onMounted, onBeforeUnmount, watch } from '@vue/composition-api';
import moment from 'moment';

export default {
    name: 'LocationDataUpdates',
    props: {
        isDateToday: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
        const { isDateToday } = toRefs(props);
        const { getters, dispatch } = inject('vuex-store');
        const activePanel = computed(() => getters['map/activePanel']);
        const teamRegionId = computed(() => getters['map/teamRegionId']);
        const hasElevatedTeamRegionFilter = computed(() => getters['user/hasElevatedTeamRegionFilter']);

        let timer;

        onMounted(() => {
            timer = new Timer(async () => {
                let response = [];
                if (activePanel.value === 'Team Members')
                    response = await dispatch('team/FETCH_TEAM_MEMBERS', {
                        date: moment().format('YYYY-MM-DD'),
                        includeLocationDetails: true,
                        teamRegionId: teamRegionId.value,
                        elevatedTeamRegionFilter: hasElevatedTeamRegionFilter.value
                    });
                else
                    response = await await dispatch('assetGroup/FETCH_ASSET_GROUPS_LOCATION_DETAILS', {
                        teamRegionId: teamRegionId.value,
                        elevatedTeamRegionFilter: hasElevatedTeamRegionFilter.value,
                        date: moment().format('YYYY-MM-DD')
                    });

                const dataToChange = activePanel.value === 'Team Members' ? 'driverDetails' : 'assetGroupDetails';
                let newData = [];
                if (response != null) {
                    newData = response.map((r) => ({
                        routeDetails: r.routeDetails,
                        userId: activePanel.value === 'Team Members' ? r.publicUserId : r.trackerReference
                    }));
                }
                context.emit('locationUpdated', { newData, dataToChange });

                if (!isDateToday.value) 
                    timer.stop();

                return true;
            }, 20000);
        });

        onBeforeUnmount(() => {
            if (timer) {
                timer.stop();
                timer = null;
            }
        });

        watch(isDateToday, (newVal) => {
            if (!newVal) 
                timer.stop();
            else 
                timer.start();
        });

        return {
            timer
        };
    },
    render: () => null
};
</script>
