<template>
    <div class="modal-container">
        <div class="modal-header">
            <h5 class="modal-title">Enter a model name</h5>
        </div>
        <div class="modal-body">
            <md-field>
                <md-input v-model="rouringModelName" />
            </md-field>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="saveRouringModelName">
                Save
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RoutingNameModal',
    props: {
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            rouringModelName: ''
        };
    },
    methods: {
        saveRouringModelName() {
            this.resolve(this.rouringModelName);
        }
    }
};
</script>
<style lang="scss" scoped>
.modal-container {
    max-width: 250px;
}
</style>
