<template>
    <div class="table-data-tooltip-container">
        <div>
            <b>{{ headerText }}</b>
        </div>
        <div>
            <table>
                <tr v-for="(row, i) in tableRows" :key="i + 'row-tooltip'">
                    <td>
                        <router-link :to="`/inventory/details/` + row.itemId" class="custom-a-blue">
                            {{ row.barcode }}
                        </router-link>
                    </td>
                    <td>{{ row.description }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GridItemDataTooltip',
    data() {
        return {
            tableRows: [],
            headerText: ''
        };
    },
    beforeMount() {
        this.headerText = this.params.headerText ?? '';
        this.tableJsonField = this.params.tableJsonField ?? '';

        if (this.tableJsonField !== '') {
            const data = this.params.data[this.tableJsonField];

            if (data) {
                // Expecting data to be a json array of objects with barcode, itemId and description.
                this.tableRows = JSON.parse(data);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.table-data-tooltip-container {
    border-radius: 5px;
    border: #333 1px solid;
    background-color: #eee;
    padding: 5px;
}
</style>
