<template>
    <div v-if="isSingleDate">
        <div class="date">
            <md-datepicker class="picker" v-model="endDate" md-immediately :md-debounce="100">
                <label>Date</label>
            </md-datepicker>
            <span v-if="endDate == null" class="error">Date is required.</span>
        </div>
    </div>
    <div v-else>
        <div class="date">
            <md-datepicker class="picker" v-model="startDate" md-immediately :md-debounce="100">
                <label>Start date</label>
            </md-datepicker>
            <span v-if="startDate == null" class="error">Start date is required.</span>
        </div>
        <div class="date">
            <md-datepicker class="picker" v-model="endDate" md-immediately :md-debounce="100">
                <label>End date</label>
            </md-datepicker>
            <span v-if="endDate == null" class="error">End date is required.</span>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.date {
    margin: auto 8px;
    min-width: 140px;
    max-width: 140px;
    float: left;

    ::v-deep .md-clear {
        display: none;
    }
}

::v-deep .picker > input[type='text'] {
    width: 100%;
}
</style>
<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins';
import _ from 'lodash';

export default {
    name: 'DateRangeFilter',
    mixins: [GeneralMixin],
    props: {
        isSingleDate: Boolean,
        maxPediodInMonths: {
            type: Number,
            default: 2
        }
    },
    data() {
        return {
            startDate: moment()
                .subtract(1, 'days')
                .toDate(),
            endDate: moment().toDate()
        };
    },
    computed: {},
    watch: {
        startDate(newVal, oldVal) {
            if (moment(newVal).valueOf() !== moment(oldVal).valueOf()) {
                this.checkStartDate(newVal);
            }
        },
        endDate(newVal, oldVal) {
            if (moment(newVal).valueOf() !== moment(oldVal).valueOf()) {
                this.checkEndDate(newVal);
            }
        },
        maxPediodInMonths(newVal, oldVal) {
            if (moment(newVal).valueOf() !== moment(oldVal).valueOf()) {
                this.checkEndDate(this.endDate);
            }
        }
    },
    methods: {
        checkStartDate(val) {
            if (!this.isSingleDate && moment(val) > moment(this.endDate)) {
                this.$notify({
                    message: 'Start date cannot be later than the end date.',
                    type: 'danger'
                });

                this.startDate = this.endDate;
                return;
            }

            const monthDifference = moment(new Date(this.endDate)).diff(new Date(val), 'months', true);
            if (monthDifference > this.maxPediodInMonths) {
                this.$notify({
                    message: `You cannot request reports longer than ${this.maxPediodInMonths} months.`,
                    type: 'danger'
                });

                this.endDate = moment(val)
                    .add(this.maxPediodInMonths, 'M')
                    .toDate();
                return;
            }

            this.handleChange();
        },
        checkEndDate(val) {
            if (!this.isSingleDate && moment(val) < moment(this.startDate)) {
                this.$notify({
                    message: 'End date cannot be earlier than the start date.',
                    type: 'danger'
                });

                this.endDate = this.startDate;
                return;
            }

            const monthDifference = moment(new Date(val)).diff(new Date(this.startDate), 'months', true);
            if (monthDifference > this.maxPediodInMonths) {
                this.$notify({
                    message: `You cannot request reports longer than ${this.maxPediodInMonths} months.`,
                    type: 'danger'
                });

                this.startDate = moment(val)
                    .add(-this.maxPediodInMonths, 'M')
                    .toDate();
                return;
            }

            this.handleChange();
        },
        // eslint-disable-next-line func-names
        handleChange: _.debounce(function() {
            this.handleDateChange();
        }, 100),
        handleDateChange() {
            // use nextTick to ensure that Date value has already been changed.
            // this is because the @selected method of md-datepicker is asynchronous.
            this.$nextTick(() => {
                if (this.isSingleDate) 
                    this.$emit('onChanged', this.endDate);
                else 
                    this.$emit('onChanged', { startDate: this.startDate, endDate: this.endDate });
            });
        }
    }
};
</script>
