<template>
    <button title="Show load saved model" @click="handleLoadSavedModal">
        <md-icon>folder</md-icon>
        <md-tooltip class="toggle-tooltip" md-direction="top">Load Saved Model</md-tooltip>
    </button>
</template>

<script>
import LoadSavedModal from '@/pages/BatchStop/Settings/LoadSavedModal';

export default {
    name: 'LoadModalButton',
    props: {
        tripDate: {
            type: String,
            default: ''
        }
    },
    methods: {
        handleLoadSavedModal() {
            this.$modal
                .show(LoadSavedModal, {
                    modelTripDate: this.tripDate
                })
                .then((response) => {
                    this.$emit('handleLoadModel', response);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-options {
    position: absolute;
    bottom: 30px;
    height: auto;
    display: flex;
    left: 5px;

    button {
        background: none padding-box rgb(255, 255, 255);
        display: table-cell;
        border: 0px;
        margin: 0px;
        padding: 0px 10px;
        text-transform: none;
        appearance: none;
        position: relative;
        cursor: pointer;
        user-select: none;
        direction: ltr;
        overflow: hidden;
        text-align: center;
        height: 44px;
        vertical-align: middle;
        color: rgb(86, 86, 86);
        font-family: Roboto, Arial, sans-serif;
        font-size: 18px;
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
        // min-width: 64px;
    }
    button:hover {
        background: none padding-box rgb(235, 235, 235);
        color: rgb(0, 0, 0);
        font-weight: 500;
    }
}
</style>
