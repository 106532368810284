var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "md-card-content",
        [
          _c("md-table", {
            staticClass: "table-hover",
            attrs: { value: _vm.distanceData },
            scopedSlots: _vm._u([
              {
                key: "md-table-row",
                fn: function (ref) {
                  var item = ref.item
                  return _c(
                    "md-table-row",
                    { style: _vm.bgc(item) },
                    [
                      !_vm.publicUserId
                        ? _c(
                            "md-table-cell",
                            { attrs: { "md-label": "Asset Group / User" } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.name) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": _vm.getPeriodLabel } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.periodText) +
                              "\n                    "
                          ),
                          item.periodSubtext
                            ? _c("small", [
                                _c("br"),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.periodSubtext) +
                                    "\n                    "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "md-table-cell",
                        {
                          attrs: {
                            "md-label": "Total Distance",
                            "md-sort": "",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.toDistanceStr(item.travelDistanceKm)) +
                              "\n                "
                          ),
                        ]
                      ),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Travel Time" } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.toTime(item.travelTimeMinutes)) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm.isTrackerData !== true && _vm.getPeriod === "day"
                        ? _c(
                            "md-table-cell",
                            {
                              attrs: {
                                "md-label": "Estimated Distance",
                                "md-sort": "",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.toDistanceStr(
                                      item.estimatedTravelDistanceKm
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isTrackerData !== true && _vm.getPeriod === "day"
                        ? _c(
                            "md-table-cell",
                            { attrs: { "md-label": "Estimated Time" } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.toTime(item.estimatedTravelTimeMinutes)
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.getPeriod === "day" || _vm.publicUserId === null
                        ? _c(
                            "md-table-cell",
                            { attrs: { "md-label": "First Ignition On" } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.displayDateTime(
                                      item.firstIgnitionOn,
                                      item.utcOffset
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.getPeriod === "day" || _vm.publicUserId === null
                        ? _c(
                            "md-table-cell",
                            { attrs: { "md-label": "Last Ignition Off" } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.displayDateTime(
                                      item.lastIgnitionOff,
                                      item.utcOffset
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Working Time" } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.toTime(item.workingTimeMinutes)) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm.$root.isDesktop &&
                      !_vm.isTrackerData &&
                      _vm.getPeriod === "day"
                        ? _c(
                            "md-table-cell",
                            { attrs: { "md-label": "Actions" } },
                            [
                              item.tripID
                                ? [
                                    _c(
                                      "span",
                                      { staticClass: "view-map" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Trip Details",
                                                params: {
                                                  tripId: item.tripID,
                                                },
                                              },
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _c("md-icon", [
                                              _vm._v("description"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "md-tooltip",
                                          { attrs: { "md-direction": "left" } },
                                          [_vm._v("View trip details")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "view-map" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Maps Overview Active User Panel",
                                                params: {
                                                  date: _vm
                                                    .getItemMoment(item)
                                                    .format(
                                                      _vm.DATE_TYPES
                                                        .internationalDate
                                                    ),
                                                  userId: _vm.publicUserId,
                                                },
                                              },
                                              target: "_blank",
                                            },
                                          },
                                          [_c("md-icon", [_vm._v("map")])],
                                          1
                                        ),
                                        _c(
                                          "md-tooltip",
                                          { attrs: { "md-direction": "left" } },
                                          [_vm._v("View stops in a map")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _vm.publicUserId
            ? _c("div", { staticClass: "label-total" }, [
                _c("span", [
                  _vm._v("Total Distance: " + _vm._s(_vm.getTotalDistance)),
                ]),
                _c("span", [
                  _vm._v("Total Duration: " + _vm._s(_vm.getTotalDuration)),
                ]),
                _c("span", [
                  _vm._v(
                    "Total Working Time: " + _vm._s(_vm.getTotalWorkingTime)
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }