var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam
    ? _c("div", { staticClass: "md-layout" }, [
        _vm.hasTeam
          ? _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
              },
              [
                _c("div", { staticClass: "custom-toolbar" }, [
                  _c(
                    "div",
                    { staticClass: "custom-toolbar-start" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Team Settings",
                            },
                          },
                        },
                        [
                          _c("h3", { staticClass: "title" }, [
                            _vm._v("Team Settings"),
                          ]),
                        ]
                      ),
                      _c("h3", { staticClass: "title" }, [
                        _vm._v(
                          "   >    " +
                            _vm._s(_vm.$t("menus.setting.importMappings"))
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c(
              "md-card",
              [
                _c("md-card-content", [
                  _c(
                    "div",
                    [
                      _c(
                        "tabs",
                        {
                          staticClass: "custom-tab-list",
                          attrs: {
                            "tab-name": _vm.tabNames,
                            "color-button": "success",
                          },
                          on: { "switch-panel": _vm.beforeTabChanged },
                        },
                        [
                          _vm._l(_vm.tabNames, function (tabName, index) {
                            return _c(
                              "template",
                              { slot: "tab-pane-" + (index + 1) },
                              [
                                _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c("import-mapping-view", {
                                      attrs: {
                                        "mapping-data": _vm.mappingData,
                                      },
                                      on: {
                                        onUpload: _vm.onUpload,
                                        handleSave: _vm.saveMapping,
                                        handleReset: _vm.handleReset,
                                        handleTemplateChanged:
                                          _vm.handleTemplateChanged,
                                        handleAddNewTemplate:
                                          _vm.handleAddNewTemplate,
                                        handleDeleteTemplate:
                                          _vm.handleDeleteTemplate,
                                        updateViewType: _vm.updateViewType,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }