<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Create Trip</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body run-form-modal">
            <form-wrapper :validator="$v.runDetails" class="form-wrapper">
                <div v-if="!isSingleUser && !isSingleTeamMember">
                    <form-group name="member" label="Team Member" class="team-member">
                        <md-input v-model="assignToPublicUserId" type="hidden" />
                        <vue-select
                            :reduce="(fullName) => fullName.publicUserId"
                            label="fullName"
                            :options="teamMemberOptions"
                            placeholder="Select Team Member"
                            v-model="assignToPublicUserId"
                            :searchable="$root.isDesktop"
                        ></vue-select>
                    </form-group>
                </div>
                <div class="md-layout create-trip-layout">
                    <div class="md-layout-item md-size-50">
                        <md-datepicker ref="mdDatePicker" v-model="tripDate" md-immediately>
                            <label>Trip Date</label>
                        </md-datepicker>
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="onCreateTrip">Create</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import * as moment from 'moment';
import { DATE_TYPES } from '@/utils/constants';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'CreateRunScheduleModal',
    mixins: [GeneralMixin],
    props: {
        members: {
            type: Array,
            default: () => []
        },
        runDetails: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        })
    },
    data() {
        return {
            tripDate: moment().format(DATE_TYPES.internationalDate),
            teamMemberOptions: [],
            assignToPublicUserId: null
        };
    },
    validations: {
        runDetails: {
            assignedUser: {
                required
            }
        }
    },
    created() {
        if (this.members.length > 1) {
            const tempMembers = this.members.map((member) => ({
                publicUserId: member.publicUserId,
                fullName: `${member.firstName || ''} ${member.lastName || ''}`,
                email: member.email
            }));

            const defaultOption = {
                publicUserId: null,
                fullName: 'Unassigned',
                email: null
            };

            this.teamMemberOptions = [defaultOption].concat(tempMembers);
        }

        this.assignToPublicUserId = this.runDetails.assignedTo.publicUserId;
    },
    methods: {
        async onCreateTrip() {
            this.$_handleLoaderState(true);
            const api = `/api/run-schedules/create-trip`;
            const payload = {
                method: 'post',
                data: {
                    runScheduleId: this.runDetails.runScheduleId,
                    assignToPublicUserId: this.assignToPublicUserId,
                    tripDate: this.tripDate
                }
            };
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully created a trip.',
                    type: 'success'
                });
                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Error in creating the trip';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 400px;
}
.team-member {
    ::v-deep label {
        top: -15px !important;
    }
    ::v-deep .vs__selected,
    ::v-deep input.vs__search {
        padding-left: 0px;
        margin-left: -2px;
    }
    ::v-deep .vs__clear {
        display: none;
    }
}
.create-trip-layout {
    ::v-deep label {
        left: 0px !important;
    }
    ::v-deep .md-clear {
        display: none;
    }
    ::v-deep .md-layout-item {
        padding-left: 0px;
    }
}
</style>
