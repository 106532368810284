<template>
    <md-button
        title="Remove from route"
        class="md-danger md-just-icon md-round"
        @click.stop="handleDeleteStop(stopId, isDialog)"
    >
        <md-icon>delete</md-icon>
    </md-button>
</template>

<script>
export default {
    name: 'DeleteStopButton',
    props: {
        stopId: {
            type: [Number, String],
            default: () => null
        },
        stopRef: {
            type: String,
            default: () => null
        },
        vehicleId: {
            type: String,
            default: () => null
        },
        address: {
            type: String,
            default: () => null
        },
        allocateStops: {
            type: Array,
            default: () => []
        },
        isDialog: Boolean
    },
    methods: {
        handleDeleteStop(stopId, isDialog) {
            if (isDialog) {
                this.$emit('stopDeleted', stopId, this.vehicleId);
            } else {
                this.$messageBox
                    .show({
                        class: 'sm-modal-container',
                        title: `Remove From Route - ${this.stopRef}`,
                        body: `${this.address}`,
                        buttons: ['Confirm', 'Cancel']
                    })
                    .then(async (response) => {
                        if (response.toLowerCase() === 'confirm')
                            await this.handleConfirmDelete(stopId, this.vehicleId);
                    });
            }
        },
        async handleConfirmDelete(stopId, vehicleId) {
            this.$emit('stopDeleted', stopId, vehicleId);
        }
    },
    computed: {
        orderedStopList() {
            return this.allocateStops.slice().sort((x, y) => (x.teamMember.fullName > y.teamMember.fullName ? 1 : -1));
        }
    }
};
</script>
