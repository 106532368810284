var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        version: "1.1",
        id: "L4",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 40 70",
        "enable-background": "new 0 0 0 0",
        "xml:space": "preserve",
      },
    },
    [
      _c("circle", { attrs: { stroke: "none", cx: "6", cy: "50", r: "6" } }, [
        _c("animate", {
          attrs: {
            attributeName: "opacity",
            dur: "1s",
            values: "0;1;0",
            repeatCount: "indefinite",
            begin: "0.1",
          },
        }),
      ]),
      _c("circle", { attrs: { stroke: "none", cx: "26", cy: "50", r: "6" } }, [
        _c("animate", {
          attrs: {
            attributeName: "opacity",
            dur: "1s",
            values: "0;1;0",
            repeatCount: "indefinite",
            begin: "0.2",
          },
        }),
      ]),
      _c("circle", { attrs: { stroke: "none", cx: "46", cy: "50", r: "6" } }, [
        _c("animate", {
          attrs: {
            attributeName: "opacity",
            dur: "1s",
            values: "0;1;0",
            repeatCount: "indefinite",
            begin: "0.3",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }