<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Invite members to your team</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.form" class="form-wrapper">
                <form-group name="role" label="Team Role">
                    <md-select v-model="form.role" @md-selected="getSelectedRole">
                        <md-option v-for="memberRole in memberRoles" :key="memberRole.id" :value="memberRole.name">
                            {{ memberRole.name }}
                        </md-option>
                    </md-select>
                </form-group>
                <form-group name="email" label="Email Address">
                    <md-input v-model="form.email" />
                </form-group>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validateEmail">
                Send Invitation
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>
<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, email } from 'vuelidate/lib/validators';

export default {
    name: 'InviteTeamMemberModal',
    mixins: [GeneralMixin],
    props: {
        memberRoles: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    validations: {
        form: {
            email: {
                required,
                email
            },
            role: {
                required
            }
        }
    },
    data() {
        return {
            form: {
                email: null,
                role: 'Team Member'
            }
        };
    },
    methods: {
        getSelectedRole(role) {
            this.form.role = role;
        },
        validateEmail() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.sendInvite();
            }
        },
        sendInvite() {
            this.$_handleLoaderState(true, 'SENDING...');
            const payload = {
                method: 'post',
                data: this.form
            };
            const api = '/api/team-members/invite';
            handleRequests(api, payload).then(
                (response) => {
                    const result = response.data.data;
                    this.$notify({
                        message: 'Invite successfully sent!',
                        type: 'success'
                    });
                    this.form.email = null;
                    this.$v.$reset();
                    this.resolve(result);
                },
                (error) => {
                    this.$_handleLoaderState(false);
                    const message = 'Error in sending an invite.';
                    showErrorMessage(this, message, error);
                }
            );
        }
    }
};
</script>
