var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    _vm._l(_vm.messages, function (message, index) {
      return _c("li", { key: index, staticStyle: { color: "white" } }, [
        _vm._v("\n        " + _vm._s(message.text) + "\n    "),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }