<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ carrierName }} - Api Error</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <p>
                <b>
                    {{ error.message }}
                </b>
            </p>
            <template v-if="parsedErrors.length">
                <md-table :value="parsedErrors" class="table-hover">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Code">
                            {{ item.code }}
                        </md-table-cell>
                        <md-table-cell md-label="Description">
                            {{ item.description }}
                        </md-table-cell>
                    </md-table-row>
                </md-table>
            </template>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'CarrierErrorModal',
    mixins: [GeneralMixin],
    props: {
        carrierName: { type: String, required: true },
        error: { type: Object, required: true }
    },
    computed: {
        parsedErrors() {
            const parsedErrors = [];
            if (this.error && this.error.errors) {
                const data = this.error.errors;
                const keys = Object.keys(data);
                const values = Object.values(data);

                for (let i = 0; i < keys.length; i++) {
                    // console.log('keys[i]', keys[i]);
                    // console.log('values[i]', values[i]);

                    parsedErrors.push({
                        code: keys[i],
                        description: values[i]
                    });
                }
            }

            return parsedErrors;
        }
    }
};
</script>
