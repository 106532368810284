<template>
    <div class="download-label">
        <md-button
            title="Download Labels"
            :class="['generate-label-btn', useIcon ? 'md-just-icon md-round' : '']"
            @click.stop="handleDownloadLabel"
        >
            <md-icon v-if="useIcon">barcode_reader</md-icon>
            <span v-else>Download Labels</span>
        </md-button>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'CarrierDownloadLabelButton',
    props: {
        useIcon: {
            type: Boolean,
            default: () => true
        },
        labels: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    mixins: [GeneralMixin],
    methods: {
        async handleDownloadLabel() {
            for (let i = 0; i < this.labels.length; i++) {
                window.open(this.labels[i].blobDownloadUrl, '_blank');
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.generate-label {
    display: inline;
}
.generate-label-btn,
.generate-label-btn:hover,
.generate-label-btn:focus {
    background-color: #2b93ff !important;
    margin-left: 0px;
}

.download-label {
    display: inline-block !important;
}
</style>
