<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Reschedule Maintenance Details</h4>
        </div>
        <div class="modal-body">
            <div class="details">
                <form-wrapper :validator="$v.maintenanceUpdateData" class="form-wrapper">
                    <div v-if="maintenanceScheduleData != null && maintenanceScheduleData.nextDueServiceDate != null">
                        <!-- <div class="reminder-title">
                        Time Based Reminder
                    </div> -->
                        <div class="reminder-details timebased-reminder">
                            <div>
                                <span>Your next due date:</span>
                                <div class="custom-md-field">
                                    <md-datepicker
                                        ref="mdDatePicker"
                                        v-model="maintenanceUpdateData.dueTime"
                                        md-immediately
                                        :md-disabled-dates="disabledDates"
                                        :md-debounce="10"
                                    ></md-datepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="maintenanceScheduleData != null && maintenanceScheduleData.nextDueEngineHours != null">
                        <div class="reminder-details custom-current-value-display">
                            <div>
                                <span>Your next due (in hours):</span>
                                <div>
                                    <form-group name="dueEngineHours" class="custom-md-field">
                                        <md-input
                                            v-model="maintenanceUpdateData.dueEngineHours"
                                            :disabled="maintenanceScheduleData.engineHoursReminderValue != null"
                                        />
                                    </form-group>
                                    <div class="minor-text">
                                        Current -
                                        {{ Math.round(maintenanceScheduleData.estimatedEngineHourValue) }} hours
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="maintenanceScheduleData != null && maintenanceScheduleData.nextDueOdometer != null">
                        <div class="reminder-details custom-current-value-display">
                            <div>
                                <span>Your next due (in {{ defaultDistanceUnit }}):</span>
                                <div>
                                    <form-group name="dueDistanceReading" class="custom-md-field">
                                        <md-input v-model="maintenanceUpdateData.dueDistanceReading" />
                                    </form-group>
                                    <div class="minor-text">
                                        Current - {{ Math.round(maintenanceScheduleData.estimatedOdometerValue) }}
                                        {{ defaultDistanceUnit }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form-wrapper>
            </div>
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click.stop="update">Reschedule</md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { DATE_TYPES, SPEED_TO_DISTANCE_UNITS } from '@/utils/constants';
import { handleRequests, showErrorMessage } from '@/helpers';
import { minValue, decimal } from 'vuelidate/lib/validators';

export default {
    name: 'UpdateUpcomingMaintenanceScheduleModal',
    mixins: [GeneralMixin],
    props: {
        maintenanceScheduleData: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            generalSettings: 'generalSetting/getGeneralSettings'
        })
    },
    validations: {
        maintenanceUpdateData: {
            dueDistanceReading: {
                minValue: minValue(1),
                decimal
            },
            dueEngineHours: {
                minValue: minValue(1),
                decimal
            }
        }
    },
    data() {
        return {
            dueTime: null,
            dueEngineHours: null,
            dueDistanceReading: null,
            defaultDistanceUnit: 'km',
            maintenanceUpdateData: {
                dueTime: null,
                dueEngineHours: null,
                dueDistanceReading: null
            },
            disabledDates: (date) => {
                const x = moment(date).format(DATE_TYPES.internationalDate);
                const now = moment().format(DATE_TYPES.internationalDate);
                return moment(x).isBefore(now, 'day');
            }
        };
    },
    mounted() {
        this.defaultDistanceUnit = SPEED_TO_DISTANCE_UNITS[this.generalSettings.defaultSpeedUnit];
        if (this.maintenanceScheduleData.nextDueServiceDate != null) {
            this.maintenanceUpdateData.dueTime = moment(this.maintenanceScheduleData.nextDueServiceDate).format(
                DATE_TYPES.internationalDate
            );
        }

        if (this.maintenanceScheduleData.nextDueEngineHours != null) {
            this.maintenanceUpdateData.dueEngineHours = this.maintenanceScheduleData.nextDueEngineHours;
        }

        if (this.maintenanceScheduleData.nextDueOdometer != null) {
            this.maintenanceUpdateData.dueDistanceReading = this.maintenanceScheduleData.nextDueOdometer;
            this.defaultDistanceUnit = this.maintenanceScheduleData.nextDueOdometerUnitType;
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        async update() {
            this.$v.maintenanceUpdateData.$touch();
            if (!this.$v.maintenanceUpdateData.$invalid) {
                this.$_handleLoaderState(true);
                const data = {
                    nextDueEngineHours: parseFloat(this.maintenanceUpdateData.dueEngineHours),
                    nextDueOdometer: parseFloat(this.maintenanceUpdateData.dueDistanceReading),
                    nextServiceDate:
                        this.maintenanceUpdateData.dueTime == null
                            ? null
                            : this.$options.filters.dateFormat(
                                this.maintenanceUpdateData.dueTime,
                                this.DATE_TYPES.internationalDate
                            )
                };

                const api = `/api/upcoming-maintenance-schedules/${
                    this.maintenanceScheduleData.assetMaintenanceScheduleRelationshipId
                }`;
                const payload = {
                    method: 'put',
                    data
                };

                try {
                    await handleRequests(api, payload);
                    this.$notify({
                        message: 'Successfully updated upcoming maintenance',
                        type: 'success'
                    });
                    this.$_handleLoaderState(false);
                    this.resolve(true);
                } catch (e) {
                    const message = 'Could not update upcoming maintenance';
                    showErrorMessage(this, message, e);
                    this.$_handleLoaderState(false);
                    this.resolve(false);
                }
            } else {
                const message = 'Could not update upcoming maintenance';
                showErrorMessage(this, message, e);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 550px;
}

.modal-header {
    text-align: center;
    display: block;
}

.modal-body {
    max-height: 80vh;
    text-align: center;
    > div {
        display: inline-block;
        vertical-align: top;
        padding: 0 20px;
        text-align: left;
    }
}

.reminder-title {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15px;
    > span {
        font-weight: initial;
    }
}

.reminder-details {
    span {
        width: 250px;
        display: inline-block;
        vertical-align: middle;
    }
    > div > div {
        display: inline-block;
        vertical-align: middle;
    }
}

.custom-current-value-display {
    span {
        margin-top: 6px;
        vertical-align: top;
    }
}
.timebased-reminder {
    margin-bottom: 12px;
}

.custom-md-field {
    display: inline-block;
    max-width: 150px;
    margin-top: 0;
    input {
        text-align: center;
        padding-bottom: 0;
        height: 30px;
    }

    ::v-deep .md-datepicker {
        margin-top: 0;
        i {
            margin-top: 12px;
            margin-bottom: 0;
        }
        input {
            padding-bottom: 0;
            padding-top: 12px;
        }
    }
}

.minor-text {
    color: #aaa;
    font-size: 10.5px;
    margin-top: -10px;
    text-align: center;
}
</style>
