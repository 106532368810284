var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c("div", { staticClass: "sidebar-image" }, [
          _c("img", {
            attrs: {
              src:
                _vm.appItem.dialogImageUrl == null
                  ? "/img/carrier-banner.jpg"
                  : _vm.appItem.dialogImageUrl,
              alt: "",
            },
          }),
        ]),
        _c(
          "simple-wizard",
          {
            attrs: {
              plain: "",
              "show-header": false,
              title: _vm.title,
              "logo-url": _vm.appItem.logoUrl,
              "sub-title": "",
              "finish-button-text": "finish",
              "next-button-text": _vm.nextButton,
            },
            on: { finish: _vm.connectCarrier, stepChange: _vm.stepChange },
          },
          [
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": _vm.validateDetails,
                  id: "welcomeScreen",
                },
              },
              [
                _c("template", { slot: "label" }, [
                  _vm._v(
                    "\n                    Welcome Screen\n                "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "padded-container" },
                  [
                    _c("welcome-screen", {
                      attrs: { "carrier-details": _vm.appItem },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": _vm.validateDetails,
                  id: "apiKeysSetup",
                },
              },
              [
                _c("template", { slot: "label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.tab2Label) +
                      "\n                "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "padded-container" },
                  [
                    _vm.showAccessKeysInput
                      ? [
                          _c("div", { staticClass: "key-note" }, [
                            _vm._v(
                              "\n                            To connect to\n                            "
                            ),
                            _c("span", [_vm._v(_vm._s(_vm.appItem.company))]),
                            _vm._v(
                              "\n                            you need to provide the following API keys. If you don't have these yet, you can skip\n                            this step for now, but you will need to provide these before you can complete the\n                            connection to the carrier and start using its services.\n                            "
                            ),
                            _vm.appItem.onboardingInstructionsUrl
                              ? _c("span", [
                                  _vm._v(
                                    "\n                                Follow\n                                "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href: _vm.appItem
                                          .onboardingInstructionsUrl,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    the instructions here\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                                to obtain these keys.\n                            "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm.currentCarrierComponent
                            ? _c(_vm.currentCarrierComponent, {
                                ref: "carrierApiKeys",
                                tag: "component",
                                staticClass: "api-key-container",
                              })
                            : _vm._e(),
                        ]
                      : [
                          _c("div", { staticClass: "key-note" }, [
                            _c("ol", [
                              _c("li", [
                                _vm._v(
                                  "\n                                    Complete this wizard.\n                                "
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  "\n                                    Locate2u will notify Uber to request access to connect to their platform.\n                                "
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  "\n                                    Uber will contact you and have the merchant agreement signed with them.\n                                "
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  "\n                                    Uber will update Locate2u when the contract has been signed.\n                                "
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  "\n                                    Locate2u will create a customer Id with Uber on your behalf.\n                                "
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  "\n                                    Uber will setup billing and pricing/radius configurations of your account.\n                                "
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                  ],
                  2
                ),
              ],
              2
            ),
            _vm.appItem.questionCustomFieldsMapping != null &&
            _vm.appItem.questionCustomFieldsMapping.booking
              ? _c(
                  "wizard-tab",
                  {
                    attrs: {
                      "before-change": _vm.validateDetails,
                      id: "questionsMappingSetup",
                    },
                  },
                  [
                    _c("template", { slot: "label" }, [
                      _vm._v(
                        "\n                    Questions Mapping\n                "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "padded-container" },
                      [
                        _c("div", { staticClass: "key-note" }, [
                          _vm._v(
                            "\n                        To get quotes and place bookings with\n                        "
                          ),
                          _c("span", [_vm._v(_vm._s(_vm.appItem.company))]),
                          _vm._v(
                            "\n                        , the following questions need to be answered. Please configure these to determine whether\n                        they can have a default answer, are mapped to a custom field, or will be asked upon each\n                        booking.\n                    "
                          ),
                        ]),
                        _c("CarrierQuestions", {
                          ref: "questionMapping",
                          staticClass: "onboarding-cq",
                          attrs: {
                            "questions-mapping":
                              _vm.appItem.questionCustomFieldsMapping != null
                                ? _vm.appItem.questionCustomFieldsMapping
                                    .booking
                                : [],
                            "booking-questions":
                              _vm.appItem.questionCustomFieldsMapping.booking !=
                              null
                                ? _vm.appItem.questionCustomFieldsMapping
                                    .booking
                                : [],
                            "carrier-teamid": _vm.appItem.carrierTeamId,
                          },
                          on: { isInvalid: _vm.validationInvalid },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }