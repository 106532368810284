<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <run-schedule-filter-component
                            :team-region-id="filters.teamRegionId"
                            @onFilterChanged="handleTeamRegionFilterChanged"
                        />
                        <filter-component
                            :class="$root.isTablet ? 'tablet-filter-container' : ''"
                            @onFilterRunSchedule="handleFilterOrSearch"
                            :team-members="() => this.teamMembers"
                        />
                    </div>
                    <div class="custom-toolbar-end">
                        <search-component ref="search" @onSearch="handleFilterOrSearch" />

                        <create-run-schedule-button
                            class="tablet-button-margin"
                            :team-members="teamMembers"
                            @runCreated="handleRunCreated"
                        />

                        <batch-upload
                            :title="'Import Run Schedules'"
                            :template-type="'runSchedules'"
                            :team-members="teamMembers"
                            @batchImported="handleRunCreated"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <div
                    v-if="bulkListError.length > 0"
                    class="alert alert-danger alert-dismissible fade show bulk-error-message"
                >
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="closeAlert">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div>
                        <div v-for="e in bulkListError" :key="e.runScheduleId" class="bulk-single-container">
                            <div>
                                {{ e.name }}
                            </div>
                            <div>
                                <div v-for="(error, i) in e.errors" :key="i">{{ error.message }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
                :class="total < pagination.perPage ? 'margin-space-bot' : ''"
            >
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>contact_page</md-icon>
                        </div>
                        <div class="custom-switch pull-right">
                            <md-switch v-model="toggleDisableRuns">Show disabled runs</md-switch>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div
                            :class="['bulk-section', topScrollPosition > 150 ? 'sticky' : '']"
                            v-if="selectedRunSchedules.length"
                        >
                            <div>
                                {{ selectedRunSchedules.length }} run schedule{{
                                    selectedRunSchedules.length > 1 ? 's' : ''
                                }}
                                selected.
                            </div>
                            <div>
                                <drop-down :should-close="shouldCloseDropdown">
                                    <span class="status md-warning" slot="title" data-toggle="dropdown">
                                        Change Status
                                    </span>
                                    <ul class="dropdown-menu">
                                        <li v-for="(item, index) in statuses" :key="index">
                                            <a @click.stop="handleChangeRunScheduleStatus(item)">
                                                {{ item }}
                                            </a>
                                        </li>
                                    </ul>
                                </drop-down>
                                <md-button
                                    class="md-round md-just-icon md-danger"
                                    title="Delete Run Schedules"
                                    @click="bulkDelete"
                                >
                                    <md-icon>delete</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div v-if="isListLoading" class="empty-table">
                            <div class="table-loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="runsList.length">
                                <md-table class="context-menu-support custom-paginated-table">
                                    <md-table-row>
                                        <md-table-head v-if="!isReadOnlyUser">
                                            <md-checkbox
                                                class="run-checkbox checkbox-head"
                                                v-model="isSelectAll"
                                                @change="onSelectAll(isSelectAll)"
                                            ></md-checkbox>
                                        </md-table-head>
                                        <md-table-head>Name</md-table-head>
                                        <md-table-head>Team Member</md-table-head>
                                        <md-table-head>Schedule</md-table-head>
                                        <md-table-head>Run Number</md-table-head>
                                        <md-table-head>Status</md-table-head>
                                        <md-table-head>Actions</md-table-head>
                                    </md-table-row>
                                    <md-table-row
                                        v-for="item in runsList"
                                        :key="item.runScheduleId"
                                        :class="item.status.toLowerCase() == 'disabled' ? 'disabled-row' : ''"
                                    >
                                        <md-table-cell v-if="!isReadOnlyUser">
                                            <md-checkbox
                                                class="run-checkbox"
                                                :value="item.runScheduleId"
                                                v-model="selectedRunSchedules"
                                                @change="onSelectRun()"
                                            ></md-checkbox>
                                        </md-table-cell>
                                        <md-table-cell class="run-schedule-name">
                                            <router-link
                                                class="custom-a-blue"
                                                :to="{
                                                    name: 'Run Schedule Details',
                                                    params: { runScheduleId: item.runScheduleId }
                                                }"
                                                target="_blank"
                                            >
                                                {{ item.name }}
                                            </router-link>
                                        </md-table-cell>
                                        <md-table-cell>
                                            <span v-if="Boolean(item.assignedTo.publicUserId)" class="custom-ellipsis">
                                                <img
                                                    v-if="item.assignedTo.fullName"
                                                    class="profile-image"
                                                    :src="
                                                        item.assignedTo.photoUrl !== null
                                                            ? item.assignedTo.photoUrl
                                                            : $root.defaultPhotoUrl
                                                    "
                                                    alt="avatar"
                                                    @error="$_setDefaultBrokenImage"
                                                />
                                                {{ item.assignedTo.fullName }}
                                            </span>
                                            <span v-else>Unassigned</span>
                                        </md-table-cell>
                                        <md-table-cell>
                                            <span v-if="item.schedule">
                                                {{ cronExpressionExplainer(item) }}
                                                <md-tooltip class="tooltip-width" md-direction="bottom">
                                                    {{ item.humanReadableSchedule }}
                                                </md-tooltip>
                                            </span>
                                            <span v-else-if="item.scheduleType === 'Fortnightly'">
                                                {{ item.scheduleType }} ({{
                                                    item.activeFromDate | dateFormat(DATE_TYPES.standardDate)
                                                }})
                                            </span>
                                            <span v-else>
                                                {{ item.scheduleType }}
                                            </span>
                                        </md-table-cell>
                                        <md-table-cell>{{ item.runNumber }}</md-table-cell>
                                        <md-table-cell>
                                            <run-status-button :run-schedule="item" @statusUpdated="onChangedStatus" />
                                        </md-table-cell>
                                        <md-table-cell class="action-buttons">
                                            <md-button
                                                title="Update run details"
                                                class="md-button md-primary md-just-icon md-round btn-size-27"
                                                @click.stop="updateRunSchedule(item.runScheduleId)"
                                            >
                                                <md-icon>edit</md-icon>
                                            </md-button>
                                            <md-button
                                                title="Delete run"
                                                class="md-danger md-just-icon md-round btn-size-27"
                                                @click.stop="handleDeleteRun(item.runScheduleId)"
                                            >
                                                <md-icon>delete</md-icon>
                                            </md-button>
                                            <md-button
                                                v-if="item.status.toLowerCase() === 'active'"
                                                title="Create Trip"
                                                class="md-primary md-just-icon md-round"
                                                @click.stop="handleCreateTrip(item)"
                                            >
                                                <md-icon>add</md-icon>
                                            </md-button>
                                        </md-table-cell>
                                    </md-table-row>
                                </md-table>
                            </div>
                            <div v-else>
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
                <md-card-actions class="page-footer" md-alignment="space-between">
                    <div>
                        <p v-if="total === pagination.perPage" class="card-category">
                            Page {{ pagination.currentPage }} of many
                        </p>
                        <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                    </div>
                    <pagination
                        v-model="pagination.currentPage"
                        class="pagination-no-border pagination-success"
                        :per-page="pagination.perPage"
                        :total="total"
                        @change-page="handleChangePage($event, pagination.perPage)"
                    />
                </md-card-actions>
            </div>
        </div>
    </div>
</template>

<script>
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { WEEK_DAYS_CONSTANTS, RUN_SCHEDULE_STATUS_CONSTANTS } from '@/utils/constants';
import { handleRequests, showErrorMessage } from '@/helpers';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import { Pagination, SearchComponent, BatchUpload } from '@/components';
import {
    CreateRunScheduleButton,
    CreateRunScheduleModal,
    RunScheduleFilterComponent,
    CreateTripModal,
    FilterComponent
} from './components';
import { RunStatusButton } from './buttons';

export default {
    name: 'RunsOverview',
    components: {
        FadeLoader,
        Pagination,
        SearchComponent,
        CreateRunScheduleButton,
        RunScheduleFilterComponent,
        RunStatusButton,
        BatchUpload,
        FilterComponent
    },
    mixins: [GeneralMixin],
    data() {
        return {
            weekDays: WEEK_DAYS_CONSTANTS,
            teamMembers: [],
            runsList: [],
            isListLoading: false,
            filters: { searchText: '', teamRegionId: null, includeActiveOnly: true },
            pagination: PAGINATION_DEFAULTS,
            maxPage: 1,
            toggleDisableRuns: true,
            runScheduleId: null,
            isSelectAll: false,
            selectedRunSchedules: [],
            bulkListError: [],
            runScheduleDetails: {},
            statuses: RUN_SCHEDULE_STATUS_CONSTANTS,
            shouldCloseDropdown: false,
            topScrollPosition: 0
        };
    },
    async mounted() {
        // this.filters.teamRegionId = this.user.teamRegionId ? this.user.teamRegionId : null; // null = All
        this.filters.teamRegionId = null; // null = All
        this.$_handleLoaderState(true);
        const { runs, totalRuns } = await this.handleFetchingOfRunsOnLoad();
        this.assignRuns(runs, totalRuns || runs.length);
        this.getTeamMembers();
        this.$_handleLoaderState(false);
    },
    watch: {
        toggleDisableRuns(newValue) {
            this.handleFilterOrSearch({ includeActiveOnly: newValue });
        },
        selectedRunSchedules() {
            if (this.selectedRunSchedules.length === 0) {
                this.bulkListError = [];
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isReadOnlyUser: 'user/isReadOnlyUser'
        }),
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        }
    },
    methods: {
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },
        async fetchRunsList(pageNumber = 1, itemsPerPage = 50) {
            const endpoint = `/api/run-schedules/list`;
            const response = await handleRequests(endpoint, {
                params: {
                    pageNumber,
                    itemsPerPage,
                    ...this.cleanFilters()
                }
            });
            return response;
        },
        async getTeamMembers(tripDate = null) {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS', {
                date: tripDate
            });
        },
        async handleFetchingOfRunsOnLoad() {
            const currentPage = Number(this.$route.query.currentPage) || 1;

            const {
                data: { runs, totalRuns }
            } = await this.fetchRunsList(currentPage);

            if (currentPage) {
                this.pagination.currentPage = currentPage;
            }

            return { runs, totalRuns };
        },
        assignRuns(runs, totalRuns) {
            this.runsList = runs;
            this.pagination.total = totalRuns;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.$_handleLoaderState(true);
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            const {
                data: { runs, totalRuns }
            } = await this.fetchRunsList(currentPage, perPage);
            const runsToAssign = totalRuns || runs.length;
            this.assignRuns(runs, runsToAssign);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            this.$_handleLoaderState(false);
            this.isListLoading = false;
        },
        async handleFilterEvent(val) {
            await handleFilterOrSearch(val);
        },
        async handleTeamRegionFilterChanged(val) {
            let shouldSearch = false;
            Object.getOwnPropertyNames(val).forEach((q) => {
                shouldSearch = shouldSearch || (val[q] || null) !== (this.filters[q] || null);
            });

            if (shouldSearch) {
                this.filters = Object.assign(this.filters, val);
                await this.handleFilterOrSearch(val);
            }
        },
        async handleFilterOrSearch(val) {
            this.isListLoading = true;
            this.filters = Object.assign(this.filters, val);

            const {
                data: { runs, totalRuns }
            } = await this.fetchRunsList(1, this.pagination.perPage);
            const runsToAssign = totalRuns || runs.length;
            this.assignRuns(runs, runsToAssign);
            this.isListLoading = false;
        },
        async deleteRun(runScheduleId) {
            const payload = {
                method: 'delete'
            };
            const api = `/api/run-schedules/${runScheduleId}`;
            try {
                this.$_handleLoaderState(true);
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Run schedule was deleted!',
                    type: 'success'
                });
                this.handleChangePage(1, this.pagination.perPage);
            } catch (e) {
                const message = 'Error in deleting a run schedule.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
                this.isListLoading = false;
            }
        },
        handleDeleteRun(runScheduleId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Run Schedule',
                    body: 'Are you sure you want to delete this run schedule?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        await this.deleteRun(runScheduleId);
                    }
                });
        },
        handleChangeRunScheduleStatus(status) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Bulk Change Status',
                    body: `Are you sure you want to change the status of these run schedules to ${status}?`,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'UPDATING...');

                        const payload = {
                            method: 'post',
                            data: {
                                runScheduleIds: this.selectedRunSchedules,
                                newStatus: status
                            }
                        };
                        try {
                            const api = `/api/run-schedules/bulk/change-status`;
                            const response = await handleRequests(api, payload);
                            if (response != null && response.data.length) {
                                this.bulkListError = [];
                                this.bulkListError = response.data;
                            } else {
                                this.$notify({
                                    message: `${
                                        this.selectedRunSchedules.length
                                    } run schedules had their status changed to ${status}!`,
                                    type: 'success'
                                });

                                if (this.isSelectAll) {
                                    this.isSelectAll = false;
                                }

                                this.selectedRunSchedules.forEach((x) => {
                                    const runSchedule = this.runsList.find((item) => item.runScheduleId === x);
                                    this.$set(runSchedule, 'status', status);
                                    if (this.runScheduleDetails) {
                                        this.$set(this.runScheduleDetails, 'status', status);
                                    }
                                });
                                this.selectedRunSchedules = [];
                            }
                        } catch (e) {
                            const message = 'Cannot change run schedule status.';
                            showErrorMessage(this, message, e);
                        }
                    }
                    this.shouldCloseDropdown = true;
                    this.$_handleLoaderState(false);
                });

            // to toggle the context menu
            this.$nextTick(() => {
                this.shouldCloseDropdown = false;
            });
        },
        bulkDelete() {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Run Schedules',
                    body: 'Are you sure you want to delete these run schedules?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'DELETING...');
                        const payload = {
                            method: 'delete',
                            data: this.selectedRunSchedules
                        };

                        try {
                            const api = `/api/run-schedules/bulk/delete`;
                            const response = await handleRequests(api, payload);

                            if (response != null && response.data.length) {
                                this.bulkListError = [];
                                this.bulkListError = response.data;

                                this.$notify({
                                    message: `There were errors while deleting run schedules.`,
                                    type: 'danger'
                                });
                            } else {
                                this.$notify({
                                    message: `${this.selectedRunSchedules.length} run schedules have been deleted!`,
                                    type: 'success'
                                });

                                if (this.isSelectAll) {
                                    this.isSelectAll = false;
                                }

                                this.selectedRunSchedules = [];
                                this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
                            }
                        } catch (e) {
                            const message = 'Cannot delete run schedules.';
                            showErrorMessage(this, message, e);
                        }
                    }
                    this.$_handleLoaderState(false);
                });
        },
        closeAlert() {
            this.bulkListError = [];
        },
        async getRunScheduleDetails(runScheduleId) {
            try {
                const api = `/api/run-schedules/${runScheduleId}`;
                const { data } = await handleRequests(api);
                return data;
            } catch (error) {
                const message = 'Error in getting the run schedule details';
                showErrorMessage(this, message, error);
            }
            return {};
        },
        onChangedStatus(response) {
            const { runScheduleId, status } = response;
            const runSchedule = this.runsList.find((item) => item.runScheduleId === runScheduleId);
            this.$set(runSchedule, 'status', status);
        },
        async updateRunSchedule(runScheduleId) {
            const runScheduleDetails = await this.getRunScheduleDetails(runScheduleId);
            const location = {
                latitude: null,
                longitude: null
            };
            runScheduleDetails.runName = runScheduleDetails.name;
            runScheduleDetails.contact = {
                name: runScheduleDetails.customerName
            };
            runScheduleDetails.runStartLocation = {
                address: runScheduleDetails.startAddress,
                location: runScheduleDetails.startLocation || location,
                name: runScheduleDetails.startAddressName
            };
            runScheduleDetails.runEndLocation = {
                address: runScheduleDetails.endAddress,
                location: runScheduleDetails.endLocation || location,
                name: runScheduleDetails.endAddressName
            };

            if (!runScheduleDetails.scheduleType) {
                runScheduleDetails.scheduleType = 'Weekly';
            }

            if (!runScheduleDetails.teamRegionId) {
                runScheduleDetails.teamRegionId = -1; // Not Set
            }
            this.$modal
                .show(CreateRunScheduleModal, {
                    members: this.teamMembers,
                    runDetails: Object.assign({}, runScheduleDetails),
                    isUpdate: true
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.handleChangePage(1, this.pagination.perPage);
                        this.$modal.hide();
                    }
                });
        },

        handleRunCreated() {
            this.handleChangePage();
        },
        cronExpressionExplainer(runDetail) {
            if (runDetail.schedule) {
                const scheduleVal = runDetail.schedule.split(' ');
                const scheduleWeekDays = scheduleVal[4].split(',');
                scheduleWeekDays.forEach((day, index) => {
                    if (day.match(/[a-z]/i)) {
                        scheduleWeekDays[index] = Object.keys(this.weekDays).find(
                            (key) => this.weekDays[key].toLocaleLowerCase() === day.toLocaleLowerCase()
                        );
                    }
                });
                const displayWeekDays = [];
                scheduleWeekDays.sort().forEach((w) => {
                    // eslint-disable-next-line radix
                    displayWeekDays.push(this.weekDays[parseInt(w)]);
                });
                return `${runDetail.scheduleType} (${displayWeekDays.join(', ')})`;
            }
            return '';
        },

        handleCreateTrip(runScheduleDetails) {
            this.$modal
                .show(CreateTripModal, {
                    members: this.teamMembers,
                    runDetails: Object.assign({}, runScheduleDetails)
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.$modal.hide();
                    }
                });
        },
        onSelectRun() {
            this.isSelectAll = this.isSelectAll 
                ? false 
                : this.runsList.every(x => this.selectedRunSchedules.includes(x.runScheduleId));
        },
        onSelectAll(value) {
            if (value) {
                this.runsList.forEach((run) => {
                    if (this.selectedRunSchedules.includes(run.runScheduleId))
                        return;

                    this.selectedRunSchedules.push(run.runScheduleId);
                });
            } else {
                this.selectedRunSchedules = [];
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.content {
    margin-top: -15px;
    ::v-deep .md-card.md-theme-default {
        margin-bottom: 0;
    }
}
.profile-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

.custom-toolbar {
    align-items: start;
    margin-top: 1px;
    .filter-member-name {
        width: 250px;
    }
    ::v-deep .header-button {
        width: 32px;
        height: 32px;
        min-width: 32px;
    }
}

::v-deep .search--container {
    width: 250px;
    float: right;
    margin-top: 6px;
}
.run-schedule-name ::v-deep a {
    color: rgba(0, 0, 0, 0.87);
}
.tooltip-width {
    max-width: 500px;
    text-align: justify;
}

.run-checkbox {
    margin: 0;
}
.checkbox-head {
    margin-top: 4px;
}

.body-list {
    position: relative;
    .sticky {
        position: fixed;
        top: 0;
        z-index: 9;
        left: 75px;
    }
}
.bulk-section {
    position: absolute;
    top: -35px;
    width: 545px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    background-color: #cfeefa;
    color: #2b93ff;
    font-weight: 400;
    padding: 5px 10px 5px 20px;
    > div {
        width: 60%;
        display: inline-block;
        vertical-align: middle;
        text-align: left;
    }
    > div:last-child {
        width: 40%;
        text-align: right;
        button {
            height: 30px;
            width: 30px;
            min-width: 30px;
        }
        .dropdown {
            width: 120px;
            background-color: #ff9800 !important;
            display: inline-block;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
            height: 30px;
            line-height: 30px;
            margin-top: 6px;
            cursor: pointer;
        }
    }
}

.bulk-error-message {
    z-index: 1 !important;
    > div {
        max-height: 160px;
        overflow: auto;
    }

    .bulk-single-container {
        display: table;
        > div {
            display: table-cell;
        }
        > div:first-child {
            width: 200px;
        }
    }
}

.filter-steps--container {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }

    ::v-deep .md-has-value:before,
    ::v-deep .md-has-value:after {
        bottom: 0;
        height: 0;
        content: '';
    }
}
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}
::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}
::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}

::v-deep .vs__search {
    cursor: pointer;
}
.disabled-row {
    background: #d4d2d2;
}
.disabled-row:hover {
    background: #d4d2d2;
}
.custom-toolbar-start {
    flex: 2;
}
</style>
