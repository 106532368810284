<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
                <md-icon>receipt_long</md-icon>
            </div>
        </md-card-header>
        <md-card-content>
            <p v-if="!lines || lines.length === 0" class="grayedout-text text-center">
                No items found
            </p>
            <div v-else>
                <md-table class="context-menu-support custom-paginated-table">
                    <md-table-row>
                        <md-table-head>Status</md-table-head>
                        <md-table-head>Barcode</md-table-head>
                        <md-table-head col-span="1">Description</md-table-head>
                        <md-table-head v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Length')">L</md-table-head>
                        <md-table-head v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Width')">W</md-table-head>
                        <md-table-head v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Height')">H</md-table-head>
                        <md-table-head v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Volume')">V</md-table-head>
                        <md-table-head v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Weight')">Wt</md-table-head>
                        <md-table-head v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Quantity')">
                            Qty
                        </md-table-head>
                        <md-table-head v-if="$root.isDesktop && !isReadOnlyUser">Actions</md-table-head>
                    </md-table-row>
                    <md-table-row
                        v-for="line in lines"
                        :key="line.StopLines"
                        @click.stop="openInventoryItem(line.itemId)"
                    >
                        <md-table-cell>
                            <div
                                :class="
                                    `custom-badge inventory-status-${line.status.toLowerCase().replace(/\s+/g, '')}`
                                "
                            >
                                {{ $t('inputs.itemStatuses.' + line.status) }}
                            </div>
                        </md-table-cell>
                        <md-table-cell>{{ line.barcode }}</md-table-cell>
                        <md-table-cell col-span="1">{{ line.description }}</md-table-cell>
                        <md-table-cell v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Length')">
                            {{ $_displayLoadValue(loadCapacityList, line.load, 'Length') }}
                        </md-table-cell>
                        <md-table-cell v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Width')">
                            {{ $_displayLoadValue(loadCapacityList, line.load, 'Width') }}
                        </md-table-cell>
                        <md-table-cell v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Height')">
                            {{ $_displayLoadValue(loadCapacityList, line.load, 'Height') }}
                        </md-table-cell>
                        <md-table-cell v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Volume')">
                            {{ $_displayLoadValue(loadCapacityList, line.load, 'Volume') }}
                        </md-table-cell>
                        <md-table-cell v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Weight')">
                            {{ $_displayLoadValue(loadCapacityList, line.load, 'Weight') }}
                        </md-table-cell>
                        <md-table-cell v-if="$_isLoadCapacityTypeEnabled(loadCapacityList, 'Quantity')">
                            {{ $_displayLoadValue(loadCapacityList, line.load, 'Quantity') }}
                        </md-table-cell>
                        <md-table-cell v-if="$root.isDesktop && !isReadOnlyUser" class="action-buttons">
                            <update-item-button
                                v-if="!isReadOnlyUser"
                                :item="line"
                                @itemUpdated="handleUpdateItem"
                                :is-delivered="line.status === 'Delivered'"
                                :stop-status="stopStatus"
                                :is-invoiced="isInvoiced"
                            />

                            <div class="action-button">
                                <md-button
                                    v-if="!isReadOnlyUser"
                                    title="Unlink item"
                                    class="md-danger md-just-icon md-round"
                                    :disabled="
                                        isInvoiced ||
                                            belongsToShipment ||
                                            line.status === 'Delivered' ||
                                            stopStatus.toLowerCase() === 'failed' ||
                                            stopStatus.toLowerCase() === 'completed'
                                    "
                                    @click.stop="handleUnlinkItem(line.itemId)"
                                >
                                    <md-icon>link_off</md-icon>
                                </md-button>
                                <md-tooltip v-if="isInvoiced" class="line-tooltip" md-direction="left">
                                    You cannot unlink an item when the stop is invoiced.
                                </md-tooltip>
                                <md-tooltip v-else-if="belongsToShipment" class="line-tooltip" md-direction="left">
                                    This stop belongs to a shipment.
                                    <br />
                                    Open the shipment details from the top of the page to unlink the item.
                                </md-tooltip>
                                <md-tooltip
                                    v-else-if="line.status === 'Delivered'"
                                    class="line-tooltip"
                                    md-direction="top"
                                >
                                    You cannot unlink a delivered item.
                                </md-tooltip>
                                <md-tooltip
                                    v-else-if="stopStatus !== null && stopStatus.toLowerCase() === 'failed'"
                                    class="line-tooltip"
                                    md-direction="top"
                                >
                                    You cannot edit an item when the stop is marked as Failed
                                </md-tooltip>
                                <md-tooltip
                                    v-else-if="stopStatus !== null && stopStatus.toLowerCase() === 'complete'"
                                    class="line-tooltip"
                                    md-direction="top"
                                >
                                    You cannot edit an item when the stop is marked as Complete
                                </md-tooltip>
                            </div>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
            </div>
        </md-card-content>

        <md-card-actions md-alignment="right" v-if="!isSupportStaff">
            <generate-label-button v-if="lines.length > 0" :id="stopId" :use-icon="false" />
            <div>
                <md-button
                    :disabled="
                        isInvoiced ||
                            belongsToShipment ||
                            stopStatus.toLowerCase() === 'complete' ||
                            stopStatus.toLowerCase() === 'failed'
                    "
                    class="md-success"
                    @click="handleAddLine()"
                >
                    Add Item
                </md-button>
                <md-tooltip v-if="isInvoiced" class="line-tooltip" md-direction="left">
                    Not allowed to add an item when the stop is already invoiced.
                </md-tooltip>
                <md-tooltip v-else-if="belongsToShipment" class="line-tooltip" md-direction="left">
                    This stop belongs to a shipment.
                    <br />
                    Open the shipment details from the top of the page to add new item.
                </md-tooltip>
                <md-tooltip v-else-if="stopStatus.toLowerCase() === 'failed'" class="line-tooltip" md-direction="left">
                    Not allowed to add an item when the stop is already marked as Failed.
                </md-tooltip>
                <md-tooltip
                    v-else-if="stopStatus.toLowerCase() === 'complete'"
                    class="line-tooltip"
                    md-direction="left"
                >
                    Not allowed to add an item when the stop is already marked as Complete.
                </md-tooltip>
            </div>
        </md-card-actions>
    </md-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins';
import { GenerateLabelButton } from '@/components';
import { UpdateItemButton } from '@/pages/Inventory/buttons';
import StopLineModal from './StopLineModal';

export default {
    name: 'StopLines',
    mixins: [GeneralMixin],
    components: {
        UpdateItemButton,
        GenerateLabelButton
    },
    props: {
        lines: {
            type: Array,
            default: () => []
        },
        stopId: {
            type: Number,
            default: null
        },
        belongsToShipment: {
            type: Boolean,
            default: false
        },
        stopStatus: {
            type: String,
            required: true
        },
        isInvoiced: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loadCapacityList: []
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser',
            isSupportStaff: 'user/isSupportStaff'
        })
    },
    methods: {
        handleAddLine() {
            this.$modal
                .show(StopLineModal, {
                    stopId: this.stopId
                })
                .then((response) => {
                    this.$emit('createdLine');
                    this.$modal.hide();
                });
        },
        openInventoryItem(itemId) {
            const routeData = this.$router.resolve({ name: 'Inventory Details', params: { itemId } });
            window.open(routeData.href, '_blank');
        },
        handleUpdateItem() {
            this.$emit('createdLine');
        },
        async handleConfirmUnlink(itemId) {
            this.$_handleLoaderState(true, 'UPDATING...');

            try {
                const payload = {
                    method: 'post',
                    data: {
                        itemId,
                        stopIds: [this.stopId]
                    }
                };
                const api = `/api/items/unlink`;
                await handleRequests(api, payload);

                this.$emit('createdLine');

                this.$notify({
                    message: `Item unlinked!`,
                    type: 'success'
                });
            } catch (e) {
                const message = 'Cannot unlink item.';
                showErrorMessage(this, message);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async handleUnlinkItem(itemId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Unlink Item',
                    body: 'Are you sure you want to unlink this item?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmUnlink(itemId);
                });
        }
    },
    mounted() {
        this.loadCapacityList = this.user.vehicleCapacityUnitsConfiguration || [];
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    margin-right: 16px;
}
.action-button {
    display: inline-block;
}
</style>
