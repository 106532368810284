var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-section" }, [
    _c("span", { staticClass: "title" }, [_vm._v("Warehouse Information")]),
    _c("div", { staticClass: "detail-group" }, [
      _c("label", { staticClass: "label" }, [
        _vm._v("\n            Name:\n        "),
      ]),
      _c("span", { staticClass: "value" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.warehouseDetails.name) + "\n        "
        ),
      ]),
    ]),
    _c("div", { staticClass: "detail-group" }, [
      _c("label", { staticClass: "label" }, [
        _vm._v("\n            Address:\n        "),
      ]),
      _c("span", { staticClass: "value" }, [
        _c(
          "a",
          {
            staticClass: "address",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.viewAddress(_vm.warehouseDetails)
              },
            },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.warehouseDetails.address) +
                "\n            "
            ),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "detail-group" }, [
      _c("label", { staticClass: "label" }, [
        _vm._v("\n            Team Region:\n        "),
      ]),
      _c("span", { staticClass: "value" }, [
        _vm._v(
          "\n            " +
            _vm._s(
              _vm.warehouseDetails.teamRegion
                ? _vm.warehouseDetails.teamRegion.name
                : ""
            ) +
            "\n        "
        ),
      ]),
    ]),
    _c("div", { staticClass: "detail-group" }, [
      _c("label", { staticClass: "label" }, [
        _vm._v("\n            Contact:\n        "),
      ]),
      _c("span", { staticClass: "value" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.contactDesc(_vm.warehouseDetails)) +
            "\n        "
        ),
      ]),
    ]),
    _c("div", { staticClass: "detail-group" }, [
      _c("label", { staticClass: "label" }, [
        _vm._v("\n            Email:\n        "),
      ]),
      _c("span", { staticClass: "value" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.warehouseDetails.email) + "\n        "
        ),
      ]),
    ]),
    _c("div", { staticClass: "detail-group" }, [
      _c("label", { staticClass: "label" }, [
        _vm._v("\n            Phone:\n        "),
      ]),
      _c("span", { staticClass: "value" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.warehouseDetails.phone) + "\n        "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }