var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-spacing" },
    [
      _c(
        "sidebar-item",
        {
          attrs: {
            link: {
              name: _vm.$t("menus.assetManagement.assetManagement"),
              icon: "local_shipping",
            },
          },
        },
        [
          _c("sidebar-item", {
            attrs: {
              link: {
                name: _vm.$t("menus.assetManagement.assets"),
                path: "/assets/overview",
              },
            },
          }),
          _c("sidebar-item", {
            attrs: {
              badge: "New",
              link: {
                name: _vm.$t("menus.assetManagement.schedules"),
                path: "/maintenance/schedules",
              },
            },
          }),
          _c("sidebar-item", {
            attrs: {
              badge: "New",
              link: {
                name: _vm.$t("menus.assetManagement.upcoming"),
                path: "/maintenance/upcoming",
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }