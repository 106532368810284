var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-steps--container" },
    [
      _vm.canViewTeamRegion
        ? _c("team-region-member-filter-options", {
            attrs: {
              "team-region-id": _vm.teamRegionId,
              "strict-team-region-filter": false,
              clearable: false,
            },
            on: { changed: _vm.handleTeamRegionMemberChanged },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }