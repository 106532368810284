<template>
    <div class="modal-container">
        <div class="modal-header">
            <span>Edit {{ type }}</span>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body failed-delivery-configuration-modal">
            <div class="stop-form-container">
                <form-wrapper :validator="$v.options" class="form-wrapper">
                    <div class="md-layout md-medium-size-100 md-xsmall-size-100 md-size-100 options">
                        <div class="md-layout-item md-size-100">
                            <div v-if="isTextBoxOptions">
                                <div class="md-layout-item md-size-10 option-toggle-button option-toggle-button-list">
                                    <md-button class="md-primary md-just-icon md-round" @click="toggleOptions">
                                        <md-icon>list</md-icon>
                                        <md-tooltip md-direction="left">Use list view to add option</md-tooltip>
                                    </md-button>
                                </div>
                                <label class="label-text">Available Options:</label>
                                <form-group name="options">
                                    <md-textarea v-model="optionValue" placeholder="Option" @change="addOptions" />
                                </form-group>
                            </div>
                            <div v-else>
                                <form-group name="options">
                                    <md-input v-model="optionValue" placeholder="Option" @keyup.enter="addOption" />
                                </form-group>
                            </div>
                        </div>
                        <div class="md-layout-item md-size-0 add-icon" v-if="!isTextBoxOptions" @click="addOption">
                            <md-icon>add</md-icon>
                        </div>
                    </div>
                    <div v-if="options.length && !isTextBoxOptions" class="options-table">
                        <div class="md-layout-item md-size-10 option-toggle-button">
                            <md-button class="md-primary md-just-icon md-round" @click="toggleOptions">
                                <md-icon>launch</md-icon>
                                <md-tooltip md-direction="left">Use text box field</md-tooltip>
                            </md-button>
                        </div>
                        <label>Available Options:</label>
                        <md-table>
                            <md-table-row v-for="(option, index) in options" :key="index">
                                <md-table-cell>
                                    <form-group>
                                        <md-input v-model="option.value" />
                                    </form-group>
                                </md-table-cell>
                                <md-table-cell>
                                    <md-button
                                        class="md-just-icon md-danger md-simple"
                                        title="delete option "
                                        @click.native="removeOption(index)"
                                    >
                                        <md-icon>close</md-icon>
                                    </md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </form-wrapper>
            </div>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="editConfiguration">
                Save
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'FailedDeliveryConfigurationModal',
    mixins: [GeneralMixin],
    props: {
        type: {
            type: String,
            default: null
        },
        failedDeliveryConfigurationOptions: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            isTextBoxOptions: false,
            options: [],
            optionValue: null
        };
    },
    async mounted() {
        this.options = JSON.parse(JSON.stringify(this.failedDeliveryConfigurationOptions));
    },
    methods: {
        toggleOptions() {
            this.isTextBoxOptions = !this.isTextBoxOptions;
            if (this.isTextBoxOptions) {
                this.optionValue = this.options.length ? this.options.map((x) => x.value).join('\n') : null;
            } else {
                this.optionValue = null;
            }
        },
        isDuplicateOption(value) {
            return this.options.findIndex((x) => x.value.toLowerCase() === value.toLowerCase()) > -1;
        },
        showWarningDuplicate(value) {
            const message = `Option name ${value} already exists.`;
            this.$notify({
                message,
                type: 'warning'
            });
        },
        addOptions() {
            const options = [];
            this.optionValue.split('\n').forEach((option) => {
                const index = options.findIndex((x) => x.value.toLowerCase() === option.toLowerCase());
                if (option && index === -1) {
                    options.push({
                        value: option
                    });
                }
            });
            this.options = options;
            this.optionValue = options.map((x) => x.value).join('\n');
        },
        addOption() {
            if (this.optionValue != null) {
                if (!this.options) {
                    this.options = [
                        {
                            value: this.optionValue
                        }
                    ];
                    this.optionValue = null;
                } else if (!this.isDuplicateOption(this.optionValue)) {
                    this.options.push({
                        value: this.optionValue
                    });
                    this.optionValue = null;
                } else {
                    this.showWarningDuplicate(this.optionValue);
                }
            }
        },
        removeOption(index) {
            this.options.splice(index, 1);
        },
        editConfiguration() {
            this.resolve(this.options);
        }
    },
    validations() {
        return {
            options: {
                required
            }
        };
    }
};
</script>
<style lang="scss" scoped>
.failed-delivery-configuration-modal {
    max-width: 550px;
    width: 550px;
}
.options {
    ::v-deep .md-layout-item {
        padding: 0px;
    }
    ::v-deep input {
        border-bottom: 1px solid #dedede !important;
        margin: 0;
        border: none;
        border-radius: 0;
        width: 100%;
        padding: 10px;
        float: left;
        font-size: 14px;
        margin-bottom: 10px;
    }
    ::v-deep .md-field {
        padding: 0px;
        margin-top: 5px;
        &::before,
        &::after {
            bottom: 0px;
            display: none;
        }
    }
    ::v-deep .md-textarea {
        padding: 5px 8px !important;
        border: 1px solid #eee !important;
        resize: none !important;
        min-height: 150px !important;
    }
    ::v-deep .md-error {
        padding-top: 12px;
    }
    .add-icon {
        margin-left: -25px;
        float: right;
        cursor: pointer;
        z-index: 100;
        margin-top: 10px;
    }
    .label-text {
        padding: 0px !important;
    }
}
.options-table {
    ::v-deep .md-table-cell {
        padding: 0px;
        border: 0px;
    }
    ::v-deep input {
        border: 1px solid #dedede;
        width: 100%;
        padding: 8px;
    }
    ::v-deep .md-field {
        padding: 0px;
        &::before,
        &::after {
            bottom: 0px;
            display: none;
        }
    }
    .md-table {
        max-height: 250px;
    }
}
.option-toggle-button {
    display: inline-block;
    vertical-align: middle;
    margin-left: -30px;
    margin-right: 5px;

    .md-button {
        height: 30px;
        width: 30px;
        min-width: 30px;
        margin-right: 0;
        ::v-deep i {
            color: #2b93ff !important;
        }
        background-color: transparent !important;
    }
    .md-button:active,
    .md-button:focus {
        background-color: transparent !important;
        ::v-deep i {
            color: #2b93ff !important;
        }
    }
    .md-button:hover {
        background: none !important;
        background-color: #2b93ff !important;
        ::v-deep i {
            color: #fff !important;
        }
    }
}
.option-toggle-button-list {
    margin-left: -15px !important;
}

.modal-container {
    width: 550px !important;
    max-width: 550px !important;
}
.modal-body {
    width: 550px !important;
}

.modal-footer {
    width: 550px !important;
}

::v-deep .vs__search {
    padding: 0 !important;
}
.multiple-field-container {
    margin: 13px 0 20px 0;
}
.custom-fields {
    ::v-deep .md-layout-item {
        padding: 0 0 0 10px;
        width: 30%;
    }
}
.custom-field-option {
    ::v-deep .md-subheader {
        text-transform: capitalize;
        font-weight: bold;
    }

    ::v-deep .md-list-item {
        margin-left: 15px !important;
    }
}
</style>
