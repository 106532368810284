<script>
import { ref, inject, onMounted, watch, onUnmounted, provide } from '@vue/composition-api';

export default {
    name: 'MapMarker',
    props: {
        id: {
            type: String,
            required: true
        },
        marker: {
            type: Object,
            default: () => {},
            required: true
        }
    },
    setup(props) {
        const { type, coordinates, markerStyle } = props.marker;
        const markerRef = ref(null);
        const mapEngine = inject('mapEngine');

        provide('currentMarkerInstance', markerRef);

        onMounted(() => {
            markerRef.value = mapEngine.addMarker({
                type,
                coordinates,
                style: markerStyle
            });
        });

        watch(
            () => props.marker.coordinates,
            (newVal, oldVal) => {
                mapEngine.updateMarkerLocation(markerRef.value, newVal);
            }
        );

        onUnmounted(() => {
            mapEngine.removeMarker(markerRef.value);
            markerRef.value = null;
        });
    }
};
</script>
<template>
    <!-- Render an element to satisfy lint build rule vue/valid-template-root -->
    <span>
        <slot />
    </span>
</template>
