import polyline from '@mapbox/polyline';
import { MarkerGenerator } from '@/components/Maps/mapboxMarkerGenerator';
import { ICON_NAMES } from './constants';

function getMarkerType(type) {
    const markerTypeInternalMapping = {
        stop: ICON_NAMES.PIN_STOP,
        destination: ICON_NAMES.DESTINATION,
        driver: ICON_NAMES.DRIVER,
        user: ICON_NAMES.PIN_USER,
        pickup: ICON_NAMES.SHIPMENT_PICKUP,
        drop: ICON_NAMES.SHIPMENT_DROP
    };

    const markerType = markerTypeInternalMapping[type] ?? null;
    if (!markerType) 
        return null;

    return markerType;
}

export class MapboxAdapter {
    constructor(mapboxRef) {
        this.mapboxRef = mapboxRef;
        this.mapRef = null;
        this.style = null;
        this.center = null;
        this.zoom = null;
        this.accessToken = null;
        this.polylineDecoder = polyline;
        // bind methods to the class to avoid unexpected behavior with "this"
        this.setMapOptions = this.setMapOptions.bind(this);
        this.buildMap = this.buildMap.bind(this);
        this.addControl = this.addControl.bind(this);
        this.addMarker = this.addMarker.bind(this);
        this.addPopup = this.addPopup.bind(this);
        this.updateMarkerLocation = this.updateMarkerLocation.bind(this);
        this.removeMarker = this.removeMarker.bind(this);
        this.removePath = this.removePath.bind(this);
        this.setPath = this.setPath.bind(this);
        this.createLngLatBounds = this.createLngLatBounds.bind(this);
        this.createLngLat = this.createLngLat.bind(this);
        this.panTo = this.panTo.bind(this);
        this.fitBounds = this.fitBounds.bind(this);
        this.decodePath = this.decodePath.bind(this);
    }

    setMapOptions(options) {
        const { accessToken, style, center, zoom } = options;
        this.accessToken = accessToken;
        this.style = style;
        this.center = center;
        this.zoom = zoom;
        return this;
    }

    buildMap(containerRef) {
        this.mapboxRef.accessToken = this.accessToken;
        this.mapRef = new this.mapboxRef.Map({
            container: containerRef,
            style: this.style,
            center: this.center,
            zoom: this.zoom
        });
        return this.mapRef;
    }

    // add control depending on the map library
    addControl(control) {
        this.mapRef.addControl(control);
    }

    addMarker(options) {
        const { type, coordinates, style } = options;
        const markerGenerator = new MarkerGenerator();
        const marker = markerGenerator.generateMarker(getMarkerType(type), {
            style
        });
        marker.setLngLat(coordinates).addTo(this.mapRef);

        return marker;
    }

    addPopup(options, markerRef = null) {
        const { offset = 25, html, closeButton = false, closeOnClick = true, behavior = 'mouseclick' } = options;

        const popup = new this.mapboxRef.Popup({
            offset,
            closeButton,
            closeOnClick
        });

        if (markerRef) {
            if (behavior === 'mouseclick') {
                popup.setHTML(html).addTo(this.mapRef);
            }
            // FUTURE: add more pop-up behavior as needed

            markerRef.setPopup(popup);
        }

        return popup;
    }

    /* eslint-disable class-methods-use-this */
    updateMarkerLocation(marker, coordinates) {
        marker.setLngLat(coordinates);
    }

    removeMarker(marker) {
        marker.remove();
    }

    removePath(options) {
        const { identifier } = options;

        if (this.mapRef.getLayer(identifier)) {
            this.mapRef.removeLayer(identifier);
        }
        if (this.mapRef.getSource(identifier)) {
            this.mapRef.removeSource(identifier);
        }
    }

    setPath(options) {
        const { identifier, coordinates, lineWidth = 2, lineColor = '#1c3e61', lineOffset = 0 } = options;

        const data = {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates
            }
        };

        if (this.mapRef.getSource(identifier)) {
            // update source
            this.mapRef.getSource(identifier).setData(data);
            return;
        }

        this.mapRef.addSource(identifier, {
            type: 'geojson',
            data
        });

        this.mapRef.addLayer({
            id: identifier,
            type: 'line',
            source: identifier,
            layout: {
                'line-join': 'round',
                'line-cap': 'round'
            },
            paint: {
                'line-color': lineColor,
                'line-width': lineWidth,
                'line-offset': lineOffset
            }
        });
    }

    createLngLatBounds(...args) {
        return new this.mapboxRef.LngLatBounds(...args);
    }

    createLngLat(...args) {
        return new this.mapboxRef.LngLat(...args);
    }

    panTo(options) {
        this.mapRef.flyTo(options);
    }

    fitBounds(options) {
        const { bounds, otherOptions } = options;
        this.mapRef.fitBounds(bounds, otherOptions);
    }

    /**
     *
     * @param {string} pathString
     * @returns {[number, number][]} first element is longitude, second is latitude
     */
    decodePath(pathString) {
        const decoded = this.polylineDecoder.decode(pathString);
        const points = decoded.map((point) => [point[1], point[0]]);
        return points;
    }
}
