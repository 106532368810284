var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasGeofenceAccess
    ? _c(
        "div",
        { staticClass: "geo-fence" },
        [
          _c(
            "div",
            {
              staticClass: "geofence-wrapper map-panel",
              class: [
                { "drawing-geo-fence": _vm.drawGeofence },
                { "showing-geo-list": _vm.showGeofenceList },
              ],
            },
            [
              _c("div", { staticClass: "geo-main-wrapper" }, [
                _c(
                  "a",
                  { staticClass: "geo-panel" },
                  [
                    _c("span", { staticClass: "title" }, [_vm._v("Geofences")]),
                    !this.drawGeofence
                      ? _c(
                          "span",
                          {
                            staticClass: "geo-icons toggle-list",
                            on: { click: _vm.toggleGeofenceList },
                          },
                          [_c("md-icon", [_vm._v("list")])],
                          1
                        )
                      : _vm._e(),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.drawGeofence
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "draw-geo-fence drawing-tool map-panel",
                            },
                            [
                              _c("div", { staticClass: "gmnoprint " }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "gmnoprint-child",
                                    class: [{ active: _vm.isDrawingCircle }],
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        class: [
                                          "circle-button",
                                          _vm.drawingType === "circle"
                                            ? "button-pressed"
                                            : "",
                                        ],
                                        attrs: {
                                          draggable: "false",
                                          title: "Draw a circle",
                                          "aria-label": "Draw a circle",
                                          type: "button",
                                          "aria-pressed": "false",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectDrawing("circle")
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "button-span" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "button-div" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    alt: "",
                                                    src: _vm.$root
                                                      .drawingToolIcon,
                                                    draggable: "false",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "gmnoprint-child",
                                    class: [{ active: !_vm.isDrawingCircle }],
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        class: [
                                          "polygon-button",
                                          _vm.drawingType === "polygon"
                                            ? "button-pressed"
                                            : "",
                                        ],
                                        attrs: {
                                          draggable: "false",
                                          title: "Draw a shape",
                                          "aria-label": "Draw a shape",
                                          type: "button",
                                          "aria-pressed": "false",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectDrawing("polygon")
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "button-span" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "button-div" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    alt: "",
                                                    src: _vm.$root
                                                      .drawingToolIcon,
                                                    draggable: "false",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "geo-icons toggle-add",
                        on: { click: _vm.toggleGeofenceDraw },
                      },
                      [
                        _vm.drawGeofence
                          ? _c("md-icon", { attrs: { title: "Cancel" } }, [
                              _vm._v("close"),
                            ])
                          : _c("md-icon", { attrs: { title: "Create" } }, [
                              _vm._v("draw"),
                            ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showGeofenceList
                  ? _c(
                      "div",
                      { staticClass: "geo-list-wrapper" },
                      [
                        _vm.geofenceArr.length
                          ? _c(
                              "md-card-content",
                              { staticClass: "geofence-table-body" },
                              [
                                _c(
                                  "ul",
                                  _vm._l(_vm.geofenceArr, function (fence) {
                                    return _c(
                                      "li",
                                      {
                                        key: fence.geofenceId,
                                        class:
                                          _vm.showInfoWindow &&
                                          fence.geofenceId === _vm.geofenceId
                                            ? "highlight-item"
                                            : "",
                                        staticStyle: { cursor: "pointer" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "name",
                                            on: {
                                              click: function ($event) {
                                                return _vm.zoomToFence(
                                                  fence.geofenceId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(fence.name) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pushed-right cta-list",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                attrs: { title: "Edit" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editGeofence(
                                                      fence.geofenceId
                                                    )
                                                  },
                                                },
                                              },
                                              [_c("md-icon", [_vm._v("edit")])],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                attrs: { title: "Delete" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteGeofence(
                                                      fence.geofenceId
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("md-icon", [
                                                  _vm._v("delete"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _c("div", { staticClass: "small" }, [
                              _vm._v(
                                "\n                    No geofences found\n                "
                              ),
                            ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm.addGeofenceDesc
            ? _c("div", { staticClass: "geo-fence-desc map-panel" }, [
                _c("div", { staticClass: "modal-main" }, [
                  _c("div", { staticClass: "modal-header" }, [
                    _vm.geofenceId
                      ? _c("b", { staticClass: "title" }, [
                          _vm._v("Edit Geofence"),
                        ])
                      : _c("b", { staticClass: "title" }, [
                          _vm._v("Add Geofence"),
                        ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "modal-body" },
                    [
                      _c(
                        "form-wrapper",
                        {
                          staticClass: "form-wrapper",
                          attrs: { validator: _vm.$v },
                        },
                        [
                          _vm.canViewTeamRegion &&
                          _vm.teamRegions &&
                          _vm.teamRegions.length > 0
                            ? _c(
                                "form-group",
                                { attrs: { label: "Team Region" } },
                                [
                                  _c("team-region-options", {
                                    attrs: {
                                      selected: _vm.currentShape.teamRegionId,
                                      "show-auto": false,
                                    },
                                    on: {
                                      selectedOption:
                                        _vm.handleTeamRegionChanged,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isTeamRegionId
                            ? _c("span", { staticClass: "error" }, [
                                _vm._v(
                                  "\n                        Team Region is required\n                    "
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "form-group",
                            {
                              attrs: {
                                name: "newGeofenceTitle",
                                label: "Name",
                              },
                            },
                            [
                              _c("md-input", {
                                directives: [
                                  { name: "focus", rawName: "v-focus" },
                                ],
                                attrs: { "aria-placeholder": "Name" },
                                model: {
                                  value: _vm.newGeofenceTitle,
                                  callback: function ($$v) {
                                    _vm.newGeofenceTitle = $$v
                                  },
                                  expression: "newGeofenceTitle",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "form-group",
                            { attrs: { name: "notes", label: "Notes" } },
                            [
                              _c("md-textarea", {
                                attrs: { "aria-placeholder": "Notes" },
                                model: {
                                  value: _vm.newGeofenceNotes,
                                  callback: function ($$v) {
                                    _vm.newGeofenceNotes = $$v
                                  },
                                  expression: "newGeofenceNotes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "modal-footer" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "md-primary dialog-button md-theme-default",
                          on: {
                            click: function ($event) {
                              return _vm.addNewGeofence(false)
                            },
                          },
                        },
                        [_vm._v("\n                    Save\n                ")]
                      ),
                      _c(
                        "md-button",
                        {
                          staticClass:
                            "md-default dialog-button md-theme-default",
                          on: {
                            click: function ($event) {
                              return _vm.cancelNewGeofence(false)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Cancel\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _c("info-window-component", {
            attrs: {
              content: _vm.infoWindowContent,
              styles: _vm.infoWindowStyles,
              "show-info-window": _vm.showInfoWindow,
            },
            on: {
              editGeofence: _vm.editGeofence,
              deleteGeofence: _vm.deleteGeofence,
              closeInfoWindow: function () {
                this$1.showInfoWindow = false
                this$1.geofenceId = null
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }