<template>
    <div v-if="batchFilterList !== null && batchFilterList.length > 0" class="filter-inline batch-filter">
        <vue-select
            class="batch-filter"
            placeholder="Batch Filter"
            :options="batchFilterList"
            label="label"
            :reduce="(x) => x.id"
            v-model="batchFilterValue"
            :searchable="$root.isDesktop"
            :clearable="true"
        ></vue-select>
    </div>
</template>
<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import moment from 'moment';
import { DATE_TYPES } from '@/utils/constants';
import { GeneralMixin } from '@/mixins';

export default {
    name: 'TeamMemberInvoiceBatchList',
    props: {
        dateFrom: {
            type: String,
            default: moment()
                .subtract(14, 'days')
                .format(DATE_TYPES.internationalDate)
        },
        dateTo: {
            type: String,
            default: moment().format(DATE_TYPES.internationalDate)
        }
    },
    data() {
        return {
            batchFilterList: [],
            batchFilterValue: null,
            filters: {
                dateFrom: moment()
                    .subtract(14, 'days')
                    .format(DATE_TYPES.internationalDate),
                dateTo: moment().format(DATE_TYPES.internationalDate),
                driverInvoiceBatchId: null,
                fetchAmount: null
            }
        };
    },
    watch: {
        batchFilterValue(newValue, oldValue) {
            this.$emit('selectedBatchChanged', newValue);
        },
        batchFilterList(newValue, oldValue) {
            this.$emit('batchListUpdated', newValue);
        },
        dateFrom(newValue) {
            if (newValue !== null) {
                this.filters.dateFrom = newValue;
                this.updateBatchList();
            }
        },
        dateTo(newValue) {
            if (newValue !== null) {
                this.filters.dateTo = newValue;
                this.updateBatchList();
            }
        }
    },
    mixins: [GeneralMixin],
    methods: {
        async updateBatchList() {
            this.batchFilterList = null;
            this.$_handleLoaderState(true);

            const params = { ...this.filters };
            const endpoint = `/api/team-member-invoices/team-member-batch-list`;

            try {
                const response = await handleRequests(endpoint, { params });
                if (response !== null && response.data) {
                    this.batchFilterList = response.data.batchFilters.map((item) => {
                        return { id: item.invoiceBatchId, label: this.formatDate(item.startDate, item.endDate) };
                    });
                }
            } catch (e) {
                const message = e.data;
                showErrorMessage(this, message, null);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        formatDate(startDate, endDate) {
            const formattedStartDate = this.$options.filters.dateFormat(startDate, DATE_TYPES.standardDate);
            const formattedEndDate = this.$options.filters.dateFormat(endDate, DATE_TYPES.standardDate);

            return `${formattedStartDate} - ${formattedEndDate}`;
        }
    }
};
</script>
<style lang="scss" scoped>
.filter-inline {
    display: inline-block;
    width: 350px;
}

.batch-filter {
    padding-top: 5px !important;
}
</style>
