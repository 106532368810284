var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _vm.hasTeam
      ? _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Settings"),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "   >    " + _vm._s(_vm.$t("menus.setting.customFields"))
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-blue" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("list_alt")])],
                  1
                ),
              ]
            ),
            _c("md-card-content", [
              _vm.hasTeam
                ? _c(
                    "div",
                    [
                      _vm.hasTeam
                        ? _c(
                            "tabs",
                            {
                              staticClass: "custom-tab-list",
                              class: {
                                "asset-custom-fields-active":
                                  _vm.isAssetCustomFieldsActive,
                              },
                              attrs: {
                                "tab-name": [
                                  "Stop custom fields",
                                  "Shipment custom fields",
                                  "Asset custom fields",
                                ],
                                "color-button": "success",
                              },
                              on: { "switch-panel": _vm.handleTabChanged },
                            },
                            [
                              _c(
                                "template",
                                { slot: "tab-pane-1" },
                                [_c("stop-custom-fields-list")],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "tab-pane-2" },
                                [_c("shipment-custom-fields-list")],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "tab-pane-3" },
                                [_c("asset-custom-fields-list")],
                                1
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }