var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasErrorMessage
    ? _c("md-content", { staticClass: "error-body" }, [
        _c("div", [
          _c("span", [_vm._v("There are items that require your attention")]),
          _c(
            "ul",
            _vm._l(_vm.content, function (c) {
              return _c("li", { key: c.message }, [
                _vm._v(
                  "\n                " + _vm._s(c.message) + "\n            "
                ),
              ])
            }),
            0
          ),
          _c("span", { staticClass: "note" }, [
            _vm._v(
              "\n            **Please edit the file with the correct values and re-upload it.\n        "
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }