<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ maintenanceScheduleData.maintenanceName }}</h4>
        </div>
        <div class="modal-body">
            <div class="details">
                <div
                    v-if="maintenanceScheduleData.timeSchedule != null || maintenanceScheduleData.timeScheduleInterval"
                >
                    <div class="reminder-title">
                        Time Based Reminder
                        <span class="custom-ellipsis">
                            ({{ cronExpressionText }})
                            <md-tooltip v-if="cronExpressionText != null && cronExpressionText.length > 26">
                                {{ cronExpressionText }}
                            </md-tooltip>
                        </span>
                    </div>
                    <div class="reminder-details">
                        <div>
                            <span>Your next due date:</span>
                            <div class="custom-md-field">
                                <md-datepicker
                                    ref="mdDatePicker"
                                    v-model="dueTime"
                                    md-immediately
                                    :md-disabled-dates="disabledDates"
                                    :md-debounce="10"
                                ></md-datepicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="
                        maintenanceScheduleData.engineHoursReminderInterval != null ||
                            maintenanceScheduleData.engineHoursReminderValue != null
                    "
                >
                    <div class="reminder-title">
                        Engine Based Reminder
                        <span v-if="maintenanceScheduleData.engineHoursReminderInterval">
                            (every {{ maintenanceScheduleData.engineHoursReminderInterval }} hours)
                        </span>
                    </div>
                    <div class="reminder-details">
                        <form-wrapper :validator="$v.formData" class="form-wrapper">
                            <div>
                                <span>Current engine hours:</span>
                                <form-group name="currentEngineHoursValue" class="custom-md-field">
                                    <md-input v-model="formData.currentEngineHoursValue" />
                                </form-group>
                            </div>
                            <div>
                                <span>Your next due (in hours):</span>
                                <form-group name="dueEngineHours" class="custom-md-field">
                                    <md-input
                                        v-model="formData.dueEngineHours"
                                        :disabled="maintenanceScheduleData.engineHoursReminderValue != null"
                                    />
                                </form-group>
                            </div>
                        </form-wrapper>
                    </div>
                </div>
                <div
                    v-if="
                        maintenanceScheduleData.odometerReminderInterval != null ||
                            maintenanceScheduleData.odometerReminderValue != null
                    "
                >
                    <div class="reminder-title">
                        Distance Based Reminder
                        <span v-if="maintenanceScheduleData.odometerReminderInterval">
                            (every {{ maintenanceScheduleData.odometerReminderInterval }}
                            {{ maintenanceScheduleData.odometerReminderUnitType }})
                        </span>
                    </div>
                    <div class="reminder-details">
                        <form-wrapper :validator="$v.formData" class="form-wrapper">
                            <div>
                                <span>
                                    Current Odometer Reading ({{ maintenanceScheduleData.odometerReminderUnitType }}):
                                </span>
                                <form-group name="currentOdometerValue" class="custom-md-field">
                                    <md-input v-model="formData.currentOdometerValue" />
                                </form-group>
                            </div>
                            <div>
                                <span>Your next due (in {{ maintenanceScheduleData.odometerReminderUnitType }}):</span>
                                <form-group name="dueDistanceReading" class="custom-md-field">
                                    <md-input
                                        v-model="formData.dueDistanceReading"
                                        :disabled="maintenanceScheduleData.odometerReminderValue != null"
                                    />
                                </form-group>
                            </div>
                        </form-wrapper>
                    </div>
                </div>
            </div>
            <div class="recurring-list">
                <div class="preview-title">Schedule Preview</div>
                <reminder-indicator
                    :maintenance-data="maintenanceScheduleData"
                    :current-engine-hours="
                        formData.currentEngineHoursValue != null ? parseFloat(formData.currentEngineHoursValue) : null
                    "
                    :next-engine-hour-due="formData.dueEngineHours != null ? parseFloat(formData.dueEngineHours) : null"
                    :next-due-distance="
                        formData.dueDistanceReading != null ? parseFloat(formData.dueDistanceReading) : null
                    "
                    :current-distance-reading="
                        formData.currentOdometerValue != null ? parseFloat(formData.currentOdometerValue) : null
                    "
                    :next-due-date="dueTime"
                    :key="counter"
                    :repeat-interval="repeatInterval"
                />
            </div>
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click.stop="attachScheduleToAsset">Apply</md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { DATE_TYPES, SPEED_TO_DISTANCE_UNITS } from '@/utils/constants';
import { handleRequests, showErrorMessage } from '@/helpers';
// eslint-disable-next-line import/extensions
import ReminderIndicator from '@/pages/Maintenance/components/ReminderIndicator';
import cronstrue from 'cronstrue';
import { minValue, decimal, requiredIf } from 'vuelidate/lib/validators';

export default {
    name: 'AssignMaintenanceScheduleModal',
    mixins: [GeneralMixin],
    components: {
        ReminderIndicator
    },
    props: {
        maintenanceScheduleData: {
            type: Object,
            default: () => {}
        },
        asset: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            generalSettings: 'generalSetting/getGeneralSettings'
        })
    },
    validations: {
        formData: {
            dueEngineHours: {
                minValue: minValue(1),
                decimal
            },
            dueDistanceReading: {
                minValue: minValue(1),
                decimal
            },
            currentEngineHoursValue: {
                minValue: minValue(1),
                decimal,
                required:
                    // eslint-disable-next-line func-names
                    requiredIf(function() {
                        return this.maintenanceScheduleData.engineHoursReminderValue != null;
                    })
            },
            currentOdometerValue: {
                minValue: minValue(1),
                decimal,
                required:
                    // eslint-disable-next-line func-names
                    requiredIf(function() {
                        return this.maintenanceScheduleData.odometerReminderValue != null;
                    })
            }
        }
    },
    data() {
        return {
            defaultCurrency: null,
            dueTime: null,
            disabledDates: (date) => {
                const x = moment(date).format(DATE_TYPES.internationalDate);
                const now = moment().format(DATE_TYPES.internationalDate);
                return moment(x).isBefore(now, 'day');
            },
            counter: 0,
            defaultSpeedUnit: 'kph',
            defaultDistanceUnit: 'km',
            nextDueSchedule: null,
            cronExpressionText: null,
            repeatInterval: 4,
            formData: {
                dueEngineHours: null,
                dueDistanceReading: null,
                currentEngineHoursValue: null,
                currentOdometerValue: null
            }
        };
    },
    mounted() {
        if (this.generalSettings.defaultCurrency != null) {
            this.defaultCurrency = this.generalSettings.defaultCurrency;
        }

        if (this.generalSettings.defaultSpeedUnit != null) {
            this.defaultDistanceUnit = SPEED_TO_DISTANCE_UNITS[this.generalSettings.defaultSpeedUnit];
            this.defaultSpeedUnit = this.generalSettings.defaultSpeedUnit;
        }

        if (
            this.maintenanceScheduleData.timeSchedule != null ||
            this.maintenanceScheduleData.timeScheduleInterval != null
        ) {
            if (this.maintenanceScheduleData.timeSchedule) {
                this.cronExpressionText = cronstrue.toString(this.maintenanceScheduleData.timeSchedule);
                if (this.cronExpressionText === 'At 12:00 AM') {
                    this.cronExpressionText = 'Every day';
                } else {
                    // needed to cut the first 13 characters saying "At 12:00 AM,"
                    this.cronExpressionText = this.cronExpressionText.substring(13);
                }
            } else {
                this.cronExpressionText = `every ${this.maintenanceScheduleData.timeScheduleInterval} ${
                    this.maintenanceScheduleData.timeScheduleUnitType
                }`;
            }
        }

        if (this.asset.estimatedEngineHourValue) {
            this.formData.currentEngineHoursValue = parseFloat(this.asset.estimatedEngineHourValue);
        }

        if (this.asset.estimatedOdometerValue) {
            // estimated odometer value returned by the server is dependent on the default distance unit set under the team settings.
            if (
                this.defaultDistanceUnit === 'miles' &&
                this.maintenanceScheduleData.odometerReminderUnitType === 'km'
            ) {
                this.formData.currentOdometerValue = Math.floor(
                    parseFloat(this.asset.estimatedOdometerValue * 1.609344)
                );
            } else if (
                this.defaultDistanceUnit === 'km' &&
                this.maintenanceScheduleData.odometerReminderUnitType === 'miles'
            ) {
                this.formData.currentOdometerValue = Math.floor(
                    parseFloat(this.asset.estimatedOdometerValue * 0.621371192)
                );
            } else {
                this.formData.currentOdometerValue = Math.floor(parseFloat(this.asset.estimatedOdometerValue));
            }
        }

        if (this.maintenanceScheduleData.engineHoursReminderValue) {
            this.formData.dueEngineHours = this.maintenanceScheduleData.engineHoursReminderValue;
            this.repeatInterval = 1;
        }

        if (this.maintenanceScheduleData.odometerReminderValue) {
            this.formData.dueDistanceReading = this.maintenanceScheduleData.odometerReminderValue;
            this.repeatInterval = 1;
        }
    },
    watch: {
        'formData.dueEngineHours': function() {
            this.counter += 1;
        },
        'formData.currentEngineHoursValue': function() {
            this.counter += 1;
        },
        'formData.dueDistanceReading': function() {
            this.counter += 1;
        },
        'formData.currentOdometerValue': function() {
            this.counter += 1;
        },
        dueTime() {
            this.counter += 1;
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        async attachScheduleToAsset() {
            this.$v.formData.$touch();
            if (this.$v.formData.$invalid) {
                return;
            }

            this.$_handleLoaderState(true);
            const data = {
                maintenanceScheduleId: Number(this.maintenanceScheduleData.assetMaintenanceScheduleId),
                currentEngineHoursValue: parseFloat(this.formData.currentEngineHoursValue),
                nextDueEngineHours: parseFloat(this.formData.dueEngineHours),
                currentOdometerValue: parseFloat(this.formData.currentOdometerValue),
                nextDueOdometer: parseFloat(this.formData.dueDistanceReading),
                nextServiceDate:
                    this.dueTime == null
                        ? null
                        : this.$options.filters.dateFormat(this.dueTime, this.DATE_TYPES.internationalDate),
                nextOdometerUnitType: this.defaultDistanceUnit,
                assetId: Number(this.asset.assetId)
            };
            const api = `/api/assets/${this.asset.assetId}/scheduled-maintenance/${
                this.maintenanceScheduleData.assetMaintenanceScheduleId
            }/assign`;
            const payload = {
                method: 'post',
                data
            };

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully attached maintenance to asset',
                    type: 'success'
                });
                this.$_handleLoaderState(false);
                this.resolve(data);
            } catch (e) {
                const message = 'Could not attach a maintenance schedule to this asset';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 850px;
}

.modal-header {
    text-align: center;
    display: block;
}

.modal-body {
    max-height: 80vh;
    > div {
        display: inline-block;
        vertical-align: top;
        padding: 0 20px;
    }

    .recurring-list {
        min-width: 350px;
        max-width: 450px;
        border-left: 1px solid #eee;

        .preview-title {
            margin-bottom: 20px;
            text-align: center;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 15px;
        }
    }
}

.reminder-title {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15px;
    > span {
        font-weight: initial;
        max-width: 200px;
    }
}

.reminder-details {
    margin-bottom: 20px;
    span {
        width: 250px;
        display: inline-block;
    }
}

.custom-md-field {
    display: inline-block;
    max-width: 150px;
    input {
        text-align: center;
    }
}

.preview-container {
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    > div {
        margin-bottom: 5px;
    }
    .material-icons {
        vertical-align: middle;
    }
}

::v-deep .reminder-indicators {
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
}

::v-deep .reminder-indicators:last-child {
    border-bottom: none;
}
</style>
