var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-table",
    { staticClass: "custom-paginated-table" },
    [
      _c(
        "md-table-row",
        [
          _c("md-table-head", [_vm._v("Business Name")]),
          _c("md-table-head", [_vm._v("Business Number")]),
          _c("md-table-head", [_vm._v("Address")]),
          _c("md-table-head", [_vm._v("Company Number")]),
          _c("md-table-head", [_vm._v("Brand")]),
        ],
        1
      ),
      _vm._l(_vm.businessEntitiesList, function (entity) {
        return _c(
          "md-table-row",
          { key: entity.businessEntityId },
          [
            _c(
              "md-table-cell",
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "Business Entity Details",
                        params: { businessEntityId: entity.businessEntityId },
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(entity.businessRegistrationName) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("md-table-cell", { staticClass: "business-number-cell" }, [
              _vm._v(
                "\n            " +
                  _vm._s(entity.businessRegistrationNumber) +
                  "\n        "
              ),
            ]),
            _c("md-table-cell", [
              _vm._v(
                "\n            " + _vm._s(entity.businessAddress) + "\n        "
              ),
            ]),
            _c("md-table-cell", [
              _vm._v(
                "\n            " + _vm._s(entity.companyNumber) + "\n        "
              ),
            ]),
            _c("md-table-cell", [
              _vm._v(
                "\n            " + _vm._s(entity.brandName) + "\n        "
              ),
            ]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }