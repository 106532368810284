<template>
    <div class="detail-container">
        <div class="close-button">
            <md-button class="md-default md-just-icon md-round pull-right" @click="closeModal">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <div v-if="!loading">
            <div class="detail-section text-center">
                <span class="title">{{ ruleDetails.description }}</span>
            </div>
            <div class="line-separator"></div>
            <div class="detail-section">
                <span class="title">Invoice Adjustment Rule Details</span>
                <div class="detail-group">
                    <label class="label">Rule Type:</label>
                    <span class="value">{{ ruleDetails.invoiceAdjustmentRuleTypeName }}</span>
                </div>
                <div class="detail-group">
                    <label class="label">Tax Settings:</label>
                    <span class="value">{{ displayNullableString(ruleDetails.taxSettingsDescription) }}</span>
                </div>
                <div class="detail-group">
                    <label class="label">Description:</label>
                    <span class="value">{{ ruleDetails.description }}</span>
                </div>
                <div class="detail-group">
                    <label class="label">Unit Price:</label>
                    <span class="value" v-if="user.teamCurrency">
                        {{ ruleDetails.unitPrice | currency(user.teamCurrency) }}
                    </span>
                    <span class="value" v-else>{{ formatCurrency(ruleDetails.unitPrice) }}</span>
                </div>
                <div class="detail-group">
                    <label class="label">Account Code:</label>
                    <span class="value">{{ displayNullableString(ruleDetails.accountCode) }}</span>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="sidebar-loader">
                <fade-loader :loading="loading" color="#333333" />
                <span>LOADING</span>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { GeneralMixin, MapOverviewMixin } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
    name: 'InvoiceAdjustmentRuleSidebar',
    mixins: [GeneralMixin, MapOverviewMixin],
    components: {
        FadeLoader
    },
    props: {
        invoiceAdjustmentRuleId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            ruleDetails: {},
            loading: false
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    watch: {
        async invoiceAdjustmentRuleId(newValue, oldValue) {
            await this.getRuleDetails(newValue);
        }
    },
    async mounted() {
        await this.getRuleDetails(this.invoiceAdjustmentRuleId);
    },
    methods: {
        async getRuleDetails(invoiceAdjustmentRuleId) {
            try {
                this.loading = true;
                const api = `/api/invoice-adjustment-rules/${invoiceAdjustmentRuleId}`;
                const { data } = await handleRequests(api);
                Object.assign(this.ruleDetails, data);
                this.loading = false;
            } catch (error) {
                const message = 'Error in getting the invoice adjustment rule details';
                showErrorMessage(this, message, error);
            }
        },
        closeModal() {
            this.$emit('closeModal');
        },
        displayNullableString(val) {
            return val && val.trim().length > 0 ? val : '-';
        },
        formatCurrency(value) {
            const code = this.user.regionCode ? `en-${this.user.regionCode}` : 'en-AU';
            const formatter = new Intl.NumberFormat(code, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            return formatter.format(value);
        }
    }
};
</script>

<style lang="scss" scoped>
.detail-container {
    position: fixed;
    top: 2vh;
    right: 10px;
    height: 96vh;
    width: 25%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    background: white;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 16px;
    .close-button {
        top: 0;
        right: 0;
        position: absolute;
        z-index: 999;
    }
    .md-button.md-just-icon,
    ::v-deep .md-button.md-just-icon {
        font-size: 15px;
        height: 30px;
        min-width: 3px;
        width: 30px;
        line-height: 30px;
        margin: 2.5px;
    }

    .detail-section {
        padding-top: 10px;
        .blue-ref a {
            color: #2b93ff !important;
        }

        .title {
            text-transform: uppercase;
            font-weight: 600;
            padding: 2px 16px;
            display: block;
            font-size: 14px;
        }

        ::v-deep .custom-a-blue a {
            color: #2b93ff !important;
        }
    }

    .line-separator {
        height: 1px;
        background-color: #ddd;
        width: calc(100% - 32px);
        margin: 10px auto;
        margin-bottom: 0px;
    }

    .detail-group {
        padding: 2px 16px;
        margin: 5px 0;
        font-size: 0.9em;
        .label {
            display: inline-block;
            width: 100px;
            margin-right: 4px;
            vertical-align: top;
            font-weight: 400;
        }

        .value {
            display: inline-block;
            width: calc(100% - 105px);
            vertical-align: top;
            a {
                color: #2b93ff;
            }
        }
    }
}

.sidebar-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

.detail-section .address {
    cursor: pointer;
}
.brand-logo {
    max-height: 50px;
    padding: 2px 16px;
    display: block;
}

.md-chip:first-child {
    margin-top: 0;
}
</style>
