<template>
    <md-content v-if="hasErrorMessage" class="error-body">
        <div>
            <span>There are items that require your attention</span>
            <ul>
                <li v-for="c in content" :key="c.message">
                    {{ c.message }}
                </li>
            </ul>
            <span class="note">
                **Please edit the file with the correct values and re-upload it.
            </span>
        </div>
    </md-content>
</template>

<script>
export default {
    name: 'BatchUploadErrorMessage',
    props: {
        hasErrorMessage: Boolean,
        content: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';
.error-body {
    margin-bottom: 20px;
    background-color: transparent !important;
    div {
        // padding: 20px;
        margin: 15px;
        background-color: #fff;
    }

    span {
        font-weight: 600;
        font-size: 15px;
        color: $locate2u-red;
    }

    ul {
        font-size: 15px;
    }

    .note {
        font-size: 12px;
        font-weight: 100;
        color: black;
    }
}
</style>
