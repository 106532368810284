<template>
    <drop-down
        v-if="!isReadOnlyUser"
        :should-close="shouldCloseDropdown"
        @closeDropdown="(val) => (this.shouldCloseDropdown = val)"
        @closeOtherDropDown="closeOtherDropDown"
        :reduce="(inv) => inv.customerInvoiceId"
    >
        <span v-if="isBulk" class="status bulk-status" slot="title" data-toggle="dropdown">
            CHANGE PAYMENT STATUS
        </span>
        <span
            v-else
            class="status"
            slot="title"
            data-toggle="dropdown"
            :class="`payment-status-${invoiceDetails.status.toLowerCase().replace(/\s+/g, '')} ${className}`"
        >
            {{ $t('inputs.paymentStatuses.' + invoiceDetails.status) }}
        </span>
        <ul class="dropdown-menu">
            <li v-for="(status, index) in statuses" :key="index">
                <a @click.stop="handleChangePaymentStatus(status.statusId)">
                    {{ status.statusValue }}
                </a>
            </li>
        </ul>
    </drop-down>
    <span
        v-else
        class="status"
        :class="`payment-status-${invoiceDetails.status.toLowerCase().replace(/\s+/g, '')} ${className}`"
    >
        {{ $t('inputs.paymentStatuses.' + invoiceDetails.status) }}
    </span>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import { PAYMENT_STATUS_CONSTANTS } from '@/utils/constants';

export default {
    name: 'PaymentStatusButton',
    mixins: [GeneralMixin],
    props: {
        invoiceDetails: {
            type: Object,
            default: () => undefined
        },
        className: {
            type: String,
            default: () => ''
        },
        isBulk: {
            type: Boolean,
            default: false
        },
        bulkCustomerInvoiceIds: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            oldStatus: null,
            statuses: this.getStatuses(PAYMENT_STATUS_CONSTANTS),
            shouldCloseDropdown: false
        };
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    mounted() {
        if (!this.isBulk) 
            this.oldStatus = this.invoiceDetails.status;
    },
    methods: {
        getStatuses(arr) {
            const stats = arr.map((x) => ({
                statusId: x,
                statusValue: this.$t(`inputs.paymentStatuses.${x}`)
            }));

            return stats;
        },
        async getInvoiceDetails(invoiceId) {
            try {
                const api = `/api/customer-invoices/${invoiceId}`;
                const { data } = await handleRequests(api);

                return data;
            } catch (error) {
                const message = 'Error in getting the invoice details';
                showErrorMessage(this, message, error);
            }
            return null;
        },
        async handleChangePaymentStatus(newStatus) {
            this.shouldCloseDropdown = true;
            if (this.isBulk) {
                this.handleBulk(newStatus);
                return;
            }

            if (this.invoiceDetails.status !== newStatus) {
                this.$emit('statusUpdated', {
                    customerInvoiceId: this.invoiceDetails.customerInvoiceId,
                    status: newStatus
                });
                const payload = {
                    method: 'post',
                    data: { newStatus }
                };

                // to toggle the context menu
                this.$nextTick(() => {
                    this.shouldCloseDropdown = false;
                });

                try {
                    const api = `/api/customer-invoices/${this.invoiceDetails.customerInvoiceId}/change-status`;
                    await handleRequests(api, payload);

                    this.$notify({
                        message: `Invoice status was changed to ${this.$t(`inputs.paymentStatuses.${newStatus}`)}!`,
                        type: 'success'
                    });

                    this.$emit('statusUpdated', {
                        customerInvoiceId: this.invoiceDetails.customerInvoiceId,
                        status: newStatus
                    });
                } catch (e) {
                    const message = 'Cannot change invoice status.';
                    showErrorMessage(this, message, e);
                    this.$emit('statusUpdated', {
                        customerInvoiceId: this.invoiceDetails.customerInvoiceId,
                        status: this.oldStatus
                    });
                }
            } else {
                const message = 'Cannot change status to the same status';
                this.$notify({
                    message,
                    type: 'danger'
                });
            }
        },
        async handleBulk(newStatus) {
            this.$_handleLoaderState(true, 'UPDATING...');
            try {
                const payload = {
                    method: 'post',
                    data: {
                        invoices: this.bulkCustomerInvoiceIds,
                        newStatus
                    }
                };
                const api = `/api/customer-invoices/bulk/change-status`;
                await handleRequests(api, payload);
                this.$emit('invoiceBulkStatusUpdate', true);

                this.$notify({
                    message: `Successfully updated the invoices to ${this.$t(`inputs.paymentStatuses.${newStatus}`)}!`,
                    type: 'success'
                });
                this.$_handleLoaderState(false);
            } catch (e) {
                const message = 'Cannot change invoice status.';
                showErrorMessage(this, message, e);
                this.$emit('invoiceBulkStatusUpdate', false);
                this.$_handleLoaderState(false);
            }
        },
        closeOtherDropDown() {
            const dropdowns = document.querySelectorAll('.open');
            dropdowns.forEach((dropdown) => dropdown.classList.remove('open'));
        }
    }
};
</script>

<style lang="scss" scoped>
.status {
    color: #fff !important;
    min-width: 140px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 140px;
    cursor: pointer;
}

::v-deep .dropdown-menu {
    li {
        cursor: pointer !important;
    }
}

.bulk-status {
    width: 100%;
    min-width: initial;
    max-width: 100%;
    background-color: #00bcd4;
}
</style>
