var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout team-member-list" }, [
    _c("div", { staticClass: "md-layout team-member-tools" }, [
      _c("div", { staticClass: "md-layout-item md-size-20" }, [
        _c("ul", { staticClass: "team-member-list-ui" }, [
          _c(
            "li",
            { staticClass: "team-member-list-li-select-all" },
            [
              _c(
                "md-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.onSelectAll(_vm.isSelectAll)
                    },
                  },
                  model: {
                    value: _vm.isSelectAll,
                    callback: function ($$v) {
                      _vm.isSelectAll = $$v
                    },
                    expression: "isSelectAll",
                  },
                },
                [_vm._v("Select All")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-40 search-team-member" },
        [
          _c(
            "div",
            { staticClass: "search-icon" },
            [_c("md-icon", [_vm._v("search")])],
            1
          ),
          _c("search-component", {
            attrs: {
              "handle-search-on-type": true,
              "search-icon": false,
              placeholder: _vm.placeholderText,
            },
            on: { onSearch: _vm.handleSearch },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout md-layout-item md-size-40 filter-link" },
        [
          _c(
            "div",
            { staticClass: "md-layout-item filter-menu-dropdown md-size-65" },
            [
              _c("drop-down", { attrs: { direction: "down" } }, [
                _c(
                  "a",
                  { attrs: { href: "#" } },
                  [
                    _c("md-icon", { staticClass: "icon-filter" }, [
                      _vm._v("filter_alt"),
                    ]),
                    _vm._v(
                      "\n                        Filter\n                    "
                    ),
                  ],
                  1
                ),
                _c("ul", { staticClass: "dropdown-menu" }, [
                  _vm.showTeamRegion
                    ? _c("li", { staticClass: "dropdown-child-menu" }, [
                        _c(
                          "a",
                          { attrs: { href: "#" } },
                          [
                            _vm._v(
                              "\n                                By Team Region\n                                "
                            ),
                            _c("md-icon", { staticClass: "icon-arrow" }, [
                              _vm._v("navigate_next"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "ul",
                          _vm._l(
                            _vm.teamRegionMenuOptions,
                            function (teamRegionMenu, index) {
                              return _c(
                                "li",
                                { key: index },
                                [
                                  _c(
                                    "md-icon",
                                    {
                                      class: [
                                        "icon-check",
                                        teamRegionMenu.checked
                                          ? "icon-check-color"
                                          : "",
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        check\n                                    "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleFilterTeamRegionClick(
                                            teamRegionMenu
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(teamRegionMenu.name) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item filter-menu md-size-35" },
            [
              _c("drop-down", { attrs: { direction: "down" } }, [
                _c(
                  "a",
                  { attrs: { href: "#" } },
                  [
                    _c("md-icon", { staticClass: "icon-filter" }, [
                      _vm._v("settings"),
                    ]),
                    _vm._v(
                      "\n                        Options\n                    "
                    ),
                  ],
                  1
                ),
                _c("ul", { staticClass: "dropdown-menu" }, [
                  _c(
                    "li",
                    { staticClass: "separator" },
                    [
                      _c(
                        "md-checkbox",
                        {
                          model: {
                            value:
                              _vm.optimiseConfiguration.includeAssignedStops,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.optimiseConfiguration,
                                "includeAssignedStops",
                                $$v
                              )
                            },
                            expression:
                              "optimiseConfiguration.includeAssignedStops",
                          },
                        },
                        [
                          _vm._v(
                            "\n                                Include assigned stops in routing\n                            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "md-radio",
                        {
                          attrs: { value: false },
                          model: {
                            value:
                              _vm.optimiseConfiguration.isDistributeWorkload,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.optimiseConfiguration,
                                "isDistributeWorkload",
                                $$v
                              )
                            },
                            expression:
                              "optimiseConfiguration.isDistributeWorkload",
                          },
                        },
                        [
                          _vm._v(
                            "\n                                Minimise team members required\n                            "
                          ),
                        ]
                      ),
                      _c(
                        "md-radio",
                        {
                          attrs: { value: true },
                          model: {
                            value:
                              _vm.optimiseConfiguration.isDistributeWorkload,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.optimiseConfiguration,
                                "isDistributeWorkload",
                                $$v
                              )
                            },
                            expression:
                              "optimiseConfiguration.isDistributeWorkload",
                          },
                        },
                        [
                          _vm._v(
                            "\n                                Spread workload evenly\n                            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "md-layout-item md-size-100",
        class: _vm.isLoading ? "loader-margin" : "",
      },
      [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "loader" },
              [
                _c("fade-loader", {
                  attrs: { loading: _vm.isLoading, color: "#333333" },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("md-table", {
          staticClass: "team-member-table",
          attrs: { value: _vm.orderedTeamMembers },
          scopedSlots: _vm._u([
            {
              key: "md-table-row",
              fn: function (ref) {
                var item = ref.item
                return _c(
                  "md-table-row",
                  {
                    class:
                      item.routeDetails == null &&
                      _vm.startLocationMode === "teammembers"
                        ? "team-member-disabled"
                        : "",
                  },
                  [
                    _c(
                      "md-table-cell",
                      { staticClass: "team-member-checkbox" },
                      [
                        item.routeDetails == null &&
                        _vm.startLocationMode === "teammembers"
                          ? _c(
                              "md-avatar",
                              [
                                _c("md-icon", { staticClass: "icon-warning" }, [
                                  _vm._v("warning"),
                                ]),
                                _c(
                                  "md-tooltip",
                                  {
                                    staticClass: "tooltip-width",
                                    attrs: { "md-direction": "right" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            This team member cannot be included because they have no start location set\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("md-checkbox", {
                              attrs: {
                                type: "checkbox",
                                value: item.publicUserId,
                              },
                              model: {
                                value: _vm.selectedTeamMembers,
                                callback: function ($$v) {
                                  _vm.selectedTeamMembers = $$v
                                },
                                expression: "selectedTeamMembers",
                              },
                            }),
                      ],
                      1
                    ),
                    _c("md-table-cell", { staticClass: "team-member-image" }, [
                      item.photoUrl
                        ? _c("img", {
                            staticClass: "profile-image",
                            attrs: { src: item.photoUrl, alt: "" },
                            on: { error: _vm.$_setDefaultBrokenImage },
                          })
                        : _c("img", {
                            staticClass: "member-image",
                            attrs: { src: _vm.$root.defaultPhotoUrl, alt: "" },
                          }),
                    ]),
                    _c(
                      "md-table-cell",
                      { staticClass: "team-member-text" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "Team Member Profile",
                                params: { publicUserId: item.publicUserId },
                              },
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.fullName) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    item.tripDetails
                      ? _c(
                          "md-table-cell",
                          { staticClass: "team-member-customer" },
                          [
                            item.tripDetails.customerName
                              ? _c(
                                  "md-avatar",
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "tooltip-place" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              item.tripDetails.customerName
                                                .length > 13
                                                ? item.tripDetails.customerName.slice(
                                                    0,
                                                    13
                                                  ) + " ..."
                                                : item.tripDetails.customerName
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "md-tooltip",
                                      {
                                        staticClass: "warning-tooltip",
                                        attrs: { "md-direction": "right" },
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            "\n                                All stops that get routed to\n                                "
                                          ),
                                          _c("b", [
                                            _vm._v(_vm._s(item.fullName)),
                                          ]),
                                          _vm._v(
                                            "\n                                will have the customer updated to match the trip customer:\n                                "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                item.tripDetails.customerName
                                              )
                                            ),
                                          ]),
                                          _vm._v(
                                            "\n                                .\n                            "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.tripDetails
                      ? _c(
                          "md-table-cell",
                          { staticClass: "team-member-place" },
                          [
                            _c(
                              "md-avatar",
                              [
                                _c("md-icon", { staticClass: "icon-place" }, [
                                  _vm._v("place"),
                                ]),
                                _c("span", { staticClass: "tooltip-place" }, [
                                  _vm._v(
                                    _vm._s(item.tripDetails.stopsCount) +
                                      " Stops"
                                  ),
                                ]),
                                _c(
                                  "md-tooltip",
                                  {
                                    staticClass: "tooltip-width",
                                    attrs: { "md-direction": "right" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.firstName) +
                                        " already has " +
                                        _vm._s(item.tripDetails.stopsCount) +
                                        " stops assigned for\n                            their trip on this date\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.tripDetails
                      ? _c(
                          "md-table-cell",
                          { staticClass: "team-member-warning" },
                          [
                            _c(
                              "md-avatar",
                              [
                                _c("md-icon", { staticClass: "warning-icon" }, [
                                  _vm._v("warning"),
                                ]),
                                _c(
                                  "md-tooltip",
                                  {
                                    staticClass: "warning-tooltip",
                                    attrs: { "md-direction": "right" },
                                  },
                                  [
                                    item.tripDetails.startTime !=
                                      _vm.optimiseConfiguration.startTime ||
                                    item.tripDetails.endTime !=
                                      _vm.optimiseConfiguration.endTime
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v(_vm._s(item.firstName)),
                                          ]),
                                          _vm._v(
                                            "\n                                has their availability set to\n                                "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("timeFormat")(
                                                  item.tripDetails.startTime,
                                                  _vm.DATE_TYPES.standardTime,
                                                  true
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(
                                            "\n                                to\n                                "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("timeFormat")(
                                                  item.tripDetails.endTime,
                                                  _vm.DATE_TYPES.standardTime,
                                                  true
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(
                                            "\n                                for this date, which differs from the\n                                "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm._f("timeFormat")(
                                                    _vm.optimiseConfiguration
                                                      .startTime,
                                                    _vm.DATE_TYPES.standardTime,
                                                    true
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]),
                                          _vm._v(
                                            "\n                                to\n                                "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm._f("timeFormat")(
                                                    _vm.optimiseConfiguration
                                                      .endTime,
                                                    _vm.DATE_TYPES.standardTime,
                                                    true
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]),
                                          _vm._v(
                                            "\n                                timeframe set in the optimise settings.\n                                "
                                          ),
                                          _c("br"),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(item.firstName) + "'s"
                                            ),
                                          ]),
                                          _vm._v(
                                            "\n                                availability timeframe will be used when creating their trip. Alternatively, check\n                                the box below the trip list to override the team members existing trip settings with\n                                the optimise settings.\n                            "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.optimiseConfiguration
                                      .startLocationMode === "manual" &&
                                    item.tripDetails.startAddress !=
                                      _vm.optimiseConfiguration.startLocation
                                        .address
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v(_vm._s(item.firstName)),
                                          ]),
                                          _vm._v(
                                            "\n                                already has a trip for this date, with their start location set to\n                                "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                item.tripDetails.startAddress
                                              )
                                            ),
                                          ]),
                                          _vm._v(
                                            "\n                                , which differs from the start location set in the optimise settings (\n                                "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.optimiseConfiguration
                                                  .startLocation.address
                                              )
                                            ),
                                          ]),
                                          _vm._v(
                                            "\n                                ).\n                                "
                                          ),
                                          _c("br"),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(item.firstName) + "'s"
                                            ),
                                          ]),
                                          _vm._v(
                                            "\n                                start location will be used when creating their trip. Alternatively, check the box\n                                below the trip list to override the team members existing trip settings with the\n                                optimise settings.\n                            "
                                          ),
                                        ])
                                      : item.tripDetails.endAddress !=
                                          _vm.optimiseConfiguration.endLocation
                                            .address &&
                                        _vm.optimiseConfiguration
                                          .endLocationMode != "none"
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v(_vm._s(item.firstName)),
                                          ]),
                                          _vm._v(
                                            "\n                                already has a trip for this date, with their end location set to\n                                "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                item.tripDetails.endAddress
                                              )
                                            ),
                                          ]),
                                          _vm._v(
                                            "\n                                , which differs from the end location set in the optimise settings (\n                                "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.optimiseConfiguration
                                                  .endLocation.address
                                              )
                                            ),
                                          ]),
                                          _vm._v(
                                            "\n                                ).\n                                "
                                          ),
                                          _c("br"),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(item.firstName) + "'s"
                                            ),
                                          ]),
                                          _vm._v(
                                            "\n                                end location will be used when creating their trip. Alternatively, check the box\n                                below the trip list to override the team members existing trip settings with the\n                                optimise settings.\n                            "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }