var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calendar-parent" },
    [
      _vm.requestOptions.type === "timeline"
        ? _c(
            "div",
            { staticClass: "filter-steps-container" },
            [
              _vm.canViewTeamRegion
                ? _c("team-region-member-filter-options", {
                    attrs: {
                      "team-region-id": _vm.teamRegionId,
                      "strict-team-region-filter": false,
                      "show-team-member": false,
                      clearable: false,
                    },
                    on: { changed: _vm.handleTeamRegionMemberChanged },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "custom-filter-container" }, [
        _vm.hasMemberSelected && !_vm.isSingleUser && !_vm.isSingleTeamMember
          ? _c(
              "div",
              { staticClass: "filter-select" },
              [
                _c("vue-select", {
                  attrs: {
                    label: "fullName",
                    reduce: function (member) {
                      return member.publicUserId
                    },
                    options: _vm.members,
                    searchable: _vm.$root.isDesktop,
                  },
                  model: {
                    value: _vm.selectedTeamMemberId,
                    callback: function ($$v) {
                      _vm.selectedTeamMemberId = $$v
                    },
                    expression: "selectedTeamMemberId",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          [
            _c("batch-upload", {
              attrs: {
                title: "Import Stops",
                "template-type": "stops",
                "team-members": _vm.members,
              },
              on: { batchImported: _vm.handleBatchImported },
            }),
            _c("create-stop", {
              attrs: {
                "team-members": _vm.members,
                "selected-member-id": _vm.selectedTeamMemberId,
              },
              on: { stopCreated: _vm.handleStopCreated },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "calendar-section",
              class: [
                { calendarOverlay: _vm.isStopDetailsLoading },
                _vm.isShowSidebar ? "show-sidebar" : "",
              ],
            },
            [
              _c("calendar-widget", {
                attrs: {
                  members: _vm.members,
                  events: _vm.events,
                  "has-member-selected": _vm.hasMemberSelected,
                },
                on: {
                  getStopInfo: _vm.showStopDetailsSidebar,
                  eventAdded: _vm.handleEventDrop,
                  dateChange: _vm.handleDateChange,
                  eventCalendarDrop: _vm.handleCalendarDrop,
                  eventCalendarResize: _vm.handleCalendarResize,
                  eventMouseHover: _vm.handleMouseHover,
                },
              }),
            ],
            1
          ),
          _c("slide-x-right-transition", { attrs: { duration: 500 } }, [
            _vm.isShowSidebar
              ? _c(
                  "div",
                  {
                    staticClass: "calendar-sidebar-container",
                    class: _vm.isShowSidebar ? "show-sidebar" : "",
                  },
                  [
                    _c("stop-calendar-sidebar", {
                      attrs: {
                        "remove-stop-id": _vm.removeStopId,
                        "unassigned-stops": _vm.unassignedStopsData,
                        "is-loading": _vm.isSidebarLoading,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm.showStopDetails
        ? _c("stop-sidebar", {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.toggleStopDetailsWindow,
                expression: "toggleStopDetailsWindow",
              },
            ],
            attrs: {
              data: _vm.stopDetails,
              "stop-id": _vm.stopId,
              "team-members": _vm.members,
            },
            on: {
              closeModal: _vm.toggleStopDetailsWindow,
              changeStopStatus: _vm.stopStatusUpdated,
              assignStop: _vm.onAssignedUser,
              updateStop: _vm.stopUpdated,
              deleteStop: _vm.stopDeleted,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }