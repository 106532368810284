var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-shipment" },
    [
      _c(
        "md-button",
        {
          class: _vm.useIcon
            ? "md-warning md-just-icon md-round"
            : "" + _vm.className,
          attrs: {
            title: "Edit shipment details",
            disabled:
              _vm.shipmentStatus &&
              _vm.shipmentStatus.toLowerCase() === "cancelled",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleUpdateShipment($event)
            },
          },
        },
        [
          _vm.useIcon
            ? _c("md-icon", [_vm._v("edit")])
            : _c("span", [_vm._v("Edit")]),
        ],
        1
      ),
      _vm.shipmentStatus && _vm.shipmentStatus.toLowerCase() === "cancelled"
        ? _c(
            "md-tooltip",
            { staticClass: "line-tooltip", attrs: { "md-direction": "top" } },
            [
              _vm._v(
                "\n        You cannot edit a shipment when it is marked as Cancelled\n    "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }