var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-toolbar state-filter" }, [
    _c(
      "div",
      { staticClass: "custom-toolbar-end" },
      [
        _vm.approvalStatusList.length > 0
          ? _c(
              "md-field",
              [
                _c("vue-select", {
                  attrs: {
                    options: _vm.approvalStatusList,
                    label: "name",
                    placeholder: "Approval Status",
                    searchable: _vm.$root.isDesktop,
                  },
                  model: {
                    value: _vm.approvalStatusFilterValue,
                    callback: function ($$v) {
                      _vm.approvalStatusFilterValue = $$v
                    },
                    expression: "approvalStatusFilterValue",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }