<template>
    <LoginRedirect />
</template>

<script>
import LoginRedirect from '../../../components/LoginRedirect';

export default {
    components: { LoginRedirect },
    async created() {
        try {
            const result = await window.auth.loginCallback();

            let returnToUrl = '/';

            if (result.state !== undefined) {
                if (!result.state.startsWith('/login')) 
                    returnToUrl = result.state;
            } else {
                this.$root.isAuthenticated = true;
            }
            if (localStorage.getItem('invitationUrl') != null) {
                returnToUrl = localStorage.getItem('invitationUrl');
                localStorage.removeItem('invitationUrl');
                this.$router.push({ path: returnToUrl });
            } else {
                await this.$store.dispatch('team/FETCH_TEAM_MEMBERS');
                this.$store.dispatch('user/FETCH_USER').then((response) => {
                    if (
                        (response.onboardingSettings == null ||
                            (response.onboardingSettings != null && !response.onboardingSettings.isSkipped)) &&
                        returnToUrl === '/'
                    ) {
                        this.$router.push({ path: '/support/onboarding' });
                    } else {
                        this.$router.push({ path: returnToUrl });
                    }
                });
            }
        } catch (e) {
            this.$router.push({ name: 'Unauthorized' });
        }
    }
};
</script>
