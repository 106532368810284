<template>
    <span>
        <router-link :to="linkPath" class="custom-a-blue">{{ linkText }}</router-link>
    </span>
</template>

<script>
export default {
    name: 'GridLinkRenderer',
    data() {
        return {
            linkPath: '',
            linkText: ''
        };
    },
    beforeMount() {
        this.linkPath = this.params.value.linkPath;
        this.linkText = this.params.value.linkText;
    }
};
</script>
