<template>
    <div class="customer-dashboard">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-end">
                        <search-component ref="search" @onSearch="handleSearch" />
                        <div class="header-button-container">
                            <md-button
                                title="Create Invoice Adjustment Rule"
                                class="md-primary md-just-icon md-round"
                                @click="createRule"
                            >
                                <md-icon>library_add</md-icon>
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon running-load-menu">
                            <drop-down direction="down">
                                <a href="#" class="load-header-text">
                                    <md-icon>receipt</md-icon>
                                    {{ selectedOption != null ? selectedOption.label : '' }}
                                    <md-icon class="icon-arrow">expand_more</md-icon>
                                </a>
                                <ul class="dropdown-menu">
                                    <li v-for="item in dropdownOptions" :key="item.key">
                                        <a @click="handleSelectedOption(item)">
                                            {{ item.label }}
                                        </a>
                                    </li>
                                </ul>
                            </drop-down>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div v-if="isListLoading">
                            <div class="table-loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="ruleList && ruleList.length > 0">
                                <md-table class="context-menu-support custom-paginated-table">
                                    <md-table-row>
                                        <md-table-head>Description</md-table-head>
                                        <md-table-head>Unit Price</md-table-head>
                                        <md-table-head>Rule Type</md-table-head>
                                        <md-table-head>Actions</md-table-head>
                                    </md-table-row>
                                    <md-table-row
                                        v-for="item in ruleList"
                                        :key="item.invoiceAdjustmentRuleId"
                                        @click.stop="showSidebar(item.invoiceAdjustmentRuleId)"
                                    >
                                        <md-table-cell>{{ item.description }}</md-table-cell>
                                        <md-table-cell v-if="user.teamCurrency">
                                            {{ item.unitPrice | currency(user.teamCurrency) }}
                                        </md-table-cell>
                                        <md-table-cell v-else>{{ formatCurrency(item.unitPrice) }}</md-table-cell>
                                        <md-table-cell>{{ item.invoiceAdjustmentRuleTypeName }}</md-table-cell>
                                        <md-table-cell>
                                            <div class="actions">
                                                <md-button
                                                    title="Update rule details"
                                                    class="md-warning md-just-icon md-round margin-right"
                                                    @click.stop="updateRule(item.invoiceAdjustmentRuleId)"
                                                >
                                                    <md-icon>edit</md-icon>
                                                </md-button>
                                                <md-button
                                                    title="Delete rule"
                                                    class="md-danger md-just-icon md-round"
                                                    @click.stop="handleDeleteRule(item.invoiceAdjustmentRuleId)"
                                                >
                                                    <md-icon>delete</md-icon>
                                                </md-button>
                                            </div>
                                        </md-table-cell>
                                    </md-table-row>
                                </md-table>
                            </div>
                            <div v-else-if="!isLoading">
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
                <md-card-actions class="page-footer" md-alignment="space-between">
                    <div>
                        <p v-if="total === pagination.perPage" class="card-category">
                            Page {{ pagination.currentPage }} of many
                        </p>
                        <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                    </div>
                    <pagination
                        v-model="pagination.currentPage"
                        class="pagination-no-border pagination-success"
                        :per-page="pagination.perPage"
                        :total="total"
                        @change-page="handleChangePage($event, pagination.perPage)"
                    />
                </md-card-actions>
            </div>

            <transition name="slide">
                <invoice-adjustment-rule-sidebar
                    v-if="isShowSidebar"
                    :invoice-adjustment-rule-id="invoiceAdjustmentRuleId"
                    v-click-outside="toggleSidebarWindow"
                    @closeModal="toggleSidebarWindow"
                />
            </transition>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { PAGINATION_DEFAULTS, INVOICE_ADJUSTMENT_RULE } from '@/utils/defaults';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { Pagination, SearchComponent } from '@/components';
import { mapGetters } from 'vuex';
import { INVOICE_ADJUSTMENT_HEADER_DROPDOWN_CONSTANTS } from '@/utils/constants';
import InvoiceAdjustmentRuleSidebar from './InvoiceAdjustmentRuleSidebar';
import CreateInvoiceAdjustmentRuleModal from './Modals/CreateInvoiceAdjustmentRuleModal';

export default {
    name: 'InvoiceAdjustmentRuleOverview',
    mixins: [GeneralMixin],
    components: {
        FadeLoader,
        Pagination,
        SearchComponent,
        InvoiceAdjustmentRuleSidebar
    },
    data() {
        return {
            ruleList: [],
            isListLoading: false,
            pagination: PAGINATION_DEFAULTS,
            filters: { status: 'active', teamRegionId: null },
            isShowSidebar: false,
            invoiceAdjustmentRuleId: null,
            selectedFile: null,
            hasError: false,
            errorMessages: [],
            invoiceAdjustmentRuleDetails: Object.assign({}, INVOICE_ADJUSTMENT_RULE()),
            dropdownOptions: [...INVOICE_ADJUSTMENT_HEADER_DROPDOWN_CONSTANTS],
            selectedOption: INVOICE_ADJUSTMENT_HEADER_DROPDOWN_CONSTANTS[0]
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isLoading: 'isLoading'
        }),
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        }
    },
    async mounted() {
        this.$_handleLoaderState(true);
        const currentPage = Number(this.$route.query.currentPage) || 1;
        if (currentPage) {
            this.pagination.currentPage = currentPage;
        }

        const {
            data: { invoiceAdjustmentRules, totalRules }
        } = await this.getRuleList(currentPage);

        this.setListAndTotal(invoiceAdjustmentRules, totalRules);
        this.$_handleLoaderState(false);
    },
    methods: {
        async getRuleList(pageNumber = 1, itemsPerPage = 50) {
            let response = {
                data: {
                    invoiceAdjustmentRules: [],
                    totalRules: null
                }
            };
            try {
                const endpoint = `/api/invoice-adjustment-rules`;
                response = await handleRequests(endpoint, {
                    params: {
                        pageNumber,
                        itemsPerPage,
                        ...this.cleanFilters()
                    }
                });
            } catch (error) {
                const message = 'Error in getting the invoice adjustment rule list';
                showErrorMessage(this, message, error);
            }
            return response;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },
        setListAndTotal(data, total) {
            this.ruleList = data;
            this.pagination.total = total || data.length;
        },
        async createRule() {
            this.$modal
                .show(CreateInvoiceAdjustmentRuleModal, {
                    invoiceAdjustmentRuleDetails: Object.assign({}, this.invoiceAdjustmentRuleDetails)
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.handleChangePage(1, this.pagination.perPage);
                        this.$modal.hide();
                    }
                });
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.$_handleLoaderState(true);
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            const {
                data: { invoiceAdjustmentRules, totalRules }
            } = await this.getRuleList(currentPage, perPage);
            const total = totalRules;
            this.setListAndTotal(invoiceAdjustmentRules, total);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            this.$_handleLoaderState(false);
        },
        showSidebar(id) {
            this.isShowSidebar = true;
            this.invoiceAdjustmentRuleId = id;
        },
        toggleSidebarWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown)
                this.isShowSidebar = !this.isShowSidebar;
        },
        async getRuleDetails(ruleId) {
            try {
                const api = `/api/invoice-adjustment-rules/${ruleId}`;
                const { data } = await handleRequests(api);
                return data;
            } catch (error) {
                const message = 'Error in getting the rule details';
                showErrorMessage(this, message, error);
            }
            return {};
        },
        async updateRule(ruleId) {
            this.$_handleLoaderState(true, 'LOADING...');
            const details = await this.getRuleDetails(ruleId);
            this.$_handleLoaderState(false);

            this.$modal
                .show(CreateInvoiceAdjustmentRuleModal, {
                    invoiceAdjustmentRuleDetails: Object.assign({}, details),
                    isUpdate: true
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.handleChangePage(1, this.pagination.perPage);
                        this.$modal.hide();
                    }
                });
        },
        async deleteRule(ruleId) {
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'delete'
            };
            const api = `/api/invoice-adjustment-rules/${ruleId}`;
            try {
                await handleRequests(api, payload);
                this.$notifySuccess('Invoice adjustment rule was deleted!');
                this.handleChangePage(1, this.pagination.perPage);
                this.$_handleLoaderState(false);
            } catch (e) {
                const message = 'Error in deleting invoice adjustment rule.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        handleDeleteRule(ruleId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Invoice Adjustment Rule',
                    body: 'Are you sure you want to delete this invoice adjustment rule?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        await this.deleteRule(ruleId);
                    }
                });
        },
        async handleSearch(data) {
            this.isListLoading = true;
            this.filters = Object.assign(this.filters, data);
            const {
                data: { invoiceAdjustmentRules, totalRules }
            } = await this.getRuleList(1, this.pagination.perPage);

            this.setListAndTotal(invoiceAdjustmentRules, totalRules);
            this.isListLoading = false;
        },
        handleSelectedOption(val) {
            this.selectedOption = val;
        },
        formatCurrency(value) {
            const code = this.user.regionCode ? `en-${this.user.regionCode}` : 'en-AU';
            const formatter = new Intl.NumberFormat(code, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            return formatter.format(value);
        }
    }
};
</script>

<style lang="scss" scoped>
.download-btn {
    color: #2b93ff !important;
    margin-top: 5px;
    display: block;
    font-size: 12px;
    cursor: pointer;
}
.table-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

::v-deep .error-body div {
    margin: 0;
    margin-top: 20px;
    padding: 10px;
}

.customer-dashboard ::v-deep .md-card {
    margin-bottom: 0;
}

.green-icon {
    color: #0bda8e !important;
    margin-right: 5px;
}

.md-table .md-table-head:last-child {
    text-align: right;
    width: 10%;
}
.md-table .md-table-cell {
    &:last-child {
        padding-right: 30px;
        .actions {
            float: right;
            width: 60px !important;
        }
    }
}

.running-load-menu {
    padding: 5px 10px !important;

    .dropdown-menu {
        li:hover {
            cursor: pointer;
            a {
                color: #fff !important;
            }
        }
        li > a {
            color: #333 !important;
        }
    }

    ::v-deep .notification {
        margin: 0;
    }
}

.margin-right {
    margin-right: 5px;
}
</style>
