<template>
    <md-card :class="[{ 'disabled-rates': hasInvoice }]">
        <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon icon-style">
                <md-icon>payments</md-icon>
                {{ title }}
            </div>
        </md-card-header>
        <div class="has-invoice" v-if="hasInvoice">
            <md-icon class="invoice-icon-color">task</md-icon>
            <i>*Costs cannot be changed because job has been invoiced to a team member</i>
        </div>
        <div class="is-approved" v-if="isCostsApproved">
            <md-icon class="invoice-icon-color">task</md-icon>
            <i>*Costs cannot be changed because job has been approved</i>
        </div>
        <md-card-content>
            <div v-if="updatingRates">
                <loading-component-spinner loading-text="Rates Updating..." />
            </div>
            <p v-if="costLines.length === 0 && !updatingRates" class="grayedout-text text-center">
                No {{ title }} found
            </p>
            <div v-if="!updatingRates && costLines.length > 0">
                <md-table>
                    <md-table-row slot="header">
                        <md-table-head class="drag-icon"></md-table-head>
                        <md-table-head>Description</md-table-head>
                        <md-table-head>Unit</md-table-head>
                        <md-table-head>Amount</md-table-head>
                        <md-table-head v-if="$root.isDesktop && !isReadOnlyUser && !hasInvoice">
                            Actions
                        </md-table-head>
                    </md-table-row>

                    <div v-if="updatingRates">
                        <loading-component-spinner loading-text="Rates Updating..." />
                    </div>
                    <md-table-row v-if="!updatingRates" v-for="rate in costLines" :key="rate.id">
                        <md-table-cell class="drag-icon">
                            <md-icon v-if="rate.isManualCharge || rate.manuallyChanged">
                                <span class="">
                                    drive_file_rename_outline
                                </span>
                            </md-icon>
                        </md-table-cell>
                        <md-table-cell>
                            {{ rate.description }}
                        </md-table-cell>
                        <md-table-cell v-if="rate.isManualCharge || rate.manuallyChanged" />
                        <md-table-cell v-else>
                            {{ rate.chargeUnit }}
                        </md-table-cell>
                        <md-table-cell>
                            {{
                                $_getRateAmount(rate)
                                    | currency(shipmentDetails ? shipmentDetails.currency : stopDetails.currency)
                            }}
                        </md-table-cell>
                        <md-table-cell class="action-buttons" v-if="!hasInvoice">
                            <md-button
                                v-if="!isReadOnlyUser"
                                title="Edit"
                                class="md-success md-just-icon md-round"
                                @click.stop="openCostLine(rate)"
                            >
                                <md-icon>edit</md-icon>
                            </md-button>
                            <md-button
                                v-if="!isReadOnlyUser"
                                title="Remove"
                                class="md-danger md-just-icon md-round"
                                @click.stop="deleteCostLine(rate)"
                            >
                                <md-icon>delete</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>

                    <md-table-row v-if="!updatingRates">
                        <md-table-cell colspan="3" />
                        <md-table-cell class="total-style">
                            {{
                                $_getTotalAmount(costLines)
                                    | currency(shipmentDetails ? shipmentDetails.currency : stopDetails.currency)
                            }}
                        </md-table-cell>
                        <md-table-cell />
                    </md-table-row>
                </md-table>
            </div>
        </md-card-content>
        <md-card-actions
            v-if="!isCostsApproved && !hasInvoice && !isReadOnlyUser"
            :class="{ 'is-loading-rates': updatingRates }"
        >
            <md-button class="md-warning revert-button" @click="validateRevertingCosts()">
                Revert to Default Costs
            </md-button>
            <md-button class="md-success" @click="handleAddNewCost()">
                Add Cost
            </md-button>
        </md-card-actions>
    </md-card>
</template>

<script>
import { GeneralMixin, RatesEngineMixin } from '@/mixins';
import { FeatureManager } from '@/directives';
import { FEATURE_NAMES } from '@/utils/constants';
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import CostItemModal from './CostItemModal';
import LoadingComponentSpinner from './LoadingComponentSpinner';

export default {
    name: 'CostRatesList',
    components: {
        LoadingComponentSpinner
    },
    mixins: [GeneralMixin, RatesEngineMixin, FeatureManager],
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            isIndividualUser: 'user/isIndividualUser',
            user: 'user/user'
        })
    },
    props: {
        costLines: {
            type: Array,
            default: () => []
        },
        shipmentDetails: {
            type: Object,
            default: () => null
        },
        stopDetails: {
            type: Object,
            default: () => null
        },
        title: {
            type: String,
            default: null
        },
        hasInvoice: {
            type: Boolean,
            default: false
        },
        ratesHaveChanged: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            updatingRates: false,
            isCostsApproved: false
        };
    },
    watch: {
        ratesHaveChanged(newValue, oldValue) {
            this.updatingRates = newValue;
        }
    },
    mounted() {
        if (this.$_hasFeature(FEATURE_NAMES.JobsCostApproval)) {
            this.isCostsApproved = this.shipmentDetails
                ? this.shipmentDetails.isCostsApproved
                : this.stopDetails.isCostsApproved;
        }
    },
    methods: {
        openCostLine(cost) {
            this.$modal
                .show(CostItemModal, {
                    shipmentId: this.shipmentDetails?.shipmentId,
                    stopId: this.stopDetails?.stopId,
                    isManualCharge: false,
                    manuallyChanged: true,
                    costLine: cost
                })
                .then((response) => {
                    this.emitUpdate();
                    this.$modal.hide();
                });
        },
        deleteCostLine(cost) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Cost',
                    body: 'Are you sure you want to delete this cost?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isListLoading = true;
                        await this.handleDeleteCost(cost.id);
                    }
                });
        },
        async handleDeleteCost(id) {
            const payload = {
                method: 'delete'
            };
            const api = `/api/rates/cost/delete/${id}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Cost was deleted!',
                    type: 'success'
                });
            } catch (e) {
                const message = 'Error in deleting a cost.';
                showErrorMessage(this, message, e);
            } finally {
                this.emitUpdate();
            }
        },
        handleAddNewCost() {
            this.$modal
                .show(CostItemModal, {
                    shipmentId: this.shipmentDetails?.shipmentId,
                    stopId: this.stopDetails?.stopId,
                    isManualCharge: true,
                    manuallyChanged: false,
                    costLine: {
                        description: null,
                        amount: 0,
                        chargeUnit: null,
                        quantity: 1
                    }
                })
                .then((response) => {
                    this.emitUpdate();
                    this.$modal.hide();
                });
        },
        validateRevertingCosts() {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Revert Costs',
                    body: 'Are you sure you want to revert costs? <br /><br /> This cannot be undone.',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        await this.handleRevertToDefaultCosts();
                    }
                });
        },
        async handleRevertToDefaultCosts() {
            try {
                this.$_handleLoaderState(true, 'REVERTING...');

                const data = this.shipmentDetails
                    ? this.$_getshipmentDataModel(
                        this.shipmentDetails,
                        this.user,
                        this.shipmentDetails.load,
                        this.shipmentDetails.chargeLines,
                        null,
                        false,
                        true,
                        false,
                        true
                    )
                    : this.$_getStopData(
                        this.stopDetails,
                        this.user,
                        this.stopDetails.load,
                        this.stopDetails.chargeLines, // existing charge list
                        null,
                        false,
                        true,
                        false,
                        true
                    );

                const payload = {
                    method: 'post',
                    data
                };

                await handleRequests('/api/rates/generate-rates', payload);

                this.$notify({
                    message: 'Costs Reverted',
                    type: 'success'
                });
            } catch (error) {
                const message = 'Error Reverting Costs';
                showErrorMessage(this, message, null);
            } finally {
                this.ratesIsLoading = false;
                this.$_handleLoaderState(false);
                this.emitUpdate();
            }
        },
        emitUpdate() {
            if (this.shipmentDetails?.shipmentId) {
                this.$emit('updateShipmentDetails');
            }

            if (this.stopDetails?.stopId) {
                this.$emit('updateStopDetails');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.total-style {
    color: #4caf50 !important;
    font-weight: bold !important;
}

.icon-style {
    color: white;
}

.revert-button {
    margin-inline-end: auto !important;
}

.disabled-rates {
    pointer-events: none;
}

.is-loading-rates {
    margin-top: 150px !important;
}

.action-buttons {
    button,
    ::v-deep button {
        margin: 0 2px;
    }

    button:last-child {
        margin-right: 0;
    }
}

::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

.has-invoice,
.is-approved {
    padding-top: 15px;
    padding-left: 20px;
}

.invoice-icon-color {
    color: #4caf50 !important;
}
</style>
