var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "services-dashboard" },
    [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c("div", { staticClass: "custom-toolbar" }, [
            _c(
              "div",
              { staticClass: "custom-toolbar-start" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "Team Settings",
                      },
                    },
                  },
                  [
                    _c("h3", { staticClass: "title" }, [
                      _vm._v("Team Settings"),
                    ]),
                  ]
                ),
                _c("h3", { staticClass: "title" }, [
                  _vm._v("   >    " + _vm._s(_vm.$t("menus.setting.services"))),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("services-toolbar", {
        class: _vm.$root.isTablet ? "tablet-filter-container" : "",
        attrs: { "rate-groups": _vm.rateGroups },
        on: {
          onCreateService: _vm.onCreateService,
          onFileSelected: _vm.onFileSelected,
          onFilterServices: _vm.handleFilterOrSearch,
        },
      }),
      _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            _c(
              "div",
              { staticClass: "error-message-container" },
              [
                _c("batch-upload-error-message", {
                  attrs: {
                    content: _vm.errorMessages,
                    "has-error-message": _vm.hasError,
                  },
                }),
              ],
              1
            ),
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-warning" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("store")])],
                      1
                    ),
                  ]
                ),
                _c("md-card-content", { staticClass: "body-list" }, [
                  _vm.isListLoading
                    ? _c("div", { staticClass: "empty-table" }, [
                        _c(
                          "div",
                          { staticClass: "table-loader" },
                          [
                            _c("fade-loader", {
                              attrs: { loading: true, color: "#333333" },
                            }),
                            _c("span", [_vm._v("LOADING")]),
                          ],
                          1
                        ),
                      ])
                    : _c("div", [
                        _vm.servicesList.length
                          ? _c(
                              "div",
                              [
                                _c(
                                  "md-table",
                                  {
                                    staticClass:
                                      "context-menu-support custom-paginated-table",
                                  },
                                  [
                                    _c(
                                      "md-table-row",
                                      [
                                        _c("md-table-head", [
                                          _vm._v("Description"),
                                        ]),
                                        _c("md-table-head", [
                                          _vm._v("Unit Price (Ex. Tax)"),
                                        ]),
                                        _c("md-table-head", [
                                          _vm._v("Unit Cost (Ex. Tax)"),
                                        ]),
                                        _c("md-table-head", [
                                          _vm._v("Quantity Type"),
                                        ]),
                                        _c("md-table-head", [
                                          _vm._v("Actions"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      _vm.servicesList,
                                      function (service) {
                                        return _c(
                                          "md-table-row",
                                          { key: service.serviceId },
                                          [
                                            _c("md-table-cell", [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "cell-hover",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.updateService(
                                                        service.serviceId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(service.name) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("md-table-cell", [
                                              service.unitPriceExTax
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            service.unitPriceExTax,
                                                            service.costCurrency
                                                          )
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ])
                                                : service.defaultUnitPriceExTax
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "default-price",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm._f("currency")(
                                                              service.defaultUnitPriceExTax,
                                                              service.costCurrency
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      "\n                                            -\n                                        "
                                                    ),
                                                  ]),
                                            ]),
                                            _c("md-table-cell", [
                                              service.unitCostExTax
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            service.unitCostExTax,
                                                            service.costCurrency
                                                          )
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ])
                                                : service.defaultUnitCostExTax
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "default-price",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm._f("currency")(
                                                              service.defaultUnitCostExTax,
                                                              service.costCurrency
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      "\n                                            -\n                                        "
                                                    ),
                                                  ]),
                                            ]),
                                            _c("md-table-cell", [
                                              _vm._v(
                                                _vm._s(service.quantityType)
                                              ),
                                            ]),
                                            _c(
                                              "md-table-cell",
                                              { staticClass: "action-buttons" },
                                              [
                                                _c(
                                                  "md-button",
                                                  {
                                                    staticClass:
                                                      "md-warning md-just-icon md-round",
                                                    attrs: {
                                                      title:
                                                        "Update service details",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.updateService(
                                                          service.serviceId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("md-icon", [
                                                      _vm._v("edit"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "md-button",
                                                  {
                                                    staticClass:
                                                      "md-danger md-just-icon md-round",
                                                    attrs: {
                                                      title: "Delete service",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleDeleteService(
                                                          service.serviceId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("md-icon", [
                                                      _vm._v("delete"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _c("div", [
                              _c("p", { staticClass: "no-result-message" }, [
                                _vm._v(
                                  "No results matching your search/filter could be found."
                                ),
                              ]),
                            ]),
                      ]),
                ]),
              ],
              1
            ),
            _c(
              "md-card-actions",
              {
                staticClass: "page-footer",
                attrs: { "md-alignment": "space-between" },
              },
              [
                _c("div", [
                  _vm.total === _vm.pagination.perPage
                    ? _c("p", { staticClass: "card-category" }, [
                        _vm._v(
                          "\n                        Page " +
                            _vm._s(_vm.pagination.currentPage) +
                            " of many\n                    "
                        ),
                      ])
                    : _c("p", { staticClass: "card-category" }, [
                        _vm._v(
                          "Page " +
                            _vm._s(_vm.pagination.currentPage) +
                            " of " +
                            _vm._s(_vm.maxPage)
                        ),
                      ]),
                ]),
                _c("pagination", {
                  staticClass: "pagination-no-border pagination-success",
                  attrs: {
                    "per-page": _vm.pagination.perPage,
                    total: _vm.total,
                  },
                  on: {
                    "change-page": function ($event) {
                      return _vm.handleChangePage(
                        $event,
                        _vm.pagination.perPage
                      )
                    },
                  },
                  model: {
                    value: _vm.pagination.currentPage,
                    callback: function ($$v) {
                      _vm.$set(_vm.pagination, "currentPage", $$v)
                    },
                    expression: "pagination.currentPage",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }