<template>
    <div class="inline-button">
        <md-button
            v-if="!isReadOnlyUser"
            title="Remove maintenance schedule from asset"
            :class="useIcon ? 'md-danger md-just-icon md-round' : `${className}`"
            @click.stop="handleRemove"
        >
            <md-icon v-if="useIcon">delete</md-icon>
            <span v-else>Remove</span>
        </md-button>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';

export default {
    name: 'RemoveUpcomingMaintenanceScheduleButton',
    mixins: [GeneralMixin],
    props: {
        className: {
            type: String,
            default: () => ''
        },
        useIcon: {
            type: Boolean,
            default: () => true
        },
        maintenanceData: {
            type: Object,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    methods: {
        handleRemove() {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Remove Maintenance Schedule',
                    body: `Are you sure you want to remove "${this.maintenanceData.maintenanceName}" from ${
                        this.maintenanceData.assetName
                    }`,
                    buttons: ['Yes', 'No']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'yes') {
                        this.$_handleLoaderState(true);
                        const api = `/api/upcoming-maintenance-schedules/${
                            this.maintenanceData.assetMaintenanceScheduleRelationshipId
                        }`;
                        const payload = {
                            method: 'delete'
                        };

                        try {
                            await handleRequests(api, payload);
                            this.$notify({
                                message: 'Successfully removed maintenance schedule',
                                type: 'success'
                            });
                            this.$_handleLoaderState(false);
                            this.$emit('maintenanceCompleted', true);
                        } catch (e) {
                            const message = 'Could not remove maintenance schedule';
                            showErrorMessage(this, message, e);
                            this.$_handleLoaderState(false);
                        }
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.inline-button {
    display: inline-block;
}
</style>
