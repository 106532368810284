import { render, staticRenderFns } from "./UserSettings.vue?vue&type=template&id=245b7dfe&"
import script from "./UserSettings.vue?vue&type=script&lang=js&"
export * from "./UserSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\a\\1\\s\\Locate2u.AppPortal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('245b7dfe')) {
      api.createRecord('245b7dfe', component.options)
    } else {
      api.reload('245b7dfe', component.options)
    }
    module.hot.accept("./UserSettings.vue?vue&type=template&id=245b7dfe&", function () {
      api.rerender('245b7dfe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/User/UserSettings.vue"
export default component.exports