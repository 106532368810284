<template>
    <md-table-row :key="teamBreakDetails.breakScheduleId" class="drag-item">
        <md-table-cell class="order-align">
            {{ teamBreakDetails.order }}
        </md-table-cell>
        <md-table-cell v-if="!editBreakState">
            After
            <span class="span-break-minutes">{{ teamBreakDetails.drivingTimeMinutes }}</span>
            minutes, take a break of
            <span class="span-break-minutes">{{ teamBreakDetails.breakTimeMinutes }}</span>
            minutes.
        </md-table-cell>
        <md-table-cell v-if="editBreakState">
            After
            <input
                type="text"
                class="search-textbox"
                v-model="teamBreakDetails.drivingTimeMinutes"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
            />
            minutes, take a break of
            <input
                type="text"
                class="search-textbox"
                v-model="teamBreakDetails.breakTimeMinutes"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
            />
            minutes.
        </md-table-cell>
        <md-table-cell>
            <md-button
                v-if="editBreakState"
                class="md-round md-just-icon md-success break-btn-icon"
                title="Save Edit"
                @click="emitEdit"
            >
                <md-icon>save</md-icon>
            </md-button>
            <md-button
                v-if="editBreakState"
                class="md-round md-just-icon md-danger break-btn-icon"
                title="Cancel Edit"
                @click="toggleEdit"
            >
                <md-icon>cancel</md-icon>
            </md-button>
            <md-button
                v-if="!editBreakState"
                class="md-round md-just-icon md-warning break-btn-icon"
                title="Edit Break Schedule"
                @click="toggleEdit"
            >
                <md-icon>edit</md-icon>
            </md-button>
            <md-button
                v-if="!editBreakState"
                class="md-round md-just-icon md-danger break-btn-icon"
                title="Delete Break Schedule"
                @click="emitDelete"
            >
                <md-icon>delete</md-icon>
            </md-button>
        </md-table-cell>
    </md-table-row>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';

export default {
    name: 'TeamBreakRowComponent',
    mixins: [GeneralMixin],
    props: {
        teamBreakDetails: {
            type: Object,
            default: () => {}
        },
        breakCount: {
            type: Number,
            default: 0
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    data() {
        return {
            editBreakState: false
        };
    },
    methods: {
        emitEdit() {
            if (this.editBreakState) {
                this.$emit('editBreakScheduleClick', this.teamBreakDetails);
                this.editBreakState = false;
            } else {
                this.editBreakState = true;
            }
        },
        toggleEdit() {
            this.editBreakState = !this.editBreakState;
        },
        emitDelete() {
            this.$emit('deleteBreakScheduleClick', this.teamBreakDetails.breakScheduleId);
        },
        changeOrder(shouldIncrease) {
            this.$emit('changeOrderBreakScheduleClick', this.teamBreakDetails.breakScheduleId, shouldIncrease);
        }
    }
};
</script>
<style lang="scss" scoped>
.order-align {
    text-align: center;
}

.span-break-minutes {
    width: 30px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
}
.search-textbox {
    width: 30px;
}
.break-btn-icon {
    margin-left: 3px;
    margin-right: 3px;
}
</style>
