var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isAssetGroup && _vm.pinnedDriverDetails.publicUserId
    ? _c(
        "div",
        { staticClass: "profile--container map-panel" },
        [
          _c(
            "router-link",
            {
              staticClass: "profile--link",
              attrs: {
                to: {
                  name: "Team Member Profile",
                  params: {
                    publicUserId: _vm.pinnedDriverDetails.publicUserId,
                  },
                },
                target: "_blank",
              },
            },
            [
              _c("img", {
                staticClass: "profile--photo",
                attrs: { src: _vm.photoUrl },
                on: { error: _vm.setDefaultBrokenImage },
              }),
            ]
          ),
          _c("span", { staticClass: "profile--fullname" }, [
            _vm._v(_vm._s(_vm.pinnedDriverDetails.fullName)),
          ]),
          _vm.pinnedDriverDetails.phone
            ? _c(
                "div",
                [
                  _c(
                    "a",
                    {
                      staticClass: "profile--phone",
                      attrs: { href: "tel:" + _vm.pinnedDriverDetails.phone },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.pinnedDriverDetails.phone)),
                      ]),
                    ]
                  ),
                  _c("send-sms-button", {
                    attrs: {
                      "public-user-id": _vm.pinnedDriverDetails.publicUserId,
                      name: _vm.pinnedDriverDetails.fullName,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }