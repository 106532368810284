var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    { staticClass: "md-card-stats" },
    [
      _c(
        "md-card-header",
        {
          staticClass: "md-card-header-icon",
          class: _vm.getClass(_vm.headerColor),
        },
        [_vm._t("header")],
        2
      ),
      _c(
        "md-card-actions",
        { attrs: { "md-alignment": "left" } },
        [_vm._t("footer")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }