<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <filter-component
                            :class="$root.isTablet ? 'tablet-filter-container' : ''"
                            @onFilterStops="handleFilterOrSearch"
                            :team-members="() => this.teamMembers"
                            :time-window-filter-options="timeWindowFilterOptions"
                            :team-region-id="filters.teamRegionId"
                            :is-geocoded-view="true"
                            :geocode-types="filters.geocodeTypes"
                            :confidence-score="filters.confidenceScore"
                        />
                    </div>
                    <div class="custom-toolbar-end">
                        <search-component v-if="$root.isDesktop" @onSearch="handleFilterOrSearch" />
                        <div>
                            <md-button
                                title="Refresh List"
                                class="md-primary md-just-icon md-round pull-right header-button"
                                @click="handleRefresh"
                            >
                                <md-icon>refresh</md-icon>
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
                :class="total < pagination.perPage ? 'margin-space-bot' : ''"
            >
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <CardHeaderDropdown
                            :dropdown-options="headerDropdown"
                            @selectedOption="handleSelectedView"
                            :selected-option="headerDropdown[1]"
                        />
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div v-if="!isListLoading">
                            <md-table class="context-menu-support">
                                <md-table-row>
                                    <md-table-head>Address</md-table-head>
                                    <md-table-head>Geocoded Address</md-table-head>
                                    <TableHeaderDropdownMultiple
                                        default-text="Type"
                                        :dropdown-options="geocodingOptions"
                                        :selected-options="selectedGeocodeTypes"
                                        @selectedOptions="handleGeocodingTypeFilter"
                                    />
                                    <TableHeaderDropdown
                                        default-text="Score"
                                        :dropdown-options="confidenceScoreList"
                                        :selected-option="selectedConfidenceScore"
                                        @selectedOption="handleSelectConfidenceFilter"
                                    />
                                </md-table-row>

                                <md-table-row
                                    :class="
                                        selectedStop != null && selectedStop.stopId == item.stopId
                                            ? 'highlight-address'
                                            : ''
                                    "
                                    v-for="(item, index) in stopsList"
                                    :key="item.stopId"
                                    @click.stop="showStopDetailsSidebar(item, index)"
                                >
                                    <md-table-cell class="address">
                                        <GeocodingIndicator :address-components="item.addressComponents" />
                                        {{ item.address }}
                                    </md-table-cell>
                                    <md-table-cell class="address">
                                        {{ item.addressComponents != null ? item.addressComponents.address : '' }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        <div
                                            v-if="item.addressComponents != null && item.addressComponents.type != null"
                                            :class="
                                                `custom-badge address-badge status-${$_getGeocodeAddressTypeBadgeColor(
                                                    item.addressComponents.type
                                                )}`
                                            "
                                        >
                                            {{
                                                $te(`geocodeTypes.${item.addressComponents.type}`)
                                                    ? $t(`geocodeTypes.${item.addressComponents.type}`)
                                                    : item.addressComponents.type
                                            }}
                                        </div>
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{
                                            item.addressComponents != null
                                                ? Math.round(item.addressComponents.confidenceScore * 100) + '%'
                                                : ''
                                        }}
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <div v-if="stopsList.length == 0 && !isLoading">
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                        <div v-else>
                            <div class="stopbar-loader">
                                <fade-loader :loading="loading" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <md-card-actions class="page-footer" md-alignment="space-between">
            <div>
                <p v-if="total === pagination.perPage" class="card-category">
                    Page {{ pagination.currentPage }} of many
                </p>
                <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
            </div>
            <pagination
                v-model="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                @change-page="handleChangePage($event, pagination.perPage)"
            />
        </md-card-actions>
        <transition name="slide">
            <fix-address-sidebar
                v-if="showStopDetails"
                v-click-outside="toggleStopDetailsWindow"
                @closeModal="toggleStopDetailsWindow"
                :stop-details="selectedStop"
                :current-index="currentListIndex"
                @leftClick="handleLeftClick"
                @rightClick="handleRightClick"
            />
        </transition>
    </div>
</template>

<script>
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import {
    Pagination,
    SearchComponent,
    GeocodingIndicator,
    CardHeaderDropdown,
    TableHeaderDropdown,
    TableHeaderDropdownMultiple
} from '@/components';
import { handleRequests } from '@/helpers';
import { GeneralMixin, GeocodeAddressMixin } from '@/mixins';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { mapGetters } from 'vuex';
import {
    STOP_HEADER_DROPDOWN_CONSTANTS,
    GEOCODE_ADDRESS_TYPE,
    GEOCODING_CONFIDENCE_SCORE_CONSTANTS
} from '@/utils/constants';
import FixAddressSidebar from './FixAddressSidebar';

import { FilterComponent } from './components';

export default {
    name: 'FixAddressView',
    mixins: [GeneralMixin, GeocodeAddressMixin],
    data() {
        return {
            isGeocodingView: true,
            stopsList: [],
            showStopDetails: false,
            stopDetails: {},
            pagination: PAGINATION_DEFAULTS,
            teamMembers: [],
            selectedTeamMemberId: '',
            maxPage: 1,
            loading: true,
            isListLoading: false,
            filters: {
                status: 'Active',
                teamMemberPublicUserId: '',
                carrierTeamId: null,
                teamRegionId: null,
                timeWindowStart: null,
                timeWindowEnd: null,
                tripDate: '',
                geocodeTypes: null,
                confidenceScore: 1
            },
            timeWindowFilterOptions: [],
            selectedStop: null,
            currentListIndex: null,
            selectedView: null,
            headerDropdown: STOP_HEADER_DROPDOWN_CONSTANTS,
            maximumTotalStops: null,
            geocodingOptions: GEOCODE_ADDRESS_TYPE,
            confidenceScoreList: GEOCODING_CONFIDENCE_SCORE_CONSTANTS,
            selectedConfidenceScore: GEOCODING_CONFIDENCE_SCORE_CONSTANTS[0],
            selectedGeocodeTypes: []
        };
    },
    components: {
        Pagination,
        FilterComponent,
        SearchComponent,
        GeocodingIndicator,
        FixAddressSidebar,
        FadeLoader,
        CardHeaderDropdown,
        TableHeaderDropdown,
        TableHeaderDropdownMultiple
    },
    computed: {
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser',
            isCustomerAdmin: 'user/isCustomerAdmin',
            isLoading: 'isLoading',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        })
    },
    created() {
        if (localStorage.getItem('geocodingview.defaults.confidenceScore')) {
            const confidenceScoreData = this.confidenceScoreList.filter(
                (x) => x.key === Number(localStorage.getItem('geocodingview.defaults.confidenceScore'))
            );
            // eslint-disable-next-line prefer-destructuring
            this.selectedConfidenceScore = confidenceScoreData[0];
            this.filters.confidenceScore = Number(localStorage.getItem('geocodingview.defaults.confidenceScore'));
        }

        if (
            localStorage.getItem('geocodingview.defaults.geocodeTypes') != null &&
            localStorage.getItem('geocodingview.defaults.geocodeTypes').length > 0
        ) {
            const localStorageOptions = JSON.parse(localStorage.getItem('geocodingview.defaults.geocodeTypes'));

            if (localStorageOptions.length > 0) {
                const selectedKeys = [];
                localStorageOptions.forEach((x) => {
                    selectedKeys.push(x);
                    const addressType = this.geocodingOptions.filter((option) => option.key === x);
                    this.selectedGeocodeTypes.push(addressType[0]);
                });
                this.filters.geocodeTypes = JSON.stringify(selectedKeys);
            }
        }
    },
    async mounted() {
        this.filters.teamRegionId = this.user.teamRegionId ? this.user.teamRegionId : null; // null = 'All'
        this.$_handleLoaderState(true);
        const { stops, totalStops } = await this.handleFetchingOfStopsOnLoad();
        this.assignStops(stops, totalStops || stops.length);
        this.getTeamMembers();
        this.$_handleLoaderState(false);
    },
    methods: {
        handleSelectedView(value) {
            this.$emit('selectedView', value.key);
        },
        async handleFetchingOfStopsOnLoad() {
            const currentPage = Number(this.$route.query.currentPage) || 1;

            const {
                data: { stops, totalStops }
            } = await this.fetchStopsList(currentPage);

            if (currentPage) {
                this.pagination.currentPage = currentPage;
            }

            return { stops, totalStops };
        },
        getMaxPage(page) {
            this.maxPage = page;
        },
        toggleModal(shouldToggle, modalToToggle) {
            this[modalToToggle] = shouldToggle;
        },
        async getTeamMembers(tripDate = null) {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS', {
                date: tripDate
            });
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },
        async fetchStopsList(pageNumber = 1, itemsPerPage = 50) {
            const endpoint = `/api/stops/geocoded/list`;
            const response = await handleRequests(endpoint, {
                params: {
                    pageNumber,
                    itemsPerPage,
                    ...this.cleanFilters()
                }
            });

            return response;
        },
        showStopDetailsSidebar(item, index) {
            this.showStopDetails = true;
            this.selectedStop = item;
            this.currentListIndex = index;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.$_handleLoaderState(true);
            this.showStopDetails = false;
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            const {
                data: { stops, totalStops }
            } = await this.fetchStopsList(currentPage, perPage);
            const stopsToAssign = totalStops || stops.length;
            this.assignStops(stops, stopsToAssign);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            this.$_handleLoaderState(false);
        },
        async handleSidebarChangePage(currentPage = 1, perPage = 50) {
            this.$_handleLoaderState(true);
            this.showStopDetails = true;
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            const {
                data: { stops, totalStops }
            } = await this.fetchStopsList(currentPage, perPage);
            const stopsToAssign = totalStops || stops.length;
            this.assignStops(stops, stopsToAssign);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            this.$_handleLoaderState(false);
        },
        async handleFilterOrSearch(val) {
            let shouldSearch = false;
            Object.getOwnPropertyNames(val).forEach((q) => {
                shouldSearch = shouldSearch || (val[q] || null) !== (this.filters[q] || null);
            });

            if (!shouldSearch) 
                return; // exit function

            this.isListLoading = true;
            this.filters = Object.assign(this.filters, val);
            const {
                data: { stops, totalStops }
            } = await this.fetchStopsList(1, this.pagination.perPage);
            const stopsToAssign = totalStops || stops.length;
            this.assignStops(stops, stopsToAssign);
            this.isListLoading = false;
        },
        toggleStopDetailsWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown)
                this.showStopDetails = !this.showStopDetails;

            this.selectedStop = null;
            this.currentListIndex = null;
        },
        assignStops(stops, totalStops) {
            this.stopsList = stops;
            this.maximumTotalStops = totalStops;
            if (this.pagination.currentPage === 1) {
                this.pagination.total = stops.length;
            } else {
                this.pagination.total = totalStops;
            }
        },
        handleRefresh() {
            this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        async handleLeftClick() {
            this.currentListIndex = this.currentListIndex - 1;

            if (this.pagination.currentPage === 1 && this.currentListIndex < 0) {
                this.currentListIndex = this.currentListIndex + 1;
                this.$notify({
                    message: `This is already the beginning of the list.`,
                    type: 'warning'
                });
                return;
            }

            if (this.currentListIndex < 0 && this.pagination.currentPage > 1) {
                await this.handleSidebarChangePage(this.pagination.currentPage - 1, this.pagination.perPage);
                this.currentListIndex = this.pagination.perPage - 1;
            }

            this.selectedStop = this.stopsList[this.currentListIndex];
        },
        async handleRightClick() {
            this.currentListIndex = this.currentListIndex + 1;
            const maxItemTotalPage =
                this.currentListIndex + (this.pagination.currentPage - 1) * this.pagination.perPage;

            if (maxItemTotalPage === this.maximumTotalStops) {
                this.currentListIndex = this.currentListIndex - 1;
                this.$notify({
                    message: `This is already the end of the list.`,
                    type: 'warning'
                });
                return;
            }

            if (this.currentListIndex % this.pagination.perPage === 0) {
                await this.handleSidebarChangePage(this.pagination.currentPage + 1, this.pagination.perPage);
                this.currentListIndex = 0;
            }

            this.selectedStop = this.stopsList[this.currentListIndex];
        },
        handleSelectConfidenceFilter(item) {
            this.selectedConfidenceScore = item;
            localStorage.setItem('geocodingview.defaults.confidenceScore', item.key);
            this.handleFilterOrSearch({ ...this.filters, confidenceScore: item.key });
        },
        handleGeocodingTypeFilter(options) {
            this.selectedGeocodeTypes = options;
            let selectedKeys = null;
            if (options.length > 0) {
                selectedKeys = options.map((x) => {
                    return x.key;
                });

                selectedKeys = JSON.stringify(selectedKeys);
                localStorage.setItem('geocodingview.defaults.geocodeTypes', selectedKeys);
            } else {
                localStorage.removeItem('geocodingview.defaults.geocodeTypes');
            }
            this.handleFilterOrSearch({ ...this.filters, geocodeTypes: selectedKeys });
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-toolbar {
    align-items: start;
    margin-top: 1px;
    .custom-toolbar-start {
        flex: 3;
    }
}

.no-result-message {
    text-align: center;
    margin-top: 45px;
    font-size: 16px;
}

::v-deep .status-container {
    .md-table-cell-container {
        overflow: visible;
    }
}

.slide-leave-active,
.slide-enter-active {
    transition: 0.3s;
}
.slide-enter {
    transform: translateX(100%);
}
.slide-leave-to {
    transform: translateX(100%);
}

.content {
    margin-top: -15px;
    ::v-deep .md-card.md-theme-default {
        margin-bottom: 0;
    }
}

::v-deep .filter-steps--container {
    margin-top: 6px;

    .filter-steps--choices {
        margin-top: 0;
        padding-bottom: 0;
        > .md-input {
            display: initial;
        }
    }
}

::v-deep .filter-steps--container.tablet-filter-container {
    width: initial;
    float: left;
    margin-left: 20px;
}

::v-deep .search--container {
    width: 250px;
    float: right;
    margin-top: 6px;
}

::v-deep .md-table-cell-container {
    .md-button.md-round.md-just-icon {
        box-shadow: none;
    }
}

::v-deep .md-table-cell {
    padding: 10px 8px;
    &:last-child {
        .md-table-cell-container {
            text-align: left !important;
            justify-content: flex-start;
        }
    }
}

::v-deep .md-table-row {
    &.zero-results {
        padding: 16px;
        text-align: center;
    }

    .custom-button-container .custom-a-blue a {
        color: rgba(0, 0, 0, 0.87);
    }
}

::v-deep .md-table-row:hover {
    background-color: #fafafa;
    cursor: pointer;
}

::v-deep .md-toolbar {
    .md-field {
        &:first-child,
        &:last-child {
            margin: 8px;
        }
    }

    .stops-search {
        padding-bottom: 10px;
        &::before,
        &::after {
            bottom: 10px;
        }
    }
}

::v-deep .md-card-actions {
    padding: 16px;
    &.page-footer {
        padding-top: 0px;
    }
}

.stop-reference ::v-deep a,
.trip-date ::v-deep a {
    color: rgba(0, 0, 0, 0.87);
}

::v-deep .header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.stopbar-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

.custom-toolbar {
    align-items: start;
    margin-top: 1px;
    .custom-toolbar-start {
        flex: 3;
    }
}

.address-badge {
    max-width: initial;
}

.highlight-address {
    background-color: #eee;
}
</style>
