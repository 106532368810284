<template>
    <div class="welcome-container" v-html="$_compiledMarkdown(carrierDetails.longDescription)"></div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'WelcomeScreen',
    mixins: [GeneralMixin],
    props: {
        carrierDetails: {
            type: Object,
            default: () => null
        }
    }
};
</script>

<style lang="scss" scoped>
.welcome-container {
    height: 100%;
    max-height: 47vh;
    overflow: auto;
}
</style>
