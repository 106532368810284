<template>
    <md-button
        title="Send invoice via email"
        class="md-just-icon md-round"
        :class="emailSentDate != null ? 'md-success' : 'md-primary'"
        @click.stop="sendEmail()"
    >
        <md-icon>{{ emailSentDate != null ? 'mark_email_read' : 'email' }}</md-icon>
        <md-tooltip v-if="emailSentDate != null" md-direction="left">
            Sent on: {{ emailSentDate | dateTimeFormat(DATE_TYPES.standardDate) }}
        </md-tooltip>
    </md-button>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';

export default {
    name: 'SendEmailButton',
    props: {
        customerInvoiceId: {
            type: [Number, String],
            default: () => null
        },
        isBulk: {
            type: Boolean,
            default: false
        },
        bulkCustomerInvoiceIds: {
            type: Array,
            default: () => []
        },
        emailSentDate: {
            type: String,
            default: null
        }
    },
    mixins: [GeneralMixin],
    methods: {
        async sendEmail() {
            if (this.isBulk) {
                await this.handleBulk();
                return;
            }
            this.$_handleLoaderState(true, 'SENDING...');
            const payload = {
                method: 'post',
                data: [this.customerInvoiceId]
            };
            const api = `/api/customer-invoices/${this.customerInvoiceId}/send-email`;

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Invoice sent successfully',
                    type: 'success'
                });
                this.$emit('invoiceSent', this.invoiceId);
            } catch (e) {
                const message = 'Error in sending invoice.';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
        },
        async handleBulk() {
            this.$_handleLoaderState(true, 'SENDING...');
            const payload = {
                method: 'post',
                data: this.bulkCustomerInvoiceIds
            };
            const api = `/api/customer-invoices/bulk/send-email`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Invoices successfully sent',
                    type: 'success'
                });
                this.$emit('handleBulkSent', this.bulkTeamMemberInvoiceIds);
            } catch (e) {
                const message = 'Error in sending invoices.';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
        }
    }
};
</script>
