var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "map-container",
      class: _vm.isTrialExpired ? "not-paid-map-overlay" : "",
    },
    [
      _c("div", { ref: "googleMap", staticClass: "google-map" }),
      Boolean(this.map) ? [_vm._t("default")] : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }