<template>
    <div>
        <form-wrapper :validator="$v.model" class="form-wrapper">
            <form-group label="Client Id" name="clientId">
                <md-input v-model.trim="model.clientId" />
            </form-group>
            <form-group label="Client Secret" name="clientSecret">
                <md-input v-model.trim="model.clientSecret" />
            </form-group>
            <form-group label="Webhook Secret" name="webhookSecret">
                <md-input v-model.trim="model.webhookSecret" />
            </form-group>
        </form-wrapper>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'SherpaKeys',
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            model: {
                clientId: '',
                clientSecret: '',
                webhookSecret: ''
            }
        };
    },
    validations() {
        return {
            model: {
                clientId: { required },
                clientSecret: { required },
                webhookSecret: { required }
            }
        };
    },
    methods: {
        update() {
            this.$v.model.$touch();
            if (this.$v.model.$invalid) 
                return;

            this.$emit('onUpdate', { sherpaAccess: { ...this.model } });
        }
    },
    watch: {
        data(value) {
            Object.assign(this.model, value);
        }
    }
};
</script>
