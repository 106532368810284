<script>
import { nextTick, onMounted, provide, ref } from '@vue/composition-api';

export default {
    name: 'MapContainer',
    props: {
        mapEngine: {
            type: Object,
            required: true
        }
    },
    setup(props, { emit }) {
        const { mapEngine } = props;
        const mapContent = ref(null);
        const mapReady = ref(false);

        provide('mapEngine', mapEngine);

        onMounted(() => {
            nextTick().then(() => {
                const map = mapEngine.buildMap(mapContent.value);

                map.on('style.load', () => {
                    mapReady.value = true;
                    emit('mapLoaded', map);
                });
            });
        });

        return {
            mapContent,
            mapReady
        };
    }
};
</script>
<template>
    <div class="map-layout">
        <div class="map-container">
            <div class="map-content" ref="mapContent">
                <div v-if="mapReady">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.map-layout {
    display: flex;
    width: 100%;
    height: 100%;
}

.map-container {
    flex: 1;
    position: relative;
    display: flex;
}

.map-content {
    flex: 1;
}
</style>
