var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "asset-group-control md-layout-item md-size-100" },
    [
      _vm.assetGroupOptions && _vm.assetGroupOptions.length > 0
        ? _c(
            "form-group",
            { attrs: { label: "Asset Group" } },
            [
              _c(
                "md-select",
                {
                  on: { "md-selected": _vm.emitChange },
                  model: {
                    value: _vm.selectedAssetGroupId,
                    callback: function ($$v) {
                      _vm.selectedAssetGroupId = $$v
                    },
                    expression: "selectedAssetGroupId",
                  },
                },
                _vm._l(_vm.assetGroupOptions, function (group, index) {
                  return _c(
                    "md-option",
                    {
                      key: "asset-group-" + index,
                      attrs: { value: group.assetGroupId },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(group.groupName) +
                          "\n            "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }