<template>
    <div class="config-container">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ title }}
                <i>{{ rateGroupName }}</i>

                <i>{{ customerName }}</i>

                <i>{{ driverName }}</i>
            </h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <div class="md-layout batch-import-template">
                <form-wrapper :validator="$v">
                    <div>
                        <md-tooltip md-direction="top">
                            Number of attempts before charges are created, only applies to failed stops with an item.
                            <br />
                            <br />
                            This number will only apply charges and costs to this stop only. Anything prior or after
                            will have no charges or costs.
                        </md-tooltip>

                        <form-group name="reattemptCharges" label="Reattempt Charges" class="form-group">
                            <md-input type="number" v-model.number="rateConfigurationRule.reattemptCharges" />
                        </form-group>
                        <form-group name="reattemptCosts" label="Reattempt Costs" class="form-group">
                            <md-input type="number" v-model.number="rateConfigurationRule.reattemptCosts" />
                        </form-group>
                    </div>
                </form-wrapper>
            </div>

            <div class="equivalence-container">
                <h4 class="modal-title">
                    Weight Equivalence
                </h4>
                <div>
                    <form-group class="equivalence-item" name="weightEquivalence">
                        <md-input
                            class="equivalence-number"
                            type="number"
                            v-model.number="rateConfigurationRule.weightEquivalence"
                        />
                        <div class="equivalence-symbol">{{ teamWeightSymbol.symbol }}</div>
                    </form-group>
                    <md-icon class="scale">
                        scale
                    </md-icon>
                    <div class="equivalence-item equivalence-volume">
                        <div class="equivalence-symbol">1 {{ teamVolumeSymbol.symbol }}</div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <md-button class="dialog-button md-primary" @click="save">
                    Save
                </md-button>
            </div>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { integer } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
    name: 'RateConfigurationModal',
    components: {},
    mixins: [GeneralMixin],
    props: {
        customerId: {
            type: Number,
            default: null
        },
        driverUserId: {
            type: Number,
            default: null
        },
        customerName: {
            type: String,
            default: null
        },
        driverName: {
            type: String,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        rateConfigurationRule: {
            type: Object,
            default() {
                return {
                    rateRuleId: null,
                    isConfigRule: true,
                    reattemptCharges: null,
                    reattemptCosts: null,
                    customerId: null,
                    userId: null,
                    weightEquivalence: null,
                    rateGroupId: null
                };
            }
        },
        rateGroupId: {
            type: Number,
            default: null
        },
        rateGroupName: {
            type: String,
            default: null
        }
    },
    validations: {
        rateConfigurationRule: {
            reattemptCosts: {
                integer
            },
            reattemptCharges: {
                integer
            },
            weightEquivalence: {
                integer
            }
        }
    },
    watch: {
        'rateConfigurationRule.reattemptCosts': function(newValue) {
            if (newValue !== null && newValue.length === 0) {
                this.rateConfigurationRule.reattemptCosts = null;
            }
        },
        'rateConfigurationRule.reattemptCharges': function(newValue) {
            if (newValue !== null && newValue.length === 0) {
                this.rateConfigurationRule.reattemptCharges = null;
            }
        },
        'rateConfigurationRule.weightEquivalence': function(newValue) {
            if (newValue !== null && newValue.length === 0) {
                this.rateConfigurationRule.weightEquivalence = null;
            }
        }
    },
    async mounted() {
        this.setTitle();

        if (this.user.vehicleCapacityUnitsConfiguration) {
            const weight = this.user.vehicleCapacityUnitsConfiguration.find((x) => x.type === 'weight');
            const volume = this.user.vehicleCapacityUnitsConfiguration.find((x) => x.type === 'volume');

            if (weight) {
                this.teamWeightSymbol.symbol = weight.symbol;
            }

            if (volume) {
                this.teamVolumeSymbol.symbol = volume.symbol;
            }
        }
    },
    data() {
        return {
            title: null,
            teamWeightSymbol: {
                symbol: null
            },
            teamVolumeSymbol: {
                symbol: null
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    methods: {
        async save() {
            this.rateConfigurationRule.isConfigRule = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                let errorMessage = ``;

                if (
                    this.$v.rateConfigurationRule.reattemptCosts.$invalid &&
                    this.$v.rateConfigurationRule.reattemptCharges.$invalid
                ) {
                    errorMessage = `Values cannot be a decimal.`;
                } else if (this.$v.rateConfigurationRule.reattemptCosts.$invalid) {
                    errorMessage = `Reattempt Costs is invalid, cannot be decimal.`;
                } else if (this.$v.rateConfigurationRule.reattemptCharges.$invalid) {
                    errorMessage = `Reattempt Charges is invalid, cannot be decimal.`;
                }

                showErrorMessage(this, errorMessage, null);
                return;
            }

            try {
                this.$_handleLoaderState(true, 'SAVING...');

                const data = { ...this.rateConfigurationRule };
                const payload = {
                    method: 'post',
                    data
                };

                const endpoint = `/api/rates/save/rate-configuration`;
                await handleRequests(endpoint, payload);
            } catch (error) {
                const message = 'Error saving config.';
                showErrorMessage(this, message, null);
            } finally {
                this.$_handleLoaderState(false);
                this.close();
            }
        },
        async close() {
            this.resolve('ok');
        },
        setTitle() {
            if (this.driverName === null && this.customerId === null && this.rateGroupId === null) {
                this.title = `Global Configuration`;
            } else {
                this.title = `Configuration for `;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-body {
    max-height: 80vh;
}

.button-padding {
    margin: 5px !important;
    float: right !important;
}

.form-group {
    float: left;
    width: 50% !important;
    margin-top: 9px;
    padding-right: 5px;
}

.config-container {
    max-width: 800px;
    margin: 0px auto;
    position: relative;
    background-color: #fff;
    transition: all 0.3s ease;
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
    border-radius: 6px;
    border: none;
}

.scale {
    font-size: 100px !important;
    padding-top: 32px;
    margin-left: 60px;
}

.equivalence-item {
    margin-left: 55px;
    max-width: 60px !important;
}

.equivalence-container {
    margin-top: 15px;
}

.equivalence-number {
    max-width: 60px !important;
    flex-wrap: wrap;
}

.equivalence-symbol {
    padding-top: 7px !important;
    font-size: 0.95rem;
    padding-left: 2px;
}

.equivalence-volume {
    padding-top: 35px !important;
}
</style>
