var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          { staticClass: "form-wrapper", attrs: { validator: _vm.$v.addNote } },
          [
            _c(
              "tabs",
              {
                staticClass: "custom-tab-list",
                attrs: {
                  "tab-name": ["Notes", "Photos", "Documents", "Preview"],
                  "color-button": "warning",
                },
              },
              [
                _c("template", { slot: "tab-pane-1" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "form-group",
                        {
                          staticClass: "custom-textarea",
                          attrs: { name: "role" },
                        },
                        [
                          _c("md-textarea", {
                            directives: [{ name: "focus", rawName: "v-focus" }],
                            attrs: { "md-debounce": 100 },
                            on: { input: _vm.update },
                            model: {
                              value: _vm.form.note,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "note", $$v)
                              },
                              expression: "form.note",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("template", { slot: "tab-pane-2" }, [
                  _c("div", [
                    _c(
                      "div",
                      { attrs: { id: "notes-upload-multiple-image" } },
                      [
                        _c("vue-upload-multiple-image", {
                          attrs: {
                            "data-images": _vm.images,
                            "id-upload": "myIdUpload",
                            "edit-upload": "myIdEdit",
                            "drag-text": "Drag Images (Multiple)",
                            "browse-text": "(or) Select",
                            "primary-text": "Default",
                            "mark-is-primary": "Set as default",
                            "popup-text":
                              "This image will be displayed as the default",
                            "drop-text": "Drop your files here ...",
                            "show-primary": false,
                            "max-image": 10,
                          },
                          on: {
                            "upload-success": _vm.uploadImageSuccess,
                            "before-remove": _vm.beforeRemove,
                            "edit-image": _vm.editImage,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("template", { slot: "tab-pane-3" }, [
                  _c("div", [
                    _c(
                      "div",
                      [
                        _c("document-file-upload", {
                          attrs: { documents: _vm.documents },
                          on: {
                            "upload-success": _vm.uploadDocumentSuccess,
                            "upload-remove": _vm.uploadDocumentRemove,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("template", { slot: "tab-pane-4" }, [
                  _c("div", [
                    _c("div", {
                      staticClass: "add-note-preview",
                      domProps: { innerHTML: _vm._s(_vm.compiledMarkdown) },
                    }),
                    _c(
                      "div",
                      { staticClass: "photo-preview" },
                      _vm._l(_vm.imageList, function (image, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "md-avatar",
                              [
                                _c("img", {
                                  staticClass: "profile-image",
                                  attrs: { src: image.photoUrl, alt: "" },
                                  on: { error: _vm.$_setDefaultBrokenImage },
                                }),
                                _c(
                                  "md-tooltip",
                                  {
                                    staticClass: "tooltip-image-container",
                                    attrs: { "md-direction": "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "tooltip-image" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: image.photoUrl,
                                            alt: "",
                                          },
                                          on: {
                                            error: _vm.$_setDefaultBrokenImage,
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ],
              2
            ),
            _c("div", { staticClass: "note-type-container" }, [
              _c(
                "div",
                { staticClass: "type-field" },
                [
                  _c("label", { staticClass: "custom-label" }, [
                    _vm._v("Type"),
                  ]),
                  _c("vue-select", {
                    attrs: {
                      reduce: function (x) {
                        return x.name
                      },
                      label: "name",
                      options: _vm.noteTypes,
                      placeholder: "Status",
                      searchable: _vm.$root.isDesktop,
                      clearable: false,
                    },
                    model: {
                      value: _vm.form.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "type", $$v)
                      },
                      expression: "form.type",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _vm._m(1),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.saveNote },
          },
          [_vm._v("\n            Save\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Add Note")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stats" }, [
      _c("i", { staticClass: "md-icon md-icon-font md-theme-default" }, [
        _vm._v("emoji_objects"),
      ]),
      _c("span", { staticClass: "tip" }, [
        _vm._v("\n                You can also use the\n                "),
        _c(
          "a",
          {
            attrs: {
              href: "https://github.com/adam-p/markdown-here/wiki/Markdown-Here-Cheatsheet",
              target: "_blank",
            },
          },
          [_vm._v("\n                    Markdown syntax\n                ")]
        ),
        _vm._v("\n                to format your notes.\n            "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }