<template>
    <div class="filter-container">
        <!--<div class="search-field">
            <md-field v-if="teamMemberOptions.length > 1 && !isSingleUser && !isSingleTeamMember">
                <vue-select
                    :reduce="(fullName) => fullName.publicUserId"
                    label="fullName"
                    :options="teamMemberOptions"
                    placeholder="Team Member"
                    v-model="teamMemberFilterValue"
                    :searchable="$root.isDesktop"
                ></vue-select>
            </md-field>
        </div>-->
        <div class="search-field">
            <customer-autocomplete
                label="Customer"
                id="autocustomer"
                placeholder=""
                :should-focus="false"
                v-model="customerDetails"
                :auto-fill-address="false"
            />
        </div>
        <div class="search-field">
            <md-datepicker
                class="filter-steps--choices filter-date"
                v-model="tripDateFilterValue"
                md-immediately
                :md-debounce="100"
            >
                <label>Trip date</label>
            </md-datepicker>
        </div>
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { CustomerAutocomplete } from '@/components';

export default {
    name: 'FilterComponent',
    components: { CustomerAutocomplete },
    props: {
        teamMembers: {
            type: Function,
            default: () => []
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {
            teamMemberFilterValue: '',
            tripDateFilterValue: '',
            defaultOption: [
                {
                    publicUserId: 0,
                    fullName: 'Unassigned',
                    email: null
                }
            ],
            teamMemberOptions: [],
            customerDetails: {
                customerId: null,
                contact: {
                    name: null
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        })
    },
    methods: {
        // eslint-disable-next-line func-names
        handleFilter: _.debounce(function() {
            this.handleFilteredValue();
        }, 700),
        clearFilters() {
            this.customerFilterValue = '';
            this.teamMemberFilterValue = '';
            this.tripDateFilterValue = '';
        },
        handleFilteredValue() {
            // use nextTick to ensure that tripDate value has already been changed.
            // this is because the @selected method of md-datepicker is asynchronous.
            this.$nextTick(() => {
                const { customerFilterValue, teamMemberFilterValue, tripDateFilterValue } = this;
                let tripDate = '';
                if (tripDateFilterValue) {
                    tripDate = this.$options.filters.dateFormat(tripDateFilterValue, this.DATE_TYPES.internationalDate);
                }
                this.$emit('onFilterRunSchedule', {
                    teamMemberPublicUserId: teamMemberFilterValue,
                    customerId: customerFilterValue,
                    tripDate
                });
            });
        }
    },
    watch: {
        teamMemberFilterValue() {
            this.handleFilter();
        },
        'customerDetails.customerId': function() {
            this.customerFilterValue = this.customerDetails.customerId;
            this.handleFilter();
        },
        tripDateFilterValue() {
            this.handleFilter();
        },
        teamMembers() {
            this.teamMemberOptions = this.defaultOption.concat(this.teamMembers());
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-container {
    ::v-deep .search-field {
        display: inline-block;
        width: 210px;
        margin: 0px;
        margin-right: 10px;
        margin-top: -15px;
    }
    .filter-date {
        top: 7px;
    }
}
</style>
