var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "simple-wizard",
          {
            attrs: {
              plain: "",
              "show-header": false,
              title: _vm.title,
              "sub-title": _vm.subtitle,
            },
            on: { stepChange: _vm.stepChange },
          },
          [
            _c(
              "wizard-tab",
              { attrs: { "before-change": _vm.validateDetails, id: "step1" } },
              [
                _c("template", { slot: "label" }, [
                  _vm._v(
                    "\n                    Create SMS Template\n                "
                  ),
                ]),
                _c(
                  "div",
                  [
                    !_vm.isStepLoading
                      ? _c(
                          "form-wrapper",
                          {
                            staticClass: "form-wrapper",
                            attrs: { validator: _vm.$v.form },
                          },
                          [
                            _c(
                              "form-group",
                              { staticClass: "custom-merge-field" },
                              [
                                _c(
                                  "drop-down",
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass: "dropdown-toggle md-info",
                                        attrs: {
                                          slot: "title",
                                          "data-toggle": "dropdown",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                        slot: "title",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Insert Merge Field\n                                "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "dropdown-menu dropdown-menu-right",
                                      },
                                      _vm._l(_vm.mergeFields, function (event) {
                                        return _c("li", { key: event.key }, [
                                          event.key === "stopcustomfields"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "custom-field-header",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(event.name) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getSelectedMergeField(
                                                        event.key
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(event.name))]
                                              ),
                                        ])
                                      }),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "form-group",
                              {
                                staticClass: "custom-textarea",
                                attrs: { name: "template" },
                              },
                              [
                                _c("md-textarea", {
                                  ref: "template",
                                  model: {
                                    value: _vm.form.template,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "template", $$v)
                                    },
                                    expression: "form.template",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("div", [
                          _c(
                            "div",
                            { staticClass: "table-loader" },
                            [
                              _c("fade-loader", {
                                attrs: { loading: true, color: "#333333" },
                              }),
                              _c("span", [_vm._v("LOADING")]),
                            ],
                            1
                          ),
                        ]),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "wizard-tab",
              { attrs: { "before-change": _vm.validateDetails, id: "step2" } },
              [
                _c("template", { slot: "label" }, [
                  _vm._v(
                    "\n                    Select Stops\n                "
                  ),
                ]),
                !_vm.isStepLoading
                  ? _c(
                      "div",
                      { staticClass: "stop-table-container" },
                      [
                        _vm.stopList.length > 0
                          ? _c(
                              "md-table",
                              [
                                _c(
                                  "md-table-row",
                                  [
                                    _c("md-table-head"),
                                    _c(
                                      "md-table-head",
                                      [
                                        _c("md-checkbox", {
                                          staticClass:
                                            "stop-checkbox checkbox-head",
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSelectAll(
                                                _vm.isSelectAll
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.isSelectAll,
                                            callback: function ($$v) {
                                              _vm.isSelectAll = $$v
                                            },
                                            expression: "isSelectAll",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("md-table-head", [
                                      _vm._v("Reference Number"),
                                    ]),
                                    _c("md-table-head", [
                                      _vm._v("Assigned To"),
                                    ]),
                                    _c("md-table-head", [_vm._v("Status")]),
                                    _c("md-table-head", [_vm._v("Contact")]),
                                    _c("md-table-head", [_vm._v("Phone")]),
                                  ],
                                  1
                                ),
                                _vm._l(_vm.stopList, function (item) {
                                  return _c(
                                    "md-table-row",
                                    { key: item.stopId },
                                    [
                                      _c("md-table-cell", [
                                        !_vm.checkValidPhone(item.contact)
                                          ? _c(
                                              "div",
                                              { staticClass: "error-icon" },
                                              [
                                                _c("md-icon", [
                                                  _vm._v("error"),
                                                ]),
                                                _c(
                                                  "md-tooltip",
                                                  {
                                                    attrs: {
                                                      "md-direction": "right",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Invalid Phone Number"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c(
                                        "md-table-cell",
                                        [
                                          _c("md-checkbox", {
                                            staticClass: "stop-checkbox",
                                            attrs: {
                                              value: item.stopId,
                                              disabled: !_vm.checkValidPhone(
                                                item.contact
                                              ),
                                            },
                                            model: {
                                              value: _vm.selectedStops,
                                              callback: function ($$v) {
                                                _vm.selectedStops = $$v
                                              },
                                              expression: "selectedStops",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "md-table-cell",
                                        { staticClass: "stop-reference" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "Stop Details",
                                                  params: {
                                                    stopId: item.stopId,
                                                  },
                                                },
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(item.stopRef) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("md-table-cell", [
                                        item.assignedTo.fullName
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "assigned-column custom-ellipsis",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      item.assignedTo.fullName
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c(
                                        "md-table-cell",
                                        { staticClass: "status-container" },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(item.status) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              item.contact
                                                ? item.contact.name
                                                : ""
                                            ) +
                                            "\n                            "
                                        ),
                                      ]),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              item.contact
                                                ? item.contact.phone
                                                : ""
                                            ) +
                                            "\n                            "
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "table-loader" },
                        [
                          _c("fade-loader", {
                            attrs: { loading: true, color: "#333333" },
                          }),
                          _c("span", [_vm._v("LOADING")]),
                        ],
                        1
                      ),
                    ]),
              ],
              2
            ),
            _c(
              "wizard-tab",
              { attrs: { "before-change": _vm.validateDetails, id: "step3" } },
              [
                _c("template", { slot: "label" }, [
                  _vm._v(
                    "\n                    Confirmation\n                "
                  ),
                ]),
                _c("div", { staticClass: "confirmation-message" }, [
                  _vm.isPastTrip
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-warning alert-dismissible fade show",
                          attrs: { role: "alert" },
                        },
                        [
                          _c("strong", [_vm._v("ATTENTION!")]),
                          _vm._v(
                            "\n                        SMSs are being sent for stops on a trip in the past. Are you sure you want to do this?\n                    "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(
                    "\n                    Are you sure you want to send SMSs to " +
                      _vm._s(this.selectedStops.length) +
                      " stops?\n                    "
                  ),
                  _c("br"),
                  _vm._v(
                    '\n                    Click the "Finish" button to send.\n                '
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }