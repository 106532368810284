var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "day-time-container" },
      [
        _c("span", { staticClass: "custom-label" }, [_vm._v("Days")]),
        _c("md-input", {
          attrs: { id: "day-input", type: "number" },
          model: {
            value: _vm.dayField,
            callback: function ($$v) {
              _vm.dayField = _vm._n($$v)
            },
            expression: "dayField",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "day-time-container" },
      [
        _c("span", { staticClass: "custom-label" }, [_vm._v("Hours")]),
        _c("md-input", {
          attrs: { id: "hour-input", type: "number" },
          model: {
            value: _vm.hourField,
            callback: function ($$v) {
              _vm.hourField = _vm._n($$v)
            },
            expression: "hourField",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "day-time-container" },
      [
        _c("span", { staticClass: "custom-label" }, [_vm._v("Minutes")]),
        _c("md-input", {
          attrs: { id: "time-input", type: "number" },
          model: {
            value: _vm.timeField,
            callback: function ($$v) {
              _vm.timeField = _vm._n($$v)
            },
            expression: "timeField",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }