<template>
    <div>
        <form-wrapper :validator="$v.model" class="form-wrapper">
            <form-group label="Api Key" name="apiKey">
                <md-input v-model.trim="model.apiKey" />
            </form-group>
        </form-wrapper>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'GophrKeys',
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            model: {
                apiKey: ''
            }
        };
    },
    validations() {
        return {
            model: {
                apiKey: { required }
            }
        };
    },
    methods: {
        update() {
            this.$v.model.$touch();
            if (this.$v.model.$invalid) 
                return;

            this.$emit('onUpdate', { gophrAccess: { ...this.model } });
        }
    },
    watch: {
        data(value) {
            Object.assign(this.model, value);
        }
    }
};
</script>
