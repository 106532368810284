<template>
    <div v-if="isLocationHistoryToggled && !isStreetView && !isReadOnlyUser">
        <RoutePlaybackPath
            :paths="paths"
            :pinned-driver-details="pinnedDriverDetails"
            v-if="isPlaying && paths.length"
        />
        <RoutePlaybackMarker
            :current-marker-pos="currentMarkerPos"
            :pinned-driver-details="pinnedDriverDetails"
            v-if="currentMarkerPos !== null"
        />
        <div
            :class="{ playback: true, individual: isIndividual, 'map-panel': true }"
            :style="{ width: currentTime ? '220px' : '100px' }"
        >
            <button class="slow-button" @click="onPlayButtonClick(0.75)">
                <md-icon>
                    fast_rewind
                    <md-tooltip md-direction="top">{{ tooltipOptions.slow }}</md-tooltip>
                </md-icon>
            </button>
            <button class="play-button" @click="onPlayButtonClick(1)">
                <md-icon>
                    {{ isPlaying ? 'pause_circle_outline' : 'play_circle_outline' }}
                    <md-tooltip md-direction="top">{{ tooltipOptions.play }}</md-tooltip>
                </md-icon>
            </button>
            <button class="fast-button" @click="onPlayButtonClick(1 / 0.75)">
                <md-icon>
                    fast_forward
                    <md-tooltip md-direction="top">{{ tooltipOptions.play }}</md-tooltip>
                </md-icon>
            </button>
            <span class="playbackTime">{{ currentTime }}</span>
        </div>
        <div
            :class="{ slider: true, individual: isIndividual, 'map-panel': true }"
            @click="handleSliderOnClick($event)"
        >
            <vue-slider
                ref="slider"
                v-model="values"
                :marks="marks"
                :min="0"
                :max="1440"
                :lazy="true"
                :duration="0"
                :dot-options="dotOptions"
                :clickable="false"
                :process="process"
                @dragging="dragging"
                @drag-start="canDragSliderDot"
                @drag-end="isDragging = false"
                :order="false"
            >
                <template v-slot:dot="{ value, focus, index }">
                    <div v-if="index === 2" :class="['custom-dot', { index }]"></div>
                </template>
                <template v-slot:tooltip="{ value, focus }">
                    <div :class="['custom-tooltip', { focus }]">{{ tooltipFormat(value) }}</div>
                </template>
            </vue-slider>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import VueSlider from 'vue-slider-component';
import { ref, toRefs, inject, watch, computed, onMounted } from '@vue/composition-api';
import { toFixedNumber, getTimeInMs } from '@/helpers';
import useMapUtils from '@/compostables/useMapUtils';
import useGeneralUtils from '@/compostables/useGeneralUtils';
import RoutePlaybackPath from './RoutePlaybackPath';
import RoutePlaybackMarker from './RoutePlaybackMarker';

const getCurrentTimeBasedOnMinutes = (givenMinutes) => {
    const hours = Math.floor(givenMinutes / 60);
    const minutes = Math.floor(givenMinutes % 60);
    // eslint-disable-next-line no-nested-ternary
    const timePeriod = hours < 12 ? 'am' : hours === 24 ? 'mn' : 'pm';
    const formattedMinutes = minutes >= 10 ? minutes : `0${minutes}`;

    return `${hours % 12 || 12}:${formattedMinutes} ${timePeriod}`;
};

export default {
    name: 'RoutePlayback',
    components: {
        VueSlider,
        RoutePlaybackPath,
        RoutePlaybackMarker
    },
    props: {
        locationHistoryDetails: {
            type: Array,
            default: () => []
        },
        pinnedDriverDetails: {
            type: Object,
            default: () => {}
        },
        assetPresent: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { root }) {
        const { createLatLngPoint } = useMapUtils(root);
        const { pinnedDriverDetails } = toRefs(props);
        const { dateTypes } = useGeneralUtils(root);
        const { getters, commit } = inject('vuex-store');
        const google = inject('google');
        const isStreetView = inject('isStreetView');
        // const teamMembers = getters['team/teamMembers'];
        const isPlaying = computed(() => getters['map/isPlaying']);
        const timeWindow = computed(() => getters['map/timeWindow']);
        const pauseTime = computed(() => getters['map/pauseTime']);
        const playbackSpeed = computed(() => getters['map/playbackSpeed']);
        const date = computed(() => getters['map/date']);
        const isReadOnlyUser = computed(() => getters['user/isReadOnlyUser']);
        // const hasTeamAccess = getters['user/hasTeamAccess'];
        const isIndividualUser = getters['user/isIndividualUser'];
        // const hasTeam = getters['user/hasTeam'];
        const canViewTeamRegion = computed(() => getters['user/canViewTeamRegion']);
        // const isSingleTeamMember = getters['team/isSingleTeamMember'];

        // const { assetPresent } = toRefs(props);

        const currentMarkerPos = ref(null);

        const { locationHistoryDetails } = toRefs(props);

        const startTime = ref(0);
        const stopTime = ref(0);

        const currentTime = ref('');

        // const isIndividual = assetPresent.value ? false : teamMembers.length === 1;
        const isIndividual = isIndividualUser || !canViewTeamRegion;

        const tooltipOptions = {
            fast: 'Increase playback speed.',
            slow: 'Decrease playback speed',
            play: 'Play/stop the timeline history.'
        };

        const marks = ref({});

        const values = ref([startTime.value, stopTime.value, startTime.value]);

        const dotOptions = ref([
            {
                disabled: true
                // min: 0,
                // max: 0
            },
            {
                disabled: true
            },
            {
                disabled: false
                // min: 0,
                // max: 0
            }
        ]);

        const process = (dotsPos) => [
            [dotsPos[0], dotsPos[1], { backgroundColor: '#2b93ff' }],
            [dotsPos[1], dotsPos[2], { backgroundColor: '#ddd' }]
        ];
        const slider = ref(null);

        const isDragging = ref(false);

        const canDragSliderDot = () => {
            if (isPlaying.value) {
                dotOptions.value[1].disabled = true;
                return;
            }

            isDragging.value = true;
        };

        const dragging = (valueArr) => {
            // #todo : Implement this feature
            // if(valueArr[2] < valueArr[0]) {
            //     valueArr[2] = valueArr[0];
            // }
            // if(valueArr[2] > valueArr[1]) {
            //     valueArr[2] = valueArr[1];
            // }
            // nextTick(() => {
            //     values.value = [...valueArr];
            // });
        };

        const paths = ref([]);

        const resetTimeLine = () => {
            commit('map/PLAY_TIMELINE', { speed: 1, isPlaying: false, pauseTime: '' });
            paths.value = [];
            currentMarkerPos.value = null;
            setTimeout(() => {
                values.value = [startTime.value, stopTime.value, startTime.value];
                currentTime.value = '';
            }, 1000);
        };

        const handleSliderOnClick = (e) => {
            commit('map/PLAY_TIMELINE', { speed: 1, isPlaying: false });
            let timeInMinutes = 0;
            const sliderDotPosition = e.clientX;

            const $slider = slider.value.$el.parentNode;

            // get 95% of the total width of the slider as the vue-slider is only 95% in width of slider
            // the $slider has a 5px padding in the left we need to subtract that as well
            const sliderWidth = Math.floor($slider.getBoundingClientRect().width * 0.95 - 5);
            // the left and the right side of the slider consists of 2.5% space each.
            const spaceFromTheLeftOfTheSlider = sliderWidth * 0.025;

            // get the left width space based on the $slider
            const sliderLeft = Math.floor($slider.getBoundingClientRect().left);

            const sliderDotPositionPercentage =
                (sliderDotPosition - sliderLeft - spaceFromTheLeftOfTheSlider - 5) / sliderWidth;

            if (sliderDotPositionPercentage > 0) {
                timeInMinutes = Math.floor(sliderDotPositionPercentage * 1440);
                // If the click is happening outside timewindow, ignore
                if (timeInMinutes < startTime.value || timeInMinutes > stopTime.value) 
                    return;

                if (timeInMinutes <= 1440) {
                    const time = getCurrentTimeBasedOnMinutes(timeInMinutes);
                    currentTime.value = moment(`${moment().format('LL')} ${time}`).format('LT');

                    values.value = [startTime.value, stopTime.value, timeInMinutes];
                }
            } else if (sliderDotPositionPercentage === 0) {
                const time = getCurrentTimeBasedOnMinutes(0);
                currentTime.value = moment(`${moment().format('LL')} ${time}`).format('LT');

                values.value = [startTime.value, stopTime.value, startTime.value];
            }

            // Find the closest minutesFromMidnight in locationHistory and clip the Paths arr
            const minutesArr = locationHistoryDetails.value.map((a) => a.minutesFromMidnight);
            const closest = minutesArr.reduce((prev, curr) => {
                return Math.abs(curr - timeInMinutes) < Math.abs(prev - timeInMinutes) ? curr : prev;
            });
            const clipIndex = locationHistoryDetails.value.findIndex((a) => a.minutesFromMidnight === closest);
            if (clipIndex < paths.value.length) {
                paths.value.length = clipIndex;

                // Move marker to the last pos from the Paths array
                currentMarkerPos.value = {
                    lat: paths.value[clipIndex - 1].lat(),
                    lng: paths.value[clipIndex - 1].lng()
                };
            } else {
                const lastPoint = paths.value[paths.value.length - 1];
                currentMarkerPos.value = {
                    lat: lastPoint.lat(),
                    lng: lastPoint.lng()
                };
            }

            commit('map/CHANGE_TIME_WINDOW', { timeWindow: [toFixedNumber(0, 2), toFixedNumber(timeInMinutes, 2)] });
        };

        const tooltipFormat = (value) => {
            const hours = Math.floor(value / 60);
            const minutes = Math.floor(value % 60);
            const timePeriod = hours < 12 ? 'am' : 'pm';
            const formattedMinutes = minutes >= 10 ? minutes : `0${minutes}`;
            return `${hours % 12 || 12}:${formattedMinutes} ${timePeriod}`;
        };

        const handleMapInteractions = (lat, lng) => {
            currentMarkerPos.value = { lat, lng };
            const point = createLatLngPoint(google.value, lat, lng);
            paths.value.push(point);
        };

        const onPlayButtonClick = (speedMultiplier) => {
            let playStatus = true;
            let speed = playbackSpeed.value;
            const pauseTime = currentTime.value !== '12:00 am' ? currentTime.value : '';

            // pause playback
            if (speedMultiplier === 1 && isPlaying.value) {
                playStatus = false;
            }
            // set speed multiplier
            else {
                speed *= speedMultiplier;
            }

            if (!locationHistoryDetails.value.length) {
                root.$notify({
                    message: `User doesn't have location history data to playback.`,
                    type: 'warning'
                });
                return;
            }

            if (playStatus && playStatus !== isPlaying.value) {
                // eslint-disable-next-line no-use-before-define
                playTimeline();
            }

            commit('map/PLAY_TIMELINE', { speed, isPlaying: playStatus, pauseTime });
        };

        const playTimeline = () => {
            /*
                1 hour = 2 seconds
                24 hours playback (48 seconds)
                1 minute = 33.33333333 ms
                ratio of time to ms = currentTime * (33.333333/2000*24);
            */

            // sets lower bound of time window to check if the playback should start with 0 or with pause time
            const currentTimeInMinutes = timeWindow.value[0] === 0 && timeWindow.value[1] === 24 ? 0 : values.value[2];

            let timeSeriesData = [...locationHistoryDetails.value];

            // to do: create a pointer to avoid looping the entire array when transitioning from pause to play
            const index = timeSeriesData.findIndex(({ minutesFromMidnight }) => {
                return minutesFromMidnight >= currentTimeInMinutes;
            });

            timeSeriesData = timeSeriesData.splice(index);
            // get the starting tick based on the timewindow and/or pauseTime
            let i;
            const paused = pauseTime.value ? getTimeInMs(pauseTime.value) / 1800 : '';
            const timeWindowTime = (timeWindow.value[0] * 3600 * 1000) / 1800;

            if (paused) {
                i = paused > timeWindowTime ? paused : timeWindowTime;
            } else {
                i = timeWindowTime;
            }

            // the current time corresponds to the last entry of the time series data
            if (
                !timeSeriesData ||
                (currentTimeInMinutes !== 0 &&
                    timeSeriesData[0].minutesFromMidnight ===
                        locationHistoryDetails.value[locationHistoryDetails.value.length - 1].minutesFromMidnight)
            ) {
                commit('map/PLAY_TIMELINE', { speed: 1, isPlaying: false });
                // if true, run playback from beginning of day
                values.value = [startTime.value, stopTime.value, startTime.value];
                const time = getCurrentTimeBasedOnMinutes(startTime.value);
                currentTime.value = moment(`${moment().format('LL')} ${time}`).format('LT');
                paths.value = [];

                root.$nextTick(() => {
                    onPlayButtonClick(1);
                });
                // eslint-disable-next-line consistent-return
                return true;
            }

            let timeSeriesToDisplay = 0;

            // let minutes = 0;
            // eslint-disable-next-line no-unused-vars
            // let hours = 0;
            const interval = 33.33333333;

            // get the last tick of time based on the timewindow
            // for now the last tick is 12mn
            // const endAnimationTime = (24 * 3600 * 1000) / 1800;

            let runningTime = currentTimeInMinutes;

            // eslint-disable-next-line consistent-return
            const playNextAnimation = (i, t) => {
                if (t <= timeSeriesData.length - 1 && runningTime === timeSeriesData[t].minutesFromMidnight) {
                    const {
                        coordinates: { latitude, longitude }
                    } = timeSeriesData[t];
                    handleMapInteractions(latitude, longitude);
                    timeSeriesToDisplay += 1;
                } else {
                    // eslint-disable-next-line no-param-reassign
                    i += interval;

                    // increment = 33.333333ms
                    // %increase = 33.333333/(48*1000);
                    // increase = %increase * width;
                    // this.circleOptions.width = i / (48 * 1000);

                    runningTime += 1;
                    const [start, end, current] = values.value;

                    // if current is >= 1440 break the flow
                    let time;
                    if (current >= 1439) {
                        resetTimeLine();
                    } else {
                        values.value = [start, end, current + 1];
                        time = getCurrentTimeBasedOnMinutes(current + 1);
                    }
                    currentTime.value = moment(`${moment().format('LL')} ${time}`).format('LT');
                }

                // playback paused or
                // end playback on last timeSeriesData
                if (!isPlaying.value || timeSeriesToDisplay > timeSeriesData.length - 1) {
                    // playback paused before end of day
                    if (currentTime.value) {
                        // last timeSeriesData reached
                        if (timeSeriesToDisplay > timeSeriesData.length - 1) {
                            commit('map/PLAY_TIMELINE', { speed: 1, isPlaying: false });
                        }

                        // eslint-disable-next-line no-unused-vars
                        const [start, end, current] = values.value;

                        commit('map/CHANGE_TIME_WINDOW', {
                            timeWindow: [toFixedNumber(start, 2), toFixedNumber(current, 2)]
                        });
                    }
                } else {
                    // continue playback
                    setTimeout(() => {
                        playNextAnimation(i, timeSeriesToDisplay);
                    }, interval / playbackSpeed.value);
                }
            };

            // interval/this.playbackSpeed determines how frequent the playNextAnimation will be called.
            // ie if this.playbackSpeed is larger, the timeOut becomes smaller so the animation becomes faster.
            return setTimeout(() => {
                playNextAnimation(i, timeSeriesToDisplay);
            }, interval / playbackSpeed.value);
        };

        onMounted(() => {
            const times = [];
            for (let i = 0; i <= 24; i++) {
                times.push(root.$options.filters.timeFormat({ hour: i }, dateTypes.standardTime).replace(/:\d\d/, ''));
            }
            marks.value = times.reduce((obj, item, index) => {
                obj[index * 60] = item;
                return obj;
            }, {});
        });

        const isLocationHistoryToggled = computed(() => getters['map/isLocationHistoryToggled']);

        watch(isLocationHistoryToggled, (newVal) => {
            if (!newVal) {
                commit('map/PLAY_TIMELINE', { speed: 1, isPlaying: false, pauseTime: '' });
            } else {
                const time = getCurrentTimeBasedOnMinutes(0);
                currentTime.value = moment(`${moment().format('LL')} ${time}`).format('LT');
                values.value = [startTime.value, stopTime.value, startTime.value];
            }
        });

        watch(pinnedDriverDetails, resetTimeLine);
        watch(date, resetTimeLine);

        watch(locationHistoryDetails, () => {
            startTime.value = locationHistoryDetails.value.length
                ? locationHistoryDetails.value[0].minutesFromMidnight
                : 0;
            const l = locationHistoryDetails.value.length;
            stopTime.value = l ? locationHistoryDetails.value[l - 1].minutesFromMidnight : 0;
            values.value = [startTime.value, stopTime.value, startTime.value];
        });

        return {
            isStreetView,
            isLocationHistoryToggled,
            isIndividual,
            date,
            currentTime,
            isPlaying,
            marks,
            values,
            dotOptions,
            process,
            canDragSliderDot,
            dragging,
            isDragging,
            onPlayButtonClick,
            tooltipOptions,
            handleSliderOnClick,
            slider,
            tooltipFormat,
            paths,
            currentMarkerPos,
            resetTimeLine,
            startTime,
            stopTime,
            isReadOnlyUser
        };
    }
};
</script>

<style lang="scss" scoped>
$themeColor: #2b93ff;
$bgColor: #d3e8ff;
.md-tooltip-top {
    &.toggle-tooltip {
        // offset for pooper.js
        margin-left: 30px !important;
    }
}
.toggled-options {
    right: 10px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    height: auto;
    display: flex;
    &.individual {
        bottom: 25px;
    }
    &.pinned {
        bottom: var(--bottom-placement);
    }
    button {
        padding: 10px;
        border: none;
        background: transparent;
        cursor: pointer;
        margin: 0 auto;
        &.active {
            ::v-deep .md-icon {
                color: $themeColor;
            }
        }
        &.disabled {
            ::v-deep .md-icon {
                color: #b2b2b2;
                opacity: 0.25;
                cursor: not-allowed !important;
            }
        }
    }
}
.playback {
    position: absolute;
    bottom: 60px;
    width: 165px;
    background-color: rgba(255, 255, 255, 0.9);
    height: auto;
    padding: 10px;
    left: 360px;
    // 360px because side panel is 340 + 20px spacing
    display: flex;
    &.individual {
        left: 10px;
    }
    .play-button,
    .slow-button,
    .fast-button {
        cursor: pointer;
        border: 0;
        background: none;
        box-shadow: none;
    }
    .play-button {
        .md-icon {
            width: 28px;
            height: 28px;
            min-width: 28px;
            min-height: 28px;
            font-size: 28px !important;
        }
    }
    .slow-button,
    .fast-button {
        padding-left: 0px;
        padding-right: 0px;
        .md-icon {
            width: 18px;
            height: 18px;
            min-width: 18px;
            min-height: 18px;
            font-size: 18px !important;
        }
    }
    .playbackTime {
        align-self: center;
        margin-left: 10px;
    }
}
.slider {
    background-color: rgba(255, 255, 255, 0.9);
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    bottom: 25px;
    left: 360px;
    width: calc(100% - 370px);
    // 360px from team-members + space and 10px for right spacing
    font-size: 11px;
    height: 30px;
    &.individual {
        width: calc(100% - 20px);
        left: 10px;
    }
    // overrides
    ::v-deep .vue-slider {
        .vue-slider-rail {
            background-color: rgb(221, 221, 221);
        }
        .vue-slider-mark-step {
            background-color: #bda1f3;
        }
        .vue-slider-mark-step-active {
            background-color: #6200ee;
        }
        .vue-slider-process:nth-child(2) {
            background-color: #d0afff !important;
        }
    }
}
.custom-dot {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: red;
    transition: all 0.3s;
}
.custom-dot.focus {
    border-radius: 50%;
}
.custom-tooltip {
    transform: translateY(5px);
    width: 50px;
    text-align: center;
}
.custom-tooltip.focus {
    font-weight: bold;
}
</style>
