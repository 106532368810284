var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasCustomerFilterAccess
    ? _c("div", { staticClass: "custom-toolbar state-filter" }, [
        _c(
          "div",
          { staticClass: "custom-toolbar-end" },
          [
            _vm.customerList.length > 0
              ? _c(
                  "md-field",
                  { staticClass: "filter-customer" },
                  [
                    _c("vue-select", {
                      attrs: {
                        options: _vm.customerList,
                        reduce: function (cl) {
                          return cl.customerId
                        },
                        label: "name",
                        placeholder: "Customer",
                        searchable: _vm.$root.isDesktop,
                      },
                      model: {
                        value: _vm.customerFilterValue,
                        callback: function ($$v) {
                          _vm.customerFilterValue = $$v
                        },
                        expression: "customerFilterValue",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }