<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.smsSettings') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>sms</md-icon>
                    </div>
                </md-card-header>

                <md-card-content>
                    <div class="member-container">
                        <sms-settings level="teams" />
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { SmsSettings } from '@/components';
import { mapGetters } from 'vuex';

export default {
    name: 'SmsSetting',
    components: {
        SmsSettings
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam'
        })
    }
};
</script>
<style lang="scss" scoped>
.card-icon {
    background: #2b93ff !important;
}
</style>
