// eslint-disable-next-line import/no-cycle
import { handleRequests } from '@/helpers/index';
import i18n from '@/i18n';
import {
    FETCH_TEAM_MEMBERS,
    UPDATE_TEAM_MEMBERS,
    FETCH_TEAM_INVITES,
    UPDATE_TEAM_INVITES,
    FETCH_TEAM_REGIONS,
    SET_TEAM_REGIONS,
    FETCH_WAREHOUSES,
    SET_WAREHOUSES,
    FETCH_TEAM_CARRIERS,
    SET_TEAM_CARRIERS,
    FETCH_TEAM_CUSTOMERS,
    SET_TEAM_CUSTOMERS,
    FETCH_TEAM_HAS_CUSTOMERS,
    SET_TEAM_HAS_CUSTOMERS
} from '../types';

const state = {
    teamMembers: [],
    inviteList: [],
    teamRegions: [],
    warehouses: [],
    teamCarriers: [],
    customers: [],
    hasCustomers: false
};

const getters = {
    teamMembers: (state) => state.teamMembers,
    isSingleTeamMember: (state) => state.teamMembers.length <= 1,
    teamRegions: (state) => state.teamRegions,
    warehouses: (state) => state.warehouses,
    teamStatus: (state, getters, rootState) => {
        return rootState.user.teamStatus;
    },
    isTrialExpired: (state, getters, rootState) => {
        return !(
            rootState.user.teamStatus === 'Unknown' ||
            rootState.user.teamStatus === 'Active' ||
            rootState.user.teamStatus === 'Disabled?'
        );
    },
    teamCarriers: (state) => state.teamCarriers,
    hasActiveCarriers: (state) => {
        if (state.teamCarriers == null || state.teamCarriers.length === 0) 
            return false;

        const activeCarriers = state.teamCarriers.filter((x) => x.status === 'Active');

        return activeCarriers.length > 0;
    },
    activeCarriers: (state) => (!state.teamCarriers ? [] : state.teamCarriers.filter((x) => x.status === 'Active')),
    customers: (state) => state.customers,
    hasCustomers: (state) => state.hasCustomers
};

const mutations = {
    [UPDATE_TEAM_MEMBERS]: (state, payload) => {
        state.teamMembers = payload;
    },
    [UPDATE_TEAM_INVITES]: (state, payload) => {
        state.inviteList = payload;
    },
    [SET_TEAM_REGIONS]: (state, payload) => {
        state.teamRegions = [...payload];
    },
    [SET_WAREHOUSES]: (state, payload) => {
        state.warehouses = [...payload];
    },
    [SET_TEAM_CARRIERS]: (state, payload) => {
        state.teamCarriers = [...payload];
    },
    [SET_TEAM_CUSTOMERS]: (state, payload) => {
        state.customers = [...payload];
    },
    [SET_TEAM_HAS_CUSTOMERS]: (state, payload) => {
        state.hasCustomers = payload;
    }
};

const actions = {
    async [FETCH_TEAM_MEMBERS](
        { commit },
        payload = {
            date: '',
            includeLocationDetails: false,
            includeTripDetails: false,
            teamRegionId: null,
            runNumber: null,
            strictTeamRegionFilter: true,
            elevatedTeamRegionFilter: false
        }
    ) {
        const {
            date,
            includeLocationDetails,
            includeTripDetails,
            teamRegionId,
            runNumber,
            strictTeamRegionFilter,
            elevatedTeamRegionFilter
        } = payload;
        const url = `/api/team-members?date=${date}&includeLocationDetails=${includeLocationDetails}&includeTripDetails=${includeTripDetails}&teamRegionId=${teamRegionId}&strictTeamRegionFilter=${strictTeamRegionFilter}&elevatedTeamRegionFilter=${elevatedTeamRegionFilter}&runNumber=${runNumber}`;
        const { data } = await handleRequests(url);
        const response = data.data;

        commit(UPDATE_TEAM_MEMBERS, response);
        return response;
    },
    async [FETCH_TEAM_INVITES]({ commit }) {
        let loader = {
            isLoading: true,
            loadingText: i18n.t('common.loadingText')
        };
        commit('CHANGE_LOADING_STATE', loader, { root: true });
        const url = '/api/team-members/invite';
        const { data } = await handleRequests(url);

        loader = {
            isLoading: false,
            loadingText: i18n.t('common.loadingText')
        };
        commit('CHANGE_LOADING_STATE', loader, { root: true });
        commit(UPDATE_TEAM_INVITES, data.data);
        return data.data;
    },
    async [FETCH_TEAM_REGIONS]({ commit }) {
        const url = '/api/teams/team-regions';
        const { data } = await handleRequests(url);
        commit(SET_TEAM_REGIONS, data);
        return data;
    },
    async [FETCH_WAREHOUSES]({ commit }) {
        const url = `/api/warehouses?teamRegionId=&itemsPerPage=999`; // we are fetching all
        try {
            const { data } = await handleRequests(url);
            if (data && data.warehouses) {
                commit(SET_WAREHOUSES, [...data.warehouses]);
                return data.warehouses;
            }
            commit(SET_WAREHOUSES, []);
            return [];
        } catch (e) {
            commit(SET_WAREHOUSES, []);
            return [];
        }
    },
    async [FETCH_TEAM_CARRIERS]({ commit, rootGetters }) {
        if (rootGetters['user/hasMarketplaceAccess']) {
            const url = '/api/teams/carriers/list';
            const { data } = await handleRequests(url);
            commit(SET_TEAM_CARRIERS, data?.carriers || []);
            return data;
        }
        return [];
    },
    async [FETCH_TEAM_CUSTOMERS]({ commit }) {
        const url = '/api/customers/lookup';
        const { data } = await handleRequests(url);
        commit(SET_TEAM_CUSTOMERS, data?.items || []);
        return data;
    },
    async [FETCH_TEAM_HAS_CUSTOMERS]({ commit }) {
        const url = '/api/customers/hasAny';
        const { data } = await handleRequests(url);
        commit(SET_TEAM_HAS_CUSTOMERS, data || false);
        return data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
