import { render, staticRenderFns } from "./ProofOfDeliveryTemplateParent.vue?vue&type=template&id=542da968&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\a\\1\\s\\Locate2u.AppPortal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('542da968')) {
      api.createRecord('542da968', component.options)
    } else {
      api.reload('542da968', component.options)
    }
    module.hot.accept("./ProofOfDeliveryTemplateParent.vue?vue&type=template&id=542da968&", function () {
      api.rerender('542da968', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/TeamSettings/ProofOfDelivery/ProofOfDeliveryTemplateParent.vue"
export default component.exports