<template>
    <div class="filter-inline">
        <md-field v-if="canViewTeamRegion && teamRegions && teamRegions.length > 0">
            <team-region-filter-options
                :team-region-id="teamRegionId"
                @changed="handleTeamRegionChanged"
                :clearable="false"
            ></team-region-filter-options>
        </md-field>
        <md-field v-if="teamMembers.length > 0">
            <vue-select
                class="select"
                label="text"
                :options="filteredOptions"
                :placeholder="teamMemberOptionsPlaceholder"
                v-model="selectedTeamMember"
                :searchable="$root.isDesktop"
                :selectable="(option) => option.selectable"
            >
                <template v-slot:option="option">
                    <span v-if="option.selectable">{{ option.text }}</span>
                    <hr v-else />
                </template>
            </vue-select>
        </md-field>
    </div>
</template>
<style lang="scss" scoped>
.select {
    min-width: 200px;
}

::v-deep .vs__selected-options {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-wrap: nowrap;
}
::v-deep .vs__dropdown-menu {
    min-width: fit-content;
}
.filter-inline {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        margin-right: 20px;
    }
}
</style>
<script>
import { GeneralMixin } from '@/mixins';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { handleRequests } from '@/helpers';
import TeamRegionFilterOptions from '@/components/TeamRegionFilterOptions';

export default {
    name: 'TeamMembersFilter',
    mixins: [GeneralMixin],
    components: { TeamRegionFilterOptions },
    props: {
        emitOnClear: {
            type: Boolean,
            default: () => false
        },
        includeAsset: {
            type: Boolean,
            default: () => true
        }
    },
    data() {
        return {
            teamMembers: [],
            selectedTeamMember: '',
            teamRegionId: null
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isSingleUser: 'user/isIndividualUser',
            canEditOwnTeamRegion: 'user/canEditOwnTeamRegion',
            canViewTeamRegion: 'user/canViewTeamRegion',
            isSingleTeamMember: 'team/isSingleTeamMember',
            teamRegions: 'team/teamRegions'
        }),
        filteredOptions() {
            if (this.teamMembers && this.teamMembers.length > 0) {
                if (this.teamRegionId === null) {
                    // All
                    return this.teamMembers;
                }
                if (this.teamRegionId === -1) {
                    // None
                    return this.teamMembers.filter((x) => x.text === null || x.objectReference.teamRegionId === null);
                }
                // specific
                return this.teamMembers.filter(
                    (x) =>
                        x.text === null ||
                        // x.objectReference.teamRegionId === null ||
                        x.objectReference.teamRegionId === this.teamRegionId
                );
            }
            return [];
        },
        teamMemberOptionsPlaceholder() {
            if (this.includeAsset) 
                return 'Team Member or Asset Group';
            return 'Team Member';
        }
    },
    async mounted() {
        // this.teamRegionId = this.user.teamRegionId ? this.user.teamRegionId : null; // null = All
        this.teamRegionId = null; // null = All
        this.getTeamMembers();
    },
    watch: {
        selectedTeamMember(newValue, oldValue) {
            this.handleFilter();
        }
    },
    methods: {
        // eslint-disable-next-line func-names
        handleFilter: _.debounce(function() {
            this.handleFilteredValue();
        }, 100),
        handleFilteredValue() {
            // use nextTick to ensure that tripDate value has already been changed.
            // this is because the @selected method of md-datepicker is asynchronous.
            this.$nextTick(() => {
                // Remove from `name` field all characters that are illegal for filenames.
                // Regular Expression taken from: https://stackoverflow.com/questions/42210199/remove-illegal-characters-from-a-file-name-but-leave-spaces

                if (!this.selectedTeamMember) {
                    if (this.emitOnClear) {
                        this.$emit('onSelection', { teamRegionId: this.teamRegionId });
                    }
                    return;
                }
                const obj = {
                    userId: this.selectedTeamMember.isTracker
                        ? this.selectedTeamMember.objectReference.trackerReference
                        : this.selectedTeamMember.objectReference.publicUserId,
                    trackerId: this.selectedTeamMember.isTracker
                        ? this.selectedTeamMember.objectReference.trackerId
                        : null,
                    name: (this.selectedTeamMember.isTracker
                        ? this.selectedTeamMember.objectReference.groupName
                        : this.selectedTeamMember.objectReference.fullName
                    ).replace(/[/\\?%*:|"<>]/g, '-'),
                    isTracker: this.selectedTeamMember.isTracker,
                    teamRegionId: this.teamRegionId
                };
                this.$emit('onSelection', obj);
            });
        },
        async getTeamMembers() {
            const array = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS');
            this.teamMembers = array.map((tm) => ({
                text: tm.fullName,
                isTracker: false,
                objectReference: tm,
                selectable: true
            }));

            if (array.length > 0) {
                this.teamMembers.push({ text: null, selectable: false });
            }

            if (this.includeAsset) {
                const endpoint = `/api/asset-groups`;
                const response = await handleRequests(endpoint);
                const { data: assetGroups } = response.data;
                if (assetGroups && assetGroups.length > 0) {
                    this.teamMembers.push(
                        // We probably don't want to display asset groups that don't have any tracker assigned.
                        ...assetGroups
                            .filter((ag) => ag.trackerId !== null)
                            .map((ag) => ({
                                text: ag.groupName,
                                isTracker: true,
                                objectReference: ag,
                                selectable: true
                            }))
                    );
                }
            }
        },
        handleTeamRegionChanged(teamRegionId) {
            this.teamRegionId = teamRegionId;
            if (this.selectedTeamMember !== '') 
                this.selectedTeamMember = '';
            else 
                this.handleFilter(); // Emit the event in case there is no alteration to value.
        }
    }
};
</script>
