<template>
    <div class="md-layout stop-details">
        <div
            :class="['md-layout-item md-size-100 alert alert-warning', isBeforeEndTime ? 'alert-bg-color' : '']"
            v-if="isBeforeStartTime"
        >
            The trip start time has already passed. The routing timeframe will be limited to between the current time
            and the designated end time.
        </div>
        <div class="md-layout-item md-size-40 team-region-field" v-if="teamRegions && teamRegions.length > 0">
            <label>Team Region</label>
            <team-region-filter-options
                :team-region-id="data.teamRegionStartLocation"
                @changed="handleStartTeamRegionChanged"
                :clearable="false"
            ></team-region-filter-options>
        </div>
        <div class="md-layout-item md-size-50 customer-field" v-if="hasCustomers">
            <customer-autocomplete
                label="Customer"
                id="autocustomer"
                ref="customerAutocomplete"
                placeholder=""
                :should-focus="false"
                v-model="customer"
                :auto-fill-address="true"
                @input="handleCustomerChanged"
                @change="handleCustomerTextChanged"
                :is-clearable="true"
                :team-region-id="data.teamRegionStartLocation"
            />
        </div>
        <div class="md-layout-item md-size-100 trip-option-box">
            <md-radio v-model="data.startLocationMode" value="manual">Enter a start location</md-radio>
            <md-radio v-model="data.startLocationMode" value="teammembers">
                Team members start from their home location
            </md-radio>
            <md-radio
                v-model="data.startLocationMode"
                value="warehouse"
                v-if="warehouseOptions && warehouseOptions.length > 0"
            >
                Warehouse
            </md-radio>
        </div>
        <div
            :class="['md-layout md-layout-item md-size-100', data.startLocationMode == 'manual' ? 'address-label' : '']"
        >
            <div class="md-layout-item md-size-80 address" v-show="data.startLocationMode == 'manual'">
                <google-autocomplete
                    ref="startlocation"
                    id="startlocationId"
                    v-model="data.startLocation"
                    classname="form-control autocomplete-input"
                    label="Address"
                    icon="add_location"
                    placeholder=""
                    @placechanged="onStartLocationChanged"
                    @inputChange="onStartLocationInputChange"
                ></google-autocomplete>
            </div>

            <div class="md-layout-item md-size-80 warehouse-field" v-show="data.startLocationMode == 'warehouse'">
                <md-field>
                    <md-icon>add_location</md-icon>
                    <vue-select
                        :reduce="(x) => x.warehouseId"
                        label="name"
                        :options="startLocationWarehouseOptions"
                        placeholder="Select warehouse start location"
                        v-model="data.warehouseStartLocation"
                    ></vue-select>
                </md-field>
            </div>
            <div
                :class="[
                    'md-layout-item md-size-20',
                    data.startLocationMode == 'manual' ? 'run-number-manual' : 'run-number'
                ]"
            >
                <form-group name="runnumber" label="Run #">
                    <md-input type="number" v-model.number="data.runNumber" min="1" />
                </form-group>
            </div>
        </div>
        <div class="md-layout-item md-size-100" @click="toggleChange($event, 0)">
            <div class="end-location-label">End Location</div>
            <md-card class="end-location-card">
                <md-card-expand>
                    <md-card-actions>
                        <div class="end-location-value">
                            {{ endLocationText }}
                        </div>
                        <md-card-expand-trigger>
                            <md-button class="btn-change">Change</md-button>
                        </md-card-expand-trigger>
                    </md-card-actions>
                    <md-card-expand-content>
                        <md-card-content>
                            <div class="md-size-100 option-box">
                                <md-radio v-model="data.endLocationMode" value="none">
                                    {{ endLocationOptions['none'] }}
                                </md-radio>
                                <md-radio v-model="data.endLocationMode" value="startlocation" class="text-padding">
                                    {{ endLocationOptions['startlocation'] }}
                                </md-radio>
                            </div>
                            <div class="md-size-100 option-box">
                                <md-radio v-model="data.endLocationMode" value="teammembers">
                                    {{ endLocationOptions['teammembers'] }}
                                </md-radio>
                                <md-radio v-model="data.endLocationMode" value="manual">
                                    {{ endLocationOptions['manual'] }}
                                </md-radio>
                            </div>
                            <div class="md-size-100 option-box" v-if="warehouseOptions && warehouseOptions.length > 0">
                                <md-radio v-model="data.endLocationMode" value="warehouse">
                                    {{ endLocationOptions['warehouse'] }}
                                </md-radio>
                            </div>
                            <div
                                class="md-size-100 address-margin address-label"
                                v-show="data.endLocationMode == 'manual'"
                            >
                                <google-autocomplete
                                    ref="endlocation"
                                    id="endlocationId"
                                    v-model="data.endLocation"
                                    classname="form-control autocomplete-input"
                                    label="Address"
                                    icon="add_location"
                                    placeholder=""
                                    @placechanged="onEndLocationChanged"
                                ></google-autocomplete>
                            </div>
                        </md-card-content>
                    </md-card-expand-content>
                </md-card-expand>
            </md-card>
        </div>
        <div
            class="md-layout-item md-size-100 end-warehouse-field"
            v-show="data.endLocationMode == 'warehouse' && isActive === 1"
        >
            <md-field>
                <md-icon>add_location</md-icon>
                <vue-select
                    :reduce="(x) => x.warehouseId"
                    label="name"
                    :options="endLocationWarehouseOptions"
                    placeholder="Select warehouse end location"
                    v-model="data.warehouseEndLocation"
                ></vue-select>
            </md-field>
        </div>
        <div class="md-layout-item md-size-35">
            <div class="trip-date-field">
                <md-datepicker v-model="data.tripDate" md-immediately>
                    <label>Date</label>
                </md-datepicker>
                <span v-if="data.tripDate == null" class="error">Trip date is required.</span>
            </div>
        </div>
        <div class="md-layout-item md-size-30">
            <form-group name="starttime" label="Start Time" class="timer-field">
                <md-icon>access_time</md-icon>
                <time-picker
                    :time="getStartTime === null ? 'None' : getStartTime"
                    :all-time-options="false"
                    @selectedTime="setStartTime"
                />
            </form-group>
        </div>
        <div class="md-layout-item md-size-30">
            <form-group name="endtime" label="End Time" class="timer-field">
                <md-icon>access_time</md-icon>
                <time-picker
                    :time="getEndTime === null ? 'None' : getEndTime"
                    :all-time-options="false"
                    @selectedTime="setEndTime"
                />
            </form-group>
        </div>
        <div class="md-layout-item md-size-100">
            <md-checkbox v-model="data.skillMatching">Skill matching ON</md-checkbox>
        </div>
        <div class="md-layout-item md-size-100">
            <md-checkbox v-model="data.teamMemberTimingOverride">
                Use the start/end times on the team member profiles (where configured), overriding the times above
            </md-checkbox>
        </div>
        <div class="md-layout-item md-size-100">
            <md-checkbox v-model="data.addReturnStartLocationStop">
                Add return to start location for existing trips
            </md-checkbox>
        </div>
        <div class="md-layout-item md-size-100">
            <md-checkbox v-model="data.ignoreTimeWindows">
                Ignore Time Windows
            </md-checkbox>
        </div>
        <div class="md-layout-item md-size-100">
            <md-checkbox v-model="data.contiguityConstraint">
                Route with non-overlapping areas
            </md-checkbox>
        </div>
        <div class="md-layout-item md-size-100">
            <md-checkbox v-model="data.excludeStopsWithNoDate">
                Exclude stops with no date
            </md-checkbox>
        </div>
    </div>
</template>
<script>
import { TimePicker, GoogleAutocomplete, CustomerAutocomplete } from '@/components';
import TeamRegionFilterOptions from '@/components/TeamRegionFilterOptions';
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'Stopdetails',
    components: {
        TimePicker,
        GoogleAutocomplete,
        TeamRegionFilterOptions,
        CustomerAutocomplete
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        warehouses: {
            type: Array,
            default: () => {}
        }
    },
    data() {
        return {
            isBeforeStartTime: false,
            isBeforeEndTime: false,
            startLocationMode: null,
            startLocationWarehouseOptions: [],
            warehouseOptions: [],
            endLocationText: null,
            endLocationOptions: {
                none: 'No end location',
                startlocation: 'Return to start location',
                teammembers: "Team member's default end location",
                manual: 'Enter an end location',
                warehouse: 'Warehouse'
            },
            endLocationWarehouseOptions: [],
            isActive: 0,
            customerId: undefined,
            customer: {
                customerId: undefined,
                contact: {
                    name: ''
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            getStartLocationMode: 'optimiseSetting/getStartLocationMode',
            getStartLocation: 'optimiseSetting/getStartLocation',
            getWarehouseStartLocation: 'optimiseSetting/getWarehouseStartLocation',
            getTeamRegionStartLocation: 'optimiseSetting/getTeamRegionStartLocation',
            getStartTime: 'optimiseSetting/getStartTime',
            getEndTime: 'optimiseSetting/getEndTime',
            getEndLocationMode: 'optimiseSetting/getEndLocationMode',
            getEndLocation: 'optimiseSetting/getEndLocation',
            getWarehouseEndLocation: 'optimiseSetting/getWarehouseEndLocation',
            getSkillMatching: 'optimiseSetting/getSkillMatching',
            getTeamMemberTimingOverride: 'optimiseSetting/getTeamMemberTimingOverride',
            getIgnoreTimeWindows: 'optimiseSetting/getIgnoreTimeWindows',
            getReturnStartLocationStop: 'optimiseSetting/getReturnStartLocationStop',
            getContiguityConstraint: 'optimiseSetting/getContiguityConstraint',
            getExcludeStopsWithNoDate: 'optimiseSetting/getExcludeStopsWithNoDate',
            teamRegions: 'team/teamRegions',
            hasCustomers: 'team/hasCustomers',
            user: 'user/user'
        })
    },
    methods: {
        ...mapMutations({
            saveStartLocationMode: 'optimiseSetting/UPDATE_START_LOCATION_MODE',
            saveStartLocation: 'optimiseSetting/UPDATE_START_LOCATION',
            saveStartTime: 'optimiseSetting/UPDATE_START_TIME',
            saveEndTime: 'optimiseSetting/UPDATE_END_TIME',
            saveEndLocationMode: 'optimiseSetting/UPDATE_END_LOCATION_MODE',
            saveEndLocation: 'optimiseSetting/UPDATE_END_LOCATION',
            saveSkillMatching: 'optimiseSetting/UPDATE_SKILL_MATCHING',
            saveTeamMemberTimingOverride: 'optimiseSetting/UPDATE_TEAM_MEMBER_TIMING_OVERRIDE',
            saveIgnoreTimeWindows: 'optimiseSetting/UPDATE_IGNORE_TIME_WINDOWS',
            saveReturnStartLocationStop: 'optimiseSetting/UPDATE_RETURN_START_LOCATION_STOP',
            saveWarehouseStartLocation: 'optimiseSetting/UPDATE_WAREHOUSE_START_LOCATION',
            saveWarehouseEndLocation: 'optimiseSetting/UPDATE_WAREHOUSE_END_LOCATION',
            saveTeamRegionStartLocation: 'optimiseSetting/UPDATE_TEAM_REGION_START_LOCATION',
            saveContiguityConstraint: 'optimiseSetting/UPDATE_CONTIGUITY_CONSTRAINT',
            saveExcludeStopsWithNoDate: 'optimiseSetting/UPDATE_EXCLUDE_STOPS_WITH_NO_DATE'
        }),
        onStartLocationChanged(startLocationData) {
            this.data.startLocation.location = startLocationData.location;
            this.$emit('validated', true);
            this.$emit('setStartEndLocationPin', 'start', false);
            this.saveStartLocation(startLocationData);
        },
        setStartTime(selectedTime) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;
                this.data.startTime = appointmentTime; // || this.getStartTime;
            } else {
                this.data.startTime = null;
            }
            this.saveStartTime(this.data.startTime);
        },
        setEndTime(selectedTime) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;
                this.data.endTime = appointmentTime; // || this.getEndTime;
            } else {
                this.data.endTime = null;
            }
            this.saveEndTime(this.data.endTime);
        },
        onStartLocationInputChange(address) {
            if (!address.newVal) {
                this.$emit('validated', false);
            }
        },
        setTripDate() {
            // If the current time is before midday, it's likely that the user wants to schedule trips for today,
            // so set today's date as the default trip date. If it's after midday, assume that the user wants to
            // schedule trips for tomorrow, so set the default trip date accordingly.
            const currentTime = moment().format('HH:mm');
            const todayMoment = moment(currentTime, 'HH:mm');
            const today1900 = moment('19:00', 'HH:mm');
            const isBeforeMidday = todayMoment.isSameOrBefore(today1900);
            this.data.tripDate = isBeforeMidday
                ? moment().format('YYYY-MM-DD')
                : moment()
                    .add(1, 'days')
                    .format('YYYY-MM-DD');
        },
        setEndLocationText(mode) {
            this.endLocationText = this.endLocationOptions[mode];
            const endLocationName = this.data.endLocation.name;
            if (mode === 'manual' && endLocationName) {
                this.endLocationText = endLocationName;
            } else if (mode === 'warehouse') {
                const warehouse = this.warehouses.find((x) => x.warehouseId === this.data.warehouseEndLocation);
                if (warehouse) {
                    this.endLocationText = warehouse.address;
                }
            }
            this.$emit('setStartEndLocationPin', 'end', mode !== 'manual');
        },
        onEndLocationChanged(endLocationData) {
            if (endLocationData.name) {
                this.endLocationText = endLocationData.name;
                this.saveEndLocation(endLocationData);
                this.$emit('setStartEndLocationPin', 'end', false);
            }
        },
        setSkillMatching() {
            this.data.skillMatching = this.getSkillMatching;
        },
        tripTimeValidation(start, end) {
            const currentTime = moment();
            const isFutureTripDate = currentTime.isBefore(this.data.tripDate);
            if (isFutureTripDate) {
                this.isBeforeStartTime = false;
            } else {
                const startTime = moment(start, 'HH:mm');
                const endTime = moment(end, 'HH:mm');
                this.isBeforeEndTime = startTime.isAfter(endTime);
                this.isBeforeStartTime = startTime.isBefore(currentTime) || this.isBeforeEndTime;
                this.$emit('validated', !this.isBeforeEndTime);
            }
        },
        toggleChange(event, index) {
            if (event.target.innerText === 'Change') {
                if (index === this.isActive) {
                    this.isActive = 1;
                } else {
                    this.isActive = index;
                }
            }
        },
        handleStartTeamRegionChanged(value) {
            this.data.teamRegionStartLocation = value;
            this.startLocationWarehouseOptions = this.filteredWarehouseOptions(this.data.teamRegionStartLocation);
            this.endLocationWarehouseOptions = this.filteredWarehouseOptions(this.data.teamRegionStartLocation);
            this.saveTeamRegionStartLocation(value);
            this.clearStartWarehouseOptions();
            this.clearEndWarehouseOptions();
            this.$emit('handleStartTeamRegionChanged', value);
        },
        filteredWarehouseOptions(teamRegionId) {
            if (!this.warehouseOptions) 
                return [];

            if (teamRegionId === null) 
                return this.warehouseOptions;

            if (teamRegionId === -1)
                return this.warehouseOptions.filter((x) => x.teamRegionId === null || x.teamRegionId === -1);

            const res = this.warehouseOptions.filter((x) => {
                return !teamRegionId || x.teamRegionId === teamRegionId || x.parentTeamRegionId === teamRegionId;
            });
            return res;
        },
        clearStartWarehouseOptions() {
            this.data.warehouseStartLocation = null;
            this.saveWarehouseStartLocation(null);
        },
        clearEndWarehouseOptions() {
            this.data.warehouseEndLocation = null;
            this.saveWarehouseEndLocation(null);
        },
        handleCustomerChanged(value) {
            this.data.customerId = value.customerId;
            this.$emit('handleCustomerChanged', value.customerId);
        },
        handleCustomerTextChanged(value) {
            if (!value || value === '') {
                this.data.customerId = null;
                this.$emit('handleCustomerChanged', null);
            }
        }
    },
    mounted() {
        const startLocation = this.getStartLocation;
        this.data.startLocationMode = this.getStartLocationMode;

        if (startLocation && this.getStartLocationMode === 'manual') {
            this.data.startLocation = {
                address: startLocation.address,
                location: startLocation.location != null ? startLocation.location : { latitude: null, longitude: null }
            };
        }

        this.$emit('validated', true);
        this.data.endLocationMode = this.getEndLocationMode;
        const endLocation = this.getEndLocation;
        if (endLocation && this.getEndLocationMode === 'manual') {
            this.data.endLocation = {
                address: endLocation.address,
                location: endLocation.location != null ? endLocation.location : { latitude: null, longitude: null },
                name: endLocation.name
            };
        }

        this.setTripDate();
        this.setEndLocationText(this.data.endLocationMode);
        this.setSkillMatching();
        this.data.startTime = this.getStartTime;
        this.data.endTime = this.getEndTime;
        this.tripTimeValidation(this.data.startTime, this.data.endTime);
        this.data.teamMemberTimingOverride = this.getTeamMemberTimingOverride;
        this.data.addReturnStartLocationStop = this.getReturnStartLocationStop;
        this.data.ignoreTimeWindows = this.getIgnoreTimeWindows;
        this.data.contiguityConstraint = this.getContiguityConstraint;
        this.data.teamRegionStartLocation = this.getTeamRegionStartLocation;
        this.data.excludeStopsWithNoDate = this.getExcludeStopsWithNoDate;
    },
    watch: {
        'data.tripDate': function(date) {
            if (date != null) {
                this.$emit('validated', true);
                this.tripTimeValidation(this.data.startTime, this.data.endTime);
            } else {
                this.data.tripDate = null;
                this.$emit('validated', false);
            }
        },
        'data.startLocationMode': function(mode) {
            this.data.startLocationMode = mode;
            this.saveStartLocationMode(mode);
            if (mode !== 'warehouse') {
                this.clearStartWarehouseOptions();
            }
            this.$emit('setStartEndLocationPin', 'start', mode !== 'manual');
            this.$emit('validated', mode === 'manual' ? !!this.data.startLocation.address : true);
        },
        'data.endLocationMode': function(mode) {
            this.data.endLocationMode = mode;
            this.setEndLocationText(mode);
            this.saveEndLocationMode(mode);

            if (mode !== 'warehouse') {
                this.clearEndWarehouseOptions();
            }
        },
        'data.skillMatching': function(value) {
            this.saveSkillMatching(value);
        },
        'data.startTime': function(value) {
            this.tripTimeValidation(value, this.data.endTime);
        },
        'data.endTime': function(value) {
            this.tripTimeValidation(this.data.startTime, value);
        },
        'data.teamMemberTimingOverride': function(value) {
            this.saveTeamMemberTimingOverride(value);
        },
        'data.addReturnStartLocationStop': function(value) {
            this.saveReturnStartLocationStop(value);
        },
        'data.ignoreTimeWindows': function(value) {
            this.saveIgnoreTimeWindows(value);
        },
        'data.warehouseStartLocation': function(location) {
            if (this.data.startLocationMode === 'warehouse') {
                this.$emit('validated', !!this.data.warehouseStartLocation && !!location);
            }
            this.saveWarehouseStartLocation(location);

            const warehouse = this.warehouses.find((x) => x.warehouseId === location);
            if (warehouse) {
                this.data.startLocation = {
                    address: warehouse.address,
                    location: warehouse.location != null ? warehouse.location : { latitude: null, longitude: null }
                };
                this.$emit('setStartEndLocationPin', 'start', false);
                this.saveStartLocation(this.data.startLocation);
            }
        },
        'data.warehouseEndLocation': function(location) {
            if (this.data.endLocationMode === 'warehouse') {
                this.$emit('validated', !!this.data.warehouseEndLocation && !!location);
                const warehouse = this.warehouses.find((x) => x.warehouseId === location);
                if (warehouse) {
                    this.endLocationText = warehouse.name;
                    this.setEndLocationText(this.data.endLocationMode);
                    this.data.endLocation = {
                        address: warehouse.address,
                        location: warehouse.location != null ? warehouse.location : { latitude: null, longitude: null }
                    };
                    this.$emit('setStartEndLocationPin', 'end', false);
                    this.saveEndLocation(this.data.endLocation);
                }
            }
            this.saveWarehouseEndLocation(location);
        },
        warehouses(data) {
            this.warehouseOptions = data;
            if (data) {
                this.handleStartTeamRegionChanged(this.data.teamRegionStartLocation);
                this.data.warehouseStartLocation = this.getWarehouseStartLocation;
                this.data.warehouseEndLocation = this.getWarehouseEndLocation;
            }
        },
        'data.contiguityConstraint': function(value) {
            this.saveContiguityConstraint(value);
        },
        'data.excludeStopsWithNoDate': function(value) {
            this.saveExcludeStopsWithNoDate(value);
            this.$emit('setExcludeStopsWithNoDate', value);
        }
    }
};
</script>
<style lang="scss" scoped>
.autocomplete-input {
    width: 100%;
}
.trip-option-box {
    .md-radio-label {
        padding-left: 5px;
        font-weight: 400;
        color: #42474a;
        font-size: 14px;
    }
}
.stop-details {
    .md-field label {
        left: 36px;
    }
    .md-field.md-theme-default.md-focused .md-icon {
        color: #2b93ff;
    }
    .md-checkbox {
        margin: 6px 16px 6px 0;
    }
}
::v-deep .timer-field {
    .time-picker-container .vs__selected {
        padding-left: 25px;
    }
    .md-icon {
        position: absolute;
    }
    .vs__clear {
        display: none;
    }
}
.error {
    font-size: 12px;
    color: red;
    margin-top: -10px;
    display: block;
    padding-left: 36px;
}
.trip-date-field {
    text-align: left;
    ::v-deep .md-clear {
        display: none;
    }
}
.end-location-label {
    color: #aaa;
    font-size: 0.6875rem;
    font-weight: 400;
    margin-bottom: -15px;
}
.end-location-card {
    margin: 15px 0px;
    background-color: transparent !important;
    box-shadow: none;
    .end-location-value {
        color: #3c4858;
        font-size: 14px;
        margin-left: 0px;
        font-weight: 400;
    }
    .text-padding {
        padding-left: 110px;
    }
    .md-card-actions {
        border: 0px;
        margin: 0px;
        padding: 0px;
        height: 20px;
        background-color: transparent !important;
        justify-content: flex-start;
    }
    .md-icon-button {
        width: 20px;
        min-width: 20px;
        height: 20px;
        margin-left: 5px !important;
    }
    .md-card-content {
        padding: 0px;
    }
    .md-button {
        text-transform: none;
        background-color: transparent !important;
        color: #2b93ff !important;
        left: -15px;
        top: -1px;
    }
    .option-box {
        height: 35px;
        font-size: 12px;
    }
    .address-margin {
        margin-top: 32px;
    }
}
.address-label ::v-deep label {
    padding-left: 30px;
}
.address-label ::v-deep .md-focused label,
.address-label ::v-deep .md-has-value label {
    margin-left: -25px !important;
}
.alert-bg-color {
    background-color: #f44336 !important;
}
.address {
    padding-left: 0;
    padding-right: 0;
}
.team-region-field {
    padding-left: 15px;
    label {
        color: #aaa;
    }
    ::v-deep .vs__selected,
    ::v-deep input {
        color: #3c4858 !important;
        padding-left: 0px;
        font-size: 14px;
    }
    ::v-deep .vs__clear {
        display: none;
    }
}
.warehouse-field {
    padding-left: 0;
    ::v-deep .vs__selected,
    ::v-deep input {
        color: #3c4858 !important;
        padding-left: 0px;
        font-size: 14px;
    }
    ::v-deep .vs__clear {
        display: none;
    }
}
.end-warehouse-field {
    ::v-deep .vs__selected,
    ::v-deep input {
        color: #3c4858 !important;
        padding-left: 0px;
        font-size: 14px;
    }
    ::v-deep .vs__clear {
        display: none;
    }
}
.run-number-manual {
    ::v-deep input {
        width: 80px !important;
        padding-left: 5px !important;
    }
}
.run-number {
    padding-left: 0;
    padding-right: 0;
    ::v-deep input {
        width: 80px !important;
    }
}
.customer-field {
    padding-top: 12px;
}
</style>
