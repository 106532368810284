<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">
                <md-icon class="billing-icon">paid</md-icon>
                Manage Billing Online - Unavailable
            </h4>
        </div>
        <div class="modal-body">
            If you are paying by invoice or have an enterprise agreement, please contact our
            <a style="color:#2b93ff;" href="mailto:sales@locate2u.com">Sales Team</a>
            or your account manager.
            <br />
            <br />

            If you have a mobile app store subscription, you will need to manage your billing there.
            <br />
            <br />

            Contact our
            <a style="color:#2b93ff;" href="mailto:support@locate2u.com">Support Team</a>
            if neither of the above apply to you.
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click.stop="$modal.hide">Ok</md-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NoBillingOptions'
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}

.modal-container {
    max-width: 600px;
}
</style>
