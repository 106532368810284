<template>
    <div>
        <template>
            <slot :publicUserId="driver.publicUserId" />
        </template>
    </div>
</template>
<script>
import { ref, toRefs, onMounted, onBeforeUnmount, watch, inject, computed } from '@vue/composition-api';
import useMapUtils from '@/compostables/useMapUtils';

export default {
    name: 'DriverMarker',
    props: {
        driver: {
            type: Object,
            default: () => {}
        },
        isDateToday: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { root }) {
        const { isDateToday, driver } = toRefs(props);
        const map = inject('map');
        const google = inject('google');
        const bounds = inject('bounds');
        const isStreetView = inject('isStreetView');
        const { getters, dispatch } = inject('vuex-store');

        const pinnedUser = computed(() => getters['map/pinnedUser']);
        const clickedStop = computed(() => getters['map/clickedStop']);
        const canOverrideLocation = computed(() => getters['user/canOverrideLocation']);

        const isPlaying = computed(() => getters['map/isPlaying']);

        const { createDriverMarker, createLatLngPoint, getMarkerOpacity } = useMapUtils(root);
        const marker = ref(null);

        if (!driver.value.routeDetails || driver.value.routeDetails.coordinates === null) 
            return marker;

        const showMarker = () => {
            const {
                coordinates: { latitude, longitude }
            } = driver.value.routeDetails;

            const opacity = getMarkerOpacity(driver.value.routeDetails.lastLocationUpdate);

            marker.value = createDriverMarker(google.value, {
                latlng: createLatLngPoint(google.value, latitude, longitude),
                map: map.value,
                html: `<div style="--driver-color:${
                    driver.value.markerPins.color
                };opacity:${opacity}" class="asset-map-image-marker"><div class="image" style="background-image: url(${driver
                    .value.photoUrl || '/img/profile-pic.png'})"></div></div>`,
                draggable: canOverrideLocation.value
            });

            marker.value.addListener('click', () => {
                root.$emit('marker-clicked', { data: driver.value, marker: marker.value, type: 'driver' });
            });

            // update location.
            const onDrop = () => {
                dispatch('map/SET_DRIVER_LOCATION', {
                    publicUserId: driver.value.publicUserId,
                    trackerId: null,
                    location: {
                        latitude: marker.value.getPosition().lat(),
                        longitude: marker.value.getPosition().lng()
                    }
                });
            };
            if (marker.value.draggable) {
                google.value.maps.event.addListener(map.value, 'mouseover', () => {
                    const mapImageMarker = marker.value?.div?.getElementsByClassName('asset-map-image-marker');
                    if (mapImageMarker) {
                        const markerDom = mapImageMarker[0];
                        if (markerDom.getAttribute('markerDropListener') !== 'true') {
                            markerDom.setAttribute('markerDropListener', 'true');
                            markerDom.addEventListener('click', () => {
                                onDrop();
                            });
                        }
                    }
                });
            }

            bounds.value.extend(marker.value.getPosition());
            map.value.fitBounds(bounds.value);

            // Set map null if
            // 1. Not today 2. Other users pinned 2. Null Island
            if (
                !isDateToday.value ||
                (pinnedUser.value && pinnedUser.value !== driver.value.publicUserId) ||
                (latitude === 0 && longitude === 0)
            )
                marker.value.setMap(null);
        };

        watch(pinnedUser, (newVal) => {
            // unpinning a user
            if (!newVal && marker?.value && isDateToday.value) {
                marker.value.setMap(map.value);
                bounds.value.extend(marker.value.getPosition());
                map.value.fitBounds(bounds.value);
            }
            // pinning a user
            else if (marker?.value) {
                if (newVal === driver.value.publicUserId && isDateToday.value) {
                    marker.value.setMap(map.value);
                    bounds.value.extend(marker.value.getPosition());
                    map.value.fitBounds(bounds.value);
                } else 
                    marker.value.setMap(null);
            }
        });

        watch(driver, (newVal) => {
            if (!isDateToday.value || isStreetView.value) 
                return true;
            const {
                routeDetails: {
                    coordinates: { latitude, longitude }
                }
            } = newVal;
            const pos = createLatLngPoint(google.value, latitude, longitude);
            if (!pinnedUser.value || pinnedUser.value === newVal.publicUserId) 
                marker.value.setPosition(pos);
            return true;
        });

        watch(isDateToday, (newVal) => {
            if (newVal && (!pinnedUser.value || (pinnedUser.value && driver.publicUserId === pinnedUser.value))) {
                marker.value.setMap(map.value);
                bounds.value.extend(marker.value.getPosition());
                map.value.fitBounds(bounds.value);
            } else 
                marker.value.setMap(null);
        });

        watch(clickedStop, ({ publicUserId }) => {
            if (publicUserId === driver.value.publicUserId) 
                bounds.value.extend(marker.value.getPosition());
        });

        watch(isPlaying, (newVal) => {
            if (!newVal && isDateToday.value && (!pinnedUser.value || pinnedUser.value === driver.value.publicUserId)) {
                marker.value.setMap(map.value);
                bounds.value.extend(marker.value.getPosition());
                map.value.fitBounds(bounds.value);
            } else {
                marker.value.setMap(null);
            }
        });

        watch(isStreetView, (newVal) => {
            if (!isDateToday.value) 
                return true;
            if (!newVal && (!pinnedUser.value || (pinnedUser.value && driver.value.publicUserId === pinnedUser.value)))
                marker.value.setMap(map.value);
            else 
                marker.value.setMap(null);
            return true;
        });

        onMounted(showMarker);

        // remove marker before unmount
        onBeforeUnmount(() => {
            marker.value.setMap(null);
            marker.value = null;
        });

        return {
            marker
        };
    },
    render: () => null
};
</script>
