// map overview mutations
export const ADD_TIMESERIES_MARKERS = 'ADD_TIMESERIES_MARKERS';
export const PIN_DRIVER = 'PIN_DRIVER';
export const UNPIN_DRIVER = 'UNPIN_DRIVER';
export const ADD_DRIVER = 'ADD_DRIVER';
export const ADD_STOP = 'ADD_STOP';
export const CHANGE_DATE = 'CHANGE_DATE';
export const CHANGE_TIME_WINDOW = 'CHANGE_TIME_WINDOW';
export const SHOW_STREET_VIEW = 'SHOW_STREET_VIEW';
export const CHANGE_PINNED_DISPLAY = 'CHANGE_PINNED_DISPLAY';
export const PLAY_TIMELINE = 'PLAY_TIMELINE';
export const CHANGE_ACTIVE_PANEL = 'CHANGE_ACTIVE_PANEL';
export const STOP_CLICKED = 'STOP_CLICKED';
// time series data mutation
export const CLEAR_TIMESERIES_DATA = 'CLEAR_TIMESERIES_DATA';

// display mutations
export const TOGGLE_HEARTBEATS = 'TOGGLE_HEARTBEATS';
export const TOGGLE_PLANNED_ROUTE = 'TOGGLE_PLANNED_ROUTE';
export const TOGGLE_LOCATION_HISTORY = 'TOGGLE_LOCATION_HISTORY';
export const TOGGLE_STOPS_LIST = 'TOGGLE_STOPS_LIST';
export const TOGGLE_STOP_STATUS = 'TOGGLE_STOP_STATUS';
export const TOGGLE_GEOFENCE = 'TOGGLE_GEOFENCE';

// map overview actions
export const FETCH_DRIVER = 'FETCH_DRIVER';
export const FETCH_STOPS = 'FETCH_STOPS';
export const FETCH_TIMESERIES_DATA = 'FETCH_TIMESERIES_DATA';

// Geo
export const FETCH_GEO_FENCE = 'FETCH_GEO_FENCE';
export const FETCH_GEO_TYPES = 'FETCH_GEO_TYPES';
export const CREATE_GEO_FENCE = 'CREATE_GEO_FENCE';
export const EDIT_GEO_FENCE = 'EDIT_GEO_FENCE';
export const DELETE_GEO_FENCE = 'DELETE_GEO_FENCE';
export const FETCH_GEO_FENCE_TYPES = 'FETCH_GEO_FENCE_TYPES';

// user actions
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_DRIVER_LOCATION = 'SET_DRIVER_LOCATION';

// team actions
export const FETCH_TEAM_MEMBERS = 'FETCH_TEAM_MEMBERS';
export const UPDATE_TEAM_MEMBERS = 'UPDATE_TEAM_MEMBERS';
export const FETCH_TEAM_INVITES = 'FETCH_TEAM_INVITES';
export const UPDATE_TEAM_INVITES = 'UPDATE_TEAM_INVITES';

// team regions
export const FETCH_TEAM_REGIONS = 'FETCH_TEAM_REGIONS';
export const SET_TEAM_REGIONS = 'SET_TEAM_REGIONS';
export const SET_TEAM_REGION_ID = 'SET_TEAM_REGION_ID'; // shared

// warehouses
export const FETCH_WAREHOUSES = 'FETCH_WAREHOUSES';
export const SET_WAREHOUSES = 'SET_WAREHOUSES';

// team carrires
export const FETCH_TEAM_CARRIERS = 'FETCH_TEAM_CARRIERS';
export const SET_TEAM_CARRIERS = 'SET_TEAM_CARRIERS';

// asset group actions
export const FETCH_ASSET_GROUPS_LOCATION_DETAILS = 'FETCH_ASSET_GROUPS_LOCATION_DETAILS';
export const UPDATE_ASSET_GROUP = 'UPDATE_ASSET_GROUP';

// reset state
export const RESET_STATE = 'RESET_STATE';

// Routing Setting
export const UPDATE_DISTANCE_COST = 'UPDATE_DISTANCE_COST';
export const UPDATE_TIME_COST = 'UPDATE_TIME_COST';
export const UPDATE_FIXED_COST = 'UPDATE_FIXED_COST';
export const UPDATE_ROUTE_CENTERING_PENALTY = 'UPDATE_ROUTE_CENTERING_PENALTY';
export const FETCH_ROUTE_SETTINGS = 'FETCH_ROUTE_SETTINGS';
export const FETCH_DEFAULT_ROUTE_SETTINGS = 'FETCH_DEFAULT_ROUTE_SETTINGS';
export const UPDATE_DEFAULT_ROUTE_SETTINGS = 'UPDATE_DEFAULT_ROUTE_SETTINGS';
export const UPDATE_MAX_RADIUS_VALUE = 'UPDATE_MAX_RADIUS_VALUE';
export const UPDATE_MAX_RADIUS_UNITS = 'UPDATE_MAX_RADIUS_UNITS';
export const UPDATE_DISTRIBUTE_WORKLOAD_COST = 'UPDATE_DISTRIBUTE_WORKLOAD_COST';

// Optimise Setting
export const UPDATE_START_LOCATION_MODE = 'UPDATE_START_LOCATION_MODE';
export const UPDATE_START_LOCATION = 'UPDATE_START_LOCATION';
export const UPDATE_START_TIME = 'UPDATE_START_TIME';
export const UPDATE_END_TIME = 'UPDATE_END_TIME';
export const UPDATE_END_LOCATION_MODE = 'UPDATE_END_LOCATION_MODE';
export const UPDATE_END_LOCATION = 'UPDATE_END_LOCATION';
export const UPDATE_SKILL_MATCHING = 'UPDATE_SKILL_MATCHING';
export const UPDATE_TEAM_MEMBER_TIMING_OVERRIDE = 'UPDATE_TEAM_MEMBER_TIMING_OVERRIDE';
export const UPDATE_RETURN_START_LOCATION_STOP = 'UPDATE_RETURN_START_LOCATION_STOP';
export const UPDATE_IGNORE_TIME_WINDOWS = 'UPDATE_IGNORE_TIME_WINDOWS';
export const UPDATE_WAREHOUSE_START_LOCATION = 'UPDATE_WAREHOUSE_START_LOCATION';
export const UPDATE_WAREHOUSE_END_LOCATION = 'UPDATE_WAREHOUSE_END_LOCATION';
export const UPDATE_TEAM_REGION_START_LOCATION = 'UPDATE_TEAM_REGION_START_LOCATION';
export const UPDATE_USE_NEW_OPTIMISE_STOPS = 'UPDATE_USE_NEW_OPTIMISE_STOPS';
export const UPDATE_CONTIGUITY_CONSTRAINT = 'UPDATE_CONTIGUITY_CONSTRAINT';
export const UPDATE_EXCLUDE_STOPS_WITH_NO_DATE = 'UPDATE_EXCLUDE_STOPS_WITH_NO_DATE';

// General Setting
export const FETCH_GENERAL_SETTINGS = 'FETCH_GENERAL_SETTINGS';
export const SET_TEAM_GENERAL_SETTINGS = 'SET_TEAM_GENERAL_SETTINGS';
export const FETCH_TEAM_ACCOUNTING_PACKAGE = 'FETCH_TEAM_ACCOUNTING_PACKAGE';
export const SET_TEAM_ACCOUNTING_PACKAGE = 'SET_TEAM_ACCOUNTING_PACKAGE';

// Rate Rules
export const FETCH_RATE_RULES = 'FETCH_RATE_RULES';
export const SET_RATE_RULES = 'SET_RATE_RULES';

// Customers
export const FETCH_TEAM_CUSTOMERS = 'FETCH_TEAM_CUSTOMERS';
export const SET_TEAM_CUSTOMERS = 'SET_TEAM_CUSTOMERS';
export const FETCH_TEAM_HAS_CUSTOMERS = 'FETCH_TEAM_HAS_CUSTOMERS';
export const SET_TEAM_HAS_CUSTOMERS = 'SET_TEAM_HAS_CUSTOMERS';
