<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ modalTitle }}</h4>
            <div class="modal-header--actions">
                <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                    <md-icon>clear</md-icon>
                </md-button>
            </div>
        </div>
        <div class="modal-body">
            <div class="form-container">
                <form-group
                    name="startTime"
                    label="Start Time"
                    class="time-picker hide-clear-btn"
                    v-if="jobType !== 'stop'"
                >
                    <time-picker
                        :time="startTime"
                        :all-time-options="false"
                        :is-required="true"
                        @selectedTime="getStartTime"
                    />
                </form-group>
                <form-group name="endTime" label="End Time" class="time-picker hide-clear-btn">
                    <time-picker
                        :time="endTime"
                        :all-time-options="false"
                        :is-required="true"
                        @selectedTime="getEndTime"
                    />
                </form-group>
            </div>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click.stop="save">
                Update
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>
<script>
import { TimePicker } from '@/components';
import moment from 'moment';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins';

export default {
    name: 'UpdateJobTimeModal',
    components: { TimePicker },
    props: {
        resolve: {
            type: Function,
            default: () => {}
        },
        jobType: {
            type: String,
            default: 'trip' // trip, shipment, or stop
        },
        initialStartTime: {
            type: String,
            default: null
        },
        initialEndTime: {
            type: String,
            default: null
        },
        tripId: {
            type: Number,
            default: null
        },
        shipmentId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            startTime: null,
            isStartTimeValid: true,
            endTime: null,
            isEndTimeValid: true
        };
    },
    created() {
        // initialStartTime and initialEndTime is in ISO format
        // convert it to HH:mm format
        if (this.initialStartTime) {
            this.startTime = moment(this.initialStartTime).format('HH:mm');
        }
        if (this.initialEndTime) {
            this.endTime = moment(this.initialEndTime).format('HH:mm');
        }
    },
    computed: {
        modalTitle() {
            switch (this.jobType) {
                case 'trip':
                    return 'Update Trip Time';
                case 'shipment':
                    return 'Update Shipment Time';
                case 'stop':
                    return 'Update Stop Time';
                default:
                    return 'Update Job Time';
            }
        }
    },
    mixins: [GeneralMixin],
    methods: {
        getStartTime(selectedTime, isTimeValid) {
            if (selectedTime !== null) {
                const { appointmentTime } = selectedTime;
                this.startTime = appointmentTime;
            } else {
                this.startTime = null;
            }
            this.isStartTimeValid = isTimeValid;
        },
        getEndTime(selectedTime, isTimeValid) {
            if (selectedTime !== null) {
                const { appointmentTime } = selectedTime;
                this.endTime = appointmentTime;
            } else {
                this.endTime = null;
            }
            this.isEndTimeValid = isTimeValid;
        },
        async save() {
            const data = {
                startTime: moment(this.startTime, 'HH:mm').format('HH:mm:ss'),
                endTime: moment(this.endTime, 'HH:mm').format('HH:mm:ss')
            };

            this.$_handleLoaderState(true, 'UPDATING...');
            const api = this.shipmentId
                ? `/api/process-charges/shipment/${this.shipmentId}/time`
                : `/api/process-charges/trip/${this.tripId}/time`;

            const payload = {
                method: 'patch',
                data
            };

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully updated the time.',
                    type: 'success'
                });

                // we need to convert the time to ISO format
                const newStartTime = moment(this.startTime, 'HH:mm').format('YYYY-MM-DDTHH:mm:ss');
                const newEndTime = moment(this.endTime, 'HH:mm').format('YYYY-MM-DDTHH:mm:ss');

                this.resolve({
                    code: 'ok',
                    newTimes: {
                        actualStartTime: newStartTime,
                        actualEndTime: newEndTime
                    }
                });
            } catch (e) {
                const message = `Could not update trip. ${e.data}`;
                showErrorMessage(this, message, null);
                this.resolve('error');
            } finally {
                this.$_handleLoaderState(false);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.form-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}
</style>
