<template>
    <div class="cf-container">
        <div class="cf-action-container">
            <md-button
                title="Add stop custom fields"
                class="md-primary md-just-icon md-round"
                @click.stop="handleAddCustomField()"
            >
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <md-table class="custom-paginated-table">
            <md-table-row>
                <md-table-head>Id</md-table-head>
                <md-table-head>Label</md-table-head>
                <md-table-head>Type</md-table-head>
                <md-table-head>Include in Proof Of Delivery PDF</md-table-head>
                <md-table-head class="actions">Actions</md-table-head>
            </md-table-row>

            <md-table-row v-for="(customField, index) in stopCustomFieldList" :key="index">
                <md-table-cell>
                    {{ customField.name }}
                </md-table-cell>
                <md-table-cell>
                    {{ customField.label }}
                </md-table-cell>
                <md-table-cell>
                    {{ customField.type }}
                </md-table-cell>
                <md-table-cell>
                    {{ customField.includeInPodPdf ? 'Yes' : 'No' }}
                </md-table-cell>
                <md-table-cell class="action-buttons">
                    <md-button
                        title="edit custom field"
                        class="md-warning md-just-icon md-round"
                        @click.stop="handleEditStopCustomField(customField)"
                    >
                        <md-icon>edit</md-icon>
                    </md-button>
                    <md-button
                        title="delete custom field"
                        class="md-danger md-just-icon md-round"
                        @click.stop="removeCustomField(index)"
                    >
                        <md-icon>delete</md-icon>
                    </md-button>
                </md-table-cell>
            </md-table-row>
        </md-table>
        <div class="text-center" v-if="stopCustomFieldList.length == 0">
            <p class="no-result-message">No results matching your search/filter could be found.</p>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import StopCustomFieldModal from './StopCustomFieldModal';

export default {
    name: 'StopCustomFieldsList',
    mixins: [GeneralMixin],
    data() {
        return {
            stopCustomFieldList: [],
            shouldShowAddStopCustomFieldModal: false
        };
    },
    async mounted() {
        this.$_handleLoaderState(true);
        const api = '/api/teams/custom-fields/stops';
        const response = await handleRequests(api);
        if (response.data !== '') {
            this.stopCustomFieldList = response.data;
        }
        this.$_handleLoaderState(false);
    },
    methods: {
        handleAddCustomField() {
            this.$modal.show(StopCustomFieldModal).then((response) => {
                const stopCustomField = this.stopCustomFieldList.find((item) => item.name === response.name);

                // to prevent duplicate error from happening, we add a randomString at the end if field name already exists.
                if (stopCustomField) {
                    const randomString = Math.random()
                        .toString(20)
                        .substr(2, 4);
                    response.name = `${response.name}-${randomString}`;
                }

                if (!this.isDuplicateField(response) && this.isDuplicateField(response) !== 0) {
                    this.stopCustomFieldList.push(response);
                    this.saveCustomFields('SAVING...');
                } else {
                    this.showWarningDuplicate(response.label);
                }
            });
        },
        handleEditStopCustomField(selectedCustomField) {
            this.$modal.show(StopCustomFieldModal, { selectedCustomField }).then((response) => {
                let hasDuplicate = false;
                const stopCustomField = this.stopCustomFieldList.find((item) => item.name === response.name);
                const hasChanges = !Object.keys(stopCustomField).every((key) => stopCustomField[key] === response[key]);
                const stopCustomFieldIndex = this.stopCustomFieldList.findIndex((item) => item.name === response.name);

                if (hasChanges) {
                    if (this.isDuplicateField(response) >= 0 && this.isDuplicateField(response) !== false) {
                        hasDuplicate = !(this.isDuplicateField(response) === stopCustomFieldIndex);
                        if (hasDuplicate) {
                            this.showWarningDuplicate(response.label);
                        }
                    }

                    if (!hasDuplicate) {
                        this.$set(stopCustomField, 'label', response.label);
                        this.$set(stopCustomField, 'type', response.type);
                        this.$set(stopCustomField, 'includeInPodPdf', response.includeInPodPdf);
                        this.saveCustomFields('UPDATING...');
                    }
                }
            });
        },
        toggleModal(shouldToggle, modalToToggle) {
            this[modalToToggle] = shouldToggle;
        },
        removeCustomField(index) {
            this.$messageBox
                .show({
                    title: 'Delete stop custom field',
                    body: 'Are you sure you want to delete this custom field?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.stopCustomFieldList.splice(index, 1);
                        this.saveCustomFields('DELETING...');
                    }
                });
        },
        async saveCustomFields(text) {
            this.$_handleLoaderState(true, text);
            const payload = {
                method: 'post',
                data: this.stopCustomFieldList
            };
            const api = `/api/teams/custom-fields/stops`;
            try {
                await handleRequests(api, payload);

                let action = 'saved';

                if (text.toLowerCase().includes('updating')) {
                    action = 'updated';
                } else if (text.toLowerCase().includes('deleting')) {
                    action = 'deleted';
                }

                this.$notify({
                    message: `Custom fields ${action}!`,
                    type: 'success'
                });
                await this.$store.dispatch('user/FETCH_USER');
            } catch (e) {
                const message = 'Cannot save custom fields.';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
        },
        isDuplicateField(customField) {
            const index = this.stopCustomFieldList.findIndex(
                (x) => x.label.toLowerCase() === customField.label.toLowerCase()
            );

            if (index === -1) {
                return false;
            }

            return index;
        },
        showWarningDuplicate(label) {
            const message = `Custom field name ${label} already exists.`;
            this.$notify({
                message,
                type: 'warning'
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.actions {
    width: 6%;
}
</style>
