var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MapContainer",
    {
      attrs: { "map-engine": _vm.mapEngine },
      on: { mapLoaded: _vm.handleMapLoaded },
    },
    [
      _c(
        "div",
        [
          _vm._l(_vm.markers, function (marker, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "MapMarker",
                  { attrs: { marker: marker, id: marker.id } },
                  [
                    marker.popupOptions && marker.popupOptions.html
                      ? _c("MapPopup", {
                          attrs: {
                            identifier: "pop-" + marker.id,
                            options: marker.popupOptions,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm.currentRoute.pathString
            ? _c(
                "div",
                [
                  _c("MapPath", {
                    attrs: {
                      "path-string": _vm.currentRoute.pathString,
                      options: _vm.currentRoute,
                      identifier: _vm.currentRoute.id,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.proposedRoute.pathString
            ? _c(
                "div",
                [
                  _c("MapPath", {
                    attrs: {
                      "path-string": _vm.proposedRoute.pathString,
                      options: _vm.proposedRoute,
                      identifier: _vm.proposedRoute.id,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }