<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <ShipmentFilterComponent
                            @onFilterShipments="handleFilterOrSearch"
                            :team-members="() => this.teamMembers"
                            :team-region-id="filters.teamRegionId"
                            :show-quoting-status="false"
                            :show-team-members="false"
                            :show-status="false"
                        />
                    </div>
                    <div class="custom-toolbar-end">
                        <SearchComponent @onSearch="handleFilterOrSearch" />
                        <div>
                            <md-button
                                title="Refresh List"
                                class="md-primary md-just-icon md-round pull-right header-button"
                                @click="handleRefresh"
                            >
                                <md-icon>refresh</md-icon>
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green" v-if="hasMarketplaceAccess">
                        <CardHeaderDropdown
                            :dropdown-options="headerDropdown"
                            @selectedOption="handleSelectedView"
                            :selected-option="headerDropdown[1]"
                        />
                    </md-card-header>
                    <md-card-header class="md-card-header-icon md-card-header-green" v-else>
                        <div class="card-icon running-load-menu">
                            <md-icon>assignment</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div
                            v-if="selectedShipmentIds.length"
                            :class="['bulk-section', topScrollPosition > 150 ? 'sticky' : '']"
                        >
                            <div>{{ selectedShipmentsDisplayText }}</div>
                            <div>
                                <md-button @click.prevent="requestQuotes" class="md-warning">Refresh quotes</md-button>
                                <md-button @click.prevent="bulkAssign" class="md-info">Assign to Carrier</md-button>
                            </div>
                        </div>
                        <div v-if="!isListLoading">
                            <md-table class="context-menu-support" id="table-container">
                                <md-table-row>
                                    <md-table-head>
                                        <md-checkbox
                                            class="shipment-checkbox checkbox-head"
                                            v-model="isSelectAll"
                                            @change="onSelectAll(isSelectAll)"
                                        ></md-checkbox>
                                    </md-table-head>

                                    <md-table-head class="hide-column">Reference</md-table-head>
                                    <md-table-head>Quote Status</md-table-head>
                                    <md-table-head class="hide-column">Trip Date</md-table-head>
                                    <md-table-head>Address</md-table-head>
                                    <md-table-head>Contact</md-table-head>
                                    <div class="md-table-head running-load-head">
                                        <div class="md-table-head-container running-load-menu">
                                            <drop-down direction="down">
                                                <a
                                                    href="#"
                                                    class="load-header-text custom-ellipsis"
                                                    v-if="
                                                        selectedCarrierHeader != 'Default Only' &&
                                                            selectedCarrierHeader != null
                                                    "
                                                >
                                                    {{ selectedCarrierHeader }}
                                                    <md-tooltip md-direction="top">
                                                        This will filter the cheapest quote based on the selected
                                                        carrier
                                                    </md-tooltip>
                                                </a>
                                                <a href="#" class="load-header-text" v-else>
                                                    Carrier
                                                    <md-tooltip md-direction="top">
                                                        This will filter the cheapest quote based on the selected
                                                        carrier
                                                    </md-tooltip>
                                                </a>
                                                <md-icon class="icon-arrow">expand_more</md-icon>

                                                <ul class="dropdown-menu table-dropdown">
                                                    <li
                                                        v-for="item in filteredCarrierTeamOptions"
                                                        :key="item.key"
                                                        :class="item.key == -1 ? 'line-menu' : ''"
                                                    >
                                                        <md-icon
                                                            :class="[
                                                                'icon-check',
                                                                selectedCarrierKey === item.key
                                                                    ? 'icon-check-color'
                                                                    : ''
                                                            ]"
                                                        >
                                                            check
                                                        </md-icon>
                                                        <a @click="handleSelectedCarrierOption(item)">
                                                            {{ item.label }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </drop-down>
                                        </div>
                                    </div>
                                    <md-table-head>Service Type</md-table-head>
                                    <md-table-head>Price</md-table-head>
                                    <md-table-head></md-table-head>
                                </md-table-row>
                                <md-table-row
                                    v-for="item in shipmentsList"
                                    :key="item.shipmentId"
                                    @click.stop="showShipmentDetailsSidebar(item)"
                                >
                                    <md-table-cell class="small-column">
                                        <md-checkbox
                                            class="shipment-checkbox"
                                            :value="item.shipmentId"
                                            v-model="selectedShipmentIds"
                                            :disabled="item.quotingStatus == 'JobAssigned'"
                                            @change="onSelectOne"
                                        ></md-checkbox>
                                    </md-table-cell>

                                    <md-table-cell class="shipment-reference hide-column">
                                        <router-link
                                            :to="{
                                                name: 'Shipment Details',
                                                params: { shipmentId: item.shipmentId }
                                            }"
                                            target="_blank"
                                        >
                                            {{ item.shipmentRef }}
                                        </router-link>
                                    </md-table-cell>
                                    <md-table-cell
                                        class="status-container quote-status-container"
                                        :class="
                                            !!item.quotingStatus && item.quotingStatus === 'QuotesGathered'
                                                ? 'quote-status'
                                                : ''
                                        "
                                    >
                                        <div
                                            :class="
                                                !!item.quotingStatus
                                                    ? `status-${$_getQuotingStatusBadgeColor(item.quotingStatus)}`
                                                    : ''
                                            "
                                        >
                                            <div
                                                class="quotes-loader"
                                                v-if="item.quotingStatus == 'GatheringQuotes' || item.showLoader"
                                                :class="item.showLoader ? 'started-loader' : ''"
                                            >
                                                <straight-line-loader />
                                            </div>
                                            <div
                                                v-if="!!item.quotingStatus"
                                                class="custom-badge quoting-badge"
                                                :class="`status-${$_getQuotingStatusBadgeColor(item.quotingStatus)}`"
                                            >
                                                <span
                                                    v-if="item.quotingStatus == 'QuotesGathered'"
                                                    @click.stop="handleShowQuotes(item)"
                                                >
                                                    {{ toQuotingStatusDesc(item) }}
                                                </span>
                                                <span v-else>{{ toQuotingStatusDesc(item) }}</span>
                                            </div>
                                        </div>
                                    </md-table-cell>
                                    <md-table-cell class="hide-column">
                                        {{ item.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                    </md-table-cell>
                                    <md-table-cell class="address-container">
                                        <div>
                                            <md-tooltip class="tooltip-width" md-direction="top">
                                                <div class="address-text-tooltip">
                                                    <b>Pickup:</b>
                                                    {{
                                                        $_displayAddressName({
                                                            address: item.pickupAddress,
                                                            name: item.pickupName
                                                        })
                                                    }}
                                                </div>
                                                <div class="address-text-tooltip">
                                                    <b>Drop off:</b>
                                                    {{
                                                        $_displayAddressName({
                                                            address: item.dropAddress,
                                                            name: item.dropName
                                                        })
                                                    }}
                                                </div>
                                            </md-tooltip>
                                            <div class="address-text">
                                                {{
                                                    $_displayAddressName({
                                                        address: item.pickupAddress,
                                                        name: item.pickupName
                                                    })
                                                }}
                                            </div>
                                            <div class="address-separator">-></div>
                                            <div class="address-text">
                                                {{
                                                    $_displayAddressName({
                                                        address: item.dropAddress,
                                                        name: item.dropName
                                                    })
                                                }}
                                            </div>
                                        </div>
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ item.contact.name }}
                                    </md-table-cell>
                                    <md-table-cell class="carrier-dropdown">
                                        <SelectShipmentDropdown
                                            :shipment="item"
                                            :team-members="[]"
                                            :is-quotes-view="true"
                                            @quoteSelected="handleQuoteSelected"
                                            @getQuotes="loadQuotes"
                                        />
                                    </md-table-cell>
                                    <md-table-cell>
                                        <span
                                            v-if="
                                                item.selectedCarrierQuote != null &&
                                                    item.selectedCarrierQuote.serviceLevel != null
                                            "
                                            class="custom-badge service-badge"
                                            :class="
                                                `status-${$_getServiceLevelBadgeColor(
                                                    item.selectedCarrierQuote.timeWindowEnd
                                                )}`
                                            "
                                        >
                                            {{ $t(item.selectedCarrierQuote.serviceLevel) }}
                                        </span>
                                        <div
                                            v-if="
                                                item.selectedCarrierQuote != null &&
                                                    item.selectedCarrierQuote.timeWindowEnd != null
                                            "
                                            class="quote-time"
                                        >
                                            ETA: {{ item.selectedCarrierQuote.timeWindowEnd | quoteDateTimeFormat }}
                                        </div>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <span v-if="item.selectedCarrierQuote != null">
                                            {{
                                                item.selectedCarrierQuote.amountAfterTax
                                                    | currency(item.selectedCarrierQuote.currency)
                                            }}
                                        </span>
                                    </md-table-cell>
                                    <md-table-cell class="action-buttons">
                                        <div
                                            class="action-button-container"
                                            v-if="
                                                item.selectedCarrierQuote != null &&
                                                    item.selectedCarrierQuote.isExpired &&
                                                    item.quotingStatus != 'GatheringQuotes'
                                            "
                                        >
                                            <md-button
                                                title="Refresh quotes"
                                                class="md-success md-just-icon md-round"
                                                @click.stop="handleRefreshQuotes(item)"
                                            >
                                                <md-icon>refresh</md-icon>
                                            </md-button>
                                        </div>
                                        <PreBookingIndicatorButton
                                            class="pre-booking-icon"
                                            v-if="item.quotingStatus !== 'JobAssigned'"
                                            :shipment="item"
                                            @onCheckPreBookingRequiredFieldsCompleted="
                                                handleCheckPreBookingRequiredFieldsCompleted
                                            "
                                            @onUpdateShipmentCompleted="handleUpdateShipmentCompleted"
                                        />
                                        <div
                                            class="action-button-container"
                                            v-if="
                                                item.selectedCarrierQuote != null && item.quotingStatus != 'JobAssigned'
                                            "
                                        >
                                            <md-button
                                                title="Assign quote to shipment"
                                                class="md-primary md-just-icon md-round"
                                                @click.stop="handleAssignQuoteToShipment(item)"
                                            >
                                                <md-icon>person_add</md-icon>
                                            </md-button>
                                        </div>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <div v-if="false">
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                        <div v-else>
                            <div class="shipmentbar-loader">
                                <fade-loader :loading="loading" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <md-card-actions class="page-footer" md-alignment="space-between">
            <div>
                <p v-if="total === pagination.perPage" class="card-category">
                    Page {{ pagination.currentPage }} of many
                </p>
                <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
            </div>
            <pagination
                v-model="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                :enable-first-page-link="true"
                :enable-last-page-link="true"
                @change-page="handleChangePage($event, pagination.perPage)"
            />
        </md-card-actions>
        <transition name="slide">
            <shipment-sidebar
                v-if="showShipmentDetails"
                :shipment-id="shipmentId"
                :data="shipmentDetails"
                :team-members="teamMembers"
                v-click-outside="toggleShipmentDetailsWindow"
                @closeModal="toggleShipmentDetailsWindow"
            />
        </transition>
    </div>
</template>

<script>
import moment from 'moment';
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin, CarrierMixin } from '@/mixins';
import {
    SearchComponent,
    PreBookingIndicatorButton,
    StraightLineLoader,
    Pagination,
    CardHeaderDropdown
} from '@/components';
import SelectShipmentDropdown from '@/pages/Shipments/buttons/SelectShipmentDropdown';
import ShipmentFilterComponent from '@/pages/Shipments/components/ShipmentFilterComponent';
import { OFFER_QUOTING_STATUSES, SHIPMENT_HEADER_DROPDOWN_CONSTANTS } from '@/utils/constants';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import ShipmentSidebar from './ShipmentSidebar';

const signalR = require('@aspnet/signalr');

export default {
    name: 'CarrierQuotesView',
    props: {
        isQuotesView: {
            type: Boolean,
            default: () => false
        }
    },
    components: {
        FadeLoader,
        SearchComponent,
        SelectShipmentDropdown,
        PreBookingIndicatorButton,
        StraightLineLoader,
        Pagination,
        CardHeaderDropdown,
        ShipmentSidebar,
        ShipmentFilterComponent
    },
    mixins: [GeneralMixin, CarrierMixin],
    data() {
        return {
            isListLoading: false,
            loading: true,
            shouldCloseDropdown: false,
            showShipmentDetails: false,
            shipmentDetails: {},
            shipmentId: 0,
            topScrollPosition: 0,
            filters: {
                teamRegionId: null,
                carrierTeamId: null,
                fromDate: null,
                toDate: null,
                showBlankShipmentDateFirst: false,
                customerId: null,
                searchText: null,
                carrierQuote: null
            },
            isSelectAll: false,
            selectedShipmentIds: [],
            shipmentsList: [],
            quotingStatusOptions: OFFER_QUOTING_STATUSES,
            connection: '',
            teamMembers: [],
            pagination: PAGINATION_DEFAULTS,
            maxPage: 1,
            headerDropdown: SHIPMENT_HEADER_DROPDOWN_CONSTANTS,
            selectedCarrierHeader: 'Cheapest Carrier',
            selectedCarrierKey: null
        };
    },
    computed: {
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser',
            isLoading: 'isLoading',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            hasMarketplaceAccess: 'user/hasMarketplaceAccess',
            hasActiveCarriers: 'team/hasActiveCarriers',
            activeCarriers: 'team/activeCarriers',
            allTeamCarriers: 'team/teamCarriers'
        }),
        teamHasOfferMethodSetup() {
            return this.hasMarketplaceAccess && this.activeCarriers && this.activeCarriers.length > 0;
        },
        showQuotingStatusColumn() {
            return this.hasMarketplaceAccess && this.activeCarriers && this.activeCarriers.length > 0;
        },
        selectedShipmentsDisplayText() {
            if (!this.selectedShipmentIds.length) {
                return '';
            }

            let currency = null;

            for (let i = 0; i < this.selectedShipmentIds.length; i++) {
                const shipment = this.shipmentsList.find((x) => x.shipmentId === this.selectedShipmentIds[i]);
                if (shipment.selectedCarrierQuote) {
                    // eslint-disable-next-line prefer-destructuring
                    currency = shipment.selectedCarrierQuote.currency;
                    break;
                }
            }

            const selectedShipments = this.shipmentsList.filter(
                (x) => this.selectedShipmentIds.includes(x.shipmentId) && x.selectedCarrierQuote
            );

            let totalFees = selectedShipments.reduce(
                (subTotal, x) => subTotal + x.selectedCarrierQuote.amountAfterTax,
                0
            );

            totalFees = this.currencyFormatter(totalFees, currency);

            if (totalFees) {
                return `${this.selectedShipmentIds.length} shipment/s selected (${totalFees})`;
            }

            return `${this.selectedShipmentIds.length} shipment/s selected`;
        },
        filteredCarrierTeamOptions() {
            if (!this.allTeamCarriers) 
                return [];

            const defaultOption = [
                {
                    key: null,
                    label: 'Cheapest'
                },
                {
                    key: -1,
                    label: 'Fastest'
                }
            ];

            const options = this.allTeamCarriers.map((x) => {
                return {
                    key: x.carrierTeamId,
                    label: x.carrierTeam.company
                };
            });

            return defaultOption.concat(options);
        }
    },
    methods: {
        currencyFormatter(value, currency) {
            const userLocale = window.navigator.userLanguage || window.navigator.language;
            if (!value) 
                return '';
            if (!currency) 
                return value;
            try {
                return Intl.NumberFormat(userLocale, {
                    style: 'currency',
                    currency
                }).format(value);
            } catch {
                // Wrong currency (probably from the DB).
                return value;
            }
        },
        handleSelectedCarrierOption(item) {
            this.selectedCarrierKey = item.key;

            if (item.key == null || item.key === -1) {
                this.selectedCarrierHeader = `${item.label} Carrier`;
            } else {
                this.selectedCarrierHeader = `${item.label} Only`;
            }

            this.handleFilterOrSearch({ ...this.filters, carrierQuote: item.key });
        },
        toggleShipmentDetailsWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown)
                this.showShipmentDetails = !this.showShipmentDetails;
        },
        showShipmentDetailsSidebar(item) {
            this.showShipmentDetails = true;
            this.shipmentId = item.shipmentId;
        },
        async setupSignalR() {
            const user = await window.auth.getUser();

            this.connection = new signalR.HubConnectionBuilder()
                .withUrl('/api/CarrierHub', {
                    accessTokenFactory: () => {
                        return user.access_token;
                    }
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            try {
                await this.connection.start().then((result) => {
                    this.connection.invoke('JoinToTeamChannel');
                });

                this.connection.onclose(async () => {
                    await this.setupSignalR();
                });

                this.connection.on('GatheringQuotes', this.handleGatheringQuotes);
                this.connection.on('QuotesGenerated', this.handleQuotesGenerated);
                this.connection.on('NewQuote', this.handleNewQuote);
                this.connection.on('JobAssigned', this.handleJobAssigned);
                this.connection.on('BookingFailed', this.handleBookingFailed);
            } catch (err) {
                setTimeout(this.setupSignalR, 5000);
            }
        },
        handleNewQuote(quote, shipmentId) {
            // eslint-disable-next-line no-console
            console.log('new quote received');
            if (quote) {
                const shipment = this.shipmentsList.find((x) => x.shipmentId === shipmentId);
                if (shipment) {
                    this.$set(shipment, 'quotingStatus', 'QuotesGathered');

                    if (quote.error == null) {
                        // we only want valid quotes to show in the shipment list
                        shipment.quotes.push({ ...quote });
                        this.$set(shipment, 'activeQuotesCount', (shipment.activeQuotesCount += 1));
                        this.$set(shipment, 'quotes', shipment.quotes);
                    }
                }
            }
        },
        handleGatheringQuotes(shipmentId) {
            const shipment = this.shipmentsList.find((x) => x.shipmentId === shipmentId);
            if (shipment) {
                this.$set(shipment, 'showLoader', true);
            }
        },
        handleQuotesGenerated(shipmentId, quotedResults, allFailed) {
            const shipment = this.shipmentsList.find((x) => x.shipmentId === shipmentId);
            if (shipment) {
                const quotes = shipment.quotes || [];
                quotedResults.forEach((qr) => {
                    const theQuoteIndex = quotes.findIndex((x) => x.tempCarrierQuoteId === qr.tempCarrierQuoteId);
                    if (theQuoteIndex > -1) {
                        quotes[theQuoteIndex].carrierQuoteId = qr.carrierQuoteId;
                        quotes[theQuoteIndex].isBestQuote = qr.isBestQuote;

                        if (qr.isBestQuote) {
                            // set as selected
                            this.$set(shipment, 'selectedCarrierQuote', { ...quotes[theQuoteIndex] });
                            this.$set(shipment, 'bestQuote', { ...quotes[theQuoteIndex] });
                        }
                    }
                });

                if (allFailed) {
                    this.$set(shipment, 'quotingStatus', 'QuotingFailed');
                }

                this.$set(shipment, 'showLoader', false);
            }
        },
        handleJobAssigned(data) {
            const shipment = this.shipmentsList.find((x) => x.shipmentId === data.shipmentId);
            if (shipment) {
                this.$set(shipment, 'quotingStatus', data.quotingStatus);
                this.$set(shipment, 'showLoader', false);
            }
            this.removeSelected(data.shipmentId);
        },
        handleBookingFailed(data) {
            const shipment = this.shipmentsList.find((x) => x.shipmentId === data.shipmentId);
            if (shipment) {
                this.$set(shipment, 'quotingStatus', 'BookingFailed');
                this.$set(shipment, 'showLoader', false);
            }
        },
        removeSelected(shipmentId) {
            // remove from selected
            const ndx = this.selectedShipmentIds.findIndex((x) => x === shipmentId);
            if (ndx > -1) {
                this.selectedShipmentIds.splice(ndx, 1);
                this.isSelectAll = false;
            }
        },
        clearSelectedQuotes() {
            for (let i = 0; i < this.shipmentsList.length; i++) {
                this.shipmentsList[i].selectedCarrierQuote = null;
            }
            this.isSelectAll = false;
        },
        onSelectAll(value) {
            this.selectedShipmentIds = [];

            if (value) {
                this.shipmentsList.forEach((shipment) => {
                    if (shipment.quotingStatus !== 'JobAssigned') {
                        this.selectedShipmentIds.push(shipment.shipmentId);
                    }
                });
            }
        },
        onSelectOne() {
            this.isSelectAll = this.selectedShipmentIds.length === this.shipmentsList.length;
        },
        async handleQuoteSelected(args) {
            const shipment = this.shipmentsList.find((x) => x.shipmentId === args.shipmentId);

            if (args.quote == null) {
                shipment.selectedCarrierQuote = null;
            } else {
                shipment.selectedCarrierQuote = { ...args.quote };
                shipment.selectedCarrierQuote.isExpired = moment().isAfter(moment.utc(args.quote.expiryDate).local());
            }
        },
        async handleCheckPreBookingRequiredFieldsCompleted(args) {
            const shipment = this.shipmentsList.find((x) => x.shipmentId === args.shipmentId);
            shipment.mandatoryFields = args.mandatoryFields;
        },
        async handleUpdateShipmentCompleted(args) {
            if (args.error) {
                // there was an error
                this.removeSelected(args.shipmentId);
            }

            const shipment = this.shipmentsList.find((x) => x.shipmentId === args.shipmentId);

            let preBookingQuestions = {
                alreadyAnswered: false
            };
            if (args && !args.error) {
                const { mandatoryFields, fixedMappingList, newAnswersList } = args;
                preBookingQuestions = {
                    mandatoryFields,
                    fixedMappingList,
                    newAnswersList,
                    alreadyAnswered: true
                };
            }

            this.$set(shipment, 'preBookingQuestions', { ...preBookingQuestions });
        },
        shouldDisableCheckbox(shipment) {
            return (
                shipment.quotingStatus === 'JobAssigned' ||
                (shipment.preBookingQuestions && !shipment.preBookingQuestions.alreadyAnswered)
            );
        },
        async requestQuotes() {
            if (!this.selectedShipmentIds.length) 
                return;

            const carrierIds = [];

            if (this.filters.carrierQuote != null && this.filters.carrierQuote !== -1) {
                carrierIds.push(carrierQuote);
            }

            const data = [];
            this.selectedShipmentIds.forEach((shipmentId) => {
                data.push({
                    shipmentId,
                    teamCarrierIdList: carrierIds // pass specific here
                });

                const shipment = this.shipmentsList.find((x) => x.shipmentId === shipmentId);
                if (shipment) {
                    this.$set(shipment, 'quotingStatus', 'GatheringQuotes');
                    this.$set(shipment, 'showLoader', true);
                }
            });

            const api = `/api/carriers/bulk-request-quotes`;
            const payload = {
                method: 'post',
                data
            };

            try {
                await handleRequests(api, payload);
            } catch (err) {
                showErrorMessage(this, 'Error requesting quotes', err);
            }
        },
        async loadQuotes(id) {
            if (!id) 
                return;

            const api = `/api/carriers/${id}/quotes`;
            const payload = {
                method: 'get',
                params: {
                    shipmentId: id
                }
            };

            const resp = await handleRequests(api, payload);

            const quotes = resp.data && resp.data ? resp.data : [];

            const shipment = this.shipmentsList.find((x) => x.shipmentId === id);
            shipment.quotes = [];
            this.$set(shipment, 'quotes', quotes);
        },
        async bulkAssign() {
            if (!this.selectedShipmentIds.length) 
                return;

            const shipments = this.shipmentsList.filter((x) => this.selectedShipmentIds.includes(x.shipmentId));

            const hasNoSelectedQuote = shipments.every((x) => {
                return x.selectedCarrierQuote && x.selectedCarrierQuote.carrierQuoteId;
            });

            if (!hasNoSelectedQuote) {
                showErrorMessage(this, 'One of the shipments has no quote selected.');
                return;
            }

            const hasMandatoryFields = shipments.some((x) => {
                return x.preBookingQuestions && !x.preBookingQuestions.alreadyAnswered;
            });

            if (hasMandatoryFields) {
                showErrorMessage(this, "One of the shipments' required questionnaires should be completed.");
                return;
            }

            const data = [];
            shipments.forEach((x) => {
                data.push({
                    shipmentId: x.shipmentId,
                    carrierTeamId: x.selectedCarrierQuote.carrierTeamId,
                    carrierQuoteId: x.selectedCarrierQuote.carrierQuoteId
                });

                x.quotingStatus = 'Booking';
                x.showLoader = true;
            });
            const api = `/api/carriers/bulk-assign-carrier`;
            const payload = {
                method: 'post',
                data
            };

            try {
                await handleRequests(api, payload);
            } catch (err) {
                showErrorMessage(this, 'Error assigning to carriers', err);
            }
        },
        async handleFilterOrSearch(val) {
            let shouldSearch = false;
            Object.getOwnPropertyNames(val).forEach((q) => {
                shouldSearch = shouldSearch || (val[q] || null) !== (this.filters[q] || null);
            });

            if (!shouldSearch) 
                return; // exit function

            this.clearSelected();
            this.filters = Object.assign(this.filters, val);
            const {
                data: { shipmentQuotes, totalShipmentQuotes }
            } = await this.getUnassignedShipments(1, this.pagination.perPage);
            const shipmentsToAssign = totalShipmentQuotes || shipmentQuotes.length;
            this.assignShipments(shipmentQuotes, shipmentsToAssign);
        },
        async handleFetchingOfShipmentQuotesOnLoad() {
            const currentPage = Number(this.$route.query.currentPage) || 1;

            const {
                data: { shipmentQuotes, totalShipmentQuotes }
            } = await this.getUnassignedShipments(currentPage);

            if (currentPage) {
                this.pagination.currentPage = currentPage;
            }

            return { shipmentQuotes, totalShipmentQuotes };
        },
        async getUnassignedShipments(pageNumber = 1, itemsPerPage = 50) {
            const api = `/api/shipments/unassigned/quotes`;
            const payload = {
                method: 'get',
                params: {
                    pageNumber,
                    itemsPerPage,
                    ...this.filters
                }
            };

            this.isListLoading = true;
            this.loading = true;
            const response = await handleRequests(api, payload);

            this.isListLoading = false;
            this.loading = false;

            const { shipmentQuotes } = response.data;

            if (shipmentQuotes.length > 0) {
                shipmentQuotes.forEach((item) => {
                    const test = item;
                    if (test.bestQuote != null && test.bestQuote.expiryDate != null) {
                        test.bestQuote.isExpired = moment().isAfter(moment.utc(test.bestQuote.expiryDate).local());
                    }

                    this.$set(item, 'selectedCarrierQuote', test.bestQuote ?? null);
                    this.$set(item, 'preBookingQuestions', { alreadyAnswered: false });
                });

                response.shipmentQuotes = shipmentQuotes;
            }

            return response;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.$_handleLoaderState(true);
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            const {
                data: { shipmentQuotes, totalShipmentQuotes }
            } = await this.getUnassignedShipments(currentPage, perPage);
            const shipmentsToAssign = totalShipmentQuotes || shipmentQuotes.length;
            this.assignShipments(shipmentQuotes, shipmentsToAssign);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            this.$_handleLoaderState(false);
        },
        toQuotingStatusDesc(shipment) {
            if (shipment.quotingStatus) {
                const status = this.quotingStatusOptions.find((x) => x.value === shipment.quotingStatus);
                return status.text;
            }

            return shipment.quotingStatus;
        },
        getMaxPage(page) {
            this.maxPage = page;
        },
        clearSelected() {
            this.selectedShipmentIds = [];
            this.isSelectAll = false;
        },
        assignShipments(shipments, totalShipments) {
            this.clearSelected();

            this.shipmentsList = shipments;
            this.pagination.total = totalShipments;
        },
        handleSelectedView(value) {
            this.$emit('selectedView', value.key);
        },
        handleRefresh() {
            this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        async handleRefreshQuotes(item) {
            if (!item) 
                return;

            item.quotingStatus = 'GatheringQuotes';

            const carrierIds = [];

            if (item.selectedCarrierQuote != null) {
                carrierIds.push(item.selectedCarrierQuote.carrierTeamId);
            }

            const api = `/api/carriers/${item.shipmentId}/request-quotes`;
            const payload = {
                method: 'post',
                data: {
                    shipmentId: item.shipmentId,
                    teamCarrierIdList: carrierIds // pass specific here
                }
            };

            await handleRequests(api, payload)
                .then((resp) => {
                    const quotes = resp.data && resp.data ? resp.data : [];
                    const shipment = this.shipmentsList.find((x) => x.shipmentId === item.shipmentId);
                    shipment.quotes = [];
                    this.$set(shipment, 'quotes', quotes);

                    if (shipment.quotingStatus == null || shipment.quotingStatus === 'QuotesGathered') {
                        shipment.quotingStatus = 'QuotesGathered';
                    }
                })
                .catch((e) => {
                    item.quotingStatus = 'QuotingFailed'; // only on UI
                    const message = 'Error fetching quotes.';
                    showErrorMessage(this, message, e);
                });
        },
        async handleAssignQuoteToShipment(shipment) {
            if (!shipment.selectedCarrierQuote || shipment.selectedCarrierQuote.isExpired) {
                this.$notify({
                    message: `No selected quote for this shipment`,
                    type: 'danger'
                });
                return;
            }

            const alreadyAnswered = shipment.preBookingQuestions && shipment.preBookingQuestions.alreadyAnswered;
            let requiredFields;
            let questionnaires;
            let fixedAnswers;

            let preBookingQueryResults;

            if (alreadyAnswered) {
                ({
                    mandatoryFields: requiredFields,
                    newAnswersList: questionnaires,
                    fixedMappingList: fixedAnswers
                } = shipment.preBookingQuestions);
            } else {
                // get questionnaires and required fields that were not filled-ups
                preBookingQueryResults = await this.getPreBookingRequiredFields({
                    shipmentId: shipment.shipmentId,
                    carrierTeamId: shipment.selectedCarrierQuote.carrierTeamId
                });

                if (!preBookingQueryResults) {
                    // there was an error
                    const message = 'Could not retrieve question mappings.';
                    showErrorMessage(this, message);
                    this.$_handleLoaderState(false);
                    return;
                }

                ({ requiredFields, questionnaires, fixedAnswers } = preBookingQueryResults);
            }

            const hasRequiredQuestions = !alreadyAnswered && (requiredFields.length > 0 || questionnaires.length > 0);

            if (hasRequiredQuestions) {
                showErrorMessage(this, 'Required questionnaires should be completed.');
                return;
            }

            const combinedList = [...fixedAnswers, ...questionnaires];

            this.$_handleLoaderState(true, 'ASSIGNING SHIPMENT...');
            const api = `/api/shipments/${shipment.shipmentId}/assign-user`;
            const data = {
                assignToCarrierTeamId: shipment.selectedCarrierQuote.carrierTeamId,
                carrierQuoteId: shipment.selectedCarrierQuote.carrierQuoteId,
                carrierQuestionAnswers: combinedList,
                carrierRequiredFields: [] // already answered
            };

            const payload = {
                method: 'post',
                data
            };

            try {
                const { data: returnedData } = await handleRequests(api, payload);

                this.handleJobAssigned({ shipmentId: shipment.shipmentId, quotingStatus: 'JobAssigned' });

                this.$notify({
                    message: `Shipment was assigned to ${returnedData?.carrierTeam?.company}`,
                    type: 'success'
                });

                this.handleRefresh();
            } catch (e) {
                let message = 'Cannot assign shipment to the carrier.';
                // eslint-disable-next-line nonblock-statement-body-position, prefer-destructuring
                if (e && e.message) message = e.message;
                showErrorMessage(this, message, e);
                this.handleBookingFailed({ shipmentId: shipment.shipmentId });
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async getPreBookingRequiredFields({ shipmentId, carrierTeamId }) {
            if (!carrierTeamId) 
                return null;

            try {
                const api = `/api/carriers/${shipmentId}/pre-booking-check`;
                const payload = {
                    method: 'post',
                    data: { shipmentId, carrierTeamId }
                };
                const { data: requirdFields } = await handleRequests(api, payload);
                return requirdFields;
            } catch (e) {
                // eslint-disable-next-line no-unused-vars
                let message = 'Unable to perform pre-booking check.';
                if (e && e.message)
                    // eslint-disable-next-line prefer-destructuring
                    message = e.message;
                return null;
            }
        }
    },
    async mounted() {
        this.$_handleLoaderState(true);
        const { shipmentQuotes, totalShipmentQuotes } = await this.handleFetchingOfShipmentQuotesOnLoad();
        this.assignShipments(shipmentQuotes, totalShipmentQuotes || shipmentQuotes.length);

        if (this.shipmentsList.length > 0) {
            this.setupSignalR();
        }

        this.isListLoading = false;
        this.$_handleLoaderState(false);
    },
    beforeDestroy() {
        if (this.connection) {
            this.connection.invoke('RemoveFromTeamChannel').catch((err) => {
                // eslint-disable-next-line no-console
                return console.log(err);
            });
        }

        window.removeEventListener('resize', this.onResize);
        document.querySelector('.main-panel').removeEventListener('scroll', this.handleScroll);
    }
};
</script>

<style lang="scss" scoped>
.no-result-message {
    text-align: center;
    margin-top: 45px;
    font-size: 16px;
}

::v-deep .status-container {
    .md-table-cell-container {
        overflow: visible;
    }
}

.slide-leave-active,
.slide-enter-active {
    transition: 0.3s;
}
.slide-enter {
    transform: translateX(100%);
}
.slide-leave-to {
    transform: translateX(100%);
}

.content {
    margin-top: -15px;
    ::v-deep .md-card.md-theme-default {
        margin-bottom: 0;
    }
}

::v-deep .filter-steps--container {
    margin-top: 6px;

    .filter-steps--choices {
        margin-top: 0;
        padding-bottom: 0;
        > .md-input {
            display: initial;
        }
    }
}

::v-deep .search--container {
    width: 250px;
    float: right;
    margin-top: 6px;
}

::v-deep .md-table-cell-container {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .md-button.md-round.md-just-icon {
        box-shadow: none;
        vertical-align: middle;
    }
}

.address-container {
    display: none;
}

@media (min-width: 1400px) {
    .address-container {
        display: table-cell;
        ::v-deep .md-table-cell-container {
            max-width: 300px;
        }
    }
}

::v-deep .md-table-cell {
    padding: 10px 8px;
    &:last-child {
        .md-table-cell-container {
            text-align: left !important;
            justify-content: flex-start;
        }
    }
}

::v-deep .md-table-row {
    &.zero-results {
        padding: 16px;
        text-align: center;
    }

    .custom-button-container .custom-a-blue a {
        color: rgba(0, 0, 0, 0.87);
    }
}

::v-deep .md-table-row:hover {
    background-color: #fafafa;
    cursor: pointer;
}

::v-deep .md-toolbar {
    .md-field {
        &:first-child,
        &:last-child {
            margin: 8px;
        }
    }

    .shipments-search {
        padding-bottom: 10px;
        &::before,
        &::after {
            bottom: 10px;
        }
    }
}

::v-deep .md-card-actions {
    padding: 16px;
    &.page-footer {
        padding-top: 0px;
    }
}

.shipment-reference ::v-deep a,
.trip-date ::v-deep a {
    color: rgba(0, 0, 0, 0.87);
}

::v-deep .header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.gray-text {
    color: rgba(0, 0, 0, 0.5);
}

.shipmentbar-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

.action-buttons {
    button,
    ::v-deep button {
        margin: 0 2px;
    }

    button:last-child {
        margin-right: 0;
    }
}
.address-text {
    white-space: nowrap;
    width: 46%;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
}
.address-separator {
    float: left;
    margin-right: 10px;
    font-weight: bold;
}
.icon-warning {
    color: #ff9800 !important;
    cursor: pointer;
    left: 14px;
}
.shipment-checkbox {
    margin: 0;
}
.checkbox-head {
    margin-top: 4px;
}
.body-list {
    position: relative;
    padding-top: 30px;
    .sticky {
        position: fixed;
        top: 0;
        z-index: 9;
        left: 75px;
    }
}
.bulk-section {
    position: absolute;
    top: -18px;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    background-color: #cfeefa;
    color: #2b93ff;
    font-weight: 400;
    padding: 5px 10px 5px 20px;
    > div {
        width: 40%;
        display: inline-block;
        vertical-align: middle;
        text-align: left;
    }
    > div:last-child {
        width: 60%;
        text-align: right;
        button {
            height: 30px;
            min-width: 30px;
            max-width: 160px;
        }
    }
}

.bulk-error-message {
    z-index: 1 !important;
    > div {
        max-height: 160px;
        overflow: auto;
    }

    .bulk-single-container {
        display: table;
        > div {
            display: table-cell;
        }
        > div:first-child {
            width: 200px;
        }
    }
}
.tooltip-width {
    max-width: 500px;
}
.address-text-tooltip {
    text-align: left;
}

.quotes-warning-icon {
    color: #ff5252 !important;
    font-size: 20px !important;
}

.quotes-button {
    margin-left: 5px;
    vertical-align: middle;
}

::v-deep .status {
    min-width: 110px;
}

.quotes-loader {
    display: inline-block;
    margin-right: 3px;
}

.quote-status-container {
    .quoting-status {
        max-width: 250px;
    }
    > div > div {
        display: inline-block;
        padding: 4px 12px;
        padding-top: 1px;
        border-radius: 15px;

        .custom-badge {
            max-width: initial;
            padding: 0;
        }
    }
}

.started-loader {
    ::v-deep svg {
        circle {
            fill: #4e79ff;
        }
    }
}

.custom-toolbar-start {
    flex: 3;
}

.carrier-dropdown {
    ::v-deep .md-table-cell-container {
        max-width: initial;
        white-space: initial;
        overflow: initial;
        text-overflow: initial;
    }
}

.pre-booking-icon {
    width: 27px;
    min-width: 27px;
    height: 27px;
}

.small-column {
    max-width: 46px;
}

.content {
    margin-bottom: 100px;
}
.action-button-container {
    display: inline-block;
}

.service-badge {
    max-width: 150px;
}

.running-load-head {
    ::v-deep .md-table-head-label {
        float: left;
        padding-right: 5px;
    }

    .load-header-text,
    .load-header-text:hover {
        font-size: 1.0625rem;
        padding-left: 0;
        font-weight: 300;
        color: #4caf50 !important;
    }

    .load-header-text {
        max-width: 130px;
        padding-right: 0;
    }

    .header-carrier-text {
        font-size: 10px;
    }

    .icon-arrow {
        font-size: 24px !important;
        margin-top: -2px;
    }

    .open {
        .icon-arrow {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
        }
    }

    .icon-check {
        color: #fff !important;
        font-size: 16px !important;
        float: left;
    }

    .icon-check-color {
        color: #333333 !important;
    }

    .running-load-menu {
        ::v-deep .dropdown {
            position: absolute;
            top: 6px;
        }

        .dropdown-menu {
            margin-left: -15px;
            margin-top: 6px;
            max-height: 300px;
            overflow: auto;

            li {
                border-bottom: 0px;
                padding: 10px;
            }

            hr.border {
                border-top: 1px solid #ddd;
            }

            a {
                color: #333333 !important;
                padding: 0px;
                margin: 0px;
                font-size: 12px;
                font-weight: 400;
            }

            a:hover {
                background-color: transparent !important;
                box-shadow: none;
                color: #2b93ff !important;
            }
        }
    }
}

.quote-time {
    font-size: 10.5px;
    margin-top: 3px;
}

.line-menu {
    border-bottom: 1px solid #ddd !important;
}

.table-dropdown {
    overflow-y: auto;
    width: 200px;
}
</style>
