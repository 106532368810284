var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.offerSetting
    ? _c("div", { staticClass: "cf-container" }, [
        _c("p", { domProps: { innerHTML: _vm._s(_vm.settingParagraph) } }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }