<template>
    <div v-if="['complete', 'failed'].includes(this.status)" class="pod-button">
        <md-button
            :title="'Download ' + this.buttonText"
            :class="[
                useIcon ? 'md-info md-just-icon md-round' : `md-info ${className}`,
                isDisabled ? 'button-disabled' : ''
            ]"
            @click.stop="handleDownloadPdf(stopId)"
        >
            <md-icon v-if="useIcon">receipt_long</md-icon>
            <span v-else>Download {{ buttonText }}</span>
        </md-button>
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage, openFile } from '@/helpers';
import { mapGetters } from 'vuex';

export default {
    name: 'StopPdfDownloadButton',
    props: {
        stopId: {
            type: [Number, String],
            default: () => null
        },
        status: {
            type: String,
            default: () => null
        },
        stopType: {
            type: String,
            default: () => null
        },
        stopRef: {
            type: String,
            default: () => null
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        useIcon: {
            type: Boolean,
            default: () => true
        },
        className: {
            type: String,
            default: () => ''
        }
    },
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        }),
        buttonText() {
            if (this.stopType === 'Pick Up') {
                return this.status === 'complete' ? 'Proof of Collection PDF' : 'Pick Up Failed PDF';
            }
            return this.status === 'complete' ? 'Proof Of Delivery PDF' : 'Failed Delivery PDF';
        }
    },
    methods: {
        async handleDownloadPdf(stopId) {
            if (this.isDisabled) {
                this.$notify({
                    message: `Cannot create a ${this.buttonText} document for a stop that is not complete.`,
                    type: 'danger'
                });
            } else {
                this.$_handleLoaderState(true, 'PREPARING...');
                const payload = {
                    method: 'get',
                    responseType: 'blob'
                };
                const api = `/api/stops/${stopId}/proof-of-delivery-pdf`;

                let reportName = `Stop-${this.stopRef}.pdf`;
                if (!this.stopRef) 
                    reportName = `Stop-${this.stopId}.pdf`;

                try {
                    const response = await handleRequests(api, payload);
                    await openFile(response.data, reportName);
                    // await downloadFile(response.data, reportName);
                } catch (e) {
                    const message =
                        this.status === 'complete'
                            ? 'Error in creating the Proof Of Delivery PDF file.'
                            : 'Error in creating the Failed Delivery PDF file.';
                    showErrorMessage(this, message, e);
                }
                this.$_handleLoaderState(false);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.button-disabled {
    opacity: 0.2 !important;
    cursor: not-allowed;
    pointer-events: all !important;
}
.pod-button {
    display: inline;
}
</style>
