<template>
    <div v-if="marker">
        <template>
            <slot :breakId="driverBreak.driverBreakId" />
        </template>
    </div>
</template>

<script>
import { ref, toRefs, onMounted, onBeforeUnmount, inject, computed, watch } from '@vue/composition-api';
import { setColour } from '@/helpers';
import useMapUtils from '@/compostables/useMapUtils';

export default {
    name: 'DriverBreakMarker',
    props: {
        publicUserId: {
            type: String,
            required: true
        },
        driver: {
            type: Object,
            default: () => {}
        },
        driverBreak: {
            type: Object,
            default: () => {}
        },
        driverBreakOrder: {
            type: Number,
            default: 0
        },
        minDistance: {
            type: Number,
            default: 0
        }
    },
    setup(props, { root }) {
        const { publicUserId, driver, driverBreak, driverBreakOrder } = props;
        const { minDistance } = toRefs(props);
        const { setMarkers, getMarkerOptions } = useMapUtils(root);
        const { getters } = inject('vuex-store');
        const google = inject('google');
        const map = inject('map');
        const bounds = inject('bounds');
        const isStreetView = inject('isStreetView');

        const pinnedUser = computed(() => getters['map/pinnedUser']);
        const clickedStop = computed(() => getters['map/clickedStop']);

        const {
            location: { latitude, longitude },
            status,
            distance
        } = driverBreak;

        const circleIcon = Object.assign({}, getMarkerOptions(google.value, 'driverBreak', driver.markerPins), {
            fillColor: setColour(driverBreak.status.toLowerCase())
        });
        const standardIcon = getMarkerOptions(google.value, 'driverBreak', driver.markerPins);

        const isActiveDriverBreak = ['complete'].indexOf(status.toLowerCase()) === -1;

        const marker = ref(null);

        const changeIcon = (originalIcon, toChange) => {
            return Object.assign({}, originalIcon, toChange);
        };

        const toggleStopMarker = (pinnedUser) => {
            if (!pinnedUser && marker?.value) {
                marker.value.setMap(null);
            } else if (marker?.value) {
                if (pinnedUser === publicUserId) {
                    marker.value.setMap(map.value);
                    bounds.value.extend(marker.value.getPosition());
                    map.value.fitBounds(bounds.value);
                    const { icon } = marker.value;
                    const obj = {
                        fillColor: setColour(driverBreak.status.toLowerCase()),
                        strokeColor: setColour(driverBreak.status.toLowerCase())
                    };
                    marker.value.setIcon(changeIcon(icon, obj));
                } else
                    marker.value.setMap(null);
            }
        };

        watch(pinnedUser, (newVal) => {
            toggleStopMarker(newVal);
        });

        watch(isStreetView, (newVal) => {
            if (!newVal && (!pinnedUser.value || pinnedUser.value === publicUserId))
                marker.value.setMap(map.value);
            else
                marker.value.setMap(null);
        });

        watch(clickedStop, ({ driveBreakId, publicUserId }) => {
            if (driveBreakId === driverBreak.driverBreakId) {
                if (publicUserId) {
                    bounds.value.extend(marker.value.getPosition());
                    map.value.fitBounds(bounds.value);
                }

                marker.value.setAnimation(google.value.maps.Animation.BOUNCE);
                // currently google's animation will continue until stopped manually, so we put a 3 second offset before we stop the animation and scale the marker down to normal size
                setTimeout(() => {
                    marker.value.setAnimation(null);
                }, 2750);
            }
        });

        watch(minDistance, (newVal) => {
            const bounds = map.value.getBounds();
            if (!bounds.contains(marker.value.getPosition()))
                return true;

            if (newVal > distance) {
                marker.value.setIcon(circleIcon);
                marker.value.setLabel(null);
            } else {
                const label = {
                    fontWeight: 'bold',
                    text: (driverBreakOrder + 1).toString(),
                    color: '#fff'
                };

                // if pinned user, change icon to approriate colors
                const obj = {
                    fillColor: setColour(driverBreak.status.toLowerCase()),
                    strokeColor: setColour(driverBreak.status.toLowerCase())
                };
                const pinnedIcon = changeIcon(standardIcon, obj);
                marker.value.setIcon(pinnedUser.value === publicUserId ? pinnedIcon : standardIcon);
                marker.value.setLabel(!isActiveDriverBreak ? null : label);
            }

            return true;
        });

        onMounted(() => {
            const label = {
                fontWeight: 'bold',
                text: (driverBreakOrder + 1).toString(),
                color: '#fff'
            };

            marker.value = setMarkers(
                google.value,
                map.value,
                latitude,
                longitude,
                'driverBreak',
                driver.markerPins,
                !isActiveDriverBreak ? null : label,
                isActiveDriverBreak
            );

            // if stop is within minimum toggle distance, change the icon to a dot
            if (distance < minDistance.value) {
                marker.value.setIcon(circleIcon);
                marker.value.setLabel(null);
            }

            marker.value.addListener('click', () => {
                root.$emit('marker-clicked', { data: driverBreak, marker: marker.value, type: 'driverBreak' });
            });

            toggleStopMarker(pinnedUser.value);
        });

        // remove marker before unmount
        onBeforeUnmount(() => {
            marker.value.setMap(null);
            marker.value = null;
        });

        return {
            marker
        };
    },
    render: () => null
};
</script>
