<template>
    <div class="md-layout stop-list">
        <div id="loader123" class="loader" v-if="isLoading">
            <fade-loader :loading="isLoading" color="#333333" />
        </div>
        <div class="md-layout-item md-size-20">
            <ul class="stop-list-ui">
                <li class="stop-list-li-select-all">
                    <md-checkbox v-model="isSelectAll" @change="onSelectAll(isSelectAll)">Select All</md-checkbox>
                </li>
            </ul>
        </div>
        <div class="md-layout md-layout-item md-size-40 drawing-tool">
            <div class="gmnoprint">
                <div class="gmnoprint-child">
                    <button
                        @click="selectDrawing(null)"
                        draggable="false"
                        title="Stop drawing"
                        aria-label="Stop drawing"
                        type="button"
                        aria-pressed="true"
                        :class="['drawing-button', drawingType === null ? 'button-pressed' : '']"
                    >
                        <span class="button-span">
                            <div class="button-div">
                                <img alt="" :src="$root.drawingToolIcon" draggable="false" />
                            </div>
                        </span>
                    </button>
                </div>
                <div class="gmnoprint-child">
                    <button
                        @click="selectDrawing('circle')"
                        draggable="false"
                        title="Draw a circle"
                        aria-label="Draw a circle"
                        type="button"
                        aria-pressed="false"
                        :class="['circle-button', drawingType === 'circle' ? 'button-pressed' : '']"
                    >
                        <span class="button-span">
                            <div class="button-div">
                                <img alt="" :src="$root.drawingToolIcon" draggable="false" />
                            </div>
                        </span>
                    </button>
                </div>
                <div class="gmnoprint-child">
                    <button
                        @click="selectDrawing('polygon')"
                        draggable="false"
                        title="Draw a shape"
                        aria-label="Draw a shape"
                        type="button"
                        aria-pressed="false"
                        :class="['polygon-button', drawingType === 'polygon' ? 'button-pressed' : '']"
                    >
                        <span class="button-span">
                            <div class="button-div">
                                <img alt="" :src="$root.drawingToolIcon" draggable="false" />
                            </div>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="md-layout md-layout-item md-size-40 filter-link">
            <div :class="['md-layout-item filter-menu', startLocationMode === 'manual' ? 'md-size-65' : 'md-size-90']">
                <drop-down direction="down">
                    <a href="#" v-if="!isFiltered">
                        <md-icon class="icon-filter">filter_alt</md-icon>
                        Filter
                    </a>
                    <a href="#" v-else>{{ filterText }}</a>
                    <ul class="dropdown-menu">
                        <li>
                            <a href="#" @click="openFilterByTimeWindow">By Time Window</a>
                        </li>
                        <li v-if="skillOptions.length != 0">
                            <a href="#" @click="openFilterStopsBySkills">By Skills</a>
                        </li>
                        <li class="dropdown-child-menu">
                            <a href="#">
                                By Selection Status
                                <md-icon style="margin-left: 3px" class="icon-arrow">navigate_next</md-icon>
                            </a>
                            <ul>
                                <li v-for="(stopMenu, index) in stopMenuOptions" :key="index">
                                    <md-icon :class="['icon-check', stopMenu.checked ? 'icon-check-color' : '']">
                                        check
                                    </md-icon>
                                    <a href="#" @click="handleFilterStopsClick(stopMenu)">
                                        {{ stopMenu.name }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="dropdown-child-menu">
                            <a href="#">
                                By Validation Status
                                <md-icon class="icon-arrow">navigate_next</md-icon>
                            </a>
                            <ul>
                                <li v-for="(validationMenu, index) in validationMenuOptions" :key="index">
                                    <md-icon :class="['icon-check', validationMenu.checked ? 'icon-check-color' : '']">
                                        check
                                    </md-icon>
                                    <a href="#" @click="handleFilterValidationClick(validationMenu)">
                                        {{ validationMenu.name }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li v-if="showTeamRegion" class="dropdown-child-menu">
                            <a href="#">
                                By Team Region
                                <md-icon class="icon-arrow">navigate_next</md-icon>
                            </a>
                            <ul>
                                <li v-for="(teamRegionMenu, index) in teamRegionMenuOptions" :key="index">
                                    <md-icon :class="['icon-check', teamRegionMenu.checked ? 'icon-check-color' : '']">
                                        check
                                    </md-icon>
                                    <a href="#" @click="handleFilterTeamRegionClick(teamRegionMenu)">
                                        {{ getTeamRegionMenuText(teamRegionMenu) }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="dropdown-child-menu">
                            <a href="#">
                                By Service Area
                                <md-icon class="icon-arrow">navigate_next</md-icon>
                            </a>
                            <ul>
                                <li v-for="(serviceArea, index) in serviceAreaList" :key="index">
                                    <md-icon :class="['icon-check', serviceArea.checked ? 'icon-check-color' : '']">
                                        check
                                    </md-icon>
                                    <a href="#" @click="handleFilterServiceAreaClick(serviceArea)">
                                        {{ serviceArea.name }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li v-if="isFiltered" class="clear-filter">
                            <a href="#" @click="clearFilters">Clear Filters</a>
                        </li>
                    </ul>
                </drop-down>
            </div>
            <div class="md-layout-item md-size-35" v-if="startLocationMode === 'manual'">
                <drop-down direction="down">
                    <a href="#">
                        <md-icon class="icon-filter">settings</md-icon>
                        Options
                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <a href="#" @click="openPickupLocationDistanceModal">Valid Radius</a>
                        </li>
                    </ul>
                </drop-down>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <md-table :value="unassignedStopList" class="stop-table" v-scrollTop>
                <md-table-row
                    slot="md-table-row"
                    slot-scope="{ item }"
                    @mouseover="onStopMouseover(item.stopId, item.shipmentId)"
                    @mouseout="onStopMouseout(item.stopId, item.shipmentId)"
                    :class="item.isOutside ? 'stop-outside' : ''"
                >
                    <md-table-cell>
                        <div v-if="item.isOutside">
                            <md-icon class="icon-warning">warning</md-icon>
                            <md-tooltip class="tooltip-width" md-direction="right">
                                This stop is {{ item.distance }} km from the start location
                            </md-tooltip>
                        </div>
                        <md-checkbox
                            v-else
                            type="checkbox"
                            :value="item.stopId || item.shipmentId"
                            v-model="selectedAddress"
                            @change="onSelectAddress(item.stopId, item.shipmentId)"
                        ></md-checkbox>
                    </md-table-cell>
                    <md-table-cell class="stop-reference" md-label="Stop Reference Number">
                        <router-link
                            v-if="item.stopId && item.shipmentId == null"
                            :to="{
                                name: 'Stop Details',
                                params: { stopId: item.stopId }
                            }"
                            target="_blank"
                        >
                            {{ item.stopRef }}
                        </router-link>
                        <router-link
                            v-if="item.shipmentId"
                            :to="{
                                name: 'Shipment Details',
                                params: { shipmentId: item.shipmentId }
                            }"
                            target="_blank"
                        >
                            {{ item.shipmentRef }}
                        </router-link>
                    </md-table-cell>
                    <md-table-cell class="address-container" md-label="Address">
                        <div v-if="item.isOutside && item.stopId" class="stop-address" @click="onAddressClick(item)">
                            <google-autocomplete
                                :ref="item.stopId"
                                :id="item.stopRef"
                                :stop-id="item.stopId"
                                v-model="item.locationData"
                                classname="form-control autocomplete-input"
                                label=""
                                icon=""
                                placeholder=""
                                @placechanged="onStopLocationChanged"
                            ></google-autocomplete>
                        </div>
                        <div v-else-if="item.shipmentId">
                            <md-icon>local_shipping</md-icon>
                            {{ item.pickupStop.address }}
                            <md-icon>arrow_right_alt</md-icon>
                            {{ item.dropStop.address }}
                        </div>
                        <div v-else>
                            {{ item.address }}
                        </div>
                    </md-table-cell>
                </md-table-row>
            </md-table>
            <div v-if="stopList.length == 0" class="text-center">
                <p class="no-result-message">No results matching your search/filter could be found.</p>
            </div>
            <md-card-actions md-alignment="space-between" v-if="stopList.length > 0">
                <div>
                    <p v-if="total === pagination.perPage" class="card-category">
                        Page {{ pagination.currentPage }} of many
                    </p>
                    <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                </div>
                <pagination
                    v-model="pagination.currentPage"
                    class="pagination-no-border pagination-success"
                    :per-page="pagination.perPage"
                    :total="total"
                    @change-page="handleChangePage($event, pagination.perPage)"
                />
            </md-card-actions>
        </div>
    </div>
</template>
<script>
import { MapOverviewMixin } from '@/mixins/MapOverviewMixin';
import { GoogleAutocomplete, Pagination } from '@/components';
import { handleRequests, showTeamRegionControl } from '@/helpers';
import FilterStopsModal from '@/pages/BatchStop/filter/FilterStopsModal';
import FilterStopsBySkillsModal from '@/pages/BatchStop/filter/FilterStopsBySkillsModal';
import DistanceSettingsModal from '@/pages/BatchStop/Settings/DistanceSettingsModal';
import { mapGetters } from 'vuex';
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import FadeLoader from 'vue-spinner/src/FadeLoader';

export default {
    name: 'SelectStopsStep',
    mixins: [MapOverviewMixin],
    components: {
        GoogleAutocomplete,
        Pagination,
        FadeLoader
    },
    props: {
        optimiseConfiguration: {
            type: Object,
            default: () => {}
        },
        stopList: {
            type: Array,
            default: () => {}
        },
        stopsMarkers: {
            type: Array,
            default: () => {}
        },
        map: {
            type: Object,
            default: () => {}
        },
        drawingManager: {
            type: Object,
            default: () => {}
        },
        timeWindowFilterOptions: {
            type: Array,
            default: () => {}
        },
        skillOptions: {
            type: Array,
            default: () => {}
        },
        startLocationMode: {
            type: String,
            default: null
        },
        selectedDrawnShapeStops: {
            type: Array,
            default: () => {}
        },
        totalStops: { type: Number, default: () => null },
        stopListSorting: Boolean,
        teamRegions: { type: Array, default: () => [] },
        unassignedStopsWithoutTeamRegion: { type: Number, default: () => null },
        activeValidationFilter: {
            type: Object,
            default: () => {}
        },
        activeSelectionFilter: {
            type: Object,
            default: () => {}
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        const teamRegionId = this.optimiseConfiguration.teamRegionId || this.user.teamRegionId;
        this.updateTeamRegionFilters(teamRegionId || null); // if User has no TR, default to All ( null ) !important
    },
    data() {
        return {
            filterText: '',
            isFiltered: false,
            filteredStopsList: [],
            selectedFilterValue: [],
            selectedSkills: [],
            isSelectAll: true,
            selectedAddress: [],
            drawingType: null,
            stopMenuOptions: [
                {
                    id: 1,
                    name: 'All Stops',
                    checked: true
                },
                {
                    id: 2,
                    name: 'Selected Stops Only',
                    checked: false
                },
                {
                    id: 3,
                    name: 'Unselected Stops Only',
                    checked: false
                }
            ],
            validationMenuOptions: [
                {
                    id: 1,
                    name: 'All Stops',
                    checked: true
                },
                {
                    id: 2,
                    name: 'Valid Stops Only',
                    checked: false
                },
                {
                    id: 3,
                    name: 'Invalid Stops Only',
                    checked: false
                }
            ],
            teamRegionMenuOptions: null,
            pagination: PAGINATION_DEFAULTS
        };
    },
    methods: {
        onSelectAll(value, hasShapeSelected) {
            if (this.isSelectAll) {
                const selected = [];
                this.stopList.forEach((stop) => {
                    if (stop.stopId || stop.shipmentId) {
                        selected.push(stop.stopId || stop.shipmentId);
                    }
                });
                this.selectedAddress = selected;
                if (value) {
                    this.$emit('selectedAllUnassignedStops', this.selectedAddress, hasShapeSelected);
                }
            } else if (value === false) {
                this.selectedAddress = [];
                this.$emit('selectedAllUnassignedStops', this.selectedAddress, hasShapeSelected);
            }
        },
        updateSelectAll() {
            if (this.stopList.length === this.selectedAddress.length) {
                this.isSelectAll = true;
            } else {
                this.isSelectAll = false;
            }
        },
        onSelectAddress(stopId, shipmentId) {
            const isChecked = this.selectedAddress.find((val) => {
                return val === stopId || val === shipmentId;
            });
            this.$emit('selectedUnassignedStops', stopId, shipmentId, !!isChecked, this.selectedAddress);
        },
        onStopMouseover(stopId, shipmentId) {
            // Used to invoke a "pulse" effect on the corresponding marker in the map for the stop that the user has their mouse over in the list,
            // to help the user identify it easily
            const isChecked = this.selectedAddress.find((val) => {
                return val === stopId || val === shipmentId;
            });
            if (isChecked) {
                this.$emit('onStopMouseOverOut', stopId, shipmentId, true);
            }
        },
        onStopMouseout(stopId, shipmentId) {
            // Used to stop the "pulse" effect on the corresponding marker in the map for this stop, that was invoked when they put their mouse over it in the list.
            const isChecked = this.selectedAddress.find((val) => {
                return val === stopId || val === shipmentId;
            });
            if (isChecked) {
                this.$emit('onStopMouseOverOut', stopId, shipmentId, false);
            }
        },

        onAddressClick(stop) {
            const marker = this.stopsMarkers.find((stopMarker) => {
                return stopMarker.stopId === stop.stopId || stopMarker.shipmentId === stop.stopId;
            });
            this.setMarkerPosition(marker, stop.location);
        },

        onStopLocationInputChange(stopId) {
            this.inputChangeStopId = stopId;
        },

        async onStopLocationChanged(stopAddressData) {
            const stop = this.stopList.find((stop) => {
                return stop.stopId === stopAddressData.stopId;
            });

            const marker = this.stopsMarkers.find((stop) => {
                return stop.stopId === stopAddressData.stopId;
            });
            const { address, name, location } = stopAddressData;
            if (location) {
                stop.address = address;
                stop.name = name;
                stop.location = location;
                stop.assignedTo = {
                    publicUserId: null
                };
                const api = `/api/stops/${stop.stopId}`;
                const payload = {
                    method: 'put',
                    data: stop
                };

                try {
                    await handleRequests(api, payload);

                    this.$_map_removeMapElement(marker, this.map);
                    this.$_map_addMapElement(marker, this.map);
                    this.setMarkerPosition(marker, location);
                    this.$emit('calculateStopsDistance', false);
                } catch (e) {
                    this.$notify({
                        message: 'Could not update stop address.',
                        type: 'danger'
                    });
                }
            }
        },
        setMarkerPosition(marker, location) {
            const currentPosition = this.$_map_createLatLngPoint(location.latitude, location.longitude);
            marker.setPosition(currentPosition);
            this.map.setCenter(marker.getPosition());
        },
        openFilterByTimeWindow() {
            if (this.timeWindowFilterOptions.length > 0) {
                this.$modal
                    .show(FilterStopsModal, {
                        data: this.timeWindowFilterOptions.sort((x, y) => (x.value > y.value ? 1 : -1)),
                        title: 'Filter By Time Window',
                        selectedFilterValue: this.selectedFilterValue
                    })
                    .then((response) => {
                        if (response.value) {
                            this.isFiltered = true;
                            this.filterText = 'Filtered By Time Window';
                            this.selectedFilterValue = response.value;
                            this.$emit('applyTimeWindowFilter', this.selectedFilterValue);
                        }
                        this.$modal.hide();
                    });
            } else {
                this.$notify({
                    message: 'None of the stops in the list below have time windows to filter by.',
                    type: 'danger'
                });
            }
        },
        openFilterStopsBySkills() {
            this.selectedFilterValue = [];
            this.$modal
                .show(FilterStopsBySkillsModal, {
                    data: this.skillOptions.sort((x, y) => (x.name > y.name ? 1 : -1)),
                    title: 'Filter Stops By Skills',
                    selectedSkillsValue: this.selectedSkills
                })
                .then((response) => {
                    if (response.value) {
                        this.isFiltered = true;
                        this.filterText = 'Filtered Stops By Skills';
                        this.selectedSkills = response.value;
                        this.$emit('applyStopsBySkillsFilter', this.selectedSkills);
                        this.clearSelectionFilters();
                    }
                    this.$modal.hide();
                });
        },
        clearFilterValues() {
            Object.assign(this.activeValidationFilter, {});
            this.filterText = '';
            this.isFiltered = false;
            this.selectedFilterValue = [];
            this.selectedSkills = [];
            this.clearSelectionFilters();
        },
        clearFilters() {
            this.clearFilterValues();
            this.$emit('clearTimeWindowFilter');
        },
        openPickupLocationDistanceModal() {
            this.$modal.show(DistanceSettingsModal).then(() => {
                this.$emit('calculateStopsDistance', true, true);
            });
        },
        selectDrawing(mode) {
            this.drawingType = mode;
            this.$emit('selectDrawing', mode);
        },
        clearSelectionFilters() {
            this.validationMenuOptions.forEach((validationMenu) => {
                validationMenu.checked = validationMenu.id === 1;
            });
            this.stopMenuOptions.forEach((stopMenu) => {
                stopMenu.checked = stopMenu.id === 1;
            });
        },
        setSelectionFilter(type, optionId) {
            if (optionId > 1) {
                this.isFiltered = true;
                this.filterText = `Filtered By ${type}`;
            }
            if (type === 'Selection Status') {
                this.stopMenuOptions.find((x) => x.checked === true).checked = false;
                this.stopMenuOptions.find((x) => x.id === optionId).checked = true;
            } else if (type === 'Validation Status') {
                this.validationMenuOptions.find((x) => x.checked === true).checked = false;
                this.validationMenuOptions.find((x) => x.id === optionId).checked = true;
            } else if (type === 'Team Region') {
                this.teamRegionMenuOptions.find((x) => x.checked === true).checked = false;
                this.teamRegionMenuOptions.find((x) => x.teamRegionId === optionId).checked = true;
            }
        },
        handleFilterStopsClick(option) {
            this.clearFilterValues();
            this.setSelectionFilter('Selection Status', option.id);
            Object.assign(this.activeSelectionFilter, option);
            this.$emit('handleFilterStopsClick', option);
        },
        handleFilterValidationClick(option) {
            this.clearFilterValues();
            this.setSelectionFilter('Validation Status', option.id);
            Object.assign(this.activeValidationFilter, option);
            this.$emit('handleFilterValidationClick', option);
        },
        handleFilterTeamRegionClick(option) {
            this.clearFilterValues();
            this.setSelectionFilter('Team Region', option.teamRegionId);
            const teamRegionId = option.teamRegionId && option.teamRegionId === 0 ? null : option.teamRegionId;
            this.optimiseConfiguration.teamRegionId = teamRegionId;
            // this.$emit('handleFilterTeamRegionClick', { ...option, teamRegionId });
        },
        handleFilterServiceAreaClick(option) {
            this.serviceAreaList.forEach((obj) => {
                if (obj.name === option.name) {
                    obj.checked = !obj.checked;
                }
            });

            this.$emit('handleFilterServiceAreaClick', this.serviceAreaList);
        },
        updateTeamRegionFilters(currentUserTeamRegionId) {
            if (this.teamRegions && this.teamRegions.length > 0) {
                const filtered = this.teamRegions.flatMap((obj) => {
                    if (
                        this.user.teamRegionId &&
                        this.user.teamRegionId !== -1 &&
                        this.user.teamRegionId !== obj.teamRegionId
                    )
                        return [];
                    return [
                        {
                            ...obj,
                            checked: currentUserTeamRegionId === obj.teamRegionId,
                            unassignedStops: obj.unassignedStops
                        }
                    ];
                });
                const teamRegionDefaultOptions = [
                    {
                        name: 'All',
                        teamRegionId: null,
                        checked: currentUserTeamRegionId === null
                    },
                    {
                        name: 'None',
                        teamRegionId: -1,
                        checked: currentUserTeamRegionId === -1,
                        unassignedStops: this.unassignedStopsWithoutTeamRegion
                    }
                ];
                /**
                 * All option should always be displayed, regardless of whether the user has a team region assigned.
                 * However, when this is selected, it should only show stops from their assigned team region, and stops/shipments
                 * which have no team region assigned at all.
                 */
                this.teamRegionMenuOptions = [...teamRegionDefaultOptions, ...filtered];
            }
        },
        getTeamRegionMenuText(teamRegionMenu) {
            if (teamRegionMenu.unassignedStops > 1) {
                return `${teamRegionMenu.name} (${teamRegionMenu.unassignedStops} stops)`;
            }
            if (teamRegionMenu.unassignedStops === 1) {
                return `${teamRegionMenu.name} (${teamRegionMenu.unassignedStops} stop)`;
            }
            return teamRegionMenu.name;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            // await this.fetchAssetGroupList(currentPage, perPage);
            // this.$router.replace({ path: this.$route.path, query: { currentPage } });
        }
    },
    computed: {
        unassignedStopList() {
            if (this.stopList.length) {
                this.onSelectAll();
            }
            const stops = this.stopList
                .filter((stop) => stop.stopId > 0 || stop.shipmentId > 0)
                .slice(
                    (this.pagination.currentPage - 1) * this.pagination.perPage,
                    this.pagination.currentPage * this.pagination.perPage
                );

            if (this.stopListSorting) {
                const sortStops = stops.sort((x, y) => (x.isOutside > y.isOutside ? -1 : 1));
                sortStops.forEach((stop, index) => {
                    stop.stopIndex = index;
                });
                return sortStops;
            }
            return stops.sort((x, y) => (x.stopIndex > y.stopIndex ? 1 : -1));
        },
        ...mapGetters({
            user: 'user/user',
            hasAssetAccess: 'user/hasAssetAccess',
            canEditOwnTeamRegion: 'user/canEditOwnTeamRegion'
        }),
        showTeamRegion() {
            return showTeamRegionControl(
                {
                    user: this.user,
                    hasAssetAccess: this.hasAssetAccess,
                    teamRegions: this.teamRegions
                },
                this.user.teamRegionId
            );
        },
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.totalStops;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        serviceAreaList() {
            const serviceList = this.user.serviceAreasOptions.map((sa) => ({ name: sa, checked: false }));
            return serviceList;
        }
    },
    watch: {
        stopList() {
            const stops = this.stopList
                .map((x) => x.stopId || x.shipmentId)
                .filter((s) => this.selectedAddress.includes(s));
            if (this.selectedAddress.length !== stops.length || this.isSelectAll) {
                this.isSelectAll = true;
                this.onSelectAll(this.isSelectAll);
            } else if (this.selectedAddress.length && this.optimiseConfiguration.teamRegionId !== null) {
                this.$emit('selectedAllUnassignedStops', this.selectedAddress);
            }
        },
        selectedAddress(stops) {
            this.updateSelectAll();
        },
        timeWindowFilterOptions() {
            this.clearFilterValues();
        },
        selectedDrawnShapeStops(value) {
            if (!this.drawingType) {
                // this.isSelectAll = true;
                // this.onSelectAll(this.isSelectAll);
            } else if (this.drawingType === 'circle' || this.drawingType === 'polygon') {
                this.isSelectAll = false;
                this.onSelectAll(this.isSelectAll, true);
                this.selectedAddress = this.selectedDrawnShapeStops;
            }
        },
        'drawingManager.drawingMode': function(mode) {
            this.drawingType = mode;
        },
        teamRegions: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.updateTeamRegionFilters(this.optimiseConfiguration.teamRegionId);
            }
        },
        totalStops() {
            this.pagination.currentPage = 1;
        }
    }
};
</script>
<style lang="scss" scoped>
.stop-list {
    margin-top: -25px;
    .md-layout-item {
        padding-right: 0px;
        padding-left: 0px;
    }
}
.stop-table {
    padding-left: 0;
    line-height: 18px;
    list-style-type: none;
    max-height: 350px;
    background-color: transparent;
    ::v-deep > div {
        background-color: transparent !important;
    }

    ::v-deep .md-table-head {
        display: none;
    }

    .md-table-cell {
        padding: 0px;
        border: 0px;
        height: 32px;
    }
    .address-container {
        max-width: 410px;
        ::v-deep .md-table-cell-container {
            white-space: nowrap;
            width: 410px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .stop-reference a {
        color: #2b93ff;
        padding-left: 5px;
    }
    .stop-outside {
        .icon-warning {
            color: #ff1744 !important;
            cursor: pointer;
            left: 14px;
        }
    }
}
.stop-list-ui {
    height: 19px;
}
.stop-table,
.stop-list-ui {
    padding-left: 0;
    line-height: 18px;
    list-style-type: none;
    ::v-deep .md-checkbox {
        margin: 5px;
    }
    .md-checkbox .md-checkbox-container {
        width: 16px;
        min-width: 16px;
        height: 16px;
    }
    .md-checkbox .md-checkbox-container:after {
        width: 5px;
        height: 11px;
    }
    .md-checkbox.md-checked .md-checkbox-container:after {
        left: 5px;
    }
    .md-checkbox .md-checkbox-label {
        color: #5f5e5c;
        line-height: 17px;
    }
    .md-icon {
        margin-left: 2px;
    }
}

.stop-list-li-select-all {
    padding-left: 0px;
    border-bottom: 1px solid #e8e8e8;
    margin-left: 0px;
    padding-bottom: 00px;
    .md-checkbox .md-checkbox-label {
        color: #5f5e5c;
        font-weight: 500;
    }
}
.tooltip-width {
    max-width: 500px;
}
.stop-address {
    ::v-deep input {
        height: 18px;
        color: red;
        font-weight: 300;
        width: 100%;
        background-image: none;
    }
    .md-field:after {
        position: relative;
    }
    .md-field {
        padding: 0;
        margin: 0;
    }

    .md-field:before {
        display: none;
    }
}
.filter-link {
    text-align: right;
    border-bottom: 1px solid #e8e8e8;
    .icon-filter {
        color: #2b93ff !important;
        font-size: 16px !important;
        margin-top: -2px;
        padding-left: 10px;
    }
    .icon-arrow {
        color: #333333 !important;
        font-size: 16px !important;
        padding-left: 25%;
    }
    .icon-check {
        color: #fff !important;
        font-size: 16px !important;
        margin-top: 5px;
        float: left;
        padding-left: 10px;
    }
    .icon-check-color {
        color: #333333 !important;
    }
    .clear-filter {
        border-top: 1px solid #e9e9e9;
    }
    .filter-menu {
        .dropdown-menu {
            margin-left: 50px;
            li {
                border-bottom: 0px;
            }
            li:last-child {
                border-bottom: 0px !important;
            }
            a {
                color: #333333 !important;
            }
            a:hover {
                background-color: transparent !important;
                box-shadow: none;
                color: #2b93ff !important;
            }
            a:hover > i {
                color: #2b93ff !important;
            }
        }
        .dropdown-child-menu {
            position: relative;
            border-bottom: 0;
            ul {
                background-color: white;
                list-style-type: none;
                cursor: pointer;
                padding: 0px;
                position: absolute;
                top: 0;
                left: 100% !important;
                z-index: 10;
                display: none;
                min-width: 160px;
                border-radius: 2px;
                li > a {
                    padding-left: 10px;
                }
            }
            &:hover > ul {
                display: block;
            }
        }
        .dropdown-menu > li:hover > ul {
            left: auto;
            padding-top: 5px;
            min-width: 100%;
        }
    }
    a {
        color: #2b93ff !important;
        font-size: 12px;
        font-weight: 400;
    }
    .filter-clear {
        position: relative !important;
        top: 12px;
        margin-top: 4px;
        font-weight: 400;
        color: #afafaf;
        cursor: pointer;
    }
}
.drawing-tool {
    border-bottom: 1px solid #e8e8e8;
    .gmnoprint {
        margin-top: 10px;
    }
    .gmnoprint-child {
        float: left;
        line-height: 0;
    }
    button {
        background: none padding-box transparent;
        display: block;
        border: 0px;
        margin: 0px;
        padding: 4px;
        text-transform: none;
        appearance: none;
        position: relative;
        cursor: pointer;
        user-select: none;
        direction: ltr;
        overflow: hidden;
        text-align: left;
        font-family: Roboto, Arial, sans-serif;
        font-size: 11px;
        border: 1px solid #e8e8e8;
        span {
            display: inline-block;
        }
        div {
            width: 16px;
            height: 16px;
            overflow: hidden;
            position: relative;
        }
        img {
            position: absolute;
            left: 0px;
            user-select: none;
            border: 0px;
            padding: 0px;
            margin: 0px;
            max-width: none;
            width: 16px;
            height: 192px;
        }
    }
    .button-pressed {
        background: #e5e5e5;
        -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
        -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
        box-shadow: inset 0px 0px 5px #c1c1c1;
        outline: none;
    }
    .drawing-button {
        color: rgb(0, 0, 0);
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        font-weight: 500;
        img {
            top: -144px;
        }
    }
    .circle-button {
        color: rgb(86, 86, 86);
        img {
            top: -160px;
        }
    }
    .polygon-button {
        color: rgb(86, 86, 86);
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
        img {
            top: -64px;
        }
    }
}
::v-deep .loader {
    position: relative;
    top: 20%;
    left: 50%;
}
</style>
