var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "detail-container" }, [
      _c(
        "div",
        { staticClass: "close-button" },
        [
          _c(
            "md-button",
            {
              staticClass: "md-default md-just-icon md-round pull-right",
              on: { click: _vm.closeModal },
            },
            [_c("md-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      !_vm.loading
        ? _c("div", [
            _c("div", { staticClass: "detail-section text-center" }, [
              _c(
                "span",
                { staticClass: "title blue-ref" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Asset Details",
                          params: { assetId: _vm.assetDetails.assetId },
                        },
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.assetDetails.name) +
                          "\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "detail-group text-center action-detail-group" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "md-button md-success md-just-icon md-round",
                      attrs: {
                        title: "View asset details",
                        to: {
                          name: "Asset Details",
                          params: { assetId: _vm.assetDetails.assetId },
                        },
                        target: "_blank",
                      },
                    },
                    [
                      _c("div", { staticClass: "md-ripple" }, [
                        _c(
                          "div",
                          { staticClass: "md-button-content" },
                          [_c("md-icon", [_vm._v("visibility")])],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c("update-asset-button", {
                    attrs: {
                      asset: _vm.assetDetails,
                      "custom-fields": _vm.assetCustomFieldList,
                    },
                    on: { onAssetUpdate: _vm.updateAsset },
                  }),
                  _vm.assetType == "Vehicle"
                    ? _c("create-assign-maintenance-schedule-button", {
                        attrs: {
                          "maintenance-schedules": _vm.maintenanceSchedules,
                          asset: _vm.assetDetails,
                        },
                        on: {
                          onAssignSchedule: _vm.handleAssignMaintenanceSchedule,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "line-separator" }),
            _c("div", { staticClass: "detail-section" }, [
              _c("span", { staticClass: "title" }, [_vm._v("Asset Details")]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Name:")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.assetDetails.name) +
                      "\n                    "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Type:")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.assetDetails.type) +
                      "\n                    "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [
                  _vm._v(
                    "\n                        Odometer:\n                    "
                  ),
                ]),
                _vm.assetDetails.estimatedOdometerValue
                  ? _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            Number(
                              _vm.assetDetails.estimatedOdometerValue
                            ).toLocaleString()
                          ) +
                          "\n                        " +
                          _vm._s(_vm.assetDetails.estimatedOdometerUnitType) +
                          "\n                    "
                      ),
                    ])
                  : _c("span", { staticClass: "value" }, [
                      _c("i", [_vm._v("(Not set)")]),
                    ]),
              ]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [
                  _vm._v(
                    "\n                        Engine hours:\n                    "
                  ),
                ]),
                _vm.assetDetails.estimatedEngineHourValue
                  ? _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            Number(
                              _vm.assetDetails.estimatedEngineHourValue
                            ).toLocaleString()
                          ) +
                          " hours\n                    "
                      ),
                    ])
                  : _c("span", { staticClass: "value" }, [
                      _c("i", [_vm._v("(Not set)")]),
                    ]),
              ]),
              _vm.assetDetails.type === "Vehicle" ||
              _vm.assetDetails.type === "Trailer"
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v(
                        "\n                        Vehicle License Plate:\n                    "
                      ),
                    ]),
                    _vm.assetDetails.vehicleLicensePlate
                      ? _c("span", { staticClass: "value" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.assetDetails.vehicleLicensePlate) +
                              "\n                    "
                          ),
                        ])
                      : _c("span", { staticClass: "value" }, [
                          _c("i", [_vm._v("(Not set)")]),
                        ]),
                  ])
                : _vm._e(),
            ]),
            _vm.assetType === "Tracker"
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.assetType === "Tracker"
              ? _c(
                  "div",
                  { staticClass: "detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Tracker Details"),
                    ]),
                    _vm._l(
                      _vm.trackerExtraFields,
                      function (customField, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "detail-group" },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v(_vm._s(customField.label) + ":"),
                            ]),
                            _vm.customFieldValues !== null &&
                            _vm.customFieldValues[customField.name] !== null
                              ? _c("div", { staticClass: "value" }, [
                                  customField.type.toLowerCase() === "date"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm._f("dateTimeFormat")(
                                                _vm.customFieldValues[
                                                  customField.name
                                                ],
                                                _vm.DATE_TYPES.standardDate
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ])
                                    : customField.name === "trackerReference"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.assetDetails.trackerReference
                                            ) +
                                            "\n                        "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.customFieldValues[
                                              customField.name
                                            ]
                                          )
                                        ),
                                      ]),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _c("div", { staticClass: "line-separator" }),
            _c(
              "div",
              { staticClass: "detail-group" },
              [
                _c("AssetSidebarLocations", {
                  attrs: { "asset-id": _vm.assetId },
                  on: {
                    "location-created": _vm.fetchAllInfo,
                    "location-updated": _vm.fetchAllInfo,
                    "location-deleted": _vm.fetchAllInfo,
                  },
                }),
              ],
              1
            ),
            _vm.assetCustomFieldList.length
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.assetCustomFieldList.length
              ? _c(
                  "div",
                  { staticClass: "detail-section contact-detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Custom fields"),
                    ]),
                    _vm._l(
                      _vm.assetCustomFieldList,
                      function (customField, index) {
                        return customField.assetType === "Any" ||
                          customField.assetType === _vm.assetDetails.type
                          ? _c(
                              "div",
                              { key: index, staticClass: "detail-group" },
                              [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v(_vm._s(customField.label) + ":"),
                                ]),
                                _vm.customFieldValues !== null &&
                                _vm.customFieldValues[customField.name] !== null
                                  ? _c("div", { staticClass: "value" }, [
                                      customField.type.toLowerCase() === "date"
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm._f("dateTimeFormat")(
                                                    _vm.customFieldValues[
                                                      customField.name
                                                    ],
                                                    _vm.DATE_TYPES.standardDate
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.customFieldValues[
                                                  customField.name
                                                ]
                                              )
                                            ),
                                          ]),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _c("div", { staticClass: "line-separator" }),
            _c("div", { staticClass: "detail-section" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v("Upcoming Maintenance"),
              ]),
              _vm.assetDetails.upcomingMaintenanceSchedules.length != 0
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "detail-group" },
                      [
                        _vm._l(
                          _vm.assetDetails.upcomingMaintenanceSchedules.slice(
                            0,
                            5
                          ),
                          function (item, index) {
                            return _c("div", { key: index }, [
                              _c(
                                "div",
                                { staticClass: "maintenance-row" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "custom-badge",
                                      class:
                                        item.status
                                          .replace(/\s/g, "")
                                          .toLowerCase() + "-badge",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.status) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                  _c("span", [
                                    _vm._v(_vm._s(item.maintenanceName)),
                                  ]),
                                  _c("reminder-indicator", {
                                    attrs: {
                                      "maintenance-data": item,
                                      "current-engine-hours":
                                        item.estimatedEngineHourValue,
                                      "next-engine-hour-due":
                                        item.nextDueEngineHours != null
                                          ? parseFloat(item.nextDueEngineHours)
                                          : null,
                                      "next-due-distance":
                                        item.nextDueOdometer != null
                                          ? parseFloat(item.nextDueOdometer)
                                          : null,
                                      "current-distance-reading":
                                        item.estimatedOdometerValue,
                                      "repeat-interval": parseInt(1),
                                      "next-due-date": item.nextDueServiceDate,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          }
                        ),
                        _vm.assetDetails.upcomingMaintenanceSchedules.length > 5
                          ? _c(
                              "div",
                              { staticClass: "text-center view-more-btn" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Asset Details",
                                        params: {
                                          assetId: _vm.assetDetails.assetId,
                                        },
                                      },
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                View more\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ])
                : _c("div", { staticClass: "detail-group" }, [
                    _vm._v("No upcoming maintenance schedule available"),
                  ]),
            ]),
            _c("div", { staticClass: "line-separator" }),
            _c("div", { staticClass: "detail-section" }, [
              _c("span", { staticClass: "title" }, [_vm._v("Recent logs")]),
              _vm.assetDetails.logs.length != 0
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "detail-group" },
                      [
                        _vm._l(
                          _vm.assetDetails.logs.slice(0, 5),
                          function (logItem, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "log-box" },
                              [
                                _c("div", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          logItem.createdDate,
                                          _vm.DATE_TYPES.internationalDate
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n                                -\n                                "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "log-box-title",
                                      on: {
                                        click: function ($event) {
                                          return _vm.showDetails(logItem)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "assetLogTypes." + logItem.logType
                                            )
                                          ) +
                                          " (" +
                                          _vm._s(
                                            _vm.$t(
                                              "assetLogSubTypes." +
                                                logItem.logSubType
                                            )
                                          ) +
                                          ")\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        _vm.assetDetails.logs.length > 5
                          ? _c(
                              "div",
                              { staticClass: "text-center view-more-btn" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Asset Details",
                                        params: {
                                          assetId: _vm.assetDetails.assetId,
                                        },
                                      },
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                View more\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ])
                : _c("div", { staticClass: "detail-group" }, [
                    _vm._v("No recent logs available"),
                  ]),
            ]),
          ])
        : _c("div", [
            _c(
              "div",
              { staticClass: "stopbar-loader" },
              [
                _c("fade-loader", {
                  attrs: { loading: _vm.loading, color: "#333333" },
                }),
                _c("span", [_vm._v("LOADING")]),
              ],
              1
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }