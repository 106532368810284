<template>
    <div class="modal-container" @keydown.esc="$modal.hide" tabindex="0">
        <div class="modal-header">
            <h4 class="modal-title">{{ isUpdate ? 'Update Service Package' : 'Create Service Package' }}</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>

        <div class="modal-body">
            <form-wrapper :validator="$v.rateGroupDetails" class="form-wrapper">
                <form novalidate @submit.prevent="validateService">
                    <div class="form-container rate-group-form-container">
                        <div class="rate-group-details">
                            <form-group
                                name="rateGroupName"
                                label="Name"
                                attribute="Rate group name"
                                :messages="localMessages"
                            >
                                <md-input v-focus v-model="rateGroupDetails.rateGroupName" required />
                            </form-group>

                            <form-group name="ruleType" label="Type">
                                <md-select v-model="rateGroupDetails.ruleType">
                                    <md-option
                                        v-for="(item, index) in chargingTypeList"
                                        :value="item.type"
                                        :key="index"
                                    >
                                        {{ item.key }}
                                    </md-option>
                                </md-select>
                            </form-group>
                        </div>
                    </div>
                </form>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validateService">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { isEmptyOrSpaces, handleRequests, showErrorMessage } from '@/helpers';
/* import { CurrenciesAutocomplete } from '@/components'; */
import { required, maxLength } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'CreateServiceModal',
    /* components: { CurrenciesAutocomplete }, */
    mixins: [GeneralMixin],
    props: {
        rateGroupDetails: {
            type: Object,
            default: () => {}
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        chargingList: {
            type: Array,
            default: () => []
        }
    },
    validations: {
        rateGroupDetails: {
            rateGroupName: { required, maxLength: maxLength(200) },
            ruleType: { maxLength: maxLength(100) }
        }
    },
    data() {
        return {
            localMessages: {
                required: '{attribute} is required.'
            },
            chargingTypeList: this.chargingList,
            selectedOption: ''
        };
    },
    mounted() {},
    methods: {
        async create() {
            try {
                this.$_handleLoaderState(true, 'PROCESSING...');
                const api = `/api/rate-groups`;

                const payload = {
                    method: 'post',
                    data: this.rateGroupDetails
                };

                const response = await handleRequests(api, payload);
                if (response.status === 201) {
                    this.$notify({
                        message: 'Successfully created rate group.',
                        type: 'success'
                    });

                    this.$v.$reset();
                    this.resolve('ok');
                }
            } catch (error) {
                const message = 'Error in creating the rate group.';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        async updateRateGroupDetails() {
            try {
                this.$_handleLoaderState(true, 'UPDATING...');

                const api = `/api/rate-groups/${this.rateGroupDetails.rateGroupId}`;
                const payload = {
                    method: 'put',
                    data: this.rateGroupDetails
                };

                await handleRequests(api, payload);

                this.$notify({
                    message: 'Successfully updated rate group.',
                    type: 'success'
                });

                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Error in updating the rate group.';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        validateService() {
            this.$v.rateGroupDetails.$touch();

            if (!this.$v.rateGroupDetails.$invalid) {
                if (!isEmptyOrSpaces(this.rateGroupDetails.rateGroupName)) {
                    this.rateGroupDetails.rateGroupName = this.rateGroupDetails.rateGroupName.trim();
                }

                if (this.isUpdate) {
                    this.updateRateGroupDetails();
                } else {
                    this.create();
                }

                return;
            }

            this.$notify({
                message: 'There are errors in the form. Please correct them to continue.',
                type: 'danger'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-container {
    max-width: 600px;
}
/*.form-container {
    height: 500px;
}*/
</style>
