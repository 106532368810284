<template>
    <vue-select
        v-if="quotingStatusOptions"
        :reduce="(x) => x.value"
        label="text"
        :options="quotingStatusOptions"
        placeholder="Quoting Status"
        v-model="selectedQuotingStatus"
    ></vue-select>
</template>

<script>
import { OFFER_QUOTING_STATUSES } from '@/utils/constants';

export default {
    name: 'QuotingStatusFilter',
    data() {
        return {
            quotingStatusOptions: OFFER_QUOTING_STATUSES || [],
            selectedQuotingStatus: null
        };
    },
    watch: {
        selectedQuotingStatus(newVal, oldVal) {
            this.$emit('changed', newVal);
        }
    }
};
</script>
