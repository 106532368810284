var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.maintenanceScheduleDetails != null
    ? _c("div", [
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c(
              "form-group",
              { attrs: { name: "maintenanceName", label: "Name" } },
              [
                _c(
                  "div",
                  [
                    _c("md-input", {
                      directives: [{ name: "focus", rawName: "v-focus" }],
                      model: {
                        value: _vm.maintenanceScheduleDetails.maintenanceName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.maintenanceScheduleDetails,
                            "maintenanceName",
                            $$v
                          )
                        },
                        expression:
                          "maintenanceScheduleDetails.maintenanceName",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c(
              "form-group",
              { attrs: { label: "Type", name: "maintenanceType" } },
              [
                _c(
                  "md-select",
                  {
                    on: {
                      "md-selected": function ($event) {
                        return _vm.handleLogType(
                          _vm.maintenanceScheduleDetails.maintenanceType
                        )
                      },
                    },
                    model: {
                      value: _vm.maintenanceScheduleDetails.maintenanceType,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.maintenanceScheduleDetails,
                          "maintenanceType",
                          $$v
                        )
                      },
                      expression: "maintenanceScheduleDetails.maintenanceType",
                    },
                  },
                  _vm._l(_vm.assetLogTypes, function (x) {
                    return _c(
                      "md-option",
                      { key: x.key, attrs: { value: x.key } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("assetLogTypes." + x.key)) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "form-group",
              { attrs: { label: "Subtype", name: "maintenanceSubType" } },
              [
                _c(
                  "md-select",
                  {
                    attrs: {
                      disabled:
                        _vm.maintenanceScheduleDetails.maintenanceType == null,
                    },
                    model: {
                      value: _vm.maintenanceScheduleDetails.maintenanceSubType,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.maintenanceScheduleDetails,
                          "maintenanceSubType",
                          $$v
                        )
                      },
                      expression:
                        "maintenanceScheduleDetails.maintenanceSubType",
                    },
                  },
                  _vm._l(_vm.subLogTypes, function (x) {
                    return _c(
                      "md-option",
                      { key: x.key, attrs: { value: x.key } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("assetLogSubTypes." + x.key)) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c(
              "form-group",
              { attrs: { name: "notes", label: "Description" } },
              [
                _c("md-textarea", {
                  model: {
                    value: _vm.maintenanceScheduleDetails.notes,
                    callback: function ($$v) {
                      _vm.$set(_vm.maintenanceScheduleDetails, "notes", $$v)
                    },
                    expression: "maintenanceScheduleDetails.notes",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }