<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Photos</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body stop-form-modal">
            <div>
                <a :href="selectedPhoto.photoUrl" target="_blank">
                    <img :src="selectedPhoto.photoUrl" alt="" />
                </a>
            </div>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Close</md-button>
        </div>
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'PhotoPreviewModal',
    mixins: [GeneralMixin],
    props: {
        photos: {
            type: Array,
            default: () => []
        },
        selectedPhoto: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 700px;
}
.stop-form-modal {
    ::v-deep img {
        max-height: 80vh;
    }
}
</style>
