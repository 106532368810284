<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ isUpdate ? 'Update' : 'Create' }} Service</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <div class="alert alert-info alert-z">
                <span>You are providing pricing for the '{{ teamRegionName }}' team region only.</span>
            </div>
            <!--<div class="title">You are providing pricing for the '{{ teamRegionName }}' team region only.</div>-->

            <form-wrapper :validator="$v.serviceDetails" class="form-wrapper">
                <div class="form-container service-form-container">
                    <div class="service-details">
                        <form-group
                            name="name"
                            label="Description"
                            attribute="Service Description"
                            :messages="localMessages"
                        >
                            <md-input v-focus v-model="serviceDetails.name" required />
                        </form-group>

                        <form-group
                            name="quantityType"
                            label="Quantity Type"
                            attribute="Quantity Type"
                            :messages="localMessages"
                        >
                            <md-input v-model="serviceDetails.quantityType" />
                        </form-group>

                        <div class="md-layout rate-row">
                            <span class="md-layout-item rate-group">Default</span>

                            <form-group
                                name="unitPriceExTax"
                                label="Unit Price (Ex. Tax)"
                                attribute="Unit Price"
                                :messages="localMessages"
                                class="md-layout-item price"
                            >
                                <md-input v-model="serviceDetails.unitPriceExTax" />
                            </form-group>

                            <!--<currencies-autocomplete
                        label="Unit Currency"
                        placeholder=""
                        v-model="serviceDetails.priceCurrency"
                        :should-focus="false"
                    />-->

                            <form-group
                                name="unitCostExTax"
                                label="Unit Cost (Ex. Tax)"
                                attribute="Unit Cost"
                                :messages="localMessages"
                                class="md-layout-item cost"
                            >
                                <md-input v-model="serviceDetails.unitCostExTax" />
                            </form-group>

                            <!--<currencies-autocomplete
                        label="Cost Currency"
                        placeholder=""
                        v-model="serviceDetails.costCurrency"
                        :should-focus="false"
                    />-->
                        </div>

                        <div
                            class="md-layout rate-row"
                            v-for="(rate, index) in serviceDetails.serviceRates"
                            :key="index"
                        >
                            <span class="md-layout-item rate-group">{{ rate.rateGroupName }}</span>

                            <form-group
                                name="serviceDetails.serviceRates.$each.0.unitPriceExTax.$model"
                                label="Unit Price (Ex. Tax)"
                                attribute="Unit Price"
                                :messages="localMessages"
                                class="md-layout-item price"
                            >
                                <md-input v-model="rate.unitPriceExTax" />
                            </form-group>
                            <form-group
                                name="serviceDetails.serviceRates.$each.0.unitCostExTax.$model"
                                label="Unit Cost (Ex. Tax)"
                                attribute="Unit Cost"
                                :messages="localMessages"
                                class="md-layout-item cost"
                            >
                                <md-input v-model="rate.unitCostExTax" />
                            </form-group>
                        </div>
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validateService">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmptyOrSpaces, handleRequests, showErrorMessage, getTeamRegionName } from '@/helpers';
/* import { CurrenciesAutocomplete } from '@/components'; */
import { required, maxLength, decimal } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'CreateServiceModal',
    components: {
        /* , CurrenciesAutocomplete */
    },
    mixins: [GeneralMixin],
    props: {
        serviceDetails: {
            type: Object,
            default: () => ({
                serviceRates: [
                    {
                        unitPriceExTax: 0,
                        unitCostExTax: 0
                    }
                ]
            })
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        teamRegionId: {
            type: Number,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    validations: {
        serviceDetails: {
            name: { required, maxLength: maxLength(200) },
            unitPriceExTax: {
                decimal,
                minValue: (value) => !value || value >= 0,
                maxValue: (value) => !value || value < 100000000
            },
            priceCurrency: {},
            unitCostExTax: {
                decimal,
                minValue: (value) => !value || value >= 0,
                maxValue: (value) => !value || value < 100000000
            },
            costCurrency: {},
            quantityType: { maxLength: maxLength(10) },
            serviceRates: {
                $each: {
                    unitPriceExTax: {
                        decimal,
                        minValue: (value) => !value || value >= 0,
                        maxValue: (value) => !value || value < 100000000
                    },
                    unitCostExTax: {
                        decimal,
                        minValue: (value) => !value || value >= 0,
                        maxValue: (value) => !value || value < 100000000
                    }
                }
            }
        }
    },
    data() {
        return {
            localMessages: {
                required: '{attribute} is required.',
                decimal: '{attribute} should contain a decimal number.',
                minValue: '{attribute} should have a positive value.',
                maxValue: '{attribute} should be less than 100000000.'
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            canViewTeamRegion: 'user/canViewTeamRegion',
            teamRegions: 'team/teamRegions'
        }),
        teamRegionName() {
            return getTeamRegionName(this.teamRegionId, this.teamRegions) || 'All';
        }
    },
    mounted() {},
    watch: {},
    methods: {
        async create() {
            try {
                this.$_handleLoaderState(true, 'PROCESSING...');
                const api = `/api/services?teamRegionId=${this.teamRegionId}`;

                const payload = {
                    method: 'post',
                    data: this.serviceDetails
                };

                const response = await handleRequests(api, payload);
                if (response.status === 201) {
                    this.$notify({
                        message: 'Successfully created service.',
                        type: 'success'
                    });

                    this.$v.$reset();
                    this.resolve('ok');
                }
            } catch (error) {
                const message = 'Error in creating the service';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        async updateServiceDetails() {
            try {
                this.$_handleLoaderState(true, 'UPDATING...');

                const api = `/api/services/${this.serviceDetails.serviceId}?teamRegionId=${this.teamRegionId}`;
                const payload = {
                    method: 'put',
                    data: this.serviceDetails
                };

                await handleRequests(api, payload);

                this.$notify({
                    message: 'Successfully updated service.',
                    type: 'success'
                });

                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Error in updating the service.';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        fixFloats(obj) {
            obj.unitPriceExTax = parseFloat(obj.unitPriceExTax);
            if (Number.isNaN(obj.unitPriceExTax)) 
                obj.unitPriceExTax = null;
            obj.unitCostExTax = parseFloat(obj.unitCostExTax);
            if (Number.isNaN(obj.unitCostExTax)) 
                obj.unitCostExTax = null;
        },
        validateService() {
            this.$v.serviceDetails.$touch();

            if (!this.$v.serviceDetails.$invalid) {
                if (!isEmptyOrSpaces(this.serviceDetails.name)) {
                    this.serviceDetails.name = this.serviceDetails.name.trim();
                }

                this.fixFloats(this.serviceDetails);

                this.serviceDetails.serviceRates.forEach((rate) => {
                    this.fixFloats(rate);
                });

                if (this.isUpdate) {
                    this.updateServiceDetails();
                } else {
                    this.create();
                }

                return;
            }

            this.$notify({
                message: 'There are errors in the form. Please correct them to continue.',
                type: 'danger'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-body {
    padding-top: 12px;
}
.modal-container {
    max-width: 600px;
}
.rate-row {
    margin-bottom: 12px;
}
.rate-group {
    font-size: 0.9em;
    margin: auto;
    /*text-align: right;*/
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 8px;
}
.price {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 4px;
    margin-right: 4px;
}
.cost {
    margin-left: 8px;
    padding-left: 0px;
    padding-right: 0px;
}
.spacer {
    width: 60%;
    margin: 12px auto 12px auto;
}
.title {
    margin-bottom: 12px;
    text-align: center;
}

/*.form-container {
    height: 500px;
}*/
</style>
