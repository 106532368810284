<template>
    <div class="details-page" v-if="invoiceDetails != null">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <h3 class="title">
                            {{ invoiceDetails.invoiceNumber }}
                        </h3>
                    </div>
                    <div class="custom-toolbar-end" v-if="!$root.isMobileOnly">
                        <div class="header-button-container">
                            <payment-status-button
                                :customer-invoice-id="invoiceDetails.customerInvoiceId"
                                :invoice-details="invoiceDetails"
                                @statusUpdated="statusUpdated"
                                class-name="md-sm"
                                class="status-btn"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout md-alignment-top-left content-layout-panel">
            <div
                class="md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-100 md-xlarge-size-60"
            >
                <div class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-blue">
                            <div class="card-icon">
                                <md-icon>description</md-icon>
                            </div>
                        </md-card-header>

                        <md-card-content>
                            <div>
                                <div class="details-box">
                                    <label>PO Number</label>
                                    <span>
                                        {{ invoiceDetails.purchaseOrderNumber }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label>Customer</label>
                                    <span>
                                        {{ invoiceDetails.customer }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label>Invoice Date</label>
                                    <span>
                                        {{ invoiceDetails.invoiceDate | dateFormat(DATE_TYPES.standardDate) }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label>Due Date</label>
                                    <span>
                                        {{ invoiceDetails.dueDate | dateFormat(DATE_TYPES.standardDate) }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label>Status</label>
                                    <span>
                                        {{ $t(`inputs.paymentStatuses.${invoiceDetails.status}`) }}
                                    </span>
                                </div>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>

                <div class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100">
                    <invoice-lines :lines="invoiceDetails.invoiceLines" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { InvoiceLines } from './components';
import { PaymentStatusButton } from './buttons';

export default {
    name: 'CustomerInvoicesDetails',
    components: {
        InvoiceLines,
        PaymentStatusButton
    },
    mixins: [GeneralMixin],
    async mounted() {
        this.$_handleLoaderState(true);

        await this.getCustomerInvoiceDetails();
        this.$_handleLoaderState(false);
    },
    data() {
        return {
            invoiceDetails: null
        };
    },
    methods: {
        async statusUpdated(status) {
            await this.getCustomerInvoiceDetails();
        },
        async getCustomerInvoiceDetails() {
            const api = `/api/customer-invoices/${this.$route.params.invoiceId}`;

            const { data } = await handleRequests(api);

            this.invoiceDetails = data;
        }
    }
};
</script>

<style lang="scss" scoped>
.details-page {
    padding-top: 40px;

    ::v-deep .md-table-row:hover {
        background-color: #fafafa;
        cursor: pointer;
    }

    ::v-deep .badge {
        display: inline-block;
        border-radius: 12px;
        padding: 5px 12px;
        text-transform: uppercase;
        font-size: 10px;
        color: #fff;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;

        position: initial;
        width: initial;
        height: initial;
        top: initial;
        right: initial;
        margin-top: initial;
        margin-right: initial;
        max-width: 60%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    ::v-deep .md-field.md-disabled:after {
        border-bottom: none;
    }

    ::v-deep .md-field:after {
        height: 0;
    }

    ::v-deep .md-field:before {
        background-color: initial !important;
    }
}

::v-deep .md-card-header-icon {
    z-index: 9;
}

.header-button-container {
    > button {
        margin-right: 0;
    }
    .status-btn {
        display: inline-block;
        text-transform: uppercase;
        font-size: 12px;
        > ::v-deep span {
            margin: 6px 8px;
            height: 36px;
            line-height: 36px;
            border-radius: 2px;
            margin-right: 0px;
        }

        > ::v-deep .dropdown-menu {
            margin-left: 9px;
            margin-top: 0;
        }
    }
}

.custom-toolbar {
    .title {
        margin-bottom: 0;
    }
}

.content-layout-panel {
    > div {
        padding: 0;
    }
}

@media (min-width: 768px) {
    .details-page {
        padding-top: 0;
    }
}
</style>
