var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "md-layout stop-list",
      class: _vm.isLoading ? "loader-margin" : "",
    },
    [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "loader" },
            [
              _c("fade-loader", {
                attrs: { loading: _vm.isLoading, color: "#333333" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "md-layout-item md-size-20" }, [
        _c("ul", { staticClass: "stop-list-ui" }, [
          _c(
            "li",
            { staticClass: "stop-list-li-select-all" },
            [
              _c(
                "md-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.onSelectAll(_vm.isSelectAll)
                    },
                  },
                  model: {
                    value: _vm.isSelectAll,
                    callback: function ($$v) {
                      _vm.isSelectAll = $$v
                    },
                    expression: "isSelectAll",
                  },
                },
                [_vm._v("Select All")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "md-layout md-layout-item md-size-40 drawing-tool" },
        [
          _c(
            "div",
            { staticClass: "gmnoprint" },
            [
              _c("DrawingButton", {
                attrs: {
                  title: "Stop drawing",
                  "aria-label": "Stop drawing",
                  "custom-class": [
                    "drawing-button",
                    _vm.drawingType === null ? "button-pressed" : "",
                  ],
                  "pressed-state": true,
                },
                on: { selectDrawing: _vm.selectDrawing },
              }),
              _c("DrawingButton", {
                attrs: {
                  title: "Draw a circle",
                  "aria-label": "Draw a circle",
                  "custom-class": [
                    "circle-button",
                    _vm.drawingType === "circle" ? "button-pressed" : "",
                  ],
                  type: "circle",
                },
                on: { selectDrawing: _vm.selectDrawing },
              }),
              _c("DrawingButton", {
                attrs: {
                  title: "Draw a shape",
                  "aria-label": "Draw a shape",
                  "custom-class": [
                    "polygon-button",
                    _vm.drawingType === "polygon" ? "button-pressed" : "",
                  ],
                  type: "polygon",
                },
                on: { selectDrawing: _vm.selectDrawing },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "md-layout md-layout-item md-size-40 filter-link" },
        [
          _c(
            "div",
            {
              class: [
                "md-layout-item filter-menu",
                _vm.startLocationMode === "manual"
                  ? "md-size-65"
                  : "md-size-90",
              ],
            },
            [
              _c("drop-down", { attrs: { direction: "down" } }, [
                !_vm.isFiltered
                  ? _c(
                      "a",
                      { attrs: { href: "#" } },
                      [
                        _c("md-icon", { staticClass: "icon-filter" }, [
                          _vm._v("filter_alt"),
                        ]),
                        _vm._v(
                          "\n                    Filter\n                "
                        ),
                      ],
                      1
                    )
                  : _c("a", { attrs: { href: "#" } }, [
                      _vm._v(_vm._s(_vm.filterText)),
                    ]),
                _c("ul", { staticClass: "dropdown-menu" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: { click: _vm.openFilterByTimeWindow },
                      },
                      [_vm._v("By Time Window")]
                    ),
                  ]),
                  _vm.skillOptions.length != 0
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.openFilterStopsBySkills },
                          },
                          [_vm._v("By Skills")]
                        ),
                      ])
                    : _vm._e(),
                  _c("li", { staticClass: "dropdown-child-menu" }, [
                    _c(
                      "a",
                      { attrs: { href: "#" } },
                      [
                        _vm._v(
                          "\n                            By Selection Status\n                            "
                        ),
                        _c(
                          "md-icon",
                          {
                            staticClass: "icon-arrow",
                            staticStyle: { "margin-left": "3px" },
                          },
                          [_vm._v("navigate_next")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "ul",
                      _vm._l(_vm.stopMenuOptions, function (stopMenu, index) {
                        return _c(
                          "li",
                          { key: index },
                          [
                            _c(
                              "md-icon",
                              {
                                class: [
                                  "icon-check",
                                  stopMenu.checked ? "icon-check-color" : "",
                                ],
                              },
                              [
                                _vm._v(
                                  "\n                                    check\n                                "
                                ),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleFilterStopsClick(stopMenu)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(stopMenu.name) +
                                    "\n                                "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("li", { staticClass: "dropdown-child-menu" }, [
                    _c(
                      "a",
                      { attrs: { href: "#" } },
                      [
                        _vm._v(
                          "\n                            By Validation Status\n                            "
                        ),
                        _c("md-icon", { staticClass: "icon-arrow" }, [
                          _vm._v("navigate_next"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "ul",
                      _vm._l(
                        _vm.validationMenuOptions,
                        function (validationMenu, index) {
                          return _c(
                            "li",
                            { key: index },
                            [
                              _c(
                                "md-icon",
                                {
                                  class: [
                                    "icon-check",
                                    validationMenu.checked
                                      ? "icon-check-color"
                                      : "",
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n                                    check\n                                "
                                  ),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleFilterValidationClick(
                                        validationMenu
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(validationMenu.name) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                  _vm.showTeamRegion
                    ? _c("li", { staticClass: "dropdown-child-menu" }, [
                        _c(
                          "a",
                          { attrs: { href: "#" } },
                          [
                            _vm._v(
                              "\n                            By Team Region\n                            "
                            ),
                            _c("md-icon", { staticClass: "icon-arrow" }, [
                              _vm._v("navigate_next"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "ul",
                          _vm._l(
                            _vm.teamRegionMenuOptions,
                            function (teamRegionMenu, index) {
                              return _c(
                                "li",
                                { key: index },
                                [
                                  _c(
                                    "md-icon",
                                    {
                                      class: [
                                        "icon-check",
                                        teamRegionMenu.checked
                                          ? "icon-check-color"
                                          : "",
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    check\n                                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleFilterTeamRegionClick(
                                            teamRegionMenu
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.getTeamRegionMenuText(
                                              teamRegionMenu
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _c("li", { staticClass: "dropdown-child-menu" }, [
                    _c(
                      "a",
                      { attrs: { href: "#" } },
                      [
                        _vm._v(
                          "\n                            By Service Area\n                            "
                        ),
                        _c("md-icon", { staticClass: "icon-arrow" }, [
                          _vm._v("navigate_next"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "ul",
                      _vm._l(
                        _vm.serviceAreaList,
                        function (serviceArea, index) {
                          return _c(
                            "li",
                            { key: index },
                            [
                              _c(
                                "md-icon",
                                {
                                  class: [
                                    "icon-check",
                                    serviceArea.checked
                                      ? "icon-check-color"
                                      : "",
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n                                    check\n                                "
                                  ),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleFilterServiceAreaClick(
                                        serviceArea
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(serviceArea.name) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                  _vm.isFiltered
                    ? _c("li", { staticClass: "clear-filter" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.clearFilters },
                          },
                          [_vm._v("Clear Filters")]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
          _vm.startLocationMode === "manual"
            ? _c(
                "div",
                { staticClass: "md-layout-item md-size-35" },
                [
                  _c("drop-down", { attrs: { direction: "down" } }, [
                    _c(
                      "a",
                      { attrs: { href: "#" } },
                      [
                        _c("md-icon", { staticClass: "icon-filter" }, [
                          _vm._v("settings"),
                        ]),
                        _vm._v(
                          "\n                    Options\n                "
                        ),
                      ],
                      1
                    ),
                    _c("ul", { staticClass: "dropdown-menu" }, [
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.openPickupLocationDistanceModal },
                          },
                          [_vm._v("Valid Radius")]
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-100" },
        [
          _c(
            "md-table",
            {
              directives: [{ name: "scrollTop", rawName: "v-scrollTop" }],
              staticClass: "stop-table",
            },
            [
              _c(
                "md-table-row",
                [
                  _c("md-table-head"),
                  _c("md-table-head", [_vm._v("Stop Reference")]),
                  _c("md-table-head", [_vm._v("Address")]),
                  _vm.hasCapacitySettings("quantity")
                    ? _c(
                        "md-table-head",
                        [
                          _c("md-icon", [
                            _c("img", {
                              staticClass: "capacity-images",
                              attrs: {
                                src: "/img/icons/capacity-items-gray.png",
                              },
                            }),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasCapacitySettings("volume")
                    ? _c(
                        "md-table-head",
                        [
                          _c("md-icon", [
                            _c("img", {
                              staticClass: "capacity-images",
                              attrs: {
                                src: "/img/icons/capacity-volume-gray.png",
                              },
                            }),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasCapacitySettings("weight")
                    ? _c(
                        "md-table-head",
                        [
                          _c("md-icon", [
                            _c("img", {
                              staticClass: "capacity-images",
                              attrs: {
                                src: "/img/icons/capacity-weight-gray.png",
                              },
                            }),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._l(_vm.unassignedStopList, function (item) {
                return _c(
                  "md-table-row",
                  {
                    key: item.shipId || item.shipmentId,
                    class: item.isOutside ? "stop-outside" : "",
                    on: {
                      mouseover: function ($event) {
                        return _vm.onStopMouseover(item.stopId, item.shipmentId)
                      },
                      mouseout: function ($event) {
                        return _vm.onStopMouseout(item.stopId, item.shipmentId)
                      },
                    },
                  },
                  [
                    _c(
                      "md-table-cell",
                      [
                        item.isOutside
                          ? _c(
                              "div",
                              [
                                _c("md-icon", { staticClass: "icon-warning" }, [
                                  _vm._v("warning"),
                                ]),
                                _c(
                                  "md-tooltip",
                                  {
                                    staticClass: "tooltip-width",
                                    attrs: { "md-direction": "right" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            This stop is " +
                                        _vm._s(item.distance) +
                                        " km from the start location\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("md-checkbox", {
                              attrs: {
                                type: "checkbox",
                                value: item.stopId || item.shipmentId,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onSelectAddress(
                                    item.stopId,
                                    item.shipmentId
                                  )
                                },
                              },
                              model: {
                                value: _vm.selectedAddress,
                                callback: function ($$v) {
                                  _vm.selectedAddress = $$v
                                },
                                expression: "selectedAddress",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "md-table-cell",
                      { staticClass: "stop-reference" },
                      [
                        item.stopId && item.shipmentId == null
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Stop Details",
                                    params: { stopId: item.stopId },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      item.sourceReference &&
                                        item.sourceReference.trim().length !== 0
                                        ? item.sourceReference
                                        : item.stopRef
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        item.shipmentId
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Shipment Details",
                                    params: { shipmentId: item.shipmentId },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      item.sourceReference &&
                                        item.sourceReference.trim().length !== 0
                                        ? item.sourceReference
                                        : item.shipmentRef
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "md-table-cell",
                      {
                        class: [
                          "address-container",
                          _vm.getAddressContainerCapacityClass(),
                        ],
                      },
                      [
                        item.isOutside && item.stopId
                          ? _c(
                              "div",
                              {
                                staticClass: "stop-address",
                                on: {
                                  click: function ($event) {
                                    return _vm.onAddressClick(item)
                                  },
                                },
                              },
                              [
                                _c("google-autocomplete", {
                                  ref: item.stopId,
                                  refInFor: true,
                                  attrs: {
                                    id: item.stopRef,
                                    "stop-id": item.stopId,
                                    classname:
                                      "form-control autocomplete-input",
                                    label: "",
                                    icon: "",
                                    placeholder: "",
                                  },
                                  on: {
                                    placechanged: _vm.onStopLocationChanged,
                                  },
                                  model: {
                                    value: item.locationData,
                                    callback: function ($$v) {
                                      _vm.$set(item, "locationData", $$v)
                                    },
                                    expression: "item.locationData",
                                  },
                                }),
                              ],
                              1
                            )
                          : item.shipmentId
                          ? _c(
                              "div",
                              [
                                _c("md-icon", [_vm._v("local_shipping")]),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.pickupStop.address) +
                                    "\n                        "
                                ),
                                _c("md-icon", [_vm._v("arrow_right_alt")]),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.dropStop.address) +
                                    "\n                    "
                                ),
                              ],
                              1
                            )
                          : _c("div", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.address) +
                                  "\n                    "
                              ),
                            ]),
                      ]
                    ),
                    _vm.hasCapacitySettings("quantity")
                      ? _c(
                          "md-table-cell",
                          { staticClass: "capacity-table-cell" },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.getCapacityInfo(item, "quantity", "items")
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.hasCapacitySettings("volume")
                      ? _c(
                          "md-table-cell",
                          { staticClass: "capacity-table-cell" },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.getCapacityInfo(item, "volume", "m3")
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.hasCapacitySettings("weight")
                      ? _c(
                          "md-table-cell",
                          { staticClass: "capacity-table-cell" },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.getCapacityInfo(item, "weight", "kg")
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm.stopList.length == 0 && !_vm.isLoading
            ? _c("div", { staticClass: "text-center" }, [
                _c("p", { staticClass: "no-result-message" }, [
                  _vm._v(
                    "No results matching your search/filter could be found."
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.stopList.length > 0
            ? _c(
                "md-card-actions",
                { attrs: { "md-alignment": "space-between" } },
                [
                  _c("div", [
                    _vm.total === _vm.pagination.perPage
                      ? _c("p", { staticClass: "card-category" }, [
                          _vm._v(
                            "\n                    Page " +
                              _vm._s(_vm.pagination.currentPage) +
                              " of many\n                "
                          ),
                        ])
                      : _c("p", { staticClass: "card-category" }, [
                          _vm._v(
                            "Page " +
                              _vm._s(_vm.pagination.currentPage) +
                              " of " +
                              _vm._s(_vm.totalPages)
                          ),
                        ]),
                  ]),
                  _c("pagination", {
                    staticClass: "pagination-no-border pagination-success",
                    attrs: {
                      "per-page": _vm.pagination.perPage,
                      total: _vm.total,
                    },
                    on: {
                      "change-page": function ($event) {
                        return _vm.handleChangePage(
                          $event,
                          _vm.pagination.perPage
                        )
                      },
                    },
                    model: {
                      value: _vm.pagination.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.pagination, "currentPage", $$v)
                      },
                      expression: "pagination.currentPage",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }