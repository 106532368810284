var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50",
      },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-text md-card-header-warning" },
              [
                _c("div", { staticClass: "card-text" }, [
                  _c("h4", { staticClass: "title" }, [
                    _vm._v(
                      "\n                        Employees Stats\n                    "
                    ),
                  ]),
                  _c("p", { staticClass: "category" }, [
                    _vm._v(
                      "\n                        New employees on 15th September, 2016\n                    "
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c("md-table", {
                  attrs: { "table-header-color": "orange" },
                  scopedSlots: _vm._u([
                    {
                      key: "md-table-row",
                      fn: function (ref) {
                        var item = ref.item
                        return _c(
                          "md-table-row",
                          {},
                          [
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "ID" } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.id) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Name" } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.name) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Salary" } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.salary) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Country" } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.country) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                  model: {
                    value: _vm.users,
                    callback: function ($$v) {
                      _vm.users = $$v
                    },
                    expression: "users",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50",
      },
      [
        _c(
          "nav-tabs-card",
          [
            _c(
              "template",
              { slot: "content" },
              [
                _c("span", { staticClass: "md-nav-tabs-title" }, [
                  _vm._v("Tasks:"),
                ]),
                _c(
                  "md-tabs",
                  { staticClass: "md-rose", attrs: { "md-alignment": "left" } },
                  [
                    _c(
                      "md-tab",
                      {
                        attrs: {
                          id: "tab-home",
                          "md-label": "Bugs",
                          "md-icon": "bug_report",
                        },
                      },
                      [
                        _c("md-table", {
                          on: { "md-selected": _vm.onSelect },
                          scopedSlots: _vm._u([
                            {
                              key: "md-table-row",
                              fn: function (ref) {
                                var item = ref.item
                                return _c(
                                  "md-table-row",
                                  {
                                    attrs: {
                                      "md-selectable": "multiple",
                                      "md-auto-select": "",
                                    },
                                  },
                                  [
                                    _c("md-table-cell", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.tab) +
                                          "\n                                "
                                      ),
                                    ]),
                                    _c(
                                      "md-table-cell",
                                      [
                                        _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "md-just-icon md-simple md-primary",
                                          },
                                          [
                                            _c("md-icon", [_vm._v("edit")]),
                                            _c(
                                              "md-tooltip",
                                              {
                                                attrs: {
                                                  "md-direction": "top",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Edit\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "md-just-icon md-simple md-danger",
                                          },
                                          [
                                            _c("md-icon", [_vm._v("close")]),
                                            _c(
                                              "md-tooltip",
                                              {
                                                attrs: {
                                                  "md-direction": "top",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Close\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              },
                            },
                          ]),
                          model: {
                            value: _vm.firstTabs,
                            callback: function ($$v) {
                              _vm.firstTabs = $$v
                            },
                            expression: "firstTabs",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "md-tab",
                      {
                        attrs: {
                          id: "tab-pages",
                          "md-label": "Website",
                          "md-icon": "code",
                        },
                      },
                      [
                        _c("md-table", {
                          on: { "md-selected": _vm.onSelect },
                          scopedSlots: _vm._u([
                            {
                              key: "md-table-row",
                              fn: function (ref) {
                                var item = ref.item
                                return _c(
                                  "md-table-row",
                                  {
                                    attrs: {
                                      "md-selectable": "multiple",
                                      "md-auto-select": "",
                                    },
                                  },
                                  [
                                    _c("md-table-cell", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.tab) +
                                          "\n                                "
                                      ),
                                    ]),
                                    _c(
                                      "md-table-cell",
                                      [
                                        _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "md-just-icon md-simple md-primary",
                                          },
                                          [
                                            _c("md-icon", [_vm._v("edit")]),
                                            _c(
                                              "md-tooltip",
                                              {
                                                attrs: {
                                                  "md-direction": "top",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Edit\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "md-just-icon md-simple md-danger",
                                          },
                                          [
                                            _c("md-icon", [_vm._v("close")]),
                                            _c(
                                              "md-tooltip",
                                              {
                                                attrs: {
                                                  "md-direction": "top",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Close\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              },
                            },
                          ]),
                          model: {
                            value: _vm.firstTabs,
                            callback: function ($$v) {
                              _vm.firstTabs = $$v
                            },
                            expression: "firstTabs",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "md-tab",
                      {
                        attrs: {
                          id: "tab-posts",
                          "md-label": "server",
                          "md-icon": "cloud",
                        },
                      },
                      [
                        _c("md-table", {
                          on: { "md-selected": _vm.onSelect },
                          scopedSlots: _vm._u([
                            {
                              key: "md-table-row",
                              fn: function (ref) {
                                var item = ref.item
                                return _c(
                                  "md-table-row",
                                  {
                                    attrs: {
                                      "md-selectable": "multiple",
                                      "md-auto-select": "",
                                    },
                                  },
                                  [
                                    _c("md-table-cell", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.tab) +
                                          "\n                                "
                                      ),
                                    ]),
                                    _c(
                                      "md-table-cell",
                                      [
                                        _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "md-just-icon md-simple md-primary",
                                          },
                                          [
                                            _c("md-icon", [_vm._v("edit")]),
                                            _c(
                                              "md-tooltip",
                                              {
                                                attrs: {
                                                  "md-direction": "top",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Edit\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "md-just-icon md-simple md-danger",
                                          },
                                          [
                                            _c("md-icon", [_vm._v("close")]),
                                            _c(
                                              "md-tooltip",
                                              {
                                                attrs: {
                                                  "md-direction": "top",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Close\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              },
                            },
                          ]),
                          model: {
                            value: _vm.firstTabs,
                            callback: function ($$v) {
                              _vm.firstTabs = $$v
                            },
                            expression: "firstTabs",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50",
      },
      [
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            { staticClass: "md-layout-item" },
            [
              _c(
                "pricing-card",
                { attrs: { "icon-color": "icon-success" } },
                [
                  _c(
                    "h6",
                    {
                      staticClass: "category",
                      attrs: { slot: "category" },
                      slot: "category",
                    },
                    [
                      _vm._v(
                        "\n                        Small Company\n                    "
                      ),
                    ]
                  ),
                  _c("md-icon", { attrs: { slot: "icon" }, slot: "icon" }, [
                    _vm._v(
                      "\n                        home\n                    "
                    ),
                  ]),
                  _c(
                    "h3",
                    {
                      staticClass: "title",
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [
                      _vm._v(
                        "\n                        29$\n                    "
                      ),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "card-description",
                      attrs: { slot: "description" },
                      slot: "description",
                    },
                    [
                      _vm._v(
                        "\n                        This is good if your company size is between 2 and 10 Persons.\n                    "
                      ),
                    ]
                  ),
                  _c(
                    "md-button",
                    {
                      staticClass: "md-success md-round",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _vm._v(
                        "\n                        Choose Plan\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item" },
            [
              _c(
                "pricing-card",
                {
                  attrs: {
                    "card-class": "md-card-plain",
                    "icon-color": "icon-black",
                  },
                },
                [
                  _c(
                    "h6",
                    {
                      staticClass: "category",
                      attrs: { slot: "category" },
                      slot: "category",
                    },
                    [
                      _vm._v(
                        "\n                        Freelancer\n                    "
                      ),
                    ]
                  ),
                  _c("md-icon", { attrs: { slot: "icon" }, slot: "icon" }, [
                    _vm._v(
                      "\n                        weekend\n                    "
                    ),
                  ]),
                  _c(
                    "h3",
                    {
                      staticClass: "title black-color",
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [
                      _vm._v(
                        "\n                        FREE\n                    "
                      ),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "card-description",
                      attrs: { slot: "description" },
                      slot: "description",
                    },
                    [
                      _vm._v(
                        "\n                        This is good if your company size is between 2 and 10 Persons.\n                    "
                      ),
                    ]
                  ),
                  _c(
                    "md-button",
                    {
                      staticClass: "md-white md-round",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _vm._v(
                        "\n                        Choose Plan\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-size-90" },
            [
              _c(
                "testimonial-card",
                [
                  _c("template", { slot: "header-icon" }, [
                    _vm._v(
                      "\n                        format_quote\n                    "
                    ),
                  ]),
                  _c("template", { slot: "content" }, [
                    _c("h5", { staticClass: "description" }, [
                      _vm._v(
                        "\n                            Your products, all the kits that I have downloaded from your site and worked with are\n                            sooo cool! I love the color mixtures, cards... everything. Keep up the great work!\n                        "
                      ),
                    ]),
                  ]),
                  _c("template", { slot: "footer" }, [
                    _c("h4", { staticClass: "title" }, [
                      _vm._v(
                        "\n                            Alec Thompson\n                        "
                      ),
                    ]),
                    _c("h6", { staticClass: "category" }, [
                      _vm._v(
                        "\n                            @alecthompson\n                        "
                      ),
                    ]),
                    _c("div", { staticClass: "avatar" }, [
                      _c("a", { attrs: { href: "#pablo" } }, [
                        _c("img", {
                          staticClass: "img",
                          attrs: { src: _vm.profileCard },
                        }),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50",
      },
      [
        _c(
          "time-line",
          { attrs: { plain: "", type: "simple" } },
          [
            _c(
              "time-line-item",
              {
                attrs: {
                  inverted: "",
                  "badge-type": "danger",
                  "badge-icon": "card_travel",
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "badge badge-danger",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_vm._v("\n                    Some title\n                ")]
                ),
                _c("p", { attrs: { slot: "content" }, slot: "content" }, [
                  _vm._v(
                    "\n                    Wifey made the best Father's Day meal ever. So thankful so happy so blessed. Thank you for\n                    making my family We just had fun with the “future” theme !!! It was a fun night all together ...\n                    The always rude Kanye Show at 2am Sold Out Famous viewing @ Figueroa and 12th in downtown.\n                "
                  ),
                ]),
                _c("h6", { attrs: { slot: "footer" }, slot: "footer" }, [
                  _c("i", { staticClass: "ti-time" }),
                  _vm._v(
                    "\n                    11 hours ago via Twitter\n                "
                  ),
                ]),
              ]
            ),
            _c(
              "time-line-item",
              {
                attrs: {
                  inverted: "",
                  "badge-type": "success",
                  "badge-icon": "extension",
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "badge badge-success",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _vm._v(
                      "\n                    Another Title\n                "
                    ),
                  ]
                ),
                _c("p", { attrs: { slot: "content" }, slot: "content" }, [
                  _vm._v(
                    "\n                    Thank God for the support of my wife and real friends. I also wanted to point out that it’s the\n                    first album to go number 1 off of streaming!!! I love you Ellen and also my number one design\n                    rule of anything I do from shoes to music to homes is that Kim has to like it....\n                "
                  ),
                ]),
              ]
            ),
            _c(
              "time-line-item",
              {
                attrs: {
                  inverted: "",
                  "badge-type": "info",
                  "badge-icon": "fingerprint",
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "badge badge-info",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _vm._v(
                      "\n                    Another Title\n                "
                    ),
                  ]
                ),
                _c(
                  "div",
                  { attrs: { slot: "content" }, slot: "content" },
                  [
                    _c("p", [
                      _vm._v(
                        "\n                        Called I Miss the Old Kanye That’s all it was Kanye And I love you like Kanye loves Kanye\n                        Famous viewing @ Figueroa and 12th in downtown LA 11:10PM\n                    "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n                        What if Kanye made a song about Kanye Royère doesn't make a Polar bear bed but the Polar\n                        bear couch is my favorite piece of furniture we own It wasn’t any Kanyes Set on his goals\n                        Kanye\n                    "
                      ),
                    ]),
                    _c("hr"),
                    _c(
                      "drop-down",
                      [
                        _c(
                          "md-button",
                          {
                            staticClass:
                              "md-button md-info md-round dropdown-toggle",
                            attrs: { slot: "title", "data-toggle": "dropdown" },
                            slot: "title",
                          },
                          [_c("md-icon", [_vm._v("build")])],
                          1
                        ),
                        _c(
                          "ul",
                          {
                            staticClass: "dropdown-menu",
                            class: { "dropdown-menu-right": !_vm.responsive },
                          },
                          [
                            _c("li", [
                              _c("a", { attrs: { href: "#" } }, [
                                _vm._v(
                                  "\n                                    Mike John responded to your email\n                                "
                                ),
                              ]),
                            ]),
                            _c("li", [
                              _c("a", { attrs: { href: "#" } }, [
                                _vm._v("You have 5 new tasks"),
                              ]),
                            ]),
                            _c("li", [
                              _c("a", { attrs: { href: "#" } }, [
                                _vm._v(
                                  "\n                                    You're now friend with Andrew\n                                "
                                ),
                              ]),
                            ]),
                            _c("li", [
                              _c("a", { attrs: { href: "#" } }, [
                                _vm._v("Another Notification"),
                              ]),
                            ]),
                            _c("li", [
                              _c("a", { attrs: { href: "#" } }, [
                                _vm._v("Another One"),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }