var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v("Invite members to your team"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          { staticClass: "form-wrapper", attrs: { validator: _vm.$v.form } },
          [
            _c(
              "form-group",
              { attrs: { name: "role", label: "Team Role" } },
              [
                _c(
                  "md-select",
                  {
                    on: { "md-selected": _vm.getSelectedRole },
                    model: {
                      value: _vm.form.role,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "role", $$v)
                      },
                      expression: "form.role",
                    },
                  },
                  _vm._l(_vm.memberRoles, function (memberRole) {
                    return _c(
                      "md-option",
                      { key: memberRole.id, attrs: { value: memberRole.name } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(memberRole.name) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "form-group",
              { attrs: { name: "email", label: "Email Address" } },
              [
                _c("md-input", {
                  model: {
                    value: _vm.form.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "email", $$v)
                    },
                    expression: "form.email",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.validateEmail },
          },
          [_vm._v("\n            Send Invitation\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }