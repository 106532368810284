export const MarkerPins = [
    {
        // driver: '/img/map/driver.png',
        stop: '/img/map/A44BC5/stop.png',
        timeSeries: '/img/map/A44BC5/time-series.png',
        color: '#A44BC5',
        driver: '/img/map/A44BC5/driver.png',
        heartBeat: '/img/map/A44BC5/heartbeat.png',
        statusHistory: '/img/map/A44BC5/status-history.png',
        geofence: '/img/map/A44BC5/geofence.png'
    },
    {
        // driver: '/img/map/driver.png',
        stop: '/img/map/066CC8/stop.png',
        timeSeries: '/img/map/066CC8/time-series.png',
        color: '#066CC8',
        driver: '/img/map/066CC8/driver.png',
        heartBeat: '/img/map/066CC8/heartbeat.png',
        statusHistory: '/img/map/066CC8/status-history.png',
        geofence: '/img/map/066CC8/geofence.png'
    },
    {
        // driver: '/img/map/driver.png',
        stop: '/img/map/018531/stop.png',
        timeSeries: '/img/map/018531/time-series.png',
        color: '#018531',
        driver: '/img/map/018531/driver.png',
        heartBeat: '/img/map/018531/heartbeat.png',
        statusHistory: '/img/map/018531/status-history.png',
        geofence: '/img/map/018531/geofence.png'
    },
    {
        // driver: '/img/map/driver.png',
        stop: '/img/map/D71B74/stop.png',
        timeSeries: '/img/map/D71B74/time-series.png',
        color: '#D71B74',
        driver: '/img/map/D71B74/driver.png',
        heartBeat: '/img/map/D71B74/heartbeat.png',
        statusHistory: '/img/map/D71B74/status-history.png',
        geofence: '/img/map/D71B74/geofence.png'
    },
    {
        // driver: '/img/map/driver.png',
        stop: '/img/map/ED9900/stop.png',
        timeSeries: '/img/map/ED9900/time-series.png',
        color: '#ED9900',
        driver: '/img/map/ED9900/driver.png',
        heartBeat: '/img/map/ED9900/heartbeat.png',
        statusHistory: '/img/map/ED9900/status-history.png',
        geofence: '/img/map/ED9900/geofence.png'
    }
];
