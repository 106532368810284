<template>
    <div class="edit-line">
        <md-button
            v-if="!isReadOnlyUser"
            title="Edit service details"
            :class="useIcon ? 'md-warning md-just-icon md-round' : `${className}`"
            @click.stop="handleUpdateService"
            :disabled="isDisabled"
        >
            <md-icon v-if="useIcon">edit</md-icon>
            <span v-else>Edit</span>
        </md-button>
        <!--<md-tooltip v-if="isDelivered" class="line-tooltip" md-direction="top">
            You cannot edit a delivered line.
        </md-tooltip>-->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ServiceLineModal from './ServiceLineModal';

export default {
    name: 'UpdateLineButton',
    props: {
        service: {
            type: Object,
            default: () => null
        },
        // teamMembers: {
        //    type: Array,
        //    default: () => []
        // },
        className: {
            type: String,
            default: () => ''
        },
        useIcon: {
            type: Boolean,
            default: () => true
        },
        isDelivered: {
            type: Boolean,
            default: () => false
        },
        stopStatus: {
            type: String,
            default: () => null
        },
        shipmentStatus: {
            type: String,
            default: () => null
        }
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        }),
        isDisabled() {
            if (
                this.shipmentStatus === null &&
                this.stopStatus !== null &&
                (this.stopStatus.toLowerCase() === 'failed' || this.stopStatus.toLowerCase() === 'complete')
            )
                return true;
            if (
                this.shipmentStatus !== null &&
                (this.shipmentStatus.toLowerCase() === 'complete' || this.shipmentStatus.toLowerCase() === 'cancelled')
            )
                return true;
            return this.isDelivered;
        }
    },
    methods: {
        handleUpdateService() {
            this.$modal
                .show(ServiceLineModal, {
                    stopId: this.service.stopId
                })
                .then((response) => {
                    if (response) {
                        Object.assign(response, { itemId: this.item.itemId });

                        this.$emit('itemUpdated', response);
                        this.$modal.hide();
                    }
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.edit-line {
    display: inline;
}
</style>
