var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "drop-down",
    {
      attrs: {
        "should-close": _vm.shouldCloseDropdown,
        reduce: function (inv) {
          return inv.teamMemberInvoiceId
        },
      },
      on: {
        closeDropdown: function (val) {
          return (this$1.shouldCloseDropdown = val)
        },
        closeOtherDropDown: _vm.closeOtherDropDown,
      },
    },
    [
      _vm.isBulk
        ? _c(
            "span",
            {
              staticClass: "status bulk-status",
              attrs: { slot: "title", "data-toggle": "dropdown" },
              slot: "title",
            },
            [_vm._v("\n        CHANGE PAYMENT STATUS\n    ")]
          )
        : _c(
            "span",
            {
              staticClass: "status",
              class:
                "payment-status-" +
                _vm.invoiceDetails.status.toLowerCase().replace(/\s+/g, "") +
                " " +
                _vm.className,
              attrs: { slot: "title", "data-toggle": "dropdown" },
              slot: "title",
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "inputs.paymentStatuses." + _vm.invoiceDetails.status
                    )
                  ) +
                  "\n    "
              ),
            ]
          ),
      _c(
        "ul",
        { staticClass: "dropdown-menu" },
        _vm._l(_vm.statuses, function (status, index) {
          return _c("li", { key: index }, [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleChangePaymentStatus(status.statusId)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(status.statusValue) +
                    "\n            "
                ),
              ]
            ),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }