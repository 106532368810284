var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam
    ? _c("div", { staticClass: "cf-container" }, [
        _c(
          "div",
          { staticClass: "cf-action-container" },
          [
            _c(
              "md-button",
              {
                staticClass:
                  "md-primary md-just-icon md-round add-geofence-event",
                attrs: { title: "Add New Band" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.addNewBand()
                  },
                },
              },
              [_c("md-icon", [_vm._v("add")])],
              1
            ),
          ],
          1
        ),
        _vm.offerSettingModel && _vm.offerSettingModel.bands
          ? _c(
              "div",
              [
                _c(
                  "md-table",
                  {
                    staticClass: "context-menu-support custom-paginated-table",
                  },
                  [
                    _c(
                      "md-table-row",
                      [
                        _c("md-table-head", [_vm._v("Order")]),
                        _c("md-table-head", [_vm._v("Band Name")]),
                        _c("md-table-head", [_vm._v("Min Proximity")]),
                        _c("md-table-head", [_vm._v("Max Proximity")]),
                        _c("md-table-head", [_vm._v("Sending Mode")]),
                        _c("md-table-head", [_vm._v("Drip Feed Delay")]),
                        _c("md-table-head", [_vm._v("Next Band Delay")]),
                        _c("md-table-head", { staticClass: "end" }, [
                          _vm._v("Actions"),
                        ]),
                      ],
                      1
                    ),
                    _vm._l(_vm.offerSettingModel.bands, function (item) {
                      return _c(
                        "md-table-row",
                        { key: item.id },
                        [
                          _c("md-table-cell", [_vm._v(_vm._s(item.order))]),
                          _c("md-table-cell", [_vm._v(_vm._s(item.name))]),
                          _c("md-table-cell", [
                            _vm._v(
                              _vm._s(
                                item.minProximity +
                                  " " +
                                  item.minProximityUnit +
                                  " "
                              )
                            ),
                          ]),
                          _c("md-table-cell", [
                            _vm._v(
                              _vm._s(
                                item.maxProximity +
                                  " " +
                                  item.maxProximityUnit +
                                  " "
                              )
                            ),
                          ]),
                          _c("md-table-cell", [
                            _vm._v(_vm._s(item.sendingMode)),
                          ]),
                          _c("md-table-cell", [
                            _vm._v(
                              _vm._s(
                                _vm._f("timeSpanFormat")(item.dripFeedDelay)
                              )
                            ),
                          ]),
                          _c("md-table-cell", [
                            _vm._v(
                              _vm._s(
                                _vm._f("timeSpanFormat")(item.nextBandDelay)
                              )
                            ),
                          ]),
                          _c(
                            "md-table-cell",
                            {
                              staticClass: "action-buttons",
                              attrs: { align: "end" },
                            },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-warning md-just-icon md-round",
                                  attrs: {
                                    title: "Update setting",
                                    "data-test-id": "btn-update-" + item.id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleUpdate(item.id)
                                    },
                                  },
                                },
                                [_c("md-icon", [_vm._v("edit")])],
                                1
                              ),
                              _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-danger md-just-icon md-round",
                                  attrs: {
                                    title: "Delete setting",
                                    "data-test-id": "btn-delete-" + item.id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleDelete(item.id)
                                    },
                                  },
                                },
                                [_c("md-icon", [_vm._v("delete")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _c("div", [
              _c("p", { staticClass: "no-result-message" }, [
                _vm._v("No actions found."),
              ]),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }