<template>
    <div>
        <md-card-content>
            <md-table :value="driveData" class="table-hover">
                <md-table-row slot="md-table-row" slot-scope="{ item, index }" @click="viewAddress(item)">
                    <md-table-cell md-label="ID">
                        {{ index + 1 }}
                    </md-table-cell>
                    <md-table-cell md-label="Date / time" md-sort>
                        {{ displayDateTime(item) | timeFormat(DATE_TYPES.standardTime) }}
                    </md-table-cell>
                    <md-table-cell md-label="Location" md-sort>
                        <a class="address" @click.stop="viewAddress(item)">
                            {{ displayAddressOrCoord(item) }}
                        </a>
                    </md-table-cell>
                    <md-table-cell md-label="Stop duration" md-sort>
                        {{ toTime(item.stopTimeMinutes) }}
                    </md-table-cell>
                    <md-table-cell v-if="hasTemperature" md-label="Avg Temperature" md-sort>
                        {{ !item.stopAvgTemperature ? '-' : item.stopAvgTemperature / 100 }}
                    </md-table-cell>
                    <md-table-cell md-label="Device IMEI">
                        {{ item.deviceIMIE }}
                    </md-table-cell>
                </md-table-row>
            </md-table>
            <div class="label-total">
                <span>Total Stops: {{ driveData.length }}</span>
            </div>
        </md-card-content>
    </div>
</template>
<style lang="scss" scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

::v-deep .md-table-head {
    cursor: default;
}

.table-hover {
    cursor: pointer;
}

.label-total {
    float: right;
    font-size: 14px;
    font-weight: 500;

    span {
        display: block;
    }
}
</style>
<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins';

import { ViewAddressModal } from '..';

export default {
    name: 'StopReportDetails',
    components: {},
    mixins: [GeneralMixin],
    props: {
        memberList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {};
    },
    computed: {
        driveData() {
            return this.memberList;
        },
        hasTemperature() {
            return this.memberList.some((obj) => obj.stopAvgTemperature != null);
        },
        user() {
            return this.$store.getters['user/user'];
        }
    },
    methods: {
        displayDateTime(item) {
            return moment(item.dateTime).utcOffset(item.utcOffset);
        },
        displayAddressOrCoord(item) {
            const houseAddr = [item.addressHouseNumber, item.addressRoad].filter((part) => part).join(' ');

            const addr = [houseAddr, item.addressSuburb, item.addressTown].filter((part) => part).join(', ');
            if (addr) 
                return addr;
            return `${item.latitude} - ${item.longitude}`;
        },
        viewAddress(item) {
            this.$modal.show(ViewAddressModal, { data: { location: item, address: this.displayAddressOrCoord(item) } });
        },
        toTime(minutes) {
            const duration = moment.duration({ minutes: Math.ceil(minutes) });
            const formatted = moment.utc(duration.asMilliseconds()).format('HH [hr] mm [min]');

            return formatted;
        }
    }
};
</script>
