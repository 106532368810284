var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isStartRouting
      ? _c("div", { staticClass: "toggled-options map-panel" }, [
          _c("div", { staticClass: "routing-panel" }, [
            _c(
              "button",
              {
                staticClass: "btn-summary",
                on: {
                  click: function ($event) {
                    _vm.isCollapsed = !_vm.isCollapsed
                  },
                },
              },
              [
                _c("div", { staticClass: "label-header" }, [
                  _vm._v(_vm._s(_vm.btnTxt) + " Routing Summary"),
                ]),
                _c("div", { staticClass: "label-timer" }, [
                  _vm._v(_vm._s(_vm.displayRunningTime)),
                ]),
              ]
            ),
            !_vm.isCollapsed
              ? _c("div", { staticClass: "md-layout panel-layout" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
                    },
                    [
                      _c(
                        "md-card",
                        { staticClass: "routing-card" },
                        [
                          _c("md-card-content", [
                            _c(
                              "div",
                              [
                                _c(
                                  "md-table",
                                  { staticClass: "table-route-panel" },
                                  [
                                    _c(
                                      "md-table-row",
                                      [
                                        _c("md-table-cell", [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(_vm.totalRoutedStops) +
                                              " of " +
                                              _vm._s(_vm.totalStops) +
                                              " stops have been routed.\n                                        "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-table-row",
                                      [
                                        _c("md-table-cell", [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(_vm.countRouteChanged) +
                                              " routes have changed since the last update.\n                                        "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-table-row",
                                      [
                                        _c("md-table-cell", [
                                          !_vm.isRouteLoading
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(
                                                      _vm.nextRoutingTime
                                                    ) +
                                                    " seconds until the next routing update\n                                            "
                                                ),
                                              ])
                                            : _c("span", [
                                                _c("div", {
                                                  staticClass:
                                                    "btn-loader route-loader",
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "route-loader-text",
                                                  },
                                                  [_vm._v("Loading...")]
                                                ),
                                              ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm.routeLastChangedTime
                                      ? _c(
                                          "md-table-row",
                                          [
                                            _c("md-table-cell", [
                                              _vm._v(
                                                "\n                                            The routes have not changed in " +
                                                  _vm._s(
                                                    _vm.routeLastChangedTime
                                                  ) +
                                                  ".\n                                        "
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "btn-center" }, [
              !_vm.isRouteLoading
                ? _c(
                    "button",
                    {
                      staticClass: "custom-btn",
                      on: { click: _vm.handleStopRouting },
                    },
                    [_vm._v("Stop Routing")]
                  )
                : _c("div", {
                    class: ["btn-loader", _vm.isCollapsed ? "loader" : ""],
                  }),
            ]),
          ]),
        ])
      : _vm._e(),
    !_vm.isStartRouting
      ? _c("div", { staticClass: "toggled-options map-panel" }, [
          _c(
            "div",
            { staticClass: "map-panel--routing_options" },
            [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 0,
                      expression: "step === 0",
                    },
                  ],
                  on: { click: _vm.handleUseNewOptimiseStops },
                },
                [
                  _c("md-icon", [_vm._v("undo")]),
                  _c(
                    "md-tooltip",
                    {
                      staticClass: "toggle-tooltip",
                      attrs: { "md-direction": "top" },
                    },
                    [_vm._v("Go back to Old Optimise Stops")]
                  ),
                ],
                1
              ),
              _c("load-modal-button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step === 0,
                    expression: "step === 0",
                  },
                ],
                attrs: { "trip-date": _vm.tripDate },
                on: { handleLoadModel: _vm.handleLoadModel },
              }),
              _c(
                "button",
                { on: { click: _vm.handleClickSetting } },
                [
                  _c("md-icon", [_vm._v("settings")]),
                  _c(
                    "md-tooltip",
                    {
                      staticClass: "toggle-tooltip",
                      attrs: { "md-direction": "top" },
                    },
                    [_vm._v("Routing Options")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }