var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayGeocodingScore
    ? _c(
        "div",
        { staticClass: "icon-container" },
        [
          _c("md-tooltip", { attrs: { "md-direction": "top" } }, [
            _vm.addressComponents.type
              ? _c("div", { staticClass: "address-text-tooltip" }, [
                  _c("b", [_vm._v("Address Type:")]),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.addressComponents.type) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm.addressComponents.confidenceScore &&
            _vm.addressComponents.confidenceScore >= 0
              ? _c("div", { staticClass: "address-text-tooltip" }, [
                  _c("b", [_vm._v("Confidence Score:")]),
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.addressComponents.confidenceScore * 100 + "%"
                      ) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm.addressComponents.line1
              ? _c("div", { staticClass: "address-text-tooltip" }, [
                  _c("b", [_vm._v("Line 1:")]),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.addressComponents.line1) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm.addressComponents.line2
              ? _c("div", { staticClass: "address-text-tooltip" }, [
                  _c("b", [_vm._v("Line 2:")]),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.addressComponents.line2) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm.addressComponents.city
              ? _c("div", { staticClass: "address-text-tooltip" }, [
                  _c("b", [_vm._v("City:")]),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.addressComponents.city) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm.addressComponents.province
              ? _c("div", { staticClass: "address-text-tooltip" }, [
                  _c("b", [_vm._v("Province:")]),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.addressComponents.province) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm.addressComponents.postcode
              ? _c("div", { staticClass: "address-text-tooltip" }, [
                  _c("b", [_vm._v("Postcode:")]),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.addressComponents.postcode) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm.addressComponents.country
              ? _c("div", { staticClass: "address-text-tooltip" }, [
                  _c("b", [_vm._v("Country:")]),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.addressComponents.country) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "md-icon",
            {
              staticClass: "icon-location",
              style: { color: _vm.confidenceColor },
            },
            [_vm._v(_vm._s(_vm.icon))]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }