<template>
    <div class="rating-parent">
        <div v-if="currentRating" class="rating-line stars">
            <div class="clear" @click.stop="clearSelection">
                <button
                    class="vs__clear"
                    title="Clear Selected"
                    aria-label="Clear Selected"
                    ref="clearButton"
                    type="button"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                        <path
                            d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                        />
                    </svg>
                </button>
            </div>

            <div @click.stop="lineClicked()">
                <md-icon
                    v-for="index in 5"
                    :key="index"
                    :style="{ color: index <= currentRating ? 'orange' : 'rgba(0,0,0,0.54)' }"
                >
                    star
                </md-icon>
                <md-icon>
                    {{ expanded ? 'expand_less' : 'expand_more' }}
                </md-icon>
            </div>
        </div>

        <div v-else class="rating-line text" @click.stop="lineClicked()">
            All ratings
            <md-icon>
                {{ expanded ? 'expand_less' : 'expand_more' }}
            </md-icon>
        </div>

        <div class="ratings-display">
            <transition name="slide">
                <ratings-display
                    v-if="expanded"
                    v-click-outside="hideRatingsDisplayWindow"
                    :ratings="ratings"
                    :can-be-selected="true"
                    :selected-rating="currentRating"
                    @selectedRating="selectRating"
                />
            </transition>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.rating-parent {
    display: inline-block;
    height: 36px;

    .rating-line {
        float: right;
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        cursor: pointer;

        &.stars {
            padding-top: 5px;

            > div {
                float: right;
            }

            .clear {
                float: right;
                margin: 4px 4px;
                width: 32px;
                text-align: center;
                cursor: pointer;
            }
        }

        &.text {
            text-align: right;
            vertical-align: baseline;
            padding: 7px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .ratings-display {
        margin-right: 10px;
        width: 300px;
    }
}
</style>
<script>
import { GeneralMixin } from '@/mixins';
import RatingsDisplay from './RatingsDisplay';

export default {
    name: 'SelectRatingFilter',
    mixins: [GeneralMixin],
    components: { RatingsDisplay },
    props: {
        selectedRating: {
            type: Number,
            default: () => null
        },
        ratings: {
            type: Array,
            default: () => null
        }
    },
    data() {
        return {
            currentRating: this.selectedRating,
            expanded: false
        };
    },
    methods: {
        lineClicked() {
            this.expanded = !this.expanded;
        },
        hideRatingsDisplayWindow() {
            this.expanded = false;
        },
        selectRating(rating) {
            if (rating !== this.currentRating) {
                this.currentRating = rating;
            } else {
                this.currentRating = null;
            }
            this.hideRatingsDisplayWindow();
            this.$emit('selectedRating', rating);
        },
        clearSelection() {
            this.selectRating(null);
        }
    }
};
</script>
