<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Add a Line</h4>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v" class="form-wrapper">
                <!--<form-group name="serviceValue" label="" :messages="localMessages">-->
                <services-autocomplete
                    ref="servicesRef"
                    label="Service"
                    id="autoservice"
                    placeholder=""
                    v-model="serviceValue"
                    :should-focus="true"
                    :is-required="true"
                />
                <!--</form-group>-->

                <form-group
                    name="description"
                    label="Alternative Description / Notes (Optional)"
                    attribute="Description"
                >
                    <md-input v-model="description" @input="$v.description.$touch()" />
                </form-group>

                <div class="md-layout md-alignment-center-left">
                    <form-group
                        class="md-layout-item md-size-33 quantity"
                        name="quantity"
                        label="Quantity"
                        attribute="Quantity"
                        :messages="localMessages"
                    >
                        <md-input v-model="quantity" type="number" min="0" required @input="$v.quantity.$touch()" />
                    </form-group>
                    <div class="md-layout-item quantity-type">{{ serviceValue.quantityType }}</div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="addServiceLine">
                Save
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, decimal } from 'vuelidate/lib/validators';
import { handleRequests, showErrorMessage } from '@/helpers';
import { ServicesAutocomplete } from '@/components';

export default {
    name: 'ServiceLineModal',
    components: {
        ServicesAutocomplete
    },
    mixins: [GeneralMixin],
    props: {
        stopId: {
            type: Number,
            default: null
        },
        lines: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        // We will use it when we decide to add Edit functionality.
        service: {
            type: Object,
            default: () => null
        }
    },
    validations: {
        serviceValue: {
            required
            // serviceId: {
            //    required
            // }
        },
        quantity: {
            required,
            decimal,
            minValue: (value) => value > 0,
            maxValue: (value) => value < 10000000
        }
    },
    data() {
        return {
            localMessages: {
                required: '{attribute} is required.',
                decimal: '{attribute} should should be a decimal number.',
                minValue: '{attribute} should be a positive number.',
                maxValue: '{attribute} should be less than 10000000'
            },

            quantity: this.service ? this.service.quantity : 0,
            serviceValue: this.service ? this.service : { serviceId: null }
        };
    },
    mounted() {},
    methods: {
        async addServiceLine() {
            this.$v.$touch();
            // Not the best... aren't we using any kind of event bus?
            this.$refs.servicesRef.callTouch();

            if (this.lines.some((s) => s.serviceId === this.serviceValue.serviceId)) {
                showErrorMessage(this, 'This service has already been added. You cannot add it again.');
                return;
            }

            if (!this.$v.$invalid) {
                try {
                    this.$_handleLoaderState(true, 'UPDATING...');
                    const payload = {
                        method: 'post',
                        data: {
                            serviceId: this.serviceValue.serviceId,
                            description: this.description,
                            quantity: Number(this.quantity)
                        }
                    };

                    const api = `/api/stops/${this.stopId}/lines`;
                    await handleRequests(api, payload);

                    this.$notify({
                        message: `Line added!`,
                        type: 'success'
                    });
                    this.$v.$reset();

                    // this.resolve(true);
                    this.resolve(payload.data);
                } catch (e) {
                    const message = 'Cannot add service.';
                    showErrorMessage(this, message);
                    this.resolve(false);
                } finally {
                    this.$_handleLoaderState(false);
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.quantity {
    padding-left: 0px;
}
/*.quantity-type {
    text-transform: lowercase;
}
.quantity-type:first-letter {
    text-transform: capitalize;
}*/
</style>
