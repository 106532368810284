<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Change Status</h4>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.changeStatus" class="form-wrapper">
                <form-group name="role" label="Change status">
                    <md-select v-model="form.newStatus" placeholder="Status">
                        <md-option v-for="item in statuses" :key="item" :label="item" :value="item">
                            {{ item }}
                        </md-option>
                    </md-select>
                </form-group>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="changeStatus">
                Save
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { required } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import {
    TRIP_STATUS_CONSTANTS,
    UNASSIGNED_STATUS_CONSTANTS,
    FUTURE_TRIP_STATUS_CONSTANTS,
    PAST_TRIP_STATUS_CONSTANTS,
    DATE_TYPES
} from '@/utils/constants';
import moment from 'moment';

export default {
    name: 'ChangeStatusModal',
    mixins: [GeneralMixin],
    props: {
        trip: {
            type: Object,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    validations: {
        changeStatus: {
            newStatus: {
                required
            }
        }
    },
    data() {
        return {
            form: {
                newStatus: null
            },
            statuses: TRIP_STATUS_CONSTANTS
        };
    },
    mounted() {
        this.form.newStatus = this.trip.status;

        if (!this.trip.user.publicUserId) {
            this.statuses = UNASSIGNED_STATUS_CONSTANTS;
        } else {
            const today = moment().format(DATE_TYPES.internationalDate);
            const tripDateObj = moment(this.trip.tripDate);

            if (tripDateObj.isAfter(today)) {
                this.statuses = FUTURE_TRIP_STATUS_CONSTANTS;
            }

            if (tripDateObj.isBefore(today)) {
                this.statuses = PAST_TRIP_STATUS_CONSTANTS;
            }
        }
    },
    methods: {
        async changeStatus() {
            this.$_handleLoaderState(true, 'UPDATING...');
            const payload = {
                method: 'post',
                data: {
                    newStatus: this.form.newStatus,
                    oldStatus: this.trip.status
                }
            };
            try {
                const api = `/api/trips/${this.trip.tripId}/change-status`;
                await handleRequests(api, payload);

                this.$notify({
                    message: `Trip status was changed to ${this.form.newStatus} !`,
                    type: 'success'
                });
                this.$v.$reset();
                this.resolve({ tripId: this.trip.tripId, status: this.form.newStatus });
            } catch (e) {
                const message = 'Cannot change trip status.';
                showErrorMessage(this, message, e);
                this.resolve(false);
            }
        }
    }
};
</script>
