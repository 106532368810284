<template>
    <div class="sidebar-spacing">
        <sidebar-item :link="{ name: 'Reports', icon: 'assignment' }">
            <sidebar-item
                v-if="hasReportsAccess"
                :link="{
                    name: 'Ignition On/Off',
                    path: '/reports/ignition-report'
                }"
            />

            <sidebar-item
                v-if="hasReportsAccess"
                :link="{
                    name: 'Location History',
                    path: '/reports/location-history-report'
                }"
            />

            <sidebar-item
                v-if="hasReportsAccess"
                :link="{
                    name: 'Safety',
                    path: '/reports/safety-report'
                }"
            />

            <sidebar-item
                :link="{
                    name: 'Safety Summary',
                    path: '/reports/safety-summary-report'
                }"
                v-if="!isIndividualUser && hasReportsAccess"
            />

            <sidebar-item
                v-if="hasReportsAccess"
                :link="{
                    name: 'Speeding',
                    path: '/reports/speeding-report'
                }"
            />

            <sidebar-item
                :link="{
                    name: 'Stops',
                    path: '/reports/stop-report'
                }"
            />

            <sidebar-item
                :link="{
                    name: 'Stop Rating Report',
                    path: '/reports/stop-rating-report'
                }"
            />

            <sidebar-item
                :link="{
                    name: 'Travel Statistics',
                    path: '/reports/distance-report'
                }"
            />
            <sidebar-item
                :link="{
                    name: 'Geofence Report',
                    path: '/reports/geofence-report'
                }"
            />
            <sidebar-item
                :link="{
                    name: 'Geofence Timesheet',
                    path: '/reports/geofence-timesheet'
                }"
            />
            <sidebar-item
                v-if="isTeamOwner"
                :link="{
                    name: 'Driver Payment Summary',
                    path: '/reports/driver-payment-summary'
                }"
            />
        </sidebar-item>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            isIndividualUser: 'user/isIndividualUser',
            isTeamOwner: 'user/isTeamOwner',
            hasReportsAccess: 'user/hasReportsAccess'
        })
    }
};
</script>
