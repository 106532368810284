var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.data == null
        ? _c(
            "md-icon",
            [
              _vm._v("\n        " + _vm._s(_vm.icon) + "\n        "),
              _c("md-tooltip", { attrs: { "md-direction": _vm.direction } }, [
                _vm._v("No location update ever received"),
              ]),
            ],
            1
          )
        : _c(
            "md-icon",
            {
              class:
                _vm.getLastLocationUpdateIndicatorColor(_vm.convertedDate) +
                "-indicator " +
                _vm.customClass,
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.data.source != null
                      ? _vm.data.source === "Tracker"
                        ? "drive_eta"
                        : "phone_iphone"
                      : _vm.icon
                  ) +
                  "\n        "
              ),
              _vm.data.lastLocationUpdate
                ? _c(
                    "md-tooltip",
                    { attrs: { "md-direction": _vm.direction } },
                    [
                      _vm._v(
                        "\n            Last location update:\n            "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("dateFormat")(
                              _vm.convertedDate,
                              _vm.dateTypes.standardDateTime
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }