<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon" v-if="stopDetails.assignedTo.photoUrl === null">
                <md-icon>account_box</md-icon>
            </div>
            <div class="card-icon profile-image" v-if="stopDetails.assignedTo.photoUrl !== null">
                <img :src="stopDetails.assignedTo.photoUrl" @error="$_setDefaultBrokenImage" alt="avatar" />
            </div>
        </md-card-header>

        <md-card-content>
            <div v-if="stopDetails.carrierTeamId" class="custom-md-field">
                <div class="custom-label">Carrier</div>
                <span v-if="isReadOnlyUser">{{ stopDetails.carrierTeam.company }}</span>
                <span v-else-if="stopDetails.carrierTeamId != null">
                    <img
                        v-if="stopDetails.carrierTeam && stopDetails.carrierTeam.logoUrl"
                        class="profile-image carrier-image-logo"
                        :src="
                            stopDetails.carrierTeam.logoUrl !== null
                                ? stopDetails.carrierTeam.logoUrl
                                : $root.defaultPhotoUrl
                        "
                        alt="carrier logo"
                        @error="$_setDefaultBrokenImage"
                    />
                    {{ stopDetails.carrierTeam.company }}
                </span>
                <reassign-stop-button
                    v-else
                    type="text"
                    :item="stopDetails"
                    :team-members="teamMembers"
                    @assignedUser="handleAssignedUser"
                />
            </div>
            <div v-else class="custom-md-field">
                <div class="custom-label">Driver</div>
                <span v-if="isReadOnlyUser">{{ stopDetails.assignedTo.fullName }}</span>
                <router-link
                    v-else-if="stopDetails.assignedTo.publicUserId"
                    :to="{
                        name: 'Team Member Profile',
                        params: { publicUserId: stopDetails.assignedTo.publicUserId }
                    }"
                    target="_blank"
                >
                    {{ stopDetails.assignedTo.fullName }}
                </router-link>
                <reassign-stop-button
                    v-else
                    type="text"
                    :item="stopDetails"
                    :team-members="teamMembers"
                    @assignedUser="handleAssignedUser"
                />

                <div v-if="!stopDetails.rateGroupId && stopDetails.assignedTo.rateGroupId && showServicePackage()">
                    <div class="custom-label">Service Package</div>
                    <span>{{ stopDetails.assignedTo.rateGroupName }}</span>
                </div>
            </div>
            <div class="details-box" v-if="stopDetails.rating">
                <label>Customer Rating for this Stop</label>
                <div class="rating">
                    <md-icon
                        v-for="index in 5"
                        :key="index"
                        :style="{ color: index <= stopDetails.rating ? 'orange' : 'rgba(0,0,0,0.54' }"
                    >
                        star_rate
                    </md-icon>
                </div>
            </div>
            <div class="details-box" v-if="stopDetails.assignedTo.phone">
                <label>Phone</label>
                <div class="phone-sms">
                    <a :href="`tel:${stopDetails.assignedTo.phone}`">
                        {{ stopDetails.assignedTo.phone }}
                    </a>
                    <send-sms-button
                        v-if="stopDetails.assignedTo.phone"
                        :public-user-id="stopDetails.assignedTo.publicUserId"
                        :name="stopDetails.assignedTo.fullName"
                    />
                </div>
            </div>
            <div v-if="stopDetails.carrierTeamId != null">
                <div class="details-box">
                    <label>Reference #:</label>
                    <span>
                        {{ stopDetails.shipment.assignedCarrierBooking.supportReference }}
                    </span>
                </div>
                <div class="details-box">
                    <label>Status</label>
                    <span>
                        <div
                            class="custom-badge"
                            :class="`status-${$_getQuotingStatusBadgeColor(stopDetails.shipment.quotingStatus)}`"
                        >
                            {{ $_getQuotingStatusText(stopDetails.shipment.quotingStatus) }}
                        </div>
                    </span>
                </div>
                <div class="details-box" v-if="stopDetails.shipment.assignedCarrierBooking.serviceLevel">
                    <label>Service Type:</label>
                    <span>
                        <div
                            class="custom-badge"
                            :class="
                                `status-${$_getServiceLevelBadgeColor(
                                    stopDetails.shipment.assignedCarrierBooking.timeWindowEnd
                                )}`
                            "
                        >
                            {{ $t(stopDetails.shipment.assignedCarrierBooking.serviceLevel) }}
                        </div>
                    </span>
                </div>
                <div class="details-box">
                    <label>Price:</label>
                    <span>
                        {{
                            stopDetails.shipment.assignedCarrierBooking.feeAfterTax
                                | currency(stopDetails.shipment.assignedCarrierBooking.currency)
                        }}
                    </span>
                </div>
                <div class="details-box" v-if="stopDetails.type == 'Pick Up'">
                    <label>Pickup ETA:</label>
                    <span v-if="stopDetails.shipment.assignedCarrierBooking.pickupEta">
                        {{
                            stopDetails.shipment.assignedCarrierBooking.pickupEta
                                | dateTimeFormat(DATE_TYPES.standardDate)
                        }}
                    </span>
                    <span v-else class="not-available">
                        Not available
                    </span>
                </div>
                <div class="details-box" v-if="stopDetails.type == 'Drop'">
                    <label>Drop ETA:</label>
                    <span v-if="stopDetails.shipment.assignedCarrierBooking.dropOffEta">
                        {{
                            stopDetails.shipment.assignedCarrierBooking.dropOffEta
                                | dateTimeFormat(DATE_TYPES.standardDate)
                        }}
                    </span>
                    <span v-else class="not-available">
                        Not available
                    </span>
                </div>
            </div>
        </md-card-content>
    </md-card>
</template>

<script>
import { GeneralMixin, CarrierMixin, RatesEngineMixin } from '@/mixins';
import ReassignStopButton from '@/pages/Stops/buttons/ReassignStopButton';
import { mapGetters } from 'vuex';
import { SendSmsButton } from '@/components';

export default {
    name: 'DriverDetails',
    mixins: [GeneralMixin, CarrierMixin, RatesEngineMixin],
    components: { ReassignStopButton, SendSmsButton },
    props: {
        stopDetails: {
            type: Object,
            default: () => {}
        },
        teamMembers: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    methods: {
        handleAssignedUser(data) {
            this.$emit('handleAssignedUser');
        },
        showServicePackage() {
            // eslint-disable-next-line no-bitwise
            if (this.stopDetails.assignedTo.applyRatesTo & this.getKeyByLabelInApplyRatesTo('stop')) {
                return true;
            }

            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.profile-image {
    height: 63px;
    width: 63px;
    padding: 0px !important;
    -webkit-box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 0, 0, 0.2) !important;
    img {
        border-radius: 3px;
    }
}

.custom-md-field {
    margin-bottom: 25px;
    a {
        color: #0076f0;
    }
}

.rating {
    display: flex;
    align-items: flex-end;
    > {
        display: inline-block;
    }
    .md-icon {
        margin: 0;
        padding-bottom: 8px;
    }
}

.phone-sms {
    display: flex;
    > span {
        margin-left: 5px;
    }
}

.not-available {
    color: #aaa !important;
    font-style: italic;
}

.carrier-image-logo {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
}
</style>
