<template>
    <div v-if="hasMarketplaceAccess" class="dashboard">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <FilterCarriers
                            :class="$root.isTablet ? 'tablet-filter-container' : ''"
                            @onFilter="handleFilter"
                        />
                    </div>
                    <div class="custom-toolbar-end">
                        <search-component ref="search" @onSearch="handleSearch" />
                        <div>
                            <router-link
                                class="md-button md-primary md-just-icon md-round pull-right header-button"
                                :to="{
                                    name: 'Marketplace'
                                }"
                            >
                                <div class="md-ripple">
                                    <div class="md-button-content">
                                        <md-icon>add</md-icon>
                                        <md-tooltip md-direction="left">Connect to Carrier</md-tooltip>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>crop</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div v-if="isListLoading" class="empty-table">
                            <div class="table-loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="teamCarrierList && teamCarrierList.length">
                                <md-table class="context-menu-support custom-paginated-table">
                                    <md-table-row>
                                        <md-table-head></md-table-head>
                                        <md-table-head>Name</md-table-head>
                                        <md-table-head>Logo</md-table-head>
                                        <md-table-head>Status</md-table-head>
                                        <md-table-head></md-table-head>
                                    </md-table-row>
                                    <md-table-row v-for="item in teamCarrierList" :key="item.teamCarrierId">
                                        <md-table-cell class="icon-warning-column">
                                            <md-icon v-if="item.status == 'Onboarding'" class="warning-yellow-icon">
                                                warning
                                                <md-tooltip md-direction="left">
                                                    Please complete the questions mapping, set up the capacity settings,
                                                    and provide the access keys to complete the activation of this
                                                    carrier.
                                                </md-tooltip>
                                            </md-icon>
                                        </md-table-cell>
                                        <md-table-cell>{{ item.carrierTeam.company }}</md-table-cell>
                                        <md-table-cell>
                                            <img
                                                v-if="item.carrierTeam.logoUrl"
                                                class="profile-image"
                                                :src="item.carrierTeam.logoUrl"
                                                :title="item.carrierTeam.logoFileName"
                                                @error="$_setDefaultBrokenImage"
                                                alt
                                            />
                                            <img v-else class="profile-image" :src="$root.defaultLogoUrl" alt />
                                        </md-table-cell>
                                        <md-table-cell>{{ item.statusDesc }}</md-table-cell>
                                        <md-table-cell class="action-buttons">
                                            <md-button
                                                :title="item.status === 'Active' ? `Deactivate` : `Activate`"
                                                class="md-just-icon md-round"
                                                :class="item.status === 'Active' ? `md-success` : `md-secondary`"
                                                @click.stop="toggleStatus(item)"
                                            >
                                                <md-icon v-if="item.status === 'Active'">link</md-icon>
                                                <md-icon v-else>link_off</md-icon>
                                            </md-button>
                                            <md-button
                                                title="Questions Mapping"
                                                class="md-info md-just-icon md-round"
                                                @click.stop="updateQuestionsMapping(item)"
                                            >
                                                <md-icon>checklist_rtl</md-icon>
                                            </md-button>
                                            <md-button
                                                v-if="showAccessKeys(item)"
                                                title="Access Keys"
                                                class="md-warning md-just-icon md-round"
                                                @click.stop="updateAccessKeys(item)"
                                                :data-test-id="`btn-update-access-keys-${item.teamCarrierId}`"
                                            >
                                                <md-icon>vpn_key</md-icon>
                                            </md-button>
                                            <a
                                                v-if="item.onboardingLinkUrl != null"
                                                title="Setup link"
                                                class="md-button md-info md-just-icon md-round setup-link"
                                                :href="item.onboardingLinkUrl"
                                                target="_blank"
                                            >
                                                <div class="md-ripple">
                                                    <div class="md-button-content">
                                                        <md-icon>article</md-icon>
                                                    </div>
                                                </div>
                                            </a>
                                        </md-table-cell>
                                    </md-table-row>
                                </md-table>
                            </div>
                            <div v-else>
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
                <md-card-actions class="page-footer" md-alignment="space-between">
                    <div>
                        <p v-if="total === pagination.perPage" class="card-category">
                            Page {{ pagination.currentPage }} of many
                        </p>
                        <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                    </div>
                    <pagination
                        v-model="pagination.currentPage"
                        class="pagination-no-border pagination-success"
                        :per-page="pagination.perPage"
                        :total="total"
                        @change-page="handleChangePage($event, pagination.perPage)"
                    />
                </md-card-actions>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { TEAM_CARRIER_STATUS_CONSTANTS } from '@/utils/constants';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { Pagination, SearchComponent } from '@/components';
import { mapActions, mapGetters } from 'vuex';
import CarrierKeysModal from './CarrierKeysModal';
import CarrierQuestionsMappingModal from './CarrierQuestionsMappingModal';
import FilterCarriers from './components/FilterCarriers';

export default {
    name: 'TeamCarrierOverview',
    mixins: [GeneralMixin],
    components: {
        FadeLoader,
        Pagination,
        SearchComponent,
        FilterCarriers
    },
    data() {
        return {
            teamCarrierList: [],
            isListLoading: false,
            pagination: PAGINATION_DEFAULTS,
            filters: { status: 'Active' },
            maxPage: 1
        };
    },
    computed: {
        ...mapGetters({
            isLoading: 'isLoading',
            hasMarketplaceAccess: 'user/hasMarketplaceAccess'
        }),
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        }
    },
    async mounted() {
        const currentPage = Number(this.$route.query.currentPage) || 1;
        if (currentPage) {
            this.pagination.currentPage = currentPage;
        }

        const {
            data: { carriers, totalCarriers }
        } = await this.getTeamCarrierList(currentPage);
        this.setTeamCarriersAndTotal(carriers, totalCarriers);
    },
    methods: {
        ...mapActions('team', ['FETCH_TEAM_CARRIERS']),
        async getTeamCarrierList(pageNumber = 1, itemsPerPage = 50) {
            let response = {
                data: {
                    carriers: [],
                    totalCarriers: null
                }
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/teams/carriers/list`;
                response = await handleRequests(endpoint, {
                    params: {
                        pageNumber,
                        itemsPerPage,
                        ...this.cleanFilters()
                    }
                });
            } catch (error) {
                const message = 'Error in getting the team carrier list';
                showErrorMessage(this, message, error);
            } finally {
                this.isListLoading = false;
            }

            return response;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },
        setTeamCarriersAndTotal(data, total) {
            let carriers = null;
            if (data && data.length) {
                carriers = data.map((x) => {
                    const statusDesc = TEAM_CARRIER_STATUS_CONSTANTS.find((s) => s.value === x.status);
                    return { ...x, statusDesc: statusDesc.text };
                });
            }

            this.teamCarrierList = carriers;
            this.pagination.total = total || carriers.length;
        },
        getMaxPage(page) {
            this.maxPage = page;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            const {
                data: { carriers, totalCarriers }
            } = await this.getTeamCarrierList(currentPage, perPage);
            this.setTeamCarriersAndTotal(carriers, totalCarriers);
        },
        async handleSearch(data) {
            this.filters = Object.assign(this.filters, data);
            const {
                data: { carriers, totalCarriers }
            } = await this.getTeamCarrierList(1, this.pagination.perPage);

            this.setTeamCarriersAndTotal(carriers, totalCarriers);
        },
        async handleFilter(args) {
            await this.handleSearch({ status: args?.status !== 'All Carriers' ? args?.status : null });
        },
        async updateAccessKeys(carrier) {
            // no need to setup api keys for Internal Carriers
            // TODO: add carrier.type
            if (carrier.type === 'Internal Carrier') {
                this.$notify({
                    message: 'This carrier does not require access keys.',
                    type: 'success'
                });
                return;
            }

            this.$modal
                .show(CarrierKeysModal, {
                    carrier
                })
                .then(({ result, data }) => {
                    if (result === 'ok') {
                        this.$modal.hide();
                    }
                });
        },
        async updateQuestionsMapping(carrier) {
            this.$modal
                .show(CarrierQuestionsMappingModal, {
                    carrierName: carrier && carrier.carrierTeam ? carrier.carrierTeam.company : '',
                    questionsMapping: carrier.questionCustomFieldsMapping,
                    bookingQuestions: carrier.carrierTeam.questions.booking || [],
                    carrierTeamid: carrier.teamCarrierId
                })
                .then(({ result, data }) => {
                    if (result === 'ok') {
                        // update our list

                        const carrierIndex = this.teamCarrierList.findIndex(
                            (x) => x.teamCarrierId === carrier.teamCarrierId
                        );
                        // eslint-disable-next-line prefer-const
                        let updatedCarrier = this.teamCarrierList[carrierIndex];
                        updatedCarrier.questionCustomFieldsMapping = [...data];
                        this.$set(this.teamCarrierList, carrierIndex, updatedCarrier);
                        this.$modal.hide();
                    }
                });
        },
        async toggleStatus(carrier) {
            let api = '';
            let successMsg = '';
            if (carrier.status === 'Active') {
                api = `/api/teams/carriers/${carrier.teamCarrierId}/deactivate`;
                successMsg = 'Carrier has been deactivated. You can no longer assign shipments to this carrier';
            } else {
                api = `/api/teams/carriers/${carrier.teamCarrierId}/activate`;
                successMsg = 'Carrier has been activated. You can now start assigning shipments to this carrier.';
            }

            const payload = {
                method: 'post'
            };

            try {
                this.$_handleLoaderState(true);
                await handleRequests(api, payload);

                this.$notify({
                    message: successMsg,
                    type: 'success'
                });

                // update list
                const updatedCarrierIndex = this.teamCarrierList.findIndex(
                    (x) => x.teamCarrierId === carrier.teamCarrierId
                );
                const updatedCarrier = this.teamCarrierList.find((x) => x.teamCarrierId === carrier.teamCarrierId);
                updatedCarrier.status = carrier.status === 'Active' ? 'Inactive' : 'Active';
                this.teamCarrierList[updatedCarrierIndex] = updatedCarrier;
                this.$_handleLoaderState(false);
                this.FETCH_TEAM_CARRIERS();
            } catch (e) {
                const message = 'Error updating carrier.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            } finally {
                this.isListLoading = false;
                this.$_handleLoaderState(false);
            }
        },
        showAccessKeys(item) {
            if (item.carrierTeam.carrierSettings == null) 
                return false;

            return (
                item.carrierTeam.carrierSettings.carrierType === 'External' &&
                item.carrierTeam.carrierSettings.carrierCode !== 'Uber'
            );
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .error-body div {
    margin: 0;
    margin-top: 20px;
    padding: 10px;
}

.dashboard ::v-deep .md-card {
    margin-bottom: 0;
}

::v-deep .profile-image {
    border-radius: 5px;
    height: 42px;
    width: initial;
    max-width: 100%;
}

.header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

// .md-table-head.end {
//     text-align: right;
// }

// ::v-deep .md-table-cell.action-buttons {
//     padding-right: 30px;
// }

.search--container {
    margin-top: 6px;
}

.custom-paginated-table .action-buttons {
    width: 200px;
}

.custom-toolbar {
    margin-top: -15px;
}

.warning-yellow-icon {
    color: #ff9800 !important;
}

.icon-warning-column {
    width: 40px;
}

.setup-link {
    margin-left: 2px;
}
</style>
