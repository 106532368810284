var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("Assign Team Member to Asset Group " + _vm._s(_vm.groupName)),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          {
            staticClass: "form-wrapper",
            attrs: { validator: _vm.$v.assignMemberVal },
          },
          [
            _c("team-region-member-controls", {
              attrs: {
                "team-region-id": _vm.teamRegionId,
                "public-user-id": _vm.form.assignedUser,
                mode: "update",
                "show-team-region": false,
                "show-child-team-region": true,
              },
              on: { onChanged: _vm.handleTeamRegionMemberChanged },
            }),
          ],
          1
        ),
        _vm.checkUserAssetGroupStatus === "idle" &&
        _vm.existingUserAssetGroup &&
        _vm.existingUserAssetGroup.userOwnsAssetGroup &&
        _vm.existingUserAssetGroup.assetGroupId !== _vm.assetGroupId
          ? _c(
              "div",
              { staticClass: "modal-body--warning-section" },
              [
                _c("md-icon", { staticClass: "icon" }, [_vm._v("warning")]),
                _c("span", { staticClass: "modal-body--warning-text" }, [
                  _vm._v(
                    "\n                This team member is already assigned to\n                "
                  ),
                  _c("b", [
                    _vm._v(_vm._s(_vm.existingUserAssetGroup.groupName)),
                  ]),
                  _vm._v(
                    "\n                , assigning them to a new asset will remove them from the previous asset.\n            "
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            attrs: { disabled: _vm.checkUserAssetGroupStatus === "pending" },
            on: { click: _vm.assignMember },
          },
          [_vm._v("\n            Assign\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }