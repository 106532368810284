/**
 * @typedef {Object} Locate2uMarker
 * @property {string} type
 * @property {string} icon
 */
import { ICON_NAMES } from './constants';

import { useDefaultMarkerStyles } from './defaults';

export class MarkerGenerator {
    constructor() {
        // bind method constructor to class
        this.defaultMarkerOptions = {
            anchor: 'bottom'
        };
        this.defaultMarkerStyles = useDefaultMarkerStyles();
        this.generatePinUserMarker.bind(this);
        this.generatePinStopMarker.bind(this);
        this.generateDestinationMarker.bind(this);
        this.generateDriverMarker.bind(this);
        this.generateShipmentPickupMarker.bind(this);
        this.generateShipmentDropMarker.bind(this);
    }

    /**
     * @param {string} type
     * @param {Object?} options
     * @returns {mapboxgl.Marker}
     */
    generateMarker(type, options) {
        switch (type) {
            case ICON_NAMES.DRIVER:
                return this.generateDriverMarker(options);
            case ICON_NAMES.DESTINATION:
                return this.generateDestinationMarker(options);
            case ICON_NAMES.PIN_STOP:
                return this.generatePinStopMarker(options);
            case ICON_NAMES.PIN_USER:
                return this.generatePinUserMarker(options);
            case ICON_NAMES.SHIPMENT_PICKUP:
                return this.generateShipmentPickupMarker(options);
            case ICON_NAMES.SHIPMENT_DROP:
                return this.generateShipmentDropMarker(options);
            default:
                return this.generatePinStopMarker(options);
        }
    }

    generateDriverMarker(options) {
        const { style = {} } = options;
        const markerElement = document.createElement('div');
        const markerStyles = {
            ...this.defaultMarkerStyles,
            'background-image': "url('/img/Van.png')",
            width: '50px',
            height: '50px',
            ...style
        };
        // markerElement.className = 'marker pin-driver-marker';
        const styles = Object.entries(markerStyles)
            .map(([key, value]) => `${key}:${value}`)
            .join(';');
        markerElement.style = styles;
        return new mapboxgl.Marker({
            ...this.defaultMarkerOptions,
            element: markerElement,
            scale: {}
        });
    }

    generateDestinationMarker(options) {
        const { style = {} } = options;
        const markerElement = document.createElement('div');
        const markerStyles = {
            ...this.defaultMarkerStyles,
            'background-image': "url('/img/pin-destination.png')",
            width: '34px',
            height: '38px',
            ...style
        };
        const styles = Object.entries(markerStyles)
            .map(([key, value]) => `${key}:${value}`)
            .join(';');
        markerElement.style = styles;
        return new mapboxgl.Marker({
            ...this.defaultMarkerOptions,
            element: markerElement
        });
    }

    generatePinStopMarker(options) {
        const { style = {} } = options;
        const markerElement = document.createElement('div');
        const markerStyles = {
            ...this.defaultMarkerStyles,
            'background-image': "url('/img/pin-stops.png')",
            width: '34px',
            height: '38px',
            ...style
        };
        const styles = Object.entries(markerStyles)
            .map(([key, value]) => `${key}:${value}`)
            .join(';');
        markerElement.style = styles;
        return new mapboxgl.Marker({
            ...this.defaultMarkerOptions,
            element: markerElement
        });
    }

    generatePinUserMarker(options) {
        const { style = {} } = options;
        const markerElement = document.createElement('div');
        const markerStyles = {
            ...this.defaultMarkerStyles,
            'background-image': "url('/img/pin-user.png')",
            width: '32px',
            height: '32px',
            ...style
        };
        const styles = Object.entries(markerStyles)
            .map(([key, value]) => `${key}:${value}`)
            .join(';');
        markerElement.style = styles;
        return new mapboxgl.Marker({
            ...this.defaultMarkerOptions,
            element: markerElement
        });
    }

    generateShipmentPickupMarker(options) {
        const { style = {} } = options;
        const markerElement = document.createElement('div');
        const markerStyles = {
            ...this.defaultMarkerStyles,
            'background-image': "url('/img/pick-up.png')",
            width: '35px',
            height: '48px',
            ...style
        };
        const styles = Object.entries(markerStyles)
            .map(([key, value]) => `${key}:${value}`)
            .join(';');
        markerElement.style = styles;
        return new mapboxgl.Marker({
            ...this.defaultMarkerOptions,
            element: markerElement
        });
    }

    generateShipmentDropMarker(options) {
        const { style = {} } = options;
        const markerElement = document.createElement('div');

        const markerStyles = {
            ...this.defaultMarkerStyles,
            'background-image': "url('/img/drop-off.png')",
            width: '35px',
            height: '48px',
            ...style
        };
        const styles = Object.entries(markerStyles)
            .map(([key, value]) => `${key}:${value}`)
            .join(';');
        markerElement.style = styles;

        return new mapboxgl.Marker({
            ...this.defaultMarkerOptions,
            element: markerElement
        });
    }
}
