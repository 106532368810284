<template>
    <div :style="style" class="colour-viewer"></div>
</template>
<script>
export default {
    name: 'ColorViewer',
    props: {
        color: {
            type: String,
            default: ''
        }
    },
    computed: {
        style() {
            return `background-color: ${this.color};`;
        }
    }
};
</script>
<style lang="scss" scoped>
.colour-viewer {
    height: 100%;
    width: 100%;
}
</style>
