<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Assign Team Member to Asset Group {{ groupName }}</h4>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.assignMemberVal" class="form-wrapper">
                <team-region-member-controls
                    :team-region-id="teamRegionId"
                    :public-user-id="form.assignedUser"
                    @onChanged="handleTeamRegionMemberChanged"
                    mode="update"
                    :show-team-region="false"
                    :show-child-team-region="true"
                />
            </form-wrapper>
            <div
                v-if="
                    checkUserAssetGroupStatus === 'idle' &&
                        existingUserAssetGroup &&
                        existingUserAssetGroup.userOwnsAssetGroup &&
                        existingUserAssetGroup.assetGroupId !== assetGroupId
                "
                class="modal-body--warning-section"
            >
                <md-icon class="icon">warning</md-icon>
                <span class="modal-body--warning-text">
                    This team member is already assigned to
                    <b>{{ existingUserAssetGroup.groupName }}</b>
                    , assigning them to a new asset will remove them from the previous asset.
                </span>
            </div>
        </div>
        <div class="modal-footer">
            <md-button
                class="md-primary dialog-button"
                :disabled="checkUserAssetGroupStatus === 'pending'"
                @click="assignMember"
            >
                Assign
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { required } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { TeamRegionMemberControls } from '@/components';
import { USER_ASSET_GROUP_DEFAULTS } from '@/utils/defaults';

export default {
    name: 'AssignToAssetGroupModal',
    mixins: [GeneralMixin],
    components: { TeamRegionMemberControls },
    props: {
        teamMembers: {
            type: Array,
            default: () => []
        },
        assetGroupId: {
            type: Number,
            default: null
        },
        ownedBy: {
            type: String,
            default: ''
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        groupName: {
            type: String,
            default: null
        },
        teamRegionId: {
            type: Number,
            default: null
        }
    },
    validations: {
        assignMemberVal: {
            assignedUser: {
                required
            }
        }
    },
    data() {
        return {
            form: {
                assignedUser: 0
            },
            /**
             * @description Existing user asset group
             */
            existingUserAssetGroup: USER_ASSET_GROUP_DEFAULTS(),
            checkUserAssetGroupStatus: 'idle'
        };
    },
    mounted() {
        this.form.assignedUser = this.ownedBy;
    },
    methods: {
        async assignMember() {
            this.$_handleLoaderState(true, 'UPDATING...');
            const payload = {
                method: 'post',
                data: {
                    assignToPublicUserId: this.form.assignedUser === 0 ? null : this.form.assignedUser
                }
            };
            try {
                const api = `/api/asset-groups/${this.assetGroupId}/assign`;
                const {
                    data: { assignedTo, assetGroupId, teamRegionId }
                } = await handleRequests(api, payload);
                let msg = 'Asset Group was un-assigned.';
                if (assignedTo && assignedTo.fullName) {
                    msg = `Asset Group was assigned to ${assignedTo.fullName} !`;
                }
                this.$notify({
                    message: msg,
                    type: 'success'
                });
                this.$v.$reset();
                this.resolve({ assignedTo, assetGroupId, teamRegionId });
            } catch (e) {
                const message = 'Cannot assign asset group to user.';
                showErrorMessage(this, message, e);
                this.resolve(false);
            }
        },
        /**
         *
         * @param {string} publicUserId
         * @returns {Promise<*|boolean>}
         */
        async checkIfUserAlreadyHasAssetGroup(publicUserId) {
            // val.publicUserId may be null
            if (!publicUserId) {
                this.existingUserAssetGroup = USER_ASSET_GROUP_DEFAULTS();
                return;
            }
            const api = `/api/users/${publicUserId}/asset-group`;
            this.checkUserAssetGroupStatus = 'pending';
            try {
                const { data } = await handleRequests(api);
                this.existingUserAssetGroup = data;
                this.checkUserAssetGroupStatus = 'idle';
            } catch (e) {
                this.existingUserAssetGroup = USER_ASSET_GROUP_DEFAULTS();
                this.checkUserAssetGroupStatus = 'idle';
            }
        },
        async handleTeamRegionMemberChanged(val) {
            this.form.assignedUser = val.publicUserId;
            await this.checkIfUserAlreadyHasAssetGroup(val.publicUserId);
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 500px;
}

.modal-body {
    &--warning-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        & .icon {
            color: #ff9800;
        }
    }
    &--warning-text {
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
    }
}
</style>
