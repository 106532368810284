var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c(
            "form-group",
            { attrs: { name: "groupName", label: "Asset Group Name" } },
            [
              _c(
                "div",
                [
                  _c("md-input", {
                    directives: [{ name: "focus", rawName: "v-focus" }],
                    model: {
                      value: _vm.assetGroupDetails.groupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.assetGroupDetails, "groupName", $$v)
                      },
                      expression: "assetGroupDetails.groupName",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-100" },
        [
          _c(
            "form-group",
            { attrs: { name: "notes", label: "Asset Group Notes" } },
            [
              _c("md-input", {
                model: {
                  value: _vm.assetGroupDetails.notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.assetGroupDetails, "notes", $$v)
                  },
                  expression: "assetGroupDetails.notes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }