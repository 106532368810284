var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c("vue-select", {
          attrs: {
            label: "fullName",
            options: _vm.teamMembers,
            searchable: _vm.$root.isDesktop,
          },
          model: {
            value: _vm.selectedTeamMember,
            callback: function ($$v) {
              _vm.selectedTeamMember = $$v
            },
            expression: "selectedTeamMember",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            attrs: { disabled: !_vm.selectedTeamMember },
            on: { click: _vm.selectTeamMember },
          },
          [_vm._v("\n            Select\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Select Member")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }