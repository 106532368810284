<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Action</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.form" class="form-wrapper">
                <form-group label="Event Actions" name="key">
                    <md-select v-model="form.triggerGroupEvent">
                        <md-option v-for="event in triggerEventOptions" :key="event.key" :value="event.key">
                            {{ event.triggerGroupEvent }}
                        </md-option>
                    </md-select>
                </form-group>
                <form-group label="Travel distance" name="enteredTravelDistanceValue">
                    <md-input v-model="form.enteredTravelDistanceValue" />
                    <md-select v-model="form.enteredTravelDistanceUnits">
                        <md-option value="km">km</md-option>
                        <md-option value="miles">miles</md-option>
                        <md-option value="meters">meters</md-option>
                        <md-option value="feet">feet</md-option>
                    </md-select>
                </form-group>
            </form-wrapper>
            <br />
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="saveGeofenceEventSetting">
                Save
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';
import { GEOFENCE_GROUP_EVENT_TRIGGER_CONSTANTS, GEOFENCE_EVENT_ACTIONS_CONSTANTS } from '@/utils/constants';

import { mapGetters } from 'vuex';

export default {
    name: 'GeofenceEventsSettingModal',
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    props: {
        origGeofenceEvent: {
            type: Object,
            default: () => {}
        },
        level: {
            type: String,
            default: ''
        },
        unit: {
            type: String,
            default: 'meters'
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    mounted() {
        this.form.geofenceEventId = this.origGeofenceEvent.geofenceEventId || 0;
        this.form.triggerGroupEvent = this.getTriggerGroupEvent(this.origGeofenceEvent);
        this.form.enteredTravelDistanceValue = this.origGeofenceEvent.enteredTravelDistanceValue;

        if (this.origGeofenceEvent.enteredTravelDistanceUnits) {
            this.form.enteredTravelDistanceUnits = this.origGeofenceEvent.enteredTravelDistanceUnits || 'meters';
        } else {
            this.form.enteredTravelDistanceUnits = this.user.speedUnits === 'kph' ? 'km' : 'miles';
        }
    },
    data() {
        return {
            form: {
                triggerGroupEvent: '',
                enteredTravelDistanceValue: 100,
                enteredTravelDistanceUnits: 'meters',
                geofenceEventId: 0
            },
            triggerEventOptions: [...GEOFENCE_GROUP_EVENT_TRIGGER_CONSTANTS],
            actionOptions: [...GEOFENCE_EVENT_ACTIONS_CONSTANTS]
        };
    },
    methods: {
        getTriggerGroupEvent(geofenceEvent) {
            if (geofenceEvent.geofenceEventActions) {
                const eventAction = geofenceEvent.geofenceEventActions[0];
                switch (eventAction.action) {
                    case 'Change-Stop-Status':
                        switch (eventAction.toValue) {
                            case 'Enroute':
                                return 'EnrouteToStop';
                            case 'Arrived':
                                return 'ArrivingAtStop';
                            case 'Complete':
                                return 'LeavingStop';
                            default:
                                break;
                        }
                        break;
                    case 'Change-Trip-Status':
                        switch (eventAction.toValue) {
                            case 'Started':
                                return 'StartingTrip';
                            case 'Complete':
                                return 'EndingTrip';
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }
            return '';
        },
        async saveGeofenceEventSetting() {
            this.$_handleLoaderState(true, 'SAVING...');

            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                let data = Object.assign({}, this.form);

                let method = 'put';
                let api = `/api/geofence/stop-events`;
                const { eventObj } = Object.assign(
                    {},
                    this.actionOptions.find((x) => x.key === this.form.triggerGroupEvent)
                );
                data = eventObj;
                if (
                    this.form.geofenceEventId === 0 ||
                    this.form.geofenceEventId === '0' ||
                    !this.form.geofenceEventId
                ) {
                    method = 'post';
                } else {
                    api += `/${data.geofenceEventId}`;
                }

                data.enteredTravelDistanceValue = parseInt(this.form.enteredTravelDistanceValue, 10);
                data.enteredTravelDistanceUnits = this.form.enteredTravelDistanceUnits;
                data.teamId = this.origGeofenceEvent.teamId;
                data.geofenceEventId = this.form.geofenceEventId;

                const payload = {
                    method,
                    data
                };

                try {
                    await handleRequests(api, payload);
                    this.$notify({
                        message: 'Geofence event setting saved!',
                        type: 'success'
                    });
                } catch (e) {
                    const message = 'Cannot save geofence event Setting.';
                    showErrorMessage(this, message, e);
                }
                this.resolve(data);
            }

            this.$_handleLoaderState(false);
        }
    },
    validations: {
        form: {
            triggerGroupEvent: {
                required
            },
            enteredTravelDistanceValue: {
                required
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 620px;
}

.modal-body {
    padding-bottom: 0;
    padding-top: 10px;
}
</style>
