var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _c("div", { staticClass: "md-layout-item" }, [
      _c("div", { staticClass: "custom-toolbar md-layout" }, [
        _c("div", { staticClass: "custom-toolbar-start" }, [
          _c(
            "div",
            { staticClass: "filter-steps--container" },
            [
              !_vm.isReadOnlyUser
                ? _c(
                    "md-field",
                    {
                      staticClass: "customer-filter",
                      attrs: { "md-clearable": "" },
                      on: { "md-clear": _vm.handleClear },
                    },
                    [
                      _c("customer-autocomplete", {
                        ref: "customerAutocomplete",
                        attrs: {
                          label: "Customer",
                          id: "autocustomer",
                          placeholder: "",
                          "should-focus": false,
                          "auto-fill-address": true,
                          "is-clearable": true,
                        },
                        on: { input: _vm.onCustomerChanged },
                        model: {
                          value: _vm.customer,
                          callback: function ($$v) {
                            _vm.customer = $$v
                          },
                          expression: "customer",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "md-field",
                { staticClass: "status-filter" },
                [
                  _vm.statuses
                    ? _c("vue-select", {
                        attrs: {
                          options: _vm.statuses,
                          label: "statusValue",
                          reduce: function (stat) {
                            return stat.statusId
                          },
                          placeholder: "Status",
                          searchable: _vm.$root.isDesktop,
                        },
                        model: {
                          value: _vm.statusFilterValue,
                          callback: function ($$v) {
                            _vm.statusFilterValue = $$v
                          },
                          expression: "statusFilterValue",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "md-field",
                [
                  _c("md-datepicker", {
                    ref: "mdDatePicker",
                    staticClass:
                      "filter-steps--choices filter-step-date start-date",
                    attrs: { "md-immediately": "", "md-debounce": 100 },
                    model: {
                      value: _vm.invoiceDateFrom,
                      callback: function ($$v) {
                        _vm.invoiceDateFrom = $$v
                      },
                      expression: "invoiceDateFrom",
                    },
                  }),
                ],
                1
              ),
              _c(
                "md-field",
                [
                  _c("md-datepicker", {
                    ref: "mdDatePicker",
                    staticClass:
                      "filter-steps--choices filter-step-date end-date",
                    attrs: { "md-immediately": "", "md-debounce": 100 },
                    model: {
                      value: _vm.invoiceDateTo,
                      callback: function ($$v) {
                        _vm.invoiceDateTo = $$v
                      },
                      expression: "invoiceDateTo",
                    },
                  }),
                ],
                1
              ),
              !_vm.isReadOnlyUser &&
              _vm.batchFilterList != null &&
              _vm.batchFilterList.length > 0
                ? _c(
                    "md-field",
                    { staticClass: "status-filter batch-filter" },
                    [
                      _c("vue-select", {
                        attrs: {
                          options: _vm.batchFilterList,
                          label: "label",
                          reduce: function (x) {
                            return x.id
                          },
                          placeholder: "Batch Filter",
                          searchable: _vm.$root.isDesktop,
                          clearable: true,
                        },
                        model: {
                          value: _vm.batchFilterValue,
                          callback: function ($$v) {
                            _vm.batchFilterValue = $$v
                          },
                          expression: "batchFilterValue",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "custom-toolbar-end" },
          [
            _c("search-component", {
              ref: "keyword",
              attrs: { placeholder: "Search" },
              on: { onSearch: _vm.handleKeyword },
            }),
            !_vm.isReadOnlyUser
              ? _c(
                  "md-button",
                  {
                    staticClass:
                      "md-primary md-just-icon md-round pull-right header-button",
                    attrs: { title: "Generate Invoices" },
                    on: { click: _vm.createInvoice },
                  },
                  [_c("md-icon", [_vm._v("library_add")])],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              [
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-primary md-just-icon md-round pull-right header-button",
                    attrs: { title: "Export Invoices" },
                    on: { click: _vm.exportInvoices },
                  },
                  [_c("md-icon", [_vm._v("get_app")])],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }