<template>
    <div class="sync-button">
        <div class="sync-loader" v-if="invoice.isSyncing" :title="invoice.retryTooltip">
            <straight-line-loader />
        </div>

        <md-button
            v-else
            :title="buttonTitle"
            class="md-just-icon md-round"
            :class="buttonColor"
            @click.stop="
                buttonColor == 'md-danger'
                    ? showError(invoice)
                    : buttonColor == 'md-success'
                    ? handleSyncedInvoice
                    : handleSyncInvoice(invoice)
            "
        >
            <md-icon>{{ buttonIcon }}</md-icon>
        </md-button>
    </div>
</template>

<script>
import { StraightLineLoader } from '@/components';
import { handleRequests } from '@/helpers';
import { InvoiceErrorMessageModal } from '../components';

export default {
    name: 'SyncInvoiceButton',
    components: {
        StraightLineLoader
    },
    props: {
        invoice: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        buttonColor() {
            if (this.invoice.accountingSystemRef != null) {
                return 'md-success';
            }

            if (this.invoice.accountingSystemNote != null && this.invoice.accountingSystemNote !== 'OK') {
                return 'md-danger';
            }

            return 'md-warning';
        },
        buttonTitle() {
            if (this.invoice.accountingSystemRef != null) {
                return `Invoice has successfully been sent to ${this.invoice.accountingSystemName}`;
            }

            if (this.invoice.accountingSystemNote != null && this.invoice.accountingSystemNote !== 'OK') {
                return `Error with syncing ${this.invoice.accountingSystemName}`;
            }

            return `Send invoice to accounting package`;
        },
        buttonIcon() {
            if (this.invoice.accountingSystemRef != null) {
                return 'done';
            }

            if (this.invoice.accountingSystemNote != null && this.invoice.accountingSystemNote !== 'OK') {
                return 'priority_high';
            }

            return 'send';
        }
    },
    methods: {
        handleSyncedInvoice() {
            // do noting for synced invoice
        },
        async handleSyncInvoice(item) {
            if (item.isSyncing === undefined) {
                this.$set(item, 'isSyncing', true);
            } else {
                item.isSyncing = true;
            }

            try {
                const endpoint = `/api/team-member-invoices/send-to-accounting`;
                const data = {
                    invoiceIdList: [item.teamMemberInvoiceId]
                };
                const payload = {
                    method: 'post',
                    data
                };
                await handleRequests(endpoint, payload);
            } catch (error) {
                item.isSyncing = false;
            }
        },
        showError(invoice) {
            this.$modal.show(InvoiceErrorMessageModal, {
                invoice
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.sync-loader {
    display: inline-block;
    background-color: #2b93ff;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    svg {
        margin-top: 3px;
        margin-left: 2px;
    }
}

.md-success {
    cursor: default;
}

.sync-button {
    display: inline-block;
}
</style>
