var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("md-table", {
        staticClass: "md-table-global-sales",
        scopedSlots: _vm._u([
          {
            key: "md-table-row",
            fn: function (ref) {
              var item = ref.item
              return _c(
                "md-table-row",
                {},
                [
                  _c("md-table-cell", { attrs: { "md-label": "" } }, [
                    _c("div", { staticClass: "flag" }, [
                      _c("img", { attrs: { src: item.flag } }),
                    ]),
                  ]),
                  _c("md-table-cell", [_vm._v(_vm._s(item.country))]),
                  _c("md-table-cell", [_vm._v(_vm._s(item.sales))]),
                  _c("md-table-cell", [_vm._v(_vm._s(item.percent))]),
                ],
                1
              )
            },
          },
        ]),
        model: {
          value: _vm.users,
          callback: function ($$v) {
            _vm.users = $$v
          },
          expression: "users",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }