var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedView == "fixAddress"
    ? _c(
        "div",
        [_c("fix-address-view", { on: { selectedView: _vm.handleView } })],
        1
      )
    : _c("div", [_c("stop-list", { on: { selectedView: _vm.handleView } })], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }