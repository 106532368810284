var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.isUpdate ? "Update" : "Create") + " Run Stop"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body stop-form-modal" },
      [
        _c(
          "form-wrapper",
          {
            staticClass: "form-wrapper",
            attrs: { validator: _vm.$v.runStopDetails },
          },
          [
            _c(
              "div",
              { staticClass: "stop-form-container" },
              [
                !_vm.runStopDetails.shipmentId
                  ? _c("charging-type-options", {
                      attrs: {
                        "selected-charge-type-id":
                          _vm.runStopDetails.rateGroupId,
                      },
                      on: { selectedOption: _vm.handleRateTypeChanged },
                    })
                  : _vm._e(),
                !_vm.runStopDetails.shipmentId
                  ? _c(
                      "div",
                      [
                        _c(
                          "form-group",
                          { attrs: { name: "type", label: "Type" } },
                          [
                            _c("md-input", {
                              attrs: { type: "hidden" },
                              model: {
                                value: _vm.runStopDetails.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.runStopDetails, "type", $$v)
                                },
                                expression: "runStopDetails.type",
                              },
                            }),
                            _c("vue-select", {
                              attrs: {
                                options: _vm.stopTypes,
                                reduce: function (x) {
                                  return x.type
                                },
                                label: "key",
                              },
                              model: {
                                value: _vm.runStopDetails.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.runStopDetails, "type", $$v)
                                },
                                expression: "runStopDetails.type",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "contact-details" },
                  [
                    _c("span", { staticClass: "cd-title" }, [
                      _vm._v("Customer Details"),
                    ]),
                    _c("customer-autocomplete", {
                      attrs: {
                        label:
                          _vm.runStopDetails.customerId == null
                            ? "Name"
                            : "Customer",
                        id: "autocustomer",
                        placeholder: "",
                        "should-focus": false,
                        "auto-fill-address": true,
                        "update-customer": !_vm.isUpdate,
                        disabled: _vm.customerNameIsDisabled(),
                      },
                      on: {
                        changeCustomerRateGroup:
                          _vm.onCustomerRateGroupIdChange,
                      },
                      model: {
                        value: _vm.runStopDetails,
                        callback: function ($$v) {
                          _vm.runStopDetails = $$v
                        },
                        expression: "runStopDetails",
                      },
                    }),
                    _c(
                      "div",
                      [
                        _c("google-autocomplete", {
                          ref: "address",
                          attrs: {
                            label: "Destination",
                            id: "autoaddress",
                            classname: "form-control autocomplete-input",
                            placeholder: "",
                            "should-focus": false,
                            "disable-gps-coordinates": false,
                          },
                          on: { handleCoordinates: _vm.handleCoordinates },
                          model: {
                            value: _vm.runStopDetails,
                            callback: function ($$v) {
                              _vm.runStopDetails = $$v
                            },
                            expression: "runStopDetails",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.runStopDetails.customerId != null
                      ? _c(
                          "form-group",
                          { attrs: { name: "name", label: "Contact Name" } },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.runStopDetails.contact.name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.runStopDetails.contact,
                                    "name",
                                    $$v
                                  )
                                },
                                expression: "runStopDetails.contact.name",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "form-group",
                      { attrs: { name: "phone", label: "Phone" } },
                      [
                        _c("md-input", {
                          model: {
                            value: _vm.runStopDetails.contact.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.runStopDetails.contact, "phone", $$v)
                            },
                            expression: "runStopDetails.contact.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "form-group",
                      { attrs: { name: "email", label: "Email" } },
                      [
                        _c("md-input", {
                          model: {
                            value: _vm.runStopDetails.contact.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.runStopDetails.contact, "email", $$v)
                            },
                            expression: "runStopDetails.contact.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.loadFieldList !== null
                      ? _c(
                          "div",
                          _vm._l(_vm.loadFieldList, function (load, index) {
                            return _c("load-inputs", {
                              key: index,
                              attrs: {
                                "load-definition": load,
                                "initial-value": _vm.loadValues,
                              },
                              on: { changed: _vm.handleLoadChanged },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm.skillOptions.length
                      ? _c(
                          "form-group",
                          { attrs: { name: "skills", label: "Skills" } },
                          [
                            _c("multiselect", {
                              attrs: {
                                options: _vm.skillOptions,
                                multiple: true,
                                "close-on-select": true,
                                placeholder: "Pick required skills",
                              },
                              model: {
                                value: _vm.runStopDetails.skills,
                                callback: function ($$v) {
                                  _vm.$set(_vm.runStopDetails, "skills", $$v)
                                },
                                expression: "runStopDetails.skills",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "form-group",
                      { attrs: { name: "notes", label: "Notes" } },
                      [
                        _c("md-textarea", {
                          model: {
                            value: _vm.runStopDetails.notes,
                            callback: function ($$v) {
                              _vm.$set(_vm.runStopDetails, "notes", $$v)
                            },
                            expression: "runStopDetails.notes",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.stopCustomFieldList !== null
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.stopCustomFieldList,
                            function (customField, index) {
                              return _c("custom-field-inputs", {
                                key: index,
                                attrs: {
                                  "custom-field-definition": customField,
                                  "initial-value":
                                    _vm.customFieldValues[customField.name],
                                },
                                on: { changed: _vm.handleCustomFieldChanged },
                              })
                            }
                          ),
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "stop-datepicker" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "form-group",
                            {
                              staticClass: "time-picker",
                              attrs: { name: "time", label: "Time" },
                            },
                            [
                              _c("time-picker", {
                                attrs: {
                                  time: _vm.time,
                                  "additional-time-options":
                                    _vm.additionalTimeOption,
                                },
                                on: { selectedTime: _vm.getTime },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "duration-container" },
                        [
                          _c(
                            "form-group",
                            {
                              attrs: {
                                name: "durationMinutes",
                                label: "Duration (mins)",
                              },
                            },
                            [
                              _c("md-input", {
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.runStopDetails.durationMinutes,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.runStopDetails,
                                      "durationMinutes",
                                      $$v
                                    )
                                  },
                                  expression: "runStopDetails.durationMinutes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.validateRunStop },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isUpdate ? "Update" : "Create") +
                "\n        "
            ),
          ]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleHideModal($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }