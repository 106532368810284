<template>
    <div class="filter-steps--container">
        <team-region-member-filter-options
            v-if="canViewTeamRegion || isCustomerAdmin"
            :team-region-id="teamRegionId"
            :team-member-public-userid="teamMemberFilterValue"
            @changed="handleTeamRegionMemberChanged"
            :strict-team-region-filter="false"
            :clearable="false"
            :show-carriers="true"
        ></team-region-member-filter-options>
        <md-field v-if="hasCustomerFilterAccess">
            <customer-filter-options
                class="filter-steps--choices filter-status"
                @onCustomerSelected="handleCustomerChanged"
                :filter-customer-id="customerIdFilterValue"
            />
        </md-field>
        <md-field class="status-filter">
            <vue-select
                v-if="statuses"
                :options="statuses"
                placeholder="Status"
                v-model="statusFilterValue"
                :searchable="$root.isDesktop"
            ></vue-select>
        </md-field>
        <md-field>
            <md-datepicker
                class="filter-steps--choices filter-step-date start-date"
                v-model="fromDate"
                md-immediately
                :md-debounce="100"
            ></md-datepicker>
        </md-field>
        <md-field>
            <md-datepicker
                class="filter-steps--choices filter-step-date end-date"
                v-model="toDate"
                md-immediately
                :md-debounce="100"
            ></md-datepicker>
        </md-field>
        <md-field class="time-picker md-theme-default" v-if="!isGeocodedView">
            <time-picker
                :key="componentKey"
                :time="selectedTime"
                @selectedTime="getTimeWindow"
                :is-appointment-time-included="false"
                :additional-time-options="additionalTimeWindowOptions"
                :is-use-in-filter="true"
                @customTimeWindowModal="showCustomTimeWindowModal"
            />
        </md-field>
        <md-checkbox type="checkbox" v-model="showBlankStopDateFirst" @change="handleShowBlankStopDateFirst">
            Show stops with no date first
        </md-checkbox>
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins';
import { STATUS_CONSTANTS, DATE_TYPES, TIME_WINDOW_CONSTANTS, DRIVER_RATING_SCORE_CONSTANTS } from '@/utils/constants';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import {
    TimePicker,
    TimePickerCustomTimeWindowModal,
    TeamRegionMemberFilterOptions,
    CustomerFilterOptions
} from '@/components';
import moment from 'moment';
import { titleCase } from '@/helpers';

export default {
    name: 'FilterComponent',
    components: { TimePicker, TeamRegionMemberFilterOptions, CustomerFilterOptions },
    props: {
        teamRegionId: { type: Number, default: null },
        teamMembers: {
            type: Function,
            default: () => []
        },
        timeWindowFilterOptions: {
            type: Array,
            default: () => []
        },
        isGeocodedView: {
            type: Boolean,
            default: false
        },
        filterValue: {
            type: Object,
            default: null
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {
            statuses: ['Active', ...STATUS_CONSTANTS, 'All'],
            statusFilterValue: 'Active',
            teamMemberFilterValue: '',
            carrierTeamIdFilterValue: null,
            timeFilterValue: { appointmentTime: null, timeWindowStart: null, timeWindowEnd: null },
            teamRegionFilterValue: '',
            selectedTeamRegionId: null,
            additionalTimeWindowOptions: [],
            componentKey: 0,
            selectedTime: null,
            customerIdFilterValue: null,
            fromDate: null,
            toDate: null,
            showBlankStopDateFirst: false,
            ratingScoreFilter: [...DRIVER_RATING_SCORE_CONSTANTS],
            driverRatingValue: null
        };
    },
    computed: {
        ...mapGetters({
            teamRegions: 'team/teamRegions',
            user: 'user/user',
            canEditOwnTeamRegion: 'user/canEditOwnTeamRegion',
            canViewTeamRegion: 'user/canViewTeamRegion',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isReadOnlyUser: 'user/isReadOnlyUser',
            hasCustomerFilterAccess: 'user/hasInventoryListAccess',
            isCustomerAdmin: 'user/isCustomerAdmin'
        })
    },
    mounted() {
        if (!this.$root.isDesktop) {
            if (this.$refs.mdDatePicker) 
                this.$refs.mdDatePicker.$refs.input.$el.setAttribute('readonly', true);
        }

        if (localStorage.getItem('stops.defaults.time-filter-options') !== null) {
            const localStorageOptions = JSON.parse(localStorage.getItem('stops.defaults.time-filter-options'));

            if (localStorageOptions.length > 0) {
                localStorageOptions.forEach((timeWindowFilter) => {
                    this.additionalTimeWindowOptions.push(timeWindowFilter);
                });
            }
        }

        this.handleFilterSetValue();
    },
    watch: {
        statusFilterValue(newValue, oldValue) {
            this.handleFilter();
        },
        timeWindowFilterOptions() {
            if (this.timeWindowFilterOptions.length > 0) {
                this.timeWindowFilterOptions.forEach((timeWindowFilter) => {
                    if (!this.additionalTimeWindowOptions.find((x) => x.name === timeWindowFilter.name))
                        this.additionalTimeWindowOptions.push(timeWindowFilter);
                });
            }
            this.componentKey += 1;
        },
        filterValue() {
            this.handleFilterSetValue();
        },
        fromDate(newVal, oldVal) {
            if (
                moment(newVal).format(DATE_TYPES.internationalDate) ===
                moment(oldVal).format(DATE_TYPES.internationalDate)
            ) {
                return;
            }

            if (
                this.fromDate &&
                this.toDate &&
                moment(newVal).format(DATE_TYPES.internationalDate) >
                    moment(this.toDate).format(DATE_TYPES.internationalDate)
            ) {
                this.$notify({
                    message: 'From date can not be later than the to date.',
                    type: 'danger'
                });
                return;
            }

            this.handleFilter();
        },
        toDate(newVal, oldVal) {
            if (
                moment(newVal).format(DATE_TYPES.internationalDate) ===
                moment(oldVal).format(DATE_TYPES.internationalDate)
            ) {
                return;
            }

            if (
                this.fromDate &&
                this.toDate &&
                moment(newVal).format(DATE_TYPES.internationalDate) <
                    moment(this.fromDate).format(DATE_TYPES.internationalDate)
            ) {
                this.$notify({
                    message: 'To date can not be earlier than the from date.',
                    type: 'danger'
                });
                return;
            }

            this.handleFilter();
        },
        driverRatingValue(newValue) {
            this.handleFilter();
        }
    },
    methods: {
        // eslint-disable-next-line func-names
        handleFilter: _.debounce(function() {
            this.handleFilteredValue();
        }, 700),
        clearFilters() {
            this.statusFilterValue = '';
            this.teamMemberFilterValue = '';
            this.carrierTeamIdFilterValue = null;
            this.selectedTeamRegionId = null;
            this.confidenceValue = 1;
            this.selectedGeocodeTypes = [];
            this.customerIdFilterValue = null;
            this.fromDate = null;
            this.toDate = null;
            this.driverRatingValue = null;
        },
        handleCustomerChanged(value) {
            this.customerIdFilterValue = value;
            this.handleFilter();
        },
        handleFilteredValue() {
            // use nextTick to ensure that date values have already been changed.
            // this is because the @selected method of md-datepicker is asynchronous.
            this.$nextTick(() => {
                const { statusFilterValue, teamMemberFilterValue, timeFilterValue } = this;

                let fromDate = null;
                if (this.fromDate) {
                    fromDate = this.$options.filters.dateFormat(this.fromDate, this.DATE_TYPES.internationalDate);
                }

                let toDate = null;
                if (this.toDate) {
                    toDate = this.$options.filters.dateFormat(this.toDate, this.DATE_TYPES.internationalDate);
                }

                if (
                    statusFilterValue != null &&
                    !(statusFilterValue.toLowerCase() === 'complete' || statusFilterValue.toLowerCase() === 'all')
                ) {
                    this.driverRatingValue = null;
                }

                const eventArgs = {
                    status: statusFilterValue,
                    teamMemberPublicUserId: teamMemberFilterValue,
                    carrierTeamId: this.carrierTeamFilterValue,
                    timeWindowStart: timeFilterValue.timeWindowStart,
                    timeWindowEnd: timeFilterValue.timeWindowEnd,
                    teamRegionId: this.selectedTeamRegionId,
                    customerId: this.customerIdFilterValue,
                    fromDate,
                    toDate,
                    showBlankStopDateFirst: this.showBlankStopDateFirst,
                    driverRating: this.driverRatingValue
                };
                this.$emit('onFilterStops', eventArgs);
            });
        },
        getTimeWindow(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { timeWindowStart, timeWindowEnd, appointmentTime } = selectedTime;

                let starTime = timeWindowStart;
                let endTime = timeWindowEnd;

                if (timeWindowStart === '00:00' && timeWindowEnd === '23:59') {
                    starTime = null;
                    endTime = null;
                }

                if (appointmentTime != null) {
                    starTime = appointmentTime;
                    endTime = appointmentTime;
                }

                this.timeFilterValue = {
                    timeWindowStart: starTime,
                    timeWindowEnd: endTime
                };
            } else {
                this.timeFilterValue = {
                    timeWindowStart: null,
                    timeWindowEnd: null
                };
            }
            if (isTimeValid) 
                this.handleFilter();
        },
        showCustomTimeWindowModal(oldTime) {
            let time = oldTime;
            this.$modal.show(TimePickerCustomTimeWindowModal).then((response) => {
                if (response) {
                    const index = this.timeWindowFilterOptions.findIndex((x) => x.name === response.name);

                    if (index === -1) {
                        this.timeWindowFilterOptions.push(response);

                        let localStorageOptions = [];
                        if (localStorage.getItem('stops.defaults.time-filter-options') !== null) {
                            localStorageOptions = JSON.parse(
                                localStorage.getItem('stops.defaults.time-filter-options')
                            );
                            // Since we are only storing the last 5 recent time options we should remove the oldest element in the array.
                            if (localStorageOptions.length === 5) {
                                localStorageOptions.shift();
                            }
                        }
                        localStorageOptions.push(response);
                        localStorage.setItem('stops.defaults.time-filter-options', JSON.stringify(localStorageOptions));
                        this.$modal.hide();
                    }
                    time = response.name;
                }

                this.selectedTime = time;
                this.componentKey += 1;
            });
        },
        handleTeamRegionMemberChanged(val) {
            this.teamMemberFilterValue = val.type === 'Member' ? val.publicUserId : null;
            this.carrierTeamFilterValue = val.type === 'Carrier' ? val.carrierTeamId : null;
            this.selectedTeamRegionId = val.teamRegionId;
            this.handleFilter();
        },
        handleShowBlankStopDateFirst(value) {
            this.showBlankStopDateFirst = value;
            this.handleFilter();
        },
        handleTimeWindowValue() {
            let stopTime = null;

            const { timeWindowStart, timeWindowEnd } = this.filterValue;

            if (timeWindowStart != null && timeWindowEnd != null) {
                // since timeWindowStart and timeWindowEnd are returning values in military time without the date we need to add a temporary date
                // to convert the military time to standard time
                const timeWindowStartStandard = moment(`2024-01-01 ${timeWindowStart}`).format(DATE_TYPES.standardTime);
                const timeWindowEndStandard = moment(`2024-01-01 ${timeWindowEnd}`).format(DATE_TYPES.standardTime);
                let hasMatch = false;

                const allTimeWindowOptions = TIME_WINDOW_CONSTANTS.concat(this.additionalTimeWindowOptions);

                const matchedValue = allTimeWindowOptions.find(
                    (x) => timeWindowStart === x.timeWindowStart && timeWindowEnd === x.timeWindowEnd
                );
                if (matchedValue) {
                    stopTime = matchedValue.name;
                    hasMatch = true;
                }

                if (!hasMatch) {
                    stopTime = `${timeWindowStartStandard} - ${timeWindowEndStandard}`;
                    this.additionalTimeWindowOptions.push({
                        name: stopTime,
                        timeWindowStart,
                        timeWindowEnd
                    });
                }
            }

            this.selectedTime = stopTime;
        },
        handleFilterSetValue() {
            if (this.filterValue.fromDate) {
                this.fromDate = this.filterValue.fromDate;
            }

            if (this.filterValue.toDate) {
                this.toDate = this.filterValue.toDate;
            }

            if (this.filterValue.status) {
                this.statusFilterValue = titleCase(this.filterValue.status);
            }

            if (this.filterValue.teamMemberPublicUserId) {
                this.teamMemberFilterValue = this.filterValue.teamMemberPublicUserId;
            }

            if (this.filterValue.teamRegionId) {
                this.selectedTeamRegionId = Number(this.filterValue.teamRegionId);
            }

            if (this.filterValue.customerId) {
                this.customerIdFilterValue = Number(this.filterValue.customerId);
            }

            // query params converts the value to string
            if (this.filterValue.showBlankStopDateFirst === 'true') {
                this.showBlankStopDateFirst = Boolean(this.filterValue.showBlankStopDateFirst);
            }

            if (this.filterValue.driverRating) {
                this.driverRatingValue = Number(this.filterValue.driverRating);
            }

            this.handleTimeWindowValue();
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-steps--container {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }
    .filter-step-date {
        min-width: 250px;
        margin-right: 0;
        min-height: initial;
        position: absolute;
        z-index: 1;
    }

    .filter-status {
        min-width: 150px;
        margin-right: 0;
        min-height: initial;
        position: absolute;
    }

    .filter-button--container {
        display: inline-block;
    }
    ::v-deep .md-datepicker:after,
    ::v-deep .md-datepicker:before {
        bottom: 0;
        height: 0;
    }

    ::v-deep .md-datepicker:before,
    ::v-deep .md-datepicker:after {
        bottom: 30px;
        color: #aaaaaa;
        font-weight: 400;
        font-size: 14px;
        transform: none;
        z-index: 1;
    }

    .start-date:before,
    .start-date:after {
        content: 'Start Date';
    }
    .end-date:before,
    .end-date:after {
        content: 'End Date';
    }

    ::v-deep .md-has-value:before,
    ::v-deep .md-has-value:after {
        bottom: 0;
        height: 0;
        content: '';
    }

    ::v-deep .md-datepicker .md-input {
        position: absolute;
        z-index: 9;
        width: 150px;
    }

    ::v-deep .md-datepicker .md-input-action {
        z-index: 99;
        right: 52px;
    }
}
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}
::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}
::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}

::v-deep .vs__search {
    cursor: pointer;
}

.datepicker-noevent {
    ::v-deep .md-datepicker {
        input {
            pointer-events: none;
        }
    }
}

.time-picker .main-time-picker-container:after {
    content: 'Select time';
    color: #aaaaaa;
    font-weight: 400;
    font-size: 14px;
    transform: none;
    bottom: 17px;
    position: absolute;
}

::v-deep .md-has-value .main-time-picker-container:after {
    content: '';
}
.time-picker {
    width: 210px !important;
}

.time-picker.md-field:after {
    bottom: 10px !important;
    height: 1px !important;
}
.time-picker:last-child {
    margin-right: 0 !important;
}

.status-filter {
    width: 150px !important;
}

.time-picker ::v-deep .time-picker-container:nth-child(2) {
    border-bottom: 1px solid #d2d2d2;
}
</style>
