<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.geofenceEventsSettings') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>settings_input_antenna</md-icon>
                    </div>
                </md-card-header>

                <md-card-content>
                    <div class="cf-container">
                        <div class="cf-action-container">
                            <md-button
                                title="Add Geofence Event"
                                class="md-primary md-just-icon md-round add-geofence-event"
                                v-if="geofenceConfiguration !== 'OFF'"
                                @click.stop="addNewGeofenceEvent()"
                            >
                                <md-icon>add</md-icon>
                            </md-button>
                        </div>
                        <div>
                            <form-group label="Geofence Setting" class="geofence-settings-key" name="setting">
                                <md-select v-model="geofenceConfiguration">
                                    <md-option value="OFF">Off</md-option>
                                    <md-option value="TEST">Test mode</md-option>
                                    <md-option value="LIVE">Live</md-option>
                                </md-select>
                            </form-group>
                            <button class="geofence-settings-key-btn custom-btn" @click="SaveGeofenceConfiguration">
                                Save
                            </button>
                        </div>

                        <md-table
                            class="custom-paginated-table"
                            v-if="geofenceConfiguration !== 'OFF' && dataLoaded === true"
                        >
                            <md-table-row>
                                <md-table-head>Travel Distance</md-table-head>
                                <md-table-head>Action</md-table-head>
                            </md-table-row>

                            <md-table-row v-for="(geofence, index) in geofenceEvents" :key="index">
                                <md-table-cell>{{ getDistanceFromText(geofence) }}</md-table-cell>
                                <md-table-cell>
                                    {{ getTriggerGroupEvent(geofence) }}
                                </md-table-cell>
                                <md-table-cell class="action-buttons">
                                    <md-button
                                        title="Edit Geofence Event"
                                        class="md-warning md-just-icon md-round"
                                        @click="editGeofenceEvent(geofence)"
                                    >
                                        <md-icon>edit</md-icon>
                                    </md-button>
                                    <md-button
                                        class="md-danger md-just-icon md-round"
                                        @click="deleteGeofenceEvent(geofence.geofenceEventId)"
                                    >
                                        <md-icon>delete</md-icon>
                                    </md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters, mapMutations } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { GEOFENCE_GROUP_EVENT_TRIGGER_CONSTANTS } from '@/utils/constants';
import GeofenceEventSettingModal from './GeofenceEventsSettingModal';

export default {
    name: 'GeofenceEventsSettings',
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            getUser: 'user/user',
            hasTeam: 'user/hasTeam'
        })
    },
    data() {
        return {
            geofenceEvent: {},
            geofenceEvents: [],
            selectedGeofenceEvent: {},
            geofenceConfiguration: 'OFF',
            team: {},
            dataLoaded: false,
            triggerEventOptions: [...GEOFENCE_GROUP_EVENT_TRIGGER_CONSTANTS],
            user: null
        };
    },
    async mounted() {
        this.user = Object.assign({}, this.getUser);
        await this.getGeofenceEvents();
        await this.getTeamData();
        this.dataLoaded = true;
    },
    methods: {
        ...mapMutations({
            updateProfile: 'user/UPDATE_USER'
        }),
        async getTeamData() {
            const api = '/api/teams';
            const { data } = await handleRequests(api);
            this.team = data.data;
            this.geofenceConfiguration = this.team.geofenceConfiguration;
        },
        getDistanceFromText(geofenceEvent) {
            const eventAction = geofenceEvent.geofenceEventActions[0];
            if (eventAction) {
                switch (eventAction.action) {
                    case 'Change-Stop-Status':
                        switch (eventAction.toValue) {
                            case 'Enroute':
                                return `Entering ${geofenceEvent.enteredTravelDistanceValue} ${
                                    geofenceEvent.enteredTravelDistanceUnits
                                } from stop location`;
                            case 'Arrived':
                                return `Entering ${geofenceEvent.enteredTravelDistanceValue} ${
                                    geofenceEvent.enteredTravelDistanceUnits
                                } from stop location`;
                            case 'Complete':
                                return `Leaving ${geofenceEvent.enteredTravelDistanceValue} ${
                                    geofenceEvent.enteredTravelDistanceUnits
                                } from stop location`;
                            default:
                                break;
                        }
                        break;
                    case 'Change-Trip-Status':
                        switch (eventAction.toValue) {
                            case 'Started':
                                return `Leaving ${geofenceEvent.enteredTravelDistanceValue} ${
                                    geofenceEvent.enteredTravelDistanceUnits
                                } from trip start location`;
                            case 'Complete':
                                return `Entering ${geofenceEvent.enteredTravelDistanceValue} ${
                                    geofenceEvent.enteredTravelDistanceUnits
                                } from trip end location`;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }
            return '';
        },
        getTriggerGroupEvent(geofenceEvent) {
            const eventAction = geofenceEvent.geofenceEventActions[0];
            if (eventAction) {
                switch (eventAction.action) {
                    case 'Change-Stop-Status':
                        switch (eventAction.toValue) {
                            case 'Enroute':
                                return this.getTriggerEventText('EnrouteToStop');
                            case 'Arrived':
                                return this.getTriggerEventText('ArrivingAtStop');
                            case 'Complete':
                                return this.getTriggerEventText('LeavingStop');
                            default:
                                break;
                        }
                        break;
                    case 'Change-Trip-Status':
                        switch (eventAction.toValue) {
                            case 'Started':
                                return this.getTriggerEventText('StartingTrip');
                            case 'Complete':
                                return this.getTriggerEventText('EndingTrip');
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }
            return '';
        },
        getTriggerEventText(trigger) {
            const event = this.triggerEventOptions.find((x) => x.key === trigger);
            return event.triggerGroupEvent;
        },
        editGeofenceEvent(geofenceEvent) {
            this.selectedGeofenceEvent = geofenceEvent;
            this.$modal
                .show(GeofenceEventSettingModal, { origGeofenceEvent: this.selectedGeofenceEvent })
                .then((response) => {
                    this.getGeofenceEvents();
                });
        },
        toggleModal(shouldToggle, modalToToggle) {
            this[modalToToggle] = shouldToggle;
        },
        async getGeofenceEvents() {
            this.$_handleLoaderState(true);
            const api = `/api/geofence/stop-events`;
            const response = await handleRequests(api);
            if (response.data !== null) {
                this.geofenceEvents = response.data;
            }

            this.$_handleLoaderState(false);
        },
        deleteGeofenceEvent(geofenceEventId) {
            this.$messageBox
                .show({
                    title: 'Delete Geofence Event',
                    body: 'Are you sure you want to delete this geofence event?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmDelete(geofenceEventId);
                });
        },
        async handleConfirmDelete(geofenceEventId) {
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'delete'
            };
            const api = `/api/geofence/stop-events/${geofenceEventId}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Geofence event was deleted!',
                    type: 'success'
                });
                this.getGeofenceEvents();
            } catch (e) {
                const message = 'Cannot delete geofence event.';
                showErrorMessage(this, message, e);
            }
        },
        addNewGeofenceEvent() {
            this.$modal.show(GeofenceEventSettingModal, { origGeofenceEvent: {} }).then((response) => {
                this.getGeofenceEvents();
            });
        },
        async SaveGeofenceConfiguration() {
            this.$_handleLoaderState(true, 'SAVING...');
            const payload = {
                method: 'post'
            };
            const api = `/api/geofence/stop-events/setting?setting=${this.geofenceConfiguration}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Geofence event setting is saved!',
                    type: 'success'
                });
                this.getGeofenceEvents();
                this.team.geofenceEventsEnabled = true;
                this.user.geofenceConfiguration = this.geofenceConfiguration;
                this.updateProfile(this.user);
            } catch (e) {
                const message = 'Cannot enable geofence event setting.';
                showErrorMessage(this, message, e);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.geofence-settings-key {
    margin-top: 20px;
    margin-right: 10px;
    width: 20%;
    display: inline-block;

    input {
        width: 100%;
    }
}
.geofence-settings-key-btn {
    display: inline-block;
    margin-right: 5px;
    width: 8% !important;
}
.geofence-setting {
    width: 20%;
    margin-top: 35px;
}
.beta-geofence-banner {
    margin-top: 15px;
}
.add-geofence-event {
    top: -14px;
}
.card-icon {
    background: #ff5245 !important;
}
</style>
