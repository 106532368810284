<template>
    <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100" v-if="hasTeam">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.accounts') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>manage_accounts</md-icon>
                    </div>
                </md-card-header>

                <md-card-content>
                    <div v-if="hasTeam">
                        <tabs
                            :tab-name="[
                                'Service packages',
                                'Invoicing',
                                'Account Codes',
                                'Rate Types',
                                'Entity',
                                'Tax Settings'
                            ]"
                            :selected-panel="selectedTab"
                            color-button="success"
                            class="custom-tab-list"
                            v-if="hasTeam"
                        >
                            <template slot="tab-pane-1">
                                <rate-groups-list />
                            </template>
                            <template slot="tab-pane-2">
                                <invoice-settings />
                            </template>
                            <template slot="tab-pane-3">
                                <account-code-settings />
                            </template>
                            <template slot="tab-pane-4">
                                <rate-type-settings />
                            </template>
                            <template slot="tab-pane-5">
                                <entity-list />
                            </template>
                            <template slot="tab-pane-6">
                                <tax-settings-list />
                            </template>
                        </tabs>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { Tabs } from '@/components';
import { mapGetters } from 'vuex';
import RateGroupsList from './RateGroups/RateGroupsList';
import InvoiceSettings from './Tabs/InvoiceSettings';
import AccountCodeSettings from './AccountCodes/AccountCodeSettings';
import RateTypeSettings from './RateTypes/RateTypeSettings';
import EntityList from './Entity/EntityList';
import TaxSettingsList from './TaxSettings/TaxSettingsList';

export default {
    name: 'TeamSettings',
    components: {
        Tabs,
        RateGroupsList,
        InvoiceSettings,
        AccountCodeSettings,
        RateTypeSettings,
        EntityList,
        TaxSettingsList
    },
    data() {
        return {
            selectedTab: this.$route.query.setupCurrency ? 'Invoicing' : 'Rate groups'
        };
    },
    methods: {},
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam',
            isLoading: 'isLoading',
            user: 'user/user'
        })
    }
};
</script>

<style lang="scss" scoped>
.title {
    display: inline-block;
}

::v-deep {
    .custom-btn {
        margin-right: 10px;
        font-weight: 400;
        height: 40px;
        width: 45%;
    }
}

::v-deep .custom-tab-list {
    .nav-tabs {
        width: calc(100% - 55px);
        flex-wrap: wrap;
        li {
            border-bottom: 0px;
        }
    }
}

.form-wrapper .custom-btn {
    margin-bottom: 0;
    margin-top: 25px;
}

.member-container {
    position: relative;
    .custom-switch {
        position: absolute;
        right: 15px;
        top: -40px;
        .md-switch {
            margin: 0;
            margin-bottom: 10px;
        }
    }
}
.card-icon {
    background: #377e47 !important;
}
</style>
