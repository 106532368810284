<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start"></div>
                    <div class="custom-toolbar-end">
                        <div>
                            <md-button
                                title="Add maintenance schedule"
                                class="md-primary md-just-icon md-round pull-right header-button"
                                @click="createMaintenanceSchedule"
                            >
                                <md-icon>add</md-icon>
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>schedule</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div v-if="!isListLoading">
                            <md-table class="context-menu-support" v-if="maintenanceScheduleList.length > 0">
                                <md-table-row>
                                    <md-table-head>Maintenance Schedules</md-table-head>
                                    <md-table-head>Type</md-table-head>
                                    <md-table-head>Status</md-table-head>
                                    <md-table-head>Actions</md-table-head>
                                </md-table-row>

                                <md-table-row
                                    @click.stop="showSidebarDetails(item)"
                                    v-for="item in maintenanceScheduleList"
                                    :key="item.assetMaintenanceScheduleId"
                                >
                                    <md-table-cell>
                                        {{ item.maintenanceName }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        <span
                                            class="material-icons custom-type-icon"
                                            :class="`icon-${$_getAssetLogTypeBadgeColor(item.maintenanceType)}`"
                                        >
                                            {{ $_getAssetLogTypeIcon(item.maintenanceType) }}
                                        </span>
                                        {{ $t('assetLogTypes.' + item.maintenanceType) }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        <drop-down>
                                            <span class="status status-complete" slot="title" data-toggle="dropdown">
                                                {{ item.status }}
                                            </span>
                                        </drop-down>
                                    </md-table-cell>
                                    <md-table-cell class="action-buttons">
                                        <update-maintenance-schedule-button
                                            :maintenance-data="item"
                                            @maintenanceUpdateCompleted="handleUpdate"
                                        />
                                        <delete-maintenance-schedule-button
                                            :id="item.assetMaintenanceScheduleId"
                                            @deleted="handleDelete"
                                        />
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <div v-else>
                                <p class="no-result-message">No existing maintenance schedule could be found.</p>
                            </div>
                        </div>
                        <div v-else>
                            <div class="content-loader">
                                <fade-loader :loading="isListLoading" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <transition name="slide">
            <maintenance-schedule-sidebar v-if="showSidebar" @closeModal="toggleSidebar" :sidebar-data="sidebarData" />
        </transition>
    </div>
</template>

<script>
import { handleRequests } from '@/helpers';
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { GeneralMixin, AssetManagementMixin } from '@/mixins';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import MaintenanceScheduleSidebar from './MaintenanceScheduleSidebar';
import CreateMaintenanceScheduleWizard from './modals/CreateMaintenanceScheduleWizard';
import DeleteMaintenanceScheduleButton from './buttons/DeleteMaintenanceScheduleButton';
import UpdateMaintenanceScheduleButton from './buttons/UpdateMaintenanceScheduleButton';

export default {
    name: 'MaintenanceScheduleOverview',
    mixins: [GeneralMixin, AssetManagementMixin],
    data() {
        return {
            isListLoading: false,
            showSidebar: false,
            maintenanceScheduleList: [],
            pagination: PAGINATION_DEFAULTS,
            sidebarData: null
        };
    },
    components: {
        MaintenanceScheduleSidebar,
        FadeLoader,
        DeleteMaintenanceScheduleButton,
        UpdateMaintenanceScheduleButton
    },
    async mounted() {
        const { maintenanceSchedules, totalMaintenanceSchedules } = await this.fetchMaintenanceScheduleList();
        this.setupDataList(maintenanceSchedules, totalMaintenanceSchedules);
    },
    methods: {
        async fetchMaintenanceScheduleList(pageNumber = 1, itemsPerPage = 50) {
            this.isListLoading = true;
            const endpoint = `/api/maintenance-schedules`;
            const response = await handleRequests(endpoint);
            this.isListLoading = false;
            return response.data;
        },
        setupDataList(data, total) {
            this.maintenanceScheduleList = data;
            this.pagination.total = total;
        },
        showSidebarDetails(item) {
            this.showSidebar = true;
            this.sidebarData = Object.assign({}, {});
            this.sidebarData = Object.assign({}, item);
            if (item.notificationConfiguration != null) {
                this.sidebarData.notificationConfiguration = JSON.parse(item.notificationConfiguration);
            }
        },
        toggleSidebar() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown) 
                this.showSidebar = !this.showSidebar;
        },
        createMaintenanceSchedule() {
            this.$modal.show(CreateMaintenanceScheduleWizard).then(async (response) => {
                if (response) {
                    this.$modal.hide();
                    const {
                        maintenanceSchedules,
                        totalMaintenanceSchedules
                    } = await this.fetchMaintenanceScheduleList();
                    this.setupDataList(maintenanceSchedules, totalMaintenanceSchedules);
                }
            });
        },
        async handleDelete(success) {
            if (success) {
                const { maintenanceSchedules, totalMaintenanceSchedules } = await this.fetchMaintenanceScheduleList();
                this.setupDataList(maintenanceSchedules, totalMaintenanceSchedules);
            }
        },
        async handleUpdate(success) {
            if (success) {
                const { maintenanceSchedules, totalMaintenanceSchedules } = await this.fetchMaintenanceScheduleList();
                this.setupDataList(maintenanceSchedules, totalMaintenanceSchedules);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

.status {
    color: #fff !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 90px;
}

.custom-type-icon {
    margin-right: 5px;
    border-radius: 50%;
    padding: 2px;
    vertical-align: middle;
}

::v-deep .badge {
    display: inline-block;
    border-radius: 12px;
    padding: 5px 12px;
    text-transform: uppercase;
    font-size: 10px;
    color: #fff;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;

    position: initial;
    width: initial;
    height: initial;
    top: initial;
    right: initial;
    margin-top: initial;
    margin-right: initial;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

::v-deep .header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.icon-pending {
    color: #c2907f;
}
.icon-enroute {
    color: #4ed2ff;
}
.icon-arrived {
    color: #2b93ff;
}
.icon-departed {
    color: #ffb42b;
}
.icon-cancelled {
    color: #ff5245;
}
.icon-delayed {
    color: #384553;
}
.icon-complete {
    color: #0bda8e;
}

.body-list {
    ::v-deep .md-table-row:hover {
        cursor: pointer;
        background-color: #fafafa;
    }
}

.action-buttons {
    > div {
        .inline-button {
            margin-right: 5px;
        }
        .inline-button:last-child {
            margin-right: 0;
        }
    }
}
</style>
