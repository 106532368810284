var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.trip
    ? _c(
        "div",
        [
          !_vm.isModal
            ? _c(
                "drop-down",
                {
                  attrs: { "should-close": _vm.shouldCloseDropdown },
                  on: {
                    closeDropdown: function (val) {
                      return (this$1.shouldCloseDropdown = val)
                    },
                    closeOtherDropDown: _vm.closeOtherDropDown,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "status",
                      class:
                        "status-" +
                        _vm.trip.status.toLowerCase().replace(/\s+/g, "") +
                        " " +
                        _vm.className,
                      attrs: { slot: "title", "data-toggle": "dropdown" },
                      slot: "title",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.trip.status) +
                          "\n        "
                      ),
                    ]
                  ),
                  !_vm.trip.user.publicUserId
                    ? _c(
                        "ul",
                        { staticClass: "dropdown-menu" },
                        _vm._l(
                          _vm.unassignedStatuses,
                          function (status, index) {
                            return _c("li", { key: index }, [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleChangeStopStatus(status)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(status) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ])
                          }
                        ),
                        0
                      )
                    : _c(
                        "ul",
                        { staticClass: "dropdown-menu" },
                        _vm._l(_vm.statuses, function (status, index) {
                          return _c("li", { key: index }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleChangeStopStatus(status)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(status) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ])
                        }),
                        0
                      ),
                ]
              )
            : _vm._e(),
          _vm.isModal
            ? _c("div", [
                _c(
                  "span",
                  {
                    staticClass: "status",
                    class:
                      "status-" +
                      _vm.trip.status.toLowerCase().replace(/\s+/g, "") +
                      " " +
                      _vm.className,
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.openAssignStop()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(_vm.trip.status) + "\n        "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }