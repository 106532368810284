<template>
    <div>
        <vue-select
            placeholder="Not Mapped"
            class="column-mappings"
            :class="{ 'is-not-mapped': isNotMapped }"
            :reduce="(x) => x.name"
            label="value"
            :options="fieldDefinition.worksheetFields"
            :clearable="false"
            v-model="displayValue"
        ></vue-select>
    </div>
</template>

<script>
export default {
    name: 'DesignerFieldInputs',
    props: {
        fieldDefinition: {
            type: [Object, Array],
            default: () => {}
        }
    },
    watch: {
        displayValue(newValue) {
            if (!this.isLoaded)
                // Don't want to raise change events until after the component is loaded
                return;

            if (newValue === 'customMapping') {
                this.$emit('editCustomMapping', this.fieldDefinition);
            } else if (newValue === 'notMapped') {
                this.fieldDefinition.value = null;
            } else {
                this.fieldDefinition.value = newValue;
            }

            this.isNotMapped = newValue === 'notMapped';
        }
    },
    mounted() {
        if (this.fieldDefinition.value) {
            if (typeof this.fieldDefinition.value === 'object') {
                this.displayValue = 'customMapping';
            } else {
                this.displayValue = this.fieldDefinition.value;
            }
        } else {
            this.displayValue = 'notMapped';
            this.isNotMapped = true;
        }

        this.$nextTick(() => {
            // Used so that the displayValue watcher doesn't immediately raise a changed event
            this.isLoaded = true;
        });
    },
    data() {
        return {
            displayValue: null,
            isNotMapped: false,
            isLoaded: false
        };
    }
};
</script>

<style scoped>
.is-not-mapped::v-deep .vs__selected {
    color: silver;
}
</style>
