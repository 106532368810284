<template>
    <drop-down
        :should-close="shouldCloseDropdown"
        @closeDropdown="(val) => (this.shouldCloseDropdown = val)"
        @closeOtherDropDown="closeOtherDropDown"
    >
        <span
            class="status"
            slot="title"
            data-toggle="dropdown"
            :class="`status-${shipment.status.toLowerCase().replace(/\s+/g, '')} ${className}`"
        >
            {{ shipment.status }}
        </span>
        <ul class="dropdown-menu" v-if="!isReadOnlyUser || (isCustomerAdmin && shipment.status === 'Pending')">
            <li v-for="(status, index) in statuses" :key="index">
                <a @click.stop="handleChangeShipmentStatus(status)">
                    {{ status }}
                </a>
            </li>
        </ul>
    </drop-down>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests } from '@/helpers';
import {
    STATUS_SHIPMENT_CONSTANTS,
    UNASSIGNED_STATUS_SHIPMENT_CONSTANTS,
    FUTURE_STATUS_SHIPMENT_CONSTANTS,
    PAST_STATUS_SHIPMENT_CONSTANTS,
    CUSTOMER_ADMIN_ROLE_STATUS_CONSTANTS,
    DATE_TYPES
} from '@/utils/constants';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    name: 'ShipmentStatusButton',
    mixins: [GeneralMixin],
    props: {
        shipment: {
            type: Object,
            default: () => {}
        },
        className: {
            type: String,
            default: () => ''
        }
    },
    data() {
        return {
            statuses: STATUS_SHIPMENT_CONSTANTS,
            shouldCloseDropdown: false
        };
    },
    computed: {
        ...mapGetters({
            isCustomerAdmin: 'user/isCustomerAdmin',
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    mounted() {
        const today = moment().format(DATE_TYPES.internationalDate);

        if (this.shipment.carrierTeamId) {
            this.statuses = ['Cancelled'];
        } else {
            // eslint-disable-next-line no-lonely-if
            if (this.shipment.assignedTo.publicUserId == null) {
                this.statuses = UNASSIGNED_STATUS_SHIPMENT_CONSTANTS;
            } else {
                // eslint-disable-next-line no-lonely-if
                if (moment(this.shipment.tripDate).isAfter(today)) {
                    this.statuses = FUTURE_STATUS_SHIPMENT_CONSTANTS;
                }
                if (moment(this.shipment.tripDate).isBefore(today)) {
                    this.statuses = PAST_STATUS_SHIPMENT_CONSTANTS;
                }
            }
        }

        if (this.isCustomerAdmin) {
            this.statuses = CUSTOMER_ADMIN_ROLE_STATUS_CONSTANTS;
        }
    },
    methods: {
        async handleChangeShipmentStatus(newStatus) {
            this.shouldCloseDropdown = true;
            const { shipmentId, status } = this.shipment;
            if (status !== newStatus) {
                const payload = {
                    method: 'post',
                    data: {
                        newStatus,
                        oldStatus: status
                    }
                };
                // to toggle the context menu
                this.$nextTick(async () => {
                    this.shouldCloseDropdown = false;
                    try {
                        const api = `/api/shipments/${shipmentId}/change-status`;
                        await handleRequests(api, payload);
                        this.$notify({
                            message: `Shipment status was changed to ${newStatus}!`,
                            type: 'success'
                        });
                        this.$emit('statusUpdated', { shipmentId, status: newStatus, oldStatus: status });
                    } catch (e) {
                        let message = 'Cannot change shipment status.';
                        if (e && e.data) 
                            [{ message }] = e.data;
                        this.$notify({
                            message,
                            type: 'danger'
                        });
                    }
                });
            } else {
                const message = 'Cannot change status to the same status';
                this.$notify({
                    message,
                    type: 'danger'
                });
            }
        },
        closeOtherDropDown() {
            const dropdowns = document.querySelectorAll('.open');
            dropdowns.forEach((dropdown) => dropdown.classList.remove('open'));
        }
    }
};
</script>

<style lang="scss" scoped>
.status {
    color: #fff !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 160px;
    cursor: pointer;
}

::v-deep .dropdown-menu {
    li {
        cursor: pointer !important;
    }
}
</style>
