<template>
    <div>
        <md-card-content>
            <md-table :value="reportData" class="table-hover">
                <md-table-row slot="md-table-row" slot-scope="{ item }" @click="viewAddress(item)">
                    <md-table-cell md-label="Date/Time">
                        {{ displayDateTime(item.dateTime, item.utcOffset) | dateTimeFormat }}
                    </md-table-cell>
                    <md-table-cell md-label="Address" md-sort>
                        <a class="address" @click.stop="viewAddress(item)">
                            {{ displayAddressOrCoord(item) }}
                        </a>
                    </md-table-cell>
                    <md-table-cell md-label="Actual Speed">
                        {{ speedInCorrectUnits(item.speedKph) }}
                    </md-table-cell>
                    <md-table-cell md-label="Max Speed">
                        {{ speedInCorrectUnits(item.maxSpeedKph) }}
                    </md-table-cell>
                    <md-table-cell md-label="Over Speed">
                        {{ displayOverSpeed(item) }}
                    </md-table-cell>
                    <md-table-cell md-label="Device IMEI">
                        {{ item.deviceIMIE }}
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </md-card-content>
    </div>
</template>
<style scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

::v-deep .md-table-head {
    cursor: default;
}

.table-hover {
    cursor: pointer;
}
</style>
<script>
import moment from 'moment';
import { ViewAddressModal } from '..';

export default {
    name: 'SpeedingReportDetails',
    components: {},
    props: {
        memberList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {};
    },
    computed: {
        reportData() {
            return this.memberList;
        },
        user() {
            return this.$store.getters['user/user'];
        },
        speedUnits() {
            return this.user.speedUnits;
        }
    },
    methods: {
        displayDateTime(date, utcOffset) {
            if (date) 
                return moment(date).utcOffset(utcOffset);
            return '';
        },
        displayAddressOrCoord(item) {
            const addr = [item.addressRoad, item.addressSuburb, item.addressTown].filter((part) => part).join(', ');
            if (addr) 
                return addr;
            return `${item.latitude} - ${item.longitude}`;
        },
        speedInCorrectUnits(speedKph) {
            // Ensure only 1 decimal digit.
            if (this.speedUnits === 'kph') 
                return `${Math.round(speedKph * 10) / 10} ${this.speedUnits}`;
            return `${Math.round(speedKph * 0.621371 * 10) / 10} ${this.speedUnits}`;
        },
        displayOverSpeed(item) {
            return `${this.speedInCorrectUnits(item.speedKph - item.maxSpeedKph)} (${Math.round(
                (10000 * (item.speedKph - item.maxSpeedKph)) / item.maxSpeedKph
            ) / 100} %)`;
        },
        viewAddress(item) {
            this.$modal.show(ViewAddressModal, { data: { location: item, address: this.displayAddressOrCoord(item) } });
        }
    }
};
</script>
