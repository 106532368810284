<template>
    <md-card class="md-card-login">
        <md-card-header>
            <slot name="title" />
            <slot name="social-logins" />
        </md-card-header>

        <md-card-content>
            <slot name="description" />
            <slot name="inputs" />
            <slot name="form-link" />
        </md-card-content>

        <md-card-actions>
            <slot name="footer" />
        </md-card-actions>
    </md-card>
</template>

<script>
export default {
    name: 'LoginCard'
};
</script>

<style lang="css" scoped>
.md-card-login .md-card-header {
    background-color: transparent;
    margin: 0;
    text-align: left;
}
</style>
