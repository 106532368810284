<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
            <div class="card-icon">
                <md-icon>coffee</md-icon>
            </div>
        </md-card-header>

        <md-card-content>
            <md-table class="custom-paginated-table">
                <!-- TODO: Make header sticky -->
                <md-table-row slot="header">
                    <md-table-head class="driver-break"></md-table-head>
                    <md-table-head class="stop-status">Status</md-table-head>
                    <md-table-head class="stop-time">Estimated Break Time</md-table-head>
                    <md-table-head class="stop-time">Started</md-table-head>
                    <md-table-head class="stop-time">Ended</md-table-head>
                </md-table-row>

                <md-table-row v-for="(driverBreakItem, breakIndex) in tripDetails.driverBreakList"
                              :key="breakIndex">
                    <md-table-cell class="driver-break">
                        Break {{ driverBreakItem.order }}
                    </md-table-cell>
                    <md-table-cell class="stop-status">
                        <span class="status"
                              :class="`status-${driverBreakItem.status.toLowerCase().replace(/\s+/g, '')}`">
                            {{ driverBreakItem.status }}
                        </span>
                    </md-table-cell>
                    <md-table-cell class="stop-time">
                        <div>
                            <span v-if="driverBreakItem.eta">
                                {{ driverBreakItem.eta | timeFormat(DATE_TYPES.standardTime) }}
                            </span>
                        </div>
                    </md-table-cell>
                    <md-table-cell class="stop-time">
                        <div>
                            <span v-if="driverBreakItem.arrivalDate" class="bold-time">
                                {{ driverBreakItem.arrivalDate | timeFormat(DATE_TYPES.standardTime) }}
                            </span>
                        </div>
                    </md-table-cell>
                    <md-table-cell class="stop-time">
                        <div>
                            <span v-if="driverBreakItem.depatureDate" class="bold-time">
                                {{ driverBreakItem.depatureDate | timeFormat(DATE_TYPES.standardTime) }}
                            </span>
                        </div>
                    </md-table-cell>
                    
                    
                </md-table-row>
            </md-table>
        </md-card-content>
    </md-card>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'DriverBreakListTable',
    mixins: [GeneralMixin],
    props: {
        tripDetails: {
            type: Object,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .driver-break {
        max-width: 200px;
    }

    .status {
        color: #fff !important;
        min-width: 90px;
        text-align: center;
        display: block;
        line-height: 31px;
        font-weight: 400;
        max-width: 90px;
    }

    .custom-paginated-table {
        > ::v-deep div {
            overflow: auto;
            max-height: 400px;
        }

        ::v-deep th {
            position: sticky;
            top: 0;
        }
    }

    .startend-table-row {
        background-color: #fafafa;
    }

    @media (min-width: 1904px) {
        .custom-paginated-table {
            > ::v-deep div {
                overflow: auto;
                max-height: 600px;
            }
        }
    }

    .bold-time {
        font-weight: 500;
    }
</style>
