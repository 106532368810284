<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title" v-if="!isLocationDetailsLoading">{{ modalTitle }}</h4>
            <div v-else class="loader">
                <fade-loader :loading="true" color="#333333" />
            </div>
            <div class="modal-header--actions">
                <search-component @onSearch="handleSearch" placeholder="Search" v-if="!isLocationDetailsLoading" />
                <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                    <md-icon>clear</md-icon>
                </md-button>
            </div>
        </div>
        <div class="modal-body">
            <div v-if="!isListLoading">
                <md-table>
                    <md-table-row>
                        <md-table-head>Barcode</md-table-head>
                        <md-table-head>Description</md-table-head>
                    </md-table-row>
                    <md-table-row v-for="item in inventoryList" :key="item.itemId">
                        <md-table-cell class="item-barcode">
                            <router-link
                                title="View item details"
                                :to="{
                                    name: 'Inventory Details',
                                    params: { itemId: item.itemId }
                                }"
                                target="_blank"
                            >
                                {{ item.barcode }}
                            </router-link>
                        </md-table-cell>
                        <md-table-cell>{{ item.description }}</md-table-cell>
                    </md-table-row>
                </md-table>
                <div v-if="inventoryList.length == 0">
                    <p class="no-result-message">No results matching your search/filter could be found.</p>
                </div>
            </div>
            <div v-else class="loader">
                <fade-loader :loading="true" color="#333333" />
            </div>
        </div>
        <div class="modal-footer">
            <div>
                <p v-if="total === pagination.perPage" class="card-category">
                    Page {{ pagination.currentPage }} of many
                </p>
                <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
            </div>
            <pagination
                v-model="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                @change-page="handleChangePage($event, pagination.perPage)"
            />
        </div>
    </div>
</template>
<script>
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { GeneralMixin } from '@/mixins';
import { handleRequests, showErrorMessage } from '@/helpers';
import { Pagination, SearchComponent } from '@/components';
import { ITEM_LOCATION_DETAILS_DEFAULTS, PAGINATION_DEFAULTS } from '@/utils/defaults';

export default {
    name: 'ManageItemLocationItemsModal',
    mixins: [GeneralMixin],
    props: {
        locationId: {
            type: Number,
            default: null
        }
    },
    components: {
        SearchComponent,
        Pagination,
        FadeLoader
    },
    data() {
        return {
            // Location details
            locationDetails: ITEM_LOCATION_DETAILS_DEFAULTS(),
            // Array of location items
            /**
             * @type {import('@/jsdocTypes/Item').Item[]}
             */
            inventoryList: [],
            pagination: {
                ...PAGINATION_DEFAULTS,
                perPage: 10
            },
            filters: {},
            isLocationDetailsLoading: false,
            isListLoading: false
        };
    },
    computed: {
        total() {
            return this.pagination.total;
        },

        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        modalTitle() {
            return `Items in ${this.locationDetails.name}`;
        }
    },
    async mounted() {
        this.pagination.currentPage = 1;

        // if one of the promises fails, the other will still be executed
        await Promise.allSettled([
            this.handleChangePage(this.pagination.currentPage, this.pagination.perPage),
            this.fetchLocationDetails()
        ]);
    },
    beforeUnmount() {
        // reset state before unmounting
        this.inventoryList = [];
        this.locationDetails = {};
        this.pagination = {
            ...PAGINATION_DEFAULTS,
            perPage: 10
        };
        this.filters = {};
        this.isLocationDetailsLoading = false;
        this.isListLoading = false;
    },
    methods: {
        /**
         * @param {{ searchText: string }} obj
         * @returns {Promise<void>}
         */
        async handleSearch(obj) {
            this.filters.keyword = obj.searchText;
            await this.handleChangePage(1, this.pagination.perPage);
        },
        async fetchLocationDetails() {
            this.isLocationDetailsLoading = true;
            try {
                const endpoint = `/api/item-locations/${this.locationId}`;
                const response = await handleRequests(endpoint);
                this.locationDetails = response.data;
            } catch (error) {
                const message = 'Error in getting location details';
                showErrorMessage(this, message, error);
            }
            this.isLocationDetailsLoading = false;
        },
        async getInventoryList(pageNumber = 1, itemsPerPage = 50) {
            let response = {
                data: {
                    items: [],
                    totalItems: null
                }
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/items/search`;
                response = await handleRequests(endpoint, {
                    params: {
                        pageNumber,
                        itemsPerPage,
                        itemLocationId: this.locationId,
                        ...this.cleanFilters()
                    }
                });

                const { items, totalItems } = response.data;
                this.setInventoryAndTotal(items, totalItems);
            } catch (error) {
                const message = 'Error in getting the inventory list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });

            return this.filters;
        },
        setInventoryAndTotal(data, total) {
            this.inventoryList = data;
            this.pagination.total = total || data.length;
            this.maxPage = Math.ceil(this.pagination.total / this.pagination.perPage);
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            await this.getInventoryList(currentPage, perPage);
        },
        async handleFilterOrSearch(data) {
            this.filters = Object.assign(this.filters, data);
            await this.handleChangePage(1, this.pagination.perPage);
        }
    }
};
</script>

<style scoped lang="scss">
.modal-container {
    max-width: 600px;
}

.modal-header {
    display: grid;
    grid-template-columns: 54fr 46fr;
}

.modal-header--actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

::v-deep .search--container .search-button--container {
    margin: 0;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.item-barcode ::v-deep a {
    color: rgba(0, 0, 0, 0.87);
}

::v-deep .md-table-head-label {
    color: #4caf50 !important;
}
</style>
