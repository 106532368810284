var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user" }, [
    _c("div", { staticClass: "photo" }, [
      _vm.user.photoUrl
        ? _c("img", {
            attrs: { src: _vm.user.photoUrl, alt: "avatar" },
            on: { error: _vm.$_setDefaultBrokenImage },
          })
        : _c("img", { attrs: { src: _vm.$root.defaultPhotoUrl, alt: "" } }),
    ]),
    _c(
      "div",
      { staticClass: "user-info" },
      [
        _c(
          "a",
          {
            attrs: {
              "data-toggle": "collapse",
              "aria-expanded": !_vm.isClosed,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.toggleMenu($event)
              },
              "!click": function ($event) {
                return _vm.clicked($event)
              },
            },
          },
          [
            _c("span", { staticClass: "info-fullname" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.user.fullName) +
                  "\n            "
              ),
            ]),
            _vm._m(0),
          ]
        ),
        _c("collapse-transition", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isClosed,
                  expression: "!isClosed",
                },
              ],
            },
            [
              _c(
                "ul",
                { staticClass: "nav" },
                [
                  _vm._t("default", [
                    !_vm.isReadOnlyUser
                      ? _c(
                          "li",
                          [
                            _c("router-link", { attrs: { to: "/profile" } }, [
                              _c("span", { staticClass: "sidebar-mini" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("menus.user.myProfile-mini"))
                                ),
                              ]),
                              _c("span", { staticClass: "sidebar-normal" }, [
                                _vm._v(_vm._s(_vm.$t("menus.user.myProfile"))),
                              ]),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isIndividualUser && !_vm.isReadOnlyUser
                      ? _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "/profile/settings" } },
                              [
                                _c("span", { staticClass: "sidebar-mini" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("menus.user.settings-mini"))
                                  ),
                                ]),
                                _c("span", { staticClass: "sidebar-normal" }, [
                                  _vm._v(_vm._s(_vm.$t("menus.user.settings"))),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("li", [
                      _c(
                        "a",
                        { attrs: { href: "#" }, on: { click: _vm.logout } },
                        [
                          _c("span", { staticClass: "sidebar-mini" }, [
                            _vm._v(_vm._s(_vm.$t("menus.user.logOut-mini"))),
                          ]),
                          _c("span", { staticClass: "sidebar-normal" }, [
                            _vm._v(_vm._s(_vm.$t("menus.user.logOut"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ],
                2
              ),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("b", { staticClass: "caret" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }