var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    { staticClass: "md-card-nav-tabs" },
    [_c("md-card-content", [_vm._t("content")], 2)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }