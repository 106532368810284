var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v("Custom SMS Template"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          { staticClass: "form-wrapper", attrs: { validator: _vm.$v.form } },
          [
            _c("div", { staticClass: "inputs-container" }, [
              _c(
                "div",
                { staticClass: "selects-container" },
                [
                  _c(
                    "form-group",
                    {
                      staticClass: "event-trigger",
                      attrs: { label: "Event Trigger", name: "key" },
                    },
                    [
                      _c(
                        "md-select",
                        {
                          on: { "md-selected": _vm.getTriggerKey },
                          model: {
                            value: _vm.form.eventTrigger,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "eventTrigger", $$v)
                            },
                            expression: "form.eventTrigger",
                          },
                        },
                        _vm._l(_vm.eventTriggerOptions, function (event) {
                          return _c(
                            "md-option",
                            {
                              key: event.eventTrigger,
                              attrs: { value: event.eventTrigger },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(event.eventTrigger) +
                                  "\n                            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.hasStopOptions
                    ? _c(
                        "form-group",
                        {
                          staticClass: "event-trigger",
                          attrs: { label: "Stop Type", name: "stopType" },
                        },
                        [
                          _c(
                            "md-select",
                            {
                              on: { "md-selected": _vm.getMergeFields },
                              model: {
                                value: _vm.form.stopType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "stopType", $$v)
                                },
                                expression: "form.stopType",
                              },
                            },
                            _vm._l(_vm.stopTypeOptions, function (stopType) {
                              return _c(
                                "md-option",
                                { key: stopType, attrs: { value: stopType } },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(stopType) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form && _vm.form.eventTrigger === "Manual",
                      expression: "form && form.eventTrigger === 'Manual'",
                    },
                  ],
                  staticClass: "name-container",
                },
                [
                  _c(
                    "form-group",
                    { attrs: { label: "Name", name: "name" } },
                    [
                      _c("md-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "form-group",
              { staticClass: "custom-merge-field", attrs: { label: "Fields" } },
              [
                _c(
                  "drop-down",
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "dropdown-toggle md-info",
                        attrs: { slot: "title", "data-toggle": "dropdown" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                        slot: "title",
                      },
                      [
                        _vm._v(
                          "\n                        Insert Merge Field\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "dropdown-menu dropdown-menu-right dropdown-menu-height",
                      },
                      _vm._l(_vm.mergeFields, function (event) {
                        return _c("li", { key: event.key }, [
                          event.key === "stopcustomfields" ||
                          event.key === "shipmentcustomfields"
                            ? _c(
                                "span",
                                { staticClass: "custom-field-header" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(event.name) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.getSelectedMergeField(
                                        event.key
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(event.name) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                        ])
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "form-group",
              { staticClass: "custom-textarea", attrs: { name: "template" } },
              [
                _c("md-textarea", {
                  ref: "template",
                  attrs: { "md-counter": "160" },
                  model: {
                    value: _vm.form.template,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "template", $$v)
                    },
                    expression: "form.template",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "md-icon",
          { staticClass: "custom-sms-over-limit-warning-icon" },
          [
            _vm._v("\n            warning\n            "),
            _c("md-tooltip", {
              domProps: { textContent: _vm._s(_vm.customSmsOverLimitTooltip) },
            }),
          ],
          1
        ),
        _vm.isCustomSmsOverLimit
          ? _c("div", { staticClass: "custom-sms-over-limit-text" }, [
              _vm._v(
                "\n            Your SMS will be sent across multiple segments and could impact your SMS usage costs. Please reduce your\n            character count to 160 or less.\n        "
              ),
            ])
          : _vm._e(),
        !_vm.isCustomSmsOverLimit ? _c("br") : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.saveSmsTemplate },
          },
          [_vm._v("Save")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }