<template>
    <div class="sidebar-spacing">
        <sidebar-item :link="{ name: $t('menus.support.support'), icon: 'live_help' }">
            <!-- for sidebar item to work properly with custom li a tags. 
            we need to trick the sidebar item to think that it has a children. 
            It will then create a ul tag that will enclose the custom li tags-->
            <sidebar-item class="hide" :link="{ active: false }" />
            <li>
                <a href="https://help.locate2u.com" target="_blank" class="nav-link first-support-link">
                    <span class="sidebar-mini">{{ $t('menus.support.knowledgeBase-mini') }}</span>
                    <span class="sidebar-normal">{{ $t('menus.support.knowledgeBase') }}</span>
                </a>
            </li>
            <li v-if="$root.isDesktop">
                <a @click="chatSupport" class="nav-link">
                    <span class="sidebar-mini">{{ $t('menus.support.chatSupport-mini') }}</span>
                    <span class="sidebar-normal">{{ $t('menus.support.chatSupport') }}</span>
                </a>
            </li>
            <sidebar-item
                v-if="user.role === 'Team Owner' || user.role === null"
                :link="{
                    name: $t('menus.support.gettingStarted'),
                    path: '/support/onboarding'
                }"
            />
        </sidebar-item>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    mounted() {
        // the chat widget should always be hidden unless clicked from the support menu
        // eslint-disable-next-line func-names
        setTimeout(function() {
            const tidioScript = document.createElement('script');
            tidioScript.src = '//code.tidio.co/xj9i60xskd6btejyd2ayrqkftunebkqe.js';
            document.body.appendChild(tidioScript);
            if (window.tidioChatApi) {
                // eslint-disable-next-line func-names
                window.tidioChatApi.on('ready', function() {
                    tidioChatApi.display(false);
                });
            } else {
                // eslint-disable-next-line func-names
                document.addEventListener('tidioChat-ready', function() {
                    tidioChatApi.display(false);
                });
            }
        }, 2 * 1000);
    },
    methods: {
        chatSupport() {
            // eslint-disable-next-line camelcase
            if (window.tidioChatApi) {
                tidioChatApi.display(true);
                // eslint-disable-next-line func-names
                window.tidioChatApi.on('close', function() {
                    tidioChatApi.display(false);
                });
                tidioChatApi.setVisitorData({
                    email: this.user.email,
                    tags: ['app portal']
                });
                window.tidioChatApi.open();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.first-support-link {
    margin-top: 0 !important;
}
</style>
