<template>
    <div class="white-bg">
        <div class="mobile-filter-header">
            <div class="md-layout">
                <div
                    :class="
                        selectedTeamMember.publicUserId
                            ? 'md-layout-item md-xsmall-size-50'
                            : 'md-layout-item md-xsmall-size-100'
                    "
                    v-if="!isSingleUser && !isSingleTeamMember"
                >
                    <div class="heavy-font-600 filter-btn">
                        <span @click="filterByTeamMember" class="filter-teammember">{{ selectedTeamMember.name }}</span>
                    </div>
                </div>
                <div class="md-layout-item md-xsmall-size-50" v-if="selectedTeamMember.publicUserId != null">
                    <div class="datepicker-container">
                        <md-datepicker
                            v-model="selectedDate"
                            md-immediately
                            :md-debounce="100"
                            ref="mdDatePicker"
                            :md-model-type="String"
                        ></md-datepicker>
                        <span class="heavy-font-600">{{ selectedDate | dateFormat(DATE_TYPES.standardDate) }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="md-layout">
            <div class="md-layout-item stoplist-container">
                <div v-if="!loading">
                    <div v-if="searchList.length">
                        <div v-for="item in searchList" :key="item.shipmentId" class="shipment-item">
                            <div class="pickup-drop-container">
                                <div class="reference-container">
                                    <div>
                                        <h5 class="type-title">
                                            {{ item.contact.name ? item.contact.name : item.shipmentRef }}
                                        </h5>
                                    </div>
                                    <div>
                                        <reassign-shipment-button
                                            v-if="!isSingleUser && !isSingleTeamMember"
                                            type="button"
                                            :item="item"
                                            :team-members="teamMembers"
                                            @assignedUser="handleFilteredValue"
                                        />
                                    </div>
                                </div>
                                <div class="pickup-container custom-ellipsis">
                                    <h5 class="type-title">
                                        Pickup
                                        <span>
                                            {{ showDate(item, item.pickupStop) }}
                                        </span>
                                    </h5>

                                    {{ item.pickupStop.address }}
                                </div>
                                <div class="drop-container custom-ellipsis">
                                    <h5 class="type-title">
                                        Drop
                                        <span>
                                            {{ showDate(item, item.dropStop) }}
                                        </span>
                                    </h5>
                                    {{ item.dropStop.address }}
                                </div>
                                <div
                                    v-if="
                                        (selectedTeamMember.publicUserId === null ||
                                            selectedTeamMember.publicUserId === 'All') &&
                                            !$_isEmptyOrSpaces(item.assignedTo.fullName)
                                    "
                                >
                                    <h5 class="type-title">Driver</h5>
                                    <div>
                                        <span>{{ item.assignedTo.fullName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="md-layout-item">
                        <span class="text-center no-result-message">No available shipments to display.</span>
                    </div>
                </div>
                <div v-else>
                    <div class="stopbar-loader" v-if="!isLoading">
                        <fade-loader :loading="true" color="#333333" />
                        <span>LOADING</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { ReassignShipmentButton } from '@/pages/Shipments/buttons';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { AssignShipmentModal } from '@/pages/Shipments/components';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { handleRequests } from '@/helpers';

export default {
    name: 'MobileShipmentsOverview',
    mixins: [GeneralMixin],
    components: { ReassignShipmentButton, FadeLoader },
    data() {
        return {
            showFilter: false,
            selectedDate: null,
            selectedTeamMember: {
                name: '',
                publicUserId: null
            },
            selectedStatus: 'All',
            searchList: [],
            searchText: '',
            loading: false,
            list: [],
            teamMembers: [],
            today: null,
            teamMemberFilterOptions: []
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isLoading: 'isLoading',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        })
    },
    watch: {
        selectedDate(newValue) {
            this.handleFilteredValue();
        },
        'selectedTeamMember.publicUserId': async function(newValue) {
            this.handleFilteredValue();
        },
        // eslint-disable-next-line func-names
        searchText: _.debounce(function() {
            this.getFilteredSearchList();
        }, 500),
        isListLoading(newValue) {
            this.loading = newValue;
        },
        showFilter(newValue) {
            if (!newValue) {
                this.searchText = '';
            }
        },
        '$route.query': function({ userId }) {
            this.getMemberDetails(userId);
        }
    },
    async mounted() {
        const { userId } = this.$route.query;
        await this.getTeamMembers();

        this.selectedDate = moment().format(this.DATE_TYPES.internationalDate);
        this.today = moment().format(this.DATE_TYPES.internationalDate);

        if (userId) {
            this.getMemberDetails(userId);
        } else {
            // eslint-disable-next-line no-lonely-if
            if (this.isSingleUser || this.isSingleTeamMember) {
                this.getMemberDetails(this.user.publicUserId);
            } else {
                this.getMemberDetails(null);
                this.getUnassignedShipments();
            }
        }

        if (this.$refs.mdDatePicker) 
            this.$refs.mdDatePicker.$refs.input.$el.setAttribute('readonly', true);
    },
    methods: {
        async getTeamMembers() {
            this.$_handleLoaderState(true);
            this.loading = true;
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS');

            if (this.isSingleUser || this.isSingleTeamMember) {
                this.teamMemberFilterOptions = this.teamMembers;
            }

            if (this.teamMembers.length > 1) {
                const tempMembers = this.teamMembers.map((member) => ({
                    publicUserId: member.publicUserId,
                    fullName: `${member.firstName || ''} ${member.lastName || ''}`,
                    email: member.email
                }));

                const defaultOption = {
                    publicUserId: null,
                    fullName: 'Unassigned',
                    email: null
                };

                const defaultAllOption = {
                    publicUserId: 'All',
                    fullName: 'All',
                    email: null
                };

                this.teamMemberFilterOptions = [defaultOption].concat(tempMembers);
                this.teamMemberFilterOptions = [defaultAllOption].concat(this.teamMemberFilterOptions);
            }

            this.loading = false;
            this.$_handleLoaderState(false);
        },
        getMemberDetails(userId) {
            if (this.teamMemberFilterOptions.length > 0) {
                const member = this.teamMemberFilterOptions.find((x) => x.publicUserId === userId);
                this.selectedTeamMember.name = member.fullName;
                this.selectedTeamMember.publicUserId = userId;
            }
        },
        async handleFilteredValue() {
            this.$nextTick(async () => {
                if (this.selectedTeamMember.publicUserId) {
                    await this.getShipmentsByDate();
                } else {
                    await this.getUnassignedShipments();
                }
            });
        },
        async getUnassignedShipments() {
            this.$_handleLoaderState(true);
            this.loading = true;
            const endpoint = `/api/shipments/unassigned`;
            const { data } = await handleRequests(endpoint);
            this.list = data;
            this.searchList = data;
            this.loading = false;
            this.$_handleLoaderState(false);
        },
        async getShipmentsByDate() {
            this.$_handleLoaderState(true);
            this.loading = true;

            const publicUserId =
                this.selectedTeamMember.publicUserId === 'All' ? '' : this.selectedTeamMember.publicUserId;

            const endpoint = `/api/shipments/list/${this.selectedDate}?teamMemberPublicUserId=${publicUserId}`;
            const { data } = await handleRequests(endpoint);
            this.list = data;
            this.searchList = data;
            this.loading = false;
            this.$_handleLoaderState(false);
        },
        filterByTeamMember() {
            this.$modal
                .show(AssignShipmentModal, {
                    isFilterSelection: true,
                    teamMembers: this.teamMemberFilterOptions,
                    assignedTo: this.selectedTeamMember.publicUserId
                })
                .then((response) => {
                    const member = this.teamMemberFilterOptions.find((x) => x.publicUserId === response);
                    if (member) {
                        this.$router.push({ path: `/shipments/list`, query: { userId: response } });
                        this.selectedTeamMember.publicUserId = response;
                        this.selectedTeamMember.name = member.fullName;
                    } else {
                        this.$router.push({ path: `/shipments/list`, query: {} });
                        this.selectedTeamMember.publicUserId = null;
                        this.selectedTeamMember.name = 'Unassigned';
                    }
                });
        },
        showDate(shipmentParent, stop) {
            if (stop.tripDate) {
                return `(${this.$options.filters.dateFormat(stop.tripDate, this.DATE_TYPES.standardDate).trim()})`;
            }

            return shipmentParent.tripDate
                ? `(${this.$options.filters.dateFormat(shipmentParent.tripDate, this.DATE_TYPES.standardDate).trim()})`
                : '';
        }
    }
};
</script>

<style lang="scss" scoped>
.pickup-drop-container {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(219, 213, 213);
    .trip-date {
        background-color: #eee;
    }
    .reference-container {
        .type-title {
            color: #2b93ff;
            margin-top: 0;
        }
        > div {
            width: 60%;
            display: inline-block;
            vertical-align: middle;
        }
        > div:last-child {
            width: 40%;
            text-align: right;
        }
    }
    .type-title {
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0px;
    }
    .pickup-container,
    .drop-container {
        margin-bottom: 0;
    }
}
.shipment-item:first-child {
    margin-top: 30px;
}

::v-deep .reassign-btn-mobile > span {
    background-color: #2b93ff;
    color: #fff;
    width: 100px;
    height: 30px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    font-size: 15px;
    border-radius: 20px;
    margin: 0 auto;
}
</style>
