var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-blue" },
        [
          _c(
            "div",
            { staticClass: "card-icon" },
            [_c("md-icon", [_vm._v("description")])],
            1
          ),
        ]
      ),
      _c(
        "md-card-content",
        [
          _c(
            "md-table",
            { staticClass: "custom-paginated-table" },
            [
              _c(
                "md-table-row",
                { attrs: { slot: "header" }, slot: "header" },
                [
                  _c("md-table-head", { staticClass: "stop-order" }),
                  _c("md-table-head", { staticClass: "stop-reference" }, [
                    _vm._v("Stop Reference"),
                  ]),
                  _c("md-table-head", { staticClass: "stop-contact" }, [
                    _vm._v("Contact"),
                  ]),
                  _c("md-table-head", { staticClass: "stop-address" }, [
                    _vm._v("Address"),
                  ]),
                  _c("md-table-head", { staticClass: "stop-status" }, [
                    _vm._v("Status"),
                  ]),
                  _vm.tripDetails.assignedTo.publicUserId != null
                    ? _c("md-table-head", { staticClass: "stop-time" }, [
                        _vm._v(
                          "\n                    ETA/Arrival\n                "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._l(_vm.tripDetails.stopList, function (stopItem, stopIndex) {
                return _c(
                  "md-table-row",
                  {
                    key: stopIndex,
                    class:
                      stopItem.isStartStop || stopItem.isEndStop
                        ? "startend-table-row"
                        : "",
                  },
                  [
                    _c(
                      "md-table-cell",
                      { staticClass: "stop-order actual-stop-order" },
                      [
                        stopItem.isStartStop
                          ? _c("md-icon", [
                              _vm._v(
                                "\n                        pin_drop\n                    "
                              ),
                            ])
                          : stopItem.isEndStop
                          ? _c("md-icon", [
                              _vm._v(
                                "\n                        flag\n                    "
                              ),
                            ])
                          : _vm.tripDetails.hasRuns
                          ? _c("span", [_vm._v(_vm._s(stopItem.index))])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  stopItem.order != null ? stopItem.order : ""
                                )
                              ),
                            ]),
                      ],
                      1
                    ),
                    _c(
                      "md-table-cell",
                      { staticClass: "stop-reference" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "Stop Details",
                                params: { stopId: stopItem.stopId },
                              },
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(stopItem.stopRef) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("md-table-cell", { staticClass: "stop-contact" }, [
                      _c("span", { staticClass: "custom-ellipsis" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              stopItem.contact != null
                                ? stopItem.contact.name
                                : ""
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _c("md-table-cell", { staticClass: "stop-address" }, [
                      _c("span", { staticClass: "custom-ellipsis" }, [
                        _vm._v(_vm._s(_vm.$_displayAddressName(stopItem))),
                      ]),
                    ]),
                    _c("md-table-cell", { staticClass: "stop-status" }, [
                      !stopItem.isStartStop && !stopItem.isEndStop
                        ? _c(
                            "span",
                            {
                              staticClass: "status",
                              class:
                                "status-" +
                                stopItem.status
                                  .toLowerCase()
                                  .replace(/\s+/g, ""),
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(stopItem.status) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm.tripDetails.assignedTo.publicUserId != null
                      ? _c("md-table-cell", { staticClass: "stop-time" }, [
                          stopItem.status != "Cancelled" &&
                          stopItem.status != "On Hold"
                            ? _c("div", [
                                stopItem.actualDate
                                  ? _c("span", { staticClass: "bold-time" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("timeFormat")(
                                              stopItem.actualDate,
                                              _vm.DATE_TYPES.standardTime
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ])
                                  : stopItem.status == "Arrived" ||
                                    stopItem.status == "Complete" ||
                                    stopItem.status == "Failed"
                                  ? _c("span", { staticClass: "bold-time" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("timeFormat")(
                                              stopItem.arrivalDate,
                                              _vm.DATE_TYPES.standardTime
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ])
                                  : _c("span", [
                                      stopItem.originalEta
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm._f("timeFormat")(
                                                    stopItem.originalEta,
                                                    _vm.DATE_TYPES.standardTime
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }