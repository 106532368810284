var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "md-layout" }, [
      _vm.showDropdown
        ? _c("div", [
            _c(
              "div",
              { staticClass: "md-layout-item md-size-50" },
              [
                _c(
                  "form-group",
                  { attrs: { label: "Select an existing vehicle" } },
                  [
                    _c(
                      "md-select",
                      {
                        on: { "md-selected": _vm.getExistingDetails },
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      },
                      [
                        _c("md-option", { attrs: { value: "0" } }, [
                          _vm._v(
                            "\n                            Create New\n                        "
                          ),
                        ]),
                        _vm._l(_vm.dropdownList, function (x) {
                          return _c(
                            "md-option",
                            { key: x.assetId, attrs: { value: x.assetId } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(x.name) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._m(0),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-100" },
        [
          _c(
            "form-group",
            { attrs: { name: "name", label: "Asset Name*" } },
            [
              _c("md-input", {
                attrs: { disabled: _vm.isDisabled },
                model: {
                  value: _vm.data.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "name", $$v)
                  },
                  expression: "data.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c(
            "form-group",
            { attrs: { name: "make", label: "Vehicle Make" } },
            [
              _c("md-input", {
                directives: [{ name: "focus", rawName: "v-focus" }],
                attrs: { disabled: _vm.isDisabled },
                model: {
                  value: _vm.data.make,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "make", $$v)
                  },
                  expression: "data.make",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c(
            "form-group",
            { attrs: { name: "model", label: "Vehicle Model" } },
            [
              _c("md-input", {
                attrs: { disabled: _vm.isDisabled },
                model: {
                  value: _vm.data.model,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "model", $$v)
                  },
                  expression: "data.model",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c(
            "form-group",
            { attrs: { name: "modelYear", label: "Vehicle Model Year" } },
            [
              _c("md-input", {
                attrs: { disabled: _vm.isDisabled },
                model: {
                  value: _vm.data.modelYear,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "modelYear", $$v)
                  },
                  expression: "data.modelYear",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c(
            "form-group",
            { attrs: { name: "color", label: "Vehicle Color" } },
            [
              _c("md-input", {
                attrs: { disabled: _vm.isDisabled },
                model: {
                  value: _vm.data.color,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "color", $$v)
                  },
                  expression: "data.color",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c(
            "form-group",
            {
              attrs: {
                name: "vehicleVIN",
                label: "Vehicle Identification Number",
              },
            },
            [
              _c("md-input", {
                attrs: { disabled: _vm.isDisabled },
                model: {
                  value: _vm.data.vehicleVIN,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "vehicleVIN", $$v)
                  },
                  expression: "data.vehicleVIN",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c(
            "form-group",
            { attrs: { name: "licensePlate", label: "Vehicle License Plate" } },
            [
              _c("md-input", {
                attrs: { disabled: _vm.isDisabled },
                model: {
                  value: _vm.data.licensePlate,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "licensePlate", $$v)
                  },
                  expression: "data.licensePlate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "md-layout-item md-size-50" }, [
        _c(
          "div",
          { staticStyle: { "text-align": "left" } },
          [
            _c(
              "md-datepicker",
              {
                attrs: { disabled: _vm.isDisabled },
                model: {
                  value: _vm.data.vehicleRegistrationExpiryDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "vehicleRegistrationExpiryDate", $$v)
                  },
                  expression: "data.vehicleRegistrationExpiryDate",
                },
              },
              [
                _c("label", [
                  _vm._v(
                    "\n                        Vehicle Registration Expiry Date\n                    "
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c(
            "form-group",
            { attrs: { name: "vehicleType", label: "Vehicle Type" } },
            [
              _c(
                "md-select",
                {
                  attrs: { disabled: _vm.isDisabled },
                  model: {
                    value: _vm.data.vehicleType,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "vehicleType", $$v)
                    },
                    expression: "data.vehicleType",
                  },
                },
                [
                  _c("md-option", { attrs: { value: "Sedan" } }, [
                    _vm._v(
                      "\n                        Sedan\n                    "
                    ),
                  ]),
                  _c("md-option", { attrs: { value: "Van" } }, [
                    _vm._v(
                      "\n                        Van\n                    "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.assetCustomFieldList !== null
        ? _c(
            "div",
            { staticClass: "md-layout-item md-size-100" },
            _vm._l(_vm.assetCustomFieldList, function (customField, index) {
              return _c("custom-field-inputs", {
                key: index,
                attrs: {
                  "custom-field-definition": customField,
                  "initial-value": _vm.data[customField.label],
                },
                on: { changed: _vm.handleCustomFieldChanged },
              })
            }),
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "md-layout-item md-size-100" }, [
      _c("h5", [_vm._v("Vehicle Information:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }