var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _vm.showDropdown
      ? _c("div", [
          _c(
            "div",
            { staticClass: "md-layout-item md-size-50" },
            [
              _c(
                "form-group",
                { attrs: { label: "Select an existing tracker" } },
                [
                  _c(
                    "md-select",
                    {
                      on: { "md-selected": _vm.getExistingDetails },
                      model: {
                        value: _vm.selected,
                        callback: function ($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected",
                      },
                    },
                    [
                      _c("md-option", { attrs: { value: "0" } }, [
                        _vm._v(
                          "\n                        Create New\n                    "
                        ),
                      ]),
                      _vm._l(_vm.dropdownList, function (x) {
                        return _c(
                          "md-option",
                          { key: x.assetId, attrs: { value: x.assetId } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(x.name) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._m(0),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "form-group",
          { attrs: { name: "name", label: "Asset Name*" } },
          [
            _c("md-input", {
              attrs: { disabled: _vm.isDisabled },
              model: {
                value: _vm.data.name,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "name", $$v)
                },
                expression: "data.name",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-50" },
      [
        _c(
          "form-group",
          { attrs: { name: "brand", label: "Tracker Brand" } },
          [
            _c("md-input", {
              directives: [{ name: "focus", rawName: "v-focus" }],
              attrs: { disabled: _vm.isDisabled },
              model: {
                value: _vm.data.brand,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "brand", $$v)
                },
                expression: "data.brand",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-50" },
      [
        _c(
          "form-group",
          { attrs: { name: "model", label: "Tracker Model" } },
          [
            _c("md-input", {
              attrs: { disabled: _vm.isDisabled },
              model: {
                value: _vm.data.model,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "model", $$v)
                },
                expression: "data.model",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-50" },
      [
        _c(
          "form-group",
          { attrs: { name: "modelYear", label: "Tracker Model Year" } },
          [
            _c("md-input", {
              attrs: { disabled: _vm.isDisabled },
              model: {
                value: _vm.data.modelYear,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "modelYear", $$v)
                },
                expression: "data.modelYear",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-50" },
      [
        _c(
          "form-group",
          { attrs: { name: "trackerReference", label: "Tracker IMEI*" } },
          [
            _c("md-input", {
              attrs: { disabled: _vm.isUpdateAsset },
              model: {
                value: _vm.data.trackerReference,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "trackerReference", $$v)
                },
                expression: "data.trackerReference",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm.assetCustomFieldList !== null
      ? _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          _vm._l(_vm.assetCustomFieldList, function (customField, index) {
            return _c("custom-field-inputs", {
              key: index,
              attrs: {
                "custom-field-definition": customField,
                "initial-value": _vm.data[customField.name],
              },
              on: { changed: _vm.handleCustomFieldChanged },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "md-layout-item md-size-100" }, [
      _c("h5", [_vm._v("Tracker Information:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }