var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Create Asset")]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "modal-body" }, [
      _c(
        "div",
        { staticClass: "md-layout create-asset-container" },
        [
          _c(
            "div",
            { staticClass: "md-layout-item md-size-100" },
            [
              _vm.showTeamRegion
                ? _c(
                    "form-group",
                    { attrs: { name: "teamRegion", label: "Team Region" } },
                    [
                      _c("team-region-options", {
                        attrs: {
                          selected: _vm.assetData.teamRegionId,
                          "show-auto": false,
                        },
                        on: { selectedOption: _vm.handleTeamRegionChanged },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-size-100" },
            [
              _c(
                "form-group",
                { attrs: { label: "Select asset type" } },
                [
                  _c(
                    "md-select",
                    {
                      on: { "md-selected": _vm.getAssetType },
                      model: {
                        value: _vm.selectedAssetType,
                        callback: function ($$v) {
                          _vm.selectedAssetType = $$v
                        },
                        expression: "selectedAssetType",
                      },
                    },
                    _vm._l(_vm.assetTypes, function (assetType, index) {
                      return _c(
                        "md-option",
                        { key: index, attrs: { value: assetType } },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(assetType) +
                              "\n                        "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "form-wrapper",
            {
              staticClass: "form-wrapper",
              attrs: { validator: _vm.$v.assetData },
            },
            [
              _vm.selectedAssetType &&
              _vm.selectedAssetType.toLowerCase() !== "tracker"
                ? _c("div", { staticClass: "md-layout" }, [
                    _c(
                      "div",
                      { staticClass: "md-layout-item md-size-100" },
                      [
                        _c(
                          "form-group",
                          { attrs: { label: "Asset Name*", name: "name" } },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.assetData.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.assetData, "name", $$v)
                                },
                                expression: "assetData.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.selectedAssetType &&
                    (_vm.selectedAssetType.toLowerCase() === "vehicle" ||
                      _vm.selectedAssetType.toLowerCase() === "trailer")
                      ? _c(
                          "div",
                          { staticClass: "md-layout-item md-size-100" },
                          [
                            _c(
                              "form-group",
                              {
                                attrs: {
                                  label: "Vehicle License Plate",
                                  name: "vehicleLicensePlate",
                                },
                              },
                              [
                                _c("md-input", {
                                  model: {
                                    value: _vm.assetData.vehicleLicensePlate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.assetData,
                                        "vehicleLicensePlate",
                                        $$v
                                      )
                                    },
                                    expression: "assetData.vehicleLicensePlate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedAssetCustomFieldList !== null
                      ? _c(
                          "div",
                          { staticClass: "md-layout-item md-size-100" },
                          _vm._l(
                            _vm.selectedAssetCustomFieldList,
                            function (customField, index) {
                              return _c("custom-field-inputs", {
                                key: index,
                                attrs: {
                                  "custom-field-definition": customField,
                                },
                                on: { changed: _vm.handleCustomFieldChanged },
                              })
                            }
                          ),
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "form-wrapper",
            {
              staticClass: "form-wrapper",
              attrs: { validator: _vm.$v.tracker },
            },
            [
              _vm.selectedAssetType === "Tracker"
                ? _c("tracker-step", {
                    attrs: {
                      data: _vm.tracker,
                      "dropdown-list": _vm.trackerDropdownList,
                      "is-disabled": _vm.isTrackerDisabled,
                      "selected-asset": _vm.selectedTracker,
                      "show-dropdown": _vm.showDropdown,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.showAssetGroup
            ? _c("asset-group", {
                attrs: {
                  "team-region-id": _vm.assetData.teamRegionId,
                  "asset-group-id": _vm.assetData.assetGroupId,
                },
                on: { changed: _vm.handleAssetGroupChanged },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            on: {
              click: function ($event) {
                return _vm.create()
              },
            },
          },
          [_vm._v("Create")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }