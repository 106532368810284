var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "visibilityhandler", rawName: "v-visibilityhandler" },
      ],
    },
    [
      _c("div", { staticClass: "md-layout" }, [
        _c("div", { staticClass: "md-layout-item" }, [
          _c("div", { staticClass: "custom-toolbar" }, [
            _c(
              "div",
              { staticClass: "custom-toolbar-start" },
              [
                _c("h3", { staticClass: "title triplist-title" }, [
                  _vm._v("Trip Dashboard"),
                ]),
                _c("trip-filter-component", {
                  attrs: {
                    "trip-date": _vm.selectedDate,
                    "team-region-id": _vm.selectedTeamRegionId,
                    "team-members": function () {
                      return this$1.teamMembers
                    },
                  },
                  on: { onFilterTrips: _vm.handleFilterOrSearch },
                }),
              ],
              1
            ),
            !_vm.isReadOnlyUser && _vm.teamMembers.length > 1
              ? _c(
                  "div",
                  { staticClass: "custom-toolbar-end" },
                  [
                    _vm.$root.isDesktop
                      ? _c("search-component", {
                          attrs: {
                            "search-filter": _vm.currentSearchTextValue,
                          },
                          on: { onSearch: _vm.handleStopsSearch },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c(
                          "md-button",
                          {
                            staticClass:
                              "md-primary md-just-icon md-round pull-right header-button",
                            attrs: { title: "Create Trip" },
                            on: { click: _vm.goToCreateTripPage },
                          },
                          [_c("md-icon", [_vm._v("add")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        !_vm.isSupportStaff
                          ? _c(
                              "md-button",
                              {
                                staticClass:
                                  "md-primary md-just-icon md-round pull-right header-button",
                                attrs: { title: "Export Trips" },
                                on: {
                                  click: function ($event) {
                                    return _vm.exportTrips(null, "")
                                  },
                                },
                              },
                              [_c("md-icon", [_vm._v("get_app")])],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "md-button",
                          {
                            staticClass:
                              "md-primary md-just-icon md-round pull-right header-button",
                            attrs: { title: "Send run sheet/s to my email" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.sendRunSheetPdfToEmail()
                              },
                            },
                          },
                          [_c("md-icon", [_vm._v("forward_to_inbox")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        !_vm.isSupportStaff
                          ? _c(
                              "md-button",
                              {
                                staticClass:
                                  "md-primary md-just-icon md-round pull-right header-button",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.sendBulkSMS()
                                  },
                                },
                              },
                              [_c("md-icon", [_vm._v("mobile_screen_share")])],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _c(
        "div",
        [
          _vm.isOfficeStaff
            ? _c(
                "md-button",
                {
                  staticClass: "md-primary btn-refresh",
                  on: { click: _vm.showAllTripsOnMap },
                },
                [_vm._v("\n            Show trips on map\n        ")]
              )
            : _vm._e(),
          _c(
            "md-button",
            {
              staticClass: "md-primary btn-refresh",
              on: { click: _vm.getTripDashboard },
            },
            [_vm._v("\n            Refresh dashboard stats\n        ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Stops Overview",
                    query: {
                      status: "All",
                      fromDate: _vm.selectedDate,
                      toDate: _vm.selectedDate,
                      teamRegionId: _vm.selectedTeamRegionId,
                    },
                  },
                  target: "_blank",
                },
              },
              [
                _c(
                  "stats-card",
                  {
                    staticClass: "custom-stats-card",
                    attrs: { "header-color": "primary" },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c(
                        "div",
                        { staticClass: "card-icon custom-card-icon" },
                        [_c("md-icon", [_vm._v("analytics")])],
                        1
                      ),
                      _c("p", { staticClass: "category" }, [
                        _vm._v(
                          "\n                            Total Stops\n                        "
                        ),
                      ]),
                      _c("h3", { staticClass: "title" }, [
                        _vm.dashboardLoading
                          ? _c("div", { staticClass: "btn-loader" })
                          : _c("span", [
                              _vm._v(_vm._s(_vm.dashboard.totalStops)),
                            ]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Stops Overview",
                    query: {
                      status: "Active",
                      fromDate: _vm.selectedDate,
                      toDate: _vm.selectedDate,
                      teamRegionId: _vm.selectedTeamRegionId,
                    },
                  },
                  target: "_blank",
                },
              },
              [
                _c(
                  "stats-card",
                  {
                    staticClass: "custom-stats-card",
                    attrs: { "header-color": "blue" },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c(
                        "div",
                        { staticClass: "card-icon custom-card-icon" },
                        [_c("md-icon", [_vm._v("local_shipping")])],
                        1
                      ),
                      _c("p", { staticClass: "category" }, [
                        _vm._v(
                          "\n                            Active Stops\n                        "
                        ),
                      ]),
                      _c("h3", { staticClass: "title" }, [
                        _vm.dashboardLoading
                          ? _c("div", { staticClass: "btn-loader" })
                          : _c("span", [
                              _vm._v(_vm._s(_vm.dashboard.activeStops)),
                            ]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Stops Overview",
                    query: {
                      status: "Complete",
                      fromDate: _vm.selectedDate,
                      toDate: _vm.selectedDate,
                      teamRegionId: _vm.selectedTeamRegionId,
                    },
                  },
                  target: "_blank",
                },
              },
              [
                _c(
                  "stats-card",
                  {
                    staticClass: "custom-stats-card",
                    attrs: { "header-color": "green" },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c(
                        "div",
                        { staticClass: "card-icon custom-card-icon" },
                        [_c("md-icon", [_vm._v("done")])],
                        1
                      ),
                      _c("p", { staticClass: "category" }, [
                        _vm._v(
                          "\n                            Completed Stops\n                        "
                        ),
                      ]),
                      _c("h3", { staticClass: "title" }, [
                        _vm.dashboardLoading
                          ? _c("div", { staticClass: "btn-loader" })
                          : _c("span", [
                              _vm._v(_vm._s(_vm.dashboard.completedStops)),
                            ]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Stops Overview",
                    query: {
                      status: "Failed",
                      fromDate: _vm.selectedDate,
                      toDate: _vm.selectedDate,
                      teamRegionId: _vm.selectedTeamRegionId,
                    },
                  },
                  target: "_blank",
                },
              },
              [
                _c(
                  "stats-card",
                  {
                    staticClass: "custom-stats-card",
                    attrs: { "header-color": "warning" },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c(
                        "div",
                        { staticClass: "card-icon custom-card-icon" },
                        [_c("md-icon", [_vm._v("report_problem")])],
                        1
                      ),
                      _c("p", { staticClass: "category" }, [
                        _vm._v(
                          "\n                            Failed Stops\n                        "
                        ),
                      ]),
                      _c("h3", { staticClass: "title" }, [
                        _vm.dashboardLoading
                          ? _c("div", { staticClass: "btn-loader" })
                          : _c("span", [
                              _vm._v(_vm._s(_vm.dashboard.failedStops)),
                            ]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Stops Overview",
                    query: {
                      status: "Cancelled",
                      fromDate: _vm.selectedDate,
                      toDate: _vm.selectedDate,
                      teamRegionId: _vm.selectedTeamRegionId,
                    },
                  },
                  target: "_blank",
                },
              },
              [
                _c(
                  "stats-card",
                  {
                    staticClass: "custom-stats-card",
                    attrs: { "header-color": "danger" },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c(
                        "div",
                        { staticClass: "card-icon custom-card-icon" },
                        [_c("md-icon", [_vm._v("cancel")])],
                        1
                      ),
                      _c("p", { staticClass: "category" }, [
                        _vm._v(
                          "\n                            Cancelled Stops\n                        "
                        ),
                      ]),
                      _c("h3", { staticClass: "title" }, [
                        _vm.dashboardLoading
                          ? _c("div", { staticClass: "btn-loader" })
                          : _c("span", [
                              _vm._v(_vm._s(_vm.dashboard.cancelledStops)),
                            ]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Stops Overview",
                    query: {
                      status: "Active",
                      fromDate: _vm.selectedDate,
                      toDate: _vm.selectedDate,
                      teamMemberPublicUserId: "Unassigned",
                      teamRegionId: _vm.selectedTeamRegionId,
                    },
                  },
                  target: "_blank",
                },
              },
              [
                _c(
                  "stats-card",
                  {
                    staticClass: "custom-stats-card",
                    attrs: { "header-color": "blue" },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c(
                        "div",
                        { staticClass: "card-icon custom-card-icon" },
                        [_c("md-icon", [_vm._v("person")])],
                        1
                      ),
                      _c("p", { staticClass: "category" }, [
                        _vm._v(
                          "\n                            Unassigned Stops\n                        "
                        ),
                      ]),
                      _c("h3", { staticClass: "title" }, [
                        _vm.dashboardLoading
                          ? _c("div", { staticClass: "btn-loader" })
                          : _c("span", [
                              _vm._v(_vm._s(_vm.dashboard.unassignedStops)),
                            ]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Stops Overview",
                    query: {
                      status: "Complete",
                      fromDate: _vm.selectedDate,
                      toDate: _vm.selectedDate,
                      driverRating: 5,
                      teamRegionId: _vm.selectedTeamRegionId,
                    },
                  },
                  target: "_blank",
                },
              },
              [
                _c(
                  "stats-card",
                  {
                    staticClass: "custom-stats-card",
                    attrs: { "header-color": "default" },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c(
                        "div",
                        { staticClass: "card-icon custom-card-icon" },
                        [_c("md-icon", [_vm._v("star")])],
                        1
                      ),
                      _c("p", { staticClass: "category" }, [
                        _vm._v(
                          "\n                            Average Driver Rating\n                        "
                        ),
                      ]),
                      _c("h3", { staticClass: "title" }, [
                        _vm.dashboardLoading
                          ? _c("div", { staticClass: "btn-loader" })
                          : _c("span", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.dashboard.averageDriverRating == null
                                      ? "-"
                                      : _vm.dashboard.averageDriverRating
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "md-layout" }, [
        _vm.tripList.length
          ? _c(
              "div",
              { staticClass: "md-layout-item margin-space-bot" },
              _vm._l(_vm.tripList, function (tripItem, tripIndex) {
                return _c(
                  "md-list",
                  {
                    key: tripIndex,
                    staticClass: "trip-list-body",
                    attrs: { "md-expand-single": _vm.expandSingle },
                  },
                  [
                    _c(
                      "md-list-item",
                      {
                        attrs: {
                          "md-expand": "",
                          "md-ripple": false,
                          "md-expanded": tripItem.isOpen,
                        },
                        on: {
                          "update:mdExpanded": function ($event) {
                            return _vm.$set(tripItem, "isOpen", $event)
                          },
                          "update:md-expanded": function ($event) {
                            return _vm.$set(tripItem, "isOpen", $event)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "trip-accordion-header",
                            class: tripItem.isOpen ? "trip-open" : "",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toggleTripItem(
                                  tripItem.user.publicUserId,
                                  tripItem.tripId
                                )
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "member-name" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "custom-ellipsis",
                                  class:
                                    tripItem.user.publicUserId ||
                                    tripItem.carrierTeam
                                      ? ""
                                      : "grey-text",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.getDisplayName(tripItem)) +
                                        "\n                                "
                                    ),
                                  ]),
                                  tripItem.vehicleLoad && tripItem.isOpen
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "vehicle-max-load-header",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.getDisplayVehicleLoad(
                                                  tripItem
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  tripItem.vehicleLoad && tripItem.isOpen
                                    ? _c("br")
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "display-sub-name",
                                      style:
                                        tripItem.vehicleLoad && tripItem.isOpen
                                          ? "margin-left: 15px"
                                          : "",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.getDisplaySubName(tripItem)
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "completed-total" }, [
                              _c("div", [
                                !_vm.isEmptyRunScheduleTrip(tripItem)
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            tripItem.completedStops +
                                              " of " +
                                              tripItem.totalStops +
                                              " stops completed"
                                          ) +
                                          "\n                                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("trip-status-button", {
                                    staticClass: "trip-status-button",
                                    attrs: { trip: tripItem, "is-modal": true },
                                    on: {
                                      statusUpdated: _vm.onChangedTripStatus,
                                    },
                                  }),
                                  _vm.isAdmin
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "grey-lock",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.rebuildTrip(
                                                tripItem.tripId
                                              )
                                            },
                                          },
                                        },
                                        [_c("md-icon", [_vm._v("build")])],
                                        1
                                      )
                                    : _vm._e(),
                                  tripItem.isFixedStopOrder &&
                                  !_vm.isCustomer &&
                                  !_vm.isSupportStaff
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "gold-lock",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.unlockTrip(
                                                tripItem.tripId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("md-icon", [_vm._v("lock")]),
                                          _c(
                                            "md-tooltip",
                                            {
                                              attrs: { "md-direction": "left" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        The stops for this trip is in a fixed order. Click to unlock the stop order.\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !tripItem.isFixedStopOrder &&
                                  !_vm.isCustomer &&
                                  !_vm.isSupportStaff
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "grey-lock",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.lockTrip(
                                                tripItem.tripId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("md-icon", [_vm._v("lock_open")]),
                                          _c(
                                            "md-tooltip",
                                            {
                                              attrs: { "md-direction": "left" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        The stops for this trip is not in a fixed order. Click to lock the stop\n                                        order.\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  tripItem.isOpen &&
                                  _vm.isAValidDate &&
                                  !_vm.isReadOnlyUser
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.setIconClassBasedOnTripState(
                                              tripItem,
                                              "export-stops"
                                            ),
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.reverseOrder(
                                                tripItem.tripId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("md-icon", [_vm._v("swap_vert")]),
                                          _c(
                                            "md-tooltip",
                                            {
                                              attrs: { "md-direction": "left" },
                                            },
                                            [
                                              _vm._v(
                                                "Reverse the order of the stops."
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  tripItem.isOpen && !tripItem.isStopListLoading
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "export-stops",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.getStopList(
                                                tripItem.user.publicUserId,
                                                tripItem.tripId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("md-icon", [_vm._v("refresh")]),
                                          _c(
                                            "md-tooltip",
                                            {
                                              attrs: { "md-direction": "left" },
                                            },
                                            [_vm._v("Refresh List")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    { staticClass: "view-map" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "Trip Details",
                                              params: {
                                                tripId: tripItem.tripId,
                                              },
                                            },
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _c("md-icon", [
                                            _vm._v("description"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "md-tooltip",
                                        { attrs: { "md-direction": "left" } },
                                        [_vm._v("View trip details")]
                                      ),
                                    ],
                                    1
                                  ),
                                  !_vm.isReadOnlyUser
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.setIconClassBasedOnTripState(
                                              tripItem,
                                              "export-stops"
                                            ),
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.sendBulkSMS(
                                                tripItem.user.publicUserId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("md-icon", [
                                            _vm._v("mobile_screen_share"),
                                          ]),
                                          _c(
                                            "md-tooltip",
                                            {
                                              attrs: { "md-direction": "left" },
                                            },
                                            [_vm._v("Send Bulk SMS")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isReadOnlyUser
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "export-stops",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.exportTrips(
                                                tripItem.tripId,
                                                tripItem.user.fullName
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("md-icon", [
                                            _vm._v("arrow_circle_down"),
                                          ]),
                                          _c(
                                            "md-tooltip",
                                            {
                                              attrs: { "md-direction": "left" },
                                            },
                                            [
                                              _vm._v(
                                                "Export stops of this trip"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  tripItem.user.publicUserId
                                    ? _c(
                                        "span",
                                        { staticClass: "view-map" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "Maps Overview Active User Panel",
                                                  params: {
                                                    date: _vm.selectedDate,
                                                    userId:
                                                      tripItem.user
                                                        .publicUserId,
                                                  },
                                                  query: { from: "trip" },
                                                },
                                                target: "_blank",
                                              },
                                            },
                                            [_c("md-icon", [_vm._v("map")])],
                                            1
                                          ),
                                          _c(
                                            "md-tooltip",
                                            {
                                              attrs: { "md-direction": "left" },
                                            },
                                            [_vm._v("View stops in a map")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isReadOnlyUser
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "export-stops",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.updateTrip(
                                                tripItem.tripId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("md-icon", [_vm._v("edit")]),
                                          _c(
                                            "md-tooltip",
                                            {
                                              attrs: { "md-direction": "left" },
                                            },
                                            [_vm._v("Edit Trip")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isSingleTeamMember &&
                                  _vm.canReassignTrip &&
                                  _vm.teamMembers.length &&
                                  _vm.isAValidDate &&
                                  !_vm.isSupportStaff
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "export-stops",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.reassignTrip(
                                                tripItem.tripId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("md-icon", [_vm._v("person")]),
                                          _c(
                                            "md-tooltip",
                                            {
                                              attrs: { "md-direction": "left" },
                                            },
                                            [_vm._v("Reassign Trip")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  tripItem.user.publicUserId &&
                                  !_vm.isSupportStaff
                                    ? _c("send-sms-button", {
                                        class: _vm.setIconClassBasedOnTripState(
                                          tripItem,
                                          "export-stops"
                                        ),
                                        attrs: {
                                          "public-user-id":
                                            tripItem.user.publicUserId,
                                          name: tripItem.user.fullName,
                                        },
                                      })
                                    : _vm._e(),
                                  tripItem.totalStops > 0 && !_vm.isSupportStaff
                                    ? _c("generate-label-button", {
                                        class: _vm.setIconClassBasedOnTripState(
                                          tripItem,
                                          "export-stops"
                                        ),
                                        attrs: {
                                          id: tripItem.tripId,
                                          type: "Trip",
                                        },
                                      })
                                    : _vm._e(),
                                  !_vm.isSingleTeamMember &&
                                  !_vm.isIndividualUser &&
                                  _vm.hasTeamAccess &&
                                  _vm.teamMembers.length
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.setIconClassBasedOnTripState(
                                              tripItem,
                                              "export-stops"
                                            ),
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.createRunSchedule(
                                                tripItem.tripId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("md-icon", [_vm._v("more_time")]),
                                          _c(
                                            "md-tooltip",
                                            {
                                              attrs: { "md-direction": "left" },
                                            },
                                            [_vm._v("Create Run Schedule")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "progress-bar",
                                      class: _vm.getProgressBarColor(
                                        tripItem.completedStops,
                                        tripItem.totalStops
                                      ),
                                    },
                                    [
                                      _c("md-progress-bar", {
                                        attrs: {
                                          "md-mode": "determinate",
                                          "md-value":
                                            _vm.calculateProgressBarValue(
                                              tripItem.completedStops,
                                              tripItem.totalStops
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "md-list",
                          {
                            staticClass: "stop-table-body",
                            attrs: { slot: "md-expand" },
                            slot: "md-expand",
                          },
                          [
                            tripItem.stopList.length > 0
                              ? _c(
                                  "md-table",
                                  {
                                    staticClass:
                                      "context-menu-support custom-paginated-table",
                                  },
                                  [
                                    tripItem.isShowAlert
                                      ? _c(
                                          "div",
                                          { staticClass: "alert-section" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "alert alert-warning alert-dismissible fade show",
                                                attrs: { role: "alert" },
                                              },
                                              [
                                                _c("div", [
                                                  _c("div", [
                                                    _c("strong", [
                                                      _vm._v("ATTENTION!"),
                                                    ]),
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          tripItem.alertMessage
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                    tripItem.showRebuild
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "rebuild-link",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.rebuildTrip(
                                                                  tripItem.tripId
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                Click here to rebuild route.\n                                            "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]),
                                                ]),
                                                _c(
                                                  "div",
                                                  [
                                                    tripItem.alertType ===
                                                    "save"
                                                      ? _c(
                                                          "md-button",
                                                          {
                                                            staticClass:
                                                              "md-primary md-just-icon md-round",
                                                            attrs: {
                                                              title:
                                                                "Save new order of stops",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.saveConfiguration(
                                                                  tripItem.tripId
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("md-icon", [
                                                              _vm._v("save"),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : tripItem.alertType ===
                                                        "refresh"
                                                      ? _c(
                                                          "md-button",
                                                          {
                                                            staticClass:
                                                              "md-primary md-just-icon md-round",
                                                            attrs: {
                                                              title:
                                                                "Save new order of stops",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.getStopList(
                                                                  tripItem.user
                                                                    .publicUserId,
                                                                  tripItem.tripId
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("md-icon", [
                                                              _vm._v("refresh"),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "draggable",
                                      {
                                        staticClass: "custom-draggable",
                                        class: [
                                          _vm.isAValidDate
                                            ? "show-draggable"
                                            : "",
                                          tripItem.isFixedStopOrder
                                            ? "has-actual-order"
                                            : "",
                                        ],
                                        attrs: {
                                          draggable: ".drag-item",
                                          disabled:
                                            !_vm.isAValidDate ||
                                            tripItem.user.publicUserId ==
                                              null ||
                                            _vm.isReadOnlyUser,
                                          move: _vm.checkMove,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.stopOrderChanged(
                                              tripItem.tripId
                                            )
                                          },
                                          end: _vm.endDrag,
                                        },
                                        model: {
                                          value: tripItem.stopList,
                                          callback: function ($$v) {
                                            _vm.$set(tripItem, "stopList", $$v)
                                          },
                                          expression: "tripItem.stopList",
                                        },
                                      },
                                      [
                                        _c(
                                          "md-table-row",
                                          {
                                            attrs: { slot: "header" },
                                            slot: "header",
                                          },
                                          [
                                            _vm.isAValidDate &&
                                            tripItem.user.publicUserId != null
                                              ? _c("md-table-head", {
                                                  staticClass: "drag-icon",
                                                })
                                              : _vm._e(),
                                            _c("md-table-head", {
                                              staticClass: "stop-order",
                                            }),
                                            tripItem.isFixedStopOrder ||
                                            tripItem.hasRuns
                                              ? _c(
                                                  "md-table-head",
                                                  {
                                                    staticClass:
                                                      "stop-order actual-stop-order",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        Planned\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            tripItem.hasStopAlerts
                                              ? _c("md-table-head", {
                                                  staticClass: "stop-alerts",
                                                })
                                              : _vm._e(),
                                            _c("TableHeaderDropdown", {
                                              staticClass: "stop-reference",
                                              attrs: {
                                                "column-icon":
                                                  "keyboard_arrow_down",
                                                "default-text":
                                                  _vm.tableColumnOptions
                                                    .columns[
                                                    _vm.columnKeys
                                                      .referenceSource
                                                  ].name,
                                                "dropdown-options":
                                                  _vm.tableColumnKeyOptions
                                                    .referenceSource,
                                                "selected-option":
                                                  _vm.tableColumnOptions
                                                    .columns[
                                                    _vm.columnKeys
                                                      .referenceSource
                                                  ],
                                              },
                                              on: {
                                                selectedOption: function (
                                                  $event
                                                ) {
                                                  return _vm.$_handleColumnSwitch(
                                                    $event,
                                                    _vm.columnKeys
                                                      .referenceSource,
                                                    "trips-overview.defaults.stop-table-options"
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "md-table-head",
                                              { staticClass: "stop-contact" },
                                              [_vm._v("Contact")]
                                            ),
                                            _c(
                                              "md-table-head",
                                              { staticClass: "stop-address" },
                                              [_vm._v("Address")]
                                            ),
                                            _c(
                                              "md-table-head",
                                              { staticClass: "stop-status" },
                                              [_vm._v("Status")]
                                            ),
                                            _c(
                                              "md-table-head",
                                              { staticClass: "delivery-time" },
                                              [_vm._v("Delivery Time")]
                                            ),
                                            _c(
                                              "md-table-head",
                                              { staticClass: "stop-time" },
                                              [_vm._v("ETA/Arrival")]
                                            ),
                                            _vm.user
                                              .vehicleCapacityUnitsConfiguration !=
                                              null &&
                                            _vm.user
                                              .vehicleCapacityUnitsConfiguration
                                              .length > 0
                                              ? _c(
                                                  "md-table-head",
                                                  {
                                                    staticClass:
                                                      "stop-load-header",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        Load " +
                                                        _vm._s(
                                                          _vm.selectedCapacitySymbol
                                                            ? "(" +
                                                                _vm.selectedCapacitySymbol +
                                                                ")"
                                                            : ""
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.user
                                              .vehicleCapacityUnitsConfiguration !=
                                              null &&
                                            _vm.user
                                              .vehicleCapacityUnitsConfiguration
                                              .length > 0
                                              ? _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "md-table-head running-load-head",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "md-table-head-container running-load-menu",
                                                      },
                                                      [
                                                        _c(
                                                          "drop-down",
                                                          {
                                                            attrs: {
                                                              direction: "down",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "load-header-text",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "md-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "toggle-tooltip",
                                                                    attrs: {
                                                                      "md-direction":
                                                                        "top",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                        " +
                                                                        _vm._s(
                                                                          _vm.selectedViewType
                                                                        ) +
                                                                        " (" +
                                                                        _vm._s(
                                                                          _vm.selectedCapacityLabel
                                                                        ) +
                                                                        ")\n                                                    "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  "\n                                                    " +
                                                                    _vm._s(
                                                                      _vm.loadHeaderText
                                                                    ) +
                                                                    "\n                                                    " +
                                                                    _vm._s(
                                                                      _vm.selectedCapacitySymbol
                                                                        ? "(" +
                                                                            _vm.selectedCapacitySymbol +
                                                                            ")"
                                                                        : ""
                                                                    ) +
                                                                    "\n                                                    "
                                                                ),
                                                                _c(
                                                                  "md-icon",
                                                                  {
                                                                    staticClass:
                                                                      "icon-arrow",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "expand_more"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "ul",
                                                              {
                                                                staticClass:
                                                                  "dropdown-menu",
                                                              },
                                                              [
                                                                _vm._l(
                                                                  _vm.user
                                                                    .vehicleCapacityUnitsConfiguration,
                                                                  function (
                                                                    vehicleCapacity,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key: index,
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "md-icon",
                                                                          {
                                                                            class:
                                                                              [
                                                                                "icon-check",
                                                                                _vm.selectedCapacityType ===
                                                                                vehicleCapacity.type
                                                                                  ? "icon-check-color"
                                                                                  : "",
                                                                              ],
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                            check\n                                                        "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                href: "#",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.changeCapacityType(
                                                                                    vehicleCapacity.type
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                            " +
                                                                                _vm._s(
                                                                                  vehicleCapacity.label
                                                                                ) +
                                                                                "\n                                                        "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                _c("hr", {
                                                                  staticClass:
                                                                    "border",
                                                                }),
                                                                _vm._l(
                                                                  _vm.viewTypes,
                                                                  function (
                                                                    viewType,
                                                                    viewindex
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        key:
                                                                          "v" +
                                                                          viewindex,
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "md-icon",
                                                                          {
                                                                            class:
                                                                              [
                                                                                "icon-check",
                                                                                _vm.selectedViewType ===
                                                                                viewType
                                                                                  ? "icon-check-color"
                                                                                  : "",
                                                                              ],
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                            check\n                                                        "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                href: "#",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.changeSelectViewType(
                                                                                    viewType
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                            " +
                                                                                _vm._s(
                                                                                  viewType
                                                                                ) +
                                                                                "\n                                                        "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "md-table-head",
                                              { staticClass: "stop-time" },
                                              [_vm._v("Actions")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._l(
                                          _vm.getStopListItems(tripItem),
                                          function (stopItem, stopIndex) {
                                            return _c(
                                              "md-table-row",
                                              {
                                                key: stopIndex,
                                                class: [
                                                  stopItem.status !=
                                                    "Complete" &&
                                                  stopItem.status !=
                                                    "Cancelled" &&
                                                  stopItem.status !=
                                                    "Arrived" &&
                                                  stopItem.status != "Failed" &&
                                                  stopItem.status !=
                                                    "On Hold" &&
                                                  !stopItem.isRunHeader &&
                                                  !stopItem.isStartStop &&
                                                  !stopItem.isEndStop &&
                                                  tripItem.isSortable
                                                    ? "drag-item"
                                                    : "",
                                                  stopItem.isStartStop ||
                                                  stopItem.isEndStop
                                                    ? "trip-status"
                                                    : "",
                                                ],
                                              },
                                              [
                                                stopItem.isRunHeader
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "stop-run-header",
                                                        attrs: {
                                                          colspan: "12",
                                                        },
                                                      },
                                                      [
                                                        stopItem.runName
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "Run " +
                                                                  _vm._s(
                                                                    stopItem.runName
                                                                  )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.isAValidDate &&
                                                tripItem.user.publicUserId !=
                                                  null &&
                                                !stopItem.isRunHeader
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "drag-icon",
                                                      },
                                                      [
                                                        stopItem.status !=
                                                          "Complete" &&
                                                        stopItem.status !=
                                                          "Cancelled" &&
                                                        stopItem.status !=
                                                          "Arrived" &&
                                                        stopItem.status !=
                                                          "Started" &&
                                                        stopItem.status !=
                                                          "Failed" &&
                                                        stopItem.status !=
                                                          "On Hold" &&
                                                        !stopItem.isEndStop &&
                                                        tripItem.isSortable
                                                          ? _c("md-icon", [
                                                              _vm._v(
                                                                "\n                                            drag_indicator\n                                        "
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !stopItem.isRunHeader
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "stop-order",
                                                      },
                                                      [
                                                        !stopItem.isRunHeader &&
                                                        !stopItem.isStartStop &&
                                                        !stopItem.isEndStop &&
                                                        ((stopItem.status !==
                                                          "Complete" &&
                                                          stopItem.status !==
                                                            "Cancelled" &&
                                                          stopItem.status !==
                                                            "Failed" &&
                                                          stopItem.status !=
                                                            "On Hold") ||
                                                          tripItem.isFixedStopOrder)
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "\n                                            " +
                                                                  _vm._s(
                                                                    stopItem.stopIndex
                                                                  ) +
                                                                  "\n                                        "
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        stopItem.isStartStop
                                                          ? _c("md-icon", [
                                                              _vm._v(
                                                                "pin_drop"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        stopItem.isEndStop
                                                          ? _c("md-icon", [
                                                              _vm._v("flag"),
                                                            ])
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                (tripItem.isFixedStopOrder ||
                                                  tripItem.hasRuns) &&
                                                !stopItem.isRunHeader
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "stop-order actual-stop-order",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              stopItem.index
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                tripItem.hasStopAlerts &&
                                                !stopItem.isRunHeader
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "stop-alerts",
                                                      },
                                                      [
                                                        stopItem.importErrors &&
                                                        stopItem.importErrors
                                                          .length > 0
                                                          ? _c(
                                                              "md-tooltip",
                                                              {
                                                                attrs: {
                                                                  "md-direction":
                                                                    "top",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "ul",
                                                                  _vm._l(
                                                                    stopItem.importErrors,
                                                                    function (
                                                                      importError,
                                                                      importErrorIndex
                                                                    ) {
                                                                      return _c(
                                                                        "li",
                                                                        {
                                                                          key: importErrorIndex,
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                    " +
                                                                              _vm._s(
                                                                                importError
                                                                              ) +
                                                                              "\n                                                "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        stopItem.importErrors &&
                                                        stopItem.importErrors
                                                          .length > 0
                                                          ? _c(
                                                              "md-icon",
                                                              {
                                                                staticClass:
                                                                  "icon-warning",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                            warning\n                                        "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !stopItem.isRunHeader
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "stop-reference",
                                                      },
                                                      [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to: {
                                                                name: "Stop Details",
                                                                params: {
                                                                  stopId:
                                                                    stopItem.stopId,
                                                                },
                                                              },
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  _vm
                                                                    .tableColumnOptions
                                                                    .columns[
                                                                    _vm
                                                                      .columnKeys
                                                                      .referenceSource
                                                                  ].key ===
                                                                    "reference-number" ||
                                                                    stopItem.sourceReference ===
                                                                      null ||
                                                                    stopItem.sourceReference ===
                                                                      ""
                                                                    ? stopItem.stopRef
                                                                    : stopItem.sourceReference
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !stopItem.isRunHeader
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "stop-contact",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "overflow-text-tooltip",
                                                                rawName:
                                                                  "v-overflow-text-tooltip",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "custom-ellipsis",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  stopItem.contact !=
                                                                    null
                                                                    ? stopItem
                                                                        .contact
                                                                        .name
                                                                    : ""
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                !stopItem.isRunHeader
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "stop-address",
                                                      },
                                                      [
                                                        _c(
                                                          "GeocodingIndicator",
                                                          {
                                                            attrs: {
                                                              "address-components":
                                                                stopItem.addressComponents,
                                                            },
                                                          }
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "overflow-text-tooltip",
                                                                rawName:
                                                                  "v-overflow-text-tooltip",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "custom-ellipsis",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  _vm.$_displayAddressName(
                                                                    stopItem
                                                                  )
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !stopItem.isRunHeader
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "stop-status",
                                                      },
                                                      [
                                                        !stopItem.isStartStop &&
                                                        !stopItem.isEndStop
                                                          ? _c(
                                                              "stop-status-button",
                                                              {
                                                                attrs: {
                                                                  stop: stopItem,
                                                                },
                                                                on: {
                                                                  refreshTripList:
                                                                    _vm.getTripList,
                                                                  statusUpdated:
                                                                    _vm.onChangedStopStatus,
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !stopItem.isRunHeader
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "delivery-time",
                                                      },
                                                      [
                                                        !stopItem.isStartStop &&
                                                        !stopItem.isEndStop
                                                          ? _c("div", [
                                                              stopItem.timeWindowStart !=
                                                                null &&
                                                              stopItem.timeWindowEnd !=
                                                                null
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "\n                                                " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "timeFormat"
                                                                          )(
                                                                            stopItem.timeWindowStart,
                                                                            _vm
                                                                              .DATE_TYPES
                                                                              .standardTime
                                                                          )
                                                                        ) +
                                                                        "\n                                                -\n                                                " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "timeFormat"
                                                                          )(
                                                                            stopItem.timeWindowEnd,
                                                                            _vm
                                                                              .DATE_TYPES
                                                                              .standardTime
                                                                          )
                                                                        ) +
                                                                        "\n                                            "
                                                                    ),
                                                                  ])
                                                                : stopItem.appointmentTime !=
                                                                  null
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "\n                                                " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "timeFormat"
                                                                          )(
                                                                            stopItem.appointmentTime,
                                                                            _vm
                                                                              .DATE_TYPES
                                                                              .standardTime
                                                                          )
                                                                        ) +
                                                                        "\n                                            "
                                                                    ),
                                                                  ])
                                                                : _c("span", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                !stopItem.isRunHeader
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "stop-time",
                                                      },
                                                      [
                                                        stopItem.status !=
                                                          "Cancelled" &&
                                                        stopItem.status !=
                                                          "On Hold"
                                                          ? _c("div", [
                                                              stopItem.actualDate
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "bold-time",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "timeFormat"
                                                                            )(
                                                                              stopItem.actualDate,
                                                                              _vm
                                                                                .DATE_TYPES
                                                                                .standardTime
                                                                            )
                                                                          ) +
                                                                          "\n                                            "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : stopItem.status ==
                                                                    "Arrived" ||
                                                                  stopItem.status ==
                                                                    "Complete" ||
                                                                  stopItem.status ==
                                                                    "Failed"
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "bold-time",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "timeFormat"
                                                                            )(
                                                                              stopItem.arrivalDate,
                                                                              _vm
                                                                                .DATE_TYPES
                                                                                .standardTime
                                                                            )
                                                                          ) +
                                                                          "\n                                            "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c("span", [
                                                                    stopItem.originalEta
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                    " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "timeFormat"
                                                                                  )(
                                                                                    stopItem.originalEta,
                                                                                    _vm
                                                                                      .DATE_TYPES
                                                                                      .standardTime
                                                                                  )
                                                                                ) +
                                                                                "\n                                                "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : !stopItem.originalEta &&
                                                                        _vm.isAValidDate
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "eta-loader-container",
                                                                          },
                                                                          [
                                                                            _vm.showETAloader(
                                                                              tripItem
                                                                            )
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "eta-loader",
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                !stopItem.isRunHeader &&
                                                _vm.user
                                                  .vehicleCapacityUnitsConfiguration !=
                                                  null &&
                                                _vm.user
                                                  .vehicleCapacityUnitsConfiguration
                                                  .length > 0
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "stop-load",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.getStopLoad(
                                                                stopItem
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                !stopItem.isRunHeader &&
                                                _vm.user
                                                  .vehicleCapacityUnitsConfiguration !=
                                                  null &&
                                                _vm.user
                                                  .vehicleCapacityUnitsConfiguration
                                                  .length > 0
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "vehicle-load",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.getVehicleCurrentLoadOrAvailableCapacity(
                                                                stopItem
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                !stopItem.isRunHeader
                                                  ? _c(
                                                      "md-table-cell",
                                                      {
                                                        staticClass:
                                                          "stop-actions",
                                                      },
                                                      [
                                                        !stopItem.isRunHeader &&
                                                        stopItem.status !=
                                                          "Complete" &&
                                                        stopItem.status !=
                                                          "Cancelled" &&
                                                        stopItem.status !=
                                                          "Failed" &&
                                                        !stopItem.isStartStop &&
                                                        !stopItem.isEndStop &&
                                                        !_vm.isIndividualUser &&
                                                        !_vm.isSingleTeamMember &&
                                                        !_vm.isCustomer &&
                                                        !_vm.isSupportStaff
                                                          ? _c(
                                                              "reassign-stop-button",
                                                              {
                                                                attrs: {
                                                                  type: "icon",
                                                                  item: stopItem,
                                                                  "team-members":
                                                                    _vm.teamMembers,
                                                                },
                                                                on: {
                                                                  assignedUser:
                                                                    _vm.onAssignedUser,
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        !stopItem.isRunHeader &&
                                                        stopItem.status !=
                                                          "Complete" &&
                                                        stopItem.status !=
                                                          "Cancelled" &&
                                                        stopItem.status !=
                                                          "Failed" &&
                                                        !stopItem.isStartStop &&
                                                        !stopItem.isEndStop &&
                                                        !_vm.isIndividualUser &&
                                                        !_vm.isSingleTeamMember &&
                                                        !_vm.isSupportStaff
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "custom-button-container",
                                                              },
                                                              [
                                                                _c(
                                                                  "md-button",
                                                                  {
                                                                    staticClass:
                                                                      "md-danger md-just-icon md-round",
                                                                    attrs: {
                                                                      title:
                                                                        "Remove stop from trip",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.handleRemoveStop(
                                                                            stopItem.stopId,
                                                                            tripItem.tripId,
                                                                            stopItem.shipmentId !=
                                                                              null
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "md-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "close"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        stopItem.runNumber &&
                                                        stopItem.index &&
                                                        stopItem.index
                                                          .toString()
                                                          .endsWith(".1")
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "custom-button-container",
                                                              },
                                                              [
                                                                stopItem.isFixedRunStop
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "gold-lock",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.unlockRun(
                                                                                tripItem.tripId,
                                                                                stopItem.runNumber
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "md-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "lock"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "md-tooltip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "md-direction":
                                                                                  "left",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                    The stops for this run are in a fixed order. Click to unlock the\n                                                    stop order.\n                                                "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "grey-lock",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.lockRun(
                                                                                tripItem.tripId,
                                                                                stopItem.runNumber
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "md-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "lock_open"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "md-tooltip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "md-direction":
                                                                                  "left",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                    The stops for this run do not have a fixed order. Click to lock\n                                                    the stop order.\n                                                "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", { staticClass: "empty-table" }, [
                                  tripItem.isStopListLoading
                                    ? _c(
                                        "div",
                                        { staticClass: "table-loader" },
                                        [
                                          _c("fade-loader", {
                                            attrs: {
                                              loading: true,
                                              color: "#333333",
                                            },
                                          }),
                                          _c("span", [_vm._v("LOADING")]),
                                        ],
                                        1
                                      )
                                    : _c("div", [
                                        tripItem.startAddress &&
                                        !tripItem.isStopListLoading &&
                                        tripItem.stopList.length <= 0
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "md-table",
                                                  {
                                                    staticClass:
                                                      "context-menu-support custom-paginated-table",
                                                  },
                                                  [
                                                    _c(
                                                      "md-table-row",
                                                      {
                                                        attrs: {
                                                          slot: "header",
                                                        },
                                                        slot: "header",
                                                      },
                                                      [
                                                        _c("md-table-head"),
                                                        _c(
                                                          "md-table-head",
                                                          {
                                                            staticClass:
                                                              "start-address-trip",
                                                          },
                                                          [_vm._v("Address")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "md-table-row",
                                                      [
                                                        _c(
                                                          "md-table-cell",
                                                          {
                                                            staticClass:
                                                              "start-address-cell",
                                                          },
                                                          [
                                                            _c("md-icon", [
                                                              _vm._v(
                                                                "pin_drop"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "md-table-cell",
                                                          {
                                                            staticClass:
                                                              "start-address-cell",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "overflow-text-tooltip",
                                                                    rawName:
                                                                      "v-overflow-text-tooltip",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "custom-ellipsis",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                    " +
                                                                    _vm._s(
                                                                      tripItem.startAddress
                                                                    ) +
                                                                    "\n                                                "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                "No stops available for this trip"
                                              ),
                                            ]),
                                      ]),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            )
          : _c("div", { staticClass: "md-layout-item margin-space-bot" }, [
              !_vm.isLoading
                ? _c("div", { staticClass: "empty-message" }, [
                    _c("span", { staticClass: "text-center" }, [
                      _vm._v("No Trips available."),
                    ]),
                  ])
                : _vm._e(),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }