<template>
    <div class="detail-container">
        <div class="close-button">
            <md-button class="md-default md-just-icon md-round pull-right" @click="closeModal">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <div v-if="!loading">
            <div class="detail-section text-center">
                <span class="title">{{ customerDetails.name }}</span>
            </div>
            <div class="line-separator"></div>
            <div class="detail-section">
                <span class="title">Personal Information</span>
                <div class="detail-group">
                    <label class="label">Full Name:</label>
                    <span class="value">{{ customerDetails.firstName }} {{ customerDetails.lastName }}</span>
                </div>
                <div class="detail-group">
                    <label class="label">Company:</label>
                    <span class="value">
                        {{ customerDetails.company }}
                    </span>
                </div>
                <div class="detail-group">
                    <label class="label">ABN:</label>
                    <span class="value">
                        {{ customerDetails.businessRegistrationNumber }}
                    </span>
                </div>
                <div class="detail-group">
                    <label class="label">Address:</label>
                    <span class="value">
                        <div
                            v-if="customerDetails.locationName && $_validateCoordinate(customerDetails.address)"
                            class="address-name"
                        >
                            {{ customerDetails.locationName }}
                        </div>
                        <a class="address" @click.prevent="viewAddress(customerDetails)">
                            {{ customerDetails.address }}
                        </a>
                    </span>
                </div>
                <div v-if="teamRegionName" class="detail-group">
                    <label class="label">Team Region:</label>
                    <span class="value">
                        {{ teamRegionName }}
                    </span>
                </div>
                <div class="detail-group">
                    <label class="label">Contact Email:</label>
                    <span class="value">
                        {{ customerDetails.contactEmail }}
                    </span>
                </div>
                <div class="detail-group">
                    <label class="label">Account Email:</label>
                    <span class="value">
                        <div class="md-chips md-primary md-theme-default md-has-placeholder md-theme-default">
                            <div
                                class="md-chip md-theme-default"
                                v-for="(email, index) in customerDetails.accountsEmailList"
                                :key="index"
                            >
                                {{ email }}
                            </div>
                        </div>
                    </span>
                </div>
                <div class="detail-group">
                    <label class="label">Notes:</label>
                    <span class="value">
                        {{ customerDetails.notes }}
                    </span>
                </div>
                <div class="detail-group">
                    <label class="label">Stop duration:</label>
                    <span class="value">
                        {{ customerDetails.defaultStopDurationMinutes }}
                    </span>
                </div>
                <div class="line-separator" v-if="customerDetails.brandId != null"></div>
                <div class="detail-section" v-if="customerDetails.brandId != null">
                    <span class="title">Brand</span>
                    <img
                        v-if="customerDetails.brandLogoUrl"
                        class="brand-logo"
                        :src="
                            customerDetails.brandLogoUrl !== null ? customerDetails.brandLogoUrl : $root.defaultPhotoUrl
                        "
                        @error="$_setDefaultBrokenImage"
                        :title="customerDetails.brandName"
                        :alt="customerDetails.brandName"
                    />
                    <span v-if="!customerDetails.brandLogoUrl">{{ customerDetails.brandName }}</span>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="sidebar-loader">
                <fade-loader :loading="loading" color="#333333" />
                <span>LOADING</span>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage, getTeamRegionName } from '@/helpers';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { GeneralMixin, MapOverviewMixin } from '@/mixins';
import { mapGetters } from 'vuex';
import { ViewAddressModal } from '..';

export default {
    name: 'CustomerSidebar',
    mixins: [GeneralMixin, MapOverviewMixin],
    components: {
        FadeLoader
    },
    props: {
        customerId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            customerDetails: {},
            loading: false,
            teamRegionId: null
        };
    },
    computed: {
        ...mapGetters({
            teamRegions: 'team/teamRegions'
        }),
        teamRegionName() {
            return getTeamRegionName(this.teamRegionId, this.teamRegions);
        }
    },
    watch: {
        async customerId(newValue, oldValue) {
            await this.getCustomerDetails(newValue);
        }
    },
    async mounted() {
        await this.getCustomerDetails(this.customerId);
    },
    methods: {
        async getCustomerDetails(customerId) {
            try {
                this.loading = true;
                const api = `/api/customers/${customerId}`;
                const { data } = await handleRequests(api);
                Object.assign(this.customerDetails, data);
                this.teamRegionId = this.customerDetails?.teamRegionId;
                this.loading = false;
            } catch (error) {
                const message = 'Error in getting the customer details';
                showErrorMessage(this, message, error);
            }
        },
        closeModal() {
            this.$emit('closeModal');
        },
        viewAddress() {
            this.$modal.show(ViewAddressModal, { data: this.customerDetails });
        }
    }
};
</script>

<style lang="scss" scoped>
.detail-container {
    position: fixed;
    top: 2vh;
    right: 10px;
    height: 96vh;
    width: 25%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    background: white;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 16px;
    .close-button {
        top: 0;
        right: 0;
        position: absolute;
        /* add z-index for old shipments that have no shipment reference numbers */
        z-index: 999;
    }
    .md-button.md-just-icon,
    ::v-deep .md-button.md-just-icon {
        font-size: 15px;
        height: 30px;
        min-width: 3px;
        width: 30px;
        line-height: 30px;
        margin: 2.5px;
    }

    .detail-section {
        padding-top: 10px;
        .blue-ref a {
            color: #2b93ff !important;
        }

        .title {
            text-transform: uppercase;
            font-weight: 600;
            padding: 2px 16px;
            display: block;
            font-size: 14px;
        }

        ::v-deep .custom-a-blue a {
            color: #2b93ff !important;
        }
    }

    .line-separator {
        height: 1px;
        background-color: #ddd;
        width: calc(100% - 32px);
        margin: 10px auto;
        margin-bottom: 0px;
    }

    .detail-group {
        padding: 2px 16px;
        margin: 5px 0;
        font-size: 0.9em;
        .label {
            display: inline-block;
            width: 100px;
            margin-right: 4px;
            vertical-align: top;
            font-weight: 400;
        }

        .value {
            display: inline-block;
            width: calc(100% - 105px);
            vertical-align: top;
            a {
                color: #2b93ff;
            }
        }
    }
}

.sidebar-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

.detail-section .address {
    cursor: pointer;
}
.brand-logo {
    max-height: 50px;
    padding: 2px 16px;
    display: block;
}

.md-chip:first-child {
    margin-top: 0;
}
</style>
