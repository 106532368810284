<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Team member Settings</h4>
        </div>
        <div class="modal-body">
            <form-group name="endtime" label="Trip Start Time" class="timer-field">
                <md-icon>access_time</md-icon>
                <time-picker
                    :time="startTripTime === null ? 'None' : startTripTime"
                    :all-time-options="false"
                    @selectedTime="setStartTime"
                />
            </form-group>
            <form-group name="endtime" label="Trip End Time" class="timer-field">
                <md-icon>access_time</md-icon>
                <time-picker
                    :time="endTripTime === null ? 'None' : endTripTime"
                    :all-time-options="false"
                    @selectedTime="setEndTime"
                />
            </form-group>
            <div
                class="md-layout"
                v-if="user.vehicleCapacityUnitsConfiguration && user.vehicleCapacityUnitsConfiguration.length"
            >
                <div class="md-layout-item">
                    <capacity-inputs
                        v-for="(capacity, index) in user.vehicleCapacityUnitsConfiguration"
                        :key="index"
                        :capacity-definition="capacity"
                        :initial-value="capacityValues[capacity.type]"
                        :disabled="false"
                        @changed="handleCapacityChanged"
                    />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <md-button @click.stop="onSettingsChange" class="md-primary dialog-button">Save</md-button>
            <md-button @click.stop="$modal.hide" class="md-default dialog-button">Close</md-button>
        </div>
    </div>
</template>

<script>
import { TimePicker, CapacityInputs } from '@/components';
import { mapGetters } from 'vuex';

export default {
    name: 'TeamMemberSettingsModal',
    components: {
        TimePicker,
        CapacityInputs
    },
    props: {
        tripItem: {
            type: Object,
            default: null
        },
        startTripTime: {
            type: String,
            default: null
        },
        endTripTime: {
            type: String,
            default: null
        },
        teamMember: {
            type: Object,
            default: null
        },
        capacityValues: {
            type: Object,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            selectedStartTime: null,
            selectedEndTime: null
        };
    },
    mounted() {
        if (this.startTripTime) {
            this.selectedStartTime = this.startTripTime;
        }
        if (this.endTripTime) {
            this.selectedEndTime = this.endTripTime;
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    methods: {
        setStartTime(selectedTime) {
            if (!selectedTime) {
                this.selectedStartTime = null;
            }
            this.selectedStartTime = selectedTime.appointmentTime;
        },
        setEndTime(selectedTime) {
            if (!selectedTime) {
                this.selectedEndTime = null;
            }
            this.selectedEndTime = selectedTime.appointmentTime;
        },
        onSettingsChange() {
            // eslint-disable-next-line prefer-destructuring
            const vehicleId = this.tripItem.vehicleId;
            const startTime = this.selectedStartTime;
            const endTime = this.selectedEndTime;
            const { capacityValues } = this;
            this.resolve({ vehicleId, startTime, endTime, capacityValues });
        },
        handleCapacityChanged({ name, value }) {
            this.capacityValues[name] = value;
        }
    }
};
</script>
<style lang="scss" scoped>
.modal-container {
    max-width: 350px;
}
button.md-button.btn-close {
    background: #999 !important;
}
.load-vals {
    display: inline-block;
    width: calc(60% - 10px);
    margin-right: 10px;

    &::last-child {
        margin-right: 0;
    }

    span {
        font-size: smaller;
        margin-left: -12px;
    }
}
</style>
