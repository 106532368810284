var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.title))]),
    ]),
    _c("div", { staticClass: "modal-body" }, [
      _c(
        "ul",
        { staticClass: "filter-option-ui" },
        _vm._l(_vm.data, function (f) {
          return _c(
            "li",
            { key: f.value },
            [
              _c(
                "md-checkbox",
                {
                  attrs: {
                    type: "checkbox",
                    id: f.value,
                    value: f.value,
                    multiple: "",
                  },
                  model: {
                    value: _vm.selectedFilterValue,
                    callback: function ($$v) {
                      _vm.selectedFilterValue = $$v
                    },
                    expression: "selectedFilterValue",
                  },
                },
                [
                  _c("label", { attrs: { for: f.value } }, [
                    _vm._v(_vm._s(f.value)),
                  ]),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            attrs: { disabled: _vm.selectedFilterValue.length === 0 },
            on: { click: _vm.applyFilter },
          },
          [_vm._v("\n            Apply\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }