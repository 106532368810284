export const MarkerColorCode = [
    { color: '#00008B' },
    { color: '#B8860B' },
    { color: '#006400' },
    { color: '#8B008B' },
    { color: '#20B2AA' },
    { color: '#556B2F' },
    { color: '#FF8C00' },
    { color: '#8B0000' },
    { color: '#FF1493' },
    { color: '#1E90FF' },
    { color: '#228B22' },
    { color: '#ec4713' },
    { color: '#4B0082' },
    { color: '#C71585' },
    { color: '#FFA500' },
    { color: '#FF4500' },
    { color: '#DA70D6' },
    { color: '#2E8B57' },
    { color: '#008080' },
    { color: '#0000FF' },
    { color: '#8A2BE2' },
    { color: '#A52A2A' },
    { color: '#DEB887' },
    { color: '#5F9EA0' },
    { color: '#7FFF00' },
    { color: '#D2691E' },
    { color: '#FF7F50' },
    { color: '#6495ED' },
    { color: '#FF6347' },
    { color: '#008B8B' }
];
