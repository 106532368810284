var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _vm.obdAlertModalHide !== true
      ? _c("div", { staticClass: "alert alert-info alert-z" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button", "aria-hidden": "true" },
              on: {
                click: function ($event) {
                  _vm.obdAlertModalHide = true
                },
              },
            },
            [_vm._v("\n            ×\n        ")]
          ),
          _c("span", [
            _vm._v(
              "\n            The safety reports are only available for OBD device data.\n        "
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c("div", { staticClass: "custom-toolbar md-layout" }, [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item custom-toolbar-start md-layout md-small-size-80 md-size-90",
              },
              [
                _c("team-members-filter", {
                  on: { onSelection: _vm.onTeamMemberSelection },
                }),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-field",
                      [
                        _c("label", { staticClass: "field-label" }, [
                          _vm._v("Type:"),
                        ]),
                        _c(
                          "md-select",
                          {
                            attrs: { multiple: "" },
                            on: { "md-selected": _vm.getReportDataList },
                            model: {
                              value: _vm.selectedType,
                              callback: function ($$v) {
                                _vm.selectedType = $$v
                              },
                              expression: "selectedType",
                            },
                          },
                          _vm._l(_vm.types, function (member) {
                            return _c(
                              "md-option",
                              {
                                key: member.key,
                                staticClass: "multiple-selection-checkbox",
                                attrs: { value: member.key },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(member.value) +
                                    "\n                                "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c("date-range-filter", {
                      attrs: { "is-single-date": false },
                      on: { onChanged: _vm.onDateChanged },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "md-layout-item custom-toolbar-end" },
              [
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-primary md-just-icon md-round pull-right header-button",
                    attrs: { title: "Export Safety report" },
                    on: { click: _vm.onExportToExcel },
                  },
                  [_c("md-icon", [_vm._v("get_app")])],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-blue" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("summarize")])],
                    1
                  ),
                ]
              ),
              _c("md-card-content", [
                !_vm.reportDataList || _vm.reportDataList.length == 0
                  ? _c("div", [
                      _c("h3", [_vm._v("Safety Report")]),
                      _c("p", [
                        _vm._v(
                          "\n                            No results to display.\n                        "
                        ),
                      ]),
                    ])
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "report-container" },
                        [
                          _c("safety-report-details", {
                            attrs: { "member-list": _vm.reportDataList },
                          }),
                        ],
                        1
                      ),
                    ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }