import {
    PIN_DRIVER,
    UNPIN_DRIVER,
    // ADD_DRIVER,
    ADD_STOP,
    STOP_CLICKED,
    FETCH_STOPS,
    CLEAR_TIMESERIES_DATA,
    FETCH_TIMESERIES_DATA,
    CHANGE_DATE,
    CHANGE_TIME_WINDOW,
    SHOW_STREET_VIEW,
    TOGGLE_HEARTBEATS,
    TOGGLE_PLANNED_ROUTE,
    TOGGLE_LOCATION_HISTORY,
    TOGGLE_STOPS_LIST,
    TOGGLE_STOP_STATUS,
    TOGGLE_GEOFENCE,
    PLAY_TIMELINE,
    RESET_STATE,
    CHANGE_ACTIVE_PANEL,
    SET_TEAM_REGION_ID,
    FETCH_GEO_FENCE,
    FETCH_GEO_TYPES,
    CREATE_GEO_FENCE,
    DELETE_GEO_FENCE,
    SET_DRIVER_LOCATION
} from '../types';
// eslint-disable-next-line import/no-cycle
import { handleRequests, getTimeInMinutes } from '../../helpers/index';

const INIT_STATE = {
    streetView: {},
    pinnedUser: '',
    isAssetGroup: false,
    isHeartbeatsToggled: false,
    isPlannedRouteToggled: true,
    isLocationHistoryToggled: true,
    stops: {},
    pinnedStops: [],
    pinnedLocationDetails: {},
    timeWindow: [0, 1440],
    date: new Date(),
    isStopsListToggled: false,
    isStopStatusToggled: true,
    isGeofenceToggled: true,
    pinnedRoute: '',
    timeseriesData: [],
    isPlaying: false,
    playbackSpeed: 1,
    pauseTime: 0,
    activePanel: 'Team Members',
    clickedStop: {},
    teamRegionId: null,
    geoFenceList: []
};

const getters = {
    streetView: ({ streetView }) => streetView,
    isAssetGroup: ({ isAssetGroup }) => isAssetGroup,
    pinnedUser: ({ pinnedUser }) => pinnedUser,
    pinnedLocationDetails: ({ pinnedLocationDetails }) => pinnedLocationDetails,
    pinnedRoute: ({ pinnedRoute }) => pinnedRoute,
    timeseriesData: ({ timeseriesData }) => timeseriesData,
    timeWindow: ({ timeWindow }) => timeWindow,
    date: ({ date }) => date,
    isPlaying: ({ isPlaying }) => isPlaying,
    playbackSpeed: ({ playbackSpeed }) => playbackSpeed,
    pauseTime: ({ pauseTime }) => pauseTime,
    isHeartbeatsToggled: ({ isHeartbeatsToggled }) => isHeartbeatsToggled,
    isPlannedRouteToggled: ({ isPlannedRouteToggled }) => isPlannedRouteToggled,
    isLocationHistoryToggled: ({ isLocationHistoryToggled }) => isLocationHistoryToggled,
    isStopsListToggled: ({ isStopsListToggled }) => isStopsListToggled,
    isStopStatusToggled: ({ isStopStatusToggled }) => isStopStatusToggled,
    isGeofenceToggled: ({ isGeofenceToggled }) => isGeofenceToggled,
    activePanel: ({ activePanel }) => activePanel,
    pinnedStops: ({ pinnedStops }) => pinnedStops,
    clickedStop: ({ clickedStop }) => clickedStop,
    teamRegionId: ({ teamRegionId }) => teamRegionId,
    geoFenceList: ({ geoFenceList }) => geoFenceList
};

const mutations = {
    [CLEAR_TIMESERIES_DATA]: (state) => {
        state.timeseriesData = [];
    },
    [UNPIN_DRIVER]: (state) => {
        state.pinnedUser = '';
        state.pinnedRoute = '';
        state.timeseriesData = [];
        state.isToggled = false;
    },
    [PIN_DRIVER]: (
        state,
        { publicUserId, routePolyline, routeDetails, isAssetGroup, stopList, locationDetails, stopDetails }
    ) => {
        state.isToggled = false;
        state.pinnedRoute = '';

        state.pinnedUser = publicUserId;
        state.isAssetGroup = isAssetGroup;
        if (routeDetails || routePolyline) {
            state.pinnedRoute = routeDetails && routeDetails.route ? routeDetails.route : routePolyline;
        }

        if (locationDetails) 
            state.pinnedLocationDetails = locationDetails;

        if (stopDetails) 
            state.pinnedStops = stopDetails;
        if (stopList) {
            let counterIndex = 1;
            stopList.forEach((x) => {
                if (
                    x.status !== 'Cancelled' &&
                    x.status !== 'Complete' &&
                    x.status !== 'Failed' &&
                    x.status !== 'On Hold'
                ) {
                    x.stopIndex = counterIndex;
                    counterIndex += 1;
                } else {
                    x.stopIndex = '-';
                }
            });
            state.pinnedStops = stopList;
        }
    },
    [ADD_STOP]: (state, payload) => {
        state.stops = Object.assign(state.stops, payload);
    },
    [STOP_CLICKED]: (state, payload) => {
        state.clickedStop = payload;
    },
    [CHANGE_DATE]: (state, { date }) => {
        state.date = date;
    },
    [CHANGE_TIME_WINDOW]: (state, payload) => {
        state.timeWindow = [...payload.timeWindow];
        state.pauseTime = '';
    },
    [SHOW_STREET_VIEW]: (state, payload) => {
        state.streetView = payload;
    },
    [TOGGLE_HEARTBEATS]: (state, payload) => {
        state.isHeartbeatsToggled = payload;
    },
    [TOGGLE_PLANNED_ROUTE]: (state, payload) => {
        state.isPlannedRouteToggled = payload;
    },
    [TOGGLE_LOCATION_HISTORY]: (state, payload) => {
        state.isLocationHistoryToggled = payload;
    },
    [TOGGLE_STOPS_LIST]: (state, payload) => {
        state.isStopsListToggled = payload;
    },
    [TOGGLE_STOP_STATUS]: (state, payload) => {
        state.isStopStatusToggled = payload;
    },
    [TOGGLE_GEOFENCE]: (state, payload) => {
        state.isGeofenceToggled = payload;
    },
    [PLAY_TIMELINE]: (state, { isPlaying, speed, pauseTime }) => {
        state.isPlaying = isPlaying;

        // set new playback speed
        if (isPlaying) 
            state.playbackSpeed = speed;
        // reset playback speed to 1 after pressing stop or ending the playback
        else 
            state.playbackSpeed = 1;
        if (pauseTime) 
            state.pauseTime = pauseTime;
    },
    [CHANGE_ACTIVE_PANEL]: (state, payload) => {
        state.activePanel = payload;
    },
    [RESET_STATE]: (state) => {
        // we don't want to reset the display panel choices as well as the active panel
        const {
            isLocationHistoryToggled,
            isHeartbeatsToggled,
            isPlannedRouteToggled,
            isStopsListToggled,
            isStopStatusToggled,
            isGeofenceToggled,
            activePanel
        } = state;

        Object.assign(state, INIT_STATE, {
            isLocationHistoryToggled,
            isHeartbeatsToggled,
            isPlannedRouteToggled,
            isStopsListToggled,
            isStopStatusToggled,
            isGeofenceToggled,
            activePanel
        });
    },
    [SET_TEAM_REGION_ID]: (state, teamRegionId) => {
        state.teamRegionId = teamRegionId;
    }
};

const actions = {
    async [FETCH_STOPS]({ commit }, payload) {
        const {
            data: { data }
        } = await handleRequests(payload.endpoint);

        if (data.length > 0) {
            const stopsObj = data.reduce((o, i) => {
                if (!o[i.assignedTo.publicUserId]) {
                    o[i.assignedTo.publicUserId] = [];
                }
                o[i.assignedTo.publicUserId].push(i);
                return o;
            }, {});
            commit(ADD_STOP, stopsObj);
            return stopsObj;
        }
        return {};
    },
    async [FETCH_TIMESERIES_DATA]({ state, commit }, { tripDate, userId }) {
        // clear timeSeriesData
        state.timeseriesData.length = 0;
        commit(CLEAR_TIMESERIES_DATA);
        // fetch and display location history markers
        const endpoint = `/api/trips/${tripDate}/location-history?userId=${userId}`;
        const uniqueEntries = [];
        try {
            const {
                data: { data }
            } = await handleRequests(endpoint);

            const filteredData = data
                .filter(
                    (m) =>
                        m.coordinates.latitude !== 0 &&
                        m.coordinates.longitude !== 0 &&
                        Math.abs(m.coordinates.latitude) <= 90 &&
                        Math.abs(m.coordinates.longitude) <= 180
                )
                .map((m) => {
                    m.minutesFromMidnight = getTimeInMinutes(m.timeStamp, m.utcOffset);
                    return m;
                });

            // Remove duplicate entries
            // const uniqueData = [];
            // const trackingHash = {};

            const entryHash = {};
            // TODO: this should be used for playback only
            // filteredData.forEach((data) => {
            //     if (!trackingHash[data.minutesFromMidnight]) {
            //         trackingHash[data.minutesFromMidnight] = true;
            //         uniqueData.push(data);
            //     }
            // });

            //
            filteredData.forEach((data) => {
                const { latitude, longitude } = data.coordinates;
                const key = `${latitude}:${longitude}}`;
                if (!entryHash[key]) {
                    entryHash[key] = true;
                    uniqueEntries.push(data);
                }
            });

            state.timeseriesData = uniqueEntries;
            // eslint-disable-next-line no-empty
        } catch (error) {
            // throws a 401 error is trackerId is not found or publicuserId
        }

        return uniqueEntries;
    },
    async [PIN_DRIVER]({ commit }, payload) {
        if (!payload.isAssetGroup) {
            const driverDetailsEndpoint = `/api/trips/${payload.date}?userId=${payload.userId}`;
            const {
                data: { data }
            } = await handleRequests(driverDetailsEndpoint);
            commit(PIN_DRIVER, {
                ...data,
                isAssetGroup: payload.isAssetGroup
            });
        } else {
            commit(PIN_DRIVER, {
                publicUserId: payload.userId,
                isAssetGroup: payload.isAssetGroup
            });
        }
    },
    async [CREATE_GEO_FENCE]({ commit }, data) {
        const method = data.geofenceId ? 'PUT' : 'POST';
        const endPoint = data.geofenceId ? `/api/geofence/${data.geofenceId}` : `/api/geofence`;
        const response = await handleRequests(endPoint, {
            method,
            data
        });
        return response;
    },
    async [FETCH_GEO_FENCE]({ commit }, teamRegionId) {
        const endPoint = `/api/geofence/list`;
        const response = await handleRequests(endPoint, {
            params: {
                teamRegionId
            }
        });
        return response;
    },

    async [FETCH_GEO_TYPES]({ commit }, data) {
        const endPoint = `/api/geofence/types/list`;
        const response = await handleRequests(endPoint);
        return response;
    },

    async [DELETE_GEO_FENCE]({ commit }, geofenceId) {
        const endPoint = `/api/geofence/${geofenceId}`;
        const response = await handleRequests(endPoint, {
            method: 'delete'
        });
        return response;
    },

    async [SET_DRIVER_LOCATION]({ commit }, data) {
        const method = 'POST';

        const endPoint = `/api/team-members/device-location`;
        const response = await handleRequests(endPoint, {
            method,
            data
        });
        return response;
    }
};

const state = () => ({ ...INIT_STATE });

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
