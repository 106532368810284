var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isListLoading
        ? _c(
            "div",
            [
              _c(
                "md-table",
                { staticClass: "custom-paginated-table context-menu-support" },
                [
                  _c(
                    "md-table-row",
                    [
                      _c("md-table-head", [_vm._v("Name")]),
                      _vm.type == "tracker"
                        ? _c("md-table-head", [
                            _vm._v(
                              "\n                    IMEI\n                "
                            ),
                          ])
                        : _vm._e(),
                      _c("md-table-head", [_vm._v("Asset Group")]),
                      _vm.assetList.length > 0 &&
                      _vm.assetList[0].type == "Tracker"
                        ? _c("md-table-head", [
                            _vm._v(
                              "\n                    Connected\n                "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "md-table-head",
                        {
                          class:
                            _vm.assetList.length > 0 &&
                            _vm.assetList[0].type == "Tracker"
                              ? ""
                              : "agroup-name-max",
                        },
                        [
                          _vm._v(
                            "\n                    Actions\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.assetList, function (item, index) {
                    return _c(
                      "md-table-row",
                      {
                        key: index,
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.showAssetDetails(item)
                          },
                        },
                      },
                      [
                        _c(
                          "md-table-cell",
                          {
                            attrs: { "md-label": "Name", "md-sort-by": "name" },
                          },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Asset Details",
                                    params: { assetId: item.assetId },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.name) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.type == "tracker"
                          ? _c(
                              "md-table-cell",
                              { attrs: { "md-label": "IMEI" } },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(item.trackerReference) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "md-table-cell",
                          { attrs: { "md-label": "Group Name" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Asset Group Details",
                                    params: { assetGroupId: item.assetGroupId },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.assetGroupName) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        item.type == "Tracker"
                          ? _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Connected" } },
                              [
                                _c(
                                  "md-icon",
                                  {
                                    class: item.isTrackerConnected
                                      ? "connected"
                                      : "disconnected",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          item.isTrackerConnected
                                            ? "check_circle"
                                            : "highlight_off"
                                        ) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                                !item.lastUpdated.startsWith("0001")
                                  ? _c(
                                      "md-tooltip",
                                      { attrs: { "md-direction": "left" } },
                                      [
                                        _vm._v(
                                          "\n                        Last Updated: " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                item.lastUpdated,
                                                _vm.DATE_TYPES.internationalDate
                                              )
                                            ) +
                                            "\n                        " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                item.lastUpdated,
                                                _vm.DATE_TYPES.militaryTime
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "md-table-cell",
                          {
                            staticClass: "action-buttons",
                            attrs: { "md-label": "Actions" },
                          },
                          [
                            _c("update-asset-button", {
                              attrs: {
                                asset: item,
                                "custom-fields": _vm.customFields,
                              },
                              on: { onAssetUpdate: _vm.handleUpdatedAsset },
                            }),
                            item.type == "Tracker"
                              ? _c("delete-asset-button", {
                                  attrs: { asset: item },
                                  on: { onAssetDelete: _vm.handleDeleteAsset },
                                })
                              : _vm._e(),
                            item.type == "Vehicle"
                              ? _c("create-asset-log-button", {
                                  attrs: {
                                    asset: item,
                                    "custom-fields": _vm.customFields,
                                  },
                                })
                              : _vm._e(),
                            item.type == "Vehicle"
                              ? _c(
                                  "create-assign-maintenance-schedule-button",
                                  {
                                    attrs: {
                                      "maintenance-schedules":
                                        _vm.maintenanceScheduledList,
                                      asset: item,
                                    },
                                    on: {
                                      onAssignSchedule:
                                        _vm.handleAssignMaintenanceSchedule,
                                    },
                                  }
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _vm.assetList.length > 0
                ? _c(
                    "md-card-actions",
                    {
                      staticClass: "page-footer",
                      attrs: { "md-alignment": "space-between" },
                    },
                    [
                      _c("div", [
                        _vm.total === _vm.pagination.perPage
                          ? _c("p", { staticClass: "card-category" }, [
                              _vm._v(
                                "\n                    Page " +
                                  _vm._s(_vm.pagination.currentPage) +
                                  " of many\n                "
                              ),
                            ])
                          : _c("p", { staticClass: "card-category" }, [
                              _vm._v(
                                "Page " +
                                  _vm._s(_vm.pagination.currentPage) +
                                  " of " +
                                  _vm._s(_vm.totalPages)
                              ),
                            ]),
                      ]),
                      _c("pagination", {
                        staticClass: "pagination-no-border pagination-success",
                        attrs: {
                          "per-page": _vm.pagination.perPage,
                          total: _vm.total,
                        },
                        on: {
                          "change-page": function ($event) {
                            return _vm.handleChangePage(
                              $event,
                              _vm.pagination.perPage
                            )
                          },
                        },
                        model: {
                          value: _vm.pagination.currentPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.pagination, "currentPage", $$v)
                          },
                          expression: "pagination.currentPage",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("div", { staticClass: "body-list" }, [
            _c(
              "div",
              { staticClass: "content-loader" },
              [
                _c("fade-loader", {
                  attrs: { loading: _vm.isListLoading, color: "#333333" },
                }),
                _c("span", [_vm._v("LOADING")]),
              ],
              1
            ),
          ]),
      _vm.assetList.length == 0 && !_vm.isListLoading
        ? _c("div", { staticClass: "text-center" }, [
            _c("p", { staticClass: "no-result-message" }, [
              _vm._v("No results matching your search/filter could be found."),
            ]),
          ])
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.showSideBar
            ? _c("asset-sidebar", {
                attrs: {
                  "asset-id": _vm.assetId,
                  "asset-type": _vm.assetType,
                  "maintenance-schedules": _vm.maintenanceScheduledList,
                },
                on: {
                  closeModal: _vm.toggleAssetDetailsWindow,
                  updateStop: _vm.handleUpdatedAsset,
                  onAssignSchedule: _vm.handleAssignMaintenanceSchedule,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }