<template>
    <div class="gmnoprint-child">
        <button
            @click="selectDrawing(type)"
            draggable="false"
            :title="title"
            :aria-label="ariaLabel"
            type="button"
            :aria-pressed="pressedState"
            :class="customClass"
        >
            <span>
                <div>
                    <img alt="" :src="$root.drawingToolIcon" draggable="false" />
                </div>
            </span>
        </button>
    </div>
</template>

<script>
export default {
    name: 'DrawingButton',
    props: {
        title: {
            type: String,
            default: () => null
        },
        ariaLabel: {
            type: String,
            default: () => null
        },
        type: {
            type: String,
            default: () => null
        },
        customClass: {
            type: Array,
            default: () => []
        },
        pressedState: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        selectDrawing(mode) {
            this.$emit('selectDrawing', mode);
        }
    }
};
</script>

<style lang="scss" scoped>
.gmnoprint-child {
    float: left;
    line-height: 0;
}
button {
    background: none padding-box transparent;
    display: block;
    border: 0px;
    margin: 0px;
    padding: 4px;
    text-transform: none;
    appearance: none;
    position: relative;
    cursor: pointer;
    user-select: none;
    direction: ltr;
    overflow: hidden;
    text-align: left;
    font-family: Roboto, Arial, sans-serif;
    font-size: 11px;
    border: 1px solid #e8e8e8;
    span {
        display: inline-block;
    }
    div {
        width: 16px;
        height: 16px;
        overflow: hidden;
        position: relative;
    }
    img {
        position: absolute;
        left: 0px;
        user-select: none;
        border: 0px;
        padding: 0px;
        margin: 0px;
        max-width: none;
        width: 16px;
        height: 192px;
    }
}
.button-pressed {
    background: #e5e5e5;
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
    -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
    box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
}
.drawing-button {
    color: rgb(0, 0, 0);
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    font-weight: 500;
    img {
        top: -144px;
    }
}
.circle-button {
    color: rgb(86, 86, 86);
    img {
        top: -160px;
    }
}
.polygon-button {
    color: rgb(86, 86, 86);
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    img {
        top: -64px;
    }
}
</style>
