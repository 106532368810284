<template>
    <div>
        <md-icon class="spin-icon">autorenew</md-icon>
        <div class="spin-text">{{ loadingText }}</div>
    </div>
</template>

<script>
export default {
    name: 'LoadingComponentSpinner',
    props: {
        loadingText: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
.spin-icon {
    animation: rotate 1.7s linear infinite;
    font-size: 48px !important;
    position: absolute;
    top: 150%;
    left: 50%;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.spin-text {
    position: absolute;
    top: 270%;
    left: 46%;
}
</style>
