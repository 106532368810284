<template>
    <div class="custom-ag-dropdown">
        <md-field>
            <md-select v-model="currentValue" @md-selected="optionSelected">
                <md-option key="blank" value=""></md-option>
                <md-option v-for="item in params.dropdownValues" :key="item" :value="item">
                    {{ item }}
                </md-option>
            </md-select>
        </md-field>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentValue: ''
        };
    },
    methods: {
        optionSelected() {
            if (this.currentValue && this.currentValue === '') {
                // Remove the filter
                this.params.parentFilterInstance((instance) => {
                    instance.onFloatingFilterChanged(null, null);
                });
                return;
            }

            this.params.parentFilterInstance((instance) => {
                instance.onFloatingFilterChanged('equals', this.currentValue);
            });
        },
        onParentModelChanged(parentModel) {
            // When the filter is empty we will receive a null value here
            if (!parentModel) {
                this.currentValue = '';
            } else {
                this.currentValue = parentModel.filter;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-input.md-select-value {
    padding-left: 8px;
    min-height: 32px;
    border-radius: 4px;
    border: 1px solid #dddddd;
    color: inherit !important;
}

.custom-ag-dropdown {
    align-items: center;
    display: flex;
    width: 100%;
    max-width: 150px;
}

.md-field:after {
    height: 0px;
}
</style>
