<template>
    <div>
        <md-button
            title="Add Stop"
            class="md-primary md-just-icon md-round pull-right header-button"
            @click="createStop"
            :disabled="!teamMembers.length && !isCustomerAdmin"
        >
            <md-icon>add</md-icon>
        </md-button>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { STOP_DETAILS_DEFAULTS } from '@/utils/defaults';
import { mapGetters } from 'vuex';
import CreateStopModal from './CreateStopModal';

export default {
    name: 'CreateStop',
    mixins: [GeneralMixin],
    props: {
        teamMembers: {
            type: Array,
            default: () => []
        },
        selectedMemberId: {
            type: [String, Number],
            default: () => 0
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isCustomerAdmin: 'user/isCustomerAdmin'
        })
    },
    data() {
        return {
            stopDetails: Object.assign({}, STOP_DETAILS_DEFAULTS())
        };
    },
    methods: {
        createStop() {
            this.resetStopState();

            if (this.selectedMemberId !== null) {
                this.stopDetails.assignToPublicUserId = this.selectedMemberId;
            }
            if (this.isCustomerAdmin) {
                this.stopDetails.customerId = this.user.customerId || null;
            }

            // User user or team default stop duration, if null use value from STOP_DETAILS_DEFAULTS
            this.stopDetails.durationMinutes = this.user.defaultStopDurationMinutes ?? this.stopDetails.durationMinutes;

            this.$modal
                .show(CreateStopModal, {
                    members: this.teamMembers,
                    stopDetails: this.stopDetails
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.emitStopCreated();
                        this.$modal.hide();
                    }
                });
        },
        emitStopCreated() {
            this.resetStopState();
            this.$emit('stopCreated');
        },
        resetStopState() {
            Object.assign(this.stopDetails, STOP_DETAILS_DEFAULTS());
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    margin-right: 16px;
}
</style>
