<template>
    <div class="modal-container">
        <div class="modal-header">
            <h5 class="modal-title">Custom Mapping - {{ title }}</h5>
        </div>

        <div class="modal-body">
            <form-group name="formatString" label="Format string">
                <md-input v-model="formatString" />
                <button
                    type="button"
                    class="info-button"
                    title="Set Up Spreadsheet Import Mappings"
                    @click="openInfo()"
                >
                    <md-icon class="info-icon" @click="openInfo">info</md-icon>
                </button>
            </form-group>
            <form-group name="defaultValue" label="Default value">
                <md-input v-model="defaultValue" />
            </form-group>
        </div>

        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="saveCustomMapping">
                OK
            </md-button>
            <md-button class="dialog-button md-default" @click="closeCustomMapping">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomMappingModal',
    props: {
        resolve: {
            type: Function,
            default: () => {}
        },
        title: {
            type: String,
            default: ''
        },
        formatString: {
            type: String,
            default: null
        },
        defaultValue: {
            type: String,
            default: null
        }
    },
    methods: {
        saveCustomMapping() {
            const result = {};

            if (this.formatString !== null && this.formatString !== '') 
                result.formatString = this.formatString;

            if (this.defaultValue !== null && this.defaultValue !== '') 
                result.defaultValue = this.defaultValue;

            this.resolve(result);
        },

        closeCustomMapping() {
            this.$modal.hide();
        },

        openInfo() {
            window.open('https://training.locate2u.com/importing-exporting-data', '_blank');
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 360px;
}
.info-icon {
    color: #2b93ff !important;
}
.info-button {
    background: none;
    border: none;
    cursor: pointer;
}
</style>
