var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _vm.selectedCustomField === null
          ? _c("span", [_vm._v("Add Shipment Custom Field")])
          : _vm._e(),
        _vm.selectedCustomField !== null
          ? _c("span", [
              _vm._v("Edit " + _vm._s(_vm.selectedCustomField.label)),
            ])
          : _vm._e(),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          { staticClass: "form-wrapper", attrs: { validator: _vm.$v.form } },
          [
            _vm.selectedCustomField !== null
              ? _c(
                  "form-group",
                  { attrs: { label: "Id", name: "label" } },
                  [
                    _c("md-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "form-group",
              { attrs: { label: "Label", name: "label" } },
              [
                _c("md-input", {
                  model: {
                    value: _vm.form.label,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "label", $$v)
                    },
                    expression: "form.label",
                  },
                }),
              ],
              1
            ),
            _c(
              "form-group",
              { attrs: { name: "type" } },
              [
                _vm.customFieldTypes
                  ? _c("vue-select", {
                      attrs: {
                        options: _vm.customFieldTypes,
                        placeholder: "Type",
                      },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "md-checkbox",
                  {
                    model: {
                      value: _vm.form.includeInPodPdf,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "includeInPodPdf", $$v)
                      },
                      expression: "form.includeInPodPdf",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Include in Proof Of Delivery PDF\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _vm.selectedCustomField === null
          ? _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary",
                on: { click: _vm.addShipmentCustomField },
              },
              [_vm._v("\n            Add\n        ")]
            )
          : _vm._e(),
        _vm.selectedCustomField !== null
          ? _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary",
                on: { click: _vm.editShipmentCustomField },
              },
              [_vm._v("\n            Save\n        ")]
            )
          : _vm._e(),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }