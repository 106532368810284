var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sync-button" },
    [
      _vm.invoice.isSyncing
        ? _c(
            "div",
            {
              staticClass: "sync-loader",
              attrs: { title: "invoice.retryTooltip" },
            },
            [_c("straight-line-loader")],
            1
          )
        : _c(
            "md-button",
            {
              staticClass: "md-just-icon md-round",
              class: _vm.buttonColor,
              attrs: { title: _vm.buttonTitle },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.buttonColor == "md-danger"
                    ? _vm.showError(_vm.invoice)
                    : _vm.buttonColor == "md-success"
                    ? _vm.handleSyncedInvoice
                    : _vm.handleSyncInvoice(_vm.invoice)
                },
              },
            },
            [_c("md-icon", [_vm._v(_vm._s(_vm.buttonIcon))])],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }