<template>
    <md-card class="summary-card">
        <md-card-header class="summary-card--header" id="summary-card--header">
            <slot name="header"></slot>
            <md-card-header-text>
                <slot name="header-text"></slot>
            </md-card-header-text>
        </md-card-header>
        <md-card-content class="summary-card--content">
            <slot name="content"></slot>
        </md-card-content>
        <md-card-actions>
            <slot name="actions"></slot>
        </md-card-actions>
    </md-card>
</template>
<script>
export default {
    name: 'SummaryCard'
};
</script>

<style scoped lang="scss">
.summary-card {
    border-left: 4px solid #4caf50;
    padding-top: 15px;
    margin: 0;
}

::v-deep .md-card-header.summary-card--header.md-card-header-flex#summary-card--header {
    padding: 10px 0;
}

::v-deep .md-card-header-text {
    padding: 0;
    margin: 0;
}

.summary-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.summary-card--content {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 15px;
}
</style>
