/* eslint-disable import/no-cycle */
import { handleRequests } from '@/helpers/index';
import { FETCH_ASSET_GROUPS_LOCATION_DETAILS, UPDATE_ASSET_GROUP } from '../types';

const INIT_STATE = {
    assetGroups: []
};

const mutations = {
    [UPDATE_ASSET_GROUP](state, payload) {
        state.assetGroups = [...payload];
    }
};

const actions = {
    async [FETCH_ASSET_GROUPS_LOCATION_DETAILS](
        { commit },
        { teamRegionId = null, elevatedTeamRegionFilter, date = null }
    ) {
        const endpoint = `/api/asset-groups/location-details?teamRegionId=${teamRegionId}&elevatedTeamRegionFilter=${elevatedTeamRegionFilter}&date=${date}`;
        const {
            data: { data }
        } = await handleRequests(endpoint);
        commit('UPDATE_ASSET_GROUP', data);
        return data;
    }
};

const state = () => ({ ...INIT_STATE });

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
