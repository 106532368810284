var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            !_vm.isReadOnlyUser
              ? _c(
                  "div",
                  [
                    _c(
                      "md-button",
                      {
                        staticClass:
                          "md-primary md-just-icon md-round pull-right header-button",
                        attrs: { title: "Export Shipments" },
                        on: { click: _vm.exportShipments },
                      },
                      [_c("md-icon", [_vm._v("get_app")])],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isReadOnlyUser
              ? _c("batch-upload", {
                  attrs: {
                    title: "Import Shipments",
                    "template-type": "shipments",
                    "team-members": _vm.teamMembers,
                  },
                  on: { batchImported: _vm.handleShipmentCreated },
                })
              : _vm._e(),
            !_vm.isCustomer && !_vm.isSupportStaff
              ? _c("create-shipment", {
                  attrs: { "team-members": _vm.teamMembers },
                  on: { shipmentCreated: _vm.handleShipmentCreated },
                })
              : _vm._e(),
            _c(
              "div",
              [
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-primary md-just-icon md-round pull-right header-button",
                    attrs: { title: "Refresh List" },
                    on: { click: _vm.handleRefresh },
                  },
                  [_c("md-icon", [_vm._v("refresh")])],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("search-component", {
                  on: { onSearch: _vm.handleFilterOrSearch },
                }),
                _c("shipment-filter-component", {
                  attrs: {
                    "team-members": function () {
                      return this$1.teamMembers
                    },
                    "team-region-id": _vm.filters.teamRegionId,
                    "show-quoting-status": _vm.teamHasOfferMethodSetup,
                    "date-range": {
                      fromDate: _vm.filters.fromDate,
                      toDate: _vm.filters.toDate,
                    },
                    "filter-value": _vm.filters,
                  },
                  on: { onFilterShipments: _vm.handleFilterOrSearch },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _vm.bulkListError.length > 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "alert alert-danger alert-dismissible fade show bulk-error-message",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "close",
                        attrs: {
                          type: "button",
                          "data-dismiss": "alert",
                          "aria-label": "Close",
                        },
                        on: { click: _vm.closeAlert },
                      },
                      [
                        _c("span", { attrs: { "aria-hidden": "true" } }, [
                          _vm._v("×"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      _vm._l(_vm.bulkListError, function (e) {
                        return _c(
                          "div",
                          {
                            key: e.shipmentRef,
                            staticClass: "bulk-single-container",
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(e.shipmentRef) +
                                  "\n                        "
                              ),
                            ]),
                            _c(
                              "div",
                              _vm._l(e.errors, function (error, i) {
                                return _c("div", { key: i }, [
                                  _vm._v(_vm._s(error.message)),
                                ])
                              }),
                              0
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c(
              "md-card",
              [
                _vm.hasMarketplaceAccess
                  ? _c(
                      "md-card-header",
                      {
                        staticClass: "md-card-header-icon md-card-header-green",
                      },
                      [
                        _c("CardHeaderDropdown", {
                          attrs: {
                            "dropdown-options": _vm.headerDropdown,
                            "selected-option": _vm.headerDropdown[0],
                          },
                          on: { selectedOption: _vm.handleSelectedView },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "md-card-header",
                      {
                        staticClass: "md-card-header-icon md-card-header-green",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-icon running-load-menu" },
                          [_c("md-icon", [_vm._v("assignment")])],
                          1
                        ),
                      ]
                    ),
                _c("md-card-content", { staticClass: "body-list" }, [
                  _vm.selectedShipments.length
                    ? _c(
                        "div",
                        {
                          class: [
                            "bulk-section",
                            _vm.topScrollPosition > 150 ? "sticky" : "",
                          ],
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.selectedShipments.length) +
                                " shipment" +
                                _vm._s(
                                  _vm.selectedShipments.length > 1 ? "s" : ""
                                ) +
                                "\n                            selected.\n                        "
                            ),
                          ]),
                          _c(
                            "div",
                            [
                              _c(
                                "drop-down",
                                {
                                  attrs: {
                                    "should-close": _vm.shouldCloseDropdown,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "status md-warning",
                                      attrs: {
                                        slot: "title",
                                        "data-toggle": "dropdown",
                                      },
                                      slot: "title",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Change Status\n                                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "ul",
                                    { staticClass: "dropdown-menu" },
                                    _vm._l(
                                      _vm.statuses,
                                      function (item, index) {
                                        return _c("li", { key: index }, [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handleChangeShipmentStatus(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(item) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                              !_vm.isSingleTeamMember &&
                              !_vm.isSingleTeamMember &&
                              !_vm.isCustomer &&
                              !_vm.isSupportStaff
                                ? _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-round md-just-icon md-info",
                                      attrs: { title: "Re-assign driver" },
                                      on: { click: _vm.bulkAssign },
                                    },
                                    [_c("md-icon", [_vm._v("person_pin")])],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-round md-just-icon md-danger",
                                  attrs: { title: "Delete Shipments" },
                                  on: { click: _vm.bulkDelete },
                                },
                                [_c("md-icon", [_vm._v("delete")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isListLoading
                    ? _c(
                        "div",
                        [
                          _c(
                            "md-table",
                            {
                              staticClass: "context-menu-support",
                              attrs: { id: "table-container" },
                            },
                            [
                              _c(
                                "md-table-row",
                                [
                                  !_vm.isCustomer && !_vm.isSupportStaff
                                    ? _c(
                                        "md-table-head",
                                        [
                                          _c("md-checkbox", {
                                            staticClass:
                                              "shipment-checkbox checkbox-head",
                                            on: {
                                              change: function ($event) {
                                                return _vm.onSelectAll(
                                                  _vm.isSelectAll
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.isSelectAll,
                                              callback: function ($$v) {
                                                _vm.isSelectAll = $$v
                                              },
                                              expression: "isSelectAll",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("TableHeaderDropdown", {
                                    attrs: {
                                      "column-icon": "keyboard_arrow_down",
                                      "default-text":
                                        _vm.tableColumnOptions.columns[
                                          _vm.columnKeys.referenceSource
                                        ].name,
                                      "dropdown-options":
                                        _vm.tableColumnKeyOptions
                                          .referenceSource,
                                      "selected-option":
                                        _vm.tableColumnOptions.columns[
                                          _vm.columnKeys.referenceSource
                                        ],
                                    },
                                    on: {
                                      selectedOption: function ($event) {
                                        return _vm.$_handleColumnSwitch(
                                          $event,
                                          _vm.columnKeys.referenceSource,
                                          "shipments.defaults.shipment-table-options"
                                        )
                                      },
                                    },
                                  }),
                                  _c("md-table-head", [_vm._v("Assigned To")]),
                                  _vm.showQuotingStatusColumn
                                    ? _c(
                                        "md-table-head",
                                        { staticClass: "hide-column" },
                                        [
                                          _vm._v(
                                            "\n                                    Quoting Status\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("md-table-head", [_vm._v("Status")]),
                                  _c("md-table-head", [_vm._v("When")]),
                                  _c("md-table-head", [_vm._v("Contact")]),
                                  _c(
                                    "md-table-head",
                                    { staticClass: "address-container" },
                                    [_vm._v("Address")]
                                  ),
                                  !_vm.isCustomer && !_vm.isSupportStaff
                                    ? _c("md-table-head", [_vm._v("Actions")])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._l(_vm.shipmentsList, function (item) {
                                return _c(
                                  "md-table-row",
                                  {
                                    key: item.shipmentId,
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.showShipmentDetailsSidebar(
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    !_vm.isCustomer && !_vm.isSupportStaff
                                      ? _c(
                                          "md-table-cell",
                                          [
                                            _c("md-checkbox", {
                                              staticClass: "shipment-checkbox",
                                              attrs: { value: item.shipmentId },
                                              model: {
                                                value: _vm.selectedShipments,
                                                callback: function ($$v) {
                                                  _vm.selectedShipments = $$v
                                                },
                                                expression: "selectedShipments",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "shipment-reference" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Shipment Details",
                                                params: {
                                                  shipmentId: item.shipmentId,
                                                },
                                              },
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.tableColumnOptions
                                                    .columns[
                                                    _vm.columnKeys
                                                      .referenceSource
                                                  ].key ===
                                                    "reference-number" ||
                                                    item.sourceReference ===
                                                      null ||
                                                    item.sourceReference === ""
                                                    ? item.shipmentRef
                                                    : item.sourceReference
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "status-container" },
                                      [
                                        _vm.hasActiveCarriers
                                          ? _c("AssignShipmentDropdown", {
                                              attrs: {
                                                shipment: item,
                                                "team-members": _vm.teamMembers,
                                              },
                                              on: {
                                                assignedUser:
                                                  _vm.onAssignedUser,
                                                handleError: _vm.showErrorModal,
                                              },
                                            })
                                          : _c(
                                              "span",
                                              [
                                                item.assignedTo.publicUserId !=
                                                null
                                                  ? _c(
                                                      "reassign-shipment-button",
                                                      {
                                                        attrs: {
                                                          type: "text",
                                                          item: item,
                                                          "team-members":
                                                            _vm.teamMembers,
                                                        },
                                                        on: {
                                                          assignedUser:
                                                            _vm.onAssignedUser,
                                                        },
                                                      }
                                                    )
                                                  : _c(
                                                      "reassign-shipment-button",
                                                      {
                                                        attrs: {
                                                          type: "button",
                                                          item: item,
                                                          "team-members":
                                                            _vm.teamMembers,
                                                        },
                                                        on: {
                                                          assignedUser:
                                                            _vm.onAssignedUser,
                                                        },
                                                      }
                                                    ),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    ),
                                    _vm.showQuotingStatusColumn
                                      ? _c(
                                          "md-table-cell",
                                          {
                                            staticClass:
                                              "status-container hide-column quote-status-container",
                                            class:
                                              !!item.quotingStatus &&
                                              item.quotingStatus ===
                                                "QuotesGathered"
                                                ? "quote-status"
                                                : "",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                class: !!item.quotingStatus
                                                  ? "status-" +
                                                    _vm.$_getQuotingStatusBadgeColor(
                                                      item.quotingStatus
                                                    )
                                                  : "",
                                              },
                                              [
                                                item.quotingStatus ==
                                                  "GatheringQuotes" ||
                                                item.showLoader
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "quotes-loader",
                                                        class: item.showLoader
                                                          ? "started-loader"
                                                          : "",
                                                      },
                                                      [
                                                        _c(
                                                          "straight-line-loader"
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !!item.quotingStatus
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "custom-badge quoting-badge",
                                                        class:
                                                          "status-" +
                                                          _vm.$_getQuotingStatusBadgeColor(
                                                            item.quotingStatus
                                                          ),
                                                      },
                                                      [
                                                        item.quotingStatus ==
                                                        "QuotesGathered"
                                                          ? _c(
                                                              "span",
                                                              {
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.handleShowQuotes(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                " +
                                                                    _vm._s(
                                                                      _vm.toQuotingStatusDesc(
                                                                        item
                                                                      )
                                                                    ) +
                                                                    "\n                                            "
                                                                ),
                                                              ]
                                                            )
                                                          : _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.toQuotingStatusDesc(
                                                                    item
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "status-container" },
                                      [
                                        _c("shipment-status-button", {
                                          attrs: { shipment: item },
                                          on: {
                                            statusUpdated:
                                              _vm.onChangedShipmentStatus,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    item.dropDate === null ||
                                    item.pickupDate === item.dropDate
                                      ? _c(
                                          "md-table-cell",
                                          { staticClass: "trip-date" },
                                          [
                                            item.tripId &&
                                            item.carrierTeamId == null
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "Trip Details",
                                                        params: {
                                                          tripId: item.tripId,
                                                        },
                                                      },
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f("dateFormat")(
                                                            item.tripDate,
                                                            _vm.DATE_TYPES
                                                              .standardDate
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _c("div", [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm._f("dateFormat")(
                                                          item.tripDate,
                                                          _vm.DATE_TYPES
                                                            .standardDate
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.dropDate !== null &&
                                    item.pickupDate !== item.dropDate
                                      ? _c(
                                          "md-table-cell",
                                          { staticClass: "trip-date" },
                                          [
                                            item.tripId
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "Trip Details",
                                                        params: {
                                                          tripId: item.tripId,
                                                        },
                                                      },
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f("dateFormat")(
                                                            item.pickupDate,
                                                            _vm.DATE_TYPES
                                                              .standardDate
                                                          )
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                    _c("strong", [
                                                      _vm._v("->"),
                                                    ]),
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f("dateFormat")(
                                                            item.dropDate,
                                                            _vm.DATE_TYPES
                                                              .standardDate
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _c("div", [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm._f("dateFormat")(
                                                          item.pickupDate,
                                                          _vm.DATE_TYPES
                                                            .standardDate
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                  _c("strong", [_vm._v("->")]),
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm._f("dateFormat")(
                                                          item.dropDate,
                                                          _vm.DATE_TYPES
                                                            .standardDate
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("md-table-cell", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.contact.name) +
                                          "\n                                "
                                      ),
                                    ]),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "address-container" },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "md-tooltip",
                                              {
                                                staticClass: "tooltip-width",
                                                attrs: {
                                                  "md-direction": "top",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "address-text-tooltip",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v("Pickup:"),
                                                    ]),
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm.$_displayAddressName(
                                                            {
                                                              address:
                                                                item.pickupAddress,
                                                              name: item.pickupName,
                                                            }
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "address-text-tooltip",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v("Drop off:"),
                                                    ]),
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm.$_displayAddressName(
                                                            {
                                                              address:
                                                                item.dropAddress,
                                                              name: item.dropName,
                                                            }
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "address-text" },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.$_displayAddressName({
                                                        address:
                                                          item.pickupAddress,
                                                        name: item.pickupName,
                                                      })
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "address-separator",
                                              },
                                              [_vm._v("->")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "address-text" },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.$_displayAddressName({
                                                        address:
                                                          item.dropAddress,
                                                        name: item.dropName,
                                                      })
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    !_vm.isReadOnlyUser
                                      ? _c(
                                          "md-table-cell",
                                          { staticClass: "action-buttons" },
                                          [
                                            _vm.showJobOfferButton
                                              ? _c("JobOfferButton", {
                                                  staticClass:
                                                    "job-offer-button",
                                                  attrs: {
                                                    "record-type": "Shipment",
                                                    "shipment-details": item,
                                                    type: "icon",
                                                  },
                                                  on: {
                                                    assignedUser:
                                                      _vm.onAssignedUser,
                                                    handleError:
                                                      _vm.showErrorModal,
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.teamHasOfferMethodSetup &&
                                            _vm.hasActiveCarriers
                                              ? _c("QuotesButton", {
                                                  staticClass: "quotes-button",
                                                  attrs: {
                                                    shipment: item,
                                                    type: "icon",
                                                  },
                                                  on: {
                                                    assignedUser:
                                                      _vm.onAssignedUser,
                                                    handleError:
                                                      _vm.showErrorModal,
                                                  },
                                                })
                                              : _vm._e(),
                                            !_vm.isSingleUser &&
                                            !_vm.isSingleTeamMember &&
                                            !_vm.isCustomer &&
                                            !_vm.isSupportStaff
                                              ? _c("reassign-shipment-button", {
                                                  attrs: {
                                                    type: "icon",
                                                    item: item,
                                                    "team-members":
                                                      _vm.teamMembers,
                                                  },
                                                  on: {
                                                    assignedUser:
                                                      _vm.onAssignedUser,
                                                  },
                                                })
                                              : _vm._e(),
                                            !_vm.isCustomer &&
                                            !_vm.isSupportStaff
                                              ? _c("update-shipment-button", {
                                                  attrs: {
                                                    shipment: item.shipmentId,
                                                    "team-members":
                                                      _vm.teamMembers,
                                                    "carrier-team-id":
                                                      item.carrierTeamId,
                                                  },
                                                  on: {
                                                    shipmentUpdated:
                                                      _vm.handleUpdateShipment,
                                                  },
                                                })
                                              : _vm._e(),
                                            !_vm.isReadOnlyUser
                                              ? _c("delete-shipment-button", {
                                                  attrs: {
                                                    "shipment-id":
                                                      item.shipmentId,
                                                    "team-members":
                                                      _vm.teamMembers,
                                                  },
                                                  on: {
                                                    shipmentDeleted:
                                                      _vm.handleDeleteShipment,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                          _vm.shipmentsList.length == 0 && !_vm.isLoading
                            ? _c("div", [
                                _c("p", { staticClass: "no-result-message" }, [
                                  _vm._v(
                                    "No results matching your search/filter could be found."
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("div", [
                        _c(
                          "div",
                          { staticClass: "shipmentbar-loader" },
                          [
                            _c("fade-loader", {
                              attrs: { loading: _vm.loading, color: "#333333" },
                            }),
                            _c("span", [_vm._v("LOADING")]),
                          ],
                          1
                        ),
                      ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "md-card-actions",
        {
          staticClass: "page-footer",
          attrs: { "md-alignment": "space-between" },
        },
        [
          _c("div", [
            _vm.total === _vm.pagination.perPage
              ? _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "\n                Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of many\n            "
                  ),
                ])
              : _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of " +
                      _vm._s(_vm.totalPages)
                  ),
                ]),
          ]),
          _c("pagination", {
            staticClass: "pagination-no-border pagination-success",
            attrs: {
              "per-page": _vm.pagination.perPage,
              total: _vm.total,
              "enable-first-page-link": true,
              "enable-last-page-link": true,
            },
            on: {
              "change-page": function ($event) {
                return _vm.handleChangePage($event, _vm.pagination.perPage)
              },
            },
            model: {
              value: _vm.pagination.currentPage,
              callback: function ($$v) {
                _vm.$set(_vm.pagination, "currentPage", $$v)
              },
              expression: "pagination.currentPage",
            },
          }),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.showShipmentDetails
            ? _c("shipment-sidebar", {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.toggleShipmentDetailsWindow,
                    expression: "toggleShipmentDetailsWindow",
                  },
                ],
                attrs: {
                  "shipment-id": _vm.shipmentId,
                  data: _vm.shipmentDetails,
                  "team-members": _vm.teamMembers,
                },
                on: {
                  assignShipment: _vm.onAssignedUser,
                  deleteShipment: _vm.handleDeleteShipment,
                  updateShipment: _vm.handleUpdateShipment,
                  changeShipmentStatus: _vm.onChangedShipmentStatus,
                  showAddNotes: _vm.handleAddShipmentNotes,
                  closeModal: _vm.toggleShipmentDetailsWindow,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }