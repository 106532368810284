<template>
    <div class="filter-inline">
        <md-field class="filter-field">
            <label class="field-label">Event Trigger:</label>
            <md-select id="eventTrigger-select" v-model="eventTrigger">
                <md-option :key="`all`" value="">All</md-option>
                <md-option v-for="item in filteredEventTriggers" :key="item.displayText" :value="item.eventTrigger">
                    {{ item.displayText }}
                </md-option>
            </md-select>
        </md-field>

        <md-field class="filter-field">
            <label class="field-label">Status:</label>
            <md-select id="smsStatuses-select" v-model="status">
                <md-option :key="`all`" value="">All</md-option>
                <md-option v-for="item in smsStatuses" :key="item.text" :value="item.value">
                    {{ item.text }}
                </md-option>
            </md-select>
        </md-field>

        <DateRangeFilter :is-single-date="false" @onChanged="onDateChanged" />
    </div>
</template>

<script>
import moment from 'moment';
import { TEAM_CUSTOM_ACTION_EVENT_TRIGGERS } from '@/utils/CustomActionConstants';
import DateRangeFilter from './DateRangeFilter';

export default {
    name: 'SmsHistoryFilter',
    components: { DateRangeFilter },
    data() {
        return {
            eventTriggers: TEAM_CUSTOM_ACTION_EVENT_TRIGGERS,
            eventTrigger: null,
            status: null,
            dateFrom: null,
            dateTo: null,
            smsStatuses: [
                {
                    text: 'Queued',
                    value: 'queued'
                },
                {
                    text: 'Sent',
                    value: 'sent'
                },
                {
                    text: 'Failed',
                    value: 'failed'
                },
                {
                    text: 'Delivered',
                    value: 'delivered'
                },
                {
                    text: 'Undelivered',
                    value: 'undelivered'
                },
                {
                    text: 'Others',
                    value: 'others'
                }
            ]
        };
    },
    computed: {
        filteredEventTriggers() {
            const customEvents = this.eventTriggers.filter((x) => x.supportedActions.includes('Sms'));

            customEvents.push({
                eventTrigger: 'stop-on-demand-sms',
                displayText: 'Bulk Sms'
            });

            customEvents.push({
                eventTrigger: 'test-sms',
                displayText: 'Test Sms'
            });

            customEvents.push({
                eventTrigger: 'text-sms',
                displayText: 'Text Sms'
            });

            return customEvents.sort((a, b) => (a.displayText > b.displayText ? 1 : -1));
        }
    },
    watch: {
        eventTrigger(newVal) {
            this.raiseEvent();
        },
        status(newVal) {
            this.raiseEvent();
        }
    },
    methods: {
        async onDateChanged(val) {
            this.dateFrom = val.startDate ? moment(val.startDate).format('YYYY-MM-DD') : null;
            this.dateTo = val.endDate ? moment(val.endDate).format('YYYY-MM-DD') : null;
            this.raiseEvent();
        },
        async raiseEvent() {
            const args = {
                eventTrigger: this.eventTrigger,
                status: this.status,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo
            };
            this.$emit('onChanged', args);
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .filter-field {
    display: inline-block;
    width: 200px;
    margin-right: 20px;
}
</style>
