<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <WarehouseFilter @onFilter="handleSearch" :team-region-id="filters.teamRegionId" />
                    </div>
                    <div class="custom-toolbar-end">
                        <search-component v-if="$root.isDesktop" @onSearch="handleSearch" />
                        <md-button
                            title="Create Warehouse"
                            class="md-primary md-just-icon md-round header-button"
                            @click="createWarehouse"
                        >
                            <md-icon>add</md-icon>
                        </md-button>
                        <input ref="fileInput" style="display: none" type="file" @change="onFileSelected" />
                        <md-button
                            title="Import Warehouses"
                            class="md-primary md-just-icon md-round header-button"
                            @click="$refs.fileInput.click()"
                        >
                            <md-icon>publish</md-icon>
                        </md-button>
                        <a class="download-btn" href="/sample-batchWarehouseUpload.xlsx" download>Download Template</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="error-message-container">
                    <batch-upload-error-message :content="errorMessages" :has-error-message="hasError" />
                </div>
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-warning">
                        <div class="card-icon">
                            <md-icon>store</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div v-if="isListLoading" class="empty-table">
                            <div class="table-loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="warehousesList.length">
                                <md-table class="context-menu-support custom-paginated-table">
                                    <md-table-row>
                                        <md-table-head>Name</md-table-head>
                                        <md-table-head>Address</md-table-head>
                                        <md-table-head>Team Region</md-table-head>
                                        <md-table-head>Actions</md-table-head>
                                    </md-table-row>
                                    <md-table-row
                                        v-for="item in warehousesList"
                                        :key="item.warehouseId"
                                        @click.stop="showSidebar(item.warehouseId)"
                                        class="warehouse-row"
                                    >
                                        <md-table-cell>
                                            {{ item.name }}

                                            <md-icon
                                                v-if="item.isDefaultShipmentPickupStop"
                                                title="This warehouse will be used as the default pickup address for shipments"
                                                class="is-default-icon"
                                            >
                                                verified
                                            </md-icon>
                                            <md-icon
                                                v-else-if="item.isDefaultShipmentDropStop"
                                                title="This warehouse will be used as the default drop address for shipments"
                                                class="is-default-icon"
                                            >
                                                bookmark_added
                                            </md-icon>
                                        </md-table-cell>
                                        <md-table-cell>{{ item.address }}</md-table-cell>
                                        <md-table-cell>{{ item.teamRegion ? item.teamRegion.name : '' }}</md-table-cell>
                                        <md-table-cell class="action-buttons">
                                            <md-button
                                                title="Update warehouse details"
                                                class="md-warning md-just-icon md-round"
                                                @click.stop="updateWarehouse(item.warehouseId)"
                                            >
                                                <md-icon>edit</md-icon>
                                            </md-button>
                                            <md-button
                                                title="Delete warehouse"
                                                class="md-danger md-just-icon md-round"
                                                @click.stop="handleDeleteWarehouse(item.warehouseId)"
                                            >
                                                <md-icon>delete</md-icon>
                                            </md-button>
                                        </md-table-cell>
                                    </md-table-row>
                                </md-table>
                            </div>
                            <div v-else>
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
                <md-card-actions class="page-footer" md-alignment="space-between">
                    <div>
                        <p v-if="total === pagination.perPage" class="card-category">
                            Page {{ pagination.currentPage }} of many
                        </p>
                        <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                    </div>
                    <pagination
                        v-model="pagination.currentPage"
                        class="pagination-no-border pagination-success"
                        :per-page="pagination.perPage"
                        :total="total"
                        @change-page="handleChangePage($event, pagination.perPage)"
                    />
                </md-card-actions>
            </div>

            <transition name="slide">
                <warehouse-sidebar
                    v-if="isShowSidebar"
                    :warehouse-id="warehouseId"
                    v-click-outside="toggleSidebarWindow"
                    @closeModal="toggleSidebarWindow"
                />
            </transition>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { WAREHOUSE_DETAILS_DEFAULTS, PAGINATION_DEFAULTS } from '@/utils/defaults';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { Pagination, SearchComponent, BatchUploadErrorMessage } from '@/components';
import CreateWarehouseModal from './Modals/CreateWarehouseModal';
import WarehouseSidebar from './WarehouseSidebar';
import WarehouseFilter from './components/WarehouseFilter';

export default {
    name: 'WarehousesOverview',
    mixins: [GeneralMixin],
    components: { FadeLoader, Pagination, WarehouseSidebar, BatchUploadErrorMessage, SearchComponent, WarehouseFilter },
    data() {
        return {
            warehousesList: [],
            isListLoading: false,
            pagination: PAGINATION_DEFAULTS,
            maxPage: 1,
            isShowSidebar: false,
            warehouseId: null,
            selectedFile: null,
            hasError: false,
            errorMessages: [],
            filters: { teamRegionId: null }
        };
    },
    computed: {
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        }
    },
    async mounted() {
        const currentPage = Number(this.$route.query.currentPage) || 1;
        if (currentPage) {
            this.pagination.currentPage = currentPage;
        }

        const {
            data: { warehouses, totalWarehouses }
        } = await this.getWarehousesList(currentPage);

        this.setWarehousesAndTotal(warehouses, totalWarehouses);
    },
    methods: {
        async getWarehousesList(pageNumber = 1, itemsPerPage = 50) {
            let response = {
                data: {
                    warehouses: [],
                    totalWarehouses: null
                }
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/warehouses`;
                response = await handleRequests(endpoint, {
                    params: {
                        pageNumber,
                        itemsPerPage,
                        ...this.cleanFilters()
                    }
                });
            } catch (error) {
                const message = 'Error in getting the warehouses list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;
            return response;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },
        setWarehousesAndTotal(data, total) {
            this.warehousesList = data;
            this.pagination.total = total || data.length;
            this.maxPage = Math.ceil(this.pagination.total / this.pagination.perPage);
        },
        async createWarehouse() {
            this.$modal
                .show(CreateWarehouseModal, {
                    warehouseDetails: Object.assign({}, WAREHOUSE_DETAILS_DEFAULTS())
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.handleChangePage(1, this.pagination.perPage);
                        this.$modal.hide();
                    }
                });
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            const {
                data: { warehouses, totalWarehouses }
            } = await this.getWarehousesList(currentPage, perPage);
            const total = totalWarehouses;
            this.setWarehousesAndTotal(warehouses, total);
        },
        showSidebar(id) {
            this.isShowSidebar = true;
            this.warehouseId = id;
        },
        toggleSidebarWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown)
                this.isShowSidebar = !this.isShowSidebar;
        },
        onFileSelected(event) {
            // eslint-disable-next-line prefer-destructuring
            this.selectedFile = event.target.files[0];
            event.target.value = '';
            if (this.selectedFile) 
                this.onUpload();
        },
        async onUpload() {
            this.$_handleLoaderState(true, 'IMPORTING...');
            const fd = new FormData();
            fd.append('file', this.selectedFile);

            const api = `/api/warehouses/import`;
            const payload = {
                method: 'post',
                data: fd
            };

            try {
                const response = await handleRequests(api, payload);

                if (response.status === 200) {
                    this.hasError = false;
                    this.errorMessages = [];
                    this.selectedFile = '';
                    this.$notify({
                        message: 'The warehouses have been successfully uploaded',
                        type: 'success'
                    });
                }
                this.$_handleLoaderState(false);
                this.handleChangePage(1, this.pagination.perPage);
            } catch (error) {
                this.$_handleLoaderState(false);
                this.hasError = true;
                this.errorMessages = error.data;
                if (!error.data) {
                    const message = 'Error in importing warehouses';
                    showErrorMessage(this, message, error);
                }
            }
        },
        async getWarehouseDetails(warehouseId) {
            try {
                const api = `/api/warehouses/${warehouseId}`;
                const { data } = await handleRequests(api);
                return data;
            } catch (error) {
                const message = 'Error in getting the warehouse details';
                showErrorMessage(this, message, error);
            }
            return {};
        },
        async updateWarehouse(warehouseId) {
            const warehouseDetails = await this.getWarehouseDetails(warehouseId);
            warehouseDetails.warehouseName = warehouseDetails.name;

            if (warehouseDetails.location == null) {
                warehouseDetails.location = {
                    latitude: null,
                    longitude: null
                };
            }

            this.$modal
                .show(CreateWarehouseModal, {
                    warehouseDetails: Object.assign({}, warehouseDetails),
                    isUpdate: true
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.handleChangePage(1, this.pagination.perPage);
                        this.$modal.hide();
                    }
                });
        },
        async deleteWarehouse(warehouseId) {
            const payload = {
                method: 'delete'
            };
            const api = `/api/warehouses/${warehouseId}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Warehouse was deleted!',
                    type: 'success'
                });
                this.handleChangePage(1, this.pagination.perPage);
            } catch (e) {
                const message = 'Error in deleting a warehouse.';
                showErrorMessage(this, message, e);
            }
        },
        handleDeleteWarehouse(warehouseId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Warehouse',
                    body: 'Are you sure you want to delete this warehouse?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isListLoading = true;
                        await this.deleteWarehouse(warehouseId);
                    }
                });
        },
        async handleSearch(data) {
            this.filters = Object.assign(this.filters, data);
            const {
                data: { warehouses, totalWarehouses }
            } = await this.getWarehousesList(1, this.pagination.perPage);

            this.setWarehousesAndTotal(warehouses, totalWarehouses);
        }
    }
};
</script>

<style lang="scss" scoped>
.download-btn {
    color: #2b93ff !important;
    margin-top: 10px;
    display: block;
    font-size: 12px;
}

::v-deep .error-body div {
    margin: 0;
    margin-top: 20px;
    padding: 10px;
}

.warehouse-row {
    cursor: pointer;
}

.warehouses-dashboard ::v-deep .md-card {
    margin-bottom: 0;
}

.is-default-icon {
    padding-left: 20px;
    color: green !important;
}

::v-deep .header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin-top: 0;
}
</style>
