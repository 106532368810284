<template>
    <div>
        <div class="md-layout">
            <div v-if="showDropdown">
                <div class="md-layout-item md-size-50">
                    <form-group label="Select an existing person">
                        <md-select
                            v-model="data.selectedPersonnel"
                            @md-selected="getExistingDetails(data.selectedPersonnel, index)"
                        >
                            <md-option value="0">
                                Create New
                            </md-option>
                            <md-option
                                v-for="p in dropdownList"
                                :key="p.assetId"
                                :value="p.assetId"
                                :disabled="selectedAssetList.includes(p.assetId)"
                            >
                                {{ p.name }}
                            </md-option>
                        </md-select>
                    </form-group>
                </div>
                <div class="md-layout-item md-size-50" v-if="listLength > 1">
                    <md-button
                        class="md-icon-button md-dense md-raised md-primary pull-right"
                        @click="removePersonnel(index)"
                    >
                        <md-icon>remove</md-icon>
                    </md-button>
                </div>
                <div class="md-layout-item md-size-100">
                    <h5>Personnel Information:</h5>
                </div>
            </div>
            <div class="md-layout-item md-size-100">
                <form-group :name="`$each.${index}.name`" label="Asset Name*">
                    <md-input v-model="data.name" :disabled="data.isDisabled" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-50">
                <form-group :name="`$each.${index}.firstName`" label="First Name">
                    <md-input
                        v-focus
                        v-model="data.firstName"
                        :disabled="data.isDisabled"
                        @input="setAssetName(data)"
                    />
                </form-group>
            </div>
            <div class="md-layout-item md-size-50">
                <form-group name="lastName" label="Last Name">
                    <md-input v-model="data.lastName" :disabled="data.isDisabled" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-50">
                <form-group :name="`$each.${index}.email`" label="Email Address">
                    <md-input v-model="data.email" :disabled="data.isDisabled" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-50">
                <form-group name="phone" label="Phone">
                    <md-input v-model="data.phone" :disabled="data.isDisabled" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-50">
                <form-group name="employeeNumber" label="Employee Number">
                    <md-input v-model="data.employeeNumber" :disabled="data.isDisabled" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-100" v-if="assetCustomFieldList !== null">
                <custom-field-inputs
                    v-for="(customField, i) in assetCustomFieldList"
                    :key="i"
                    :custom-field-definition="customField"
                    @changed="handleCustomFieldChanged"
                    :initial-value="data[customField.label]"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CustomFieldInputs } from '@/components';

export default {
    name: 'PersonnelStep',
    components: {
        CustomFieldInputs
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: () => 0
        },
        dropdownList: {
            type: Array,
            default: () => []
        },
        isDisabled: {
            type: Boolean,
            default: () => false
        },
        selectedAsset: {
            type: Number,
            default: () => 0
        },
        showDropdown: {
            type: Boolean,
            default: () => true
        },
        selectedAssetList: {
            type: Array,
            default: () => []
        },
        listLength: {
            type: Number,
            default: () => 0
        }
    },
    watch: {
        selectedAsset(newValue, oldValue) {
            this.prevId = oldValue;
        }
    },
    data() {
        return {
            prevId: 0,
            assetCustomFieldList: []
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    mounted() {
        if (this.user.assetCustomFieldDefinitions !== '' && this.user.assetCustomFieldDefinitions != null) {
            this.assetCustomFieldList = this.user.assetCustomFieldDefinitions.filter((item) => {
                return item.assetType === 'Personnel' || item.assetType === 'Any';
            });
        }
    },
    methods: {
        setAssetName(data) {
            data.name = `${data.firstName ? data.firstName : ''} ${data.lastName ? data.lastName : ''}`.trim();
        },
        getExistingDetails(assetId, index) {
            if (!this.selectedAssetList.includes(assetId)) {
                // eslint-disable-next-line radix
                this.$emit('getExistingDetails', parseInt(assetId), index, this.prevId);
            }
        },
        removePersonnel(index) {
            this.$emit('removePersonnel', index);
        },
        handleCustomFieldChanged({ name, value }) {
            this.data[name] = value;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-disabled .md-list-item-text {
    color: #eee;
}
</style>
