export const ASSET_LOG_TYPES = [
    {
        name: 'Insurance',
        key: 'insurance',
        sub_categories: [
            { name: 'Third Party Injury', key: 'third-party-injury', color: 'cancelled' },
            { name: 'Vehicle Insurance', key: 'vehicle-insurance', color: 'delayed' }
        ],
        color: 'arrived',
        icon: 'post_add',
        isVehicleLogType: false
    },
    {
        name: 'Log entry',
        key: 'log-entry',
        sub_categories: [
            { name: 'Odometer and engine hour reading', key: 'odometer-engine-hour-reading', color: 'arrived' },
            { name: 'Traffic violation', key: 'traffic-violation', color: 'cancelled' }
        ],
        color: 'delayed',
        icon: 'edit_document',
        isVehicleLogType: true
    },
    {
        name: 'Preventive Maintenance',
        key: 'preventive-maintenance',
        sub_categories: [
            { name: 'Air filter', key: 'air-filter', color: 'delayed' },
            { name: 'Battery check performance', key: 'battery-check-performance', color: 'complete' },
            { name: 'Battery replacement', key: 'battery-replacement', color: 'arrived' },
            { name: 'Break fluid', key: 'break-fluid', color: 'enroute' },
            { name: 'Change tyres', key: 'change-tyres', color: 'cancelled' },
            { name: 'Coolant liquid', key: 'coolant-liquid', color: 'departed' },
            { name: 'Engine air filter', key: 'engine-air-filter', color: 'cancelled' },
            { name: 'Engine replacement', key: 'engine-replacement', color: 'departed' },
            { name: 'Oil Change', key: 'oil-change', color: 'departed' },
            { name: 'Power steering liquid', key: 'power-steering-liquid', color: 'arrived' },
            { name: 'Replace windshield wipers', key: 'replace-windshield-wipers', color: 'complete' },
            { name: 'Spark plugs', key: 'spark-plugs', color: 'delayed' },
            { name: 'Tyre rotation', key: 'tyre-rotation', color: 'enroute' },
            { name: 'Vehicle Service', key: 'vehicle-service', color: 'enroute' }
        ],
        color: 'pending',
        icon: 'engineering',
        isVehicleLogType: false
    },
    {
        name: 'Repair',
        key: 'repair',
        sub_categories: [
            { name: 'Critial Repair', key: 'critical-repair', color: 'cancelled' },
            { name: 'Repair', key: 'repair', color: 'delayed' }
        ],
        color: 'complete',
        icon: 'construction',
        isVehicleLogType: true
    },
    {
        name: 'Vehicle Registration',
        key: 'vehicle-registration',
        sub_categories: [
            { name: 'Registration', key: 'registration', color: 'pending' },
            { name: 'Roadworthiness Certificate', key: 'roadworthiness-certificate', color: 'complete' }
        ],
        color: 'pending',
        icon: 'engineering',
        isVehicleLogType: false
    }
];
