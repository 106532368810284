<template>
    <md-select v-model="selectedIndustry" @md-selected="getSelectedIndustry">
        <md-option v-for="item in industryList" :key="item" :value="item">
            {{ item }}
        </md-option>
    </md-select>
</template>
<script>
import { INDUSTRY_CONSTANTS } from '@/utils/constants';

export default {
    name: 'IndustryDropDown',
    props: {
        industry: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            industryList: [...INDUSTRY_CONSTANTS],
            selectedIndustry: ''
        };
    },
    watch: {
        industry(newValue) {
            this.selectedIndustry = newValue;
        }
    },
    methods: {
        getSelectedIndustry() {
            this.$emit('selectedIndustry', this.selectedIndustry);
        }
    }
};
</script>
