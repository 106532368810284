<template>
    <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">
                        &nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.failedDeliveryConfiguration') }}
                    </h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>
                            <img src="/img/icons/failed-delivery-configuration-white-icon.svg" />
                        </md-icon>
                    </div>
                </md-card-header>
                <md-card-content>
                    <div class="custom-toolbar">
                        <div class="custom-toolbar-end">
                            <div class="header-button-container">
                                <md-button
                                    class="md-button md-primary header-button"
                                    @click="saveFailedDeliveryConfiguration"
                                >
                                    Save
                                </md-button>
                            </div>
                        </div>
                    </div>
                    <div class="cf-container">
                        <md-table class="custom-paginated-table">
                            <md-table-row slot="header">
                                <md-table-head>Type</md-table-head>
                                <md-table-head>Stops/Shipments</md-table-head>
                                <md-table-head>Include in PDF</md-table-head>
                                <md-table-head class="actions">Actions</md-table-head>
                            </md-table-row>

                            <md-table-row v-for="(item, index) in failedDeliveryConfiguration" :key="index">
                                <md-table-cell>
                                    {{ item.type }}
                                </md-table-cell>

                                <md-table-cell>
                                    <div class="select-tooltip-container">
                                        <form-group class="pod-dropdown">
                                            <md-select
                                                v-model="item.stopShipmentRequirement"
                                                :disabled="
                                                    item.type === 'Reasons' && item.stopShipmentRequirement === 'Always'
                                                        ? true
                                                        : false
                                                "
                                            >
                                                <md-option
                                                    :disabled="item.type === 'Reasons' && option !== 'Always'"
                                                    v-for="option in stopShipmentRequirementOptions"
                                                    :key="option"
                                                    :value="option"
                                                >
                                                    {{ option }}
                                                </md-option>
                                            </md-select>
                                        </form-group>
                                    </div>
                                </md-table-cell>

                                <md-table-cell>
                                    <form-group class="pod-checkbox">
                                        <md-checkbox v-model="item.includeInPodPdf" />
                                    </form-group>
                                </md-table-cell>

                                <md-table-cell>
                                    <md-button
                                        title="edit configuration"
                                        class="md-warning md-just-icon md-round"
                                        @click="handleEditConfiguration(item)"
                                        v-if="item.type === 'Reasons'"
                                    >
                                        <md-icon>edit</md-icon>
                                    </md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <div class="text-center" v-if="failedDeliveryConfiguration.length == 0">
                            <p class="no-result-message">This setting is currently not yet available.</p>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FailedDeliveryConfigurationModal from './FailedDeliveryConfigurationModal';

export default {
    name: 'FailedDeliveryConfiguration',
    mixins: [GeneralMixin],
    data() {
        return {
            failedDeliveryConfiguration: [],
            stopShipmentRequirementOptions: ['Always', 'Optional', 'Never']
        };
    },
    computed: {
        stopShipmentRequirementValues() {
            if (this.failedDeliveryConfiguration && this.failedDeliveryConfiguration.length > 0) {
                return this.failedDeliveryConfiguration.map((x) => x.stopShipmentRequirement);
            }

            return [];
        }
    },
    methods: {
        async setDefaultConfiguration(configuration) {
            let types = ['Reasons', 'Photos', 'Notes'];

            if (configuration && configuration.length !== 0) {
                types = [];
                configuration.forEach((x) => {
                    types.push(x.type);
                });
            }

            this.failedDeliveryConfiguration = [];

            types.forEach((type) => {
                const existingConfiguration =
                    configuration !== null ? configuration.find((x) => x.type === type) : null;
                if (existingConfiguration) {
                    this.failedDeliveryConfiguration.push(existingConfiguration);
                } else {
                    this.failedDeliveryConfiguration.push({
                        type,
                        includeInPodPdf: false,
                        stopShipmentRequirement: type === 'Reasons' ? 'Always' : 'Optional',
                        options: []
                    });
                }
            });
        },
        async getFailedDeliveryConfiguration() {
            this.$_handleLoaderState(true);
            try {
                const api = '/api/teams/failed-delivery-configuration';
                const response = await handleRequests(api);
                this.failedDeliveryConfiguration = Object.assign(this.failedDeliveryConfiguration, response.data);
            } catch (e) {
                const message = 'Unable to retrieve failed delivery configuration.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
            await this.setDefaultConfiguration(this.failedDeliveryConfiguration);
        },
        async saveFailedDeliveryConfiguration() {
            const payload = {
                method: 'post',
                data: this.failedDeliveryConfiguration
            };

            const api = '/api/teams/failed-delivery-configuration';
            try {
                this.$_handleLoaderState(true, 'SAVING...');
                await handleRequests(api, payload);

                this.$notify({
                    message: 'Failed delivery configuration saved!',
                    type: 'success'
                });
            } catch (e) {
                const message = 'Cannot save failed delivery configuration.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async handleEditConfiguration(item) {
            this.$modal
                .show(FailedDeliveryConfigurationModal, {
                    type: item.type,
                    failedDeliveryConfigurationOptions: item.options
                })
                .then((response) => {
                    item.options = [...response];
                });
        }
    },
    async mounted() {
        await this.getFailedDeliveryConfiguration();
    }
};
</script>

<style lang="scss" scoped>
.card-icon {
    background: #dd4b39 !important;
}
.pod-dropdown {
    width: 100px;
    margin-top: 0;
    border: 1px solid #ddd;
    padding: 0;
    padding-left: 10px;
}
.pod-dropdown:after,
.pod-dropdown:before {
    height: 0 !important;
    border-bottom: 0 !important;
}
.pod-checkbox {
    width: 100px;
    margin-top: 0;
    padding: 0;
    padding-left: 10px;
}
.pod-checkbox:after,
.pod-checkbox:before {
    height: 0;
}
.actions {
    width: 10%;
}
.select-tooltip-container {
    width: 100px;
}

::v-deep .md-list-item-button {
    .md-disabled {
        color: #aaa;
    }
}
</style>
