<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item mx-auto text-center">
                <h2 class="title">
                    Locate2u Pricing Benefits
                </h2>
                <h5 class="description">
                    Our pricing plans are designed for small or large delivery or service businesses.
                </h5>
            </div>
        </div>
        <div class="md-layout text-center">
            <div class="md-layout-item md-medium-size-30 md-small-size-50 md-xsmall-size-100 md-size-25">
                <div class="pricing-plan-container">
                    <form-group>
                        <md-select v-model="starterPlan" @md-selected="selectStarterPlan">
                            <md-option value="core-gps">Core - GPS</md-option>
                            <md-option value="professional-gps">Professional - GPS</md-option>
                        </md-select>
                    </form-group>
                    <div class="content-price">
                        <div class="price">{{ currency }}{{ starterPrice }}</div>
                        <p>monthly per user</p>
                        <p>
                            Ready to know where your team and assets live throughout the day? Start using Locate2u
                            today.
                        </p>
                    </div>
                    <a v-if="enableLiveMode" :href="starterUrl" target="_blank">Choose plan</a>
                    <div class="ideal-container">
                        <h5>Ideal for customers who:</h5>
                        <p>Have up to 5 vehicles or assets to track</p>
                        <p>Need to see live location and don't require a long history of data</p>
                    </div>

                    <div class="key-benefits">
                        <h5>Key benefits</h5>
                        <ul>
                            <li>Use your mobile or our GPS device to track the live location of your team</li>
                            <li>View a route replay up to 3 days</li>
                            <li>Track your live location via mobile and web app</li>
                            <li>
                                Suitable for most types of vehicles and can be used for tracking trailers, equipment and
                                bins
                            </li>
                            <li>Email and chat based support. 24 hrs 5 day week</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="md-layout-item md-medium-size-30 md-small-size-50 md-xsmall-size-100 md-size-25">
                <div class="pricing-plan-container">
                    <form-group>
                        <md-select v-model="premiumPlan" @md-selected="selectPremiumPlan">
                            <md-option value="premium3">Premium 3</md-option>
                            <md-option value="premium10">Premium 10</md-option>
                            <md-option value="premium30">Premium 30</md-option>
                        </md-select>
                    </form-group>
                    <div class="content-price">
                        <div class="price">{{ currency }}{{ premiumPrice }}</div>
                        <p>monthly per user</p>
                        <p>
                            Looking for a complete solution for your service or delivery business? Provide your
                            customers with the ultimate customer experience and help your business grow at speed.
                        </p>
                    </div>
                    <a v-if="enableLiveMode" :href="premiumUrl" target="_blank">Choose plan</a>
                    <div class="ideal-container">
                        <h5>Ideal for customers who:</h5>
                        <p>
                            You are either a delivery, transport or service business looking to provide a better
                            experience to your customers.
                        </p>
                        <p>Require a system to track your team and share that information with your customers</p>
                        <p>Require a system to manage your bookings.</p>
                        <p>Wanting to optimize your route</p>
                        <p>Need up to 3 users</p>
                        <p>Manage up to 100 bookings in a day</p>
                    </div>

                    <div class="key-benefits">
                        <h5>Key benefits</h5>
                        <ul>
                            <li>Booking Management - Includes proof of delivery, custom fields</li>
                            <li>Driver App</li>
                            <li>Access to portal via Mobile or Desktop</li>
                            <li>Schedule view of your bookings</li>
                            <li>Optimize your route in real time, save your drivers time and money</li>
                            <li>Share your live location with customers</li>
                            <li>GPS tracking via mobile or hardware device</li>
                            <li>View route replay up to 120 days in history</li>
                            <li>Automatic SMS</li>
                            <li>Up to 3 users</li>
                            <li>Phone, Email and Chat based support, 24 hrs 5 day a week</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="md-layout-item md-medium-size-30 md-small-size-50 md-xsmall-size-100 md-size-25">
                <div class="pricing-plan-container">
                    <div class="enterprise-title">Enterprise</div>
                    <div class="content-price">
                        <p>
                            For enterprise clients who have over 50 users and are looking to use our API, speak to us so
                            we can assist in developing a solution specifically for your business.
                        </p>
                        <p style="small-p">
                            We work with many businesses across the world who are looking to improve their profitability
                            and increase their customer experience
                        </p>
                    </div>
                    <a v-if="enableLiveMode" :href="enterpriseUrl" target="_blank">Inquire now</a>
                    <div class="ideal-container">
                        <h5>Ideal for customers who:</h5>
                        <p>Enterprise customers who belong to the following industries;</p>
                        <h5>Logistics Businesses</h5>
                        <p>Same Day Couriers, Line Haul Trucking, Tow Trucks, Retail &amp; Food delivery.</p>
                        <h5>Service Businesses:</h5>
                        <p>
                            Cleaning, Security, Plumber, Electrical, Installation, Repair, IT Service, Garden
                            Maintenance Rubbish Removal, Removalists and the list goes on and on. Feel free to reach out
                            to us to see if we can assist you..
                        </p>
                    </div>

                    <div class="key-benefits">
                        <h5>Key benefits</h5>
                        <ul>
                            <li>Use your mobile or our GPS device to track the live location of your team</li>
                            <li>View a route replay up to 3 days</li>
                            <li>Track your live location via mobile and web app</li>
                            <li>
                                Suitable for most types of vehicles and can be used for tracking trailers, equipment and
                                bins
                            </li>
                            <li>Phone, Email and Chat based support. 24 hrs 5 day week</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item mx-auto text-center">
                <h5 class="description">
                    If you have any questions regarding your subscription please contact us at
                    <a href="mailto:hello@locate2u.com">hello@locate2u.com</a>
                </h5>
            </div>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import { PRICING_PLANS } from '@/utils/constants';

export default {
    name: 'PricingPlans',
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    data() {
        return {
            starterPlan: 'core-gps', // pricing plans should be loaded from server/stripe in the near future
            starterUsers: 5,
            starterPrice: 10,
            premiumUsers: 3,
            premiumPrice: 95,
            premiumPlan: 'premium3',
            pricingPlans: PRICING_PLANS,
            starterUrl: '',
            premiumUrl: '',
            enterpriseUrl: '',
            hostUrl: 'https://payment.locate2u.com/order-plans',
            userCountryCode: 'au',
            enableLiveMode: true,
            currency: '$'
        };
    },
    async mounted() {
        await this.$store.dispatch('user/FETCH_USER');

        this.userCountryCode = this.user.countryCode.toLowerCase();
        this.starterPrice = this.getCountryPrice('core-gps');
        this.starterUrl = `${this.hostUrl}/?user_id=${this.user.publicUserId}&plan_type=core-gps`;
        this.premiumPrice = this.getCountryPrice('premium3');
        this.premiumUrl = `${this.hostUrl}/?user_id=${this.user.publicUserId}&plan_type=premium3`;
        this.enterpriseUrl = `${this.hostUrl}/?user_id=${this.user.publicUserId}&plan_type=enterprise`;
    },
    methods: {
        getCountryPrice(plan) {
            const pricingPlan = this.pricingPlans.find((x) => x.name === plan);

            const euCountries = [
                'be',
                'bg',
                'cz',
                'dk',
                'de',
                'ee',
                'ie',
                'el',
                'es',
                'fr',
                'hr',
                'it',
                'cy',
                'lv',
                'lt',
                'lu',
                'hu',
                'mt',
                'nl',
                'at',
                'pl',
                'pt',
                'ro',
                'si',
                'sk',
                'fi',
                'se',
                'tr',
                'no'
            ];

            if (euCountries.includes(this.userCountryCode.toLowerCase())) {
                this.userCountryCode = 'eu';
            }

            switch (this.userCountryCode.toLowerCase()) {
                case 'eu':
                    this.currency = '€';
                    break;
                case 'gb':
                    this.currency = '£';
                    break;
                default:
                    this.currency = '$';
                    break;
            }

            if (pricingPlan.prices.find((y) => y.key === this.userCountryCode)) {
                return pricingPlan.prices.find((y) => y.key === this.userCountryCode).price;
            }

            // default to us prices if country is not supported
            return pricingPlan.prices.find((y) => y.key === 'us').price;
        },
        selectStarterPlan(value) {
            this.starterPrice = this.getCountryPrice(value);
            this.starterUrl = `${this.hostUrl}/?user_id=${this.user.publicUserId}&plan_type=${value}`;
        },
        selectPremiumPlan(value) {
            this.premiumPrice = this.getCountryPrice(value);
            this.premiumUrl = `${this.hostUrl}/?user_id=${this.user.publicUserId}&plan_type=${value}`;
        }
    }
};
</script>

<style scoped lang="scss">
.key-benefits,
.ideal-container {
    text-align: left;
}

.pricing-plan-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 30px;

    ::v-deep .md-select-value {
        text-align: center;
        font-size: 1.4rem !important;
    }

    a {
        background-color: #2b93ff;
        color: #fff;
        padding: 15px 40px;
        border-radius: 4px;
        margin: 20px auto;
        display: block;
    }

    a:hover,
    a:focus,
    a:active {
        color: #fff !important;
    }

    h5 {
        color: #2b93ff;
        font-weight: 700;
    }

    .price {
        font-size: 3rem;
        font-weight: 900;
        margin-top: 30px;
    }

    .enterprise-title {
        font-weight: 700;
        font-size: 1.4rem;
        margin-top: 20px;
    }
    .small-p {
        font-size: 1rem;
    }
}

@media (min-width: 992px) {
    .pricing-plan-container {
        .content-price {
            height: 330px;
            margin-top: 50px;
        }
        .enterprise-title {
            margin-bottom: 65px;
        }
    }
}

@media (min-width: 1200px) {
    .pricing-plan-container {
        .content-price {
            height: 250px;
        }
    }
}

@media (min-width: 1750px) {
    .pricing-plan-container {
        .content-price {
            height: 200px;
        }
    }
}
</style>
