<template>
    <div class="modal-container">
        <div class="modal-header" v-if="title">
            <h4 class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
            <div v-html="body" />
        </div>
        <div class="modal-footer" v-if="buttons.length">
            <md-button
                v-for="(button, index) in buttons"
                :key="index"
                :class="[index === 0 ? 'md-primary' : 'md-default']"
                class="dialog-button"
                @click.stop="
                    () => {
                        resolve(button);
                    }
                "
            >
                {{ button }}
            </md-button>
        </div>
        <div class="modal-footer" v-else>
            <md-button
                class="md-primary dialog-button"
                @click.stop="
                    () => {
                        resolve(true);
                    }
                "
            >
                OK
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MessageBox',
    props: {
        buttons: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ''
        },
        body: {
            type: String,
            default: ''
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    beforeMount() {
        window.addEventListener('keyup', this.onKeyUp);
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.onKeyUp);
    },
    methods: {
        onKeyUp(event) {
            const hasButtonsProp = this.buttons.length > 0;
            // escape button pressed
            if (event.which === 13) {
                this.resolve(hasButtonsProp ? this.buttons[0] : true);
            }
            // enter button pressed
            else if (event.which === 27) {
                this.resolve(hasButtonsProp ? this.buttons[this.buttons.length - 1] : false);
            }
        }
    }
};
</script>
