<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item md-size-100 mx-auto">
                <md-card class="md-card-calendar">
                    <md-card-content>
                        <fullCalendar
                            ref="calendar"
                            @eventClick="eventClick"
                            :options="calendarOptions"
                            v-if="members.length > 0"
                        />
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { DATE_TYPES } from '@/utils/constants';

// const today = new Date()
// const y = today.getFullYear()
// const m = today.getMonth()
// const d = today.getDate()

export default {
    name: 'CalendarWidget',
    components: {
        FullCalendar
    },
    props: {
        events: {
            type: Array,
            default: () => []
        },
        members: {
            type: Array,
            default: () => []
        },
        hasMemberSelected: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        events(newVal) {
            this.calendarOptions.events = newVal;
        },
        members(newVal) {
            if (newVal.length !== 0) {
                this.memberList = [];
                newVal.forEach((member) => {
                    this.memberList.push({ id: member.publicUserId, title: member.fullName });
                });
                this.calendarOptions.resources = this.memberList;
            }
        },
        hasMemberSelected(val) {
            if (!val) 
                this.$refs.calendar.getApi().changeView('resourceTimelineDay');
        }
    },
    data() {
        return {
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, resourceTimelinePlugin],
                // themeSystem: 'standard',
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay resourceTimelineDay'
                },

                views: {
                    resourceTimelineDay: {
                        buttonText: 'timeline',
                        titleFormat: this.getStandardDayTitle
                    },
                    timeGridDay: {
                        slotDuration: '00:15:00',
                        titleFormat: this.getStandardDayTitle
                    },
                    timeGridWeek: {
                        slotDuration: '00:15:00',
                        titleFormat: this.getStandardWeekTitle
                    }
                },
                initialView: 'resourceTimelineDay',
                editable: true,
                dropabble: true,
                resourceAreaHeaderContent: 'Team members',
                resourceAreaWidth: '15%',
                slotMinWidth: '100',
                events: [],
                resourceOrder: 'title',
                resources: this.memberList,
                allDaySlot: true,
                selectable: true,
                aspectRatio: 2.85,
                select: this.handleSelect,
                drop: this.handleDrop,
                height: '80vh',
                eventContent(eventInfo) {
                    return { html: eventInfo.event.extendedProps.customHtml };
                },
                eventMouseEnter: this.handleEventMouseEnter,
                eventDrop: this.handleCalendarEventDrop,
                eventResize: this.handleCalendarEventResize,
                datesSet: this.handleChangeView,
                eventClick: this.eventClick,
                snapDuration: '00:15:00'
            },
            draggable: null,
            memberList: [],
            calendar: null,
            initialLoad: true
        };
    },
    methods: {
        getStandardDayTitle(viewInfo) {
            return moment(viewInfo.date.marker).format('dddd, MMMM DD, YYYY');
        },
        getStandardWeekTitle(viewInfo) {
            const start = moment(viewInfo.start.marker).format('MMMM DD');
            const end = moment(viewInfo.end.marker).format('DD, YYYY');
            return `${start} - ${end}`;
        },
        getSelectedView(type) {
            let viewType;
            switch (type) {
                case 'month':
                    viewType = 'dayGridMonth';
                    break;
                case 'week':
                    viewType = 'timeGridWeek';
                    break;
                case 'day':
                    viewType = 'timeGridDay';
                    break;
                case 'timeline':
                default:
                    viewType = 'resourceTimelineDay';
            }

            return viewType;
        },
        handleChangeView({ view }) {
            const { type, currentStart, currentEnd } = view;

            const dateStart = moment(currentStart).format(DATE_TYPES.internationalDate);
            const dateEnd = moment(currentEnd).format(DATE_TYPES.internationalDate);
            let selectedView;
            let emittedView;

            // On initial load, use the user's last used calendar view via the localstorage and update the route accordingly.
            if (this.initialLoad) {
                if (!this.$route.params.type) {
                    const defaultView = localStorage.getItem('stops.defaults.calendar-view');
                    selectedView = defaultView;
                    if (!defaultView) {
                        selectedView = 'timeline';
                    } else {
                        localStorage.removeItem('stops.defaults.calendar-view');
                    }
                } else {
                    selectedView = this.$route.params.type;
                    this.$router.replace({ path: `/stops/calendar/${this.$route.params.type}` });
                }
                emittedView = selectedView;
                const viewType = this.getSelectedView(selectedView);
                this.$refs.calendar.getApi().changeView(viewType);
                this.initialLoad = false;
                // we do not emit dateChange here cause when we call the changeView function on line 169 we call the handleChangeView function again
                // and it should go to the else part to handle the emit.
            } else {
                let viewType;
                switch (type) {
                    case 'dayGridMonth':
                        viewType = 'month';
                        break;
                    case 'timeGridWeek':
                        viewType = 'week';
                        break;
                    case 'resourceTimelineDay':
                        viewType = 'timeline';
                        break;
                    case 'timeGridDay':
                    default:
                        viewType = 'day';
                }
                emittedView = viewType;
                this.$emit('dateChange', { type: emittedView, dateStart, dateEnd });
            }
        },
        eventClick(event) {
            event.jsEvent.stopPropagation();
            event.jsEvent.preventDefault();
            this.$emit('getStopInfo', event);
        },
        handleSelect(event) {},
        handleDrop(event) {
            this.$emit('eventAdded', event);
        },
        handleCalendarEventDrop(event) {
            this.$emit('eventCalendarDrop', event);
        },
        handleCalendarEventResize(event) {
            this.$emit('eventCalendarResize', event);
        },
        handleEventMouseEnter(event) {
            this.$emit('eventMouseHover', event);
        }
    }
};
</script>
<style lang="scss" scoped>
#fullCalendar {
    min-height: 300px;
}

::v-deep .fc-event {
    // height: 60px !important;
    border: none;
    padding: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    // cursor: move;
    // margin: 3px 0;
    .heavy-font {
        font-weight: 600;
        text-transform: uppercase;
    }

    div {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .type-day {
        display: inline-block;
    }
    .type-day:first-child {
        margin-right: 10px;
    }
}

::v-deep .fc-event-title {
    white-space: initial;
    text-align: left;
}

::v-deep {
    .test {
        background-color: red !important;
        opacity: 0.8 !important;
    }
}

.md-card-calendar {
    background-color: #fff;
    height: 80vh;
    .md-card-content {
        padding: 0 !important;
    }
}

.text-center {
    text-align: center;
}

::v-deep .fc-button-primary,
::v-deep .fc-button-primary:active,
::v-deep .fc-button-primary:hover,
::v-deep .fc-button-primary:focus {
    background-color: #2b93ff !important;
}

::v-deep .fc-timeline-slot-cushion,
::v-deep .fc-col-header-cell-cushion,
::v-deep .fc-daygrid-day-number {
    color: #2b93ff !important;
}

::v-deep .fc-timeline-event-harness {
    text-align: left;
}

::v-deep .fc .fc-timegrid .fc-daygrid-body {
    max-height: 200px;
}
</style>
