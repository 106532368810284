<template>
    <div class="ratings">
        <md-card class="card">
            <h3><strong>Customer reviews</strong></h3>
            <div>
                <div v-if="totalRatings">
                    <md-icon v-for="index in parseInt(avgRatingRoundedOnHalfDecimal)" :key="index" class="orange-star">
                        star
                    </md-icon>
                    <md-icon
                        v-if="avgRatingRoundedOnHalfDecimal != parseInt(avgRatingRoundedOnHalfDecimal)"
                        class="orange-star"
                    >
                        star_half
                    </md-icon>
                    <md-icon
                        v-for="index in parseInt(5 - avgRatingRoundedOnHalfDecimal)"
                        class="orange-star"
                        :key="'ls' + index"
                    >
                        star_outline
                    </md-icon>

                    <span class="padding">
                        <strong>{{ avgRatingRoundedOnDecimal }} out of 5</strong>
                    </span>
                    <div class="padding">{{ totalRatings }} ratings</div>
                </div>
                <div else>
                    <md-empty-state
                        v-if="totalRatings === 0"
                        md-icon="reviews"
                        md-label="No reviews"
                        md-description="No reviews for the selected criteria."
                    ></md-empty-state>
                </div>
                <div
                    v-for="index in 5"
                    :key="'gr' + index"
                    @click.stop="ratingSelected(6 - index)"
                    :class="currentRating === 6 - index ? 'selected-line' : 'grid-wrapper'"
                >
                    <div class="stars">{{ 6 - index }} star</div>
                    <div class="bar">
                        <div class="progressbar">
                            <div :style="{ width: percents[5 - index] + '%' }"></div>
                        </div>
                    </div>
                    <div v-if="totalRatings !== 0" class="percent">{{ percents[5 - index] }}%</div>
                </div>
            </div>
        </md-card>
    </div>
</template>
<script>
export default {
    name: 'RatingsDisplay',
    props: {
        ratings: {
            type: Array,
            default: () => null
        },
        canBeSelected: {
            type: Boolean,
            default: false
        },
        selectedRating: {
            type: Number,
            default: () => -1
        }
    },
    computed: {
        avgRating() {
            return this.ratings.reduce((p, c, i) => p + c * (i + 1), 0) / this.totalRatings;
        },
        avgRatingRoundedOnDecimal() {
            // Possible return values: 0.1, 0.2, 0.3, ..., 5.0
            return (Math.round(this.avgRating * 10) / 10).toFixed(1);
        },
        avgRatingRoundedOnHalfDecimal() {
            // Possible return values: 0.5, 1.0, 1.5, 2.0, 2.5, ..., 5.0
            return (Math.round(this.avgRating * 2) / 2).toFixed(1);
        },
        totalRatings() {
            return this.ratings.reduce((a, b) => a + b, 0);
        },
        percents() {
            const tot = this.totalRatings;
            if (tot === 0) 
                return this.ratings.map((n) => 0);
            return this.ratings.map((n) => Math.round((100 * n) / tot));
        }
    },
    data() {
        return {
            currentRating: this.selectedRating
        };
    },
    methods: {
        ratingSelected(rating) {
            if (this.canBeSelected) {
                if (this.currentRating !== rating) 
                    this.currentRating = rating;
                else 
                    this.currentRating = null;
                this.$emit('selectedRating', this.currentRating);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.ratings {
    margin-top: 24px;
    position: relative;

    .card {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1000;
        margin: 4px;
        padding: 4px;
        width: 300px;

        h3 {
            margin: 4px;
        }

        .padding {
            padding: 4px;
        }

        .grid-wrapper {
            height: 26px;
            width: 100%;
            display: block;
            border: 1px none #f00;
        }

        .selected-line {
            height: 26px;
            width: 100%;
            display: block;
            border: 1px solid #f00;
            background-color: lightyellow;
        }

        .grid-wrapper,
        .selected-line {
            cursor: pointer;
        }

        .stars {
            float: left;
            width: 50px;
            text-align: center;
        }

        .bar {
            float: left;
            width: 200px;
            padding: 4px;
        }

        .percent {
            float: left;
            width: 30px;
        }

        .progressbar {
            background-color: #eeeeee;
            border-style: solid;
            border-width: 1px;
            border-color: darkorange;
            border-radius: 2px;
            /* (height of inner div) / 2 + padding */
            padding: 0px;
        }

        .progressbar > div {
            background-color: orange;
            /*width: 34%;*/
            /* Adjust with JavaScript */
            height: 12px;
            border-radius: 2px;
        }

        .md-empty-state {
            padding: 0px;
        }

        .orange-star {
            color: orange !important;
        }
    }
}
</style>
