<template>
    <div id="chargeSidePanel" class="charge-wrapper side-panel" v-if="ratesList.length">
        <div>
            <div class="">
                <b>Charges</b>
            </div>
            <md-table class="custom-paginated-table" v-if="!ratesIsLoading">
                <md-table-row>
                    <md-table-head />
                    <md-table-head>Description</md-table-head>
                    <md-table-head>Quantity</md-table-head>
                    <md-table-head>Amount</md-table-head>
                </md-table-row>

                <md-table-row v-for="(rate, index) in ratesList" :key="index">
                    <md-table-cell>
                        <md-icon v-if="rate.isManualCharge || rate.manuallyChanged">
                            <span class="">
                                drive_file_rename_outline
                            </span>
                        </md-icon>
                    </md-table-cell>
                    <md-table-cell>{{ rate.description }}</md-table-cell>
                    <md-table-cell v-if="rate.isManualCharge || rate.manuallyChanged" />
                    <md-table-cell v-else>
                        {{ rate.chargeUnit }}
                    </md-table-cell>
                    <md-table-cell>
                        {{ $_getRateAmount(rate) | currency(currency) }}
                    </md-table-cell>
                </md-table-row>
                <md-table-row>
                    <md-table-cell />
                    <md-table-cell />
                    <md-table-cell />
                    <md-table-cell class="total-style">
                        {{ $_getTotalAmount(ratesList) | currency(currency) }}
                    </md-table-cell>
                </md-table-row>
            </md-table>
            <span class="loading-text" v-if="ratesIsLoading">Loading Charges...</span>
        </div>
    </div>
</template>

<script>
import { GeneralMixin, RatesEngineMixin } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
    name: 'ChargeListModal',
    components: {},
    mixins: [GeneralMixin, RatesEngineMixin],
    props: {
        ratesList: {
            type: Array,
            default: () => []
        },
        ratesIsLoading: {
            type: Boolean,
            default: false
        },
        rateListData: {
            type: Object,
            default: () => []
        },
        currency: {
            type: String,
            default: ''
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        })
    },
    async mounted() {
        if (this.rateListData === null) {
            return;
        }

        if (this.rateListData.totalCharge === 0 || this.rateListData.totalCharge === null) {
            this.rateListData.totalCharge = this.ratesList.reduce((n, { amount }) => n + amount, 0);
        }
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.charge-wrapper {
    padding-left: 25px;
}

.side-panel {
    display: none;
    width: 500px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 600px;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    border-radius: 15px;
    border: 2px solid #000000;
    max-width: 500px;
    overflow: auto;
    background-color: #ffffff;
}

.side-panel-open {
    display: initial;
    width: 500px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 601px;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    border-radius: 15px;
    border: 2px solid #ffffff;
    max-width: 500px;
    overflow: auto;
    background-color: #ffffff;
    box-shadow: 0 0 10px (0, 0, 0, 0.35);
}

.loading-text {
    padding-left: 30%;
}

.total-style {
    color: #4caf50 !important;
    font-weight: bold !important;
}
</style>
