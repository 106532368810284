var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "md-layout" }, [
        _c("div", { staticClass: "md-layout-item" }, [
          _c("div", { staticClass: "custom-toolbar" }, [
            _c("div", { staticClass: "custom-toolbar-start" }),
            _c("div", { staticClass: "custom-toolbar-end" }, [
              _c(
                "div",
                [
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-primary md-just-icon md-round pull-right header-button",
                      attrs: { title: "Add maintenance schedule" },
                      on: { click: _vm.createMaintenanceSchedule },
                    },
                    [_c("md-icon", [_vm._v("add")])],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-green" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("schedule")])],
                      1
                    ),
                  ]
                ),
                _c("md-card-content", { staticClass: "body-list" }, [
                  !_vm.isListLoading
                    ? _c(
                        "div",
                        [
                          _vm.maintenanceScheduleList.length > 0
                            ? _c(
                                "md-table",
                                { staticClass: "context-menu-support" },
                                [
                                  _c(
                                    "md-table-row",
                                    [
                                      _c("md-table-head", [
                                        _vm._v("Maintenance Schedules"),
                                      ]),
                                      _c("md-table-head", [_vm._v("Type")]),
                                      _c("md-table-head", [_vm._v("Status")]),
                                      _c("md-table-head", [_vm._v("Actions")]),
                                    ],
                                    1
                                  ),
                                  _vm._l(
                                    _vm.maintenanceScheduleList,
                                    function (item) {
                                      return _c(
                                        "md-table-row",
                                        {
                                          key: item.assetMaintenanceScheduleId,
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.showSidebarDetails(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("md-table-cell", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(item.maintenanceName) +
                                                "\n                                "
                                            ),
                                          ]),
                                          _c("md-table-cell", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "material-icons custom-type-icon",
                                                class:
                                                  "icon-" +
                                                  _vm.$_getAssetLogTypeBadgeColor(
                                                    item.maintenanceType
                                                  ),
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.$_getAssetLogTypeIcon(
                                                        item.maintenanceType
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "assetLogTypes." +
                                                      item.maintenanceType
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]),
                                          _c(
                                            "md-table-cell",
                                            [
                                              _c("drop-down", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "status status-complete",
                                                    attrs: {
                                                      slot: "title",
                                                      "data-toggle": "dropdown",
                                                    },
                                                    slot: "title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(item.status) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "md-table-cell",
                                            { staticClass: "action-buttons" },
                                            [
                                              _c(
                                                "update-maintenance-schedule-button",
                                                {
                                                  attrs: {
                                                    "maintenance-data": item,
                                                  },
                                                  on: {
                                                    maintenanceUpdateCompleted:
                                                      _vm.handleUpdate,
                                                  },
                                                }
                                              ),
                                              _c(
                                                "delete-maintenance-schedule-button",
                                                {
                                                  attrs: {
                                                    id: item.assetMaintenanceScheduleId,
                                                  },
                                                  on: {
                                                    deleted: _vm.handleDelete,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _c("div", [
                                _c("p", { staticClass: "no-result-message" }, [
                                  _vm._v(
                                    "No existing maintenance schedule could be found."
                                  ),
                                ]),
                              ]),
                        ],
                        1
                      )
                    : _c("div", [
                        _c(
                          "div",
                          { staticClass: "content-loader" },
                          [
                            _c("fade-loader", {
                              attrs: {
                                loading: _vm.isListLoading,
                                color: "#333333",
                              },
                            }),
                            _c("span", [_vm._v("LOADING")]),
                          ],
                          1
                        ),
                      ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.showSidebar
            ? _c("maintenance-schedule-sidebar", {
                attrs: { "sidebar-data": _vm.sidebarData },
                on: { closeModal: _vm.toggleSidebar },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }