<template>
    <div class="main-time-picker-container">
        <div class="time-picker-container" :class="!isTimeValid ? 'error-box' : ''">
            <div>
                <md-input name="time" v-model="selectedTime" type="hidden" />
                <vue-select
                    append-to-body
                    :calculate-position="withPopper"
                    taggable
                    :disabled="!timeOptions.length || disabled"
                    :options="timeOptions"
                    v-model="selectedTime"
                    :searchable="$root.isDesktop"
                    @input="handleChange"
                ></vue-select>
            </div>
            <div v-if="!isTimeValid" class="error-note">
                <span>Invalid time format</span>
            </div>
        </div>

        <!-- For Time Windows -->
        <div v-if="showTimeWindowOption" class="time-picker-container" :class="!isStartTimeValid ? 'error-box' : ''">
            <div class="hide-cross">
                <span class="custom-label">Start Time</span>
                <md-input v-model="selectedStartTime" type="hidden" />
                <vue-select
                    append-to-body
                    :calculate-position="withPopper"
                    taggable
                    :disabled="!startEndTimeOptions.length || disabled"
                    :options="startEndTimeOptions"
                    v-model="selectedStartTime"
                    :searchable="$root.isDesktop"
                ></vue-select>
            </div>
            <div v-if="!isStartTimeValid" class="error-note">
                <span>{{ invalidText }}</span>
            </div>
        </div>
        <div v-if="showTimeWindowOption" class="time-picker-container" :class="!isEndTimeValid ? 'error-box' : ''">
            <div class="hide-cross" ref="endTime">
                <span class="custom-label">End Time</span>
                <md-input v-model="selectedEndTime" type="hidden" />
                <vue-select
                    append-to-body
                    :calculate-position="withPopper"
                    taggable
                    :disabled="!startEndTimeOptions.length || disabled"
                    :options="startEndTimeOptions"
                    v-model="selectedEndTime"
                    :searchable="$root.isDesktop"
                ></vue-select>
            </div>
            <div v-if="!isEndTimeValid" class="error-note">
                <span>{{ invalidText }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { createPopper } from '@popperjs/core';
import { DATE_TYPES, TIME_WINDOW_CONSTANTS } from '@/utils/constants';

export default {
    name: 'TimePicker',
    props: {
        time: {
            type: String,
            default: () => 'None'
        },
        allTimeOptions: {
            type: Boolean,
            default: true
        },
        additionalTimeOptions: {
            type: Array,
            default: () => []
        },
        clearNone: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        isAppointmentTimeIncluded: {
            type: Boolean,
            default: true
        },
        isUseInFilter: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        enableAutoScroll: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        selectedTime(newValue, oldValue) {
            let timeObj = {};
            let appointmentTime = null;
            let timeWindowStart = null;
            let timeWindowEnd = null;
            this.isTimeValid = true;
            let index = null;
            let shouldEmitData = true;

            this.timeOptionsValues.some((x, key) => {
                if (x.name === newValue) {
                    index = key;
                }
                return x.name === newValue;
            });

            if (index != null) {
                ({ timeWindowStart, timeWindowEnd } = this.timeOptionsValues[index]);

                if (
                    !TIME_WINDOW_CONSTANTS.some((x) => x.name === newValue) ||
                    newValue.toLowerCase() === 'custom time window'
                ) {
                    if (this.isUseInFilter) {
                        if (newValue.toLowerCase() === 'custom time window') {
                            this.$emit('customTimeWindowModal', oldValue);
                            shouldEmitData = false;
                        }
                    } else {
                        this.selectedStartTime = timeWindowStart;
                        this.selectedEndTime = timeWindowEnd;
                        this.showTimeWindowOption = true;
                        this.scrollToBottomField();
                    }
                } else {
                    this.showTimeWindowOption = false;
                }
            } else if (newValue !== 'None' && newValue !== null) {
                if (!moment(newValue, this.supportedFormats, true).isValid()) {
                    this.isTimeValid = false;
                    timeObj = {
                        appointmentTime: null,
                        timeWindowStart: null,
                        timeWindowEnd: null
                    };
                    this.$emit('selectedTime', timeObj, this.isTimeValid);
                    return;
                }
                this.selectedTime = this.$options.filters.timeFormat(newValue, DATE_TYPES.standardTime, true);
                appointmentTime = this.$options.filters.timeFormat(newValue, DATE_TYPES.militaryTime, true);
                this.showTimeWindowOption = false;
            }

            if (shouldEmitData) {
                timeObj = {
                    appointmentTime,
                    timeWindowStart,
                    timeWindowEnd
                };

                this.timeObject = Object.assign({}, timeObj);

                this.$emit('selectedTime', timeObj, this.isTimeValid);
            }
        },
        selectedStartTime(newValue, oldValue) {
            this.isStartTimeValid = true;
            this.timeObject.appointmentTime = null;
            this.timeObject.timeWindowStart = null;

            if (newValue !== null) {
                if (!moment(newValue, this.supportedFormats, true).isValid()) {
                    this.isStartTimeValid = false;
                    return;
                }

                this.isTimeWindowValid();
                this.selectedStartTime = this.$options.filters.timeFormat(newValue, DATE_TYPES.standardTime, true);
                this.timeObject.timeWindowStart = this.$options.filters.timeFormat(
                    newValue,
                    DATE_TYPES.militaryTime,
                    true
                );
            } else {
                this.timeObject.timeWindowStart = null;
                this.isStartTimeValid = false;
            }

            this.$emit('selectedTime', this.timeObject, this.isStartTimeValid);
        },
        selectedEndTime(newValue, oldValue) {
            this.isEndTimeValid = true;
            this.timeObject.timeWindowEnd = null;
            this.timeObject.appointmentTime = null;

            if (newValue !== null) {
                if (!moment(newValue, this.supportedFormats, true).isValid()) {
                    this.isEndTimeValid = false;
                    return;
                }

                this.isTimeWindowValid();
                this.selectedEndTime = this.$options.filters.timeFormat(newValue, DATE_TYPES.standardTime, true);
                this.timeObject.timeWindowEnd = this.$options.filters.timeFormat(
                    newValue,
                    DATE_TYPES.militaryTime,
                    true
                );
            } else {
                this.isEndTimeValid = false;
            }

            this.$emit('selectedTime', this.timeObject, this.isEndTimeValid);
        }
    },
    created() {
        this.selectedTime = this.time;
    },
    mounted() {
        // Should not allow users to choose the none option if time is required.
        if (!this.isRequired) {
            this.timeOptions.push('None');
        }

        if (this.allTimeOptions) {
            TIME_WINDOW_CONSTANTS.forEach((x) => {
                this.timeOptions.push(x.name);
            });
        }

        if (this.additionalTimeOptions.length > 0) {
            this.additionalTimeOptions.forEach((x) => {
                this.timeOptions.push(x.name);
                this.timeOptionsValues.push(x);
            });
        }

        if (this.isAppointmentTimeIncluded) {
            this.generateTimeOptions();
        }
    },
    data() {
        return {
            timeOptions: [],
            selectedTime: null,
            timeFormat: 'h:mm a',
            dateFormat: 'YYYY-MM-DD',
            supportedFormats: ['hh:mm a', 'h:mm a', 'hh:mma', 'h:mma', 'H:mm'],
            isTimeValid: true,
            timeOptionsValues: [...TIME_WINDOW_CONSTANTS],
            placement: 'top',
            selectedStartTime: null,
            selectedEndTime: null,
            startEndTimeOptions: [],
            isStartTimeValid: true,
            isEndTimeValid: true,
            showTimeWindowOption: false,
            invalidText: 'Invalid time format',
            timeObject: {},
            manualSelect: false
        };
    },
    methods: {
        handleChange(){
            this.manualSelect = true;
        },
        generateTimeOptions() {
            if (this.clearNone) {
                this.timeOptions = [];
            }
            let hour = 0;

            for (hour; hour < 24; hour++) {
                const hourOption = this.$options.filters.timeFormat({ hour }, DATE_TYPES.standardTime);
                const minuteOption = this.$options.filters.timeFormat(
                    {
                        hour,
                        minute: 30
                    },
                    DATE_TYPES.standardTime
                );

                this.timeOptions.push(hourOption);
                this.timeOptions.push(minuteOption);
                this.startEndTimeOptions.push(hourOption);
                this.startEndTimeOptions.push(minuteOption);
            }
        },
        isTimeWindowValid() {
            const timeStart = moment(this.selectedStartTime, DATE_TYPES.standardTime);
            const timeEnd = moment(this.selectedEndTime, DATE_TYPES.standardTime);

            if (moment(timeStart).isSameOrBefore(timeEnd)) {
                this.invalidText = 'Invalid time format';
                this.isStartTimeValid = true;
                this.isEndTimeValid = true;
                return;
            }

            this.invalidText = 'Invalid time window';
            this.isStartTimeValid = false;
            this.isEndTimeValid = false;
        },
        withPopper(dropdownList, component, { width }) {
            /**
             * We need to explicitly define the dropdown width since
             * it is usually inherited from the parent with CSS.
             */
            dropdownList.style.width = width;

            /**
             * Here we position the dropdownList relative to the $refs.toggle Element.
             *
             * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
             * the dropdownList overlap by 1 pixel.
             *
             * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
             * wrapper so that we can set some styles for when the dropdown is placed
             * above.
             */
            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: this.placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1]
                        }
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({ state }) {
                            component.$el.classList.toggle('drop-up', state.placement === 'top');
                        }
                    }
                ]
            });

            /**
             * To prevent memory leaks Popper needs to be destroyed.
             * If you return function, it will be called just before dropdown is removed from DOM.
             */
            return () => popper.destroy();
        },
        scrollToBottomField(){
            if (this.enableAutoScroll && this.manualSelect){
                this.$nextTick(() => {
                    this.$refs.endTime.scrollIntoView({ behavior: 'smooth' });
                });

                this.manualSelect = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.main-time-picker-container,
.time-picker-container {
    width: 100%;
}

.error-box > .error-note {
    font-size: 12px;
    color: red;
    display: block;
}
.error-box ::v-deep .vs--searchable .vs__dropdown-toggle {
    border-color: red;
    border-radius: 0;
}

::v-deep .vs__dropdown-menu {
    max-height: 15vh;
    min-height: initial;
}

.hide-cross {
    position: relative;
    padding-top: 17px;
    .custom-label {
        position: absolute;
        top: 0;
    }
}

.hide-cross ::v-deep .vs__actions {
    display: none;
}
.time-picker-container ::v-deep .vs--searchable .vs__dropdown-toggle {
    min-width: 100px;
}
</style>
