<template>
    <drop-down
        :should-close="shouldCloseDropdown"
        @closeDropdown="(val) => (this.shouldCloseDropdown = val)"
        @closeOtherDropDown="closeOtherDropDown"
        :reduce="(item) => item.statusId"
    >
        <span
            class="status"
            slot="title"
            data-toggle="dropdown"
            :class="`inventory-status-${itemStatusValue.toLowerCase().replace(/\s+/g, '')} ${className}`"
        >
            {{ $t('inputs.itemStatuses.' + itemStatusValue) }}
        </span>
        <ul class="dropdown-menu" v-if="!isReadOnlyUser">
            <li v-for="(status, index) in statuses" :key="index">
                <a @click.stop="handleChangeInventoryStatus(status.statusId)">
                    {{ status.statusValue }}
                </a>
            </li>
        </ul>
    </drop-down>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { ITEM_STATUS_CONSTANTS } from '@/utils/constants';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    name: 'InventoryStatusButton',
    mixins: [GeneralMixin],
    props: {
        itemStatus: {
            type: String,
            default: () => ''
        },
        itemId: {
            type: Number,
            default: () => undefined
        },
        inventoryDetails: {
            type: Object,
            default: () => undefined
        },
        className: {
            type: String,
            default: () => ''
        }
    },
    data() {
        return {
            itemStatusValue: this.itemStatus,
            inventoryDetailsValue: this.deepCopyInvDetails,
            statuses: this.getStatuses(ITEM_STATUS_CONSTANTS),
            shouldCloseDropdown: false
        };
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        }),
        deepCopyInvDetails() {
            if (this.inventoryDetails !== null && this.inventoryDetails !== undefined)
                return JSON.parse(JSON.stringify(this.inventoryDetails));
            return this.inventoryDetails;
        }
    },
    methods: {
        getStatuses(arr) {
            const stats = arr.map((x) => ({
                statusId: x,
                statusValue: this.$t(`inputs.itemStatuses.${x}`)
            }));

            return stats;
        },
        async getInventoryDetails(itemId) {
            try {
                const api = `/api/items/${itemId}`;
                const { data } = await handleRequests(api);

                // Sort status histories ascending.
                data.itemStatusHistory.sort((a, b) => {
                    return moment(a.actionDate).diff(moment(b.actionDate));
                });

                if (data.lines && data.lines.length > 0)
                    data.lines.sort((a, b) => {
                        if (a.tripDate === null) 
                            return 1;
                        if (b.tripDate === null) 
                            return -1;
                        return moment(a.tripDate).diff(moment(b.tripDate));
                    });

                return data;
            } catch (error) {
                const message = 'Error in getting the inventory details';
                showErrorMessage(this, message, error);
            }
            return null;
        },
        async handleChangeInventoryStatus(newStatus) {
            this.shouldCloseDropdown = true;

            if (this.inventoryDetailsValue === undefined)
                this.inventoryDetailsValue = await this.getInventoryDetails(this.itemId);

            if (this.inventoryDetailsValue === null || this.inventoryDetailsValue === undefined) 
                return;

            const { itemStatusHistory: statHist, lines: stopLines, orderId } = this.inventoryDetailsValue;

            if (!stopLines || stopLines.length === 0) {
                this.$notify({
                    message: 'Not allowed to update the status of an item that is not attached to a stop/shipment.',
                    type: 'danger'
                });
                return;
            }

            const status = statHist[statHist.length - 1].newStatus;

            if (status !== newStatus) {
                if (newStatus.toLowerCase() === 'returned' && stopLines && stopLines.length > 0) {
                    if (moment(stopLines[stopLines.length - 1].tripDate) > moment()) {
                        this.$notify({
                            message:
                                "It's not allowed to return an item attached to a stop/shipment set to future date.",
                            type: 'danger'
                        });
                        return;
                    }
                }

                const payload = {
                    method: 'post',
                    data: {
                        newStatus,
                        oldStatus: status,
                        location: statHist[statHist.length - 1].location,
                        isManualEntry: false
                    }
                };

                // to toggle the context menu
                // this.$nextTick(() => {
                //    this.shouldCloseDropdown = false;
                // });

                try {
                    const api = `/api/stops/${orderId}/lines/${
                        stopLines[stopLines.length - 1].stopLineId
                    }/change-status`;
                    await handleRequests(api, payload);

                    this.$notify({
                        message: `Inventory status was changed to ${this.$t(`inputs.itemStatuses.${newStatus}`)}!`,
                        type: 'success'
                    });

                    this.$emit('statusUpdated');
                    this.itemStatusValue = newStatus;

                    // Clear the value to force refresh (getItemDetails) on the item next time.
                    this.inventoryDetailsValue = undefined;
                } catch (e) {
                    const message = 'Cannot change inventory status.';
                    showErrorMessage(this, message, e);
                }
            } else {
                const message = 'Cannot change status to the same status';
                this.$notify({
                    message,
                    type: 'danger'
                });
            }
        },
        closeOtherDropDown() {
            const dropdowns = document.querySelectorAll('.open');
            dropdowns.forEach((dropdown) => dropdown.classList.remove('open'));
        }
    }
};
</script>

<style lang="scss" scoped>
.status {
    color: #fff !important;
    min-width: 140px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 140px;
    cursor: pointer;
}

::v-deep .dropdown-menu {
    li {
        cursor: pointer !important;
    }
}
</style>
