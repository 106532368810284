<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Create Asset</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <div class="md-layout create-asset-container">
                <div class="md-layout-item md-size-100">
                    <form-group v-if="showTeamRegion" name="teamRegion" label="Team Region">
                        <team-region-options
                            :selected="assetData.teamRegionId"
                            @selectedOption="handleTeamRegionChanged"
                            :show-auto="false"
                        />
                    </form-group>
                </div>

                <div class="md-layout-item md-size-100">
                    <form-group label="Select asset type">
                        <md-select v-model="selectedAssetType" @md-selected="getAssetType">
                            <md-option v-for="(assetType, index) in assetTypes" :key="index" :value="assetType">
                                {{ assetType }}
                            </md-option>
                        </md-select>
                    </form-group>
                </div>

                <form-wrapper :validator="$v.assetData" class="form-wrapper">
                    <div class="md-layout" v-if="selectedAssetType && selectedAssetType.toLowerCase() !== 'tracker'">
                        <div class="md-layout-item md-size-100">
                            <form-group label="Asset Name*" name="name">
                                <md-input v-model="assetData.name" />
                            </form-group>
                        </div>
                        <div
                            class="md-layout-item md-size-100"
                            v-if="
                                selectedAssetType &&
                                    (selectedAssetType.toLowerCase() === 'vehicle' ||
                                        selectedAssetType.toLowerCase() === 'trailer')
                            "
                        >
                            <form-group label="Vehicle License Plate" name="vehicleLicensePlate">
                                <md-input v-model="assetData.vehicleLicensePlate" />
                            </form-group>
                        </div>
                        <div class="md-layout-item md-size-100" v-if="selectedAssetCustomFieldList !== null">
                            <custom-field-inputs
                                v-for="(customField, index) in selectedAssetCustomFieldList"
                                :key="index"
                                :custom-field-definition="customField"
                                @changed="handleCustomFieldChanged"
                            />
                        </div>
                    </div>
                </form-wrapper>
                <form-wrapper :validator="$v.tracker" class="form-wrapper">
                    <tracker-step
                        v-if="selectedAssetType === 'Tracker'"
                        :data="tracker"
                        :dropdown-list="trackerDropdownList"
                        :is-disabled="isTrackerDisabled"
                        :selected-asset="selectedTracker"
                        :show-dropdown="showDropdown"
                    />
                </form-wrapper>

                <asset-group
                    v-if="showAssetGroup"
                    :team-region-id="assetData.teamRegionId"
                    :asset-group-id="assetData.assetGroupId"
                    @changed="handleAssetGroupChanged"
                />
            </div>
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="create()">Create</md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
        <!-- <form-wrapper :validator="$v.personnel" class="form-wrapper">
                <personnel-step
                    v-if="selectedAssetType === 'Personnel'"
                    v-for="(item, index) in $v.personnel.$each.$iter"
                    :key="index"
                    :data="item.$model"
                    :index="Number(index)"
                    :dropdown-list="personnelDropdownList"
                    :is-disabled="isPersonnelDisabled"
                    :selected-asset="selectedPersonnel"
                    :show-dropdown="showDropdown"
                />
            </form-wrapper>

            <form-wrapper :validator="$v.vehicle" class="form-wrapper">
                <vehicle-step
                    v-if="selectedAssetType === 'Vehicle'"
                    :data="vehicle"
                    :dropdown-list="vehicleDropdownList"
                    :is-disabled="isVehicleDisabled"
                    :selected-asset="selectedVehicle"
                    :show-dropdown="showDropdown"
                />
            </form-wrapper>



            <form-wrapper :validator="$v.assetForm" class="form-wrapper">
                <general-step
                    :data="assetForm"
                    :type="selectedAssetType"
                    v-if="
                        selectedAssetType !== 'Personnel' &&
                            selectedAssetType !== 'Vehicle' &&
                            selectedAssetType !== 'Tracker'
                    "
                />
            </form-wrapper> -->
    </div>
</template>

<script>
import { CustomFieldInputs, TeamRegionOptions } from '@/components';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, numeric, maxLength } from 'vuelidate/lib/validators';
import { TrackerStep } from '@/pages/AssetTracking/StepComponents';
import { handleRequests, showErrorMessage, showTeamRegionControl, isValidIMEI } from '@/helpers';
import { ASSET_TYPE_CONSTANTS } from '@/utils/constants';
import { mapGetters } from 'vuex';
import AssetGroup from './AssetGroup';

export default {
    name: 'CreateAssetContent',
    components: {
        CustomFieldInputs,
        TrackerStep,
        TeamRegionOptions,
        AssetGroup
    },
    mixins: [GeneralMixin],
    props: {
        personnelDropdownList: {
            type: Array,
            default: () => []
        },
        vehicleDropdownList: {
            type: Array,
            default: () => []
        },
        trackerDropdownList: {
            type: Array,
            default: () => []
        },
        assetCustomFieldList: {
            type: Array,
            default: () => []
        },
        activeTab: { type: String, default: '' },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            selectedAssetType: 'Personnel',
            selectedAssetCustomFieldList: [],
            selectedPersonnel: 0,
            selectedVehicle: 0,
            selectedTracker: 0,
            tracker: {
                type: 'Tracker',
                name: null
            },
            isPersonnelDisabled: false,
            isVehicleDisabled: false,
            isTrackerDisabled: false,
            showDropdown: false,
            assetTypes: ASSET_TYPE_CONSTANTS,
            assetData: {
                name: null,
                type: 'Personnel',
                assetGroupId: null,
                teamRegionId: null,
                vehicleLicensePlate: null
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasAssetAccess: 'user/hasAssetAccess',
            teamRegions: 'team/teamRegions',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        }),
        showTeamRegion() {
            return showTeamRegionControl(
                {
                    user: this.user,
                    hasAssetAccess: this.hasAssetAccess,
                    teamRegions: this.teamRegions
                },
                this.assetData.teamRegionId
            );
        },
        showAssetGroup() {
            return this.selectedAssetType && this.selectedAssetType.toLowerCase() !== 'tracker';
        }
    },
    validations: {
        tracker: {
            name: {
                required
            },
            trackerReference: {
                requiredIMEI: required,
                numericIMEI: numeric,
                validIMEI: isValidIMEI
            },
            modelYear: {
                numeric
            }
        },
        assetData: {
            name: {
                required
            },
            vehicleLicensePlate: {
                maxLength: maxLength(50)
            }
        }
    },
    mounted() {
        this.assetData.teamRegionId = this.user.teamRegionId;
        this.setDefaultAssetType();
    },
    methods: {
        getAssetType() {
            this.personnel = [
                {
                    type: 'Personnel',
                    name: null
                }
            ];
            this.vehicle = {
                type: 'Vehicle',
                name: null
            };
            this.tracker = {
                type: 'Tracker',
                name: null
            };

            this.assetData.type = this.selectedAssetType;
            this.assetData.name = null;
            this.assetData.vehicleLicensePlate = null;

            if (!this.selectedAssetType) {
                this.selectedAssetCustomFieldList = [];
            } else {
                this.selectedAssetCustomFieldList = this.assetCustomFieldList.filter((x) => {
                    return (
                        x.assetType.toLowerCase() === this.selectedAssetType.toLowerCase() ||
                        x.assetType.toLowerCase() === 'any'
                    );
                });
            }

            this.$v.$reset();
        },
        handleCustomFieldChanged({ name, value }) {
            this.assetData[name] = value;
        },
        async create() {
            this.$_handleLoaderState(true, 'PROCESSING...');
            let isValid = false;

            if (this.selectedAssetType && this.selectedAssetType.toLowerCase() === 'tracker') {
                this.assetData = Object.assign(
                    {
                        teamRegionId: this.assetData.teamRegionId,
                        assetGroupId: this.assetData.assetGroupId
                    },
                    this.tracker
                );
                this.$v.tracker.$touch();
                isValid = !this.$v.tracker.$invalid;
            } else {
                this.$v.assetData.$touch();
                isValid = !this.$v.assetData.$invalid;
            }

            const data = this.assetData;

            if (isValid) {
                let obj = {};
                let api = '/api/assets';
                if (data.type === 'Tracker') {
                    Object.assign(obj, data);
                    api = '/api/assets/trackers';
                } else {
                    const customFields = {};
                    Object.assign(customFields, data);
                    const { name, type, teamRegionId, assetGroupId, vehicleLicensePlate } = data;

                    // these deletes are necessary so that the customFields won't contain these values anymore.
                    delete customFields.name;
                    delete customFields.type;
                    delete customFields.teamRegionId;
                    delete customFields.assetGroupId;

                    obj = {
                        name,
                        type,
                        teamRegionId,
                        assetGroupId,
                        customFields: JSON.stringify(customFields),
                        vehicleLicensePlate
                    };
                }

                const payload = {
                    method: 'post',
                    data: obj
                };

                try {
                    const res = await handleRequests(api, payload);
                    this.$notify({
                        message: 'Successfully created asset.',
                        type: 'success'
                    });
                    this.personnel = { type: 'Personnel', name: null };
                    this.vehicle = { type: 'Vehicle', name: null };
                    this.tracker = { type: 'Tracker', name: null };
                    this.assetData = { type: this.selectedAssetType, name: null, vehicleLicensePlate: null };

                    this.resolve({
                        type: this.selectedAssetType,
                        assetId: res.data.assetId,
                        teamRegionId: res.data.teamRegionId
                    });
                    // TODO: We need to add a way to clear the customfields after successfully creating the asset.
                    this.$v.$reset();
                } catch (error) {
                    const message = 'Error in creating asset';
                    showErrorMessage(this, message, error);
                }
            }

            this.$_handleLoaderState(false);
        },
        async setDefaultAssetType() {
            if (this.assetTypes && this.assetTypes.length > 0 && this.activeTab) {
                const asset = this.assetTypes.find((x) => x.toLowerCase() === this.activeTab.toLowerCase());
                this.selectedAssetType = asset;
                this.assetData.type = asset;
                if (asset) {
                    this.selectedAssetCustomFieldList = this.assetCustomFieldList.filter(
                        (x) => x.assetType.toLowerCase() === asset.toLowerCase() || x.assetType.toLowerCase() === 'any' // this.selectedAssetType.toLowerCase() ||
                    );
                }
            }
        },
        handleTeamRegionChanged(value) {
            this.assetData.teamRegionId = value;
            this.assetData.assetGroupId = null;
        },
        handleAssetGroupChanged(assetGroup) {
            if (assetGroup) 
                this.assetData.assetGroupId = assetGroup.assetGroupId;
            else 
                this.assetData.assetGroupId = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.asset-action {
    text-align: right;
}

.create-asset-container {
    max-height: 80vh;
    padding-right: 14px !important;
    overflow: auto;
    width: 100%;

    ::v-deep .form-wrapper {
        width: 100%;
    }
}
</style>
