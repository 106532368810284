<template>
    <md-field class="field-select">
        <md-select v-model="selectedOption">
            <md-option v-for="(option, index) in options" :key="index" :value="option.type">
                {{ option.name }}
            </md-option>
        </md-select>
    </md-field>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    name: 'FrequencyIntervalDropdown',
    mixins: [GeneralMixin],
    props: {
        date: {
            type: Date,
            default: () => null
        }
    },
    data() {
        return {
            options: [],
            shouldCloseDropdown: false,
            selectedOption: 'daily',
            selectedInterval: {}
        };
    },
    mounted() {
        this.generateDropdownOptions();
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            isIndividualUser: 'user/isIndividualUser'
        })
    },
    watch: {
        date(newValue) {
            this.generateDropdownOptions(newValue);
        },
        selectedOption(newValue) {
            this.$emit('recurrenceUpdated', newValue);
        }
    },
    methods: {
        generateDropdownOptions(selectedDate = null) {
            this.options = [];
            if (selectedDate != null) {
                const dayOfWeek = moment(selectedDate).format('dddd');
                const yearly = moment(selectedDate).format('MMMM D');
                const dayOfMonth = moment(selectedDate).format('Do');
                this.options.push({ name: 'Daily', type: 'daily', date: selectedDate });
                this.options.push({ name: `Every ${dayOfWeek}`, type: 'weekly', date: selectedDate });
                this.options.push({ name: `Monthly on every ${dayOfMonth}`, type: 'monthly', date: selectedDate });
                this.options.push({ name: `Annually on ${yearly}`, type: 'yearly', date: selectedDate });
                this.options.push({ name: 'Custom', type: 'custom', date: selectedDate });
            } else {
                const today = moment();
                this.options.push({ name: 'Daily', type: 'daily', date: today });
                this.options.push({ name: `Weekly`, type: 'weekly', date: today });
                this.options.push({ name: `Monthly`, type: 'monthly', date: today });
                this.options.push({ name: `Annually`, type: 'yearly', date: today });
                this.options.push({ name: 'Custom', type: 'custom', date: today });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.status {
    color: #aaa !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    padding: 0 10px;
    cursor: pointer;
}

::v-deep .dropdown-menu {
    top: -7px;
    left: 105%;
    li {
        cursor: pointer !important;
    }
}

.custom-dropdown {
    display: inline-block;
}

.field-select {
    display: inline-block;
    width: initial !important;
    max-width: 200px !important;
    margin-left: 10px;
    margin-top: 5px;
}
</style>
