var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "map-footer" }, [
    !_vm.$root.isDesktop
      ? _c(
          "button",
          {
            class: [
              "map-panel-list-button",
              _vm.isActive ? "open-button" : "close-button",
            ],
            attrs: {
              title: _vm.isActive
                ? "Open team member and asset groups panel"
                : "Close team member and asset groups panel",
            },
            on: { click: _vm.handleToggle },
          },
          [
            _vm.isActive
              ? _c("span", [_vm._v("Team Members / Asset Groups")])
              : _vm._e(),
            _vm.isActive
              ? _c("md-icon", { staticClass: "icon-color" }, [
                  _vm._v("chevron_right"),
                ])
              : _c("md-icon", { staticClass: "icon-color" }, [
                  _vm._v("chevron_left"),
                ]),
          ],
          1
        )
      : _vm._e(),
    !_vm.isActive || _vm.$root.isDesktop
      ? _c(
          "div",
          { staticClass: "map-panel" },
          [
            _c(
              "tabs",
              {
                staticClass: "group--tabs",
                attrs: {
                  "tab-name": _vm.tabs,
                  "selected-panel": _vm.activePanel,
                  "is-loading": Boolean(false),
                },
                on: { "switch-panel": _vm.handleSwitchPanel },
              },
              [
                _c("template", { slot: "tab-pane-1" }, [
                  _vm.drivers.length > 5
                    ? _c(
                        "div",
                        { staticClass: "search--container" },
                        [
                          _c(
                            "md-field",
                            {
                              attrs: { "md-clearable": "" },
                              on: { "md-clear": _vm.handleClear },
                            },
                            [
                              _c("md-input", {
                                attrs: { type: "text", placeholder: "Search" },
                                model: {
                                  value: _vm.searchText,
                                  callback: function ($$v) {
                                    _vm.searchText = $$v
                                  },
                                  expression: "searchText",
                                },
                              }),
                              _c("md-icon", [_vm._v("search")]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "ul",
                    _vm._l(_vm.filteredMembers, function (driver) {
                      return _c(
                        "li",
                        {
                          key: driver.email,
                          class: {
                            isPinned: driver.publicUserId === _vm.pinnedUser,
                          },
                          style: {
                            "--color":
                              driver.publicUserId === _vm.pinnedUser
                                ? driver.markerPins.color
                                : "black",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.pinUser(driver)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "name" }, [
                            _vm._v(
                              _vm._s(driver.firstName) +
                                " " +
                                _vm._s(driver.lastName)
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "zoom",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.zoomToDriver(driver)
                                },
                              },
                            },
                            [
                              _c("md-icon", { staticClass: "footer-icon" }, [
                                _vm._v("my_location"),
                              ]),
                            ],
                            1
                          ),
                          _c("tracking-location-data-indicator", {
                            attrs: {
                              direction: "right",
                              data: driver.routeDetails,
                              "render-key": _vm.renderKey,
                            },
                          }),
                          _c(
                            "md-icon",
                            { staticClass: "footer-icon user-pin" },
                            [_vm._v("push_pin")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm.drivers.length === 0
                    ? _c("span", { staticClass: "error-text" }, [
                        _vm._v("No team members found."),
                      ])
                    : _vm.filteredMembers.length === 0
                    ? _c("span", { staticClass: "error-text" }, [
                        _vm._v(
                          "\n                    There is no team member named '" +
                            _vm._s(_vm.searchText) +
                            "'.\n                "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("template", { slot: "tab-pane-2" }, [
                  _vm.assetGroups.length > 5
                    ? _c(
                        "div",
                        { staticClass: "search--container" },
                        [
                          _c(
                            "md-field",
                            {
                              attrs: { "md-clearable": "" },
                              on: { "md-clear": _vm.handleClear },
                            },
                            [
                              _c("md-input", {
                                ref: "searchBar",
                                attrs: { type: "text", placeholder: "Search" },
                                model: {
                                  value: _vm.searchText,
                                  callback: function ($$v) {
                                    _vm.searchText = $$v
                                  },
                                  expression: "searchText",
                                },
                              }),
                              _c("md-icon", [_vm._v("search")]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "ul",
                    _vm._l(_vm.filteredMembers, function (assetGroup) {
                      return _c(
                        "li",
                        {
                          key: assetGroup.assetGroupId,
                          class: {
                            isPinned:
                              assetGroup.trackerReference === _vm.pinnedUser,
                          },
                          style: {
                            "--color":
                              assetGroup.trackerReference === _vm.pinnedUser
                                ? assetGroup.markerPins.color
                                : "black",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.pinUser(assetGroup)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "overflow-tooltip",
                                  rawName: "v-overflow-tooltip",
                                },
                              ],
                              staticClass: "name",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(assetGroup.groupName)),
                              ]),
                              assetGroup.ownerName
                                ? _c("span", { staticClass: "owner-name" }, [
                                    _vm._v(
                                      "\n                                 (" +
                                        _vm._s(assetGroup.ownerName) +
                                        ")\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "zoom",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.zoomToDriver(assetGroup)
                                },
                              },
                            },
                            [
                              _c("md-icon", { staticClass: "footer-icon" }, [
                                _vm._v("my_location"),
                              ]),
                            ],
                            1
                          ),
                          _c("tracking-location-data-indicator", {
                            attrs: {
                              icon: "drive_eta",
                              direction: "right",
                              data: assetGroup.routeDetails,
                              "render-key": _vm.renderKey,
                            },
                          }),
                          _c(
                            "md-icon",
                            { staticClass: "footer-icon user-pin" },
                            [_vm._v("push_pin")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm.assetGroups.length === 0
                    ? _c("span", { staticClass: "error-text" }, [
                        _vm._v("No asset groups found."),
                      ])
                    : _vm.filteredMembers.length === 0
                    ? _c("span", { staticClass: "error-text" }, [
                        _vm._v(
                          "\n                    There is no asset group named '" +
                            _vm._s(_vm.searchText) +
                            "'.\n                "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }