var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("md-icon", { staticClass: "spin-icon" }, [_vm._v("autorenew")]),
      _c("div", { staticClass: "spin-text" }, [
        _vm._v(_vm._s(_vm.loadingText)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }