<template>
    <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.driverInstructions') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>checklist</md-icon>
                    </div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                            <md-checkbox v-model="model.hasAuthorityToLeave">Has authority to leave</md-checkbox>
                        </div>
                        <div class="md-layout-item md-size-100">
                            <md-checkbox v-model="model.mustCheckRecipientId">Must check recipient Id</md-checkbox>
                        </div>
                        <div class="md-layout-item md-size-100">
                            <md-checkbox v-model="model.mustWearHighVisibilityVest">
                                Must wear high visibility vest
                            </md-checkbox>
                        </div>
                        <div class="md-layout-item md-size-100">
                            <md-checkbox v-model="model.isContactlessDelivery">Is contactless delivery</md-checkbox>
                        </div>
                        <div class="md-layout-item md-size-100">
                            <md-field maxlength="5">
                                <label>Instructions to driver</label>
                                <md-textarea v-model="model.instructions" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
                <md-card-actions md-alignment="left">
                    <button class="custom-btn" @click.stop="saveSettings">Save Settings</button>
                </md-card-actions>
            </md-card>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'DriverInstructions',
    mixins: [GeneralMixin],
    data() {
        return {
            model: {
                hasAuthorityToLeave: false,
                mustCheckRecipientId: false,
                mustWearHighVisibilityVest: false,
                isContactlessDelivery: false,
                instructions: null
            }
        };
    },
    watch: {
        'model.hasAuthorityToLeave': function(newVal) {
            if (newVal && this.model.mustCheckRecipientId) 
                this.model.mustCheckRecipientId = false;
        },
        'model.mustCheckRecipientId': function(newVal) {
            if (newVal && this.model.hasAuthorityToLeave) 
                this.model.hasAuthorityToLeave = false;
        }
    },
    methods: {
        async getTeamDetails() {
            this.$_handleLoaderState(true, '');
            try {
                const api = '/api/teams';
                const { data } = await handleRequests(api);
                this.model = { ...this.model, ...data.data.defaultDriverInstructionsConfiguration };
            } catch (e) {
                const message = 'Unable to fetch team settings.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async saveSettings() {
            // only driver instructions
            const payload = {
                method: 'post',
                data: this.model
            };

            const api = '/api/teams/settings/driver-instructions';
            try {
                this.$_handleLoaderState(true, 'Updating settings');
                await handleRequests(api, payload);

                this.$notify({
                    message: 'Settings updated!',
                    type: 'success'
                });
            } catch (e) {
                const message = 'Cannot update Driver Instructions settings.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        }
    },
    async mounted() {
        await this.getTeamDetails();
    }
};
</script>

<style lang="scss" scoped>
.card-icon {
    background: #43a047 !important;
}
</style>
