<script>
import { ref, toRefs, onMounted, watch, inject, computed, onBeforeUnmount } from '@vue/composition-api';
import useMapUtils from '@/compostables/useMapUtils';

export default {
    name: 'AssetGroupMarker',
    props: {
        assetGroup: {
            type: Object,
            default: () => {}
        },
        isDateToday: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { root }) {
        const map = inject('map');
        const google = inject('google');
        const bounds = inject('bounds');
        const { getters, dispatch } = inject('vuex-store');

        const { isDateToday, assetGroup } = toRefs(props);

        const pinnedUser = computed(() => getters['map/pinnedUser']);
        const isPlaying = computed(() => getters['map/isPlaying']);
        const canOverrideLocation = computed(() => getters['user/canOverrideLocation']);

        const { createDriverMarker, createLatLngPoint } = useMapUtils(root);
        const marker = ref(null);

        if (!assetGroup.value.routeDetails || assetGroup.value.routeDetails.coordinates === null) 
            return marker;

        const showMarker = () => {
            const {
                coordinates: { latitude, longitude }
            } = assetGroup.value.routeDetails;

            let className = 'image asset';
            if (assetGroup.value.ownerPhotoUrl) {
                className = 'image';
            }
            marker.value = createDriverMarker(google.value, {
                latlng: createLatLngPoint(google.value, latitude, longitude),
                map: map.value,
                html: `<div style="--driver-color:${
                    assetGroup.value.markerPins.color
                };" class="asset-map-image-marker"><div class="${className}" style="background-image: url(${assetGroup
                    .value.ownerPhotoUrl || assetGroup.value.markerPins.driver})"></div></div>`,
                draggable: canOverrideLocation.value
            });

            marker.value.addListener('click', () => {
                root.$emit('marker-clicked', { data: assetGroup.value, marker: marker.value, type: 'assetGroup' });
            });

            // update location.
            const onDrop = () => {
                dispatch('map/SET_DRIVER_LOCATION', {
                    publicUserId: null,
                    trackerId: assetGroup.value.trackerId,
                    location: {
                        latitude: marker.value.getPosition().lat(),
                        longitude: marker.value.getPosition().lng()
                    }
                });
            };
            if (marker.value.draggable) {
                google.value.maps.event.addListener(map.value, 'mouseover', () => {
                    const mapImageMarker = marker.value?.div?.getElementsByClassName('asset-map-image-marker');
                    if (mapImageMarker) {
                        const markerDom = mapImageMarker[0];
                        if (markerDom.getAttribute('markerDropListener') !== 'true') {
                            markerDom.setAttribute('markerDropListener', 'true');
                            markerDom.addEventListener('click', () => {
                                onDrop();
                            });
                        }
                    }
                });
            }

            bounds.value.extend(marker.value.getPosition());
            map.value.fitBounds(bounds.value);

            if (!isDateToday.value) 
                marker.value.setMap(null);

            if (pinnedUser.value && pinnedUser.value !== assetGroup.value.trackerReference) 
                marker.value.setMap(null);
        };

        watch(pinnedUser, (newVal) => {
            if (!newVal && marker?.value && isDateToday.value) {
                marker.value.setMap(map.value);
                bounds.value.extend(marker.value.getPosition());
                map.value.fitBounds(bounds.value);
            } else if (marker?.value) {
                if (newVal === assetGroup.value.trackerReference && isDateToday.value) {
                    marker.value.setMap(map.value);
                    bounds.value.extend(marker.value.getPosition());
                    map.value.fitBounds(bounds.value);
                } else 
                    marker.value.setMap(null);
            }
        });

        watch(isDateToday, (newVal) => {
            if (
                newVal &&
                (!pinnedUser.value || (pinnedUser.value && assetGroup.trackerReference === pinnedUser.value))
            ) {
                marker.value.setMap(map.value);
                bounds.value.extend(marker.value.getPosition());
                map.value.fitBounds(bounds.value);
            } else 
                marker.value.setMap(null);
        });

        watch(isPlaying, (newVal) => {
            if (
                !newVal &&
                isDateToday.value &&
                (!pinnedUser.value || assetGroup.value.trackerReference === pinnedUser.value)
            ) {
                marker.value.setMap(map.value);
                bounds.value.extend(marker.value.getPosition());
                map.value.fitBounds(bounds.value);
            } else {
                marker.value.setMap(null);
            }
        });

        watch(assetGroup, (newVal) => {
            if (!isDateToday.value) 
                return true;
            const {
                routeDetails: {
                    coordinates: { latitude, longitude }
                }
            } = newVal;
            const pos = createLatLngPoint(google.value, latitude, longitude);

            if (!pinnedUser.value || pinnedUser.value === newVal.trackerReference) 
                marker.value.setPosition(pos);
            return true;
        });

        onMounted(showMarker);

        // remove marker before unmount
        onBeforeUnmount(() => {
            marker.value.setMap(null);
            marker.value = null;
        });

        return {
            marker
        };
    },
    render: () => null
};
</script>
