<template>
    <div>
        <div v-if="isStartRouting" class="toggled-options map-panel">
            <div class="routing-panel">
                <button @click="isCollapsed = !isCollapsed" class="btn-summary">
                    <div class="label-header">{{ btnTxt }} Routing Summary</div>
                    <div class="label-timer">{{ displayRunningTime }}</div>
                </button>

                <div class="md-layout panel-layout" v-if="!isCollapsed">
                    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                        <md-card class="routing-card">
                            <md-card-content>
                                <div>
                                    <md-table class="table-route-panel">
                                        <md-table-row>
                                            <md-table-cell>
                                                {{ totalRoutedStops }} of {{ totalStops }} stops have been routed.
                                            </md-table-cell>
                                        </md-table-row>
                                        <md-table-row>
                                            <md-table-cell>
                                                {{ countRouteChanged }} routes have changed since the last update.
                                            </md-table-cell>
                                        </md-table-row>
                                        <md-table-row>
                                            <md-table-cell>
                                                <span v-if="!isRouteLoading">
                                                    {{ nextRoutingTime }} seconds until the next routing update
                                                </span>
                                                <span v-else>
                                                    <div class="btn-loader route-loader"></div>
                                                    <span class="route-loader-text">Loading...</span>
                                                </span>
                                            </md-table-cell>
                                        </md-table-row>
                                        <md-table-row v-if="routeLastChangedTime">
                                            <md-table-cell>
                                                The routes have not changed in {{ routeLastChangedTime }}.
                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                </div>
                            </md-card-content>
                        </md-card>
                    </div>
                </div>
                <div class="btn-center">
                    <button v-if="!isRouteLoading" class="custom-btn" @click="handleStopRouting">Stop Routing</button>
                    <div v-else :class="['btn-loader', isCollapsed ? 'loader' : '']"></div>
                </div>
            </div>
        </div>
        <div v-if="!isStartRouting" class="toggled-options map-panel">
            <div class="map-panel--routing_options">
                <button v-show="step === 0" @click="handleUseNewOptimiseStops">
                    <md-icon>announcement</md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Try the New Optimise Stops</md-tooltip>
                </button>
                <load-modal-button v-show="step === 0" :trip-date="tripDate" @handleLoadModel="handleLoadModel" />
                <button @click="handleClickSetting">
                    <md-icon>settings</md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Routing Options</md-tooltip>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import RoutingSettingsModal from '@/pages/BatchStop/Settings/RoutingSettingsModal';
import LoadModalButton from '@/pages/BatchStop/Settings/LoadModalButton';
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'RoutingSettingsComponent',
    components: { LoadModalButton },
    props: {
        startLocationMode: {
            type: String,
            default: null
        },
        isStartRouting: {
            type: Boolean
        },
        isRouteLoading: {
            type: Boolean
        },
        routeLastChangedTime: {
            type: String,
            default: null
        },
        countRouteChanged: {
            type: Number,
            default: 0
        },
        totalRoutedStops: {
            type: Number,
            default: 0
        },
        totalStops: {
            type: Number,
            default: 0
        },
        isNextRouting: {
            type: Boolean
        },
        isDistributeWorkload: {
            type: Boolean
        },
        tripDate: {
            type: String,
            default: null
        },
        step: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            summaryStartTime: null,
            displayRunningTime: null,
            timeInterval: null,
            isCollapsed: false,
            nextRoutingTime: 15,
            isRunning: false,
            interval: null
        };
    },
    methods: {
        ...mapMutations({
            saveUseNewOptimiseStops: 'optimiseSetting/UPDATE_USE_NEW_OPTIMISE_STOPS'
        }),
        handleClickSetting() {
            this.$modal
                .show(RoutingSettingsModal, {
                    startLocationMode: this.startLocationMode,
                    isDistributeWorkload: this.isDistributeWorkload
                })
                .then((response) => {
                    this.$emit('handleRoutingSettings', response);
                });
        },
        handleStopRouting() {
            this.$emit('onStopRouting');
        },
        startTimer() {
            if (this.isRunning) {
                clearInterval(this.interval);
            } else {
                this.interval = setInterval(this.countDown, 1000);
            }
            this.isRunning = !this.isRunning;
        },
        countDown() {
            this.nextRoutingTime -= 1;
            if (this.nextRoutingTime === 0) {
                clearInterval(this.interval);
                this.isRunning = true;
            }
        },
        routeRunningTime() {
            const currentTime = moment();
            const duration = moment.duration(currentTime.diff(this.summaryStartTime)).asMilliseconds();
            this.displayRunningTime = moment.utc(duration).format('HH:mm:ss');
        },
        handleLoadModel(response) {
            this.$emit('handleLoadModel', response);
        },
        handleUseNewOptimiseStops() {
            this.saveUseNewOptimiseStops(true);
            this.$router.push({
                path: `/optimise-stops`
            });
        }
    },
    computed: {
        btnTxt() {
            return this.isCollapsed ? '🡅 ' : '🡇 ';
        },
        ...mapGetters({
            getUseNewOptimiseStops: 'optimiseSetting/getUseNewOptimiseStops'
        })
    },
    watch: {
        isNextRouting(value) {
            if (value) {
                this.nextRoutingTime = 15;
                this.isRunning = false;
                this.startTimer();
            } else {
                clearInterval(this.interval);
            }
        },
        isStartRouting(value) {
            this.summaryStartTime = null;
            if (value) {
                this.summaryStartTime = moment();
                this.routeRunningTime();
                this.timeInterval = setInterval(this.routeRunningTime, 1000);
            } else {
                clearInterval(this.timeInterval);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.md-tooltip-top {
    &.toggle-tooltip {
        // offset for pooper.js
        margin-left: 20px !important;
    }
}
.toggled-options {
    right: 200px;
    position: absolute;
    top: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    height: auto;
    display: flex;

    button {
        padding: 10px;
        border: none;
        background: transparent;
        cursor: pointer;
        margin: 0 auto;
    }
    .routing-panel {
        position: relative;
        min-width: 310px;
        max-width: 310px;
        .btn-summary {
            width: 98%;
            .label-header {
                float: left;
            }
            .label-timer {
                float: right;
                padding-top: 2.5px;
                color: #343434;
            }
        }
    }

    .custom-btn {
        background-color: #2b93ff !important;
        line-height: 25px;
    }
    .btn-center {
        text-align: center;
        padding: 10px;
        height: 65px;
    }
}
.panel-layout {
    max-height: 160px;
}
.route-loader {
    top: 20% !important;
    left: 0 !important;
}
.route-loader-text {
    left: 37px;
    position: absolute;
    top: 13px;
}
.btn-loader {
    border: 4px solid #eeeeee;
    border-radius: 50%;
    border-top: 4px solid #2b93ff;
    width: 24px;
    height: 24px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    top: 85%;
    left: 48%;
    position: absolute;
}
.loader {
    top: 65% !important;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.routing-card,
.md-content.md-table.table-route-panel.md-theme-default,
.table-route-panel ::v-deep div {
    background-color: transparent !important;
}
.routing-card {
    margin: 0px;
    .md-card-header {
        padding: 0px;
        padding-top: 8px;
    }
    .md-card-content {
        padding: 0px;
    }
}

.table-route-panel {
    .md-table-cell {
        height: 40px;
        padding: 5px 0px;
    }
}
.map-panel--routing_options {
    position: relative;
    display: flex;
}
</style>
