<template>
    <md-content class="info-detail--container" v-if="content.stopRef === undefined || content.stopRef === null">
        <md-content class="info-detail route-optimization">
            <span class="info-label">Address</span>
            <span>{{ content.address }}</span>
        </md-content>
    </md-content>
    <md-content v-else class="optimised-stop-container">
        <div class="md-layout optimised-stop-ref-block">
            <div
                class="md-layout-item"
                v-if="
                    !(
                        (content.sourceReference === undefined || content.sourceReference === null) &&
                        (content.stopRef === undefined || content.stopRef === null)
                    )
                "
            >
                <span @click="showDetailsPage">
                    {{ content.sourceReference || content.stopRef }}
                </span>
                <md-button
                    title="stop details"
                    class="info--buttons md-white md-button md-just-icon md-round"
                    :md-ripple="false"
                    @click="showDetailsPage"
                >
                    <md-icon id="optimised-stop-ref-visibility-icon">
                        visibility
                    </md-icon>
                </md-button>
            </div>
        </div>
        <div class="md-layout optimised-stop-address-block">
            <div class="md-layout-item">
                <span>{{ content.address }}</span>
            </div>
        </div>
        <info-window-more-info-content
            :content="content"
            :show-details-page="showDetailsPage"
            :get-capacity-info="getCapacityInfo"
            :has-capacity-settings="hasCapacitySettings"
            :get-skills-info="getSkillsInfo"
            :has-skills-settings="hasSkillsSettings"
            :get-service-info="getServiceInfo"
            :has-service-settings="hasServiceSettings"
        />
    </md-content>
</template>

<script>
import { GeneralMixin } from '@/mixins';
import InfoWindowMoreInfoContent from './InfoWindowMoreInfoContent';

export default {
    name: 'InfoWindowOptimisedStopContent',
    components: {
        InfoWindowMoreInfoContent
    },
    mixins: [GeneralMixin],
    props: {
        content: {
            type: Object,
            default: () => {}
        },
        showDetailsPage: {
            type: Function,
            default: () => {}
        },
        getCapacityInfo: {
            type: Function,
            default: () => {}
        },
        hasCapacitySettings: {
            type: Function,
            default: () => {}
        },
        getSkillsInfo: {
            type: Function,
            default: () => {}
        },
        hasSkillsSettings: {
            type: Function,
            default: () => {}
        },
        getServiceInfo: {
            type: Function,
            default: () => {}
        },
        hasServiceSettings: {
            type: Function,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';
.md-content {
    font-weight: bold;
}

.optimised-stop-ref-block {
    span {
        color: #2b93ff;
        cursor: pointer;
    }
    button {
        height: 16px;
        margin: 0 !important;
        top: -1px;
    }
}
.optimised-stop-address-block {
    span {
        font-weight: 700;
    }
}

#optimised-stop-ref-visibility-icon {
    color: #2b93ff !important;
}
</style>
