var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("customer-invoices-overview-toolbar", {
        class: _vm.$root.isTablet ? "tablet-filter-container" : "",
        attrs: { "batch-filter-list": _vm.batchFilterList },
        on: {
          onFilterInvoices: _vm.handleFilterOrSearch,
          onExportClicked: _vm.handleExportClick,
          onSendToAccountingPackageClicked: _vm.processInvoicesBasedOnFilter,
          onCreateInvoice: _vm.handleGenerateInvoice,
        },
      }),
      _c(
        "div",
        { staticClass: "md-layout" },
        [
          _c(
            "div",
            { staticClass: "md-layout-item" },
            [
              _c(
                "md-card",
                [
                  _c(
                    "md-card-header",
                    {
                      staticClass: "md-card-header-icon md-card-header-warning",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card-icon" },
                        [_c("md-icon", [_vm._v("receipt")])],
                        1
                      ),
                    ]
                  ),
                  _c("md-card-content", { staticClass: "body-list" }, [
                    _vm.selectedInvoices.length &&
                    (!_vm.showProgressBox || _vm.isProgressComplete)
                      ? _c(
                          "div",
                          {
                            class: [
                              "bulk-section",
                              _vm.topScrollPosition < 0 ? "sticky" : "",
                            ],
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.selectedInvoices.length) +
                                  " invoice" +
                                  _vm._s(
                                    _vm.selectedInvoices.length > 1 ? "s" : ""
                                  ) +
                                  "\n                            selected.\n                        "
                              ),
                            ]),
                            _c(
                              "div",
                              [
                                _c("payment-status-button", {
                                  attrs: {
                                    "is-bulk": true,
                                    "bulk-customer-invoice-ids":
                                      _vm.selectedInvoices,
                                  },
                                  on: {
                                    invoiceBulkStatusUpdate:
                                      _vm.handlePageUpdate,
                                  },
                                }),
                                _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-round md-just-icon md-warning",
                                    attrs: {
                                      title: "Sync to Accounting Package",
                                    },
                                    on: {
                                      click:
                                        _vm.handleSendToAccountingPackageClick,
                                    },
                                  },
                                  [_c("md-icon", [_vm._v("sync")])],
                                  1
                                ),
                                _c("send-email-button", {
                                  attrs: {
                                    "is-bulk": true,
                                    "bulk-customer-invoice-ids":
                                      _vm.selectedInvoices,
                                  },
                                  on: {
                                    handleBulkSent: _vm.handleBulkSentEmail,
                                  },
                                }),
                                _c("delete-invoice-button", {
                                  attrs: {
                                    "is-bulk": true,
                                    "bulk-customer-invoice-ids":
                                      _vm.selectedInvoices,
                                  },
                                  on: { invoiceBulkDelete: _vm.bulkDelete },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    !_vm.isListLoading
                      ? _c(
                          "div",
                          [
                            _c(
                              "md-table",
                              {
                                staticClass:
                                  "context-menu-support custom-paginated-table",
                              },
                              [
                                _c(
                                  "md-table-row",
                                  [
                                    !_vm.isReadOnlyUser
                                      ? _c(
                                          "md-table-head",
                                          [
                                            _c("md-checkbox", {
                                              staticClass:
                                                "invoice-checkbox checkbox-head",
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onSelectAll(
                                                    _vm.isSelectAll
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.isSelectAll,
                                                callback: function ($$v) {
                                                  _vm.isSelectAll = $$v
                                                },
                                                expression: "isSelectAll",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("md-table-head", [
                                      _vm._v("Invoice Number"),
                                    ]),
                                    _c("md-table-head", [_vm._v("Customer")]),
                                    _c("md-table-head", [
                                      _vm._v("Invoice Date"),
                                    ]),
                                    _c("md-table-head", [_vm._v("Subtotal")]),
                                    _c("md-table-head", [_vm._v("Tax")]),
                                    _c("md-table-head", [
                                      _vm._v("Total Amount"),
                                    ]),
                                    _c("md-table-head", [_vm._v("Status")]),
                                    _vm.$root.isDesktop && !_vm.isReadOnlyUser
                                      ? _c("md-table-head", [_vm._v("Actions")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._l(_vm.invoiceList, function (invoice) {
                                  return _c(
                                    "md-table-row",
                                    {
                                      key: invoice.customerInvoiceId,
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.showSidebar(
                                            invoice.customerInvoiceId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      !_vm.isReadOnlyUser
                                        ? _c(
                                            "md-table-cell",
                                            [
                                              _c("md-checkbox", {
                                                staticClass: "invoice-checkbox",
                                                attrs: {
                                                  value:
                                                    invoice.customerInvoiceId,
                                                },
                                                model: {
                                                  value: _vm.selectedInvoices,
                                                  callback: function ($$v) {
                                                    _vm.selectedInvoices = $$v
                                                  },
                                                  expression:
                                                    "selectedInvoices",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "md-table-cell",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "invoice-ref",
                                              attrs: {
                                                to: {
                                                  name: "Customer Invoice Details",
                                                  params: {
                                                    invoiceId:
                                                      invoice.customerInvoiceId,
                                                  },
                                                },
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    invoice.invoiceNumber
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(invoice.customer) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                invoice.invoiceDate,
                                                _vm.DATE_TYPES.standardDate
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              invoice.subTotal,
                                              invoice.currency
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              invoice.totalTax,
                                              invoice.currency
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              invoice.total,
                                              invoice.currency
                                            )
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "md-table-cell",
                                        [
                                          _c("payment-status-button", {
                                            attrs: {
                                              "customer-invoice-id":
                                                invoice.customerInvoiceId,
                                              "invoice-details": invoice,
                                            },
                                            on: {
                                              statusUpdated:
                                                _vm.onChangedInvoiceStatus,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.$root.isDesktop
                                        ? _c(
                                            "md-table-cell",
                                            { staticClass: "action-buttons" },
                                            [
                                              !_vm.isReadOnlyUser
                                                ? _c("sync-invoice-button", {
                                                    attrs: { invoice: invoice },
                                                  })
                                                : _vm._e(),
                                              _c("generate-pdf-button", {
                                                attrs: {
                                                  "customer-invoice-id":
                                                    invoice.customerInvoiceId,
                                                },
                                              }),
                                              _vm.showInvoiceDataExcelLink
                                                ? _c("generate-excel-button", {
                                                    attrs: {
                                                      "customer-invoice-id":
                                                        invoice.customerInvoiceId,
                                                    },
                                                  })
                                                : _vm._e(),
                                              !_vm.isReadOnlyUser
                                                ? _c("send-email-button", {
                                                    attrs: {
                                                      "customer-invoice-id":
                                                        invoice.customerInvoiceId,
                                                      "email-sent-date":
                                                        invoice.lastEmailDateSent,
                                                    },
                                                    on: {
                                                      invoiceSent:
                                                        _vm.handleSentEmail,
                                                    },
                                                  })
                                                : _vm._e(),
                                              !_vm.isReadOnlyUser
                                                ? _c("delete-invoice-button", {
                                                    attrs: {
                                                      "customer-invoice-id":
                                                        invoice.customerInvoiceId,
                                                      status: invoice.status,
                                                    },
                                                    on: {
                                                      invoiceDeleted:
                                                        _vm.handlePageUpdate,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                            _vm.invoiceList.length === 0
                              ? _c("div", [
                                  _c(
                                    "p",
                                    { staticClass: "no-result-message" },
                                    [
                                      _vm._v(
                                        "No results matching your search/filter could be found."
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c("div", [
                          _c(
                            "div",
                            { staticClass: "loader" },
                            [
                              _c("fade-loader", {
                                attrs: { loading: true, color: "#333333" },
                              }),
                              _c("span", [_vm._v("LOADING")]),
                            ],
                            1
                          ),
                        ]),
                  ]),
                ],
                1
              ),
              _c(
                "md-card-actions",
                {
                  staticClass: "page-footer",
                  attrs: { "md-alignment": "space-between" },
                },
                [
                  _c("div", [
                    _vm.total === _vm.pagination.perPage
                      ? _c("p", { staticClass: "card-category" }, [
                          _vm._v(
                            "\n                        Page " +
                              _vm._s(_vm.pagination.currentPage) +
                              " of many\n                    "
                          ),
                        ])
                      : _c("p", { staticClass: "card-category" }, [
                          _vm._v(
                            "Page " +
                              _vm._s(_vm.pagination.currentPage) +
                              " of " +
                              _vm._s(_vm.maxPage)
                          ),
                        ]),
                  ]),
                  _c("pagination", {
                    staticClass: "pagination-no-border pagination-success",
                    attrs: {
                      "per-page": _vm.pagination.perPage,
                      total: _vm.total,
                    },
                    on: {
                      "change-page": function ($event) {
                        return _vm.handleChangePage(
                          $event,
                          _vm.pagination.perPage
                        )
                      },
                    },
                    model: {
                      value: _vm.pagination.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.pagination, "currentPage", $$v)
                      },
                      expression: "pagination.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "slide" } },
            [
              _vm.isShowSidebar
                ? _c("customer-invoices-sidebar", {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.toggleSidebarWindow,
                        expression: "toggleSidebarWindow",
                      },
                    ],
                    attrs: { "customer-invoice-id": _vm.customerInvoiceId },
                    on: {
                      closeModal: _vm.toggleSidebarWindow,
                      invoiceUpdated: function (p) {
                        return _vm.invoiceUpdated(_vm.customerInvoiceId, p)
                      },
                      invoiceDeleted: _vm.handleDeletedInvoice,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showProgressBox
        ? _c("div", { staticClass: "progress-container" }, [
            _c(
              "div",
              { staticClass: "button-close" },
              [
                _c(
                  "md-button",
                  {
                    staticClass: "md-default md-just-icon md-round",
                    on: { click: _vm.handleCloseProgressBox },
                  },
                  [_c("md-icon", [_vm._v("close")])],
                  1
                ),
              ],
              1
            ),
            !_vm.isProgressComplete
              ? _c("div", { staticClass: "progress-text-section" }, [
                  _vm._m(0),
                  _vm.totalInvoices == 0
                    ? _c("div", [_vm._v("Preparing invoices...")])
                    : _c("div", [
                        _vm._v(
                          _vm._s(_vm.currentProcessedInvoices) +
                            " of " +
                            _vm._s(_vm.totalInvoices) +
                            " invoices have been generated."
                        ),
                      ]),
                ])
              : _c("div", { staticClass: "progress-text-section" }, [
                  _c(
                    "div",
                    [
                      _c("md-icon", { staticClass: "green-check" }, [
                        _vm._v("check_circle"),
                      ]),
                    ],
                    1
                  ),
                  _c("div", [
                    _vm._v(
                      "\n                Process has been completed.\n            "
                    ),
                  ]),
                ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("div", { staticClass: "btn-loader route-loader" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }