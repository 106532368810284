var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "md-layout conditions-container" }, [
      _c("div", { staticClass: "md-layout-item conditions-title-container" }, [
        _vm._v("\n            Conditions\n        "),
      ]),
      _c(
        "div",
        { staticClass: "md-layout-item conditions-button-container" },
        [
          _c(
            "md-button",
            {
              staticClass: "md-simple md-just-icon md-round",
              attrs: { title: "Add condition" },
              on: { click: _vm.handleAddCondition },
            },
            [_c("md-icon", [_vm._v("add")])],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "conditions-list" },
      _vm._l(_vm.conditions, function (condition, conditionIndex) {
        return _c(
          "div",
          { key: conditionIndex, staticClass: "condition-item" },
          [
            _c("div", { staticClass: "condition-content-container" }, [
              _c(
                "div",
                { staticClass: "condition-header" },
                [
                  _c(
                    "form-group",
                    {
                      staticClass: "group-filter",
                      attrs: { label: "Condition" },
                    },
                    [
                      _c(
                        "md-select",
                        {
                          on: {
                            "md-selected": function ($event) {
                              return _vm.handleConditionTypeSelected(condition)
                            },
                          },
                          model: {
                            value: condition.type,
                            callback: function ($$v) {
                              _vm.$set(condition, "type", $$v)
                            },
                            expression: "condition.type",
                          },
                        },
                        _vm._l(_vm.conditionsOptions, function (option, index) {
                          return _c(
                            "md-option",
                            { key: index, attrs: { value: option.value } },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(option.name) +
                                  "\n                            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "condition-content" }, [
                condition.type === "teamRegions"
                  ? _c(
                      "div",
                      { staticClass: "multiple-field-container" },
                      [
                        _c("multiselect", {
                          attrs: {
                            options: condition.teamRegionsOptions,
                            multiple: true,
                            "close-on-select": true,
                            placeholder: "Pick team regions",
                            label: "name",
                            "track-by": "teamRegionId",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "option",
                                fn: function (ref) {
                                  var option = ref.option
                                  return [
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          marginLeft:
                                            _vm.getIndentation(option),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              !option.$isDisabled
                                                ? option.name
                                                : option.selectedLabel
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: condition.data,
                            callback: function ($$v) {
                              _vm.$set(condition, "data", $$v)
                            },
                            expression: "condition.data",
                          },
                        }),
                        _c(
                          "div",
                          [
                            _c(
                              "md-tooltip",
                              {
                                staticClass: "team-region-popup",
                                attrs: {
                                  "md-direction": "right",
                                  "md-active":
                                    condition.teamRegionPopup
                                      .teamRegionPopUpActive,
                                },
                                on: {
                                  "update:mdActive": function ($event) {
                                    return _vm.$set(
                                      condition.teamRegionPopup,
                                      "teamRegionPopUpActive",
                                      $event
                                    )
                                  },
                                  "update:md-active": function ($event) {
                                    return _vm.$set(
                                      condition.teamRegionPopup,
                                      "teamRegionPopUpActive",
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      condition.teamRegionPopup
                                        .teamRegionPopUpMessage
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : condition.type === "shipmentContents"
                  ? _c(
                      "div",
                      { staticClass: "multiple-field-container" },
                      [
                        _c("multiselect", {
                          attrs: {
                            options: _vm.shipmentContentsOptions,
                            multiple: true,
                            "close-on-select": true,
                            placeholder: "Pick shipment contents",
                            "group-values": "values",
                            "group-label": "label",
                            "group-select": true,
                            "track-by": "name",
                            label: "name",
                          },
                          model: {
                            value: condition.data,
                            callback: function ($$v) {
                              _vm.$set(condition, "data", $$v)
                            },
                            expression: "condition.data",
                          },
                        }),
                      ],
                      1
                    )
                  : condition.type === "customFields"
                  ? _c("div", { staticClass: "custom-fields" }, [
                      _c(
                        "div",
                        { staticClass: "custom-field-item" },
                        [
                          _c(
                            "form-group",
                            {
                              staticClass: "group-filter",
                              attrs: { label: "Name" },
                            },
                            [
                              _c(
                                "md-select",
                                {
                                  model: {
                                    value: condition.data.name,
                                    callback: function ($$v) {
                                      _vm.$set(condition.data, "name", $$v)
                                    },
                                    expression: "condition.data.name",
                                  },
                                },
                                [
                                  _c(
                                    "md-optgroup",
                                    {
                                      staticClass: "custom-field-option",
                                      attrs: { label: "Stop Custom Fields" },
                                    },
                                    _vm._l(
                                      _vm.stopCustomFieldsOptions,
                                      function (stopCustomField, index) {
                                        return _c(
                                          "md-option",
                                          {
                                            key: index,
                                            attrs: {
                                              value: stopCustomField.name,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(stopCustomField.label) +
                                                "\n                                        "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                  _c(
                                    "md-optgroup",
                                    {
                                      staticClass: "custom-field-option",
                                      attrs: {
                                        label: "Shipment Custom Fields",
                                      },
                                    },
                                    _vm._l(
                                      _vm.shipmentCustomFieldsOptions,
                                      function (shipmentCustomField, index) {
                                        return _c(
                                          "md-option",
                                          {
                                            key: index,
                                            attrs: {
                                              value: shipmentCustomField.name,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  shipmentCustomField.label
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "custom-field-item" },
                        [
                          _c(
                            "form-group",
                            {
                              staticClass: "group-filter",
                              attrs: { label: "Operator" },
                            },
                            [
                              _c(
                                "md-select",
                                {
                                  model: {
                                    value: condition.data.operator,
                                    callback: function ($$v) {
                                      _vm.$set(condition.data, "operator", $$v)
                                    },
                                    expression: "condition.data.operator",
                                  },
                                },
                                _vm._l(
                                  _vm.operatorOptions,
                                  function (operator, index) {
                                    return _c(
                                      "md-option",
                                      {
                                        key: index,
                                        attrs: { value: operator.value },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(operator.name) +
                                            "\n                                    "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "custom-field-item" },
                        [
                          _c(
                            "form-group",
                            { attrs: { label: "Value", name: "value" } },
                            [
                              _c("md-input", {
                                model: {
                                  value: condition.data.value,
                                  callback: function ($$v) {
                                    _vm.$set(condition.data, "value", $$v)
                                  },
                                  expression: "condition.data.value",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : condition.type === "customers"
                  ? _c(
                      "div",
                      [
                        _c("customer-chips-autocomplete", {
                          staticClass: "customer-condition-auto-complete",
                          attrs: { customers: condition.data },
                          on: {
                            select: _vm.handleCustomerSelect,
                            delete: _vm.handleCustomerDeselect,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "condition-actions" },
              [
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-just-icon md-danger md-simple delete-button",
                    attrs: { title: "delete condition" },
                    on: {
                      click: function ($event) {
                        return _vm.handleDeleteCondition(conditionIndex)
                      },
                    },
                  },
                  [_c("md-icon", [_vm._v("close")])],
                  1
                ),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
    _vm.conditions.length == 0
      ? _c("div", { staticClass: "text-center" }, [
          _c("p", { staticClass: "no-result-message" }, [
            _vm._v("No available conditions."),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }