<template>
    <div class="map-overlay">
        <div v-if="isStreetView" class="close-button">
            <md-button class="md-white md-icon-button" :md-ripple="false" @click="close">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <TripDatePicker v-if="!isStreetView" />
        <MapSearch v-if="!isStreetView && $root.isDesktop" />
        <GoogleMapLoader
            @bounds-defined="setBounds"
            @google-defined="setGoogle"
            @map-defined="setMap"
            @panorama-defined="setPanorama"
        >
            <template>
                <team-region-member-filter-options
                    v-if="canViewTeamRegion && teamRegions && teamRegions.length > 0"
                    @changed="handleTeamRegionChanged"
                    :team-region-id="selectedTeamRegionId"
                    :show-team-member="false"
                    :clearable="false"
                    :strict-team-region-filter="true"
                    :use-in-map="true"
                    display="block"
                    class="team-region-container"
                />
                <GeofenceLayer v-if="$root.isDesktop" :map="map" :user="currentUser" />
                <InfoWindow :is-date-today="isDateToday" v-if="!isStreetView" />
                <div v-if="activePanel === 'Team Members'">
                    <DriverMarker
                        v-for="driver in driverDetails"
                        :key="driver.publicUserId"
                        :driver="driver"
                        :is-date-today="isDateToday"
                    >
                        <template
                            slot-scope="{ publicUserId }"
                            v-if="stopDetails[publicUserId] && stopDetails[publicUserId].length > 0"
                        >
                            <div>
                                <StopMarker v-for="stop in getModifiedStops(stopDetails, publicUserId)"
                                            :key="stop.stopId"
                                            :public-user-id="publicUserId"
                                            :stop="stop"
                                            :driver="driver"
                                            :stop-order="stop.stopIndex"
                                            :min-distance="minDistance">
                                    <template slot-scope="{ stopId }">
                                        <div v-if="stop.stopStatusHistory.length > 0">
                                            <div v-for="(stopStatus, index) in stop.stopStatusHistory" :key="index">
                                                <StopStatusHistoryMarker :driver="driver"
                                                                         :stop-id="stopId"
                                                                         :stop-details="stop"
                                                                         :stop-status-details="stopStatus"
                                                                         v-if="stopStatus.location && stopStatus.oldStatus" />
                                            </div>
                                        </div>
                                        <div v-if="geofenceData[stopId] && geofenceData[stopId].length > 0">
                                            <div v-for="(geofence, index) in geofenceData[stopId]" :key="index">
                                                <GeofenceMarker :driver="driver"
                                                                :geofence-details="geofence"
                                                                :stop-details="stop" />
                                            </div>
                                        </div>
                                    </template>
                                </StopMarker>
                                <DriverBreakMarker v-if="driverBreakList.length" v-for="driverBreak in driverBreakList"
                                            :key="driverBreak.driverBreakId"
                                            :public-user-id="publicUserId"
                                            :driver-break="driverBreak"
                                            :driver="driver">
                                </DriverBreakMarker>
                            </div>
                        </template>
                    </DriverMarker>
                </div>
                <div v-else>
                    <AssetGroupMarker
                        v-for="assetGroup in assetGroupDetails"
                        :key="assetGroup.assetGroupId"
                        :asset-group="assetGroup"
                        :is-date-today="isDateToday"
                    />
                </div>

                <RoutePolyline :pinned-driver-details="pinnedDriverDetails" :is-date-today="isDateToday" />
                <div v-if="pinnedUser">
                    <ToggleOptions v-if="!isReadOnlyUser" />
                    <CommandsPanel v-if="!isReadOnlyUser" :pinned-driver-details="pinnedDriverDetails" />
                    <DriverProfile
                        v-if="pinnedDriverDetails"
                        :pinned-driver-details="pinnedDriverDetails"
                        :photo-url="pinnedDriverDetails.photoUrl || undefined"
                    />
                    <StartEndLocationMarker />
                    <StopsListOverlay
                        :pinned-driver-details="pinnedDriverDetails"
                        v-if="pinnedDriverDetails !== undefined"
                    />
                    <LocationHistoryRoutePolyline
                        v-if="locationHistoryDetails.length"
                        :location-history-details="locationHistoryDetails"
                        :pinned-driver-details="pinnedDriverDetails"
                    />
                    <div v-for="(locationHistory, index) in locationHistoryDetails" :key="index">
                        <LocationHistoryMarker
                            v-if="!locationHistory.isHB"
                            :location-history-details="locationHistory"
                            :pinned-driver-details="pinnedDriverDetails"
                            :zoom-level="zoomLevel"
                        />
                        <HeartbeatMarker
                            v-else
                            :location-history-details="locationHistory"
                            :pinned-driver-details="pinnedDriverDetails"
                            :zoom-level="zoomLevel"
                        />
                    </div>
                    <RoutePlayback
                        :asset-present="!!assetGroupDetails.length"
                        :location-history-details="locationHistoryDetails"
                        :pinned-driver-details="pinnedDriverDetails"
                    />
                </div>
            </template>
        </GoogleMapLoader>
        <TeamMembersList
            v-if="membersLoaded && (canViewTeamRegion || isReadOnlyUser) && !isStreetView"
            :drivers="driverDetails"
            :render-key="renderKey"
            :asset-groups="assetGroupDetails"
            @changeLoading="(val) => (isLoading = val)"
        />
        <LocationDataUpdates @locationUpdated="handleLocationUpdate" :is-date-today="isDateToday" />
        <div v-if="isLoading">
            <div class="map-loader">
                <fade-loader :loading="isLoading" color="#000" />
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-restricted-properties */
import useMapUtils from '@/compostables/useMapUtils';
import useGeneralUtils from '@/compostables/useGeneralUtils';

import {
    ref,
    provide,
    reactive,
    computed,
    watch,
    toRefs,
    inject,
    onMounted,
    onBeforeUnmount
} from '@vue/composition-api';
import moment from 'moment';
import FadeLoader from 'vue-spinner/src/FadeLoader';

import { showErrorMessage, handleRequests, getDistanceBetweenMarkers, filterMembersByTeamRegionId } from '@/helpers';
import { MarkerPins } from '@/utils/MarkerPins';

import { TeamRegionMemberFilterOptions } from '@/components';
import TripDatePicker from './components/TripDatePicker';
import MapSearch from './components/MapSearch';
import GoogleMapLoader from './components/GoogleMapLoader';
import DriverMarker from './components/DriverMarker';
import AssetGroupMarker from './components/AssetGroupMarker';
import StopMarker from './components/StopMarker';
import DriverBreakMarker from './components/DriverBreakMarker';
import StopStatusHistoryMarker from './components/StopStatusHistoryMarker';
import GeofenceMarker from './components/GeofenceMarker';
import StartEndLocationMarker from './components/StartEndLocationMarker';
import LocationHistoryMarker from './components/LocationHistoryMarker';
import HeartbeatMarker from './components/HeartbeatMarker';
import LocationHistoryRoutePolyline from './components/LocationHistoryRoutePolyline';
import RoutePolyline from './components/RoutePolyline';
import RoutePlayback from './components/RoutePlayback';
import StopsListOverlay from './components/StopsListOverlay';
import ToggleOptions from './components/ToggleOptions';
import CommandsPanel from './components/CommandsPanel';
import InfoWindow from './components/InfoWindow';
import DriverProfile from './components/DriverProfile';
import LocationDataUpdates from './components/LocationDataUpdates';
import TeamMembersList from './TeamMembersList';
import GeofenceLayer from './GeoFenceLayer';
// import LeafletMapLoader from './components/LeafletMapLoader';

export default {
    name: 'MapOverview',
    components: {
        FadeLoader,
        // LeafletMapLoader
        GoogleMapLoader,
        DriverMarker,
        AssetGroupMarker,
        StopMarker,
        DriverBreakMarker,
        StopStatusHistoryMarker,
        GeofenceMarker,
        StartEndLocationMarker,
        LocationHistoryMarker,
        HeartbeatMarker,
        LocationHistoryRoutePolyline,
        RoutePolyline,
        TeamMembersList,
        TripDatePicker,
        MapSearch,
        RoutePlayback,
        StopsListOverlay,
        ToggleOptions,
        CommandsPanel,
        InfoWindow,
        DriverProfile,
        LocationDataUpdates,
        GeofenceLayer,
        TeamRegionMemberFilterOptions
    },
    setup(_, { root }) {
        const membersLoaded = ref(false);
        const isLoading = ref(false);
        const { createBounds, handleListener, closeStreetView, toggleStreetView } = useMapUtils(root);
        const { dateTypes } = useGeneralUtils(root);
        const { getters, dispatch, commit } = inject('vuex-store');
        // const hasTeam = getters['user/hasTeam'];
        const userHasTeamAccess = getters['user/hasTeamAccess'];
        const userHasAssetAccess = getters['user/hasAssetAccess'];
        const publicUserId = getters['user/publicUserId'];
        const geofenceConfiguration = getters['user/geofenceConfiguration'];
        // const isIndividualUser = getters['user/isIndividualUser'];
        // const isSingleTeamMember = getters['team/isSingleTeamMember'];
        // const isIndividual = !userHasTeamAccess || isIndividualUser;
        // const isIndividual = isIndividualUser || (hasTeam && !userHasTeamAccess);
        const hasElevatedTeamRegionFilter = computed(() => getters['user/hasElevatedTeamRegionFilter']);
        const canViewTeamRegion = computed(() => getters['user/canViewTeamRegion']);
        const teamRegionId = computed(() => getters['map/teamRegionId']);
        const teamRegions = computed(() => getters['team/teamRegions']);
        const allTeamMembers = computed(() => getters['team/teamMembers']);
        const filteredTeamMembers = computed(() => {
            return filterMembersByTeamRegionId(teamRegions.value, allTeamMembers.value, null, teamRegionId.value, true);
        });
        const userIdParam = ref(null);
        const loadUserParam = ref(false);
        const selectedTeamRegionId = ref(null);

        const state = reactive({
            driverDetails: [],
            assetGroupDetails: [],
            stopDetails: {},
            geofenceData: {},
            pinnedDriverDetails: {},
            geofenceList: [],
            driverBreakList: []
        });

        const getModifiedStops = (stops, id) => {
            let counter = 0;
            return stops[id].map((stop) => {
                if (!['complete', 'cancelled', 'failed', 'on hold'].includes(stop.status.toLowerCase())) {
                    stop.stopIndex = counter;
                    counter += 1;
                }
                return stop;
            });
        };

        const bounds = ref(null);
        const google = ref(null);
        const map = ref(null);
        const panorama = ref(null);
        const isStreetView = ref(false);

        const date = computed(() => getters['map/date']);
        const pinnedUser = computed(() => getters['map/pinnedUser']);
        const currentUser = computed(() => getters['user/user']);
        const locationHistoryDetails = computed(() => getters['map/timeseriesData']);
        const activePanel = computed(() => getters['map/activePanel']);
        const clickedStop = computed(() => getters['map/clickedStop']);
        const streetView = computed(() => getters['map/streetView']);
        const driverBreakList = computed(() => getters['map/driverBreakList']);
        const isReadOnlyUser = computed(() => getters['user/isReadOnlyUser']);
        const zoomLevel = ref(0);
        const minDistance = ref(0);

        // Set Team Members tab if page redirect from the trip
        if (root.$route.query?.from === 'trip') {
            commit('map/CHANGE_ACTIVE_PANEL', 'Team Members');
        }

        const isDateToday = ref(new Date(date.value).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0));

        const setBounds = (boundsGiven) => {
            bounds.value = boundsGiven;
        };

        const setGoogle = (googleGiven) => {
            google.value = googleGiven;
        };

        const setMap = (mapGiven) => {
            map.value = mapGiven;
            handleListener(map.value, 'bounds_changed', () => {
                zoomLevel.value = map.value.getZoom();
                const mapCenter = map.value.getCenter();
                // get minimum distance to show stop pins.
                const distanceScale =
                    (156543.03392 * Math.cos((mapCenter.lat() * Math.PI) / 180)) / Math.pow(2, zoomLevel.value) / 1000; // distance per pixel in km at a particular zoom level
                minDistance.value = distanceScale * 50; // half of the size of the marker in pixels. if the distance is less than this, the markers will overlap
            });
        };

        const setPanorama = (panoramaGiven) => {
            panorama.value = panoramaGiven;
        };

        const close = () => {
            closeStreetView(panorama.value);
            isStreetView.value = false;
        };

        const renderKey = ref(0);

        const handleLocationUpdate = ({ newData, dataToChange }) => {
            renderKey.value += 1;
            state[dataToChange].forEach((element, index) => {
                const userId = activePanel.value === 'Team Members' ? 'publicUserId' : 'trackerReference';
                const driverData = newData.find((d) => d.userId === element[userId]);

                if (driverData && driverData.routeDetails)
                    state[dataToChange][index] = Object.assign({}, element, { routeDetails: driverData.routeDetails });
            });
        };

        const fetchMapData = async () => {
            isLoading.value = true;
            state.driverdetails = [];
            state.assetGroupDetails = state.assetGroupDetails || [];
            state.stopDetails = {};
            state.driveBreakDetails = {};

            const fromDate = moment(date.value).format('YYYY-MM-DD');
            if (userHasAssetAccess || isReadOnlyUser) {
                // fetch asset groups data
                await dispatch('assetGroup/FETCH_ASSET_GROUPS_LOCATION_DETAILS', {
                    teamRegionId: teamRegionId.value,
                    elevatedTeamRegionFilter: hasElevatedTeamRegionFilter.value,
                    date: fromDate
                })
                    .catch((e) => {
                        const message = 'There was an error in fetching the asset group details';
                        showErrorMessage(root, message, e);
                    })
                    .then((r) => {
                        state.assetGroupDetails = r.map((a, i) => ({
                            ...a,
                            markerPins: MarkerPins[i % MarkerPins.length],
                            isAssetGroup: true
                        }));
                    });
            }

            // fetch team member data
            await dispatch('team/FETCH_TEAM_MEMBERS', {
                date: fromDate,
                includeLocationDetails: true,
                teamRegionId: teamRegionId.value,
                strictTeamRegionFilter: false, // true
                elevatedTeamRegionFilter: hasElevatedTeamRegionFilter.value
            })
                .catch((e) => {
                    const message = 'There was an error in fetching the driver details.';
                    showErrorMessage(root, message, e);
                })
                .then((r) => {
                    state.driverDetails = filteredTeamMembers.value.map((d, i) => ({
                        ...d,
                        markerPins: MarkerPins[i % MarkerPins.length],
                        isAssetGroup: false
                    }));

                    // Virtually pin the user, if induvidual user
                    if (
                        state.driverDetails.length === 1 &&
                        (currentUser.value.publicUserId || isReadOnlyUser.value) &&
                        activePanel.value !== 'Asset Groups'
                    ) {
                        dispatch('map/PIN_DRIVER', {
                            userId: state.driverDetails[0].publicUserId
                        });
                    }
                    membersLoaded.value = true;
                });

            // fetch stops data
            const userId = userHasTeamAccess ? '' : `&userId=${publicUserId}`;

            // removed teamRegion query param because for some scenario a Stop may have a TR but it's assigned to a member w/o TR
            // and so, if there's a mismatch between Stop and member's TR, no Stop will be returned
            const stopDetailsEndpoint = `/api/stops?fromDate=${fromDate}${userId}&includeStatusHistory=true`;
            await dispatch('map/FETCH_STOPS', { endpoint: stopDetailsEndpoint })
                .catch((e) => {
                    const message = 'There was an error in fetching the stops.';
                    showErrorMessage(root, message, e);
                })
                .then((r) => {
                    const stopsObj = {};
                    // loop through all stops to put distance between stops
                    Object.entries(r).forEach(([userId, stops]) => {
                        // users with more than two stops
                        if (stops.length >= 2) {
                            stops.forEach((stop, index) => {
                                if (index > 0) {
                                    const { location: firstStopLoc } = stop;
                                    const { location: secondStopLoc } = stops[index - 1];
                                    stop.distance = getDistanceBetweenMarkers(firstStopLoc, secondStopLoc);
                                    // make first stop the same distance as second stop
                                    if (index === 1)
                                        stops[0].distance = getDistanceBetweenMarkers(firstStopLoc, secondStopLoc);
                                }
                            });
                        }

                        stopsObj[userId] = stops;
                    });
                    state.stopDetails = stopsObj;
                });
            
            isLoading.value = false;
        };

        const fetchData = async (dateToLoad) => {
            isDateToday.value = new Date(dateToLoad).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);
            commit('map/CLEAR_TIMESERIES_DATA');
            await fetchMapData();
            // if there is a pinnedUser, fetch his details
            if (pinnedUser.value) {
                isLoading.value = true;
                dispatch('map/PIN_DRIVER', {
                    userId: pinnedUser.value,
                    date: root.$options.filters.dateFormat(dateToLoad, dateTypes.internationalDate),
                    isAssetGroup: activePanel.value === 'Asset Groups'
                });

                dispatch('map/FETCH_TIMESERIES_DATA', {
                    userId: pinnedUser.value,
                    tripDate: moment(date.value).format('YYYY-MM-DD')
                }).then(() => {
                    isLoading.value = false;
                });
            }
        };

        const unpinUser = () => {
            const { params } = root.$router.currentRoute;
            if (moment(params.date).isSame(moment(), 'day')) {
                root.$router.replace({ path: `/map` });
            } else {
                root.$router.replace({ path: `/map/${params.date}` });
            }
            commit('map/UNPIN_DRIVER');
        };

        const handleTeamRegionChanged = async (args) => {
            const prevTeamRegionId = selectedTeamRegionId.value;
            commit('map/SET_TEAM_REGION_ID', args.teamRegionId);

            if (prevTeamRegionId !== args?.teamRegionId) {
                unpinUser();
            }
            await fetchData(date.value);
        };

        const getUser = async (publicUserId) => {
            if (publicUserId) {
                const url = `/api/team-members/${publicUserId}`;
                await handleRequests(url).then((data) => {
                    if (data.data && data.data.data) {
                        commit(
                            'map/SET_TEAM_REGION_ID',
                            data.data.data.teamRegionId ? data.data.data.teamRegionId : -1
                        );

                        selectedTeamRegionId.value = data.data.data.teamRegionId ? data.data.data.teamRegionId : -1;
                    }
                });
            }
        };

        watch(pinnedUser, async (newVal) => {
            bounds.value = createBounds(google.value);
            if (activePanel.value === 'Team Members')
                state.pinnedDriverDetails = state.driverDetails.find((d) => d.publicUserId === newVal);
            else 
                state.pinnedDriverDetails = state.assetGroupDetails.find((a) => a.trackerReference === newVal);

            if (geofenceConfiguration === 'TEST') {
                const url = `/api/geofence?tripDate=${moment(date.value).format('YYYY-MM-DD')}&publicUserId=${newVal}`;

                const {
                    data: { data: geofenceData }
                } = await handleRequests(url);

                state.geofenceData = geofenceData.reduce((obj, item) => {
                    if (obj[item.stopId]) 
                        obj[item.stopId].push(item);
                    else 
                        obj[item.stopId] = [item];
                    return obj;
                }, {});
            }
        });

        // reset bounds
        watch(clickedStop, () => {
            bounds.value = createBounds(google.value);
        });

        // reset bounds
        watch(activePanel, () => {
            bounds.value = createBounds(google.value);
        });

        watch(date, async (newVal) => {
            await fetchData(newVal);
        });

        watch(streetView, ({ lat, lng }) => {
            toggleStreetView(panorama.value, lat, lng);
            isStreetView.value = true;
        });

        onMounted(async () => {
            let date = new Date();
            // we need to format the given date of the url to a valid date string accepted by the Date object
            if (root.$route.params.date) {
                date = new Date(root.$route.params.date);
            }
            commit('map/CHANGE_DATE', { date });
            // check if has user id (directly accessed) to set the correct teamRegionId later
            if (root.$route.params.userId && activePanel.value === 'Team Members') {
                userIdParam.value = root.$route.params.userId;
                loadUserParam.value = true;

                // set selected TeamRegionId to incoming Driver's TR
                await getUser(userIdParam.value);
            } else {
                // commit('map/SET_TEAM_REGION_ID', currentUser.value.teamRegionId);
                commit('map/SET_TEAM_REGION_ID', null); // All
                selectedTeamRegionId.value = null; // All set // currentUser.value.teamRegionId ? currentUser.value.teamRegionId : -1; // not set
            }
        });

        onBeforeUnmount(() => {
            // destroy instance
            google.value = null;
            map.value = null;
            bounds.value = null;
            state.driverdetails = [];
            state.assetGroupDetails = [];
            state.stopDetails = {};
            state.driverBreakList = [];

            commit('map/UNPIN_DRIVER');
        });

        provide('google', google);
        provide('map', map);
        provide('bounds', bounds);
        provide('isStreetView', isStreetView);

        return {
            isStreetView,
            close,
            isLoading,
            membersLoaded,
            ...toRefs(state),
            setBounds,
            setGoogle,
            setMap,
            zoomLevel,
            minDistance,
            setPanorama,
            locationHistoryDetails,
            activePanel,
            pinnedUser,
            currentUser,
            isDateToday,
            renderKey,
            handleLocationUpdate,
            // isIndividual,
            hasElevatedTeamRegionFilter,
            canViewTeamRegion,
            getModifiedStops,
            isReadOnlyUser,
            teamRegionId,
            handleTeamRegionChanged,
            getUser,
            map,
            teamRegions,
            selectedTeamRegionId,
            driverBreakList
        };
    }
};
</script>

<style lang="scss" scoped>
.map-overlay {
    position: relative;
    width: 100%;
    ::v-deep .asset-map-image-marker {
        background-color: var(--driver-color);
        border-radius: 50%;
        cursor: pointer !important;
        height: 40px;
        margin-left: -20px; /* margin-left = -width/2 */
        margin-top: -50px; /* margin-top = -height + arrow */
        padding: 0px;
        position: absolute;
        width: 40px;

        &:after {
            border-color: var(--driver-color) transparent;
            border-style: solid;
            border-width: 10px 10px 0;
            bottom: -8px;
            content: '';
            display: block;
            left: 10px;
            position: absolute;
            width: 0;
        }

        .image {
            background-color: var(--driver-color);
            background-position: center center;
            background-size: cover;
            border-radius: 50%;
            height: 36px;
            margin: 2px;
            width: 36px;
            &.asset {
                background-size: auto;
                width: 87%;
                background-position: 34% 25%;
            }
        }
    }

    .map-loader {
        position: absolute;
        top: 35%;
        left: 50%;

        span {
            position: absolute;
            margin-top: 50px;
            width: 110px;
            left: calc((100% - 100px) / 2);
            text-align: center;
            font-weight: 600;
        }
    }

    .close-button {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 4;

        .md-button {
            .md-icon {
                color: black !important;
            }
        }
    }
    .team-region-container {
        position: absolute;
        top: 10px;
        left: 405px;
        z-index: 2;
        height: 40px;
        background: white;
        max-width: 218px;
        padding: 0 8px;
        display: flex;
        border-radius: 3px;
        ::v-deep .vs__dropdown-toggle {
            border: 0;
            margin-top: -9px;
            padding-bottom: 1px;
        }
    }
    .map-calendar {
        width: 155px;
    }
}
</style>
