<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Add Charge item</h4>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.chargeLine" class="form-wrapper">
                <div class="item-description">
                    <form-group label="Description">
                        <md-input v-model="chargeLine.description" :disabled="chargeLine.type === 'surcharge'" />
                    </form-group>
                </div>
                <div class="item-unit">
                    <form-group label="Unit">
                        <md-input v-model="chargeLine.chargeUnit" readonly />
                    </form-group>
                </div>
                <div class="item-unitPriceExTax">
                    <form-group label="Amount">
                        <md-input v-model.number="chargeLine.unitPriceExTax" />
                    </form-group>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="addChargeItem">
                Save
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, decimal } from 'vuelidate/lib/validators';
import { handleRequests, showErrorMessage } from '@/helpers';

export default {
    name: 'TripChargeItemModal',
    mixins: [GeneralMixin],
    props: {
        chargeLine: {
            type: Object,
            default: () => {}
        },
        tripId: {
            type: Number,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        isManualCharge: {
            type: Boolean,
            default: false
        },
        manuallyChanged: {
            type: Boolean,
            default: false
        }
    },
    validations: {
        chargeLine: {
            description: {
                required
            },
            quantity: {
                decimal
            },
            unitPriceExTax: {
                decimal
            }
        }
    },
    data() {
        return {
            description: '',
            unitPriceExTax: 0,
            quantity: 1
        };
    },
    methods: {
        async addChargeItem() {
            if (this.$v.$invalid) {
                showErrorMessage(this, 'Missing required data');
                return;
            }

            this.$_handleLoaderState(true, 'UPDATING...');
            const payload = {
                method: 'post',
                data: {
                    id: this.chargeLine.id,
                    description: this.chargeLine.description,
                    quantity: this.chargeLine.quantity,
                    unitPriceExTax: this.chargeLine.unitPriceExTax,
                    manuallyChanged: this.manuallyChanged,
                    isManualCharge: this.isManualCharge
                }
            };
            try {
                const api = `/api/trips/${this.tripId}/rate/charges`;
                await handleRequests(api, payload);

                this.$notify({
                    message: `The charge was successfully added.`,
                    type: 'success'
                });
                this.$v.$reset();

                this.resolve(true);
            } catch (e) {
                const message = 'There was an error adding the charge. Please try again later.';
                showErrorMessage(this, message);
                this.resolve(false);
            }
            this.$_handleLoaderState(false);
        }
    }
};
</script>
