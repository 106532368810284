var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { attrs: { clas: "modal-title" } }, [
          _vm._v("Edit " + _vm._s(_vm.assetGroupData.groupName)),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          {
            staticClass: "form-wrapper",
            attrs: { validator: _vm.$v.assetGroup },
          },
          [
            _c(
              "div",
              { staticClass: "md-layout-item md-size-100" },
              [
                _c(
                  "form-group",
                  { attrs: { label: "Asset Group Name*", name: "groupName" } },
                  [
                    _c("md-input", {
                      model: {
                        value: _vm.assetGroup.groupName,
                        callback: function ($$v) {
                          _vm.$set(_vm.assetGroup, "groupName", $$v)
                        },
                        expression: "assetGroup.groupName",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "md-layout-item md-size-100" },
              [
                _vm.showTeamRegion
                  ? _c(
                      "form-group",
                      { attrs: { name: "teamRegion", label: "Team Region" } },
                      [
                        _c("team-region-options", {
                          attrs: {
                            selected: _vm.assetGroup.teamRegionId,
                            mode: "update",
                            "show-auto": false,
                          },
                          on: { selectedOption: _vm.handleTeamRegionChanged },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            on: {
              click: function ($event) {
                return _vm.update()
              },
            },
          },
          [_vm._v("\n            Update\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }