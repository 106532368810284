<template>
    <div class="md-layout-item">
        <div class="custom-toolbar">
            <div class="custom-toolbar-start">
                <div class="filter-steps--container">
                    <team-region-member-filter-options
                        v-if="canViewTeamRegion"
                        :team-region-id="teamRegionId"
                        @changed="handleTeamRegionMemberChanged"
                        :strict-team-region-filter="true"
                        :use-elevated-filter-view="true"
                        :clearable="false"
                        :selected-team-member="filters.selectedTeamMember"
                    />
                    <md-field>
                        <customer-filter-options
                            class="filter-steps--choices filter-status"
                            :filter-customer-id="filters.customerId"
                            @onCustomerSelected="handleCustomerChanged"
                        />
                    </md-field>

                    <md-field>
                        <process-charge-status-filter
                            class="filter-steps--choices filter-status"
                            :filter-status-filter="filters.status"
                            @onStatusSelected="handleStatusFilterChanged"
                        />
                    </md-field>

                    <md-field>
                        <md-datepicker
                            class="filter-steps--choices filter-step-date start-date"
                            v-model="filters.fromDate"
                            md-immediately
                            :md-debounce="100"
                        ></md-datepicker>
                    </md-field>

                    <md-field>
                        <md-datepicker
                            class="filter-steps--choices filter-step-date end-date"
                            v-model="filters.toDate"
                            md-immediately
                            :md-debounce="100"
                        ></md-datepicker>
                    </md-field>

                    <md-field>
                        <vue-select
                            label="name"
                            :options="invoiceListSettings"
                            :reduce="(x) => x.key"
                            placeholder="Invoice"
                            v-model="filters.invoiceListSettingValue"
                        />
                    </md-field>

                    <md-field v-if="serviceData.length > 1">
                        <vue-select
                            label="name"
                            :options="serviceData"
                            :reduce="(x) => x.serviceId"
                            placeholder="Service"
                            v-model="filters.serviceId"
                        />
                    </md-field>

                    <form-group class="free-text" label="Attempt #">
                        <md-input v-model.number="filters.attempt" />
                    </form-group>

                    <form-group class="free-text" label="Run #">
                        <md-input v-model.number="filters.runNumber" />
                    </form-group>

                    <template v-feature="[featureNames.JobsCostApproval, featureNames.JobsChargeApproval]">
                        <md-field>
                            <process-charge-approval-status-filter
                                class="filter-steps--choices filter-approval-status"
                                :filter-approval-status-filter="filters.approvalStatus"
                                @onApprovalStatusSelected="handleApprovalStatusFilterChanged"
                            />
                        </md-field>
                    </template>

                    <md-field class="charging-options">
                        <charging-type-options
                            label-name=""
                            @selectedOption="servicePackageChanged"
                            placeholder="Service Package">
                        </charging-type-options>
                    </md-field>
                </div>
            </div>
            <div class="custom-toolbar-end">
                <search-component
                    ref="keyword"
                    @onSearch="handleKeyword"
                    placeholder="Search"
                    :search-filter="filters.search"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins';
import { DATE_TYPES, FEATURE_NAMES } from '@/utils/constants';
import { handleRequests } from '@/helpers';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { TeamRegionMemberFilterOptions, SearchComponent, CustomerFilterOptions, ChargingTypeOptions } from '@/components';
import moment from 'moment';
import ProcessChargeStatusFilter from './ProcessChargeStatusFilter';
import ProcessChargeApprovalStatusFilter from './ProcessChargeApprovalStatusFilter';

export default {
    name: 'ProcessChargeOverviewToolbar',
    components: {
        TeamRegionMemberFilterOptions,
        SearchComponent,
        CustomerFilterOptions,
        ProcessChargeStatusFilter,
        ProcessChargeApprovalStatusFilter,
        ChargingTypeOptions
    },
    props: {
        teamRegionId: {
            type: Number,
            default: null
        },
        isCostApprovalFeatureEnabled: {
            type: Boolean,
            default: false
        },
        isChargeApprovalFeatureEnabled: {
            type: Boolean,
            default: false
        },
        servicePackages: {
            type: Array,
            default: () => []
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {
            userId: null,
            selectedTeamRegionId: null,
            invoiceListSettings: [
                { key: 'All', name: 'All uninvoiced stops' },
                { key: 'Customer', name: 'Uninvoiced Customer' },
                { key: 'TeamMember', name: 'Uninvoiced Team member' }
            ],
            filters: {
                teamRegionId: null,
                customerId: null,
                totalCostFilter: 0,
                totalChargeFilter: 0,
                userId: null,
                status: 'All',
                fromDate: null,
                toDate: null,
                search: null,
                selectedTeamMember: null,
                invoiceListSettingValue: 'All',
                serviceId: null,
                attempt: null,
                approvalStatus: 'Unapproved',
                runNumber: null,
                rateGroupId: null
            },
            serviceData: [],
            featureNames: FEATURE_NAMES
        };
    },
    mounted() {
        if (localStorage.getItem('processCharge.search-filter-options') !== null) {
            const localStorageOptions = JSON.parse(localStorage.getItem('processCharge.search-filter-options'));
            if (localStorageOptions) {
                this.filters = localStorageOptions;
                if (this.filters.toDate && this.filters.fromDate) {
                    this.filters.toDate = moment(this.filters.toDate).format(DATE_TYPES.internationalDate);
                    this.filters.fromDate = moment(this.filters.fromDate).format(DATE_TYPES.internationalDate);
                } else {
                    this.filters.toDate = moment().format(DATE_TYPES.internationalDate);
                    const startDate = moment(this.filters.toDate);
                    const tempDate = startDate.clone().subtract(14, 'days');

                    this.filters.fromDate = moment(tempDate).format(DATE_TYPES.internationalDate);
                }
            }
        } else {
            this.filters.toDate = moment().format(DATE_TYPES.internationalDate);
            const startDate = moment(this.filters.toDate);
            const tempDate = startDate.clone().subtract(14, 'days');

            this.filters.fromDate = moment(tempDate).format(DATE_TYPES.internationalDate);
        }

        this.getServiceList();
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            canViewTeamRegion: 'user/canViewTeamRegion'
        })
    },
    watch: {
        'filters.customerId': function() {
            this.handleFilter();
        },
        'filters.status': function() {
            this.handleFilter();
        },
        'filters.search': function() {
            this.handleFilter();
        },
        'filters.fromDate': function(newVal, oldVal) {
            if (this.filters.fromDate && this.filters.toDate) {
                if (moment(newVal).format('YYYY-MM-DD') !== moment(oldVal).format('YYYY-MM-DD')) {
                    if (
                        !this.isSingleDate &&
                        moment(newVal).format('YYYY-MM-DD') > moment(this.filters.toDate).format('YYYY-MM-DD')
                    ) {
                        this.$notify({
                            message: 'From date cannot be later than the to date.',
                            type: 'danger'
                        });
                    } else {
                        this.handleFilter();
                    }
                }
            }

            if (!this.filters.fromDate && !this.filters.toDate) {
                this.handleFilter();
            }
        },
        'filters.toDate': function(newVal, oldVal) {
            if (this.filters.fromDate && this.filters.toDate) {
                if (moment(newVal).format('YYYY-MM-DD') !== moment(oldVal).format('YYYY-MM-DD')) {
                    if (
                        !this.isSingleDate &&
                        moment(newVal).format('YYYY-MM-DD') < moment(this.filters.fromDate).format('YYYY-MM-DD')
                    ) {
                        this.$notify({
                            message: 'To date cannot be earlier than the from date.',
                            type: 'danger'
                        });
                    } else {
                        this.handleFilter();
                    }
                }
            }

            if (!this.filters.fromDate && !this.filters.toDate) {
                this.handleFilter();
            }
        },
        'filters.invoiceListSettingValue': function(newValue) {
            localStorage.setItem('processCharge.search-filter-options', JSON.stringify(this.filters));
            this.handleFilter();
        },
        'filters.serviceId': function(newValue) {
            localStorage.setItem('processCharge.search-filter-options', JSON.stringify(this.filters));
            this.handleFilter();
        },
        'filters.attempt': function(newValue) {
            localStorage.setItem('processCharge.search-filter-options', JSON.stringify(this.filters));
            this.handleFilter();
        },
        'filters.approvalStatus': function(newValue) {
            this.handleFilter();
        },
        'filters.runNumber': function(newValue) {
            this.handleFilter();
        },
        'filters.rateGroupId': function (newValue) {
            this.handleFilter();
        }
    },
    methods: {
        // eslint-disable-next-line func-names
        handleFilter: _.debounce(function() {
            this.handleFilteredValue();
        }, 700),
        handleFilteredValue() {
            this.emitEvent('onFilterProcessCharges');
        },
        handleTeamRegionMemberChanged(val) {
            this.userId = val.teamMember?.userId;
            this.selectedTeamRegionId = val.teamRegionId;
            this.handleFilter();
        },
        handleKeyword(data) {
            this.filters.search = data.searchText;
        },
        handleCustomerChanged(value) {
            this.filters.customerId = value;
        },
        handleStatusFilterChanged(value) {
            this.filters.status = String(value);
        },
        handleApprovalStatusFilterChanged(value) {
            this.filters.approvalStatus = String(value);
        },
        emitEvent(evt) {
            this.$nextTick(() => {
                if (this.filters.fromDate) {
                    this.filters.fromDate = this.$options.filters.dateFormat(
                        this.filters.fromDate,
                        this.DATE_TYPES.internationalDate
                    );
                }

                if (this.filters.toDate) {
                    this.filters.toDate = this.$options.filters.dateFormat(
                        this.filters.toDate,
                        this.DATE_TYPES.internationalDate
                    );
                }

                localStorage.setItem('processCharge.search-filter-options', JSON.stringify(this.filters));
                this.filters.userId = this.userId;
                this.filters.teamRegionId = this.selectedTeamRegionId;

                this.$emit(evt, {
                    ...this.filters
                });
            });
        },
        async getServiceList() {
            const endpoint = `/api/services/list`;
            const result = await handleRequests(endpoint);
            this.serviceData = result.data;

            const allElementItem = {
                serviceId: 0,
                name: 'All'
            };

            this.serviceData.unshift(allElementItem);
        },
        servicePackageChanged(servicePackage) {
            if (servicePackage !== undefined) {
                this.filters.rateGroupId = servicePackage?.rateGroupId ?? null;
                this.handleFilter();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-toolbar {
    align-items: start;
    margin-top: 1px;
    .custom-toolbar-start {
        flex: 3;
    }
}

::v-deep .md-toolbar {
    .md-field {
        &:first-child,
        &:last-child {
            margin: 8px;
        }
    }

    .stops-search {
        padding-bottom: 10px;
        &::before,
        &::after {
            bottom: 10px;
        }
    }
}

::v-deep .filter-steps--container {
    .filter-steps--choices {
        margin-top: 0;
        padding-bottom: 0;
        > .md-input {
            display: initial;
        }
    }
}

.filter-steps--container {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }
    .filter-step-date {
        min-width: 250px;
        margin-right: 0;
        min-height: initial;
        position: absolute;
        z-index: 1;
    }

    .filter-status,
    .filter-approval-status {
        min-width: 150px;
        margin-right: 0;
        min-height: initial;
        position: absolute;
    }

    .filter-button--container {
        display: inline-block;
    }
    ::v-deep .md-datepicker:after,
    ::v-deep .md-datepicker:before {
        bottom: 0;
        height: 0;
    }

    ::v-deep .md-datepicker:before,
    ::v-deep .md-datepicker:after {
        bottom: 30px;
        color: #aaaaaa;
        font-weight: 400;
        font-size: 14px;
        transform: none;
        z-index: 1;
    }

    .start-date:before,
    .start-date:after {
        content: 'Start Date';
    }
    .end-date:before,
    .end-date:after {
        content: 'End Date';
    }

    ::v-deep .md-has-value:not(.free-text):before,
    ::v-deep .md-has-value:not(.free-text):after {
        bottom: 0;
        height: 0;
        content: '';
    }

    ::v-deep .md-datepicker .md-input {
        position: absolute;
        z-index: 9;
        width: 150px;
    }

    ::v-deep .md-datepicker .md-input-action {
        z-index: 99;
        right: 52px;
    }
}
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}
::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}
::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}

::v-deep .vs__search {
    cursor: pointer;
}

.datepicker-noevent {
    ::v-deep .md-datepicker {
        input {
            pointer-events: none;
        }
    }
}

.status-filter {
    width: 150px !important;
}

.filter-steps--container {
    .filter-steps--choices {
        margin-top: 0;
        padding-bottom: 0;
        > .md-input {
            display: initial;
        }
    }
}

.charging-options {
    height: 0px;
}
</style>
