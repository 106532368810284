<template>
    <div
        class="stops-list map-panel"
        v-if="isStopsListToggled && !pinnedDriverDetails.isAssetGroup"
        :style="{ bottom: getButtonPlacement }"
    >
        <md-card-content class="stop-table-body">
            <md-table v-if="pinnedStops.length > 0" class="context-menu-support custom-paginated-table">
                <md-table-row>
                    <md-table-head></md-table-head>
                    <md-table-head>Address</md-table-head>
                    <md-table-head>Status</md-table-head>
                    <md-table-head class="eta-header">ETA/Arrival</md-table-head>
                    <md-table-head></md-table-head>
                </md-table-row>
                <md-table-row
                    v-for="(stopItem, stopIndex) in pinnedStops"
                    :key="stopIndex"
                    style="cursor:pointer;"
                    @click="handleStopClicked(stopItem)"
                >
                    <md-table-cell>
                        {{ stopItem.stopIndex }}
                    </md-table-cell>
                    <md-table-cell class="stop-address">
                        {{ stopItem.address }}
                    </md-table-cell>
                    <md-table-cell>
                        {{ stopItem.status }}
                    </md-table-cell>
                    <md-table-cell class="stop-time">
                        <div v-if="['On Hold', 'Cancelled'].indexOf(stopItem.status) === -1">
                            <span
                                v-if="['Arrived', 'Complete', 'Failed'].indexOf(stopItem.status) !== -1"
                                class="bold-time"
                            >
                                {{ stopItem.arrivalDate | timeFormat(dateTypes.standardTime) }}
                            </span>
                            <span v-else>
                                {{ stopItem.originalEta | timeFormat(dateTypes.standardTime) }}
                            </span>
                        </div>
                    </md-table-cell>
                    <md-table-cell>
                        <router-link
                            :to="{
                                name: 'Stop Details',
                                params: { stopId: stopItem.stopId }
                            }"
                            target="_blank"
                            @click.native="$event.stopImmediatePropagation()"
                            title="Stop details"
                        >
                            <md-icon class="link-icon" :style="{ '--bg-color': pinnedDriverDetails.markerPins.color }">
                                open_in_new
                            </md-icon>
                        </router-link>
                    </md-table-cell>
                </md-table-row>
            </md-table>
            <div v-else class="empty-table">
                <span>{{ pinnedDriverDetails.firstName }} has no stops assigned on this date</span>
            </div>
        </md-card-content>
    </div>
</template>

<script>
import { inject, computed } from '@vue/composition-api';
import useGeneralUtils from '@/compostables/useGeneralUtils';

export default {
    name: 'StopsListOverlay',
    props: {
        pinnedDriverDetails: {
            type: Object,
            default: () => {},
            required: true
        },
        tripDetails: {
            type: Array,
            default: () => [],
            required: false
        }
    },
    setup(_, context) {
        const { dateTypes } = useGeneralUtils(context);
        const { getters, commit } = inject('vuex-store');
        const pinnedStops = computed(() => getters['map/pinnedStops']);
        const isStopsListToggled = computed(() => getters['map/isStopsListToggled']);
        const isLocationHistoryToggled = computed(() => getters['map/isLocationHistoryToggled']);

        const handleStopClicked = ({ stopId, assignedTo: { publicUserId } }) => {
            commit('map/STOP_CLICKED', { stopId, publicUserId });
        };

        const getButtonPlacement = computed(() => {
            let buttonPlacement = '25px';
            if (isLocationHistoryToggled.value) {
                buttonPlacement = '60px';
            }
            return buttonPlacement;
        });

        return {
            pinnedStops,
            dateTypes,
            handleStopClicked,
            isStopsListToggled,
            isLocationHistoryToggled,
            getButtonPlacement
        };
    }
};
</script>
<style lang="scss" scoped>
.stops-list {
    position: absolute;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    width: 32%;
    // to remove x-overflow
    min-width: 580px;

    max-height: 350px;
    overflow-y: auto;
    .stop-table-body {
        padding: 8px;
    }

    ::v-deep .md-table {
        background-color: transparent !important;
        .md-table-content {
            background-color: transparent !important;

            tr {
                & > .md-table-head {
                    padding: 2px 4px;
                }
                & > .md-table-cell {
                    padding: 0px 4px;
                    height: 35px;
                }
            }
            .eta-header {
                padding-right: 0;
                text-align: right;

                .md-table-head-label {
                    padding-right: 0;
                }
            }
        }

        .md-icon {
            font-size: 15px !important;
            padding-left: 12px;
            &:hover {
                color: var(--bg-color);
            }
        }
    }

    .stop-time {
        ::v-deep .md-table-cell-container {
            text-align: right;
        }
        .bold-time {
            font-weight: 500;
        }
    }

    .stop-address {
        ::v-deep .md-table-cell-container {
            max-width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .empty-table {
        height: 150px;
        display: flex;

        span {
            margin: auto;
        }
    }
}
</style>
