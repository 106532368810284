<template>
    <div>
        <md-card-content>
            <md-table :value="reportData" class="table-hover">
                <md-table-row slot="md-table-row" slot-scope="{ item }" @click="viewAddress(item)">
                    <md-table-cell md-label="Date/Time">
                        {{ displayDateTime(item.dateTime, item.utcOffset) | dateTimeFormat }}
                    </md-table-cell>
                    <md-table-cell md-label="Address" md-sort>
                        <a class="address" @click.stop="viewAddress(item)">
                            {{ displayAddressOrCoord(item) }}
                        </a>
                    </md-table-cell>
                    <md-table-cell md-label="Type">
                        {{ item.type }}
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </md-card-content>
    </div>
</template>

<style scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

::v-deep .md-table-head {
    cursor: default;
}

.table-hover {
    cursor: pointer;
}
</style>
<script>
import moment from 'moment';
import { ViewAddressModal } from '..';

export default {
    name: 'SafetyReportDetails',
    components: {},
    props: {
        memberList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {};
    },
    computed: {
        reportData() {
            return this.memberList;
        }
    },
    methods: {
        displayDateTime(date, utcOffset) {
            if (date) 
                return moment(date).utcOffset(utcOffset);
            return '';
        },
        displayAddressOrCoord(item) {
            const addr = [item.addressRoad, item.addressSuburb, item.addressTown].filter((part) => part).join(', ');
            if (addr) 
                return addr;
            return `${item.latitude} - ${item.longitude}`;
        },
        viewAddress(item) {
            this.$modal.show(ViewAddressModal, { data: { location: item, address: this.displayAddressOrCoord(item) } });
        }
    }
};
</script>
