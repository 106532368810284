var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading && _vm.orderDetails != null
    ? _c("div", [
        _c("div", { staticClass: "md-layout" }, [
          _c("div", { staticClass: "md-layout-item" }, [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Orders List",
                        },
                      },
                    },
                    [_c("h3", { staticClass: "title" }, [_vm._v("Order List")])]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("   >    " + _vm._s(_vm.orderDetails.orderNumber)),
                  ]),
                ],
                1
              ),
              !_vm.$root.isMobileOnly
                ? _c("div", { staticClass: "custom-toolbar-end" }, [
                    _c(
                      "div",
                      { staticClass: "header-button-container" },
                      [
                        _c("OrderPaymentStatusButton", {
                          attrs: { order: _vm.orderDetails },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-66 md-xlarge-size-50",
            },
            [
              _c(
                "md-card",
                [
                  _c(
                    "md-card-header",
                    { staticClass: "md-card-header-icon md-card-header-blue" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-icon" },
                        [_c("md-icon", [_vm._v("description")])],
                        1
                      ),
                    ]
                  ),
                  _c("md-card-content", [
                    _c("div", { staticClass: "multiple-details-box" }, [
                      _c("div", { staticClass: "details-box" }, [
                        _c("label", [_vm._v("Order Number")]),
                        _c("span", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.orderDetails.orderNumber) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "details-box" }, [
                        _c("label", [_vm._v("Purchase Order Number")]),
                        _c("span", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.orderDetails.purchaseOrderNumber) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "details-box" }, [
                        _c("label", [_vm._v("Source Reference")]),
                        _c("span", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.orderDetails.sourceReference) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "details-box" }, [
                        _c("label", [_vm._v("Payment date")]),
                        _c("span", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.orderDetails.paymentDate,
                                  _vm.DATE_TYPES.standardDate
                                )
                              ) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "multiple-details-box" }, [
                      _c("div", { staticClass: "details-box" }, [
                        _c("label", [_vm._v("Order date")]),
                        _c("span", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.orderDetails.orderDate,
                                  _vm.DATE_TYPES.standardDate
                                )
                              ) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "details-box" }, [
                        _c("label", [_vm._v("Expected Delivery Date")]),
                        _c("span", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.orderDetails.expectedDeliveryDate,
                                  _vm.DATE_TYPES.standardDate
                                )
                              ) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm.orderDetails.pickupAddress != null
                      ? _c("div", { staticClass: "address-details" }, [
                          _c("div", { staticClass: "details-box" }, [
                            _c("label", [_vm._v("Pickup Address")]),
                            _vm.orderDetails.pickupAddress.name
                              ? _c("div", { staticClass: "address-name" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.orderDetails.pickupAddress.name
                                      ) +
                                      "\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.orderDetails.pickupAddress.address)
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.orderDetails.shippingAddress != null
                      ? _c("div", { staticClass: "address-details" }, [
                          _c("div", { staticClass: "details-box" }, [
                            _c("label", [_vm._v("Delivery Address")]),
                            _vm.orderDetails.shippingAddress.name
                              ? _c("div", { staticClass: "address-name" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.orderDetails.shippingAddress.name
                                      ) +
                                      "\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.orderDetails.shippingAddress.address)
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "details-box" }, [
                      _c("label", [_vm._v("Delivery Instructions")]),
                      _c("div", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.orderDetails.deliveryInstructions) +
                            "\n                        "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "details-box" }, [
                      _c("label", [_vm._v("Notes")]),
                      _c("div", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.orderDetails.notes) +
                            "\n                        "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "md-card",
                [
                  _c(
                    "md-card-header",
                    { staticClass: "md-card-header-icon md-card-header-rose" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-icon" },
                        [_c("md-icon", [_vm._v("receipt_long")])],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "md-card-content",
                    [
                      _vm.orderDetails.orderLines.length > 0
                        ? _c(
                            "md-table",
                            [
                              _c(
                                "md-table-row",
                                [
                                  _c("md-table-head", [
                                    _vm._v("Product Reference"),
                                  ]),
                                  _c("md-table-head", [_vm._v("Qty")]),
                                  _c("md-table-head", [_vm._v("Status")]),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.orderDetails.orderLines,
                                function (orderLine) {
                                  return _c(
                                    "md-table-row",
                                    { key: orderLine.orderLineId },
                                    [
                                      _c("md-table-cell", [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(orderLine.productReference) +
                                            "\n                                "
                                        ),
                                        orderLine.productVariant != null
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                                    (" +
                                                  _vm._s(
                                                    orderLine.productVariant
                                                      .size
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    orderLine.productVariant
                                                      .colour
                                                  ) +
                                                  ")\n                                "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _c("md-table-cell", [
                                        _vm._v(_vm._s(orderLine.quantity)),
                                      ]),
                                      _c("md-table-cell", [
                                        _c(
                                          "span",
                                          { staticClass: "custom-badge" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(orderLine.status) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _c("div", [
                            _c("p", { staticClass: "no-result-message" }, [
                              _vm._v("No existing order lines for this order."),
                            ]),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "md-card",
                [
                  _c(
                    "md-card-header",
                    { staticClass: "md-card-header-icon md-card-header-green" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-icon" },
                        [_c("md-icon", [_vm._v("inventory_2")])],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "md-card-content",
                    [
                      _vm.orderDetails.items.length > 0
                        ? _c(
                            "md-table",
                            { staticClass: "itemline-table" },
                            [
                              _c(
                                "md-table-row",
                                [
                                  _c("md-table-head", [_vm._v("Barcode")]),
                                  _c("md-table-head", [_vm._v("Status")]),
                                  _c("md-table-head", [_vm._v("Item lines")]),
                                ],
                                1
                              ),
                              _vm._l(_vm.orderDetails.items, function (item) {
                                return _c(
                                  "md-table-row",
                                  {
                                    key: item.itemId,
                                    staticClass: "top-align",
                                  },
                                  [
                                    _c(
                                      "md-table-cell",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "ref-link",
                                            attrs: {
                                              title: "View item details",
                                              to: {
                                                name: "Inventory Details",
                                                params: { itemId: item.itemId },
                                              },
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(item.barcode) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("md-table-cell", [
                                      _c(
                                        "span",
                                        { staticClass: "custom-badge" },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item.status) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "mini-table-row" },
                                      [
                                        _c(
                                          "md-table",
                                          { staticClass: "mini-table" },
                                          [
                                            _c("md-table-head", [
                                              _vm._v("Qty"),
                                            ]),
                                            _c("md-table-head", [
                                              _vm._v("Product"),
                                            ]),
                                            _vm._l(
                                              item.itemLines,
                                              function (line) {
                                                return _c(
                                                  "md-table-row",
                                                  { key: line.itemLineId },
                                                  [
                                                    _c("md-table-cell", [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            line.quantity
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]),
                                                    _c("md-table-cell", [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            line.productReference
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        : _c("div", [
                            _c("p", { staticClass: "no-result-message" }, [
                              _vm._v("No existing order lines for this order."),
                            ]),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-xsmall-size-100 md-small-size-33 md-medium-size-33 md-large-size-33 md-xlarge-size-50",
            },
            [
              _c("contact-details", {
                attrs: { "stop-details": _vm.orderDetails },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }