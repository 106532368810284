<template>
    <div v-visibilityhandler>
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <h3 class="title triplist-title">Trip Dashboard</h3>
                        <trip-filter-component
                            :trip-date="selectedDate"
                            :team-region-id="selectedTeamRegionId"
                            :team-members="() => this.teamMembers"
                            @onFilterTrips="handleFilterOrSearch"
                        />
                    </div>
                    <div class="custom-toolbar-end" v-if="!isReadOnlyUser && teamMembers.length > 1">
                        <search-component
                            v-if="$root.isDesktop"
                            :search-filter="currentSearchTextValue"
                            @onSearch="handleStopsSearch"
                        />
                        <div>
                            <md-button
                                title="Create Trip"
                                class="md-primary md-just-icon md-round pull-right header-button"
                                @click="goToCreateTripPage"
                            >
                                <md-icon>add</md-icon>
                            </md-button>
                        </div>
                        <div>
                            <md-button
                                v-if="!isSupportStaff"
                                title="Export Trips"
                                class="md-primary md-just-icon md-round pull-right header-button"
                                @click="exportTrips(null, '')"
                            >
                                <md-icon>get_app</md-icon>
                            </md-button>
                        </div>
                        <div>
                            <md-button
                                title="Send run sheet/s to my email"
                                class="md-primary md-just-icon md-round pull-right header-button"
                                @click.stop="sendRunSheetPdfToEmail()"
                            >
                                <md-icon>forward_to_inbox</md-icon>
                            </md-button>
                        </div>
                        <div>
                            <md-button
                                v-if="!isSupportStaff"
                                class="md-primary md-just-icon md-round pull-right header-button"
                                @click.stop="sendBulkSMS()"
                            >
                                <md-icon>mobile_screen_share</md-icon>
                            </md-button>
                        </div>
                        <!-- filters and action buttons -->
                    </div>
                </div>
            </div>
        </div>
        <div>
            <md-button v-if="isOfficeStaff" class="md-primary btn-refresh" @click="showAllTripsOnMap">
                Show trips on map
            </md-button>
            <md-button class="md-primary btn-refresh" @click="getTripDashboard">
                Refresh dashboard stats
            </md-button>
        </div>
        <div class="md-layout">
            <div class="md-layout-item">
                <router-link
                    :to="{
                        name: 'Stops Overview',
                        query: {
                            status: 'All',
                            fromDate: selectedDate,
                            toDate: selectedDate,
                            teamRegionId: selectedTeamRegionId
                        }
                    }"
                    target="_blank"
                >
                    <stats-card header-color="primary" class="custom-stats-card">
                        <template slot="header">
                            <div class="card-icon custom-card-icon">
                                <md-icon>analytics</md-icon>
                            </div>
                            <p class="category">
                                Total Stops
                            </p>
                            <h3 class="title">
                                <div v-if="dashboardLoading" class="btn-loader"></div>
                                <span v-else>{{ dashboard.totalStops }}</span>
                            </h3>
                        </template>
                    </stats-card>
                </router-link>
            </div>
            <div class="md-layout-item">
                <router-link
                    :to="{
                        name: 'Stops Overview',
                        query: {
                            status: 'Active',
                            fromDate: selectedDate,
                            toDate: selectedDate,
                            teamRegionId: selectedTeamRegionId
                        }
                    }"
                    target="_blank"
                >
                    <stats-card header-color="blue" class="custom-stats-card">
                        <template slot="header">
                            <div class="card-icon custom-card-icon">
                                <md-icon>local_shipping</md-icon>
                            </div>
                            <p class="category">
                                Active Stops
                            </p>
                            <h3 class="title">
                                <div v-if="dashboardLoading" class="btn-loader"></div>
                                <span v-else>{{ dashboard.activeStops }}</span>
                            </h3>
                        </template>
                    </stats-card>
                </router-link>
            </div>
            <div class="md-layout-item">
                <router-link
                    :to="{
                        name: 'Stops Overview',
                        query: {
                            status: 'Complete',
                            fromDate: selectedDate,
                            toDate: selectedDate,
                            teamRegionId: selectedTeamRegionId
                        }
                    }"
                    target="_blank"
                >
                    <stats-card header-color="green" class="custom-stats-card">
                        <template slot="header">
                            <div class="card-icon custom-card-icon">
                                <md-icon>done</md-icon>
                            </div>
                            <p class="category">
                                Completed Stops
                            </p>
                            <h3 class="title">
                                <div v-if="dashboardLoading" class="btn-loader"></div>
                                <span v-else>{{ dashboard.completedStops }}</span>
                            </h3>
                        </template>
                    </stats-card>
                </router-link>
            </div>
            <div class="md-layout-item">
                <router-link
                    :to="{
                        name: 'Stops Overview',
                        query: {
                            status: 'Failed',
                            fromDate: selectedDate,
                            toDate: selectedDate,
                            teamRegionId: selectedTeamRegionId
                        }
                    }"
                    target="_blank"
                >
                    <stats-card header-color="warning" class="custom-stats-card">
                        <template slot="header">
                            <div class="card-icon custom-card-icon">
                                <md-icon>report_problem</md-icon>
                            </div>
                            <p class="category">
                                Failed Stops
                            </p>
                            <h3 class="title">
                                <div v-if="dashboardLoading" class="btn-loader"></div>
                                <span v-else>{{ dashboard.failedStops }}</span>
                            </h3>
                        </template>
                    </stats-card>
                </router-link>
            </div>
            <div class="md-layout-item">
                <router-link
                    :to="{
                        name: 'Stops Overview',
                        query: {
                            status: 'Cancelled',
                            fromDate: selectedDate,
                            toDate: selectedDate,
                            teamRegionId: selectedTeamRegionId
                        }
                    }"
                    target="_blank"
                >
                    <stats-card header-color="danger" class="custom-stats-card">
                        <template slot="header">
                            <div class="card-icon custom-card-icon">
                                <md-icon>cancel</md-icon>
                            </div>
                            <p class="category">
                                Cancelled Stops
                            </p>
                            <h3 class="title">
                                <div v-if="dashboardLoading" class="btn-loader"></div>
                                <span v-else>{{ dashboard.cancelledStops }}</span>
                            </h3>
                        </template>
                    </stats-card>
                </router-link>
            </div>
            <div class="md-layout-item">
                <router-link
                    :to="{
                        name: 'Stops Overview',
                        query: {
                            status: 'Active',
                            fromDate: selectedDate,
                            toDate: selectedDate,
                            teamMemberPublicUserId: 'Unassigned',
                            teamRegionId: selectedTeamRegionId
                        }
                    }"
                    target="_blank"
                >
                    <stats-card header-color="blue" class="custom-stats-card">
                        <template slot="header">
                            <div class="card-icon custom-card-icon">
                                <md-icon>person</md-icon>
                            </div>
                            <p class="category">
                                Unassigned Stops
                            </p>
                            <h3 class="title">
                                <div v-if="dashboardLoading" class="btn-loader"></div>
                                <span v-else>{{ dashboard.unassignedStops }}</span>
                            </h3>
                        </template>
                    </stats-card>
                </router-link>
            </div>
            <div class="md-layout-item">
                <router-link
                    :to="{
                        name: 'Stops Overview',
                        query: {
                            status: 'Complete',
                            fromDate: selectedDate,
                            toDate: selectedDate,
                            driverRating: 5,
                            teamRegionId: selectedTeamRegionId
                        }
                    }"
                    target="_blank"
                >
                    <stats-card header-color="default" class="custom-stats-card">
                        <template slot="header">
                            <div class="card-icon custom-card-icon">
                                <md-icon>star</md-icon>
                            </div>
                            <p class="category">
                                Average Driver Rating
                            </p>
                            <h3 class="title">
                                <div v-if="dashboardLoading" class="btn-loader"></div>
                                <span v-else>
                                    {{ dashboard.averageDriverRating == null ? '-' : dashboard.averageDriverRating }}
                                </span>
                            </h3>
                        </template>
                    </stats-card>
                </router-link>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item margin-space-bot" v-if="tripList.length">
                <md-list
                    :md-expand-single="expandSingle"
                    class="trip-list-body"
                    v-for="(tripItem, tripIndex) in tripList"
                    :key="tripIndex"
                >
                    <md-list-item md-expand :md-ripple="false" :md-expanded.sync="tripItem.isOpen">
                        <div
                            class="trip-accordion-header"
                            :class="tripItem.isOpen ? 'trip-open' : ''"
                            @click.stop="toggleTripItem(tripItem.user.publicUserId, tripItem.tripId)"
                        >
                            <div class="member-name">
                                <span
                                    class="custom-ellipsis"
                                    :class="tripItem.user.publicUserId || tripItem.carrierTeam ? '' : 'grey-text'"
                                >
                                    <span>
                                        {{ getDisplayName(tripItem) }}
                                    </span>
                                    <span
                                        class="vehicle-max-load-header"
                                        v-if="tripItem.vehicleLoad && tripItem.isOpen"
                                    >
                                        {{ getDisplayVehicleLoad(tripItem) }}
                                    </span>
                                    <br v-if="tripItem.vehicleLoad && tripItem.isOpen" />
                                    <span
                                        class="display-sub-name"
                                        :style="tripItem.vehicleLoad && tripItem.isOpen ? 'margin-left: 15px' : ''"
                                    >
                                        {{ getDisplaySubName(tripItem) }}
                                    </span>
                                </span>
                            </div>

                            <div class="completed-total">
                                <div>
                                    <span v-if="!isEmptyRunScheduleTrip(tripItem)">
                                        {{ `${tripItem.completedStops} of ${tripItem.totalStops} stops completed` }}
                                    </span>
                                </div>

                                <div>
                                    <trip-status-button
                                        :trip="tripItem"
                                        :is-modal="true"
                                        class="trip-status-button"
                                        @statusUpdated="onChangedTripStatus"
                                    />
                                    <span v-if="isAdmin" class="grey-lock" @click.stop="rebuildTrip(tripItem.tripId)">
                                        <md-icon>build</md-icon>
                                    </span>
                                    <span
                                        class="gold-lock"
                                        v-if="tripItem.isFixedStopOrder && !isCustomer && !isSupportStaff"
                                        @click.stop="unlockTrip(tripItem.tripId)"
                                    >
                                        <md-icon>lock</md-icon>
                                        <md-tooltip md-direction="left">
                                            The stops for this trip is in a fixed order. Click to unlock the stop order.
                                        </md-tooltip>
                                    </span>
                                    <span
                                        class="grey-lock"
                                        v-if="!tripItem.isFixedStopOrder && !isCustomer && !isSupportStaff"
                                        @click.stop="lockTrip(tripItem.tripId)"
                                    >
                                        <md-icon>lock_open</md-icon>
                                        <md-tooltip md-direction="left">
                                            The stops for this trip is not in a fixed order. Click to lock the stop
                                            order.
                                        </md-tooltip>
                                    </span>
                                    <span
                                        v-if="isAValidDate && !isReadOnlyUser"
                                        :class="setIconToDisable(!tripItem.isOpen, 'export-stops')"
                                        @click.stop="reverseOrder(tripItem.tripId)"
                                    >
                                        <md-icon>swap_vert</md-icon>
                                        <md-tooltip md-direction="left">Reverse the order of the stops.</md-tooltip>
                                    </span>
                                    <span
                                        :class="
                                            setIconToDisable(
                                                !(tripItem.isOpen && !tripItem.isStopListLoading),
                                                'export-stops'
                                            )
                                        "
                                        @click.stop="getStopList(tripItem.user.publicUserId, tripItem.tripId)"
                                    >
                                        <md-icon>refresh</md-icon>
                                        <md-tooltip md-direction="left">Refresh List</md-tooltip>
                                    </span>
                                    <span class="view-map">
                                        <router-link
                                            :to="{
                                                name: 'Trip Details',
                                                params: {
                                                    tripId: tripItem.tripId
                                                }
                                            }"
                                            target="_blank"
                                        >
                                            <md-icon>description</md-icon>
                                        </router-link>
                                        <md-tooltip md-direction="left">View trip details</md-tooltip>
                                    </span>
                                    <span
                                        v-if="!isReadOnlyUser"
                                        :class="setIconClassBasedOnTripState(tripItem, 'export-stops')"
                                        @click.stop="sendBulkSMS(tripItem.user.publicUserId)"
                                    >
                                        <md-icon>mobile_screen_share</md-icon>
                                        <md-tooltip md-direction="left">Send Bulk SMS</md-tooltip>
                                    </span>
                                    <span
                                        v-if="!isReadOnlyUser"
                                        class="export-stops"
                                        @click.stop="exportTrips(tripItem.tripId, tripItem.user.fullName)"
                                    >
                                        <md-icon>arrow_circle_down</md-icon>
                                        <md-tooltip md-direction="left">Export stops of this trip</md-tooltip>
                                    </span>
                                    <span :class="setIconToDisable(!tripItem.user.publicUserId, 'view-map')">
                                        <router-link
                                            :to="{
                                                name: 'Maps Overview Active User Panel',
                                                params: {
                                                    date: selectedDate,
                                                    userId: tripItem.user.publicUserId
                                                },
                                                query: { from: 'trip' }
                                            }"
                                            target="_blank"
                                        >
                                            <md-icon>map</md-icon>
                                        </router-link>
                                        <md-tooltip md-direction="left">View stops in a map</md-tooltip>
                                    </span>
                                    <span
                                        v-if="!isReadOnlyUser"
                                        class="export-stops"
                                        @click.stop="updateTrip(tripItem.tripId)"
                                    >
                                        <md-icon>edit</md-icon>
                                        <md-tooltip md-direction="left">Edit Trip</md-tooltip>
                                    </span>
                                    <span
                                        class="export-stops"
                                        @click.stop="reassignTrip(tripItem.tripId)"
                                        v-if="
                                            !isSingleTeamMember &&
                                                canReassignTrip &&
                                                teamMembers.length &&
                                                isAValidDate &&
                                                !isSupportStaff
                                        "
                                    >
                                        <md-icon>person</md-icon>
                                        <md-tooltip md-direction="left">Reassign Trip</md-tooltip>
                                    </span>
                                    <send-sms-button
                                        v-if="tripItem.user.publicUserId && !isSupportStaff"
                                        :class="setIconToDisable(!tripItem.user.publicUserId, 'export-stops')"
                                        :public-user-id="tripItem.user.publicUserId"
                                        :name="tripItem.user.fullName"
                                    />
                                    <generate-label-button
                                        v-if="tripItem.totalStops > 0 && !isSupportStaff"
                                        :class="setIconToDisable(tripItem.totalStops <= 0, 'export-stops')"
                                        :id="tripItem.tripId"
                                        :type="'Trip'"
                                    />
                                    <span
                                        :class="setIconClassBasedOnTripState(tripItem, 'export-stops')"
                                        @click.stop="createRunSchedule(tripItem.tripId)"
                                        v-if="
                                            !isSingleTeamMember &&
                                                !isIndividualUser &&
                                                hasTeamAccess &&
                                                teamMembers.length
                                        "
                                    >
                                        <md-icon>more_time</md-icon>
                                        <md-tooltip md-direction="left">Create Run Schedule</md-tooltip>
                                    </span>

                                    <div
                                        class="progress-bar"
                                        :class="getProgressBarColor(tripItem.completedStops, tripItem.totalStops)"
                                    >
                                        <md-progress-bar
                                            md-mode="determinate"
                                            :md-value="
                                                calculateProgressBarValue(tripItem.completedStops, tripItem.totalStops)
                                            "
                                        ></md-progress-bar>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <md-list slot="md-expand" class="stop-table-body">
                            <md-table
                                v-if="tripItem.stopList.length > 0"
                                class="context-menu-support custom-paginated-table"
                            >
                                <div class="alert-section" v-if="tripItem.isShowAlert">
                                    <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                        <div>
                                            <div>
                                                <strong>ATTENTION!</strong>
                                                {{ tripItem.alertMessage }}
                                                <span
                                                    v-if="tripItem.showRebuild"
                                                    class="rebuild-link"
                                                    @click.stop="rebuildTrip(tripItem.tripId)"
                                                >
                                                    Click here to rebuild route.
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <md-button
                                                v-if="tripItem.alertType === 'save'"
                                                title="Save new order of stops"
                                                class="md-primary md-just-icon md-round"
                                                @click.stop="saveConfiguration(tripItem.tripId)"
                                            >
                                                <md-icon>save</md-icon>
                                            </md-button>
                                            <md-button
                                                v-else-if="tripItem.alertType === 'refresh'"
                                                title="Save new order of stops"
                                                class="md-primary md-just-icon md-round"
                                                @click.stop="getStopList(tripItem.user.publicUserId, tripItem.tripId)"
                                            >
                                                <md-icon>refresh</md-icon>
                                            </md-button>
                                        </div>
                                    </div>
                                </div>
                                <draggable
                                    v-model="tripItem.stopList"
                                    draggable=".drag-item"
                                    class="custom-draggable"
                                    :disabled="!isAValidDate || tripItem.user.publicUserId == null || isReadOnlyUser"
                                    :class="[
                                        isAValidDate ? 'show-draggable' : '',
                                        tripItem.isFixedStopOrder ? 'has-actual-order' : ''
                                    ]"
                                    @change="stopOrderChanged(tripItem.tripId)"
                                    :move="checkMove"
                                    @end="endDrag"
                                >
                                    <md-table-row slot="header">
                                        <md-table-head
                                            v-if="isAValidDate && tripItem.user.publicUserId != null"
                                            class="drag-icon"
                                        ></md-table-head>
                                        <md-table-head class="stop-order"></md-table-head>
                                        <md-table-head
                                            v-if="tripItem.isFixedStopOrder || tripItem.hasRuns"
                                            class="stop-order actual-stop-order"
                                        >
                                            Planned
                                        </md-table-head>
                                        <md-table-head
                                            v-if="tripItem.hasStopAlerts"
                                            class="stop-alerts"
                                        ></md-table-head>
                                        <TableHeaderDropdown
                                            class="stop-reference"
                                            column-icon="keyboard_arrow_down"
                                            :default-text="tableColumnOptions.columns[columnKeys.referenceSource].name"
                                            :dropdown-options="tableColumnKeyOptions.referenceSource"
                                            :selected-option="tableColumnOptions.columns[columnKeys.referenceSource]"
                                            @selectedOption="
                                                $_handleColumnSwitch(
                                                    $event,
                                                    columnKeys.referenceSource,
                                                    'trips-overview.defaults.stop-table-options'
                                                )
                                            "
                                        />
                                        <md-table-head class="stop-contact">Contact</md-table-head>
                                        <md-table-head class="stop-address">Address</md-table-head>
                                        <md-table-head class="stop-status">Status</md-table-head>
                                        <md-table-head class="delivery-time">Delivery Time</md-table-head>
                                        <md-table-head class="stop-time">ETA/Arrival</md-table-head>
                                        <md-table-head
                                            class="stop-load-header"
                                            v-if="
                                                user.vehicleCapacityUnitsConfiguration != null &&
                                                    user.vehicleCapacityUnitsConfiguration.length > 0
                                            "
                                        >
                                            Load {{ selectedCapacitySymbol ? `(${selectedCapacitySymbol})` : '' }}
                                        </md-table-head>
                                        <th
                                            class="md-table-head running-load-head"
                                            v-if="
                                                user.vehicleCapacityUnitsConfiguration != null &&
                                                    user.vehicleCapacityUnitsConfiguration.length > 0
                                            "
                                        >
                                            <div class="md-table-head-container running-load-menu">
                                                <drop-down direction="down">
                                                    <a href="#" class="load-header-text">
                                                        <md-tooltip md-direction="top" class="toggle-tooltip">
                                                            {{ selectedViewType }} ({{ selectedCapacityLabel }})
                                                        </md-tooltip>
                                                        {{ loadHeaderText }}
                                                        {{
                                                            selectedCapacitySymbol ? `(${selectedCapacitySymbol})` : ''
                                                        }}
                                                        <md-icon class="icon-arrow">expand_more</md-icon>
                                                    </a>
                                                    <ul class="dropdown-menu">
                                                        <li
                                                            v-for="(vehicleCapacity,
                                                            index) in user.vehicleCapacityUnitsConfiguration"
                                                            :key="index"
                                                        >
                                                            <md-icon
                                                                :class="[
                                                                    'icon-check',
                                                                    selectedCapacityType === vehicleCapacity.type
                                                                        ? 'icon-check-color'
                                                                        : ''
                                                                ]"
                                                            >
                                                                check
                                                            </md-icon>
                                                            <a
                                                                href="#"
                                                                @click="changeCapacityType(vehicleCapacity.type)"
                                                            >
                                                                {{ vehicleCapacity.label }}
                                                            </a>
                                                        </li>
                                                        <hr class="border" />
                                                        <li
                                                            v-for="(viewType, viewindex) in viewTypes"
                                                            :key="'v' + viewindex"
                                                        >
                                                            <md-icon
                                                                :class="[
                                                                    'icon-check',
                                                                    selectedViewType === viewType
                                                                        ? 'icon-check-color'
                                                                        : ''
                                                                ]"
                                                            >
                                                                check
                                                            </md-icon>
                                                            <a href="#" @click="changeSelectViewType(viewType)">
                                                                {{ viewType }}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </drop-down>
                                            </div>
                                        </th>
                                        <md-table-head class="stop-time">Actions</md-table-head>
                                    </md-table-row>

                                    <md-table-row v-for="(stopItem, stopIndex) in getStopListItems(tripItem)"
                                                  :key="stopIndex"
                                                  :class="[
                                            stopItem.status != 'Complete' &&
                                            stopItem.status != 'Cancelled' &&
                                            stopItem.status != 'Arrived' &&
                                            stopItem.status != 'Failed' &&
                                            stopItem.status != 'On Hold' &&
                                            !stopItem.isRunHeader &&
                                            !stopItem.isStartStop &&
                                            !stopItem.isEndStop &&
                                            tripItem.isSortable
                                                ? 'drag-item'
                                                : '',
                                            stopItem.isStartStop || stopItem.isEndStop ? 'trip-status' : ''
                                        ]">
                                        <md-table-cell class="stop-run-header" v-if="stopItem.isRunHeader" colspan="12">
                                            <span v-if="stopItem.runName">Run {{ stopItem.runName }}</span>
                                        </md-table-cell>
                                        <md-table-cell class="drag-icon"
                                                       v-if="
                                                isAValidDate &&
                                                    tripItem.user.publicUserId != null &&
                                                    !stopItem.isRunHeader
                                            ">
                                            <md-icon v-if="
                                                    stopItem.status != 'Complete' &&
                                                        stopItem.status != 'Cancelled' &&
                                                        stopItem.status != 'Arrived' &&
                                                        stopItem.status != 'Started' &&
                                                        stopItem.status != 'Failed' &&
                                                        stopItem.status != 'On Hold' &&
                                                        !stopItem.isEndStop &&
                                                        tripItem.isSortable
                                                ">
                                                drag_indicator
                                            </md-icon>
                                        </md-table-cell>
                                        <md-table-cell class="stop-order" v-if="!stopItem.isRunHeader">
                                            <span v-if="
                                                    !stopItem.isRunHeader &&
                                                        !stopItem.isStartStop &&
                                                        !stopItem.isEndStop &&
                                                        ((stopItem.status !== 'Complete' &&
                                                            stopItem.status !== 'Cancelled' &&
                                                            stopItem.status !== 'Failed' &&
                                                            stopItem.status != 'On Hold') ||
                                                            tripItem.isFixedStopOrder)
                                                ">
                                                {{ stopItem.stopIndex }}
                                            </span>
                                            <md-icon v-if="stopItem.isStartStop">pin_drop</md-icon>
                                            <md-icon v-if="stopItem.isEndStop">flag</md-icon>
                                        </md-table-cell>
                                        <md-table-cell class="stop-order actual-stop-order"
                                                       v-if="
                                                (tripItem.isFixedStopOrder || tripItem.hasRuns) && !stopItem.isRunHeader
                                            ">
                                            <span>{{ stopItem.index }}</span>
                                        </md-table-cell>
                                        <md-table-cell v-if="tripItem.hasStopAlerts && !stopItem.isRunHeader"
                                                       class="stop-alerts">
                                            <md-tooltip md-direction="top"
                                                        v-if="stopItem.importErrors && stopItem.importErrors.length > 0">
                                                <ul>
                                                    <li v-for="(importError, importErrorIndex) in stopItem.importErrors"
                                                        :key="importErrorIndex">
                                                        {{ importError }}
                                                    </li>
                                                </ul>
                                            </md-tooltip>
                                            <md-icon v-if="stopItem.importErrors && stopItem.importErrors.length > 0"
                                                     class="icon-warning">
                                                warning
                                            </md-icon>
                                        </md-table-cell>
                                        <md-table-cell class="stop-reference" v-if="!stopItem.isRunHeader">
                                            <router-link :to="{
                                                    name: 'Stop Details',
                                                    params: { stopId: stopItem.stopId }
                                                }"
                                                         target="_blank"
                                                         v-if="!stopItem.driverBreakId">
                                                {{
                                                    tableColumnOptions.columns[columnKeys.referenceSource].key ===
                                                        'reference-number' ||
                                                    stopItem.sourceReference === null ||
                                                    stopItem.sourceReference === ''
                                                        ? stopItem.stopRef
                                                        : stopItem.sourceReference
                                                }}
                                            </router-link>
                                            <div v-if="stopItem.driverBreakId > 0" style="text-align:center">
                                                <md-icon class="icon-break" :class="
                                                        `status-break-${stopItem.status.toLowerCase().replace(/\s+/g, '')}`
                                                    ">
                                                    local_cafe
                                                </md-icon>
                                            </div>
                                        </md-table-cell>
                                        <md-table-cell class="stop-contact" v-if="!stopItem.isRunHeader">
                                            <span class="custom-ellipsis" v-overflow-text-tooltip>
                                                {{ stopItem.contact != null ? stopItem.contact.name : '' }}
                                            </span>
                                        </md-table-cell>
                                        <md-table-cell class="stop-address" v-if="!stopItem.driverBreakId && !stopItem.isRunHeader">
                                            <GeocodingIndicator :address-components="stopItem.addressComponents" />
                                            <span class="custom-ellipsis" v-overflow-text-tooltip>
                                                {{ $_displayAddressName(stopItem) }}
                                            </span>
                                        </md-table-cell>
                                        <md-table-cell class="break-address" v-if="stopItem.driverBreakId > 0 && !stopItem.isRunHeader">
                                            <span v-overflow-text-tooltip>
                                                -- Scheduled Break --
                                            </span>
                                        </md-table-cell>
                                        <md-table-cell class="stop-status" v-if="!stopItem.isRunHeader">
                                            <stop-status-button v-if="!stopItem.driverBreakId && !stopItem.isStartStop && !stopItem.isEndStop"
                                                                :stop="stopItem"
                                                                @refreshTripList="getTripList"
                                                                @statusUpdated="onChangedStopStatus" />
                                            <div class="dropdown">
                                                <span class="status-break"
                                                      v-if="stopItem.driverBreakId > 0"
                                                      :class="`status-${stopItem.status.toLowerCase().replace(/\s+/g, '')}`">
                                                    {{ stopItem.status }}
                                                </span>
                                            </div>
                                        </md-table-cell>
                                        <md-table-cell class="delivery-time" v-if="!stopItem.isRunHeader">
                                            <div :class="[!stopItem.driverBreakId ? '' : 'break-eta']" v-if="!stopItem.isStartStop && !stopItem.isEndStop">
                                                <span v-if="
                                                        stopItem.timeWindowStart != null &&
                                                            stopItem.timeWindowEnd != null
                                                    ">
                                                    {{ stopItem.timeWindowStart | timeFormat(DATE_TYPES.standardTime) }}
                                                    -
                                                    {{ stopItem.timeWindowEnd | timeFormat(DATE_TYPES.standardTime) }}
                                                </span>
                                                <span v-else-if="stopItem.appointmentTime != null">
                                                    {{ stopItem.appointmentTime | timeFormat(DATE_TYPES.standardTime) }}
                                                </span>
                                                <span v-else>-</span>
                                            </div>
                                        </md-table-cell>
                                        <md-table-cell class="stop-time" v-if="!stopItem.isRunHeader">
                                            <div :class="[!stopItem.driverBreakId ? '' : 'break-eta']" v-if="stopItem.status != 'Cancelled' && stopItem.status != 'On Hold'">
                                                <span v-if="stopItem.actualDate" class="bold-time">
                                                    {{ stopItem.actualDate | timeFormat(DATE_TYPES.standardTime) }}
                                                </span>
                                                <span v-else-if="
                                                        stopItem.status == 'Arrived' ||
                                                            stopItem.status == 'Complete' ||
                                                            stopItem.status == 'Failed'
                                                    "
                                                      class="bold-time">
                                                    {{ stopItem.arrivalDate | timeFormat(DATE_TYPES.standardTime) }}
                                                </span>
                                                <span v-else>
                                                    <span v-if="stopItem.originalEta">
                                                        {{ stopItem.originalEta | timeFormat(DATE_TYPES.standardTime) }}
                                                    </span>
                                                    <div v-else-if="!stopItem.originalEta && isAValidDate"
                                                         class="eta-loader-container">
                                                        <div class="eta-loader" v-if="showETAloader(tripItem)"></div>
                                                    </div>
                                                </span>
                                            </div>
                                        </md-table-cell>
                                        <md-table-cell class="stop-load"
                                                       v-if="
                                                       !stopItem.isRunHeader &&
                                                       user.vehicleCapacityUnitsConfiguration !=null &&
                                                       user.vehicleCapacityUnitsConfiguration.length>
                                            0
                                            "
                                            >
                                            {{ getStopLoad(stopItem) }}
                                        </md-table-cell>
                                        <md-table-cell class="vehicle-load"
                                                       v-if="
                                                       !stopItem.isRunHeader &&
                                                       user.vehicleCapacityUnitsConfiguration !=null &&
                                                       user.vehicleCapacityUnitsConfiguration.length>
                                            0
                                            "
                                            >
                                            {{ getVehicleCurrentLoadOrAvailableCapacity(stopItem) }}
                                        </md-table-cell>
                                        <md-table-cell class="stop-actions" v-if="!stopItem.isRunHeader">
                                            <reassign-stop-button v-if="
                                                    !stopItem.isRunHeader &&
                                                        stopItem.status != 'Complete' &&
                                                        stopItem.status != 'Cancelled' &&
                                                        stopItem.status != 'Failed' &&
                                                        !stopItem.isStartStop &&
                                                        !stopItem.isEndStop &&
                                                        !isIndividualUser &&
                                                        !isSingleTeamMember &&
                                                        !isCustomer &&
                                                        !isSupportStaff
                                                "
                                                                  type="icon"
                                                                  :item="stopItem"
                                                                  :team-members="teamMembers"
                                                                  @assignedUser="onAssignedUser" />
                                            <div class="custom-button-container"
                                                 v-if="
                                                    !stopItem.isRunHeader &&
                                                        stopItem.status != 'Complete' &&
                                                        stopItem.status != 'Cancelled' &&
                                                        stopItem.status != 'Failed' &&
                                                        !stopItem.isStartStop &&
                                                        !stopItem.isEndStop &&
                                                        !isIndividualUser &&
                                                        !isSingleTeamMember &&
                                                        !isSupportStaff
                                                "
                                            >
                                                <md-button v-if="!stopItem.driverBreakId"
                                                    title="Remove stop from trip"
                                                    class="md-danger md-just-icon md-round"
                                                    @click.stop="
                                                        handleRemoveStop(
                                                            stopItem.stopId,
                                                            tripItem.tripId,
                                                            stopItem.shipmentId != null
                                                        )
                                                    ">
                                                    <md-icon>
                                                        <img class="delete-icon" src="/img/icons/remove-booking-icon.svg" />
                                                    </md-icon>
                                                </md-button>
                                            </div>
                                            <div class="custom-button-container"
                                                 v-if="
                                                    stopItem.runNumber &&
                                                        stopItem.index &&
                                                        stopItem.index.toString().endsWith('.1')
                                                ">
                                                <span class="gold-lock"
                                                      v-if="stopItem.isFixedRunStop"
                                                      @click.stop="unlockRun(tripItem.tripId, stopItem.runNumber)">
                                                    <md-icon>lock</md-icon>
                                                    <md-tooltip md-direction="left">
                                                        The stops for this run are in a fixed order. Click to unlock the
                                                        stop order.
                                                    </md-tooltip>
                                                </span>
                                                <span class="grey-lock"
                                                      v-else
                                                      @click.stop="lockRun(tripItem.tripId, stopItem.runNumber)">
                                                    <md-icon>lock_open</md-icon>
                                                    <md-tooltip md-direction="left">
                                                        The stops for this run do not have a fixed order. Click to lock
                                                        the stop order.
                                                    </md-tooltip>
                                                </span>
                                            </div>
                                        </md-table-cell>
                                    </md-table-row>
                                    <template v-if="!isCustomer && !isCustomerAdmin">
                                        <md-table-row class="trip-status">
                                            <md-table-cell :colspan="isAValidDate ? 7 : 6">
                                                <div class="total-trip-time-labels">
                                                    <div class="total-trip-time-text">
                                                        Estimated Trip Duration:
                                                    </div>
                                                    <div>
                                                        Actual Trip Duration:
                                                    </div>
                                                </div>
                                            </md-table-cell>
                                            <md-table-cell colspan="4">
                                                <template v-if="tripItem.totalTripTime">
                                                    <div class="total-trip-time-text">
                                                        {{ tripItem.totalTripTime.tripStartEstimatedRangeFormatted }}
                                                        <span
                                                            v-html="
                                                                tripItem.totalTripTime.tripStartEstimatedHoursFormatted
                                                            "
                                                        ></span>
                                                    </div>
                                                    <div>
                                                        {{ tripItem.totalTripTime.actualRangeFormatted }}
                                                        <span
                                                            v-html="tripItem.totalTripTime.actualHoursFormatted"
                                                        ></span>
                                                    </div>
                                                </template>
                                            </md-table-cell>
                                        </md-table-row>
                                    </template>
                                </draggable>
                            </md-table>
                            <div v-else class="empty-table">
                                <div class="table-loader" v-if="tripItem.isStopListLoading">
                                    <fade-loader :loading="true" color="#333333" />
                                    <span>LOADING</span>
                                </div>
                                <div v-else>
                                    <div
                                        v-if="
                                            tripItem.startAddress &&
                                                !tripItem.isStopListLoading &&
                                                tripItem.stopList.length <= 0
                                        "
                                    >
                                        <md-table class="context-menu-support custom-paginated-table">
                                            <md-table-row slot="header">
                                                <md-table-head />
                                                <md-table-head class="start-address-trip">Address</md-table-head>
                                            </md-table-row>
                                            <md-table-row>
                                                <md-table-cell class="start-address-cell">
                                                    <md-icon>pin_drop</md-icon>
                                                </md-table-cell>
                                                <md-table-cell class="start-address-cell">
                                                    <span class="custom-ellipsis" v-overflow-text-tooltip>
                                                        {{ tripItem.startAddress }}
                                                    </span>
                                                </md-table-cell>
                                            </md-table-row>
                                        </md-table>
                                    </div>
                                    <span v-else>No stops available for this trip</span>
                                </div>
                            </div>
                        </md-list>
                    </md-list-item>
                </md-list>
            </div>
            <div class="md-layout-item margin-space-bot" v-else>
                <div class="empty-message" v-if="!isLoading">
                    <span class="text-center">No Trips available.</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { STOP_TABLE_HEADER_DISPLAY_OPTION } from '@/utils/defaults';
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { DATE_TYPES, REFERENCE_SOURCE_KEY_OPTIONS } from '@/utils/constants';
import { StopStatusButton, ReassignStopButton } from '@/pages/Stops/buttons';
import { handleRequests, showErrorMessage } from '@/helpers';
import {
    SendSmsButton,
    GenerateLabelButton,
    GeocodingIndicator,
    StatsCard,
    TableHeaderDropdown,
    SearchComponent
} from '@/components';
import TripStatusButton from './Buttons/TripStatusButton';
import SendBulkSms from './Modals/SendBulkSms';
import UpdateTripModal from './Modals/UpdateTripModal';
import ReassignTripModal from './Modals/ReassignTripModal';
import TripFilterComponent from './Components/TripFilterComponent';
import CreateRunScheduleModal from './Modals/CreateRunScheduleModal';

const signalR = require('@aspnet/signalr');

const getTripEtaPollingInterval = (totalStops) => {
    // Simple interval scaling: the more stops, presumably the longer it takes
    // to be fetched from the backend, so the longer the interval should be set
    let interval = 20000;

    if (totalStops < 200) {
        interval = 10000;
    } else if (totalStops < 400) {
        interval = 12000;
    } else if (totalStops < 600) {
        interval = 14000;
    } else if (totalStops < 800) {
        interval = 16000;
    } else if (totalStops < 1000) {
        interval = 18000;
    }

    return interval;
};

export default {
    name: 'TripsOverview',
    mixins: [GeneralMixin],
    components: {
        StopStatusButton,
        FadeLoader,
        ReassignStopButton,
        TripStatusButton,
        TripFilterComponent,
        SendSmsButton,
        GenerateLabelButton,
        GeocodingIndicator,
        StatsCard,
        TableHeaderDropdown,
        SearchComponent
    },
    data() {
        return {
            selectedDate: this.$route.params.date
                ? this.$route.params.date
                : moment().format(DATE_TYPES.internationalDate),
            selectedTeamRegionId: null,
            selectedCustomerId: null,
            selectedStatus: '',
            selectedTeamMemberPublicUserId: null,
            tripList: [],
            isToday: true,
            dateToday: moment().format(DATE_TYPES.internationalDate),
            isAValidDate: true, // if true allows sorting and reassigning functionality
            tripListPolling: null,
            expandNews: false,
            expandSingle: false,
            alertSaveMessage: 'Please click the save button in order to save the changes made.',
            alertRefreshMessage: 'Please click the refresh button to update the sorting of the list.',
            alertEtaMessage: 'The trip is still being re-routed. Please wait...',
            teamMembers: [],
            selectedTeamMember: '',
            isAdmin: Boolean(this.$route.query.isAdmin),
            updatedTripList: [],
            isValidStopReOrder: false,
            isStartOrEndLocation: false,
            loadHeaderText: 'CL',
            selectedViewType: 'Current Load',
            selectedCapacityType: null,
            viewTypes: ['Current Load', 'Available Capacity'],
            selectedCapacityLabel: null,
            selectedCapacitySymbol: null,
            connection: '',
            dashboard: {
                totalStops: 0,
                completedStops: 0,
                activeStops: 0,
                cancelledStops: 0,
                failedStops: 0,
                averageDriverRating: 0,
                unassignedStops: 0
            },
            ...STOP_TABLE_HEADER_DISPLAY_OPTION(),
            dashboardLoading: false,
            currentSearchTextValue: null
        };
    },
    beforeDestroy() {
        clearInterval(this.tripListPolling);
    },
    async created() {
        this.$_applyColumnSwitchDefaults('trips-overview.defaults.stop-table-options', {
            [this.columnKeys.referenceSource]: REFERENCE_SOURCE_KEY_OPTIONS
        });
    },
    async mounted() {
        // if (this.canEditOwnTeamRegion) {
        //     this.selectedTeamRegionId = this.user.teamRegionId ? this.user.teamRegionId : null; // 'null = All'
        // } else {
        //     this.selectedTeamRegionId = this.user.teamRegionId ? this.user.teamRegionId : -1; // '-1 = Not Set'
        // }

        if (
            this.user.vehicleCapacityUnitsConfiguration != null &&
            this.user.vehicleCapacityUnitsConfiguration.length > 0
        ) {
            this.selectedCapacityType = this.user.vehicleCapacityUnitsConfiguration[0].type;
            this.selectedCapacityLabel = this.user.vehicleCapacityUnitsConfiguration[0].label;
            this.selectedCapacitySymbol = this.user.vehicleCapacityUnitsConfiguration[0].symbol;
        }
        this.selectedTeamRegionId = null; // 'null = All'
        const isAdminQueryString = this.$route.query.isAdmin ? '?isAdmin=true' : '';
        this.$router.replace({ path: `/trips/list/${this.selectedDate}${isAdminQueryString}` });

        if (!this.$root.isDesktop) {
            if (this.$refs.mdDatePicker) 
                this.$refs.mdDatePicker.$refs.input.$el.setAttribute('readonly', true);
        }

        this.getTeamMembers();
        this.isDateValid();
        this.$_handleLoaderState(true);
        await this.getTripList();
        this.getTripDashboard();
        this.$_handleLoaderState(false);
        await this.setupSignalR();
        this.getTripDashboard();
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isLoading: 'isLoading',
            isIndividualUser: 'user/isIndividualUser',
            canReassignTrip: 'user/canReassignTrip',
            hasTeamAccess: 'user/hasTeamAccess',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isReadOnlyUser: 'user/isReadOnlyUser',
            hasElevatedTeamRegionFilter: 'user/hasElevatedTeamRegionFilter',
            canEditOwnTeamRegion: 'user/canEditOwnTeamRegion',
            isCustomer: 'user/isCustomer',
            isCustomerAdmin: 'user/isCustomerAdmin',
            isOfficeStaff: 'user/isOfficeStaff',
            isSupportStaff: 'user/isSupportStaff'
        })
    },
    watch: {
        selectedDate(newValue) {
            this.$router.replace({ path: `/trips/list/${newValue}` });
        }
    },
    methods: {
        roundNumber(number) {
            return Math.round((number + Number.EPSILON) * 100) / 100;
        },
        async getTeamMembers() {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS');
        },
        isDateValid() {
            clearInterval(this.tripListPolling);
            this.isAValidDate = true;
            if (moment(this.selectedDate).isBefore(this.dateToday)) {
                this.isAValidDate = false;
            }
            if (moment(this.selectedDate).isSame(this.dateToday)) {
                this.tripListPolling = setInterval(() => {
                    this.refreshList();
                }, 60000);
            }
        },
        isEmptyRunScheduleTrip(tripItem) {
            return tripItem.setAsEmptyByRunSchedule && !tripItem.hasStopsOtherThanStartAndEnd;
        },
        async getTripList() {
            const qry = new URLSearchParams();
            qry.append('elevatedTeamRegionFilter', this.hasElevatedTeamRegionFilter);
            if (this.selectedTeamRegionId) {
                qry.append('teamRegionId', this.selectedTeamRegionId);
            }
            if (this.selectedTeamMemberPublicUserId) {
                qry.append('teamMemberPublicUserId', this.selectedTeamMemberPublicUserId);
            }
            if (this.selectedStatus !== 'All Statuses') {
                qry.append('statusFilter', this.selectedStatus);
            } else 
                qry.append('statusFilter', '');
            if (this.currentSearchTextValue) {
                qry.append('searchText', this.currentSearchTextValue);
            }
            if (this.selectedCustomerId) {
                qry.append('customerId', this.selectedCustomerId);
            }

            const api = `/api/trips/${this.selectedDate}/list?${qry}`;

            try {
                const { data } = await handleRequests(api);
                this.updatedTripList = [];
                data.forEach((trip) => {
                    const existingTrip = this.getTripDetails(trip.tripId);
                    this.updatedTripList.push(trip.tripId);

                    if (existingTrip != null) {
                        Object.assign(existingTrip, trip);
                        existingTrip.showRebuild = false;
                    } else {
                        trip.stopList = [];
                        trip.isOpen = false;
                        trip.isShowAlert = false;
                        trip.isSortable = true;
                        trip.isStopListLoading = false;
                        trip.alertType = null;
                        trip.alertMessage = this.alertSaveMessage;
                        trip.showRebuild = false;
                        trip.customerName = trip.customerName || '';
                        trip.rateGroupName = trip.rateGroupName || '';
                        this.tripList.push(trip);
                    }
                });
            } catch (error) {
                const message = 'Error in loading the trip list';
                showErrorMessage(message, error);
            }
        },
        async sendRunSheetPdfToEmail() {
            const trips = this.tripList.map((x) => {
                return x.tripId;
            });

            const api = `/api/trips/${this.selectedDate}/send-run-sheet-email`;
            const payload = {
                method: 'post',
                data: trips
            };

            this.$_handleLoaderState(true, 'PROCESSING...');

            try {
                await handleRequests(api, payload);

                this.$notify({
                    message: 'Successfully sent the run sheet to your email.',
                    type: 'success'
                });
            } catch (e) {
                const message = 'Error in sending run sheet.';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
        },
        includeTripStartEnd(stopList, data) {
            if (stopList.length === 0) 
                return;

            const assignedUser = stopList[0].assignedTo;
            const startTripStop = {
                stopId: 0,
                stopRef: '',
                actualDate: data.actualStartTime,
                originalEta: data.locationDetails.startEta,
                location: data.locationDetails.startLocation,
                address: data.locationDetails.startAddress,
                assignedTo: assignedUser,
                isStartStop: true,
                status: '',
                type: 'Start Location'
            };
            stopList.splice(0, 0, startTripStop);
            const endTripStop = {
                stopId: 0,
                stopRef: '',
                actualDate: data.actualEndTime,
                originalEta: data.locationDetails.endEta,
                location: data.locationDetails.endLocation,
                address: data.completedDate
                    ? 'Completed Date'
                    : data.locationDetails.endAddress || 'Estimated Completion',
                assignedTo: assignedUser,
                isEndStop: true,
                status: '',
                type: 'End Location'
            };
            stopList.splice(stopList.length, 0, endTripStop);
        },
        getStopListItems(tripItem) {
            return !this.isEmptyRunScheduleTrip(tripItem)
                ? tripItem.stopList
                : tripItem.stopList.filter((x) => x.type === 'Start Location' || x.type === 'End Location');
        },
        getTripDetails(tripId) {
            let trip = null;
            if (this.tripList.length > 0) {
                trip = this.tripList.find((x) => x.tripId === tripId);
            }
            return trip;
        },
        toggleTripItem(userId, tripId) {
            const trip = this.getTripDetails(tripId);
            trip.isOpen = !trip.isOpen;
            if (trip.isOpen) {
                this.refreshTripStops(trip, trip.isFixedStopOrder, false, false);
            }
        },
        async getTripDashboard() {
            if (this.dashboardLoading) 
                return;
            this.dashboardLoading = true;
            const qry = new URLSearchParams();
            qry.append('elevatedTeamRegionFilter', this.hasElevatedTeamRegionFilter);
            if (this.selectedTeamRegionId) {
                qry.append('teamRegionId', this.selectedTeamRegionId);
            }
            if (this.selectedTeamMemberPublicUserId) {
                qry.append('teamMemberPublicUserId', this.selectedTeamMemberPublicUserId);
            }
            if (this.currentSearchTextValue) {
                qry.append('searchText', this.currentSearchTextValue);
            }

            if (this.selectedStatus !== 'All Statuses') {
                qry.append('statusFilter', this.selectedStatus);
            } else 
                qry.append('statusFilter', '');

            if (this.selectedCustomerId) {
                qry.append('customerId', this.selectedCustomerId);
            }

            const api = `/api/trips/${this.selectedDate}/dashboard?${qry}`;

            const { data } = await handleRequests(api);
            this.dashboardLoading = false;
            this.dashboard = Object.assign({}, data);
        },
        async getStopList(userId, tripId, retryUntilRoutingComplete = false, showLoader = true) {
            const trip = this.getTripDetails(tripId);

            if (!trip || trip === undefined) 
                return;

            const qry = new URLSearchParams();
            qry.append('retryUntilRoutingComplete', retryUntilRoutingComplete);
            qry.append('teamRegionId', this.selectedTeamRegionId ? this.selectedTeamRegionId : '');
            qry.append('searchText', this.currentSearchTextValue ? this.currentSearchTextValue : '');

            trip.isSortable = true;
            trip.isShowAlert = false;
            trip.alertType = null;

            if (showLoader) {
                trip.isStopListLoading = true;
                trip.stopList = [];
            }

            let api = `/api/trips/${this.selectedDate}?userId=${userId}&${qry}`;

            if (userId == null) {
                api = `/api/trips/${trip.tripId}/unassigned?${qry}`;
            }

            try {
                const {
                    data: { data }
                } = await handleRequests(api);
                trip.status = data.status;
                trip.stopList = data.stopList;
                this.calculateStopIndex(trip);
                await this.setTripRunHeader(trip);
                trip.vehicleLoad = data.vehicleLoad;
                trip.userCapacity = data.userCapacity;
                trip.totalStops = trip.stopList.filter((x) => !x.driverBreakId && x.status !== 'Cancelled' && !x.isRunHeader).length;
                trip.completedStops = trip.stopList.filter(
                    (x) => x.status === 'Complete' || x.status === 'Failed'
                ).length;
                const stopsWithoutETA = trip.stopList.filter((x) => x.status === 'Pending' && x.originalEta === null);
                trip.hasStopAlerts =
                    trip.stopList.filter((x) => x.importErrors && x.importErrors.length > 0).length > 0;

                if (stopsWithoutETA.length > 0 && !moment(this.selectedDate).isBefore(this.dateToday)) {
                    if (trip.user.publicUserId != null) {
                        trip.isShowAlert = true;
                        trip.alertType = 'eta';
                        trip.alertMessage = this.alertEtaMessage;
                        trip.showRebuild = true;
                    }
                }

                await this.includeTripStartEnd(trip.stopList, data);

                trip.totalTripTime = this.formatTotalTripTime(data.totalTripTime);
            } catch (error) {
                const message = 'Error in loading the trip list';
                showErrorMessage(message, error);
            }
            trip.isStopListLoading = false;
        },
        formatTotalTripTime(totalTripTime) {
            if (!totalTripTime) {
                return undefined;
            }

            const formatted = Object.assign({}, totalTripTime);

            const formatRange = (start, end) =>
                `${moment(start).format(DATE_TYPES.standardTime)} - ${moment(end).format(DATE_TYPES.standardTime)}`;
            const formatHours = (hours) => ` (<strong>${hours.toFixed(2)} hrs</strong>)`;

            formatted.tripStartEstimatedRangeFormatted = '-';
            if (totalTripTime.tripStartEstimatedStart && totalTripTime.tripStartEstimatedEnd) {
                formatted.tripStartEstimatedRangeFormatted = formatRange(
                    totalTripTime.tripStartEstimatedStart,
                    totalTripTime.tripStartEstimatedEnd
                );
            }

            formatted.tripStartEstimatedHoursFormatted = '';
            if (totalTripTime.tripStartEstimatedHours) {
                formatted.tripStartEstimatedHoursFormatted = formatHours(totalTripTime.tripStartEstimatedHours);
            }

            formatted.actualRangeFormatted = '-';
            if (totalTripTime.actualStart && totalTripTime.actualEnd) {
                formatted.actualRangeFormatted = formatRange(totalTripTime.actualStart, totalTripTime.actualEnd);
            }

            formatted.actualHoursFormatted = '';
            if (totalTripTime.actualHours) {
                formatted.actualHoursFormatted = formatHours(totalTripTime.actualHours);
            }

            return formatted;
        },
        calculateProgressBarValue(completed, totalStops) {
            return (completed / totalStops) * 100;
        },
        setIconClassBasedOnTripState(tripItem, defaultClass) {
            return !this.isEmptyRunScheduleTrip(tripItem) ? defaultClass : 'disabled-icon';
        },
        setIconToDisable(shouldBeDisabled, defaultClass) {
            return shouldBeDisabled ? 'disabled-icon' : defaultClass;
        },
        getProgressBarColor(completed, totalStops) {
            if (completed === totalStops) {
                return 'green-progressbar';
            }

            if (completed === 0) {
                return 'red-progressbar';
            }
            return 'blue-progressbar';
        },
        onChangedStopStatus(response) {
            const { stopId, status, tripId } = response;
            const trip = this.tripList.find((item) => item.tripId === tripId);
            const stop = trip.stopList.find((x) => x.stopId === stopId);

            if (
                (stop.status === 'Complete' && status !== 'Complete') ||
                (stop.status === 'Failed' && status !== 'Failed')
            ) {
                trip.completedStops -= 1;
            } else if (
                (stop.status !== 'Complete' && status === 'Complete') ||
                (stop.status !== 'Failed' && status === 'Failed')
            ) {
                trip.completedStops += 1;
            }

            if (stop.status === 'Cancelled' && status !== 'Cancelled') {
                trip.totalStops += 1;
            }

            if (status === 'Arrived' || status === 'Complete' || status === 'Failed') {
                this.$set(stop, 'arrivalDate', moment());
            }

            if (status === 'Cancelled') {
                trip.totalStops -= 1;
            }

            this.$set(stop, 'status', status);
            trip.isSortable = false;
        },
        onChangedTripStatus(response) {
            const { status, tripId } = response;
            const trip = this.tripList.find((item) => item.tripId === tripId);
            this.$set(trip, 'status', status);
            this.getStopList(trip.user.publicUserId, trip.tripId, false, false);
        },
        goToCreateTripPage() {
            this.$router.push({
                path: '/trips/create'
            });
        },
        async exportTrips(tripId, name) {
            this.$_handleLoaderState(true, 'EXPORTING...');
            const formttedDate = `${this.$options.filters.dateFormat(this.selectedDate, 'dddd MMMM D YYYY')}`;

            try {
                const endpoint = `/api/trips/export`;

                const params = {
                    tripDate: this.selectedDate
                };
                let fileName = `Locate2u Trips - ${formttedDate}.xlsx`;

                if (tripId) {
                    params.tripId = tripId;
                    fileName = `${name} - ${formttedDate}.xlsx`;
                }

                if (this.selectedTeamRegionId) {
                    params.teamRegionId = this.selectedTeamRegionId;
                }
                const response = await handleRequests(endpoint, {
                    params,
                    responseType: 'blob'
                });

                const resData = response.data;
                if (resData.size === 0) {
                    this.$notify({
                        message: 'There are no trips to export',
                        type: 'danger'
                    });
                } else {
                    const url = window.URL.createObjectURL(new Blob([resData]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                }
            } catch (error) {
                const message = 'Error in exporting trips.';
                showErrorMessage(this, message, error);
            }

            this.$_handleLoaderState(false);
        },
        stopOrderChanged(tripId) {
            const trip = this.getTripDetails(tripId);
            this.calculateStopIndex(trip);
            trip.alertType = 'save';
            trip.alertMessage = this.alertSaveMessage;
            trip.isShowAlert = true;
            this.$forceUpdate();
        },
        // closeAlert(tripId) {
        //     const trip = this.getTripDetails(tripId);
        //     trip.isShowAlert = false;
        //     this.$forceUpdate();
        // },
        async saveConfiguration(tripId) {
            const trip = this.getTripDetails(tripId);

            trip.stopList.forEach((x, i) => {
                x.order = i;
            });
            const stopList = trip.stopList.filter((x) => !x.isStartStop && !x.isEndStop);

            this.$_handleLoaderState(true, 'SAVING...');

            try {
                const endpoint = `/api/trips/${this.selectedDate}/order-stops`;
                const payload = {
                    method: 'post',
                    data: {
                        tripId: trip.tripId,
                        stops: stopList
                    }
                };
                await handleRequests(endpoint, payload);
                await this.refreshTripStops(trip, true);

                this.$notify({
                    message: 'Successfully reordered stops.',
                    type: 'success'
                });
                trip.isFixedStopOrder = true;
            } catch (error) {
                const message = 'Error in reordering of stops.';
                showErrorMessage(this, message, error);
            }

            this.$_handleLoaderState(false);
        },
        reverseOrder(tripId) {
            const trip = this.getTripDetails(tripId);
            trip.stopList.reverse().forEach((x) => {
                x.originalEta = null;
            });
            this.calculateStopIndex(trip);
            trip.alertType = 'save';
            trip.alertMessage = this.alertSaveMessage;
            trip.isShowAlert = true;
            this.$forceUpdate();
        },
        async lockTrip(tripId) {
            if (this.isCustomer || this.isSupportStaff) 
                return;

            this.$messageBox
                .show({
                    title: 'Lock Trip Stop Order',
                    body: 'Are you sure you want to lock the stop order?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'PROCESSING...');
                        const trip = this.getTripDetails(tripId);
                        const endpoint = `/api/trips/${this.selectedDate}/lock?userId=${trip.user.publicUserId}`;
                        const payload = {
                            method: 'post'
                        };

                        try {
                            await handleRequests(endpoint, payload);
                            this.$set(trip, 'isFixedStopOrder', true);
                            this.$_handleLoaderState(false);
                            this.$notify({
                                message: 'Successfully locked trip.',
                                type: 'success'
                            });
                            await this.refreshTripStops(trip, true);
                        } catch (error) {
                            this.$_handleLoaderState(false);
                            const message = 'Error in locking the trip';
                            showErrorMessage(this, message, error);
                        }
                    }
                });
        },
        async unlockTrip(tripId) {
            if (this.isCustomer || this.isSupportStaff) 
                return;

            this.$messageBox
                .show({
                    title: 'Unlock Trip Stop Order',
                    body: 'Are you sure you want to unlock the stop order?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'PROCESSING...');
                        const trip = this.getTripDetails(tripId);
                        const endpoint = `/api/trips/${this.selectedDate}/unlock?userId=${trip.user.publicUserId}`;
                        const payload = {
                            method: 'post'
                        };

                        try {
                            await handleRequests(endpoint, payload);
                            this.$set(trip, 'isFixedStopOrder', false);
                            this.$_handleLoaderState(false);
                            this.$notify({
                                message: 'Successfully unlocked trip.',
                                type: 'success'
                            });
                            await this.refreshTripStops(trip, false);
                        } catch (error) {
                            this.$_handleLoaderState(false);
                            const message = 'Error in unlocking the trip';
                            showErrorMessage(this, message, error);
                        }
                    }
                });
        },
        async lockRun(tripId, runNumber) {
            this.$messageBox
                .show({
                    title: 'Lock Run Stop Order',
                    body: 'Are you sure you want to have the stop order fixed?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'PROCESSING...');
                        const trip = this.getTripDetails(tripId);
                        const endpoint = `/api/trips/${this.selectedDate}/runs/${runNumber}/lock?userId=${
                            trip.user.publicUserId
                        }`;
                        const payload = {
                            method: 'post'
                        };

                        try {
                            await handleRequests(endpoint, payload);
                            this.$set(trip, 'isFixedStopOrder', true);
                            this.$_handleLoaderState(false);
                            this.$notify({
                                message: 'Successfully locked run.',
                                type: 'success'
                            });
                            await this.refreshTripStops(trip, true);
                        } catch (error) {
                            this.$_handleLoaderState(false);
                            const message = 'Error in locking the run';
                            showErrorMessage(this, message, error);
                        }
                    }
                });
        },
        async unlockRun(tripId, runNumber) {
            this.$messageBox
                .show({
                    title: 'Unlock Run Stop Order',
                    body: 'Are you sure you want to unlock the stop order?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'PROCESSING...');
                        const trip = this.getTripDetails(tripId);
                        const endpoint = `/api/trips/${this.selectedDate}/runs/${runNumber}/unlock?userId=${
                            trip.user.publicUserId
                        }`;
                        const payload = {
                            method: 'post'
                        };

                        try {
                            await handleRequests(endpoint, payload);
                            this.$set(trip, 'isFixedStopOrder', false);
                            this.$_handleLoaderState(false);
                            this.$notify({
                                message: 'Successfully unlocked run.',
                                type: 'success'
                            });
                            await this.refreshTripStops(trip, false);
                        } catch (error) {
                            this.$_handleLoaderState(false);
                            const message = 'Error in unlocking the run';
                            showErrorMessage(this, message, error);
                        }
                    }
                });
        },
        async rebuildTrip(tripId) {
            this.$messageBox
                .show({
                    title: 'Rebuild Trip',
                    body: 'Are you sure you want to rebuld the trip?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'PROCESSING...');
                        const trip = this.getTripDetails(tripId);
                        const endpoint = `/api/trips/${tripId}/rebuild-trip`;
                        const payload = {
                            method: 'post'
                        };
                        await handleRequests(endpoint, payload);
                        this.$_handleLoaderState(false);
                        await this.refreshTripStops(trip, false);

                        this.$notify({
                            message: 'Successfully rebuilt trip.',
                            type: 'success'
                        });
                    }
                });
        },
        async refreshTripStops(trip, isLocked = null, isUpdate = false, retryUntilRoutingComplete = true) {
            trip.stopList = [];
            if (moment(this.selectedDate).isSameOrAfter(this.dateToday)) {
                trip.isStopListLoading = true;
                trip.getEtaCounter = 0;

                if (isUpdate) {
                    // added a 3 second delay to fetch the stops to give time for odl to update the end time when changing the end location of a trip.
                    // but once odl side is fixed the timeout should be removed.
                    setTimeout(async () => {
                        await this.getStopList(trip.user.publicUserId, trip.tripId, retryUntilRoutingComplete);
                    }, 3000);
                } else {
                    await this.getStopList(trip.user.publicUserId, trip.tripId, retryUntilRoutingComplete);
                }

                const stops = this.getStopsWithNoEta(trip.tripId);

                if (stops.length > 0 && trip.user.publicUserId != null) {
                    const obj = {
                        isShowAlert: true,
                        alertType: 'eta',
                        alertMessage: this.alertEtaMessage
                    };

                    // eslint-disable-next-line no-param-reassign
                    trip = Object.assign({}, trip, obj);

                    const interval = getTripEtaPollingInterval(trip.totalStops);
                    trip.etaPolling = setInterval(async () => {
                        await this.getStopList(trip.user.publicUserId, trip.tripId);
                        trip.getEtaCounter += 1;

                        if (trip.stopList.length > 0) {
                            const stops = this.getStopsWithNoEta(trip.tripId);

                            // past dates wont return any ETA's so we need to clear the polling
                            // Maximum of 30 intervals, this gives the dashboard to try to get ETA's for 5 minutes.
                            // 6 intervals is equivalent to 1 minute.
                            if (
                                moment(this.selectedDate).isBefore(this.dateToday) ||
                                stops.length === 0 ||
                                trip.getEtaCounter >= 30
                            ) {
                                clearInterval(trip.etaPolling);
                            }
                        }
                    }, interval);
                }
            } else {
                this.getStopList(trip.user.publicUserId, trip.tripId, retryUntilRoutingComplete);
            }

            trip.isShowAlert = false;
            trip.alertType = null;
            if (isLocked != null) {
                trip.isFixedStopOrder = isLocked;
            }

            this.$forceUpdate();
        },
        getStopsWithNoEta(tripId) {
            const trip = this.getTripDetails(tripId);
            if (trip == null) 
                return [];

            return trip.stopList.filter((x) => x.status === 'Pending' && x.originalEta === null);
        },
        async refreshList() {
            await this.getTripList();
            this.getTripDashboard();
            if (this.tripList.length > 0) {
                // should not include trip with pending changes that needs to be saved.
                const openedList = this.tripList.filter((x) => x.isOpen === true && x.alertType !== 'save');
                if (openedList.length) {
                    openedList.forEach((trip) => {
                        this.getStopList(trip.user.publicUserId, trip.tripId, false, false);
                    });
                }
            }
        },
        endDrag(event) {
            if (!this.isValidStopReOrder) {
                this.$notify({
                    message: "Moving stops between runs is not allowed, Please edit the stop's run number.",
                    type: 'warning'
                });
            }
            if (this.isStartOrEndLocation) {
                this.$notify({
                    message: 'Moving start or end locations between runs is not allowed.',
                    type: 'warning'
                });
            }
        },
        checkMove(event) {
            // console.log(event);
            // const trip = this.getTripDetails(tripId);
            // const { stopId, shipmentId } = event.draggedContext.element;
            // const { futureIndex } = event.draggedContext;
            // const shipments = trip.stopList.find(x => x.shipmentId === shipmentId);
            // const pickup = shipments.find(x => x.type.toLowerCase() === 'pick up');
            // const drop = shipments.find(x => x.type.toLowerCase() === 'drop');
            // const moveElement = trip.stopList.find(x => x.stopId === stopId);
            // const currentDropIndex = trip.stopList.findIndex(x => x.stopId === x.drop.stopId);
            // const currentPickupIndex = trip.stopList.findIndex(x => x.stopId === x.pickup.stopId);
            // if(moveElement.type.toLowerCase === 'drop'){
            //     if(currentPickupIndex > futureIndex){
            //         console.log('error invalid');
            //         return false;
            //     }
            // }else {
            //     console.log('invalid');
            //     return false;
            // }
            // return true;
            const fromRunNumber = event.draggedContext.element.runNumber;
            const toRunNumber = event.relatedContext.element.runNumber;
            const isSelectedStartLocation = event.draggedContext.element.isStartLocation;
            const isSelectedEndLocation = event.draggedContext.element.isEndLocation;
            const isStartRunLocation = event.relatedContext.element.isStartLocation;
            this.isValidStopReOrder = fromRunNumber === toRunNumber && !isStartRunLocation;
            this.isStartOrEndLocation = isSelectedStartLocation || isSelectedEndLocation;
            return this.isValidStopReOrder && !this.isStartOrEndLocation;
        },
        handleVisibilityChange(isHidden) {
            if (isHidden) 
                clearInterval(this.tripListPolling);
            else {
                this.isDateValid();
                this.refreshList();
            }
        },
        sendBulkSMS(userId = null) {
            this.$modal
                .show(SendBulkSms, {
                    title: 'Send Bulk SMS',
                    userId: userId || 'All',
                    selectedDate: this.selectedDate,
                    teamRegionId: this.selectedTeamRegionId,
                    stopCustomFieldDefinitions: this.user.stopCustomFieldDefinitions
                })
                .then((response) => {
                    if (response === 'ok') 
                        this.$modal.hide();
                });
        },
        calculateStopIndex(trip) {
            let counterIndex = 1;
            trip.stopList.forEach((x) => {
                if (
                    !x.isRunHeader &&
                    !x.isStartStop &&
                    !x.isEndStop &&
                    ((x.status !== 'Cancelled' &&
                        x.status !== 'Complete' &&
                        x.status !== 'Failed' &&
                        x.status !== 'On Hold') ||
                        trip.isFixedStopOrder)
                ) {
                    x.stopIndex = counterIndex;
                    counterIndex += 1;
                }
            });
        },
        async setTripRunHeader(trip) {
            if (trip.stopList.some((x) => x.runName !== null || x.runNumber !== null)) {
                const stopLists = [];
                const groupBy = (prop) => (data) => {
                    return data.reduce((dict, item) => {
                        /* eslint-disable no-unused-vars */
                        const { [prop]: _, ...rest } = item;
                        const byRunNumber = 'runNumber';
                        let itemProp = item[prop];
                        if ((item[prop] === '' || item[prop] === null) && item[byRunNumber] !== null) {
                            itemProp = item[byRunNumber];
                        }
                        dict[itemProp] = [...(dict[itemProp] || []), rest];
                        return dict;
                    }, {});
                };
                const result = Object.entries(groupBy('runName')(trip.stopList)).map(([key, value]) => ({
                    name: key,
                    children: value
                }));
                result.forEach((x) => {
                    stopLists.push({ runName: x.name !== 'null' ? x.name : null, isRunHeader: true });
                    stopLists.push(...x.children);
                });
                trip.stopList = stopLists;
            }
        },
        updateTrip(tripId) {
            const trip = this.getTripDetails(tripId);
            this.$modal.show(UpdateTripModal, { tripId }).then(async (response) => {
                if (response.code === 'ok') {
                    await this.getTripList();
                    this.getTripDashboard();
                    if (!this.updatedTripList.includes(trip.tripId)) {
                        const tripIndex = this.tripList.findIndex((x) => x.tripId === tripId);
                        if (tripIndex >= 0) {
                            this.tripList.splice(tripIndex, 1);
                        }
                    } else {
                        this.refreshTripStops(trip, trip.isFixedStopOrder, true);
                    }
                }
            });
        },
        reassignTrip(tripId) {
            const trip = this.getTripDetails(tripId);
            this.$modal
                .show(ReassignTripModal, {
                    tripId,
                    teamMembers: this.teamMembers,
                    assignedTo: trip.user.publicUserId,
                    assignedCarrierTeamId: trip.carrierTeamId,
                    tripDate: this.selectedDate,
                    teamRegionId: trip.teamRegionId
                })
                .then(async (response) => {
                    if (response.code === 'ok') {
                        this.$modal.hide();
                        await this.getTripList();
                        this.getTripDashboard();
                        if (!this.updatedTripList.includes(trip.tripId)) {
                            const tripIndex = this.tripList.findIndex((x) => x.tripId === tripId);
                            if (tripIndex >= 0) {
                                this.tripList.splice(tripIndex, 1);
                            }
                        } else {
                            this.refreshTripStops(trip);
                            if (response.value != null) {
                                const assignedTrip = this.tripList.find((x) => x.user.publicUserId === response.value);
                                if (assignedTrip != null && assignedTrip.tripId !== trip.tripId) {
                                    this.refreshTripStops(assignedTrip);
                                }
                            }
                        }
                    }
                });
        },
        async onAssignedUser(response) {
            await this.refreshList();
        },
        handleRemoveStop(stopId, tripId, isShipment) {
            let title = 'Remove Stop from Trip';
            let body = 'Are you sure you want to remove this stop from the trip? The stop will be unassigned.';

            if (isShipment) {
                title = 'Remove shipment from trip';
                body = 'Are you sure you want to remove this shipment? Both legs of the shipment will be removed.';
            }

            this.$messageBox
                .show({
                    title,
                    body,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'UPDATING...');

                        const api = `/api/stops/${stopId}/assign-user`;
                        const data = {
                            assignToPublicUserId: null,
                            carrierTeamId: null
                        };
                        const msg = 'Stop has been removed from the trip.';
                        const payload = {
                            method: 'post',
                            data
                        };

                        try {
                            await handleRequests(api, payload);
                            this.$notify({
                                message: msg,
                                type: 'success'
                            });
                        } catch (e) {
                            const message = 'Cannot remove stop from a trip.';
                            showErrorMessage(this, message, e);
                        }
                        const trip = this.getTripDetails(tripId);
                        this.refreshTripStops(trip, trip.isFixedStopOrder);
                    }
                    this.$_handleLoaderState(false);
                });
        },
        showETAloader(tripItem) {
            const { completedStops, totalStops, stopList, user } = tripItem;
            const onHoldStops = stopList.filter((x) => x.status?.toLowerCase() === 'on hold').length;
            let showLoader = false;

            if (user.publicUserId === null || completedStops === totalStops) {
                return showLoader;
            }
            // if our stopList has a stop that has an onhold status then we check if that stop is only the remaining active stop
            // if it is and it is equal to the total stops then it shouldn't show the loader cause on hold status wont have an
            // estimated completion time returned.
            if (completedStops !== totalStops && totalStops !== onHoldStops) {
                showLoader = true;
            }

            return showLoader;
        },

        createRunSchedule(tripId) {
            this.$modal
                .show(CreateRunScheduleModal, {
                    tripId
                })
                .then(async (response) => {
                    if (response.code === 'ok') {
                        this.$modal.hide();
                    }
                });
        },
        handleTeamRegionChanged(value) {
            this.selectedTeamRegionId = value;
        },
        async handleFilterOrSearch(val) {
            const prevTeamRegionId = this.selectedTeamRegionId;
            if (
                this.selectedDate !== val.tripDate ||
                prevTeamRegionId !== val.teamRegionId ||
                this.selectedStatus !== val.statusFilter ||
                this.selectedTeamMemberPublicUserId !== val.teamMemberPublicUserId ||
                this.selectedCustomerId !== val.customerId
            ) {
                this.selectedDate = val.tripDate;
                this.selectedTeamRegionId = val.teamRegionId;
                this.selectedStatus = val.statusFilter;
                this.selectedCustomerId = val.customerId;
                this.selectedTeamMemberPublicUserId = val.teamMemberPublicUserId;
                if (val.teamRegionId !== prevTeamRegionId) {
                    this.getTeamMembers();
                }

                this.$nextTick(async () => {
                    this.tripList = [];
                    this.isDateValid();
                    this.$_handleLoaderState(true);
                    await this.getTripList();
                    this.getTripDashboard();
                    this.$_handleLoaderState(false);
                });
            }
        },
        async handleStopsSearch(val) {
            this.currentSearchTextValue = val.searchText;
            this.$nextTick(async () => {
                this.tripList = [];
                this.isDateValid();
                this.$_handleLoaderState(true);
                await this.getTripList();
                this.getTripDashboard();
                this.$_handleLoaderState(false);
            });
        },
        getDisplayName(tripItem) {
            let finalName = 'Unassigned';
            if (tripItem.user.publicUserId) {
                finalName = tripItem.user.vehicleLicensePlate
                    ? `${tripItem.user.fullName} (${tripItem.user.vehicleLicensePlate})`
                    : tripItem.user.fullName;
            }

            if (tripItem.carrierTeam) {
                finalName = `${tripItem.carrierTeam.company}`;
            }

            return finalName;
        },
        getDisplaySubName(tripItem) {
            const textToRender = [];
            if (tripItem.customerName) {
                textToRender.push(tripItem.customerName);
            }
            if (tripItem.rateGroupName) {
                textToRender.push(`(${tripItem.rateGroupName})`);
            }
            return textToRender.join(' ');
        },
        getDisplayVehicleLoad(tripItem) {
            if (!this.user.vehicleCapacityUnitsConfiguration || this.user.vehicleCapacityUnitsConfiguration.length <= 0)
                return '';

            this.calculateVehicleLoadAndCapacity(tripItem);
            const { defaultMaxCapacity, symbol } = this.user.vehicleCapacityUnitsConfiguration.find(
                (x) => x.type === this.selectedCapacityType
            );

            const vehicleCapacity = tripItem.userCapacity
                ? tripItem.userCapacity[this.selectedCapacityType]
                : defaultMaxCapacity;

            return `${tripItem.vehicleLoad[this.selectedCapacityType]}/${vehicleCapacity ??
                defaultMaxCapacity} ${symbol}`;
        },
        async calculateVehicleLoadAndCapacity(tripItem) {
            if (
                !tripItem.vehicleLoad ||
                !this.user.vehicleCapacityUnitsConfiguration ||
                this.user.vehicleCapacityUnitsConfiguration.length <= 0
            )
                return;

            const vehicleLoadTypes = Object.keys(tripItem.vehicleLoad);
            vehicleLoadTypes.forEach((type) => {
                const { defaultLoad, defaultMaxCapacity } = this.user.vehicleCapacityUnitsConfiguration.find(
                    (x) => x.type === type
                );
                let vehicleCapacity = tripItem.userCapacity ? tripItem.userCapacity[type] : defaultMaxCapacity;
                let currentVehicleLoad = 0;

                // Calculate the starting load
                tripItem.stopList
                    .filter(
                        (stop) =>
                            !stop.isRunHeader &&
                            stop.type !== 'Pick Up' &&
                            stop.type !== 'Drop' &&
                            stop.type !== 'Start Location' &&
                            stop.type !== 'End Location'
                    )
                    .forEach((stop) => {
                        const stopLoadValue = !stop.load ? defaultLoad : Number(stop.load[type] ?? defaultLoad); // Need to use Number() since some loads have the string type
                        currentVehicleLoad += stopLoadValue;
                    });
                if (!vehicleCapacity) {
                    vehicleCapacity = currentVehicleLoad; // if no set maximum vehicle capacity then set a total stop load a maximum capacity
                }
                // Now calculate the running totals
                tripItem.stopList.forEach((stop) => {
                    if (!stop.vehicleLoad) {
                        stop.vehicleLoad = {};
                    }

                    if (!stop.availableCapacity) {
                        stop.availableCapacity = {};
                    }

                    if (stop.type === 'Start Location') {
                        stop.vehicleLoad[type] = this.roundNumber(currentVehicleLoad);
                        stop.availableCapacity[type] = this.roundNumber(vehicleCapacity - currentVehicleLoad);
                    } else if (stop.type === 'End Location') {
                        stop.vehicleLoad[type] = 0;
                        stop.availableCapacity[type] = this.roundNumber(vehicleCapacity);
                    } else if (stop.load) {
                        const stopLoadValue = !stop.load ? defaultLoad : Number(stop.load[type] ?? defaultLoad); // Need to use Number() since some loads have the string type
                        stop.load[type] = stopLoadValue;

                        if (stop.type !== 'Pick Up') {
                            currentVehicleLoad -= stopLoadValue;
                        } else {
                            currentVehicleLoad += stopLoadValue;
                        }

                        stop.vehicleLoad[type] = this.roundNumber(currentVehicleLoad);
                        stop.availableCapacity[type] = this.roundNumber(vehicleCapacity - currentVehicleLoad);
                    }
                });
            });
        },
        changeCapacityType(val) {
            if (this.user.vehicleCapacityUnitsConfiguration && this.user.vehicleCapacityUnitsConfiguration.length > 0) {
                this.selectedCapacityType = val;
                const data = this.user.vehicleCapacityUnitsConfiguration.find((x) => x.type === val);
                this.selectedCapacityLabel = data?.label;
                this.selectedCapacitySymbol = data?.symbol;
            }
        },
        changeSelectViewType(val) {
            this.selectedViewType = val;
            this.loadHeaderText = val === 'Current Load' ? 'CL' : 'AC';
        },
        getVehicleCurrentLoadOrAvailableCapacity(item) {
            if (item.driverBreakId > 0 || !item.vehicleLoad) 
                return '';

            if (this.selectedViewType === 'Available Capacity') {
                return `${item.availableCapacity[this.selectedCapacityType]}  `;
            }
            return `${item.vehicleLoad[this.selectedCapacityType]} `;
        },
        getStopLoad(item) {
            if (item.driverBreakId > 0 || !item.load || item.isStartStop || item.isEndStop) {
                return '';
            }

            let sign = '';
            if (item.type === 'Pick Up') {
                sign = '+';
            } else if (item.type === 'Drop') {
                sign = '-';
            }
            return `${sign} ${item.load[this.selectedCapacityType]}`;
        },
        async setupSignalR() {
            try {
                const userCrendentials = await window.auth.getUser();
                this.connection = new signalR.HubConnectionBuilder()
                    .withUrl('/api/TripCreatedHub', {
                        accessTokenFactory: () => {
                            return userCrendentials.access_token;
                        }
                    })
                    .configureLogging(signalR.LogLevel.Information)
                    .build();

                await this.connection.start().then((result) => {
                    this.connection.invoke('JoinToTeamChannel');
                });

                this.connection.onclose(async () => {
                    await this.setupSignalR();
                });

                this.connection.on('ProcessTripCreated', async (tripId) => {
                    await this.refreshList();
                });
                // eslint-disable-next-line no-empty
            } catch {}
        },
        showAllTripsOnMap() {
            this.$router.push({
                path: `/trips/routes/${this.selectedDate}`
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.delete-icon {
    transform: scale(1.2);
}
.trip-open {
    border-bottom: 1px solid #eee;
}
.trip-accordion-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;
    position: relative;
    padding-right: 30px;
    z-index: 10;
    .member-name {
        font-size: 14px;
        width: 40%;
        text-transform: uppercase;
        font-weight: 600;
        a {
            color: rgba(0, 0, 0, 0.87);
        }
        .custom-ellipsis {
            max-width: 60%;

            .display-sub-name {
                font-weight: normal;
                color: #666;
                margin: 0;
            }
        }
        span {
            margin-left: 15px;
        }
    }
    .completed-total {
        width: 60%;
        font-size: 1rem;
        display: flex;
        align-items: center;
        align-content: space-between;
        justify-content: space-between;
        .view-map {
            margin-right: 5px;
            cursor: pointer;
            ::v-deep .md-icon {
                color: #2b93ff;
            }
        }

        .export-stops {
            ::v-deep .md-icon {
                color: #2b93ff;
            }
        }
        .progress-bar {
            margin-right: 10px;
            width: 135px;
            display: inline-block;
        }
        span {
            text-transform: uppercase;
            font-weight: 400;
            margin-right: 5px;
            cursor: pointer;
        }

        .red-progressbar {
            ::v-deep .md-progress-bar {
                background-color: rgba(255, 82, 69, 0.38);
                .md-progress-bar-fill {
                    background-color: #ff5245;
                }
            }
        }

        .blue-progressbar {
            ::v-deep .md-progress-bar {
                background-color: rgba(43, 147, 255, 0.38);
                .md-progress-bar-fill {
                    background-color: #2b93ff;
                }
            }
        }

        .green-progressbar {
            ::v-deep .md-progress-bar {
                background-color: rgba(11, 218, 142, 0.38);
                .md-progress-bar-fill {
                    background-color: #0bda8e;
                }
            }
        }

        ::v-deep .md-progress-bar {
            margin-bottom: 0;
        }
    }
}

.gold-lock {
    margin-right: 10px;
    .md-icon {
        color: #daa520;
    }
}

.grey-lock {
    margin-right: 10px;
    .md-icon {
        color: #a9a9a9;
    }
}

.trip-stop-list {
    > .single-stop {
        display: table;
        width: 100%;
        border-bottom: 1px solid #eee;
        padding: 10px 0;
        > div {
            display: table-cell;
            padding-right: 10px;
        }
        > div:first-child {
            width: 150px;
        }
        > div:nth-child(2) {
            width: 400px;
            max-width: 400px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.trip-list-body {
    margin: 0 0 10px 0;
    border-radius: 0;
    padding: 0;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);

    ::v-deep .md-list-item {
        margin-left: 0;
    }

    ::v-deep .md-list-item .md-list-item-container {
        font-weight: 100;

        a {
            font-weight: 100;
        }
    }

    ::v-deep .md-list-item .md-list-item-container .md-ripple {
        padding: 0;
        border-radius: 0;

        .md-list-expand-icon {
            margin-left: 0;
            margin-right: 10px;
            z-index: 1;
            position: absolute;
            right: 0;
        }
    }

    ::v-deep .md-list-item .md-list-item-container .md-ripple:hover {
        background-color: #fff !important;
    }

    ::v-deep .md-list-item .md-list-item-container .alert-section .md-ripple:hover {
        background-color: initial !important;
    }

    ::v-deep .md-list-item .md-list-item-container .stop-actions .md-ripple:hover {
        background-color: initial !important;
    }

    ::v-deep .md-card-actions {
        button {
            height: 30px;
            min-width: 30px;
            width: 30px;
        }
    }

    ::v-deep .md-card-expand-content {
        border-top: 1px solid #eee;
    }

    .stop-reference {
        a {
            color: rgba(0, 0, 0, 0.87);
        }
    }

    .completed-total a {
        display: inline-block;
    }

    .stop-table-body {
        padding: 10px 20px;
        max-height: 500px;
        overflow: auto;

        .stop-time {
            .bold-time {
                font-weight: 500;
            }
        }

        ::v-deep .md-table {
            .stop-reference .dropdown {
                top: 5px;
            }

            .stop-reference .load-header-text {
                font-size: 17px;
            }

            tr > .stop-run-header {
                background-color: #dddddd;
                color: #000;
                font-weight: bold;
                height: 40px;
                padding-left: 20px;
            }

            tr > .stop-run-header:hover {
                background-color: #dddddd !important;
            }

            tr > .stop-order {
                width: 5%;
                text-align: center;

                .lock-icon i {
                    font-size: 19px !important;
                }
            }

            tr > .stop-alerts {
                width: 3%;
                text-align: center;

                .lock-icon i {
                    font-size: 19px !important;
                }
            }

            tr > .stop-reference {
                width: 15%;

                a {
                    font-size: 14px;
                }

                a:hover {
                    color: #2b93ff !important;
                }
            }

            tr > .stop-address {
                width: 30%;

                span {
                    max-width: 80%;
                }
            }

            tr > .stop-status {
                width: 11%;
            }

            tr > .stop-time {
                width: 11%;
            }

            tr > .delivery-time {
                width: 16%;
            }

            tr > .stop-contact {
                width: 12%;

                span {
                    max-width: 80%;
                }
            }

            tr > .stop-actions {
                width: 15%;

                > div > div {
                    margin-right: 5px;
                }
            }

            .show-draggable {
                tr > .drag-icon {
                    width: 2%;
                }

                tr > .stop-address {
                    width: 33%;
                }
            }

            .has-actual-order {
                /*tr > .stop-order {
                    border-right: 1px solid #ddd;
                }
                tr > .actual-stop-order {
                    width: 6%;
                    text-align: center;
                    border-right: none;
                }
                tr > .stop-address {
                    width: 25%;
                }
                tr > .stop-reference {
                    width: 12%;
                }
                tr > .stop-time {
                    width: 10%;
                }*/
            }
        }

        .stop-load-header {
            width: 10%;
            text-align: center;
        }

        .running-load-head {
            width: 12%;
            text-align: center;

            ::v-deep .md-table-head-label {
                float: left;
                padding-right: 5px;
            }

            .load-header-text,
            .load-header-text:hover {
                font-size: 1.0625rem;
                padding-left: 0;
                padding-right: 10px;
                font-weight: 300;
                color: #52b255 !important;
                text-transform: capitalize;
            }

            .icon-arrow {
                font-size: 24px !important;
                margin-top: -2px;
            }

            .open {
                .icon-arrow {
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                }
            }

            .icon-check {
                color: #fff !important;
                font-size: 16px !important;
                float: left;
            }

            .icon-check-color {
                color: #333333 !important;
            }

            .running-load-menu {
                ::v-deep .dropdown {
                    position: absolute;
                    bottom: 17.5px;
                    width: 150px;
                    left: calc((100% - 150px) / 2);
                }

                .dropdown-menu {
                    margin-left: -15px;
                    margin-top: 6px;

                    li {
                        border-bottom: 0px;
                        padding: 10px;
                    }

                    hr.border {
                        border-top: 1px solid #ddd;
                    }

                    a {
                        color: #333333 !important;
                        padding: 0px;
                        margin: 0px;
                        font-size: 12px;
                        font-weight: 400;
                    }

                    a:hover {
                        background-color: transparent !important;

                        .stop-load-header {
                            width: 10%;
                            text-align: right;
                        }

                        .running-load-head {
                            width: 12%;
                            text-align: right;

                            ::v-deep .md-table-head-label {
                                float: left;
                                padding-right: 5px;
                            }

                            .load-header-text,
                            .load-header-text:hover {
                                font-size: 1.0625rem;
                                padding-left: 0;
                                padding-right: 0;
                                font-weight: 300;
                                color: #333333 !important;
                            }

                            .icon-arrow {
                                font-size: 24px !important;
                                margin-top: -2px;
                            }

                            .open {
                                .icon-arrow {
                                    -webkit-transform: rotate(180deg);
                                    -moz-transform: rotate(180deg);
                                    -ms-transform: rotate(180deg);
                                    -o-transform: rotate(180deg);
                                }
                            }

                            .icon-check {
                                color: #fff !important;
                                font-size: 16px !important;
                                float: left;
                            }

                            .icon-check-color {
                                color: #333333 !important;
                            }

                            .running-load-menu {
                                ::v-deep .dropdown {
                                    position: absolute;
                                    bottom: 8px;
                                    width: 80px;
                                }

                                .dropdown-menu {
                                    margin-left: -15px;
                                    margin-top: 6px;

                                    li {
                                        border-bottom: 0px;
                                        padding: 10px;
                                    }

                                    hr.border {
                                        border-top: 1px solid #ddd;
                                    }

                                    a {
                                        color: #333333 !important;
                                        padding: 0px;
                                        margin: 0px;
                                        font-size: 12px;
                                        font-weight: 400;
                                    }

                                    a:hover {
                                        background-color: transparent !important;
                                        box-shadow: none;
                                        color: #2b93ff !important;
                                    }
                                }
                            }
                        }

                        .md-tooltip-top {
                            &.toggle-tooltip {
                                max-width: 220px !important;
                            }
                        }

                        box-shadow: none;
                        color: #2b93ff !important;
                    }
                }
            }
        }

        .md-tooltip-top {
            &.toggle-tooltip {
                max-width: 220px !important;
            }
        }
    }

    .empty-table {
        height: 150px;
        display: block;
    }
}

.trip-list-body:first-child {
    margin-top: 40px;
}

.datepicker-parent-container {
    width: 100%;
}

.datepicker-container {
    max-width: 140px;
    > div {
        margin-top: 0;
        ::v-deep .md-clear {
            display: none;
        }
    }
    ::v-deep .md-input {
        width: 100%;
    }
}

::v-deep .header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.custom-draggable {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.alert-section {
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 1;
    > div {
        padding: 8px 20px;
        > div {
            display: inline-block;
            vertical-align: middle;
            button {
                height: 30px;
                min-width: 30px;
                width: 30px;
            }
        }
    }
}

.drag-item {
    cursor: grab;
}

.eta-loader-container {
    margin-left: 10%;
}

.eta-loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #aaa;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    display: inline-block;
    vertical-align: middle;
}

.icon-warning {
    color: #ff9800 !important;
    cursor: pointer;
    left: 14px;
}

.trip-status {
    background-color: whitesmoke;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.grey-text {
    color: red;
}

.trip-status-button {
    display: inline-block;
    margin-right: 10px;
}

.disabled-icon {
    pointer-events: none;
    .md-icon {
        color: #a9a9a9;
    }

    ::v-deep .md-icon {
        color: #a9a9a9;
    }
}

::v-deep .filter-steps--container {
    .filter-steps--choices {
        margin-top: 0;
        padding-bottom: 0;
        > .md-input {
            display: initial;
        }
    }
}

::v-deep .filter-steps--container.tablet-filter-container {
    width: initial;
    float: left;
    margin-left: 20px;
}

.title {
    display: inline-block;
    // width: 150px;
}

.rebuild-link {
    display: inline-block;
}

.rebuild-link:hover {
    cursor: pointer;
}

.custom-toolbar {
    align-items: start;
    margin-top: 1px;
    .custom-toolbar-start {
        flex: 2;
    }
}
.stop-load {
    ::v-deep .md-table-cell-container {
        text-align: center;
        padding-right: 5px;
    }
}

::v-deep .search--container {
    width: 250px;
    float: right;
    margin-top: 6px;
}

.vehicle-load {
    ::v-deep .md-table-cell-container {
        text-align: center;
    }
}
.vehicle-max-load-header {
    margin-left: 5px !important;
    color: darkgray;
}

.custom-stats-card {
    .custom-card-icon {
        padding: 10px !important;
        color: #fff;

        i {
            width: initial !important;
            height: 25px !important;
            margin-top: 0 !important;
            margin-left: 0 !important;
            font-size: 28px !important;
            line-height: 28px;
        }

        .custom-card-title {
            display: inline-block;
            margin-left: 8px;
            vertical-align: text-top;
            margin-right: 6px;
        }
    }

    ::v-deep .md-card-stats .md-card-header.md-card-header-icon i {
        font-size: 28px !important;
    }

    ::v-deep .md-card-actions {
        border: none;
        margin: 0;
    }
}

@media screen and (min-width: 991px) {
    ::v-deep .md-card-stats .md-card-header .card-icon + .category {
        padding-top: 30px;
    }
}

@media screen and (min-width: 1441px) {
    ::v-deep .md-card-stats .md-card-header .card-icon + .category {
        padding-top: 10px;
    }
}

.btn-loader {
    margin-top: 5px;
    border: 4px solid #eeeeee;
    border-radius: 50%;
    border-top: 4px solid #2b93ff;
    width: 24px;
    height: 24px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.btn-refresh {
    float: right;
    margin-right: 15px;
}

.start-address-trip {
    width: 73.8%;

    span {
        max-width: 80%;
    }
}

.start-address-cell {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.total-trip-time-labels {
    text-align: right;
}

.total-trip-time-text {
    margin-bottom: 0.5em;
}

.status-break {
    color: #fff !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 90px;
    cursor: pointer;
}

.icon-break {
    color: #C2907F;
    padding-right:50px;
}

.break-address {
    span {
        padding-left: 35px;
        font-weight: 600;
    }
}

.break-eta {
    span {
        font-weight: 600;
    }
}
</style>
