<template>
    <div class="cf-container">
        <div class="cf-action-container">
            <md-button
                title="Create Rate Group"
                class="md-primary md-just-icon md-round"
                @click.stop="createTaxSettings"
            >
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div v-if="!isListLoading">
            <md-table class="custom-paginated-table">
                <md-table-row>
                    <md-table-head>Type</md-table-head>
                    <md-table-head>Tax Type</md-table-head>
                    <md-table-head>Description</md-table-head>
                    <md-table-head>Rate</md-table-head>
                    <md-table-head class="actions">Actions</md-table-head>
                </md-table-row>

                <md-table-row v-for="taxSetting in taxSettingsList" :key="taxSetting.taxSettingsId">
                    <md-table-cell>{{ taxSetting.type }}</md-table-cell>
                    <md-table-cell>{{ taxSetting.accountTaxType }}</md-table-cell>
                    <md-table-cell>{{ taxSetting.description }}</md-table-cell>
                    <md-table-cell>{{ showPercentage(taxSetting.rate) }}</md-table-cell>

                    <md-table-cell class="action-buttons">
                        <md-button
                            title="Update tax settings"
                            class="md-warning md-just-icon md-round"
                            @click.stop="updateTaxSettings(taxSetting.taxSettingsId)"
                        >
                            <md-icon>edit</md-icon>
                        </md-button>
                        <md-button
                            title="Delete tax settings"
                            class="md-danger md-just-icon md-round"
                            @click.stop="handleDeleteTaxSettings(taxSetting.taxSettingsId)"
                        >
                            <md-icon>delete</md-icon>
                        </md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
            <div class="text-center" v-if="taxSettingsList.length == 0">
                <p class="no-result-message">No results matching your search/filter could be found.</p>
            </div>
        </div>
        <div v-else>
            <div class="stopbar-loader">
                <fade-loader :loading="true" color="#333333" />
                <span>LOADING</span>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import CreateTaxSettingsModal from './CreateTaxSettingsModal';

export default {
    name: 'TaxSettingsList',
    components: {
        FadeLoader
    },
    mixins: [GeneralMixin],
    data() {
        return {
            taxSettingsList: [],
            isListLoading: true
        };
    },
    async mounted() {
        this.taxSettingsList = await this.getTaxSettings();
    },
    methods: {
        async createTaxSettings() {
            this.$modal
                .show(CreateTaxSettingsModal, {
                    taxSettingDetails: {
                        type: null,
                        accountTaxType: null,
                        description: null,
                        rate: 0
                    }
                })
                .then(async (response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.taxSettingsList = await this.getTaxSettings();
                        this.$modal.hide();
                    }
                });
        },
        async getTaxSettings() {
            let response = {
                data: []
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/team-settings/tax-settings`;

                response = await handleRequests(endpoint);
            } catch (error) {
                const message = 'Error in getting the tax settings list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;
            return response.data ?? [];
        },
        async getTaxSettingsDetails(taxSettingsId) {
            try {
                const api = `/api/team-settings/tax-settings/${taxSettingsId}`;
                const { data } = await handleRequests(api);
                return data;
            } catch (error) {
                const message = 'Error in getting the tax settings details';
                showErrorMessage(this, message, error);
            }
            return {};
        },
        async updateTaxSettings(taxSettingsId) {
            this.$_handleLoaderState(true, 'FETCHING...');
            const taxSettingsDetail = await this.getTaxSettingsDetails(taxSettingsId);
            this.$_handleLoaderState(false);
            this.$modal
                .show(CreateTaxSettingsModal, {
                    taxSettingDetails: Object.assign({}, taxSettingsDetail),
                    isUpdate: true
                })
                .then(async (response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.taxSettingsList = await this.getTaxSettings();
                        this.$modal.hide();
                    }
                });
        },
        async deleteTaxSettings(taxSettingsId) {
            const payload = {
                method: 'delete'
            };
            const api = `/api/team-settings/tax-settings/${taxSettingsId}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Tax settings was deleted!',
                    type: 'success'
                });

                this.taxSettingsList = await this.getTaxSettings();
            } catch (e) {
                const message = 'Error in deleting tax settings.';
                showErrorMessage(this, message, e);
                this.isListLoading = false;
            }
        },
        handleDeleteTaxSettings(taxSettingsId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Tax Settings',
                    body: 'Are you sure you want to delete this tax setting?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isListLoading = true;
                        await this.deleteTaxSettings(taxSettingsId);
                    }
                });
        },
        showPercentage(value) {
            return `${value}%`;
        }
    }
};
</script>
<style lang="scss" scoped>
.stopbar-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

.cf-container {
    min-height: 250px;
}
</style>
