<template>
    <div class="map-overlay">
        <DispatchMapboxCanvas
            :shipment-details="shipmentDetails"
            :offer-list="offerList"
            :pinned-offer.sync="pinnedOffer"
            :highlighted-driver-id.sync="highlightedDriverId"
            :focused-driver.sync="focusedDriver"
            :highlighted-stop-id.sync="highlightedStopId"
            :route-display="routeDisplay"
        />
        <OfferListPanel
            :offer-list="offerList"
            :pinned-offer.sync="pinnedOffer"
            :highlighted-driver-id.sync="highlightedDriverId"
            :focused-driver.sync="focusedDriver"
        />

        <ShipmentDetailsPanel
            :shipment-details="shipmentDetails"
            :shipment-list="shipmentList"
            @navigateToShipmentId="loadShipment"
        />

        <div v-if="pinnedOffer">
            <DriverProfilePanel
                v-if="pinnedDriverDetails"
                :pinned-offer="pinnedOffer"
                :shipment-details="shipmentDetails"
                :pinned-driver-details="pinnedDriverDetails"
                :photo-url="pinnedDriverDetails.photoUrl || $root.defaultPhotoUrl"
                @driverAssigned="onDriverAssigned"
            />

            <StopListPanel
                :pinned-offer="pinnedOffer"
                :pinned-driver-details="pinnedDriverDetails"
                :highlighted-stop-id.sync="highlightedStopId"
                :route-display="routeDisplay"
                :shipment-details="shipmentDetails"
            />
        </div>

        <ActionsPanel
            @recalculateOffers="recalculateOffers"
            :shipment-details="shipmentDetails"
            :pinned-offer="pinnedOffer"
            :route-display.sync="routeDisplay"
            :calculation-date="calculationDate"
        />

        <div class="already-assigned-alert alert alert-danger" v-if="assignedName">
            <b>NOTE -</b>
            This shipment has already been assigned to {{ assignedName }}
        </div>

        <div
            class="already-assigned-alert alert alert-warning alert-calculation-info"
            v-else-if="isOfferListStale"
            @click="recalculateOffers"
        >
            <b>NOTE -</b>
            These offers were calculated {{ offerListAgeText }} and may be stale. Click here to refresh them.
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins';
import { showErrorMessage, handleRequests } from '@/helpers';
import moment from 'moment';
import {
    DispatchMapboxCanvas,
    ShipmentDetailsPanel,
    ActionsPanel,
    DriverProfilePanel,
    OfferListPanel,
    StopListPanel
} from './components';

export default {
    name: 'DispatchDashboard',

    mixins: [GeneralMixin],

    components: {
        DispatchMapboxCanvas,
        ShipmentDetailsPanel,
        OfferListPanel,
        StopListPanel,
        ActionsPanel,
        DriverProfilePanel
    },

    data() {
        return {
            shipmentDetails: null,
            shipmentList: [],
            offerList: [],
            allDriversList: [],
            pinnedOffer: null,
            focusedDriver: null, // Used to centre the map on a driver's location
            highlightedDriverId: null, // Used to make the driver stand out more on the map
            highlightedStopId: null, // Used to highlight a particular stop on the map
            pinnedDriverDetails: {},
            recordType: 'Shipment',
            routeDisplay: 'Both',
            calculationDate: null,
            currentDate: Date.now() // Used to make some of the computed properties recalculate their values
        };
    },

    computed: {
        isAssigned() {
            return (
                this.shipmentDetails &&
                (this.shipmentDetails.assignedTo?.publicUserId || this.shipmentDetails.carrierTeamId)
            );
        },

        assignedName() {
            if (this.isAssigned) {
                if (this.shipmentDetails.assignedTo?.publicUserId) 
                    return this.shipmentDetails.assignedTo.fullName;
                return this.shipmentDetails.carrierTeam.company;
            }

            return null;
        },

        isOfferListStale() {
            if (!this.calculationDate) 
                return false;

            const duration = moment.duration(moment(this.currentDate).diff(moment(this.calculationDate)));

            // Consider the offer list stale 15 minutes after the calculation
            return duration.as('minutes') > 15;
        },

        offerListAgeText() {
            // Show how long ago the calculation was done, in human-friendly terms
            return moment(this.calculationDate).from(this.currentDate);
        }
    },

    async mounted() {
        this.stopId = this.$route.params.stopId;
        this.shipmentId = this.$route.params.shipmentId;

        // Deliberately not awaiting these, as it can be slow, so doing it as a background task
        // TODO: Handle stop details as well if this is a stop
        this.loadAllDrivers();
        this.loadShipmentList();

        await this.loadShipment(this.shipmentId);

        // eslint-disable-next-line  no-return-assign
        setInterval(() => (this.currentDate = new Date()), 10000); // Used to force some computed properties to refresh their values every 10s
    },

    watch: {
        async pinnedOffer(offer) {
            if (!offer) {
                // No offer selected, so clear the data
                this.pinnedDriverDetails = null;

                return;
            }

            if (this.allDriversList)
                this.pinnedDriverDetails = this.allDriversList.find((x) => x.publicUserId === offer.publicUserId);
        }
    },

    methods: {
        async loadShipment(shipmentId) {
            this.shipmentId = shipmentId;
            this.clearState();

            // Update URL, so if page is refreshed, it will stay on the same shipment
            this.$router.replace({ path: `/shipments/${shipmentId}/dispatch` });

            this.$_handleLoaderState(true);

            await this.loadShipmentDetails(shipmentId);

            // must wait for the shipment details
            await this.loadDriverOffers(shipmentId);

            this.$_handleLoaderState(false);
        },

        async loadShipmentDetails(shipmentId) {
            try {
                // Load, custom fields, notes (both shipment level + logged notes), skills, from + to name/address, customer name, time windows, items
                const api = `/api/shipments/${shipmentId}?`;
                const data = await handleRequests(api);
                this.shipmentDetails = data.data;

                document.title = `Locate2u - Dispatch Shipment ${this.shipmentDetails.shipmentRef}`;
            } catch (error) {
                const message = 'Error in loading the shipment details.';
                showErrorMessage(this, message, error);
            }
        },

        async loadDriverOffers(shipmentId) {
            // This will return the offers from the last calculation that was done (if any)
            let endpoint = '';

            if (this.stopId) {
                endpoint = `/api/stops/${this.stopId}/job-offers/`;
            } else if (this.shipmentId) {
                endpoint = `/api/shipments/${shipmentId}/job-offers/`;
            }

            try {
                const { data } = await handleRequests(endpoint);

                if (data?.data) {
                    const responseData = data?.data || null;

                    this.offerList = this.parseOffers(responseData);
                } else {
                    this.offerList = [];
                }
            } catch (e) {
                this.offerList = [];

                if (e.status !== 404) {
                    this.$notify({
                        message: 'Error retrieving offers.',
                        type: 'danger'
                    });
                } else if (!this.isAssigned) {
                    // No offers have been calculated yet, so intiate the calculation of offers
                    await this.recalculateOffers();
                }
            }
        },

        loadAllDrivers() {
            // For dispatch, we're always only concerned about the current date
            const fromDate = moment().format('YYYY-MM-DD');

            // Fetch a list of all the drivers, so we can plot them all on the map as well. This also provides
            // current locations for the offered drivers, as their location may have changed since the offers
            // where originally calculated.
            // NOTE: I'm deliberately using a promise here rather than async/await because I don't want this
            // call (which can be a bit slow at times) resulting in delaying the page load.
            this.$store
                .dispatch('team/FETCH_TEAM_MEMBERS', {
                    date: fromDate,
                    includeLocationDetails: false // For now, in contrast to the comment above, since we're not using the current location info just yet
                })
                .then((result) => {
                    this.allDriversList = result;

                    // Sometimes an offer is pinned before this list has loaded, so populate the pinned driver details now
                    if (this.pinnedOffer)
                        this.pinnedDriverDetails = this.allDriversList.find(
                            (x) => x.publicUserId === this.pinnedOffer.publicUserId
                        );
                });
        },

        async loadShipmentList() {
            const filters = {
                params: {
                    tripDate: moment().format('YYYY-MM-DD') // For dispatch, we're always only concerned about the current date
                }
            };

            const { data } = await handleRequests('/api/shipments/list/unassigned', filters);

            this.shipmentList = data;
        },

        parseOffers(calculationBatch) {
            let driverOfferList = [];

            // TODO: We should review the structure of the response and streamline/improve the model
            if (calculationBatch && calculationBatch.bands && calculationBatch.bands.length) {
                this.calculationDate = calculationBatch.calculationDate;

                // When the new ODL-based filtering was introduced, the concept of grouping users into bands no longer matter
                // because, ODL may choose a driver that is outside the defined bands. So we can just combine all the drivers
                // into a single list
                // TODO: See if this banding is actually appropriate here, and why the drivers are grouped as such.
                calculationBatch.bands.forEach((band) => {
                    // Since the data from the server is a bit ugly at the moment (to be improved),
                    // let's map it to a nicer structure first
                    const bandDriverList = band.users
                        .filter((x) => x.userIncluded)
                        .map((x) => ({
                            publicUserId: x.publicUserId,
                            driverName: x.fullName,
                            phone: x.phone,
                            company: x.company,
                            status: x.status,
                            vehicleType: x.vehicleType,
                            location: {
                                // NOTE: This is as per when the calculation took place. It may be out of date.
                                latitude: x.userLatitude,
                                longitude: x.userLongitude
                            },
                            isOnline: x.isOnline,
                            photoUrl: x.photoUrl,
                            distance: x.userDistance, // This is the travel distance to the pickup stop, including the path to the other stops that will be done by this driver before this stop
                            distanceUnits: x.userDistanceUnit,
                            additionalDistance: x.additionalDistance, // How much extra distance this shipment will add to this trip
                            additionalTimeSeconds: x.additionalTimeSeconds, // How much extra time this shipment will add to this trip
                            currentRoute: {
                                path: x.userCurrentRouteDetails.route,
                                stops: x.userCurrentRouteDetails.stops
                            },
                            proposedRoute: {
                                path: x.userToBeRouteDetails.route,
                                stops: x.userToBeRouteDetails.stops
                            },
                            rank: x.userSortOrder,

                            isPinned: false
                            // Other properties to consider:
                            // - Before + after distance
                            // - Before + after travel time
                            // - Load/capacity info
                            // - Stops
                            // - How old the "current" location is
                            // - Skills
                            // - Status
                            // - Trip start + end
                            // - Company
                            // - Vehicle type
                            // - Vehicle capacity
                        }));

                    driverOfferList = driverOfferList.concat(bandDriverList);
                });

                driverOfferList.sort(this.sortDriversByRank);
            }

            return driverOfferList;
        },

        sortDriversByRank(x, y) {
            // Online drivers should be at the top of the list, and then sort by rank
            if (!x.isOnline && y.isOnline) 
                return 1;

            if (x.isOnline && !y.isOnline) 
                return -1;

            if (x.rank > y.rank) 
                return 1;

            if (x.rank < y.rank) 
                return -1;

            return 0;
        },

        clearState() {
            this.pinnedOffer = null;
            this.focusedDriver = null;
            this.highlightedDriverId = null;
            this.highlightedStopId = null;
            this.pinnedDriverDetails = null;
            this.calculationDate = null;
        },

        async recalculateOffers() {
            if (this.isCalculatingOffer) 
                return; // Don't allow it to recalculate when it's already calculating

            this.isCalculatingOffer = true;

            this.$_handleLoaderState(true, 'CALCULATING OFFERS...');

            this.clearState();

            let endpoint = '';
            if (this.stopId) {
                endpoint = `/api/stops/${this.stopId}/job-offers/calculate`;
            } else if (this.shipmentId) {
                endpoint = `/api/shipments/${this.shipmentId}/job-offers/calculate`;
            }

            const payload = {
                method: 'post'
            };

            try {
                const { data } = await handleRequests(endpoint, payload);

                if (!data) {
                    this.$notify({
                        message: 'No suitable driver found.',
                        type: 'warning'
                    });
                } else {
                    this.offerList = this.parseOffers(data);
                }
            } catch (e) {
                this.$notify({
                    message: e.data || 'Error calculating offers.',
                    type: 'danger'
                });
            } finally {
                this.isCalculatingOffer = false;
                this.$_handleLoaderState(false);
            }
        },

        onDriverAssigned() {
            this.loadShipment(this.shipmentDetails.shipmentId);
        }
    }
};
</script>

<style lang="scss" scoped>
.map-overlay {
    width: 100vw;
    height: 100vh;

    .map-loader {
        position: absolute;
        top: 35%;
        left: 50%;

        span {
            position: absolute;
            margin-top: 50px;
            width: 110px;
            left: calc((100% - 100px) / 2);
            text-align: center;
            font-weight: 600;
        }
    }
}

.already-assigned-alert {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.alert-calculation-info {
    cursor: pointer;
}
</style>
