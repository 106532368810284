var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.openSmsModal()
        },
      },
    },
    [
      _c("md-icon", [_vm._v("textsms")]),
      _c("md-tooltip", { attrs: { "md-direction": "left" } }, [
        _vm._v("\n        Send SMS.\n    "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }