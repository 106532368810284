<template>
    <md-button
        title="Download Excel"
        class="md-info md-just-icon md-round"
        @click.stop="handleGenerateExcel(invoiceId)"
    >
        <md-icon>get_app</md-icon>
    </md-button>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage, downloadFile } from '@/helpers';

export default {
    name: 'GenerateExcelButton',
    props: {
        invoiceId: {
            type: [Number, String],
            default: () => null
        }
    },
    mixins: [GeneralMixin],
    methods: {
        async handleGenerateExcel(invoiceId) {
            this.$_handleLoaderState(true, 'GENERATING...');
            const payload = {
                method: 'get',
                responseType: 'blob'
            };
            const api = `/api/team-member-invoices/${invoiceId}/download/excel`;
            try {
                const response = await handleRequests(api, payload);
                await downloadFile(response.data, 'invoiceData.xlsx');
                this.$notify({
                    message: 'Successfully downloaded the invoice data',
                    type: 'success'
                });
                this.$emit('invoiceGenerated', true);
            } catch (e) {
                const message = 'Error in downloading invoice data.';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
        }
    }
};
</script>
