var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _vm.hasTeam
      ? _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Settings"),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "   >    " +
                        _vm._s(_vm.$t("menus.setting.billingDashboard"))
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-blue" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("paid")])],
                  1
                ),
              ]
            ),
            _c("md-card-content", [
              _c(
                "div",
                { staticClass: "cf-container" },
                [
                  _c("h5", [
                    _vm._v(
                      "\n                        Here you will be able to make changes to your subscriptions.\n                    "
                    ),
                  ]),
                  _c(
                    "md-button",
                    {
                      staticClass: "md-primary",
                      on: { click: _vm.openBillingDashboard },
                    },
                    [_vm._v("Open Stripe")]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }