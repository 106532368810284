<script>
import { ref, onMounted, toRefs, inject, watch, onBeforeUnmount } from '@vue/composition-api';
import useMapUtils from '@/compostables/useMapUtils';

export default {
    name: 'RoutePlaybackMarker',
    props: {
        currentMarkerPos: {
            type: Object,
            default: () => {}
        },
        pinnedDriverDetails: {
            type: Object,
            default: () => {}
        }
    },
    setup(props, context) {
        const { currentMarkerPos, pinnedDriverDetails } = toRefs(props);
        const { setMarkers, createLatLngPoint } = useMapUtils(context);
        const google = inject('google');
        const map = inject('map');
        const marker = ref(null);

        onMounted(() => {
            const { lat, lng } = currentMarkerPos.value;
            marker.value = setMarkers(
                google.value,
                map.value,
                lat,
                lng,
                'driver',
                pinnedDriverDetails.value.markerPins
            );
        });

        watch(currentMarkerPos, (newVal) => {
            const { lat, lng } = newVal;
            const point = createLatLngPoint(google.value, lat, lng);
            marker.value.setPosition(point);
        });

        onBeforeUnmount(() => {
            marker.value.setMap(null);
            marker.value = null;
        });

        return {
            marker
        };
    },
    render: () => null
};
</script>
