var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-steps--container" },
    [
      _c(
        "md-field",
        { staticClass: "status-filter" },
        [
          _vm.statuses
            ? _c("vue-select", {
                attrs: {
                  options: _vm.statuses,
                  placeholder: "Status",
                  searchable: _vm.$root.isDesktop,
                },
                model: {
                  value: _vm.statusFilterValue,
                  callback: function ($$v) {
                    _vm.statusFilterValue = $$v
                  },
                  expression: "statusFilterValue",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }