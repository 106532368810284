<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ isUpdate ? 'Update' : 'Add' }} Brand</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.brandDetails" class="form-wrapper">
                <div class="form-container">
                    <div class="contact-details">
                        <form-group name="name" label="Brand name" :messages="{ required: 'Brand name is required.' }">
                            <md-input v-focus v-model="brandDetails.name" id="brandName" />
                        </form-group>
                    </div>
                    <div>
                        <div class="upload-container">
                            <img
                                v-if="!logoPreview"
                                class="profile-image"
                                :src="logoPreview !== null ? logoPreview : '/img/logos/logo2-placeholder.jpg'"
                                @error="$_setDefaultBrokenImage"
                                alt
                            />
                            <img
                                v-if="logoPreview"
                                class="profile-image"
                                :src="logoPreview"
                                @error="$_setDefaultBrokenImage"
                                alt
                            />
                            <div>
                                <input
                                    ref="logoInput"
                                    hidden
                                    type="file"
                                    @change="onLogoSelected"
                                    accept="image/x-png,image/jpeg,image/jpg,image/png"
                                />
                                <div>
                                    <button class="custom-btn" @click="$refs.logoInput.click()">
                                        {{ isUpdate ? 'Update' : 'Upload' }} brand logo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click.prevent="addUpdateBrand">
                {{ isUpdate ? 'Update' : 'Add' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { required } from 'vuelidate/lib/validators';
import { BlockBlobClient } from '@azure/storage-blob';

export default {
    name: 'AddBrandModal',
    mixins: [GeneralMixin],
    props: {
        existingData: {
            type: Object,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            brandDetails: { name: null, logoFileName: null },
            logoPreview: null,
            selectedLogo: null,
            isUpdate: false,
            originalFile: null
        };
    },
    mounted() {
        if (this.existingData != null) {
            this.isUpdate = true;
            Object.assign(this.brandDetails, this.existingData);
            this.logoPreview = this.existingData.logoFileUrl;
        }
    },
    methods: {
        async addUpdateBrand() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            if (this.selectedLogo == null && !this.isUpdate) {
                const message = 'Please select a photo';
                showErrorMessage(this, message);
                return;
            }

            await this.saveBrandDetails();
        },
        onLogoSelected(event) {
            const [file] = event.target.files;

            if (file) {
                this.selectedLogo = file;
                this.logoPreview = URL.createObjectURL(this.selectedLogo);
                this.brandDetails.logoFileName = file.name;
            }
        },
        async saveBrandDetails() {
            this.$_handleLoaderState(true, 'Saving...');
            try {
                let api = '/api/brands';

                let payload = {
                    method: 'post',
                    data: this.brandDetails
                };

                if (this.isUpdate) {
                    api = `/api/brands/${this.brandDetails.brandId}`;
                    payload = {
                        method: 'put',
                        data: this.brandDetails
                    };
                }

                const response = await handleRequests(api, payload);
                await this.onUploadImage(response.data.uploadUrl);
            } catch (e) {
                const message = 'Error in saving brand details';
                this.$_handleLoaderState(false);
                showErrorMessage(this, message, e);
            }
        },
        async onUploadImage(uri) {
            if (uri == null || uri === '') {
                this.$notify({
                    message: `Successfully updated`,
                    type: 'success'
                });
                this.$_handleLoaderState(false);
                this.resolve(true);
                return;
            }
            this.$_handleLoaderState(true, 'UPLOADING...');
            const file = this.selectedLogo;

            const blockBlobClient = new BlockBlobClient(uri);

            try {
                await blockBlobClient.upload(file, file.size);
                this.$_handleLoaderState(false);

                let message = 'Successfully added';

                if (this.isUpdate) {
                    message = 'Successfully updated';
                }

                this.$notify({
                    message,
                    type: 'success'
                });
                this.resolve(true);
            } catch (e) {
                const message = 'Error in uploading brand logo';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        }
    },
    validations: {
        brandDetails: {
            name: { required }
        }
    }
};
</script>

<style lang="scss" scoped>
.upload-container {
    height: 150px;
    text-align: center;
    .gray-text {
        margin-bottom: 10px;
    }
    img {
        max-height: 100%;
    }

    > div .custom-btn {
        max-width: initial;
        background-color: #2b93ff;
    }
    > div {
        height: initial;
    }
}
</style>
