var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isListLoading
      ? _c("div", [
          !_vm.hasInvitations
            ? _c("div", { staticClass: "text-center" }, [
                _c("p", [
                  _vm._v(
                    "\n                It seems that you haven't invited anyone to your team. Click the button below to start inviting\n                people.\n            "
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "custom-btn",
                    on: { click: _vm.onInviteMember },
                  },
                  [_vm._v("\n                Invite Member\n            ")]
                ),
              ])
            : _c(
                "div",
                [
                  _c(
                    "md-table",
                    { staticClass: "custom-paginated-table" },
                    [
                      _c(
                        "md-table-row",
                        [
                          _c("md-table-head", [_vm._v("Email")]),
                          _c("md-table-head", [_vm._v("Role")]),
                          _c("md-table-head", [_vm._v("Status")]),
                          _c("md-table-head", [_vm._v("Invited Date")]),
                          _c("md-table-head", [_vm._v("Accepted Date")]),
                          _c("md-table-head", [_vm._v("Invited By")]),
                          _c("md-table-head", [_vm._v("Actions")]),
                        ],
                        1
                      ),
                      _vm._l(_vm.invitationList, function (item, index) {
                        return _c(
                          "md-table-row",
                          { key: index },
                          [
                            _c("md-table-cell", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.email) +
                                  "\n                    "
                              ),
                            ]),
                            _c("md-table-cell", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.role) +
                                  "\n                    "
                              ),
                            ]),
                            _c(
                              "md-table-cell",
                              { staticClass: "status-container" },
                              [
                                _c(
                                  "span",
                                  {
                                    class:
                                      "status-" +
                                      item.status.toLowerCase() +
                                      " invite-status",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.status.toLowerCase()) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("md-table-cell", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      item.createdDate,
                                      _vm.DATE_TYPES.standardDate
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]),
                            _c("md-table-cell", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      item.acceptedDate,
                                      _vm.DATE_TYPES.standardDate
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]),
                            _c("md-table-cell", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.inviterName) +
                                  "\n                    "
                              ),
                            ]),
                            _c(
                              "md-table-cell",
                              { staticClass: "action-buttons" },
                              [
                                item.status == "PENDING"
                                  ? _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-primary md-just-icon md-round btn-size-27",
                                        attrs: { title: "Resend Invite" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resend(item.teamInviteId)
                                          },
                                        },
                                      },
                                      [_c("md-icon", [_vm._v("email")])],
                                      1
                                    )
                                  : _vm._e(),
                                item.status == "PENDING"
                                  ? _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-danger md-just-icon md-round btn-size-27",
                                        attrs: { title: "Revoke Invite" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.revoke(
                                              item.teamInviteId,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_c("md-icon", [_vm._v("cancel")])],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _vm.invitationList.length == 0 && !_vm.isLoading
                    ? _c("div", [
                        _c("p", { staticClass: "no-result-message" }, [
                          _vm._v(
                            "No results matching your search/filter could be found."
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "md-card-actions",
                    {
                      staticClass: "page-footer",
                      attrs: { "md-alignment": "space-between" },
                    },
                    [
                      _c("div", [
                        _vm.total === _vm.pagination.perPage
                          ? _c("p", { staticClass: "card-category" }, [
                              _vm._v(
                                "\n                        Page " +
                                  _vm._s(_vm.pagination.currentPage) +
                                  " of many\n                    "
                              ),
                            ])
                          : _c("p", { staticClass: "card-category" }, [
                              _vm._v(
                                "Page " +
                                  _vm._s(_vm.pagination.currentPage) +
                                  " of " +
                                  _vm._s(_vm.totalPages)
                              ),
                            ]),
                      ]),
                      _c("pagination", {
                        staticClass: "pagination-no-border pagination-success",
                        attrs: {
                          "per-page": _vm.pagination.perPage,
                          total: _vm.total,
                        },
                        on: {
                          "change-page": function ($event) {
                            return _vm.handleChangePage(
                              $event,
                              _vm.pagination.perPage
                            )
                          },
                        },
                        model: {
                          value: _vm.pagination.currentPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.pagination, "currentPage", $$v)
                          },
                          expression: "pagination.currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ])
      : _c("div", [
          _c("div", { staticClass: "body-list" }, [
            _c(
              "div",
              { staticClass: "list-loader" },
              [
                _c("fade-loader", {
                  attrs: { loading: _vm.isListLoading, color: "#333333" },
                }),
                _c("span", [_vm._v("LOADING")]),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }