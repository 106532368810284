<template>
    <div class="customer-dashboard">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <customer-filter-component
                            :team-region-id="filters.teamRegionId"
                            @onFilterChanged="handleTeamRegionFilterChanged"
                        />
                    </div>
                    <div class="custom-toolbar-end">
                        <search-component ref="search" @onSearch="handleSearch" />
                        <!-- filters and action buttons -->
                        <div class="header-button-container">
                            <md-button
                                title="Create Customer"
                                class="md-primary md-just-icon md-round"
                                @click="createCustomer"
                            >
                                <md-icon>add</md-icon>
                            </md-button>
                            <input ref="fileInput" style="display: none" type="file" @change="onFileSelected" />
                            <md-button
                                title="Import Customers"
                                class="md-primary md-just-icon md-round pull-right header-button"
                                @click="$refs.fileInput.click()"
                            >
                                <md-icon>publish</md-icon>
                            </md-button>
                            <md-button
                                title="Export Customers"
                                class="md-primary md-just-icon md-round pull-right header-button"
                                @click="exportCustomers"
                            >
                                <md-icon>get_app</md-icon>
                            </md-button>
                        </div>
                        <div>
                            <a class="download-btn" @click="importTemplate" download>
                                Download Template
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="error-message-container">
                    <batch-upload-error-message :content="errorMessages" :has-error-message="hasError" />
                </div>
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>contact_page</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div v-if="isListLoading" class="empty-table">
                            <div class="table-loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="customerList.length">
                                <md-table class="context-menu-support custom-paginated-table">
                                    <md-table-row>
                                        <md-table-head>Name</md-table-head>
                                        <md-table-head>Email</md-table-head>
                                        <md-table-head>Phone</md-table-head>
                                        <md-table-head>Address</md-table-head>
                                        <md-table-head>Actions</md-table-head>
                                    </md-table-row>
                                    <md-table-row
                                        v-for="item in customerList"
                                        :key="item.customerId"
                                        @click.stop="showSidebar(item.customerId)"
                                    >
                                        <md-table-cell>
                                            {{ item.name }}
                                            <md-icon v-if="item.linkedUserAccount" class="green-icon">
                                                account_circle
                                                <md-tooltip md-direction="right">
                                                    This customer has a user login
                                                </md-tooltip>
                                            </md-icon>
                                        </md-table-cell>
                                        <md-table-cell>{{ item.email }}</md-table-cell>
                                        <md-table-cell>{{ item.phone }}</md-table-cell>
                                        <md-table-cell>{{ item.address }}</md-table-cell>
                                        <md-table-cell class="action-buttons">
                                            <md-button
                                                title="Update customer details"
                                                class="md-warning md-just-icon md-round"
                                                @click.stop="updateCustomer(item.customerId)"
                                            >
                                                <md-icon>edit</md-icon>
                                            </md-button>
                                            <md-button
                                                title="Delete customer"
                                                class="md-danger md-just-icon md-round"
                                                @click.stop="handleDeleteCustomer(item.customerId)"
                                            >
                                                <md-icon>delete</md-icon>
                                            </md-button>
                                        </md-table-cell>
                                    </md-table-row>
                                </md-table>
                            </div>
                            <div v-else>
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
                <md-card-actions class="page-footer" md-alignment="space-between">
                    <div>
                        <p v-if="total === pagination.perPage" class="card-category">
                            Page {{ pagination.currentPage }} of many
                        </p>
                        <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                    </div>
                    <pagination
                        v-model="pagination.currentPage"
                        class="pagination-no-border pagination-success"
                        :per-page="pagination.perPage"
                        :total="total"
                        @change-page="handleChangePage($event, pagination.perPage)"
                    />
                </md-card-actions>
            </div>

            <transition name="slide">
                <customer-sidebar
                    v-if="isShowSidebar"
                    :customer-id="customerId"
                    v-click-outside="toggleSidebarWindow"
                    @closeModal="toggleSidebarWindow"
                />
            </transition>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { CUSTOMER_DETAILS_DEFAULTS, PAGINATION_DEFAULTS } from '@/utils/defaults';
import { handleRequests, showErrorMessage, downloadFile } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { Pagination, SearchComponent, BatchUploadErrorMessage } from '@/components';
import { mapGetters, mapActions } from 'vuex';
import CreateCustomerModal from './Modals/CreateCustomerModal';
import CustomerSidebar from './CustomerSidebar';
import CustomerFilterComponent from './components/CustomerFilterComponent';

export default {
    name: 'CustomerOverview',
    mixins: [GeneralMixin],
    components: {
        FadeLoader,
        Pagination,
        CustomerSidebar,
        BatchUploadErrorMessage,
        SearchComponent,
        CustomerFilterComponent
    },
    data() {
        return {
            customerList: [],
            isListLoading: false,
            pagination: PAGINATION_DEFAULTS,
            filters: { status: 'active', teamRegionId: null },
            isShowSidebar: false,
            customerId: null,
            selectedFile: null,
            hasError: false,
            errorMessages: []
        };
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam',
            isLoading: 'isLoading',
            user: 'user/user'
        }),
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        }
    },
    async mounted() {
        // this.filters.teamRegionId = this.user.teamRegionId ? this.user.teamRegionId : null; // null = 'All'
        this.filters.teamRegionId = null; // null = 'All'
        const currentPage = Number(this.$route.query.currentPage) || 1;
        if (currentPage) {
            this.pagination.currentPage = currentPage;
        }

        const {
            data: { customers, totalCustomers }
        } = await this.getCustomerList(currentPage);

        this.setCustomerAndTotal(customers, totalCustomers);
    },
    methods: {
        ...mapActions('team', ['FETCH_TEAM_HAS_CUSTOMERS']),
        async refreshState() {
            this.FETCH_TEAM_HAS_CUSTOMERS();
        },
        async getCustomerList(pageNumber = 1, itemsPerPage = 50) {
            let response = {
                data: {
                    customers: [],
                    totalCustomers: null
                }
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/customers`;
                response = await handleRequests(endpoint, {
                    params: {
                        pageNumber,
                        itemsPerPage,
                        ...this.cleanFilters()
                    }
                });
            } catch (error) {
                const message = 'Error in getting the customer list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;
            return response;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },
        setCustomerAndTotal(data, total) {
            this.customerList = data;
            this.pagination.total = total || data.length;
        },
        async createCustomer() {
            const customerDetails = { ...CUSTOMER_DETAILS_DEFAULTS(), teamRegionId: this.user.teamRegionId || null };
            this.$modal
                .show(CreateCustomerModal, {
                    customerDetails
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.handleChangePage(1, this.pagination.perPage);
                        this.$modal.hide();
                        this.refreshState();
                    }
                });
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            const {
                data: { customers, totalCustomers }
            } = await this.getCustomerList(currentPage, perPage);
            const total = totalCustomers;
            this.setCustomerAndTotal(customers, total);
        },
        showSidebar(id) {
            this.isShowSidebar = true;
            this.customerId = id;
        },
        toggleSidebarWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown)
                this.isShowSidebar = !this.isShowSidebar;
        },
        onFileSelected(event) {
            // eslint-disable-next-line prefer-destructuring
            this.selectedFile = event.target.files[0];
            event.target.value = '';
            if (this.selectedFile) 
                this.onUpload();
        },
        async onUpload() {
            this.$_handleLoaderState(true, 'IMPORTING...');
            const fd = new FormData();
            fd.append('file', this.selectedFile);
            fd.append('mappingTemplateId', localStorage.getItem('customers.defaults.import-template') ?? 'Default');

            const api = `/api/customers/import`;
            const payload = {
                method: 'post',
                data: fd
            };

            try {
                const response = await handleRequests(api, payload);

                if (response.status === 200) {
                    this.hasError = false;
                    this.errorMessages = [];
                    this.selectedFile = '';
                    this.$notifySuccess('The customers have been successfully uploaded');
                }
                this.$_handleLoaderState(false);
                this.handleChangePage(1, this.pagination.perPage);
            } catch (error) {
                this.$_handleLoaderState(false);
                this.hasError = true;
                this.errorMessages = error.data;
                if (!error.data) {
                    const message = 'Error in importing customers';
                    showErrorMessage(this, message, error);
                }
            }
        },
        async exportCustomers() {
            this.$_handleLoaderState(true, 'EXPORTING...');
            try {
                const endpoint = `/api/customers/export`;
                const response = await handleRequests(endpoint, {
                    params: {
                        pageNumber: 1,
                        itemsPerPage: 2000,
                        ...this.cleanFilters()
                    },
                    responseType: 'blob'
                });

                const resData = response.data;
                if (resData.size === 0) {
                    this.$notifyError('There are no customers to export');
                } else {
                    const url = window.URL.createObjectURL(new Blob([resData]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'customerlist.xlsx');
                    document.body.appendChild(link);
                    link.click();
                }
            } catch (error) {
                const message = 'Error in exporting customers.';
                showErrorMessage(this, message, error);
            }

            this.$_handleLoaderState(false);
        },
        async getCustomerDetails(customerId) {
            try {
                const api = `/api/customers/${customerId}`;
                const { data } = await handleRequests(api);
                return data;
            } catch (error) {
                const message = 'Error in getting the customer details';
                showErrorMessage(this, message, error);
            }
            return {};
        },
        async updateCustomer(customerId) {
            this.$_handleLoaderState(true, 'LOADING...');
            const customerDetails = await this.getCustomerDetails(customerId);
            this.$_handleLoaderState(false);
            customerDetails.customerName = customerDetails.name;

            if (customerDetails.location == null) {
                customerDetails.location = {
                    latitude: null,
                    longitude: null
                };
            }

            if (customerDetails.teamRegionId === null) 
                customerDetails.teamRegionId = -1; // Not Set

            this.$modal
                .show(CreateCustomerModal, {
                    customerDetails: Object.assign({}, customerDetails),
                    isUpdate: true
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.handleChangePage(1, this.pagination.perPage);
                        this.$modal.hide();
                    }
                });
        },
        async deleteCustomer(customerId) {
            const payload = {
                method: 'delete'
            };
            const api = `/api/customers/${customerId}`;
            try {
                await handleRequests(api, payload);
                this.$notifySuccess('Customer was deleted!');
                this.handleChangePage(1, this.pagination.perPage);
            } catch (e) {
                const message = 'Error in deleting a customer.';
                showErrorMessage(this, message, e);
            }
        },
        handleDeleteCustomer(customerId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Customer',
                    body: 'Are you sure you want to delete this customer?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isListLoading = true;
                        await this.deleteCustomer(customerId);
                        this.refreshState();
                    }
                });
        },
        async handleSearch(data) {
            this.filters = Object.assign(this.filters, data);
            const {
                data: { customers, totalCustomers }
            } = await this.getCustomerList(1, this.pagination.perPage);

            this.setCustomerAndTotal(customers, totalCustomers);
        },
        async handleTeamRegionFilterChanged(val) {
            const newFilter = { ...val };
            const currentFilters = { ...this.filters };
            let shouldSearch = true;

            Object.getOwnPropertyNames(val).forEach((q, idx, array) => {
                shouldSearch = shouldSearch && val[q] !== (currentFilters[q] || null);
            });
            if (shouldSearch) {
                await this.handleSearch(newFilter);
            }
        },
        async importTemplate() {
            try {
                const mappingTemplate = localStorage.getItem('customers.defaults.import-template') ?? 'Default';
                const endpoint = `/api/teams/import-template-file/customers`;
                const payload = {
                    method: 'get',
                    responseType: 'blob',
                    params: {
                        mappingTemplate
                    }
                };
                const response = await handleRequests(endpoint, payload);
                downloadFile(response.data, 'Upload Customers Template.xlsx');
            } catch (error) {
                const message = 'Error in import template customers.';
                showErrorMessage(this, message, error);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.download-btn {
    color: #2b93ff !important;
    margin-top: 5px;
    display: block;
    font-size: 12px;
    cursor: pointer;
}

::v-deep .error-body div {
    margin: 0;
    margin-top: 20px;
    padding: 10px;
}

.customer-dashboard ::v-deep .md-card {
    margin-bottom: 0;
}

.green-icon {
    color: #0bda8e !important;
    margin-right: 5px;
}
</style>
