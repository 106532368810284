var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasQuestionnaires
    ? _c(
        "md-table",
        { staticClass: "context-menu-support custom-paginated-table" },
        [
          _c(
            "md-table-row",
            [
              _c("md-table-head", [_vm._v("Question")]),
              _c("md-table-head", [_vm._v("Mapping")]),
              _c("md-table-head"),
              _c(
                "md-table-head",
                [
                  _vm._v("\n            Overridable\n            "),
                  _c(
                    "md-icon",
                    {
                      staticClass: "info-icon-custom",
                      attrs: {
                        title:
                          "You will be able to override the values when assigning a shipment to a carrier",
                      },
                    },
                    [_vm._v("\n                help_outline\n            ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.$v.questionMappings.$each.$iter, function (q, ndx) {
            return _c(
              "md-table-row",
              { key: ndx },
              [
                _c(
                  "md-table-cell",
                  {
                    staticClass: "questions-column",
                    class: q.$model.isRequired ? "required-question" : "",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(q.$model.label) +
                        "\n            "
                    ),
                    _vm.showToolTip(q.$model)
                      ? _c(
                          "span",
                          [
                            _c(
                              "md-icon",
                              { staticClass: "info-icon-custom" },
                              [
                                _vm._v(
                                  "\n                    help_outline\n                    "
                                ),
                                _c("md-tooltip", [
                                  _vm._v(_vm._s(_vm.showToolTip(q.$model))),
                                ]),
                              ],
                              1
                            ),
                            q.$model.isRequired
                              ? _c(
                                  "md-icon",
                                  { staticClass: "required-icon" },
                                  [
                                    _vm._v(
                                      "\n                    emergency\n                    "
                                    ),
                                    _c("md-tooltip", [
                                      _vm._v("This is a required field"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "md-table-cell",
                  [
                    _c(
                      "form-group",
                      {
                        class: { error: q.mapping.$error },
                        attrs: { name: "question" },
                      },
                      [
                        _c("vue-select", {
                          staticClass: "custom-mapping-select",
                          attrs: {
                            reduce: function (quest) {
                              return quest.value
                            },
                            label: "text",
                            options: q.$model.isRequired
                              ? _vm.requiredMappingOptions
                              : _vm.nonRequiredMappingOptions,
                            placeholder: q.$model.isRequired
                              ? "Ask when booking"
                              : "Ignore",
                            searchable: false,
                          },
                          model: {
                            value: q.mapping.$model,
                            callback: function ($$v) {
                              _vm.$set(q.mapping, "$model", $$v)
                            },
                            expression: "q.mapping.$model",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "md-table-cell",
                  { staticClass: "action-buttons", attrs: { align: "end" } },
                  [
                    q.mapping.$model === "SetDefault"
                      ? [
                          _c(
                            "form-group",
                            {
                              class: {
                                error: q.defaultValue.$error,
                                "field-text": q.$model.type == "Text",
                                "checkbox-field": q.$model.type == "CheckBox",
                                "dropdown-field": q.$model.type == "Dropdown",
                                "textarea-custom-field":
                                  q.$model.type == "MultiLineText",
                              },
                              attrs: { name: "name" },
                            },
                            [
                              _c("CustomFieldInputs", {
                                attrs: {
                                  "custom-field-definition": q.$model,
                                  "initial-value": q.defaultValue.$model,
                                  "show-label": false,
                                  placeholder: "Value",
                                },
                                on: { changed: _vm.handleCustomFieldChanged },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    q.mapping.$model === "CustomField"
                      ? [
                          _c(
                            "form-group",
                            {
                              class: {
                                error: q.customFieldName.$error,
                              },
                              attrs: { name: "name" },
                            },
                            [
                              _c("vue-select", {
                                staticClass: "custom-mapping-select",
                                attrs: {
                                  reduce: function (cf) {
                                    return cf.name
                                  },
                                  label: "label",
                                  options: _vm.getValidCustomFields(
                                    q.$model.type
                                  ),
                                  placeholder: "Select a custom field",
                                  searchable: false,
                                },
                                model: {
                                  value: q.customFieldName.$model,
                                  callback: function ($$v) {
                                    _vm.$set(q.customFieldName, "$model", $$v)
                                  },
                                  expression: "q.customFieldName.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c(
                  "md-table-cell",
                  { staticClass: "overridable-col" },
                  [
                    q.mapping.$model === "SetDefault"
                      ? _c("md-checkbox", {
                          model: {
                            value: q.$model.overridable,
                            callback: function ($$v) {
                              _vm.$set(q.$model, "overridable", $$v)
                            },
                            expression: "q.$model.overridable",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      )
    : _c("div", [
        _vm._v("This carrier has no booking questions requiring mapping."),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }