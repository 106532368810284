<template>
    <div class="page-layout">
        <div class="page-toolbar">
            <div class="toolbar-left"></div>
            <div class="toolbar-middle">
                <input
                    type="text"
                    class="search-textbox"
                    placeholder="Just start typing to search all fields..."
                    v-model="searchText"
                    @keyup="searchTextChanged"
                    autofocus
                />
            </div>
            <div class="toolbar-right"></div>
        </div>
        <div class="page-content">
            <ag-grid-vue
                class="grid-display"
                row-model-type="serverSide"
                :class="gridThemeClass"
                :column-defs="columnDefs"
                :default-col-def="defaultColDef"
                :pagination="true"
                :pagination-page-size="gridPageSize"
                :pagination-page-size-selector="[10, 15, 20, 50, 100]"
                :suppress-excel-export="false"
                :auto-group-column-def="gridAutoGroupColumnDef"
                :row-selection="rowSelection"
                :side-bar="gridSideBar"
                :pivot-panel-show="gridPivotPanelShow"
                :group-selects-children="true"
                :overlay-loading-template="gridLoadingOverlayTemplate"
                @grid-ready="onGridReady"
                :pagination-number-formatter="paginationNumberFormatter"
                :tooltip-interaction="true"
            ></ag-grid-vue>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins';
import { mapGetters, mapActions } from 'vuex';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridVue } from 'ag-grid-vue';
import { handleRequests } from '@/helpers';
import _ from 'lodash';
import { STATUS_CONSTANTS } from '@/utils/constants';
import StopStatusRenderer from './grid-components/StopStatusRenderer';
import GridDropDownFilter from './grid-components/GridDropDownFilter';
import GridLinkRenderer from './grid-components/GridLinkRenderer';
import GridItemDataTooltip from './grid-components/GridItemDataTooltip';
import GridKeyValueDataTooltip from './grid-components/GridKeyValueDataTooltip';

// Much of this code is oriented around the AG-GRID component.
// https://www.ag-grid.com/vue-data-grid/vue2/

export default {
    name: 'JobsFilter',
    components: {
        AgGridVue,
        // eslint-disable-next-line vue/no-unused-components
        GridDropDownFilter,
        // eslint-disable-next-line vue/no-unused-components
        StopStatusRenderer,
        // eslint-disable-next-line vue/no-unused-components
        GridLinkRenderer,
        // eslint-disable-next-line vue/no-unused-components
        GridItemDataTooltip,
        // eslint-disable-next-line vue/no-unused-components
        GridKeyValueDataTooltip
    },
    mixins: [GeneralMixin],
    async mounted() {
        await this.FETCH_TEAM_CUSTOMERS();
        this.setupColumns();
    },
    data() {
        return {
            columnDefs: null, // We set the column definitions later because of the need to fill values in custom filters
            defaultColDef: {
                // All columns will get these properties unless otherwise specified (overridden on the column definitions)
                enableRowGroup: false,
                enablePivot: false,
                enableValue: false,
                editable: false,
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    // suppressAndOrCondition: true
                    maxNumConditions: 1
                }
            },
            rowSelection: 'multiple',
            gridThemeClass: 'ag-theme-quartz',
            showDarkTheme: false,
            gridPageSize: 10,
            gridSideBar: {
                // Configures the pop out side bar on the right where user's can select additional columns to show
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressSideButtons: false,
                            suppressColumnFilter: false,
                            suppressColumnSelectAll: false,
                            suppressColumnExpandAll: false
                        }
                    }
                ],
                allowDragFromColumnsToolPanel: true,
                suppressRowGroups: true,
                suppressMovable: true,
                suppressValues: true,
                suppressPivots: true
            },
            gridRowGroupPanelShow: 'always',
            gridPivotPanelShow: 'never',
            gridAutoGroupColumnDef: {
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    checkbox: true
                }
            },
            paginationNumberFormatter: (params) => {
                if (params.value >= 10000) 
                    return 'Over 10,000';
                return params.value;
            },
            gridApi: null,
            stopsSearchResults: [],
            totalStops: 0,
            isGridLoading: false,
            gridLoadingOverlayTemplate:
                '<div aria-live="polite" aria-atomic="true" style="position:absolute;top:0;left:0;right:0; bottom:0; background: url(https://ag-grid.com/images/ag-grid-loading-spinner.svg) center no-repeat" aria-label="loading"></div>',
            searchText: ''
        };
    },
    computed: {
        ...mapGetters({
            customerList: 'team/customers'
        })
    },
    methods: {
        ...mapActions('team', ['FETCH_TEAM_CUSTOMERS']),

        async onGridReady(params) {
            this.gridApi = params.api;
            params.api.setGridOption('serverSideDatasource', this.createGridServer());
        },
        toggleGridTheme() {
            this.showDarkTheme = !this.showDarkTheme;
            if (this.showDarkTheme) {
                this.gridThemeClass = 'ag-theme-quartz-dark';
            } else {
                this.gridThemeClass = 'ag-theme-quartz';
            }
        },
        searchTextChanged() {
            // When the user types, we don't want to re-search on every letter pressed.
            // So we debounce the search to only fire after the user has stopped typing for X milliseconds.
            _.debounce(() => {
                this.gridApi.refreshServerSide();
            }, 1500)();
        },
        setupColumns() {
            this.columnDefs = [
                // All fields returned are as per the Locate2u.Search.Models.StopElasticModel object
                // Additional IDs are available as follows:
                // StopId, TeamId, UserId,  BrandId, CustomerId, TripId, ShipmentId, TeamRegionId, CarrierTeamId, TeamMemberInvoiceId,  CustomerInvoiceId, RateGroupId,
                {
                    // This column will hold the stop ref, and contain checkboxes for row selection.
                    // It is locked to the left and can't be moved. When scrolling right (due to lots of columns)
                    // this column will always remain visible
                    headerName: 'Reference',
                    field: 'stopRef',
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    showDisabledCheckboxes: true,
                    pinned: 'left',
                    lockPosition: 'left',
                    suppressMovable: true,
                    lockPinned: true,
                    valueGetter: (params) => {
                        return { linkPath: `/stops/details/${params.data.stopId}`, linkText: params.data.stopRef };
                    },
                    cellRenderer: 'GridLinkRenderer'
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    valueGetter: (params) => {
                        return { status: params.data.status };
                    },
                    cellRenderer: 'StopStatusRenderer',
                    floatingFilterComponent: 'GridDropDownFilter',
                    floatingFilterComponentParams: {
                        dropdownValues: STATUS_CONSTANTS
                    },
                    cellStyle: { textAlign: 'center' }
                },
                { headerName: 'Source', field: 'source', initialHide: true },
                { headerName: 'Source Ref', field: 'sourceReference' },
                { headerName: 'Shipment Ref', field: 'shipmentRef' },
                {
                    headerName: 'Dates & Times',
                    children: [
                        {
                            headerName: 'Trip Date',
                            field: 'tripDate',
                            filter: 'agDateColumnFilter',
                            valueGetter: (params) => {
                                let text = '';
                                if (params.data.tripDate) {
                                    const dt = new Date(params.data.tripDate);
                                    text = dt.toDateString();
                                }
                                return { linkPath: `/trips/details/${params.data.tripId}`, linkText: text };
                            },
                            cellRenderer: 'GridLinkRenderer'
                        },
                        {
                            headerName: 'Appointment Date',
                            field: 'appointmentDate',
                            filter: 'agDateColumnFilter',
                            valueFormatter: (params) => {
                                if (params.value) {
                                    const dt = new Date(params.value);
                                    return dt.toDateString();
                                }
                                return '';
                            }
                        },
                        {
                            headerName: 'Appointment Time',
                            field: 'appointmentTime'
                        },
                        {
                            headerName: 'Time Window Start',
                            field: 'timeWindowStart',
                            initialHide: true
                        },
                        {
                            headerName: 'Time Window End',
                            field: 'timeWindowEnd',
                            initialHide: true
                        }
                    ]
                },
                { headerName: 'Trip Status', field: 'tripStatus', initialHide: true },
                {
                    headerName: 'Item Count',
                    field: 'itemsCount',
                    cellDataType: 'number',
                    filter: 'agNumberColumnFilter',
                    tooltipComponent: 'GridItemDataTooltip',
                    tooltipComponentParams: { headerText: 'Items', tableJsonField: 'itemsJson' },
                    tooltipField: 'itemsCount'
                },
                {
                    headerName: 'Driver Details',
                    children: [
                        { headerName: 'Driver Name', field: 'driverName' },
                        { headerName: 'Driver Email', field: 'driverEmail', initialHide: true }
                    ]
                },
                { headerName: 'Address', field: 'address', initialHide: true },
                { headerName: 'Location Name', field: 'name', initialHide: true },
                { headerName: 'Type', field: 'type', initialHide: true },
                {
                    headerName: 'Rating',
                    field: 'rating',
                    initialHide: true,
                    cellDataType: 'number',
                    filter: 'agNumberColumnFilter'
                },
                {
                    headerName: 'Duration',
                    field: 'durationMinutes',
                    initialHide: true,
                    cellDataType: 'number',
                    filter: 'agNumberColumnFilter'
                },
                {
                    headerName: 'Run #',
                    field: 'runNumber',
                    initialHide: true,
                    cellDataType: 'number',
                    filter: 'agNumberColumnFilter'
                },
                {
                    headerName: 'Customer Name',
                    field: 'customerName',
                    initialHide: false,
                    floatingFilterComponent: 'GridDropDownFilter',
                    floatingFilterComponentParams: {
                        dropdownValues: this.customerList.map((x) => x.value)
                    }
                },
                { headerName: 'Team Region', field: 'teamRegionName', initialHide: true },
                { headerName: 'Rate Group Name', field: 'rateGroupName', initialHide: true },
                {
                    headerName: 'Contact Details',
                    children: [
                        { headerName: 'Contact Name', field: 'contactName' },
                        { headerName: 'Contact Phone', field: 'contactPhone', initialHide: true },
                        { headerName: 'Contact Email', field: 'contactEmail', initialHide: true }
                    ]
                },
                {
                    headerName: 'Invoice Numbers',
                    children: [
                        {
                            headerName: 'Driver Invoice',
                            field: 'teamMemberInvoiceNumber',
                            initialHide: false,
                            valueGetter: (params) => {
                                return {
                                    linkPath: `/team-member-invoices/details/${params.data.teamMemberInvoiceId}`,
                                    linkText: params.data.teamMemberInvoiceNumber
                                };
                            },
                            cellRenderer: 'GridLinkRenderer'
                        },
                        {
                            headerName: 'Customer Invoice',
                            field: 'customerInvoiceNumber',
                            initialHide: false,
                            valueGetter: (params) => {
                                return {
                                    linkPath: `/customer-invoices/details/${params.data.customerInvoiceId}`,
                                    linkText: params.data.customerInvoiceNumber
                                };
                            },
                            cellRenderer: 'GridLinkRenderer'
                        }
                    ]
                },
                { headerName: 'Skills', field: 'skills', initialHide: true },
                { headerName: 'Services', field: 'services', initialHide: true },
                { headerName: 'Brand', field: 'brandName' },
                {
                    headerName: 'Custom Fields',
                    field: 'customFields',
                    initialHide: false,
                    filter: null,
                    tooltipComponent: 'GridKeyValueDataTooltip',
                    tooltipComponentParams: { headerText: 'Custom Fields', tableJsonField: 'customFields' },
                    tooltipField: 'customFields',
                    valueGetter: (params) => {
                        if (
                            params.data.customFields &&
                            params.data.customFields !== '' &&
                            params.data.customFields !== '{}'
                        ) {
                            return 'Custom Field List';
                        }
                        return '';
                    }
                },
                { headerName: 'Notes', field: 'notes', initialHide: true },
                {
                    headerName: 'Capacity',
                    children: [
                        { headerName: 'Weight', field: 'weight', initialHide: true },
                        { headerName: 'Height', field: 'height', initialHide: true },
                        { headerName: 'Width', field: 'width', initialHide: true },
                        { headerName: 'Length', field: 'length', initialHide: true },
                        { headerName: 'Volume', field: 'volume', initialHide: true },
                        { headerName: 'Quantity', field: 'quantity', initialHide: true }
                    ]
                }
            ];
        },
        createGridServer() {
            // This is a 'server' for the Grid server side model. It acts as a facade for calling our own API.
            // Normally you would not use this if you just want to get all your data in one request and show in the grid.
            // However in this approach we are using ElasticSearch to handle all aspects of paging, filtering, and sorting.
            // So the server side model facade lets us control all of that on the server side.
            const t = this;
            return {
                async getRows(params) {
                    const request = this.convertToServerSideRequest(params.request);
                    const result = await this.performSearch(request);

                    if (result) {
                        params.success({
                            rowData: result.stops,
                            rowCount: result.totalStops
                        });
                    } else {
                        params.fail();
                    }
                },
                convertToServerSideRequest(gridRequest) {
                    // We update the request to include the search text that sits outside the grid
                    const req = {
                        ...gridRequest,
                        SearchAllFields: t.searchText
                    };
                    return req;
                },
                async performSearch(request) {
                    t.isGridLoading = true;
                    t.gridApi.showLoadingOverlay();

                    const api = `/api/jobs/search`;
                    const payload = {
                        method: 'post',
                        data: request
                    };
                    try {
                        const response = await handleRequests(api, payload);
                        const stopSearchResult = response.data ? response.data : [];
                        t.isGridLoading = false;
                        t.gridApi.hideOverlay();
                        return stopSearchResult;
                    } catch (e) {
                        t.isGridLoading = false;
                        t.gridApi.hideOverlay();
                        return null;
                    }
                }

                // Example of a request object that is sent from Ag Grid (params.request)
                // {
                //     "startRow": 0,
                //     "endRow": 100,
                //     "rowGroupCols": [],
                //     "valueCols": [],
                //     "pivotCols": [],
                //     "pivotMode": false,
                //     "groupKeys": [],
                //     "filterModel": {
                //         "tripDate": {
                //             "dateFrom": "2024-01-31 00:00:00",
                //             "dateTo": null,
                //             "filterType": "date",
                //             "type": "equals"
                //         },
                //         "sourceReference": {
                //             "filterType": "text",
                //             "type": "contains",
                //             "filter": "CODE2"
                //         }
                //     },
                //     "sortModel": [
                //         {
                //         "sort": "asc",
                //         "colId": "status"
                //         }
                //     ]
                // }
            };
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-quartz.css';
::v-deep .layout-parent {
    height: 100%;
}
.page-layout {
    height: 90vh;
    width: 100%;
    display: grid;
    grid-template-rows: 60px 1fr;
    justify-items: center;
}
.page-toolbar {
    width: 100%;
    grid-row: 1;
    display: grid;
    grid-template-columns: 1fr 6fr 1fr;
    justify-items: stretch;
}
.toolbar-left {
    grid-column: 1;
}
.toolbar-middle {
    grid-column: 2;
}
.toolbar-right {
    grid-column: 3;
    justify-content: right;
    display: flex;
}
.search-textbox {
    width: 100%;
    border: 1px solid #999;
    border-radius: 8px;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    background-color: #f8f8f8;
    align-items: center;
    justify-self: center;
}
.page-content {
    grid-row: 2;
    width: 100%;
}
.grid-display {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.ag-theme-quartz-dark {
    --ag-odd-row-background-color: #1f2f3a;
}
.ag-theme-quartz-dark .ag-row-odd {
    background-color: var(--ag-odd-row-background-color);
}
.ag-theme-quartz {
    --ag-odd-row-background-color: #fbfbfb;
}
.ag-theme-quartz .ag-row-odd {
    background-color: var(--ag-odd-row-background-color);
}
</style>
