/* eslint-disable no-restricted-properties */
import GoogleMapsLoader from 'google-maps';
import moment from 'moment';
// import { reactive, toRefs } from '@vue/composition-api';

async function loadGoogleMap() {
    GoogleMapsLoader.KEY = process.env.VUE_APP_GOOGLE_API_KEY;
    GoogleMapsLoader.LIBRARIES = ['geometry', 'places', 'drawing'];
    GoogleMapsLoader.VERSION = '3.53';

    return new Promise((resolve) => {
        GoogleMapsLoader.load((google) => {
            resolve(google);
        });
    });
}

function createDriverMarker(google, { latlng, map, html, draggable }) {
    /*
        grabbed from: https://blackatlascreative.com/blog/custom-clickable-google-map-markers-with-images/
    */

    // eslint-disable-next-line no-unused-vars

    class HTMLMapMarker extends google.maps.OverlayView {
        constructor({ latlng, map, html, draggable }) {
            super();
            this.latlng = latlng;
            this.html = html;
            this.draggable = draggable;
            this.setMap(map);
        }

        // Create the div with content and add a listener for click events
        createDiv() {
            this.div = document.createElement('div');
            this.div.style.position = 'absolute';
            if (this.html) {
                this.div.innerHTML = this.html;
            }
            google.maps.event.addDomListener(this.div, 'click', (event) => {
                google.maps.event.trigger(this, 'click');
            });

            google.maps.event.addDomListener(this.div, 'mouseover', (event) => {
                google.maps.event.trigger(this, 'mouseover');
            });

            google.maps.event.addDomListener(this.div, 'mouseout', (event) => {
                google.maps.event.trigger(this, 'mouseout');
            });

            // Drag driver marker
            this.div.draggable = this.draggable;
            if (this.div.draggable) {
                const me = this;
                google.maps.event.addDomListener(this.get('map').getDiv(), 'mouseleave', () => {
                    google.maps.event.trigger(this.div, 'mouseup');
                });

                google.maps.event.addDomListener(this.div, 'mousedown', (event) => {
                    me.map.set('draggable', false);
                    me.set('origin', event);

                    me.moveHandler = google.maps.event.addDomListener(me.map.getDiv(), 'mousemove', (e) => {
                        const { origin } = me;
                        const left = origin.clientX - e.clientX;
                        const top = origin.clientY - e.clientY;
                        const pos = me.getProjection().fromLatLngToDivPixel(me.getPosition());
                        const latLng = me
                            .getProjection()
                            .fromDivPixelToLatLng(new google.maps.Point(pos.x - left, pos.y - top));
                        me.set('origin', e);
                        me.setPosition(latLng);
                        me.draw();
                    });
                });

                google.maps.event.addDomListener(this.div, 'mouseup', () => {
                    me.map.set('draggable', this.draggable);
                    google.maps.event.removeListener(me.moveHandler);
                });

                this.getPanes().floatPane.appendChild(this.div);
            }
        }

        // Append to the overlay layer
        // Appending to both overlayLayer and overlayMouseTarget which should allow this to be clickable
        appendDivToOverlay() {
            const panes = this.getPanes();
            if (panes) {
                panes.overlayLayer.appendChild(this.div);
                panes.overlayMouseTarget.appendChild(this.div);
            }
        }

        // Position the div according to the coordinates
        positionDiv() {
            const point = this.getProjection()?.fromLatLngToDivPixel(this.latlng);
            if (point && this.div) {
                this.div.style.left = `${point.x}px`;
                this.div.style.top = `${point.y}px`;
            }
        }

        // Create the div and append to map
        draw() {
            if (!this.div) {
                this.createDiv();
                this.appendDivToOverlay();
            }
            this.positionDiv();
        }

        // Remove this from map
        remove() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            }
        }

        // Return lat and long object
        getPosition() {
            return this.latlng;
        }

        // Set new position
        setPosition(newPosition) {
            this.latlng = newPosition;
            this.positionDiv();
        }
    }
    return new HTMLMapMarker({ latlng, map, html, draggable });
}

function callMapFunction(obj, method, params) {
    return obj[method](...params);
}

function createMap(google, element, lat, lng, mapOptions = {}) {
    const center = new google.maps.LatLng(lat, lng);

    const obj = {
        center,
        streetViewControl: false,
        mapTypeControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP
        },
        fullscreenControl: false,
        zoom: 6,
        zoomControl: window.matchMedia('(min-width: 767px)').matches,
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
        }
    };

    const options = Object.assign(obj, mapOptions);

    return new google.maps.Map(element, options);
}

function handleListener(map, listener, callback) {
    map.addListener(listener, callback);
}

function createBounds(google) {
    return new google.maps.LatLngBounds();
}

function createPanorama(google, map, element = null) {
    if (element) {
        return new google.maps.StreetViewPanorama(element);
    }
    return map.getStreetView();
}

function setPanoramaOptions(panorama, options, pov) {
    panorama.setPov(pov);
    panorama.setOptions(options);
}

function toggleStreetView(panorama, lat, lng) {
    panorama.setPosition({ lat, lng });
    panorama.setVisible(true);
}

function closeStreetView(panorama) {
    panorama.setVisible(false);
}

function getMarkerOptions(google, type, markers) {
    switch (type) {
        case 'statusHistory':
        case 'heartBeat':
            return Object.assign(
                {},
                {
                    scaledSize: new google.maps.Size(22, 22),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(11, 11),
                    url: markers[type]
                }
            );
        case 'geofence':
            return Object.assign(
                {},
                {
                    path: 'M 5 0 10 10 5 20 0 10 Z',
                    strokeColor: 'black',
                    fillColor: markers.color,
                    fillOpacity: 1,
                    strokeOpacity: 0.8,
                    anchor: new google.maps.Point(5, 11)
                }
            );
        case 'stop':
            return Object.assign(
                {},
                {
                    path:
                        'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
                    fillColor: markers.color,
                    fillOpacity: 0.8,
                    strokeColor: 'black',
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    anchor: new google.maps.Point(0, 0),
                    labelOrigin: new google.maps.Point(0, -30),
                    scale: 0.75
                }
            );
        case 'driverBreak':
            return Object.assign(
                {},
                {
                    path:
                        'M158-110v-118h644v118H158Zm171-170q-72 0-121.5-49.5T158-451v-399h623q48.26 0 82.63 34.65T898-733v144q0 47.7-34.37 82.35Q829.26-472 781-472h-53v21q0 72-50.22 121.5Q627.55-280 556-280H329Zm399-309h52v-144h-52v144Z',
                    fillColor: markers.color,
                    fillOpacity: 0.8,
                    strokeColor: 'black',
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(-400, -30),
                    labelOrigin: new google.maps.Point(0, -30),
                    scale: 0.03
                }
            );
        case 'optimiseStop':
            return Object.assign(
                {},
                {
                    scaledSize: new google.maps.Size(24, 36),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(12, 18),
                    url: markers[type]
                }
            );
        case 'driver':
            return Object.assign(
                {},
                {
                    scaledSize: new google.maps.Size(48, 50),
                    origin: new google.maps.Point(0, 0),
                    // anchor should be the base of the marker + length of the pin
                    anchor: new google.maps.Point(20, 40),
                    url: markers[type]
                }
            );
        case 'stops':
        case 'unassignedstop':
            return Object.assign(
                {},
                {
                    path: google.maps.SymbolPath.CIRCLE,
                    strokeColor: 'black',
                    fillColor: '#ffa500',
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    strokeWeight: 1,
                    scale: 4
                }
            );
        case 'timeSeries':
        default:
            return Object.assign(
                {},
                {
                    path: google.maps.SymbolPath.CIRCLE,
                    anchor: new google.maps.Point(0, 0),
                    scale: 6,
                    fillColor: markers.fillColor,
                    strokeColor: markers.strokeColor,
                    fillOpacity: 1,
                    strokeWeight: 1
                }
            );
    }
}

function setMarkerIcon(marker, iconOptions) {
    const icon = this.$_map_getMarkerOptions('', iconOptions);
    const newIcon = Object.assign({}, icon, iconOptions);
    marker.setIcon(newIcon);
}

function createLatLngPoint(google, lat, lng) {
    return new google.maps.LatLng(lat, lng);
}

function setMarkers(google, map, latitude, longitude, type, markers, label = '', shouldShow = true) {
    const pos = createLatLngPoint(google, latitude, longitude);
    const icon = getMarkerOptions(google, type, markers);
    let zIndex = 1;
    if (type === 'driver' || type === 'stop') 
        zIndex = 10;
    else if (type === 'statusHistory') 
        zIndex = 5;
    const marker = new google.maps.Marker({
        position: pos,
        map: shouldShow ? map : null,
        icon,
        zIndex
    });
    if (label) {
        marker.setLabel(label);
    }
    return marker;
}

function calculateMarkerCoordinates(google, map, marker, options = {}) {
    // for getting the pixel location of the marker
    const scale = Math.pow(2, map.getZoom());
    const INFO_WINDOW_HEIGHT = options.height;
    const INFO_WINDOW_WIDTH = options.width || 132.5;
    const nw = createLatLngPoint(
        google,
        map
            .getBounds()
            .getNorthEast()
            .lat(),
        map
            .getBounds()
            .getSouthWest()
            .lng()
    );

    const worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
    const worldCoordinate = map.getProjection().fromLatLngToPoint(marker.getPosition());

    return {
        left: `${Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale - INFO_WINDOW_WIDTH)}px`,
        top: `${Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale) - INFO_WINDOW_HEIGHT}px`
    };
}

function createPath(google, routeStr) {
    return google.maps.geometry.encoding.decodePath(routeStr);
}

function getDistance(startLocation, endLocation) {
    // in meters
    return google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(startLocation.latitude, startLocation.longitude),
        new google.maps.LatLng(endLocation.latitude, endLocation.longitude)
    );
}

function encodeLine(routeLine) {
    let path = null;
    routeLine.forEach((seg) => {
        if (path === null) 
            path = seg.getPath().getArray();
        else 
            path = path.concat(seg.getPath().getArray());
    });
    return google.maps.geometry.encoding.encodePath(path);
}

function getPath(routeLine) {
    return routeLine.getPath();
}

function setPath(routeLine, pathToSet) {
    routeLine.setPath(pathToSet);
}

function createRouteLine(google, path = [], strokeColor, options, map) {
    return new google.maps.Polyline({
        ...options,
        path,
        strokeColor,
        map
    });
}

function addMapElement(element, map) {
    element.setMap(map);
}

function removeMapElement(element) {
    element.setMap(null);
}

function createCircle(map, radius, options) {
    const circle = new google.maps.Circle({
        map,
        radius,
        ...options
    });
    return circle;
}

function getMarkerOpacity(lastupdate) {
    // #todo, set this value from State
    const TIME_INTERVAL_A = 60 * 1;
    const TIME_INTERVAL_B = 60 * 6;

    let opacity = 1;

    const currentTime = moment(new Date());
    const lastUpdate = moment(lastupdate);
    const minuteDiff = currentTime.diff(lastUpdate, 'minutes');
    if (minuteDiff > TIME_INTERVAL_A && minuteDiff < TIME_INTERVAL_B) {
        // First level transparency
        opacity = 0.6;
    } else if (minuteDiff >= TIME_INTERVAL_B) {
        opacity = 0.2;
    }

    return opacity;
}

function useMapUtils() {
    return {
        loadGoogleMap,
        createDriverMarker,
        callMapFunction,
        closeStreetView,
        createMap,
        handleListener,
        createBounds,
        createPanorama,
        setPanoramaOptions,
        toggleStreetView,
        getMarkerOptions,
        setMarkerIcon,
        setMarkers,
        calculateMarkerCoordinates,
        createPath,
        getDistance,
        encodeLine,
        getPath,
        setPath,
        createRouteLine,
        createLatLngPoint,
        addMapElement,
        removeMapElement,
        createCircle,
        getMarkerOpacity
    };
}

export default useMapUtils;
