<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <div class="custom-toolbar md-layout">
                    <div class="md-layout-item custom-toolbar-start md-layout md-small-size-70 md-size-70">
                        <div class="filter-steps--container">
                            <team-region-member-filter-options
                                :team-region-id="teamRegionId"
                                @changed="handleTeamRegionMemberChanged"
                                :hide-none="true"
                                :hide-unassigned="true"
                                class="team-region"
                            />
                        </div>
                        <div class="md-layout-item">
                            <date-range-filter :is-single-date="false" @onChanged="onDateChanged" />
                        </div>
                    </div>
                    <div class="md-layout-item custom-toolbar-end">
                        <select-rating-filter
                            :selected-rating="selectedRatingValue"
                            :ratings="ratingsCount"
                            @selectedRating="selectRating"
                        />

                        <md-button
                            title="Export Stop Rating report"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click="onExportToExcel"
                        >
                            <md-icon>get_app</md-icon>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>summarize</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div v-if="!ratingsList || ratingsList.length == 0">
                            <h3>Stop Rating Report</h3>
                            <p>
                                No results to display.
                            </p>
                        </div>
                        <div v-else>
                            <div class="report-container">
                                <stop-rating-report-details :ratings-list="ratingsList" />
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.rating-field {
    width: 200px;
    padding: 10px 0px;
    margin: 0px;
}

.filter-steps--container {
    padding-top: 10px;

    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }

    .filter-step-date {
        min-width: 250px;
        margin-right: 0;
        min-height: initial;
        position: absolute;
        z-index: 1;
    }

    .filter-button--container {
        display: inline-block;
    }

    ::v-deep .md-datepicker:after,
    ::v-deep .md-datepicker:before {
        bottom: 0;
        height: 0;
    }

    ::v-deep .md-datepicker:before,
    ::v-deep .md-datepicker:after {
        bottom: 30px;
        content: 'Trip date';
        color: #aaaaaa;
        font-weight: 400;
        font-size: 14px;
        transform: none;
        z-index: 1;
    }

    ::v-deep .md-has-value:before,
    ::v-deep .md-has-value:after {
        bottom: 0;
        height: 0;
        content: '';
    }

    ::v-deep .md-datepicker .md-input {
        position: absolute;
        z-index: 9;
        width: 150px;
    }

    ::v-deep .md-datepicker .md-input-action {
        z-index: 99;
        right: 52px;
    }
}

::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}

::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}

::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}

::v-deep .vs__search {
    cursor: pointer;
}

::v-deep .filter-member-name .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    display: block;
}
</style>
<script>
import moment from 'moment';
import { DATE_TYPES } from '@/utils/constants';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import { TeamRegionMemberFilterOptions } from '@/components';
import StopRatingReportDetails from './StopRatingReportDetails';
import { handleRequests, showErrorMessage } from '../../helpers';
import { DateRangeFilter, SelectRatingFilter } from './components';

export default {
    name: 'StopRatingReport',
    components: { StopRatingReportDetails, TeamRegionMemberFilterOptions, DateRangeFilter, SelectRatingFilter },
    mixins: [GeneralMixin],
    data() {
        return {
            teamMemberFilterValue: '',
            selectedTeamRegionId: null,
            reportDateFrom: null,
            reportDateTo: null,
            ratingsList: [],
            ratingsCount: [],
            userFullName: '',
            selectedRatingValue: null
        };
    },

    computed: {
        teamRegionId() {
            return this.user.teamRegionId;
        }
    },
    watch: {
        selectedRatingValue() {
            this.getStopRatingList();
        }
    },
    created() {
        this.getStopRatingList = _.debounce(this.getStopRatingList, 500);
    },
    methods: {
        ...mapGetters({
            user: 'user/user',
            isLoading: 'isLoading'
        }),

        getQueryParams() {
            const query = new URLSearchParams();
            if (this.teamMemberFilterValue) 
                query.append('userId', this.teamMemberFilterValue);
            if (this.selectedTeamRegionId) 
                query.append('teamRegionId', this.selectedTeamRegionId);
            query.append('startDate', moment(this.reportDateFrom).format('YYYY-MM-DD'));
            query.append('endDate', moment(this.reportDateTo).format('YYYY-MM-DD'));
            if (this.selectedRatingValue) 
                query.append('rating', this.selectedRatingValue);

            return query;
        },

        onExportToExcel() {
            const me = this;
            if (me.reportDateFrom && me.reportDateTo) {
                const reportDate = `${moment(me.reportDateFrom).format(DATE_TYPES.internationalDate)}-${moment(
                    me.reportDateTo
                ).format(DATE_TYPES.internationalDate)}`;

                const reportName = `StopRatingReport-${reportDate}.xlsx`;

                const query = this.getQueryParams();
                const endpoint = `/api/reports/stop-rating-report/export-to-excel/?${query.toString()}`;

                handleRequests(endpoint, {
                    method: 'get',
                    responseType: 'blob'
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', reportName);
                        document.body.appendChild(link);
                        link.click();

                        me.isLoading = false;
                        me.$_handleLoaderState(false);
                    })
                    .catch((e) => {
                        me.isLoading = false;
                        me.$_handleLoaderState(false);

                        const message = 'There was an error fetching the stop rating report. Please try again later.';
                        showErrorMessage(this, message, e);
                    });
            }
        },

        async getRatingCounts() {
            const query = this.getQueryParams();
            const endpoint = `/api/reports/ratings/?${query.toString()}`;

            handleRequests(endpoint)
                .then((response) => {
                    const { data } = response.data;

                    this.ratingsCount = data;
                })
                .catch((e) => {
                    const message = 'There was an error fetching the stop ratings. Please try again later.';
                    showErrorMessage(this, message, e);
                });
        },

        async getStopRatingList(refreshRatings = true) {
            const me = this;

            if (me.reportDateFrom && me.reportDateTo) {
                me.isLoading = true;
                me.$_handleLoaderState(true);

                const query = this.getQueryParams();
                const endpoint = `/api/reports/stop-rating-report/?${query.toString()}`;

                handleRequests(endpoint)
                    .then((response) => {
                        me.isLoading = false;
                        const { data } = response.data;

                        me.ratingsList = data;

                        me.$_handleLoaderState(false);

                        if (refreshRatings) 
                            this.getRatingCounts();
                    })
                    .catch((e) => {
                        me.isLoading = false;
                        me.$_handleLoaderState(false);

                        const message = 'There was an error fetching the stop rating report. Please try again later.';
                        showErrorMessage(this, message, e);
                    });
            }
        },

        async onDateChanged(val) {
            this.reportDateFrom = val.startDate;
            this.reportDateTo = val.endDate;
            this.getStopRatingList();
        },

        handleTeamRegionMemberChanged(val) {
            this.teamMemberFilterValue = val.publicUserId === 'unassigned' ? null : val.publicUserId;
            this.selectedTeamRegionId = val.teamRegionId;
            this.getStopRatingList();
        },
        async getTeamMembers(tripDate = null) {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS', {
                date: tripDate
            });
        },

        selectRating(rating) {
            this.selectedRatingValue = rating;
            this.getStopRatingList(false);
        }
    },

    async mounted() {
        const me = this;
        me.userFullName = me.user().fullName;
        this.getTeamMembers();
    }
};
</script>
