<template>
    <div class="actions-panel">
        <div class="map-panel">
            <button @click="$emit('recalculateOffers')" :class="{ active: !isAssigned }" :disabled="isAssigned">
                <md-icon>refresh</md-icon>
                <md-tooltip class="toggle-tooltip" md-direction="top">
                    Recalculate Offers
                    <br />
                    (latest: {{ formattedCalculationTime }})
                </md-tooltip>
            </button>
        </div>

        <div class="map-panel" v-if="pinnedOffer">
            <button
                @click="toggleRouteDisplay('Current')"
                :class="{ active: routeDisplay === 'Current' || routeDisplay === 'Both' }"
            >
                <md-icon>directions</md-icon>
                <md-tooltip class="toggle-tooltip" md-direction="top">Show Current Route</md-tooltip>
            </button>

            <button
                @click="toggleRouteDisplay('Offer')"
                :class="{ active: routeDisplay === 'Offer' || routeDisplay === 'Both' }"
            >
                <md-icon>route</md-icon>
                <md-tooltip class="toggle-tooltip" md-direction="top">Show Offered Route</md-tooltip>
            </button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'ActionsPanel',

    props: {
        shipmentDetails: {
            type: Object,
            default: null
        },
        pinnedOffer: {
            type: Object,
            default: null
        },
        routeDisplay: {
            type: String,
            default: () => 'Both'
        },
        calculationDate: {
            type: String,
            default: null
        }
    },

    computed: {
        isAssigned() {
            return (
                this.shipmentDetails &&
                (this.shipmentDetails.assignedTo?.publicUserId || this.shipmentDetails.carrierTeamId)
            );
        },

        assignedName() {
            if (this.isAssigned) {
                if (this.shipmentDetails.assignedTo?.publicUserId) 
                    return this.shipmentDetails.assignedTo.fullName;

                return this.shipmentDetails.carrierTeam.company;
            }

            return null;
        },

        formattedCalculationTime() {
            if (!this.calculationDate) 
                return 'none';

            const isAfter = moment.utc().isAfter(this.calculationDate, 'day');
            if (isAfter) 
                return moment(this.calculationDate).format('lll');

            return moment(this.calculationDate).format('LT');
        }
    },

    emits: ['recalculateOffers'],

    methods: {
        toggleRouteDisplay(option) {
            let newValue = 'None';

            if (this.routeDisplay === option) {
                // We're turning off the only selected option
                newValue = 'None';
            } else if (this.routeDisplay === 'None') {
                // Turn this option on as the only option
                newValue = option;
            } else if (this.routeDisplay !== 'Both') {
                // Both options are now turned on
                newValue = 'Both';
            } else if (option === 'Offer') {
                newValue = 'Current';
            } else {
                newValue = 'Offer';
            }

            // TODO: Save this updated setting to local storage?

            this.$emit('update:routeDisplay', newValue);
        }
    }
};
</script>

<style lang="scss" scoped>
$themeColor: #2b93ff;

.actions-panel {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    display: flex;

    .map-panel {
        margin: 0 3px;
    }

    button {
        padding: 10px;
        border: none;
        background-color: rgba(255, 255, 255, 0.9);
        cursor: pointer;
        margin: 0 auto;

        &.active {
            ::v-deep .md-icon {
                color: $themeColor;
            }
        }

        &.disabled {
            ::v-deep .md-icon {
                color: #b2b2b2;
                opacity: 0.25;
                cursor: not-allowed !important;
            }
        }
    }
}

.toggle-tooltip {
    margin-left: 20px !important;
}
</style>
