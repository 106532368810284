<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.jobOffers') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>work_history</md-icon>
                    </div>
                </md-card-header>
                <md-card-content>
                    <div v-if="teamRegionSchemeSupported" class="content-header">
                        <JobOfferFilter
                            :team-region-id="selectedTeamRegionId"
                            @onFilter="handleFilterTeamRegion"
                            class="filter"
                        />
                    </div>
                    <div class="content-body">
                        <div v-if="teamRegionSchemeSupported && !offerSetting && !offerSettingList">
                            <div>
                                <div>{{ noSettingFoundText }}</div>
                                <md-button class="md-primary" @click.prevent="showCreate">Create</md-button>
                            </div>
                        </div>
                        <div v-else-if="offerSettingList">
                            <JobOfferSettingsList
                                class="job-offer-container"
                                :offer-settings="offerSettingList"
                                @onViewDetailsClick="handleViewDetailsClick"
                                @onAddClick="showCreate"
                            ></JobOfferSettingsList>
                        </div>
                        <div v-else>
                            <template v-if="offerSetting">
                                <JobOfferSettings
                                    class="job-offer-container"
                                    :offer-setting="offerSetting"
                                    @updated="handleSettingUpdated"
                                    @deleted="handleSettingDeleted"
                                />
                                <JobOfferBands
                                    class="job-offer-container"
                                    v-if="!showManualAllocationSettings"
                                    :offer-setting="offerSetting"
                                />
                            </template>
                            <template v-else>
                                <div>
                                    <div>{{ noSettingFoundText }}</div>
                                    <md-button class="md-primary" @click.prevent="showCreate">Create</md-button>
                                </div>
                            </template>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { JOB_OFFER_SETTING_DEFAULTS } from '@/utils/defaults';
import JobOfferFilter from './JobOfferFilter';
import JobOfferSettingsList from './JobOfferSettingsList';
import JobOfferSettings from './JobOfferSettings';
import JobOfferBands from './JobOfferBands';
import CreateJobOfferSettingModal from './CreateJobOfferSettingModal';

export default {
    name: 'JobOfferSettingsPage',
    components: {
        JobOfferFilter,
        JobOfferSettingsList,
        JobOfferSettings,
        JobOfferBands
    },
    mixins: [GeneralMixin],
    data() {
        return {
            offerSetting: null,
            offerSettingList: [],
            selectedTeamRegionId: null,
            selectedTeamRegion: null,
            teamRegionSchemeSupported: false // we'll remove this when the TeamRegion Routing Model Scheme is supported
        };
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam'
        }),

        noSettingFoundText() {
            if (this.selectedTeamRegion && this.selectedTeamRegion.teamRegionId > 0) {
                return `${this.selectedTeamRegion.name} Team Region-level settings not found.`;
            }

            return `Team-level settings not found.`;
        },

        showManualAllocationSettings() {
            return !this.offerSetting || this.offerSetting.offerAllocationMethod === 'Manual';
        }
    },
    watch: {
        '$route.params': function(newVal) {
            this.refreshJobOfferSettings();
        }
    },
    methods: {
        async handleFilterTeamRegion(args) {
            if (args.teamRegion && args.teamRegion.teamRegionId) {
                this.selectedTeamRegionId = args.teamRegion.teamRegionId;
                this.selectedTeamRegion = args.teamRegion;

                this.$router.push({
                    path: `/settings/job-offers/${this.selectedTeamRegionId}`
                });
            } else {
                this.selectedTeamRegionId = null;
                this.selectedTeamRegion = null;

                this.$router.push({
                    path: `/settings/job-offers`
                });
            }

            await this.getSettings();
        },
        async getSettings() {
            this.$_handleLoaderState(true);
            try {
                const api = `/api/teams/settings/job-offers?teamRegionId=${this.selectedTeamRegionId}`;
                const { data } = await handleRequests(api);

                if (data && data.length) {
                    if (data.length === 1 && this.selectedTeamRegionId !== null) {
                        // specific setting (either team-level or teamRegion-level)
                        this.offerSetting = { ...data[0] };
                        this.offerSettingList = null;
                    } else {
                        // all settings
                        this.offerSetting = null;
                        this.offerSettingList = [...data];
                    }
                } else {
                    // no record found
                    this.offerSetting = null;
                    this.offerSettingList = null;
                }
            } catch (e) {
                const message = 'Unable to retrieve settings.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async showCreate() {
            const jobOfferSetting = {
                ...JOB_OFFER_SETTING_DEFAULTS(),
                teamRegionId: this.selectedTeamRegionId !== null ? this.selectedTeamRegionId : -1 // 'Not Set'
            };

            this.$modal
                .show(CreateJobOfferSettingModal, {
                    offerSetting: jobOfferSetting
                })
                .then(({ result, data }) => {
                    if (result === 'ok') {
                        this.offerSetting = data || null;

                        if (!this.offerSettingList) {
                            this.offerSettingList = [{ ...data }];
                        } else {
                            this.offerSettingList = [...this.offerSettingList, { ...data }];
                        }
                        this.$modal.hide();
                        this.getSettings();
                    }
                });
        },
        async handleSettingUpdated(updatedModel) {
            this.offerSetting = {
                ...this.offerSetting,
                ...updatedModel
            };
        },
        async handleSettingDeleted(model) {
            this.offerSetting = null;
        },
        async handleViewDetailsClick(teamRegionId) {
            this.selectedTeamRegionId = teamRegionId;
            this.$router.push({
                path: `/settings/job-offers/${teamRegionId}`
            });
        },
        async refreshJobOfferSettings() {
            // parse teamRegionId params
            if (this.$route.params && this.$route.params.teamRegionId) {
                if (!Number.isNaN(this.$route.params.teamRegionId)) {
                    this.selectedTeamRegionId = Number(this.$route.params.teamRegionId);
                } else {
                    this.$router.push({
                        path: `/settings/job-offers`
                    });
                }
            }
            this.getSettings();
        }
    },
    async mounted() {
        await this.refreshJobOfferSettings();
    }
};
</script>

<style lang="scss" scoped>
.card-icon {
    background: #cce055 !important;
}

.job-offer-container {
    margin-top: 80px;
}

.filter {
    display: inline-block;
}

.custom-switch {
    float: right;
    width: 350px;

    ::v-deep .md-switch {
        display: inline-block;
    }
    .md-checked {
        ::v-deep .md-switch-container {
            background-color: #2b93ff !important;
        }
        ::v-deep .md-switch-thumb {
            border: 1px solid #2b93ff !important;
        }
    }
}

.content-header {
    display: block;
}

.content-body {
    position: relative;
    display: block;
}
</style>
