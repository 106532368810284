<template>
    <div class="detail-section">
        <span class="title">Warehouse Information</span>
        <div class="detail-group">
            <label class="label">
                Name:
            </label>
            <span class="value">
                {{ warehouseDetails.name }}
            </span>
        </div>

        <div class="detail-group">
            <label class="label">
                Address:
            </label>
            <span class="value">
                <a class="address" @click.prevent="viewAddress(warehouseDetails)">
                    {{ warehouseDetails.address }}
                </a>
            </span>
        </div>
        <div class="detail-group">
            <label class="label">
                Team Region:
            </label>
            <span class="value">
                {{ warehouseDetails.teamRegion ? warehouseDetails.teamRegion.name : '' }}
            </span>
        </div>
        <div class="detail-group">
            <label class="label">
                Contact:
            </label>
            <span class="value">
                {{ contactDesc(warehouseDetails) }}
            </span>
        </div>
        <div class="detail-group">
            <label class="label">
                Email:
            </label>
            <span class="value">
                {{ warehouseDetails.email }}
            </span>
        </div>
        <div class="detail-group">
            <label class="label">
                Phone:
            </label>
            <span class="value">
                {{ warehouseDetails.phone }}
            </span>
        </div>
    </div>
</template>
<script>
import { ViewAddressModal } from '@/pages';

export default {
    name: 'WarehouseSidebarDetails',
    props: {
        warehouseDetails: {
            type: Object,
            required: true
        }
    },
    methods: {
        contactDesc(item) {
            return `${item.firstName || ''} ${item.lastName || ''}`.trim();
        },

        viewAddress() {
            this.$modal.show(ViewAddressModal, { data: this.warehouseDetails });
        }
    }
};
</script>
<style lang="scss" scoped>
.detail-group {
    margin: 5px 0;
    font-size: 0.9em;
    .label {
        display: inline-block;
        width: 95px;
        margin-right: 4px;
        vertical-align: top;
        font-weight: 400;
    }

    .value {
        display: inline-block;
        width: calc(100% - 105px);
        vertical-align: top;
        a {
            color: #2b93ff;
        }
    }
}
</style>
