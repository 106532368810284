var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-container" }, [
    _c(
      "div",
      { staticClass: "close-button" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-default md-just-icon md-round pull-right",
            on: { click: _vm.closeModal },
          },
          [_c("md-icon", [_vm._v("close")])],
          1
        ),
      ],
      1
    ),
    !_vm.loading
      ? _c("div", [
          _c("div", { staticClass: "detail-section text-center" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.customerDetails.name)),
            ]),
          ]),
          _c("div", { staticClass: "line-separator" }),
          _c("div", { staticClass: "detail-section" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("Personal Information"),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Full Name:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  _vm._s(_vm.customerDetails.firstName) +
                    " " +
                    _vm._s(_vm.customerDetails.lastName)
                ),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Company:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.customerDetails.company) +
                    "\n                "
                ),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("ABN:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.customerDetails.businessRegistrationNumber) +
                    "\n                "
                ),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Address:")]),
              _c("span", { staticClass: "value" }, [
                _vm.customerDetails.locationName &&
                _vm.$_validateCoordinate(_vm.customerDetails.address)
                  ? _c("div", { staticClass: "address-name" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.customerDetails.locationName) +
                          "\n                    "
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "a",
                  {
                    staticClass: "address",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.viewAddress(_vm.customerDetails)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.customerDetails.address) +
                        "\n                    "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm.teamRegionName
              ? _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Team Region:"),
                  ]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.teamRegionName) +
                        "\n                "
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Contact Email:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.customerDetails.contactEmail) +
                    "\n                "
                ),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Account Email:")]),
              _c("span", { staticClass: "value" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-chips md-primary md-theme-default md-has-placeholder md-theme-default",
                  },
                  _vm._l(
                    _vm.customerDetails.accountsEmailList,
                    function (email, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "md-chip md-theme-default" },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(email) +
                              "\n                        "
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Notes:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.customerDetails.notes) +
                    "\n                "
                ),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Stop duration:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.customerDetails.defaultStopDurationMinutes) +
                    "\n                "
                ),
              ]),
            ]),
            _vm.customerDetails.brandId != null
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.customerDetails.brandId != null
              ? _c("div", { staticClass: "detail-section" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("Brand")]),
                  _vm.customerDetails.brandLogoUrl
                    ? _c("img", {
                        staticClass: "brand-logo",
                        attrs: {
                          src:
                            _vm.customerDetails.brandLogoUrl !== null
                              ? _vm.customerDetails.brandLogoUrl
                              : _vm.$root.defaultPhotoUrl,
                          title: _vm.customerDetails.brandName,
                          alt: _vm.customerDetails.brandName,
                        },
                        on: { error: _vm.$_setDefaultBrokenImage },
                      })
                    : _vm._e(),
                  !_vm.customerDetails.brandLogoUrl
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.customerDetails.brandName)),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "sidebar-loader" },
            [
              _c("fade-loader", {
                attrs: { loading: _vm.loading, color: "#333333" },
              }),
              _c("span", [_vm._v("LOADING")]),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }