<template>
    <drop-down
        :should-close="shouldCloseDropdown"
        @closeDropdown="(val) => (this.shouldCloseDropdown = val)"
        @closeOtherDropDown="closeOtherDropDown"
    >
        <span
            class="status"
            slot="title"
            data-toggle="dropdown"
            :class="`status-${runSchedule.status.toLowerCase().replace(/\s+/g, '')} ${className}`"
        >
            {{ runSchedule.status }}
        </span>
        <ul class="dropdown-menu" v-if="!isReadOnlyUser">
            <li v-for="(status, index) in statuses" :key="index">
                <a @click.stop="handleChangeRunScheduleStatus(status)">
                    {{ status }}
                </a>
            </li>
        </ul>
    </drop-down>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { RUN_SCHEDULE_STATUS_CONSTANTS } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
    name: 'RunScheduleStatusButton',
    mixins: [GeneralMixin],
    props: {
        runSchedule: {
            type: Object,
            default: () => {}
        },
        className: {
            type: String,
            default: () => ''
        }
    },
    data() {
        return {
            statuses: RUN_SCHEDULE_STATUS_CONSTANTS,
            shouldCloseDropdown: false
        };
    },
    mounted() {
        this.updateStatus();
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    methods: {
        updateStatus() {
            this.statuses = RUN_SCHEDULE_STATUS_CONSTANTS;
        },
        async handleChangeRunScheduleStatus(newStatus) {
            this.shouldCloseDropdown = true;
            const { runScheduleId, status } = this.runSchedule;
            if (status !== newStatus) {
                const payload = {
                    method: 'post'
                };

                // to toggle the context menu
                this.$nextTick(() => {
                    this.shouldCloseDropdown = false;
                });

                this.$emit('statusUpdated', { runScheduleId, status: newStatus });
                try {
                    const api = `/api/run-schedules/${runScheduleId}/${newStatus}`;
                    await handleRequests(api, payload);

                    this.$notify({
                        message: `Run schedule status was changed to ${newStatus}!`,
                        type: 'success'
                    });
                } catch (e) {
                    const message = 'Cannot change run schedule status.';
                    showErrorMessage(this, message, e);
                    this.$emit('statusUpdated', { runScheduleId, status });
                }
            } else {
                const message = 'Cannot change status to the same status';
                this.$notify({
                    message,
                    type: 'danger'
                });
            }
        },
        closeOtherDropDown() {
            const dropdowns = document.querySelectorAll('.open');
            dropdowns.forEach((dropdown) => dropdown.classList.remove('open'));
        }
    }
};
</script>

<style lang="scss" scoped>
.status {
    color: #fff !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 90px;
    cursor: pointer;
}

::v-deep .dropdown-menu {
    li {
        cursor: pointer !important;
    }
}
</style>
