var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field-container" },
    [
      _c(
        "form-wrapper",
        {
          staticClass: "form-wrapper",
          attrs: { validator: _vm.$v.jobOfferSettingModel },
        },
        [
          _c("div", { staticClass: "md-layout" }, [
            _c(
              "div",
              { staticClass: "md-layout-item" },
              [
                _c(
                  "form-group",
                  {
                    attrs: {
                      name: "jobOfferSetting.offerAllocationMethod",
                      label: "Offer Allocation Method",
                    },
                  },
                  [
                    _c(
                      "md-select",
                      {
                        model: {
                          value: _vm.jobOfferSettingModel.offerAllocationMethod,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.jobOfferSettingModel,
                              "offerAllocationMethod",
                              $$v
                            )
                          },
                          expression:
                            "jobOfferSettingModel.offerAllocationMethod",
                        },
                      },
                      _vm._l(
                        _vm.jobOfferAllocationMethodOptions,
                        function (item) {
                          return _c(
                            "md-option",
                            { key: item.value, attrs: { value: item.value } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.text) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.isAutoAllocation
            ? _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "form-group",
                      { attrs: { name: "maxRetry", label: "Max Retry" } },
                      [
                        _c("md-input", {
                          attrs: { type: "number" },
                          model: {
                            value: _vm.jobOfferSettingModel.maxRetry,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.jobOfferSettingModel,
                                "maxRetry",
                                _vm._n($$v)
                              )
                            },
                            expression: "jobOfferSettingModel.maxRetry",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "form-group",
                      { attrs: { name: "retryDelay", label: "Retry Delay" } },
                      [
                        _c("TimeSpanInput", {
                          attrs: { value: _vm.jobOfferSettingModel.retryDelay },
                          on: { changed: _vm.handleRetryDelayChanged },
                          model: {
                            value: _vm.jobOfferSettingModel.retryDelay,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.jobOfferSettingModel,
                                "retryDelay",
                                $$v
                              )
                            },
                            expression: "jobOfferSettingModel.retryDelay",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "md-layout" }, [
            _c(
              "div",
              { staticClass: "md-layout-item" },
              [
                _c(
                  "form-group",
                  {
                    staticClass: "no-underline",
                    attrs: { name: "jobOfferSetting.matchSkills" },
                  },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.jobOfferSettingModel.matchSkills,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.jobOfferSettingModel,
                              "matchSkills",
                              $$v
                            )
                          },
                          expression: "jobOfferSettingModel.matchSkills",
                        },
                      },
                      [_vm._v("Match Skills")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "md-layout-item" },
              [
                _c(
                  "form-group",
                  {
                    staticClass: "no-underline",
                    attrs: { name: "jobOfferSetting.checkLoadCapacity" },
                  },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.jobOfferSettingModel.checkLoadCapacity,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.jobOfferSettingModel,
                              "checkLoadCapacity",
                              $$v
                            )
                          },
                          expression: "jobOfferSettingModel.checkLoadCapacity",
                        },
                      },
                      [_vm._v("Check Load Capacity")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "md-layout" }, [
            _c(
              "div",
              { staticClass: "md-layout-item" },
              [
                _c(
                  "form-group",
                  {
                    staticClass: "no-underline",
                    attrs: { name: "jobOfferSetting.prioritizeEmptyVehicles" },
                  },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value:
                            _vm.jobOfferSettingModel.prioritizeEmptyVehicles,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.jobOfferSettingModel,
                              "prioritizeEmptyVehicles",
                              $$v
                            )
                          },
                          expression:
                            "jobOfferSettingModel.prioritizeEmptyVehicles",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Prioritize Empty Vehicles\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "md-layout-item" }),
          ]),
          _vm.isAutoAllocation
            ? _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "form-group",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                        staticClass: "no-underline",
                        attrs: { name: "jobOfferSetting.autoAllocate" },
                      },
                      [
                        _c(
                          "md-checkbox",
                          {
                            model: {
                              value: _vm.jobOfferSettingModel.autoAllocate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.jobOfferSettingModel,
                                  "autoAllocate",
                                  $$v
                                )
                              },
                              expression: "jobOfferSettingModel.autoAllocate",
                            },
                          },
                          [_vm._v("Auto Allocate")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "form-group",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                        staticClass: "no-underline",
                        attrs: { name: "jobOfferSetting.failFast" },
                      },
                      [
                        _c(
                          "md-checkbox",
                          {
                            model: {
                              value: _vm.jobOfferSettingModel.failFast,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.jobOfferSettingModel,
                                  "failFast",
                                  $$v
                                )
                              },
                              expression: "jobOfferSettingModel.failFast",
                            },
                          },
                          [_vm._v("Fail Fast")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.isAutoAllocation
            ? _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "form-group",
                      {
                        attrs: {
                          name: "jobOfferSetting.alertLeadTime",
                          label:
                            "Notification Lead Time before the scheduled timeframe",
                        },
                      },
                      [
                        _c("TimeSpanInput", {
                          attrs: {
                            value: _vm.jobOfferSettingModel.alertLeadTime,
                          },
                          on: { changed: _vm.handleAlertLeadTimeChanged },
                          model: {
                            value: _vm.jobOfferSettingModel.alertLeadTime,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.jobOfferSettingModel,
                                "alertLeadTime",
                                $$v
                              )
                            },
                            expression: "jobOfferSettingModel.alertLeadTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "md-card-actions",
        [
          _c(
            "md-button",
            {
              staticClass: "header-button md-primary",
              attrs: { id: "btnUpdate" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.update($event)
                },
              },
            },
            [_vm._v("Update")]
          ),
          _vm.teamRegionSchemeSupported
            ? _c(
                "md-button",
                {
                  staticClass: "md-danger header-button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.remove($event)
                    },
                  },
                },
                [_vm._v("\n            Remove\n        ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }