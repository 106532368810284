var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(
            _vm._s(_vm.carrierName ? _vm.carrierName : "") +
              " Pre-booking Questions"
          ),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "modal-body",
        class: _vm.showScrollbar ? "scroll-body" : "",
      },
      [
        _vm._l(_vm.$v.newAnswersList.$each.$iter, function (question, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "field-container",
              class: {
                error: question.defaultValue.$error,
                "field-text": question.$model.type == "Text",
                "checkbox-field": question.$model.type == "CheckBox",
                "dropdown-field": question.$model.type == "Dropdown",
                "textarea-custom-field":
                  question.$model.type == "MultiLineText",
              },
              attrs: { name: "name" },
            },
            [
              _c("CustomFieldInputs", {
                attrs: {
                  "custom-field-definition": question.$model,
                  "initial-value": question.defaultValue.$model,
                },
                on: { changed: _vm.handleAnswerChanged },
              }),
            ],
            1
          )
        }),
        _vm._l(_vm.$v.mandatoryFields.$each.$iter, function (fld, index) {
          return _c(
            "div",
            {
              key: "fld-" + index,
              staticClass: "field-container",
              class: {
                error: fld.value.$error,
                "field-text": fld.$model.type == "Text",
                "checkbox-field": fld.$model.type == "CheckBox",
                "dropdown-field": fld.$model.type == "Dropdown",
                "textarea-custom-field": fld.$model.type == "MultiLineText",
              },
              attrs: { name: "name" },
            },
            [
              index == 0 ||
              _vm.$v.mandatoryFields.$each.$iter[index].$model.groupName !=
                _vm.$v.mandatoryFields.$each.$iter[index - 1].$model.groupName
                ? _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t(
                            "carrierFieldLabels.groups." +
                              _vm.$v.mandatoryFields.$each.$iter[index].$model
                                .groupName
                          )
                        ) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
              _c("CustomFieldInputs", {
                attrs: {
                  "custom-field-definition": fld.$model,
                  "initial-value": fld.value.$model,
                },
                on: { changed: _vm.handleMandatoryAnswerChanged },
              }),
            ],
            1
          )
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.continueAssignment },
          },
          [_vm._v("Continue")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }