<template>
    <div class="custom-toolbar state-filter">
        <div class="custom-toolbar-end">
            <md-field v-if="statusList.length > 0">
                <vue-select
                    :options="statusList"
                    label="name"
                    placeholder="Status"
                    v-model="statusFilterValue"
                    :searchable="$root.isDesktop"
                ></vue-select>
            </md-field>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins';

export default {
    name: 'ProcessChargeStatusFilter',
    props: {
        statusList: {
            type: Array,
            default: () => ['All', 'Complete', 'Failed']
        },
        filterStatusFilter: {
            type: String,
            default: 'All'
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {
            statusFilterValue: null
        };
    },
    watch: {
        statusFilterValue(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.statusFilterValue = newValue;
                this.handleFilterValue();
            }
        },
        filterStatusFilter() {
            if (this.filterStatusFilter === 'null') {
                this.statusFilterValue = 'All';
            } else {
                this.statusFilterValue = this.filterStatusFilter;
            }
        }
    },
    methods: {
        handleFilterValue() {
            this.$emit('onStatusSelected', this.statusFilterValue);
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    }
};
</script>

<style lang="scss" scoped>
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}

::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}

::v-deep .vs__search {
    cursor: pointer;
}

.status-filter {
    width: 180px !important;
}

.state-filter {
    margin-right: 20px;
}
</style>
