<template>
    <md-button
        title="Create asset log"
        :class="useIcon ? 'md-info md-just-icon md-round' : `${className}`"
        @click.stop="handleCreateAssetLog"
    >
        <md-icon v-if="useIcon">note_add</md-icon>
        <span v-else>note_add</span>
    </md-button>
</template>

<script>
import CreateAssetLogModal from '../ModalComponents/CreateAssetLogModal';

export default {
    name: 'CreateAssetLogButton',
    props: {
        asset: {
            type: Object,
            default: () => {}
        },
        customFields: {
            type: Array,
            default: () => []
        },
        className: {
            type: String,
            default: () => ''
        },
        useIcon: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        handleCreateAssetLog() {
            this.$modal.show(CreateAssetLogModal, { assetData: this.asset }).then((response) => {
                this.$emit('onAssetCreateLog', response);
            });
        }
    }
};
</script>
