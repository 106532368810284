import { render, staticRenderFns } from "./ReminderIndicator.vue?vue&type=template&id=3a184104&scoped=true&"
import script from "./ReminderIndicator.vue?vue&type=script&lang=js&"
export * from "./ReminderIndicator.vue?vue&type=script&lang=js&"
import style0 from "./ReminderIndicator.vue?vue&type=style&index=0&id=3a184104&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a184104",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\a\\1\\s\\Locate2u.AppPortal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a184104')) {
      api.createRecord('3a184104', component.options)
    } else {
      api.reload('3a184104', component.options)
    }
    module.hot.accept("./ReminderIndicator.vue?vue&type=template&id=3a184104&scoped=true&", function () {
      api.rerender('3a184104', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Maintenance/components/ReminderIndicator.vue"
export default component.exports