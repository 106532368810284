var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "status-container" },
    [
      _c("md-icon", { class: _vm.iconColor }, [
        _vm._v("\n        " + _vm._s(_vm.icon) + "\n    "),
      ]),
      _c("span", { class: _vm.labelColor }, [_vm._v(_vm._s(_vm.label))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }