<template>
    <div>
        <form-group
            v-if="
                customFieldDefinition.type.toLowerCase() == 'text' ||
                    customFieldDefinition.type.toLowerCase() == 'multilinetext'
            "
            :name="customFieldDefinition.name"
            :label="showLabel ? customFieldDefinition.label : null"
        >
            <md-input
                v-if="customFieldDefinition.type.toLowerCase() == 'text'"
                v-model="customFieldValue"
                :placeholder="placeholder"
            />
            <md-textarea
                v-if="customFieldDefinition.type.toLowerCase() == 'multilinetext'"
                v-model="customFieldValue"
            />
        </form-group>

        <md-datepicker
            v-if="customFieldDefinition.type.toLowerCase() == 'date'"
            v-model="customFieldValue"
            md-immediately
            :md-debounce="10"
        >
            <label>{{ showLabel ? customFieldDefinition.label : null }}</label>
        </md-datepicker>
        <md-checkbox v-if="customFieldDefinition.type.toLowerCase() == 'checkbox'" v-model="customFieldValue">
            {{ showLabel ? customFieldDefinition.label : '' }}
        </md-checkbox>
        <form-group
            v-if="customFieldDefinition.type.toLowerCase() === 'dropdown'"
            :name="customFieldDefinition.name"
            :label="showLabel ? customFieldDefinition.label : null"
        >
            <vue-select
                :reduce="(cf) => cf.value"
                label="value"
                :options="customFieldDefinition.options"
                :placeholder="customFieldDefinition.label"
                v-model="customFieldValue"
                :searchable="false"
                class="custom-mapping-select"
            ></vue-select>
        </form-group>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { DATE_TYPES } from '@/utils/constants';
import moment from 'moment';

export default {
    name: 'CustomFieldInputs',
    mixins: [GeneralMixin],
    props: {
        customFieldDefinition: {
            type: Object,
            default: () => {}
        },
        // eslint-disable-next-line vue/require-default-prop
        initialValue: [String, Number, Date, Boolean],
        showLabel: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: null
        }
    },
    watch: {
        customFieldValue(newValue) {
            let val = newValue;
            if (this.customFieldDefinition.type.toLowerCase() === 'date' && val) {
                val = !moment(val).isValid() ? null : moment(val).format(DATE_TYPES.standardDate);
            }
            this.$emit('changed', {
                name: this.customFieldDefinition.name,
                value: val,
                type: this.customFieldDefinition.type
            });
        },
        customFieldDefinition: {
            immediate: true,
            handler() {
                // update because consumer may change definitions
                this.updateFields();
            }
        }
    },
    methods: {
        updateFields() {
            if (this.initialValue === false && this.customFieldDefinition?.type.toLowerCase() !== 'checkbox') {
                this.customFieldValue = null;
            } else if (!!this.initialValue && this.customFieldDefinition?.type.toLowerCase() === 'date') {
                this.customFieldValue = moment(this.initialValue).format(DATE_TYPES.internationalDate);
            } else {
                this.customFieldValue = this.initialValue;
            }
        }
    },
    mounted() {
        this.updateFields();
    },
    data() {
        return {
            customFieldValue: null
        };
    }
};
</script>

<style lang="scss" scoped>
.custom-mapping-select {
    width: 100%;
    max-width: 200px;
    ::v-deep .vs__clear {
        display: none;
    }
    ::v-deep .vs__search,
    .vs__search:focus {
        font-size: 14px;
        padding: 0;
    }

    ::v-deep .vs__selected-options {
        padding-left: 5px;
        display: inline-flex;
        flex-grow: unset;
        flex-wrap: unset;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ::v-deep ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #aaa;
        opacity: 1; /* Firefox */
        padding: 0;
    }

    ::v-deep :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #aaa;
        padding: 0;
    }

    ::v-deep ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #aaa;
        padding: 0;
    }

    ::v-deep .vs__dropdown-menu {
        white-space: nowrap;
        width: 160px;
        min-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

::v-deep .md-field.md-theme-default label {
    top: -0.8rem !important;
    font-size: 0.6875rem !important;
}
</style>
