var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.tripList.length > 1 ? "trip-summary" : ""] }, [
    _vm.unallocatedStopList.length > 0
      ? _c("div", { staticClass: "alert alert-warning" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button", "aria-hidden": "true" },
            },
            [_vm._v("×")]
          ),
          _c("span", [
            _vm._v("\n            Could not route  \n            "),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.handleUnassignedStopList(_vm.unallocatedStopList)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.unallocatedStopListLength) +
                    " stops\n            "
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.hasDisplayed && _vm.tripList.length > 1
      ? _c("div", { staticClass: "md-layout md-size-100 trip-tools" }, [
          _c(
            "div",
            { staticClass: "md-layout-item md-size-80 search-field" },
            [
              _c(
                "div",
                { staticClass: "search-icon" },
                [_c("md-icon", [_vm._v("search")])],
                1
              ),
              _c("search-component", {
                attrs: {
                  "handle-search-on-type": true,
                  "search-icon": false,
                  placeholder: _vm.placeholderText,
                },
                on: { onSearch: _vm.handleSearch },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "md-layout-item md-size-20 icon-pin-all" }, [
            _c(
              "a",
              {
                class: { active: _vm.isPinedAll },
                attrs: { href: "#" },
                on: { click: _vm.onPinedAll },
              },
              [
                _c("md-icon", { staticClass: "route-pin-all" }, [
                  _vm._v("\n                    push_pin\n                "),
                ]),
                _c("md-tooltip", { attrs: { "md-direction": "right" } }, [
                  _vm.isPinedAll
                    ? _c("span", [
                        _vm._v(
                          "\n                        Unpin All\n                    "
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          "\n                        Pin All\n                    "
                        ),
                      ]),
                ]),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm.hasDisplayed
      ? _c(
          "div",
          { staticClass: "table-wrapper" },
          [
            _c(
              "md-table",
              { staticClass: "trip-table table-container" },
              [
                _vm.orderedTripList.length > 0
                  ? _c(
                      "md-table-row",
                      [
                        _c("md-table-head", [_vm._v("Driver")]),
                        _c("md-table-head", [_vm._v("Stops")]),
                        _c("md-table-head", [_vm._v("Finish Time")]),
                        _c(
                          "md-table-head",
                          [
                            _vm.user.vehicleCapacityUnitsConfiguration !=
                              null &&
                            _vm.user.vehicleCapacityUnitsConfiguration.length >
                              0
                              ? _c(
                                  "form-group",
                                  { staticClass: "vehicle-config" },
                                  [
                                    _c(
                                      "md-select",
                                      {
                                        model: {
                                          value: _vm.selectedCapacityType,
                                          callback: function ($$v) {
                                            _vm.selectedCapacityType = $$v
                                          },
                                          expression: "selectedCapacityType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.user
                                          .vehicleCapacityUnitsConfiguration,
                                        function (vehicleCapacity, index) {
                                          return _c(
                                            "md-option",
                                            {
                                              key: index,
                                              staticClass: "vehicle-config",
                                              attrs: {
                                                value: vehicleCapacity.type,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    vehicleCapacity.label
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("md-table-head"),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(_vm.orderedTripList, function (item, index) {
                  return _c(
                    "md-table-row",
                    {
                      key: index,
                      attrs: { slot: "md-table-row" },
                      slot: "md-table-row",
                    },
                    [
                      _c("md-table-cell", { staticClass: "allocatestop" }, [
                        _c("span", { style: { background: item.color } }, [
                          _vm._v(" "),
                        ]),
                        item.teamMember.photoUrl
                          ? _c("img", {
                              staticClass: "profile-image",
                              attrs: { src: item.teamMember.photoUrl, alt: "" },
                              on: { error: _vm.$_setDefaultBrokenImage },
                            })
                          : _c("img", {
                              staticClass: "profile-image",
                              attrs: {
                                src: _vm.$root.defaultPhotoUrl,
                                alt: "",
                              },
                            }),
                        _vm.startLocationMode === "teammembers"
                          ? _c("span", [
                              _vm._v(_vm._s(item.teamMember.fullName)),
                            ])
                          : _c("span", [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAssignedTrip(
                                        item.vehicleId
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.teamMember.fullName))]
                              ),
                            ]),
                      ]),
                      _c(
                        "md-table-cell",
                        { staticClass: "allocatestop-number" },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAssignedStopList(
                                    item.vehicleId
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.stopsCount) + " Stops")]
                          ),
                        ]
                      ),
                      _c("md-table-cell", { staticClass: "finish-time" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.arrivalTime) +
                            "\n                "
                        ),
                      ]),
                      _vm.user.vehicleCapacityUnitsConfiguration != null &&
                      _vm.user.vehicleCapacityUnitsConfiguration.length > 0
                        ? _c(
                            "md-table-cell",
                            { staticClass: "allocatestop-column" },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.getVehicleLoad(
                                      item.vehicle,
                                      item.teamMember
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("md-table-cell", { staticClass: "allocatestop-pin" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                return _vm.showTeamMemberSettingsModal(item)
                              },
                            },
                          },
                          [
                            _c("md-icon", { staticClass: "route-pin" }, [
                              _vm._v(
                                "\n                            settings\n                        "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "a",
                          {
                            class: { active: item.isPinned },
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                return _vm.allocateStopMarkers(item)
                              },
                            },
                          },
                          [
                            _c("md-icon", { staticClass: "route-pin" }, [
                              _vm._v(
                                "\n                            push_pin\n                        "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
            _vm.orderedTripList.length > 1
              ? _c(
                  "div",
                  { staticClass: "totals-footer" },
                  [
                    _c(
                      "md-table",
                      { staticClass: "trip-table" },
                      [
                        _c(
                          "md-table-row",
                          [
                            _c(
                              "md-table-cell",
                              { staticClass: "total-stops-footer" },
                              [_vm._v(_vm._s(_vm.totalStops) + " Stops")]
                            ),
                            _c(
                              "md-table-cell",
                              { staticClass: "total-capacity-footer" },
                              [_vm._v(_vm._s(_vm.totalLoad))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "div",
                    { staticClass: "checkbox-fixed-order" },
                    [
                      _c(
                        "md-checkbox",
                        {
                          model: {
                            value: _vm.isFixedStopOrder,
                            callback: function ($$v) {
                              _vm.isFixedStopOrder = $$v
                            },
                            expression: "isFixedStopOrder",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Create Fixed Order Trips\n                    "
                          ),
                        ]
                      ),
                      _c(
                        "md-tooltip",
                        {
                          staticClass: "fixed-order-tooltip",
                          attrs: { "md-direction": "bottom" },
                        },
                        [
                          _vm._v(
                            "\n                        If selected, the trips created will have a fixed stop order as per the original planned\n                        route.\n                        "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                        If unselected, the stops will change order, allowing the trip to be automatically rerouted\n                        when additional stops are added to the trip or if the driver doesn't follow the expected\n                        stop order.\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "custom-btn",
                      on: {
                        click: function ($event) {
                          return _vm.onCreateTrips()
                        },
                      },
                    },
                    [_vm._v("Create " + _vm._s(_vm.tripList.length) + " Trips")]
                  ),
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-primary md-just-icon md-round pull-right header-button",
                      attrs: { title: "Export Trips" },
                      on: {
                        click: function ($event) {
                          return _vm.onExportTrips()
                        },
                      },
                    },
                    [_c("md-icon", [_vm._v("get_app")])],
                    1
                  ),
                  !_vm.isStartRouting && !_vm.loadedSaveModel
                    ? _c(
                        "md-button",
                        {
                          staticClass:
                            "md-primary md-just-icon md-round pull-right header-button",
                          attrs: { title: "Save Optimise" },
                          on: {
                            click: function ($event) {
                              return _vm.onSaveOptimise()
                            },
                          },
                        },
                        [_c("md-icon", [_vm._v("save")])],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }