<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Change Status</h4>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.changeStatus" class="form-wrapper">
                <form-group name="role" label="Change status">
                    <md-select v-model="form.newStatus" placeholder="Status">
                        <md-option v-for="item in statuses" :key="item" :label="item" :value="item">
                            {{ item }}
                        </md-option>
                    </md-select>
                </form-group>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="changeStatus">
                Save
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { required } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { STATUS_CONSTANTS } from '@/utils/constants';

export default {
    name: 'ChangeStatusModal',
    mixins: [GeneralMixin],
    props: {
        status: {
            type: String,
            default: null
        },
        stopId: {
            type: Number,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    validations: {
        changeStatus: {
            newStatus: {
                required
            }
        }
    },
    data() {
        return {
            form: {
                newStatus: null
            },
            statuses: STATUS_CONSTANTS
        };
    },
    mounted() {
        this.form.newStatus = this.status;
    },
    methods: {
        async changeStatus() {
            this.$_handleLoaderState(true, 'UPDATING...');
            const payload = {
                method: 'post',
                data: {
                    newStatus: this.form.newStatus,
                    oldStatus: this.status
                }
            };
            try {
                const api = `/api/stops/${this.stopId}/change-status`;
                await handleRequests(api, payload);

                this.$notify({
                    message: `Stop status was changed to ${this.form.newStatus} !`,
                    type: 'success'
                });
                this.$v.$reset();
                this.resolve({ stopId: this.stopId, status: this.form.newStatus });
            } catch (e) {
                const message = 'Cannot change stop status.';
                showErrorMessage(this, message, e);
                this.resolve(false);
            }
        }
    }
};
</script>
