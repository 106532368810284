<template>
    <div>
        <form-wrapper :validator="$v.model" class="form-wrapper">
            <form-group label="Postage Assessment Calculation (PAC) Api Key" name="pacApiKey">
                <md-input v-model.trim="model.pacApiKey" />
            </form-group>
        </form-wrapper>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'AusPostKeys',
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            model: {
                pacApiKey: ''
            }
        };
    },
    validations() {
        return {
            model: {
                pacApiKey: { required }
            }
        };
    },
    methods: {
        update() {
            this.$v.model.$touch();
            if (this.$v.model.$invalid) 
                return;

            this.$emit('onUpdate', { ausPostAccess: { ...this.model } });
        }
    },
    mounted() {
        if (data != null) {
            Object.assign(model, data);
        }
    }
};
</script>
