var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    { class: [{ "disabled-rates": _vm.hasInvoice }] },
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-green" },
        [
          _c(
            "div",
            { staticClass: "card-icon icon-style" },
            [
              _c("md-icon", [_vm._v("attach_money")]),
              _vm._v("\n            " + _vm._s(_vm.title) + "\n        "),
            ],
            1
          ),
        ]
      ),
      _vm.hasInvoice
        ? _c(
            "div",
            { staticClass: "has-invoice" },
            [
              _c("md-icon", { staticClass: "invoice-icon-color" }, [
                _vm._v("task"),
              ]),
              _c("i", [
                _vm._v(
                  "*Charges cannot be changed because trip has been invoiced"
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.isChargesApproved
        ? _c(
            "div",
            { staticClass: "is-approved" },
            [
              _c("md-icon", { staticClass: "invoice-icon-color" }, [
                _vm._v("task"),
              ]),
              _c("i", [
                _vm._v(
                  "*Charges cannot be changed because trip has been approved"
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("md-card-content", [
        _vm.updatingRates
          ? _c(
              "div",
              [
                _c("loading-component-spinner", {
                  attrs: { "loading-text": "Rates Updating..." },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.chargeLines.length === 0 && !_vm.updatingRates
          ? _c("p", { staticClass: "grayedout-text text-center" }, [
              _vm._v(
                "\n            No " + _vm._s(_vm.title) + " found\n        "
              ),
            ])
          : _vm._e(),
        !_vm.updatingRates && _vm.chargeLines.length > 0
          ? _c(
              "div",
              [
                _c(
                  "md-table",
                  [
                    _c(
                      "md-table-row",
                      { attrs: { slot: "header" }, slot: "header" },
                      [
                        _c("md-table-head", { staticClass: "drag-icon" }),
                        _c("md-table-head", [_vm._v("Description")]),
                        _c("md-table-head", [_vm._v("Unit")]),
                        _c("md-table-head", [_vm._v("Amount")]),
                        _vm.$root.isDesktop &&
                        !_vm.isReadOnlyUser &&
                        !_vm.hasInvoice
                          ? _c("md-table-head", [
                              _vm._v(
                                "\n                        Actions\n                    "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.updatingRates
                      ? _c(
                          "div",
                          [
                            _c("loading-component-spinner", {
                              attrs: { "loading-text": "Rates Updating..." },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.chargeLines, function (rate) {
                      return !_vm.updatingRates
                        ? _c(
                            "md-table-row",
                            { key: rate.id },
                            [
                              _c(
                                "md-table-cell",
                                { staticClass: "drag-icon" },
                                [
                                  rate.isManualCharge || rate.manuallyChanged
                                    ? _c("md-icon", [
                                        _c("span", [
                                          _vm._v(
                                            "\n                                drive_file_rename_outline\n                            "
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("md-table-cell", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(rate.description) +
                                    "\n                    "
                                ),
                              ]),
                              rate.isManualCharge || rate.manuallyChanged
                                ? _c("md-table-cell")
                                : _c("md-table-cell", [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(rate.chargeUnit) +
                                        "\n                    "
                                    ),
                                  ]),
                              _c("md-table-cell", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.$_getRateAmount(rate),
                                        _vm.tripDetails.currency
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]),
                              !_vm.hasInvoice
                                ? _c(
                                    "md-table-cell",
                                    { staticClass: "action-buttons" },
                                    [
                                      !_vm.isReadOnlyUser
                                        ? _c(
                                            "md-button",
                                            {
                                              staticClass:
                                                "md-success md-just-icon md-round",
                                              attrs: { title: "Edit Charge" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.openChargeLine(
                                                    rate
                                                  )
                                                },
                                              },
                                            },
                                            [_c("md-icon", [_vm._v("edit")])],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.isReadOnlyUser
                                        ? _c(
                                            "md-button",
                                            {
                                              staticClass:
                                                "md-danger md-just-icon md-round",
                                              attrs: { title: "Remove Charge" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.deleteChargeLine(
                                                    rate
                                                  )
                                                },
                                              },
                                            },
                                            [_c("md-icon", [_vm._v("delete")])],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e()
                    }),
                    !_vm.updatingRates
                      ? _c(
                          "md-table-row",
                          [
                            _c("md-table-cell", { attrs: { colspan: "3" } }),
                            _c(
                              "md-table-cell",
                              { staticClass: "total-style" },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.$_getTotalAmount(_vm.chargeLines),
                                        _vm.tripDetails.currency
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _c("md-table-cell"),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.$root.isDesktop &&
      !_vm.isChargesApproved &&
      !_vm.isReadOnlyUser &&
      !_vm.hasInvoice
        ? _c(
            "md-card-actions",
            { class: { "is-loading-rates": _vm.updatingRates } },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-warning revert-button",
                  on: {
                    click: function ($event) {
                      return _vm.validateRevertingCharges()
                    },
                  },
                },
                [_vm._v("\n            Revert to Default Charges\n        ")]
              ),
              _c(
                "md-button",
                {
                  staticClass: "md-success",
                  on: {
                    click: function ($event) {
                      return _vm.handleAddNewCharge()
                    },
                  },
                },
                [_vm._v("\n            Add Charge\n        ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }