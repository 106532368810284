<template>
    <div class="profile-container">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25">
                <md-card class="md-card-profile">
                    <div
                        class="md-card-avatar upload-container"
                        @mouseover="showUploadButton = true"
                        @mouseleave="showUploadButton = false"
                    >
                        <img
                            v-if="!photoPreview"
                            class="profile-image"
                            :src="user.photoUrl !== null ? user.photoUrl : $root.defaultPhotoUrl"
                            @error="$_setDefaultBrokenImage"
                            alt
                        />
                        <img
                            v-if="photoPreview"
                            class="profile-image"
                            :src="photoPreview"
                            @error="$_setDefaultBrokenImage"
                            alt
                        />
                        <div v-if="showUploadButton">
                            <input ref="photoInput" hidden type="file" @change="onPhotoSelected" />
                            <div>
                                <button class="custom-btn" @click="$refs.photoInput.click()">Update Photo</button>
                            </div>
                        </div>
                    </div>

                    <md-card-content>
                        <h6 class="category text-gray">
                            {{ displayRole }}
                        </h6>
                        <span class="card-description">
                            Date Joined: {{ user.createdDate | dateFormat(DATE_TYPES.standardDate) }}
                        </span>
                        <h4 class="card-title">
                            {{ displayName }}
                        </h4>
                    </md-card-content>
                </md-card>
                <md-card class="md-card-profile logo-section" v-if="!hasTeam">
                    <div
                        class="md-card-avatar upload-container"
                        @mouseover="showLogoUploadButton = true"
                        @mouseleave="showLogoUploadButton = false"
                    >
                        <img
                            v-if="!logoPreview"
                            class="profile-image"
                            :src="user.logoUrl !== null ? user.logoUrl : $root.defaultLogoUrl"
                            @error="$_setDefaultBrokenImage"
                            alt
                        />
                        <img
                            v-if="logoPreview"
                            class="profile-image"
                            :src="logoPreview"
                            @error="$_setDefaultBrokenImage"
                            alt
                        />
                        <div v-if="showLogoUploadButton">
                            <input ref="logoInput" hidden type="file" @change="onLogoSelected" />
                            <div>
                                <button class="custom-btn" @click="$refs.logoInput.click()">Update Photo</button>
                            </div>
                        </div>
                    </div>

                    <md-card-content>
                        <h6 class="category text-gray">Company Logo</h6>
                        <span class="card-description">
                            {{ displayIndustry }}
                        </span>
                        <h4 class="card-title">
                            {{ displayCompany }}
                        </h4>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout-item md-medium-size-75 md-xsmall-size-100 md-size-75">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-warning">
                        <div class="card-icon">
                            <md-icon>person</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div class="member-information field-spacing-container">
                            <form-wrapper :validator="$v.user" class="form-wrapper">
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <form-group name="firstName" label="First Name">
                                                <md-input v-model="user.firstName" />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item">
                                            <form-group name="lastName" label="Last Name">
                                                <md-input v-model="user.lastName" />
                                            </form-group>
                                        </div>
                                    </div>

                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <form-group name="email" label="Email Address">
                                                <md-input v-model="user.email" />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item">
                                            <form-group name="phone" label="Phone">
                                                <md-input v-model="user.phone" />
                                            </form-group>
                                        </div>
                                    </div>
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <form-group name="company" label="Company">
                                                <md-input v-model="user.company" />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item">
                                            <form-group
                                                name="businessRegistrationNumber"
                                                :label="businessRegistrationNumberLabel"
                                            >
                                                <md-input v-model="user.businessRegistrationNumber" />
                                            </form-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box" v-if="skillList.length">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <div class="skillfield-container">
                                                <label for="skills" v-if="user.skills.length">Skills</label>
                                                <multiselect
                                                    v-model="user.skills"
                                                    :options="skillList"
                                                    :multiple="true"
                                                    :close-on-select="true"
                                                    placeholder="Pick skills"
                                                ></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box" v-if="serviceAreasList && serviceAreasList.length">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <div class="skillfield-container">
                                                <label for="serviceAreas" v-if="user.serviceAreas.length">
                                                    Service Areas
                                                </label>
                                                <multiselect
                                                    v-model="user.serviceAreas"
                                                    :options="serviceAreasList"
                                                    :multiple="true"
                                                    :close-on-select="true"
                                                    placeholder="Pick service areas"
                                                ></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box" v-if="capacityDefinitions.length">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <capacity-inputs
                                                v-for="(capacity, index) in capacityDefinitions"
                                                :key="index"
                                                :capacity-definition="capacity"
                                                :initial-value="capacityValues[capacity.type]"
                                                @changed="handleCapacityChanged"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div v-if="!hasTeam" class="md-layout">
                                        <div class="md-layout-item">
                                            <form-group name="company" label="Company">
                                                <md-input v-model="user.company" />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item">
                                            <form-group name="industry" label="Industry">
                                                <industry-drop-down
                                                    :industry="user.industry"
                                                    @selectedIndustry="selectedIndustry"
                                                />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item">
                                            <form-group name="websiteUrl" label="Website url">
                                                <md-input v-model="user.websiteUrl" />
                                            </form-group>
                                        </div>
                                    </div>
                                    <div class="md-layout">
                                        <div class="md-layout-item vehicle-box">
                                            <label>Vehicle:</label>
                                            <md-radio v-model="user.vehicleType" value="Bike">Bike</md-radio>
                                            <md-radio v-model="user.vehicleType" value="Car">Car</md-radio>
                                            <md-radio v-model="user.vehicleType" value="Walk">Walk</md-radio>
                                        </div>
                                    </div>
                                    <div class="md-layout">
                                        <div class="md-layout-item speed-display">
                                            <label>Speed Display:</label>
                                            <md-radio v-model="user.speedUnits" value="kph">
                                                Kilometers per hour
                                            </md-radio>
                                            <md-radio v-model="user.speedUnits" value="mph">Miles per hour</md-radio>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item" v-if="teamRegions && teamRegions.length > 0">
                                            <form-group
                                                v-if="isLoaded"
                                                name="teamRegion"
                                                label="Team Region"
                                                class="md-field-split"
                                            >
                                                <team-region-options
                                                    :selected="user.teamRegionId"
                                                    @selectedOption="handleTeamRegionChanged"
                                                    :enabled="canEditOwnTeamRegion"
                                                    :show-auto="false"
                                                    id="user-profile-team-region-options"
                                                    :show-all-team-region="true"
                                                />
                                            </form-group>
                                        </div>

                                        <div class="md-layout-item" v-if="!hasTeam || hasTeamAccess">
                                            <form-group
                                                name="role"
                                                label="Mobile Location Tracking Behaviour"
                                                class="md-field-split"
                                            >
                                                <gps-collection-dropdown
                                                    :selected="user.gpsDataCollectionRule"
                                                    @selectedOption="getSelectedTrackingBehaviour"
                                                />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item md-xsmall-size-100 md-size-25">
                                            <form-group
                                                v-if="isLoaded"
                                                name="defaultTripStartTime"
                                                label="Start Time"
                                                class="timer-field hide-timer-clear-button"
                                            >
                                                <time-picker
                                                    :time="user.defaultTripStartTime"
                                                    :all-time-options="false"
                                                    :clear-none="true"
                                                    @selectedTime="getStartTime"
                                                />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item md-xsmall-size-100 md-size-25">
                                            <form-group
                                                v-if="isLoaded"
                                                name="defaultTripEndTime"
                                                label="End Time"
                                                class="timer-field"
                                            >
                                                <time-picker
                                                    :time="user.defaultTripEndTime"
                                                    :all-time-options="false"
                                                    @selectedTime="getEndTime"
                                                />
                                            </form-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item md-xsmall-size-100 md-size-25">
                                            <form-group
                                                name="defaultStopDurationMinutes"
                                                label="Default Stop Duration (in Minutes)"
                                            >
                                                <md-input
                                                    type="number"
                                                    v-model.number="user.defaultStopDurationMinutes"
                                                />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item md-xsmall-size-100 md-size-25">
                                            <form-group name="stopDurationMultiplier" label="Stop Duration Multiplier">
                                                <md-input type="number" v-model.number="user.stopDurationMultiplier" />
                                            </form-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <md-checkbox v-model="user.startFromCurrentLocation">
                                                Start from current location
                                            </md-checkbox>
                                        </div>
                                    </div>
                                    <div class="md-layout" v-if="!user.startFromCurrentLocation">
                                        <div class="md-layout-item">
                                            <google-autocomplete
                                                label="Start Address"
                                                ref="startaddress"
                                                id="startaddress"
                                                classname="form-control autocomplete-input"
                                                placeholder=""
                                                v-model="startLocation"
                                                @placechanged="handleStartLocationChange"
                                            ></google-autocomplete>
                                        </div>
                                    </div>
                                </div>

                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <google-autocomplete
                                                label="End Address"
                                                ref="endaddress"
                                                id="endaddress"
                                                classname="form-control autocomplete-input"
                                                placeholder=""
                                                v-model="endLocation"
                                                @placechanged="handleEndLocationChange"
                                            ></google-autocomplete>
                                        </div>
                                    </div>
                                </div>

                                <payment-method-inputs :detail="user" />

                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <md-field>
                                                <label>Bio</label>
                                                <md-textarea v-model="user.bio" />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item custom-switch">
                                            <label class="label-text">
                                                Is this user a driver?
                                                <md-icon class="icon-info">info</md-icon>
                                                <md-tooltip class="tooltip-width" md-direction="right">
                                                    This setting is used to determine whether the user can be assigned
                                                    stops or shipments.
                                                </md-tooltip>
                                            </label>
                                            <md-switch v-model="user.isDriver"></md-switch>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item custom-switch">
                                            <label class="label-text">
                                                Apply tax for invoicing?
                                            </label>
                                            <md-switch v-model="user.shouldApplyTax"></md-switch>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <md-button class="md-primary dialog-button pull-right" @click="validateForm">
                                        Update Profile
                                    </md-button>
                                </div>
                            </form-wrapper>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import { BlockBlobClient } from '@azure/storage-blob';
import { mapGetters, mapMutations } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, email, requiredIf } from 'vuelidate/lib/validators';
import ValidatePasswordModal from '@/pages/User/ValidatePasswordModal';
import {
    GoogleAutocomplete,
    TimePicker,
    IndustryDropDown,
    GpsCollectionDropdown,
    CapacityInputs,
    TeamRegionOptions,
    PaymentMethodInputs
} from '@/components';
import {
    handleRequests,
    isPhoneValid,
    showErrorMessage,
    minStopDuration,
    maxStopDuration,
    isStopDurationNull,
    imageCacheBuster
} from '@/helpers';
import { GPS_DATA_COLLECTION_RULE_CONSTANTS } from '@/utils/constants';
// eslint-disable-next-line import/no-dynamic-require
const { config } = require(`@/config/config.${process.env.NODE_ENV}`);
const signalR = require('@aspnet/signalr');

export default {
    name: 'UserProfile',
    components: {
        GoogleAutocomplete,
        TimePicker,
        IndustryDropDown,
        GpsCollectionDropdown,
        CapacityInputs,
        TeamRegionOptions,
        PaymentMethodInputs
    },
    mixins: [GeneralMixin],
    data() {
        return {
            selectedPhoto: null,
            photoPreview: null,
            logoPreview: null,
            selectedLogo: null,
            connection: '',
            startLocation: {},
            endLocation: {},
            user: {
                paymentDetails: {
                    paymentMethod: 'None',
                    bank: {
                        name: null,
                        accountNumber: null,
                        bsb: null
                    }
                }
            },
            skillList: [],
            serviceAreasList: [],
            capacityDefinitions: [],
            capacityValues: {},
            showUploadButton: false,
            showLogoUploadButton: false,
            displayName: '',
            displayRole: '',
            displayCompany: '',
            displayIndustry: '',
            gpsDataCollectionOptions: GPS_DATA_COLLECTION_RULE_CONSTANTS,
            isLoaded: false,
            oldEmail: null,
            oldAccountNumber: null,
            businessRegistrationNumberLabel: 'Business Registration Number'
        };
    },
    validations: {
        user: {
            firstName: {
                required
            },
            defaultTripStartTime: {
                required
            },
            email: {
                required,
                email
            },
            defaultTripEndTime: {
                required:
                    // eslint-disable-next-line func-names
                    requiredIf(function() {
                        return this.user.gpsDataCollectionRule === 'Business Hours' && !this.user.defaultTripEndTime;
                    })
            },
            phone: {
                phoneValid: isPhoneValid
            },
            defaultStopDurationMinutes: {
                minValue_customerStopDuration: minStopDuration,
                maxValue_customerStopDuration: maxStopDuration, // max duration value of 24 hours,
                numeric_duration: isStopDurationNull
            },
            stopDurationMultiplier: {
                minValue_Multiplier(duration) {
                    if (duration === '' || duration === null) 
                        return true;

                    if (duration < 0.1) 
                        return false;

                    return true;
                },
                numeric_duration: isStopDurationNull
            },
            paymentDetails: {
                bank: {
                    accountNumber: {
                        required:
                            // eslint-disable-next-line func-names
                            requiredIf(function() {
                                return (
                                    this.user.paymentDetails.paymentMethod === 'Bank Transfer' &&
                                    !this.user.paymentDetails.bank.accountNumber
                                );
                            })
                    },
                    bsb: {
                        required:
                            // eslint-disable-next-line func-names
                            requiredIf(function() {
                                return (
                                    this.user.paymentDetails.paymentMethod === 'Bank Transfer' &&
                                    !this.user.paymentDetails.bank.bsb
                                );
                            })
                    }
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam',
            hasTeamAccess: 'user/hasTeamAccess',
            hasAssetAccess: 'user/hasAssetAccess',
            canEditOwnTeamRegion: 'user/canEditOwnTeamRegion',
            teamRegions: 'team/teamRegions'
        })
    },
    created() {
        this.$_handleLoaderState(false);
    },
    async mounted() {
        this.$_handleLoaderState(true);

        const userCrendentials = await window.auth.getUser();

        const url = '/api/users/me/profile?includeExtendedDetails=true';
        const { data } = await handleRequests(url);
        data.photoUrl = imageCacheBuster(data.photoUrl);
        data.logoUrl = imageCacheBuster(data.logoUrl);

        if (data.teamRegionId === null) 
            data.teamRegionId = -1; // Not Set

        this.user = data;

        this.$_handleLoaderState(false);

        this.startLocation = {
            address: this.user.startAddress,
            location: this.user.startLocation != null ? this.user.startLocation : { latitude: null, longitude: null }
        };

        this.endLocation = {
            address: this.user.endAddress,
            location: this.user.endLocation != null ? this.user.endLocation : { latitude: null, longitude: null }
        };

        this.user.roles = [this.user.role];
        this.displayName = this.user.fullName;
        this.displayRole = this.user.role;
        this.displayCompany = this.user.company;
        this.displayIndustry = this.user.industry;
        this.user.startFromCurrentLocation = this.user.startFromCurrentLocation || false;
        this.capacityDefinitions = this.user.vehicleCapacityUnitsConfiguration || [];
        this.capacityValues = this.user.vehicleCapacity || {};
        this.oldEmail = this.user.email;
        this.user.paymentDetails = this.user.paymentDetails || {
            paymentMethod: 'None',
            bank: {
                name: null,
                accountNumber: null,
                bsb: null
            }
        };
        this.oldAccountNumber = this.user.paymentDetails.bank.accountNumber;

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl('/ImageHub', {
                accessTokenFactory: () => {
                    return userCrendentials.access_token;
                }
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();
        this.connection.start();
        this.connection.on('Updated', async (isLogo, filename) => {
            await this.$store.dispatch('user/FETCH_USER');
            const obj = {};

            const isLogoUpdate = isLogo.toLowerCase() === 'true';

            // Cache busting as updated image has the same url
            if (isLogoUpdate) {
                this.logoPreview = false;
                obj.logoUrl = this.user.logoUrl;
                this.user.logoUrl = obj.logoUrl;
            } else {
                this.photoPreview = false;
                obj.photoUrl = this.user.photoUrl;
                this.user.photoUrl = obj.photoUrl;
            }

            this.updateProfile(obj);
            this.$notify({
                message: `Your ${
                    isLogoUpdate ? 'logo' : 'profile photo'
                } has been processed and is available now to view.`,
                type: 'success'
            });
        });

        this.getSkills();
        this.getServiceAreas();
        this.isLoaded = true;

        this.SetBusinessRegistrationNumberLabel(this.user.countryCode);
    },
    beforeRouteLeave(to, from, next) {
        if (!this.user.firstName) {
            const message = 'Please update your profile to continue.';
            showErrorMessage(this, message, null);

            this.$v.$touch();
            next(false); // prevent from leaving
        }

        next();
    },
    methods: {
        ...mapMutations({
            UPDATE_USER: 'user/UPDATE_USER'
        }),
        validateForm() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.update();
            }
        },
        async update() {
            if (!this.endLocation.address) {
                this.user.endAddress = null;
                this.user.endLocation = null;
            }

            // When user enters a blank value for defaultStopDurationMinute, it's passed to the API as an empty string. This
            // results in the API not being able to deserialise the data, resulting in a null reference exception on the server.
            // So we need to convert a blank string to null for this field.
            if (this.user.defaultStopDurationMinutes === '') 
                this.user.defaultStopDurationMinutes = null;
            if (this.user.stopDurationMultiplier === '') 
                this.user.stopDurationMultiplier = null;
            if (!this.user.serviceAreas?.length) 
                this.user.serviceAreas = null;

            if (!this.startLocation.address) {
                this.$notify({
                    message: 'Start Address is required',
                    type: 'danger'
                });
                return;
            }
            if (
                this.user.paymentDetails.bank.accountNumber &&
                this.user.paymentDetails.bank.accountNumber.includes('*')
            ) {
                this.user.paymentDetails.bank.accountNumber = this.oldAccountNumber;
            }

            if (this.oldEmail !== this.user.email) {
                this.$modal.show(ValidatePasswordModal).then((response) => {
                    this.$_handleLoaderState(true, 'VALIDATING...');
                    handleRequests(`${config.authority}api/user/validate-password`, {
                        method: 'post',
                        data: {
                            password: response
                        }
                    })
                        .then((response) => {
                            this.$_handleLoaderState(false);
                            this.updateRequest();
                        })
                        .catch((error) => {
                            this.$_handleLoaderState(false);
                            let message = 'Error in updating the profile. Failed to confirm password';
                            if (error.data === 'Password Validation not valid for social logins')
                                message =
                                    'Sorry, you are not allowed to update your email address when your account is linked to a social login.';
                            showErrorMessage(this, message);
                        });
                });
            } else {
                this.updateRequest();
            }
        },
        async updateRequest() {
            this.user.vehicleCapacity = this.capacityValues;

            this.$_handleLoaderState(true, 'UPDATING...');

            const api = '/api/users/me/profile/';
            const payload = {
                method: 'put',
                data: this.user
            };

            try {
                const response = await handleRequests(api, payload);

                if (response.status === 204) {
                    this.$notify({
                        message: 'Profile updated successfully!',
                        type: 'success'
                    });
                    this.updateProfile(this.user);
                    this.displayName = this.user.fullName;
                    this.displayRole = this.user.role;
                    this.displayCompany = this.user.company;
                    this.displayIndustry = this.user.industry;
                }
            } catch (e) {
                const message = 'Error in updating the profile';
                showErrorMessage(this, message, e);
            }

            this.$_handleLoaderState(false);
        },
        onPhotoSelected(event) {
            const [file] = event.target.files;
            this.selectedPhoto = file;
            this.photoPreview = URL.createObjectURL(this.selectedPhoto);
            this.onUploadImage('photo');
        },
        onLogoSelected(event) {
            const [file] = event.target.files;
            this.selectedLogo = file;
            this.logoPreview = URL.createObjectURL(this.selectedLogo);
            this.onUploadImage('logo');
        },
        async onUploadImage(type) {
            this.$_handleLoaderState(true, 'UPLOADING...');
            let file = this.selectedPhoto;

            if (type === 'logo') {
                file = this.selectedLogo;
            }

            const fileType = file.type.split('/');

            let ext = fileType[1];

            if (ext === 'jpeg') {
                ext = 'jpg';
            }

            const api = `/api/users/me/${type}/?fileType=${ext}`;
            const response = await handleRequests(api, { method: 'post' });

            const blockBlobClient = new BlockBlobClient(response.data.uri);

            try {
                await blockBlobClient.upload(file, file.size);
                this.$_handleLoaderState(false);

                this.$notify({
                    message: `Your ${type} has been successfully uploaded. Please wait a moment for it to be processed - it will reflect on your profile shortly.`,
                    type: 'success'
                });

                if (type === 'photo') {
                    this.selectedPhoto = null;
                }

                if (type === 'logo') {
                    this.selectedLogo = '';
                }
            } catch (e) {
                const message = `Error in uploading ${type}`;
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        async getSkills() {
            if (this.user.skillOptions) {
                this.skillList = this.user.skillOptions.map((x) => x.name);
            }
        },
        async getServiceAreas() {
            this.serviceAreasList = this.user.serviceAreasOptions;
        },
        getStartTime(selectedTime) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;
                this.user.defaultTripStartTime = appointmentTime;
            } else {
                this.user.defaultTripStartTime = null;
            }
        },
        getEndTime(selectedTime) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;
                this.user.defaultTripEndTime = appointmentTime;
            } else {
                this.user.defaultTripEndTime = null;
            }
        },
        selectedIndustry(industry) {
            this.user.industry = industry;
        },
        handleTeamRegionChanged(value) {
            this.user.teamRegionId = value;
        },
        handleStartLocationChange(data) {
            this.user.startAddress = data.address;
            this.user.startLocation = data.location;
            if (data && data.addressComponents) {
                this.SetBusinessRegistrationNumberLabel(data.addressComponents.countryCode);
            }
        },
        handleEndLocationChange(data) {
            this.user.endAddress = data.address;
            this.user.endLocation = data.location;
        },
        handleCapacityChanged({ name, value }) {
            this.capacityValues[name] = value;
        },
        getSelectedTrackingBehaviour(value) {
            this.user.gpsDataCollectionRule = value;
        },
        updateProfile(user) {
            if (user.teamRegionId === -1) 
                user.teamRegionId = null;

            if (
                this.user.paymentDetails.bank.accountNumber &&
                !this.user.paymentDetails.bank.accountNumber.includes('*')
            ) {
                const { accountNumber } = this.user.paymentDetails.bank;
                this.user.paymentDetails.bank.accountNumber = this.$_getMaskingText(accountNumber);
                this.oldAccountNumber = this.user.paymentDetails.bank.accountNumber;
            }

            this.UPDATE_USER(user);
        },
        SetBusinessRegistrationNumberLabel(countryCode) {
            switch (countryCode) {
                case 'AU':
                    this.businessRegistrationNumberLabel = 'ABN/ACN';
                    break;
                case 'NZ':
                    this.businessRegistrationNumberLabel = 'NZBN';
                    break;
                default:
                    this.businessRegistrationNumberLabel = 'Business Registration Number';
                    break;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.profile-container {
    margin-top: 20px;
}

.vehicle-box,
.speed-display {
    label {
        margin-right: 10px;
    }
    .md-radio {
        width: 100px;
    }
}

.speed-display {
    .md-radio {
        width: auto;
    }
}
.hide-timer-clear-button {
    ::v-deep .vs__actions {
        display: block;
    }
    ::v-deep .vs__clear {
        display: none;
    }
    ::v-deep .vs__dropdown-toggle {
        padding-bottom: 3px !important;
    }
    ::v-deep .vs__selected {
        margin-top: 0px;
    }
}
.timer-field ::v-deep .vs__dropdown-toggle {
    padding-bottom: 6px;
}
.custom-switch {
    margin-top: 22px;
    margin-bottom: 0px;
    label {
        float: left;
        font-weight: 400;
        color: #aaaaaa !important;
        line-height: 1.4;
    }
    .md-checked {
        ::v-deep .md-switch-container {
            background-color: #2b93ff !important;
        }
        ::v-deep .md-switch-thumb {
            border: 1px solid #2b93ff !important;
        }
    }
    ::v-deep .md-switch-label {
        margin-top: 0px;
    }
    .md-switch {
        margin: 0;
        margin-bottom: 10px;
        padding-left: 10px;
    }
}
.icon-info {
    color: #2b93ff !important;
    cursor: pointer;
    padding-bottom: 4px;
    margin-right: 15px;
}
.tooltip-width {
    max-width: 600px;
    text-align: justify;
}
.half-width {
    width: 50% !important;
}
</style>
