var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      attrs: { title: "Show load saved model" },
      on: { click: _vm.handleLoadSavedModal },
    },
    [
      _c("md-icon", [_vm._v("folder")]),
      _c(
        "md-tooltip",
        { staticClass: "toggle-tooltip", attrs: { "md-direction": "top" } },
        [_vm._v("Load Saved Model")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }