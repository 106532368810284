<template>
    <div class="edit-shipment">
        <md-button
            title="Edit shipment details"
            :class="useIcon ? 'md-warning md-just-icon md-round' : `${className}`"
            @click.stop="handleUpdateShipment"
            :disabled="shipmentStatus && shipmentStatus.toLowerCase() === 'cancelled'"
        >
            <md-icon v-if="useIcon">edit</md-icon>
            <span v-else>Edit</span>
        </md-button>
        <md-tooltip
            v-if="shipmentStatus && shipmentStatus.toLowerCase() === 'cancelled'"
            class="line-tooltip"
            md-direction="top"
        >
            You cannot edit a shipment when it is marked as Cancelled
        </md-tooltip>
    </div>
</template>

<script>
import { UpdateShipmentModal } from '@/pages/Shipments/components';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'UpdateShipmnetButton',
    props: {
        shipment: {
            type: [Number, String, Object],
            default: () => null
        },
        carrierTeamId: {
            type: [Number],
            default: () => null
        },
        teamMembers: {
            type: Array,
            default: () => []
        },
        className: {
            type: String,
            default: () => ''
        },
        useIcon: {
            type: Boolean,
            default: () => true
        },
        shipmentStatus: {
            type: String,
            default: () => null
        }
    },
    mixins: [GeneralMixin],
    methods: {
        handleUpdateShipment() {
            // disallow edit if assigned to carrier
            if (this.carrierTeamId) {
                this.$notify({
                    message: 'Shipment is assigned to a carrier. Edit is not allowed.',
                    type: 'warning'
                });
                return;
            }

            this.$modal
                .show(UpdateShipmentModal, {
                    members: this.teamMembers,
                    shipment: this.shipment
                })
                .then((response) => {
                    this.$emit('shipmentUpdated', response);
                    this.$modal.hide();
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.edit-shipment {
    display: inline;
}
</style>
