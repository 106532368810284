var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("span", [_vm._v("Edit " + _vm._s(_vm.type))]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body failed-delivery-configuration-modal" },
      [
        _c(
          "div",
          { staticClass: "stop-form-container" },
          [
            _c(
              "form-wrapper",
              {
                staticClass: "form-wrapper",
                attrs: { validator: _vm.$v.options },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout md-medium-size-100 md-xsmall-size-100 md-size-100 options",
                  },
                  [
                    _c("div", { staticClass: "md-layout-item md-size-100" }, [
                      _vm.isTextBoxOptions
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-size-10 option-toggle-button option-toggle-button-list",
                                },
                                [
                                  _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-primary md-just-icon md-round",
                                      on: { click: _vm.toggleOptions },
                                    },
                                    [
                                      _c("md-icon", [_vm._v("list")]),
                                      _c(
                                        "md-tooltip",
                                        { attrs: { "md-direction": "left" } },
                                        [_vm._v("Use list view to add option")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("label", { staticClass: "label-text" }, [
                                _vm._v("Available Options:"),
                              ]),
                              _c(
                                "form-group",
                                { attrs: { name: "options" } },
                                [
                                  _c("md-textarea", {
                                    attrs: { placeholder: "Option" },
                                    on: { change: _vm.addOptions },
                                    model: {
                                      value: _vm.optionValue,
                                      callback: function ($$v) {
                                        _vm.optionValue = $$v
                                      },
                                      expression: "optionValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "form-group",
                                { attrs: { name: "options" } },
                                [
                                  _c("md-input", {
                                    attrs: { placeholder: "Option" },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.addOption($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.optionValue,
                                      callback: function ($$v) {
                                        _vm.optionValue = $$v
                                      },
                                      expression: "optionValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]),
                    !_vm.isTextBoxOptions
                      ? _c(
                          "div",
                          {
                            staticClass: "md-layout-item md-size-0 add-icon",
                            on: { click: _vm.addOption },
                          },
                          [_c("md-icon", [_vm._v("add")])],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm.options.length && !_vm.isTextBoxOptions
                  ? _c(
                      "div",
                      { staticClass: "options-table" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-size-10 option-toggle-button",
                          },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-primary md-just-icon md-round",
                                on: { click: _vm.toggleOptions },
                              },
                              [
                                _c("md-icon", [_vm._v("launch")]),
                                _c(
                                  "md-tooltip",
                                  { attrs: { "md-direction": "left" } },
                                  [_vm._v("Use text box field")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("label", [_vm._v("Available Options:")]),
                        _c(
                          "md-table",
                          _vm._l(_vm.options, function (option, index) {
                            return _c(
                              "md-table-row",
                              { key: index },
                              [
                                _c(
                                  "md-table-cell",
                                  [
                                    _c(
                                      "form-group",
                                      [
                                        _c("md-input", {
                                          model: {
                                            value: option.value,
                                            callback: function ($$v) {
                                              _vm.$set(option, "value", $$v)
                                            },
                                            expression: "option.value",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "md-table-cell",
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-just-icon md-danger md-simple",
                                        attrs: { title: "delete option " },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.removeOption(index)
                                          },
                                        },
                                      },
                                      [_c("md-icon", [_vm._v("close")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.editConfiguration },
          },
          [_vm._v("\n            Save\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }