<template>
    <div>
        <md-button
            :title="title"
            class="md-primary md-just-icon md-round pull-right header-button"
            @click="batchUpload"
            :disabled="!teamMembers.length"
        >
            <md-icon>publish</md-icon>
        </md-button>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import BatchUploadModal from './BatchUploadModal';

export default {
    name: 'BatchUpload',
    mixins: [GeneralMixin],
    props: {
        title: {
            type: String,
            default: null
        },
        templateType: {
            type: String,
            default: null
        },
        teamMembers: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        batchUpload() {
            this.$modal
                .show(BatchUploadModal, {
                    members: this.teamMembers,
                    title: this.title,
                    templateType: this.templateType
                })
                .then((response) => {
                    if (response.toLowerCase() === 'ok') 
                        this.$emit('batchImported');
                    this.$modal.hide();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    margin-right: 16px;
}
</style>
