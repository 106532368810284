var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-content",
    { staticClass: "stop-container" },
    [
      !(
        (_vm.content.sourceReference === undefined ||
          _vm.content.sourceReference === null) &&
        (_vm.content.stopRef === undefined || _vm.content.stopRef === null)
      )
        ? _c("div", { staticClass: "md-layout" }, [
            _c(
              "div",
              { staticClass: "md-layout-item stop-ref-block" },
              [
                _c("span", { on: { click: _vm.showDetailsPage } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.content.sourceReference || _vm.content.stopRef
                      ) +
                      "\n            "
                  ),
                ]),
                _c(
                  "md-button",
                  {
                    staticClass:
                      "info--buttons md-white md-button md-just-icon md-round",
                    attrs: { title: "stop details", "md-ripple": false },
                    on: { click: _vm.showDetailsPage },
                  },
                  [
                    _c(
                      "md-icon",
                      { attrs: { id: "stop-ref-visibility-icon" } },
                      [
                        _vm._v(
                          "\n                    visibility\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "md-layout stop-address-block" }, [
        _c("div", { staticClass: "md-layout-item" }, [
          _vm.content.isStartLocation
            ? _c("span", { staticClass: "info-label" }, [
                _vm._v("Start location"),
              ])
            : _vm.content.isEndLocation
            ? _c("span", { staticClass: "info-label" }, [
                _vm._v("End location"),
              ])
            : _vm._e(),
          _c("span", { staticClass: "info-heading" }, [
            _vm._v(_vm._s(_vm.$_displayAddressName(_vm.content))),
          ]),
        ]),
      ]),
      ["Cancelled", "On Hold"].indexOf(_vm.content.status) === -1
        ? _c("div", { staticClass: "md-layout stop-status-block" }, [
            _vm.content.originalEta || _vm.content.arrivalDate
              ? _c("div", { staticClass: "md-layout-item" }, [
                  _c("span", { staticClass: "info-label" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          ["Arrived", "Complete"].indexOf(
                            _vm.content.status
                          ) === -1
                            ? "ETA"
                            : "Time of arrival:"
                        ) +
                        "\n            "
                    ),
                  ]),
                  ["Arrived", "Complete"].indexOf(_vm.content.status) !== -1
                    ? _c("span", [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("timeFormat")(
                                _vm.content.arrivalDate,
                                _vm.DATE_TYPES.standardTime
                              )
                            ) +
                            "\n            "
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("timeFormat")(
                              _vm.content.originalEta,
                              _vm.DATE_TYPES.standardTime
                            )
                          )
                        ),
                      ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("info-window-more-info-content", {
        attrs: {
          content: _vm.content,
          "show-details-page": _vm.showDetailsPage,
          "get-capacity-info": _vm.getCapacityInfo,
          "has-capacity-settings": _vm.hasCapacitySettings,
          "get-skills-info": _vm.getSkillsInfo,
          "has-skills-settings": _vm.hasSkillsSettings,
          "get-service-info": _vm.getServiceInfo,
          "has-service-settings": _vm.hasServiceSettings,
        },
      }),
      _c(
        "div",
        { staticClass: "md-layout md-alignment-center-space-between" },
        [
          _c(
            "div",
            { staticClass: "md-layout-item md-size-60 stop-team-member-block" },
            [
              _c("span", { staticClass: "team-member-image" }, [
                _vm.content.photoUrl
                  ? _c("img", {
                      staticClass: "profile-image",
                      attrs: { src: _vm.content.photoUrl, alt: "" },
                      on: { error: _vm.$_setDefaultBrokenImage },
                    })
                  : _c("img", {
                      staticClass: "member-image",
                      attrs: { src: _vm.$root.defaultPhotoUrl, alt: "" },
                    }),
              ]),
              _c("span", { staticClass: "left-margin" }, [
                _vm._v(_vm._s(_vm.content.teamMemberName)),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-size-40 stop-button-block" },
            [
              _c("delete-stop-button", {
                staticClass:
                  "info--buttons md-primary md-button md-just-icon md-round",
                attrs: {
                  "stop-id": _vm.content.stopId,
                  "vehicle-id": _vm.content.vehicleId,
                  "allocate-stops": _vm.content.allocateStops,
                  "stop-ref": _vm.content.stopRef,
                  address: _vm.content.address,
                },
                on: { stopDeleted: _vm.handleDeleteStop },
              }),
              _c("reassign-stop-button", {
                attrs: {
                  "team-members": _vm.content.teamMembers,
                  "allocate-stops": _vm.content.allocateStops,
                  "stop-id": _vm.content.stopId,
                  "vehicle-id": _vm.content.vehicleId,
                },
                on: { reassignedStop: _vm.handleReassignedStop },
              }),
              _vm.content.trackingLinkUrl
                ? _c(
                    "md-button",
                    {
                      staticClass:
                        "info--buttons md-primary md-button md-just-icon md-round",
                      attrs: { title: "tracking link", "md-ripple": false },
                      on: { click: _vm.showTrackingLink },
                    },
                    [_c("md-icon", [_vm._v("directions_car")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }