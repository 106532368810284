var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-overlay" },
    [
      _vm.isStreetView
        ? _c(
            "div",
            { staticClass: "close-button" },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-white md-icon-button",
                  attrs: { "md-ripple": false },
                  on: { click: _vm.close },
                },
                [_c("md-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isStreetView ? _c("TripDatePicker") : _vm._e(),
      !_vm.isStreetView && _vm.$root.isDesktop ? _c("MapSearch") : _vm._e(),
      _c(
        "GoogleMapLoader",
        {
          on: {
            "bounds-defined": _vm.setBounds,
            "google-defined": _vm.setGoogle,
            "map-defined": _vm.setMap,
            "panorama-defined": _vm.setPanorama,
          },
        },
        [
          [
            _vm.canViewTeamRegion &&
            _vm.teamRegions &&
            _vm.teamRegions.length > 0
              ? _c("team-region-member-filter-options", {
                  staticClass: "team-region-container",
                  attrs: {
                    "team-region-id": _vm.selectedTeamRegionId,
                    "show-team-member": false,
                    clearable: false,
                    "strict-team-region-filter": true,
                    "use-in-map": true,
                    display: "block",
                  },
                  on: { changed: _vm.handleTeamRegionChanged },
                })
              : _vm._e(),
            _vm.$root.isDesktop
              ? _c("GeofenceLayer", {
                  attrs: { map: _vm.map, user: _vm.currentUser },
                })
              : _vm._e(),
            !_vm.isStreetView
              ? _c("InfoWindow", {
                  attrs: { "is-date-today": _vm.isDateToday },
                })
              : _vm._e(),
            _vm.activePanel === "Team Members"
              ? _c(
                  "div",
                  _vm._l(_vm.driverDetails, function (driver) {
                    return _c("DriverMarker", {
                      key: driver.publicUserId,
                      attrs: {
                        driver: driver,
                        "is-date-today": _vm.isDateToday,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var publicUserId = ref.publicUserId
                              return _vm.stopDetails[publicUserId] &&
                                _vm.stopDetails[publicUserId].length > 0
                                ? [
                                    _c(
                                      "div",
                                      _vm._l(
                                        _vm.getModifiedStops(
                                          _vm.stopDetails,
                                          publicUserId
                                        ),
                                        function (stop) {
                                          return _c("StopMarker", {
                                            key: stop.stopId,
                                            attrs: {
                                              "public-user-id": publicUserId,
                                              stop: stop,
                                              driver: driver,
                                              "stop-order": stop.stopIndex,
                                              "min-distance": _vm.minDistance,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var stopId = ref.stopId
                                                    return [
                                                      stop.stopStatusHistory
                                                        .length > 0
                                                        ? _c(
                                                            "div",
                                                            _vm._l(
                                                              stop.stopStatusHistory,
                                                              function (
                                                                stopStatus,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: index,
                                                                  },
                                                                  [
                                                                    stopStatus.location &&
                                                                    stopStatus.oldStatus
                                                                      ? _c(
                                                                          "StopStatusHistoryMarker",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                driver:
                                                                                  driver,
                                                                                "stop-id":
                                                                                  stopId,
                                                                                "stop-details":
                                                                                  stop,
                                                                                "stop-status-details":
                                                                                  stopStatus,
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                      _vm.geofenceData[
                                                        stopId
                                                      ] &&
                                                      _vm.geofenceData[stopId]
                                                        .length > 0
                                                        ? _c(
                                                            "div",
                                                            _vm._l(
                                                              _vm.geofenceData[
                                                                stopId
                                                              ],
                                                              function (
                                                                geofence,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: index,
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "GeofenceMarker",
                                                                      {
                                                                        attrs: {
                                                                          driver:
                                                                            driver,
                                                                          "geofence-details":
                                                                            geofence,
                                                                          "stop-details":
                                                                            stop,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                : undefined
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  1
                )
              : _c(
                  "div",
                  _vm._l(_vm.assetGroupDetails, function (assetGroup) {
                    return _c("AssetGroupMarker", {
                      key: assetGroup.assetGroupId,
                      attrs: {
                        "asset-group": assetGroup,
                        "is-date-today": _vm.isDateToday,
                      },
                    })
                  }),
                  1
                ),
            _c("RoutePolyline", {
              attrs: {
                "pinned-driver-details": _vm.pinnedDriverDetails,
                "is-date-today": _vm.isDateToday,
              },
            }),
            _vm.pinnedUser
              ? _c(
                  "div",
                  [
                    !_vm.isReadOnlyUser ? _c("ToggleOptions") : _vm._e(),
                    !_vm.isReadOnlyUser
                      ? _c("CommandsPanel", {
                          attrs: {
                            "pinned-driver-details": _vm.pinnedDriverDetails,
                          },
                        })
                      : _vm._e(),
                    _vm.pinnedDriverDetails
                      ? _c("DriverProfile", {
                          attrs: {
                            "pinned-driver-details": _vm.pinnedDriverDetails,
                            "photo-url":
                              _vm.pinnedDriverDetails.photoUrl || undefined,
                          },
                        })
                      : _vm._e(),
                    _c("StartEndLocationMarker"),
                    _vm.pinnedDriverDetails !== undefined
                      ? _c("StopsListOverlay", {
                          attrs: {
                            "pinned-driver-details": _vm.pinnedDriverDetails,
                          },
                        })
                      : _vm._e(),
                    _vm.locationHistoryDetails.length
                      ? _c("LocationHistoryRoutePolyline", {
                          attrs: {
                            "location-history-details":
                              _vm.locationHistoryDetails,
                            "pinned-driver-details": _vm.pinnedDriverDetails,
                          },
                        })
                      : _vm._e(),
                    _vm._l(
                      _vm.locationHistoryDetails,
                      function (locationHistory, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            !locationHistory.isHB
                              ? _c("LocationHistoryMarker", {
                                  attrs: {
                                    "location-history-details": locationHistory,
                                    "pinned-driver-details":
                                      _vm.pinnedDriverDetails,
                                    "zoom-level": _vm.zoomLevel,
                                  },
                                })
                              : _c("HeartbeatMarker", {
                                  attrs: {
                                    "location-history-details": locationHistory,
                                    "pinned-driver-details":
                                      _vm.pinnedDriverDetails,
                                    "zoom-level": _vm.zoomLevel,
                                  },
                                }),
                          ],
                          1
                        )
                      }
                    ),
                    _c("RoutePlayback", {
                      attrs: {
                        "asset-present": !!_vm.assetGroupDetails.length,
                        "location-history-details": _vm.locationHistoryDetails,
                        "pinned-driver-details": _vm.pinnedDriverDetails,
                      },
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ],
        ],
        2
      ),
      _vm.membersLoaded &&
      (_vm.canViewTeamRegion || _vm.isReadOnlyUser) &&
      !_vm.isStreetView
        ? _c("TeamMembersList", {
            attrs: {
              drivers: _vm.driverDetails,
              "render-key": _vm.renderKey,
              "asset-groups": _vm.assetGroupDetails,
            },
            on: {
              changeLoading: function (val) {
                return (_vm.isLoading = val)
              },
            },
          })
        : _vm._e(),
      _c("LocationDataUpdates", {
        attrs: { "is-date-today": _vm.isDateToday },
        on: { locationUpdated: _vm.handleLocationUpdate },
      }),
      _vm.isLoading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "map-loader" },
              [
                _c("fade-loader", {
                  attrs: { loading: _vm.isLoading, color: "#000" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }