// Directive that will show a tooltip when the mouse is on top of an element with all the text in that element, if that
// element is currently overflowing. i.e. Text is hidden because it doesn't fit within the element.
export const OverflowTooltip = {
    bind(el) {
        const isMobile = window.matchMedia('(max-width: 480px)').matches;
        if (isMobile) 
            return true;
        el.addEventListener('mouseenter', () => {
            /* eslint consistent-return: 'off' */
            if (isMobile) 
                return true;
            if (el.scrollWidth > el.clientWidth) {
                const $tooltip = document.createElement('div');
                const rect = el.getBoundingClientRect();

                $tooltip.setAttribute('class', 'overflow-right-tooltip md-tooltip md-tooltip-right md-theme-default');
                $tooltip.setAttribute(
                    'style',
                    `transition-delay: 0ms; position: absolute; max-width: 350px; top: ${rect.top -
                        8}px; left: ${rect.left + 185}px;`
                );
                $tooltip.setAttribute('x-placement', 'right');

                $tooltip.innerHTML = el.innerText;

                document.body.insertBefore($tooltip, document.body.lastChild);
            }
        });

        el.addEventListener('mouseleave', () => {
            /* eslint consistent-return: 'off' */
            if (isMobile) 
                return true;
            const $tooltip = document.querySelector('.overflow-right-tooltip');
            if ($tooltip) {
                document.body.removeChild($tooltip);
            }
        });
    },
    unbind(el) {
        el.removeEventListener('mouseenter', () => {});
        el.removeEventListener('mouseleave', () => {});
    }
};
