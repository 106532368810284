var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-container" }, [
    _c(
      "div",
      { staticClass: "dayTime-container" },
      [
        _c("span", { staticClass: "custom-label" }, [_vm._v("Distance")]),
        _c("md-input", {
          staticClass: "distance-element",
          attrs: { id: "distance-input", type: "number" },
          model: {
            value: _vm.model.distance,
            callback: function ($$v) {
              _vm.$set(_vm.model, "distance", _vm._n($$v))
            },
            expression: "model.distance",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dayTime-container" },
      [
        _c("span", { staticClass: "custom-label" }, [_vm._v("Unit")]),
        _c("vue-select", {
          staticClass: "distance-element",
          attrs: {
            reduce: function (o) {
              return o.value
            },
            label: "text",
            options: _vm.unitOptions,
            clearable: false,
          },
          model: {
            value: _vm.model.unit,
            callback: function ($$v) {
              _vm.$set(_vm.model, "unit", $$v)
            },
            expression: "model.unit",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }