var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _vm.title
      ? _c("div", { staticClass: "modal-header" }, [
          _c("h4", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.title))]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "modal-body" }, [
      _c("div", { domProps: { innerHTML: _vm._s(_vm.body) } }),
    ]),
    _vm.buttons.length
      ? _c(
          "div",
          { staticClass: "modal-footer" },
          _vm._l(_vm.buttons, function (button, index) {
            return _c(
              "md-button",
              {
                key: index,
                staticClass: "dialog-button",
                class: [index === 0 ? "md-primary" : "md-default"],
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return (function () {
                      _vm.resolve(button)
                    })($event)
                  },
                },
              },
              [_vm._v("\n            " + _vm._s(button) + "\n        ")]
            )
          }),
          1
        )
      : _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "md-button",
              {
                staticClass: "md-primary dialog-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return (function () {
                      _vm.resolve(true)
                    })($event)
                  },
                },
              },
              [_vm._v("\n            OK\n        ")]
            ),
            _c(
              "md-button",
              {
                staticClass: "md-default dialog-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$modal.hide($event)
                  },
                },
              },
              [_vm._v("\n            Cancel\n        ")]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }