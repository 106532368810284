var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v("Add Business Entity"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          {
            staticClass: "form-wrapper",
            attrs: { validator: _vm.$v.entityDetails },
          },
          [
            _c("div", { staticClass: "form-container" }, [
              _c(
                "div",
                { staticClass: "contact-details" },
                [
                  _c(
                    "form-group",
                    {
                      attrs: {
                        name: "name",
                        label: "Business Name",
                        messages: { required: "Business Name is required." },
                      },
                    },
                    [
                      _c("md-input", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        attrs: { id: "businessName" },
                        model: {
                          value: _vm.entityDetails.businessRegistrationName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.entityDetails,
                              "businessRegistrationName",
                              $$v
                            )
                          },
                          expression: "entityDetails.businessRegistrationName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "form-group",
                    {
                      attrs: {
                        name: "number",
                        label: "Business Number (ABN)",
                        messages: { required: "Business Number is required." },
                      },
                    },
                    [
                      _c("md-input", {
                        attrs: { id: "businessNumber" },
                        model: {
                          value: _vm.entityDetails.businessRegistrationNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.entityDetails,
                              "businessRegistrationNumber",
                              $$v
                            )
                          },
                          expression:
                            "entityDetails.businessRegistrationNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "form-group",
                    {
                      attrs: {
                        name: "address",
                        label: "Business Address",
                        messages: { required: "Business Address is required." },
                      },
                    },
                    [
                      _c("md-input", {
                        attrs: { id: "businessAddress" },
                        model: {
                          value: _vm.entityDetails.businessAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.entityDetails, "businessAddress", $$v)
                          },
                          expression: "entityDetails.businessAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.addBusinessEntity($event)
              },
            },
          },
          [_vm._v("\n            Add\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }