<template>
    <th
        v-if="dropdownOptions != null && dropdownOptions.length > 0"
        class="md-table-head running-load-head-table-header"
    >
        <div class="md-table-head-container running-load-menu">
            <drop-down direction="down">
                <a href="#" class="load-header-text">
                    {{ defaultText }}
                    <md-icon class="icon-arrow">{{ columnIcon }}</md-icon>
                </a>
                <div class="header-note" v-if="selectedOption != null">
                    <span>{{ selectedOption.selectedLabel }}</span>
                </div>
                <ul class="dropdown-menu">
                    <li v-for="(option, index) in dropdownOptions" :key="index">
                        <md-icon :class="['icon-check', selectedOption.key === option.key ? 'icon-check-color' : '']">
                            check
                        </md-icon>
                        <a href="#" @click.stop="handleSelectedOption(option)">
                            {{ option.name }}
                        </a>
                        <md-tooltip v-if="option.tooltip != null">
                            {{ option.tooltip }}
                        </md-tooltip>
                    </li>
                </ul>
            </drop-down>
        </div>
    </th>
    <div v-else class="card-icon">
        <md-icon v-if="defaultIcon != null">{{ defaultIcon }}</md-icon>
        {{ defaultText }}
    </div>
</template>

<script>
export default {
    name: 'TableHeaderDropdown',
    props: {
        dropdownOptions: {
            type: Array,
            default: null
        },
        defaultIcon: {
            type: String,
            default: null
        },
        defaultText: {
            type: String,
            default: null
        },
        selectedOption: {
            type: Object,
            default: null
        },
        updateClass: {
            type: Boolean,
            default: false
        },
        columnIcon: {
            type: String,
            default: 'filter_alt'
        }
    },
    methods: {
        handleSelectedOption(value) {
            this.$emit('selectedOption', value);
        }
    }
};
</script>

<style lang="scss" scoped>
.running-load-head-table-header {
    min-width: 120px;
    // width: 80px;
    text-align: right;

    ::v-deep .md-table-head-label {
        float: left;
        padding-right: 5px;
    }

    .load-header-text,
    .load-header-text:hover {
        font-size: 1.0625rem;
        padding-left: 0;
        padding-right: 10px;
        font-weight: 300;
        color: #52b255 !important;
        text-transform: capitalize;
    }
    .icon-arrow {
        font-size: 18px !important;
        margin-top: -1px !important;
    }

    .icon-check {
        color: #fff !important;
        font-size: 16px !important;
        float: left;
    }

    .icon-check-color {
        color: #333333 !important;
    }

    .running-load-menu {
        ::v-deep .dropdown {
            position: absolute;
            top: 6px;
        }

        .dropdown-menu {
            margin-left: -50px;
            margin-top: 6px;

            li {
                border-bottom: 0px;
                padding: 10px;
            }

            hr.border {
                border-top: 1px solid #ddd;
            }

            a {
                color: #333333 !important;
                padding: 0px;
                margin: 0px;
                font-size: 12px;
                font-weight: 400;
                box-shadow: none;
            }

            a:hover {
                background-color: transparent !important;
                box-shadow: none;
                color: #2b93ff !important;
            }
        }

        .dropdown-menu li a:hover,
        .dropdown-menu li a:focus,
        .dropdown-menu li a:active {
            background-color: initial !important;
        }
    }
}

.header-note {
    text-align: left;
    font-size: 10px;
}
</style>
