var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cf-container" },
    [
      _vm.settings.length > 0
        ? _c(
            "div",
            { staticClass: "cf-action-container" },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-primary md-just-icon md-round",
                  attrs: { title: "Save Proof of delivery configurations" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.saveConfiguration()
                    },
                  },
                },
                [_c("md-icon", [_vm._v("save")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "alert-section" }, [
        _vm.showAlert
          ? _c(
              "div",
              {
                staticClass: "alert alert-warning alert-dismissible fade show",
                attrs: { role: "alert" },
              },
              [
                _c("strong", [_vm._v("ATTENTION!")]),
                _vm._v(
                  "\n            Please click the save button on the upper right to save the changes made.\n            "
                ),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "alert",
                      "aria-label": "Close",
                    },
                    on: { click: _vm.closeAlert },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "md-table",
        { staticClass: "custom-paginated-table" },
        [
          _c(
            "draggable",
            {
              staticClass: "custom-draggable",
              attrs: { draggable: ".drag-item" },
              model: {
                value: _vm.settings,
                callback: function ($$v) {
                  _vm.settings = $$v
                },
                expression: "settings",
              },
            },
            [
              _c(
                "md-table-row",
                { attrs: { slot: "header" }, slot: "header" },
                [
                  _c("md-table-head"),
                  _c("md-table-head", [_vm._v("Type")]),
                  _c("md-table-head", [_vm._v("Stops")]),
                  _c("md-table-head", [_vm._v("Shipment Pickup")]),
                  _c("md-table-head", [_vm._v("Shipment Drop Off")]),
                  !_vm.isReadOnlyUser && !_vm.isSingleUser
                    ? _c("md-table-head", [_vm._v("Include in PDF")])
                    : _vm._e(),
                ],
                1
              ),
              _vm._l(_vm.settings, function (setting, index) {
                return _c(
                  "md-table-row",
                  { key: index, staticClass: "drag-item" },
                  [
                    _c(
                      "md-table-cell",
                      [_c("md-icon", [_vm._v("drag_indicator")])],
                      1
                    ),
                    _c("md-table-cell", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t("podTypes." + setting.type.toLowerCase())
                          ) +
                          "\n                "
                      ),
                    ]),
                    _c(
                      "md-table-cell",
                      [
                        _c(
                          "form-group",
                          { staticClass: "pod-dropdown" },
                          [
                            _c(
                              "md-select",
                              {
                                on: { "md-selected": _vm.updateSettings },
                                model: {
                                  value: setting.standardStops,
                                  callback: function ($$v) {
                                    _vm.$set(setting, "standardStops", $$v)
                                  },
                                  expression: "setting.standardStops",
                                },
                              },
                              [
                                _c(
                                  "md-option",
                                  { attrs: { value: "Always" } },
                                  [_vm._v("Always")]
                                ),
                                _c(
                                  "md-option",
                                  { attrs: { value: "Optional" } },
                                  [_vm._v("Optional")]
                                ),
                                _c("md-option", { attrs: { value: "Never" } }, [
                                  _vm._v("Never"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "md-table-cell",
                      [
                        _c(
                          "form-group",
                          { staticClass: "pod-dropdown" },
                          [
                            _c(
                              "md-select",
                              {
                                on: { "md-selected": _vm.updateSettings },
                                model: {
                                  value: setting.shipmentPickupStops,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      setting,
                                      "shipmentPickupStops",
                                      $$v
                                    )
                                  },
                                  expression: "setting.shipmentPickupStops",
                                },
                              },
                              [
                                _c(
                                  "md-option",
                                  { attrs: { value: "Always" } },
                                  [_vm._v("Always")]
                                ),
                                _c(
                                  "md-option",
                                  { attrs: { value: "Optional" } },
                                  [_vm._v("Optional")]
                                ),
                                _c("md-option", { attrs: { value: "Never" } }, [
                                  _vm._v("Never"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "md-table-cell",
                      [
                        _c(
                          "form-group",
                          { staticClass: "pod-dropdown" },
                          [
                            _c(
                              "md-select",
                              {
                                on: { "md-selected": _vm.updateSettings },
                                model: {
                                  value: setting.shipmentDropStops,
                                  callback: function ($$v) {
                                    _vm.$set(setting, "shipmentDropStops", $$v)
                                  },
                                  expression: "setting.shipmentDropStops",
                                },
                              },
                              [
                                _c(
                                  "md-option",
                                  { attrs: { value: "Always" } },
                                  [_vm._v("Always")]
                                ),
                                _c(
                                  "md-option",
                                  { attrs: { value: "Optional" } },
                                  [_vm._v("Optional")]
                                ),
                                _c("md-option", { attrs: { value: "Never" } }, [
                                  _vm._v("Never"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.isReadOnlyUser && !_vm.isSingleUser
                      ? _c(
                          "md-table-cell",
                          [
                            _c(
                              "form-group",
                              { staticClass: "pod-checkbox" },
                              [
                                _c("md-checkbox", {
                                  model: {
                                    value: setting.includeInPodPdf,
                                    callback: function ($$v) {
                                      _vm.$set(setting, "includeInPodPdf", $$v)
                                    },
                                    expression: "setting.includeInPodPdf",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm.settings.length == 0
        ? _c("div", { staticClass: "text-center" }, [
            _c("p", { staticClass: "no-result-message" }, [
              _vm._v("This setting is currently not yet available."),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }