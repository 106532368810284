<template>
    <div class="cf-container">
        <div v-if="teamRegionSchemeSupported" class="cf-action-container">
            <md-button
                @click.stop="handleAdd"
                title="Add New Setting"
                class="md-primary md-just-icon md-round add-geofence-event"
            >
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div v-if="offerSettings && offerSettings.length">
            <md-table class="context-menu-support custom-paginated-table">
                <md-table-row>
                    <md-table-head>Team Region</md-table-head>
                    <md-table-head v-if="showAutoAllocateMethodFields">Max Retry</md-table-head>
                    <md-table-head v-if="showAutoAllocateMethodFields">Retry Delay</md-table-head>
                    <md-table-head>Match Skills</md-table-head>
                    <md-table-head>Check Load Capacity</md-table-head>
                    <md-table-head>Prioritize Empty Vehicles</md-table-head>
                    <md-table-head v-if="showAutoAllocateMethodFields">Bands Count</md-table-head>
                    <md-table-head class="end">Actions</md-table-head>
                </md-table-row>
                <md-table-row v-for="item in offerSettings" :key="item.teamRegionId">
                    <md-table-cell>
                        {{ item.teamRegionName !== null ? item.teamRegionName : 'Team Level' }}
                    </md-table-cell>
                    <md-table-cell v-if="showAutoAllocateMethodFields">{{ item.maxRetry }}</md-table-cell>
                    <md-table-cell v-if="showAutoAllocateMethodFields">
                        {{ item.retryDelay | timeSpanDesc }}
                    </md-table-cell>
                    <md-table-cell>{{ item.matchSkills | yesNoDesc }}</md-table-cell>
                    <md-table-cell>{{ item.checkLoadCapacity | yesNoDesc }}</md-table-cell>
                    <md-table-cell>{{ item.prioritizeEmptyVehicles | yesNoDesc }}</md-table-cell>
                    <md-table-cell v-if="showAutoAllocateMethodFields">
                        {{ item.bands && item.bands.length ? item.bands.length : '-' }}
                    </md-table-cell>
                    <md-table-cell align="end" class="action-buttons">
                        <md-button
                            title="View details"
                            class="md-info md-just-icon md-round"
                            @click.stop="handleViewDetails(item)"
                            :data-test-id="`btn-view-details-${item.teamRegionId}`"
                        >
                            <md-icon>subject</md-icon>
                        </md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
        <div v-else>
            <p class="no-result-message">No Job Offer settings found.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'JobOfferSettingsList',
    props: {
        offerSettings: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            showAutoAllocateMethodFields: false, // false until Algorithmic Allocation Method is re-enabled (JOB_OFFER_ALLOCATION_METHOD_OPTIONS)
            teamRegionSchemeSupported: false // we'll remove this when the TeamRegion Routing Model Scheme is supported
        };
    },
    methods: {
        handleAdd() {
            this.$emit('onAddClick');
        },
        handleViewDetails(item) {
            const teamRegionId = item.teamRegionId !== null ? item.teamRegionId : -1; // NOT SET
            this.$emit('onViewDetailsClick', teamRegionId);
        }
    }
};
</script>

<style scoped>
.md-table-head.end {
    text-align: right;
}
</style>
