var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c("div", { staticClass: "custom-toolbar md-layout" }, [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item custom-toolbar-start md-small-size-70 md-size-70",
              },
              [
                _c("SmsHistoryFilter", {
                  on: { onChanged: _vm.smsHistoryFilterChangedHandler },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "md-layout-item custom-toolbar-end" },
              [
                _vm.$root.isDesktop
                  ? _c("SearchComponent", {
                      attrs: { "search-filter": _vm.searchTextValue },
                      on: { onSearch: _vm.searchComponentSearchHandler },
                    })
                  : _vm._e(),
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-primary md-just-icon md-round pull-right header-button",
                    attrs: { title: "Export Sms History" },
                    on: { click: _vm.onExportToExcel },
                  },
                  [_c("md-icon", [_vm._v("get_app")])],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-blue" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("sms")])],
                    1
                  ),
                ]
              ),
              _c("md-card-content", [
                !_vm.smsLogs || _vm.smsLogs.length == 0
                  ? _c("div", [
                      _c("h3", [_vm._v("Sms History")]),
                      _c("p", [
                        _vm._v(
                          "\n                            No results to display.\n                        "
                        ),
                      ]),
                    ])
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "report-container" },
                        [
                          _c(
                            "md-card-content",
                            [
                              _c("md-table", {
                                staticClass: "table-hover",
                                attrs: { value: _vm.smsLogs },
                                scopedSlots: _vm._u([
                                  {
                                    key: "md-table-row",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return _c(
                                        "md-table-row",
                                        {},
                                        [
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: {
                                                "md-label": "Date/Time",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm._f("dateTimeFormat")(
                                                      item.statusUpdatedDate,
                                                      _vm.DATE_TYPES
                                                        .standardDate
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: {
                                                "md-label": "Recipient",
                                                "md-sort": "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(item.toNumber) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: { "md-label": "Address" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(item.stopAddress) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: { "md-label": "Trigger" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.toTriggerString(
                                                      item.eventTrigger
                                                    )
                                                  ) +
                                                  "\n                                            "
                                              ),
                                              item.statusFilter
                                                ? [
                                                    _c("br"),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          "(" +
                                                            item.statusFilter +
                                                            ")"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: { "md-label": "Segments" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(item.segments) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: { "md-label": "Content" },
                                            },
                                            [
                                              _c("md-icon", [
                                                _vm._v("visibility"),
                                              ]),
                                              _c(
                                                "md-tooltip",
                                                {
                                                  attrs: {
                                                    "md-direction": "top",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.message))]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "md-table-cell",
                                            { attrs: { "md-label": "Status" } },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(item.l2uStatus) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
              ]),
              _vm.total
                ? _c(
                    "md-card-actions",
                    {
                      staticClass: "page-footer",
                      attrs: { "md-alignment": "space-between" },
                    },
                    [
                      _c("div", [
                        _vm.total === _vm.pagination.perPage
                          ? _c("p", { staticClass: "card-category" }, [
                              _vm._v(
                                "\n                            Page " +
                                  _vm._s(_vm.pagination.currentPage) +
                                  " of many\n                        "
                              ),
                            ])
                          : _c("p", { staticClass: "card-category" }, [
                              _vm._v(
                                "Page " +
                                  _vm._s(_vm.pagination.currentPage) +
                                  " of " +
                                  _vm._s(_vm.totalPages)
                              ),
                            ]),
                      ]),
                      _c("Pagination", {
                        staticClass: "pagination-no-border pagination-success",
                        attrs: {
                          "per-page": _vm.pagination.perPage,
                          total: _vm.total,
                          "enable-first-page-link": true,
                          "enable-last-page-link": true,
                        },
                        on: {
                          "change-page": function ($event) {
                            return _vm.handleChangePage(
                              $event,
                              _vm.pagination.perPage
                            )
                          },
                        },
                        model: {
                          value: _vm.pagination.currentPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.pagination, "currentPage", $$v)
                          },
                          expression: "pagination.currentPage",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }