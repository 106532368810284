<template>
    <div class="modal-container">
        <div class="modal-header">
            <span v-if="selectedCustomField === null">Add Asset Custom Field</span>
            <span v-else>Edit {{ selectedCustomField.label }}</span>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.form" class="form-wrapper">
                <form-group v-if="selectedCustomField !== null" label="Id" name="label">
                    <md-input v-model="form.name" disabled />
                </form-group>
                <form-group label="Label" name="label">
                    <md-input v-model="form.label" />
                </form-group>
                <form-group name="type">
                    <vue-select
                        v-if="customFieldTypes"
                        :options="customFieldTypes"
                        placeholder="Type"
                        v-model="form.type"
                        :searchable="$root.isDesktop"
                    ></vue-select>
                </form-group>
                <form-group label="Select asset type" class="asset-type-filter" name="assetType">
                    <md-select v-model="form.assetType">
                        <md-option v-for="(assetType, index) in assetTypes" :key="index" :value="assetType">
                            {{ assetType }}
                        </md-option>
                    </md-select>
                </form-group>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button
                v-if="selectedCustomField === null"
                class="dialog-button md-primary"
                @click="addAssetCustomField"
            >
                Add
            </md-button>
            <md-button v-else class="dialog-button md-primary" @click="editAssetCustomField">
                Save
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';
import { CUSTOM_FIELD_TYPES_CONSTANTS, ASSET_TYPE_CONSTANTS } from '@/utils/constants';

export default {
    name: 'AssetCustomFieldModal',
    mixins: [GeneralMixin],
    props: {
        selectedCustomField: {
            type: Object,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            form: {
                name: '',
                label: '',
                type: '',
                assetType: ''
            },
            customfield: {},
            customFieldTypes: CUSTOM_FIELD_TYPES_CONSTANTS,
            assetTypes: [...ASSET_TYPE_CONSTANTS, 'Any']
        };
    },
    mounted() {
        if (this.selectedCustomField !== null) {
            this.form.name = this.selectedCustomField.name;
            this.form.label = this.selectedCustomField.label;
            this.form.type = this.selectedCustomField.type;
            this.form.assetType = this.selectedCustomField.assetType;
        }
    },
    methods: {
        addAssetCustomField() {
            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                this.customfield = {
                    name: this.form.label.toLowerCase().replace(/[^\w]/gi, ''),
                    label: this.form.label,
                    type: this.form.type,
                    assetType: this.form.assetType
                };
                this.resolve(this.customfield);
            }
        },
        editAssetCustomField() {
            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                this.customfield = Object.assign({}, this.form);
                this.resolve(this.customfield);
            }
        }
    },
    validations: {
        form: {
            label: {
                required
            },
            type: {
                required
            },
            assetType: {
                required
            }
        }
    }
};
</script>
