var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-spacing" },
    [
      _c(
        "sidebar-item",
        {
          attrs: {
            link: { name: _vm.$t("menus.team.settings"), icon: "settings" },
          },
        },
        [
          _vm.hasTeamSettingsAccess
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: _vm.$t("menus.team.profile"),
                    path: "/team/profile",
                  },
                },
              })
            : _vm._e(),
          _c("sidebar-item", {
            attrs: {
              link: {
                name: _vm.$t("menus.team.members"),
                path: "/team/team-members",
              },
            },
          }),
          _vm.hasTeamSettingsAccess
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: _vm.$t("menus.team.configuration"),
                    path: "/team/settings",
                  },
                },
              })
            : _vm._e(),
          _vm.hasMarketplaceAccess &&
          (!_vm.teamType || _vm.teamType !== "Carrier")
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: "Carriers",
                    path: "/team/carriers",
                  },
                },
              })
            : _vm._e(),
          _vm.hasMarketplaceAccess &&
          (!_vm.teamType || _vm.teamType !== "Carrier")
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: "Carrier Marketplace",
                    path: "/marketplace",
                  },
                },
              })
            : _vm._e(),
          _c("sidebar-item", {
            attrs: {
              link: {
                name: _vm.$t("menus.team.runSchedules"),
                path: "/team/run-schedules",
              },
            },
          }),
          !_vm.isIndividualUser &&
          _vm.hasInventoryListAccess &&
          _vm.$root.isDesktop &&
          !_vm.isReadOnlyUser
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: "Warehouses",
                    icon: "store",
                    path: "/warehouses/list",
                  },
                },
              })
            : _vm._e(),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "nav-link first-support-link",
                on: {
                  click: function ($event) {
                    return _vm.openCustomerBillingPortal()
                  },
                },
              },
              [
                _c("span", { staticClass: "sidebar-mini" }, [
                  _vm._v(_vm._s(_vm.$t("menus.team.billing-mini"))),
                ]),
                _c("span", { staticClass: "sidebar-normal" }, [
                  _vm._v(_vm._s(_vm.$t("menus.team.billing"))),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }