var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          {
            staticClass: "form-wrapper",
            attrs: { validator: _vm.$v.chargeLine },
          },
          [
            _c(
              "div",
              { staticClass: "item-description" },
              [
                _c(
                  "form-group",
                  { attrs: { label: "Description" } },
                  [
                    _c("md-input", {
                      attrs: { disabled: _vm.chargeLine.type === "surcharge" },
                      model: {
                        value: _vm.chargeLine.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.chargeLine, "description", $$v)
                        },
                        expression: "chargeLine.description",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item-unit" },
              [
                _c(
                  "form-group",
                  { attrs: { label: "Unit" } },
                  [
                    _c("md-input", {
                      attrs: { readonly: "" },
                      model: {
                        value: _vm.chargeLine.chargeUnit,
                        callback: function ($$v) {
                          _vm.$set(_vm.chargeLine, "chargeUnit", $$v)
                        },
                        expression: "chargeLine.chargeUnit",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item-unitPriceExTax" },
              [
                _c(
                  "form-group",
                  { attrs: { label: "Amount" } },
                  [
                    _c("md-input", {
                      model: {
                        value: _vm.chargeLine.unitPriceExTax,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.chargeLine,
                            "unitPriceExTax",
                            _vm._n($$v)
                          )
                        },
                        expression: "chargeLine.unitPriceExTax",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            on: { click: _vm.addChargeItem },
          },
          [_vm._v("\n            Save\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Add Charge item")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }