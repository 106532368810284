export const ScrollTop = {
    bind(el) {
        el.event = (event) => {
            if (!el.contains(event.target)) {
                el.firstElementChild.scrollTop = 0;
            }
        };
        document.body.addEventListener('change', el.event);
    },
    unbind(el) {
        document.body.removeEventListener('change', el.event);
    }
};
