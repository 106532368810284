var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table" },
    [
      _c(
        "div",
        { staticClass: "table-row table-header" },
        [_vm._t("table-header")],
        2
      ),
      _c("div", {
        staticStyle: { height: "1px", width: "100%", background: "#eee" },
      }),
      _vm._t("table-content"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }