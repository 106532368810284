<template>
    <div class="cf-container">
        <div class="cf-action-container">
            <div class="mappings-template" v-if="mappingData.viewType == 'designer'">
                <drop-down direction="down">
                    <span class="mappings-template-text">
                        <md-tooltip md-direction="top" class="toggle-tooltip">
                            Mapping Template - {{ mappingData.selectedTemplateName }}
                        </md-tooltip>
                        {{ mappingData.selectedTemplateName }}
                    </span>
                    <md-icon class="icon-arrow">expand_more</md-icon>
                    <ul class="dropdown-menu">
                        <li
                            v-for="(templateName, index) in mappingData.templateNameList"
                            :key="index"
                            @click="getSelectedTemplate(templateName)"
                        >
                            <md-icon
                                :class="[
                                    'icon-check',
                                    mappingData.selectedTemplateName === templateName ? 'icon-check-color' : ''
                                ]"
                            >
                                check
                            </md-icon>
                            <span class="name">
                                {{ templateName }}
                            </span>

                            <div
                                class="delete"
                                v-if="templateName !== 'Default'"
                                @click.stop.prevent="deleteTemplate(templateName)"
                            >
                                <md-tooltip md-direction="right" class="toggle-tooltip">
                                    Delete Template
                                </md-tooltip>
                                <md-icon>close</md-icon>
                            </div>
                        </li>
                        <li class="li-border">
                            <md-button class="dialog-button md-primary add" @click="addNewTemplate">
                                Add Template
                            </md-button>
                        </li>
                    </ul>
                </drop-down>
            </div>
            <div class="switch-options">
                <div class="custom-switch">
                    <md-switch v-model="mappingData.isJSONEditorView" @change="updateViewType()">JSON Editor</md-switch>
                </div>
            </div>
            <md-button
                title="Reset mappings - mappings will be reverted to the original mappings."
                class="md-just-icon md-round reset-btn"
                @click.stop="handleReset()"
            >
                <md-icon>settings_backup_restore</md-icon>
            </md-button>
            <input ref="fileInput" style="display:none;" type="file" @change="onFileSelected" />
            <md-button
                v-if="mappingData.viewType == 'designer'"
                title="Please upload a sample spreadsheet."
                class="md-primary md-just-icon md-round"
                @click="$refs.fileInput.click()"
            >
                <md-icon>publish</md-icon>
            </md-button>
            <md-button title="Save mappings" class="md-primary md-just-icon md-round" @click.stop="handleSave()">
                <md-icon>save</md-icon>
            </md-button>
        </div>
        <div v-if="mappingData.viewType == 'designer'">
            <div class="alert alert-warning" v-if="mappingData.isDefaultMappings">
                <span>
                    You do not have any import field mappings configured. Please upload a sample spreadsheet using the
                    Upload button in the top right to start mapping your spreadsheet for import.
                </span>
            </div>
            <div class="import-designer">
                <div class="mappings-header">
                    <div class="field-label-col">Field</div>
                    <div class="mapped-column">
                        Mapped Column
                    </div>
                </div>
                <div v-for="(data, index) in mappingData.importMappingFields" :key="index">
                    <div v-if="data.isSectionHeader" class="section-header">
                        {{ data.displayName }}
                    </div>
                    <div v-else>
                        <div class="field-label-col field-label">
                            {{ data.displayName }}
                        </div>
                        <div class="mapped-column">
                            <designer-field-inputs :field-definition="data" @editCustomMapping="editCustomMapping" />
                        </div>
                        <div
                            v-if="data.value && typeof data.value !== 'string'"
                            class="custom-mapping-info"
                            title="Edit Mapping"
                            @click="editCustomMapping(data)"
                        >
                            <span v-if="data.value.formatString">Format: {{ data.value.formatString }}</span>
                            <span v-if="data.value.formatString && data.value.defaultValue">
                                ,&nbsp;&nbsp;
                            </span>
                            <span v-if="data.value.defaultValue">Default Value: {{ data.value.defaultValue }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="mappingData.viewType == 'editor'">
            <div class="import-editor">
                <form-group>
                    <md-textarea class="import-textarea" v-model="mappingData.importMappingJson" />
                </form-group>
            </div>
        </div>
    </div>
</template>

<script>
import CustomMappingModal from './CustomMappingModal';
import DesignerFieldInputs from './DesignerFieldInputs';

export default {
    name: 'ImportMappingView',
    components: {
        DesignerFieldInputs
    },
    props: {
        mappingData: {
            type: Object,
            default: () => {}
        },
        importConfiguration: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        addNewTemplate() {
            this.$emit('handleAddNewTemplate');
        },
        deleteTemplate(value) {
            this.$emit('handleDeleteTemplate', value);
        },
        async getSelectedTemplate(value) {
            this.$emit('handleTemplateChanged', value);
            this.mappingData.selectedTemplateName = value;
        },
        onFileSelected(event) {
            if (event.target.files[0]) 
                this.onUpload(event.target.files[0]);

            event.target.value = '';
        },

        async onUpload(selectedFile) {
            await this.$emit('onUpload', selectedFile);
        },

        async handleSave() {
            await this.$emit('handleSave');
        },

        async handleReset() {
            await this.$emit('handleReset');
        },

        updateViewType() {
            this.$emit('updateViewType');
        },

        editCustomMapping(fieldDefinition) {
            this.$modal
                .show(CustomMappingModal, {
                    title: fieldDefinition.displayName,
                    formatString: fieldDefinition.value?.formatString,
                    defaultValue: fieldDefinition.value?.defaultValue
                })
                .then((response) => {
                    fieldDefinition.value = response;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.cf-container .cf-action-container {
    position: absolute;
    top: -50px;
    right: 10px;
}
.md-button.md-primary.dialog-button {
    color: #fff !important;
    background-color: #2b93ff !important;
    margin-top: -2px;
}

.import-designer {
    min-height: 360px;
    h5 {
        font-weight: 400;
    }
    &::before {
        margin-bottom: 10px;
    }
    &::after {
        border-bottom: 0px !important;
    }
    .field-label-col {
        width: 170px;
        display: inline-block;
        padding: 3px;
    }
    .field-label {
        font-weight: 400;
    }
    .mapped-column {
        padding: 3px 3px 3px 20px;
        width: 500px;
        display: inline-block;
    }
    .custom-mapping-info {
        padding: 3px 3px 3px 20px;
        width: 500px;
        display: inline-block;
        cursor: pointer;
    }
    .custom-mapping-info:hover {
        color: #2b93ff;
    }
    .mappings-header {
        color: #4caf50 !important;
        font-weight: 300 !important;
        font-size: 17px;
        margin-top: 15px;
    }
    .section-header {
        color: #4caf50 !important;
        font-weight: 400;
        margin-top: 25px;
        width: 150px;
        padding: 3px;
    }
}
.import-editor {
    min-height: 510px;
    padding-top: 5px;
    textarea {
        min-height: 500px !important;
        width: 96%;
        font-family: monospace;
        border: 1px solid #ccc;
    }
    &::before {
        margin-bottom: 10px;
    }
    &::after {
        border-bottom: 0px !important;
    }
    .md-field.md-has-textarea:not(.md-autogrow):after,
    .md-field.md-has-textarea:not(.md-autogrow):before {
        border-color: transparent !important;
    }
}

.mappings-template {
    float: left;
    margin-top: -2px;
    margin-right: 30px;
    .mappings-template-text,
    .mappings-template:hover {
        font-size: 14px;
        line-height: 1.42857;
        font-weight: 400;
        color: rgba(80, 80, 80, 0.9) !important;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
    }
    .icon-arrow {
        font-size: 24px !important;
        margin-top: -2px;
    }
    .open {
        .icon-arrow {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
        }
    }
    .icon-check {
        color: #fff !important;
        background: transparent !important;
        margin: 0px !important;
    }
    .icon-check-color {
        color: #333333 !important;
    }
    ::v-deep .dropdown {
        top: 8px;
        display: flex;
    }
    .dropdown-menu {
        max-width: 175px;
        margin-top: 6px;
        z-index: 999999;
        .li-border {
            border-top: 1px solid #b1b1b1;
            margin-top: 5px;
        }
        li {
            color: #333333;
            cursor: pointer;
            font-size: 14px;
            display: flex;
            line-height: 28px;
            &:hover {
                color: #2b93ff !important;
                ::v-deep .delete {
                    display: block;
                }
            }

            .name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
                font-weight: 400;
            }

            .add {
                width: 160px;
                margin-top: 5px;
                margin-bottom: 0px;
            }

            .delete {
                display: none;
                margin-left: auto;
                margin-right: 5px;
            }

            .md-icon {
                width: 28px;
                height: 28px;
                font-size: 14px !important;
                font-weight: 500;
                border-radius: 50%;
            }
        }
    }
}
.md-tooltip-top {
    &.toggle-tooltip {
        max-width: 320px !important;
    }
}
.switch-options {
    float: left;
    margin: 5px 20px 0 0;
    .custom-switch {
        .md-switch {
            margin: 0;
            margin-bottom: 10px;
        }
        .md-checked {
            ::v-deep .md-switch-container {
                background-color: #2b93ff !important;
            }
            ::v-deep .md-switch-thumb {
                border: 1px solid #2b93ff !important;
            }
        }
        ::v-deep .md-switch-label {
            margin-left: -10px;
        }
    }
}
.alert {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}
.reset-btn {
    background-color: red !important;
}
</style>
