<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 id="createJobOfferSettingModalTitle" class="modal-title">Create Job Offer Setting</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div v-if="jobOfferSettingModel" class="modal-body">
            <form-wrapper :validator="$v.jobOfferSettingModel" class="form-wrapper">
                <form-group name="teamRegion" label="Team Region">
                    <team-region-options
                        :selected="jobOfferSettingModel.teamRegionId"
                        @selectedOption="handleTeamRegionOptionsChanged"
                        :show-auto="false"
                        mode="create"
                        :include-sub-team-regions="false"
                    />
                </form-group>
                <form-group name="jobOfferSetting.offerAllocationMethod" label="Offer Allocation Method">
                    <md-select v-model="jobOfferSettingModel.offerAllocationMethod">
                        <md-option
                            v-for="item in jobOfferAllocationMethodOptions"
                            :key="item.value"
                            :value="item.value"
                        >
                            {{ item.text }}
                        </md-option>
                    </md-select>
                </form-group>
                <form-group v-show="isAutoAllocation" name="maxRetry" label="Max Retry">
                    <md-input v-model.number="jobOfferSettingModel.maxRetry" type="number" />
                </form-group>
                <form-group v-show="isAutoAllocation" name="retryDelay" label="Retry Delay">
                    <TimeSpanInput
                        v-model="jobOfferSettingModel.retryDelay"
                        :value="jobOfferSettingModel.retryDelay"
                        @changed="handleRetryDelayChanged"
                    />
                </form-group>
                <form-group name="jobOfferSetting.matchSkills" class="no-underline">
                    <md-checkbox v-model="jobOfferSettingModel.matchSkills">Match Skills</md-checkbox>
                </form-group>
                <form-group name="jobOfferSetting.checkLoadCapacity" class="no-underline">
                    <md-checkbox v-model="jobOfferSettingModel.checkLoadCapacity">Check Load Capacity</md-checkbox>
                </form-group>
                <form-group name="jobOfferSetting.prioritizeEmptyVehicles" class="no-underline">
                    <md-checkbox v-model="jobOfferSettingModel.prioritizeEmptyVehicles">
                        Prioritize Empty Vehicles
                    </md-checkbox>
                </form-group>
                <form-group v-show="false" name="jobOfferSetting.autoAllocate" class="no-underline">
                    <md-checkbox v-model="jobOfferSettingModel.autoAllocate">Auto Allocate</md-checkbox>
                </form-group>
                <form-group v-show="isAutoAllocation" name="jobOfferSetting.failFast" class="no-underline">
                    <md-checkbox v-model="jobOfferSettingModel.failFast">Fail Fast</md-checkbox>
                </form-group>
                <form-group
                    v-show="isAutoAllocation"
                    name="jobOfferSetting.alertLeadTime"
                    label="Notification Lead Time before the scheduled timeframe"
                >
                    <TimeSpanInput
                        v-model="jobOfferSettingModel.alertLeadTime"
                        :value="jobOfferSettingModel.alertLeadTime"
                        @changed="handleAlertLeadTimeChanged"
                    />
                </form-group>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button id="btnUpdate" class="dialog-button md-primary" @click.prevent="create">Create</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import TimeSpanInput from '@/components/TimeSpanInput';
import { TeamRegionOptions } from '@/components';
import { JOB_OFFER_SENDING_MODE_OPTIONS, JOB_OFFER_ALLOCATION_METHOD_OPTIONS } from '@/utils/constants';
import { handleRequests, showErrorMessage } from '@/helpers';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'CreateJobOfferSettingModal',
    components: { TeamRegionOptions, TimeSpanInput },
    mixins: [GeneralMixin],
    props: {
        offerSetting: { type: Object, default: () => null },
        resolve: { type: Function, default: () => {} }
    },
    data() {
        return {
            jobOfferSettingModel: {},
            jobOfferSendingModeOptions: JOB_OFFER_SENDING_MODE_OPTIONS,
            jobOfferAllocationMethodOptions: JOB_OFFER_ALLOCATION_METHOD_OPTIONS
        };
    },
    computed: {
        isAutoAllocation() {
            return this.jobOfferSettingModel && this.jobOfferSettingModel.offerAllocationMethod === 'Auto';
        }
    },
    validations() {
        return {
            jobOfferSettingModel: {
                maxRetry: { required, minValue: minValue(1), maxValue: maxValue(99) },
                retryDelay: { required }
            }
        };
    },

    methods: {
        async create() {
            this.$v.jobOfferSettingModel.$touch();

            if (this.$v.jobOfferSettingModel.$invalid) 
                return;

            const dataModel = {
                ...this.jobOfferSettingModel,
                teamRegionId:
                    this.jobOfferSettingModel.teamRegionId === -1 ? null : this.jobOfferSettingModel.teamRegionId
            };

            this.$_handleLoaderState(true, 'CREATING...');

            try {
                const api = `/api/teams/settings/job-offers`;
                const payload = {
                    method: 'post',
                    data: dataModel
                };

                const { data } = await handleRequests(api, payload);

                this.$notify({
                    message: 'Successfully created a new setting.',
                    type: 'success'
                });

                this.resolve({ result: 'ok', data });
            } catch (e) {
                const message = 'Could not create a new setting.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        handleTeamRegionOptionsChanged(value) {
            this.jobOfferSettingModel = {
                ...this.jobOfferSettingModel,
                teamRegionId: value
            };
        },
        handleRetryDelayChanged(args) {
            this.jobOfferSettingModel = {
                ...this.jobOfferSettingModel,
                retryDelay: args.formatted
            };
        },
        handleAlertLeadTimeChanged(args) {
            this.jobOfferSettingModel = {
                ...this.jobOfferSettingModel,
                alertLeadTime: args.formatted
            };
        }
    },
    mounted() {
        this.jobOfferSettingModel = {
            ...this.offerSetting,
            teamRegionId: this.offerSetting.teamRegionId !== null ? this.offerSetting.teamRegionId : -1 // NOT SET
        };
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-checkbox-container {
    top: 10px;
}

::v-deep .md-checkbox-label {
    opacity: 1 !important;
    color: black !important;
}

::v-deep div.md-field.no-underline:after {
    height: 0px;
}

::v-deep .md-checkbox {
    margin-top: 0px;
}
</style>
