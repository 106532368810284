var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          {
            staticClass: "form-wrapper",
            attrs: { validator: _vm.$v.changeStatus },
          },
          [
            _c(
              "form-group",
              { attrs: { name: "role", label: "Change status" } },
              [
                _c(
                  "md-select",
                  {
                    attrs: { placeholder: "Status" },
                    model: {
                      value: _vm.form.newStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "newStatus", $$v)
                      },
                      expression: "form.newStatus",
                    },
                  },
                  _vm._l(_vm.statuses, function (item) {
                    return _c(
                      "md-option",
                      { key: item, attrs: { label: item, value: item } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            on: { click: _vm.changeStatus },
          },
          [_vm._v("\n            Save\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Change Status")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }