var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cf-container" }, [
    _c(
      "div",
      { staticClass: "cf-action-container" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary md-just-icon md-round",
            attrs: { title: "Create Rate Group" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.createTaxSettings($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("add")])],
          1
        ),
      ],
      1
    ),
    !_vm.isListLoading
      ? _c(
          "div",
          [
            _c(
              "md-table",
              { staticClass: "custom-paginated-table" },
              [
                _c(
                  "md-table-row",
                  [
                    _c("md-table-head", [_vm._v("Type")]),
                    _c("md-table-head", [_vm._v("Tax Type")]),
                    _c("md-table-head", [_vm._v("Description")]),
                    _c("md-table-head", [_vm._v("Rate")]),
                    _c("md-table-head", { staticClass: "actions" }, [
                      _vm._v("Actions"),
                    ]),
                  ],
                  1
                ),
                _vm._l(_vm.taxSettingsList, function (taxSetting) {
                  return _c(
                    "md-table-row",
                    { key: taxSetting.taxSettingsId },
                    [
                      _c("md-table-cell", [_vm._v(_vm._s(taxSetting.type))]),
                      _c("md-table-cell", [
                        _vm._v(_vm._s(taxSetting.accountTaxType)),
                      ]),
                      _c("md-table-cell", [
                        _vm._v(_vm._s(taxSetting.description)),
                      ]),
                      _c("md-table-cell", [
                        _vm._v(_vm._s(_vm.showPercentage(taxSetting.rate))),
                      ]),
                      _c(
                        "md-table-cell",
                        { staticClass: "action-buttons" },
                        [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-warning md-just-icon md-round",
                              attrs: { title: "Update tax settings" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.updateTaxSettings(
                                    taxSetting.taxSettingsId
                                  )
                                },
                              },
                            },
                            [_c("md-icon", [_vm._v("edit")])],
                            1
                          ),
                          _c(
                            "md-button",
                            {
                              staticClass: "md-danger md-just-icon md-round",
                              attrs: { title: "Delete tax settings" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleDeleteTaxSettings(
                                    taxSetting.taxSettingsId
                                  )
                                },
                              },
                            },
                            [_c("md-icon", [_vm._v("delete")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
            _vm.taxSettingsList.length == 0
              ? _c("div", { staticClass: "text-center" }, [
                  _c("p", { staticClass: "no-result-message" }, [
                    _vm._v(
                      "No results matching your search/filter could be found."
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      : _c("div", [
          _c(
            "div",
            { staticClass: "stopbar-loader" },
            [
              _c("fade-loader", { attrs: { loading: true, color: "#333333" } }),
              _c("span", [_vm._v("LOADING")]),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }