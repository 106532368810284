// eslint-disable-next-line import/extensions
import { store } from '@/store/store';

export const FeatureManager = {
    bind(el, binding) {
        const user = store.getters['user/user'];
        const enabledFeatures = user.features?.features;

        const valuesToFilterOut = [undefined, null];

        let featureNames = Array.isArray(binding.value) ? binding.value : [binding.value];
        featureNames = featureNames.filter((feature) => !valuesToFilterOut.includes(feature));

        if (featureNames.length <= 0) 
            return;

        if (!enabledFeatures || enabledFeatures === undefined) {
            el.style.display = 'none';
            return;
        }

        if (!enabledFeatures.some((feature) => featureNames.includes(feature))) {
            el.style.display = 'none';
        }
    },
    methods: {
        $_hasFeature(feature) {
            if (!feature) 
                return false;

            const user = store.getters['user/user'];
            const enabledFeatures = user.features?.features;

            if (enabledFeatures?.length > 0) {
                return enabledFeatures.includes(feature);
            }

            return false;
        },
        $_hasAnyFeature(features) {
            if (!features || !features.length || !Array.isArray(features)) 
                return false;

            const user = store.getters['user/user'];
            const enabledFeatures = user.features?.features;

            if (enabledFeatures?.length > 0) {
                for (let i = 0; i < features.length; i++) {
                    if (enabledFeatures.includes(features[i])) {
                        return true;
                    }
                }
            }

            return false;
        },
        $_hasAllFeatures(features) {
            if (!features || !features.length || !Array.isArray(features)) 
                return false;

            const user = store.getters['user/user'];
            const enabledFeatures = user.features?.features;

            if (enabledFeatures?.length > 0) {
                for (let i = 0; i < features.length; i++) {
                    if (!enabledFeatures.includes(features[i])) {
                        return false;
                    }
                }
            }

            return true;
        }
    }
};
