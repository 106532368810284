<template>
    <div class="field-container">
        <form-wrapper :validator="$v.jobOfferSettingModel" class="form-wrapper">
            <div class="md-layout">
                <div class="md-layout-item">
                    <form-group name="jobOfferSetting.offerAllocationMethod" label="Offer Allocation Method">
                        <md-select v-model="jobOfferSettingModel.offerAllocationMethod">
                            <md-option
                                v-for="item in jobOfferAllocationMethodOptions"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.text }}
                            </md-option>
                        </md-select>
                    </form-group>
                </div>
            </div>

            <div v-if="isAutoAllocation" class="md-layout">
                <div class="md-layout-item">
                    <form-group name="maxRetry" label="Max Retry">
                        <md-input v-model.number="jobOfferSettingModel.maxRetry" type="number" />
                    </form-group>
                </div>
                <div class="md-layout-item">
                    <form-group name="retryDelay" label="Retry Delay">
                        <TimeSpanInput
                            v-model="jobOfferSettingModel.retryDelay"
                            :value="jobOfferSettingModel.retryDelay"
                            @changed="handleRetryDelayChanged"
                        />
                    </form-group>
                </div>
            </div>

            <div class="md-layout">
                <div class="md-layout-item">
                    <form-group name="jobOfferSetting.matchSkills" class="no-underline">
                        <md-checkbox v-model="jobOfferSettingModel.matchSkills">Match Skills</md-checkbox>
                    </form-group>
                </div>
                <div class="md-layout-item">
                    <form-group name="jobOfferSetting.checkLoadCapacity" class="no-underline">
                        <md-checkbox v-model="jobOfferSettingModel.checkLoadCapacity">Check Load Capacity</md-checkbox>
                    </form-group>
                </div>
            </div>

            <div class="md-layout">
                <div class="md-layout-item">
                    <form-group name="jobOfferSetting.prioritizeEmptyVehicles" class="no-underline">
                        <md-checkbox v-model="jobOfferSettingModel.prioritizeEmptyVehicles">
                            Prioritize Empty Vehicles
                        </md-checkbox>
                    </form-group>
                </div>
                <div class="md-layout-item"></div>
            </div>

            <div v-if="isAutoAllocation" class="md-layout">
                <div class="md-layout-item">
                    <form-group v-show="false" name="jobOfferSetting.autoAllocate" class="no-underline">
                        <md-checkbox v-model="jobOfferSettingModel.autoAllocate">Auto Allocate</md-checkbox>
                    </form-group>
                </div>
                <div class="md-layout-item">
                    <form-group v-show="false" name="jobOfferSetting.failFast" class="no-underline">
                        <md-checkbox v-model="jobOfferSettingModel.failFast">Fail Fast</md-checkbox>
                    </form-group>
                </div>
            </div>

            <div v-if="isAutoAllocation" class="md-layout">
                <div class="md-layout-item">
                    <form-group
                        name="jobOfferSetting.alertLeadTime"
                        label="Notification Lead Time before the scheduled timeframe"
                    >
                        <TimeSpanInput
                            v-model="jobOfferSettingModel.alertLeadTime"
                            :value="jobOfferSettingModel.alertLeadTime"
                            @changed="handleAlertLeadTimeChanged"
                        />
                    </form-group>
                </div>
            </div>
        </form-wrapper>
        <md-card-actions>
            <md-button id="btnUpdate" class="header-button md-primary" @click.prevent="update">Update</md-button>
            <md-button v-if="teamRegionSchemeSupported" class="md-danger header-button" @click.stop="remove">
                Remove
            </md-button>
        </md-card-actions>
    </div>
</template>

<script>
import TimeSpanInput from '@/components/TimeSpanInput';
import { JOB_OFFER_SENDING_MODE_OPTIONS, JOB_OFFER_ALLOCATION_METHOD_OPTIONS } from '@/utils/constants';
import { handleRequests, showErrorMessage } from '@/helpers';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'UpdateJobOfferSetting',
    components: { TimeSpanInput },
    mixins: [GeneralMixin],
    props: {
        offerSetting: { type: Object, default: () => null },
        isUpdate: { type: Boolean, default: false }
    },
    data() {
        return {
            jobOfferSettingModel: {},
            jobOfferSendingModeOptions: JOB_OFFER_SENDING_MODE_OPTIONS,
            jobOfferAllocationMethodOptions: JOB_OFFER_ALLOCATION_METHOD_OPTIONS,
            teamRegionSchemeSupported: false // we'll remove this when the TeamRegion Routing Model Scheme is supported
        };
    },
    computed: {
        isAutoAllocation() {
            return this.jobOfferSettingModel && this.jobOfferSettingModel.offerAllocationMethod === 'Auto';
        }
    },
    validations() {
        return {
            jobOfferSettingModel: {
                maxRetry: { required, minValue: minValue(1), maxValue: maxValue(99) },
                retryDelay: { required }
            }
        };
    },

    methods: {
        async update() {
            this.$v.jobOfferSettingModel.$touch();

            if (this.$v.jobOfferSettingModel.$invalid) 
                return;

            const dataModel = {
                ...this.jobOfferSettingModel,
                teamRegionId:
                    this.jobOfferSettingModel.teamRegionId === -1 ? null : this.jobOfferSettingModel.teamRegionId
            };

            this.$_handleLoaderState(true, 'UPDATING...');

            try {
                const api = `/api/teams/settings/job-offers/${dataModel.teamRegionId}`;
                const payload = {
                    method: 'put',
                    data: dataModel
                };

                await handleRequests(api, payload);

                this.$notify({
                    message: 'Successfully updated a setting.',
                    type: 'success'
                });
            } catch (e) {
                const message = 'Could not update setting.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async remove() {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Job Order Setting',
                    body:
                        'Are you sure you want to delete this setting? All bands associated with it will also be deleted.',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        await this.confirmRemove();
                    }
                });
        },
        async confirmRemove() {
            this.$_handleLoaderState(true, 'DELETING...');

            try {
                const teamRegionId =
                    this.jobOfferSettingModel.teamRegionId === -1 ? null : this.jobOfferSettingModel.teamRegionId;
                const api = `/api/teams/settings/job-offers/${teamRegionId}`;
                const payload = {
                    method: 'delete'
                };

                await handleRequests(api, payload);

                this.$notify({
                    message: 'Setting deleted.',
                    type: 'success'
                });

                this.$router.push({ name: 'Job Offer Settings' });
            } catch (e) {
                const message = 'Could not remove setting.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },

        handleRetryDelayChanged(args) {
            this.jobOfferSettingModel = {
                ...this.jobOfferSettingModel,
                retryDelay: args.formatted
            };
        },
        handleAlertLeadTimeChanged(args) {
            this.jobOfferSettingModel = {
                ...this.jobOfferSettingModel,
                alertLeadTime: args.formatted
            };
        }
    },
    mounted() {
        this.jobOfferSettingModel = {
            ...this.offerSetting,
            teamRegionId: this.offerSetting.teamRegionId !== null ? this.offerSetting.teamRegionId : -1 // NOT SET
        };
    }
};
</script>
