<template>
    <li
        :class="[{ 'timeline-inverted': inverted }, { 'timeline-simple': !inverted }, { clickable: isClickable }]"
        @click="() => $emit('clicked')"
    >
        <slot name="badge">
            <div class="timeline-badge" :class="[badgeType, isImage ? 'timeline-image-badge' : '']">
                <md-icon v-if="!isImage">{{ badgeIcon }}</md-icon>
                <img v-if="!imageSrc && isImage" :src="$root.defaultPhotoUrl" alt="" class="timeline-image" />
                <img v-else :src="imageSrc" @error="$_setDefaultBrokenImage" alt="" class="timeline-image" />
            </div>
        </slot>
        <div class="timeline-panel">
            <div class="timeline-heading">
                <slot name="header" />
                <slot name="headerTime" />
            </div>
            <div v-if="$slots.content" class="timeline-body">
                <slot name="content" />
            </div>
            <h6 v-if="$slots.footer">
                <slot name="footer" />
            </h6>
        </div>
    </li>
</template>
<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    mixins: [GeneralMixin],
    name: 'TimeLineItem',
    props: {
        inverted: Boolean,
        badgeType: {
            type: String,
            default: 'success'
        },
        isClickable: {
            type: Boolean,
            default: false
        },
        badgeIcon: {
            type: String,
            default: ''
        },
        isImage: {
            type: Boolean,
            default: false
        },
        imageSrc: {
            type: String,
            default: null
        }
    }
};
</script>
<style scoped lang="scss">
.clickable {
    cursor: pointer;
}
.timeline-image {
    border-radius: 50%;
}

.timeline-image-badge {
    background-color: transparent !important;
}
</style>
