var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "map-layout" }, [
    _c("div", { staticClass: "map-container" }, [
      _c("div", { ref: "mapContent", staticClass: "map-content" }, [
        _vm.mapReady ? _c("div", [_vm._t("default")], 2) : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }