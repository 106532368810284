<template>
    <span v-if="driverName && driverName.length">
        <!-- Show the driver's online status -->
        <svg
            class="online-status"
            :class="[isOnline ? 'online' : 'offline', iconSize == 'small' ? 'small-icon' : 'big-icon']"
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="5" cy="7" r="3" stroke-width="1" />
        </svg>
        <md-tooltip class="toggle-tooltip" md-direction="top">
            {{ driverName }} is {{ isOnline ? 'online' : 'offline' }}
        </md-tooltip>
    </span>
</template>

<script>
export default {
    name: 'DriverOnlineStatus',
    props: {
        driverName: { type: String, required: true },
        isOnline: { type: Boolean, required: true },
        iconSize: { type: String, default: () => 'small' }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';
.online-status {
    &.online {
        circle {
            stroke: #42972b;
            fill: #32cd34;
        }
    }

    &.offline {
        circle {
            stroke: #972b2b;
            fill: #cd3232;
        }
    }

    &.small-icon {
        width: 18px;
        height: 18px;
    }

    &.big-icon {
        width: 32px;
        height: 32px;
    }
}
</style>
