var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    { staticClass: "summary-card" },
    [
      _c(
        "md-card-header",
        {
          staticClass: "summary-card--header",
          attrs: { id: "summary-card--header" },
        },
        [
          _vm._t("header"),
          _c("md-card-header-text", [_vm._t("header-text")], 2),
        ],
        2
      ),
      _c(
        "md-card-content",
        { staticClass: "summary-card--content" },
        [_vm._t("content")],
        2
      ),
      _c("md-card-actions", [_vm._t("actions")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }