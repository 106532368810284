var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isReadOnlyUser
    ? _c(
        "md-button",
        {
          staticClass: "md-danger md-just-icon md-round",
          attrs: { title: "Delete invoice" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleDeleteInvoice()
            },
          },
        },
        [_c("md-icon", [_vm._v("delete")])],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }