var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-data-tooltip-container" }, [
    _c("div", [_c("b", [_vm._v(_vm._s(_vm.headerText))])]),
    _c("div", [
      _c(
        "table",
        _vm._l(_vm.tableRows, function (row, i) {
          return _c("tr", { key: i + "row-tooltip" }, [
            _c("td", [_vm._v(_vm._s(row[0]))]),
            _c("td", [_vm._v(_vm._s(row[1]))]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }