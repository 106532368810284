var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropDown,
          expression: "closeDropDown",
        },
      ],
      class: [
        { open: _vm.isOpen },
        { dropdown: _vm.direction === "down" },
        { dropup: _vm.direction === "up" },
      ],
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleDropDown($event)
        },
      },
    },
    [
      _vm._t("title", [
        _c(
          "a",
          {
            staticClass: "dropdown-toggle",
            attrs: { "data-toggle": "dropdown", href: "javascript:void(0)" },
          },
          [
            _c("i", { class: _vm.icon }),
            _c("p", { staticClass: "notification" }, [
              _vm._v(
                "\n                " + _vm._s(_vm.title) + "\n                "
              ),
              _c("b", { staticClass: "caret" }),
            ]),
          ]
        ),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }