<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Select Member</h4>
        </div>
        <div class="modal-body">
            <vue-select
                label="fullName"
                v-model="selectedTeamMember"
                :options="teamMembers"
                :searchable="$root.isDesktop"
            />
        </div>
        <div class="modal-footer">
            <md-button :disabled="!selectedTeamMember" class="md-primary dialog-button" @click="selectTeamMember">
                Select
            </md-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectMember',
    props: {
        teamMembers: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            selectedTeamMember: ''
        };
    },
    methods: {
        selectTeamMember() {
            this.resolve(this.selectedTeamMember);
        }
    }
};
</script>
