<template>
    <div class="filter-steps--container">
        <team-region-member-filter-options
            class="team-region-container"
            v-if="(canViewTeamRegion && teamRegions && teamRegions.length > 0) || isCustomerAdmin"
            :team-region-id="teamRegionId"
            :team-member-public-userid="teamMemberFilterValue"
            @changed="handleTeamRegionChanged"
            :show-team-member="true"
            :clearable="false"
            :use-in-map="true"
        ></team-region-member-filter-options>

        <md-field class="datepicker-container">
            <md-datepicker
                class="filter-steps--choices filter-step-date"
                v-model="tripDateFilterValue"
                md-immediately
                :md-debounce="100"
                ref="mdDatePicker"
                :clearable="false"
            ></md-datepicker>
        </md-field>

        <md-field>
            <vue-select
                v-if="statuses"
                :options="statuses"
                :clearable="false"
                placeholder="Status"
                v-model="statusFilterValue"
            ></vue-select>
        </md-field>
        <CustomerOptions
            v-if="hasCustomerFilterAccess"
            :selected="customerId"
            :team-region-id="teamRegionId"
            @selectedOption="handleCustomerChanged"
        />
    </div>
</template>
<script>
import moment from 'moment';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import TeamRegionMemberFilterOptions from '@/components/TeamRegionMemberFilterOptions';
import { TRIP_STATUS_CONSTANTS } from '@/utils/constants';
import CustomerOptions from '@/pages/Trips/Components/CustomerOptions';

export default {
    name: 'TripFilterComponent',
    props: {
        tripDate: {
            type: String, // Date "2021-07-30"
            default: ''
        },
        teamRegionId: {
            type: Number,
            default: null
        }
    },
    components: { CustomerOptions, TeamRegionMemberFilterOptions },
    data() {
        return {
            tripDateFilterValue: '',
            selectedTeamRegionId: null,
            statusFilterValue: 'All Statuses',
            statuses: ['All Statuses', ...TRIP_STATUS_CONSTANTS],
            teamMemberFilterValue: '',
            referenceNumberValue: '',
            customerId: null
        };
    },
    computed: {
        ...mapGetters({
            teamRegions: 'team/teamRegions',
            user: 'user/user',
            canEditOwnTeamRegion: 'user/canEditOwnTeamRegion',
            hasElevatedTeamRegionFilter: 'user/hasElevatedTeamRegionFilter',
            canViewTeamRegion: 'user/canViewTeamRegion',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isReadOnlyUser: 'user/isReadOnlyUser',
            hasCustomerFilterAccess: 'user/hasInventoryListAccess',
            isCustomerAdmin: 'user/isCustomerAdmin'
        }),
        teamRegionsOptions() {
            if (this.teamRegions) {
                return [
                    {
                        name: 'All',
                        teamRegionId: null
                    },
                    ...this.teamRegions
                ];
            }
            return [];
        }
    },
    watch: {
        tripDate: {
            immediate: true,
            handler(newVal, oldVal) {
                this.tripDateFilterValue = newVal;
            }
        },
        tripDateFilterValue(newValue, oldValue) {
            this.handleFilter();
        },
        teamRegionFilterValue() {
            this.handleFilter();
        },
        statusFilterValue() {
            this.handleFilter();
        }
    },
    methods: {
        // eslint-disable-next-line func-names
        handleFilter: _.debounce(function() {
            this.handleFilteredValue();
        }, 700),
        clearFilters() {
            this.tripDateFilterValue = '';
            this.teamRegionFilterValue = '';
            this.statusFilterValue = 'All Statuses';
            this.teamMemberFilterValue = '';
            this.referenceNumberValue = '';
        },
        handleFilteredValue() {
            // use nextTick to ensure that tripDate value has already been changed.
            // this is because the @selected method of md-datepicker is asynchronous.
            this.$nextTick(() => {
                const { tripDateFilterValue } = this;
                let tripDate = '';
                if (tripDateFilterValue) {
                    tripDate = moment(tripDateFilterValue).format('YYYY-MM-DD');
                }

                this.$emit('onFilterTrips', {
                    tripDate,
                    teamRegionId: this.selectedTeamRegionId,
                    elevatedTeamRegionFilter: this.hasElevatedTeamRegionFilter,
                    statusFilter: this.statusFilterValue,
                    teamMemberPublicUserId: this.teamMemberFilterValue,
                    referenceNumber: this.referenceNumberValue,
                    customerId: this.customerId
                });
            });
        },
        handleTeamRegionChanged(args) {
            this.teamMemberFilterValue = args.publicUserId;
            this.selectedTeamRegionId = args.teamRegionId;
            this.handleFilter();
        },
        handleCustomerChanged(customerId, customerModel) {
            this.customerId = customerId;
            this.handleFilter();
        }
    },
    mounted() {
        if (!this.$root.isDesktop) {
            if (this.$refs.mdDatePicker) 
                this.$refs.mdDatePicker.$refs.input.$el.setAttribute('readonly', true);
        }

        // set to current user's team region
        if (this.user.teamRegionId && this.teamRegions) {
            const userTeamRegion = this.teamRegions.find((x) => x.teamRegionId === this.user.teamRegionId);
            this.teamRegionFilterValue = userTeamRegion;
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-steps--container {
    flex-grow: 2;
    ::v-deep .md-field {
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }

    ::v-deep > .md-field {
        display: inline-block;
        width: 180px;
    }

    .filter-step-date {
        margin-right: 0;
        min-height: initial;
        position: absolute;
        z-index: 1;
        width: 140px;
        ::v-deep .md-clear {
            display: none;
        }
        ::v-deep .md-input {
            width: 100%;
        }
    }

    ::v-deep .md-datepicker:after,
    ::v-deep .md-datepicker:before {
        bottom: 0;
        height: 0;
    }

    ::v-deep .md-datepicker:before,
    ::v-deep .md-datepicker:after {
        bottom: 30px;
        content: 'Trip date';
        color: #aaaaaa;
        font-weight: 400;
        font-size: 14px;
        transform: none;
        z-index: 1;
    }

    ::v-deep .md-has-value:before,
    ::v-deep .md-has-value:after {
        bottom: 0;
        height: 0;
        content: '';
    }

    ::v-deep .md-datepicker .md-input-action {
        z-index: 99;
        right: 52px;
    }
}
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}
::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}
::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}

::v-deep .vs__search {
    cursor: pointer;
}

.datepicker-noevent {
    ::v-deep .md-datepicker {
        input {
            pointer-events: none;
        }
    }
}
</style>
