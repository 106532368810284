var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isListLoading
      ? _c(
          "div",
          [
            _c(
              "md-table",
              { staticClass: "custom-paginated-table" },
              [
                _c(
                  "md-table-row",
                  [
                    _c("md-table-head"),
                    _c("md-table-head", [_vm._v("Name")]),
                    _c("md-table-head", [_vm._v("Role")]),
                    _c("md-table-head", [_vm._v("Phone")]),
                    _c("md-table-head", [_vm._v("Tracking")]),
                    _c("md-table-head", [_vm._v("Date Joined")]),
                    _c("md-table-head", [_vm._v("Device")]),
                    _c("md-table-head", [_vm._v("App Version")]),
                    _c("md-table-head", [_vm._v("Status")]),
                    _c("md-table-head", [_vm._v("2FA Enabled")]),
                    _vm.isServiceAreaVisible()
                      ? _c("md-table-head", [_vm._v("Service areas")])
                      : _vm._e(),
                    _vm.isTeamRegionVisible()
                      ? _c("md-table-head", [_vm._v("Team region")])
                      : _vm._e(),
                    _vm.isVehicleCapacityVisible("weight")
                      ? _c("md-table-head", [
                          _vm._v(
                            "\n                    Weight (" +
                              _vm._s(_vm.getVehicleCapacitySymbol("weight")) +
                              ")\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm.isVehicleCapacityVisible("volume")
                      ? _c("md-table-head", [
                          _vm._v(
                            "\n                    Volume (" +
                              _vm._s(_vm.getVehicleCapacitySymbol("volume")) +
                              ")\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm.isVehicleCapacityVisible("quantity")
                      ? _c("md-table-head", [
                          _vm._v(
                            "\n                    Quantity (" +
                              _vm._s(_vm.getVehicleCapacitySymbol("quantity")) +
                              ")\n                "
                          ),
                        ])
                      : _vm._e(),
                    _c("md-table-head", [_vm._v("Actions")]),
                  ],
                  1
                ),
                _vm._l(_vm.memberList, function (item, index) {
                  return _c(
                    "md-table-row",
                    { key: index },
                    [
                      _c(
                        "md-table-cell",
                        [
                          _vm.loggedInUserEmail != item.email
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Team Member Profile",
                                      params: {
                                        publicUserId: item.publicUserId,
                                      },
                                    },
                                  },
                                },
                                [
                                  item.photoUrl
                                    ? _c("img", {
                                        staticClass: "circle-profile-image",
                                        attrs: { src: item.photoUrl, alt: "" },
                                        on: {
                                          error: _vm.$_setDefaultBrokenImage,
                                        },
                                      })
                                    : _c("img", {
                                        staticClass: "circle-profile-image",
                                        attrs: {
                                          src: _vm.$root.defaultPhotoUrl,
                                          alt: "",
                                        },
                                      }),
                                ]
                              )
                            : _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "User Profile",
                                    },
                                  },
                                },
                                [
                                  item.photoUrl
                                    ? _c("img", {
                                        staticClass: "circle-profile-image",
                                        attrs: { src: item.photoUrl, alt: "" },
                                        on: {
                                          error: _vm.$_setDefaultBrokenImage,
                                        },
                                      })
                                    : _c("img", {
                                        staticClass: "circle-profile-image",
                                        attrs: {
                                          src: _vm.$root.defaultPhotoUrl,
                                          alt: "",
                                        },
                                      }),
                                ]
                              ),
                        ],
                        1
                      ),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Name" } },
                        [
                          _vm.loggedInUserEmail != item.email
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "custom-a-blue",
                                  attrs: {
                                    to: {
                                      name: "Team Member Profile",
                                      params: {
                                        publicUserId: item.publicUserId,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(item.fullName) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            : _c(
                                "router-link",
                                {
                                  staticClass: "custom-a-blue",
                                  attrs: {
                                    to: {
                                      name: "User Profile",
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(item.fullName) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Role", "md-sort": "" } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.role) +
                              "\n                "
                          ),
                        ]
                      ),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Phone" } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.phone) +
                              "\n                    "
                          ),
                          item.phone
                            ? _c("send-sms-button", {
                                attrs: {
                                  "public-user-id": item.publicUserId,
                                  name: item.fullName,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Tracking" } },
                        [
                          _c(
                            "div",
                            { staticClass: "custom-tooltip-container" },
                            [
                              _c("tracking-location-data-indicator", {
                                attrs: { data: item.routeDetails },
                              }),
                              _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.gpsDataCollectionRule) +
                                      "\n                            "
                                  ),
                                  item.gpsDataCollectionRule
                                    ? _c(
                                        "md-tooltip",
                                        {
                                          staticClass: "toggle-tooltip",
                                          attrs: { "md-direction": "top" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.getGpsDataCollectionRuleDescription(
                                                  item.gpsDataCollectionRule
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Vehicle" } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  item.dateJoined,
                                  _vm.DATE_TYPES.standardDate
                                )
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                      _c("md-table-cell", { attrs: { "md-label": "Device" } }, [
                        _vm._v(
                          _vm._s(item.deviceType) + " " + _vm._s(item.osVersion)
                        ),
                      ]),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "AppVersion" } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.appVersion) +
                              "\n                "
                          ),
                        ]
                      ),
                      _c("md-table-cell", { attrs: { "md-label": "Status" } }, [
                        item.disabledDate != null
                          ? _c("span", [_vm._v("Disabled")])
                          : _vm._e(),
                      ]),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "2FA" } },
                        [
                          item.twoFactorEnabled
                            ? _c("md-icon", [_vm._v("phonelink_lock")])
                            : _vm._e(),
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.twoFactorEnabled ? "Active" : "") +
                              "\n                "
                          ),
                        ],
                        1
                      ),
                      _vm.isServiceAreaVisible()
                        ? _c(
                            "md-table-cell",
                            { attrs: { "md-label": "Service area" } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.getFormattedServiceAreas(item)) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isTeamRegionVisible()
                        ? _c(
                            "md-table-cell",
                            { attrs: { "md-label": "Team region" } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.teamRegionName) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isVehicleCapacityVisible("weight")
                        ? _c(
                            "md-table-cell",
                            { attrs: { "md-label": "Weight" } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.getVehicleCapacityValue(item, "weight")
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isVehicleCapacityVisible("volume")
                        ? _c(
                            "md-table-cell",
                            { attrs: { "md-label": "Volume" } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.getVehicleCapacityValue(item, "volume")
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isVehicleCapacityVisible("quantity")
                        ? _c(
                            "md-table-cell",
                            { attrs: { "md-label": "Quantity" } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.getVehicleCapacityValue(
                                      item,
                                      "quantity"
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "md-table-cell",
                        {
                          staticClass: "action-buttons",
                          attrs: { "md-label": "Actions" },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "md-button md-primary md-just-icon md-round btn-size-27",
                              attrs: {
                                tag: "md-button",
                                title: "Edit member",
                                to: {
                                  name: "Team Member Profile",
                                  params: { publicUserId: item.publicUserId },
                                },
                              },
                            },
                            [_c("md-icon", [_vm._v("edit")])],
                            1
                          ),
                          item.twoFactorEnabled
                            ? _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-info md-just-icon md-round btn-size-27",
                                  attrs: { title: "Reset two factor setup" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetTwoFactorAuthentication(
                                        item.publicUserId
                                      )
                                    },
                                  },
                                },
                                [_c("md-icon", [_vm._v("lock_reset")])],
                                1
                              )
                            : _vm._e(),
                          item.disabledDate == null &&
                          _vm.loggedInUserEmail != item.email
                            ? _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-warning md-just-icon md-round btn-size-27",
                                  attrs: { title: "Disable Member" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateTeamMemberStatus(
                                        item.publicUserId,
                                        index,
                                        "disable"
                                      )
                                    },
                                  },
                                },
                                [_c("md-icon", [_vm._v("block")])],
                                1
                              )
                            : _vm._e(),
                          item.disabledDate != null &&
                          _vm.loggedInUserEmail != item.email
                            ? _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-success md-just-icon md-round btn-size-27",
                                  attrs: { title: "Enable Member" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateTeamMemberStatus(
                                        item.publicUserId,
                                        index,
                                        "enable"
                                      )
                                    },
                                  },
                                },
                                [_c("md-icon", [_vm._v("how_to_reg")])],
                                1
                              )
                            : _vm._e(),
                          _vm.loggedInUserEmail != item.email
                            ? _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-danger md-just-icon md-round btn-size-27",
                                  attrs: { title: "Remove Member" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemoveMember(
                                        item.publicUserId
                                      )
                                    },
                                  },
                                },
                                [_c("md-icon", [_vm._v("delete")])],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
            _vm.memberList.length == 0 && !_vm.isLoading
              ? _c("div", [
                  _c("p", { staticClass: "no-result-message" }, [
                    _vm._v(
                      "No results matching your search/filter could be found."
                    ),
                  ]),
                ])
              : _vm._e(),
            _c(
              "md-card-actions",
              {
                staticClass: "page-footer",
                attrs: { "md-alignment": "space-between" },
              },
              [
                _c("div", [
                  _vm.total === _vm.pagination.perPage
                    ? _c("p", { staticClass: "card-category" }, [
                        _vm._v(
                          "\n                    Page " +
                            _vm._s(_vm.pagination.currentPage) +
                            " of many\n                "
                        ),
                      ])
                    : _c("p", { staticClass: "card-category" }, [
                        _vm._v(
                          "Page " +
                            _vm._s(_vm.pagination.currentPage) +
                            " of " +
                            _vm._s(_vm.totalPages)
                        ),
                      ]),
                ]),
                _c("pagination", {
                  staticClass: "pagination-no-border pagination-success",
                  attrs: {
                    "per-page": _vm.pagination.perPage,
                    total: _vm.total,
                  },
                  on: {
                    "change-page": function ($event) {
                      return _vm.handleChangePage(
                        $event,
                        _vm.pagination.perPage
                      )
                    },
                  },
                  model: {
                    value: _vm.pagination.currentPage,
                    callback: function ($$v) {
                      _vm.$set(_vm.pagination, "currentPage", $$v)
                    },
                    expression: "pagination.currentPage",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _c("div", [
          _c("div", { staticClass: "body-list" }, [
            _c(
              "div",
              { staticClass: "list-loader" },
              [
                _c("fade-loader", {
                  attrs: { loading: _vm.isListLoading, color: "#333333" },
                }),
                _c("span", [_vm._v("LOADING")]),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }