<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title" v-if="teamMemberName !== 'Unallocated'">{{ teamMemberName }} - Assigned Stops</h4>
            <h4 class="modal-title" v-if="teamMemberName === 'Unallocated'">Unassigned Stops</h4>
        </div>
        <div class="modal-body">
            <md-table class="context-menu-support unassignstop-table">
                <md-table-row>
                    <md-table-head>Reference</md-table-head>
                    <md-table-head>Address</md-table-head>
                    <md-table-head v-if="teamMemberName !== 'Unallocated'">ETA</md-table-head>
                    <md-table-head v-if="hasCapacitySettings('quantity')">
                        <md-icon>
                            <img class="capacity-images" src="/img/icons/capacity-items-gray.png" />
                        </md-icon>
                    </md-table-head>
                    <md-table-head v-if="hasCapacitySettings('volume')">
                        <md-icon>
                            <img class="capacity-images" src="/img/icons/capacity-volume-gray.png" />
                        </md-icon>
                    </md-table-head>
                    <md-table-head v-if="hasCapacitySettings('weight')">
                        <md-icon>
                            <img class="capacity-images" src="/img/icons/capacity-weight-gray.png" />
                        </md-icon>
                    </md-table-head>
                </md-table-row>
                <md-table-row v-for="item in stopList" :key="item.shipId || item.shipmentId">
                    <md-table-cell class="stop-reference">
                        <md-icon v-if="item.routingErrors.length > 0">info</md-icon>
                        <md-tooltip v-if="item.routingErrors.length > 0" md-direction="top">
                            <ul>
                                <li v-for="(reason, index) in item.routingErrors" :key="index">
                                    {{ reason.message }}
                                </li>
                            </ul>
                        </md-tooltip>
                        <router-link
                            v-if="item.stopRef"
                            :to="{
                                name: 'Stop Details',
                                params: { stopId: item.stopId }
                            }"
                            target="_blank"
                        >
                            {{ item.stopRef }}
                        </router-link>
                        <router-link
                            v-else-if="item.shipmentId"
                            :to="{
                                name: 'Shipment Details',
                                params: { shipmentId: item.shipmentId }
                            }"
                            target="_blank"
                        >
                            {{ item.shipmentRef }}
                        </router-link>
                        <span v-else>{{ item.locationType }}</span>
                    </md-table-cell>
                    <md-table-cell class="address-container">
                        <div class="stop-address" v-if="item.stopId">
                            <google-autocomplete
                                :ref="item.stopId"
                                :id="item.stopRef"
                                :stop-id="item.stopId"
                                v-model="item.locationData"
                                classname="form-control autocomplete-input"
                                label=""
                                icon=""
                                placeholder=""
                                @placechanged="onStopLocationChanged"
                            ></google-autocomplete>
                        </div>
                        <div v-else-if="item.shipmentId">
                            <md-icon>
                                local_shipping
                            </md-icon>
                            {{ item.pickupStop.address }}
                            <md-icon>
                                arrow_right_alt
                            </md-icon>
                            {{ item.dropStop.address }}
                        </div>
                    </md-table-cell>
                    <md-table-cell v-if="teamMemberName !== 'Unallocated'" class="address-eta">
                        {{ item.arrivalTime }}
                    </md-table-cell>
                    <md-table-cell v-if="hasCapacitySettings('quantity')" class="capacity-table-cell">
                        {{ getCapacityInfo(item, 'quantity', 'items') }}
                    </md-table-cell>
                    <md-table-cell v-if="hasCapacitySettings('volume')" class="capacity-table-cell">
                        {{ getCapacityInfo(item, 'volume', 'm3') }}
                    </md-table-cell>
                    <md-table-cell v-if="hasCapacitySettings('weight')" class="capacity-table-cell">
                        {{ getCapacityInfo(item, 'weight', 'kg') }}
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
        <div class="modal-footer">
            <md-button @click.stop="$modal.hide" class="btn-close">Close</md-button>
        </div>
    </div>
</template>

<script>
import { GoogleAutocomplete } from '@/components';
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import moment from 'moment';

export default {
    name: 'TripStopListModal',
    mixins: [GeneralMixin],
    components: {
        GoogleAutocomplete
    },
    props: {
        stopList: {
            type: Array,
            default: () => []
        },
        teamMemberName: {
            type: String,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        getCapacityInfo: {
            type: Function,
            default: () => {}
        },
        hasCapacitySettings: {
            type: Function,
            default: () => {}
        }
    },
    methods: {
        onStopLocationChanged(stopAddressData) {
            this.$_handleLoaderState(true, 'UPDATING...');
            const stop = this.stopList.find((stop) => {
                return stop.stopId === stopAddressData.stopId;
            });
            const { address, name, location } = stopAddressData;
            const appointmentTimeWithDate = stop.appointmentTime;
            if (location) {
                stop.appointmentTime = stop.appointmentTime
                    ? moment.parseZone(stop.appointmentTime).format('H:mm')
                    : stop.appointmentTime;

                stop.address = address;
                stop.name = name;
                stop.location = location;
                stop.assignedTo = {
                    publicUserId: null
                };
                const api = `/api/stops/${stop.stopId}`;
                const payload = {
                    method: 'put',
                    data: stop
                };
                handleRequests(api, payload)
                    .then((response) => {
                        this.$_handleLoaderState(false);
                        stop.appointmentTime = appointmentTimeWithDate;
                        this.resolve();
                    })
                    .catch(() => {
                        this.$_handleLoaderState(false);
                        this.$notify({
                            message: 'Could not update stop address.',
                            type: 'danger'
                        });
                    });
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.modal-container {
    max-width: 800px;
}
.unassignstop-table {
    padding-left: 0;
    line-height: 18px;
    list-style-type: none;
    max-height: 350px;
    overflow-y: scroll;
}
td.md-table-cell.stop-reference,
td.md-table-cell.address-container,
td.md-table-cell.address-eta {
    padding: 5px 8px;
    height: 37px;
}

div.md-table-head-container,
div.md-table-head-label {
    height: 30px;
}

.stop-reference a {
    color: #2b93ff !important;
}
.address-container .md-table-cell-container {
    white-space: nowrap;
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
}
button.md-button.btn-close {
    background: #999 !important;
}
.stop-address {
    ::v-deep input {
        height: 18px;
        color: red;
        font-weight: 300;
        width: 100%;
        background-image: none;
    }
    .md-field:after {
        position: relative;
    }
    .md-field {
        padding: 0;
        margin: 0;
    }

    .md-field:before {
        display: none;
    }
}
.stop-reference {
    width: 30%;
}
.capacity-table-cell {
    width: 70px;
}
.capacity-images {
    border-radius: 50%;
    transform: scale(1.2);
    margin-left: 7px;
}
</style>
