<template>
    <div>
        <div class="dayTime-container">
            <span class="custom-label">Days</span>
            <md-input id="day-input" v-model.number="dayField" type="number" class="dayTime-element" />
        </div>
        <div class="dayTime-container">
            <span class="custom-label">Hours</span>
            <md-input id="hour-input" v-model.number="hourField" type="number" class="dayTime-element" />
        </div>
        <div class="dayTime-container">
            <span class="custom-label">Minutes</span>
            <md-input id="minute-input" v-model.number="minuteField" type="number" class="dayTime-element" />
        </div>
        <div class="dayTime-container">
            <span class="custom-label">Seconds</span>
            <md-input id="second-input" v-model.number="secondField" type="number" class="dayTime-element" />
        </div>
    </div>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators';

export default {
    name: 'TimeSpanInput',
    props: {
        value: { type: String, default: () => null }
    },
    data() {
        return {
            dayField: 0,
            hourField: 0,
            minuteField: 0,
            secondField: 0
        };
    },
    validations() {
        return {
            dayField: {
                required,
                minValue: minValue(0),
                maxValue: maxValue(30)
            },
            hourField: {
                required,
                minValue: minValue(0),
                maxValue: maxValue(23)
            },
            minuteField: {
                required,
                minValue: minValue(0),
                maxValue: maxValue(59)
            },
            secondField: {
                required,
                minValue: minValue(0),
                maxValue: maxValue(59)
            }
        };
    },
    watch: {
        value(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.parseValue(newVal);
            }
        },
        dayField(newVal, oldVal) {
            if (newVal !== oldVal) 
                this.handleChange();
        },
        hourField(newVal, oldVal) {
            if (newVal !== oldVal) 
                this.handleChange();
        },
        minuteField(newVal, oldVal) {
            if (newVal !== oldVal) 
                this.handleChange();
        },
        secondField(newVal, oldVal) {
            if (newVal !== oldVal) 
                this.handleChange();
        }
    },
    methods: {
        padNumber(num) {
            return num < 10 ? `0${num}` : `${num}`;
        },
        // eslint-disable-next-line func-names
        handleChange: _.debounce(function() {
            this.raiseEvent();
        }, 100),
        raiseEvent() {
            const args = {
                day: this.dayField,
                hour: this.hourField,
                minute: this.minuteField,
                second: this.secondField,
                totalSeconds:
                    this.dayField * 24 * 60 * 60 + this.hourField * 60 * 60 + this.minuteField * 60 + this.secondField,
                formatted: `${this.dayField}.${this.padNumber(this.hourField)}:${this.padNumber(
                    this.minuteField
                )}:${this.padNumber(this.secondField)}`
            };

            this.$emit('changed', args);
        },
        parseValue(value) {
            // value format:
            // could be '1.02:03:04' (that has a day in it)
            // or just '02:03:04'
            if (!value) {
                this.dayField = 0;
                this.hourField = 0;
                this.minuteField = 0;
                this.secondField = 0;
                return;
            }

            let d = 0;
            let h = 0;
            let m = 0;
            let s = 0;

            const parts = value.split(':');
            if (parts.length === 3) {
                s = Number(parts[2]);
                m = Number(parts[1]);

                // try splitting days and hours
                const dayHourParts = parts[0].split('.');
                if (dayHourParts.length === 2) {
                    h = Number(dayHourParts[1]);
                    d = Number(dayHourParts[0]);
                } else if (dayHourParts.length === 1) {
                    // only hour, no day
                    h = Number(dayHourParts[0]);
                }
            }

            this.dayField = d;
            this.hourField = h;
            this.minuteField = m;
            this.secondField = s;
        }
    },
    mounted() {
        this.parseValue(this.value);
    }
};
</script>

<style scoped lang="scss">
.dayTime-container {
    display: inline-block;
    border-bottom: 1px solid #ddd;
    margin-right: 10px;
    width: calc((100% - 30px) / 4);

    .dayTime-element {
        width: calc(100% - 2px);
    }
}

.dayTime-container:last-child {
    margin-right: 0;
}
</style>
