var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("g", { attrs: { id: "Map-pin" } }, [
            _c("g", { attrs: { id: "map-pin", fill: "#007ABD" } }, [
              _c("circle", {
                attrs: { id: "Oval", cx: "15", cy: "15", r: "15" },
              }),
              _c("path", {
                attrs: {
                  d: "M0,15.5848107 L30,15.5848107 C30,20.2841756 28.5330904,24.5790498 25.5992713,28.4694335 C22.6654522,32.3598171 19.1323618,35.946929 15,39.2307692 C11.168119,35.9923428 7.71014874,32.4052309 4.62608924,28.4694335 C1.54202975,24.533636 2.27373675e-12,20.2387618 0,15.5848107 Z",
                  id: "Rectangle",
                },
              }),
              _c("circle", {
                attrs: {
                  id: "Oval",
                  cx: "15.2884615",
                  cy: "15",
                  r: "5.76923077",
                },
              }),
            ]),
            _c(
              "text",
              {
                attrs: {
                  id: "1",
                  "font-family": "Roboto-Bold, Roboto",
                  "font-size": "16",
                  "font-weight": "bold",
                  fill: "#FFFFFF",
                },
              },
              [
                _c(
                  "tspan",
                  {
                    attrs: {
                      x: "50%",
                      y: "45%",
                      "alignment-baseline": "middle",
                      "text-anchor": "middle",
                    },
                  },
                  [_vm._v(_vm._s(_vm.iconName))]
                ),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }