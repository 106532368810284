<template>
    <drop-down
        :should-close="shouldCloseDropdown"
        @closeDropdown="(val) => (this.shouldCloseDropdown = val)"
        @closeOtherDropDown="closeOtherDropDown"
    >
        <span
            class="status"
            slot="title"
            data-toggle="dropdown"
            :class="
                `status-${order.paymentStatus.toLowerCase().replace(/\s+/g, '')} ${className} ${
                    statuses.length ? '' : 'no-pointer'
                }`
            "
        >
            {{ order.paymentStatus }}
        </span>
        <ul
            class="dropdown-menu"
            v-if="(!isReadOnlyUser && statuses.length) || (isCustomerAdmin && order.status === 'Pending')"
        >
            <li v-for="(status, index) in statuses" :key="index">
                <a @click.stop="handleChangeOrderPaymentStatus(status)">
                    {{ status }}
                </a>
            </li>
        </ul>
    </drop-down>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { ORDER_PAYMENT_STATUS_CONSTANTS } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
    name: 'OrderPaymentStatusButton',
    mixins: [GeneralMixin],
    props: {
        order: {
            type: Object,
            default: () => {}
        },
        className: {
            type: String,
            default: () => ''
        }
    },
    data() {
        return {
            statuses: ORDER_PAYMENT_STATUS_CONSTANTS,
            shouldCloseDropdown: false
        };
    },
    mounted() {
        this.updateStatus();
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            isCustomerAdmin: 'user/isCustomerAdmin',
            isIndividualUser: 'user/isIndividualUser'
        })
    },
    methods: {
        updateStatus() {},
        handleChangeOrderPaymentStatus(newStatus) {},
        closeOtherDropDown() {
            const dropdowns = document.querySelectorAll('.open');
            dropdowns.forEach((dropdown) => dropdown.classList.remove('open'));
        }
    }
};
</script>

<style lang="scss" scoped>
.status {
    color: #fff !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 90px;
    cursor: pointer;
    background-color: #c2907f !important;
}

.status-paid {
    background-color: #0bda8e !important;
}

::v-deep .dropdown-menu {
    li {
        cursor: pointer !important;
    }
}

.no-pointer {
    cursor: not-allowed !important;
}
</style>
