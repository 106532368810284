var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "asset-management-page" }, [
    _vm.isValidType
      ? _c("div", { staticClass: "md-layout" }, [
          _vm.hasTeam
            ? _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
                },
                [
                  _c("div", { staticClass: "custom-toolbar" }, [
                    _c(
                      "div",
                      { staticClass: "custom-toolbar-start" },
                      [
                        _c("asset-tracking-filter", {
                          attrs: { "team-region-id": _vm.filters.teamRegionId },
                          on: { onFilterChanged: _vm.handleFilterOrSearch },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "custom-toolbar-end" },
                      [
                        _c("search-component", {
                          ref: "search",
                          on: { onSearch: _vm.handleSearch },
                        }),
                        _c(
                          "div",
                          { staticClass: "header-button-container" },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-primary md-just-icon md-round",
                                attrs: { title: "Create Asset" },
                                on: { click: _vm.createAsset },
                              },
                              [_c("md-icon", [_vm._v("add")])],
                              1
                            ),
                            _c(
                              "md-button",
                              {
                                staticClass: "md-primary md-just-icon md-round",
                                attrs: { title: "Add asset log" },
                                on: { click: _vm.createAssetlog },
                              },
                              [_c("md-icon", [_vm._v("note_add")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
            },
            [
              _c(
                "md-card",
                { staticClass: "custom-card" },
                [
                  _c(
                    "md-card-header",
                    {
                      staticClass: "md-card-header-icon md-card-header-warning",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card-icon" },
                        [_c("md-icon", [_vm._v("work")])],
                        1
                      ),
                    ]
                  ),
                  _c("md-card-content", [
                    !_vm.hasTeam && !_vm.isLoading
                      ? _c(
                          "div",
                          [
                            _c("h3", [_vm._v("Asset Group")]),
                            _c("p", [
                              _vm._v(
                                "\n                            You are currently set up as an individual user of Locate2u. Asset Management is only\n                            available if you are part of a team.\n                        "
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "\n                            If you want to create your own team, click here to set one up. This will allow you to\n                            invite people to your team and manage them via this portal.\n                        "
                              ),
                            ]),
                            _c(
                              "router-link",
                              {
                                staticClass: "custom-a-blue",
                                attrs: { to: "/team/profile" },
                              },
                              [_vm._v("Create Team")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.hasTeam
                      ? _c(
                          "div",
                          [
                            _c(
                              "tabs",
                              {
                                staticClass: "custom-tab-list",
                                attrs: {
                                  "tab-name": _vm.tabNames,
                                  "color-button": "success",
                                  "selected-panel":
                                    _vm.replaceDashToSpacesAndCapitalizeFirstLetter(
                                      _vm.activeTab
                                    ),
                                },
                                on: { "switch-panel": _vm.switchTab },
                              },
                              [
                                _vm._l(_vm.tabNames, function (tabName, index) {
                                  return _c(
                                    "template",
                                    { slot: "tab-pane-" + (index + 1) },
                                    [
                                      _c(
                                        "div",
                                        { key: index },
                                        [
                                          index == 0
                                            ? _c("asset-group-list-table", {
                                                attrs: {
                                                  "new-asset-group-id":
                                                    _vm.newAssetGroupId,
                                                  "search-text": _vm.searchText,
                                                  filters: _vm.filters,
                                                },
                                                on: {
                                                  removeAssetFromGroup:
                                                    _vm.removeAssetFromGroup,
                                                },
                                              })
                                            : _c("asset-list-table", {
                                                attrs: {
                                                  "search-text": _vm.searchText,
                                                  type: _vm.activeTab,
                                                  "new-asset-id":
                                                    _vm.newAssetId,
                                                  "custom-fields":
                                                    _vm.assetCustomFieldList,
                                                  filters: _vm.filters,
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _c("div", [
          _c("p", { staticClass: "text-center" }, [_vm._v("Type Not Valid")]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }