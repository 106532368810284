<template>
    <div>
        <div class="detail-container">
            <div class="close-button">
                <md-button class="md-default md-just-icon md-round pull-right" @click="closeModal">
                    <md-icon>close</md-icon>
                </md-button>
            </div>
            <div v-if="!loading">
                <div class="detail-section text-center">
                    <span class="title blue-ref">
                        <router-link
                            :to="{
                                name: 'Asset Group Details',
                                params: { assetGroupId: assetGroupDetails.assetGroupId }
                            }"
                            target="_blank"
                        >
                            {{ assetGroupDetails.groupName }}
                        </router-link>
                    </span>
                    <div class="detail-group text-center action-detail-group">
                        <router-link
                            title="View asset details"
                            class="md-button md-success md-just-icon md-round"
                            :to="{
                                name: 'Asset Group Details',
                                params: { assetGroupId: assetGroupDetails.assetGroupId }
                            }"
                            target="_blank"
                        >
                            <div class="md-ripple">
                                <div class="md-button-content">
                                    <md-icon>visibility</md-icon>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="line-separator"></div>
                <div class="detail-section">
                    <span class="title">Asset Group Details</span>
                    <div class="detail-group">
                        <label class="label">Assigned To:</label>
                        <span class="value">
                            <router-link
                                v-if="getUser.publicUserId != assetGroupDetails.ownerId"
                                class="custom-a-blue"
                                :to="{
                                    name: 'Team Member Profile',
                                    params: { publicUserId: assetGroupDetails.ownerId }
                                }"
                            >
                                {{ assetGroupDetails.ownerName }}
                            </router-link>
                            <router-link
                                v-else
                                class="custom-a-blue"
                                :to="{
                                    name: 'User Profile'
                                }"
                            >
                                {{ assetGroupDetails.ownerName }}
                            </router-link>
                        </span>
                    </div>
                    <div class="detail-group" v-if="assetGroupDetails.disabledDate != null">
                        <label class="label">Date Disabled:</label>
                        <span class="value">
                            {{ assetGroupDetails.disabledDate | dateFormat(DATE_TYPES.standardDate) }}
                        </span>
                    </div>
                    <!--<div class="detail-group">
                        <label class="label">Created By:</label>
                        <span class="value">
                            {{ assetGroupDetails.createdByName }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Date Created:</label>
                        <span class="value">
                            {{ assetGroupDetails.createdDate | dateFormat(DATE_TYPES.standardDate) }}
                        </span>
                    </div>-->
                </div>
                <div class="line-separator"></div>
                <div class="detail-section">
                    <span class="title">Connected Assets</span>
                    <div v-if="assetGroupDetails.connectedAssets.length > 0">
                        <div
                            class="detail-group"
                            v-for="(item, index) in assetGroupDetails.connectedAssets"
                            :key="index"
                        >
                            <label class="label">{{ item.type }}</label>
                            <span class="value">
                                <router-link
                                    :to="{
                                        name: 'Asset Details',
                                        params: { assetId: item.assetId }
                                    }"
                                    target="_blank"
                                >
                                    {{ item.name }}
                                </router-link>
                            </span>
                        </div>
                    </div>
                    <div v-else class="detail-group">No assets connected to this group</div>
                </div>
                <!--<div class="line-separator"></div>
                <div class="detail-section">
                    <span class="title">Asset Group Logs</span>
                    <div class="detail-group">No logs available</div>
                </div>-->
            </div>
            <div v-else>
                <div class="stopbar-loader">
                    <fade-loader :loading="loading" color="#333333" />
                    <span>LOADING</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
    name: 'AssetGroupSidebar',
    mixins: [GeneralMixin],
    components: {
        FadeLoader
    },
    computed: {
        ...mapGetters({
            getUser: 'user/user'
        })
    },
    data() {
        return {
            loading: true,
            assetGroupDetails: null
        };
    },
    props: {
        assetGroupId: {
            type: [Number, String],
            default: () => null
        }
    },
    watch: {
        async assetGroupId(newValue, oldValue) {
            await this.getAssetGroupDetails(newValue);
        }
    },
    async mounted() {
        await this.getAssetGroupDetails(this.assetGroupId);
    },
    methods: {
        async getAssetGroupDetails(id) {
            this.loading = true;
            const apiEndpoint = `/api/asset-groups/${id}`;

            const {
                data: { data }
            } = await handleRequests(apiEndpoint);

            this.assetGroupDetails = data;
            this.loading = false;
        },
        closeModal() {
            this.$emit('closeModal');
        },
        updateAsset(response) {
            this.$emit('updateAsset', response);
        }
    }
};
</script>

<style lang="scss" scoped>
.detail-container {
    position: fixed;
    top: 2vh;
    right: 10px;
    height: 96vh;
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    background: white;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 16px;

    .close-button {
        top: 0;
        right: 0;
        position: absolute;
        /* add z-index for old stops that have no stop reference numbers */
        z-index: 999;
    }

    .rating {
        display: flex;
        align-items: flex-end;
        > {
            display: inline-block;
        }
        .md-icon {
            margin: 0;
            padding-bottom: 5px;
        }
    }

    .md-button.md-just-icon,
    ::v-deep .md-button.md-just-icon {
        font-size: 15px;
        height: 30px;
        min-width: 3px;
        width: 30px;
        line-height: 30px;
        margin: 2.5px;
    }

    .detail-section {
        padding-top: 10px;
        .blue-ref a {
            color: #2b93ff !important;
        }
        .title {
            text-transform: uppercase;
            font-weight: 600;
            padding: 2px 16px;
            display: block;
            font-size: 14px;
        }
        ::v-deep .custom-a-blue a {
            color: #2b93ff !important;
        }
    }

    .line-separator {
        height: 1px;
        background-color: #ddd;
        width: calc(100% - 32px);
        margin: 10px auto;
        margin-bottom: 0px;
    }

    .detail-group {
        padding: 2px 16px;
        margin: 5px 0;
        font-size: 0.9em;
        .label {
            display: inline-block;
            width: 95px;
            margin-right: 4px;
            vertical-align: top;
            font-weight: 400;
        }

        .value {
            display: inline-block;
            width: calc(100% - 105px);
            vertical-align: top;
            a {
                color: #2b93ff;
            }
        }
        .custom-btn,
        ::v-deep .custom-btn {
            padding: 4px 8px;
            margin: 0;
            width: initial;
            max-width: initial;
            padding: 4px 0;
            .md-ripple {
                padding: 0 !important;
            }
        }
        .address {
            color: #2b93ff;
            &:hover {
                text-decoration: underline !important;
                cursor: pointer;
            }
        }
        .status-label {
            margin-left: 10px;
            font-weight: 400;
            font-size: 0.75rem;
            width: calc(100% - 100px);
        }
        .info-line {
            font-weight: 400;
            //font-size: 0.75rem;
            width: 100%;
        }
        .quantity {
            float: right;
        }
        .notes {
            padding-left: 24px;
            width: 100%;
        }
    }

    .item-reference ::v-deep a {
        color: rgba(0, 0, 0, 0.87);
    }

    .action-detail-group {
        margin: 0;
        position: relative;
    }

    .profile-image {
        height: 50px;
        width: 50px;
        margin-top: -10px;
        position: absolute;
        left: 16px;
        margin-top: -16px;
        border-radius: 50%;
        box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.1), 0 0px 15px 0 rgba(0, 0, 0, 0.15) !important;
    }
}

.line-tooltip {
    width: 600px;

    .label {
        font-weight: bold;
    }
}

::v-deep {
    .status {
        color: #fff !important;
        min-width: 90px;
        max-width: 90px;
        display: block;
        min-height: 31px;
        text-align: center;
    }
}

.note-image {
    height: 100px;
    width: initial;
    margin-right: 10px;
}

.stopbar-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

a {
    &.link {
        color: #2b93ff;
    }
}

@media (min-width: 992px) {
    .detail-container {
        width: 40%;
    }
}

@media (min-width: 1200px) {
    .detail-container {
        width: 25%;
    }
}

.quantity-type {
    display: inline-block;
    text-transform: lowercase;
}

.quantity-type:first-letter {
    text-transform: capitalize;
}
</style>
