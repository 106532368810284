<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Custom SMS Template</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.form" class="form-wrapper">
                <div class="inputs-container">
                    <div class="selects-container">
                        <form-group label="Event Trigger" class="event-trigger" name="key">
                            <md-select v-model="form.eventTrigger" @md-selected="getTriggerKey">
                                <md-option
                                    v-for="event in eventTriggerOptions"
                                    :key="event.eventTrigger"
                                    :value="event.eventTrigger"
                                >
                                    {{ event.eventTrigger }}
                                </md-option>
                            </md-select>
                        </form-group>
                        <form-group label="Stop Type" class="event-trigger" name="stopType" v-if="hasStopOptions">
                            <md-select v-model="form.stopType" @md-selected="getMergeFields">
                                <md-option v-for="stopType in stopTypeOptions" :key="stopType" :value="stopType">
                                    {{ stopType }}
                                </md-option>
                            </md-select>
                        </form-group>
                    </div>
                    <div class="name-container" v-show="form && form.eventTrigger === 'Manual'">
                        <form-group label="Name" class="" name="name">
                            <md-input v-model="form.name" />
                        </form-group>
                    </div>
                </div>
                <form-group label="Fields" class="custom-merge-field">
                    <drop-down>
                        <md-button @click.prevent slot="title" class="dropdown-toggle md-info" data-toggle="dropdown">
                            Insert Merge Field
                        </md-button>
                        <ul class="dropdown-menu dropdown-menu-right dropdown-menu-height">
                            <li v-for="event in mergeFields" :key="event.key">
                                <span
                                    v-if="event.key === 'stopcustomfields' || event.key === 'shipmentcustomfields'"
                                    class="custom-field-header"
                                >
                                    {{ event.name }}
                                </span>
                                <a v-else @click="getSelectedMergeField(event.key)">
                                    {{ event.name }}
                                </a>
                            </li>
                        </ul>
                    </drop-down>
                </form-group>
                <form-group class="custom-textarea" name="template">
                    <md-textarea v-model="form.template" ref="template" md-counter="160" />
                </form-group>
            </form-wrapper>
            <md-icon class="custom-sms-over-limit-warning-icon">
                warning
                <md-tooltip v-text="customSmsOverLimitTooltip"></md-tooltip>
            </md-icon>
            <div class="custom-sms-over-limit-text" v-if="isCustomSmsOverLimit">
                Your SMS will be sent across multiple segments and could impact your SMS usage costs. Please reduce your
                character count to 160 or less.
            </div>
            <br v-if="!isCustomSmsOverLimit" />
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="saveSmsTemplate">Save</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';
import {
    SMS_EVENT_TRIGGER_CONSTANTS,
    INSERT_MERGE_FIELDS_CONSTANTS,
    INSERT_SHIPMENT_MERGE_FIELDS_CONSTANTS,
    SMS_STOP_TYPES,
    SMS_TRIP_EVENT_TRIGGER_CONSTANTS,
    SMS_MANUAL_EVENT_TRIGGER
} from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
    name: 'TeamSmsTemplateModal',
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            user: 'user/user'
        }),
        isCustomSmsOverLimit() {
            return (
                (this.form === undefined ||
                this.form.template === undefined ||
                this.form === null ||
                this.form.template === null
                    ? 0
                    : this.form.template.length) > 160
            );
        }
    },
    props: {
        origSmsTemplate: {
            type: Object,
            default: () => {}
        },
        level: {
            type: String,
            default: ''
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    async mounted() {
        this.form.key = this.origSmsTemplate.key ? this.origSmsTemplate.key : 'STOP-STATUS-ENROUTE';
        this.form.template = this.origSmsTemplate.template;
        this.form.eventTrigger = this.origSmsTemplate.eventTrigger
            ? this.origSmsTemplate.eventTrigger
            : 'Status-Enroute';
        this.form.stopType = this.origSmsTemplate.stopType ? this.origSmsTemplate.stopType : 'No Stop Type';
        this.form.name = this.origSmsTemplate.name;
        await this.getPodSettings();
        this.getMergeFields();
        this.getShipmentCustomFields();
        this.getStopCustomFields();
        this.hasStopOptions = !this.tripEvents.some((te) => te.eventTrigger === this.origSmsTemplate.eventTrigger);
    },
    data() {
        return {
            form: {
                key: '',
                template: '',
                level: '',
                eventTrigger: '',
                stopType: null,
                name: '',
                id: 0
            },
            eventTriggerOptions: [
                ...SMS_EVENT_TRIGGER_CONSTANTS,
                ...SMS_TRIP_EVENT_TRIGGER_CONSTANTS,
                SMS_MANUAL_EVENT_TRIGGER
            ],
            stopCustomMergeFields: [],
            shipmentCustomMergeFields: [],
            insertShipmentMergeFieldOptions: [...INSERT_SHIPMENT_MERGE_FIELDS_CONSTANTS],
            mergeFields: [],
            stopTypeOptions: [...SMS_STOP_TYPES],
            tripEvents: [...SMS_TRIP_EVENT_TRIGGER_CONSTANTS],
            hasStopOptions: true,
            otpSettings: null,
            customSmsOverLimitTooltip:
                'Note: Using merge fields will affect the SMS character count (e.g. If you use the {{Address}} merge field and a stop/shipment address has 60 characters, then your SMS character count will be down to 100)'
        };
    },
    watch: {
        'form.eventTrigger': function() {
            this.getMergeFields();
        }
    },
    methods: {
        getMergeFields() {
            const insertMergeFieldOptions = this.getInsertMergeFieldOptions();
            if (this.form.stopType === 'Pick Up' || this.form.stopType === 'Drop') {
                this.mergeFields = insertMergeFieldOptions
                    .concat(this.insertShipmentMergeFieldOptions)
                    .concat(this.stopCustomMergeFields)
                    .concat(this.shipmentCustomMergeFields);
            } else {
                this.mergeFields = insertMergeFieldOptions.concat(this.stopCustomMergeFields);
            }
        },
        async saveSmsTemplate() {
            this.$_handleLoaderState(true, 'SAVING...');
            this.form.id = this.origSmsTemplate.id;
            this.form.level = this.level;

            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                const data = Object.assign({}, this.form);

                let method = 'put';
                if (data.id === 0 || data.id === '0' || !data.id) {
                    method = 'post';
                }

                const payload = {
                    method,
                    data
                };

                const api = `/api/${this.level}/sms/template`;

                try {
                    await handleRequests(api, payload);
                    this.$notifySuccess('SMS template saved!');
                } catch (e) {
                    const message = 'Cannot save SMS template.';
                    showErrorMessage(this, message, e);
                }
                this.resolve(data);
            }

            this.$_handleLoaderState(false);
        },
        getTriggerKey(value) {
            const trigger = this.eventTriggerOptions.find((x) => x.eventTrigger === value);
            this.form.key = trigger.key;
            this.hasStopOptions = !this.tripEvents.some((te) => te.eventTrigger === value);
        },
        getSelectedMergeField(value) {
            const textarea = this.$refs.template.$el;

            if (value !== 'default') {
                if (!this.form.template) {
                    this.form.template = '';
                }
                const temp = this.form.template;
                const currentCursorPosition = textarea.selectionStart;
                this.form.template = `${temp.substring(0, textarea.selectionStart)}{{${value}}}${temp.substring(
                    textarea.selectionEnd,
                    temp.length
                )}`;

                this.$nextTick(() => {
                    textarea.focus();
                    // The additional + 4 is because we are adding {{}} to the merge field value upon insert.
                    const cursorfocusPos = currentCursorPosition + value.length + 4;
                    textarea.setSelectionRange(cursorfocusPos, cursorfocusPos);
                });
            }
        },
        getStopCustomFields() {
            const api = '/api/teams/custom-fields/stops';
            handleRequests(api).then((response) => {
                if (response.data.length > 0) {
                    this.stopCustomMergeFields.push({
                        name: 'Stop Custom Fields',
                        key: 'stopcustomfields'
                    });
                    response.data.forEach((field) => {
                        this.stopCustomMergeFields.push({
                            name: field.label,
                            key: field.name
                        });
                    });
                }
                this.getMergeFields();
            });
        },
        getShipmentCustomFields() {
            const api = '/api/teams/custom-fields/shipments';
            handleRequests(api).then((response) => {
                if (response.data.length > 0) {
                    this.shipmentCustomMergeFields.push({
                        name: 'Shipment Custom Fields',
                        key: 'shipmentcustomfields'
                    });
                    response.data.forEach((field) => {
                        this.shipmentCustomMergeFields.push({
                            name: field.label,
                            key: `Shipment.${field.name}`
                        });
                    });
                }
                this.getMergeFields();
            });
        },
        async getPodSettings() {
            const api = `/api/${this.level}/proof-of-delivery`;
            await handleRequests(api).then((response) => {
                const { data } = response;
                if (data && data.length) {
                    this.otpSettings = data.find((x) => x.type === 'OneTimePin');
                }
            });
        },
        getInsertMergeFieldOptions() {
            const eventsWithOtpSupport = ['Status-Enroute', 'StopAssigned'];

            let hideOtpField = true;

            if (this.otpSettings && eventsWithOtpSupport.includes(this.form.eventTrigger)) {
                if (this.form.stopType === 'No Stop Type' && this.otpSettings.standardStops !== 'Never') {
                    hideOtpField = false;
                } else if (this.form.stopType === 'Pick Up' && this.otpSettings.shipmentPickupStops !== 'Never') {
                    hideOtpField = false;
                } else if (this.form.stopType === 'Drop' && this.otpSettings.shipmentDropStops !== 'Never') {
                    hideOtpField = false;
                } else if (
                    this.form.stopType === 'All' &&
                    (this.otpSettings.standardStops !== 'Never' ||
                        this.otpSettings.shipmentPickupStops === 'Never' ||
                        this.otpSettings.shipmentDropStops === 'Never')
                ) {
                    // at least one is not Never
                    hideOtpField = false;
                }
            }

            const stopMergeFields = Array.from(INSERT_MERGE_FIELDS_CONSTANTS);
            if (hideOtpField) {
                // remove otp
                const otpIndex = stopMergeFields.findIndex((x) => x.key === 'OneTimePin');
                if (otpIndex >= 0) 
                    stopMergeFields.splice(otpIndex, 1);
            }

            return stopMergeFields;
        }
    },
    validations: {
        form: {
            key: {
                required
            },
            template: {
                required
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 620px;
}

.inputs-container {
    float: left;
    width: 380px;
    position: relative;

    .selects-container {
        display: block;
        width: 380px;
        float: none;
        clear: both;
    }

    .name-container {
        display: block;
        width: 380px;
        float: none;
        clear: both;
    }
}

.custom-textarea {
    margin-top: 5px !important;
}

.custom-textarea textarea {
    margin-bottom: -13px !important;
}

.event-trigger {
    float: left;
    width: 180px;
    margin-top: 29px;
    margin-right: 10px;
    ::v-deep label {
        top: -1.2rem !important;
    }
}
.custom-merge-field {
    justify-content: flex-end;
    margin: 15px 0 0 auto;
    width: 188px;
    float: right;

    ::v-deep label {
        top: -5px !important;
        font-size: 0.6875rem;
    }

    .dropdown {
        margin-left: 0;
        margin-top: 12px;
        .md-button {
            background-color: #00bcd4 !important;
            margin: 0;

            ::v-deep .md-button-content {
                color: #fff !important;
            }

            ::v-deep .md-ripple:after {
                color: #fff !important;
            }
        }
    }

    .dropdown-menu-height {
        max-height: 400px;
        overflow-y: scroll;
    }
    .custom-field-header {
        padding-left: 10px;
        font-weight: bold;
    }
}
.custom-merge-field:after,
.custom-merge-field:before {
    height: 0;
}
.modal-body {
    padding-bottom: 0;
    padding-top: 10px;
}
.custom-sms-over-limit-text {
    margin-top: 13px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.54);
}
::v-deep .md-count {
    right: 36px !important;
}
.custom-sms-over-limit-warning-icon {
    top: -24px;
    right: 8px;
    float: right;
    position: relative;
}
</style>
