var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.ready
      ? _c("p", [
          _vm._v(
            "\n        Thank you! Your payment has been processed. You can now manage your subscription\n        "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://locate2u.recurly.com/account/" + _vm.token,
                target: "_blank",
              },
            },
            [_vm._v("\n            here\n        ")]
          ),
        ])
      : _vm._e(),
    _vm.error ? _c("p", [_vm._v(_vm._s(_vm.error))]) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }