<template>
    <div class="modal-container" @keydown.esc="$modal.hide" tabindex="0">
        <div class="modal-header">
            <h4 class="modal-title">{{ isUpdate ? 'Update Tax Setting' : 'Create Tax Setting' }}</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>

        <div class="modal-body">
            <form-wrapper :validator="$v.taxSettingDetails" class="form-wrapper">
                <form novalidate @submit.prevent="validateService">
                    <div class="form-container rate-group-form-container">
                        <div class="rate-group-details">
                            <form-group name="type" label="Type" attribute="Type" :messages="localMessages">
                                <md-input v-focus v-model="taxSettingDetails.type" required />
                            </form-group>

                            <!-- <form-group
                                name="accountTaxType"
                                label="Tax type"
                                attribute="Tax Type"
                                :messages="localMessages"
                            >
                                <md-input v-focus v-model="taxSettingDetails.accountTaxType" />
                            </form-group> -->

                            <form-group
                                name="description"
                                label="Description"
                                attribute="Description"
                                :messages="localMessages"
                            >
                                <md-input v-focus v-model="taxSettingDetails.description" />
                            </form-group>

                            <form-group name="rate" label="Rate (%)" attribute="Rate" :messages="localMessages">
                                <md-input v-focus v-model="taxSettingDetails.rate" required />
                            </form-group>
                        </div>
                    </div>
                </form>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validateService">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { isEmptyOrSpaces, handleRequests, showErrorMessage } from '@/helpers';
import { required, maxLength } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'TaxSettingsModal',
    mixins: [GeneralMixin],
    props: {
        taxSettingDetails: {
            type: Object,
            default: () => {}
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    validations: {
        taxSettingDetails: {
            type: { required, maxLength: maxLength(50) },
            description: { maxLength: maxLength(150) },
            accountTaxType: { maxLength: maxLength(50) },
            rate: { required }
        }
    },
    data() {
        return {
            localMessages: {
                required: '{attribute} is required.'
            }
        };
    },
    methods: {
        async create() {
            try {
                this.$_handleLoaderState(true, 'PROCESSING...');
                const api = `/api/team-settings/tax-settings`;

                const payload = {
                    method: 'post',
                    data: this.taxSettingDetails
                };

                const response = await handleRequests(api, payload);
                if (response.status === 200) {
                    this.$notify({
                        message: 'Successfully created tax settings.',
                        type: 'success'
                    });

                    this.$v.$reset();
                    this.resolve('ok');
                }
            } catch (error) {
                const message = 'Error in creating tax settings';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        async updateTaxSettingsDetails() {
            try {
                this.$_handleLoaderState(true, 'UPDATING...');

                const api = `/api/team-settings/tax-settings/${this.taxSettingDetails.taxSettingsId}`;
                const payload = {
                    method: 'put',
                    data: this.taxSettingDetails
                };

                await handleRequests(api, payload);

                this.$notify({
                    message: 'Successfully updated tax settings.',
                    type: 'success'
                });

                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Error in updating the tax settings.';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        validateService() {
            this.$v.taxSettingDetails.$touch();

            if (!this.$v.taxSettingDetails.$invalid) {
                if (!isEmptyOrSpaces(this.taxSettingDetails.type)) {
                    this.taxSettingDetails.type = this.taxSettingDetails.type.trim();
                }

                if (this.isUpdate) {
                    this.updateTaxSettingsDetails();
                } else {
                    this.create();
                }

                return;
            }

            this.$notify({
                message: 'There are errors in the form. Please correct them to continue.',
                type: 'danger'
            });
        }
    }
};
</script>
