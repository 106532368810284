<template>
    <div class="content">
        <div v-if="obdAlertModalHide !== true" class="alert alert-info alert-z">
            <button type="button" aria-hidden="true" class="close" @click="obdAlertModalHide = true">
                ×
            </button>
            <span>
                The Ignition On/Off reports are only available for OBD device data.
            </span>
        </div>

        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <div class="custom-toolbar md-layout">
                    <div class="md-layout-item custom-toolbar-start md-layout md-small-size-80 md-size-90">
                        <team-members-filter @onSelection="onTeamMemberSelection" />

                        <div class="md-layout-item">
                            <date-range-filter @onChanged="onDateChanged" />
                        </div>
                    </div>
                    <div class="md-layout-item custom-toolbar-end">
                        <md-button
                            title="Export Ignition report"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click="onExportToExcel"
                        >
                            <md-icon>get_app</md-icon>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <ignition-report-details :member-list="drivesList" />
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.alert-z {
    z-index: 0 !important;
}
</style>
<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import IgnitionReportDetails from './IgnitionReportDetails';
import { handleRequests, downloadFile } from '../../helpers';
import { TeamMembersFilter, DateRangeFilter } from './components';

export default {
    name: 'IgnitionReport',
    components: { IgnitionReportDetails, TeamMembersFilter, DateRangeFilter },
    mixins: [GeneralMixin],
    data() {
        return {
            isLoading: true,
            startDate: null,
            endDate: null,
            drivesList: [],
            userFullName: '',
            selectedTeamMember: {},
            smallAlertModalHide: false,
            obdAlertModalHide: false
        };
    },
    created() {
        this.getDriveDetailsList = _.debounce(this.getDriveDetailsList, 500);
    },
    methods: {
        ...mapGetters({
            user: 'user/user'
        }),

        onExportToExcel() {
            if (this.startDate && this.endDate) {
                const reportDate = `${moment(this.startDate).format('YYYY-MM-DD')}-${moment(this.endDate).format(
                    'YYYY-MM-DD'
                )}`;

                this.$_handleLoaderState(true);
                const api = `/api/reports/ignition-report/export-to-excel/?userId=${this.selectedTeamMember.userId ||
                    ''}&isTracker=${this.selectedTeamMember.isTracker}&startDate=${moment(this.startDate).format(
                    'YYYY-MM-DD'
                )}&endDate=${moment(this.endDate).format('YYYY-MM-DD')}`;
                const payload = {
                    method: 'get',
                    responseType: 'blob'
                };
                const reportName = `${this.selectedTeamMember.name ||
                    this.userFullName}-IgnitionsReport-${reportDate}.xlsx`;

                handleRequests(api, payload)
                    .then((response) => {
                        downloadFile(response.data, reportName);
                        this.$_handleLoaderState(false);
                    })
                    .catch((e) => {
                        this.$_handleLoaderState(false);
                        this.$notify({ message: e.data, type: 'danger' });
                    });
            }
        },

        getDriveDetailsList() {
            if (this.startDate && this.endDate) {
                this.isLoading = true;
                this.$_handleLoaderState(true);
                const api = `/api/reports/ignition-report/?userId=${this.selectedTeamMember.userId || ''}&isTracker=${
                    this.selectedTeamMember.isTracker
                }
                    &startDate=${moment(this.startDate).format('YYYY-MM-DD')}&endDate=${moment(this.endDate).format(
    'YYYY-MM-DD'
)}`;
                handleRequests(api)
                    .then((response) => {
                        this.isLoading = false;
                        const { data } = response.data;

                        this.drivesList = data;

                        this.$_handleLoaderState(false);
                    })
                    .catch((e) => {
                        this.drivesList = [];
                        this.$_handleLoaderState(false);
                        this.$notify({ message: e.data, type: 'danger' });
                    });
            }
        },

        async onDateChanged(val) {
            this.startDate = val.startDate;
            this.endDate = val.endDate;
            this.getDriveDetailsList();
        },

        async onTeamMemberSelection(val) {
            this.selectedTeamMember = val || {};
            this.getDriveDetailsList();
        }
    },
    watch: {
        startDate(newVal, oldVal) {
            if (moment(newVal).valueOf() !== moment(oldVal).valueOf()) {
                if (moment(newVal) > moment(this.endDate)) {
                    this.$notify({
                        message: 'Start date must not be after the end date.',
                        type: 'danger'
                    });

                    this.startDate = this.endDate;
                    return;
                }
                this.getDriveDetailsList();
            }
        },
        endDate(newVal, oldVal) {
            if (moment(newVal).valueOf() !== moment(oldVal).valueOf()) {
                if (moment(newVal) < moment(this.startDate)) {
                    this.$notify({
                        message: 'End date cannot be earlier than the start date.',
                        type: 'danger'
                    });

                    this.endDate = this.startDate;
                    return;
                }
                this.getDriveDetailsList();
            }
        }
    },
    async mounted() {
        this.userFullName = this.user().fullName;
    }
};
</script>
