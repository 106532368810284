<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item md-size-50">
                <form-group name="groupName" label="Asset Group Name">
                    <div>
                        <md-input
                            v-focus
                            v-model="assetGroupDetails.groupName"
                        />
                    </div>
                </form-group>
            </div>
            <div class="md-layout-item md-size-100">
                <form-group name="notes" label="Asset Group Notes">
                    <md-input v-model="assetGroupDetails.notes" />
                </form-group>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GroupStep',
    props: {
        assetGroupDetails: {
            type: Object,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-field > div,
::v-deep .md-input {
    width: 100%;
}
</style>
