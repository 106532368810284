var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-form-column" },
      [
        _c(
          "md-card",
          [
            _c("md-card-header", [
              _c("div", { staticClass: "md-title" }, [_vm._v("Create Trip")]),
            ]),
            _c("md-card-content", [
              _c("div", { staticClass: "md-layout md-gutter" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-medium-size-50 md-small-size-100",
                  },
                  [
                    _c(
                      "form-group",
                      { attrs: { name: "teamRegion", label: "Team Region" } },
                      [
                        _c("team-region-options", {
                          attrs: { selected: _vm.tripDetails.teamRegionId },
                          on: { selectedOption: _vm.handleTeamRegionChanged },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-medium-size-50 md-small-size-100",
                  },
                  [
                    _c("TeamMemberOptions", {
                      attrs: {
                        selected: _vm.tripDetails.publicUserId,
                        "team-region-id-filter": _vm.tripDetails.teamRegionId,
                      },
                      on: { selectedOption: _vm.handleTeamMemberChanged },
                    }),
                    _vm.errors.publicUserId
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v(_vm._s(_vm.errors.publicUserId.message)),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "md-layout md-gutter" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-medium-size-50 md-small-size-100",
                  },
                  [
                    _c("CustomerOptions", {
                      attrs: {
                        selected: _vm.tripDetails.customerId,
                        "team-region-id": _vm.tripDetails.teamRegionId,
                      },
                      on: { selectedOption: _vm.handleCustomerChanged },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-medium-size-50 md-small-size-100",
                  },
                  [
                    _c("charging-type-options", {
                      attrs: {
                        "selected-charge-type-id": _vm.tripDetails.rateGroupId,
                      },
                      on: { selectedOption: _vm.handleRateTypeChanged },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "md-card",
          [
            _c("md-card-header", [
              _c("h3", { staticClass: "md-title" }, [
                _vm._v("Trip Information"),
              ]),
            ]),
            _c("md-card-content", [
              _c("div", { staticClass: "md-layout md-gutter" }, [
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-datepicker",
                      {
                        ref: "mdDatePicker",
                        attrs: {
                          "md-immediately": "",
                          "md-disabled-dates": _vm.disabledDates,
                          "md-debounce": 10,
                          clearable: false,
                        },
                        model: {
                          value: _vm.tripDetails.tripDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.tripDetails, "tripDate", $$v)
                          },
                          expression: "tripDetails.tripDate",
                        },
                      },
                      [
                        _c("label", [_vm._v("Trip Date")]),
                        _vm.errors.tripDate
                          ? _c("span", { staticClass: "error" }, [
                              _vm._v(_vm._s(_vm.errors.tripDate.message)),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "md-layout md-gutter" }, [
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c("google-autocomplete", {
                      attrs: {
                        label: "Start Address",
                        id: "startAddress",
                        classname: "form-control autocomplete-input",
                        placeholder: "",
                        "should-focus": false,
                      },
                      on: { placechanged: _vm.handleStartLocationChange },
                      model: {
                        value: _vm.tripDetails.startAddress,
                        callback: function ($$v) {
                          _vm.$set(_vm.tripDetails, "startAddress", $$v)
                        },
                        expression: "tripDetails.startAddress",
                      },
                    }),
                    _vm.errors.startAddress
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v(_vm._s(_vm.errors.startAddress.message)),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "md-layout md-gutter" }, [
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c("google-autocomplete", {
                      attrs: {
                        label: "End Address",
                        id: "endAddress",
                        classname: "form-control autocomplete-input",
                        placeholder: "",
                        "should-focus": false,
                      },
                      on: { placechanged: _vm.handleEndLocationChange },
                      model: {
                        value: _vm.tripDetails.endAddress,
                        callback: function ($$v) {
                          _vm.$set(_vm.tripDetails, "endAddress", $$v)
                        },
                        expression: "tripDetails.endAddress",
                      },
                    }),
                    _vm.errors.endAddress
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v(_vm._s(_vm.errors.endAddress.message)),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "md-layout md-gutter" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-medium-size-50 md-small-size-100",
                  },
                  [
                    _c(
                      "form-group",
                      {
                        staticClass: "time-picker hide-clear-btn",
                        attrs: { name: "startTime", label: "Start Time" },
                      },
                      [
                        _c("time-picker", {
                          attrs: {
                            time: _vm.tripDetails.startTime,
                            "all-time-options": false,
                            "is-required": false,
                          },
                          on: { selectedTime: _vm.getStartTime },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "time-picker-error" }, [
                      _vm.errors.startTime
                        ? _c("span", { staticClass: "error" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.errors.startTime.message) +
                                "\n                            "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-medium-size-50 md-small-size-100",
                  },
                  [
                    _c(
                      "form-group",
                      {
                        staticClass: "time-picker hide-clear-btn",
                        attrs: { name: "endTime", label: "End Time" },
                      },
                      [
                        _c("time-picker", {
                          attrs: {
                            time: _vm.tripDetails.endTime,
                            "all-time-options": false,
                            "is-required": false,
                          },
                          on: { selectedTime: _vm.getEndTime },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "time-picker-error" }, [
                      _vm.errors.endTime
                        ? _c("span", { staticClass: "error" }, [
                            _vm._v(_vm._s(_vm.errors.endTime.message)),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", [
      _c(
        "div",
        { staticClass: "summary" },
        [
          _c("SummaryCard", {
            scopedSlots: _vm._u([
              {
                key: "header-text",
                fn: function () {
                  return [_c("h3", [_vm._v("Summary")])]
                },
                proxy: true,
              },
              {
                key: "content",
                fn: function () {
                  return [
                    !!_vm.selectedTeamRegionModel
                      ? _c("SummaryCardSection", [
                          _c("h6", [_vm._v("Team Region")]),
                          _c("p", [
                            _vm._v(_vm._s(_vm.selectedTeamRegionModel.name)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.fieldsToDisplayByDefault.publicUserId ||
                    !!_vm.selectedTeamMemberModel
                      ? _c("SummaryCardSection", [
                          _c("h6", [_vm._v("Assigned To")]),
                          _c("p", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.selectedTeamMemberModel
                                    ? _vm.selectedTeamMemberModel.fullName
                                    : "-"
                                ) +
                                "\n                        "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    !!_vm.selectedCustomerModel
                      ? _c(
                          "SummaryCardSection",
                          { staticClass: "summary-card--section" },
                          [
                            _c("h6", [_vm._v("Customer")]),
                            _c("p", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.selectedCustomerModel.company ||
                                      _vm.selectedCustomerModel.name ||
                                      _vm.selectedCustomerModel.firstName +
                                        " " +
                                        _vm.selectedCustomerModel.lastName
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    !!_vm.selectedRateTypeModel
                      ? _c(
                          "SummaryCardSection",
                          { staticClass: "summary-card--section" },
                          [
                            _c("h6", [_vm._v("Service Package")]),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.selectedRateTypeModel.rateGroupName)
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "SummaryCardSection",
                      { staticClass: "summary-card--section" },
                      [
                        _c("h6", [_vm._v("Trip Date")]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.tripDetails.tripDate &&
                                _vm.tripDetails.tripDate.toDateString()
                            )
                          ),
                        ]),
                        _vm.fieldsToDisplayByDefault.startAddress ||
                        _vm.tripDetails.startAddress.address
                          ? [
                              _c("h6", [_vm._v("Start Address")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.tripDetails.startAddress.address || "-"
                                  )
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _vm.tripDetails.endAddress.address
                          ? [
                              _c("h6", [_vm._v("End Address")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.tripDetails.endAddress.address || "-"
                                  )
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _c("div", { staticClass: "start-end-time" }, [
                          _vm.fieldsToDisplayByDefault.startTime ||
                          _vm.startTimeFormatted !== "-"
                            ? _c("div", [
                                _c("h6", [_vm._v("Start Time")]),
                                _c("p", [
                                  _vm._v(_vm._s(_vm.startTimeFormatted)),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.fieldsToDisplayByDefault.endTime ||
                          _vm.endTimeFormatted !== "-"
                            ? _c("div", [
                                _c("h6", [_vm._v("End Time")]),
                                _c("p", [_vm._v(_vm._s(_vm.endTimeFormatted))]),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      2
                    ),
                    _vm.rateListUIItems.length > 0 &&
                    _vm.fetchRateListStatus !== "pending"
                      ? _c(
                          "div",
                          { staticClass: "summary-card--charges-cost-section" },
                          [
                            _c("div", { staticClass: "charges-cost-heading" }, [
                              _c("h6", [_vm._v("Expected Charges")]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "charges-cost-table" },
                              [
                                _vm._l(
                                  _vm.rateListUIItems,
                                  function (uiItem, index) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "charge-cost-description",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(uiItem.description) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          key: index,
                                          staticClass: "charge-cost-amount",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                uiItem.amountText ||
                                                  _vm.formatValue(uiItem.amount)
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      : _vm.fetchRateListStatus === "pending"
                      ? _c(
                          "div",
                          [
                            _c("md-progress-bar", {
                              attrs: { "md-mode": "indeterminate" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "actions",
                fn: function () {
                  return [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-primary",
                        on: { click: _vm.createTrip },
                      },
                      [
                        _vm._v(
                          "\n                        Create Trip\n                    "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }