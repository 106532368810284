var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-section" }, [
    _c("div", { staticClass: "section-header" }, [
      _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.sectionTitle))]),
      _c(
        "div",
        { staticClass: "section-header--actions" },
        [
          _c(
            "md-button",
            {
              staticClass: "md-info md-just-icon md-round",
              on: { click: _vm.createLocation },
            },
            [_c("md-icon", [_vm._v("add")])],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "search-area" },
      [
        _c("vue-select", {
          attrs: {
            label: "displayText",
            options: _vm.locationChoicesPluralized,
            placeholder: "Select location type",
          },
          model: {
            value: _vm.locationTypeQuery,
            callback: function ($$v) {
              _vm.locationTypeQuery = $$v
            },
            expression: "locationTypeQuery",
          },
        }),
        _c("search-component", {
          attrs: { placeholder: "Search barcode / name" },
          on: { onSearch: _vm.handleSearchByName },
        }),
      ],
      1
    ),
    _vm.loading
      ? _c(
          "div",
          { staticClass: "loading-section" },
          [
            _c("FadeLoader", {
              attrs: { loading: _vm.loading, color: "#333333" },
            }),
          ],
          1
        )
      : _c("div", [
          _vm.locations.length === 0
            ? _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label no-location" }, [
                  _vm._v("No locations found"),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "location-groups" },
            _vm._l(_vm.locationGroupsSorted, function (type) {
              return _c(
                "div",
                { key: type, staticClass: "detail-group location-group" },
                [
                  _c("md-tooltip", [
                    _vm._v(
                      "\n                    Click me to view items inside this location\n                "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "location-items" },
                    [
                      _c("span", { staticClass: "location-group--title" }, [
                        _vm._v(
                          _vm._s(_vm.locationChoicesObjPluralized[type] || type)
                        ),
                      ]),
                      _vm._l(
                        _vm.locationsGroupedByType[type],
                        function (location, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "location-item",
                              on: {
                                click: function ($event) {
                                  return _vm.viewItemLocationItems(
                                    location.locationId
                                  )
                                },
                              },
                            },
                            [
                              _c("ColorViewer", {
                                staticClass: "location-item--color",
                                attrs: { color: location.color },
                              }),
                              _c(
                                "div",
                                { staticClass: "location-item--body" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "location-item--body-title",
                                    },
                                    [_vm._v(_vm._s(location.name))]
                                  ),
                                  !!location.notes
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "location-item--body-subtitle",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(location.notes) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "location-item--actions" },
                                [
                                  _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-info md-just-icon md-round",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.generateLabel(
                                            location.locationId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("md-icon", [
                                        _vm._v("download_for_offline"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-warning md-just-icon md-round",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.updateLocation(
                                            location.locationId
                                          )
                                        },
                                      },
                                    },
                                    [_c("md-icon", [_vm._v("edit")])],
                                    1
                                  ),
                                  _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-danger md-just-icon md-round",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteLocation(
                                            location.locationId
                                          )
                                        },
                                      },
                                    },
                                    [_c("md-icon", [_vm._v("delete")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }