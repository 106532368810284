var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "detail-container" }, [
      _c(
        "div",
        { staticClass: "close-button" },
        [
          _c(
            "md-button",
            {
              staticClass: "md-default md-just-icon md-round pull-right",
              on: { click: _vm.closeModal },
            },
            [_c("md-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      !_vm.loading
        ? _c("div", [
            _c("div", { staticClass: "detail-section text-center" }, [
              _c(
                "span",
                { staticClass: "title blue-ref" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Asset Group Details",
                          params: {
                            assetGroupId: _vm.assetGroupDetails.assetGroupId,
                          },
                        },
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.assetGroupDetails.groupName) +
                          "\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "detail-group text-center action-detail-group" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "md-button md-success md-just-icon md-round",
                      attrs: {
                        title: "View asset details",
                        to: {
                          name: "Asset Group Details",
                          params: {
                            assetGroupId: _vm.assetGroupDetails.assetGroupId,
                          },
                        },
                        target: "_blank",
                      },
                    },
                    [
                      _c("div", { staticClass: "md-ripple" }, [
                        _c(
                          "div",
                          { staticClass: "md-button-content" },
                          [_c("md-icon", [_vm._v("visibility")])],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "line-separator" }),
            _c("div", { staticClass: "detail-section" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v("Asset Group Details"),
              ]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Assigned To:")]),
                _c(
                  "span",
                  { staticClass: "value" },
                  [
                    _vm.getUser.publicUserId != _vm.assetGroupDetails.ownerId
                      ? _c(
                          "router-link",
                          {
                            staticClass: "custom-a-blue",
                            attrs: {
                              to: {
                                name: "Team Member Profile",
                                params: {
                                  publicUserId: _vm.assetGroupDetails.ownerId,
                                },
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.assetGroupDetails.ownerName) +
                                "\n                        "
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass: "custom-a-blue",
                            attrs: {
                              to: {
                                name: "User Profile",
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.assetGroupDetails.ownerName) +
                                "\n                        "
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ]),
              _vm.assetGroupDetails.disabledDate != null
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Date Disabled:"),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("dateFormat")(
                              _vm.assetGroupDetails.disabledDate,
                              _vm.DATE_TYPES.standardDate
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "line-separator" }),
            _c("div", { staticClass: "detail-section" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v("Connected Assets"),
              ]),
              _vm.assetGroupDetails.connectedAssets.length > 0
                ? _c(
                    "div",
                    _vm._l(
                      _vm.assetGroupDetails.connectedAssets,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "detail-group" },
                          [
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(item.type)),
                            ]),
                            _c(
                              "span",
                              { staticClass: "value" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Asset Details",
                                        params: { assetId: item.assetId },
                                      },
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.name) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _c("div", { staticClass: "detail-group" }, [
                    _vm._v("No assets connected to this group"),
                  ]),
            ]),
          ])
        : _c("div", [
            _c(
              "div",
              { staticClass: "stopbar-loader" },
              [
                _c("fade-loader", {
                  attrs: { loading: _vm.loading, color: "#333333" },
                }),
                _c("span", [_vm._v("LOADING")]),
              ],
              1
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }