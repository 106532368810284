var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v("Create Support User"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "tabs",
          {
            staticClass: "custom-tab-list",
            attrs: {
              "tab-name": ["Add Existing User", "Create User"],
              "color-button": "warning",
            },
            on: { "switch-panel": _vm.handleSwitchPanel },
          },
          [
            _c(
              "template",
              { slot: "tab-pane-1" },
              [
                _c(
                  "form-wrapper",
                  {
                    staticClass: "form-wrapper",
                    attrs: { validator: _vm.$v.formAdd },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "form-group",
                          {
                            attrs: {
                              name: "email",
                              label: "Email Address*",
                              messages: _vm.localMessages,
                            },
                          },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.formAdd.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formAdd, "email", $$v)
                                },
                                expression: "formAdd.email",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.joinErrors.length > 0
                      ? _c("div", [
                          _c(
                            "ul",
                            _vm._l(_vm.joinErrors, function (error) {
                              return _c(
                                "li",
                                { key: error.errorCode, staticClass: "error" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(error.message) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
            _c(
              "template",
              { slot: "tab-pane-2" },
              [
                _c(
                  "form-wrapper",
                  {
                    staticClass: "form-wrapper",
                    attrs: { validator: _vm.$v.formCreate },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "form-group",
                          {
                            attrs: {
                              name: "email",
                              label: "Email Address*",
                              messages: _vm.localMessages,
                            },
                          },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.formCreate.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formCreate, "email", $$v)
                                },
                                expression: "formCreate.email",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "form-group",
                          {
                            attrs: { name: "firstName", label: "First name*" },
                          },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.formCreate.firstName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formCreate, "firstName", $$v)
                                },
                                expression: "formCreate.firstName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "form-group",
                          { attrs: { name: "lastName", label: "Last name*" } },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.formCreate.lastName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formCreate, "lastName", $$v)
                                },
                                expression: "formCreate.lastName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "md-checkbox",
                          {
                            on: { change: _vm.checkSetPassword },
                            model: {
                              value: _vm.setPassword,
                              callback: function ($$v) {
                                _vm.setPassword = $$v
                              },
                              expression: "setPassword",
                            },
                          },
                          [_vm._v("Set a password.")]
                        ),
                        _vm.setPassword
                          ? _c(
                              "form-group",
                              {
                                attrs: { name: "password", label: "Password" },
                              },
                              [
                                _c("md-input", {
                                  model: {
                                    value: _vm.formCreate.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formCreate, "password", $$v)
                                    },
                                    expression: "formCreate.password",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.createErrors.length > 0
                      ? _c("div", [
                          _c(
                            "ul",
                            _vm._l(_vm.createErrors, function (error) {
                              return _c(
                                "li",
                                { key: error.code, staticClass: "error" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(error.description) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.create },
          },
          [_vm._v(_vm._s(_vm.createButtonText))]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }