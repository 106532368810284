import { ASSET_LOG_TYPES } from '@/utils/AssetLogs';

export const AssetManagementMixin = {
    methods: {
        $_getAssetLogTypeBadgeColor(type) {
            const assetTypes = [...ASSET_LOG_TYPES];
            const result = assetTypes.find((assetType) => assetType.key === type);

            if (result) 
                return result.color;

            return 'pending';
        },
        $_getAssetLogTypeIcon(type) {
            const assetTypes = [...ASSET_LOG_TYPES];
            const result = assetTypes.find((assetType) => assetType.key === type);

            if (result) 
                return result.icon;

            return 'build';
        },
        $_getAssetLogSubTypeBadgeColor(type, subType) {
            const assetTypes = [...ASSET_LOG_TYPES];
            const result = assetTypes.find((assetType) => assetType.key === type);

            if (!result) 
                return 'build';

            const subresult = result.sub_categories.find((sub) => sub.key === subType);

            if (subresult) 
                return subresult.color;

            return 'build';
        }
    }
};
