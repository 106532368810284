<template>
    <div class="modal-container" v-if="showWizardModal">
        <div class="modal-header">
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <simple-wizard
                plain
                :show-header="false"
                :title="title"
                sub-title=""
                @finish="validateDetails"
                @stepChange="stepChange"
                :finish-button-text="!isUpdate ? 'Create' : 'Update'"
            >
                <wizard-tab :before-change="validateDetails" :id="'maintenanceScheduleDetails'">
                    <template slot="label">
                        Schedule Details
                    </template>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <form-wrapper
                                v-if="step === 'maintenanceScheduleDetails'"
                                :validator="$v.maintenanceScheduleDetails"
                                class="form-wrapper"
                            >
                                <schedule-details-step :maintenance-schedule-details="maintenanceScheduleDetails" />
                            </form-wrapper>
                        </div>

                        <div class="md-layout-item md-size-50 card-expansion" v-if="!isUpdate">
                            <form-wrapper :validator="$v.maintenanceScheduleDetails" class="form-wrapper">
                                <div class="recurrence-container">
                                    <div>
                                        <span>Recurrence type:</span>
                                        <md-radio v-model="maintenanceScheduleDetails.frequency" value="one-off">
                                            One-off
                                        </md-radio>
                                        <md-radio v-model="maintenanceScheduleDetails.frequency" value="recurring">
                                            Recurring
                                        </md-radio>
                                    </div>
                                </div>

                                <md-list
                                    :md-expand-single="false"
                                    class="custom-accordion-list"
                                    v-if="maintenanceScheduleDetails.frequency == 'recurring' || asset != null"
                                >
                                    <div>
                                        <div class="container-checkbox">
                                            <md-checkbox
                                                v-model="setTimeReminder"
                                                class="accordion-checkbox"
                                            ></md-checkbox>
                                        </div>
                                        <div
                                            class="container-dropdown context-menu-support-accordion"
                                            :class="asset != null && asset.trackerId != null ? 'one-time-field' : ''"
                                        >
                                            <md-list-item md-expand :md-expanded.sync="expandTimeReminder">
                                                <span class="md-list-item-text" @click.stop="setTimeReminderTitle">
                                                    Time based reminder
                                                </span>

                                                <md-list slot="md-expand">
                                                    <!-- will only be shown for when creating single maintenance schedule for assets. -->
                                                    <div v-if="asset != null" class="field-box-value">
                                                        <div
                                                            class="reminder-p interval-title"
                                                            v-if="maintenanceScheduleDetails.frequency == 'recurring'"
                                                        >
                                                            Next due date:
                                                        </div>
                                                        <div class="reminder-p interval-title" v-else>Due on</div>
                                                        <div class="custom-md-field custom-date-picker-field">
                                                            <md-datepicker
                                                                ref="mdDatePicker"
                                                                v-model="nextServiceDate"
                                                                md-immediately
                                                                :md-disabled-dates="disabledDates"
                                                                :md-debounce="10"
                                                            ></md-datepicker>
                                                        </div>
                                                    </div>

                                                    <p
                                                        class="reminder-p"
                                                        v-if="maintenanceScheduleDetails.frequency == 'recurring'"
                                                    >
                                                        <span class="interval-title">
                                                            Interval
                                                        </span>

                                                        <frequency-interval-dropdown
                                                            :date="nextServiceDate"
                                                            @recurrenceUpdated="handleRecurrenceUpdate"
                                                        />
                                                    </p>

                                                    <div
                                                        v-if="
                                                            maintenanceScheduleDetails.frequency == 'recurring' &&
                                                                selectedRecurrence == 'custom'
                                                        "
                                                    >
                                                        <div
                                                            v-if="
                                                                maintenanceScheduleDetails.timeScheduleUnitType !=
                                                                    'weeks'
                                                            "
                                                            class="field-box-value time-sched-input"
                                                        >
                                                            <div class="reminder-p"><span>Repeat every</span></div>
                                                            <form-group
                                                                name="timeScheduleInterval"
                                                                class="custom-field-input"
                                                            >
                                                                <md-input
                                                                    v-model="
                                                                        maintenanceScheduleDetails.timeScheduleInterval
                                                                    "
                                                                />
                                                            </form-group>
                                                        </div>

                                                        <div
                                                            v-if="
                                                                maintenanceScheduleDetails.timeScheduleUnitType ==
                                                                    'weeks'
                                                            "
                                                            class="field-box-value"
                                                        >
                                                            <div class="md-layout weekly-box">
                                                                <label class="reminder-p">Repeat Every:</label>
                                                                <div class="checkbox-container">
                                                                    <md-checkbox
                                                                        v-model="scheduleWeeklyValue"
                                                                        v-for="(day, index) in weekDays"
                                                                        :key="day"
                                                                        :value="index"
                                                                    >
                                                                        {{ day }}
                                                                    </md-checkbox>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <md-field class="field-select">
                                                            <md-select
                                                                v-model="
                                                                    maintenanceScheduleDetails.timeScheduleUnitType
                                                                "
                                                            >
                                                                <md-option value="days">
                                                                    days
                                                                </md-option>
                                                                <md-option value="weeks">
                                                                    weeks
                                                                </md-option>
                                                                <md-option value="months">
                                                                    months
                                                                </md-option>
                                                                <md-option value="years">
                                                                    years
                                                                </md-option>
                                                            </md-select>
                                                        </md-field>
                                                    </div>

                                                    <p class="reminder-p" v-if="selectedRecurrence != 'daily'">
                                                        <span>Remind me</span>
                                                        <form-group name="timeScheduleBefore" class="short-field">
                                                            <md-input
                                                                v-model="maintenanceScheduleDetails.timeScheduleBefore"
                                                            />
                                                        </form-group>
                                                        days before.
                                                    </p>

                                                    <p class="reminder-p" v-if="selectedRecurrence != 'daily'">
                                                        <span
                                                            v-if="maintenanceScheduleDetails.frequency == 'recurring'"
                                                        >
                                                            Remind me every
                                                        </span>
                                                        <span v-else>Remind me</span>
                                                        <form-group name="timeScheduleAfter" class="short-field">
                                                            <md-input
                                                                v-model="maintenanceScheduleDetails.timeScheduleAfter"
                                                            />
                                                        </form-group>
                                                        days after.
                                                    </p>
                                                </md-list>
                                            </md-list-item>
                                        </div>
                                    </div>
                                </md-list>

                                <!-- <div v-if="asset != null && asset.type == 'Vehicle' && asset.trackerId == null">
                                <span>**No available tracker attached.</span>
                            </div> -->

                                <md-list :md-expand-single="true" class="custom-accordion-list">
                                    <div>
                                        <div class="container-checkbox">
                                            <md-checkbox
                                                v-model="setEngineHoursReminder"
                                                class="accordion-checkbox"
                                                :disabled="asset != null && asset.trackerId == null"
                                            ></md-checkbox>
                                        </div>
                                        <div
                                            :class="asset != null && asset.trackerId == null ? 'disabled-overlay' : ''"
                                            v-if="asset != null && asset.trackerId == null"
                                        ></div>
                                        <div
                                            class="container-dropdown"
                                            :class="asset != null && asset.trackerId != null ? 'eh-one-time-field' : ''"
                                        >
                                            <md-list-item md-expand :md-expanded.sync="expandEngineHours">
                                                <span
                                                    class="md-list-item-text"
                                                    @click.stop="setEngineHoursReminderTitle"
                                                >
                                                    Engine Hours Reminder
                                                    <span
                                                        class="unavailable-note"
                                                        v-if="asset != null && asset.trackerId == null"
                                                    >
                                                        Tracker Unavailable
                                                    </span>
                                                </span>
                                                <md-list slot="md-expand">
                                                    <p
                                                        class="reminder-p"
                                                        v-if="asset != null && asset.trackerId != null"
                                                    >
                                                        <span class="">Current engine hours</span>
                                                        <form-group
                                                            name="currentEngineHoursValue"
                                                            class="short-field  distance-field"
                                                        >
                                                            <div>
                                                                <md-input
                                                                    v-model="
                                                                        maintenanceScheduleDetails.currentEngineHoursValue
                                                                    "
                                                                />
                                                            </div>
                                                        </form-group>
                                                    </p>
                                                    <p
                                                        class="reminder-p"
                                                        v-if="
                                                            asset != null &&
                                                                maintenanceScheduleDetails.frequency == 'recurring'
                                                        "
                                                    >
                                                        <span class="interval-title">Next due</span>
                                                        <form-group
                                                            class="short-field  distance-field"
                                                            name="nextDueEngineHours"
                                                        >
                                                            <md-input
                                                                v-model="maintenanceScheduleDetails.nextDueEngineHours"
                                                            />
                                                        </form-group>
                                                        hours
                                                    </p>

                                                    <p class="reminder-p">
                                                        <span
                                                            v-if="maintenanceScheduleDetails.frequency == 'recurring'"
                                                        >
                                                            Repeat every
                                                        </span>
                                                        <span v-else>Due</span>
                                                        <form-group
                                                            name="engineHoursDue"
                                                            class="short-field  distance-field"
                                                        >
                                                            <md-input
                                                                v-model="maintenanceScheduleDetails.engineHoursDue"
                                                            />
                                                        </form-group>

                                                        hours
                                                    </p>

                                                    <p class="reminder-p">
                                                        <span>Remind me</span>
                                                        <form-group
                                                            name="engineHoursBefore"
                                                            class="short-field  distance-field"
                                                        >
                                                            <md-input
                                                                v-model="maintenanceScheduleDetails.engineHoursBefore"
                                                            />
                                                        </form-group>
                                                        hours before.
                                                    </p>

                                                    <p class="reminder-p">
                                                        <span>Remind me every</span>
                                                        <form-group
                                                            name="engineHoursAfter"
                                                            class="short-field  distance-field"
                                                        >
                                                            <md-input
                                                                v-model="maintenanceScheduleDetails.engineHoursAfter"
                                                            />
                                                        </form-group>
                                                        hours after.
                                                    </p>
                                                </md-list>
                                            </md-list-item>
                                        </div>
                                    </div>
                                </md-list>

                                <md-list :md-expand-single="true" class="custom-accordion-list">
                                    <div>
                                        <div class="container-checkbox">
                                            <md-checkbox
                                                v-model="setOdometerReminder"
                                                class="accordion-checkbox"
                                                :disabled="asset != null && asset.trackerId == null"
                                            ></md-checkbox>
                                        </div>
                                        <div
                                            :class="asset != null && asset.trackerId == null ? 'disabled-overlay' : ''"
                                            v-if="asset != null && asset.trackerId == null"
                                        ></div>
                                        <div
                                            class="container-dropdown"
                                            :class="
                                                asset != null && asset.trackerId != null
                                                    ? 'one-time-field odometer-custom-field'
                                                    : ''
                                            "
                                        >
                                            <md-list-item md-expand :md-expanded.sync="expandOdometer">
                                                <span class="md-list-item-text" @click.stop="setOdometerReminderTitle">
                                                    Distance Based Reminder
                                                    <span
                                                        class="unavailable-note"
                                                        v-if="asset != null && asset.trackerId == null"
                                                    >
                                                        Tracker Unavailable
                                                    </span>
                                                </span>

                                                <md-list slot="md-expand">
                                                    <p
                                                        class="reminder-p"
                                                        v-if="asset != null && asset.trackerId != null"
                                                    >
                                                        <span class="interval-title">Current odometer reading</span>
                                                        <form-group
                                                            name="currentOdometerValue"
                                                            class="short-field distance-field"
                                                        >
                                                            <md-input
                                                                v-model="
                                                                    maintenanceScheduleDetails.currentOdometerValue
                                                                "
                                                            />
                                                        </form-group>
                                                        {{ defaultDistanceUnit }}
                                                    </p>
                                                    <p
                                                        class="reminder-p"
                                                        v-if="
                                                            asset != null &&
                                                                maintenanceScheduleDetails.frequency == 'recurring'
                                                        "
                                                    >
                                                        <span class="interval-title">Next due</span>
                                                        <form-group
                                                            name="nextDueOdometer"
                                                            class="short-field distance-field"
                                                        >
                                                            <md-input
                                                                v-model="maintenanceScheduleDetails.nextDueOdometer"
                                                            />
                                                        </form-group>
                                                        {{ defaultDistanceUnit }}.
                                                    </p>

                                                    <p class="reminder-p">
                                                        <span
                                                            v-if="maintenanceScheduleDetails.frequency == 'recurring'"
                                                        >
                                                            Repeat every
                                                        </span>
                                                        <span v-else>Due</span>
                                                        <form-group
                                                            name="odometerDue"
                                                            class="short-field distance-field"
                                                        >
                                                            <md-input
                                                                v-model="maintenanceScheduleDetails.odometerDue"
                                                            />
                                                        </form-group>
                                                        {{ defaultDistanceUnit }}.
                                                    </p>

                                                    <p class="reminder-p">
                                                        <span>Remind me</span>
                                                        <form-group
                                                            class="short-field distance-field"
                                                            name="odometerBefore"
                                                        >
                                                            <md-input
                                                                v-model="maintenanceScheduleDetails.odometerBefore"
                                                            />
                                                        </form-group>
                                                        {{ defaultDistanceUnit }} before.
                                                    </p>

                                                    <p class="reminder-p">
                                                        <span>Remind me every</span>
                                                        <form-group
                                                            class="short-field distance-field"
                                                            name="odometerAfter"
                                                        >
                                                            <md-input
                                                                v-model="maintenanceScheduleDetails.odometerAfter"
                                                            />
                                                        </form-group>
                                                        {{ defaultDistanceUnit }} after.
                                                    </p>
                                                </md-list>
                                            </md-list-item>
                                        </div>
                                    </div>
                                </md-list>
                            </form-wrapper>
                        </div>
                    </div>
                </wizard-tab>
                <wizard-tab :before-change="validateDetails" :id="'notificationConfiguration'">
                    <template slot="label">
                        Notification Configuration
                    </template>
                    <form-wrapper
                        v-if="step === 'notificationConfiguration'"
                        class="form-wrapper notification-tab-pane"
                        :validator="$v.notificationConfiguration"
                    >
                        <div class="notification-table">
                            <div></div>
                            <div>
                                <span class="notification-title">Email</span>
                            </div>
                        </div>
                        <div class="notification-table">
                            <div>Team Owner</div>
                            <div>
                                <md-checkbox
                                    v-model="maintenanceScheduleDetails.notificationConfiguration.email"
                                    value="Team Owner"
                                ></md-checkbox>
                            </div>
                        </div>
                        <div class="notification-table">
                            <div>Team Manager</div>
                            <div>
                                <md-checkbox
                                    v-model="maintenanceScheduleDetails.notificationConfiguration.email"
                                    value="Team Manager"
                                ></md-checkbox>
                            </div>
                        </div>
                        <div class="notification-table">
                            <div>Assigned Driver</div>
                            <div>
                                <md-checkbox
                                    v-model="maintenanceScheduleDetails.notificationConfiguration.email"
                                    value="Assigned Driver"
                                ></md-checkbox>
                            </div>
                        </div>

                        <div class="notification-table">
                            <div>Additional Emails</div>
                            <div>
                                <md-field class="">
                                    <md-input
                                        v-model="maintenanceScheduleDetails.notificationConfiguration.additionalEmails"
                                        placeholder="test@locate2u.com; maintenance@locate2u.com"
                                    />
                                </md-field>
                            </div>
                        </div>
                    </form-wrapper>
                </wizard-tab>
            </simple-wizard>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, minValue, decimal, integer } from 'vuelidate/lib/validators';
import { SimpleWizard, WizardTab } from '@/components';
import { handleRequests, showErrorMessage, isValidEmail, isEmptyOrSpaces } from '@/helpers';
import { MAINTENANCE_SCHEDULE_DEFAULTS } from '@/utils/defaults';
import ScheduleDetailsStep from '@/pages/Maintenance/StepComponents/ScheduleDetailsStep';
import FrequencyIntervalDropdown from '@/pages/Maintenance/buttons/FrequencyIntervalDropdown';
import moment from 'moment';
import { DATE_TYPES, WEEK_DAYS_CONSTANTS, SPEED_TO_DISTANCE_UNITS } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
    name: 'CreateMaintenanceScheduleWizard',
    components: {
        SimpleWizard,
        WizardTab,
        ScheduleDetailsStep,
        FrequencyIntervalDropdown
    },
    mixins: [GeneralMixin],
    props: {
        existingMaintenanceScheduleData: {
            type: Object,
            default: () => null
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        asset: {
            type: Object,
            default: () => null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            maintenanceScheduleDetails: Object.assign({}, MAINTENANCE_SCHEDULE_DEFAULTS()),
            title: 'Create Maintenance Schedule',
            step: 'maintenanceScheduleDetails',
            disabledDates: (date) => {
                const x = moment(date).format(DATE_TYPES.internationalDate);
                const now = moment().format(DATE_TYPES.internationalDate);
                return moment(x).isBefore(now, 'day');
            },
            recurrence: ['monthly', 'daily', 'annually'],
            intervalNumber: null,
            selectedRecurrence: 'daily',
            weekDays: WEEK_DAYS_CONSTANTS,
            scheduleWeeklyValue: [],
            setTimeReminder: false,
            setEngineHoursReminder: false,
            setOdometerReminder: false,
            defaultSpeedUnit: 'kph',
            defaultDistanceUnit: 'km',
            expandTimeReminder: false,
            expandEngineHours: false,
            expandOdometer: false,
            // nextDueOdometer: null,
            // nextDueEngineHours: null,
            nextServiceDate: null,
            showWizardModal: false
        };
    },
    validations: {
        maintenanceScheduleDetails: {
            maintenanceName: {
                required
            },
            maintenanceType: {
                required
            },
            maintenanceSubType: {
                required
            },
            frequency: {
                required
            },
            timeScheduleBefore: {
                minValue: minValue(1),
                integer
            },
            timeScheduleInterval: {
                minValue: minValue(1),
                integer
            },
            timeScheduleAfter: {
                minValue: minValue(1),
                integer
            },
            engineHoursBefore: {
                minValue: minValue(1),
                decimal
            },
            engineHoursDue: {
                minValue: minValue(1),
                decimal
            },
            engineHoursAfter: {
                minValue: minValue(1),
                decimal
            },
            odometerBefore: {
                minValue: minValue(1),
                decimal
            },
            odometerDue: {
                minValue: minValue(1),
                decimal
            },
            odometerAfter: {
                minValue: minValue(1),
                decimal
            },
            currentEngineHoursValue: {
                minValue: minValue(1),
                decimal
            },
            currentOdometerValue: {
                minValue: minValue(1),
                decimal
            },
            nextDueEngineHours: {
                minValue: minValue(1),
                decimal
            },
            nextDueOdometer: {
                minValue: minValue(1),
                decimal
            }
        },
        notificationConfiguration: {}
    },
    mounted() {
        this.title = !this.isUpdate ? 'Create Maintenance Schedule' : 'Update Scheduled Maintenance';

        if (this.generalSettings.defaultSpeedUnit == null) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Speed unit',
                    body:
                        'No default speed unit is set. Please go to the <a href="/settings/general-settings">general settings</a> under the teams menu and set a default speed unit under the teams tab',
                    buttons: ['Ok']
                })
                .then(async (response) => {
                    this.resolve(false);
                });
        } else if (this.generalSettings.defaultCurrency == null) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Currency',
                    body:
                        'No default currency is set. Please go to <a href="/settings/accounts">accounts</a> under the teams menu and set currency under invoicing tab',
                    buttons: ['Ok']
                })
                .then(async (response) => {
                    this.resolve(false);
                });
        } else {
            this.showWizardModal = true;
        }

        // this is needed so that it will reset everything when modal is closed.
        this.$v.$reset();
        if (this.existingMaintenanceScheduleData != null) {
            this.maintenanceScheduleDetails.maintenanceName = this.existingMaintenanceScheduleData.maintenanceName;
            this.maintenanceScheduleDetails.maintenanceType = this.existingMaintenanceScheduleData.maintenanceType;
            this.maintenanceScheduleDetails.maintenanceSubType = this.existingMaintenanceScheduleData.maintenanceSubType;
            this.maintenanceScheduleDetails.notes = this.existingMaintenanceScheduleData.notes;
            this.maintenanceScheduleDetails.notificationConfiguration = JSON.parse(
                this.existingMaintenanceScheduleData.notificationConfiguration
            );
        }

        if (this.generalSettings.defaultSpeedUnit != null) {
            this.defaultDistanceUnit = SPEED_TO_DISTANCE_UNITS[this.generalSettings.defaultSpeedUnit];
            this.defaultSpeedUnit = this.generalSettings.defaultSpeedUnit;
        }

        if (this.asset != null) {
            this.title = `${this.title} for ${this.asset.name}`;
            this.maintenanceScheduleDetails.currentOdometerValue = this.asset.estimatedOdometerValue;
            this.maintenanceScheduleDetails.currentEngineHoursValue = this.asset.estimatedEngineHourValue;

            if (this.asset.estimatedOdometerUnitType != null) {
                this.defaultSpeedUnit = this.asset.estimatedOdometerUnitType === 'km' ? 'kph' : 'mph';
                this.defaultDistanceUnit = SPEED_TO_DISTANCE_UNITS[this.defaultSpeedUnit];
            }
        }
    },
    watch: {
        'maintenanceScheduleDetails.frequency': function() {
            this.selectedRecurrence = 'daily';
            this.maintenanceScheduleDetails.timeScheduleUnitType = 'days';
        },
        setTimeReminder(newValue) {
            this.expandTimeReminder = newValue;
        },
        setEngineHoursReminder(newValue) {
            this.expandEngineHours = newValue;
        },
        setOdometerReminder(newValue) {
            this.expandOdometer = newValue;
        }
    },
    computed: {
        ...mapGetters({
            generalSettings: 'generalSetting/getGeneralSettings'
        })
    },
    methods: {
        validateDetails() {
            if (this.step === 'notificationConfiguration') {
                this.validateNotificationConfiguration();
            } else {
                return this.validateMaintenanceScheduleDetails();
            }
            return false;
        },
        validateNotificationConfiguration() {
            let hasNotificationReceiver = false;

            if (
                this.maintenanceScheduleDetails.notificationConfiguration.email.length === 0 &&
                this.maintenanceScheduleDetails.notificationConfiguration.additionalEmails == null
            ) {
                showErrorMessage(this, 'Notification configuration is required');
                return;
            }

            const areEmailsValid = this.validateAdditionalEmails(
                this.maintenanceScheduleDetails.notificationConfiguration.additionalEmails
            );
            if (!areEmailsValid) {
                return;
            }

            if (
                this.maintenanceScheduleDetails.notificationConfiguration.email.length > 0 ||
                this.maintenanceScheduleDetails.notificationConfiguration.additionalEmails.length > 0
            ) {
                hasNotificationReceiver = true;
            }

            if (hasNotificationReceiver) {
                if (this.isUpdate) {
                    this.updateMaintenanceSchedule();
                } else {
                    // eslint-disable-next-line no-lonely-if
                    if (this.asset == null) {
                        this.create();
                    } else {
                        this.createAssetSchedule();
                    }
                }
            } else {
                showErrorMessage(this, 'Notification configuration is required');
            }
        },
        validateMaintenanceScheduleDetails() {
            if (this.maintenanceScheduleDetails.frequency == null) {
                showErrorMessage(this, 'Please select recurrence type');
                return false;
            }

            if (
                (!this.setTimeReminder &&
                    !this.setEngineHoursReminder &&
                    !this.setOdometerReminder &&
                    !this.isUpdate) ||
                (this.asset == null &&
                    this.maintenanceScheduleDetails.frequency === 'one-off' &&
                    !this.isUpdate &&
                    (!this.setEngineHoursReminder && !this.setOdometerReminder))
            ) {
                showErrorMessage(this, 'Please select at least one reminder type');
                return false;
            }

            if (!this.isSingleAssetMaintenanceValid() && !this.isUpdate) {
                return false;
            }

            if (
                (!this.isUpdate &&
                    (this.maintenanceScheduleDetails.frequency === 'recurring' &&
                        ((this.selectedRecurrence === 'custom' &&
                            isEmptyOrSpaces(this.maintenanceScheduleDetails.timeScheduleInterval) &&
                            this.maintenanceScheduleDetails.timeScheduleUnitType !== 'weeks') ||
                            (this.scheduleWeeklyValue.length === 0 &&
                                this.maintenanceScheduleDetails.timeScheduleUnitType === 'weeks')))) ||
                (this.setEngineHoursReminder && isEmptyOrSpaces(this.maintenanceScheduleDetails.engineHoursDue)) ||
                (this.setOdometerReminder && isEmptyOrSpaces(this.maintenanceScheduleDetails.odometerDue))
            ) {
                showErrorMessage(this, 'Please set the interval value');
                return false;
            }

            this.$v[this.step].$touch();
            if (!this.$v[this.step].$invalid) {
                return true;
            }
            return false;
        },
        isSingleAssetMaintenanceValid() {
            if (this.isUpdate) 
                return true;

            if (this.asset == null) 
                return true;

            if (this.setEngineHoursReminder && !this.maintenanceScheduleDetails.currentEngineHoursValue) {
                showErrorMessage(this, 'Please set the current engine  hours value');
                return false;
            }

            if (this.setOdometerReminder && !this.maintenanceScheduleDetails.currentOdometerValue) {
                showErrorMessage(this, 'Please set the current odometer value');
                return false;
            }

            if (
                this.setEngineHoursReminder &&
                this.maintenanceScheduleDetails.frequency === 'recurring' &&
                !this.maintenanceScheduleDetails.nextDueEngineHours
            ) {
                showErrorMessage(this, 'Please set next due engine hour value.');
                return false;
            }

            if (
                this.setOdometerReminder &&
                this.maintenanceScheduleDetails.frequency === 'recurring' &&
                !this.maintenanceScheduleDetails.nextDueOdometer
            ) {
                showErrorMessage(this, 'Please set next due odometer value.');
                return false;
            }

            if (this.setTimeReminder && !this.nextServiceDate) {
                showErrorMessage(this, 'Please set the due date/next service date.');
                return false;
            }

            return true;
        },
        generateReminderOffsetString(beforeOffsets, afterOffsets) {
            const offsets = [];

            // TODO: convert this to do foreach once we support multiple pre and post reminders
            if (afterOffsets != null) {
                offsets.push(Math.abs(afterOffsets));
            }

            // TODO: convert this to do foreach once we support multiple pre and post reminders
            if (beforeOffsets != null) {
                offsets.push(-Math.abs(beforeOffsets));
            }

            if (offsets.length === 0) 
                return null;

            return offsets;
        },
        async create() {
            this.$_handleLoaderState(true);

            const transformedData = {
                maintenanceName: this.maintenanceScheduleDetails.maintenanceName,
                maintenanceType: this.maintenanceScheduleDetails.maintenanceType,
                maintenanceSubType: this.maintenanceScheduleDetails.maintenanceSubType,
                timeScheduleReminderOffsetsDays: null,
                timeSchedule: null,
                timeScheduleInterval: null,
                timeScheduleUnitType: null,
                engineHoursReminderOffsets: null,
                engineHoursReminderInterval: null,
                engineHoursReminderValue: null,
                odometerReminderOffsets: null,
                odometerReminderInterval: null,
                odometerReminderValue: null,
                odometerReminderUnitType: null,
                notificationConfiguration: JSON.stringify(this.maintenanceScheduleDetails.notificationConfiguration),
                notes: this.maintenanceScheduleDetails.notes
            };

            this.includeTimeScheduleReminder(transformedData);
            this.includeEngineHoursBasedReminder(transformedData);
            this.includeOdometerReminder(transformedData);

            const api = '/api/maintenance-schedules';
            const payload = {
                method: 'post',
                data: transformedData
            };

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully created a maintenance schedule.',
                    type: 'success'
                });
                this.$v.$reset();
                this.$_handleLoaderState(false);
                this.resolve(true);
            } catch (e) {
                const message = 'Could not create a new maintenance schedule.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
                // this.resolve(false);
            }
        },
        async createAssetSchedule() {
            this.$_handleLoaderState(true);
            const transformedData = {
                maintenanceName: this.maintenanceScheduleDetails.maintenanceName,
                maintenanceType: this.maintenanceScheduleDetails.maintenanceType,
                maintenanceSubType: this.maintenanceScheduleDetails.maintenanceSubType,
                timeScheduleReminderOffsetDays: null,
                timeSchedule: null,
                timeScheduleInterval: null,
                timeScheduleUnitType: null,
                engineHoursReminderOffsets: null,
                engineHoursReminderInterval: null,
                engineHoursReminderValue: null,
                odometerReminderOffsets: null,
                odometerReminderInterval: null,
                odometerReminderValue: null,
                odometerReminderUnitType: null,
                notificationConfiguration: JSON.stringify(this.maintenanceScheduleDetails.notificationConfiguration),
                notes: this.maintenanceScheduleDetails.notes,
                currentOdometerValue: this.maintenanceScheduleDetails.currentOdometerValue,
                currentEngineHoursValue: this.maintenanceScheduleDetails.currentEngineHoursValue,
                nextDueEngineHours: null,
                nextDueOdometer: null,
                nextOdometerUnitType: null,
                nextServiceDate: null
            };

            this.includeTimeScheduleReminder(transformedData);
            this.includeEngineHoursBasedReminder(transformedData);
            this.includeOdometerReminder(transformedData);

            const api = `/api/assets/${this.asset.assetId}/scheduled-maintenance`;
            const payload = {
                method: 'post',
                data: transformedData
            };

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully created a maintenance schedule.',
                    type: 'success'
                });
                this.$v.$reset();
                this.$_handleLoaderState(false);
                this.resolve(true);
            } catch (e) {
                const message = 'Could not create a new maintenance schedule.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        async updateMaintenanceSchedule() {
            this.$_handleLoaderState(true);

            const transformedData = {
                maintenanceName: this.maintenanceScheduleDetails.maintenanceName,
                maintenanceType: this.maintenanceScheduleDetails.maintenanceType,
                maintenanceSubType: this.maintenanceScheduleDetails.maintenanceSubType,
                notificationConfiguration: JSON.stringify(this.maintenanceScheduleDetails.notificationConfiguration),
                notes: this.maintenanceScheduleDetails.notes
            };

            const api = `/api/maintenance-schedules/${this.existingMaintenanceScheduleData.assetMaintenanceScheduleId}`;
            const payload = {
                method: 'put',
                data: transformedData
            };

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully updated the maintenance schedule.',
                    type: 'success'
                });
                this.$v.$reset();
                this.$_handleLoaderState(false);
                this.resolve(true);
            } catch (e) {
                const message = 'Could not update the maintenance schedule.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        includeTimeScheduleReminder(transformedData) {
            if (!this.setTimeReminder) 
                return;

            transformedData.timeScheduleReminderOffsetDays = this.generateReminderOffsetString(
                this.maintenanceScheduleDetails.timeScheduleBefore,
                this.maintenanceScheduleDetails.timeScheduleAfter
            );

            if (this.asset != null) {
                transformedData.nextServiceDate = this.$options.filters.dateFormat(
                    this.nextServiceDate,
                    this.DATE_TYPES.internationalDate
                );
            }

            const today = moment().format(DATE_TYPES.internationalDate);
            let specifiedDay = null;

            if (this.maintenanceScheduleDetails.frequency === 'recurring') {
                // eslint-disable-next-line no-lonely-if
                if (this.selectedRecurrence === 'custom') {
                    if (this.maintenanceScheduleDetails.timeScheduleUnitType === 'weeks') {
                        transformedData.timeSchedule = `0 0 * * ${this.scheduleWeeklyValue.join(',')}`;
                    } else {
                        // eslint-disable-next-line radix
                        transformedData.timeScheduleInterval = parseInt(
                            this.maintenanceScheduleDetails.timeScheduleInterval
                        );
                        transformedData.timeScheduleUnitType = this.maintenanceScheduleDetails.timeScheduleUnitType;
                    }
                } else {
                    switch (this.selectedRecurrence) {
                        case 'daily':
                            transformedData.timeSchedule = '0 0 * * *';
                            break;
                        case 'weekly':
                            // eslint-disable-next-line no-case-declarations
                            specifiedDay = moment(today).format('ddd');
                            transformedData.timeSchedule = `0 0 * * ${specifiedDay}`;
                            break;
                        case 'monthly':
                            // eslint-disable-next-line no-case-declarations
                            specifiedDay = moment(today).format('D');
                            transformedData.timeSchedule = `0 0 ${specifiedDay} * *`;
                            break;
                        case 'yearly':
                            // eslint-disable-next-line no-case-declarations
                            const specifiedMonth = moment(today).format('MMM');
                            // eslint-disable-next-line no-case-declarations
                            const specifiedDate = moment(today).format('D');
                            transformedData.timeSchedule = `0 0 ${specifiedDate} ${specifiedMonth} *`;
                            break;
                        default:
                            transformedData.timeSchedule = null;
                            break;
                    }
                }
            } else {
                transformedData.timeScheduleUnitType = null;
            }
        },
        includeEngineHoursBasedReminder(transformedData) {
            if (!this.setEngineHoursReminder) 
                return;

            transformedData.engineHoursReminderOffsets = this.generateReminderOffsetString(
                this.maintenanceScheduleDetails.engineHoursBefore,
                this.maintenanceScheduleDetails.engineHoursAfter
            );
            if (this.maintenanceScheduleDetails.frequency !== 'one-off') {
                transformedData.engineHoursReminderInterval =
                    this.maintenanceScheduleDetails.engineHoursDue != null
                        ? parseFloat(this.maintenanceScheduleDetails.engineHoursDue)
                        : null;

                if (this.asset != null) {
                    transformedData.nextDueEngineHours = parseFloat(this.maintenanceScheduleDetails.nextDueEngineHours);
                }
            } else {
                transformedData.engineHoursReminderValue =
                    this.maintenanceScheduleDetails.engineHoursDue != null
                        ? parseFloat(this.maintenanceScheduleDetails.engineHoursDue)
                        : null;

                if (this.asset != null) {
                    transformedData.nextDueEngineHours = parseFloat(this.maintenanceScheduleDetails.engineHoursDue);
                }
            }
        },
        includeOdometerReminder(transformedData) {
            if (!this.setOdometerReminder) 
                return;

            transformedData.odometerReminderUnitType = this.defaultDistanceUnit;

            transformedData.odometerReminderOffsets = this.generateReminderOffsetString(
                this.maintenanceScheduleDetails.odometerBefore,
                this.maintenanceScheduleDetails.odometerAfter
            );
            if (this.maintenanceScheduleDetails.frequency !== 'one-off') {
                transformedData.odometerReminderInterval =
                    this.maintenanceScheduleDetails.odometerDue != null
                        ? parseFloat(this.maintenanceScheduleDetails.odometerDue)
                        : null;

                if (this.asset != null) {
                    transformedData.nextDueOdometer = parseFloat(this.maintenanceScheduleDetails.nextDueOdometer);
                    transformedData.nextOdometerUnitType = this.defaultDistanceUnit;
                }
            } else {
                transformedData.odometerReminderValue =
                    this.maintenanceScheduleDetails.odometerDue != null
                        ? parseFloat(this.maintenanceScheduleDetails.odometerDue)
                        : null;

                if (this.asset != null) {
                    transformedData.nextDueOdometer = parseFloat(this.maintenanceScheduleDetails.odometerDue);
                    transformedData.nextOdometerUnitType = this.defaultDistanceUnit;
                }
            }
        },
        stepChange(stepName) {
            this.step = stepName;
        },
        handleRecurrenceUpdate(optionsData) {
            this.scheduleWeeklyValue = [];
            this.selectedRecurrence = optionsData;
        },
        validateAdditionalEmails(list) {
            if (isEmptyOrSpaces(list)) 
                return true;

            let isValid = true;
            let emailList = list.replace(/\s/g, '');
            emailList = emailList.replace(/;/g, ',');
            emailList = emailList.split(',');

            emailList.forEach((element) => {
                if (!isValidEmail(element)) {
                    isValid = false;
                    showErrorMessage(this, `${element} is not a valid email`);
                }
            });
            return isValid;
        },
        setEngineHoursReminderTitle() {
            if (this.asset != null && this.asset.trackerId == null) 
                return;

            this.setEngineHoursReminder = !this.setEngineHoursReminder;
        },
        setOdometerReminderTitle() {
            if (this.asset != null && this.asset.trackerId == null) 
                return;

            this.setOdometerReminder = !this.setOdometerReminder;
        },
        setTimeReminderTitle() {
            this.setTimeReminder = !this.setTimeReminder;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-card-content {
    padding-bottom: 5px;
}
.overflow-container {
    overflow: auto;
    max-height: 50vh;
    margin-bottom: 10px;
    > div {
        margin-bottom: 20px;
    }
}
.p-border {
    border-bottom: 1px solid #eee;
}
.modal-container {
    max-width: 800px;
    @media (min-width: 1440px) {
        max-width: 1100px;
    }
    .modal-header {
        padding: 0;
        button {
            z-index: 99;
        }
    }
    .modal-body {
        padding-top: 0;
        max-height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
        h5 {
            font-weight: 400;
            text-transform: uppercase;
            font-size: 15px;
        }

        ::v-deep .md-card {
            margin: 0 !important;
            background-color: #fff;
            box-shadow: none;

            .md-card-content {
                overflow-y: visible;
                padding: 20px 0 0 0;
                // max-height: 435px;

                .md-size-50:first-child {
                    padding-left: 0;
                    padding-right: 30px;
                }

                .md-size-50:last-child {
                    padding-left: 30px;
                    padding-right: 0;
                }
            }
        }

        ::v-deep .tab-content {
            padding: 0 15px 0 15px !important;
        }
        .md-icon-button,
        ::v-deep .md-icon-button {
            background-color: #2b93ff !important;
        }
    }
}

.short-field {
    width: 50px;
    display: inline-block;
    padding: 0 5px;
    margin: 0;
    > input {
        text-align: center;
        width: 100%;
    }
}

.distance-field {
    width: 80px;
}

.date-field {
    width: 200px;

    ::v-deep .md-clear {
        i > svg {
            fill: #fff !important;
        }
    }
}

::v-deep .md-collapse.is-active .md-collapse-label {
    .md-collapse-title {
        color: #2b93ff !important;
    }
}

::v-deep .md-collapse .md-collapse-label:hover {
    .md-collapse-title {
        color: #2b93ff !important;
    }
}

.reminder-p {
    padding-bottom: 0;
}

.notification-table {
    display: table;
    width: 100%;
    > div {
        display: table-cell;
        vertical-align: middle;
    }
    > div:first-child {
        width: 200px;
        padding-top: 3px;
    }
    > div:last-child {
        max-width: 300px;
    }
    .notification-title {
        font-weight: 500;
    }
}

.field-box-value {
    display: inline-block;
    input {
        width: 100%;
        max-width: 50px;
        text-align: center;
    }
    > div {
        display: inline-block;
    }

    .custom-field-input {
        width: 50px;
        margin-top: 0 !important;
        padding-bottom: 0;
        input {
            padding-bottom: 0;
        }
        // margin-left: 10px;
    }

    .custom-field-input:before,
    .custom-field-input:after {
        bottom: 1px;
    }

    .weekly-box {
        display: initial;
    }
}

.field-select {
    display: inline-block;
    width: 100px;
    margin: 0 !important;
    padding-bottom: 0;
}

.field-select:before,
.field-select:after {
    bottom: 5px;
}

.recurrence-container {
    margin-top: 22px;
    margin-bottom: 10px;
    font-weight: 400;

    span {
        margin-right: 10px;
    }
    ::v-deep .md-radio {
        margin: 0 30px 0 0;
    }

    .form-group-no-underline ::v-deep label {
        top: 1px;
    }

    .form-group-no-underline:after {
        height: 0;
    }

    ::v-deep label {
        font-weight: 400;
        color: black !important;
    }
}

.custom-accordion {
    display: table;
    > div {
        display: table-cell;
        vertical-align: top;
        .reminder-p {
            margin-bottom: 0;
        }
    }
    .accordion-btn {
        min-width: 20px;
        height: 20px;
        width: 20px;
        background-color: initial !important;
    }
    .accordion-checkbox {
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 10px;
    }
    ::v-deep .md-card-actions {
        border-bottom: 1px solid #ddd;
        margin-left: 5px;
    }
}

::v-deep .md-expand-active {
    // min-height: 300px;
}

.card-expansion {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 300px;
}

.custom-accordion-list {
    > div {
        display: table;
        width: 100%;
        position: relative;

        .reminder-p {
            margin-bottom: 0;
        }

        > div {
            display: table-cell;
            vertical-align: top;
        }
        .container-checkbox {
            // vertical-align: top;
            width: 30px;

            ::v-deep .md-checkbox {
                margin: 0;
                margin-top: 2px;
            }
        }

        .container-dropdown {
            ::v-deep .md-list-item-text {
                display: contents;
            }

            ::v-deep .md-list-item-content {
                padding: 0;
                min-height: initial;
                font-size: 14px;
            }

            ::v-deep .md-list-item-content:hover {
                background-color: initial !important;
            }

            ::v-deep .md-list-item-expand {
                border: none;
            }
        }
        .disabled-overlay {
            top: 0;
            left: 35px;
            position: absolute;
            height: 35px;
            width: 100%;
            z-index: 99;
        }

        .reminder-p {
            font-size: 14px;
            span {
                width: 114px;
                display: inline-block;
                text-align: left;
            }
        }
    }

    .context-menu-support-accordion {
        //to fix the dropdown being chopped off when inside the table
        overflow-x: initial !important;

        ::v-deep .md-list-item {
            overflow: initial;
            // z-index: 999;
            z-index: 9;
        }

        ::v-deep .md-list-item-container {
            overflow: initial;
        }
        ::v-deep .md-list-expand {
            overflow: initial !important;
        }

        ::v-deep .md-list {
            overflow-x: visible !important;
            overflow-y: visible !important;
        }
    }

    ::v-deep .md-list-expand {
        display: none;
    }

    ::v-deep .md-list-item-expand.md-active {
        .md-list-expand {
            display: block;
        }
    }

    ::v-deep .md-list-item-content .md-ripple-wave {
        display: none;
    }

    .weekly-box {
        color: black;
        display: inline-block;
        width: 100%;
        ::v-deep .md-checkbox {
            color: black;
            display: table-cell;
        }

        ::v-deep .md-checkbox .md-checkbox-label {
            padding-left: 0;
            margin-right: 10px;
            color: black;
            font-size: 14px;
            width: 27px;
            display: block;
        }
        .reminder-p {
            margin-bottom: 10px;
        }
        .checkbox-container {
            margin-top: 10px;
        }
    }

    .custom-error-message {
        font-size: 12px;
        color: #ff1744;
    }
}

.custom-blue-icon {
    color: #2b93ff;
    vertical-align: middle;
}

.notification-tab-pane {
    padding: 0 15px;
}

.short-field:after,
.short-field:before {
    bottom: 7px;
}

.frequency-dropdown {
    border-color: #aaa;
    ::v-deep .status {
        color: black !important;
    }
}

.custom-md-field {
    display: inline-block;
    max-width: 150px;
    margin-left: 10px;
    input {
        text-align: center;
    }
}

.modal-container .modal-body .custom-md-field {
    ::v-deep .md-icon-button {
        background-color: transparent !important;
    }
}

.asset-reading-info {
    margin-bottom: 10px;
    > div {
        display: inline-block;
        width: calc((100% - 15px) / 2);
        margin-right: 15px;
        max-width: 200px;
        vertical-align: top;
    }
    > div:last-child {
        margin-right: 0;
    }
}

.custom-date-picker-field {
    .md-datepicker {
        margin-top: 0;
        margin-left: -37px;
    }
    ::v-deep i {
        visibility: hidden;
    }

    ::v-deep button {
        visibility: hidden;
    }

    ::v-deep input {
        padding-bottom: 0;
    }
}

.interval-title {
    // margin-right: 10px;
    width: 114px !important;
}

.unavailable-note {
    color: #aaa;
    font-size: 12px;
    margin-left: 10px;
}

.time-sched-input {
    ::v-deep .md-invalid {
        .md-error {
            padding-top: 10px !important;
        }
    }
}

.custom-date-picker-field {
    margin-left: 0;
}

.one-time-field {
    .interval-title {
        width: 125px !important;
    }

    .reminder-p > span {
        width: 125px !important;
    }
}

.eh-one-time-field {
    .interval-title {
        width: 155px !important;
    }

    .reminder-p > span {
        width: 155px !important;
    }
}

.odometer-custom-field {
    .reminder-p > span {
        width: 172px !important;
    }
}
</style>
