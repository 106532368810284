var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.routeDisplay !== "None"
    ? _c(
        "div",
        { staticClass: "stop-list map-panel map-panel-stop-list" },
        [
          _c(
            "md-card-content",
            { staticClass: "stop-table-body" },
            [
              _vm.stopList.length > 0
                ? _c(
                    "md-table",
                    {
                      staticClass:
                        "context-menu-support custom-paginated-table",
                    },
                    [
                      _c(
                        "md-table-row",
                        [
                          _c("md-table-head"),
                          _c("md-table-head", [_vm._v("Address")]),
                          _c("md-table-head", [_vm._v("Status")]),
                          _c("md-table-head", { staticClass: "eta-header" }, [
                            _vm._v("ETA/Arrival"),
                          ]),
                          _c("md-table-head"),
                        ],
                        1
                      ),
                      _vm._l(_vm.stopList, function (stopItem, stopIndex) {
                        return _c(
                          "md-table-row",
                          {
                            key: stopIndex,
                            staticClass: "stop-list-row",
                            class: {
                              "table-danger": stopItem.isOfferStop,
                              "offer-stop": stopItem.isOfferStop,
                            },
                            on: {
                              mouseover: function ($event) {
                                return _vm.onStopHighlighted(stopItem)
                              },
                              mouseout: function ($event) {
                                return _vm.onStopHighlighted(null)
                              },
                            },
                          },
                          [
                            _c("md-table-cell", { staticClass: "stop-order" }, [
                              _vm._v(_vm._s(stopIndex + 1) + "."),
                            ]),
                            _c(
                              "md-table-cell",
                              { staticClass: "stop-address" },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.formatStopAddressDesc(stopItem)
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _c("md-table-cell", [
                              _c(
                                "span",
                                {
                                  staticClass: "status",
                                  class:
                                    "status-" +
                                    stopItem.status
                                      .toLowerCase()
                                      .replace(/\s+/g, ""),
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.fixStopStatus(stopItem)) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]),
                            _c("md-table-cell", { staticClass: "stop-time" }, [
                              ["On Hold", "Cancelled"].indexOf(
                                stopItem.status
                              ) === -1
                                ? _c("div", [
                                    ["Arrived", "Complete", "Failed"].indexOf(
                                      stopItem.status
                                    ) !== -1
                                      ? _c(
                                          "span",
                                          { staticClass: "bold-time" },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm._f("timeFormat")(
                                                    stopItem.arrivalDate,
                                                    _vm.dateTypes.standardTime
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        )
                                      : stopItem.etaTime
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(stopItem.etaTime) +
                                              "\n                        "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm._f("timeFormat")(
                                                  stopItem.originalEta,
                                                  _vm.dateTypes.standardTime
                                                )
                                              ) +
                                              "\n                        "
                                          ),
                                        ]),
                                  ])
                                : _vm._e(),
                            ]),
                            _c(
                              "md-table-cell",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Stop Details",
                                        params: { stopId: stopItem.stopId },
                                      },
                                      target: "_blank",
                                      title: "Stop details",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return $event.stopImmediatePropagation()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "md-icon",
                                      { staticClass: "link-icon" },
                                      [
                                        _vm._v(
                                          "\n                            open_in_new\n                        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _c("div", { staticClass: "empty-table" }, [
                    _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.pinnedDriverDetails
                              ? _vm.pinnedDriverDetails.firstName
                              : "This driver"
                          ) +
                          " has no stops assigned for\n                today\n            "
                      ),
                    ]),
                  ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }