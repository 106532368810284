import { DATE_TYPES, SPEED_MULTIPLIER, GPS_COORDINATE_CONSTANT } from '@/utils/constants';
import { isAValidCoordinate, isEmptyOrSpaces } from '@/helpers';
import i18n from '@/i18n';

import { reactive, toRefs } from '@vue/composition-api';
// eslint-disable-next-line import/prefer-default-export
function useGeneralUtils(root) {
    const event = reactive({
        dateTypes: DATE_TYPES,
        speedMultiplier: SPEED_MULTIPLIER,
        gpsCoordinateConstant: GPS_COORDINATE_CONSTANT
    });

    function handleLoaderState(state, loadingText = i18n.t('common.loadingText')) {
        const loader = {
            isLoading: state,
            loadingText
        };

        root.$store.commit('CHANGE_LOADING_STATE', loader, { root: true });
    }

    function setDefaultBrokenImage(event) {
        event.target.src = root.$root.defaultBrokenImage;
    }

    function displayAddressName({ address, name: stopName }) {
        if (isAValidCoordinate(address)) {
            if (stopName === undefined || isEmptyOrSpaces(stopName)) {
                return GPS_COORDINATE_CONSTANT;
            }

            return stopName;
        }

        return address;
    }

    return { ...toRefs(event), handleLoaderState, setDefaultBrokenImage, displayAddressName };
}

export default useGeneralUtils;
