<template>
    <div class="dashboard">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.teamRegions') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-end">
                        <search-component ref="search" @onSearch="handleSearch" />
                        <!-- filters and action buttons -->
                        <div class="header-button-container">
                            <md-button
                                title="Create Team Region"
                                class="md-primary md-just-icon md-round"
                                @click="createTeamRegion"
                            >
                                <md-icon>add</md-icon>
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>crop</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div v-if="isListLoading" class="empty-table">
                            <div class="table-loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="teamRegionList && teamRegionList.length">
                                <md-table class="context-menu-support custom-paginated-table">
                                    <md-table-row>
                                        <md-table-head>Name</md-table-head>
                                        <!--<md-table-head>Parent</md-table-head>-->
                                        <md-table-head class="end">Actions</md-table-head>
                                    </md-table-row>
                                    <md-table-row
                                        v-for="(item, index) in teamRegionList"
                                        :key="item.teamRegionId"
                                        @click.stop="showSidebar(item.teamRegionId)"
                                        :class="index > 0 && item.level === 1 ? `option-level-1` : ''"
                                    >
                                        <md-table-cell :class="item.level !== 1 ? `option-level-${item.level}` : ''">
                                            {{ item.name }}
                                        </md-table-cell>
                                        <!--<md-table-cell>
                                        <span v-if="item.parentName !== item.name">{{ item.parentName }}</span>
                                    </md-table-cell>-->
                                        <md-table-cell class="action-buttons">
                                            <md-button
                                                title="Update team region details"
                                                class="md-warning md-just-icon md-round"
                                                @click.stop="updateTeamRegion(item.teamRegionId)"
                                            >
                                                <md-icon>edit</md-icon>
                                            </md-button>
                                            <md-button
                                                title="Delete team region"
                                                class="md-danger md-just-icon md-round"
                                                @click.stop="handleDeleteTeamRegion(item.teamRegionId)"
                                            >
                                                <md-icon>delete</md-icon>
                                            </md-button>
                                        </md-table-cell>
                                    </md-table-row>
                                </md-table>
                            </div>
                            <div v-else>
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
                <md-card-actions class="page-footer" md-alignment="space-between">
                    <div>
                        <p v-if="total === pagination.perPage" class="card-category">
                            Page {{ pagination.currentPage }} of many
                        </p>
                        <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                    </div>
                    <pagination
                        v-model="pagination.currentPage"
                        class="pagination-no-border pagination-success"
                        :per-page="pagination.perPage"
                        :total="total"
                        @change-page="handleChangePage($event, pagination.perPage)"
                    />
                </md-card-actions>
            </div>

            <transition name="slide">
                <team-region-sidebar
                    v-if="isShowSidebar"
                    :team-region-id="teamRegionId"
                    v-click-outside="toggleSidebarWindow"
                    @closeModal="toggleSidebarWindow"
                />
            </transition>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { TEAM_REGION_DEFAULTS, PAGINATION_DEFAULTS } from '@/utils/defaults';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { Pagination, SearchComponent } from '@/components';
import { mapActions, mapGetters } from 'vuex';
import CreateTeamRegionModal from './Modals/CreateTeamRegionModal';
import TeamRegionSidebar from './TeamRegionSidebar';

export default {
    name: 'TeamRegionOverview',
    mixins: [GeneralMixin],
    components: {
        FadeLoader,
        Pagination,
        SearchComponent,
        TeamRegionSidebar
    },
    data() {
        return {
            teamRegionList: [],
            isListLoading: false,
            pagination: PAGINATION_DEFAULTS,
            filters: { status: 'active' },
            maxPage: 1,
            isShowSidebar: false,
            teamRegionId: null,
            selectedFile: null,
            hasError: false,
            errorMessages: []
        };
    },
    computed: {
        ...mapGetters({
            isLoading: 'isLoading'
        }),
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        }
    },
    async mounted() {
        const currentPage = Number(this.$route.query.currentPage) || 1;
        if (currentPage) {
            this.pagination.currentPage = currentPage;
        }

        const {
            data: { teamRegions, totalTeamRegions }
        } = await this.getTeamRegionList(currentPage);

        this.setTeamRegionsAndTotal(teamRegions, totalTeamRegions);
    },
    methods: {
        ...mapActions('team', ['FETCH_TEAM_REGIONS']),
        async getTeamRegionList(pageNumber = 1, itemsPerPage = 50) {
            let response = {
                data: {
                    teamRegions: [],
                    totalTeamRegions: null
                }
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/teams/team-regions/list`;
                response = await handleRequests(endpoint, {
                    params: {
                        pageNumber,
                        itemsPerPage,
                        ...this.cleanFilters()
                    }
                });
            } catch (error) {
                const message = 'Error in getting the team region list';
                showErrorMessage(this, message, error);
            } finally {
                this.isListLoading = false;
            }

            return response;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },
        setTeamRegionsAndTotal(data, total) {
            this.teamRegionList = data;
            this.pagination.total = total || data.length;
        },
        async createTeamRegion() {
            const teamRegion = { ...TEAM_REGION_DEFAULTS() };

            this.$modal
                .show(CreateTeamRegionModal, {
                    teamRegionModel: teamRegion
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.handleChangePage(1, this.pagination.perPage);
                        this.$modal.hide();
                    }
                });
        },
        getMaxPage(page) {
            this.maxPage = page;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            const {
                data: { teamRegions, totalTeamRegions }
            } = await this.getTeamRegionList(currentPage, perPage);
            this.setTeamRegionsAndTotal(teamRegions, totalTeamRegions);
        },
        showSidebar(teamRegionId) {
            this.isShowSidebar = true;
            this.teamRegionId = teamRegionId;
        },
        toggleSidebarWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown)
                this.isShowSidebar = !this.isShowSidebar;
        },
        async getTeamRegionDetails(teamRegionId) {
            try {
                const api = `/api/teams/team-regions/${teamRegionId}`;
                const { data } = await handleRequests(api);
                return data;
            } catch (error) {
                const message = 'Error getting team region details.';
                showErrorMessage(this, message, error);
            }
            return null;
        },
        async updateTeamRegion(teamRegionId) {
            const teamRegion = await this.getTeamRegionDetails(teamRegionId);

            if (!teamRegion) 
                return;

            this.$modal
                .show(CreateTeamRegionModal, {
                    teamRegionModel: { ...teamRegion },
                    isUpdate: true
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.handleChangePage(1, this.pagination.perPage);
                        this.$modal.hide();

                        // update store
                        this.FETCH_TEAM_REGIONS();
                    }
                });
        },
        async deleteTeamRegion(teamRegionId) {
            const payload = {
                method: 'delete'
            };
            const api = `/api/teams/team-regions/${teamRegionId}`;
            try {
                await handleRequests(api, payload);
                this.FETCH_TEAM_REGIONS();
                this.$notify({
                    message: 'Team Region was deleted!',
                    type: 'success'
                });
                this.handleChangePage(1, this.pagination.perPage);
            } catch (e) {
                const message = 'Error in deleting a Team Region.';
                showErrorMessage(this, message, e);
            } finally {
                this.isListLoading = false;
            }
        },
        handleDeleteTeamRegion(teamRegionId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Team Region',
                    body: 'Are you sure you want to delete this team region?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isListLoading = true;
                        await this.deleteTeamRegion(teamRegionId);
                    }
                });
        },
        async handleSearch(data) {
            this.filters = Object.assign(this.filters, data);
            const {
                data: { teamRegions, totalTeamRegions }
            } = await this.getTeamRegionList(1, this.pagination.perPage);

            this.setTeamRegionsAndTotal(teamRegions, totalTeamRegions);
        }
    }
};
</script>

<style lang="scss" scoped>
.download-btn {
    color: #2b93ff !important;
    margin-top: 5px;
    display: block;
    font-size: 12px;
}

::v-deep .error-body div {
    margin: 0;
    margin-top: 20px;
    padding: 10px;
}

.dashboard ::v-deep .md-card {
    margin-bottom: 0;
}

.green-icon {
    color: #0bda8e !important;
    margin-right: 5px;
}

.md-table-head.end {
    text-align: right;
}

::v-deep .md-table-cell.action-buttons {
    padding-right: 30px;
}
.option-level-1 {
    border-top: 2px solid #b4b0b0;
}
.option-level-2 ::v-deep .md-table-cell-container {
    margin-left: 35px !important;
}
.option-level-3 ::v-deep .md-table-cell-container {
    margin-left: 70px !important;
}
.custom-paginated-table .action-buttons {
    width: 100px;
}
</style>
