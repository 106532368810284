<template>
    <div>
        <md-button class="md-primary pull-right header-button" @click="updateStop" :disabled="!teamMembers.length">
            Edit
        </md-button>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import UpdateStopModal from './UpdateStopModal';

export default {
    name: 'UpdateStop',
    mixins: [GeneralMixin],
    props: {
        teamMembers: {
            type: Array,
            default: () => []
        },
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            stopDetails: {}
        };
    },
    methods: {
        updateStop() {
            this.stopDetails = Object.assign({}, this.data);

            this.$modal
                .show(UpdateStopModal, {
                    members: this.teamMembers,
                    stop: this.stopDetails
                })
                .then((response) => {
                    if (response.toLowerCase() === 'ok') 
                        this.emitStopUpdated();
                    this.$modal.hide();
                });
        },
        emitStopUpdated() {
            this.$emit('stopUpdated');
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    margin-right: 16px;
}
</style>
