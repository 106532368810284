var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.quotingStatusOptions
    ? _c("vue-select", {
        attrs: {
          reduce: function (x) {
            return x.value
          },
          label: "text",
          options: _vm.quotingStatusOptions,
          placeholder: "Quoting Status",
        },
        model: {
          value: _vm.selectedQuotingStatus,
          callback: function ($$v) {
            _vm.selectedQuotingStatus = $$v
          },
          expression: "selectedQuotingStatus",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }