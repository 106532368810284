<template>
    <div class="container-overview">
        <div class="container-component" v-if="selectedView == 'quotes'">
            <CarrierQuotesview @selectedView="handleView" :is-quotes-view="selectedView == 'quotes'" />
        </div>
        <div class="container-component" v-else>
            <ShipmentList @selectedView="handleView" />
        </div>
    </div>
</template>
<script>
import CarrierQuotesview from './CarrierQuotesView';
import ShipmentList from './ShipmentList';

export default {
    name: 'ShipmentOverview',
    components: {
        CarrierQuotesview,
        ShipmentList
    },
    data() {
        return {
            selectedView: null
        };
    },
    methods: {
        handleView(value) {
            this.selectedView = value;
        }
    }
};
</script>

<style lang="scss" scoped>
.aggregate-options {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.container-overview {
    margin-top: -30px;

    .container-component {
        padding-top: 30px;
    }
}
</style>
