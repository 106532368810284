<template>
    <div class="modal-container">
        <div class="modal-header">
            Send Test SMS
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.form" class="form-wrapper">
                <form-group label="Recipient Phone Number" name="testPhoneNumber">
                    <md-input v-model="form.testPhoneNumber" />
                </form-group>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="sendSms">
                Send
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'SendTestSmsModal',
    mixins: [GeneralMixin],
    props: {
        api: {
            type: String,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            form: {
                testPhoneNumber: ''
            }
        };
    },
    methods: {
        async sendSms() {
            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                this.$_handleLoaderState(true, 'SENDING...');
                const payload = {
                    method: 'post',
                    data: { testPhoneNumber: this.form.testPhoneNumber }
                };
                const apiUrl = `${this.api}`;
                try {
                    await handleRequests(apiUrl, payload);
                    this.$notify({
                        message: 'Test SMS sent!',
                        type: 'success'
                    });
                    this.resolve();
                } catch (e) {
                    const message = 'Unable to send test SMS.';
                    showErrorMessage(this, message, e);
                }
                this.$_handleLoaderState(false);
            }
        }
    },
    validations: {
        form: {
            testPhoneNumber: {
                required_testPhoneNumber: required
            }
        }
    }
};
</script>
