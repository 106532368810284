<template>
    <div class="filter-inline" v-if="chargingTypeList.length > 0">
        <form-group name="service" :label="labelName">
            <md-input v-model="selectedOption" type="hidden" />
            <vue-select
                :reduce="(r) => r"
                label="rateGroupName"
                :options="chargingTypeList"
                v-model="selectedOption"
                :searchable="$root.isDesktop"
            ></vue-select>
        </form-group>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';

export default {
    name: 'ChargingTypeOptions',
    props: {
        selected: {
            type: Number,
            default: null
        },
        selectedChargeTypeId: {
            type: [String, Number, Object],
            default: null
        },
        labelName: {
            type: String,
            default: 'Service Package'
        }
    },
    data() {
        return {
            chargingTypeList: [],
            selectedOption: null,
            rateGroupName: null
        };
    },
    computed: {
        ...mapGetters({
            teamRegions: 'team/teamRegions',
            user: 'user/user'
        })
    },
    async mounted() {
        this.chargingTypeList = [...(await this.getRateGroupsList())];

        if (this.chargingTypeList.length > 0) {
            this.$emit('hasRateGroups', true);
        }

        if (this.selectedChargeTypeId != null) {
            this.selectedOption = this.chargingTypeList.find((x) => x.rateGroupId === this.selectedChargeTypeId);
        }
    },
    watch: {
        selectedOption(value) {
            if (value === null) {
                this.getSelectedOption(value);
            } else if (this.selectedChargeTypeId !== this.selectedOption?.rateGroupId) {
                this.getSelectedOption(value);
            }
        },
        selectedChargeTypeId(value) {
            if (value) {
                this.selectedOption = this.chargingTypeList.find((x) => x.rateGroupId === value);
            } else {
                this.selectedOption = null;
            }
        }
    },
    methods: {
        getSelectedOption(value) {
            this.$emit('selectedOption', value === 0 ? null : value);
        },
        async getRateGroupsList() {
            let response = {
                data: []
            };

            try {
                this.isListLoading = true;
                const endpoint = `/api/rate-groups/rateRules`;

                response = await handleRequests(endpoint);
            } catch (error) {
                const message = 'Error in getting the rate groups list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;

            return response.data.map((rg) => {
                return {
                    rateGroupId: rg.rateGroupId,
                    rateGroupName: rg.rateGroupName,
                    rateGroupType: rg.ruleType
                };
            });
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}

::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}

::v-deep .vs__search {
    cursor: pointer;
}

.status-filter {
    width: 180px !important;
}

.state-filter {
    margin-right: 20px;
}
</style>
