<template>
    <footer class="footer">
        <div class="container">
            <div class="copyright text-center">
                Powered by
                <a href="https://www.locate2u.com" target="_blank">
                    <img src="/img/logos/locate2u-logo-dark.png" />
                </a>
            </div>
        </div>
    </footer>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
    padding: 0;
    .container {
        display: block;
        text-align: center;
    }
}
.copyright {
    a {
        color: #2b93ff;
        img {
            width: 100px;
            margin-left: 5px;
            margin-top: 5px;
        }
    }
}
</style>
