<template>
    <div class="modal-container" @keydown.esc="$modal.hide" tabindex="0">
        <div class="modal-header">
            <h4 class="modal-title">{{ isUpdate ? 'Update Account Code' : 'Create Account Code' }}</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>

        <div class="modal-body">
            <form-wrapper :validator="$v.accountCodeDetails" class="form-wrapper">
                <form novalidate @submit.prevent="validateService">
                    <div v-if="isUpdate">
                        <div>
                            <form-group name="type" label="Type" attribute="Type" :messages="localMessages">
                                <md-select v-model="accountCodeDetails.type">
                                    <md-option v-for="item in accountCodeTypes" :key="item" :value="item">
                                        {{ item }}
                                    </md-option>
                                </md-select>
                            </form-group>
                        </div>
                    </div>
                    <div>
                        <div>
                            <form-group
                                name="accountCode"
                                label="Account Code"
                                attribute="Account Code"
                                :messages="localMessages"
                            >
                                <md-input v-focus v-model="accountCodeDetails.code" required />
                            </form-group>
                        </div>
                    </div>
                    <div>
                        <div>
                            <form-group
                                name="description"
                                label="Description"
                                attribute="Description"
                                :messages="localMessages"
                            >
                                <md-input v-model="accountCodeDetails.description" />
                            </form-group>
                        </div>
                    </div>
                    <div v-if="!isUpdate">
                        <div>
                            <form-group name="type" label="Type" attribute="Type" :messages="localMessages">
                                <md-select v-model="accountCodeDetails.type">
                                    <md-option v-for="item in accountCodeTypes" :key="item" :value="item">
                                        {{ item }}
                                    </md-option>
                                </md-select>
                            </form-group>
                        </div>
                    </div>
                    <div v-if="taxSettings.length > 0">
                        <div>
                            <form-group
                                name="type"
                                label="Default Tax Type"
                                attribute="Default tax type"
                                :messages="localMessages"
                            >
                                <md-select v-model="accountCodeDetails.defaultTaxSettingId">
                                    <md-option v-for="item in taxSettings" :key="item.key" :value="item.key">
                                        {{ item.description }}
                                    </md-option>
                                </md-select>
                            </form-group>
                        </div>
                    </div>
                </form>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validateService">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { required, maxLength } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'CreateAccountCodeModal',
    mixins: [GeneralMixin],
    props: {
        accountCodeDetails: {
            type: Object,
            default: () => {}
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    validations: {
        accountCodeDetails: {
            code: { required, maxLength: maxLength(15) }
        }
    },
    data() {
        return {
            localMessages: {
                required: '{attribute} is required.'
            },
            accountCodeTypes: ['Income', 'Expense'],
            taxSettings: []
        };
    },
    methods: {
        async create() {
            try {
                this.$_handleLoaderState(true, 'PROCESSING...');
                const api = `/api/team-settings/account-codes`;

                const payload = {
                    method: 'post',
                    data: this.accountCodeDetails
                };

                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully created account code.',
                    type: 'success'
                });

                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Error in creating the account code.';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        async updateAccountCodeDetails() {
            try {
                this.$_handleLoaderState(true, 'UPDATING...');

                const api = `/api/team-settings/account-codes/${this.accountCodeDetails.accountCodeId}`;
                const payload = {
                    method: 'put',
                    data: this.accountCodeDetails
                };

                await handleRequests(api, payload);

                this.$notify({
                    message: 'Successfully updated account code.',
                    type: 'success'
                });

                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Error in updating the account code.';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        validateService() {
            this.$v.accountCodeDetails.$touch();

            if (!this.$v.accountCodeDetails.$invalid) {
                if (this.isUpdate) {
                    this.updateAccountCodeDetails();
                } else {
                    this.create();
                }

                return;
            }

            this.$notify({
                message: 'There are errors in the form. Please correct them to continue.',
                type: 'danger'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-container {
    max-width: 600px;
}
</style>
