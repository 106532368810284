var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "md-card",
        { staticClass: "no-border-card" },
        [
          _c("md-card-content", [
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                { staticClass: "md-layout-item md-size-20" },
                [
                  _c(
                    "form-group",
                    {
                      attrs: {
                        label: "Business Registration Name",
                        name: "businessRegistrationName",
                      },
                    },
                    [
                      _c("md-input", {
                        model: {
                          value: _vm.entity.businessRegistrationName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.entity,
                              "businessRegistrationName",
                              $$v
                            )
                          },
                          expression: "entity.businessRegistrationName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-size-20" },
                [
                  _c(
                    "form-group",
                    {
                      attrs: {
                        label: "Business Registration Number",
                        name: "businessRegistrationNumber",
                      },
                    },
                    [
                      _c("md-input", {
                        model: {
                          value: _vm.entity.businessRegistrationNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.entity,
                              "businessRegistrationNumber",
                              $$v
                            )
                          },
                          expression: "entity.businessRegistrationNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("md-card-actions", { attrs: { "md-alignment": "left" } }, [
            _c(
              "button",
              { staticClass: "custom-btn", on: { click: _vm.validateForm } },
              [_vm._v("\n                Save\n            ")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }