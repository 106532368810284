<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 id="createEmailActionModalTitle" class="modal-title">{{ isUpdate ? 'Update' : 'Create' }} Action</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.customActionModel" class="form-wrapper">
                <form-group name="action" label="Action Type">
                    <md-select id="action-type-select" v-model="customActionModel.action" disabled>
                        <md-option v-for="item in actionTypeOptions" :key="item.text" :value="item.value">
                            {{ item.text }}
                        </md-option>
                    </md-select>
                </form-group>
                <form-group name="eventTrigger" label="Event Trigger">
                    <md-select id="eventTrigger-select" v-model="customActionModel.eventTrigger">
                        <md-option
                            :data-test-id="`create-email-action-${item.eventTrigger}`"
                            v-for="item in eventTriggers"
                            :key="item.displayText"
                            :value="item.eventTrigger"
                        >
                            {{ item.displayText }}
                        </md-option>
                    </md-select>
                </form-group>
                <form-group v-if="filters && filters.length > 0" name="filter.status" label="Filter">
                    <md-select id="filter-status-select" v-model="nonWebhookStatusFilter">
                        <md-option
                            v-for="item in filters"
                            :key="item"
                            :value="item"
                            :data-test-id="`create-email-action-filter-${item}`"
                        >
                            {{ item }}
                        </md-option>
                    </md-select>
                </form-group>
                <form-group name="settings.emailRecipientTypes" label="Recipient(s)">
                    <md-select
                        id="emailRecipientTypes"
                        multiple
                        v-model="customActionModel.settings.emailRecipientTypes"
                    >
                        <md-option
                            v-for="item in emailRecipientOptions"
                            :key="item.value"
                            :value="item.value"
                            :data-test-id="`create-email-action-recipient-types-${item.value}`"
                        >
                            {{ item.text }}
                        </md-option>
                    </md-select>
                </form-group>
                <form-group
                    v-if="
                        customActionModel.settings.emailRecipientTypes &&
                            customActionModel.settings.emailRecipientTypes.includes('Specified')
                    "
                    name="settings.recipientEmailAddresses"
                    label="Email address(es)"
                >
                    <md-input
                        id="recipient-addresses-input"
                        v-model="customActionModel.settings.recipientEmailAddresses"
                    />
                </form-group>
                <!-- // hide it because we are yet to offer customised template
                <form-group name="Use system template" class="no-underline">
                    <md-checkbox
                        v-model="customActionModel.settings.useSystemTemplate"
                        :disabled="initialUseSystemTemplate"
                    >
                        Use default template
                    </md-checkbox>
                </form-group>
                <form-group
                    v-if="!customActionModel.settings.useSystemTemplate"
                    name="settings.emailTemplateId"
                    label="Custom Template Id"
                    class="keep-spacing"
                >
                    <md-input
                        id="custom-template-input"
                        v-model="customActionModel.settings.emailTemplateId"
                        @input="$v.customActionModel.settings.emailTemplateId.$touch()"
                        readonly
                    />
                </form-group>
-->
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button id="btnUpdateCustomAction" class="dialog-button md-primary" @click.prevent="validate">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { TEAM_CUSTOM_ACTION_EMAIL_RECIPIENT_OPTIONS, TEAM_CUSTOM_ACTION_TYPE_OPTIONS } from '@/utils/constants';
import { showErrorMessage } from '@/helpers';
import { required, requiredIf, minLength } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'CreateEmailAction',
    mixins: [GeneralMixin],
    props: {
        customActionModel: { type: Object, default: () => null },
        isUpdate: { type: Boolean, default: false },
        resolve: { type: Function, default: () => {} }
    },
    data() {
        return {
            selectedEventTrigger: null,
            actionTypeOptions: TEAM_CUSTOM_ACTION_TYPE_OPTIONS,
            nonWebhookStatusFilter: null,
            initialUseSystemTemplate: false
        };
    },
    validations() {
        return {
            customActionModel: {
                action: { required },
                eventTrigger: { required },
                filter: {
                    status: {
                        required: requiredIf(() => {
                            return this.filters.length;
                        })
                    }
                },
                settings: {
                    emailTemplateId: {
                        required: requiredIf(() => {
                            return !this.customActionModel.settings.useSystemTemplate;
                        })
                    },
                    emailRecipientTypes: { required, minLength: minLength(1) },
                    recipientEmailAddresses: {
                        required: requiredIf(() => {
                            return (
                                this.customActionModel.settings.emailRecipientTypes &&
                                this.customActionModel.settings.emailRecipientTypes.includes('Specified')
                            );
                        })
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters('custom-actions', ['existingReplyToEmails']),
        eventTriggers() {
            const action = this.actionTypeOptions.find((x) => x.value === this.customActionModel.action);
            if (action && action.eventTriggers) 
                return [...action.eventTriggers];
            return [];
        },
        currentEventTrigger() {
            if (!this.customActionModel.eventTrigger) 
                return null;

            return this.eventTriggers.find((x) => x.eventTrigger === this.customActionModel.eventTrigger);
        },
        filters() {
            if (!this.customActionModel.eventTrigger) 
                return [];

            let filters = [];
            const evt = this.eventTriggers.find((x) => x.eventTrigger === this.customActionModel.eventTrigger);
            if (evt && evt.filters && evt.filters.statuses) {
                filters = [...evt.filters.statuses];

                if (this.customActionModel.action === 'Webhook') {
                    filters = ['All'].concat(filters);
                }
            }

            return filters;
        },
        isStatusMultiselect() {
            return this.customActionModel.action === 'Webhook';
        },
        emailRecipientOptions() {
            if (!this.currentEventTrigger) 
                return [];

            let limitRecipients = [];
            if (this.currentEventTrigger.limitRecipients) {
                [limitRecipients] = [this.currentEventTrigger.limitRecipients];
            } else if (this.currentEventTrigger.limitRecipientsByFilter) {
                limitRecipients = this.currentEventTrigger.limitRecipientsByFilter(this.nonWebhookStatusFilter);
            }

            let recipientOptions = TEAM_CUSTOM_ACTION_EMAIL_RECIPIENT_OPTIONS;
            if (limitRecipients && limitRecipients.length) {
                recipientOptions = TEAM_CUSTOM_ACTION_EMAIL_RECIPIENT_OPTIONS.filter((x) =>
                    limitRecipients.includes(x.value)
                );
            }

            return recipientOptions;
        }
    },
    watch: {
        'customActionModel.eventTrigger': function(newVal, oldVal) {
            if (oldVal) {
                this.nonWebhookStatusFilter = null;
                this.customActionModel.settings.emailRecipientTypes = [];
            }
        },
        nonWebhookStatusFilter(newVal, oldVal) {
            this.customActionModel.filter.status = newVal ? [newVal] : [];

            // The email recipients for some triggers vary from filter to filter,
            // so I am resetting the recipients whenever the filter changes
            // to prevent saving invalid recipients.
            const shouldResetRecipientTypes =
                oldVal && oldVal !== newVal && this.customActionModel.eventTrigger === 'STOP-STATUS-CHANGED';
            if (shouldResetRecipientTypes) {
                this.customActionModel.settings.emailRecipientTypes = [];
            }
        },
        'customActionModel.settings.emailRecipientTypes': function(newVal) {
            if (!newVal || !newVal.includes('Specified')) {
                this.customActionModel.settings.recipientEmailAddresses = null;
            }
        }
    },
    methods: {
        ...mapActions('custom-actions', ['ADD_EVENT_TRIGGER', 'UPDATE_EVENT_TRIGGER']),
        async validate() {
            if (this.isUpdate) {
                await this.update();
            } else {
                await this.create();
            }
        },
        async create() {
            this.$v.customActionModel.$touch();
            if (this.$v.customActionModel.$invalid) 
                return;

            const model = this.transposeModel(this.customActionModel);

            this.$_handleLoaderState(true, 'CREATING EVENT TRIGGER...');

            try {
                await this.ADD_EVENT_TRIGGER({ data: model });

                this.$notify({
                    message: 'Successfully created an event trigger.',
                    type: 'success'
                });

                this.resolve({ result: 'ok' });
            } catch (e) {
                const message = 'Could not create event trigger.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        async update() {
            this.$v.customActionModel.$touch();
            if (this.$v.customActionModel.$invalid) 
                return;

            this.$_handleLoaderState(true, 'UPDATING EVENT TRIGGER...');

            try {
                const model = this.transposeModel(this.customActionModel);

                await this.UPDATE_EVENT_TRIGGER({ data: model });

                this.$notify({
                    message: 'Successfully updated an event trigger.',
                    type: 'success'
                });

                this.resolve({ result: 'ok' });
            } catch (e) {
                const message = 'Could not update event trigger.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        transposeModel(customActionModel) {
            // the 'multiselect' control gives us single value if multiselect property is false (for Email, Sms)
            // but the backend expects array to support 'webhook'
            // so, we need to ensure UI passes array

            if (this.filters.length && !this.isStatusMultiselect) {
                const { filter } = customActionModel;
                if (!Array.isArray(filter.status)) {
                    customActionModel.filter = {
                        ...filter,
                        status: [filter.status]
                    };
                }
            }
            return customActionModel;
        }
    },
    mounted() {
        // if there's a custom template id from backend, provide an option to use it or use the system template
        // but if there's no template id, force user to use the system template
        this.initialUseSystemTemplate =
            !this.customActionModel.settings.emailTemplateId || !this.customActionModel.settings.emailTemplateId.length;

        /* eslint-disable */
        if (this.isUpdate && this.customActionModel.action !== 'Webhook') {
            const { status } = this.customActionModel.filter;
            if (status.length) {
                this.nonWebhookStatusFilter = status[0];
            }
        }
        /* eslint-enable */
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-checkbox-label {
    opacity: 1 !important;
    color: black !important;
}

::v-deep div.md-field.no-underline:after {
    height: 0px;
}

::v-deep .md-checkbox {
    margin-top: 0px;
}
</style>
