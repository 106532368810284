<template>
    <div class="inline-button">
        <md-button
            v-if="!isReadOnlyUser"
            title="Update maintenance schedule"
            :class="useIcon ? 'md-warning md-just-icon md-round' : `${className}`"
            @click.stop="handleUpdate"
        >
            <md-icon v-if="useIcon">edit</md-icon>
            <span v-else>Update</span>
        </md-button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CreateMaintenanceScheduleWizard from '@/pages/Maintenance/modals/CreateMaintenanceScheduleWizard';

export default {
    name: 'UpdateMaintenanceScheduleButton',
    props: {
        className: {
            type: String,
            default: () => ''
        },
        useIcon: {
            type: Boolean,
            default: () => true
        },
        maintenanceData: {
            type: Object,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    methods: {
        handleUpdate() {
            this.$modal
                .show(CreateMaintenanceScheduleWizard, {
                    existingMaintenanceScheduleData: this.maintenanceData,
                    isUpdate: true
                })
                .then((response) => {
                    this.$emit('maintenanceUpdateCompleted', response);
                    this.$modal.hide();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.inline-button {
    display: inline-block;
}
</style>
