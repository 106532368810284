var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam
    ? _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Settings"),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "   >    " + _vm._s(_vm.$t("menus.setting.jobOffers"))
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-blue" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("work_history")])],
                      1
                    ),
                  ]
                ),
                _c("md-card-content", [
                  _vm.teamRegionSchemeSupported
                    ? _c(
                        "div",
                        { staticClass: "content-header" },
                        [
                          _c("JobOfferFilter", {
                            staticClass: "filter",
                            attrs: {
                              "team-region-id": _vm.selectedTeamRegionId,
                            },
                            on: { onFilter: _vm.handleFilterTeamRegion },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "content-body" }, [
                    _vm.teamRegionSchemeSupported &&
                    !_vm.offerSetting &&
                    !_vm.offerSettingList
                      ? _c("div", [
                          _c(
                            "div",
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.noSettingFoundText)),
                              ]),
                              _c(
                                "md-button",
                                {
                                  staticClass: "md-primary",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.showCreate($event)
                                    },
                                  },
                                },
                                [_vm._v("Create")]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm.offerSettingList
                      ? _c(
                          "div",
                          [
                            _c("JobOfferSettingsList", {
                              staticClass: "job-offer-container",
                              attrs: { "offer-settings": _vm.offerSettingList },
                              on: {
                                onViewDetailsClick: _vm.handleViewDetailsClick,
                                onAddClick: _vm.showCreate,
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _vm.offerSetting
                              ? [
                                  _c("JobOfferSettings", {
                                    staticClass: "job-offer-container",
                                    attrs: {
                                      "offer-setting": _vm.offerSetting,
                                    },
                                    on: {
                                      updated: _vm.handleSettingUpdated,
                                      deleted: _vm.handleSettingDeleted,
                                    },
                                  }),
                                  !_vm.showManualAllocationSettings
                                    ? _c("JobOfferBands", {
                                        staticClass: "job-offer-container",
                                        attrs: {
                                          "offer-setting": _vm.offerSetting,
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              : [
                                  _c(
                                    "div",
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.noSettingFoundText)),
                                      ]),
                                      _c(
                                        "md-button",
                                        {
                                          staticClass: "md-primary",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.showCreate($event)
                                            },
                                          },
                                        },
                                        [_vm._v("Create")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                          ],
                          2
                        ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }