var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form-wrapper",
        { staticClass: "form-wrapper", attrs: { validator: _vm.$v.model } },
        [
          _c(
            "form-group",
            { attrs: { label: "Api Key", name: "apiKey" } },
            [
              _c("md-input", {
                model: {
                  value: _vm.model.apiKey,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.model,
                      "apiKey",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "model.apiKey",
                },
              }),
            ],
            1
          ),
          _c(
            "form-group",
            { attrs: { label: "Api Secret", name: "apiSecret" } },
            [
              _c("md-input", {
                model: {
                  value: _vm.model.apiSecret,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.model,
                      "apiSecret",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "model.apiSecret",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }