import { GEOCODE_ADDRESS_TYPE } from '@/utils/constants';

export const GeocodeAddressMixin = {
    methods: {
        $_getGeocodeAddressTypeBadgeColor(type) {
            const addressTypes = [...GEOCODE_ADDRESS_TYPE];
            const result = addressTypes.find((addressType) => addressType.key.toLowerCase() === type.toLowerCase());

            if (result) 
                return result.color;

            return 'pending';
        }
    }
};
