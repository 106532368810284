var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-warning" },
        [
          _c(
            "div",
            { staticClass: "card-icon" },
            [_c("md-icon", [_vm._v("history")])],
            1
          ),
        ]
      ),
      _c(
        "md-card-content",
        [
          _vm.list.length > 0
            ? _c(
                "time-line",
                { attrs: { plain: "", type: "simple" } },
                _vm._l(_vm.list, function (history) {
                  return _c(
                    "time-line-item",
                    {
                      key: history.actionDate,
                      attrs: {
                        inverted: "",
                        "badge-type":
                          "status-" +
                          history.newStatus.toLowerCase().replace(/\s+/g, ""),
                        "badge-icon": "place",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "custom-badge",
                          class:
                            "status-" +
                            history.newStatus.toLowerCase().replace(/\s+/g, ""),
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(history.newStatus) +
                              "\n                "
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "time",
                          attrs: { slot: "headerTime" },
                          slot: "headerTime",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("dateTimeFormat")(
                                  history.actionDate,
                                  _vm.DATE_TYPES.standardDate
                                )
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                      _c("h6", { attrs: { slot: "footer" }, slot: "footer" }, [
                        _c("i", { staticClass: "ti-time" }),
                        _vm._v(
                          "\n\n                    By " +
                            _vm._s(
                              history.changedByUserFullName ||
                                history.changedByPublicUserId
                            ) +
                            "\n                "
                        ),
                      ]),
                    ]
                  )
                }),
                1
              )
            : _c("p", { staticClass: "grayedout-text" }, [
                _vm._v("\n            No Status History Available\n        "),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }