<template>
    <md-card :class="[{ 'disabled-rates': hasInvoice }]">
        <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon icon-style">
                <md-icon>attach_money</md-icon>
                {{ title }}
            </div>
        </md-card-header>
        <div class="has-invoice" v-if="hasInvoice">
            <md-icon class="invoice-icon-color">task</md-icon>
            <i>*Charges cannot be changed because trip has been invoiced</i>
        </div>
        <div class="is-approved" v-if="isChargesApproved">
            <md-icon class="invoice-icon-color">task</md-icon>
            <i>*Charges cannot be changed because trip has been approved</i>
        </div>
        <md-card-content>
            <div v-if="updatingRates">
                <loading-component-spinner loading-text="Rates Updating..." />
            </div>
            <p v-if="chargeLines.length === 0 && !updatingRates" class="grayedout-text text-center">
                No {{ title }} found
            </p>
            <div v-if="!updatingRates && chargeLines.length > 0">
                <md-table>
                    <md-table-row slot="header">
                        <md-table-head class="drag-icon"></md-table-head>
                        <md-table-head>Description</md-table-head>
                        <md-table-head>Unit</md-table-head>
                        <md-table-head>Amount</md-table-head>
                        <md-table-head v-if="$root.isDesktop && !isReadOnlyUser && !hasInvoice">
                            Actions
                        </md-table-head>
                    </md-table-row>

                    <div v-if="updatingRates">
                        <loading-component-spinner loading-text="Rates Updating..." />
                    </div>
                    <md-table-row v-if="!updatingRates" v-for="rate in chargeLines" :key="rate.id">
                        <md-table-cell class="drag-icon">
                            <md-icon v-if="rate.isManualCharge || rate.manuallyChanged">
                                <span>
                                    drive_file_rename_outline
                                </span>
                            </md-icon>
                        </md-table-cell>
                        <md-table-cell>
                            {{ rate.description }}
                        </md-table-cell>
                        <md-table-cell v-if="rate.isManualCharge || rate.manuallyChanged" />
                        <md-table-cell v-else>
                            {{ rate.chargeUnit }}
                        </md-table-cell>
                        <md-table-cell>
                            {{ $_getRateAmount(rate) | currency(tripDetails.currency) }}
                        </md-table-cell>
                        <md-table-cell v-if="!hasInvoice" class="action-buttons">
                            <md-button
                                v-if="!isReadOnlyUser"
                                title="Edit Charge"
                                class="md-success md-just-icon md-round"
                                @click.stop="openChargeLine(rate)"
                            >
                                <md-icon>edit</md-icon>
                            </md-button>
                            <md-button
                                v-if="!isReadOnlyUser"
                                title="Remove Charge"
                                class="md-danger md-just-icon md-round"
                                @click.stop="deleteChargeLine(rate)"
                            >
                                <md-icon>delete</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>

                    <md-table-row v-if="!updatingRates">
                        <md-table-cell colspan="3" />
                        <md-table-cell class="total-style">
                            {{ $_getTotalAmount(chargeLines) | currency(tripDetails.currency) }}
                        </md-table-cell>
                        <md-table-cell />
                    </md-table-row>
                </md-table>
            </div>
        </md-card-content>
        <md-card-actions
            v-if="$root.isDesktop && !isChargesApproved && !isReadOnlyUser && !hasInvoice"
            :class="{ 'is-loading-rates': updatingRates }"
        >
            <md-button class="md-warning revert-button" @click="validateRevertingCharges()">
                Revert to Default Charges
            </md-button>
            <md-button class="md-success" @click="handleAddNewCharge()">
                Add Charge
            </md-button>
        </md-card-actions>
    </md-card>
</template>

<script>
import { GeneralMixin, RatesEngineMixin } from '@/mixins';
import { FeatureManager } from '@/directives';
import { FEATURE_NAMES } from '@/utils/constants';
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import { LoadingComponentSpinner } from '@/components';
import TripChargeItemModal from './TripChargeItemModal';

export default {
    name: 'TripChargeRatesList',
    components: {
        LoadingComponentSpinner
    },
    mixins: [GeneralMixin, RatesEngineMixin, FeatureManager],
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            isIndividualUser: 'user/isIndividualUser',
            user: 'user/user'
        })
    },
    props: {
        chargeLines: {
            type: Array,
            default: () => []
        },
        tripDetails: {
            type: Object,
            default: () => null
        },
        title: {
            type: String,
            default: null
        },
        hasInvoice: {
            type: Boolean,
            default: false
        },
        ratesHaveChanged: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            updatingRates: false,
            isChargesApproved: false
        };
    },
    watch: {
        ratesHaveChanged(newValue, oldValue) {
            this.updatingRates = newValue;
        }
    },
    mounted() {
        if (this.$_hasFeature(FEATURE_NAMES.JobsChargeApproval)) {
            this.isChargesApproved = this.tripDetails.isChargesApproved;
        }
    },
    methods: {
        openChargeLine(charge) {
            this.$modal
                .show(TripChargeItemModal, {
                    tripId: this.tripDetails.tripId,
                    isManualCharge: false,
                    manuallyChanged: true,
                    chargeLine: charge
                })
                .then((response) => {
                    this.emitUpdate();
                    this.$modal.hide();
                });
        },
        deleteChargeLine(charge) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Charge',
                    body: 'Are you sure you want to delete this charge?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isListLoading = true;
                        await this.handleDeleteCharge(charge.id);
                    }
                });
        },
        async handleDeleteCharge(id) {
            const payload = {
                method: 'delete'
            };

            this.$_handleLoaderState(true, 'DELETING...');

            const api = `/api/trips/rate/charge/delete/${id}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Charge was deleted!',
                    type: 'success'
                });
            } catch (e) {
                const message = 'Error in deleting a charge.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
                this.emitUpdate();
            }
        },
        validateRevertingCharges() {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Revert Charges',
                    body: 'Are you sure you want to revert charges? <br /><br /> This cannot be undone.',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        await this.handleRevertToDefaultCharges(true, false);
                    }
                });
        },
        async handleRevertToDefaultCharges() {
            try {
                this.$_handleLoaderState(true, 'REVERTING...');

                const tripData = {
                    isCosting: false,
                    isCharging: true,
                    revertCosts: false,
                    revertCharges: true
                };

                const payload = {
                    method: 'post',
                    data: tripData
                };

                await handleRequests(`/api/rates/trip/${this.tripDetails.tripId}/generate-rates`, payload);

                this.$notify({
                    message: 'Charges Reverted',
                    type: 'success'
                });
            } catch (error) {
                const message = 'Error in reverting charges';
                showErrorMessage(this, message, null);
            } finally {
                this.ratesIsLoading = false;
                this.$_handleLoaderState(false);
                this.emitUpdate();
            }
        },
        handleAddNewCharge() {
            this.$modal
                .show(TripChargeItemModal, {
                    tripId: this.tripDetails?.tripId,
                    isManualCharge: true,
                    manuallyChanged: false,
                    chargeLine: {
                        description: null,
                        amount: 0,
                        chargeUnit: null,
                        quantity: 1
                    }
                })
                .then((response) => {
                    this.emitUpdate();
                    this.$modal.hide();
                });
        },
        emitUpdate() {
            if (this.tripDetails?.tripId) {
                this.$emit('updateTripDetails');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.total-style {
    color: #4caf50 !important;
    font-weight: bold !important;
}

.icon-style {
    color: white;
}

.revert-button {
    margin-inline-end: auto !important;
}

.disabled-rates {
    pointer-events: none;
}

.is-loading-rates {
    margin-top: 150px !important;
}

.action-buttons {
    button,
    ::v-deep button {
        margin: 0 2px;
    }

    button:last-child {
        margin-right: 0;
    }
}

::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

.has-invoice,
.is-approved {
    padding-top: 15px;
    padding-left: 20px;
}
</style>
