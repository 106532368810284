var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v(_vm._s(_vm.modalTitle)),
      ]),
      _c(
        "div",
        { staticClass: "modal-header--actions" },
        [
          _c(
            "md-button",
            {
              staticClass:
                "md-simple md-just-icon md-round modal-default-button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$modal.hide($event)
                },
              },
            },
            [_c("md-icon", [_vm._v("clear")])],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "modal-body" }, [
      _c(
        "div",
        { staticClass: "form-container" },
        [
          _vm.jobType !== "stop"
            ? _c(
                "form-group",
                {
                  staticClass: "time-picker hide-clear-btn",
                  attrs: { name: "startTime", label: "Start Time" },
                },
                [
                  _c("time-picker", {
                    attrs: {
                      time: _vm.startTime,
                      "all-time-options": false,
                      "is-required": true,
                    },
                    on: { selectedTime: _vm.getStartTime },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "form-group",
            {
              staticClass: "time-picker hide-clear-btn",
              attrs: { name: "endTime", label: "End Time" },
            },
            [
              _c("time-picker", {
                attrs: {
                  time: _vm.endTime,
                  "all-time-options": false,
                  "is-required": true,
                },
                on: { selectedTime: _vm.getEndTime },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.save($event)
              },
            },
          },
          [_vm._v("\n            Update\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }