var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stop-item" },
    [
      !_vm.isCustomer && !_vm.isSupportStaff
        ? _c(
            "md-button",
            {
              class: _vm.useIcon
                ? "md-warning md-just-icon md-round"
                : "" + _vm.className,
              attrs: {
                title: "Edit stop details",
                disabled: _vm.isFailed || _vm.isCarrierAssigned,
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleUpdateStop($event)
                },
              },
            },
            [
              _vm.useIcon
                ? _c("md-icon", [_vm._v("edit")])
                : _c("span", [_vm._v("Edit")]),
            ],
            1
          )
        : _vm._e(),
      _vm.isCarrierAssigned
        ? _c(
            "md-tooltip",
            { staticClass: "line-tooltip", attrs: { "md-direction": "top" } },
            [
              _vm._v(
                "\n        Shipment is assigned to a carrier. Edit is not allowed.\n    "
              ),
            ]
          )
        : _vm.isFailed
        ? _c(
            "md-tooltip",
            { staticClass: "line-tooltip", attrs: { "md-direction": "top" } },
            [
              _vm._v(
                "\n        Not allowed to edit a stop when it is already marked as Failed.\n    "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }