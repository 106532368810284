var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasMarketplaceAccess
    ? _c("div", { staticClass: "dashboard" }, [
        _c("div", { staticClass: "md-layout" }, [
          _c("div", { staticClass: "md-layout-item" }, [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c("FilterCarriers", {
                    class: _vm.$root.isTablet ? "tablet-filter-container" : "",
                    on: { onFilter: _vm.handleFilter },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "custom-toolbar-end" },
                [
                  _c("search-component", {
                    ref: "search",
                    on: { onSearch: _vm.handleSearch },
                  }),
                  _c(
                    "div",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "md-button md-primary md-just-icon md-round pull-right header-button",
                          attrs: {
                            to: {
                              name: "Marketplace",
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "md-ripple" }, [
                            _c(
                              "div",
                              { staticClass: "md-button-content" },
                              [
                                _c("md-icon", [_vm._v("add")]),
                                _c(
                                  "md-tooltip",
                                  { attrs: { "md-direction": "left" } },
                                  [_vm._v("Connect to Carrier")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            { staticClass: "md-layout-item" },
            [
              _c(
                "md-card",
                [
                  _c(
                    "md-card-header",
                    { staticClass: "md-card-header-icon md-card-header-green" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-icon" },
                        [_c("md-icon", [_vm._v("crop")])],
                        1
                      ),
                    ]
                  ),
                  _c("md-card-content", { staticClass: "body-list" }, [
                    _vm.isListLoading
                      ? _c("div", { staticClass: "empty-table" }, [
                          _c(
                            "div",
                            { staticClass: "table-loader" },
                            [
                              _c("fade-loader", {
                                attrs: { loading: true, color: "#333333" },
                              }),
                              _c("span", [_vm._v("LOADING")]),
                            ],
                            1
                          ),
                        ])
                      : _c("div", [
                          _vm.teamCarrierList && _vm.teamCarrierList.length
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "md-table",
                                    {
                                      staticClass:
                                        "context-menu-support custom-paginated-table",
                                    },
                                    [
                                      _c(
                                        "md-table-row",
                                        [
                                          _c("md-table-head"),
                                          _c("md-table-head", [_vm._v("Name")]),
                                          _c("md-table-head", [_vm._v("Logo")]),
                                          _c("md-table-head", [
                                            _vm._v("Status"),
                                          ]),
                                          _c("md-table-head"),
                                        ],
                                        1
                                      ),
                                      _vm._l(
                                        _vm.teamCarrierList,
                                        function (item) {
                                          return _c(
                                            "md-table-row",
                                            { key: item.teamCarrierId },
                                            [
                                              _c(
                                                "md-table-cell",
                                                {
                                                  staticClass:
                                                    "icon-warning-column",
                                                },
                                                [
                                                  item.status == "Onboarding"
                                                    ? _c(
                                                        "md-icon",
                                                        {
                                                          staticClass:
                                                            "warning-yellow-icon",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            warning\n                                            "
                                                          ),
                                                          _c(
                                                            "md-tooltip",
                                                            {
                                                              attrs: {
                                                                "md-direction":
                                                                  "left",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                Please complete the questions mapping, set up the capacity settings,\n                                                and provide the access keys to complete the activation of this\n                                                carrier.\n                                            "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c("md-table-cell", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.carrierTeam.company
                                                  )
                                                ),
                                              ]),
                                              _c("md-table-cell", [
                                                item.carrierTeam.logoUrl
                                                  ? _c("img", {
                                                      staticClass:
                                                        "profile-image",
                                                      attrs: {
                                                        src: item.carrierTeam
                                                          .logoUrl,
                                                        title:
                                                          item.carrierTeam
                                                            .logoFileName,
                                                        alt: "",
                                                      },
                                                      on: {
                                                        error:
                                                          _vm.$_setDefaultBrokenImage,
                                                      },
                                                    })
                                                  : _c("img", {
                                                      staticClass:
                                                        "profile-image",
                                                      attrs: {
                                                        src: _vm.$root
                                                          .defaultLogoUrl,
                                                        alt: "",
                                                      },
                                                    }),
                                              ]),
                                              _c("md-table-cell", [
                                                _vm._v(_vm._s(item.statusDesc)),
                                              ]),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  staticClass: "action-buttons",
                                                },
                                                [
                                                  _c(
                                                    "md-button",
                                                    {
                                                      staticClass:
                                                        "md-just-icon md-round",
                                                      class:
                                                        item.status === "Active"
                                                          ? "md-success"
                                                          : "md-secondary",
                                                      attrs: {
                                                        title:
                                                          item.status ===
                                                          "Active"
                                                            ? "Deactivate"
                                                            : "Activate",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.toggleStatus(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      item.status === "Active"
                                                        ? _c("md-icon", [
                                                            _vm._v("link"),
                                                          ])
                                                        : _c("md-icon", [
                                                            _vm._v("link_off"),
                                                          ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "md-button",
                                                    {
                                                      staticClass:
                                                        "md-info md-just-icon md-round",
                                                      attrs: {
                                                        title:
                                                          "Questions Mapping",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.updateQuestionsMapping(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("md-icon", [
                                                        _vm._v("checklist_rtl"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.showAccessKeys(item)
                                                    ? _c(
                                                        "md-button",
                                                        {
                                                          staticClass:
                                                            "md-warning md-just-icon md-round",
                                                          attrs: {
                                                            title:
                                                              "Access Keys",
                                                            "data-test-id":
                                                              "btn-update-access-keys-" +
                                                              item.teamCarrierId,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.updateAccessKeys(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("md-icon", [
                                                            _vm._v("vpn_key"),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.onboardingLinkUrl != null
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "md-button md-info md-just-icon md-round setup-link",
                                                          attrs: {
                                                            title: "Setup link",
                                                            href: item.onboardingLinkUrl,
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "md-ripple",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "md-button-content",
                                                                },
                                                                [
                                                                  _c(
                                                                    "md-icon",
                                                                    [
                                                                      _vm._v(
                                                                        "article"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _c("div", [
                                _c("p", { staticClass: "no-result-message" }, [
                                  _vm._v(
                                    "No results matching your search/filter could be found."
                                  ),
                                ]),
                              ]),
                        ]),
                  ]),
                ],
                1
              ),
              _c(
                "md-card-actions",
                {
                  staticClass: "page-footer",
                  attrs: { "md-alignment": "space-between" },
                },
                [
                  _c("div", [
                    _vm.total === _vm.pagination.perPage
                      ? _c("p", { staticClass: "card-category" }, [
                          _vm._v(
                            "\n                        Page " +
                              _vm._s(_vm.pagination.currentPage) +
                              " of many\n                    "
                          ),
                        ])
                      : _c("p", { staticClass: "card-category" }, [
                          _vm._v(
                            "Page " +
                              _vm._s(_vm.pagination.currentPage) +
                              " of " +
                              _vm._s(_vm.totalPages)
                          ),
                        ]),
                  ]),
                  _c("pagination", {
                    staticClass: "pagination-no-border pagination-success",
                    attrs: {
                      "per-page": _vm.pagination.perPage,
                      total: _vm.total,
                    },
                    on: {
                      "change-page": function ($event) {
                        return _vm.handleChangePage(
                          $event,
                          _vm.pagination.perPage
                        )
                      },
                    },
                    model: {
                      value: _vm.pagination.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.pagination, "currentPage", $$v)
                      },
                      expression: "pagination.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }