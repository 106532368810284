<template>
    <div class="custom-button-container" v-if="!$root.isMobileOnly">
        <span
            v-if="type === 'text' && !isSingleUser && !isSingleTeamMember"
            :class="`value custom-a-blue ${className}`"
            @click.stop="
                handleAssignShipment({
                    shipmentId: item.shipmentId,
                    assignedTo: item.assignedTo.publicUserId,
                    assignedCarrierTeamId: item.carrierTeamId,
                    shipmentRef: item.shipmentRef
                })
            "
        >
            <a v-if="Boolean(item.assignedTo.publicUserId)" href="#" title="reassign shipment">
                <img
                    v-if="item.assignedTo.fullName"
                    class="profile-image"
                    :src="item.assignedTo.photoUrl !== null ? item.assignedTo.photoUrl : $root.defaultPhotoUrl"
                    alt="avatar"
                />
                {{ item.assignedTo.fullName }}
            </a>
            <a v-else-if="item.carrierTeamId" href="#" title="reassign shipment" class="custom-ellipsis">
                <img
                    v-if="item.carrierTeam && item.carrierTeam.logoUrl"
                    class="profile-image"
                    :src="item.carrierTeam.logoUrl !== null ? item.carrierTeam.logoUrl : $root.defaultPhotoUrl"
                    alt="carrier logo"
                    @error="$_setDefaultBrokenImage"
                />
                {{ item.carrierTeam.company }}
            </a>
            <a v-else href="#" title="reassign shipment">
                Unassigned
            </a>
        </span>
        <span v-if="type === 'text' && (isSingleUser || isSingleTeamMember)" :class="`value ${className}`">
            <img
                v-if="item.assignedTo.fullName"
                class="profile-image"
                :src="item.assignedTo.photoUrl !== null ? item.assignedTo.photoUrl : $root.defaultPhotoUrl"
                alt="avatar"
                @error="$_setDefaultBrokenImage"
            />
            {{ item.assignedTo.fullName }}
        </span>

        <md-button
            v-if="type === 'icon'"
            title="Re-assign driver"
            :class="`md-info md-just-icon md-round ${className}`"
            @click.stop="
                handleAssignShipment({
                    shipmentId: item.shipmentId,
                    assignedTo: item.assignedTo.publicUserId,
                    assignedCarrierTeamId: item.carrierTeamId,
                    shipmentRef: item.shipmentRef
                })
            "
        >
            <md-icon>person_pin</md-icon>
        </md-button>

        <md-button
            v-if="type === 'button' && !isCustomer && !isSupportStaff"
            title="Re-assign driver"
            :class="`custom-btn ${className}`"
            @click.stop="
                handleAssignShipment({
                    shipmentId: item.shipmentId,
                    assignedTo: item.assignedTo.publicUserId,
                    assignedCarrierTeamId: item.carrierTeamId,
                    shipmentRef: item.shipmentRef
                })
            "
        >
            Assign
        </md-button>
    </div>
    <div
        class="reassign-btn-mobile"
        v-else
        @click.stop="
            handleAssignShipment({
                shipmentId: item.shipmentId,
                assignedTo: item.assignedTo.publicUserId,
                assignedCarrierTeamId: item.carrierTeamId,
                shipmentRef: item.shipmentRef
            })
        "
    >
        <span class="blue-span" v-if="item.assignedTo != null && item.assignedTo.fullName">Reallocate</span>
        <span v-else>Allocate</span>
    </div>
</template>

<script>
import { AssignShipmentModal } from '@/pages/Shipments/components';
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'ReassignShipmentButton',
    mixins: [GeneralMixin],
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        teamMembers: {
            type: Array,
            default: () => []
        },
        useIcon: {
            type: Boolean,
            default: () => true
        },
        className: {
            type: String,
            default: () => ''
        },
        type: {
            type: String,
            default: () => 'button'
        }
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isCustomer: 'user/isCustomer',
            isSupportStaff: 'user/isSupportStaff'
        })
    },
    methods: {
        handleAssignShipment({ shipmentId, assignedTo, assignedCarrierTeamId, shipmentRef }) {
            if (this.isCustomer || this.isSupportStaff) 
                return;

            this.$modal
                .show(AssignShipmentModal, {
                    shipmentId,
                    teamMembers: this.teamMembers,
                    assignedTo,
                    assignedCarrierTeamId,
                    shipmentRef,
                    teamRegionId: this.item.teamRegionId
                })
                .then((response) => {
                    this.$emit('assignedUser', response);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    background-color: #2b93ff !important;
    color: #fff !important;
    border: none;
    width: 100%;
    max-width: 90px;
    text-align: center;
    line-height: 40px;
    font-size: 15px;
    cursor: pointer;
    text-transform: initial;
    height: 31px !important;
    padding: 0;
    font-weight: 300;
}

.custom-btn:hover,
.custom-btn:focus {
    color: #fff !important;
    background-color: #0076f0 !important;
}

.custom-a-blue a {
    color: #0076f0;
}
.profile-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
}
</style>
