var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "md-button",
        {
          staticClass: "md-primary pull-right header-button",
          attrs: { disabled: !_vm.teamMembers.length },
          on: { click: _vm.updateStop },
        },
        [_vm._v("\n        Edit\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }