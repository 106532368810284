var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return ["complete", "failed"].includes(this.status)
    ? _c(
        "div",
        { staticClass: "pod-button" },
        [
          _c(
            "md-button",
            {
              class: [
                _vm.useIcon
                  ? "md-info md-just-icon md-round"
                  : "md-info " + _vm.className,
                _vm.isDisabled ? "button-disabled" : "",
              ],
              attrs: { title: "Download " + this.buttonText },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleDownloadPdf(_vm.stopId)
                },
              },
            },
            [
              _vm.useIcon
                ? _c("md-icon", [_vm._v("receipt_long")])
                : _c("span", [_vm._v("Download " + _vm._s(_vm.buttonText))]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }