<template>
    <div>
        <div class="day-time-container">
            <span class="custom-label">Days</span>
            <md-input id="day-input" v-model.number="dayField" type="number" />
        </div>
        <div class="day-time-container">
            <span class="custom-label">Hours</span>
            <md-input id="hour-input" v-model.number="hourField" type="number" />
        </div>
        <div class="day-time-container">
            <span class="custom-label">Minutes</span>
            <md-input id="time-input" v-model.number="timeField" type="number" />
        </div>
    </div>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators';

export default {
    name: 'DayTimePicker',
    props: {
        value: { type: [Number, String], default: 0 }
    },
    data() {
        return {
            dayField: 0,
            hourField: 0,
            timeField: 0,
            dayOptions: [],
            hourOptions: [],
            timeOptions: []
        };
    },
    validations() {
        return {
            dayField: {
                required,
                minValue: minValue(0),
                maxValue: maxValue(30)
            },
            hourField: {
                required,
                minValue: minValue(0),
                maxValue: maxValue(23)
            },
            timeField: {
                required,
                minValue: minValue(0),
                maxValue: maxValue(59)
            }
        };
    },
    watch: {
        value(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.parseValue(newVal);
            }
        },
        dayField(newVal, oldVal) {
            if (newVal !== oldVal) 
                this.handleChange();
        },
        hourField(newVal, oldVal) {
            if (newVal !== oldVal) 
                this.handleChange();
        },
        timeField(newVal, oldVal) {
            if (newVal !== oldVal) 
                this.handleChange();
        }
    },
    methods: {
        populateDayOptions() {
            for (let d = 0; d <= 30; d++) {
                this.dayOptions.push(d);
            }
        },
        populateHourOptions() {
            for (let d = 0; d <= 23; d++) {
                this.hourOptions.push(d);
            }
        },
        populateTimeOptions() {
            for (let d = 0; d <= 59; d++) {
                this.timeOptions.push(d);
            }
        },
        padNumber(num) {
            return num < 10 ? `0${num}` : `${num}`;
        },
        // eslint-disable-next-line func-names
        handleChange: _.debounce(function() {
            this.raiseEvent();
        }, 100),
        raiseEvent() {
            const args = {
                day: this.dayField,
                hour: this.hourField,
                time: this.timeField,
                totalMinutes: this.dayField * 24 * 60 + this.hourField * 60 + this.timeField,
                formatted: `${this.padNumber(this.dayField)}:${this.padNumber(this.hourField)}:${this.padNumber(
                    this.timeField
                )}`
            };

            this.$emit('changed', args);
        },
        parseValue(value) {
            if (!value || value === 0) {
                this.dayField = 0;
                this.hourField = 0;
                this.timeField = 0;
                return;
            }
            const minutes = Number(value);
            const d = Math.floor(minutes / (60 * 24));
            const h = Math.floor((minutes % (60 * 24)) / 60);
            const m = Math.floor(minutes % 60);

            this.dayField = d;
            this.hourField = h;
            this.timeField = m;
        }
    },
    mounted() {
        this.populateDayOptions();
        this.populateHourOptions();
        this.populateTimeOptions();
        this.parseValue(this.value);
    }
};
</script>

<style scoped>
.day-time-container {
    display: inline-block;
    border-bottom: 1px solid #ddd;
    margin-right: 10px;
    width: calc((100% - 20px) / 3);
}

.day-time-container:last-child {
    margin-right: 0;
}
</style>
