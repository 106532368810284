<template>
    <div v-if="displayGeocodingScore" class="icon-container">
        <md-tooltip md-direction="top">
            <div v-if="addressComponents.type" class="address-text-tooltip">
                <b>Address Type:</b>
                {{ addressComponents.type }}
            </div>
            <div
                v-if="addressComponents.confidenceScore && addressComponents.confidenceScore >= 0"
                class="address-text-tooltip"
            >
                <b>Confidence Score:</b>
                {{ `${addressComponents.confidenceScore * 100}%` }}
            </div>
            <div v-if="addressComponents.line1" class="address-text-tooltip">
                <b>Line 1:</b>
                {{ addressComponents.line1 }}
            </div>
            <div v-if="addressComponents.line2" class="address-text-tooltip">
                <b>Line 2:</b>
                {{ addressComponents.line2 }}
            </div>
            <div v-if="addressComponents.city" class="address-text-tooltip">
                <b>City:</b>
                {{ addressComponents.city }}
            </div>
            <div v-if="addressComponents.province" class="address-text-tooltip">
                <b>Province:</b>
                {{ addressComponents.province }}
            </div>
            <div v-if="addressComponents.postcode" class="address-text-tooltip">
                <b>Postcode:</b>
                {{ addressComponents.postcode }}
            </div>
            <div v-if="addressComponents.country" class="address-text-tooltip">
                <b>Country:</b>
                {{ addressComponents.country }}
            </div>
        </md-tooltip>
        <md-icon class="icon-location" :style="{ color: confidenceColor }">{{ icon }}</md-icon>
    </div>
</template>

<script>
const ACCURATE_ADDRESS_TYPES = ['Point Address', 'street_number', 'premise', 'subpremise', 'Address Range', 'floor'];
const STREET_ADDRESS_TYPES = ['Street', 'route', 'intersection', 'Cross Street'];

export default {
    name: 'GeocodingIndicator',
    props: {
        addressComponents: {
            type: Object,
            default: () => null
        }
    },
    computed: {
        displayGeocodingScore() {
            // todo: add more conditions
            return this.addressComponents && this.addressComponents.type;
        },
        isAccurate() {
            return this.addressComponents.type && ACCURATE_ADDRESS_TYPES.includes(this.addressComponents.type);
        },
        isStreetAddress() {
            return this.addressComponents.type && STREET_ADDRESS_TYPES.includes(this.addressComponents.type);
        },
        confidenceScorePercent() {
            if (!this.addressComponents.confidenceScore) 
                return null;

            return this.addressComponents.confidenceScore * 100;
        },
        confidenceColor() {
            /** Locate2u-specific
             *  * Blue: #00D3EE  // hsla(187, 100%, 47%, 1)
             *  * Orange: #FFA21A // hsla(36, 100%, 55%, 1)
             *  * Red: #F55A4E // hsla(4, 89%, 63%, 1)
             *  * Green: #5CB860  // hsla(123, 39%, 54%, 1)
             *  */
            const green = 'hsla(123, 39%, 54%, 1)';
            const orange = 'hsla(36, 100%, 55%, 1)';
            const red = 'hsla(4, 89%, 63%, 1)';

            if (this.isAccurate) {
                if (!this.confidenceScorePercent || this.confidenceScorePercent > 90) 
                    return green;
                return orange;
            }

            if (this.isStreetAddress) {
                if (!this.confidenceScorePercent || this.confidenceScorePercent > 90) 
                    return orange;
                return red;
            }

            return red;
        },
        icon() {
            if (this.isAccurate) {
                if (!this.confidenceScorePercent || this.confidenceScorePercent > 90) 
                    return 'where_to_vote';
                return 'not_listed_location';
            }

            if (this.isStreetAddress) {
                if (!this.confidenceScorePercent || this.confidenceScorePercent > 90) 
                    return 'not_listed_location';
                return 'wrong_location';
            }

            return 'wrong_location';
        }
    }
};
</script>

<style scoped lang="scss">
.icon-container {
    width: 35px;
    float: left;
}

.icon-location {
    cursor: pointer;
}

.address-text-tooltip {
    text-align: left;
}

.md-tooltip {
    min-width: 300px !important;
}
</style>
