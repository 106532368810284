var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shipmentDetails
    ? _c("div", { staticClass: "shipment-details-container map-panel" }, [
        _c("div", { staticClass: "panel-header" }, [
          _vm.previousShipmentId
            ? _c(
                "a",
                {
                  staticClass: "navigation-button back",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.navigate(_vm.previousShipmentId)
                    },
                  },
                },
                [
                  _c("md-icon", [_vm._v("arrow_back_ios")]),
                  _c(
                    "md-tooltip",
                    {
                      staticClass: "toggle-tooltip",
                      attrs: { "md-direction": "top" },
                    },
                    [
                      _vm._v(
                        "\n                Previous Shipment\n                "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.shipmentListCursor + 1) +
                          " of " +
                          _vm._s(_vm.shipmentList.length) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a",
            { attrs: { href: _vm.shipmentDetailsUrl, target: "_blank" } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.shipmentDetails.pickupStop.name ||
                      _vm.shipmentDetails.pickupStop.address
                  ) +
                  "\n            >\n            " +
                  _vm._s(
                    _vm.shipmentDetails.dropStop.name ||
                      _vm.shipmentDetails.dropStop.address
                  ) +
                  "\n        "
              ),
            ]
          ),
          _vm.nextShipmentId
            ? _c(
                "a",
                {
                  staticClass: "navigation-button forwards",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.navigate(_vm.nextShipmentId)
                    },
                  },
                },
                [
                  _c("md-icon", [_vm._v("arrow_forward_ios")]),
                  _c(
                    "md-tooltip",
                    {
                      staticClass: "toggle-tooltip",
                      attrs: { "md-direction": "top" },
                    },
                    [
                      _vm._v(
                        "\n                Next Shipment\n                "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.shipmentListCursor + 1) +
                          " of " +
                          _vm._s(_vm.shipmentList.length) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "panel-content" }, [
          _vm.shipmentDetails.customerName
            ? _c("div", { staticClass: "customer content-section" }, [
                _c(
                  "span",
                  [
                    _c("md-icon", [_vm._v("warehouse")]),
                    _c(
                      "md-tooltip",
                      {
                        staticClass: "toggle-tooltip",
                        attrs: { "md-direction": "top" },
                      },
                      [_vm._v("Customer")]
                    ),
                  ],
                  1
                ),
                _c("span", [_vm._v(_vm._s(_vm.shipmentDetails.customerName))]),
                _vm.shipmentDetails.contact.name
                  ? _c("span", [
                      _vm._v("- " + _vm._s(_vm.shipmentDetails.contact.name)),
                    ])
                  : _vm._e(),
                _vm.shipmentDetails.contact.phone
                  ? _c("span", { staticClass: "call-customer" }, [
                      _c(
                        "a",
                        {
                          staticClass: "profile--phone",
                          attrs: {
                            href: "tel:" + _vm.shipmentDetails.contact.phone,
                          },
                        },
                        [
                          _c("md-icon", [_vm._v("phone")]),
                          _c(
                            "md-tooltip",
                            {
                              staticClass: "toggle-tooltip",
                              attrs: { "md-direction": "top" },
                            },
                            [
                              _vm._v(
                                "\n                        Call " +
                                  _vm._s(
                                    _vm.shipmentDetails.contact.name ||
                                      _vm.shipmentDetails.customerName ||
                                      "customer"
                                  ) +
                                  " on\n                        " +
                                  _vm._s(_vm.shipmentDetails.contact.phone) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.stopTimeWindows && _vm.stopTimeWindows.length
            ? _c(
                "div",
                { staticClass: "time-windows content-section" },
                [
                  _c(
                    "span",
                    [
                      _c("md-icon", [_vm._v("schedule")]),
                      _c(
                        "md-tooltip",
                        {
                          staticClass: "toggle-tooltip",
                          attrs: { "md-direction": "top" },
                        },
                        [_vm._v("Stop Time Windows")]
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.stopTimeWindows, function (stop, ndx) {
                    return _c(
                      "span",
                      { key: "tw-" + ndx, staticClass: "section-values" },
                      [
                        _c("label", [_vm._v(_vm._s(stop.label) + ":")]),
                        _vm._v(
                          "\n                " +
                            _vm._s(stop.value) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.shipmentDetails.skills && _vm.shipmentDetails.skills.length
            ? _c(
                "div",
                { staticClass: "skills content-section" },
                [
                  _c(
                    "span",
                    [
                      _c("md-icon", [_vm._v("checklist")]),
                      _c(
                        "md-tooltip",
                        {
                          staticClass: "toggle-tooltip",
                          attrs: { "md-direction": "top" },
                        },
                        [_vm._v("Required Skills")]
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.shipmentDetails.skills, function (skill, ndx) {
                    return _c(
                      "span",
                      {
                        key: "skill-" + ndx,
                        staticClass: "md-chip md-theme-default custom-chip",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(skill) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.loadDetails && _vm.loadDetails.length
            ? _c(
                "div",
                { staticClass: "load content-section" },
                [
                  _c(
                    "span",
                    [
                      _c("md-icon", [_vm._v("scale")]),
                      _c(
                        "md-tooltip",
                        {
                          staticClass: "toggle-tooltip",
                          attrs: { "md-direction": "top" },
                        },
                        [_vm._v("Shipment Load")]
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.loadDetails, function (load, ndx) {
                    return _c(
                      "span",
                      { key: "load-" + ndx, staticClass: "section-values" },
                      [
                        _c("label", [_vm._v(_vm._s(load.label) + ":")]),
                        _vm._v(
                          "\n                " +
                            _vm._s(load.value) +
                            " " +
                            _vm._s(load.symbol) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.shipmentDetails.notes
            ? _c("div", { staticClass: "notes content-section" }, [
                _c(
                  "span",
                  [
                    _c("md-icon", [_vm._v("description")]),
                    _c(
                      "md-tooltip",
                      {
                        staticClass: "toggle-tooltip",
                        attrs: { "md-direction": "top" },
                      },
                      [_vm._v("Shipment Notes")]
                    ),
                  ],
                  1
                ),
                _c("span", [_vm._v(_vm._s(_vm.shipmentDetails.notes))]),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }