<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Create Customer Invoices</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v" class="form-wrapper">
                <div class="form-container">
                    <team-region-member-filter-options
                        v-if="canViewTeamRegion"
                        :team-region-id="teamRegionId"
                        :show-team-member="false"
                        @changed="handleTeamRegionMemberChanged"
                        :strict-team-region-filter="true"
                    />
                    <div class="customer-filter">
                        <customer-autocomplete
                            label="Customer"
                            id="autocustomer"
                            ref="customerAutocomplete"
                            placeholder=""
                            :should-focus="false"
                            v-model="customer"
                            :auto-fill-address="true"
                            @input="handleCustomerChanged"
                            :is-clearable="true"
                            :team-region-id="teamRegionId"
                        />
                    </div>
                    <div class="hide-clear-button">
                        <md-datepicker v-model="startDate" md-immediately :md-debounce="10">
                            <label>Start Date</label>
                        </md-datepicker>
                        <span v-if="startDate == null" class="error">
                            Start date is required.
                        </span>
                    </div>

                    <div class="hide-clear-button">
                        <md-datepicker v-model="endDate" md-immediately :md-debounce="10">
                            <label>End Date</label>
                        </md-datepicker>
                        <span v-if="endDate == null" class="error">
                            End date is required.
                        </span>
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="createInvoices">Create Invoices</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { required } from 'vuelidate/lib/validators';
import { TeamRegionMemberFilterOptions, CustomerAutocomplete } from '@/components';
import moment from 'moment';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';

export default {
    name: 'CreateInvoicesModal',
    components: {
        TeamRegionMemberFilterOptions,
        CustomerAutocomplete
    },
    mixins: [GeneralMixin],
    props: {
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            canViewTeamRegion: 'user/canViewTeamRegion',
            teamRegions: 'team/teamRegions',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        })
    },
    watch: {
        startDate(newVal, oldVal) {
            if (moment(newVal).valueOf() !== moment(oldVal).valueOf()) {
                if (moment(newVal) > moment(this.endDate)) {
                    this.$notify({
                        message: 'Start date cannot be later than the end date.',
                        type: 'danger'
                    });

                    this.startDate = this.endDate;
                }
            }
        },
        endDate(newVal, oldVal) {
            if (moment(newVal).valueOf() !== moment(oldVal).valueOf()) {
                if (moment(newVal) < moment(this.startDate)) {
                    this.$notify({
                        message: 'End date cannot be earlier than the start date.',
                        type: 'danger'
                    });

                    this.endDate = this.startDate;
                }
            }
        }
    },
    mounted() {
        this.teamRegionId = this.user.teamRegionId ? this.user.teamRegionId : null; // not set
    },
    methods: {
        async createInvoices() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                showErrorMessage(this, 'The form has invalid values. Please correct them in order to continue.');
                return;
            }

            this.$_handleLoaderState(true, 'UPDATING...');
            try {
                const api = `/api/customer-invoices/generate?teamRegionId=${this.teamRegionId}&customerId=${
                    this.customerId
                }&startDate=${moment(this.startDate).format('YYYY-MM-DD')}&endDate=${moment(this.endDate).format(
                    'YYYY-MM-DD'
                )}`;
                await handleRequests(api);

                this.$v.$reset();
                this.resolve(true);
            } catch (e) {
                if (e && e.data) {
                    const { message } = e.data[0];
                    showErrorMessage(this, message);
                    this.resolve(false);
                } else {
                    const message = 'There was an error generating the invoices.';
                    showErrorMessage(this, message);
                    this.resolve(false);
                }
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        handleTeamRegionMemberChanged(value) {
            this.teamRegionId = value.teamRegionId;
        },
        handleCustomerChanged() {
            this.customerId = this.customer.customerId;
        }
    },
    data() {
        return {
            startDate: moment()
                .subtract(8, 'days')
                .toDate(),
            endDate: moment()
                .subtract(1, 'days')
                .toDate(),
            teamRegionId: -1,
            customerId: undefined,
            customer: {
                customerId: undefined,
                contact: {
                    name: ''
                }
            }
        };
    },
    validations: {
        startDate: {
            required
        },
        endDate: {
            required
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-container {
    max-width: 600px;
}
::v-deep .time-picker {
    .vs__search:focus {
        min-width: 135px !important;
    }
}
.customer-filter {
    width: 230px;
    float: right;
}
</style>
