<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Create Support User</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <tabs
                :tab-name="['Add Existing User', 'Create User']"
                color-button="warning"
                class="custom-tab-list"
                @switch-panel="handleSwitchPanel"
            >
                <template slot="tab-pane-1">
                    <form-wrapper :validator="$v.formAdd" class="form-wrapper">
                        <div>
                            <form-group name="email" label="Email Address*" :messages="localMessages">
                                <md-input v-model="formAdd.email" />
                            </form-group>
                        </div>

                        <div v-if="joinErrors.length > 0">
                            <ul>
                                <li v-for="error in joinErrors" :key="error.errorCode" class="error">
                                    {{ error.message }}
                                </li>
                            </ul>
                        </div>
                    </form-wrapper>
                </template>
                <template slot="tab-pane-2">
                    <form-wrapper :validator="$v.formCreate" class="form-wrapper">
                        <div>
                            <form-group name="email" label="Email Address*" :messages="localMessages">
                                <md-input v-model="formCreate.email" />
                            </form-group>
                            <form-group name="firstName" label="First name*">
                                <md-input v-model="formCreate.firstName" />
                            </form-group>
                            <form-group name="lastName" label="Last name*">
                                <md-input v-model="formCreate.lastName" />
                            </form-group>
                            <md-checkbox v-model="setPassword" @change="checkSetPassword">Set a password.</md-checkbox>
                            <form-group v-if="setPassword" name="password" label="Password">
                                <md-input v-model="formCreate.password" />
                            </form-group>
                        </div>

                        <div v-if="createErrors.length > 0">
                            <ul>
                                <li v-for="error in createErrors" :key="error.code" class="error">
                                    {{ error.description }}
                                </li>
                            </ul>
                        </div>
                    </form-wrapper>
                </template>
            </tabs>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="create">{{ createButtonText }}</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>
<script>
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { Tabs } from '@/components';

// eslint-disable-next-line import/no-dynamic-require
const { config } = require(`@/config/config.${process.env.NODE_ENV}`);
const matchLocate2uDomain = (value, vm) =>
    value !== undefined &&
    (value.substring(value.indexOf('@') + 1).toUpperCase() === 'LOCATE2U.COM' ||
        value.substring(value.indexOf('@') + 1).toUpperCase() === 'ZOOM2U.COM');

export default {
    name: 'AddSupportUserModal',
    mixins: [GeneralMixin],
    props: {
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    components: { Tabs },
    validations: {
        formCreate: {
            email: {
                required,
                email,
                matchLocate2uDomain
            },
            firstName: {
                required
            },
            lastName: {
                required
            }
        },
        formAdd: {
            email: {
                required,
                email,
                matchLocate2uDomain
            }
        }
    },
    data() {
        return {
            localMessages: {
                matchLocate2uDomain: '{attribute} should end with @locate2u.com or @zoom2u.com'
            },
            formCreate: {
                email: '',
                role: 'Support',
                firstName: null,
                lastName: null,
                password: null,
                teamRegionId: null
            },
            formAdd: {
                email: ''
            },
            setPassword: false,
            createErrors: [],
            joinErrors: [],
            createButtonText: 'Add'
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasAssetAccess: 'user/hasAssetAccess'
        })
    },
    methods: {
        checkSetPassword(e) {
            if (e === false) {
                this.createErrors = [];
                this.formCreate.password = null;
            }
        },
        create() {
            if (this.createButtonText === 'Add') {
                this.$v.formAdd.$touch();

                if (!this.$v.formAdd.$invalid) {
                    this.addExistingUser();
                }
            } else {
                this.$v.formCreate.$touch();

                if (!this.$v.formCreate.$invalid) {
                    this.addNewUser();
                }
            }
        },
        handleSwitchPanel(payload) {
            this.createButtonText = payload === 'Add Existing User' ? 'Add' : 'Create';
        },
        addExistingUser() {
            this.$_handleLoaderState(true, 'SAVING...');
            handleRequests('/api/support/users/add', {
                method: 'post',
                params: {
                    email: this.formAdd.email
                }
            }).then(
                (response) => {
                    const result = response.data;
                    this.$_handleLoaderState(false);
                    this.$messageBox
                        .show({
                            title: 'New User Added Successfully!',
                            body: `User ${this.formAdd.email} was added successfully!`,
                            buttons: ['Confirm']
                        })
                        .then(() => {
                            this.formAdd.email = null;
                            this.$v.$reset();
                            this.resolve(result);
                        });
                },
                (error) => {
                    this.$_handleLoaderState(false);
                    this.joinErrors = error.data;
                }
            );
        },
        addNewUser() {
            this.$_handleLoaderState(true, 'SAVING...');
            const payload = {
                method: 'post',
                data: this.formCreate
            };
            const api = `${config.authority}api/team/users/create`;
            handleRequests(api, payload).then(
                (response) => {
                    const result = response.data;
                    this.$_handleLoaderState(false);
                    this.$messageBox
                        .show({
                            title: 'New User Created Successfully!',
                            body: `Email: ${
                                this.formCreate.email
                            } </br> Temporary Password: ${result} </br></br> The temporary password will be displayed once. Please copy and save it before logging in.\n`,
                            buttons: ['Confirm']
                        })
                        .then(() => {
                            this.formCreate.email = null;
                            this.$v.$reset();
                            this.resolve(result);
                        });
                },
                (error) => {
                    this.$_handleLoaderState(false);
                    this.createErrors = error.data;
                }
            );
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped>
.form-wrapper {
    margin: 25px 15px 0 5px;
}
</style>
