var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-spacing" },
    [
      _c(
        "sidebar-item",
        { attrs: { link: { name: "Inventory", icon: "inventory" } } },
        [
          _c("sidebar-item", {
            attrs: {
              link: {
                name: "Warehouses",
                icon: "store",
                path: "/warehouses/list",
              },
            },
          }),
          _c("sidebar-item", {
            attrs: {
              link: {
                name: "Inventory",
                icon: "inventory",
                path: "/inventory/list",
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }