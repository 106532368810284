var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _vm.selectedCustomField === null
          ? _c("span", [_vm._v("Add Asset Custom Field")])
          : _c("span", [
              _vm._v("Edit " + _vm._s(_vm.selectedCustomField.label)),
            ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          { staticClass: "form-wrapper", attrs: { validator: _vm.$v.form } },
          [
            _vm.selectedCustomField !== null
              ? _c(
                  "form-group",
                  { attrs: { label: "Id", name: "label" } },
                  [
                    _c("md-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "form-group",
              { attrs: { label: "Label", name: "label" } },
              [
                _c("md-input", {
                  model: {
                    value: _vm.form.label,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "label", $$v)
                    },
                    expression: "form.label",
                  },
                }),
              ],
              1
            ),
            _c(
              "form-group",
              { attrs: { name: "type" } },
              [
                _vm.customFieldTypes
                  ? _c("vue-select", {
                      attrs: {
                        options: _vm.customFieldTypes,
                        placeholder: "Type",
                        searchable: _vm.$root.isDesktop,
                      },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "form-group",
              {
                staticClass: "asset-type-filter",
                attrs: { label: "Select asset type", name: "assetType" },
              },
              [
                _c(
                  "md-select",
                  {
                    model: {
                      value: _vm.form.assetType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "assetType", $$v)
                      },
                      expression: "form.assetType",
                    },
                  },
                  _vm._l(_vm.assetTypes, function (assetType, index) {
                    return _c(
                      "md-option",
                      { key: index, attrs: { value: assetType } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(assetType) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _vm.selectedCustomField === null
          ? _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary",
                on: { click: _vm.addAssetCustomField },
              },
              [_vm._v("\n            Add\n        ")]
            )
          : _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary",
                on: { click: _vm.editAssetCustomField },
              },
              [_vm._v("\n            Save\n        ")]
            ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }