<template>
    <div>
        <md-card-content>
            <md-table :value="list" class="table-hover">
                <md-table-row>
                    <md-table-head>Team Region</md-table-head>
                    <md-table-head>User</md-table-head>
                    <md-table-head>Company</md-table-head>
                    <md-table-head># Stops</md-table-head>
                    <md-table-head>Sub Total</md-table-head>
                    <md-table-head>Total Tax</md-table-head>
                    <md-table-head>Total</md-table-head>
                    <md-table-head>Deductions</md-table-head>
                </md-table-row>

                <md-table-row v-for="(item, index) in list" :key="index">
                    <md-table-cell>{{ item.region }}</md-table-cell>
                    <md-table-cell>{{ item.userName }}</md-table-cell>
                    <md-table-cell>{{ item.company }}</md-table-cell>
                    <md-table-cell>{{ item.numberOfStops }}</md-table-cell>
                    <md-table-cell>{{ item.subTotal | currency(invoiceCurrency) }}</md-table-cell>
                    <md-table-cell>{{ item.totalTax | currency(invoiceCurrency) }}</md-table-cell>
                    <md-table-cell>{{ item.total | currency(invoiceCurrency) }}</md-table-cell>
                    <md-table-cell class="deductions-font">
                        ({{ item.deduction | currency(invoiceCurrency) }})
                    </md-table-cell>
                </md-table-row>

                <md-table-row>
                    <md-table-cell colspan="3" />
                    <md-table-cell class="total-style">
                        {{ totalnumberofStops }}
                    </md-table-cell>
                    <md-table-cell class="total-style">
                        {{ totalSubTotal | currency(invoiceCurrency) }}
                    </md-table-cell>
                    <md-table-cell class="total-style">
                        {{ totalTotalTax | currency(invoiceCurrency) }}
                    </md-table-cell>
                    <md-table-cell class="total-style">
                        {{ totalTotal | currency(invoiceCurrency) }}
                    </md-table-cell>
                    <md-table-cell class="total-style deductions-font">
                        {{ totalDeduction | currency(invoiceCurrency) }}
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </md-card-content>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins';

export default {
    name: 'DriverPaymentSummaryDetails',
    components: {},
    mixins: [GeneralMixin],
    props: {
        list: {
            type: Array,
            default: () => []
        },
        invoiceCurrency: {
            type: String,
            default: null
        }
    },
    computed: {
        totalSubTotal() {
            let total = 0;
            this.list.forEach((line) => {
                total += Math.round((line.subTotal + Number.EPSILON) * 100) / 100;
            });
            return total;
        },
        totalTotalTax() {
            let total = 0;
            this.list.forEach((line) => {
                total += Math.round((line.totalTax + Number.EPSILON) * 100) / 100;
            });
            return total;
        },
        totalTotal() {
            let total = 0;
            this.list.forEach((line) => {
                total += Math.round((line.total + Number.EPSILON) * 100) / 100;
            });
            return total;
        },
        totalDeduction() {
            let total = 0;
            this.list.forEach((line) => {
                total += Math.round((line.deduction + Number.EPSILON) * 100) / 100;
            });
            return total;
        },
        totalnumberofStops() {
            let total = 0;
            this.list.forEach((line) => {
                total += line.numberOfStops;
            });
            return total;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

::v-deep .md-table-head {
    cursor: default;
}

.table-hover {
    cursor: pointer;
}

.deductions-font {
    color: red !important;
}

.total-style {
    font-weight: bold !important;
}
</style>
