var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("transition", { attrs: { name: "modal" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c(
            "div",
            { staticClass: "modal-wrapper" },
            [
              _c(
                _vm.modalComponent,
                _vm._b({ tag: "component" }, "component", _vm.modalAttrs, false)
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }