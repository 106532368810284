<template>
    <md-table class="context-menu-support custom-paginated-table" v-if="hasQuestionnaires">
        <md-table-row>
            <md-table-head>Question</md-table-head>
            <md-table-head>Mapping</md-table-head>
            <md-table-head></md-table-head>
            <md-table-head>
                Overridable
                <md-icon
                    class="info-icon-custom"
                    title="You will be able to override the values when assigning a shipment to a carrier"
                >
                    help_outline
                </md-icon>
            </md-table-head>
        </md-table-row>
        <md-table-row v-for="(q, ndx) in $v.questionMappings.$each.$iter" :key="ndx">
            <md-table-cell class="questions-column" :class="q.$model.isRequired ? 'required-question' : ''">
                {{ q.$model.label }}
                <span v-if="showToolTip(q.$model)">
                    <md-icon class="info-icon-custom">
                        help_outline
                        <md-tooltip>{{ showToolTip(q.$model) }}</md-tooltip>
                    </md-icon>
                    <md-icon class="required-icon" v-if="q.$model.isRequired">
                        emergency
                        <md-tooltip>This is a required field</md-tooltip>
                    </md-icon>
                </span>
            </md-table-cell>
            <md-table-cell>
                <form-group name="question" :class="{ error: q.mapping.$error }">
                    <vue-select
                        :reduce="(quest) => quest.value"
                        label="text"
                        :options="q.$model.isRequired ? requiredMappingOptions : nonRequiredMappingOptions"
                        :placeholder="q.$model.isRequired ? 'Ask when booking' : 'Ignore'"
                        v-model="q.mapping.$model"
                        :searchable="false"
                        class="custom-mapping-select"
                    ></vue-select>
                </form-group>
            </md-table-cell>
            <md-table-cell align="end" class="action-buttons">
                <template v-if="q.mapping.$model === 'SetDefault'">
                    <form-group
                        name="name"
                        :class="{
                            error: q.defaultValue.$error,
                            'field-text': q.$model.type == 'Text',
                            'checkbox-field': q.$model.type == 'CheckBox',
                            'dropdown-field': q.$model.type == 'Dropdown',
                            'textarea-custom-field': q.$model.type == 'MultiLineText'
                        }"
                    >
                        <CustomFieldInputs
                            :custom-field-definition="q.$model"
                            :initial-value="q.defaultValue.$model"
                            :show-label="false"
                            placeholder="Value"
                            @changed="handleCustomFieldChanged"
                        />
                    </form-group>
                </template>
                <template v-if="q.mapping.$model === 'CustomField'">
                    <form-group
                        name="name"
                        :class="{
                            error: q.customFieldName.$error
                        }"
                    >
                        <vue-select
                            :reduce="(cf) => cf.name"
                            label="label"
                            :options="getValidCustomFields(q.$model.type)"
                            placeholder="Select a custom field"
                            v-model="q.customFieldName.$model"
                            :searchable="false"
                            class="custom-mapping-select"
                        ></vue-select>
                    </form-group>
                </template>
            </md-table-cell>
            <md-table-cell class="overridable-col">
                <md-checkbox v-if="q.mapping.$model === 'SetDefault'" v-model="q.$model.overridable"></md-checkbox>
            </md-table-cell>
        </md-table-row>
    </md-table>
    <div v-else>This carrier has no booking questions requiring mapping.</div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';
import { CustomFieldInputs } from '@/components';
import { requiredIf } from 'vuelidate/lib/validators';

export default {
    name: 'CarrierQuestions',
    mixins: [GeneralMixin],
    props: {
        carrierTeamid: { type: [String, Number], default: () => null },
        questionsMapping: { type: Array, default: () => [] },
        bookingQuestions: { type: Array, default: () => [] },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            questionMappings: [],
            // hasQuestionnaires: false,
            requiredMappingOptions: [
                {
                    text: 'Ask when booking',
                    value: 'AskWhenBooking'
                },
                {
                    text: 'Custom Field',
                    value: 'CustomField'
                },
                {
                    text: 'Default Value',
                    value: 'SetDefault'
                }
            ],
            nonRequiredMappingOptions: [
                {
                    text: 'Ask when booking',
                    value: 'AskWhenBooking'
                },
                {
                    text: 'Custom Field',
                    value: 'CustomField'
                },
                {
                    text: 'Default Value',
                    value: 'SetDefault'
                },
                {
                    text: 'Ignore',
                    value: 'Ignore'
                }
            ]
        };
    },
    components: {
        CustomFieldInputs
    },
    validations() {
        return {
            questionMappings: {
                $each: {
                    mapping: {
                        required: requiredIf((item) => {
                            return item.isRequired;
                        })
                    },
                    customFieldName: {
                        required: requiredIf((item) => {
                            return item.mapping === 'CustomField';
                        })
                    },
                    defaultValue: {
                        required: requiredIf((item) => {
                            return item.isRequired && item.mapping === 'SetDefault';
                        })
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        }),
        hasQuestionnaires() {
            return this.questionMappings && this.questionMappings.length;
        }
    },
    methods: {
        showToolTip(itemModel) {
            if (itemModel.description == null) 
                return false;

            if (itemModel.isRequired) 
                return `Required. ${itemModel.description}`;
            return itemModel.description;
        },
        async isInvalid() {
            this.$v.questionMappings.$touch();
            this.$emit('isInvalid', this.$v.questionMappings.$invalid);
        },
        handleCustomFieldChanged({ name, value, type }) {
            const questionIndex = this.questionMappings.findIndex((x) => x.name === name);
            const updatedQuestion = this.questionMappings[questionIndex];

            let parsedValue = value;
            if (!!value && type.toLowerCase() === 'date') {
                parsedValue = moment(value).format('YYYY-MM-DD');
            }

            updatedQuestion.defaultValue = parsedValue;
            this.$set(this.questionMappings, questionIndex, updatedQuestion);
        },
        getValidCustomFields(questionType) {
            if (this.user.shipmentCustomFieldDefinitions && this.user.shipmentCustomFieldDefinitions.length) {
                return this.user.shipmentCustomFieldDefinitions.filter((x) => x.type === questionType);
            }
            return [];
        },
        parseValue(type, value) {
            switch (type) {
                case 'CheckBox':
                    return value === 'true';
                default:
                    return value;
            }
        },
        setValue(answer) {
            if (answer.mapping === 'SetDefault') {
                if (answer.type === 'CheckBox') {
                    return (answer.defaultValue || false).toString();
                }
                return answer.defaultValue ? answer.defaultValue.toString() : null;
            }
            return null;
        },
        async mapCarrierQuestions() {
            try {
                const data = this.questionsMapping;
                const mappings = [];

                if (data && data.length) {
                    for (let ndx = 0; ndx < this.bookingQuestions.length; ndx++) {
                        // find if the question has existing mapping already
                        const question = this.bookingQuestions[ndx];
                        const currentMapping = data.find((x) => x.name === question.name);
                        if (currentMapping) {
                            mappings.push({
                                ...question,
                                mapping: currentMapping.mapping,
                                defaultValue: this.parseValue(question.type, currentMapping.defaultValue),
                                customFieldName: currentMapping.customFieldName,
                                overridable: currentMapping.overridable
                            });
                        } else {
                            mappings.push({
                                ...question,
                                mapping: null,
                                defaultValue: null,
                                customFieldName: null,
                                overridable: true
                            });
                        }
                    }

                    this.questionMappings = [...mappings];
                } else {
                    this.questionMappings = this.bookingQuestions.map((x) => {
                        return {
                            ...x,
                            mapping: null,
                            defaultValue: null,
                            customFieldName: null,
                            overridable: true
                        };
                    });
                }

                this.questionMappings = this.questionMappings.sort(this.sortByIsRequiredAndAlphabetical);
            } catch (e) {
                const message = 'Error in retrieving mappings';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        sortByIsRequiredAndAlphabetical(a, b) {
            if (a.isRequired && !b.isRequired) {
                return -1;
            }

            if (!a.isRequired && b.isRequired) {
                return 1;
            }

            return a.label.localeCompare(b.label);
        }
    },
    mounted() {
        this.mapCarrierQuestions();
    }
};
</script>

<style lang="scss" scoped>
.error {
    border: solid 1px red;
    max-width: 200px;
}

.info-icon-custom {
    min-width: 20px;
    font-size: 20px !important;
}

::v-deep .md-field:after {
    height: 0;
}

.field-text {
    display: block;
    margin-top: 0;
    padding-bottom: 0;
}

.field-text ::v-deep .md-field:after {
    height: 1px;
}

.field-text ::v-deep .md-field {
    width: 100%;
    margin-top: 0;
    padding-bottom: 0px;
    max-width: 200px;
    min-width: 140px;
}

.field-text ::v-deep .md-field:before,
.field-text ::v-deep .md-field:after {
    bottom: 0px;
}

.field-text ::v-deep .md-input {
    padding-bottom: 3px;
    max-width: 200px;
    min-width: 140px;
    padding: 0 6px 3px 6px;
}

.checkbox-field {
    display: block;
    text-align: center;
}

.dropdown-field {
    padding-bottom: 0 !important;
    margin-top: 0;
}

.custom-mapping-select,
.dropdown-field > div {
    width: 100%;
    max-width: 200px;
    ::v-deep .vs__clear {
        display: none;
    }
    ::v-deep .vs__search,
    .vs__search:focus {
        font-size: 14px;
        padding: 0;
    }

    ::v-deep .vs__selected-options {
        padding-left: 5px;
        display: inline-flex;
        flex-grow: unset;
        flex-wrap: unset;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ::v-deep ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #aaa;
        opacity: 1; /* Firefox */
        padding: 0;
    }

    ::v-deep :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #aaa;
        padding: 0;
    }

    ::v-deep ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #aaa;
        padding: 0;
    }

    ::v-deep .vs__dropdown-menu {
        white-space: nowrap;
        width: 160px;
        min-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.action-buttons .custom-mapping-select {
    width: 100%;
    max-width: 200px;
}

::v-deep .md-checkbox {
    margin: 10px 10px 10px 0;
}

.questions-column {
    width: 250px;
}

::v-deep .md-datepicker:after {
    height: 1px;
}

::v-deep .md-datepicker {
    max-width: 200px;
}

.textarea-custom-field {
    padding-bottom: 0;
}

.textarea-custom-field > div {
    width: 100%;

    ::v-deep .md-has-textarea {
        padding-bottom: 0 !important;
        max-width: 200px;
    }

    ::v-deep .md-textarea {
        margin-top: 10px;
        border: 1px solid #ddd;
        width: 100%;
        margin: 0;
    }
}

.action-buttons ::v-deep .md-field {
    min-width: 140px !important;
}

.required-note {
    font-size: 10px;
    text-align: right;
    position: absolute;
    bottom: 0;
    left: 12px;
    color: red;
}

.required-icon {
    color: red !important;
    font-size: 10px !important;
}
::v-deep .md-table-cell-container {
    font-weight: 400;
}

.required-question {
    ::v-deep .md-table-cell-container {
        font-weight: 500;
    }
}

.overridable-col {
    text-align: center;
}

::v-deep .md-table.md-theme-default .md-table-row td {
    border: none;
}

::v-deep .md-table-cell {
    padding: 0 8px;
}

::v-deep .vs--unsearchable .vs__dropdown-toggle {
    min-width: 180px;
}
</style>
