<template>
    <div class="content">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-blue">
                <div class="card-icon">
                    <md-icon>fact_check</md-icon>
                </div>
            </md-card-header>

            <md-card-content>
                <md-table class="custom-paginated-table">
                    <md-table-row slot="header">
                        <md-table-head></md-table-head>
                        <md-table-head>Name</md-table-head>
                        <md-table-head>Used By</md-table-head>
                        <md-table-head>Actions</md-table-head>
                    </md-table-row>

                    <md-table-row
                        v-for="(template, index) in templates"
                        :key="index"
                        :class="{
                            'deleted-row': template.actionStatus === 'deleted',
                            'deleting-row': template.actionStatus === 'deleting' || template.actionStatus === 'deleted'
                        }"
                    >
                        <md-table-cell class="default-icon-column">
                            <md-icon class="pod-default">{{ template.isDefault ? 'star' : '' }}</md-icon>
                        </md-table-cell>
                        <md-table-cell>
                            {{ template.name }}
                        </md-table-cell>
                        <md-table-cell>
                            {{ $_getUsedByLabel(template.usedBy) }}
                        </md-table-cell>
                        <md-table-cell>
                            <md-button
                                title="Set as default"
                                class="md-just-icon md-round action-button"
                                v-if="!template.isDefault"
                                :disabled="isTemplateDisabled(template)"
                                @click="setDefaultTemplate(template)"
                            >
                                <md-icon>star</md-icon>
                            </md-button>
                            <router-link
                                :to="{
                                    name: 'Edit Proof Of Delivery Template',
                                    params: { podTemplateId: template.podTemplateId }
                                }"
                            >
                                <md-button
                                    title="Edit Template"
                                    class="md-warning md-just-icon md-round action-button"
                                    :disabled="isTemplateDisabled(template)"
                                >
                                    <md-icon>edit</md-icon>
                                </md-button>
                            </router-link>
                            <md-button
                                title="Delete Template"
                                class="md-danger md-just-icon md-round"
                                :disabled="template.isDefault || isTemplateDisabled(template)"
                                @click="deleteTemplate(template)"
                            >
                                <fade-loader
                                    v-if="template.actionStatus === 'deleting' || template.actionStatus === 'deleted'"
                                    class="delete-spinner"
                                    width="5px"
                                    height="5px"
                                    radius="8px"
                                    color="white"
                                />
                                <md-icon v-else>delete</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
            </md-card-content>
        </md-card>

        <md-card-actions class="page-footer" md-alignment="space-between">
            <div>
                <p v-if="total === pagination.perPage" class="card-category">
                    Page {{ pagination.currentPage }} of many
                </p>
                <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
            </div>
            <pagination
                v-model="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                :enable-first-page-link="true"
                :enable-last-page-link="true"
                @change-page="handleChangePage($event, pagination.perPage)"
            />
        </md-card-actions>
    </div>
</template>

<script>
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { Pagination } from '@/components';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';

export default {
    name: 'ProofOfDeliveryTemplateList',
    components: {
        Pagination,
        FadeLoader
    },
    mixins: [GeneralMixin],
    data() {
        return {
            templates: [],
            pagination: PAGINATION_DEFAULTS
        };
    },
    computed: {
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        }
    },
    async mounted() {
        this.$_handleLoaderState(true);
        const { templates, totalTemplates } = await this.handleGetTemplatesOnLoad();
        this.assignTemplates(templates, totalTemplates || templates.length);
        this.$_handleLoaderState(false);
    },
    methods: {
        async handleGetTemplatesOnLoad() {
            const currentPage = Number(this.$route.query.currentPage) || 1;

            const {
                data: { templates, totalTemplates }
            } = await this.getTemplates(currentPage, this.pagination.perPage);

            if (currentPage) {
                this.pagination.currentPage = currentPage;
            }

            return { templates, totalTemplates };
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.$_handleLoaderState(true);
            this.showStopDetails = false;
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            const {
                data: { templates, totalTemplates }
            } = await this.getTemplates(currentPage, perPage);
            const templatesToAssign = totalTemplates || templates.length;
            this.assignTemplates(templates, templatesToAssign);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            this.$_handleLoaderState(false);
        },
        async handleRefresh() {
            await this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        assignTemplates(templates, totalTemplates) {
            this.templates = templates.map((x) => {
                return { ...x, actionStatus: 'active' };
            });
            this.pagination.total = totalTemplates;
        },
        async getTemplates(pageNumber = 1, itemsPerPage = 50) {
            const api = `/api/teams/proof-of-delivery/list`;
            const response = await handleRequests(api, {
                params: {
                    pageNumber,
                    itemsPerPage
                }
            });

            return response;
        },
        async deleteTemplate(template) {
            const deleteMessage = 'Are you sure you want to delete this proof of delivery template?'.concat(
                template.usedBy.length === 0
                    ? ''
                    : '<br /> <br /> Warning: Assigned customers will revert to the default template.'
            );

            this.$messageBox
                .show({
                    title: 'Delete proof of delivery template',
                    body: deleteMessage,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        template.actionStatus = 'deleting';
                        const payload = {
                            method: 'delete'
                        };
                        const api = `/api/teams/proof-of-delivery/${template.podTemplateId}`;
                        try {
                            await handleRequests(api, payload);
                            this.$notify({
                                message: 'Proof of Delivery template deleted!',
                                type: 'success'
                            });
                            template.actionStatus = 'deleted';
                        } catch (e) {
                            template.actionStatus = 'active';
                            const message = 'Cannot delete proof of delivery template.';
                            showErrorMessage(this, message, e);
                        }
                    }
                });
        },
        setDefaultTemplate(template) {
            const moreThanOneCustomer = template.usedBy.length > 1;
            const setDefaultMessage = 'Are you sure you want to set this as the default proof of delivery template?'.concat(
                template.usedBy.length === 0
                    ? ''
                    : `<br /> <br /> Warning: There ${moreThanOneCustomer ? 'are' : 'is'} ${
                        template.usedBy.length
                    } customer${
                        moreThanOneCustomer ? 's' : ''
                    } assigned to this template. Setting this as the default proof of delivery template will unassign customers from this template.`
            );

            this.$messageBox
                .show({
                    title: 'Set default proof of delivery template',
                    body: setDefaultMessage,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'SETTING AS DEFAULT...');
                        const payload = {
                            method: 'put',
                            data: { podTemplateId: template.podTemplateId }
                        };
                        const api = '/api/teams/proof-of-delivery/set-default';
                        try {
                            await handleRequests(api, payload);
                            this.$notify({
                                message: 'Proof of delivery template is set as default!',
                                type: 'success'
                            });

                            this.$_handleLoaderState(false);
                        } catch (e) {
                            this.$_handleLoaderState(false);
                            const message = 'Cannot set default proof of delivery template.';
                            showErrorMessage(this, message, e);
                        }

                        this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
                    }
                });
        },
        isTemplateDisabled(template) {
            return template.actionStatus === 'deleting' || template.actionStatus === 'deleted';
        }
    }
};
</script>
<style lang="scss" scoped>
.card-icon {
    background: #0bda8e !important;
}
::v-deep .md-card-actions {
    padding: 0;
    &.page-footer {
        padding-top: 0px;
    }
}
.pod-default {
    color: rgb(255, 210, 48) !important;
}
.action-button {
    margin-right: 5px;
}
.default-icon-column {
    width: 45px;
}
::v-deep .deleted-row td {
    border-top: 0;
    height: 0;
    padding: 0;
    margin: 0;
    transition: height 0.3s;

    div {
        height: 0;
        overflow: hidden;
        transition: height 0.3s;
    }
}
.deleting-row {
    background-color: rgba(255, 0, 0, 0.1);
}
.delete-spinner {
    top: -5px;
    left: -4px;
}
</style>
