<template>
    <md-button
        v-if="type === 'icon'"
        title="Show qualified drivers"
        :class="`md-just-icon md-round md-primary ${className}`"
        @click.stop="handleShowDrivers"
    >
        <md-icon>people</md-icon>
    </md-button>
</template>

<script>
export default {
    name: 'JobOfferButton',
    props: {
        recordType: { type: String, required: true },
        shipmentDetails: { type: Object, required: true },
        className: {
            type: String,
            default: () => ''
        },
        type: {
            type: String,
            default: () => 'icon'
        }
    },
    methods: {
        handleShowDrivers() {
            const url = `/${this.recordType.toLowerCase()}s/${this.shipmentDetails.shipmentId}/dispatch`;
            window.open(url, '_blank');
        }
    }
};
</script>
