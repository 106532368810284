<template>
    <span>
        <md-icon v-if="data == null">
            {{ icon }}
            <md-tooltip :md-direction="direction">No location update ever received</md-tooltip>
        </md-icon>
        <md-icon v-else :class="`${getLastLocationUpdateIndicatorColor(convertedDate)}-indicator ${customClass}`">
            {{ data.source != null ? (data.source === 'Tracker' ? 'drive_eta' : 'phone_iphone') : icon }}
            <md-tooltip :md-direction="direction" v-if="data.lastLocationUpdate">
                Last location update:
                <br />
                {{ convertedDate | dateFormat(dateTypes.standardDateTime) }}
            </md-tooltip>
        </md-icon>
    </span>
</template>
<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins';
import { DATE_TYPES } from '@/utils/constants';
import { convertUtcTimeStringToLocalDate } from '@/helpers';

export default {
    name: 'TrackingLocationDataIndicator',
    mixins: [GeneralMixin],
    props: {
        data: {
            type: Object,
            default: () => null
        },
        dateTime: {
            type: String,
            default: null
        },
        direction: {
            type: String,
            default: 'top'
        },
        customClass: {
            type: String,
            default: ''
        },
        renderKey: {
            type: Number,
            default: 0
        },
        icon: {
            type: String,
            default: 'phone_iphone'
        }
    },
    data() {
        return {
            dateTypes: DATE_TYPES
        };
    },
    computed: {
        convertedDate() {
            const { lastLocationUpdate, utcOffset } = this.data;
            return convertUtcTimeStringToLocalDate(lastLocationUpdate, utcOffset);
        }
    },
    watch: {
        renderKey() {
            this.$forceUpdate();
        }
    },
    methods: {
        getLastLocationUpdateIndicatorColor(dateTime) {
            if (!dateTime) 
                return 'gray';

            const now = moment();
            const time = moment(dateTime);
            const duration = Math.ceil(moment.duration(now.diff(time)).as('minutes'));
            let color = '';

            switch (true) {
                case duration <= 2:
                    color = 'green';
                    break;
                case duration > 2 && duration <= 11:
                    color = 'orange';
                    break;
                default:
                    color = 'red';
            }

            return color;
        }
    }
};
</script>
