<template>
    <div class="sidebar-spacing">
        <sidebar-item :link="{ name: 'Accounting', icon: 'paid' }">
            <sidebar-item :link="{ name: 'Team Member Invoices', icon: 'store', path: '/team-member-invoices/list' }" />

            <sidebar-item :link="{ name: 'Customer Invoices', icon: 'people', path: '/customer-invoices/list' }" />
            <sidebar-item
                v-if="hasTeamAccess"
                :link="{ name: 'Invoice Adjustments', icon: 'people', path: '/invoice-adjustments/list' }"
            />
            <sidebar-item
                v-if="(!isIndividualUser && $root.isDesktop && isTeamOwner && hasRateRules) || isAdmin"
                :link="{ name: $t('menus.rates'), icon: 'price_change', path: '/rates/list' }"
            />
            <sidebar-item
                v-if="(!isIndividualUser && $root.isDesktop && isTeamOwner) || isAdmin"
                :link="{ name: $t('menus.processCharges'), icon: 'price_check', path: '/processcharges/list' }"
            />
        </sidebar-item>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            isAdmin: Boolean(this.$route.query.isAdmin)
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasTeamSettingsAccess: 'user/hasTeamSettingsAccess',
            isTeamOwner: 'user/isTeamOwner',
            hasRateRules: 'user/hasRateRules',
            isIndividualUser: 'user/isIndividualUser',
            hasTeamAccess: 'user/hasTeamAccess'
        })
    }
};
</script>
