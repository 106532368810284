<template>
    <div :class="{ 'not-paid-User': isTrialExpired }">
        <div class="street-view" ref="streetView" :style="{ zIndex: isStreetView ? '3' : '-1' }"></div>
        <div v-if="isStreetView" class="close-button">
            <md-button class="md-white md-icon-button" :md-ripple="false" @click="$_map_closeStreetView(false)">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-warning">
                <div class="card-icon">
                    <md-icon>map</md-icon>
                </div>
            </md-card-header>

            <md-card-content>
                <div ref="map" class="map"></div>
                <md-content
                    v-if="showInfoWindow"
                    class="info-tooltip md-elevation-2 md-layout"
                    :style="infoWindowStyles"
                    v-click-outside="closeInfoWindow"
                >
                    <md-content class="button--actions">
                        <!--<md-button
                            title="Show street view"
                            class="md-just-icon md-round md-info"
                            @click="showStreetView(content.latitude, content.longitude)"
                        >
                            <md-icon>location_searching</md-icon>
                        </md-button>-->
                    </md-content>
                    <md-content v-if="content.isShipmentStatus" class="info-detail--container shipment-status--detail">
                        <md-icon>contacts</md-icon>
                        <md-content class="info-detail">
                            <span class="label">Changed By</span>
                            <span>{{ content.changedBy }}</span>
                        </md-content>
                    </md-content>
                    <md-content class="info-detail--container shipment-status--detail" v-if="content.isShipmentStatus">
                        <md-icon>date_range</md-icon>
                        <md-content class="info-detail">
                            <span class="label">Location Change</span>
                            <span>{{ content.actionDate | dateTimeFormat }}</span>
                        </md-content>
                    </md-content>
                    <md-content
                        v-if="content.isShipmentStatus"
                        class="info-detail--container shipment-status--detail shipment-status--change"
                    >
                        <span :style="{ color: setColor(content.oldStatus) }">
                            {{ content.oldStatus }}
                        </span>
                        <md-icon class="arrow-icon">arrow_right_alt</md-icon>
                        <span :style="{ color: setColor(content.newStatus) }">
                            {{ content.newStatus }}
                        </span>
                    </md-content>
                    <md-content v-if="content.isShipment" class="info-detail--container">
                        <md-icon>location_on</md-icon>
                        <md-content class="info-detail shipment-detail">
                            <span class="label">Address</span>
                            <span>{{ content.address }}</span>
                        </md-content>
                    </md-content>
                </md-content>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
import { GeneralMixin, MapOverviewMixin } from '@/mixins';
import { MarkerPins } from '@/utils/MarkerPins';
import { handleRequests, getShipmentStatusColour } from '@/helpers';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    name: 'MapOverlay',
    mixins: [GeneralMixin, MapOverviewMixin],
    props: {
        shipmentDetails: {
            type: Object,
            default: () => {}
        },
        shipmentStatusHistory: {
            type: Array,
            default: () => {}
        },
        clickedHistory: {
            type: Object,
            default: () => {}
        }
    },
    async mounted() {
        await this.$_map_loadGoogleMap();
        this.initializeMap();
    },
    data() {
        return {
            map: null,
            bounds: null,
            markers: [],
            panorama: null,
            isStreetView: false,
            showInfoWindow: false,
            infoWindowStyles: {},
            content: {},
            dimensions: {
                height: 125,
                width: 117.5
            },
            currentlySelectedMarker: null
        };
    },
    computed: {
        ...mapGetters({
            isTrialExpired: 'team/isTrialExpired'
        })
    },
    watch: {
        'clickedHistory.status': function(val) {
            if (this.currentlySelectedMarker) {
                this.$_map_callMapFunction(this.currentlySelectedMarker, 'setZIndex', [10]);
            }
            const marker = this.markers.find(
                (m) => m.status === val && m.actionDate === this.clickedHistory.actionDate
            );

            const markerColors = getShipmentStatusColour(marker.status);

            this.$_map_callMapFunction(this.map, 'panTo', [marker.getPosition()]);
            this.$_map_callMapFunction(marker, 'setZIndex', [99]);
            this.$_map_callMapFunction(this.map, 'setZoom', [14]);

            // scale up the marker to make it stand out more
            this.$_map_setMarkerIcon(marker, { scale: 8, ...markerColors });
            this.$_map_callMapFunction(marker, 'setAnimation', [google.maps.Animation.BOUNCE]);

            // currently google's animation will continue until shipmentped manually, so we put a 3 second offset before we shipment the animation and scale the marker down to normal size
            setTimeout(() => {
                this.$_map_setMarkerIcon(marker, { scale: 6, ...markerColors });
                this.$_map_callMapFunction(marker, 'setAnimation', [null]);
            }, 2750);

            this.currentlySelectedMarker = marker;
        }
    },
    methods: {
        async initializeMap() {
            const {
                tripDate,
                assignedTo: { publicUserId },
                dropStop: { stopId }
            } = this.shipmentDetails;
            const date = this.$options.filters.dateFormat(tripDate, this.DATE_TYPES.internationalDate);
            const driverDetailsEndpoint = `/api/trips/${date}?userId=${publicUserId}&untilStopId=${stopId}`;

            const pickuplat = this.shipmentDetails.pickupStop.location.latitude;
            const pickuplng = this.shipmentDetails.pickupStop.location.longitude;
            const { status } = this.shipmentDetails;
            const isActiveShipment = ['complete', 'cancelled'].indexOf(status.toLowerCase()) === -1;

            const element = this.$refs.map;

            const options = {
                zoomControl: false,
                mapTypeControl: false,
                gestureHandling: 'cooperative'
            };

            this.map = this.$_map_createMap(element, pickuplat, pickuplng, options);
            this.bounds = this.$_map_createBounds();
            const pins = MarkerPins[0];
            if (publicUserId && isActiveShipment) {
                const {
                    data: {
                        data: { routeDetails, routePolyline }
                    }
                } = await handleRequests(driverDetailsEndpoint);

                if (routeDetails && routeDetails.coordinates) {
                    const {
                        coordinates: { latitude, longitude },
                        route
                    } = routeDetails;
                    this.showDriverMarker({ latitude, longitude }, route, pins);
                    if (route) {
                        this.showRoute(route, pins);
                    }
                } else {
                    // eslint-disable-next-line no-lonely-if
                    const today = moment().format(this.DATE_TYPES.internationalDate);
                    // eslint-disable-next-line no-lonely-if
                    if (routePolyline && moment(date).isAfter(today)) {
                        this.showRoute(routePolyline, pins);
                    }
                }
            }

            this.showShipmentMarker(pins);
            this.showShipmentStatusMarkers();
            this.$_map_callMapFunction(this.map, 'fitBounds', [this.bounds]);

            // const panoramaContainer = this.$refs.streetView;

            // this.panorama = this.$_map_createPanorama(this.map, panoramaContainer);
            // const panoramaOptions = {
            //    scrollwheel: false,
            //    disableDefaultUI: true,
            //    clickToGo: false,
            //    enableCloseButton: true
            // };

            // const pov = { heading: 265, pitch: 0 };
            // this.$_map_setPanoramaOptions(this.panorama, panoramaOptions, pov);
            // this.panorama.setVisible(false);
        },
        showShipmentMarker(pins) {
            this.setMarker(this.shipmentDetails.pickupStop, MarkerPins[1]);
            this.setMarker(this.shipmentDetails.dropStop, MarkerPins[2]);
        },
        setMarker(shipmentStop, pins) {
            const shipmentMarker = this.$_map_setMarkers(
                shipmentStop.location.latitude,
                shipmentStop.location.longitude,
                'stop',
                pins
            );
            shipmentMarker.addListener('click', () => {
                const obj = {
                    isShipment: true,
                    address: shipmentStop.address,
                    latitude: shipmentStop.location.latitude,
                    longitude: shipmentStop.location.longitude
                };

                this.content = obj;

                // for setting the location of the tooltip (1/2 the width of tooltip + 1/2 width of marker icon)
                this.infoWindowStyles = this.$_map_calculateMarkerCoordinates(
                    shipmentMarker,
                    this.map,
                    this.dimensions
                );

                if (!this.showInfoWindow) 
                    this.showInfoWindow = true;
            });
            this.markers.push(shipmentMarker);
            this.$_map_callMapFunction(this.bounds, 'extend', [shipmentMarker.getPosition()]);
        },
        showShipmentStatusMarkers(pins) {
            if (this.shipmentStatusHistory.length) {
                this.shipmentStatusHistory.forEach((s) => {
                    if (s.location) {
                        const colors = getShipmentStatusColour(s.newStatus);
                        const { latitude, longitude } = s.location;
                        const statusMarker = this.$_map_setMarkers(latitude, longitude, '', colors);
                        statusMarker.addListener('click', () => {
                            const obj = {
                                isShipmentStatus: true,
                                oldStatus: s.oldStatus,
                                newStatus: s.newStatus,
                                changedBy: s.changedByUserFullName,
                                actionDate: s.actionDate,
                                latitude,
                                longitude
                            };

                            this.content = obj;

                            // for setting the location of the tooltip (1/2 the width of tooltip + 1/2 width of marker icon)
                            this.infoWindowStyles = this.$_map_calculateMarkerCoordinates(
                                statusMarker,
                                this.map,
                                this.dimensions
                            );

                            if (!this.showInfoWindow) 
                                this.showInfoWindow = true;
                        });
                        statusMarker.status = s.newStatus.toLowerCase();
                        statusMarker.actionDate = s.actionDate;
                        statusMarker.type = 'historyMarker';
                        this.markers.push(statusMarker);
                        this.$_map_callMapFunction(this.bounds, 'extend', [statusMarker.getPosition()]);
                    }
                });
            }
        },
        showStreetView(lat, lng) {
            // this.isStreetView = true;
            // this.$_map_handlePanorama(this.panorama, lat, lng, true);
        },
        showDriverMarker({ latitude, longitude }, routePath, pins) {
            if (!latitude || !longitude) 
                return;
            // driver marker
            const driverMarker = this.$_map_setMarkers(latitude, longitude, 'driver', pins);
            // driverMarker.addListener('click', () => {
            //    this.isStreetView = true;
            //    this.$_map_handlePanorama(this.panorama, latitude, longitude, true);
            // });
            this.markers.push(driverMarker);

            this.$_map_callMapFunction(this.bounds, 'extend', [driverMarker.getPosition()]);

            // driver route
            if (routePath) {
                this.showRoute(routePath, pins);
            }
        },
        showRoute(routePath, pins) {
            const routeOptions = {
                strokeColor: pins.color,
                strokeOpacity: 1,
                zIndex: 3
            };

            const path = this.$_map_createPath(routePath);

            const route = this.$_map_createRouteLine(path, pins.color, routeOptions, this.map);
            this.$_map_addMapElement(route, this.map);
        },
        setColor(status) {
            return getShipmentStatusColour(status).fillColor;
        },
        closeInfoWindow() {
            if (this.isStreetView) 
                return;
            this.showInfoWindow = false;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';

::v-deep {
    .md-card-content {
        position: relative;
        .map {
            max-height: 480px;
        }
    }
}

.street-view {
    position: fixed !important;
    top: 0;
    left: 0;
    background-color: transparent !important;
    width: 100vw;
    height: 100vh;
}
.close-button {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 4;

    .md-button {
        .md-icon {
            color: black !important;
        }
    }
}
.not-paid-User .close-button {
    // Warning srtip height 60 + prev 15
    top: 75px;
}

.info-tooltip {
    position: absolute;
    line-height: 1.2;
    max-width: 230px;
    padding: 10px;
    border: 1px solid white;
    background-color: white;
    border-radius: 8px;
    color: black;
    z-index: 2;

    display: flex;
    flex-direction: column;

    &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 130px;
        border-top: 8px solid white;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }

    &:after {
        content: '';
        position: absolute;
        left: 130px;
        top: 100%;
        border-top: 8px solid white;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }

    .button--actions {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 3px;

        .md-just-icon {
            min-width: 20px;
            width: 20px;
            height: 20px;

            .md-icon {
                font-size: 12px !important;
            }
        }
    }

    .info-detail--container {
        display: flex;
        margin: auto;
        margin-top: 8px;

        .md-icon {
            margin-left: 0px;
            margin-right: 8px;
            width: 28px;
            height: 28px;
            font-size: 28px !important;
            color: $locate2u-blue-base;
            &.arrow-icon {
                color: black;
                height: 0;
                margin-left: 8px;
            }
        }

        .info-detail {
            display: flex;
            flex-direction: column;

            &.shipment-detail {
                padding-right: 24px;
            }

            span {
                font-size: 14px;
            }

            .label {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: normal;
                color: $grey-400 !important;
            }
        }

        &.shipment-status--change {
            margin: auto !important;
            display: block;
            margin-top: 8px !important;
        }
    }
}
.shipment-status--detail {
    margin-left: 0 !important;
}

.md-content {
    font-weight: bold;
}

::v-deep .md-card .md-card-header .card-icon i {
    color: #fff;
}
</style>
