var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "mobile-filter-header" }, [
      _c("div", { staticClass: "md-layout" }, [
        _c("div", { staticClass: "md-layout-item" }, [
          _c(
            "div",
            { staticClass: "datepicker-container" },
            [
              _c("md-datepicker", {
                ref: "mdDatePicker",
                attrs: {
                  "md-immediately": "",
                  "md-debounce": 100,
                  "md-model-type": String,
                },
                model: {
                  value: _vm.selectedDate,
                  callback: function ($$v) {
                    _vm.selectedDate = $$v
                  },
                  expression: "selectedDate",
                },
              }),
              _c("span", { staticClass: "heavy-font-600" }, [
                _vm._v(
                  _vm._s(_vm._f("dateFormat")(this.selectedDate, "ddd D MMM"))
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "md-layout" }, [
      _c("div", { staticClass: "md-layout-item" }, [
        !_vm.loading
          ? _c("div", [
              _vm.list.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.list, function (tripItem, tripIndex) {
                      return _c(
                        "md-list",
                        {
                          key: tripIndex,
                          staticClass: "trip-list-body",
                          attrs: { "md-expand-single": _vm.expandSingle },
                        },
                        [
                          _c(
                            "md-list-item",
                            {
                              attrs: {
                                "md-expand": "",
                                "md-ripple": false,
                                "md-expanded": tripItem.isOpen,
                              },
                              on: {
                                "update:mdExpanded": function ($event) {
                                  return _vm.$set(tripItem, "isOpen", $event)
                                },
                                "update:md-expanded": function ($event) {
                                  return _vm.$set(tripItem, "isOpen", $event)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "trip-accordion-header",
                                  class: tripItem.isOpen ? "trip-open" : "",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toggleTripItem(
                                        tripItem.user.publicUserId,
                                        tripItem.tripId
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "member-name custom-ellipsis",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(tripItem.user.fullName) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              tripItem.completedStops +
                                                " of " +
                                                tripItem.totalStops +
                                                " stops completed"
                                            ) +
                                            "\n                                        "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "view-map" }, [
                                    _c(
                                      "span",
                                      { staticClass: "view-map" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Maps Overview Active User Panel",
                                                params: {
                                                  date: _vm.selectedDate,
                                                  userId:
                                                    tripItem.user.publicUserId,
                                                },
                                              },
                                              target: "_blank",
                                            },
                                          },
                                          [_c("md-icon", [_vm._v("map")])],
                                          1
                                        ),
                                        _c(
                                          "md-tooltip",
                                          { attrs: { "md-direction": "left" } },
                                          [_vm._v("View stops in a map")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "md-list",
                                {
                                  staticClass: "stop-table-body",
                                  attrs: { slot: "md-expand" },
                                  slot: "md-expand",
                                },
                                [
                                  _vm._l(
                                    tripItem.stopList,
                                    function (stopItem, stopIndex) {
                                      return tripItem.stopList.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              key: stopIndex,
                                              staticClass:
                                                "stop-expand-container",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "stop-contact-address",
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "marker-reference-container",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "location-marker",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              [
                                                                _c(
                                                                  "location-marker",
                                                                  {
                                                                    attrs: {
                                                                      "icon-name":
                                                                        stopIndex +
                                                                        1,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", [
                                                          stopItem.contact !=
                                                            null &&
                                                          stopItem.contact
                                                            .name != null
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "name custom-ellipsis weight-600",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                        " +
                                                                      _vm._s(
                                                                        stopItem.contact !=
                                                                          null
                                                                          ? stopItem
                                                                              .contact
                                                                              .name
                                                                          : ""
                                                                      ) +
                                                                      "\n                                                    "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "stop-ref",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  stopItem.stopRef
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "stop-address weight-600",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "custom-ellipsis",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                stopItem.address
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "stop-status",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            class:
                                                              "status-" +
                                                              stopItem.status.toLowerCase(),
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  stopItem.status
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", [
                                                          _c("span", [
                                                            _vm._v("ETA:"),
                                                          ]),
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "timeFormat"
                                                                )(
                                                                  stopItem.originalEta,
                                                                  _vm.DATE_TYPES
                                                                    .standardTime
                                                                )
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "empty-table",
                                      class: tripItem.isStopListLoading
                                        ? "loader-container"
                                        : "",
                                    },
                                    [
                                      tripItem.isStopListLoading
                                        ? _c(
                                            "div",
                                            { staticClass: "table-loader" },
                                            [
                                              _c("fade-loader", {
                                                attrs: {
                                                  loading: true,
                                                  color: "#333333",
                                                },
                                              }),
                                              _c("span", [_vm._v("LOADING")]),
                                            ],
                                            1
                                          )
                                        : tripItem.stopList.length == 0
                                        ? _c("div", [
                                            _c("span", [
                                              _vm._v(
                                                "No stops available for this trip"
                                              ),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c("div", [
                    _c("span", { staticClass: "empty-list" }, [
                      _vm._v("No trips available for this day"),
                    ]),
                  ]),
            ])
          : _c("div", [
              !_vm.isLoading
                ? _c(
                    "div",
                    { staticClass: "stopbar-loader" },
                    [
                      _c("fade-loader", {
                        attrs: { loading: true, color: "#333333" },
                      }),
                      _c("span", [_vm._v("LOADING")]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }