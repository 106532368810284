<template>
    <div class="md-layout">
        <div class="md-layout-item md-xsmall-size-100 md-medium-size-100">
            <router-link to="/map/">
                <div class="box-container first-box">
                    <div>
                        <div><img src="/img/mobile/dashboard-map.png" alt="Track your team" /></div>
                        <div><span>Track your team</span></div>
                        <div class="navigate-arrow"><md-icon>navigate_next</md-icon></div>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="md-layout-item md-xsmall-size-100 md-medium-size-100">
            <router-link to="/stops/list">
                <div class="box-container">
                    <div>
                        <div><img src="/img/mobile/dashboard-stops.png" alt="Manage your stops" /></div>
                        <div><span>Manage your stops</span></div>
                        <div class="navigate-arrow"><md-icon>navigate_next</md-icon></div>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="md-layout-item md-xsmall-size-100 md-medium-size-100">
            <router-link to="/shipments/list">
                <div class="box-container">
                    <div>
                        <div><img src="/img/mobile/dashboard-shipments.png" alt="Shipments" /></div>
                        <div><span>Shipments</span></div>
                        <div class="navigate-arrow"><md-icon>navigate_next</md-icon></div>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="md-layout-item md-xsmall-size-100 md-medium-size-100">
            <router-link to="/trips/list">
                <div class="box-container">
                    <div>
                        <div><img src="/img/mobile/dashboard-trips.png" alt="Trips" /></div>
                        <div><span>Trips</span></div>
                        <div class="navigate-arrow"><md-icon>navigate_next</md-icon></div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileWelcomeDashboard'
};
</script>

<style lang="scss" scoped>
::v-deep .main-panel-whitebg {
    background-color: #f7fbff;
}

.box-container {
    border: 1px solid #ddd;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    background-color: #f7fbff;
    border-radius: 10px;
    margin: 0 0 15px 0;
    padding: 30px 20px;
    position: relative;
    > div {
        max-width: 90%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        > div {
            > span {
                color: #3c4858;
                font-weight: 600;
                vertical-align: middle;
                font-size: 1.5rem;
            }
        }
        > div:first-child {
            margin-right: 25px;
            min-width: 55px;
        }

        .navigate-arrow {
            position: absolute;
            right: 20px;
            i {
                color: #ddd;
                height: 35px;
                width: 35px;
                min-width: 35px;
            }
        }
    }
}

.first-box {
    margin-top: 40px;
}
</style>
