<template>
    <div class="profile--container map-panel">
        <div>
            <div class="photo-container">
                <router-link
                    class="profile--link"
                    :to="{
                        name: 'Team Member Profile',
                        params: { publicUserId: pinnedDriverDetails.publicUserId }
                    }"
                    target="_blank"
                >
                    <img :src="photoUrl" class="profile--photo" @error="setDefaultBrokenImage" />
                </router-link>

                <!-- Show the driver's online status -->
                <div class="online-status-container">
                    <DriverOnlineStatus
                        :driver-name="pinnedDriverDetails.fullName"
                        :is-online="pinnedOffer.isOnline"
                        icon-size="big"
                    />
                </div>
            </div>

            <div class="contact-driver-container" v-if="pinnedDriverDetails.phone">
                <a :href="`tel:${pinnedDriverDetails.phone}`" class="profile--phone">
                    <md-icon>phone</md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">
                        Call {{ pinnedDriverDetails.fullName }} on {{ pinnedDriverDetails.phone }}
                    </md-tooltip>
                </a>

                <a @click="sendDriverSms()" href="#">
                    <md-icon>textsms</md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">
                        Send {{ pinnedDriverDetails.fullName }} an SMS
                    </md-tooltip>
                </a>
            </div>
        </div>

        <div class="profile-details-container">
            <span class="profile--fullname">
                {{ pinnedDriverDetails.fullName }}
            </span>

            <div
                class="details-box md-chips md-primary md-theme-default md-has-placeholder md-theme-default"
                v-if="pinnedDriverDetails.skills.length > 0"
            >
                <div
                    class="md-chip md-theme-default custom-chip"
                    v-for="(skill, ndx) in pinnedDriverDetails.skills"
                    :key="ndx"
                >
                    {{ skill }}
                </div>
            </div>

            <md-button class="md-success assign-button" @click="assignShipment" v-if="!isAssigned">
                Assign
            </md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins';
import { showErrorMessage, handleRequests } from '@/helpers';
import useGeneralUtils from '@/compostables/useGeneralUtils';
import SendSmsModal from '@/components/SmsSettings/SendSmsModal';
import DriverOnlineStatus from './DriverOnlineStatus';

export default {
    name: 'DriverProfile',
    components: { DriverOnlineStatus },
    props: {
        shipmentDetails: {
            type: Object,
            default: null
        },

        pinnedOffer: {
            type: Object,
            default: null
        },

        pinnedDriverDetails: {
            type: Object,
            default: () => {}
        },

        photoUrl: {
            type: String,
            default: '/img/profile-pic.png'
        }
    },

    computed: {
        isAssigned() {
            return this.shipmentDetails.assignedTo?.publicUserId;
        }
    },

    mixins: [GeneralMixin],

    setup(props, { root }) {
        const { setDefaultBrokenImage } = useGeneralUtils(root);

        return {
            setDefaultBrokenImage
        };
    },

    methods: {
        async assignShipment() {
            this.$_handleLoaderState(true, 'ASSIGNING...');

            const payload = {
                method: 'post',
                data: {
                    assignToPublicUserId: this.pinnedDriverDetails.publicUserId
                }
            };

            try {
                await handleRequests(`/api/shipments/${this.shipmentDetails.shipmentId}/assign-user`, payload);

                this.$notify({
                    message: `The shipment has now been assigned to ${this.pinnedDriverDetails.fullName}`,
                    type: 'success'
                });

                this.$emit('driverAssigned');
            } catch (e) {
                let message = 'Cannot assign shipment to driver.';

                if (e && e.message) 
                    [{ message }] = e.data;

                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },

        sendDriverSms() {
            this.$modal
                .show(SendSmsModal, {
                    name: this.pinnedDriverDetails.fullName,
                    phone: this.pinnedDriverDetails.phone,
                    countryCode: this.pinnedDriverDetails.countryCode,
                    publicUserId: this.pinnedDriverDetails.publicUserId
                })
                .then((response) => {
                    this.$modal.hide();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';

.md-button:hover {
    box-shadow: 0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(76, 175, 80, 0.2);
}

.profile--container {
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    right: 10px;
    top: 10px;
    min-width: 380px;
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 10px;

    .profile-details-container {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
    }

    .contact-driver-container {
        text-align: center;

        .md-icon::v-deep {
            margin: 10px 5px 0 5px !important;
            font-size: 30px !important;
        }
    }

    .profile--link {
        margin-bottom: 5px;

        .profile--photo {
            min-width: 80px;
            height: 80px;
            box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.1), 0 0px 5px 0 rgba(0, 0, 0, 0.15) !important;
            border-radius: 3px;
        }
    }

    a {
        color: $locate2u-blue-base !important;
    }

    .profile--fullname {
        font-weight: 500;
        white-space: nowrap;
        max-width: 270px;
        overflow: hidden;
        text-overflow: ellipsis;
        /*margin-top: -5px;*/
    }

    .profile--phone {
        margin-top: -10px;
        font-size: 13px;
    }

    .photo-container {
        position: relative;
    }

    .online-status-container {
        position: absolute;
        top: 55px;
        right: -15px;
    }

    .assign-button {
        margin-left: 0;
        width: 150px;
    }
}
</style>
