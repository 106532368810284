<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ invoice.invoiceNumber }} Error</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            {{ invoice.accountingSystemNote }}
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="retry">
                Retry sync
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests } from '@/helpers';

export default {
    name: 'InvoiceErrorMessageModal',
    props: {
        invoice: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        async retry() {
            if (this.invoice.isSyncing === undefined) {
                this.$set(this.invoice, 'isSyncing', true);
            } else {
                this.invoice.isSyncing = true;
            }
            this.$modal.hide();
            try {
                const endpoint = `/api/customer-invoices/send-to-accounting`;
                const data = {
                    invoiceIdList: [this.invoice.customerInvoiceId]
                };
                const payload = {
                    method: 'post',
                    data
                };
                await handleRequests(endpoint, payload);
            } catch (error) {
                this.invoice.isSyncing = false;
            }
        }
    }
};
</script>
