var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard" }, [
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c("div", { staticClass: "custom-toolbar" }, [
          _c(
            "div",
            { staticClass: "custom-toolbar-start" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "Team Settings",
                    },
                  },
                },
                [_c("h3", { staticClass: "title" }, [_vm._v("Team Settings")])]
              ),
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "   >    " + _vm._s(_vm.$t("menus.setting.teamRegions"))
                ),
              ]),
            ],
            1
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "md-layout" }, [
      _c("div", { staticClass: "md-layout-item" }, [
        _c("div", { staticClass: "custom-toolbar" }, [
          _c(
            "div",
            { staticClass: "custom-toolbar-end" },
            [
              _c("search-component", {
                ref: "search",
                on: { onSearch: _vm.handleSearch },
              }),
              _c(
                "div",
                { staticClass: "header-button-container" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-primary md-just-icon md-round",
                      attrs: { title: "Create Team Region" },
                      on: { click: _vm.createTeamRegion },
                    },
                    [_c("md-icon", [_vm._v("add")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "md-layout" },
      [
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-green" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("crop")])],
                      1
                    ),
                  ]
                ),
                _c("md-card-content", { staticClass: "body-list" }, [
                  _vm.isListLoading
                    ? _c("div", { staticClass: "empty-table" }, [
                        _c(
                          "div",
                          { staticClass: "table-loader" },
                          [
                            _c("fade-loader", {
                              attrs: { loading: true, color: "#333333" },
                            }),
                            _c("span", [_vm._v("LOADING")]),
                          ],
                          1
                        ),
                      ])
                    : _c("div", [
                        _vm.teamRegionList && _vm.teamRegionList.length
                          ? _c(
                              "div",
                              [
                                _c(
                                  "md-table",
                                  {
                                    staticClass:
                                      "context-menu-support custom-paginated-table",
                                  },
                                  [
                                    _c(
                                      "md-table-row",
                                      [
                                        _c("md-table-head", [_vm._v("Name")]),
                                        _c(
                                          "md-table-head",
                                          { staticClass: "end" },
                                          [_vm._v("Actions")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      _vm.teamRegionList,
                                      function (item, index) {
                                        return _c(
                                          "md-table-row",
                                          {
                                            key: item.teamRegionId,
                                            class:
                                              index > 0 && item.level === 1
                                                ? "option-level-1"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.showSidebar(
                                                  item.teamRegionId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "md-table-cell",
                                              {
                                                class:
                                                  item.level !== 1
                                                    ? "option-level-" +
                                                      item.level
                                                    : "",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(item.name) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              { staticClass: "action-buttons" },
                                              [
                                                _c(
                                                  "md-button",
                                                  {
                                                    staticClass:
                                                      "md-warning md-just-icon md-round",
                                                    attrs: {
                                                      title:
                                                        "Update team region details",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.updateTeamRegion(
                                                          item.teamRegionId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("md-icon", [
                                                      _vm._v("edit"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "md-button",
                                                  {
                                                    staticClass:
                                                      "md-danger md-just-icon md-round",
                                                    attrs: {
                                                      title:
                                                        "Delete team region",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleDeleteTeamRegion(
                                                          item.teamRegionId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("md-icon", [
                                                      _vm._v("delete"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _c("div", [
                              _c("p", { staticClass: "no-result-message" }, [
                                _vm._v(
                                  "No results matching your search/filter could be found."
                                ),
                              ]),
                            ]),
                      ]),
                ]),
              ],
              1
            ),
            _c(
              "md-card-actions",
              {
                staticClass: "page-footer",
                attrs: { "md-alignment": "space-between" },
              },
              [
                _c("div", [
                  _vm.total === _vm.pagination.perPage
                    ? _c("p", { staticClass: "card-category" }, [
                        _vm._v(
                          "\n                        Page " +
                            _vm._s(_vm.pagination.currentPage) +
                            " of many\n                    "
                        ),
                      ])
                    : _c("p", { staticClass: "card-category" }, [
                        _vm._v(
                          "Page " +
                            _vm._s(_vm.pagination.currentPage) +
                            " of " +
                            _vm._s(_vm.totalPages)
                        ),
                      ]),
                ]),
                _c("pagination", {
                  staticClass: "pagination-no-border pagination-success",
                  attrs: {
                    "per-page": _vm.pagination.perPage,
                    total: _vm.total,
                  },
                  on: {
                    "change-page": function ($event) {
                      return _vm.handleChangePage(
                        $event,
                        _vm.pagination.perPage
                      )
                    },
                  },
                  model: {
                    value: _vm.pagination.currentPage,
                    callback: function ($$v) {
                      _vm.$set(_vm.pagination, "currentPage", $$v)
                    },
                    expression: "pagination.currentPage",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "transition",
          { attrs: { name: "slide" } },
          [
            _vm.isShowSidebar
              ? _c("team-region-sidebar", {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.toggleSidebarWindow,
                      expression: "toggleSidebarWindow",
                    },
                  ],
                  attrs: { "team-region-id": _vm.teamRegionId },
                  on: { closeModal: _vm.toggleSidebarWindow },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }