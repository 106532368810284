var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-container" }, [
    _c(
      "div",
      { staticClass: "close-button" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-default md-just-icon md-round pull-right",
            on: { click: _vm.closeModal },
          },
          [_c("md-icon", [_vm._v("close")])],
          1
        ),
      ],
      1
    ),
    !_vm.loading
      ? _c("div", [
          _c("div", { staticClass: "detail-section text-center" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.teamRegionModel.name)),
            ]),
          ]),
          _c("div", { staticClass: "line-separator" }),
          _c("div", { staticClass: "detail-section" }, [
            _c("span", { staticClass: "title" }, [_vm._v("Team Region")]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Name:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.teamRegionModel.name)),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Notes:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.teamRegionModel.notes) +
                    "\n                "
                ),
              ]),
            ]),
          ]),
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "sidebar-loader" },
            [
              _c("fade-loader", {
                attrs: { loading: _vm.loading, color: "#333333" },
              }),
              _c("span", [_vm._v("LOADING")]),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }