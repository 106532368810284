var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.batchFilterList !== null && _vm.batchFilterList.length > 0
    ? _c(
        "div",
        { staticClass: "filter-inline batch-filter" },
        [
          _c("vue-select", {
            staticClass: "batch-filter",
            attrs: {
              placeholder: "Batch Filter",
              options: _vm.batchFilterList,
              label: "label",
              reduce: function (x) {
                return x.id
              },
              searchable: _vm.$root.isDesktop,
              clearable: true,
            },
            model: {
              value: _vm.batchFilterValue,
              callback: function ($$v) {
                _vm.batchFilterValue = $$v
              },
              expression: "batchFilterValue",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }