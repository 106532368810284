var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam
    ? _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Settings"),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "   >    " +
                        _vm._s(_vm.$t("menus.setting.capacitySettings"))
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-blue" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("production_quantity_limits")])],
                      1
                    ),
                  ]
                ),
                _c("md-card-content", [
                  _c("div", { staticClass: "cf-container" }, [
                    _c("h5", [
                      _vm._v(
                        "\n                        This will set a default capacity for each user in your team when using the optimise stops.\n                    "
                      ),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "md-table",
                          { staticClass: "custom-paginated-table" },
                          [
                            _c(
                              "md-table-row",
                              [
                                _c("md-table-head"),
                                _c("md-table-head", [_vm._v("Type")]),
                                _c("md-table-head", [_vm._v("Unit")]),
                                _c("md-table-head", [_vm._v("Symbol")]),
                                _c("md-table-head", [
                                  _vm._v("Default Stop/Shipment Load"),
                                ]),
                                _c("md-table-head", [
                                  _vm._v("Default Max Vehicle Capacity"),
                                ]),
                                _c("md-table-head", [
                                  _vm._v("Include in POD PDF"),
                                ]),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.capacityDefaults,
                              function (capacity, index) {
                                return _c(
                                  "md-table-row",
                                  { key: index },
                                  [
                                    _c(
                                      "md-table-cell",
                                      [
                                        _c("md-checkbox", {
                                          staticClass: "checkbox-head",
                                          model: {
                                            value: capacity.selected,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                capacity,
                                                "selected",
                                                $$v
                                              )
                                            },
                                            expression: "capacity.selected",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("md-table-cell", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(capacity.label) +
                                          "\n                                "
                                      ),
                                    ]),
                                    _c(
                                      "md-table-cell",
                                      [
                                        _c(
                                          "form-group",
                                          [
                                            _c(
                                              "md-select",
                                              {
                                                on: {
                                                  "md-selected": function (
                                                    $event
                                                  ) {
                                                    return _vm.handleSelectedUnit(
                                                      capacity.type,
                                                      capacity.selectedUnit
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: capacity.selectedUnit,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      capacity,
                                                      "selectedUnit",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "capacity.selectedUnit",
                                                },
                                              },
                                              _vm._l(
                                                capacity.units,
                                                function (item, unitNdx) {
                                                  return _c(
                                                    "md-option",
                                                    {
                                                      key:
                                                        unitNdx +
                                                        "-" +
                                                        item.unit,
                                                      attrs: {
                                                        value: item.unit,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(item.unit) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                            _c("md-input", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    capacity.selectedUnit ===
                                                    "Others",
                                                  expression:
                                                    "capacity.selectedUnit === 'Others'",
                                                },
                                              ],
                                              model: {
                                                value: capacity.otherUnit,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    capacity,
                                                    "otherUnit",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "capacity.otherUnit",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-table-cell",
                                      [
                                        _c(
                                          "form-group",
                                          [
                                            _c("md-input", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    capacity.selectedUnit !==
                                                    "Others",
                                                  expression:
                                                    "capacity.selectedUnit !== 'Others'",
                                                },
                                              ],
                                              model: {
                                                value: capacity.selectedSymbol,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    capacity,
                                                    "selectedSymbol",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "capacity.selectedSymbol",
                                              },
                                            }),
                                            _c("md-input", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    capacity.selectedUnit ===
                                                    "Others",
                                                  expression:
                                                    "capacity.selectedUnit === 'Others'",
                                                },
                                              ],
                                              model: {
                                                value: capacity.otherSymbol,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    capacity,
                                                    "otherSymbol",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "capacity.otherSymbol",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-table-cell",
                                      [
                                        _c(
                                          "form-group",
                                          [
                                            _c("md-input", {
                                              attrs: { type: "number" },
                                              model: {
                                                value: capacity.defaultLoad,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    capacity,
                                                    "defaultLoad",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "capacity.defaultLoad",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-table-cell",
                                      [
                                        _c(
                                          "form-group",
                                          [
                                            _c("md-input", {
                                              attrs: { type: "number" },
                                              model: {
                                                value:
                                                  capacity.defaultMaxCapacity,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    capacity,
                                                    "defaultMaxCapacity",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "capacity.defaultMaxCapacity",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("md-table-cell", [
                                      _c(
                                        "span",
                                        [
                                          _c("md-checkbox", {
                                            staticClass: "checkbox-head",
                                            attrs: {
                                              disabled:
                                                !_vm.capacityTypeAllowedInPodPdf(
                                                  capacity.type
                                                ),
                                            },
                                            model: {
                                              value: capacity.includeInPodPdf,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  capacity,
                                                  "includeInPodPdf",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "capacity.includeInPodPdf",
                                            },
                                          }),
                                          !_vm.capacityTypeAllowedInPodPdf(
                                            capacity.type
                                          )
                                            ? _c("md-tooltip", [
                                                _vm._v(
                                                  "\n                                            Not allowed in POD PDF\n                                        "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "custom-btn",
                        on: { click: _vm.saveTeamCapacity },
                      },
                      [_vm._v("Save")]
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }