var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showWizardModal
    ? _c("div", { staticClass: "modal-container" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _vm.logData.assetMaintenanceScheduleId == null
              ? _c("h4", { staticClass: "modal-title" }, [
                  _vm._v("Add Asset Log"),
                ])
              : _c("h4", { staticClass: "modal-title" }, [
                  _vm._v("Add Maintenance Record"),
                ]),
            _c(
              "md-button",
              {
                staticClass:
                  "md-simple md-just-icon md-round modal-default-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$modal.hide($event)
                  },
                },
              },
              [_c("md-icon", [_vm._v("clear")])],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "modal-body" }, [
          _c(
            "div",
            { staticClass: "md-layout create-asset-container" },
            [
              _c(
                "form-wrapper",
                {
                  staticClass: "form-wrapper",
                  attrs: { validator: _vm.$v.logData },
                },
                [
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100 align-top-div" },
                    [
                      _c(
                        "form-group",
                        { attrs: { label: "Asset Name", name: "assetId" } },
                        [
                          _c("md-input", {
                            attrs: { type: "hidden" },
                            model: {
                              value: _vm.logData.assetId,
                              callback: function ($$v) {
                                _vm.$set(_vm.logData, "assetId", $$v)
                              },
                              expression: "logData.assetId",
                            },
                          }),
                          _c("vue-select", {
                            attrs: {
                              reduce: function (x) {
                                return x.assetId
                              },
                              label: "name",
                              options: _vm.vehicleDropdownList,
                              searchable: true,
                              disabled:
                                _vm.logData.assetMaintenanceScheduleId != null,
                            },
                            model: {
                              value: _vm.logData.assetId,
                              callback: function ($$v) {
                                _vm.$set(_vm.logData, "assetId", $$v)
                              },
                              expression: "logData.assetId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "form-group",
                        { attrs: { label: "Type", name: "logType" } },
                        [
                          _c(
                            "md-select",
                            {
                              attrs: {
                                disabled:
                                  _vm.logData.assetMaintenanceScheduleId !=
                                  null,
                              },
                              on: {
                                "md-selected": function ($event) {
                                  return _vm.handleLogType(_vm.logData.logType)
                                },
                              },
                              model: {
                                value: _vm.logData.logType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.logData, "logType", $$v)
                                },
                                expression: "logData.logType",
                              },
                            },
                            _vm._l(_vm.assetLogTypes, function (x) {
                              return _c(
                                "md-option",
                                { key: x.key, attrs: { value: x.key } },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("assetLogTypes." + x.key)) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "form-group",
                        { attrs: { label: "Subtype", name: "subType" } },
                        [
                          _c(
                            "md-select",
                            {
                              attrs: {
                                disabled:
                                  _vm.logData.assetMaintenanceScheduleId !=
                                  null,
                              },
                              model: {
                                value: _vm.logData.logSubType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.logData, "logSubType", $$v)
                                },
                                expression: "logData.logSubType",
                              },
                            },
                            _vm._l(_vm.subLogTypes, function (x) {
                              return _c(
                                "md-option",
                                { key: x.key, attrs: { value: x.key } },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t("assetLogSubTypes." + x.key)
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "md-layout-item md-size-50" }, [
                    _c(
                      "div",
                      { staticClass: "log-date" },
                      [
                        _c(
                          "md-datepicker",
                          {
                            attrs: { "md-disabled-dates": _vm.disabledDates },
                            model: {
                              value: _vm.logData.logDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.logData, "logDate", $$v)
                              },
                              expression: "logData.logDate",
                            },
                          },
                          [
                            _c("label", [
                              _vm._v(
                                "\n                                Date\n                            "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-50" },
                    [
                      _c(
                        "form-group",
                        { attrs: { label: "Total cost", name: "cost" } },
                        [
                          _c("md-input", {
                            model: {
                              value: _vm.logData.cost,
                              callback: function ($$v) {
                                _vm.$set(_vm.logData, "cost", $$v)
                              },
                              expression: "logData.cost",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "md-layout-item md-size-100" }, [
                    _c("span", { staticClass: "custom-note" }, [
                      _vm._v(
                        "\n                        *Odometer and engine hour values will only be updated if log date is for today or yesterday.\n                    "
                      ),
                    ]),
                  ]),
                  _vm.logData.logType != "insurance" &&
                  _vm.logData.logType != "vehicle-registration"
                    ? _c(
                        "div",
                        { staticClass: "md-layout-item md-size-40" },
                        [
                          _c(
                            "form-group",
                            {
                              attrs: {
                                label: "Engine hours",
                                name: "startingEngineHours",
                              },
                            },
                            [
                              _c("md-input", {
                                model: {
                                  value: _vm.logData.startingEngineHours,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.logData,
                                      "startingEngineHours",
                                      $$v
                                    )
                                  },
                                  expression: "logData.startingEngineHours",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.logData.logType != "insurance" &&
                  _vm.logData.logType != "vehicle-registration"
                    ? _c(
                        "div",
                        { staticClass: "md-layout-item md-size-40" },
                        [
                          _c(
                            "form-group",
                            {
                              attrs: {
                                label: "Odometer Reading",
                                name: "startingOdometer",
                              },
                            },
                            [
                              _c("md-input", {
                                model: {
                                  value: _vm.logData.startingOdometer,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.logData,
                                      "startingOdometer",
                                      $$v
                                    )
                                  },
                                  expression: "logData.startingOdometer",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.logData.logType != "insurance" &&
                  _vm.logData.logType != "vehicle-registration"
                    ? _c(
                        "div",
                        { staticClass: "md-layout-item md-size-20" },
                        [
                          _c(
                            "form-group",
                            { attrs: { label: "Unit" } },
                            [
                              _c(
                                "md-select",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.maintenanceData != null &&
                                      _vm.maintenanceData
                                        .nextDueOdometerUnitType != null,
                                  },
                                  model: {
                                    value: _vm.logData.odometerUnitType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.logData,
                                        "odometerUnitType",
                                        $$v
                                      )
                                    },
                                    expression: "logData.odometerUnitType",
                                  },
                                },
                                [
                                  _c("md-option", { attrs: { value: "km" } }, [
                                    _vm._v("km"),
                                  ]),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "miles" } },
                                    [_vm._v("miles")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.logData.logType != "insurance" &&
                  _vm.logData.logType != "vehicle-registration"
                    ? _c(
                        "div",
                        { staticClass: "md-layout-item md-size-100" },
                        [
                          _c("google-autocomplete", {
                            attrs: {
                              label: "Location (Optional)",
                              id: "autoaddress",
                              classname: "form-control autocomplete-input",
                              placeholder: "",
                              "should-focus": false,
                              name: "startDetails.address",
                            },
                            model: {
                              value: _vm.tempLogAddressData.startDetails,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tempLogAddressData,
                                  "startDetails",
                                  $$v
                                )
                              },
                              expression: "tempLogAddressData.startDetails",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100" },
                    [
                      _c(
                        "form-group",
                        { attrs: { name: "notes", label: "Notes" } },
                        [
                          _c("md-textarea", {
                            model: {
                              value: _vm.logData.notes,
                              callback: function ($$v) {
                                _vm.$set(_vm.logData, "notes", $$v)
                              },
                              expression: "logData.notes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "md-button",
              {
                staticClass: "md-primary dialog-button",
                on: {
                  click: function ($event) {
                    return _vm.create()
                  },
                },
              },
              [_vm._v("Add")]
            ),
            _c(
              "md-button",
              {
                staticClass: "md-default dialog-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$modal.hide($event)
                  },
                },
              },
              [_vm._v("Cancel")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }