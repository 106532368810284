var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.driverInstructionsList, function (instruction, ndx) {
      return _c(
        "div",
        { key: "instruction-" + ndx, staticClass: "detail-group" },
        [
          _c("label", { staticClass: "label" }, [
            _vm._v(_vm._s(instruction.text) + ":"),
          ]),
          _c("span", { staticClass: "value" }, [
            _vm._v("\n            " + _vm._s(instruction.value) + "\n        "),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }