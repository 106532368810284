<template>
    <div v-if="showTripStopWindow" class="modal">
        <md-content class="info-tooltip">
            <md-card>
                <div class="modal-header">
                    <h4 class="modal-title" v-if="content.teamMember !== null">
                        {{ content.teamMember.fullName }} - Assigned Stops {{ getVehicleMaxLoad() }}
                    </h4>
                    <h4 class="modal-title" v-if="content.teamMember === null">Unassigned Stops</h4>
                </div>
                <div class="modal-body" :class="content.stopList.length > 7 ? 'modal-body-scroll' : ''">
                    <md-table class="context-menu-support unassignstop-table">
                        <md-table-row class="stop-list-row">
                            <md-table-head></md-table-head>
                            <!--<md-table-head>Reference</md-table-head>-->
                            <md-table-head class="contact-header">Contact</md-table-head>
                            <md-table-head class="address-header">Address</md-table-head>
                            <md-table-head class="eta-header">ETA</md-table-head>
                            <md-table-head
                                class="stop-load-header"
                                v-if="
                                    user.vehicleCapacityUnitsConfiguration != null &&
                                        user.vehicleCapacityUnitsConfiguration.length > 0
                                "
                            >
                                Load {{ selectedCapacitySymbol ? `(${selectedCapacitySymbol})` : '' }}
                            </md-table-head>
                            <div
                                class="md-table-head running-load-head"
                                v-if="
                                    user.vehicleCapacityUnitsConfiguration != null &&
                                        user.vehicleCapacityUnitsConfiguration.length > 0
                                "
                            >
                                <div class="md-table-head-container running-load-menu">
                                    <drop-down direction="down">
                                        <a href="#" class="load-header-text">
                                            <md-tooltip md-direction="top" class="toggle-tooltip">
                                                {{ selectedViewType }} ({{ selectedCapacityLabel }})
                                            </md-tooltip>
                                            {{ loadHeaderText }}
                                            {{ selectedCapacitySymbol ? `(${selectedCapacitySymbol})` : '' }}
                                            <md-icon class="icon-arrow">expand_more</md-icon>
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li
                                                v-for="(vehicleCapacity,
                                                index) in user.vehicleCapacityUnitsConfiguration"
                                                :key="index"
                                            >
                                                <md-icon
                                                    :class="[
                                                        'icon-check',
                                                        content.selectedCapacityType === vehicleCapacity.type
                                                            ? 'icon-check-color'
                                                            : ''
                                                    ]"
                                                >
                                                    check
                                                </md-icon>
                                                <a href="#" @click="changeCapacityType(vehicleCapacity.type)">
                                                    {{ vehicleCapacity.label }}
                                                </a>
                                            </li>
                                            <hr class="border" />
                                            <li v-for="(viewType, viewindex) in viewTypes" :key="'v' + viewindex">
                                                <md-icon
                                                    :class="[
                                                        'icon-check',
                                                        selectedViewType === viewType ? 'icon-check-color' : ''
                                                    ]"
                                                >
                                                    check
                                                </md-icon>
                                                <a href="#" @click="changeSelectViewType(viewType)">
                                                    {{ viewType }}
                                                </a>
                                            </li>
                                        </ul>
                                    </drop-down>
                                </div>
                            </div>
                            <md-table-head></md-table-head>
                        </md-table-row>

                        <md-table-row
                            v-for="(item, stopIndex) in content.stopList"
                            :key="item.stopId"
                            style="cursor: pointer"
                            class="stop-list-row"
                        >
                            <md-table-cell>
                                <span v-if="item.stopRef">{{ stopIndex }}</span>
                            </md-table-cell>
                            <!--<md-table-cell class="stop-reference">
                                <router-link
                                    v-if="item.stopRef && item.stopId"
                                    :to="{
                                        name: 'Stop Details',
                                        params: { stopId: item.stopId }
                                    }"
                                    target="_blank"
                                >
                                    {{ item.stopRef }}
                                </router-link>
                                <span v-else>{{ item.locationType }}</span>
                            </md-table-cell>-->
                            <md-table-cell class="contact-name-container">
                                <span class="custom-ellipsis" v-overflow-text-tooltip>
                                    {{ $_displayContactName(item) }}
                                </span>
                            </md-table-cell>
                            <md-table-cell class="address-container">
                                <span class="custom-ellipsis" v-overflow-text-tooltip>
                                    <router-link
                                        v-if="item.stopRef && item.stopId"
                                        :to="{
                                            name: 'Stop Details',
                                            params: { stopId: item.stopId }
                                        }"
                                        target="_blank"
                                    >
                                        {{ $_displayAddressName(item) }}
                                    </router-link>
                                    <span v-else>{{ $_displayAddressName(item) }}</span>
                                </span>
                            </md-table-cell>
                            <md-table-cell v-if="content.teamMember !== null" class="stop-eta">
                                {{ item.arrivalTime | timeFormat(DATE_TYPES.standardTime) }}
                            </md-table-cell>
                            <md-table-cell
                                class="stop-load"
                                v-if="
                                    user.vehicleCapacityUnitsConfiguration != null &&
                                        user.vehicleCapacityUnitsConfiguration.length > 0
                                "
                            >
                                {{ getStopLoad(item) }}
                            </md-table-cell>
                            <md-table-cell
                                class="vehicle-load"
                                v-if="
                                    user.vehicleCapacityUnitsConfiguration != null &&
                                        user.vehicleCapacityUnitsConfiguration.length > 0
                                "
                            >
                                {{ getVehicleCurrentLoadOrAvailableCapacity(item) }}
                            </md-table-cell>
                            <md-table-cell v-if="content.teamMember !== null && !loadedSaveModel">
                                <md-avatar v-if="item.stopRef && showWarningAboutCustomerId(item)">
                                    <md-icon class="warning-icon">warning</md-icon>
                                    <md-tooltip
                                        v-if="stopWithDifferentCustomer(item)"
                                        class="warning-tooltip"
                                        md-direction="right"
                                    >
                                        This {{ item.shipmentId ? `stop's shipment` : 'stop' }} has a different customer
                                        from the trip. This will be updated to match with the trip customer.
                                    </md-tooltip>
                                    <md-tooltip
                                        v-else-if="stopWithoutCustomer(item)"
                                        class="warning-tooltip"
                                        md-direction="right"
                                    >
                                        This {{ item.shipmentId ? `stop's shipment` : 'stop' }} has no customer. This
                                        will be updated to match with the trip customer.
                                    </md-tooltip>
                                </md-avatar>
                            </md-table-cell>
                            <md-table-cell
                                v-if="content.teamMember !== null && !loadedSaveModel"
                                class="action-buttons"
                            >
                                <reassign-stop-button
                                    :class="item.display === 'hide' ? 'btn-display' : ''"
                                    v-if="item.stopRef"
                                    :is-assigned-stops="true"
                                    :allocate-stops="content.allTrips"
                                    :stop-id="item.stopId"
                                    :vehicle-id="content.vehicleId"
                                    @reassignedStop="handleReassignedStop"
                                />
                                <delete-stop-button
                                    :class="['custom-btn', item.display === 'hide' ? 'btn-display' : '']"
                                    v-if="item.stopRef"
                                    :stop-id="item.stopId"
                                    :vehicle-id="content.vehicleId"
                                    :allocate-stops="content.allTrips"
                                    :stop-ref="item.stopRef"
                                    :address="item.address"
                                    :is-dialog="true"
                                    @stopDeleted="handleDeleteStop"
                                />
                                <md-button
                                    v-if="item.stopRef"
                                    title="Undo changes"
                                    :class="[
                                        'md-danger undo-btn md-just-icon md-round',
                                        item.display === 'hide' ? '' : 'btn-display'
                                    ]"
                                    @click.stop="undoTripStop(item.stopId)"
                                >
                                    <md-icon>undo</md-icon>
                                </md-button>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                </div>
                <div class="modal-footer">
                    <md-button class="dialog-button md-default btn-close" @click.stop="closeTripStopWindow">
                        Close
                    </md-button>
                </div>
            </md-card>
        </md-content>
    </div>
</template>

<script>
import { DeleteStopButton, ReassignStopButton } from '@/pages/BatchStop/buttons';
import { GeneralMixin } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
    name: 'TripStopWindowComponent',
    mixins: [GeneralMixin],
    components: {
        DeleteStopButton,
        ReassignStopButton
    },
    props: {
        content: {
            type: Object,
            default: () => {}
        },
        showTripStopWindow: Boolean,
        loadedSaveModel: Boolean
    },
    data() {
        return {
            loadHeaderText: 'CL',
            selectedViewType: 'Current Load',
            viewTypes: ['Current Load', 'Available Capacity'],
            selectedCapacityLabel: null,
            selectedCapacitySymbol: null,
            deleteTripStops: {
                stops: [],
                vehicleId: null
            },
            reassignedTripStops: {
                records: [],
                stops: [],
                vehicleId: null
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    methods: {
        stopWithDifferentCustomer(stop) {
            return stop.customerId && this.content.tripCustomerId && stop.customerId !== this.content.tripCustomerId;
        },
        stopWithoutCustomer(stop) {
            return !stop.customerId && this.content.tripCustomerId;
        },
        showWarningAboutCustomerId(stop) {
            return this.stopWithDifferentCustomer(stop) || this.stopWithoutCustomer(stop);
        },
        changeCapacityType(val) {
            this.content.selectedCapacityType = val;
        },
        changeSelectViewType(val) {
            this.selectedViewType = val;
            this.loadHeaderText = val === 'Current Load' ? 'CL' : 'AC';
        },
        getCapacityDetails() {
            if (
                !this.content.selectedCapacityType &&
                user.vehicleCapacityUnitsConfiguration != null &&
                this.user.vehicleCapacityUnitsConfiguration.length > 0
            ) {
                this.content.selectedCapacityType = this.user.vehicleCapacityUnitsConfiguration[0].type;
            }
            const data = this.user.vehicleCapacityUnitsConfiguration.find(
                (x) => x.type === this.content.selectedCapacityType
            );
            this.selectedCapacityLabel = data?.label;
            this.selectedCapacitySymbol = data?.symbol;
            return data;
        },
        getVehicleMaxLoad() {
            if (!this.content.selectedCapacityType) {
                return '';
            }
            const { symbol, defaultMaxCapacity } = this.getCapacityDetails();
            const vehicleCapacity =
                this.content.teamMember.vehicleCapacity[this.content.selectedCapacityType] ?? defaultMaxCapacity;

            const trip = this.content.allTrips.find((x) => x.vehicleId === this.content.vehicleId);

            if (!trip.vehicle.vehicleLoad) 
                return '';

            const displayMaxCapacity = vehicleCapacity ? `/${vehicleCapacity}` : '';
            return `${trip.vehicle.vehicleLoad[this.content.selectedCapacityType]}${displayMaxCapacity} ${symbol} `;
        },
        getVehicleCurrentLoadOrAvailableCapacity(item) {
            if (!item.vehicleLoad) 
                return '';

            if (this.selectedViewType === 'Available Capacity') {
                return `${item.availableCapacity[this.content.selectedCapacityType]}  `;
            }
            return `${item.vehicleLoad[this.content.selectedCapacityType]} `;
        },
        getStopLoad(item) {
            if (item.load) {
                let sign = '';
                if (item.type === 'SHIPMENT_PICKUP') {
                    sign = '+';
                } else if (item.type === 'SHIPMENT_DELIVERY') {
                    sign = '-';
                }
                return `${sign} ${item.load[this.content.selectedCapacityType] ?? 0} `;
            }
            return '';
        },
        closeTripStopWindow() {
            this.$emit('closeTripStopWindow', this.reassignedTripStops, this.deleteTripStops);
            this.clearData();
        },
        undoTripStop(stopId) {
            const stop = this.content.stopList.find((s) => s.stopId === stopId);
            stop.address += ' ';
            stop.display = 'show';
            const deleteStopIndex = this.deleteTripStops.stops.findIndex((s) => s === stopId);
            if (deleteStopIndex >= 0) {
                this.deleteTripStops.stops.splice(deleteStopIndex, 1);
            }
            const reassignedStopIndex = this.reassignedTripStops.stops.findIndex((s) => s === stopId);
            if (reassignedStopIndex >= 0) {
                this.reassignedTripStops.records.splice(reassignedStopIndex, 1);
                this.reassignedTripStops.stops.splice(reassignedStopIndex, 1);
            }
        },
        handleDeleteStop(stopId, vehicleId) {
            this.deleteTripStops.stops.push(stopId);
            this.deleteTripStops.vehicleId = vehicleId;
            const stop = this.content.stopList.find((s) => s.stopId === stopId);
            stop.address += ' ';
            stop.display = 'hide';
        },
        handleReassignedStop(record, stopId, vehicleId) {
            this.reassignedTripStops.records.push(record);
            this.reassignedTripStops.stops.push(stopId);
            this.reassignedTripStops.vehicleId = vehicleId;
            const stop = this.content.stopList.find((s) => s.stopId === stopId);
            stop.address += ' ';
            stop.display = 'hide';
        },
        clearData() {
            this.deleteTripStops = {
                stops: [],
                vehicleId: null
            };
            this.reassignedTripStops = {
                records: [],
                stops: [],
                vehicleId: null
            };
        }
    }
};
</script>
<style lang="scss" scoped>
.modal {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: table;
    -webkit-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease;

    .modal-body {
        padding: 0px 15px;
        max-height: 500px;
    }
    .modal-body-scroll {
        overflow: auto;
    }
}
.info-tooltip {
    margin: 6% auto;
    position: relative;
    line-height: 1.2;
    width: 60%;
    border: 0px;
    background-color: transparent;
    border-radius: 8px;
    color: black;
    z-index: 2;
}
.warning-icon {
    color: #ffa21a !important;
    cursor: pointer;
}
.warning-tooltip {
    max-width: 500px;
    text-align: justify;
}

.warning-tooltip[x-placement='left']:after {
    top: 48%;
}

.warning-tooltip[x-placement='right']:after {
    top: 48%;
}

.unassignstop-table {
    padding-left: 0;
    line-height: 18px;
    list-style-type: none;
    max-height: 350px;
}
td.md-table-cell.stop-reference,
td.md-table-cell.address-container,
td.md-table-cell.contact-name-container,
td.md-table-cell.stop-eta,
td.md-table-cell.stop-load,
td.md-table-cell.action-buttons {
    padding: 0px 5px;
    height: 36px;
}

.stop-reference a {
    color: #2b93ff !important;
}
.address-container {
    ::v-deep .md-table-cell-container {
        white-space: nowrap;
        width: 360px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.address-container a {
    color: #2b93ff !important;
}
.stop-eta {
    ::v-deep .md-table-cell-container {
        width: 60px;
        text-align: right;
    }
}

.stop-load {
    ::v-deep .md-table-cell-container {
        width: 70px;
        text-align: right;
        padding-right: 5px;
    }
}
.vehicle-load {
    ::v-deep .md-table-cell-container {
        width: 70px;
        text-align: right;
    }
}
.contact-name-container {
    ::v-deep .md-table-cell-container {
        width: 150px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.action-buttons {
    :v-deep .md-table-cell-container {
        text-align: right;
    }
}
button.md-button.btn-close {
    top: 10px;
    right: 10px;
}
button.md-button.btn-undo {
    background: #2b93ff !important;
    margin-left: 8px;
}

.action-buttons {
    button,
    ::v-deep button {
        margin: 0 2px;
    }

    button:last-child {
        margin-right: 0;
    }
}
.custom-btn {
    background-color: #f44336 !important;
    color: #fff !important;
    border: none;
    max-width: 29px;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    text-transform: initial;
    height: 29px !important;
    padding: 0;
    font-weight: 300;
    border-radius: 30px;
}

.custom-btn:hover,
.custom-btn:focus {
    color: #fff !important;
    background-color: #f44336 !important;
}

.custom-a-blue a {
    color: #f44336;
}
.custom-button-container {
    margin-top: -15px !important;
}
.undo-btn {
    background-color: #ffa21a !important;
    color: #fff !important;
    border: none;
    max-width: 29px;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    text-transform: initial;
    height: 29px !important;
    padding: 0;
    font-weight: 300;
    border-radius: 30px;
}

.undo-btn:hover,
.undo-btn:focus {
    color: #fff !important;
    background-color: #ffa21a !important;
}
.btn-display {
    display: none;
}
.row-background {
    background-color: lightgray;
}
.contact-header {
    width: 150px;
}
.address-header {
    width: 360px;
}
.eta-header {
    width: 60px;
    text-align: right;
}
.stop-load-header {
    width: 60px;
    text-align: right;
}
.stop-list-row .md-table-cell {
    padding: 0px;
    height: auto;
}
.stop-list-row .md-table-head {
    padding: 5px;
    height: auto;
}
.running-load-head {
    width: 80px;
    text-align: right;

    ::v-deep .md-table-head-label {
        float: left;
        padding-right: 5px;
    }

    .load-header-text,
    .load-header-text:hover {
        font-size: 1.0625rem;
        padding-left: 0;
        padding-right: 10px;
        font-weight: 300;
        color: #333333 !important;
    }

    .icon-arrow {
        font-size: 24px !important;
        margin-top: -2px;
    }

    .open {
        .icon-arrow {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
        }
    }

    .icon-check {
        color: #fff !important;
        font-size: 16px !important;
        float: left;
    }

    .icon-check-color {
        color: #333333 !important;
    }

    .running-load-menu {
        ::v-deep .dropdown {
            position: absolute;
            bottom: 8px;
            width: 80px;
        }

        .dropdown-menu {
            margin-left: -15px;
            margin-top: 6px;

            li {
                border-bottom: 0px;
                padding: 10px;
            }

            hr.border {
                border-top: 1px solid #ddd;
            }

            a {
                color: #333333 !important;
                padding: 0px;
                margin: 0px;
                font-size: 12px;
                font-weight: 400;
            }

            a:hover {
                background-color: transparent !important;
                box-shadow: none;
                color: #2b93ff !important;
            }
        }
    }
}
.md-tooltip-top {
    &.toggle-tooltip {
        max-width: 220px !important;
    }
}
</style>
