export const Iso4217Currencies = {
    /* { key: "AED", value: "United Arab Emirates dirham  United Arab Emirates"} */
    AED: 'United Arab Emirates dirham  United Arab Emirates',
    AFN: 'Afghan afghani',
    ALL: 'Albanian lek ',
    AMD: 'Armenian dram',
    ANG: 'Netherlands Antillean guilder',
    AOA: 'Angolan kwanza',
    ARS: 'Argentine peso',
    AUD: 'Australian dollar',
    AWG: 'Aruban florin',
    AZN: 'Azerbaijani manat',
    BAM: 'Bosnia and Herzegovina convertible mark  Bosnia and Herzegovina',
    BBD: 'Barbados dollar  Barbados',
    BDT: 'Bangladeshi taka ',
    BGN: 'Bulgarian lev',
    BHD: 'Bahraini dinar',
    BIF: 'Burundian franc  Burundi',
    BMD: 'Bermudian dollar ',
    BND: 'Brunei dollar',
    BOB: 'Boliviano',
    BOV: "Bolivian Mvdol (funds 'code')  Bolivia",
    BRL: 'Brazilian real',
    BSD: 'Bahamian dollar  Bahamas',
    BTN: 'Bhutanese ngultrum',
    BWP: 'Botswana pula',
    BYR: 'Belarusian ruble ',
    BZD: 'Belize dollar',
    CAD: 'Canadian dollar  Canada',
    CDF: 'Congolese franc  Democratic Republic of Congo',
    CHE: 'WIR Euro (complementary currency) ',
    CHF: 'Swiss franc',
    CHW: 'WIR Franc (complementary currency)',
    CLF: "Unidad de Fomento (funds 'code')",
    CLP: 'Chilean peso ',
    CNY: 'Chinese yuan ',
    COP: 'Colombian peso',
    COU: "Unidad de Valor Real (UVR) (funds 'code')[7]",
    CRC: 'Costa Rican colon',
    CUC: 'Cuban convertible peso',
    CUP: 'Cuban peso',
    CVE: 'Cape Verde escudo',
    CZK: 'Czech koruna ',
    DJF: 'Djiboutian franc ',
    DKK: 'Danish krone ',
    DOP: 'Dominican peso',
    DZD: 'Algerian dinar',
    EGP: 'Egyptian pound',
    ERN: 'Eritrean nakfa',
    ETB: 'Ethiopian birr',
    EUR: 'Euro ',
    FJD: 'Fiji dollar  Fiji',
    FKP: 'Falkland Islands pound',
    GBP: 'Pound sterling',
    GEL: 'Georgian lari',
    GHS: 'Ghanaian cedi',
    GIP: 'Gibraltar pound  Gibraltar',
    GMD: 'Gambian dalasi',
    GNF: 'Guinean franc',
    GTQ: 'Guatemalan quetzal',
    GYD: 'Guyanese dollar  Guyana',
    HKD: 'Hong Kong dollar ',
    HNL: 'Honduran lempira ',
    HRK: 'Croatian kuna',
    HTG: 'Haitian gourde',
    HUF: 'Hungarian forint ',
    IDR: 'Indonesian rupiah',
    ILS: 'Israeli new shekel',
    INR: 'Indian rupee ',
    IQD: 'Iraqi dinar  Iraq',
    IRR: 'Iranian rial ',
    ISK: 'Icelandic króna  Iceland',
    JMD: 'Jamaican dollar  Jamaica',
    JOD: 'Jordanian dinar  Jordan, auxiliary in West Bank',
    JPY: 'Japanese yen ',
    KES: 'Kenyan shilling  Kenya',
    KGS: 'Kyrgyzstani som  Kyrgyzstan',
    KHR: 'Cambodian riel',
    KMF: 'Comoro franc ',
    KPW: 'North Korean won ',
    KRW: 'South Korean won ',
    KWD: 'Kuwaiti dinar',
    KYD: 'Cayman Islands dollar',
    KZT: 'Kazakhstani tenge',
    LAK: 'Lao kip  Laos',
    LBP: 'Lebanese pound',
    LKR: 'Sri Lankan rupee ',
    LRD: 'Liberian dollar  Liberia',
    LSL: 'Lesotho loti ',
    LYD: 'Libyan dinar ',
    MAD: 'Moroccan dirham  Morocco',
    MDL: 'Moldovan leu ',
    MGA: 'Malagasy ariary  Madagascar',
    MKD: 'Macedonian denar ',
    MMK: 'Myanmar kyat ',
    MNT: 'Mongolian tugrik ',
    MOP: 'Macanese pataca  Macao',
    MRO: 'Mauritanian ouguiya  Mauritania',
    MUR: 'Mauritian rupee  Mauritius',
    MVR: 'Maldivian rufiyaa',
    MWK: 'Malawian kwacha  Malawi',
    MXN: 'Mexican peso ',
    MXV: "Mexican Unidad de Inversion (UDI) (funds 'code')",
    MYR: 'Malaysian ringgit',
    MZN: 'Mozambican metical',
    NAD: 'Namibian dollar  Namibia',
    NGN: 'Nigerian naira',
    NIO: 'Nicaraguan córdoba',
    NOK: 'Norwegian krone  Norway,  Svalbard and  Jan Mayen (SJ),  Bouvet Island (BV), Queen Maud Land, Peter I Island',
    NPR: 'Nepalese rupee ',
    NZD: 'New Zealand dollar',
    OMR: 'Omani rial',
    PAB: 'Panamanian balboa',
    PEN: 'Peruvian nuevo sol',
    PGK: 'Papua New Guinean kina',
    PHP: 'Philippine peso  Philippines',
    PKR: 'Pakistani rupee  Pakistan',
    PLN: 'Polish złoty ',
    PYG: 'Paraguayan guaraní',
    QAR: 'Qatari riyal ',
    RON: 'Romanian new leu ',
    RSD: 'Serbian dinar',
    RUB: 'Russian ruble',
    RWF: 'Rwandan franc',
    SAR: 'Saudi riyal  Saudi Arabia',
    SBD: 'Solomon Islands dollar',
    SCR: 'Seychelles rupee ',
    SDG: 'Sudanese pound',
    SEK: 'Swedish krona/kronor ',
    SGD: 'Singapore dollar ',
    SHP: 'Saint Helena pound',
    SLL: 'Sierra Leonean leone ',
    SOS: 'Somali shilling  Somalia (except  Somaliland)',
    SRD: 'Surinamese dollar',
    SSP: 'South Sudanese pound ',
    STD: 'São Tomé and Príncipe dobra  São Tomé and Príncipe',
    SYP: 'Syrian pound ',
    SZL: 'Swazi lilangeni  Swaziland',
    THB: 'Thai baht',
    TJS: 'Tajikistani somoni',
    TMT: 'Turkmenistani manat  Turkmenistan',
    TND: 'Tunisian dinar',
    TOP: 'Tongan paʻanga',
    TRY: 'Turkish lira ',
    TTD: 'Trinidad and Tobago dollar',
    TWD: 'New Taiwan dollar',
    TZS: 'Tanzanian shilling',
    UAH: 'Ukrainian hryvnia',
    UGX: 'Ugandan shilling ',
    USD: 'United States dollar ',
    USN: "United States dollar (next day) (funds 'code') ",
    USS: "United States dollar (same day) (funds 'code')[10] ",
    UYI: "Uruguay Peso en Unidades Indexadas (URUIURUI) (funds 'code')",
    UYU: 'Uruguayan peso',
    UZS: 'Uzbekistan som',
    VEF: 'Venezuelan bolívar',
    VND: 'Vietnamese dong  Vietnam',
    VUV: 'Vanuatu vatu ',
    WST: 'Samoan tala  Samoa',
    XAF:
        'CFA,franc B EAC,  Cameroon (CM),  Central African Republic (CF),  Republic of the Congo (CG),  Chad (TD),  Equatorial Guinea (GQ),  Gabon (GA)',
    XAG: 'Silver (one troy ounce) ',
    XAU: 'Gold (one troy ounce)   ',
    XBA: 'European Composite Unit (EURCO) (bond market unit)  ',
    XBB: 'European Monetary Unit (E.M.U.-6) (bond market unit)    ',
    XBC: "European Unit of Account 'e': 9,  (E.U.A.-9) (bond market unit)    ",
    XBD: 'European Unit  of Account 17 (E.U.A.-17) (bond market unit)  ',
    XCD: 'East Caribbean dollar',
    XDR: 'Special drawing rights  International Monetary Fund',
    XFU: 'UIC franc (special settlement currency) International Union of Railways',
    XOF:
        "CFA franc BC EAO, Benin (BJ),  Burkina Faso (BF),  Cote d'Ivoire (CI),  Guinea-Bissau (GW),  Mali (ML),  Niger (NE),  Senegal (SN),  Togo (TG)",
    XPD: 'Palladium (one troy ounce)  ',
    XPF:
        'CFP franc (franc Pacifique) French territories of the Pacific Ocean:  French Polynesia (PF),  New Caledonia (NC),  Wallis and Futuna (WF)',
    XPT: "Plati'num' (one troy ounce)   ",
    XSU: 'CRE  Unified System for Regional Compensation (SUCRE)[11]',
    XTS: 'Code reserved for testing purposes  ',
    XUA: 'ADB Unit of Account African Development Bank[12]',
    XXX: 'No currency ',
    YER: 'Yemeni rial  Yemen',
    ZAR: 'South African rand',
    ZMW: 'Zambian kwacha',
    ZWL: 'None (formerly  Zimbabwe)'
};
