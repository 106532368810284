var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-overview" }, [
    _vm.selectedView == "quotes"
      ? _c(
          "div",
          { staticClass: "container-component" },
          [
            _c("CarrierQuotesview", {
              attrs: { "is-quotes-view": _vm.selectedView == "quotes" },
              on: { selectedView: _vm.handleView },
            }),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "container-component" },
          [_c("ShipmentList", { on: { selectedView: _vm.handleView } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }