<template>
    <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.shipmentContents') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>medication_liquid</md-icon>
                    </div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Food
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="model.isFood">Food</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="model.isBaked">Baked</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="model.isCake">Cake</md-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Meat
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="model.isMeat">Meat</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="model.isBeef">Beef</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="model.isPork">Pork</md-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Liquid
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="model.isAlcohol">Alcohol</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="model.isLiquid">Liquid</md-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Medications
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="model.isPrescriptionMedication">
                                        Prescription medications
                                    </md-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Drugs
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="model.isTobacco">Tobacco</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="model.isDangerousGoods">Dangerous goods</md-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Fragile Things
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="model.isFragile">Fragile</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="model.isGlass">Glass</md-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Floral
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="model.isFlowers">Flowers</md-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item  md-size-25">
                            <h5 class="app-title">
                                Others
                            </h5>
                            <div class="md-layout-item md-size-100 layout-item">
                                <div>
                                    <md-checkbox v-model="model.isNotRotatable">Not rotatable</md-checkbox>
                                </div>
                                <div>
                                    <md-checkbox v-model="model.mustBeUpright">Must be upright</md-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </md-card-content>
                <md-card-actions md-alignment="left">
                    <button class="custom-btn" @click.stop="saveSettings">Save Settings</button>
                </md-card-actions>
            </md-card>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'ShipmentContents',
    mixins: [GeneralMixin],
    data() {
        return {
            model: {
                isAlcohol: false,
                isTobacco: false,
                isPrescriptionMedication: false,
                isDangerousGoods: false,
                isMeat: false,
                isPork: false,
                isBeef: false,
                isFragile: false,
                isFlowers: false,
                isCake: false,
                isFood: false,
                isLiquid: false,
                isNotRotatable: false,
                isGlass: false,
                isBaked: false,
                mustBeUpright: false
            }
        };
    },
    methods: {
        async getTeamDetails() {
            this.$_handleLoaderState(true, '');
            try {
                const api = '/api/teams';
                const { data } = await handleRequests(api);
                this.model = { ...this.model, ...data.data.defaultShipmentContentsConfiguration };
            } catch (e) {
                const message = 'Unable to fetch team settings.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async saveSettings() {
            // only shipment-contents
            const payload = {
                method: 'post',
                data: this.model
            };

            const api = '/api/teams/settings/shipment-contents';
            try {
                this.$_handleLoaderState(true, 'Updating settings');
                await handleRequests(api, payload);

                this.$notify({
                    message: 'Settings updated!',
                    type: 'success'
                });
            } catch (e) {
                const message = 'Cannot update Shipment Contents settings.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        }
    },
    async mounted() {
        await this.getTeamDetails();
    }
};
</script>

<style lang="scss" scoped>
.app-title {
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 0;
    color: #999;
}
.layout-item {
    padding: 0px;
}
.card-icon {
    background: #f53c3cc7 !important;
}
</style>
