var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c("div", { staticClass: "custom-toolbar" }, [
          _c(
            "div",
            { staticClass: "custom-toolbar-start" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "Team Settings",
                    },
                  },
                },
                [_c("h3", { staticClass: "title" }, [_vm._v("Team Settings")])]
              ),
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "   >    " +
                    _vm._s(_vm.$t("menus.setting.driverInstructions"))
                ),
              ]),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-blue" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("checklist")])],
                  1
                ),
              ]
            ),
            _c("md-card-content", [
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  { staticClass: "md-layout-item md-size-100" },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.model.hasAuthorityToLeave,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "hasAuthorityToLeave", $$v)
                          },
                          expression: "model.hasAuthorityToLeave",
                        },
                      },
                      [_vm._v("Has authority to leave")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item md-size-100" },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.model.mustCheckRecipientId,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "mustCheckRecipientId", $$v)
                          },
                          expression: "model.mustCheckRecipientId",
                        },
                      },
                      [_vm._v("Must check recipient Id")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item md-size-100" },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.model.mustWearHighVisibilityVest,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.model,
                              "mustWearHighVisibilityVest",
                              $$v
                            )
                          },
                          expression: "model.mustWearHighVisibilityVest",
                        },
                      },
                      [
                        _vm._v(
                          "\n                            Must wear high visibility vest\n                        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item md-size-100" },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.model.isContactlessDelivery,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "isContactlessDelivery", $$v)
                          },
                          expression: "model.isContactlessDelivery",
                        },
                      },
                      [_vm._v("Is contactless delivery")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item md-size-100" },
                  [
                    _c(
                      "md-field",
                      { attrs: { maxlength: "5" } },
                      [
                        _c("label", [_vm._v("Instructions to driver")]),
                        _c("md-textarea", {
                          model: {
                            value: _vm.model.instructions,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "instructions", $$v)
                            },
                            expression: "model.instructions",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("md-card-actions", { attrs: { "md-alignment": "left" } }, [
              _c(
                "button",
                {
                  staticClass: "custom-btn",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.saveSettings($event)
                    },
                  },
                },
                [_vm._v("Save Settings")]
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }