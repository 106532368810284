var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v("Edit " + _vm._s(_vm.assetType)),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "modal-body" }, [
      _c(
        "div",
        { staticClass: "md-layout create-asset-container" },
        [
          _c(
            "form-wrapper",
            {
              staticClass: "form-wrapper",
              attrs: { validator: _vm.$v.tracker },
            },
            [
              _vm.assetType === "Tracker"
                ? _c("tracker-step", {
                    attrs: {
                      data: _vm.formattedAssetData,
                      "show-dropdown": Boolean(false),
                      "is-disabled": Boolean(false),
                      "is-update-asset": Boolean(true),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.assetType.toLowerCase() !== "tracker"
            ? _c(
                "form-wrapper",
                {
                  staticClass: "form-wrapper",
                  attrs: { validator: _vm.$v.formattedAssetData },
                },
                [
                  _vm.assetType !== null
                    ? _c(
                        "div",
                        { staticClass: "md-layout-item md-size-100" },
                        _vm._l(
                          _vm.formattedCustomFields,
                          function (customField, index) {
                            return _c("custom-field-inputs", {
                              key: index,
                              attrs: {
                                "custom-field-definition": customField,
                                "initial-value":
                                  _vm.formattedAssetData[customField.name],
                              },
                              on: { changed: _vm.handleCustomFieldChanged },
                            })
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100" },
                    [
                      _c(
                        "form-group",
                        { attrs: { label: "Asset Name*", name: "name" } },
                        [
                          _c("md-input", {
                            model: {
                              value: _vm.formattedAssetData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formattedAssetData, "name", $$v)
                              },
                              expression: "formattedAssetData.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.assetType === "Vehicle" || _vm.assetType === "Trailer"
                        ? _c(
                            "form-group",
                            {
                              attrs: {
                                label: "Vehicle License Plate",
                                name: "vehicleLicensePlate",
                              },
                            },
                            [
                              _c("md-input", {
                                model: {
                                  value:
                                    _vm.formattedAssetData.vehicleLicensePlate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formattedAssetData,
                                      "vehicleLicensePlate",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formattedAssetData.vehicleLicensePlate",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "md-layout-item md-size-100" },
            [
              _vm.showTeamRegion
                ? _c(
                    "form-group",
                    { attrs: { name: "teamRegion", label: "Team Region" } },
                    [
                      _c("team-region-options", {
                        attrs: {
                          selected: _vm.formattedAssetData.teamRegionId,
                          mode: "update",
                          "show-auto": false,
                        },
                        on: { selectedOption: _vm.handleTeamRegionChanged },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.showAssetGroup
            ? _c("AssetGroup", {
                attrs: {
                  "team-region-id": _vm.formattedAssetData.teamRegionId,
                  "asset-group-id": _vm.formattedAssetData.assetGroupId,
                },
                on: { changed: _vm.handleAssetGroupChanged },
              })
            : _vm._e(),
          this.assetType.toLowerCase() == "tracker"
            ? _c(
                "div",
                {
                  staticClass: "md-layout-item md-size-100 timezone-container",
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "form-group",
                        { attrs: { name: "timeZone", label: "Time Zone" } },
                        [
                          _c("md-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.formattedAssetData.timeZone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formattedAssetData,
                                  "timeZone",
                                  $$v
                                )
                              },
                              expression: "formattedAssetData.timeZone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.timeZoneChanged
                        ? _c("div", { staticClass: "error-text" }, [
                            _vm._v(_vm._s(_vm.timeZoneChangedText)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "timezone-btn" },
                    [
                      _vm.timeZoneChanged
                        ? _c(
                            "md-button",
                            {
                              staticClass: "md-danger md-just-icon md-round",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.rejectTimeZoneUpdate($event)
                                },
                              },
                            },
                            [
                              _c("md-icon", [_vm._v("close")]),
                              _c("md-tooltip", [
                                _vm._v("Reject the current time zone."),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "md-button",
                            {
                              staticClass: "md-primary md-just-icon md-round",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.getTrackerTimeZone($event)
                                },
                              },
                            },
                            [
                              _c("md-icon", [_vm._v("my_location")]),
                              _c("md-tooltip", [_vm._v("Update time zone")]),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            on: {
              click: function ($event) {
                return _vm.update()
              },
            },
          },
          [_vm._v("Update")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }