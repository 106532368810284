var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "download-label" },
    [
      _c(
        "md-button",
        {
          class: [
            "generate-label-btn",
            _vm.useIcon ? "md-just-icon md-round" : "",
          ],
          attrs: { title: "Download Labels" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleDownloadLabel($event)
            },
          },
        },
        [
          _vm.useIcon
            ? _c("md-icon", [_vm._v("barcode_reader")])
            : _c("span", [_vm._v("Download Labels")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }