<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 id="carrierKeysModalTitle" class="modal-title">
                {{ `${carrierName} Access Keys` }}
            </h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <component
                v-if="currentCarrierComponent"
                :is="currentCarrierComponent"
                @onUpdate="update"
                @onCancel="hideModal"
                v-bind="currentProperties"
                ref="carrierApiKeys"
            ></component>
        </div>
        <div class="modal-footer">
            <md-button id="btnUpdate" class="dialog-button md-primary" @click.prevent="triggerUpdate">Update</md-button>
            <md-button class="dialog-button md-default" @click.prevent="hideModal">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import AusPostKeys from './components/AusPostKeys';
import DoordashKeys from './components/DoordashKeys';
import GoPeopleKeys from './components/GoPeopleKeys';
import GophrKeys from './components/GophrKeys';
import LalamoveKeys from './components/LalamoveKeys';
import SendleKeys from './components/SendleKeys';
import SherpaKeys from './components/SherpaKeys';
import TgeKeys from './components/TgeKeys';
import TransportifyKeys from './components/TransportifyKeys';
import TntKeys from './components/TntKeys';
import UberKeys from './components/UberKeys';
import Zoom2uKeys from './components/Zoom2uKeys';

export default {
    name: 'CarrierKeysModal',
    mixins: [GeneralMixin],
    props: {
        carrier: { type: Object, default: () => null },
        resolve: { type: Function, default: () => {} }
    },
    components: {
        SherpaKeys,
        AusPostKeys,
        DoordashKeys,
        GoPeopleKeys,
        GophrKeys,
        LalamoveKeys,
        SendleKeys,
        TgeKeys,
        TntKeys,
        TransportifyKeys,
        UberKeys,
        Zoom2uKeys
    },
    data() {
        return {
            data: null
        };
    },
    computed: {
        carrierName() {
            return this.carrier && this.carrier.carrierTeam ? this.carrier.carrierTeam.company : '';
        },
        currentCarrierComponent() {
            if (!this.carrier) 
                return null;

            return `${this.carrier.carrierTeam.carrierSettings.carrierCode}Keys`;
        },
        currentProperties() {
            return { data: this.data };
        }
    },
    methods: {
        hideModal() {
            this.$modal.hide();
        },
        triggerUpdate() {
            this.$refs.carrierApiKeys.update();
        },
        async update(accessKeys) {
            const carrierApiModel = Object.keys(accessKeys);
            const modelKeys = accessKeys[carrierApiModel[0]];
            const carrierApiKeys = Object.keys(modelKeys);

            carrierApiKeys.forEach((key, index) => {
                // we wont make any updates if api keys are masked
                // we will assume that the first character can be an asterisk
                if (modelKeys[key].substring(0, 2) === '**') {
                    modelKeys[key] = null; // if no change (still asterisk), set it to null; we'll take care of it in the backend
                }
            });
            accessKeys[carrierApiModel[0]] = { ...modelKeys }; // update back

            this.$_handleLoaderState(true, 'UPDATING...');

            try {
                const api = `/api/teams/carriers/${this.carrier.teamCarrierId}`;
                const payload = {
                    method: 'put',
                    data: accessKeys
                };

                await handleRequests(api, payload);

                this.$notify({
                    message: 'Successfully updated access keys.',
                    type: 'success'
                });

                this.resolve({ result: 'ok' });
            } catch (e) {
                const message = 'Could not update access keys.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        }
    },
    async mounted() {
        this.$_handleLoaderState(true, 'FETCHING...');

        try {
            const api = `/api/teams/carriers/${this.carrier.teamCarrierId}`;
            const payload = {
                method: 'get'
            };

            const resp = await handleRequests(api, payload);

            this.data = resp.data;
        } catch (e) {
            const message = 'Could not retrieve access keys.';
            showErrorMessage(this, message, e);
        } finally {
            this.$_handleLoaderState(false);
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-checkbox-container {
    top: 10px;
}

::v-deep .md-checkbox-label {
    opacity: 1 !important;
    color: black !important;
}

::v-deep div.md-field.no-underline:after {
    height: 0px;
}

::v-deep .md-checkbox {
    margin-top: 0px;
}
</style>
