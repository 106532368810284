<template>
    <div>
        <md-card-content>
            <md-table :value="reportData" class="table-hover">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Team Member">
                        {{ item.groupName }}
                    </md-table-cell>
                    <md-table-cell md-label="Over Speed">
                        {{ item.overSpeed }}
                    </md-table-cell>
                    <md-table-cell md-label="Fatigue">
                        {{ item.fatigue }}
                    </md-table-cell>
                    <md-table-cell md-label="Harsh Deceleration">
                        {{ item.harshDeceleration }}
                    </md-table-cell>
                    <md-table-cell md-label="Harsh Acceleration">
                        {{ item.harshAcceleration }}
                    </md-table-cell>
                    <md-table-cell md-label="Harsh Turning">
                        {{ item.harshTurning }}
                    </md-table-cell>
                    <md-table-cell md-label="Impact">
                        {{ item.impact }}
                    </md-table-cell>
                    <md-table-cell md-label="Roll Over">
                        {{ item.rollOver }}
                    </md-table-cell>
                    <md-table-cell md-label="High RPM">
                        {{ item.highRPM }}
                    </md-table-cell>
                    <md-table-cell md-label="RPM/Speed Mismatch">
                        {{ item.rpmSpeedMismatch }}
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </md-card-content>
    </div>
</template>
<style scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}
</style>
<script>
export default {
    name: 'SafetySummaryReportDetails',
    components: {},
    props: {
        memberList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {};
    },
    computed: {
        reportData() {
            return this.memberList;
        }
    },
    methods: {}
};
</script>
