var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-100 mx-auto" },
        [
          _c(
            "md-card",
            { staticClass: "md-card-calendar" },
            [
              _c(
                "md-card-content",
                [
                  _vm.members.length > 0
                    ? _c("fullCalendar", {
                        ref: "calendar",
                        attrs: { options: _vm.calendarOptions },
                        on: { eventClick: _vm.eventClick },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }