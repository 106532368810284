var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c(
          "h4",
          {
            staticClass: "modal-title",
            attrs: { id: "createEmailActionModalTitle" },
          },
          [_vm._v(_vm._s(_vm.isUpdate ? "Update" : "Create") + " Action")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          {
            staticClass: "form-wrapper",
            attrs: { validator: _vm.$v.customActionModel },
          },
          [
            _c(
              "form-group",
              { attrs: { name: "action", label: "Action Type" } },
              [
                _c(
                  "md-select",
                  {
                    attrs: { id: "action-type-select", disabled: "" },
                    model: {
                      value: _vm.customActionModel.action,
                      callback: function ($$v) {
                        _vm.$set(_vm.customActionModel, "action", $$v)
                      },
                      expression: "customActionModel.action",
                    },
                  },
                  _vm._l(_vm.actionTypeOptions, function (item) {
                    return _c(
                      "md-option",
                      { key: item.text, attrs: { value: item.value } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.text) +
                            "\n                        "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "form-group",
              { attrs: { name: "eventTrigger", label: "Event Trigger" } },
              [
                _c(
                  "md-select",
                  {
                    attrs: { id: "eventTrigger-select" },
                    model: {
                      value: _vm.customActionModel.eventTrigger,
                      callback: function ($$v) {
                        _vm.$set(_vm.customActionModel, "eventTrigger", $$v)
                      },
                      expression: "customActionModel.eventTrigger",
                    },
                  },
                  _vm._l(_vm.eventTriggers, function (item) {
                    return _c(
                      "md-option",
                      {
                        key: item.displayText,
                        attrs: {
                          "data-test-id":
                            "create-email-action-" + item.eventTrigger,
                          value: item.eventTrigger,
                        },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.displayText) +
                            "\n                        "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm.filters && _vm.filters.length > 0
              ? _c(
                  "form-group",
                  { attrs: { name: "filter.status", label: "Filter" } },
                  [
                    _c(
                      "md-select",
                      {
                        attrs: { id: "filter-status-select" },
                        model: {
                          value: _vm.nonWebhookStatusFilter,
                          callback: function ($$v) {
                            _vm.nonWebhookStatusFilter = $$v
                          },
                          expression: "nonWebhookStatusFilter",
                        },
                      },
                      _vm._l(_vm.filters, function (item) {
                        return _c(
                          "md-option",
                          {
                            key: item,
                            attrs: {
                              value: item,
                              "data-test-id":
                                "create-email-action-filter-" + item,
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item) +
                                "\n                        "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "form-group",
              {
                attrs: {
                  name: "settings.emailRecipientTypes",
                  label: "Recipient(s)",
                },
              },
              [
                _c(
                  "md-select",
                  {
                    attrs: { id: "emailRecipientTypes", multiple: "" },
                    model: {
                      value: _vm.customActionModel.settings.emailRecipientTypes,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.customActionModel.settings,
                          "emailRecipientTypes",
                          $$v
                        )
                      },
                      expression:
                        "customActionModel.settings.emailRecipientTypes",
                    },
                  },
                  _vm._l(_vm.emailRecipientOptions, function (item) {
                    return _c(
                      "md-option",
                      {
                        key: item.value,
                        attrs: {
                          value: item.value,
                          "data-test-id":
                            "create-email-action-recipient-types-" + item.value,
                        },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.text) +
                            "\n                        "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm.customActionModel.settings.emailRecipientTypes &&
            _vm.customActionModel.settings.emailRecipientTypes.includes(
              "Specified"
            )
              ? _c(
                  "form-group",
                  {
                    attrs: {
                      name: "settings.recipientEmailAddresses",
                      label: "Email address(es)",
                    },
                  },
                  [
                    _c("md-input", {
                      attrs: { id: "recipient-addresses-input" },
                      model: {
                        value:
                          _vm.customActionModel.settings
                            .recipientEmailAddresses,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.customActionModel.settings,
                            "recipientEmailAddresses",
                            $$v
                          )
                        },
                        expression:
                          "customActionModel.settings.recipientEmailAddresses",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            attrs: { id: "btnUpdateCustomAction" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.validate($event)
              },
            },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.isUpdate ? "Update" : "Create") +
                "\n            "
            ),
          ]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }