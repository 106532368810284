/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import i18n from '../i18n';

import assetGroup from './modules/assetGroup';
import map from './modules/map';
import team from './modules/team';
import user from './modules/user';
import routingSetting from './modules/routingSetting';
import optimiseSetting from './modules/optimiseSetting';
import customActions from './modules/custom-actions';
import generalSetting from './modules/generalSetting';

Vue.use(Vuex);

const INIT_STATE = {
    isLoading: false,
    loadingText: i18n.t('common.loadingText')
};

const getters = {
    isLoading: ({ isLoading }) => isLoading,
    loadingText: ({ loadingText }) => loadingText
};

const state = () => ({ ...INIT_STATE });

const mutations = {
    CHANGE_LOADING_STATE: (state, payload) => {
        Object.assign(state, payload);
    }
};

// eslint-disable-next-line import/prefer-default-export
export const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions: {},
    plugins: [
        createPersistedState({
            key: 'locate2u',
            paths: [
                'user',
                'map.isHeartbeatsToggled',
                'map.isPlannedRouteToggled',
                'map.isLocationHistoryToggled',
                'map.isStopsListToggled',
                'map.isStopStatusToggled',
                'map.GeofenceToggled',
                'map.activePanel',
                'routingSetting.radiusValue',
                'routingSetting.radiusUnits',
                'routingSetting.distanceCost',
                'routingSetting.timeCost',
                'routingSetting.fixedCost',
                'routingSetting.routeCenteringPenalty',
                'optimiseSetting.startLocationMode',
                'optimiseSetting.startLocation',
                'optimiseSetting.startTime',
                'optimiseSetting.endTime',
                'optimiseSetting.endLocationMode',
                'optimiseSetting.endLocation',
                'optimiseSetting.skillMatching',
                'optimiseSetting.teamMemberTimingOverride',
                'optimiseSetting.returnStartLocationStop',
                'optimiseSetting.ignoreTimeWindows',
                'optimiseSetting.teamRegionStartLocation',
                'optimiseSetting.warehouseStartLocation',
                'optimiseSetting.warehouseEndLocation',
                'optimiseSetting.useNewOptimiseStops',
                'generalSetting'
            ]
        })
    ],
    modules: {
        assetGroup,
        map,
        team,
        user,
        routingSetting,
        optimiseSetting,
        'custom-actions': customActions,
        generalSetting
    }
});
