var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam && _vm.offerSettingModel
    ? _c("div", { staticClass: "cf-container" }, [
        _c(
          "div",
          [
            _vm.offerSettingModel
              ? _c(
                  "md-card",
                  [
                    _c(
                      "md-card-content",
                      [
                        _vm.showManualAllocationSettings
                          ? _c("UpdateJobOfferSetting", {
                              attrs: { "offer-setting": _vm.offerSettingModel },
                            })
                          : _vm._e(),
                        !_vm.showManualAllocationSettings
                          ? _c("JobOfferSettingsWorded", {
                              attrs: { "offer-setting": _vm.offerSetting },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }