var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v("Create Customer Invoices"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          { staticClass: "form-wrapper", attrs: { validator: _vm.$v } },
          [
            _c(
              "div",
              { staticClass: "form-container" },
              [
                _vm.canViewTeamRegion
                  ? _c("team-region-member-filter-options", {
                      attrs: {
                        "team-region-id": _vm.teamRegionId,
                        "show-team-member": false,
                        "strict-team-region-filter": true,
                      },
                      on: { changed: _vm.handleTeamRegionMemberChanged },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "customer-filter" },
                  [
                    _c("customer-autocomplete", {
                      ref: "customerAutocomplete",
                      attrs: {
                        label: "Customer",
                        id: "autocustomer",
                        placeholder: "",
                        "should-focus": false,
                        "auto-fill-address": true,
                        "is-clearable": true,
                        "team-region-id": _vm.teamRegionId,
                      },
                      on: { input: _vm.handleCustomerChanged },
                      model: {
                        value: _vm.customer,
                        callback: function ($$v) {
                          _vm.customer = $$v
                        },
                        expression: "customer",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "hide-clear-button" },
                  [
                    _c(
                      "md-datepicker",
                      {
                        attrs: { "md-immediately": "", "md-debounce": 10 },
                        model: {
                          value: _vm.startDate,
                          callback: function ($$v) {
                            _vm.startDate = $$v
                          },
                          expression: "startDate",
                        },
                      },
                      [_c("label", [_vm._v("Start Date")])]
                    ),
                    _vm.startDate == null
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v(
                            "\n                        Start date is required.\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "hide-clear-button" },
                  [
                    _c(
                      "md-datepicker",
                      {
                        attrs: { "md-immediately": "", "md-debounce": 10 },
                        model: {
                          value: _vm.endDate,
                          callback: function ($$v) {
                            _vm.endDate = $$v
                          },
                          expression: "endDate",
                        },
                      },
                      [_c("label", [_vm._v("End Date")])]
                    ),
                    _vm.endDate == null
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v(
                            "\n                        End date is required.\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.createInvoices },
          },
          [_vm._v("Create Invoices")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }