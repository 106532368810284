var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "alert open alert-with-icon",
      class: [_vm.verticalAlign, _vm.horizontalAlign, _vm.alertType],
      style: _vm.customPosition,
      attrs: {
        "data-notify": "container",
        role: "alert",
        "data-notify-position": "top-center",
      },
      on: {
        click: function ($event) {
          return _vm.close()
        },
      },
    },
    [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "aria-hidden": "true",
            "data-notify": "dismiss",
          },
          on: { click: _vm.close },
        },
        [_vm._v("\n        ×\n    ")]
      ),
      _vm.icon
        ? _c(
            "i",
            { staticClass: "material-icons", attrs: { "data-notify": "icon" } },
            [_vm._v(_vm._s(_vm.icon))]
          )
        : _vm._e(),
      _c("span", {
        attrs: { "data-notify": "message" },
        domProps: { innerHTML: _vm._s(_vm.message) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }