var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "copyright text-center" }, [
          _vm._v("\n            Powered by\n            "),
          _c(
            "a",
            { attrs: { href: "https://www.locate2u.com", target: "_blank" } },
            [_c("img", { attrs: { src: "/img/logos/locate2u-logo-dark.png" } })]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }