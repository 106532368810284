<template>
    <div class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-rose">
                <div class="card-icon custom-header-icon-text">
                    <md-icon>notes</md-icon>
                    Possession History
                </div>

                <div class="filter-steps--container">
                    <md-field class="datepicker-container">
                        <md-datepicker
                            class="filter-step-date"
                            v-model="dateFilter"
                            md-immediately
                            :md-debounce="100"
                        ></md-datepicker>
                    </md-field>
                </div>
            </md-card-header>

            <md-card-content>
                <div v-if="!isLoading">
                    <md-table
                        v-model="logs.ownerHistory"
                        table-header-color="green"
                        v-if="logs && logs.ownerHistory.length"
                    >
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="Team Member">
                                <a
                                    :href="'/team/team-members/' + item.publicUserId"
                                    title="reassign stop"
                                    class="custom-ellipsis"
                                >
                                    <img
                                        v-if="item.ownerName"
                                        class="profile-image"
                                        :src="item.photoUrl !== null ? item.photoUrl : $root.defaultPhotoUrl"
                                        alt="avatar"
                                        @error="$_setDefaultBrokenImage"
                                    />
                                    {{ item.ownerName }}
                                </a>
                            </md-table-cell>

                            <md-table-cell
                                md-label="Time Taken"
                                :class="{ 'manual-actioned': item.checkedOutByUserFullName !== null }"
                            >
                                {{ item.checkOutDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                                <md-tooltip v-if="item.checkInNotes !== null" md-direction="left">
                                    "{{ item.checkOutNotes }}"
                                    <div v-if="item.checkedOutByUserFullName !== null">
                                        Actioned by {{ item.checkedOutByUserFullName }}
                                    </div>
                                </md-tooltip>
                            </md-table-cell>

                            <md-table-cell
                                md-label="Time Returned"
                                :class="{ 'manual-actioned': item.checkedInByUserFullName !== null }"
                            >
                                {{ item.checkInDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                                <md-tooltip v-if="item.checkInNotes !== null" md-direction="left">
                                    "{{ item.checkInNotes }}"
                                    <div v-if="item.checkedInByUserFullName !== null">
                                        Actioned by {{ item.checkedInByUserFullName }}
                                    </div>
                                </md-tooltip>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>

                    <p v-if="!isLoading && logs && logs.ownerHistory.length === 0" class="grayedout-text">
                        No possession history available
                    </p>

                    <md-card-actions
                        class="page-footer"
                        md-alignment="space-between"
                        v-if="logs && logs.ownerHistory.length"
                    >
                        <div>
                            <p v-if="total === pagination.perPage" class="card-category">
                                Page {{ pagination.currentPage }} of many
                            </p>
                            <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                        </div>
                        <pagination
                            v-model="pagination.currentPage"
                            class="pagination-no-border pagination-success"
                            :per-page="pagination.perPage"
                            :total="total"
                            @change-page="handleChangePage($event, pagination.perPage)"
                        />
                    </md-card-actions>
                </div>
                <div v-else>
                    <div class="body-list">
                        <div class="list-loader">
                            <fade-loader :loading="isLoading" color="#333333" />
                            <span>LOADING</span>
                        </div>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests } from '@/helpers';
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { Pagination } from '@/components';
import moment from 'moment';
import { ViewAddressModal } from '..';

const itemsPerPage = 20;

export default {
    name: 'AssetGroupOwnerHistory',
    mixins: [GeneralMixin],
    props: {
        assetLogs: {
            type: Object,
            default: () => {
                return {};
            }
        },
        assetGroupId: {
            type: [Number],
            default: () => null
        }
    },
    watch: {
        dateFilter(newValue, oldValue) {
            if (!this.isLoading) 
                this.fetchAssetLogList();
        }
    },
    components: {
        Pagination,
        FadeLoader
    },
    data() {
        return {
            locationData: {
                location: null
            },
            dateFilter: null,
            pagination: PAGINATION_DEFAULTS,
            isLoading: false,
            logs: null,
            totalItemCount: null
        };
    },
    async mounted() {
        this.logs = this.assetLogs;
        this.totalItemCount = this.assetLogs.total;
        this.pagination.total = this.assetLogs.total;
        this.pagination.perPage = itemsPerPage;

        const { type } = this.$route.params;

        let currentPage = 1;

        if (this.type === type) {
            currentPage = Number(this.$route.query.currentPage) || 1;
        }

        if (currentPage) {
            this.pagination.currentPage = currentPage;
        }
    },
    methods: {
        viewAddress(location) {
            this.locationData.location = location;
            this.$modal.show(ViewAddressModal, { data: this.locationData });
        },
        validLocation(location) {
            if (location === null) {
                return false;
            }

            if (location.latitude === 0 && location.longitude === 0) {
                return false;
            }

            return true;
        },
        async handleChangePage(currentPage = 1, perPage = itemsPerPage) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;

            await this.fetchAssetLogList(currentPage, perPage);

            this.$router.replace({ path: this.$route.path, query: { currentPage } });
        },
        async fetchAssetLogList(currentPage = 1, perPage = itemsPerPage) {
            this.isLoading = true;
            const params = {
                dateFilter: this.dateFilter ? moment(this.dateFilter).format('YYYY-MM-DD') : null,
                page: Number(currentPage),
                takeNext: Number(perPage)
            };

            const api = `/api/asset-groups/${this.assetGroupId}/owners`;

            const { data } = await handleRequests(api, {
                params
            });

            this.logs = data;
            this.pagination.total = data.total;
            this.isLoading = false;
        }
    },
    computed: {
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total ?? 0;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        ...mapGetters({
            user: 'user/user'
        })
    }
};
</script>

<style lang="scss" scoped>
.address {
    color: #2b93ff;

    &:hover {
        text-decoration: underline !important;
        cursor: pointer;
    }
}

.datepicker-container {
    float: right;
    width: 160px;
}

.filter-steps--container > div {
    display: inline-block;
}

.filter-steps--container {
    ::v-deep .md-field {
        min-height: 48px;
        margin-right: 20px;
        margin-top: 5px;
        vertical-align: top;
    }

    .filter-step-date {
        margin-right: 0;
        min-height: initial;
        position: absolute;
        z-index: 1;

        ::v-deep .md-input {
            width: 100%;
        }

        ::v-deep i {
            height: 25px;
            width: 25px;
        }

        ::v-deep .md-input-action {
            top: 3px;
        }
    }

    ::v-deep .md-datepicker:after,
    ::v-deep .md-datepicker:before {
        bottom: 0;
        height: 0;
    }

    ::v-deep .md-datepicker:before,
    ::v-deep .md-datepicker:after {
        padding-left: 4px;
        bottom: 40px;
        content: 'Date Filter';
        color: #aaaaaa;
        font-size: 14px;
        transform: none;
        z-index: 1;
    }

    ::v-deep .md-has-value:before,
    ::v-deep .md-has-value:after {
        bottom: 0;
        height: 0;
        content: '';
    }

    ::v-deep .md-datepicker .md-input-action {
        z-index: 99;
    }
}

::v-deep .md-card-actions.page-footer {
    padding-bottom: 0 !important;
}

.profile-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
}
.manual-actioned {
    color: silver;
}
</style>
