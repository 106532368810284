var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "md-layout optimised-stop-contact-block" }, [
      _c(
        "div",
        { staticClass: "md-layout-item" },
        [
          _c("md-icon", { staticClass: "optimised-stop-contact-group-icon" }, [
            _vm._v("\n                group\n            "),
          ]),
          _c("span", { staticClass: "left-margin" }, [
            _vm._v(_vm._s(_vm.content.contactName || "-")),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item" },
        [
          _c("md-icon", { staticClass: "optimised-stop-contact-call-icon" }, [
            _vm._v("\n                call\n            "),
          ]),
          _c("span", { staticClass: "left-margin" }, [
            _vm._v(_vm._s(_vm.content.contactPhone || "-")),
          ]),
        ],
        1
      ),
    ]),
    _vm.hasCapacitySettings("quantity", "volume", "weight")
      ? _c("div", { staticClass: "md-layout optimised-stop-capacity-block" }, [
          _vm.hasCapacitySettings("quantity")
            ? _c(
                "div",
                { staticClass: "md-layout-item md-size-40" },
                [
                  _c("md-icon", [
                    _c("img", {
                      staticClass: "capacity-images",
                      attrs: { src: "/img/icons/capacity-items-gray.png" },
                    }),
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.getCapacityInfo(_vm.content, "quantity", "items")
                      )
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.hasCapacitySettings("volume")
            ? _c(
                "div",
                { staticClass: "md-layout-item md-size-30" },
                [
                  _c("md-icon", [
                    _c("img", {
                      staticClass: "capacity-images",
                      attrs: { src: "/img/icons/capacity-volume-gray.png" },
                    }),
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.getCapacityInfo(_vm.content, "volume", "m3"))
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.hasCapacitySettings("weight")
            ? _c(
                "div",
                { staticClass: "md-layout-item md-size-30" },
                [
                  _c("md-icon", [
                    _c("img", {
                      staticClass: "capacity-images",
                      attrs: { src: "/img/icons/capacity-weight-gray.png" },
                    }),
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.getCapacityInfo(_vm.content, "weight", "kg"))
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.hasSkillsSettings()
      ? _c("div", { staticClass: "md-layout optimised-stop-skills-block" }, [
          _c("div", { staticClass: "md-layout-item" }, [
            _c("span", [_vm._v("Skills:")]),
            _c("span", [_vm._v(_vm._s(_vm.getSkillsInfo()))]),
          ]),
        ])
      : _vm._e(),
    _vm.hasServiceSettings()
      ? _c("div", { staticClass: "md-layout optimised-stop-services-block" }, [
          _c("div", { staticClass: "md-layout-item" }, [
            _c("span", [_vm._v("Services:")]),
            _c("span", [_vm._v(_vm._s(_vm.getServiceInfo()))]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }