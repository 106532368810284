var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c("div", { staticClass: "md-layout-item" }, [
        _c("div", { staticClass: "custom-toolbar" }, [
          _c(
            "div",
            { staticClass: "custom-toolbar-start" },
            [
              _c("run-schedule-filter-component", {
                attrs: { "team-region-id": _vm.filters.teamRegionId },
                on: { onFilterChanged: _vm.handleTeamRegionFilterChanged },
              }),
              _c("filter-component", {
                class: _vm.$root.isTablet ? "tablet-filter-container" : "",
                attrs: {
                  "team-members": function () {
                    return this$1.teamMembers
                  },
                },
                on: { onFilterRunSchedule: _vm.handleFilterOrSearch },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "custom-toolbar-end" },
            [
              _c("search-component", {
                ref: "search",
                on: { onSearch: _vm.handleFilterOrSearch },
              }),
              _c("create-run-schedule-button", {
                staticClass: "tablet-button-margin",
                attrs: { "team-members": _vm.teamMembers },
                on: { runCreated: _vm.handleRunCreated },
              }),
              _c("batch-upload", {
                attrs: {
                  title: "Import Run Schedules",
                  "template-type": "runSchedules",
                  "team-members": _vm.teamMembers,
                },
                on: { batchImported: _vm.handleRunCreated },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _vm.bulkListError.length > 0
            ? _c(
                "div",
                {
                  staticClass:
                    "alert alert-danger alert-dismissible fade show bulk-error-message",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: {
                        type: "button",
                        "data-dismiss": "alert",
                        "aria-label": "Close",
                      },
                      on: { click: _vm.closeAlert },
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("×"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    _vm._l(_vm.bulkListError, function (e) {
                      return _c(
                        "div",
                        {
                          key: e.runScheduleId,
                          staticClass: "bulk-single-container",
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(e.name) +
                                "\n                        "
                            ),
                          ]),
                          _c(
                            "div",
                            _vm._l(e.errors, function (error, i) {
                              return _c("div", { key: i }, [
                                _vm._v(_vm._s(error.message)),
                              ])
                            }),
                            0
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          class: _vm.total < _vm.pagination.perPage ? "margin-space-bot" : "",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-green" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("contact_page")])],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "custom-switch pull-right" },
                    [
                      _c(
                        "md-switch",
                        {
                          model: {
                            value: _vm.toggleDisableRuns,
                            callback: function ($$v) {
                              _vm.toggleDisableRuns = $$v
                            },
                            expression: "toggleDisableRuns",
                          },
                        },
                        [_vm._v("Show disabled runs")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("md-card-content", { staticClass: "body-list" }, [
                _vm.selectedRunSchedules.length
                  ? _c(
                      "div",
                      {
                        class: [
                          "bulk-section",
                          _vm.topScrollPosition > 150 ? "sticky" : "",
                        ],
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.selectedRunSchedules.length) +
                              " run schedule" +
                              _vm._s(
                                _vm.selectedRunSchedules.length > 1 ? "s" : ""
                              ) +
                              "\n                            selected.\n                        "
                          ),
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "drop-down",
                              {
                                attrs: {
                                  "should-close": _vm.shouldCloseDropdown,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "status md-warning",
                                    attrs: {
                                      slot: "title",
                                      "data-toggle": "dropdown",
                                    },
                                    slot: "title",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Change Status\n                                "
                                    ),
                                  ]
                                ),
                                _c(
                                  "ul",
                                  { staticClass: "dropdown-menu" },
                                  _vm._l(_vm.statuses, function (item, index) {
                                    return _c("li", { key: index }, [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleChangeRunScheduleStatus(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(item) +
                                              "\n                                        "
                                          ),
                                        ]
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                            _c(
                              "md-button",
                              {
                                staticClass: "md-round md-just-icon md-danger",
                                attrs: { title: "Delete Run Schedules" },
                                on: { click: _vm.bulkDelete },
                              },
                              [_c("md-icon", [_vm._v("delete")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.isListLoading
                  ? _c("div", { staticClass: "empty-table" }, [
                      _c(
                        "div",
                        { staticClass: "table-loader" },
                        [
                          _c("fade-loader", {
                            attrs: { loading: true, color: "#333333" },
                          }),
                          _c("span", [_vm._v("LOADING")]),
                        ],
                        1
                      ),
                    ])
                  : _c("div", [
                      _vm.runsList.length
                        ? _c(
                            "div",
                            [
                              _c(
                                "md-table",
                                {
                                  staticClass:
                                    "context-menu-support custom-paginated-table",
                                },
                                [
                                  _c(
                                    "md-table-row",
                                    [
                                      !_vm.isReadOnlyUser
                                        ? _c(
                                            "md-table-head",
                                            [
                                              _c("md-checkbox", {
                                                staticClass:
                                                  "run-checkbox checkbox-head",
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onSelectAll(
                                                      _vm.isSelectAll
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.isSelectAll,
                                                  callback: function ($$v) {
                                                    _vm.isSelectAll = $$v
                                                  },
                                                  expression: "isSelectAll",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("md-table-head", [_vm._v("Name")]),
                                      _c("md-table-head", [
                                        _vm._v("Team Member"),
                                      ]),
                                      _c("md-table-head", [_vm._v("Schedule")]),
                                      _c("md-table-head", [
                                        _vm._v("Run Number"),
                                      ]),
                                      _c("md-table-head", [_vm._v("Status")]),
                                      _c("md-table-head", [_vm._v("Actions")]),
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.runsList, function (item) {
                                    return _c(
                                      "md-table-row",
                                      {
                                        key: item.runScheduleId,
                                        class:
                                          item.status.toLowerCase() ==
                                          "disabled"
                                            ? "disabled-row"
                                            : "",
                                      },
                                      [
                                        !_vm.isReadOnlyUser
                                          ? _c(
                                              "md-table-cell",
                                              [
                                                _c("md-checkbox", {
                                                  staticClass: "run-checkbox",
                                                  attrs: {
                                                    value: item.runScheduleId,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.onSelectRun()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selectedRunSchedules,
                                                    callback: function ($$v) {
                                                      _vm.selectedRunSchedules =
                                                        $$v
                                                    },
                                                    expression:
                                                      "selectedRunSchedules",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "md-table-cell",
                                          { staticClass: "run-schedule-name" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "custom-a-blue",
                                                attrs: {
                                                  to: {
                                                    name: "Run Schedule Details",
                                                    params: {
                                                      runScheduleId:
                                                        item.runScheduleId,
                                                    },
                                                  },
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(item.name) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("md-table-cell", [
                                          Boolean(item.assignedTo.publicUserId)
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "custom-ellipsis",
                                                },
                                                [
                                                  item.assignedTo.fullName
                                                    ? _c("img", {
                                                        staticClass:
                                                          "profile-image",
                                                        attrs: {
                                                          src:
                                                            item.assignedTo
                                                              .photoUrl !== null
                                                              ? item.assignedTo
                                                                  .photoUrl
                                                              : _vm.$root
                                                                  .defaultPhotoUrl,
                                                          alt: "avatar",
                                                        },
                                                        on: {
                                                          error:
                                                            _vm.$_setDefaultBrokenImage,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        item.assignedTo.fullName
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v("Unassigned"),
                                              ]),
                                        ]),
                                        _c("md-table-cell", [
                                          item.schedule
                                            ? _c(
                                                "span",
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.cronExpressionExplainer(
                                                          item
                                                        )
                                                      ) +
                                                      "\n                                            "
                                                  ),
                                                  _c(
                                                    "md-tooltip",
                                                    {
                                                      staticClass:
                                                        "tooltip-width",
                                                      attrs: {
                                                        "md-direction":
                                                          "bottom",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            item.humanReadableSchedule
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : item.scheduleType ===
                                              "Fortnightly"
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(item.scheduleType) +
                                                    " (" +
                                                    _vm._s(
                                                      _vm._f("dateFormat")(
                                                        item.activeFromDate,
                                                        _vm.DATE_TYPES
                                                          .standardDate
                                                      )
                                                    ) +
                                                    ")\n                                        "
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(item.scheduleType) +
                                                    "\n                                        "
                                                ),
                                              ]),
                                        ]),
                                        _c("md-table-cell", [
                                          _vm._v(_vm._s(item.runNumber)),
                                        ]),
                                        _c(
                                          "md-table-cell",
                                          [
                                            _c("run-status-button", {
                                              attrs: { "run-schedule": item },
                                              on: {
                                                statusUpdated:
                                                  _vm.onChangedStatus,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "md-table-cell",
                                          { staticClass: "action-buttons" },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-button md-primary md-just-icon md-round btn-size-27",
                                                attrs: {
                                                  title: "Update run details",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.updateRunSchedule(
                                                      item.runScheduleId
                                                    )
                                                  },
                                                },
                                              },
                                              [_c("md-icon", [_vm._v("edit")])],
                                              1
                                            ),
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-danger md-just-icon md-round btn-size-27",
                                                attrs: { title: "Delete run" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleDeleteRun(
                                                      item.runScheduleId
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("md-icon", [
                                                  _vm._v("delete"),
                                                ]),
                                              ],
                                              1
                                            ),
                                            item.status.toLowerCase() ===
                                            "active"
                                              ? _c(
                                                  "md-button",
                                                  {
                                                    staticClass:
                                                      "md-primary md-just-icon md-round",
                                                    attrs: {
                                                      title: "Create Trip",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleCreateTrip(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("md-icon", [
                                                      _vm._v("add"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("p", { staticClass: "no-result-message" }, [
                              _vm._v(
                                "No results matching your search/filter could be found."
                              ),
                            ]),
                          ]),
                    ]),
              ]),
            ],
            1
          ),
          _c(
            "md-card-actions",
            {
              staticClass: "page-footer",
              attrs: { "md-alignment": "space-between" },
            },
            [
              _c("div", [
                _vm.total === _vm.pagination.perPage
                  ? _c("p", { staticClass: "card-category" }, [
                      _vm._v(
                        "\n                        Page " +
                          _vm._s(_vm.pagination.currentPage) +
                          " of many\n                    "
                      ),
                    ])
                  : _c("p", { staticClass: "card-category" }, [
                      _vm._v(
                        "Page " +
                          _vm._s(_vm.pagination.currentPage) +
                          " of " +
                          _vm._s(_vm.totalPages)
                      ),
                    ]),
              ]),
              _c("pagination", {
                staticClass: "pagination-no-border pagination-success",
                attrs: { "per-page": _vm.pagination.perPage, total: _vm.total },
                on: {
                  "change-page": function ($event) {
                    return _vm.handleChangePage($event, _vm.pagination.perPage)
                  },
                },
                model: {
                  value: _vm.pagination.currentPage,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "currentPage", $$v)
                  },
                  expression: "pagination.currentPage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }