var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldDisplayInfoWindow
    ? _c(
        "md-content",
        {
          class: [
            "info-tooltip md-elevation-2 md-layout map-panel",
            _vm.content.isOptimise ? "info-tooltip-height" : "",
          ],
          style: _vm.styles,
        },
        [
          _c(
            "md-card",
            {
              class: [
                "info--container",
                _vm.content.isStop ? "top-margin" : "",
                _vm.content.isOptimise ? "card-margin-top" : "",
              ],
            },
            [
              _c(
                "div",
                {
                  staticClass: "close-button",
                  on: {
                    click: function () {
                      return (_vm.shouldDisplayInfoWindow = false)
                    },
                  },
                },
                [_c("md-icon", [_vm._v("close")])],
                1
              ),
              ["driver", "assetGroup"].indexOf(_vm.content.type) !== -1
                ? _c(
                    "md-content",
                    { staticClass: "info-detail--container" },
                    [
                      !_vm.pinnedUser
                        ? _c(
                            "md-content",
                            { staticClass: "info-detail info-heading-box" },
                            [
                              _c(
                                "span",
                                { staticClass: "info-heading driver-name" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.content.fullName ||
                                        _vm.content.groupName
                                    )
                                  ),
                                ]
                              ),
                              _c("br"),
                              _vm.content.ownerName
                                ? _c(
                                    "span",
                                    { staticClass: "info-heading owner-name" },
                                    [_vm._v(_vm._s(_vm.content.ownerName))]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "misc-details" },
                        [
                          _vm.content.battery && _vm.isLastLocationUpdateToday
                            ? _c(
                                "md-content",
                                {
                                  staticClass: "info-detail",
                                  attrs: { title: "Battery level" },
                                },
                                [
                                  _c(
                                    "md-icon",
                                    { staticClass: "battery-icon" },
                                    [_vm._v("battery_std")]
                                  ),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.content.battery) + "%"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "md-content",
                            {
                              staticClass: "info-detail",
                              attrs: { title: "Speed" },
                            },
                            [
                              _vm.isLastLocationUpdateToday
                                ? _c("span", { staticClass: "time-update" }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm._f("timeFormat")(
                                            _vm.content.lastLocationUpdate
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                  ])
                                : _c("span", { staticClass: "info-detail" }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            _vm.content.lastLocationUpdate,
                                            _vm.dateTypes.standardDateTime
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                  ]),
                              _vm._v(
                                "\n                     \n                    "
                              ),
                              _c("md-icon", [_vm._v("speed")]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(
                                  _vm._s(_vm.convertSpeed(_vm.content.speed)) +
                                    " " +
                                    _vm._s(_vm.user.speedUnits || "kph")
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.content.type === "locationHistory"
                ? _c(
                    "md-content",
                    { staticClass: "info-detail--container" },
                    [
                      _c(
                        "div",
                        { staticClass: "misc-details" },
                        [
                          _c(
                            "md-content",
                            {
                              staticClass: "info-detail",
                              attrs: { title: "Speed" },
                            },
                            [
                              _vm.isDateToday
                                ? _c("span", { staticClass: "time-update" }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm._f("timeFormat")(
                                            _vm.content.lastLocationUpdate
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                "\n                     \n                    "
                              ),
                              _c("md-icon", [_vm._v("speed")]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(
                                  _vm._s(_vm.convertSpeed(_vm.content.speed)) +
                                    " " +
                                    _vm._s(_vm.user.speedUnits || "kph")
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.isDateToday
                        ? _c(
                            "md-content",
                            {
                              staticClass: "info-detail location-update",
                              attrs: { title: "Location update" },
                            },
                            [
                              _c("span", { staticClass: "info-label" }, [
                                _vm._v("Last location update"),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateTimeFormat")(
                                      _vm.content.lastLocationUpdate,
                                      _vm.dateTypes.standardDate
                                    )
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.content.isAssetGroup
                        ? _c(
                            "md-content",
                            {
                              staticClass: "info-detail update-type",
                              attrs: { title: "Type of update" },
                            },
                            [
                              _c("md-icon", [_vm._v("settings")]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.content.logType)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.content.type === "stop"
                ? _c(
                    "md-content",
                    { staticClass: "info-detail--container" },
                    [
                      _c(
                        "md-content",
                        { staticClass: "info-detail stop-address" },
                        [
                          _vm.content.isStartLocation
                            ? _c("span", { staticClass: "info-label" }, [
                                _vm._v(
                                  "\n                    Start location\n                "
                                ),
                              ])
                            : _vm.content.isEndLocation
                            ? _c("span", { staticClass: "info-label" }, [
                                _vm._v(
                                  "\n                    End location\n                "
                                ),
                              ])
                            : _vm._e(),
                          _c("span", { staticClass: "info-heading" }, [
                            _vm._v(_vm._s(_vm.content.address)),
                          ]),
                        ]
                      ),
                      ["Cancelled", "On Hold"].indexOf(_vm.content.status) ===
                      -1
                        ? [
                            _vm.content.originalEta || _vm.content.arrivalDate
                              ? _c(
                                  "md-content",
                                  {
                                    staticClass:
                                      "info-detail route-optimization",
                                  },
                                  [
                                    _c("span", { staticClass: "info-label" }, [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            [
                                              "Arrived",
                                              "Complete",
                                              "Failed",
                                            ].indexOf(_vm.content.status) === -1
                                              ? "ETA"
                                              : "Time of arrival:"
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                    ["Arrived", "Complete", "Failed"].indexOf(
                                      _vm.content.status
                                    ) !== -1
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm._f("timeFormat")(
                                                  _vm.content.arrivalDate,
                                                  _vm.dateTypes.standardTime
                                                )
                                              ) +
                                              "\n                    "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("timeFormat")(
                                                _vm.content.originalEta,
                                                _vm.dateTypes.standardTime
                                              )
                                            )
                                          ),
                                        ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "misc-details" },
                        [
                          _vm.content.contactName
                            ? _c(
                                "md-content",
                                {
                                  staticClass: "info-detail",
                                  attrs: { title: "Contact name" },
                                },
                                [
                                  _c("md-icon", [_vm._v("supervisor_account")]),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.content.contactName)),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.content.contactPhone
                            ? _c(
                                "md-content",
                                {
                                  staticClass: "info-detail",
                                  attrs: { title: "Contact phone number" },
                                },
                                [
                                  _c("md-icon", [_vm._v("call")]),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.content.contactPhone)),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "md-content",
                        { staticClass: "buttons-container" },
                        [
                          _vm.content.stopId
                            ? _c(
                                "md-button",
                                {
                                  staticClass:
                                    "info--buttons md-primary md-button md-just-icon md-round",
                                  attrs: {
                                    title: "stop details",
                                    "md-ripple": false,
                                  },
                                  on: { click: _vm.showDetailsPage },
                                },
                                [_c("md-icon", [_vm._v("visibility")])],
                                1
                              )
                            : _vm._e(),
                          _vm.content.trackingLinkUrl
                            ? _c(
                                "md-button",
                                {
                                  staticClass:
                                    "info--buttons md-primary md-button md-just-icon md-round",
                                  attrs: {
                                    title: "tracking link",
                                    "md-ripple": false,
                                  },
                                  on: { click: _vm.showTrackingLink },
                                },
                                [_c("md-icon", [_vm._v("directions_car")])],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : ["statusHistory", "geofence"].indexOf(_vm.content.type) !== -1
                ? _c(
                    "md-content",
                    { staticClass: "info-detail--container" },
                    [
                      _c(
                        "md-content",
                        { staticClass: "info-detail stop-address" },
                        [
                          _c("span", { staticClass: "info-heading" }, [
                            _vm._v(_vm._s(_vm.content.address)),
                          ]),
                        ]
                      ),
                      _c(
                        "md-content",
                        {
                          staticClass: "info-detail stop-ref",
                          on: { click: _vm.stopRefClicked },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.content.stopRef) +
                              "\n            "
                          ),
                        ]
                      ),
                      _c(
                        "md-content",
                        {
                          staticClass:
                            "info-detail--container stop-status--detail stop-status--change",
                        },
                        [
                          _c(
                            "span",
                            {
                              style: {
                                color: _vm.setColor(_vm.content.oldStatus),
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.content.oldStatus) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _c("md-icon", { staticClass: "arrow-icon" }, [
                            _vm._v("arrow_right_alt"),
                          ]),
                          _c(
                            "span",
                            {
                              style: {
                                color: _vm.setColor(_vm.content.newStatus),
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.content.newStatus) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "md-content",
                        {
                          staticClass: "info-detail action-date",
                          attrs: { title: "Action date" },
                        },
                        [
                          _c("span", { staticClass: "info-label" }, [
                            _vm._v("Action date"),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateTimeFormat")(
                                  _vm.content.actionDate,
                                  _vm.dateTypes.standardDate
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm.content.type === "geofence"
                ? _c(
                    "div",
                    [
                      _c(
                        "md-content",
                        { staticClass: "info-detail geo-fence-name" },
                        [
                          _c("b", [_vm._v(_vm._s(_vm.content.title))]),
                          _c("p", [_vm._v(_vm._s(_vm.content.notes))]),
                          _c(
                            "div",
                            { staticClass: "cta" },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-button md-just-icon md-round md-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "editGeoFence",
                                        _vm.content.id
                                      )
                                    },
                                  },
                                },
                                [_c("md-icon", [_vm._v("edit")])],
                                1
                              ),
                              _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-button md-just-icon md-round md-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "deleteGeoFence",
                                        _vm.content.id
                                      )
                                    },
                                  },
                                },
                                [_c("md-icon", [_vm._v("delete")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }