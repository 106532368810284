<template>
    <div :class="[tripList.length > 1 ? 'trip-summary' : '']">
        <div class="alert alert-warning" v-if="unallocatedStopList.length > 0">
            <button type="button" aria-hidden="true" class="close">×</button>
            <span>
                Could not route &nbsp;
                <a href="#" @click="handleUnassignedStopList(unallocatedStopList)">
                    {{ unallocatedStopListLength }} stops
                </a>
            </span>
        </div>
        <div class="md-layout md-size-100 trip-tools" v-if="hasDisplayed && tripList.length > 1">
            <div class="md-layout-item md-size-80 search-field">
                <div class="search-icon">
                    <md-icon>search</md-icon>
                </div>
                <search-component
                    :handle-search-on-type="true"
                    :search-icon="false"
                    :placeholder="placeholderText"
                    @onSearch="handleSearch"
                />
            </div>
            <div class="md-layout-item md-size-20 icon-pin-all">
                <a href="#" @click="onPinedAll" :class="{ active: isPinedAll }">
                    <md-icon class="route-pin-all">
                        push_pin
                    </md-icon>
                    <md-tooltip md-direction="right">
                        <span v-if="isPinedAll">
                            Unpin All
                        </span>
                        <span v-else>
                            Pin All
                        </span>
                    </md-tooltip>
                </a>
            </div>
        </div>
        <div class="table-wrapper" v-if="hasDisplayed">
            <md-table class="trip-table table-container">
                <md-table-row v-if="orderedTripList.length > 0">
                    <md-table-head>Driver</md-table-head>
                    <md-table-head>Stops</md-table-head>
                    <md-table-head>Finish Time</md-table-head>
                    <md-table-head>
                        <form-group
                            class="vehicle-config"
                            v-if="
                                user.vehicleCapacityUnitsConfiguration != null &&
                                    user.vehicleCapacityUnitsConfiguration.length > 0
                            "
                        >
                            <md-select v-model="selectedCapacityType">
                                <md-option
                                    v-for="(vehicleCapacity, index) in user.vehicleCapacityUnitsConfiguration"
                                    :key="index"
                                    :value="vehicleCapacity.type"
                                    class="vehicle-config"
                                >
                                    {{ vehicleCapacity.label }}
                                </md-option>
                            </md-select>
                        </form-group>
                    </md-table-head>
                    <md-table-head />
                </md-table-row>
                <md-table-row v-for="(item, index) in orderedTripList" :key="index" slot="md-table-row">
                    <md-table-cell class="allocatestop">
                        <span :style="{ background: item.color }">&nbsp;</span>
                        <img
                            v-if="item.teamMember.photoUrl"
                            class="profile-image"
                            :src="item.teamMember.photoUrl"
                            @error="$_setDefaultBrokenImage"
                            alt
                        />
                        <img v-else class="profile-image" :src="$root.defaultPhotoUrl" alt />
                        <span v-if="startLocationMode === 'teammembers'">{{ item.teamMember.fullName }}</span>
                        <span v-else>
                            <a href="#" @click="handleAssignedTrip(item.vehicleId)">{{ item.teamMember.fullName }}</a>
                        </span>
                    </md-table-cell>
                    <md-table-cell class="allocatestop-number">
                        <a href="#" @click="handleAssignedStopList(item.vehicleId)">{{ item.stopsCount }} Stops</a>
                    </md-table-cell>
                    <md-table-cell class="finish-time">
                        {{ item.arrivalTime }}
                    </md-table-cell>
                    <md-table-cell
                        class="allocatestop-column"
                        v-if="
                            user.vehicleCapacityUnitsConfiguration != null &&
                                user.vehicleCapacityUnitsConfiguration.length > 0
                        "
                    >
                        {{ getVehicleLoad(item.vehicle, item.teamMember) }}
                    </md-table-cell>
                    <md-table-cell class="allocatestop-pin">
                        <a href="#" @click="showTeamMemberSettingsModal(item)">
                            <md-icon class="route-pin">
                                settings
                            </md-icon>
                        </a>
                        <a href="#" @click="allocateStopMarkers(item)" :class="{ active: item.isPinned }">
                            <md-icon class="route-pin">
                                push_pin
                            </md-icon>
                        </a>
                    </md-table-cell>
                </md-table-row>
            </md-table>
            <div v-if="orderedTripList.length > 1" class="totals-footer">
                <md-table class="trip-table">
                    <md-table-row>
                        <md-table-cell class="total-stops-footer">{{ totalStops }} Stops</md-table-cell>
                        <md-table-cell class="total-capacity-footer">{{ totalLoad }}</md-table-cell>
                    </md-table-row>
                </md-table>
            </div>
            <div class="row">
                <div class="text-center">
                    <div class="checkbox-fixed-order">
                        <md-checkbox v-model="isFixedStopOrder">
                            Create Fixed Order Trips
                        </md-checkbox>
                        <md-tooltip class="fixed-order-tooltip" md-direction="bottom">
                            If selected, the trips created will have a fixed stop order as per the original planned
                            route.
                            <br />
                            If unselected, the stops will change order, allowing the trip to be automatically rerouted
                            when additional stops are added to the trip or if the driver doesn't follow the expected
                            stop order.
                        </md-tooltip>
                    </div>
                    <button class="custom-btn" @click="onCreateTrips()">Create {{ tripList.length }} Trips</button>
                    <md-button
                        title="Export Trips"
                        class="md-primary md-just-icon md-round pull-right header-button"
                        @click="onExportTrips()"
                    >
                        <md-icon>get_app</md-icon>
                    </md-button>
                    <md-button
                        v-if="!isStartRouting && !loadedSaveModel"
                        title="Save Optimise"
                        class="md-primary md-just-icon md-round pull-right header-button"
                        @click="onSaveOptimise()"
                    >
                        <md-icon>save</md-icon>
                    </md-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { SearchComponent } from '@/components';
import { mapGetters } from 'vuex';
import RoutingNameModal from '@/pages/BatchStop/Settings/RoutingNameModal';
import TeamMemberSettingsModal from '@/pages/BatchStop/StepComponents/TeamMemberSettingsModal';
import moment from 'moment';

export default {
    mixins: [GeneralMixin],
    name: 'TripSummary',
    components: {
        SearchComponent
    },
    props: {
        tripList: {
            type: Array,
            default: () => []
        },
        unallocatedStopList: {
            type: Array,
            default: () => []
        },
        hasDisplayed: {
            type: Boolean,
            default: false
        },
        isStartRouting: {
            type: Boolean,
            default: false
        },
        loadedSaveModel: {
            type: Boolean,
            default: false
        },
        startLocationMode: {
            type: String,
            default: null
        },
        startTripTime: {
            type: String,
            default: null
        },
        endTripTime: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            unallocatedStopListLength: 0,
            isPinedAll: false,
            placeholderText: 'Find team member...',
            isFixedStopOrder: true,
            orderedTripList: [],
            selectedCapacityType: null,
            capacityValues: null
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        }),
        totalStops() {
            if (this.orderedTripList.length > 0) {
                let totalStops = 0;

                // eslint-disable-next-line  no-return-assign
                this.orderedTripList.forEach((item) => (totalStops += item.stopsCount));
                return totalStops;
            }

            return 0;
        },
        totalLoad() {
            if (this.orderedTripList.length > 0) {
                let totalLoad = 0;
                let loadType = '';

                // eslint-disable-next-line  no-return-assign
                this.orderedTripList.forEach((item) => {
                    if (!this.user?.vehicleCapacityUnitsConfiguration) 
                        return;

                    if (
                        !item.vehicle.vehicleLoad ||
                        !this.user.vehicleCapacityUnitsConfiguration ||
                        this.user.vehicleCapacityUnitsConfiguration.length <= 0
                    )
                        totalLoad += 0;

                    // eslint-disable-next-line no-unused-vars
                    const { symbol, defaultMaxCapacity } = this.user.vehicleCapacityUnitsConfiguration.find(
                        (x) => x.type === this.selectedCapacityType
                    );
                    loadType = symbol;

                    const load = item.vehicle.vehicleLoad[this.selectedCapacityType] ?? 0;
                    totalLoad += load;
                });

                const loadValue = totalLoad.toString();
                let total = totalLoad;
                if (loadValue.includes('.')) {
                    const numberOfDecimals = loadValue.split('.')[1].length;
                    if (numberOfDecimals > 2) {
                        total = total.toFixed(2);
                    }
                }

                return `${total} ${loadType}`;
            }

            return '';
        }
    },
    methods: {
        getVehicleLoad(vehicle, teamMember) {
            if (
                !vehicle.vehicleLoad ||
                !this.user.vehicleCapacityUnitsConfiguration ||
                this.user.vehicleCapacityUnitsConfiguration.length <= 0
            )
                return '';

            if (this.selectedCapacityType === null && this.user.vehicleCapacityUnitsConfiguration) {
                this.selectedCapacityType = this.user.vehicleCapacityUnitsConfiguration[0].type;
            }

            const { symbol, defaultMaxCapacity } = this.user.vehicleCapacityUnitsConfiguration.find(
                (x) => x.type === this.selectedCapacityType
            );

            const vehicleCapacity = teamMember.vehicleCapacity[this.selectedCapacityType] ?? defaultMaxCapacity;

            const load = vehicle.vehicleLoad[this.selectedCapacityType];
            const displayMaxCapacity = vehicleCapacity ? `/${vehicleCapacity}` : '';
            vehicle.capacityValues = {};
            vehicle.capacityValues[this.selectedCapacityType] = vehicleCapacity;

            return `${load}${displayMaxCapacity} ${symbol} `;
        },
        onPinedAll() {
            const isPin = !this.isPinedAll;
            this.isPinedAll = isPin;
            this.orderedTripList.forEach((trip) => {
                trip.isPinned = isPin;
                this.$emit('allocateStopMarkers');
            });
        },
        handleSearch(value) {
            const { searchText } = value;
            if (searchText) {
                const filterTripList = this.tripList.filter((trip) => {
                    return trip.teamMember.fullName.toLowerCase().match(searchText.toLowerCase());
                });
                this.orderedTripList = filterTripList;
            } else {
                this.orderedTripList = this.getSortedTripList();
            }
        },
        handleAssignedStopList(vehicleId) {
            this.$emit('handleAssignedStopList', vehicleId, this.selectedCapacityType);
        },
        handleUnassignedStopList(stops) {
            this.$emit('handleUnassignedStopList', stops);
        },
        allocateStopMarkers(item) {
            item.isPinned = !item.isPinned;
            const tripPin = this.orderedTripList.map((trip) => trip.isPinned);
            this.isPinedAll = tripPin.every((value) => value === true);
            this.$emit('allocateStopMarkers');
        },
        onCreateTrips() {
            this.$emit('onCreateTrips', this.isFixedStopOrder);
        },
        handleAssignedTrip(vehicleId) {
            this.$emit('handleAssignedTrip', vehicleId);
        },
        onExportTrips() {
            this.$emit('onExportTrips');
        },
        getSortedTripList() {
            return this.tripList.slice().sort((x, y) => (x.teamMember.fullName > y.teamMember.fullName ? 1 : -1));
        },
        onSaveOptimise() {
            this.$modal.show(RoutingNameModal).then((response) => {
                this.$emit('onSaveOptimise', response);
            });
        },
        showTeamMemberSettingsModal(item) {
            this.$modal
                .show(TeamMemberSettingsModal, {
                    tripItem: item,
                    startTripTime: item.vehicle.customStartTime
                        ? item.vehicle.customStartTime
                        : moment(item.vehicle.vehicleStartTime).format('HH:mm'),
                    endTripTime: item.vehicle.customEndTime
                        ? item.vehicle.customEndTime
                        : moment(item.vehicle.vehicleEndTime).format('HH:mm'),
                    capacityValues: item.teamMember.vehicleCapacity
                })
                .then((response) => {
                    item.vehicle.customStartTime = response.startTime;
                    item.vehicle.customEndTime = response.endTime;
                    this.$emit('handleChangeTeamMemberSettings', response);
                });
        }
    },
    watch: {
        tripList() {
            if (this.tripList.length) {
                this.orderedTripList = this.getSortedTripList();
            }
        },
        unallocatedStopList() {
            this.unallocatedStopListLength = this.unallocatedStopList.length;
            const shipmentsCount = this.unallocatedStopList.filter((x) => x.shipment !== null);

            if (shipmentsCount) {
                this.unallocatedStopListLength += shipmentsCount.length;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.trip-summary {
    margin-top: -25px;
    .alert {
        margin-bottom: 0px;
        margin-top: 32px;
    }
}
.trip-table {
    padding-left: 0;
    line-height: 18px;
    list-style-type: none;
    max-height: 350px;
    background-color: transparent;
    ::v-deep > div {
        background-color: transparent !important;
    }
    ::v-deep .md-table-head {
        padding: 0px;
    }
    .md-table-cell {
        padding: 0px;
        border: 0px;
        height: 32px;
    }
    .address-container {
        max-width: 410px;
        .md-table-cell-container {
            white-space: nowrap;
            width: 450px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    td.md-table-cell.vehicle-capacity,
    td.md-table-cell.vehicle-capacity .md-table-cell-container {
        padding-right: 30px;
        text-align: right;
    }
    td.md-table-cell.vehicle-capacity,
    td.md-table-cell.vehicle-capacity .md-table-cell-container {
        padding-right: 30px;
        text-align: right;
    }
    .allocatestop {
        max-width: 150px;
        min-width: 120px;
        padding-right: 10px;

        ::v-deep div {
            white-space: nowrap;
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .allocatestop a {
        color: #2b93ff;
    }
    .allocatestop-pin {
        max-width: 50px;
        min-width: 50px;
    }
    .active .md-icon.md-theme-default.md-icon-font {
        color: #2b93ff;
        transform: rotate(0deg);
    }
    td.md-table-cell.allocatestop,
    td.md-table-cell.allocatestop .md-table-cell-container {
        padding-left: 5px;
    }
    .route-pin {
        transform: rotate(90deg);
    }
    .profile-image {
        border-radius: 50%;
        height: 24px;
        width: 24px;
        box-shadow: none !important;
        border: 1px solid #eee;
    }
}
.md-content.md-table.trip-table.md-theme-default,
.md-content.md-table.trip-table.md-theme-default div {
    background-color: transparent;
}
.alert-warning a {
    color: #fdfdfd !important;
    text-decoration: underline !important;
}
.start-stop-route-btn {
    background-color: transparent;
    color: #2b93ff !important;
    border: 1px solid;
    width: 100%;
    max-width: 115px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    line-height: 38px;
    font-size: 15px;
    cursor: pointer;
    float: right;
}
.stop-routing-label {
    color: #999 !important;
}
.md-menu-content.md-select-menu {
    width: 100px !important;
}
.trip-tools {
    margin: 5px 0px;
    border-bottom: 1px solid #e8e8e8;
    .md-layout-item {
        padding: 0px;
    }
    .active .md-icon.md-theme-default.md-icon-font {
        color: #2b93ff;
        transform: rotate(0deg);
    }
    .route-pin-all {
        transform: rotate(90deg);
        margin-top: 8px;
        margin-right: 15px;
    }
    .icon-pin-all {
        a {
            float: right;
        }
    }
    .vehicle-units-filter {
        width: 85px;
        right: 75px;
        position: absolute;
        margin-top: 4px;
        ::v-deep input {
            border: 0px;
            background: transparent;
            text-align: right;
        }
    }
    .search--container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        ::v-deep .md-field {
            box-sizing: border-box;
            margin: 0;
            padding: 3px 0px 0px 5px;
            &::before,
            &::after {
                bottom: 0px;
                display: none;
            }
        }
        ::v-deep input {
            font-size: 12px !important;
        }
        ::placeholder {
            font-size: 12px !important;
        }
        ::v-deep .md-clear {
            display: none;
        }
    }
    .search-field {
        .search-icon {
            float: left;
            width: 15px;
            padding: 8px 0px 0px;
            cursor: pointer;
        }
        i {
            font-size: 12px !important;
            color: #aaa;
        }
    }
}
.custom-btn {
    margin-right: 50px;
    margin-bottom: 0px;
}
.checkbox-fixed-order {
    float: left;
    padding-top: 15px;
    max-width: 180px;
    text-align: left;
    ::v-deep .md-checkbox-label {
        font-weight: 500;
        color: #333;
        padding-left: 5px;
    }
    ::v-deep .md-checkbox {
        margin: 5px;
    }
}
.checkbox-show-service-area {
    margin-top: 0px;
    margin-left: 5px;
    text-align: left;
    ::v-deep .md-checkbox-label {
        font-weight: 500;
        color: #333;
        padding-left: 5px;
    }
    ::v-deep .md-checkbox {
        margin: 5px;
    }
}
.vehicle-units {
    width: 85px;
    margin-top: -15px;
    float: right;
    ::v-deep input {
        border: 0px;
        background: transparent;
        text-align: right;
    }
}
.md-tooltip-bottom {
    &.fixed-order-tooltip {
        max-width: 450px;
        text-align: justify;
    }
}

.allocatestop-number {
    max-width: 80px;
    min-width: 80px;
}

.finish-time {
    min-width: 120px;
    max-width: 120px;
}

.allocatestop-column {
}

.vehicle-config {
    margin: -4px 0 0 !important;
    max-width: 200px;
    min-width: 150px;

    ::v-deep input {
        font-size: 1.0625rem !important;
        font-weight: 300;
    }
}

.table-wrapper {
    position: relative;
}

.table-container {
    max-height: 300px;
    overflow-y: auto;
}

.total-stops-footer {
    padding-left: 135px !important;
    width: 56%;
}

.total-capacity-footer {
}
</style>
