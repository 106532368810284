/* eslint-disable import/no-cycle */
import { handleRequests } from '@/helpers/index';
import {
    UPDATE_MAX_RADIUS_VALUE,
    UPDATE_MAX_RADIUS_UNITS,
    UPDATE_DISTANCE_COST,
    UPDATE_TIME_COST,
    UPDATE_FIXED_COST,
    UPDATE_ROUTE_CENTERING_PENALTY,
    UPDATE_DISTRIBUTE_WORKLOAD_COST,
    FETCH_ROUTE_SETTINGS,
    FETCH_DEFAULT_ROUTE_SETTINGS,
    UPDATE_DEFAULT_ROUTE_SETTINGS
} from '@/store/types';

const INIT_STATE = () => ({
    radiusValue: null,
    radiusUnits: null,
    distanceCost: null,
    timeCost: null,
    fixedCost: null,
    routeCenteringPenalty: null,
    distributeWorkloadCost: null,
    defaultRouteSettings: null
});

const getters = {
    maxRadiusValue: (state) => state.radiusValue,
    maxRadiusUnits: (state) => state.radiusUnits,
    getDistanceCost: (state) => state.distanceCost,
    getTimeCost: (state) => state.timeCost,
    getFixedCost: (state) => state.fixedCost,
    getRouteCenteringPenalty: (state) => state.routeCenteringPenalty,
    getDistributeWorkloadCost: (state) => state.distributeWorkloadCost,
    getDefaultRouteSettings: (state) => state.defaultRouteSettings
};

const mutations = {
    [UPDATE_MAX_RADIUS_VALUE]: (state, payload) => {
        state.radiusValue = payload;
    },
    [UPDATE_MAX_RADIUS_UNITS]: (state, payload) => {
        state.radiusUnits = payload;
    },
    [UPDATE_DISTANCE_COST]: (state, payload) => {
        state.distanceCost = payload;
    },
    [UPDATE_TIME_COST]: (state, payload) => {
        state.timeCost = payload;
    },
    [UPDATE_FIXED_COST]: (state, payload) => {
        state.fixedCost = payload;
    },
    [UPDATE_ROUTE_CENTERING_PENALTY]: (state, payload) => {
        state.routeCenteringPenalty = payload;
    },
    [UPDATE_DISTRIBUTE_WORKLOAD_COST]: (state, payload) => {
        state.distributeWorkloadCost = payload;
    },
    [UPDATE_DEFAULT_ROUTE_SETTINGS]: (state, payload) => {
        state.defaultRouteSettings = payload;
    }
};

const state = () => ({ ...INIT_STATE() });

const actions = {
    async [FETCH_ROUTE_SETTINGS]({ commit }) {
        const url = `/api/teams/route-settings`;
        try {
            const { data } = await handleRequests(url);
            commit(UPDATE_DISTANCE_COST, data?.distanceCost);
            commit(UPDATE_FIXED_COST, data?.fixedCost);
            commit(UPDATE_TIME_COST, data?.timeCost);
            commit(UPDATE_ROUTE_CENTERING_PENALTY, data?.routeCenteringPenalty);
            commit(UPDATE_DISTRIBUTE_WORKLOAD_COST, data?.distributeWorkloadCost);

            return data;
        } catch (e) {
            commit(UPDATE_DISTANCE_COST, null);
            commit(UPDATE_FIXED_COST, null);
            commit(UPDATE_TIME_COST, null);
            commit(UPDATE_ROUTE_CENTERING_PENALTY, null);
            commit(UPDATE_DISTRIBUTE_WORKLOAD_COST, null);

            return null;
        }
    },
    async [FETCH_DEFAULT_ROUTE_SETTINGS]({ commit }) {
        const url = `/api/teams/route-settings/default`;
        try {
            const { data } = await handleRequests(url);
            if (data) 
                commit(UPDATE_DEFAULT_ROUTE_SETTINGS, data);

            return data;
        } catch (e) {
            commit(UPDATE_DEFAULT_ROUTE_SETTINGS, null);

            return null;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
