var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [
        _vm._v("Custom Mapping - " + _vm._s(_vm.title)),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-group",
          { attrs: { name: "formatString", label: "Format string" } },
          [
            _c("md-input", {
              model: {
                value: _vm.formatString,
                callback: function ($$v) {
                  _vm.formatString = $$v
                },
                expression: "formatString",
              },
            }),
            _c(
              "button",
              {
                staticClass: "info-button",
                attrs: {
                  type: "button",
                  title: "Set Up Spreadsheet Import Mappings",
                },
                on: {
                  click: function ($event) {
                    return _vm.openInfo()
                  },
                },
              },
              [
                _c(
                  "md-icon",
                  { staticClass: "info-icon", on: { click: _vm.openInfo } },
                  [_vm._v("info")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "form-group",
          { attrs: { name: "defaultValue", label: "Default value" } },
          [
            _c("md-input", {
              model: {
                value: _vm.defaultValue,
                callback: function ($$v) {
                  _vm.defaultValue = $$v
                },
                expression: "defaultValue",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.saveCustomMapping },
          },
          [_vm._v("\n            OK\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: { click: _vm.closeCustomMapping },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }