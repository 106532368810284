<template>
    <md-toolbar
        md-elevation="0"
        class="md-transparent custom-toolbar-navigation"
        :class="{
            'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute
        }"
    >
        <div class="md-toolbar-row">
            <div class="md-toolbar-section-start">
                <h3 class="md-title">
                    {{ $route.meta.title ? $route.meta.title : $route.name }}
                </h3>
                <div class="topbar-logo">
                    <img src="/img/logos/locate2u-logo.png" class="logo-complete" />
                </div>
            </div>
            <div class="md-toolbar-section-end">
                <md-button
                    class="md-just-icon md-round md-simple md-toolbar-toggle"
                    :class="{ toggled: $sidebar.showSidebar }"
                    @click="toggleSidebar"
                >
                    <span class="icon-bar" />
                    <span class="icon-bar" />
                    <span class="icon-bar" />
                </md-button>
            </div>
        </div>
    </md-toolbar>
</template>

<script>
export default {
    methods: {
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-toolbar-navigation {
    background-color: #1c3e61 !important;

    .md-title {
        color: #fff !important;
        width: 48%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (min-width: 768px) {
            width: 61%;
        }
    }
    .icon-bar {
        background-color: #fff !important;
    }

    .md-toolbar-section-start {
        flex: 2;
        .topbar-logo {
            max-width: 100px;
            margin-top: 6px;
            img {
                width: 100%;
            }
        }
    }
}
</style>
