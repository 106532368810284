var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cf-container" },
    [
      _c(
        "div",
        { staticClass: "cf-action-container" },
        [
          _c(
            "md-button",
            {
              staticClass: "md-primary md-just-icon md-round",
              attrs: { title: "Add custom SMS template" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.addNewSMSTemplate()
                },
              },
            },
            [_c("md-icon", [_vm._v("add")])],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "form-group",
            {
              staticClass: "sms-settings-key",
              attrs: { name: "twilioSid", label: _vm.accountLabel },
            },
            [
              _c("md-input", {
                model: {
                  value: _vm.smsSettings.accountId,
                  callback: function ($$v) {
                    _vm.$set(_vm.smsSettings, "accountId", $$v)
                  },
                  expression: "smsSettings.accountId",
                },
              }),
            ],
            1
          ),
          _c(
            "form-group",
            {
              staticClass: "sms-settings-key",
              attrs: { name: "twiliotoken", label: _vm.tokenLabel },
            },
            [
              _c("md-input", {
                model: {
                  value: _vm.smsSettings.accountToken,
                  callback: function ($$v) {
                    _vm.$set(_vm.smsSettings, "accountToken", $$v)
                  },
                  expression: "smsSettings.accountToken",
                },
              }),
            ],
            1
          ),
          _c(
            "form-group",
            {
              staticClass: "sms-settings-key",
              attrs: {
                name: "sms-sent-from",
                label: "Phone number to send sms from",
              },
            },
            [
              _c("md-input", {
                model: {
                  value: _vm.smsSettings.outboundSmsPhoneNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.smsSettings, "outboundSmsPhoneNumber", $$v)
                  },
                  expression: "smsSettings.outboundSmsPhoneNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "form-group",
            {
              staticClass: "sms-settings-key",
              attrs: {
                name: "sms-sent-from",
                label: "Sender Id to send sms from",
              },
            },
            [
              _c("md-input", {
                model: {
                  value: _vm.smsSettings.outboundSmsSenderId,
                  callback: function ($$v) {
                    _vm.$set(_vm.smsSettings, "outboundSmsSenderId", $$v)
                  },
                  expression: "smsSettings.outboundSmsSenderId",
                },
              }),
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass: "save-sms-key-btn custom-btn",
              on: { click: _vm.update },
            },
            [_vm._v("\n            Save\n        ")]
          ),
          _vm.hasAllSettings
            ? _c(
                "button",
                {
                  staticClass: "save-sms-key-btn custom-btn",
                  on: { click: _vm.sendTestSMS },
                },
                [_vm._v("\n            Send Test SMS\n        ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "md-table",
        { staticClass: "custom-paginated-table" },
        [
          _c(
            "md-table-row",
            [
              _c("md-table-head", [_vm._v("Event Trigger")]),
              _c("md-table-head", [_vm._v("Template")]),
              _c("md-table-head", [_vm._v("Stop Type")]),
              _c("md-table-head", [_vm._v("Actions")]),
            ],
            1
          ),
          _vm._l(_vm.smsTemplates, function (smsTemplate, index) {
            return _c(
              "md-table-row",
              { key: index },
              [
                _c("md-table-cell", [
                  _c("div", [_vm._v(_vm._s(smsTemplate.eventTriggerText))]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: smsTemplate.name,
                          expression: "smsTemplate.name",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(smsTemplate.name))]
                  ),
                ]),
                _c("md-table-cell", [
                  _vm._v(
                    "\n                " +
                      _vm._s(smsTemplate.template) +
                      "\n            "
                  ),
                ]),
                _c("md-table-cell", [
                  _vm._v(
                    "\n                " +
                      _vm._s(smsTemplate.stopType) +
                      "\n            "
                  ),
                ]),
                _c(
                  "md-table-cell",
                  { staticClass: "action-buttons" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass: "md-warning md-just-icon md-round",
                        attrs: { title: "Edit SMS" },
                        on: {
                          click: function ($event) {
                            return _vm.editSmsTemplate(smsTemplate)
                          },
                        },
                      },
                      [_c("md-icon", [_vm._v("edit")])],
                      1
                    ),
                    smsTemplate.isCustom > 0
                      ? _c(
                          "md-button",
                          {
                            staticClass: "md-danger md-just-icon md-round",
                            attrs: { title: "Delete SMS" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteSmsTemplate(smsTemplate.id)
                              },
                            },
                          },
                          [_c("md-icon", [_vm._v("delete")])],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }