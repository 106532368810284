<template>
    <div class="stop-list map-panel map-panel-stop-list" v-if="routeDisplay !== 'None'">
        <md-card-content class="stop-table-body">
            <md-table v-if="stopList.length > 0" class="context-menu-support custom-paginated-table">
                <md-table-row>
                    <md-table-head></md-table-head>
                    <md-table-head>Address</md-table-head>
                    <md-table-head>Status</md-table-head>
                    <md-table-head class="eta-header">ETA/Arrival</md-table-head>
                    <md-table-head></md-table-head>
                </md-table-row>

                <md-table-row
                    v-for="(stopItem, stopIndex) in stopList"
                    :key="stopIndex"
                    :class="{ 'table-danger': stopItem.isOfferStop, 'offer-stop': stopItem.isOfferStop }"
                    class="stop-list-row"
                    @mouseover="onStopHighlighted(stopItem)"
                    @mouseout="onStopHighlighted(null)"
                >
                    <md-table-cell class="stop-order">{{ stopIndex + 1 }}.</md-table-cell>

                    <md-table-cell class="stop-address">
                        {{ formatStopAddressDesc(stopItem) }}
                    </md-table-cell>

                    <md-table-cell>
                        <span class="status" :class="`status-${stopItem.status.toLowerCase().replace(/\s+/g, '')}`">
                            {{ fixStopStatus(stopItem) }}
                        </span>
                    </md-table-cell>

                    <md-table-cell class="stop-time">
                        <div v-if="['On Hold', 'Cancelled'].indexOf(stopItem.status) === -1">
                            <span
                                v-if="['Arrived', 'Complete', 'Failed'].indexOf(stopItem.status) !== -1"
                                class="bold-time"
                            >
                                {{ stopItem.arrivalDate | timeFormat(dateTypes.standardTime) }}
                            </span>

                            <span v-else-if="stopItem.etaTime">
                                {{ stopItem.etaTime }}
                            </span>

                            <span v-else>
                                {{ stopItem.originalEta | timeFormat(dateTypes.standardTime) }}
                            </span>
                        </div>
                    </md-table-cell>

                    <md-table-cell>
                        <router-link
                            :to="{
                                name: 'Stop Details',
                                params: { stopId: stopItem.stopId }
                            }"
                            target="_blank"
                            @click.native="$event.stopImmediatePropagation()"
                            title="Stop details"
                        >
                            <md-icon class="link-icon">
                                open_in_new
                            </md-icon>
                        </router-link>
                    </md-table-cell>
                </md-table-row>
            </md-table>

            <div v-else class="empty-table">
                <span>
                    {{ pinnedDriverDetails ? pinnedDriverDetails.firstName : 'This driver' }} has no stops assigned for
                    today
                </span>
            </div>
        </md-card-content>
    </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import useGeneralUtils from '@/compostables/useGeneralUtils';

export default {
    name: 'StopList',

    props: {
        pinnedOffer: {
            type: Object,
            default: null
        },

        pinnedDriverDetails: {
            type: Object,
            default: () => {}
        },

        highlightedStopId: {
            type: Number,
            default: null,
            required: false
        },

        routeDisplay: {
            type: String,
            default: () => 'Both'
        },

        shipmentDetails: {
            type: Object,
            default: null
        }
    },

    setup(props, context) {
        const { dateTypes } = useGeneralUtils(context);

        // TODO: Validate that the calculated trip includes all the stops from the current trip
        // and show a message that the calculation is out of date if they aren't. Complete and
        // Failed stops(+ On Hold ?) should be excluded. NOTE: To be done in DispatchDashboard.vue

        // TODO: Show the trip start and end location stop? NOT NEEDED FOR NOW

        // TODO: Show a loading indicator, so that when switching between pinned drivers and
        // waiting for the route, it doesn't show the old route's details.

        // TODO: Toggle display of completed/failed stops

        // TODO: Display the offered stop(s) in bold in the list, or highlight the row. Also show with status "Offering"

        const stopList = computed(() => {
            // TODO: Merge the trip details with the calculated trip

            // if (!props.currentTripDetails) // || !props.calculatedTripDetails
            //    return [];

            // // TODO: Use the calculated route as the base
            // return props.currentTripDetails.stopList || [];

            // if (!props.pinnedOffer || !props.pinnedOffer.proposedRoute || !props.pinnedOffer.proposedRoute.stops) // || !props.calculatedTripDetails
            //    return [];

            if (props.routeDisplay === 'Current') 
                return props.pinnedOffer?.currentRoute?.stops || [];

            return props.pinnedOffer?.proposedRoute?.stops || []; // If Both is selected as the route display, show the offered route
        });

        const onStopHighlighted = (stop) => {
            // This will highlight this stop on the map, showing an info window with more details about the stop
            context.emit('update:highlightedStopId', stop?.stopId);
        };

        const formatStopAddressDesc = (stop) => {
            return `${stop.type ? `${stop.type.charAt(0)} -` : '  '} ${stop.stopName}`;
        };

        const fixStopStatus = (stop) => {
            // the Stop List section data is from the calculation logs, which means that the status will most likely not in sync with the current ones
            // ex. you're looking for an old jobs and/or was calculated in the past, or the job has already been assigned
            // if the display need to change, change it here.
            return stop.status;
        };

        return {
            stopList,
            dateTypes,
            onStopHighlighted,
            formatStopAddressDesc,
            fixStopStatus
        };
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';

.stop-list {
    position: absolute;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    // to remove x-overflow
    width: 580px;

    max-height: 350px;
    overflow-y: auto;

    .stop-table-body {
        padding: 8px;
    }

    ::v-deep .md-table {
        background-color: transparent !important;

        .md-table-content {
            background-color: transparent !important;

            tr {
                & > .md-table-head {
                    padding: 2px 4px;
                }

                & > .md-table-cell {
                    padding: 0px 4px;
                    height: 35px;
                }

                .stop-order {
                    padding-left: 10px !important;
                }
            }

            .eta-header {
                padding-right: 0;
                text-align: right;

                .md-table-head-label {
                    padding-right: 0;
                }
            }
        }

        .md-icon {
            font-size: 15px !important;
            padding-left: 12px;

            &:hover {
                color: var(--bg-color);
            }
        }
    }

    .stop-time {
        ::v-deep .md-table-cell-container {
            text-align: right;
        }

        .bold-time {
            font-weight: 500;
        }
    }

    .offer-stop {
        .stop-address {
            font-weight: bold;
        }
    }

    .stop-address {
        ::v-deep .md-table-cell-container {
            max-width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .empty-table {
        height: 150px;
        display: flex;

        span {
            margin: auto;
        }
    }
}

.status {
    border-radius: 14px;
    padding: 3px 10px;
    font-size: 12px;
}

.status-pending {
    background-color: $status-pending !important;
    color: #fff !important;
}

.status-pending:hover,
.status-pending:focus {
    color: #fff !important;
}

.status-enroute {
    background-color: $status-enroute !important;
}

.status-picked-up {
    background-color: $status-complete !important;
}

.status-complete {
    background-color: $status-complete !important;
}

.status-delayed {
    background-color: $status-delayed !important;
}

.status-cancelled {
    background-color: $status-cancelled !important;
}

.status-offering {
    background-color: $status-offering !important;
    color: white;
}

.map-panel-stop-list {
    bottom: 25px;
}

.stop-list-row {
    cursor: pointer;
}
</style>
