var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam
    ? _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Settings"),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "   >    " +
                        _vm._s(_vm.$t("menus.setting.appPermissionSettings"))
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100 main-container" },
          [
            _c(
              "md-card",
              [
                _c("md-card-content", [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v(
                      "\n                    Select from the list of below features which you want to provide access for mobile app users.\n                "
                    ),
                  ]),
                  _c("div", { staticClass: "md-layout" }, [
                    _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                      _c("h5", { staticClass: "app-title" }, [
                        _vm._v(
                          "\n                            Stops\n                        "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout-item md-size-100 layout-item",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.addStops,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "addStops",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.addStops",
                                  },
                                },
                                [_vm._v("Add Stops")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value:
                                      _vm.appPermissions.addStopsForCustomers,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "addStopsForCustomers",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.addStopsForCustomers",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Add Stops for Customers\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.updateStops,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "updateStops",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.updateStops",
                                  },
                                },
                                [_vm._v("Update Stops")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.deleteStops,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "deleteStops",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.deleteStops",
                                  },
                                },
                                [_vm._v("Delete Stops")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value:
                                      _vm.appPermissions.changeStopStatuses,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "changeStopStatuses",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.changeStopStatuses",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Change Stop Statuses\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.reorderStops,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "reorderStops",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.reorderStops",
                                  },
                                },
                                [_vm._v("Reorder Stops")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.viewStopNotes,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "viewStopNotes",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.viewStopNotes",
                                  },
                                },
                                [_vm._v("View Stop Notes")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.addStopNotes,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "addStopNotes",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.addStopNotes",
                                  },
                                },
                                [_vm._v("Add Stop Notes")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.discardStops,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "discardStops",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.discardStops",
                                  },
                                },
                                [_vm._v("Discard Stops")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.selectCustomer,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "selectCustomer",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.selectCustomer",
                                  },
                                },
                                [_vm._v("Select Customer")]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "md-layout-item md-size-35 md-form-label",
                              },
                              [
                                _vm._v(
                                  "\n                                    Stop Line Mode\n                                "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "md-layout-item md-size-65" },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c(
                                      "md-select",
                                      {
                                        attrs: { name: "stopLineMode" },
                                        model: {
                                          value:
                                            _vm.appPermissions.stopLineMode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.appPermissions,
                                              "stopLineMode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "appPermissions.stopLineMode",
                                        },
                                      },
                                      [
                                        _c(
                                          "md-option",
                                          { attrs: { value: "noPricing" } },
                                          [
                                            _vm._v(
                                              "\n                                                No Pricing\n                                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "md-option",
                                          {
                                            attrs: { value: "teamMemberCosts" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                Team Member Costs\n                                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "md-option",
                                          {
                                            attrs: { value: "customerPricing" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                Customer Pricing\n                                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                      _c("h5", { staticClass: "app-title" }, [
                        _vm._v(
                          "\n                            Shipments\n                        "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout-item md-size-100 layout-item",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.addShipments,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "addShipments",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.addShipments",
                                  },
                                },
                                [_vm._v("Add Shipments")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.updateShipments,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "updateShipments",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.updateShipments",
                                  },
                                },
                                [_vm._v("Update Shipments")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.deleteShipments,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "deleteShipments",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.deleteShipments",
                                  },
                                },
                                [_vm._v("Delete Shipments")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value:
                                      _vm.appPermissions.changeShipmentStatuses,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "changeShipmentStatuses",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.changeShipmentStatuses",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Change Shipment Statuses\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.discardShipments,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "discardShipments",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.discardShipments",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Discard Shipments\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "md-layout-item md-size-25" }, [
                      _c("h5", { staticClass: "app-title" }, [
                        _vm._v(
                          "\n                            Users\n                        "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout-item md-size-100 layout-item",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.updateUserProfile,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "updateUserProfile",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.updateUserProfile",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Update User Profile\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.updateUserStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "updateUserStatus",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.updateUserStatus",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Update User Status\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.changeUserPhoto,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "changeUserPhoto",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.changeUserPhoto",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Change User Photo\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.changeLogo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "changeLogo",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.changeLogo",
                                  },
                                },
                                [_vm._v("Change Logo")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                      _c("h5", { staticClass: "app-title" }, [
                        _vm._v(
                          "\n                            Links\n                        "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout-item md-size-100 layout-item",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.createLinks,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "createLinks",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.createLinks",
                                  },
                                },
                                [_vm._v("Create Links")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.deleteLinks,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "deleteLinks",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.deleteLinks",
                                  },
                                },
                                [_vm._v("Delete Links")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.addStopLines,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "addStopLines",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.addStopLines",
                                  },
                                },
                                [_vm._v("Add Stop Lines")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.updateStopLines,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "updateStopLines",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.updateStopLines",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Update Stop Lines\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.deleteStopLines,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "deleteStopLines",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.deleteStopLines",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Delete Stop Lines\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "md-layout" }, [
                    _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                      _c("h5", { staticClass: "app-title" }, [
                        _vm._v(
                          "\n                            Trips\n                        "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout-item md-size-100 layout-item",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.updateTrips,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "updateTrips",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.updateTrips",
                                  },
                                },
                                [_vm._v("Update Trips")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value:
                                      _vm.appPermissions
                                        .askTripQuestionsForEachRun,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "askTripQuestionsForEachRun",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.askTripQuestionsForEachRun",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Ask Trip Questions for Each Run\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "md-layout-item md-size-35 md-form-label",
                              },
                              [
                                _vm._v(
                                  "\n                                    Trip List Mode\n                                "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "md-layout-item md-size-65" },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c(
                                      "md-select",
                                      {
                                        attrs: { name: "tripListMode" },
                                        model: {
                                          value:
                                            _vm.appPermissions.tripListMode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.appPermissions,
                                              "tripListMode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "appPermissions.tripListMode",
                                        },
                                      },
                                      [
                                        _c(
                                          "md-option",
                                          { attrs: { value: "all" } },
                                          [
                                            _vm._v(
                                              "\n                                                Stops + Shipments\n                                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "md-option",
                                          { attrs: { value: "stops" } },
                                          [
                                            _vm._v(
                                              "\n                                                Stops\n                                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "md-option",
                                          { attrs: { value: "shipments" } },
                                          [
                                            _vm._v(
                                              "\n                                                Shipments\n                                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "md-layout-item md-size-25" }, [
                      _c("h5", { staticClass: "app-title" }, [
                        _vm._v(
                          "\n                            Assets\n                        "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout-item md-size-100 layout-item",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value:
                                      _vm.appPermissions
                                        .registerGpsTrackingDevice,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "registerGpsTrackingDevice",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.registerGpsTrackingDevice",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Register GPS Tracking Device\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value:
                                      _vm.appPermissions.checkAssetsInAndOut,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "checkAssetsInAndOut",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.checkAssetsInAndOut",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Take Possession of Assets...\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "padding-left": "30px" } },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "md-checkbox",
                                    {
                                      model: {
                                        value:
                                          _vm.appPermissions.assetCheckInOut
                                            .nfc,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.appPermissions.assetCheckInOut,
                                            "nfc",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "appPermissions.assetCheckInOut.nfc",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        via NFC Tap\n                                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "md-checkbox",
                                    {
                                      model: {
                                        value:
                                          _vm.appPermissions.assetCheckInOut
                                            .barcode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.appPermissions.assetCheckInOut,
                                            "barcode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "appPermissions.assetCheckInOut.barcode",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        via Scan Barcode\n                                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "md-checkbox",
                                    {
                                      model: {
                                        value:
                                          _vm.appPermissions.assetCheckInOut
                                            .selectFromList,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.appPermissions.assetCheckInOut,
                                            "selectFromList",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "appPermissions.assetCheckInOut.selectFromList",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        via Select From List\n                                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                      _c("h5", { staticClass: "app-title" }, [
                        _vm._v(
                          "\n                            Invoices\n                        "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout-item md-size-100 layout-item",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value:
                                      _vm.appPermissions.createCustomerInvoices,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "createCustomerInvoices",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.createCustomerInvoices",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Create Customer Invoices\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.acceptPayments,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "acceptPayments",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.acceptPayments",
                                  },
                                },
                                [_vm._v("Accept Payments")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                      _c("h5", { staticClass: "app-title" }, [
                        _vm._v(
                          "\n                            Items\n                        "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout-item md-size-100 layout-item",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.pickupItems,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "pickupItems",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.pickupItems",
                                  },
                                },
                                [_vm._v("Pickup Items")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.returnItems,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "returnItems",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.returnItems",
                                  },
                                },
                                [_vm._v("Return Items")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value:
                                      _vm.appPermissions.warehouseItemsScan,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "warehouseItemsScan",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.warehouseItemsScan",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Warehouse Items Scan\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "md-layout" }, [
                    _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                      _c("h5", { staticClass: "app-title" }, [
                        _vm._v(
                          "\n                            Views\n                        "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout-item md-size-100 layout-item",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.views.map,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions.views,
                                        "map",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.views.map",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Map\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.views.stops,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions.views,
                                        "stops",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.views.stops",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Stops\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  on: { change: _vm.itemScanningChanged },
                                  model: {
                                    value: _vm.appPermissions.views.items,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions.views,
                                        "items",
                                        $$v
                                      )
                                    },
                                    expression: "appPermissions.views.items",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Item scanning\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  on: { change: _vm.itemChecklistChanged },
                                  model: {
                                    value:
                                      _vm.appPermissions.views.itemChecklist,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions.views,
                                        "itemChecklist",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.views.itemChecklist",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Item checklist\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "md-layout-item md-size-35 md-form-label",
                              },
                              [
                                _vm._v(
                                  "\n                                    Default view\n                                "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "md-layout-item md-size-65" },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c(
                                      "md-select",
                                      {
                                        attrs: { name: "defaultview" },
                                        model: {
                                          value:
                                            _vm.appPermissions.views.default,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.appPermissions.views,
                                              "default",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "appPermissions.views.default",
                                        },
                                      },
                                      [
                                        _c(
                                          "md-option",
                                          { attrs: { value: "" } },
                                          [
                                            _vm._v(
                                              "\n                                                Not Selected\n                                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "md-option",
                                          { attrs: { value: "map" } },
                                          [
                                            _vm._v(
                                              "\n                                                Map\n                                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "md-option",
                                          { attrs: { value: "stops" } },
                                          [
                                            _vm._v(
                                              "\n                                                Stops\n                                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "md-option",
                                          { attrs: { value: "items" } },
                                          [
                                            _vm._v(
                                              "\n                                                Item scanning\n                                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "md-option",
                                          { attrs: { value: "itemChecklist" } },
                                          [
                                            _vm._v(
                                              "\n                                                Item checklist\n                                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "md-layout-item  md-size-25" }, [
                      _c("h5", { staticClass: "app-title" }, [
                        _vm._v(
                          "\n                            Photo/Camera options\n                        "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout-item md-size-100 layout-item",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value:
                                      _vm.appPermissions.quickCapturePhotoNotes,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "quickCapturePhotoNotes",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.quickCapturePhotoNotes",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Quick capture photo notes\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "md-checkbox",
                                {
                                  model: {
                                    value: _vm.appPermissions.uploadFromGallery,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appPermissions,
                                        "uploadFromGallery",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appPermissions.uploadFromGallery",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Upload from gallery\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("md-card-actions", { attrs: { "md-alignment": "left" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "custom-btn",
                      on: { click: _vm.saveAppPermissions },
                    },
                    [_vm._v("\n                    Save\n                ")]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }