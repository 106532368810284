var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dropdownOptions != null && _vm.dropdownOptions.length > 0
    ? _c(
        "div",
        { staticClass: "card-icon running-load-menu" },
        [
          _c("drop-down", { attrs: { direction: "down" } }, [
            _c(
              "a",
              { staticClass: "load-header-text", attrs: { href: "#" } },
              [
                _c("md-icon", [_vm._v("assignment")]),
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.selectedOption != null ? _vm.selectedOption.label : ""
                    ) +
                    "\n            "
                ),
                _c("md-icon", { staticClass: "icon-arrow" }, [
                  _vm._v("expand_more"),
                ]),
              ],
              1
            ),
            _c(
              "ul",
              { staticClass: "dropdown-menu" },
              _vm._l(_vm.dropdownOptions, function (item) {
                return _c("li", { key: item.key }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleSelectedOption(item)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.label) +
                          "\n                "
                      ),
                    ]
                  ),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "card-icon" },
        [
          _vm.defaultIcon != null
            ? _c("md-icon", [_vm._v(_vm._s(_vm.defaultIcon))])
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.defaultText) + "\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }