<template>
    <div class="status-container">
        <md-icon :class="iconColor">
            {{ icon }}
        </md-icon>
        <span :class="labelColor">{{ label }}</span>
    </div>
</template>

<script>
export default {
    name: 'JobStatus',
    props: {
        variant: {
            type: String,
            required: true,
            values: ['success', 'warning', 'error']
        },
        customLabels: {
            type: Object,
            default: () => ({
                success: 'Complete',
                warning: 'In Progress',
                error: 'Failed'
            })
        }
    },
    computed: {
        icon() {
            switch (this.variant) {
                case 'success':
                    return 'check_circle';
                case 'error':
                    return 'error';
                case 'warning':
                default:
                    return 'warning';
            }
        },
        label() {
            const customLabel = this.customLabels[this.variant];
            return customLabel || this.variant;
        },
        iconColor() {
            switch (this.variant) {
                case 'success':
                    return 'success-icon-color';
                case 'error':
                    return 'error-icon-color';
                case 'warning':
                default:
                    return 'warning-icon-color';
            }
        },
        labelColor() {
            switch (this.variant) {
                case 'success':
                    return 'success-icon-color';
                case 'error':
                    return 'error-icon-color';
                case 'warning':
                default:
                    return 'warning-icon-color';
            }
        }
    }
};
</script>
<style scoped lang="scss">
.status-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 5px;
}

::v-deep .md-icon {
    margin: 0;
    &.success-icon-color {
        color: #4caf50;
    }

    &.warning-icon-color {
        color: #ffc107;
    }

    &.error-icon-color {
        color: #f44336;
    }
}

.success-icon-color {
    color: #4caf50;
}

.warning-icon-color {
    color: #ffc107;
}

.error-icon-color {
    color: #f44336;
}
</style>
