var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "address-box",
        class: !_vm.disableGpsCoordinates ? "address-has-coordinates" : "",
      },
      [
        _c(
          "form-group",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isCoordinates,
                expression: "!isCoordinates",
              },
            ],
            attrs: { name: _vm.name, label: _vm.label },
          },
          [
            _vm.icon ? _c("md-icon", [_vm._v(_vm._s(_vm.icon))]) : _vm._e(),
            _c("md-input", {
              ref: "autocomplete",
              class: _vm.classname,
              attrs: {
                type: "text",
                id: _vm.id,
                "stop-id": _vm.stopId,
                placeholder: _vm.placeholder,
                disabled: _vm.disabled,
                title: _vm.autocompleteText,
              },
              on: {
                focus: function ($event) {
                  return _vm.onFocus($event)
                },
                blur: function ($event) {
                  return _vm.onBlur()
                },
                change: _vm.onChange,
                keypress: _vm.onKeyPress,
                keyup: _vm.onKeyUp,
              },
              model: {
                value: _vm.autocompleteText,
                callback: function ($$v) {
                  _vm.autocompleteText = $$v
                },
                expression: "autocompleteText",
              },
            }),
          ],
          1
        ),
        _vm.isCoordinates
          ? _c(
              "div",
              { staticClass: "coordinate-address" },
              [
                _c(
                  "form-group",
                  { attrs: { name: "stopName", label: "Address Name" } },
                  [
                    _c("md-input", {
                      attrs: { placeholder: _vm.gpsCoordinatePlaceholder },
                      model: {
                        value: _vm.value.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "name", $$v)
                        },
                        expression: "value.name",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    !_vm.disableGpsCoordinates
      ? _c(
          "div",
          { staticClass: "coordinate-toggle-button" },
          [
            _c(
              "md-button",
              {
                staticClass: "md-primary md-just-icon md-round",
                class: _vm.isCoordinates ? "has-coordinates" : "",
                on: { click: _vm.toggleCoordinates },
              },
              [
                _vm.isCoordinates
                  ? _c("md-icon", [_vm._v("location_on")])
                  : _c("md-icon", [_vm._v("home")]),
                _vm.isCoordinates
                  ? _c("md-tooltip", { attrs: { "md-direction": "right" } }, [
                      _vm._v("Use Address"),
                    ])
                  : _c("md-tooltip", { attrs: { "md-direction": "right" } }, [
                      _vm._v("Use Coordinates"),
                    ]),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    !_vm.disableGpsCoordinates
      ? _c("div", { staticClass: "coordinates-container" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isCoordinates,
                  expression: "isCoordinates",
                },
              ],
              staticClass: "coordinates",
            },
            [
              _c(
                "form-group",
                { attrs: { name: _vm.name, label: "Coordinates" } },
                [
                  _c("md-input", {
                    attrs: { placeholder: "Paste GPS coordinates" },
                    model: {
                      value: _vm.coordinateString,
                      callback: function ($$v) {
                        _vm.coordinateString = $$v
                      },
                      expression: "coordinateString",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }