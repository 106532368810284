<script>
import { ref, inject, computed, watch, onMounted, onBeforeUnmount } from '@vue/composition-api';
import useMapUtils from '@/compostables/useMapUtils';

export default {
    name: 'StopStatusHistory',
    props: {
        driver: {
            type: Object,
            default: () => {}
        },
        stopDetails: {
            type: Object,
            default: () => {}
        },
        stopStatusDetails: {
            type: Object,
            default: () => {}
        }
    },
    setup(props, { root }) {
        const { getters } = inject('vuex-store');
        const map = inject('map');
        const google = inject('google');
        const bounds = inject('bounds');
        const { setMarkers } = useMapUtils(root);

        const pinnedUser = computed(() => getters['map/pinnedUser']);
        const isStopStatusToggled = computed(() => getters['map/isStopStatusToggled']);
        const marker = ref(null);

        const {
            driver: { publicUserId, markerPins },
            stopDetails: { address, stopRef, stopId },
            stopStatusDetails: { oldStatus, newStatus, actionDate, location, utcOffset }
        } = props;

        onMounted(() => {
            const { latitude, longitude } = location;
            marker.value = setMarkers(google.value, map.value, latitude, longitude, 'statusHistory', markerPins);

            if (!pinnedUser.value || pinnedUser.value !== publicUserId) 
                marker.value.setMap(null);
            if (!isStopStatusToggled.value) 
                marker.value.setMap(null);
            const data = { address, stopRef, oldStatus, newStatus, actionDate, stopId, location, utcOffset };
            marker.value.addListener('click', () => {
                root.$emit('marker-clicked', { data, marker: marker.value, type: 'statusHistory' });
            });
        });

        onBeforeUnmount(() => {
            marker.value.setMap(null);
            marker.value = null;
        });

        watch(pinnedUser, (newVal) => {
            // unpinning a user
            if (!newVal) 
                marker.value.setMap(null);

            if (newVal === publicUserId) {
                marker.value.setMap(map.value);
                bounds.value.extend(marker.value.getPosition());
            } else 
                marker.value.setMap(null);
        });

        watch(isStopStatusToggled, (newVal) => {
            marker.value.setMap(newVal ? map.value : null);
        });

        return {
            marker
        };
    },
    render: () => null
};
</script>
