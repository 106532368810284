var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "map-overlay" }, [
    _c(
      "div",
      { staticClass: "custom-toolbar" },
      [
        _c(
          "router-link",
          {
            attrs: {
              to: {
                name: "Team Settings",
              },
            },
          },
          [_c("h3", { staticClass: "title" }, [_vm._v("Team Settings")])]
        ),
        _c("h3", { staticClass: "title" }, [
          _vm._v("   >    " + _vm._s(_vm.$t("menus.setting.rateZones"))),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "rate-zone-area-main" }, [
      _c("div", {
        ref: "googleMap",
        class: [
          _vm.isTrialExpired ? "not-paid-map-overlay" : "",
          _vm.longerPanelWidth ? "shorter-google-map" : "google-map",
        ],
        attrs: { id: "map_canvas" },
      }),
      _c(
        "div",
        {
          class: _vm.longerPanelWidth
            ? "longer-service-area-panel"
            : "rate-zone-area-panel",
        },
        [
          _c("AreasLayer", {
            attrs: {
              map: _vm.map,
              "drawing-manager": _vm.drawingManager,
              title: "Rate Zones",
              "id-name": "rateZoneId",
              "end-point-name": "/api/teams/rate-zones",
            },
            on: { selectFromList: _vm.handleScreenWidth },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }