<template>
    <div class="field">
        <md-input v-model="selectedOption" type="hidden" />
        <vue-select
            :reduce="(r) => r"
            label="type"
            :options="optionList"
            v-model="selectedOption"
            :searchable="$root.isDesktop"
        ></vue-select>
    </div>
</template>
<script>
import { handleRequests, showErrorMessage } from '@/helpers';

export default {
    name: 'TaxSettingsOptions',
    props: {
        selected: {
            type: Number,
            default: null
        },
        isUpdate: { type: Boolean, default: false },
        enabled: { type: Boolean, default: true }
    },
    data() {
        return {
            optionList: [],
            isListLoading: Boolean,
            selectedOption: null
        };
    },
    mounted() {
        this.getTaxSettings();
    },
    watch: {
        selected: {
            immediate: true,
            handler(newValue) {
                if (newValue && this.optionList) {
                    this.selectedOption = this.optionList.find((o) => o.taxSettingsId === this.selected);
                }
            }
        },
        selectedOption(newValue, oldValue) {
            this.optionSelected(newValue);
        }
    },
    methods: {
        async getTaxSettings() {
            try {
                this.isListLoading = true;
                const endpoint = `/api/team-settings/tax-settings`;

                const response = await handleRequests(endpoint);
                this.optionList = response.data;
                if (this.isUpdate && this.selected && this.selected > 0) {
                    this.selectedOption = this.optionList.find((o) => o.taxSettingsId === this.selected);
                }

                if (!this.optionList || this.optionList.length === 0)
                    this.optionList.push({ description: 'empty', taxSettingsId: null, type: '' });
            } catch (error) {
                const message = 'Error in getting the tax settings list';
                showErrorMessage(this, message, error);
            }

            this.isListLoading = false;
        },
        optionSelected(value) {
            this.$emit('optionSelected', !value || value?.taxSettingsId === 0 ? null : value.taxSettingsId);
        }
    }
};
</script>
<style scoped>
.field {
    width: 100%;
}
</style>
