var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter-container" }, [
    _c(
      "div",
      { staticClass: "search-field" },
      [
        _c("customer-autocomplete", {
          attrs: {
            label: "Customer",
            id: "autocustomer",
            placeholder: "",
            "should-focus": false,
            "auto-fill-address": false,
          },
          model: {
            value: _vm.customerDetails,
            callback: function ($$v) {
              _vm.customerDetails = $$v
            },
            expression: "customerDetails",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "search-field" },
      [
        _c(
          "md-datepicker",
          {
            staticClass: "filter-steps--choices filter-date",
            attrs: { "md-immediately": "", "md-debounce": 100 },
            model: {
              value: _vm.tripDateFilterValue,
              callback: function ($$v) {
                _vm.tripDateFilterValue = $$v
              },
              expression: "tripDateFilterValue",
            },
          },
          [_c("label", [_vm._v("Trip date")])]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }