var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drag-drop-container" }, [
    _c("div", { staticClass: "bg-gray" }, [
      _c("input", {
        ref: "file",
        staticClass: "dragdrop-input",
        attrs: {
          type: "file",
          multiple: "",
          name: "fields[assetsFieldHandle][]",
          id: "assetsFieldHandle",
          accept: ".pdf,.xlsx,.xls,.txt,.ppt,.pptx,.docx,.doc,.mp4",
        },
        on: { change: _vm.onChange },
      }),
      _vm._m(0),
    ]),
    _c("div", { staticClass: "file-names" }, [
      this.filelist.length
        ? _c(
            "ul",
            { staticClass: "filename-list" },
            _vm._l(_vm.filelist, function (file, index) {
              return _c("li", { key: index, staticClass: "text-sm" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(file.name) +
                    "\n                "
                ),
                _c(
                  "button",
                  {
                    staticClass: "file-remove-btn",
                    attrs: { type: "button", title: "Remove file" },
                    on: {
                      click: function ($event) {
                        _vm.remove(_vm.filelist.indexOf(file))
                      },
                    },
                  },
                  [_vm._v("\n                    remove\n                ")]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "block cursor-pointer",
        attrs: { for: "assetsFieldHandle" },
      },
      [
        _c("div", [
          _c("span", { staticClass: "underline" }, [_vm._v("Click here")]),
          _vm._v("\n                to upload files\n            "),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }