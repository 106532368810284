<template>
    <drop-down
        direction="down"
        class="custom-dropdown"
        :should-close="shouldCloseDropdown"
        @closeDropdown="(val) => (this.shouldCloseDropdown = val)"
        @closeOtherDropDown="closeOtherDropDown"
    >
        <md-button
            slot="title"
            class="md-button md-success md-just-icon md-round"
            data-toggle="dropdown"
            title="Create new maintenance schedule"
        >
            <i class="material-icons">more_time</i>
        </md-button>
        <ul class="dropdown-menu custom-dropdown-menu" v-if="!isReadOnlyUser">
            <div class="schedule-list" v-if="this.asset.trackerId != null">
                <div v-if="maintenanceSchedules == null || maintenanceSchedules.length == 0" class="sched-container">
                    No generic maintenance schedule available. Go to
                    <router-link
                        :to="{
                            name: 'Maintenance Schedule Dashboard'
                        }"
                        target="_blank"
                    >
                        Maintenance Schedule Dashboard
                    </router-link>
                    to start creating one.
                </div>
                <li v-for="(option, index) in maintenanceSchedules" :key="index" v-else>
                    <a @click.stop="handleChangeOption(option)">
                        {{ option.maintenanceName }}

                        <md-tooltip v-if="option.maintenanceName.length > 26" md-direction="left">
                            {{ option.maintenanceName }}
                        </md-tooltip>
                    </a>
                </li>
            </div>
            <div class="schedule-list" v-else>
                <div v-if="noTrackerScheduleList == null || noTrackerScheduleList.length == 0" class="sched-container">
                    No generic maintenance schedule available
                    <router-link
                        :to="{
                            name: 'Maintenance Schedule Dashboard'
                        }"
                        target="_blank"
                    >
                        Maintenance Schedule Dashboard
                    </router-link>
                    to start creating one.
                </div>
                <li v-for="(option, index) in noTrackerScheduleList" :key="index" v-else>
                    <a @click.stop="handleChangeOption(option)">
                        {{ option.maintenanceName }}
                        <md-tooltip v-if="option.maintenanceName.length > 26">{{ option.maintenanceName }}</md-tooltip>
                    </a>
                </li>
            </div>

            <div class="custom-create-btn" @click.stop="handleNew">
                <i class="material-icons">more_time</i>
                Create New
            </div>
        </ul>
    </drop-down>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import {
    STATUS_CONSTANTS,
    UNASSIGNED_STATUS_CONSTANTS,
    STATUS_STOP_SHIPMENT_CONSTANTS,
    FUTURE_STATUS_STOP_SHIPMENT_CONSTANTS,
    PAST_STATUS_STOP_SHIPMENT_CONSTANTS,
    UNASSIGNED_STATUS_STOP_SHIPMENT_CONSTANTS,
    FUTURE_STATUS_CONSTANTS,
    DATE_TYPES,
    PAST_STATUS_CONSTANTS
} from '@/utils/constants';
import moment from 'moment';
import { mapGetters } from 'vuex';
import CreateMaintenanceScheduleWizard from '@/pages/Maintenance/modals/CreateMaintenanceScheduleWizard';
import AssignMaintenanceScheduleModal from '@/pages/Maintenance/modals/AssignMaintenanceScheduleModal';

export default {
    name: 'AssignMaintenanceScheduleButton',
    mixins: [GeneralMixin],
    props: {
        maintenanceSchedules: {
            type: Array,
            default: () => null
        },
        asset: {
            type: Object,
            default: () => null
        },
        useIcon: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            options: null,
            shouldCloseDropdown: false,
            selectedOption: { name: 'Create New', id: 0 },
            noTrackerScheduleList: null
        };
    },
    mounted() {
        if (this.asset.trackerId == null && this.maintenanceSchedules != null) {
            this.noTrackerScheduleList = this.maintenanceSchedules.filter(
                (x) =>
                    x.engineHoursReminderInterval == null &&
                    x.engineHoursReminderValue == null &&
                    x.odometerReminderInterval == null &&
                    x.odometerReminderValue == null
            );
        }
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            isIndividualUser: 'user/isIndividualUser'
        })
    },
    watch: {
        date(newValue, oldValue) {
            this.generateDropdownOptions(newValue);
        }
    },
    methods: {
        handleNew() {
            this.shouldCloseDropdown = true;
            this.$modal.show(CreateMaintenanceScheduleWizard, { asset: this.asset }).then((response) => {
                if (response) {
                    this.$modal.hide();
                }
            });
        },
        generateOptions(data) {
            return data.map((x) => ({
                name: x.maintenanceName,
                type: x.assetMaintenanceScheduleId
            }));
        },
        generateDropdownOptions(selectedDate) {
            const dayOfWeek = moment(selectedDate).format('dddd');
            const yearly = moment(selectedDate).format('MMMM D');
            const dayOfMonth = moment(selectedDate).format('Do');
            this.options = [];
            this.options.push({ name: 'Daily', type: 'daily', date: selectedDate });
            this.options.push({ name: `Weekly on ${dayOfWeek}`, type: 'weekly', date: selectedDate });
            this.options.push({ name: `Monthly on every ${dayOfMonth}`, type: 'monthly', date: selectedDate });
            this.options.push({ name: `Anually on ${yearly}`, type: 'yearly', date: selectedDate });
            this.options.push({ name: 'Custom', type: 'custom', date: selectedDate });
        },
        updateStatus() {
            const today = moment().format(DATE_TYPES.internationalDate);
            const tripDate = moment(this.stop.tripDate);
            const shipmentId = this.stop && this.stop.shipmentId;

            this.statuses = shipmentId ? STATUS_STOP_SHIPMENT_CONSTANTS : STATUS_CONSTANTS;

            if (this.stop.assignedTo.publicUserId == null) {
                this.statuses = shipmentId ? UNASSIGNED_STATUS_STOP_SHIPMENT_CONSTANTS : UNASSIGNED_STATUS_CONSTANTS;
            } else {
                if (tripDate.isAfter(today)) {
                    this.statuses = shipmentId ? FUTURE_STATUS_STOP_SHIPMENT_CONSTANTS : FUTURE_STATUS_CONSTANTS;
                }

                if (tripDate.isBefore(today)) {
                    this.statuses = shipmentId ? PAST_STATUS_STOP_SHIPMENT_CONSTANTS : PAST_STATUS_CONSTANTS;
                }
            }
        },
        async handleChangeOption(optionData) {
            this.selectedOption = optionData;
            this.$modal
                .show(AssignMaintenanceScheduleModal, { maintenanceScheduleData: optionData, asset: this.asset })
                .then((response) => {
                    if (response) {
                        this.$emit('onAssignSchedule', response);
                        this.$modal.hide();
                    }
                });

            this.shouldCloseDropdown = true;
        },
        closeOtherDropDown() {
            const dropdowns = document.querySelectorAll('.open');
            dropdowns.forEach((dropdown) => dropdown.classList.remove('open'));
        }
    }
};
</script>

<style lang="scss" scoped>
.status {
    color: #aaa !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    padding: 0 10px;
    cursor: pointer;
}

::v-deep .dropdown-menu {
    li {
        cursor: pointer !important;
    }
}

.custom-dropdown {
    display: inline-block;
}

.custom-create-btn {
    font-size: 0.8125rem;
    padding: 10px 1.5rem;
    margin: 0 5px;
    text-transform: none;
    color: #333333 !important;
    border-radius: 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 400;
    line-height: 1.428571;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    -webkit-transition: all 150ms linear;
    transition: all 150ms linear;
    border-top: 1px solid #eee;

    .material-icons {
        margin-right: 5px;
    }
}

.custom-create-btn:hover {
    background-color: #4caf50 !important;
    color: #ffffff !important;
    cursor: pointer;
}

.schedule-list::-webkit-scrollbar {
    width: 0.5em;
}

.schedule-list::-webkit-scrollbar-track {
    box-shadow: #2b93ff;
}

.schedule-list::-webkit-scrollbar-thumb {
    background-color: #eee;
}

.schedule-list {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

.custom-dropdown-menu {
    right: 0;
    left: initial;
    max-width: 200px;
}

.sched-container {
    padding: 20px;
    a {
        color: #2b93ff;
    }
}
</style>
