<template>
    <div class="cf-container">
        <div class="cf-action-container">
            <md-button
                title="Add custom SMS template"
                class="md-primary md-just-icon md-round"
                @click.stop="addNewSMSTemplate()"
            >
                <md-icon>add</md-icon>
            </md-button>
        </div>

        <div>
            <form-group class="sms-settings-key" name="twilioSid" :label="accountLabel">
                <md-input v-model="smsSettings.accountId" />
            </form-group>
            <form-group class="sms-settings-key" name="twiliotoken" :label="tokenLabel">
                <md-input v-model="smsSettings.accountToken" />
            </form-group>
            <form-group class="sms-settings-key" name="sms-sent-from" label="Phone number to send sms from">
                <md-input v-model="smsSettings.outboundSmsPhoneNumber" />
            </form-group>
            <form-group class="sms-settings-key" name="sms-sent-from" label="Sender Id to send sms from">
                <md-input v-model="smsSettings.outboundSmsSenderId" />
            </form-group>
            <button class="save-sms-key-btn custom-btn" @click="update">
                Save
            </button>
            <button class="save-sms-key-btn custom-btn" @click="sendTestSMS" v-if="hasAllSettings">
                Send Test SMS
            </button>
        </div>

        <md-table class="custom-paginated-table">
            <md-table-row>
                <md-table-head>Event Trigger</md-table-head>
                <md-table-head>Template</md-table-head>
                <md-table-head>Stop Type</md-table-head>
                <md-table-head>Actions</md-table-head>
            </md-table-row>

            <md-table-row v-for="(smsTemplate, index) in smsTemplates" :key="index">
                <md-table-cell>
                    <div>{{ smsTemplate.eventTriggerText }}</div>
                    <div v-show="smsTemplate.name">{{ smsTemplate.name }}</div>
                </md-table-cell>
                <md-table-cell>
                    {{ smsTemplate.template }}
                </md-table-cell>
                <md-table-cell>
                    {{ smsTemplate.stopType }}
                </md-table-cell>
                <md-table-cell class="action-buttons">
                    <md-button
                        title="Edit SMS"
                        class="md-warning md-just-icon md-round"
                        @click="editSmsTemplate(smsTemplate)"
                    >
                        <md-icon>edit</md-icon>
                    </md-button>
                    <md-button
                        v-if="smsTemplate.isCustom > 0"
                        title="Delete SMS"
                        class="md-danger md-just-icon md-round"
                        @click="deleteSmsTemplate(smsTemplate.id)"
                    >
                        <md-icon>delete</md-icon>
                    </md-button>
                </md-table-cell>
            </md-table-row>
        </md-table>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import SmsTemplateModal from './SmsTemplateModal';
import SendTestSmsModal from './SendTestSmsModal';

export default {
    name: 'SmsSettings',
    mixins: [GeneralMixin],
    props: {
        level: {
            type: String,
            default: 'users'
        }
    },
    data() {
        return {
            smsSettings: {},
            smsTemplates: [],
            selectedSmsTemplate: {},
            hasAllSettings: false
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        }),
        accountLabel() {
            return this.user.countryCode === 'IN' ? 'Plivo Auth Id' : 'Twilio Sid';
        },
        tokenLabel() {
            return this.user.countryCode === 'IN' ? 'Plivo Auth Token' : 'Twilio Token';
        }
    },
    async mounted() {
        await this.getSmsProviderCredentials();
        await this.getSmsTemplates();
    },
    methods: {
        editSmsTemplate(smsTemplate) {
            this.selectedSmsTemplate = smsTemplate;
            this.$modal
                .show(SmsTemplateModal, { origSmsTemplate: this.selectedSmsTemplate, level: this.level })
                .then((response) => {
                    this.getSmsTemplates();
                });
        },
        toggleModal(shouldToggle, modalToToggle) {
            this[modalToToggle] = shouldToggle;
        },
        async update() {
            this.$_handleLoaderState(true, 'UPDATING...');
            const request = {
                accountId: this.smsSettings.accountId,
                accountToken: this.smsSettings.accountToken,
                outboundSmsPhoneNumber: this.smsSettings.outboundSmsPhoneNumber,
                outboundSmsSenderId: this.smsSettings.outboundSmsSenderId
            };
            const api = `/api/${this.level}/sms/settings`;
            const payload = {
                method: 'put',
                data: request
            };

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Settings updated successfully!',
                    type: 'success'
                });
                await this.getSmsProviderCredentials();
                await this.getSmsTemplates();
                this.$_handleLoaderState(false);
            } catch (e) {
                const message = 'Error in updating settings';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }

            this.$_handleLoaderState(false);
        },
        async getSmsProviderCredentials() {
            this.$_handleLoaderState(true);

            const api = `/api/${this.level}/sms/settings`;
            const response = await handleRequests(api);
            if (response.data !== null) {
                this.smsSettings = response.data;
            }
            this.hasAllSettings = this.checkHasAllSettings();
            this.$_handleLoaderState(false);
        },
        async getSmsTemplates() {
            this.$_handleLoaderState(true);
            const api = `/api/${this.level}/sms/templates`;
            const response = await handleRequests(api);
            if (response.data !== null) {
                this.smsTemplates = response.data;
            }

            this.$_handleLoaderState(false);
        },
        deleteSmsTemplate(smsTemplateId) {
            this.$messageBox
                .show({
                    title: 'Delete Custom SMS Template',
                    body: 'Are you sure you want to delete this custom SMS template?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmDelete(smsTemplateId);
                });
        },
        async handleConfirmDelete(smsTemplateId) {
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'delete'
            };
            const api = `/api/${this.level}/sms/template/${smsTemplateId}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Custom SMS template was deleted!',
                    type: 'success'
                });
                this.getSmsTemplates();
            } catch (e) {
                const message = 'Cannot delete custom SMS template.';
                showErrorMessage(this, message, e);
            }
        },
        sendTestSMS() {
            this.$modal.show(SendTestSmsModal, { api: `/api/${this.level}/sms/test` });
        },
        checkHasAllSettings() {
            return (
                this.smsSettings.accountId !== null &&
                this.smsSettings.accountId.length > 0 &&
                (this.smsSettings.accountToken !== null && this.smsSettings.accountToken.length > 0) &&
                ((this.smsSettings.outboundSmsPhoneNumber !== null &&
                    this.smsSettings.outboundSmsPhoneNumber.length > 0) ||
                    (this.smsSettings.outboundSmsSenderId !== null && this.smsSettings.outboundSmsSenderId.length > 0))
            );
        },
        addNewSMSTemplate() {
            if (this.checkHasAllSettings()) {
                this.$modal.show(SmsTemplateModal, { origSmsTemplate: {}, level: this.level }).then((response) => {
                    this.getSmsTemplates();
                });
            } else {
                this.$messageBox.show({
                    title: 'SMS Template',
                    body: 'Please provide your sms settings first before adding a new sms template.',
                    buttons: ['OK']
                });
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.sms-settings-key {
    margin-top: 20px;
    margin-right: 10px;
    width: 20%;
    display: inline-block;

    input {
        width: 100%;
    }
}
.save-sms-key-btn {
    display: inline-block;
    margin-right: 5px;
    width: 8% !important;
}
</style>
