var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-steps--container" },
    [
      _vm.canViewTeamRegion || _vm.isCustomerAdmin
        ? _c("team-region-member-filter-options", {
            attrs: {
              "team-region-id": _vm.teamRegionId,
              "strict-team-region-filter": false,
              clearable: false,
              "show-carriers": true,
              "show-team-member": _vm.showTeamMembers,
            },
            on: { changed: _vm.handleTeamRegionMemberChanged },
          })
        : _vm._e(),
      _vm.hasCustomerFilterAccess
        ? _c(
            "md-field",
            [
              _c("customer-filter-options", {
                staticClass: "filter-steps--choices filter-status",
                on: { onCustomerSelected: _vm.handleCustomerChanged },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showStatus
        ? _c(
            "md-field",
            [
              _vm.statuses
                ? _c("vue-select", {
                    attrs: { options: _vm.statuses, placeholder: "Status" },
                    model: {
                      value: _vm.statusFilterValue,
                      callback: function ($$v) {
                        _vm.statusFilterValue = $$v
                      },
                      expression: "statusFilterValue",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.showQuotingStatus
        ? _c(
            "md-field",
            [
              _c("QuotingStatusFilter", {
                on: { changed: _vm.handleQuotingStatusChanged },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "md-field",
        [
          _c("md-datepicker", {
            staticClass: "filter-steps--choices filter-step-date start-date",
            attrs: { "md-immediately": "", "md-debounce": 100 },
            model: {
              value: _vm.fromDate,
              callback: function ($$v) {
                _vm.fromDate = $$v
              },
              expression: "fromDate",
            },
          }),
        ],
        1
      ),
      _c(
        "md-field",
        [
          _c("md-datepicker", {
            staticClass: "filter-steps--choices filter-step-date end-date",
            attrs: { "md-immediately": "", "md-debounce": 100 },
            model: {
              value: _vm.toDate,
              callback: function ($$v) {
                _vm.toDate = $$v
              },
              expression: "toDate",
            },
          }),
        ],
        1
      ),
      _c(
        "md-checkbox",
        {
          attrs: { type: "checkbox" },
          on: { change: _vm.handleShowBlankShipmentDateFirst },
          model: {
            value: _vm.showBlankShipmentDateFirst,
            callback: function ($$v) {
              _vm.showBlankShipmentDateFirst = $$v
            },
            expression: "showBlankShipmentDateFirst",
          },
        },
        [_vm._v("\n        Show shipments with no date first\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }