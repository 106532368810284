var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "alert trial-alert-expiry alert-dismissible fade show",
        class: _vm.trialAlertColor,
        attrs: { role: "alert" },
      },
      [
        _vm._v("\n        " + _vm._s(_vm.alertMessage) + "\n        "),
        _vm.user.role === "Team Owner" || _vm.user.role === null
          ? _c(
              "a",
              {
                staticClass: "trial-upgrade-btn",
                attrs: { target: "_blank" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.redirectUser($event)
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("pages.dashboardLayout.upgradeNow")) +
                    "\n        "
                ),
              ]
            )
          : _c("span", [
              _vm._v(_vm._s(_vm.$t("pages.dashboardLayout.contactTeamOwner"))),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }