<template>
    <th
        v-if="dropdownOptions != null && dropdownOptions.length > 0"
        class="md-table-head running-load-head-table-header"
    >
        <div class="md-table-head-container running-load-menu">
            <drop-down direction="down">
                <a href="#" class="load-header-text">
                    {{ defaultText }}
                    <md-icon class="icon-arrow" v-if="selectedData != null && selectedData.length > 0">
                        filter_alt
                    </md-icon>
                    <md-icon class="icon-arrow" v-else>filter_alt_off</md-icon>
                </a>
                <div class="header-note" v-if="selectedData != null && selectedData.length > 0">
                    <span v-if="selectedData != null && selectedData.length == 1">
                        {{ selectedData[0].selectedLabel }}
                    </span>
                    <span v-else>{{ selectedData.length }} options selected</span>
                </div>
                <ul class="dropdown-menu">
                    <li
                        v-for="(option, index) in dropdownOptions"
                        :key="index"
                        @click.stop="handleSelectedOption(option)"
                    >
                        <md-icon
                            :class="[
                                'icon-check',
                                selectedData.filter((e) => e.key === option.key).length > 0 ? 'icon-check-color' : ''
                            ]"
                        >
                            check
                        </md-icon>
                        <a href="#">
                            {{ option.name }}
                        </a>
                    </li>
                </ul>
            </drop-down>
        </div>
    </th>
    <div v-else class="card-icon">
        <md-icon v-if="defaultIcon != null">{{ defaultIcon }}</md-icon>
        {{ defaultText }}
    </div>
</template>

<script>
export default {
    name: 'TableHeaderDropdownMultiple',
    props: {
        dropdownOptions: {
            type: Array,
            default: null
        },
        defaultIcon: {
            type: String,
            default: null
        },
        defaultText: {
            type: String,
            default: null
        },
        selectedOptions: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            selectedData: []
        };
    },
    mounted() {
        this.selectedData = [];
        if (
            this.selectedOptions != null &&
            this.selectedOptions.length > 0 &&
            this.dropdownOptions != null &&
            this.dropdownOptions.length > 0
        ) {
            this.selectedOptions.forEach((option) => {
                const existingOption = this.dropdownOptions.filter((x) => x.key === option.key);
                if (existingOption.length > 0) {
                    this.selectedData.push(existingOption[0]);
                }
            });
        }
    },
    methods: {
        handleSelectedOption(value) {
            if (this.selectedData.filter((x) => x.key === value.key).length > 0) {
                this.removeOption(value);
            } else {
                this.selectedData.push(value);
            }

            this.$emit('selectedOptions', this.selectedData);
        },
        removeOption(value) {
            const index = this.selectedData.findIndex((x) => x.key === value.key);
            if (index > -1) {
                this.selectedData.splice(index, 1);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.running-load-head-table-header {
    min-width: 110px;
    text-align: right;

    ::v-deep .md-table-head-label {
        float: left;
        padding-right: 5px;
    }

    .load-header-text,
    .load-header-text:hover {
        font-size: 1.0625rem;
        padding-left: 0;
        padding-right: 10px;
        font-weight: 300;
        color: #52b255 !important;
        text-transform: capitalize;
    }
    .icon-arrow {
        font-size: 18px !important;
        margin-top: -4px !important;
    }

    .icon-check {
        color: #fff !important;
        font-size: 16px !important;
        float: left;
    }

    .icon-check-color {
        color: #333333 !important;
    }

    .running-load-menu {
        ::v-deep .dropdown {
            position: absolute;
            top: 6px;
        }

        .dropdown-menu {
            margin-left: -90px;
            margin-top: 6px;

            li {
                border-bottom: 0px;
                padding: 10px;
            }

            hr.border {
                border-top: 1px solid #ddd;
            }

            a {
                color: #333333 !important;
                padding: 0px;
                margin: 0px;
                font-size: 12px;
                font-weight: 400;
                box-shadow: none;
            }

            a:hover {
                background-color: transparent !important;
                box-shadow: none;
                color: #2b93ff !important;
            }
        }

        .dropdown-menu li a:hover,
        .dropdown-menu li a:focus,
        .dropdown-menu li a:active {
            background-color: initial !important;
        }
    }
}

.header-note {
    text-align: left;
    font-size: 10px;
}
</style>
