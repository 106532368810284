<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-warning">
            <div class="card-icon">
                <md-icon>history</md-icon>
            </div>
        </md-card-header>

        <md-card-content>
            <time-line plain type="simple" v-if="list.length > 0">
                <time-line-item
                    inverted
                    :badge-type="`status-${history.newStatus.toLowerCase().replace(/\s+/g, '')}`"
                    badge-icon="place"
                    v-for="history in list"
                    :key="history.actionDate"
                >
                    <span
                        slot="header"
                        class="custom-badge"
                        :class="`status-${history.newStatus.toLowerCase().replace(/\s+/g, '')}`"
                    >
                        {{ history.newStatus }}
                    </span>

                    <span slot="headerTime" class="time">
                        {{ history.actionDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                    </span>

                    <h6 slot="footer">
                        <i class="ti-time" />

                        By {{ history.changedByUserFullName || history.changedByPublicUserId }}
                    </h6>
                </time-line-item>
            </time-line>
            <p v-else class="grayedout-text">
                No Status History Available
            </p>
        </md-card-content>
    </md-card>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import TimeLine from './TimeLine';
import TimeLineItem from './TimeLineItem';

export default {
    name: 'StatusHistoryTimeline',
    mixins: [GeneralMixin],
    components: {
        TimeLine,
        TimeLineItem
    },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .timeline.timeline-simple {
    margin-top: 0;
}

::v-deep .md-card-timeline {
    margin-top: 0;
    margin-bottom: 0;
}

::v-deep .timeline > li > .timeline-panel {
    padding: 5px 20px;
    background-color: #fafafa;
}

::v-deep .timeline-panel > h6 > h6 {
    text-transform: none;
    margin-top: 0;
}

::v-deep .timeline-panel > h6 {
    margin-top: 0px;
}

::v-deep .timeline-heading {
    margin-bottom: 5px;
}

::v-deep .timeline > li {
    margin-bottom: 5px;
}

::v-deep .timeline > li > .timeline-panel:after,
::v-deep .timeline > li > .timeline-panel:before {
    border-left-color: #fafafa;
    border-right-color: #fafafa;
}

::v-deep .timeline.timeline-simple > li > .timeline-panel {
    width: 83%;
}

::v-deep .timeline-heading .time {
    margin-left: 10px;
    color: #333333;
    font-weight: 400;
    font-size: 0.75rem;
}
::v-deep .md-card-timeline {
    overflow: auto;
    max-height: 321px;
}

.timeline-active-scrollbar {
    ::v-deep .timeline.timeline-simple {
        margin: 0 10px 0 10px;
    }
}
</style>
