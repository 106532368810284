<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start"></div>
                    <div class="custom-toolbar-end">
                        <search-component
                            v-if="$root.isDesktop"
                            :search-filter="currentSearchTextValue"
                            :handle-search-on-type="true"
                            @onSearch="handleFilterOrSearch"
                        />
                    </div>
                </div>
                <md-card class="custom-margin-bottom">
                    <md-card-header class="md-card-header-icon md-card-header-warning">
                        <div class="card-icon">
                            <md-icon>receipt_long</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div v-if="!isListLoading">
                            <md-table class="context-menu-support custom-paginated-table">
                                <md-table-row>
                                    <TableHeaderDropdown
                                        column-icon="keyboard_arrow_down"
                                        default-text="Order #"
                                        :dropdown-options="columnDropdownOptions"
                                        :selected-option="selectedColumn"
                                        @selectedOption="handleSelectedColumn"
                                    />
                                    <md-table-head>Order Date</md-table-head>
                                    <md-table-head>Items</md-table-head>
                                    <md-table-head>Shipping Address</md-table-head>
                                    <md-table-head>Customer Name</md-table-head>
                                    <md-table-head>Payment Status</md-table-head>
                                    <md-table-head>Fulfillment Status</md-table-head>
                                    <md-table-head v-if="$root.isDesktop && !isReadOnlyUser">Actions</md-table-head>
                                </md-table-row>
                                <md-table-row v-for="order in orderList" :key="order.itemId">
                                    <md-table-cell>
                                        <router-link
                                            v-if="selectedColumn.key == 'order-number'"
                                            class="ref-link"
                                            :to="{ name: 'Order Details', params: { orderId: order.orderId } }"
                                            target="_blank"
                                        >
                                            {{ order.orderNumber }}
                                        </router-link>
                                        <router-link
                                            v-else
                                            class="ref-link"
                                            :to="{ name: 'Order Details', params: { orderId: order.orderId } }"
                                            target="_blank"
                                        >
                                            {{
                                                order.sourceReference != null
                                                    ? order.sourceReference
                                                    : order.orderNumber
                                            }}
                                        </router-link>
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ order.orderDate | dateFormat(DATE_TYPES.standardDate) }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ order.itemCount }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ order.shippingAddress != null ? order.shippingAddress.city : '' }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ order.contact.name }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        <OrderPaymentStatusButton :order="order" />
                                    </md-table-cell>
                                    <md-table-cell class="status-container">
                                        <span class="custom-badge">
                                            {{ order.status }}
                                        </span>
                                    </md-table-cell>

                                    <md-table-cell v-if="$root.isDesktop && !isReadOnlyUser" class="action-buttons">
                                        <md-button
                                            v-if="!isReadOnlyUser"
                                            title="Create a delivery schedule"
                                            :class="[
                                                'md-info md-just-icon md-round',
                                                isDisabled ? 'button-disabled' : ''
                                            ]"
                                        >
                                            <md-icon>departure_board</md-icon>
                                        </md-button>
                                        <md-button
                                            v-if="!isReadOnlyUser"
                                            title="Update order"
                                            :class="[
                                                'md-warning md-just-icon md-round',
                                                isDisabled ? 'button-disabled' : ''
                                            ]"
                                        >
                                            <md-icon>edit</md-icon>
                                        </md-button>
                                        <!-- <md-button
                                            v-if="!isReadOnlyUser"
                                            title="Delete order"
                                            :class="[
                                                'md-danger md-just-icon md-round',
                                                isDisabled ? 'button-disabled' : ''
                                            ]"
                                        >
                                            <md-icon>delete</md-icon>
                                        </md-button> -->
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <div v-if="orderList.length == 0">
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
                <md-card-actions class="page-footer" md-alignment="space-between">
                    <div>
                        <p v-if="total === pagination.perPage" class="card-category">
                            Page {{ pagination.currentPage }} of many
                        </p>
                        <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                    </div>
                    <pagination
                        v-model="pagination.currentPage"
                        class="pagination-no-border pagination-success"
                        :per-page="pagination.perPage"
                        :total="total"
                        @change-page="handleChangePage($event, pagination.perPage)"
                    />
                </md-card-actions>
            </div>
        </div>
    </div>
</template>

<script>
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { mapGetters } from 'vuex';
import { handleRequests } from '@/helpers';
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { Pagination, TableHeaderDropdown, SearchComponent } from '@/components';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import _ from 'lodash';
import OrderPaymentStatusButton from './Buttons/OrderPaymentStatusButton';

export default {
    name: 'OrdersOverview',
    mixins: [GeneralMixin],
    components: {
        FadeLoader,
        Pagination,
        TableHeaderDropdown,
        OrderPaymentStatusButton,
        SearchComponent
    },
    data() {
        return {
            orderList: [],
            isListLoading: false,
            pagination: PAGINATION_DEFAULTS,
            columnDropdownOptions: [
                { key: 'order-number', name: 'Order #' },
                { key: 'source-reference', name: 'Source Reference' }
            ],
            selectedColumn: { key: 'order-number', name: 'Order #' },
            isDisabled: false,
            currentSearchTextValue: ''
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser'
        }),
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        }
    },
    async mounted() {
        await this.fetchOrdersList();
    },
    methods: {
        async fetchOrdersList(pageNumber = 1, itemsPerPage = 10) {
            this.isListLoading = true;
            const endpoint = `/api/orders/list`;
            const response = await handleRequests(endpoint, {
                params: {
                    pageNumber,
                    itemsPerPage,
                    searchText: this.currentSearchTextValue
                }
            });

            this.orderList = response.data.orders;
            this.pagination.total = response.data.totalOrders;
            this.pagination.perPage = itemsPerPage;
            this.isListLoading = false;
        },
        handleSelectedColumn(item) {
            this.selectedColumn = item;
        },
        async handleChangePage(currentPage = 1, perPage = 10) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            this.fetchOrdersList(currentPage, perPage);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
        },
        // eslint-disable-next-line func-names
        handleFilterOrSearch: _.debounce(function(val) {
            this.currentSearchTextValue = val.searchText;
            this.fetchOrdersList();
        }, 1000)
    }
};
</script>

<style lang="scss" scoped>
.loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

.ref-link {
    color: rgba(0, 0, 0, 0.87) !important;
}

.ref-link:hover {
    color: #2b93ff !important;
}

::v-deep .dropdown-menu {
    margin-left: 0 !important;
}

.custom-margin-bottom {
    margin-bottom: 0;
}

.custom-badge {
    background-color: #c2907f;
    max-width: initial !important;
}
</style>
