<template>
    <div class="modal-container-rule">
        <div class="modal-header">
            <h4 class="modal-title">Rule Condition</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <br />

        <form-wrapper class="form-wrapper" :validator="$v.condition">
            <div class="md-layout-item">
                <div v-if="condition.length" class="condition-wrapper operator-wrapper">
                    <form-group name="selectedOperator" label="And/Or" attribute="And/Or">
                        <md-input v-model="condition.selectedOperator" type="hidden" required />
                        <vue-select :options="operators" label="key" v-model="condition.selectedOperator"></vue-select>
                    </form-group>
                </div>

                <!--pickup address, weight, items, volume, etc....-->
                <form-group name="selectedVariable" label="Variable" class="lg-condition-wrapper" attribute="Variable">
                    <md-input v-model="condition.selectedVariable" type="hidden" required @input="variableChanged" />
                    <vue-select
                        :options="conditionVariables"
                        label="key"
                        v-model="condition.selectedVariable"
                    ></vue-select>
                </form-group>

                <!--In, Not In OR >= <= Between-->
                <form-group
                    v-if="
                        condition.selectedVariable !== undefined &&
                            condition.selectedVariable !== null &&
                            (condition.selectedVariable.key !== 'Pickup Address' &&
                                condition.selectedVariable.key !== 'Drop Address')
                    "
                    name="selectedOption"
                    label="Condition"
                    class="lge-condition-wrapper"
                    attribute="Condition"
                >
                    <md-input v-model="condition.selectedOption" type="hidden" required />
                    <vue-select :options="conditionTypes" label="key" v-model="condition.selectedOption"></vue-select>
                </form-group>
                <form-group
                    v-else
                    name="selectedOption"
                    label="Condition"
                    class="lge-condition-wrapper-zone"
                    attribute="Condition"
                >
                    <md-input v-model="condition.selectedOption" type="hidden" required />
                    <vue-select
                        :options="addressZoneConditionTypes"
                        label="key"
                        v-model="condition.selectedOption"
                    ></vue-select>
                </form-group>

                <!--Value-->
                <form-group
                    v-if="
                        condition.selectedVariable !== undefined &&
                            condition.selectedVariable !== null &&
                            (condition.selectedVariable.key === 'Pickup Address' ||
                                condition.selectedVariable.key === 'Drop Address')
                    "
                    name="zoneValue"
                    label="Zone"
                    class="condition-data-zone"
                    attribute="Zone"
                >
                    <md-input v-model.number="condition.value" type="hidden" required />
                    <multiselect
                        v-model="condition.zoneValue"
                        label="name"
                        track-by="name"
                        placeholder="Search zone"
                        open-direction="right"
                        :options="rateZones"
                        :searchable="true"
                        :internal-search="false"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :limit="1"
                        :limit-text="limitText"
                        :max-height="200"
                        :show-no-results="false"
                        :reduce="(zone) => zone.rateZoneId"
                    >
                        <template slot="tag" slot-scope="{ option }">
                            <span class="zone_names">
                                <span>{{ option.name }}</span>
                            </span>
                        </template>
                        <template slot="clear" slot-scope="props">
                            <div
                                class="zone_names"
                                v-if="rateZones.length"
                                @mousedown.prevent.stop="clearAll(props.search)"
                            ></div>
                        </template>
                        <span slot="noResult">Oops! No zones created.</span>
                    </multiselect>
                </form-group>
                <!--Value zone selection-->
                <form-group v-else name="value" label="Value" class="condition-data" attribute="Value">
                    <md-input v-model.number="condition.value" required />
                </form-group>

                <form-group
                    v-if="
                        condition.selectedOption !== undefined &&
                            condition.selectedOption !== null &&
                            condition.selectedOption.key == 'Between'
                    "
                    name="valueTwo"
                    label="Value"
                    class="condition-data"
                    attribute="Number"
                >
                    <md-input v-model.number="condition.valueTwo" required />
                </form-group>

                <md-button class="md-success md-just-icon md-round condition-add" @click="addToCondition()">
                    <md-icon>add</md-icon>
                </md-button>
            </div>
        </form-wrapper>
        <div v-if="condition.length" class="rule-container">
            <md-chips class="md-primary" md-static :validator="$v.condition">
                <template v-for="(oper, index) in condition">
                    <md-chip :key="index">{{ formatCondition(oper) }}</md-chip>
                    <md-button
                        :key="index + '-del'"
                        title="Delete Condition"
                        class="md-danger md-just-icon md-round delete-rule"
                        @click.stop="deleteCondition(oper)"
                    >
                        <md-icon>delete</md-icon>
                    </md-button>
                </template>
            </md-chips>
        </div>

        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="create()">Save</md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';
import { RATE_CONDITION_TYPES, RATE_VARIABLES, RATE_ZONE_CONDITION_TYPES } from '@/utils/constants';
import { decimal, required, requiredIf } from 'vuelidate/lib/validators';

export default {
    name: 'CreateConditionRuleContent',
    components: {},
    mixins: [GeneralMixin],
    props: {
        rateDetail: {
            type: Object,
            default: () => {}
        },
        conditionDetail: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        rateRuleId: {
            type: Number,
            default: null
        },
        rateZones: {
            type: Array,
            default: () => []
        }
    },
    watch: {},
    data() {
        return {
            operators: [{ key: 'And', type: '&&' }, { key: 'Or', type: '||' }],
            conditionVariables: RATE_VARIABLES,
            conditionTypes: RATE_CONDITION_TYPES,
            addressZoneConditionTypes: RATE_ZONE_CONDITION_TYPES,
            selectedOption: null,
            selectedVariable: null,
            selectedOperator: null,
            value: null,
            condition: this.rateDetail.condition,
            isListLoading: true,
            localMessages: {
                required: '{attribute} is required.'
            },
            previousVariableSelected: null,
            zoneValue: null
        };
    },
    validations: {
        condition: {
            selectedVariable: {
                required
            },
            selectedOperator: {
                // eslint-disable-next-line func-names
                required: requiredIf(function() {
                    return this.condition.length >= 1;
                })
            },
            selectedOption: {
                required
            },
            value: {
                // eslint-disable-next-line func-names
                required: requiredIf(function() {
                    return this.condition.selectedOption?.key !== 'PickupAddress';
                }),
                decimal
            },
            valueTwo: {
                // eslint-disable-next-line func-names
                required: requiredIf(function() {
                    return this.condition.selectedOption?.key === 'Between';
                }),
                decimal
            },
            zoneValue: {
                // eslint-disable-next-line func-names
                required: requiredIf(function() {
                    return (
                        this.condition.selectedOption?.key === 'PickupAddress' ||
                        this.condition.selectedOption?.key === 'DropAddress'
                    );
                })
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    methods: {
        variableChanged(val) {
            if (val !== null) {
                if (val.type === 'PickupAddress' || val.type === 'DropAddress') {
                    if (this.condition.selectedOption !== null && this.condition.selectedOption !== undefined) {
                        const currentCondition = this.addressZoneConditionTypes.find(
                            (x) => x.type === this.condition.selectedOption.type
                        );

                        if (currentCondition === undefined) {
                            this.condition.selectedOption = null;
                        }
                    }

                    if (this.condition.value) {
                        const zone = this.rateZones.find((x) => x.name === this.condition.value);
                        if (zone === undefined) {
                            this.condition.value = null;
                        }
                    }
                } else {
                    if (this.condition.selectedOption !== null && this.condition.selectedOption !== undefined) {
                        const currentCondition = this.conditionTypes.find(
                            (x) => x.type === this.condition.selectedOption.type
                        );

                        if (currentCondition === undefined) {
                            this.condition.selectedOption = null;
                        }
                    }

                    if (this.condition.value) {
                        if (
                            this.previousVariableSelected === 'PickupAddress' ||
                            this.previousVariableSelected === 'DropAddress'
                        ) {
                            this.condition.value = null;
                        }
                    }
                }

                this.previousVariableSelected = val.type;
            }
        },
        formatCondition(conditionRow) {
            let conditionRowCopy = conditionRow;
            const numberPattern = /\d+/g;

            if (conditionRowCopy.startsWith('(')) {
                const variableName = conditionRowCopy.match(/\br\.(\w+)\b/)[1];
                const formatted = `${variableName} Between ${conditionRowCopy.match(/\d+/g)[0]} and ${
                    conditionRowCopy.match(/\d+/g)[1]
                }`;
                conditionRowCopy = formatted;
            }

            if (conditionRowCopy.includes('In') || conditionRowCopy.includes('NotIn')) {
                const match = conditionRowCopy.match(numberPattern);

                match.forEach((zoneId, index) => {
                    const zone = this.rateZones.find((x) => x.rateZoneId === Number(zoneId));
                    conditionRowCopy = conditionRowCopy.replaceAll(zoneId, zone.name);
                });
            }

            conditionRowCopy = conditionRowCopy.replaceAll('r.', '');
            conditionRowCopy = conditionRowCopy.replace('&&', 'And');
            conditionRowCopy = conditionRowCopy.replace('||', 'Or');
            conditionRowCopy = conditionRowCopy.replace('==', '=');
            conditionRowCopy = conditionRowCopy.replaceAll('.', ' ');

            return conditionRowCopy;
        },
        async create() {
            const obj = {
                rateRuleId: this.rateRuleId,
                condition: this.condition
            };

            try {
                this.isListLoading = true;
                const endpoint = '/api/rates/condition';
                const payload = {
                    method: 'post',
                    data: obj
                };

                this.$_handleLoaderState(true, 'SAVING...');

                const response = await handleRequests(endpoint, payload);

                if (response.status === 200) {
                    this.$notify({
                        message: 'Successfully updated condition.',
                        type: 'success'
                    });

                    this.resolve({
                        record: response.status
                    });
                } else {
                    showErrorMessage(this, 'Failed adding condition.', null);
                }
            } catch (error) {
                const message = 'Error Creating Conditions';
                showErrorMessage(this, message, error);
                this.$_handleLoaderState(false);
            }
        },
        async addToCondition() {
            this.$v.condition.$touch();

            if (this.validateRule()) {
                return;
            }

            const originalConditionLength = this.condition.length;

            if (this.condition.selectedOption.key === 'Between') {
                if (this.condition.length > 0) {
                    const conRule = `${this.condition.selectedOperator.type} ${
                        this.condition.selectedVariable.type
                    } >= ${this.condition.value} && ${this.condition.selectedVariable.type} <= ${
                        this.condition.valueTwo
                    }`;
                    this.condition.push(conRule);
                } else {
                    const conRule = `${this.condition.selectedVariable.type} >= ${this.condition.value} && ${
                        this.condition.selectedVariable.type
                    } <= ${this.condition.valueTwo}`;
                    this.condition.push(conRule);
                }
            } else if (this.condition.length <= 0) {
                const currentCondition = this.conditionVariables.find(
                    (x) => x.type === this.condition.selectedVariable.type
                );
                if (currentCondition.type === 'PickupAddress' || currentCondition.type === 'DropAddress') {
                    const conRule = `${this.condition.selectedVariable.type}.${this.condition.selectedOption.type}(${
                        this.condition.zoneValue.rateZoneId
                    })`;
                    this.condition.push(conRule);
                } else {
                    const conRule = `${this.condition.selectedVariable.type} ${this.condition.selectedOption.key} ${
                        this.condition.value
                    }`;
                    this.condition.push(conRule);
                }
            } else {
                const currentCondition = this.conditionVariables.find(
                    (x) => x.type === this.condition.selectedVariable.type
                );
                if (currentCondition.type === 'PickupAddress' || currentCondition.type === 'DropAddress') {
                    const conRule = `${this.condition.selectedOperator.type} ${
                        this.condition.selectedVariable.type
                    }.${this.condition.selectedOption.type}(${this.condition.zoneValue.rateZoneId})`;
                    this.condition.push(conRule);
                } else {
                    const conRule = `${this.condition.selectedOperator.type} ${
                        this.condition.selectedVariable.type
                    } ${this.condition.selectedOption.key} ${this.condition.value}`;
                    this.condition.push(conRule);
                }
            }

            if (this.condition.length > originalConditionLength) {
                this.$v.condition.$reset();
            }
        },
        deleteCondition(item) {
            const index = this.condition.indexOf(item);

            if (index === 0) {
                this.condition.splice(index, 1);

                if (this.condition.length) {
                    this.condition[0] = this.condition[0].slice(3);
                }
            } else {
                this.condition.splice(index, 1);
            }
        },
        validateRule() {
            if (this.condition.selectedOption?.key === 'Between') {
                if (this.condition.valueTwo < this.condition.value) {
                    showErrorMessage(this, 'Second Between value needs to be above the first value', null);
                    return true;
                }
            }

            if (
                this.condition.selectedVariable?.type === 'PickupAddress' ||
                this.condition.selectedVariable?.type === 'DropAddress'
            ) {
                if (this.condition.zoneValue === null || this.condition.zoneValue === undefined) {
                    showErrorMessage(this, 'Zone must be selected', null);
                    return true;
                }

                return false;
            }

            return this.$v.$invalid;
        },
        limitText(count) {
            if (count > 1) 
                return `and ${count} other zones`;
            return `and ${count} other zone`;
        }
    }
};
</script>

<style lang="scss" scoped>
.rate-form-container {
    padding-top: 20px;
    max-height: 80vh;
    padding-right: 10px;
}

::v-deep .form-wrapper {
    width: 100%;
}

.condition-add {
    min-width: 15px;
    min-height: 15px;
    height: 15px;
    display: inline-block;
    margin-top: 20px;
    display: inline-block;
}

// Holds And/Or
.condition-wrapper {
    width: 120px;
    margin: 1px;
    display: inline-block;
}

::v-deep .condition-wrapper .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

// Holds the variable eg. weight, items, volume
.lg-condition-wrapper {
    width: 20%;
    margin-right: 10px;
    display: inline-block;
}

::v-deep .lg-condition-wrapper .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

// holds the Condition eg. >= <=
.lge-condition-wrapper {
    width: 20%;
    margin-right: 6px;
    display: inline-block;
}

::v-deep .lge-condition-wrapper .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

// holds the Condition for IN - NOT IN
.lge-condition-wrapper-zone {
    width: 20%;
    margin-right: 10px;
    display: inline-block;
}

::v-deep .lge-condition-wrapper-zone .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

// hols the between values
.condition-data {
    width: 10%;
    margin: 10px;
    display: inline-block;
    height: 48px;
}

.condition-data-zone {
    width: 200px;
    margin: 2px;
    display: inline-block;
    padding-right: 5px;
    border: none;
}

.condition-data-opeator {
    width: 100px;
    margin: 2px;
    display: inline-block;
    padding-right: 5px;
    border: none;
}

.operator-wrapper {
    width: 15%;
    display: inline-block;
    margin-right: 10px;
}

.rule-table {
    border: none !important;
}

.delete-rule {
    color: green;
    height: 0%;
    width: 0%;
}

.rule-container {
    margin-left: 10px;
}

.condition-add {
    width: 25px;
    height: 25px;
    min-width: 25px;
    display: inline-block;
    margin-top: 20px;
    display: inline-block;
}

.modal-container-rule {
    max-width: 50%;
    margin: 0 auto;
    position: relative;
    background-color: #fff;
    transition: all 0.3s ease;
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
    border-radius: 6px;
    border: none;
}

.operator-chip {
    background-color: red;
    color: yellow;
    lighting-color: yellow;
}
</style>
