import { ICON_NAMES } from './constants';

const useDefaultMarkerStyles = () => {
    return {
        'background-size': 'cover',
        width: '50px',
        height: '50px',
        'border-radius': '50%',
        cursor: 'pointer'
    };
};

const useMapDefaultStyle = (iconName) => {
    switch (iconName) {
        case ICON_NAMES.DRIVER:
            return {
                'background-image': "url('/img/Van.png')"
            };
        case ICON_NAMES.DESTINATION:
            return {
                'background-image': "url('/img/pin-destination.png')"
            };
        case ICON_NAMES.PIN_STOP:
            return {
                'background-image': "url('/img/pin-stops.png')"
            };
        case ICON_NAMES.PIN_USER:
            return {
                'background-image': "url('/img/pin-user.png')"
            };
        case ICON_NAMES.SHIPMENT_PICKUP:
            return {
                'background-image': "url('/img/pick-up.png')"
            };
        case ICON_NAMES.SHIPMENT_DROP:
            return {
                'background-image': "url('/img/drop-off.png')"
            };
        default:
            return {};
    }
};

export { useDefaultMarkerStyles, useMapDefaultStyle };
