<template>
    <div class="cf-container">
        <div class="cf-action-container">
            <md-button
                title="Add asset custom fields"
                class="md-primary md-just-icon md-round"
                @click.stop="handleAddAssetCustomField()"
            >
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <form-group label="Select asset type" class="asset-type-filter">
            <md-select v-model="selectedAssetType" @md-selected="getSelectedAssetCutomFields">
                <md-option v-for="(assetType, index) in assetTypes" :key="index" :value="assetType">
                    {{ assetType }}
                </md-option>
            </md-select>
        </form-group>
        <md-table class="custom-paginated-table">
            <md-table-row>
                <md-table-head>Id</md-table-head>
                <md-table-head>Label</md-table-head>
                <md-table-head>Type</md-table-head>
                <md-table-head>Asset Type</md-table-head>
                <md-table-head class="actions">Actions</md-table-head>
            </md-table-row>

            <md-table-row v-for="(customField, index) in assetCustomFieldFilteredList" :key="index">
                <md-table-cell>
                    {{ customField.name }}
                </md-table-cell>
                <md-table-cell>
                    {{ customField.label }}
                </md-table-cell>
                <md-table-cell>
                    {{ customField.type }}
                </md-table-cell>
                <md-table-cell>
                    {{ customField.assetType }}
                </md-table-cell>
                <md-table-cell class="action-buttons">
                    <md-button
                        title="edit custom field"
                        class="md-warning md-just-icon md-round"
                        @click.stop="handleEditAssetCustomField(customField)"
                    >
                        <md-icon>edit</md-icon>
                    </md-button>
                    <md-button
                        title="delete custom field"
                        class="md-danger md-just-icon md-round"
                        @click.stop="removeAssetCustomField(customField, index)"
                    >
                        <md-icon>delete</md-icon>
                    </md-button>
                </md-table-cell>
            </md-table-row>
        </md-table>
        <div class="text-center" v-if="assetCustomFieldFilteredList.length == 0">
            <p class="no-result-message">No results matching your search/filter could be found.</p>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { ASSET_TYPE_CONSTANTS } from '@/utils/constants';
import AssetCustomFieldModal from './AssetCustomFieldModal';

export default {
    name: 'AssetCustomFieldsList',
    mixins: [GeneralMixin],
    data() {
        return {
            assetCustomFieldList: [],
            assetCustomFieldsWithTypeAny: [],
            assetCustomFieldFilteredList: [],
            shouldShowAddAssetCustomFieldModal: false,
            selectedAssetType: null,
            assetTypes: ['All', ...ASSET_TYPE_CONSTANTS, 'Any']
        };
    },
    async mounted() {
        this.$_handleLoaderState(true);
        const api = '/api/teams/custom-fields/assets';
        const response = await handleRequests(api);
        if (response.data !== '') {
            this.assetCustomFieldList = response.data;
            this.assetCustomFieldsWithTypeAny = response.data.filter((x) => x.assetType.toLowerCase() === 'any');
        }
        this.getSelectedAssetCutomFields();
        this.$_handleLoaderState(false);
        this.selectedAssetType = 'All';
    },
    methods: {
        handleAddAssetCustomField() {
            this.$modal.show(AssetCustomFieldModal).then((response) => {
                const assetCustomField = this.assetCustomFieldList.find((item) => item.name === response.name);

                // to prevent duplicate error from happening, we add a randomString at the end if field name already exists.
                if (assetCustomField) {
                    const randomString = Math.random()
                        .toString(20)
                        .substr(2, 4);
                    response.name = `${response.name}-${randomString}`;
                }

                if (!this.isDuplicateField(response)) {
                    this.assetCustomFieldList.push(response);
                    this.getSelectedAssetCutomFields();
                    this.saveAssetCustomFields('SAVING...');
                } else {
                    this.showWarningDuplicate(response.label, response.assetType);
                }
            });
        },
        /*
            can have same label with different asset type
            cannot have the same label name with Any asset type
            cannot have the same label with same asset type
        */
        handleEditAssetCustomField(selectedCustomField) {
            this.$modal.show(AssetCustomFieldModal, { selectedCustomField }).then((response) => {
                const hasChanges = Object.keys(selectedCustomField).some(
                    (key) => selectedCustomField[key] !== response[key]
                );

                if (hasChanges) {
                    const { label, type, assetType, name } = response;

                    let hasDuplicate = false;

                    // if has duplicate, check if the assetType is 'any'
                    if (this.isDuplicateField(response)) {
                        // if assetType of the edited custom field is 'any', check if there is a custom field with the same label
                        if (assetType.toLowerCase() === 'any') {
                            hasDuplicate = this.assetCustomFieldList.some((x) => x.label === label);
                        } else {
                            /*
                                otherwise check if
                                1. there is a custom field with assetType 'any' with the same label
                                2. there is a custom field with the same assetType but different name
                            */
                            const filteredCustomFields = this.assetCustomFieldList.filter(
                                (x) => x.label === label && x.name !== name && x.assetType === assetType
                            );
                            const hasSameLabelWithCustomFieldsAssetTypeAny = this.assetCustomFieldsWithTypeAny.some(
                                (x) => x.label === label
                            );
                            hasDuplicate = filteredCustomFields.length > 0 || hasSameLabelWithCustomFieldsAssetTypeAny;
                        }
                    }

                    if (!hasDuplicate) {
                        this.$set(selectedCustomField, 'label', label);
                        this.$set(selectedCustomField, 'type', type);
                        this.$set(selectedCustomField, 'assetType', assetType);
                        this.saveAssetCustomFields('UPDATING...');
                    } else {
                        this.showWarningDuplicate(label);
                    }
                }
            });
        },
        toggleModal(shouldToggle, modalToToggle) {
            this[modalToToggle] = shouldToggle;
        },
        removeAssetCustomField(customField, index) {
            this.$messageBox
                .show({
                    title: 'Delete asset custom field',
                    body: 'Are you sure you want to delete this custom field?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.assetCustomFieldFilteredList.splice(index, 1);

                        if (this.selectedAssetType !== 'All') {
                            const originalIndex = this.assetCustomFieldList.findIndex(
                                (item) => item.name === customField.name
                            );
                            this.assetCustomFieldList.splice(originalIndex, 1);
                        }

                        this.saveAssetCustomFields('DELETING...');
                    }
                });
        },
        async saveAssetCustomFields(text) {
            this.$_handleLoaderState(true, text);
            const payload = {
                method: 'post',
                data: this.assetCustomFieldList
            };
            const api = `/api/teams/custom-fields/assets`;
            try {
                await handleRequests(api, payload);

                let action = 'saved';

                if (text.toLowerCase().includes('updating')) {
                    action = 'updated';
                } else if (text.toLowerCase().includes('deleting')) {
                    action = 'deleted';
                }

                this.$notify({
                    message: `Custom fields ${action}!`,
                    type: 'success'
                });
                await this.$store.dispatch('user/FETCH_USER');
            } catch (e) {
                const message = 'Cannot save custom fields.';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
        },
        getSelectedAssetCutomFields() {
            if (this.selectedAssetType === 'All') {
                this.assetCustomFieldFilteredList = this.assetCustomFieldList;
            } else {
                this.assetCustomFieldFilteredList = this.assetCustomFieldList.filter((item) => {
                    return item.assetType === this.selectedAssetType;
                });
            }
        },
        isDuplicateField(customField) {
            const hasDuplicate = this.assetCustomFieldList.some(
                (x) =>
                    x.label.toLowerCase() === customField.label.toLowerCase() &&
                    (x.assetType.toLowerCase() === customField.assetType.toLowerCase() ||
                        x.assetType.toLowerCase() === 'any' ||
                        customField.assetType.toLowerCase() === 'any')
            );

            return hasDuplicate;
        },
        showWarningDuplicate(label, assetType = null) {
            let message = `Custom field name ${label} already exists.`;
            if (assetType && assetType.toLowerCase() !== 'any') {
                message = `Custom field name ${label} with asset type ${assetType} already exists.`;
            }

            this.$notify({
                message,
                type: 'warning'
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.actions {
    width: 6%;
}

.asset-type-filter {
    margin: 0;
    width: 150px;
    position: absolute;
    right: 65px;
    top: -36px;
}
</style>
