var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _vm.obdAlertModalHide !== true
      ? _c("div", { staticClass: "alert alert-info alert-z" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button", "aria-hidden": "true" },
              on: {
                click: function ($event) {
                  _vm.obdAlertModalHide = true
                },
              },
            },
            [_vm._v("\n            ×\n        ")]
          ),
          _c("span", [
            _vm._v(
              "\n            The Ignition On/Off reports are only available for OBD device data.\n        "
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c("div", { staticClass: "custom-toolbar md-layout" }, [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item custom-toolbar-start md-layout md-small-size-80 md-size-90",
              },
              [
                _c("team-members-filter", {
                  on: { onSelection: _vm.onTeamMemberSelection },
                }),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c("date-range-filter", {
                      on: { onChanged: _vm.onDateChanged },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "md-layout-item custom-toolbar-end" },
              [
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-primary md-just-icon md-round pull-right header-button",
                    attrs: { title: "Export Ignition report" },
                    on: { click: _vm.onExportToExcel },
                  },
                  [_c("md-icon", [_vm._v("get_app")])],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c("ignition-report-details", {
            attrs: { "member-list": _vm.drivesList },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }