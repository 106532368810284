var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-steps--container" },
    [
      _c("team-region-member-filter-options", {
        attrs: {
          "team-region-id": _vm.teamRegionId,
          "show-team-member": false,
          clearable: false,
        },
        on: { changed: _vm.handleTeamRegionChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }