var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam
    ? _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Settings"),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "   >    " + _vm._s(_vm.$t("menus.setting.customActions"))
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-blue" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("offline_bolt")])],
                      1
                    ),
                  ]
                ),
                _c("md-card-content", [
                  _vm.hasTeam
                    ? _c(
                        "div",
                        [
                          _c(
                            "tabs",
                            {
                              staticClass: "custom-tab-list",
                              class: {
                                "asset-custom-fields-active":
                                  _vm.isAssetCustomFieldsActive,
                              },
                              attrs: {
                                "tab-name": ["Email", "Sms", "Others"],
                                "color-button": "success",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "tab-pane-1" },
                                [
                                  _c("EmailCustomAction", {
                                    attrs: {
                                      "event-trigger": _vm.selectedEventTrigger,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "tab-pane-2" },
                                [_c("SmsCustomAction")],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "tab-pane-3" },
                                [
                                  _c("OtherCustomActions", {
                                    attrs: {
                                      "event-trigger": _vm.selectedEventTrigger,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }