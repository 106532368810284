<template>
    <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <h3 class="title">User Settings</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>settings</md-icon>
                    </div>
                </md-card-header>

                <md-card-content>
                    <div>
                        <tabs
                            :tab-name="['SMS Settings', 'Proof of Delivery Settings']"
                            color-button="success"
                            class="custom-tab-list"
                        >
                            <template slot="tab-pane-1">
                                <div class="member-container">
                                    <sms-settings level="users" />
                                </div>
                            </template>
                            <template slot="tab-pane-2">
                                <div>
                                    <proof-of-delivery-configuration />
                                </div>
                            </template>
                        </tabs>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { ProofOfDeliveryConfiguration, Tabs, SmsSettings } from '@/components';

export default {
    name: 'UserSettings',
    mixins: [GeneralMixin],
    components: { ProofOfDeliveryConfiguration, SmsSettings, Tabs }
};
</script>
