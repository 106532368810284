var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-field",
    { class: { "md-invalid": _vm.hasErrors } },
    [
      _c("label", [_vm._v(_vm._s(_vm.label))]),
      _vm._t("default", null, {
        touch: function () {
          return _vm.preferredValidator.$touch()
        },
      }),
      _vm.hasErrors
        ? _c("span", { staticClass: "md-error" }, [
            _vm._v(_vm._s(_vm.firstErrorMessage)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }