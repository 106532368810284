var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search--container" },
    [
      _c(
        "md-field",
        { attrs: { "md-clearable": "" }, on: { "md-clear": _vm.handleClear } },
        [
          _c("md-input", {
            attrs: { type: "text", placeholder: _vm.placeholder },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSearch($event)
              },
            },
            model: {
              value: _vm.searchText,
              callback: function ($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText",
            },
          }),
        ],
        1
      ),
      _vm.searchIcon
        ? _c(
            "div",
            { staticClass: "search-button--container" },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-icon-button md-dense md-raised md-primary",
                  on: { click: _vm.handleSearch },
                },
                [_c("md-icon", [_vm._v("search")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }