<template>
    <div class="md-layout">
        <md-card class="invoice-setting">
            <md-card-content>
                <form-wrapper :validator="$v.settings" class="invoice-form">
                    <div class="md-layout">
                        <label class="md-layout-item md-size-5 md-form-label">
                            Currency:
                        </label>
                        <div class="md-layout-item md-size-20">
                            <currencies-autocomplete
                                label=""
                                placeholder=""
                                v-model="settings.currency"
                                :value="settings.currency"
                                :should-focus="true"
                            />
                        </div>
                        <div class="md-layout-item md-size-20">
                            <form-group label="Payment Terms (Days)" name="paymentTermsInDay">
                                <md-input v-model="settings.paymentTermsInDays" />
                            </form-group>
                        </div>

                        <div class="md-layout-item md-size-20">
                            <form-group v-if="taxSettings.length" label="Default tax settings">
                                <md-select v-model="settings.defaultTaxSettingsId">
                                    <md-option :value="''">N/A</md-option>
                                    <md-option
                                        v-for="tax in taxSettings"
                                        :key="tax.taxSettingsId"
                                        :value="tax.taxSettingsId"
                                    >
                                        {{ tax.type }} ({{ tax.description }})
                                    </md-option>
                                </md-select>
                            </form-group>
                        </div>
                    </div>
                </form-wrapper>
            </md-card-content>

            <form-wrapper :validator="$v.settings">
                <invoice-affix-settings :settings="settings" />
            </form-wrapper>

            <payment-method-inputs :detail="settings" :show-remittance="true" />

            <md-card-actions md-alignment="left">
                <button class="custom-btn" @click="validateForm">
                    Save
                </button>
            </md-card-actions>
        </md-card>
    </div>
</template>

<script>
import { CurrenciesAutocomplete, PaymentMethodInputs } from '@/components';
import { handleRequests, showErrorMessage, isValidInvoiceNumberAffix } from '@/helpers';
import { required, maxLength } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import InvoiceAffixSettings from './InvoiceAffixSettings';

export default {
    name: 'InvoiceSettings',
    mixins: [GeneralMixin],
    components: { CurrenciesAutocomplete, PaymentMethodInputs, InvoiceAffixSettings },
    data() {
        return {
            settings: {
                paymentDetails: {
                    paymentMethod: 'None',
                    bank: {
                        name: null,
                        accountNumber: null,
                        bsb: null
                    }
                },
                paymentTermsInDays: null,
                invoiceAffixSettings: {
                    teamMemberInvoiceNumberPrefix: null,
                    teamMemberInvoiceNumberSuffix: null,
                    customerInvoiceNumberPrefix: null,
                    customerInvoiceNumberSuffix: null
                },
                defaultTaxSettingsId: '',
                sendAsPaymentSummary: false
            },
            taxSettings: [],
            settingUpRates: Boolean(this.$route.query.setupCurrency)
        };
    },
    async mounted() {
        this.$_handleLoaderState(true);
        await this.getInvoiceSettingsData();
        this.taxSettings = await this.getTaxSettings();
        this.$_handleLoaderState(false);
    },
    validations: {
        settings: {
            currency: { required },
            invoiceAffixSettings: {
                teamMemberInvoiceNumberPrefix: {
                    maxLength: maxLength(6),
                    invalid: isValidInvoiceNumberAffix
                },
                teamMemberInvoiceNumberSuffix: {
                    maxLength: maxLength(6),
                    invalid: isValidInvoiceNumberAffix
                },
                customerInvoiceNumberPrefix: {
                    maxLength: maxLength(6),
                    invalid: isValidInvoiceNumberAffix
                },
                customerInvoiceNumberSuffix: {
                    maxLength: maxLength(6),
                    invalid: isValidInvoiceNumberAffix
                }
            }
        }
    },
    methods: {
        async getInvoiceSettingsData() {
            const api = '/api/teams/settings/invoice';
            const { data } = await handleRequests(api);
            this.settings = data;
        },
        validateForm() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.saveInvoiceSettings();
            }
        },
        async saveInvoiceSettings() {
            this.$_handleLoaderState(true, 'SAVING...');

            this.settings.paymentTermsInDays =
                this.settings.paymentTermsInDays === '' ? null : this.settings.paymentTermsInDays;
            const payload = {
                method: 'post',
                data: this.settings
            };

            const api = '/api/teams/settings/invoice';
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Invoice settings saved!',
                    type: 'success'
                });
                this.$_handleLoaderState(false);
            } catch (e) {
                this.$_handleLoaderState(false);
                const message = 'Cannot save invoice settings.';
                showErrorMessage(this, message, e);
            } finally {
                if (this.settingUpRates) {
                    this.$router.replace({ path: `/rates/list?callBackFromCurrency=true`, query: {} });
                }
            }
        },
        async getTaxSettings() {
            let response = {
                data: []
            };
            try {
                const endpoint = `/api/team-settings/tax-settings`;

                response = await handleRequests(endpoint);
            } catch (error) {
                const message = 'Error in getting the tax settings list';
                showErrorMessage(this, message, error);
            }
            return response.data ?? [];
        }
    }
};
</script>
<style lang="scss" scoped>
.invoice-setting {
    border: 0px !important;
    box-shadow: none;
    margin: 0;
}
.invoice-form label {
    padding-left: 15px !important;
    text-align: left !important;
}
</style>
