var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-inline" },
    [
      _vm.canViewTeamRegion && _vm.teamRegions && _vm.teamRegions.length > 0
        ? _c(
            "md-field",
            [
              _c("team-region-filter-options", {
                attrs: { "team-region-id": _vm.teamRegionId, clearable: false },
                on: { changed: _vm.handleTeamRegionChanged },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.teamMembers.length > 0
        ? _c(
            "md-field",
            [
              _c("vue-select", {
                staticClass: "select",
                attrs: {
                  label: "text",
                  options: _vm.filteredOptions,
                  placeholder: _vm.teamMemberOptionsPlaceholder,
                  searchable: _vm.$root.isDesktop,
                  selectable: function (option) {
                    return option.selectable
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          option.selectable
                            ? _c("span", [_vm._v(_vm._s(option.text))])
                            : _c("hr"),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3215030299
                ),
                model: {
                  value: _vm.selectedTeamMember,
                  callback: function ($$v) {
                    _vm.selectedTeamMember = $$v
                  },
                  expression: "selectedTeamMember",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }