<!-- eslint-disable vue/valid-v-if -->
<template>
    <div>
        <div class="detail-container">
            <div class="close-button">
                <md-button class="md-default md-just-icon md-round pull-right" @click="closeModal">
                    <md-icon>close</md-icon>
                </md-button>
            </div>
            <div v-if="!loading">
                <div class="detail-section text-center">
                    <span class="title">
                        {{ sidebarData.maintenanceName }}
                    </span>
                </div>
                <div class="line-separator"></div>
                <div class="detail-section">
                    <span class="title">Maintenance Schedule Details</span>
                    <div class="detail-group">
                        <label class="label">Type</label>
                        <span class="value">
                            <span
                                class="badge"
                                :class="`status-${$_getAssetLogTypeBadgeColor(sidebarData.maintenanceType)}`"
                            >
                                {{ $t('assetLogTypes.' + sidebarData.maintenanceType) }}
                            </span>
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Subtype</label>
                        <span class="value">
                            <span
                                class="badge"
                                :class="
                                    `status-${$_getAssetLogSubTypeBadgeColor(
                                        sidebarData.maintenanceType,
                                        sidebarData.maintenanceSubType
                                    )}`
                                "
                            >
                                {{ $t('assetLogSubTypes.' + sidebarData.maintenanceSubType) }}
                            </span>
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Description</label>
                        <span class="value">{{ sidebarData.notes }}</span>
                    </div>
                </div>

                <div class="line-separator" v-if="cronExpressionText != null"></div>
                <div
                    class="detail-section"
                    v-if="sidebarData.timeSchedule != null || sidebarData.timeScheduleInterval != null"
                >
                    <span class="title">Time based reminders</span>
                    <div class="detail-group">
                        <label class="label blue-icon">
                            <span class="material-icons">
                                notifications_active
                            </span>
                            Due Reminder
                        </label>
                        <div class="value">
                            <div v-if="sidebarData.timeSchedule != null">
                                {{ cronExpressionText }}
                            </div>
                            <div v-if="sidebarData.timeScheduleInterval != null">
                                every {{ sidebarData.timeScheduleInterval }} {{ sidebarData.timeScheduleUnitType }}
                            </div>
                        </div>
                    </div>
                    <div class="detail-group">
                        <label class="label">
                            <span class="material-icons">
                                restore
                            </span>
                            Pre-reminder
                        </label>
                        <div class="value">
                            <div class="">
                                <span
                                    v-if="
                                        getBeforeReminders(sidebarData.timeScheduleReminderOffsetDays, 'days') === null
                                    "
                                >
                                    -
                                </span>
                                {{ getBeforeReminders(sidebarData.timeScheduleReminderOffsetDays, 'days') }}
                            </div>
                        </div>
                    </div>
                    <div class="detail-group">
                        <label class="label">
                            <span class="material-icons">
                                update
                            </span>
                            Post-reminder
                        </label>
                        <div class="value">
                            <div>
                                <span
                                    v-if="
                                        getAfterReminders(sidebarData.timeScheduleReminderOffsetDays, 'days') === null
                                    "
                                >
                                    -
                                </span>
                                {{ getAfterReminders(sidebarData.timeScheduleReminderOffsetDays, 'days') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="line-separator"
                    v-if="sidebarData.odometerReminderInterval != null || sidebarData.odometerReminderValue != null"
                ></div>
                <div
                    class="detail-section"
                    v-if="sidebarData.odometerReminderInterval != null || sidebarData.odometerReminderValue != null"
                >
                    <span class="title">Distance reminders</span>
                    <div class="detail-group">
                        <label class="label blue-icon">
                            <span class="material-icons">
                                notifications_active
                            </span>
                            Due Reminder
                        </label>
                        <div class="value">
                            <div>
                                <span v-if="sidebarData.odometerReminderInterval != null">
                                    every {{ sidebarData.odometerReminderInterval }}
                                    {{ sidebarData.odometerReminderUnitType }}
                                </span>
                                <span v-if="sidebarData.odometerReminderValue != null">
                                    {{ sidebarData.odometerReminderValue }}
                                    {{ sidebarData.odometerReminderUnitType }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="detail-group">
                        <label class="label">
                            <span class="material-icons">
                                restore
                            </span>
                            Pre-reminder
                        </label>
                        <div class="value">
                            <div class="">
                                <span
                                    v-if="
                                        getBeforeReminders(
                                            sidebarData.odometerReminderOffsets,
                                            sidebarData.odometerReminderUnitType
                                        ) === null
                                    "
                                >
                                    -
                                </span>

                                {{
                                    getBeforeReminders(
                                        sidebarData.odometerReminderOffsets,
                                        sidebarData.odometerReminderUnitType
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="detail-group">
                        <label class="label">
                            <span class="material-icons">
                                update
                            </span>
                            Post-reminder
                        </label>
                        <div class="value">
                            <div class="red-icon">
                                <span
                                    v-if="
                                        getAfterReminders(
                                            sidebarData.odometerReminderOffsets,
                                            sidebarData.odometerReminderUnitType
                                        ) === null
                                    "
                                >
                                    -
                                </span>
                                {{
                                    getAfterReminders(
                                        sidebarData.odometerReminderOffsets,
                                        sidebarData.odometerReminderUnitType
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="line-separator"
                    v-if="
                        sidebarData.engineHoursReminderInterval != null || sidebarData.engineHoursReminderValue != null
                    "
                ></div>
                <div
                    class="detail-section"
                    v-if="
                        sidebarData.engineHoursReminderInterval != null || sidebarData.engineHoursReminderValue != null
                    "
                >
                    <span class="title">Engine hours reminders</span>
                    <div class="detail-group">
                        <label class="label blue-icon">
                            <span class="material-icons">
                                notifications_active
                            </span>
                            Due Reminder
                        </label>
                        <div class="value">
                            <div>
                                <span v-if="sidebarData.engineHoursReminderInterval != null">
                                    every {{ sidebarData.engineHoursReminderInterval }} hours
                                </span>
                                <span v-if="sidebarData.engineHoursReminderValue != null">
                                    {{ sidebarData.engineHoursReminderValue }} hours
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="detail-group">
                        <label class="label">
                            <span class="material-icons">
                                restore
                            </span>
                            Pre-reminder
                        </label>
                        <div class="value">
                            <div>
                                <span
                                    v-if="getBeforeReminders(sidebarData.engineHoursReminderOffsets, 'hours') === null"
                                >
                                    -
                                </span>
                                {{ getBeforeReminders(sidebarData.engineHoursReminderOffsets, 'hours') }}
                            </div>
                        </div>
                    </div>
                    <div class="detail-group">
                        <label class="label">
                            <span class="material-icons">
                                update
                            </span>
                            Post-reminder
                        </label>
                        <div class="value">
                            <div class="red-icon">
                                <span
                                    v-if="getAfterReminders(sidebarData.engineHoursReminderOffsets, 'hours') === null"
                                >
                                    -
                                </span>
                                {{ getAfterReminders(sidebarData.engineHoursReminderOffsets, 'hours') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line-separator"></div>
                <div class="detail-section">
                    <span class="title">Notification Configuration</span>
                    <div
                        class="detail-group"
                        v-if="
                            this.sidebarData.notificationConfiguration.email != null &&
                                this.sidebarData.notificationConfiguration.email.length > 0
                        "
                    >
                        <label class="label">
                            <span class="material-icons custom-blue-icon">
                                email
                                <md-tooltip md-direction="left">Receieve notification thru email</md-tooltip>
                            </span>
                            Email
                        </label>
                        <div class="value">
                            <span
                                class="notification-role"
                                v-for="(role, index) in this.sidebarData.notificationConfiguration.email"
                                :key="index"
                            >
                                {{ role }}
                            </span>
                        </div>
                    </div>

                    <div class="detail-group" v-if="this.additionalEmails != null && this.additionalEmails.length > 0">
                        <label class="label">Additional Emails:</label>
                        <div class="value custom-scroll-container">
                            <span v-for="(email, index) in this.additionalEmails" :key="index" class="custom-ellipsis">
                                {{ email }}
                            </span>
                        </div>
                    </div>
                    <div class="detail-group" v-if="this.sidebarData.vehicleNames.length > 0">
                        <label class="label">Assets Selected:</label>
                        <div class="value custom-scroll-container">
                            <span
                                v-for="(name, index) in this.sidebarData.vehicleNames"
                                :key="index"
                                class="custom-ellipsis"
                            >
                                {{ name }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import { GeneralMixin, MapOverviewMixin, AssetManagementMixin } from '@/mixins';
import cronstrue from 'cronstrue';
import { SPEED_TO_DISTANCE_UNITS } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
    name: 'MaintenanceScheduleSidebar',
    props: {
        sidebarData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            loading: true,
            sidebarDetails: null,
            additionalEmails: null,
            cronExpressionText: null,
            defaultSpeedUnit: 'kph',
            defaultDistanceUnit: 'km'
        };
    },
    computed: {
        ...mapGetters({
            generalSettings: 'generalSetting/getGeneralSettings'
        })
    },
    mixins: [GeneralMixin, MapOverviewMixin, AssetManagementMixin],
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        listAdditionalEmails() {
            if (this.sidebarData.notificationConfiguration.additionalEmails) {
                let emails = this.sidebarData.notificationConfiguration.additionalEmails.replace(/\s/g, '');
                emails = emails.replace(/;/g, ',');
                emails = emails.split(',');
                this.additionalEmails = emails;
            } else {
                this.additionalEmails = null;
            }
        },
        getBeforeReminders(data, type) {
            if (data != null) {
                const parsedData = JSON.parse(data);
                const beforeReminders = parsedData.filter((x) => x < 0);

                if (beforeReminders.length > 0) 
                    return `${beforeReminders.map((x) => Math.abs(x))} ${type}`;

                return '-';
            }
            return null;
        },
        getAfterReminders(data, type) {
            if (data != null) {
                const parsedData = JSON.parse(data);
                const afterReminders = parsedData.filter((x) => x > 0);

                if (afterReminders.length > 0) 
                    return `${afterReminders} ${type}`;

                return '-';
            }
            return null;
        },
        getDefaultDetailsAndCronExpressionText() {
            if (this.sidebarData.timeSchedule != null || this.sidebarData.timeScheduleInterval != null) {
                if (this.sidebarData.timeSchedule) {
                    this.cronExpressionText = cronstrue.toString(this.sidebarData.timeSchedule);
                    // needed to cut the first 13 characters saying "At 12:00 AM,"
                    this.cronExpressionText = this.cronExpressionText.substring(13);
                } else {
                    this.cronExpressionText = `every ${this.sidebarData.timeScheduleInterval} ${
                        this.sidebarData.timeScheduleUnitType
                    }`;
                }
            } else {
                this.cronExpressionText = null;
            }

            if (this.generalSettings.defaultSpeedUnit != null) {
                this.defaultDistanceUnit = SPEED_TO_DISTANCE_UNITS[this.generalSettings.defaultSpeedUnit];
                this.defaultSpeedUnit = this.generalSettings.defaultSpeedUnit;
            }
        }
    },
    watch: {
        'sidebarData.assetMaintenanceScheduleId': function() {
            this.listAdditionalEmails();
            this.getDefaultDetailsAndCronExpressionText();
        }
    },
    mounted() {
        this.loading = false;
        this.listAdditionalEmails();
        this.getDefaultDetailsAndCronExpressionText();
    }
};
</script>

<style lang="scss" scoped>
.detail-container {
    position: fixed;
    top: 2vh;
    right: 10px;
    height: 96vh;
    width: 25%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    background: white;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 16px;
    max-width: 400px;
    min-width: 450px;

    .close-button {
        top: 0;
        right: 0;
        position: absolute;
        /* add z-index for old stops that have no stop reference numbers */
        z-index: 999;
    }

    .rating {
        display: flex;
        align-items: flex-end;
        > {
            display: inline-block;
        }
        .md-icon {
            margin: 0;
            padding-bottom: 5px;
        }
    }

    .md-button.md-just-icon,
    ::v-deep .md-button.md-just-icon {
        font-size: 15px;
        height: 30px;
        min-width: 3px;
        width: 30px;
        line-height: 30px;
        margin: 2.5px;
    }

    .detail-section {
        padding-top: 10px;
        .blue-ref a {
            color: #2b93ff !important;
        }
        .blue-ref {
            color: #2b93ff;
        }
        .title {
            text-transform: uppercase;
            font-weight: 600;
            padding: 2px 16px;
            display: block;
            font-size: 14px;
        }
        ::v-deep .custom-a-blue a {
            color: #2b93ff !important;
        }
    }

    .line-separator {
        height: 1px;
        background-color: #ddd;
        width: calc(100% - 32px);
        margin: 10px auto;
        margin-bottom: 0px;
    }

    .detail-group {
        padding: 2px 16px;
        margin: 5px 0;
        font-size: 0.9em;
        .label {
            display: inline-block;
            width: 150px;
            margin-right: 4px;
            vertical-align: top;
            font-weight: 400;
            .custom-blue-icon {
                color: #2b93ff;
            }

            .material-icons {
                vertical-align: middle;
            }
        }

        .value {
            display: inline-block;
            width: calc(100% - 160px);
            vertical-align: top;
            a {
                color: #2b93ff;
            }
            .custom-blue-icon {
                color: #2b93ff;
            }
            > div {
                vertical-align: middle;
                > span {
                    vertical-align: middle;
                }
            }
            .notification-role {
                display: block;
            }
        }
        .custom-btn,
        ::v-deep .custom-btn {
            padding: 4px 8px;
            margin: 0;
            width: initial;
            max-width: initial;
            padding: 4px 0;
            .md-ripple {
                padding: 0 !important;
            }
        }
        .address {
            color: #2b93ff;
            &:hover {
                text-decoration: underline !important;
                cursor: pointer;
            }
        }
        .status-label {
            margin-left: 10px;
            font-weight: 400;
            font-size: 0.75rem;
            width: calc(100% - 100px);
        }
        .info-line {
            font-weight: 400;
            //font-size: 0.75rem;
            width: 100%;
        }
        .quantity {
            float: right;
        }
        .notes {
            padding-left: 24px;
            width: 100%;
        }
    }

    .item-reference ::v-deep a {
        color: rgba(0, 0, 0, 0.87);
    }

    .action-detail-group {
        margin: 0;
        position: relative;
    }

    .profile-image {
        height: 50px;
        width: 50px;
        margin-top: -10px;
        position: absolute;
        left: 16px;
        margin-top: -16px;
        border-radius: 50%;
        box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.1), 0 0px 15px 0 rgba(0, 0, 0, 0.15) !important;
    }

    .custom-scroll-container {
        background-color: #fff;
        max-height: 150px;
        overflow: auto;
        span {
            display: block;
        }
    }

    .blue-icon {
        > span {
            color: #2b93ff;
        }
    }
}
</style>
