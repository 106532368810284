var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-steps--container" },
    [
      _vm.canViewTeamRegion || _vm.isCustomerAdmin
        ? _c("team-region-member-filter-options", {
            attrs: {
              "team-region-id": _vm.teamRegionId,
              "team-member-public-userid": _vm.teamMemberFilterValue,
              "strict-team-region-filter": false,
              clearable: false,
              "show-carriers": true,
            },
            on: { changed: _vm.handleTeamRegionMemberChanged },
          })
        : _vm._e(),
      _vm.hasCustomerFilterAccess
        ? _c(
            "md-field",
            [
              _c("customer-filter-options", {
                staticClass: "filter-steps--choices filter-status",
                attrs: { "filter-customer-id": _vm.customerIdFilterValue },
                on: { onCustomerSelected: _vm.handleCustomerChanged },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "md-field",
        { staticClass: "status-filter" },
        [
          _vm.statuses
            ? _c("vue-select", {
                attrs: {
                  options: _vm.statuses,
                  placeholder: "Status",
                  searchable: _vm.$root.isDesktop,
                },
                model: {
                  value: _vm.statusFilterValue,
                  callback: function ($$v) {
                    _vm.statusFilterValue = $$v
                  },
                  expression: "statusFilterValue",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "md-field",
        [
          _c("md-datepicker", {
            staticClass: "filter-steps--choices filter-step-date start-date",
            attrs: { "md-immediately": "", "md-debounce": 100 },
            model: {
              value: _vm.fromDate,
              callback: function ($$v) {
                _vm.fromDate = $$v
              },
              expression: "fromDate",
            },
          }),
        ],
        1
      ),
      _c(
        "md-field",
        [
          _c("md-datepicker", {
            staticClass: "filter-steps--choices filter-step-date end-date",
            attrs: { "md-immediately": "", "md-debounce": 100 },
            model: {
              value: _vm.toDate,
              callback: function ($$v) {
                _vm.toDate = $$v
              },
              expression: "toDate",
            },
          }),
        ],
        1
      ),
      !_vm.isGeocodedView
        ? _c(
            "md-field",
            { staticClass: "time-picker md-theme-default" },
            [
              _c("time-picker", {
                key: _vm.componentKey,
                attrs: {
                  time: _vm.selectedTime,
                  "is-appointment-time-included": false,
                  "additional-time-options": _vm.additionalTimeWindowOptions,
                  "is-use-in-filter": true,
                },
                on: {
                  selectedTime: _vm.getTimeWindow,
                  customTimeWindowModal: _vm.showCustomTimeWindowModal,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "md-checkbox",
        {
          attrs: { type: "checkbox" },
          on: { change: _vm.handleShowBlankStopDateFirst },
          model: {
            value: _vm.showBlankStopDateFirst,
            callback: function ($$v) {
              _vm.showBlankStopDateFirst = $$v
            },
            expression: "showBlankStopDateFirst",
          },
        },
        [_vm._v("\n        Show stops with no date first\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }