var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.baseComponent,
    {
      tag: "component",
      class: { active: _vm.isActive },
      attrs: { to: _vm.link.path ? _vm.link.path : "/", tag: "li" },
    },
    [
      _vm.isMenu
        ? _c(
            "a",
            {
              staticClass: "nav-link sidebar-menu-item",
              attrs: {
                href: "#",
                "aria-expanded": !_vm.collapsed,
                "data-toggle": "collapse",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.collapseMenu($event)
                },
              },
            },
            [
              _c("md-icon", [_vm._v(_vm._s(_vm.link.icon))]),
              _c("p", [
                _vm._v(
                  "\n            " + _vm._s(_vm.link.name) + "\n            "
                ),
                _vm.badge.length > 0
                  ? _c(
                      "span",
                      { staticClass: "menu-badge md-square md-primary" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.badge) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("b", { staticClass: "caret" }),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("collapse-transition", [
        _vm.$slots.default || _vm.isMenu
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.collapsed,
                    expression: "!collapsed",
                  },
                ],
              },
              [_c("ul", { staticClass: "nav" }, [_vm._t("default")], 2)]
            )
          : _vm._e(),
      ]),
      _vm.children.length === 0 && !_vm.$slots.default && _vm.link.path
        ? _vm._t("title", [
            _c(
              _vm.elementType(_vm.link, false),
              {
                tag: "component",
                staticClass: "nav-link",
                class: { active: _vm.link.active },
                attrs: {
                  to: _vm.link.path,
                  target: _vm.link.target,
                  href: _vm.link.path,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.linkClick($event)
                  },
                },
              },
              [
                _vm.addLink
                  ? [
                      _c("span", { staticClass: "sidebar-mini" }, [
                        _vm._v(_vm._s(_vm.linkPrefix)),
                      ]),
                      _c("span", { staticClass: "sidebar-normal" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.link.name) +
                            "\n                    "
                        ),
                        _vm.badge.length > 0
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "menu-badge menu-badge-link md-square md-primary",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.badge) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  : [
                      _c("md-icon", [_vm._v(_vm._s(_vm.link.icon))]),
                      _c("p", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.link.name) +
                            "\n                    "
                        ),
                        _vm.badge.length > 0
                          ? _c(
                              "span",
                              {
                                staticClass: "menu-badge md-square md-primary",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.badge) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ],
              ],
              2
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }