<template>
    <div class="load-vals">
        <form-group :name="loadDefinition.type" :label="loadDefinition.label">
            <md-input v-model="loadValue" />
            <span>
                {{ loadDefinition.symbol }}
            </span>
        </form-group>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'LoadInputs',
    mixins: [GeneralMixin],
    props: {
        loadDefinition: {
            type: Object,
            default: () => {}
        },
        // eslint-disable-next-line vue/require-default-prop
        initialValue: null
    },
    watch: {
        loadValue(newValue, oldValue) {
            this.$emit('changed', { name: this.loadDefinition.type, value: parseFloat(newValue) });
        }
    },
    mounted() {
        if (this.initialValue[this.loadDefinition.type] === null) {
            this.loadValue = this.loadDefinition.defaultLoad;
        } else {
            this.loadValue =
                this.initialValue && this.initialValue[this.loadDefinition.type] !== undefined
                    ? this.initialValue[this.loadDefinition.type]
                    : this.loadDefinition.defaultLoad;
        }
    },
    data() {
        return {
            loadValue: null
        };
    }
};
</script>

<style lang="scss" scoped>
.load-vals {
    display: inline-block;
    width: calc(33% - 10px);
    margin-right: 10px;
    &::last-child {
        margin-right: 0;
    }
    span {
        font-size: smaller;
        margin-left: -12px;
    }
}
</style>
