<template>
    <div class="md-layout">
        <div v-if="showDropdown">
            <div class="md-layout-item md-size-50">
                <form-group label="Select an existing tracker">
                    <md-select v-model="selected" @md-selected="getExistingDetails">
                        <md-option value="0">
                            Create New
                        </md-option>
                        <md-option v-for="x in dropdownList" :key="x.assetId" :value="x.assetId">
                            {{ x.name }}
                        </md-option>
                    </md-select>
                </form-group>
            </div>
            <div class="md-layout-item md-size-100">
                <h5>Tracker Information:</h5>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <form-group name="name" label="Asset Name*">
                <md-input v-model="data.name" :disabled="isDisabled" />
            </form-group>
        </div>
        <div class="md-layout-item md-size-50">
            <form-group name="brand" label="Tracker Brand">
                <md-input v-focus v-model="data.brand" :disabled="isDisabled" />
            </form-group>
        </div>
        <div class="md-layout-item md-size-50">
            <form-group name="model" label="Tracker Model">
                <md-input v-model="data.model" :disabled="isDisabled" />
            </form-group>
        </div>
        <div class="md-layout-item md-size-50">
            <form-group name="modelYear" label="Tracker Model Year">
                <md-input v-model="data.modelYear" :disabled="isDisabled" />
            </form-group>
        </div>
        <div class="md-layout-item md-size-50">
            <form-group name="trackerReference" label="Tracker IMEI*">
                <md-input v-model="data.trackerReference" :disabled="isUpdateAsset" />
            </form-group>
        </div>
        <div class="md-layout-item md-size-100" v-if="assetCustomFieldList !== null">
            <custom-field-inputs
                v-for="(customField, index) in assetCustomFieldList"
                :key="index"
                :custom-field-definition="customField"
                @changed="handleCustomFieldChanged"
                :initial-value="data[customField.name]"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CustomFieldInputs } from '@/components';

export default {
    name: 'TrackerStep',
    components: {
        CustomFieldInputs
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        dropdownList: {
            type: Array,
            default: () => []
        },
        isDisabled: {
            type: Boolean,
            default: () => false
        },
        isUpdateAsset: {
            type: Boolean,
            default: () => false
        },
        selectedAsset: {
            type: Number,
            default: () => 0
        },
        showDropdown: {
            type: Boolean,
            default: () => true
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    mounted() {
        this.selected = this.selectedAsset;
        if (this.user.assetCustomFieldDefinitions !== '' && this.user.assetCustomFieldDefinitions != null) {
            this.assetCustomFieldList = this.user.assetCustomFieldDefinitions.filter((item) => {
                return item.assetType === 'Tracker' || item.assetType === 'Any';
            });
        }
    },
    data() {
        return {
            selected: 0,
            assetCustomFieldList: []
        };
    },
    methods: {
        setAssetName() {
            if (this.data.name && this.data.name !== this.data.trackerReference) 
                return;
            this.data.name = `${this.data.trackerReference ? this.data.trackerReference : ''}`.trim();
        },
        getExistingDetails(assetId) {
            // eslint-disable-next-line radix
            this.$emit('getExistingDetails', parseInt(assetId));
        },
        handleCustomFieldChanged({ name, value }) {
            this.data[name] = value;
        }
    }
};
</script>
