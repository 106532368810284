<template>
    <div>
        <div
            class="toggled-options map-panel"
            :class="{ individual: isIndividual, pinned: pinnedUser }"
            :style="{ '--bottom-placement': getButtonPlacement }"
        >
            <div style="position:relative; display:flex;">
                <button @click="downloadData()" :class="{ disabled: isFutureDate.value }">
                    <md-icon>
                        cloud_download
                    </md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Download Raw GPS Data</md-tooltip>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject, computed } from '@vue/composition-api';
import { handleRequests, downloadFile, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import moment from 'moment';

export default {
    name: 'CommandsPanel',
    props: {
        pinnedDriverDetails: {
            type: Object,
            default: () => {}
        }
    },
    mixins: [GeneralMixin],
    methods: {
        downloadData() {
            if (this.isFutureDate.value) 
                return;
            if (this.date) {
                this.$_handleLoaderState(true);
                const api = `/api/reports/raw-gps-data/${moment(this.date).format(
                    'YYYY-MM-DD'
                )}/export-to-excel/?userId=${this.pinnedUser || ''}&isTracker=${this.isAssetGroup}`;
                const payload = {
                    method: 'get',
                    responseType: 'blob'
                };

                let name;
                if (this.isAssetGroup) 
                    name = this.pinnedDriverDetails.groupName;
                else 
                    name = this.pinnedDriverDetails.fullName;
                // Replace possibly invalid filename characters.
                name = name.replace(/[/\\?%*:|"<>]/g, '-');

                const reportName = `${name}-gps-${moment(this.date).format('YYYY-MM-DD')}.xlsx`;

                handleRequests(api, payload)
                    .then((response) => {
                        downloadFile(response.data, reportName);
                        this.$_handleLoaderState(false);
                    })
                    .catch(async (blobError) => {
                        const message = 'There are no GPS data to export.';

                        let error = null;

                        /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
                        try {
                            // Receiving an ActivityException error here will return it as a text in a blob (since the responseType of the request is 'blob').
                            // We get the blob text & parse it as json. Then we compine an error object with real data to display as error.
                            error = {
                                data: JSON.parse(await new Blob([blobError.data]).text())
                            };
                        } catch {}

                        showErrorMessage(this, message, error);

                        this.$_handleLoaderState(false);
                    });
            }
        }
    },
    setup(_, __) {
        const { getters } = inject('vuex-store');

        const pinnedUser = computed(() => getters['map/pinnedUser']);
        const isLocationHistoryToggled = computed(() => getters['map/isLocationHistoryToggled']);
        const isStopsListToggled = computed(() => getters['map/isStopsListToggled']);
        const pinnedStops = computed(() => getters['map/pinnedStops']);
        const activePanel = computed(() => getters['map/activePanel']);
        const isAssetGroup = computed(() => activePanel.value === 'Asset Groups');
        const date = computed(() => getters['map/date']);

        const isFutureDate = computed(() =>
            ref(new Date(date.value).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0))
        );

        const userHasTeamAccess = getters['user/hasTeamAccess'];
        const isIndividualUser = getters['user/isIndividualUser'];

        const isIndividual = !userHasTeamAccess || isIndividualUser;

        const getButtonPlacement = computed(() => {
            let buttonPlacement = '25px';
            if (isLocationHistoryToggled.value) {
                buttonPlacement = '60px';
            }

            if (isStopsListToggled.value) {
                // add 45px to account for the height of the toggled options height and margin
                if (pinnedStops.value.length > 0) {
                    // 1 cell is 35px in height
                    // table headers is 32px in height
                    const computedHeight = pinnedStops.value.length * 35 + 32;
                    buttonPlacement = computedHeight <= 350 ? `${computedHeight + 45}px` : '380px';
                    if (isLocationHistoryToggled.value) {
                        buttonPlacement = computedHeight <= 350 ? `${computedHeight + 80}px` : '415px';
                    }
                } else if (!isAssetGroup.value) {
                    // 150px is the resulting height of the stops overlay
                    buttonPlacement = '195px';
                    if (isLocationHistoryToggled.value) {
                        buttonPlacement = '230px';
                    }
                }
            }
            return buttonPlacement;
        });

        return {
            pinnedUser,
            isLocationHistoryToggled,
            isStopsListToggled,
            getButtonPlacement,
            isIndividual,
            isFutureDate,
            pinnedStops,
            isAssetGroup,
            date
        };
    }
};
</script>

<style lang="scss" scoped>
$themeColor: #2b93ff;

.toggled-options {
    right: 10px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    height: auto;
    display: flex;

    &.individual {
        bottom: 25px;
    }
    &.pinned {
        bottom: var(--bottom-placement);
    }

    button {
        padding: 10px;
        border: none;
        background: transparent;
        cursor: pointer;
        margin: 0 auto;

        &.active {
            ::v-deep .md-icon {
                color: $themeColor;
            }
        }
        &.disabled {
            ::v-deep .md-icon {
                color: #b2b2b2;
                opacity: 0.25;
                cursor: not-allowed !important;
            }
        }
    }
}
</style>
