<!--
The component can be used two ways.
    1) Using option tags inside the slot:
        <ButtonGroup>
            <option value="1">Text</option>
            <option value="2">Text 2</option>
        </ButtonGroup>
    2) Using binding in the `options` property:
        <ButtonGroup :options="options" />
-->
<template>
    <div class="md-group">
        <md-button
            v-for="option in myOptions.value"
            :key="option.value || option.header"
            :class="[className, option.value === selectedValue ? selectedClassName : '']"
            @click="buttonClicked(option.value)"
        >
            {{ option.text }}
        </md-button>
    </div>
</template>
<style lang="scss" scoped></style>
<script>
import { ref, watch, computed } from '@vue/composition-api';

const getOptionsFromVNodes = (vnodes) =>
    vnodes.reduce((opts, vnode) => {
        if (vnode.tag === 'option') {
            const text = vnode.children && vnode.children.length > 0 ? vnode.children[0].text : '';
            // node is an option
            opts.push({
                text,
                ...vnode.data.attrs
            });
        } else {
            // ignore all the rest, but warn that it's not supported

            // eslint-disable-next-line no-console
            console.warn('Passing in default slot anything else than a list of <option> tags is not supported');
        }
        return opts;
    }, []);

export default {
    name: 'ButtonGroup',
    props: {
        options: {
            default: () => [],
            type: Array
        },
        // eslint-disable-next-line vue/require-default-prop
        className: {
            // default: () => 'md-info',
            type: String
        },
        selectedClassName: {
            default: () => 'md-success',
            type: String
        },
        value: {
            default: () => null,
            type: String
        },
        allowNoSelection: {
            default: () => false,
            type: Boolean
        }
    },
    data() {
        return {
            selectedValue: this.value
        };
    },
    setup(props, { slots }) {
        const options = computed({
            get() {
                const slotValues = slots.default ? getOptionsFromVNodes(slots.default()) : [];

                const vv = ref(props.options && props.options.length ? props.options : slotValues);
                return vv;
            },
            // Empty setter in order not to have a console message of 'Write operation failed: computed value is readonly' when we update the option values dynamically.
            set(val) {}
        });

        watch(
            () => props.options,
            () => {
                options.value = props.options;
            }
        );

        return {
            myOptions: options
        };
    },

    mounted() {
        if (!this.allowNoSelection && !this.selectedValue) {
            this.selectedValue = this.myOptions.value[0].value;
        }
    },

    methods: {
        buttonClicked(value) {
            if (this.allowNoSelection && this.selectedValue === value) {
                this.selectedValue = null;
                this.$emit('input', this.selectedValue);
                return;
            }
            this.selectedValue = value;
            this.$emit('input', this.selectedValue);
        }
    }
};
</script>
