<template>
    <div class="team-invite">
        <div class="logo-header">
            <img src="/img/logos/locate2u-logo.png" />
        </div>
        <div v-show="!isLoading">
            <div v-if="!hasError" class="content">
                <div>
                    <p v-if="isLoggedIn && user.inviteeName != null">
                        Hi
                        <span>{{ user.inviteeName }}</span>
                    </p>
                    <p v-if="!isLoggedIn">Hi there</p>
                    <p v-if="isAbleToAcceptInvite">
                        Your friend
                        <span>{{ user.inviterName }}</span>
                        wants you to join their team
                        <span>({{ user.teamCompany }})</span>
                        on Locate2u.
                    </p>
                    <p v-else>
                        You are currently assigned to another Team on Locate2u and will be unable to accept any
                        invitation from another Team.
                    </p>
                    <div v-if="isLoggedIn && isAbleToAcceptInvite">
                        <p>Please click the button below to accept the invite.</p>
                        <button class="custom-btn" @click="acceptInvite">Accept Invitation</button>
                    </div>
                    <div v-if="!isLoggedIn && hasAccount">
                        <p>In order to accept the invitation please log in first.</p>
                        <button class="custom-btn" @click="login">Login</button>
                    </div>
                    <div v-if="!isLoggedIn && !hasAccount">
                        <p>In order to accept the invitation please sign up first.</p>
                        <button class="custom-btn" @click="register">Sign up</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="hasError && !isLoading" class="content">
            <p class="text-center">{{ errorMessage }}</p>

            <div v-if="isLoggedIn">
                <button class="custom-btn" @click="logout">Log out</button>
            </div>
        </div>
        <div v-show="isLoading" class="loading">
            <fade-loader :loading="isLoading" color="#333333" />
            <span>L O A D I N G</span>
        </div>
    </div>
</template>
<script>
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'TeamInvitation',
    components: { FadeLoader },
    mixins: [GeneralMixin],
    data() {
        return {
            user: {
                inviteeName: null,
                inviterName: null,
                teamCompany: null,
                email: null
            },
            hasAccount: false,
            hasError: false,
            errorMessage: null,
            isLoggedIn: false,
            isLoading: true,
            hasTeam: false,
            isAbleToAcceptInvite: true
        };
    },
    async mounted() {
        this.isLoggedIn = await window.auth.isLoggedIn();
        const api = `/api/team-members/invite/${this.$route.params.invitecode}`;
        const payload = {
            method: 'get'
        };
        handleRequests(api, payload, false)
            .then((response) => {
                this.user = response.data.data;
                if (this.user.isExistingUser) {
                    this.hasAccount = true;
                }
                if (this.user.userBelongsToATeam) {
                    this.hasTeam = true;
                }
                this.isAbleToAcceptInvite = this.user.isAbleToAcceptInvite;
                this.isLoading = false;
            })
            .catch((error) => {
                this.hasError = true;
                if (error.status === 404) {
                    this.errorMessage =
                        'The invite code may have expired or is invalid. Please contact the team owner and ask them for a new invite.';
                } else {
                    this.errorMessage = error.data[0].message;
                }
                this.isLoading = false;
            });
    },
    methods: {
        async login() {
            localStorage.setItem('invitationUrl', this.$route.fullPath);
            window.auth.login();
        },
        async logout() {
            this.$store.commit('user/RESET_STATE');
            await window.auth.logout();
            // clear local storage
            window.localStorage.clear();
            this.$router.push({ path: '/login' });
        },
        async register() {
            localStorage.setItem('invitationUrl', this.$route.fullPath);
            window.auth.register();
        },
        async acceptInvite() {
            this.isLoading = true;
            const response = await window.auth.isLoggedIn();

            if (response) {
                try {
                    const api = `/api/team-members/invite/${this.$route.params.invitecode}`;
                    const payload = {
                        method: 'post'
                    };
                    await handleRequests(api, payload, true);
                    this.$_handleLoaderState(true);
                    await this.$store.dispatch('user/FETCH_USER');
                    this.$_handleLoaderState(false);
                    this.$router.push({ path: '/' });
                } catch (error) {
                    this.hasError = true;
                    this.errorMessage = error.data[0].message;
                    this.isLoading = false;
                }
            }
            this.isLoading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';
.logo-header {
    background-color: $locate2u-blue-dark;
    padding: 20px;
    text-align: center;

    img {
        height: 35px;
    }
}

.team-invite {
    background-color: #fff;
    position: relative;
    height: 100vh;
    text-align: center;

    .content {
        padding: 20px;
        color: black;

        p {
            font-size: 20px;
        }
    }

    .loading {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.35);
        z-index: 9999;

        .v-spinner {
            position: absolute;
            top: 40%;
            left: 50%;

            .v-fade {
                background-color: #fff !important;
            }
            .v-fade1,
            .v-fade2,
            .v-fade3,
            .v-fade4,
            .v-fade5,
            .v-fade6,
            .v-fade7 {
                background-color: #fff;
            }
        }

        span {
            position: absolute;
            // margin-top: 50px;
            top: 46%;
            width: 110px;
            left: calc((100% - 100px) / 2);
            text-align: center;
            font-weight: 600;
        }
    }
}
</style>
