var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "team-invite" }, [
    _vm._m(0),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading",
          },
        ],
      },
      [
        !_vm.hasError
          ? _c("div", { staticClass: "content" }, [
              _c("div", [
                _vm.isLoggedIn && _vm.user.inviteeName != null
                  ? _c("p", [
                      _vm._v("\n                    Hi\n                    "),
                      _c("span", [_vm._v(_vm._s(_vm.user.inviteeName))]),
                    ])
                  : _vm._e(),
                !_vm.isLoggedIn ? _c("p", [_vm._v("Hi there")]) : _vm._e(),
                _vm.isAbleToAcceptInvite
                  ? _c("p", [
                      _vm._v(
                        "\n                    Your friend\n                    "
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.user.inviterName))]),
                      _vm._v(
                        "\n                    wants you to join their team\n                    "
                      ),
                      _c("span", [
                        _vm._v("(" + _vm._s(_vm.user.teamCompany) + ")"),
                      ]),
                      _vm._v(
                        "\n                    on Locate2u.\n                "
                      ),
                    ])
                  : _c("p", [
                      _vm._v(
                        "\n                    You are currently assigned to another Team on Locate2u and will be unable to accept any\n                    invitation from another Team.\n                "
                      ),
                    ]),
                _vm.isLoggedIn && _vm.isAbleToAcceptInvite
                  ? _c("div", [
                      _c("p", [
                        _vm._v(
                          "Please click the button below to accept the invite."
                        ),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "custom-btn",
                          on: { click: _vm.acceptInvite },
                        },
                        [_vm._v("Accept Invitation")]
                      ),
                    ])
                  : _vm._e(),
                !_vm.isLoggedIn && _vm.hasAccount
                  ? _c("div", [
                      _c("p", [
                        _vm._v(
                          "In order to accept the invitation please log in first."
                        ),
                      ]),
                      _c(
                        "button",
                        { staticClass: "custom-btn", on: { click: _vm.login } },
                        [_vm._v("Login")]
                      ),
                    ])
                  : _vm._e(),
                !_vm.isLoggedIn && !_vm.hasAccount
                  ? _c("div", [
                      _c("p", [
                        _vm._v(
                          "In order to accept the invitation please sign up first."
                        ),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "custom-btn",
                          on: { click: _vm.register },
                        },
                        [_vm._v("Sign up")]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ]
    ),
    _vm.hasError && !_vm.isLoading
      ? _c("div", { staticClass: "content" }, [
          _c("p", { staticClass: "text-center" }, [
            _vm._v(_vm._s(_vm.errorMessage)),
          ]),
          _vm.isLoggedIn
            ? _c("div", [
                _c(
                  "button",
                  { staticClass: "custom-btn", on: { click: _vm.logout } },
                  [_vm._v("Log out")]
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "loading",
      },
      [
        _c("fade-loader", {
          attrs: { loading: _vm.isLoading, color: "#333333" },
        }),
        _c("span", [_vm._v("L O A D I N G")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-header" }, [
      _c("img", { attrs: { src: "/img/logos/locate2u-logo.png" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }