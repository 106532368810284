var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c("div", { staticClass: "custom-toolbar" }, [
          _c(
            "div",
            { staticClass: "custom-toolbar-start" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "Team Settings",
                    },
                  },
                },
                [_c("h3", { staticClass: "title" }, [_vm._v("Team Settings")])]
              ),
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "   >    " + _vm._s(_vm.$t("menus.setting.businessEntities"))
                ),
              ]),
            ],
            1
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-green" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon card-icon-text" },
                    [
                      _c("md-icon", [_vm._v("account_balance")]),
                      _vm._v(
                        "\n                        Business Entities\n                    "
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("md-card-content", [
                _c("div", { staticClass: "cf-container" }, [
                  _c(
                    "div",
                    { staticClass: "cf-action-container" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-primary md-just-icon md-round",
                          attrs: { title: "Add Business Entity" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleAdd()
                            },
                          },
                        },
                        [_c("md-icon", [_vm._v("add")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.businessEntitiesList.length != 0
                    ? _c(
                        "div",
                        [
                          _c("business-entities-list", {
                            attrs: {
                              "list-of-business-entities":
                                _vm.businessEntitiesList,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.businessEntitiesList.length == 0
                    ? _c("div", [
                        _c("p", { staticClass: "no-result-message" }, [
                          _vm._v("No business entities configured"),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }