<template>
    <span @click.stop="openSmsModal()">
        <md-icon>textsms</md-icon>
        <md-tooltip md-direction="left">
            Send SMS.
        </md-tooltip>
    </span>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import SendSmsModal from './SendSmsModal';

export default {
    name: 'SendSmsButton',
    mixins: [GeneralMixin],
    props: {
        name: {
            type: String,
            default: null
        },
        phone: {
            type: String,
            default: null
        },
        countryCode: {
            type: String,
            default: null
        },
        publicUserId: {
            type: String,
            default: null
        }
    },
    methods: {
        openSmsModal() {
            this.$modal
                .show(SendSmsModal, {
                    name: this.name,
                    phone: this.phone,
                    countryCode: this.countryCode,
                    publicUserId: this.publicUserId
                })
                .then((response) => {
                    this.$modal.hide();
                });
        }
    }
};
</script>
<style lang="scss" scoped>
span {
    text-transform: uppercase;
    cursor: pointer;
}
</style>
