<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item">
                <div v-if="!isReadOnlyUser">
                    <md-button
                        title="Export Shipments"
                        class="md-primary md-just-icon md-round pull-right header-button"
                        @click="exportShipments"
                    >
                        <md-icon>get_app</md-icon>
                    </md-button>
                </div>
                <batch-upload
                    :title="'Import Shipments'"
                    :template-type="'shipments'"
                    :team-members="teamMembers"
                    @batchImported="handleShipmentCreated"
                    v-if="!isReadOnlyUser"
                />

                <create-shipment
                    :team-members="teamMembers"
                    @shipmentCreated="handleShipmentCreated"
                    v-if="!isCustomer && !isSupportStaff"
                />
                <div>
                    <md-button
                        title="Refresh List"
                        class="md-primary md-just-icon md-round pull-right header-button"
                        @click="handleRefresh"
                    >
                        <md-icon>refresh</md-icon>
                    </md-button>
                </div>
                <div>
                    <search-component @onSearch="handleFilterOrSearch" />
                    <shipment-filter-component
                        @onFilterShipments="handleFilterOrSearch"
                        :team-members="() => this.teamMembers"
                        :team-region-id="filters.teamRegionId"
                        :show-quoting-status="teamHasOfferMethodSetup"
                        :date-range="{ fromDate: filters.fromDate, toDate: filters.toDate }"
                        :filter-value="filters"
                    />
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <div
                    v-if="bulkListError.length > 0"
                    class="alert alert-danger alert-dismissible fade show bulk-error-message"
                >
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="closeAlert">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div>
                        <div v-for="e in bulkListError" :key="e.shipmentRef" class="bulk-single-container">
                            <div>
                                {{ e.shipmentRef }}
                            </div>
                            <div>
                                <div v-for="(error, i) in e.errors" :key="i">{{ error.message }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green" v-if="hasMarketplaceAccess">
                        <CardHeaderDropdown
                            :dropdown-options="headerDropdown"
                            @selectedOption="handleSelectedView"
                            :selected-option="headerDropdown[0]"
                        />
                    </md-card-header>
                    <md-card-header class="md-card-header-icon md-card-header-green" v-else>
                        <div class="card-icon running-load-menu">
                            <md-icon>assignment</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content class="body-list">
                        <div
                            :class="['bulk-section', topScrollPosition > 150 ? 'sticky' : '']"
                            v-if="selectedShipments.length"
                        >
                            <div>
                                {{ selectedShipments.length }} shipment{{ selectedShipments.length > 1 ? 's' : '' }}
                                selected.
                            </div>
                            <div>
                                <drop-down :should-close="shouldCloseDropdown">
                                    <span class="status md-warning" slot="title" data-toggle="dropdown">
                                        Change Status
                                    </span>
                                    <ul class="dropdown-menu">
                                        <li v-for="(item, index) in statuses" :key="index">
                                            <a @click.stop="handleChangeShipmentStatus(item)">
                                                {{ item }}
                                            </a>
                                        </li>
                                    </ul>
                                </drop-down>
                                <md-button
                                    v-if="!isSingleTeamMember && !isSingleTeamMember && !isCustomer && !isSupportStaff"
                                    class="md-round md-just-icon md-info"
                                    title="Re-assign driver"
                                    @click="bulkAssign"
                                >
                                    <md-icon>person_pin</md-icon>
                                </md-button>
                                <md-button
                                    class="md-round md-just-icon md-danger"
                                    title="Delete Shipments"
                                    @click="bulkDelete"
                                >
                                    <md-icon>delete</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div v-if="!isListLoading">
                            <md-table class="context-menu-support" id="table-container">
                                <md-table-row>
                                    <md-table-head v-if="!isCustomer && !isSupportStaff">
                                        <md-checkbox
                                            class="shipment-checkbox checkbox-head"
                                            v-model="isSelectAll"
                                            @change="onSelectAll(isSelectAll)"
                                        ></md-checkbox>
                                    </md-table-head>
                                    <TableHeaderDropdown
                                        column-icon="keyboard_arrow_down"
                                        :default-text="tableColumnOptions.columns[columnKeys.referenceSource].name"
                                        :dropdown-options="tableColumnKeyOptions.referenceSource"
                                        :selected-option="tableColumnOptions.columns[columnKeys.referenceSource]"
                                        @selectedOption="
                                            $_handleColumnSwitch(
                                                $event,
                                                columnKeys.referenceSource,
                                                'shipments.defaults.shipment-table-options'
                                            )
                                        "
                                    />
                                    <md-table-head>Assigned To</md-table-head>
                                    <md-table-head v-if="showQuotingStatusColumn" class="hide-column">
                                        Quoting Status
                                    </md-table-head>
                                    <md-table-head>Status</md-table-head>
                                    <md-table-head>When</md-table-head>
                                    <md-table-head>Contact</md-table-head>
                                    <md-table-head class="address-container">Address</md-table-head>
                                    <md-table-head v-if="!isCustomer && !isSupportStaff">Actions</md-table-head>
                                </md-table-row>

                                <md-table-row
                                    v-for="item in shipmentsList"
                                    :key="item.shipmentId"
                                    @click.stop="showShipmentDetailsSidebar(item)"
                                >
                                    <md-table-cell v-if="!isCustomer && !isSupportStaff">
                                        <md-checkbox
                                            class="shipment-checkbox"
                                            :value="item.shipmentId"
                                            v-model="selectedShipments"
                                        ></md-checkbox>
                                    </md-table-cell>
                                    <md-table-cell class="shipment-reference">
                                        <router-link
                                            :to="{
                                                name: 'Shipment Details',
                                                params: { shipmentId: item.shipmentId }
                                            }"
                                            target="_blank"
                                        >
                                            {{
                                                tableColumnOptions.columns[columnKeys.referenceSource].key ===
                                                    'reference-number' ||
                                                item.sourceReference === null ||
                                                item.sourceReference === ''
                                                    ? item.shipmentRef
                                                    : item.sourceReference
                                            }}
                                        </router-link>
                                    </md-table-cell>
                                    <md-table-cell class="status-container">
                                        <AssignShipmentDropdown
                                            v-if="hasActiveCarriers"
                                            :shipment="item"
                                            :team-members="teamMembers"
                                            @assignedUser="onAssignedUser"
                                            @handleError="showErrorModal"
                                        />
                                        <span v-else>
                                            <reassign-shipment-button
                                                v-if="item.assignedTo.publicUserId != null"
                                                type="text"
                                                :item="item"
                                                :team-members="teamMembers"
                                                @assignedUser="onAssignedUser"
                                            />
                                            <reassign-shipment-button
                                                v-else
                                                type="button"
                                                :item="item"
                                                :team-members="teamMembers"
                                                @assignedUser="onAssignedUser"
                                            />
                                        </span>
                                    </md-table-cell>
                                    <md-table-cell
                                        v-if="showQuotingStatusColumn"
                                        class="status-container hide-column quote-status-container"
                                        :class="
                                            !!item.quotingStatus && item.quotingStatus === 'QuotesGathered'
                                                ? 'quote-status'
                                                : ''
                                        "
                                    >
                                        <div
                                            :class="
                                                !!item.quotingStatus
                                                    ? `status-${$_getQuotingStatusBadgeColor(item.quotingStatus)}`
                                                    : ''
                                            "
                                        >
                                            <div
                                                class="quotes-loader"
                                                v-if="item.quotingStatus == 'GatheringQuotes' || item.showLoader"
                                                :class="item.showLoader ? 'started-loader' : ''"
                                            >
                                                <straight-line-loader />
                                            </div>
                                            <div
                                                v-if="!!item.quotingStatus"
                                                class="custom-badge quoting-badge"
                                                :class="`status-${$_getQuotingStatusBadgeColor(item.quotingStatus)}`"
                                            >
                                                <span
                                                    v-if="item.quotingStatus == 'QuotesGathered'"
                                                    @click.stop="handleShowQuotes(item)"
                                                >
                                                    {{ toQuotingStatusDesc(item) }}
                                                </span>
                                                <span v-else>{{ toQuotingStatusDesc(item) }}</span>
                                            </div>
                                        </div>
                                    </md-table-cell>
                                    <md-table-cell class="status-container">
                                        <shipment-status-button
                                            :shipment="item"
                                            @statusUpdated="onChangedShipmentStatus"
                                        />
                                    </md-table-cell>
                                    <md-table-cell
                                        class="trip-date"
                                        v-if="item.dropDate === null || item.pickupDate === item.dropDate"
                                    >
                                        <router-link
                                            v-if="item.tripId && item.carrierTeamId == null"
                                            :to="{
                                                name: 'Trip Details',
                                                params: {
                                                    tripId: item.tripId
                                                }
                                            }"
                                            target="_blank"
                                        >
                                            {{ item.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                        </router-link>
                                        <div v-else>
                                            {{ item.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                        </div>
                                    </md-table-cell>
                                    <md-table-cell
                                        class="trip-date"
                                        v-if="item.dropDate !== null && item.pickupDate !== item.dropDate"
                                    >
                                        <router-link
                                            v-if="item.tripId"
                                            :to="{
                                                name: 'Trip Details',
                                                params: {
                                                    tripId: item.tripId
                                                }
                                            }"
                                            target="_blank"
                                        >
                                            {{ item.pickupDate | dateFormat(DATE_TYPES.standardDate) }}
                                            <strong>-></strong>
                                            {{ item.dropDate | dateFormat(DATE_TYPES.standardDate) }}
                                        </router-link>
                                        <div v-else>
                                            {{ item.pickupDate | dateFormat(DATE_TYPES.standardDate) }}
                                            <strong>-></strong>
                                            {{ item.dropDate | dateFormat(DATE_TYPES.standardDate) }}
                                        </div>
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ item.contact.name }}
                                    </md-table-cell>
                                    <md-table-cell class="address-container">
                                        <div>
                                            <md-tooltip class="tooltip-width" md-direction="top">
                                                <div class="address-text-tooltip">
                                                    <b>Pickup:</b>
                                                    {{
                                                        $_displayAddressName({
                                                            address: item.pickupAddress,
                                                            name: item.pickupName
                                                        })
                                                    }}
                                                </div>
                                                <div class="address-text-tooltip">
                                                    <b>Drop off:</b>
                                                    {{
                                                        $_displayAddressName({
                                                            address: item.dropAddress,
                                                            name: item.dropName
                                                        })
                                                    }}
                                                </div>
                                            </md-tooltip>
                                            <div class="address-text">
                                                {{
                                                    $_displayAddressName({
                                                        address: item.pickupAddress,
                                                        name: item.pickupName
                                                    })
                                                }}
                                            </div>
                                            <div class="address-separator">-></div>
                                            <div class="address-text">
                                                {{
                                                    $_displayAddressName({
                                                        address: item.dropAddress,
                                                        name: item.dropName
                                                    })
                                                }}
                                            </div>
                                        </div>
                                    </md-table-cell>
                                    <md-table-cell class="action-buttons" v-if="!isReadOnlyUser">
                                        <JobOfferButton
                                            record-type="Shipment"
                                            :shipment-details="item"
                                            @assignedUser="onAssignedUser"
                                            @handleError="showErrorModal"
                                            class="job-offer-button"
                                            type="icon"
                                            v-if="showJobOfferButton"
                                        />
                                        <QuotesButton
                                            v-if="teamHasOfferMethodSetup && hasActiveCarriers"
                                            @assignedUser="onAssignedUser"
                                            @handleError="showErrorModal"
                                            class="quotes-button"
                                            :shipment="item"
                                            type="icon"
                                        />
                                        <reassign-shipment-button
                                            v-if="
                                                !isSingleUser && !isSingleTeamMember && !isCustomer && !isSupportStaff
                                            "
                                            type="icon"
                                            :item="item"
                                            :team-members="teamMembers"
                                            @assignedUser="onAssignedUser"
                                        />
                                        <update-shipment-button
                                            v-if="!isCustomer && !isSupportStaff"
                                            :shipment="item.shipmentId"
                                            :team-members="teamMembers"
                                            :carrier-team-id="item.carrierTeamId"
                                            @shipmentUpdated="handleUpdateShipment"
                                        />
                                        <delete-shipment-button
                                            v-if="!isReadOnlyUser"
                                            :shipment-id="item.shipmentId"
                                            :team-members="teamMembers"
                                            @shipmentDeleted="handleDeleteShipment"
                                        />
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <div v-if="shipmentsList.length == 0 && !isLoading">
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                        <div v-else>
                            <div class="shipmentbar-loader">
                                <fade-loader :loading="loading" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <md-card-actions class="page-footer" md-alignment="space-between">
            <div>
                <p v-if="total === pagination.perPage" class="card-category">
                    Page {{ pagination.currentPage }} of many
                </p>
                <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
            </div>
            <pagination
                v-model="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                :enable-first-page-link="true"
                :enable-last-page-link="true"
                @change-page="handleChangePage($event, pagination.perPage)"
            />
        </md-card-actions>
        <transition name="slide">
            <shipment-sidebar
                v-if="showShipmentDetails"
                :shipment-id="shipmentId"
                :data="shipmentDetails"
                :team-members="teamMembers"
                v-click-outside="toggleShipmentDetailsWindow"
                @assignShipment="onAssignedUser"
                @deleteShipment="handleDeleteShipment"
                @updateShipment="handleUpdateShipment"
                @changeShipmentStatus="onChangedShipmentStatus"
                @showAddNotes="handleAddShipmentNotes"
                @closeModal="toggleShipmentDetailsWindow"
            />
        </transition>
    </div>
</template>

<script>
import { PAGINATION_DEFAULTS, STOP_TABLE_HEADER_DISPLAY_OPTION } from '@/utils/defaults';
import {
    STATUS_SHIPMENT_CONSTANTS,
    OFFER_QUOTING_STATUSES,
    REFERENCE_SOURCE_KEY_OPTIONS,
    SHIPMENT_HEADER_DROPDOWN_CONSTANTS
} from '@/utils/constants';
import {
    Pagination,
    SearchComponent,
    QuotesButton,
    JobOfferButton,
    CarrierErrorModal,
    QuotesModal,
    BatchUpload,
    StraightLineLoader,
    TableHeaderDropdown,
    CardHeaderDropdown
} from '@/components';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin, CarrierMixin } from '@/mixins';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { mapGetters } from 'vuex';
import moment from 'moment';
import ShipmentSidebar from './ShipmentSidebar';

import { ShipmentFilterComponent, CreateShipment, AssignShipmentModal } from './components';

import {
    UpdateShipmentButton,
    DeleteShipmentButton,
    ReassignShipmentButton,
    ShipmentStatusButton,
    AssignShipmentDropdown
} from './buttons';

const signalR = require('@aspnet/signalr');

export default {
    name: 'ShipmentList',
    components: {
        ShipmentSidebar,
        Pagination,
        ShipmentFilterComponent,
        SearchComponent,
        CreateShipment,
        BatchUpload,
        FadeLoader,
        UpdateShipmentButton,
        DeleteShipmentButton,
        ReassignShipmentButton,
        ShipmentStatusButton,
        QuotesButton,
        JobOfferButton,
        AssignShipmentDropdown,
        StraightLineLoader,
        TableHeaderDropdown,
        CardHeaderDropdown
    },
    mixins: [GeneralMixin, CarrierMixin],
    async created() {
        this.$_applyColumnSwitchDefaults('shipments.defaults.shipment-table-options', {
            [this.columnKeys.referenceSource]: REFERENCE_SOURCE_KEY_OPTIONS
        });
    },

    async mounted() {
        // this.filters.teamRegionId = this.user.teamRegionId ? this.user.teamRegionId : null; // null='All'
        this.filters.teamRegionId = null; // null='All'
        this.$_handleLoaderState(true);
        const { shipments, totalShipments } = await this.handleFetchingOfShipmentsOnLoad();
        this.assignShipments(shipments, totalShipments || shipments.length);

        if (this.shipmentsList.length > 0 && this.hasMarketplaceAccess) {
            this.setupSignalR();
        }
        this.getTeamMembers();
        this.$_handleLoaderState(false);
        this.createHandleScroll();

        if (this.shipmentsList.length > 0 && !this.isReadOnlyUser) {
            this.setupShipmentSignalR();
        }
    },
    beforeDestroy() {
        if (this.connection) {
            this.connection.invoke('RemoveFromTeamChannel');
        }

        if (this.shipmentHubConnection) {
            this.shipmentHubConnection.invoke('RemoveFromTeamChannel');
        }

        window.removeEventListener('resize', this.onResize);
        document.querySelector('.main-panel').removeEventListener('scroll', this.handleScroll);
    },
    data() {
        return {
            shouldShowAddShipmentNotesModal: false,
            shipmentsList: [],
            showShipmentDetails: false,
            shipmentDetails: {},
            pagination: PAGINATION_DEFAULTS,
            teamMembers: [],
            selectedTeamMemberId: '',
            // selectedCarrierTeamMemberId: null,
            shipmentId: 0,
            status: null,
            loading: true,
            isListLoading: false,
            filters: {
                status: 'Active',
                teamRegionId: null,
                teamMemberPublicUserId: null,
                carrierTeamId: null,
                quotingStatus: null,
                fromDate: moment()
                    .subtract(7, 'd')
                    .format('YYYY-MM-DD'),
                toDate: moment()
                    .add(7, 'd')
                    .format('YYYY-MM-DD')
            },
            statuses: STATUS_SHIPMENT_CONSTANTS,
            shouldCloseDropdown: false,
            selectedShipments: [],
            isSelectAll: false,
            bulkListError: [],
            topScrollPosition: 0,
            brandList: [],
            quotingStatusOptions: OFFER_QUOTING_STATUSES,
            connection: '',
            shipmentHubConnection: '',
            quoteModalStatus: null,
            signalRDate: null,
            ...STOP_TABLE_HEADER_DISPLAY_OPTION(),
            selectedView: null
        };
    },
    watch: {
        selectedStops() {
            if (this.selectedShipments.length === 0) {
                this.bulkListError = [];
            }
        },
        selectedView(value) {
            this.$emit('selectedView', value);
        }
    },
    methods: {
        handleView(value) {
            this.selectedView = value;
        },
        async handleFetchingOfShipmentsOnLoad() {
            const currentPage = Number(this.$route.query.currentPage) || 1;

            const {
                data: { shipments, totalShipments }
            } = await this.fetchShipmentsList(currentPage);

            if (currentPage) {
                this.pagination.currentPage = currentPage;
            }

            return { shipments, totalShipments };
        },
        toggleModal(shouldToggle, modalToToggle) {
            this[modalToToggle] = shouldToggle;
        },
        async getTeamMembers(tripDate = null) {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS', {
                date: tripDate
            });
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                // eslint-disable-next-line nonblock-statement-body-position
                if (!this.filters[e]) delete this.filters[e];
            });
            return this.filters;
        },
        async fetchShipmentsList(pageNumber = 1, itemsPerPage = 50) {
            const endpoint = `/api/shipments/list`;
            const response = await handleRequests(endpoint, {
                params: {
                    pageNumber,
                    itemsPerPage,
                    ...this.cleanFilters()
                }
            });
            return response;
        },
        showShipmentDetailsSidebar(item) {
            this.showShipmentDetails = true;
            this.shipmentId = item.shipmentId;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.$_handleLoaderState(true);
            this.showShipmentDetails = false;
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            const {
                data: { shipments, totalShipments }
            } = await this.fetchShipmentsList(currentPage, perPage);
            const shipmentsToAssign = totalShipments || shipments.length;
            this.assignShipments(shipments, shipmentsToAssign);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            this.$_handleLoaderState(false);
        },
        async handleFilterOrSearch(val) {
            let shouldSearch = false;
            Object.getOwnPropertyNames(val).forEach((q) => {
                shouldSearch = shouldSearch || (val[q] || null) !== (this.filters[q] || null);
            });

            // eslint-disable-next-line nonblock-statement-body-position
            if (!shouldSearch) return; // exit function

            this.pagination.currentPage = 1;
            this.isListLoading = true;

            const originalFilters = { ...this.filters };
            this.filters = Object.assign(this.filters, val);
            try {
                const {
                    data: { shipments, totalShipments }
                } = await this.fetchShipmentsList(1, this.pagination.perPage);
                const shipmentsToAssign = totalShipments || shipments.length;
                this.assignShipments(shipments, shipmentsToAssign);
                this.$router.replace({ path: this.$route.path, query: { currentPage: 1 } });
            } catch (e) {
                let message = 'Failed to fetch shipments';
                const { data } = e || {};
                const [error] = data;
                if (error.message) {
                    const { message: errorMessage } = error;
                    message = errorMessage;
                }

                showErrorMessage(this, message);
                this.filters = originalFilters;
            }
            this.isListLoading = false;
        },
        toggleShipmentDetailsWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown)
                this.showShipmentDetails = !this.showShipmentDetails;
        },
        assignShipments(shipments, totalShipments) {
            this.shipmentsList = shipments;
            this.pagination.total = totalShipments;
        },
        handleAddShipmentNotes(shipmentId) {
            this.shipmentId = shipmentId;
            this.toggleModal(true, 'shouldShowAddShipmentNotesModal');
        },
        handleDeleteShipment(response) {
            this.showShipmentDetails = false;
            this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        onAssignedUser(response) {
            this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        onChangedShipmentStatus(response) {
            this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        handleAddedShipmentNote() {},
        handleShipmentCreated() {
            // after creating a new shipment, fetch the latest list of shipments
            this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        handleUpdateShipment(response) {
            if (response.toLowerCase() === 'ok')
                this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        async exportShipments() {
            this.$_handleLoaderState(true, 'EXPORTING...');
            try {
                const endpoint = `/api/shipments/export`;
                const response = await handleRequests(endpoint, {
                    params: {
                        pageNumber: 1,
                        itemsPerPage: 2000,
                        ...this.cleanFilters()
                    },
                    responseType: 'blob'
                });

                const resData = response.data;
                if (resData.size === 0) {
                    this.$notify({
                        message: 'There are no shipments to export',
                        type: 'danger'
                    });
                } else {
                    const url = window.URL.createObjectURL(new Blob([resData]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'shipmentlist.xlsx');
                    document.body.appendChild(link);
                    link.click();
                }
            } catch (error) {
                const message = 'Error in exporting shipments.';
                showErrorMessage(this, message, error);
            }

            this.$_handleLoaderState(false);
        },
        handleRefresh() {
            this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        createHandleScroll() {
            document.querySelector('.main-panel').addEventListener('scroll', this.handleScroll, true);
        },
        handleScroll(e) {
            this.topScrollPosition = e.target.scrollTop;
        },
        onSelectAll(value) {
            if (value) {
                this.shipmentsList.forEach((shipment) => {
                    this.selectedShipments.push(shipment.shipmentId);
                });
            } else {
                this.selectedShipments = [];
            }
        },
        bulkDelete() {
            if (!this.validateBulkAction()) {
                return;
            }

            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Shipments',
                    body: 'Are you sure you want to delete these shipments?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'DELETING...');
                        const payload = {
                            method: 'delete',
                            data: this.selectedShipments
                        };
                        const api = `/api/shipments/bulk/delete`;
                        try {
                            const response = await handleRequests(api, payload);

                            if (response != null && response.data.length) {
                                this.bulkListError = [];
                                this.bulkListError = response.data;
                            } else {
                                this.$notify({
                                    message: `${this.selectedShipments.length} shipments have been deleted!`,
                                    type: 'success'
                                });

                                if (this.isSelectAll) {
                                    this.isSelectAll = false;
                                }

                                this.selectedShipments = [];
                                this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
                            }
                        } catch (e) {
                            const message = 'Cannot delete shipments.';
                            showErrorMessage(this, message, e);
                        }
                    }
                    this.$_handleLoaderState(false);
                });
        },
        bulkAssign() {
            if (!this.validateBulkAction()) {
                return;
            }

            const firstTeamRegionId = this.shipmentsList.find((x) => x.shipmentId === this.selectedShipments[0])
                .teamRegionId;

            // show the reassign modal then get the publicuserid of the selected user
            this.$modal
                .show(AssignShipmentModal, {
                    shipmentId: this.selectedShipments,
                    teamMembers: this.teamMembers,
                    assignedTo: '',
                    shipmentRef: null,
                    isBulk: true,
                    teamRegionId: firstTeamRegionId
                })
                .then((response) => {
                    const { errors, name } = response;

                    if (errors.length) {
                        this.bulkListError = [];
                        this.bulkListError = errors;
                    } else {
                        let msg = `${this.selectedShipments.length} shipments have been assigned to ${name}!`;
                        if (!name) {
                            msg = `${this.selectedShipments.length} shipments have been un-assigned`;
                        }
                        this.$notify({
                            message: msg,
                            type: 'success'
                        });

                        if (this.isSelectAll) {
                            this.isSelectAll = false;
                        }

                        this.selectedShipments = [];
                        this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
                    }
                });
        },
        handleChangeShipmentStatus(status) {
            if (!this.validateBulkAction()) {
                return;
            }

            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Bulk Change Status',
                    body: `Are you sure you want to change the status of the shipments to ${status}?`,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'UPDATING...');

                        const payload = {
                            method: 'post',
                            data: {
                                shipments: this.selectedShipments,
                                statusChangeInfo: {
                                    newStatus: status
                                }
                            }
                        };
                        try {
                            const api = `/api/shipments/bulk/change-status`;
                            const response = await handleRequests(api, payload);
                            if (response.data.length) {
                                this.bulkListError = [];
                                this.bulkListError = response.data;
                            } else {
                                this.$notify({
                                    message: `${
                                        this.selectedShipments.length
                                    } shipments had their status changed to ${status}!`,
                                    type: 'success'
                                });

                                if (this.isSelectAll) {
                                    this.isSelectAll = false;
                                }

                                this.selectedShipments.forEach((x) => {
                                    const shipment = this.shipmentsList.find((item) => item.shipmentId === x);
                                    this.$set(shipment, 'status', status);
                                    if (this.shipmentDetails) {
                                        this.$set(this.shipmentDetails, 'status', status);
                                    }
                                });
                                this.selectedShipments = [];
                            }
                        } catch (e) {
                            const message = 'Cannot change shipment status.';
                            showErrorMessage(this, message, e);
                            this.resolve(false);
                        }
                    }
                    this.shouldCloseDropdown = true;
                    this.$_handleLoaderState(false);
                });

            // to toggle the context menu
            this.$nextTick(() => {
                this.shouldCloseDropdown = false;
            });
        },
        closeAlert() {
            this.bulkListError = [];
        },
        toQuotingStatusDesc(shipment) {
            if (shipment.quotingStatus) {
                const status = this.quotingStatusOptions.find((x) => x.value === shipment.quotingStatus);
                if (status && status.text === 'Quotes Gathered') {
                    // eslint-disable-next-line eqeqeq, nonblock-statement-body-position
                    if (shipment.activeQuotesCount == 0 || shipment.activeQuotesCount == null) return status.text;

                    return `${shipment.activeQuotesCount} ${status.text}`;
                }

                if (status && status.text !== 'Quotes Gathered') {
                    return status.text;
                }
            }

            return shipment.quotingStatus;
        },
        showErrorModal(history) {
            // eslint-disable-next-line nonblock-statement-body-position
            if (history == null) return;

            this.$modal
                .show(CarrierErrorModal, {
                    carrierName: history.carrierName,
                    error: history.errors
                })
                .then(() => {
                    this.$modal.hide();
                });
        },
        async setupSignalR() {
            const user = await window.auth.getUser();

            this.connection = new signalR.HubConnectionBuilder()
                .withUrl('/api/CarrierHub', {
                    accessTokenFactory: () => {
                        return user.access_token;
                    }
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            try {
                await this.connection.start().then((result) => {
                    this.connection.invoke('JoinToTeamChannel');
                });

                this.connection.onclose(async () => {
                    await this.setupSignalR();
                });

                this.connection.on('GatheringQuotes', this.handleGatheringQuotes);
                this.connection.on('QuotesGenerated', this.handleQuotesGenerated);
                this.connection.on('NewQuote', this.handleNewQuote);
            } catch (err) {
                setTimeout(this.setupSignalR, 5000);
            }
        },
        async setupShipmentSignalR() {
            const userCrendentials = await window.auth.getUser();

            this.shipmentHubConnection = new signalR.HubConnectionBuilder()
                .withUrl('/api/ShipmentHub', {
                    accessTokenFactory: () => {
                        return userCrendentials.access_token;
                    }
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            try {
                await this.shipmentHubConnection.start().then((result) => {
                    this.shipmentHubConnection.invoke('JoinToTeamChannel');
                });

                this.shipmentHubConnection.onclose(async () => {
                    await this.setupShipmentSignalR();
                });

                this.shipmentHubConnection.on('AssignmentStatus', async (message) => {
                    if (message && typeof message === 'string' && message.trim().length !== 0) {
                        this.$notifyWarning(message, 8500);
                    }
                });
            } catch (err) {
                setTimeout(this.setupShipmentSignalR, 5000);
            }
        },
        handleNewQuote(quote, shipmentId) {
            if (quote) {
                const shipment = this.shipmentsList.find((x) => x.shipmentId === shipmentId);
                if (shipment) {
                    this.$set(shipment, 'quotingStatus', 'QuotesGathered');

                    if (quote.error != null) {
                        this.$set(quote, 'createdDate', this.signalRDate);
                        this.$set(shipment, 'errorQuotes', quote);
                    } else {
                        // we only want valid quotes to show in the shipment list
                        shipment.quotes.push(quote);
                        this.$set(shipment, 'activeQuotesCount', (shipment.activeQuotesCount += 1));
                        this.$set(shipment, 'quotes', shipment.quotes);
                        this.$set(quote, 'createdDate', this.signalRDate);
                        this.$set(shipment, 'newQuotes', quote);
                    }
                }
            }
        },
        handleGatheringQuotes(shipmentId) {
            const shipment = this.shipmentsList.find((x) => x.shipmentId === shipmentId);
            if (shipment) {
                this.$set(shipment, 'activeQuotesCount', 0);
                this.$set(shipment, 'showLoader', true);
                this.signalRDate = moment().format();
            }
        },
        handleQuotesGenerated(shipmentId, quotedResults) {
            const shipment = this.shipmentsList.find((x) => x.shipmentId === shipmentId);
            if (shipment) {
                const quotes = shipment.quotes || [];
                quotedResults.forEach((qr) => {
                    const theQuoteIndex = quotes.findIndex((x) => x.tempCarrierQuoteId === qr.tempCarrierQuoteId);
                    if (theQuoteIndex > -1) {
                        quotes[theQuoteIndex].carrierQuoteId = qr.carrierQuoteId;
                    }
                });
                this.$set(shipment, 'finalQuotes', quotes);
                this.$set(shipment, 'showLoader', false);
            }
        },
        validateBulkAction() {
            let isValid = true;
            for (let i = 0; i < this.selectedShipments.length; i++) {
                const shipment = this.shipmentsList.find((x) => x.shipmentId === this.selectedShipments[i]);
                isValid = shipment && !shipment.carrierTeamId;

                if (!isValid) 
                    break;
            }

            if (!isValid) {
                const message = 'Cannot perform bulk action. One or more selected shipments are assigned to a carrier.';
                showErrorMessage(this, message, null);
            }
            return isValid;
        },
        handleShowQuotes(shipment) {
            this.$modal
                .show(QuotesModal, {
                    shipment
                })
                .then((response) => {
                    if (response.type && response.type === 'error') {
                        this.$modal.hide();
                        this.$emit('handleError', response.data);
                    } else {
                        this.$emit('assignedUser', response);
                        this.$modal.hide();
                    }
                });
        },
        handleSelectedView(value) {
            this.$emit('selectedView', value.key);
        }
    },
    computed: {
        headerDropdown() {
            if (this.hasMarketplaceAccess && this.hasActiveCarriers) {
                return SHIPMENT_HEADER_DROPDOWN_CONSTANTS;
            }
            return SHIPMENT_HEADER_DROPDOWN_CONSTANTS.splice(0, 1); // just the Shipment View
        },
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser',
            isCustomer: 'user/isCustomer',
            isLoading: 'isLoading',
            isSingleUser: 'user/isIndividualUser',
            oldJobOffersEnabled: 'user/oldJobOffersEnabled',
            isSingleTeamMember: 'team/isSingleTeamMember',
            hasMarketplaceAccess: 'user/hasMarketplaceAccess',
            hasActiveCarriers: 'team/hasActiveCarriers',
            activeCarriers: 'team/activeCarriers',
            hasOfferToTeamMembersAction: 'custom-actions/hasOfferToTeamMembersAction',
            isSupportStaff: 'user/isSupportStaff'
        }),
        teamHasOfferMethodSetup() {
            return this.hasMarketplaceAccess && this.activeCarriers && this.activeCarriers.length > 0;
        },
        showQuotingStatusColumn() {
            return this.hasMarketplaceAccess && this.activeCarriers && this.activeCarriers.length > 0;
        },
        showJobOfferButton() {
            return this.oldJobOffersEnabled === false && this.hasOfferToTeamMembersAction;
        }
    }
};
</script>

<style lang="scss" scoped>
.no-result-message {
    text-align: center;
    margin-top: 45px;
    font-size: 16px;
}

::v-deep .status-container {
    .md-table-cell-container {
        overflow: visible;
    }
}

.slide-leave-active,
.slide-enter-active {
    transition: 0.3s;
}
.slide-enter {
    transform: translateX(100%);
}
.slide-leave-to {
    transform: translateX(100%);
}

.content {
    margin-top: -15px;
    ::v-deep .md-card.md-theme-default {
        margin-bottom: 0;
    }
}

::v-deep .filter-steps--container {
    margin-top: 6px;

    .filter-steps--choices {
        margin-top: 0;
        padding-bottom: 0;
        > .md-input {
            display: initial;
        }
    }
}

::v-deep .search--container {
    width: 250px;
    float: right;
}

::v-deep .md-table-cell-container {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .md-button.md-round.md-just-icon {
        box-shadow: none;
        vertical-align: middle;
    }
}

.address-container {
    display: none;
}

@media (min-width: 1400px) {
    .address-container {
        display: table-cell;
        ::v-deep .md-table-cell-container {
            max-width: 300px;
        }
    }
}

::v-deep .md-table-cell {
    padding: 10px 8px;
    &:last-child {
        .md-table-cell-container {
            text-align: left !important;
            justify-content: flex-start;
        }
    }
}

::v-deep .md-table-row {
    &.zero-results {
        padding: 16px;
        text-align: center;
    }

    .custom-button-container .custom-a-blue a {
        color: rgba(0, 0, 0, 0.87);
    }
}

::v-deep .md-table-row:hover {
    background-color: #fafafa;
    cursor: pointer;
}

::v-deep .md-toolbar {
    .md-field {
        &:first-child,
        &:last-child {
            margin: 8px;
        }
    }

    .shipments-search {
        padding-bottom: 10px;
        &::before,
        &::after {
            bottom: 10px;
        }
    }
}

::v-deep .md-card-actions {
    padding: 16px;
    &.page-footer {
        padding-top: 0px;
    }
}

.shipment-reference ::v-deep a,
.trip-date ::v-deep a {
    color: rgba(0, 0, 0, 0.87);
}

::v-deep .header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.gray-text {
    color: rgba(0, 0, 0, 0.5);
}

.shipmentbar-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

.action-buttons {
    button,
    ::v-deep button {
        margin: 0 2px;
    }

    button:last-child {
        margin-right: 0;
    }
}
.address-text {
    white-space: nowrap;
    width: 46%;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
}
.address-separator {
    float: left;
    margin-right: 10px;
    font-weight: bold;
}
.icon-warning {
    color: #ff9800 !important;
    cursor: pointer;
    left: 14px;
}
.shipment-checkbox {
    margin: 0;
}
.checkbox-head {
    margin-top: 4px;
}
.body-list {
    position: relative;
    padding-top: 30px;
    .sticky {
        position: fixed;
        top: 0;
        z-index: 9;
        left: 75px;
    }
}
.bulk-section {
    position: absolute;
    top: -18px;
    width: 545px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    background-color: #cfeefa;
    color: #2b93ff;
    font-weight: 400;
    padding: 5px 10px 5px 20px;
    > div {
        width: 60%;
        display: inline-block;
        vertical-align: middle;
        text-align: left;
    }
    > div:last-child {
        width: 40%;
        text-align: right;
        button {
            height: 30px;
            width: 30px;
            min-width: 30px;
        }
        .dropdown {
            width: 120px;
            background-color: #ff9800 !important;
            display: inline-block;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
            height: 30px;
            line-height: 30px;
            margin-top: 6px;
            cursor: pointer;
        }
    }
}

.bulk-error-message {
    z-index: 1 !important;
    > div {
        max-height: 160px;
        overflow: auto;
    }

    .bulk-single-container {
        display: table;
        > div {
            display: table-cell;
        }
        > div:first-child {
            width: 200px;
        }
    }
}
.tooltip-width {
    max-width: 500px;
}
.address-text-tooltip {
    text-align: left;
}

.quotes-warning-icon {
    color: #ff5252 !important;
    font-size: 20px !important;
}

.quotes-button {
    margin-left: 5px;
    vertical-align: middle;
}

.job-offer-button {
    margin-left: 5px;
    vertical-align: middle;
}

::v-deep .status {
    min-width: 110px;
}

.quotes-loader {
    display: inline-block;
    margin-right: 3px;
}

.quote-status-container {
    .quoting-status {
        max-width: 250px;
    }
    > div > div {
        display: inline-block;
        padding: 5px 12px;
        border-radius: 15px;

        .custom-badge {
            max-width: initial;
            padding: 0;
        }
    }
}

.started-loader {
    ::v-deep svg {
        circle {
            fill: #4e79ff;
        }
    }
}

.aggregate-options {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.running-load-head {
    text-align: right;

    ::v-deep .md-table-head-label {
        float: left;
        padding-right: 5px;
    }

    .load-header-text,
    .load-header-text:hover {
        font-size: 1.0625rem;
        padding-left: 0;
        padding-right: 10px;
        font-weight: 300;
        color: #fff !important;
    }

    .icon-arrow {
        font-size: 24px !important;
        margin-top: -2px;
    }

    .open {
        .icon-arrow {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
        }
    }

    .icon-check {
        color: #fff !important;
        font-size: 16px !important;
        float: left;
        height: 19px !important;
    }

    .icon-check-color {
        color: #333333 !important;
    }

    .running-load-menu {
        padding-top: 0;
        .dropdown-menu {
            width: 100%;
            margin-left: 15px;
            margin-top: 6px;

            li {
                border-bottom: 0px;
                padding: 10px;
            }

            li:hover {
                cursor: pointer;
            }

            hr.border {
                border-top: 1px solid #ddd;
            }

            a {
                color: #333333 !important;
                padding: 0px;
                margin: 0px;
                font-size: 12px;
                font-weight: 400;
            }

            a:hover {
                background-color: transparent !important;
                box-shadow: none;
                color: #2b93ff !important;
            }
        }
    }
}
</style>
