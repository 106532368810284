<template>
    <div class="summary-card--section">
        <md-icon class="summary-card--section-icon">done</md-icon>
        <slot></slot>
    </div>
</template>
<script setup>
export default {
    name: 'SummaryCardSection'
};
</script>

<style scoped lang="scss">
.summary-card--section {
    position: relative;
    padding: 0 20px;
}

.md-icon.md-theme-default.md-icon-font {
    color: #fff;
}
.summary-card--section-icon {
    border-radius: 50%;
    color: #fff;
    background: #4caf50;
    position: absolute;
    top: 2%;
    left: 0;
    transform: translateX(-75%);
    scale: 67%;
}
</style>
