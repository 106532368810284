<template>
    <div>
        <div
            class="toggled-options map-panel"
            :class="{ individual: isIndividual, pinned: pinnedUser }"
            :style="{ '--bottom-placement': getButtonPlacement }"
        >
            <div style="position:relative; display:flex;">
                <button
                    @click="toggle('planned_route', isAssetGroup)"
                    :class="{ active: isPlannedRouteToggled, disabled: isAssetGroup }"
                >
                    <md-icon>
                        alt_route
                    </md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Planned route</md-tooltip>
                </button>
                <button
                    @click="toggle('location_history', isFutureDate.value)"
                    :class="{ active: isLocationHistoryToggled, disabled: isFutureDate.value }"
                >
                    <md-icon>
                        history
                    </md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Location history</md-tooltip>
                </button>
                <!--  hide this for now
                <div>
                    <md-icon>location_on</md-icon>
                    <md-tooltip md-direction="top">Location markers</md-tooltip>
                </div> -->
                <button
                    @click="toggle('heartbeats', isFutureDate.value)"
                    :class="{ active: isHeartbeatsToggled, disabled: isFutureDate.value }"
                >
                    <md-icon>
                        favorite
                    </md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Heartbeats</md-tooltip>
                </button>
                <button
                    @click="toggle('stops_list', isFutureDate.value || isAssetGroup)"
                    :class="{ active: isStopsListToggled, disabled: isAssetGroup }"
                >
                    <md-icon>
                        list
                    </md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Stops list</md-tooltip>
                </button>
                <button
                    v-if="geofenceConfiguration === 'TEST'"
                    @click="toggle('stop_status', isFutureDate.value || isAssetGroup)"
                    :class="{ active: isStopStatusToggled, disabled: isFutureDate.value || isAssetGroup }"
                >
                    <md-icon>
                        location_on
                    </md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Stop status</md-tooltip>
                </button>
                <button
                    v-if="geofenceConfiguration === 'TEST'"
                    @click="toggle('geofence', isFutureDate.value || isAssetGroup)"
                    :class="{ active: isGeofenceToggled, disabled: isFutureDate.value || isAssetGroup }"
                >
                    <md-icon>
                        share_location
                    </md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Geofence Events</md-tooltip>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject, computed, watch, onMounted, nextTick } from '@vue/composition-api';

export default {
    name: 'ToggleOptions',
    setup(_, __) {
        const { getters, commit } = inject('vuex-store');

        const geofenceConfiguration = getters['user/geofenceConfiguration'];
        const pinnedUser = computed(() => getters['map/pinnedUser']);
        const isLocationHistoryToggled = computed(() => getters['map/isLocationHistoryToggled']);
        const isStopsListToggled = computed(() => getters['map/isStopsListToggled']);
        const isPlannedRouteToggled = computed(() => getters['map/isPlannedRouteToggled']);
        const isHeartbeatsToggled = computed(() => getters['map/isHeartbeatsToggled']);
        const isStopStatusToggled = computed(() => getters['map/isStopStatusToggled']);
        const isGeofenceToggled = computed(() => getters['map/isGeofenceToggled']);
        const pinnedStops = computed(() => getters['map/pinnedStops']);
        const activePanel = computed(() => getters['map/activePanel']);

        const isAssetGroup = computed(() => activePanel.value === 'Asset Groups');

        const date = computed(() => getters['map/date']);
        const isPastDate = ref(new Date(date.value).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0));
        const isFutureDate = computed(() =>
            ref(new Date(date.value).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0))
        );

        const userHasTeamAccess = getters['user/hasTeamAccess'];
        const isIndividualUser = getters['user/isIndividualUser'];

        const isIndividual = !userHasTeamAccess || isIndividualUser;

        const getButtonPlacement = computed(() => {
            let buttonPlacement = '25px';
            if (isLocationHistoryToggled.value) {
                buttonPlacement = '60px';
            }

            if (isStopsListToggled.value) {
                // add 45px to account for the height of the toggled options height and margin
                if (pinnedStops.value.length > 0) {
                    // 1 cell is 35px in height
                    // table headers is 32px in height
                    const computedHeight = pinnedStops.value.length * 35 + 32;
                    buttonPlacement = computedHeight <= 350 ? `${computedHeight + 45}px` : '380px';
                    if (isLocationHistoryToggled.value) {
                        buttonPlacement = computedHeight <= 350 ? `${computedHeight + 80}px` : '415px';
                    }
                } else if (!isAssetGroup.value) {
                    // 150px is the resulting height of the stops overlay
                    buttonPlacement = '195px';
                    if (isLocationHistoryToggled.value) {
                        buttonPlacement = '230px';
                    }
                }
            }
            return buttonPlacement;
        });

        const toggle = (toToggle, isDisabled) => {
            if (isDisabled) 
                return true;
            switch (toToggle) {
                case 'planned_route':
                    commit('map/TOGGLE_PLANNED_ROUTE', !isPlannedRouteToggled.value);
                    break;
                case 'location_history':
                    commit('map/TOGGLE_LOCATION_HISTORY', !isLocationHistoryToggled.value);
                    break;
                case 'heartbeats':
                    commit('map/TOGGLE_HEARTBEATS', !isHeartbeatsToggled.value);
                    break;
                case 'stops_list':
                    commit('map/TOGGLE_STOPS_LIST', !isStopsListToggled.value);
                    break;
                case 'stop_status':
                    commit('map/TOGGLE_STOP_STATUS', !isStopStatusToggled.value);
                    break;
                case 'geofence':
                    commit('map/TOGGLE_GEOFENCE', !isGeofenceToggled.value);
                    break;
                default:
            }
            return true;
        };

        const handleToggles = () => {
            const { value } = isFutureDate.value;
            if (value) {
                commit('map/TOGGLE_LOCATION_HISTORY', false);
            } else {
                commit('map/TOGGLE_LOCATION_HISTORY', true);
            }
            commit('map/TOGGLE_PLANNED_ROUTE', true);
        };

        watch(date, () => {
            nextTick(() => handleToggles());
        });

        onMounted(() => nextTick(() => handleToggles()));

        return {
            toggle,
            geofenceConfiguration,
            getButtonPlacement,
            isIndividual,
            pinnedUser,
            isPlannedRouteToggled,
            isStopsListToggled,
            isLocationHistoryToggled,
            isHeartbeatsToggled,
            isStopStatusToggled,
            isGeofenceToggled,
            isPastDate,
            isFutureDate,
            isAssetGroup,
            date
        };
    }
};
</script>

<style lang="scss" scoped>
$themeColor: #2b93ff;

.toggled-options {
    right: 60px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    height: auto;
    display: flex;

    &.individual {
        bottom: 25px;
    }
    &.pinned {
        bottom: var(--bottom-placement);
    }

    button {
        padding: 10px;
        border: none;
        background: transparent;
        cursor: pointer;
        margin: 0 auto;

        &.active {
            ::v-deep .md-icon {
                color: $themeColor;
            }
        }
        &.disabled {
            ::v-deep .md-icon {
                color: #b2b2b2;
                opacity: 0.25;
                cursor: not-allowed !important;
            }
        }
    }
}
</style>
