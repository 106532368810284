<template>
    <md-button
        title="Add Shipment"
        :class="useIcon ? 'md-primary md-just-icon md-round' : 'md-primary header-button'"
        @click.stop="createRunShipment"
    >
        <md-icon v-if="useIcon">add</md-icon>
        <span v-else>Add Shipment</span>
    </md-button>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { RUN_SHIPMENT_DETAILS_DEFAULTS } from '@/utils/defaults';
import { mapGetters } from 'vuex';
import { CreateRunShipmentModal } from '../components';

export default {
    name: 'CreateRunShipmentButton',
    mixins: [GeneralMixin],
    props: {
        useIcon: {
            type: Boolean,
            default: () => false
        },
        runScheduleId: {
            type: [String, Number],
            default: () => 0
        },
        selectedMemberId: {
            type: [String, Number],
            default: () => 0
        },
        runScheduleCustomerId: {
            type: [String, Number],
            default: () => 0
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    data() {
        return {
            runShipmentDetails: Object.assign({}, RUN_SHIPMENT_DETAILS_DEFAULTS())
        };
    },
    methods: {
        createRunShipment() {
            this.resetShipmentState();
            this.runShipmentDetails.runScheduleId = this.runScheduleId;
            this.runShipmentDetails.durationMinutes =
                this.user.defaultStopDurationMinutes ?? this.runShipmentDetails.durationMinutes;
            this.runShipmentDetails.pickupRunScheduleStop.runScheduleId = this.runScheduleId;
            this.runShipmentDetails.dropRunScheduleStop.runScheduleId = this.runScheduleId;
            this.$modal
                .show(CreateRunShipmentModal, {
                    runShipmentDetails: this.runShipmentDetails,
                    runScheduleCustomerId: this.runScheduleCustomerId
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.emitShipmentCreated();
                        this.$modal.hide();
                    }
                });
        },
        emitShipmentCreated() {
            this.resetShipmentState();
            this.$emit('runShipmentCreated');
        },
        resetShipmentState() {
            Object.assign(this.runShipmentDetails, RUN_SHIPMENT_DETAILS_DEFAULTS());
        }
    }
};
</script>
