var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c("div", { staticClass: "md-layout-item" }, [
        _c("div", { staticClass: "custom-toolbar" }, [
          _c(
            "div",
            { staticClass: "custom-toolbar-start" },
            [
              _c("WarehouseFilter", {
                attrs: { "team-region-id": _vm.filters.teamRegionId },
                on: { onFilter: _vm.handleSearch },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "custom-toolbar-end" },
            [
              _vm.$root.isDesktop
                ? _c("search-component", { on: { onSearch: _vm.handleSearch } })
                : _vm._e(),
              _c(
                "md-button",
                {
                  staticClass: "md-primary md-just-icon md-round header-button",
                  attrs: { title: "Create Warehouse" },
                  on: { click: _vm.createWarehouse },
                },
                [_c("md-icon", [_vm._v("add")])],
                1
              ),
              _c("input", {
                ref: "fileInput",
                staticStyle: { display: "none" },
                attrs: { type: "file" },
                on: { change: _vm.onFileSelected },
              }),
              _c(
                "md-button",
                {
                  staticClass: "md-primary md-just-icon md-round header-button",
                  attrs: { title: "Import Warehouses" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.fileInput.click()
                    },
                  },
                },
                [_c("md-icon", [_vm._v("publish")])],
                1
              ),
              _c(
                "a",
                {
                  staticClass: "download-btn",
                  attrs: {
                    href: "/sample-batchWarehouseUpload.xlsx",
                    download: "",
                  },
                },
                [_vm._v("Download Template")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "md-layout" },
      [
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            _c(
              "div",
              { staticClass: "error-message-container" },
              [
                _c("batch-upload-error-message", {
                  attrs: {
                    content: _vm.errorMessages,
                    "has-error-message": _vm.hasError,
                  },
                }),
              ],
              1
            ),
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-warning" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("store")])],
                      1
                    ),
                  ]
                ),
                _c("md-card-content", { staticClass: "body-list" }, [
                  _vm.isListLoading
                    ? _c("div", { staticClass: "empty-table" }, [
                        _c(
                          "div",
                          { staticClass: "table-loader" },
                          [
                            _c("fade-loader", {
                              attrs: { loading: true, color: "#333333" },
                            }),
                            _c("span", [_vm._v("LOADING")]),
                          ],
                          1
                        ),
                      ])
                    : _c("div", [
                        _vm.warehousesList.length
                          ? _c(
                              "div",
                              [
                                _c(
                                  "md-table",
                                  {
                                    staticClass:
                                      "context-menu-support custom-paginated-table",
                                  },
                                  [
                                    _c(
                                      "md-table-row",
                                      [
                                        _c("md-table-head", [_vm._v("Name")]),
                                        _c("md-table-head", [
                                          _vm._v("Address"),
                                        ]),
                                        _c("md-table-head", [
                                          _vm._v("Team Region"),
                                        ]),
                                        _c("md-table-head", [
                                          _vm._v("Actions"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._l(_vm.warehousesList, function (item) {
                                      return _c(
                                        "md-table-row",
                                        {
                                          key: item.warehouseId,
                                          staticClass: "warehouse-row",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.showSidebar(
                                                item.warehouseId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "md-table-cell",
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(item.name) +
                                                  "\n\n                                        "
                                              ),
                                              item.isDefaultShipmentPickupStop
                                                ? _c(
                                                    "md-icon",
                                                    {
                                                      staticClass:
                                                        "is-default-icon",
                                                      attrs: {
                                                        title:
                                                          "This warehouse will be used as the default pickup address for shipments",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            verified\n                                        "
                                                      ),
                                                    ]
                                                  )
                                                : item.isDefaultShipmentDropStop
                                                ? _c(
                                                    "md-icon",
                                                    {
                                                      staticClass:
                                                        "is-default-icon",
                                                      attrs: {
                                                        title:
                                                          "This warehouse will be used as the default drop address for shipments",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            bookmark_added\n                                        "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c("md-table-cell", [
                                            _vm._v(_vm._s(item.address)),
                                          ]),
                                          _c("md-table-cell", [
                                            _vm._v(
                                              _vm._s(
                                                item.teamRegion
                                                  ? item.teamRegion.name
                                                  : ""
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "md-table-cell",
                                            { staticClass: "action-buttons" },
                                            [
                                              _c(
                                                "md-button",
                                                {
                                                  staticClass:
                                                    "md-warning md-just-icon md-round",
                                                  attrs: {
                                                    title:
                                                      "Update warehouse details",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.updateWarehouse(
                                                        item.warehouseId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("md-icon", [
                                                    _vm._v("edit"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "md-button",
                                                {
                                                  staticClass:
                                                    "md-danger md-just-icon md-round",
                                                  attrs: {
                                                    title: "Delete warehouse",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.handleDeleteWarehouse(
                                                        item.warehouseId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("md-icon", [
                                                    _vm._v("delete"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _c("div", [
                              _c("p", { staticClass: "no-result-message" }, [
                                _vm._v(
                                  "No results matching your search/filter could be found."
                                ),
                              ]),
                            ]),
                      ]),
                ]),
              ],
              1
            ),
            _c(
              "md-card-actions",
              {
                staticClass: "page-footer",
                attrs: { "md-alignment": "space-between" },
              },
              [
                _c("div", [
                  _vm.total === _vm.pagination.perPage
                    ? _c("p", { staticClass: "card-category" }, [
                        _vm._v(
                          "\n                        Page " +
                            _vm._s(_vm.pagination.currentPage) +
                            " of many\n                    "
                        ),
                      ])
                    : _c("p", { staticClass: "card-category" }, [
                        _vm._v(
                          "Page " +
                            _vm._s(_vm.pagination.currentPage) +
                            " of " +
                            _vm._s(_vm.totalPages)
                        ),
                      ]),
                ]),
                _c("pagination", {
                  staticClass: "pagination-no-border pagination-success",
                  attrs: {
                    "per-page": _vm.pagination.perPage,
                    total: _vm.total,
                  },
                  on: {
                    "change-page": function ($event) {
                      return _vm.handleChangePage(
                        $event,
                        _vm.pagination.perPage
                      )
                    },
                  },
                  model: {
                    value: _vm.pagination.currentPage,
                    callback: function ($$v) {
                      _vm.$set(_vm.pagination, "currentPage", $$v)
                    },
                    expression: "pagination.currentPage",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "transition",
          { attrs: { name: "slide" } },
          [
            _vm.isShowSidebar
              ? _c("warehouse-sidebar", {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.toggleSidebarWindow,
                      expression: "toggleSidebarWindow",
                    },
                  ],
                  attrs: { "warehouse-id": _vm.warehouseId },
                  on: { closeModal: _vm.toggleSidebarWindow },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }