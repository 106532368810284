<template>
    <div class="content" v-if="isTeamOwner">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar md-layout">
                    <div class="md-layout-item md-layout md-small-size-80 md-size-90">
                        <div class="md-layout-item">
                            <DateRangeFilter :is-single-date="false" @onChanged="onDateChanged" />

                            <team-member-invoice-batch-list
                                class="md-layout-item"
                                :date-from="filters.dateFrom"
                                :date-to="filters.dateTo"
                                @selectedBatchChanged="onBatchChanged"
                                @batchListUpdated="onBatchListUpdate"
                            />
                        </div>
                    </div>
                    <div class="md-layout-item">
                        <md-button
                            title="Export Report"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click.stop="exportToExcel"
                        >
                            <md-icon>get_app</md-icon>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card v-if="!reportDataList || reportDataList.length == 0">
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>paid</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div v-if="!reportDataList || reportDataList.length == 0">
                            <h3>Driver Payment Summary</h3>
                            <p>No results to display.</p>
                            <p v-if="!batchList || batchList.length <= 0">
                                *Please Select a date range to get a list of team member invoice batches.
                            </p>
                        </div>
                    </md-card-content>
                </md-card>
                <div v-else v-for="(group, index) in reportDataList.groupDataByTeamRegion" :key="index">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-blue">
                            <div class="card-icon">
                                <md-icon>paid</md-icon>
                            </div>
                        </md-card-header>

                        <md-card-content>
                            <h3 v-if="group[0].region !== null">
                                Driver Payment Summary for
                                <i>{{ group[0].region }}</i>
                            </h3>
                            <h3 v-else>Driver Payment Summary</h3>
                            <div class="report-container">
                                <driver-payment-summary-details
                                    :list="group"
                                    :invoice-currency="reportDataList.currency"
                                />
                            </div>
                        </md-card-content>
                    </md-card>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        No available report.
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { DATE_TYPES } from '@/utils/constants';
import { handleRequests, downloadFile } from '@/helpers';
import { GeneralMixin } from '@/mixins';
import { TeamMemberInvoiceBatchList } from '@/components';
import { DateRangeFilter } from './components';
import DriverPaymentSummaryDetails from './DriverPaymentSummaryDetails';

export default {
    name: 'DriverPaymentSummary',
    components: {
        DateRangeFilter,
        DriverPaymentSummaryDetails,
        TeamMemberInvoiceBatchList
    },
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            isTeamOwner: 'user/isTeamOwner'
        })
    },
    data() {
        return {
            reportDataList: [],
            filters: {
                dateFrom: moment()
                    .subtract(14, 'days')
                    .format(DATE_TYPES.internationalDate),
                dateTo: moment().format(DATE_TYPES.internationalDate),
                driverInvoiceBatchId: null,
                fetchAmount: null
            },
            batchList: []
        };
    },
    methods: {
        async exportToExcel() {
            const endpoint = `/api/reports/driver-payment-summary/export/${this.filters.driverInvoiceBatchId}`;

            if (this.filters.driverInvoiceBatchId == null) {
                return;
            }
            this.$_handleLoaderState(true);

            await handleRequests(endpoint, { responseType: 'blob' })
                .then((response) => {
                    const reportName = `DriverSummaryReport.xlsx`;
                    downloadFile(response.data, reportName);
                })
                .catch((err) => {
                    this.$notify({
                        message: 'Error retrieving reports.',
                        type: 'danger'
                    });
                })
                .finally(() => {
                    this.$_handleLoaderState(false);
                });
        },
        async onDateChanged(val) {
            if (val) {
                this.filters.dateFrom = val.startDate
                    ? moment(val.startDate).format(DATE_TYPES.internationalDate)
                    : null;
                this.filters.dateTo = val.endDate ? moment(val.endDate).format(DATE_TYPES.internationalDate) : null;
            } else {
                this.filters.dateFrom = null;
                this.filters.dateTo = null;
            }
        },
        async onBatchChanged(driverInvoiceBatchId) {
            this.filters.driverInvoiceBatchId = driverInvoiceBatchId;
            if (driverInvoiceBatchId == null) {
                this.reportDataList = null;
                return;
            }

            this.$_handleLoaderState(true);
            const endpoint = `/api/reports/driver-payment-summary/${driverInvoiceBatchId}`;
            const response = await handleRequests(endpoint);
            this.reportDataList = response.data.data;
            this.$_handleLoaderState(false);
        },
        async onBatchListUpdate(list) {
            this.batchList = list;

            if (this.batchList == null) {
                this.reportDataList = null;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.batch-filter {
    display: table-cell;
}
</style>
