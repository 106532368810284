<template>
    <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100" v-if="hasTeam">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.billingDashboard') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>paid</md-icon>
                    </div>
                </md-card-header>

                <md-card-content>
                    <div class="cf-container">
                        <h5>
                            Here you will be able to make changes to your subscriptions.
                        </h5>
                        <md-button class="md-primary" @click="openBillingDashboard">Open Stripe</md-button>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { redirectToBillingPortal } from '@/helpers';
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
// eslint-disable-next-line import/no-dynamic-require
const { config } = require(`../../config/config.${process.env.NODE_ENV}`);

export default {
    name: 'BillingDashboard',
    mixins: [GeneralMixin],
    data() {
        return {
            appPermissions: {},
            stripeRedirect: { redirect: null },
            hasStripeCustomerId: true
        };
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam'
        })
    },
    methods: {
        async openBillingDashboard() {
            await redirectToBillingPortal(config.billing_redirect_url);
        }
    }
};
</script>

<style lang="scss" scoped>
.title {
    display: inline-block;
}

::v-deep {
    .custom-btn {
        margin-right: 10px;
        font-weight: 400;
        height: 40px;
        width: 45%;
    }
}

::v-deep .custom-tab-list {
    .nav-tabs {
        width: calc(100% - 55px);
        flex-wrap: wrap;

        li {
            border-bottom: 0px;
        }
    }
}

.form-wrapper .custom-btn {
    margin-bottom: 0;
    margin-top: 25px;
}

.member-container {
    position: relative;

    .custom-switch {
        position: absolute;
        right: 15px;
        top: -40px;

        .md-switch {
            margin: 0;
            margin-bottom: 10px;
        }
    }
}

.card-icon {
    background: #7069fe !important;
}

.stripe-button {
    display: inline;
    position: center;
}
.no-stripe-text {
    color: Red !important;
}
</style>
