var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam
    ? _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "custom-back-button",
                      attrs: {
                        to: {
                          name: "Proof Of Delivery Configuration",
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("keyboard_backspace"),
                      ]),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Setting"),
                      ]),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Proof Of Delivery Configuration",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v(
                          "\n                           >    " +
                            _vm._s(
                              _vm.$t(
                                "menus.setting.proofOfDeliveryConfiguration"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(
                      "\n                       >    " +
                        _vm._s(
                          _vm.isCreateMode
                            ? "Create"
                            : _vm.proofOfDeliveryTemplate.name
                        ) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "custom-toolbar custom-toolbar-template-name" },
              [
                _c(
                  "div",
                  { staticClass: "custom-toolbar-start" },
                  [
                    _c(
                      "form-wrapper",
                      {
                        staticClass: "form-wrapper",
                        attrs: { validator: _vm.$v },
                      },
                      [
                        _c(
                          "form-group",
                          {
                            attrs: {
                              label: "Template Name",
                              name: "proofOfDeliveryTemplate.name",
                            },
                          },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.proofOfDeliveryTemplate.name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.proofOfDeliveryTemplate,
                                    "name",
                                    $$v
                                  )
                                },
                                expression: "proofOfDeliveryTemplate.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "custom-toolbar-end" }, [
                  _c(
                    "div",
                    { staticClass: "header-button-container" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-button md-primary header-button",
                          on: { click: _vm.validateForm },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.isCreateMode ? "Add" : "Save") +
                              "\n                    "
                          ),
                        ]
                      ),
                      !_vm.isCreateMode &&
                      !_vm.proofOfDeliveryTemplate.isDefault
                        ? _c(
                            "md-button",
                            {
                              staticClass: "md-button md-danger header-button",
                              on: { click: _vm.deleteTemplate },
                            },
                            [
                              _vm._v(
                                "\n                        Delete\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-blue" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("fact_check")])],
                      1
                    ),
                  ]
                ),
                _c(
                  "md-card-content",
                  [
                    _c("proof-of-delivery-template", {
                      ref: "proofOfDeliveryTemplate",
                      attrs: {
                        "proof-of-delivery-template":
                          _vm.proofOfDeliveryTemplate,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }