<!-- eslint-disable no-nested-ternary -->
<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 id="carrierQuestionsMappingModalTitle" class="modal-title">
                {{ `${carrierName} Questions Mapping` }}
            </h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body stop-form-modal">
            <div>
                <CarrierQuestions
                    :questions-mapping="questionsMapping"
                    :booking-questions="bookingQuestions"
                    :carrier-teamid="carrierTeamid"
                    ref="questionMapping"
                    @isInvalid="validationInvalid"
                />
            </div>
        </div>
        <div class="modal-footer">
            <md-button v-if="hasQuestionnaires" class="dialog-button md-primary" @click="updateQuestionsMapping">
                Update
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import CarrierQuestions from './CarrierQuestionsMapping';

export default {
    name: 'CarrierQuestionsMappingModal',
    props: {
        carrierTeamid: { type: [String, Number], default: () => null },
        carrierName: { type: String, default: () => null },
        questionsMapping: { type: Array, default: () => [] },
        bookingQuestions: { type: Array, default: () => [] },
        resolve: { type: Function, default: () => {} }
    },
    components: {
        CarrierQuestions
    },
    mixins: [GeneralMixin],
    data() {
        return {
            isInvalid: false
        };
    },
    computed: {
        hasQuestionnaires() {
            return this.bookingQuestions && this.bookingQuestions.length;
        }
    },
    methods: {
        hideModal() {
            this.$modal.hide();
        },
        validationInvalid(value) {
            this.isInvalid = value;
        },
        async updateQuestionsMapping() {
            this.$refs.questionMapping.isInvalid();

            if (this.isInvalid) {
                return;
            }

            // eslint-disable-next-line array-callback-return, consistent-return
            let mappings = this.$refs.questionMapping.$v.questionMappings.$model.map((x) => {
                if (x.mapping && x.mapping != null) {
                    return {
                        name: x.name ?? null,
                        type: x.type ?? null,
                        mapping: x.mapping,
                        defaultValue:
                            // eslint-disable-next-line no-nested-ternary
                            x.mapping === 'AskWhenBooking' ? null : x.defaultValue ? x.defaultValue.toString() : null,
                        customFieldName: (x.mapping === 'CustomField' ? x.customFieldName : null) ?? null,
                        overridable: (x.mapping === 'CustomField' ? null : x.overridable) ?? null
                    };
                }
            });

            mappings = mappings.filter((item) => {
                return item !== undefined;
            });

            const model = {
                // carrierTeamId: this.carrier.carrierTeamId,
                mappings: mappings == null || mappings.length === 0 ? null : [...mappings]
            };

            this.$_handleLoaderState(true, 'UPDATING...');

            try {
                const api = `/api/teams/carriers/${this.carrierTeamid}/question-field-mapping`;
                const payload = {
                    method: 'put',
                    data: model
                };

                await handleRequests(api, payload);

                this.$notify({
                    message: 'Successfully updated questions mapping.',
                    type: 'success'
                });

                this.resolve({ result: 'ok', data: mappings });
            } catch (e) {
                const message = 'Could not update questions mapping.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.modal-container {
    width: 920px !important;
    max-width: 920px !important;
}
.modal-body {
    width: 920px !important;
    ::v-deep .custom-paginated-table {
        max-height: 38vh;
        min-height: 37vh;
        overflow: auto;

        tbody .md-table-row td {
            border: none;
        }
    }
}

.modal-footer {
    width: 920px !important;
}
</style>
