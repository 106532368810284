<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Create Surcharge Rule</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>

        <form-wrapper class="form-wrapper" :validator="$v.rateDetail">
            <div class="md-layout-item md-size-100">
                <rule-valid-days
                    :current-days="rateDetail.validForWeekDays"
                    :from-date="rateDetail.validFromDate"
                    :to-date="rateDetail.validToDate"
                    @daysSelected="handleDaysSeleceted"
                    ref="ruleValidDays"
                />
                <form-group name="surchargeName" label="Name">
                    <md-input v-model.trim="rateDetail.surchargeName" />
                </form-group>
                <form-group name="surchargePercentage" label="Percentage">
                    <md-input v-model.number="rateDetail.surchargePercentage" />
                </form-group>
                <form-group name="surchargeFlatRate" label="Flat Rate">
                    <md-input v-model.number="rateDetail.surchargeFlatRate" />
                </form-group>
            </div>
        </form-wrapper>

        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="create()">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin, RatesEngineMixin } from '@/mixins';
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';
import { RATE_CHARGING_TYPES } from '@/utils/constants';
import { minLength, maxLength, required, decimal } from 'vuelidate/lib/validators';
import { RuleValidDays } from '@/components';

export default {
    name: 'CreateSurchargeRuleContent',
    components: {
        RuleValidDays
    },
    mixins: [GeneralMixin, RatesEngineMixin],
    props: {
        rateDetail: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        listLength: {
            type: Number,
            default: 0
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        customerId: {
            type: Number,
            default: null
        },
        rateGroupId: {
            type: Number,
            default: null
        },
        driverUserId: {
            type: Number,
            default: null
        },
        driverRuleCreation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            ruleData: {
                type: null,
                rule: 'test',
                customerId: null
            },
            rateChargingTypes: RATE_CHARGING_TYPES
        };
    },
    validations: {
        rateDetail: {
            surchargeName: {
                minLength: minLength(1),
                maxLength: maxLength(100),
                required
            },
            surchargePercentage: {
                decimal
            },
            surchargeFlatRate: {
                decimal
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    mounted() {},
    methods: {
        validateData() {
            if (this.$v.$invalid) {
                return false;
            }

            if (
                (this.$v.rateDetail.surchargeName.$model === null ||
                    this.$v.rateDetail.surchargeName.$model === undefined) &&
                (this.$v.rateDetail.surchargeFlatRate.$model === null ||
                    this.$v.rateDetail.surchargeFlatRate.$model === undefined) &&
                (this.$v.rateDetail.surchargePercentage.$model === null ||
                    this.$v.rateDetail.surchargePercentage.$model === undefined)
            ) {
                return false;
            }

            if (
                (this.$v.rateDetail.surchargeName.$model !== null ||
                    this.$v.rateDetail.surchargeName.$model !== undefined) &&
                (this.$v.rateDetail.surchargeFlatRate.$model === null ||
                    this.$v.rateDetail.surchargeFlatRate.$model === undefined) &&
                (this.$v.rateDetail.surchargePercentage.$model === null ||
                    this.$v.rateDetail.surchargePercentage.$model === undefined)
            ) {
                return false;
            }

            return true;
        },
        async create() {
            const ruleDaysValid = this.$refs.ruleValidDays.validate();
            if (!ruleDaysValid) {
                return;
            }

            if (this.isUpdate) {
                this.updateRuleDetails();
                return;
            }

            if (!this.validateData()) {
                showErrorMessage(this, 'Rule must have valid data', null);
                return;
            }

            if (
                this.$v.rateDetail.surchargeFlatRate.$model === '' ||
                this.$v.rateDetail.surchargeFlatRate.$model === null ||
                this.$v.rateDetail.surchargeFlatRate.$model === undefined
            ) {
                this.rateDetail.surchargeFlatRateEnabled = false;
                this.rateDetail.surchargeEnabled = true;
            } else {
                this.rateDetail.surchargeFlatRateEnabled = true;
                this.rateDetail.surchargeEnabled = false;
            }

            this.$_handleLoaderState(true, 'PROCESSING...');

            const chargeType = this.rateChargingTypes.find((x) => x.key.toLowerCase() === this.$route.params.type);
            const obj = this.$_getRateRuleData(
                chargeType,
                this.user.publicUserId,
                this.rateDetail,
                this.customerId,
                this.listLength,
                this.rateGroupId,
                this.driverRuleCreation,
                this.driverUserId
            );

            const endpoint = '/api/rates/create';
            const payload = {
                method: 'post',
                data: obj
            };

            try {
                const response = await handleRequests(endpoint, payload);

                if (response.status === 200) {
                    this.$notify({
                        message: 'Successfully created rule.',
                        type: 'success'
                    });

                    this.resolve({
                        record: response.data
                    });
                } else {
                    showErrorMessage(this, 'Failed creating rule.', null);
                }
            } catch (error) {
                showErrorMessage(this, error.data, null);
            }

            this.$_handleLoaderState(false);
        },
        async updateRuleDetails() {
            if (!this.validateData()) {
                showErrorMessage(this, 'Rule must have valid data', null);
                return;
            }

            this.$_handleLoaderState(true, 'UPDATING...');

            if (
                this.$v.rateDetail.surchargeFlatRate.$model === '' ||
                this.$v.rateDetail.surchargeFlatRate.$model === null ||
                this.$v.rateDetail.surchargeFlatRate.$model === undefined
            ) {
                this.rateDetail.surchargeFlatRateEnabled = false;
                this.rateDetail.surchargeEnabled = true;
            } else {
                this.rateDetail.surchargeFlatRateEnabled = true;
                this.rateDetail.surchargeEnabled = false;
            }

            const rateId = this.rateDetail.rateRulesId;
            this.rateDetail.condition = null;
            this.rateDetail.currency = null;
            this.rateDetail.priority = null;
            this.rateDetail.rateRulesId = null;

            const obj = {
                rateRulesId: rateId,
                rule: JSON.stringify(this.rateDetail)
            };

            const endpoint = '/api/rates/updateRate';
            const payload = {
                method: 'post',
                data: obj
            };

            try {
                const response = await handleRequests(endpoint, payload);

                if (response.status === 200) {
                    this.$notify({
                        message: 'Successfully updated rule.',
                        type: 'success'
                    });

                    this.resolve({
                        record: response.status
                    });
                } else {
                    showErrorMessage(this, 'Failed updating rule.', null);
                }
            } catch (error) {
                showErrorMessage(this, 'Failed updating rule.', null);
            }

            this.$_handleLoaderState(false);
        },
        handleDaysSeleceted(value) {
            this.rateDetail = Object.assign(this.rateDetail, value);
        }
    }
};
</script>

<style lang="scss" scoped>
.rate-form-container {
    padding-top: 20px;
    max-height: 80vh;
    padding-right: 10px;
}

.form-wrapper {
    width: 100%;
}

.md-table {
    .drag-icon {
        width: 5%;
    }
}

.drag-item {
    cursor: grab;
}

.custom-draggable {
    display: table;
    width: 100%;
    table-layout: fixed;
}
</style>
