var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-select",
    {
      attrs: { disabled: _vm.disabled },
      on: { "md-selected": _vm.getSelectedOption },
      model: {
        value: _vm.selectedOption,
        callback: function ($$v) {
          _vm.selectedOption = $$v
        },
        expression: "selectedOption",
      },
    },
    _vm._l(_vm.optionList, function (item) {
      return _c(
        "md-option",
        { key: item.name, attrs: { value: item.name } },
        [
          _vm._v("\n        " + _vm._s(item.name) + "\n        "),
          _c("md-tooltip", { attrs: { "md-direction": "right" } }, [
            _vm._v("\n            " + _vm._s(item.description) + "\n        "),
          ]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }