<template>
    <div class="search--container">
        <md-field md-clearable @md-clear="handleClear">
            <md-input type="text" :placeholder="placeholder" v-model="searchText" @keyup.enter="handleSearch" />
        </md-field>
        <div class="search-button--container" v-if="searchIcon">
            <md-button class="md-icon-button md-dense md-raised md-primary" @click="handleSearch">
                <md-icon>search</md-icon>
            </md-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchComponent',
    props: {
        handleSearchOnType: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Search'
        },
        searchIcon: {
            type: Boolean,
            default: true
        },
        searchFilter: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            searchText: ''
        };
    },
    mounted() {
        this.searchText = this.searchFilter;
    },
    watch: {
        searchText(newVal) {
            if (this.handleSearchOnType) 
                this.$emit('onSearch', { searchText: newVal });
        },
        searchFilter() {
            this.searchText = this.searchFilter;
        }
    },
    methods: {
        handleClear() {
            this.searchText = '';
            this.handleSearch();
        },
        handleSearch() {
            this.$emit('onSearch', { searchText: this.searchText });
        }
    }
};
</script>

<style lang="scss" scoped>
.search--container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    ::v-deep .md-field {
        box-sizing: border-box;
        margin-top: 0;
        &::before,
        &::after {
            bottom: 10px;
        }
    }
    .search-button--container {
        margin-right: 12px;
        margin-left: 4px;
        display: inline-block;
    }
}
</style>
