var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-spacing" },
    [
      _c(
        "sidebar-item",
        { attrs: { link: { name: "Reports", icon: "assignment" } } },
        [
          _vm.hasReportsAccess
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: "Ignition On/Off",
                    path: "/reports/ignition-report",
                  },
                },
              })
            : _vm._e(),
          _vm.hasReportsAccess
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: "Location History",
                    path: "/reports/location-history-report",
                  },
                },
              })
            : _vm._e(),
          _vm.hasReportsAccess
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: "Safety",
                    path: "/reports/safety-report",
                  },
                },
              })
            : _vm._e(),
          !_vm.isIndividualUser && _vm.hasReportsAccess
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: "Safety Summary",
                    path: "/reports/safety-summary-report",
                  },
                },
              })
            : _vm._e(),
          _vm.hasReportsAccess
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: "Speeding",
                    path: "/reports/speeding-report",
                  },
                },
              })
            : _vm._e(),
          _c("sidebar-item", {
            attrs: {
              link: {
                name: "Stops",
                path: "/reports/stop-report",
              },
            },
          }),
          _c("sidebar-item", {
            attrs: {
              link: {
                name: "Stop Rating Report",
                path: "/reports/stop-rating-report",
              },
            },
          }),
          _c("sidebar-item", {
            attrs: {
              link: {
                name: "Travel Statistics",
                path: "/reports/distance-report",
              },
            },
          }),
          _c("sidebar-item", {
            attrs: {
              link: {
                name: "Geofence Report",
                path: "/reports/geofence-report",
              },
            },
          }),
          _c("sidebar-item", {
            attrs: {
              link: {
                name: "Geofence Timesheet",
                path: "/reports/geofence-timesheet",
              },
            },
          }),
          _vm.isTeamOwner
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: "Driver Payment Summary",
                    path: "/reports/driver-payment-summary",
                  },
                },
              })
            : _vm._e(),
          _vm.isTeamOwner
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: "Sms History",
                    path: "/reports/sms-history",
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }