<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <div class="custom-toolbar md-layout">
                    <div class="md-layout-item custom-toolbar-start md-layout md-small-size-80 md-size-90">
                        <team-members-filter @onSelection="handleTeamRegionMemberChanged" />
                        <div class="md-layout-item">
                            <md-field>
                                <label class="field-label">Geofence:</label>
                                <md-select v-model="filters.geofenceId" @md-selected="getReports">
                                    <md-option :key="`fence`" value="">All</md-option>
                                    <md-option
                                        v-for="(fence, index) in geofenceList"
                                        :key="`fence-${index}`"
                                        :value="fence.geofenceId"
                                    >
                                        {{ toGeofenceNameDesc(fence) }}
                                    </md-option>
                                </md-select>
                            </md-field>
                        </div>

                        <div class="md-layout-item">
                            <DateRangeFilter :is-single-date="false" @onChanged="onDateChanged" />
                        </div>
                    </div>
                    <div class="md-layout-item custom-toolbar-end">
                        <md-button
                            title="Export Geofence report"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click.stop="exportToExcel"
                        >
                            <md-icon>get_app</md-icon>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>summarize</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div v-if="!reportDataList || reportDataList.length == 0">
                            <h3>Geofence Timesheet Report</h3>
                            <p>No results to display.</p>
                        </div>
                        <div v-else>
                            <div class="report-container">
                                <GeofenceTimesheetReportDetails :list="reportDataList" />
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapMutations, mapGetters } from 'vuex';
import GeofenceTimesheetReportDetails from './GeofenceTimesheetReportDetails';
import { handleRequests, SearchGuard, downloadFile } from '../../helpers';
import { DateRangeFilter, TeamMembersFilter } from './components';

export default {
    name: 'GeofenceTimesheetReport',
    components: { GeofenceTimesheetReportDetails, DateRangeFilter, TeamMembersFilter },
    mixins: [GeneralMixin],
    data() {
        return {
            geofenceList: [],
            reportDataList: [],
            filters: {
                trackerId: null,
                publicUserId: null,
                dateFrom: moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD'),
                dateTo: moment().format('YYYY-MM-DD'),
                geofenceId: null,
                teamRegionId: null
            },
            searchGuard: {},
            selectedTeamMember: null,
            smallAlertModalHide: false
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            canViewTeamRegion: 'user/canViewTeamRegion'
        })
    },
    methods: {
        ...mapMutations({
            changeDate: 'map/CHANGE_DATE'
        }),

        async exportToExcel() {
            this.$_handleLoaderState(true);

            const params = { ...this.filters };
            const endpoint = `/api/reports/geofence-timesheet/export-to-excel`;

            await handleRequests(endpoint, {
                params,
                responseType: 'blob'
            })
                .then((response) => {
                    const reportName = `GeofenceTimesheetReport.xlsx`;
                    downloadFile(response.data, reportName);
                })
                .catch((err) => {
                    this.$notify({
                        message: 'Error retrieving reports.',
                        type: 'danger'
                    });
                })
                .finally(() => {
                    this.$_handleLoaderState(false);
                });
        },
        async getReports() {
            const shouldSearch = this.searchGuard.shouldSearch(this.filters);
            if (!shouldSearch) 
                return;

            await this.executeGetReports();
        },
        async executeGetReports() {
            this.$_handleLoaderState(true);

            const params = { ...this.filters };
            const endpoint = `/api/reports/geofence-timesheet/`;

            await handleRequests(endpoint, { params })
                .then((response) => {
                    this.reportDataList = response.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        message: 'Error retrieving reports.',
                        type: 'danger'
                    });
                })
                .finally(() => {
                    this.$_handleLoaderState(false);
                });
        },

        async onDateChanged(val) {
            this.dateFrom = val.startDate;
            this.dateTo = val.endDate;

            if (val) {
                this.filters.dateFrom = val.startDate ? moment(val.startDate).format('YYYY-MM-DD') : null;
                this.filters.dateTo = val.endDate ? moment(val.endDate).format('YYYY-MM-DD') : null;
            } else {
                this.filters.dateFrom = null;
                this.filters.dateTo = null;
            }

            this.getReports();
        },
        async handleTeamRegionMemberChanged(val) {
            if (val) {
                if (val.isTracker) {
                    this.filters.trackerId = val.trackerId;
                    this.filters.publicUserId = null;
                } else {
                    this.filters.trackerId = null;
                    this.filters.publicUserId = val.userId;
                }

                this.filters.teamRegionId = val.teamRegionId;
            } else {
                this.filters.publicUserId = null;
                this.filters.trackerId = null;
                this.filters.teamRegionId = null;
            }

            this.getReports();
        },
        async getGeofences() {
            this.$_handleLoaderState(true);

            const endpoint = `/api/geofence/list?includeDeleted=true`;
            await handleRequests(endpoint)
                .then((response) => {
                    this.geofenceList = response.data;
                })
                .catch((err) => {
                    this.$notify({
                        message: 'Error retrieving geofence list.',
                        type: 'danger'
                    });
                })
                .finally(() => {
                    this.$_handleLoaderState(false);
                });
        },
        async getTeamMembers() {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS');
        },
        toGeofenceNameDesc(item) {
            if (item.status) {
                return `${item.name} (${item.status})`;
            }
            return item.name;
        }
    },
    async mounted() {
        this.filters.teamRegionId = this.user.teamRegionId ? this.user.teamRegionId : null; // null = All
        this.searchGuard = new SearchGuard({ ...this.filters });

        Promise.all([this.getGeofences(), this.getTeamMembers()]);
        await this.executeGetReports();
    }
};
</script>

<style scoped>
.header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.multiple-selection-checkbox .md-checkbox.md-theme-default .md-checkbox-container {
    border-color: rgba(0, 0, 0, 0.54) !important;
}

.multiple-selection-checkbox .md-checkbox {
    margin-left: unset !important;
}

.multiple-selection-checkbox .md-list-item-text {
    left: 35px !important;
}

.alert-z {
    z-index: 0 !important;
}
</style>
