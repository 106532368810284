<template>
    <div class="cf-container">
        <div class="cf-action-container">
            <md-button title="Save rate types" class="md-primary md-just-icon md-round" @click.stop="saveChanges">
                <md-icon>save</md-icon>
            </md-button>
        </div>
        <md-table class="custom-paginated-table" v-if="!isListLoading">
            <md-table-row>
                <md-table-head>Type</md-table-head>
                <md-table-head>Income Account Code</md-table-head>
                <md-table-head>Income Tax Type</md-table-head>
                <md-table-head>Expense Account Code</md-table-head>
                <md-table-head>Expense Tax Type</md-table-head>
            </md-table-row>

            <md-table-row v-for="(item, index) in rateTypes" :key="index">
                <md-table-cell>
                    <span>{{ $t(item.type) }}</span>
                </md-table-cell>
                <md-table-cell>
                    <form-group class="pod-dropdown account-code-dropdown" v-if="accountCodeList.length">
                        <md-select v-model="item.rateTypeConfig.incomeAccountCode">
                            <md-option :value="''">N/A</md-option>
                            <md-option
                                v-for="accountCode in accountCodeList"
                                :key="accountCode.code"
                                :value="accountCode.code"
                            >
                                {{ accountCode.code }}
                            </md-option>
                        </md-select>
                    </form-group>
                </md-table-cell>
                <md-table-cell>
                    <form-group class="pod-dropdown" v-if="taxSettings.length">
                        <md-select v-model="item.rateTypeConfig.incomeTaxSettingsId">
                            <md-option :value="''">N/A</md-option>
                            <md-option v-for="tax in taxSettings" :key="tax.taxSettingsId" :value="tax.taxSettingsId">
                                {{ tax.type }} ({{ tax.description }})
                            </md-option>
                        </md-select>
                    </form-group>
                </md-table-cell>
                <md-table-cell>
                    <form-group class="pod-dropdown account-code-dropdown" v-if="accountCodeList.length">
                        <md-select v-model="item.rateTypeConfig.expenseAccountCode">
                            <md-option :value="''">N/A</md-option>
                            <md-option
                                v-for="accountCode in accountCodeList"
                                :key="accountCode.code"
                                :value="accountCode.code"
                            >
                                {{ accountCode.code }}
                            </md-option>
                        </md-select>
                    </form-group>
                </md-table-cell>
                <md-table-cell>
                    <form-group class="pod-dropdown" v-if="taxSettings.length">
                        <md-select v-model="item.rateTypeConfig.expenseTaxSettingsId">
                            <md-option :value="''">N/A</md-option>
                            <md-option v-for="tax in taxSettings" :key="tax.taxSettingsId" :value="tax.taxSettingsId">
                                {{ tax.type }} ({{ tax.description }})
                            </md-option>
                        </md-select>
                    </form-group>
                </md-table-cell>
            </md-table-row>
        </md-table>
        <div v-else>
            <div class="stopbar-loader">
                <fade-loader :loading="true" color="#333333" />
                <span>LOADING</span>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';

export default {
    name: 'RateTypeSettings',
    components: {
        FadeLoader
    },
    mixins: [GeneralMixin],
    data() {
        return {
            accountCodeList: [],
            isListLoading: false,
            taxSettings: [],
            rateTypes: []
        };
    },
    async mounted() {
        this.rateTypes = await this.getRateTypes();
        this.accountCodeList = await this.getAccountCodeList();
        this.taxSettings = await this.getTaxSettings();
    },
    methods: {
        async getAccountCodeList() {
            let response = {
                data: []
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/team-settings/account-codes`;

                response = await handleRequests(endpoint);
            } catch (error) {
                const message = 'Error in getting the account code list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;
            return response.data;
        },
        async getTaxSettings() {
            let response = {
                data: []
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/team-settings/tax-settings`;

                response = await handleRequests(endpoint);
            } catch (error) {
                const message = 'Error in getting the tax settings list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;
            return response.data ?? [];
        },
        async getRateTypes() {
            let response = {
                data: []
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/teams/rate-types`;

                response = await handleRequests(endpoint);
            } catch (error) {
                const message = 'Error in getting the rateTypes';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;

            response.data.forEach((rt) => {
                if (rt.rateTypeConfig.incomeAccountCode === null) {
                    rt.rateTypeConfig.incomeAccountCode = '';
                }

                if (rt.rateTypeConfig.expenseAccountCode === null) {
                    rt.rateTypeConfig.expenseAccountCode = '';
                }

                if (rt.rateTypeConfig.incomeTaxSettingsId === null) {
                    rt.rateTypeConfig.incomeTaxSettingsId = '';
                }

                if (rt.rateTypeConfig.expenseTaxSettingsId === null) {
                    rt.rateTypeConfig.expenseTaxSettingsId = '';
                }
            });
            return response.data;
        },
        async saveChanges() {
            this.$_handleLoaderState(true, 'SAVING...');

            const data = JSON.parse(JSON.stringify(this.rateTypes));

            data.forEach((rt) => {
                if (rt.rateTypeConfig.incomeAccountCode === '') {
                    rt.rateTypeConfig.incomeAccountCode = null;
                }

                if (rt.rateTypeConfig.expenseAccountCode === '') {
                    rt.rateTypeConfig.expenseAccountCode = null;
                }

                if (rt.rateTypeConfig.incomeTaxSettingsId === '') {
                    rt.rateTypeConfig.incomeTaxSettingsId = null;
                }

                if (rt.rateTypeConfig.expenseTaxSettingsId === '') {
                    rt.rateTypeConfig.expenseTaxSettingsId = null;
                }
            });

            const payload = {
                method: 'post',
                data
            };

            const api = '/api/teams/rate-types';
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully updated rate types',
                    type: 'success'
                });
                this.rateTypes = await this.getRateTypes();
                this.$_handleLoaderState(false);
            } catch (e) {
                this.$_handleLoaderState(false);
                const message = 'Error in saving rate types';
                showErrorMessage(this, message, e);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.cf-container {
    min-height: 200px;
}

.stopbar-loader {
    min-height: 400px;
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

.pod-dropdown {
    max-width: 250px;
    margin-top: 0;
    border: 1px solid #ddd;
    padding: 0;
    padding-left: 10px;
}

.account-code-dropdown {
    width: 120px;
}

.pod-checkbox:after,
.pod-checkbox:before {
    height: 0;
}

.pod-dropdown:after,
.pod-dropdown:before {
    height: 0;
}
</style>
