var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar",
      attrs: {
        "data-color": "blue",
        "data-background-color": _vm.backgroundColor,
      },
      on: { mouseenter: _vm.toggleLogo, mouseleave: _vm.toggleLogo },
    },
    [
      _c("div", { staticClass: "logo" }, [
        !_vm.showLogo
          ? _c(
              "a",
              {
                staticClass: "simple-text logo-mini",
                attrs: { href: "https://www.locate2u.com/", target: "_blank" },
              },
              [
                _vm.logo
                  ? _c("div", { staticClass: "logo-img" }, [
                      _c("img", { attrs: { src: _vm.logo } }),
                    ])
                  : _c("div", { staticClass: "logo-img" }, [
                      _c("img", {
                        attrs: {
                          src: "/img/logos/locate2u-logo-thumbnail.png",
                        },
                      }),
                    ]),
              ]
            )
          : _c(
              "a",
              {
                staticClass: "simple-text logo-normal",
                attrs: { href: "https://www.locate2u.com/", target: "_blank" },
              },
              [
                _vm.isCustomerType
                  ? [
                      _vm.logo
                        ? _c("img", {
                            staticClass: "logo-complete",
                            attrs: { src: _vm.logo },
                          })
                        : _c("img", {
                            staticClass: "logo-complete",
                            attrs: { src: "/img/logos/locate2u-logo-dark.png" },
                          }),
                    ]
                  : [
                      _c("img", {
                        staticClass: "logo-complete",
                        attrs: { src: "/img/logos/locate2u-logo.png" },
                      }),
                    ],
              ],
              2
            ),
        _c(
          "div",
          { staticClass: "navbar-minimize" },
          [
            _c(
              "md-button",
              {
                staticClass: "md-round md-just-icon md-transparent",
                attrs: { id: "minimizeSidebar" },
                on: { click: _vm.minimizeSidebar },
              },
              [
                _c(
                  "i",
                  {
                    staticClass:
                      "material-icons text_align-center visible-on-sidebar-regular",
                  },
                  [_vm._v("\n                    more_vert\n                ")]
                ),
                _c(
                  "i",
                  {
                    staticClass:
                      "material-icons design_bullet-list-67 visible-on-sidebar-mini",
                  },
                  [_vm._v("\n                    view_list\n                ")]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          ref: "sidebarScrollArea",
          staticClass: "sidebar-wrapper",
          class: !_vm.showLogo ? "mini-sidebar" : "max-sidebar",
        },
        [
          _vm._t("default"),
          _c(
            "md-list",
            { staticClass: "nav" },
            [
              _vm._t(
                "links",
                _vm._l(_vm.sidebarLinks, function (link, index) {
                  return _c(
                    "sidebar-item",
                    { key: link.name + index, attrs: { link: link } },
                    _vm._l(link.children, function (subLink, i) {
                      return _c("sidebar-item", {
                        key: subLink.name + i,
                        attrs: { link: subLink },
                      })
                    }),
                    1
                  )
                })
              ),
            ],
            2
          ),
          _vm.appVersion
            ? _c(
                "div",
                { class: _vm.showLogo ? "version-text" : "version-text-min" },
                [
                  _c("a", { attrs: { href: _vm.appVersionLink } }, [
                    _vm.showLogo ? _c("span", [_vm._v("Locate2u")]) : _vm._e(),
                    _vm._v(
                      "\n                v" +
                        _vm._s(_vm.appVersion) +
                        "\n            "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }