<template>
    <drop-down
        class="custom-button-container"
        :should-close="shouldCloseDropdown"
        @closeDropdown="(val) => (this.shouldCloseDropdown = val)"
        @closeOtherDropDown="closeOtherDropDown"
    >
        <md-button title="Re-assign driver" class="md-info md-just-icon md-round custom-btn">
            <md-icon>person_pin</md-icon>
        </md-button>
        <ul class="dropdown-menu" v-if="this.isAssignedStops">
            <li v-for="(item, index) in orderedStopList" :key="index">
                <a @click.stop="handleReassignStop(item)">
                    {{ item.teamMember.fullName }}
                </a>
            </li>
        </ul>
        <ul class="dropdown-menu" v-else>
            <li v-for="(item, index) in teamMembers" :key="index">
                <a @click.stop="handleReassignStop(item)">
                    {{ item.fullName }}
                </a>
            </li>
        </ul>
    </drop-down>
</template>

<script>
export default {
    name: 'ReassignStopButton',
    props: {
        teamMembers: {
            type: Array,
            default: () => []
        },
        isAssignedStops: {
            type: Boolean,
            default: false
        },
        stopId: {
            type: [Number, String],
            default: () => null
        },
        vehicleId: {
            type: String,
            default: () => null
        },
        allocateStops: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            shouldCloseDropdown: false
        };
    },
    methods: {
        handleReassignStop(record) {
            this.shouldCloseDropdown = true;
            if (this.isAssignedStops) {
                this.$emit('reassignedStop', record, this.stopId, this.vehicleId);
            } else {
                const vehicleData = this.orderedStopList.filter((x) => x.vehicleId === this.vehicleId);
                if (vehicleData.length) {
                    const vehicle = vehicleData[0];
                    vehicle.newVehicleId = record.publicUserId;
                    this.$emit('reassignedStop', vehicle, this.stopId, this.vehicleId);
                }
            }
        },
        closeOtherDropDown() {
            const dropdowns = document.querySelectorAll('.open');
            dropdowns.forEach((dropdown) => dropdown.classList.remove('open'));
        }
    },
    computed: {
        orderedStopList() {
            return this.allocateStops.slice().sort((x, y) => (x.teamMember.fullName > y.teamMember.fullName ? 1 : -1));
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    background-color: #2b93ff !important;
    color: #fff !important;
    border: none;
    max-width: 29px;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    text-transform: initial;
    height: 29px !important;
    padding: 0;
    font-weight: 300;
    border-radius: 30px;
}

.custom-btn:hover,
.custom-btn:focus {
    color: #fff !important;
    background-color: #0076f0 !important;
}

.custom-a-blue a {
    color: #0076f0;
}
.dropdown-menu {
    max-width: 250px;
    max-height: 210px;
    overflow: auto;
}
.unassignstop-table .dropdown-menu {
    left: -125px;
    z-index: 1000;
}
</style>
