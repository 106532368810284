<template>
    <div class="map-overlay">
        <div id="toggleRoute" class="trip-container">
            <div>
                <md-button class="md-round md-just-icon md-transparent toggle-icon" @click="toggleRouteWindow()">
                    <md-icon id="routeOpenButton" class="show-icon">
                        more_vert
                    </md-icon>
                    <md-icon id="routeCloseButton" class="hide-icon">
                        view_list
                    </md-icon>
                </md-button>
            </div>
            <div class="route-container">
                <h3 class="summary-title">Summary</h3>
                <div id="routeTableContainer" class="table-wrapper">
                    <md-table class="trip-table table-container">
                        <md-table-row>
                            <md-table-head>Driver</md-table-head>
                            <md-table-head>Stops</md-table-head>
                        </md-table-row>
                        <md-table-row v-for="(trip, index) in trips" :key="index">
                            <md-table-cell class="allocatestop">
                                <span :style="{ background: trip.color }">&nbsp;</span>
                                {{ trip.driverName }}
                            </md-table-cell>
                            <md-table-cell class="allocatestop-number">
                                {{ trip.stops.length }}
                            </md-table-cell>

                            <md-table-cell class="allocatestop-pin">
                                <a
                                    v-if="trip.stops.length > 0"
                                    href="#"
                                    @click="handleClickedRouteLine(trip)"
                                    :class="{ active: trip.isPinned }"
                                >
                                    <md-icon class="route-pin">
                                        push_pin
                                    </md-icon>
                                </a>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                </div>
            </div>
            <div class="trip-footer" />
        </div>
        <div id="map_canvas" :class="isTrialExpired ? 'not-paid-map-overlay' : 'trip-map'" />
        <info-window-component
            :content="infoWindowContent"
            :styles="infoWindowStyles"
            :show-info-window="showInfoWindow"
            @closeInfoWindow="
                () => {
                    this.showInfoWindow = false;
                }
            "
        />
    </div>
</template>

<script>
import { MarkerPins } from '@/utils/MarkerPins';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { MapBoxMixin } from '@/mixins/MapBoxMixin';
import { MapOverviewMixin } from '@/mixins/MapOverviewMixin';
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import { MarkerColorCode } from '@/utils/MarkerColorCode';
// eslint-disable-next-line import/extensions
import InfoWindowComponent from '@/pages/Map/InfoWindow.vue';

export default {
    name: 'TripMapRoutes',
    mixins: [MapOverviewMixin, MapBoxMixin, GeneralMixin],
    components: {
        InfoWindowComponent
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isTrialExpired: 'team/isTrialExpired'
        })
    },
    data() {
        return {
            map: null,
            bounds: null,
            drawingManager: null,
            currentDrawingShape: null,
            drawingPolygons: [],
            drawnShapeStops: [],
            skillOptions: [],
            selectedDate: this.$route.params.date
                ? this.$route.params.date
                : moment().format(DATE_TYPES.internationalDate),
            trips: [],
            markerPins: {},
            stopMarkers: [],
            mapboxEventListeners: [],
            pinnedTrips: [],
            showInfoWindow: false,
            infoWindowContent: {},
            infoWindowStyles: {}
        };
    },
    async mounted() {
        this.initializeMap();
        await this.fetchTripDate();
    },
    methods: {
        async fetchTripDate() {
            this.$_handleLoaderState(true);
            const api = `/api/trips/routes/${this.selectedDate}`;

            try {
                const { data } = await handleRequests(api);
                this.trips = data;

                if (this.trips.length > 0) {
                    this.trips.forEach((x, index) => {
                        this.markerPins = Object.assign(this.markerPins, {
                            [x.vehicleId]: MarkerColorCode[index % MarkerColorCode.length]
                        });

                        x.color = this.markerPins[x.vehicleId].color;
                    });

                    // This is based on any trip that has routePlyline data
                    const tripsWithLatLngs = this.trips.filter((trip) => trip.coordinates.length > 0);
                    this.createRouteMapDataFromTrip(tripsWithLatLngs);

                    // BELOW FOR FUTURE RELEASE
                    // create routes based on no routePlyline, this is based on stops lng lat
                    // taken out until we can figure what to do here.
                    // const stopsWithLnglats = this.trips.filter((trip) => trip.coordinates.length === 0 && trip.stops.length > 0);
                    // this.createRouteMapDataFromStops(stopsWithLnglats);

                    try {
                        this.$_mapBox_callMapFunction(this.map, 'panTo', [
                            this.trips[0].startLocation.longitude,
                            this.trips[0].startLocation.latitude
                        ]);
                    } catch {
                        // eslint-disable-next-line no-empty
                    }
                }
            } catch (error) {
                const message = 'Error in loading data';
                showErrorMessage(this, message, error);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        initializeMap() {
            // get starting location of user. if there is none, default to Australia
            const { latitude, longitude } = this.user.startLocation
                ? this.user.startLocation
                : getRegionCoordinates('AU');

            this.map = this.$_mapBox_createMap('map_canvas', latitude, longitude, null, this.callRoutingInterval);
            this.drawingManager = this.$_map_drawingManager();
            this.map.addControl(this.drawingManager);

            this.drawnShapeStops = [];
            this.skillOptions = this.user.skillOptions;
        },
        createRouteMapDataFromTrip(trips) {
            trips.forEach((x) => {
                const pins = this.markerPins[x.vehicleId];

                const options = {
                    strokeColor: pins.color,
                    strokeWeight: 3,
                    strokeOpacity: 1,
                    zIndex: 3
                };

                const path = x.coordinates.map((co) => [co.latitude, co.longitude]);
                let routeLine = this.$_mapBox_createRouteData(path);
                routeLine = [routeLine];
                x.stopMarkers = [];

                this.$_mapBox_createRouteLine(routeLine, this.map, x.vehicleId, options);

                let vehicleEventListener = null;

                if (this.mapboxEventListeners.length > 0) {
                    vehicleEventListener = this.mapboxEventListeners.find((x) => x === x.vehicleId);
                }

                if (!vehicleEventListener) {
                    this.map.on('click', x.vehicleId, (e) => {
                        this.handleClickedRouteLine(x);
                    });

                    this.mapboxEventListeners.push(x.vehicleId);
                }
            });
        },
        createRouteMapDataFromStops(tripStops) {
            tripStops.forEach((x) => {
                const pins = this.markerPins[x.vehicleId];

                const options = {
                    strokeColor: pins.color,
                    strokeWeight: 3,
                    strokeOpacity: 1,
                    zIndex: 3
                };
                const path = [];

                x.stops.forEach((stop) => {
                    path.push([stop.location.latitude, stop.location.longitude]);
                });

                let routeLine = this.$_mapBox_createRouteData(path);
                routeLine = [routeLine];

                this.$_mapBox_createRouteLine(routeLine, this.map, x.vehicleId, options);
            });
        },
        toggleRouteWindow() {
            const slidingWindow = document.getElementById('toggleRoute');
            const toggleCloseButton = document.getElementById('routeCloseButton');
            const toggleOpenButton = document.getElementById('routeOpenButton');
            const routeTableContainer = document.getElementById('routeTableContainer');

            slidingWindow.classList.toggle('closed');
            toggleCloseButton.classList.toggle('show-icon');
            toggleCloseButton.classList.toggle('hide-icon');

            toggleOpenButton.classList.toggle('hide-icon');
            toggleOpenButton.classList.toggle('show-icon');

            routeTableContainer.classList.toggle('hide-icon');
            routeTableContainer.classList.toggle('show-icon');
        },
        async handleClickedRouteLine(trip) {
            this.trips.find((x) => x.tripId === trip.tripId).isPinned = !trip.isPinned;
            this.setStopMarkers(trip, true);
            this.setDriverMarker(trip);
            this.updatePinnedTripsColour();
        },
        updatePinnedTripsColour() {
            this.trips.forEach((t) => {
                if (!t.isPinned) {
                    this.$_mapBox_hideRoute(t.vehicleId, this.map);
                } else {
                    const pins = this.markerPins[t.vehicleId];
                    this.$_mapBox_showRoute(t.vehicleId, this.map, pins.color);
                }
            });

            if (!this.trips.find((t) => t.isPinned)) {
                this.trips.forEach((t) => {
                    const pins = this.markerPins[t.vehicleId];
                    this.$_mapBox_showRoute(t.vehicleId, this.map, pins.color);
                });
            }
        },
        setStopMarkers(trip, fitBounds) {
            if (trip.isPinned) {
                this.pinnedTrips.push(trip);
                const pins = this.markerPins[trip.vehicleId];

                if (trip.stopMarkers.length === 0) {
                    const isSameLocation = trip.startAddress === trip.endAddress;
                    this.setStopStartEndMarkers(trip, pins, 'startLocation', isSameLocation);

                    /* eslint-disable no-unused-vars */
                    const sameMarkerIndexes = [];
                    trip.stops.forEach((stop, stopIndex) => {
                        const sameMarkers = trip.stops.filter(
                            (m) =>
                                m.location.latitude === stop.location.latitude &&
                                m.location.longitude === stop.location.longitude
                        );

                        // if there is more than one marker with the same coordinates
                        if (sameMarkers.length > 1) {
                            let offset = 0;
                            /* eslint-disable no-unused-vars */
                            const grpIndex = sameMarkerIndexes.find(
                                (x) => x.lat === stop.location.latitude && x.lng === stop.location.longitude
                            );
                            if (grpIndex) {
                                // calculate the offset for the current marker
                                offset = 13 * (grpIndex.index - (sameMarkers.length - 1) / 2);
                                grpIndex.index += 1;
                            } else {
                                sameMarkerIndexes.push({
                                    lat: stop.location.latitude,
                                    lng: stop.location.longitude,
                                    index: 0
                                });
                            }
                            // set the offset
                            this.setRouteMarkers(stop.index, stop, pins, trip, offset);
                        } else {
                            this.setRouteMarkers(stop.index, stop, pins, trip);
                        }
                    });
                } else {
                    trip.stopMarkers.forEach((s) => {
                        this.$_mapBox_addMapElement(s, this.map);
                    });
                }

                if (fitBounds) {
                    const routeCoordinates = trip.stops
                        .filter((stop) => stop.location.longitude !== undefined && stop.location.latitude !== undefined)
                        .map((stop) => [stop.location.longitude, stop.location.latitude]);

                    routeCoordinates.push([trip.startLocation.longitude, trip.startLocation.latitude]);

                    this.pinnedTrips.forEach((x) => {
                        const stopCoordinates = x.stops
                            .filter(
                                (stop) => stop.location.longitude !== undefined && stop.location.latitude !== undefined
                            )
                            .map((stop) => [stop.location.longitude, stop.location.latitude]);
                        stopCoordinates.forEach((x) => routeCoordinates.push(x));
                    });

                    const coordinates = this.$_mapBox_getMaxBounds(routeCoordinates);
                    this.$_mapBox_callMapFunction(this.map, 'fitBounds', coordinates);
                }
            } else {
                const indexToRemove = this.pinnedTrips.indexOf(trip);
                this.pinnedTrips.splice(indexToRemove, 1);

                trip.stopMarkers.forEach((s) => {
                    const markerIndex = this.stopMarkers.indexOf(s);
                    this.stopMarkers.splice(markerIndex, 1);
                    this.$_mapBox_removeElement(s);
                });
            }
        },
        setStopStartEndMarkers(trip, pin, locationType, isSameLocation) {
            this.bounds = this.$_mapbox_createBounds(this.map);
            let location;
            /* eslint-disable no-unused-vars */
            let locationAddress;
            /* eslint-disable no-unused-vars */
            let type = 'stop';
            if (locationType === 'startLocation' || isSameLocation) {
                type = 'optimiseStop';
                pin.optimiseStop = '/img/map/optimise-stop/start.png';
                location = trip.startLocation;
                locationAddress = trip.startAddress;
            } else if (locationType === 'endLocation') {
                type = 'optimiseStop';
                pin.optimiseStop = '/img/map/optimise-stop/end.png';
                location = trip.endLocation;
                locationAddress = trip.endAddress;
            }

            const marker = this.$_mapBox_setMarkers(
                this.map,
                location.latitude,
                location.longitude,
                locationType === 'startLocation' ? 'start' : 'end',
                pin
            );

            trip.stopMarkers.push(marker);
        },
        setRouteMarkers(stopIndex, stop, pins, trip, offset) {
            const newPin = { color: pins.color };

            if (stop.status === 'Failed') 
                newPin.color = '#f22428';
            else if (stop.status === 'Complete') 
                newPin.color = '#0eb514';

            const marker = this.$_mapBox_setMarkers(
                this.map,
                stop.location.latitude,
                stop.location.longitude,
                'stop',
                newPin,
                stopIndex
            );

            this.$_mapBox_addMapElement(marker, this.map);
            marker.getElement().addEventListener('click', () => {
                this.showInfoWindow = false;
                const vehicleDetails = Object.assign({}, stop);
                vehicleDetails.markerType = 'location';
                this.handleClickedMarker(vehicleDetails, marker, 'stop', false);
            });
            if (offset) {
                marker.setOffset([offset, 0]);
            }

            trip.stopMarkers.push(marker);
        },
        setCustomMarkerForStop(vehicleDetails, marker, type) {
            const customerMarker = document.createElement('div');
            const width = 100;
            const height = 100;
            customerMarker.className = 'custom-marker';
            customerMarker.style.width = `${width}px`;
            customerMarker.style.height = `${height}px`;
        },
        handleClickedMarker(data, marker, type, reallocateStop) {
            const {
                markerType,
                status,
                address,
                fullName,
                phone,
                stopId,
                trackingLinkUrl,
                stopRef,
                vehicleId,
                contact,
                arrivalDate,
                shipmentId,
                driverImageLink
            } = data;

            let location = null;
            location = { latitude: data.location.latitude, longitude: data.location.longitude };

            const obj = {
                address,
                teamMemberName: fullName,
                name: contact ?? '-',
                phone: phone ?? '-',
                coordinates: location,
                stopId,
                shipmentId,
                status,
                trackingLinkUrl,
                originalEta: arrivalDate,
                type,
                reallocateStop,
                stopRef,
                vehicleId,
                photoUrl: driverImageLink
            };

            // eslint-disable-next-line prefer-destructuring
            let defaultPhotoUrl = this.$root.defaultPhotoUrl;

            if (obj.photoUrl !== null) 
                defaultPhotoUrl = null;

            const el = document.createElement('div');
            const width = 250;
            const height = 230;

            el.style.width = `${width}px`;
            el.style.height = `${height}px`;
            el.style.backgroundColor = 'white';
            el.style.opacity = 0.95;
            el.style.marginTop = `-160px`;
            el.style.borderRadius = `5px`;
            el.style.cursor = `default`;
            el.style.pointerEvents = `auto`;
            el.style.padding = `10px`;
            el.style.fontSize = `14px`;

            el.innerHTML = `<div class='close-button' style='cursor: pointer; position: absolute; right: 5px;' @click="closeStopDetails()">
                                <i class="material-icons">close</i>
                            </div>
                            <div id="stopRef" style="padding-top: 4px; padding-bottom: 5px; margin-left: 5px; cursor: pointer; color: blue; font-size: 16px;">
                                <b>${obj.stopRef}</b>
                            </div>
                            <div style="padding-bottom: 5px; margin-left: 5px; ">
                                <b>${obj.address}</b>
                            </div>
                            <div style="margin-left: 5px; display: flex;">
                                <i class="material-icons">people</i>
                                <span v-if="${obj.name} !== null">${obj.name}</span>
                            </div>
                            <div style="margin-left: 5px; display: flex; padding-bottom: 15px">
                                <i class="material-icons">call</i>
                                <span v-if="${obj.phone} !== null">${obj.phone}</span>
                            </div>
                            <div style="padding-bottom: 5px; margin-left: 5px;">
                                <span>
                                    <img v-if="${
    obj.photoUrl
} !== null" style="max-width: 38px; max-height: 38px; border-radius: 25px;" src=${
    obj.photoUrl
} alt="" />
                                    <img v-else style="max-width: 35px; max-height: 35px; border-radius: 25px;" src=${defaultPhotoUrl}  alt="" />
                                </span>
                                <b>${obj.teamMemberName}</b>
                            </div>
                            <div style="padding-bottom: 5px; margin-left: 5px;">
                                <b>Status: </b>${obj.status}
                            </div>
                            `;

            el.querySelector('.close-button').addEventListener('click', () => {
                this.closeStopDetails(el);
            });

            el.querySelector('#stopRef').addEventListener('click', () => {
                this.openStop(obj.stopId);
            });

            new mapboxgl.Marker(el).setLngLat([location.longitude, location.latitude]).addTo(this.map);
        },
        closeStopDetails(stopDetails) {
            stopDetails.remove();
        },
        openStop(stopId) {
            const location = this.$router.resolve({ name: 'Stop Details', path: `/stops/details/${stopId}` });
            window.open(location.location.path, '_blank');
        },
        setDriverMarker(trip) {
            const pin = MarkerPins[1];

            if (trip.driverLocation) {
                const marker = this.$_mapBox_setMarkers(
                    this.map,
                    trip.driverLocation.latitude,
                    trip.driverLocation.longitude,
                    'driver',
                    pin
                );

                trip.stopMarkers.push(marker);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.close-button {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 0;
    z-index: 4;

    .i {
        max-width: 12px !important;
        max-height: 12px !important;
        font-size: 12px !important;
        color: black !important;
    }
}

.trip-footer {
    width: 550px;
    height: 40px;
    background-color: rgba(240, 240, 240, 0.9);
    bottom: 0;
    left: 0;
}

.route-container {
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 12px;
    width: 550px;
    padding: 20px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    height: 350px;
    padding-top: 8px;
}

.trip-container {
    width: 550px;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    transition: transform 0.5s ease;
}

@-moz-keyframes pulsate {
    from {
        -moz-transform: scale(0.25);
        opacity: 1;
    }

    95% {
        -moz-transform: scale(1.3);
        opacity: 0;
    }

    to {
        -moz-transform: scale(0.3);
        opacity: 0;
    }
}

@-webkit-keyframes pulsate {
    from {
        -webkit-transform: scale(0.25);
        opacity: 1;
    }

    95% {
        -webkit-transform: scale(1.3);
        opacity: 0;
    }

    to {
        -webkit-transform: scale(0.3);
        opacity: 0;
    }
}

#map_canvas ::v-deep div[title='pulse'] {
    -moz-animation: pulsate 1s ease-in-out infinite;
    -webkit-animation: pulsate 1s ease-in-out infinite;
    border: 1pt solid #fff;
    -moz-border-radius: 51px;
    -webkit-border-radius: 51px;
    border-radius: 51px;
    -webkit-box-shadow: inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f,
        0 0 5px #06f;
    box-shadow: inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f;
    height: 46px !important;
    margin: -18px 0 0 -18px;
    width: 46px !important;
}

.map-overlay {
    position: relative;

    .not-paid-map-overlay {
        width: 100%;
        height: calc(100vh - 60px);

        > div {
            bottom: 0;
        }
    }

    .gmnoprint-tool {
        z-index: 6;
        position: absolute;
        left: 120px;
        top: 110px;
    }
}

.toggle-icon {
    float: right !important;
}

.closed {
    transform: translateX(-91%);
}

.show-icon {
    display: inline-block;
}

.hide-icon {
    display: none;
}

.trip-table {
    padding-left: 0;
    line-height: 18px;
    list-style-type: none;
    max-height: 350px;
    background-color: transparent;

    ::v-deep > div {
        background-color: transparent !important;
    }

    .md-table-cell {
        padding: 0px;
        border: 0px;
        height: 32px;
    }

    .address-container {
        max-width: 410px;

        .md-table-cell-container {
            white-space: nowrap;
            width: 450px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    td.md-table-cell.vehicle-capacity,
    td.md-table-cell.vehicle-capacity .md-table-cell-container {
        padding-right: 30px;
        text-align: right;
    }

    td.md-table-cell.vehicle-capacity,
    td.md-table-cell.vehicle-capacity .md-table-cell-container {
        padding-right: 30px;
        text-align: right;
    }

    .allocatestop a {
        color: #2b93ff;
    }

    .allocatestop-pin {
        width: 52px;
    }

    .active .md-icon.md-theme-default.md-icon-font {
        color: #2b93ff;
        transform: rotate(0deg);
    }

    td.md-table-cell.allocatestop,
    td.md-table-cell.allocatestop .md-table-cell-container {
        padding-left: 13px;
    }

    td.md-table-cell.allocatestop-number,
    td.md-table-cell.allocatestop-number .md-table-cell-container {
        padding-left: 13px;
    }

    .route-pin {
        transform: rotate(90deg);
        cursor: pointer;
    }

    .route-pin-clicked {
        transform: rotate(-90deg);
        cursor: pointer;
        color: blue;
    }

    .profile-image {
        border-radius: 50%;
        height: 24px;
        width: 24px;
        box-shadow: none !important;
        border: 1px solid #eee;
    }
}

.md-content.md-table.trip-table.md-theme-default,
.md-content.md-table.trip-table.md-theme-default div {
    background-color: transparent;
}

.allocatestop {
    max-width: 360px;
    padding-right: 10px;

    ::v-deep div {
        white-space: nowrap;
        width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.allocatestop a {
    color: #2b93ff;
}

.allocatestop-pin {
    max-width: 50px;
    min-width: 50px;
}

td.md-table-cell.allocatestop,
td.md-table-cell.allocatestop .md-table-cell-container {
    padding-left: 10px;
}

.finish-time {
    min-width: 120px;
    max-width: 120px;
}

.table-container {
    max-height: 250px;
    overflow-y: auto;
}

.marker-driver {
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

::v-deep .marker-driver {
    width: 0;
    height: 0;
}

::v-deep .marker-driver span {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform-origin: 0 0;
    transform: rotateZ(-135deg);

    img {
        transform: rotateZ(135deg);
        border-radius: 50%;
    }
}

.marker-stop {
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

::v-deep .marker-stop {
    width: 0;
    height: 0;
}

::v-deep .marker-stop span {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    color: white;
    border: solid 2px;
    border-radius: 0 70% 70%;
    box-shadow: 0 0 2px #000;
    cursor: pointer;
    transform-origin: 0 0;
    transform: rotateZ(-135deg);

    img {
        transform: rotateZ(135deg);
        border-radius: 50%;
    }
}

::v-deep .marker-stop b {
    transform: rotateZ(135deg);
    color: #fff;
}

::v-deep .marker-driver b {
    transform: rotateZ(135deg);
    color: #fff;
}

.info-tooltip {
    position: absolute;
    line-height: 1.2;
    max-width: 240px;
    border: 1px solid white;
    background-color: white;
    border-radius: 8px;
    color: black;
    z-index: 2;
}

.trip-map {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.summary-title {
    display: contents;
}

.allocatestop-number {
    max-width: 50px;
}

.table-wrapper {
    padding-top: 10px;
}
</style>
