<template>
    <div class="map-panel map-search">
        <md-icon>search</md-icon>
        <google-autocomplete
            ref="address"
            id="autoaddress"
            classname="form-control autocomplete-input"
            placeholder="Location"
            v-model="searchedPlace"
            :should-focus="false"
            @placechanged="handlePlaceChanged"
            @keyup="handleKeyUp"
        />
    </div>
</template>

<script>
import { ref, inject } from '@vue/composition-api';
import { GoogleAutocomplete } from '@/components';

export default {
    name: 'MapSearchComponent',
    components: {
        GoogleAutocomplete
    },
    setup(_, { root }) {
        const map = inject('map');
        const searchedPlace = ref({
            address: '',
            location: {
                latitude: null,
                longitude: null
            },
            viewport: '',
            name: ''
        });
        const address = ref(null);

        const handlePlaceChanged = ({ location, viewport }) => {
            const { latitude: lat, longitude: lng } = location;
            searchedPlace.value = Object.assign(searchedPlace.value, { location, viewport });
            map.value.setCenter({ lat, lng });
            map.value.fitBounds(viewport);
        };

        const handleKeyUp = (e) => {
            const { location, viewport } = searchedPlace.value;
            // enter is pressed
            if (e.keyCode === 13) 
                handlePlaceChanged({ location, viewport });
        };
        return {
            searchedPlace,
            address,
            handlePlaceChanged,
            handleKeyUp
        };
    }
};
</script>

<style lang="scss" scoped>
.map-search {
    position: absolute;
    top: 10px;
    left: 180px;
    z-index: 2;
    height: 40px;
    background: white;
    max-width: 230px;
    padding: 0 8px;
    display: flex;
    .md-icon {
        margin-left: 0;
        margin-right: 0;
        & + .md-focused {
            color: #4ba3ff;
            fill: #4ba3ff;
        }
    }
    ::v-deep .md-field {
        margin: 0;
        padding: 0;
        margin-left: 12px;
        width: calc(100% - 35px);
        button {
            display: none;
        }
        input {
            width: 100% !important;
            padding: 0;
            margin-right: 0;
            // to adjust the autocomplete style since it checks programatically for the leftmost side of the input
            margin-left: -44px;
            padding-left: 44px;
            margin-bottom: 5px;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
    }
}
</style>
