var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.customFieldDefinition.type.toLowerCase() == "text" ||
      _vm.customFieldDefinition.type.toLowerCase() == "multilinetext"
        ? _c(
            "form-group",
            {
              attrs: {
                name: _vm.customFieldDefinition.name,
                label: _vm.showLabel ? _vm.customFieldDefinition.label : null,
              },
            },
            [
              _vm.customFieldDefinition.type.toLowerCase() == "text"
                ? _c("md-input", {
                    attrs: { placeholder: _vm.placeholder },
                    model: {
                      value: _vm.customFieldValue,
                      callback: function ($$v) {
                        _vm.customFieldValue = $$v
                      },
                      expression: "customFieldValue",
                    },
                  })
                : _vm._e(),
              _vm.customFieldDefinition.type.toLowerCase() == "multilinetext"
                ? _c("md-textarea", {
                    model: {
                      value: _vm.customFieldValue,
                      callback: function ($$v) {
                        _vm.customFieldValue = $$v
                      },
                      expression: "customFieldValue",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.customFieldDefinition.type.toLowerCase() == "date"
        ? _c(
            "md-datepicker",
            {
              attrs: { "md-immediately": "", "md-debounce": 10 },
              model: {
                value: _vm.customFieldValue,
                callback: function ($$v) {
                  _vm.customFieldValue = $$v
                },
                expression: "customFieldValue",
              },
            },
            [
              _c("label", [
                _vm._v(
                  _vm._s(_vm.showLabel ? _vm.customFieldDefinition.label : null)
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.customFieldDefinition.type.toLowerCase() == "checkbox"
        ? _c(
            "md-checkbox",
            {
              model: {
                value: _vm.customFieldValue,
                callback: function ($$v) {
                  _vm.customFieldValue = $$v
                },
                expression: "customFieldValue",
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.showLabel ? _vm.customFieldDefinition.label : "") +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm.customFieldDefinition.type.toLowerCase() === "dropdown"
        ? _c(
            "form-group",
            {
              attrs: {
                name: _vm.customFieldDefinition.name,
                label: _vm.showLabel ? _vm.customFieldDefinition.label : null,
              },
            },
            [
              _c("vue-select", {
                staticClass: "custom-mapping-select",
                attrs: {
                  reduce: function (cf) {
                    return cf.value
                  },
                  label: "value",
                  options: _vm.customFieldDefinition.options,
                  placeholder: _vm.customFieldDefinition.label,
                  searchable: false,
                },
                model: {
                  value: _vm.customFieldValue,
                  callback: function ($$v) {
                    _vm.customFieldValue = $$v
                  },
                  expression: "customFieldValue",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }