<template>
    <div class="sidebar-spacing">
        <sidebar-item :link="{ name: $t('menus.assetManagement.assetManagement'), icon: 'local_shipping' }">
            <sidebar-item
                :link="{
                    name: $t('menus.assetManagement.assets'),
                    path: '/assets/overview'
                }"
            />

            <sidebar-item
                badge="New"
                :link="{
                    name: $t('menus.assetManagement.schedules'),
                    path: '/maintenance/schedules'
                }"
            />

            <sidebar-item
                badge="New"
                :link="{
                    name: $t('menus.assetManagement.upcoming'),
                    path: '/maintenance/upcoming'
                }"
            />
        </sidebar-item>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            isClosed: true
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasTeamSettingsAccess: 'user/hasTeamSettingsAccess'
        })
    },
    methods: {
        clicked(e) {
            e.preventDefault();
        },
        toggleMenu() {
            this.isClosed = !this.isClosed;
        }
    }
};
</script>
<style scoped>
.collapsed {
    transition: opacity 1s;
}

.caret-report {
    position: absolute;
    top: 8px;
    right: 15px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
</style>
