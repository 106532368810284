<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.brands') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>face</md-icon>
                    </div>
                </md-card-header>

                <md-card-content>
                    <div class="cf-container">
                        <div class="cf-action-container">
                            <md-button
                                title="Add brand"
                                class="md-primary md-just-icon md-round"
                                @click.stop="handleAdd()"
                            >
                                <md-icon>add</md-icon>
                            </md-button>
                        </div>
                        <md-table class="custom-paginated-table">
                            <md-table-row>
                                <md-table-head>Name</md-table-head>
                                <md-table-head>Logo</md-table-head>
                                <md-table-head>Actions</md-table-head>
                            </md-table-row>

                            <md-table-row v-for="(item, index) in brandList" :key="index">
                                <md-table-cell>
                                    {{ item.name }}
                                </md-table-cell>
                                <md-table-cell>
                                    <div>
                                        <a @click="viewPhoto([item.logoFileUrl], item.logoFileUrl)">
                                            <img
                                                class="note-image"
                                                :src="item.logoFileUrl"
                                                @error="$_notePhotoLoading($event, item.logoFileUrl)"
                                            />
                                        </a>
                                    </div>
                                </md-table-cell>
                                <md-table-cell class="action-buttons">
                                    <md-button
                                        title="update brand"
                                        class="md-warning md-just-icon md-round"
                                        @click.stop="update(item)"
                                    >
                                        <md-icon>edit</md-icon>
                                    </md-button>
                                    <md-button
                                        title="delete brand"
                                        class="md-danger md-just-icon md-round"
                                        @click.stop="deleteBrand(item.brandId)"
                                    >
                                        <md-icon>delete</md-icon>
                                    </md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <div class="text-center" v-if="brandList.length == 0">
                            <p class="no-result-message">No results matching your search/filter could be found.</p>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PhotoPreviewModal from '@/components/PhotoPreviewModal';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import AddBrandModal from './AddBrandModal';

export default {
    name: 'BrandSettings',
    mixins: [GeneralMixin],
    props: {
        teamId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            brandList: []
        };
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam'
        })
    },
    async mounted() {
        await this.getBrands();
    },
    methods: {
        handleAdd() {
            this.$modal.show(AddBrandModal).then(async (response) => {
                if (response) {
                    this.getBrands();
                }
            });
        },
        toggleModal(shouldToggle, modalToToggle) {
            this[modalToToggle] = shouldToggle;
        },
        deleteBrand(id) {
            this.$messageBox
                .show({
                    title: 'Delete Brand',
                    body: 'Are you sure you want to delete this brand?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmDelete(id);
                });
        },
        async getBrands() {
            this.$_handleLoaderState(true);
            const api = '/api/brands';
            const response = await handleRequests(api);
            if (response.data !== null) {
                this.brandList = response.data;
            }
            this.$_handleLoaderState(false);
        },
        async handleConfirmDelete(id) {
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'delete'
            };
            const api = `/api/brands/${id}`;
            try {
                await handleRequests(api, payload);
                this.$_handleLoaderState(false);
                this.$notify({
                    message: 'Brand was deleted!',
                    type: 'success'
                });
                this.getBrands();
            } catch (e) {
                this.$_handleLoaderState(false);
                const message = 'Cannot delete brand.';
                showErrorMessage(this, message, e);
            }
        },
        async update(item) {
            this.$modal.show(AddBrandModal, { existingData: item }).then(async (response) => {
                if (response) {
                    this.getBrands();
                }
            });
        },
        viewPhoto(photos, selected) {
            this.$modal
                .show(PhotoPreviewModal, {
                    photos,
                    selectedPhoto: selected
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.$modal.hide();
                    }
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.actions {
    width: 6%;
}

.asset-type-filter {
    width: 10%;
    float: right;
}

.custom-paginated-table .action-buttons {
    width: 100px;
}
.card-icon {
    background: #0976b4 !important;
}

.note-image {
    max-height: 50px;
    width: initial;
    background-color: #eee;
}
</style>
