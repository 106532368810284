export const warehouseLocationChoices = {
    Bin: 'Bins',
    'Loading Area': 'Loading Areas',
    'Unloading Area': 'Unloading Areas',
    Shelf: 'Shelves',
    'Assembly Area': 'Assembly Areas',
    'Bulk Storage Area': 'Bulk Storage Areas',
    'Cold Storage / Refrigerator': 'Cold Storage / Refrigerators',
    'Cross-Docking Area': 'Cross-Docking Areas',
    'Hazmat Storage Area': 'Hazmat Storage Areas',
    'Loading Dock': 'Loading Docks',
    Other: 'Others',
    'Palletizing Area': 'Palletizing Areas',
    'Packing Area': 'Packing Areas',
    'Picking Area': 'Picking Areas',
    'Quality Control Area': 'Quality Control Areas',
    'Receiving Area': 'Receiving Areas',
    'Returns Processing Area': 'Returns Processing Areas',
    'Shipping Area': 'Shipping Areas',
    'Storage Rack / Shelving': 'Storage Racks / Shelvings',
    Workstation: 'Workstations',
    Trailer: 'Trailers'
};

export const warehouseLocationChoicesList = Object.keys(warehouseLocationChoices);

export const assetLocationChoices = {
    Left: 'Left',
    Right: 'Right',
    Front: 'Front',
    Back: 'Back'
};

export const assetLocationChoicesList = Object.keys(assetLocationChoices);
