import { UserManager, WebStorageStateStore } from 'oidc-client';

// eslint-disable-next-line import/no-dynamic-require
const { config } = require(`../config/config.${process.env.NODE_ENV}`);

export default class AuthService {
    constructor() {
        const defaultSettings = {
            client_id: 'locate2u.appportal',
            response_type: 'id_token token',
            scope: 'openid profile locate2u.appportal',
            userStore: new WebStorageStateStore({
                store: window.localStorage
            }),
            automaticSilentRenew: true,
            accessTokenExpiringNotificationTime: 10
            // acr_values: 'pti:995e7cd9-e84f-41f0-b4fc-3917a7f239e3' // this is used for white labeling login page for the identity server
        };

        const settings = Object.assign({}, defaultSettings, config);

        this.userManager = new UserManager(settings);
    }

    async getUser() {
        const response = await this.userManager.getUser();
        return response;
    }

    async loginCallback() {
        const response = await this.userManager.signinRedirectCallback();
        return response;
    }

    async login() {
        const response = await this.userManager.signinRedirect({
            state: window.location.pathname
        });
        return response;
    }

    async register() {
        const response = await this.userManager.signinRedirect({
            extraQueryParams: {
                page: 'register'
            }
        });
        return response;
    }

    async logout() {
        const response = await this.userManager.signoutRedirect();
        return response;
    }

    async isLoggedIn() {
        const user = await this.getUser();

        return user !== null && !user.expired;
    }
}
