var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "drop-down",
    {
      staticClass: "custom-dropdown",
      attrs: { direction: "down", "should-close": _vm.shouldCloseDropdown },
      on: {
        closeDropdown: function (val) {
          return (this$1.shouldCloseDropdown = val)
        },
        closeOtherDropDown: _vm.closeOtherDropDown,
      },
    },
    [
      _c(
        "md-button",
        {
          staticClass: "md-button md-success md-just-icon md-round",
          attrs: {
            slot: "title",
            "data-toggle": "dropdown",
            title: "Create new maintenance schedule",
          },
          slot: "title",
        },
        [_c("i", { staticClass: "material-icons" }, [_vm._v("more_time")])]
      ),
      !_vm.isReadOnlyUser
        ? _c("ul", { staticClass: "dropdown-menu custom-dropdown-menu" }, [
            this.asset.trackerId != null
              ? _c(
                  "div",
                  { staticClass: "schedule-list" },
                  [
                    _vm.maintenanceSchedules == null ||
                    _vm.maintenanceSchedules.length == 0
                      ? _c(
                          "div",
                          { staticClass: "sched-container" },
                          [
                            _vm._v(
                              "\n                No generic maintenance schedule available. Go to\n                "
                            ),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Maintenance Schedule Dashboard",
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    Maintenance Schedule Dashboard\n                "
                                ),
                              ]
                            ),
                            _vm._v(
                              "\n                to start creating one.\n            "
                            ),
                          ],
                          1
                        )
                      : _vm._l(
                          _vm.maintenanceSchedules,
                          function (option, index) {
                            return _c("li", { key: index }, [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleChangeOption(option)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(option.maintenanceName) +
                                      "\n\n                    "
                                  ),
                                  option.maintenanceName.length > 26
                                    ? _c(
                                        "md-tooltip",
                                        { attrs: { "md-direction": "left" } },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(option.maintenanceName) +
                                              "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          }
                        ),
                  ],
                  2
                )
              : _c(
                  "div",
                  { staticClass: "schedule-list" },
                  [
                    _vm.noTrackerScheduleList == null ||
                    _vm.noTrackerScheduleList.length == 0
                      ? _c(
                          "div",
                          { staticClass: "sched-container" },
                          [
                            _vm._v(
                              "\n                No generic maintenance schedule available\n                "
                            ),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Maintenance Schedule Dashboard",
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    Maintenance Schedule Dashboard\n                "
                                ),
                              ]
                            ),
                            _vm._v(
                              "\n                to start creating one.\n            "
                            ),
                          ],
                          1
                        )
                      : _vm._l(
                          _vm.noTrackerScheduleList,
                          function (option, index) {
                            return _c("li", { key: index }, [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleChangeOption(option)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(option.maintenanceName) +
                                      "\n                    "
                                  ),
                                  option.maintenanceName.length > 26
                                    ? _c("md-tooltip", [
                                        _vm._v(_vm._s(option.maintenanceName)),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          }
                        ),
                  ],
                  2
                ),
            _c(
              "div",
              {
                staticClass: "custom-create-btn",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleNew($event)
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("more_time"),
                ]),
                _vm._v("\n            Create New\n        "),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }