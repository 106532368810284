<template>
    <div v-if="!isLoading && orderDetails != null">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <router-link
                            :to="{
                                name: 'Orders List'
                            }"
                        >
                            <h3 class="title">Order List</h3>
                        </router-link>
                        <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ orderDetails.orderNumber }}</h3>
                    </div>
                    <div class="custom-toolbar-end" v-if="!$root.isMobileOnly">
                        <div class="header-button-container">
                            <OrderPaymentStatusButton :order="orderDetails" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-66 md-xlarge-size-50">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>description</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div class="multiple-details-box">
                            <div class="details-box">
                                <label>Order Number</label>
                                <span>
                                    {{ orderDetails.orderNumber }}
                                </span>
                            </div>
                            <div class="details-box">
                                <label>Purchase Order Number</label>
                                <span>
                                    {{ orderDetails.purchaseOrderNumber }}
                                </span>
                            </div>
                            <div class="details-box">
                                <label>Source Reference</label>
                                <span>
                                    {{ orderDetails.sourceReference }}
                                </span>
                            </div>
                            <div class="details-box">
                                <label>Payment date</label>
                                <span>
                                    {{ orderDetails.paymentDate | dateFormat(DATE_TYPES.standardDate) }}
                                </span>
                            </div>
                        </div>

                        <div class="multiple-details-box">
                            <div class="details-box">
                                <label>Order date</label>
                                <span>
                                    {{ orderDetails.orderDate | dateFormat(DATE_TYPES.standardDate) }}
                                </span>
                            </div>
                            <div class="details-box">
                                <label>Expected Delivery Date</label>
                                <span>
                                    {{ orderDetails.expectedDeliveryDate | dateFormat(DATE_TYPES.standardDate) }}
                                </span>
                            </div>
                        </div>

                        <div class="address-details" v-if="orderDetails.pickupAddress != null">
                            <div class="details-box">
                                <label>Pickup Address</label>
                                <div v-if="orderDetails.pickupAddress.name" class="address-name">
                                    {{ orderDetails.pickupAddress.name }}
                                </div>
                                <span>{{ orderDetails.pickupAddress.address }}</span>
                            </div>
                        </div>
                        <div class="address-details" v-if="orderDetails.shippingAddress != null">
                            <div class="details-box">
                                <label>Delivery Address</label>
                                <div v-if="orderDetails.shippingAddress.name" class="address-name">
                                    {{ orderDetails.shippingAddress.name }}
                                </div>
                                <span>{{ orderDetails.shippingAddress.address }}</span>
                            </div>
                        </div>
                        <div class="details-box">
                            <label>Delivery Instructions</label>
                            <div>
                                {{ orderDetails.deliveryInstructions }}
                            </div>
                        </div>
                        <div class="details-box">
                            <label>Notes</label>
                            <div>
                                {{ orderDetails.notes }}
                            </div>
                        </div>
                    </md-card-content>
                </md-card>

                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-rose">
                        <div class="card-icon">
                            <md-icon>receipt_long</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <md-table v-if="orderDetails.orderLines.length > 0">
                            <md-table-row>
                                <md-table-head>Product Reference</md-table-head>
                                <md-table-head>Qty</md-table-head>
                                <md-table-head>Status</md-table-head>
                            </md-table-row>
                            <md-table-row v-for="orderLine in orderDetails.orderLines" :key="orderLine.orderLineId">
                                <md-table-cell>
                                    {{ orderLine.productReference }}
                                    <span v-if="orderLine.productVariant != null">
                                        ({{ orderLine.productVariant.size }} {{ orderLine.productVariant.colour }})
                                    </span>
                                </md-table-cell>
                                <md-table-cell>{{ orderLine.quantity }}</md-table-cell>
                                <md-table-cell>
                                    <span class="custom-badge">
                                        {{ orderLine.status }}
                                    </span>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <div v-else>
                            <p class="no-result-message">No existing order lines for this order.</p>
                        </div>
                    </md-card-content>
                </md-card>

                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div class="card-icon">
                            <md-icon>inventory_2</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <md-table v-if="orderDetails.items.length > 0" class="itemline-table">
                            <md-table-row>
                                <md-table-head>Barcode</md-table-head>
                                <md-table-head>Status</md-table-head>
                                <md-table-head>Item lines</md-table-head>
                            </md-table-row>
                            <md-table-row v-for="item in orderDetails.items" :key="item.itemId" class="top-align">
                                <md-table-cell>
                                    <router-link
                                        title="View item details"
                                        class="ref-link"
                                        :to="{
                                            name: 'Inventory Details',
                                            params: { itemId: item.itemId }
                                        }"
                                        target="_blank"
                                    >
                                        {{ item.barcode }}
                                    </router-link>
                                </md-table-cell>

                                <md-table-cell>
                                    <span class="custom-badge">
                                        {{ item.status }}
                                    </span>
                                </md-table-cell>
                                <md-table-cell class="mini-table-row">
                                    <md-table class="mini-table">
                                        <md-table-head>Qty</md-table-head>
                                        <md-table-head>Product</md-table-head>
                                        <md-table-row v-for="line in item.itemLines" :key="line.itemLineId">
                                            <md-table-cell>
                                                {{ line.quantity }}
                                            </md-table-cell>

                                            <md-table-cell>
                                                {{ line.productReference }}
                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <div v-else>
                            <p class="no-result-message">No existing order lines for this order.</p>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <div
                class="md-layout-item md-xsmall-size-100 md-small-size-33 md-medium-size-33 md-large-size-33 md-xlarge-size-50"
            >
                <contact-details :stop-details="orderDetails" />
            </div>
        </div>
    </div>
</template>

<script>
import { ContactDetails } from '@/pages/Stops/components';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import OrderPaymentStatusButton from './Buttons/OrderPaymentStatusButton';

export default {
    name: 'OrderDetails',
    components: { ContactDetails, OrderPaymentStatusButton },
    mixins: [GeneralMixin],
    data() {
        return {
            orderDetails: null,
            isLoading: null
        };
    },
    async mounted() {
        await this.fetchOrderDetails();
    },
    methods: {
        async fetchOrderDetails() {
            try {
                this.isLoading = true;
                this.$_handleLoaderState(true);
                const api = `/api/orders/${this.$route.params.orderId}`;

                const response = await handleRequests(api);

                this.orderDetails = response.data;
                // this.orderDetails.orderLines = [
                //     {
                //         orderLineId: 1,
                //         quantity: 10,
                //         productReference: "Medium Tshirt",
                //         productVariant: null,
                //         status: "Created"
                //     },
                //     {
                //         orderLineId: 2,
                //         quantity: 3,
                //         productReference: "Uniqlo Tshirt",
                //         productVariantId: 1,
                //         productVariant: {
                //             size: "XXL",
                //             colour: "Red"
                //         },
                //         status: "Created"
                //     }
                // ]
                this.isLoading = false;
                this.$_handleLoaderState(false);
            } catch (error) {
                this.$_handleLoaderState(false);
                const message = 'Error in loading the details';
                showErrorMessage(this, message, error);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.multiple-details-box {
    > div {
        display: inline-block;
        width: 200px;
        vertical-align: top;
    }
}

::v-deep .dropdown-menu {
    left: initial;
    right: 0;
}
.custom-badge {
    background-color: #c2907f;
}

.itemline-table {
    .top-align {
        vertical-align: top;
    }
}

.mini-table {
    ::v-deep .md-table-head {
        padding: 5px;
        line-height: 10px;
    }
    ::v-deep .md-table-head-label {
        font-size: 0.8rem;
        font-weight: 500;
    }

    .md-table-row .md-table-cell {
        padding: 8px 8px;
        height: 30px;
    }
}

.mini-table-row {
    padding: 0;
}

.ref-link {
    color: rgba(0, 0, 0, 0.87) !important;
}

.ref-link:hover {
    color: #2b93ff !important;
}
</style>
