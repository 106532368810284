<template>
    <div class="page-container">
        <div class="page-form-column">
            <md-card>
                <md-card-header>
                    <div class="md-title">Create Trip</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-medium-size-50 md-small-size-100">
                            <form-group name="teamRegion" label="Team Region">
                                <team-region-options
                                    :selected="tripDetails.teamRegionId"
                                    @selectedOption="handleTeamRegionChanged"
                                />
                            </form-group>
                        </div>
                        <div class="md-layout-item md-medium-size-50 md-small-size-100">
                            <TeamMemberOptions
                                :selected="tripDetails.publicUserId"
                                :team-region-id-filter="tripDetails.teamRegionId"
                                @selectedOption="handleTeamMemberChanged"
                            />
                            <span v-if="errors.publicUserId" class="error">{{ errors.publicUserId.message }}</span>
                        </div>
                    </div>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-medium-size-50 md-small-size-100">
                            <CustomerOptions
                                :selected="tripDetails.customerId"
                                :team-region-id="tripDetails.teamRegionId"
                                @selectedOption="handleCustomerChanged"
                            />
                        </div>
                        <div class="md-layout-item md-medium-size-50 md-small-size-100">
                            <charging-type-options
                                :selected-charge-type-id="tripDetails.rateGroupId"
                                @selectedOption="handleRateTypeChanged"
                            />
                        </div>
                    </div>
                </md-card-content>
            </md-card>
            <md-card>
                <md-card-header>
                    <h3 class="md-title">Trip Information</h3>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                            <md-datepicker
                                ref="mdDatePicker"
                                v-model="tripDetails.tripDate"
                                md-immediately
                                :md-disabled-dates="disabledDates"
                                :md-debounce="10"
                                :clearable="false"
                            >
                                <label>Trip Date</label>
                                <span v-if="errors.tripDate" class="error">{{ errors.tripDate.message }}</span>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                            <google-autocomplete
                                label="Start Address"
                                id="startAddress"
                                classname="form-control autocomplete-input"
                                placeholder=""
                                :should-focus="false"
                                v-model="tripDetails.startAddress"
                                @placechanged="handleStartLocationChange"
                            ></google-autocomplete>
                            <span v-if="errors.startAddress" class="error">{{ errors.startAddress.message }}</span>
                        </div>
                    </div>

                    <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                            <google-autocomplete
                                label="End Address"
                                id="endAddress"
                                classname="form-control autocomplete-input"
                                placeholder=""
                                :should-focus="false"
                                v-model="tripDetails.endAddress"
                                @placechanged="handleEndLocationChange"
                            ></google-autocomplete>
                            <span v-if="errors.endAddress" class="error">{{ errors.endAddress.message }}</span>
                        </div>
                    </div>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-medium-size-50 md-small-size-100">
                            <form-group name="startTime" label="Start Time" class="time-picker hide-clear-btn">
                                <time-picker
                                    :time="tripDetails.startTime"
                                    :all-time-options="false"
                                    :is-required="false"
                                    @selectedTime="getStartTime"
                                />
                            </form-group>
                            <div class="time-picker-error">
                                <span v-if="errors.startTime" class="error">
                                    {{ errors.startTime.message }}
                                </span>
                            </div>
                        </div>
                        <div class="md-layout-item md-medium-size-50 md-small-size-100">
                            <form-group name="endTime" label="End Time" class="time-picker hide-clear-btn">
                                <time-picker
                                    :time="tripDetails.endTime"
                                    :all-time-options="false"
                                    :is-required="false"
                                    @selectedTime="getEndTime"
                                />
                            </form-group>
                            <div class="time-picker-error">
                                <span v-if="errors.endTime" class="error">{{ errors.endTime.message }}</span>
                            </div>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <div>
            <div class="summary">
                <SummaryCard>
                    <template v-slot:header-text>
                        <h3>Summary</h3>
                    </template>
                    <template v-slot:content>
                        <SummaryCardSection v-if="!!selectedTeamRegionModel">
                            <h6>Team Region</h6>
                            <p>{{ selectedTeamRegionModel.name }}</p>
                        </SummaryCardSection>
                        <SummaryCardSection v-if="fieldsToDisplayByDefault.publicUserId || !!selectedTeamMemberModel">
                            <h6>Assigned To</h6>
                            <p>
                                {{ selectedTeamMemberModel ? selectedTeamMemberModel.fullName : '-' }}
                            </p>
                        </SummaryCardSection>
                        <SummaryCardSection class="summary-card--section" v-if="!!selectedCustomerModel">
                            <h6>Customer</h6>
                            <p>
                                {{
                                    selectedCustomerModel.company ||
                                        selectedCustomerModel.name ||
                                        `${selectedCustomerModel.firstName} ${selectedCustomerModel.lastName}`
                                }}
                            </p>
                        </SummaryCardSection>
                        <SummaryCardSection class="summary-card--section" v-if="!!selectedRateTypeModel">
                            <h6>Service Package</h6>
                            <p>{{ selectedRateTypeModel.rateGroupName }}</p>
                        </SummaryCardSection>
                        <SummaryCardSection class="summary-card--section">
                            <h6>Trip Date</h6>
                            <p>{{ tripDetails.tripDate && tripDetails.tripDate.toDateString() }}</p>
                            <template v-if="fieldsToDisplayByDefault.startAddress || tripDetails.startAddress.address">
                                <h6>Start Address</h6>
                                <p>{{ tripDetails.startAddress.address || '-' }}</p>
                            </template>
                            <template v-if="tripDetails.endAddress.address">
                                <h6>End Address</h6>
                                <p>{{ tripDetails.endAddress.address || '-' }}</p>
                            </template>
                            <div class="start-end-time">
                                <div v-if="fieldsToDisplayByDefault.startTime || startTimeFormatted !== '-'">
                                    <h6>Start Time</h6>
                                    <p>{{ startTimeFormatted }}</p>
                                </div>
                                <div v-if="fieldsToDisplayByDefault.endTime || endTimeFormatted !== '-'">
                                    <h6>End Time</h6>
                                    <p>{{ endTimeFormatted }}</p>
                                </div>
                            </div>
                        </SummaryCardSection>
                        <div
                            class="summary-card--charges-cost-section"
                            v-if="rateListUIItems.length > 0 && fetchRateListStatus !== 'pending'"
                        >
                            <div class="charges-cost-heading">
                                <h6>Expected Charges</h6>
                            </div>
                            <div class="charges-cost-table">
                                <template v-for="(uiItem, index) in rateListUIItems">
                                    <div class="charge-cost-description" :key="index">
                                        {{ uiItem.description }}
                                    </div>
                                    <span class="charge-cost-amount" :key="index">
                                        {{ uiItem.amountText || formatValue(uiItem.amount) }}
                                    </span>
                                </template>
                            </div>
                        </div>
                        <div v-else-if="fetchRateListStatus === 'pending'">
                            <md-progress-bar md-mode="indeterminate"></md-progress-bar>
                        </div>
                    </template>
                    <template v-slot:actions>
                        <md-button class="md-primary" @click="createTrip">
                            Create Trip
                        </md-button>
                    </template>
                </SummaryCard>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, getCurrentInstance, computed, watch } from '@vue/composition-api';
import useGeneralUtils from '@/compostables/useGeneralUtils';
import { ChargingTypeOptions, GoogleAutocomplete, TimePicker } from '@/components';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import TeamRegionOptions from '@/components/TeamRegionOptions';
import TeamMemberOptions from '@/pages/Trips/Components/TeamMemberOptions';
import CustomerOptions from '@/pages/Trips/Components/CustomerOptions';
import moment from 'moment/moment';
import { DATE_TYPES } from '@/utils/constants';
import { handleRequests, showErrorMessage } from '@/helpers';
import SummaryCard from '@/pages/Trips/Components/SummaryCard';
import SummaryCardSection from '@/pages/Trips/Components/SummaryCardSection';
import useCurrencyUtils from '@/compostables/useCurrencyUtils';
import useRateList from '@/pages/Trips/useRateList';
import _ from 'lodash';
import useStore from '@/compostables/useStore';

export default {
    name: 'CreateTripPage',
    components: {
        SummaryCardSection,
        SummaryCard,
        CustomerOptions,
        TeamMemberOptions,
        TeamRegionOptions,
        ChargingTypeOptions,
        TimePicker,
        GoogleAutocomplete
    },
    mixins: [GeneralMixin],

    setup(props, context) {
        const vm = getCurrentInstance();
        const generalUtils = useGeneralUtils(vm.proxy.$root);
        const store = useStore();
        const userGetter = store.getters['user/user'];
        const notify = context.root.$notify;
        const tripDetails = ref({
            teamRegionId: null,
            tripDate: new Date(),
            startAddress: {
                address: '',
                location: {
                    latitude: 0,
                    longitude: 0
                }
            },
            startTime: userGetter?.defaultTripStartTime,
            endAddress: {
                address: '',
                location: {
                    latitude: 0,
                    longitude: 0
                }
            },
            endTime: userGetter?.defaultTripEndTime,
            publicUserId: null,
            customerId: null,
            rateGroupId: null
        });

        const currencyUtils = useCurrencyUtils();

        const fieldsToDisplayByDefault = computed(() => {
            const tripDate = moment(tripDetails.value.tripDate).format(DATE_TYPES.internationalDate);
            // if booking a trip in the past, start time and end time should be required
            if (moment(tripDate).isBefore(moment().format(DATE_TYPES.internationalDate))) {
                return {
                    startAddress: true,
                    startTime: true,
                    endTime: true,
                    publicUserId: true
                };
            }
            return {
                startAddress: true,
                startTime: false,
                endTime: false,
                publicUserId: false
            };
        });

        const startTimeFormatted = computed(() => {
            return tripDetails.value.startTime ? moment(tripDetails.value.startTime, 'HH:mm').format('hh:mm A') : '-';
        });
        const endTimeFormatted = computed(() => {
            return tripDetails.value.endTime ? moment(tripDetails.value.endTime, 'HH:mm').format('hh:mm A') : '-';
        });
        /**
         * @type {Ref<{ [key: string]: { message: string }}>}
         */
        const errors = ref({});
        /**
         * @type {Ref<import("@/jsDocTypes/Customer").CustomerListItemViewModel | null>}
         */
        const selectedCustomerModel = ref(null);
        const selectedTeamRegionModel = ref(null);
        const selectedTeamMemberModel = ref(null);

        /**
         *
         * @type {Ref<import("@/jsDocTypes/RateRule").RateRule | null>}
         */
        const selectedRateTypeModel = ref(null);
        const isStartTimeValid = ref(true);
        const isEndTimeValid = ref(true);
        const isFormTouched = ref(false);

        const rateListHook = useRateList();

        function formatValue(value) {
            if (Number.isNaN(+value)) {
                return value;
            }
            const currency = rateListHook.currency.value;
            return currencyUtils.formatValue(value, currency);
        }

        function disabledDates(date) {
            const x = moment(date).format(DATE_TYPES.internationalDate);
            const now = moment().format(DATE_TYPES.internationalDate);
            return !moment(x).isSameOrAfter(now, 'day');
        }

        function handleStartLocationChange(data) {
            if (errors.value.startAddress && errors.value.startAddress.message) {
                delete errors.value.startAddress;
            }
            tripDetails.value.startAddress.address = data.address || '';
            tripDetails.value.startAddress.location = data.location;
        }

        function handleTeamMemberChanged(selectedMemberId, selectedMemberModel) {
            tripDetails.value.publicUserId = !selectedMemberId ? null : selectedMemberId;
            selectedTeamMemberModel.value = selectedMemberModel;
        }

        function handleEndLocationChange(data) {
            if (errors.value.endAddress && errors.value.endAddress.message) {
                delete errors.value.endAddress;
            }
            tripDetails.value.endAddress.address = data.address || '';
            tripDetails.value.endAddress.location = data.location;
        }

        function getStartTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;
                tripDetails.value.startTime = appointmentTime;
            } else {
                tripDetails.value.startTime = null;
            }
            errors.value.startTime = isTimeValid ? null : { message: 'Invalid start time' };
        }

        function getEndTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;
                tripDetails.value.endTime = appointmentTime;
            } else {
                tripDetails.value.endTime = null;
            }
            errors.value.endTime = isTimeValid ? null : { message: 'Invalid end time' };
        }

        /**
         *
         * @param {import("@/jsDocTypes/RateRule").RateRule} rateRule
         */
        function handleRateTypeChanged(rateRule) {
            tripDetails.value.rateGroupId = rateRule?.rateGroupId ?? null;
            selectedRateTypeModel.value = rateRule;
        }

        function handleTeamRegionChanged(teamRegionId, teamRegionModel) {
            tripDetails.value.teamRegionId = !teamRegionId ? 0 : teamRegionId;
            selectedTeamRegionModel.value = teamRegionModel;
        }

        function handleCustomerChanged(customerId, customerModel) {
            tripDetails.value.customerId = customerId;
            selectedCustomerModel.value = customerModel;
        }

        async function createTrip() {
            // validation
            isFormTouched.value = true;
            errors.value = {};
            if (!tripDetails.value.tripDate) {
                errors.value.tripDate = { message: 'Trip date is required' };
            }
            if (!tripDetails.value.startAddress.address) {
                errors.value.startAddress = { message: 'Start address is required' };
            }

            const tripDate = moment(tripDetails.value.tripDate).format(DATE_TYPES.internationalDate);
            // if booking a trip in the past, start time and end time should be required
            if (moment(tripDate).isBefore(moment().format(DATE_TYPES.internationalDate))) {
                if (!tripDetails.value.startTime) {
                    errors.value.startTime = { message: 'Start time is required' };
                }
                if (!tripDetails.value.endTime) {
                    errors.value.endTime = { message: 'End time is required' };
                }
                if (!tripDetails.value.publicUserId) {
                    errors.value.publicUserId = { message: 'Team member is required' };
                }
            }

            if (Object.keys(errors.value).length !== 0) {
                return;
            }

            const data = {
                tripDate,
                startLocation: tripDetails.value.startAddress.location,
                startAddress: tripDetails.value.startAddress.address,
                endLocation: tripDetails.value.endAddress.location,
                endAddress: tripDetails.value.endAddress.address,
                startTime: tripDetails.value.startTime,
                endTime: tripDetails.value.endTime,
                publicUserId: tripDetails.value.publicUserId,
                customerId: tripDetails.value.customerId,
                rateGroupId: tripDetails.value.rateGroupId,
                teamRegionId: tripDetails.value.teamRegionId === -1 ? null : tripDetails.value.teamRegionId,
                chargingTag: selectedRateTypeModel.value?.rateGroupType || ''
            };
            generalUtils.handleLoaderState(true, 'Creating Trip');
            const api = '/api/trips';
            const payload = {
                method: 'post',
                data
            };
            try {
                await handleRequests(api, payload);
                notify({
                    message: 'Trip created successfully',
                    type: 'success'
                });
                generalUtils.handleLoaderState(false);
                vm.proxy.$root.$router.push(`/trips/list/${tripDate}`);
            } catch (e) {
                generalUtils.handleLoaderState(false);
                showErrorMessage(vm.proxy.$root, 'Failed to create trip', e);
            }
        }

        async function generateRateRules() {
            // trip date, start address, customer, service package, team member
            if (
                tripDetails.value.rateGroupId === null ||
                tripDetails.value.startAddress.address === '' ||
                tripDetails.value.customerId === null ||
                tripDetails.value.tripDate === null
            ) {
                return;
            }
            await rateListHook.fetchRateRules(
                {
                    publicUserId: tripDetails.value.publicUserId || '',
                    customerId: tripDetails.value.customerId,
                    rateGroupId: tripDetails.value.rateGroupId,
                    tripDate: tripDetails.value.tripDate,
                    startTime: tripDetails.value.startTime || '',
                    endTime: tripDetails.value.endTime || '',
                    startLocation: tripDetails.value.startAddress.location,
                    startAddress: tripDetails.value.startAddress.address || '',
                    endLocation: tripDetails.value.endAddress.location,
                    endAddress: tripDetails.value.endAddress.address || '',
                    chargingTag: selectedRateTypeModel.value?.rateGroupType || ''
                },
                () => {},
                (e) => {
                    showErrorMessage(vm.proxy.$root, 'Failed to generate rates', e);
                }
            );
        }

        watch(
            () => tripDetails.value.tripDate,
            () => {
                _.debounce(generateRateRules, 1000)();
            }
        );
        watch(
            () => tripDetails.value.publicUserId,
            () => {
                _.debounce(generateRateRules, 1000)();
            }
        );
        watch(
            () => tripDetails.value.rateGroupId,
            () => {
                _.debounce(generateRateRules, 500)();
            }
        );

        watch(
            () => tripDetails.value.customerId,
            () => {
                _.debounce(generateRateRules, 1000)();
            }
        );

        watch(
            () => tripDetails.value.startAddress.address,
            () => {
                _.debounce(generateRateRules, 1000)();
            }
        );

        watch(
            () => tripDetails.value.endAddress.address,
            () => {
                _.debounce(generateRateRules, 1000)();
            }
        );

        return {
            startTimeFormatted,
            endTimeFormatted,
            errors,
            tripDetails,
            isFormTouched,
            isEndTimeValid,
            isStartTimeValid,
            selectedCustomerModel,
            selectedTeamRegionModel,
            selectedTeamMemberModel,
            selectedRateTypeModel,
            disabledDates,
            handleStartLocationChange,
            handleEndLocationChange,
            getStartTime,
            getEndTime,
            handleRateTypeChanged,
            handleTeamRegionChanged,
            handleTeamMemberChanged,
            handleCustomerChanged,
            createTrip,
            formatValue,
            rateListUIItems: rateListHook.rateListItems,
            fetchRateListStatus: rateListHook.fetchTripRateRulesStatus.status,
            fieldsToDisplayByDefault
        };
    },
    validations: {
        tripDetails: {}
    }
};
</script>

<style scoped lang="scss">
.page-container {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    grid-gap: 20px;
}

// for screens less than 1024px, make the .page-container grid-template-columns 1fr 1fr
@media (max-width: 1024px) {
    .page-container {
        grid-template-columns: 1fr 1fr;
        padding: 20px;
    }
}

// for screens less than 768px, make the .page-container grid-template-columns 1fr
@media (max-width: 768px) {
    .page-container {
        grid-template-columns: 1fr;
    }
}

.page-form-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: stretch;
}

.summary {
    position: sticky;
    top: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

h5,
h6,
p {
    margin: 0;
}

h6 {
    margin-top: 5px;
}

p {
    margin-top: 2.5px;
}

.summary-card--charges-cost-section {
    padding: 0 20px;
}

.charges-cost-heading {
    display: flex;
    justify-content: flex-end;
}

.charges-cost-table {
    margin: 5px;
    display: grid;
    grid-template-columns: 7fr 3fr;
    gap: 5px;
    justify-items: end;
}

span.charge-cost-amount {
    font-weight: bold;
}

// remove z index in md-card to prevent the vue-select dropdown options from hiding
::v-deep .md-card {
    z-index: initial !important;
    margin: 0;
}

::v-deep .md-card-actions {
    border: none;
}

.start-end-time {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;
}

// mobile responsive for small screens make .start-end-time flex-direction column
@media (max-width: 768px) {
    .start-end-time {
        flex-direction: column;
        align-items: flex-start;
    }
}
</style>
