var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v("Add members to your team"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          { staticClass: "form-wrapper", attrs: { validator: _vm.$v.form } },
          [
            _vm.showTeamRegion
              ? _c(
                  "form-group",
                  { attrs: { name: "teamRegion", label: "Team Region" } },
                  [
                    _c("team-region-options", {
                      attrs: {
                        selected: _vm.form.teamRegionId,
                        "show-auto": false,
                      },
                      on: { selectedOption: _vm.handleTeamRegionChanged },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "form-group",
              { attrs: { name: "role", label: "Team Role*" } },
              [
                _c(
                  "md-select",
                  {
                    on: { "md-selected": _vm.getSelectedRole },
                    model: {
                      value: _vm.form.role,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "role", $$v)
                      },
                      expression: "form.role",
                    },
                  },
                  _vm._l(_vm.memberRoles, function (memberRole) {
                    return _c(
                      "md-option",
                      { key: memberRole.id, attrs: { value: memberRole.name } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(memberRole.name) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "form-group",
              { attrs: { name: "email", label: "Email Address*" } },
              [
                _c("md-input", {
                  model: {
                    value: _vm.form.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "email", $$v)
                    },
                    expression: "form.email",
                  },
                }),
              ],
              1
            ),
            _c(
              "form-group",
              { attrs: { name: "firstName", label: "First name*" } },
              [
                _c("md-input", {
                  model: {
                    value: _vm.form.firstName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "firstName", $$v)
                    },
                    expression: "form.firstName",
                  },
                }),
              ],
              1
            ),
            _c(
              "form-group",
              { attrs: { name: "lastName", label: "Last name*" } },
              [
                _c("md-input", {
                  model: {
                    value: _vm.form.lastName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "lastName", $$v)
                    },
                    expression: "form.lastName",
                  },
                }),
              ],
              1
            ),
            _c(
              "md-checkbox",
              {
                on: { change: _vm.checkSetPassword },
                model: {
                  value: _vm.setPassword,
                  callback: function ($$v) {
                    _vm.setPassword = $$v
                  },
                  expression: "setPassword",
                },
              },
              [_vm._v("Set a password.")]
            ),
            _vm.setPassword
              ? _c(
                  "form-group",
                  { attrs: { name: "password", label: "Password" } },
                  [
                    _c("md-input", {
                      model: {
                        value: _vm.form.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "password", $$v)
                        },
                        expression: "form.password",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.errors.length > 0
              ? _c("div", [
                  _c(
                    "ul",
                    _vm._l(_vm.errors, function (error) {
                      return _c(
                        "li",
                        { key: error.code, staticClass: "error" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(error.description) +
                              "\n                    "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.validateEmail },
          },
          [_vm._v("Create")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }