var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calendar-sidebar" }, [
    _c("div", [
      _c("h4", [
        _vm._v("Unassigned Stops (" + _vm._s(_vm.totalUnassignedStops) + ")"),
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "outer" },
          [
            _c(
              "md-field",
              { staticClass: "full" },
              [
                _c("md-input", {
                  attrs: { placeholder: "Search for a stop" },
                  model: {
                    value: _vm.searchStopFilter,
                    callback: function ($$v) {
                      _vm.searchStopFilter = $$v
                    },
                    expression: "searchStopFilter",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { ref: "unassignedStops", staticClass: "calendar-sidebar-list" },
      [
        !_vm.loading
          ? _c("div", [
              _vm.searchResultList.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.searchResultList, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.stopId,
                          staticClass: "stop-box",
                          attrs: { "data-stop": "" + JSON.stringify(item) },
                        },
                        [
                          _c("p", { staticClass: "contactName" }, [
                            _c(
                              "span",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Stop Details",
                                        params: { stopId: item.stopId },
                                      },
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.stopRef) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.contact.name) +
                                "\n                    "
                            ),
                          ]),
                          _c("p", { staticClass: "contactAddress" }, [
                            _vm._v(_vm._s(item.address)),
                          ]),
                          _c("p", { staticClass: "date" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    item.tripDate,
                                    _vm.DATE_TYPES.standardDate
                                  )
                                ) +
                                "\n                        "
                            ),
                            item.timeWindowStart != null &&
                            item.timeWindowEnd != null
                              ? _c("span", [
                                  _vm._v(
                                    "\n                            (" +
                                      _vm._s(
                                        _vm._f("timeFormat")(
                                          item.timeWindowStart
                                        )
                                      ) +
                                      " - " +
                                      _vm._s(
                                        _vm._f("timeFormat")(item.timeWindowEnd)
                                      ) +
                                      ")\n                        "
                                  ),
                                ])
                              : item.appointmentTime != null
                              ? _c("span", [
                                  _vm._v(
                                    "\n                            (" +
                                      _vm._s(
                                        _vm._f("timeFormat")(
                                          item.appointmentTime
                                        )
                                      ) +
                                      ")\n                        "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", [
                    _c("span", { staticClass: "stop-box-empty" }, [
                      _vm._v("No Results Found"),
                    ]),
                  ]),
            ])
          : _c("div", [
              _c(
                "div",
                { staticClass: "stopbar-loader" },
                [
                  _c("fade-loader", {
                    attrs: { loading: _vm.loading, color: "#333333" },
                  }),
                  _c("span", [_vm._v("LOADING")]),
                ],
                1
              ),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }