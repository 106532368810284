<template>
    <div>
        <div class="md-layout">
            <div v-if="showDropdown">
                <div class="md-layout-item md-size-50">
                    <form-group label="Select an existing vehicle">
                        <md-select v-model="selected" @md-selected="getExistingDetails">
                            <md-option value="0">
                                Create New
                            </md-option>
                            <md-option v-for="x in dropdownList" :key="x.assetId" :value="x.assetId">
                                {{ x.name }}
                            </md-option>
                        </md-select>
                    </form-group>
                </div>
                <div class="md-layout-item md-size-100">
                    <h5>Vehicle Information:</h5>
                </div>
            </div>
            <div class="md-layout-item md-size-100">
                <form-group name="name" label="Asset Name*">
                    <md-input v-model="data.name" :disabled="isDisabled" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-50">
                <form-group name="make" label="Vehicle Make">
                    <md-input v-focus v-model="data.make" :disabled="isDisabled" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-50">
                <form-group name="model" label="Vehicle Model">
                    <md-input v-model="data.model" :disabled="isDisabled" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-50">
                <form-group name="modelYear" label="Vehicle Model Year">
                    <md-input v-model="data.modelYear" :disabled="isDisabled" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-50">
                <form-group name="color" label="Vehicle Color">
                    <md-input v-model="data.color" :disabled="isDisabled" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-50">
                <form-group name="vehicleVIN" label="Vehicle Identification Number">
                    <md-input v-model="data.vehicleVIN" :disabled="isDisabled" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-50">
                <form-group name="licensePlate" label="Vehicle License Plate">
                    <md-input v-model="data.licensePlate" :disabled="isDisabled" />
                </form-group>
            </div>
            <div class="md-layout-item md-size-50">
                <div style="text-align:left">
                    <md-datepicker v-model="data.vehicleRegistrationExpiryDate" :disabled="isDisabled">
                        <label>
                            Vehicle Registration Expiry Date
                        </label>
                    </md-datepicker>
                </div>
            </div>
            <div class="md-layout-item md-size-50">
                <form-group name="vehicleType" label="Vehicle Type">
                    <md-select v-model="data.vehicleType" :disabled="isDisabled">
                        <md-option value="Sedan">
                            Sedan
                        </md-option>
                        <md-option value="Van">
                            Van
                        </md-option>
                    </md-select>
                </form-group>
            </div>
            <div class="md-layout-item md-size-100" v-if="assetCustomFieldList !== null">
                <custom-field-inputs
                    v-for="(customField, index) in assetCustomFieldList"
                    :key="index"
                    :custom-field-definition="customField"
                    @changed="handleCustomFieldChanged"
                    :initial-value="data[customField.label]"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CustomFieldInputs } from '@/components';

export default {
    name: 'VehicleStep',
    components: {
        CustomFieldInputs
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        dropdownList: {
            type: Array,
            default: () => []
        },
        isDisabled: {
            type: Boolean,
            default: () => false
        },
        selectedAsset: {
            type: Number,
            default: () => 0
        },
        showDropdown: {
            type: Boolean,
            default: () => true
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    mounted() {
        this.selected = this.selectedAsset;
        if (this.user.assetCustomFieldDefinitions !== '' && this.user.assetCustomFieldDefinitions != null) {
            this.assetCustomFieldList = this.user.assetCustomFieldDefinitions.filter((item) => {
                return item.assetType === 'Vehicle' || item.assetType === 'Any';
            });
        }
    },
    data() {
        return {
            selected: 0,
            assetCustomFieldList: []
        };
    },
    methods: {
        setAssetName() {
            this.data.name = `${this.data.color ? this.data.color : ''} ${this.data.make ? this.data.make : ''} ${
                this.data.model ? this.data.model : ''
            } ${this.data.licensePlate ? `(${this.data.licensePlate})` : ''}`.trim();
        },
        getExistingDetails(assetId) {
            // eslint-disable-next-line radix
            this.$emit('getExistingDetails', parseInt(assetId));
        },
        handleCustomFieldChanged({ name, value }) {
            this.data[name] = value;
        }
    }
};
</script>
