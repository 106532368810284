var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stopDetails != null
    ? _c("div", { staticClass: "details-page" }, [
        _c("div", { staticClass: "md-layout" }, [
          _c("div", { staticClass: "md-layout-item" }, [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c("div", { staticClass: "custom-toolbar-start" }, [
                _vm.stopDetails.stopRef != null
                  ? _c(
                      "h3",
                      { staticClass: "title" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.stopDetails.type || "Stop") +
                            " " +
                            _vm._s(_vm.stopDetails.stopRef) +
                            "\n                        "
                        ),
                        _vm.stopDetails.sourceReference
                          ? [
                              _vm._v(
                                "\n                            / " +
                                  _vm._s(_vm.stopDetails.sourceReference) +
                                  "\n                        "
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _c("h3", { staticClass: "title" }, [
                      _vm._v(
                        _vm._s(_vm.stopDetails.type || "Stop") +
                          " " +
                          _vm._s(_vm.stopDetails.stopId)
                      ),
                    ]),
              ]),
              !_vm.$root.isMobileOnly
                ? _c("div", { staticClass: "custom-toolbar-end" }, [
                    _c(
                      "div",
                      { staticClass: "header-button-container" },
                      [
                        _c("stop-status-button", {
                          staticClass: "status-btn",
                          attrs: {
                            stop: _vm.stopDetails,
                            "class-name": "md-sm",
                          },
                          on: {
                            statusUpdated: _vm.handleChangedStatus,
                            statusHistoryUpdated:
                              _vm.handleStatusHistoryUpdated,
                            refreshStop: _vm.getStopDetails,
                          },
                        }),
                        _c("update-stop-button", {
                          attrs: {
                            stop: _vm.stopDetails,
                            "team-members": _vm.teamMembers,
                            "class-name": "header-button",
                            "use-icon": false,
                            "is-failed":
                              _vm.stopDetails.status.toLowerCase() === "failed",
                          },
                          on: { stopUpdated: _vm.reloadDetails },
                        }),
                        !_vm.isSupportStaff
                          ? _c("generate-label-button", {
                              attrs: {
                                id: _vm.stopDetails.stopId,
                                "use-icon": false,
                                type: "Stop",
                              },
                              on: {
                                "download-success":
                                  _vm.handleGeneratedStopLabel,
                              },
                            })
                          : _vm._e(),
                        !_vm.stopDetails.carrierTeamId
                          ? _c("stop-pdf-download-button", {
                              attrs: {
                                status: _vm.stopDetails.status.toLowerCase(),
                                "stop-type": _vm.stopDetails.type,
                                "stop-id": _vm.stopDetails.stopId,
                                "stop-ref": _vm.stopDetails.stopRef,
                                "use-icon": false,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "md-layout md-alignment-top-left content-layout-panel",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-66 md-xlarge-size-50",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-blue",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-icon" },
                              [_c("md-icon", [_vm._v("description")])],
                              1
                            ),
                          ]
                        ),
                        _c("md-card-content", [
                          _vm.stopDetails.shipmentId > 0
                            ? _c("div", { staticClass: "details-box" }, [
                                _c("label", [_vm._v("Shipment Ref")]),
                                _c(
                                  "span",
                                  { staticClass: "value" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Shipment Details",
                                            params: {
                                              shipmentId:
                                                _vm.stopDetails.shipmentId,
                                            },
                                          },
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.stopDetails.stopRef.slice(
                                                0,
                                                -2
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "address-details" }, [
                            _c("div", { staticClass: "details-box" }, [
                              _c("label", [_vm._v("Address")]),
                              _vm.stopDetails.name
                                ? _c("div", { staticClass: "address-name" }, [
                                    _vm._v(_vm._s(_vm.stopDetails.name)),
                                  ])
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$_displayAddressName(_vm.stopDetails)
                                  )
                                ),
                              ]),
                            ]),
                            _vm.stopDetails.durationMinutes > 0
                              ? _c(
                                  "div",
                                  { staticClass: "details-box duration" },
                                  [
                                    _c("label", [_vm._v("Stop duration")]),
                                    _c("span", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.stopDetails.durationMinutes +
                                              " minutes"
                                          ) +
                                          "\n                                "
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "details-box previous-stop" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "details-box previous-stop" },
                                  [
                                    _vm._v(
                                      "\n                                    Attempt #: " +
                                        _vm._s(
                                          _vm.stopDetails.stopAttemptCounter
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm.stopDetails.supersededStop
                                  ? _c("div", [
                                      _c("label", [_vm._v("Superseded Stop")]),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "Stop Details",
                                                  params: {
                                                    stopId:
                                                      _vm.stopDetails
                                                        .supersededStop.stopId,
                                                  },
                                                },
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.stopDetails
                                                      .supersededStop
                                                      .stopReference
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.stopDetails.previousAttemptStop
                                  ? _c("div", [
                                      _c("label", [
                                        _vm._v("Previous Attempted Stop"),
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "Stop Details",
                                                  params: {
                                                    stopId:
                                                      _vm.stopDetails
                                                        .previousAttemptStop
                                                        .stopId,
                                                  },
                                                },
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.stopDetails
                                                      .previousAttemptStop
                                                      .stopReference
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "stop-time-details" }, [
                            _vm.stopDetails.tripDate
                              ? _c("div", [
                                  _c("div", { staticClass: "details-box" }, [
                                    _c("label", [_vm._v("Trip date")]),
                                    _vm.stopDetails.tripId &&
                                    !_vm.isCarrierAssigned
                                      ? _c(
                                          "span",
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "Trip Details",
                                                    params: {
                                                      tripId:
                                                        _vm.stopDetails.tripId,
                                                    },
                                                  },
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm._f("dateFormat")(
                                                        _vm.stopDetails
                                                          .tripDate,
                                                        _vm.DATE_TYPES
                                                          .standardDate
                                                      )
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm._f("dateFormat")(
                                                  _vm.stopDetails.tripDate,
                                                  _vm.DATE_TYPES.standardDate
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.stopDetails.requestedAppointmentDate
                              ? _c("div", [
                                  _c("div", { staticClass: "details-box" }, [
                                    _c("label", [
                                      _vm._v("Requested Trip date"),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm._f("timeFormat")(
                                              _vm.stopDetails
                                                .requestedAppointmentDate,
                                              _vm.DATE_TYPES.standardDate
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.stopDetails.timeWindowStart &&
                            _vm.stopDetails.timeWindowEnd
                              ? _c("div", [
                                  _c("div", { staticClass: "details-box" }, [
                                    _c("label", [_vm._v("Time Window")]),
                                    _c("span", [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm._f("timeFormat")(
                                              _vm.stopDetails.timeWindowStart,
                                              _vm.DATE_TYPES.standardTime
                                            )
                                          ) +
                                          "\n                                        - " +
                                          _vm._s(
                                            _vm._f("timeFormat")(
                                              _vm.stopDetails.timeWindowEnd,
                                              _vm.DATE_TYPES.standardTime
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm.stopDetails.appointmentTime
                              ? _c("div", [
                                  _c("div", { staticClass: "details-box" }, [
                                    _c("label", [_vm._v("Appointment time")]),
                                    _c("span", [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm._f("timeFormat")(
                                              _vm.stopDetails.appointmentTime,
                                              _vm.DATE_TYPES.standardTime
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.stopDetails.originalEta && !_vm.hideETA
                              ? _c("div", [
                                  _c("div", { staticClass: "details-box" }, [
                                    _c("label", [
                                      _vm._v("Estimated Time of Arrival"),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm._f("timeFormat")(
                                              _vm.stopDetails.originalEta,
                                              _vm.DATE_TYPES.standardDateTime
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.stopDetails.arrivalDate && _vm.hideETA
                              ? _c("div", [
                                  _c("div", { staticClass: "details-box" }, [
                                    _c("label", [
                                      _vm._v("Actual Arrival Time"),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm._f("timeFormat")(
                                              _vm.stopDetails.arrivalDate,
                                              _vm.DATE_TYPES.standardDateTime
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.stopDetails.runNumber || _vm.stopDetails.runName
                              ? _c("div", { staticClass: "run-number" }, [
                                  _c("div", { staticClass: "details-box" }, [
                                    _c("label", [_vm._v("Run")]),
                                    _vm.stopDetails.runName
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(_vm.stopDetails.runName) +
                                              "\n                                    "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.stopDetails.runNumber
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              [
                                _vm.isCarrierAssigned
                                  ? [
                                      _vm.carrierTrackingUrl
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: _vm.carrierTrackingUrl,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c("md-icon", [_vm._v("place")]),
                                              _vm._v(
                                                "\n                                        Tracking Link\n                                    "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  : [
                                      _vm.stopDetails.trackingLinkUrl
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: _vm.setTrackingLinkUrl(
                                                  _vm.stopDetails
                                                    .trackingLinkUrl
                                                ),
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c("md-icon", [_vm._v("place")]),
                                              _vm._v(
                                                "\n                                        Tracking Link\n                                    "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.stopDetails.trackingLinkUrl ==
                                            null && !_vm.isReadOnlyUser
                                        ? _c(
                                            "a",
                                            {
                                              on: {
                                                click: _vm.createTrackingLink,
                                              },
                                            },
                                            [
                                              _c("md-icon", [
                                                _vm._v("add_location_alt"),
                                              ]),
                                              _vm._v(
                                                "\n                                        Create tracking link\n                                    "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                              ],
                              2
                            ),
                          ]),
                          _vm.stopDetails.rateGroup
                            ? _c("div", { staticClass: "details-box" }, [
                                _c("label", [_vm._v("Service Package")]),
                                _c("span", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.stopDetails.rateGroup.rateGroupName
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.stopDetails.skills.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "details-box md-chips md-primary md-theme-default md-has-placeholder md-theme-default",
                                },
                                [
                                  _c("label", [_vm._v("Required skills:")]),
                                  _vm._l(
                                    _vm.stopDetails.skills,
                                    function (skill, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "md-chip md-theme-default custom-chip",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(skill) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.stopCustomFieldList !== null
                            ? _c(
                                "div",
                                { staticClass: "stop-time-details" },
                                _vm._l(
                                  _vm.stopCustomFieldList,
                                  function (customField, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "details-box",
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(_vm._s(customField.label)),
                                        ]),
                                        _vm.customFieldValues !== null &&
                                        _vm.customFieldValues[
                                          customField.name
                                        ] !== null
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.customFieldValues[
                                                      customField.name
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ])
                                          : _c("span", [_vm._v(" ")]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.stopDetails.shipmentId &&
                          _vm.shipmentCustomFieldList &&
                          _vm.shipmentCustomFieldList.length
                            ? _c(
                                "div",
                                { staticClass: "stop-time-details" },
                                _vm._l(
                                  _vm.shipmentCustomFieldList,
                                  function (customField, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "details-box",
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(_vm._s(customField.label)),
                                        ]),
                                        _vm.shipmentCustomFieldValues !==
                                          null &&
                                        _vm.shipmentCustomFieldValues[
                                          customField.name
                                        ] !== null
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm
                                                      .shipmentCustomFieldValues[
                                                      customField.name
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ])
                                          : _c("span", [_vm._v(" ")]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.loadCapacityList !== null
                            ? _c(
                                "div",
                                { staticClass: "stop-time-details" },
                                _vm._l(
                                  _vm.loadCapacityList,
                                  function (loadCapacity, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "details-box",
                                      },
                                      [
                                        loadCapacity.type !== "dimensions"
                                          ? _c("div", [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(loadCapacity.label)
                                                ),
                                              ]),
                                              loadCapacity !== null &&
                                              _vm.loadValues[
                                                loadCapacity.type
                                              ] !== null
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.loadValues[
                                                            loadCapacity.type
                                                          ]
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          loadCapacity.symbol
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        loadCapacity.type === "dimensions"
                                          ? _c("div", [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(loadCapacity.label)
                                                ),
                                              ]),
                                              loadCapacity !== null &&
                                              _vm.loadValues[
                                                loadCapacity.type
                                              ] !== null
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                                        L: " +
                                                        _vm._s(
                                                          _vm.loadValues[
                                                            "length"
                                                          ]
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          loadCapacity.symbol
                                                        ) +
                                                        " W:\n                                        " +
                                                        _vm._s(
                                                          _vm.loadValues[
                                                            "width"
                                                          ]
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          loadCapacity.symbol
                                                        ) +
                                                        " H:\n                                        " +
                                                        _vm._s(
                                                          _vm.loadValues[
                                                            "height"
                                                          ]
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          loadCapacity.symbol
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.teamRegionName
                            ? _c("div", { staticClass: "details-box" }, [
                                _c("label", [_vm._v("Team Region")]),
                                _c("div", [_vm._v(_vm._s(_vm.teamRegionName))]),
                              ])
                            : _vm._e(),
                          _vm.stopDetails.brandId != null
                            ? _c("div", { staticClass: "stop-time-details" }, [
                                _c("div", [
                                  _c("div", { staticClass: "details-box" }, [
                                    _c("label", [_vm._v("Brand")]),
                                    _vm.stopDetails.brandLogoUrl
                                      ? _c("img", {
                                          staticClass: "brand-logo",
                                          attrs: {
                                            src:
                                              _vm.stopDetails.brandLogoUrl !==
                                              null
                                                ? _vm.stopDetails.brandLogoUrl
                                                : _vm.$root.defaultPhotoUrl,
                                            title: _vm.stopDetails.brandName,
                                            alt: _vm.stopDetails.brandName,
                                          },
                                          on: {
                                            error: _vm.$_setDefaultBrokenImage,
                                          },
                                        })
                                      : _vm._e(),
                                    !_vm.stopDetails.brandLogoUrl
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.stopDetails.brandName)
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "details-box" }, [
                            _c("label", [_vm._v("Notes")]),
                            _vm.stopDetails.notes
                              ? _c("span", { staticClass: "stop-notes" }, [
                                  _vm._v(_vm._s(_vm.stopDetails.notes)),
                                ])
                              : _c("span", { staticClass: "grayedout-text" }, [
                                  _vm._v("No notes found"),
                                ]),
                          ]),
                          _vm.stopDetails.teamMemberInvoiceId
                            ? _c("div", { staticClass: "details-box" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Team Member Invoice:"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "value" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          "custom-badge payment-status-" +
                                          _vm.stopDetails.teamMemberInvoiceStatus
                                            .toLowerCase()
                                            .replace(/\s+/g, ""),
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "inputs.paymentStatuses." +
                                                  _vm.stopDetails
                                                    .teamMemberInvoiceStatus
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Team Member Invoice Details",
                                            params: {
                                              invoiceId:
                                                _vm.stopDetails
                                                  .teamMemberInvoiceId,
                                            },
                                          },
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.stopDetails
                                                .teamMemberInvoiceNumber
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.stopDetails.customerInvoiceId
                            ? _c("div", { staticClass: "details-box" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Customer Invoice:"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "value" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          "custom-badge payment-status-" +
                                          _vm.stopDetails.customerInvoiceStatus
                                            .toLowerCase()
                                            .replace(/\s+/g, ""),
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "inputs.paymentStatuses." +
                                                  _vm.stopDetails
                                                    .customerInvoiceStatus
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Customer Invoice Details",
                                            params: {
                                              invoiceId:
                                                _vm.stopDetails
                                                  .customerInvoiceId,
                                            },
                                          },
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.stopDetails
                                                .customerInvoiceNumber
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                !_vm.$root.isDesktop
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                      },
                      [
                        _c("driver-details", {
                          attrs: {
                            "stop-details": _vm.stopDetails,
                            "team-members": _vm.teamMembers,
                          },
                          on: { handleAssignedUser: _vm.handleAssignedUser },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.$root.isDesktop
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                      },
                      [
                        _c("contact-details", {
                          attrs: { "stop-details": _vm.stopDetails },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$root.isDesktop
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100 map-box",
                      },
                      [
                        _c("map-overlay", {
                          attrs: {
                            "stop-details": _vm.stopDetails,
                            "stop-status-history": _vm.stopStatusHistory,
                            "clicked-history": _vm.clickedHistory,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100",
                  },
                  [
                    _c("notes", {
                      attrs: {
                        list: _vm.stopDetails.stopNotes,
                        "stop-id": _vm.stopDetails.stopId,
                      },
                      on: { notesAdded: _vm.handleAddedStopNote },
                    }),
                  ],
                  1
                ),
                _vm.stopDetails.assignedTo.fullName && !_vm.$root.isDesktop
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100 map-box",
                      },
                      [
                        _c("map-overlay", {
                          attrs: {
                            "stop-details": _vm.stopDetails,
                            "stop-status-history": _vm.stopStatusHistory,
                            "clicked-history": _vm.clickedHistory,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isIndividualUser && !_vm.stopDetails.shipmentId
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100",
                      },
                      [
                        _vm.isTeamOwner || _vm.isAdmin || _vm.isReadOnlyUser
                          ? _c("charge-rates-list", {
                              attrs: {
                                "charge-lines": _vm.stopDetails.chargeLines,
                                "stop-details": _vm.stopDetails,
                                title: "Charges",
                                "has-invoice":
                                  _vm.stopDetails.customerInvoiceId !== null,
                                "rates-have-changed":
                                  _vm.updateChargesInBackground,
                              },
                              on: { updateStopDetails: _vm.getStopDetails },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isIndividualUser
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100 stop-line-box",
                      },
                      [
                        _c("stop-lines", {
                          attrs: {
                            lines: _vm.stopDetails.items,
                            "stop-id": _vm.stopDetails.stopId,
                            "belongs-to-shipment":
                              _vm.stopDetails.shipmentId !== null,
                            "stop-status": _vm.stopDetails.status,
                            "is-invoiced":
                              _vm.stopDetails.teamMemberInvoiceId !== null ||
                              _vm.stopDetails.customerInvoiceId !== null,
                          },
                          on: { createdLine: _vm.getStopDetails },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "feature",
                        rawName: "v-feature",
                        value: [_vm.featureNames.Orders],
                        expression: "[featureNames.Orders]",
                      },
                    ],
                    staticClass:
                      "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100 stop-order-box",
                  },
                  [
                    _c("order-items", {
                      attrs: { orders: _vm.stopDetails.orders },
                    }),
                  ],
                  1
                ),
                !_vm.isIndividualUser
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100",
                      },
                      [
                        _c("services-lines", {
                          attrs: {
                            lines: _vm.stopDetails.lines,
                            "stop-id": _vm.stopDetails.stopId,
                            "belongs-to-shipment":
                              _vm.stopDetails.shipmentId !== null,
                            "stop-status": _vm.stopDetails.status,
                            "is-invoiced":
                              _vm.stopDetails.teamMemberInvoiceId !== null ||
                              _vm.stopDetails.customerInvoiceId !== null,
                          },
                          on: { createdLine: _vm.getStopDetails },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _vm.$root.isDesktop
              ? _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-layout md-medium-size-100 md-large-size-33 md-xlarge-size-50",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50",
                      },
                      [
                        _c("driver-details", {
                          attrs: {
                            "stop-details": _vm.stopDetails,
                            "team-members": _vm.teamMembers,
                          },
                          on: { handleAssignedUser: _vm.handleAssignedUser },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50",
                      },
                      [
                        _c("contact-details", {
                          attrs: { "stop-details": _vm.stopDetails },
                        }),
                      ],
                      1
                    ),
                    _vm.$root.isDesktop
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50",
                          },
                          [
                            _c(
                              "md-card",
                              [
                                _c(
                                  "md-card-header",
                                  {
                                    staticClass:
                                      "md-card-header-icon md-card-header-warning",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "card-icon" },
                                      [_c("md-icon", [_vm._v("history")])],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "md-card-content",
                                  [
                                    _vm.stopStatusHistory.length > 0
                                      ? _c(
                                          "time-line",
                                          {
                                            attrs: {
                                              plain: "",
                                              type: "simple",
                                            },
                                          },
                                          _vm._l(
                                            _vm.stopStatusHistory,
                                            function (history, index) {
                                              return _c(
                                                "time-line-item",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    inverted: "",
                                                    "badge-type":
                                                      "status-" +
                                                      history.newStatus
                                                        .toLowerCase()
                                                        .replace(/\s+/g, ""),
                                                    "badge-icon": "place",
                                                    "is-clickable": true,
                                                  },
                                                  on: {
                                                    clicked: function ($event) {
                                                      return _vm.showHistoryInMap(
                                                        history
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "badge",
                                                      class:
                                                        "status-" +
                                                        history.newStatus
                                                          .toLowerCase()
                                                          .replace(/\s+/g, ""),
                                                      attrs: { slot: "header" },
                                                      slot: "header",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            history.newStatus
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "time",
                                                      attrs: {
                                                        slot: "headerTime",
                                                      },
                                                      slot: "headerTime",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "dateTimeFormat"
                                                            )(
                                                              history.actionDate,
                                                              _vm.DATE_TYPES
                                                                .standardDate
                                                            )
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "h6",
                                                    {
                                                      attrs: { slot: "footer" },
                                                      slot: "footer",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass: "ti-time",
                                                      }),
                                                      _vm._v(
                                                        "\n\n                                    By " +
                                                          _vm._s(
                                                            history.changedByUserFullName
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _c(
                                          "p",
                                          { staticClass: "grayedout-text" },
                                          [
                                            _vm._v(
                                              "No Status History Available"
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$root.isDesktop
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50",
                          },
                          [
                            _c("assignment-history-timeline", {
                              attrs: {
                                list: _vm.stopDetails.stopAssignmentHistory,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$root.isDesktop
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50",
                          },
                          [
                            _c(
                              "md-card",
                              [
                                _c(
                                  "md-card-header",
                                  {
                                    staticClass:
                                      "md-card-header-icon md-card-header-blue",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "card-icon icon-style" },
                                      [
                                        _c("md-icon", [_vm._v("checklist")]),
                                        _vm._v(
                                          "\n                            Driver's Instructions\n                        "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "md-card-content",
                                  [
                                    _c("DriverInstructions", {
                                      attrs: {
                                        "driver-instructions":
                                          _vm.stopDetails.driverInstructions,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isIndividualUser && !_vm.stopDetails.shipmentId
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [
                            _vm.isTeamOwner || _vm.isAdmin
                              ? _c("cost-rates-list", {
                                  attrs: {
                                    "cost-lines": _vm.stopDetails.costLines,
                                    "stop-details": _vm.stopDetails,
                                    title: "Costs",
                                    "has-invoice":
                                      _vm.stopDetails.teamMemberInvoiceId !==
                                      null,
                                    "rates-have-changed":
                                      _vm.updateCostsInBackground,
                                  },
                                  on: { updateStopDetails: _vm.getStopDetails },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }