<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Add members to your team</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.form" class="form-wrapper">
                <form-group v-if="showTeamRegion" name="teamRegion" label="Team Region">
                    <team-region-options
                        :selected="form.teamRegionId"
                        @selectedOption="handleTeamRegionChanged"
                        :show-auto="false"
                    />
                </form-group>
                <form-group name="role" label="Team Role*">
                    <md-select v-model="form.role" @md-selected="getSelectedRole">
                        <md-option v-for="memberRole in memberRoles" :key="memberRole.id" :value="memberRole.name">
                            {{ memberRole.name }}
                        </md-option>
                    </md-select>
                </form-group>
                <form-group name="email" label="Email Address*">
                    <md-input v-model="form.email" />
                </form-group>
                <form-group name="firstName" label="First name*">
                    <md-input v-model="form.firstName" />
                </form-group>
                <form-group name="lastName" label="Last name*">
                    <md-input v-model="form.lastName" />
                </form-group>
                <md-checkbox v-model="setPassword" @change="checkSetPassword">Set a password.</md-checkbox>
                <form-group v-if="setPassword" name="password" label="Password">
                    <md-input v-model="form.password" />
                </form-group>
                <div v-if="errors.length > 0">
                    <ul>
                        <li v-for="error in errors" :key="error.code" class="error">
                            {{ error.description }}
                        </li>
                    </ul>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validateEmail">Create</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>
<script>
import { handleRequests, showTeamRegionControl } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import { TeamRegionOptions } from '@/components';
// eslint-disable-next-line import/no-dynamic-require
const { config } = require(`../../config/config.${process.env.NODE_ENV}`);
export default {
    name: 'AddTeamMemberModal',
    mixins: [GeneralMixin],
    props: {
        memberRoles: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    components: { TeamRegionOptions },
    validations: {
        form: {
            email: {
                required,
                email
            },
            role: {
                required
            },
            firstName: {
                required
            },
            lastName: {
                required
            }
        }
    },
    data() {
        return {
            form: {
                email: null,
                role: 'Team Member',
                firstName: null,
                lastName: null,
                password: null,
                teamRegionId: null
            },
            setPassword: false,
            errors: []
        };
    },
    computed: {
        ...mapGetters({
            teamRegions: 'team/teamRegions',
            user: 'user/user',
            hasAssetAccess: 'user/hasAssetAccess'
        }),
        showTeamRegion() {
            return showTeamRegionControl(
                {
                    user: this.user,
                    hasAssetAccess: this.hasAssetAccess,
                    teamRegions: this.teamRegions
                },
                this.form.teamRegionId
            );
        }
    },
    methods: {
        checkSetPassword(e) {
            if (e === false) {
                this.errors = [];
                this.form.password = null;
            }
        },
        getSelectedRole(role) {
            this.form.role = role;
        },
        validateEmail() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.addUser();
            }
        },
        addUser() {
            this.$_handleLoaderState(true, 'SAVING...');
            const payload = {
                method: 'post',
                data: this.form
            };
            const api = `${config.authority}api/team/users/create`;
            handleRequests(api, payload).then(
                (response) => {
                    const result = response.data;
                    this.$_handleLoaderState(false);
                    this.$messageBox
                        .show({
                            title: 'New User Created Successfully!',
                            body: `Email: ${
                                this.form.email
                            } </br> Temporary Password: ${result} </br></br> The temporary password will be displayed once. Please copy and save it before logging in.\n`,
                            buttons: ['Confirm']
                        })
                        .then(() => {
                            this.form.email = null;
                            this.$v.$reset();
                            this.resolve(result);
                        });
                },
                (error) => {
                    this.$_handleLoaderState(false);
                    this.errors = error.data;
                }
            );
        },
        handleTeamRegionChanged(teamRegionId) {
            this.form.teamRegionId = teamRegionId;
        }
    },
    mounted() {
        if (this.showTeamRegion) {
            this.form.teamRegionId = this.user.teamRegionId;
        }
    }
};
</script>
