var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "config--container" }, [
    _c(
      "div",
      { staticClass: "config-button--container" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-icon-button md-dense md-raised md-primary",
            attrs: { disabled: _vm.disableClick, title: "Config Settings" },
            on: { click: _vm.openRateConfig },
          },
          [
            _c("md-icon", { staticClass: "config" }, [
              _vm._v("\n                settings\n            "),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }