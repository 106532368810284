<template>
    <div class="map-calendar map-panel">
        <md-datepicker v-model="tripDate" md-immediately />
    </div>
</template>

<script>
import moment from 'moment';
import { ref, watch, inject } from '@vue/composition-api';
import useGeneralUtils from '@/compostables/useGeneralUtils';

export default {
    name: 'TripDatePicker',
    setup(_, { root }) {
        const { commit } = inject('vuex-store');
        const { dateTypes } = useGeneralUtils(root);

        let date = new Date();

        // we need to format the given date of the url to a valid date string accepted by the Date object
        if (root.$route.params.date) {
            date = new Date(root.$route.params.date);
        }

        const tripDate = ref(date);

        watch(tripDate, (newVal, oldVal) => {
            // new date is a different date, so do the necessary steps
            if (moment(newVal.setHours(0, 0, 0, 0)).valueOf() !== moment(oldVal.setHours(0, 0, 0, 0)).valueOf()) {
                // this.isLoading = true;

                commit('map/CHANGE_DATE', { date: newVal });

                const date = root.$options.filters.dateFormat(newVal, dateTypes.internationalDate);
                // Update router to keep date and pinned team member after a page refresh.
                let { params } = root.$router.currentRoute;

                if (params.date) {
                    params = { ...params, date };
                    root.$router.push({ params });
                } else {
                    root.$router.replace({ path: `/map/${date}` });
                }
                // if (this.timer)
                //     this.timer.stop();
                // this.changeTimeWindow({ timeWindow: [0, 24] });
                // this.callMethodForActivePanel();
            }
        });

        return {
            tripDate
        };
    }
};
</script>

<style lang="scss" scoped>
.map-calendar {
    position: absolute;
    top: 10px;
    left: 15px;
    height: 40px;
    background-color: white;
    z-index: 1;
    width: 150px;
    padding: 0 8px;

    ::v-deep .md-field {
        margin: 0;
        padding: 0;
        button {
            display: none;
        }
        &.md-datepicker:before {
            margin-left: 28px;
        }
        input {
            margin-right: 10px;
            width: 100% !important;
            padding: 0;
            margin-bottom: 5px;
        }
    }
}
</style>
