<template>
    <div class="custom-button-container" v-if="!$root.isMobileOnly">
        <span
            v-if="type === 'text' && !isSingleUser && !isSingleTeamMember"
            :class="`value custom-a-blue ${className}`"
            @click.stop="
                handleAssignStop({
                    stopId: item.stopId,
                    assignedTo: item.assignedTo.publicUserId,
                    assignedCarrierTeamId: item.carrierTeamId,
                    stopRef: item.stopRef,
                    teamRegionId: item.teamRegionId
                })
            "
        >
            <a v-if="Boolean(item.assignedTo.publicUserId)" href="#" title="reassign stop" class="custom-ellipsis">
                <img
                    v-if="item.assignedTo.fullName"
                    class="profile-image"
                    :src="item.assignedTo.photoUrl !== null ? item.assignedTo.photoUrl : $root.defaultPhotoUrl"
                    alt="avatar"
                    @error="$_setDefaultBrokenImage"
                />
                {{ item.assignedTo.fullName }}
            </a>
            <a v-else-if="item.carrierTeamId" href="#" title="reassign stop" class="custom-ellipsis">
                <img
                    v-if="item.carrierTeam"
                    class="profile-image"
                    :src="item.carrierTeam.logoUrl !== null ? item.carrierTeam.logoUrl : $root.defaultLogoUrl"
                    alt="carrier logo"
                    @error="$_setDefaultBrokenImage"
                />
                {{ item.carrierTeam.company }}
            </a>
            <a v-else href="#" title="reassign stop">Unassigned</a>
        </span>
        <span
            v-if="type === 'text' && (isSingleUser || isSingleTeamMember)"
            :class="`custom-ellipsis value ${className}`"
        >
            <img
                v-if="item.assignedTo.fullName"
                class="profile-image"
                :src="item.assignedTo.photoUrl !== null ? item.assignedTo.photoUrl : $root.defaultPhotoUrl"
                alt="avatar"
                @error="$_setDefaultBrokenImage"
            />
            {{ item.assignedTo.fullName }}
        </span>

        <md-button
            v-if="type === 'icon' && !isSupportStaff"
            title="Re-assign driver"
            :class="`md-info md-just-icon md-round ${className}`"
            @click.stop="
                handleAssignStop({
                    stopId: item.stopId,
                    assignedTo: item.assignedTo.publicUserId,
                    assignedCarrierTeamId: item.carrierTeamId,
                    stopRef: item.stopRef,
                    teamRegionId: item.teamRegionId
                })
            "
        >
            <md-icon>person_pin</md-icon>
        </md-button>

        <md-button
            v-if="type === 'button' && !isCustomer && !isSupportStaff"
            title="Re-assign driver"
            :class="`custom-btn ${className}`"
            @click.stop="
                handleAssignStop({
                    stopId: item.stopId,
                    assignedTo: item.assignedTo.publicUserId,
                    assignedCarrierTeamId: item.carrierTeamId,
                    stopRef: item.stopRef,
                    teamRegionId: item.teamRegionId
                })
            "
        >
            Assign
        </md-button>
    </div>
    <div
        class="reassign-btn-mobile"
        v-else
        @click.stop="
            handleAssignStop({
                stopId: item.stopId,
                assignedTo:
                    item.assignedTo != null && item.assignedTo.publicUserId != null
                        ? item.assignedTo.publicUserId
                        : null,
                assignedCarrierTeamId: item.carrierTeamId,
                stopRef: item.stopRef,
                teamRegionId: item.teamRegionId
            })
        "
    >
        <span class="blue-span" v-if="item.assignedTo != null && item.assignedTo.fullName">Reallocate</span>
        <span v-else>Allocate</span>
    </div>
</template>

<script>
import { AssignStopModal } from '@/pages/Stops/components';
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'ReassignStopButton',
    mixins: [GeneralMixin],
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        teamMembers: {
            type: Array,
            default: () => []
        },
        useIcon: {
            type: Boolean,
            default: () => true
        },
        className: {
            type: String,
            default: () => ''
        },
        type: {
            type: String,
            default: () => 'button'
        }
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isCustomer: 'user/isCustomer',
            isSupportStaff: 'user/isSupportStaff'
        })
    },
    methods: {
        handleAssignStop({ stopId, assignedTo, assignedCarrierTeamId, stopRef, teamRegionId }) {
            if (this.isCustomer || this.isSupportStaff) 
                return;

            if (this.teamMembers.length > 0) {
                this.$modal
                    .show(AssignStopModal, {
                        stopId,
                        teamMembers: this.teamMembers,
                        assignedTo,
                        assignedCarrierTeamId,
                        stopRef,
                        teamRegionId
                    })
                    .then((response) => {
                        this.$emit('assignedUser', response);
                    });
            } else {
                this.$notify({
                    message: 'Reassign function is still loading. Please try again later.',
                    type: 'warning'
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    background-color: #2b93ff !important;
    color: #fff !important;
    border: none;
    width: 100%;
    max-width: 90px;
    text-align: center;
    line-height: 40px;
    font-size: 15px;
    cursor: pointer;
    text-transform: initial;
    height: 31px !important;
    padding: 0;
    font-weight: 300;
}

.custom-btn:hover,
.custom-btn:focus {
    color: #fff !important;
    background-color: #0076f0 !important;
}

.custom-a-blue a {
    color: #0076f0;
}

.blue-span {
    background-color: #2b93ff !important;
    color: #fff;
}
.profile-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
    object-fit: contain;
    background-color: #eee;
}
</style>
