<template>
    <div>
        <div v-if="!isListLoading">
            <md-table class="custom-paginated-table context-menu-support">
                <md-table-row>
                    <md-table-head>Name</md-table-head>
                    <md-table-head v-if="type == 'tracker'">
                        IMEI
                    </md-table-head>
                    <md-table-head>Asset Group</md-table-head>
                    <!--<md-table-head>Status</md-table-head>-->
                    <md-table-head v-if="assetList.length > 0 && assetList[0].type == 'Tracker'">
                        Connected
                    </md-table-head>
                    <md-table-head
                        :class="assetList.length > 0 && assetList[0].type == 'Tracker' ? '' : 'agroup-name-max'"
                    >
                        Actions
                    </md-table-head>
                </md-table-row>

                <md-table-row v-for="(item, index) in assetList" :key="index" @click.stop="showAssetDetails(item)">
                    <md-table-cell md-label="Name" md-sort-by="name">
                        <router-link
                            :to="{
                                name: 'Asset Details',
                                params: { assetId: item.assetId }
                            }"
                            target="_blank"
                        >
                            {{ item.name }}
                        </router-link>
                    </md-table-cell>
                    <md-table-cell v-if="type == 'tracker'" md-label="IMEI">
                        {{ item.trackerReference }}
                    </md-table-cell>
                    <md-table-cell md-label="Group Name">
                        <router-link
                            :to="{
                                name: 'Asset Group Details',
                                params: { assetGroupId: item.assetGroupId }
                            }"
                            target="_blank"
                        >
                            {{ item.assetGroupName }}
                        </router-link>
                    </md-table-cell>
                    <!--<md-table-cell md-label="Status">
                        {{ item.status }}
                    </md-table-cell>-->
                    <md-table-cell v-if="item.type == 'Tracker'" md-label="Connected">
                        <md-icon :class="item.isTrackerConnected ? 'connected' : 'disconnected'">
                            {{ item.isTrackerConnected ? 'check_circle' : 'highlight_off' }}
                        </md-icon>
                        <md-tooltip md-direction="left" v-if="!item.lastUpdated.startsWith('0001')">
                            Last Updated: {{ item.lastUpdated | dateFormat(DATE_TYPES.internationalDate) }}
                            {{ item.lastUpdated | dateFormat(DATE_TYPES.militaryTime) }}
                        </md-tooltip>
                    </md-table-cell>
                    <md-table-cell md-label="Actions" class="action-buttons">
                        <update-asset-button
                            :asset="item"
                            :custom-fields="customFields"
                            @onAssetUpdate="handleUpdatedAsset"
                        />
                        <delete-asset-button
                            :asset="item"
                            @onAssetDelete="handleDeleteAsset"
                            v-if="item.type == 'Tracker'"
                        />
                        <create-asset-log-button
                            :asset="item"
                            :custom-fields="customFields"
                            v-if="item.type == 'Vehicle'"
                        />
                        <create-assign-maintenance-schedule-button
                            v-if="item.type == 'Vehicle'"
                            :maintenance-schedules="maintenanceScheduledList"
                            :asset="item"
                            @onAssignSchedule="handleAssignMaintenanceSchedule"
                        />
                    </md-table-cell>
                </md-table-row>
            </md-table>
            <md-card-actions class="page-footer" md-alignment="space-between" v-if="assetList.length > 0">
                <div>
                    <p v-if="total === pagination.perPage" class="card-category">
                        Page {{ pagination.currentPage }} of many
                    </p>
                    <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                </div>
                <pagination
                    v-model="pagination.currentPage"
                    class="pagination-no-border pagination-success"
                    :per-page="pagination.perPage"
                    :total="total"
                    @change-page="handleChangePage($event, pagination.perPage)"
                />
            </md-card-actions>
        </div>
        <div v-else class="body-list">
            <div class="content-loader">
                <fade-loader :loading="isListLoading" color="#333333" />
                <span>LOADING</span>
            </div>
        </div>
        <div v-if="assetList.length == 0 && !isListLoading" class="text-center">
            <p class="no-result-message">No results matching your search/filter could be found.</p>
        </div>
        <transition name="slide">
            <asset-sidebar
                v-if="showSideBar"
                :asset-id="assetId"
                :asset-type="assetType"
                :maintenance-schedules="maintenanceScheduledList"
                @closeModal="toggleAssetDetailsWindow"
                @updateStop="handleUpdatedAsset"
                @onAssignSchedule="handleAssignMaintenanceSchedule"
            />
        </transition>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests } from '@/helpers';
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { Pagination } from '@/components';
import { mapGetters } from 'vuex';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import CreateAssignMaintenanceScheduleButton from '@/pages/Maintenance/buttons/AssignMaintenanceScheduleButton';
import UpdateAssetButton from './Buttons/UpdateAssetButton';
import DeleteAssetButton from './Buttons/DeleteAssetButton';
import CreateAssetLogButton from './Buttons/CreateAssetLogButton';
import AssetSidebar from './AssetSidebar';

export default {
    name: 'AssetListTable',
    components: {
        FadeLoader,
        Pagination,
        UpdateAssetButton,
        DeleteAssetButton,
        AssetSidebar,
        CreateAssetLogButton,
        CreateAssignMaintenanceScheduleButton
    },
    mixins: [GeneralMixin],
    props: {
        type: {
            type: String,
            default: () => ''
        },
        newAssetId: {
            type: Number,
            default: () => null
        },
        customFields: {
            type: Array,
            default: () => []
        },
        filters: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            pagination: PAGINATION_DEFAULTS,
            maxPage: 1,
            prevFilters: {
                status: 'active',
                teamRegionId: undefined,
                memberPublicUserId: undefined,
                searchText: undefined
            },
            isListLoading: false,
            assetList: [],
            showSideBar: false,
            assetId: null,
            assetType: null,
            maintenanceScheduledList: []
        };
    },
    watch: {
        filters: {
            deep: true,
            handler(newValue, oldValue) {
                this.handleFilterOrSearch(newValue);
            }
        },
        newAssetId(newValue) {
            // this.handleChangePage();
            this.fetchAssetListByType(this.pagination.currentPage || 1, this.pagination.perPage || 50, true);
        }
    },
    async mounted() {
        const { type } = this.$route.params;

        if (this.user.teamRegionId) {
            this.prevFilters.teamRegionId = this.user.teamRegionId;
        }

        if (this.filters.teamRegionId) {
            this.prevFilters.teamRegionId = this.filters.teamRegionId;
        }

        let currentPage = 1;
        // When switching between tabs there is a delay in replacing the route hence we need to
        // compare the type passed in the props and the type in the url if it does not match then current page should be set to 1.
        if (this.type === type) {
            currentPage = Number(this.$route.query.currentPage) || 1;
        }

        if (currentPage) {
            this.pagination.currentPage = currentPage;
        }

        await this.handleFilterOrSearch({ teamRegionId: this.prevFilters.teamRegionId });
    },
    methods: {
        async fetchAssetListByType(pageNumber = 1, itemsPerPage = 50, forced = false) {
            this.isListLoading = true;
            if (this.type === 'vehicle') {
                await this.fetchMaintenanceSchedule();
            }

            const newFilters = { ...this.filters, pageNumber, itemsPerPage };
            let params = {};
            let shouldSearch = false;
            Object.entries(newFilters).forEach(([key, value]) => {
                shouldSearch = shouldSearch || (value || null) !== (this.prevFilters[key] || null);
                params = { ...params, [key]: value };
            });

            if (!forced && !shouldSearch) {
                this.isListLoading = false;
                return; // exit function
            }

            this.prevFilters = { ...this.prevFilters, ...newFilters };

            const api = `/api/assets/list?type=${this.type}`;
            const {
                data: { assets, totalAssets }
            } = await handleRequests(api, {
                params
            });
            this.assetList = assets;
            this.assignToList(assets, totalAssets || assets.length);
            this.isListLoading = false;
        },
        async fetchMaintenanceSchedule() {
            const endpoint = `/api/maintenance-schedules`;
            const response = await handleRequests(endpoint);

            const res = response.data.maintenanceSchedules;

            // const list = res.map((x) => ({
            //     name: x.maintenanceName,
            //     type: x.assetMaintenanceScheduleId
            // }));

            this.maintenanceScheduledList = res;
        },
        assignToList(assets, total) {
            this.assetList = assets;
            this.pagination.total = total;
        },
        getMaxPage(page) {
            this.maxPage = page;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            await this.fetchAssetListByType(currentPage, perPage);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
        },
        async handleFilterOrSearch(val) {
            if (!this.$route.query.currentPage) {
                this.$router.replace({ path: this.$route.path, query: {} });
            }

            this.filters = Object.assign(this.filters, val);
            await this.fetchAssetListByType(this.pagination.currentPage, this.pagination.perPage);
        },
        async handleUpdatedAsset({ id, data }) {
            await this.fetchAssetListByType(1, 50, true);
        },
        handleAssignMaintenanceSchedule({ currentEngineHoursValue, currentOdometerValue, assetId }) {
            const index = this.assetList.findIndex((asset) => asset.assetId === assetId);

            this.assetList[index].estimatedEngineHourValue = currentEngineHoursValue;
            this.assetList[index].estimatedOdometerValue = currentOdometerValue;
        },
        async handleDeleteAsset() {
            await this.fetchAssetListByType(1, 50, true);
        },
        showAssetDetails(item) {
            this.showSideBar = true;
            this.assetId = item.assetId;
            this.assetType = item.type;
        },
        toggleAssetDetailsWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown) 
                this.showSideBar = !this.showSideBar;
        }
    },
    computed: {
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        ...mapGetters({
            isLoading: 'isLoading',
            user: 'user/user'
        })
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-table-cell {
    height: 40px;
}
.body-list {
    margin-top: 150px;
    min-height: 100px;
}
.connected {
    color: green !important;
}
.disconnected {
    color: red !important;
}
.custom-paginated-table {
    a {
        color: rgba(0, 0, 0, 0.87);
    }
}

.md-card-actions.page-footer {
    padding-bottom: 0 !important;
}

// .agroup-name-max {
//     width: 190px;
// }
</style>
