<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
                <md-icon>shopping_basket</md-icon>
            </div>
        </md-card-header>
        <md-card-content>
            <p v-if="!orders || orders.length === 0" class="grayedout-text text-center">
                No orders found
            </p>
            <div v-else>
                <md-table class="context-menu-support custom-paginated-table">
                    <md-table-row>
                        <md-table-head>Order Number</md-table-head>
                        <md-table-head>Created Date</md-table-head>
                        <md-table-head col-span="1">Status</md-table-head>
                    </md-table-row>
                    <md-table-row v-for="order in orders" :key="order.orderId" @click.stop="openOrder(order.orderId)">
                        <md-table-cell>{{ order.orderNumber }}</md-table-cell>
                        <md-table-cell>{{ order.createdDate | dateFormat(DATE_TYPES.standardDate) }}</md-table-cell>
                        <md-table-cell col-span="1">
                            <div :class="`custom-badge order-status-${order.status.toLowerCase().replace(/\s+/g, '')}`">
                                {{ $t('inputs.orderStatuses.' + order.status) }}
                            </div>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
            </div>
        </md-card-content>
    </md-card>
</template>

<script>
import { GeneralMixin } from '@/mixins';

export default {
    name: 'OrderItems',
    mixins: [GeneralMixin],
    props: {
        orders: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        openOrder(orderId) {
            const routeData = this.$router.resolve({ name: 'Order Details', params: { orderId } });
            window.open(routeData.href, '_blank');
        }
    }
};
</script>
