<template>
    <div>
        <md-card v-if="!driveData || driveData.length == 0">
            <md-card-header class="md-card-header-icon md-card-header-blue">
                <div class="card-icon">
                    <md-icon>summarize</md-icon>
                </div>
            </md-card-header>

            <md-card-content>
                <div>
                    <h3>Ignition On/Off Report</h3>
                    <p>
                        No results to display.
                    </p>
                </div>
            </md-card-content>
        </md-card>
        <div v-else v-for="(dataList, i) in filteredData" :key="i">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <CardHeaderDropdown default-icon="summarize" :default-text="displayDate(i)" />
                </md-card-header>

                <md-card-content>
                    <div>
                        <div class="report-container">
                            <md-card-content>
                                <md-table :value="dataList" class="table-hover">
                                    <md-table-row
                                        slot="md-table-row"
                                        slot-scope="{ item, index }"
                                        @click="viewAddress(item.arrival)"
                                    >
                                        <md-table-cell md-label="ID">
                                            {{ index + 1 }}
                                        </md-table-cell>
                                        <md-table-cell md-label="Departure time" md-sort>
                                            {{
                                                displayDateTime(item.departure, item.utcOffset)
                                                    | timeFormat(DATE_TYPES.standardTime)
                                            }}
                                        </md-table-cell>
                                        <md-table-cell md-label="Departure location" md-sort>
                                            <a class="address" @click.stop="viewAddress(item.departure)">
                                                {{ displayAddressOrCoord(item.departure) }}
                                            </a>
                                        </md-table-cell>

                                        <md-table-cell md-label="Arrival time" md-sort>
                                            {{
                                                displayDateTime(item.arrival, item.utcOffset)
                                                    | timeFormat(DATE_TYPES.standardTime)
                                            }}
                                        </md-table-cell>
                                        <md-table-cell md-label="Arrival location" md-sort>
                                            <a class="address" @click.stop="viewAddress(item.arrival)">
                                                {{ displayAddressOrCoord(item.arrival) }}
                                            </a>
                                        </md-table-cell>

                                        <md-table-cell md-label="Distance covered" md-sort>
                                            {{ toDistanceStr(item.distanceCoveredMeters / 1000) }}
                                        </md-table-cell>

                                        <md-table-cell md-label="Travel time">
                                            {{ toTime(item.travelTimeMinutes) }}
                                        </md-table-cell>

                                        <md-table-cell md-label="Device IMEI">
                                            {{ item.deviceIMIE }}
                                        </md-table-cell>
                                    </md-table-row>
                                </md-table>
                                <div class="label-total">
                                    <span>Total Ignitions: {{ dataList.length }}</span>
                                </div>
                            </md-card-content>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>
<style lang="scss" scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

::v-deep .md-table-head {
    cursor: default;
}

.table-hover {
    cursor: pointer;
}

.label-total {
    float: right;
    font-size: 14px;
    font-weight: 500;

    span {
        display: block;
    }
}
</style>
<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins';
import { CardHeaderDropdown } from '@/components';
import { DATE_TYPES } from '@/utils/constants';
import { ViewAddressModal } from '..';

export default {
    name: 'IgnitionReportDetails',
    components: { CardHeaderDropdown },
    mixins: [GeneralMixin],
    props: {
        memberList: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        filteredData() {
            const filteredResult = Object.groupBy(this.memberList, ({ tripDate }) => tripDate);
            return filteredResult;
        },
        driveData() {
            return this.memberList;
        },
        user() {
            return this.$store.getters['user/user'];
        },
        speedUnits() {
            return this.user.speedUnits;
        }
    },
    methods: {
        displayDateTime(item, utcOffset) {
            return moment(item.time).utcOffset(utcOffset);
        },
        displayDate(str) {
            return moment(str, 'YYYYMMDD').format(DATE_TYPES.standardDate);
        },
        displayAddressOrCoord(item) {
            const houseAddr = [item.addressHouseNumber, item.addressRoad].filter((part) => part).join(' ');
            const addr = [houseAddr, item.addressSuburb, item.addressTown].filter((part) => part).join(', ');
            if (addr) 
                return addr;
            return `${item.latitude} - ${item.longitude}`;
        },
        viewAddress(item) {
            this.$modal.show(ViewAddressModal, { data: { location: item, address: this.displayAddressOrCoord(item) } });
        },
        toDistanceStr(dist) {
            if (this.speedUnits === 'kph') 
                return `${Math.round(dist * 10) / 10} km`;
            return `${Math.round(dist * 0.621371 * 10) / 10} mi`;
        },
        toTime(minutes) {
            const duration = moment.duration({ minutes: Math.ceil(minutes) });
            const formatted = moment.utc(duration.asMilliseconds()).format('HH [hr] mm [min]');

            return formatted;
        }
    }
};
</script>
<style lang="scss" scoped>
.md-card-header-blue ::v-deep .card-icon {
    color: #fff;
}
</style>
