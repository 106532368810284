<template>
    <div class="custom-toolbar state-filter">
        <div class="custom-toolbar-end">
            <md-field v-if="approvalStatusList.length > 0">
                <vue-select
                    :options="approvalStatusList"
                    label="name"
                    placeholder="Approval Status"
                    v-model="approvalStatusFilterValue"
                    :searchable="$root.isDesktop"
                ></vue-select>
            </md-field>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins';

export default {
    name: 'ProcessChargeApprovalStatusFilter',
    props: {
        approvalStatusList: {
            type: Array,
            default: () => ['Unapproved', 'Approved', 'All']
        },
        filterApprovalStatusFilter: {
            type: String,
            default: 'Unapproved'
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {
            approvalStatusFilterValue: 'Unapproved'
        };
    },
    mounted() {
        this.approvalStatusFilterValue = this.filterApprovalStatusFilter;
    },
    watch: {
        approvalStatusFilterValue(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.approvalStatusFilterValue = newValue;
                this.handleFilterValue();
            }
        },
        filterApprovalStatusFilter() {
            if (this.filterApprovalStatusFilter === 'null') {
                this.approvalStatusFilterValue = 'Unapproved';
            } else {
                this.approvalStatusFilterValue = this.filterApprovalStatusFilter;
            }
        }
    },
    methods: {
        handleFilterValue() {
            this.$emit('onApprovalStatusSelected', this.approvalStatusFilterValue);
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    }
};
</script>

<style lang="scss" scoped>
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}

::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}

::v-deep .vs__search {
    cursor: pointer;
}

.status-filter {
    width: 180px !important;
}

.state-filter {
    margin-right: 20px;
}
</style>
