<template>
    <div class="profile-container">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-60 md-xsmall-size-100 md-size-60">
                <router-link :to="{ name: 'Team Members' }" class="custom-a">
                    <i class="material-icons">keyboard_backspace</i>
                    Go back to Team Member Dashboard
                </router-link>
                <md-card class="md-card-profile logo-section">
                    <md-card-content class="custom-content-padding">
                        <div class="member-information field-spacing-container">
                            <form-wrapper :validator="$v.form" class="form-wrapper">
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <form-group name="company" label="Company Name">
                                                <md-input v-model="form.company" />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item">
                                            <form-group name="industry" label="Industry">
                                                <industry-drop-down
                                                    :industry="form.industry"
                                                    @selectedIndustry="getSelectedIndustry"
                                                />
                                            </form-group>
                                        </div>
                                    </div>
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <div class="location-container">
                                                <form-group name="businessLocation"
                                                            label="Business Location"
                                                            class="location-dropdown">
                                                    <md-select v-model="form.businessLocationId"
                                                               @md-selected="selectedWarehouseChanged">
                                                        <md-option v-for="item in warehouses"
                                                                   :key="item.warehouseId"
                                                                   :value="item.warehouseId">
                                                            {{ item.name }}
                                                        </md-option>
                                                    </md-select>
                                                </form-group>
                                                <div class="add-location-button">
                                                    <md-button title="Add new warehouse as business location"
                                                               class="md-primary md-just-icon md-round pull-right"
                                                               @click="addWarehouseClicked">
                                                        <md-icon>add</md-icon>
                                                    </md-button>
                                                </div>
                                            </div>

                                            <div class="detail-group business-location-details">
                                                <span class="business-address">{{ selectedWarehouse ? selectedWarehouse.address : '' }}</span>
                                                <span class="business-email">{{ selectedWarehouse ? selectedWarehouse.email : '' }}</span>
                                                <span class="business-phone">{{ selectedWarehouse ? selectedWarehouse.phone : '' }}</span>
                                            </div>
                                        </div>
                                        <div class="md-layout-item">
                                            <form-group name="websiteUrl" label="Website Url">
                                                <md-input v-model="form.websiteUrl" />
                                            </form-group>
                                        </div>
                                    </div>
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <div class="text-align-left">
                                                Do you want to always track your team members, or only while they have
                                                active stops / tracking links?
                                                <span class="default-tracking-info">
                                                    <md-icon>info</md-icon>
                                                    <md-tooltip md-direction="top">
                                                        Changes will only apply to newly invited users. Existing members
                                                        won't be affected.
                                                    </md-tooltip>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="md-layout-item">
                                            <form-group
                                                name="defaultTrackingBehaviour"
                                                label="Location Tracking Behaviour"
                                            >
                                                <gps-collection-dropdown
                                                    :selected="form.defaultTrackingBehaviour"
                                                    @selectedOption="getSelectedTrackingBehaviour"
                                                />
                                            </form-group>
                                        </div>
                                    </div>
                                    <div class="md-layout">
                                        <div class="md-layout-item md-size-50">
                                            <form-group
                                                name="etaTimeWindowWidthInMinutes"
                                                label="ETA Time Window Width In Minutes"
                                            >
                                                <md-input
                                                    v-model.number="form.etaTimeWindowWidthInMinutes"
                                                    type="number"
                                                />
                                            </form-group>
                                        </div>
                                    </div>
                                </div>
                            </form-wrapper>

                            <div>
                                <label class="gray-text">Upload your logo here</label>
                                <div
                                    class="upload-container"
                                    @mouseover="showUploadButton = true"
                                    @mouseleave="showUploadButton = false"
                                >
                                    <img
                                        v-if="!logoPreview"
                                        class="profile-image"
                                        :src="logoPreview !== null ? logoPreview : '/img/logos/logo2-placeholder.jpg'"
                                        @error="$_setDefaultBrokenImage"
                                        alt
                                    />
                                    <img
                                        v-if="logoPreview"
                                        class="profile-image"
                                        :src="logoPreview"
                                        @error="$_setDefaultBrokenImage"
                                        alt
                                    />
                                    <div v-if="showUploadButton">
                                        <input ref="logoInput" hidden type="file" @change="onLogoSelected" />
                                        <div>
                                            <button class="custom-btn" @click="$refs.logoInput.click()">
                                                Update Photo
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-actions">
                            <md-button v-if="!hasTeam" class="md-primary dialog-button" @click="validateForm">
                                Create Team
                            </md-button>
                            <md-button v-if="hasTeam" class="md-primary dialog-button" @click="validateForm">
                                Update Details
                            </md-button>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout-item md-medium-size-40 md-xsmall-size-100 md-size-40 md-xsmall-hide md-small-hide">
                <div class="phone-logo">
                    <img src="/img/phone-logo.png" />
                    <img class="arrow-to-phone" src="/img/arrow-to-phone.png" alt="" />
                    <div class="phone-logo-replacer" :class="logoPreview ? '' : 'transparent-img'">
                        <img :src="logoPreview" @error="$_setDefaultBrokenImage" alt />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { personnelDefaults, vehicleDefaults } from '@/utils/customAssetFieldDefaults';
/* eslint-disable no-unused-vars */
import { BlockBlobClient } from '@azure/storage-blob';
// import { ContainerClient, newPipeline, AnonymousCredential } from '@azure/storage-blob';
import { mapGetters, mapActions } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, minLength, maxLength, integer, minValue, maxValue } from 'vuelidate/lib/validators';
import { IndustryDropDown, GpsCollectionDropdown } from '@/components';
import { handleRequests, imageCacheBuster, showErrorMessage } from '@/helpers';
import { WAREHOUSE_DETAILS_DEFAULTS } from '@/utils/defaults';
import CreateWarehouseModal from '@/pages/Warehouses/Modals/CreateWarehouseModal';

const signalR = require('@aspnet/signalr');

export default {
    name: 'TeamProfile',
    mixins: [GeneralMixin],
    components: { IndustryDropDown, GpsCollectionDropdown },
    data() {
        return {
            form: {
                company: null,
                industry: null,
                websiteUrl: null,
                defaultTrackingBehaviour: null,
                etaTimeWindowWidthInMinutes: 60,
                businessLocationId: null
            },
            selectedWarehouse: null,
            logoPreview: null,
            selectedLogo: null,
            industryList: [
                'Appliance Repair',
                'Building',
                'Carpet Cleaning',
                'Cleaning',
                'Data & Security',
                'Delivery',
                'Electrical',
                'Flooring',
                'Handyman',
                'HVAC',
                'IT Services',
                'Painting',
                'Plumbing & Gas',
                'Pool & Spa Services',
                'Pressure Washing',
                'Repairs & Maintenance',
                'Snow Removal',
                'Transportation',
                'Tree Care',
                'Utilities',
                'Other'
            ],
            showUploadButton: false
        };
    },
    validations: {
        form: {
            company: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            industry: {
                required
            },
            etaTimeWindowWidthInMinutes: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(1440) // 24 hours
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasTeam: 'user/hasTeam',
            warehouses: 'team/warehouses'
        })
    },
    async mounted() {
        this.$_handleLoaderState(true);
        if (this.hasTeam) {
            const api = '/api/teams';
            const { data } = await handleRequests(api);
            const response = data.data;
            this.form.company = response.company;
            this.form.industry = response.industry;
            this.form.websiteUrl = response.websiteUrl;
            this.form.businessLocationId = response.businessLocationId;
            this.form.defaultTrackingBehaviour = response.defaultTrackingBehaviour || 'Default';
            this.form.etaTimeWindowWidthInMinutes = response.etaTimeWindowWidthInMinutes || 60;

            this.logoPreview = imageCacheBuster(response.logoUrl);
        } else {
            this.form.company = this.user.company;
            this.form.industry = this.user.industry;
            this.form.websiteUrl = this.user.websiteUrl;
            this.form.defaultTrackingBehaviour = this.user.gpsDataCollectionRule || 'Default';
            this.logoPreview = this.user.logoUrl;
        }

        const userCrendentials = await window.auth.getUser();
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl('/ImageHub', {
                accessTokenFactory: () => {
                    return userCrendentials.access_token;
                }
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();
        this.connection.start();
        this.connection.on('Updated', async (isLogo, filename) => {
            const api = '/api/teams';
            const { data } = await handleRequests(api);
            const response = data.data;
            this.logoPreview = imageCacheBuster(response.logoUrl);

            this.$notify({
                message: `Your team logo has been processed and is available now to view.`,
                type: 'success'
            });
        });

        this.$_handleLoaderState(false);
    },
    methods: {
        ...mapActions('team', ['FETCH_WAREHOUSES']),
        validateForm() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                if (this.hasTeam) {
                    this.updateTeamDetails();
                } else {
                    this.createTeam();
                }
            }
        },
        async createAssetCustomFieldDefaults() {
            const payload = {
                method: 'post',
                data: [...personnelDefaults, ...vehicleDefaults]
            };
            const api = `/api/teams/custom-fields/assets`;
            await handleRequests(api, payload);
        },
        async createTeam() {
            this.$_handleLoaderState(true, 'PROCESSING...');
            const payload = {
                method: 'post',
                data: this.form
            };

            try {
                const api = '/api/teams/';
                await handleRequests(api, payload);

                this.$notify({
                    message: 'Team successfully created',
                    type: 'success'
                });

                await this.$store.dispatch('user/FETCH_USER');
                await this.$store.dispatch('generalSetting/FETCH_GENERAL_SETTINGS');

                // create default custom fields
                await this.createAssetCustomFieldDefaults();

                this.$_handleLoaderState(false);
                this.$router.push({ path: '/team/team-members' });
            } catch (error) {
                const message = 'Error in creating a team';
                showErrorMessage(this, message, error);
                this.$_handleLoaderState(false);
            }
        },
        async updateTeamDetails() {
            this.$_handleLoaderState(true, 'UPDATING...');

            try {
                const api = '/api/teams';
                const payload = {
                    method: 'put',
                    data: this.form
                };

                await handleRequests(api, payload);
                this.$notify({
                    message: 'Team successfully updated.',
                    type: 'success'
                });
                this.$notify({
                    message: `Newly invited members will now have ${
                        this.form.defaultTrackingBehaviour
                    } as the location tracking behaviour.`,
                    duration: 5000,
                    type: 'success'
                });
                this.$_handleLoaderState(false);
            } catch (error) {
                const message = 'Error in updating team profile';
                showErrorMessage(this, message, error);
                this.$_handleLoaderState(false);
            }
        },
        onLogoSelected(event) {
            const [file] = event.target.files;
            this.selectedLogo = file;
            this.logoPreview = URL.createObjectURL(this.selectedLogo);
            this.onUploadImage();
        },
        async onUploadImage() {
            this.$_handleLoaderState(true, 'UPLOADING...');
            const file = this.selectedLogo;

            const fileType = file.type.split('/');

            let ext = fileType[1];

            if (ext === 'jpeg') {
                ext = 'jpg';
            }

            const api = `/api/teams/me/logo/?fileType=${ext}&company=${this.form.company}`;
            const response = await handleRequests(api, { method: 'post' });

            const blockBlobClient = new BlockBlobClient(response.data.uri);

            try {
                await blockBlobClient.upload(file, file.size);
                this.$_handleLoaderState(false);

                this.$notify({
                    message: `Your team logo has been successfully uploaded. Please wait a moment for it to be processed - it will reflect on your profile shortly.`,
                    type: 'success'
                });
            } catch (e) {
                const message = 'Error in uploading logo';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }

            // handleRequests(api, { method: 'post' }).then((response) => {
            //     const containerUrl = new ContainerClient(response.data.uri, newPipeline(new AnonymousCredential()));

            //     const payload = {
            //         method: 'put',
            //         headers: {
            //             'Content-type': file.type,
            //             'x-ms-blob-type': 'BlockBlob'
            //         },
            //         data: file
            //     };

            //     handleRequests(containerUrl.url, payload).then(
            //         (response) => {
            //             this.$notify({
            //                 message: 'Your logo has been successfully uploaded',
            //                 type: 'success'
            //             });
            //         },
            //         (error) => {
            //             this.$notify({
            //                 message: error.data[0].message,
            //                 type: 'danger'
            //             });
            //         }
            //     );
            // });

            // this.$_handleLoaderState(false);
        },
        getSelectedIndustry(industry) {
            this.form.industry = industry;
        },
        getSelectedTrackingBehaviour(value) {
            this.form.defaultTrackingBehaviour = value;
        },
        selectedWarehouseChanged(warehouseId) {
            let warehouse = null;
            if (warehouseId && this.warehouses) {
                warehouse = this.warehouses.find((x) => x.warehouseId === warehouseId);
            }
            this.selectedWarehouse = warehouse;
        },
        addWarehouseClicked() {
            this.$modal
                .show(CreateWarehouseModal, {
                    warehouseDetails: Object.assign({}, WAREHOUSE_DETAILS_DEFAULTS())
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.FETCH_WAREHOUSES();
                        this.$modal.hide();
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.text-align-left {
    text-align: left;
    display: block;
    margin-bottom: 20px;
}
.md-error {
    bottom: -32px !important;
}
.md-field {
    margin-bottom: 30px !important;
}
.md-field:nth-last-child(2) {
    margin-bottom: 10px !important;
}
.display-image {
    span {
        display: block;
    }
}
.default-tracking-info {
    i {
        font-size: 17px !important;
        margin-top: -4px;
        color: #2b93ff !important;
    }
}

.form-actions {
    margin-top: 30px;
    .dialog-button {
        background-color: #2b93ff !important;
        color: #fff !important;
        min-width: 250px;
        border-radius: 6px;
        height: 50px;
        text-transform: initial;
        font-size: 1rem;
    }
    .dialog-button:hover,
    .dialog-button:focus,
    .dialog-button:active {
        background-color: #2b93ff !important;
        color: #fff !important;
    }
}

.custom-content-padding {
    padding: 30px;
}

.gray-text {
    color: #aaaaaa !important;
}

.logo-section {
    margin-top: 40px !important;
}

.upload-container {
    background-color: #fbfbfb;
    height: 200px;
    width: 100%;
    position: relative;
    img {
        width: initial;
        max-height: 100%;
        max-width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    border: 1px solid #d8d8d8;
}

.phone-logo {
    position: relative;
    margin-top: 100px;
    margin-left: 20px;

    .arrow-to-phone {
        position: absolute;
        left: -77px;
        z-index: 1;
        bottom: 127px;
        max-width: 170px;
    }

    .phone-logo-replacer {
        position: relative;
        max-width: 150px;
        display: block;
        background-color: #fff;
        left: 69px;
        bottom: 75px;
        height: 50px;
        img {
            width: initial;
            max-height: 100%;
            max-width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .transparent-img {
        background-color: initial;
    }
}

.detail-group {
    margin-bottom: 20px;
    .label {
        display: inline-block;
        width: 95px;
        margin-right: 4px;
        vertical-align: top;
        font-weight: 400;
        text-align: left;
    }

    .value {
        display: inline-block;
        width: calc(100% - 105px);
        vertical-align: top;
        a {
            color: #2b93ff;
        }
        text-align: left;
    }
}

.location-container {
    .location-dropdown {
        display: inline-block;
        width: calc(100% - 35px);
        margin-right: 5px;
    }
    .add-location-button {
        display: inline-block;
        width: 30px;
        vertical-align: middle;
    }

    .md-button.md-fab,
    .md-button.md-just-icon {
        height: 25px;
        line-height: 25px;
        width: 25px;
        min-width: 25px;
    }
}

.business-location-details {
    text-align: left;

    span {
        display: block;
        line-height: 1.4em;
    }

    .business-address {
        font-weight: bold;
    }

    .business-phone, .business-email {
        color: #AAAAAA;
    }
}
</style>
