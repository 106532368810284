<template>
    <div class="register-custom-layout md-layout md-alignment-center-center">
        <div class="md-layout-item">
            <div>
                <img src="/img/icons/logo.png" alt="Alternate Text" />
                <h1 slot="title">
                    Connecting you with your customers.
                </h1>
                <md-button
                    href="/login"
                    class="btn-blue md-simple md-success md-lg"
                >
                    Sign in
                </md-button>
            </div>
        </div>
        <div class="md-layout-item">
            <login-card>
                <h4 slot="title" class="title">
                    Create
                    <br />
                    Your account
                </h4>
                <div
                    slot="social-logins"
                    class="social-logins md-layout md-alignment-center-center"
                >
                    <div class="md-layout-item">
                        <p class="description">
                            Sign in using
                        </p>
                    </div>
                    <div class="md-layout-item">
                        <md-button
                            href="#facebook"
                            class="md-just-icon md-simple md-white"
                        >
                            <img
                                :src="facebook"
                                alt="facebook"
                                height="40"
                                width="40"
                            />
                        </md-button>
                        <md-button
                            href="#google"
                            class="md-just-icon md-simple md-white"
                        >
                            <img
                                :src="google"
                                alt="google"
                                height="40"
                                width="40"
                            />
                        </md-button>
                    </div>
                </div>

                <p slot="description" class="description">
                    Or sign in using your account
                </p>
                <md-field slot="inputs" class="md-form-group">
                    <md-input v-model="name" type="text" placeholder="Name" />
                </md-field>
                <md-field slot="inputs" class="md-form-group">
                    <md-input
                        v-model="email"
                        type="email"
                        placeholder="Email"
                    />
                </md-field>
                <md-field slot="inputs" class="md-form-group">
                    <md-input v-model="password" placeholder="Password" />
                </md-field>
                <md-field slot="inputs" class="md-form-group">
                    <md-input
                        v-model="confirmPassword"
                        placeholder="Confirm Password"
                    />
                </md-field>

                <md-button slot="footer" class="md-simple md-success md-lg">
                    Sign up
                </md-button>
            </login-card>
        </div>
    </div>
</template>
<script>
import { LoginCard } from '@/components';

export default {
    components: {
        LoginCard
    },
    props: {
        facebook: {
            type: String,
            default: '/img/icons/fb_icon@2x.png'
        },
        google: {
            type: String,
            default: '/img/icons/google_icon@2x.png'
        }
    },
    data() {
        return {
            firstname: null,
            email: null,
            password: null
        };
    }
};
</script>

<style scoped>
.md-card .social-logins .description {
    text-align: left;
    margin-bottom: 0;
}

.md-card .social-logins {
    margin-bottom: 30px;
}

.md-card.md-theme-default {
    background-color: transparent;
}

.social-logins .md-layout-item {
    padding: 0 !important;
}

.social-logins .md-layout-item:last-child {
    text-align: right;
}

.md-field .md-input {
    border: none !important;
    border-bottom: 1px solid #0cdb8f !important;
    border-radius: 0px !important;
    padding: 0 !important;
}

.md-field .md-input:focus {
    background-color: transparent !important;
    color: #fff !important;
    -webkit-text-fill-color: #fff !important;
}

.md-field label {
    left: 10px;
}

.md-field.md-focused label {
    top: -1rem;
}

.md-field.md-field.md-has-value label {
    top: -1rem;
}

.md-field:before {
    height: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    transition: none;
    will-change: border, opacity, transform;
    content: ' ';
}

.md-field:after {
    height: 0;
}

.md-button.form-link {
    color: #fff !important;
}

.md-button.form-link .md-ripple {
    justify-content: flex-start;
    align-items: flex-start;
}

.md-button.form-link:not(.md-just-icon):not(.md-btn-fab):not(.md-icon-button):not(.md-toolbar-toggle)
    .md-ripple {
    padding: 0;
    padding-left: 10px;
}

.md-button.form-link .md-button-content {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.md-card-content {
    text-align: left;
}

.md-button.md-just-icon {
    width: 55px;
}

.md-card .md-card-header .title {
    font-size: 40px;
    font-weight: 900;
    line-height: 45px;
    margin-bottom: 30px;
}

.md-card
    [class*='md-card-header-']:not(.md-card-header-icon):not(.md-card-header-text):not(.md-card-header-image) {
    padding: 0;
}

.md-button.md-success.md-simple {
    background-color: #0cdb8f !important;
    color: #fff !important;
    width: 200px;
    border-radius: 35px;
    height: 45px;
    display: block;
    margin: 10px auto;
}

.md-card-actions {
    display: block;
    text-align: center;
}

.md-button.md-success.md-simple.btn-blue {
    color: #fff !important;
    background-color: #2b93ff !important;
    margin-left: 0;
}

.md-card {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.md-card
    [class*='md-card-header-']:not(.md-card-header-icon):not(.md-card-header-text):not(.md-card-header-image) {
    margin-top: 0;
}

.md-card-header,
.description {
    text-align: left;
}
</style>
