var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tripDetails != null
    ? _c("div", { staticClass: "details-page" }, [
        _c("div", { staticClass: "md-layout" }, [
          _c("div", { staticClass: "md-layout-item" }, [
            _c("div", { staticClass: "custom-toolbar" }, [
              !_vm.$root.isMobileOnly && !_vm.isReadOnlyUser
                ? _c("div", { staticClass: "custom-toolbar-end" }, [
                    _c(
                      "div",
                      { staticClass: "header-button-container" },
                      [
                        _c("trip-status-button", {
                          staticClass: "status-btn",
                          attrs: {
                            trip: _vm.tripDetails,
                            "is-modal": false,
                            "class-name": "md-sm",
                          },
                          on: {
                            statusUpdated: _vm.handleChangedTripStatus,
                            statusHistoryUpdated:
                              _vm.handleTripStatusHistoryUpdated,
                          },
                        }),
                        _c(
                          "md-button",
                          {
                            attrs: { title: "Reassign Trip" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.reassignTrip($event)
                              },
                            },
                          },
                          [_c("span", [_vm._v("Reassign Trip")])]
                        ),
                        _c(
                          "md-button",
                          {
                            attrs: { title: "Edit trip details" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleTripUpdate($event)
                              },
                            },
                          },
                          [_c("span", [_vm._v("Edit")])]
                        ),
                        _vm.tripDetails.stopList.length > 0
                          ? _c("generate-label-button", {
                              attrs: {
                                id: _vm.tripDetails.tripId,
                                "use-icon": false,
                                type: "Trip",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "md-layout md-alignment-top-left content-layout-panel",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-66 md-xlarge-size-50",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-blue",
                          },
                          [
                            _vm.tripDetails.assignedTo.photoUrl === null
                              ? _c(
                                  "div",
                                  { staticClass: "card-icon" },
                                  [_c("md-icon", [_vm._v("description")])],
                                  1
                                )
                              : _vm._e(),
                            _vm.tripDetails.assignedTo.photoUrl !== null
                              ? _c(
                                  "div",
                                  { staticClass: "card-icon profile-image" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.tripDetails.assignedTo
                                          .photoUrl,
                                        alt: "avatar",
                                      },
                                      on: {
                                        error: _vm.$_setDefaultBrokenImage,
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c("md-card-content", [
                          _c(
                            "div",
                            { staticClass: "single-row three-column-box" },
                            [
                              _c(
                                "div",
                                { staticClass: "custom-md-field details-box" },
                                [
                                  _c("div", { staticClass: "custom-label" }, [
                                    _vm._v("Driver"),
                                  ]),
                                  _vm.tripDetails.assignedTo.publicUserId
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "Team Member Profile",
                                              params: {
                                                publicUserId:
                                                  _vm.tripDetails.assignedTo
                                                    .publicUserId,
                                              },
                                            },
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.tripDetails.assignedTo
                                                  .fullName ||
                                                  _vm.tripDetails.assignedTo
                                                    .publicUserId
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "a",
                                        {
                                          attrs: { title: "Reassign Trip" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.reassignTrip($event)
                                            },
                                          },
                                        },
                                        [_vm._v("Unassigned")]
                                      ),
                                ],
                                1
                              ),
                              !_vm.tripDetails.rateGroupId &&
                              _vm.tripDetails.assignedTo.rateGroupId &&
                              _vm.showServicePackage()
                                ? _c("div", { staticClass: "details-box" }, [
                                    _c("label", [
                                      _vm._v("Driver Service Package"),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.tripDetails.assignedTo
                                              .rateGroupName
                                          ) +
                                          "\n                                "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "details-box" }, [
                                _c("label", [_vm._v("Trip Date")]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          _vm.tripDetails.tripDate,
                                          _vm.DATE_TYPES.standardDate
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                ]),
                              ]),
                              _vm.tripDetails.completedDate
                                ? _c("div", { staticClass: "details-box" }, [
                                    _c("label", [_vm._v("Completed Date")]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm._f("dateTimeFormat")(
                                              _vm.tripDetails.completedDate,
                                              _vm.DATE_TYPES.standardDate
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "single-row three-column-box" },
                            [
                              _vm.tripDetails.startTime
                                ? _c("div", { staticClass: "details-box" }, [
                                    _c("label", [_vm._v("Start Time")]),
                                    _c("span", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.convertTimeFormat(
                                              _vm.tripDetails.startTime
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.tripDetails.actualStartTime
                                ? _c("div", { staticClass: "details-box" }, [
                                    _c("label", [_vm._v("Actual Start Time")]),
                                    _c("span", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm._f("dateTimeFormat")(
                                              _vm.tripDetails.actualStartTime,
                                              _vm.DATE_TYPES.standardDate
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "details-box" }, [
                                _c("label", [_vm._v("Start Location")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.tripDetails.locationDetails
                                        .startAddress
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "single-row three-column-box" },
                            [
                              _vm.tripDetails.endTime
                                ? _c("div", { staticClass: "details-box" }, [
                                    _vm.tripDetails.endTime != null
                                      ? _c("label", [_vm._v("End Time")])
                                      : _vm._e(),
                                    _vm.tripDetails.endTime != null
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.convertTimeFormat(
                                                  _vm.tripDetails.endTime
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm.tripDetails.actualEndTime &&
                              _vm.tripDetails.status === "Complete"
                                ? _c("div", { staticClass: "details-box" }, [
                                    _c("label", [_vm._v("Actual End Time")]),
                                    _c("span", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm._f("dateTimeFormat")(
                                              _vm.tripDetails.actualEndTime,
                                              _vm.DATE_TYPES.standardDate
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "details-box" }, [
                                _vm.tripDetails.locationDetails.endAddress !=
                                null
                                  ? _c("label", [_vm._v("End Location")])
                                  : _vm._e(),
                                _vm.tripDetails.locationDetails.endAddress !=
                                null
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.tripDetails.locationDetails
                                              .endAddress
                                          ) +
                                          "\n                                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _vm.teamRegionName
                            ? _c("div", { staticClass: "single-row" }, [
                                _c("div", { staticClass: "details-box" }, [
                                  _c("label", [_vm._v("Team Region")]),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.teamRegionName)),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.tripDetails.rateGroupName
                            ? _c("div", { staticClass: "single-row" }, [
                                _c("div", { staticClass: "details-box" }, [
                                  _c("label", [_vm._v("Service Package")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.tripDetails.rateGroupName)
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.tripDetails.customerInvoiceId
                            ? _c("div", { staticClass: "details-box" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Customer Invoice:"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "value" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          "custom-badge payment-status-" +
                                          _vm.tripDetails.customerInvoiceStatus
                                            .toLowerCase()
                                            .replace(/\s+/g, ""),
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "inputs.paymentStatuses." +
                                                  _vm.tripDetails
                                                    .customerInvoiceStatus
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Customer Invoice Details",
                                            params: {
                                              invoiceId:
                                                _vm.tripDetails
                                                  .customerInvoiceId,
                                            },
                                          },
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.tripDetails
                                                .customerInvoiceNumber
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.tripDetails.teamMemberInvoiceId
                            ? _c("div", { staticClass: "details-box" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Team Member Invoice:"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "value" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          "custom-badge payment-status-" +
                                          _vm.tripDetails.teamMemberInvoiceStatus
                                            .toLowerCase()
                                            .replace(/\s+/g, ""),
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "inputs.paymentStatuses." +
                                                  _vm.tripDetails
                                                    .teamMemberInvoiceStatus
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Team Member Invoice Details",
                                            params: {
                                              invoiceId:
                                                _vm.tripDetails
                                                  .teamMemberInvoiceId,
                                            },
                                          },
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.tripDetails
                                                .teamMemberInvoiceNumber
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100 map-box",
                  },
                  [
                    _c("map-overlay", {
                      attrs: { "trip-details": _vm.tripDetails },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "\n                    md-layout-item\n                    md-small-size-100\n                    md-large-size-100\n                    md-xlarge-size-100\n                    md-xsmall-hide\n                    md-small-hide-\n                    md-medium-hide\n                    md-large-hide\n                ",
                  },
                  [
                    _c("notes", {
                      attrs: {
                        list: _vm.tripDetails.notes,
                        "trip-id": _vm.tripDetails.tripId,
                      },
                      on: { notesAdded: _vm.handleAddedTripNote },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100 md-xlarge-hide",
                  },
                  [
                    _c("stop-list-table", {
                      attrs: { "trip-details": _vm.stopListData },
                    }),
                  ],
                  1
                ),
                _vm.tripDetails.driverBreakList &&
                _vm.tripDetails.driverBreakList.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100 md-xlarge-hide",
                      },
                      [
                        _c("driver-break-list-table", {
                          attrs: { "trip-details": _vm.stopListData },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-layout md-medium-size-100 md-large-size-33 md-xlarge-size-50",
              },
              [
                _c(
                  "div",
                  [
                    _c("trip-contact-details", {
                      staticClass:
                        "\n                        md-layout-item\n                        md-small-size-100\n                        md-large-size-100\n                        md-xlarge-size-100\n                        md-xsmall-hide\n                        md-medium-hide\n                        md-large-hide",
                      attrs: { "trip-details": _vm.tripDetails },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "\n                    md-layout-item\n                    md-small-size-100\n                    md-medium-size-50\n                    md-large-size-100\n                    md-xlarge-size-50\n                    md-xlarge-hide\n                ",
                  },
                  [
                    _c("assignment-history-timeline", {
                      attrs: { list: _vm.tripDetails.tripUserHistory },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "\n                    md-layout-item\n                    md-small-size-100\n                    md-medium-size-50\n                    md-large-size-100\n                    md-xlarge-size-50\n                    md-xlarge-hide\n                ",
                  },
                  [
                    _c("status-history-timeline", {
                      attrs: { list: _vm.tripDetails.statusHistory },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50 md-xlarge-hide",
                  },
                  [
                    _c("notes", {
                      staticClass: "custom-note-card-large",
                      attrs: {
                        list: _vm.tripDetails.notes,
                        "trip-id": _vm.tripDetails.tripId,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "\n                    md-layout-item\n                    md-small-size-100\n                    md-large-size-100\n                    md-xlarge-size-100\n                    md-xlarge-size-50\n                    md-xsmall-hide\n                    md-small-hide-\n                    md-medium-hide\n                    md-large-hide\n                ",
                  },
                  [
                    _c("stop-list-table", {
                      attrs: { "trip-details": _vm.stopListData },
                    }),
                  ],
                  1
                ),
                _vm.tripDetails.driverBreakList &&
                _vm.tripDetails.driverBreakList.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "\n                    md-layout-item\n                    md-small-size-100\n                    md-large-size-100\n                    md-xlarge-size-100\n                    md-xlarge-size-50\n                    md-xsmall-hide\n                    md-small-hide-\n                    md-medium-hide\n                    md-large-hide\n                ",
                      },
                      [
                        _c("driver-break-list-table", {
                          attrs: { "trip-details": _vm.tripDetails },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "\n                    md-layout-item\n                    md-small-size-100\n                    md-medium-size-50\n                    md-large-size-100\n                    md-xlarge-size-50\n                    md-xsmall-hide\n                    md-small-hide-\n                    md-medium-hide\n                    md-large-hide\n                ",
                  },
                  [
                    _c("assignment-history-timeline", {
                      attrs: { list: _vm.tripDetails.tripUserHistory },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "\n                    md-layout-item\n                    md-small-size-100\n                    md-medium-size-50\n                    md-large-size-100\n                    md-xlarge-size-50\n                    md-xsmall-hide\n                    md-small-hide-\n                    md-medium-hide\n                    md-large-hide\n                ",
                  },
                  [
                    _c("status-history-timeline", {
                      attrs: { list: _vm.tripDetails.statusHistory },
                    }),
                  ],
                  1
                ),
                !_vm.isIndividualUser
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100",
                      },
                      [
                        (_vm.tripDetails.chargeLines.length > 0 ||
                          _vm.tripDetails.customerId) &&
                        (_vm.isTeamOwner || _vm.isAdmin || _vm.isReadOnlyUser)
                          ? _c("trip-charge-rates-list", {
                              attrs: {
                                "charge-lines": _vm.tripDetails.chargeLines,
                                "trip-details": _vm.tripDetails,
                                title: "Trip Charges",
                                "has-invoice":
                                  _vm.tripDetails.customerInvoiceId !== null,
                                "rates-have-changed": false,
                              },
                              on: { updateTripDetails: _vm.getTripDetails },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isIndividualUser
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100",
                      },
                      [
                        (_vm.tripDetails.costLines.length > 0 ||
                          _vm.tripDetails.publicUserId) &&
                        (_vm.isTeamOwner || _vm.isAdmin)
                          ? _c("trip-cost-rates-list", {
                              attrs: {
                                "cost-lines": _vm.tripDetails.costLines,
                                "trip-details": _vm.tripDetails,
                                title: "Trip Costs",
                                "has-invoice":
                                  _vm.tripDetails.teamMemberInvoiceId !== null,
                                "rates-have-changed": false,
                              },
                              on: { updateTripDetails: _vm.getTripDetails },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }