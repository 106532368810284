var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-select", {
        staticClass: "column-mappings",
        class: { "is-not-mapped": _vm.isNotMapped },
        attrs: {
          placeholder: "Not Mapped",
          reduce: function (x) {
            return x.name
          },
          label: "value",
          options: _vm.fieldDefinition.worksheetFields,
          clearable: false,
        },
        model: {
          value: _vm.displayValue,
          callback: function ($$v) {
            _vm.displayValue = $$v
          },
          expression: "displayValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }