<template>
    <div class="edit-item">
        <md-button
            v-if="!isReadOnlyUser"
            title="Edit item details"
            :class="useIcon ? 'md-warning md-just-icon md-round' : `${className}`"
            @click.stop="handleUpdateItem"
            :disabled="isDisabled"
        >
            <md-icon v-if="useIcon">edit</md-icon>
            <span v-else>Edit</span>
        </md-button>
        <md-tooltip v-if="isInvoiced" class="line-tooltip" md-direction="left">
            You cannot edit an item when the stop is invoiced.
        </md-tooltip>
        <md-tooltip v-else-if="isDelivered" class="line-tooltip" md-direction="top">
            You cannot edit a delivered item.
        </md-tooltip>
        <md-tooltip
            v-else-if="!shipmentStatus && stopStatus !== null && stopStatus.toLowerCase() === 'failed'"
            class="line-tooltip"
            md-direction="top"
        >
            You cannot edit an item when the stop is marked as Failed
        </md-tooltip>
        <md-tooltip
            v-else-if="!shipmentStatus && stopStatus !== null && stopStatus.toLowerCase() === 'complete'"
            class="line-tooltip"
            md-direction="top"
        >
            You cannot edit an item when the stop is marked as Complete
        </md-tooltip>
        <md-tooltip
            v-else-if="shipmentStatus && shipmentStatus.toLowerCase() === 'complete'"
            class="line-tooltip"
            md-direction="top"
        >
            You cannot edit an item when the shipment is marked as Complete
        </md-tooltip>
        <md-tooltip
            v-else-if="shipmentStatus && shipmentStatus.toLowerCase() === 'cancelled'"
            class="line-tooltip"
            md-direction="top"
        >
            You cannot edit an item when the shipment is marked as Cancelled
        </md-tooltip>
    </div>
</template>

<script>
import { UpdateItemModal } from '@/pages/Inventory/components';
import { mapGetters } from 'vuex';

export default {
    name: 'UpdateItemButton',
    props: {
        item: {
            type: Object,
            default: () => null
        },
        // teamMembers: {
        //    type: Array,
        //    default: () => []
        // },
        className: {
            type: String,
            default: () => ''
        },
        useIcon: {
            type: Boolean,
            default: () => true
        },
        isDelivered: {
            type: Boolean,
            default: () => false
        },
        stopStatus: {
            type: String,
            default: () => null
        },
        shipmentStatus: {
            type: String,
            default: () => null
        },
        isInvoiced: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        }),
        isDisabled() {
            if (this.isInvoiced) 
                return true;
            if (
                this.shipmentStatus === null &&
                this.stopStatus !== null &&
                (this.stopStatus.toLowerCase() === 'failed' || this.stopStatus.toLowerCase() === 'complete')
            )
                return true;
            if (
                this.shipmentStatus !== null &&
                (this.shipmentStatus.toLowerCase() === 'complete' || this.shipmentStatus.toLowerCase() === 'cancelled')
            )
                return true;
            return this.isDelivered;
        }
    },
    methods: {
        handleUpdateItem() {
            this.$modal
                .show(UpdateItemModal, {
                    item: this.item
                })
                .then((response) => {
                    if (response) {
                        Object.assign(response, { itemId: this.item.itemId });

                        this.$emit('itemUpdated', response);
                        this.$modal.hide();
                    }
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.edit-item {
    display: inline;
}
</style>
