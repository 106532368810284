var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "load-vals" },
    [
      _c(
        "form-group",
        {
          attrs: {
            name: _vm.loadDefinition.type,
            label: _vm.loadDefinition.label,
          },
        },
        [
          _c("md-input", {
            model: {
              value: _vm.loadValue,
              callback: function ($$v) {
                _vm.loadValue = $$v
              },
              expression: "loadValue",
            },
          }),
          _c("span", [
            _vm._v(
              "\n            " +
                _vm._s(_vm.loadDefinition.symbol) +
                "\n        "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }