var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-rose" },
        [
          _c(
            "div",
            { staticClass: "card-icon" },
            [_c("md-icon", [_vm._v("coffee")])],
            1
          ),
        ]
      ),
      _c(
        "md-card-content",
        [
          _c(
            "md-table",
            { staticClass: "custom-paginated-table" },
            [
              _c(
                "md-table-row",
                { attrs: { slot: "header" }, slot: "header" },
                [
                  _c("md-table-head", { staticClass: "driver-break" }),
                  _c("md-table-head", { staticClass: "stop-status" }, [
                    _vm._v("Status"),
                  ]),
                  _c("md-table-head", { staticClass: "stop-time" }, [
                    _vm._v("Estimated Break Time"),
                  ]),
                  _c("md-table-head", { staticClass: "stop-time" }, [
                    _vm._v("Started"),
                  ]),
                  _c("md-table-head", { staticClass: "stop-time" }, [
                    _vm._v("Ended"),
                  ]),
                ],
                1
              ),
              _vm._l(
                _vm.tripDetails.driverBreakList,
                function (driverBreakItem, breakIndex) {
                  return _c(
                    "md-table-row",
                    { key: breakIndex },
                    [
                      _c("md-table-cell", { staticClass: "driver-break" }, [
                        _vm._v(
                          "\n                    Break " +
                            _vm._s(driverBreakItem.order) +
                            "\n                "
                        ),
                      ]),
                      _c("md-table-cell", { staticClass: "stop-status" }, [
                        _c(
                          "span",
                          {
                            staticClass: "status",
                            class:
                              "status-" +
                              driverBreakItem.status
                                .toLowerCase()
                                .replace(/\s+/g, ""),
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(driverBreakItem.status) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]),
                      _c("md-table-cell", { staticClass: "stop-time" }, [
                        _c("div", [
                          driverBreakItem.eta
                            ? _c("span", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("timeFormat")(
                                        driverBreakItem.eta,
                                        _vm.DATE_TYPES.standardTime
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("md-table-cell", { staticClass: "stop-time" }, [
                        _c("div", [
                          driverBreakItem.arrivalDate
                            ? _c("span", { staticClass: "bold-time" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("timeFormat")(
                                        driverBreakItem.arrivalDate,
                                        _vm.DATE_TYPES.standardTime
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("md-table-cell", { staticClass: "stop-time" }, [
                        _c("div", [
                          driverBreakItem.depatureDate
                            ? _c("span", { staticClass: "bold-time" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("timeFormat")(
                                        driverBreakItem.depatureDate,
                                        _vm.DATE_TYPES.standardTime
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  )
                }
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }