var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-container" }, [
    _c(
      "div",
      { staticClass: "close-button" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-default md-just-icon md-round",
            on: { click: _vm.closeModal },
          },
          [_c("md-icon", [_vm._v("close")])],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "button-arrows" }, [
      _c(
        "div",
        [
          _c(
            "md-button",
            {
              staticClass: "left-btn md-primary md-round",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleLeftClick($event)
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "md-label" },
                [
                  _c("md-icon", { staticClass: "material-icons" }, [
                    _vm._v(
                      "\n                        keyboard_arrow_left\n                    "
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "md-button",
            {
              staticClass: "right-btn md-primary md-round",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleRightClick($event)
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "md-label md-label-right" },
                [
                  _c("md-icon", { staticClass: "material-icons" }, [
                    _vm._v(
                      "\n                        keyboard_arrow_right\n                    "
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "main-container" }, [
      _c("div", { staticClass: "detail-section text-center" }, [
        _vm.stopDetails.stopRef != null
          ? _c(
              "span",
              { staticClass: "title blue-ref" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "Stop Details",
                        params: { stopId: _vm.stopDetails.stopId },
                      },
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.stopDetails.stopRef) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            )
          : _c(
              "span",
              { staticClass: "title" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "Stop Details",
                        params: { stopId: _vm.stopDetails.stopId },
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.stopDetails.id) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
      ]),
      _c("div", { staticClass: "line-separator" }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasNoMapLocation,
              expression: "!hasNoMapLocation",
            },
          ],
        },
        [_c("div", { ref: "map", staticClass: "map" })]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasNoMapLocation,
              expression: "hasNoMapLocation",
            },
          ],
          staticClass: "no-map",
        },
        [_c("p", [_vm._v("No map available")])]
      ),
      _vm.stopDetails.addressComponents != null
        ? _c("div", { staticClass: "detail-section" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("Address Components"),
            ]),
            _c(
              "div",
              { staticClass: "detail-group" },
              [
                _c("google-autocomplete", {
                  ref: "address",
                  attrs: {
                    label: "Address",
                    id: "autoaddress",
                    classname: "form-control autocomplete-input",
                    placeholder: "",
                    "should-focus": true,
                    "disable-gps-coordinates": true,
                    "should-keep-original-address": true,
                  },
                  on: {
                    handleCoordinates: _vm.handleCoordinates,
                    placechanged: _vm.handleAddressChange,
                  },
                  model: {
                    value: _vm.stopDetails,
                    callback: function ($$v) {
                      _vm.stopDetails = $$v
                    },
                    expression: "stopDetails",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Type:")]),
              _c("div", { staticClass: "value" }, [
                _vm.stopDetails.addressComponents != null &&
                _vm.stopDetails.addressComponents.type != null
                  ? _c(
                      "div",
                      {
                        class:
                          "custom-badge address-badge status-" +
                          _vm.$_getGeocodeAddressTypeBadgeColor(
                            _vm.stopDetails.addressComponents.type
                          ),
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$te(
                                "geocodeTypes." +
                                  _vm.stopDetails.addressComponents.type
                              )
                                ? _vm.$t(
                                    "geocodeTypes." +
                                      _vm.stopDetails.addressComponents.type
                                  )
                                : _vm.stopDetails.addressComponents.type
                            ) +
                            "\n                    "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [
                _vm._v("Confidence score:"),
              ]),
              _c("div", { staticClass: "value" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.stopDetails.addressComponents.confidenceScore == 0 ||
                        _vm.stopDetails.addressComponents.confidenceScore ==
                          null
                        ? "-"
                        : Math.round(
                            _vm.stopDetails.addressComponents.confidenceScore *
                              100
                          ) + "%"
                    ) +
                    "\n                "
                ),
              ]),
            ]),
            _vm.stopDetails.addressComponents.line1
              ? _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Line 1:")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.stopDetails.addressComponents.line1) +
                        "\n                "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.stopDetails.addressComponents.line2
              ? _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Line 2:")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.stopDetails.addressComponents.line2) +
                        "\n                "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.stopDetails.addressComponents.city
              ? _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("City:")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.stopDetails.addressComponents.city) +
                        "\n                "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.stopDetails.addressComponents.postcode
              ? _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Postcode:")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.stopDetails.addressComponents.postcode) +
                        "\n                "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.stopDetails.addressComponents.province
              ? _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Province:")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.stopDetails.addressComponents.province) +
                        "\n                "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.stopDetails.addressComponents.country
              ? _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Country:")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.stopDetails.addressComponents.country) +
                        "\n                "
                    ),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("div", { staticClass: "line-separator" }),
      _c("div", { staticClass: "detail-section" }, [
        _c("span", { staticClass: "title" }, [_vm._v("Stop Details")]),
        _c("div", { staticClass: "detail-group" }, [
          _c("label", { staticClass: "label" }, [_vm._v("When:")]),
          _c("div", { staticClass: "value" }, [
            _vm.stopDetails.tripDate == null &&
            _vm.stopDetails.appointmentTime == null &&
            _vm.stopDetails.timeWindowStart == null &&
            _vm.stopDetails.timeWindowEnd == null
              ? _c("span", [_c("i", [_vm._v("Unscheduled")])])
              : _vm._e(),
            _vm.stopDetails.tripDate != null
              ? _c(
                  "span",
                  [
                    _vm.stopDetails.tripId
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "Trip Details",
                                params: {
                                  tripId: _vm.stopDetails.tripId,
                                },
                              },
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.stopDetails.tripDate,
                                    _vm.DATE_TYPES.standardDate
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        )
                      : _c("span", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.stopDetails.tripDate,
                                  _vm.DATE_TYPES.standardDate
                                )
                              ) +
                              "\n                        "
                          ),
                        ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.stopDetails.timeWindowStart != null &&
            _vm.stopDetails.timeWindowEnd != null
              ? _c("span", [
                  _vm._v(
                    "\n                        (" +
                      _vm._s(
                        _vm._f("timeFormat")(_vm.stopDetails.timeWindowStart)
                      ) +
                      " -\n                        " +
                      _vm._s(
                        _vm._f("timeFormat")(_vm.stopDetails.timeWindowEnd)
                      ) +
                      ")\n                    "
                  ),
                ])
              : _vm.stopDetails.appointmentTime != null
              ? _c("span", [
                  _vm._v(
                    "\n                        (" +
                      _vm._s(
                        _vm._f("timeFormat")(_vm.stopDetails.appointmentTime)
                      ) +
                      ")\n                    "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "detail-group" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Contact Name:")]),
          _c("span", { staticClass: "value" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.stopDetails.contact.name) +
                "\n                "
            ),
          ]),
        ]),
        _c("div", { staticClass: "detail-group" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Contact Email:")]),
          _c("span", { staticClass: "value" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.stopDetails.contact.email) +
                "\n                "
            ),
          ]),
        ]),
        _c("div", { staticClass: "detail-group" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Contact Phone:")]),
          _c("span", { staticClass: "value" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.stopDetails.contact.phone) +
                "\n                "
            ),
          ]),
        ]),
        _c("div", { staticClass: "detail-group" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Notes:")]),
          _c("span", { staticClass: "value" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.stopDetails.notes) +
                "\n                "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }