<template>
    <md-button
        v-if="!isReadOnlyUser"
        title="Delete stop"
        :class="['md-danger md-just-icon md-round', isDisabled ? 'button-disabled' : '']"
        @click.stop="handleDeleteStop(stopId)"
    >
        <md-icon>delete</md-icon>
    </md-button>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';

export default {
    name: 'DeleteStopButton',
    props: {
        stopId: {
            type: [Number, String],
            default: () => null
        },
        teamMembers: {
            type: Array,
            default: () => []
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        isShipmentStop: {
            type: Boolean,
            default: false
        }
    },
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    methods: {
        handleDeleteStop(stopId) {
            if (this.isDisabled) {
                this.$notify({
                    message: 'Unable to delete a stop that is part of a shipment.',
                    type: 'danger'
                });
            } else {
                let message = 'Are you sure you want to delete this stop? This cannot be undone.';
                if (this.isShipmentStop) {
                    message =
                        'This stop is part of a shipment. This will delete the whole shipment. Are you sure you want to delete? This cannot be undone.';
                }

                this.$messageBox
                    .show({
                        class: 'sm-modal-container',
                        title: 'Delete Stop',
                        body: message,
                        buttons: ['Confirm', 'Cancel']
                    })
                    .then(async (response) => {
                        if (response.toLowerCase() === 'confirm') 
                            await this.handleConfirmDelete(stopId);
                    });
            }
        },
        async handleConfirmDelete(stopId) {
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'delete'
            };
            const api = `/api/stops/${stopId}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Stop was deleted!',
                    type: 'success'
                });
                this.$emit('stopDeleted', true);
            } catch (e) {
                const message = 'Error in deleting a stop.';
                showErrorMessage(this, message, e);
                this.$emit('stopDeleted', false);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.button-disabled {
    opacity: 0.2 !important;
    cursor: not-allowed;
    pointer-events: all !important;
}
</style>
