var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-container" }, [
    _c(
      "div",
      { staticClass: "close-button" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-default md-just-icon md-round pull-right",
            on: { click: _vm.closeModal },
          },
          [_c("md-icon", [_vm._v("close")])],
          1
        ),
      ],
      1
    ),
    !_vm.loading
      ? _c("div", [
          _c("div", { staticClass: "detail-section text-center" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.warehouseDetails.name)),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "detail-container-list" },
            [
              _c("div", { staticClass: "line-separator" }),
              _c("WarehouseSidebarDetails", {
                attrs: { "warehouse-details": _vm.warehouseDetails },
              }),
              _c("div", { staticClass: "line-separator" }),
              _c("WarehouseSidebarLocations", {
                attrs: { "warehouse-id": _vm.warehouseId },
                on: {
                  "location-created": _vm.fetchAllInfo,
                  "location-updated": _vm.fetchAllInfo,
                  "location-deleted": _vm.fetchAllInfo,
                },
              }),
            ],
            1
          ),
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "sidebar-loader" },
            [
              _c("fade-loader", {
                attrs: { loading: _vm.loading, color: "#333333" },
              }),
              _c("span", [_vm._v("LOADING")]),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }