<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ carrierName ? carrierName : '' }} Pre-booking Questions</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body" :class="showScrollbar ? 'scroll-body' : ''">
            <div
                class="field-container"
                v-for="(question, index) in $v.newAnswersList.$each.$iter"
                :key="index"
                name="name"
                :class="{
                    error: question.defaultValue.$error,
                    'field-text': question.$model.type == 'Text',
                    'checkbox-field': question.$model.type == 'CheckBox',
                    'dropdown-field': question.$model.type == 'Dropdown',
                    'textarea-custom-field': question.$model.type == 'MultiLineText'
                }"
            >
                <CustomFieldInputs
                    :custom-field-definition="question.$model"
                    :initial-value="question.defaultValue.$model"
                    @changed="handleAnswerChanged"
                />
            </div>
            <div
                class="field-container"
                v-for="(fld, index) in $v.mandatoryFields.$each.$iter"
                :key="`fld-${index}`"
                name="name"
                :class="{
                    error: fld.value.$error,
                    'field-text': fld.$model.type == 'Text',
                    'checkbox-field': fld.$model.type == 'CheckBox',
                    'dropdown-field': fld.$model.type == 'Dropdown',
                    'textarea-custom-field': fld.$model.type == 'MultiLineText'
                }"
            >
                <span
                    v-if="
                        index == 0 ||
                            $v.mandatoryFields.$each.$iter[index].$model.groupName !=
                                $v.mandatoryFields.$each.$iter[index - 1].$model.groupName
                    "
                >
                    {{ $t(`carrierFieldLabels.groups.${$v.mandatoryFields.$each.$iter[index].$model.groupName}`) }}
                </span>
                <CustomFieldInputs
                    :custom-field-definition="fld.$model"
                    :initial-value="fld.value.$model"
                    @changed="handleMandatoryAnswerChanged"
                />
            </div>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="continueAssignment">Continue</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { requiredIf, required } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { CustomFieldInputs } from '@/components';

export default {
    name: 'CarrierPreBookingQuestionsModal',
    mixins: [GeneralMixin],
    components: { CustomFieldInputs },
    props: {
        carrierTeamId: { type: Number, required: true },
        carrierName: { type: String, required: true, default: () => '' },
        fixedMappingList: { type: Array, default: () => [] },
        newAnswersList: { type: Array, default: () => [] },
        mandatoryFields: { type: Array, default: () => [] },
        resolve: { type: Function, default: () => {} }
    },
    computed: {
        showScrollbar() {
            return (this.newAnswersList?.length || 0) + (this.mandatoryFields?.length || 0) > 8;
        }
    },
    validations() {
        return {
            newAnswersList: {
                $each: {
                    defaultValue: {
                        required: requiredIf((item) => {
                            return item.isRequired;
                        })
                    }
                }
            },
            mandatoryFields: {
                $each: {
                    value: {
                        required
                    }
                }
            }
        };
    },
    methods: {
        handleAnswerChanged({ name, value, type }) {
            const questionIndex = this.newAnswersList.findIndex((x) => x.name === name);
            const updatedQuestion = this.newAnswersList[questionIndex];

            let parsedValue = value;
            if (!!value && type.toLowerCase() === 'date') {
                parsedValue = moment(value).format('YYYY-MM-DD');
            }

            updatedQuestion.defaultValue = parsedValue;
            this.$set(this.newAnswersList, questionIndex, updatedQuestion);
        },
        handleMandatoryAnswerChanged({ name, value, type }) {
            const fieldIndex = this.mandatoryFields.findIndex((x) => x.name === name);
            const updatedField = this.mandatoryFields[fieldIndex];

            let parsedValue = value;
            if (!!value && type.toLowerCase() === 'date') {
                parsedValue = moment(value).format('YYYY-MM-DD');
            }

            updatedField.value = parsedValue;
            this.$set(this.mandatoryFields, fieldIndex, updatedField);
        },
        async continueAssignment() {
            this.$v.newAnswersList.$touch();
            this.$v.mandatoryFields.$touch();
            if (this.$v.newAnswersList.$invalid || this.$v.mandatoryFields.$invalid) 
                return;

            this.resolve({
                result: 'ok',
                data: { newAnswersList: [...this.newAnswersList], mandatoryFields: [...this.mandatoryFields] }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-body {
    max-height: 500px;
}

.scroll-body {
    overflow: auto;
}

::v-deep .custom-mapping-select {
    max-width: 100% !important;
}
.error {
    border: solid 1px red;
    margin: 10px 0;
}

.field-text ::v-deep .md-field {
    padding-bottom: 0;
}
.field-text ::v-deep .md-field:before,
.field-text ::v-deep .md-field:after {
    bottom: 0px;
}

.field-text {
    padding-bottom: 5px;
}

.field-text ::v-deep .md-input {
    padding-bottom: 3px;
    min-width: 140px;
    padding: 0 6px 3px 6px;
}

.textarea-custom-field {
    ::v-deep .md-textarea {
        padding: 20px 6px 0 6px !important;
    }
}
</style>
