<template>
    <svg
        :width="width"
        :height="height"
        :viewBox="viewBox"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Map-pin">
                <g id="map-pin" fill="#007ABD">
                    <circle id="Oval" cx="15" cy="15" r="15"></circle>
                    <path
                        d="M0,15.5848107 L30,15.5848107 C30,20.2841756 28.5330904,24.5790498 25.5992713,28.4694335 C22.6654522,32.3598171 19.1323618,35.946929 15,39.2307692 C11.168119,35.9923428 7.71014874,32.4052309 4.62608924,28.4694335 C1.54202975,24.533636 2.27373675e-12,20.2387618 0,15.5848107 Z"
                        id="Rectangle"
                    ></path>
                    <circle id="Oval" cx="15.2884615" cy="15" r="5.76923077"></circle>
                </g>
                <text id="1" font-family="Roboto-Bold, Roboto" font-size="16" font-weight="bold" fill="#FFFFFF">
                    <tspan x="50%" y="45%" alignment-baseline="middle" text-anchor="middle">{{ iconName }}</tspan>
                </text>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'LocationMarker',
    props: {
        viewBox: { type: String, default: '0 0 31 40' },
        iconName: { type: [String, Number], default: '' },
        width: { type: [Number, String], default: 23 },
        height: { type: [Number, String], default: 32 },
        iconColor: { type: String, default: '#007ABD' }
    }
};
</script>
