var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.getModalTitle()) + " Quotes"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "modal-body" }, [
      !_vm.isLoading &&
      (_vm.activeQuotes.length > 0 ||
        _vm.expiredQuotes.length > 0 ||
        _vm.errorQuotes.length > 0)
        ? _c("div", { staticClass: "top-section" }, [
            _c(
              "div",
              { staticClass: "date-list" },
              [
                _c(
                  "drop-down",
                  {
                    attrs: { "should-close": _vm.shouldCloseDropdown },
                    on: {
                      closeDropdown: function (val) {
                        return (this$1.shouldCloseDropdown = val)
                      },
                      closeOtherDropDown: _vm.closeOtherDropDown,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "quote-history-dropdown",
                        attrs: { slot: "title", "data-toggle": "dropdown" },
                        slot: "title",
                      },
                      [_vm._v("Quote History")]
                    ),
                    _c(
                      "ul",
                      { staticClass: "dropdown-menu" },
                      _vm._l(_vm.quoteDateOptions, function (date, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            class:
                              _vm.selectedDateKey == date
                                ? "selected-date"
                                : "",
                          },
                          [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleQuoteHistory(date)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.toLocalTime(date)) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "tabs",
                {
                  staticClass: "custom-tab-list",
                  attrs: {
                    "tab-name": _vm.tabNames,
                    "color-button": "success",
                    "badge-length": _vm.badgeLength,
                    "hide-empty-tabs": true,
                    "selected-panel": _vm.activeTab,
                  },
                  on: { "switch-panel": _vm.switchTab },
                },
                [
                  _c(
                    "template",
                    { slot: "tab-pane-1" },
                    [
                      _vm.activeQuotes.length > 0 &&
                      _vm.latestDate != _vm.selectedDateKey
                        ? _c("div", { staticClass: "old-quote-warning" }, [
                            _vm._v(
                              "\n                        These are old quotes generated at\n                        " +
                                _vm._s(_vm.toLocalTime(_vm.selectedDateKey)) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                      _vm.showIncomingQuoteLoader
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "text-center quotes-loader-container",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "quotes-loader" },
                                [_c("StraightLineLoader")],
                                1
                              ),
                              _vm._v(
                                "\n                        Retrieving quotes\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.activeQuotes.length > 0
                        ? [
                            _c(
                              "div",
                              [
                                _c("md-table", {
                                  attrs: {
                                    value: _vm.activeQuotes,
                                    "md-sort": _vm.sortBy,
                                    "md-sort-order": _vm.sortOrder,
                                  },
                                  on: {
                                    "md-selected": _vm.onSelect,
                                    "update:mdSort": function ($event) {
                                      _vm.sortBy = $event
                                    },
                                    "update:md-sort": function ($event) {
                                      _vm.sortBy = $event
                                    },
                                    "update:mdSortOrder": function ($event) {
                                      _vm.sortOrder = $event
                                    },
                                    "update:md-sort-order": function ($event) {
                                      _vm.sortOrder = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "md-table-row",
                                        fn: function (ref) {
                                          var item = ref.item
                                          var index = ref.index
                                          return _c(
                                            "md-table-row",
                                            {
                                              key: index,
                                              attrs: {
                                                "md-selectable": "single",
                                              },
                                            },
                                            [
                                              _c(
                                                "md-table-cell",
                                                {
                                                  staticClass: "carrier-logo",
                                                  attrs: { "md-label": "" },
                                                },
                                                [
                                                  item.carrierTeam.logoUrl !=
                                                  null
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: item.carrierTeam
                                                            .logoUrl,
                                                          alt: "",
                                                        },
                                                      })
                                                    : _c("img", {
                                                        attrs: {
                                                          src: _vm.$root
                                                            .defaultLogoUrl,
                                                          alt: "",
                                                        },
                                                      }),
                                                ]
                                              ),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  attrs: {
                                                    "md-label": "Carrier",
                                                    "md-sort-by":
                                                      "carrierTeam.company",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        item.carrierTeam.company
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  attrs: {
                                                    "md-label": "Amount",
                                                    "md-sort-by":
                                                      "amountAfterTax",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          item.amountAfterTax,
                                                          item.currency
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  attrs: {
                                                    "md-label": "Service Type",
                                                    "md-sort-by":
                                                      "serviceLevel",
                                                  },
                                                },
                                                [
                                                  item.serviceLevel
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "custom-badge",
                                                          class:
                                                            "status-" +
                                                            _vm.$_getServiceLevelBadgeColor(
                                                              item.timeWindowEnd
                                                            ),
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  item.serviceLevel
                                                                )
                                                              ) +
                                                              "\n                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  attrs: {
                                                    "md-label": "Pickup ETA",
                                                    "md-sort-by":
                                                      "timeWindowEnd",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "quoteDateTimeFormat"
                                                        )(item.timeWindowStart)
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "md-table-cell",
                                                {
                                                  attrs: {
                                                    "md-label": "Delivery ETA",
                                                    "md-sort-by":
                                                      "timeWindowEnd",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "quoteDateTimeFormat"
                                                        )(item.timeWindowEnd)
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1866123420
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        : [
                            !_vm.showIncomingQuoteLoader
                              ? _c("div", [
                                  _vm.hasError
                                    ? _c(
                                        "span",
                                        { staticClass: "empty-quotes" },
                                        [
                                          _vm._v(
                                            "\n                                Error in fetching quotes. Please try again later or\n                                "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "quote-link",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.requestQuotes()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    click here to refresh qoutes.\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm.shipment.quotingStatus ===
                                      "GatheringQuotes"
                                    ? _c(
                                        "span",
                                        { staticClass: "empty-quotes" },
                                        [
                                          _vm._v(
                                            "\n                                No available quotes at the moment.\n                                "
                                          ),
                                          _c("br"),
                                          _c("br"),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "quote-link",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.requestQuotes()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Click here to refresh qoutes.\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "empty-quotes" },
                                        [_vm._v("No quotes gathered.")]
                                      ),
                                ])
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "template",
                    { slot: "tab-pane-2" },
                    [
                      _vm.expiredQuotes.length > 0 &&
                      _vm.latestDate != _vm.selectedDateKey
                        ? _c("div", { staticClass: "old-quote-warning" }, [
                            _vm._v(
                              "\n                        These are old quotes generated at\n                        " +
                                _vm._s(_vm.toLocalTime(_vm.selectedDateKey)) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                      _vm.showIncomingQuoteLoader
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "text-center quotes-loader-container",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "quotes-loader" },
                                [_c("StraightLineLoader")],
                                1
                              ),
                              _vm._v(
                                "\n                        Retrieving quotes\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.expiredQuotes.length > 0
                        ? [
                            _c("md-table", {
                              attrs: {
                                value: _vm.expiredQuotes,
                                "md-sort": _vm.sortBy,
                                "md-sort-order": _vm.sortOrder,
                              },
                              on: {
                                "update:mdSort": function ($event) {
                                  _vm.sortBy = $event
                                },
                                "update:md-sort": function ($event) {
                                  _vm.sortBy = $event
                                },
                                "update:mdSortOrder": function ($event) {
                                  _vm.sortOrder = $event
                                },
                                "update:md-sort-order": function ($event) {
                                  _vm.sortOrder = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "md-table-row",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return _c(
                                        "md-table-row",
                                        { class: _vm.getClass(item) },
                                        [
                                          _c(
                                            "md-table-cell",
                                            {
                                              staticClass: "carrier-logo",
                                              attrs: { "md-label": "" },
                                            },
                                            [
                                              item.carrierTeam.logoUrl &&
                                              item.carrierTeam.logoUrl != null
                                                ? _c("img", {
                                                    attrs: {
                                                      src: item.carrierTeam
                                                        .logoUrl,
                                                      alt: "",
                                                    },
                                                  })
                                                : _c("img", {
                                                    attrs: {
                                                      src: _vm.$root
                                                        .defaultLogoUrl,
                                                      alt: "",
                                                    },
                                                  }),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              staticClass: "carrier-name",
                                              attrs: { "md-label": "Carrier" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    item.carrierTeam
                                                      ? item.carrierTeam.company
                                                      : ""
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: {
                                                "md-label": "Amount",
                                                "md-sort-by": "amountAfterTax",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        item.amountAfterTax,
                                                        item.currency
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: {
                                                "md-label": "Service Type",
                                              },
                                            },
                                            [
                                              item.serviceLevel
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "custom-badge",
                                                      class:
                                                        "status-" +
                                                        _vm.$_getServiceLevelBadgeColor(
                                                          item.timeWindowEnd
                                                        ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              item.serviceLevel
                                                            )
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: { "md-label": "Expired" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "md-list-item-text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.toLocalTime(
                                                          item.expiryDate
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            [
                                              item.notes
                                                ? _c(
                                                    "md-icon",
                                                    {
                                                      staticClass: "help-icon",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        help\n                                        "
                                                      ),
                                                      _c("md-tooltip", [
                                                        _vm._v(
                                                          _vm._s(item.notes)
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "md-table-cell",
                                            { attrs: { "md-label": "" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "action-button-container",
                                                },
                                                [
                                                  _c(
                                                    "md-button",
                                                    {
                                                      staticClass:
                                                        "md-success md-just-icon md-round",
                                                      attrs: {
                                                        title: "Refresh quotes",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.requestQuotes(
                                                            item.carrierTeam
                                                              .carrierTeamId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("md-icon", [
                                                        _vm._v("refresh"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                115106647
                              ),
                            }),
                          ]
                        : [
                            _c("span", { staticClass: "empty-quotes" }, [
                              _vm._v("No expired quotes."),
                            ]),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "template",
                    { slot: "tab-pane-3" },
                    [
                      _vm.showIncomingQuoteLoader
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "text-center quotes-loader-container",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "quotes-loader" },
                                [_c("StraightLineLoader")],
                                1
                              ),
                              _vm._v(
                                "\n                        Retrieving quotes\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.errorQuotes.length > 0 &&
                      _vm.latestDate != _vm.selectedDateKey
                        ? _c("div", { staticClass: "old-quote-warning" }, [
                            _vm._v(
                              "\n                        These are old quotes generated at\n                        " +
                                _vm._s(
                                  _vm._f("dateTimeFormat")(
                                    _vm.selectedDateKey,
                                    _vm.DATE_TYPES.standardDate
                                  )
                                ) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                      _vm.errorQuotes.length > 0
                        ? [
                            _c("md-table", {
                              staticClass: "table-hover",
                              attrs: { value: _vm.errorQuotes },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "md-table-row",
                                    fn: function (ref) {
                                      var item = ref.item
                                      var index = ref.index
                                      return _c(
                                        "md-table-row",
                                        {
                                          key: index,
                                          class: _vm.getClass(item),
                                        },
                                        [
                                          _c(
                                            "md-table-cell",
                                            {
                                              staticClass: "carrier-logo",
                                              attrs: { "md-label": "" },
                                            },
                                            [
                                              item.carrierTeam &&
                                              item.carrierTeam.logoUrl &&
                                              item.carrierTeam.logoUrl != null
                                                ? _c("img", {
                                                    attrs: {
                                                      src: item.carrierTeam
                                                        .logoUrl,
                                                      alt: "",
                                                    },
                                                  })
                                                : _c("img", {
                                                    attrs: {
                                                      src: _vm.$root
                                                        .defaultLogoUrl,
                                                      alt: "",
                                                    },
                                                  }),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              staticClass: "carrier-name",
                                              attrs: { "md-label": "Carrier" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    item.carrierTeam
                                                      ? item.carrierTeam.company
                                                      : ""
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: { "md-label": "Message" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    item.error
                                                      ? item.error.message
                                                      : ""
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            [
                                              _vm.shouldDisplayErrors(item)
                                                ? _c(
                                                    "md-icon",
                                                    {
                                                      staticClass: "help-icon",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        help\n                                        "
                                                      ),
                                                      _c("md-tooltip", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.error.errors
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : [
                                                    _vm._v(
                                                      "\n                                         \n                                    "
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                2424522074
                              ),
                            }),
                          ]
                        : [
                            _c("span", { staticClass: "empty-quotes" }, [
                              _vm._v("No quotes gathered"),
                            ]),
                          ],
                    ],
                    2
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm.showIncomingQuoteLoader &&
          _vm.activeQuotes.length == 0 &&
          _vm.expiredQuotes.length == 0 &&
          _vm.errorQuotes.length == 0
        ? _c(
            "div",
            [
              [
                _c(
                  "div",
                  { staticClass: "list-loader" },
                  [
                    _c("fade-loader", {
                      attrs: { loading: _vm.isLoading, color: "#333333" },
                    }),
                    _c("span", [_vm._v("Retrieving Quotes")]),
                  ],
                  1
                ),
              ],
            ],
            2
          )
        : _c(
            "div",
            [
              [
                _c(
                  "div",
                  { staticClass: "list-loader" },
                  [
                    _c("fade-loader", {
                      attrs: { loading: _vm.isLoading, color: "#333333" },
                    }),
                    _c("span", [_vm._v("Retrieving Quotes")]),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _vm.shipment.quotingStatus != "GatheringQuotes" &&
        (_vm.shipment.status == "Pending" ||
          _vm.shipment.status == "On Hold") &&
        !_vm.isPastDate &&
        !this.isLoading
          ? _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary reload-icon",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.requestQuotes()
                  },
                },
              },
              [_vm._v("\n            Refresh Quotes\n        ")]
            )
          : _vm._e(),
        !_vm.isBulk &&
        _vm.shipment.quotingStatus != "GatheringQuotes" &&
        (_vm.shipment.status == "Pending" ||
          _vm.shipment.status == "On Hold") &&
        !_vm.isPastDate
          ? _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary",
                attrs: {
                  disabled: !_vm.selectedQuote || _vm.hasAssignedCarrier,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.assignShipment($event)
                  },
                },
              },
              [_vm._v("\n            Assign Shipment\n        ")]
            )
          : _vm._e(),
        _vm.isBulk &&
        _vm.shipment.quotingStatus != "GatheringQuotes" &&
        (_vm.shipment.status == "Pending" ||
          _vm.shipment.status == "On Hold") &&
        !_vm.isPastDate
          ? _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary",
                attrs: {
                  disabled: !_vm.selectedQuote || _vm.hasAssignedCarrier,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.quoteSelected($event)
                  },
                },
              },
              [_vm._v("\n            Select Quote\n        ")]
            )
          : _vm._e(),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Close")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }