<template>
    <div class="cf-container">
        <div class="cf-action-container">
            <md-button
                title="Create Account Code"
                class="md-primary md-just-icon md-round"
                @click.stop="createAccountCode"
            >
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <md-table class="custom-paginated-table" v-if="!isListLoading">
            <md-table-row>
                <md-table-head>Type</md-table-head>
                <md-table-head>Account Code</md-table-head>
                <md-table-head>Description</md-table-head>
                <md-table-head>Tax Type</md-table-head>
                <md-table-head class="actions">Actions</md-table-head>
            </md-table-row>

            <md-table-row v-for="item in accountCodeList" :key="item.accountCode">
                <md-table-cell>
                    <span class="custom-badge" :class="item.type == 'Income' ? `status-complete` : 'status-failed'">
                        {{ item.type }}
                    </span>
                </md-table-cell>
                <md-table-cell>{{ item.code }}</md-table-cell>
                <md-table-cell>{{ item.description }}</md-table-cell>
                <md-table-cell>{{ item.defaultTaxType }}</md-table-cell>

                <md-table-cell class="action-buttons">
                    <md-button
                        title="Update account code"
                        class="md-warning md-just-icon md-round"
                        @click.stop="update(item)"
                    >
                        <md-icon>edit</md-icon>
                    </md-button>
                    <span>
                        <md-button
                            title="Delete account code"
                            class="md-danger md-just-icon md-round"
                            @click.stop="handleDelete(item.accountCodeId)"
                        >
                            <md-icon>delete</md-icon>
                        </md-button>
                    </span>
                </md-table-cell>
            </md-table-row>
        </md-table>
        <div v-else>
            <div class="stopbar-loader">
                <fade-loader :loading="true" color="#333333" />
                <span>LOADING</span>
            </div>
        </div>
        <div class="text-center" v-if="accountCodeList.length == 0 && !isListLoading">
            <p class="no-result-message">No results matching your search/filter could be found.</p>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import CreateAccountCodeModal from './CreateAccountCodeModal';

export default {
    name: 'AccountCodeSettings',
    components: {
        FadeLoader
    },
    mixins: [GeneralMixin],
    data() {
        return {
            accountCodeList: [],
            isListLoading: false
        };
    },
    async mounted() {
        this.accountCodeList = await this.getAccountCodeList();
    },
    methods: {
        async getAccountCodeList() {
            let response = {
                data: []
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/team-settings/account-codes`;

                response = await handleRequests(endpoint);
            } catch (error) {
                const message = 'Error in getting the account code list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;
            return response.data;
        },
        async createAccountCode() {
            this.$modal
                .show(CreateAccountCodeModal, {
                    accountCodeDetails: {
                        code: null,
                        defaultTaxSettingId: null,
                        description: null,
                        type: null
                    }
                })
                .then(async (response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.accountCodeList = await this.getAccountCodeList();
                        this.$modal.hide();
                    }
                });
        },
        async update(item) {
            this.$modal
                .show(CreateAccountCodeModal, {
                    accountCodeDetails: Object.assign({}, item),
                    isUpdate: true
                })
                .then(async (response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.accountCodeList = await this.getAccountCodeList();
                        this.$modal.hide();
                    }
                });
        },
        async deleteAccountCode(id) {
            const payload = {
                method: 'delete'
            };
            const api = `/api/team-settings/account-codes/${id}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Account code was deleted!',
                    type: 'success'
                });

                this.accountCodeList = await this.getAccountCodeList();
            } catch (e) {
                const message = 'Error in deleting a account code.';
                showErrorMessage(this, message, e);
                this.isListLoading = false;
            }
        },
        handleDelete(id) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Account Code',
                    body: 'Are you sure you want to delete this account code?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isListLoading = true;
                        await this.deleteAccountCode(id);
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.cf-container {
    min-height: 200px;
}

.stopbar-loader {
    min-height: 400px;
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}
</style>
