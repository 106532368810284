<script>
import { ref, toRefs, inject, onMounted, onBeforeUnmount, computed, watch } from '@vue/composition-api';
import useMapUtils from '@/compostables/useMapUtils';
import { MAP_ZOOM_LEVEL_BREAKPOINT } from '@/utils/constants';

export default {
    name: 'LocationHistoryMarker',
    props: {
        locationHistoryDetails: {
            type: Object,
            required: true
        },
        pinnedDriverDetails: {
            type: Object,
            default: () => {}
        },
        zoomLevel: {
            type: Number,
            default: null
        }
    },
    setup(props, { root }) {
        const { locationHistoryDetails } = props;
        const {
            coordinates: { latitude, longitude },
            minutesFromMidnight
        } = locationHistoryDetails;
        const { setMarkers } = useMapUtils(root);
        const { getters } = inject('vuex-store');
        const google = inject('google');
        const map = inject('map');
        const bounds = inject('bounds');
        const isStreetView = inject('isStreetView');
        const marker = ref(null);
        const isHeartbeatsToggled = computed(() => getters['map/isHeartbeatsToggled']);

        const { zoomLevel } = toRefs(props);

        const timeWindow = computed(() => getters['map/timeWindow']);
        const { pinnedDriverDetails } = toRefs(props);

        onMounted(() => {
            const markerToShow = pinnedDriverDetails.value.markerPins;

            marker.value = setMarkers(google.value, map.value, latitude, longitude, 'heartBeat', markerToShow);

            bounds.value.extend(marker.value.getPosition());
            map.value.fitBounds(bounds.value);

            marker.value.addListener('click', () => {
                const data = Object.assign({}, pinnedDriverDetails.value, locationHistoryDetails);
                root.$emit('marker-clicked', {
                    data,
                    marker: marker.value,
                    type: 'locationHistory'
                });
            });

            // dont display marker if it's not withing the timeWindow range
            const [start, end] = timeWindow.value;
            if (start < minutesFromMidnight && minutesFromMidnight > end) 
                marker.value.setMap(null);
            // don't display marker if location history is not toggled.
            if (!isHeartbeatsToggled.value) {
                marker.value.setMap(null);
            }
        });

        onBeforeUnmount(() => {
            marker.value.setMap(null);
            marker.value = null;
        });

        const isPlaying = computed(() => getters['map/isPlaying']);

        watch(isPlaying, (newVal) => {
            if (newVal) {
                marker.value.setMap(null);
            } else {
                const zoomLevel = map.value.getZoom();
                if (zoomLevel <= MAP_ZOOM_LEVEL_BREAKPOINT) {
                    marker.value.setMap(null);
                } else {
                    marker.value.setMap(map.value);
                    bounds.value.extend(marker.value.getPosition());
                    map.value.fitBounds(bounds.value);
                }
            }
        });

        watch(isStreetView, (newVal) => {
            marker.value.setMap(!newVal && isHeartbeatsToggled.value ? map.value : null);
        });

        watch(isHeartbeatsToggled, (newVal) => {
            marker.value.setMap(newVal ? map.value : null);
        });

        watch(timeWindow, ([start, end]) => {
            if (minutesFromMidnight > end) 
                marker.value.setMap(null);
            else {
                const zoomLevel = map.value.getZoom();
                if (zoomLevel <= MAP_ZOOM_LEVEL_BREAKPOINT) {
                    marker.value.setMap(null);
                } else {
                    marker.value.setMap(map.value);
                    bounds.value.extend(marker.value.getPosition());
                    map.value.fitBounds(bounds.value);
                }
            }
        });

        // check if zoom level is greater than breakpoint
        watch(zoomLevel, (newVal) => {
            if (!isHeartbeatsToggled.value) {
                marker.value.setMap(null);
                return true;
            }
            // if less than breakpoint, don't show marker
            if (newVal <= MAP_ZOOM_LEVEL_BREAKPOINT) 
                marker.value.setMap(null);
            // if greater than breakpoint, show marker if it is within bounds.
            else {
                marker.value.setMap(bounds.value.contains(marker.value.getPosition()) ? map.value : null);
                // dont display marker if it's not withing the timeWindow range
                const [start, end] = timeWindow.value;
                if (start < minutesFromMidnight && minutesFromMidnight > end) 
                    marker.value.setMap(null);
            }
            return true;
        });

        return {
            marker
        };
    },
    render: () => null
};
</script>
