<template>
    <div>
        <div class="detail-container">
            <div class="close-button">
                <md-button class="md-default md-just-icon md-round pull-right" @click="closeModal">
                    <md-icon>close</md-icon>
                </md-button>
            </div>
            <div v-if="!loading">
                <div class="detail-section text-center">
                    <span class="title blue-ref">
                        <router-link
                            :to="{
                                name: 'Asset Details',
                                params: { assetId: assetDetails.assetId }
                            }"
                            target="_blank"
                        >
                            {{ assetDetails.name }}
                        </router-link>
                    </span>
                    <div class="detail-group text-center action-detail-group">
                        <router-link
                            title="View asset details"
                            class="md-button md-success md-just-icon md-round"
                            :to="{
                                name: 'Asset Details',
                                params: { assetId: assetDetails.assetId }
                            }"
                            target="_blank"
                        >
                            <div class="md-ripple">
                                <div class="md-button-content">
                                    <md-icon>visibility</md-icon>
                                </div>
                            </div>
                        </router-link>
                        <update-asset-button
                            :asset="assetDetails"
                            :custom-fields="assetCustomFieldList"
                            @onAssetUpdate="updateAsset"
                        />
                        <create-assign-maintenance-schedule-button
                            v-if="assetType == 'Vehicle'"
                            :maintenance-schedules="maintenanceSchedules"
                            :asset="assetDetails"
                            @onAssignSchedule="handleAssignMaintenanceSchedule"
                        />
                        <!--                        delete asset button-->
                    </div>
                </div>
                <div class="line-separator"></div>
                <div class="detail-section">
                    <span class="title">Asset Details</span>
                    <div class="detail-group">
                        <label class="label">Name:</label>
                        <span class="value">
                            {{ assetDetails.name }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Type:</label>
                        <span class="value">
                            {{ assetDetails.type }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">
                            Odometer:
                        </label>
                        <span class="value" v-if="assetDetails.estimatedOdometerValue">
                            {{ Number(assetDetails.estimatedOdometerValue).toLocaleString() }}
                            {{ assetDetails.estimatedOdometerUnitType }}
                        </span>
                        <span class="value" v-else>
                            <i>(Not set)</i>
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">
                            Engine hours:
                        </label>
                        <span class="value" v-if="assetDetails.estimatedEngineHourValue">
                            {{ Number(assetDetails.estimatedEngineHourValue).toLocaleString() }} hours
                        </span>
                        <span class="value" v-else>
                            <i>(Not set)</i>
                        </span>
                    </div>
                    <div class="detail-group" v-if="assetDetails.type === 'Vehicle' || assetDetails.type === 'Trailer'">
                        <label class="label">
                            Vehicle License Plate:
                        </label>
                        <span class="value" v-if="assetDetails.vehicleLicensePlate">
                            {{ assetDetails.vehicleLicensePlate }}
                        </span>
                        <span class="value" v-else>
                            <i>(Not set)</i>
                        </span>
                    </div>
                </div>
                <div class="line-separator" v-if="assetType === 'Tracker'"></div>
                <div class="detail-section" v-if="assetType === 'Tracker'">
                    <span class="title">Tracker Details</span>
                    <div class="detail-group" v-for="(customField, index) in trackerExtraFields" :key="index">
                        <span class="label">{{ customField.label }}:</span>
                        <div
                            class="value"
                            v-if="customFieldValues !== null && customFieldValues[customField.name] !== null"
                        >
                            <span v-if="customField.type.toLowerCase() === 'date'">
                                {{ customFieldValues[customField.name] | dateTimeFormat(DATE_TYPES.standardDate) }}
                            </span>
                            <span v-else-if="customField.name === 'trackerReference'">
                                {{ assetDetails.trackerReference }}
                            </span>
                            <span v-else>{{ customFieldValues[customField.name] }}</span>
                        </div>
                    </div>
                </div>

                <div class="line-separator" />
                <div class="detail-group">
                    <AssetSidebarLocations
                        :asset-id="assetId"
                        @location-created="fetchAllInfo"
                        @location-updated="fetchAllInfo"
                        @location-deleted="fetchAllInfo"
                    />
                </div>
                <div class="line-separator" v-if="assetCustomFieldList.length"></div>
                <div class="detail-section contact-detail-section" v-if="assetCustomFieldList.length">
                    <span class="title">Custom fields</span>
                    <div
                        class="detail-group"
                        v-for="(customField, index) in assetCustomFieldList"
                        :key="index"
                        v-if="customField.assetType === 'Any' || customField.assetType === assetDetails.type"
                    >
                        <span class="label">{{ customField.label }}:</span>
                        <div
                            class="value"
                            v-if="customFieldValues !== null && customFieldValues[customField.name] !== null"
                        >
                            <span v-if="customField.type.toLowerCase() === 'date'">
                                {{ customFieldValues[customField.name] | dateTimeFormat(DATE_TYPES.standardDate) }}
                            </span>
                            <span v-else>{{ customFieldValues[customField.name] }}</span>
                        </div>
                    </div>
                </div>
                <div class="line-separator"></div>
                <div class="detail-section">
                    <span class="title">Upcoming Maintenance</span>
                    <div v-if="assetDetails.upcomingMaintenanceSchedules.length != 0">
                        <div class="detail-group">
                            <div
                                v-for="(item, index) in assetDetails.upcomingMaintenanceSchedules.slice(0, 5)"
                                :key="index"
                            >
                                <div class="maintenance-row">
                                    <span
                                        class="custom-badge"
                                        :class="`${item.status.replace(/\s/g, '').toLowerCase()}-badge`"
                                    >
                                        {{ item.status }}
                                    </span>
                                    <span>{{ item.maintenanceName }}</span>
                                    <reminder-indicator
                                        :maintenance-data="item"
                                        :current-engine-hours="item.estimatedEngineHourValue"
                                        :next-engine-hour-due="
                                            item.nextDueEngineHours != null ? parseFloat(item.nextDueEngineHours) : null
                                        "
                                        :next-due-distance="
                                            item.nextDueOdometer != null ? parseFloat(item.nextDueOdometer) : null
                                        "
                                        :current-distance-reading="item.estimatedOdometerValue"
                                        :repeat-interval="parseInt(1)"
                                        :next-due-date="item.nextDueServiceDate"
                                    />
                                </div>
                            </div>
                            <div
                                class="text-center view-more-btn"
                                v-if="assetDetails.upcomingMaintenanceSchedules.length > 5"
                            >
                                <router-link
                                    :to="{
                                        name: 'Asset Details',
                                        params: { assetId: assetDetails.assetId }
                                    }"
                                    target="_blank"
                                >
                                    View more
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div v-else class="detail-group">No upcoming maintenance schedule available</div>
                </div>
                <div class="line-separator"></div>
                <div class="detail-section">
                    <span class="title">Recent logs</span>
                    <div v-if="assetDetails.logs.length != 0">
                        <div class="detail-group">
                            <div v-for="(logItem, index) in assetDetails.logs.slice(0, 5)" :key="index" class="log-box">
                                <div>
                                    <span>{{ logItem.createdDate | dateFormat(DATE_TYPES.internationalDate) }}</span>
                                    -
                                    <span class="log-box-title" @click="showDetails(logItem)">
                                        {{ $t('assetLogTypes.' + logItem.logType) }} ({{
                                            $t('assetLogSubTypes.' + logItem.logSubType)
                                        }})
                                    </span>
                                </div>
                            </div>
                            <div class="text-center view-more-btn" v-if="assetDetails.logs.length > 5">
                                <router-link
                                    :to="{
                                        name: 'Asset Details',
                                        params: { assetId: assetDetails.assetId }
                                    }"
                                    target="_blank"
                                >
                                    View more
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div v-else class="detail-group">No recent logs available</div>
                </div>
            </div>
            <div v-else>
                <div class="stopbar-loader">
                    <fade-loader :loading="loading" color="#333333" />
                    <span>LOADING</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins';
import { mapGetters } from 'vuex';
import { trackerDefaults } from '@/utils/customAssetFieldDefaults';
import ItemLocationList from '@/components/ItemLocations/ItemLocationList';
import ReminderIndicator from '@/pages/Maintenance/components/ReminderIndicator';
import CreateAssignMaintenanceScheduleButton from '@/pages/Maintenance/buttons/AssignMaintenanceScheduleButton';
import UpdateAssetButton from './Buttons/UpdateAssetButton';
import AssetLogDetailsModal from './ModalComponents/AssetLogDetailsModal';

export default {
    name: 'AssetSidebar',
    mixins: [GeneralMixin],
    components: {
        FadeLoader,
        UpdateAssetButton,
        ReminderIndicator,
        CreateAssignMaintenanceScheduleButton,
        AssetSidebarLocations: ItemLocationList
    },
    computed: {
        ...mapGetters({
            getUser: 'user/user'
        })
    },
    data() {
        return {
            loading: true,
            assetDetails: null,
            assetCustomFieldList: [],
            trackerExtraFields: [],
            customFieldValues: {}
        };
    },
    props: {
        assetId: {
            type: [Number, String],
            default: () => null
        },
        assetType: {
            type: [String],
            default: () => null
        },
        maintenanceSchedules: {
            type: Array,
            default: () => null
        }
    },
    watch: {
        async assetId(newValue, oldValue) {
            if (newValue !== oldValue) 
                await this.fetchAllInfo();
        }
    },
    async mounted() {
        const user = this.getUser;
        if (user.assetCustomFieldDefinitions) {
            this.assetCustomFieldList = user.assetCustomFieldDefinitions.filter((x) => x.assetType === this.assetType);
        }
        if (this.assetType === 'Tracker') {
            this.trackerExtraFields = trackerDefaults;
        }
        await this.fetchAllInfo();
    },
    methods: {
        async fetchAllInfo() {
            this.loading = true;
            try {
                const promises = [this.getAssetDetails(this.assetId)];
                await Promise.all(promises);

                this.loading = false;
            } catch (e) {
                const message = 'Error in getting the asset details';
                showErrorMessage(this, message, e);
            }
        },
        async getAssetDetails(assetId) {
            const assetDetailsEndpoint = `/api/assets/${assetId}`;
            const {
                data: { data }
            } = await handleRequests(assetDetailsEndpoint);
            this.assetDetails = data;
            this.customFieldValues = this.assetDetails.customFields;
        },
        closeModal() {
            this.$emit('closeModal');
        },
        updateAsset(response) {
            this.$emit('updateStop', response);
        },
        showDetails(data) {
            this.$modal.show(AssetLogDetailsModal, { logData: data }).then((response) => {
                this.$modal.hide();
            });
        },
        handleAssignMaintenanceSchedule() {
            this.getAssetDetails(this.assetId);
        }
    }
};
</script>

<style lang="scss" scoped>
.detail-container {
    position: fixed;
    top: 2vh;
    right: 10px;
    height: 96vh;
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    background: white;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 16px;

    .close-button {
        top: 0;
        right: 0;
        position: absolute;
        /* add z-index for old stops that have no stop reference numbers */
        z-index: 999;
    }

    .rating {
        display: flex;
        align-items: flex-end;

        > {
            display: inline-block;
        }

        .md-icon {
            margin: 0;
            padding-bottom: 5px;
        }
    }

    .md-button.md-just-icon,
    ::v-deep .md-button.md-just-icon {
        font-size: 15px;
        height: 30px;
        min-width: 3px;
        width: 30px;
        line-height: 30px;
        margin: 2.5px;
    }

    .detail-section {
        padding-top: 10px;

        .blue-ref a {
            color: #2b93ff !important;
        }

        .title {
            text-transform: uppercase;
            font-weight: 600;
            padding: 2px 16px;
            display: block;
            font-size: 14px;
        }

        ::v-deep .custom-a-blue a {
            color: #2b93ff !important;
        }
    }

    .line-separator {
        height: 1px;
        background-color: #ddd;
        width: calc(100% - 32px);
        margin: 10px auto;
        margin-bottom: 0px;
    }

    .detail-group {
        padding: 2px 16px;
        margin: 5px 0;
        font-size: 0.9em;

        .label {
            display: inline-block;
            width: 95px;
            margin-right: 4px;
            vertical-align: top;
            font-weight: 400;
        }

        .value {
            display: inline-block;
            width: calc(100% - 105px);
            vertical-align: top;

            a {
                color: #2b93ff;
            }

            i {
                color: #aaa;
            }
        }

        .custom-btn,
        ::v-deep .custom-btn {
            padding: 4px 8px;
            margin: 0;
            width: initial;
            max-width: initial;
            padding: 4px 0;

            .md-ripple {
                padding: 0 !important;
            }
        }

        .address {
            color: #2b93ff;

            &:hover {
                text-decoration: underline !important;
                cursor: pointer;
            }
        }

        .status-label {
            margin-left: 10px;
            font-weight: 400;
            font-size: 0.75rem;
            width: calc(100% - 100px);
        }

        .info-line {
            font-weight: 400;
            //font-size: 0.75rem;
            width: 100%;
        }

        .quantity {
            float: right;
        }

        .notes {
            padding-left: 24px;
            width: 100%;
        }
    }

    .item-reference ::v-deep a {
        color: rgba(0, 0, 0, 0.87);
    }

    .action-detail-group {
        margin: 0;
        position: relative;
    }

    .profile-image {
        height: 50px;
        width: 50px;
        margin-top: -10px;
        position: absolute;
        left: 16px;
        margin-top: -16px;
        border-radius: 50%;
        box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.1), 0 0px 15px 0 rgba(0, 0, 0, 0.15) !important;
    }
}

.line-tooltip {
    width: 600px;

    .label {
        font-weight: bold;
    }
}

::v-deep {
    .status {
        color: #fff !important;
        min-width: 90px;
        max-width: 90px;
        display: block;
        min-height: 31px;
        text-align: center;
    }
}

.note-image {
    height: 100px;
    width: initial;
    margin-right: 10px;
}

.stopbar-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

a {
    &.link {
        color: #2b93ff;
    }
}

@media (min-width: 992px) {
    .detail-container {
        width: 40%;
    }
}

@media (min-width: 1200px) {
    .detail-container {
        width: 27.5%;
    }
}

.quantity-type {
    display: inline-block;
    text-transform: lowercase;
}

.quantity-type:first-letter {
    text-transform: capitalize;
}

.log-box {
    display: table;
    width: 100%;

    > div {
        display: table-cell;
    }

    > div:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 10px;
    }

    .log-box-title {
        text-decoration: underline;
        color: #2b93ff !important;
    }

    .log-box-title:hover {
        cursor: pointer;
    }
}

.view-more-btn a {
    color: #2b93ff !important;
}

.maintenance-row {
    span {
        font-weight: 500;
    }

    // border-bottom: 1px solid #eee;
    margin-bottom: 5px;
    // padding-bottom: 5px;
    .custom-badge {
        margin-right: 5px;
    }

    ::v-deep .reminder-indicators {
        margin-top: 5px;
        margin-bottom: 10px;

        .material-icons {
            color: #aaa;
        }
    }
}

.overdue-badge {
    background-color: red !important;
    color: #fff;
}

.duesoon-badge {
    background-color: orange !important;
    color: #fff;
}

.upcoming-badge {
    background-color: grey !important;
    color: #fff;
}
</style>
