var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "simple-wizard",
          {
            attrs: {
              plain: "",
              "show-header": false,
              title: _vm.title,
              "sub-title": _vm.subtitle,
            },
            on: { finish: _vm.validateDetails, stepChange: _vm.stepChange },
          },
          [
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": _vm.validateDetails,
                  id: "assetGroupDetails",
                },
              },
              [
                _c("template", { slot: "label" }, [
                  _vm._v(
                    "\n                    Group Details\n                "
                  ),
                ]),
                _vm.step === "assetGroupDetails"
                  ? _c(
                      "form-wrapper",
                      {
                        staticClass: "form-wrapper",
                        attrs: { validator: _vm.$v.assetGroupDetails },
                      },
                      [
                        _c("group-step", {
                          attrs: {
                            "asset-group-details": _vm.assetGroupDetails,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "wizard-tab",
              {
                attrs: {
                  "before-change": _vm.validateDetails,
                  id: "personnel",
                },
              },
              [
                _c("template", { slot: "label" }, [
                  _vm._v("\n                    Personnel\n                "),
                ]),
                _vm.step === "personnel"
                  ? _c(
                      "form-wrapper",
                      {
                        staticClass: "form-wrapper",
                        attrs: { validator: _vm.$v.personnel },
                      },
                      [
                        _c(
                          "div",
                          {
                            ref: "personnelContainer",
                            staticClass: "overflow-container",
                            class: _vm.showBorder ? "p-border" : "",
                          },
                          _vm._l(
                            _vm.$v.personnel.$each.$iter,
                            function (item, index) {
                              return _c("personnel-step", {
                                key: index,
                                attrs: {
                                  data: item.$model,
                                  index: Number(index),
                                  "dropdown-list": _vm.personnelDropdownList,
                                  "is-disabled": item.$model.isDisabled,
                                  "selected-asset": Number(
                                    item.$model.selectedPersonnel
                                  ),
                                  "selected-asset-list":
                                    _vm.selectedPersonnelList,
                                  "list-length": _vm.personnel.length,
                                },
                                on: {
                                  getExistingDetails:
                                    _vm.getExistingPersonnelDetails,
                                  removePersonnel: _vm.removePersonnel,
                                },
                              })
                            }
                          ),
                          1
                        ),
                        _c(
                          "md-button",
                          {
                            staticClass:
                              "md-icon-button md-dense md-raised md-primary pull-right",
                            on: { click: _vm.addPersonnel },
                          },
                          [_c("md-icon", [_vm._v("add")])],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "wizard-tab",
              {
                attrs: { "before-change": _vm.validateDetails, id: "vehicle" },
              },
              [
                _c("template", { slot: "label" }, [
                  _vm._v("\n                    Vehicle\n                "),
                ]),
                _vm.step === "vehicle"
                  ? _c(
                      "form-wrapper",
                      {
                        staticClass: "form-wrapper",
                        attrs: { validator: _vm.$v.vehicle },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "overflow-container" },
                          [
                            _c("vehicle-step", {
                              attrs: {
                                data: _vm.vehicle,
                                "dropdown-list": _vm.vehicleDropdownList,
                                "is-disabled": _vm.isVehicleDisabled,
                                "selected-asset": _vm.selectedVehicle,
                              },
                              on: {
                                getExistingDetails:
                                  _vm.getExistingVehicleDetails,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "wizard-tab",
              {
                attrs: { "before-change": _vm.validateDetails, id: "tracker" },
              },
              [
                _c("template", { slot: "label" }, [
                  _vm._v("\n                    Tracker\n                "),
                ]),
                _vm.step === "tracker"
                  ? _c(
                      "form-wrapper",
                      {
                        staticClass: "form-wrapper",
                        attrs: { validator: _vm.$v.tracker },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "overflow-container" },
                          [
                            _c("tracker-step", {
                              attrs: {
                                data: _vm.tracker,
                                "dropdown-list": _vm.trackerDropdownList,
                                "is-disabled": _vm.isTrackerDisabled,
                                "selected-asset": _vm.selectedTracker,
                              },
                              on: {
                                getExistingDetails:
                                  _vm.getExistingTrackerDetails,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }