<template>
    <div class="modal-container" v-if="!isLoading">
        <div class="modal-header">
            <h4 class="modal-title">Update Trip</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.tripDetails" class="form-wrapper">
                <div>
                    <google-autocomplete
                        label="Start Address"
                        ref="address"
                        id="startAddress"
                        classname="form-control autocomplete-input"
                        placeholder=""
                        :should-focus="false"
                        v-model="startAddress"
                        @placechanged="handleStartLocationChange"
                    ></google-autocomplete>
                </div>

                <div>
                    <google-autocomplete
                        label="End Address"
                        id="endAddress"
                        classname="form-control autocomplete-input"
                        placeholder=""
                        :should-focus="false"
                        v-model="endAddress"
                        @placechanged="handleEndLocationChange"
                    ></google-autocomplete>
                </div>

                <div class="date-picker-div">
                    <md-datepicker
                        ref="mdDatePicker"
                        v-model="tripDate"
                        md-immediately
                        :md-debounce="10"
                        :clearable="false"
                    >
                        <label>Trip Date</label>
                    </md-datepicker>
                    <span v-if="tripDate == null || !isTripDateValid" class="error">Trip date is invalid.</span>
                </div>

                <div class="time-picker-div">
                    <form-group name="startTime" label="Start Time" class="time-picker hide-clear-btn">
                        <time-picker
                            :time="startTime"
                            :all-time-options="false"
                            :is-required="true"
                            @selectedTime="getStartTime"
                        />
                    </form-group>
                    <div class="time-picker-error">
                        <span v-if="isFormTouched && !isStartTimeValid" class="error">Start time is required.</span>
                    </div>
                </div>

                <div class="time-picker-div last-tpd">
                    <form-group name="endTime" label="End Time" class="time-picker">
                        <time-picker :time="endTime" :all-time-options="false" @selectedTime="getEndTime" />
                    </form-group>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item">
                        <CustomerOptions
                            :selected="customerId"
                            :team-region-id="teamRegionId"
                            @selectedOption="handleCustomerChanged"
                        />
                    </div>
                    <div class="md-layout-item">
                        <charging-type-options
                            :selected-charge-type-id="rateGroupId"
                            @selectedOption="handleRateTypeChanged"
                        />
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click.stop="updateTrip">Update</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage, isEmptyOrSpaces } from '@/helpers';
import { TimePicker, GoogleAutocomplete, ChargingTypeOptions } from '@/components';
import { DATE_TYPES } from '@/utils/constants';
import moment from 'moment';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import CustomerOptions from '@/pages/Trips/Components/CustomerOptions';

export default {
    name: 'UpdateTripModal',
    components: { ChargingTypeOptions, CustomerOptions, TimePicker, GoogleAutocomplete },
    mixins: [GeneralMixin],
    props: {
        tripId: {
            type: Number,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            tripDetails: {
                startAddress: null,
                startTime: null
            },
            customerId: null,
            rateGroupId: null,
            startTime: null,
            endTime: null,
            startAddress: {},
            endAddress: {},
            isFormTouched: false,
            invalidStartAddress: false,
            isStartTimeValid: true,
            isEndTimeInvalid: true,
            tripDate: null,
            isTripDateValid: true,
            teamRegionId: null,
            isLoading: true,
            dateToday: moment().format(DATE_TYPES.internationalDate)
        };
    },
    async mounted() {
        await this.getTripDetails(this.tripId);
    },
    methods: {
        /**
         *
         * @param {import("@/jsDocTypes/RateRule").RateRule} rateRule
         */
        handleRateTypeChanged(rateRule) {
            this.rateGroupId = rateRule?.rateGroupId ?? null;
        },
        handleCustomerChanged(customerId) {
            this.customerId = !customerId ? 0 : customerId;
        },
        async getTripDetails(tripId) {
            const api = `/api/trips/${tripId}/details`;

            try {
                const {
                    data: { data }
                } = await handleRequests(api);
                this.isLoading = false;

                const { locationDetails, tripDate, startTime, endTime, teamRegionId, customerId, rateGroupId } = data;

                this.teamRegionid = teamRegionId;
                this.customerId = customerId;
                this.rateGroupId = rateGroupId;
                this.tripDate = moment(tripDate).format(DATE_TYPES.internationalDate);
                this.startTime = startTime;
                this.endTime = endTime;
                this.startAddress = {
                    address: locationDetails.startAddress,
                    location:
                        locationDetails.startLocation != null
                            ? locationDetails.startLocation
                            : { latitude: null, longitude: null }
                };

                this.endAddress = {
                    address: locationDetails.endAddress,
                    location:
                        locationDetails.endLocation != null
                            ? locationDetails.endLocation
                            : { latitude: null, longitude: null }
                };

                this.tripDetails = {
                    startAddress: locationDetails.startAddress,
                    endAddress: locationDetails.endAddress,
                    startLocation: locationDetails.startLocation,
                    endLocation: locationDetails.endLocation,
                    endTime,
                    startTime,
                    tripDate
                };
            } catch (e) {
                const message = 'Could not retrieve trip details. Please try again later.';
                showErrorMessage(this, message, e);
                this.resolve(false);
            }
        },
        async updateTrip() {
            this.isFormTouched = true;

            if (isEmptyOrSpaces(this.startAddress.address)) {
                return;
            }

            if (isEmptyOrSpaces(this.tripDetails.startTime) || !this.isStartTimeValid) {
                this.isStartTimeValid = false;
                return;
            }

            if (!this.isEndTimeInvalid) {
                return;
            }

            if (!this.isTripDateValid) {
                return;
            }

            if (isEmptyOrSpaces(this.endAddress.address)) {
                this.tripDetails.endAddress = null;
                this.tripDetails.endLocation = null;
            }

            this.tripDetails.tripDate = this.tripDate;

            const data = {
                ...this.tripDetails,
                customerId: this.customerId,
                rateGroupId: this.rateGroupId
            };

            this.$_handleLoaderState(true, 'UPDATING...');
            const api = `/api/trips/${this.tripId}/details`;
            const payload = {
                method: 'put',
                data
            };

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully updated the trip.',
                    type: 'success'
                });
                this.$v.$reset();
                this.resolve({ code: 'ok', newDate: this.tripDate });
            } catch (e) {
                const message = 'Could not update trip.';
                showErrorMessage(this, message, e);
                this.resolve('error');
            }
            this.$_handleLoaderState(false);
        },
        getStartTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;
                this.tripDetails.startTime = appointmentTime;
            } else {
                this.startTime = null;
            }
            this.isStartTimeValid = isTimeValid;
        },
        getEndTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;
                this.tripDetails.endTime = appointmentTime;
            } else {
                this.endTime = null;
            }
            this.isEndTimeInvalid = isTimeValid;
        },
        handleStartLocationChange(data) {
            this.tripDetails.startAddress = data.address;
            this.tripDetails.startLocation = data.location;
        },
        handleEndLocationChange(data) {
            this.tripDetails.endAddress = data.address;
            this.tripDetails.endLocation = data.location;
        }
    },
    validations: {
        tripDetails: {}
    }
};
</script>

<style lang="scss" scoped>
.hide-clear-btn ::v-deep .vs__clear {
    display: none;
}

.date-picker-div {
    display: inline-block;
    width: 150px;
    margin-right: 25px;

    ::v-deep .md-clear {
        display: none;
    }
}

.time-picker-div {
    display: inline-block;
    width: 125px;
    margin-right: 25px;
    vertical-align: top;

    .time-picker {
        > div {
            min-width: 135px;
        }
    }

    .time-picker-error {
        display: inline-block;
    }
}

.last-tpd {
    margin-right: 0;
}
</style>
