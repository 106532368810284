<template>
    <md-button
        title="Add Stop"
        :class="useIcon ? 'md-primary md-just-icon md-round' : 'md-primary header-button'"
        @click.stop="createRunStop"
    >
        <md-icon v-if="useIcon">add</md-icon>
        <span v-else>Add Stop</span>
    </md-button>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { RUN_STOP_DETAILS_DEFAULTS } from '@/utils/defaults';
import { mapGetters } from 'vuex';
import CreateRunStopModal from './CreateRunStopModal';

export default {
    name: 'CreateRunStop',
    mixins: [GeneralMixin],
    props: {
        useIcon: {
            type: Boolean,
            default: () => false
        },
        runScheduleId: {
            type: [String, Number],
            default: () => 0
        },
        selectedMemberId: {
            type: [String, Number],
            default: () => 0
        },
        verifyPendingTripExistsOnRunSchedule: {
            type: Function,
            default: () => {}
        },
        runScheduleCustomerId: {
            type: [String, Number],
            default: () => 0
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    data() {
        return {
            runStopDetails: Object.assign({}, RUN_STOP_DETAILS_DEFAULTS())
        };
    },
    methods: {
        createRunStop() {
            this.resetStopState();

            this.runStopDetails.durationMinutes =
                this.user.defaultStopDurationMinutes ?? this.runStopDetails.durationMinutes;
            this.runStopDetails.runScheduleId = this.runScheduleId;

            this.$modal
                .show(CreateRunStopModal, {
                    runStopDetails: this.runStopDetails,
                    verifyPendingTripExistsOnRunSchedule: this.verifyPendingTripExistsOnRunSchedule,
                    runScheduleCustomerId: this.runScheduleCustomerId
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.emitStopCreated();
                        this.$modal.hide();
                    }
                });
        },
        emitStopCreated() {
            this.resetStopState();
            this.$emit('runStopCreated');
        },
        resetStopState() {
            Object.assign(this.runStopDetails, RUN_STOP_DETAILS_DEFAULTS());
        }
    }
};
</script>
