var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-field",
    { staticClass: "member-role-filter-container" },
    [
      _vm.memberRoles
        ? _c("vue-select", {
            attrs: {
              options: _vm.memberRoles,
              clearable: false,
              placeholder: "Team Role",
              label: "name",
              "track-by": "id",
            },
            model: {
              value: _vm.selectedOption,
              callback: function ($$v) {
                _vm.selectedOption = $$v
              },
              expression: "selectedOption",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }