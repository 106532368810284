<template>
    <md-button
        title="Update asset details"
        :class="useIcon ? 'md-warning md-just-icon md-round' : `${className}`"
        @click.stop="handleUpdateAsset"
    >
        <md-icon v-if="useIcon">edit</md-icon>
        <span v-else>Edit</span>
    </md-button>
</template>

<script>
import UpdateAssetModal from '../ModalComponents/UpdateAssetModal';

export default {
    name: 'UpdateAssetButton',
    props: {
        asset: {
            type: Object,
            default: () => {}
        },
        customFields: {
            type: Array,
            default: () => []
        },
        className: {
            type: String,
            default: () => ''
        },
        useIcon: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        handleUpdateAsset() {
            const { type } = this.asset;
            this.$modal
                .show(UpdateAssetModal, { assetData: this.asset, customFields: this.customFields, assetType: type })
                .then((response) => {
                    this.$emit('onAssetUpdate', response);
                });
        }
    }
};
</script>
