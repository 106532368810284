/*
 *  Plugin for creatin modals
 *  returns a promise containing the data you want to parse
 *  can pass in a component to be rendered.
 *  passing a 'dialog' as the first argument creates a default dialog box with the title and text supplied
 *
 */

import { Modal, MessageBox } from '@/components';

// will be used as the container for the modal
const createModalContainer = () => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    return div;
};

const ModalPlugin = {
    install(Vue, options = { componentName: 'Modal' }) {
        if (this.installed) 
            return;

        this.installed = true;
        this.event = new Vue();
        this.rootInstance = null;
        const { componentName } = options;

        const showModal = (modal, params, events) => {
            return new Promise((resolve) => {
                const root = ModalPlugin.rootInstance;
                const container = createModalContainer();

                // eslint-disable-next-line no-underscore-dangle
                if (!root._dynamicContainer) {
                    new Vue({
                        parent: root,
                        name: 'ModalPlugin',
                        render: (h) => h(Modal)
                    }).$mount(container);
                }

                // eslint-disable-next-line no-underscore-dangle
                const containerComponent = root._dynamicContainer;
                containerComponent.set(modal, { ...params }, events, resolve);
            });
        };

        Vue.prototype.$modal = {
            showModal: false,
            isModalShown: false,
            get context() {
                return {
                    componentName,
                    options
                };
            },

            get shouldShowModal() {
                return this.showModal;
            },

            set shouldShowModal(val) {
                this.showModal = val;
            },
            get subscription() {
                return ModalPlugin.event;
            },

            /*
                arguments:
                    modal (component to be rendered. put MessageBox for a normal dialog)
                    params: data to be passed on the modal component as props
                    events: listeners for the modal component
                returns:
                    a promise that resolves to the data you want it to pass.
                    for a normal dialog, it resolves either true or false depending on the button clicked.
            */
            async show(modal, ...args) {
                this.isModalShown = true;
                const r = await showModal(modal, ...args);
                this.hide();
                return r;
            },

            hide() {
                this.isModalShown = false;
                this.shouldShowModal = false;
                ModalPlugin.event.$emit('toggle', false);
            }
        };

        Vue.prototype.$messageBox = {
            showMessageBox: false,
            isMessageBoxShown: false,
            get shouldShowMessageBox() {
                return this.showMessageBox;
            },
            set shouldShowMessageBox(val) {
                this.showMessageBox = val;
            },
            async show(...args) {
                this.isMessageBoxShown = true;
                const r = await showModal(MessageBox, ...args);
                this.hide();
                return r;
            },
            hide() {
                this.isMessageBoxShown = false;
                this.shouldShowModal = false;
                ModalPlugin.event.$emit('toggle', false);
            }
        };

        Vue.component('Modal', Modal);
        Vue.component('MessageBox', MessageBox);

        Vue.mixin({
            beforeMount() {
                if (ModalPlugin.rootInstance === null) {
                    ModalPlugin.rootInstance = this.$root;
                }
            }
        });
    }
};

export default ModalPlugin;
