var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "toggled-options map-panel",
        class: { individual: _vm.isIndividual, pinned: _vm.pinnedUser },
        style: { "--bottom-placement": _vm.getButtonPlacement },
      },
      [
        _c("div", { staticStyle: { position: "relative", display: "flex" } }, [
          _c(
            "button",
            {
              class: {
                active: _vm.isPlannedRouteToggled,
                disabled: _vm.isAssetGroup,
              },
              on: {
                click: function ($event) {
                  return _vm.toggle("planned_route", _vm.isAssetGroup)
                },
              },
            },
            [
              _c("md-icon", [
                _vm._v("\n                    alt_route\n                "),
              ]),
              _c(
                "md-tooltip",
                {
                  staticClass: "toggle-tooltip",
                  attrs: { "md-direction": "top" },
                },
                [_vm._v("Planned route")]
              ),
            ],
            1
          ),
          _c(
            "button",
            {
              class: {
                active: _vm.isLocationHistoryToggled,
                disabled: _vm.isFutureDate.value,
              },
              on: {
                click: function ($event) {
                  return _vm.toggle("location_history", _vm.isFutureDate.value)
                },
              },
            },
            [
              _c("md-icon", [
                _vm._v("\n                    history\n                "),
              ]),
              _c(
                "md-tooltip",
                {
                  staticClass: "toggle-tooltip",
                  attrs: { "md-direction": "top" },
                },
                [_vm._v("Location history")]
              ),
            ],
            1
          ),
          _c(
            "button",
            {
              class: {
                active: _vm.isHeartbeatsToggled,
                disabled: _vm.isFutureDate.value,
              },
              on: {
                click: function ($event) {
                  return _vm.toggle("heartbeats", _vm.isFutureDate.value)
                },
              },
            },
            [
              _c("md-icon", [
                _vm._v("\n                    favorite\n                "),
              ]),
              _c(
                "md-tooltip",
                {
                  staticClass: "toggle-tooltip",
                  attrs: { "md-direction": "top" },
                },
                [_vm._v("Heartbeats")]
              ),
            ],
            1
          ),
          _c(
            "button",
            {
              class: {
                active: _vm.isStopsListToggled,
                disabled: _vm.isAssetGroup,
              },
              on: {
                click: function ($event) {
                  return _vm.toggle(
                    "stops_list",
                    _vm.isFutureDate.value || _vm.isAssetGroup
                  )
                },
              },
            },
            [
              _c("md-icon", [
                _vm._v("\n                    list\n                "),
              ]),
              _c(
                "md-tooltip",
                {
                  staticClass: "toggle-tooltip",
                  attrs: { "md-direction": "top" },
                },
                [_vm._v("Stops list")]
              ),
            ],
            1
          ),
          _vm.geofenceConfiguration === "TEST"
            ? _c(
                "button",
                {
                  class: {
                    active: _vm.isStopStatusToggled,
                    disabled: _vm.isFutureDate.value || _vm.isAssetGroup,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toggle(
                        "stop_status",
                        _vm.isFutureDate.value || _vm.isAssetGroup
                      )
                    },
                  },
                },
                [
                  _c("md-icon", [
                    _vm._v(
                      "\n                    location_on\n                "
                    ),
                  ]),
                  _c(
                    "md-tooltip",
                    {
                      staticClass: "toggle-tooltip",
                      attrs: { "md-direction": "top" },
                    },
                    [_vm._v("Stop status")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.geofenceConfiguration === "TEST"
            ? _c(
                "button",
                {
                  class: {
                    active: _vm.isGeofenceToggled,
                    disabled: _vm.isFutureDate.value || _vm.isAssetGroup,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toggle(
                        "geofence",
                        _vm.isFutureDate.value || _vm.isAssetGroup
                      )
                    },
                  },
                },
                [
                  _c("md-icon", [
                    _vm._v(
                      "\n                    share_location\n                "
                    ),
                  ]),
                  _c(
                    "md-tooltip",
                    {
                      staticClass: "toggle-tooltip",
                      attrs: { "md-direction": "top" },
                    },
                    [_vm._v("Geofence Events")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }