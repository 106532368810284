var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "modal-body md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-75" },
        [
          _c(
            "form-group",
            {
              attrs: {
                name: "radiusValue",
                label: "Maximum Valid Distance From Pickup Location",
              },
            },
            [
              _c("md-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.radiusValue,
                  callback: function ($$v) {
                    _vm.radiusValue = $$v
                  },
                  expression: "radiusValue",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-25" },
        [
          _c(
            "form-group",
            [
              _c(
                "md-select",
                {
                  model: {
                    value: _vm.radiusUnits,
                    callback: function ($$v) {
                      _vm.radiusUnits = $$v
                    },
                    expression: "radiusUnits",
                  },
                },
                [
                  _c("md-option", { attrs: { value: "KM" } }, [_vm._v("km")]),
                  _c("md-option", { attrs: { value: "MILES" } }, [
                    _vm._v("miles"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.saveDistanceSetting },
          },
          [_vm._v("\n            Save\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Distance Setting")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }