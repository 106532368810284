var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cf-container" },
    [
      _c(
        "div",
        { staticClass: "cf-action-container" },
        [
          _c(
            "md-button",
            {
              staticClass: "md-primary md-just-icon md-round",
              attrs: { title: "Add asset custom fields" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleAddAssetCustomField()
                },
              },
            },
            [_c("md-icon", [_vm._v("add")])],
            1
          ),
        ],
        1
      ),
      _c(
        "form-group",
        {
          staticClass: "asset-type-filter",
          attrs: { label: "Select asset type" },
        },
        [
          _c(
            "md-select",
            {
              on: { "md-selected": _vm.getSelectedAssetCutomFields },
              model: {
                value: _vm.selectedAssetType,
                callback: function ($$v) {
                  _vm.selectedAssetType = $$v
                },
                expression: "selectedAssetType",
              },
            },
            _vm._l(_vm.assetTypes, function (assetType, index) {
              return _c(
                "md-option",
                { key: index, attrs: { value: assetType } },
                [
                  _vm._v(
                    "\n                " + _vm._s(assetType) + "\n            "
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "md-table",
        { staticClass: "custom-paginated-table" },
        [
          _c(
            "md-table-row",
            [
              _c("md-table-head", [_vm._v("Id")]),
              _c("md-table-head", [_vm._v("Label")]),
              _c("md-table-head", [_vm._v("Type")]),
              _c("md-table-head", [_vm._v("Asset Type")]),
              _c("md-table-head", { staticClass: "actions" }, [
                _vm._v("Actions"),
              ]),
            ],
            1
          ),
          _vm._l(
            _vm.assetCustomFieldFilteredList,
            function (customField, index) {
              return _c(
                "md-table-row",
                { key: index },
                [
                  _c("md-table-cell", [
                    _vm._v(
                      "\n                " +
                        _vm._s(customField.name) +
                        "\n            "
                    ),
                  ]),
                  _c("md-table-cell", [
                    _vm._v(
                      "\n                " +
                        _vm._s(customField.label) +
                        "\n            "
                    ),
                  ]),
                  _c("md-table-cell", [
                    _vm._v(
                      "\n                " +
                        _vm._s(customField.type) +
                        "\n            "
                    ),
                  ]),
                  _c("md-table-cell", [
                    _vm._v(
                      "\n                " +
                        _vm._s(customField.assetType) +
                        "\n            "
                    ),
                  ]),
                  _c(
                    "md-table-cell",
                    { staticClass: "action-buttons" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-warning md-just-icon md-round",
                          attrs: { title: "edit custom field" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleEditAssetCustomField(customField)
                            },
                          },
                        },
                        [_c("md-icon", [_vm._v("edit")])],
                        1
                      ),
                      _c(
                        "md-button",
                        {
                          staticClass: "md-danger md-just-icon md-round",
                          attrs: { title: "delete custom field" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.removeAssetCustomField(
                                customField,
                                index
                              )
                            },
                          },
                        },
                        [_c("md-icon", [_vm._v("delete")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }
          ),
        ],
        2
      ),
      _vm.assetCustomFieldFilteredList.length == 0
        ? _c("div", { staticClass: "text-center" }, [
            _c("p", { staticClass: "no-result-message" }, [
              _vm._v("No results matching your search/filter could be found."),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }