var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-spacing" },
    [
      _c(
        "sidebar-item",
        {
          attrs: {
            link: { name: _vm.$t("menus.support.support"), icon: "live_help" },
          },
        },
        [
          _c("sidebar-item", {
            staticClass: "hide",
            attrs: { link: { active: false } },
          }),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "nav-link first-support-link",
                attrs: { href: "https://help.locate2u.com", target: "_blank" },
              },
              [
                _c("span", { staticClass: "sidebar-mini" }, [
                  _vm._v(_vm._s(_vm.$t("menus.support.knowledgeBase-mini"))),
                ]),
                _c("span", { staticClass: "sidebar-normal" }, [
                  _vm._v(_vm._s(_vm.$t("menus.support.knowledgeBase"))),
                ]),
              ]
            ),
          ]),
          _vm.$root.isDesktop
            ? _c("li", [
                _c(
                  "a",
                  { staticClass: "nav-link", on: { click: _vm.chatSupport } },
                  [
                    _c("span", { staticClass: "sidebar-mini" }, [
                      _vm._v(_vm._s(_vm.$t("menus.support.chatSupport-mini"))),
                    ]),
                    _c("span", { staticClass: "sidebar-normal" }, [
                      _vm._v(_vm._s(_vm.$t("menus.support.chatSupport"))),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.user.role === "Team Owner" || _vm.user.role === null
            ? _c("sidebar-item", {
                attrs: {
                  link: {
                    name: _vm.$t("menus.support.gettingStarted"),
                    path: "/support/onboarding",
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }