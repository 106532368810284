var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "md-layout" }, [
        _c("div", { staticClass: "md-layout-item" }, [
          _c("div", { staticClass: "custom-toolbar" }, [
            _c(
              "div",
              { staticClass: "custom-toolbar-start" },
              [
                _c("filter-component", {
                  class: _vm.$root.isTablet ? "tablet-filter-container" : "",
                  attrs: {
                    "team-members": function () {
                      return this$1.teamMembers
                    },
                    "time-window-filter-options": _vm.timeWindowFilterOptions,
                    "team-region-id": _vm.filters.teamRegionId,
                    "filter-value": _vm.filters,
                  },
                  on: { onFilterStops: _vm.handleFilterOrSearch },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "custom-toolbar-end" },
              [
                _vm.$root.isDesktop
                  ? _c("search-component", {
                      attrs: { "search-filter": _vm.currentSearchTextValue },
                      on: { onSearch: _vm.handleFilterOrSearch },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _c(
                      "md-button",
                      {
                        staticClass:
                          "md-primary md-just-icon md-round pull-right header-button",
                        attrs: { title: "Refresh List" },
                        on: { click: _vm.handleRefresh },
                      },
                      [_c("md-icon", [_vm._v("refresh")])],
                      1
                    ),
                  ],
                  1
                ),
                !_vm.isCustomer && !_vm.isSupportStaff
                  ? _c("create-stop", {
                      staticClass: "tablet-button-margin",
                      attrs: { "team-members": _vm.teamMembers },
                      on: { stopCreated: _vm.handleStopCreated },
                    })
                  : _vm._e(),
                !_vm.isReadOnlyUser
                  ? _c("batch-upload", {
                      attrs: {
                        title: "Import Stops",
                        "template-type": "stops",
                        "team-members": _vm.teamMembers,
                      },
                      on: { batchImported: _vm.handleStopCreated },
                    })
                  : _vm._e(),
                !_vm.isReadOnlyUser
                  ? _c(
                      "div",
                      [
                        _c(
                          "md-button",
                          {
                            staticClass:
                              "md-primary md-just-icon md-round pull-right header-button",
                            attrs: { title: "Export Stops" },
                            on: { click: _vm.exportStops },
                          },
                          [_c("md-icon", [_vm._v("get_app")])],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _vm.bulkListError.length > 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "alert alert-danger alert-dismissible fade show bulk-error-message",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "close",
                        attrs: {
                          type: "button",
                          "data-dismiss": "alert",
                          "aria-label": "Close",
                        },
                        on: { click: _vm.closeAlert },
                      },
                      [
                        _c("span", { attrs: { "aria-hidden": "true" } }, [
                          _vm._v("×"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      _vm._l(_vm.bulkListError, function (e) {
                        return _c(
                          "div",
                          {
                            key: e.stopRef,
                            staticClass: "bulk-single-container",
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(e.stopRef) +
                                  "\n                        "
                              ),
                            ]),
                            _c(
                              "div",
                              _vm._l(e.errors, function (error, i) {
                                return _c("div", { key: i }, [
                                  _vm._v(_vm._s(error.message)),
                                ])
                              }),
                              0
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
            class: _vm.total < _vm.pagination.perPage ? "margin-space-bot" : "",
          },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-green" },
                  [
                    _c("CardHeaderDropdown", {
                      attrs: {
                        "dropdown-options": _vm.headerDropdown,
                        "selected-option": _vm.headerDropdown[0],
                      },
                      on: { selectedOption: _vm.handleSelectedView },
                    }),
                  ],
                  1
                ),
                _c("md-card-content", { staticClass: "body-list" }, [
                  _vm.selectedStops.length
                    ? _c(
                        "div",
                        {
                          class: [
                            "bulk-section",
                            _vm.topScrollPosition > 150 ? "sticky" : "",
                          ],
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.selectedStops.length) +
                                " stop" +
                                _vm._s(
                                  _vm.selectedStops.length > 1 ? "s" : ""
                                ) +
                                " selected.\n                        "
                            ),
                          ]),
                          _c(
                            "div",
                            [
                              _c(
                                "drop-down",
                                {
                                  attrs: {
                                    "should-close": _vm.shouldCloseDropdown,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "status md-warning",
                                      attrs: {
                                        slot: "title",
                                        "data-toggle": "dropdown",
                                      },
                                      slot: "title",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Change Status\n                                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "ul",
                                    { staticClass: "dropdown-menu" },
                                    _vm._l(
                                      _vm.statuses,
                                      function (item, index) {
                                        return _c("li", { key: index }, [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handleChangeStopStatus(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(item) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                              !_vm.isSingleTeamMember &&
                              !_vm.isSingleTeamMember &&
                              !_vm.isCustomer &&
                              !_vm.isSupportStaff
                                ? _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-round md-just-icon md-info",
                                      attrs: { title: "Re-assign driver" },
                                      on: { click: _vm.bulkAssign },
                                    },
                                    [_c("md-icon", [_vm._v("person_pin")])],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-round md-just-icon md-danger",
                                  attrs: { title: "Delete Stops" },
                                  on: { click: _vm.bulkDelete },
                                },
                                [_c("md-icon", [_vm._v("delete")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isListLoading
                    ? _c(
                        "div",
                        [
                          _c(
                            "md-table",
                            { staticClass: "context-menu-support" },
                            [
                              _c(
                                "md-table-row",
                                [
                                  !_vm.isCustomer && !_vm.isSupportStaff
                                    ? _c(
                                        "md-table-head",
                                        [
                                          _c("md-checkbox", {
                                            staticClass:
                                              "stop-checkbox checkbox-head",
                                            on: {
                                              change: function ($event) {
                                                return _vm.onSelectAll(
                                                  _vm.isSelectAll
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.isSelectAll,
                                              callback: function ($$v) {
                                                _vm.isSelectAll = $$v
                                              },
                                              expression: "isSelectAll",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("TableHeaderDropdown", {
                                    attrs: {
                                      "column-icon": "keyboard_arrow_down",
                                      "default-text":
                                        _vm.tableColumnOptions.columns[
                                          _vm.columnKeys.referenceSource
                                        ].name,
                                      "dropdown-options":
                                        _vm.tableColumnKeyOptions
                                          .referenceSource,
                                      "selected-option":
                                        _vm.tableColumnOptions.columns[
                                          _vm.columnKeys.referenceSource
                                        ],
                                    },
                                    on: {
                                      selectedOption: function ($event) {
                                        return _vm.$_handleColumnSwitch(
                                          $event,
                                          _vm.columnKeys.referenceSource,
                                          "stops.defaults.stop-table-options"
                                        )
                                      },
                                    },
                                  }),
                                  _c("md-table-head", [_vm._v("Assigned To")]),
                                  _vm.filters.status === "Complete" ||
                                  _vm.filters.status === "All"
                                    ? _c("md-table-head", [
                                        _vm._v(
                                          "\n                                    Rating\n                                "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.$root.isDesktop
                                    ? _c("md-table-head", [_vm._v("Type")])
                                    : _vm._e(),
                                  _c("md-table-head", [_vm._v("Status")]),
                                  _c("md-table-head", [_vm._v("When")]),
                                  _vm.$root.isDesktop
                                    ? _c("md-table-head", [_vm._v("Contact")])
                                    : _vm._e(),
                                  _c(
                                    "md-table-head",
                                    { staticClass: "address-container" },
                                    [_vm._v("Address")]
                                  ),
                                  _vm.$root.isDesktop &&
                                  !_vm.isCustomer &&
                                  !_vm.isSupportStaff
                                    ? _c("md-table-head", [
                                        _vm._v(
                                          "\n                                    Actions\n                                "
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._l(_vm.stopsList, function (item) {
                                return _c(
                                  "md-table-row",
                                  {
                                    key: item.stopId,
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.showStopDetailsSidebar(item)
                                      },
                                    },
                                  },
                                  [
                                    !_vm.isCustomer && !_vm.isSupportStaff
                                      ? _c(
                                          "md-table-cell",
                                          [
                                            _c("md-checkbox", {
                                              staticClass: "stop-checkbox",
                                              attrs: { value: item.stopId },
                                              model: {
                                                value: _vm.selectedStops,
                                                callback: function ($$v) {
                                                  _vm.selectedStops = $$v
                                                },
                                                expression: "selectedStops",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "stop-reference" },
                                      [
                                        item.importErrors &&
                                        item.importErrors.length > 0
                                          ? _c(
                                              "md-tooltip",
                                              {
                                                attrs: {
                                                  "md-direction": "top",
                                                },
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  _vm._l(
                                                    item.importErrors,
                                                    function (
                                                      importError,
                                                      importErrorIndex
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: importErrorIndex,
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                importError
                                                              ) +
                                                              "\n                                            "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        item.importErrors &&
                                        item.importErrors.length > 0
                                          ? _c(
                                              "md-icon",
                                              { staticClass: "icon-warning" },
                                              [
                                                _vm._v(
                                                  "\n                                        warning\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        item.shipmentId != null
                                          ? _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "Shipment Details",
                                                    params: {
                                                      shipmentId:
                                                        item.shipmentId,
                                                    },
                                                  },
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c("md-icon", [
                                                  _vm._v("directions_car"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Stop Details",
                                                params: { stopId: item.stopId },
                                              },
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.tableColumnOptions
                                                    .columns[
                                                    _vm.columnKeys
                                                      .referenceSource
                                                  ].key ===
                                                    "reference-number" ||
                                                    item.sourceReference ===
                                                      null ||
                                                    item.sourceReference === ""
                                                    ? item.stopRef
                                                    : item.sourceReference
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-table-cell",
                                      [
                                        item.assignedTo.publicUserId ||
                                        item.carrierTeamId
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "assigned-column",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c("reassign-stop-button", {
                                                      attrs: {
                                                        type: "text",
                                                        item: item,
                                                        "team-members":
                                                          _vm.teamMembers,
                                                      },
                                                      on: {
                                                        assignedUser:
                                                          _vm.onAssignedUser,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _c("reassign-stop-button", {
                                              attrs: {
                                                type: "button",
                                                item: item,
                                                "team-members": _vm.teamMembers,
                                              },
                                              on: {
                                                assignedUser:
                                                  _vm.onAssignedUser,
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _vm.filters.status === "Complete" ||
                                    _vm.filters.status === "All"
                                      ? _c(
                                          "md-table-cell",
                                          { staticClass: "rating-container" },
                                          [
                                            item.rating
                                              ? _c("div", [
                                                  _vm.$root.isDesktop
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ratings",
                                                        },
                                                        _vm._l(
                                                          5,
                                                          function (index) {
                                                            return _c(
                                                              "md-icon",
                                                              {
                                                                key: index,
                                                                style: {
                                                                  color:
                                                                    index <=
                                                                    item.rating
                                                                      ? "orange"
                                                                      : "rgba(0,0,0,0.54)",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                star_rate\n                                            "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ratings",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.rating
                                                              )
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            "\n                                             \n                                            "
                                                          ),
                                                          _c("md-icon", [
                                                            _vm._v("star_rate"),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.$root.isDesktop
                                      ? _c("md-table-cell", [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item.type) +
                                              "\n                                "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "status-container" },
                                      [
                                        _c("stop-status-button", {
                                          attrs: { stop: item },
                                          on: {
                                            statusUpdated:
                                              _vm.onChangedStopStatus,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "trip-date" },
                                      [
                                        item.tripId &&
                                        item.carrierTeamid == null
                                          ? _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "Trip Details",
                                                    params: {
                                                      tripId: item.tripId,
                                                    },
                                                  },
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm._f("dateFormat")(
                                                        item.tripDate,
                                                        _vm.DATE_TYPES
                                                          .standardDate
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          : _c("div", [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm._f("dateFormat")(
                                                      item.tripDate,
                                                      _vm.DATE_TYPES
                                                        .standardDate
                                                    )
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]),
                                      ],
                                      1
                                    ),
                                    _vm.$root.isDesktop
                                      ? _c("md-table-cell", [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item.contact.name) +
                                              "\n                                "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "md-table-cell",
                                      { staticClass: "address-container" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "custom-ellipsis" },
                                          [
                                            _c("GeocodingIndicator", {
                                              attrs: {
                                                "address-components":
                                                  item.addressComponents,
                                              },
                                            }),
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$_displayAddressName(item)
                                                ) +
                                                "\n                                    "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm.$root.isDesktop
                                      ? _c(
                                          "md-table-cell",
                                          { staticClass: "action-buttons" },
                                          [
                                            !_vm.isCustomer &&
                                            !_vm.isSupportStaff
                                              ? _c("update-stop-button", {
                                                  attrs: {
                                                    stop: item.stopId,
                                                    "team-members":
                                                      _vm.teamMembers,
                                                    "is-failed":
                                                      item.status.toLowerCase() ===
                                                      "failed",
                                                    "carrier-team-id":
                                                      item.carrierTeamId,
                                                  },
                                                  on: {
                                                    stopUpdated:
                                                      _vm.handleUpdateStop,
                                                  },
                                                })
                                              : _vm._e(),
                                            !_vm.isSingleUser &&
                                            !_vm.isSingleTeamMember &&
                                            !_vm.isCustomer &&
                                            !_vm.isSupportStaff
                                              ? _c("reassign-stop-button", {
                                                  attrs: {
                                                    type: "icon",
                                                    item: item,
                                                    "team-members":
                                                      _vm.teamMembers,
                                                  },
                                                  on: {
                                                    assignedUser:
                                                      _vm.onAssignedUser,
                                                  },
                                                })
                                              : _vm._e(),
                                            !_vm.isReadOnlyUser
                                              ? _c("delete-stop-button", {
                                                  attrs: {
                                                    "stop-id": item.stopId,
                                                    "team-members":
                                                      _vm.teamMembers,
                                                    "is-shipment-stop":
                                                      item.shipmentId != null,
                                                  },
                                                  on: {
                                                    stopDeleted:
                                                      _vm.handleDeleteStop,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                          _vm.stopsList.length == 0 && !_vm.isLoading
                            ? _c("div", [
                                _c("p", { staticClass: "no-result-message" }, [
                                  _vm._v(
                                    "No results matching your search/filter could be found."
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("div", [
                        _c(
                          "div",
                          { staticClass: "stopbar-loader" },
                          [
                            _c("fade-loader", {
                              attrs: { loading: _vm.loading, color: "#333333" },
                            }),
                            _c("span", [_vm._v("LOADING")]),
                          ],
                          1
                        ),
                      ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "md-card-actions",
        {
          staticClass: "page-footer",
          attrs: { "md-alignment": "space-between" },
        },
        [
          _c("div", [
            _vm.total === _vm.pagination.perPage
              ? _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "\n                Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of many\n            "
                  ),
                ])
              : _c("p", { staticClass: "card-category" }, [
                  _vm._v(
                    "Page " +
                      _vm._s(_vm.pagination.currentPage) +
                      " of " +
                      _vm._s(_vm.totalPages)
                  ),
                ]),
          ]),
          _c("pagination", {
            staticClass: "pagination-no-border pagination-success",
            attrs: {
              "per-page": _vm.pagination.perPage,
              total: _vm.total,
              "enable-first-page-link": true,
              "enable-last-page-link": true,
            },
            on: {
              "change-page": function ($event) {
                return _vm.handleChangePage($event, _vm.pagination.perPage)
              },
            },
            model: {
              value: _vm.pagination.currentPage,
              callback: function ($$v) {
                _vm.$set(_vm.pagination, "currentPage", $$v)
              },
              expression: "pagination.currentPage",
            },
          }),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.showStopDetails
            ? _c("stop-sidebar", {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.toggleStopDetailsWindow,
                    expression: "toggleStopDetailsWindow",
                  },
                ],
                attrs: {
                  "stop-id": _vm.stopId,
                  data: _vm.stopDetails,
                  "team-members": _vm.teamMembers,
                },
                on: {
                  assignStop: _vm.onAssignedUser,
                  deleteStop: _vm.handleDeleteStop,
                  updateStop: _vm.handleUpdateStop,
                  changeStopStatus: _vm.onChangedStopStatus,
                  showAddNotes: _vm.handleAddStopNotes,
                  closeModal: _vm.toggleStopDetailsWindow,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }