<script>
import { ref, toRefs, computed, inject, watch } from '@vue/composition-api';
import useMapUtils from '@/compostables/useMapUtils';

export default {
    name: 'RoutePolyline',
    props: {
        pinnedDriverDetails: {
            type: Object,
            default: () => {}
        },
        isDateToday: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
        const { getters } = inject('vuex-store');
        const { pinnedDriverDetails, isDateToday } = toRefs(props);
        const { createPath, createRouteLine } = useMapUtils(context);
        const google = inject('google');
        const map = inject('map');
        const route = ref(null);
        const pinnedRoute = computed(() => getters['map/pinnedRoute']);
        const isPlannedRouteToggled = computed(() => getters['map/isPlannedRouteToggled']);

        watch(pinnedRoute, (newVal) => {
            if (route?.value) {
                route.value.setMap(null);
                route.value = null;
            }
            if (newVal && pinnedDriverDetails.value) {
                const {
                    markerPins: { color }
                } = pinnedDriverDetails.value;
                const options = {
                    strokeColor: color,
                    strokeOpacity: 1,
                    zIndex: 3
                };

                const path = createPath(google.value, newVal);
                route.value = createRouteLine(google.value, path, color, options, map.value);
                if (!isPlannedRouteToggled.value) 
                    route.value.setMap(null);
            }
        });

        watch(isPlannedRouteToggled, (newVal) => {
            if (route?.value) 
                route.value.setMap(newVal ? map.value : null);
        });

        watch(isDateToday, (newVal) => {
            if (route?.value) 
                route.value.setMap(newVal ? map.value : null);
        });

        return {
            route
        };
    },
    render: () => null
};
</script>
