var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.carrierName) + " - Api Error"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c("p", [
          _c("b", [
            _vm._v(
              "\n                " +
                _vm._s(_vm.error.message) +
                "\n            "
            ),
          ]),
        ]),
        _vm.parsedErrors.length
          ? [
              _c("md-table", {
                staticClass: "table-hover",
                attrs: { value: _vm.parsedErrors },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "md-table-row",
                      fn: function (ref) {
                        var item = ref.item
                        return _c(
                          "md-table-row",
                          {},
                          [
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Code" } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.code) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Description" } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.description) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ],
                  null,
                  false,
                  2538226368
                ),
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }