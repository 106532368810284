var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam
    ? _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Settings"),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("   >    " + _vm._s(_vm.$t("menus.setting.skills"))),
                  ]),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-blue" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("psychology")])],
                      1
                    ),
                  ]
                ),
                _c("md-card-content", [
                  _c(
                    "div",
                    { staticClass: "cf-container" },
                    [
                      _c(
                        "div",
                        { staticClass: "cf-action-container" },
                        [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-primary md-just-icon md-round",
                              attrs: { title: "Add skill" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleAddSkill()
                                },
                              },
                            },
                            [_c("md-icon", [_vm._v("add")])],
                            1
                          ),
                          _c(
                            "md-button",
                            {
                              staticClass: "md-primary md-just-icon md-round",
                              attrs: { title: "Import skill" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleImportSkills()
                                },
                              },
                            },
                            [_c("md-icon", [_vm._v("import_export")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "md-table",
                        { staticClass: "custom-paginated-table" },
                        [
                          _c(
                            "md-table-row",
                            [
                              _c("md-table-head", [_vm._v("Name")]),
                              _c("md-table-head", [_vm._v("Actions")]),
                            ],
                            1
                          ),
                          _vm._l(_vm.skillList, function (skill, index) {
                            return _c(
                              "md-table-row",
                              { key: index },
                              [
                                _c("md-table-cell", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(skill.name) +
                                      "\n                            "
                                  ),
                                ]),
                                _c(
                                  "md-table-cell",
                                  { staticClass: "action-buttons" },
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-danger md-just-icon md-round",
                                        attrs: { title: "delete skill" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.deleteSkill(skill.id)
                                          },
                                        },
                                      },
                                      [_c("md-icon", [_vm._v("delete")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                      _vm.skillList.length == 0
                        ? _c("div", { staticClass: "text-center" }, [
                            _c("p", { staticClass: "no-result-message" }, [
                              _vm._v(
                                "No results matching your search/filter could be found."
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.shouldShowAddSkillModal
                        ? _c("skill-modal", {
                            attrs: { "team-id": _vm.teamId },
                            on: { emitToggleModal: _vm.toggleModal },
                          })
                        : _vm._e(),
                      _vm.shouldShowImportSkillsModal
                        ? _c("skill-import-modal", {
                            attrs: { "team-id": _vm.teamId },
                            on: { emitToggleModal: _vm.toggleModal },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }