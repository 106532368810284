var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-select", {
        attrs: {
          label: "name",
          options: _vm.teamRegionOptions,
          placeholder: "Team Region",
          disabled: !_vm.enabled,
          clearable: _vm.clearable,
        },
        scopedSlots: _vm._u([
          {
            key: "selected-option",
            fn: function (option) {
              return [
                _c("span", { staticStyle: { "margin-left": "2px" } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(option.name) +
                      "\n            "
                  ),
                ]),
              ]
            },
          },
          {
            key: "option",
            fn: function (option) {
              return [
                _c(
                  "span",
                  {
                    class: _vm.isSubRegion
                      ? "option-level-" + option.level
                      : "",
                    staticStyle: { "margin-left": "2px" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(option.name) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.teamRegionFilterValue,
          callback: function ($$v) {
            _vm.teamRegionFilterValue = $$v
          },
          expression: "teamRegionFilterValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }