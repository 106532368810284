<template>
    <div class="inline-button">
        <md-button
            v-if="!isReadOnlyUser"
            title="Reschedule upcoming maintenance"
            :class="useIcon ? 'md-warning md-just-icon md-round' : `${className}`"
            @click.stop="handleCompleteSchedule"
        >
            <md-icon v-if="useIcon">update</md-icon>
            <span v-else>Reschedule</span>
        </md-button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UpdateUpcomingMaintenanceScheduleModal from '@/pages/Maintenance/modals/UpdateUpcomingMaintenanceScheduleModal';

export default {
    name: 'UpdateUpcomingMaintenanceScheduleButton',
    props: {
        className: {
            type: String,
            default: () => ''
        },
        useIcon: {
            type: Boolean,
            default: () => true
        },
        maintenanceData: {
            type: Object,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    methods: {
        handleCompleteSchedule() {
            this.$modal
                .show(UpdateUpcomingMaintenanceScheduleModal, {
                    maintenanceScheduleData: this.maintenanceData
                })
                .then((response) => {
                    this.$emit('maintenanceCompleted', response);
                    this.$modal.hide();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.inline-button {
    display: inline-block;
}
</style>
