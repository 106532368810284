var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-calendar map-panel" },
    [
      _c("md-datepicker", {
        attrs: { "md-immediately": "" },
        model: {
          value: _vm.tripDate,
          callback: function ($$v) {
            _vm.tripDate = $$v
          },
          expression: "tripDate",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }