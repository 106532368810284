var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "slider",
    class: ["slider-" + _vm.type],
    attrs: { disabled: _vm.disabled },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }