<template>
    <div class="md-layout">
        <div class="md-layout-item">
            <div class="custom-toolbar md-layout">
                <div class="custom-toolbar-start">
                    <div class="filter-steps--container">
                        <team-region-member-filter-options
                            v-if="canViewTeamRegion"
                            :team-region-id="teamRegionId"
                            @changed="handleTeamRegionMemberChanged"
                            :strict-team-region-filter="false"
                            :use-elevated-filter-view="true"
                            :clearable="false"
                        />

                        <md-field class="status-filter">
                            <vue-select
                                v-if="statuses"
                                :options="statuses"
                                label="statusValue"
                                :reduce="(stat) => stat.statusId"
                                placeholder="Status"
                                v-model="statusFilterValue"
                                :searchable="$root.isDesktop"
                                :clearable="false"
                            ></vue-select>
                        </md-field>

                        <md-field>
                            <md-datepicker
                                class="filter-steps--choices filter-step-date start-date"
                                v-model="InvoiceDateFrom"
                                md-immediately
                                :md-debounce="100"
                                ref="mdDatePicker"
                            ></md-datepicker>
                        </md-field>

                        <md-field>
                            <md-datepicker
                                class="filter-steps--choices filter-step-date end-date"
                                v-model="InvoiceDateTo"
                                md-immediately
                                :md-debounce="100"
                                ref="mdDatePicker"
                            ></md-datepicker>
                        </md-field>

                        <md-field class="status-filter">
                            <vue-select
                                v-if="syncStatuses != null && syncStatuses.length > 0"
                                :options="syncStatuses"
                                label="label"
                                placeholder="Sync Status"
                                v-model="syncStatusValue"
                                :searchable="$root.isDesktop"
                                :clearable="false"
                            ></vue-select>
                        </md-field>

                        <md-field class="status-filter batch-filter">
                            <vue-select
                                v-if="batchFilterList != null && batchFilterList.length > 0"
                                :options="batchFilterList"
                                label="label"
                                :reduce="(x) => x.id"
                                placeholder="Batch Filter"
                                v-model="batchFilterValue"
                                :searchable="$root.isDesktop"
                                :clearable="true"
                            ></vue-select>
                        </md-field>
                    </div>
                </div>
                <div class="custom-toolbar-end">
                    <search-component ref="keyword" @onSearch="handleKeyword" placeholder="Search" />

                    <md-button
                        title="Generate Invoices"
                        class="md-primary md-just-icon md-round pull-right header-button"
                        @click="createInvoice"
                    >
                        <md-icon>library_add</md-icon>
                    </md-button>

                    <div>
                        <md-button
                            title="Export Invoices"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click="exportInvoices"
                            :disabled="disableExport"
                        >
                            <md-icon>
                                get_app
                            </md-icon>
                        </md-button>
                        <md-tooltip :class="{ 'hide-export-tool-tip': !disableExport }">
                            No available data
                        </md-tooltip>
                    </div>
                    <div>
                        <md-button
                            v-if="hasAccountingPackage"
                            title="Send data that matches the filter to Accounting Package"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click="sendInvoicesToAccountingPackage"
                        >
                            <md-icon>send</md-icon>
                        </md-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins';
import { PAYMENT_STATUS_CONSTANTS } from '@/utils/constants';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { TeamRegionMemberFilterOptions, SearchComponent } from '@/components';
import CreateInvoicesModal from './CreateInvoicesModal';

export default {
    name: 'TeamMemberInvoicesOverviewToolbar',
    components: { TeamRegionMemberFilterOptions, SearchComponent },
    props: {
        teamRegionId: { type: Number, default: null },
        teamMembers: {
            type: Function,
            default: () => []
        },
        batchFilterList: {
            type: Array,
            default: () => []
        },
        disableExport: {
            type: Boolean,
            default: true
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {
            statuses: this.getStatuses([...PAYMENT_STATUS_CONSTANTS, 'All']),
            statusFilterValue: 'All',
            keywordFilterValue: '',
            teamMemberFilterValue: '',
            syncStatusValue: 'All',
            InvoiceDateFrom: null,
            InvoiceDateTo: null,
            teamRegionFilterValue: '',
            selectedTeamRegionId: null,
            syncStatuses: ['All', 'Synced', 'Unsynced'],
            batchFilterValue: null
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            canViewTeamRegion: 'user/canViewTeamRegion',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            hasAccountingPackage: 'generalSetting/hasAccountingPackage'
        })
    },
    mounted() {
        if (!this.$root.isDesktop) {
            if (this.$refs.mdDatePicker) 
                this.$refs.mdDatePicker.$refs.input.$el.setAttribute('readonly', true);
        }
    },
    watch: {
        statusFilterValue(newValue, oldValue) {
            this.handleFilter();
        },
        keywordFilterValue(newValue, oldValue) {
            this.handleFilter();
        },
        InvoiceDateFrom(newValue, oldValue) {
            this.handleFilter();
        },
        InvoiceDateTo(newValue, oldValue) {
            this.handleFilter();
        },
        syncStatusValue() {
            this.handleFilter();
        },
        batchFilterValue() {
            this.handleFilter();
        }
    },
    methods: {
        getStatuses(arr) {
            const stats = arr.map((x) => ({
                statusId: x,
                statusValue: this.$t(`inputs.paymentStatuses.${x}`)
            }));

            return stats;
        },
        // eslint-disable-next-line func-names
        handleFilter: _.debounce(function() {
            this.handleFilteredValue();
        }, 700),
        emitEvent(evt) {
            // use nextTick to ensure that invoiceDate value has already been changed.
            // this is because the @selected method of md-datepicker is asynchronous.
            this.$nextTick(() => {
                const {
                    keywordFilterValue,
                    InvoiceDateFrom,
                    InvoiceDateTo,
                    teamMemberFilterValue,
                    selectedTeamRegionId,
                    batchFilterValue
                } = this;

                let { statusFilterValue, syncStatusValue } = this;

                let invoiceDateFrom = '';
                if (InvoiceDateFrom) {
                    invoiceDateFrom = this.$options.filters.dateFormat(
                        InvoiceDateFrom,
                        this.DATE_TYPES.internationalDate
                    );
                }

                let invoiceDateTo = '';
                if (InvoiceDateTo) {
                    invoiceDateTo = this.$options.filters.dateFormat(InvoiceDateTo, this.DATE_TYPES.internationalDate);
                }

                if (statusFilterValue === 'All') 
                    statusFilterValue = null;

                if (syncStatusValue === 'All') 
                    syncStatusValue = null;

                this.$emit(evt, {
                    paymentStatus: statusFilterValue,
                    invoiceNumber: keywordFilterValue,
                    publicUserId: teamMemberFilterValue,
                    invoiceDateFrom,
                    invoiceDateTo,
                    teamRegionId: selectedTeamRegionId,
                    syncStatus: syncStatusValue,
                    teamMemberInvoiceBatchId: batchFilterValue
                });
            });
        },
        handleFilteredValue() {
            this.emitEvent('onFilterInvoices');
        },
        handleKeyword(data) {
            this.keywordFilterValue = data.searchText;
        },
        async exportInvoices() {
            this.emitEvent('onExportClicked');
        },
        async sendInvoicesToAccountingPackage() {
            this.emitEvent('onSendToAccountingPackageClicked');
        },
        handleTeamRegionMemberChanged(val) {
            this.teamMemberFilterValue = val.publicUserId;
            this.selectedTeamRegionId = val.teamRegionId;
            this.handleFilter();
        },
        async createInvoice() {
            this.$modal.show(CreateInvoicesModal, {}).then((response) => {
                if (response) {
                    this.$emit('onCreateInvoice');
                    this.handleFilter();
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-steps--container {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }

    .filter-step-date {
        min-width: 250px;
        margin-right: 0;
        min-height: initial;
        position: absolute;
        z-index: 1;
    }

    .filter-button--container {
        display: inline-block;
    }

    ::v-deep .md-datepicker:after,
    ::v-deep .md-datepicker:before {
        bottom: 0;
        height: 0;
    }

    ::v-deep .md-datepicker:before,
    ::v-deep .md-datepicker:after {
        bottom: 30px;
        content: 'Invoice date';
        color: #aaaaaa;
        font-weight: 400;
        font-size: 14px;
        transform: none;
        z-index: 1;
    }

    .start-date:before,
    .start-date:after {
        content: 'Start Date';
    }

    .end-date:before,
    .end-date:after {
        content: 'End Date';
    }

    ::v-deep .md-has-value:before,
    ::v-deep .md-has-value:after {
        bottom: 0;
        height: 0;
        content: '';
    }

    ::v-deep .md-datepicker .md-input {
        position: absolute;
        z-index: 9;
        width: 150px;
    }

    ::v-deep .md-datepicker .md-input-action {
        z-index: 99;
        right: 52px;
    }
}

::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}

::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}

::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}

::v-deep .vs__search {
    cursor: pointer;
}

.datepicker-noevent {
    ::v-deep .md-datepicker {
        input {
            pointer-events: none;
        }
    }
}

::v-deep .filter-member-name .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    display: block;
}

.time-picker .main-time-picker-container:after {
    content: 'Select time';
    color: #aaaaaa;
    font-weight: 400;
    font-size: 14px;
    transform: none;
    bottom: 17px;
    position: absolute;
}

::v-deep .md-has-value .main-time-picker-container:after {
    content: '';
}

.time-picker {
    width: 210px !important;
}

.time-picker.md-field:after {
    bottom: 10px !important;
    height: 1px !important;
}

.time-picker:last-child {
    margin-right: 0 !important;
}

.status-filter {
    width: 150px !important;
}

.time-picker ::v-deep .time-picker-container:nth-child(2) {
    border-bottom: 1px solid #d2d2d2;
}

.custom-toolbar {
    align-items: start;
    margin-top: 1px;

    .custom-toolbar-start {
        flex: 3;
    }
}

.batch-filter {
    font-size: 12.5px;
    width: 250px !important;
}

.hide-export-tool-tip {
    display: none;
}
</style>
