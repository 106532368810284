<template>
    <div v-if="trip">
        <drop-down
            v-if="!isModal"
            :should-close="shouldCloseDropdown"
            @closeDropdown="(val) => (this.shouldCloseDropdown = val)"
            @closeOtherDropDown="closeOtherDropDown"
        >
            <span
                class="status"
                slot="title"
                data-toggle="dropdown"
                :class="`status-${trip.status.toLowerCase().replace(/\s+/g, '')} ${className}`"
            >
                {{ trip.status }}
            </span>
            <ul v-if="!trip.user.publicUserId" class="dropdown-menu">
                <li v-for="(status, index) in unassignedStatuses" :key="index">
                    <a @click.stop="handleChangeStopStatus(status)">
                        {{ status }}
                    </a>
                </li>
            </ul>
            <ul v-else class="dropdown-menu">
                <li v-for="(status, index) in statuses" :key="index">
                    <a @click.stop="handleChangeStopStatus(status)">
                        {{ status }}
                    </a>
                </li>
            </ul>
        </drop-down>
        <div v-if="isModal">
            <span
                class="status"
                @click.stop="openAssignStop()"
                :class="`status-${trip.status.toLowerCase().replace(/\s+/g, '')} ${className}`"
            >
                {{ trip.status }}
            </span>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import {
    TRIP_STATUS_CONSTANTS,
    UNASSIGNED_STATUS_CONSTANTS,
    FUTURE_TRIP_STATUS_CONSTANTS,
    PAST_TRIP_STATUS_CONSTANTS,
    DATE_TYPES
} from '@/utils/constants';
import moment from 'moment';
import { mapGetters } from 'vuex';
import changeStatusModal from '../Modals/ChangeStatusModal';

export default {
    name: 'TripStatusButton',
    mixins: [GeneralMixin],
    props: {
        trip: {
            type: Object,
            default: () => {}
        },
        className: {
            type: String,
            default: () => ''
        },
        isModal: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    data() {
        return {
            statuses: TRIP_STATUS_CONSTANTS,
            unassignedStatuses: UNASSIGNED_STATUS_CONSTANTS,
            shouldCloseDropdown: false
        };
    },
    mounted() {
        const today = moment().format(DATE_TYPES.internationalDate);
        const tripDate = moment(this.trip.tripDate);

        if (tripDate.isAfter(today)) {
            this.statuses = FUTURE_TRIP_STATUS_CONSTANTS;
        }

        if (tripDate.isBefore(today)) {
            this.statuses = PAST_TRIP_STATUS_CONSTANTS;
        }
    },
    methods: {
        async openAssignStop() {
            if (this.isReadOnlyUser) 
                return;

            this.$modal
                .show(changeStatusModal, {
                    trip: this.trip
                })
                .then((response) => {
                    this.$emit('statusUpdated', response);
                });
        },
        async handleChangeStopStatus(newStatus) {
            if (this.isReadOnlyUser) 
                return;

            this.shouldCloseDropdown = true;
            const { status, tripId = null } = this.trip;
            if (status !== newStatus) {
                const payload = {
                    method: 'post',
                    data: {
                        newStatus,
                        oldStatus: status
                    }
                };

                // to toggle the context menu
                this.$nextTick(() => {
                    this.shouldCloseDropdown = false;
                });

                try {
                    const api = `/api/trips/${tripId}/change-status`;
                    await handleRequests(api, payload);

                    this.$notify({
                        message: `Trip status was changed to ${newStatus}!`,
                        type: 'success'
                    });
                    this.$emit('statusUpdated', { tripId, oldStatus: status, status: newStatus });
                } catch (e) {
                    const message = 'Cannot change trip status.';
                    showErrorMessage(this, message, e);
                    this.$emit('statusUpdated', { tripId, oldStatus: status, status: newStatus });
                }
            } else {
                const message = 'Cannot change status to the same status';
                this.$notify({
                    message,
                    type: 'danger'
                });
            }
        },
        closeOtherDropDown() {
            const dropdowns = document.querySelectorAll('.open');
            dropdowns.forEach((dropdown) => dropdown.classList.remove('open'));
        }
    }
};
</script>

<style lang="scss" scoped>
.status {
    color: #fff !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 90px;
    cursor: pointer;
}

::v-deep .dropdown-menu {
    li {
        cursor: pointer !important;
    }
}
</style>
