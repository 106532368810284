<script>
import { ref, toRefs, onMounted, inject, onBeforeUnmount, watch } from '@vue/composition-api';
import useMapUtils from '@/compostables/useMapUtils';

export default {
    name: 'RoutePlaybackPath',
    props: {
        paths: {
            type: Array,
            default: () => []
        },
        pinnedDriverDetails: {
            type: Object,
            default: () => {}
        }
    },
    setup(props, context) {
        const { paths, pinnedDriverDetails } = toRefs(props);
        const { createRouteLine } = useMapUtils(context);
        const routeLine = ref(null);
        const google = inject('google');
        const map = inject('map');

        const routeOptions = {
            strokeOpacity: 0,
            fillOpacity: 0,
            icons: [
                {
                    icon: {
                        path: google.value.maps.SymbolPath.CIRCLE,
                        strokeOpacity: 1,
                        fillOpacity: 1,
                        scale: 2
                    },
                    offset: '0',
                    repeat: '8px'
                }
            ],
            zIndex: 3
        };

        onMounted(() => {
            const {
                markerPins: { color }
            } = pinnedDriverDetails.value;
            routeLine.value = createRouteLine(google.value, paths.value, color, routeOptions, map.value);
        });

        onBeforeUnmount(() => {
            routeLine.value.setMap(null);
            routeLine.value = null;
        });

        watch(paths, (newVal, oldVal) => {
            routeLine.value.setPath(newVal);
        });

        return {
            routeLine
        };
    },
    render: () => null
};
</script>
