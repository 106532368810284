<template>
    <div class="filter-inline" v-if="businessEntitiesList.length > 0">
        <form-group name="businessEntity" :label="dropdownLabel">
            <md-input v-model="businessEntityValue" type="hidden" />
            <vue-select
                :reduce="(x) => x.businessEntityId"
                label="businessRegistrationName"
                :options="businessEntitiesList"
                v-model="businessEntityValue"
            ></vue-select>
        </form-group>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests } from '@/helpers';

export default {
    name: 'BusinessEntitiesDropdown',
    props: {
        value: {
            type: Number,
            default: 0
        },
        listOfBusinessEntities: {
            type: Array,
            default: null
        },
        dropdownLabel: {
            type: String,
            default: 'Business Entity'
        }
    },
    mixins: [GeneralMixin],
    async mounted() {
        if (this.listOfBusinessEntities == null) {
            await this.getBusinessEntities();
        } else {
            this.businessEntitiesList = this.listOfBusinessEntities;
        }

        if (this.value) {
            // eslint-disable-next-line radix
            this.businessEntityValue = this.value;
        } else {
            this.businessEntityValue = null;
        }
    },
    methods: {
        async getBusinessEntities() {
            this.$_handleLoaderState(true);
            const api = '/api/business-entities/list';
            const response = await handleRequests(api);
            if (response.data !== null) {
                this.businessEntitiesList = response.data.businessEntities;
            }
            this.$_handleLoaderState(false);
        }
    },
    watch: {
        businessEntityValue(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.value = newValue;
                this.$emit('input', this.value);
            }
        },
        value(newValue) {
            this.businessEntityValue = newValue;
            this.$emit('input', this.value);
        }
    },
    data() {
        return {
            businessEntitiesList: [],
            businessEntityValue: null
        };
    }
};
</script>
