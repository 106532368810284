var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-toolbar",
    {
      staticClass: "md-transparent custom-toolbar-navigation",
      class: {
        "md-toolbar-absolute md-white md-fixed-top":
          _vm.$route.meta.navbarAbsolute,
      },
      attrs: { "md-elevation": "0" },
    },
    [
      _c("div", { staticClass: "md-toolbar-row" }, [
        _c("div", { staticClass: "md-toolbar-section-start" }, [
          _c("h3", { staticClass: "md-title" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.$route.meta.title
                    ? _vm.$route.meta.title
                    : _vm.$route.name
                ) +
                "\n            "
            ),
          ]),
          _c("div", { staticClass: "topbar-logo" }, [
            _c("img", {
              staticClass: "logo-complete",
              attrs: { src: "/img/logos/locate2u-logo.png" },
            }),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "md-toolbar-section-end" },
          [
            _c(
              "md-button",
              {
                staticClass:
                  "md-just-icon md-round md-simple md-toolbar-toggle",
                class: { toggled: _vm.$sidebar.showSidebar },
                on: { click: _vm.toggleSidebar },
              },
              [
                _c("span", { staticClass: "icon-bar" }),
                _c("span", { staticClass: "icon-bar" }),
                _c("span", { staticClass: "icon-bar" }),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }