<template>
    <div class="modal-container" v-if="showWizardModal">
        <div class="modal-header">
            <h4 class="modal-title" v-if="logData.assetMaintenanceScheduleId == null">Add Asset Log</h4>
            <h4 class="modal-title" v-else>Add Maintenance Record</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <div class="md-layout create-asset-container">
                <form-wrapper :validator="$v.logData" class="form-wrapper">
                    <div class="md-layout-item md-size-100 align-top-div">
                        <form-group label="Asset Name" name="assetId">
                            <md-input v-model="logData.assetId" type="hidden" />
                            <vue-select
                                :reduce="(x) => x.assetId"
                                label="name"
                                :options="vehicleDropdownList"
                                v-model="logData.assetId"
                                :searchable="true"
                                :disabled="logData.assetMaintenanceScheduleId != null"
                            ></vue-select>
                        </form-group>
                        <form-group label="Type" name="logType">
                            <md-select
                                v-model="logData.logType"
                                @md-selected="handleLogType(logData.logType)"
                                :disabled="logData.assetMaintenanceScheduleId != null"
                            >
                                <md-option v-for="x in assetLogTypes" :key="x.key" :value="x.key">
                                    {{ $t('assetLogTypes.' + x.key) }}
                                </md-option>
                            </md-select>
                        </form-group>
                        <form-group label="Subtype" name="subType">
                            <md-select
                                v-model="logData.logSubType"
                                :disabled="logData.assetMaintenanceScheduleId != null"
                            >
                                <md-option v-for="x in subLogTypes" :key="x.key" :value="x.key">
                                    {{ $t('assetLogSubTypes.' + x.key) }}
                                </md-option>
                            </md-select>
                        </form-group>
                    </div>
                    <div class="md-layout-item md-size-50">
                        <div class="log-date">
                            <md-datepicker v-model="logData.logDate" :md-disabled-dates="disabledDates">
                                <label>
                                    Date
                                </label>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="md-layout-item md-size-50">
                        <form-group label="Total cost" name="cost">
                            <md-input v-model="logData.cost" />
                        </form-group>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <span class="custom-note">
                            *Odometer and engine hour values will only be updated if log date is for today or yesterday.
                        </span>
                    </div>
                    <div
                        class="md-layout-item md-size-40"
                        v-if="logData.logType != 'insurance' && logData.logType != 'vehicle-registration'"
                    >
                        <form-group label="Engine hours" name="startingEngineHours">
                            <md-input v-model="logData.startingEngineHours" />
                        </form-group>
                    </div>
                    <div
                        class="md-layout-item md-size-40"
                        v-if="logData.logType != 'insurance' && logData.logType != 'vehicle-registration'"
                    >
                        <form-group label="Odometer Reading" name="startingOdometer">
                            <md-input v-model="logData.startingOdometer" />
                        </form-group>
                    </div>
                    <div
                        class="md-layout-item md-size-20"
                        v-if="logData.logType != 'insurance' && logData.logType != 'vehicle-registration'"
                    >
                        <form-group label="Unit">
                            <md-select
                                v-model="logData.odometerUnitType"
                                :disabled="maintenanceData != null && maintenanceData.nextDueOdometerUnitType != null"
                            >
                                <md-option value="km">km</md-option>
                                <md-option value="miles">miles</md-option>
                            </md-select>
                        </form-group>
                    </div>

                    <!-- temporarily commenting this out as we will base the currency on the team settings -->

                    <!-- <div class="md-layout-item md-size-20">
                        <form-group label="Currency">
                            <md-select v-model="logData.costCurrency">
                                <md-option v-for="x in currencies" :key="x.isoCode" :value="x.isoCode">
                                    {{ x.isoCode }}
                                </md-option>
                            </md-select>
                        </form-group>
                    </div> -->

                    <div
                        class="md-layout-item md-size-100"
                        v-if="logData.logType != 'insurance' && logData.logType != 'vehicle-registration'"
                    >
                        <google-autocomplete
                            label="Location (Optional)"
                            id="autoaddress"
                            classname="form-control autocomplete-input"
                            placeholder=""
                            :should-focus="false"
                            v-model="tempLogAddressData.startDetails"
                            name="startDetails.address"
                        ></google-autocomplete>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <form-group name="notes" label="Notes">
                            <md-textarea v-model="logData.notes" />
                        </form-group>
                    </div>
                </form-wrapper>
            </div>
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="create()">Add</md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, decimal, minValue } from 'vuelidate/lib/validators';
import { handleRequests, showErrorMessage } from '@/helpers';
import { ASSET_TYPE_CONSTANTS, DATE_TYPES } from '@/utils/constants';
import { ASSET_LOG_TYPES } from '@/utils/AssetLogs';
import { GoogleAutocomplete } from '@/components';
import { Iso4217Currencies } from '@/utils/Iso4217Currencies';
import { ASSET_LOGS_DEFAULTS } from '@/utils/defaults';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    name: 'CreateAssetLog',
    components: {
        GoogleAutocomplete
    },
    mixins: [GeneralMixin],
    props: {
        vehicleDropdownList: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        assetData: {
            type: Object,
            default: null
        },
        maintenanceData: {
            type: Object,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            generalSettings: 'generalSetting/getGeneralSettings'
        })
    },
    data() {
        return {
            disabledDates: (date) => {
                const x = moment(date).format(DATE_TYPES.internationalDate);
                const now = moment().format(DATE_TYPES.internationalDate);
                return moment(x).isSameOrAfter(now, 'day');
            },
            assetTypes: ASSET_TYPE_CONSTANTS,
            assetLogTypes: ASSET_LOG_TYPES,
            currencies: Iso4217Currencies,
            subLogTypes: ASSET_LOG_TYPES[0].sub_categories,
            logData: Object.assign({}, ASSET_LOGS_DEFAULTS()),
            selectLogType: 'general-services',
            selectLogSubType: 'repair',
            selectedAssetId: null,
            tempLogAddressData: {
                startDetails: {
                    name: null,
                    address: null,
                    location: {
                        latitude: null,
                        longitude: null
                    }
                }
            },
            showWizardModal: false
        };
    },
    validations: {
        logData: {
            logType: {
                required
            },
            logSubType: {
                required
            },
            assetId: {
                required
            },
            startingEngineHours: {
                decimal,
                minValue: minValue(1)
            },
            startingOdometer: {
                decimal,
                minValue: minValue(1)
            },
            cost: {
                decimal
            }
        }
    },
    mounted() {
        if (this.generalSettings.defaultSpeedUnit == null) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Speed unit',
                    body:
                        'No default speed unit is set. Please go to the <a href="/settings/general-settings">general settings</a> under the teams menu and set a default speed unit under the teams tab',
                    buttons: ['Ok']
                })
                .then(async (response) => {
                    this.resolve(false);
                });
        } else if (this.generalSettings.defaultCurrency == null) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Currency',
                    body:
                        'No default currency is set. Please go to <a href="/settings/accounts">accounts</a> under the teams menu and set currency under invoicing tab',
                    buttons: ['Ok']
                })
                .then(async (response) => {
                    this.resolve(false);
                });
        } else {
            this.showWizardModal = true;
        }

        if (this.assetData !== null) {
            this.vehicleDropdownList.push(this.assetData);
            this.logData.assetId = this.assetData.assetId;
            this.logData.startingOdometer = this.assetData.estimatedOdometerValue;
            this.logData.startingEngineHours = this.assetData.estimatedEngineHourValue;
        }

        if (this.maintenanceData != null) {
            this.logData.assetMaintenanceScheduleId = this.maintenanceData.assetMaintenanceScheduleId;
            this.logData.logType = this.maintenanceData.logType;
            this.handleLogType(this.maintenanceData.logType);
            this.logData.logSubType = this.maintenanceData.logSubType;
            this.vehicleDropdownList.push({
                assetId: this.maintenanceData.assetId,
                name: this.maintenanceData.assetName
            });
            this.logData.assetId = this.maintenanceData.assetId;

            if (this.logData.assetMaintenanceScheduleId != null) {
                this.logData.notes = `${this.maintenanceData.maintenanceName} \n`;
            }

            if (this.maintenanceData.nextDueServiceDate != null) {
                this.logData.notes = `${this.logData.notes ?? ''}Service due on ${moment(
                    this.maintenanceData.nextDueServiceDate
                ).format(DATE_TYPES.internationalDate)} \n`;
            }

            if (this.maintenanceData.nextDueEngineHours != null) {
                this.logData.notes = `${this.logData.notes ?? ''}Engine hour due on ${
                    this.maintenanceData.nextDueEngineHours
                } \n`;
            }

            if (this.maintenanceData.nextDueOdometer != null) {
                this.logData.notes = `${this.logData.notes ?? ''}Odometer reading due on ${
                    this.maintenanceData.nextDueOdometer
                }`;
            }
        }

        this.currencies = Object.entries(Iso4217Currencies).map(([key, value]) => ({
            isoCode: key,
            description: value
        }));

        this.logData.logDate = moment().format(DATE_TYPES.internationalDate);
        this.logData.odometerUnitType = this.generalSettings.defaultSpeedUnit === 'kph' ? 'km' : 'miles';

        if (this.vehicleDropdownList.length === 0) {
            this.$notify({
                message: 'Please add a vehicle asset',
                type: 'danger'
            });
            this.resolve(true);
        }

        if (this.logData.assetMaintenanceScheduleId != null) {
            if (this.maintenanceData.nextDueOdometerUnitType != null) {
                this.logData.odometerUnitType = this.maintenanceData.nextDueOdometerUnitType;
            }

            this.logData.startingOdometer = this.maintenanceData.estimatedOdometerValue;
            this.logData.startingEngineHours = this.maintenanceData.estimatedEngineHourValue;
        }
    },
    methods: {
        async create() {
            this.$_handleLoaderState(true, 'PROCESSING...');

            if (this.logData.startingEngineHours === '') 
                this.logData.startingEngineHours = null;

            if (this.logData.startingOdometer === '') 
                this.logData.startingOdometer = null;

            if (this.logData.assetId === '') 
                this.logData.assetId = null;

            this.$v.logData.$touch();

            if (!this.$v.logData.$invalid) {
                if (this.logData.cost != null) 
                    this.logData.cost = parseFloat(this.logData.cost);

                if (this.logData.startingEngineHours != null && this.logData.startingEngineHours !== '')
                    this.logData.startingEngineHours = parseFloat(this.logData.startingEngineHours);

                if (this.logData.startingOdometer != null && this.logData.startingOdometer !== '')
                    this.logData.startingOdometer = parseFloat(this.logData.startingOdometer);

                if (this.tempLogAddressData.startDetails.address != null) {
                    this.logData.startAddressName = this.tempLogAddressData.startDetails.name;
                    this.logData.startAddress = this.tempLogAddressData.startDetails.address;
                    this.logData.startLocation = this.tempLogAddressData.startDetails.location;
                }

                let api = '/api/asset-logs';

                if (this.logData.assetMaintenanceScheduleId != null && this.logData.assetId != null) {
                    api = `/api/assets/${this.logData.assetId}/scheduled-maintenance/${
                        this.logData.assetMaintenanceScheduleId
                    }/complete`;
                }

                const payload = {
                    method: 'post',
                    data: this.logData
                };

                try {
                    await handleRequests(api, payload);
                    this.$notify({
                        message: 'Successfully created an asset log.',
                        type: 'success'
                    });

                    this.resolve({
                        isSuccess: true
                    });
                } catch (error) {
                    const message = 'Error in creating asset log';
                    showErrorMessage(this, message, error);
                }
            }

            this.$_handleLoaderState(false);
        },
        handleLogType(type) {
            const subtypes = this.assetLogTypes.filter((x) => x.key === type);
            this.subLogTypes = null;
            this.subLogTypes = subtypes[0].sub_categories;
            this.logData.logSubType = subtypes[0].sub_categories[0].key;
        }
    }
};
</script>

<style lang="scss" scoped>
.asset-action {
    text-align: right;
}

.create-asset-container {
    max-height: 80vh;
    padding-right: 14px !important;
    overflow: auto;
    width: 100%;

    ::v-deep .form-wrapper {
        width: 100%;
    }
}

.md-size-40 {
    width: 40%;
    display: inline-block;
    vertical-align: top;
}
.md-size-20 {
    width: 20%;
    display: inline-block;
}

.md-size-50 {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

::v-deep .md-textarea {
    resize: none !important;
    padding: 0 !important;
    margin-top: 20px !important;
}

::v-deep .vs__clear {
    display: none;
}

.log-date {
    ::v-deep .md-clear {
        display: none;
    }
}

.custom-note {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: block;
    color: #aaa;
    margin-bottom: 12px;
}
.align-top-div {
    vertical-align: top;
}
</style>
