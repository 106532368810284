export const Tooltip = {
    bind(el, binding) {
        const isMobile = window.matchMedia('(max-width: 480px)').matches;
        const { value } = binding;
        el.addEventListener('mouseenter', () => {
            /* eslint consistent-return: 'off' */
            if (isMobile) 
                return true;
            const $tooltip = document.createElement('div');

            $tooltip.setAttribute('class', 'tooltip');

            const $content = document.createElement('span');
            $content.innerHTML = value.content;
            $tooltip.appendChild($content);

            const $parentNode = document.querySelector(value.parentNode);

            $parentNode.insertBefore($tooltip, $parentNode.firstChild);
        });

        el.addEventListener('mouseleave', () => {
            /* eslint consistent-return: 'off' */
            if (isMobile) 
                return true;
            const $parentNode = document.querySelector(value.parentNode);
            const $tooltip = document.querySelector('.tooltip');

            $parentNode.removeChild($tooltip);
        });
    },
    unbind(el) {
        el.removeEventListener('mouseenter', () => {});
        el.removeEventListener('mouseleave', () => {});
    }
};
