<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <div class="md-layout batch-import-template">
                <div class="md-layout-item md-size-90">
                    <form-group name="mappingtemplate" label="Select Import Template">
                        <md-select v-model="selectedTemplateName">
                            <md-option
                                v-for="templateName in templateNameList"
                                :key="templateName"
                                :value="templateName"
                            >
                                {{ templateName }}
                            </md-option>
                        </md-select>
                    </form-group>
                </div>
                <div class="md-layout-item md-size-10">
                    <md-button
                        title="Download Sample Template"
                        class="md-primary md-just-icon md-round pull-right download-btn"
                        @click="downloadSampleTemplate"
                    >
                        <md-icon>get_app</md-icon>
                    </md-button>
                </div>
            </div>

            <input ref="fileInput" style="display:none;" type="file" @change="onFileSelected" />
            <button class="custom-btn" @click="$refs.fileInput.click()">
                Select File...
            </button>
            <div class="error-message-container">
                <batch-upload-error-message :content="errorMessages" :has-error-message="hasError" />
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue-scoped-css/require-scoped */
import { handleRequests, showErrorMessage, downloadFile } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import BatchUploadErrorMessage from './BatchUploadErrorMessage';

export default {
    name: 'BatchUploadModal',
    mixins: [GeneralMixin],
    components: {
        BatchUploadErrorMessage
    },
    setup() {
        return {
            templateNameList: ['Default'],
            selectedTemplateName: 'Default'
        };
    },
    props: {
        title: {
            type: String,
            default: null
        },
        templateType: {
            type: String,
            default: null
        },
        members: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    watch: {
        selectedTeamMemberId(newVal) {
            if (newVal == null) {
                this.selectedTeamMemberId = 0;
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        })
    },
    async mounted() {
        await this.loadTemplateNames();
    },
    methods: {
        async loadTemplateNames() {
            const api = `/api/teams/import-template-names/${this.templateType}`;
            const response = await handleRequests(api);
            const { data } = response;

            if (data.length) {
                this.templateNameList = ['Default'].concat(
                    data.filter((value) => value !== 'Default').sort((value1, value2) => value1.localeCompare(value2))
                );
            } else {
                this.templateNameList = ['Default'];
            }

            this.$forceUpdate(); // Hack needed to reliably get the list to update

            this.selectedTemplateName =
                localStorage.getItem(`${this.templateType}.defaults.import-template`) ?? this.templateNameList[0];
        },

        async downloadSampleTemplate() {
            try {
                const endpoint = `/api/teams/import-template-file/${this.templateType}`;
                const payload = {
                    method: 'get',
                    responseType: 'blob',
                    params: {
                        mappingTemplate: this.selectedTemplateName
                    }
                };
                const response = await handleRequests(endpoint, payload);

                downloadFile(
                    response.data,
                    `Upload${this.templateType.charAt(0).toUpperCase() +
                        this.templateType.substr(1).toLowerCase()}Template.xlsx`
                );
            } catch (error) {
                const message = `Error in import template ${this.templateType}.`;
                showErrorMessage(this, message, error);
            }
        },

        onFileSelected(event) {
            // eslint-disable-next-line prefer-destructuring
            this.selectedFile = event.target.files[0];
            event.target.value = '';
            if (this.selectedFile) 
                this.onUpload();
        },

        async onUpload() {
            this.$_handleLoaderState(true, 'IMPORTING...');
            const fd = new FormData();
            fd.append('file', this.selectedFile);
            fd.append('mappingTemplateId', this.selectedTemplateName);
            // eslint-disable-next-line eqeqeq
            if (this.selectedTeamMemberId == 0) {
                this.selectedTeamMemberId = '';
            }
            let route = this.templateType;
            if (this.templateType === 'runSchedules') {
                route = 'run-schedules';
            }
            const api = `/api/${route}/import`;
            const payload = {
                method: 'post',
                data: fd
            };

            try {
                const response = await handleRequests(api, payload);

                if (response.status === 200) {
                    this.hasError = false;
                    this.errorMessages = [];
                    this.selectedFile = '';
                    this.resolve('ok');
                    this.$notify({
                        message: `The ${this.templateType} have been successfully uploaded`,
                        type: 'success'
                    });

                    localStorage.setItem(`${route}.defaults.import-template`, this.selectedTemplateName);
                }
                this.$_handleLoaderState(false);
            } catch (error) {
                this.$_handleLoaderState(false);
                this.hasError = true;
                this.errorMessages = error.data;
                if (!error.data) {
                    const message = `Error in importing ${this.templateType}`;
                    showErrorMessage(this, message, error);
                }
            }
        }
    },
    data() {
        return {
            selectedTemplateName: 'Default',
            selectedTeamMemberId: 0,
            teamMemberSelected: false,
            selectedFile: null,
            hasError: false,
            errorMessages: []
        };
    }
};
</script>

<style lang="scss" scoped>
.modal-body {
    .custom-btn {
        margin-right: 10px;
    }
}

.hide {
    display: none;
}

.modal-body {
    max-height: 80vh;
    ::v-deep .vs__search::placeholder {
        color: #aaaaaa;
        // font-size: 14px;
    }
}

.error-message-container {
    overflow: auto;
    max-height: 60vh;
}

.create-stop {
    overflow: auto;
    max-height: 70vh;
    .contact-details {
        margin-bottom: 20px;
        span {
            text-align: left;
            text-transform: uppercase;
            display: block;
            font-size: 12px;
            font-weight: 600;
        }
    }

    ::v-deep .md-field {
        margin-top: 10px;
        width: 100%;
    }

    ::v-deep .md-textarea {
        resize: none !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    ::v-deep .md-has-textarea {
        margin-top: 0;
    }

    .tripdate {
        text-align: left;

        ::v-deep .md-checkbox {
            margin: 0;
        }
    }

    .stop-datepicker {
        text-align: left;
        > div {
            width: 30%;
            margin-right: 20px;
            display: inline-block;
            vertical-align: top;
        }
        > div:first-child {
            width: 35%;
        }
        > div:last-child {
            margin-right: 0;
            width: 18%;
        }
        .error {
            font-size: 12px;
            color: red;
            margin-top: -10px;
            display: block;
        }

        ::v-deep .md-input {
            width: 100%;
        }

        .time-picker:after {
            height: 0;
        }

        .time-window {
            width: 100%;
            > div {
                width: 100%;
            }
            > div:first-child {
                text-transform: uppercase;
                display: block;
                font-size: 12px;
                font-weight: 600;
                text-align: left;
            }
            > div:nth-child(2) {
                ::v-deep .md-radio {
                    margin: 0;
                    margin-right: 15px;
                }
                text-transform: uppercase;
                text-align: left;
                font-size: 12px;
            }
        }
    }

    .half-width {
        width: 50%;
        display: inline-block;
    }

    .autocomplete-input {
        width: 100%;
    }
}
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}

::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}

::v-deep .vs--single .vs__selected {
    padding: 0;
    margin-left: 0;
}
.download-btn {
    cursor: pointer;
}
.batch-import-template {
    ::v-deep .md-layout-item {
        padding: 0px;
    }
}

.modal-body {
    .download-btn {
        width: 32px;
        height: 32px;
        min-width: 32px;
        margin-top: 15px;
        background: #2b93ff !important;
    }

    .md-button:hover {
        background: #0076f0 !important;
    }
}
</style>
