var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "config-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v("\n            " + _vm._s(_vm.title) + "\n            "),
          _c("i", [_vm._v(_vm._s(_vm.rateGroupName))]),
          _c("i", [_vm._v(_vm._s(_vm.customerName))]),
          _c("i", [_vm._v(_vm._s(_vm.driverName))]),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "modal-body" }, [
      _c(
        "div",
        { staticClass: "md-layout batch-import-template" },
        [
          _c("form-wrapper", { attrs: { validator: _vm.$v } }, [
            _c(
              "div",
              [
                _c("md-tooltip", { attrs: { "md-direction": "top" } }, [
                  _vm._v(
                    "\n                        Number of attempts before charges are created, only applies to failed stops with an item.\n                        "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n                        This number will only apply charges and costs to this stop only. Anything prior or after\n                        will have no charges or costs.\n                    "
                  ),
                ]),
                _c(
                  "form-group",
                  {
                    staticClass: "form-group",
                    attrs: {
                      name: "reattemptCharges",
                      label: "Reattempt Charges",
                    },
                  },
                  [
                    _c("md-input", {
                      attrs: { type: "number" },
                      model: {
                        value: _vm.rateConfigurationRule.reattemptCharges,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.rateConfigurationRule,
                            "reattemptCharges",
                            _vm._n($$v)
                          )
                        },
                        expression: "rateConfigurationRule.reattemptCharges",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "form-group",
                  {
                    staticClass: "form-group",
                    attrs: { name: "reattemptCosts", label: "Reattempt Costs" },
                  },
                  [
                    _c("md-input", {
                      attrs: { type: "number" },
                      model: {
                        value: _vm.rateConfigurationRule.reattemptCosts,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.rateConfigurationRule,
                            "reattemptCosts",
                            _vm._n($$v)
                          )
                        },
                        expression: "rateConfigurationRule.reattemptCosts",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "equivalence-container" }, [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v("\n                Weight Equivalence\n            "),
        ]),
        _c(
          "div",
          [
            _c(
              "form-group",
              {
                staticClass: "equivalence-item",
                attrs: { name: "weightEquivalence" },
              },
              [
                _c("md-input", {
                  staticClass: "equivalence-number",
                  attrs: { type: "number" },
                  model: {
                    value: _vm.rateConfigurationRule.weightEquivalence,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.rateConfigurationRule,
                        "weightEquivalence",
                        _vm._n($$v)
                      )
                    },
                    expression: "rateConfigurationRule.weightEquivalence",
                  },
                }),
                _c("div", { staticClass: "equivalence-symbol" }, [
                  _vm._v(_vm._s(_vm.teamWeightSymbol.symbol)),
                ]),
              ],
              1
            ),
            _c("md-icon", { staticClass: "scale" }, [
              _vm._v("\n                    scale\n                "),
            ]),
            _c("div", { staticClass: "equivalence-item equivalence-volume" }, [
              _c("div", { staticClass: "equivalence-symbol" }, [
                _vm._v("1 " + _vm._s(_vm.teamVolumeSymbol.symbol)),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "md-button",
            {
              staticClass: "dialog-button md-primary",
              on: { click: _vm.save },
            },
            [_vm._v("\n                Save\n            ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }