var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    {
      staticClass: "md-card-tabs",
      class: [
        { "flex-column": _vm.flexColumn },
        { "nav-pills-icons": _vm.navPillsIcons },
        { "md-card-plain": _vm.plain },
      ],
    },
    [
      _c("md-card-header", [_vm._t("header-title")], 2),
      _c(
        "md-card-content",
        [
          !_vm.hideTabHeader
            ? _c(
                "md-list",
                { staticClass: "nav-tabs" },
                _vm._l(_vm.tabName, function (item, index) {
                  var _obj
                  return _c(
                    "md-list-item",
                    {
                      key: item,
                      class: [
                        {
                          active: _vm.isActiveTab(item)
                            ? _vm.isActivePanel(_vm.tabName[index])
                            : "false",
                        },
                        ((_obj = {}),
                        (_obj[_vm.getColorButton(_vm.colorButton)] =
                          _vm.isActivePanel(_vm.tabName[index])),
                        _obj),
                        {
                          hideTab:
                            _vm.badgeLength.length > 0 &&
                            _vm.hideTab(_vm.badgeLength[index]),
                        },
                      ],
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.isActiveTab(item)
                            ? _vm.switchPanel(_vm.tabName[index])
                            : ""
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.tabName[index]) +
                          "\n                "
                      ),
                      _vm.badgeLength.length > 0 && _vm.badgeLength[index] > 0
                        ? _c("md-badge", {
                            staticClass: "tab-custom-badge",
                            attrs: { "md-content": _vm.badgeLength[index] },
                          })
                        : _vm._e(),
                      _vm.navPillsIcons
                        ? _c("md-icon", [_vm._v(_vm._s(_vm.tabIcon[index]))])
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _c(
              "div",
              { staticClass: "tab-content" },
              [
                _vm._l(_vm.tabName, function (item, tabindex) {
                  return [
                    _vm.isActivePanel(_vm.tabName[tabindex])
                      ? [
                          _c(
                            "div",
                            {
                              key: tabindex,
                              class: _vm.getTabContent(tabindex + 1),
                            },
                            [
                              _vm._t(_vm.getTabContent(tabindex + 1), [
                                _vm._v("This is the default text!"),
                              ]),
                            ],
                            2
                          ),
                        ]
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }