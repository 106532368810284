var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTeam
    ? _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
          },
          [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c(
                "div",
                { staticClass: "custom-toolbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Team Settings",
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v("Team Settings"),
                      ]),
                    ]
                  ),
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("   >    " + _vm._s(_vm.$t("menus.setting.brands"))),
                  ]),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-blue" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("face")])],
                      1
                    ),
                  ]
                ),
                _c("md-card-content", [
                  _c(
                    "div",
                    { staticClass: "cf-container" },
                    [
                      _c(
                        "div",
                        { staticClass: "cf-action-container" },
                        [
                          _c(
                            "md-button",
                            {
                              staticClass: "md-primary md-just-icon md-round",
                              attrs: { title: "Add brand" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleAdd()
                                },
                              },
                            },
                            [_c("md-icon", [_vm._v("add")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "md-table",
                        { staticClass: "custom-paginated-table" },
                        [
                          _c(
                            "md-table-row",
                            [
                              _c("md-table-head", [_vm._v("Name")]),
                              _c("md-table-head", [_vm._v("Logo")]),
                              _c("md-table-head", [_vm._v("Actions")]),
                            ],
                            1
                          ),
                          _vm._l(_vm.brandList, function (item, index) {
                            return _c(
                              "md-table-row",
                              { key: index },
                              [
                                _c("md-table-cell", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.name) +
                                      "\n                            "
                                  ),
                                ]),
                                _c("md-table-cell", [
                                  _c("div", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.viewPhoto(
                                              [item.logoFileUrl],
                                              item.logoFileUrl
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "note-image",
                                          attrs: { src: item.logoFileUrl },
                                          on: {
                                            error: function ($event) {
                                              return _vm.$_notePhotoLoading(
                                                $event,
                                                item.logoFileUrl
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "md-table-cell",
                                  { staticClass: "action-buttons" },
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-warning md-just-icon md-round",
                                        attrs: { title: "update brand" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.update(item)
                                          },
                                        },
                                      },
                                      [_c("md-icon", [_vm._v("edit")])],
                                      1
                                    ),
                                    _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-danger md-just-icon md-round",
                                        attrs: { title: "delete brand" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.deleteBrand(item.brandId)
                                          },
                                        },
                                      },
                                      [_c("md-icon", [_vm._v("delete")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                      _vm.brandList.length == 0
                        ? _c("div", { staticClass: "text-center" }, [
                            _c("p", { staticClass: "no-result-message" }, [
                              _vm._v(
                                "No results matching your search/filter could be found."
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }