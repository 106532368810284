/* eslint-disable import/no-cycle */
import { TEAM_CUSTOM_ACTION_EVENT_TRIGGERS } from '@/utils/CustomActionConstants';
import { handleRequests } from '../../helpers/index';

const parseCustomAction = (data) => {
    const parsed = data;
    const t = TEAM_CUSTOM_ACTION_EVENT_TRIGGERS.find((y) => y.eventTrigger === parsed.eventTrigger);
    parsed.eventTriggerName = t ? t.displayText : '';

    // we'll set this to null and use as indicator whether old data gets migrated to the new field 'emailRecipientTypes'
    // we can remove this code when no user is referring to it in db
    parsed.settings.emailRecipientType = null;

    return parsed;
};

const sortByEventTriggerName = (x, y) => {
    const a = x.eventTriggerName;
    const b = y.eventTriggerName;
    if (a < b) 
        return -1;
    if (a > b) 
        return 1;
    return 0;
};

const INIT_STATE = {
    customActions: [],
    emailCustomActions: [],
    quotesFromCarriersCustomActions: [],
    webhookCustomActions: []
};

const getters = {
    customActions: ({ customActions }) => customActions,
    quotesFromCarriersCustomActions: ({ quotesFromCarriersCustomActions }) => quotesFromCarriersCustomActions,
    emailCustomActions: ({ customActions }) =>
        customActions.filter((x) => x.action === 'Email').sort(sortByEventTriggerName),
    webhookCustomActions: ({ customActions }) =>
        customActions.filter((x) => x.action === 'Webhook').sort(sortByEventTriggerName),
    smsCustomActions: ({ customActions }) => customActions.filter((x) => x.action === 'Sms'),
    existingReplyToEmails: (state, getters) => {
        const all = getters.emailCustomActions.map((evt) => {
            if (evt.settings) {
                return evt.settings.replyToEmail;
            }
            return null;
        });

        const unique = all.filter((val, index, self) => {
            return self.indexOf(val) === index;
        });

        return unique;
    },
    hasOfferToTeamMembersAction: (state) => {
        return state.customActions.findIndex((x) => x.action === 'OfferToTeamMembers') > -1;
    }
};

const mutations = {
    SET_EVENT_TRIGGERS: (state, { actionType, customActions }) => {
        if (!actionType) {
            // replace all
            state.customActions = [...customActions];
            return;
        }

        const current = state.customActions.filter((x) => x.action !== actionType);
        state.customActions = [...current, ...customActions];
    },
    ADD_EVENT_TRIGGER: (state, { customAction }) => {
        const parsedCustomAction = parseCustomAction(customAction);
        const current = [...state.customActions];
        current.push(parsedCustomAction);
        state.customActions = current;
    },
    UPDATE_EVENT_TRIGGER: (state, { customAction }) => {
        const parsedCustomAction = parseCustomAction(customAction);
        const current = [...state.customActions];
        const index = current.findIndex((x) => x.teamCustomActionId === parsedCustomAction.teamCustomActionId);
        if (index >= 0) 
            current[index] = { ...parsedCustomAction };

        state.customActions = current;
    },
    REMOVE_EVENT_TRIGGER: (state, { actionType, teamCustomActionId }) => {
        const current = [...state.customActions];
        const index = current.findIndex((x) => x.teamCustomActionId === teamCustomActionId);
        if (index >= 0) 
            current.splice(index, 1);

        state.customActions = current;
    }
};

const actions = {
    async FETCH_EVENT_TRIGGERS({ commit }, { actionType }) {
        const api = `/api/teams/custom-actions/list?actionType=${actionType !== null ? actionType : ''}`;
        const payload = {
            method: 'get'
        };

        await handleRequests(api, payload).then((response) => {
            let mapped = [];
            const { data } = response;
            if (data) {
                mapped = [...data].map((x) => {
                    const parsedCustomAction = parseCustomAction(x);
                    return parsedCustomAction;
                });
            }
            commit('SET_EVENT_TRIGGERS', { actionType, customActions: mapped });
        });
    },
    async ADD_EVENT_TRIGGER({ commit }, { data }) {
        const api = `/api/teams/custom-actions`;
        const payload = {
            method: 'post',
            data
        };

        await handleRequests(api, payload).then((response) => {
            data.teamCustomActionId = response.data.teamCustomActionId;
            const parsedCustomAction = parseCustomAction(data);

            commit('ADD_EVENT_TRIGGER', { customAction: parsedCustomAction });
        });
    },
    async UPDATE_EVENT_TRIGGER({ commit }, { data }) {
        const api = `/api/teams/custom-actions/${data.teamCustomActionId}`;
        const payload = {
            method: 'put',
            data
        };

        await handleRequests(api, payload).then((response) => {
            commit('UPDATE_EVENT_TRIGGER', { customAction: data });
        });
    },
    async DELETE_EVENT_TRIGGER({ commit }, { actionType, teamCustomActionId }) {
        const api = `/api/teams/custom-actions/${teamCustomActionId}`;
        const payload = {
            method: 'delete'
        };

        await handleRequests(api, payload).then((response) => {
            commit('REMOVE_EVENT_TRIGGER', { actionType, teamCustomActionId });
        });
    }
};

const state = () => ({ ...INIT_STATE });

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
