<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
            <ul class="filter-option-ui">
                <li v-for="f in data" :key="f.value">
                    <md-checkbox type="checkbox" :id="f.value" :value="f.value" v-model="selectedFilterValue" multiple>
                        <label :for="f.value">{{ f.value }}</label>
                    </md-checkbox>
                </li>
            </ul>
        </div>
        <div class="modal-footer">
            <md-button
                class="md-primary dialog-button"
                @click="applyFilter"
                :disabled="selectedFilterValue.length === 0"
            >
                Apply
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterStopsModal',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        },
        selectedFilterValue: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        applyFilter() {
            this.resolve({ value: this.selectedFilterValue });
        }
    }
};
</script>
<style lang="scss" scoped>
.modal-container {
    max-width: 250px;
}
.filter-option-ui {
    padding: 0;
    margin: 0;
    list-style-type: none;
    .md-checkbox.md-theme-default {
        margin: 8px 0px;
    }
}
</style>
