<template>
    <div class="content">
        <customer-invoices-overview-toolbar
            :class="$root.isTablet ? 'tablet-filter-container' : ''"
            @onFilterInvoices="handleFilterOrSearch"
            @onExportClicked="handleExportClick"
            @onSendToAccountingPackageClicked="processInvoicesBasedOnFilter"
            @onCreateInvoice="handleGenerateInvoice"
            :batch-filter-list="batchFilterList"
        />
        <div class="md-layout">
            <div class="md-layout-item">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-warning">
                        <div class="card-icon">
                            <md-icon>receipt</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div
                            :class="['bulk-section', topScrollPosition < 0 ? 'sticky' : '']"
                            v-if="selectedInvoices.length && (!showProgressBox || isProgressComplete)"
                        >
                            <div>
                                {{ selectedInvoices.length }} invoice{{ selectedInvoices.length > 1 ? 's' : '' }}
                                selected.
                            </div>
                            <div>
                                <payment-status-button
                                    :is-bulk="true"
                                    @invoiceBulkStatusUpdate="handlePageUpdate"
                                    :bulk-customer-invoice-ids="selectedInvoices"
                                />
                                <md-button
                                    class="md-round md-just-icon md-warning"
                                    title="Sync to Accounting Package"
                                    @click="handleSendToAccountingPackageClick"
                                >
                                    <md-icon>sync</md-icon>
                                </md-button>
                                <send-email-button
                                    :is-bulk="true"
                                    :bulk-customer-invoice-ids="selectedInvoices"
                                    @handleBulkSent="handleBulkSentEmail"
                                />
                                <delete-invoice-button
                                    :is-bulk="true"
                                    :bulk-customer-invoice-ids="selectedInvoices"
                                    @invoiceBulkDelete="bulkDelete"
                                />
                            </div>
                        </div>

                        <div v-if="!isListLoading">
                            <md-table class="context-menu-support custom-paginated-table">
                                <md-table-row>
                                    <md-table-head v-if="!isReadOnlyUser">
                                        <md-checkbox
                                            class="invoice-checkbox checkbox-head"
                                            v-model="isSelectAll"
                                            @change="onSelectAll(isSelectAll)"
                                        ></md-checkbox>
                                    </md-table-head>

                                    <md-table-head>Invoice Number</md-table-head>
                                    <md-table-head>Customer</md-table-head>
                                    <md-table-head>Invoice Date</md-table-head>

                                    <md-table-head>Subtotal</md-table-head>
                                    <md-table-head>Tax</md-table-head>
                                    <md-table-head>Total Amount</md-table-head>

                                    <md-table-head>Status</md-table-head>
                                    <md-table-head v-if="$root.isDesktop && !isReadOnlyUser">Actions</md-table-head>
                                </md-table-row>
                                <md-table-row
                                    v-for="invoice in invoiceList"
                                    :key="invoice.customerInvoiceId"
                                    @click.stop="showSidebar(invoice.customerInvoiceId)"
                                >
                                    <md-table-cell v-if="!isReadOnlyUser">
                                        <md-checkbox
                                            class="invoice-checkbox"
                                            :value="invoice.customerInvoiceId"
                                            v-model="selectedInvoices"
                                        ></md-checkbox>
                                    </md-table-cell>

                                    <md-table-cell>
                                        <router-link
                                            class="invoice-ref"
                                            :to="{
                                                name: 'Customer Invoice Details',
                                                params: { invoiceId: invoice.customerInvoiceId }
                                            }"
                                            target="_blank"
                                        >
                                            {{ invoice.invoiceNumber }}
                                        </router-link>
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ invoice.customer }}
                                    </md-table-cell>

                                    <md-table-cell>
                                        {{ invoice.invoiceDate | dateFormat(DATE_TYPES.standardDate) }}
                                    </md-table-cell>

                                    <md-table-cell>{{ invoice.subTotal | currency(invoice.currency) }}</md-table-cell>
                                    <md-table-cell>{{ invoice.totalTax | currency(invoice.currency) }}</md-table-cell>
                                    <md-table-cell>{{ invoice.total | currency(invoice.currency) }}</md-table-cell>

                                    <md-table-cell>
                                        <payment-status-button
                                            :customer-invoice-id="invoice.customerInvoiceId"
                                            :invoice-details="invoice"
                                            @statusUpdated="onChangedInvoiceStatus"
                                        />
                                    </md-table-cell>

                                    <md-table-cell v-if="$root.isDesktop" class="action-buttons">
                                        <sync-invoice-button :invoice="invoice" v-if="!isReadOnlyUser" />
                                        <generate-pdf-button :customer-invoice-id="invoice.customerInvoiceId" />
                                        <generate-excel-button
                                            v-if="showInvoiceDataExcelLink"
                                            :customer-invoice-id="invoice.customerInvoiceId"
                                        />
                                        <send-email-button
                                            v-if="!isReadOnlyUser"
                                            :customer-invoice-id="invoice.customerInvoiceId"
                                            @invoiceSent="handleSentEmail"
                                            :email-sent-date="invoice.lastEmailDateSent"
                                        />
                                        <delete-invoice-button
                                            v-if="!isReadOnlyUser"
                                            :customer-invoice-id="invoice.customerInvoiceId"
                                            @invoiceDeleted="handlePageUpdate"
                                            :status="invoice.status"
                                        />
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <div v-if="invoiceList.length === 0">
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
                <md-card-actions class="page-footer" md-alignment="space-between">
                    <div>
                        <p v-if="total === pagination.perPage" class="card-category">
                            Page {{ pagination.currentPage }} of many
                        </p>
                        <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ maxPage }}</p>
                    </div>

                    <pagination
                        v-model="pagination.currentPage"
                        class="pagination-no-border pagination-success"
                        :per-page="pagination.perPage"
                        :total="total"
                        @change-page="handleChangePage($event, pagination.perPage)"
                    />
                </md-card-actions>
            </div>

            <transition name="slide">
                <customer-invoices-sidebar
                    v-if="isShowSidebar"
                    :customer-invoice-id="customerInvoiceId"
                    v-click-outside="toggleSidebarWindow"
                    @closeModal="toggleSidebarWindow"
                    @invoiceUpdated="(p) => invoiceUpdated(customerInvoiceId, p)"
                    @invoiceDeleted="handleDeletedInvoice"
                />
            </transition>
        </div>

        <div class="progress-container" v-if="showProgressBox">
            <div class="button-close">
                <md-button class="md-default md-just-icon md-round" @click="handleCloseProgressBox">
                    <md-icon>close</md-icon>
                </md-button>
            </div>
            <div class="progress-text-section" v-if="!isProgressComplete">
                <div>
                    <div class="btn-loader route-loader"></div>
                </div>
                <div v-if="totalInvoices == 0">Preparing invoices...</div>
                <div v-else>{{ currentProcessedInvoices }} of {{ totalInvoices }} invoices have been generated.</div>
            </div>
            <div v-else class="progress-text-section">
                <div>
                    <md-icon class="green-check">check_circle</md-icon>
                </div>
                <div>
                    Process has been completed.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { handleRequests, showErrorMessage, downloadFile } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { mapGetters } from 'vuex';
import { Pagination } from '@/components';
import moment from 'moment';
import { PAYMENT_STATUS_CONSTANTS, DATE_TYPES, SEND_TO_ACCOUNTING_CONSTANTS } from '@/utils/constants';
import { CustomerInvoicesOverviewToolbar } from './components';
import CustomerInvoicesSidebar from './CustomerInvoicesSidebar';

import {
    PaymentStatusButton,
    DeleteInvoiceButton,
    GeneratePdfButton,
    GenerateExcelButton,
    SendEmailButton,
    SyncInvoiceButton
} from './buttons';

const signalR = require('@aspnet/signalr');

export default {
    name: 'CustomerInvoicesOverview',
    mixins: [GeneralMixin],
    components: {
        CustomerInvoicesOverviewToolbar,
        FadeLoader,
        CustomerInvoicesSidebar,
        Pagination,
        PaymentStatusButton,
        DeleteInvoiceButton,
        GeneratePdfButton,
        GenerateExcelButton,
        SendEmailButton,
        SyncInvoiceButton
    },
    data() {
        return {
            invoiceList: [],
            isListLoading: false,
            pagination: PAGINATION_DEFAULTS,
            filters: {
                teamMemberPublicUserId: '',
                teamRegionId: null
            },
            maxPage: 1,
            isShowSidebar: false,
            customerInvoiceId: null,
            topScrollPosition: 0,
            selectedInvoices: [],
            bulkListError: [],
            statuses: PAYMENT_STATUS_CONSTANTS,
            shouldCloseDropdown: false,
            isSelectAll: false,
            connection: '',
            batchFilterList: [],
            showInvoiceDataExcelLink: true,
            totalInvoices: 0,
            currentProcessedInvoices: 0,
            isProgressComplete: false,
            showProgressBox: false,
            generatedInvoiceIds: []
        };
    },
    watch: {
        selectedInvoices() {
            if (this.selectedInvoices.length === 0) {
                this.bulkListError = [];
            }
        }
    },
    computed: {
        total() {
            return this.pagination.total;
        },
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    async mounted() {
        this.filters.teamRegionId = this.user.teamRegionId ? this.user.teamRegionId : -1; // not set

        const currentPage = Number(this.$route.query.currentPage) || 1;
        if (currentPage) {
            this.pagination.currentPage = currentPage;
        }

        await this.handleChangePage(currentPage, this.pagination.perPage);

        this.setupSignalR();

        document.querySelector('.main-panel').addEventListener('scroll', this.handleScroll, true);
    },
    beforeDestroy() {
        document.querySelector('.main-panel').removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        async handleSyncInvoice(item) {
            if (item.isSyncing === undefined) {
                this.$set(item, 'isSyncing', true);
            } else {
                item.isSyncing = true;
            }
            try {
                const endpoint = `/api/customer-invoices/send-to-accounting`;
                const data = {
                    invoiceIdList: [item.customerInvoiceId]
                };
                const payload = {
                    method: 'post',
                    data
                };
                await handleRequests(endpoint, payload);
            } catch (error) {
                item.isSyncing = false;
            }
        },
        onSelectAll(value) {
            if (value) {
                this.invoiceList.forEach((inv) => {
                    this.selectedInvoices.push(inv.customerInvoiceId);
                });
            } else {
                this.selectedInvoices = [];
            }
        },
        handleScroll(e) {
            const target = document.querySelector('.md-card-header');
            this.topScrollPosition = target.getBoundingClientRect().top + 12;
        },
        handleChangeInvoicesStatus(status) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Bulk Change Status',
                    body: `Are you sure you want to change the status of the invoices to ${status}?`,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'UPDATING...');

                        const payload = {
                            method: 'post',
                            data: {
                                invoices: this.selectedInvoices,
                                newStatus: status
                            }
                        };

                        try {
                            const api = `/api/customer-invoices/bulk/change-status`;
                            const response = await handleRequests(api, payload);
                            if (response.data.length) {
                                this.bulkListError = [];
                                this.bulkListError = response.data;
                            } else {
                                this.$notify({
                                    message: `${
                                        this.selectedInvoices.length
                                    } invoices had their status changed to ${status}!`,
                                    type: 'success'
                                });

                                if (this.isSelectAll) {
                                    this.isSelectAll = false;
                                }

                                this.selectedInvoices.forEach((x) => {
                                    const invoice = this.invoiceList.find((item) => item.customerInvoiceId === x);
                                    this.$set(invoice, 'status', status);
                                });
                                this.selectedInvoices = [];
                            }
                        } catch (e) {
                            const message = 'Cannot change invoices status.';
                            showErrorMessage(this, message, e);
                            this.resolve(false);
                        }
                    }
                    this.shouldCloseDropdown = true;
                    this.$_handleLoaderState(false);
                });

            // to toggle the context menu
            this.$nextTick(() => {
                this.shouldCloseDropdown = false;
            });
        },
        bulkDelete({ result, ids }) {
            const invoices = [...ids];
            if (result) {
                invoices.forEach((invoiceId) => {
                    this.removeSelected(invoiceId);
                });

                this.handlePageUpdate(true);
            } else {
                const message = 'There was an error deleting the invoices';
                showErrorMessage(this, message);
            }
        },
        removeSelected(invoiceId) {
            const ndx = this.selectedInvoices.findIndex((x) => x === invoiceId);
            if (ndx > -1) {
                this.selectedInvoices.splice(ndx, 1);
                this.isSelectAll = false;
            }
        },
        invoiceUpdated(customerInvoiceId, msg) {
            // from sidebar
            const fnd = this.invoiceList.find((element) => element.customerInvoiceId === customerInvoiceId);
            if (fnd === undefined) 
                return;

            if (msg) {
                this.$set(fnd, 'status', msg.status);
            }
        },
        handleDeletedInvoice({ result, invoiceId }) {
            this.isShowSidebar = false;
            this.removeSelected(invoiceId);
            if (result) {
                this.handleChangePage(1, this.pagination.perPage);
            }
        },
        async getTeamMembers(tripDate = null) {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS', {
                date: tripDate,
                teamRegionId: this.selectedTeamRegionId
            });
        },
        getFullNameFromTeamMemberId(id) {
            const fnd = this.teamMembers.find((element) => element.publicUserId === id);
            if (fnd === undefined) 
                return '';
            return fnd.fullName;
        },
        async getInvoiceList(pageNumber = 1, invoicesPerPage = 50) {
            try {
                this.isListLoading = true;
                const endpoint = `/api/customer-invoices/search`;
                const response = await handleRequests(endpoint, {
                    params: {
                        pageNumber,
                        invoicesPerPage,
                        ...this.cleanFilters()
                    }
                });

                const { invoices, totalInvoices, batchFilters, showCustomerInvoiceExcelData } = response.data;

                this.batchFilterList = batchFilters.map((item) => {
                    return { id: item.invoiceBatchId, label: this.formatDate(item.startDate, item.endDate) };
                });

                this.invoiceList = invoices;

                // Prevent sending of individual invoice to accounting package if there was a recent unfinished last sync attempt
                for (let i = 0; i < this.invoiceList.length; i++) {
                    const invoice = this.invoiceList[i];

                    if (
                        invoice.accountingSystemLastSyncAttemptDate &&
                        !invoice.accountingSystemRef &&
                        !invoice.accountingSystemNote
                    ) {
                        const now = moment.utc();
                        const accountingSystemLastSyncAttemptDate = moment.utc(
                            invoice.accountingSystemLastSyncAttemptDate
                        );

                        if (
                            now.diff(accountingSystemLastSyncAttemptDate, 'minutes') <
                            SEND_TO_ACCOUNTING_CONSTANTS.enableRetryAfterMinutes
                        ) {
                            invoice.isSyncing = true;
                            invoice.retryTooltip = 'Sending is still in progress. Please check again later.';
                        }
                    }
                }

                this.pagination.total = totalInvoices || this.invoiceList.length;
                this.maxPage = Math.ceil(this.pagination.total / this.pagination.perPage);
                this.showInvoiceDataExcelLink = showCustomerInvoiceExcelData;
            } catch (error) {
                const message = 'Error in getting the invoices list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;
        },
        formatDate(startDate, endDate) {
            const formattedStartDate = this.$options.filters.dateFormat(startDate, DATE_TYPES.standardDate);
            const formattedEndDate = this.$options.filters.dateFormat(endDate, DATE_TYPES.standardDate);

            return `${formattedStartDate} - ${formattedEndDate}`;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });

            return this.filters;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            await this.getInvoiceList(currentPage, perPage);
        },
        showSidebar(id) {
            this.isShowSidebar = true;
            this.customerInvoiceId = id;
        },
        toggleSidebarWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown)
                this.isShowSidebar = !this.isShowSidebar;
        },
        async handleFilterOrSearch(data) {
            this.filters = Object.assign(this.filters, data);
            this.handleChangePage(1, this.pagination.perPage);
        },
        async handleExportClick(data) {
            this.filters = Object.assign(this.filters, data);
            this.$_handleLoaderState(true, 'EXPORTING...');
            try {
                const endpoint = `/api/customer-invoices/export`;
                const payload = {
                    method: 'get',
                    responseType: 'blob',
                    params: {
                        ...this.cleanFilters()
                    }
                };

                const response = await handleRequests(endpoint, payload);
                downloadFile(response.data, 'customerinvoiceList.xlsx');
            } catch (error) {
                const message = 'Error in exporting invoices.';
                showErrorMessage(this, message, error);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        async setupSignalR() {
            const userCrendentials = await window.auth.getUser();

            this.connection = new signalR.HubConnectionBuilder()
                .withUrl('/api/InvoicingHub', {
                    accessTokenFactory: () => {
                        return userCrendentials.access_token;
                    }
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            try {
                await this.connection.start().then((result) => {
                    this.connection.invoke('JoinToTeamChannel');
                });

                this.connection.onclose(async () => {
                    await this.setupSignalR();
                });

                this.connection.on('CustomerInvoiceBatchCompleted', async (batchId, status, completedInvoiceCount) => {
                    this.isProgressComplete = true;

                    if (status === 'CompletedNoRecords') {
                        this.handleCloseProgressBox();

                        const resultMsg = 'Invoice batch completed but no uninvoiced stops found in range.';
                        this.$notify({
                            message: resultMsg,
                            type: 'success',
                            duration: 60000
                        });
                    } else {
                        this.handleCloseProgressBox();

                        const resultMsg = `${completedInvoiceCount} Invoices have been generated.`;
                        this.$notify({
                            message: resultMsg,
                            type: 'success',
                            duration: 60000
                        });
                    }
                });
                this.connection.on('CustInvoiceSentResult', this.handleInvoiceSentResult);
                this.connection.on('BulkCustInvoiceResult', this.handleBulkCustInvoiceResult);

                this.connection.on(
                    'CustomerInvoiceBatchFailed',
                    async (status, completedInvoiceCount, failedInvoiceCount) => {
                        this.isProgressComplete = true;
                        this.handleCloseProgressBox();

                        this.$notify({
                            message: `Completed Invoices: ${completedInvoiceCount} Failed Invoices: ${failedInvoiceCount}.<br /><br /> Please try again. If error persists please contact support.`,
                            type: 'warning',
                            duration: 10000
                        });
                    }
                );

                this.connection.on('CustomerTotalInvoicesToBeGenerated', this.handleTotalInvoiceToBeGenerated);
                this.connection.on('CustomerInvoicesGenerated', this.handleInvoiceGenerated);
                this.connection.on('CustomerIncompatibleLineTypes', async (customerName) => {
                    await this.handleCustomerIncompatibleLineTypes(customerName);
                });
                this.connection.on('CustomerHasNoLineTypes', async (customerName) => {
                    await this.handleCustomerNoLineTypes(customerName);
                });
            } catch (err) {
                setTimeout(this.setupSignalR, 5000);
            }
        },
        handlePdfGenerated(file) {
            downloadFile(file.data, 'customer-invoice.xlsx');
        },
        async handleSendToAccountingPackageClick() {
            this.selectedInvoices.forEach((invoiceId) => {
                const invoice = this.invoiceList.find((x) => x.customerInvoiceId === invoiceId);

                if (invoice.isSyncing === undefined) {
                    this.$set(invoice, 'isSyncing', true);
                } else {
                    invoice.isSyncing = true;
                }
            });

            try {
                const endpoint = `/api/customer-invoices/send-to-accounting`;
                const data = {
                    invoiceIdList: this.selectedInvoices
                };
                const payload = {
                    method: 'post',
                    data
                };
                await handleRequests(endpoint, payload);
            } catch (error) {
                const message = 'Error in sending the invoices to the accounting package';
                showErrorMessage(this, message, error);
                this.selectedInvoices.forEach((invoiceId) => {
                    const invoice = this.invoiceList.find((x) => x.customerInvoiceId === invoiceId);
                    invoice.isSyncing = false;
                });
            }
        },
        handleInvoiceSentResult(invoiceId, reference, note) {
            const invoice = this.invoiceList.find((x) => x.customerInvoiceId === Number(invoiceId));

            if (invoice == null) 
                return;

            if (reference != null) {
                invoice.accountingSystemRef = reference;
            } else {
                invoice.accountingSystemNote = note;
            }

            if (invoice.isSyncing === undefined) {
                this.$set(invoice, 'isSyncing', false);
            } else {
                invoice.isSyncing = false;
            }

            this.removeSelected(invoiceId);
        },
        async handleBulkCustInvoiceResult(result, invoices) {
            this.$_handleLoaderState(false);

            let successMessage = 'Successfully processed the invoices.';
            let errorMessage = 'Error in sending the invoices to the accounting package.';

            if (invoices?.length) {
                if (invoices.length === 1) {
                    successMessage = 'Successfully processed the invoice.';
                    errorMessage = 'Error in sending the invoice to the accounting package.';
                }

                for (let i = 0; i < invoices.length; i++) {
                    const currentInvoice = invoices[i];
                    const matchingInvoice = this.invoiceList.find(
                        (x) => x.customerInvoiceId === Number(currentInvoice.InvoiceId)
                    );

                    if (matchingInvoice) {
                        if (matchingInvoice.isSyncing === undefined) {
                            this.$set(matchingInvoice, 'isSyncing', false);
                        } else {
                            matchingInvoice.isSyncing = false;
                        }

                        matchingInvoice.accountingSystemRef = currentInvoice.Reference;
                        matchingInvoice.accountingSystemNote = currentInvoice.Note;
                    }
                }
            }

            if (result === 'Success') {
                this.$notifySuccess(successMessage, 10000);
            } else {
                this.$notifyError(errorMessage);
            }
        },
        onChangedInvoiceStatus(response) {
            const { customerInvoiceId, status } = response;
            const invoice = this.invoiceList.find((item) => item.customerInvoiceId === customerInvoiceId);
            this.$set(invoice, 'status', status);
        },
        handleSentInvoice() {},
        handlePageUpdate(response) {
            if (response) {
                this.handleChangePage(1, this.pagination.perPage);
            }
        },
        async processInvoicesBasedOnFilter() {
            this.isProcessAll = true;
            this.$_handleLoaderState(true, 'SENDING...');
            try {
                const payload = {
                    method: 'post',
                    params: {
                        ...this.cleanFilters()
                    }
                };
                const endpoint = `/api/customer-invoices/send-to-accounting/all`;
                await handleRequests(endpoint, payload);
                this.$_handleLoaderState(false);
                this.isProcessAll = false;
            } catch (error) {
                this.isProcessAll = false;
                const message = 'Error in sending the invoices to the accounting package';
                showErrorMessage(this, message, error);
                this.$_handleLoaderState(false);
            }
        },
        handleSentEmail(invoiceId) {
            const invoice = this.invoiceList.find((x) => x.customerInvoiceId === Number(invoiceId));
            if (invoice) {
                invoice.lastEmailDateSent = moment().format();
            }
        },
        handleBulkSentEmail(invoiceIdList) {
            invoiceIdList.forEach((invoiceId) => {
                this.handleSentEmail(invoiceId);
            });
        },
        handleTotalInvoiceToBeGenerated(total) {
            this.totalInvoices = Number(total);
        },
        async handleInvoiceGenerated(customerInvoiceId) {
            if (!customerInvoiceId) {
                return;
            }

            this.generatedInvoiceIds.push(customerInvoiceId);

            this.currentProcessedInvoices = this.currentProcessedInvoices + 1;

            // Debounced method: will only be actually executed once all the generated invoice ids have been sent from the server
            await this.tryPushGeneratedInvoices();
        },
        // eslint-disable-next-line func-names
        tryPushGeneratedInvoices: _.debounce(async function() {
            // If the number of the existing invoices plus the number of the generated invoices exceed the page capacity,
            // reload the page instead of just appending to the list
            const getSingleInvoicePromises = [];
            let shouldPushToInvoiceList = true;

            for (let i = 0; i < this.generatedInvoiceIds.length; i++) {
                if (i + 1 + this.invoiceList.length > this.pagination.perPage) {
                    shouldPushToInvoiceList = false;
                    break;
                }

                getSingleInvoicePromises.push(this.getSingleInvoice(Number(this.generatedInvoiceIds[i])));
            }

            this.generatedInvoiceIds = [];

            if (shouldPushToInvoiceList) {
                const invoices = await Promise.all(getSingleInvoicePromises);

                for (let i = 0; i < invoices.length; i++) {
                    const invoice = invoices[i];
                    if (invoice && invoice.invoiceNumber) {
                        this.invoiceList.push(invoice);
                    }
                }
            } else {
                this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
            }
        }, 1000),
        handleCloseProgressBox() {
            this.showProgressBox = false;
        },
        handleGenerateInvoice() {
            this.isProgressComplete = false;
            this.showProgressBox = true;
            this.currentProcessedInvoices = 0;
            this.totalInvoices = 0;
        },
        async getSingleInvoice(customerInvoiceId) {
            const endpoint = `/api/customer-invoices/${customerInvoiceId}`;
            const { data } = await handleRequests(endpoint);
            return data;
        },
        async handleCustomerIncompatibleLineTypes(customerName) {
            this.$notify({
                message: `Customer: <b>'${customerName}'</b>, has incompatible invoice line types, please check customer card to validate.`,
                type: 'warning',
                duration: 10000
            });
        },
        async handleCustomerNoLineTypes(customerName) {
            this.$notify({
                message: `Customer: <b>'${customerName}'</b>, has no invoice line types setup, please setup in customer card.`,
                type: 'warning',
                duration: 10000
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.no-result-message {
    text-align: center;
    margin-top: 45px;
    font-size: 16px;
}

::v-deep .status-container {
    .md-table-cell-container {
        overflow: visible;
    }
}

::v-deep .rating-container {
    .ratings {
        display: flex;
        align-items: flex-end;

        > {
            display: inline-block;
        }

        .md-icon {
            margin: 0;
        }
    }
}

.slide-leave-active,
.slide-enter-active {
    transition: 0.3s;
}

.slide-enter {
    transform: translateX(100%);
}

.slide-leave-to {
    transform: translateX(100%);
}

.content {
    margin-top: -15px;

    ::v-deep .md-card.md-theme-default {
        margin-bottom: 0;
    }
}

::v-deep .filter-steps--container {
    margin-top: 6px;

    .filter-steps--choices {
        margin-top: 0;
        padding-bottom: 0;

        > .md-input {
            display: initial;
        }
    }
}

::v-deep .filter-steps--container.tablet-filter-container {
    width: initial;
    float: left;
    margin-left: 20px;
}

::v-deep .search--container {
    width: 250px;
    float: right;
    margin-top: 6px;
}

::v-deep .md-table-cell-container {
    max-width: 205px;

    .md-button.md-round.md-just-icon {
        box-shadow: none;
    }
}

.custom-hidden-tablet {
    display: none;
}

.address-container {
    display: none;
}

@media (min-width: 1400px) {
    .address-container {
        display: table-cell;

        ::v-deep .md-table-cell-container {
            max-width: 300px;
        }
    }
}

::v-deep .md-table-cell {
    padding: 0px 8px;

    &:last-child {
        .md-table-cell-container {
            text-align: left !important;
            justify-content: flex-start;
        }
    }
}

::v-deep .md-table-row {
    &.zero-results {
        padding: 16px;
        text-align: center;
    }

    .custom-button-container .custom-a-blue a {
        color: rgba(0, 0, 0, 0.87);
    }
}

::v-deep .md-table-row:hover {
    background-color: #fafafa;
    cursor: pointer;
}

::v-deep .md-toolbar {
    .md-field {
        &:first-child,
        &:last-child {
            margin: 8px;
        }
    }

    .stops-search {
        padding-bottom: 10px;

        &::before,
        &::after {
            bottom: 10px;
        }
    }
}

::v-deep .md-card-actions {
    padding: 16px;

    &.page-footer {
        padding-top: 0px;
    }
}

::v-deep .header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.gray-text {
    color: rgba(0, 0, 0, 0.5);
}

.loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

.action-buttons {
    button,
    ::v-deep button {
        margin: 0 2px;
    }

    button:last-child {
        margin-right: 0;
    }
}

.checkbox-head {
    margin-top: 4px;
}

.body-list {
    position: relative;

    .sticky {
        position: fixed;
        top: 0;
        z-index: 9;
        left: 75px;
    }
}

.bulk-section {
    position: absolute;
    top: -35px;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    background-color: #cfeefa;
    color: #2b93ff;
    font-weight: 400;
    padding: 5px 10px 5px 20px;

    > div {
        width: 40%;
        display: inline-block;
        vertical-align: middle;
        text-align: left;
    }

    > div:last-child {
        width: 60%;
        text-align: right;

        button {
            height: 30px;
            width: 30px;
            min-width: 30px;
        }

        .dropdown {
            width: 180px;
            background-color: #ff9800 !important;
            display: inline-block;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
            height: 30px;
            line-height: 30px;
            margin-top: 6px;
            cursor: pointer;
        }
    }
}

.bulk-error-message {
    z-index: 1 !important;

    > div {
        max-height: 160px;
        overflow: auto;
    }

    .bulk-single-container {
        display: table;

        > div {
            display: table-cell;
        }

        > div:first-child {
            width: 200px;
        }
    }
}

.icon-warning {
    color: #ff9800 !important;
    cursor: pointer;
    left: 14px;
}

@media (max-width: 992px) {
    .tablet-button-margin {
        margin-right: 10px;
    }
}

.invoice-ref {
    color: rgba(0, 0, 0, 0.87) !important;
}
.invoice-ref:hover {
    color: #2b93ff !important;
}

.progress-container {
    min-width: 200px;
    max-width: 250px;
    background-color: #fff;
    position: fixed;
    right: 12px;
    bottom: 8px;
    z-index: 99;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    padding: 0 10px 10px 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    .progress-text-section {
        display: table;
        padding-top: 12px;
        > div {
            display: table-cell;
            vertical-align: middle;
        }

        > div:last-child {
            padding-left: 10px;
        }
    }

    ::v-deep .md-button {
        height: 20px;
        min-width: 20px;
        width: 20px;
        position: absolute;
        top: -12px;
        right: -12px;

        i {
            font-size: 15px !important;
        }
    }
}

.green-check {
    color: #4caf50 !important;
}

.route-loader {
    top: 20% !important;
    left: 0 !important;
}

.route-loader-text {
    left: 37px;
    position: absolute;
    top: 13px;
}

.btn-loader {
    border: 4px solid #eeeeee;
    border-radius: 50%;
    border-top: 4px solid #2b93ff;
    width: 24px;
    height: 24px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    top: 85%;
    left: 48%;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
