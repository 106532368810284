<template>
    <div class="map-footer">
        <button
            v-if="!$root.isDesktop"
            :class="['map-panel-list-button', isActive ? 'open-button' : 'close-button']"
            @click="handleToggle"
            :title="isActive ? 'Open team member and asset groups panel' : 'Close team member and asset groups panel'"
        >
            <span v-if="isActive">Team Members / Asset Groups</span>
            <md-icon v-if="isActive" class="icon-color">chevron_right</md-icon>
            <md-icon v-else class="icon-color">chevron_left</md-icon>
        </button>
        <div v-if="!isActive || $root.isDesktop" class="map-panel">
            <tabs
                :tab-name="tabs"
                :selected-panel="activePanel"
                :is-loading="Boolean(false)"
                class="group--tabs"
                @switch-panel="handleSwitchPanel"
            >
                <template slot="tab-pane-1">
                    <!--<team-region-member-filter-options
                    v-if="canViewTeamRegion && teamRegions && teamRegions.length > 0"
                    @changed="handleTeamRegionChanged"
                    :team-region-id="selectedTeamRegionId"
                    :show-team-member="false"
                    :clearable="false"
                    :strict-team-region-filter="true"
                    :use-in-map="true"
                    display="block"
                    class="search--container"
                />-->
                    <div class="search--container" v-if="drivers.length > 5">
                        <md-field md-clearable @md-clear="handleClear">
                            <md-input type="text" placeholder="Search" v-model="searchText" />
                            <md-icon>search</md-icon>
                        </md-field>
                    </div>
                    <ul>
                        <li
                            v-for="driver in filteredMembers"
                            :key="driver.email"
                            :class="{
                                isPinned: driver.publicUserId === pinnedUser
                            }"
                            :style="{
                                '--color': driver.publicUserId === pinnedUser ? driver.markerPins.color : 'black'
                            }"
                            @click="pinUser(driver)"
                        >
                            <span class="name">{{ driver.firstName }} {{ driver.lastName }}</span>
                            <span class="zoom" @click.stop="zoomToDriver(driver)">
                                <md-icon class="footer-icon">my_location</md-icon>
                            </span>
                            <tracking-location-data-indicator
                                direction="right"
                                :data="driver.routeDetails"
                                :render-key="renderKey"
                            />
                            <md-icon class="footer-icon user-pin">push_pin</md-icon>
                        </li>
                    </ul>
                    <span class="error-text" v-if="drivers.length === 0">No team members found.</span>
                    <span class="error-text" v-else-if="filteredMembers.length === 0">
                        There is no team member named '{{ searchText }}'.
                    </span>
                </template>
                <template slot="tab-pane-2">
                    <div class="search--container" v-if="assetGroups.length > 5">
                        <md-field md-clearable @md-clear="handleClear">
                            <md-input type="text" placeholder="Search" v-model="searchText" ref="searchBar" />
                            <md-icon>search</md-icon>
                        </md-field>
                    </div>
                    <ul>
                        <li
                            v-for="assetGroup in filteredMembers"
                            :key="assetGroup.assetGroupId"
                            :class="{
                                isPinned: assetGroup.trackerReference === pinnedUser
                            }"
                            :style="{
                                '--color':
                                    assetGroup.trackerReference === pinnedUser ? assetGroup.markerPins.color : 'black'
                            }"
                            @click="pinUser(assetGroup)"
                        >
                            <span class="name" v-overflow-tooltip>
                                <span>{{ assetGroup.groupName }}</span>
                                <span class="owner-name" v-if="assetGroup.ownerName">
                                    &nbsp;({{ assetGroup.ownerName }})
                                </span>
                            </span>
                            <span class="zoom" @click.stop="zoomToDriver(assetGroup)">
                                <md-icon class="footer-icon">my_location</md-icon>
                            </span>
                            <tracking-location-data-indicator
                                icon="drive_eta"
                                direction="right"
                                :data="assetGroup.routeDetails"
                                :render-key="renderKey"
                            />
                            <md-icon class="footer-icon user-pin">push_pin</md-icon>
                        </li>
                    </ul>
                    <span class="error-text" v-if="assetGroups.length === 0">No asset groups found.</span>
                    <span class="error-text" v-else-if="filteredMembers.length === 0">
                        There is no asset group named '{{ searchText }}'.
                    </span>
                </template>
            </tabs>
            <!-- if there are no asset groups, just display the team -->
        </div>
        <!-- <div v-else class="btn-mobile-container" @click.stop="showFilterMobileModal">
        <div class="btn-image-mobile" v-if="selected.name != 'All' && selected.type === 'Team Members'">
            <div class="mobile-table-cell">
                <div class="filter-image">
                    <img v-if="selected.photoUrl != null" :src="selected.photoUrl" alt="" />
                    <img v-else :src="$root.defaultPhotoUrl" alt="" />
                </div>
                <div class="filter-name">{{ selected.name }}</div>
            </div>
        </div>
        <div v-else>
            <div class="mobile-table-cell">
                <div :class="selected.name != 'All' ? 'small-btn-mobile' : ''">
                    <md-button title="Filter" class="md-primary md-just-icon md-round">
                        <md-icon v-if="selectedPanel === 'Team Members'">people</md-icon>
                        <md-icon v-else>drive_eta</md-icon>
                    </md-button>
                </div>
                <div class="filter-name" v-if="selected.name != 'All'">{{ selected.name }}</div>
            </div>
        </div>
    </div> -->
    </div>
</template>

<script>
import moment from 'moment';
import useMapUtils from '@/compostables/useMapUtils';
import { Tabs, TrackingLocationDataIndicator } from '@/components';
// import MapFilterMemberAndGroupModal from '@/pages-mobile/Map/MapFilterMemberAndGroupModal';

import { computed, inject, onMounted, watch, ref, toRefs } from '@vue/composition-api';

export default {
    name: 'TeamMembersList',
    props: {
        drivers: {
            type: Array,
            default: () => []
        },
        assetGroups: {
            type: Array,
            default: () => []
        },
        renderKey: {
            type: Number,
            default: 0
        }
    },
    components: {
        TrackingLocationDataIndicator,
        Tabs
    },
    mounted() {
        this.$nextTick(() => {
            const userFromParam = this.$route.params.userId;
            if (userFromParam) {
                this.pinUser(
                    {
                        publicUserId: userFromParam
                    },
                    true
                );
            }
        });
    },
    setup(props, context) {
        const { getters, commit, dispatch } = inject('vuex-store');
        const { callMapFunction } = useMapUtils(context);
        const { drivers, assetGroups, renderKey } = toRefs(props);
        const tabs = ['Team Members', 'Asset Groups'];
        const map = inject('map');
        const activePanel = computed(() => getters['map/activePanel']);
        const pinnedUser = computed(() => getters['map/pinnedUser']);
        const date = computed(() => getters['map/date']);
        // const user = getters['user/user'];
        const filteredMembers = ref([]);
        const searchText = ref('');
        const panelObj = {
            'Team Members': drivers.value,
            'Asset Groups': assetGroups.value
        };
        const teamRegionId = computed(() => getters['map/teamRegionId']);
        const selectedTeamRegionId = ref(null);
        const teamRegions = computed(() => getters['team/teamRegions']);
        const canViewTeamRegion = computed(() => getters['user/canViewTeamRegion']);
        const isActive = ref(true);

        onMounted(() => {
            filteredMembers.value = [...panelObj[activePanel.value]];
            // selectedTeamRegionId.value = teamRegionId.value ? teamRegionId.value : null; // All
            selectedTeamRegionId.value = null; // All
        });

        const handleClear = () => {
            searchText.value = '';
        };

        // method to check if lat and long are 0,0
        const isNullIsland = (user) => {
            const latitude = user.routeDetails?.coordinates?.latitude;
            const longitude = user.routeDetails?.coordinates?.longitude;
            return latitude === 0 && longitude === 0;
        };

        const handleZeroZero = () => {
            context.root.$notify({
                message: `Location details are not available for this device`,
                type: 'warning'
            });
        };

        watch(searchText, (newVal) => {
            if (!newVal) 
                filteredMembers.value = [...panelObj[activePanel.value]];
            else {
                filteredMembers.value = [...panelObj[activePanel.value]].filter((d) => {
                    const str =
                        activePanel.value === 'Team Members'
                            ? `${d.firstName?.toLowerCase() ?? ''} ${d.lastName?.toLowerCase() ?? ''}`
                            : d.groupName.toLowerCase() ?? '';
                    return str.includes(newVal.toLowerCase());
                });
            }
        });

        watch(activePanel, (newVal) => {
            filteredMembers.value = [...panelObj[newVal]];
        });

        const handleSwitchPanel = (payload) => {
            selectedTeamRegionId.value = teamRegionId.value;
            commit('map/CHANGE_ACTIVE_PANEL', payload);
            handleClear();
            if (pinnedUser.value) {
                commit('map/UNPIN_DRIVER');
                const { params } = context.root.$router.currentRoute;
                if (moment(params.date).isSame(moment(), 'day')) {
                    context.root.$router.replace({ path: `/map` });
                } else {
                    context.root.$router.replace({ path: `/map/${params.date}` });
                }
            }
        };

        const pinUser = (user, forcePin) => {
            // check for 0,0
            if (isNullIsland(user)) {
                handleZeroZero();
                return;
            }

            const userId = user.isAssetGroup ? user.trackerReference : user.publicUserId;
            // Add user public ID to params

            let { params } = context.root.$router.currentRoute;

            if (pinnedUser.value === userId && !forcePin) {
                if (moment(params.date).isSame(moment(), 'day')) {
                    context.root.$router.replace({ path: `/map` });
                } else {
                    context.root.$router.replace({ path: `/map/${params.date}` });
                }
                commit('map/UNPIN_DRIVER');
            } else {
                // Unpin the user, before that reset the url
                if (!params.date || !params.userId) {
                    params.date = moment(date.value).format('YYYY-MM-DD');
                    params.userId = userId;
                    context.root.$router.replace({ path: `/map/${params.date}/user/${params.userId}` });
                } else {
                    params = { ...params, userId };
                    context.root.$router.push({ params });
                }

                // fetch trip details and location history
                dispatch('map/PIN_DRIVER', {
                    userId,
                    date: moment(date.value).format('YYYY-MM-DD'),
                    isAssetGroup: user.isAssetGroup
                });

                if (user) {
                    context.emit('changeLoading', true);

                    dispatch('map/FETCH_TIMESERIES_DATA', {
                        userId,
                        tripDate: moment(date.value).format('YYYY-MM-DD')
                    }).then(() => {
                        context.emit('changeLoading', false);
                    });
                }
            }
        };

        const zoomToDriver = (driver) => {
            if (isNullIsland(driver)) {
                handleZeroZero();
                return;
            }
            if (!driver.routeDetails) 
                return;
            const userId = driver.publicUserId || driver.trackerReference;
            const {
                routeDetails: {
                    coordinates: { latitude, longitude }
                }
            } = driver;
            if (pinnedUser.value && pinnedUser.value !== userId) 
                return;
            callMapFunction(map.value, 'panTo', [{ lat: latitude, lng: longitude }]);
            callMapFunction(map.value, 'setZoom', [14]);
        };

        const filterList = async () => {
            panelObj['Team Members'] = drivers.value;
            panelObj['Asset Groups'] = assetGroups.value;
            filteredMembers.value = activePanel.value === 'Team Members' ? drivers.value : assetGroups.value;
        };

        // updating the last location update of the members;
        watch(renderKey, () => {
            panelObj['Team Members'] = drivers.value;
            panelObj['Asset Groups'] = assetGroups.value;
            filteredMembers.value = activePanel.value === 'Team Members' ? drivers.value : assetGroups.value;
            if (searchText.value) {
                // if there is a value in the search text it needs to keep the list based on the search filter
                filteredMembers.value = [...panelObj[activePanel.value]].filter((d) => {
                    const str =
                        activePanel.value === 'Team Members'
                            ? `${d.firstName?.toLowerCase() ?? ''} ${d.lastName?.toLowerCase() ?? ''}`
                            : d.groupName.toLowerCase() ?? '';
                    return str.includes(searchText.value.toLowerCase());
                });
            }
        });

        watch(drivers, () => {
            filterList();
        });

        const unpinUser = () => {
            const { params } = context.root.$router.currentRoute;
            if (moment(params.date).isSame(moment(), 'day')) {
                context.root.$router.replace({ path: `/map` });
            } else {
                context.root.$router.replace({ path: `/map/${params.date}` });
            }
            commit('map/UNPIN_DRIVER');
        };

        const handleTeamRegionChanged = (args) => {
            const prevTeamRegionId = selectedTeamRegionId.value;
            commit('map/SET_TEAM_REGION_ID', args.teamRegionId);
            context.emit('teamRegionChanged', args);
            handleClear();

            if (prevTeamRegionId !== args?.teamRegionId) {
                unpinUser();
            }
        };

        const handleToggle = () => {
            isActive.value = !isActive.value;
        };

        return {
            isActive,
            handleToggle,
            searchText,
            filteredMembers,
            handleClear,
            pinnedUser,
            pinUser,
            zoomToDriver,
            activePanel,
            tabs,
            handleSwitchPanel,
            isNullIsland,
            teamRegionId,
            selectedTeamRegionId,
            teamRegions,
            canViewTeamRegion,
            handleTeamRegionChanged,
            filterList
        };
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';

.map-footer-mobile {
    // should not be aligned to the right side as
    // Jivo chat has this transparent div on the lower right that will
    // interfere with event clicks if it overlaps.
    position: absolute;
    bottom: 15px;
    left: 20px;
}

.map-footer {
    position: absolute;
    bottom: 25px;
    left: 20px;
    width: 300px;
    height: auto;
    max-height: 340px;

    .group--tabs ::v-deep {
        margin: 0;
        .md-card-header {
            padding: 0 !important;
        }
        .md-card-content {
            padding: 0 !important;
            .md-list {
                padding: 0 !important;

                .md-list-item {
                    margin: 0;
                    width: 50%;
                    border-bottom: 1px solid $locate2u-blue-base;
                    &.active {
                        button,
                        button:hover {
                            border-radius: 0 !important;
                            background-color: $locate2u-blue-base !important;
                            color: white !important;
                        }
                    }
                    button {
                        border-radius: 0 !important;
                        background-color: $white !important;
                        color: black !important;
                    }
                }
            }

            .tab-content {
                margin-top: 8px;
                padding: 8px;
                overflow-y: auto;
                max-height: 270px;
            }
        }
    }

    h5 {
        margin-top: 0;
        font-size: 12px;
        font-weight: 800;
        margin-bottom: 5px;
        background-color: #2b93ff;
        color: rgb(161, 94, 94);
        padding: 10px;

        span {
            float: right;
            cursor: pointer;
        }
    }

    .team-panel {
        background: rgba(255, 255, 255, 0.9);
        padding-top: 10px;
        margin-top: 8px;
        padding: 8px;
        overflow-y: auto;
        max-height: 195px;
    }
    ul {
        padding: 0 10px;
        padding-top: 0;
        margin: 0;
        list-style: none;
        font-size: 14px;

        li {
            cursor: pointer;
            font-size: 14px;
            display: flex;
            &:hover {
                font-weight: bold;
            }

            .name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .owner-name {
                font-weight: 100 !important;
            }

            .HB-icon {
                margin-left: auto;
                margin-right: 5px;
            }

            .zoom {
                margin-left: auto;
            }

            .md-icon {
                width: 14px;
                height: 14px;
                font-size: 14px !important;
                margin-right: 0px;
                margin-left: 0px;
                &.user-pin {
                    transform: rotate(90deg);
                }
            }
        }
        .pushed-right {
            margin-left: 5px !important;
        }
    }
    ::v-deep .isPinned {
        color: var(--color) !important;
        font-weight: bold;

        .md-icon {
            color: var(--color) !important;
        }

        .user-pin {
            transform: rotate(0deg) !important;
        }
    }

    .map-panel {
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
        border-radius: 3px;
        height: 100%;
    }
}
.map-panel-list-button {
    background-color: #fff;
    border: 0px;
    border-radius: 5px;
    height: 45px;
    cursor: pointer;
    bottom: 20px;
    position: absolute;

    .icon-color {
        color: #2b93ff;
        font-weight: 400;
    }
}
.open-button {
    float: left;
}
.close-button {
    position: absolute;
    left: 301px;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-radius: 0;
    bottom: auto;
}

.map-panel-mobile {
    ::v-deep .tab-content {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.mobile-panel {
    span:first-child {
        font-weight: 600;
    }
    padding: 10px 10px;
}

.btn-image-mobile {
    > div > .filter-image {
        background-color: #2b93ff;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
        height: 30px;
        width: 30px;
        border-radius: 50%;
        img {
            border-radius: 50%;
        }
    }
}
.btn-mobile-container {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin: 5px 0 20px 0;
    display: inline-block;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    .filter-name {
        font-weight: 600;
        font-size: 0.8rem;
        line-height: 16px;
        margin-bottom: 5px;
        display: block;
        padding-left: 5px;
    }

    .small-btn-mobile {
        > button {
            height: 30px;
            width: 30px;
            min-width: 30px;
        }
    }
}

.search--container {
    // we put a negative margin so that we don't affect the normal display of the members or asset groups when there is no searchbar.
    margin-top: -8px;
    padding-left: 12px;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    ::v-deep .md-field {
        box-sizing: border-box;
        margin-top: 0;
        &::before,
        &::after {
            bottom: 10px;
        }
        &.md-focused {
            .md-icon {
                color: #4ba3ff;
                fill: #4ba3ff;
            }
        }
        .md-icon {
            color: #d2d2d2;
            fill: #d2d2d2;
            margin-bottom: 0 !important;
            margin-top: 6px !important;
            font-size: 18px !important;
        }
    }

    .md-input {
        padding-top: 0;
        padding-bottom: 0;

        &::placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder,
        &::-webkit-input-placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #d2d2d2 !important;
            opacity: 1 !important; /* Firefox */
        }
    }
}

.error-text {
    padding-left: 4px;
    color: #ff5245;
}

.mobile-table-cell {
    display: table;
    > div {
        display: table-cell;
    }
    .md-round {
        margin: 0 !important;
    }
}
</style>
