var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.assetGroupDetails != null
    ? _c("div", { staticClass: "details-page" }, [
        _c("div", { staticClass: "md-layout" }, [
          _c("div", { staticClass: "md-layout-item" }, [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c("div", { staticClass: "custom-toolbar-start" }, [
                _vm.assetGroupDetails.groupName != null
                  ? _c(
                      "h3",
                      { staticClass: "title" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.assetGroupDetails.groupName) +
                            "\n                        "
                        ),
                        _c(
                          "badge",
                          {
                            staticClass: "custom-badge",
                            attrs: { type: "warning" },
                          },
                          [_vm._v("Asset Group")]
                        ),
                        _vm.assetGroupDetails.disabledDate != null
                          ? _c(
                              "badge",
                              {
                                staticClass: "custom-badge",
                                attrs: { type: "danger" },
                              },
                              [
                                _vm._v(
                                  "\n                            Disabled\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "h3",
                      { staticClass: "title" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.assetGroupDetails.assetGroupId) +
                            "\n                        "
                        ),
                        _c(
                          "badge",
                          {
                            staticClass: "custom-badge",
                            attrs: { type: "warning" },
                          },
                          [_vm._v("Asset Group")]
                        ),
                        _vm.assetGroupDetails.disabledDate != null
                          ? _c(
                              "badge",
                              {
                                staticClass: "custom-badge",
                                attrs: { type: "danger" },
                              },
                              [
                                _vm._v(
                                  "\n                            Disabled\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
              ]),
              !_vm.$root.isMobileOnly
                ? _c("div", { staticClass: "custom-toolbar-end" }, [
                    _c("div", { staticClass: "header-button-container" }),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "md-layout md-alignment-top-left content-layout-panel",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-66 md-xlarge-size-50",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-blue",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card-icon custom-header-icon-text",
                              },
                              [
                                _c("md-icon", [_vm._v("description")]),
                                _vm._v(
                                  "\n                            Asset Group Details\n                        "
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("md-card-content", [
                          _c(
                            "div",
                            { staticClass: "asset-details-box details-box" },
                            [
                              _c("div", [
                                _c("label", [_vm._v("Asset Group Name")]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.assetGroupDetails.groupName) +
                                      "\n                                "
                                  ),
                                ]),
                              ]),
                              _vm.assetGroupDetails.disabledDate != null
                                ? _c("div", [
                                    _c("label", [_vm._v("Disabled Date")]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm._f("dateTimeFormat")(
                                              _vm.assetGroupDetails
                                                .disabledDate,
                                              _vm.DATE_TYPES.standardDate
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c("div", [
                            _c("div", { staticClass: "line-separator" }),
                            _c("h4", { staticClass: "small-details-title" }, [
                              _vm._v("Connected Assets"),
                            ]),
                            _vm.assetGroupDetails.connectedAssets.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.assetGroupDetails.connectedAssets,
                                    function (item, index) {
                                      return _c("div", { key: index }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "value icon-asset-name",
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "md-icon",
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm.$_getAssetIcon(
                                                            item.type
                                                          )
                                                        ) +
                                                        "\n                                                "
                                                    ),
                                                    _c(
                                                      "md-tooltip",
                                                      {
                                                        attrs: {
                                                          "md-direction":
                                                            "right",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(item.type) +
                                                            "\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "Asset Details",
                                                        params: {
                                                          assetId: item.assetId,
                                                        },
                                                      },
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(item.name) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _c("div", [_vm._v("No connected assets")]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("asset-group-history", {
                  attrs: {
                    "asset-logs": _vm.assetLogs,
                    "asset-group-id": _vm.assetGroupDetails.assetGroupId,
                  },
                }),
              ],
              1
            ),
            _vm.$root.isDesktop
              ? _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-layout md-medium-size-100 md-large-size-33 md-xlarge-size-50",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50",
                      },
                      [
                        _c(
                          "md-card",
                          [
                            _c(
                              "md-card-header",
                              {
                                staticClass:
                                  "md-card-header-icon md-card-header-warning",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "card-icon custom-header-icon-text",
                                  },
                                  [
                                    _c("md-icon", [_vm._v("fact_check")]),
                                    _vm._v(
                                      "\n                            Asset Logs\n                        "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("md-card-content", [
                              _c("div", [_vm._v("No logs available")]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }