var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLocationHistoryToggled &&
    !_vm.isStreetView &&
    !_vm.isReadOnlyUser
    ? _c(
        "div",
        [
          _vm.isPlaying && _vm.paths.length
            ? _c("RoutePlaybackPath", {
                attrs: {
                  paths: _vm.paths,
                  "pinned-driver-details": _vm.pinnedDriverDetails,
                },
              })
            : _vm._e(),
          _vm.currentMarkerPos !== null
            ? _c("RoutePlaybackMarker", {
                attrs: {
                  "current-marker-pos": _vm.currentMarkerPos,
                  "pinned-driver-details": _vm.pinnedDriverDetails,
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              class: {
                playback: true,
                individual: _vm.isIndividual,
                "map-panel": true,
              },
              style: { width: _vm.currentTime ? "220px" : "100px" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "slow-button",
                  on: {
                    click: function ($event) {
                      return _vm.onPlayButtonClick(0.75)
                    },
                  },
                },
                [
                  _c(
                    "md-icon",
                    [
                      _vm._v("\n                fast_rewind\n                "),
                      _c("md-tooltip", { attrs: { "md-direction": "top" } }, [
                        _vm._v(_vm._s(_vm.tooltipOptions.slow)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "play-button",
                  on: {
                    click: function ($event) {
                      return _vm.onPlayButtonClick(1)
                    },
                  },
                },
                [
                  _c(
                    "md-icon",
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.isPlaying
                              ? "pause_circle_outline"
                              : "play_circle_outline"
                          ) +
                          "\n                "
                      ),
                      _c("md-tooltip", { attrs: { "md-direction": "top" } }, [
                        _vm._v(_vm._s(_vm.tooltipOptions.play)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "fast-button",
                  on: {
                    click: function ($event) {
                      return _vm.onPlayButtonClick(1 / 0.75)
                    },
                  },
                },
                [
                  _c(
                    "md-icon",
                    [
                      _vm._v(
                        "\n                fast_forward\n                "
                      ),
                      _c("md-tooltip", { attrs: { "md-direction": "top" } }, [
                        _vm._v(_vm._s(_vm.tooltipOptions.play)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("span", { staticClass: "playbackTime" }, [
                _vm._v(_vm._s(_vm.currentTime)),
              ]),
            ]
          ),
          _c(
            "div",
            {
              class: {
                slider: true,
                individual: _vm.isIndividual,
                "map-panel": true,
              },
              on: {
                click: function ($event) {
                  return _vm.handleSliderOnClick($event)
                },
              },
            },
            [
              _c("vue-slider", {
                ref: "slider",
                attrs: {
                  marks: _vm.marks,
                  min: 0,
                  max: 1440,
                  lazy: true,
                  duration: 0,
                  "dot-options": _vm.dotOptions,
                  clickable: false,
                  process: _vm.process,
                  order: false,
                },
                on: {
                  dragging: _vm.dragging,
                  "drag-start": _vm.canDragSliderDot,
                  "drag-end": function ($event) {
                    _vm.isDragging = false
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "dot",
                      fn: function (ref) {
                        var value = ref.value
                        var focus = ref.focus
                        var index = ref.index
                        return [
                          index === 2
                            ? _c("div", {
                                class: ["custom-dot", { index: index }],
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "tooltip",
                      fn: function (ref) {
                        var value = ref.value
                        var focus = ref.focus
                        return [
                          _c(
                            "div",
                            { class: ["custom-tooltip", { focus: focus }] },
                            [_vm._v(_vm._s(_vm.tooltipFormat(value)))]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1117776052
                ),
                model: {
                  value: _vm.values,
                  callback: function ($$v) {
                    _vm.values = $$v
                  },
                  expression: "values",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }