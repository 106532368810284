var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-container" }, [
    _c(
      "div",
      { staticClass: "close-button" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-default md-just-icon md-round pull-right",
            on: { click: _vm.closeModal },
          },
          [_c("md-icon", [_vm._v("close")])],
          1
        ),
      ],
      1
    ),
    !_vm.loading
      ? _c("div", [
          _c("div", { staticClass: "detail-section text-center" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.ruleDetails.description)),
            ]),
          ]),
          _c("div", { staticClass: "line-separator" }),
          _c("div", { staticClass: "detail-section" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("Invoice Adjustment Rule Details"),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Rule Type:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.ruleDetails.invoiceAdjustmentRuleTypeName)),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Tax Settings:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  _vm._s(
                    _vm.displayNullableString(
                      _vm.ruleDetails.taxSettingsDescription
                    )
                  )
                ),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Description:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.ruleDetails.description)),
              ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Unit Price:")]),
              _vm.user.teamCurrency
                ? _c("span", { staticClass: "value" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("currency")(
                            _vm.ruleDetails.unitPrice,
                            _vm.user.teamCurrency
                          )
                        ) +
                        "\n                "
                    ),
                  ])
                : _c("span", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm.formatCurrency(_vm.ruleDetails.unitPrice))
                    ),
                  ]),
            ]),
            _c("div", { staticClass: "detail-group" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Account Code:")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  _vm._s(_vm.displayNullableString(_vm.ruleDetails.accountCode))
                ),
              ]),
            ]),
          ]),
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "sidebar-loader" },
            [
              _c("fade-loader", {
                attrs: { loading: _vm.loading, color: "#333333" },
              }),
              _c("span", [_vm._v("LOADING")]),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }