<template>
    <md-button
        title="Delete asset details"
        :class="useIcon ? 'md-danger md-just-icon md-round' : `${className}`"
        @click.stop="handleDeleteAsset"
    >
        <md-icon v-if="useIcon">delete</md-icon>
        <span v-else>Delete</span>
    </md-button>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';

export default {
    name: 'DeleteAssetButton',
    props: {
        asset: {
            type: Object,
            default: () => {}
        },
        className: {
            type: String,
            default: () => ''
        },
        useIcon: {
            type: Boolean,
            default: true
        }
    },
    mixins: [GeneralMixin],
    methods: {
        handleDeleteAsset() {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Tracker',
                    body: 'Are you sure you want to delete this tracker?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmDelete(this.asset.assetId);
                });
        },
        async handleConfirmDelete(assetId) {
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'delete'
            };
            const api = `/api/assets/${assetId}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Tracker was deleted!',
                    type: 'success'
                });
                this.$_handleLoaderState(false);
                this.$emit('onAssetDelete', true);
            } catch (e) {
                const message = 'Error in deleting a tracker.';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        }
    }
};
</script>
