var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "detail-container" }, [
      _c(
        "div",
        { staticClass: "close-button" },
        [
          _c(
            "md-button",
            {
              staticClass: "md-default md-just-icon md-round pull-right",
              on: { click: _vm.closeModal },
            },
            [_c("md-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      !_vm.loading
        ? _c("div", [
            _c("div", { staticClass: "detail-section text-center" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.sidebarData.maintenanceName) +
                    "\n                "
                ),
              ]),
            ]),
            _c("div", { staticClass: "line-separator" }),
            _c("div", { staticClass: "detail-section" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v("Maintenance Schedule Details"),
              ]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Type")]),
                _c("span", { staticClass: "value" }, [
                  _c(
                    "span",
                    {
                      staticClass: "badge",
                      class:
                        "status-" +
                        _vm.$_getAssetLogTypeBadgeColor(
                          _vm.sidebarData.maintenanceType
                        ),
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "assetLogTypes." + _vm.sidebarData.maintenanceType
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Subtype")]),
                _c("span", { staticClass: "value" }, [
                  _c(
                    "span",
                    {
                      staticClass: "badge",
                      class:
                        "status-" +
                        _vm.$_getAssetLogSubTypeBadgeColor(
                          _vm.sidebarData.maintenanceType,
                          _vm.sidebarData.maintenanceSubType
                        ),
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "assetLogSubTypes." +
                                _vm.sidebarData.maintenanceSubType
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Description")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.sidebarData.notes)),
                ]),
              ]),
            ]),
            _vm.cronExpressionText != null
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.sidebarData.timeSchedule != null ||
            _vm.sidebarData.timeScheduleInterval != null
              ? _c("div", { staticClass: "detail-section" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Time based reminders"),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "value" }, [
                      _vm.sidebarData.timeSchedule != null
                        ? _c("div", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.cronExpressionText) +
                                "\n                        "
                            ),
                          ])
                        : _vm._e(),
                      _vm.sidebarData.timeScheduleInterval != null
                        ? _c("div", [
                            _vm._v(
                              "\n                            every " +
                                _vm._s(_vm.sidebarData.timeScheduleInterval) +
                                " " +
                                _vm._s(_vm.sidebarData.timeScheduleUnitType) +
                                "\n                        "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "value" }, [
                      _c("div", {}, [
                        _vm.getBeforeReminders(
                          _vm.sidebarData.timeScheduleReminderOffsetDays,
                          "days"
                        ) === null
                          ? _c("span", [
                              _vm._v(
                                "\n                                -\n                            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.getBeforeReminders(
                                _vm.sidebarData.timeScheduleReminderOffsetDays,
                                "days"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "value" }, [
                      _c("div", [
                        _vm.getAfterReminders(
                          _vm.sidebarData.timeScheduleReminderOffsetDays,
                          "days"
                        ) === null
                          ? _c("span", [
                              _vm._v(
                                "\n                                -\n                            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.getAfterReminders(
                                _vm.sidebarData.timeScheduleReminderOffsetDays,
                                "days"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.sidebarData.odometerReminderInterval != null ||
            _vm.sidebarData.odometerReminderValue != null
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.sidebarData.odometerReminderInterval != null ||
            _vm.sidebarData.odometerReminderValue != null
              ? _c("div", { staticClass: "detail-section" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Distance reminders"),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _vm._m(3),
                    _c("div", { staticClass: "value" }, [
                      _c("div", [
                        _vm.sidebarData.odometerReminderInterval != null
                          ? _c("span", [
                              _vm._v(
                                "\n                                every " +
                                  _vm._s(
                                    _vm.sidebarData.odometerReminderInterval
                                  ) +
                                  "\n                                " +
                                  _vm._s(
                                    _vm.sidebarData.odometerReminderUnitType
                                  ) +
                                  "\n                            "
                              ),
                            ])
                          : _vm._e(),
                        _vm.sidebarData.odometerReminderValue != null
                          ? _c("span", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.sidebarData.odometerReminderValue
                                  ) +
                                  "\n                                " +
                                  _vm._s(
                                    _vm.sidebarData.odometerReminderUnitType
                                  ) +
                                  "\n                            "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _vm._m(4),
                    _c("div", { staticClass: "value" }, [
                      _c("div", {}, [
                        _vm.getBeforeReminders(
                          _vm.sidebarData.odometerReminderOffsets,
                          _vm.sidebarData.odometerReminderUnitType
                        ) === null
                          ? _c("span", [
                              _vm._v(
                                "\n                                -\n                            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n\n                            " +
                            _vm._s(
                              _vm.getBeforeReminders(
                                _vm.sidebarData.odometerReminderOffsets,
                                _vm.sidebarData.odometerReminderUnitType
                              )
                            ) +
                            "\n                        "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _vm._m(5),
                    _c("div", { staticClass: "value" }, [
                      _c("div", { staticClass: "red-icon" }, [
                        _vm.getAfterReminders(
                          _vm.sidebarData.odometerReminderOffsets,
                          _vm.sidebarData.odometerReminderUnitType
                        ) === null
                          ? _c("span", [
                              _vm._v(
                                "\n                                -\n                            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.getAfterReminders(
                                _vm.sidebarData.odometerReminderOffsets,
                                _vm.sidebarData.odometerReminderUnitType
                              )
                            ) +
                            "\n                        "
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.sidebarData.engineHoursReminderInterval != null ||
            _vm.sidebarData.engineHoursReminderValue != null
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.sidebarData.engineHoursReminderInterval != null ||
            _vm.sidebarData.engineHoursReminderValue != null
              ? _c("div", { staticClass: "detail-section" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Engine hours reminders"),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _vm._m(6),
                    _c("div", { staticClass: "value" }, [
                      _c("div", [
                        _vm.sidebarData.engineHoursReminderInterval != null
                          ? _c("span", [
                              _vm._v(
                                "\n                                every " +
                                  _vm._s(
                                    _vm.sidebarData.engineHoursReminderInterval
                                  ) +
                                  " hours\n                            "
                              ),
                            ])
                          : _vm._e(),
                        _vm.sidebarData.engineHoursReminderValue != null
                          ? _c("span", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.sidebarData.engineHoursReminderValue
                                  ) +
                                  " hours\n                            "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _vm._m(7),
                    _c("div", { staticClass: "value" }, [
                      _c("div", [
                        _vm.getBeforeReminders(
                          _vm.sidebarData.engineHoursReminderOffsets,
                          "hours"
                        ) === null
                          ? _c("span", [
                              _vm._v(
                                "\n                                -\n                            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.getBeforeReminders(
                                _vm.sidebarData.engineHoursReminderOffsets,
                                "hours"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _vm._m(8),
                    _c("div", { staticClass: "value" }, [
                      _c("div", { staticClass: "red-icon" }, [
                        _vm.getAfterReminders(
                          _vm.sidebarData.engineHoursReminderOffsets,
                          "hours"
                        ) === null
                          ? _c("span", [
                              _vm._v(
                                "\n                                -\n                            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.getAfterReminders(
                                _vm.sidebarData.engineHoursReminderOffsets,
                                "hours"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "line-separator" }),
            _c("div", { staticClass: "detail-section" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v("Notification Configuration"),
              ]),
              this.sidebarData.notificationConfiguration.email != null &&
              this.sidebarData.notificationConfiguration.email.length > 0
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _c(
                        "span",
                        { staticClass: "material-icons custom-blue-icon" },
                        [
                          _vm._v(
                            "\n                            email\n                            "
                          ),
                          _c(
                            "md-tooltip",
                            { attrs: { "md-direction": "left" } },
                            [_vm._v("Receieve notification thru email")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(
                        "\n                        Email\n                    "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      _vm._l(
                        this.sidebarData.notificationConfiguration.email,
                        function (role, index) {
                          return _c(
                            "span",
                            { key: index, staticClass: "notification-role" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(role) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ])
                : _vm._e(),
              this.additionalEmails != null && this.additionalEmails.length > 0
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Additional Emails:"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value custom-scroll-container" },
                      _vm._l(this.additionalEmails, function (email, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "custom-ellipsis" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(email) +
                                "\n                        "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              this.sidebarData.vehicleNames.length > 0
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Assets Selected:"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value custom-scroll-container" },
                      _vm._l(
                        this.sidebarData.vehicleNames,
                        function (name, index) {
                          return _c(
                            "span",
                            { key: index, staticClass: "custom-ellipsis" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(name) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label blue-icon" }, [
      _c("span", { staticClass: "material-icons" }, [
        _vm._v(
          "\n                            notifications_active\n                        "
        ),
      ]),
      _vm._v("\n                        Due Reminder\n                    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _c("span", { staticClass: "material-icons" }, [
        _vm._v(
          "\n                            restore\n                        "
        ),
      ]),
      _vm._v("\n                        Pre-reminder\n                    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _c("span", { staticClass: "material-icons" }, [
        _vm._v(
          "\n                            update\n                        "
        ),
      ]),
      _vm._v("\n                        Post-reminder\n                    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label blue-icon" }, [
      _c("span", { staticClass: "material-icons" }, [
        _vm._v(
          "\n                            notifications_active\n                        "
        ),
      ]),
      _vm._v("\n                        Due Reminder\n                    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _c("span", { staticClass: "material-icons" }, [
        _vm._v(
          "\n                            restore\n                        "
        ),
      ]),
      _vm._v("\n                        Pre-reminder\n                    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _c("span", { staticClass: "material-icons" }, [
        _vm._v(
          "\n                            update\n                        "
        ),
      ]),
      _vm._v("\n                        Post-reminder\n                    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label blue-icon" }, [
      _c("span", { staticClass: "material-icons" }, [
        _vm._v(
          "\n                            notifications_active\n                        "
        ),
      ]),
      _vm._v("\n                        Due Reminder\n                    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _c("span", { staticClass: "material-icons" }, [
        _vm._v(
          "\n                            restore\n                        "
        ),
      ]),
      _vm._v("\n                        Pre-reminder\n                    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label" }, [
      _c("span", { staticClass: "material-icons" }, [
        _vm._v(
          "\n                            update\n                        "
        ),
      ]),
      _vm._v("\n                        Post-reminder\n                    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }