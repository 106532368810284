<template>
    <div>
        <form-wrapper :validator="$v.model" class="form-wrapper">
            <form-group label="Api Key" name="apiKey">
                <md-input v-model.trim="model.apiKey" />
            </form-group>
            <form-group label="Customer Key" name="customerKey">
                <md-input v-model.trim="model.customerKey" />
            </form-group>
        </form-wrapper>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'Zoom2uKeys',
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            model: {
                apiKey: '',
                customerKey: ''
            }
        };
    },
    validations() {
        return {
            model: {
                apiKey: { required },
                customerKey: { required }
            }
        };
    },
    methods: {
        update() {
            this.$v.model.$touch();
            if (this.$v.model.$invalid) 
                return;

            this.$emit('onUpdate', { zoom2uAccess: { ...this.model } });
        }
    },
    watch: {
        data(value) {
            Object.assign(this.model, value);
        }
    }
};
</script>
