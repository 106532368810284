<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Edit {{ assetType }}</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <div class="md-layout create-asset-container">
                <form-wrapper :validator="$v.tracker" class="form-wrapper">
                    <tracker-step
                        v-if="assetType === 'Tracker'"
                        :data="formattedAssetData"
                        :show-dropdown="Boolean(false)"
                        :is-disabled="Boolean(false)"
                        :is-update-asset="Boolean(true)"
                    />
                </form-wrapper>
                <form-wrapper
                    :validator="$v.formattedAssetData"
                    class="form-wrapper"
                    v-if="assetType.toLowerCase() !== 'tracker'"
                >
                    <div class="md-layout-item md-size-100" v-if="assetType !== null">
                        <custom-field-inputs
                            v-for="(customField, index) in formattedCustomFields"
                            :key="index"
                            :custom-field-definition="customField"
                            @changed="handleCustomFieldChanged"
                            :initial-value="formattedAssetData[customField.name]"
                        />
                    </div>
                    <div class="md-layout-item md-size-100">
                        <form-group label="Asset Name*" name="name">
                            <md-input v-model="formattedAssetData.name" />
                        </form-group>
                        <form-group
                            v-if="assetType === 'Vehicle' || assetType === 'Trailer'"
                            label="Vehicle License Plate"
                            name="vehicleLicensePlate"
                        >
                            <md-input v-model="formattedAssetData.vehicleLicensePlate" />
                        </form-group>
                    </div>
                </form-wrapper>
                <div class="md-layout-item md-size-100">
                    <form-group v-if="showTeamRegion" name="teamRegion" label="Team Region">
                        <team-region-options
                            :selected="formattedAssetData.teamRegionId"
                            @selectedOption="handleTeamRegionChanged"
                            mode="update"
                            :show-auto="false"
                        />
                    </form-group>
                </div>
                <AssetGroup
                    v-if="showAssetGroup"
                    :team-region-id="formattedAssetData.teamRegionId"
                    :asset-group-id="formattedAssetData.assetGroupId"
                    @changed="handleAssetGroupChanged"
                />
                <div
                    class="md-layout-item md-size-100 timezone-container"
                    v-if="this.assetType.toLowerCase() == 'tracker'"
                >
                    <div>
                        <form-group name="timeZone" label="Time Zone">
                            <md-input v-model="formattedAssetData.timeZone" readonly />
                        </form-group>
                        <div class="error-text" v-if="timeZoneChanged">{{ timeZoneChangedText }}</div>
                    </div>

                    <div class="timezone-btn">
                        <md-button
                            class="md-danger md-just-icon md-round"
                            @click.stop="rejectTimeZoneUpdate"
                            v-if="timeZoneChanged"
                        >
                            <md-icon>close</md-icon>
                            <md-tooltip>Reject the current time zone.</md-tooltip>
                        </md-button>
                        <md-button class="md-primary md-just-icon md-round" @click.stop="getTrackerTimeZone" v-else>
                            <md-icon>my_location</md-icon>
                            <md-tooltip>Update time zone</md-tooltip>
                        </md-button>
                    </div>
                </div>

                <!-- <form-wrapper :validator="$v.personnel" class="form-wrapper">
                <personnel-step
                    v-if="assetType === 'Personnel'"
                    :data="formattedAssetData"
                    :show-dropdown="showDropdown"
                />
            </form-wrapper>

            <form-wrapper :validator="$v.vehicle" class="form-wrapper">
                <vehicle-step v-if="assetType === 'Vehicle'" :data="formattedAssetData" :show-dropdown="showDropdown" />
            </form-wrapper>

            <form-wrapper :validator="$v.assetForm" class="form-wrapper">
                <general-step
                    :data="formatterAssetData"
                    :type="assetType"
                    v-if="
                        assetType !== 'Personnel' &&
                            assetType !== 'Vehicle' &&
                            assetType !== 'Tracker'
                    "
                />
            </form-wrapper> -->
            </div>
        </div>
        <div class="modal-footer">
            <md-button class="md-primary dialog-button" @click="update()">Update</md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { CustomFieldInputs, TeamRegionOptions } from '@/components';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, numeric, maxLength } from 'vuelidate/lib/validators';
import { TrackerStep } from '@/pages/AssetTracking/StepComponents';
import { handleRequests, showTeamRegionControl } from '@/helpers';
import { mapGetters } from 'vuex';
import AssetGroup from './AssetGroup';
// import { ASSET_TYPE_CONSTANTS } from '@/utils/constants';

export default {
    name: 'UpdateAssetModal',
    components: {
        TrackerStep,
        CustomFieldInputs,
        TeamRegionOptions,
        AssetGroup
        // GeneralStep
    },
    mixins: [GeneralMixin],
    props: {
        assetData: {
            type: Object,
            default: () => {}
        },
        assetType: {
            type: String,
            default: ''
        },
        customFields: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasAssetAccess: 'user/hasAssetAccess',
            teamRegions: 'team/teamRegions',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember'
        }),
        showTeamRegion() {
            return showTeamRegionControl(
                {
                    user: this.user,
                    hasAssetAccess: this.hasAssetAccess,
                    teamRegions: this.teamRegions
                },
                this.formattedAssetData.teamRegionId
            );
        },
        showAssetGroup() {
            return this.assetType && this.assetType.toLowerCase() !== 'tracker';
        }
    },
    async mounted() {
        this.oldTimeZone = this.assetData.timeZone;
        const { customFields } = this.assetData;
        this.formattedAssetData = customFields;
        this.formattedAssetData.name = this.assetData.name;
        this.formattedAssetData.timeZone = this.assetData.timeZone;
        this.formattedAssetData.teamRegionId = this.assetData.teamRegionId !== null ? this.assetData.teamRegionId : -1; // Not Set
        this.formattedAssetData.assetGroupId = this.assetData.assetGroupId;
        this.formattedAssetData.vehicleLicensePlate = this.assetData.vehicleLicensePlate;
        this.formattedAssetData = Object.assign({}, this.formattedAssetData);
        this.formattedCustomFields = this.customFields.filter(
            (x) => x.assetType === this.assetType || x.assetType === 'Any'
        );
    },
    data() {
        return {
            formattedAssetData: {},
            formattedCustomFields: [],
            showDropdown: false,
            tracker: {},
            selectedAssetGroup: undefined,
            timeZoneChanged: false,
            timeZoneChangedText: '',
            oldTimeZone: null
        };
    },
    validations: {
        tracker: {
            name: {
                required
            },
            trackerReference: {
                required
            },
            modelYear: {
                numeric
            }
        },
        formattedAssetData: {
            name: {
                required
            },
            vehicleLicensePlate: {
                maxLength: maxLength(50)
            }
        }
    },
    methods: {
        handleCustomFieldChanged({ name, value }) {
            this.formattedAssetData[name] = value;
        },
        getAssetType() {
            this.personnel = [
                {
                    type: 'Personnel',
                    name: null
                }
            ];
            this.vehicle = {
                type: 'Vehicle',
                name: null
            };
            this.tracker = {
                type: 'Tracker',
                name: null
            };

            this.assetForm.type = this.assetType;
            this.assetForm.name = '';
            this.$v.$reset();
        },
        async getTrackerTimeZone() {
            if (this.assetType.toLowerCase() !== 'tracker') 
                return;

            this.$_handleLoaderState(true, 'LOADING...');
            const api = `/api/assets/tracker/${this.assetData.assetId}/time-zone`;
            try {
                const res = await handleRequests(api);
                this.$_handleLoaderState(false);
                this.formattedAssetData.timeZone = res.data.timeZone;

                if (this.formattedAssetData.timeZone !== this.oldTimeZone) {
                    this.timeZoneChanged = true;
                    this.timeZoneChangedText = `Previous time zone was ${this.oldTimeZone ?? 'not set'}`;
                }
            } catch (e) {
                this.$_handleLoaderState(false);
                this.$notify({
                    message: 'Error in fetching the time zone',
                    type: 'danger'
                });
            }
        },
        rejectTimeZoneUpdate() {
            this.formattedAssetData.timeZone = this.oldTimeZone;
            this.timeZoneChanged = false;
            this.timeZoneChangedText = '';
        },
        async update() {
            this.$_handleLoaderState(true, 'SAVING...');
            let isValid = false;
            const { name, teamRegionId, assetGroupId, timeZone, vehicleLicensePlate } = this.formattedAssetData;
            const { assetId } = this.assetData;

            if (this.assetType.toLowerCase() === 'tracker') {
                this.tracker = Object.assign({}, this.formattedAssetData);
                this.$v.tracker.$touch();
                isValid = !this.$v.tracker.$invalid;
            } else {
                this.$v.formattedAssetData.$touch();
                isValid = !this.$v.formattedAssetData.$invalid;
            }

            if (isValid) {
                delete this.formattedAssetData.name;
                delete this.formattedAssetData.teamRegionId;
                delete this.formattedAssetData.assetGroupId;
                delete this.formattedAssetData.timeZone;
                delete this.formattedAssetData.vehicleLicensePlate;
                const customFields = JSON.stringify(this.formattedAssetData);

                const obj = {
                    name,
                    teamRegionId,
                    assetGroupId,
                    type: this.assetType,
                    customFields,
                    timeZone,
                    vehicleLicensePlate
                };

                const api = `/api/assets/${assetId}`;

                // const api = '/api/assets';
                const payload = {
                    method: 'put',
                    data: obj
                };

                try {
                    const res = await handleRequests(api, payload);

                    this.$notify({
                        message: 'Successfully updated the asset.',
                        type: 'success'
                    });
                    this.personnel = { type: 'Personnel', name: null };
                    this.vehicle = { type: 'Vehicle', name: null };
                    this.tracker = { type: 'Tracker', name: null };
                    this.assetForm = { type: this.assetType, name: null };

                    // work around to keep/update Asset Group Name
                    if (obj.assetGroupId !== this.assetData.assetGroupId)
                        obj.assetGroupName = this.selectedAssetGroup ? this.selectedAssetGroup.groupName : '';
                    else 
                        obj.assetGroupName = this.assetData.assetGroupName;

                    obj.teamRegionId = res.data.teamRegionId;
                    obj.timeZone = res.data.timeZone;
                    obj.customFields = JSON.parse(customFields);

                    this.resolve({ id: res.data.assetId, data: obj });

                    // TODO: We need to add a way to clear the customfields after successfully creating the asset.
                    this.$v.$reset();
                } catch (error) {
                    const err = error.data;
                    if (err.length) {
                        err.forEach((e) => {
                            this.$notify({
                                message: e.message,
                                type: 'danger'
                            });
                        });
                    }

                    this.$notify({
                        message: 'Error in updating the asset',
                        type: 'danger'
                    });
                }
            }
            this.$_handleLoaderState(false);
        },
        handleTeamRegionChanged(value) {
            this.formattedAssetData.teamRegionId = value;
            this.formattedAssetData.assetGroupId = null;
        },
        handleAssetGroupChanged(assetGroup) {
            if (assetGroup) 
                this.formattedAssetData.assetGroupId = assetGroup.assetGroupId;
            else 
                this.formattedAssetData.assetGroupId = null;

            this.selectedAssetGroup = assetGroup;
        }
    }
};
</script>

<style lang="scss" scoped>
.asset-action {
    text-align: right;
}

.create-asset-container {
    max-height: 80vh;
    padding-right: 14px !important;
    overflow: auto;
    width: 100%;

    ::v-deep .form-wrapper {
        width: 100%;
    }
}

.timezone-container {
    > div {
        display: inline-block;
        vertical-align: middle;
    }

    > div:first-child {
        width: calc(100% - 50px);
        .md-field {
            .md-input {
                width: 100%;
            }
        }
    }
    .error-text {
        color: #ff5245;
        font-size: 13px;
        margin-top: -10px;
    }
    .timezone-btn {
        width: 50px;
        display: inline-block;
        vertical-align: middle;
        .md-button {
            // width: 100%;'
            background: #2b93ff !important;
            height: 32px;
            min-width: 32px;
            width: 32px;
            margin-right: 0;
            margin-left: 17px;
        }

        .md-danger {
            background: #f44336 !important;
        }
    }
}
</style>
