<template>
    <md-content
        v-if="shouldDisplayInfoWindow"
        :class="['info-tooltip md-elevation-2 md-layout map-panel', content.isOptimise ? 'info-tooltip-height' : '']"
        :style="styles"
    >
        <md-card
            :class="[
                'info--container',
                content.isStop ? 'top-margin' : '',
                content.isOptimise ? 'card-margin-top' : ''
            ]"
        >
            <div class="close-button" @click="() => (shouldDisplayInfoWindow = false)">
                <md-icon>close</md-icon>
            </div>

            <md-content class="info-detail--container" v-if="['driver', 'assetGroup'].indexOf(content.type) !== -1">
                <md-content class="info-detail info-heading-box" v-if="!pinnedUser">
                    <span class="info-heading driver-name">{{ content.fullName || content.groupName }}</span>
                    <br />
                    <span class="info-heading owner-name" v-if="content.ownerName">{{ content.ownerName }}</span>
                </md-content>
                <div class="misc-details">
                    <md-content
                        class="info-detail"
                        v-if="content.battery && isLastLocationUpdateToday"
                        title="Battery level"
                    >
                        <md-icon class="battery-icon">battery_std</md-icon>
                        <span>{{ content.battery }}%</span>
                    </md-content>
                    <md-content class="info-detail" title="Speed">
                        <span class="time-update" v-if="isLastLocationUpdateToday">
                            {{ content.lastLocationUpdate | timeFormat }}
                        </span>
                        <span class="info-detail" v-else>
                            {{ content.lastLocationUpdate | dateFormat(dateTypes.standardDateTime) }}
                        </span>
                        &nbsp;
                        <md-icon>speed</md-icon>
                        <span class="value">{{ convertSpeed(content.speed) }} {{ user.speedUnits || 'kph' }}</span>
                    </md-content>
                </div>
                <!--<md-content class="buttons-container">
                    <md-button class="text--button" :md-ripple="false" @click="showStreetView">
                        STREET VIEW
                    </md-button>
                </md-content>-->
            </md-content>
            <md-content class="info-detail--container" v-if="content.type === 'locationHistory'">
                <div class="misc-details">
                    <md-content class="info-detail" title="Speed">
                        <span class="time-update" v-if="isDateToday">
                            {{ content.lastLocationUpdate | timeFormat }}
                        </span>
                        &nbsp;
                        <md-icon>speed</md-icon>
                        <span class="value">{{ convertSpeed(content.speed) }} {{ user.speedUnits || 'kph' }}</span>
                    </md-content>
                </div>
                <md-content class="info-detail location-update" title="Location update" v-if="!isDateToday">
                    <span class="info-label">Last location update</span>
                    <span>{{ content.lastLocationUpdate | dateTimeFormat(dateTypes.standardDate) }}</span>
                </md-content>
                <md-content class="info-detail update-type" title="Type of update" v-if="content.isAssetGroup">
                    <md-icon>settings</md-icon>
                    <span class="value">{{ content.logType }}</span>
                </md-content>

                <!--<md-content class="buttons-container">
                    <md-button class="text--button" :md-ripple="false" @click="showStreetView">
                        STREET VIEW
                    </md-button>
                </md-content>-->
            </md-content>
            <md-content class="info-detail--container" v-else-if="content.type === 'stop'">
                <!-- <md-content class="info-detail" v-if="content.reallocateStop">
                    <router-link
                        v-if="content.stopRef"
                        :to="{
                            name: 'Stop Details',
                            params: { stopId: content.stopId }
                        }"
                        target="_blank"
                    >
                        {{ content.stopRef }}
                    </router-link>
                </md-content> -->
                <md-content class="info-detail stop-address">
                    <span v-if="content.isStartLocation" class="info-label">
                        Start location
                    </span>
                    <span v-else-if="content.isEndLocation" class="info-label">
                        End location
                    </span>
                    <span class="info-heading">{{ content.address }}</span>
                </md-content>
                <template v-if="['Cancelled', 'On Hold'].indexOf(content.status) === -1">
                    <md-content
                        class="info-detail route-optimization"
                        v-if="content.originalEta || content.arrivalDate"
                    >
                        <span class="info-label">
                            {{
                                ['Arrived', 'Complete', 'Failed'].indexOf(content.status) === -1
                                    ? 'ETA'
                                    : 'Time of arrival:'
                            }}
                        </span>
                        <span v-if="['Arrived', 'Complete', 'Failed'].indexOf(content.status) !== -1">
                            {{ content.arrivalDate | timeFormat(dateTypes.standardTime) }}
                        </span>
                        <span v-else>{{ content.originalEta | timeFormat(dateTypes.standardTime) }}</span>
                    </md-content>
                </template>
                <div class="misc-details">
                    <md-content class="info-detail" v-if="content.contactName" title="Contact name">
                        <md-icon>supervisor_account</md-icon>
                        <span>{{ content.contactName }}</span>
                    </md-content>
                    <md-content class="info-detail" v-if="content.contactPhone" title="Contact phone number">
                        <md-icon>call</md-icon>
                        <span>{{ content.contactPhone }}</span>
                    </md-content>
                </div>
                <md-content class="buttons-container">
                    <!--<md-button
                        title="street view"
                        class="info--buttons md-primary md-button md-just-icon md-round"
                        :md-ripple="false"
                        @click="showStreetView"
                    >
                        <md-icon>streetview</md-icon>
                    </md-button>-->
                    <md-button
                        v-if="content.stopId"
                        title="stop details"
                        class="info--buttons md-primary md-button md-just-icon md-round"
                        :md-ripple="false"
                        @click="showDetailsPage"
                    >
                        <md-icon>visibility</md-icon>
                    </md-button>
                    <md-button
                        v-if="content.trackingLinkUrl"
                        title="tracking link"
                        class="info--buttons md-primary md-button md-just-icon md-round"
                        :md-ripple="false"
                        @click="showTrackingLink"
                    >
                        <md-icon>directions_car</md-icon>
                    </md-button>
                    <!-- <reassign-stop-button
                        v-if="content.reallocateStop"
                        :allocate-stops="content.allocateStops"
                        :stop-id="content.stopId"
                        :vehicle-id="content.vehicleId"
                        @reassignedStop="handleReassignedStop"
                    />
                    <delete-stop-button
                        v-if="content.reallocateStop"
                        class="info--buttons md-primary md-button md-just-icon md-round"
                        :stop-id="content.stopId"
                        :vehicle-id="content.vehicleId"
                        :allocate-stops="content.allocateStops"
                        :stop-ref="content.stopRef"
                        :address="content.address"
                        @stopDeleted="handleDeleteStop"
                    /> -->
                </md-content>
            </md-content>
            <md-content
                class="info-detail--container"
                v-else-if="['statusHistory', 'geofence'].indexOf(content.type) !== -1"
            >
                <md-content class="info-detail stop-address">
                    <span class="info-heading">{{ content.address }}</span>
                </md-content>
                <md-content class="info-detail stop-ref" @click="stopRefClicked">
                    {{ content.stopRef }}
                </md-content>
                <md-content class="info-detail--container stop-status--detail stop-status--change">
                    <span :style="{ color: setColor(content.oldStatus) }">
                        {{ content.oldStatus }}
                    </span>
                    <md-icon class="arrow-icon">arrow_right_alt</md-icon>
                    <span :style="{ color: setColor(content.newStatus) }">
                        {{ content.newStatus }}
                    </span>
                </md-content>
                <md-content class="info-detail action-date" title="Action date">
                    <span class="info-label">Action date</span>
                    <span>{{ content.actionDate | dateTimeFormat(dateTypes.standardDate) }}</span>
                </md-content>
            </md-content>
            <!-- <div v-else-if="content.type === 'optimisedStop'">
                <md-content class="info-detail--container">
                    <md-content class="info-detail route-optimization">
                        <span class="info-label">Address</span>
                        <span>{{ content.address }}</span>
                    </md-content>
                </md-content>
            </div> -->
            <div v-else-if="content.type === 'geofence'">
                <md-content class="info-detail geo-fence-name">
                    <b>{{ content.title }}</b>
                    <p>{{ content.notes }}</p>
                    <div class="cta">
                        <md-button
                            class="md-button md-just-icon md-round md-primary"
                            @click="$emit('editGeoFence', content.id)"
                        >
                            <md-icon>edit</md-icon>
                        </md-button>
                        <md-button
                            class="md-button md-just-icon md-round md-primary"
                            @click="$emit('deleteGeoFence', content.id)"
                        >
                            <md-icon>delete</md-icon>
                        </md-button>
                    </div>
                </md-content>
            </div>
        </md-card>
    </md-content>
</template>

<script>
import moment from 'moment';
import { ref, computed, onMounted, inject } from '@vue/composition-api';
import { convertUtcTimeStringToLocalDate } from '@/helpers';
import useGeneralUtils from '@/compostables/useGeneralUtils';
import useMapUtils from '@/compostables/useMapUtils';

export default {
    name: 'InfoWindow',
    props: {
        isDateToday: {
            type: Boolean,
            default: true
        }
    },
    setup(_, { root }) {
        const map = inject('map');
        const google = inject('google');
        const { getters, commit } = inject('vuex-store');
        const { calculateMarkerCoordinates, handleListener } = useMapUtils(root);
        const { speedMultiplier, dateTypes } = useGeneralUtils(root);
        const user = getters['user/user'];
        const pinnedUser = computed(() => getters['map/pinnedUser']);

        const shouldDisplayInfoWindow = ref(false);

        const convertSpeed = (speed) => {
            return Number.isNaN(speed) ? 0 : Math.round(speed * speedMultiplier.value[user.speedUnits]);
        };

        const setColor = (status) => {
            switch (status.toLowerCase()) {
                case 'pending':
                    return '#c2907f';
                case 'enroute':
                    return '#4ed2ff';
                case 'arrived':
                    return '#2b93ff';
                case 'departed':
                    return '#ffb42b';
                case 'cancelled':
                    return '#ff5245';
                case 'failed':
                    return '#f44336';
                case 'delayed':
                    return '#384553';
                case 'complete':
                    return '#0bda8e';
                default:
                    return '#c2907f';
            }
        };

        const content = ref({});
        const styles = ref({});

        const showStreetView = () => {
            const {
                coordinates: { latitude: lat, longitude: lng }
            } = content.value;
            commit('map/SHOW_STREET_VIEW', { lat, lng });
        };

        const showDetailsPage = () => {
            const { stopId } = content.value;
            const { location } = root.$router.resolve({ name: 'Stop Details', path: `/stops/details/${stopId}` });
            window.open(location.path, '_blank');
        };

        const showTrackingLink = () => {
            const link = content.value.trackingLinkUrl;
            // To avoid an issue. If the link do not have a http or https, the window.open will prepend current url
            window.open(link.includes('http') ? link : `https://${link}`, '_blank');
        };

        const stopRefClicked = () => {
            commit('map/STOP_CLICKED', { stopId: content.value.stopId });
        };

        const isLastLocationUpdateToday = computed(() => {
            return (
                moment()
                    .startOf('day')
                    .diff(moment(content.value.lastLocationUpdate).startOf('day')) <= 0
            );
        });

        onMounted(() => {
            // listening to events emitted by markers in order to display the info window
            root.$on('marker-clicked', ({ data, marker, type }) => {
                const options = {
                    height: 152,
                    width: 128
                };
                switch (type) {
                    case 'driver':
                    case 'assetGroup': {
                        const { fullName, groupName, ownerName } = data;
                        const {
                            battery = '',
                            speed = '',
                            lastLocationUpdate,
                            coordinates,
                            utcOffset
                        } = data.routeDetails;

                        const convertedDate = convertUtcTimeStringToLocalDate(lastLocationUpdate, utcOffset);

                        content.value = {
                            fullName,
                            groupName,
                            ownerName,
                            battery,
                            speed,
                            lastLocationUpdate: convertedDate,
                            coordinates,
                            type
                        };

                        if (pinnedUser.value) 
                            options.height = 185;
                        else 
                            options.height = 200;
                        break;
                    }
                    case 'stop': {
                        const {
                            isStartLocation,
                            isEndLocation,
                            address,
                            originalEta,
                            arrivalDate,
                            status,
                            stopId,
                            trackingLinkUrl,
                            location: coordinates
                        } = data;

                        let contactName = '';
                        let contactPhone = '';

                        if (data.contact) {
                            ({ name: contactName, phone: contactPhone } = data.contact);
                        }

                        content.value = {
                            coordinates,
                            isStartLocation,
                            isEndLocation,
                            address,
                            originalEta,
                            arrivalDate,
                            status,
                            contactName,
                            contactPhone,
                            stopId,
                            trackingLinkUrl,
                            type
                        };

                        options.height = 207;
                        if (isStartLocation || isEndLocation) 
                            options.height = 185;

                        break;
                    }
                    case 'geofence':
                    case 'statusHistory': {
                        const {
                            address,
                            stopRef,
                            oldStatus,
                            newStatus,
                            actionDate,
                            stopId,
                            utcOffset,
                            location: coordinates
                        } = data;

                        const convertedDate = convertUtcTimeStringToLocalDate(actionDate, utcOffset);

                        content.value = {
                            address,
                            stopRef,
                            oldStatus,
                            newStatus,
                            actionDate: convertedDate,
                            stopId,
                            type,
                            coordinates
                        };
                        break;
                    }
                    case 'locationHistory': {
                        const {
                            groupName: fullName,
                            isAssetGroup,
                            battery,
                            speed,
                            coordinates,
                            timeStamp,
                            utcOffset,
                            type: logType
                        } = data;

                        const convertedDate = convertUtcTimeStringToLocalDate(timeStamp, utcOffset);

                        content.value = {
                            fullName,
                            isAssetGroup,
                            battery,
                            speed,
                            lastLocationUpdate: convertedDate,
                            coordinates,
                            logType,
                            type
                        };
                        break;
                    }
                    default:
                }
                styles.value = calculateMarkerCoordinates(google.value, map.value, marker, options);
                shouldDisplayInfoWindow.value = true;
            });

            // handling of infoWindow
            handleListener(map.value, 'bounds_changed', () => {
                shouldDisplayInfoWindow.value = false;
            });
        });

        return {
            isLastLocationUpdateToday,
            shouldDisplayInfoWindow,
            showDetailsPage,
            showTrackingLink,
            stopRefClicked,
            dateTypes,
            showStreetView,
            setColor,
            content,
            styles,
            pinnedUser,
            convertSpeed,
            user
        };
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';

.close-button {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 0;
    z-index: 4;
    .md-icon {
        max-width: 12px !important;
        max-height: 12px !important;
        font-size: 12px !important;
        color: black !important;
    }
}

.md-content {
    font-weight: bold;
}

.left-margin {
    margin-left: 4px !important;
}

.right-margin {
    margin-right: 4px !important;
}

.top-margin {
    margin-top: 16px;
}

.content-phone {
    color: $grey-600 !important;
}
.info-tooltip-height {
    line-height: 1.5 !important;
    margin: 50px 0px 0px 5px;
}
.card-margin-top {
    margin-top: -1px;
}

.info-tooltip {
    position: absolute;
    line-height: 1.2;
    max-width: 240px;
    border: 1px solid white;
    background-color: white;
    border-radius: 8px;
    color: black;
    z-index: 2;

    &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 120px;
        border-top: 8px solid white;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }

    &:after {
        content: '';
        position: absolute;
        left: 120px;
        top: 100%;
        border-top: 8px solid white;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }

    .info--container {
        margin-top: 16px;
        margin-bottom: 16px;

        .info-detail--container,
        .contact-container {
            display: flex;
            flex-direction: column;
            margin: auto;
            padding: 0 8px;

            .misc-details {
                display: flex;
                margin: 5px auto;
            }

            .info-detail,
            .contact-details {
                text-align: center;
                cursor: help;
                margin: 5px;
                display: flex;
                justify-content: center;

                &.update-type {
                    margin-top: 10px;
                }

                &.stop-ref {
                    cursor: pointer;
                    color: $locate2u-blue-base;
                    align-self: baseline;
                    margin-left: 0;
                    font-size: 12px;
                }

                span {
                    font-size: 11px;
                    &.value {
                        margin-top: 1px;
                    }
                }

                .info-heading {
                    font-size: 14px;
                    font-weight: 500;
                    max-width: 200px;

                    &.driver-name {
                        max-width: 185px;
                    }
                    &.owner-name {
                        max-width: 185px;
                        color: silver;
                        font-weight: normal;
                        font-size: 12px;
                    }
                }

                .time-update {
                    font-size: 12px;
                }

                .md-icon {
                    margin: 0 2px 0 0;
                    width: 14px;
                    height: 14px;
                    font-size: 22px !important;
                    color: $locate2u-blue-base;
                    &.arrow-icon {
                        color: black;
                        height: 0;
                        margin-left: 8px;
                    }
                    &.battery-icon {
                        transform: rotate(90deg);
                    }
                }

                &.location-update,
                &.action-date,
                &.stop-address,
                &.route-optimization {
                    margin: 0 auto;
                    flex-direction: column;
                    align-items: baseline;
                    span {
                        &:first-child {
                            margin-bottom: 1px;
                        }
                        &:last-child {
                            margin-top: 1px;
                            font-size: 12px;
                        }

                        &.info-label {
                            font-size: 0.8rem;
                            color: $grey;
                        }
                    }

                    &.route-optimization {
                        text-align: left;
                        margin: 5px 0 10px;
                    }
                }

                &.stop-address {
                    margin-top: 15px;
                    text-align: left;
                }

                &.action-date {
                    align-self: baseline;
                    margin: 0;
                }
            }

            .info-heading-box {
                display: -webkit-box;
            }

            &.stop-status--change {
                display: block;
                margin: 0;
                align-self: baseline;
                padding: 0;
                font-size: 15px;
            }
        }

        .buttons-container {
            width: 100%;
            margin: 8px auto;
            display: flex;
            justify-content: center;
            .info--buttons {
                min-width: 28px !important;
                width: 28px !important;
                height: 28px !important;
            }
            .text--button {
                width: 80%;
                margin: 0 auto;
                display: block;
                border-radius: 8px;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 1px;
                font-weight: 500;
                background: $white !important;
                color: $locate2u-blue-base !important;
                &:hover {
                    border: 1px solid $locate2u-blue-base !important;
                }
            }
        }
    }
}

.custom-button-container {
    margin-top: -9px;
    button,
    ::v-deep button {
        margin: 0px 0px;
        min-width: 29px !important;
        width: 29px !important;
        height: 29px !important;
    }

    button:last-child {
        margin-right: 0;
    }
}
.geo-fence-name {
    padding: 0 10px;
    b {
        font-size: 1.1rem;
    }
    p {
        font-weight: 100;
    }
    .cta {
        display: flex;
        justify-content: center;
    }
    .md-button {
        transform: scale(0.75);
    }
}
</style>
