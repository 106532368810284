// Directive that will show a tooltip when the mouse is on top of an element with all the text in that element, if that
// element is currently overflowing. i.e. Text is hidden because it doesn't fit within the element.
export const OverflowTextTooltip = {
    bind(el) {
        const isMobile = window.matchMedia('(max-width: 480px)').matches;
        el.addEventListener('mouseenter', () => {
            /* eslint consistent-return: 'off' */
            if (isMobile) 
                return true;
            if (el.scrollWidth > el.clientWidth) {
                const $tooltip = document.createElement('span');

                $tooltip.setAttribute('class', 'overflow-text-tooltip md-tooltip');

                $tooltip.innerHTML = el.innerText;

                el.insertBefore($tooltip, el.firstChild);
            }
        });

        el.addEventListener('mouseleave', () => {
            /* eslint consistent-return: 'off' */
            if (isMobile) 
                return true;
            const $tooltip = document.querySelector('.overflow-text-tooltip');
            if ($tooltip) {
                el.removeChild($tooltip);
            }
        });
    },
    unbind(el) {
        el.removeEventListener('mouseenter', () => {});
        el.removeEventListener('mouseleave', () => {});
    }
};
