<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Routing Setting</h4>
        </div>
        <div class="modal-body">
            <div class="field-with-icon">
                <form-group name="distanceCost" label="Distance Cost">
                    <md-input type="number" v-model="distanceCost" :disabled="!hasTeamAccess" />
                </form-group>
                <div>
                    <md-icon>info</md-icon>
                    <md-tooltip class="tooltip-width" md-direction="right">
                        Cost that is incurred for each km the vehicle travels. The higher the value the stops will be in
                        shorter distance to one another. Recommended value is
                        <strong>{{ defaultRouteSettings.distanceCost }}</strong>
                        .
                    </md-tooltip>
                </div>
            </div>

            <div class="field-with-icon">
                <form-group name="timeCost" label="Time Cost">
                    <md-input type="number" v-model="timeCost" :disabled="!hasTeamAccess" />
                </form-group>
                <div>
                    <md-icon>info</md-icon>
                    <md-tooltip class="tooltip-width" md-direction="right">
                        Cost that is incurred for each hour the vehicle travels. The higher the value the stops will
                        have less travel time between one another. Recommended value is
                        <strong>{{ defaultRouteSettings.timeCost }}</strong>
                        .
                    </md-tooltip>
                </div>
            </div>

            <div class="field-with-icon" v-if="isDistributeWorkload === false">
                <form-group name="fixedCost" label="Fixed Costs">
                    <md-input type="number" v-model="fixedCost" :disabled="!hasTeamAccess" />
                </form-group>
                <div>
                    <md-icon>info</md-icon>
                    <md-tooltip class="tooltip-width" md-direction="right">
                        A fixed cost which is applied when a vehicle is used, irrespective of the number of jobs
                        assigned to it. Recommended value is
                        <strong>{{ defaultRouteSettings.fixedCost }}</strong>
                        .
                    </md-tooltip>
                </div>
            </div>

            <div class="field-with-icon" v-if="isDistributeWorkload === true">
                <form-group name="distributeWorkloadCost" label="Distribute Workload Cost">
                    <md-input type="number" v-model="distributeWorkloadCost" :disabled="!hasTeamAccess" />
                </form-group>
                <div>
                    <md-icon>info</md-icon>
                    <md-tooltip class="tooltip-width" md-direction="right">
                        Workload balancing enables work to be spread more evenly across your planned routes, which helps
                        to (a) keep your staff content and (b) retain capacity across your fleet for future jobs. You
                        are advised to try
                        <strong>{{ defaultRouteSettings.distributeWorkloadCost }}</strong>
                        as a starting point and then reduce it (for example to 1) if the travel time is impacted too
                        greatly, or increase it if the balance doesn’t seem enough.
                    </md-tooltip>
                </div>
            </div>

            <div class="field-with-icon">
                <form-group name="routeCenteringPenalty" label="Route Centering Penalty">
                    <md-input type="number" v-model="routeCenteringPenalty" :disabled="!hasTeamAccess" />
                </form-group>
                <div>
                    <md-icon>info</md-icon>
                    <md-tooltip class="tooltip-width" md-direction="right">
                        Cost which is applied to minimise the travel distance or time between the most central stop on a
                        route and all other stops on the route. You can use this cost to force routes to form tighter
                        clusters, instead of the typical ‘petal’ shaped routes that arise naturally from a central
                        depot. Recommended value range is
                        <strong>0.1 to {{ defaultRouteSettings.routeCenteringPenalty }}</strong>
                        .
                    </md-tooltip>
                </div>
            </div>
            <div class="reset">
                <md-button class="dialog-button md-default" v-if="hasTeamAccess" @click="resetSettingsToDefault">
                    Reset to default values
                </md-button>
            </div>
        </div>
        <div class="modal-footer">
            <md-button v-if="hasTeamAccess" class="dialog-button md-primary" @click="saveRoutingSetting">
                Save
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { ROUTE_SETTING_DEFAULTS } from '@/utils/defaults';

export default {
    name: 'RoutingSettingsModal',
    mixins: [GeneralMixin],
    props: {
        resolve: {
            type: Function,
            default: () => {}
        },
        startLocationMode: {
            type: String,
            default: null
        },
        isDistributeWorkload: {
            type: Boolean
        }
    },
    data() {
        return {
            distanceCost: null,
            timeCost: null,
            fixedCost: null,
            routeCenteringPenalty: null,
            distributeWorkloadCost: null,
            defaultRouteSettings: ROUTE_SETTING_DEFAULTS
        };
    },
    computed: {
        ...mapGetters({
            getDistanceCost: 'routingSetting/getDistanceCost',
            getTimeCost: 'routingSetting/getTimeCost',
            getFixedCost: 'routingSetting/getFixedCost',
            getRouteCenteringPenalty: 'routingSetting/getRouteCenteringPenalty',
            getDistributeWorkloadCost: 'routingSetting/getDistributeWorkloadCost',
            hasTeamAccess: 'user/hasTeamAccess',
            getDefaultRouteSettings: 'routingSetting/getDefaultRouteSettings'
        })
    },
    methods: {
        ...mapActions({
            FETCH_ROUTE_SETTINGS: 'routingSetting/FETCH_ROUTE_SETTINGS',
            FETCH_DEFAULT_ROUTE_SETTINGS: 'routingSetting/FETCH_DEFAULT_ROUTE_SETTINGS'
        }),
        ...mapMutations({
            saveDistanceCost: 'routingSetting/UPDATE_DISTANCE_COST',
            saveTimeCost: 'routingSetting/UPDATE_TIME_COST',
            saveFixedCost: 'routingSetting/UPDATE_FIXED_COST',
            saveRouteCenteringPenalty: 'routingSetting/UPDATE_ROUTE_CENTERING_PENALTY',
            saveDistributeWorkloadCost: 'routingSetting/UPDATE_DISTRIBUTE_WORKLOAD_COST'
        }),
        async resetSettingsToDefault() {
            const {
                distanceCost,
                timeCost,
                fixedCost,
                routeCenteringPenalty,
                distributeWorkloadCost
            } = this.defaultRouteSettings;

            const isChange =
                distanceCost !== this.distanceCost ||
                timeCost !== this.timeCost ||
                fixedCost !== this.fixedCost ||
                routeCenteringPenalty !== this.routeCenteringPenalty ||
                distributeWorkloadCost !== this.distributeWorkloadCost;

            // Set the default values to form inputs
            this.distanceCost = distanceCost;
            this.timeCost = timeCost;
            this.fixedCost = fixedCost;
            this.routeCenteringPenalty = routeCenteringPenalty;
            this.distributeWorkloadCost = distributeWorkloadCost;
            await this.saveToDb(this.defaultRouteSettings, isChange);
        },
        async saveRoutingSetting() {
            const distanceCost = parseFloat(this.distanceCost);
            const timeCost = parseFloat(this.timeCost);
            const fixedCost = parseFloat(this.fixedCost);
            const routeCenteringPenalty = parseFloat(this.routeCenteringPenalty);
            const distributeWorkloadCost = parseFloat(this.distributeWorkloadCost);

            const isChange =
                distanceCost !== this.distanceCost ||
                timeCost !== this.timeCost ||
                fixedCost !== this.fixedCost ||
                routeCenteringPenalty !== this.routeCenteringPenalty ||
                distributeWorkloadCost !== this.distributeWorkloadCost;

            const data = {
                distanceCost: this.distanceCost,
                timeCost: this.timeCost,
                fixedCost: this.fixedCost,
                routeCenteringPenalty: this.routeCenteringPenalty,
                distributeWorkloadCost: this.distributeWorkloadCost
            };

            await this.saveToDb(data, isChange);
        },
        async saveToDb(data, isChange) {
            this.$_handleLoaderState(true, 'PROCESSING...');
            const api = `/api/teams/route-settings`;
            const payload = {
                method: 'post',
                data
            };

            try {
                await handleRequests(api, payload);
                this.$notifySuccess('Successfully updated route settings.');

                if (isChange) 
                    await this.FETCH_ROUTE_SETTINGS();

                this.resolve({ isChange });
            } catch (error) {
                const message = 'Error in updating route settings.';
                showErrorMessage(this, message, error);
            }

            this.$_handleLoaderState(false);
        }
    },
    async mounted() {
        await this.FETCH_DEFAULT_ROUTE_SETTINGS();
        await this.FETCH_ROUTE_SETTINGS();
        Object.assign(this.defaultRouteSettings, this.getDefaultRouteSettings);

        this.distanceCost = this.getDistanceCost;
        this.timeCost = this.getTimeCost;
        this.fixedCost = this.getFixedCost;
        this.routeCenteringPenalty = this.getRouteCenteringPenalty;
        this.distributeWorkloadCost = this.getDistributeWorkloadCost;
    }
};
</script>
<style lang="scss" scoped>
.modal-container {
    max-width: 250px;
}

.field-with-icon {
    display: flex;

    div {
        display: flex;
        align-items: center;
    }

    .md-icon {
        cursor: pointer;
    }
}
.reset {
    display: flex;
    justify-content: center;
}
.tooltip-width {
    max-width: 400px;
    text-align: justify;
    font-size: smaller;
}
</style>
