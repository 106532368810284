<template>
    <div class="content">
        <div class="main-content">
            <div class="getting-started-text">
                <h4>
                    Getting started with
                    <img class="gs-logo" src="/img/logos/l2u-logo-dark.png" />
                </h4>
                <p>
                    Welcome {{ firstName }}, watch the
                    <span>video</span>
                    to learn how Locate2u works
                </p>
            </div>
            <div class="getting-started-video">
                <div>
                    <div class="main-video-container">
                        <div class="vid-container">
                            <iframe
                                class="responsive-iframe"
                                v-show="activeStep == 1"
                                src="https://fast.wistia.net/embed/iframe/kvddydowcb?videoFoam=true"
                                frameborder="0"
                                webkitallowfullscreen
                                mozallowfullscreen
                                allowfullscreen
                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                            ></iframe>
                            <iframe
                                class="responsive-iframe"
                                v-show="activeStep == 2"
                                src="https://fast.wistia.net/embed/iframe/ts5wm9b8di?videoFoam=true"
                                frameborder="0"
                                webkitallowfullscreen
                                mozallowfullscreen
                                allowfullscreen
                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                            ></iframe>
                            <iframe
                                class="responsive-iframe"
                                v-show="activeStep == 3"
                                src="https://fast.wistia.net/embed/iframe/81rqkag5oq?videoFoam=true"
                                frameborder="0"
                                webkitallowfullscreen
                                mozallowfullscreen
                                allowfullscreen
                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                            ></iframe>
                        </div>
                    </div>
                    <div class="step-parent-container">
                        <div class="step-container">
                            <span
                                class="circle-step"
                                :class="activeStep == 1 ? 'step-active' : ''"
                                @click.stop="changeVideoContent(1)"
                            >
                                1
                            </span>
                            <div class="step-line"></div>
                            <div class="step-content">
                                <span>Step 1:</span>
                                <p>Get quickly set up with Locate2u in a few single steps.</p>
                            </div>
                        </div>
                        <div class="step-container">
                            <span
                                class="circle-step"
                                :class="activeStep == 2 ? 'step-active' : ''"
                                @click.stop="changeVideoContent(2)"
                            >
                                2
                            </span>
                            <div class="step-line"></div>
                            <div class="step-content">
                                <span>Step 2:</span>
                                <p>Basic walk through</p>
                            </div>
                        </div>
                        <div class="step-container">
                            <span
                                class="circle-step"
                                :class="activeStep == 3 ? 'step-active' : ''"
                                @click.stop="changeVideoContent(3)"
                            >
                                3
                            </span>
                            <div class="step-content">
                                <span>Step 3:</span>
                                <p>Advanced walk through</p>
                            </div>
                        </div>
                    </div>
                    <div class="step-content-mobile">
                        <div class="step-content" v-if="activeStep == 1">
                            <span>Step 1:</span>
                            <p>Get quickly set up with Locate2u in a few single steps.</p>
                        </div>
                        <div class="step-content" v-if="activeStep == 2">
                            <span>Step 2:</span>
                            <p>Basic walk through</p>
                        </div>
                        <div class="step-content" v-if="activeStep == 3">
                            <span>Step 3:</span>
                            <p>Advanced walk through</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-controls">
            <div>
                <span class="cc-title">Other videos in our Getting Started library</span>
                <div class="video-content">
                    <div class="video-container" v-for="(item, index) in list" :key="index">
                        <div @click="showVideo(item.url)">
                            <div class="video-thumbnail">
                                <img :src="item.thumbnailUrl" />
                            </div>
                            <div class="video-text custom-text-ellipsis">
                                <span>{{ item.title }}</span>
                                {{ item.subtitle }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center" style="margin-top:10px;">
            Feel free to send your inquiries at
            <a style="color:#2b93ff;" href="mailto:support@locate2u.com">support@locate2u.com</a>
        </div>

        <div>
            <div class="text-center">
                <a @click="skipOnboarding" class="skip-button" v-if="!isSkipped">
                    Skip and continue to dashboard
                </a>
                <p>You can return here anytime, check Getting Started under Support menu.</p>
            </div>
        </div>
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests } from '@/helpers';
import { mapGetters } from 'vuex';

export default {
    name: 'GettingStarted',
    mixins: [GeneralMixin],
    data() {
        return {
            firstName: '',
            activeStep: 1,
            onboardingSettings: {},
            isOnboardingCompleted: false,
            isSkipped: false,
            settings: {
                dots: true,
                focusOnSelect: true,
                infinite: true,
                speed: 500,
                slidesToShow: 5,
                slidesToScroll: 5,
                touchThreshold: 5
            },
            list: [
                {
                    title: '1. Setting up your team members',
                    thumbnailUrl: '/img/getting-started/setting-up-team-members.jpg',
                    url: 'https://fast.wistia.net/embed/iframe/a7sfezat5v?videoFoam=true'
                },
                {
                    title: '2. Setting up Locate2u for your business',
                    thumbnailUrl: '/img/getting-started/setup-for-business.jpg',
                    url: 'https://fast.wistia.net/embed/iframe/gk4ic71eij?videoFoam=true'
                },
                {
                    title: '3. Differences between Stops and Shipments',
                    thumbnailUrl: '/img/getting-started/difference-stops-shipments.jpg',
                    url: 'https://fast.wistia.net/embed/iframe/kyldqp4yp5?videoFoam=true'
                },
                {
                    title: '4. Connecting your website to Locate2u',
                    thumbnailUrl: '/img/getting-started/connecting-to-website.jpg',
                    url: 'https://fast.wistia.net/embed/iframe/i3uytb0iwt?videoFoam=true'
                },
                {
                    title: '5. Setting up live tracking links',
                    thumbnailUrl: '/img/getting-started/setting-up-live-tracking.jpg',
                    url: 'https://fast.wistia.net/embed/iframe/yg7j4wkcc8?videoFoam=true'
                },
                {
                    title: '6. How to allocate bookings to your staff',
                    thumbnailUrl: '/img/getting-started/allocate-bookings.jpg',
                    url: 'https://fast.wistia.net/embed/iframe/5q5uyl6d72?videoFoam=true'
                },
                {
                    title: '7. Setting up skills in locate2u',
                    thumbnailUrl: '/img/getting-started/setup-skills.jpg',
                    url: 'https://fast.wistia.net/embed/iframe/mlelbg787c?videoFoam=true'
                },
                {
                    title: '8. Setting up Geofencing for your business',
                    thumbnailUrl: '/img/getting-started/geofencing.jpg',
                    url: 'https://fast.wistia.net/embed/iframe/6hw3p2q5jm?videoFoam=true'
                },
                {
                    title: '9. Setting up Custom fields',
                    thumbnailUrl: '/img/getting-started/custom-fields.jpg',
                    url: 'https://fast.wistia.net/embed/iframe/w1iwalkmjg?videoFoam=true'
                },
                {
                    title: '10. Setting up team regions for your business',
                    thumbnailUrl: '/img/getting-started/team-regions.jpg',
                    url: 'https://fast.wistia.net/embed/iframe/snmwp9d9qf?videoFoam=true'
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasTeam: 'user/hasTeam'
        })
    },
    async created() {
        this.$_handleLoaderState(true);
        const api = `/api/users/onboarding`;
        const response = await handleRequests(api);

        if (response.data !== '') {
            const { data } = response;

            if (data.isSkipped) {
                this.isSkipped = data.isSkipped;
            }
        }

        this.firstName = this.user.firstName;

        this.$_handleLoaderState(false);
    },
    methods: {
        changeVideoContent(videoNumber) {
            this.activeStep = videoNumber;
        },
        showVideo(url) {
            this.$messageBox.show({
                class: 'lg-modal-container',
                title: 'Locate2u Overview',
                body: `<div><div style="position: relative; padding-bottom: 56.25%; height: 0;"><iframe src="${url}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div></div>`,
                buttons: ['Close']
            });
        },
        async saveChangesToServer() {
            const payload = {
                method: 'post',
                data: this.onboardingSettings
            };
            const api = `/api/users/onboarding`;

            await handleRequests(api, payload);
        },
        async skipOnboarding() {
            this.onboardingSettings.isSkipped = true;
            this.onboardingSettings.settings = [];
            await this.saveChangesToServer();

            this.$router.push({
                name: 'HomeDashboard'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.content {
    padding: 10px;
    @media (min-width: 768px) {
        padding: 0;
    }
    .main-content {
        font-weight: 400;
        color: #1d3e61;

        h4 {
            margin: 0;
            font-weight: 800;
            font-size: 2rem;
            color: #1d3e61;
            text-align: center;
        }

        .gs-logo {
            width: 170px;
        }

        p {
            text-align: center;
            font-size: 1rem;
            margin-bottom: 20px;
            span {
                font-weight: 600;
            }
        }

        .getting-started-video {
            .main-video-container {
                .vid-container {
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    padding-top: 56.25%;
                }

                .responsive-iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
        }

        @media (min-width: 992px) {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 40px 5%;
            margin-left: -70px;

            h4 {
                margin-top: 20px;
                text-align: left;
                max-width: 300px;
            }

            .getting-started-text {
                margin-right: 120px;
                margin-top: -160px;
                p {
                    max-width: 300px;
                    text-align: left;
                }
            }
        }

        @media (min-width: 1250px) {
            .getting-started-video {
                width: 45%;
            }
        }

        @media (min-width: 1600px) {
            h4 {
                font-size: 2.5rem;
                max-width: 380px;
            }
            .gs-logo {
                width: 200px;
            }
        }
    }

    .content-controls {
        margin-top: 20px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        .cc-title {
            font-weight: 600;
            color: #1d3e61;
            margin-bottom: 5px;
            display: block;
            font-size: 14px;
            text-align: center;
        }

        .sc-container {
            display: none;
            @media (min-width: 992px) {
                display: block;
            }
        }

        .video-content {
            text-align: center;

            .video-container {
                margin: 0 auto;
                margin-bottom: 20px;
                vertical-align: top;
                .video-thumbnail {
                    height: 100px;
                    width: 200px;
                    border-color: #aaa;
                    background-color: #eee;
                    background-color: #eee;
                    position: relative;
                    margin: 0 auto;
                    margin-bottom: 5px;
                    .video-watched {
                        background-color: #1d3e61;
                        font-size: 10px;
                        position: absolute;
                        bottom: 5px;
                        right: 5px;
                        color: #fff;
                        padding: 5px;
                        line-height: 1;
                        border-radius: 3px;
                    }
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .video-text {
                    font-size: 13px;
                    line-height: 18px;
                    span {
                        font-weight: 500;
                        font-size: 13px;
                    }
                }

                .custom-text-ellipsis {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                @media (min-width: 768px) {
                    display: inline-block;
                    margin: 10px 20px;
                    max-width: 200px;
                }
            }
        }

        .mobile-vc {
            .video-thumbnail {
                margin: 0 auto;
            }
        }

        @media (min-width: 992px) {
            background-color: #fff;
            padding: 20px 5%;
            margin-top: 0;
            display: block;
        }

        .mobile-vc {
            @media (min-width: 768px) {
                display: none;
            }
        }
    }

    .button-container {
        align-items: center;
        width: 100%;
        height: 60px;

        .custom-btn {
            display: block;
            background-color: #2b93ff !important;
            color: #fff;
            margin: 0;
            max-width: 350px;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .step-parent-container {
        text-align: center;
        @media (min-width: 768px) {
            text-align: center;
        }
    }

    .step-container {
        margin-top: 20px;
        display: inline-block;
        margin-right: 50px;
        .circle-step {
            border-radius: 50%;
            border: 2px solid #2b93ff;
            height: 40px;
            width: 40px;
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            font-weight: 600;
            font-size: 20px;
            background-color: #fafafa;
            position: relative;
            z-index: 99;

            @media (min-width: 768px) {
                left: 88%;
            }
        }

        .circle-step:hover {
            cursor: pointer;
        }

        .step-active {
            background-color: #2b93ff;
            color: #fff;
        }

        .step-content {
            display: none;
            span {
                font-weight: 600;
                color: #1d3e61;
            }
            p {
                font-size: 12px;
                line-height: 20px;
                font-weight: 400;
                margin-top: 0;
            }
        }
        .step-line {
            display: none;
        }

        @media (min-width: 768px) {
            width: 120px;
            vertical-align: top;
            position: relative;
            .step-line {
                display: block;
                position: absolute;
                top: 20px;
                height: 2px;
                width: 170px;
                background-color: #2b93ff;
                z-index: 1;
                left: 50%;
            }

            .step-content {
                display: block;
            }
        }
    }

    .step-container:last-child {
        margin-right: 0;
    }

    .step-content-mobile {
        display: block;

        .step-content {
            display: block;
            text-align: center;
            span {
                font-weight: 600;
                color: #1d3e61;
            }
            p {
                font-size: 12px;
                line-height: 20px;
                font-weight: 400;
                margin-top: 0;
            }
        }

        @media (min-width: 768px) {
            display: none;
        }
    }

    .vid {
        height: 200px;
        width: 200px;
        background-color: red;
    }

    .skip-button {
        background-color: #2b93ff;
        color: #fff !important;
        padding: 10px 40px;
        margin: 30px 0 20px 0;
        display: block;
        max-width: 350px;
        margin: 30px auto 20px auto;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
    }
}
</style>
