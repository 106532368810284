<template>
    <ul class="pagination" :class="paginationClass">
        <li class="page-item prev-page" :class="{ disabled: value === 1, 'no-arrows': noArrows }" v-if="value > 1">
            <a class="page-link" aria-label="Previous" @click="prevPage">
                <md-icon>chevron_left</md-icon>
            </a>
        </li>
        <li v-show="showFirstPageLink" class="page-item">
            <a class="page-link" @click="changePage(1)">{{ 1 }}</a>
        </li>
        <li v-show="showFirstPageLink" class="page-item">
            . . .
        </li>
        <li v-for="item in range(minPage, maxPage)" :key="item" class="page-item" :class="{ active: value === item }">
            <a class="page-link" @click="changePage(item)">{{ item }}</a>
        </li>
        <li v-show="showLastPageLink" class="page-item">
            . . .
        </li>
        <li v-show="showLastPageLink" class="page-item">
            <a class="page-link" @click="changePage(totalPages)">{{ totalPages }}</a>
        </li>
        <li class="page-item page-pre next-page" :class="{ disabled: value === totalPages, 'no-arrows': noArrows }">
            <a v-if="total >= perPage" class="page-link" aria-label="Next" @click="nextPage">
                <md-icon>chevron_right</md-icon>
            </a>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'Pagination',
    props: {
        type: {
            type: String,
            default: 'primary',
            validator: (value) => {
                return ['default', 'primary', 'danger', 'success', 'warning', 'info', 'rose'].includes(value);
            }
        },
        noArrows: Boolean,
        pageCount: {
            type: Number,
            default: 0
        },
        perPage: {
            type: Number,
            default: 10
        },
        total: {
            type: Number,
            default: 0
        },
        value: {
            type: Number,
            default: 1
        },
        enableFirstPageLink: {
            type: Boolean,
            default: false
        },
        enableLastPageLink: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            defaultPagesToDisplay: 5
        };
    },
    computed: {
        paginationClass() {
            return `pagination-${this.type}`;
        },
        totalPages() {
            if (this.pageCount > 0) 
                return this.pageCount;
            if (this.total > 0) {
                return Math.ceil(this.total / this.perPage);
            }
            return 1;
        },
        pagesToDisplay() {
            if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
                return this.totalPages;
            }
            return this.defaultPagesToDisplay;
        },
        minPage() {
            if (this.value >= this.pagesToDisplay) {
                const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
                const newMaxPage = pagesToAdd + this.value;
                if (newMaxPage > this.totalPages) {
                    return this.totalPages - this.pagesToDisplay + 1;
                }
                return this.value - pagesToAdd;
            }
            return 1;
        },
        maxPage() {
            if (this.value >= this.pagesToDisplay) {
                const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
                const newMaxPage = pagesToAdd + this.value;
                if (newMaxPage < this.totalPages) {
                    return newMaxPage;
                }
                return this.totalPages;
            }
            return this.pagesToDisplay;
        },
        showFirstPageLink() {
            return (
                this.enableFirstPageLink && this.totalPages > this.pagesToDisplay && this.value >= this.pagesToDisplay
            );
        },
        showLastPageLink() {
            return (
                this.enableLastPageLink &&
                this.totalPages > this.pagesToDisplay &&
                this.value < this.totalPages - Math.floor(this.pagesToDisplay / 2)
            );
        }
    },
    watch: {
        perPage() {
            this.$emit('input', 1);
        },
        total(val) {
            if (val > this.perPage) 
                return;
            this.$emit('input', 1);
        },
        maxPage(newValue, oldValue) {
            this.$emit('maxPage', newValue);
        }
    },
    methods: {
        range(min, max) {
            const arr = [];
            for (let i = min; i <= max; i += 1) {
                arr.push(i);
            }
            return arr;
        },
        changePage(item) {
            this.$emit('change-page', item);
        },
        nextPage() {
            if (this.value < this.totalPages || this.value === 1) {
                this.$emit('change-page', this.value + 1);
            }
        },
        prevPage() {
            if (this.value > 1) {
                this.$emit('change-page', this.value - 1);
            }
        }
    }
};
</script>
