var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field" },
    [
      _c("md-input", {
        attrs: { type: "hidden" },
        model: {
          value: _vm.selectedOption,
          callback: function ($$v) {
            _vm.selectedOption = $$v
          },
          expression: "selectedOption",
        },
      }),
      _c("vue-select", {
        attrs: {
          reduce: function (r) {
            return r
          },
          label: "type",
          options: _vm.optionList,
          searchable: _vm.$root.isDesktop,
        },
        model: {
          value: _vm.selectedOption,
          callback: function ($$v) {
            _vm.selectedOption = $$v
          },
          expression: "selectedOption",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }