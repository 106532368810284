var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "icon"
    ? _c(
        "md-button",
        {
          class: "md-just-icon md-round md-primary " + _vm.className,
          attrs: { title: "Show qualified drivers" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleShowDrivers($event)
            },
          },
        },
        [_c("md-icon", [_vm._v("people")])],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }