var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-rose" },
        [
          _c(
            "div",
            { staticClass: "card-icon" },
            [_c("md-icon", [_vm._v("post_add")])],
            1
          ),
          _vm.list.length > 0
            ? _c(
                "div",
                { staticClass: "note-type-filter" },
                [
                  _c("vue-select", {
                    attrs: {
                      reduce: function (x) {
                        return x.name
                      },
                      label: "name",
                      options: _vm.noteTypes,
                      placeholder: "Status",
                      searchable: _vm.$root.isDesktop,
                      clearable: false,
                    },
                    model: {
                      value: _vm.selectedNoteType,
                      callback: function ($$v) {
                        _vm.selectedNoteType = $$v
                      },
                      expression: "selectedNoteType",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("md-card-content", [
        _vm.filteredList.length > 0
          ? _c(
              "div",
              _vm._l(_vm.filteredList, function (item, index) {
                return _c("div", { key: index, staticClass: "stop-notes" }, [
                  _c("div", [
                    item.type
                      ? _c("div", { staticClass: "note-type" }, [
                          _c(
                            "span",
                            {
                              staticClass: "custom-badge",
                              class: "status-" + _vm.$_getBadgeColor(item.type),
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.getItemType(item)) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          item.type === "Outgoing SMS" ||
                          item.type === "Outgoing Email"
                            ? _c("span", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        item.createdDate,
                                        _vm.DATE_TYPES.standardDateTime
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ])
                            : _vm._e(),
                          item.stopRef
                            ? _c(
                                "span",
                                { staticClass: "note-reference" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Stop Details",
                                          params: { stopId: item.stopId },
                                        },
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(item.stopRef) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.shipmentRef
                            ? _c(
                                "span",
                                { staticClass: "note-reference" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Shipment Details",
                                          params: {
                                            shipmentId: item.shipmentId,
                                          },
                                        },
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(item.shipmentRef) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    item.rating
                      ? _c(
                          "div",
                          { staticClass: "rating" },
                          _vm._l(5, function (indexRating) {
                            return _c(
                              "md-icon",
                              {
                                key: indexRating,
                                style: {
                                  color:
                                    indexRating <= item.rating
                                      ? "orange"
                                      : "rgba(0,0,0,0.54",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            star_rate\n                        "
                                ),
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "rater-details" }, [
                      item.raterName
                        ? _c("span", [
                            _vm._v("(" + _vm._s(item.stopContactName) + ") -"),
                          ])
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(item.stopAddress))]),
                    ]),
                    _c("div", { staticClass: "note-document" }, [
                      item.documentUrls != null && item.documentUrls.length > 0
                        ? _c(
                            "div",
                            _vm._l(
                              item.documentUrls,
                              function (documentUrl, documentIndex) {
                                return _c("div", { key: documentIndex }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: documentUrl.photoUrl,
                                        download: "",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm.$_getFileIcon(documentUrl.photoName)
                                        ? _c("img", {
                                            staticClass: "file-icon",
                                            attrs: {
                                              src:
                                                "/img/icons/" +
                                                _vm.$_getFileIcon(
                                                  documentUrl.photoName
                                                ) +
                                                ".png",
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(documentUrl.photoName) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ])
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]),
                    item.photoUrls != null && item.photoUrls.length > 0
                      ? _c("div", { staticClass: "note-photos" }, [
                          _c(
                            "div",
                            { staticClass: "note-image-container" },
                            _vm._l(
                              item.photoUrls,
                              function (photoUrl, photoIndex) {
                                return _c("div", { key: photoIndex }, [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewPhoto(
                                            item.photoUrls,
                                            photoUrl
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "note-image",
                                        attrs: { src: photoUrl.thumbnailUrl },
                                        on: {
                                          error: function ($event) {
                                            return _vm.$_notePhotoLoading(
                                              $event,
                                              photoUrl.thumbnailUrl
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "note-preview",
                        domProps: {
                          innerHTML: _vm._s(_vm.$_compiledMarkdown(item.note)),
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.note) +
                            "\n                    "
                        ),
                      ]
                    ),
                    item.metadata != null
                      ? _c(
                          "div",
                          { staticClass: "note-metadata" },
                          _vm._l(item.metadata, function (metadata, metaindex) {
                            return _c("div", { key: metaindex }, [
                              metaindex === "questions"
                                ? _c(
                                    "div",
                                    _vm._l(metadata, function (x, j) {
                                      return _c(
                                        "span",
                                        { key: j },
                                        [
                                          typeof x === "string" ||
                                          x instanceof String
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "questions" },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "label" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getQuestionLabel(
                                                              j,
                                                              item.type
                                                            )
                                                          ) + ":"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.isMultilineAnswer(x)
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "answers-multiline",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(x) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "answers",
                                                          },
                                                          [_vm._v(_vm._s(x))]
                                                        ),
                                                  ]
                                                ),
                                                _c("br"),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                : metaindex !== "runNumber"
                                ? _c("div", [
                                    _vm.checkMetadataValue(metadata)
                                      ? _c(
                                          "div",
                                          { staticClass: "metadata-label" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$_camelCaseToSentenceCase(
                                                    metaindex
                                                  )
                                                ) +
                                                ":\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    typeof metadata === "object"
                                      ? _c(
                                          "div",
                                          _vm._l(metadata, function (x, j) {
                                            return _c("span", { key: j }, [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(x.barcode) +
                                                  "\n                                        "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "barcode-notes",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        x.note
                                                          ? ", " + x.note
                                                          : ""
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _c("br"),
                                            ])
                                          }),
                                          0
                                        )
                                      : _c("div", [_vm._v(_vm._s(metadata))]),
                                  ])
                                : _vm._e(),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "note-author" },
                      [
                        _c(
                          "md-avatar",
                          [
                            _c("img", {
                              staticClass: "profile-image",
                              attrs: {
                                src:
                                  item.createdByPhotoUrl == null
                                    ? _vm.$root.defaultPhotoUrl
                                    : item.createdByPhotoUrl,
                                alt: "",
                              },
                              on: { error: _vm.$_setDefaultBrokenImage },
                            }),
                            _c(
                              "md-tooltip",
                              { attrs: { "md-direction": "top" } },
                              [
                                _c("i", [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.createdDate,
                                          _vm.DATE_TYPES.standardDateTime
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                ]),
                                _c("br"),
                                _vm._v(
                                  "\n                                by: " +
                                    _vm._s(item.createdByUserFullName) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              }),
              0
            )
          : _c("div", { staticClass: "text-center" }, [
              _c("p", { staticClass: "grayedout-text" }, [
                _vm._v("No notes found"),
              ]),
            ]),
      ]),
      _vm.isAllowedToAddNotes
        ? _c(
            "md-card-actions",
            { attrs: { "md-alignment": "right" } },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-rose",
                  on: {
                    click: function ($event) {
                      return _vm.handleAddNotes()
                    },
                  },
                },
                [_vm._v("Add note")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }