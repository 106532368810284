<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Proof Of Delivery Configuration'
                        }"
                        class="custom-back-button"
                    >
                        <i class="material-icons">keyboard_backspace</i>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Setting</h3>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'Proof Of Delivery Configuration'
                        }"
                    >
                        <h3 class="title">
                            &nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.proofOfDeliveryConfiguration') }}
                        </h3>
                    </router-link>
                    <h3 class="title">
                        &nbsp;&nbsp; > &nbsp;&nbsp; {{ isCreateMode ? 'Create' : proofOfDeliveryTemplate.name }}
                    </h3>
                </div>
            </div>
            <div class="custom-toolbar custom-toolbar-template-name">
                <div class="custom-toolbar-start">
                    <form-wrapper :validator="$v" class="form-wrapper">
                        <form-group label="Template Name" name="proofOfDeliveryTemplate.name">
                            <md-input v-model="proofOfDeliveryTemplate.name" />
                        </form-group>
                    </form-wrapper>
                </div>
                <div class="custom-toolbar-end">
                    <div class="header-button-container">
                        <md-button class="md-button md-primary header-button" @click="validateForm">
                            {{ isCreateMode ? 'Add' : 'Save' }}
                        </md-button>
                        <md-button
                            class="md-button md-danger header-button"
                            v-if="!isCreateMode && !proofOfDeliveryTemplate.isDefault"
                            @click="deleteTemplate"
                        >
                            Delete
                        </md-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>fact_check</md-icon>
                    </div>
                </md-card-header>
                <md-card-content>
                    <proof-of-delivery-template
                        ref="proofOfDeliveryTemplate"
                        :proof-of-delivery-template="proofOfDeliveryTemplate"
                    />
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import ProofOfDeliveryTemplate from './ProofOfDeliveryTemplate';

export default {
    name: 'ProofOfDeliveryTemplatePage',
    mixins: [GeneralMixin],
    data() {
        return {
            proofOfDeliveryTemplate: {
                podTemplateId: null,
                name: null,
                isDefault: false,
                configuration: [],
                assignedCustomers: []
            }
        };
    },
    validations: {
        proofOfDeliveryTemplate: {
            name: {
                required,
                maxLength: maxLength(50)
            }
        }
    },
    components: {
        ProofOfDeliveryTemplate
    },
    async mounted() {
        this.$_handleLoaderState(true);

        if (this.isCreateMode) {
            this.createTemplate();
        } else {
            await this.getTemplate();
        }

        this.$_handleLoaderState(false);
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam'
        }),
        isCreateMode() {
            return this.$route.meta.mode === 'create';
        }
    },
    methods: {
        async getTemplate() {
            this.proofOfDeliveryTemplate.podTemplateId = this.$route.params.podTemplateId;
            const api = `/api/teams/proof-of-delivery/${this.proofOfDeliveryTemplate.podTemplateId}`;
            const response = await handleRequests(api);
            this.proofOfDeliveryTemplate = Object.assign(this.proofOfDeliveryTemplate, response.data);
            this.$refs.proofOfDeliveryTemplate.setDefaultConfiguration(response.data.configuration);
        },
        createTemplate() {
            this.proofOfDeliveryTemplate.podTemplateId = null;
            this.proofOfDeliveryTemplate.name = null;
            this.proofOfDeliveryTemplate.isDefault = false;
            this.proofOfDeliveryTemplate.configuration = [];
            this.proofOfDeliveryTemplate.assignedCustomers = [];
            this.$refs.proofOfDeliveryTemplate.setDefaultConfiguration(null);
        },
        async validateForm() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                await this.verifyCustomerAssignment(this.saveTemplate);
            }
        },
        async saveTemplate() {
            this.$_handleLoaderState(true, this.isCreateMode ? 'ADDING...' : 'SAVING...');
            const payload = {
                method: this.isCreateMode ? 'post' : 'put',
                data: this.proofOfDeliveryTemplate
            };
            const api = '/api/teams/proof-of-delivery';
            try {
                const response = await handleRequests(api, payload);
                this.$notify({
                    message: this.isCreateMode
                        ? 'Proof of Delivery template added!'
                        : 'Proof of Delivery template saved!',
                    type: 'success'
                });

                if (this.isCreateMode) {
                    this.proofOfDeliveryTemplate.podTemplateId = response.data;
                }

                this.$_handleLoaderState(false);
                this.$refs.proofOfDeliveryTemplate.closeAlert();
                this.$router.push('list');
            } catch (e) {
                this.$_handleLoaderState(false);
                const message = this.isCreateMode
                    ? 'Cannot add proof of delivery template.'
                    : 'Cannot save proof of delivery template.';
                showErrorMessage(this, message, e);
            }
        },
        async verifyCustomerAssignment(onSuccess) {
            this.$_handleLoaderState(true, 'VERIFYING');
            const { proofOfDeliveryTemplate } = this;
            try {
                const customerIds = proofOfDeliveryTemplate.assignedCustomers
                    .map((x) => `customerId=${encodeURIComponent(x.customerId)}`)
                    .join('&');
                const api = `/api/teams/proof-of-delivery/verify-assignment?${customerIds}`;
                const response = await handleRequests(api);

                this.$_handleLoaderState(false);

                const customersAssignedToOtherTemplates = (response.data ?? []).filter(
                    (x) => x.podTemplateId !== proofOfDeliveryTemplate.podTemplateId
                );

                if (customersAssignedToOtherTemplates.length === 0) {
                    await onSuccess();
                    return;
                }

                const assignedCustomerMessage = customersAssignedToOtherTemplates
                    .map(
                        (x) =>
                            proofOfDeliveryTemplate.assignedCustomers.filter((y) => y.customerId === x.customerId)[0]
                                .name
                    )
                    .join(', ');
                const moreThanOneCustomer = customersAssignedToOtherTemplates.length > 1;
                const confirmMessage = `<b>${assignedCustomerMessage}</b> ${
                    moreThanOneCustomer ? 'are' : 'is'
                } assigned to another template.<br/> <br/>Are you sure you want to reallocate ${
                    moreThanOneCustomer ? 'these customers' : 'this customer'
                } to this template?`;

                this.$messageBox
                    .show({
                        title: 'Confirm reallocation',
                        body: confirmMessage,
                        buttons: ['Confirm', 'Cancel']
                    })
                    .then(async (response) => {
                        if (response.toLowerCase() === 'confirm') {
                            await onSuccess();
                        }
                    });
            } catch (e) {
                this.$_handleLoaderState(false);
                const message = 'Cannot verify customer template assignment';
                showErrorMessage(this, message, e);
            }
        },
        async deleteTemplate() {
            const deleteMessage = 'Are you sure you want to delete this proof of delivery template?'.concat(
                this.proofOfDeliveryTemplate.assignedCustomers &&
                    this.proofOfDeliveryTemplate.assignedCustomers.length !== 0
                    ? '<br /> <br /> Warning: Assigned customers will revert to the default template.'
                    : ''
            );

            this.$messageBox
                .show({
                    title: 'Delete proof of delivery template',
                    body: deleteMessage,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.$_handleLoaderState(true, 'DELETING...');
                        const payload = {
                            method: 'delete'
                        };
                        const api = `/api/teams/proof-of-delivery/${this.$route.params.podTemplateId}`;
                        try {
                            await handleRequests(api, payload);
                            this.$notify({
                                message: 'Proof of Delivery template deleted!',
                                type: 'success'
                            });

                            this.$_handleLoaderState(false);
                            this.$router.push('list');
                        } catch (e) {
                            this.$_handleLoaderState(false);
                            const message = 'Cannot delete proof of delivery template.';
                            showErrorMessage(this, message, e);
                        }
                    }
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.card-icon {
    background: #0bda8e !important;
}
::v-deep .form-wrapper {
    width: 100% !important;
}
.custom-toolbar-template-name {
    margin-top: 10px;
}
.custom-back-button {
    margin-bottom: 10px;
    margin-right: 10px;
    color: rgb(51, 51, 51) !important;
}
</style>
