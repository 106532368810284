var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-overlay" },
    [
      _c("DispatchMapboxCanvas", {
        attrs: {
          "shipment-details": _vm.shipmentDetails,
          "offer-list": _vm.offerList,
          "pinned-offer": _vm.pinnedOffer,
          "highlighted-driver-id": _vm.highlightedDriverId,
          "focused-driver": _vm.focusedDriver,
          "highlighted-stop-id": _vm.highlightedStopId,
          "route-display": _vm.routeDisplay,
        },
        on: {
          "update:pinnedOffer": function ($event) {
            _vm.pinnedOffer = $event
          },
          "update:pinned-offer": function ($event) {
            _vm.pinnedOffer = $event
          },
          "update:highlightedDriverId": function ($event) {
            _vm.highlightedDriverId = $event
          },
          "update:highlighted-driver-id": function ($event) {
            _vm.highlightedDriverId = $event
          },
          "update:focusedDriver": function ($event) {
            _vm.focusedDriver = $event
          },
          "update:focused-driver": function ($event) {
            _vm.focusedDriver = $event
          },
          "update:highlightedStopId": function ($event) {
            _vm.highlightedStopId = $event
          },
          "update:highlighted-stop-id": function ($event) {
            _vm.highlightedStopId = $event
          },
        },
      }),
      _c("OfferListPanel", {
        attrs: {
          "offer-list": _vm.offerList,
          "pinned-offer": _vm.pinnedOffer,
          "highlighted-driver-id": _vm.highlightedDriverId,
          "focused-driver": _vm.focusedDriver,
        },
        on: {
          "update:pinnedOffer": function ($event) {
            _vm.pinnedOffer = $event
          },
          "update:pinned-offer": function ($event) {
            _vm.pinnedOffer = $event
          },
          "update:highlightedDriverId": function ($event) {
            _vm.highlightedDriverId = $event
          },
          "update:highlighted-driver-id": function ($event) {
            _vm.highlightedDriverId = $event
          },
          "update:focusedDriver": function ($event) {
            _vm.focusedDriver = $event
          },
          "update:focused-driver": function ($event) {
            _vm.focusedDriver = $event
          },
        },
      }),
      _c("ShipmentDetailsPanel", {
        attrs: {
          "shipment-details": _vm.shipmentDetails,
          "shipment-list": _vm.shipmentList,
        },
        on: { navigateToShipmentId: _vm.loadShipment },
      }),
      _vm.pinnedOffer
        ? _c(
            "div",
            [
              _vm.pinnedDriverDetails
                ? _c("DriverProfilePanel", {
                    attrs: {
                      "pinned-offer": _vm.pinnedOffer,
                      "shipment-details": _vm.shipmentDetails,
                      "pinned-driver-details": _vm.pinnedDriverDetails,
                      "photo-url":
                        _vm.pinnedDriverDetails.photoUrl ||
                        _vm.$root.defaultPhotoUrl,
                    },
                    on: { driverAssigned: _vm.onDriverAssigned },
                  })
                : _vm._e(),
              _c("StopListPanel", {
                attrs: {
                  "pinned-offer": _vm.pinnedOffer,
                  "pinned-driver-details": _vm.pinnedDriverDetails,
                  "highlighted-stop-id": _vm.highlightedStopId,
                  "route-display": _vm.routeDisplay,
                  "shipment-details": _vm.shipmentDetails,
                },
                on: {
                  "update:highlightedStopId": function ($event) {
                    _vm.highlightedStopId = $event
                  },
                  "update:highlighted-stop-id": function ($event) {
                    _vm.highlightedStopId = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("ActionsPanel", {
        attrs: {
          "shipment-details": _vm.shipmentDetails,
          "pinned-offer": _vm.pinnedOffer,
          "route-display": _vm.routeDisplay,
          "calculation-date": _vm.calculationDate,
        },
        on: {
          recalculateOffers: _vm.recalculateOffers,
          "update:routeDisplay": function ($event) {
            _vm.routeDisplay = $event
          },
          "update:route-display": function ($event) {
            _vm.routeDisplay = $event
          },
        },
      }),
      _vm.assignedName
        ? _c(
            "div",
            { staticClass: "already-assigned-alert alert alert-danger" },
            [
              _c("b", [_vm._v("NOTE -")]),
              _vm._v(
                "\n        This shipment has already been assigned to " +
                  _vm._s(_vm.assignedName) +
                  "\n    "
              ),
            ]
          )
        : _vm.isOfferListStale
        ? _c(
            "div",
            {
              staticClass:
                "already-assigned-alert alert alert-warning alert-calculation-info",
              on: { click: _vm.recalculateOffers },
            },
            [
              _c("b", [_vm._v("NOTE -")]),
              _vm._v(
                "\n        These offers were calculated " +
                  _vm._s(_vm.offerListAgeText) +
                  " and may be stale. Click here to refresh them.\n    "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }