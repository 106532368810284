let hidden;
let visibilityChange;
if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}
const visibilityVariables = {
    hidden,
    visibilityChange
};

export const VisibilityHandler = {
    bind(el, binding, vNode) {
        const { handleVisibilityChange } = vNode.context;
        document.addEventListener(
            [visibilityVariables.visibilityChange],
            () => {
                handleVisibilityChange(document[visibilityVariables.hidden]);
            },
            false
        );
    },
    unbind() {
        document.removeEventListener([visibilityVariables.visibilityChange], () => {}, false);
    }
};
