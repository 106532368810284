var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "register-custom-layout md-layout md-alignment-center-center",
    },
    [
      _c("div", { staticClass: "md-layout-item" }, [
        _c(
          "div",
          [
            _c("img", {
              attrs: { src: "/img/icons/logo.png", alt: "Alternate Text" },
            }),
            _c("h1", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v(
                "\n                Connecting you with your customers.\n            "
              ),
            ]),
            _c(
              "md-button",
              {
                staticClass: "btn-blue md-simple md-success md-lg",
                attrs: { href: "/login" },
              },
              [_vm._v("\n                Sign in\n            ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "md-layout-item" },
        [
          _c(
            "login-card",
            [
              _c(
                "h4",
                {
                  staticClass: "title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _vm._v("\n                Create\n                "),
                  _c("br"),
                  _vm._v("\n                Your account\n            "),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "social-logins md-layout md-alignment-center-center",
                  attrs: { slot: "social-logins" },
                  slot: "social-logins",
                },
                [
                  _c("div", { staticClass: "md-layout-item" }, [
                    _c("p", { staticClass: "description" }, [
                      _vm._v(
                        "\n                        Sign in using\n                    "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "md-layout-item" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-just-icon md-simple md-white",
                          attrs: { href: "#facebook" },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.facebook,
                              alt: "facebook",
                              height: "40",
                              width: "40",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "md-button",
                        {
                          staticClass: "md-just-icon md-simple md-white",
                          attrs: { href: "#google" },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.google,
                              alt: "google",
                              height: "40",
                              width: "40",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "p",
                {
                  staticClass: "description",
                  attrs: { slot: "description" },
                  slot: "description",
                },
                [
                  _vm._v(
                    "\n                Or sign in using your account\n            "
                  ),
                ]
              ),
              _c(
                "md-field",
                {
                  staticClass: "md-form-group",
                  attrs: { slot: "inputs" },
                  slot: "inputs",
                },
                [
                  _c("md-input", {
                    attrs: { type: "text", placeholder: "Name" },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "md-field",
                {
                  staticClass: "md-form-group",
                  attrs: { slot: "inputs" },
                  slot: "inputs",
                },
                [
                  _c("md-input", {
                    attrs: { type: "email", placeholder: "Email" },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "md-field",
                {
                  staticClass: "md-form-group",
                  attrs: { slot: "inputs" },
                  slot: "inputs",
                },
                [
                  _c("md-input", {
                    attrs: { placeholder: "Password" },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "md-field",
                {
                  staticClass: "md-form-group",
                  attrs: { slot: "inputs" },
                  slot: "inputs",
                },
                [
                  _c("md-input", {
                    attrs: { placeholder: "Confirm Password" },
                    model: {
                      value: _vm.confirmPassword,
                      callback: function ($$v) {
                        _vm.confirmPassword = $$v
                      },
                      expression: "confirmPassword",
                    },
                  }),
                ],
                1
              ),
              _c(
                "md-button",
                {
                  staticClass: "md-simple md-success md-lg",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [_vm._v("\n                Sign up\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }