<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
                <md-icon>receipt_long</md-icon>
            </div>
        </md-card-header>
        <md-card-content>
            <p v-if="!lines || lines.length === 0" class="grayedout-text">No item provided.</p>
            <div v-else>
                <md-table class="context-menu-support custom-paginated-table">
                    <md-table-row>
                        <md-table-head>Description</md-table-head>
                        <md-table-head>Quantity</md-table-head>
                        <md-table-head>Unit Cost (Ex-Tax)</md-table-head>
                        <md-table-head>Total Amount (Ex-Tax)</md-table-head>
                        <md-table-head>Stop Ref</md-table-head>
                        <md-table-head>Stop Status</md-table-head>
                    </md-table-row>
                    <md-table-row v-for="line in lines" :key="line.teamMemberInvoiceLineId">
                        <md-table-cell>{{ line.description }}</md-table-cell>
                        <md-table-cell>
                            {{ line.quantity }}
                            <span class="quantity-type">{{ line.quantityType }}</span>
                        </md-table-cell>
                        <md-table-cell>{{ line.unitPriceExTax | currency(line.priceCurrency) }}</md-table-cell>
                        <md-table-cell>
                            {{ (line.unitPriceExTax * line.quantity) | currency(line.priceCurrency) }}
                        </md-table-cell>
                        <md-table-cell>
                            <router-link
                                :to="{ name: 'Stop Details', params: { stopId: line.stopId } }"
                                target="_blank"
                            >
                                {{ line.stopRef }}
                            </router-link>
                        </md-table-cell>
                        <md-table-cell>
                            {{ line.stopStatus ? $t('inputs.stopStatuses.' + line.stopStatus) : '' }}
                        </md-table-cell>
                    </md-table-row>
                </md-table>
            </div>
        </md-card-content>
    </md-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'InvoiceLines',
    mixins: [GeneralMixin],
    components: {},
    props: {
        lines: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    methods: {},
    mounted() {}
};
</script>

<style lang="scss" scoped>
.custom-btn {
    margin-right: 16px;
}
.action-button {
    display: inline-block;
}
</style>
