<template>
    <form>
        <md-card>
            <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
                <div class="card-icon">
                    <md-icon>perm_identity</md-icon>
                </div>
                <h4 class="title">
                    Edit Profile -
                    <small>Complete your profile</small>
                </h4>
            </md-card-header>

            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>Company (disabled)</label>
                            <md-input v-model="disabled" disabled />
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>User Name</label>
                            <md-input v-model="username" type="text" />
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>Email Address</label>
                            <md-input v-model="emailadress" type="email" />
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-50">
                        <md-field>
                            <label>First Name</label>
                            <md-input v-model="firstname" type="text" />
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-50">
                        <md-field>
                            <label>Last Name</label>
                            <md-input v-model="lastname" type="text" />
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-field>
                            <label>Adress</label>
                            <md-input v-model="address" type="text" />
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>City</label>
                            <md-input v-model="city" type="text" />
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>Country</label>
                            <md-input v-model="country" type="text" />
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>Postal Code</label>
                            <md-input v-model="code" type="number" />
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-field maxlength="5">
                            <label>About Me</label>
                            <md-textarea v-model="aboutme" />
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100 text-right">
                        <md-button class="md-raised md-success mt-4">
                            Update Profile
                        </md-button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </form>
</template>
<script>
export default {
    name: 'EditProfileForm',
    props: {
        headerColor: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            username: null,
            disabled: null,
            emailadress: null,
            lastname: null,
            firstname: null,
            address: null,
            city: null,
            country: null,
            code: null,
            aboutme: "Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo."
        };
    },
    methods: {
        getClass(headerColor) {
            return `md-card-header-${headerColor}`;
        }
    }
};
</script>
