<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Load Saved Model</h4>
        </div>
        <div class="modal-body route-list">
            <div class="trip-date-field">
                <md-datepicker v-model="tripDate" md-immediately></md-datepicker>
            </div>
            <ul>
                <li v-for="route in savedRouteList" :key="route">
                    <div class="name" @click="handleSelectName(route)">{{ decodeURIComponent(route) }}</div>
                </li>
            </ul>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import moment from 'moment';
import { DATE_TYPES } from '@/utils/constants';

export default {
    name: 'RoutingSettingsModal',
    mixins: [GeneralMixin],
    props: {
        resolve: {
            type: Function,
            default: () => {}
        },
        modelTripDate: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            savedRouteList: [],
            tripDate: this.modelTripDate
        };
    },
    methods: {
        async getSaveRouteList() {
            this.$_handleLoaderState(true);
            const api = `api/stops/optimise/routes/${moment(this.tripDate).format(DATE_TYPES.internationalDate)}/List`;
            handleRequests(api)
                .then((response) => {
                    this.$_handleLoaderState(false);
                    const { data } = response;
                    this.savedRouteList = data.map((x) => decodeURIComponent(x));
                })
                .catch(() => {
                    this.$_handleLoaderState(false);
                });
        },
        handleSelectName(name) {
            this.$_handleLoaderState(true);
            const api = `api/stops/optimise/routes/${moment(this.tripDate).format(
                DATE_TYPES.internationalDate
            )}/${name}`;
            handleRequests(api)
                .then((response) => {
                    this.$_handleLoaderState(false);
                    const { data } = response;
                    this.resolve({ data });
                })
                .catch(() => {
                    this.$_handleLoaderState(false);
                });
        }
    },
    watch: {
        tripDate: 'getSaveRouteList'
    },
    mounted() {
        this.getSaveRouteList();
    }
};
</script>
<style lang="scss" scoped>
.modal-container {
    max-width: 250px;
    .route-list {
        padding: 0px 24px 16px 24px;
        .trip-date-field {
            ::v-deep .md-clear {
                display: none;
            }
        }
        ul {
            padding: 10px;
            padding-top: 0;
            margin: 0;
            list-style: none;
            font-size: 14px;
            overflow-y: auto;
            max-height: 140px;
            li {
                cursor: pointer;
                font-size: 14px;
                display: flex;
                &:hover {
                    font-weight: bold;
                }
                .name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
</style>
