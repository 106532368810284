const types = {
    text: 'Text',
    date: 'Date',
    multiLineText: 'MultiLineText',
    checkBox: 'CheckBox'
};

const assetTypes = {
    tracker: 'Tracker',
    vehicle: 'Vehicle',
    equipment: 'Equipment',
    trailer: 'Trailer',
    personnel: 'Personnel',
    any: 'Any'
};

export const personnelDefaults = [
    {
        name: 'firstName',
        label: 'First Name',
        type: types.text,
        assetType: assetTypes.personnel
    },
    {
        name: 'lastName',
        label: 'Last Name',
        type: types.text,
        assetType: assetTypes.personnel
    },
    {
        name: 'email',
        label: 'Email Address',
        type: types.text,
        assetType: assetTypes.personnel
    },
    {
        name: 'phone',
        label: 'Phone',
        type: types.text,
        assetType: assetTypes.personnel
    },
    {
        name: 'employeeNumber',
        label: 'Employee Number',
        type: types.text,
        assetType: assetTypes.personnel
    }
];

export const vehicleDefaults = [
    {
        name: 'make',
        label: 'Vehicle Make',
        type: types.text,
        assetType: assetTypes.vehicle
    },
    {
        name: 'model',
        label: 'Vehicle Model',
        type: types.text,
        assetType: assetTypes.vehicle
    },
    {
        name: 'modelYear',
        label: 'Vehicle Model Year',
        type: types.text,
        assetType: assetTypes.vehicle
    },
    {
        name: 'color',
        label: 'Vehicle Color',
        type: types.text,
        assetType: assetTypes.vehicle
    },
    {
        name: 'vehicleVIN',
        label: 'Vehicle Identification Number',
        type: types.text,
        assetType: assetTypes.vehicle
    },
    {
        name: 'licensePlate',
        label: 'Vehicle License Plate',
        type: types.text,
        assetType: assetTypes.vehicle
    },
    {
        name: 'vehicleRegistrationExpiryDate',
        label: 'Vehicle Registration Expiry Date',
        type: types.date,
        assetType: assetTypes.vehicle
    },
    {
        name: 'vehicleType',
        label: 'Vehicle Type',
        type: types.text,
        assetType: assetTypes.vehicle
    }
];

export const trackerDefaults = [
    {
        assetType: assetTypes.tracker,
        label: 'Brand',
        name: 'brand',
        type: types.text
    },
    {
        assetType: assetTypes.tracker,
        label: 'Model',
        name: 'model',
        type: types.text
    },
    {
        assetType: assetTypes.tracker,
        label: 'Model Year',
        name: 'modelYear',
        type: types.text
    },
    {
        assetType: assetTypes.tracker,
        label: 'IMEI',
        name: 'trackerReference',
        type: types.text
    }
];
