<template>
    <div class="details-page" v-if="tripDetails != null">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-end" v-if="!$root.isMobileOnly && !isReadOnlyUser">
                        <div class="header-button-container">
                            <trip-status-button
                                :trip="tripDetails"
                                :is-modal="false"
                                @statusUpdated="handleChangedTripStatus"
                                @statusHistoryUpdated="handleTripStatusHistoryUpdated"
                                class-name="md-sm"
                                class="status-btn"
                            />
                            <md-button title="Reassign Trip" @click.stop="reassignTrip">
                                <span>Reassign Trip</span>
                            </md-button>
                            <md-button title="Edit trip details" @click.stop="handleTripUpdate">
                                <span>Edit</span>
                            </md-button>
                            <generate-label-button
                                v-if="tripDetails.stopList.length > 0"
                                :id="tripDetails.tripId"
                                :use-icon="false"
                                :type="'Trip'"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="md-layout md-alignment-top-left content-layout-panel">
            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-66 md-xlarge-size-50">
                <div class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-blue">
                            <div class="card-icon" v-if="tripDetails.assignedTo.photoUrl === null">
                                <md-icon>description</md-icon>
                            </div>
                            <div class="card-icon profile-image" v-if="tripDetails.assignedTo.photoUrl !== null">
                                <img :src="tripDetails.assignedTo.photoUrl"
                                     @error="$_setDefaultBrokenImage"
                                     alt="avatar" />
                            </div>
                        </md-card-header>

                        <md-card-content>
                            <div class="single-row three-column-box">
                                <div class="custom-md-field details-box">
                                    <div class="custom-label">Driver</div>
                                    <router-link v-if="tripDetails.assignedTo.publicUserId"
                                                 :to="{
                                            name: 'Team Member Profile',
                                            params: { publicUserId: tripDetails.assignedTo.publicUserId }
                                        }"
                                                 target="_blank">
                                        {{ tripDetails.assignedTo.fullName || tripDetails.assignedTo.publicUserId }}
                                    </router-link>
                                    <a v-else title="Reassign Trip" @click.stop="reassignTrip">Unassigned</a>
                                </div>
                                <div class="details-box"
                                     v-if="
                                        !tripDetails.rateGroupId &&
                                            tripDetails.assignedTo.rateGroupId &&
                                            showServicePackage()
                                    ">
                                    <label>Driver Service Package</label>
                                    <span class="value">
                                        {{ tripDetails.assignedTo.rateGroupName }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label>Trip Date</label>
                                    <span class="value">
                                        {{ tripDetails.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                    </span>
                                </div>
                                <div class="details-box" v-if="tripDetails.completedDate">
                                    <label>Completed Date</label>
                                    <span class="value">
                                        {{ tripDetails.completedDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                                    </span>
                                </div>
                            </div>
                            <div class="single-row three-column-box">
                                <div v-if="tripDetails.startTime" class="details-box">
                                    <label>Start Time</label>
                                    <span>
                                        {{ convertTimeFormat(tripDetails.startTime) }}
                                    </span>
                                </div>
                                <div v-if="tripDetails.actualStartTime" class="details-box">
                                    <label>Actual Start Time</label>
                                    <span>
                                        {{ tripDetails.actualStartTime | dateTimeFormat(DATE_TYPES.standardDate) }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label>Start Location</label>
                                    <span>{{ tripDetails.locationDetails.startAddress }}</span>
                                </div>
                            </div>
                            <div class="single-row three-column-box">
                                <div v-if="tripDetails.endTime" class="details-box">
                                    <label v-if="tripDetails.endTime != null">End Time</label>
                                    <span v-if="tripDetails.endTime != null">
                                        {{ convertTimeFormat(tripDetails.endTime) }}
                                    </span>
                                </div>
                                <div v-if="tripDetails.actualEndTime && tripDetails.status === 'Complete'"
                                     class="details-box">
                                    <label>Actual End Time</label>
                                    <span>
                                        {{ tripDetails.actualEndTime | dateTimeFormat(DATE_TYPES.standardDate) }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label v-if="tripDetails.locationDetails.endAddress != null">End Location</label>
                                    <span v-if="tripDetails.locationDetails.endAddress != null">
                                        {{ tripDetails.locationDetails.endAddress }}
                                    </span>
                                </div>
                            </div>
                            <div v-if="teamRegionName" class="single-row">
                                <div class="details-box">
                                    <label>Team Region</label>
                                    <span>{{ teamRegionName }}</span>
                                </div>
                            </div>
                            <div v-if="tripDetails.rateGroupName" class="single-row">
                                <div class="details-box">
                                    <label>Service Package</label>
                                    <span>{{ tripDetails.rateGroupName }}</span>
                                </div>
                            </div>

                            <div v-if="tripDetails.customerInvoiceId" class="details-box">
                                <label class="label">Customer Invoice:</label>

                                <span class="value">
                                    <div :class="
                                            `custom-badge payment-status-${tripDetails.customerInvoiceStatus
                                                .toLowerCase()
                                                .replace(/\s+/g, '')}`
                                        ">
                                        {{ $t('inputs.paymentStatuses.' + tripDetails.customerInvoiceStatus) }}
                                    </div>

                                    <router-link :to="{
                                            name: 'Customer Invoice Details',
                                            params: { invoiceId: tripDetails.customerInvoiceId }
                                        }"
                                                 target="_blank">
                                        {{ tripDetails.customerInvoiceNumber }}
                                    </router-link>
                                </span>
                            </div>

                            <div v-if="tripDetails.teamMemberInvoiceId" class="details-box">
                                <label class="label">Team Member Invoice:</label>

                                <span class="value">
                                    <div :class="
                                            `custom-badge payment-status-${tripDetails.teamMemberInvoiceStatus
                                                .toLowerCase()
                                                .replace(/\s+/g, '')}`
                                        ">
                                        {{ $t('inputs.paymentStatuses.' + tripDetails.teamMemberInvoiceStatus) }}
                                    </div>

                                    <router-link :to="{
                                            name: 'Team Member Invoice Details',
                                            params: { invoiceId: tripDetails.teamMemberInvoiceId }
                                        }"
                                                 target="_blank">
                                        {{ tripDetails.teamMemberInvoiceNumber }}
                                    </router-link>
                                </span>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>

                <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100 map-box">
                    <map-overlay :trip-details="tripDetails" />
                </div>

                <div class="
                        md-layout-item
                        md-small-size-100
                        md-large-size-100
                        md-xlarge-size-100
                        md-xsmall-hide
                        md-small-hide-
                        md-medium-hide
                        md-large-hide
                    ">
                    <notes :list="tripDetails.notes" :trip-id="tripDetails.tripId" @notesAdded="handleAddedTripNote" />
                </div>

                <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100 md-xlarge-hide">
                    <stop-list-table :trip-details="stopListData" />
                </div>
                <div v-if="tripDetails.driverBreakList && tripDetails.driverBreakList.length > 0" class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100 md-xlarge-hide">
                    <driver-break-list-table :trip-details="stopListData" />
                </div>
            </div>

            <div class="md-layout-item md-small-size-100 md-layout md-medium-size-100 md-large-size-33 md-xlarge-size-50">
                <div>
                    <trip-contact-details class="
                            md-layout-item
                            md-small-size-100
                            md-large-size-100
                            md-xlarge-size-100
                            md-xsmall-hide
                            md-medium-hide
                            md-large-hide"
                                          :trip-details="tripDetails" />
                </div>
                <div class="
                        md-layout-item
                        md-small-size-100
                        md-medium-size-50
                        md-large-size-100
                        md-xlarge-size-50
                        md-xlarge-hide
                    ">
                    <assignment-history-timeline :list="tripDetails.tripUserHistory" />
                </div>
                <div class="
                        md-layout-item
                        md-small-size-100
                        md-medium-size-50
                        md-large-size-100
                        md-xlarge-size-50
                        md-xlarge-hide
                    ">
                    <status-history-timeline :list="tripDetails.statusHistory" />
                </div>
                <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-50 md-xlarge-hide">
                    <notes :list="tripDetails.notes" class="custom-note-card-large" :trip-id="tripDetails.tripId" />
                </div>
                <div class="
                        md-layout-item
                        md-small-size-100
                        md-large-size-100
                        md-xlarge-size-100
                        md-xlarge-size-50
                        md-xsmall-hide
                        md-small-hide-
                        md-medium-hide
                        md-large-hide
                    ">
                    <stop-list-table :trip-details="stopListData" />
                </div>
                <div v-if="tripDetails.driverBreakList && tripDetails.driverBreakList.length > 0" class="
                        md-layout-item
                        md-small-size-100
                        md-large-size-100
                        md-xlarge-size-100
                        md-xlarge-size-50
                        md-xsmall-hide
                        md-small-hide-
                        md-medium-hide
                        md-large-hide
                    ">
                    <driver-break-list-table :trip-details="tripDetails" />
                </div>
                <div class="
                        md-layout-item
                        md-small-size-100
                        md-medium-size-50
                        md-large-size-100
                        md-xlarge-size-50
                        md-xsmall-hide
                        md-small-hide-
                        md-medium-hide
                        md-large-hide
                    ">
                    <assignment-history-timeline :list="tripDetails.tripUserHistory" />
                </div>
                <div class="
                        md-layout-item
                        md-small-size-100
                        md-medium-size-50
                        md-large-size-100
                        md-xlarge-size-50
                        md-xsmall-hide
                        md-small-hide-
                        md-medium-hide
                        md-large-hide
                    ">
                    <status-history-timeline :list="tripDetails.statusHistory" />
                </div>

                <div v-if="!isIndividualUser"
                     class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100">
                    <trip-charge-rates-list v-if="
                                            (tripDetails.chargeLines.length>
                        0 || tripDetails.customerId) &&
                        (isTeamOwner || isAdmin || isReadOnlyUser)
                        "
                        :charge-lines="tripDetails.chargeLines"
                        :trip-details="tripDetails"
                        title="Trip Charges"
                        :has-invoice="tripDetails.customerInvoiceId !== null"
                        :rates-have-changed="false"
                        @updateTripDetails="getTripDetails"
                        />
                </div>

                <div v-if="!isIndividualUser"
                     class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100">
                    <trip-cost-rates-list v-if="
                                          (tripDetails.costLines.length>
                        0 || tripDetails.publicUserId) && (isTeamOwner || isAdmin)
                        "
                        :cost-lines="tripDetails.costLines"
                        :trip-details="tripDetails"
                        title="Trip Costs"
                        :has-invoice="tripDetails.teamMemberInvoiceId !== null"
                        :rates-have-changed="false"
                        @updateTripDetails="getTripDetails"
                        />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests, getTeamRegionName } from '@/helpers';
import { GeneralMixin, RatesEngineMixin } from '@/mixins';
import { AssignmentHistoryTimeline, Notes, StatusHistoryTimeline, GenerateLabelButton } from '@/components';
import moment from 'moment';
import { DATE_TYPES } from '@/utils/constants';
import { mapGetters } from 'vuex';
import StopListTable from './Components/StopListTable';
import DriverBreakListTable from './Components/DriverBreakListTable';
import MapOverlay from './Components/MapOverlay';
import TripChargeRatesList from './Components/TripChargeRatesList';
import TripCostRatesList from './Components/TripCostRatesList';
import TripContactDetails from './Components/TripContactDetails';

import UpdateTripModal from './Modals/UpdateTripModal';
import ReassignTripModal from './Modals/ReassignTripModal';
import TripStatusButton from './Buttons/TripStatusButton';

export default {
    name: 'TripDetails',
    mixins: [GeneralMixin, RatesEngineMixin],
    components: {
        AssignmentHistoryTimeline,
        Notes,
        MapOverlay,
        StopListTable,
        DriverBreakListTable,
        TripStatusButton,
        StatusHistoryTimeline,
        GenerateLabelButton,
        TripChargeRatesList,
        TripCostRatesList,
        TripContactDetails
    },
    data() {
        return {
            tripDetails: null,
            teamMembers: [],
            stopList: [],
            isAdmin: Boolean(this.$route.query.isAdmin)
        };
    },
    async created() {
        this.$_handleLoaderState(true);
        await this.getTripDetails();
        await this.getTeamMembers();
        this.$_handleLoaderState(false);
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            teamRegions: 'team/teamRegions',
            isTeamOwner: 'user/isTeamOwner',
            isIndividualUser: 'user/isIndividualUser'
        }),
        teamRegionName() {
            return getTeamRegionName(this.tripDetails?.teamRegionId, this.teamRegions);
        }
    },
    methods: {
        async getTeamMembers() {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS');
        },
        async getTripDetails() {
            const api = `/api/trips/${
                this.$route.params.tripId
            }/details?includeNotes=true&includeUserHistory=true&includeStops=true&includeAssignedUser=true&includeStatusHistory=true`;
            const {
                data: { data }
            } = await handleRequests(api);

            this.tripDetails = data;
            this.tripDetails.user = data.assignedTo;
            this.stopListData = data;
            this.includeTripStartEnd(this.stopListData.stopList, data);
        },
        handleTripUpdate() {
            const { tripId } = this.$route.params;

            this.$modal
                .show(UpdateTripModal, {
                    // eslint-disable-next-line radix
                    tripId: parseInt(tripId)
                })
                .then(async (response) => {
                    if (response.code === 'ok') {
                        this.$modal.hide();
                        this.$_handleLoaderState(true);
                        await this.getTripDetails();
                        this.$_handleLoaderState(false);
                    }
                });
        },
        handleChangedTripStatus(response) {
            const { status } = response;
            this.$set(this.tripDetails, 'status', status);
        },
        handleTripStatusHistoryUpdated({ stopId, oldStatus, newStatus }) {
            this.tripDetails.statusHistory.push({
                actionDate: moment().format(),
                changedByUserFullName: this.tripDetails.user.fullName,
                location: null,
                oldStatus,
                newStatus
            });
        },
        includeTripStartEnd(stopList, data) {
            if (stopList.length === 0) 
                return;

            const assignedUser = stopList[0].assignedTo;
            const startTripStop = {
                stopId: 0,
                stopRef: '',
                actualDate: data.actualStartTime,
                originalEta: data.locationDetails.startEta,
                location: data.locationDetails.startLocation,
                address: data.locationDetails.startAddress,
                assignedTo: assignedUser,
                isStartStop: true,
                status: ''
            };
            this.stopListData.stopList.splice(0, 0, startTripStop);
            const endTripStop = {
                stopId: 0,
                stopRef: '',
                actualDate: data.actualEndTime,
                originalEta: data.locationDetails.endEta,
                location: data.locationDetails.endLocation,
                address: data.locationDetails.endAddress || 'Estimated Completion',
                assignedTo: assignedUser,
                isEndStop: true,
                status: ''
            };

            this.stopListData.stopList.splice(this.stopListData.stopList.length, 0, endTripStop);
        },
        reassignTrip() {
            const { tripId } = this.$route.params;
            const { publicUserId } = this.tripDetails;
            let { tripDate } = this.tripDetails;
            tripDate = moment(tripDate).format(DATE_TYPES.internationalDate);

            this.$modal
                .show(ReassignTripModal, {
                    // eslint-disable-next-line radix
                    tripId: parseInt(tripId),
                    teamMembers: this.teamMembers,
                    assignedTo: publicUserId,
                    tripDate,
                    teamRegionId: this.tripDetails.teamRegionId
                })
                .then(async (response) => {
                    if (response.code === 'ok') {
                        this.$_handleLoaderState(true);
                        await this.getTripDetails();
                        this.$_handleLoaderState(false);
                    }
                });
        },
        convertTimeFormat(time) {
            if (time != null) 
                return moment(time, 'LT').format('LT');
            return null;
        },
        async handleAddedTripNote() {
            await this.refreshNoteList();
        },
        async refreshNoteList() {
            const api = `/api/trips/${this.tripDetails.tripId}/notes`;
            const result = await handleRequests(api);
            this.tripDetails.notes = result.data;
        },
        showServicePackage() {
            // eslint-disable-next-line no-bitwise
            if (this.tripDetails.assignedTo.applyRatesTo & this.getKeyByLabelInApplyRatesTo('Trip')) {
                return true;
            }

            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.content-layout-panel {
    > div {
        padding: 0;
    }
}

.details-box {
    display: inline-block;
    vertical-align: top;
    min-width: 150px;
}

.profile-image {
    height: 63px;
    width: 63px;
    padding: 0px !important;
    -webkit-box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 0, 0, 0.2) !important;
    img {
        border-radius: 3px;
    }
}

.custom-md-field {
    a {
        color: #0076f0;
        cursor: pointer;
    }
}

.custom-note-card-large {
    ::v-deep .md-card-content > div {
        max-height: 661px;
        overflow: auto;
    }
}

.single-row {
    > div:first-child {
        width: 20%;
    }
    > div:last-child {
        width: 80%;
    }
}

.three-column-box {
    > div:first-child {
        width: 20%;
    }
    > div:nth-child(2) {
        width: 20%;
    }
    > div:last-child {
        width: 40%;
    }
}

.status-btn {
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
    margin: 6px 0px;
    height: 36px;
    line-height: 36px;
    border-radius: 2px;

    ::v-deep span {
        height: 36px;
        line-height: 36px;
    }

    > ::v-deep .dropdown-menu {
        margin-left: 9px;
        margin-top: 0;
    }
}
</style>
