<template>
    <div class="modal-container">
        <div class="modal-header">
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <simple-wizard plain :show-header="false" :title="title" :sub-title="subtitle" @stepChange="stepChange">
                <wizard-tab :before-change="validateDetails" :id="'step1'">
                    <template slot="label">
                        Create SMS Template
                    </template>
                    <div>
                        <form-wrapper :validator="$v.form" class="form-wrapper" v-if="!isStepLoading">
                            <form-group class="custom-merge-field">
                                <drop-down>
                                    <md-button
                                        @click.prevent
                                        slot="title"
                                        class="dropdown-toggle md-info"
                                        data-toggle="dropdown"
                                    >
                                        Insert Merge Field
                                    </md-button>
                                    <ul class="dropdown-menu dropdown-menu-right">
                                        <li v-for="event in mergeFields" :key="event.key">
                                            <span v-if="event.key === 'stopcustomfields'" class="custom-field-header">
                                                {{ event.name }}
                                            </span>
                                            <a v-else @click="getSelectedMergeField(event.key)">{{ event.name }}</a>
                                        </li>
                                    </ul>
                                </drop-down>
                            </form-group>
                            <form-group class="custom-textarea" name="template">
                                <md-textarea v-model="form.template" ref="template" />
                            </form-group>
                        </form-wrapper>
                        <div v-else>
                            <div class="table-loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </div>
                </wizard-tab>
                <wizard-tab :before-change="validateDetails" :id="'step2'">
                    <template slot="label">
                        Select Stops
                    </template>
                    <div class="stop-table-container" v-if="!isStepLoading">
                        <md-table v-if="stopList.length > 0">
                            <md-table-row>
                                <md-table-head></md-table-head>
                                <md-table-head>
                                    <md-checkbox
                                        class="stop-checkbox checkbox-head"
                                        v-model="isSelectAll"
                                        @change="onSelectAll(isSelectAll)"
                                    ></md-checkbox>
                                </md-table-head>
                                <md-table-head>Reference Number</md-table-head>
                                <md-table-head>Assigned To</md-table-head>
                                <md-table-head>Status</md-table-head>
                                <md-table-head>Contact</md-table-head>
                                <md-table-head>Phone</md-table-head>
                            </md-table-row>

                            <md-table-row v-for="item in stopList" :key="item.stopId">
                                <md-table-cell>
                                    <div class="error-icon" v-if="!checkValidPhone(item.contact)">
                                        <md-icon>error</md-icon>
                                        <md-tooltip md-direction="right">Invalid Phone Number</md-tooltip>
                                    </div>
                                </md-table-cell>
                                <md-table-cell>
                                    <md-checkbox
                                        class="stop-checkbox"
                                        :value="item.stopId"
                                        v-model="selectedStops"
                                        :disabled="!checkValidPhone(item.contact)"
                                    ></md-checkbox>
                                </md-table-cell>
                                <md-table-cell class="stop-reference">
                                    <router-link
                                        :to="{
                                            name: 'Stop Details',
                                            params: { stopId: item.stopId }
                                        }"
                                        target="_blank"
                                    >
                                        {{ item.stopRef }}
                                    </router-link>
                                </md-table-cell>
                                <md-table-cell>
                                    <div v-if="item.assignedTo.fullName" class="assigned-column custom-ellipsis">
                                        {{ item.assignedTo.fullName }}
                                    </div>
                                </md-table-cell>
                                <md-table-cell class="status-container">
                                    {{ item.status }}
                                </md-table-cell>
                                <md-table-cell>
                                    {{ item.contact ? item.contact.name : '' }}
                                </md-table-cell>
                                <md-table-cell>
                                    {{ item.contact ? item.contact.phone : '' }}
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                    <div v-else>
                        <div class="table-loader">
                            <fade-loader :loading="true" color="#333333" />
                            <span>LOADING</span>
                        </div>
                    </div>
                </wizard-tab>

                <wizard-tab :before-change="validateDetails" :id="'step3'">
                    <template slot="label">
                        Confirmation
                    </template>
                    <div class="confirmation-message">
                        <div class="alert alert-warning alert-dismissible fade show" role="alert" v-if="isPastTrip">
                            <strong>ATTENTION!</strong>
                            SMSs are being sent for stops on a trip in the past. Are you sure you want to do this?
                        </div>
                        Are you sure you want to send SMSs to {{ this.selectedStops.length }} stops?
                        <br />
                        Click the "Finish" button to send.
                    </div>
                </wizard-tab>
            </simple-wizard>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';
import { SimpleWizard, WizardTab } from '@/components';
import { SMS_EVENT_TRIGGER_CONSTANTS, INSERT_MERGE_FIELDS_CONSTANTS, SMS_STOP_TYPES } from '@/utils/constants';
import { handleRequests, showErrorMessage, isPhoneValid } from '@/helpers';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import moment from 'moment';

export default {
    name: 'SendBulkSms',
    mixins: [GeneralMixin],
    components: {
        SimpleWizard,
        WizardTab,
        FadeLoader
    },
    props: {
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        tripId: {
            type: Number || String,
            default: null
        },
        selectedDate: {
            type: String,
            default: null
        },
        userId: {
            type: String,
            default: null
        },
        teamRegionId: {
            type: Number,
            default: null
        },
        stopCustomFieldDefinitions: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            form: {
                key: '',
                template: '',
                level: '',
                eventTrigger: '',
                stopType: null,
                id: 0
            },
            eventTriggerOptions: [...SMS_EVENT_TRIGGER_CONSTANTS],
            insertMergeFieldOptions: [...INSERT_MERGE_FIELDS_CONSTANTS],
            stopTypeOptions: [...SMS_STOP_TYPES],
            stopCustomMergeFields: [],
            mergeFields: [],
            step: 'step1',
            isSelectAll: true,
            stopList: [],
            selectedStops: [],
            smsTemplates: [],
            isStepLoading: false,
            isPastTrip: false
        };
    },
    mounted() {
        this.getMergeFields();
        if (localStorage.getItem('trips.defaults.bulksms') != null) {
            this.form.template = JSON.parse(localStorage.getItem('trips.defaults.bulksms'));
        }

        const today = moment().startOf('day');
        if (moment(this.selectedDate).isBefore(today)) {
            this.isPastTrip = true;
        }
    },
    methods: {
        getMergeFields() {
            if (this.stopCustomFieldDefinitions != null && this.stopCustomFieldDefinitions.length > 0) {
                this.stopCustomMergeFields.push({
                    name: 'Stop Custom Fields',
                    key: 'stopcustomfields'
                });
                this.stopCustomFieldDefinitions.forEach((field) => {
                    this.stopCustomMergeFields.push({
                        name: field.label,
                        key: field.name
                    });
                });
            }
            const resultStopCustomFields = this.stopCustomMergeFields
                .filter((e) => e.name === 'Stop Custom Fields')
                .concat(
                    this.stopCustomMergeFields
                        .filter((e) => e.name !== 'Stop Custom Fields')
                        .sort((a, b) => a.name.localeCompare(b.name))
                );

            this.mergeFields = this.insertMergeFieldOptions
                .sort((a, b) => a.name.localeCompare(b.name))
                .concat(resultStopCustomFields);
        },
        stepChange(name) {
            this.step = name;
        },
        async validateDetails() {
            switch (this.step) {
                case 'step1':
                    if (this.form.template === '' || this.form.template === null) {
                        this.$notify({
                            message: 'Template cannot be empty',
                            type: 'danger'
                        });
                        return false;
                    }

                    // this is to prevent server side call in getting the stops, when we change the step from step 1 to 2.
                    if (this.stopList.length === 0) {
                        this.isStepLoading = true;
                        if (this.userId === 'All') {
                            await this.getAllStops();
                        } else {
                            await this.getStopList();
                        }
                        this.onSelectAll(true);
                    }

                    break;
                case 'step2':
                    if (this.selectedStops.length <= 0) {
                        this.$notify({
                            message: 'Please select at least one stop to send an SMS to',
                            type: 'danger'
                        });
                        return false;
                    }

                    // if(this.stopList.length === 0)
                    //     await this.getStopList();
                    break;
                case 'step3':
                    this.sendBulkSms();
                    break;
                default:
                    break;
            }

            return true;
        },
        onSelectAll(value) {
            this.selectedStops = [];
            if (value) {
                this.stopList.forEach((stop) => {
                    if (stop.contact != null && stop.contact.phone && stop.status !== 'Cancelled') {
                        this.selectedStops.push(stop.stopId);
                    }
                });
            }
        },
        getSelectedMergeField(value) {
            const textarea = this.$refs.template.$el;

            if (value !== 'default') {
                if (!this.form.template) {
                    this.form.template = '';
                }
                const temp = this.form.template;
                const currentCursorPosition = textarea.selectionStart;
                this.form.template = `${temp.substring(0, textarea.selectionStart)}{{${value}}}${temp.substring(
                    textarea.selectionEnd,
                    temp.length
                )}`;

                this.$nextTick(() => {
                    textarea.focus();
                    // The additional + 4 is because we are adding {{}} to the merge field value upon insert.
                    const cursorfocusPos = currentCursorPosition + value.length + 4;
                    textarea.setSelectionRange(cursorfocusPos, cursorfocusPos);
                });
            }
        },
        async getStopList() {
            const api = `/api/trips/${this.selectedDate}?userId=${this.userId}`;

            try {
                const {
                    data: { data }
                } = await handleRequests(api);

                this.stopList = data.stopList;

                this.stopList.forEach((stop) => {
                    if (
                        stop.contact != null &&
                        stop.contact.phone &&
                        stop.status !== 'Complete' &&
                        stop.status !== 'Cancelled'
                    ) {
                        this.selectedStops.push(stop.stopId);
                    }
                    stop.assignedTo = data.assignedTo;
                });
            } catch (error) {
                const message = 'Error in loading the stop list';
                showErrorMessage(this, message, error);
            }
            this.isStepLoading = false;
        },
        async getAllStops() {
            const api = `/api/stops/bulk-sms/list?tripDate=${this.selectedDate}&teamRegionId=${this.teamRegionId}`;
            try {
                const { data } = await handleRequests(api);
                this.stopList = data.stops;

                this.stopList.forEach((stop) => {
                    if (
                        stop.contact != null &&
                        stop.contact.phone &&
                        stop.status !== 'Complete' &&
                        stop.status !== 'Cancelled'
                    ) {
                        this.selectedStops.push(stop.stopId);
                    }
                });
            } catch (error) {
                const message = 'Error in loading the stop list';
                showErrorMessage(this, message, error);
            }
            this.isStepLoading = false;
        },
        checkValidPhone(contact) {
            if (contact !== null && contact.phone) {
                return isPhoneValid(contact.phone);
            }

            return false;
        },
        async sendBulkSms() {
            try {
                const data = [];
                this.selectedStops.forEach((id) => {
                    const stop = this.stopList.find((x) => x.stopId === id);
                    data.push({ stopId: stop.stopId, phoneNumber: stop.contact != null ? stop.contact.phone : '' });
                });

                const api = '/api/stops/bulk/send-sms';
                const payload = {
                    method: 'post',
                    data: {
                        template: this.form.template,
                        bulkSmsData: data
                    }
                };
                await handleRequests(api, payload);
                // send to bulk sms endpoint
                this.$notify({
                    message: 'Your SMSs are now being sent.',
                    type: 'success'
                });
                localStorage.setItem('trips.defaults.bulksms', JSON.stringify(this.form.template));
                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Unable to send bulk sms.';
                showErrorMessage(this, message, error);
            }
        }
    },
    validations: {
        form: {
            key: {
                required
            },
            template: {
                required
            }
        },
        step1: {},
        step2: {},
        step3: {}
    }
};
</script>

<style lang="scss" scoped>
.overflow-container {
    overflow: auto;
    max-height: 50vh;
    margin-bottom: 10px;
    > div {
        margin-bottom: 20px;
    }
}
.modal-container {
    max-width: 800px;
    @media (min-width: 1440px) {
        max-width: 1000px;
    }
    .modal-header {
        padding: 0;
        button {
            z-index: 99;
        }
    }
    .modal-body {
        padding-top: 0;
        h5 {
            font-weight: 400;
            text-transform: uppercase;
            font-size: 15px;
        }

        ::v-deep .md-card {
            margin: 0 !important;
            background-color: #fff;
            box-shadow: none;

            .md-card-content {
                // overflow-y: scroll;
                max-height: 60vh;
            }
        }

        ::v-deep .tab-content {
            padding: 20px 15px 0 15px !important;
        }
        .md-icon-button,
        ::v-deep .md-icon-button {
            background-color: #2b93ff !important;
        }

        .custom-merge-field {
            // width: 188px;
            ::v-deep label {
                top: -10px;
            }
            ::v-deep .dropdown .md-button {
                background-color: #00bcd4 !important;
            }
            .dropdown-menu-right {
                max-height: 300px;
                overflow: auto;
                a {
                    cursor: pointer;
                }
            }
            .custom-field-header {
                padding-left: 10px;
                font-weight: bold;
            }
        }
        .event-trigger {
            float: left;
            width: 180px;
            margin-top: 29px;
            margin-right: 10px;
            ::v-deep label {
                top: -1.2rem !important;
            }
        }

        .stop-table-container {
            ::v-deep .md-table {
                overflow-x: initial;
                // position:relative;
                // th {
                //     position: sticky;
                //     top: 0;
                // }
            }
            min-height: 50vh;
            max-height: 50vh;
            max-width: 100%;
            overflow: auto;
            .stop-checkbox {
                margin: 0;
                margin-top: 6px;
            }
            .error-icon {
                display: inline-block;
                i {
                    color: #ffcc00;
                }
            }
            ::v-deep .md-table-cell,
            ::v-deep .md-table-head {
                padding: 0;
                a {
                    color: #1286ff;
                }
            }
            ::v-deep .md-table-cell {
                height: initial;
            }
            ::v-deep .md-table-head {
                padding-bottom: 5px;
                .md-table-head-label {
                    color: #4caf50 !important;
                }
            }
            .assigned-column {
                max-width: 200px;
            }
        }
        .confirmation-message {
            text-align: center;
            margin-top: 10%;
            font-size: 20px;

            .alert {
                font-size: 16px;
            }
        }
    }
}

.md-tooltip {
    z-index: 9999;
}
</style>
