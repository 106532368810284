var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-button",
    {
      class: _vm.useIcon
        ? "md-primary md-just-icon md-round"
        : "md-primary header-button",
      attrs: { title: "Add Shipment" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.createRunShipment($event)
        },
      },
    },
    [
      _vm.useIcon
        ? _c("md-icon", [_vm._v("add")])
        : _c("span", [_vm._v("Add Shipment")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }