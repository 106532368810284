<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-warning">
            <div class="card-icon">
                <md-icon>collections_bookmark</md-icon>
            </div>
        </md-card-header>
        <md-card-content>
            <template v-if="carrierBookingHistory && carrierBookingHistory.length">
                <time-line plain type="simple">
                    <time-line-item
                        inverted
                        v-for="(history, index) in carrierBookingHistory"
                        :key="index"
                        :is-clickable="false"
                        :is-image="true"
                        :image-src="
                            history.carrierTeam && history.carrierTeam.logoUrl
                                ? history.carrierTeam.logoUrl
                                : $root.defaultLogoUrl
                        "
                    >
                        <span slot="header" class="custom-badge status-pending" v-if="history.status">
                            {{ history.status }}
                        </span>
                        <span slot="header" class="custom-badge status-cancelled" v-else>
                            Error
                        </span>

                        <span slot="headerTime" class="time">
                            {{
                                (history.updatedDate ? history.updatedDate : history.createdDate)
                                    | dateTimeFormat(DATE_TYPES.standardDate)
                            }}
                        </span>

                        <h6 slot="footer">
                            <i class="ti-time" />

                            {{ history.carrierTeam ? history.carrierTeam.company : '-' }}
                            <span v-if="history.error && history.error.message">
                                {{ history.error.message }}
                                <md-button
                                    title="Show details"
                                    :class="`md-danger md-just-icon md-round error-button`"
                                    @click.stop="showErrorModal(history)"
                                >
                                    <md-icon>priority_high</md-icon>
                                </md-button>
                            </span>
                        </h6>
                    </time-line-item>
                </time-line>
            </template>
            <template v-else>
                <p class="grayedout-text">No Status History Available</p>
            </template>
        </md-card-content>
    </md-card>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { TimeLine, TimeLineItem, CarrierErrorModal } from '@/components';

export default {
    name: 'CarrierBookingHistory',
    props: {
        carrierBookingHistory: { type: Array, default: () => [] }
    },
    components: { TimeLine, TimeLineItem },
    mixins: [GeneralMixin],
    methods: {
        showErrorModal(history) {
            this.$modal
                .show(CarrierErrorModal, {
                    carrierName: history.carrierTeam.company,
                    error: history.error
                })
                .then(() => {
                    this.$modal.hide();
                });
        }
    }
};
</script>

<style scoped lang="scss">
.status-pending {
    background-color: #c2907f;
}

.quotes-warning-icon {
    color: maroon !important;
}

.md-button.md-fab,
.md-button.md-just-icon {
    height: 25px;
    min-width: 25px;
    width: 25px;
    border-radius: 30px;
}
.error-button {
    float: right;
    height: 20px !important;
    min-width: 20px !important;
    width: 20px !important;
    button,
    ::v-deep button {
        margin: 0 2px;
    }
    ::v-deep .md-button-content i {
        font-size: 15px !important;
    }
}
</style>
