<template>
    <div class="modal-container">
        <div class="modal-header">
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <div class="sidebar-image">
                <img
                    :src="appItem.dialogImageUrl == null ? '/img/carrier-banner.jpg' : appItem.dialogImageUrl"
                    alt=""
                />
            </div>
            <simple-wizard
                plain
                :show-header="false"
                :title="title"
                :logo-url="appItem.logoUrl"
                sub-title=""
                @finish="connectCarrier"
                @stepChange="stepChange"
                finish-button-text="finish"
                :next-button-text="nextButton"
            >
                <wizard-tab :before-change="validateDetails" :id="'welcomeScreen'">
                    <template slot="label">
                        Welcome Screen
                    </template>
                    <div class="padded-container">
                        <welcome-screen :carrier-details="appItem" />
                    </div>
                </wizard-tab>
                <wizard-tab :before-change="validateDetails" :id="'apiKeysSetup'">
                    <template slot="label">
                        {{ tab2Label }}
                    </template>
                    <div class="padded-container">
                        <template v-if="showAccessKeysInput">
                            <div class="key-note">
                                To connect to
                                <span>{{ appItem.company }}</span>
                                you need to provide the following API keys. If you don't have these yet, you can skip
                                this step for now, but you will need to provide these before you can complete the
                                connection to the carrier and start using its services.
                                <span v-if="appItem.onboardingInstructionsUrl">
                                    Follow
                                    <a target="_blank" :href="appItem.onboardingInstructionsUrl">
                                        the instructions here
                                    </a>
                                    to obtain these keys.
                                </span>
                            </div>
                            <component
                                class="api-key-container"
                                v-if="currentCarrierComponent"
                                :is="currentCarrierComponent"
                                ref="carrierApiKeys"
                            ></component>
                        </template>
                        <template v-else>
                            <div class="key-note">
                                <ol>
                                    <li>
                                        Complete this wizard.
                                    </li>
                                    <li>
                                        Locate2u will notify Uber to request access to connect to their platform.
                                    </li>
                                    <li>
                                        Uber will contact you and have the merchant agreement signed with them.
                                    </li>
                                    <li>
                                        Uber will update Locate2u when the contract has been signed.
                                    </li>
                                    <li>
                                        Locate2u will create a customer Id with Uber on your behalf.
                                    </li>
                                    <li>
                                        Uber will setup billing and pricing/radius configurations of your account.
                                    </li>
                                </ol>
                            </div>
                        </template>
                    </div>
                </wizard-tab>
                <wizard-tab
                    :before-change="validateDetails"
                    :id="'questionsMappingSetup'"
                    v-if="appItem.questionCustomFieldsMapping != null && appItem.questionCustomFieldsMapping.booking"
                >
                    <template slot="label">
                        Questions Mapping
                    </template>
                    <div class="padded-container">
                        <div class="key-note">
                            To get quotes and place bookings with
                            <span>{{ appItem.company }}</span>
                            , the following questions need to be answered. Please configure these to determine whether
                            they can have a default answer, are mapped to a custom field, or will be asked upon each
                            booking.
                        </div>
                        <CarrierQuestions
                            :questions-mapping="
                                appItem.questionCustomFieldsMapping != null
                                    ? appItem.questionCustomFieldsMapping.booking
                                    : []
                            "
                            :booking-questions="
                                appItem.questionCustomFieldsMapping.booking != null
                                    ? appItem.questionCustomFieldsMapping.booking
                                    : []
                            "
                            :carrier-teamid="appItem.carrierTeamId"
                            ref="questionMapping"
                            @isInvalid="validationInvalid"
                            class="onboarding-cq"
                        />
                    </div>
                </wizard-tab>
            </simple-wizard>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { SimpleWizard, WizardTab } from '@/components';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import AusPostKeys from '@/pages/TeamCarriers/components/AusPostKeys';
import DoordashKeys from '@/pages/TeamCarriers/components/DoordashKeys';
import GoPeopleKeys from '@/pages/TeamCarriers/components/GoPeopleKeys';
import GophrKeys from '@/pages/TeamCarriers/components/GophrKeys';
import LalamoveKeys from '@/pages/TeamCarriers/components/LalamoveKeys';
import SendleKeys from '@/pages/TeamCarriers/components/SendleKeys';
import SherpaKeys from '@/pages/TeamCarriers/components/SherpaKeys';
import TgeKeys from '@/pages/TeamCarriers/components/TgeKeys';
import TntKeys from '@/pages/TeamCarriers/components/TntKeys';
import TransportifyKeys from '@/pages/TeamCarriers/components/TransportifyKeys';
import UberKeys from '@/pages/TeamCarriers/components/UberKeys';
import Zoom2uKeys from '@/pages/TeamCarriers/components/Zoom2uKeys';
import CarrierQuestions from '@/pages/TeamCarriers/CarrierQuestionsMapping';
import WelcomeScreen from './OnboardingComponents/WelcomeScreen';

export default {
    name: 'OnboardingWizard',
    mixins: [GeneralMixin],
    components: {
        SimpleWizard,
        WizardTab,
        WelcomeScreen,
        CarrierQuestions,
        SherpaKeys,
        AusPostKeys,
        DoordashKeys,
        GoPeopleKeys,
        GophrKeys,
        LalamoveKeys,
        SendleKeys,
        TgeKeys,
        TntKeys,
        TransportifyKeys,
        UberKeys,
        Zoom2uKeys
    },
    props: {
        appItem: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        currentCarrierComponent() {
            if (!this.appItem) 
                return null;

            return `${this.appItem.settings.carrierCode}Keys`;
        },
        showAccessKeysInput() {
            return this.appItem && this.appItem.settings.carrierCode !== 'Uber';
        },
        tab2Label() {
            return this.showAccessKeysInput ? 'Api Keys' : 'Process';
        }
    },
    data() {
        return {
            title: 'Carrier Onboarding Wizard',
            nextButton: 'Start',
            currentStep: 'welcomeScreen',
            isInvalid: false
        };
    },
    methods: {
        validationInvalid(value) {
            this.isInvalid = value;
        },
        stepChange(value) {
            if (value === 'welcomeScreen') {
                this.nextButton = 'Start';
            } else {
                this.nextButton = 'Next';
            }
            this.currentStep = value;
        },
        validateDetails() {
            return true;
        },
        async connectCarrier() {
            const apiKeyModel = {};
            if (this.showAccessKeysInput) {
                apiKeyModel[`${_.camelCase(this.appItem.settings.carrierCode)}Access`] = {
                    ...this.$refs.carrierApiKeys.$data.model
                };
            }

            let mappings = null;

            if (this.$refs.questionMapping) {
                // eslint-disable-next-line array-callback-return, consistent-return
                mappings = this.$refs.questionMapping.$v.questionMappings.$model.map((x) => {
                    if (x.mapping && x.mapping != null) {
                        return {
                            name: x.name ?? null,
                            type: x.type ?? null,
                            mapping: x.mapping,
                            defaultValue: x.defaultValue ? x.defaultValue.toString() : null,
                            customFieldName: (x.mapping === 'CustomField' ? x.customFieldName : null) ?? null,
                            overridable: (x.mapping === 'CustomField' ? null : x.overridable) ?? null
                        };
                    }
                });
            }

            if (mappings != null) {
                mappings = mappings.filter((item) => {
                    return item !== undefined;
                });
            }

            this.$_handleLoaderState(true);
            const model = {
                carrierMarketplaceId: this.appItem.carrierMarketplaceId,
                apiKeys: apiKeyModel,
                questionMappings:
                    mappings == null || mappings.length === 0 ? { mappings: null } : { mappings: [...mappings] }
            };
            try {
                const endpoint = `/api/teams/carriers`;
                const payload = {
                    method: 'post',
                    data: model
                };
                const resp = await handleRequests(endpoint, payload);

                if (resp.data.status === 'Active') {
                    this.$notify({
                        message: `Successfully activated ${this.appItem.company}`,
                        type: 'success'
                    });
                } else {
                    this.$notify({
                        message: `Setup is not complete for ${
                            this.appItem.company
                        }. Please proceed to the carriers page to complete the setup.`,
                        type: 'warning'
                    });
                }

                this.resolve(resp.data.status);
            } catch (error) {
                const message = `Error in connecting to ${this.appItem.company}. Please try again later`;
                showErrorMessage(this, message, error);
                this.resolve('failed');
            }
            this.$_handleLoaderState(false);
        }
    },
    mounted() {
        this.title = `${this.appItem.company} - Onboarding Wizard`;
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 83%;
    @media (min-width: 1600px) {
        max-width: 60%;
    }
    .modal-header {
        padding: 0;
        button {
            z-index: 99;
        }
    }
    .modal-body {
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 0;
        height: 90vh;
        max-height: 700px;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 6px;
        h5 {
            font-weight: 400;
            text-transform: uppercase;
            font-size: 15px;
        }

        .sidebar-image {
            display: inline-block;
            vertical-align: top;
            position: relative;
            height: 100%;
            width: 30%;
            > img {
                height: 100%;
                object-fit: cover;
            }
        }

        .wizard-container {
            height: 100%;
            width: calc(70% - 20px);
            display: inline-block;
            vertical-align: top;
            margin-left: 20px;

            ::v-deep .md-table-cell-container .md-field {
                margin-top: 0;
            }
        }

        ::v-deep form {
            height: 100%;
        }

        ::v-deep .md-card {
            margin: 0 !important;
            background-color: #fff;
            box-shadow: none;
            height: 100%;

            .md-card-content {
                overflow-y: visible;
                padding: 20px 0 0 0;
                height: 100%;
                max-height: 500px;

                .md-size-50:first-child {
                    padding-left: 0;
                    padding-right: 30px;
                }

                .md-size-50:last-child {
                    padding-left: 30px;
                    padding-right: 0;
                }
            }
        }

        ::v-deep .tab-content {
            padding: 0 15px 0 15px !important;
            height: 100%;
            overflow-y: auto;
        }
        .md-icon-button,
        ::v-deep .md-icon-button {
            background-color: #2b93ff !important;
        }
    }

    .key-note {
        margin-bottom: 30px;
        span {
            font-weight: 500;
        }
        a {
            color: #2b93ff;
        }
    }

    ::v-deep .questions-column {
        width: 350px;
    }

    ::v-deep .overridable-col {
        text-align: center;
    }

    .padded-container {
        padding: 20px;

        ::v-deep .custom-paginated-table {
            max-height: 460px;
            min-height: 350px;
            overflow: auto;

            tbody .md-table-row td {
                border: none;
            }
        }

        .api-key-container {
            max-height: 36vh;
            overflow-y: auto;
        }
    }
}

.onboarding-cq {
    ::v-deep .action-buttons .field-text {
        padding-bottom: 10px;
    }
}
</style>
