<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ data.address }}</h4>
        </div>
        <div class="modal-body">
            <div ref="googleMap" class="google-map" />
        </div>
        <div class="modal-footer">
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">
                Close
            </md-button>
        </div>
    </div>
</template>

<script>
import { MapOverviewMixin } from '@/mixins';

export default {
    name: 'ViewAddressModal',
    mixins: [MapOverviewMixin],
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    mounted() {
        this.initializeMap();
    },
    data() {
        return {
            map: null,
            markers: [],
            polygons: []
        };
    },
    beforeDestroy() {
        this.map = null;
        this.markers.forEach((x) => this.$_map_removeMapElement(x));
        this.markers.length = 0;
        this.polygons.forEach((x) => this.$_map_removeMapElement(x));
        this.polygons.length = 0;
    },
    methods: {
        async initializeMap() {
            await this.$_map_loadGoogleMap();
            const { latitude, longitude } = this.data.location;

            const element = this.$refs.googleMap;

            this.map = this.$_map_createMap(element, latitude, longitude);
            this.$_map_callMapFunction(this.map, 'setZoom', [15]);

            const marker = this.$_map_setMarkers(latitude, longitude, 'stop', { stop: '/img/map/C30E00/stop.png' });

            this.markers.push(marker);

            if (this.data.polygons !== undefined) {
                let boundsUnion = new google.maps.LatLngBounds();
                this.data.polygons.data.forEach((poly) => {
                    const pl = this.$_map_createPolygon(this.map, poly, this.data.polygons.options);
                    this.polygons.push(pl);

                    // If there are multiple polygons on the screen we need to ensure that we do a `union` on the bounds of each polygon
                    // (in order to extend the bounds and zoom to all polygons).
                    const bounds = pl.getBounds();
                    boundsUnion = boundsUnion.union(bounds);
                });
                this.$_map_zoomToBounds(this.map, boundsUnion);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.google-map {
    width: 100%;
    height: 300px;
}
.modal-body {
    font-size: 12px;
}
</style>
