var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Action")]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          { staticClass: "form-wrapper", attrs: { validator: _vm.$v.form } },
          [
            _c(
              "form-group",
              { attrs: { label: "Event Actions", name: "key" } },
              [
                _c(
                  "md-select",
                  {
                    model: {
                      value: _vm.form.triggerGroupEvent,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "triggerGroupEvent", $$v)
                      },
                      expression: "form.triggerGroupEvent",
                    },
                  },
                  _vm._l(_vm.triggerEventOptions, function (event) {
                    return _c(
                      "md-option",
                      { key: event.key, attrs: { value: event.key } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(event.triggerGroupEvent) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "form-group",
              {
                attrs: {
                  label: "Travel distance",
                  name: "enteredTravelDistanceValue",
                },
              },
              [
                _c("md-input", {
                  model: {
                    value: _vm.form.enteredTravelDistanceValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "enteredTravelDistanceValue", $$v)
                    },
                    expression: "form.enteredTravelDistanceValue",
                  },
                }),
                _c(
                  "md-select",
                  {
                    model: {
                      value: _vm.form.enteredTravelDistanceUnits,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "enteredTravelDistanceUnits", $$v)
                      },
                      expression: "form.enteredTravelDistanceUnits",
                    },
                  },
                  [
                    _c("md-option", { attrs: { value: "km" } }, [_vm._v("km")]),
                    _c("md-option", { attrs: { value: "miles" } }, [
                      _vm._v("miles"),
                    ]),
                    _c("md-option", { attrs: { value: "meters" } }, [
                      _vm._v("meters"),
                    ]),
                    _c("md-option", { attrs: { value: "feet" } }, [
                      _vm._v("feet"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("br"),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.saveGeofenceEventSetting },
          },
          [_vm._v("\n            Save\n        ")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }