var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dropdownOptions != null && _vm.dropdownOptions.length > 0
    ? _c(
        "th",
        { staticClass: "md-table-head running-load-head-table-header" },
        [
          _c(
            "div",
            { staticClass: "md-table-head-container running-load-menu" },
            [
              _c("drop-down", { attrs: { direction: "down" } }, [
                _c(
                  "a",
                  { staticClass: "load-header-text", attrs: { href: "#" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.defaultText) +
                        "\n                "
                    ),
                    _vm.selectedData != null && _vm.selectedData.length > 0
                      ? _c("md-icon", { staticClass: "icon-arrow" }, [
                          _vm._v(
                            "\n                    filter_alt\n                "
                          ),
                        ])
                      : _c("md-icon", { staticClass: "icon-arrow" }, [
                          _vm._v("filter_alt_off"),
                        ]),
                  ],
                  1
                ),
                _vm.selectedData != null && _vm.selectedData.length > 0
                  ? _c("div", { staticClass: "header-note" }, [
                      _vm.selectedData != null && _vm.selectedData.length == 1
                        ? _c("span", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.selectedData[0].selectedLabel) +
                                "\n                "
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              _vm._s(_vm.selectedData.length) +
                                " options selected"
                            ),
                          ]),
                    ])
                  : _vm._e(),
                _c(
                  "ul",
                  { staticClass: "dropdown-menu" },
                  _vm._l(_vm.dropdownOptions, function (option, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleSelectedOption(option)
                          },
                        },
                      },
                      [
                        _c(
                          "md-icon",
                          {
                            class: [
                              "icon-check",
                              _vm.selectedData.filter(function (e) {
                                return e.key === option.key
                              }).length > 0
                                ? "icon-check-color"
                                : "",
                            ],
                          },
                          [
                            _vm._v(
                              "\n                        check\n                    "
                            ),
                          ]
                        ),
                        _c("a", { attrs: { href: "#" } }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(option.name) +
                              "\n                    "
                          ),
                        ]),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ]
      )
    : _c(
        "div",
        { staticClass: "card-icon" },
        [
          _vm.defaultIcon != null
            ? _c("md-icon", [_vm._v(_vm._s(_vm.defaultIcon))])
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.defaultText) + "\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }