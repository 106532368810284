<template>
    <md-button
        title="Generate PDF"
        class="md-info md-just-icon md-round"
        @click.stop="handleGeneratePdf(customerInvoiceId)"
    >
        <md-icon>preview</md-icon>
    </md-button>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage, openFile } from '@/helpers';

export default {
    name: 'GeneratePdfButton',
    props: {
        customerInvoiceId: {
            type: [Number, String],
            default: () => null
        }
    },
    mixins: [GeneralMixin],
    methods: {
        async handleGeneratePdf(customerInvoiceId) {
            this.$_handleLoaderState(true, 'GENERATING...');
            const payload = {
                method: 'get',
                responseType: 'blob'
            };
            const api = `/api/customer-invoices/${customerInvoiceId}/download`;

            try {
                const response = await handleRequests(api, payload);
                await openFile(response.data, 'invoice.pdf');
                this.$notify({
                    message: 'Successfully generated the invoice.',
                    type: 'success'
                });
                this.$emit('invoiceGenerated', true);
            } catch (e) {
                const message = 'Error in generating invoice.';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
        }
    }
};
</script>
