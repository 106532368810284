var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "currencies-autocomplete" },
    [
      _c(
        "form-group",
        { attrs: { name: "currency", label: _vm.label } },
        [
          _c("md-input", {
            ref: "currenciesAutocomplete",
            attrs: { type: "text" },
            on: {
              focus: function ($event) {
                return _vm.onFocus($event)
              },
              focusout: function ($event) {
                return _vm.onFocusout($event)
              },
              blur: _vm.blur,
              change: _vm.onChange,
              keyup: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  ) {
                    return null
                  }
                  return _vm.onArrowDown($event)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  ) {
                    return null
                  }
                  return _vm.onArrowUp($event)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onEnter($event)
                },
              ],
            },
            model: {
              value: _vm.autocompleteText,
              callback: function ($$v) {
                _vm.autocompleteText = $$v
              },
              expression: "autocompleteText",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "autocomplete" }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen && _vm.autocompleteList,
                expression: "isOpen && autocompleteList",
              },
            ],
            ref: "autocompleteContainer",
            staticClass: "autocomplete-results",
            attrs: { id: "autocomplete-results" },
          },
          _vm._l(_vm.autocompleteList, function (entry, index) {
            return _c(
              "li",
              {
                key: entry.isoCode,
                ref: "autocompleteOptions",
                refInFor: true,
                staticClass: "autocomplete-result",
                class: { "is-active": index === _vm.arrowCounter },
                on: {
                  click: function ($event) {
                    return _vm.setAutocompleteResult(entry)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(entry.symbol) +
                    " - " +
                    _vm._s(entry.description) +
                    "\n            "
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }