<template>
    <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100" v-if="hasTeam">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.customFields') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>list_alt</md-icon>
                    </div>
                </md-card-header>

                <md-card-content>
                    <div v-if="hasTeam">
                        <tabs
                            :tab-name="['Stop custom fields', 'Shipment custom fields', 'Asset custom fields']"
                            color-button="success"
                            class="custom-tab-list"
                            :class="{
                                'asset-custom-fields-active': isAssetCustomFieldsActive
                            }"
                            @switch-panel="handleTabChanged"
                            v-if="hasTeam"
                        >
                            <template slot="tab-pane-1">
                                <stop-custom-fields-list />
                            </template>
                            <template slot="tab-pane-2">
                                <shipment-custom-fields-list />
                            </template>
                            <template slot="tab-pane-3">
                                <asset-custom-fields-list />
                            </template>
                        </tabs>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { Tabs } from '@/components';
import { mapGetters } from 'vuex';
import StopCustomFieldsList from './StopCustomFieldsList';
import ShipmentCustomFieldsList from './ShipmentCustomFieldsList';
import AssetCustomFieldsList from './AssetCustomFieldsList';

export default {
    name: 'TeamSettings',
    components: {
        Tabs,
        StopCustomFieldsList,
        ShipmentCustomFieldsList,
        AssetCustomFieldsList
    },
    data() {
        return {
            isAssetCustomFieldsActive: false
        };
    },
    methods: {
        handleTabChanged(tab) {
            this.isAssetCustomFieldsActive = tab === 'Asset custom fields';
        }
    },
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam',
            isLoading: 'isLoading',
            user: 'user/user'
        })
    }
};
</script>

<style lang="scss" scoped>
.title {
    display: inline-block;
}

::v-deep {
    .custom-btn {
        margin-right: 10px;
        font-weight: 400;
        height: 40px;
        width: 45%;
    }
}

::v-deep .custom-tab-list {
    .nav-tabs {
        width: calc(100% - 55px);
        flex-wrap: wrap;
        li {
            border-bottom: 0px;
        }
    }
    &.asset-custom-fields-active {
        .nav-tabs {
            width: calc(100% - 225px);
        }
    }
}

.form-wrapper .custom-btn {
    margin-bottom: 0;
    margin-top: 25px;
}

.member-container {
    position: relative;
    .custom-switch {
        position: absolute;
        right: 15px;
        top: -40px;
        .md-switch {
            margin: 0;
            margin-bottom: 10px;
        }
    }
}
.card-icon {
    background: #c2907f !important;
}
</style>
