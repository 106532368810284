<template>
    <div class="modal-container">
        <div class="modal-header">
            <span v-if="selectedQuestion === null">Add Question ({{ selectedGroup }})</span>
            <span v-if="selectedQuestion !== null">Edit ({{ selectedGroup }})</span>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body questions-form-modal">
            <div class="stop-form-container">
                <div v-if="!isEditMode && isBookingGroup">
                    <form-group>
                        <md-radio v-model="selectedKind" value="Standard">Standard</md-radio>
                        <md-radio v-model="selectedKind" value="Custom">Custom</md-radio>
                    </form-group>
                </div>
                <template v-if="!isEditMode && isBookingGroup && selectedKind === 'Standard'">
                    <div v-if="!questionStandardBookingOptions.length">No Standard questions available.</div>
                    <md-table v-else class="context-menu-support custom-paginated-table">
                        <md-table-row>
                            <md-table-head></md-table-head>
                            <md-table-head>Label</md-table-head>
                            <md-table-head>Description</md-table-head>
                            <md-table-head>Type</md-table-head>
                        </md-table-row>
                        <md-table-row v-for="(q, index) in questionStandardBookingOptions" :key="index">
                            <md-table-cell>
                                <md-checkbox
                                    class="stop-checkbox"
                                    :value="q"
                                    v-model="selectedStandardQuestions"
                                ></md-checkbox>
                            </md-table-cell>
                            <md-table-cell>
                                {{ q.label }}
                            </md-table-cell>
                            <md-table-cell>
                                {{ q.description }}
                            </md-table-cell>
                            <md-table-cell>
                                {{ q.type }}
                                <ul v-if="q.options && q.options.length">
                                    <li v-for="(opt, ndx) in q.options" :key="ndx">
                                        {{ opt }}
                                    </li>
                                </ul>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                </template>
                <template v-else>
                    <form-wrapper :validator="$v.form" class="form-wrapper">
                        <form-group v-if="isBookingGroup" label="Name" name="questionName">
                            <md-input v-model.trim="form.questionName" :disabled="isEditMode" />
                        </form-group>
                        <form-group label="Label" name="label">
                            <md-input v-model="form.label" :disabled="isBookingStandard" />
                        </form-group>
                        <form-group name="type">
                            <vue-select
                                v-if="questionTypes"
                                :options="questionTypes"
                                placeholder="Type"
                                v-model="form.type"
                                :searchable="$root.isDesktop"
                                :disabled="isBookingStandard"
                            ></vue-select>
                        </form-group>
                        <div
                            class="md-layout md-medium-size-100 md-xsmall-size-100 md-size-100 options"
                            v-if="form.type === 'Dropdown'"
                        >
                            <div class="md-layout-item md-size-100">
                                <div v-if="isTextBoxOptions">
                                    <div
                                        class="md-layout-item md-size-10 option-toggle-button option-toggle-button-list"
                                    >
                                        <md-button class="md-primary md-just-icon md-round" @click="toggleOptions">
                                            <md-icon>list</md-icon>
                                            <md-tooltip md-direction="left">Use list view to add option</md-tooltip>
                                        </md-button>
                                    </div>
                                    <label class="label-text">Available Options:</label>
                                    <form-group name="options">
                                        <md-textarea v-model="optionValue" placeholder="Option" @change="addOptions" />
                                    </form-group>
                                </div>
                                <div v-else>
                                    <form-group name="options">
                                        <md-input v-model="optionValue" placeholder="Option" @keyup.enter="addOption" />
                                    </form-group>
                                </div>
                            </div>
                            <div class="md-layout-item md-size-0 add-icon" v-if="!isTextBoxOptions" @click="addOption">
                                <md-icon>add</md-icon>
                            </div>
                        </div>
                        <div
                            v-if="this.form.options.length && !isTextBoxOptions && form.type === 'Dropdown'"
                            class="options-table"
                        >
                            <div class="md-layout-item md-size-10 option-toggle-button">
                                <md-button class="md-primary md-just-icon md-round" @click="toggleOptions">
                                    <md-icon>launch</md-icon>
                                    <md-tooltip md-direction="left">Use text box field</md-tooltip>
                                </md-button>
                            </div>
                            <label>Available Options:</label>
                            <md-table>
                                <md-table-row v-for="(option, index) in form.options" :key="index">
                                    <md-table-cell>
                                        <form-group>
                                            <md-input v-model="option.value" />
                                        </form-group>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <md-button
                                            class="md-just-icon md-danger md-simple"
                                            title="delete option "
                                            @click.native="removeOption(index)"
                                        >
                                            <md-icon>close</md-icon>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                        <div>
                            <md-checkbox v-model="form.isRequired">Required</md-checkbox>
                        </div>
                        <div v-if="['proofofdelivery', 'arrivedatstop'].includes(selectedGroupNormalized)">
                            <md-checkbox v-model="form.includeInPdf">Include in Proof Of Delivery PDF</md-checkbox>
                        </div>
                        <div v-else-if="selectedGroupNormalized === 'faileddelivery'">
                            <md-checkbox v-model="form.includeInPdf">
                                Include in Failed Delivery PDF
                            </md-checkbox>
                        </div>
                        <div
                            v-if="
                                ['Proof of Delivery', 'Failed Delivery', 'Trip Start', 'Trip End'].includes(
                                    selectedGroup
                                )
                            "
                        >
                            <question-conditions
                                :form="form"
                                :conditions="conditions"
                                :conditions-options="conditionsOptions"
                                :team-regions-options="teamRegionsOptions"
                                :shipment-contents-options="shipmentContentsOptions"
                                :stop-custom-fields-options="stopCustomFieldsOptions"
                                :shipment-custom-fields-options="shipmentCustomFieldsOptions"
                                :operator-options="operatorOptions"
                            />
                        </div>
                        <form-group v-if="isBookingGroup" label="Description" name="Description">
                            <md-input v-model="form.description" />
                        </form-group>
                    </form-wrapper>
                </template>
            </div>
        </div>
        <div class="modal-footer">
            <md-button v-if="selectedQuestion === null" class="dialog-button md-primary" @click="addQuestion">
                Add
            </md-button>
            <md-button v-if="selectedQuestion !== null" class="dialog-button md-primary" @click="editQuestion">
                Save
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, requiredIf, maxLength, helpers } from 'vuelidate/lib/validators';
import {
    QUESTION_TYPES_CONSTANTS,
    QUESTION_KIND_OPTIONS,
    QUESTION_STANDARD_BOOKING_OPTIONS,
    DEFAULT_SHIPMENT_CONTENTS_CONSTANTS,
    OPERATOR_CONSTANTS,
    CONDITION_CONSTANTS
} from '@/utils/constants';
import QuestionConditions from './QuestionConditions';

export default {
    name: 'QuestionModal',
    mixins: [GeneralMixin],
    components: {
        QuestionConditions
    },
    props: {
        selectedQuestion: {
            type: Object,
            default: null
        },
        selectedGroup: {
            type: String,
            default: null
        },
        questionsList: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            form: {
                questionName: '', // the 'name' field shows 'Asset Name is required' when validation fails
                label: '',
                type: '',
                isRequired: false,
                includeInPdf: false,
                options: [],
                description: '',
                kind: null
            },
            isTextBoxOptions: false,
            questionfield: {},
            questionTypes: [],
            optionValue: null,
            questionKindOptions: QUESTION_KIND_OPTIONS,
            conditionsOptions: CONDITION_CONSTANTS,
            teamRegionsOptions: [],
            shipmentContentsOptions: DEFAULT_SHIPMENT_CONTENTS_CONSTANTS,
            stopCustomFieldsOptions: [],
            shipmentCustomFieldsOptions: [],
            operatorOptions: OPERATOR_CONSTANTS,
            questionStandardBookingOptions: [],
            selectedStandardQuestions: [],
            selectedKind: 'Standard',
            conditions: [],
            previousConditions: null
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            teamRegions: 'team/teamRegions'
        }),
        selectedGroupNormalized() {
            return this.selectedGroup.toLowerCase().replace(/[^\w]/gi, '');
        },
        isBookingGroup() {
            return this.selectedGroup.toLowerCase() === 'booking';
        },
        isEditMode() {
            return !!this.selectedQuestion;
        },
        isBookingStandard() {
            return this.isBookingGroup && this.selectedQuestion?.kind === 'Standard';
        }
    },
    mounted() {
        this.teamRegionsOptions = this.teamRegions.map((x) => ({
            teamRegionId: x.teamRegionId,
            name: x.name,
            selectedLabel: `${x.name} (selected)`, // label for the team region when the its parent is selected
            level: x.level,
            parentTeamRegionId: x.parentTeamRegionId,
            $isDisabled: false // UI (Multiselect comp) needed this field for styling purposes
        }));
        if (this.user.stopCustomFieldDefinitions) {
            this.stopCustomFieldsOptions = this.user.stopCustomFieldDefinitions.map((x) => ({
                name: x.name,
                label: x.label
            }));
        }
        if (this.user.shipmentCustomFieldDefinitions) {
            this.shipmentCustomFieldsOptions = this.user.shipmentCustomFieldDefinitions.map((x) => ({
                name: x.name,
                label: x.label
            }));
        }

        this.questionTypes = [...QUESTION_TYPES_CONSTANTS];
        if (['Trip Start', 'Trip End'].includes(this.selectedGroup)) {
            this.questionTypes.push('Signature');
        }
        if (!this.isEditMode) {
            if (!this.isBookingGroup) {
                this.selectedKind = 'Custom';
            } else {
                // on create (booking group), set the selected kind to 'Standard' questions
                // and hide those that were already added
                this.selectedKind = 'Standard';
                // questionStandardBookingOptions: QUESTION_STANDARD_BOOKING_OPTIONS,
                this.questionStandardBookingOptions = QUESTION_STANDARD_BOOKING_OPTIONS.filter((std) => {
                    return (
                        this.questionsList.findIndex((curr) => curr.kind === std.kind && curr.name === std.name) === -1
                    );
                });
            }
        }

        if (this.selectedQuestion !== null) {
            this.form.questionName = this.selectedQuestion.name;
            this.form.label = this.selectedQuestion.label;
            this.form.type = this.selectedQuestion.type;
            this.form.isRequired = this.selectedQuestion.isRequired;
            this.form.includeInPdf = this.selectedQuestion.includeInPdf;
            this.form.options = this.selectedQuestion.options || [];
            this.form.description = this.selectedQuestion.description;
            this.conditions = this.buildConditions(this.selectedQuestion.conditions);
        }
    },
    watch: {
        'conditions': {
            handler(newConditions) {
                if (JSON.stringify(newConditions) === this.previousConditions) {
                    return;
                }

                const previousConditions = JSON.parse(this.previousConditions);

                newConditions.forEach((item, index) => {
                    if (item.type === 'teamRegions') {
                        this.teamRegionsWatcher(index, item.data, previousConditions ? previousConditions[index].data : null);
                    }
                });

                this.previousConditions = JSON.stringify(this.conditions);
            },
            deep: true
        }
    },
    methods: {
        toggleOptions() {
            this.isTextBoxOptions = !this.isTextBoxOptions;
            if (this.isTextBoxOptions) {
                this.optionValue = this.form.options.length ? this.form.options.map((x) => x.value).join('\n') : null;
            } else {
                this.optionValue = null;
            }
        },
        isDuplicateOption(value) {
            const index = this.form.options.findIndex((x) => x.value.toLowerCase() === value.toLowerCase());

            if (index === -1) {
                return false;
            }

            return index;
        },
        showWarningDuplicate(value) {
            const message = `Option name ${value} already exists.`;
            this.$notify({
                message,
                type: 'warning'
            });
        },
        addOptions() {
            const options = [];
            this.optionValue.split('\n').forEach((option) => {
                const index = options.findIndex((x) => x.value.toLowerCase() === option.toLowerCase());
                if (option && index === -1) {
                    options.push({
                        value: option
                    });
                }
            });
            this.form.options = options;
            this.optionValue = options.map((x) => x.value).join('\n');
        },
        addOption() {
            if (this.optionValue != null) {
                if (!this.form.options) {
                    this.form.options = [
                        {
                            value: this.optionValue
                        }
                    ];
                    this.optionValue = null;
                } else if (!this.isDuplicateOption(this.optionValue)) {
                    this.form.options.push({
                        value: this.optionValue
                    });
                    this.optionValue = null;
                } else {
                    this.showWarningDuplicate(this.optionValue);
                }
            }
        },
        removeOption(index) {
            this.form.options.splice(index, 1);
        },
        addQuestion() {
            if (this.selectedKind === 'Standard') {
                if (!this.selectedStandardQuestions.length) {
                    this.$notify({
                        message: 'Please select at least 1 Standard question.',
                        type: 'warning'
                    });
                    return;
                }

                // check for duplicate question 'name'
                const duplicateQuestions = this.questionsList.filter((curr) => {
                    return this.selectedStandardQuestions.findIndex((nu) => nu.name === curr.name) > -1;
                });

                if (duplicateQuestions.length) {
                    const duplicateNames = duplicateQuestions.map((d) => d.name);
                    this.$notify({
                        message: `Question with the same name already exist. ${duplicateNames.join()}`,
                        type: 'warning'
                    });
                    return;
                }

                const standardQuestions = {
                    booking: [...this.selectedStandardQuestions]
                };

                this.resolve(standardQuestions);
            } else {
                // other question group or booking/custom
                this.$v.form.$touch();
                if (!this.$v.form.$invalid) {
                    let nameField = null;
                    if (this.isBookingGroup) {
                        nameField = this.form.questionName;
                    } else {
                        nameField = this.form.label
                            .toLowerCase()
                            .replace(/[^\w]/gi, '')
                            .substring(0, 20); // name to a max of 20 chars
                    }

                    this.questionfield = {
                        [this.selectedGroup.toLowerCase().replace(/[^\w]/gi, '')]: [
                            {
                                kind: 'Custom',
                                name: nameField,
                                label: this.form.label,
                                type: this.form.type,
                                isRequired: this.form.isRequired,
                                includeInPdf: this.form.includeInPdf,
                                options: this.form.options,
                                description: this.form.description,
                                conditions: this.getConditionData(this.conditions)
                            }
                        ]
                    };
                    this.resolve(this.questionfield);
                }
            }
        },
        editQuestion() {
            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                this.questionfield = { ...this.form, name: this.form.questionName, conditions: this.getConditionData(this.conditions) };
                this.resolve(this.questionfield);
            }
        },
        setPopUpActive(teamRegion, condition) {
            if (!teamRegion || !teamRegion.name) 
                return;

            condition.teamRegionPopup.teamRegionPopUpMessage = `You have selected all child regions of '${teamRegion.name}' region.`;
            condition.teamRegionPopup.teamRegionPopUpActive = true;

            setTimeout(() => {
                condition.teamRegionPopup.teamRegionPopUpActive = false;
                condition.teamRegionPopup.teamRegionPopUpMessage = '';
            }, 5000);
        },
        buildConditions(sourceCondition) {
            if (!sourceCondition) {
                return [];
            }

            const clonedSourceCondition = this.deepCopyData(sourceCondition);
            const conditions = [];

            Object.entries(clonedSourceCondition).forEach(([key, value]) => {
                const baseCondition = {
                    type: key,
                    data: key === 'customFields' && Array.isArray(value.list) ? null : value,
                    teamRegionsOptions: key === 'teamRegions' ? this.deepCopyData(this.teamRegionsOptions) : [],
                    teamRegionPopup: {
                        teamRegionPopUpActive: false,
                        teamRegionPopUpMessage: '',
                        teamRegionsInitialized: false,
                    }
                };

                if (key === 'customFields' && Array.isArray(value.list)) {
                    value.list.forEach((customFieldCondition) => {
                        conditions.push({ ...baseCondition, data: customFieldCondition });
                    });
                } else {
                    conditions.push(baseCondition);
                }
            });

            return conditions;
        },
        groupedConditions(sourceCondition) {
            const keyMap = {
                shipmentContents: 'name',
                customers: 'customerId',
                teamRegions: 'teamRegionId'
            };

            const groupedData = sourceCondition.reduce((group, item, index) => {
                if (!group[item.type]) {
                    group[item.type] = {
                        type: item.type,
                        data: new Map(),
                    };
                }

                if (item.type === 'customFields') {
                    group[item.type].data.set(`cf-${index}`, item.data);
                } else if (keyMap[item.type] && item.data) {
                    const key = keyMap[item.type];
                    item.data.forEach(x => {
                        if (!group[item.type].data.has(x[key])) {
                            group[item.type].data.set(x[key], x);
                        }
                    });
                }

                return group;
            }, {});
            
            return Object.values(groupedData).map(item => ({
                type: item.type,
                data: Array.from(item.data.values())
            }));
        },
        getConditionData(sourceCondition) {
            if (sourceCondition === undefined || sourceCondition === null) {
                return null;
            }

            const filteredSourceCondition = sourceCondition.filter(x => x.type !== 'none');
            if (filteredSourceCondition.length === 0)
            {
                return null;
            }

            const groupedData = this.groupedConditions(filteredSourceCondition);
            const objectCondition = {};
            
            Object.values(groupedData).forEach(({ type, data }) => {
                const newCondition = this.deepCopyData(data);
                if (type === 'customFields') {
                    [objectCondition[type]] = this.deepCopyData(data);
                    objectCondition[type].list = newCondition;
                }
                else {
                    objectCondition[type] = newCondition;
                }
            });
            
            return objectCondition;
        },
        teamRegionsWatcher(itemIndex, newTeamRegionValues, oldTeamRegionValues) {
            if (JSON.stringify(newTeamRegionValues) === JSON.stringify(oldTeamRegionValues)) 
                return; // This block will avoid infinite loop

            if (!newTeamRegionValues && (!oldTeamRegionValues || oldTeamRegionValues.length === 0)) 
                return;
            const condition = this.conditions[itemIndex];
            // We need to initialize team region data of old questions if they lacks some property
            newTeamRegionValues.forEach((val) => {
                const newVal = condition.teamRegionsOptions.find((tr) => tr.teamRegionId === val.teamRegionId);
                if (newVal) {
                    if (!val.level) 
                        val.level = newVal.level;
                    if (!val.parentTeamRegionId) 
                        val.parentTeamRegionId = newVal.parentTeamRegionId;
                    if (!val.$isDisabled) 
                        val.$isDisabled = newVal.$isDisabled;
                    if (!val.selectedLabel) 
                        val.selectedLabel = newVal.selectedLabel;

                    val.name = newVal.name;
                }
            });
            
            const processedTeamRegionIds = [];
            if (newTeamRegionValues && newTeamRegionValues.length > 0) {
                let teamRegionsToDisplay = this.deepCopyData(newTeamRegionValues);

                let maxHierarchyLevel = newTeamRegionValues[0].level ? newTeamRegionValues[0].level : 1;
                for (let index = 1; index < newTeamRegionValues.length; index++) {
                    const teamReg = newTeamRegionValues[index];
                    if (teamReg.level && +teamReg.level > maxHierarchyLevel) 
                        maxHierarchyLevel = teamReg.level;
                }

                for (let hierarchyLevel = 1; hierarchyLevel <= maxHierarchyLevel; hierarchyLevel++) {
                    const teamRegionsInThisLevel = newTeamRegionValues.filter((tr) => tr.level === hierarchyLevel);

                    for (let trIndex = 0; trIndex < teamRegionsInThisLevel.length; trIndex++) {
                        const teamRegion = teamRegionsInThisLevel[trIndex];

                        if (!processedTeamRegionIds.includes(teamRegion.teamRegionId)) {
                            const allSiblingRegions = condition.teamRegionsOptions.filter(
                                (tr) => tr.parentTeamRegionId === teamRegion.parentTeamRegionId
                            );

                            const allSiblingRegionsSelected = allSiblingRegions.every((br) =>
                                newTeamRegionValues.map((tr) => tr.teamRegionId).includes(br.teamRegionId)
                            );

                            // Same level (siblings)
                            if (allSiblingRegionsSelected) {
                                // all siblings are selected, we will tag and remove them on the multiselect field
                                allSiblingRegions.forEach((cr) => {
                                    cr.$isDisabled = true;
                                    processedTeamRegionIds.push(cr.teamRegionId);
                                });

                                teamRegion.$isDisabled = true;

                                // remove the siblings
                                teamRegionsToDisplay = teamRegionsToDisplay.filter(
                                    (tr) => !allSiblingRegions.map((chr) => chr.teamRegionId).includes(tr.teamRegionId)
                                );

                                // insert the parent region
                                if (
                                    !teamRegionsToDisplay.find(
                                        (tr) => tr.teamRegionId === teamRegion.parentTeamRegionId
                                    )
                                ) {
                                    const parentTeamRegion = condition.teamRegionsOptions.find(
                                        (tr) => tr.teamRegionId === teamRegion.parentTeamRegionId
                                    );
                                    teamRegionsToDisplay.push(parentTeamRegion);
                                    
                                    if (condition.teamRegionPopup.teamRegionsInitialized) {
                                        this.setPopUpActive(parentTeamRegion, condition); // fire a popup message
                                    }
                                }

                                processedTeamRegionIds.push(teamRegion.parentTeamRegionId);
                            }

                            // Lower level (children)
                            const allChildRegions = condition.teamRegionsOptions.filter(
                                (tr) => tr.parentTeamRegionId === teamRegion.teamRegionId
                            );

                            let grandChildRegionsToRemove = [];
                            allChildRegions.forEach((cr) => {
                                cr.$isDisabled = true;
                                processedTeamRegionIds.push(cr.teamRegionId);

                                grandChildRegionsToRemove = condition.teamRegionsOptions.filter(
                                    (tr) => tr.parentTeamRegionId === cr.teamRegionId
                                );

                                grandChildRegionsToRemove.forEach((gcr) => {
                                    gcr.$isDisabled = true;
                                    processedTeamRegionIds.push(gcr.teamRegionId);
                                });
                            });

                            const childRegionsToRemove = teamRegionsToDisplay.filter((tr) =>
                                allChildRegions.map((cr) => cr.teamRegionId).includes(tr.teamRegionId)
                            );

                            // remove the child regions
                            teamRegionsToDisplay = teamRegionsToDisplay.filter(
                                (tr) => !childRegionsToRemove.map((chr) => chr.teamRegionId).includes(tr.teamRegionId)
                            );

                            if (grandChildRegionsToRemove.length > 0) {
                                // remove the grand child regions
                                teamRegionsToDisplay = teamRegionsToDisplay.filter(
                                    (tr) =>
                                        !grandChildRegionsToRemove
                                            .map((chr) => chr.teamRegionId)
                                            .includes(tr.teamRegionId)
                                );
                            }

                            processedTeamRegionIds.push(teamRegion.teamRegionId);
                        }
                    }
                }

                this.conditions[itemIndex].data = teamRegionsToDisplay;
            }
            
            condition.teamRegionsOptions.forEach((trOption) => {
                if (!processedTeamRegionIds.find((id) => id === trOption.teamRegionId)) 
                    trOption.$isDisabled = false;
            });

            condition.teamRegionPopup.teamRegionsInitialized = true;
        },
        deepCopyData(data) {
            return JSON.parse(JSON.stringify(data));
        }
    },
    validations() {
        return {
            form: {
                questionName: {
                    // name is a free-input for Booking group to let Carrier customise it for their own purpose
                    required: requiredIf(() => {
                        return this.isBookingGroup;
                    }),
                    maxLength: maxLength(40),
                    alpha: () => {
                        if (!this.isBookingGroup) 
                            return true;

                        // validate if under booking group
                        const alpha = helpers.regex('alpha', /^[a-zA-Z]*$/);
                        return alpha(this.form.questionName);
                    },
                    duplicate: () => {
                        if (!this.isBookingGroup) 
                            return true;

                        // validate if under booking group
                        const questionsData = this.questionsList.filter((item) => item.name === this.form.questionName);
                        return !(!this.isEditMode && questionsData.length);
                    }
                },
                label: {
                    required
                },
                type: {
                    required
                },
                options: {
                    required:
                        // eslint-disable-next-line func-names
                        requiredIf(function() {
                            return this.form.type === 'Dropdown';
                        })
                }
            }
        };
    }
};
</script>
<style lang="scss" scoped>
.questions-form-modal {
    max-width: 550px;
    width: 550px;
}
.options {
    ::v-deep .md-layout-item {
        padding: 0px;
    }
    ::v-deep input {
        border-bottom: 1px solid #dedede !important;
        margin: 0;
        border: none;
        border-radius: 0;
        width: 100%;
        padding: 10px;
        float: left;
        font-size: 14px;
        margin-bottom: 10px;
    }
    ::v-deep .md-field {
        padding: 0px;
        margin-top: 5px;
        &::before,
        &::after {
            bottom: 0px;
            display: none;
        }
    }
    ::v-deep .md-textarea {
        padding: 5px 8px !important;
        border: 1px solid #eee !important;
        resize: none !important;
        min-height: 150px !important;
    }
    ::v-deep .md-error {
        padding-top: 12px;
    }
    .add-icon {
        margin-left: -25px;
        float: right;
        cursor: pointer;
        z-index: 100;
        margin-top: 10px;
    }
    .label-text {
        padding: 0px !important;
    }
}
.options-table {
    ::v-deep .md-table-cell {
        padding: 0px;
        border: 0px;
    }
    ::v-deep input {
        border: 1px solid #dedede;
        width: 100%;
        padding: 8px;
    }
    ::v-deep .md-field {
        padding: 0px;
        &::before,
        &::after {
            bottom: 0px;
            display: none;
        }
    }
    .md-table {
        max-height: 250px;
    }
}
.option-toggle-button {
    display: inline-block;
    vertical-align: middle;
    margin-left: -30px;
    margin-right: 5px;

    .md-button {
        // background-color: #2b93ff  !important;
        height: 30px;
        width: 30px;
        min-width: 30px;
        margin-right: 0;
        ::v-deep i {
            color: #2b93ff !important;
        }
        background-color: transparent !important;
    }
    .md-button:active,
    .md-button:focus {
        background-color: transparent !important;
        ::v-deep i {
            color: #2b93ff !important;
        }
    }
    .md-button:hover {
        background: none !important;
        background-color: #2b93ff !important;
        ::v-deep i {
            color: #fff !important;
        }
    }
}
.option-toggle-button-list {
    margin-left: -15px !important;
}

.modal-container {
    width: 550px !important;
    max-width: 550px !important;
}
.modal-body {
    width: 550px !important;
}

.modal-footer {
    width: 550px !important;
}

::v-deep .vs__search {
    padding: 0 !important;
}
</style>
