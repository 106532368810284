<template>
    <div v-if="dropdownOptions != null && dropdownOptions.length > 0" class="card-icon running-load-menu">
        <drop-down direction="down">
            <a href="#" class="load-header-text">
                <md-icon>assignment</md-icon>
                {{ selectedOption != null ? selectedOption.label : '' }}
                <md-icon class="icon-arrow">expand_more</md-icon>
            </a>
            <ul class="dropdown-menu">
                <li v-for="item in dropdownOptions" :key="item.key">
                    <a @click="handleSelectedOption(item)">
                        {{ item.label }}
                    </a>
                </li>
            </ul>
        </drop-down>
    </div>
    <div v-else class="card-icon">
        <md-icon v-if="defaultIcon != null">{{ defaultIcon }}</md-icon>
        {{ defaultText }}
    </div>
</template>

<script>
export default {
    name: 'CardHeaderDropdown',
    props: {
        dropdownOptions: {
            type: Array,
            default: null
        },
        defaultIcon: {
            type: String,
            default: null
        },
        defaultText: {
            type: String,
            default: null
        },
        selectedOption: {
            type: Object,
            default: null
        }
    },
    methods: {
        handleSelectedOption(value) {
            this.$emit('selectedOption', value);
        }
    }
};
</script>

<style lang="scss" scoped>
.running-load-menu {
    padding: 5px 10px !important;

    .dropdown-menu {
        li:hover {
            cursor: pointer;
            a {
                color: #fff !important;
            }
        }
        li > a {
            color: #333 !important;
        }
    }

    ::v-deep .notification {
        margin: 0;
    }
}
</style>
