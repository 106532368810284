<template>
    <div class="content">
        <inventory-filter-component
            :class="$root.isTablet ? 'tablet-filter-container' : ''"
            @onFilterStops="handleFilterOrSearch"
            @onExportClicked="handleExportClick"
            :team-members="teamMembers"
            :warehouses="warehouses"
            :item-locations="itemLocations"
        />
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="error-message-container">
                    <batch-upload-error-message :content="errorMessages" :has-error-message="hasError" />
                </div>
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-warning">
                        <div class="card-icon">
                            <md-icon>inventory</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content class="body-list">
                        <div :key="updateList" v-if="!isListLoading">
                            <md-table class="context-menu-support custom-paginated-table">
                                <md-table-row>
                                    <md-table-head>Barcode</md-table-head>
                                    <md-table-head>Description</md-table-head>
                                    <md-table-head>Order Number</md-table-head>
                                    <md-table-head>Current Location</md-table-head>
                                    <md-table-head>Status</md-table-head>
                                    <md-table-head v-if="$root.isDesktop && !isReadOnlyUser">Actions</md-table-head>
                                </md-table-row>
                                <md-table-row
                                    v-for="item in inventoryList"
                                    :key="item.itemId"
                                    @click.stop="showSidebar(item.itemId)"
                                >
                                    <md-table-cell>{{ item.barcode }}</md-table-cell>
                                    <md-table-cell>{{ item.description }}</md-table-cell>
                                    <md-table-cell>
                                        <router-link
                                            :to="{ name: 'Orders', params: { orderId: item.orderId } }"
                                            target="_blank"
                                        >
                                            {{ item.orderNumber }}
                                        </router-link>
                                    </md-table-cell>
                                    <md-table-cell>{{ getLocationStatus(item.currentLocation) }}</md-table-cell>
                                    <md-table-cell class="status-container">
                                        <inventory-status-button
                                            :item-status="item.itemStatus"
                                            :item-id="item.itemId"
                                        />
                                    </md-table-cell>

                                    <md-table-cell v-if="$root.isDesktop && !isReadOnlyUser" class="action-buttons">
                                        <update-item-button
                                            v-if="!isReadOnlyUser"
                                            :item="item"
                                            @itemUpdated="handleUpdateItem"
                                            :is-delivered="item.itemStatus === 'Delivered'"
                                            :stop-status="item.stopStatus"
                                            :shipment-status="item.shipmentStatus"
                                        />

                                        <delete-item-button
                                            v-if="!isReadOnlyUser"
                                            :item-id="item.itemId"
                                            :is-shipment-attached="item.shipmentId !== null"
                                            :is-stop-attached="item.stopId !== null"
                                            :is-order-attached="item.orderId !== null"
                                            @itemDeleted="handleDeletedItem"
                                        />
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <div v-if="inventoryList.length == 0">
                                <p class="no-result-message">No results matching your search/filter could be found.</p>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader">
                                <fade-loader :loading="true" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
                <md-card-actions class="page-footer" md-alignment="space-between">
                    <div>
                        <p v-if="total === pagination.perPage" class="card-category">
                            Page {{ pagination.currentPage }} of many
                        </p>
                        <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                    </div>
                    <pagination
                        v-model="pagination.currentPage"
                        class="pagination-no-border pagination-success"
                        :per-page="pagination.perPage"
                        :total="total"
                        @change-page="handleChangePage($event, pagination.perPage)"
                    />
                </md-card-actions>
            </div>

            <transition name="slide">
                <inventory-sidebar
                    v-if="isShowSidebar"
                    :item-id="itemId"
                    :warehouses="warehouses"
                    :team-members="teamMembers"
                    v-click-outside="toggleSidebarWindow"
                    @closeModal="toggleSidebarWindow"
                    @itemUpdated="(p) => itemUpdated(p)"
                    @itemDeleted="handleDeletedItem"
                />
            </transition>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { mapGetters } from 'vuex';
import { Pagination, BatchUploadErrorMessage } from '@/components';
import { ITEM_MOVEMENT_LOCATION_CONSTANTS } from '@/utils/constants';
import { InventoryFilterComponent } from './components';
import InventorySidebar from './InventorySidebar';

import { InventoryStatusButton, UpdateItemButton, DeleteItemButton } from './buttons';

export default {
    name: 'InventoryOverview',
    mixins: [GeneralMixin],
    components: {
        InventoryFilterComponent,
        FadeLoader,
        Pagination,
        InventorySidebar,
        BatchUploadErrorMessage,
        InventoryStatusButton,
        UpdateItemButton,
        DeleteItemButton
    },
    data() {
        return {
            teamMembers: [],
            warehouses: [],
            inventoryList: [],
            /**
             * @type {import('@/components/ItemLocations/types/ItemLocation').ItemLocation[]}
             */
            itemLocations: [],
            isListLoading: false,
            pagination: PAGINATION_DEFAULTS,
            filters: {},
            maxPage: 1,
            isShowSidebar: false,
            itemId: null,
            selectedFile: null,
            hasError: false,
            errorMessages: [],
            updateList: 0,
            selectedTeamRegionId: undefined,
            movementStatus: ITEM_MOVEMENT_LOCATION_CONSTANTS
        };
    },
    computed: {
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    async mounted() {
        this.selectedTeamRegionId = this.user.teamRegionId ? this.user.teamRegionId : -1; // NOT SET

        const currentPage = Number(this.$route.query.currentPage) || 1;
        if (currentPage) {
            this.pagination.currentPage = currentPage;
        }

        // wait for all the data to be fetched
        // use allSettled so that the page will still load even if one of the requests fail
        await Promise.allSettled([
            this.getTeamMembers(),
            this.getWarehouses(),
            this.getItemLocations(),
            this.handleChangePage(currentPage, this.pagination.perPage)
        ]);
    },
    methods: {
        getLocationStatus(key) {
            const locationStatus = this.movementStatus.find((x) => x.key === key);

            if (locationStatus) {
                return locationStatus.name;
            }
            return key;
        },
        itemUpdated(msg) {
            // from sidebar
            const fnd = this.inventoryList.find((element) => element.itemId === msg.itemId);
            if (fnd === undefined) 
                return;

            if (msg.details) {
                fnd.barcode = msg.details.barcode;
                fnd.description = msg.details.description;
                fnd.itemStatus = msg.details.status;
            }
            this.updateList += 1;
        },
        async getTeamMembers(tripDate = null) {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS', {
                date: tripDate,
                teamRegionId: this.selectedTeamRegionId
            });
        },
        async getWarehouses(pageNumber = 1, itemsPerPage = 200) {
            let response = {
                data: {
                    warehouses: [],
                    totalWarehouses: null
                }
            };
            try {
                const endpoint = `/api/warehouses`;
                response = await handleRequests(endpoint, {
                    params: {
                        pageNumber,
                        itemsPerPage,
                        ...this.cleanFilters()
                    }
                });
            } catch (error) {
                const message = 'Error in getting the warehouses list';
                showErrorMessage(this, message, error);
            }
            this.warehouses = response.data.warehouses;
        },
        async getItemLocations() {
            try {
                const endpoint = `/api/item-locations`;
                const response = await handleRequests(endpoint);
                this.itemLocations = response.data;
            } catch (e) {
                const message = 'Error in getting the warehouses list';
                showErrorMessage(this, message, error);
            }
        },
        getFullNameFromTeamMemberId(id) {
            const fnd = this.teamMembers.find((element) => element.publicUserId === id);
            if (fnd === undefined) 
                return '';
            return fnd.fullName;
        },
        async getInventoryList(pageNumber = 1, itemsPerPage = 50) {
            let response = {
                data: {
                    items: [],
                    totalItems: null
                }
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/items/search`;
                response = await handleRequests(endpoint, {
                    params: {
                        pageNumber,
                        itemsPerPage,
                        ...this.cleanFilters()
                    }
                });

                const { items, totalItems } = response.data;
                this.setInventoryAndTotal(items, totalItems);
            } catch (error) {
                const message = 'Error in getting the inventory list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;
            // return response;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });

            return this.filters;
        },
        setInventoryAndTotal(data, total) {
            this.inventoryList = data;
            this.pagination.total = total || data.length;
            this.maxPage = Math.ceil(this.pagination.total / this.pagination.perPage);
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            await this.getInventoryList(currentPage, perPage);
        },
        async handleFilterOrSearch(data) {
            this.filters = Object.assign(this.filters, data);
            await this.handleChangePage(1, this.pagination.perPage);
        },
        showSidebar(id) {
            this.isShowSidebar = true;
            this.itemId = id;
        },
        toggleSidebarWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown)
                this.isShowSidebar = !this.isShowSidebar;
        },
        async handleExportClick(data) {
            this.filters = Object.assign(this.filters, data);
            this.$_handleLoaderState(true, 'EXPORTING...');
            try {
                const endpoint = `/api/items/export`;
                const response = await handleRequests(endpoint, {
                    method: 'post',
                    responseType: 'blob',
                    params: {
                        pageNumber: 1,
                        itemsPerPage: 1000,
                        ...this.cleanFilters()
                    }
                });

                // TODO: When reports get merged, replace the following lines with downloadFile() method.
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'InventoryList.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                const message = 'Error in exporting items.';
                showErrorMessage(this, message, error);
            }

            this.$_handleLoaderState(false);
        },
        handleUpdateItem(item) {
            const result = this.inventoryList.find(({ itemId }) => itemId === item.itemId);
            if (result) {
                result.barcode = item.barcode;
                result.description = item.description;
            }
        },
        handleDeletedItem() {
            this.isShowSidebar = false;
            if (this.inventoryList.length > 1)
                this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
            else 
                this.handleChangePage(Math.max(this.pagination.currentPage - 1, 1), this.pagination.perPage);
        }
    }
};
</script>

<style lang="scss" scoped>
.no-result-message {
    text-align: center;
    margin-top: 45px;
    font-size: 16px;
}

::v-deep .status-container {
    .md-table-cell-container {
        overflow: visible;
    }
}

::v-deep .rating-container {
    .ratings {
        display: flex;
        align-items: flex-end;

        > {
            display: inline-block;
        }

        .md-icon {
            margin: 0;
        }
    }
}

.slide-leave-active,
.slide-enter-active {
    transition: 0.3s;
}

.slide-enter {
    transform: translateX(100%);
}

.slide-leave-to {
    transform: translateX(100%);
}

.content {
    margin-top: -15px;

    ::v-deep .md-card.md-theme-default {
        margin-bottom: 0;
    }
}

::v-deep .filter-steps--container {
    // width: 880px;
    margin-top: 6px;
    // float: right;

    .filter-steps--choices {
        margin-top: 0;
        padding-bottom: 0;

        > .md-input {
            display: initial;
        }
    }
}

::v-deep .filter-steps--container.tablet-filter-container {
    width: initial;
    float: left;
    margin-left: 20px;
}

::v-deep .search--container {
    width: 250px;
    float: right;
    margin-top: 6px;
}

::v-deep .md-table-cell-container {
    max-width: 205px;
    // .custom-btn {
    //     margin: 0;
    //     max-width: initial;
    //     width: 90px;
    //     line-height: 29px;
    // }

    .md-button.md-round.md-just-icon {
        box-shadow: none;
    }
}

.custom-hidden-tablet {
    display: none;
}

.address-container {
    display: none;
}

@media (min-width: 1400px) {
    .address-container {
        display: table-cell;

        ::v-deep .md-table-cell-container {
            max-width: 300px;
        }
    }
}

::v-deep .md-table-cell {
    padding: 0px 8px;

    &:last-child {
        .md-table-cell-container {
            text-align: left !important;
            justify-content: flex-start;
        }
    }
}

::v-deep .md-table-row {
    &.zero-results {
        padding: 16px;
        text-align: center;
    }

    .custom-button-container .custom-a-blue a {
        color: rgba(0, 0, 0, 0.87);
    }
}

::v-deep .md-table-row:hover {
    background-color: #fafafa;
    cursor: pointer;
}

::v-deep .md-toolbar {
    .md-field {
        &:first-child,
        &:last-child {
            margin: 8px;
        }
    }

    .stops-search {
        padding-bottom: 10px;

        &::before,
        &::after {
            bottom: 10px;
        }
    }
}

::v-deep .md-card-actions {
    padding: 16px;

    &.page-footer {
        padding-top: 0px;
    }
}

.stop-reference ::v-deep a {
    color: rgba(0, 0, 0, 0.87);
}

::v-deep .header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.gray-text {
    color: rgba(0, 0, 0, 0.5);
}

.profile-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

.loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

.action-buttons {
    button,
    ::v-deep button {
        margin: 0 2px;
    }

    button:last-child {
        margin-right: 0;
    }
}

.assigned-column {
    display: inline-block;
    vertical-align: middle;

    ::v-deep .custom-ellipsis {
        max-width: 150px;
    }
}

.stop-checkbox {
    margin: 0;
}

.checkbox-head {
    margin-top: 4px;
}

.body-list {
    position: relative;

    .sticky {
        position: fixed;
        top: 0;
        z-index: 9;
        left: 75px;
    }
}

/*    .custom-toolbar {
        align-items: start;
        margin-top: 1px;

        .custom-toolbar-start {
            flex: 3;
        }
    }
*/

.icon-warning {
    color: #ff9800 !important;
    cursor: pointer;
    left: 14px;
}

@media (max-width: 992px) {
    .tablet-button-margin {
        margin-right: 10px;
    }
}
</style>
