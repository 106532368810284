\
<template>
    <div class="cf-container">
        <div class="cf-action-container">
            <md-button
                title="Create Service Package"
                class="md-primary md-just-icon md-round"
                @click.stop="createRateGroup"
            >
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <md-table class="custom-paginated-table">
            <md-table-row>
                <md-table-head>Name</md-table-head>
                <md-table-head>Type</md-table-head>
                <md-table-head class="actions">Actions</md-table-head>
            </md-table-row>

            <md-table-row v-for="rateGroup in rateGroupsList" :key="rateGroup.rateGroupId">
                <md-table-cell>{{ rateGroup.rateGroupName }}</md-table-cell>
                <md-table-cell>{{ getRuleType(rateGroup.ruleType) }}</md-table-cell>

                <md-table-cell class="action-buttons">
                    <md-button
                        title="Update rate group"
                        class="md-warning md-just-icon md-round"
                        @click.stop="updateRateGroup(rateGroup.rateGroupId)"
                    >
                        <md-icon>edit</md-icon>
                    </md-button>
                    <span>
                        <md-button
                            title="Delete rate group"
                            class="md-danger md-just-icon md-round"
                            @click.stop="handleDeleteRateGroup(rateGroup.rateGroupId)"
                            :disabled="rateGroup.canNotDelete"
                        >
                            <md-icon>delete</md-icon>
                        </md-button>
                        <md-tooltip v-if="rateGroup.canNotDelete" class="line-tooltip" md-direction="top">
                            You cannot delete the rate group. It is already assigned to a Service rate.
                        </md-tooltip>
                    </span>
                </md-table-cell>
            </md-table-row>
        </md-table>
        <div class="text-center" v-if="rateGroupsList.length == 0">
            <p class="no-result-message">No results matching your search/filter could be found.</p>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { RATE_CHARGING_TYPES } from '@/utils/constants';
import CreateRateGroupModal from './CreateRateGroupModal';

export default {
    name: 'RateGroupsList',
    mixins: [GeneralMixin],
    data() {
        return {
            rateGroupsList: [],
            isListLoading: false,
            filters: {},
            selectedFile: null,
            hasError: false,
            errorMessages: []
        };
    },
    async mounted() {
        this.rateGroupsList = await this.getRateGroupsList();
    },
    methods: {
        async getRateGroupsList() {
            let response = {
                data: []
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/rate-groups`;

                response = await handleRequests(endpoint);
            } catch (error) {
                const message = 'Error in getting the rate groups list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;
            return response.data;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },
        async createRateGroup() {
            const chargeList = [...RATE_CHARGING_TYPES];
            chargeList.splice(0, 2);
            this.$modal
                .show(CreateRateGroupModal, {
                    rateGroupDetails: {
                        RateGroupName: null,
                        RuleType: null
                    },
                    chargingList: chargeList
                })
                .then(async (response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.rateGroupsList = await this.getRateGroupsList();

                        this.$modal.hide();
                    }
                });
        },
        async getRateGroupDetails(rateGroupId) {
            try {
                const api = `/api/rate-groups/${rateGroupId}`;
                const { data } = await handleRequests(api);
                return data;
            } catch (error) {
                const message = 'Error in getting the rate group details';
                showErrorMessage(this, message, error);
            }
            return {};
        },
        async updateRateGroup(rateGroupId) {
            const rateGroupDetails = await this.getRateGroupDetails(rateGroupId);
            const chargeList = [...RATE_CHARGING_TYPES];
            chargeList.splice(0, 2);

            this.$modal
                .show(CreateRateGroupModal, {
                    rateGroupDetails: Object.assign({}, rateGroupDetails),
                    isUpdate: true,
                    chargingList: chargeList
                })
                .then(async (response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.rateGroupsList = await this.getRateGroupsList();

                        this.$modal.hide();
                    }
                });
        },
        async deleteRateGroup(rateGroupId) {
            const payload = {
                method: 'delete'
            };
            const api = `/api/rate-groups/${rateGroupId}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Rate Group was deleted!',
                    type: 'success'
                });

                this.rateGroupsList = await this.getRateGroupsList();
            } catch (e) {
                const message = 'Error in deleting a rate group.';
                showErrorMessage(this, message, e);
                this.isListLoading = false;
            }
        },
        handleDeleteRateGroup(rateGroupId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Rate Group',
                    body: 'Are you sure you want to delete this rate group?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isListLoading = true;
                        await this.deleteRateGroup(rateGroupId);
                    }
                });
        },
        getRuleType(ruleType) {
            const type = RATE_CHARGING_TYPES.find((rule) => rule.type === ruleType);
            if (type !== undefined) 
                return type.key;
            return null;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .error-body div {
    margin: 0;
    margin-top: 20px;
    padding: 10px;
}

.rate-groups ::v-deep .md-card {
    margin-bottom: 0;
}
</style>
