<template>
    <div>
        <div class="address-box" :class="!disableGpsCoordinates ? 'address-has-coordinates' : ''">
            <form-group :name="name" :label="label" v-show="!isCoordinates">
                <md-icon v-if="icon">{{ icon }}</md-icon>
                <md-input
                    ref="autocomplete"
                    type="text"
                    :class="classname"
                    :id="id"
                    :stop-id="stopId"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :title="autocompleteText"
                    v-model="autocompleteText"
                    @focus="onFocus($event)"
                    @blur="onBlur()"
                    @change="onChange"
                    @keypress="onKeyPress"
                    @keyup="onKeyUp"
                />
            </form-group>
            <div v-if="isCoordinates" class="coordinate-address">
                <form-group name="stopName" label="Address Name">
                    <md-input v-model="value.name" :placeholder="gpsCoordinatePlaceholder" />
                </form-group>
            </div>
        </div>

        <div class="coordinate-toggle-button" v-if="!disableGpsCoordinates">
            <md-button
                class="md-primary md-just-icon md-round"
                @click="toggleCoordinates"
                :class="isCoordinates ? 'has-coordinates' : ''"
            >
                <md-icon v-if="isCoordinates">location_on</md-icon>
                <md-icon v-else>home</md-icon>
                <md-tooltip md-direction="right" v-if="isCoordinates">Use Address</md-tooltip>
                <md-tooltip md-direction="right" v-else>Use Coordinates</md-tooltip>
            </md-button>
        </div>
        <div class="coordinates-container" v-if="!disableGpsCoordinates">
            <div v-show="isCoordinates" class="coordinates">
                <form-group :name="name" label="Coordinates">
                    <md-input v-model="coordinateString" placeholder="Paste GPS coordinates" />
                </form-group>
            </div>
        </div>
    </div>
</template>

<script>
import GoogleMapsLoader from 'google-maps';
import { mapGetters } from 'vuex';
import { isAValidCoordinate } from '@/helpers';

const ADDRESS_COMPONENTS = {
    subpremise: 'short_name',
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    postal_town: 'long_name',
    administrative_area_level_1: 'short_name',
    administrative_area_level_2: 'long_name',
    country: 'long_name',
    postal_code: 'short_name'
};
const ADDRESS_COMPONENTS_RANK = {
    // plus_code: 1, // indicates an encoded location reference, derived from latitude and longitude
    subpremise: 2, // unit number (apartment) // first-order entity below a named location,
    premise: 3, // named location, usually a building
    street_number: 4,
    route: 5, // street
    locality: 6, // city
    postal_town: 7, // city
    administrative_area_level_2: 8, // suburb ?
    administrative_area_level_1: 9, // province or state
    country: 10,
    postal_code: 11
};
const CITIES_TYPE = ['locality', 'postal_town', 'administrative_area_level_3'];
const REGIONS_TYPE = [
    'locality',
    'sublocality',
    'postal_code',
    'country',
    'administrative_area_level_1',
    'administrative_area_level_2'
];
export default {
    name: 'GoogleAutocomplete',
    props: {
        id: {
            type: String,
            required: true
        },
        classname: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Start typing'
        },
        types: {
            type: [String, Array],
            default: () => ['geocode']
        },
        country: {
            type: [String, Array],
            default: null
        },
        enableGeolocation: {
            type: Boolean,
            default: false
        },
        geolocationOptions: {
            type: Object,
            default: null
        },
        shouldFocus: {
            type: Boolean,
            default: false
        },
        stopId: {
            type: Number,
            default: 0
        },
        value: {
            type: Object,
            default: null
        },
        disableGpsCoordinates: {
            type: Boolean,
            default: true
        },
        gpsCoordinatePlaceholder: {
            type: String,
            default: 'Enter a name for this stop'
        },
        name: {
            type: String,
            default: 'address'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        shouldKeepOriginalAddress: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            /**
             * The Autocomplete object.
             *
             * @type {Autocomplete}
             * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
             */
            autocomplete: null,
            /**
             * Autocomplete input text
             * @type {String}
             */
            autocompleteText: '',
            geolocation: {
                /**
                 * Google Geocoder Objet
                 * @type {Geocoder}
                 * @link https://developers.google.com/maps/documentation/javascript/reference#Geocoder
                 */
                geocoder: null,
                /**
                 * Filled after geolocate result
                 * @type {Coordinates}
                 * @link https://developer.mozilla.org/en-US/docs/Web/API/Coordinates
                 */
                loc: null,
                /**
                 * Filled after geolocate result
                 * @type {Position}
                 * @link https://developer.mozilla.org/en-US/docs/Web/API/Position
                 */
                position: null
            },
            isCoordinates: false,
            coordinateString: ''
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    watch: {
        'value.address': function(newVal, oldVal) {
            this.update(newVal);
        },
        autocompleteText(newVal, oldVal) {
            if (!this.isCoordinates) {
                this.$emit('textChanged', newVal);
                this.$emit('input', this.value);
            }
        },
        country(newVal, oldVal) {
            this.autocomplete.setComponentRestrictions({
                country: this.country === null ? [] : this.country
            });
        },
        coordinateString(newVal, oldVal) {
            if (isAValidCoordinate(newVal)) {
                const coordinates = newVal.split(',');
                const latitude = Number(coordinates[0]);
                const longitude = Number(coordinates[1]);

                if (this.value != null) {
                    this.value.location.latitude = latitude;
                    this.value.location.longitude = longitude;
                    this.value.name = this.value.name;
                    this.value.address = newVal;
                    this.value.coordinateAddress = newVal;
                    if (this.stopId) {
                        this.value.stopId = this.stopId;
                    }
                }
                const data = {
                    location: {
                        latitude,
                        longitude
                    },
                    name: this.value.name,
                    address: newVal,
                    stopId: this.stopId
                };
                this.$emit('input', this.value);
                this.$emit('placechanged', data);
                this.$emit('handleCoordinates', this.isCoordinates);
            } else {
                this.value.address = newVal;
                this.value.coordinateAddress = newVal;
                this.value.location.latitude = null;
                this.value.location.longitude = null;
                this.$emit('handleCoordinates', this.isCoordinates);
                this.$emit('input', this.value);
            }
        }
    },
    async mounted() {
        this.value.coordinateAddress = '';
        if (this.value.address != null) {
            if (isAValidCoordinate(this.value.address)) {
                this.isCoordinates = true;
                this.coordinateString = this.value.address;
            } else {
                this.isCoordinates = false;
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if (!this.disableGpsCoordinates) {
                if (localStorage.getItem('stops.defaults.use-coordinates') !== null) {
                    if (localStorage.getItem('stops.defaults.use-coordinates') === 'true') {
                        this.isCoordinates = true;
                    } else {
                        this.isCoordinates = false;
                    }
                    this.coordinateString = this.value.address;
                }
            }
        }
        this.$emit('handleCoordinates', this.isCoordinates);
        this.initializeGoogleMaps();
    },
    methods: {
        toggleCoordinates() {
            this.isCoordinates = !this.isCoordinates;
            localStorage.setItem('stops.defaults.use-coordinates', this.isCoordinates);
            this.$emit('handleCoordinates', this.isCoordinates);
        },
        async initializeGoogleMaps() {
            GoogleMapsLoader.KEY = process.env.VUE_APP_GOOGLE_API_KEY;
            GoogleMapsLoader.LIBRARIES = ['geometry', 'places', 'drawing'];
            GoogleMapsLoader.VERSION = '3.53';

            await GoogleMapsLoader.load(() => {
                this.initializePlace();
                if (this.value != null) {
                    this.update(this.value.address);
                }

                if (this.shouldFocus) {
                    this.$refs.autocomplete.$el.focus();
                }
            });
        },
        initializePlace() {
            const options = {};
            if (this.types) {
                options.types = typeof this.types === 'string' ? [this.types] : [...this.types, 'establishment'];
            }
            this.autocomplete = new google.maps.places.Autocomplete(document.getElementById(this.id), options);
            this.autocomplete.addListener('place_changed', this.onPlaceChanged);
        },
        /**
         * When a place changed
         */
        onPlaceChanged() {
            const place = this.autocomplete.getPlace();
            if (!place.geometry) {
                this.$emit('no-results-found', place, this.id);
                return;
            }
            if (place.address_components !== undefined) {
                // return returnData object and PlaceResult object
                const formatted = this.formatResult(place);
                const { latitude, longitude, fullAddress, viewport } = formatted;

                const addressComponents = {
                    inputAddress: formatted.fullAddress,
                    geocoder: 'Google Places - App Portal', // must be exactly the same in backend
                    type: formatted.type,
                    confidenceScore: null,
                    address: formatted.fullAddress,
                    location: {
                        latitude,
                        longitude
                    },
                    name: formatted.name,
                    line1: formatted.line1,
                    city: formatted.city,
                    province: formatted.province,
                    provinceCode: formatted.provinceCode,
                    postcode: formatted.postal_code,
                    country: formatted.country,
                    countryCode: formatted.countryCode
                };

                if (this.value != null) {
                    if (this.value.location == null) 
                        this.value.location = {};
                    this.value.location.latitude = latitude;
                    this.value.location.longitude = longitude;

                    if (!this.shouldKeepOriginalAddress) {
                        this.value.address = fullAddress;
                        this.value.name = place.name;
                    }

                    if (this.stopId) {
                        this.value.stopId = this.stopId;
                    }

                    this.value.addressComponents = addressComponents;
                }

                const data = {
                    location: {
                        latitude,
                        longitude
                    },
                    viewport,
                    name: !this.shouldKeepOriginalAddress ? place.name : this.value.name,
                    address: !this.shouldKeepOriginalAddress ? fullAddress : this.value.address,
                    stopId: this.stopId,
                    addressComponents
                };
                this.$emit('placechanged', data);

                this.autocompleteText = document.getElementById(this.id).value;
                this.onChange();
            }
        },
        /**
         * When the input gets focus
         */
        onFocus(e) {
            // for selecting the whole input field
            setTimeout(() => e.target.select(), 0);
            this.biasAutocompleteLocation();
            this.$emit('focus');
        },
        /**
         * When the input loses focus
         */
        onBlur() {
            // this is to prevent invalid address. when user does not choose on the recommended addresses given by google
            // it will then revert back to the previous valid address if any.
            if (this.autocompleteText) {
                if (this.value.address !== this.autocompleteText) {
                    this.autocompleteText = this.value.address;
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (!this.shouldKeepOriginalAddress) {
                    this.value.address = null;
                    this.value.location.latitude = null;
                    this.value.location.longitude = null;
                    this.value.name = null;
                }
            }

            this.$emit('blur');
        },
        /**
         * When the input got changed
         */
        onChange() {
            this.$emit('change', this.autocompleteText);
        },
        /**
         * When a key gets pressed
         * @param  {Event} event A keypress event
         */
        onKeyPress(event) {
            this.$emit('keypress', event);
        },
        /**
         * When a keyup occurs
         * @param  {Event} event A keyup event
         */
        onKeyUp(event) {
            this.$emit('keyup', event);
        },
        /**
         * Clear the input
         */
        clear() {
            this.autocompleteText = '';
        },
        /**
         * Focus the input
         */
        focus() {
            this.$refs.autocomplete.focus();
        },
        /**
         * Blur the input
         */
        blur() {
            this.$refs.autocomplete.blur();
        },
        /**
         * Update the value of the input
         * @param  {String} value
         */
        update(value) {
            this.autocompleteText = value;
            if (isAValidCoordinate(value)) {
                this.isCoordinates = true;
                this.coordinateString = value;
            }
        },
        /**
         * Update internal location from navigator geolocation
         * @param  {Function} (geolocation, position)
         */
        updateGeolocation() {
            if (navigator.geolocation) {
                const options = {};
                if (this.geolocationOptions) {
                    Object.assign(options, this.geolocationOptions);
                }
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const geolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                        this.geolocation.loc = geolocation;
                        this.geolocation.position = position;
                        if (callback) {
                            callback(geolocation, position);
                        }
                    },
                    (err) => {
                        this.$emit('error', 'Unable to get your current location', err);
                    },
                    options
                );
            }
        },
        // Bias the autocomplete object to the user's start position, if there is no start position, use navigator.geolocation instead
        biasAutocompleteLocation() {
            let latitude = null;
            let longitude = null;

            if (this.user.startLocation != null) {
                ({ latitude, longitude } = this.user.startLocation);
            }

            if (latitude && longitude) {
                const center = { lat: latitude, lng: longitude };
                const circle = new google.maps.Circle({
                    center,
                    // 400km radius
                    radius: 400000
                });
                this.autocomplete.setBounds(circle.getBounds());
            } else if (this.enableGeolocation) {
                this.updateGeolocation((geolocation, position) => {
                    const circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy
                    });
                    this.autocomplete.setBounds(circle.getBounds());
                });
            }
        },
        /**
         * Format result from Geo google APIs
         * @param place
         * @returns {{formatted output}}
         */
        formatResult(place) {
            const returnData = {};
            for (let i = 0; i < place.address_components.length; i++) {
                const addressType = place.address_components[i].types[0];
                returnData.type = this.compareAddressTypeRank(returnData.type, addressType);
                if (ADDRESS_COMPONENTS[addressType]) {
                    const val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
                    returnData[addressType] = val;

                    if (addressType === 'country') {
                        returnData.countryCode = place.address_components[i].short_name;
                    } else if (addressType === 'administrative_area_level_1') {
                        returnData.provinceCode = place.address_components[i].short_name;
                        returnData.province = place.address_components[i].long_name;
                    }
                }
            }
            returnData.viewport = place.geometry.viewport;
            returnData.latitude = place.geometry.location.lat();
            returnData.longitude = place.geometry.location.lng();
            returnData.fullAddress = place.formatted_address;
            returnData.line1 = `${returnData.street_number || ''} ${returnData.route || ''}`.trim();
            returnData.city =
                returnData.locality || returnData.postal_town || returnData.administrative_area_level_3 || '';
            returnData.name = place.name;
            return returnData;
        },
        /**
         * Extract configured types out of raw result as
         * Geocode API does not allow to do it
         * @param results
         * @returns {GeocoderResult}
         * @link https://developers.google.com/maps/documentation/javascript/reference#GeocoderResult
         */
        filterGeocodeResultTypes(results) {
            if (!results || !this.types) 
                return results;
            const output = [];
            let types = [this.types];
            if (types.includes('(cities)')) 
                types = types.concat(CITIES_TYPE);
            if (types.includes('(regions)')) 
                types = types.concat(REGIONS_TYPE);
            // eslint-disable-next-line no-restricted-syntax
            for (const r of results) {
                // eslint-disable-next-line no-restricted-syntax
                for (const t of r.types) {
                    if (types.includes(t)) {
                        output.push(r);
                        break;
                    }
                }
            }
            return output;
        },
        /**
         *
         * @param {String} currentType
         * @param {String} incomingType
         */
        compareAddressTypeRank(currentType, incomingType) {
            if (!currentType) 
                return incomingType;
            if (!incomingType) 
                return currentType;

            if (ADDRESS_COMPONENTS_RANK[incomingType] < ADDRESS_COMPONENTS_RANK[currentType]) 
                return incomingType;
            return currentType;
        }
    }
};
</script>

<style lang="scss" scoped>
.coordinates-container {
    .coordinate-address {
        display: block;
    }
}

.address-box {
    width: 100%;
    display: block;
}

.address-has-coordinates {
    display: inline-block;
    width: calc(100% - 38px);
}

.coordinate-toggle-button {
    display: inline-block;
    vertical-align: middle;

    .md-button {
        // background-color: #2b93ff  !important;
        height: 30px;
        width: 30px;
        min-width: 30px;
        margin-right: 0;
        ::v-deep i {
            color: #2b93ff !important;
        }
        background-color: transparent !important;
    }
    .md-button:active,
    .md-button:focus {
        background-color: transparent !important;
        ::v-deep i {
            color: #2b93ff !important;
        }
    }
    .md-button:hover {
        background: none !important;
        background-color: #2b93ff !important;
        ::v-deep i {
            color: #fff !important;
        }
    }
}
</style>
