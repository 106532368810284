var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "main-content" }, [
      _c("div", { staticClass: "getting-started-text" }, [
        _vm._m(0),
        _c("p", [
          _vm._v(
            "\n                Welcome " +
              _vm._s(_vm.firstName) +
              ", watch the\n                "
          ),
          _c("span", [_vm._v("video")]),
          _vm._v("\n                to learn how Locate2u works\n            "),
        ]),
      ]),
      _c("div", { staticClass: "getting-started-video" }, [
        _c("div", [
          _c("div", { staticClass: "main-video-container" }, [
            _c("div", { staticClass: "vid-container" }, [
              _c("iframe", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeStep == 1,
                    expression: "activeStep == 1",
                  },
                ],
                staticClass: "responsive-iframe",
                staticStyle: {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                },
                attrs: {
                  src: "https://fast.wistia.net/embed/iframe/kvddydowcb?videoFoam=true",
                  frameborder: "0",
                  webkitallowfullscreen: "",
                  mozallowfullscreen: "",
                  allowfullscreen: "",
                },
              }),
              _c("iframe", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeStep == 2,
                    expression: "activeStep == 2",
                  },
                ],
                staticClass: "responsive-iframe",
                staticStyle: {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                },
                attrs: {
                  src: "https://fast.wistia.net/embed/iframe/ts5wm9b8di?videoFoam=true",
                  frameborder: "0",
                  webkitallowfullscreen: "",
                  mozallowfullscreen: "",
                  allowfullscreen: "",
                },
              }),
              _c("iframe", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeStep == 3,
                    expression: "activeStep == 3",
                  },
                ],
                staticClass: "responsive-iframe",
                staticStyle: {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                },
                attrs: {
                  src: "https://fast.wistia.net/embed/iframe/81rqkag5oq?videoFoam=true",
                  frameborder: "0",
                  webkitallowfullscreen: "",
                  mozallowfullscreen: "",
                  allowfullscreen: "",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "step-parent-container" }, [
            _c("div", { staticClass: "step-container" }, [
              _c(
                "span",
                {
                  staticClass: "circle-step",
                  class: _vm.activeStep == 1 ? "step-active" : "",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.changeVideoContent(1)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                            1\n                        "
                  ),
                ]
              ),
              _c("div", { staticClass: "step-line" }),
              _vm._m(1),
            ]),
            _c("div", { staticClass: "step-container" }, [
              _c(
                "span",
                {
                  staticClass: "circle-step",
                  class: _vm.activeStep == 2 ? "step-active" : "",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.changeVideoContent(2)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                            2\n                        "
                  ),
                ]
              ),
              _c("div", { staticClass: "step-line" }),
              _vm._m(2),
            ]),
            _c("div", { staticClass: "step-container" }, [
              _c(
                "span",
                {
                  staticClass: "circle-step",
                  class: _vm.activeStep == 3 ? "step-active" : "",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.changeVideoContent(3)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                            3\n                        "
                  ),
                ]
              ),
              _vm._m(3),
            ]),
          ]),
          _c("div", { staticClass: "step-content-mobile" }, [
            _vm.activeStep == 1
              ? _c("div", { staticClass: "step-content" }, [
                  _c("span", [_vm._v("Step 1:")]),
                  _c("p", [
                    _vm._v(
                      "Get quickly set up with Locate2u in a few single steps."
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.activeStep == 2
              ? _c("div", { staticClass: "step-content" }, [
                  _c("span", [_vm._v("Step 2:")]),
                  _c("p", [_vm._v("Basic walk through")]),
                ])
              : _vm._e(),
            _vm.activeStep == 3
              ? _c("div", { staticClass: "step-content" }, [
                  _c("span", [_vm._v("Step 3:")]),
                  _c("p", [_vm._v("Advanced walk through")]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "content-controls" }, [
      _c("div", [
        _c("span", { staticClass: "cc-title" }, [
          _vm._v("Other videos in our Getting Started library"),
        ]),
        _c(
          "div",
          { staticClass: "video-content" },
          _vm._l(_vm.list, function (item, index) {
            return _c("div", { key: index, staticClass: "video-container" }, [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.showVideo(item.url)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "video-thumbnail" }, [
                    _c("img", { attrs: { src: item.thumbnailUrl } }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "video-text custom-text-ellipsis" },
                    [
                      _c("span", [_vm._v(_vm._s(item.title))]),
                      _vm._v(
                        "\n                            " +
                          _vm._s(item.subtitle) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]
              ),
            ])
          }),
          0
        ),
      ]),
    ]),
    _vm._m(4),
    _c("div", [
      _c("div", { staticClass: "text-center" }, [
        !_vm.isSkipped
          ? _c(
              "a",
              { staticClass: "skip-button", on: { click: _vm.skipOnboarding } },
              [
                _vm._v(
                  "\n                Skip and continue to dashboard\n            "
                ),
              ]
            )
          : _vm._e(),
        _c("p", [
          _vm._v(
            "You can return here anytime, check Getting Started under Support menu."
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _vm._v("\n                Getting started with\n                "),
      _c("img", {
        staticClass: "gs-logo",
        attrs: { src: "/img/logos/l2u-logo-dark.png" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-content" }, [
      _c("span", [_vm._v("Step 1:")]),
      _c("p", [
        _vm._v("Get quickly set up with Locate2u in a few single steps."),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-content" }, [
      _c("span", [_vm._v("Step 2:")]),
      _c("p", [_vm._v("Basic walk through")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-content" }, [
      _c("span", [_vm._v("Step 3:")]),
      _c("p", [_vm._v("Advanced walk through")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "text-center", staticStyle: { "margin-top": "10px" } },
      [
        _vm._v("\n        Feel free to send your inquiries at\n        "),
        _c(
          "a",
          {
            staticStyle: { color: "#2b93ff" },
            attrs: { href: "mailto:support@locate2u.com" },
          },
          [_vm._v("support@locate2u.com")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }