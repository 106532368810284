var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "md-layout" }, [
      _vm.showDropdown
        ? _c("div", [
            _c(
              "div",
              { staticClass: "md-layout-item md-size-50" },
              [
                _c(
                  "form-group",
                  { attrs: { label: "Select an existing person" } },
                  [
                    _c(
                      "md-select",
                      {
                        on: {
                          "md-selected": function ($event) {
                            return _vm.getExistingDetails(
                              _vm.data.selectedPersonnel,
                              _vm.index
                            )
                          },
                        },
                        model: {
                          value: _vm.data.selectedPersonnel,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "selectedPersonnel", $$v)
                          },
                          expression: "data.selectedPersonnel",
                        },
                      },
                      [
                        _c("md-option", { attrs: { value: "0" } }, [
                          _vm._v(
                            "\n                            Create New\n                        "
                          ),
                        ]),
                        _vm._l(_vm.dropdownList, function (p) {
                          return _c(
                            "md-option",
                            {
                              key: p.assetId,
                              attrs: {
                                value: p.assetId,
                                disabled: _vm.selectedAssetList.includes(
                                  p.assetId
                                ),
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(p.name) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.listLength > 1
              ? _c(
                  "div",
                  { staticClass: "md-layout-item md-size-50" },
                  [
                    _c(
                      "md-button",
                      {
                        staticClass:
                          "md-icon-button md-dense md-raised md-primary pull-right",
                        on: {
                          click: function ($event) {
                            return _vm.removePersonnel(_vm.index)
                          },
                        },
                      },
                      [_c("md-icon", [_vm._v("remove")])],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._m(0),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-100" },
        [
          _c(
            "form-group",
            {
              attrs: {
                name: "$each." + _vm.index + ".name",
                label: "Asset Name*",
              },
            },
            [
              _c("md-input", {
                attrs: { disabled: _vm.data.isDisabled },
                model: {
                  value: _vm.data.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "name", $$v)
                  },
                  expression: "data.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c(
            "form-group",
            {
              attrs: {
                name: "$each." + _vm.index + ".firstName",
                label: "First Name",
              },
            },
            [
              _c("md-input", {
                directives: [{ name: "focus", rawName: "v-focus" }],
                attrs: { disabled: _vm.data.isDisabled },
                on: {
                  input: function ($event) {
                    return _vm.setAssetName(_vm.data)
                  },
                },
                model: {
                  value: _vm.data.firstName,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "firstName", $$v)
                  },
                  expression: "data.firstName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c(
            "form-group",
            { attrs: { name: "lastName", label: "Last Name" } },
            [
              _c("md-input", {
                attrs: { disabled: _vm.data.isDisabled },
                model: {
                  value: _vm.data.lastName,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "lastName", $$v)
                  },
                  expression: "data.lastName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c(
            "form-group",
            {
              attrs: {
                name: "$each." + _vm.index + ".email",
                label: "Email Address",
              },
            },
            [
              _c("md-input", {
                attrs: { disabled: _vm.data.isDisabled },
                model: {
                  value: _vm.data.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "email", $$v)
                  },
                  expression: "data.email",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c(
            "form-group",
            { attrs: { name: "phone", label: "Phone" } },
            [
              _c("md-input", {
                attrs: { disabled: _vm.data.isDisabled },
                model: {
                  value: _vm.data.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "phone", $$v)
                  },
                  expression: "data.phone",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-50" },
        [
          _c(
            "form-group",
            { attrs: { name: "employeeNumber", label: "Employee Number" } },
            [
              _c("md-input", {
                attrs: { disabled: _vm.data.isDisabled },
                model: {
                  value: _vm.data.employeeNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "employeeNumber", $$v)
                  },
                  expression: "data.employeeNumber",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.assetCustomFieldList !== null
        ? _c(
            "div",
            { staticClass: "md-layout-item md-size-100" },
            _vm._l(_vm.assetCustomFieldList, function (customField, i) {
              return _c("custom-field-inputs", {
                key: i,
                attrs: {
                  "custom-field-definition": customField,
                  "initial-value": _vm.data[customField.label],
                },
                on: { changed: _vm.handleCustomFieldChanged },
              })
            }),
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "md-layout-item md-size-100" }, [
      _c("h5", [_vm._v("Personnel Information:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }