<template>
    <md-field :class="{ 'md-invalid': hasErrors }">
        <label>{{ label }}</label>
        <slot :touch="() => preferredValidator.$touch()" />
        <span v-if="hasErrors" class="md-error">{{ firstErrorMessage }}</span>
    </md-field>
</template>
<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
    extends: singleErrorExtractorMixin
};
</script>

<style lang="scss" scoped>
.md-error {
    bottom: -10px !important;
}
</style>
