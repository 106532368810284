<template>
    <div class="modal-container">
        <div class="modal-header">
            Import Skills
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.form" class="form-wrapper">
                <form-group label="Skills" name="skills">
                    <md-textarea v-model="form.skills" />
                </form-group>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="importSkills">Import</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'SkillImportModal',
    mixins: [GeneralMixin],
    props: {
        teamId: {
            type: Number,
            default: 0
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            form: {
                skills: ''
            }
        };
    },
    methods: {
        async importSkills() {
            this.$v.form.$touch();
            if (this.$v.form.$invalid) 
                return;

            this.$_handleLoaderState(true, 'IMPORTING...');
            const skillImport = this.form.skills.split('\n');
            const payload = {
                method: 'post',
                data: skillImport
            };
            const api = `/api/teams/skills/import`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Skills imported!',
                    type: 'success'
                });
            } catch (e) {
                const message = 'Cannot import skills.';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
            this.resolve(this.form);
        }
    },
    validations: {
        form: {
            skills: {
                required_skill: required // required,
            }
        }
    }
};
</script>

<style scoped>
::v-deep .md-error {
    padding-top: 15px !important;
}
</style>
