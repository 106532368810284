<template>
    <form-group name="teamMember" label="Team Member" class="team-member-dropdown-group">
        <md-input v-model="selectedMemberId" type="hidden" />
        <vue-select
            :reduce="(item) => item.memberOrCarrierId"
            label="fullName"
            :options="filteredTeamMemberOptions"
            v-model="selectedMemberId"
            :searchable="$root.isDesktop"
            :selectable="(option) => option.selectable"
            :dropdown-overlay="false"
        >
            <template v-slot:option="option">
                <span v-if="option.selectable">{{ option.fullName }}</span>
                <hr v-else />
            </template>
        </vue-select>
    </form-group>
</template>
<script>
import { computed, getCurrentInstance, ref, onMounted, watch } from '@vue/composition-api';

export default {
    name: 'TeamMemberOptions',
    props: {
        selected: {
            default: null,
            type: String
        },
        teamRegionIdFilter: {
            type: Number,
            default: null
        },
        strictTeamRegionFilter: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
        const selectedMemberId = ref(null);

        const vm = getCurrentInstance();
        const teamMembers = ref([]);

        onMounted(async () => {
            teamMembers.value = await vm.proxy.$root.$store.dispatch('team/FETCH_TEAM_MEMBERS');
            selectedMemberId.value = props.selected;
        });

        watch(
            () => selectedMemberId.value,
            (newVal) => {
                const model = teamMembers.value.find((member) => member.publicUserId === newVal);
                context.emit('selectedOption', newVal, model);
            }
        );

        const filteredTeamMemberOptions = computed(() => {
            const members = teamMembers.value
                .filter((member) => {
                    if (props.teamRegionIdFilter === 0 || props.teamRegionIdFilter === null) 
                        return true;
                    if (member.teamRegionId === null) 
                        return true;
                    return member.teamRegionId === props.teamRegionIdFilter;
                })
                .map((member) => {
                    return {
                        memberOrCarrierId: member.publicUserId,
                        fullName: member.fullName,
                        selectable: true,
                        teamRegionId: member.teamRegionId
                    };
                });
            return members;
        });

        watch(filteredTeamMemberOptions, (newValue) => {
            if (newValue.length === 0) {
                selectedMemberId.value = null;
            }
            const selectedMember = newValue.find((member) => member.memberOrCarrierId === selectedMemberId.value);
            if (!selectedMember) {
                selectedMemberId.value = null;
            }
        });

        return {
            selectedMemberId,
            filteredTeamMemberOptions
        };
    }
};
</script>
