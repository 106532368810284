var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "md-card-content",
        [
          _c("md-table", {
            staticClass: "table-hover",
            attrs: { value: _vm.reportData },
            scopedSlots: _vm._u([
              {
                key: "md-table-row",
                fn: function (ref) {
                  var item = ref.item
                  return _c(
                    "md-table-row",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.viewAddress(item)
                        },
                      },
                    },
                    [
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Date/Time" } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("dateTimeFormat")(
                                  _vm.displayDateTime(
                                    item.dateTime,
                                    item.utcOffset
                                  )
                                )
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Address", "md-sort": "" } },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "address",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.viewAddress(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.displayAddressOrCoord(item)) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("md-table-cell", { attrs: { "md-label": "Type" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.type) +
                            "\n                "
                        ),
                      ]),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }