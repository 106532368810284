<template>
    <div class="config--container">
        <div class="config-button--container">
            <md-button
                :disabled="disableClick"
                class="md-icon-button md-dense md-raised md-primary"
                @click="openRateConfig"
                title="Config Settings"
            >
                <md-icon class="config">
                    settings
                </md-icon>
            </md-button>
        </div>
    </div>
</template>

<script>
import RateConfigurationModal from './RateConfigurationModal';

export default {
    name: 'RateConfiguration',
    props: {
        customerId: {
            type: Number,
            default: null
        },
        driverUserId: {
            type: Number,
            default: null
        },
        customerName: {
            type: String,
            default: null
        },
        driverName: {
            type: String,
            default: null
        },
        rateConfigurationRule: {
            type: Object,
            default: () => {}
        },
        disableClick: {
            type: Boolean,
            default: false
        },
        rateGroupId: {
            type: Number,
            default: null
        },
        rateGroupName: {
            type: String,
            default: null
        }
    },
    methods: {
        openRateConfig() {
            this.$modal
                .show(RateConfigurationModal, {
                    customerId: this.customerId,
                    driverUserId: this.driverUserId,
                    customerName: this.customerName,
                    driverName: this.driverName,
                    rateConfigurationRule: this.rateConfigurationRule,
                    rateGroupId: this.rateGroupId,
                    rateGroupName: this.rateGroupName
                })
                .then(({ record }) => {
                    this.$emit('fetchRateConfig');
                    this.$modal.hide();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.config--container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .config-button--container {
        margin: -25px !important;
        display: inline-block;
        font-size: 30px !important;
    }

    .md-button {
        min-width: 40px !important;
        min-height: 40px !important;
    }
}

.config--container ::v-deep .md-button .config {
    font-size: 27px !important;
}
</style>
