var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-button",
    {
      staticClass: "md-just-icon md-round",
      class: _vm.emailSentDate != null ? "md-success" : "md-primary",
      attrs: { title: "Send invoice via email" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.sendEmail()
        },
      },
    },
    [
      _c("md-icon", [
        _vm._v(_vm._s(_vm.emailSentDate != null ? "mark_email_read" : "email")),
      ]),
      _vm.emailSentDate != null
        ? _c("md-tooltip", { attrs: { "md-direction": "left" } }, [
            _vm._v(
              "\n        Sent on: " +
                _vm._s(
                  _vm._f("dateTimeFormat")(
                    _vm.emailSentDate,
                    _vm.DATE_TYPES.standardDate
                  )
                ) +
                "\n    "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }