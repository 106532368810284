<script>
import { ref, inject, onMounted, watch } from '@vue/composition-api';

export default {
    name: 'MapPopup',
    props: {
        identifier: {
            type: String,
            required: true
        },
        options: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    setup(props) {
        const popupRef = ref(null);
        const mapEngine = inject('mapEngine');
        const currentMarkerInstance = inject('currentMarkerInstance');

        onMounted(() => {
            if (currentMarkerInstance.value === null) 
                return;
            popupRef.value = mapEngine.addPopup(props.options, currentMarkerInstance.value);
        });

        watch(
            () => currentMarkerInstance.value,
            (newVal, oldVal) => {
                if (newVal === null) {
                    popupRef.value = null;
                    return;
                }
                popupRef.value = mapEngine.addPopup(props.options, currentMarkerInstance.value);
            }
        );
    }
};
</script>
<template>
    <!-- Render an element to satisfy lint rule vue/valid-template-root -->
    <span />
</template>
