<template>
    <div v-if="hasTeam" class="cf-container">
        <div class="cf-action-container">
            <md-button
                title="Add New Band"
                class="md-primary md-just-icon md-round add-geofence-event"
                @click.stop="addNewBand()"
            >
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div v-if="offerSettingModel && offerSettingModel.bands">
            <md-table class="context-menu-support custom-paginated-table">
                <md-table-row>
                    <md-table-head>Order</md-table-head>
                    <md-table-head>Band Name</md-table-head>
                    <md-table-head>Min Proximity</md-table-head>
                    <md-table-head>Max Proximity</md-table-head>
                    <md-table-head>Sending Mode</md-table-head>
                    <md-table-head>Drip Feed Delay</md-table-head>
                    <md-table-head>Next Band Delay</md-table-head>
                    <md-table-head class="end">Actions</md-table-head>
                </md-table-row>
                <md-table-row v-for="item in offerSettingModel.bands" :key="item.id">
                    <md-table-cell>{{ item.order }}</md-table-cell>
                    <md-table-cell>{{ item.name }}</md-table-cell>
                    <md-table-cell>{{ `${item.minProximity} ${item.minProximityUnit} ` }}</md-table-cell>
                    <md-table-cell>{{ `${item.maxProximity} ${item.maxProximityUnit} ` }}</md-table-cell>
                    <md-table-cell>{{ item.sendingMode }}</md-table-cell>
                    <md-table-cell>{{ item.dripFeedDelay | timeSpanFormat }}</md-table-cell>
                    <md-table-cell>{{ item.nextBandDelay | timeSpanFormat }}</md-table-cell>
                    <md-table-cell align="end" class="action-buttons">
                        <md-button
                            title="Update setting"
                            class="md-warning md-just-icon md-round"
                            @click.stop="handleUpdate(item.id)"
                            :data-test-id="`btn-update-${item.id}`"
                        >
                            <md-icon>edit</md-icon>
                        </md-button>
                        <md-button
                            title="Delete setting"
                            class="md-danger md-just-icon md-round"
                            @click.stop="handleDelete(item.id)"
                            :data-test-id="`btn-delete-${item.id}`"
                        >
                            <md-icon>delete</md-icon>
                        </md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
        <div v-else>
            <p class="no-result-message">No actions found.</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { JOB_OFFER_BAND_DEFAULTS } from '@/utils/defaults';
import CreateJobOfferBandModal from './CreateJobOfferBandModal';

export default {
    components: {},
    props: {
        offerSetting: {
            type: Object,
            default: () => null
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {
            offerSettingModel: null
        };
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam',
            speedUnits: 'user/speedUnits'
        })
    },
    watch: {
        offerSetting() {
            this.refreshModel();
        }
    },
    methods: {
        async addNewBand() {
            const band = {
                ...JOB_OFFER_BAND_DEFAULTS()
            };

            if (this.speedUnits === 'mph') {
                band.minProximityUnit = 'mi';
                band.maxProximityUnit = 'mi';
            }

            this.$modal
                .show(CreateJobOfferBandModal, {
                    offerBand: band,
                    offerSetting: this.offerSetting
                })
                .then(({ result, data }) => {
                    if (result === 'ok') {
                        this.offerSettingModel.bands = [...this.offerSettingModel.bands, { ...data }];

                        this.$modal.hide();
                    }
                });
        },
        async handleUpdate(bandId) {
            const band = this.offerSettingModel.bands.find((x) => x.id === bandId);
            this.$modal
                .show(CreateJobOfferBandModal, {
                    offerBand: band,
                    offerSetting: this.offerSettingModel,
                    isUpdate: true
                })
                .then(({ result, data }) => {
                    if (result === 'ok') {
                        const currentBands = [...this.offerSettingModel.bands];
                        const bandIndex = currentBands.findIndex((x) => x.id === bandId);
                        currentBands[bandIndex] = { ...data };
                        this.$set(this.offerSettingModel, 'bands', currentBands);

                        this.$modal.hide();
                    }
                });
        },
        async handleDelete(bandId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Band',
                    body: 'Are you sure you want to delete this band?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        await this.confirmDelete(bandId);
                    }
                });
        },
        async confirmDelete(bandId) {
            this.$_handleLoaderState(true, 'DELETING...');

            try {
                const api = `/api/teams/settings/job-offers/bands/${bandId}?teamRegionId=${
                    this.offerSettingModel.teamRegionId
                }`;
                const payload = {
                    method: 'delete'
                };

                await handleRequests(api, payload);
                const currentBands = [...this.offerSettingModel.bands];
                const bandIndex = currentBands.findIndex((x) => x.id === bandId);
                currentBands.splice(bandIndex, 1);
                this.$set(this.offerSettingModel, 'bands', currentBands);

                this.$notify({
                    message: 'Band deleted.',
                    type: 'success'
                });
            } catch (e) {
                const message = 'Could not remove band.';
                showErrorMessage(this, message, e);
            } finally {
                this.$_handleLoaderState(false);
            }
        },
        refreshModel() {
            if (this.offerSetting) {
                this.offerSettingModel = { ...this.offerSetting };
            } else {
                this.offerSettingModel = null;
            }
        }
    },
    mounted() {
        this.refreshModel();
    }
};
</script>

<style lang="scss" scoped>
.md-table-head.end {
    text-align: right;
}
::v-deep .md-table-cell.action-buttons {
    padding-right: 30px;
    text-align: right;
}
</style>
