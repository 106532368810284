var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-container",
      class: _vm.$root.isMobileOnly ? "modal-dialog-full-width" : "",
    },
    [
      _c(
        "div",
        { staticClass: "modal-header" },
        [
          _vm.isFilterSelection
            ? _c("h4", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.filterTitle)),
              ])
            : !_vm.isBulk
            ? _c("h4", { staticClass: "modal-title" }, [
                _vm._v("Assign Team Member Stop " + _vm._s(_vm.stopRef)),
              ])
            : _c("h4", { staticClass: "modal-title" }, [
                _vm._v("Assign Stops"),
              ]),
          _c(
            "md-button",
            {
              staticClass:
                "md-simple md-just-icon md-round modal-default-button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$modal.hide($event)
                },
              },
            },
            [_c("md-icon", [_vm._v("clear")])],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "modal-body",
          class: _vm.$root.isMobileOnly ? "modal-content-full-width" : "",
        },
        [
          !_vm.$root.isMobileOnly
            ? _c(
                "form-wrapper",
                {
                  staticClass: "form-wrapper",
                  attrs: { validator: _vm.$v.createStop },
                },
                [
                  _c("team-region-member-controls", {
                    attrs: {
                      "team-region-id": _vm.teamRegionId,
                      "public-user-id": _vm.form.assignedUser,
                      "carrier-team-id": _vm.form.assignedCarrierTeamId,
                      mode: "update",
                      "show-team-region": false,
                      "strict-team-region-filter": false,
                      "show-carriers": false,
                    },
                    on: { onChanged: _vm.handleTeamRegionMemberChanged },
                  }),
                ],
                1
              )
            : _c(
                "div",
                _vm._l(_vm.teamMemberOptions, function (member) {
                  return _c(
                    "div",
                    {
                      key: member.publicUserId,
                      staticClass: "member-item",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.assignStopMobile(member.publicUserId)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(member.fullName))]),
                      _vm.form.assignedUser === member.publicUserId
                        ? _c(
                            "span",
                            { staticClass: "check-mark" },
                            [_c("md-icon", [_vm._v("check_circle")])],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
        ],
        1
      ),
      !_vm.$root.isMobileOnly
        ? _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-primary dialog-button",
                  on: { click: _vm.assignStop },
                },
                [_vm._v("Assign")]
              ),
              _c(
                "md-button",
                {
                  staticClass: "md-default dialog-button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$modal.hide($event)
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }