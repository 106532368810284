<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Update Name</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.data" class="form-wrapper">
                <form-group name="appName" label="App Name">
                    <md-input v-model="data.appName" />
                </form-group>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validateName">
                Update
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">
                Cancel
            </md-button>
        </div>
    </div>
</template>
<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';
// eslint-disable-next-line import/no-dynamic-require
const { config } = require(`../../config/config.${process.env.NODE_ENV}`);

export default {
    name: 'AppNameModal',
    mixins: [GeneralMixin],
    props: {
        data: {
            type: Object,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    validations: {
        data: {
            appName: {
                required
            }
        }
    },
    methods: {
        validateName() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.save();
            }
        },
        save() {
            this.$_handleLoaderState(true, 'RENAMING...');
            const payload = {
                method: 'post',
                data: this.data
            };

            const api = `${config.authority}api/apps/${this.data.appId}/rename`;
            handleRequests(api, payload).then(
                (response) => {
                    const { data } = response;
                    this.$notify({
                        message: `Application renamed!`,
                        type: 'success'
                    });
                    this.$v.$reset();
                    this.resolve(data);
                },
                (error) => {
                    this.$_handleLoaderState(false);
                    const message = 'Error in renaming name.';
                    showErrorMessage(this, message, error);
                }
            );
        }
    }
};
</script>
