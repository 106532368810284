var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c("div", { staticClass: "custom-toolbar" }, [
          _c(
            "div",
            { staticClass: "custom-toolbar-start" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "Team Settings",
                    },
                  },
                },
                [_c("h3", { staticClass: "title" }, [_vm._v("Team Settings")])]
              ),
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "\n                       >    " +
                    _vm._s(
                      _vm.$t("menus.setting.failedDeliveryConfiguration")
                    ) +
                    "\n                "
                ),
              ]),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-blue" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [
                    _c("md-icon", [
                      _c("img", {
                        attrs: {
                          src: "/img/icons/failed-delivery-configuration-white-icon.svg",
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ]
            ),
            _c("md-card-content", [
              _c("div", { staticClass: "custom-toolbar" }, [
                _c("div", { staticClass: "custom-toolbar-end" }, [
                  _c(
                    "div",
                    { staticClass: "header-button-container" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-button md-primary header-button",
                          on: { click: _vm.saveFailedDeliveryConfiguration },
                        },
                        [
                          _vm._v(
                            "\n                                Save\n                            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "cf-container" },
                [
                  _c(
                    "md-table",
                    { staticClass: "custom-paginated-table" },
                    [
                      _c(
                        "md-table-row",
                        { attrs: { slot: "header" }, slot: "header" },
                        [
                          _c("md-table-head", [_vm._v("Type")]),
                          _c("md-table-head", [_vm._v("Stops/Shipments")]),
                          _c("md-table-head", [_vm._v("Include in PDF")]),
                          _c("md-table-head", { staticClass: "actions" }, [
                            _vm._v("Actions"),
                          ]),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.failedDeliveryConfiguration,
                        function (item, index) {
                          return _c(
                            "md-table-row",
                            { key: index },
                            [
                              _c("md-table-cell", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.type) +
                                    "\n                            "
                                ),
                              ]),
                              _c("md-table-cell", [
                                _c(
                                  "div",
                                  { staticClass: "select-tooltip-container" },
                                  [
                                    _c(
                                      "form-group",
                                      { staticClass: "pod-dropdown" },
                                      [
                                        _c(
                                          "md-select",
                                          {
                                            attrs: {
                                              disabled:
                                                item.type === "Reasons" &&
                                                item.stopShipmentRequirement ===
                                                  "Always"
                                                  ? true
                                                  : false,
                                            },
                                            model: {
                                              value:
                                                item.stopShipmentRequirement,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "stopShipmentRequirement",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.stopShipmentRequirement",
                                            },
                                          },
                                          _vm._l(
                                            _vm.stopShipmentRequirementOptions,
                                            function (option) {
                                              return _c(
                                                "md-option",
                                                {
                                                  key: option,
                                                  attrs: {
                                                    disabled:
                                                      item.type === "Reasons" &&
                                                      option !== "Always",
                                                    value: option,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(option) +
                                                      "\n                                            "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "md-table-cell",
                                [
                                  _c(
                                    "form-group",
                                    { staticClass: "pod-checkbox" },
                                    [
                                      _c("md-checkbox", {
                                        model: {
                                          value: item.includeInPodPdf,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "includeInPodPdf",
                                              $$v
                                            )
                                          },
                                          expression: "item.includeInPodPdf",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "md-table-cell",
                                [
                                  item.type === "Reasons"
                                    ? _c(
                                        "md-button",
                                        {
                                          staticClass:
                                            "md-warning md-just-icon md-round",
                                          attrs: {
                                            title: "edit configuration",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEditConfiguration(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [_c("md-icon", [_vm._v("edit")])],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _vm.failedDeliveryConfiguration.length == 0
                    ? _c("div", { staticClass: "text-center" }, [
                        _c("p", { staticClass: "no-result-message" }, [
                          _vm._v(
                            "This setting is currently not yet available."
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }