/* eslint-disable no-restricted-properties */
import GoogleMapsLoader from 'google-maps';

export const MapOverviewMixin = {
    methods: {
        createPolygonGetBoundsPrototype() {
            google.maps.Polygon.prototype.getBounds = function getBounds() {
                const bounds = new google.maps.LatLngBounds();
                const paths = this.getPaths();
                let path;
                for (let i = 0; i < paths.getLength(); i++) {
                    path = paths.getAt(i);
                    for (let ii = 0; ii < path.getLength(); ii++) {
                        bounds.extend(path.getAt(ii));
                    }
                }
                return bounds;
            };
        },
        async $_map_loadGoogleMap() {
            GoogleMapsLoader.KEY = process.env.VUE_APP_GOOGLE_API_KEY;
            GoogleMapsLoader.LIBRARIES = ['geometry', 'places', 'drawing'];
            GoogleMapsLoader.VERSION = '3.53';

            return new Promise((resolve) => {
                GoogleMapsLoader.load(() => {
                    resolve(google);
                    this.createPolygonGetBoundsPrototype();
                });
            });
        },
        $_map_createDriverMarker({ latlng, map, html }) {
            /*
                grabbed from: https://blackatlascreative.com/blog/custom-clickable-google-map-markers-with-images/
            */

            // eslint-disable-next-line no-unused-vars
            class HTMLMapMarker extends google.maps.OverlayView {
                constructor({ latlng, map, html }) {
                    super();
                    this.latlng = latlng;
                    this.html = html;
                    this.setMap(map);
                }

                // Create the div with content and add a listener for click events
                createDiv() {
                    this.div = document.createElement('div');
                    this.div.style.position = 'absolute';
                    if (this.html) {
                        this.div.innerHTML = this.html;
                    }
                    google.maps.event.addDomListener(this.div, 'click', (event) => {
                        google.maps.event.trigger(this, 'click');
                    });
                }

                // Append to the overlay layer
                // Appending to both overlayLayer and overlayMouseTarget which should allow this to be clickable
                appendDivToOverlay() {
                    const panes = this.getPanes();
                    panes.overlayLayer.appendChild(this.div);
                    panes.overlayMouseTarget.appendChild(this.div);
                }

                // Position the div according to the coordinates
                positionDiv() {
                    const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
                    if (point) {
                        this.div.style.left = `${point.x}px`;
                        this.div.style.top = `${point.y}px`;
                    }
                }

                // Create the div and append to map
                draw() {
                    if (!this.div) {
                        this.createDiv();
                        this.appendDivToOverlay();
                    }
                    this.positionDiv();
                }

                // Remove this from map
                remove() {
                    if (this.div) {
                        this.div.parentNode.removeChild(this.div);
                        this.div = null;
                    }
                }

                // Return lat and long object
                getPosition() {
                    return this.latlng;
                }

                // Set new position
                setPosition(newPosition) {
                    this.latlng = newPosition;
                    this.positionDiv();
                }
            }
            return new HTMLMapMarker({ latlng, map, html });
        },
        $_map_drawingManager() {
            const drawingOptions = {
                fillColor: 'transparent',
                fillOpacity: 0.5,
                strokeWeight: 1,
                strokeColor: '#808080',
                clickable: false,
                editable: true,
                zIndex: 1
            };

            const drawingManager = new google.maps.drawing.DrawingManager({
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT,
                    drawingModes: [google.maps.drawing.OverlayType.CIRCLE, google.maps.drawing.OverlayType.POLYGON]
                },
                circleOptions: drawingOptions,
                polygonOptions: drawingOptions
            });
            return drawingManager;
        },
        $_map_setMarkerIconColor(marker, iconColor) {
            const icon = {
                path: google.maps.SymbolPath.CIRCLE,
                strokeColor: 'black',
                fillColor: iconColor,
                strokeOpacity: 1,
                fillOpacity: 1,
                strokeWeight: 1,
                scale: 4
            };
            marker.setIcon(icon);
        },
        $_map_computeDistance(start, end) {
            return google.maps.geometry.spherical.computeDistanceBetween(
                new google.maps.LatLng(start.lat(), start.lng()),
                new google.maps.LatLng(end.lat(), end.lng())
            );
        },
        $_map_containsLocation(marker, polygon) {
            return google.maps.geometry.poly.containsLocation(marker.getPosition(), polygon);
        },
        $_map_polygon_containsLocation(location, geometry) {
            if (location) {
                const poly = turf.polygon(geometry.coordinates);
                const markerPoint = turf.point([location.longitude, location.latitude]);
                const ins = turf.booleanPointInPolygon(markerPoint, poly);
                return ins;
            }
            return false;
        },
        $_map_callMapFunction(obj, method, params) {
            return obj[method](...params);
        },
        $_map_closeStreetView(shouldHandlePinMarkers = true) {
            this.panorama.setVisible(false);
            this.isStreetView = false;
            if (shouldHandlePinMarkers) 
                this.handlePinMarkers(this.pinnedUser);
        },
        $_map_createMap(element, lat, lng, mapOptions = {}) {
            const center = new google.maps.LatLng(lat, lng);

            const obj = {
                center,
                drawingControl: true,
                streetViewControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_TOP
                },
                fullscreenControl: false,
                zoom: 6,
                zoomControl: window.matchMedia('(min-width: 767px)').matches,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_CENTER
                }
            };

            const options = Object.assign(obj, mapOptions);

            return new google.maps.Map(element, options);
        },
        $_map_handleListener(map, listener, callback) {
            map.addListener(listener, callback);
        },
        $_map_createBounds() {
            return new google.maps.LatLngBounds();
        },
        $_map_createPanorama(map, element = null) {
            if (element) {
                return new google.maps.StreetViewPanorama(element);
            }
            return map.getStreetView();
        },
        $_map_setPanoramaOptions(panorama, options, pov) {
            panorama.setPov(pov);
            panorama.setOptions(options);
        },
        $_map_handlePanorama(panorama, lat, lng, shouldBeVisible) {
            panorama.setPosition({ lat, lng });
            panorama.setVisible(shouldBeVisible);
        },
        $_map_getMarkerOptions(type, markers) {
            switch (type) {
                case 'statusHistory':
                case 'heartBeat':
                    return Object.assign(
                        {},
                        {
                            scaledSize: new google.maps.Size(22, 22),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(11, 11),
                            url: markers[type]
                        }
                    );
                case 'geofence':
                    return Object.assign(
                        {},
                        {
                            path: 'M 5 0 10 10 5 20 0 10 Z',
                            strokeColor: 'black',
                            fillColor: '#ffa500',
                            fillOpacity: 1,
                            strokeOpacity: 0.8,
                            anchor: new google.maps.Point(5, 11)
                        }
                    );
                case 'stop':
                    return Object.assign(
                        {},
                        {
                            path:
                                'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
                            fillColor: markers.color,
                            fillOpacity: 0.8,
                            strokeColor: 'black',
                            strokeOpacity: 0.8,
                            strokeWeight: 1,
                            anchor: new google.maps.Point(0, 0),
                            labelOrigin: new google.maps.Point(0, -30),
                            scale: 0.75
                        }
                    );
                case 'optimiseStop':
                    return Object.assign(
                        {},
                        {
                            scaledSize: new google.maps.Size(24, 36),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(12, 18),
                            url: markers[type]
                        }
                    );
                case 'driver':
                    return Object.assign(
                        {},
                        {
                            scaledSize: new google.maps.Size(48, 50),
                            origin: new google.maps.Point(0, 0),
                            // anchor should be the base of the marker + length of the pin
                            anchor: new google.maps.Point(20, 40),
                            url: markers[type]
                        }
                    );
                case 'stops':
                case 'unassignedstop':
                    return Object.assign(
                        {},
                        {
                            path: google.maps.SymbolPath.CIRCLE,
                            strokeColor: 'black',
                            fillColor: '#ffa500',
                            strokeOpacity: 1,
                            fillOpacity: 1,
                            strokeWeight: 1,
                            scale: 4
                        }
                    );
                case 'timeSeries':
                default:
                    return Object.assign(
                        {},
                        {
                            path: google.maps.SymbolPath.CIRCLE,
                            anchor: new google.maps.Point(0, 0),
                            scale: 6,
                            fillColor: markers.fillColor,
                            strokeColor: markers.strokeColor,
                            fillOpacity: 1,
                            strokeWeight: 1
                        }
                    );
            }
        },
        $_map_setMarkerIcon(marker, iconOptions) {
            const icon = this.$_map_getMarkerOptions('', iconOptions);
            const newIcon = Object.assign({}, icon, iconOptions);
            marker.setIcon(newIcon);
        },
        $_map_setMarkers(latitude, longitude, type, markers, labelText, shouldShow = true) {
            const pos = this.$_map_createLatLngPoint(latitude, longitude);
            const icon = this.$_map_getMarkerOptions(type, markers);
            let zIndex = 1;
            if (type === 'driver' || type === 'stop') 
                zIndex = 10;
            else if (type === 'statusHistory' || type === 'geofence') 
                zIndex = 5;
            const marker = new google.maps.Marker({
                position: pos,
                map: shouldShow ? this.map : null,
                icon,
                zIndex
            });
            if (labelText) {
                marker.setLabel({
                    text: labelText.toString(),
                    color: 'white',
                    fontWeight: 'bold'
                });
            }
            return marker;
        },
        $_map_calculateMarkerCoordinates(marker, map, options = {}) {
            // for getting the pixel location of the marker
            const scale = Math.pow(2, map.getZoom());
            const INFO_WINDOW_HEIGHT = options.height;
            const INFO_WINDOW_WIDTH = options.width || 132.5;
            const nw = this.$_map_createLatLngPoint(
                map
                    .getBounds()
                    .getNorthEast()
                    .lat(),
                map
                    .getBounds()
                    .getSouthWest()
                    .lng()
            );

            const worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
            const worldCoordinate = map.getProjection().fromLatLngToPoint(marker.getPosition());

            return {
                left: `${Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale - INFO_WINDOW_WIDTH)}px`,
                top: `${Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale) - INFO_WINDOW_HEIGHT}px`
            };
        },
        $_map_createPath(routeStr) {
            return google.maps.geometry.encoding.decodePath(routeStr);
        },
        $_map_distanceMetres(startLocation, endLocation) {
            return google.maps.geometry.spherical.computeDistanceBetween(
                new google.maps.LatLng(startLocation.latitude, startLocation.longitude),
                new google.maps.LatLng(endLocation.latitude, endLocation.longitude)
            );
        },
        $_map_encodeLine(routeLine) {
            let path = null;
            routeLine.forEach((seg) => {
                if (path === null) 
                    path = seg.getPath().getArray();
                else 
                    path = path.concat(seg.getPath().getArray());
            });
            return google.maps.geometry.encoding.encodePath(path);
        },
        $_map_getPath(routeLine) {
            return routeLine.getPath();
        },
        $_map_setPath(routeLine, pathToSet) {
            routeLine.setPath(pathToSet);
        },
        $_map_createRouteLine(path = [], strokeColor, options, map) {
            return new google.maps.Polyline({
                ...options,
                path,
                strokeColor,
                map
            });
        },
        $_map_createLatLngPoint(lat, lng) {
            return new google.maps.LatLng(lat, lng);
        },
        $_map_addMapElement(element, map) {
            element.setMap(map);
        },
        $_map_removeMapElement(element) {
            element.setMap(null);
        },

        $_map_createCircle(map, radius, options) {
            const circle = new google.maps.Circle({
                map,
                radius,
                ...options
            });
            return circle;
        },

        $_map_createPolygon(map, coords, options) {
            const polygon = new google.maps.Polygon({
                paths: coords,
                ...options
            });

            polygon.setMap(map);
            return polygon;
        },

        $_map_zoomToBounds(map, bounds) {
            map.fitBounds(bounds);
        },

        $_map_createGeofenceActions({ latlng, map, html }) {
            // eslint-disable-next-line no-unused-vars
            class HTMLMapMarker extends google.maps.OverlayView {
                constructor({ latlng, map, html }) {
                    super();
                    this.latlng = latlng;
                    this.html = html;
                    this.setMap(map);
                }

                // Create the div with content and add a listener for click events
                createDiv() {
                    this.div = document.createElement('div');
                    this.div.style.position = 'absolute';
                    if (this.html) {
                        this.div.innerHTML = this.html;
                    }
                }

                // Append to the overlay layer
                // Appending to both overlayLayer and overlayMouseTarget which should allow this to be clickable
                appendDivToOverlay() {
                    const panes = this.getPanes();
                    panes.overlayLayer.appendChild(this.div);
                    panes.overlayMouseTarget.appendChild(this.div);
                }

                // Position the div according to the coordinates
                positionDiv() {
                    const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
                    if (point) {
                        this.div.style.left = `${point.x}px`;
                        this.div.style.top = `${point.y}px`;
                    }
                }

                // Create the div and append to map
                draw() {
                    if (this.isDisplay) {
                        if (!this.div) {
                            this.createDiv();
                            this.appendDivToOverlay();
                        }
                        this.positionDiv();
                    }
                }

                // Remove this from map
                remove() {
                    if (this.div) {
                        this.div.parentNode.removeChild(this.div);
                        this.div = null;
                    }
                }

                // Return lat and long object
                getPosition() {
                    return this.latlng;
                }

                // Set new position
                setPosition(newPosition) {
                    this.latlng = newPosition;
                    this.positionDiv();
                }
            }
            return new HTMLMapMarker({ latlng, map, html });
        },

        $_map_createAreaActions({ latlng, map, html }) {
            // eslint-disable-next-line no-unused-vars
            class HTMLMapMarker extends google.maps.OverlayView {
                constructor({ latlng, map, html }) {
                    super();
                    this.latlng = latlng;
                    this.html = html;
                    this.setMap(map);
                }

                // Create the div with content and add a listener for click events
                createDiv() {
                    this.div = document.createElement('div');
                    this.div.style.position = 'absolute';
                    if (this.html) {
                        this.div.innerHTML = this.html;
                    }
                }

                // Append to the overlay layer
                // Appending to both overlayLayer and overlayMouseTarget which should allow this to be clickable
                appendDivToOverlay() {
                    const panes = this.getPanes();
                    panes.overlayLayer.appendChild(this.div);
                    panes.overlayMouseTarget.appendChild(this.div);
                }

                // Position the div according to the coordinates
                positionDiv() {
                    const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
                    if (point) {
                        this.div.style.left = `${point.x}px`;
                        this.div.style.top = `${point.y}px`;
                    }
                }

                // Create the div and append to map
                draw() {
                    if (this.isDisplay) {
                        if (!this.div) {
                            this.createDiv();
                            this.appendDivToOverlay();
                        }
                        this.positionDiv();
                    }
                }

                // Remove this from map
                remove() {
                    if (this.div?.parentNode) {
                        this.div.parentNode.removeChild(this.div);
                        this.div = null;
                    }
                }

                // Return lat and long object
                getPosition() {
                    return this.latlng;
                }

                // Set new position
                setPosition(newPosition) {
                    this.latlng = newPosition;
                    this.positionDiv();
                }
            }
            return new HTMLMapMarker({ latlng, map, html });
        }
    }
};
