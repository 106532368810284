var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-green" },
        [
          _c(
            "div",
            { staticClass: "card-icon" },
            [_c("md-icon", [_vm._v("miscellaneous_services")])],
            1
          ),
        ]
      ),
      _c("md-card-content", [
        !_vm.lines || _vm.lines.length === 0
          ? _c("p", { staticClass: "grayedout-text text-center" }, [
              _vm._v("\n            No services found\n        "),
            ])
          : _c(
              "div",
              [
                _c(
                  "md-table",
                  {
                    staticClass: "context-menu-support custom-paginated-table",
                  },
                  [
                    _c(
                      "md-table-row",
                      [
                        _c("md-table-head", [_vm._v("Service")]),
                        _c("md-table-head", [_vm._v("Quantity")]),
                        _vm.$root.isDesktop && !_vm.isReadOnlyUser
                          ? _c("md-table-head", [_vm._v("Actions")])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._l(_vm.lines, function (line) {
                      return _c(
                        "md-table-row",
                        { key: line.StopLines },
                        [
                          _c("md-table-cell", [
                            _vm._v(_vm._s(line.description)),
                          ]),
                          _c("md-table-cell", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(line.quantity) +
                                "\n                        "
                            ),
                            _c("span", { staticClass: "quantity-type" }, [
                              _vm._v(_vm._s(line.quantityType)),
                            ]),
                          ]),
                          _vm.$root.isDesktop && !_vm.isReadOnlyUser
                            ? _c(
                                "md-table-cell",
                                { staticClass: "action-buttons" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "action-button" },
                                    [
                                      !_vm.isReadOnlyUser
                                        ? _c(
                                            "md-button",
                                            {
                                              staticClass:
                                                "md-danger md-just-icon md-round",
                                              attrs: {
                                                title: "Delete line",
                                                disabled: _vm.isInvoiced,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handleDeleteService(
                                                    line.stopLineId
                                                  )
                                                },
                                              },
                                            },
                                            [_c("md-icon", [_vm._v("delete")])],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isInvoiced
                                        ? _c(
                                            "md-tooltip",
                                            {
                                              staticClass: "line-tooltip",
                                              attrs: { "md-direction": "top" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                You cannot delete a line when the stop is invoiced.\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
      ]),
      _c("md-card-actions", { attrs: { "md-alignment": "right" } }, [
        _c(
          "div",
          [
            !_vm.isSupportStaff
              ? _c(
                  "md-button",
                  {
                    staticClass: "md-success",
                    attrs: { disabled: _vm.isInvoiced },
                    on: {
                      click: function ($event) {
                        return _vm.handleAddLine()
                      },
                    },
                  },
                  [_vm._v("\n                Add Line\n            ")]
                )
              : _vm._e(),
            _vm.isInvoiced
              ? _c(
                  "md-tooltip",
                  {
                    staticClass: "line-tooltip",
                    attrs: { "md-direction": "left" },
                  },
                  [
                    _vm._v(
                      "\n                Not allowed to add a line when the stop is already invoiced.\n            "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }