const ICON_NAMES = {
    DRIVER: 'DRIVER',
    DESTINATION: 'DESTINATION',
    PIN_STOP: 'PIN_STOP',
    PIN_USER: 'PIN_USER',
    SHIPMENT_PICKUP: 'SHIPMENT_PICKUP',
    SHIPMENT_DROP: 'SHIPMENT_DROP'
};

export { ICON_NAMES };
