<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <div class="custom-toolbar md-layout">
                    <div class="md-layout-item custom-toolbar-start md-layout md-small-size-80 md-size-90">
                        <team-members-filter @onSelection="onTeamMemberSelection" />

                        <div class="md-layout-item">
                            <date-range-filter :is-single-date="true" @onChanged="onDateChanged" />
                        </div>
                    </div>
                    <div class="md-layout-item custom-toolbar-end">
                        <md-button
                            title="Export Stops report"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click="onExportToExcel"
                        >
                            <md-icon>get_app</md-icon>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>summarize</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div v-if="!drivesList || drivesList.length == 0">
                            <h3>Stop Report</h3>
                            <p>
                                No results to display.
                            </p>
                        </div>
                        <div v-else>
                            <div class="report-container">
                                <stop-report-details :member-list="drivesList" />
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.alert-z {
    z-index: 0 !important;
}
</style>
<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import StopReportDetails from './StopReportDetails';
import { handleRequests, downloadFile } from '../../helpers';
import { TeamMembersFilter, DateRangeFilter } from './components';

export default {
    name: 'StopReport',
    components: { StopReportDetails, TeamMembersFilter, DateRangeFilter },
    mixins: [GeneralMixin],
    data() {
        return {
            isLoading: true,
            tripDate: null,
            drivesList: [],
            userFullName: '',
            selectedTeamMember: {},
            smallAlertModalHide: false
        };
    },
    created() {
        this.getDriveDetailsList = _.debounce(this.getDriveDetailsList, 500);
    },
    methods: {
        ...mapGetters({
            user: 'user/user'
        }),

        onExportToExcel() {
            if (this.tripDate) {
                const reportDate = moment(this.tripDate).format('YYYY-MM-DD');

                this.$_handleLoaderState(true);
                const api = `/api/reports/stop-report/${moment(this.tripDate).format(
                    'YYYY-MM-DD'
                )}/export-to-excel/?userId=${this.selectedTeamMember.userId || ''}&isTracker=${
                    this.selectedTeamMember.isTracker
                }`;
                const payload = {
                    method: 'get',
                    responseType: 'blob'
                };
                const reportName = `${this.selectedTeamMember.name ||
                    this.userFullName}-StopsReport-${reportDate}.xlsx`;

                handleRequests(api, payload)
                    .then((response) => {
                        downloadFile(response.data, reportName);
                        this.$_handleLoaderState(false);
                    })
                    .catch((e) => {
                        this.$_handleLoaderState(false);
                        this.$notify({ message: e.data, type: 'danger' });
                    });
            }
        },

        getDriveDetailsList() {
            if (this.tripDate) {
                this.isLoading = true;
                this.$_handleLoaderState(true);
                const api = `/api/reports/stop-report/${moment(this.tripDate).format('YYYY-MM-DD')}/?userId=${this
                    .selectedTeamMember.userId || ''}&isTracker=${this.selectedTeamMember.isTracker}`;
                handleRequests(api)
                    .then((response) => {
                        this.isLoading = false;
                        const { data } = response.data;

                        this.drivesList = data;

                        this.$_handleLoaderState(false);
                    })
                    .catch((e) => {
                        this.drivesList = [];
                        this.$_handleLoaderState(false);
                        this.$notify({ message: e.data, type: 'danger' });
                    });
            }
        },

        async onDateChanged(val) {
            this.tripDate = val;
            this.getDriveDetailsList();
        },

        async onTeamMemberSelection(val) {
            this.selectedTeamMember = val || {};
            this.getDriveDetailsList();
        }
    },
    async mounted() {
        this.userFullName = this.user().fullName;
    }
};
</script>
