var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "pagination", class: _vm.paginationClass },
    [
      _vm.value > 1
        ? _c(
            "li",
            {
              staticClass: "page-item prev-page",
              class: { disabled: _vm.value === 1, "no-arrows": _vm.noArrows },
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  attrs: { "aria-label": "Previous" },
                  on: { click: _vm.prevPage },
                },
                [_c("md-icon", [_vm._v("chevron_left")])],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "li",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFirstPageLink,
              expression: "showFirstPageLink",
            },
          ],
          staticClass: "page-item",
        },
        [
          _c(
            "a",
            {
              staticClass: "page-link",
              on: {
                click: function ($event) {
                  return _vm.changePage(1)
                },
              },
            },
            [_vm._v(_vm._s(1))]
          ),
        ]
      ),
      _c(
        "li",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFirstPageLink,
              expression: "showFirstPageLink",
            },
          ],
          staticClass: "page-item",
        },
        [_vm._v("\n        . . .\n    ")]
      ),
      _vm._l(_vm.range(_vm.minPage, _vm.maxPage), function (item) {
        return _c(
          "li",
          {
            key: item,
            staticClass: "page-item",
            class: { active: _vm.value === item },
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                on: {
                  click: function ($event) {
                    return _vm.changePage(item)
                  },
                },
              },
              [_vm._v(_vm._s(item))]
            ),
          ]
        )
      }),
      _c(
        "li",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLastPageLink,
              expression: "showLastPageLink",
            },
          ],
          staticClass: "page-item",
        },
        [_vm._v("\n        . . .\n    ")]
      ),
      _c(
        "li",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLastPageLink,
              expression: "showLastPageLink",
            },
          ],
          staticClass: "page-item",
        },
        [
          _c(
            "a",
            {
              staticClass: "page-link",
              on: {
                click: function ($event) {
                  return _vm.changePage(_vm.totalPages)
                },
              },
            },
            [_vm._v(_vm._s(_vm.totalPages))]
          ),
        ]
      ),
      _c(
        "li",
        {
          staticClass: "page-item page-pre next-page",
          class: {
            disabled: _vm.value === _vm.totalPages,
            "no-arrows": _vm.noArrows,
          },
        },
        [
          _vm.total >= _vm.perPage
            ? _c(
                "a",
                {
                  staticClass: "page-link",
                  attrs: { "aria-label": "Next" },
                  on: { click: _vm.nextPage },
                },
                [_c("md-icon", [_vm._v("chevron_right")])],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }