<template>
    <div>
        <md-card-content>
            <md-table :value="list" class="table-hover">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Team Member/ Asset Group" md-sort>
                        {{ item.trackerId ? item.assetGroupName : item.teamMember.fullName }}
                    </md-table-cell>
                    <md-table-cell md-label="Geofence" md-sort>
                        {{ toGeofenceNameDesc(item) }}
                    </md-table-cell>
                    <md-table-cell md-label="In">
                        {{ item.inActionDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                    </md-table-cell>
                    <md-table-cell md-label="Out">
                        {{ item.outActionDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                    </md-table-cell>
                    <md-table-cell md-label="Total Minutes">
                        {{ toTime(item.totalMinutes) }}
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </md-card-content>
    </div>
</template>

<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins';

export default {
    name: 'GeofenceTimesheetReportDetails',
    components: {},
    mixins: [GeneralMixin],
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {};
    },
    methods: {
        displayDateTime(date, utcOffset) {
            if (date) 
                return moment(date).utcOffset(utcOffset);
            return '';
        },
        toTime(minutes) {
            if (!minutes) 
                return '-';

            const duration = moment.duration({ minutes: Math.ceil(minutes) });
            const formatted = moment.utc(duration.asMilliseconds()).format('HH [hr] mm [min]');

            return formatted;
        },
        toGeofenceNameDesc(item) {
            if (item.geofenceStatus) {
                return `${item.geofenceName} (${item.geofenceStatus})`;
            }
            return item.geofenceName;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-table-head-label {
    color: #4caf50 !important;
}

::v-deep .md-table-head {
    cursor: default;
}

.table-hover {
    cursor: pointer;
}
</style>
