<template>
    <div v-if="offerSetting" class="cf-container">
        <p v-html="settingParagraph"></p>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'JobOfferSettingsWorded',
    components: {},
    props: {
        offerSetting: {
            type: Object,
            default: () => null
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {};
    },
    computed: {
        settingParagraph() {
            if (!this.offerSetting) 
                return null;

            const maxRetryText = this.formatWord(this.offerSetting.maxRetry);

            let words = `Job Offer will be calculated ${maxRetryText} `;
            const retryDelayText = this.formatWord(this.$options.filters.timeSpanFormat(this.offerSetting.retryDelay));

            if (this.offerSetting.maxRetry > 1) {
                words += `times at ${retryDelayText} interval until a member accepts. `;
                words += `After ${maxRetryText} retries are finished, it will wait for another ${retryDelayText} before sending an email informing that no one accepted the job.`;
            } else {
                words += 'time. ';
                words += `It will wait for another ${retryDelayText} before sending an email informing that no one accepted the job.`;
            }

            if (this.offerSetting.matchSkills || this.offerSetting.checkLoadCapacity) {
                let memberSentence = `Team Member's `;
                let and = '';
                if (this.offerSetting.matchSkills) {
                    memberSentence += `${this.formatWord('Skills')} `;
                    and = 'and ';
                }

                if (this.offerSetting.checkLoadCapacity) {
                    memberSentence += `${and}${this.formatWord('Load Capacity')} `;
                }

                memberSentence += 'will be considered in the calculation process. ';

                words += `<p>${memberSentence}</p>`;
            }

            if (this.offerSetting.matchSkills || this.offerSetting.checkLoadCapacity) {
                if (this.offerSetting.prioritizeEmptyVehicles) {
                    words += `${this.formatWord('Prioritize Empty Vehicles')} will also be considered. `;
                }
            } else if (this.offerSetting.prioritizeEmptyVehicles) {
                words += `${this.formatWord(
                    'Prioritize Empty Vehicles'
                )} will be considered in the calculation process. `;
            }

            words += `<p>When there are no members yielding from the calculation, an email will be sent informing that no members matches the criteria.</p>`;
            words += '<p></p>';
            words += this.bandsToParagraph(this.offerSetting);

            return words;
        }
    },
    methods: {
        formatWord(word) {
            return `<b>${word}</b>`;
        },
        bandsToParagraph(offerSetting) {
            if (!offerSetting || !offerSetting.bands || !offerSetting.bands.length) 
                return '';

            let words = '';

            if (offerSetting.maxRetry > 1) {
                words += 'In each calculation retry, an ';
            } else {
                // only 1 retry
                words += 'An ';
            }

            if (offerSetting.bands.length > 1) {
                words = 'Team Members will be grouped according to bands. <br/>';

                let bandParagraphList = '';

                offerSetting.bands.forEach((band, index) => {
                    let bandParagraph = `${this.formatWord(band.name)}`;
                    bandParagraph += ` - ${this.bandToSentence(band, 'An ')}`;

                    if (index < offerSetting.bands.length - 1) {
                        const nextBandDelayText = this.formatWord(
                            this.$options.filters.timeSpanFormat(band.nextBandDelay)
                        );
                        const nextBandNameText = this.formatWord(offerSetting.bands[index + 1].name);
                        bandParagraph += `After ${nextBandDelayText}, ${nextBandNameText} will commence. `;
                    }

                    bandParagraph = `<li>${bandParagraph}</li>`;
                    bandParagraphList += bandParagraph;
                });

                words += `<ol type="1">${bandParagraphList}</ol>`;
            } else {
                // only 1 band
                const band = offerSetting.bands[0];

                words = this.bandToSentence(band, words);

                return words;
            }

            return words;
        },
        bandToSentence(band, prefixWord) {
            let words = prefixWord;
            words += `offer will be sent to all team members within ${this.formatWord(
                band.minProximity
            )} ${this.formatWord(band.minProximityUnit)} to ${this.formatWord(band.maxProximity)} ${this.formatWord(
                band.maxProximityUnit
            )} `;

            if (band.sendingMode === 'Broadcast') {
                words += 'all at the same time. ';
            } else {
                // Drip Feed
                words += `giving priority to the closest team member, at ${this.formatWord(
                    this.$options.filters.timeSpanFormat(band.dripFeedDelay)
                )} after each other. `;
            }

            return words;
        }
    }
};
</script>
