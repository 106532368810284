var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-item" },
    [
      !_vm.isReadOnlyUser
        ? _c(
            "md-button",
            {
              class: _vm.useIcon
                ? "md-warning md-just-icon md-round"
                : "" + _vm.className,
              attrs: { title: "Edit item details", disabled: _vm.isDisabled },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleUpdateItem($event)
                },
              },
            },
            [
              _vm.useIcon
                ? _c("md-icon", [_vm._v("edit")])
                : _c("span", [_vm._v("Edit")]),
            ],
            1
          )
        : _vm._e(),
      _vm.isInvoiced
        ? _c(
            "md-tooltip",
            { staticClass: "line-tooltip", attrs: { "md-direction": "left" } },
            [
              _vm._v(
                "\n        You cannot edit an item when the stop is invoiced.\n    "
              ),
            ]
          )
        : _vm.isDelivered
        ? _c(
            "md-tooltip",
            { staticClass: "line-tooltip", attrs: { "md-direction": "top" } },
            [_vm._v("\n        You cannot edit a delivered item.\n    ")]
          )
        : !_vm.shipmentStatus &&
          _vm.stopStatus !== null &&
          _vm.stopStatus.toLowerCase() === "failed"
        ? _c(
            "md-tooltip",
            { staticClass: "line-tooltip", attrs: { "md-direction": "top" } },
            [
              _vm._v(
                "\n        You cannot edit an item when the stop is marked as Failed\n    "
              ),
            ]
          )
        : !_vm.shipmentStatus &&
          _vm.stopStatus !== null &&
          _vm.stopStatus.toLowerCase() === "complete"
        ? _c(
            "md-tooltip",
            { staticClass: "line-tooltip", attrs: { "md-direction": "top" } },
            [
              _vm._v(
                "\n        You cannot edit an item when the stop is marked as Complete\n    "
              ),
            ]
          )
        : _vm.shipmentStatus && _vm.shipmentStatus.toLowerCase() === "complete"
        ? _c(
            "md-tooltip",
            { staticClass: "line-tooltip", attrs: { "md-direction": "top" } },
            [
              _vm._v(
                "\n        You cannot edit an item when the shipment is marked as Complete\n    "
              ),
            ]
          )
        : _vm.shipmentStatus && _vm.shipmentStatus.toLowerCase() === "cancelled"
        ? _c(
            "md-tooltip",
            { staticClass: "line-tooltip", attrs: { "md-direction": "top" } },
            [
              _vm._v(
                "\n        You cannot edit an item when the shipment is marked as Cancelled\n    "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }