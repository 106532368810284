var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("team-member-invoices-overview-toolbar", {
        class: _vm.$root.isTablet ? "tablet-filter-container" : "",
        attrs: {
          "team-members": function () {
            return this$1.teamMembers
          },
          "team-region-id": _vm.filters.teamRegionId,
          "batch-filter-list": _vm.batchFilterList,
          "disable-export": !(
            (this.selectedMessage && this.selectedMessage > 0) ||
            (_vm.invoicesList && _vm.invoicesList.length > 0)
          ),
        },
        on: {
          onFilterInvoices: _vm.handleFilterOrSearch,
          onExportClicked: _vm.handleExportClick,
          onSendToAccountingPackageClicked: _vm.processInvoicesBasedOnFilter,
          onCreateInvoice: _vm.handleGenerateInvoice,
        },
      }),
      _c(
        "div",
        { staticClass: "md-layout" },
        [
          _c(
            "div",
            { staticClass: "md-layout-item" },
            [
              _c(
                "md-card",
                [
                  _c(
                    "md-card-header",
                    {
                      staticClass: "md-card-header-icon md-card-header-warning",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card-icon" },
                        [_c("md-icon", [_vm._v("receipt")])],
                        1
                      ),
                    ]
                  ),
                  _c("md-card-content", { staticClass: "body-list" }, [
                    _vm.showProgressBox &&
                    _vm.selectedTeamMemberInvoiceIds != null &&
                    _vm.selectedTeamMemberInvoiceIds.length > 0
                      ? _c("div", { staticClass: "bulk-section" }, [
                          _c("div", [
                            _vm._v(
                              "Cannot perform bulk operations while generating invoice."
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.selectedMessage &&
                    (!_vm.showProgressBox || _vm.isProgressComplete)
                      ? _c("div", { staticClass: "bulk-section" }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.selectedMessage) +
                                " invoice" +
                                _vm._s(_vm.selectedMessage > 1 ? "s" : "") +
                                " selected."
                            ),
                          ]),
                          _c(
                            "div",
                            [
                              _c("payment-status-button", {
                                attrs: {
                                  "is-bulk": true,
                                  "bulk-team-member-invoice-ids":
                                    _vm.selectedTeamMemberInvoiceIds,
                                },
                                on: {
                                  invoiceBulkStatusUpdate:
                                    _vm.handleBulkChangeStatus,
                                },
                              }),
                              _vm.hasAccountingPackage
                                ? _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-round md-just-icon md-warning",
                                      attrs: {
                                        title: "Send to Accounting Package",
                                      },
                                      on: {
                                        click:
                                          _vm.handleSendToAccountingPackageClick,
                                      },
                                    },
                                    [_c("md-icon", [_vm._v("send")])],
                                    1
                                  )
                                : _vm._e(),
                              _c("send-email-button", {
                                attrs: {
                                  "is-bulk": true,
                                  "bulk-team-member-invoice-ids":
                                    _vm.selectedTeamMemberInvoiceIds,
                                },
                                on: { handleBulkSent: _vm.handleBulkSentEmail },
                              }),
                              _c("delete-invoice-button", {
                                attrs: {
                                  "is-bulk": true,
                                  "bulk-team-member-invoice-ids":
                                    _vm.selectedTeamMemberInvoiceIds,
                                },
                                on: { invoiceBulkDelete: _vm.handleBulkDelete },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    !_vm.isListLoading
                      ? _c(
                          "div",
                          { key: _vm.updateList },
                          [
                            _c(
                              "md-table",
                              {
                                staticClass:
                                  "context-menu-support custom-paginated-table",
                              },
                              [
                                _c(
                                  "md-table-row",
                                  [
                                    _c(
                                      "md-table-head",
                                      [
                                        _c("md-checkbox", {
                                          staticClass:
                                            "shipment-checkbox checkbox-head",
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSelectAll(
                                                _vm.isSelectAll
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.isSelectAll,
                                            callback: function ($$v) {
                                              _vm.isSelectAll = $$v
                                            },
                                            expression: "isSelectAll",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("md-table-head", [
                                      _vm._v("Invoice Number"),
                                    ]),
                                    _c("md-table-head", [
                                      _vm._v("Team Member"),
                                    ]),
                                    _c("md-table-head", [
                                      _vm._v("Invoice Date"),
                                    ]),
                                    _c("md-table-head", [_vm._v("Due Date")]),
                                    _c(
                                      "md-table-head",
                                      { attrs: { "md-numeric": "" } },
                                      [_vm._v("Subtotal")]
                                    ),
                                    _c(
                                      "md-table-head",
                                      { attrs: { "md-numeric": "" } },
                                      [_vm._v("Tax")]
                                    ),
                                    _c(
                                      "md-table-head",
                                      { attrs: { "md-numeric": "" } },
                                      [_vm._v("Total Amount")]
                                    ),
                                    _c("md-table-head", [_vm._v("Status")]),
                                    _vm.$root.isDesktop && !_vm.isReadOnlyUser
                                      ? _c("md-table-head", [_vm._v("Actions")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._l(_vm.invoicesList, function (invoice) {
                                  return _c(
                                    "md-table-row",
                                    {
                                      key: invoice.teamMemberInvoiceId,
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.showSidebar(
                                            invoice.teamMemberInvoiceId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "md-table-cell",
                                        [
                                          _c("md-checkbox", {
                                            staticClass: "shipment-checkbox",
                                            attrs: {
                                              value:
                                                invoice.teamMemberInvoiceId,
                                            },
                                            model: {
                                              value:
                                                _vm.selectedTeamMemberInvoiceIds,
                                              callback: function ($$v) {
                                                _vm.selectedTeamMemberInvoiceIds =
                                                  $$v
                                              },
                                              expression:
                                                "selectedTeamMemberInvoiceIds",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "md-table-cell",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "invoice-ref",
                                              attrs: {
                                                to: {
                                                  name: "Team Member Invoice Details",
                                                  params: {
                                                    invoiceId:
                                                      invoice.teamMemberInvoiceId,
                                                  },
                                                },
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    invoice.invoiceNumber
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              invoice.teamMember.fullName
                                            ) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                invoice.invoiceDate,
                                                _vm.DATE_TYPES.standardDate
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _c("md-table-cell", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                invoice.dueDate,
                                                _vm.DATE_TYPES.standardDate
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _c(
                                        "md-table-cell",
                                        { attrs: { "md-numeric": "" } },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  invoice.subTotal,
                                                  invoice.currency
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "md-table-cell",
                                        { attrs: { "md-numeric": "" } },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  invoice.totalTax,
                                                  invoice.currency
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "md-table-cell",
                                        { attrs: { "md-numeric": "" } },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  invoice.total,
                                                  invoice.currency
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "md-table-cell",
                                        [
                                          _c("payment-status-button", {
                                            attrs: {
                                              "invoice-details": invoice,
                                            },
                                            on: {
                                              statusUpdated:
                                                _vm.onChangedInvoiceStatus,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.$root.isDesktop && !_vm.isReadOnlyUser
                                        ? _c(
                                            "md-table-cell",
                                            { staticClass: "action-buttons" },
                                            [
                                              _vm.hasAccountingPackage
                                                ? _c("sync-invoice-button", {
                                                    attrs: { invoice: invoice },
                                                  })
                                                : _vm._e(),
                                              !_vm.isReadOnlyUser
                                                ? _c("generate-pdf-button", {
                                                    attrs: {
                                                      "invoice-id":
                                                        invoice.teamMemberInvoiceId,
                                                    },
                                                  })
                                                : _vm._e(),
                                              !_vm.isReadOnlyUser
                                                ? _c("generate-excel-button", {
                                                    attrs: {
                                                      "invoice-id":
                                                        invoice.teamMemberInvoiceId,
                                                    },
                                                  })
                                                : _vm._e(),
                                              !_vm.isReadOnlyUser
                                                ? _c("send-email-button", {
                                                    attrs: {
                                                      "invoice-id":
                                                        invoice.teamMemberInvoiceId,
                                                      "email-sent-date":
                                                        invoice.lastEmailDateSent,
                                                    },
                                                    on: {
                                                      invoiceSent:
                                                        _vm.handleSentEmail,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c("delete-invoice-button", {
                                                attrs: {
                                                  "invoice-id":
                                                    invoice.teamMemberInvoiceId,
                                                },
                                                on: {
                                                  invoiceDeleted:
                                                    _vm.handleDeleteInvoice,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                            _vm.invoicesList.length == 0
                              ? _c("div", [
                                  _c(
                                    "p",
                                    { staticClass: "no-result-message" },
                                    [
                                      _vm._v(
                                        "No results matching your search/filter could be found."
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c("div", [
                          _c(
                            "div",
                            { staticClass: "loader" },
                            [
                              _c("fade-loader", {
                                attrs: { loading: true, color: "#333333" },
                              }),
                              _c("span", [_vm._v("LOADING")]),
                            ],
                            1
                          ),
                        ]),
                  ]),
                ],
                1
              ),
              _vm.total >= _vm.pagination.perPage
                ? _c(
                    "md-card-actions",
                    {
                      staticClass: "page-footer",
                      attrs: { "md-alignment": "space-between" },
                    },
                    [
                      _c("div", {}, [
                        _c("p", { staticClass: "card-category" }, [
                          _vm._v(
                            "Showing page " +
                              _vm._s(_vm.pagination.currentPage) +
                              " of " +
                              _vm._s(_vm.maxPage)
                          ),
                        ]),
                      ]),
                      _c("pagination", {
                        staticClass: "pagination-no-border pagination-success",
                        attrs: {
                          "per-page": _vm.pagination.perPage,
                          total: _vm.total,
                        },
                        on: {
                          "change-page": function ($event) {
                            return _vm.handleChangePage(
                              $event,
                              _vm.pagination.perPage
                            )
                          },
                        },
                        model: {
                          value: _vm.pagination.currentPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.pagination, "currentPage", $$v)
                          },
                          expression: "pagination.currentPage",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "slide" } },
            [
              _vm.isShowSidebar
                ? _c("team-member-invoices-sidebar", {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.toggleSidebarWindow,
                        expression: "toggleSidebarWindow",
                      },
                    ],
                    attrs: {
                      "team-member-invoice-id": _vm.teamMemberInvoiceId,
                    },
                    on: { closeModal: _vm.toggleSidebarWindow },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showProgressBox
        ? _c("div", { staticClass: "progress-container" }, [
            _c(
              "div",
              { staticClass: "button-close" },
              [
                _c(
                  "md-button",
                  {
                    staticClass: "md-default md-just-icon md-round",
                    on: { click: _vm.handleCloseProgressBox },
                  },
                  [_c("md-icon", [_vm._v("close")])],
                  1
                ),
              ],
              1
            ),
            !_vm.isProgressComplete
              ? _c("div", { staticClass: "progress-text-section" }, [
                  _vm._m(0),
                  _vm.totalInvoice == 0
                    ? _c("div", [_vm._v("Preparing invoices...")])
                    : _c("div", [
                        _vm._v(
                          _vm._s(_vm.currentProcessedInvoice) +
                            " of " +
                            _vm._s(_vm.totalInvoice) +
                            " invoices have been generated."
                        ),
                      ]),
                ])
              : _c("div", { staticClass: "progress-text-section" }, [
                  _c(
                    "div",
                    [
                      _c("md-icon", { staticClass: "green-check" }, [
                        _vm._v("check_circle"),
                      ]),
                    ],
                    1
                  ),
                  _c("div", [
                    _vm._v(
                      "\n                Process has been completed.\n            "
                    ),
                  ]),
                ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("div", { staticClass: "btn-loader route-loader" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }