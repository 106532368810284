<script>
import { ref, onMounted, onBeforeUnmount, inject, computed, watch } from '@vue/composition-api';
import useMapUtils from '@/compostables/useMapUtils';

export default {
    name: 'StartEndLocationMarker',
    setup(_, { root }) {
        const { setMarkers } = useMapUtils(root);
        const { getters } = inject('vuex-store');
        const google = inject('google');
        const map = inject('map');
        const isStreetView = inject('isStreetView');
        const bounds = inject('bounds');

        const pinnedLocationDetails = computed(() => getters['map/pinnedLocationDetails']);

        const isAssetGroup = computed(() => getters['map/isAssetGroup']);
        if (isAssetGroup.value) 
            return false;

        const pins = {
            startLocation: { optimiseStop: '/img/map/optimise-stop/start.png' },
            endLocation: { optimiseStop: '/img/map/optimise-stop/end.png' }
        };

        const startMarker = ref(null);
        const endMarker = ref(null);

        const setStartAndEndLocationMarkers = (locationDetails) => {
            const hasStartLocation = Boolean(locationDetails?.startAddress && locationDetails?.startLocation);
            const hasEndLocation = Boolean(locationDetails?.endAddress && locationDetails?.endLocation);

            if (hasStartLocation) {
                const { latitude, longitude } = locationDetails.startLocation;
                startMarker.value = setMarkers(
                    google.value,
                    map.value,
                    latitude,
                    longitude,
                    'optimiseStop',
                    pins.startLocation,
                    null
                );

                const { startAddress: address, startLocation: location } = locationDetails;

                const data = {
                    address,
                    location,
                    isStartLocation: true
                };
                startMarker.value.addListener('click', () => {
                    root.$emit('marker-clicked', { data, marker: startMarker.value, type: 'stop' });
                });

                bounds.value.extend(startMarker.value.getPosition());
            }

            if (hasEndLocation) {
                const { latitude, longitude } = locationDetails.endLocation;
                endMarker.value = setMarkers(
                    google.value,
                    map.value,
                    latitude,
                    longitude,
                    'optimiseStop',
                    pins.endLocation,
                    null
                );

                const { endAddress: address, endLocation: location } = locationDetails;

                const data = {
                    address,
                    location,
                    isEndLocation: true
                };

                endMarker.value.addListener('click', () => {
                    root.$emit('marker-clicked', { data, marker: endMarker.value, type: 'stop' });
                });

                bounds.value.extend(endMarker.value.getPosition());
            }
        };

        const clearMarkers = () => {
            if (startMarker.value) {
                startMarker.value.setMap(null);
                startMarker.value = null;
            }
            if (endMarker.value) {
                endMarker.value.setMap(null);
                endMarker.value = null;
            }
        };

        watch(pinnedLocationDetails, (newVal) => {
            clearMarkers();
            setStartAndEndLocationMarkers(newVal);
        });

        watch(isStreetView, (newVal) => {
            if (startMarker?.value) 
                startMarker.value.setMap(newVal ? null : map.value);
            if (endMarker?.value) 
                endMarker.value.setMap(newVal ? null : map.value);
        });

        onMounted(() => {
            setStartAndEndLocationMarkers(pinnedLocationDetails.value);
        });

        // remove marker before unmount
        onBeforeUnmount(() => {
            clearMarkers();
        });

        return {
            startMarker,
            endMarker
        };
    },
    render: () => null
};
</script>
