var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-group",
    {
      staticClass: "team-member-dropdown-group",
      attrs: { name: "teamMember", label: "Team Member" },
    },
    [
      _c("md-input", {
        attrs: { type: "hidden" },
        model: {
          value: _vm.selectedMemberId,
          callback: function ($$v) {
            _vm.selectedMemberId = $$v
          },
          expression: "selectedMemberId",
        },
      }),
      _c("vue-select", {
        attrs: {
          reduce: function (item) {
            return item.memberOrCarrierId
          },
          label: "fullName",
          options: _vm.filteredTeamMemberOptions,
          searchable: _vm.$root.isDesktop,
          selectable: function (option) {
            return option.selectable
          },
          "dropdown-overlay": false,
        },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function (option) {
              return [
                option.selectable
                  ? _c("span", [_vm._v(_vm._s(option.fullName))])
                  : _c("hr"),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedMemberId,
          callback: function ($$v) {
            _vm.selectedMemberId = $$v
          },
          expression: "selectedMemberId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }