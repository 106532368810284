<template>
    <div class="filter-steps--container">
        <team-region-member-filter-options
            :team-region-id="teamRegionId"
            @changed="handleTeamRegionMemberChanged"
            :clearable="false"
            :show-team-member="false"
        ></team-region-member-filter-options>
    </div>
</template>

<script>
import { TeamRegionMemberFilterOptions } from '@/components';

export default {
    name: 'AssetTrackingFilter',
    props: {
        teamRegionId: {
            type: Number,
            default: null
        }
    },
    components: { TeamRegionMemberFilterOptions },
    data() {
        return {
            selectedTeamRegionId: null
        };
    },
    methods: {
        // eslint-disable-next-line func-names
        handleFilter: _.debounce(function() {
            this.handleFilteredValue();
        }, 700),
        handleFilteredValue() {
            // use nextTick to ensure that tripDate value has already been changed.
            // this is because the @selected method of md-datepicker is asynchronous.
            this.$nextTick(() => {
                const eventArgs = {
                    teamRegionId: this.selectedTeamRegionId
                };
                this.$emit('onFilterChanged', eventArgs);
            });
        },
        handleTeamRegionMemberChanged(val) {
            this.selectedTeamRegionId = val.teamRegionId;
            this.handleFilter();
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-steps--container {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }
    .filter-step-date {
        width: 250px;
        margin-right: 0;
        min-height: initial;
    }

    .filter-button--container {
        display: inline-block;
    }
    ::v-deep .md-datepicker:after,
    ::v-deep .md-datepicker:before {
        bottom: 0;
        height: 0;
    }

    ::v-deep .md-datepicker:before,
    ::v-deep .md-datepicker:after {
        bottom: 30px;
        content: 'Trip date';
        color: #aaaaaa;
        font-weight: 400;
        font-size: 14px;
        transform: none;
        z-index: 1;
    }

    ::v-deep .md-has-value:before,
    ::v-deep .md-has-value:after {
        bottom: 0;
        height: 0;
        content: '';
    }

    ::v-deep .md-datepicker .md-input {
        position: absolute;
        z-index: 9;
    }
}
::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}
::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}
::v-deep .vs--disabled .vs__clear,
::v-deep .vs--disabled .vs__dropdown-toggle,
::v-deep .vs--disabled .vs__open-indicator,
::v-deep .vs--disabled .vs__search,
::v-deep .vs--disabled .vs__selected {
    background-color: transparent;
}

::v-deep .vs__search {
    cursor: pointer;
}
</style>
