var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "profile-container" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-60 md-xsmall-size-100 md-size-60",
        },
        [
          _c(
            "router-link",
            {
              staticClass: "custom-a",
              attrs: { to: { name: "Team Members" } },
            },
            [
              _c("i", { staticClass: "material-icons" }, [
                _vm._v("keyboard_backspace"),
              ]),
              _vm._v(
                "\n                Go back to Team Member Dashboard\n            "
              ),
            ]
          ),
          _c(
            "md-card",
            { staticClass: "md-card-profile logo-section" },
            [
              _c("md-card-content", { staticClass: "custom-content-padding" }, [
                _c(
                  "div",
                  { staticClass: "member-information field-spacing-container" },
                  [
                    _c(
                      "form-wrapper",
                      {
                        staticClass: "form-wrapper",
                        attrs: { validator: _vm.$v.form },
                      },
                      [
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "company",
                                      label: "Company Name",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      model: {
                                        value: _vm.form.company,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "company", $$v)
                                        },
                                        expression: "form.company",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "industry",
                                      label: "Industry",
                                    },
                                  },
                                  [
                                    _c("industry-drop-down", {
                                      attrs: { industry: _vm.form.industry },
                                      on: {
                                        selectedIndustry:
                                          _vm.getSelectedIndustry,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "md-layout" }, [
                            _c("div", { staticClass: "md-layout-item" }, [
                              _c(
                                "div",
                                { staticClass: "location-container" },
                                [
                                  _c(
                                    "form-group",
                                    {
                                      staticClass: "location-dropdown",
                                      attrs: {
                                        name: "businessLocation",
                                        label: "Business Location",
                                      },
                                    },
                                    [
                                      _c(
                                        "md-select",
                                        {
                                          on: {
                                            "md-selected":
                                              _vm.selectedWarehouseChanged,
                                          },
                                          model: {
                                            value: _vm.form.businessLocationId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "businessLocationId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.businessLocationId",
                                          },
                                        },
                                        _vm._l(_vm.warehouses, function (item) {
                                          return _c(
                                            "md-option",
                                            {
                                              key: item.warehouseId,
                                              attrs: {
                                                value: item.warehouseId,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                        " +
                                                  _vm._s(item.name) +
                                                  "\n                                                    "
                                              ),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "add-location-button" },
                                    [
                                      _c(
                                        "md-button",
                                        {
                                          staticClass:
                                            "md-primary md-just-icon md-round pull-right",
                                          attrs: {
                                            title:
                                              "Add new warehouse as business location",
                                          },
                                          on: {
                                            click: _vm.addWarehouseClicked,
                                          },
                                        },
                                        [_c("md-icon", [_vm._v("add")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "detail-group business-location-details",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "business-address" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedWarehouse
                                            ? _vm.selectedWarehouse.address
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "business-email" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedWarehouse
                                            ? _vm.selectedWarehouse.email
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "business-phone" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedWarehouse
                                            ? _vm.selectedWarehouse.phone
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "websiteUrl",
                                      label: "Website Url",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      model: {
                                        value: _vm.form.websiteUrl,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "websiteUrl", $$v)
                                        },
                                        expression: "form.websiteUrl",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "md-layout" }, [
                            _c("div", { staticClass: "md-layout-item" }, [
                              _c("div", { staticClass: "text-align-left" }, [
                                _vm._v(
                                  "\n                                            Do you want to always track your team members, or only while they have\n                                            active stops / tracking links?\n                                            "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "default-tracking-info" },
                                  [
                                    _c("md-icon", [_vm._v("info")]),
                                    _c(
                                      "md-tooltip",
                                      { attrs: { "md-direction": "top" } },
                                      [
                                        _vm._v(
                                          "\n                                                    Changes will only apply to newly invited users. Existing members\n                                                    won't be affected.\n                                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "defaultTrackingBehaviour",
                                      label: "Location Tracking Behaviour",
                                    },
                                  },
                                  [
                                    _c("gps-collection-dropdown", {
                                      attrs: {
                                        selected:
                                          _vm.form.defaultTrackingBehaviour,
                                      },
                                      on: {
                                        selectedOption:
                                          _vm.getSelectedTrackingBehaviour,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item md-size-50" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "etaTimeWindowWidthInMinutes",
                                      label: "ETA Time Window Width In Minutes",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      attrs: { type: "number" },
                                      model: {
                                        value:
                                          _vm.form.etaTimeWindowWidthInMinutes,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "etaTimeWindowWidthInMinutes",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "form.etaTimeWindowWidthInMinutes",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c("div", [
                      _c("label", { staticClass: "gray-text" }, [
                        _vm._v("Upload your logo here"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "upload-container",
                          on: {
                            mouseover: function ($event) {
                              _vm.showUploadButton = true
                            },
                            mouseleave: function ($event) {
                              _vm.showUploadButton = false
                            },
                          },
                        },
                        [
                          !_vm.logoPreview
                            ? _c("img", {
                                staticClass: "profile-image",
                                attrs: {
                                  src:
                                    _vm.logoPreview !== null
                                      ? _vm.logoPreview
                                      : "/img/logos/logo2-placeholder.jpg",
                                  alt: "",
                                },
                                on: { error: _vm.$_setDefaultBrokenImage },
                              })
                            : _vm._e(),
                          _vm.logoPreview
                            ? _c("img", {
                                staticClass: "profile-image",
                                attrs: { src: _vm.logoPreview, alt: "" },
                                on: { error: _vm.$_setDefaultBrokenImage },
                              })
                            : _vm._e(),
                          _vm.showUploadButton
                            ? _c("div", [
                                _c("input", {
                                  ref: "logoInput",
                                  attrs: { hidden: "", type: "file" },
                                  on: { change: _vm.onLogoSelected },
                                }),
                                _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "custom-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.logoInput.click()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            Update Photo\n                                        "
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-actions" },
                  [
                    !_vm.hasTeam
                      ? _c(
                          "md-button",
                          {
                            staticClass: "md-primary dialog-button",
                            on: { click: _vm.validateForm },
                          },
                          [
                            _vm._v(
                              "\n                            Create Team\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.hasTeam
                      ? _c(
                          "md-button",
                          {
                            staticClass: "md-primary dialog-button",
                            on: { click: _vm.validateForm },
                          },
                          [
                            _vm._v(
                              "\n                            Update Details\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-40 md-xsmall-size-100 md-size-40 md-xsmall-hide md-small-hide",
        },
        [
          _c("div", { staticClass: "phone-logo" }, [
            _c("img", { attrs: { src: "/img/phone-logo.png" } }),
            _c("img", {
              staticClass: "arrow-to-phone",
              attrs: { src: "/img/arrow-to-phone.png", alt: "" },
            }),
            _c(
              "div",
              {
                staticClass: "phone-logo-replacer",
                class: _vm.logoPreview ? "" : "transparent-img",
              },
              [
                _c("img", {
                  attrs: { src: _vm.logoPreview, alt: "" },
                  on: { error: _vm.$_setDefaultBrokenImage },
                }),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }