var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-header" }, [
      _c(
        "h4",
        { staticClass: "modal-title" },
        [
          _c("md-icon", { staticClass: "billing-icon" }, [_vm._v("paid")]),
          _vm._v("\n            Manage Billing Online - Unavailable\n        "),
        ],
        1
      ),
    ]),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Ok")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _vm._v(
        "\n        If you are paying by invoice or have an enterprise agreement, please contact our\n        "
      ),
      _c(
        "a",
        {
          staticStyle: { color: "#2b93ff" },
          attrs: { href: "mailto:sales@locate2u.com" },
        },
        [_vm._v("Sales Team")]
      ),
      _vm._v("\n        or your account manager.\n        "),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n        If you have a mobile app store subscription, you will need to manage your billing there.\n        "
      ),
      _c("br"),
      _c("br"),
      _vm._v("\n\n        Contact our\n        "),
      _c(
        "a",
        {
          staticStyle: { color: "#2b93ff" },
          attrs: { href: "mailto:support@locate2u.com" },
        },
        [_vm._v("Support Team")]
      ),
      _vm._v("\n        if neither of the above apply to you.\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }