<template>
    <md-button
        v-if="icon"
        :title="error && error.message ? error.message : `Questions Mapping`"
        class="md-just-icon md-round"
        :class="buttonColor"
        @click.stop="updateQuestionsMapping()"
    >
        <md-icon>{{ icon }}</md-icon>
        <md-tooltip v-if="error.message">{{ error.message }}</md-tooltip>
    </md-button>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import CarrierPreBookingQuestionsModal from '@/components/JobOffers/CarrierPreBookingQuestionsModal';
import { GeneralMixin } from '@/mixins';

export default {
    name: 'PreBookingIndicatorButton',
    mixins: [GeneralMixin],
    props: {
        shipment: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            error: {},
            questionMapper: null,
            mappingResults: {},
            mandatoryFields: [],
            isLoading: true
        };
    },
    computed: {
        hasMissingRequiredFields() {
            return !this.shipment.preBookingQuestions || !this.shipment.preBookingQuestions.alreadyAnswered;
        },
        buttonColor() {
            if (this.error.message) 
                return 'has-error';

            return this.hasMissingRequiredFields ? 'has-warning' : 'md-info';
        },
        icon() {
            if (!this.shipment || !this.shipment.selectedCarrierQuote) 
                return null;
            if (this.error && this.error.message) 
                return 'report_problem';

            // apply animation while fetching required fields from backend
            if (this.isLoading) 
                return 'more_horiz';

            return 'checklist_rtl';
        }
    },
    watch: {
        'shipment.selectedCarrierQuote': function(newVal, oldVal) {
            this.CheckPreBookingRequiredFields();
        }
    },
    mounted() {
        this.CheckPreBookingRequiredFields();
    },
    methods: {
        async CheckPreBookingRequiredFields() {
            this.isLoading = false;
            if (this.shipment.selectedCarrierQuote == null) 
                return;

            this.error = {};
            const args = {
                shipmentId: this.shipment.shipmentId,
                error: false,
                mandatoryFields: [],
                fixedMappingList: [],
                newAnswersList: []
            };

            if (this.shipment.selectedCarrierQuote.isExpired) {
                return;
            }

            this.isLoading = true;

            // find missing required fields
            const preBookingQueryResults = await this.getPreBookingRequiredFields({
                shipmentId: this.shipment.shipmentId,
                carrierTeamId: this.shipment.selectedCarrierQuote.carrierTeamId
            });

            if (!preBookingQueryResults) {
                // there was an error
                this.error = {
                    message: 'Error fetching required fields.'
                };

                args.error = true;
                this.isLoading = false;
                this.$emit('onCheckPreBookingRequiredFieldsCompleted', args);
                return;
            }

            const { requiredFields, questionnaires, fixedAnswers } = preBookingQueryResults;
            const mandatoryFields = requiredFields.map((fld) => {
                return {
                    ...fld,
                    label: this.$t(`carrierFieldLabels.${fld.name}`)
                };
            });

            this.mappingResults.mandatoryFields = [...mandatoryFields];
            this.mappingResults.fixedMappingList = [...fixedAnswers];
            this.mappingResults.newAnswersList = [...questionnaires];

            args.mandatoryFields = [...mandatoryFields];
            args.fixedMappingList = [...fixedAnswers];
            args.newAnswersList = [...questionnaires];

            this.isLoading = false;
            this.$emit('onCheckPreBookingRequiredFieldsCompleted', args);
        },
        async getPreBookingRequiredFields({ shipmentId, carrierTeamId }) {
            if (!carrierTeamId) 
                return null;

            try {
                const api = `/api/carriers/${shipmentId}/pre-booking-check`;
                const payload = {
                    method: 'post',
                    data: { shipmentId, carrierTeamId }
                };
                const { data: requirdFields } = await handleRequests(api, payload);
                return requirdFields;
            } catch (e) {
                // eslint-disable-next-line no-unused-vars
                let message = 'Unable to perform pre-booking check.';
                if (e && e.message)
                    // eslint-disable-next-line prefer-destructuring
                    message = e.message;
                return null;
            }
        },
        updateQuestionsMapping() {
            if (!this.shipment || !this.shipment.selectedCarrierQuote || this.shipment.selectedCarrierQuote.isExpired) {
                this.$notify({
                    message: `No selected quote for this shipment`,
                    type: 'danger'
                });
                return;
            }

            if (this.error.message) 
                return;

            if (
                !this.mappingResults ||
                (!this.mappingResults.newAnswersList.length && !this.mappingResults.mandatoryFields.length)
            )
                return;

            this.mappingResults.newAnswersList.sort(this.sortByIsRequiredAndAlphabetical);
            // show pre-booking questions
            this.$modal
                .show(CarrierPreBookingQuestionsModal, {
                    carrierTeamId: this.shipment.selectedCarrierQuote.carrierTeamId,
                    carrierName: this.shipment.selectedCarrierQuote.carrierTeam.company,
                    fixedMappingList: [...this.mappingResults.fixedMappingList],
                    newAnswersList: [...this.mappingResults.newAnswersList],
                    mandatoryFields: [...this.mappingResults.mandatoryFields]
                })
                .then((response) => {
                    if (response && response.result) {
                        const updatedAnswers = {
                            fixedMappingList: this.mappingResults.fixedMappingList,
                            newAnswersList: response.data.newAnswersList,
                            mandatoryFields: response.data.mandatoryFields
                        };

                        return this.continueUpdateShipment(updatedAnswers);
                    }
                    return Promise.resolve();
                });
        },
        sortByIsRequiredAndAlphabetical(a, b) {
            if (a.isRequired && !b.isRequired) {
                return -1;
            }

            if (!a.isRequired && b.isRequired) {
                return 1;
            }

            return a.label.localeCompare(b.label);
        },
        async continueUpdateShipment(updatedAnswers) {
            const args = {
                error: false,
                shipmentId: this.shipment.shipmentId,
                ...updatedAnswers
            };

            if (!updatedAnswers.mandatoryFields.length) {
                this.$emit('onUpdateShipmentCompleted', args);
                return;
            }

            this.$_handleLoaderState(true, 'UPDATING SHIPMENT...');
            const api = `/api/carriers/${this.shipment.shipmentId}/update-mandatory-fields`;
            const data = {
                shipmentId: this.shipment.shipmentId,
                carrierRequiredFields: [...updatedAnswers.mandatoryFields]
            };

            const payload = {
                method: 'post',
                data
            };

            try {
                await handleRequests(api, payload);
                this.error = {};
            } catch (e) {
                let message = 'Could not update shipment.';
                if (e && e.message)
                    // eslint-disable-next-line prefer-destructuring
                    message = e.message;
                showErrorMessage(this, message, e);

                this.error = {
                    message: 'Error updating shipment.'
                };
                args.error = true;
            } finally {
                this.$emit('onUpdateShipmentCompleted', args);
                this.$_handleLoaderState(false);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.has-error,
.has-error:hover {
    background-color: transparent !important;
    ::v-deep i {
        color: red !important;
    }
}

.has-warning,
.has-warning:hover,
.has-warning:focus {
    background-color: orange !important;
    ::v-deep i {
        color: #fff !important;
    }
}
</style>
