var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "profile-container" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25",
        },
        [
          _c(
            "md-card",
            { staticClass: "md-card-profile" },
            [
              _c(
                "div",
                {
                  staticClass: "md-card-avatar upload-container",
                  on: {
                    mouseover: function ($event) {
                      _vm.showUploadButton = true
                    },
                    mouseleave: function ($event) {
                      _vm.showUploadButton = false
                    },
                  },
                },
                [
                  !_vm.photoPreview
                    ? _c("img", {
                        staticClass: "profile-image",
                        attrs: {
                          src:
                            _vm.user.photoUrl !== null
                              ? _vm.user.photoUrl
                              : _vm.$root.defaultPhotoUrl,
                          alt: "",
                        },
                        on: { error: _vm.$_setDefaultBrokenImage },
                      })
                    : _vm._e(),
                  _vm.photoPreview
                    ? _c("img", {
                        staticClass: "profile-image",
                        attrs: { src: _vm.photoPreview, alt: "" },
                        on: { error: _vm.$_setDefaultBrokenImage },
                      })
                    : _vm._e(),
                  _vm.showUploadButton
                    ? _c("div", [
                        _c("input", {
                          ref: "photoInput",
                          attrs: { hidden: "", type: "file" },
                          on: { change: _vm.onPhotoSelected },
                        }),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "custom-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.photoInput.click()
                                },
                              },
                            },
                            [_vm._v("Update Photo")]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
              _c("md-card-content", [
                _c("h6", { staticClass: "category text-gray" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.displayRole) +
                      "\n                    "
                  ),
                ]),
                _c("span", { staticClass: "card-description" }, [
                  _vm._v(
                    "\n                        Date Joined: " +
                      _vm._s(
                        _vm._f("dateFormat")(
                          _vm.user.createdDate,
                          _vm.DATE_TYPES.standardDate
                        )
                      ) +
                      "\n                    "
                  ),
                ]),
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.displayName) +
                      "\n                    "
                  ),
                ]),
              ]),
            ],
            1
          ),
          !_vm.hasTeam
            ? _c(
                "md-card",
                { staticClass: "md-card-profile logo-section" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "md-card-avatar upload-container",
                      on: {
                        mouseover: function ($event) {
                          _vm.showLogoUploadButton = true
                        },
                        mouseleave: function ($event) {
                          _vm.showLogoUploadButton = false
                        },
                      },
                    },
                    [
                      !_vm.logoPreview
                        ? _c("img", {
                            staticClass: "profile-image",
                            attrs: {
                              src:
                                _vm.user.logoUrl !== null
                                  ? _vm.user.logoUrl
                                  : _vm.$root.defaultLogoUrl,
                              alt: "",
                            },
                            on: { error: _vm.$_setDefaultBrokenImage },
                          })
                        : _vm._e(),
                      _vm.logoPreview
                        ? _c("img", {
                            staticClass: "profile-image",
                            attrs: { src: _vm.logoPreview, alt: "" },
                            on: { error: _vm.$_setDefaultBrokenImage },
                          })
                        : _vm._e(),
                      _vm.showLogoUploadButton
                        ? _c("div", [
                            _c("input", {
                              ref: "logoInput",
                              attrs: { hidden: "", type: "file" },
                              on: { change: _vm.onLogoSelected },
                            }),
                            _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass: "custom-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.logoInput.click()
                                    },
                                  },
                                },
                                [_vm._v("Update Photo")]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c("md-card-content", [
                    _c("h6", { staticClass: "category text-gray" }, [
                      _vm._v("Company Logo"),
                    ]),
                    _c("span", { staticClass: "card-description" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.displayIndustry) +
                          "\n                    "
                      ),
                    ]),
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.displayCompany) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-75 md-xsmall-size-100 md-size-75",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-warning" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("person")])],
                    1
                  ),
                ]
              ),
              _c("md-card-content", [
                _c(
                  "div",
                  { staticClass: "member-information field-spacing-container" },
                  [
                    _c(
                      "form-wrapper",
                      {
                        staticClass: "form-wrapper",
                        attrs: { validator: _vm.$v.user },
                      },
                      [
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "firstName",
                                      label: "First Name",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      model: {
                                        value: _vm.user.firstName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.user, "firstName", $$v)
                                        },
                                        expression: "user.firstName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "lastName",
                                      label: "Last Name",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      model: {
                                        value: _vm.user.lastName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.user, "lastName", $$v)
                                        },
                                        expression: "user.lastName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "email",
                                      label: "Email Address",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      model: {
                                        value: _vm.user.email,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.user, "email", $$v)
                                        },
                                        expression: "user.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  { attrs: { name: "phone", label: "Phone" } },
                                  [
                                    _c("md-input", {
                                      model: {
                                        value: _vm.user.phone,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.user, "phone", $$v)
                                        },
                                        expression: "user.phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "company",
                                      label: "Company",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      model: {
                                        value: _vm.user.company,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.user, "company", $$v)
                                        },
                                        expression: "user.company",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "businessRegistrationNumber",
                                      label:
                                        _vm.businessRegistrationNumberLabel,
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      model: {
                                        value:
                                          _vm.user.businessRegistrationNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.user,
                                            "businessRegistrationNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "user.businessRegistrationNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm.skillList.length
                          ? _c("div", { staticClass: "information-box" }, [
                              _c("div", { staticClass: "md-layout" }, [
                                _c("div", { staticClass: "md-layout-item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "skillfield-container" },
                                    [
                                      _vm.user.skills.length
                                        ? _c(
                                            "label",
                                            { attrs: { for: "skills" } },
                                            [_vm._v("Skills")]
                                          )
                                        : _vm._e(),
                                      _c("multiselect", {
                                        attrs: {
                                          options: _vm.skillList,
                                          multiple: true,
                                          "close-on-select": true,
                                          placeholder: "Pick skills",
                                        },
                                        model: {
                                          value: _vm.user.skills,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.user, "skills", $$v)
                                          },
                                          expression: "user.skills",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.serviceAreasList && _vm.serviceAreasList.length
                          ? _c("div", { staticClass: "information-box" }, [
                              _c("div", { staticClass: "md-layout" }, [
                                _c("div", { staticClass: "md-layout-item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "skillfield-container" },
                                    [
                                      _vm.user.serviceAreas.length
                                        ? _c(
                                            "label",
                                            { attrs: { for: "serviceAreas" } },
                                            [
                                              _vm._v(
                                                "\n                                                Service Areas\n                                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("multiselect", {
                                        attrs: {
                                          options: _vm.serviceAreasList,
                                          multiple: true,
                                          "close-on-select": true,
                                          placeholder: "Pick service areas",
                                        },
                                        model: {
                                          value: _vm.user.serviceAreas,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "serviceAreas",
                                              $$v
                                            )
                                          },
                                          expression: "user.serviceAreas",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.capacityDefinitions.length
                          ? _c("div", { staticClass: "information-box" }, [
                              _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  _vm._l(
                                    _vm.capacityDefinitions,
                                    function (capacity, index) {
                                      return _c("capacity-inputs", {
                                        key: index,
                                        attrs: {
                                          "capacity-definition": capacity,
                                          "initial-value":
                                            _vm.capacityValues[capacity.type],
                                        },
                                        on: {
                                          changed: _vm.handleCapacityChanged,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "information-box" }, [
                          !_vm.hasTeam
                            ? _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c(
                                      "form-group",
                                      {
                                        attrs: {
                                          name: "company",
                                          label: "Company",
                                        },
                                      },
                                      [
                                        _c("md-input", {
                                          model: {
                                            value: _vm.user.company,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.user, "company", $$v)
                                            },
                                            expression: "user.company",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c(
                                      "form-group",
                                      {
                                        attrs: {
                                          name: "industry",
                                          label: "Industry",
                                        },
                                      },
                                      [
                                        _c("industry-drop-down", {
                                          attrs: {
                                            industry: _vm.user.industry,
                                          },
                                          on: {
                                            selectedIndustry:
                                              _vm.selectedIndustry,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c(
                                      "form-group",
                                      {
                                        attrs: {
                                          name: "websiteUrl",
                                          label: "Website url",
                                        },
                                      },
                                      [
                                        _c("md-input", {
                                          model: {
                                            value: _vm.user.websiteUrl,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "websiteUrl",
                                                $$v
                                              )
                                            },
                                            expression: "user.websiteUrl",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item vehicle-box" },
                              [
                                _c("label", [_vm._v("Vehicle:")]),
                                _c(
                                  "md-radio",
                                  {
                                    attrs: { value: "Bike" },
                                    model: {
                                      value: _vm.user.vehicleType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "vehicleType", $$v)
                                      },
                                      expression: "user.vehicleType",
                                    },
                                  },
                                  [_vm._v("Bike")]
                                ),
                                _c(
                                  "md-radio",
                                  {
                                    attrs: { value: "Car" },
                                    model: {
                                      value: _vm.user.vehicleType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "vehicleType", $$v)
                                      },
                                      expression: "user.vehicleType",
                                    },
                                  },
                                  [_vm._v("Car")]
                                ),
                                _c(
                                  "md-radio",
                                  {
                                    attrs: { value: "Walk" },
                                    model: {
                                      value: _vm.user.vehicleType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "vehicleType", $$v)
                                      },
                                      expression: "user.vehicleType",
                                    },
                                  },
                                  [_vm._v("Walk")]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item speed-display" },
                              [
                                _c("label", [_vm._v("Speed Display:")]),
                                _c(
                                  "md-radio",
                                  {
                                    attrs: { value: "kph" },
                                    model: {
                                      value: _vm.user.speedUnits,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "speedUnits", $$v)
                                      },
                                      expression: "user.speedUnits",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            Kilometers per hour\n                                        "
                                    ),
                                  ]
                                ),
                                _c(
                                  "md-radio",
                                  {
                                    attrs: { value: "mph" },
                                    model: {
                                      value: _vm.user.speedUnits,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "speedUnits", $$v)
                                      },
                                      expression: "user.speedUnits",
                                    },
                                  },
                                  [_vm._v("Miles per hour")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _vm.teamRegions && _vm.teamRegions.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _vm.isLoaded
                                      ? _c(
                                          "form-group",
                                          {
                                            staticClass: "md-field-split",
                                            attrs: {
                                              name: "teamRegion",
                                              label: "Team Region",
                                            },
                                          },
                                          [
                                            _c("team-region-options", {
                                              attrs: {
                                                selected: _vm.user.teamRegionId,
                                                enabled:
                                                  _vm.canEditOwnTeamRegion,
                                                "show-auto": false,
                                                id: "user-profile-team-region-options",
                                                "show-all-team-region": true,
                                              },
                                              on: {
                                                selectedOption:
                                                  _vm.handleTeamRegionChanged,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.hasTeam || _vm.hasTeamAccess
                              ? _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c(
                                      "form-group",
                                      {
                                        staticClass: "md-field-split",
                                        attrs: {
                                          name: "role",
                                          label:
                                            "Mobile Location Tracking Behaviour",
                                        },
                                      },
                                      [
                                        _c("gps-collection-dropdown", {
                                          attrs: {
                                            selected:
                                              _vm.user.gpsDataCollectionRule,
                                          },
                                          on: {
                                            selectedOption:
                                              _vm.getSelectedTrackingBehaviour,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-size-25",
                              },
                              [
                                _vm.isLoaded
                                  ? _c(
                                      "form-group",
                                      {
                                        staticClass:
                                          "timer-field hide-timer-clear-button",
                                        attrs: {
                                          name: "defaultTripStartTime",
                                          label: "Start Time",
                                        },
                                      },
                                      [
                                        _c("time-picker", {
                                          attrs: {
                                            time: _vm.user.defaultTripStartTime,
                                            "all-time-options": false,
                                            "clear-none": true,
                                          },
                                          on: {
                                            selectedTime: _vm.getStartTime,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-size-25",
                              },
                              [
                                _vm.isLoaded
                                  ? _c(
                                      "form-group",
                                      {
                                        staticClass: "timer-field",
                                        attrs: {
                                          name: "defaultTripEndTime",
                                          label: "End Time",
                                        },
                                      },
                                      [
                                        _c("time-picker", {
                                          attrs: {
                                            time: _vm.user.defaultTripEndTime,
                                            "all-time-options": false,
                                          },
                                          on: { selectedTime: _vm.getEndTime },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-size-25",
                              },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "defaultStopDurationMinutes",
                                      label:
                                        "Default Stop Duration (in Minutes)",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      attrs: { type: "number" },
                                      model: {
                                        value:
                                          _vm.user.defaultStopDurationMinutes,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.user,
                                            "defaultStopDurationMinutes",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "user.defaultStopDurationMinutes",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-size-25",
                              },
                              [
                                _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "stopDurationMultiplier",
                                      label: "Stop Duration Multiplier",
                                    },
                                  },
                                  [
                                    _c("md-input", {
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.user.stopDurationMultiplier,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.user,
                                            "stopDurationMultiplier",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "user.stopDurationMultiplier",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "md-checkbox",
                                  {
                                    model: {
                                      value: _vm.user.startFromCurrentLocation,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.user,
                                          "startFromCurrentLocation",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "user.startFromCurrentLocation",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            Start from current location\n                                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          !_vm.user.startFromCurrentLocation
                            ? _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c("google-autocomplete", {
                                      ref: "startaddress",
                                      attrs: {
                                        label: "Start Address",
                                        id: "startaddress",
                                        classname:
                                          "form-control autocomplete-input",
                                        placeholder: "",
                                      },
                                      on: {
                                        placechanged:
                                          _vm.handleStartLocationChange,
                                      },
                                      model: {
                                        value: _vm.startLocation,
                                        callback: function ($$v) {
                                          _vm.startLocation = $$v
                                        },
                                        expression: "startLocation",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c("google-autocomplete", {
                                  ref: "endaddress",
                                  attrs: {
                                    label: "End Address",
                                    id: "endaddress",
                                    classname:
                                      "form-control autocomplete-input",
                                    placeholder: "",
                                  },
                                  on: {
                                    placechanged: _vm.handleEndLocationChange,
                                  },
                                  model: {
                                    value: _vm.endLocation,
                                    callback: function ($$v) {
                                      _vm.endLocation = $$v
                                    },
                                    expression: "endLocation",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("payment-method-inputs", {
                          attrs: { detail: _vm.user },
                        }),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item" },
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", [_vm._v("Bio")]),
                                    _c("md-textarea", {
                                      model: {
                                        value: _vm.user.bio,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.user, "bio", $$v)
                                        },
                                        expression: "user.bio",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item custom-switch" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label-text" },
                                  [
                                    _vm._v(
                                      "\n                                            Is this user a driver?\n                                            "
                                    ),
                                    _c(
                                      "md-icon",
                                      { staticClass: "icon-info" },
                                      [_vm._v("info")]
                                    ),
                                    _c(
                                      "md-tooltip",
                                      {
                                        staticClass: "tooltip-width",
                                        attrs: { "md-direction": "right" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                                This setting is used to determine whether the user can be assigned\n                                                stops or shipments.\n                                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("md-switch", {
                                  model: {
                                    value: _vm.user.isDriver,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "isDriver", $$v)
                                    },
                                    expression: "user.isDriver",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "information-box" }, [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item custom-switch" },
                              [
                                _c("label", { staticClass: "label-text" }, [
                                  _vm._v(
                                    "\n                                            Apply tax for invoicing?\n                                        "
                                  ),
                                ]),
                                _c("md-switch", {
                                  model: {
                                    value: _vm.user.shouldApplyTax,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "shouldApplyTax", $$v)
                                    },
                                    expression: "user.shouldApplyTax",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-actions" },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass:
                                  "md-primary dialog-button pull-right",
                                on: { click: _vm.validateForm },
                              },
                              [
                                _vm._v(
                                  "\n                                    Update Profile\n                                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }