<template>
    <div class="shipment-details-container map-panel" v-if="shipmentDetails">
        <div class="panel-header">
            <a href="#" class="navigation-button back" @click="navigate(previousShipmentId)" v-if="previousShipmentId">
                <md-icon>arrow_back_ios</md-icon>
                <md-tooltip class="toggle-tooltip" md-direction="top">
                    Previous Shipment
                    <br />
                    {{ shipmentListCursor + 1 }} of {{ shipmentList.length }}
                </md-tooltip>
            </a>

            <!--TODO: Simplify this to just be Suburb -> Suburb?-->
            <a :href="shipmentDetailsUrl" target="_blank">
                {{ shipmentDetails.pickupStop.name || shipmentDetails.pickupStop.address }}
                >
                {{ shipmentDetails.dropStop.name || shipmentDetails.dropStop.address }}
            </a>

            <a href="#" class="navigation-button forwards" @click="navigate(nextShipmentId)" v-if="nextShipmentId">
                <md-icon>arrow_forward_ios</md-icon>
                <md-tooltip class="toggle-tooltip" md-direction="top">
                    Next Shipment
                    <br />
                    {{ shipmentListCursor + 1 }} of {{ shipmentList.length }}
                </md-tooltip>
            </a>
        </div>

        <div class="panel-content">
            <div class="customer content-section" v-if="shipmentDetails.customerName">
                <span>
                    <md-icon>warehouse</md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Customer</md-tooltip>
                </span>

                <span>{{ shipmentDetails.customerName }}</span>
                <span v-if="shipmentDetails.contact.name">- {{ shipmentDetails.contact.name }}</span>

                <span class="call-customer" v-if="shipmentDetails.contact.phone">
                    <a :href="`tel:${shipmentDetails.contact.phone}`" class="profile--phone">
                        <md-icon>phone</md-icon>
                        <md-tooltip class="toggle-tooltip" md-direction="top">
                            Call {{ shipmentDetails.contact.name || shipmentDetails.customerName || 'customer' }} on
                            {{ shipmentDetails.contact.phone }}
                        </md-tooltip>
                    </a>
                </span>

                <!-- Brand -->
            </div>

            <div class="time-windows content-section" v-if="stopTimeWindows && stopTimeWindows.length">
                <span>
                    <md-icon>schedule</md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Stop Time Windows</md-tooltip>
                </span>

                <span class="section-values" v-for="(stop, ndx) in stopTimeWindows" :key="`tw-${ndx}`">
                    <label>{{ stop.label }}:</label>
                    {{ stop.value }}
                </span>
            </div>

            <div class="skills content-section" v-if="shipmentDetails.skills && shipmentDetails.skills.length">
                <span>
                    <md-icon>checklist</md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Required Skills</md-tooltip>
                </span>

                <span
                    class="md-chip md-theme-default custom-chip"
                    v-for="(skill, ndx) in shipmentDetails.skills"
                    :key="`skill-${ndx}`"
                >
                    {{ skill }}
                </span>
            </div>

            <div class="load content-section" v-if="loadDetails && loadDetails.length">
                <span>
                    <md-icon>scale</md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Shipment Load</md-tooltip>
                </span>

                <span class="section-values" v-for="(load, ndx) in loadDetails" :key="`load-${ndx}`">
                    <label>{{ load.label }}:</label>
                    {{ load.value }} {{ load.symbol }}
                </span>
            </div>

            <div class="notes content-section" v-if="shipmentDetails.notes">
                <span>
                    <md-icon>description</md-icon>
                    <md-tooltip class="toggle-tooltip" md-direction="top">Shipment Notes</md-tooltip>
                </span>

                <!-- TODO: This should preserve line breaks -->
                <span>{{ shipmentDetails.notes }}</span>
            </div>
        </div>

        <!--
        Future things to add:
        Shipment Reference / Source Reference?
        Contact details
        Brand
        Shipment contents
        Driver instructions
        Custom fields?
        Driver Pay / Customer Charge
        Edit button
        -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { DATE_TYPES } from '@/utils/constants';

export default {
    name: 'ShipmentDetailsPanel',

    props: {
        shipmentDetails: {
            type: Object,
            default: null
        },

        shipmentList: {
            type: Array,
            default: null
        }
    },

    computed: {
        ...mapGetters({
            user: 'user/user'
        }),

        shipmentDetailsUrl() {
            if (!this.shipmentDetails) 
                return null;

            return `/shipments/details/${this.shipmentDetails.shipmentId}`;
        },

        shipmentListCursor() {
            // This is the index of the current shipment in the list
            if (!this.shipmentDetails || !this.shipmentList) 
                return null;

            return (
                this.shipmentList.findIndex((shipment) => shipment.shipmentId === this.shipmentDetails.shipmentId) || 0
            );
        },

        previousShipmentId() {
            if (!this.shipmentDetails || !this.shipmentList || this.shipmentListCursor === null) 
                return null;

            if (this.shipmentListCursor !== 0) {
                const shipment = this.shipmentList[this.shipmentListCursor - 1];
                return shipment?.shipmentId;
            }

            return null;
        },

        nextShipmentId() {
            if (!this.shipmentDetails || !this.shipmentList || this.shipmentListCursor === null) 
                return null;

            if (this.shipmentListCursor !== this.shipmentList.length - 1) {
                const shipment = this.shipmentList[this.shipmentListCursor + 1];
                return shipment?.shipmentId;
            }

            return null;
        },

        stopTimeWindows() {
            if (!this.shipmentDetails) 
                return null;

            const pickupStopTimeWindow = this.formatStopTimeWindow(this.shipmentDetails.pickupStop);
            const dropStopTimeWindow = this.formatStopTimeWindow(this.shipmentDetails.dropStop);

            const output = [];

            if (pickupStopTimeWindow) {
                output.push({
                    label: 'Pickup',
                    value: pickupStopTimeWindow
                });
            }

            if (dropStopTimeWindow) {
                output.push({
                    label: 'Drop',
                    value: dropStopTimeWindow
                });
            }

            return output;
        },

        loadDetails() {
            if (!this.shipmentDetails || !this.user) 
                return null;

            const loadUnits = this.user.vehicleCapacityUnitsConfiguration;

            const shipmentLoad = this.shipmentDetails.load || {};

            const load = loadUnits.map((unit) => {
                return {
                    type: unit.type,
                    label: unit.label,
                    value: shipmentLoad[unit.type] || '-',
                    symbol: unit.symbol
                };
            });

            return load;
        }
    },

    setup(props, { root }) {
        return {};
    },

    methods: {
        navigate(shipmentId) {
            this.$emit('navigateToShipmentId', shipmentId);
        },
        formatStopTimeWindow(stop) {
            if (stop.appointmentTime) {
                return moment(stop.appointmentTime).format(DATE_TYPES.standardTime);
            }

            let description = '';

            if (stop.timeWindowStart) {
                description += moment(stop.timeWindowStart).format(DATE_TYPES.standardTime);
            }

            if (stop.timeWindowStart && stop.timeWindowEnd) 
                description += ' - ';

            if (stop.timeWindowEnd) {
                description += moment(stop.timeWindowEnd).format(DATE_TYPES.standardTime);
            }

            return description || null;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';

.shipment-details-container {
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    left: 10px;
    top: 10px;
    width: 570px;
    height: auto;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .panel-header {
        font-weight: bold;
        border-bottom: 1px solid #d2d2d2;
        text-align: center;
        height: 30px;
        margin-bottom: 5px;
        background-color: $locate2u-blue-base !important;
        font-size: 14px;
        line-height: 30px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        position: relative;

        a {
            color: white !important;

            &:hover {
                color: white !important;
            }
        }

        .navigation-button {
            position: absolute;
            top: 0;
            margin: 0 10px;

            .md-icon {
                color: white;
                font-size: 14px !important;
            }
        }

        .back {
            left: 0;
        }

        .forwards {
            right: -5px;
        }
    }

    .panel-content {
        padding: 5px 10px;

        .md-icon {
            color: $locate2u-blue-base;
            margin-right: 15px;
        }
    }

    .content-section {
        padding-bottom: 10px;
    }

    .custom-chip {
        background-color: $locate2u-blue-dark !important;
        margin-top: 0;
    }

    .section-values {
        margin-right: 20px;
    }

    label {
        font-weight: bold;
    }

    a {
        color: $locate2u-blue-base;
    }

    .call-customer {
        .md-icon {
            font-size: 14px !important;
            color: $locate2u-blue-dark;
            margin-left: 10px;
        }
    }
}
</style>
