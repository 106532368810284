<template>
    <div class="modal-container">
        <div class="modal-header">
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <simple-wizard
                plain
                :show-header="false"
                :title="title"
                :sub-title="subtitle"
                @finish="validateDetails"
                @stepChange="stepChange"
            >
                <wizard-tab :before-change="validateDetails" :id="'assetGroupDetails'">
                    <template slot="label">
                        Group Details
                    </template>
                    <form-wrapper
                        v-if="step === 'assetGroupDetails'"
                        :validator="$v.assetGroupDetails"
                        class="form-wrapper"
                    >
                        <group-step :asset-group-details="assetGroupDetails" />
                    </form-wrapper>
                </wizard-tab>
                <wizard-tab :before-change="validateDetails" :id="'personnel'">
                    <template slot="label">
                        Personnel
                    </template>
                    <form-wrapper v-if="step === 'personnel'" :validator="$v.personnel" class="form-wrapper">
                        <div ref="personnelContainer" class="overflow-container" :class="showBorder ? 'p-border' : ''">
                            <personnel-step
                                v-for="(item, index) in $v.personnel.$each.$iter"
                                :key="index"
                                :data="item.$model"
                                :index="Number(index)"
                                :dropdown-list="personnelDropdownList"
                                @getExistingDetails="getExistingPersonnelDetails"
                                :is-disabled="item.$model.isDisabled"
                                :selected-asset="Number(item.$model.selectedPersonnel)"
                                :selected-asset-list="selectedPersonnelList"
                                @removePersonnel="removePersonnel"
                                :list-length="personnel.length"
                            />
                        </div>

                        <md-button
                            class="md-icon-button md-dense md-raised md-primary pull-right"
                            @click="addPersonnel"
                        >
                            <md-icon>add</md-icon>
                        </md-button>
                    </form-wrapper>
                </wizard-tab>

                <wizard-tab :before-change="validateDetails" :id="'vehicle'">
                    <template slot="label">
                        Vehicle
                    </template>
                    <form-wrapper v-if="step === 'vehicle'" :validator="$v.vehicle" class="form-wrapper">
                        <div class="overflow-container">
                            <vehicle-step
                                :data="vehicle"
                                :dropdown-list="vehicleDropdownList"
                                @getExistingDetails="getExistingVehicleDetails"
                                :is-disabled="isVehicleDisabled"
                                :selected-asset="selectedVehicle"
                            />
                        </div>
                    </form-wrapper>
                </wizard-tab>

                <wizard-tab :before-change="validateDetails" :id="'tracker'">
                    <template slot="label">
                        Tracker
                    </template>
                    <form-wrapper v-if="step === 'tracker'" :validator="$v.tracker" class="form-wrapper">
                        <div class="overflow-container">
                            <tracker-step
                                :data="tracker"
                                :dropdown-list="trackerDropdownList"
                                @getExistingDetails="getExistingTrackerDetails"
                                :is-disabled="isTrackerDisabled"
                                :selected-asset="selectedTracker"
                            />
                        </div>
                    </form-wrapper>
                </wizard-tab>
            </simple-wizard>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, email, numeric } from 'vuelidate/lib/validators';
import { SimpleWizard, WizardTab } from '@/components';
import GroupStep from '@/pages/AssetTracking/StepComponents/GroupStep';
import PersonnelStep from '@/pages/AssetTracking/StepComponents/PersonnelStep';
import VehicleStep from '@/pages/AssetTracking/StepComponents/VehicleStep';
import TrackerStep from '@/pages/AssetTracking/StepComponents/TrackerStep';
import { handleRequests, showErrorMessage } from '@/helpers';

export default {
    name: 'CreateAssetGroupContent',
    components: {
        SimpleWizard,
        WizardTab,
        GroupStep,
        PersonnelStep,
        VehicleStep,
        TrackerStep
    },
    mixins: [GeneralMixin],
    props: {
        personnelDropdownList: {
            type: Array,
            default: () => []
        },
        vehicleDropdownList: {
            type: Array,
            default: () => []
        },
        trackerDropdownList: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            title: 'Asset Group',
            subtitle: 'Create a set of related assets.',
            selectedPersonnel: 0,
            selectedVehicle: 0,
            selectedTracker: 0,
            assetGroupDetails: {
                groupName: null
            },
            personnel: [
                {
                    type: 'Personnel',
                    name: null,
                    firstName: null,
                    email: null,
                    selectedPersonnel: 0,
                    isDisabled: false
                }
            ],
            vehicle: {
                type: 'Vehicle',
                name: null
            },
            tracker: {
                type: 'Tracker',
                name: null
            },
            form: {
                assets: []
            },
            isPersonnelDisabled: false,
            isVehicleDisabled: false,
            isTrackerDisabled: false,
            step: 'assetGroupDetails',
            showBorder: false,
            selectedPersonnelList: [],
            isRemove: false
        };
    },
    validations: {
        assetGroupDetails: {
            groupName: {
                required
            }
        },
        personnel: {
            $each: {
                email: {
                    email
                },
                name: {
                    required
                }
            }
        },
        vehicle: {
            name: {
                required
            },
            modelYear: {
                numeric
            }
        },
        tracker: {
            name: {
                required
            },
            trackerReference: {
                required
            },
            modelYear: {
                numeric
            }
        }
    },
    mounted() {
        // this is needed so that it will reset everything when modal is closed.
        this.$v.$reset();
        this.personnel = [
            {
                type: 'Personnel',
                name: null,
                firstName: null,
                email: null,
                selectedPersonnel: 0,
                isDisabled: false
            }
        ];
        this.vehicle = { type: 'Vehicle', name: null };
        this.tracker = { type: 'Tracker', name: null };
        this.selectedPersonnelList = [];
    },
    methods: {
        validateDetails() {
            this.$v[this.step].$touch();
            if (!this.$v[this.step].$invalid) {
                if (this.step === 'vehicle') {
                    if (this.vehicle.vehicleVIN) {
                        const tracker = this.trackerDropdownList.find((x) => x.vehicleVIN === this.vehicle.vehicleVIN);

                        if (tracker != null) {
                            this.selectedTracker = tracker.assetId;
                            this.setTrackerDetails(tracker);
                        }
                    }
                }

                if (this.step === 'tracker') {
                    this.finish();
                }

                return true;
            }
            return false;
        },
        setDataToArray(data) {
            const customFields = {};
            Object.assign(customFields, data);
            let { assetId } = data;
            const { name, type } = data;

            if (!assetId) 
                assetId = 0;

            // these deletes are necessary so that the customFields won't contain these values anymore.
            delete customFields.assetId;
            delete customFields.name;
            delete customFields.type;

            if (type === 'Personnel') {
                delete customFields.selectedPersonnel;
                delete customFields.isDisabled;
            }

            const obj = {
                assetId,
                name,
                type,
                customFields: JSON.stringify(customFields)
            };
            this.form.assets.push(obj);
        },
        pushToAssets(data, type) {
            if (type !== 'personnel') {
                this.setDataToArray(data);
            } else {
                data.forEach((e) => {
                    this.setDataToArray(e);
                });
            }
        },
        async finish() {
            this.$_handleLoaderState(true);
            this.form.assets = [];

            this.pushToAssets(this.personnel, 'personnel');
            this.pushToAssets(this.vehicle);
            this.pushToAssets(this.tracker);

            const api = '/api/asset-groups';
            const payload = {
                method: 'post',
                data: {
                    AssetGroupDetails: this.assetGroupDetails,
                    AssetDetails: this.form.assets
                }
            };
            try {
                const res = await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully created an asset group.',
                    type: 'success'
                });

                this.assetGroupDetails = { groupName: null };
                this.personnel = [
                    {
                        type: 'Personnel',
                        name: null,
                        selectedPersonnel: 0,
                        isDisabled: false
                    }
                ];
                this.vehicle = { type: 'Vehicle', name: null };
                this.tracker = { type: 'Tracker', name: null };
                this.selectedPersonnelList = [];
                this.$v.$reset();
                this.resolve(res);
            } catch (error) {
                const message = 'Error in creating asset group';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        getExistingPersonnelDetails(assetId, index, prevId) {
            this.deselectPersonnelIdFromList(prevId);
            this.$v.personnel.$each[index].$reset();

            if (!this.isRemove) {
                const keys = Object.keys(this.personnel[index]);
                keys.forEach((key) => {
                    if (key !== 'type' || key !== 'selectedPersonnel') 
                        this.personnel[index][key] = null;
                });
            }

            if (assetId !== 0) {
                Object.assign(this.personnel[index], this.setAssetDetails(this.personnelDropdownList, assetId));
                this.selectedPersonnelList.push(assetId);
            } else {
                // eslint-disable-next-line no-lonely-if
                if (!this.isRemove) {
                    Object.assign(this.personnel[index], {
                        type: 'Personnel',
                        name: null,
                        firstName: null,
                        email: null,
                        selectedPersonnel: 0,
                        isDisabled: false
                    });
                }
            }

            this.personnel[index].selectedPersonnel = assetId;
            this.personnel[index].isDisabled = this.personnel[index].name != null;
            this.isRemove = false;
        },
        deselectPersonnelIdFromList(assetId) {
            const i = this.selectedPersonnelList.findIndex((x) => x === assetId);
            if (i > -1) 
                this.selectedPersonnelList.splice(i, 1);
        },
        getExistingVehicleDetails(assetId) {
            this.selectedVehicle = assetId;
            this.vehicle =
                assetId !== 0
                    ? this.setAssetDetails(this.vehicleDropdownList, assetId)
                    : { type: 'Vehicle', name: null };
            this.isVehicleDisabled = this.vehicle.name != null;
        },
        getExistingTrackerDetails(assetId) {
            this.selectedTracker = assetId;
            this.tracker = { type: 'Tracker', name: null };
            if (assetId !== 0) {
                Object.assign(this.tracker, this.trackerDropdownList.find((x) => x.assetId === assetId));
            }
            this.isTrackerDisabled = this.tracker.name != null;
        },
        setAssetDetails(list, id) {
            const { assetId, name, customFields, type } = list.find((x) => x.assetId === id);
            const data = {
                assetId,
                name,
                type
            };
            const obj = JSON.parse(customFields);

            Object.assign(data, obj);

            return data;
        },
        stepChange(stepName) {
            this.step = stepName;
        },
        addPersonnel() {
            if (this.validateDetails()) {
                const index = this.personnel.length - 1;
                if (this.personnel[index].name != null) {
                    this.personnel.push({
                        type: 'Personnel',
                        name: null,
                        selectedPersonnel: 0,
                        isDisabled: false
                    });
                    this.$nextTick(() => {
                        this.$refs.personnelContainer.scrollTop = this.$refs.personnelContainer.scrollHeight;
                    });
                }
                this.showBorder = true;
            }
        },
        removePersonnel(index) {
            this.isRemove = true;
            const prevAssetId = this.personnel[index].selectedPersonnel;
            this.deselectPersonnelIdFromList(prevAssetId);
            this.$v.personnel.$each[index].$reset();
            this.personnel.splice(index, 1);
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-card-content {
    padding-bottom: 5px;
}
.overflow-container {
    overflow: auto;
    max-height: 50vh;
    margin-bottom: 10px;
    > div {
        margin-bottom: 20px;
    }
}
.p-border {
    border-bottom: 1px solid #eee;
}
.modal-container {
    max-width: 800px;
    @media (min-width: 1440px) {
        max-width: 1000px;
    }
    .modal-header {
        padding: 0;
        button {
            z-index: 99;
        }
    }
    .modal-body {
        padding-top: 0;
        h5 {
            font-weight: 400;
            text-transform: uppercase;
            font-size: 15px;
        }

        ::v-deep .md-card {
            margin: 0 !important;
            background-color: #fff;
            box-shadow: none;
        }

        ::v-deep .tab-content {
            padding: 20px 15px 0 15px !important;
        }
        .md-icon-button,
        ::v-deep .md-icon-button {
            background-color: #2b93ff !important;
        }
    }
}
</style>
