var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-group" },
    _vm._l(_vm.myOptions.value, function (option) {
      return _c(
        "md-button",
        {
          key: option.value || option.header,
          class: [
            _vm.className,
            option.value === _vm.selectedValue ? _vm.selectedClassName : "",
          ],
          on: {
            click: function ($event) {
              return _vm.buttonClicked(option.value)
            },
          },
        },
        [_vm._v("\n        " + _vm._s(option.text) + "\n    ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }