var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-layout stop-details" }, [
    _vm.isBeforeStartTime
      ? _c(
          "div",
          {
            class: [
              "md-layout-item md-size-100 alert alert-warning",
              _vm.isBeforeEndTime ? "alert-bg-color" : "",
            ],
          },
          [
            _vm._v(
              "\n        The trip start time has already passed. The routing timeframe will be limited to between the current time\n        and the designated end time.\n    "
            ),
          ]
        )
      : _vm._e(),
    _vm.teamRegions && _vm.teamRegions.length > 0
      ? _c(
          "div",
          { staticClass: "md-layout-item md-size-40 team-region-field" },
          [
            _c("label", [_vm._v("Team Region")]),
            _c("team-region-filter-options", {
              attrs: {
                "team-region-id": _vm.data.teamRegionStartLocation,
                clearable: false,
              },
              on: { changed: _vm.handleStartTeamRegionChanged },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.hasCustomers
      ? _c(
          "div",
          { staticClass: "md-layout-item md-size-50 customer-field" },
          [
            _c("customer-autocomplete", {
              ref: "customerAutocomplete",
              attrs: {
                label: "Customer",
                id: "autocustomer",
                placeholder: "",
                "should-focus": false,
                "auto-fill-address": true,
                "is-clearable": true,
                "team-region-id": _vm.data.teamRegionStartLocation,
              },
              on: {
                input: _vm.handleCustomerChanged,
                change: _vm.handleCustomerTextChanged,
              },
              model: {
                value: _vm.customer,
                callback: function ($$v) {
                  _vm.customer = $$v
                },
                expression: "customer",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100 trip-option-box" },
      [
        _c(
          "md-radio",
          {
            attrs: { value: "manual" },
            model: {
              value: _vm.data.startLocationMode,
              callback: function ($$v) {
                _vm.$set(_vm.data, "startLocationMode", $$v)
              },
              expression: "data.startLocationMode",
            },
          },
          [_vm._v("Enter a start location")]
        ),
        _c(
          "md-radio",
          {
            attrs: { value: "teammembers" },
            model: {
              value: _vm.data.startLocationMode,
              callback: function ($$v) {
                _vm.$set(_vm.data, "startLocationMode", $$v)
              },
              expression: "data.startLocationMode",
            },
          },
          [
            _vm._v(
              "\n            Team members start from their home location\n        "
            ),
          ]
        ),
        _vm.warehouseOptions && _vm.warehouseOptions.length > 0
          ? _c(
              "md-radio",
              {
                attrs: { value: "warehouse" },
                model: {
                  value: _vm.data.startLocationMode,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "startLocationMode", $$v)
                  },
                  expression: "data.startLocationMode",
                },
              },
              [_vm._v("\n            Warehouse\n        ")]
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        class: [
          "md-layout md-layout-item md-size-100",
          _vm.data.startLocationMode == "manual" ? "address-label" : "",
        ],
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.data.startLocationMode == "manual",
                expression: "data.startLocationMode == 'manual'",
              },
            ],
            staticClass: "md-layout-item md-size-80 address",
          },
          [
            _c("google-autocomplete", {
              ref: "startlocation",
              attrs: {
                id: "startlocationId",
                classname: "form-control autocomplete-input",
                label: "Address",
                icon: "add_location",
                placeholder: "",
              },
              on: {
                placechanged: _vm.onStartLocationChanged,
                inputChange: _vm.onStartLocationInputChange,
              },
              model: {
                value: _vm.data.startLocation,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "startLocation", $$v)
                },
                expression: "data.startLocation",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.data.startLocationMode == "warehouse",
                expression: "data.startLocationMode == 'warehouse'",
              },
            ],
            staticClass: "md-layout-item md-size-80 warehouse-field",
          },
          [
            _c(
              "md-field",
              [
                _c("md-icon", [_vm._v("add_location")]),
                _c("vue-select", {
                  attrs: {
                    reduce: function (x) {
                      return x.warehouseId
                    },
                    label: "name",
                    options: _vm.startLocationWarehouseOptions,
                    placeholder: "Select warehouse start location",
                  },
                  model: {
                    value: _vm.data.warehouseStartLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "warehouseStartLocation", $$v)
                    },
                    expression: "data.warehouseStartLocation",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            class: [
              "md-layout-item md-size-20",
              _vm.data.startLocationMode == "manual"
                ? "run-number-manual"
                : "run-number",
            ],
          },
          [
            _c(
              "form-group",
              { attrs: { name: "runnumber", label: "Run #" } },
              [
                _c("md-input", {
                  attrs: { type: "number", min: "1" },
                  model: {
                    value: _vm.data.runNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "runNumber", _vm._n($$v))
                    },
                    expression: "data.runNumber",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "md-layout-item md-size-100",
        on: {
          click: function ($event) {
            return _vm.toggleChange($event, 0)
          },
        },
      },
      [
        _c("div", { staticClass: "end-location-label" }, [
          _vm._v("End Location"),
        ]),
        _c(
          "md-card",
          { staticClass: "end-location-card" },
          [
            _c(
              "md-card-expand",
              [
                _c(
                  "md-card-actions",
                  [
                    _c("div", { staticClass: "end-location-value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.endLocationText) +
                          "\n                    "
                      ),
                    ]),
                    _c(
                      "md-card-expand-trigger",
                      [
                        _c("md-button", { staticClass: "btn-change" }, [
                          _vm._v("Change"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "md-card-expand-content",
                  [
                    _c("md-card-content", [
                      _c(
                        "div",
                        { staticClass: "md-size-100 option-box" },
                        [
                          _c(
                            "md-radio",
                            {
                              attrs: { value: "none" },
                              model: {
                                value: _vm.data.endLocationMode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "endLocationMode", $$v)
                                },
                                expression: "data.endLocationMode",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.endLocationOptions["none"]) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _c(
                            "md-radio",
                            {
                              staticClass: "text-padding",
                              attrs: { value: "startlocation" },
                              model: {
                                value: _vm.data.endLocationMode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "endLocationMode", $$v)
                                },
                                expression: "data.endLocationMode",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.endLocationOptions["startlocation"]
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "md-size-100 option-box" },
                        [
                          _c(
                            "md-radio",
                            {
                              attrs: { value: "teammembers" },
                              model: {
                                value: _vm.data.endLocationMode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "endLocationMode", $$v)
                                },
                                expression: "data.endLocationMode",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.endLocationOptions["teammembers"]
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _c(
                            "md-radio",
                            {
                              attrs: { value: "manual" },
                              model: {
                                value: _vm.data.endLocationMode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "endLocationMode", $$v)
                                },
                                expression: "data.endLocationMode",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.endLocationOptions["manual"]) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.warehouseOptions && _vm.warehouseOptions.length > 0
                        ? _c(
                            "div",
                            { staticClass: "md-size-100 option-box" },
                            [
                              _c(
                                "md-radio",
                                {
                                  attrs: { value: "warehouse" },
                                  model: {
                                    value: _vm.data.endLocationMode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "endLocationMode", $$v)
                                    },
                                    expression: "data.endLocationMode",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.endLocationOptions["warehouse"]
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.data.endLocationMode == "manual",
                              expression: "data.endLocationMode == 'manual'",
                            },
                          ],
                          staticClass:
                            "md-size-100 address-margin address-label",
                        },
                        [
                          _c("google-autocomplete", {
                            ref: "endlocation",
                            attrs: {
                              id: "endlocationId",
                              classname: "form-control autocomplete-input",
                              label: "Address",
                              icon: "add_location",
                              placeholder: "",
                            },
                            on: { placechanged: _vm.onEndLocationChanged },
                            model: {
                              value: _vm.data.endLocation,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "endLocation", $$v)
                              },
                              expression: "data.endLocation",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.data.endLocationMode == "warehouse" && _vm.isActive === 1,
            expression: "data.endLocationMode == 'warehouse' && isActive === 1",
          },
        ],
        staticClass: "md-layout-item md-size-100 end-warehouse-field",
      },
      [
        _c(
          "md-field",
          [
            _c("md-icon", [_vm._v("add_location")]),
            _c("vue-select", {
              attrs: {
                reduce: function (x) {
                  return x.warehouseId
                },
                label: "name",
                options: _vm.endLocationWarehouseOptions,
                placeholder: "Select warehouse end location",
              },
              model: {
                value: _vm.data.warehouseEndLocation,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "warehouseEndLocation", $$v)
                },
                expression: "data.warehouseEndLocation",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "md-layout-item md-size-35" }, [
      _c(
        "div",
        { staticClass: "trip-date-field" },
        [
          _c(
            "md-datepicker",
            {
              attrs: { "md-immediately": "" },
              model: {
                value: _vm.data.tripDate,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "tripDate", $$v)
                },
                expression: "data.tripDate",
              },
            },
            [_c("label", [_vm._v("Date")])]
          ),
          _vm.data.tripDate == null
            ? _c("span", { staticClass: "error" }, [
                _vm._v("Trip date is required."),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-30" },
      [
        _c(
          "form-group",
          {
            staticClass: "timer-field",
            attrs: { name: "starttime", label: "Start Time" },
          },
          [
            _c("md-icon", [_vm._v("access_time")]),
            _c("time-picker", {
              attrs: {
                time: _vm.getStartTime === null ? "None" : _vm.getStartTime,
                "all-time-options": false,
              },
              on: { selectedTime: _vm.setStartTime },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-30" },
      [
        _c(
          "form-group",
          {
            staticClass: "timer-field",
            attrs: { name: "endtime", label: "End Time" },
          },
          [
            _c("md-icon", [_vm._v("access_time")]),
            _c("time-picker", {
              attrs: {
                time: _vm.getEndTime === null ? "None" : _vm.getEndTime,
                "all-time-options": false,
              },
              on: { selectedTime: _vm.setEndTime },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-checkbox",
          {
            model: {
              value: _vm.data.skillMatching,
              callback: function ($$v) {
                _vm.$set(_vm.data, "skillMatching", $$v)
              },
              expression: "data.skillMatching",
            },
          },
          [_vm._v("Skill matching ON")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-checkbox",
          {
            model: {
              value: _vm.data.teamMemberTimingOverride,
              callback: function ($$v) {
                _vm.$set(_vm.data, "teamMemberTimingOverride", $$v)
              },
              expression: "data.teamMemberTimingOverride",
            },
          },
          [
            _vm._v(
              "\n            Use the start/end times on the team member profiles (where configured), overriding the times above\n        "
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-checkbox",
          {
            model: {
              value: _vm.data.addReturnStartLocationStop,
              callback: function ($$v) {
                _vm.$set(_vm.data, "addReturnStartLocationStop", $$v)
              },
              expression: "data.addReturnStartLocationStop",
            },
          },
          [
            _vm._v(
              "\n            Add return to start location for existing trips\n        "
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-checkbox",
          {
            model: {
              value: _vm.data.ignoreTimeWindows,
              callback: function ($$v) {
                _vm.$set(_vm.data, "ignoreTimeWindows", $$v)
              },
              expression: "data.ignoreTimeWindows",
            },
          },
          [_vm._v("\n            Ignore Time Windows\n        ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-checkbox",
          {
            model: {
              value: _vm.data.contiguityConstraint,
              callback: function ($$v) {
                _vm.$set(_vm.data, "contiguityConstraint", $$v)
              },
              expression: "data.contiguityConstraint",
            },
          },
          [_vm._v("\n            Route with non-overlapping areas\n        ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-checkbox",
          {
            model: {
              value: _vm.data.excludeStopsWithNoDate,
              callback: function ($$v) {
                _vm.$set(_vm.data, "excludeStopsWithNoDate", $$v)
              },
              expression: "data.excludeStopsWithNoDate",
            },
          },
          [_vm._v("\n            Exclude stops with no date\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }