<template>
    <div class="content">
        <div v-if="obdAlertModalHide !== true" class="alert alert-info alert-z">
            <button type="button" aria-hidden="true" class="close" @click="obdAlertModalHide = true">
                ×
            </button>
            <span>
                The safety reports are only available for OBD device data.
            </span>
        </div>

        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <div class="custom-toolbar md-layout">
                    <div class="md-layout-item custom-toolbar-start md-layout md-small-size-80 md-size-90">
                        <team-region-member-filter-options
                            class="filter-inline"
                            v-if="canViewTeamRegion"
                            :team-region-id="teamRegionId"
                            @changed="handleTeamRegionMemberChanged"
                            :show-team-member="false"
                            :clearable="false"
                        ></team-region-member-filter-options>
                        <div class="md-layout-item">
                            <date-range-filter :is-single-date="false" @onChanged="onDateChanged" />
                        </div>
                    </div>
                    <div class="md-layout-item custom-toolbar-end">
                        <md-button
                            title="Export Safety Summary Report"
                            class="md-primary md-just-icon md-round pull-right header-button"
                            @click="onExportToExcel"
                        >
                            <md-icon>get_app</md-icon>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>summarize</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div v-if="!reportDataList || reportDataList.length == 0">
                            <h3>Safety Summary Report</h3>
                            <p>No results to display.</p>
                        </div>
                        <div v-else>
                            <div class="report-container">
                                <safety-summary-report-details :member-list="reportDataList" />
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.header-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.alert-z {
    z-index: 0 !important;
}

.filter-inline {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        margin-right: 20px;
    }
}
</style>
<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapMutations, mapGetters } from 'vuex';
import { TeamRegionMemberFilterOptions } from '@/components';
import SafetySummaryReportDetails from './SafetySummaryReportDetails';
import { handleRequests, downloadFile, SearchGuard } from '../../helpers';
import { DateRangeFilter } from './components';

export default {
    name: 'SafetySummaryReport',
    components: { SafetySummaryReportDetails, DateRangeFilter, TeamRegionMemberFilterOptions },
    mixins: [GeneralMixin],
    data() {
        return {
            isLoading: true,
            reportDateFrom: null,
            reportDateTo: null,
            reportDataList: [],
            smallAlertModalHide: false,
            obdAlertModalHide: false,

            teamRegionId: undefined,
            searchGuard: {}
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            canViewTeamRegion: 'user/canViewTeamRegion'
        })
    },
    watch: {},
    methods: {
        ...mapMutations({
            changeDate: 'map/CHANGE_DATE'
        }),

        onExportToExcel() {
            if (this.reportDateFrom && this.reportDateTo) {
                this.$_handleLoaderState(true);
                const api = `/api/reports/safety-summary-report/export-to-excel?startDate=${moment(
                    this.reportDateFrom
                ).format('YYYY-MM-DD')}&endDate=${moment(this.reportDateTo).format('YYYY-MM-DD')}&teamRegionId=${
                    this.teamRegionId
                }`;
                const payload = {
                    method: 'get',
                    responseType: 'blob'
                };
                const reportName = `SafetySummaryReport.xlsx`;
                handleRequests(api, payload)
                    .then((response) => {
                        downloadFile(response.data, reportName);
                        this.$_handleLoaderState(false);
                    })
                    .catch((e) => {
                        this.$_handleLoaderState(false);
                        this.$notify({ message: e.data, type: 'danger' });
                    });
            }
        },
        getReportDataList() {
            if (this.reportDateFrom && this.reportDateTo) {
                const shouldSearch = this.searchGuard.shouldSearch({
                    reportDateFrom: this.reportDateFrom,
                    reportDateTo: this.reportDateTo,
                    teamRegionId: this.teamRegionId
                });

                if (!shouldSearch) 
                    return;

                this.isLoading = true;

                this.$_handleLoaderState(true);
                const api = `/api/reports/safety-summary-report?startDate=${moment(this.reportDateFrom).format(
                    'YYYY-MM-DD'
                )}&endDate=${moment(this.reportDateTo).format('YYYY-MM-DD')}&teamRegionId=${this.teamRegionId}`;
                handleRequests(api)
                    .then((response) => {
                        this.isLoading = false;
                        this.reportDataList = response.data.data;
                        this.$_handleLoaderState(false);
                    })
                    .catch((e) => {
                        this.reportDataList = [];
                        this.$_handleLoaderState(false);
                        this.$notify({ message: e.data, type: 'danger' });
                    });
            }
        },

        async onDateChanged(val) {
            this.reportDateFrom = val.startDate;
            this.reportDateTo = val.endDate;
            this.getReportDataList();
        },

        handleTeamRegionMemberChanged(args) {
            this.teamRegionId = args.teamRegionId;
            this.getReportDataList();
        }
    },
    async mounted() {
        this.searchGuard = new SearchGuard({
            reportDateFrom: null,
            reportDateTo: null,
            teamRegionId: undefined
        });

        // this.teamRegionId = this.user.teamRegionId ? this.user.teamRegionId : null; // null = 'All'
        this.teamRegionId = null; // null = 'All'
        this.$_handleLoaderState(false);
    }
};
</script>
