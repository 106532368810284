var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-green" },
        [
          _c(
            "div",
            { staticClass: "card-icon" },
            [_c("md-icon", [_vm._v("shopping_basket")])],
            1
          ),
        ]
      ),
      _c("md-card-content", [
        !_vm.orders || _vm.orders.length === 0
          ? _c("p", { staticClass: "grayedout-text text-center" }, [
              _vm._v("\n            No orders found\n        "),
            ])
          : _c(
              "div",
              [
                _c(
                  "md-table",
                  {
                    staticClass: "context-menu-support custom-paginated-table",
                  },
                  [
                    _c(
                      "md-table-row",
                      [
                        _c("md-table-head", [_vm._v("Order Number")]),
                        _c("md-table-head", [_vm._v("Created Date")]),
                        _c("md-table-head", { attrs: { "col-span": "1" } }, [
                          _vm._v("Status"),
                        ]),
                      ],
                      1
                    ),
                    _vm._l(_vm.orders, function (order) {
                      return _c(
                        "md-table-row",
                        {
                          key: order.orderId,
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openOrder(order.orderId)
                            },
                          },
                        },
                        [
                          _c("md-table-cell", [
                            _vm._v(_vm._s(order.orderNumber)),
                          ]),
                          _c("md-table-cell", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  order.createdDate,
                                  _vm.DATE_TYPES.standardDate
                                )
                              )
                            ),
                          ]),
                          _c("md-table-cell", { attrs: { "col-span": "1" } }, [
                            _c(
                              "div",
                              {
                                class:
                                  "custom-badge order-status-" +
                                  order.status
                                    .toLowerCase()
                                    .replace(/\s+/g, ""),
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "inputs.orderStatuses." + order.status
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }