var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-panel map-search" },
    [
      _c("md-icon", [_vm._v("search")]),
      _c("google-autocomplete", {
        ref: "address",
        attrs: {
          id: "autoaddress",
          classname: "form-control autocomplete-input",
          placeholder: "Location",
          "should-focus": false,
        },
        on: { placechanged: _vm.handlePlaceChanged, keyup: _vm.handleKeyUp },
        model: {
          value: _vm.searchedPlace,
          callback: function ($$v) {
            _vm.searchedPlace = $$v
          },
          expression: "searchedPlace",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }