<template>
    <div v-if="selectedView == 'fixAddress'">
        <fix-address-view @selectedView="handleView" />
    </div>
    <div v-else>
        <stop-list @selectedView="handleView" />
    </div>
</template>

<script>
import StopList from './StopList';
import FixAddressView from './FixAddressView';

export default {
    name: 'StopsOverview',
    components: {
        FixAddressView,
        StopList
    },
    data() {
        return {
            selectedView: null
        };
    },
    methods: {
        handleView(value) {
            this.selectedView = value;
            this.$router.replace({ path: this.$route.path, query: null });
        }
    }
};
</script>
