<template>
    <div>
        <div v-if="!isListLoading">
            <md-table class="custom-paginated-table">
                <md-table-row>
                    <md-table-head></md-table-head>
                    <md-table-head>Name</md-table-head>
                    <md-table-head>Role</md-table-head>
                    <md-table-head>Phone</md-table-head>
                    <md-table-head>Tracking</md-table-head>
                    <md-table-head>Date Joined</md-table-head>
                    <md-table-head>Device</md-table-head>
                    <md-table-head>App Version</md-table-head>
                    <md-table-head>Status</md-table-head>
                    <md-table-head>2FA Enabled</md-table-head>
                    <md-table-head v-if="isServiceAreaVisible()">Service areas</md-table-head>
                    <md-table-head v-if="isTeamRegionVisible()">Team region</md-table-head>
                    <md-table-head v-if="isVehicleCapacityVisible('weight')">
                        Weight ({{ getVehicleCapacitySymbol('weight') }})
                    </md-table-head>
                    <md-table-head v-if="isVehicleCapacityVisible('volume')">
                        Volume ({{ getVehicleCapacitySymbol('volume') }})
                    </md-table-head>
                    <md-table-head v-if="isVehicleCapacityVisible('quantity')">
                        Quantity ({{ getVehicleCapacitySymbol('quantity') }})
                    </md-table-head>
                    <md-table-head>Actions</md-table-head>
                </md-table-row>

                <md-table-row v-for="(item, index) in memberList" :key="index">
                    <md-table-cell>
                        <router-link
                            v-if="loggedInUserEmail != item.email"
                            :to="{
                                name: 'Team Member Profile',
                                params: { publicUserId: item.publicUserId }
                            }"
                        >
                            <img
                                v-if="item.photoUrl"
                                class="circle-profile-image"
                                :src="item.photoUrl"
                                @error="$_setDefaultBrokenImage"
                                alt
                            />
                            <img v-else class="circle-profile-image" :src="$root.defaultPhotoUrl" alt />
                        </router-link>
                        <router-link
                            v-else
                            :to="{
                                name: 'User Profile'
                            }"
                        >
                            <img
                                v-if="item.photoUrl"
                                class="circle-profile-image"
                                :src="item.photoUrl"
                                @error="$_setDefaultBrokenImage"
                                alt
                            />
                            <img v-else class="circle-profile-image" :src="$root.defaultPhotoUrl" alt />
                        </router-link>
                    </md-table-cell>
                    <md-table-cell md-label="Name">
                        <router-link
                            v-if="loggedInUserEmail != item.email"
                            class="custom-a-blue"
                            :to="{
                                name: 'Team Member Profile',
                                params: { publicUserId: item.publicUserId }
                            }"
                        >
                            {{ item.fullName }}
                        </router-link>
                        <router-link
                            v-else
                            class="custom-a-blue"
                            :to="{
                                name: 'User Profile'
                            }"
                        >
                            {{ item.fullName }}
                        </router-link>
                    </md-table-cell>
                    <md-table-cell md-label="Role" md-sort>
                        {{ item.role }}
                    </md-table-cell>
                    <md-table-cell md-label="Phone">
                        {{ item.phone }}
                        <send-sms-button v-if="item.phone" :public-user-id="item.publicUserId" :name="item.fullName" />
                    </md-table-cell>
                    <md-table-cell md-label="Tracking">
                        <div class="custom-tooltip-container">
                            <tracking-location-data-indicator :data="item.routeDetails" />

                            <span>
                                {{ item.gpsDataCollectionRule }}
                                <md-tooltip class="toggle-tooltip" md-direction="top" v-if="item.gpsDataCollectionRule">
                                    {{ getGpsDataCollectionRuleDescription(item.gpsDataCollectionRule) }}
                                </md-tooltip>
                            </span>
                        </div>
                    </md-table-cell>
                    <md-table-cell md-label="Vehicle">
                        {{ item.dateJoined | dateFormat(DATE_TYPES.standardDate) }}
                    </md-table-cell>
                    <md-table-cell md-label="Device">{{ item.deviceType }} {{ item.osVersion }}</md-table-cell>
                    <md-table-cell md-label="AppVersion">
                        {{ item.appVersion }}
                    </md-table-cell>
                    <md-table-cell md-label="Status">
                        <span v-if="item.disabledDate != null">Disabled</span>
                    </md-table-cell>
                    <md-table-cell md-label="2FA">
                        <md-icon v-if="item.twoFactorEnabled">phonelink_lock</md-icon>
                        {{ item.twoFactorEnabled ? 'Active' : '' }}
                    </md-table-cell>
                    <md-table-cell v-if="isServiceAreaVisible()" md-label="Service area">
                        {{ getFormattedServiceAreas(item) }}
                    </md-table-cell>
                    <md-table-cell v-if="isTeamRegionVisible()" md-label="Team region">
                        {{ item.teamRegionName }}
                    </md-table-cell>
                    <md-table-cell v-if="isVehicleCapacityVisible('weight')" md-label="Weight">
                        {{ getVehicleCapacityValue(item, 'weight') }}
                    </md-table-cell>
                    <md-table-cell v-if="isVehicleCapacityVisible('volume')" md-label="Volume">
                        {{ getVehicleCapacityValue(item, 'volume') }}
                    </md-table-cell>
                    <md-table-cell v-if="isVehicleCapacityVisible('quantity')" md-label="Quantity">
                        {{ getVehicleCapacityValue(item, 'quantity') }}
                    </md-table-cell>
                    <md-table-cell md-label="Actions" class="action-buttons">
                        <router-link
                            tag="md-button"
                            title="Edit member"
                            :to="{
                                name: 'Team Member Profile',
                                params: { publicUserId: item.publicUserId }
                            }"
                            class="md-button md-primary md-just-icon md-round btn-size-27"
                        >
                            <md-icon>edit</md-icon>
                        </router-link>
                        <md-button
                            v-if="item.twoFactorEnabled"
                            title="Reset two factor setup"
                            class="md-info md-just-icon md-round btn-size-27"
                            @click="resetTwoFactorAuthentication(item.publicUserId)"
                        >
                            <md-icon>lock_reset</md-icon>
                        </md-button>
                        <md-button
                            v-if="item.disabledDate == null && loggedInUserEmail != item.email"
                            title="Disable Member"
                            class="md-warning md-just-icon md-round btn-size-27"
                            @click="updateTeamMemberStatus(item.publicUserId, index, 'disable')"
                        >
                            <md-icon>block</md-icon>
                        </md-button>
                        <md-button
                            v-if="item.disabledDate != null && loggedInUserEmail != item.email"
                            title="Enable Member"
                            class="md-success md-just-icon md-round btn-size-27"
                            @click="updateTeamMemberStatus(item.publicUserId, index, 'enable')"
                        >
                            <md-icon>how_to_reg</md-icon>
                        </md-button>
                        <md-button
                            v-if="loggedInUserEmail != item.email"
                            title="Remove Member"
                            class="md-danger md-just-icon md-round btn-size-27"
                            @click="handleRemoveMember(item.publicUserId)"
                        >
                            <md-icon>delete</md-icon>
                        </md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>

            <div v-if="memberList.length == 0 && !isLoading">
                <p class="no-result-message">No results matching your search/filter could be found.</p>
            </div>

            <md-card-actions class="page-footer" md-alignment="space-between">
                <div>
                    <p v-if="total === pagination.perPage" class="card-category">
                        Page {{ pagination.currentPage }} of many
                    </p>
                    <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                </div>
                <pagination
                    v-model="pagination.currentPage"
                    class="pagination-no-border pagination-success"
                    :per-page="pagination.perPage"
                    :total="total"
                    @change-page="handleChangePage($event, pagination.perPage)"
                />
            </md-card-actions>
        </div>
        <div v-else>
            <div class="body-list">
                <div class="list-loader">
                    <fade-loader :loading="isListLoading" color="#333333" />
                    <span>LOADING</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Pagination, TrackingLocationDataIndicator, SendSmsButton } from '@/components';
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { GPS_DATA_COLLECTION_RULE_CONSTANTS } from '@/utils/constants';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { handleRequests, showErrorMessage } from '@/helpers';

export default {
    name: 'MembersTable',
    components: {
        Pagination,
        FadeLoader,
        TrackingLocationDataIndicator,
        SendSmsButton
    },
    mixins: [GeneralMixin],
    props: {
        loggedInUserEmail: {
            type: String,
            default: () => null
        },
        showDisabledMembers: {
            type: Boolean,
            default: false
        },
        newUser: {
            type: String,
            default: ''
        },
        searchText: {
            type: String,
            default: ''
        },
        teamRegionId: {
            type: Number,
            default: null // none selected
        },
        memberRole: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            pagination: PAGINATION_DEFAULTS,
            memberList: [],
            filters: { isDisabled: this.showDisabledMembers, teamRegionId: null, memberRole: null },
            maxPage: 1,
            isListLoading: false
        };
    },
    watch: {
        showDisabledMembers(newValue) {
            this.handleFilterOrSearch({ isDisabled: newValue });
        },
        newUser() {
            this.handleChangePage();
        },
        searchText(newValue) {
            this.handleFilterOrSearch({ searchText: newValue });
        },
        teamRegionId(newValue) {
            const oldTeamRegionId = this.filters.teamRegionId ? this.filters.teamRegionId : null;
            if (newValue !== oldTeamRegionId) {
                this.filters.teamRegionId = newValue;
                this.handleFilterOrSearch({ teamRegionId: newValue });
            }
        },
        memberRole(newValue, oldValue) {
            const newSelectedRole = newValue ? newValue.id : null;
            const oldSelectedRole = oldValue ? oldValue.id : null;
            if (newSelectedRole !== oldSelectedRole) {
                const selectedRoleName = newValue && newSelectedRole ? newValue.name : null;
                this.filters.memberRole = selectedRoleName;
                this.handleFilterOrSearch({ memberRole: selectedRoleName });
            }
        }
    },
    async mounted() {
        // keep previous value if coming from another tab,
        // this.filters.teamRegionId = this.teamRegionId || (this.user.teamRegionId ? this.user.teamRegionId : null); // null = All
        this.filters.teamRegionId = this.teamRegionId;
        this.isListLoading = true;
        let currentPage = 1;
        const { type } = this.$route.params;
        if (type === 'team-members') {
            currentPage = Number(this.$route.query.currentPage) || 1;
        }
        const {
            data: { members, totalMembers }
        } = await this.fetchTeamMemberList(currentPage);

        this.pagination.currentPage = currentPage;

        this.assignToList(members, totalMembers || members.length);
        this.isListLoading = false;
    },
    methods: {
        async fetchTeamMemberList(pageNumber = 1, itemsPerPage = 50) {
            const endpoint = `/api/team-members/list`;
            const response = await handleRequests(endpoint, {
                params: {
                    pageNumber,
                    itemsPerPage,
                    ...this.cleanFilters()
                }
            });
            return response;
        },
        assignToList(members, totalMembers) {
            this.memberList = members;
            this.pagination.total = totalMembers;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },
        getMaxPage(page) {
            this.maxPage = page;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.$_handleLoaderState(true);
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            const {
                data: { members, totalMembers }
            } = await this.fetchTeamMemberList(currentPage, perPage);
            const membersToAssign = totalMembers || members.length;
            this.assignToList(members, membersToAssign);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            this.$_handleLoaderState(false);
        },
        async handleFilterOrSearch(val) {
            this.isListLoading = true;
            this.filters = Object.assign(this.filters, val);
            this.pagination.currentPage = 1;
            const {
                data: { members, totalMembers }
            } = await this.fetchTeamMemberList(1, this.pagination.perPage);
            const membersToAssign = totalMembers || members.length;
            this.assignToList(members, membersToAssign);
            this.isListLoading = false;
            this.$emit('on-filter-change', this.filters);
        },
        updateTeamMemberStatus(publicUserId, index, type) {
            this.$_handleLoaderState(true, 'UPDATING...');
            const payload = {
                method: 'post'
            };
            const api = `/api/team-members/${publicUserId}/${type}`;
            handleRequests(api, payload).then(
                (response) => {
                    this.$_handleLoaderState(false);
                    this.$notify({
                        message: `Member ${type}d!`,
                        type: 'success'
                    });

                    this.handleChangePage(1, this.pagination.perPage);
                },
                (error) => {
                    this.$_handleLoaderState(false);
                    const message = 'Error in enabling/disabling member';
                    showErrorMessage(this, message, error);
                }
            );
        },
        resetTwoFactorAuthentication(publicUserId) {
            this.$_handleLoaderState(true, 'RESETTING...');
            const payload = {
                method: 'post'
            };
            const api = `/api/team-members/${publicUserId}/two-factor-authentication/reset`;
            handleRequests(api, payload).then(
                (response) => {
                    this.$_handleLoaderState(false);
                    this.$notifySuccess('Successfully reset their two factor authentication setup!');
                    this.handleChangePage(1, this.pagination.perPage);
                },
                (error) => {
                    this.$_handleLoaderState(false);
                    const message = 'Error in resetting their two factor authentication';
                    showErrorMessage(this, message, error);
                }
            );
        },
        handleRemoveMember(publicUserId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Remove user from team',
                    body: 'Are you sure you want to remove this user from the team?',
                    buttons: ['Yes', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'yes') 
                        await this.removeMember(publicUserId);
                });
        },
        async removeMember(publicUserId) {
            const disableUser = await this.validateTeamMemberDelete(publicUserId);

            if (!disableUser) {
                this.$_handleLoaderState(true, 'DELETING...');

                try {
                    const api = `/api/team-members/${publicUserId}`;
                    await handleRequests(api, { method: 'delete' });
                    this.$notify({
                        message: `Successfully removed member from the team.`,
                        type: 'success'
                    });
                    this.handleChangePage(1, this.pagination.perPage);
                } catch (error) {
                    const message = 'Error in removing member';
                    showErrorMessage(this, message, error);
                }
                this.$_handleLoaderState(false);
            } else {
                this.$messageBox
                    .show({
                        class: 'sm-modal-container',
                        title: 'Pending jobs for invoice',
                        body: 'User has pending jobs that may be required for invoicing, User will be disabled.',
                        buttons: ['Ok', 'Cancel']
                    })
                    .then(async (response) => {
                        if (response.toLowerCase() === 'ok') {
                            this.$_handleLoaderState(true, 'DISABLING...');

                            try {
                                const api = `/api/team-members/${publicUserId}/disable`;
                                await handleRequests(api, { method: 'post' });
                            } catch (error) {
                                const message = 'Error in disabling member';
                                showErrorMessage(this, message, error);
                            } finally {
                                this.$_handleLoaderState(false);
                                this.handleChangePage(1, this.pagination.perPage);
                            }
                        }
                    });
            }
        },
        getGpsDataCollectionRuleDescription(type) {
            const rule = GPS_DATA_COLLECTION_RULE_CONSTANTS.find((x) => x.name === type);

            if (rule) 
                return rule.description;

            return '';
        },
        async validateTeamMemberDelete(publicUserId) {
            this.$_handleLoaderState(true, 'VALIDATING...');

            try {
                const api = `/api/team-members/${publicUserId}/validateDeleteUser`;
                const response = await handleRequests(api);
                this.$_handleLoaderState(false);
                return response.data;
            } catch (error) {
                const message = 'Error in removing member';
                showErrorMessage(this, message, error);
            }

            this.$_handleLoaderState(false);
            return false;
        },
        getFormattedServiceAreas(item) {
            if (Array.isArray(item.serviceAreas)) {
                return item.serviceAreas.join(',');
            }
            return '';
        },
        getVehicleCapacityValue(item, capacityType) {
            const { vehicleCapacity } = item;
            if (
                vehicleCapacity &&
                typeof vehicleCapacity === 'object' &&
                Object.prototype.hasOwnProperty.call(vehicleCapacity, capacityType)
            ) {
                return item.vehicleCapacity[capacityType];
            }
            return '';
        },
        isVehicleCapacityVisible(capacityType) {
            const { vehicleCapacityUnitsConfiguration } = this.user;
            return (
                vehicleCapacityUnitsConfiguration &&
                Array.isArray(vehicleCapacityUnitsConfiguration) &&
                vehicleCapacityUnitsConfiguration.some((x) => x.type === capacityType)
            );
        },
        getVehicleCapacitySymbol(capacityType) {
            const { vehicleCapacityUnitsConfiguration } = this.user;

            if (!Array.isArray(vehicleCapacityUnitsConfiguration)) {
                return '';
            }

            const capacity = vehicleCapacityUnitsConfiguration.find((x) => x.type === capacityType);
            return capacity ? capacity.symbol : '';
        },
        isServiceAreaVisible() {
            const { user } = this;
            return Array.isArray(user?.serviceAreasOptions) && user.serviceAreasOptions.length > 0;
        },
        isTeamRegionVisible() {
            const { teamRegions } = this;
            return Array.isArray(teamRegions) && teamRegions.length > 0;
        }
    },
    computed: {
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        ...mapGetters({
            isLoading: 'isLoading',
            user: 'user/user',
            teamRegions: 'team/teamRegions'
        })
    }
};
</script>

<style lang="scss" scoped>
::v-deep table > tbody > tr > td:first-child {
    width: 32px !important;
    padding: 0;
}

::v-deep table > tbody > tr > td:last-child {
    width: 120px !important;
}

.table .md-round {
    margin: 0 3px;
}

.custom-tooltip-container {
    display: inline-block;
}

.md-tooltip-top {
    &.toggle-tooltip {
        // offset for pooper.js
        margin-left: 20px !important;
    }
}
</style>
