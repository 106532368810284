var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-warning" },
        [
          _c(
            "div",
            { staticClass: "card-icon" },
            [_c("md-icon", [_vm._v("collections_bookmark")])],
            1
          ),
        ]
      ),
      _c(
        "md-card-content",
        [
          _vm.carrierBookingHistory && _vm.carrierBookingHistory.length
            ? [
                _c(
                  "time-line",
                  { attrs: { plain: "", type: "simple" } },
                  _vm._l(_vm.carrierBookingHistory, function (history, index) {
                    return _c(
                      "time-line-item",
                      {
                        key: index,
                        attrs: {
                          inverted: "",
                          "is-clickable": false,
                          "is-image": true,
                          "image-src":
                            history.carrierTeam && history.carrierTeam.logoUrl
                              ? history.carrierTeam.logoUrl
                              : _vm.$root.defaultLogoUrl,
                        },
                      },
                      [
                        history.status
                          ? _c(
                              "span",
                              {
                                staticClass: "custom-badge status-pending",
                                attrs: { slot: "header" },
                                slot: "header",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(history.status) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "custom-badge status-cancelled",
                                attrs: { slot: "header" },
                                slot: "header",
                              },
                              [
                                _vm._v(
                                  "\n                        Error\n                    "
                                ),
                              ]
                            ),
                        _c(
                          "span",
                          {
                            staticClass: "time",
                            attrs: { slot: "headerTime" },
                            slot: "headerTime",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("dateTimeFormat")(
                                    history.updatedDate
                                      ? history.updatedDate
                                      : history.createdDate,
                                    _vm.DATE_TYPES.standardDate
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _c(
                          "h6",
                          { attrs: { slot: "footer" }, slot: "footer" },
                          [
                            _c("i", { staticClass: "ti-time" }),
                            _vm._v(
                              "\n\n                        " +
                                _vm._s(
                                  history.carrierTeam
                                    ? history.carrierTeam.company
                                    : "-"
                                ) +
                                "\n                        "
                            ),
                            history.error && history.error.message
                              ? _c(
                                  "span",
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(history.error.message) +
                                        "\n                            "
                                    ),
                                    _c(
                                      "md-button",
                                      {
                                        class:
                                          "md-danger md-just-icon md-round error-button",
                                        attrs: { title: "Show details" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.showErrorModal(history)
                                          },
                                        },
                                      },
                                      [
                                        _c("md-icon", [
                                          _vm._v("priority_high"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ]
            : [
                _c("p", { staticClass: "grayedout-text" }, [
                  _vm._v("No Status History Available"),
                ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }