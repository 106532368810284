<template>
    <div :class="shipment && shipment.selectedCarrierQuote ? 'quote-dropdown-container' : ''">
        <span
            v-if="!isBestQuote && shipment && shipment.selectedCarrierQuote && shipment.quotingStatus != 'JobAssigned'"
            class="close-button"
            @click.stop="handleRemove"
        >
            <md-icon>cancel</md-icon>
            <md-tooltip md-direction="bottom">Clicking this button will revert back to the best quote</md-tooltip>
        </span>
        <drop-down
            :should-close="shouldCloseDropdown"
            @closeDropdown="(val) => (this.shouldCloseDropdown = val)"
            @closeOtherDropDown="closeOtherDropDown"
        >
            <div
                v-if="shipment && shipment.selectedCarrierQuote"
                class="status carrier-text"
                slot="title"
                data-toggle="dropdown"
            >
                <div
                    @click="handleClick"
                    :class="
                        shipment.selectedCarrierQuote != null && shipment.selectedCarrierQuote.isExpired
                            ? 'expired'
                            : ''
                    "
                >
                    <span class="profile-image">
                        <img
                            :src="
                                shipment.selectedCarrierQuote.carrierTeam.logoUrl !== null
                                    ? shipment.selectedCarrierQuote.carrierTeam.logoUrl
                                    : $root.defaultLogoUrl
                            "
                            alt="avatar"
                        />
                    </span>
                    {{ selectedQuoteText }}
                    <md-tooltip v-show="shipment.selectedCarrierQuote.isExpired" md-direction="left">
                        This quote has already expired. To get a new quote please press the refresh button.
                    </md-tooltip>
                </div>
            </div>
            <span v-else class="unassigned" slot="title" :class="totalQuotes.length > 0 ? '' : 'no-quotes'">
                {{ totalQuotes.length > 0 ? totalQuotes.length + ' quotes available' : 'No quotes available' }}
            </span>
            <ul
                class="dropdown-menu"
                :class="shipment.bestQuote != null ? 'carriers-dropdown-menu' : ''"
                v-if="shipment.bestQuote != null"
            >
                <li class="carrier-title loading-text" v-if="dropdownLoading">Loading...</li>
                <li class="carrier-title" v-if="totalQuotes.length > 0 && !dropdownLoading">Carriers</li>
                <template v-if="totalQuotes.length > 0 && !dropdownLoading">
                    <li
                        v-for="(quote, index) in validQuotes"
                        :key="index"
                        @click.stop="handleSelectQuote(quote)"
                        :class="quote.isExpired ? 'expired-quote' : ''"
                    >
                        <div class="righthand-side">
                            <div class="carrier-image">
                                <img
                                    v-if="quote.carrierTeam.logoUrl != null"
                                    class="profile-image"
                                    :src="quote.carrierTeam.logoUrl"
                                    @error="$_setDefaultBrokenImage"
                                    alt="avatar"
                                />
                                <img
                                    v-else
                                    class="profile-image"
                                    :src="$root.defaultLogoUrl"
                                    @error="$_setDefaultBrokenImage"
                                    alt="avatar"
                                />
                            </div>
                            <div class="carrier-delivery-details">
                                <div class="carrier-name custom-ellipsis">{{ quote.carrierTeam.company }}</div>
                                <div class="carrier-speed">{{ $t(quote.serviceLevel) }}</div>
                            </div>
                        </div>
                        <div class="carrier-pricing">
                            {{ quote.amountAfterTax | currency(quote.currency) }}
                        </div>
                    </li>
                    <li v-if="totalQuotes.length && totalQuotes.length > 3" class="see-more" @click="showQuotesModal">
                        See all {{ totalQuotes.length }} quotes...
                    </li>
                    <li v-if="totalQuotes.length && totalQuotes.length <= 3" class="see-more" @click="showQuotesModal">
                        See previous quotes...
                    </li>
                </template>
            </ul>
        </drop-down>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { QuotesModal } from '@/components';

export default {
    name: 'SelectShipmentDropdown',
    mixins: [GeneralMixin],
    props: {
        shipment: {
            type: Object,
            default: () => {}
        },
        teamMembers: {
            type: Array,
            default: () => []
        },
        className: {
            type: String,
            default: () => ''
        },
        isQuotesView: { type: Boolean, default: () => false }
    },
    data() {
        return {
            shouldCloseDropdown: false,
            quoteOptions: [],
            isBestQuote: false,
            dropdownLoading: false,
            expired: false
        };
    },
    mounted() {
        this.refreshExpiry();
        setInterval(this.refreshExpiry.bind(this), 1000);
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            user: 'user/user',
            allTeamCarriers: 'team/teamCarriers'
        }),
        selectedQuoteText() {
            if (this.shipment.selectedCarrierQuote) {
                if (this.shipment.bestQuote.carrierQuoteId === this.shipment.selectedCarrierQuote.carrierQuoteId) {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.isBestQuote = true;
                } else {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.isBestQuote = false;
                }

                return this.quoteItemText(this.shipment.selectedCarrierQuote);
            }

            return '';
        },
        assignee() {
            if (this.shipment.assignedTo && this.shipment.assignedTo.publicUserId) {
                return {
                    name: this.shipment.assignedTo.fullName,
                    photoUrl: this.shipment.assignedTo.photoUrl
                };
            }
            if (this.shipment.carrierTeam) {
                return {
                    name: this.shipment.carrierTeam.company,
                    photoUrl: this.shipment.carrierTeam.logoUrl
                };
            }
            return null;
        },
        validQuotes() {
            if (!this.shipment || this.shipment.carrierTeam?.carrierTeamId || !this.shipment.quotes) 
                return [];

            let quotes = this.shipment.quotes.filter((x) => x.error == null && !x.isArchived);

            quotes.forEach((quote) => {
                quote.isExpired = moment().isAfter(moment.utc(quote.expiryDate).local());
            });

            quotes = quotes.sort((x, y) => x.amountAfterTax - y.amountAfterTax);
            return quotes.slice(0, 3);
        },
        totalQuotes() {
            if (!this.shipment || this.shipment.carrierTeam?.carrierTeamId || !this.shipment.quotes) 
                return [];

            const quotes = this.shipment.quotes.filter((x) => x.error == null && !x.isArchived);

            return quotes;
        }
    },
    watch: {
        'shipment.quotes': function() {
            if (!this.shipment.quotes) 
                return;

            this.dropdownLoading = false;
        },
        'shipment.bestQuote': function(value) {
            if (this.shipment.selectedCarrierQuote == null) {
                this.$emit('quoteSelected', { shipmentId: this.shipment.shipmentId, quote: value });
            } else {
                // eslint-disable-next-line no-lonely-if
                if (value.carrierQuoteId !== this.shipment.selectedCarrierQuote.carrierQuoteId) {
                    this.$emit('quoteSelected', { shipmentId: this.shipment.shipmentId, quote: value });
                }
            }
        }
    },
    methods: {
        refreshExpiry() {
            if (this.shipment.selectedCarrierQuote == null || this.shipment.selectedCarrierQuote.expiryDate == null) {
                return;
            }

            this.shipment.selectedCarrierQuote.isExpired = moment().isAfter(
                moment.utc(this.shipment.selectedCarrierQuote.expiryDate).local()
            );
        },
        handleRemove() {
            if (this.shipment.bestQuote != null) {
                this.$emit('quoteSelected', { shipmentId: this.shipment.shipmentId, quote: this.shipment.bestQuote });
                this.isBestQuote = true;
            } else {
                this.$emit('quoteSelected', { shipmentId: this.shipment.shipmentId, quote: null });
                this.isBestQuote = false;
            }

            this.shouldCloseDropdown = true;
        },
        quoteItemText(quote) {
            return `${quote.carrierTeam?.company}`;
        },
        showQuotesModal() {
            this.$modal
                .show(QuotesModal, {
                    shipment: this.shipment,
                    isBulk: true
                })
                .then((response) => {
                    if (response) {
                        this.handleSelectQuote(response);
                    }
                    this.$modal.hide();
                });
            this.$emit('showQuotesModal');
        },
        handleClick() {
            this.dropdownLoading = true;
            this.$emit('getQuotes', this.shipment.shipmentId);
        },
        async handleSelectQuote(quote) {
            if (quote.isExpired) 
                return;

            if (this.shipment.bestQuote == null) {
                this.isBestQuote = false;
            } else {
                // eslint-disable-next-line no-lonely-if
                if (quote.carrierQuoteId === this.shipment.bestQuote.carrierQuoteId) {
                    this.isBestQuote = true;
                } else {
                    this.isBestQuote = false;
                }
            }

            this.$emit('quoteSelected', { shipmentId: this.shipment.shipmentId, quote });
            this.shouldCloseDropdown = true;
        },
        closeOtherDropDown() {
            const dropdowns = document.querySelectorAll('.open');
            dropdowns.forEach((dropdown) => dropdown.classList.remove('open'));
        }
    }
};
</script>

<style lang="scss" scoped>
.status {
    min-width: 90px;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 160px;
    cursor: pointer;
}

.unassigned {
    color: #fff !important;
    background-color: #2b93ff !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 160px;
    cursor: pointer;
}

::v-deep .dropdown-menu {
    li {
        cursor: pointer !important;
    }
}

.carriers-dropdown-menu,
.carriers-dropdown-menu-loader {
    width: 300px;
    left: calc(100% - 300px);

    .carrier-title,
    .see-more {
        font-weight: 500;
        padding: 5px 20px;
        display: block;
    }

    .see-more {
        text-align: center;
        font-weight: 400;
        color: #aaa;
    }

    li {
        border-bottom: 1px solid #eee;
        display: table;
        width: 100%;
        > div {
            display: table-cell;
            vertical-align: middle;
            margin: 5px;
        }
    }

    li:hover {
        background-color: #eee;
    }

    li:first-child:hover {
        background-color: initial;
        cursor: default !important;
    }

    .righthand-side {
        display: table;
        width: 180px;
        width: 100%;
        > div {
            display: table-cell;
            vertical-align: middle;
        }

        > div:first-child {
            width: 40px;
        }
        .carrier-delivery-details {
            padding-left: 10px;
            max-width: 100px;
        }

        .carrier-name {
            font-weight: 700;
            font-size: 14px;
        }
        .carrier-speed {
            font-weight: 500px;
            font-size: 10px;
        }
        .carrier-image {
            width: 100%;
        }
    }

    .carrier-pricing {
        padding: 0 10px;
        text-align: right;
        font-size: 13px;
    }

    .loading-text {
        border-bottom: none;
        text-align: center;
    }
}

.profile-image {
    margin-right: 5px;
    display: inline-block;
    img {
        object-fit: contain;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #eee;
    }
}

.zero-quote-container {
    font-size: 12px;
    font-weight: 500;
    color: #aaa;
    text-align: center;
    padding: 10px;
    display: block;
    border-top: 1px solid #eee;
}

.custom-ellipsis {
    max-width: 400px;
    min-width: 250px;
}

.no-quotes {
    background-color: #ddd !important;
    color: #aaa !important;
}

.close-button {
    line-height: 18px;
    padding-right: 5px;
    i {
        color: red !important;
    }
}

.close-button:hover {
    i {
        color: #aaa !important;
    }
}

.carrier-text {
    font-weight: 300;
    max-width: initial;
    > div {
        display: inline-block;
        line-height: 20px;
    }
}

.carrier-text:hover {
    color: #2b93ff !important;
}

.quote-dropdown-container {
    display: table;
    .close-button {
        display: table-cell;
        vertical-align: middle;
    }
}

.expired-quote {
    .carrier-pricing,
    .carrier-name,
    .carrier-speed {
        text-decoration: line-through;
    }
    color: #ddd;
}

.expired-quote:hover {
    cursor: not-allowed !important;
}

.expired {
    color: #aaa !important;
    text-decoration: line-through;
}
</style>
