var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white-bg" }, [
    _c("div", { staticClass: "mobile-filter-header" }, [
      _c("div", { staticClass: "md-layout" }, [
        _c("div", { staticClass: "md-layout-item md-xsmall-size-40" }, [
          _c(
            "div",
            { staticClass: "datepicker-container" },
            [
              _c("md-datepicker", {
                ref: "mdDatePicker",
                attrs: {
                  "md-immediately": "",
                  "md-debounce": 100,
                  "md-model-type": String,
                },
                model: {
                  value: _vm.selectedDate,
                  callback: function ($$v) {
                    _vm.selectedDate = $$v
                  },
                  expression: "selectedDate",
                },
              }),
              _c("span", { staticClass: "heavy-font-600" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(
                      _vm._f("dateFormat")(
                        this.selectedDate,
                        _vm.DATE_TYPES.standardDate
                      )
                    ) +
                    "\n                    "
                ),
              ]),
            ],
            1
          ),
        ]),
        !_vm.isSingleUser && !_vm.isSingleTeamMember
          ? _c("div", { staticClass: "md-layout-item md-xsmall-size-50" }, [
              _c("div", { staticClass: "heavy-font-600 filter-btn" }, [
                _c(
                  "span",
                  {
                    staticClass: "filter-teammember",
                    on: { click: _vm.filterByTeamMember },
                  },
                  [_vm._v(_vm._s(_vm.selectedTeamMember.name))]
                ),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "md-layout-item md-xsmall-size-10" }, [
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  _vm.showFilter = !_vm.showFilter
                },
              },
            },
            [
              _vm.showFilter
                ? _c("md-icon", [_vm._v("search_off")])
                : _c("md-icon", [_vm._v("search")]),
            ],
            1
          ),
        ]),
      ]),
      _vm.showFilter
        ? _c("div", { staticClass: "md-layout" }, [
            _c("div", { staticClass: "search-bar" }, [
              _c("input", {
                directives: [
                  { name: "focus", rawName: "v-focus" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchText,
                    expression: "searchText",
                  },
                ],
                attrs: {
                  type: "text",
                  placeholder: "Search by name or stop reference",
                },
                domProps: { value: _vm.searchText },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchText = $event.target.value
                  },
                },
              }),
            ]),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "md-layout" }, [
      _c("div", { staticClass: "md-layout-item stoplist-container" }, [
        !_vm.loading
          ? _c("div", [
              _vm.searchStopList.length
                ? _c(
                    "div",
                    _vm._l(_vm.searchStopList, function (item) {
                      return _c(
                        "div",
                        { key: item.stopId, staticClass: "stop-item" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "item-details",
                              class:
                                item.status == "Complete"
                                  ? "completed-stop"
                                  : "",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item-status-icon" },
                                [
                                  _c(
                                    "md-button",
                                    {
                                      staticClass: "md-just-icon md-round",
                                      class:
                                        "status-" + item.status.toLowerCase(),
                                    },
                                    [_c("md-icon", [_vm._v("place")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Stop Details",
                                          params: { stopId: item.stopId },
                                        },
                                      },
                                    },
                                    [
                                      item.contact != null
                                        ? _c("div", [
                                            _vm._v(_vm._s(item.contact.name)),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "heavy-font-600" },
                                        [_vm._v(_vm._s(item.address))]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          item.status != "Complete"
                            ? _c(
                                "div",
                                { staticClass: "item-actions" },
                                [
                                  !_vm.isSingleTeamMember && !_vm.isSingleUser
                                    ? _c("reassign-stop-button", {
                                        attrs: {
                                          item: item,
                                          "team-members": _vm.teamMembers,
                                        },
                                        on: {
                                          assignedUser: _vm.handleFilteredValue,
                                        },
                                      })
                                    : _vm._e(),
                                  item.originalEta == null ||
                                  (item.originalEta != null &&
                                    _vm.today != _vm.selectedDate)
                                    ? _c(
                                        "div",
                                        { staticClass: "heavy-font-600" },
                                        [
                                          item.timeWindowStart != null &&
                                          item.timeWindowEnd != null
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                    Due: " +
                                                    _vm._s(
                                                      _vm._f("timeFormat")(
                                                        item.timeWindowStart
                                                      )
                                                    ) +
                                                    " -\n                                    " +
                                                    _vm._s(
                                                      _vm._f("timeFormat")(
                                                        item.timeWindowEnd
                                                      )
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ])
                                            : item.appointmentTime != null
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                    Due: " +
                                                    _vm._s(
                                                      _vm._f("timeFormat")(
                                                        item.appointmentTime
                                                      )
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "heavy-font-600" },
                                        [
                                          _vm.today == _vm.selectedDate &&
                                          item.originalEta != null &&
                                          item.status != "Arrived" &&
                                          item.status != "Departed" &&
                                          item.status != "Complete"
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                    ETA: " +
                                                    _vm._s(
                                                      _vm._f("timeFormat")(
                                                        item.originalEta
                                                      )
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "md-layout-item" }, [
                    _c(
                      "span",
                      { staticClass: "text-center no-result-message" },
                      [_vm._v("No available stops to display.")]
                    ),
                  ]),
            ])
          : _c("div", [
              !_vm.isLoading
                ? _c(
                    "div",
                    { staticClass: "stopbar-loader" },
                    [
                      _c("fade-loader", {
                        attrs: { loading: true, color: "#333333" },
                      }),
                      _c("span", [_vm._v("LOADING")]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }