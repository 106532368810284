var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "drop-down",
    {
      staticClass: "custom-button-container",
      attrs: { "should-close": _vm.shouldCloseDropdown },
      on: {
        closeDropdown: function (val) {
          return (this$1.shouldCloseDropdown = val)
        },
        closeOtherDropDown: _vm.closeOtherDropDown,
      },
    },
    [
      _c(
        "md-button",
        {
          staticClass: "md-info md-just-icon md-round custom-btn",
          attrs: { title: "Re-assign driver" },
        },
        [_c("md-icon", [_vm._v("person_pin")])],
        1
      ),
      this.isAssignedStops
        ? _c(
            "ul",
            { staticClass: "dropdown-menu" },
            _vm._l(_vm.orderedStopList, function (item, index) {
              return _c("li", { key: index }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleReassignStop(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.teamMember.fullName) +
                        "\n            "
                    ),
                  ]
                ),
              ])
            }),
            0
          )
        : _c(
            "ul",
            { staticClass: "dropdown-menu" },
            _vm._l(_vm.teamMembers, function (item, index) {
              return _c("li", { key: index }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleReassignStop(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.fullName) +
                        "\n            "
                    ),
                  ]
                ),
              ])
            }),
            0
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }