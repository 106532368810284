var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.isUpdate ? "Update" : "Create") + " Service"),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c("div", { staticClass: "alert alert-info alert-z" }, [
          _c("span", [
            _vm._v(
              "You are providing pricing for the '" +
                _vm._s(_vm.teamRegionName) +
                "' team region only."
            ),
          ]),
        ]),
        _c(
          "form-wrapper",
          {
            staticClass: "form-wrapper",
            attrs: { validator: _vm.$v.serviceDetails },
          },
          [
            _c(
              "div",
              { staticClass: "form-container service-form-container" },
              [
                _c(
                  "div",
                  { staticClass: "service-details" },
                  [
                    _c(
                      "form-group",
                      {
                        attrs: {
                          name: "name",
                          label: "Description",
                          attribute: "Service Description",
                          messages: _vm.localMessages,
                        },
                      },
                      [
                        _c("md-input", {
                          directives: [{ name: "focus", rawName: "v-focus" }],
                          attrs: { required: "" },
                          model: {
                            value: _vm.serviceDetails.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.serviceDetails, "name", $$v)
                            },
                            expression: "serviceDetails.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "form-group",
                      {
                        attrs: {
                          name: "quantityType",
                          label: "Quantity Type",
                          attribute: "Quantity Type",
                          messages: _vm.localMessages,
                        },
                      },
                      [
                        _c("md-input", {
                          model: {
                            value: _vm.serviceDetails.quantityType,
                            callback: function ($$v) {
                              _vm.$set(_vm.serviceDetails, "quantityType", $$v)
                            },
                            expression: "serviceDetails.quantityType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "md-layout rate-row" },
                      [
                        _c(
                          "span",
                          { staticClass: "md-layout-item rate-group" },
                          [_vm._v("Default")]
                        ),
                        _c(
                          "form-group",
                          {
                            staticClass: "md-layout-item price",
                            attrs: {
                              name: "unitPriceExTax",
                              label: "Unit Price (Ex. Tax)",
                              attribute: "Unit Price",
                              messages: _vm.localMessages,
                            },
                          },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.serviceDetails.unitPriceExTax,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.serviceDetails,
                                    "unitPriceExTax",
                                    $$v
                                  )
                                },
                                expression: "serviceDetails.unitPriceExTax",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "form-group",
                          {
                            staticClass: "md-layout-item cost",
                            attrs: {
                              name: "unitCostExTax",
                              label: "Unit Cost (Ex. Tax)",
                              attribute: "Unit Cost",
                              messages: _vm.localMessages,
                            },
                          },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.serviceDetails.unitCostExTax,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.serviceDetails,
                                    "unitCostExTax",
                                    $$v
                                  )
                                },
                                expression: "serviceDetails.unitCostExTax",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(
                      _vm.serviceDetails.serviceRates,
                      function (rate, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "md-layout rate-row" },
                          [
                            _c(
                              "span",
                              { staticClass: "md-layout-item rate-group" },
                              [_vm._v(_vm._s(rate.rateGroupName))]
                            ),
                            _c(
                              "form-group",
                              {
                                staticClass: "md-layout-item price",
                                attrs: {
                                  name: "serviceDetails.serviceRates.$each.0.unitPriceExTax.$model",
                                  label: "Unit Price (Ex. Tax)",
                                  attribute: "Unit Price",
                                  messages: _vm.localMessages,
                                },
                              },
                              [
                                _c("md-input", {
                                  model: {
                                    value: rate.unitPriceExTax,
                                    callback: function ($$v) {
                                      _vm.$set(rate, "unitPriceExTax", $$v)
                                    },
                                    expression: "rate.unitPriceExTax",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "form-group",
                              {
                                staticClass: "md-layout-item cost",
                                attrs: {
                                  name: "serviceDetails.serviceRates.$each.0.unitCostExTax.$model",
                                  label: "Unit Cost (Ex. Tax)",
                                  attribute: "Unit Cost",
                                  messages: _vm.localMessages,
                                },
                              },
                              [
                                _c("md-input", {
                                  model: {
                                    value: rate.unitCostExTax,
                                    callback: function ($$v) {
                                      _vm.$set(rate, "unitCostExTax", $$v)
                                    },
                                    expression: "rate.unitCostExTax",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.validateService },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isUpdate ? "Update" : "Create") +
                "\n        "
            ),
          ]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }