var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-dashboard" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c("div", { staticClass: "md-layout-item" }, [
        _c("div", { staticClass: "custom-toolbar" }, [
          _c(
            "div",
            { staticClass: "custom-toolbar-end" },
            [
              _c("search-component", {
                ref: "search",
                on: { onSearch: _vm.handleSearch },
              }),
              _c(
                "div",
                { staticClass: "header-button-container" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-primary md-just-icon md-round",
                      attrs: { title: "Create Invoice Adjustment Rule" },
                      on: { click: _vm.createRule },
                    },
                    [_c("md-icon", [_vm._v("library_add")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "md-layout" },
      [
        _c(
          "div",
          { staticClass: "md-layout-item" },
          [
            _c(
              "md-card",
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-green" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-icon running-load-menu" },
                      [
                        _c("drop-down", { attrs: { direction: "down" } }, [
                          _c(
                            "a",
                            {
                              staticClass: "load-header-text",
                              attrs: { href: "#" },
                            },
                            [
                              _c("md-icon", [_vm._v("receipt")]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.selectedOption != null
                                      ? _vm.selectedOption.label
                                      : ""
                                  ) +
                                  "\n                                "
                              ),
                              _c("md-icon", { staticClass: "icon-arrow" }, [
                                _vm._v("expand_more"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "ul",
                            { staticClass: "dropdown-menu" },
                            _vm._l(_vm.dropdownOptions, function (item) {
                              return _c("li", { key: item.key }, [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSelectedOption(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(item.label) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _c("md-card-content", { staticClass: "body-list" }, [
                  _vm.isListLoading
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "table-loader" },
                          [
                            _c("fade-loader", {
                              attrs: { loading: true, color: "#333333" },
                            }),
                            _c("span", [_vm._v("LOADING")]),
                          ],
                          1
                        ),
                      ])
                    : _c("div", [
                        _vm.ruleList && _vm.ruleList.length > 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "md-table",
                                  {
                                    staticClass:
                                      "context-menu-support custom-paginated-table",
                                  },
                                  [
                                    _c(
                                      "md-table-row",
                                      [
                                        _c("md-table-head", [
                                          _vm._v("Description"),
                                        ]),
                                        _c("md-table-head", [
                                          _vm._v("Unit Price"),
                                        ]),
                                        _c("md-table-head", [
                                          _vm._v("Rule Type"),
                                        ]),
                                        _c("md-table-head", [
                                          _vm._v("Actions"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._l(_vm.ruleList, function (item) {
                                      return _c(
                                        "md-table-row",
                                        {
                                          key: item.invoiceAdjustmentRuleId,
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.showSidebar(
                                                item.invoiceAdjustmentRuleId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("md-table-cell", [
                                            _vm._v(_vm._s(item.description)),
                                          ]),
                                          _vm.user.teamCurrency
                                            ? _c("md-table-cell", [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        item.unitPrice,
                                                        _vm.user.teamCurrency
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ])
                                            : _c("md-table-cell", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatCurrency(
                                                      item.unitPrice
                                                    )
                                                  )
                                                ),
                                              ]),
                                          _c("md-table-cell", [
                                            _vm._v(
                                              _vm._s(
                                                item.invoiceAdjustmentRuleTypeName
                                              )
                                            ),
                                          ]),
                                          _c("md-table-cell", [
                                            _c(
                                              "div",
                                              { staticClass: "actions" },
                                              [
                                                _c(
                                                  "md-button",
                                                  {
                                                    staticClass:
                                                      "md-warning md-just-icon md-round margin-right",
                                                    attrs: {
                                                      title:
                                                        "Update rule details",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.updateRule(
                                                          item.invoiceAdjustmentRuleId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("md-icon", [
                                                      _vm._v("edit"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "md-button",
                                                  {
                                                    staticClass:
                                                      "md-danger md-just-icon md-round",
                                                    attrs: {
                                                      title: "Delete rule",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleDeleteRule(
                                                          item.invoiceAdjustmentRuleId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("md-icon", [
                                                      _vm._v("delete"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : !_vm.isLoading
                          ? _c("div", [
                              _c("p", { staticClass: "no-result-message" }, [
                                _vm._v(
                                  "No results matching your search/filter could be found."
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                ]),
              ],
              1
            ),
            _c(
              "md-card-actions",
              {
                staticClass: "page-footer",
                attrs: { "md-alignment": "space-between" },
              },
              [
                _c("div", [
                  _vm.total === _vm.pagination.perPage
                    ? _c("p", { staticClass: "card-category" }, [
                        _vm._v(
                          "\n                        Page " +
                            _vm._s(_vm.pagination.currentPage) +
                            " of many\n                    "
                        ),
                      ])
                    : _c("p", { staticClass: "card-category" }, [
                        _vm._v(
                          "Page " +
                            _vm._s(_vm.pagination.currentPage) +
                            " of " +
                            _vm._s(_vm.totalPages)
                        ),
                      ]),
                ]),
                _c("pagination", {
                  staticClass: "pagination-no-border pagination-success",
                  attrs: {
                    "per-page": _vm.pagination.perPage,
                    total: _vm.total,
                  },
                  on: {
                    "change-page": function ($event) {
                      return _vm.handleChangePage(
                        $event,
                        _vm.pagination.perPage
                      )
                    },
                  },
                  model: {
                    value: _vm.pagination.currentPage,
                    callback: function ($$v) {
                      _vm.$set(_vm.pagination, "currentPage", $$v)
                    },
                    expression: "pagination.currentPage",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "transition",
          { attrs: { name: "slide" } },
          [
            _vm.isShowSidebar
              ? _c("invoice-adjustment-rule-sidebar", {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.toggleSidebarWindow,
                      expression: "toggleSidebarWindow",
                    },
                  ],
                  attrs: {
                    "invoice-adjustment-rule-id": _vm.invoiceAdjustmentRuleId,
                  },
                  on: { closeModal: _vm.toggleSidebarWindow },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }