var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ratings" },
    [
      _c("md-card", { staticClass: "card" }, [
        _c("h3", [_c("strong", [_vm._v("Customer reviews")])]),
        _c(
          "div",
          [
            _vm.totalRatings
              ? _c(
                  "div",
                  [
                    _vm._l(
                      parseInt(_vm.avgRatingRoundedOnHalfDecimal),
                      function (index) {
                        return _c(
                          "md-icon",
                          { key: index, staticClass: "orange-star" },
                          [
                            _vm._v(
                              "\n                    star\n                "
                            ),
                          ]
                        )
                      }
                    ),
                    _vm.avgRatingRoundedOnHalfDecimal !=
                    parseInt(_vm.avgRatingRoundedOnHalfDecimal)
                      ? _c("md-icon", { staticClass: "orange-star" }, [
                          _vm._v(
                            "\n                    star_half\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm._l(
                      parseInt(5 - _vm.avgRatingRoundedOnHalfDecimal),
                      function (index) {
                        return _c(
                          "md-icon",
                          { key: "ls" + index, staticClass: "orange-star" },
                          [
                            _vm._v(
                              "\n                    star_outline\n                "
                            ),
                          ]
                        )
                      }
                    ),
                    _c("span", { staticClass: "padding" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.avgRatingRoundedOnDecimal) + " out of 5"
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "padding" }, [
                      _vm._v(_vm._s(_vm.totalRatings) + " ratings"),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "div",
              { attrs: { else: "" } },
              [
                _vm.totalRatings === 0
                  ? _c("md-empty-state", {
                      attrs: {
                        "md-icon": "reviews",
                        "md-label": "No reviews",
                        "md-description":
                          "No reviews for the selected criteria.",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._l(5, function (index) {
              return _c(
                "div",
                {
                  key: "gr" + index,
                  class:
                    _vm.currentRating === 6 - index
                      ? "selected-line"
                      : "grid-wrapper",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.ratingSelected(6 - index)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "stars" }, [
                    _vm._v(_vm._s(6 - index) + " star"),
                  ]),
                  _c("div", { staticClass: "bar" }, [
                    _c("div", { staticClass: "progressbar" }, [
                      _c("div", {
                        style: { width: _vm.percents[5 - index] + "%" },
                      }),
                    ]),
                  ]),
                  _vm.totalRatings !== 0
                    ? _c("div", { staticClass: "percent" }, [
                        _vm._v(_vm._s(_vm.percents[5 - index]) + "%"),
                      ])
                    : _vm._e(),
                ]
              )
            }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }