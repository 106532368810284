var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gmnoprint-child" }, [
    _c(
      "button",
      {
        class: _vm.customClass,
        attrs: {
          draggable: "false",
          title: _vm.title,
          "aria-label": _vm.ariaLabel,
          type: "button",
          "aria-pressed": _vm.pressedState,
        },
        on: {
          click: function ($event) {
            return _vm.selectDrawing(_vm.type)
          },
        },
      },
      [
        _c("span", [
          _c("div", [
            _c("img", {
              attrs: {
                alt: "",
                src: _vm.$root.drawingToolIcon,
                draggable: "false",
              },
            }),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }