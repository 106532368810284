<template>
    <div>
        <div class="md-layout optimised-stop-contact-block">
            <div class="md-layout-item">
                <md-icon class="optimised-stop-contact-group-icon">
                    group
                </md-icon>
                <span class="left-margin">{{ content.contactName || '-' }}</span>
            </div>
            <div class="md-layout-item">
                <md-icon class="optimised-stop-contact-call-icon">
                    call
                </md-icon>
                <span class="left-margin">{{ content.contactPhone || '-' }}</span>
            </div>
        </div>
        <div v-if="hasCapacitySettings('quantity', 'volume', 'weight')" class="md-layout optimised-stop-capacity-block">
            <div v-if="hasCapacitySettings('quantity')" class="md-layout-item md-size-40">
                <md-icon>
                    <img class="capacity-images" src="/img/icons/capacity-items-gray.png" />
                </md-icon>
                <span>{{ getCapacityInfo(content, 'quantity', 'items') }}</span>
            </div>
            <div v-if="hasCapacitySettings('volume')" class="md-layout-item md-size-30">
                <md-icon>
                    <img class="capacity-images" src="/img/icons/capacity-volume-gray.png" />
                </md-icon>
                <span>{{ getCapacityInfo(content, 'volume', 'm3') }}</span>
            </div>
            <div v-if="hasCapacitySettings('weight')" class="md-layout-item md-size-30">
                <md-icon>
                    <img class="capacity-images" src="/img/icons/capacity-weight-gray.png" />
                </md-icon>
                <span>{{ getCapacityInfo(content, 'weight', 'kg') }}</span>
            </div>
        </div>
        <div v-if="hasSkillsSettings()" class="md-layout optimised-stop-skills-block">
            <div class="md-layout-item">
                <span>Skills:</span>
                <span>{{ getSkillsInfo() }}</span>
            </div>
        </div>
        <div v-if="hasServiceSettings()" class="md-layout optimised-stop-services-block">
            <div class="md-layout-item">
                <span>Services:</span>
                <span>{{ getServiceInfo() }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoWindowMoreInfoContent',
    props: {
        content: {
            type: Object,
            default: () => {}
        },
        getCapacityInfo: {
            type: Function,
            default: () => {}
        },
        hasCapacitySettings: {
            type: Function,
            default: () => {}
        },
        getSkillsInfo: {
            type: Function,
            default: () => {}
        },
        hasSkillsSettings: {
            type: Function,
            default: () => {}
        },
        getServiceInfo: {
            type: Function,
            default: () => {}
        },
        hasServiceSettings: {
            type: Function,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';
.left-margin {
    margin-left: 4px !important;
}

.optimised-stop-contact-block,
.optimised-stop-capacity-block {
    span {
        font-weight: 400;
    }
}

.optimised-stop-skills-block,
.optimised-stop-services-block {
    span:last-child {
        font-weight: 400;
    }
}

.optimised-stop-contact-block {
    > *:first-child {
        padding-right: 4px !important;
    }
    > *:last-child {
        padding-left: 0 !important;
    }
}

.optimised-stop-capacity-block {
    > *:first-child {
        padding-right: 4px !important;
    }
    > *:not(:first-child):not(:last-child) {
        padding-left: 0 !important;
        padding-right: 4px !important;
    }
    > *:last-child:not(:only-child) {
        padding-left: 0 !important;
    }
    span {
        margin-left: 7px;
    }
}

.optimised-stop-container {
    .md-layout {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.optimised-stop-contact-block:not(:last-child),
.optimised-stop-capacity-block:not(:last-child) {
    margin-bottom: 10px !important;
}

.capacity-images {
    border-radius: 50%;
    transform: scale(1.2);
}

.optimised-stop-contact-group-icon,
.optimised-stop-contact-call-icon {
    color: #000000 !important;
}

.optimised-stop-contact-group-icon {
    font-size: 22px !important;
}

.optimised-stop-contact-call-icon {
    font-size: 16px !important;
    width: 10px !important;
    min-width: 10px !important;
}
</style>
