var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "profile-container" }, [
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
      },
      [
        _c("div", { staticClass: "custom-toolbar" }, [
          _c(
            "div",
            { staticClass: "custom-toolbar-start" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "Team Settings",
                    },
                  },
                },
                [_c("h3", { staticClass: "title" }, [_vm._v("Team Settings")])]
              ),
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "Business Entities",
                    },
                  },
                },
                [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("   >    Business Entities"),
                  ]),
                ]
              ),
              _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "   >    " +
                    _vm._s(_vm.businessEntity.businessRegistrationName)
                ),
              ]),
            ],
            1
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-green" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("account_balance")])],
                    1
                  ),
                ]
              ),
              _c("md-card-content", [
                _c("div", { staticClass: "cf-container" }, [
                  _c(
                    "div",
                    { staticClass: "cf-action-container" },
                    [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-primary md-just-icon md-round",
                          attrs: { title: "Edit Business Entity" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.toggleEdit()
                            },
                          },
                        },
                        [_c("md-icon", [_vm._v("edit")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "businessEntity-information field-spacing-container",
                    },
                    [
                      _c(
                        "form-wrapper",
                        {
                          staticClass: "form-wrapper",
                          attrs: { validator: _vm.$v.businessEntity },
                        },
                        [
                          _c("div", { staticClass: "information-box" }, [
                            _c("div", { staticClass: "md-layout" }, [
                              _c(
                                "div",
                                { staticClass: "md-layout-item" },
                                [
                                  _c(
                                    "form-group",
                                    {
                                      attrs: {
                                        name: "businessName",
                                        label: "Business Name",
                                      },
                                    },
                                    [
                                      _c("md-input", {
                                        attrs: { disabled: !_vm.editMode },
                                        model: {
                                          value:
                                            _vm.businessEntity
                                              .businessRegistrationName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.businessEntity,
                                              "businessRegistrationName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "businessEntity.businessRegistrationName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "md-layout-item" },
                                [
                                  _c(
                                    "form-group",
                                    {
                                      attrs: {
                                        name: "businessNumber",
                                        label: "Business Number",
                                      },
                                    },
                                    [
                                      _c("md-input", {
                                        attrs: { disabled: !_vm.editMode },
                                        model: {
                                          value:
                                            _vm.businessEntity
                                              .businessRegistrationNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.businessEntity,
                                              "businessRegistrationNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "businessEntity.businessRegistrationNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "md-layout" }, [
                              _c(
                                "div",
                                { staticClass: "md-layout-item" },
                                [
                                  _c(
                                    "form-group",
                                    {
                                      attrs: {
                                        name: "businessAddress",
                                        label: "Address",
                                      },
                                    },
                                    [
                                      _c("md-input", {
                                        attrs: { disabled: !_vm.editMode },
                                        model: {
                                          value:
                                            _vm.businessEntity.businessAddress,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.businessEntity,
                                              "businessAddress",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "businessEntity.businessAddress",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "md-layout-item" },
                                [
                                  _c(
                                    "form-group",
                                    {
                                      attrs: {
                                        name: "companyNumber",
                                        label: "Company Number",
                                      },
                                    },
                                    [
                                      _c("md-input", {
                                        attrs: { disabled: !_vm.editMode },
                                        model: {
                                          value:
                                            _vm.businessEntity.companyNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.businessEntity,
                                              "companyNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "businessEntity.companyNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "md-layout" }, [
                              _c(
                                "div",
                                { staticClass: "md-layout-item" },
                                [
                                  _vm.editMode
                                    ? _c("brand-filter", {
                                        model: {
                                          value: _vm.businessEntity,
                                          callback: function ($$v) {
                                            _vm.businessEntity = $$v
                                          },
                                          expression: "businessEntity",
                                        },
                                      })
                                    : _vm._e(),
                                  !_vm.editMode
                                    ? _c(
                                        "form-group",
                                        {
                                          attrs: {
                                            name: "brand",
                                            label: "Brand",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "default-tracking-info",
                                              class: {
                                                empty:
                                                  _vm.businessEntity
                                                    .brandName == null,
                                              },
                                            },
                                            [
                                              _c("md-icon", [_vm._v("info")]),
                                              _c(
                                                "md-tooltip",
                                                {
                                                  attrs: {
                                                    "md-direction": "top",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                        If no brand is selected, the team profile logo will be\n                                                        displayed.\n                                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("md-input", {
                                            attrs: { disabled: !_vm.editMode },
                                            model: {
                                              value:
                                                _vm.businessEntity.brandName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.businessEntity,
                                                  "brandName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "businessEntity.brandName",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "md-layout-item" },
                                [
                                  _c(
                                    "form-group",
                                    { attrs: { name: "tags", label: "Tags" } },
                                    [
                                      _c("md-input", {
                                        attrs: { disabled: !_vm.editMode },
                                        model: {
                                          value: _vm.businessEntity.tags,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.businessEntity,
                                              "tags",
                                              $$v
                                            )
                                          },
                                          expression: "businessEntity.tags",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "md-layout" }, [
                              _c("div", { staticClass: "md-layout-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item" },
                                  [
                                    _c(
                                      "md-checkbox",
                                      {
                                        attrs: {
                                          type: "checkbox",
                                          disabled: !_vm.editMode,
                                        },
                                        model: {
                                          value:
                                            _vm.businessEntity
                                              .isRegisteredForTax,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.businessEntity,
                                              "isRegisteredForTax",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "businessEntity.isRegisteredForTax",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                                    Is Registered for Tax\n                                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-actions" },
                            [
                              _vm.editMode
                                ? _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-primary dialog-button pull-right",
                                      on: { click: _vm.updateBusinessEntity },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Update Business Entity\n                                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }