<template>
    <MapContainer :map-engine="mapEngine" @mapLoaded="handleMapLoaded">
        <div>
            <div v-for="(marker, index) in markers" :key="index">
                <MapMarker :marker="marker" :id="marker.id">
                    <MapPopup
                        :identifier="`pop-${marker.id}`"
                        :options="marker.popupOptions"
                        v-if="marker.popupOptions && marker.popupOptions.html"
                    />
                </MapMarker>
            </div>
            <div v-if="currentRoute.pathString">
                <MapPath :path-string="currentRoute.pathString" :options="currentRoute" :identifier="currentRoute.id" />
            </div>
            <div v-if="proposedRoute.pathString">
                <MapPath
                    :path-string="proposedRoute.pathString"
                    :options="proposedRoute"
                    :identifier="proposedRoute.id"
                />
            </div>
        </div>
    </MapContainer>
</template>

<script>
import { onMounted, ref, watch, nextTick, inject } from '@vue/composition-api';
import { MapPath, MapMarker, MapContainer, MapPopup, MapboxAdapter, ICON_NAMES } from '@/components/Maps';
import { useMapDefaultStyle } from '@/components/Maps/defaults';
import { setColour } from '@/helpers';

export default {
    name: 'DispatchMapboxCanvas',
    components: {
        MapPath,
        MapMarker,
        MapContainer,
        MapPopup
    },
    props: {
        shipmentDetails: {
            type: Object,
            default: null
        },
        offerList: {
            type: Array,
            default: () => []
        },
        pinnedOffer: {
            type: Object,
            default: null
        },
        focusedDriver: {
            type: Object,
            default: null
        },
        highlightedDriverId: {
            type: String,
            default: null
        },
        highlightedStopId: {
            type: Number,
            default: null,
            required: false
        },
        allTeamMembers: {
            type: Array,
            default: () => []
        },
        routeDisplay: {
            type: String,
            default: () => 'Both'
        }
    },
    data() {
        return {};
    },
    setup(props, { root }) {
        const { getters } = inject('vuex-store');
        const INFOBOX_HEIGHT = 220;

        // Centre the map on the current user's location or Sydney
        let center = ref(getters['user/user'].startLocation);
        if (!center.value)
            center = {
                latitude: -33.86500621007078,
                longitude: 151.20945304246425
            };

        const markers = ref([]);
        const markerToFitBoundsTo = ref(null);
        const currentRoute = ref({
            pathString: '',
            lineWidth: 4,
            lineColor: '#A44BC5',
            lineOffset: -1
        });
        const proposedRoute = ref({
            pathString: '',
            lineWidth: 4,
            lineColor: '#066CC8',
            lineOffset: 1
        });

        // mapEngine should be the mapbox or google maps object surround with an adapter
        const mapEngine = ref(null);

        function fitDriverToMap(markerId, withAnimation = true) {
            if (!markerId) 
                return;

            // checks if driver is in the map
            const driverMarker = markers.value.find((marker) => marker.id === markerId);
            if (!driverMarker) {
                return;
            }
            // driver should be within the map
            // modify map to find driver in bounds
            const allCoordinates = markers.value.map(
                /**
                 * @type {import('./types').MapMarker}
                 */
                (marker) => [marker.coordinates[0], marker.coordinates[1]]
            );
            allCoordinates.push([driverMarker.coordinates[0], driverMarker.coordinates[1]]);

            const bounds = allCoordinates.reduce((bounds, coord) => {
                return bounds.extend(coord);
            }, mapEngine.value.createLngLatBounds(allCoordinates[0], allCoordinates[0]));

            const options = {
                padding: {
                    bottom: INFOBOX_HEIGHT + 100,
                    top: 200
                }
            };

            if (!withAnimation) {
                Object.assign(options, {
                    duration: 0
                });
            }

            mapEngine.value.fitBounds({
                bounds,
                otherOptions: options
            });
        }

        function mapPanTo(coordinates) {
            mapEngine.value.panTo({
                center: coordinates,
                essential: true
            });
        }

        function handleMapLoaded() {
            nextTick().then(() => {
                fitDriverToMap(markerToFitBoundsTo.value?.id);
            });
        }

        const mapAdapter = new MapboxAdapter(mapboxgl);
        const options = {
            accessToken: process.env.VUE_APP_MAPBOX_API_KEY,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [center.value.longitude, center.value.latitude],
            zoom: 12
        };

        mapAdapter.setMapOptions(options);
        mapEngine.value = mapAdapter;

        const mapStopTypeToMarkerIcon = (stopType) => {
            switch (stopType) {
                case 'Pick Up':
                    return ICON_NAMES.SHIPMENT_PICKUP;
                case 'Drop':
                    return ICON_NAMES.SHIPMENT_DROP;
                default:
                    return ICON_NAMES.DESTINATION;
            }
        };

        const mapStopTypeToMarkerType = (stopType) => {
            switch (stopType) {
                case 'Pick Up':
                    return 'pickup';
                case 'Drop':
                    return 'drop';
                default:
                    return 'stop';
            }
        };

        const getDriverPopupOptions = (offer) => {
            return {
                html: offer.driverName,
                offset: 50
            };
        };

        const getStopPopupOptions = (stop, stopOrder) => {
            const text = `${stop.type ? `${stop.type.charAt(0)} -` : '  '} ${stop.stopName ?? stop.name}`;
            return {
                html: `${stopOrder}. ${text}`,
                offset: 50
            };
        };

        const createDriverMarker = (offer) => {
            const style = useMapDefaultStyle(ICON_NAMES.DRIVER);

            style['background-image'] = offer.photoUrl ? `url("${offer.photoUrl}")` : `url("${root.defaultPhotoUrl}")`;

            const markerInfo = {
                id: offer.publicUserId,
                coordinates: [offer.location.longitude, offer.location.latitude],
                color: offer.isOnline ? '#42972b' : '#972b2b', // "#2B93FF"
                isFaded: false, // Will be used to differentiate offered drivers from other drivers on the map
                isHighlighted: false,
                info: offer.driverName,
                offer,
                popupOptions: getDriverPopupOptions(offer),
                type: 'driver',
                markerStyle: style
            };

            markers.value.push(markerInfo);

            return markerInfo;
        };

        const createStopMarker = (stop, stopOrder) => {
            // These are markers that currently belong to the route for the pinned driver/offer
            const style = useMapDefaultStyle(mapStopTypeToMarkerIcon(stop.type));
            const markerInfo = {
                id: stop.stopId.toString(),
                coordinates: [stop.location.longitude, stop.location.latitude],
                color: setColour(stop.status),
                label: stopOrder.toString(),
                isHighlighted: false,
                info: `${stopOrder}. ${stop.stopName ?? stop.name}`,
                stop,
                popupOptions: getStopPopupOptions(stop, stopOrder),
                type: mapStopTypeToMarkerType(stop.type),
                markerStyle: style
            };

            markers.value.push(markerInfo);

            return markerInfo;
        };

        const plotRoutesAndMarkers = (offer) => {
            // Clear any existing stop markers + route polylines for the previous offer
            markers.value = [];
            currentRoute.value.pathString = '';
            proposedRoute.value.pathString = '';

            // wait for the markers and pathString to clear
            nextTick().then(() => {
                if (offer) {
                    // An offer has been pinned, show the routes + stops for the offer
                    if (props.routeDisplay === 'Current' || props.routeDisplay === 'Both') {
                        currentRoute.value = {
                            ...currentRoute.value,
                            id: `${offer.publicUserId}_current`,
                            pathString: offer.currentRoute ? offer.currentRoute.path : null
                        };
                    } else {
                        currentRoute.value = {
                            ...currentRoute.value,
                            id: `${offer.publicUserId}_current`,
                            pathString: ''
                        };
                    }

                    if (props.routeDisplay === 'Offer' || props.routeDisplay === 'Both') {
                        proposedRoute.value = {
                            ...proposedRoute.value,
                            id: `${offer.publicUserId}_proposed`,
                            pathString: offer.proposedRoute ? offer.proposedRoute.path : null
                        };
                    } else {
                        proposedRoute.value = {
                            ...proposedRoute.value,
                            id: `${offer.publicUserId}_proposed`,
                            pathString: ''
                        };
                    }

                    // display driver's marker
                    markerToFitBoundsTo.value = createDriverMarker(offer);

                    // Add markers for the stops in the driver's route.
                    if (props.routeDisplay !== 'None') {
                        const routeStops =
                            props.routeDisplay === 'Current' ? offer.currentRoute.stops : offer.proposedRoute.stops;

                        if (routeStops && routeStops.length) {
                            routeStops.forEach((stop, stopIndex) => {
                                if (!stop.isOfferStop) {
                                    createStopMarker(stop, stopIndex + 1);
                                } else {
                                    // IDEA: differentiate current vs offered stop markers
                                    createStopMarker(stop, stopIndex + 1);
                                }
                            });
                        }

                        // TODO: If the current route includes stops that aren't in this calculation, then
                        // plot these markers too.
                    }

                    // // Fit the map to the driver's location and routes
                    fitDriverToMap(markerToFitBoundsTo.value.id);
                }
            });
        };

        const plotDriversMarkers = (offerList) => {
            if (offerList && offerList.length) {
                const { location } = offerList[0];
                const coordinates = [location.longitude, location.latitude];

                offerList.forEach((offer) => createDriverMarker(offer));

                mapPanTo(coordinates);

                return;
            }

            // no offers found, show markers based on the details
            if (props.shipmentDetails) {
                createStopMarker(props.shipmentDetails.pickupStop, 1);
                createStopMarker(props.shipmentDetails.dropStop, 2);

                const { location } = props.shipmentDetails.pickupStop;
                const coordinates = [location.longitude, location.latitude];
                mapPanTo(coordinates);
            }
        };
        onMounted(() => {
            plotDriversMarkers(props.offerList);
        });

        watch(
            () => props.offerList,
            (newVal) => {
                plotDriversMarkers(newVal);
            }
        );

        watch(
            () => props.pinnedOffer,
            (offer) => {
                plotRoutesAndMarkers(offer);
            }
        );

        watch(
            () => props.routeDisplay,
            () => {
                // Redraw the map
                if (!props.pinnedOffer) 
                    return;
                plotRoutesAndMarkers(props.pinnedOffer);
            }
        );

        return {
            mapEngine,
            markers,
            currentRoute,
            proposedRoute,
            handleMapLoaded
        };
    }
};
</script>
