<template>
    <div>
        <div v-for="(content, ndx) in contentsList" class="detail-group" :key="`content-${ndx}`">
            <label class="label">{{ content.text }}:</label>
            <span class="value">
                {{ content.value }}
            </span>
        </div>
    </div>
</template>

<script>
import { SHIPMENT_CONTENTS_OPTIONS } from '@/utils/constants';

export default {
    name: 'ShipmentContentsDisplay',
    props: {
        contents: { type: Object, default: () => null }
    },
    computed: {
        contentsList() {
            const list = [];

            if (this.contents) {
                SHIPMENT_CONTENTS_OPTIONS.forEach((x) => {
                    if (this.contents[x.key] !== undefined && this.contents[x.key] !== null) {
                        list.push({ ...x, value: this.contents[x.key] ? 'Yes' : 'No' });
                    }
                });
            }

            list.sort((a, b) => {
                if (a.value === 'Yes' && b.value === 'Yes') {
                    return a.order > b.order ? 1 : -1;
                }
                if (a.value === 'Yes' && b.value === 'No') {
                    return -1;
                }
                if (a.value === 'No' && b.value === 'Yes') {
                    return 1;
                }
                return a.order > b.order ? 1 : -1;
            });

            return list;
        }
    }
};
</script>

<style lang="scss" scoped>
.detail-group {
    padding: 2px 16px;
    margin: 5px 0;
    font-size: 0.9em;
    .label {
        display: inline-block;
        width: 160px;
        margin-right: 4px;
        vertical-align: top;
        font-weight: 400;
    }

    .value {
        display: inline-block;
        width: calc(100% - 175px);
        vertical-align: top;
        a {
            color: #2b93ff;
        }
    }
}
</style>
