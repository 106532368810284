var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "filter-inline",
      style: { display: _vm.display },
      attrs: { id: _vm.id },
    },
    [
      _vm.teamRegions && _vm.teamRegions.length > 0
        ? _c(
            "md-field",
            [
              _c("vue-select", {
                attrs: {
                  label: "name",
                  options: _vm.filterTeamRegionOptions,
                  placeholder: "Team Region",
                  clearable: _vm.clearable,
                  "append-to-body": "",
                  "calculate-position": _vm.withPopper,
                  id: "sel-team-region-" + _vm.id,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selected-option",
                      fn: function (option) {
                        return [
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "2px" } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(option.name) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          _c(
                            "span",
                            {
                              class: _vm.isSubRegion
                                ? "option-level-" + option.level
                                : "",
                              staticStyle: { "margin-left": "2px" },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(option.name) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  708263386
                ),
                model: {
                  value: _vm.teamRegionFilterValue,
                  callback: function ($$v) {
                    _vm.teamRegionFilterValue = $$v
                  },
                  expression: "teamRegionFilterValue",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      (_vm.showTeamMember || _vm.showCarriers) &&
      _vm.filteredTeamMemberOptions.length > 0 &&
      !_vm.isSingleUser &&
      !_vm.isSingleTeamMember
        ? _c(
            "md-field",
            { staticClass: "filter-member-name" },
            [
              _c("vue-select", {
                attrs: {
                  label: "displayText",
                  options: _vm.filteredTeamMemberOptions,
                  placeholder: _vm.assigneePlaceHolder,
                  searchable: _vm.$root.isDesktop,
                  id: "sel-member-" + _vm.id,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          option.type !== "Separator"
                            ? _c("span", [_vm._v(_vm._s(option.displayText))])
                            : _c("hr"),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2934992455
                ),
                model: {
                  value: _vm.teamMemberFilterValue,
                  callback: function ($$v) {
                    _vm.teamMemberFilterValue = $$v
                  },
                  expression: "teamMemberFilterValue",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }