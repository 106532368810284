var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "md-card-content",
        [
          _c("md-table", {
            staticClass: "table-hover",
            attrs: { value: _vm.list },
            scopedSlots: _vm._u([
              {
                key: "md-table-row",
                fn: function (ref) {
                  var item = ref.item
                  return _c(
                    "md-table-row",
                    {},
                    [
                      _c(
                        "md-table-cell",
                        {
                          attrs: {
                            "md-label": "Team Member/ Asset Group",
                            "md-sort": "",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                item.trackerId
                                  ? item.assetGroupName
                                  : item.teamMemberFullName
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Geofence", "md-sort": "" } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.toGeofenceNameDesc(item)) +
                              "\n                "
                          ),
                        ]
                      ),
                      _c("md-table-cell", { attrs: { "md-label": "Action" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.action) +
                            "\n                "
                        ),
                      ]),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Date/Time" } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("dateTimeFormat")(
                                  item.actionDate,
                                  _vm.DATE_TYPES.standardDate
                                )
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }